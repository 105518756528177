import { ReactComponent as UploadIcon } from "../../../../assets/icons/upload.svg";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/x.svg";
import { FileUploader } from "react-drag-drop-files";
import { ImageView } from "../../../../components/Image";
import { useState } from "react";

const fileTypes = ["png", "jpeg", "jpg"];

export function UploadView({ text, onChange, value, onRemove, allowPdf = false }) {
  const url = value
    ? typeof value === "string"
      ? value
      : URL.createObjectURL(value)
    : "";
  if (url) {
    return (
      <div className="flex relative flex-col items-center justify-center h-[215px] gap-2 bg-[rgba(196,243,229,0.40)] p-[18px] border border-dashed border-primary rounded-[11px]">
        <div className="flex flex-col h-full justify-center max-w-[295px]">
          <ImageView
            src={url}
            alt="insurance card"
            className="h-full max-h-[-webkit-fill-available] rounded-lg border border-[#BFD8BD]"
            loading="lazy"
          />
        </div>
        <button
          className="bg-tintPrimary rounded-full p-2.5 absolute right-4 top-5"
          onClick={onRemove}
        >
          <CloseIcon className="[&_path]:w-[18px] [&_path]:h-[18px]" />
        </button>
      </div>
    );
  }
  return (
    <FileUploader handleChange={onChange} name="file" types={allowPdf ? [...fileTypes, "pdf"] : fileTypes}>
      <div className="flex flex-col cursor-pointer items-center h-[140px] gap-2 bg-[rgba(196,243,229,0.40)] p-[18px] border border-dashed border-primary rounded-[11px]">
        <div className="bg-white flex justify-center items-center rounded-full w-[56px] h-[56px]">
          <UploadIcon />
        </div>
        <div className="text-center text-base text-black">{text}</div>
      </div>
    </FileUploader>
  );
}

export function ManualUpload({ onChange, backSide, frontSide }) {
  return (
    <div className="flex flex-col gap-3 mt-2 mb-0">
      <UploadView
        text="Upload Insurance Card Photo (Front)"
        onChange={(file) => {
          onChange(file, "front");
        }}
        value={frontSide}
        onRemove={() => onChange(null, "front")}
      />
      <UploadView
        text="Upload Insurance Card Photo (Back)"
        onChange={(file) => {
          onChange(file, "back");
        }}
        value={backSide}
        onRemove={() => onChange(null, "back")}
      />
    </div>
  );
}

export function VOBUpload({ onChange, value }) {
  const url = value
    ? typeof value === "string"
      ? value
      : URL.createObjectURL(value)
    : "";

  const [pdfUploaded, setPdfUploaded] = useState(false);
  return (
    <div className="flex flex-col gap-3 mt-0 mb-5">
      <UploadView
        text="Upload Verification of Benefits (VOB)"
        onChange={(file) => {
          if (file.type.includes("pdf")) setPdfUploaded(true);
          onChange(file);
        }}
        value={(pdfUploaded || url?.includes(".pdf")) ? "/images/pdf.png" : url}
        allowPdf={true}
        onRemove={() => onChange(null)}
      />

    </div>
  );
}

export function CdeUpload({ onChange, value }) {
  const url = value
    ? typeof value === "string"
      ? value
      : URL.createObjectURL(value)
    : "";

  const [pdfUploaded, setPdfUploaded] = useState(false);
  return (
    <div className="flex flex-col gap-3 mt-0 mb-5">
      <UploadView
        text="Upload Diagnostic Evaluation (DE)"
        onChange={(file) => {
          if (file.type.includes("pdf")) setPdfUploaded(true);
          onChange(file);
        }}
        value={(pdfUploaded || url?.includes(".pdf")) ? "/images/pdf.png" : url}
        allowPdf={true}
        onRemove={() => onChange(null)}
      />

    </div>
  );
}