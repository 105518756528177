import { ReactComponent as InfoIcon } from "../../../assets/icons/info.svg";
import { Tooltip } from "react-tooltip";

export function InfoWithStatusNoteTooltip({ text, color }) {
  return (
    <>
      <InfoIcon
        className={`w-4 h-4 status-tooltip ${color} cursor-pointer`}
        data-tooltip-id="my-tooltip"
        data-tooltip-content={text}
      />
      <Tooltip
        id="my-tooltip"
        className="z-[100] !w-[228px] !border-0 !text-black !rounded-lg !p-4 !bg-white !shadow-[0px_2px_5px_0px_rgba(46,84,61,0.125)]"
        noArrow
      />
    </>
  );
}
