import { ReactComponent as Email } from "../../../../assets/icons/email.svg";
import { ReactComponent as Call } from "../../../../assets/icons/call.svg";
import { ReactComponent as Check } from "../../../../assets/icons/check.svg";
import { ReactComponent as Close } from "../../../../assets/icons/x.svg";
import { ReactComponent as DownloadIcon } from "../../../../assets/icons/download.svg";
import moment from "moment";
import { PopupModal } from "../../../../components/PopupModal";
import { useEffect, useState } from "react";
import { api } from "../../../../services/api";
import { Loading } from "../../../../components/Loading/Loading";
import { ShowToast } from "../../../../services/toast";
import { ImageView } from "../../../../components/Image";
import { getInitial } from "../../../../services/utils";
import { EditFamilyProfile } from "./EditFamilyProfile";
import axios from "axios";
import { ProviderList } from "../../components/ProviderList";
import { TextArea } from "../../../../components/Textarea";
import { zipCodes } from "../../../rbtRequests/modals/zip_codes";
import { clientStatusColor, HowDidYouHearAboutUs } from "../../hooks/patient.types";

export function EditFamilyProfileForm({
  user,
  onClose,
  onUpdate,
}: {
  user: any;
  onClose: () => void;
  onUpdate: () => void;
}) {
  const [userData, setUserData] = useState<any>(user);
  const [editProfile, setEditProfile] = useState(false);
  const [loading, setLoading] = useState(false);
  const [insurance, setInsurance] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const [edit, setEdit] = useState(false);
  const [form, setForm] = useState<any>({
    notes: "",
    isVerified: false,
  });
  const [secondaryInsurance, setSecondaryInsurance] = useState<any>({});
  const [secondaryInsuranceForm, setSecondaryInsuranceForm] = useState<any>({
    notes: "",
    isVerified: false,
  });
  const [previewInsurance, setPreviewInsurance] = useState<{ url: string, fileName: string } | undefined>();
  const [activeTab, setActiveTab] = useState("about");
  const [clientProviders, setClientProviders] = useState<any[]>([]);

  const textReasonForHeardAboutUs = userData.howDidYouHearAboutUs && [
    HowDidYouHearAboutUs.ReferralFromAnotherCareProvider,
    HowDidYouHearAboutUs.SchoolOrDaycare,
    HowDidYouHearAboutUs.ParentSupportGroupOrResourceCenter,
    HowDidYouHearAboutUs.Other].includes(userData.howDidYouHearAboutUs)

  useEffect(() => {
    try {
      api.getClientProviders(user.id).then((res) => {
        setClientProviders(res.providers);
      })
    } catch (error) {
      console.error(error);
    }
  }, [user?.id]);

  const handleClose = () => {
    onClose();
  };

  const handlePreviewInsuranceDownload = async () => {
    // download file instead of opening it
    const url = previewInsurance?.url;
    try {
      if (url) {
        // download file here
        const response = await axios.get(url, {
          responseType: "blob",
          headers: {
            Accept: "*",
          },
        });
        const newURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = newURL;
        link.setAttribute("download", `${previewInsurance.fileName}${decodeURIComponent(previewInsurance.url.substring(previewInsurance.url.lastIndexOf('.')))}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Unable to download file. Please try again.",
      });
    }
  }

  const fetchInsurance = async (userId?: string, showLoading = true) => {
    if (userId) {
      showLoading && setLoading(true);
      api
        .getInsurance(user.id)
        .then((res) => {
          setInsurance(res?.data?.find((i) => i.isPrimary) || {});
          setSecondaryInsurance(res?.data?.find((i) => !i.isPrimary) || {});
          setLoading(false);
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    fetchInsurance(user.id);
    setUserData(user);
  }, [user]);

  const hasInsurance = insurance.frontImg || insurance.backImg;
  const hasSecondaryInsurance =
    secondaryInsurance.frontImg || secondaryInsurance.backImg;
  return (
    <>
      <PopupModal
        onClose={handleClose}
        contentClassName="p-8 !z-[10] bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-[98%] md:w-[552px] md:max-w-[552px] mx-auto max-h-[calc(100vh-50px)]"
        exit={{ opacity: 0 }}
        overlayClassName="!z-[9]"
        shouldStopPropagation={false}
      >
        {editProfile ? (
          <>
            <EditFamilyProfile
              isOpen={editProfile}
              onClose={() => setEditProfile(false)}
              user={userData}
              insuranceData={insurance}
              secondaryInsuranceData={secondaryInsurance}
              onBack={() => setEditProfile(false)}
              onSuccess={(data) => {
                onUpdate();
                setUserData((prev) => ({ ...prev, ...data }));
                fetchInsurance(user.id, true);
              }}
            />
          </>
        ) : (
          <>
            <div className="w-full justify-between items-start flex">
              <div className="text-black text-2xl font-bold capitalize leading-7">
                {userData?.firstName}'s details
              </div>
              <div className="justify-end items-center gap-3 flex">
                {activeTab === "about" && (
                  <button
                    className="text-primary !text-sm font-medium hover:text-black"
                    onClick={() => setEditProfile(true)}
                  >
                    Edit Profile
                  </button>
                )}
                <button onClick={handleClose}>
                  <Close className="w-6 h-6" />
                </button>
              </div>
            </div>
            {loading ? (
              <div>
                <Loading />
              </div>
            ) : (
              <>
                <div className="w-full  max-h-[calc(100vh-150px)] overflow-auto flex-col justify-start items-start gap-5 flex">
                  <div className="self-stretch p-3 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-3.5 flex">
                    <div className="self-stretch justify-start items-center gap-3.5 inline-flex">
                      <div className="justify-start items-center gap-3.5 flex">
                        {userData?.profileImg ? (
                          <ImageView
                            className="w-24 h-24 rounded-[10px] object-cover"
                            src={userData?.profileImg}
                            alt="user"
                            loading="lazy"
                          />
                        ) : (
                          <div className="uppercase w-24 h-24 rounded-[10px] flex justify-center items-center text-3xl text-primary bg-secondary">
                            {getInitial(
                              userData?.guardianFirstName,
                              userData?.guardianLastName
                            )}
                          </div>
                        )}
                        <div className="flex-col justify-start items-start gap-2 inline-flex">
                          <div className="text-black text-lg font-medium ">
                            {userData?.guardianFirstName}{" "}
                            {userData?.guardianLastName}
                          </div>
                          <div className="justify-start items-center gap-1.5 inline-flex">
                            <Email className="w-5 h-5 relative" />
                            <div className="text-black text-opacity-80 text-sm font-normal  leading-tight">
                              {userData?.email}
                            </div>
                          </div>
                          <div className="justify-start items-center gap-1.5 inline-flex">
                            <Call className="w-5 h-5 relative"></Call>
                            <div className="text-black text-opacity-80 text-sm font-normal  leading-tight">
                              {userData?.mobile}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`w-fit p-1.5 bg-white rounded-3xl justify-start items-start gap-px inline-flex`}
                  >
                    <button
                      onClick={() => setActiveTab("about")}
                      className={`px-4 py-2 ${activeTab === "about" ? "bg-green-100" : "bg-white"
                        } rounded-full justify-center items-center gap-2.5 flex`}
                    >
                      <div className="text-black text-base font-normal font-['Outfit'] leading-tight">
                        About
                      </div>
                    </button>
                    <button
                      onClick={() => setActiveTab("providers")}
                      className={`px-4 py-2 ${activeTab === "providers" ? "bg-green-100" : "bg-white"
                        } rounded-full justify-center items-center gap-2.5 flex`}
                    >
                      <div className="text-black text-base font-normal leading-tight flex items-center">
                        Providers{" "}
                        {loading ? (
                          <>
                            <div className="w-4 h-4 ml-1.5 mt-1 rounded-md bg-gray-300 animate-pulse transition duration-50"></div>
                          </>
                        ) : (
                          <>({clientProviders.length})</>
                        )}
                      </div>
                    </button>
                  </div>

                  {activeTab === "providers" && (
                    <div className="self-stretch">
                      <ProviderList
                        clientUser={user}
                        clientProviders={clientProviders}
                      />
                    </div>
                  )}


                  {activeTab === "about" && <>

                    {userData?.profileSummary && (
                      <div className="self-stretch flex-col justify-start items-start gap-3 flex">
                        <div className="text-neutral-500 text-sm font-semibold  uppercase">
                          About me
                        </div>
                        <div className="self-stretch p-4 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-1.5 flex">
                          <span className="text-black opacity-80 text-sm font-normal">
                            {userData?.profileSummary}
                          </span>
                        </div>
                      </div>
                    )}

                    <div className="self-stretch flex-col justify-start items-start gap-3 flex">
                      <div className="text-neutral-500 text-sm font-semibold  uppercase">
                        Client's details
                      </div>
                      <div className="self-stretch p-4 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-1.5 flex">
                        <div className="justify-start items-center gap-1.5 inline-flex">
                          <div className="w-32 opacity-80 text-black text-sm font-normal ">
                            Name:{" "}
                          </div>
                          <div className="opacity-80 text-black text-sm font-medium ">
                            {userData?.firstName} {userData?.lastName}
                          </div>
                        </div>
                        <div className="justify-start items-center gap-1.5 inline-flex">
                          <div className="w-32 opacity-80 text-black text-sm font-normal ">
                            Date of birth:{" "}
                          </div>
                          <div className="opacity-80 text-black text-sm font-medium ">
                            {moment(userData?.dob).format("MMM DD, YYYY")}
                          </div>
                        </div>
                        {userData?.zipCode && (
                          <div className="justify-start items-center gap-1.5 inline-flex">
                            <div className="w-32 opacity-80 text-black text-sm font-normal font-['Outfit']">
                              Location (Zip Code):
                            </div>
                            <div className="opacity-80 text-black text-sm font-medium font-['Outfit']">
                              {userData?.zipCode && `${zipCodes[userData.zipCode].city}, ${zipCodes[userData.zipCode].state_short} (${userData.zipCode})`}
                            </div>
                          </div>
                        )}
                        {userData?.clientStatus && (
                          <div className="justify-start items-center gap-1.5 inline-flex">
                            <div className="w-32 opacity-80 text-black text-sm font-normal font-['Outfit']">
                              Status:
                            </div>
                            <div className="opacity-80 text-black text-sm font-medium font-['Outfit']" style={{ color: clientStatusColor(userData.clientStatus) }}>
                              {userData.clientStatus}
                            </div>
                          </div>
                        )}
                      </div>

                    </div>

                    {userData?.cdeUrl &&
                      <>
                        <div className="text-neutral-500 text-sm font-semibold uppercase block">
                          Diagnostic Evaluation (DE)
                        </div>
                        <div
                          className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-center text-center items-center gap-1.5 flex flex-col lg:flex-row"
                          onClick={() => setPreviewInsurance({ url: userData?.cdeUrl, fileName: `DiagnosticEvaluation` })}
                        >
                          <ImageView
                            className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg cursor-pointer"
                            containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                            src={userData.cdeUrl.includes(".pdf") ? "/images/pdf.png" : userData.cdeUrl}
                            style={userData.cdeUrl.includes(".pdf") ? { height: "140px", textAlign: "left", padding: "20px" } : {}}
                            alt="Diagnostic Evaluation"
                            loading="lazy"
                          />
                        </div>
                      </>
                    }

                    <div className="self-stretch flex-col justify-start items-start gap-3 flex">
                      <div className="self-stretch justify-between items-center flex">
                        <div className="flex items-center gap-2.5 !rounded-full">
                          <div className="text-neutral-500 text-sm font-semibold  uppercase">
                            Insurance
                          </div>
                        </div>


                        {((insurance.frontImg || insurance.backImg) && insurance.isVerified !== undefined) && (
                          <div className="px-3 py-1 bg-emerald-100 rounded-3xl border border-emerald-200 justify-start items-center gap-1 flex">
                            {insurance.isVerified && (
                              <Check className="w-3.5 h-3.5 relative" />
                            )}
                            <div className="text-black text-sm font-normal ">
                              {insurance.isVerified
                                ? "Verified"
                                : "Verification In-Progress"}
                            </div>
                          </div>
                        )}
                      </div>
                      {!hasInsurance ? (
                        <div className="w-full mt-[-10px]">
                          <div className="text-center">No insurance provided</div>
                        </div>
                      ) : (
                        <div className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-start items-center gap-1.5 flex flex-col">
                          {!!insurance.verifyNotes && !edit ? (
                            <div className="w-full p-3 bg-[#E1F9F1] rounded-md justify-start items-start gap-2.5 flex">
                              <div className="text-black text-sm">
                                {insurance.verifyNotes.split("\n").map((note, index) => <p key={index}>{note}</p>)}
                              </div>
                            </div>
                          ) : null}
                          {edit && (
                            <TextArea
                              rows={6}
                              placeholder="Enter Insurance Notes"
                              className="!p-[14px]"
                              name="insuranceNotes"
                              value={form.notes}
                              onChange={(e) => {
                                setForm({ ...form, notes: e.target.value });
                              }}
                            />
                          )}
                          <div className="justify-start items-center gap-1.5 grid grid-cols-2 mt-3">
                            {insurance.frontImg &&
                              <ImageView
                                className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg"
                                containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col items-center cursor-pointer"
                                src={insurance.frontImg}
                                alt="insurance"
                                loading="lazy"
                                onClick={() => setPreviewInsurance({ url: insurance.frontImg, fileName: `${userData.firstName}-insurance-primary-front` })}
                              />}
                            {insurance.backImg &&
                              <ImageView
                                className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg"
                                containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col items-center cursor-pointer"
                                src={insurance.backImg}
                                alt="insurance"
                                loading="lazy"
                                onClick={() => setPreviewInsurance({ url: insurance.backImg, fileName: `${userData.firstName}-insurance-primary-back` })}
                              />
                            }

                          </div>
                        </div>
                      )}
                    </div>

                    {insurance.vobImg &&
                      <>
                        <div className="text-neutral-500 text-sm font-semibold uppercase block">
                          Verification of Benefits (VOB)
                        </div>
                        <div className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-center text-center items-center gap-1.5 flex flex-col lg:flex-row">
                          <ImageView
                            className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg"
                            containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col items-center cursor-pointer"
                            src={insurance.vobImg.includes(".pdf") ? "/images/pdf.png" : insurance.vobImg}
                            style={insurance.vobImg.includes(".pdf") ? { height: "140px", textAlign: "left", padding: "20px" } : {}}
                            alt="insurance vob"
                            loading="lazy"
                            onClick={() => setPreviewInsurance({ url: insurance.vobImg, fileName: `${userData.firstName}-insurance-primary-vob` })}
                          />
                        </div>
                      </>
                    }

                    {/** Secondary Insurance */}
                    <div className="self-stretch flex-col justify-start items-start gap-3 flex mt-4">
                      <div className="self-stretch justify-between items-center flex">
                        <div className="flex items-center gap-2.5 !rounded-full">
                          <div className="text-neutral-500 text-sm font-semibold  uppercase">
                            Secondary Insurance
                          </div>
                        </div>

                        {((secondaryInsurance.frontImg || secondaryInsurance.backImg) && secondaryInsurance.isVerified !== undefined) && (
                          <div className="px-3 py-1 bg-emerald-100 rounded-3xl border border-emerald-200 justify-start items-center gap-1 flex">
                            {secondaryInsurance.isVerified && (
                              <Check className="w-3.5 h-3.5 relative" />
                            )}
                            <div className="text-black text-sm font-normal ">
                              {secondaryInsurance.isVerified
                                ? "Verified"
                                : "Verification In-Progress"}
                            </div>
                          </div>
                        )}

                      </div>
                      {!hasSecondaryInsurance ? (
                        <div className="w-full mt-[-5px] text-center">
                          No secondary insurance provided
                        </div>
                      ) : (
                        <div className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-start items-center gap-1.5 flex flex-col">
                          {secondaryInsurance.verifyNotes && (
                            <div className="w-full p-3 bg-[#E1F9F1] rounded-md justify-start items-start gap-2.5 flex">
                              <div className="text-black text-sm">
                                {secondaryInsurance.verifyNotes.split("\n").map((note, index) => <p key={index}>{note}</p>)}
                              </div>
                            </div>
                          )}
                          <div className="justify-start items-center gap-1.5 flex flex-col lg:flex-row mt-3">
                            {secondaryInsurance.frontImg &&
                              <ImageView
                                className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg"
                                containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center cursor-pointer"
                                src={secondaryInsurance.frontImg}
                                alt="insurance"
                                loading="lazy"
                                onClick={() => setPreviewInsurance({ url: secondaryInsurance.frontImg, fileName: "insurance-secondary-front" })}
                              />
                            }
                            {secondaryInsurance.backImg &&
                              <ImageView
                                className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg"
                                containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center cursor-pointer"
                                src={secondaryInsurance.backImg}
                                alt="insurance"
                                loading="lazy"
                                onClick={() => setPreviewInsurance({ url: secondaryInsurance.backImg, fileName: "insurance-secondary-back" })}
                              />
                            }
                          </div>
                        </div>
                      )}
                    </div>
                    {secondaryInsurance.vobImg && <>
                      <div className="text-neutral-500 text-sm font-semibold uppercase block">
                        Verification of Benefits (VOB)
                      </div>
                      <div className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-center text-center items-center gap-1.5 flex flex-col lg:flex-row">
                        <ImageView
                          className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg"
                          containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col items-center cursor-pointer"
                          src={secondaryInsurance.vobImg.includes(".pdf") ? "/images/pdf.png" : secondaryInsurance.vobImg}
                          style={secondaryInsurance.vobImg.includes(".pdf") ? { height: "140px", textAlign: "left", padding: "20px" } : {}}
                          alt="insurance vob"
                          loading="lazy"
                          onClick={() => setPreviewInsurance({ url: secondaryInsurance.vobImg, fileName: `${userData.firstName}-insurance-secondary-vob` })}
                        />
                      </div>
                    </>}

                    <div className="self-stretch flex-col justify-start items-start gap-3 flex mt-6">
                      <div className="text-neutral-500 text-sm font-semibold  uppercase">
                        Referral Source
                      </div>
                      <div className="self-stretch p-4 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-1.5 flex">
                        <div className="justify-start items-center inline-flex">
                          <div className="w-52 opacity-80 text-black text-sm font-normal ">
                            How did you hear about Headstart?{" "}
                          </div>
                          <div className="opacity-80 text-black text-sm font-medium ">
                            {userData?.howDidYouHearAboutUs ?? '(Not Provided)'}
                          </div>
                        </div>
                        {textReasonForHeardAboutUs && (
                          <div className="justify-start items-center inline-flex">
                            <div className="w-52 opacity-80 text-black text-sm font-normal ">
                              {userData.howDidYouHearAboutUs === HowDidYouHearAboutUs.ReferralFromAnotherCareProvider
                                && "What is the name of the institution or provider that referred you?"}
                              {userData.howDidYouHearAboutUs === HowDidYouHearAboutUs.SchoolOrDaycare
                                && "What is the name of the facility that referred you?"}
                              {userData.howDidYouHearAboutUs === HowDidYouHearAboutUs.ParentSupportGroupOrResourceCenter
                                && "What is the name of the group or resource center?"}
                              {userData.howDidYouHearAboutUs === HowDidYouHearAboutUs.Other
                                && "Other (please specify):"}
                              {" "}
                            </div>
                            <div className="opacity-80 text-black text-sm font-medium ">
                              {userData?.howDidYouHearAboutUsText}
                            </div>
                          </div>
                        )}
                      </div>

                    </div>
                  </>}
                </div>
              </>
            )}
          </>
        )
        }

        {
          previewInsurance &&
          <PopupModal
            contentClassName="p-8 !z-[10] bg-white rounded-2xl flex-col justify-start items-center !gap-4 flex w-[98%] md:w-[60vw] mx-auto min-h-[80vh] max-h-[calc(100vh-50px)]"
            onClose={() => setPreviewInsurance(undefined)}
          >
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center gap-2">
                <div
                  className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap"
                  style={{ wordBreak: "break-word" }}
                >

                </div>
                <button onClick={handlePreviewInsuranceDownload}>
                  <DownloadIcon />
                </button>
              </div>
              <button onClick={() => setPreviewInsurance(undefined)}>
                <Close className="w-8 h-8" />
              </button>
            </div>

            <div className="flex justify-center items-center flex-1 w-full">

              {previewInsurance.url.includes(".pdf") ?
                <iframe
                  src={previewInsurance.url}
                  className="w-full h-full object-contain"
                  title="file"
                  frameBorder="0"
                />
                :
                <div className="h-[calc(100vh-200px)]">
                  <ImageView
                    src={previewInsurance?.url}
                    className="w-full h-full object-contain"
                    alt="file"
                  />
                </div>
              }

            </div>


          </PopupModal>
        }


      </PopupModal>
    </>
  );
}
