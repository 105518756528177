import { ReactComponent as FolderIcon } from "../../../assets/icons/file-folder.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as FileIcon } from "../../../assets/icons/file.svg";
import { Button } from "../../../components/Button";
import { useFiles } from "../context/file-context";
import React from "react";
import axios from "axios";
import { AnimatePresence } from "framer-motion";
import { DeleteConfirm } from "./DeleteConfirm";
import { MoveFileDialog } from "./MoveFileDialog";
import { AdminTooltip } from "./AdminTooltip";
import UIPopover from "../../../components/Popover";
import { ShowToast } from "../../../services/toast";
import { ImageView } from "../../../components/Image";
import { RenameFolderDialog } from "./RenameFolder";
import { PopupModal } from "../../../components/PopupModal";
import { APP_URL } from "../../../env";

export function LoadingSkeleton({ type }) {
  // it should be animated skeleton pulse
  // for both grid and list view
  if (type === "grid") {
    return (
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-4 gap-[15px]">
        {new Array(12).fill(0).map((item, index) => (
          <div
            key={index}
            className="p-8 bg-white rounded-2xl flex-col justify-start items-center gap-5 flex"
          >
            <div className="animate-pulse bg-gray-200 w-[68px] h-[68px] rounded-full"></div>
            <div className="animate-pulse bg-gray-200 h-4 w-32 rounded-full"></div>
          </div>
        ))}
      </div>
    );
  }
  return (
    <div className="flex flex-col gap-4">
      {[1, 2, 3, 4, 5, 6, 7, 8].map((item, index) => (
        <div
          key={index}
          className="pl-3 pr-6 py-3 bg-white rounded-2xl justify-start items-center gap-9 flex"
        >
          <div className="grow shrink basis-0 h-12 justify-start items-center gap-3 flex">
            <div className="animate-pulse bg-gray-200 w-9 h-9 rounded-full"></div>
            <div className="animate-pulse bg-gray-200 h-4 w-32 rounded-full"></div>
          </div>
          <div className="animate-pulse bg-gray-200 h-6 w-16 rounded-full"></div>
          <div className="self-stretch justify-end items-center gap-1.5 flex">
            <div className="animate-pulse bg-gray-200 h-4 w-5 rounded-md" />
            <div className="flex items-center gap-[3px]">
              <div className={`w-1 h-1 bg-gray-200 rounded-full`} />
              <div className={`w-1 h-1 bg-gray-200 rounded-full`} />
              <div className={`w-1 h-1 bg-gray-200 rounded-full`} />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function Menu({
  onMove,
  onDelete,
  disabled = false,
  onRename,
  onShare,
  onUnshare,
  onCopyUrl,
  isShared,
}) {
  return (
    <UIPopover
      trigger={
        <div className="flex items-center gap-[3px] px-1 py-4">
          <div
            className={`w-1 h-1 bg-black  ${disabled ? "opacity-30" : "bg-black"
              } rounded-full`}
          />
          <div
            className={`w-1 h-1 bg-black ${disabled ? "opacity-30" : ""
              } rounded-full`}
          />
          <div
            className={`w-1 h-1 bg-black ${disabled ? "opacity-30" : ""
              } rounded-full`}
          />
        </div>
      }
      disabled={disabled}
    >
      {(close) => (
        <Dropdown
          onMove={(e) => {
            onMove(e);
            close();
          }}
          onDelete={(e) => {
            onDelete(e);
            close();
          }}
          onRename={(e) => {
            onRename(e);
            close();
          }}
          onShare={(e) => {
            onShare(e);
            close();
          }}
          onUnshare={(e) => {
            onUnshare(e);
            close();
          }}
          onCopyUrl={(e) => {
            onCopyUrl(e)
            close();
          }}
          isShared={isShared}
        />
      )}
    </UIPopover>
  );
}

export function Folder({
  type,
  data,
  onFileClick,
}: {
  type: "grid" | "list";
  data: any;
  onFileClick?: () => void;
}) {
  const { onFolderSelect, removingFile, deleteDocument, shareFolder, breadcrumb } =
    useFiles() as any;
  const [showDeleteConfirm, setShowDeleteConfirm] = React.useState(false);
  const [showMoveFileDialog, setShowMoveFileDialog] = React.useState(false);
  const [showRenameDialog, setShowRenameDialog] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const isFolder = data?.type === "folder";

  const handleConfirm = async () => {
    await deleteDocument(data);
    setShowDeleteConfirm(false);
  };

  const handleDownload = async () => {
    // download file instead of opening it
    const url = data?.url;
    try {
      if (url) {
        // download file here
        const response = await axios.get(url, {
          responseType: "blob",
          headers: {
            Accept: "*",
          },
        });
        const newURL = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = newURL;
        link.setAttribute("download", `${data?.name}`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      ShowToast({
        type: "error",
        message: "Unable to download file. Please try again.",
      });
    }
  };

  const handleClick = async () => {
    if (isFolder) {
      onFolderSelect(data);
    } else if (data?.type === "youtubelink" || data?.mimeType?.includes("image") || data?.mimeType?.includes("pdf")) {
      setOpen(true);
    } else {
      handleDownload()
    }

  };

  const youtubeParser = (url) => {
    const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url.match(regExp);
    return (match && match[7].length === 11) ? match[7] : false;
  }

  const renderPreview = () => {
    return (
      <>
        {open && (
          <PopupModal
            contentClassName="p-8 !z-[10] bg-white rounded-2xl flex-col justify-start items-center !gap-4 flex w-[98%] md:w-[60vw] mx-auto min-h-[80vh] max-h-[calc(100vh-50px)]"
            onClose={() => setOpen(false)}
          >
            <div className="flex justify-between items-center w-full">
              <div className="flex items-center gap-2">
                <div
                  className="max-w-[200px] overflow-hidden text-ellipsis whitespace-nowrap"
                  style={{ wordBreak: "break-word" }}
                >
                  {data?.name}
                </div>
                {data?.type !== "youtubelink" && (
                  <button onClick={handleDownload}>
                    <DownloadIcon />
                  </button>)}
              </div>
              <button onClick={() => setOpen(false)}>
                <CloseIcon className="w-8 h-8" />
              </button>
            </div>
            {
              data?.type === "youtubelink" ?
                (<iframe width="100%" height="100%"
                  src={`https://www.youtube.com/embed/${youtubeParser(data?.url)}`}
                  title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; 
                  encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowFullScreen>

                </iframe>)
                : data?.mimeType?.includes("image") ? (
                  <div className="flex justify-center items-center flex-1 w-full">
                    <div className="h-[calc(100vh-200px)]">
                      <ImageView
                        src={data.url}
                        className="w-full h-full object-contain"
                        alt="file"
                      />
                    </div>
                  </div>
                ) : data?.mimeType?.includes("pdf") && (
                  <iframe
                    src={data.url}
                    className="w-full h-full object-contain"
                    title="file"
                    frameBorder="0"
                  />
                )
            }
          </PopupModal>
        )}
      </>
    );
  };

  if (type === "grid") {
    return (
      <div
        role="button"
        onClick={handleClick}
        className={`p-8 relative bg-white rounded-2xl hover:shadow-[0px_18px_52px_0px_rgba(46,84,61,0.25)] flex-col justify-start items-center gap-5 flex`}
      >
        {data?.isAccessibleByProviders && (
          <div className="absolute top-2.5 left-2.5">
            <AdminTooltip />
          </div>
        )}
        <div className="absolute top-2 right-3">
          <Menu
            onCopyUrl={(e) => {
              e.stopPropagation();
              e.preventDefault();
              copyDocUrlToClipboard();
            }}
            onMove={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowMoveFileDialog(true);
            }}
            onDelete={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowDeleteConfirm(true);
            }}
            onRename={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setShowRenameDialog(true);
            }}
            onShare={(e) => {
              e.stopPropagation();
              e.preventDefault();
              shareFolder(data, true);
            }}
            onUnshare={(e) => {
              e.stopPropagation();
              e.preventDefault();
              shareFolder(data, false);
            }}
            isShared={data?.isAccessibleByProviders}
          />
        </div>
        {isFolder ? (
          <div className="p-5 bg-[#E8F3F2] rounded-full justify-start items-center gap-2.5 flex">
            <FolderIcon className="w-12 h-12" />
          </div>
        ) : (
          <div className="p-3 justify-center items-center gap-2.5 flex w-[88px] h-[88px]">
            {data?.type === "youtubelink" ?
              (<ImageView
                src={"/images/videoIcon.png"}
                alt="icon"
                className="w-full h-full"
              />)
              :
              data?.mimeType?.includes("image") ? (
                <ImageView src={data?.url} alt="icon" className="w-full h-full" />
              ) : data?.mimeType?.includes("pdf") ? (
                <ImageView
                  src={"/images/pdf.png"}
                  alt="icon"
                  className="w-full h-full"
                />
              ) : (
                <FileIcon />
              )}
          </div>
        )}
        <div className="text-center overflow-hidden text-ellipsis max-w-[150px] whitespace-nowrap text-black text-lg font-medium">
          {data?.name}
        </div>
        <AnimatePresence mode="wait" initial={false}>
          {showDeleteConfirm && (
            <DeleteConfirm
              onCancel={() => setShowDeleteConfirm(false)}
              name={data?.name}
              onConfirm={handleConfirm}
              loading={removingFile}
              fileType={data?.type}
            />
          )}
        </AnimatePresence>

        <AnimatePresence mode="wait" initial={false}>
          {showMoveFileDialog && (
            <MoveFileDialog
              onClose={() => setShowMoveFileDialog(false)}
              document={data}
            />
          )}
        </AnimatePresence>

        <AnimatePresence mode="wait" initial={false}>
          {showRenameDialog && (
            <RenameFolderDialog
              onClose={() => setShowRenameDialog(false)}
              data={data}
              open={showRenameDialog}
            />
          )}
        </AnimatePresence>
        {renderPreview()}
      </div>
    );
  }

  const copyDocUrlToClipboard = () => {
    let dirList;
    if (breadcrumb) {
      dirList = breadcrumb.map(b => b.name).join('____')
    }

    const fileLinkQueryStr = encodeURIComponent(`${dirList ? `${dirList}____` : ''}${data.name}`);
    const link = encodeURI(`${APP_URL}/provider/library?filelink=${fileLinkQueryStr}`);

    navigator.clipboard.writeText(link).then(() => {
      console.log("Document URL copied to clipboard");
    }).catch((error) => {
      console.error("Failed to copy document URL to clipboard:", error);
    });

  }

  return (
    <div
      role="button"
      className="pl-3 pr-3 md:pr-6 py-3 bg-white rounded-2xl hover:shadow-[0px_18px_52px_0px_rgba(46,84,61,0.25)] justify-start items-center gap-9 flex"
      onClick={handleClick}
    >
      <div className="grow shrink basis-0 h-12 justify-start items-center gap-3 flex">
        {isFolder ? (
          <div className="p-3 bg-[#E8F3F2] rounded-full justify-center items-center gap-2.5 flex">
            <FolderIcon className="w-6 h-6" />
          </div>
        ) : (
          <div className="p-3 justify-center items-center gap-2.5 flex w-[50px] h-[50px]">
            {data?.type === "youtubelink" ?
              (<ImageView
                src={"/images/videoIcon.png"}
                alt="icon"
                className="w-full h-full"
              />) : data?.mimeType?.includes("image") ? (
                <ImageView
                  src={data?.url}
                  alt="icon"
                  className="w-full h-full object-contain"
                />
              ) : data?.mimeType?.includes("pdf") ? (
                <ImageView
                  src={"/images/pdf.png"}
                  alt="icon"
                  className="w-full h-full object-contain"
                />
              ) : (
                <FileIcon />
              )}
          </div>
        )}
        <div className="max-w-[175px] md:max-w-[450px] overflow-hidden whitespace-nowrap text-ellipsis text-black text-lg font-medium font-['Outfit'] leading-relaxed">
          {data?.name}
        </div>
        {data?.isAccessibleByProviders && (
          <div>
            <AdminTooltip />
          </div>
        )}
      </div>
      {data?.children?.length > 0 && (
        <div className="px-3.5 py-2 bg-[#E8F3F2] rounded-3xl justify-start items-center gap-2.5 flex">
          <div className="opacity-70 text-black text-sm font-medium font-['Outfit'] leading-tight">
            {data?.children?.length} file{data?.children?.length > 1 ? "s" : ""}
          </div>
        </div>
      )}
      <div className="self-stretch justify-end items-center gap-1.5 flex">
        {!isFolder && data?.type !== "youtubelink" && (
          <Button variant="link" onClick={handleDownload}>
            <DownloadIcon className="w-6 h-6" />
          </Button>
        )}
        <Menu
          onMove={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowMoveFileDialog(true);
          }}
          onDelete={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowDeleteConfirm(true);
          }}
          onRename={(e) => {
            e.stopPropagation();
            e.preventDefault();
            setShowRenameDialog(true);
          }}
          onShare={(e) => {
            e.stopPropagation();
            e.preventDefault();
            shareFolder(data, true);
          }}
          onUnshare={(e) => {
            e.stopPropagation();
            e.preventDefault();
            shareFolder(data, false);
          }}
          onCopyUrl={(e) => {
            e.stopPropagation();
            e.preventDefault();
            copyDocUrlToClipboard();
          }}
          isShared={data?.isAccessibleByProviders}
        />
      </div>

      <AnimatePresence mode="wait" initial={false}>
        {showDeleteConfirm && (
          <DeleteConfirm
            onCancel={() => setShowDeleteConfirm(false)}
            name={data?.name}
            onConfirm={handleConfirm}
            loading={removingFile}
            fileType={data?.type}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {showMoveFileDialog && (
          <MoveFileDialog
            onClose={() => setShowMoveFileDialog(false)}
            document={data}
          />
        )}
      </AnimatePresence>

      <AnimatePresence mode="wait" initial={false}>
        {showRenameDialog && (
          <RenameFolderDialog
            onClose={() => setShowRenameDialog(false)}
            data={data}
            open={showRenameDialog}
          />
        )}
      </AnimatePresence>
      {renderPreview()}
    </div>);
}

const Dropdown = ({
  onMove,
  onDelete,
  onRename,
  onShare,
  onUnshare,
  onCopyUrl,
  isShared,
}) => {
  const { breadcrumb } = useFiles() as any;
  return (
    <div className="z-10 w-[110px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-3 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onMove(e);
            }}
          >
            Move file
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#212424] px-3 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onRename(e);
            }}
          >
            Rename
          </button>
        </li>

        <li>
          <button
            className="w-full text-[#212424] px-3 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();
              isShared ? onUnshare(e) : onShare(e);
            }}
          >
            {isShared ? "Unpublish" : "Publish"}
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#212424] px-3 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();
              onCopyUrl(e)
            }}
          >
            Copy URL
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#E3270E] px-3 h-10 flex items-center justify-center text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();
              onDelete(e);
            }}
          >
            Delete
          </button>
        </li>
      </ul>
    </div>
  );
};
