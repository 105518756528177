import React, { useCallback, useEffect } from "react";
import { api } from "../../../services/api";
import { getUser } from "../../../services/utils";
import { ShowToast } from "../../../services/toast";
import { fileUpload } from "../../../services/file-upload";
import { DOCUMENT_OWNER_TYPES } from "../../../constant";
import toast from "react-hot-toast";

export const FileContext = React.createContext({});

export function FileProvider({ children }) {
  const user = getUser();

  const [searchKeyword, setSearchKeyword] = React.useState<string>("");

  // loading states
  const [fileLoading, setFileLoading] = React.useState<boolean>(false);
  const [creatingFolder, setCreatingFolder] = React.useState<boolean>(false);
  const [renamingFolder, setRenamingFolder] = React.useState<boolean>(false);
  const [uploadingFiles, setUploadingFiles] = React.useState<boolean>(false);
  const [removingFile, setRemovingFile] = React.useState<boolean>(false);
  const [movingFile, setMovingFile] = React.useState<boolean>(false);

  const [fileList, setFileList] = React.useState<any>([]);
  const [currentFolder, setCurrentFolder] = React.useState<any>(null);
  const [breadcrumb, setBreadcrumb] = React.useState<any>([]);
  const [
    lastSelectedCurrentFolderBeforeSearch,
    setLastSelectedCurrentFolderBeforeSearch,
  ] = React.useState<any>(null);
  const [lastBradcrumbBeforeSearch, setLastBradcrumbBeforeSearch] =
    React.useState<any>(null);

  const moveFile = async (document: any, folder: any) => {
    try {
      const payload = {
        folderId: folder.id,
      };
      setMovingFile(true);
      const response = await api.moveDocument(document.id, payload);
      setMovingFile(false);
      if ([200, 201].includes(response.status)) {
        await fetchFiles();
        ShowToast({
          type: "success",
          message: "File Moved!",
        });
        return true;
      } else {
        ShowToast({
          type: "error",
          message: "Unable to move file, please try again",
        });
        return false;
      }
    } catch (err: any) {
      setMovingFile(false);
      ShowToast({
        type: "error",
        message: err.response.data.message,
      });
      return false;
    }
  };

  const uploadFilesToFolder = async (
    files: File[],
    folder: any,
    isFromFolderCreation = false,
    isAccessibleByProviders?: boolean
  ) => {
    try {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const fileResponse = await fileUpload(file, user?.id, "DOCUMENTS");
        const payload = {
          userId: user.id,
          name: file.name,
          ownerType: DOCUMENT_OWNER_TYPES.ADMIN,
          type: "file",
          folderId: folder ? folder.id : null,
          url: fileResponse.url,
          mimeType: file.type,
          isAccessibleByProviders,
        };
        const response = await api.createDocument(payload);
        if ([200, 201].includes(response.status)) {
        } else {
          ShowToast({
            type: "error",
            message: "Unable to upload file, please try again",
          });
        }
      }
      await fetchFiles();
      setUploadingFiles(false);
      setCreatingFolder(false);
      !isFromFolderCreation &&
        ShowToast({
          type: "success",
          message: "Files Uploaded!",
        });
    } catch (err: any) {
      setUploadingFiles(false);
      setCreatingFolder(false);
      ShowToast({
        type: "error",
        message: err.response.data.message || "Failed to upload file",
      });
    }
  };

  const uploadFiles = async (
    files: File[],
    isAccessibleByProviders?: boolean
  ) => {
    setUploadingFiles(true);
    await uploadFilesToFolder(
      files,
      currentFolder,
      false,
      isAccessibleByProviders
    );
  };

  const renameFolder = async (folderName: string, data: any) => {
    // create a folder
    const payload = {
      name: folderName,
    };
    try {
      setRenamingFolder(true);
      const response = await api.renameDocument(data.id, payload);
      if ([200, 201].includes(response.status)) {
        await fetchFiles();
        setRenamingFolder(false);

        ShowToast({
          type: "success",
          message: "Folder renamed successfully",
        });
      } else {
        setRenamingFolder(false);
        ShowToast({
          type: "error",
          message: "Unable to rename folder, please try again",
        });
      }
    } catch (err: any) {
      setRenamingFolder(false);
      ShowToast({
        type: "error",
        message: err.response.data.message || "Failed to rename folder",
      });
    }
  };

  const shareFolder = async (
    data: { id: string },
    isAccessibleByProviders: boolean
  ) => {
    // create a folder
    const payload = {
      isAccessibleByProviders,
    };
    try {
      toast.loading(
        isAccessibleByProviders
          ? "Publishing folder..."
          : "Unpublishing folder..."
      );
      const response = await api.renameDocument(data.id, payload);
      toast.dismiss();
      if ([200, 201].includes(response.status)) {
        await fetchFiles();
        setRenamingFolder(false);

        ShowToast({
          type: "success",
          message: isAccessibleByProviders
            ? "Folder published successfully"
            : "Folder unpublished successfully",
        });
      } else {
        setRenamingFolder(false);
        ShowToast({
          type: "error",
          message: `Unable to ${isAccessibleByProviders ? "publish" : "unpublish"
            } folder, please try again`,
        });
      }
    } catch (err: any) {
      toast.dismiss();
      setRenamingFolder(false);
      ShowToast({
        type: "error",
        message:
          err.response.data.message ||
          `Failed to ${isAccessibleByProviders ? "publish" : "unpublish"
          } folder`,
      });
    }
  };

  const createFolder = async (
    folderName: string,
    files: File[],
    isAccessibleByProviders?: boolean
  ) => {
    // create a folder
    const payload = {
      userId: user.id,
      name: folderName,
      ownerType: DOCUMENT_OWNER_TYPES.ADMIN,
      type: "folder",
      folderId: currentFolder ? currentFolder.id : null,
      isAccessibleByProviders,
    };
    try {
      setCreatingFolder(true);
      const response = await api.createDocument(payload);
      if ([200, 201].includes(response.status)) {
        if (files.length === 0) {
          await fetchFiles();
          setUploadingFiles(false);
          setCreatingFolder(false);
        } else {
          await uploadFilesToFolder(
            files,
            { ...response.data },
            true,
            isAccessibleByProviders
          );
        }
        ShowToast({
          type: "success",
          message: "Folder created successfully",
        });
      } else {
        setCreatingFolder(false);
        ShowToast({
          type: "error",
          message: "Unable to create folder, please try again",
        });
      }
    } catch (err: any) {
      setCreatingFolder(false);
      ShowToast({
        type: "error",
        message: err.response.data.message || "Failed to create folder",
      });
    }
  };

  const fetchFiles = useCallback(
    (shouldShowFileLoading = false) => {
      if (shouldShowFileLoading) {
        setFileLoading(true);
      }
      api
        .getDocuments(user.id)
        .then((res) => {
          setFileList(res.data);
          setFileLoading(false);
        })
        .catch((err) => {
          setFileLoading(false);
          ShowToast({
            type: "error",
            message: err.response.data.message,
          });
        })
        .finally(() => {
          setFileLoading(false);
        });
    },
    [user.id]
  );

  const onFolderSelect = (folder?: any) => {
    // manage breadcrumb
    // when folder is clicked, add it to breadcrumb
    // we are showing clickable folder names in breadcrumb
    // so that user can navigate back to that folder
    if (folder) {
      const newBreadcrumb = [...breadcrumb, folder];
      setBreadcrumb(newBreadcrumb);
      setCurrentFolder(folder);
    }
  };

  const onBreadcrumbClick = (index: number) => {
    // when breadcrumb is clicked, remove all folders after that index
    // also set current folder to that folder
    const newBreadcrumb = breadcrumb.slice(0, index + 1);
    setCurrentFolder(newBreadcrumb[index]);
    setBreadcrumb(newBreadcrumb);
  };

  const deleteDocument = async (document: any) => {
    setRemovingFile(true);
    try {
      const response = await api.deleteDocument(document.id);
      if ([200, 201].includes(response.status)) {
        setRemovingFile(false);
        ShowToast({
          type: "success",
          message: "File Deleted!",
        });
        if (currentFolder) {
          const newChildren = currentFolder.children.filter(
            (file: any) => file.id !== document.id
          );
          setCurrentFolder({
            ...currentFolder,
            children: newChildren,
          });
        } else {
          const newFiles = fileList.filter(
            (file: any) => file.id !== document.id
          );
          setFileList(newFiles);
        }
        fetchFiles();
        return;
      }
    } catch (err: any) {
      setRemovingFile(false);
      ShowToast({
        type: "error",
        message: err.response.data.message,
      });
    }
  };

  const onSearch = (keyword: string) => {
    setSearchKeyword(keyword);
    if (!keyword) {
      // if search keyword is empty then reset the search
      setSearchKeyword("");
      setCurrentFolder(lastSelectedCurrentFolderBeforeSearch);
      setLastSelectedCurrentFolderBeforeSearch(null);
      if (lastBradcrumbBeforeSearch) {
        setBreadcrumb(lastBradcrumbBeforeSearch);
      }
      setLastBradcrumbBeforeSearch(null);
    } else {
      // if search keyword is present then save the current folder
      // so that when search is reset then we can go back to that folder
      // set only if last selected folder is not set
      if (!lastSelectedCurrentFolderBeforeSearch) {
        setLastSelectedCurrentFolderBeforeSearch(currentFolder);
        setCurrentFolder(null);
      }
      if (!lastBradcrumbBeforeSearch) {
        setLastBradcrumbBeforeSearch(breadcrumb);
        setBreadcrumb([]);
      }
    }
  };

  const getFileList = React.useCallback(() => {
    let files = JSON.parse(JSON.stringify([...fileList]));
    if (currentFolder) {
      files = currentFolder.children;
    } else if (searchKeyword) {
      // If search keyword is present then filter files based on search keyword deep search
      let searchResult: any = [];
      const deepSearch = (folder: any) => {
        if (folder.children) {
          folder.children.forEach((file: any) => {
            if (file.name.toLowerCase().includes(searchKeyword.toLowerCase())) {
              searchResult.push(file);
            }
            if (file.type === "folder") {
              deepSearch(file);
            }
          });
        }
      };
      deepSearch({ children: fileList });
      files = searchResult;
    }

    files = files.sort((a, b) => a.name.localeCompare(b.name))

    // put folders first and then files
    files = [
      ...files.filter((file: any) => file.type === "folder"),
      ...files.filter((file: any) => file.type === "file" || file.type === "youtubelink"),
    ];
    return files;
  }, [currentFolder, fileList, searchKeyword]);

  useEffect(() => {
    // if current folder is set and fileList is updated then update current folder
    // we have nested folders so needs to go recursively to find the current folder
    if (currentFolder) {
      const findFolder = (folder: any) => {
        if (folder.id === currentFolder.id) {
          setCurrentFolder(folder);
          return;
        }
        if (folder.children) {
          folder.children.forEach((child: any) => {
            if (child.id === currentFolder.id) {
              setCurrentFolder(child);
              return;
            }
            if (child.children) {
              findFolder(child);
            }
          });
        }
      };
      findFolder({ children: fileList });
    }
  }, [fileList, currentFolder]);

  useEffect(() => {
    if (user?.id) {
      fetchFiles(true);
    }
  }, [user?.id]);

  const values = {
    fileLoading,
    setFileLoading,
    creatingFolder,
    setCreatingFolder,
    setRenamingFolder,
    renamingFolder,
    renameFolder,
    removingFile,
    setRemovingFile,
    files: getFileList(),
    allFiles: fileList,
    setFileList,
    fetchFiles,
    currentFolder,
    setCurrentFolder,
    onFolderSelect,
    deleteDocument,
    searchKeyword,
    setSearchKeyword,
    onSearch,
    onBreadcrumbClick,
    setBreadcrumb,
    breadcrumb,
    createFolder,
    uploadFiles,
    uploadingFiles,
    moveFile,
    movingFile,
    shareFolder,
  };

  return <FileContext.Provider value={values}>{children}</FileContext.Provider>;
}

export function useFiles() {
  const context = React.useContext(FileContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
