import * as React from "react";
import { ReactComponent as CloseIcon } from "../assets/icons/x.svg";

/**Type of Input-Props */
export type Props = {
  /**Emits index */
  onRemove: Function;
  /**Array of chips */
  chips: string[];
  /**Emits value */
  onSubmit: Function;
  readOnly?: boolean;
  placeholder?: string;
};

export function ChipInput({
  onRemove,
  chips,
  onSubmit,
  readOnly,
  placeholder,
}: Props) {
  const formControlRef = React.useRef<any>();
  /**Called when user clicks on remove icon.
   * And in turn, onRemove of props called, wih index passed as argument.
   */
  const removeChip = (index: number) => {
    onRemove(index);
  };

  return (
    /**The main container div */
    <div className={`flex items-center`}>
      <div className="flex flex-wrap items-center gap-1">
        {/* Each chip is bootstrap's col */}
        {chips.map((chip, index) => (
          <div
            key={index}
            className="flex items-center bg-[#E8F3F2] rounded-full text-black px-5 py-2 text-base font-light gap-1"
          >
            {chip}
            <button onClick={() => removeChip(index)}>
              <CloseIcon className={"w-4 h-4 [&>path]:stroke-[#2A2B2F]"} />
            </button>
          </div>
        ))}
        <div>
          {/* The input, from which value is read and chip is added accordingly */}
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              onSubmit(formControlRef?.current?.value);
              formControlRef.current.value = "";
            }}
            onBlur={(e: any) => {
              e.preventDefault();
              onSubmit(formControlRef?.current?.value);
              formControlRef.current.value = "";
            }}
            onChange={(e: any) => {
              // on space or comma in chipInput, chip is added
              // get chipInput value
              const value = formControlRef?.current?.value;
              if (value.includes(",")) {
                // cut the last comma or space
                onSubmit(value.slice(0, -1));
                formControlRef.current.value = "";
              }
            }}
            noValidate
          >
            <input
              ref={formControlRef}
              name="chipInput"
              placeholder={placeholder}
              aria-label="Chip Input"
              className="text-sm w-full border-none !outline-none"
              readOnly={readOnly}
            />
          </form>
        </div>
      </div>
    </div>
  );
}
