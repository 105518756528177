import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import { ReactComponent as More } from "../../../assets/icons/more.svg";
import { useEffect, useState } from "react";
import moment from "moment";
import Modal from "../../../components/Modal";
import { api } from "../../../services/api";
import UIPopover from "../../../components/Popover";
import { DeactivatePartialSignupConfirm } from "./components/DeactivatePartialSignupConfirm";
import { RepromptPartialSignupConfirm } from "./components/RepromptPartialSignupConfirm";

const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

export type PartialFamilyUser = {
  id: string;
  guardianFirstName: string;
  guardianLastName: string;
  providerName: string;
  mobile: string;
  email: string;
  createdAt: string;
}

export function PartialSignUpFamilyList({ isOpen, onClose, onRefresh }) {
  const [partialSignUps, setPartialSignUps] = useState<Array<PartialFamilyUser>>([]);
  const [filters, setFilters] = useState({ keyword: "" });
  const [sortField, setSortField] = useState<String | undefined>()
  const [sortASC, setSortASC] = useState(false)

  const [userToDeactivate, setUserToDeactivate] = useState<PartialFamilyUser | undefined>(undefined);
  const [userToReprompt, setUserToReprompt] = useState<PartialFamilyUser | undefined>(undefined);

  const refreshList = () => {
    api.getPartialFamilySignUps().then((data) => {
      setPartialSignUps(data);
    });
  }

  useEffect(() => {
    refreshList();
  }, [])

  const filteredUsers = partialSignUps?.filter((user) => {
    if (filters.keyword) {
      return (
        user.guardianFirstName?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.guardianLastName?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.mobile?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.email?.toLowerCase().includes(filters.keyword.toLowerCase())
      );
    }
    return true;
  });

  filteredUsers.sort((a, b) => {
    if (sortField === 'parentGuardianName') {
      const guardianA = `${a.guardianFirstName} ${a.guardianLastName}`;
      const guardianB = `${b.guardianFirstName} ${b.guardianLastName}`;
      return guardianA.localeCompare(guardianB) * (sortASC ? 1 : -1)
    }
    if (sortField === 'phoneNumber' && a.mobile && b.mobile) {
      return (sortASC ? (a.mobile < b.mobile) : (b.mobile < a.mobile)) ? -1 : 1;
    }
    if (sortField === 'email' && a.email && b.email) {
      return (sortASC ? (a.email < b.email) : (b.email < a.email)) ? -1 : 1;
    }

    if ((!sortField || sortField === 'createdAt') && a.createdAt && b.createdAt) {
      return (sortASC ? (a.createdAt < b.createdAt) : (b.createdAt < a.createdAt)) ? -1 : 1;
    }

    if (sortField === 'provider' && a.providerName && b.providerName) {
      return (sortASC ? (a.providerName < b.providerName) : (b.providerName < a.providerName)) ? -1 : 1;
    }


    return 0;
  })

  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortASC(false)
      } else {
        setSortASC(true)
        setSortField(undefined)
      }
    } else {
      setSortField(field)
      setSortASC(true)
    }
  }

  const headers = [
    { value: "parentGuardianName", label: "Parent/Guardian Name" },
    { value: "phoneNumber", label: "Phone Number" },
    { value: "email", label: "Email" },
    { value: "provider", label: "Desired Provider" },
    { value: "createdAt", label: "Created At" },
  ]

  return (
    <Modal isOpen={isOpen} handleModal={onClose}>
      <LoadAnimatedContainer className="max-w-[1116px] mx-auto">
        <div className="text-black text-3xl font-bold mb-5">
          Partial Family Sign Ups
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => setFilters({ keyword: e.target.value })}
                />
              </div>
            </div>

            <div className="flex gap-2 items-center"></div>
          </div>
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <colgroup>
                {/* <col style={{ width: "17%" }} />
                <col style={{ width: "9%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "2%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "10%" }} /> */}
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  {headers.map((header, i) => (<td key={i} className={i === 0 ? "rounded-l pl-5" : ""}>
                    <div className="flex items-center cursor-pointer text-nowrap" onClick={sortToggleClick(header.value)}>
                      {header.label}
                      {sortField === header.value && <div>
                        {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                      </div>}
                    </div>
                  </td>))}
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {filteredUsers?.map((user) => (
                  <tr
                    key={user.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <div className="flex items-center gap-2.5">
                            <div className="w-10 h-10 rounded-full">
                              <div
                                className={`uppercase w-10 h-10 rounded-full flex justify-center items-center text-2xl text-primary bg-secondary`}
                              >
                                {user?.guardianFirstName?.[0] || ""}
                              </div>
                            </div>
                            <div className="flex gap-1 items-center">
                              <h5 className="flex-1 max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis">
                                {user?.guardianFirstName}{" "}
                                {user?.guardianLastName}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="subscription">
                      <div className="flex items-center gap-2">
                        {user.mobile}
                      </div>
                    </td>
                    <td className="account-manager">{user?.email}</td>
                    <td>
                      {user.providerName}
                    </td>
                    <td>
                      {moment.utc(user.createdAt).local().format("MMM DD,YYYY")}
                    </td>

                    <td onClick={stopPropagate} className="pr-5">
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                        positions={["bottom", "top", "left", "right"]}
                      >
                        {(close) => (
                          <PartialFamilyOptionsDropdown
                            onDeactivate={() => {
                              close();
                              setUserToDeactivate(user);
                            }}
                            onResendContinueSignupEmail={() => {
                              close();
                              setUserToReprompt(user);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>


                  </tr>
                ))}
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>

        {userToDeactivate &&
          <DeactivatePartialSignupConfirm
            user={userToDeactivate}
            onCancel={() => {
              setUserToDeactivate(undefined);
            }}
            onSuccess={() => {
              refreshList();
              setUserToDeactivate(undefined);
            }} />
        }
        {userToReprompt &&
          <RepromptPartialSignupConfirm
            user={userToReprompt}
            onCancel={() => {
              setUserToReprompt(undefined);
            }}
            onSuccess={() => {
              setUserToReprompt(undefined);
            }} />
        }

      </LoadAnimatedContainer>
    </Modal>
  );
}

const PartialFamilyOptionsDropdown = ({ onDeactivate, onResendContinueSignupEmail }) => {
  return (
    <div className="z-10 w-[200px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();
              onDeactivate();
            }}
          >
            Deactivate
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#212424] px-2 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();
              onResendContinueSignupEmail();
            }}
          >
            Resend Prompt Email
          </button>
        </li>
      </ul>
    </div>
  );
};
