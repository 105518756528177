import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/right-chevron.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import { ReactComponent as More } from "../../../assets/icons/more.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { useRbtCandidates } from "../context/RbtCandidatesContext";
import { useState } from "react";
import moment from "moment";
import { Button } from "../../../components/Button";
import { Loading } from "../../../components/Loading/Loading";
import { rbtCandidateStatusToSentenceCase, RbtCandidate, RbtCandidateStatus, rbtCandidateStatusColor } from "../RbtCandidate.types";
import { buildURI } from "../../../services/csv-generator";
import { ShowToast } from "../../../services/toast";
import { RbtCandidateModal } from "../modals/RbtCandidateModal";
import UIPopover from "../../../components/Popover";
import { DeleteRbtCandidateConfirm } from "../modals/CancelConfirmRbtCandidate";
import { RbtAssignModal } from "../modals/RbtAssignModal";
import { SelectInput } from "../../../components/SelectInput";
import { zipCodes } from "../../rbtRequests/modals/zip_codes";



export function RbtCandidateList() {
  const { rbtCandidates, refreshCandidates } = useRbtCandidates();
  const [candidateProfile, setCandidateProfile] = useState<RbtCandidate>();
  const [candidateAssign, setCandidateAssign] = useState<RbtCandidate>();
  const [showCreateNew, setShowCreateNew] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false)
  const [sortField, setSortField] = useState<String | undefined>()
  const [sortASC, setSortASC] = useState(false)
  const [deleteCandidateId, setDeleteCandidate] = useState<RbtCandidate | undefined>()
  const [stateFilter, setStateFilter] = useState('')
  const [searchFilter, setSearchFilter] = useState('')


  const sortedCandidates = rbtCandidates.sort((a, b) => {
    if (sortField === 'fullName' && a.firstName && b.firstName) {
      return `${b.firstName}${b.lastName}`.localeCompare(`${a.firstName}${a.lastName}`) * (sortASC ? 1 : -1)
    }

    if (sortField === 'assignedBCBA') {
      const firstVal = a.assignedRequest ? `${a.assignedRequest.owner?.firstName}${a.assignedRequest.owner?.lastName}` : 'zzzzzz'
      const secondVal = b.assignedRequest ? `${b.assignedRequest.owner?.firstName}${b.assignedRequest.owner?.lastName}` : 'zzzzzz'

      return secondVal.localeCompare(firstVal) * (sortASC ? 1 : -1)
    }

    if ((!sortField || sortField === 'createdAt') && a.createdAt && b.createdAt) {
      const aDate = a.createdAt ?? new Date('1990-01-01');
      const bDate = b.createdAt ?? new Date('1990-01-01');
      return (new Date(aDate).getTime() < new Date(bDate).getTime() ? -1 : 1) * (sortASC ? 1 : -1)
    }

    if (sortField === 'assignedDate') {
      const aDate = a.assignedDate ?? new Date('1990-01-01');
      const bDate = b.assignedDate ?? new Date('1990-01-01');
      return (new Date(aDate).getTime() < new Date(bDate).getTime() ? -1 : 1) * (sortASC ? 1 : -1)
    }

    if (sortField === 'reviewDeadline') {
      const aDate = a.reviewDeadline ?? new Date('1990-01-01');
      const bDate = b.reviewDeadline ?? new Date('1990-01-01');
      return (new Date(aDate).getTime() < new Date(bDate).getTime() ? -1 : 1) * (sortASC ? 1 : -1)
    }

    if (sortField === 'status' && a.status && b.status) {
      const keys = Object.values(RbtCandidateStatus);
      return ((keys.indexOf(a.status) < keys.indexOf(b.status)) ? -1 : 1) * (sortASC ? 1 : -1)
    }
    return 0;
  }).filter(candidate => {
    if (!searchFilter || searchFilter.length === 0) return true;
    if (`${candidate.firstName} ${candidate?.lastName}`?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    if (`${candidate?.assignedRequest?.owner?.firstName} ${candidate?.assignedRequest?.owner?.lastName}`?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    if (candidate?.assignedRequest?.friendlyId?.includes(searchFilter)) return true;
    if (candidate?.assignedRequest?.clientZipCode?.includes(searchFilter)) return true;
    return false
  })

  const allRequestsStateData = sortedCandidates?.filter(candidate => candidate.isActive && candidate?.assignedRequest?.clientZipCode)?.map((candidate) =>
  ({
    value: zipCodes[candidate?.assignedRequest?.clientZipCode ?? '']?.state_short,
    label: zipCodes[candidate?.assignedRequest?.clientZipCode ?? '']?.state
  }));
  const statesShown = allRequestsStateData?.filter((state, index) => state.value && allRequestsStateData.findIndex(s => s.value === state.value) === index);


  const downloadList = async () => {
    try {
      setIsDownloading(true);
      const data = sortedCandidates?.filter(c => c.isActive)?.map((candidate) => {
        return [
          `${candidate.firstName} ${candidate.lastName}`,
          candidate.assignedRequest ? `${candidate.assignedRequest?.owner?.firstName} ${candidate.assignedRequest?.owner?.lastName} (#${candidate.assignedRequest?.friendlyId})` : `-`,
          candidate.assignedDate ? moment.utc(candidate.assignedDate).local().format("MMM DD,YYYY") : '-',
          candidate.reviewDeadline ? moment.utc(candidate.reviewDeadline).local().format("MMM DD,YYYY") : '-',
          candidate?.status ?? '-'
        ];
      });
      data.splice(0, 0, [
        "Candidate Name",
        "Assigned BCBA",
        "Assigned Date",
        "Review Deadline",
        "Status",
      ]);
      const build = buildURI(data, true, undefined, undefined, undefined);
      // download csv file
      const a = document.createElement("a");
      a.href = build;
      a.download = `rbt-candidates-${moment().format()}.csv`;
      a.click();
      // remove the a tag
      a.remove();
      setIsDownloading(false);
    } catch (error: any) {
      setIsDownloading(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortField(undefined)
        setSortASC(false)
      } else {
        setSortASC(true)
      }
    } else {
      setSortField(field)
      setSortASC(false)
    }
  }

  const headers = [
    { value: "fullName", label: "Candidate Name" },
    { value: "assignedBCBA", label: "Assigned BCBA" },
    { value: "clientZipCode", label: "Client Zip Code" },
    { value: "createdAt", label: "Created Date" },
    { value: "assignedDate", label: "Assigned Date" },
    { value: "reviewDeadline", label: "Review Deadline" },
    { value: "status", label: "Status" },
  ]

  return (
    <>
      <LoadAnimatedContainer>
        <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
          <div className="text-black text-3xl font-bold">RBT Candidates</div>
          <div className="flex justify-center"></div>

          <Button
            variant="primary"
            className="!rounded-full mt-0"
            onClick={() => setShowCreateNew(true)}
          >
            <PlusIcon className="[&_path]:stroke-white" />
            Add RBT Candidate
          </Button>
        </div>

        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => setSearchFilter(e.target.value || '')}
                />
              </div>
              <SelectInput
                placeholder="Filter by client state"
                disabledOptionText="Filter by client state"
                data={stateFilter === '' ? statesShown : [{ label: "No Filter", value: '' }, ...statesShown]}
                value={stateFilter ?? ''}
                onChange={(e) => setStateFilter(e.target.value)}
                className="mx-3 p-[12px] border-[#D9D9E7] text-[gray]"
              />
            </div>


            <div className="flex gap-2 items-center ml-auto">

              <Button
                variant="link"
                onClick={() => downloadList()}
                disabled={isDownloading}
                className="border rounded-md !px-[14px] !py-2 border-primary hover:opacity-80 [&>svg]:!w-6 [&>svg]:!h-6 [&>svg]:!mr-0"
              >
                {isDownloading ? <Loading /> : <DownloadIcon />}
              </Button>
            </div>
          </div>
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <colgroup>
                <col style={{ width: "19.8%" }} />
                <col style={{ width: "18.8%" }} />
                <col style={{ width: "12.8%" }} />
                <col style={{ width: "10.8%" }} />
                <col style={{ width: "10.8%" }} />
                <col style={{ width: "12.8%" }} />
                <col style={{ width: "16.8%" }} />
                <col style={{ width: "16.8%" }} />
                <col style={{ width: "18.8%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  {headers.map((header, i) => (<td key={i} className={i === 0 ? "rounded-l pl-5" : ""}>
                    <div className="flex items-center cursor-pointer" onClick={sortToggleClick(header.value)}>
                      {header.label}
                      {sortField === header.value && <div>
                        {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                      </div>}
                    </div>
                  </td>))}
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {sortedCandidates?.filter(request => !stateFilter || (request?.assignedRequest?.clientZipCode && zipCodes[request?.assignedRequest?.clientZipCode]?.state_short === stateFilter))?.filter(c => c.isActive)?.map((candidate) => (
                  <tr
                    key={candidate.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setCandidateProfile(candidate)}
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <div className="flex items-center gap-2.5">
                            <div className="inline-block overflow-none">
                              {candidate?.firstName} {candidate?.lastName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="" onClick={(e) => {
                      e.stopPropagation();
                      setCandidateAssign(candidate)
                    }}>
                      <div className="flex items-center gap-2">
                        <div className="font-bold inline-block">{candidate.assignedRequest ?
                          <>
                            <div className="font-bold inline-block">{candidate.assignedRequest?.owner?.firstName} {candidate.assignedRequest?.owner?.lastName}</div>
                            <div className="ml-1 font-normal inline-block">#{candidate.assignedRequest?.friendlyId}</div>
                          </>
                          : <span>
                            -
                            <div className="font-normal ml-1 text-primary inline-block">(Assign)</div>
                          </span>}

                        </div>
                      </div>
                    </td>
                    <td onClick={(e) => {
                      e.stopPropagation();
                      setCandidateAssign(candidate)
                    }} >
                      {candidate?.assignedRequest?.clientZipCode ?? '-'} {candidate?.assignedRequest?.clientZipCode && zipCodes?.[candidate?.assignedRequest?.clientZipCode]?.city ? `(${zipCodes?.[candidate?.assignedRequest?.clientZipCode]?.city},${zipCodes?.[candidate?.assignedRequest?.clientZipCode]?.state_short})` : ''}
                    </td>
                    <td onClick={(e) => {
                      e.stopPropagation();
                      setCandidateAssign(candidate)
                    }} className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
                      {candidate.createdAt ? moment.utc(candidate.createdAt).local().format("MMM DD,YYYY") : '-'}
                    </td>
                    <td onClick={(e) => {
                      e.stopPropagation();
                      setCandidateAssign(candidate)
                    }} className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
                      {candidate.assignedDate ? moment.utc(candidate.assignedDate).local().format("MMM DD,YYYY") : '-'}
                    </td>
                    <td onClick={(e) => {
                      e.stopPropagation();
                      setCandidateAssign(candidate)
                    }}>
                      {candidate.reviewDeadline ? moment.utc(candidate.reviewDeadline).local().format("MMM DD,YYYY") : '-'}
                    </td>
                    <td onClick={(e) => {
                      e.stopPropagation();
                      setCandidateAssign(candidate)
                    }}>
                      <div style={{ color: rbtCandidateStatusColor(candidate.status || RbtCandidateStatus.UNASSIGNED) }}>
                        {candidate.status && rbtCandidateStatusToSentenceCase(candidate.status)}
                      </div>
                    </td>
                    <td onClick={(e: React.SyntheticEvent) => e.stopPropagation()} className="pr-5">
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                        positions={["bottom", "top", "left", "right"]}
                      >
                        {(close) => (
                          <CandidateOptionsDropdown
                            onView={() => {
                              close();
                              setCandidateProfile(candidate)
                            }}
                            onAssign={() => {
                              close();
                              setCandidateAssign(candidate)
                            }}
                            onDelete={async () => {
                              close();
                              setDeleteCandidate(candidate);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {(candidateProfile || showCreateNew) && <RbtCandidateModal rbtCandidate={showCreateNew ? undefined : candidateProfile}
          onClose={() => {
            setCandidateProfile(undefined);
            setShowCreateNew(false);
            refreshCandidates();
          }}
          onAssignCandidate={() => {
            setCandidateAssign(candidateProfile);
            setCandidateProfile(undefined);
          }}
        />}
        {candidateAssign && <RbtAssignModal rbtCandidate={candidateAssign} onClose={
          () => {
            setCandidateAssign(undefined);
            refreshCandidates();
          }}
          onViewProfile={() => {
            setCandidateProfile(candidateAssign);
            setCandidateAssign(undefined);
          }}
        />}
      </LoadAnimatedContainer >
      {deleteCandidateId && <DeleteRbtCandidateConfirm
        onCancel={() => setDeleteCandidate(undefined)}
        onDelete={() => {
          setCandidateProfile(undefined);
          setDeleteCandidate(undefined)
          refreshCandidates();
        }}
        candidate={deleteCandidateId} />}
    </>
  );
}

export const CandidateOptionsDropdown = ({ onView, onAssign, onDelete }) => {
  return (
    <div className="z-10 w-[150px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base text-left rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();
              onView();
            }}
          >
            View Profile
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base text-left rounded-[10px] hover:bg-whiteButtonHover whitespace-nowrap"
            onClick={(e) => {
              e.stopPropagation();
              onAssign();
            }}
          >
            Assign Request
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base text-left rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();
              onDelete();
            }}
          >
            Delete
          </button>
        </li>
      </ul>
    </div>
  );
};