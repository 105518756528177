import { useState } from "react";
import { ShowToast } from "../../../services/toast";
import { PopupModal } from "../../../components/PopupModal";
import { Button } from "../../../components/Button";
import { api } from "../../../services/api";
import { ClientReferral } from "../ClientReferrals.types";

export function ArchiveAuthRequestConfirm({
  onCancel,
  onArchive,
  referral,
}: {
  onCancel: () => void;
  onArchive: () => void;
  referral: ClientReferral;
}) {
  const [loading, setLoading] = useState(false);
  const performAction = async () => {
    try {
      setLoading(true);
      const response = await api.updateClientReferral({ ...referral, isActive: false });
      if ([200, 201].includes(response.status)) {
        setLoading(false);
        ShowToast({
          type: "success",
          message: "Client Referral Archived!",
        });
      }
    } catch (error: any) {
      setLoading(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
    onArchive()
  };

  return (
    <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="flex flex-col w-full">
        <p className="text-center text-xl text-black/80 font-medium">
          Are you sure you want to archive Client Referral ({referral.clientFirstName} {referral.clientLastName}) ?
        </p>
        <div className="flex gap-3 items-center w-full mt-8">
          <Button
            onClick={onCancel}
            variant="outline"
            className="!rounded-full w-full flex-1"
            disabled={loading}
          >
            Go Back
          </Button>
          <Button
            onClick={performAction}
            className="!rounded-full w-full flex-1 bg-red-500 text-white"
            variant="primary"
            disabled={loading}
            loading={loading}
          >
            Archive
          </Button>
        </div>
      </div>
    </PopupModal>
  );
}
