import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserProvider } from "./auth/context/user-context";
import { Menu } from "../components/Menu/Menu";

function MainApp() {
  return (
    <div className="flex-1 flex bg-secondary">
      <>
        <Menu />
        <div
          className={`flex-1 max-h-screen overflow-auto p-2 md:p-4 lg:p-8 !pt-[105px] lg:!pt-8`}
        >
          <Outlet />
        </div>
      </>
    </div>
  );
}

export function Main() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem("token");
    const userData = JSON.parse(localStorage.getItem("user") || "{}");
    if (!token || !userData?.id) {
      navigate("/login");
      setIsLoggedIn(false);
    } else {
      setIsLoggedIn(true);
      if (location.pathname === "/") {
        navigate("/dashboard");
      }
    }
  }, [location]);

  if (!isLoggedIn) return null;

  return (
    <UserProvider>
      <MainApp />
    </UserProvider>
  );
}
