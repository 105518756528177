import { RbtCandidate } from "../rbtCandidates/RbtCandidate.types";

export enum DAY {
  MON = "MON",
  TUES = "TUES",
  WED = "WED",
  THUR = "THUR",
  FRI = "FRI",
  SAT = "SAT",
  SUN = "SUN",
}

export type Shift = {
  day: "MON" | "TUES" | "WED" | "THUR" | "FRI" | "SAT" | "SUN";
  startTime: Date;
  endTime: Date;
  timeZone: string;
};

export enum RbtRequestStatus {
  REVIEW = "Reviewing",
  // IN_PROGRESS = "In Progress",
  IN_PROCESS = "In Process",
  ACTION_REQUIRED = "Action Required",
  COMPLETED = "Completed",
  CANCELED = "Canceled",
}

export const getRbtRequestStatusByValue = (enumValue) => {
  let keys = Object.keys(RbtRequestStatus).filter((x) => RbtRequestStatus[x] === enumValue);
  return keys.length > 0 ? keys[0] : undefined;
};

export const rbtRequestStatusColor = (status: RbtRequestStatus) => {
  switch (status) {
    case RbtRequestStatus.REVIEW:
      return "grey";
    case RbtRequestStatus.IN_PROCESS:
      // case RbtRequestStatus.IN_PROGRESS:
      return "rgb(59 130 246)"; //blue
    case RbtRequestStatus.ACTION_REQUIRED:
      return "rgb(249 115 22)"; //orange
    case RbtRequestStatus.COMPLETED:
      return "green";
    case RbtRequestStatus.CANCELED:
      return "rgb(239 68 68)"; //red
  }
};

type User = {
  firstName: string;
  lastName: string;
  id: string;
  isActive: boolean;
};

export type RbtRequest = {
  pointPerson?: string;
  id?: string;
  friendlyId?: string;
  ownerId: string;
  owner?: User;
  status?: RbtRequestStatus;
  clientZipCode: string;
  clientLocationComments: string;
  qualifications: "RBT_ONLY" | "BT_OR_RBT";
  weeklyHoursNeeded: number | "";
  shiftsNeeded: Array<Shift>;
  comments?: string;
  desiredStart: Date;
  hireReason?: "NEW" | "REPLACE";
  rbtCandidates: Array<RbtCandidate>;
  createdAt?: Date;
  updatedAt?: Date;
  isActive?: boolean;
};
