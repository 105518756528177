import moment from "moment";
import { DateSeparator } from "./DateSeparator";
import { FileMessage } from "./FileMessage";
import "react-quill/dist/quill.snow.css";
import { ImageView } from "../../../components/Image";

export function MessageView({
  isMe,
  message,
  prevMessageDate,
  senderProfileImg,
  senderName,
  isPreviousSenderSame,
  onProfileImageClick,
}: {
  isMe: boolean;
  message: any;
  prevMessageDate: string;
  senderProfileImg: string;
  senderName: string;
  isPreviousSenderSame: boolean;
  onProfileImageClick: () => void;
}) {
  if (isMe) {
    return (
      <>
        <DateSeparator date={message.createdAt} prevDate={prevMessageDate} />

        <div className="max-w-[90%] md:max-w-[70%] self-end gap-2.5 flex flex-col">
          <div className="justify-end flex">
            <span className="opacity-50 text-right text-black text-sm font-medium font-['Outfit']">
              {moment(message.createdAt).local().format("hh:mm A")}
            </span>
          </div>
          {message.contentType === "text" ? (
            <div
              dangerouslySetInnerHTML={{
                __html: message.content,
              }}
              style={{ wordBreak: "break-word" }}
              className="ql-editor message-content rounded-lg p-4 bg-emerald-50 text-black text-base font-light leading-normal"
            ></div>
          ) : (
            <FileMessage
              contentType={message.contentType}
              content={message.content}
              isMe={true}
            />
          )}
        </div>
      </>
    );
  }
  return (
    <>
      <DateSeparator date={message.createdAt} prevDate={prevMessageDate} />

      <div className="max-w-[90%] md:max-w-[70%] self-start flex-col justify-start items-start gap-1.5 inline-flex">
        <div className="pl-9 justify-start items-start gap-2.5 inline-flex">
          <div className="text-right text-black/50 text-sm font-medium font-['Outfit'] leading-tight">
            {moment(message.createdAt).local().format("hh:mm A")}
          </div>
        </div>
        <div className="self-stretch justify-start items-start gap-2 inline-flex">
          {!isPreviousSenderSame ? (
            <button onClick={onProfileImageClick}>
              {senderProfileImg ? (
                <ImageView
                  className="w-7 h-7 rounded-lg object-cover"
                  src={senderProfileImg}
                  loading="lazy"
                  alt="sender"
                />
              ) : (
                <div
                  className={`uppercase w-7 h-7 rounded-lg flex justify-center items-center text-base text-primary bg-secondary`}
                >
                  {senderName?.[0] || ""}
                </div>
              )}
            </button>
          ) : (
            <div className="min-w-7 min-h-7 w-7 h-7" />
          )}
          {message.contentType === "text" ? (
            <div
              dangerouslySetInnerHTML={{
                __html: message.content,
              }}
              style={{ wordBreak: "break-word" }}
              className="ql-editor message-content text-black text-base font-light leading-normal p-4 rounded-lg border border-zinc-300"
            ></div>
          ) : (
            <FileMessage
              contentType={message.contentType}
              content={message.content}
              isMe={false}
            />
          )}
        </div>
      </div>
    </>
  );
}
