import { PopupModal } from "../../components/PopupModal";
import { ReactComponent as Email } from "../../assets/icons/email.svg";
import { ReactComponent as Phone } from "../../assets/icons/call.svg";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";
import { Button } from "../../components/Button";
import { useMemo, useState } from "react";
import { ProviderDeleteConfirm } from "../dashboard/components/ProviderDeleteConfirm";
import { RequestStatusChangeSelection } from "../Library/components/RequestStatusChangeSelection";
import { ShowToast } from "../../services/toast";
import { Loading } from "../../components/Loading/Loading";
import { api } from "../../services/api";
import { TextArea } from "../../components/Textarea";
import { zipCodes } from "../rbtRequests/modals/zip_codes";

export const statusColorCode = {
  NotTouched: {
    bg: "bg-[#F0F0F0]",
    fg: "text-[#5D5D5D]",
    text: "Not Touched",
    icon: "[&_path]:stroke-[#5D5D5D]",
  },
  InProgress: {
    bg: "bg-[#FFE5BF]",
    fg: "text-[#A57311]",
    text: "In-Progress",
    icon: "[&_path]:stroke-[#A57311]",
  },
  NotResponding: {
    bg: "bg-[#FFD9D9]",
    fg: "text-[#A51111]",
    text: "Not Responding",
    icon: "[&_path]:stroke-[#A51111]",
  },
};

export function NewRequestModal({
  onClose,
  provider,
  onMarkAsAdded,
  onRefresh,
  onUpdate,
}) {
  const [notes, setNotes] = useState<string | undefined>(undefined);
  const [saving, setSaving] = useState(false);
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [edit, setEdit] = useState(false);
  const [changingStatus, setChangingStatus] = useState(false);

  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code])
  const [zipCity, zipState] = useMemo(() => {
    return isZipValid(provider.addressZip) ? [zipCodes[provider.addressZip]?.city, zipCodes[provider.addressZip]?.state] : [undefined, undefined];
  }, [provider.addressZip]);

  const handleChangeStatus = async (status) => {
    // api call
    try {
      setChangingStatus(true);
      const response = await api.updateUserStatus(provider.id, {
        pendingStatus: status,
        pendingStatusNotes:
          notes !== undefined ? notes : provider?.pendingStatusNotes,
      });
      if ([200, 201].includes(response.status)) {
        setChangingStatus(false);
        if (status !== "NotTouched") {
          setNotes(provider?.pendingStatusNotes || "");
          setEdit(true);
        }
        onUpdate({ ...provider, pendingStatus: status });
        ShowToast({
          message: "Status Changed!",
          type: "success",
        });
      }
    } catch (error: any) {
      ShowToast({
        message: error?.response?.data?.message || "Something went wrong",
        type: "error",
      });
    }
  };

  const handleSave = async (e) => {
    // api call
    if (notes === provider?.pendingStatusNotes) {
      setEdit(false);
      return;
    }
    try {
      setSaving(true);
      const response = await api.updateUserStatus(provider.id, {
        pendingStatusNotes: notes,
      });
      if ([200, 201].includes(response.status)) {
        setSaving(false);
        setEdit(false);
        onUpdate({ ...provider, pendingStatusNotes: notes });
        ShowToast({
          message: "Note Saved!",
          type: "success",
        });
      }
    } catch (error: any) {
      ShowToast({
        message: error?.response?.data?.message || "Something went wrong",
        type: "error",
      });
    }
  };
  return (
    <PopupModal
      onClose={onClose}
      containerClassName="w-[98%] md:w-[552px] md:max-w-[552px] mx-auto"
    >
      <div className="flex-col justify-start items-start gap-7 flex max-h-[calc(100vh-100px)] overflow-y-auto">
        <div className="self-stretch justify-between items-start flex">
          <div className="flex-col justify-start items-start gap-3 flex">
            <div className="text-black text-xl font-semibold ">
              {provider?.firstName} {provider?.lastName}
            </div>
            <div className="justify-start flex-wrap items-center gap-1.5 flex">
              <div className="px-2.5 py-1.5 bg-[#E3F2F1] rounded-md justify-start items-center gap-2 flex">
                <Email className="w-5 h-5" />
                <div className="opacity-60 text-black text-base font-normal ">
                  {provider?.email}
                </div>
              </div>
              <div className="px-2.5 py-1.5 bg-[#E3F2F1] rounded-md justify-start items-center gap-2 flex">
                <Phone className="w-5 h-5" />
                <div className="opacity-60 text-black text-base font-normal ">
                  +1{provider?.mobile}
                </div>
              </div>
            </div>
          </div>
          <button onClick={onClose}>
            <CloseIcon />
          </button>
        </div>
        <div className="justify-start items-center gap-4 flex">
          <Button
            onClick={() => onMarkAsAdded(provider)}
            variant="link"
            className="!text-base text-primary !font-medium "
          >
            Mark as added
          </Button>
          <div className="border-l border-l-[#D7D7D7] h-4"></div>

          <Button
            variant="link"
            className="py-[14px] px-6 !rounded-full"
            onClick={() => setRemoveUserModal(provider)}
          >
            <div className="!text-[#BB2828] !text-sm font-medium hover:!text-black">
              Remove request
            </div>
          </Button>
        </div>
        <div className="w-full">
          <div className="justify-start items-center gap-1.5 flex mb-2">
            <div className="text-black/60 text-base">Status:</div>
            <>
              <RequestStatusChangeSelection
                value={provider?.pendingStatus || "NotTouched"}
                onChange={handleChangeStatus}
              />
              {changingStatus && <Loading />}
            </>
          </div>
          {provider?.pendingStatus === "NotTouched" &&
            !provider?.pendingStatusNotes ? (
            ""
          ) : (
            <>
              {!edit ? (
                <>
                  <div className="w-full p-[18px] bg-[#E1F9F1]/80 rounded-md flex justify-between items-center gap-2">
                    <span className="text-base">
                      Note: {provider?.pendingStatusNotes || "-"}
                    </span>
                    <Button
                      variant="link"
                      onClick={() => {
                        setEdit(true);
                        if (provider?.pendingStatusNotes)
                          setNotes(provider?.pendingStatusNotes);
                      }}
                    >
                      Edit
                    </Button>
                  </div>
                </>
              ) : (
                <div className="relative">
                  <TextArea
                    placeholder="Add a note"
                    onChange={(e) => setNotes(e.target.value)}
                    value={notes}
                    className="pr-[20px]"
                    rows={1}
                  />
                  <Button
                    className="absolute right-[18px] top-5"
                    variant="link"
                    disabled={!notes || saving}
                    loading={saving}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
        <div className="flex items-center w-full">
          <div className="flex-[3] flex-col justify-start items-start gap-2.5 flex">
            <div className="opacity-60 text-black text-base font-normal ">
              Your qualifications:
            </div>
            <div className="opacity-80 text-black text-base font-medium ">
              {provider?.profession?.join(", ")}
            </div>
          </div>
          <div className="flex-[2] flex-col justify-start items-start gap-2.5 flex">
            <div className="opacity-60 text-black text-base font-normal ">
              Location:
            </div>
            <div className="opacity-80 text-black text-base font-medium ">
              {(provider?.addressZip && zipCity && zipState && `${zipCity}, ${zipState} (${provider?.addressZip})`) || "Not provided"}
            </div>
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-2.5 flex">
          <div className="opacity-60 text-black text-base font-normal ">
            Focus areas:
          </div>
          {provider?.focusAreas?.filter((f) => f).length > 0 && (
            <ul className="list-disc marker:text-primary ml-5 mt-2">
              {provider?.focusAreas
                ?.filter((f) => f)
                ?.map((f, index) => (
                  <li
                    key={index}
                    className="opacity-80 text-black font-medium text-base mb-2"
                  >
                    {f}
                  </li>
                ))}
            </ul>
          )}
          {provider?.focusAreas?.filter((f) => f).length === 0 && (
            <div className="opacity-80 text-black text-base font-medium">
              Not provided
            </div>
          )}
        </div>
        <div className="flex-col justify-start items-start gap-2.5 flex">
          <div className="opacity-60 text-black text-base font-normal ">
            How can Headstart help you?
          </div>
          <div className="opacity-80 text-black text-base font-medium font-['Outfit'] leading-normal">
            {provider?.joinReason || "Not provided"}
          </div>
        </div>
        <div className="flex-col justify-start items-start gap-2.5 flex">
          <div className="opacity-60 text-black text-base font-normal ">
            How did you hear about Headstart?
          </div>
          <div className="opacity-80 text-black text-base font-medium font-['Outfit'] leading-normal">
            {provider?.source || "Not provided"}
          </div>
        </div>
      </div>

      {removeUserModal && (
        <ProviderDeleteConfirm
          user={removeUserModal}
          onCancel={() => setRemoveUserModal(null)}
          onSuccess={() => {
            setRemoveUserModal(null);
            onRefresh();
          }}
        />
      )}
    </PopupModal>
  );
}

export const RequestOptionsDropdown = ({
  onClose,
  onMarkAsAdded,
  onRemove,
}) => {
  return (
    <div className="z-10 w-[150px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-20">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-3 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onMarkAsAdded();
            }}
          >
            Mark As Added
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#E3270E] px-3 h-10 flex items-center justify-center text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onRemove(e);
            }}
          >
            Remove
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#212424] px-3 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onClose();
            }}
          >
            Close
          </button>
        </li>
      </ul>
    </div>
  );
};
