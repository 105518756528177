import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/left-arrow.svg";
import { useState } from "react";
import moment from "moment";
import { ImageView } from "../../../components/Image";

export function ProviderList({
  clientUser,
  clientProviders
}: { clientUser: any; clientProviders: any[] }) {
  const [filters, updateFilters] = useState<{
    keyword?: string;
    sort_by?: string;
    sort_order?: string;
  }>({
    keyword: "",
    sort_by: "",
    sort_order: "",
  });

  const sortedProviders = clientProviders.sort((a, b) => new Date(b.scheduledDate).getTime() - new Date(a.scheduledDate).getTime());

  const filteredProviders = (filters?.keyword?.trim() === "") ? sortedProviders : sortedProviders.filter((provider) => {
    if (filters.keyword && provider.firstName.toLowerCase().includes(filters.keyword.toLowerCase())) {
      return provider;
    }
    if (filters.keyword && provider.lastName.toLowerCase().includes(filters.keyword.toLowerCase())) {
      return provider;
    }
    if (filters.keyword && `${provider.firstName} ${provider.lastName}`.toLowerCase().includes(filters.keyword.toLowerCase())) {
      return provider;
    }
  });

  if (clientProviders?.length === 0) {
    return (<div className="text-center">
      Client has not reached out to any providers yet.
    </div>)
  }


  return (
    <LoadAnimatedContainer>
      <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-0">
        <div className="flex items-center justify-between ">
          <div className="flex items-center gap-2">
            <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
              <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
              <input
                type="text"
                placeholder="Search"
                className="outline-none "
                onChange={(e) => updateFilters({ keyword: e.target.value })}
              />
            </div>
          </div>

          <div className="flex gap-2 items-center"></div>
        </div>
        <div className={`mt-3 text-[14px] h-[calc(100vh-450px)] overflow-auto`}>
          <table className="w-full">
            <colgroup>
              <col style={{ width: "70%" }} />
              <col style={{ width: "30%" }} />
            </colgroup>
            <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
              <tr className="sticky top-0 z-[1]">
                <td className="rounded-l pl-5">
                  <div className="flex items-center">
                    Provider Name
                    {filters.sort_by === "firstName" ? (
                      <ArrowRight
                        stroke="#2A2B2F"
                        className={`sort-icon ${filters.sort_order === "asc"
                          ? "rotate-[270deg]"
                          : "rotate-[90deg]"
                          }`}
                      />
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="flex items-center">
                    Reached Out On
                  </div>
                </td>

                <td className="rounded-r"></td>
              </tr>
            </thead>
            <tbody>
              {filteredProviders?.map((user) => (
                <tr
                  key={user.id}
                  className="hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                >
                  <td className="name pl-5">
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2.5">
                          <div className="w-[58px] h-[58px] rounded-full">
                            {user.profileImg ? (
                              <ImageView
                                alt="user"
                                src={user.profileImg}
                                className="w-[58px] h-[58px] rounded-full object-cover"
                                loading="lazy"
                              />
                            ) : (
                              <div
                                className={`uppercase w-[58px] h-[58px] rounded-full flex justify-center items-center text-2xl text-primary bg-secondary`}
                              >
                                {user?.firstName?.[0] || ""}
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col gap-[2px] text-sm">
                            <h5 className="flex-1 max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis">
                              {user?.firstName} {user?.lastName}
                            </h5>
                            <div>{user?.mobile}</div>
                            <div>{user?.email}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {user?.scheduledDate && moment.utc(user?.scheduledDate).local().format("MMM DD,YYYY")}
                  </td>
                </tr>
              ))}
              {/* {isLoading && <ProviderListLoadingSkeleton />} */}
              <div className="h-5"></div>
            </tbody>
          </table>
          {/* {isLoading === false && !users?.length && (
            <div className="flex items-center h-[calc(100vh-300px)]">
              <EmptyStates text="No session found!" />
            </div>
          )} */}
        </div>
      </div>
    </LoadAnimatedContainer>
  );
}
