import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/left-arrow.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import { ReactComponent as More } from "../../../assets/icons/more.svg";
import useFamilies from "../hooks/useFamilies";
import UIPopover from "../../../components/Popover";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { EmptyStates } from "../../../components/EmptyStates";
import Modal from "../../../components/Modal";
import toast from "react-hot-toast";
import { api } from "../../../services/api";
import { ShowToast } from "../../../services/toast";
import { DeleteConfirm } from "./components/DeleteConfirm";
import { ImageView } from "../../../components/Image";
import { zipCodes } from "../../rbtRequests/modals/zip_codes";
import { PartialFamilyUser } from "./PartialFamilyList";

function FamilyListLoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr className="hover:bg-gray-100 [&_td]:py-3 border-b" key={i}>
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <div className="flex items-center gap-2.5">
                  <div className="w-10 h-10 rounded-full bg-gray-300 animate-pulse transition duration-50"></div>
                  <div className="w-28 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
                </div>
              </div>
            </div>
          </td>
          <td className="account-executive">
            <div className="w-12 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="subscription">
            <div className="w-24 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-manager">
            <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-executive">
            <div className="w-16 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="pr-5">
            <div className="w-6 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
        </tr>
      ))}
    </>
  );
}

export function BlockedFamilyList({ isOpen, onClose, onRefresh }) {
  const { users, total, isLoading, updateFilters, loadMore, refetch } =
    useFamilies();
  const [removeConfirmModal, setRemoveCategoryModal] = useState<any>(null);
  const [filters, setFilters] = useState<any>({});
  const [sortField, setSortField] = useState<String | undefined>()
  const [sortASC, setSortASC] = useState(false)
  const [partialSignUps, setPartialSignUps] = useState<PartialFamilyUser[]>([]);

  const refreshInactivePartialFamilySignUps = () => {
    api.getInactivePartialFamilySignUps().then((data) => {
      setPartialSignUps(data);
    });
  }

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleUnBlock = async (user) => {
    try {
      toast.loading("Activating...");
      const response = (user.firstName ?
        await api.unBlockUser(user?.id, { isActive: true })
        : await api.reactivatePartialFamilySignUp(user?.id));
      toast.dismiss();
      if ([200, 201].includes(response.status)) {
        ShowToast({
          type: "success",
          message: user.firstName ? 'User Activated!' : 'Partial Sign Up Restored',
        });
        refetch();
        onRefresh();
        refreshInactivePartialFamilySignUps();
      } else {
        ShowToast({
          type: "error",
          message:
            response?.data?.message || "Something went wrong, Please try again",
        });
      }
    } catch (error: any) {
      toast.dismiss();
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const filteredUsers = (isLoading ? [] : [...partialSignUps.map(partialUser => ({
    ...partialUser,
    joinedOn: partialUser.createdAt,
  })), ...users])?.filter((user) => {
    if (filters.keyword) {
      return (
        user.guardianFirstName?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.guardianLastName?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.mobile?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.email?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.firstName?.toLowerCase().includes(filters.keyword.toLowerCase()) ||
        user.lastName?.toLowerCase().includes(filters.keyword.toLowerCase())
      );
    }
    return true;
  });

  filteredUsers.sort((a, b) => {
    if (sortField === 'parentGuardianName') {
      const guardianA = `${a.guardianFirstName} ${a.guardianLastName}`;
      const guardianB = `${b.guardianFirstName} ${b.guardianLastName}`;
      return guardianA.localeCompare(guardianB) * (sortASC ? 1 : -1)
    }
    if (sortField === 'phoneNumber' && a.mobile && b.mobile) {
      return (sortASC ? (a.mobile < b.mobile) : (b.mobile < a.mobile)) ? -1 : 1;
    }
    if (sortField === 'email' && a.email && b.email) {
      return (sortASC ? (a.email < b.email) : (b.email < a.email)) ? -1 : 1;
    }
    if (sortField === 'clientName') {
      const nameA = `${a.firstName} ${a.lastName}`;
      const nameB = `${b.firstName} ${b.lastName}`;
      return nameA.localeCompare(nameB) * (sortASC ? 1 : -1)
    }

    if (sortField === 'clientCity') {
      const cityA = zipCodes[a.zipCode]?.city;
      const cityB = zipCodes[b.zipCode]?.city;
      if (cityA && !cityB) return -1;
      if (cityB && !cityA) return 1;
      if (!cityA && !cityB) return 0;
      return cityA.localeCompare(cityB) * (sortASC ? 1 : -1)
    }
    if (sortField === 'clientState') {
      const stateA = zipCodes[a.zipCode]?.state;
      const stateB = zipCodes[b.zipCode]?.state;
      if (stateA && !stateB) return -1;
      if (stateB && !stateA) return 1;
      if (!stateA && !stateB) return 0;
      return stateA.localeCompare(stateB) * (sortASC ? 1 : -1)
    }

    if ((sortField === 'joinedOn') && a.joinedOn && b.joinedOn) {
      return (sortASC ? (a.joinedOn < b.joinedOn) : (b.joinedOn < a.joinedOn)) ? -1 : 1;
    }

    if ((sortField === 'lastActive')) {
      const lastActiveA = a.lastActive;
      const lastActiveB = b.lastActive;
      if (lastActiveA && !lastActiveB) return -1;
      if (lastActiveB && !lastActiveA) return 1;
      if (!lastActiveA && !lastActiveB) return 0;

      return (sortASC ? (lastActiveA < lastActiveB) : (lastActiveB < lastActiveA)) ? -1 : 1;
    }

    if (!sortField && a.joinedOn && b.joinedOn) {
      return (sortASC ? (a.joinedOn < b.joinedOn) : (b.joinedOn < a.joinedOn)) ? 1 : -1;
    }

    return 0;
  })

  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortASC(false)
      } else {
        setSortASC(true)
        setSortField(undefined)
      }
    } else {
      setSortField(field)
      setSortASC(true)
    }
  }

  const headers = [
    { value: "clientName", label: "Client Name" },
    { value: "phoneNumber", label: "Phone Number" },
    { value: "email", label: "Email" },
    { value: "parentGuardianName", label: "Parent/Guardian Name" },
    { value: "clientCity", label: "City" },
    { value: "clientState", label: "State" },
    { value: "joinedOn", label: "Joined On" },
    // { value: "lastActive", label: "Last Active" },
  ]

  useEffect(() => {
    updateFilters({ showActive: false });
    refreshInactivePartialFamilySignUps();
  }, []);

  return (
    <Modal isOpen={isOpen} handleModal={onClose}>
      <LoadAnimatedContainer className="max-w-[1116px] mx-auto">
        <div className="text-black text-3xl font-bold mb-5">
          Deactivated Clients
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => setFilters({ keyword: e.target.value })}
                />
              </div>
            </div>

            <div className="flex gap-2 items-center"></div>
          </div>
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "9%" }} />
                <col style={{ width: "22%" }} />
                <col style={{ width: "14%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "6%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  {headers.map((header, i) => (<td key={i} className={i === 0 ? "rounded-l pl-5" : ""}>
                    <div className="flex items-center cursor-pointer text-nowrap" onClick={sortToggleClick(header.value)}>
                      {header.label}
                      {sortField === header.value && <div>
                        {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                      </div>}
                    </div>
                  </td>))}
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {filteredUsers?.map((user) => (
                  <tr
                    key={user.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <div className="flex items-center gap-2.5">
                            <div className="w-10 h-10 rounded-full">
                              {user.profileImg ? (
                                <ImageView
                                  alt="user"
                                  src={user.profileImg}
                                  className="w-full h-full rounded-full object-cover"
                                  loading="lazy"
                                />
                              ) : (
                                <div
                                  className={`uppercase w-10 h-10 rounded-full flex justify-center items-center text-2xl text-primary bg-secondary`}
                                >
                                  {user?.firstName?.[0] || ""}
                                </div>
                              )}
                            </div>
                            <div className="flex gap-1 items-center">
                              <h5 className="flex-1 max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis">
                                {user?.firstName}{" "}
                                {user?.lastName}
                                {!user?.firstName && '[Partial Signup]'}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="subscription">
                      <div className="flex items-center gap-2">
                        {user.mobile}
                      </div>
                    </td>
                    <td className="account-manager">{user?.email}</td>
                    <td>
                      {user.guardianFirstName} {user.guardianLastName}
                    </td>
                    <td>
                      {zipCodes[user.zipCode]?.city ?? '-'}
                    </td>
                    <td>
                      {zipCodes[user.zipCode]?.state_short ?? "-"}
                    </td>
                    <td>
                      {moment.utc(user.joinedOn).local().format("MMM DD,YYYY")}
                    </td>

                    <td onClick={stopPropagate} className="pr-5">
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                        positions={["bottom", "top", "left", "right"]}
                      >
                        {(close) => (
                          <FamilyOptionsDropdown
                            onRemove={() => {
                              close();
                              setRemoveCategoryModal(user);
                            }}
                            onUnBlock={() => {
                              close();
                              handleUnBlock(user);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {isLoading && <FamilyListLoadingSkeleton />}
                <div className="h-5"></div>
              </tbody>
            </table>
            {isLoading === false && !users?.length && (
              <div className="flex items-center h-[calc(100vh-300px)]">
                <EmptyStates text="No family found!" />
              </div>
            )}
          </div>
        </div>

        {removeConfirmModal && (
          <DeleteConfirm
            onCancel={() => setRemoveCategoryModal(null)}
            onSuccess={() => {
              refetch(removeConfirmModal.id, true);
              onRefresh();
              setRemoveCategoryModal(null);
            }}
            user={removeConfirmModal}
          />
        )}
      </LoadAnimatedContainer>
    </Modal>
  );
}

export const FamilyOptionsDropdown = ({ onUnBlock, onRemove }) => {
  return (
    <div className="z-10 w-[150px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onUnBlock();
            }}
          >
            Activate
          </button>
        </li>
      </ul>
    </div>
  );
};
