import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as PinIcon } from "../../../assets/icons/pin.svg";
import { Button } from "../../../components/Button";
import { useEffect, useMemo, useRef, useState } from "react";
import { TextInput } from "../../../components/TextInput";
import { TextArea } from "../../../components/Textarea";
import { ShowToast } from "../../../services/toast";
import { fileUpload } from "../../../services/file-upload";
import { api } from "../../../services/api";
import {
  getUser
} from "../../../services/utils";
import Modal from "../../../components/Modal";
import { FileUploader } from "react-drag-drop-files";
import { DOCUMENT_OWNER_TYPES } from "../../../constant";
import RichEditor from "../../../components/RichTextEditor";

export function AddBulletinModal({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (val?: boolean) => void;
}) {
  const user = getUser();
  const [errors, setErrors] = useState({} as any);
  const [saving, setSaving] = useState(false);

  const [showRightFeatureSection, setShowRightFeatureSection] = useState(false)
  const [rightFeatureFile, setRightFeatureFile] = useState<File | undefined>(undefined);
  const [rightFeatureYoutubeLink, setRightFeatureYoutubeLink] = useState<string>("");
  const [rightFeatureYoutubeLinkForSubmit, setRightFeatureYoutubeLinkForSubmit] = useState<string>("");
  const [rightFeatureYouTubeMode, setRightFeatureYouTubeMode] = useState(false)


  const [files, setFiles] = useState<File[]>([]);
  const [youtubeFiles, setYoutubeFiles] = useState<Array<{ name: string; url: string; }>>([]);
  const [youtubeLinkMode, setYoutubeLinkMode] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState<string>("");
  const [videoName, setVideoName] = useState<string>("");
  const [form, setForm] = useState<any>({
    title: "",
    description: ""
  });

  const handleFileChange = (fileList: File[]) => {
    setFiles([...files, ...fileList].filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.name === value.name)
    )
    )
  };

  const handleRightFeatureFileChange = (file: File) => {
    setRightFeatureFile(file)
  };


  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setForm((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleAddYoutube = () => {
    let toAdd = true
    const linkRegex = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/gmi;
    if (!linkRegex.test(youtubeLink)) {
      setErrors({ ...errors, youtubeLink: "Invalid YouTube Link" })
      toAdd = false
    }
    if (videoName.trim().length === 0) {
      setErrors({ ...errors, videoName: "Video Name Required" })
      toAdd = false
    }
    if (toAdd) {
      setYoutubeFiles([...youtubeFiles, { name: videoName, url: youtubeLink }].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.url === value.url)
      ))
    }
  }

  const handleAddRightFeatureYoutube = () => {
    const linkRegex = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/gmi;
    if (!linkRegex.test(rightFeatureYoutubeLink)) {
      setErrors({ ...errors, rightFeatureYoutubeLink: "Invalid YouTube Link" })
    } else {
      setRightFeatureYoutubeLinkForSubmit(rightFeatureYoutubeLink)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    let rightFeatureFileUrl;
    try {
      if (!rightFeatureYouTubeMode && rightFeatureFile) {
        setSaving(true);
        rightFeatureFileUrl = (await fileUpload(rightFeatureFile, user?.id, "BULLETIN")).url
      }
    } catch (err: any) {
      ShowToast({
        type: "error",
        message: err.response.data.message || "Failed to upload file",
      });
      setSaving(false);
    }

    const payload: any = {
      userId: user.id,
      title: form.title,
      description: form.description,
      isActive: true,
      previewImg: rightFeatureYouTubeMode ? rightFeatureYoutubeLinkForSubmit : (rightFeatureFile && rightFeatureFileUrl)
    };
    try {
      setErrors({});
      setSaving(true);

      const createBulletinRes = await api.createBulletin(payload);
      setSaving(false);
      if ([200, 201].includes(createBulletinRes.status)) {
        if (files?.length > 0) {
          setSaving(true);
          try {
            for (let i = 0; i < files.length; i++) {
              const file = files[i];
              const fileResponse = await fileUpload(file, user?.id, "BULLETIN");
              const payload = {
                userId: user.id,
                name: file.name,
                ownerType: DOCUMENT_OWNER_TYPES.ADMIN,
                type: "file",
                folderId: null,
                url: fileResponse.url,
                mimeType: file.type,
                isAccessibleByProviders: true,
                bulletinId: createBulletinRes?.data?.id
              };
              const response = await api.createDocument(payload);
              if ([200, 201].includes(response.status)) {
                setSaving(false)
              } else {
                ShowToast({
                  type: "error",
                  message: "Unable to upload file, please try again",
                });
              }
            }

          } catch (err: any) {
            setErrors({ ...errors, files: err.response.data.message || "Failed to upload file" })
            ShowToast({
              type: "error",
              message: err.response.data.message || "Failed to upload file",
            });
          }
        }

        if (youtubeFiles.length > 0) {
          await Promise.all(youtubeFiles.map(async youtube => {
            await api.createDocument({
              userId: user.id,
              name: youtube.name,
              ownerType: DOCUMENT_OWNER_TYPES.ADMIN,
              type: "youtubelink",
              folderId: null,
              url: youtube.url,
              isAccessibleByProviders: true,
              bulletinId: createBulletinRes?.data?.id
            })
          }))
        }

        ShowToast({
          type: "success",
          message: "Bulletin Created!",
        });
        onClose(true);
      }
    } catch (error: any) {
      setSaving(false);
      return false;
    }
  };

  useEffect(() => {
    if (isOpen) {
      setForm({
        userType: "P",
        isAvailable: false,
        focusAreas: [],
        acceptedInsurances: [],
        practiceAreas: [],
        addressState: "",
      });
    }
  }, [isOpen]);

  const disabled = useMemo(() => {
    return (
      !form.title ||
      !form.description ||
      form?.title?.length === 0 ||
      form?.description?.length === 0
    );
  }, [form]);

  const youtubeAddDisabled = youtubeLink.length === 0 || videoName.length === 0;

  return (
    <Modal isOpen={isOpen} handleModal={() => onClose()}>
      <div className="max-w-[820px] mx-auto flex flex-col mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-black text-2xl font-bold">Add Bulletin</span>
          </div>
          <Button
            variant="primary"
            className="!text-sm !font-medium !rounded-full"
            onClick={handleSubmit}
            disabled={disabled || saving}
            loading={saving}
          >
            <CheckIcon className="[&_path]:stroke-white" />
            Add
          </Button>
        </div>
      </div>
      <div
        id="provider-scroll"
        className="max-h-[calc(100vh-150px)] lg:max-h-[calc(100vh-95px)] overflow-auto"
      >
        <div className="max-w-[820px] mx-auto flex flex-col gap-4">
          <div className="pt-flex pt-5 px-5 bg-white rounded-2xl flex-col">
            <div className="flex flex-col md:flex-row items-center justify-center gap-3 mb-5">
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">Title</label>
                <TextInput
                  name="title"
                  value={form.title}
                  onChange={handleChange}
                  placeholder="Enter your new Bulletin Title"
                  maxLength={250}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center gap-3 pb-2">
              <div className="flex-1 w-full flex flex-col gap-1.5 pb-5 relative">
                <label className="text-sm text-black/80">Description</label>
                <RichEditor
                  value={form.description}
                  onChange={(value) => {
                    setForm((prev: any) => ({ ...prev, description: value }));
                  }}
                  onSend={() => { }}
                  className="w-full"
                />
                {errors.description && (
                  <div className="absolute bottom-0 text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    {errors.description}
                  </div>
                )}
              </div>
            </div>
          </div>

          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">

            {showRightFeatureSection ? <>
              <span className="text-black/80 text-sm">Add Right Display Image/Youtube</span>

              {rightFeatureYouTubeMode ?
                <>
                  {rightFeatureYoutubeLinkForSubmit.length > 0 && <div className="inline-block">
                    Youtube Video Added: <a className="underline text-blue-500" target="_blank" href={rightFeatureYoutubeLinkForSubmit} rel="noreferrer">{rightFeatureYoutubeLinkForSubmit}</a>
                    {/* <div className="text-red-500 text-right cursor-pointer" onClick={() => setYoutubeFiles([])}>Remove Youtube Link</div> */}
                  </div>}
                  {rightFeatureYoutubeLinkForSubmit.length === 0 &&
                    <>
                      <TextInput
                        placeholder="Enter YouTube Link URL"
                        className="!p-[14px]"
                        name="youtubelink"
                        value={rightFeatureYoutubeLink}
                        onChange={(e) => setRightFeatureYoutubeLink(e.target.value)}
                      />
                      {errors.rightFeatureYoutubeLink && (
                        <div className="text-red-500 text-xs font-medium">
                          {errors.rightFeatureYoutubeLink}
                        </div>
                      )}
                      <Button
                        variant="primary"
                        className="!text-sm !font-medium !rounded-full"
                        onClick={handleAddRightFeatureYoutube}
                        disabled={rightFeatureYoutubeLink.trim().length === 0}
                        loading={saving}
                      >
                        <CheckIcon className="[&_path]:stroke-white" />
                        Add Youtube Video
                      </Button>
                    </>
                  }

                </>

                :
                <FileUploader handleChange={handleRightFeatureFileChange} name="rightFeatureFile" types={["png", "jpg", "jpeg", "gif", "webp"]}>
                  <div className="cursor-pointer p-4 h-[114px] bg-[#E8F3F2] w-full rounded-xl border border-primary justify-center items-center gap-1 flex">
                    <PinIcon className="w-5 h-5" />
                    <div className="text-primary text-base font-normal font-['Outfit'] leading-normal">
                      {rightFeatureFile
                        ? `Added: ${rightFeatureFile?.name}`
                        : "Click to add or drag and drop Image"}
                    </div>
                  </div>
                </FileUploader>

              }

              {(rightFeatureYoutubeLinkForSubmit.length > 0 || rightFeatureFile) &&
                <>
                  <div className="float-right text-red-500 text-right cursor-pointer inline-block" onClick={() => {
                    setRightFeatureFile(undefined)
                    setRightFeatureYouTubeMode(false)
                    setRightFeatureYoutubeLink("")
                    setRightFeatureYoutubeLinkForSubmit("")
                    setErrors({ ...errors, rightFeatureYoutubeLink: undefined })
                  }}>Remove</div>
                  {errors.files && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.files}
                    </div>
                  )}
                </>
              }

              {rightFeatureYouTubeMode ?
                <span className="underline cursor-pointer inline-block" onClick={() => {
                  setRightFeatureYoutubeLinkForSubmit("")
                  setRightFeatureYoutubeLink("")
                  setErrors({ ...errors, rightFeatureYoutubeLink: undefined })
                  setRightFeatureYouTubeMode(false)
                }}>Add Image</span>
                :
                <span className="underline cursor-pointer inline-block" onClick={() => {
                  setRightFeatureYouTubeMode(true)
                  setRightFeatureFile(undefined)
                }}>Add Youtube Video</span>
              }

            </> :
              <span className="underline cursor-pointer inline-block" onClick={() => setShowRightFeatureSection(true)}>Add Right Feature Image/Youtube</span>
            }

          </div>

          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <span className="text-black/80 text-sm">Add Documents/Images</span>
            <FileUploader handleChange={handleFileChange} name="file" multiple>
              <div className="cursor-pointer p-4 h-[114px] bg-[#E8F3F2] w-full rounded-xl border border-primary justify-center items-center gap-1 flex">
                <PinIcon className="w-5 h-5" />
                <div className="text-primary text-base font-normal font-['Outfit'] leading-normal">
                  {files.length > 0
                    ? `${files.length} files added: ${[...files]?.map(file => file.name)?.join(", ")}`
                    : "Click to add or drag and drop"}
                </div>
              </div>
            </FileUploader>
            <div className="float-right text-red-500 text-right cursor-pointer inline-block" onClick={() => {
              setFiles([])
              setYoutubeFiles([])
              setYoutubeLinkMode(false)
            }}>Remove All Files/Youtube</div>
            {errors.files && (
              <div className="text-red-500 text-xs font-medium">
                {errors.files}
              </div>
            )}

            {youtubeLinkMode ?
              <span className="underline cursor-pointer inline-block" onClick={() => setYoutubeLinkMode(false)}>Hide Youtube Section</span>
              :
              <span className="underline cursor-pointer inline-block" onClick={() => setYoutubeLinkMode(true)}>Add Youtube Video</span>
            }

            {youtubeLinkMode && <>
              <div className="border-b border-b-[#D4E1DD] w-full mt-5" />
              <span className="text-black/80 text-sm">Youtube Videos</span>
              {youtubeFiles.length > 0 && <>
                Youtube Videos Added: {youtubeFiles.map((f => <a className="underline text-blue-500" target="_blank" href={f.url} rel="noreferrer">{f.name}</a>))}
                <div className="text-red-500 text-right cursor-pointer" onClick={() => setYoutubeFiles([])}>Remove Youtube Links</div>
              </>}
              <TextInput
                placeholder="Video Name"
                className="!p-[14px]"
                name="folderName"
                value={videoName}
                onChange={(e) => setVideoName(e.target.value)}
              />
              {errors.youtubeLink && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.videoName}
                </div>
              )}
              <TextInput
                placeholder="Enter YouTube Link URL"
                className="!p-[14px]"
                name="youtubelink"
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)}
              />
              {errors.youtubeLink && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.youtubeLink}
                </div>
              )}
              <Button
                variant="primary"
                className="!text-sm !font-medium !rounded-full"
                onClick={handleAddYoutube}
                disabled={youtubeAddDisabled}
                loading={saving}
              >
                <CheckIcon className="[&_path]:stroke-white" />
                Add Youtube Video
              </Button>
            </>}
          </div>
        </div>
      </div>
    </Modal >
  );
}
