import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ChevronRight } from "../../../assets/icons/right-chevron.svg";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import { ReactComponent as DownloadIcon } from "../../../assets/icons/download.svg";
import { useState } from "react";
import moment from "moment";
import { Button } from "../../../components/Button";
import { Loading } from "../../../components/Loading/Loading";
import { buildURI } from "../../../services/csv-generator";
import { ShowToast } from "../../../services/toast";
import { ClientReferralModal } from "../modals/ClientReferralModal";
import Modal from "../../../components/Modal";
import { SelectInput } from "../../../components/SelectInput";
import { useClientReferrals } from "../context/ClientReferralContext";
import { ClientReferral, ClientReferralStatus, clientReferralStatusColor } from "../ClientReferrals.types";


export function ClientReferralList({ inactiveList, onClose, onRefresh }: { inactiveList?: boolean, onClose?: () => void, onRefresh?: () => void }) {
  const { clientReferrals, refreshReferrals } = useClientReferrals();
  const [referralView, setReferralView] = useState<ClientReferral>();
  const [showInactiveList, setShowInactiveList] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false)
  const [sortField, setSortField] = useState<String | undefined>()
  const [sortASC, setSortASC] = useState(false)
  const [stateFilter, setStateFilter] = useState('')
  const [searchFilter, setSearchFilter] = useState('')

  const sortedReferrals = clientReferrals?.sort((a, b) => {

    // default
    if (!sortField && a.createdAt && b.createdAt) {
      return a.createdAt < b.createdAt ? 1 : -1;
    }

    if ((sortField === 'createdAt') && a.createdAt && b.createdAt) {
      return (sortASC ? (a.createdAt < b.createdAt) : (b.createdAt < a.createdAt)) ? -1 : 1;
    }
    if (sortField === 'client' && a.clientFirstName && b.clientFirstName) {
      const aName = `${a.clientFirstName} ${a.clientLastName}`;
      const bName = `${b.clientFirstName} ${b.clientLastName}`;
      return aName.localeCompare(bName) * (sortASC ? 1 : -1)
    }
    if (sortField === 'parent' && a.parentFirstName && b.parentFirstName) {
      const aName = `${a.parentFirstName} ${a.parentLastName}`;
      const bName = `${b.parentFirstName} ${b.parentLastName}`;
      return aName.localeCompare(bName) * (sortASC ? 1 : -1)
    }
    if (sortField === 'clientState' && a.clientState && b.clientState) {
      return a.clientState.localeCompare(b.clientState) * (sortASC ? 1 : -1)
    }
    if (sortField === 'parentEmail' && a.parentEmail && b.parentEmail) {
      return a.parentEmail.localeCompare(b.parentEmail) * (sortASC ? 1 : -1)
    }
    if (sortField === 'parentPhone' && a.parentPhone && b.parentPhone) {
      return a.parentPhone.localeCompare(b.parentPhone) * (sortASC ? 1 : -1)
    }
    if (sortField === 'provider' && a.providerFirstName && b.providerFirstName) {
      const aName = `${a.providerFirstName} ${a.providerLastName}`;
      const bName = `${b.providerFirstName} ${b.providerLastName}`;
      return aName.localeCompare(bName) * (sortASC ? 1 : -1)
    }

    if (sortField === 'status' && a.status && b.status) {
      const keys = Object.values(ClientReferralStatus);
      return ((keys.indexOf(a.status) < keys.indexOf(b.status)) ? -1 : 1) * (sortASC ? 1 : -1)
    }

    return 0;
  }).filter(request => {
    if (!searchFilter || searchFilter.length === 0) return true;
    if (`${request.clientFirstName} ${request.clientLastName}`?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    if (`${request.providerFirstName} ${request.providerLastName}`?.toLowerCase().includes(searchFilter.toLowerCase())) return true;
    return false
  })

  const downloadList = async () => {
    try {
      setIsDownloading(true);
      const data = clientReferrals?.filter(r => inactiveList ? !r.isActive : r.isActive)?.map((referral) => {
        return [
          `${referral.clientFirstName} ${referral.clientLastName}`,
          `${referral.parentFirstName} ${referral.parentLastName}`,
          referral.clientState,
          referral.parentEmail,
          referral.parentPhone,
          `${referral.providerFirstName} ${referral.providerLastName}`,
          referral.providerPracticeName,
          referral.providerEmail,
          referral.providerPhone,
          (referral.cdeUrl && referral.cdeUrl.length > 0) ? referral.cdeUrl : 'No Diagnostic Evaluation (DE) provided.',
          moment.utc(referral.createdAt).local().format("MMM DD,YYYY"),
          referral.status
        ];
      });

      data.splice(0, 0, [
        "Client",
        "Parent / Guardian",
        "Client State",
        "Parent / Guardian Email",
        "Parent / Guardian Phone",
        "Referring Provider",
        "Provider Practice / Group",
        "Provider Email",
        "Provider Phone",
        "Diagnostic Evaluation",
        "Submitted Date",
        "Status",
      ]);
      const build = buildURI(data, true, undefined, undefined, undefined);
      // download csv file
      const a = document.createElement("a");
      a.href = build;
      a.download = `${inactiveList ? 'achieved-' : ''}client-referrals-${moment().format()}.csv`;
      a.click();
      // remove the a tag
      a.remove();
      setIsDownloading(false);
    } catch (error: any) {
      setIsDownloading(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortASC(false)
      } else {
        setSortField(undefined)
        setSortASC(true)
      }
    } else {
      setSortField(field)
      setSortASC(true)
    }
  }

  const headers = [
    { value: "client", label: "Client" },
    { value: "parent", label: "Parent / Guardian" },
    { value: "clientState", label: "Client State" },
    { value: "parentEmail", label: "Parent / Guardian Email" },
    { value: "parentPhone", label: "Parent / Guardian Phone" },

    { value: "provider", label: "Referring Provider" },
    // { value: "providerGroup", label: "Provider Practice / Group" },
    // { value: "providerEmail", label: "Provider Email" },
    // { value: "providerPhone", label: "Provider Phone" },

    { value: "createdAt", label: "Submitted Date" },
    { value: "status", label: "Status" },
  ]

  const allReferralsStates =
    Array.from(new Set<string>(sortedReferrals?.filter(referral => referral.isActive && referral.clientState)
      ?.map((request) => request.clientState)))
      .sort((a, b) => a.localeCompare(b))
      ?.map((state) => ({ label: state, value: state }));


  const page = <LoadAnimatedContainer>
    <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
      <div className="text-black text-3xl font-bold">{inactiveList ? "Archived" : "Manage"} Client Referrals</div>
      <div className="flex justify-center"></div>
    </div>
    <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-2">
          <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
            <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
            <input
              type="text"
              placeholder="Search"
              className="outline-none"
              onChange={(e) => setSearchFilter(e.target.value || '')}
            />
          </div>
          <SelectInput
            placeholder="Filter by state"
            disabledOptionText="Filter by state"
            data={stateFilter === '' ? allReferralsStates : [{ label: "No Filter", value: '' }, ...allReferralsStates]}
            value={stateFilter ?? ''}
            onChange={(e) => setStateFilter(e.target.value)}
            className="mx-3 p-[12px] border-[#D9D9E7] text-[gray]"
          />
        </div>


        <div className="flex gap-2 items-center ml-auto">

          {!inactiveList &&
            <button
              onClick={() => setShowInactiveList(true)}
              className="pl-4 pr-3.5 py-3 group rounded-full justify-start items-center flex"
            >
              <div className="text-black text-base group-hover:text-black/80">
                View Archived Referrals
              </div>
              <ChevronRight className="w-5 h-5 [&_path]:stroke-primary" />
            </button>
          }
          <Button
            variant="link"
            onClick={() => downloadList()}
            disabled={isDownloading}
            className="border rounded-md !px-[14px] !py-2 border-primary hover:opacity-80 [&>svg]:!w-6 [&>svg]:!h-6 [&>svg]:!mr-0"
          >
            {isDownloading ? <Loading /> : <DownloadIcon />}
          </Button>
        </div>
      </div>
      <div
        className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
      >
        <table className="w-full">
          <colgroup>
            <col style={{ width: "14%" }} />
            <col style={{ width: "14%" }} />
            <col style={{ width: "8%" }} />
            <col style={{ width: "19%" }} />
            <col style={{ width: "14%" }} />
            <col style={{ width: "14%" }} />
            {/* <col style={{ width: "11%" }} /> */}
            <col style={{ width: "10%" }} />
            <col style={{ width: "10%" }} />
          </colgroup>
          <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
            <tr className="sticky top-0 z-[1]">
              {headers.map((header, i) => (<td key={i} className={i === 0 ? "rounded-l pl-5" : ""}>
                <div className="flex items-center cursor-pointer" onClick={sortToggleClick(header.value)}>
                  {header.label}
                  {sortField === header.value && <div>
                    {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                  </div>}
                </div>
              </td>))}
            </tr>
          </thead>
          <tbody>
            {sortedReferrals?.filter(referral => !stateFilter || (referral.clientState === stateFilter))?.filter(r => inactiveList ? !r.isActive : r.isActive)?.map((referral) => (
              <tr
                key={referral.id}
                className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                onClick={() => setReferralView(referral)}
              >
                <td className="name pl-5">
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2.5">
                        <div className="inline-block overflow-none">
                          {referral?.clientFirstName} {referral?.clientLastName}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td className="name">
                  <div className="flex items-center gap-3">
                    <div className="flex flex-col">
                      <div className="flex items-center gap-2.5">
                        <div className="inline-block overflow-none">
                          {referral?.parentFirstName} {referral?.parentLastName}
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {referral.clientState}
                </td>
                <td className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[50px]">
                  {referral.parentEmail}
                </td>
                <td className="">
                  <div className="flex items-center gap-2">
                    {referral.parentPhone}
                  </div>
                </td>
                <td className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
                  {referral?.providerFirstName} {referral?.providerLastName}
                </td>

                {/* <td>
                  {referral.providerPracticeName}
                </td> */}
                {/* <td>
                  {referral.providerEmail}
                </td>
                <td>
                  {referral.providerPhone}
                </td> */}
                <td className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
                  {moment.utc(referral.createdAt).local().format("MMM DD,YYYY")}
                </td>
                <td>
                  <div style={{ color: clientReferralStatusColor(referral.status || ClientReferralStatus.RECEIVED) }}>
                    {referral.status}
                  </div>
                </td>
              </tr>
            ))}

          </tbody>
        </table>
      </div>
    </div>
    {referralView && <ClientReferralModal clientReferralId={referralView?.id} onClose={
      () => {
        setReferralView(undefined);
        refreshReferrals();
      }}
    />}
    {showInactiveList && (
      <ClientReferralList
        inactiveList={showInactiveList}
        onClose={() => {
          setShowInactiveList(false)
        }}
        onRefresh={refreshReferrals}
      />
    )}
  </LoadAnimatedContainer >

  return inactiveList ?
    <Modal isOpen={true} handleModal={onClose}>
      {page}
    </Modal> : page

}

