import { useRef, useState } from "react";
import { ReactComponent as Attachment } from "../../../assets/icons/attachment.svg";
import { ReactComponent as Send } from "../../../assets/icons/send.svg";
import { fileUpload } from "../../../services/file-upload";
import { getUser } from "../../../services/utils";
import { Loading } from "../../../components/Loading/Loading";
import RichEditor from "../../../components/RichTextEditor";

export function Editor({ onSend }: { onSend: (data: any) => void }) {
  const [text, setText] = useState("");
  const user = getUser();
  const attachRef = useRef<HTMLInputElement | null>(null);
  const [uploading, setUploading] = useState(false);

  const handleSend = () => {
    if (!text.trim()) return;
    if (text.replace(/<(.|\n)*?>/g, "").trim().length === 0) {
      return;
    }

    let newText = text.replace(new RegExp("<p><br></p>$"), "");
    // also remove li tags if they are empty
    newText = newText.replace(new RegExp("<li><br></li>", "g"), "");
    // also remove ol/ul tags if they are empty
    newText = newText.replace(new RegExp("<(ol|ul)><br></(ol|ul)>$"), "");
    newText = newText.replace(new RegExp("<(ol|ul)></(ol|ul)>$"), "");
    // remove empty span, div, h1-h6, blockquote, pre tags
    newText = newText.replace(new RegExp("<span><br></span>$"), "");
    newText = newText.replace(new RegExp("<div><br></div>$"), "");
    newText = newText.replace(new RegExp("<h1><br></h1>", "g"), "");
    newText = newText.replace(new RegExp("<h2><br></h2>", "g"), "");
    newText = newText.replace(new RegExp("<h3><br></h3>", "g"), "");
    newText = newText.replace(new RegExp("<h4><br></h4>", "g"), "");
    newText = newText.replace(new RegExp("<h5><br></h5>", "g"), "");
    newText = newText.replace(new RegExp("<h6><br></h6>", "g"), "");
    newText = newText.replace(
      new RegExp("<blockquote><br></blockquote>", "g"),
      ""
    );
    newText = newText.replace(new RegExp("<pre><br></pre>", "g"), "");
    onSend({
      content: newText,
      contentType: "text",
    });
    setText("");
  };

  const handleAttach = async (file: File | null) => {
    if (!file || !user?.id) return;
    try {
      setUploading(true);
      const response = await fileUpload(
        file,
        user?.id,
        "MESSAGES",
        file.name.split(".").slice(0, -1).join(".")
      );
      setUploading(false);
      onSend({ content: response.url, contentType: file?.type });
    } catch (error: any) {
      setUploading(false);
    }
  };

  return (
    <div className="w-full max-w-[100vw] relative p-1 rounded-lg justify-between items-center gap-2 flex">
      <RichEditor
        value={text}
        onChange={(value) => {
          const elements = document.getElementsByClassName("ql-editor");
          if (elements.length) {
            elements[0].scrollTop = elements[0].scrollHeight;
          }
          setText(value);
        }}
        onSend={() => {
          handleSend();
        }}
        className="w-full"
      />

      <div className="absolute bottom-3.5 right-3.5 justify-start items-start gap-1 flex">
        <input
          type="file"
          onChange={(e) =>
            handleAttach(e?.target?.files ? e.target.files?.[0] : null)
          }
          className="hidden"
          ref={(e) => (attachRef.current = e)}
          disabled={uploading}
        />
        <button
          onClick={() => attachRef.current?.click()}
          className="p-2 bg-white rounded-lg justify-start items-start gap-2.5 flex [&_svg]:!mr-0 [&_svg]:w-4 [&_svg]:h-4"
          disabled={uploading}
        >
          {uploading ? (
            <Loading />
          ) : (
            <Attachment className="w-4 h-4 relative" />
          )}
        </button>
        <button
          onClick={handleSend}
          className="p-2 bg-primary rounded-lg justify-start items-start gap-2.5 flex"
          disabled={uploading || !text?.trim()}
        >
          <Send className="w-4 h-4 relative" />
        </button>
      </div>
    </div>
  );
}
