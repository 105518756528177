import { motion } from "framer-motion";
import { ChatBox } from "./ChatBox";

const framerSidebarPanel = {
  initial: { y: "10%" },
  animate: { y: 0 },
  exit: { y: "10%" },
  transition: { duration: 0.05 },
};

export function MobileChatBox({
  containerClassName,
  onClose,
  messages,
  onSend,
  type,
  selectedContact,
  messagesLoading,
  onRemove,
  isInquiry,
  onStartChat,
  onDecline,
  selectedContactStatus,
  insuranceDetails,
  secondaryInsuranceDetails,
}: {
  containerClassName?: string;
  onClose: () => void;
  messages: any[];
  onSend: (data: any) => void;
  type: string;
  selectedContact: any;
  messagesLoading: boolean;
  onRemove: () => void;
  isInquiry?: boolean;
  onStartChat?: () => void;
  onDecline?: () => void;
  selectedContactStatus: any;
  insuranceDetails?: any;
  secondaryInsuranceDetails?: any;
}) {
  return (
    <motion.div
      {...framerSidebarPanel}
      className={`fixed z-[2] top-0 h-[100vh] left-0 right-0 flex justify-end`}
    >
      <ChatBox
        containerClassName={containerClassName}
        isMobile
        onClose={onClose}
        onSend={onSend}
        messages={messages}
        type={type}
        selectedContact={selectedContact}
        messagesLoading={messagesLoading}
        onRemove={onRemove}
        isInquiry={isInquiry}
        onStartChat={onStartChat}
        onDecline={onDecline}
        selectedContactStatus={selectedContactStatus}
        insuranceDetails={insuranceDetails}
        secondaryInsuranceDetails={secondaryInsuranceDetails}
      />
    </motion.div>
  );
}
