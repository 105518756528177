import { ReactComponent as EmailIcon } from "../../../assets/icons/email.svg";
import { ReactComponent as MoreIcon } from "../../../assets/icons/more.svg";
import { ReactComponent as PhoneIcon } from "../../../assets/icons/call.svg";
import { useState } from "react";
import moment from "moment";
import UIPopover from "../../../components/Popover";
import { ShowToast } from "../../../services/toast";
import { api } from "../../../services/api";
import {
  NewRequestModal,
  statusColorCode,
} from "../../provider/NewRequestModal";
import { ProviderDeleteConfirm } from "./ProviderDeleteConfirm";
import toast from "react-hot-toast";
import { InfoWithStatusNoteTooltip } from "../../provider/components/InfoWithStatusNoteTooltip";

export function PersonCard({ provider, refetch }) {
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);
  const [providerRequest, setProviderRequest] = useState<any>(null);

  const handleMarkAsAdded = async (provider) => {
    try {
      toast.loading("Marking as added...");
      const response = await api.updateUserStatus(provider.id, {
        isActive: true,
      });
      toast.dismiss();
      if ([200, 201].includes(response.status)) {
        await api.confirmUser(provider.id);
        ShowToast({
          type: "success",
          message: "Provider Request Accepted!",
        });
        refetch();
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (error: any) {
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  return (
    <div className="rounded-[12px] border-[#DDD] border-[1px] border-solid p-4 flex">
      <div className="grid gap-3 w-full">
        <div className="flex justify-between relative">
          <div className="md:flex grid md:gap-1 gap-2 items-center">
            <div className="text-black text-xl font-semibold leading-snug">
              {provider?.firstName} {provider?.lastName}
            </div>
          </div>

          <UIPopover
            trigger={
              <span className="block p-2 hover:bg-[#D0F3C9] rounded-[6px] transition-all">
                <MoreIcon />
              </span>
            }
          >
            {(close) => {
              return (
                <ProviderRequestOptionsDropdown
                  onMarkAsAdded={() => {
                    close();
                    handleMarkAsAdded(provider);
                  }}
                  onView={() => {
                    close();
                    setProviderRequest(provider);
                  }}
                  onRemove={() => {
                    close();
                    setRemoveUserModal(provider);
                  }}
                />
              );
            }}
          </UIPopover>
        </div>
        <div className="flex gap-1.5 items-center flex-wrap">
          <div className="w-fit px-2.5 py-1.5 bg-[#E3F2F1] rounded-md justify-start items-center gap-2 flex">
            <EmailIcon className="w-5 h-5" />
            <div
              title={provider?.email}
              className="opacity-60 text-black text-base max-w-[250px] text-ellipsis overflow-hidden whitespace-nowrap"
            >
              {provider?.email}
            </div>
          </div>
          <div className="w-fit px-2.5 py-1.5 bg-[#E3F2F1] rounded-md justify-start items-center gap-2 flex">
            <PhoneIcon className="w-5 h-5" />
            <div className="opacity-60 text-black text-base">
              +1{provider?.mobile}
            </div>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <div className="opacity-60 text-black text-base mt-[2px]">
            Received on: {moment(provider?.joinedOn).format("MMM DD, YYYY")}
          </div>
          <div
            className={`w-fit px-2.5 py-1.5 ${
              statusColorCode[provider?.pendingStatus || "NotTouched"].bg
            } rounded-lg justify-center items-center gap-1 flex`}
          >
            <div
              className={`${
                statusColorCode[provider?.pendingStatus || "NotTouched"].fg
              } text-sm`}
            >
              {statusColorCode[provider?.pendingStatus || "NotTouched"].text}
            </div>
            <InfoWithStatusNoteTooltip
              text={
                provider?.status === "NotTouched"
                  ? "No progress has been made regarding this request."
                  : provider?.pendingStatusNotes ||
                    "No progress has been made regarding this request."
              }
              color={
                statusColorCode[provider?.pendingStatus || "NotTouched"].icon
              }
            />
          </div>
        </div>
      </div>
      {removeUserModal && (
        <ProviderDeleteConfirm
          user={removeUserModal}
          onCancel={() => setRemoveUserModal(null)}
          onSuccess={() => refetch()}
        />
      )}
      {providerRequest && (
        <NewRequestModal
          onClose={() => setProviderRequest(null)}
          provider={providerRequest}
          onMarkAsAdded={() => handleMarkAsAdded(providerRequest)}
          onUpdate={(updatedData) => {
            refetch();
            setProviderRequest({ ...updatedData });
          }}
          onRefresh={() => {
            setProviderRequest(null);
            refetch();
          }}
        />
      )}
    </div>
  );
}

export const ProviderRequestOptionsDropdown = ({
  onView,
  onMarkAsAdded,
  onRemove,
}) => {
  return (
    <div className="z-10 w-[150px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="p-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-3 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onView();
            }}
          >
            View Details
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#212424] px-3 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onMarkAsAdded();
            }}
          >
            Mark As Added
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#E3270E] px-3 h-10 flex items-center justify-center text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onRemove(e);
            }}
          >
            Remove
          </button>
        </li>
      </ul>
    </div>
  );
};
