import { UnreadConvCounts } from "../../auth/context/user-context";

export const GENERAL_ADMIN_COLOR = '#E5D4C1';
export const RBT_COLOR = '#A39FE1';
export const CLIENT_ACQ_COLOR = '#DEB3E0';
export const INS_OPS_COLOR = '#FFEB9C';
export const CLIENT_CHAT_COLOR = '#FFDDE4';


export function AdminChatContactTabs({
  onTabChange,
  activeTab,
  unreadConvCounts
}: {
  activeTab: string;
  onTabChange: (tab: string) => void;
  unreadConvCounts: UnreadConvCounts;
}) {
  return (
    <>
    <div className="mb-2.5 text-base font-semibold">Providers:</div>
    <div className="p-1.5 mb-[11px] rounded-lg border border-neutral-200 bg-white justify-start items-start flex w-full gap-1">
      <button
        onClick={() => onTabChange("providers")}
        className={`h-12 flex-1 px-4 py-[13px] ${activeTab === "providers"
          ? `bg-[#E5D4C1]`
          : "hover:bg-whiteButtonHover rounded-lg"
          } rounded-md justify-center items-center gap-2.5 flex`}
          style={activeTab !== "providers" ? {borderColor: GENERAL_ADMIN_COLOR, borderWidth: '1px'} : {}}
      >
        <div className="text-black text-base font-normal font-['Outfit'] leading-none">
          General Admin
        </div>
        {unreadConvCounts.generalAdminCount > 0 && (
          <div className="w-5 h-5 p-2.5 mt-1 bg-primary rounded-3xl flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-[#FBF5E8]  text-xs font-bold ">
              {unreadConvCounts.generalAdminCount}
            </div>
          </div>
        )}
      </button>
      <button
        onClick={() => onTabChange("rbt")}
        className={`h-12 flex-1 px-4 py-[13px] ${activeTab === "rbt"
          ? `bg-[#A39FE1]`
          : "hover:bg-whiteButtonHover"
          } rounded-md justify-center items-center gap-2.5 flex`}
          style={activeTab !== "rbt" ? {borderColor: RBT_COLOR, borderWidth: '1px'} : {}}
      >
        <div className="text-black text-base font-normal font-['Outfit'] leading-none">
          RBT
        </div>
        {unreadConvCounts.rbtAdminCount > 0 && (
          <div className="w-5 h-5 p-2.5 mt-1 bg-primary rounded-3xl flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-[#FBF5E8]  text-xs font-bold ">
              {unreadConvCounts.rbtAdminCount}
            </div>
          </div>
        )}
      </button>
      <button
        onClick={() => onTabChange("client-acq")}
        className={`h-12 flex-1 px-4 py-[13px] ${activeTab === "client-acq"
          ? `bg-[#DEB3E0]`
          : "hover:bg-whiteButtonHover"
          } rounded-md justify-center items-center gap-2.5 flex`}
          style={activeTab !== "client-acq" ? {borderColor: CLIENT_ACQ_COLOR, borderWidth: '1px'} : {}}
      >
        <div className="text-black text-base font-normal font-['Outfit'] leading-none">
          Client Acq
        </div>
        {unreadConvCounts.clientAcqCount > 0 && (
          <div className="w-5 h-5 p-2.5 mt-1 bg-primary rounded-3xl flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-[#FBF5E8]  text-xs font-bold ">
              {unreadConvCounts.clientAcqCount}
            </div>
          </div>
        )}
      </button>
      <button
        onClick={() => onTabChange("ins-ops")}
        className={`h-12 flex-1 px-4 py-[13px] ${activeTab === "ins-ops"
          ? `bg-[#FFEB9C]`
          : "hover:bg-whiteButtonHover"
          } rounded-md justify-center items-center gap-2.5 flex`}
          style={activeTab !== "ins-ops" ? {borderColor: INS_OPS_COLOR, borderWidth: '1px'} : {}}
      >
        <div className="text-black text-base font-normal font-['Outfit'] leading-none">
          Insurance Ops
        </div>
        {unreadConvCounts.insOpsCount > 0 && (
          <div className="w-5 h-5 p-2.5 mt-1 bg-primary rounded-3xl flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-[#FBF5E8]  text-xs font-bold ">
              {unreadConvCounts.insOpsCount}
            </div>
          </div>
        )}
      </button>
    </div>
    <div className="ml-3 mb-2.5 text-base font-semibold">Clients:</div>
    <div className="p-1.5 mb-[11px] rounded-lg border border-neutral-200 bg-white justify-start items-start flex w-[110px] lg:w-[200px]">
      <button
        onClick={() => onTabChange("clients")}
        className={`h-12 flex-1 px-4 py-[13px] rounded-md justify-center items-center gap-2.5 flex ${activeTab === "clients" ? "bg-[#FFDDE4]" : "hover:bg-whiteButtonHover"
          }`}
          style={activeTab !== "clients" ? {borderColor: CLIENT_CHAT_COLOR, borderWidth: '1px'} : {}}
      >
        <div className="text-black text-base font-normal">Clients</div>
        {unreadConvCounts.clientCount > 0 && (
          <div className="w-5 h-5 p-2.5 mt-1 bg-primary rounded-3xl flex-col justify-center items-center gap-2.5 inline-flex">
            <div className="text-[#FBF5E8]  text-xs font-bold ">
              {unreadConvCounts.clientCount}
            </div>
          </div>
        )}
      </button>
    </div>
    </>
  );
}
