export enum AuthRequestType {
  ASSESSMENT = "ASSESSMENT",
  TREATMENT = "TREATMENT",
}

export enum AuthRequestReferralSource {
  INSURANCE = "INSURANCE",
  REFERRAL_FROM_PED_OR_MED = "REFERRAL_FROM_PED_OR_MED",
  SCHOOL_OR_DAYCARE = "SCHOOL_OR_DAYCARE",
  PARENT_SUPPORT_GROUP = "PARENT_SUPPORT_GROUP",
  GOOGLE = "GOOGLE",
  SOCIAL_MEDIA = "SOCIAL_MEDIA",
  OTHER = "OTHER",
}

export enum AuthRequestStatus {
  REVIEWING = "REVIEWING",
  IN_PROGRESS = "IN_PROGRESS",
  AWAITING_INSURANCE = "AWAITING_INSURANCE",
  APPEALING = "APPEALING",
  ACTION_REQUIRED = "ACTION_REQUIRED",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  CANCELED = "CANCELED",
}

export const authRequestStatusText = (status?: AuthRequestStatus) => {
  switch (status) {
    case AuthRequestStatus.REVIEWING:
      return "Reviewing";
    case AuthRequestStatus.IN_PROGRESS:
      return "In Progress";
    case AuthRequestStatus.AWAITING_INSURANCE:
      return "Awaiting Insurance";
    case AuthRequestStatus.APPEALING:
      return "Appealing";
    case AuthRequestStatus.ACTION_REQUIRED:
      return "Action Required";
    case AuthRequestStatus.APPROVED:
      return "Insurance Approved";
    case AuthRequestStatus.CANCELED:
      return "Canceled";
    case AuthRequestStatus.REJECTED:
      return "Insurance Denial";
    default:
      return "";
  }
};

export const authRequestReferralSourceToLabel = (source: AuthRequestReferralSource) => {
  let friendlyReferralSource = "";
  switch (source) {
    // create case statements for each referral source
    case "INSURANCE":
      friendlyReferralSource = "Insurance";
      break;
    case "REFERRAL_FROM_PED_OR_MED":
      friendlyReferralSource = "Referral from Pediatrician or other Medical Professional";
      break;
    case "SCHOOL_OR_DAYCARE":
      friendlyReferralSource = "School or Daycare";
      break;
    case "PARENT_SUPPORT_GROUP":
      friendlyReferralSource = "Parent Support Group";
      break;
    case "GOOGLE":
      friendlyReferralSource = "Google";
      break;
    case "SOCIAL_MEDIA":
      friendlyReferralSource = "Social Media";
      break;
    case "OTHER":
      friendlyReferralSource = "Other";
      break;
  }
  return friendlyReferralSource;
};

export const authRequestStatusColor = (status: AuthRequestStatus) => {
  switch (status) {
    case AuthRequestStatus.REVIEWING:
      return "grey";
    // case RbtRequestStatus.IN_PROGRESS:
    case AuthRequestStatus.IN_PROGRESS:
      return "rgb(59 130 246)"; //blue
    case AuthRequestStatus.AWAITING_INSURANCE:
      return "rgb(59 130 246)"; //blue
    case AuthRequestStatus.ACTION_REQUIRED:
      return "rgb(249 115 22)"; //orange
    case AuthRequestStatus.APPEALING:
      return "#9D00FF"; // purple
    case AuthRequestStatus.APPROVED:
      return "green";
    case AuthRequestStatus.CANCELED:
      return "rgb(239 68 68)"; //red
    case AuthRequestStatus.REJECTED:
      return "rgb(239 68 68)"; //red
  }
};

export type AuthRequestClient = {
  id: string;
  firstName: string;
  lastName: string;
  guardianFirstName: string;
  guardianLastName: string;
};

export type AuthRequestPatient = {
  zipCode?: string;
};

export type Provider = {
  id: string;
  firstName: string;
  lastName: string;
};

export type AuthRequest = {
  id?: string;
  friendlyId?: string;
  ownerId: string;
  provider: Provider;
  clientId: string;
  client?: AuthRequestClient;
  patientInfo?: AuthRequestPatient;
  status?: AuthRequestStatus;
  type?: AuthRequestType;
  pointPerson?: string;
  alohaAbaClientAlias?: string;
  comments?: string;
  clientReferralSource?: AuthRequestReferralSource;
  referralGroupName?: string;
  referralProviderName?: string;
  referralPhoneNumber?: string;
  createdAt?: Date;
  updatedAt?: Date;
  isActive?: boolean;
};
