import { useState } from "react";
import { Button } from "../../../../components/Button";
import { PopupModal } from "../../../../components/PopupModal";
import { ShowToast } from "../../../../services/toast";
import { api } from "../../../../services/api";

export function DeleteConfirm({
  onCancel,
  onSuccess,
  user,
}: {
  onCancel: () => void;
  onSuccess: () => void;
  user: any;
}) {
  const [deleting, setDeleting] = useState(false);
  const onDelete = async () => {
    try {
      setDeleting(true);
      const response = await api.deleteAccount(user?.id);
      if ([200, 201].includes(response.status)) {
        setDeleting(false);
        ShowToast({
          type: "success",
          message: "User Deleted!",
        });
        onSuccess();
      }
    } catch (error: any) {
      setDeleting(false);
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };
  return (
    <PopupModal contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]">
      <div className="flex flex-col w-full">
        <p className="text-center text-xl text-black/80 font-medium break-words">
          Are you sure, you want to remove "{user?.guardianFirstName}{" "}
          {user?.guardianLastName}"?
        </p>
        <div className="flex gap-3 items-center w-full mt-8">
          <Button
            onClick={onCancel}
            variant="outline"
            className="!rounded-full w-full flex-1"
            disabled={deleting}
          >
            Go Back
          </Button>
          <Button
            onClick={onDelete}
            className="!rounded-full w-full flex-1 !bg-[#D75656] hover:!bg-black border border-[rgba(62,163,163,0.20)]"
            variant="primary"
            disabled={deleting}
            loading={deleting}
          >
            Delete
          </Button>
        </div>
      </div>
    </PopupModal>
  );
}
