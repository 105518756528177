import { readFile } from "./CropImage";
import { useImageCropContext } from "./provider/ImageCropProvider";
import Cropper from "./components/Cropper";
import { ZoomSlider } from "./components/Sliders";
import { Button } from "../Button";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";

const ImageCropModalContent = ({ handleDone, handleClose }) => {
  const { setImage, resetStates } = useImageCropContext() as any;

  const handleFileChange = async ({ target: { files } }) => {
    const file = files && files[0];
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
  };

  const handleCancel = () => {
    resetStates();
    handleClose();
  };

  return (
    <div className="text-center relative">
      <div className="flex justify-between items-center mb-7">
        <h1 className="text-black text-xl font-semibold">Edit Image</h1>
        <button onClick={handleCancel} className="group">
          <CloseIcon />
        </button>
      </div>
      <div className="border border-dashed border-gray-200 p-6 rounded-lg relative pt-[275px]">
        <div className="flex justify-center">
          <div className="crop-container mb-4">
            <Cropper />
          </div>
        </div>
        <ZoomSlider className="mb-4" />
        <input
          type="file"
          multiple
          onChange={handleFileChange}
          className="hidden"
          id="avatarInput"
          accept="image/*"
        />
        <div className="flex gap-2">
          <Button variant="primary" className="w-full" onClick={handleDone}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ImageCropModalContent;
