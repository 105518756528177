import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as ArrowRight } from "../../../assets/icons/left-arrow.svg";
import { useEffect, useRef } from "react";
import moment from "moment";
import { EmptyStates } from "../../../components/EmptyStates";
import { ImageView } from "../../../components/Image";

function FamilyListLoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr className="hover:bg-gray-100 [&_td]:py-3 border-b" key={i}>
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <div className="flex items-center gap-2.5">
                  <div className="w-[58px] h-[58px] rounded-full bg-gray-300 animate-pulse transition duration-50"></div>
                  <div className="w-28 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
                </div>
              </div>
            </div>
          </td>
          <td className="subscription">
            <div className="w-24 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-executive">
            <div className="w-16 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
        </tr>
      ))}
    </>
  );
}

export function ClientList({
  providerId,
  updateFilters,
  filters,
  users,
  total,
  isLoading,
  loadMore,
}) {
  const observerTarget = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          users &&
          total &&
          users.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, users, total, isLoading]);

  return (
    <LoadAnimatedContainer>
      <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-2">
            <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
              <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
              <input
                type="text"
                placeholder="Search"
                className="outline-none"
                onChange={(e) => updateFilters({ keyword: e.target.value })}
              />
            </div>
          </div>

          <div className="flex gap-2 items-center"></div>
        </div>
        <div className={`mt-3 text-[14px] h-[calc(100vh-450px)] overflow-auto`}>
          <table className="w-full">
            <colgroup>
              <col style={{ width: "50%" }} />
              <col style={{ width: "25%" }} />
              <col style={{ width: "25%" }} />
            </colgroup>
            <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
              <tr className="sticky top-0 z-[1]">
                <td className="rounded-l pl-5">
                  <div className="flex items-center">
                    Parent/Guardian Name
                    {filters.sort_by === "firstName" ? (
                      <ArrowRight
                        stroke="#2A2B2F"
                        className={`sort-icon ${
                          filters.sort_order === "asc"
                            ? "rotate-[270deg]"
                            : "rotate-[90deg]"
                        }`}
                      />
                    ) : null}
                  </div>
                </td>
                <td>
                  <div className="flex items-center cursor-pointer">
                    Client Name
                  </div>
                </td>
                <td>
                  <div className="flex items-center cursor-pointer">
                    Reached Out On
                  </div>
                </td>

                <td className="rounded-r"></td>
              </tr>
            </thead>
            <tbody>
              {users?.map((user) => (
                <tr
                  key={user.id}
                  className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                >
                  <td className="name pl-5">
                    <div className="flex items-center gap-3">
                      <div className="flex flex-col">
                        <div className="flex items-center gap-2.5">
                          <div className="w-[58px] h-[58px] rounded-full">
                            {user.profileImg ? (
                              <ImageView
                                alt="user"
                                src={user.profileImg}
                                className="w-[58px] h-[58px] rounded-full object-cover"
                                loading="lazy"
                              />
                            ) : (
                              <div
                                className={`uppercase w-[58px] h-[58px] rounded-full flex justify-center items-center text-2xl text-primary bg-secondary`}
                              >
                                {user?.firstName?.[0] || ""}
                              </div>
                            )}
                          </div>
                          <div className="flex flex-col gap-[2px] text-sm">
                            <h5 className="flex-1 max-w-[100px] overflow-hidden whitespace-nowrap text-ellipsis">
                              {user?.guardianFirstName} {user?.guardianLastName}
                            </h5>
                            <div>{user?.mobile}</div>
                            <div>{user?.email}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    {user.firstName} {user.lastName}
                  </td>
                  <td>
                    {moment.utc(user.scheduledAt).local().format("MMM DD,YYYY")}
                  </td>
                </tr>
              ))}
              {isLoading && <FamilyListLoadingSkeleton />}
              <div ref={observerTarget}></div>
              <div className="h-5"></div>
            </tbody>
          </table>
          {isLoading === false && !users?.length && (
            <div className="flex items-center h-[calc(100vh-300px)]">
              <EmptyStates text="No session found!" />
            </div>
          )}
        </div>
      </div>
    </LoadAnimatedContainer>
  );
}
