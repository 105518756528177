export function TextArea({
  rows,
  ...props
}: React.InputHTMLAttributes<HTMLTextAreaElement> & { rows?: number }) {
  return (
    <div className="w-full">
      <textarea
        {...props}
        className={`!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus:border-grey2 p-[18px] text-base text-black ${
          props.className || ""
        }`}
        rows={rows}
      />
    </div>
  );
}
