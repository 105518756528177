import { PopupModal } from "../../../components/PopupModal";
import { ReactComponent as Email } from "../../../assets/icons/email.svg";
import { ReactComponent as Call } from "../../../assets/icons/call.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { ReactComponent as Close } from "../../../assets/icons/x.svg";
import { ReactComponent as MessageIcon } from "../../../assets/icons/message.svg";
import moment from "moment";
import { Button } from "../../../components/Button";
import { ImageView } from "../../../components/Image";
import { getInitial } from "../../../services/utils";
import { zipCodes } from "../../rbtRequests/modals/zip_codes";

export function FamilyProfileView({
  user,
  insurance,
  secondaryInsurance,
  onClose,
  onMessage,
}: {
  user: any;
  onMessage: () => void;
  onClose: () => void;
  insurance: any;
  secondaryInsurance: any;
}) {
  const handleClose = () => {
    onClose();
  };
  return (
    <PopupModal
      onClose={handleClose}
      contentClassName="p-8 !z-[10] bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-[98%] md:w-[552px] md:max-w-[552px] mx-auto max-h-[calc(100vh-50px)]"
      exit={{ opacity: 0 }}
      overlayClassName="!z-[9]"
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
            Client's Profile
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={handleClose}>
              <Close className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full  max-h-[calc(100vh-150px)] overflow-auto flex-col justify-start items-start gap-5 flex">
          <div className="self-stretch p-3 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-3.5 flex">
            <div className="self-stretch justify-start items-center gap-3.5 inline-flex">
              <div className="justify-start items-center gap-3.5 flex">
                {user?.profileImg ? (
                  <ImageView
                    className="w-24 h-24 rounded-[10px] object-cover"
                    src={user?.profileImg}
                    alt="user"
                    loading="lazy"
                  />
                ) : (
                  <div className="uppercase w-24 h-24 rounded-[10px] flex justify-center items-center text-3xl text-primary bg-secondary">
                    {getInitial(user?.guardianFirstName, user?.guardianLastName)}
                  </div>
                )}
                <div className="flex-col justify-start items-start gap-2 inline-flex">
                  <div className="text-black text-lg font-medium font-['Outfit']">
                    {user?.guardianFirstName} {user?.guardianLastName}
                  </div>
                  <div className="justify-start items-center gap-1.5 inline-flex">
                    <Email className="w-5 h-5 relative" />
                    <div className="truncate text-black text-opacity-80 text-sm font-normal font-['Outfit'] leading-tight">
                      {user?.email}
                    </div>
                  </div>
                  <div className="justify-start items-center gap-1.5 inline-flex">
                    <Call className="w-5 h-5 relative"></Call>
                    <div className="text-black text-opacity-80 text-sm font-normal font-['Outfit'] leading-tight">
                      {user?.mobile}
                    </div>
                  </div>
                </div>
              </div>
              {/* <div className="justify-center items-center gap-3 hidden md:flex">
                <Button
                  variant="primary"
                  onClick={onMessage}
                  className="!rounded-full text-primary text-sm font-medium font-['Outfit'] flex items-center gap-1.5"
                >
                  <MessageIcon />
                  Message
                </Button>
              </div> */}
            </div>
          </div>
          {user?.profileSummary && (
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="text-neutral-500 text-sm font-semibold font-['Outfit'] uppercase">
                About me
              </div>
              <div className="self-stretch p-4 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-1.5 flex">
                <span className="text-black opacity-80 text-sm font-normal">
                  {user?.profileSummary}
                </span>
              </div>
            </div>
          )}
          <div className="self-stretch flex-col justify-start items-start gap-3 flex">
            <div className="text-neutral-500 text-sm font-semibold font-['Outfit'] uppercase">
              Client's details
            </div>
            <div className="self-stretch p-4 bg-white rounded-xl border border-neutral-200 flex-col justify-center items-start gap-1.5 flex">
              <div className="justify-start items-center gap-1.5 inline-flex">
                <div className="w-24 opacity-80 text-black/80 text-sm font-normal font-['Outfit']">
                  Name:{" "}
                </div>
                <div className="opacity-80 text-black text-sm font-medium font-['Outfit']">
                  {user?.firstName} {user?.lastName}
                </div>
              </div>
              <div className="justify-start items-center gap-1.5 inline-flex">
                <div className="w-24 opacity-80 text-black text-sm font-normal font-['Outfit']">
                  Date of birth:{" "}
                </div>
                <div className="opacity-80 text-black text-sm font-medium font-['Outfit']">
                  {moment(user?.dob).format("MMM DD, YYYY")}
                </div>
              </div>
              {user?.zipCode && (
                <div className="justify-start items-center gap-1.5 inline-flex">
                  <div className="w-32 opacity-80 text-black text-sm font-normal font-['Outfit']">
                    {user?.zipCode && `${zipCodes[user.zipCode].city},${zipCodes[user.zipCode].state} (${user.zipCode})`}
                  </div>
                  <div className="opacity-80 text-black text-sm font-medium font-['Outfit']">
                    {user?.zipCode}
                  </div>
                </div>
              )}
            </div>
          </div>
          {(insurance?.frontImg || insurance?.backImg) && (
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-neutral-500 text-sm font-semibold font-['Outfit'] uppercase">
                  Insurance
                </div>
                <div className="px-3 py-1 bg-emerald-100 rounded-3xl border border-emerald-200 justify-start items-center gap-1 flex">
                  {insurance.isVerified && (
                    <Check className="w-3.5 h-3.5 relative" />
                  )}
                  <div className="text-black text-sm font-normal font-['Outfit']">
                    {insurance.isVerified
                      ? "Verified"
                      : "Verification In-Progress"}
                  </div>
                </div>
              </div>
              {!!insurance.verifyNotes ? <p>{insurance.verifyNotes}</p> : null}
              <div className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-start items-center gap-1.5 flex flex-col lg:flex-row">
                <ImageView
                  className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg"
                  containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                  src={insurance.frontImg}
                  alt="insurance"
                  loading="eager"
                />
                <ImageView
                  className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg"
                  containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                  src={insurance.backImg}
                  alt="insurance"
                  loading="lazy"
                />
              </div>
            </div>
          )}

          {/** secondary insurance */}
          {(secondaryInsurance?.frontImg || secondaryInsurance?.backImg) && (
            <div className="self-stretch flex-col justify-start items-start gap-3 flex">
              <div className="self-stretch justify-between items-center inline-flex">
                <div className="text-neutral-500 text-sm font-semibold font-['Outfit'] uppercase">
                  Secondary Insurance
                </div>
                <div className="px-3 py-1 bg-emerald-100 rounded-3xl border border-emerald-200 justify-start items-center gap-1 flex">
                  {secondaryInsurance.isVerified && (
                    <Check className="w-3.5 h-3.5 relative" />
                  )}
                  <div className="text-black text-sm font-normal font-['Outfit']">
                    {secondaryInsurance.isVerified
                      ? "Verified"
                      : "Verification In-Progress"}
                  </div>
                </div>
              </div>
              {!!secondaryInsurance.verifyNotes ? (
                <p>{secondaryInsurance.verifyNotes}</p>
              ) : null}
              <div className="w-full p-4 bg-white rounded-xl border border-neutral-200 justify-start items-center gap-1.5 flex flex-col lg:flex-row">
                <ImageView
                  className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg"
                  containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                  src={secondaryInsurance.frontImg}
                  alt="insurance"
                  loading="eager"
                />
                <ImageView
                  className="w-full h-[210px] lg:w-56 lg:h-[140px] object-contain rounded-lg"
                  containerClassName="w-full h-[215px] lg:w-56 lg:h-36 rounded-lg border border-[#BFD8BD] flex flex-col justify-center"
                  src={secondaryInsurance.backImg}
                  alt="insurance"
                  loading="lazy"
                />
              </div>
            </div>
          )}
        </div>
      </>
    </PopupModal>
  );
}
