import React, { useCallback, useEffect, useState } from "react";
import { api } from "../../../services/api";
import { getUser } from "../../../services/utils";
import { ShowToast } from "../../../services/toast";
import { useUser } from "../../auth/context/user-context";
import { tab } from "@testing-library/user-event/dist/tab";

export const MailBoxContext = React.createContext({});

export const CONTACT_FETCH_INTERVAL = 1000 * 10; // 10 seconds
export const CHAT_FETCH_INTERVAL = 1000 * 10; // 10 seconds
const ACTIVE_STATUS_FETCH_INTERVAL = 1000 * 60 * 1; // 1 minutes

export function MailBoxProvider({ children }) {
  const user = getUser();
  const { userInfo, fetchUnreadConvCounts } = useUser() as any;
  const isProvider = userInfo?.type === "P";

  const chatContactsRef = React.useRef<any>(null);
  const chatRef = React.useRef<any>(null);
  const selectedContactRef = React.useRef<any>(null);
  const inquiryRef = React.useRef<any>(null);

  const [activeTab, setActiveTab] = useState<"clients" | "providers" | "rbt" | "client-acq" | "ins-ops" | "currentClients">("providers");

  // loading states
  const [contactListLoading, setContactListLoading] =
    React.useState<boolean>(false);
  const [inquiryLoading, setInquiryLoading] = React.useState<boolean>(false);
  const [messagesLoading, setMessagesLoading] = React.useState<boolean>(false);
  const [removingSchedule, setRemovingSchedule] =
    React.useState<boolean>(false);
  const [inquiryDeclineLoading, setInquiryDeclineLoading] =
    React.useState<boolean>(false);
  const [inquiryAcceptLoading, setInquiryAcceptLoading] =
    React.useState<boolean>(false);

  const [contactList, setContactList] = React.useState<any>([]);
  const [inquiries, setInquiries] = React.useState<any>([]);
  const [secondaryInsuranceDetails, setSecondaryInsuranceDetails] =
    React.useState<any>({});
  const [insuranceDetails, setInsuranceDetails] = React.useState<any>({});
  const [selectedContact, setSelectedContact] = React.useState<any>(null);
  const [selectedContactStatus, setSelectedContactStatus] =
    React.useState<any>(null);
  const [chatMessages, setChatMessages] = React.useState<any>([]);

  const isClientTabSelected = activeTab === "clients";

  const [markUnreadLoading, setMarkUnreadLoading] = useState<boolean>(false);
  
  
  const markConversationUnread = async (contactId: string) => {
    setMarkUnreadLoading(true);
    const result = await api.markConversationAsUnread({
      contactId,
      tab: activeTab,
    }
    );
    await Promise.all([fetchChatMessages(), fetchContactList(), fetchUnreadConvCounts()]);
    setMarkUnreadLoading(false);
    return result;
  }


  const onInquiryDecline = async (id: string) => {
    try {
      setInquiryDeclineLoading(true);
      const response = await api.updateScheduleStatus(id, {
        providerStatus: -1,
      });
      // fetch enquiries again
      if ([200, 201].includes(response.status)) {
        setSelectedContact(null);
        ShowToast({
          message: "Inquiry Declined!",
          type: "success",
        });
        await fetchUnreadConvCounts();
        const list = await fetchInquiries();
        if (list.length > 0 && list[0]) {
          setSelectedContact(list[0]);
        }
        setInquiryDeclineLoading(false);
      } else {
        setInquiryDeclineLoading(false);
        ShowToast({
          message: "Unable to decline, please try again later",
          type: "error",
        });
      }
    } catch (error: any) {
      setInquiryDeclineLoading(false);
      ShowToast({
        message: "Something went wrong while declining inquiry",
        type: "error",
      });
    }
  };

  const onInquiryAccept = async (id: string) => {
    try {
      setInquiryAcceptLoading(true);
      const response = await api.updateScheduleStatus(id, {
        providerStatus: 1,
      });
      // fetch enquiries again
      // needs to move in current clients tab
      // fetch contact list again
      // make selected contact as selected
      // fetch chat messages
      // fetch insurance details
      // fetch selected contact status
      if ([200, 201].includes(response.status)) {
        ShowToast({
          message: "Inquiry Accepted!",
          type: "success",
        });
        fetchInquiries();
        fetchUnreadConvCounts();
        const list = await fetchContactList();
        if (list.length > 0 && list.find((e) => e.id === id)) {
          const contact = contactList.find((e) => e.id === id);
          setSelectedContact(contact);
        }
        setInquiryAcceptLoading(false);
        setActiveTab("currentClients");
      } else {
        setInquiryAcceptLoading(false);
      }
    } catch (error: any) {
      setInquiryAcceptLoading(false);
      ShowToast({
        message: "Something went wrong while declining inquiry",
        type: "error",
      });
    }
  };

  const fetchInsuranceDetails = async () => {
    try {
      const insuranceResponse = await api.getInsurance(selectedContact?.id);
      if ([200, 201].includes(insuranceResponse.status)) {
        setInsuranceDetails(
          (insuranceResponse?.data || []).find(
            (e) => e.isActive && e.isPrimary
          ) || {}
        );
        setSecondaryInsuranceDetails(
          (insuranceResponse?.data || []).find(
            (e) => e.isActive && !e.isPrimary
          ) || {}
        );
      }
    } catch (error: any) {
      ShowToast({
        message: "Something went wrong while fetching insurance details",
        type: "error",
      });
    }
  };

  const fetchInquiries = async (isInquiryLoading = false) => {
    try {
      if (isInquiryLoading) {
        setInquiryLoading(true);
      }
      const response = await api.getProviderClients(user?.id, {
        page: 0,
        limit: 1000,
        type: 0,
      });
      setInquiryLoading(false);
      if ([200, 201].includes(response.status)) {
        setInquiries(response?.data?.items || []);
        return response?.data?.items || [];
      }
    } catch (error: any) {
      setInquiryLoading(false);
      ShowToast({
        message: "Something went wrong while fetching inquiries",
        type: "error",
      });
      return [];
    }
    return [];
  };

  const fetchSelectedContactStatus = async () => {
    if (selectedContact) {
      try {
        const id = selectedContact?.id;
        const response = await api.fetchUserStatus(id);
        if (response && [200, 201].includes(response.status)) {
          // set selected contact profile
          setSelectedContactStatus((prev) => ({
            ...prev,
            ...response.data,
          }));
        }
      } catch (error: any) { }
    }
  };

  const removeSchedule = async (id: string) => {
    try {
      setRemovingSchedule(true);
      const response = await api.removeSchedules(id);
      if ([200, 201].includes(response.status)) {
        // fetch enquiries again
        // fetch contact list again
        fetchContactList();
        // also set selected contact to null
        setSelectedContact(null);
        setRemovingSchedule(false);
      } else {
        ShowToast({
          message: "Unable to remove, please try again later",
          type: "error",
        });
      }
    } catch (error: any) {
      setRemovingSchedule(false);
      ShowToast({
        message:
          error?.response?.data?.message ||
          "Something went wrong while removing contact",
        type: "error",
      });
    }
  };

  const fetchContactList = async (isContactLoading = false) => {
    if (user?.id) {
      try {
        if (isContactLoading) {
          setContactListLoading(true);
        }
        if (activeTab === "clients") {
          const response = await api.getProviderClients(user?.id, {
            page: 0,
            limit: 1000,
            type: 1,
          });
          isContactLoading && setContactListLoading(false);
          if ([200, 201].includes(response.status)) {
            setContactList(response?.data?.items || []);
            return response?.data?.items || [];
          }
        } else if (activeTab === "rbt") {
          const response = await api.getAdminRbtChatProviders(user?.id);
          isContactLoading && setContactListLoading(false);
          if ([200, 201].includes(response.status)) {
            setContactList(response?.data?.items || []);
            return response?.data?.items || [];
          }

        } else if (activeTab === "client-acq") {
          const response = await api.getAdminClientAcqChatProviders(user?.id);
          isContactLoading && setContactListLoading(false);
          if ([200, 201].includes(response.status)) {
            setContactList(response?.data?.items || []);
            return response?.data?.items || [];
          }
        } else if (activeTab === "ins-ops") {
          const response = await api.getAdminInsOpsChatProviders(user?.id);
          isContactLoading && setContactListLoading(false);
          if ([200, 201].includes(response.status)) {
            setContactList(response?.data?.items || []);
            return response?.data?.items || [];
          }
        } else {
          const response = await api.getFamilyProviders(user?.id);
          isContactLoading && setContactListLoading(false);
          if ([200, 201].includes(response.status)) {
            setContactList(response?.data?.items || []);
            return response?.data?.items || [];
          }
        }
      } catch (error: any) {
        ShowToast({
          message: "Something went wrong while fetching contact list",
          type: "error",
        });
      }
    }
    return [];
  };

  const fetchChatMessages = useCallback(
    async (isMessagesLoading = false) => {
      if (selectedContact && user?.id) {
        try {
          const providerId = isClientTabSelected
            ? user?.id
            : selectedContact?.id;
          const patientId = isClientTabSelected
            ? selectedContact?.id
            : user?.id;
          if (isMessagesLoading) {
            setMessagesLoading(true);
          }

          let response;
          switch (activeTab) {
            case "rbt":
              response = await api.getAdminRbtChatMessages(providerId);
              break;
            case "client-acq":
              response = await api.getAdminClientAcqChatMessages(providerId);
              break;
            case "ins-ops":
              response = await api.getAdminInsOpsMessages(providerId);
              break;
            default:
              response = await api.getMessages(patientId, providerId);
              break;
          }

          if (isMessagesLoading) {
            setMessagesLoading(false);
          }
          if ([200, 201].includes(response.status)) {
            // set chat messages
            setChatMessages(response.data);
          }
        } catch (error: any) {
          ShowToast({
            message: "Something went wrong while fetching chat messages",
            type: "error",
          });
        }
      }
    },
    [activeTab, isClientTabSelected, selectedContact, user?.id]
  );

  const sendMessage = async ({
    content,
    contentType,
  }: {
    content: string;
    contentType?: string;
  }) => {
    if (selectedContact && user?.id) {
      try {
        const payload = {
          providerId: isClientTabSelected
            ? user?.id
            : selectedContact?.id,
          patientId: isClientTabSelected
            ? selectedContact?.id
            : user?.id,
          isPatientSender: !isClientTabSelected,
          content,
          contentType: contentType || "text",
        };

        let adminType;
        switch (activeTab) {
          case "rbt":
            adminType = "A-RBT";
            break;
          case "client-acq":
            adminType = "A-CLIENT-ACQ";
            break;
          case "ins-ops":
            adminType = "A-INS-OPS";
            break;
          case "providers":
            adminType = "A";
            break;
        }

        const response = await api.sendMessageAsAdmin(payload, adminType);
        if ([200, 201].includes(response.status)) {
          setContactList([
            ...contactList.filter((contact) => contact?.id === selectedContact.id),
            ...contactList.filter((contact) => contact?.id !== selectedContact.id),
          ]);
          // fetch chat messages again
          fetchChatMessages();
        }
      } catch (error) {
        ShowToast({
          message: "Something went wrong while sending message",
          type: "error",
        });
      }
    }
  };

  useEffect(() => {
    if (selectedContact && user?.id) {
      // fetch chat messages
      // should be called every 5 seconds
      // call immediately for the first time
      // clear chat messages when selected contact changes
      setChatMessages([]);
      fetchChatMessages(true);
      // when the selected contact changes, clear the interval  and set it again
      if (chatRef.current) {
        clearInterval(chatRef.current);
        chatRef.current = null;
      }
      chatRef.current = setInterval(() => {
        fetchChatMessages();
      }, CHAT_FETCH_INTERVAL);

      // mark as read
      let payload = {
        tab: activeTab,
      };
      if (isClientTabSelected) {
        payload["patientId"] = selectedContact?.id;
      } else {
        payload["providerId"] = selectedContact?.id;
      }
      api.markAsRead(payload).then(async (response) => {
        if ([200, 201].includes(response.status)) {
          // fetch chat messages again
          await fetchUnreadConvCounts();
          fetchContactList();
        }
      });

      // if user is provider then fetch insurance details of the selected contact
      if (isClientTabSelected) {
        fetchInsuranceDetails();
      }

      // we also need to fetch active status of the selected contact
      // fetch get profile api
      // call immediately for the first time
      // then call for every 5 minutes
      fetchSelectedContactStatus();
      if (selectedContactRef.current) {
        clearInterval(selectedContactRef.current);
        selectedContactRef.current = null;
      }
      selectedContactRef.current = setInterval(() => {
        fetchSelectedContactStatus();
      }, ACTIVE_STATUS_FETCH_INTERVAL);
    }

    return () => {
      clearInterval(chatRef.current);
      clearInterval(selectedContactRef.current);
      chatRef.current = null;
      selectedContactRef.current = null;
    };
  }, [selectedContact, user?.id, isClientTabSelected]);

  useEffect(() => {
    if (user?.id) {
      // call for chat list

      // should be called every minute
      // call immediately for the first time
      fetchContactList(true);
      chatContactsRef.current = setInterval(() => {
        fetchContactList();
      }, CONTACT_FETCH_INTERVAL);

      if (isProvider) {
        // fetch enquiries
        fetchInquiries(true);
        inquiryRef.current = setInterval(() => {
          fetchInquiries();
        }, CONTACT_FETCH_INTERVAL);
      }
    }
    return () => {
      clearInterval(chatContactsRef.current);
      chatContactsRef.current = null;
      clearInterval(inquiryRef.current);
      inquiryRef.current = null;
    };
  }, [user?.id, isProvider]);

  // when tab changes, we need to fetch the contact list again
  useEffect(() => {
    if (user?.id) {
      setContactList([]);
      fetchContactList(true);
      clearInterval(chatContactsRef.current);
      chatContactsRef.current = setInterval(() => {
        fetchContactList();
      }, CONTACT_FETCH_INTERVAL);
    }
    return () => {
      clearInterval(chatContactsRef.current);
      chatContactsRef.current = null;
    };
  }, [activeTab]);

  const values = {
    selectedContact,
    setSelectedContact,
    markConversationUnread,
    markUnreadLoading,
    selectedContactStatus,
    contactList,
    contactListLoading,
    sendMessage,
    chatMessages,
    messagesLoading,
    removeSchedule,
    removingSchedule,
    inquiries,
    inquiryLoading,
    insuranceDetails,
    secondaryInsuranceDetails,
    onInquiryDecline,
    onInquiryAccept,
    inquiryDeclineLoading,
    inquiryAcceptLoading,
    activeTab,
    setActiveTab,
  };

  return (
    <MailBoxContext.Provider value={values}>{children}</MailBoxContext.Provider>
  );
}

export function useMailBox() {
  const context = React.useContext(MailBoxContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
