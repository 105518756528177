import { ReactComponent as Close } from "../../../../assets/icons/x.svg";
import { ReactComponent as AlertIcon } from "../../../../assets/icons/Alert.svg";
import { ReactComponent as BackIcon } from "../../../../assets/icons/chevron-left.svg";

import { useEffect, useMemo, useRef, useState } from "react";
import {
  validateEmail,
  validatePhone,
  getInitial,
} from "../../../../services/utils";
import { ShowToast } from "../../../../services/toast";
import { api } from "../../../../services/api";
import { Button } from "../../../../components/Button";
import { TextInput } from "../../../../components/TextInput";
import { CdeUpload, ManualUpload, VOBUpload } from "../../../auth/register/components/ManualUpload";
import { fileUpload } from "../../../../services/file-upload";
import { TextArea } from "../../../../components/Textarea";
import { ImageView } from "../../../../components/Image";
import { ImageCrop } from "../../../../components/ImageCropper/ImageCrop";
import { zipCodes } from "../../../rbtRequests/modals/zip_codes";
import { SelectInput } from "../../../../components/SelectInput";
import { ClientStatus } from "../../hooks/patient.types";

export function EditFamilyProfile({
  onClose,
  isOpen,
  user,
  insuranceData,
  secondaryInsuranceData,
  onBack,
  fetchInsuranceData,
  onSuccess,
}: {
  onClose: () => void;
  isOpen: boolean;
  user: any;
  insuranceData: any;
  secondaryInsuranceData: any;
  onBack: () => void;
  fetchInsuranceData?: () => void;
  onSuccess: (data: any) => void;
}) {
  const profileImgRef = useRef<HTMLInputElement | null>(null);
  const [profileImg, setProfileImg] = useState<any>(null);
  const [errors, setErrors] = useState({} as any);
  const [error, setError] = useState<string>("");
  const [saving, setSaving] = useState(false);
  const [insurance, setInsurance] = useState({} as any);
  const [secondaryInsurance, setSecondaryInsurance] = useState({} as any);
  const [cdeFile, setCdeFile] = useState<File | null>(null);
  const [form, setForm] = useState({
    email: "",
    userType: "PF",
    firstName: "",
    lastName: "",
    guardianFirstName: "",
    guardianLastName: "",
    dob: "",
    zipCode: "",
    clientStatus: "",
    cdeUrl: "",
    mobile: "",
    profileImg: "",
    profileSummary: "",
  });
  const [showCropModal, setShowCropModal] = useState(false);

  const handleCrop = () => {
    setShowCropModal(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "mobile") {
      // allow only number
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        if (value && !validatePhone(value.toString())) {
          setErrors((prev) => ({
            ...prev,
            mobile: "Invalid mobile number",
          }));
        } else {
          setErrors((prev) => ({ ...prev, mobile: "" }));
        }
        setForm((prev) => ({ ...prev, [name]: value }));
        return;
      } else {
        return;
      }
    }
    if (name === "email") {
      if (value && !validateEmail(value)) {
        setErrors((prev) => ({
          ...prev,
          email: "Invalid email format",
        }));
      } else {
        setErrors((prev) => ({ ...prev, email: "" }));
      }
    }

    setForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // upload cde file if exists
    let cdeUrl = form?.cdeUrl ?? null;
    if (cdeFile) {
      setSaving(true)
      const uploadResponse = await fileUpload(
        cdeFile,
        user.id,
        "CDE"
      );
      if (uploadResponse.url) {
        cdeUrl = uploadResponse.url;
      }
      setSaving(false)
    }

    const payload: any = {
      email: form.email,
      userType: form.userType,
      firstName: form.firstName,
      lastName: form.lastName,
      guardianFirstName: form.guardianFirstName,
      guardianLastName: form.guardianLastName,
      dob: form.dob,
      zipCode: form.zipCode,
      cdeUrl: cdeUrl,
      clientStatus: form.clientStatus,
      mobile: form.mobile,
      profileSummary: form.profileSummary,
      profileImg: form.profileImg,
    };
    try {
      setErrors({});
      setError("");
      setSaving(true);
      if (profileImg) {
        const frontSideResponse = await fileUpload(
          profileImg,
          user.id,
          "USER_PROFILE"
        );
        if (frontSideResponse.url) {
          payload.profileImg = frontSideResponse.url;
        }
      }

      console.log(JSON.stringify(insurance))

      // save insurance
      // upload insurance files
      // upload insurance files only if new files are selected
      let insurancePayload: any = {
        isPrimary: true,
        isVerified: insurance.isVerified,
        verifyNotes: insurance.verifyNotes,
      };
      if (insurance.frontSide && typeof insurance.frontSide !== "string") {
        const frontSideResponse = await fileUpload(
          insurance.frontSide,
          user.id,
          "INSURANCE"
        );
        if (frontSideResponse.url) {
          insurancePayload.frontImg = frontSideResponse.url;
        }
      } else if (insurance.frontSide) {
        // already uploaded file
        insurancePayload.frontImg = insurance.frontSide;
      }
      if (insurance.backSide && typeof insurance.backSide !== "string") {
        const backSideResponse = await fileUpload(
          insurance.backSide,
          user.id,
          "INSURANCE"
        );
        if (backSideResponse.url) {
          insurancePayload.backImg = backSideResponse.url;
        }
      } else if (insurance.backSide) {
        // already uploaded file
        insurancePayload.backImg = insurance.backSide;
      }

      if (insurance.vob && typeof insurance.vob !== "string") {
        const uploadResponse = await fileUpload(
          insurance.vob,
          user.id,
          "INSURANCE"
        );
        if (uploadResponse.url) {
          insurancePayload.vobImg = uploadResponse.url;
        }
      } else if (insurance.vob) {
        // already uploaded file
        insurancePayload.vobImg = insurance.vob;
      }

      // save insurance
      const insuranceSaveResponse = await api.saveInsurance(user.id, insurancePayload);
      if (![200, 201].includes(insuranceSaveResponse.status)) {
        ShowToast({
          type: "error",
          message: "Unable to save insurance",
        });
      }

      // upload secondary insurance files
      // upload secondary insurance files only if new files are selected
      let secondaryInsurancePayload: any = {
        // isPrimary: secondaryInsurance.isPrimary,
        isPrimary: false,
        isVerified: secondaryInsurance.isVerified,
        verifyNotes: secondaryInsurance.verifyNotes,
      };
      if (
        secondaryInsurance.frontSide &&
        typeof secondaryInsurance.frontSide !== "string"
      ) {
        const frontSideResponse = await fileUpload(
          secondaryInsurance.frontSide,
          user.id,
          "INSURANCE"
        );
        if (frontSideResponse.url) {
          secondaryInsurancePayload.frontImg = frontSideResponse.url;
        }
      } else if (secondaryInsurance.frontSide) {
        // already uploaded file
        secondaryInsurancePayload.frontImg = secondaryInsurance.frontSide;
      }
      if (
        secondaryInsurance.backSide &&
        typeof secondaryInsurance.backSide !== "string"
      ) {
        const backSideResponse = await fileUpload(
          secondaryInsurance.backSide,
          user.id,
          "INSURANCE"
        );
        if (backSideResponse.url) {
          secondaryInsurancePayload.backImg = backSideResponse.url;
        }
      } else if (secondaryInsurance.backSide) {
        // already uploaded file
        secondaryInsurancePayload.backImg = secondaryInsurance.backSide;
      }

      if (secondaryInsurance.vob && typeof secondaryInsurance.vob !== "string") {
        const uploadResponse = await fileUpload(
          secondaryInsurance.vob,
          user.id,
          "INSURANCE"
        );
        if (uploadResponse.url) {
          secondaryInsurancePayload.vobImg = uploadResponse.url;
        }
      } else if (secondaryInsurance.vob) {
        // already uploaded file
        secondaryInsurancePayload.vobImg = secondaryInsurance.vob;
      }

      // save secondary insurance
      const secondaryInsuranceResponse = await api.saveInsurance(
        user.id,
        secondaryInsurancePayload
      );
      if (![200, 201].includes(secondaryInsuranceResponse.status)) {
        ShowToast({
          type: "error",
          message: "Unable to save secondary insurance",
        });
      }
      fetchInsuranceData && fetchInsuranceData();

      const response = await api.saveUserProfile(user.id, payload);
      setSaving(false);
      const result = response;
      if ([200, 201].includes(result.status)) {
        ShowToast({
          type: "success",
          message: "Profile Updated!",
        });
        const userResponse = await api.getUserProfile(user.id);
        if (userResponse) {
          onSuccess(userResponse);
        }
        onBack();
      }
    } catch (error: any) {
      setSaving(false);
      if (error?.response?.status === 401) {
        setError("Invalid email or password");
        return;
      }
      setError(error?.response?.data?.message || "Something went wrong");
      return false;
    }
  };
  const disabled = useMemo(() => {
    let flag =
      !form.email ||
      !validateEmail(form.email) ||
      !form.firstName ||
      !form.lastName ||
      !form.guardianFirstName ||
      !form.guardianLastName ||
      !form.dob ||
      !form.mobile ||
      !validatePhone(form.mobile);
    return flag;
  }, [form, insurance]);

  const minDate = useMemo(() => {
    // 100 years back
    const date = new Date();
    date.setFullYear(date.getFullYear() - 100);
    return date.toISOString().split("T")[0];
  }, []);

  useEffect(() => {
    if (isOpen) {
      setForm({
        email: user?.email || "",
        userType: "PF",
        firstName: user?.firstName || "",
        lastName: user?.lastName || "",
        guardianFirstName: user?.guardianFirstName || "",
        guardianLastName: user?.guardianLastName || "",
        dob: user?.dob || "",
        zipCode: user?.zipCode || "",
        cdeUrl: user?.cdeUrl || "",
        clientStatus: user?.clientStatus || "",
        mobile: user?.mobile || "",
        profileImg: user?.profileImg || "",
        profileSummary: user?.profileSummary || "",
      });
      setInsurance({
        frontSide: insuranceData?.frontImg || null,
        backSide: insuranceData?.backImg || null,
        vob: insuranceData?.vobImg || null,
        isVerified: insuranceData?.isVerified || false,
        verifyNotes: insuranceData?.verifyNotes || '',
      });
      setSecondaryInsurance({
        frontSide: secondaryInsuranceData?.frontImg || null,
        backSide: secondaryInsuranceData?.backImg || null,
        vob: secondaryInsuranceData?.vobImg || null,
        isVerified: secondaryInsuranceData?.isVerified || false,
        verifyNotes: secondaryInsuranceData?.verifyNotes || '',
      });
    }
  }, [isOpen]);
  const url = profileImg ? URL.createObjectURL(profileImg) : form.profileImg;

  const isZipValid = (code: string) => code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code]
  const zipCity = isZipValid(form.zipCode) && zipCodes[form.zipCode]?.city
  const zipState = isZipValid(form.zipCode) && zipCodes[form.zipCode]?.state_short


  return (
    <>
      <div className="justify-between items-start flex w-full">
        <div className="justify-start items-center gap-1.5 flex">
          <button onClick={onBack}>
            <BackIcon className="w-6 h-6" />
          </button>
          <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
            Edit Profile
          </div>
        </div>
        <button
          onClick={onClose}
          className="justify-end items-start gap-3 flex"
        >
          <Close className="w-6 h-6 relative" />
        </button>
      </div>
      <div className="max-h-[calc(100vh-150px)] overflow-auto w-full">
        <div className="self-stretch h-24 bg-white rounded-xl flex-col justify-center items-start gap-3.5 flex">
          <div className="self-stretch justify-start items-center gap-3.5 inline-flex">
            <div className="justify-start items-center gap-3.5 flex">
              <input
                type="file"
                onChange={(e) => {
                  if (e.target.files?.[0]) {
                    setProfileImg(e.target.files[0]);
                    setShowCropModal(true);
                  }
                }}
                id="profileImg"
                className="hidden"
                accept="image/png, image/jpeg"
                ref={(e) => (profileImgRef.current = e)}
              />
              {url ? (
                <ImageView
                  className="w-24 h-24 rounded-[10px] object-cover"
                  src={url}
                  alt="user"
                  loading="lazy"
                />
              ) : (
                <div className="uppercase w-24 h-24 rounded-[10px] flex justify-center items-center text-3xl text-primary bg-secondary">
                  {getInitial(user?.guardianFirstName, user?.guardianLastName)}
                </div>
              )}
              <div className="flex flex-col gap-[14px]">
                <button
                  onClick={() => profileImgRef.current?.click()}
                  className="text-teal-500 text-base font-medium font-['Outfit'] leading-none"
                >
                  Change photo
                </button>

                {profileImg && (
                  <button
                    onClick={() => handleCrop()}
                    className="text-primary text-base font-medium"
                  >
                    Edit Photo
                  </button>
                )}
                {(form.profileImg || profileImg) && (
                  <button
                    onClick={() => {
                      setProfileImg(null);
                      setForm((prev) => ({ ...prev, profileImg: "" }));
                    }}
                    className="text-[#D12E2E] text-base font-medium font-['Outfit'] leading-none"
                  >
                    Remove photo
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="w-full flex-col justify-start items-center gap-3.5 flex">
          <div className="w-full flex-col justify-start items-center gap-8 flex">
            {/** reg form */}
            <form
              className="w-full mt-4 flex flex-col gap-[12px]"
              onSubmit={handleSubmit}
            >
              <div className="flex flex-col md:flex-row items-center gap-3">
                <div className="flex-1 w-full flex flex-col gap-1.5">
                  <label className="text-sm text-black/80">
                    Parent/Guardian First Name
                  </label>
                  <TextInput
                    name="guardianFirstName"
                    value={form.guardianFirstName}
                    onChange={handleChange}
                    placeholder="Enter first name"
                    maxLength={128}
                  />
                </div>
                <div className="flex-1 w-full flex flex-col gap-1.5">
                  <label
                    htmlFor="guardianLastName"
                    className="text-sm text-black/80"
                  >
                    Parent/Guardian Last Name
                  </label>
                  <TextInput
                    name="guardianLastName"
                    value={form.guardianLastName}
                    onChange={handleChange}
                    placeholder="Enter last name"
                    maxLength={128}
                  />
                </div>
              </div>
              <div className="flex flex-col md:flex-row items-center gap-3">
                <div className="flex-1 w-full flex flex-col gap-1.5">
                  <label htmlFor="email" className="text-sm text-black/80">
                    Client First Name
                  </label>
                  <TextInput
                    name="firstName"
                    value={form.firstName}
                    onChange={handleChange}
                    placeholder="Enter first name"
                    maxLength={128}
                  />
                </div>
                <div className="flex-1 w-full flex flex-col gap-1.5">
                  <label htmlFor="lastName" className="text-sm text-black/80">
                    Client Last Name
                  </label>
                  <TextInput
                    name="lastName"
                    value={form.lastName}
                    onChange={handleChange}
                    placeholder="Enter last name"
                    maxLength={128}
                  />
                </div>
              </div>

              <div className="flex flex-col md:flex-row items-center gap-3">
                <div className="flex-1 w-full flex flex-col gap-1.5">
                  <label htmlFor="email" className="text-sm text-black/80">
                    Client Date of Birth
                  </label>
                  <TextInput
                    type="date"
                    placeholder="mm/dd/yyyy"
                    name="dob"
                    value={form.dob}
                    onChange={handleChange}
                    max={new Date().toISOString().split("T")[0]}
                    min={minDate} // last 100 years only
                    className={`${!form.dob ? "!text-[#8D8E92]" : ""} ${(zipCity || errors.zipCode) ? 'mb-6' : ''}`}
                  />
                </div>
                <div className="flex-1 w-full flex flex-col gap-1.5">
                  <label htmlFor="password" className="text-sm text-black/80">
                    Client Zip Code
                  </label>
                  <TextInput
                    name="zipCode"
                    value={form.zipCode}
                    onChange={handleChange}
                    placeholder="Enter Zip Code"
                  />
                  {zipCity && zipState && <div className="text-xs">Client City: {zipCity}, {zipState}</div>}
                  {errors.zipCode && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.zipCode}
                    </div>
                  )}
                </div>
              </div>

              <div className={`flex flex-col md:flex-row items-center gap-3 ${zipCity ? 'mt-[-24px]' : ''}`}>
                <div className="flex-1 w-full flex flex-col gap-1.5">
                  <span className="text-black/80 text-sm">Status</span>
                  <SelectInput
                    data={Object.entries(ClientStatus).map(([k, v]) => ({
                      value: v,
                      label: v,
                    }))}
                    name="clientStatus"
                    value={form?.clientStatus}
                    disabledOptionText={"Select Status"}
                    onChange={handleChange}
                    className={`h-10 py-0`}
                  />
                </div>
                <div className="flex-1 w-full flex flex-col gap-1.5" />
              </div>

              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label htmlFor="aboutMe" className="text-sm text-black/80">
                  About me (Optional)
                </label>
                <TextArea
                  name="profileSummary"
                  value={form.profileSummary}
                  onChange={handleChange}
                  placeholder="Tell us about how Headstart can help?"
                  maxLength={512}
                />
              </div>
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label htmlFor="email" className="text-sm text-black/80">
                  Phone Number
                </label>
                <TextInput
                  type="tel"
                  placeholder="Enter your phone number"
                  value={form.mobile}
                  onChange={handleChange}
                  name="mobile"
                  maxLength={10}
                />
                {errors.mobile && (
                  <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    {errors.mobile}
                  </div>
                )}
              </div>

              <div className="flex-1 w-full flex flex-col">
                <label htmlFor="email" className="text-sm text-black/80">
                  Email
                </label>
                <TextInput
                  type="email"
                  placeholder="Enter your email address"
                  value={form.email}
                  name="email"
                  maxLength={128}
                  readOnly
                />
                {errors.email && (
                  <div className="text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    {errors.email}
                  </div>
                )}
              </div>

              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">
                  Diagnostic Evaluation (DE)
                </label>
                <div className="flex flex-col gap-3 mt-2 mb-3">
                  <CdeUpload
                    onChange={(file) => {
                      if (file !== null) {
                        setCdeFile(file);
                      } else {
                        setCdeFile(null);
                        setForm({ ...form, cdeUrl: '' });
                      }
                    }}
                    value={cdeFile ?? form.cdeUrl}
                  />
                </div>
              </div>


              <div className="mt-10 inline-block">
                <div className="inline-block text-sm text-black/80">
                  Primary Insurance
                </div>
                <div className="inline-block float-right">
                  <div className="relative inline-block w-8 h-4  rounded-full cursor-pointer text">
                    <input
                      id="switch-component"
                      type="checkbox"
                      onChange={(e) => {
                        setInsurance({
                          ...insurance,
                          isVerified: e.target.checked,
                        });
                      }}
                      className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-[#E8F3F2] checked:bg-primary"
                      checked={insurance.isVerified}
                    />
                    <label
                      htmlFor="switch-component"
                      className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity before:shadow-[10px_14px_40px_0px_rgba(0,71,28,0.11)]  hover:before:opacity-0 peer-checked:translate-x-full peer-checked:before:bg-primary"
                    >
                      <div
                        className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                        data-ripple-dark="true"
                      ></div>
                    </label>
                  </div>
                  <div
                    className="text-black text-sm font-normal inline-block ml-3 mt-0"
                    style={{ transform: "translateY(-3px)" }}
                    data-ripple-dark="true"
                  >
                    Mark as Verified
                  </div>
                </div>
              </div>

              <TextArea
                rows={6}
                placeholder="Enter Insurance Notes"
                className="!p-[14px]"
                name="insuranceNotes"
                value={insurance.verifyNotes}
                onChange={(e) => {
                  setInsurance({ ...insurance, verifyNotes: e.target.value });
                }}
              />


              <ManualUpload
                frontSide={insurance.frontSide}
                backSide={insurance.backSide}
                onChange={(file, type) => {
                  setInsurance((prev) => ({
                    ...prev,
                    [type === "front" ? "frontSide" : "backSide"]: file,
                  }));
                }}
              />

              <VOBUpload
                value={insurance.vob ?? insurance.vobImg}
                onChange={(file) => {
                  setInsurance((prev) => ({
                    ...prev,
                    vob: file,
                  }));
                }}

              />




              <div className="mt-10 inline-block">
                <div className="inline-block text-sm text-black/80">
                  Secondary Insurance (Optional)
                </div>
                <div className="inline-block float-right">
                  <div className="relative inline-block w-8 h-4  rounded-full cursor-pointer text">
                    <input
                      id="switch-component2"
                      type="checkbox"
                      onChange={(e) => {
                        setSecondaryInsurance({
                          ...secondaryInsurance,
                          isVerified: e.target.checked,
                        });
                      }}
                      className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-[#E8F3F2] checked:bg-primary"
                      checked={secondaryInsurance.isVerified}
                    />
                    <label
                      htmlFor="switch-component2"
                      className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity before:shadow-[10px_14px_40px_0px_rgba(0,71,28,0.11)]  hover:before:opacity-0 peer-checked:translate-x-full peer-checked:before:bg-primary"
                    >
                      <div
                        className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                        data-ripple-dark="true"
                      ></div>
                    </label>
                  </div>
                  <div
                    className="text-black text-sm font-normal inline-block ml-3 mt-0"
                    style={{ transform: "translateY(-3px)" }}
                    data-ripple-dark="true"
                  >
                    Mark as Verified
                  </div>
                </div>
              </div>

              <TextArea
                rows={6}
                placeholder="Enter Secondary Insurance Notes"
                className="!p-[14px]"
                name="secondaryInsuranceNotes"
                value={secondaryInsurance.verifyNotes}
                onChange={(e) => {
                  setSecondaryInsurance({ ...secondaryInsurance, verifyNotes: e.target.value });
                }}
              />

              <ManualUpload
                frontSide={secondaryInsurance.frontSide}
                backSide={secondaryInsurance.backSide}
                onChange={(file, type) => {
                  setSecondaryInsurance((prev) => ({
                    ...prev,
                    [type === "front" ? "frontSide" : "backSide"]: file,
                  }));
                }}
              />


              <VOBUpload
                value={secondaryInsurance.vob ?? secondaryInsurance.vobImg}
                onChange={(file) => {
                  setSecondaryInsurance((prev) => ({
                    ...prev,
                    vob: file,
                  }));
                }}
              />

              <div className="mt-5">
                {error && (
                  <div className="text-red-500 text-xs font-medium mb-4 flex items-center gap-1">
                    <AlertIcon /> {error}
                  </div>
                )}
                <Button
                  type="submit"
                  variant="primary"
                  className="!rounded-full w-full"
                  disabled={disabled || saving}
                  loading={saving}
                  onClick={handleSubmit}
                >
                  Save
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {showCropModal && (
        <ImageCrop
          isOpen={showCropModal}
          onClose={() => {
            setShowCropModal(false);
          }}
          image={profileImg}
          onImageChange={({ url, file }) => setProfileImg(file)}
        />
      )}
    </>
  );
}
