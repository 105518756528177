import ReactQuill from "react-quill";
import styled from "styled-components";
import "react-quill/dist/quill.snow.css";
import React from "react";
import { twMerge } from "tailwind-merge";

var Link = ReactQuill.Quill.import("formats/link");

class MyLink extends Link {
  static create(value) {
    let node = super.create(value);
    value = this.sanitize(value);
    if (!value.startsWith("http")) {
      value = "http://" + value;
    }
    node.setAttribute("href", value);
    return node;
  }
}

ReactQuill.Quill.register(MyLink);

const StyledRichEditor = styled.div`
  .ql-container {
    min-height: 100px;
  }
  .ql-toolbar.ql-snow {
    border-radius: 8px 8px 0px 0px;
    border-color: transparent;
    border-bottom-color: #d9d9d9;
    background-color: #edf3f2;
  }
  .ql-container.ql-snow {
    border-radius: 0px 0px 8px 8px;
    border-color: transparent;
    background-color: #edf3f2;
  }
  .ql-toolbar.ql-snow {
    font-family: "Outfit", sans-serif;
  }
  .ql-editor.ql-blank::before {
    color: #8d8e92;
    font-style: normal;
    font-family: "Outfit", sans-serif;
  }
  .ql-editor.ql-blank > p {
    min-height: 30px;
  }
  .ql-editor {
    font-size: 16px;
    font-family: "Outfit", sans-serif;
    font-weight: 400;
    padding-bottom: 44px;

    max-height: 50vh;
    overflow-y: auto;
  }
  .ql-snow .ql-picker-label::before {
    color: #333;
    font-weight: 400;
  }
  &.focus {
    .ql-toolbar.ql-snow {
      border-radius: 8px 8px 0px 0px;
      border-color: #00ae9b;
      border-bottom-color: #d9d9d9;
    }
    .ql-container.ql-snow {
      border-radius: 0px 0px 8px 8px;
      border-color: #00ae9b;
      background-color: #edf3f2;
    }
  }
  .ql-snow.ql-toolbar button,
  .ql-snow .ql-toolbar button {
    height: 20px;
    svg {
      fill: #333;
    }
  }
  .ql-toolbar.ql-snow .ql-formats {
    margin-right: 0px;
  }
`;

function RichEditor({
  initialState = "",
  value = "",
  onChange,
  className = "",
  label = "",
  onSend,
}: {
  initialState?: string;
  value?: string;
  onChange: (value: string) => void;
  label?: string;
  className?: string;
  onSend: () => void;
}) {
  const [isFocused, setIsFocused] = React.useState(false);
  const prevKey = React.useRef<string | null>(null);
  return (
    <StyledRichEditor className={twMerge(isFocused ? "focus" : "", className)}>
      <ReactQuill
        theme="snow"
        value={value}
        onChange={onChange}
        placeholder="Enter here"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        onKeyDown={(e) => {
          if (prevKey.current === "Shift" && e.key === "Enter") {
            return true;
          }
          prevKey.current = e.key;
          if (e.key === "Enter") {
            e.preventDefault();
            onSend();
            return false;
          }
        }}
      />
    </StyledRichEditor>
  );
}
export default RichEditor;
