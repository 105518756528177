import { ReactComponent as ChevronDown } from "../../assets/icons/chevron-down.svg";
import { ImageView } from "../Image";

export function UserButton({
  user,
  variant,
  className = "",
  onClick,
}: {
  user: any;
  variant?: string;
  className?: string;
  onClick: () => void;
}) {
  return (
    <div
      role="button"
      onClick={onClick}
      className={`mb-4 cursor-pointer justify-start items-center gap-2 flex ${className}`}
    >
      <div className="flex-1 mx-4 p-3 bg-white rounded-md border border-neutral-200 items-center gap-3 flex">
        <ImageView
          className="w-10 h-10 rounded-md object-cover"
          src={"/images/admin.png"}
          alt="user"
          loading="lazy"
        />
        <div className="flex-1 justify-between items-center gap-px flex">
          <div className="text-[#060606] text-lg font-medium">Admin</div>
          <ChevronDown className="w-5 h-5 relative" />
        </div>
      </div>
    </div>
  );
}
