import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";

export function SearchBar({ onChange }: { onChange: (e: any) => void }) {
  return (
    <div className="flex mb-[11px] items-center p-4 bg-white border border-gray-200 rounded-lg">
      <SearchIcon className="h-5 w-5 [&_path]:stroke-[#8D8E92]" />
      <input
        type="text"
        placeholder="Search"
        className="w-full outline-none ml-2"
        onChange={onChange}
      />
    </div>
  );
}
