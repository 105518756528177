import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";
import DatePickerComponent from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export function TimePicker({
  value,
  onChange,
  className = "",
  minDate,
  maxDate,
  disabled,
}: {
  value: any;
  onChange: (value: any) => void;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
}) {
  return (
    <div className={`date-picker ${className}`}>
      <DatePickerComponent
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm aa"
        onChange={onChange}
        selected={value}

        showIcon={false}
        // placeholderText="mm/dd/yyyy"
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        popperPlacement="top"
        className={`!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus-within:border-grey2 focus:border-grey2 p-1 text-base text-black [&>div]:!border-none`}
      // style={{ minHeight: '50px' }}
      />
    </div>
  );
}
