import { useEffect, useState } from "react";
import { api } from "../../../services/api";

export type Bulletin = {
  id: string;
  title: string;
  description: string;
  createdAt: Date;
  updatedAt?: Date;
  isActive: boolean;
  previewImg?: string;
  attachedDocs?: Array<{ id: string; name: string; type: "youtubelink" | "file" | "folder"; url?: string }>;
};

function useBulletins() {
  const [bulletins, setBulletins] = useState<Array<Bulletin>>([]);

  const fetchBulletins = async () => {
    setBulletins(await api.getBulletins());
  };

  useEffect(() => {
    fetchBulletins();
  }, []);

  return {
    bulletins,
    fetchBulletins,
  };
}

export default useBulletins;
