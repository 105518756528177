export enum ClientReferralStatus {
  RECEIVED = "Referral Received",
  ACTIVE_CLIENT = "Active Client",
  DECLINED = "Referral Declined",
  NOT_SUITABLE = "Not Suitable for Services",
  LOST_TO_FOLLOW_UP = "Lost to Follow-Up",
  REFERRED_OUT = "Referred Out",
}

export const getClientReferralStatusByValue = (enumValue) => {
  let keys = Object.keys(ClientReferralStatus).filter((x) => ClientReferralStatus[x] === enumValue);
  return keys.length > 0 ? keys[0] : undefined;
};

export const clientReferralStatusColor = (status: ClientReferralStatus) => {
  switch (status) {
    case ClientReferralStatus.RECEIVED:
      return "grey";
    case ClientReferralStatus.ACTIVE_CLIENT:
      return "green";
    case ClientReferralStatus.DECLINED:
      return "rgb(249 115 22)"; //orange
    case ClientReferralStatus.NOT_SUITABLE:
      return "rgb(239 68 68)"; //red
    case ClientReferralStatus.LOST_TO_FOLLOW_UP:
      return "rgb(59 130 246)"; //blue
    case ClientReferralStatus.REFERRED_OUT:
      return "rgb(59 130 246)"; //blue
  }
};

export type ClientReferral = {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  isActive?: boolean;

  status: ClientReferralStatus;

  // Family Information
  parentFirstName: string;
  parentLastName: string;
  clientFirstName: string;
  clientLastName: string;
  clientState: string;
  parentEmail: string;
  parentPhone: string;

  // Provider Information
  providerFirstName: string;
  providerLastName: string;
  providerPracticeName: string;
  providerEmail: string;
  providerPhone: string;

  // Diagnostic Information
  cdeUrl: string;
  comments?: string;
};
