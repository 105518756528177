import Routes from "./pages/routes";
import { Toaster } from "react-hot-toast";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import "./App.css";
import { AppProvider } from "./services/app-context";
import ImageCropProvider from "./components/ImageCropper/provider/ImageCropProvider";
import { RbtRequestProvider } from "./pages/rbtRequests/context/RbtRequestContext";
import { RbtCandidateProvider } from "./pages/rbtCandidates/context/RbtCandidatesContext";
import { AuthRequestProvider } from "./pages/authRequests/context/RequestAuthContext";
import { ClientReferralProvider } from "./pages/clientReferrals/context/ClientReferralContext";

// Create a client
const queryClient = new QueryClient();

function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <Toaster />
      <AppProvider>
        <ClientReferralProvider>
          <RbtRequestProvider>
            <RbtCandidateProvider>
              <AuthRequestProvider>
                <div className="font-outfit min-h-screen flex flex-col">
                  <ImageCropProvider>
                    <Routes />
                  </ImageCropProvider>
                </div>
              </AuthRequestProvider>
            </RbtCandidateProvider>
          </RbtRequestProvider>
        </ClientReferralProvider>
      </AppProvider>
    </QueryClientProvider>
  );
}

export default App;
