import {
  isValidPhoneNumber
} from 'libphonenumber-js'
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as SuccessIcon } from "../../../assets/icons/success.svg";
import { useEffect, useState } from "react";
import Autosuggest from 'react-autosuggest';
import { AuthRequest, AuthRequestClient, AuthRequestReferralSource, authRequestReferralSourceToLabel, AuthRequestStatus, authRequestStatusColor, authRequestStatusText, AuthRequestType } from "../RequestAuth.types";
import { ArchiveAuthRequestConfirm } from "./CancelConfirmAuthRequest";
import { Link } from "react-router-dom";
import { useAuthRequests } from "../context/RequestAuthContext";
import { ShowToast } from "../../../services/toast";
import { api } from "../../../services/api";
import { PopupModal } from "../../../components/PopupModal";
import { SelectInput } from "../../../components/SelectInput";
import { TextInput } from "../../../components/TextInput";
import { TextArea } from "../../../components/Textarea";
import { Button } from "../../../components/Button";
import { ReActivateAuthRequestConfirm } from './ReActivateConfirmAuthRequest';

export function AuthRequestModal({ authRequestId, onClose }: { authRequestId?: string; onClose: () => void }) {
  const { authRequests, allPatients } = useAuthRequests();
  const [form, setForm] = useState<AuthRequest>({
    ...authRequests.filter(r => r.id === authRequestId)[0]!,
    clientId: authRequests.filter(r => r.id === authRequestId)[0]?.client?.id ?? '',
    ownerId: authRequests.filter(r => r.id === authRequestId)[0]?.provider?.id ?? '',
  }
  );
  const [checks, setChecks] = useState({
    isClientAlohaCreated: false,
    isClientEvalUploaded: false,
    isTreatmentPlanCreated: false,
    isTreatmentPlanUploaded: false,

  });
  const [errors, setErrors] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);
  const [submitSuccess, setSubmitSuccess] = useState()
  const [clientName, setClientName] = useState('')
  const [autoSuggestClients, setAutoSuggestClients] = useState<Array<AuthRequestClient> | undefined>()
  const [archiveRequest, setArchiveRequest] = useState<AuthRequest | undefined>()
  const [reactivateRequest, setReactivateRequest] = useState<AuthRequest | undefined>()
  const [changeMade, setChangeMade] = useState(false)
  const [editMode, setEditMode] = useState(false)

  useEffect(() => {
    if (authRequestId) {
      const request = authRequests.filter(r => r.id === authRequestId)[0];
      if (request && request.client) {
        setClientName(`${request.client.firstName} ${request.client.lastName}`)
      }
    }
  }, [authRequestId])

  const authRequest = (authRequests.filter(r => r.id === authRequestId).length > 0) ? authRequests.filter(r => r.id === authRequestId)[0] : undefined
  const isNewRequest = !authRequestId

  const handleArchive = async () => {
    if (authRequestId) {
      setArchiveRequest(authRequest)
    }
  }

  const handleReActivate = async () => {
    if (authRequestId) {
      setReactivateRequest(authRequest)
    }
  }

  const getClientSuggestions = value => {
    const inputValue = value?.trim()?.toLowerCase() ?? '';
    const inputLength = inputValue.length;
    if (inputLength === 0) return []

    const withFilter = allPatients.filter(patient =>
      patient.firstName?.toLowerCase().includes(inputValue) ||
      patient.lastName?.toLowerCase().includes(inputValue) ||
      `${patient.firstName} ${patient.lastName}`.toLowerCase().includes(inputValue) ||
      patient.guardianFirstName?.toLowerCase().includes(inputValue) ||
      patient.guardianLastName?.toLowerCase().includes(inputValue) ||
      patient.id?.toLowerCase().includes(inputValue)
    )
    withFilter.sort((a, b) => {
      const first = a.firstName?.toLowerCase() + a.lastName?.toLowerCase()
      const second = b.firstName?.toLowerCase() + b.lastName?.toLowerCase()
      return first.localeCompare(second ?? '') ?? 0
    })
    return withFilter.slice(0, 6)

  };


  const handleSubmit = async () => {
    let newErrors = {}
    if (!form.type) {
      newErrors = { ...newErrors, type: "Please provide Auth Request Type" }
    }

    if (!form.clientId) {
      newErrors = { ...newErrors, clientName: "Please select a valid client name" }
    }

    if (!form.alohaAbaClientAlias) {
      newErrors = { ...newErrors, alohaAbaClientAlias: "Please provide Aloha ABA Client Alias" }
    }

    if (form.type === AuthRequestType.ASSESSMENT && !form.clientReferralSource) {
      newErrors = { ...newErrors, clientReferralSource: "Please share how this client heard about Headstart" }
    }

    if (form.clientReferralSource &&
      [AuthRequestReferralSource.REFERRAL_FROM_PED_OR_MED,
      AuthRequestReferralSource.SCHOOL_OR_DAYCARE,
      AuthRequestReferralSource.PARENT_SUPPORT_GROUP]
        .includes(form.clientReferralSource) && !form.referralGroupName) {
      newErrors = { ...newErrors, referralGroupName: "Please provide the name of the institution (or group) that referred your client" }
    }

    if (form.clientReferralSource && [AuthRequestReferralSource.REFERRAL_FROM_PED_OR_MED].includes(form.clientReferralSource) && !form.referralProviderName) {
      newErrors = { ...newErrors, referralProviderName: "Please provide the name of the provider that referred your client" }
    }

    if (form.clientReferralSource && [AuthRequestReferralSource.REFERRAL_FROM_PED_OR_MED, AuthRequestReferralSource.SCHOOL_OR_DAYCARE].includes(form.clientReferralSource)
      && (!form.referralPhoneNumber || !isValidPhoneNumber(form.referralPhoneNumber ?? '', 'US'))) {
      newErrors = { ...newErrors, referralPhoneNumber: "Please provide the phone number of the referral source" }
    }

    if ((form?.comments?.length ?? 0) > 1000) {
      newErrors = { ...newErrors, comments: `Please keep additional comments under 1000 characters (currently ${form.comments?.length})` }
    }

    if (Object.keys(newErrors).length !== 0) {
      setErrors(newErrors)
      return
    }

    setErrors({})
    setLoading(true)

    if (isNewRequest) {
      const response = await api.createAuthRequest(form)
      if ([200, 201].includes(response.status)) {
        ShowToast({
          message: "You've successfully created an Auth Request",
          type: "success",
        });
        setSubmitSuccess(response.data.friendlyId)
      } else {
        ShowToast({
          message: response?.data?.message || "Something went wrong",
          type: "error",
        })
      }
    } else {
      const response = await api.updateAuthRequest(form)
      if ([200, 201].includes(response.status)) {
        ShowToast({
          message: "You've successfully updated the Auth Request",
          type: "success",
        });
        setEditMode(false)
      } else {
        ShowToast({
          message: response?.data?.message || "Something went wrong",
          type: "error",
        })
      }
    }
    setLoading(false)
  };

  const disabled = !form.type ||
    (form.type === AuthRequestType.ASSESSMENT && (!checks.isClientAlohaCreated || !checks.isClientEvalUploaded)) ||
    (form.type === AuthRequestType.TREATMENT && (!checks.isTreatmentPlanCreated || !checks.isTreatmentPlanUploaded))

  if (submitSuccess) {
    return <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[642px] max-w-[642px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div id="provider-scroll" className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7">
            Auth Request Form
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full text-center">
          <SuccessIcon className="w-64 h-auto max-h-96 mx-auto" />
          <p className="text-xl mb-2">
            Thank you! Our team will be in touch.
          </p>
          <p className="text-xl mb-5">
            Your request no. is <b>#{submitSuccess}</b>
          </p>
          <div className="text-center text-md sm:px-20 mb-4">
            If you will need a new RBT/BT to service this client, please be sure to also{' '}
            <Link className='text-primary' to={'/provider/request-rbt'}>Request an RBT</Link>{' '}
            via the Headstart portal.
          </div>
        </div>
      </>
    </PopupModal >
  }

  const disableForm = !isNewRequest && !editMode;

  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[842px] max-w-[842px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="inline-block text-black text-2xl font-bold font-['Outfit'] leading-7">
            {isNewRequest ? 'Create An Authorization Request' : `Auth Request #${authRequest?.friendlyId}`}
            {!isNewRequest && authRequest?.status && <div className="inline-block ml-5 px-2 h-5 rounded-lg gap-2.5" style={{ transform: "translateY(-3px)", backgroundColor: authRequestStatusColor(form?.status ?? AuthRequestStatus.REVIEWING) }}>
              <div className="text-white text-sm font-normal">{authRequestStatusText(form?.status)}</div>
            </div>}
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full max-h-[calc(100vh-160px)] lg:max-h-[calc(100vh-155px)] overflow-auto" id="provider-scroll">

          <div className="flex flex-col mb-5">

            <div className="grid grid-cols-2 gap-10 mb-3">
              <div>
                <span className="text-black/80 text-sm">Headstart Point Person</span>
                <TextInput
                  className={`h-10 ${!editMode && 'text-gray-400'}`}
                  name="pointPerson"
                  value={form.pointPerson}
                  onChange={(e) => {
                    setForm({ ...form, pointPerson: e.target.value })
                    setChangeMade(true)
                  }}
                  disabled={disableForm}
                />
              </div>
              <div className="">
                <span className="text-black/80 text-sm">Status</span>
                <SelectInput
                  data={Object.entries(AuthRequestStatus).map(([k, v]) => ({
                    value: k,
                    label: authRequestStatusText(v),
                  }))}
                  name="status"
                  value={form?.status}
                  disabledOptionText={"Select Status"}
                  onChange={(e) => {
                    setForm({ ...form, status: AuthRequestStatus[e.target.value] ?? undefined });
                    setChangeMade(true)
                  }}
                  className={`h-10 py-0 ${!editMode && 'text-gray-500'}`}
                  disabled={disableForm}
                />
              </div>
            </div>

            <div className="gap-10">
              <div className="mb-3 max-w-64">
                <span className="text-black/80 text-sm">Authorization Type
                  <SelectInput
                    data={
                      Object.values(AuthRequestType).map((type) => ({
                        value: type, label:
                          `${type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()} Authorization`
                      }))
                    }
                    name="type"
                    value={form.type}
                    disabledOptionText={"Select Authorization Type"}
                    onChange={(e) => {
                      setForm({ ...form, type: e.target.value as AuthRequestType });
                      setChangeMade(true)
                    }}
                    // className="h-16 my-0"
                    className={`h-10 py-0`}
                    disabled={disableForm}
                  />
                </span>
                {errors.type && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.type}
                  </div>
                )}
              </div>
            </div>

            <div className="grid grid-cols-2 gap-10">
              <div className="mb-3">
                <span className="text-black/80 text-sm">Client's Name</span>
                <Autosuggest
                  suggestions={autoSuggestClients || []}
                  onSuggestionsFetchRequested={({ value }) => setAutoSuggestClients(getClientSuggestions(value))}
                  getSuggestionValue={(client) => `${client.firstName} ${client.lastName}`}
                  renderSuggestion={(client) => <div className="cursor-pointer text-sm">Client: {client?.firstName} {client?.lastName} <small className="ml-1 text-gray-400">Parent: {client?.guardianFirstName} {client?.guardianLastName}</small></div>}
                  onSuggestionSelected={(e, { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }) => {
                    if (suggestionValue) {
                      setForm({ ...form, clientId: suggestion.id })
                    }
                  }}
                  inputProps={{
                    className: `${disableForm && 'text-gray-400'} pl-3 py-2 !outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg border border-lightGray focus-within:border-grey2 focus:border-grey2 text-base text-black [&>div]:!border-none`,
                    placeholder: 'Client Name',
                    value: clientName,
                    onChange: (e, { newValue, method }) => {
                      if (form?.clientId?.length > 0 && newValue?.length < clientName?.length) {
                        setClientName('')
                      } else {
                        setClientName(newValue)
                      }
                      setChangeMade(true)
                      setForm({ ...form, clientId: '' })
                      setErrors({ ...errors, clientName: undefined })
                    },
                    disabled: disableForm
                  }}
                  disabled={disableForm}
                />
                {errors.clientName && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.clientName}
                  </div>
                )}
              </div>

              <div>
                <span className="text-black/80 text-sm">Aloha ABA Client Alias</span>
                <TextInput
                  className={`h-10 ${disableForm && 'text-gray-400'}`}
                  name="aloahAbaClientAlias"
                  value={form.alohaAbaClientAlias}
                  placeholder="ABCD01"
                  onChange={(e) => {
                    setForm({ ...form, alohaAbaClientAlias: e.target.value })
                    setChangeMade(true)
                  }}
                  disabled={disableForm}
                />
                {errors.alohaAbaClientAlias && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.alohaAbaClientAlias}
                  </div>
                )}
              </div>
            </div>

            {form.type === AuthRequestType.ASSESSMENT && <>
              <div className="mb-3">
                <span className="text-black/80 text-sm">How did this client hear about Headstart?
                  <SelectInput
                    data={
                      Object.values(AuthRequestReferralSource).map((type) => ({
                        value: type, label: authRequestReferralSourceToLabel(type)
                      }))
                    }
                    name="type"
                    value={form.clientReferralSource}
                    disabledOptionText={"Select how client heard about Headstart"}
                    onChange={(e) => {
                      setForm({ ...form, clientReferralSource: e.target.value as AuthRequestReferralSource });
                      setChangeMade(true)
                    }}
                    // className="h-16 my-0"
                    className={`h-10 py-0 ${disableForm && 'text-gray-400'}`}
                    disabled={disableForm}
                  />
                </span>
                {errors.clientReferralSource && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.clientReferralSource}
                  </div>
                )}
              </div>

              {form.clientReferralSource &&
                [AuthRequestReferralSource.REFERRAL_FROM_PED_OR_MED,
                AuthRequestReferralSource.SCHOOL_OR_DAYCARE,
                AuthRequestReferralSource.PARENT_SUPPORT_GROUP]
                  .includes(form.clientReferralSource) &&
                <div className="mb-3">
                  <span className="text-black/80 text-sm">What is the name of the institution (or group) that referred your client?</span>
                  <TextInput
                    className={`h-10 ${disableForm && 'text-gray-400'}`}
                    name="referralGroupName"
                    value={form.referralGroupName}
                    onChange={(e) => {
                      setForm({ ...form, referralGroupName: e.target.value })
                      setChangeMade(true)
                    }}
                    disabled={disableForm}
                  />
                  {errors.referralGroupName && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.referralGroupName}
                    </div>
                  )}
                </div>
              }

              {form.clientReferralSource &&
                [AuthRequestReferralSource.REFERRAL_FROM_PED_OR_MED]
                  .includes(form.clientReferralSource) &&
                <div className="mb-3">
                  <span className="text-black/80 text-sm">What is the name of the provider that referred your client?</span>
                  <TextInput
                    className={`h-10 ${disableForm && 'text-gray-400'}`}
                    name="referralProviderName"
                    value={form.referralProviderName}
                    onChange={(e) => {
                      setForm({ ...form, referralProviderName: e.target.value })
                      setChangeMade(true)
                    }}
                    disabled={disableForm}
                  />
                  {errors.referralProviderName && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.referralProviderName}
                    </div>
                  )}
                </div>
              }

              {form.clientReferralSource &&
                [AuthRequestReferralSource.REFERRAL_FROM_PED_OR_MED,
                AuthRequestReferralSource.SCHOOL_OR_DAYCARE]
                  .includes(form.clientReferralSource) &&
                <div className="mb-3">
                  <span className="text-black/80 text-sm">What is the phone number of the referral source?</span>
                  <TextInput
                    className={`h-10 ${disableForm && 'text-gray-400'}`}
                    name="referralPhoneNumber"
                    value={form.referralPhoneNumber}
                    onChange={(e) => {
                      setForm({ ...form, referralPhoneNumber: e.target.value })
                      setChangeMade(true)
                    }}
                    disabled={disableForm}
                  />
                  {errors.referralPhoneNumber && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.referralPhoneNumber}
                    </div>
                  )}
                </div>
              }
            </>}



            <div className="flex flex-col mb-2">
              <span className="text-black/80 text-sm">Additional Comments (Optional)</span>
              <TextArea
                placeholder="Any additional comments about the authorization request"
                className={`!p-[14px] ${disableForm && 'text-gray-400'} `}
                name="comments"
                rows={3}
                value={form.comments}
                onChange={(e) => {
                  setForm({ ...form, comments: e.target.value });
                  setChangeMade(true)
                }}
                disabled={disableForm}
              />
              {errors.comments && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.comments}
                </div>
              )}
            </div>

            {form.type === AuthRequestType.ASSESSMENT && <>
              <div className="flex items-center gap-1.5 mt-3 mb-5">
                <input
                  type="checkbox"
                  id="isClientAlohaCreated"
                  name="isClientAlohaCreated"
                  checked={checks.isClientAlohaCreated || !isNewRequest}
                  onChange={(e) =>
                    setChecks({ ...checks, isClientAlohaCreated: e.target.checked })
                  }
                  className="min-w-[22px] w-[22px] h-[22px] accent-primary"
                  disabled={!isNewRequest}
                />
                <label
                  htmlFor="providerAccessCheckbox"
                  className="text-black/80 text-base"
                >
                  I have created a profile for this client in Aloha ABA
                </label>
              </div>

              <div className="flex items-center gap-1.5 mb-2">
                <input
                  type="checkbox"
                  id="isClientEvalUploaded"
                  name="isClientEvalUploaded"
                  checked={checks.isClientEvalUploaded || !isNewRequest}
                  onChange={(e) =>
                    setChecks({ ...checks, isClientEvalUploaded: e.target.checked })
                  }
                  className="min-w-[22px] w-[22px] h-[22px] accent-primary"
                  disabled={!isNewRequest}
                />
                <label
                  htmlFor="providerAccessCheckbox"
                  className="text-black/80 text-base"
                >
                  I have uploaded the client's Diagnostic Evaluation (DE) to the client's profile in Aloha ABA
                </label>
              </div>
            </>}

            {form.type === AuthRequestType.TREATMENT && <>
              <div className="flex items-center gap-1.5 mt-3 mb-5">
                <input
                  type="checkbox"
                  id="isTreatmentPlanCreated"
                  name="isTreatmentPlanCreated"
                  checked={checks.isTreatmentPlanCreated || !isNewRequest}
                  onChange={(e) =>
                    setChecks({ ...checks, isTreatmentPlanCreated: e.target.checked })
                  }
                  className="min-w-[22px] w-[22px] h-[22px] accent-primary"
                  disabled={!isNewRequest}
                />
                <label
                  htmlFor="providerAccessCheckbox"
                  className="text-black/80 text-base"
                >
                  I have created a complete treatment plan for this client, including completion of the appropriate behavior assessments
                </label>
              </div>

              <div className="flex items-center gap-1.5 mb-2">
                <input
                  type="checkbox"
                  id="isTreatmentPlanUploaded"
                  name="isTreatmentPlanUploaded"
                  checked={checks.isTreatmentPlanUploaded || !isNewRequest}
                  onChange={(e) =>
                    setChecks({ ...checks, isTreatmentPlanUploaded: e.target.checked })
                  }
                  className="min-w-[22px] w-[22px] h-[22px] accent-primary"
                  disabled={!isNewRequest}
                />
                <label
                  htmlFor="providerAccessCheckbox"
                  className="text-black/80 text-base"
                >
                  I have uploaded this treatment plan to the client's Aloha ABA profile
                </label>
              </div>
            </>}

          </div>

          {(authRequest) && <>
            <div className="w-full">
              {isNewRequest ?
                <Button
                  disabled={disabled || loading}
                  variant="primary"
                  className="!rounded-full w-full mt-0"
                  onClick={handleSubmit}
                  loading={loading}
                >
                  Submit
                </Button>
                :
                <div className="grid grid-cols-2 gap-10 px-1">
                  {!editMode ? (authRequest?.isActive ?
                    <Button
                      disabled={loading}
                      variant="primary"
                      className="!rounded-full w-full mt-0 bg-red-500 text-white"
                      onClick={handleArchive}
                      loading={loading}
                    >
                      Archive Request
                    </Button>
                    :
                    <Button
                      disabled={loading}
                      variant="primary"
                      className="!rounded-full w-full mt-0 bg-red-500 text-white"
                      onClick={handleReActivate}
                      loading={loading}
                    >
                      Re-Activate Request
                    </Button>
                  ) : <div />}

                  <Button
                    disabled={loading}
                    variant={(editMode && !changeMade) ? "outline" : "primary"}
                    className="!rounded-full w-full mt-0"
                    onClick={() => editMode ? ((changeMade) ? handleSubmit() : setEditMode(false)) : setEditMode(!editMode)}
                    loading={loading}
                  >
                    {editMode ? ((changeMade) ? "Save" : "Stop Editing") : "Edit Request"}
                  </Button>
                </div>
              }
            </div>
          </>}

        </div>

        {archiveRequest && <ArchiveAuthRequestConfirm
          onCancel={() => setArchiveRequest(undefined)}
          onArchive={onClose}
          request={archiveRequest} />}
        {reactivateRequest && <ReActivateAuthRequestConfirm
          onCancel={() => setReactivateRequest(undefined)}
          onDelete={onClose}
          request={reactivateRequest} />
        }
      </>
    </PopupModal >);


}
