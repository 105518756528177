import moment from "moment";

export function DateSeparator({
  date,
  prevDate,
}: {
  date: string;
  prevDate: string;
}) {
  // if prevDate is not the same as date, show date
  const showDate =
    new Date(date).toDateString() !== new Date(prevDate).toDateString();
  // check for today and yesterday
  let dateText = moment(date).format("MMM DD, YYYY");
  if (new Date(date).toDateString() === new Date().toDateString()) {
    dateText = "Today";
  } else if (
    new Date(date).toDateString() ===
    new Date(new Date().setDate(new Date().getDate() - 1)).toDateString()
  ) {
    dateText = "Yesterday";
  }
  return (
    <>
      {showDate ? (
        <div className="flex bg-[#EEE] justify-center self-center items-center rounded-full py-2 px-4 w-fit mt-6 mb-4">
          <div className="text-black/80 text-sm font-medium font-['Outfit']">
            {dateText}
          </div>
        </div>
      ) : null}
    </>
  );
}
