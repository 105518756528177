import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { useState } from "react";
import { DAY, Shift } from "../RequestRbt.types";
import { PopupModal } from "../../../components/PopupModal";
import { TimePicker } from "../../../components/TimePicker";
import { Button } from "../../../components/Button";
import moment from "moment";
import { DateTime } from "luxon";
import { SelectInput } from "../../../components/SelectInput";

export const AddShiftModal = ({ title, onClose, onAdd }) => {
  const [shiftToAdd, setShiftToAdd] = useState<{
    days: Array<'MON' | 'TUES' | 'WED' | 'THUR' | 'FRI' | 'SAT' | 'SUN'>,
    startTime?: Date, endTime?: Date, timeZone: string
  }>({ days: [], startTime: undefined, endTime: undefined, timeZone: '' })

  const timeZoneOptions = [{ value: "America/Los_Angeles", label: "Pacific Time" }, { value: "America/Phoenix", label: "Mountain Time" }, { value: "America/Indiana/Tell_City", label: "Central Time" }, { value: "America/Indiana/Indianapolis", label: "Eastern Time" }]

  if (shiftToAdd.timeZone === '') {
    const localOffsetName = DateTime.local().offsetNameLong
    let localTz
    if (localOffsetName.includes('Pacific')) localTz = "America/Los_Angeles"
    if (localOffsetName.includes('Mountain')) localTz = "America/Phoenix"
    if (localOffsetName.includes('Central')) localTz = "America/Indiana/Tell_City"
    if (localOffsetName.includes('Eastern')) localTz = "America/Indiana/Indianapolis"
    setShiftToAdd({ ...shiftToAdd, timeZone: localTz ?? "America/Indiana/Tell_City" })
  }

  return <PopupModal
    contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[542px] max-w-[542px]"
    onClose={onClose}
    shouldStopPropagation={false}
  >
    <div className="w-full justify-between items-start flex">
      <div className="text-black text-2xl font-bold font-['Outfit'] leading-7">
        {title}
      </div>
      <div className="justify-end items-center gap-3 flex">
        <button onClick={onClose}>
          <CloseIcon className="w-6 h-6" />
        </button>
      </div>
    </div>

    <div className="inline-block">
      {Object.values(DAY).map(day => {
        return (<div className="inline-block">

          <input
            key={day}
            type="checkbox"
            id={day}
            name="shiftDay"
            value={day}
            checked={shiftToAdd.days.indexOf(day) > -1}
            className={`w-[22px] h-[22px] cursor-pointer inline-block ml-2 mr-1`}
            style={{ accentColor: "rgb(0 174 155", transform: "translateY(6px)" }}
            onChange={(e) => {
              if (shiftToAdd.days.indexOf(day) > -1) {
                setShiftToAdd({ ...shiftToAdd, days: shiftToAdd.days.filter(d => d !== day) })
              } else {
                setShiftToAdd({ ...shiftToAdd, days: [...shiftToAdd.days, day] })
              }
            }}
          />
          <label className="self-center justify-self-center align-middle" htmlFor={day}>{day}</label>
        </div>)
      })}
    </div>
    <div className="grid grid-cols-2 gap-10">
      <div>
        <span className="text-black/80 text-sm mr-1">Start Time:</span>

        <TimePicker
          value={shiftToAdd.startTime}
          onChange={(date) => setShiftToAdd({
            ...shiftToAdd, startTime:
              date
              ?? undefined
          })}
        />
      </div>
      <div>
        <span className="text-black/80 text-sm mr-1">End Time:</span>
        <TimePicker
          value={shiftToAdd.endTime}
          onChange={(date) => setShiftToAdd({
            ...shiftToAdd, endTime:
              date
              ?? undefined
          })}
        />
      </div>
    </div>

    <div className="mb-3 w-40">
      <span className="text-black/80 text-sm">Time Zone</span>
      <SelectInput
        data={timeZoneOptions}
        name="qualifications"
        value={shiftToAdd.timeZone}
        disabledOptionText={"Select Time Zone"}
        onChange={(e) => {
          setShiftToAdd({ ...shiftToAdd, timeZone: e.target.value });
        }}
        className="h-12 px-[8px] py-0 my-0"
      />
    </div>

    <Button
      disabled={!shiftToAdd.days || !shiftToAdd.startTime || !shiftToAdd.endTime || shiftToAdd.endTime <= shiftToAdd.startTime}
      variant="primary"
      className="!rounded-full w-full"
      onClick={() => {
        if (shiftToAdd.days && shiftToAdd.startTime && shiftToAdd.endTime) {
          let shifts: Array<Shift> = []
          for (let daySelected of shiftToAdd.days) {
            const { startTime, endTime, timeZone } = shiftToAdd
            const shift: Shift = { day: daySelected, startTime, endTime, timeZone }
            shifts.push(shift)
          }
          onAdd(shifts)
          onClose()
        }
      }}
    >
      Add Session
    </Button>

  </PopupModal>
}