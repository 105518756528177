import Modal from "../../components/Modal";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as LocationIcon } from "../../assets/icons/location.svg";
import { ProviderIntroHeader } from "./components/ProviderDetailsHeader";
import { ImageView } from "../../components/Image";
import { getInitial } from "../../services/utils";
import { ProviderAboutView } from "./components/ProviderAboutView";

export function ProviderDetails({ onClose, isOpen, provider, onSchedule }) {
  return (
    <Modal isOpen={isOpen} handleModal={onClose}>
      <div
        id="provider-scroll"
        className="max-h-[calc(100vh-75px)] lg:max-h-[calc(100vh-36px)] overflow-auto"
      >
        <div className="max-w-[820px] mx-auto flex flex-col gap-4">
          <>
            <div className="flex p-5 bg-white rounded-2xl gap-2 flex-col">
              <div className="items-start md:items-center gap-6 flex">
                {provider.profileImg ? (
                  <ImageView
                    className="w-32 h-32 rounded-2xl object-cover"
                    src={provider.profileImg || "/images/profile1.png"}
                    alt="provider"
                    loading="lazy"
                  />
                ) : (
                  <div
                    className={`uppercase w-32 h-32 rounded-2xl flex justify-center items-center text-[40px] text-primary bg-secondary`}
                  >
                    {getInitial(provider?.firstName, provider?.lastName)}
                  </div>
                )}

                <div className="flex-1 justify-between gap-2 md:gap-1 items-start md:items-center flex flex-col md:flex-row">
                  <div className="flex-col justify-start items-start gap-3.5 inline-flex">
                    <div className="flex-col justify-start items-start gap-2.5 flex">
                      <div className="justify-start items-start gap-2.5 flex flex-col md:flex-row">
                        <div
                          className="text-black text-2xl font-semibold max-w-[250px]"
                          style={{ wordBreak: "break-word" }}
                        >
                          {provider.firstName} {provider.lastName}
                        </div>
                        {provider?.isAvailable && (
                          <div className="pl-2.5 pr-3 py-1.5 bg-white rounded-3xl border border-teal-500 border-opacity-25 justify-start items-center gap-0.5 flex">
                            <CheckIcon className="w-4 h-4" />
                            <div className="text-black text-sm font-normal ">
                              Accepting New Clients
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="text-black text-base font-normal ">
                        {provider?.profession?.join(", ")}
                      </div>
                    </div>
                    <div className="justify-start items-start gap-1.5 inline-flex">
                      <LocationIcon className="w-6 h-6 justify-center items-center flex" />
                      <div className="text-black text-base font-normal ">
                        {provider?.addressState}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          <ProviderAboutView provider={provider} />
        </div>
      </div>
    </Modal>
  );
}
