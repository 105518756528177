import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as More } from "../../../assets/icons/more.svg";
import { ReactComponent as PlusIcon } from "../../../assets/icons/plus.svg";
import "react-quill/dist/quill.snow.css";
import UIPopover from "../../../components/Popover";
import { useRef, useState } from "react";
import moment from "moment";
import { DeleteBulletinConfirm } from "../components/DeleteBulletinConfirm";
import { Button } from "../../../components/Button";
import { AddBulletinModal } from "../modals/AddBulletinModal";
import useBulletins from "../hooks/useBulletins";
import { EditBulletinModal } from "../modals/EditBulletinModal";


export function BulletinList() {
  const { bulletins, fetchBulletins } = useBulletins();

  const [editModal, setEditModal] = useState<any>(null);
  const [removeConfirmModal, setRemoveConfirmModal] = useState<any>(null);
  const [newBulletinModal, setNewBulletinModal] = useState(false);

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleNewProviderModal = () => {
    setNewBulletinModal(true);
  };

  const closeNewProviderModal = (shouldRefresh?: boolean) => {
    if (shouldRefresh) fetchBulletins();
    setNewBulletinModal(false);
  };

  const observerTarget = useRef(null);

  return (
    <>
      <LoadAnimatedContainer>
        <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
          <div className="text-black text-3xl font-bold">Manage Bulletin</div>
          <div className="flex justify-center">
            <Button
              variant="primary"
              onClick={handleNewProviderModal}
              className="!rounded-full"
            >
              <PlusIcon className="[&_path]:stroke-white" />
              Add Entry
            </Button>
          </div>
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <colgroup>
                <col style={{ width: "15%" }} />
                <col style={{ width: "45%" }} />
                <col style={{ width: "25%" }} />
                <col style={{ width: "10%" }} />
                <col style={{ width: "5%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td className="rounded-l pl-5">
                    <div className="flex items-center">Title</div>
                  </td>
                  <td>
                    <div className="flex items-center">Description</div>
                  </td>
                  <td>
                    <div className="flex items-center">Attached File(s) / Youtube Link(s)</div>
                  </td>
                  <td>
                    <div
                      className="flex items-center"
                    >
                      Created On
                    </div>
                  </td>
                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {bulletins?.map((bulletin) => (
                  <tr
                    key={bulletin.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setEditModal(bulletin)}
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-2.5">
                        <h5 className="flex-1">
                          {bulletin?.title}
                        </h5>
                      </div>
                    </td>
                    <td>
                      <div className="pl-3 message-content items-center gap-2 mr-5 whitespace-pre-line" dangerouslySetInnerHTML={{ __html: bulletin?.description }} />
                    </td>
                    <td>
                      <div className="flex items-center gap-2">
                        {(bulletin?.attachedDocs ?? []).map(doc => doc.name).join(", ")}
                      </div>
                    </td>
                    <td className="mrr">
                      {moment.utc(bulletin.createdAt).local().format("MMM DD,YYYY")}
                    </td>
                    <td onClick={stopPropagate} className="pr-5">
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                        positions={["bottom", "top", "left", "right"]}
                      >
                        {(close) => (
                          <BulletinEntryDropdown
                            onRemove={() => {
                              close();
                              setRemoveConfirmModal(bulletin);
                            }}
                            onEdit={() => {
                              close();
                              setEditModal(bulletin);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
        {editModal && (
          <EditBulletinModal
            onClose={() => {
              fetchBulletins();
              setEditModal(null);
            }}
            bulletin={editModal}
            isOpen={!!editModal} />
        )}
        {removeConfirmModal && (
          <DeleteBulletinConfirm
            onCancel={() => setRemoveConfirmModal(null)}
            onSuccess={() => {
              fetchBulletins()
              setRemoveConfirmModal(null);
            }}
            bulletin={removeConfirmModal}
          />
        )}
        {newBulletinModal && (
          <AddBulletinModal
            isOpen={newBulletinModal}
            onClose={closeNewProviderModal}
          />
        )}
      </LoadAnimatedContainer>
    </>
  );
}

export const BulletinEntryDropdown = ({ onEdit, onRemove }) => {
  return (
    <div className="z-10 w-[150px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover text-left"
            onClick={(e) => {
              e.stopPropagation();
              onEdit();
            }}
          >
            Edit Entry
          </button>
        </li>
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base text-red-500 rounded-[10px] hover:bg-whiteButtonHover text-left"
            onClick={(e) => {
              e.stopPropagation();
              onRemove();
            }}
          >
            Delete Entry
          </button>
        </li>
      </ul>
    </div>
  );
};
