import { Tooltip } from "react-tooltip";
import { ReactComponent as Location } from "../../../assets/icons/location.svg";
import { ReactComponent as UnreadIcon } from "../../../assets/icons/unread.svg";
import { ReactComponent as DeadIcon } from "../../../assets/icons/dead.svg";
import { ImageView } from "../../../components/Image";

import "./mailbox.css";
import { useState } from "react";
import { UnreadConvCounts } from "../../auth/context/user-context";

export function ContactCard({
  unreadConvCounts,
  data,
  unreadCount,
  active,
  cardType,
  onMarkUnread,
  markUnreadLoading,
  onClick,
}: {
  unreadConvCounts: UnreadConvCounts;
  active: boolean;
  data: any;
  unreadCount: number;
  cardType: string;
  onMarkUnread?: () => void;
  markUnreadLoading?: boolean;
  onClick?: () => void;
}) {
  const [mouseHovering, setMouseHovering] = useState(false);
  const isFamily = cardType === "family";
  return (
    <div
      className={`p-3 ${active ? "bg-[#E0F0EB]" : "hover:bg-[rgba(210,226,213,0.30)]"
        } rounded-[15px] justify-start items-center gap-3.5 flex`}
      onClick={onClick}
      role="button"
      onMouseEnter={e => {
        setMouseHovering(true)
      }}
      onMouseLeave={e => {
        setMouseHovering(false)
      }}
    >
      <div className="flex-1 justify-start items-center gap-3.5 flex">
        <div className="flex-column gap-2 ml-[-6px] mr-[-8px] cursor-help" onClick={e => {e.preventDefault(); e.stopPropagation()}}>
          {unreadConvCounts.generalAdminUnread.includes(data.id) && (
            <div className="general-dot rounded-full bg-[#E5D4C1] border-3 w-2 h-2 mb-1">
              <Tooltip
                anchorSelect=".general-dot"
                content="Unread in General Admin"
                className="!w-fit text-nowrap !border-2 !border-gray-100 !text-black !rounded-lg !p-2 text-xs !bg-white z-[999] !opacity-100"
                noArrow
              />
            </div>
          )}
          {unreadConvCounts.rbtAdminUnread.includes(data.id) && (
            <div className="rbt-dot rounded-full bg-[#A39FE1] border-3 w-2 h-2 mb-1">
              <Tooltip
                anchorSelect=".rbt-dot"
                content="Unread in RBT"
                className="!w-fit text-nowrap !border-2 !border-gray-100 !text-black !rounded-lg !p-2 text-xs !bg-white z-[999] !opacity-100"
                noArrow
              />
            </div>
          )}
          {unreadConvCounts.clientAcqUnread.includes(data.id) && (
            <div className="client-acq-dot rounded-full bg-[#DEB3E0] border-3 w-2 h-2 mb-1">
              <Tooltip
                anchorSelect=".client-acq-dot"
                content="Unread in Client Acq"
                className="!w-fit text-nowrap !border-2 !border-gray-100 !text-black !rounded-lg !p-2 text-xs !bg-white z-[999] !opacity-100"
                noArrow
              />
            </div>
          )}
          {unreadConvCounts.insOpsUnread.includes(data.id) && (
            <div className="ins-ops-dot rounded-full bg-[#FFEB9C] border-3 w-2 h-2 mb-1">
              <Tooltip
                anchorSelect=".ins-ops-dot"
                content="Unread in Insurance Ops"
                className="!w-fit text-nowrap !border-2 !border-gray-100 !text-black !rounded-lg !p-2 text-xs !bg-white z-[999] !opacity-100"
                noArrow
              />
            </div>
          )}
        </div>

        {data?.profileImg ? (
          <ImageView
            className={`${isFamily ? "w-[73px] h-[73px]" : "w-[61px] h-[61px]"
              } rounded-[10px] object-cover`}
            src={data?.profileImg}
            alt="user"
            loading="lazy"
          />
        ) : (
          <div
            className={`uppercase ${isFamily ? "w-[73px] h-[73px]" : "w-[61px] h-[61px]"} rounded-[10px] flex justify-center items-center text-3xl text-primary bg-secondary`}
            style={{ minWidth: isFamily ? "73px" : "61px" }}
          >
            {(cardType === "family" ? data?.firstName?.[0] : (data?.guardianFirstName) ? data?.clientName?.[0] : <DeadIcon />) || ""}
          </div>
        )}

        <div className="flex-col justify-start items-start gap-1.5 flex">
          <div className="text-black text-lg font-medium font-['Outfit'] leading-none">
            {cardType === "family" && data?.firstName && `${data?.firstName} ${data?.lastName}`}
            {cardType === "family" && !data?.firstName && `[Deleted User]`}
            {cardType !== "family" && `${data?.clientName}`}
          </div>
          {cardType === "family" ? (
            <>
              <div className="text-black text-sm font-normal font-['Outfit'] leading-tight">
                {data?.profession?.join(", ")}
              </div>
              <div className="justify-start items-start gap-1.5 flex">
                <Location className="w-4 h-4" />
                <div className="text-black text-sm font-normal font-['Outfit'] leading-tight">
                  {data?.addressState}
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="justify-start items-start gap-1 inline-flex">
                <div className="text-black text-opacity-80 text-sm font-normal font-['Outfit'] leading-tight">
                  {data.guardianFirstName && "Parent:"}
                </div>
                <div className="text-black text-opacity-80 text-sm font-normal font-['Outfit'] leading-tight">
                  {!data.guardianFirstName ? `` : `${data?.guardianFirstName} ${data?.guardianLastName}`}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {mouseHovering && !markUnreadLoading && unreadCount === 0 &&
        <div className="">
          <div
            className={`w-9 h-9 focus:ring-0 focus:border-none bg-gray-200 p-2 rounded-xl flex-col overflow-hidden justify-center items-center gap-2.5 flex`}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              onMarkUnread?.();
            }}
          >
            <UnreadIcon className="unread-anchor" style={{ outline: "none !important" }} />
            <Tooltip
              anchorSelect=".unread-anchor"
              content="Mark Unread"
              className="!w-[103px] text-nowrap !border-2 !border-gray-100 !text-black !rounded-lg !p-2 text-xs !bg-white "
              noArrow
            />
          </div>
        </div>
      }

      {active || !unreadCount ? null : (
        <div
          className={`w-9 h-9 bg-teal-500 rounded-3xl flex-col justify-center items-center gap-2.5 flex`}
        >
          <div className="text-[#FBF5E8] text-[12px] font-bold">
            {unreadCount}
          </div>{" "}
          {/** font-['Manrope'] */}
        </div>
      )}
    </div>
  );
}
