import { ReactComponent as FilledClock } from "../../../../assets/icons/filled-clock.svg";
import { Tooltip } from "react-tooltip";

export function PendingVerificationTooltip() {
  return (
    <>
      <FilledClock className="w-[22px] min-w-[22px] h-[22px] min-h-[22px] my-anchor-element" />
      <Tooltip
        anchorSelect=".my-anchor-element"
        content="Verification In-Progress"
        className="!w-[175px] z-[1] !border-0 !text-black !rounded-lg !p-4 !bg-white !shadow-[0px_2px_5px_0px_rgba(46,84,61,0.125)]"
        noArrow
      />
    </>
  );
}
