import { useEffect, useRef, useState } from "react";
import { useDebounce } from "../../../services/useDebounce";
import { api } from "../../../services/api";
export const LIMIT = 25;

function useProviders() {
  const [pagination, setPagination] = useState({
    page: 0,
    limit: LIMIT,
  });
  const [state, setState] = useState<any>({
    keyword: "",
    sort_by: "",
    sort_order: "",
    showActive: undefined,
    showPending: false,
    end_date: "",
    type: "P",
    timeType: "",
    categories: [],
  });
  const [data, setData] = useState<any>([]);
  const [total, setTotal] = useState(0);
  const [isLoading, setIsLoading] = useState<boolean | undefined>(undefined);
  const debouncedFilters = useDebounce(state, 500);
  const signalRef = useRef<any>();
  const fetchingParams = useRef<any>();

  const fetchUsers = async (
    params,
    pageInfo = pagination,
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    try {
      setIsLoading(true);
      signalRef.current = new AbortController();
      fetchingParams.current = params;
      const { items = [], totalRecords } = await api.getUsers(
        {
          ...params,
          ...pageInfo,
        },
        signalRef.current.signal
      );
      // filter deleted question
      if (isDeleted && refetchId) {
        setData((prev) => prev.filter((item) => item.id !== refetchId));
        setIsLoading(false);
      } else if (refetchId) {
        setData((prev) => [
          ...prev.filter((item) => item.id !== refetchId),
          ...items,
        ]);
        setIsLoading(false);
      } else if (refetchAll) {
        const newData: any[] = [];
        // refetch all pages
        for (let i = 0; i <= pageInfo.page; i++) {
          const { items = [] } = await api.getUsers({
            ...params,
            ...pageInfo,
            page: i,
          });
          newData.push(...items);
        }
        setData(newData);
        setIsLoading(false);
      } else {
        if (pageInfo.page === 0) {
          setData([...items]);
        } else {
          setData((prev) => [...prev, ...items]);
        }
        setIsLoading(false);
      }
      setTotal(totalRecords);
    } catch (e: any) {
      if (e.name !== "CanceledError") {
        setIsLoading(false);
      }
    }
  };

  const refetch = (
    refetchId?: string,
    isDeleted?: boolean,
    refetchAll?: boolean
  ) => {
    fetchUsers(debouncedFilters, pagination, refetchId, isDeleted, refetchAll);
  };

  const loadMore = () => {
    // update page only we have data
    const page = pagination.page;
    const totalPage = Math.ceil(total / LIMIT);
    if (page < totalPage) {
      setIsLoading(true);
      setPagination((prev) => ({ ...prev, page: prev.page + 1 }));
      fetchUsers(debouncedFilters, {
        ...pagination,
        page: pagination.page + 1,
      });
    }
  };

  const handleFilters = (updates: any) => {
    // update filters & reset to first page
    setIsLoading(true);
    setData([]);
    if (signalRef.current) {
      signalRef.current.abort();
      signalRef.current = null;
    }
    setPagination((prev) => ({ ...prev, page: 0 }));
    setState((prev) => ({ ...prev, ...updates }));
  };

  useEffect(() => {
    if (signalRef.current) {
      signalRef.current.abort();
      signalRef.current = null;
    }
    fetchUsers(debouncedFilters);
  }, [debouncedFilters]);

  const providers: any[] = data || [];
  return {
    providers,
    totalPages: Math.ceil(total / LIMIT),
    total: total,
    pagination,
    isLoading: isLoading,
    filters: state,
    updateFilters: (updates) => handleFilters(updates),
    refetch,
    loadMore,
    reset: () => {
      setData([]);
      setPagination({ page: 0, limit: LIMIT });
      setState({
        keyword: "",
        sort_by: "",
        sort_order: "",
        category_id: "",
        start_date: "",
        end_date: "",
        type: "all",
        timeType: "",
        categories: [],
      });
    },
  };
}

export default useProviders;
