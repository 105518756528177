import { ReactComponent as AdminIcon } from "../../../assets/icons/check-logo.svg";
import { Tooltip } from "react-tooltip";

export function AdminTooltip() {
  return (
    <>
      <AdminIcon className="w-6 h-6 my-anchor-element" />
      <Tooltip
        anchorSelect=".my-anchor-element"
        content="Shared with Providers"
        className="!w-[228px] !border-0 !text-black !rounded-lg !p-4 !bg-white !shadow-[0px_2px_5px_0px_rgba(46,84,61,0.125)]"
        noArrow
      />
    </>
  );
}
