import UIPopover from "../../../components/Popover";
import { statusColorCode } from "../../provider/NewRequestModal";
import { ReactComponent as DownIcon } from "../../../assets/icons/chevron-down.svg";

export function RequestStatusChangeSelection({ value, onChange }) {
  return (
    <UIPopover
      shouldHighlight={false}
      withChevron={true}
      trigger={(isOpen) => (
        <div
          className={`h-8 px-2.5 py-1.5 ${statusColorCode[value].bg} rounded-lg justify-center items-center gap-1 flex`}
        >
          <div className={`${statusColorCode[value].fg} text-sm font-normal`}>
            {statusColorCode[value].text}
          </div>
          <DownIcon className={`w-4 h-4 ${isOpen ? "rotate-180" : ""}`} />
        </div>
      )}
      positions={["bottom", "top", "left", "right"]}
    >
      {(close) => (
        <StatusOptionsDropdown
          value={value}
          onChange={(val) => {
            close();
            onChange(val);
          }}
        />
      )}
    </UIPopover>
  );
}

export const StatusOptionsDropdown = ({ onChange, value }) => {
  return (
    <div className="z-10 w-[200px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 flex flex-col gap-2"
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className={`w-fit ${statusColorCode["NotTouched"].bg} ${statusColorCode["NotTouched"].fg} px-5 h-10 text-base rounded-[10px] hover:opacity-80`}
            onClick={(e) => {
              e.stopPropagation();

              onChange("NotTouched");
            }}
          >
            {statusColorCode["NotTouched"].text}
          </button>
        </li>
        <li>
          <button
            className={`w-fit ${statusColorCode["InProgress"].bg} ${statusColorCode["InProgress"].fg} px-5 h-10 text-base rounded-[10px] hover:opacity-80`}
            onClick={(e) => {
              e.stopPropagation();

              onChange("InProgress");
            }}
          >
            {statusColorCode["InProgress"].text}
          </button>
        </li>
        <li>
          <button
            className={`w-fit ${statusColorCode["NotResponding"].bg} ${statusColorCode["NotResponding"].fg} px-5 h-10 flex items-center justify-center text-base rounded-[10px] hover:opacity-80`}
            onClick={(e) => {
              e.stopPropagation();

              onChange("NotResponding");
            }}
          >
            {statusColorCode["NotResponding"].text}
          </button>
        </li>
      </ul>
    </div>
  );
};
