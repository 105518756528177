import { acceptedInsurances, focusAreas } from "../../../constant";

export function ProviderAboutView({ provider }) {
  return (
    <>
      <div className="p-5 bg-white rounded-2xl flex flex-col gap-2">
        <div className="text-black text-base font-semibold">About Me</div>
        <p>{provider?.profileSummary}</p>
      </div>
      <div className="p-5 bg-white rounded-2xl flex flex-col gap-2">
        <div className="text-black text-base font-semibold">My Focus Areas</div>

        <ul className="list-disc marker:text-primary ml-5 mt-2">
          {provider?.focusAreas?.map((f, index) => (
            <li key={index} className="text-black text-base font-light mb-2">
              {focusAreas.find((area) => area.value === f)?.label || f}
            </li>
          ))}
        </ul>
      </div>
      <div className="p-5 bg-white rounded-2xl flex flex-col gap-2">
        <div className="text-black text-base font-semibold">
          Accepted Insurance
        </div>
        <div className="flex items-center flex-wrap gap-1.5">
          {provider?.acceptedInsurances?.map((practice, index) => (
            <div key={index} className="bg-secondary rounded-full px-5 py-2">
              <span className="text-black text-base font-light">
                {acceptedInsurances.find((area) => area.value === practice)
                  ?.label || practice}
              </span>
            </div>
          ))}
          {provider?.acceptedInsurances?.length === 0 && (
            <div className="text-black text-base font-light">Not Available</div>
          )}
        </div>
      </div>
      <div className="p-5 bg-white rounded-2xl flex flex-col gap-2">
        <div className="text-black text-base font-semibold">
          Where I Practice
        </div>
        <div className="flex items-center flex-wrap gap-1.5">
          {provider?.practiceAreas?.map((practice, index) => (
            <div key={index} className="bg-secondary rounded-full px-5 py-2">
              <span className="text-black text-base font-light">
                {practice}
              </span>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
