import { useEffect, useState } from "react";
import { Button } from "../Button";
import { PopupModal } from "../PopupModal";
import { api } from "../../services/api";
import { TextInput } from "../TextInput";
import { ShowToast } from "../../services/toast";
import { Loading } from "../Loading/Loading";
import { ReactComponent as CloseIcon } from "../../assets/icons/x.svg";

export function SettingsModal({ onClose }) {
  const [edit, setEdit] = useState(false);
  const [config, setConfig] = useState<any>([]);
  const [form, setForm] = useState<any>({});
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);

  const fetchConfigs = async (shouldLoading = true) => {
    shouldLoading && setLoading(true);
    const response = await api.getConfig();
    setConfig(response.config);
    setLoading(false);
  };

  const handleEdit = () => {
    setEdit(true);
    setForm(config || {});
  };

  const handleSave = async () => {
    // check if form is changed or not
    if (
      form.familyInfoEmails === config.familyInfoEmails &&
      form.familyInfoSubject === config.familyInfoSubject
    ) {
      setEdit(false);
      return;
    }
    try {
      setSaving(true);
      const response = await api.saveConfig(form);
      if ([200, 201].includes(response.status)) {
        ShowToast({
          type: "success",
          message: "Settings Saved!",
        });
        await fetchConfigs(false);
        setSaving(false);
        setEdit(false);
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (error: any) {
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    fetchConfigs();
  }, []);

  return (
    <PopupModal
      onClose={onClose}
      contentClassName="p-8 z-[2] w-[95%] md:w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]"
    >
      <div className="flex flex-col w-full">
        <div className="flex justify-between items-center mb-7">
          <h1 className="text-black text-xl font-semibold">Settings</h1>
          <button onClick={onClose} className="group">
            <CloseIcon />
          </button>
        </div>
        {loading ? (
          <div className="w-full flex justify-center my-5">
            <Loading />
          </div>
        ) : (
          <>
            <div className="flex flex-col gap-2">
              <div className="flex justify-between items-center">
                <span className="text-lg font-medium">
                  Email Configuration:
                </span>

                {!edit ? (
                  <Button variant="link" onClick={handleEdit}>
                    Edit
                  </Button>
                ) : (
                  <Button
                    variant="link"
                    disabled={
                      !form.familyInfoEmails ||
                      !form.familyInfoSubject ||
                      saving
                    }
                    loading={saving}
                    onClick={handleSave}
                  >
                    Save
                  </Button>
                )}
              </div>
              {!edit ? (
                <div>
                  <div className="w-full p-[18px] bg-[#E1F9F1]/80 rounded-md flex flex-col gap-2">
                    <span className="text-base">
                      Email: {config?.familyInfoEmails}
                    </span>
                    <span className="text-base">
                      Subject: {config?.familyInfoSubject}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="flex flex-col gap-2">
                  <div className="relative">
                    <label htmlFor="email" className="text-sm text-black/80">
                      Email:
                    </label>
                    <TextInput
                      placeholder="Add an email"
                      onChange={(e) =>
                        setForm((prev) => ({
                          ...prev,
                          familyInfoEmails: e.target.value,
                        }))
                      }
                      value={form.familyInfoEmails}
                    />
                  </div>
                  <div className="relative">
                    <label htmlFor="email" className="text-sm text-black/80">
                      Subject:
                    </label>
                    <TextInput
                      placeholder="Add a subject"
                      onChange={(e) =>
                        setForm((prev) => ({
                          ...prev,
                          familyInfoSubject: e.target.value,
                        }))
                      }
                      value={form.familyInfoSubject}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </PopupModal>
  );
}
