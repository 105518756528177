import { RbtRequest, Shift } from "../rbtRequests/RequestRbt.types";

export enum PreferredContactMethod {
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  OTHER = "OTHER",
}

export enum WorkPreference {
  FULLTIME = "FULLTIME",
  PARTTIME = "PARTTIME",
  EITHER = "EITHER",
}

export enum RbtCandidateStatus {
  UNASSIGNED = "UNASSIGNED",
  POOR_FIT = "POOR_FIT",
  PROVIDER_REVIEW = "PROVIDER_REVIEW",
  NEGOTIATING = "NEGOTIATING",
  REJECTED = "REJECTED",
  ACCEPTED = "ACCEPTED",
  INACTIVE = "INACTIVE",
}

export const rbtCandidateStatusToSentenceCase = (status: RbtCandidateStatus): string => {
  return status
    .toLowerCase()
    .replace(/_/g, " ")
    .replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
};

export const getRbtCandidateStatusByValue = (enumValue) => {
  let keys = Object.keys(RbtCandidateStatus).filter((x) => RbtCandidateStatus[x] === enumValue);
  return keys.length > 0 ? keys[0] : undefined;
};

export const rbtCandidateStatusColor = (status: RbtCandidateStatus) => {
  switch (status) {
    case RbtCandidateStatus.UNASSIGNED:
      return "grey";
    case RbtCandidateStatus.POOR_FIT:
      return "rgb(239 68 68)"; //red
    case RbtCandidateStatus.PROVIDER_REVIEW:
      return "rgb(249 115 22)"; //orange
    case RbtCandidateStatus.NEGOTIATING:
      return "rgb(204,204,0)";
    case RbtCandidateStatus.REJECTED:
      return "rgb(239 68 68)"; //red
    case RbtCandidateStatus.ACCEPTED:
      return "green";
    case RbtCandidateStatus.INACTIVE:
      return "rgb(239 68 68)"; //red
  }
};

export type RbtCandidate = {
  id?: string;
  isActive: boolean;
  firstName: string;
  lastName: string;
  email?: string;
  mobile?: string;
  preferredContact: PreferredContactMethod;
  workPreference: WorkPreference;
  targetWeeklyHours: number | "";
  scheduleAvailability: Array<Shift>;
  comments?: string;
  resumeUrl?: string;
  createdAt?: Date;
  assignedDate?: Date;
  reviewDeadline?: Date;
  rejections?: [
    {
      hadOffer?: boolean;
      rejectReason: string;
      rbtRequestId: string;
      rbtRequestOwnerId: string;
      rbtRequestOwner?: {
        firstName: string;
        lastName: string;
      };
      rejectionDate: Date;
    }
  ];
  assignedRequestId?: string;
  assignedRequest?: RbtRequest;
  status?: RbtCandidateStatus;
};
