import Modal from "../../../components/Modal";
import { useState } from "react";
import { EditProfile } from "./EditProfile";
import { ProviderProfileView } from "./Profile";

export function ProviderProfile({
  onClose,
  isOpen,
  provider,
  onRefresh,
  onEditUpdate,
}) {
  const [edit, setEdit] = useState(false);

  const onEditClose = () => {
    setEdit(false);
  };

  const onEdit = () => {
    setEdit(true);
  };

  return (
    <Modal isOpen={isOpen} handleModal={onClose}>
      {edit ? (
        <EditProfile
          provider={provider}
          onUpdate={(updatedData: any) => {
            onEditClose();
            onRefresh(undefined, undefined, true);
            onEditUpdate(updatedData);
          }}
          onBack={onEditClose}
        />
      ) : (
        <ProviderProfileView
          provider={provider}
          onClose={onClose}
          onEdit={onEdit}
          onRefresh={onRefresh}
        />
      )}
    </Modal>
  );
}
