import { ReactComponent as CalendarIcon } from "../assets/icons/calendar.svg";
import DatePickerComponent from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

export function DatePicker({
  value,
  onChange,
  className = "",
  minDate,
  maxDate,
  disabled,
  minHeight,
  height,
}: {
  value: any;
  onChange: (value: any) => void;
  className?: string;
  minDate?: Date;
  maxDate?: Date;
  disabled?: boolean;
  minHeight?: string;
  height?: string;
}) {
  return (
    <div className={`date-picker ${className}`}>
      <DatePickerComponent
        onChange={onChange}
        selected={value}
        icon={
          <div>
            <CalendarIcon />
          </div>
        }
        showIcon={true}
        placeholderText="mm/dd/yyyy"
        minDate={minDate}
        maxDate={maxDate}
        disabled={disabled}
        popperPlacement="bottom-end"
        className={`!outline-none w-full bg-ultraLightGray focus:bg-white rounded-lg ${disabled && 'text-gray-400'}
           border border-lightGray focus-within:border-grey2 focus:border-grey2 p-3 text-base text-black [&>div]:!border-none`}
        style={{ minHeight: minHeight ?? '50px', height: height && height, padding: '0' }}
      />
    </div>
  );
}
