import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as TrashIcon } from "../../../assets/icons/trash.svg";
import { Button } from "../../../components/Button";
import { useEffect, useMemo, useRef, useState } from "react";
import { TextInput } from "../../../components/TextInput";
import { TextArea } from "../../../components/Textarea";
import { ChipInput } from "../../../components/ChipInput";
import { ShowToast } from "../../../services/toast";
import { fileUpload } from "../../../services/file-upload";
import { api } from "../../../services/api";
import {
  getLocationListForSelect,
  validateEmail,
  validatePhone,
} from "../../../services/utils";
import { SelectInput } from "../../../components/SelectInput";
import Modal from "../../../components/Modal";
import { ImageView } from "../../../components/Image";
import { ImageCrop } from "../../../components/ImageCropper/ImageCrop";
import { acceptedInsurances, focusAreas } from "../../../constant";
import { MultiSelect } from "../components/MultiSelect";
import { zipCodes } from "../../rbtRequests/modals/zip_codes";

export function AddNewProvider({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: (val?: boolean) => void;
}) {
  const profileImgRef = useRef<HTMLInputElement | null>(null);
  const [profileImg, setProfileImg] = useState<any>(null);
  const [errors, setErrors] = useState({} as any);
  const [saving, setSaving] = useState(false);
  const [form, setForm] = useState<any>({
    userType: "P",
    isAvailable: false,
    focusAreas: [],
    acceptedInsurances: [],
    practiceAreas: [],
    addressState: "",
    addressZip: "",
  });
  const [showCropModal, setShowCropModal] = useState(false);

  const handleCrop = () => {
    setShowCropModal(true);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;

    if (name === 'addressZip') {
      if (isZipValid(e.target.value)) {
        setErrors({ ...errors, 'zipCode': "" });
      }
    }
    if (name === "mobile") {
      // allow only number
      const regex = /^[0-9\b]+$/;
      if (value === "" || regex.test(value)) {
        if (value && !validatePhone(value.toString())) {
          setErrors((prev) => ({
            ...prev,
            mobile: "Invalid mobile number",
          }));
        } else {
          setErrors((prev) => ({ ...prev, mobile: "" }));
        }
        setForm((prev) => ({ ...prev, [name]: value }));
        return;
      } else {
        return;
      }
    }
    if (name === "email") {
      if (value && !validateEmail(value)) {
        setErrors((prev) => ({
          ...prev,
          email: "Invalid email format",
        }));
      } else {
        setErrors((prev) => ({ ...prev, email: "" }));
      }
    }
    setForm((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleFocusChange = (e: any, index: number) => {
    const { value } = e.target;
    setForm((prev: any) => {
      const focusAreas = prev.focusAreas.map((f: any, i: number) =>
        i === index ? value : f
      );
      return { ...prev, focusAreas };
    });
  };

  const handleAddFocus = () => {
    setForm((prev: any) => ({
      ...prev,
      focusAreas: [...prev.focusAreas, ""],
    }));
  };

  const handleRemove = (index: number) => {
    setForm((prev: any) => ({
      ...prev,
      focusAreas: prev.focusAreas.filter((_, i) => i !== index),
    }));
  };

  const handleSubmit = async (e) => {
    if (!form.addressZip || !isZipValid(form.addressZip)) {
      setErrors({ ...errors, 'zipCode': "Please provide provider's valid five digit zip code" });
      return
    }

    e.preventDefault();
    const payload: any = {
      email: form.email,
      mobile: form.mobile,
      userType: form.userType,
      firstName: form.firstName,
      lastName: form.lastName,
      addressState: form.addressState,
      addressZip: form.addressZip,
      profession:
        form?.profession?.split(",")?.map((p: string) => p.trim()) || [],
      focusAreas: form.focusAreas,
      practiceAreas: form.practiceAreas,
      acceptedInsurances: form.acceptedInsurances,
      isAvailable: form.isAvailable,
      profileSummary: form.profileSummary,
      isActive: true,
    };
    try {
      setErrors({});
      setSaving(true);

      const response = await api.signupProvider(payload);
      setSaving(false);
      const result = response;
      if ([200, 201].includes(result.status)) {
        if (profileImg) {
          setSaving(true);
          const frontSideResponse = await fileUpload(
            profileImg,
            result.data.id,
            "USER_PROFILE"
          );
          if (frontSideResponse.url) {
            const updatePayload = {
              profileImg: frontSideResponse.url,
            };
            const updateResponse = await api.saveUserProfile(
              result.data.id,
              updatePayload
            );
          }
          setSaving(false);
        }
        ShowToast({
          type: "success",
          message: "Profile Created!",
        });
        onClose(true);
      }
    } catch (error: any) {
      setSaving(false);
      if (error?.response?.data?.message?.includes("Email is already in use")) {
        setErrors({ ...errors, email: "Email already used. Use new email" })
      } else if (error?.response?.data?.message) {
        ShowToast({
          type: "error",
          message: error?.response?.data?.message ?? "Something went wrong",
        });
      }
      return false;
    }
  };

  const isZipValid = (code: string) => code && (code.trim().length === 5 && !isNaN(parseInt(code, 10)) && !!zipCodes[code])
  const [zipCity, zipState, zipFullState] = useMemo(() => {
    return isZipValid(form.addressZip) ? [zipCodes[form.addressZip]?.city, zipCodes[form.addressZip]?.state_short, zipCodes[form.addressZip]?.state] : [undefined, undefined];
  }, [form.addressZip]);

  useEffect(() => {
    if (isOpen) {
      setForm({
        userType: "P",
        isAvailable: false,
        focusAreas: [],
        acceptedInsurances: [],
        practiceAreas: [],
        addressState: "",
      });
    }
  }, [isOpen]);

  const url = profileImg ? URL.createObjectURL(profileImg) : form.profileImg;

  const disabled = useMemo(() => {
    return (
      !form.firstName ||
      !form.lastName ||
      !form.email ||
      !form.mobile ||
      !form.addressZip ||
      form?.profession?.length === 0 ||
      form?.focusAreas?.length === 0 ||
      form?.practiceAreas?.length === 0
      // form?.acceptedInsurances?.length === 0
    );
  }, [form]);

  return (
    <Modal isOpen={isOpen} handleModal={() => onClose()}>
      <div className="max-w-[820px] mx-auto flex flex-col mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-black text-2xl font-bold">Add provider</span>
          </div>
          <Button
            variant="primary"
            className="!text-sm !font-medium !rounded-full"
            onClick={handleSubmit}
            disabled={disabled || saving}
            loading={saving}
          >
            <CheckIcon className="[&_path]:stroke-white" />
            Add
          </Button>
        </div>
      </div>
      <div
        id="provider-scroll"
        className="max-h-[calc(100vh-150px)] lg:max-h-[calc(100vh-95px)] overflow-auto"
      >
        <div className="max-w-[820px] mx-auto flex flex-col gap-4">
          <div className="pt-flex pt-5 px-5 bg-white rounded-2xl flex-col">
            <div className="text-black text-base font-semibold">
              Personal Details
            </div>
            <div className="border-b border-b-[#D4E1DD] w-full my-5" />
            <div className="items-start md:items-center gap-6 flex flex-col md:flex-row justify-between">
              <div className="flex gap-3.5 items-center">
                <input
                  type="file"
                  onChange={(e) => {
                    if (e.target.files?.[0]) {
                      setProfileImg(e.target.files[0]);
                    }
                  }}
                  id="profileImg"
                  className="hidden"
                  accept="image/png, image/jpeg"
                  ref={(e) => (profileImgRef.current = e)}
                />
                <ImageView
                  className="w-24 h-24 rounded-[10px] object-cover"
                  src={url || "/images/admin.png"}
                  alt="user"
                  loading="lazy"
                />

                <button
                  onClick={() => profileImgRef.current?.click()}
                  className="text-primary text-base font-medium"
                >
                  Upload Photo
                </button>
                {profileImg && (
                  <button
                    onClick={() => handleCrop()}
                    className="text-primary text-base font-medium"
                  >
                    Edit Photo
                  </button>
                )}
              </div>
              <div className="pl-4 pr-5 py-3.5 bg-white rounded-md border border-teal-500 border-opacity-25 justify-start items-center gap-1.5 inline-flex">
                <div className="inline-flex items-center">
                  <div className="relative inline-block w-8 h-4 rounded-full cursor-pointer">
                    <input
                      id="switch-component"
                      type="checkbox"
                      onChange={(e) =>
                        setForm((prev: any) => ({
                          ...prev,
                          isAvailable: e.target.checked,
                        }))
                      }
                      className="absolute w-8 h-4 transition-colors duration-300 rounded-full appearance-none cursor-pointer peer bg-[#E8F3F2] checked:bg-primary"
                      checked={form.isAvailable}
                    />
                    <label
                      htmlFor="switch-component"
                      className="before:content[''] absolute top-2/4 -left-1 h-5 w-5 -translate-y-2/4 cursor-pointer rounded-full bg-white shadow-md transition-all duration-300 before:absolute before:top-2/4 before:left-2/4 before:block before:h-10 before:w-10 before:-translate-y-2/4 before:-translate-x-2/4 before:opacity-0 before:transition-opacity before:shadow-[10px_14px_40px_0px_rgba(0,71,28,0.11)]  hover:before:opacity-0 peer-checked:translate-x-full peer-checked:before:bg-primary"
                    >
                      <div
                        className="inline-block p-5 rounded-full top-2/4 left-2/4 -translate-x-2/4 -translate-y-2/4"
                        data-ripple-dark="true"
                      ></div>
                    </label>
                  </div>
                </div>
                <div className="text-black text-sm font-normal font-['Outfit'] leading-tight">
                  Accepting New Clients
                </div>
              </div>
            </div>
            <div className="border-b border-b-[#D4E1DD] w-full my-5" />
            <div className="flex flex-col md:flex-row items-center justify-center gap-3 mb-5">
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">First Name</label>
                <TextInput
                  name="firstName"
                  value={form.firstName}
                  onChange={handleChange}
                  placeholder="Enter first name"
                  maxLength={128}
                />
              </div>
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">Last Name</label>
                <TextInput
                  name="lastName"
                  value={form.lastName}
                  onChange={handleChange}
                  placeholder="Enter last name"
                  maxLength={128}
                />
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center gap-3 mb-5">
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">Role</label>
                <TextInput
                  name="profession"
                  value={form.profession}
                  onChange={handleChange}
                  placeholder="eg. MS, BCBA, LBA"
                  maxLength={128}
                />
              </div>

              <div className={`flex-1 w-full flex flex-col gap-1.5 ${zipCity ? 'mt-5' : ''}`}>
                <label className="text-sm text-black/80">
                  Zip Code
                </label>
                <TextInput
                  name="addressZip"
                  value={form.addressZip}
                  onChange={handleChange}
                  placeholder="Enter Zip Code"
                />
                {zipCity && <div className="text-xs">City: {zipCity}, {zipState}</div>}
                {errors.zipCode && (
                  <div className="text-red-500 text-xs font-medium">
                    {errors.zipCode}
                  </div>
                )}
              </div>

              {/* <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">Location</label>

                <SelectInput
                  data={[...getLocationListForSelect()]}
                  value={form.addressState}
                  onChange={(e) =>
                    setForm((prev: any) => ({
                      ...prev,
                      addressState: e.target.value,
                    }))
                  }
                  className={`${!form.addressState ? "!text-[#8D8E92]" : ""}`}
                />
              </div> */}
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center gap-3 pb-2">
              <div className="flex-1 w-full flex flex-col gap-1.5 pb-5 relative">
                <label className="text-sm text-black/80">Email</label>
                <TextInput
                  name="email"
                  value={form.email}
                  onChange={handleChange}
                  placeholder="Enter your email address"
                  maxLength={128}
                />
                {errors.email && (
                  <div className="absolute bottom-0 text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    {errors.email}
                  </div>
                )}
              </div>
              <div className="flex-1 w-full flex flex-col gap-1.5 pb-5 relative">
                <label className="text-sm text-black/80">Phone Number</label>
                <TextInput
                  type="tel"
                  placeholder="Enter your phone number"
                  value={form.mobile}
                  onChange={handleChange}
                  name="mobile"
                  maxLength={10}
                />
                {errors.mobile && (
                  <div className="absolute bottom-0 text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    {errors.mobile}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <div className="text-black text-base font-semibold">About Me</div>
            <TextArea
              name="profileSummary"
              value={form.profileSummary}
              onChange={handleChange}
              placeholder="Enter about me"
              maxLength={1000}
              rows={5}
            />
          </div>
          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <div className="text-black text-base font-semibold">
              My Focus Areas
            </div>
            <MultiSelect
              options={focusAreas}
              value={form.focusAreas || []}
              name="focusAreas"
              onChange={(selectedOptions) => {
                setForm((prev: any) => ({
                  ...prev,
                  focusAreas: selectedOptions.map((o) => o.value),
                }));
              }}
            />
          </div>
          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <div className="text-black text-base font-semibold">
              Accepted Insurance
            </div>
            <MultiSelect
              disabled={!zipFullState}
              options={acceptedInsurances.filter(insurance => insurance.states.includes(zipFullState))}
              value={form.acceptedInsurances || []}
              name="acceptedInsurances"
              onChange={(selectedOptions) => {
                setForm((prev: any) => ({
                  ...prev,
                  acceptedInsurances: selectedOptions.map((o) => o.value),
                }));
              }}
            />
          </div>

          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <div className="text-black text-base font-semibold">
              Where I Practice
            </div>

            <div className="border border-[#8D8E92] rounded-md p-3.5">
              <ChipInput
                chips={form.practiceAreas || []}
                placeholder="Enter Practice Areas"
                onSubmit={(chip: string) => {
                  if (
                    chip.trim() !== "" &&
                    !(form.practiceAreas || []).includes(chip)
                  ) {
                    setForm((prev: any) => ({
                      ...prev,
                      practiceAreas: [...(prev.practiceAreas || []), chip],
                    }));
                  }
                }}
                onRemove={(index: number) =>
                  setForm((prev: any) => ({
                    ...prev,
                    practiceAreas: prev.practiceAreas.filter(
                      (_, i) => i !== index
                    ),
                  }))
                }
              />
            </div>
          </div>
        </div>
      </div>
      {
        showCropModal && (
          <ImageCrop
            isOpen={showCropModal}
            onClose={() => {
              setShowCropModal(false);
            }}
            image={profileImg}
            onImageChange={({ url, file }) => setProfileImg(file)}
          />
        )
      }
    </Modal >
  );
}
