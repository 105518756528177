import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as PinIcon } from "../../../assets/icons/pin.svg";
import { Button } from "../../../components/Button";
import { useEffect, useState } from "react";
import { TextInput } from "../../../components/TextInput";
import { TextArea } from "../../../components/Textarea";
import { ShowToast } from "../../../services/toast";
import { fileUpload } from "../../../services/file-upload";
import { api } from "../../../services/api";
import {
  getUser,
  youtubeParser,
} from "../../../services/utils";
import Modal from "../../../components/Modal";
import { FileUploader } from "react-drag-drop-files";
import { DOCUMENT_OWNER_TYPES } from "../../../constant";
import { Bulletin } from "../hooks/useBulletins";
import RichEditor from "../../../components/RichTextEditor";

export function EditBulletinModal({
  isOpen,
  onClose,
  bulletin
}: {
  isOpen: boolean;
  onClose: (val?: boolean) => void;
  bulletin?: Bulletin;
}) {

  const user = getUser();
  const [errors, setErrors] = useState({} as any);
  const [saving, setSaving] = useState(false);

  const [showRightFeatureSection, setShowRightFeatureSection] = useState(false)
  const [existingRightFeatureFile, setExistingRightFeatureFile] = useState<string | undefined>(undefined)
  const [existingRightYoutube, setExistingRightYoutube] = useState<string>("")
  const [rightFeatureFile, setRightFeatureFile] = useState<File | undefined>(undefined);
  const [rightFeatureYoutubeLink, setRightFeatureYoutubeLink] = useState<string>("");
  const [rightFeatureYoutubeLinkForSubmit, setRightFeatureYoutubeLinkForSubmit] = useState<string>("");
  const [rightFeatureYouTubeMode, setRightFeatureYouTubeMode] = useState(false)

  const [files, setFiles] = useState<File[]>([]);
  const [clearAlreadyUploadedFiles, setClearAlreadyUploadedFiles] = useState(false);
  const [youtubeFiles, setYoutubeFiles] = useState<Array<{ name: string; url: string; }>>([]);
  const [youtubeLinkMode, setYoutubeLinkMode] = useState(false);
  const [youtubeLink, setYoutubeLink] = useState<string>("");
  const [videoName, setVideoName] = useState<string>("");
  const [form, setForm] = useState<any>({
    title: bulletin?.title,
    description: bulletin?.description
  });

  useEffect(() => {
    if (bulletin?.previewImg) {
      setShowRightFeatureSection(true)
      if (youtubeParser(bulletin.previewImg)) {
        setRightFeatureYoutubeLinkForSubmit(bulletin.previewImg)
        setRightFeatureYouTubeMode(true)
        setExistingRightYoutube(bulletin.previewImg)
      } else {
        setExistingRightFeatureFile(bulletin.previewImg)
        setRightFeatureYouTubeMode(false)
      }
    }
  }, [])

  const hasExistingYoutubeVideos = (bulletin?.attachedDocs?.filter(doc => doc.type === "youtubelink") ?? []).length > 0;

  const handleFileChange = (fileList: File[]) => {
    setFiles([...files, ...fileList].filter(
      (value, index, self) =>
        index === self.findIndex((t) => t.name === value.name)
    )
    )
  };

  const handleRightFeatureFileChange = (file: File) => {
    setRightFeatureFile(file)
    setExistingRightFeatureFile(undefined)
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setForm((prev: any) => ({ ...prev, [name]: value }));
  };

  const handleAddYoutube = () => {
    let toAdd = true
    const linkRegex = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/gmi;
    if (!linkRegex.test(youtubeLink)) {
      setErrors({ ...errors, youtubeLink: "Invalid YouTube Link" })
      toAdd = false
    }
    if (videoName.trim().length === 0) {
      setErrors({ ...errors, videoName: "Video Name Required" })
      toAdd = false
    }
    if (toAdd) {
      setYoutubeFiles([...youtubeFiles, { name: videoName, url: youtubeLink }].filter(
        (value, index, self) =>
          index === self.findIndex((t) => t.url === value.url)
      ))
      setErrors({ ...errors, youtubeLink: undefined })
    }
  }

  const handleAddRightFeatureYoutube = () => {
    const linkRegex = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/gmi;
    if (!linkRegex.test(rightFeatureYoutubeLink)) {
      setErrors({ ...errors, rightFeatureYoutubeLink: "Invalid YouTube Link" })
    } else {
      setRightFeatureYoutubeLinkForSubmit(rightFeatureYoutubeLink)
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (form.title?.length === 0) {
      setErrors({ ...errors, title: "Please provide a title" })
      return
    }
    if (form.description?.length === 0) {
      setErrors({ ...errors, description: "Please provide a description" })
      return
    }

    let rightFeatureFileUrl;
    try {
      if (!rightFeatureYouTubeMode && rightFeatureFile) {
        setSaving(true);
        rightFeatureFileUrl = (await fileUpload(rightFeatureFile, user?.id, "BULLETIN")).url
      }
    } catch (err: any) {
      ShowToast({
        type: "error",
        message: err.response.data.message || "Failed to upload file",
      });
      setSaving(false);
    }



    const payload: any = {
      ...bulletin,
      userId: user.id,
      title: form.title,
      description: form.description,
      isActive: true,
      previewImg: rightFeatureYouTubeMode ? rightFeatureYoutubeLinkForSubmit : (rightFeatureFile ? rightFeatureFileUrl : existingRightFeatureFile)
    };
    try {
      setErrors({});
      setSaving(true);
      await api.updateBulletin(payload);
      setSaving(false);

      if (files?.length > 0) {
        setSaving(true);
        try {
          for (let i = 0; i < files.length; i++) {
            const file = files[i];
            const fileResponse = await fileUpload(file, user?.id, "BULLETIN");
            const payload = {
              userId: user.id,
              name: file.name,
              ownerType: DOCUMENT_OWNER_TYPES.ADMIN,
              type: "file",
              folderId: null,
              url: fileResponse.url,
              mimeType: file.type,
              isAccessibleByProviders: true,
              bulletinId: bulletin?.id,
            };
            const response = await api.createDocument(payload);
            if ([200, 201].includes(response.status)) {
            } else {
              ShowToast({
                type: "error",
                message: "Unable to upload file, please try again",
              });
            }
          }
          setSaving(false)
        } catch (err: any) {
          setErrors({ ...errors, files: err.response.data.message || "Failed to upload file" })
          ShowToast({
            type: "error",
            message: err.response.data.message || "Failed to upload file",
          });
        }
      }

      if (youtubeFiles.length > 0) {
        await Promise.all(youtubeFiles.map(async youtube => {
          await api.createDocument({
            userId: user.id,
            name: youtube.name,
            ownerType: DOCUMENT_OWNER_TYPES.ADMIN,
            type: "youtubelink",
            folderId: null,
            url: youtube.url,
            isAccessibleByProviders: true,
            bulletinId: bulletin?.id,
          })
        }))
      }

      ShowToast({
        type: "success",
        message: "Bulletin Edited!",
      });
      onClose(true);
      if (clearAlreadyUploadedFiles && bulletin && bulletin.attachedDocs) {
        await Promise.all(bulletin.attachedDocs.map((doc) => api.deleteDocument(doc.id)))
        onClose(true);
      }
    } catch (error: any) {
      setSaving(false);
      return false;
    }
  };

  const disabled =
    (!form.title || (form.title === bulletin?.title))
    && (!form.description || (form.description === bulletin?.description))
    && files.length === 0 && !clearAlreadyUploadedFiles
    && youtubeFiles.length === 0
    && !rightFeatureFile
    && (existingRightYoutube ? existingRightYoutube === rightFeatureYoutubeLinkForSubmit : rightFeatureYoutubeLinkForSubmit.length === 0)
  const youtubeAddDisabled = youtubeLink.length === 0 || videoName.length === 0;

  return (
    <Modal isOpen={isOpen} handleModal={() => onClose()}>
      <div className="max-w-[820px] mx-auto flex flex-col mb-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center">
            <span className="text-black text-2xl font-bold">Edit Bulletin</span>
          </div>
          <Button
            variant="primary"
            className="!text-sm !font-medium !rounded-full"
            onClick={handleSubmit}
            disabled={disabled || saving}
            loading={saving}
          >
            <CheckIcon className="[&_path]:stroke-white" />
            Save
          </Button>
        </div>
      </div>
      <div
        id="provider-scroll"
        className="max-h-[calc(100vh-150px)] lg:max-h-[calc(100vh-95px)] overflow-auto"
      >
        <div className="max-w-[820px] mx-auto flex flex-col gap-4">
          <div className="pt-flex pt-5 px-5 bg-white rounded-2xl flex-col">
            <div className="flex flex-col md:flex-row items-center justify-center gap-3 mb-5">
              <div className="flex-1 w-full flex flex-col gap-1.5">
                <label className="text-sm text-black/80">Title</label>
                <TextInput
                  name="title"
                  value={form.title}
                  onChange={handleChange}
                  placeholder="Enter your new Bulletin Title"
                  maxLength={250}
                  disabled={saving}
                />
                {errors.title && (
                  <div className="absolute bottom-0 text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    {errors.title}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center gap-3 pb-2">
              <div className="flex-1 w-full flex flex-col gap-1.5 pb-5 relative">
                <label className="text-sm text-black/80">Description</label>
                <RichEditor
                  value={form.description}
                  onChange={(value) => {
                    setForm((prev: any) => ({ ...prev, description: value }));
                  }}
                  onSend={() => { }}
                  className="w-full"
                />

                {errors.description && (
                  <div className="absolute bottom-0 text-red-500 text-xs font-medium mt-2 flex items-center gap-1">
                    {errors.description}
                  </div>
                )}
              </div>
            </div>
          </div>


          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">

            {showRightFeatureSection ? <>
              <span className="text-black/80 text-sm">Add Right Display Image/Youtube</span>

              {rightFeatureYouTubeMode ?
                <>
                  {rightFeatureYoutubeLinkForSubmit.length > 0 && <div className="inline-block">
                    Youtube Video Added: <a className="underline text-blue-500" target="_blank" href={rightFeatureYoutubeLinkForSubmit} rel="noreferrer">{rightFeatureYoutubeLinkForSubmit}</a>
                  </div>}
                  {rightFeatureYoutubeLinkForSubmit.length === 0 &&
                    <>
                      <TextInput
                        placeholder="Enter YouTube Link URL"
                        className="!p-[14px]"
                        name="youtubelink"
                        value={rightFeatureYoutubeLink}
                        onChange={(e) => setRightFeatureYoutubeLink(e.target.value)}
                      />
                      {errors.rightFeatureYoutubeLink && (
                        <div className="text-red-500 text-xs font-medium">
                          {errors.rightFeatureYoutubeLink}
                        </div>
                      )}
                      <Button
                        variant="primary"
                        className="!text-sm !font-medium !rounded-full"
                        onClick={handleAddRightFeatureYoutube}
                        disabled={rightFeatureYoutubeLink.trim().length === 0}
                        loading={saving}
                      >
                        <CheckIcon className="[&_path]:stroke-white" />
                        Add Youtube Video
                      </Button>

                      <span className="underline cursor-pointer inline-block" onClick={() => {
                        setRightFeatureYoutubeLink("")
                        setErrors({ ...errors, rightFeatureYoutubeLink: undefined })
                        setRightFeatureYouTubeMode(false)
                      }}>Add Image</span>
                    </>
                  }
                </>
                :
                <>
                  {!rightFeatureFile?.name && existingRightFeatureFile ? (<div>
                    Added: <img style={{ maxWidth: "300px" }} src={existingRightFeatureFile} />
                  </div>)
                    :
                    <FileUploader handleChange={handleRightFeatureFileChange} name="rightFeatureFile" types={["png", "jpg", "jpeg", "gif", "webp"]}>
                      <div className="cursor-pointer p-4 h-[114px] bg-[#E8F3F2] w-full rounded-xl border border-primary justify-center items-center gap-1 flex">
                        <PinIcon className="w-5 h-5" />
                        <div className="text-primary text-base font-normal font-['Outfit'] leading-normal">
                          {(rightFeatureFile || existingRightFeatureFile)
                            ? (!rightFeatureFile?.name && existingRightFeatureFile) ? <a href={existingRightFeatureFile}>[Existing Image]</a>
                              : `Added: ${rightFeatureFile?.name}`
                            : "Click to add or drag and drop Image"}
                        </div>
                      </div>
                    </FileUploader>
                  }

                  {!rightFeatureFile &&
                    <span className="underline cursor-pointer inline-block" onClick={() => {
                      setRightFeatureYouTubeMode(true)
                      setRightFeatureFile(undefined)
                    }}>Add Youtube Video</span>
                  }


                </>}

              {(rightFeatureYoutubeLinkForSubmit.length > 0 || rightFeatureFile || existingRightFeatureFile) &&
                <>
                  <div className="float-right text-red-500 text-right cursor-pointer inline-block" onClick={() => {
                    setRightFeatureFile(undefined)
                    setRightFeatureYouTubeMode(false)
                    setRightFeatureYoutubeLink("")
                    setRightFeatureYoutubeLinkForSubmit("")
                    setExistingRightFeatureFile(undefined)
                    setErrors({ ...errors, rightFeatureYoutubeLink: undefined })
                    setShowRightFeatureSection(false)
                  }}>Remove</div>
                  {errors.files && (
                    <div className="text-red-500 text-xs font-medium">
                      {errors.files}
                    </div>
                  )}
                </>
              }

            </> :
              (<span className="underline cursor-pointer inline-block" onClick={() => setShowRightFeatureSection(true)}>Add Right Feature Image/Youtube</span>)

            }

          </div>


          <div className="flex p-5 bg-white rounded-2xl flex-col gap-4">
            <span className="text-black/80 text-sm">Add Documents/Images</span>
            <FileUploader handleChange={handleFileChange} name="file" multiple disabled={saving}>
              <div className="cursor-pointer p-4 h-[114px] bg-[#E8F3F2] w-full rounded-xl border border-primary justify-center items-center gap-1 flex">
                <PinIcon className="w-5 h-5" />
                <div className="text-primary text-base font-normal font-['Outfit'] leading-normal">
                  {[...((clearAlreadyUploadedFiles ? [] : bulletin?.attachedDocs) ?? []), ...files].length > 0
                    ? `${[...((clearAlreadyUploadedFiles ? [] : bulletin?.attachedDocs) ?? []), ...files].length} files added: ${[...((clearAlreadyUploadedFiles ? [] : bulletin?.attachedDocs) ?? []), ...files]?.map(file => file.name)?.join(", ")}`
                    : "Click to add or drag and drop"}
                </div>
              </div>
            </FileUploader>
            {!saving && <>
              {hasExistingYoutubeVideos && <>
                <span>
                  {!clearAlreadyUploadedFiles &&
                    (<span className="inline-block">Existing Youtube Videos:
                      {bulletin?.attachedDocs?.filter(doc => doc.type === "youtubelink").map(
                        (f =>
                          <a className="underline text-blue-500 mx-1" target="_blank" href={f.url} rel="noreferrer">{f.name}</a>
                        ))}
                    </span>)}
                  <div className="float-right text-red-500 text-right cursor-pointer inline-block" onClick={() => {
                    setFiles([])
                    setYoutubeFiles([])
                    setYoutubeLinkMode(false)
                    if ((bulletin?.attachedDocs?.length ?? 0) > 0) {
                      setClearAlreadyUploadedFiles(true)
                    }
                  }}>Remove All Files/Youtube</div>

                </span>
              </>}

              {!hasExistingYoutubeVideos && <>
                <div className="text-red-500 text-right cursor-pointer inline-block" onClick={() => {
                  setFiles([])
                  setYoutubeFiles([])
                  setYoutubeLinkMode(false)
                  if ((bulletin?.attachedDocs?.length ?? 0) > 0) {
                    setClearAlreadyUploadedFiles(true)
                  }
                }}>Remove All Files/Youtube</div>
                <br />
              </>}

              {errors.files && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.files}
                </div>
              )}
            </>}

            {!saving && <>
              {youtubeLinkMode ?
                <span className="underline cursor-pointer inline-block" onClick={() => setYoutubeLinkMode(false)}>Hide Youtube Section</span>
                :
                <span className="underline cursor-pointer inline-block" onClick={() => setYoutubeLinkMode(true)}>Add Youtube Video</span>
              }
            </>}

            {youtubeLinkMode && <>
              <div className="border-b border-b-[#D4E1DD] w-full mt-5" />
              <span className="text-black/80 text-sm">Youtube Videos</span>

              <span className="text-black/80 text-sm">Add Youtube Videos</span>
              {youtubeFiles.length > 0 && <>
                Youtube Videos To Add: {youtubeFiles.map((f => <a className="underline text-blue-500" target="_blank" href={f.url} rel="noreferrer">{f.name}</a>))}
                <div className="text-red-500 text-right cursor-pointer" onClick={() => setYoutubeFiles([])}>Remove Youtube Links to add</div>
              </>}
              <TextInput
                placeholder="Video Name"
                className="!p-[14px]"
                name="folderName"
                value={videoName}
                onChange={(e) => setVideoName(e.target.value)}
              />
              {errors.youtubeLink && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.videoName}
                </div>
              )}
              <TextInput
                placeholder="Enter YouTube Link URL"
                className="!p-[14px]"
                name="youtubelink"
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)}
              />
              {errors.youtubeLink && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.youtubeLink}
                </div>
              )}
              <Button
                variant="primary"
                className="!text-sm !font-medium !rounded-full"
                onClick={handleAddYoutube}
                disabled={youtubeAddDisabled}
                loading={saving}
              >
                <CheckIcon className="[&_path]:stroke-white" />
                Add Youtube Video
              </Button>
            </>}
          </div>
        </div>
      </div>
    </Modal>
  );
}
