import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { ReactComponent as SearchIcon } from "../../../assets/icons/search.svg";
import { ReactComponent as More } from "../../../assets/icons/more.svg";
import UIPopover from "../../../components/Popover";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { EmptyStates } from "../../../components/EmptyStates";
import useProviders from "../hooks/useProviders";
import Modal from "../../../components/Modal";
import { ProviderRequestOptionsDropdown } from "../../dashboard/components/PersonCard";
import { NewRequestModal, statusColorCode } from "../NewRequestModal";
import toast from "react-hot-toast";
import { ShowToast } from "../../../services/toast";
import { api } from "../../../services/api";
import { ProviderDeleteConfirm } from "../../dashboard/components/ProviderDeleteConfirm";
import { InfoWithStatusNoteTooltip } from "../components/InfoWithStatusNoteTooltip";

function LoadingSkeleton() {
  return (
    <>
      {Array.from({ length: 7 }).map((_, i) => (
        <tr className="hover:bg-gray-100 [&_td]:py-3 border-b" key={i}>
          <td className="name pl-5">
            <div className="flex items-center gap-3">
              <div className="flex flex-col">
                <div className="flex items-center gap-2.5">
                  <div className="w-28 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
                </div>
              </div>
            </div>
          </td>
          <td className="account-executive">
            <div className="w-12 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="subscription">
            <div className="w-24 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-manager">
            <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="account-manager">
            <div className="w-20 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
          <td className="pr-5">
            <div className="w-6 h-4 bg-gray-300 animate-pulse transition duration-50"></div>
          </td>
        </tr>
      ))}
    </>
  );
}

export function NewRequestList({ isOpen, onClose, onRefresh }) {
  const { updateFilters, providers, total, isLoading, loadMore, refetch } =
    useProviders();
  const [userView, setUserView] = useState<any>(null);
  const [removeUserModal, setRemoveUserModal] = useState<any>(null);

  const stopPropagate = (e: React.SyntheticEvent) => e.stopPropagation();

  const handleMarkAsAdded = async (provider) => {
    try {
      toast.loading("Marking as added...");
      const response = await api.updateUserStatus(provider.id, {
        isActive: true,
      });
      toast.dismiss();
      if ([200, 201].includes(response.status)) {
        await api.confirmUser(provider.id);
        setUserView(null);
        ShowToast({
          type: "success",
          message: "Provider Request Accepted!",
        });
        refetch(provider.id, true);
        onRefresh();
      } else {
        ShowToast({
          type: "error",
          message: response?.data?.message || "Something went wrong",
        });
      }
    } catch (error: any) {
      ShowToast({
        type: "error",
        message: error?.response?.data?.message || "Something went wrong",
      });
    }
  };

  const observerTarget = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (
          entries[0].isIntersecting &&
          providers &&
          total &&
          providers.length < total &&
          !isLoading
        ) {
          loadMore();
        }
      },
      { threshold: 1 }
    );

    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }

    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, providers, total, isLoading]);

  useEffect(() => {
    updateFilters({ showPending: true, showActive: false });
  }, []);

  return (
    <Modal isOpen={isOpen} handleModal={onClose}>
      <LoadAnimatedContainer className="max-w-[1116px] mx-auto">
        <div className="text-black text-3xl font-bold mb-5">
          New requests {isLoading ? "" : `(${total})`}
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <div className="rounded-lg border border-[#D9D9E7] focus-within:border-lightGray p-3 flex gap-[6px] items-center">
                <SearchIcon className="[&_path]:stroke-[#8D8E92] w-5 h-5" />
                <input
                  type="text"
                  placeholder="Search"
                  className="outline-none"
                  onChange={(e) => updateFilters({ keyword: e.target.value })}
                />
              </div>
            </div>

            <div className="flex gap-2 items-center"></div>
          </div>
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <colgroup>
                <col style={{ width: "24%" }} />
                <col style={{ width: "18%" }} />
                <col style={{ width: "20%" }} />
                <col style={{ width: "17%" }} />
                <col style={{ width: "15%" }} />
                <col style={{ width: "6%" }} />
              </colgroup>
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  <td className="rounded-l pl-5">
                    <div className="flex items-center">Name</div>
                  </td>
                  <td>
                    <div className="flex items-center">Phone Number</div>
                  </td>
                  <td>
                    <div className="flex items-center">Email</div>
                  </td>

                  <td>
                    <div className="flex items-center cursor-pointer">
                      Requested On
                    </div>
                  </td>
                  <td>
                    <div className="flex items-center cursor-pointer">
                      Current Status
                    </div>
                  </td>

                  <td className="rounded-r"></td>
                </tr>
              </thead>
              <tbody>
                {providers?.map((user) => (
                  <tr
                    key={user.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-2.5">
                        <h5 className="flex-1">
                          {user?.firstName} {user?.lastName}
                        </h5>
                      </div>
                    </td>
                    <td className="subscription">
                      <div className="flex items-center gap-2">
                        {user.mobile}
                      </div>
                    </td>
                    <td>{user?.email}</td>

                    <td className="mrr">
                      {moment.utc(user.joinedOn).local().format("MMM DD,YYYY")}
                    </td>
                    <td>
                      <div
                        className={`w-fit px-2.5 py-1.5 ${
                          statusColorCode[user?.pendingStatus || "NotTouched"]
                            .bg
                        } rounded-lg justify-center items-center gap-1 flex`}
                      >
                        <div
                          className={`${
                            statusColorCode[user?.pendingStatus || "NotTouched"]
                              .fg
                          } text-sm`}
                        >
                          {
                            statusColorCode[user?.pendingStatus || "NotTouched"]
                              .text
                          }
                        </div>
                        <InfoWithStatusNoteTooltip
                          text={
                            user?.status === "NotTouched"
                              ? "No progress has been made regarding this request."
                              : user?.pendingStatusNotes ||
                                "No progress has been made regarding this request."
                          }
                          color={
                            statusColorCode[user?.pendingStatus || "NotTouched"]
                              .icon
                          }
                        />
                      </div>
                    </td>

                    <td onClick={stopPropagate} className="pr-5">
                      <UIPopover
                        trigger={
                          <span className="block p-2 border border-gray-270 rounded-lg">
                            <More />
                          </span>
                        }
                        positions={["bottom", "top", "left", "right"]}
                      >
                        {(close) => (
                          <ProviderRequestOptionsDropdown
                            onMarkAsAdded={() => {
                              close();
                              handleMarkAsAdded(user);
                            }}
                            onView={() => {
                              close();
                              setUserView(user);
                            }}
                            onRemove={() => {
                              close();
                              setRemoveUserModal(user);
                            }}
                          />
                        )}
                      </UIPopover>
                    </td>
                  </tr>
                ))}
                {isLoading && <LoadingSkeleton />}
                <div ref={observerTarget}></div>
                <div className="h-5"></div>
              </tbody>
            </table>
            {isLoading === false && !providers?.length && (
              <EmptyStates text="No providers found!" />
            )}
          </div>
        </div>
      </LoadAnimatedContainer>
      {userView && (
        <NewRequestModal
          onClose={() => setUserView(null)}
          provider={userView}
          onMarkAsAdded={() => handleMarkAsAdded(userView)}
          onUpdate={(updatedData) => {
            refetch();
            onRefresh();
            setUserView({ ...updatedData });
          }}
          onRefresh={() => {
            onRefresh();
            refetch(userView.id, true);
            setUserView(null);
          }}
        />
      )}

      {removeUserModal && (
        <ProviderDeleteConfirm
          user={removeUserModal}
          onCancel={() => setRemoveUserModal(null)}
          onSuccess={() => {
            refetch(removeUserModal.id, true);
            onRefresh();
            setRemoveUserModal(null);
          }}
        />
      )}
    </Modal>
  );
}
