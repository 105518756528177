import { PopupModal } from "../../../components/PopupModal";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as PinIcon } from "../../../assets/icons/pin.svg";
import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { Button } from "../../../components/Button";
import { useFiles } from "../context/file-context";
import { TextInput } from "../../../components/TextInput/TextInput";
import { api } from "../../../services/api";
import { DOCUMENT_OWNER_TYPES } from "../../../constant";
import { getUser } from "../../../services/utils";

export function UploadFilesDialog({ onClose }) {
  const user = getUser();
  const { uploadFiles, uploadingFiles, currentFolder } = useFiles() as any;
  const [youtubeLinkMode, setYoutubeLinkMode] = React.useState(false);
  const [youtubeLink, setYoutubeLink] = React.useState<string>("");
  const [videoName, setVideoName] = React.useState<string>("");
  const [errors, setErrors] = React.useState<any>({});
  const [files, setFiles] = React.useState<File[]>([]);
  const [isAccessibleByProviders, setIsAccessibleByProviders] =
    React.useState<boolean>(false);

  const toggleYoutubeLinkMode = () => setYoutubeLinkMode(!youtubeLinkMode)

  const handleFileChange = (fileList) => {
    setFiles(fileList);
  };

  const handleSubmit = async () => {
    if (!youtubeLinkMode && !files.length) {
      setErrors({ ...errors, files: "Please add files" });
      return;
    }

    if (youtubeLinkMode) {
      let stopSubmit = false
      const linkRegex = /(?:https?:\/\/)?(?:www\.)?youtu\.?be(?:\.com)?\/?.*(?:watch|embed)?(?:.*v=|v\/|\/)([\w-_]+)/gmi;
      if (!linkRegex.test(youtubeLink)) {
        setErrors({ ...errors, youtubeLink: "Invalid YouTube Link" })
        stopSubmit = true
      }
      if (videoName.trim().length === 0) {
        setErrors({ ...errors, videoName: "Video Name Required" })
        stopSubmit = true
      }
      if (stopSubmit) return
    }

    if (youtubeLink) {
      await api.createDocument({
        userId: user.id,
        name: videoName,
        ownerType: DOCUMENT_OWNER_TYPES.ADMIN,
        type: "youtubelink",
        folderId: currentFolder ? currentFolder.id : null,
        url: youtubeLink,
        isAccessibleByProviders
      })
    } else {
      await uploadFiles(files, isAccessibleByProviders);
    }
    onClose();
  };

  const disabled = youtubeLinkMode ? (youtubeLink.length === 0 || videoName.length === 0) : !files.length;
  return (
    <PopupModal
      contentClassName="p-8 z-[2] w-[98%] self-center bg-white rounded-2xl flex-col justify-start items-center gap-6 flex w-full md:mx-auto md:w-[442px] max-w-[442px]"
      onClose={onClose}
      shouldStopPropagation={false}
    >
      <>
        <div className="w-full justify-between items-start flex">
          <div className="text-black text-2xl font-bold font-['Outfit']">
            {youtubeLinkMode ? "Add YouTube Video" : "Upload file"}
          </div>
          <div className="justify-end items-center gap-3 flex">
            <button onClick={onClose}>
              <CloseIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
        <div className="w-full">
          <div className="flex flex-col gap-1.5">
            {youtubeLinkMode ? <>
              <span className="text-black/80 text-sm">Video Name</span>
              <TextInput
                placeholder="Video Name"
                className="!p-[14px]"
                name="folderName"
                value={videoName}
                onChange={(e) => setVideoName(e.target.value)}
              />
              {errors.youtubeLink && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.videoName}
                </div>
              )}
              <span className="text-black/80 text-sm mt-5">YouTube Link URL:</span>
              <TextInput
                placeholder="Enter YouTube Link URL"
                className="!p-[14px]"
                name="youtubelink"
                value={youtubeLink}
                onChange={(e) => setYoutubeLink(e.target.value)}
              />
              {errors.youtubeLink && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.youtubeLink}
                </div>
              )}
              <div>
                <span className="underline cursor-pointer inline-block mt-5" onClick={toggleYoutubeLinkMode}>Upload file</span>
              </div>
            </> : <>
              <span className="text-black/80 text-sm">Add Documents/Images</span>
              <FileUploader handleChange={handleFileChange} name="file" multiple>
                <div className="cursor-pointer p-4 h-[114px] bg-[#E8F3F2] w-full rounded-xl border border-primary justify-center items-center gap-1 flex">
                  <PinIcon className="w-5 h-5" />
                  <div className="text-primary text-base font-normal font-['Outfit'] leading-normal">
                    {files.length > 0
                      ? `${files.length} files added`
                      : "Click to add or drag and drop"}
                  </div>
                </div>
              </FileUploader>
              {errors.files && (
                <div className="text-red-500 text-xs font-medium">
                  {errors.files}
                </div>
              )}
              <div>
                <span className="underline cursor-pointer inline-block" onClick={toggleYoutubeLinkMode}>Add Youtube Video</span>
              </div>
            </>}
          </div>
          <div className="flex items-center gap-1.5 mt-[22px] mb-8">
            <input
              type="checkbox"
              id="providerAccessCheckbox"
              name="providerAccessCheckbox"
              checked={isAccessibleByProviders}
              onChange={() =>
                setIsAccessibleByProviders(!isAccessibleByProviders)
              }
              className="w-[22px] h-[22px] accent-primary"
            />
            <label
              htmlFor="providerAccessCheckbox"
              className="text-black/80 text-base"
            >
              All providers can access
            </label>
          </div>
          <Button
            disabled={disabled || uploadingFiles}
            variant="primary"
            className="!rounded-full w-full"
            onClick={handleSubmit}
            loading={uploadingFiles}
          >
            Add
          </Button>
        </div>
      </>
    </PopupModal>
  );
}
