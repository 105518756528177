/* tslint:disable */

export const zipCodes = {
  '10001': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7484,
    longitude: -73.9967,
    postal_code: '10001'
  },
  '10002': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7152,
    longitude: -73.9877,
    postal_code: '10002'
  },
  '10003': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7313,
    longitude: -73.9892,
    postal_code: '10003'
  },
  '10004': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10004'
  },
  '10005': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7056,
    longitude: -74.0083,
    postal_code: '10005'
  },
  '10006': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7085,
    longitude: -74.0135,
    postal_code: '10006'
  },
  '10007': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7139,
    longitude: -74.007,
    postal_code: '10007'
  },
  '10008': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10008'
  },
  '10009': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7262,
    longitude: -73.9796,
    postal_code: '10009'
  },
  '10010': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7375,
    longitude: -73.9813,
    postal_code: '10010'
  },
  '10011': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7402,
    longitude: -73.9996,
    postal_code: '10011'
  },
  '10012': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7255,
    longitude: -73.9983,
    postal_code: '10012'
  },
  '10013': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7185,
    longitude: -74.0025,
    postal_code: '10013'
  },
  '10014': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7339,
    longitude: -74.0054,
    postal_code: '10014'
  },
  '10016': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7443,
    longitude: -73.9781,
    postal_code: '10016'
  },
  '10017': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7517,
    longitude: -73.9707,
    postal_code: '10017'
  },
  '10018': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7547,
    longitude: -73.9925,
    postal_code: '10018'
  },
  '10019': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7651,
    longitude: -73.9858,
    postal_code: '10019'
  },
  '10020': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7354,
    longitude: -73.9968,
    postal_code: '10020'
  },
  '10021': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7685,
    longitude: -73.9588,
    postal_code: '10021'
  },
  '10022': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7571,
    longitude: -73.9657,
    postal_code: '10022'
  },
  '10023': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7764,
    longitude: -73.9827,
    postal_code: '10023'
  },
  '10024': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7864,
    longitude: -73.9764,
    postal_code: '10024'
  },
  '10025': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7975,
    longitude: -73.9683,
    postal_code: '10025'
  },
  '10026': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8019,
    longitude: -73.9531,
    postal_code: '10026'
  },
  '10027': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8116,
    longitude: -73.955,
    postal_code: '10027'
  },
  '10028': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7763,
    longitude: -73.9529,
    postal_code: '10028'
  },
  '10029': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7918,
    longitude: -73.9447,
    postal_code: '10029'
  },
  '10030': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8183,
    longitude: -73.9426,
    postal_code: '10030'
  },
  '10031': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8246,
    longitude: -73.9507,
    postal_code: '10031'
  },
  '10032': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8382,
    longitude: -73.942,
    postal_code: '10032'
  },
  '10033': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8496,
    longitude: -73.9356,
    postal_code: '10033'
  },
  '10034': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8662,
    longitude: -73.9221,
    postal_code: '10034'
  },
  '10035': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8011,
    longitude: -73.9371,
    postal_code: '10035'
  },
  '10036': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7597,
    longitude: -73.9918,
    postal_code: '10036'
  },
  '10037': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8135,
    longitude: -73.9381,
    postal_code: '10037'
  },
  '10038': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7101,
    longitude: -74.0013,
    postal_code: '10038'
  },
  '10039': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8265,
    longitude: -73.9383,
    postal_code: '10039'
  },
  '10040': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8583,
    longitude: -73.9296,
    postal_code: '10040'
  },
  '10041': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7038,
    longitude: -74.0098,
    postal_code: '10041'
  },
  '10043': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10043'
  },
  '10044': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7618,
    longitude: -73.9505,
    postal_code: '10044'
  },
  '10045': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7086,
    longitude: -74.0087,
    postal_code: '10045'
  },
  '10055': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10055'
  },
  '10060': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10060'
  },
  '10065': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7651,
    longitude: -73.9638,
    postal_code: '10065'
  },
  '10069': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.778,
    longitude: -73.9884,
    postal_code: '10069'
  },
  '10075': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10075'
  },
  '10080': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10080'
  },
  '10081': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10081'
  },
  '10087': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10087'
  },
  '10090': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10090'
  },
  '10101': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10101'
  },
  '10102': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10102'
  },
  '10103': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7603,
    longitude: -73.9762,
    postal_code: '10103'
  },
  '10104': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7609,
    longitude: -73.9799,
    postal_code: '10104'
  },
  '10105': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7628,
    longitude: -73.9785,
    postal_code: '10105'
  },
  '10106': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7652,
    longitude: -73.9804,
    postal_code: '10106'
  },
  '10107': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7664,
    longitude: -73.9827,
    postal_code: '10107'
  },
  '10108': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10108'
  },
  '10109': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10109'
  },
  '10110': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.754,
    longitude: -73.9808,
    postal_code: '10110'
  },
  '10111': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7592,
    longitude: -73.9778,
    postal_code: '10111'
  },
  '10112': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7593,
    longitude: -73.9798,
    postal_code: '10112'
  },
  '10113': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10113'
  },
  '10114': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10114'
  },
  '10115': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.8111,
    longitude: -73.9642,
    postal_code: '10115'
  },
  '10116': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10116'
  },
  '10117': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10117'
  },
  '10118': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.749,
    longitude: -73.9865,
    postal_code: '10118'
  },
  '10119': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10119'
  },
  '10120': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7506,
    longitude: -73.9894,
    postal_code: '10120'
  },
  '10121': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7496,
    longitude: -73.9919,
    postal_code: '10121'
  },
  '10122': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7518,
    longitude: -73.9922,
    postal_code: '10122'
  },
  '10123': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7515,
    longitude: -73.9905,
    postal_code: '10123'
  },
  '10124': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10124'
  },
  '10125': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10125'
  },
  '10126': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10126'
  },
  '10128': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7816,
    longitude: -73.9511,
    postal_code: '10128'
  },
  '10129': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10129'
  },
  '10130': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10130'
  },
  '10131': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10131'
  },
  '10132': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10132'
  },
  '10133': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10133'
  },
  '10138': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10138'
  },
  '10150': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10150'
  },
  '10151': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7634,
    longitude: -73.974,
    postal_code: '10151'
  },
  '10152': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7589,
    longitude: -73.973,
    postal_code: '10152'
  },
  '10153': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7641,
    longitude: -73.9735,
    postal_code: '10153'
  },
  '10154': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7583,
    longitude: -73.9735,
    postal_code: '10154'
  },
  '10155': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7611,
    longitude: -73.968,
    postal_code: '10155'
  },
  '10156': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10156'
  },
  '10157': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10157'
  },
  '10158': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7494,
    longitude: -73.9758,
    postal_code: '10158'
  },
  '10159': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10159'
  },
  '10160': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10160'
  },
  '10161': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10161'
  },
  '10162': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7699,
    longitude: -73.9511,
    postal_code: '10162'
  },
  '10163': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10163'
  },
  '10164': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10164'
  },
  '10165': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7524,
    longitude: -73.9791,
    postal_code: '10165'
  },
  '10166': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7546,
    longitude: -73.9762,
    postal_code: '10166'
  },
  '10167': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7549,
    longitude: -73.975,
    postal_code: '10167'
  },
  '10168': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7519,
    longitude: -73.9768,
    postal_code: '10168'
  },
  '10169': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7547,
    longitude: -73.9766,
    postal_code: '10169'
  },
  '10170': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7526,
    longitude: -73.9755,
    postal_code: '10170'
  },
  '10171': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7564,
    longitude: -73.9748,
    postal_code: '10171'
  },
  '10172': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7558,
    longitude: -73.9753,
    postal_code: '10172'
  },
  '10173': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7543,
    longitude: -73.9796,
    postal_code: '10173'
  },
  '10174': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7517,
    longitude: -73.9752,
    postal_code: '10174'
  },
  '10175': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7543,
    longitude: -73.9798,
    postal_code: '10175'
  },
  '10176': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7556,
    longitude: -73.9789,
    postal_code: '10176'
  },
  '10177': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7553,
    longitude: -73.9761,
    postal_code: '10177'
  },
  '10178': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7514,
    longitude: -73.9785,
    postal_code: '10178'
  },
  '10179': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10179'
  },
  '10185': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10185'
  },
  '10199': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7503,
    longitude: -74.0006,
    postal_code: '10199'
  },
  '10203': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10203'
  },
  '10211': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10211'
  },
  '10212': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10212'
  },
  '10213': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10213'
  },
  '10242': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10242'
  },
  '10249': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10249'
  },
  '10256': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10256'
  },
  '10258': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10258'
  },
  '10259': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10259'
  },
  '10260': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10260'
  },
  '10261': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10261'
  },
  '10265': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10265'
  },
  '10268': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10268'
  },
  '10269': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10269'
  },
  '10270': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7069,
    longitude: -74.0082,
    postal_code: '10270'
  },
  '10271': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7089,
    longitude: -74.0111,
    postal_code: '10271'
  },
  '10272': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10272'
  },
  '10273': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10273'
  },
  '10274': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10274'
  },
  '10275': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10275'
  },
  '10276': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10276'
  },
  '10277': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7808,
    longitude: -73.9772,
    postal_code: '10277'
  },
  '10278': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7152,
    longitude: -74.0038,
    postal_code: '10278'
  },
  '10279': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7127,
    longitude: -74.0078,
    postal_code: '10279'
  },
  '10280': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7105,
    longitude: -74.0163,
    postal_code: '10280'
  },
  '10281': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7146,
    longitude: -74.015,
    postal_code: '10281'
  },
  '10282': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7166,
    longitude: -74.0146,
    postal_code: '10282'
  },
  '10285': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7143,
    longitude: -74.006,
    postal_code: '10285'
  },
  '10286': {
    country: 'US',
    city: 'New York',
    state: 'New York',
    state_short: 'NY',
    county: 'New York',
    latitude: 40.7142,
    longitude: -74.0119,
    postal_code: '10286'
  },
  '10301': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.6316,
    longitude: -74.0927,
    postal_code: '10301'
  },
  '10302': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.6306,
    longitude: -74.1379,
    postal_code: '10302'
  },
  '10303': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.6301,
    longitude: -74.1607,
    postal_code: '10303'
  },
  '10304': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.6102,
    longitude: -74.0878,
    postal_code: '10304'
  },
  '10305': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.5973,
    longitude: -74.0768,
    postal_code: '10305'
  },
  '10306': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.5682,
    longitude: -74.1184,
    postal_code: '10306'
  },
  '10307': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.5085,
    longitude: -74.2445,
    postal_code: '10307'
  },
  '10308': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.5518,
    longitude: -74.1526,
    postal_code: '10308'
  },
  '10309': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.5352,
    longitude: -74.2116,
    postal_code: '10309'
  },
  '10310': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.6324,
    longitude: -74.1171,
    postal_code: '10310'
  },
  '10311': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.6052,
    longitude: -74.1795,
    postal_code: '10311'
  },
  '10312': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.5457,
    longitude: -74.1792,
    postal_code: '10312'
  },
  '10313': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.5644,
    longitude: -74.1468,
    postal_code: '10313'
  },
  '10314': {
    country: 'US',
    city: 'Staten Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Richmond',
    latitude: 40.6039,
    longitude: -74.1472,
    postal_code: '10314'
  },
  '10451': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8222,
    longitude: -73.9217,
    postal_code: '10451'
  },
  '10452': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8376,
    longitude: -73.9216,
    postal_code: '10452'
  },
  '10453': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.852,
    longitude: -73.9129,
    postal_code: '10453'
  },
  '10454': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8085,
    longitude: -73.9198,
    postal_code: '10454'
  },
  '10455': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8153,
    longitude: -73.9072,
    postal_code: '10455'
  },
  '10456': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8316,
    longitude: -73.9099,
    postal_code: '10456'
  },
  '10457': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8486,
    longitude: -73.8999,
    postal_code: '10457'
  },
  '10458': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8633,
    longitude: -73.8895,
    postal_code: '10458'
  },
  '10459': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8247,
    longitude: -73.894,
    postal_code: '10459'
  },
  '10460': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8409,
    longitude: -73.8794,
    postal_code: '10460'
  },
  '10461': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8465,
    longitude: -73.841,
    postal_code: '10461'
  },
  '10462': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8434,
    longitude: -73.8602,
    postal_code: '10462'
  },
  '10463': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8798,
    longitude: -73.9067,
    postal_code: '10463'
  },
  '10464': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8469,
    longitude: -73.7874,
    postal_code: '10464'
  },
  '10465': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8261,
    longitude: -73.8196,
    postal_code: '10465'
  },
  '10466': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8904,
    longitude: -73.8503,
    postal_code: '10466'
  },
  '10467': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8737,
    longitude: -73.8712,
    postal_code: '10467'
  },
  '10468': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8662,
    longitude: -73.9003,
    postal_code: '10468'
  },
  '10469': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8702,
    longitude: -73.8495,
    postal_code: '10469'
  },
  '10470': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.9,
    longitude: -73.8622,
    postal_code: '10470'
  },
  '10471': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.9011,
    longitude: -73.9053,
    postal_code: '10471'
  },
  '10472': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8295,
    longitude: -73.8716,
    postal_code: '10472'
  },
  '10473': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8194,
    longitude: -73.8606,
    postal_code: '10473'
  },
  '10474': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8139,
    longitude: -73.8841,
    postal_code: '10474'
  },
  '10475': {
    country: 'US',
    city: 'Bronx',
    state: 'New York',
    state_short: 'NY',
    county: 'Bronx',
    latitude: 40.8729,
    longitude: -73.8278,
    postal_code: '10475'
  },
  '10501': {
    country: 'US',
    city: 'Amawalk',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2946,
    longitude: -73.7611,
    postal_code: '10501'
  },
  '10502': {
    country: 'US',
    city: 'Ardsley',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0113,
    longitude: -73.8413,
    postal_code: '10502'
  },
  '10503': {
    country: 'US',
    city: 'Ardsley On Hudson',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0259,
    longitude: -73.8718,
    postal_code: '10503'
  },
  '10504': {
    country: 'US',
    city: 'Armonk',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.136,
    longitude: -73.7009,
    postal_code: '10504'
  },
  '10505': {
    country: 'US',
    city: 'Baldwin Place',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3421,
    longitude: -73.7454,
    postal_code: '10505'
  },
  '10506': {
    country: 'US',
    city: 'Bedford',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.1909,
    longitude: -73.6355,
    postal_code: '10506'
  },
  '10507': {
    country: 'US',
    city: 'Bedford Hills',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2344,
    longitude: -73.6915,
    postal_code: '10507'
  },
  '10509': {
    country: 'US',
    city: 'Brewster',
    state: 'New York',
    state_short: 'NY',
    county: 'Putnam',
    latitude: 41.4097,
    longitude: -73.5992,
    postal_code: '10509'
  },
  '10510': {
    country: 'US',
    city: 'Briarcliff Manor',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.1444,
    longitude: -73.835,
    postal_code: '10510'
  },
  '10511': {
    country: 'US',
    city: 'Buchanan',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2583,
    longitude: -73.9412,
    postal_code: '10511'
  },
  '10512': {
    country: 'US',
    city: 'Carmel',
    state: 'New York',
    state_short: 'NY',
    county: 'Putnam',
    latitude: 41.4432,
    longitude: -73.6815,
    postal_code: '10512'
  },
  '10514': {
    country: 'US',
    city: 'Chappaqua',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.1705,
    longitude: -73.7715,
    postal_code: '10514'
  },
  '10516': {
    country: 'US',
    city: 'Cold Spring',
    state: 'New York',
    state_short: 'NY',
    county: 'Putnam',
    latitude: 41.4414,
    longitude: -73.9335,
    postal_code: '10516'
  },
  '10517': {
    country: 'US',
    city: 'Crompond',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3006,
    longitude: -73.8612,
    postal_code: '10517'
  },
  '10518': {
    country: 'US',
    city: 'Cross River',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2722,
    longitude: -73.602,
    postal_code: '10518'
  },
  '10519': {
    country: 'US',
    city: 'Croton Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3477,
    longitude: -73.661,
    postal_code: '10519'
  },
  '10520': {
    country: 'US',
    city: 'Croton On Hudson',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.218,
    longitude: -73.8924,
    postal_code: '10520'
  },
  '10521': {
    country: 'US',
    city: 'Croton On Hudson',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2343,
    longitude: -73.9262,
    postal_code: '10521'
  },
  '10522': {
    country: 'US',
    city: 'Dobbs Ferry',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0118,
    longitude: -73.8665,
    postal_code: '10522'
  },
  '10523': {
    country: 'US',
    city: 'Elmsford',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0572,
    longitude: -73.8136,
    postal_code: '10523'
  },
  '10524': {
    country: 'US',
    city: 'Garrison',
    state: 'New York',
    state_short: 'NY',
    county: 'Putnam',
    latitude: 41.3621,
    longitude: -73.92,
    postal_code: '10524'
  },
  '10526': {
    country: 'US',
    city: 'Goldens Bridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3004,
    longitude: -73.6479,
    postal_code: '10526'
  },
  '10527': {
    country: 'US',
    city: 'Granite Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3098,
    longitude: -73.753,
    postal_code: '10527'
  },
  '10528': {
    country: 'US',
    city: 'Harrison',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9719,
    longitude: -73.7181,
    postal_code: '10528'
  },
  '10530': {
    country: 'US',
    city: 'Hartsdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0197,
    longitude: -73.8074,
    postal_code: '10530'
  },
  '10532': {
    country: 'US',
    city: 'Hawthorne',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.1073,
    longitude: -73.796,
    postal_code: '10532'
  },
  '10533': {
    country: 'US',
    city: 'Irvington',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0381,
    longitude: -73.8597,
    postal_code: '10533'
  },
  '10535': {
    country: 'US',
    city: 'Jefferson Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3385,
    longitude: -73.7947,
    postal_code: '10535'
  },
  '10536': {
    country: 'US',
    city: 'Katonah',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2709,
    longitude: -73.6841,
    postal_code: '10536'
  },
  '10537': {
    country: 'US',
    city: 'Lake Peekskill',
    state: 'New York',
    state_short: 'NY',
    county: 'Putnam',
    latitude: 41.3374,
    longitude: -73.8838,
    postal_code: '10537'
  },
  '10538': {
    country: 'US',
    city: 'Larchmont',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9351,
    longitude: -73.7571,
    postal_code: '10538'
  },
  '10540': {
    country: 'US',
    city: 'Lincolndale',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3334,
    longitude: -73.7243,
    postal_code: '10540'
  },
  '10541': {
    country: 'US',
    city: 'Mahopac',
    state: 'New York',
    state_short: 'NY',
    county: 'Putnam',
    latitude: 41.3717,
    longitude: -73.7508,
    postal_code: '10541'
  },
  '10542': {
    country: 'US',
    city: 'Mahopac Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Putnam',
    latitude: 41.3726,
    longitude: -73.7601,
    postal_code: '10542'
  },
  '10543': {
    country: 'US',
    city: 'Mamaroneck',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9525,
    longitude: -73.735,
    postal_code: '10543'
  },
  '10545': {
    country: 'US',
    city: 'Maryknoll',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.119,
    longitude: -73.733,
    postal_code: '10545'
  },
  '10546': {
    country: 'US',
    city: 'Millwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2015,
    longitude: -73.7926,
    postal_code: '10546'
  },
  '10547': {
    country: 'US',
    city: 'Mohegan Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3143,
    longitude: -73.8508,
    postal_code: '10547'
  },
  '10548': {
    country: 'US',
    city: 'Montrose',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2496,
    longitude: -73.9446,
    postal_code: '10548'
  },
  '10549': {
    country: 'US',
    city: 'Mount Kisco',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.205,
    longitude: -73.7299,
    postal_code: '10549'
  },
  '10550': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9079,
    longitude: -73.838,
    postal_code: '10550'
  },
  '10551': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9126,
    longitude: -73.8371,
    postal_code: '10551'
  },
  '10552': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9231,
    longitude: -73.8299,
    postal_code: '10552'
  },
  '10553': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9086,
    longitude: -73.8221,
    postal_code: '10553'
  },
  '10560': {
    country: 'US',
    city: 'North Salem',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3414,
    longitude: -73.5929,
    postal_code: '10560'
  },
  '10562': {
    country: 'US',
    city: 'Ossining',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.1673,
    longitude: -73.8538,
    postal_code: '10562'
  },
  '10566': {
    country: 'US',
    city: 'Peekskill',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2892,
    longitude: -73.9184,
    postal_code: '10566'
  },
  '10567': {
    country: 'US',
    city: 'Cortlandt Manor',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.284,
    longitude: -73.8931,
    postal_code: '10567'
  },
  '10570': {
    country: 'US',
    city: 'Pleasantville',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.135,
    longitude: -73.7845,
    postal_code: '10570'
  },
  '10573': {
    country: 'US',
    city: 'Port Chester',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0222,
    longitude: -73.6798,
    postal_code: '10573'
  },
  '10576': {
    country: 'US',
    city: 'Pound Ridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2042,
    longitude: -73.5732,
    postal_code: '10576'
  },
  '10577': {
    country: 'US',
    city: 'Purchase',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0384,
    longitude: -73.7156,
    postal_code: '10577'
  },
  '10578': {
    country: 'US',
    city: 'Purdys',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3259,
    longitude: -73.6551,
    postal_code: '10578'
  },
  '10579': {
    country: 'US',
    city: 'Putnam Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Putnam',
    latitude: 41.3728,
    longitude: -73.8502,
    postal_code: '10579'
  },
  '10580': {
    country: 'US',
    city: 'Rye',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9734,
    longitude: -73.6907,
    postal_code: '10580'
  },
  '10583': {
    country: 'US',
    city: 'Scarsdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9927,
    longitude: -73.7995,
    postal_code: '10583'
  },
  '10587': {
    country: 'US',
    city: 'Shenorock',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3287,
    longitude: -73.7423,
    postal_code: '10587'
  },
  '10588': {
    country: 'US',
    city: 'Shrub Oak',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3286,
    longitude: -73.8273,
    postal_code: '10588'
  },
  '10589': {
    country: 'US',
    city: 'Somers',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3346,
    longitude: -73.6951,
    postal_code: '10589'
  },
  '10590': {
    country: 'US',
    city: 'South Salem',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2553,
    longitude: -73.5402,
    postal_code: '10590'
  },
  '10591': {
    country: 'US',
    city: 'Tarrytown',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0897,
    longitude: -73.844,
    postal_code: '10591'
  },
  '10594': {
    country: 'US',
    city: 'Thornwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.1182,
    longitude: -73.7733,
    postal_code: '10594'
  },
  '10595': {
    country: 'US',
    city: 'Valhalla',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0856,
    longitude: -73.7776,
    postal_code: '10595'
  },
  '10596': {
    country: 'US',
    city: 'Verplanck',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2548,
    longitude: -73.9587,
    postal_code: '10596'
  },
  '10597': {
    country: 'US',
    city: 'Waccabuc',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.3032,
    longitude: -73.6032,
    postal_code: '10597'
  },
  '10598': {
    country: 'US',
    city: 'Yorktown Heights',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.2999,
    longitude: -73.7924,
    postal_code: '10598'
  },
  '10601': {
    country: 'US',
    city: 'White Plains',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.033,
    longitude: -73.7652,
    postal_code: '10601'
  },
  '10602': {
    country: 'US',
    city: 'White Plains',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.034,
    longitude: -73.7629,
    postal_code: '10602'
  },
  '10603': {
    country: 'US',
    city: 'White Plains',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0499,
    longitude: -73.7776,
    postal_code: '10603'
  },
  '10604': {
    country: 'US',
    city: 'West Harrison',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0592,
    longitude: -73.7395,
    postal_code: '10604'
  },
  '10605': {
    country: 'US',
    city: 'White Plains',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0141,
    longitude: -73.7552,
    postal_code: '10605'
  },
  '10606': {
    country: 'US',
    city: 'White Plains',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0247,
    longitude: -73.7781,
    postal_code: '10606'
  },
  '10607': {
    country: 'US',
    city: 'White Plains',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.0398,
    longitude: -73.8117,
    postal_code: '10607'
  },
  '10610': {
    country: 'US',
    city: 'White Plains',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 41.119,
    longitude: -73.733,
    postal_code: '10610'
  },
  '10701': {
    country: 'US',
    city: 'Yonkers',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9461,
    longitude: -73.8669,
    postal_code: '10701'
  },
  '10702': {
    country: 'US',
    city: 'Yonkers',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9312,
    longitude: -73.8987,
    postal_code: '10702'
  },
  '10703': {
    country: 'US',
    city: 'Yonkers',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9518,
    longitude: -73.8852,
    postal_code: '10703'
  },
  '10704': {
    country: 'US',
    city: 'Yonkers',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9176,
    longitude: -73.8593,
    postal_code: '10704'
  },
  '10705': {
    country: 'US',
    city: 'Yonkers',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9177,
    longitude: -73.895,
    postal_code: '10705'
  },
  '10706': {
    country: 'US',
    city: 'Hastings On Hudson',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9878,
    longitude: -73.863,
    postal_code: '10706'
  },
  '10707': {
    country: 'US',
    city: 'Tuckahoe',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9569,
    longitude: -73.8198,
    postal_code: '10707'
  },
  '10708': {
    country: 'US',
    city: 'Bronxville',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9391,
    longitude: -73.8353,
    postal_code: '10708'
  },
  '10709': {
    country: 'US',
    city: 'Eastchester',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.955,
    longitude: -73.8086,
    postal_code: '10709'
  },
  '10710': {
    country: 'US',
    city: 'Yonkers',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9656,
    longitude: -73.8434,
    postal_code: '10710'
  },
  '10801': {
    country: 'US',
    city: 'New Rochelle',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9166,
    longitude: -73.7877,
    postal_code: '10801'
  },
  '10802': {
    country: 'US',
    city: 'New Rochelle',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9115,
    longitude: -73.7824,
    postal_code: '10802'
  },
  '10803': {
    country: 'US',
    city: 'Pelham',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9045,
    longitude: -73.8073,
    postal_code: '10803'
  },
  '10804': {
    country: 'US',
    city: 'New Rochelle',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9491,
    longitude: -73.7863,
    postal_code: '10804'
  },
  '10805': {
    country: 'US',
    city: 'New Rochelle',
    state: 'New York',
    state_short: 'NY',
    county: 'Westchester',
    latitude: 40.9002,
    longitude: -73.781,
    postal_code: '10805'
  },
  '10901': {
    country: 'US',
    city: 'Suffern',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1177,
    longitude: -74.1241,
    postal_code: '10901'
  },
  '10910': {
    country: 'US',
    city: 'Arden',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.2745,
    longitude: -74.1529,
    postal_code: '10910'
  },
  '10911': {
    country: 'US',
    city: 'Bear Mountain',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1609,
    longitude: -74.0608,
    postal_code: '10911'
  },
  '10912': {
    country: 'US',
    city: 'Bellvale',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.2504,
    longitude: -74.3107,
    postal_code: '10912'
  },
  '10913': {
    country: 'US',
    city: 'Blauvelt',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.0626,
    longitude: -73.9629,
    postal_code: '10913'
  },
  '10914': {
    country: 'US',
    city: 'Blooming Grove',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4093,
    longitude: -74.1951,
    postal_code: '10914'
  },
  '10915': {
    country: 'US',
    city: 'Bullville',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.5443,
    longitude: -74.3615,
    postal_code: '10915'
  },
  '10916': {
    country: 'US',
    city: 'Campbell Hall',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.443,
    longitude: -74.2505,
    postal_code: '10916'
  },
  '10917': {
    country: 'US',
    city: 'Central Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3268,
    longitude: -74.122,
    postal_code: '10917'
  },
  '10918': {
    country: 'US',
    city: 'Chester',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3554,
    longitude: -74.2651,
    postal_code: '10918'
  },
  '10919': {
    country: 'US',
    city: 'Circleville',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.5244,
    longitude: -74.385,
    postal_code: '10919'
  },
  '10920': {
    country: 'US',
    city: 'Congers',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1487,
    longitude: -73.9413,
    postal_code: '10920'
  },
  '10921': {
    country: 'US',
    city: 'Florida',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3295,
    longitude: -74.3528,
    postal_code: '10921'
  },
  '10922': {
    country: 'US',
    city: 'Fort Montgomery',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3346,
    longitude: -73.9917,
    postal_code: '10922'
  },
  '10923': {
    country: 'US',
    city: 'Garnerville',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.2021,
    longitude: -74.0005,
    postal_code: '10923'
  },
  '10924': {
    country: 'US',
    city: 'Goshen',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3946,
    longitude: -74.3302,
    postal_code: '10924'
  },
  '10925': {
    country: 'US',
    city: 'Greenwood Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.2101,
    longitude: -74.3033,
    postal_code: '10925'
  },
  '10926': {
    country: 'US',
    city: 'Harriman',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3005,
    longitude: -74.1249,
    postal_code: '10926'
  },
  '10927': {
    country: 'US',
    city: 'Haverstraw',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1971,
    longitude: -73.969,
    postal_code: '10927'
  },
  '10928': {
    country: 'US',
    city: 'Highland Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3582,
    longitude: -73.9746,
    postal_code: '10928'
  },
  '10930': {
    country: 'US',
    city: 'Highland Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3536,
    longitude: -74.1197,
    postal_code: '10930'
  },
  '10931': {
    country: 'US',
    city: 'Hillburn',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.124,
    longitude: -74.1702,
    postal_code: '10931'
  },
  '10932': {
    country: 'US',
    city: 'Howells',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4795,
    longitude: -74.4652,
    postal_code: '10932'
  },
  '10933': {
    country: 'US',
    city: 'Johnson',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3653,
    longitude: -74.5109,
    postal_code: '10933'
  },
  '10940': {
    country: 'US',
    city: 'Middletown',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4512,
    longitude: -74.4701,
    postal_code: '10940'
  },
  '10941': {
    country: 'US',
    city: 'Middletown',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4886,
    longitude: -74.345,
    postal_code: '10941'
  },
  '10949': {
    country: 'US',
    city: 'Monroe',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3274,
    longitude: -74.1911,
    postal_code: '10949'
  },
  '10950': {
    country: 'US',
    city: 'Monroe',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3286,
    longitude: -74.1885,
    postal_code: '10950'
  },
  '10952': {
    country: 'US',
    city: 'Monsey',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1163,
    longitude: -74.0736,
    postal_code: '10952'
  },
  '10953': {
    country: 'US',
    city: 'Mountainville',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4009,
    longitude: -74.0785,
    postal_code: '10953'
  },
  '10954': {
    country: 'US',
    city: 'Nanuet',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.0977,
    longitude: -74.0109,
    postal_code: '10954'
  },
  '10956': {
    country: 'US',
    city: 'New City',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1472,
    longitude: -73.9962,
    postal_code: '10956'
  },
  '10958': {
    country: 'US',
    city: 'New Hampton',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3627,
    longitude: -74.4435,
    postal_code: '10958'
  },
  '10959': {
    country: 'US',
    city: 'New Milford',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.2345,
    longitude: -74.4138,
    postal_code: '10959'
  },
  '10960': {
    country: 'US',
    city: 'Nyack',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.0914,
    longitude: -73.9252,
    postal_code: '10960'
  },
  '10962': {
    country: 'US',
    city: 'Orangeburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.0442,
    longitude: -73.9609,
    postal_code: '10962'
  },
  '10963': {
    country: 'US',
    city: 'Otisville',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4818,
    longitude: -74.5294,
    postal_code: '10963'
  },
  '10964': {
    country: 'US',
    city: 'Palisades',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.0103,
    longitude: -73.925,
    postal_code: '10964'
  },
  '10965': {
    country: 'US',
    city: 'Pearl River',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.0629,
    longitude: -74.0159,
    postal_code: '10965'
  },
  '10968': {
    country: 'US',
    city: 'Piermont',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.0395,
    longitude: -73.9192,
    postal_code: '10968'
  },
  '10969': {
    country: 'US',
    city: 'Pine Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.2926,
    longitude: -74.4888,
    postal_code: '10969'
  },
  '10970': {
    country: 'US',
    city: 'Pomona',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1901,
    longitude: -74.0436,
    postal_code: '10970'
  },
  '10973': {
    country: 'US',
    city: 'Slate Hill',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.376,
    longitude: -74.4847,
    postal_code: '10973'
  },
  '10974': {
    country: 'US',
    city: 'Sloatsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1575,
    longitude: -74.2008,
    postal_code: '10974'
  },
  '10975': {
    country: 'US',
    city: 'Southfields',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.248,
    longitude: -74.1762,
    postal_code: '10975'
  },
  '10976': {
    country: 'US',
    city: 'Sparkill',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.0256,
    longitude: -73.9229,
    postal_code: '10976'
  },
  '10977': {
    country: 'US',
    city: 'Spring Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1158,
    longitude: -74.0474,
    postal_code: '10977'
  },
  '10979': {
    country: 'US',
    city: 'Sterling Forest',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.1823,
    longitude: -74.3184,
    postal_code: '10979'
  },
  '10980': {
    country: 'US',
    city: 'Stony Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.2292,
    longitude: -73.9962,
    postal_code: '10980'
  },
  '10981': {
    country: 'US',
    city: 'Sugar Loaf',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3232,
    longitude: -74.2886,
    postal_code: '10981'
  },
  '10982': {
    country: 'US',
    city: 'Tallman',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1112,
    longitude: -74.0999,
    postal_code: '10982'
  },
  '10983': {
    country: 'US',
    city: 'Tappan',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.0278,
    longitude: -73.9491,
    postal_code: '10983'
  },
  '10984': {
    country: 'US',
    city: 'Thiells',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.2078,
    longitude: -74.0154,
    postal_code: '10984'
  },
  '10985': {
    country: 'US',
    city: 'Thompson Ridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.575,
    longitude: -74.3225,
    postal_code: '10985'
  },
  '10986': {
    country: 'US',
    city: 'Tomkins Cove',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.2598,
    longitude: -73.9892,
    postal_code: '10986'
  },
  '10987': {
    country: 'US',
    city: 'Tuxedo Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.1925,
    longitude: -74.2159,
    postal_code: '10987'
  },
  '10988': {
    country: 'US',
    city: 'Unionville',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.302,
    longitude: -74.5615,
    postal_code: '10988'
  },
  '10989': {
    country: 'US',
    city: 'Valley Cottage',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.1183,
    longitude: -73.943,
    postal_code: '10989'
  },
  '10990': {
    country: 'US',
    city: 'Warwick',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.2656,
    longitude: -74.3604,
    postal_code: '10990'
  },
  '10992': {
    country: 'US',
    city: 'Washingtonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4237,
    longitude: -74.1601,
    postal_code: '10992'
  },
  '10993': {
    country: 'US',
    city: 'West Haverstraw',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.209,
    longitude: -73.9821,
    postal_code: '10993'
  },
  '10994': {
    country: 'US',
    city: 'West Nyack',
    state: 'New York',
    state_short: 'NY',
    county: 'Rockland',
    latitude: 41.0973,
    longitude: -73.9768,
    postal_code: '10994'
  },
  '10996': {
    country: 'US',
    city: 'West Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3945,
    longitude: -73.9737,
    postal_code: '10996'
  },
  '10997': {
    country: 'US',
    city: 'West Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3915,
    longitude: -73.956,
    postal_code: '10997'
  },
  '10998': {
    country: 'US',
    city: 'Westtown',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3214,
    longitude: -74.5529,
    postal_code: '10998'
  },
  '11001': {
    country: 'US',
    city: 'Floral Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7236,
    longitude: -73.7058,
    postal_code: '11001'
  },
  '11002': {
    country: 'US',
    city: 'Floral Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7237,
    longitude: -73.7049,
    postal_code: '11002'
  },
  '11003': {
    country: 'US',
    city: 'Elmont',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6976,
    longitude: -73.7049,
    postal_code: '11003'
  },
  '11004': {
    country: 'US',
    city: 'Glen Oaks',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7481,
    longitude: -73.7114,
    postal_code: '11004'
  },
  '11005': {
    country: 'US',
    city: 'Floral Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7571,
    longitude: -73.7182,
    postal_code: '11005'
  },
  '11010': {
    country: 'US',
    city: 'Franklin Square',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.701,
    longitude: -73.6758,
    postal_code: '11010'
  },
  '11020': {
    country: 'US',
    city: 'Great Neck',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7742,
    longitude: -73.7189,
    postal_code: '11020'
  },
  '11021': {
    country: 'US',
    city: 'Great Neck',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7867,
    longitude: -73.727,
    postal_code: '11021'
  },
  '11022': {
    country: 'US',
    city: 'Great Neck',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7548,
    longitude: -73.6018,
    postal_code: '11022'
  },
  '11023': {
    country: 'US',
    city: 'Great Neck',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7993,
    longitude: -73.7343,
    postal_code: '11023'
  },
  '11024': {
    country: 'US',
    city: 'Great Neck',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8171,
    longitude: -73.7416,
    postal_code: '11024'
  },
  '11026': {
    country: 'US',
    city: 'Great Neck',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7548,
    longitude: -73.6018,
    postal_code: '11026'
  },
  '11027': {
    country: 'US',
    city: 'Great Neck',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8007,
    longitude: -73.7285,
    postal_code: '11027'
  },
  '11030': {
    country: 'US',
    city: 'Manhasset',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7934,
    longitude: -73.6888,
    postal_code: '11030'
  },
  '11040': {
    country: 'US',
    city: 'New Hyde Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7294,
    longitude: -73.6828,
    postal_code: '11040'
  },
  '11042': {
    country: 'US',
    city: 'New Hyde Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7602,
    longitude: -73.695,
    postal_code: '11042'
  },
  '11050': {
    country: 'US',
    city: 'Port Washington',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.835,
    longitude: -73.6964,
    postal_code: '11050'
  },
  '11051': {
    country: 'US',
    city: 'Port Washington',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7548,
    longitude: -73.6018,
    postal_code: '11051'
  },
  '11052': {
    country: 'US',
    city: 'Port Washington',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7548,
    longitude: -73.6018,
    postal_code: '11052'
  },
  '11053': {
    country: 'US',
    city: 'Port Washington',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7548,
    longitude: -73.6018,
    postal_code: '11053'
  },
  '11054': {
    country: 'US',
    city: 'Port Washington',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7548,
    longitude: -73.6018,
    postal_code: '11054'
  },
  '11055': {
    country: 'US',
    city: 'Port Washington',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7548,
    longitude: -73.6018,
    postal_code: '11055'
  },
  '11096': {
    country: 'US',
    city: 'Inwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6205,
    longitude: -73.7474,
    postal_code: '11096'
  },
  '11101': {
    country: 'US',
    city: 'Long Island City',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7446,
    longitude: -73.9345,
    postal_code: '11101'
  },
  '11102': {
    country: 'US',
    city: 'Astoria',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7706,
    longitude: -73.9265,
    postal_code: '11102'
  },
  '11103': {
    country: 'US',
    city: 'Astoria',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7627,
    longitude: -73.9149,
    postal_code: '11103'
  },
  '11104': {
    country: 'US',
    city: 'Sunnyside',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7436,
    longitude: -73.9216,
    postal_code: '11104'
  },
  '11105': {
    country: 'US',
    city: 'Astoria',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7763,
    longitude: -73.911,
    postal_code: '11105'
  },
  '11106': {
    country: 'US',
    city: 'Astoria',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7608,
    longitude: -73.9295,
    postal_code: '11106'
  },
  '11109': {
    country: 'US',
    city: 'Long Island City',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7454,
    longitude: -73.9575,
    postal_code: '11109'
  },
  '11120': {
    country: 'US',
    city: 'Long Island City',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7448,
    longitude: -73.9487,
    postal_code: '11120'
  },
  '11201': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.694,
    longitude: -73.9903,
    postal_code: '11201'
  },
  '11202': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6451,
    longitude: -73.945,
    postal_code: '11202'
  },
  '11203': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6505,
    longitude: -73.9349,
    postal_code: '11203'
  },
  '11204': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6179,
    longitude: -73.9856,
    postal_code: '11204'
  },
  '11205': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6924,
    longitude: -73.9666,
    postal_code: '11205'
  },
  '11206': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.7012,
    longitude: -73.9436,
    postal_code: '11206'
  },
  '11207': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6705,
    longitude: -73.894,
    postal_code: '11207'
  },
  '11208': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6762,
    longitude: -73.8736,
    postal_code: '11208'
  },
  '11209': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6251,
    longitude: -74.0303,
    postal_code: '11209'
  },
  '11210': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6281,
    longitude: -73.9467,
    postal_code: '11210'
  },
  '11211': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.7095,
    longitude: -73.9563,
    postal_code: '11211'
  },
  '11212': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6625,
    longitude: -73.9145,
    postal_code: '11212'
  },
  '11213': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.67,
    longitude: -73.9367,
    postal_code: '11213'
  },
  '11214': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6016,
    longitude: -73.9968,
    postal_code: '11214'
  },
  '11215': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6669,
    longitude: -73.9828,
    postal_code: '11215'
  },
  '11216': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6794,
    longitude: -73.9496,
    postal_code: '11216'
  },
  '11217': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6816,
    longitude: -73.9798,
    postal_code: '11217'
  },
  '11218': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6424,
    longitude: -73.9758,
    postal_code: '11218'
  },
  '11219': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6336,
    longitude: -73.996,
    postal_code: '11219'
  },
  '11220': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6412,
    longitude: -74.0133,
    postal_code: '11220'
  },
  '11221': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6907,
    longitude: -73.9274,
    postal_code: '11221'
  },
  '11222': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.7272,
    longitude: -73.9498,
    postal_code: '11222'
  },
  '11223': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.5979,
    longitude: -73.9743,
    postal_code: '11223'
  },
  '11224': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.5767,
    longitude: -73.9884,
    postal_code: '11224'
  },
  '11225': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6628,
    longitude: -73.9546,
    postal_code: '11225'
  },
  '11226': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6467,
    longitude: -73.957,
    postal_code: '11226'
  },
  '11228': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6174,
    longitude: -74.0121,
    postal_code: '11228'
  },
  '11229': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6011,
    longitude: -73.9475,
    postal_code: '11229'
  },
  '11230': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6225,
    longitude: -73.965,
    postal_code: '11230'
  },
  '11231': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6794,
    longitude: -74.0014,
    postal_code: '11231'
  },
  '11232': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6521,
    longitude: -74.0018,
    postal_code: '11232'
  },
  '11233': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6784,
    longitude: -73.9211,
    postal_code: '11233'
  },
  '11234': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6205,
    longitude: -73.9239,
    postal_code: '11234'
  },
  '11235': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.5839,
    longitude: -73.9536,
    postal_code: '11235'
  },
  '11236': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6407,
    longitude: -73.9028,
    postal_code: '11236'
  },
  '11237': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.7006,
    longitude: -73.918,
    postal_code: '11237'
  },
  '11238': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.679,
    longitude: -73.9644,
    postal_code: '11238'
  },
  '11239': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6497,
    longitude: -73.8824,
    postal_code: '11239'
  },
  '11241': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6451,
    longitude: -73.945,
    postal_code: '11241'
  },
  '11242': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6451,
    longitude: -73.945,
    postal_code: '11242'
  },
  '11243': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6451,
    longitude: -73.945,
    postal_code: '11243'
  },
  '11245': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6451,
    longitude: -73.945,
    postal_code: '11245'
  },
  '11247': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6451,
    longitude: -73.945,
    postal_code: '11247'
  },
  '11249': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6451,
    longitude: -73.945,
    postal_code: '11249'
  },
  '11251': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6451,
    longitude: -73.945,
    postal_code: '11251'
  },
  '11252': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6451,
    longitude: -73.945,
    postal_code: '11252'
  },
  '11256': {
    country: 'US',
    city: 'Brooklyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Kings',
    latitude: 40.6451,
    longitude: -73.945,
    postal_code: '11256'
  },
  '11351': {
    country: 'US',
    city: 'Flushing',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7817,
    longitude: -73.8317,
    postal_code: '11351'
  },
  '11352': {
    country: 'US',
    city: 'Flushing',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6514,
    longitude: -73.8708,
    postal_code: '11352'
  },
  '11354': {
    country: 'US',
    city: 'Flushing',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7667,
    longitude: -73.8241,
    postal_code: '11354'
  },
  '11355': {
    country: 'US',
    city: 'Flushing',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7536,
    longitude: -73.8226,
    postal_code: '11355'
  },
  '11356': {
    country: 'US',
    city: 'College Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7855,
    longitude: -73.845,
    postal_code: '11356'
  },
  '11357': {
    country: 'US',
    city: 'Whitestone',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7851,
    longitude: -73.8096,
    postal_code: '11357'
  },
  '11358': {
    country: 'US',
    city: 'Flushing',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7606,
    longitude: -73.7968,
    postal_code: '11358'
  },
  '11359': {
    country: 'US',
    city: 'Bayside',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7928,
    longitude: -73.7767,
    postal_code: '11359'
  },
  '11360': {
    country: 'US',
    city: 'Bayside',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7807,
    longitude: -73.7812,
    postal_code: '11360'
  },
  '11361': {
    country: 'US',
    city: 'Bayside',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7627,
    longitude: -73.7745,
    postal_code: '11361'
  },
  '11362': {
    country: 'US',
    city: 'Little Neck',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7591,
    longitude: -73.7326,
    postal_code: '11362'
  },
  '11363': {
    country: 'US',
    city: 'Little Neck',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7722,
    longitude: -73.7454,
    postal_code: '11363'
  },
  '11364': {
    country: 'US',
    city: 'Oakland Gardens',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7428,
    longitude: -73.7588,
    postal_code: '11364'
  },
  '11365': {
    country: 'US',
    city: 'Fresh Meadows',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7374,
    longitude: -73.7951,
    postal_code: '11365'
  },
  '11366': {
    country: 'US',
    city: 'Fresh Meadows',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7272,
    longitude: -73.7949,
    postal_code: '11366'
  },
  '11367': {
    country: 'US',
    city: 'Flushing',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.728,
    longitude: -73.8195,
    postal_code: '11367'
  },
  '11368': {
    country: 'US',
    city: 'Corona',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7453,
    longitude: -73.8611,
    postal_code: '11368'
  },
  '11369': {
    country: 'US',
    city: 'East Elmhurst',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7613,
    longitude: -73.8739,
    postal_code: '11369'
  },
  '11370': {
    country: 'US',
    city: 'East Elmhurst',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7611,
    longitude: -73.8916,
    postal_code: '11370'
  },
  '11371': {
    country: 'US',
    city: 'Flushing',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7721,
    longitude: -73.8735,
    postal_code: '11371'
  },
  '11372': {
    country: 'US',
    city: 'Jackson Heights',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7513,
    longitude: -73.883,
    postal_code: '11372'
  },
  '11373': {
    country: 'US',
    city: 'Elmhurst',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7351,
    longitude: -73.8776,
    postal_code: '11373'
  },
  '11374': {
    country: 'US',
    city: 'Rego Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7278,
    longitude: -73.8602,
    postal_code: '11374'
  },
  '11375': {
    country: 'US',
    city: 'Forest Hills',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7229,
    longitude: -73.8473,
    postal_code: '11375'
  },
  '11377': {
    country: 'US',
    city: 'Woodside',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.745,
    longitude: -73.9069,
    postal_code: '11377'
  },
  '11378': {
    country: 'US',
    city: 'Maspeth',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7239,
    longitude: -73.8997,
    postal_code: '11378'
  },
  '11379': {
    country: 'US',
    city: 'Middle Village',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7173,
    longitude: -73.8792,
    postal_code: '11379'
  },
  '11380': {
    country: 'US',
    city: 'Elmhurst',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7365,
    longitude: -73.8779,
    postal_code: '11380'
  },
  '11381': {
    country: 'US',
    city: 'Flushing',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6514,
    longitude: -73.8708,
    postal_code: '11381'
  },
  '11385': {
    country: 'US',
    city: 'Ridgewood',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7036,
    longitude: -73.8961,
    postal_code: '11385'
  },
  '11386': {
    country: 'US',
    city: 'Ridgewood',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7001,
    longitude: -73.9057,
    postal_code: '11386'
  },
  '11405': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6514,
    longitude: -73.8708,
    postal_code: '11405'
  },
  '11411': {
    country: 'US',
    city: 'Cambria Heights',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6947,
    longitude: -73.7374,
    postal_code: '11411'
  },
  '11412': {
    country: 'US',
    city: 'Saint Albans',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6958,
    longitude: -73.7617,
    postal_code: '11412'
  },
  '11413': {
    country: 'US',
    city: 'Springfield Gardens',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6645,
    longitude: -73.7559,
    postal_code: '11413'
  },
  '11414': {
    country: 'US',
    city: 'Howard Beach',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6588,
    longitude: -73.8438,
    postal_code: '11414'
  },
  '11415': {
    country: 'US',
    city: 'Kew Gardens',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7069,
    longitude: -73.8297,
    postal_code: '11415'
  },
  '11416': {
    country: 'US',
    city: 'Ozone Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6838,
    longitude: -73.8514,
    postal_code: '11416'
  },
  '11417': {
    country: 'US',
    city: 'Ozone Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6769,
    longitude: -73.8448,
    postal_code: '11417'
  },
  '11418': {
    country: 'US',
    city: 'Richmond Hill',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6982,
    longitude: -73.8345,
    postal_code: '11418'
  },
  '11419': {
    country: 'US',
    city: 'South Richmond Hill',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6868,
    longitude: -73.823,
    postal_code: '11419'
  },
  '11420': {
    country: 'US',
    city: 'South Ozone Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6744,
    longitude: -73.819,
    postal_code: '11420'
  },
  '11421': {
    country: 'US',
    city: 'Woodhaven',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6913,
    longitude: -73.8585,
    postal_code: '11421'
  },
  '11422': {
    country: 'US',
    city: 'Rosedale',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6621,
    longitude: -73.7353,
    postal_code: '11422'
  },
  '11423': {
    country: 'US',
    city: 'Hollis',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7142,
    longitude: -73.7677,
    postal_code: '11423'
  },
  '11424': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6514,
    longitude: -73.8708,
    postal_code: '11424'
  },
  '11425': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6514,
    longitude: -73.8708,
    postal_code: '11425'
  },
  '11426': {
    country: 'US',
    city: 'Bellerose',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7347,
    longitude: -73.723,
    postal_code: '11426'
  },
  '11427': {
    country: 'US',
    city: 'Queens Village',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7277,
    longitude: -73.7489,
    postal_code: '11427'
  },
  '11428': {
    country: 'US',
    city: 'Queens Village',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7208,
    longitude: -73.7433,
    postal_code: '11428'
  },
  '11429': {
    country: 'US',
    city: 'Queens Village',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.709,
    longitude: -73.7401,
    postal_code: '11429'
  },
  '11430': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6472,
    longitude: -73.7827,
    postal_code: '11430'
  },
  '11431': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6869,
    longitude: -73.8501,
    postal_code: '11431'
  },
  '11432': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7119,
    longitude: -73.7944,
    postal_code: '11432'
  },
  '11433': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6969,
    longitude: -73.7877,
    postal_code: '11433'
  },
  '11434': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6775,
    longitude: -73.7758,
    postal_code: '11434'
  },
  '11435': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.7029,
    longitude: -73.8111,
    postal_code: '11435'
  },
  '11436': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6763,
    longitude: -73.7966,
    postal_code: '11436'
  },
  '11439': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.722,
    longitude: -73.7908,
    postal_code: '11439'
  },
  '11451': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6514,
    longitude: -73.8708,
    postal_code: '11451'
  },
  '11499': {
    country: 'US',
    city: 'Jamaica',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6514,
    longitude: -73.8708,
    postal_code: '11499'
  },
  '11501': {
    country: 'US',
    city: 'Mineola',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7469,
    longitude: -73.6398,
    postal_code: '11501'
  },
  '11507': {
    country: 'US',
    city: 'Albertson',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7703,
    longitude: -73.6514,
    postal_code: '11507'
  },
  '11509': {
    country: 'US',
    city: 'Atlantic Beach',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.5887,
    longitude: -73.7255,
    postal_code: '11509'
  },
  '11510': {
    country: 'US',
    city: 'Baldwin',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6548,
    longitude: -73.6097,
    postal_code: '11510'
  },
  '11514': {
    country: 'US',
    city: 'Carle Place',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7512,
    longitude: -73.6119,
    postal_code: '11514'
  },
  '11516': {
    country: 'US',
    city: 'Cedarhurst',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6236,
    longitude: -73.7264,
    postal_code: '11516'
  },
  '11518': {
    country: 'US',
    city: 'East Rockaway',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6404,
    longitude: -73.6674,
    postal_code: '11518'
  },
  '11520': {
    country: 'US',
    city: 'Freeport',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6536,
    longitude: -73.5866,
    postal_code: '11520'
  },
  '11530': {
    country: 'US',
    city: 'Garden City',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7245,
    longitude: -73.6487,
    postal_code: '11530'
  },
  '11531': {
    country: 'US',
    city: 'Garden City',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7268,
    longitude: -73.6343,
    postal_code: '11531'
  },
  '11542': {
    country: 'US',
    city: 'Glen Cove',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.865,
    longitude: -73.6277,
    postal_code: '11542'
  },
  '11545': {
    country: 'US',
    city: 'Glen Head',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8281,
    longitude: -73.6076,
    postal_code: '11545'
  },
  '11547': {
    country: 'US',
    city: 'Glenwood Landing',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8307,
    longitude: -73.6387,
    postal_code: '11547'
  },
  '11548': {
    country: 'US',
    city: 'Greenvale',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8125,
    longitude: -73.6261,
    postal_code: '11548'
  },
  '11549': {
    country: 'US',
    city: 'Hempstead',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7172,
    longitude: -73.6027,
    postal_code: '11549'
  },
  '11550': {
    country: 'US',
    city: 'Hempstead',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7049,
    longitude: -73.6176,
    postal_code: '11550'
  },
  '11551': {
    country: 'US',
    city: 'Hempstead',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7062,
    longitude: -73.6187,
    postal_code: '11551'
  },
  '11552': {
    country: 'US',
    city: 'West Hempstead',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6929,
    longitude: -73.6539,
    postal_code: '11552'
  },
  '11553': {
    country: 'US',
    city: 'Uniondale',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.702,
    longitude: -73.592,
    postal_code: '11553'
  },
  '11554': {
    country: 'US',
    city: 'East Meadow',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7149,
    longitude: -73.5561,
    postal_code: '11554'
  },
  '11555': {
    country: 'US',
    city: 'Uniondale',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7004,
    longitude: -73.5929,
    postal_code: '11555'
  },
  '11556': {
    country: 'US',
    city: 'Uniondale',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7548,
    longitude: -73.6018,
    postal_code: '11556'
  },
  '11557': {
    country: 'US',
    city: 'Hewlett',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6404,
    longitude: -73.6957,
    postal_code: '11557'
  },
  '11558': {
    country: 'US',
    city: 'Island Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.604,
    longitude: -73.6554,
    postal_code: '11558'
  },
  '11559': {
    country: 'US',
    city: 'Lawrence',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.614,
    longitude: -73.733,
    postal_code: '11559'
  },
  '11560': {
    country: 'US',
    city: 'Locust Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8817,
    longitude: -73.5927,
    postal_code: '11560'
  },
  '11561': {
    country: 'US',
    city: 'Long Beach',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.5877,
    longitude: -73.6595,
    postal_code: '11561'
  },
  '11563': {
    country: 'US',
    city: 'Lynbrook',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6571,
    longitude: -73.6741,
    postal_code: '11563'
  },
  '11565': {
    country: 'US',
    city: 'Malverne',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.675,
    longitude: -73.6731,
    postal_code: '11565'
  },
  '11566': {
    country: 'US',
    city: 'Merrick',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6685,
    longitude: -73.5536,
    postal_code: '11566'
  },
  '11568': {
    country: 'US',
    city: 'Old Westbury',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7882,
    longitude: -73.5875,
    postal_code: '11568'
  },
  '11569': {
    country: 'US',
    city: 'Point Lookout',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.5905,
    longitude: -73.5808,
    postal_code: '11569'
  },
  '11570': {
    country: 'US',
    city: 'Rockville Centre',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6637,
    longitude: -73.638,
    postal_code: '11570'
  },
  '11571': {
    country: 'US',
    city: 'Rockville Centre',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6587,
    longitude: -73.6412,
    postal_code: '11571'
  },
  '11572': {
    country: 'US',
    city: 'Oceanside',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6362,
    longitude: -73.6375,
    postal_code: '11572'
  },
  '11575': {
    country: 'US',
    city: 'Roosevelt',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6802,
    longitude: -73.5867,
    postal_code: '11575'
  },
  '11576': {
    country: 'US',
    city: 'Roslyn',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7984,
    longitude: -73.6477,
    postal_code: '11576'
  },
  '11577': {
    country: 'US',
    city: 'Roslyn Heights',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7845,
    longitude: -73.6403,
    postal_code: '11577'
  },
  '11579': {
    country: 'US',
    city: 'Sea Cliff',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.846,
    longitude: -73.6436,
    postal_code: '11579'
  },
  '11580': {
    country: 'US',
    city: 'Valley Stream',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6742,
    longitude: -73.7057,
    postal_code: '11580'
  },
  '11581': {
    country: 'US',
    city: 'Valley Stream',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6523,
    longitude: -73.7118,
    postal_code: '11581'
  },
  '11582': {
    country: 'US',
    city: 'Valley Stream',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6643,
    longitude: -73.7085,
    postal_code: '11582'
  },
  '11590': {
    country: 'US',
    city: 'Westbury',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7557,
    longitude: -73.5723,
    postal_code: '11590'
  },
  '11596': {
    country: 'US',
    city: 'Williston Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7592,
    longitude: -73.6449,
    postal_code: '11596'
  },
  '11598': {
    country: 'US',
    city: 'Woodmere',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6326,
    longitude: -73.7141,
    postal_code: '11598'
  },
  '11599': {
    country: 'US',
    city: 'Garden City',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7268,
    longitude: -73.6343,
    postal_code: '11599'
  },
  '11690': {
    country: 'US',
    city: 'Far Rockaway',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6054,
    longitude: -73.7551,
    postal_code: '11690'
  },
  '11691': {
    country: 'US',
    city: 'Far Rockaway',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6006,
    longitude: -73.758,
    postal_code: '11691'
  },
  '11692': {
    country: 'US',
    city: 'Arverne',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.5923,
    longitude: -73.7933,
    postal_code: '11692'
  },
  '11693': {
    country: 'US',
    city: 'Far Rockaway',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6076,
    longitude: -73.8198,
    postal_code: '11693'
  },
  '11694': {
    country: 'US',
    city: 'Rockaway Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.5766,
    longitude: -73.8428,
    postal_code: '11694'
  },
  '11695': {
    country: 'US',
    city: 'Far Rockaway',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.6514,
    longitude: -73.8708,
    postal_code: '11695'
  },
  '11697': {
    country: 'US',
    city: 'Breezy Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Queens',
    latitude: 40.5594,
    longitude: -73.9067,
    postal_code: '11697'
  },
  '11701': {
    country: 'US',
    city: 'Amityville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.6842,
    longitude: -73.4171,
    postal_code: '11701'
  },
  '11702': {
    country: 'US',
    city: 'Babylon',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.6957,
    longitude: -73.3257,
    postal_code: '11702'
  },
  '11703': {
    country: 'US',
    city: 'North Babylon',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7321,
    longitude: -73.3236,
    postal_code: '11703'
  },
  '11704': {
    country: 'US',
    city: 'West Babylon',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7135,
    longitude: -73.3546,
    postal_code: '11704'
  },
  '11705': {
    country: 'US',
    city: 'Bayport',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7444,
    longitude: -73.0542,
    postal_code: '11705'
  },
  '11706': {
    country: 'US',
    city: 'Bay Shore',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7051,
    longitude: -73.243,
    postal_code: '11706'
  },
  '11707': {
    country: 'US',
    city: 'West Babylon',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7182,
    longitude: -73.3543,
    postal_code: '11707'
  },
  '11709': {
    country: 'US',
    city: 'Bayville',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.9074,
    longitude: -73.5601,
    postal_code: '11709'
  },
  '11710': {
    country: 'US',
    city: 'Bellmore',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6729,
    longitude: -73.5365,
    postal_code: '11710'
  },
  '11713': {
    country: 'US',
    city: 'Bellport',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7733,
    longitude: -72.9469,
    postal_code: '11713'
  },
  '11714': {
    country: 'US',
    city: 'Bethpage',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.74,
    longitude: -73.4857,
    postal_code: '11714'
  },
  '11715': {
    country: 'US',
    city: 'Blue Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7501,
    longitude: -73.0352,
    postal_code: '11715'
  },
  '11716': {
    country: 'US',
    city: 'Bohemia',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7678,
    longitude: -73.1163,
    postal_code: '11716'
  },
  '11717': {
    country: 'US',
    city: 'Brentwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7809,
    longitude: -73.2503,
    postal_code: '11717'
  },
  '11718': {
    country: 'US',
    city: 'Brightwaters',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.728,
    longitude: -73.2646,
    postal_code: '11718'
  },
  '11719': {
    country: 'US',
    city: 'Brookhaven',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7843,
    longitude: -72.8921,
    postal_code: '11719'
  },
  '11720': {
    country: 'US',
    city: 'Centereach',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8705,
    longitude: -73.0822,
    postal_code: '11720'
  },
  '11721': {
    country: 'US',
    city: 'Centerport',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8929,
    longitude: -73.3754,
    postal_code: '11721'
  },
  '11722': {
    country: 'US',
    city: 'Central Islip',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7866,
    longitude: -73.1961,
    postal_code: '11722'
  },
  '11724': {
    country: 'US',
    city: 'Cold Spring Harbor',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8601,
    longitude: -73.4423,
    postal_code: '11724'
  },
  '11725': {
    country: 'US',
    city: 'Commack',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.843,
    longitude: -73.2799,
    postal_code: '11725'
  },
  '11726': {
    country: 'US',
    city: 'Copiague',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.6778,
    longitude: -73.3963,
    postal_code: '11726'
  },
  '11727': {
    country: 'US',
    city: 'Coram',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.885,
    longitude: -73.0069,
    postal_code: '11727'
  },
  '11729': {
    country: 'US',
    city: 'Deer Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7591,
    longitude: -73.3257,
    postal_code: '11729'
  },
  '11730': {
    country: 'US',
    city: 'East Islip',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7282,
    longitude: -73.1805,
    postal_code: '11730'
  },
  '11731': {
    country: 'US',
    city: 'East Northport',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.857,
    longitude: -73.3146,
    postal_code: '11731'
  },
  '11732': {
    country: 'US',
    city: 'East Norwich',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8472,
    longitude: -73.5349,
    postal_code: '11732'
  },
  '11733': {
    country: 'US',
    city: 'East Setauket',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9426,
    longitude: -73.1116,
    postal_code: '11733'
  },
  '11735': {
    country: 'US',
    city: 'Farmingdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7315,
    longitude: -73.4327,
    postal_code: '11735'
  },
  '11737': {
    country: 'US',
    city: 'Farmingdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7326,
    longitude: -73.4454,
    postal_code: '11737'
  },
  '11738': {
    country: 'US',
    city: 'Farmingville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8366,
    longitude: -73.0413,
    postal_code: '11738'
  },
  '11739': {
    country: 'US',
    city: 'Great River',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7297,
    longitude: -73.1607,
    postal_code: '11739'
  },
  '11740': {
    country: 'US',
    city: 'Greenlawn',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8621,
    longitude: -73.3646,
    postal_code: '11740'
  },
  '11741': {
    country: 'US',
    city: 'Holbrook',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7964,
    longitude: -73.0718,
    postal_code: '11741'
  },
  '11742': {
    country: 'US',
    city: 'Holtsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8105,
    longitude: -73.0416,
    postal_code: '11742'
  },
  '11743': {
    country: 'US',
    city: 'Huntington',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8676,
    longitude: -73.4102,
    postal_code: '11743'
  },
  '11746': {
    country: 'US',
    city: 'Huntington Station',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8143,
    longitude: -73.3634,
    postal_code: '11746'
  },
  '11747': {
    country: 'US',
    city: 'Melville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7946,
    longitude: -73.403,
    postal_code: '11747'
  },
  '11749': {
    country: 'US',
    city: 'Islandia',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8067,
    longitude: -73.1709,
    postal_code: '11749'
  },
  '11751': {
    country: 'US',
    city: 'Islip',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7348,
    longitude: -73.2221,
    postal_code: '11751'
  },
  '11752': {
    country: 'US',
    city: 'Islip Terrace',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7548,
    longitude: -73.1827,
    postal_code: '11752'
  },
  '11753': {
    country: 'US',
    city: 'Jericho',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7881,
    longitude: -73.5331,
    postal_code: '11753'
  },
  '11754': {
    country: 'US',
    city: 'Kings Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8861,
    longitude: -73.2438,
    postal_code: '11754'
  },
  '11755': {
    country: 'US',
    city: 'Lake Grove',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8567,
    longitude: -73.1168,
    postal_code: '11755'
  },
  '11756': {
    country: 'US',
    city: 'Levittown',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7254,
    longitude: -73.5166,
    postal_code: '11756'
  },
  '11757': {
    country: 'US',
    city: 'Lindenhurst',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.6884,
    longitude: -73.3745,
    postal_code: '11757'
  },
  '11758': {
    country: 'US',
    city: 'Massapequa',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6682,
    longitude: -73.4588,
    postal_code: '11758'
  },
  '11760': {
    country: 'US',
    city: 'Melville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7934,
    longitude: -73.4151,
    postal_code: '11760'
  },
  '11762': {
    country: 'US',
    city: 'Massapequa Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6807,
    longitude: -73.4444,
    postal_code: '11762'
  },
  '11763': {
    country: 'US',
    city: 'Medford',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8174,
    longitude: -72.9852,
    postal_code: '11763'
  },
  '11764': {
    country: 'US',
    city: 'Miller Place',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9436,
    longitude: -72.9913,
    postal_code: '11764'
  },
  '11765': {
    country: 'US',
    city: 'Mill Neck',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8857,
    longitude: -73.5526,
    postal_code: '11765'
  },
  '11766': {
    country: 'US',
    city: 'Mount Sinai',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9271,
    longitude: -73.0127,
    postal_code: '11766'
  },
  '11767': {
    country: 'US',
    city: 'Nesconset',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8462,
    longitude: -73.1482,
    postal_code: '11767'
  },
  '11768': {
    country: 'US',
    city: 'Northport',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9051,
    longitude: -73.3309,
    postal_code: '11768'
  },
  '11769': {
    country: 'US',
    city: 'Oakdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7382,
    longitude: -73.1297,
    postal_code: '11769'
  },
  '11770': {
    country: 'US',
    city: 'Ocean Beach',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.6443,
    longitude: -73.1613,
    postal_code: '11770'
  },
  '11771': {
    country: 'US',
    city: 'Oyster Bay',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.866,
    longitude: -73.5272,
    postal_code: '11771'
  },
  '11772': {
    country: 'US',
    city: 'Patchogue',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7609,
    longitude: -72.9871,
    postal_code: '11772'
  },
  '11773': {
    country: 'US',
    city: 'Syosset',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8262,
    longitude: -73.5021,
    postal_code: '11773'
  },
  '11775': {
    country: 'US',
    city: 'Melville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7934,
    longitude: -73.4151,
    postal_code: '11775'
  },
  '11776': {
    country: 'US',
    city: 'Port Jefferson Station',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9136,
    longitude: -73.0464,
    postal_code: '11776'
  },
  '11777': {
    country: 'US',
    city: 'Port Jefferson',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9457,
    longitude: -73.0611,
    postal_code: '11777'
  },
  '11778': {
    country: 'US',
    city: 'Rocky Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9492,
    longitude: -72.9357,
    postal_code: '11778'
  },
  '11779': {
    country: 'US',
    city: 'Ronkonkoma',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8083,
    longitude: -73.1305,
    postal_code: '11779'
  },
  '11780': {
    country: 'US',
    city: 'Saint James',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8813,
    longitude: -73.1591,
    postal_code: '11780'
  },
  '11782': {
    country: 'US',
    city: 'Sayville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7459,
    longitude: -73.0859,
    postal_code: '11782'
  },
  '11783': {
    country: 'US',
    city: 'Seaford',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.6795,
    longitude: -73.491,
    postal_code: '11783'
  },
  '11784': {
    country: 'US',
    city: 'Selden',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8699,
    longitude: -73.0448,
    postal_code: '11784'
  },
  '11786': {
    country: 'US',
    city: 'Shoreham',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9485,
    longitude: -72.8927,
    postal_code: '11786'
  },
  '11787': {
    country: 'US',
    city: 'Smithtown',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8542,
    longitude: -73.2138,
    postal_code: '11787'
  },
  '11788': {
    country: 'US',
    city: 'Hauppauge',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8231,
    longitude: -73.1958,
    postal_code: '11788'
  },
  '11789': {
    country: 'US',
    city: 'Sound Beach',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9567,
    longitude: -72.9742,
    postal_code: '11789'
  },
  '11790': {
    country: 'US',
    city: 'Stony Brook',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9068,
    longitude: -73.1277,
    postal_code: '11790'
  },
  '11791': {
    country: 'US',
    city: 'Syosset',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8146,
    longitude: -73.5024,
    postal_code: '11791'
  },
  '11792': {
    country: 'US',
    city: 'Wading River',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.952,
    longitude: -72.8348,
    postal_code: '11792'
  },
  '11793': {
    country: 'US',
    city: 'Wantagh',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.685,
    longitude: -73.5103,
    postal_code: '11793'
  },
  '11794': {
    country: 'US',
    city: 'Stony Brook',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9257,
    longitude: -73.1409,
    postal_code: '11794'
  },
  '11795': {
    country: 'US',
    city: 'West Islip',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7117,
    longitude: -73.3007,
    postal_code: '11795'
  },
  '11796': {
    country: 'US',
    city: 'West Sayville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.732,
    longitude: -73.1,
    postal_code: '11796'
  },
  '11797': {
    country: 'US',
    city: 'Woodbury',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.8154,
    longitude: -73.4716,
    postal_code: '11797'
  },
  '11798': {
    country: 'US',
    city: 'Wyandanch',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7523,
    longitude: -73.3761,
    postal_code: '11798'
  },
  '11801': {
    country: 'US',
    city: 'Hicksville',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7623,
    longitude: -73.523,
    postal_code: '11801'
  },
  '11802': {
    country: 'US',
    city: 'Hicksville',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7684,
    longitude: -73.5251,
    postal_code: '11802'
  },
  '11803': {
    country: 'US',
    city: 'Plainview',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7781,
    longitude: -73.4816,
    postal_code: '11803'
  },
  '11804': {
    country: 'US',
    city: 'Old Bethpage',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.765,
    longitude: -73.4575,
    postal_code: '11804'
  },
  '11815': {
    country: 'US',
    city: 'Hicksville',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7548,
    longitude: -73.6018,
    postal_code: '11815'
  },
  '11819': {
    country: 'US',
    city: 'Hicksville',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.7684,
    longitude: -73.5251,
    postal_code: '11819'
  },
  '11853': {
    country: 'US',
    city: 'Jericho',
    state: 'New York',
    state_short: 'NY',
    county: 'Nassau',
    latitude: 40.792,
    longitude: -73.5398,
    postal_code: '11853'
  },
  '11901': {
    country: 'US',
    city: 'Riverhead',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9262,
    longitude: -72.652,
    postal_code: '11901'
  },
  '11930': {
    country: 'US',
    city: 'Amagansett',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9895,
    longitude: -72.0959,
    postal_code: '11930'
  },
  '11931': {
    country: 'US',
    city: 'Aquebogue',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9445,
    longitude: -72.627,
    postal_code: '11931'
  },
  '11932': {
    country: 'US',
    city: 'Bridgehampton',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9339,
    longitude: -72.3077,
    postal_code: '11932'
  },
  '11933': {
    country: 'US',
    city: 'Calverton',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9297,
    longitude: -72.7423,
    postal_code: '11933'
  },
  '11934': {
    country: 'US',
    city: 'Center Moriches',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7997,
    longitude: -72.797,
    postal_code: '11934'
  },
  '11935': {
    country: 'US',
    city: 'Cutchogue',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 41.0139,
    longitude: -72.4803,
    postal_code: '11935'
  },
  '11937': {
    country: 'US',
    city: 'East Hampton',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.993,
    longitude: -72.179,
    postal_code: '11937'
  },
  '11939': {
    country: 'US',
    city: 'East Marion',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 41.1264,
    longitude: -72.3419,
    postal_code: '11939'
  },
  '11940': {
    country: 'US',
    city: 'East Moriches',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.809,
    longitude: -72.7538,
    postal_code: '11940'
  },
  '11941': {
    country: 'US',
    city: 'Eastport',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8297,
    longitude: -72.7283,
    postal_code: '11941'
  },
  '11942': {
    country: 'US',
    city: 'East Quogue',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8428,
    longitude: -72.5813,
    postal_code: '11942'
  },
  '11944': {
    country: 'US',
    city: 'Greenport',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 41.1039,
    longitude: -72.3674,
    postal_code: '11944'
  },
  '11946': {
    country: 'US',
    city: 'Hampton Bays',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8726,
    longitude: -72.5202,
    postal_code: '11946'
  },
  '11947': {
    country: 'US',
    city: 'Jamesport',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9495,
    longitude: -72.5815,
    postal_code: '11947'
  },
  '11948': {
    country: 'US',
    city: 'Laurel',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9674,
    longitude: -72.554,
    postal_code: '11948'
  },
  '11949': {
    country: 'US',
    city: 'Manorville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8421,
    longitude: -72.8002,
    postal_code: '11949'
  },
  '11950': {
    country: 'US',
    city: 'Mastic',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8064,
    longitude: -72.8566,
    postal_code: '11950'
  },
  '11951': {
    country: 'US',
    city: 'Mastic Beach',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.7657,
    longitude: -72.8537,
    postal_code: '11951'
  },
  '11952': {
    country: 'US',
    city: 'Mattituck',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9943,
    longitude: -72.5363,
    postal_code: '11952'
  },
  '11953': {
    country: 'US',
    city: 'Middle Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8782,
    longitude: -72.9525,
    postal_code: '11953'
  },
  '11954': {
    country: 'US',
    city: 'Montauk',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 41.0459,
    longitude: -71.944,
    postal_code: '11954'
  },
  '11955': {
    country: 'US',
    city: 'Moriches',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8095,
    longitude: -72.8229,
    postal_code: '11955'
  },
  '11956': {
    country: 'US',
    city: 'New Suffolk',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9915,
    longitude: -72.4759,
    postal_code: '11956'
  },
  '11957': {
    country: 'US',
    city: 'Orient',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 41.1437,
    longitude: -72.2879,
    postal_code: '11957'
  },
  '11958': {
    country: 'US',
    city: 'Peconic',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 41.0392,
    longitude: -72.4666,
    postal_code: '11958'
  },
  '11959': {
    country: 'US',
    city: 'Quogue',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8226,
    longitude: -72.6012,
    postal_code: '11959'
  },
  '11960': {
    country: 'US',
    city: 'Remsenburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8086,
    longitude: -72.7064,
    postal_code: '11960'
  },
  '11961': {
    country: 'US',
    city: 'Ridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9018,
    longitude: -72.8881,
    postal_code: '11961'
  },
  '11962': {
    country: 'US',
    city: 'Sagaponack',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9305,
    longitude: -72.2707,
    postal_code: '11962'
  },
  '11963': {
    country: 'US',
    city: 'Sag Harbor',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.982,
    longitude: -72.3067,
    postal_code: '11963'
  },
  '11964': {
    country: 'US',
    city: 'Shelter Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 41.064,
    longitude: -72.3366,
    postal_code: '11964'
  },
  '11965': {
    country: 'US',
    city: 'Shelter Island Heights',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 41.084,
    longitude: -72.3559,
    postal_code: '11965'
  },
  '11967': {
    country: 'US',
    city: 'Shirley',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8015,
    longitude: -72.8676,
    postal_code: '11967'
  },
  '11968': {
    country: 'US',
    city: 'Southampton',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9043,
    longitude: -72.4103,
    postal_code: '11968'
  },
  '11969': {
    country: 'US',
    city: 'Southampton',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8843,
    longitude: -72.3895,
    postal_code: '11969'
  },
  '11970': {
    country: 'US',
    city: 'South Jamesport',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9365,
    longitude: -72.5773,
    postal_code: '11970'
  },
  '11971': {
    country: 'US',
    city: 'Southold',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 41.0555,
    longitude: -72.429,
    postal_code: '11971'
  },
  '11972': {
    country: 'US',
    city: 'Speonk',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8195,
    longitude: -72.7054,
    postal_code: '11972'
  },
  '11973': {
    country: 'US',
    city: 'Upton',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8678,
    longitude: -72.8822,
    postal_code: '11973'
  },
  '11975': {
    country: 'US',
    city: 'Wainscott',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9396,
    longitude: -72.2425,
    postal_code: '11975'
  },
  '11976': {
    country: 'US',
    city: 'Water Mill',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.9209,
    longitude: -72.3491,
    postal_code: '11976'
  },
  '11977': {
    country: 'US',
    city: 'Westhampton',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.818,
    longitude: -72.6699,
    postal_code: '11977'
  },
  '11978': {
    country: 'US',
    city: 'Westhampton Beach',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8295,
    longitude: -72.6473,
    postal_code: '11978'
  },
  '11980': {
    country: 'US',
    city: 'Yaphank',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.837,
    longitude: -72.9174,
    postal_code: '11980'
  },
  '12007': {
    country: 'US',
    city: 'Alcove',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.4561,
    longitude: -73.9277,
    postal_code: '12007'
  },
  '12008': {
    country: 'US',
    city: 'Alplaus',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.8573,
    longitude: -73.9002,
    postal_code: '12008'
  },
  '12009': {
    country: 'US',
    city: 'Altamont',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.7063,
    longitude: -74.0193,
    postal_code: '12009'
  },
  '12010': {
    country: 'US',
    city: 'Amsterdam',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9387,
    longitude: -74.1882,
    postal_code: '12010'
  },
  '12015': {
    country: 'US',
    city: 'Athens',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2736,
    longitude: -73.8152,
    postal_code: '12015'
  },
  '12016': {
    country: 'US',
    city: 'Auriesville',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9295,
    longitude: -74.3165,
    postal_code: '12016'
  },
  '12017': {
    country: 'US',
    city: 'Austerlitz',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.3223,
    longitude: -73.455,
    postal_code: '12017'
  },
  '12018': {
    country: 'US',
    city: 'Averill Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.6365,
    longitude: -73.5504,
    postal_code: '12018'
  },
  '12019': {
    country: 'US',
    city: 'Ballston Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 42.9192,
    longitude: -73.8552,
    postal_code: '12019'
  },
  '12020': {
    country: 'US',
    city: 'Ballston Spa',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.005,
    longitude: -73.8486,
    postal_code: '12020'
  },
  '12022': {
    country: 'US',
    city: 'Berlin',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.6919,
    longitude: -73.3702,
    postal_code: '12022'
  },
  '12023': {
    country: 'US',
    city: 'Berne',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6108,
    longitude: -74.1466,
    postal_code: '12023'
  },
  '12024': {
    country: 'US',
    city: 'Brainard',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.495,
    longitude: -73.5107,
    postal_code: '12024'
  },
  '12025': {
    country: 'US',
    city: 'Broadalbin',
    state: 'New York',
    state_short: 'NY',
    county: 'Fulton',
    latitude: 43.0727,
    longitude: -74.1684,
    postal_code: '12025'
  },
  '12027': {
    country: 'US',
    city: 'Burnt Hills',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 42.9329,
    longitude: -73.896,
    postal_code: '12027'
  },
  '12028': {
    country: 'US',
    city: 'Buskirk',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.9601,
    longitude: -73.4497,
    postal_code: '12028'
  },
  '12029': {
    country: 'US',
    city: 'Canaan',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.4132,
    longitude: -73.4159,
    postal_code: '12029'
  },
  '12031': {
    country: 'US',
    city: 'Carlisle',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.7648,
    longitude: -74.4569,
    postal_code: '12031'
  },
  '12032': {
    country: 'US',
    city: 'Caroga Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Fulton',
    latitude: 43.1922,
    longitude: -74.5169,
    postal_code: '12032'
  },
  '12033': {
    country: 'US',
    city: 'Castleton On Hudson',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.5376,
    longitude: -73.7071,
    postal_code: '12033'
  },
  '12035': {
    country: 'US',
    city: 'Central Bridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.737,
    longitude: -74.3451,
    postal_code: '12035'
  },
  '12036': {
    country: 'US',
    city: 'Charlotteville',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.533,
    longitude: -74.6819,
    postal_code: '12036'
  },
  '12037': {
    country: 'US',
    city: 'Chatham',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.3496,
    longitude: -73.5873,
    postal_code: '12037'
  },
  '12040': {
    country: 'US',
    city: 'Cherry Plain',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.6467,
    longitude: -73.3716,
    postal_code: '12040'
  },
  '12041': {
    country: 'US',
    city: 'Clarksville',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.5759,
    longitude: -73.964,
    postal_code: '12041'
  },
  '12042': {
    country: 'US',
    city: 'Climax',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3659,
    longitude: -73.851,
    postal_code: '12042'
  },
  '12043': {
    country: 'US',
    city: 'Cobleskill',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.684,
    longitude: -74.4939,
    postal_code: '12043'
  },
  '12045': {
    country: 'US',
    city: 'Coeymans',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.4757,
    longitude: -73.7977,
    postal_code: '12045'
  },
  '12046': {
    country: 'US',
    city: 'Coeymans Hollow',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.4865,
    longitude: -73.9206,
    postal_code: '12046'
  },
  '12047': {
    country: 'US',
    city: 'Cohoes',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.7754,
    longitude: -73.7124,
    postal_code: '12047'
  },
  '12050': {
    country: 'US',
    city: 'Columbiaville',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.3172,
    longitude: -73.7486,
    postal_code: '12050'
  },
  '12051': {
    country: 'US',
    city: 'Coxsackie',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3501,
    longitude: -73.8199,
    postal_code: '12051'
  },
  '12052': {
    country: 'US',
    city: 'Cropseyville',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.7667,
    longitude: -73.4719,
    postal_code: '12052'
  },
  '12053': {
    country: 'US',
    city: 'Delanson',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.748,
    longitude: -74.1868,
    postal_code: '12053'
  },
  '12054': {
    country: 'US',
    city: 'Delmar',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6158,
    longitude: -73.8373,
    postal_code: '12054'
  },
  '12055': {
    country: 'US',
    city: 'Dormansville',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6149,
    longitude: -73.9708,
    postal_code: '12055'
  },
  '12056': {
    country: 'US',
    city: 'Duanesburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.7708,
    longitude: -74.0839,
    postal_code: '12056'
  },
  '12057': {
    country: 'US',
    city: 'Eagle Bridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 42.9808,
    longitude: -73.3522,
    postal_code: '12057'
  },
  '12058': {
    country: 'US',
    city: 'Earlton',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3527,
    longitude: -73.9062,
    postal_code: '12058'
  },
  '12059': {
    country: 'US',
    city: 'East Berne',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6191,
    longitude: -74.0555,
    postal_code: '12059'
  },
  '12060': {
    country: 'US',
    city: 'East Chatham',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.433,
    longitude: -73.4903,
    postal_code: '12060'
  },
  '12061': {
    country: 'US',
    city: 'East Greenbush',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.5951,
    longitude: -73.6826,
    postal_code: '12061'
  },
  '12062': {
    country: 'US',
    city: 'East Nassau',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.5352,
    longitude: -73.4984,
    postal_code: '12062'
  },
  '12063': {
    country: 'US',
    city: 'East Schodack',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.5637,
    longitude: -73.6274,
    postal_code: '12063'
  },
  '12064': {
    country: 'US',
    city: 'East Worcester',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.6321,
    longitude: -74.6674,
    postal_code: '12064'
  },
  '12065': {
    country: 'US',
    city: 'Clifton Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 42.8499,
    longitude: -73.7851,
    postal_code: '12065'
  },
  '12066': {
    country: 'US',
    city: 'Esperance',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.7717,
    longitude: -74.2882,
    postal_code: '12066'
  },
  '12067': {
    country: 'US',
    city: 'Feura Bush',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.555,
    longitude: -73.9237,
    postal_code: '12067'
  },
  '12068': {
    country: 'US',
    city: 'Fonda',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9571,
    longitude: -74.4021,
    postal_code: '12068'
  },
  '12069': {
    country: 'US',
    city: 'Fort Hunter',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9423,
    longitude: -74.2851,
    postal_code: '12069'
  },
  '12070': {
    country: 'US',
    city: 'Fort Johnson',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9765,
    longitude: -74.2484,
    postal_code: '12070'
  },
  '12071': {
    country: 'US',
    city: 'Fultonham',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.5923,
    longitude: -74.4381,
    postal_code: '12071'
  },
  '12072': {
    country: 'US',
    city: 'Fultonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9036,
    longitude: -74.3598,
    postal_code: '12072'
  },
  '12073': {
    country: 'US',
    city: 'Gallupville',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.6626,
    longitude: -74.2326,
    postal_code: '12073'
  },
  '12074': {
    country: 'US',
    city: 'Galway',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.0217,
    longitude: -74.029,
    postal_code: '12074'
  },
  '12075': {
    country: 'US',
    city: 'Ghent',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.3036,
    longitude: -73.6486,
    postal_code: '12075'
  },
  '12076': {
    country: 'US',
    city: 'Gilboa',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.4108,
    longitude: -74.4003,
    postal_code: '12076'
  },
  '12077': {
    country: 'US',
    city: 'Glenmont',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.5971,
    longitude: -73.7959,
    postal_code: '12077'
  },
  '12078': {
    country: 'US',
    city: 'Gloversville',
    state: 'New York',
    state_short: 'NY',
    county: 'Fulton',
    latitude: 43.0616,
    longitude: -74.3375,
    postal_code: '12078'
  },
  '12082': {
    country: 'US',
    city: 'Grafton',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.7726,
    longitude: -73.4468,
    postal_code: '12082'
  },
  '12083': {
    country: 'US',
    city: 'Greenville',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.4113,
    longitude: -74.0222,
    postal_code: '12083'
  },
  '12084': {
    country: 'US',
    city: 'Guilderland',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6973,
    longitude: -73.8975,
    postal_code: '12084'
  },
  '12085': {
    country: 'US',
    city: 'Guilderland Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.702,
    longitude: -73.9662,
    postal_code: '12085'
  },
  '12086': {
    country: 'US',
    city: 'Hagaman',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9695,
    longitude: -74.1556,
    postal_code: '12086'
  },
  '12087': {
    country: 'US',
    city: 'Hannacroix',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.429,
    longitude: -73.8093,
    postal_code: '12087'
  },
  '12089': {
    country: 'US',
    city: 'Hoosick',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.8667,
    longitude: -73.3181,
    postal_code: '12089'
  },
  '12090': {
    country: 'US',
    city: 'Hoosick Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.8937,
    longitude: -73.3581,
    postal_code: '12090'
  },
  '12092': {
    country: 'US',
    city: 'Howes Cave',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.7045,
    longitude: -74.3648,
    postal_code: '12092'
  },
  '12093': {
    country: 'US',
    city: 'Jefferson',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.4999,
    longitude: -74.6117,
    postal_code: '12093'
  },
  '12094': {
    country: 'US',
    city: 'Johnsonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.8769,
    longitude: -73.4989,
    postal_code: '12094'
  },
  '12095': {
    country: 'US',
    city: 'Johnstown',
    state: 'New York',
    state_short: 'NY',
    county: 'Fulton',
    latitude: 43.0069,
    longitude: -74.3715,
    postal_code: '12095'
  },
  '12106': {
    country: 'US',
    city: 'Kinderhook',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.3767,
    longitude: -73.7183,
    postal_code: '12106'
  },
  '12107': {
    country: 'US',
    city: 'Knox',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.66,
    longitude: -74.1168,
    postal_code: '12107'
  },
  '12108': {
    country: 'US',
    city: 'Lake Pleasant',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.6676,
    longitude: -74.4569,
    postal_code: '12108'
  },
  '12110': {
    country: 'US',
    city: 'Latham',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.7462,
    longitude: -73.763,
    postal_code: '12110'
  },
  '12115': {
    country: 'US',
    city: 'Malden Bridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.4725,
    longitude: -73.5807,
    postal_code: '12115'
  },
  '12116': {
    country: 'US',
    city: 'Maryland',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.5371,
    longitude: -74.903,
    postal_code: '12116'
  },
  '12117': {
    country: 'US',
    city: 'Mayfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Fulton',
    latitude: 43.1411,
    longitude: -74.2444,
    postal_code: '12117'
  },
  '12118': {
    country: 'US',
    city: 'Mechanicville',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 42.9168,
    longitude: -73.7214,
    postal_code: '12118'
  },
  '12120': {
    country: 'US',
    city: 'Medusa',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.4515,
    longitude: -74.1315,
    postal_code: '12120'
  },
  '12121': {
    country: 'US',
    city: 'Melrose',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.8412,
    longitude: -73.6077,
    postal_code: '12121'
  },
  '12122': {
    country: 'US',
    city: 'Middleburgh',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.5637,
    longitude: -74.3292,
    postal_code: '12122'
  },
  '12123': {
    country: 'US',
    city: 'Nassau',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.5271,
    longitude: -73.6118,
    postal_code: '12123'
  },
  '12124': {
    country: 'US',
    city: 'New Baltimore',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.4442,
    longitude: -73.7882,
    postal_code: '12124'
  },
  '12125': {
    country: 'US',
    city: 'New Lebanon',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.4759,
    longitude: -73.3773,
    postal_code: '12125'
  },
  '12128': {
    country: 'US',
    city: 'Newtonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.725,
    longitude: -73.7643,
    postal_code: '12128'
  },
  '12130': {
    country: 'US',
    city: 'Niverville',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.44,
    longitude: -73.6663,
    postal_code: '12130'
  },
  '12131': {
    country: 'US',
    city: 'North Blenheim',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.4899,
    longitude: -74.4282,
    postal_code: '12131'
  },
  '12132': {
    country: 'US',
    city: 'North Chatham',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.4705,
    longitude: -73.63,
    postal_code: '12132'
  },
  '12133': {
    country: 'US',
    city: 'North Hoosick',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.9261,
    longitude: -73.3464,
    postal_code: '12133'
  },
  '12134': {
    country: 'US',
    city: 'Northville',
    state: 'New York',
    state_short: 'NY',
    county: 'Fulton',
    latitude: 43.2662,
    longitude: -74.2288,
    postal_code: '12134'
  },
  '12136': {
    country: 'US',
    city: 'Old Chatham',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.4357,
    longitude: -73.5545,
    postal_code: '12136'
  },
  '12137': {
    country: 'US',
    city: 'Pattersonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.8499,
    longitude: -74.1231,
    postal_code: '12137'
  },
  '12138': {
    country: 'US',
    city: 'Petersburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.7495,
    longitude: -73.3401,
    postal_code: '12138'
  },
  '12139': {
    country: 'US',
    city: 'Piseco',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.4481,
    longitude: -74.5263,
    postal_code: '12139'
  },
  '12140': {
    country: 'US',
    city: 'Poestenkill',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.6918,
    longitude: -73.5627,
    postal_code: '12140'
  },
  '12141': {
    country: 'US',
    city: 'Quaker Street',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.7318,
    longitude: -74.1854,
    postal_code: '12141'
  },
  '12143': {
    country: 'US',
    city: 'Ravena',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.4754,
    longitude: -73.822,
    postal_code: '12143'
  },
  '12144': {
    country: 'US',
    city: 'Rensselaer',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.6359,
    longitude: -73.7219,
    postal_code: '12144'
  },
  '12147': {
    country: 'US',
    city: 'Rensselaerville',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.5133,
    longitude: -74.1474,
    postal_code: '12147'
  },
  '12148': {
    country: 'US',
    city: 'Rexford',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 42.8524,
    longitude: -73.8701,
    postal_code: '12148'
  },
  '12149': {
    country: 'US',
    city: 'Richmondville',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.6424,
    longitude: -74.571,
    postal_code: '12149'
  },
  '12150': {
    country: 'US',
    city: 'Rotterdam Junction',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.8745,
    longitude: -74.0465,
    postal_code: '12150'
  },
  '12151': {
    country: 'US',
    city: 'Round Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 42.9247,
    longitude: -73.7859,
    postal_code: '12151'
  },
  '12153': {
    country: 'US',
    city: 'Sand Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.6379,
    longitude: -73.4989,
    postal_code: '12153'
  },
  '12154': {
    country: 'US',
    city: 'Schaghticoke',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.9144,
    longitude: -73.6154,
    postal_code: '12154'
  },
  '12155': {
    country: 'US',
    city: 'Schenevus',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.59,
    longitude: -74.8149,
    postal_code: '12155'
  },
  '12156': {
    country: 'US',
    city: 'Schodack Landing',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.4816,
    longitude: -73.748,
    postal_code: '12156'
  },
  '12157': {
    country: 'US',
    city: 'Schoharie',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.6615,
    longitude: -74.3047,
    postal_code: '12157'
  },
  '12158': {
    country: 'US',
    city: 'Selkirk',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.5486,
    longitude: -73.8129,
    postal_code: '12158'
  },
  '12159': {
    country: 'US',
    city: 'Slingerlands',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6485,
    longitude: -73.8711,
    postal_code: '12159'
  },
  '12160': {
    country: 'US',
    city: 'Sloansville',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.7599,
    longitude: -74.3642,
    postal_code: '12160'
  },
  '12161': {
    country: 'US',
    city: 'South Bethlehem',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.5317,
    longitude: -73.8473,
    postal_code: '12161'
  },
  '12164': {
    country: 'US',
    city: 'Speculator',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.5042,
    longitude: -74.3667,
    postal_code: '12164'
  },
  '12165': {
    country: 'US',
    city: 'Spencertown',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.3091,
    longitude: -73.5008,
    postal_code: '12165'
  },
  '12166': {
    country: 'US',
    city: 'Sprakers',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.8484,
    longitude: -74.4536,
    postal_code: '12166'
  },
  '12167': {
    country: 'US',
    city: 'Stamford',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.4174,
    longitude: -74.6098,
    postal_code: '12167'
  },
  '12168': {
    country: 'US',
    city: 'Stephentown',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.5487,
    longitude: -73.374,
    postal_code: '12168'
  },
  '12169': {
    country: 'US',
    city: 'Stephentown',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.5855,
    longitude: -73.4154,
    postal_code: '12169'
  },
  '12170': {
    country: 'US',
    city: 'Stillwater',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.0019,
    longitude: -73.6609,
    postal_code: '12170'
  },
  '12172': {
    country: 'US',
    city: 'Stottville',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.2857,
    longitude: -73.7335,
    postal_code: '12172'
  },
  '12173': {
    country: 'US',
    city: 'Stuyvesant',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.3596,
    longitude: -73.7613,
    postal_code: '12173'
  },
  '12174': {
    country: 'US',
    city: 'Stuyvesant Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.344,
    longitude: -73.7348,
    postal_code: '12174'
  },
  '12175': {
    country: 'US',
    city: 'Summit',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.535,
    longitude: -74.5452,
    postal_code: '12175'
  },
  '12176': {
    country: 'US',
    city: 'Surprise',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3851,
    longitude: -73.9587,
    postal_code: '12176'
  },
  '12177': {
    country: 'US',
    city: 'Tribes Hill',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9554,
    longitude: -74.2851,
    postal_code: '12177'
  },
  '12180': {
    country: 'US',
    city: 'Troy',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.7287,
    longitude: -73.6683,
    postal_code: '12180'
  },
  '12181': {
    country: 'US',
    city: 'Troy',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.7387,
    longitude: -73.6739,
    postal_code: '12181'
  },
  '12182': {
    country: 'US',
    city: 'Troy',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.7829,
    longitude: -73.6648,
    postal_code: '12182'
  },
  '12183': {
    country: 'US',
    city: 'Troy',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.746,
    longitude: -73.6943,
    postal_code: '12183'
  },
  '12184': {
    country: 'US',
    city: 'Valatie',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.4321,
    longitude: -73.6683,
    postal_code: '12184'
  },
  '12185': {
    country: 'US',
    city: 'Valley Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.8855,
    longitude: -73.5437,
    postal_code: '12185'
  },
  '12186': {
    country: 'US',
    city: 'Voorheesville',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6431,
    longitude: -73.9448,
    postal_code: '12186'
  },
  '12187': {
    country: 'US',
    city: 'Warnerville',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.6604,
    longitude: -74.5074,
    postal_code: '12187'
  },
  '12188': {
    country: 'US',
    city: 'Waterford',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 42.81,
    longitude: -73.6995,
    postal_code: '12188'
  },
  '12189': {
    country: 'US',
    city: 'Watervliet',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.7298,
    longitude: -73.7123,
    postal_code: '12189'
  },
  '12190': {
    country: 'US',
    city: 'Wells',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.4012,
    longitude: -74.2886,
    postal_code: '12190'
  },
  '12192': {
    country: 'US',
    city: 'West Coxsackie',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.4029,
    longitude: -73.8283,
    postal_code: '12192'
  },
  '12193': {
    country: 'US',
    city: 'Westerlo',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.5156,
    longitude: -74.0394,
    postal_code: '12193'
  },
  '12194': {
    country: 'US',
    city: 'West Fulton',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.5506,
    longitude: -74.4631,
    postal_code: '12194'
  },
  '12195': {
    country: 'US',
    city: 'West Lebanon',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.4862,
    longitude: -73.4662,
    postal_code: '12195'
  },
  '12196': {
    country: 'US',
    city: 'West Sand Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.638,
    longitude: -73.6109,
    postal_code: '12196'
  },
  '12197': {
    country: 'US',
    city: 'Worcester',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.6049,
    longitude: -74.7299,
    postal_code: '12197'
  },
  '12198': {
    country: 'US',
    city: 'Wynantskill',
    state: 'New York',
    state_short: 'NY',
    county: 'Rensselaer',
    latitude: 42.6878,
    longitude: -73.6383,
    postal_code: '12198'
  },
  '12201': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12201'
  },
  '12202': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6413,
    longitude: -73.7641,
    postal_code: '12202'
  },
  '12203': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.7003,
    longitude: -73.8575,
    postal_code: '12203'
  },
  '12204': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6847,
    longitude: -73.7354,
    postal_code: '12204'
  },
  '12205': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.7198,
    longitude: -73.8207,
    postal_code: '12205'
  },
  '12206': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6683,
    longitude: -73.7744,
    postal_code: '12206'
  },
  '12207': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12207'
  },
  '12208': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.656,
    longitude: -73.7964,
    postal_code: '12208'
  },
  '12209': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6417,
    longitude: -73.7854,
    postal_code: '12209'
  },
  '12210': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6568,
    longitude: -73.7605,
    postal_code: '12210'
  },
  '12211': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.713,
    longitude: -73.7739,
    postal_code: '12211'
  },
  '12212': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.7168,
    longitude: -73.8104,
    postal_code: '12212'
  },
  '12214': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12214'
  },
  '12220': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12220'
  },
  '12222': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6853,
    longitude: -73.8253,
    postal_code: '12222'
  },
  '12223': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12223'
  },
  '12224': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12224'
  },
  '12225': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12225'
  },
  '12226': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12226'
  },
  '12227': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12227'
  },
  '12228': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12228'
  },
  '12229': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12229'
  },
  '12230': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12230'
  },
  '12231': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12231'
  },
  '12232': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12232'
  },
  '12233': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12233'
  },
  '12234': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12234'
  },
  '12235': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12235'
  },
  '12236': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12236'
  },
  '12237': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12237'
  },
  '12238': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12238'
  },
  '12239': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12239'
  },
  '12240': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12240'
  },
  '12241': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12241'
  },
  '12242': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12242'
  },
  '12243': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12243'
  },
  '12244': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12244'
  },
  '12245': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12245'
  },
  '12246': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6471,
    longitude: -73.7503,
    postal_code: '12246'
  },
  '12247': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12247'
  },
  '12248': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12248'
  },
  '12249': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12249'
  },
  '12250': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12250'
  },
  '12252': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12252'
  },
  '12255': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12255'
  },
  '12256': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12256'
  },
  '12257': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12257'
  },
  '12260': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12260'
  },
  '12261': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12261'
  },
  '12288': {
    country: 'US',
    city: 'Albany',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.6526,
    longitude: -73.7562,
    postal_code: '12288'
  },
  '12301': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.8142,
    longitude: -73.9396,
    postal_code: '12301'
  },
  '12302': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.88,
    longitude: -73.9913,
    postal_code: '12302'
  },
  '12303': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.7823,
    longitude: -73.9448,
    postal_code: '12303'
  },
  '12304': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.7841,
    longitude: -73.9094,
    postal_code: '12304'
  },
  '12305': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.8161,
    longitude: -73.9398,
    postal_code: '12305'
  },
  '12306': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.7904,
    longitude: -73.9809,
    postal_code: '12306'
  },
  '12307': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.8047,
    longitude: -73.9363,
    postal_code: '12307'
  },
  '12308': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.8179,
    longitude: -73.9206,
    postal_code: '12308'
  },
  '12309': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.8091,
    longitude: -73.8693,
    postal_code: '12309'
  },
  '12325': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.8333,
    longitude: -74.058,
    postal_code: '12325'
  },
  '12345': {
    country: 'US',
    city: 'Schenectady',
    state: 'New York',
    state_short: 'NY',
    county: 'Schenectady',
    latitude: 42.8142,
    longitude: -73.9396,
    postal_code: '12345'
  },
  '12401': {
    country: 'US',
    city: 'Kingston',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.9697,
    longitude: -74.0668,
    postal_code: '12401'
  },
  '12402': {
    country: 'US',
    city: 'Kingston',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.927,
    longitude: -73.9974,
    postal_code: '12402'
  },
  '12404': {
    country: 'US',
    city: 'Accord',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8083,
    longitude: -74.2353,
    postal_code: '12404'
  },
  '12405': {
    country: 'US',
    city: 'Acra',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3304,
    longitude: -74.0857,
    postal_code: '12405'
  },
  '12406': {
    country: 'US',
    city: 'Arkville',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.1417,
    longitude: -74.5721,
    postal_code: '12406'
  },
  '12407': {
    country: 'US',
    city: 'Ashland',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3037,
    longitude: -74.3335,
    postal_code: '12407'
  },
  '12409': {
    country: 'US',
    city: 'Bearsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0406,
    longitude: -74.1551,
    postal_code: '12409'
  },
  '12410': {
    country: 'US',
    city: 'Big Indian',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.074,
    longitude: -74.453,
    postal_code: '12410'
  },
  '12411': {
    country: 'US',
    city: 'Bloomington',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8752,
    longitude: -74.0436,
    postal_code: '12411'
  },
  '12412': {
    country: 'US',
    city: 'Boiceville',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0048,
    longitude: -74.2658,
    postal_code: '12412'
  },
  '12413': {
    country: 'US',
    city: 'Cairo',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3096,
    longitude: -74.0115,
    postal_code: '12413'
  },
  '12414': {
    country: 'US',
    city: 'Catskill',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2276,
    longitude: -73.8985,
    postal_code: '12414'
  },
  '12416': {
    country: 'US',
    city: 'Chichester',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.095,
    longitude: -74.2717,
    postal_code: '12416'
  },
  '12417': {
    country: 'US',
    city: 'Connelly',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.9076,
    longitude: -73.9893,
    postal_code: '12417'
  },
  '12418': {
    country: 'US',
    city: 'Cornwallville',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3629,
    longitude: -74.1631,
    postal_code: '12418'
  },
  '12419': {
    country: 'US',
    city: 'Cottekill',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8467,
    longitude: -74.1038,
    postal_code: '12419'
  },
  '12420': {
    country: 'US',
    city: 'Cragsmoor',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.6698,
    longitude: -74.3801,
    postal_code: '12420'
  },
  '12421': {
    country: 'US',
    city: 'Denver',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.2522,
    longitude: -74.5407,
    postal_code: '12421'
  },
  '12422': {
    country: 'US',
    city: 'Durham',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.402,
    longitude: -74.1849,
    postal_code: '12422'
  },
  '12423': {
    country: 'US',
    city: 'East Durham',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.386,
    longitude: -74.1117,
    postal_code: '12423'
  },
  '12424': {
    country: 'US',
    city: 'East Jewett',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2469,
    longitude: -74.1353,
    postal_code: '12424'
  },
  '12427': {
    country: 'US',
    city: 'Elka Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.1643,
    longitude: -74.1245,
    postal_code: '12427'
  },
  '12428': {
    country: 'US',
    city: 'Ellenville',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.7218,
    longitude: -74.4141,
    postal_code: '12428'
  },
  '12429': {
    country: 'US',
    city: 'Esopus',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8279,
    longitude: -73.9651,
    postal_code: '12429'
  },
  '12430': {
    country: 'US',
    city: 'Fleischmanns',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.1772,
    longitude: -74.5473,
    postal_code: '12430'
  },
  '12431': {
    country: 'US',
    city: 'Freehold',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3815,
    longitude: -74.0623,
    postal_code: '12431'
  },
  '12432': {
    country: 'US',
    city: 'Glasco',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0437,
    longitude: -73.9474,
    postal_code: '12432'
  },
  '12433': {
    country: 'US',
    city: 'Glenford',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0053,
    longitude: -74.1532,
    postal_code: '12433'
  },
  '12434': {
    country: 'US',
    city: 'Grand Gorge',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.362,
    longitude: -74.494,
    postal_code: '12434'
  },
  '12435': {
    country: 'US',
    city: 'Greenfield Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.7281,
    longitude: -74.5201,
    postal_code: '12435'
  },
  '12436': {
    country: 'US',
    city: 'Haines Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.1955,
    longitude: -74.1023,
    postal_code: '12436'
  },
  '12438': {
    country: 'US',
    city: 'Halcottsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.2079,
    longitude: -74.6012,
    postal_code: '12438'
  },
  '12439': {
    country: 'US',
    city: 'Hensonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2898,
    longitude: -74.2165,
    postal_code: '12439'
  },
  '12440': {
    country: 'US',
    city: 'High Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8167,
    longitude: -74.1311,
    postal_code: '12440'
  },
  '12441': {
    country: 'US',
    city: 'Highmount',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.1443,
    longitude: -74.4899,
    postal_code: '12441'
  },
  '12442': {
    country: 'US',
    city: 'Hunter',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2333,
    longitude: -74.2416,
    postal_code: '12442'
  },
  '12443': {
    country: 'US',
    city: 'Hurley',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.9327,
    longitude: -74.0687,
    postal_code: '12443'
  },
  '12444': {
    country: 'US',
    city: 'Jewett',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2694,
    longitude: -74.2793,
    postal_code: '12444'
  },
  '12446': {
    country: 'US',
    city: 'Kerhonkson',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.7939,
    longitude: -74.3035,
    postal_code: '12446'
  },
  '12448': {
    country: 'US',
    city: 'Lake Hill',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0733,
    longitude: -74.2123,
    postal_code: '12448'
  },
  '12449': {
    country: 'US',
    city: 'Lake Katrine',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.9918,
    longitude: -73.9924,
    postal_code: '12449'
  },
  '12450': {
    country: 'US',
    city: 'Lanesville',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.1332,
    longitude: -74.2443,
    postal_code: '12450'
  },
  '12451': {
    country: 'US',
    city: 'Leeds',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3045,
    longitude: -73.9457,
    postal_code: '12451'
  },
  '12452': {
    country: 'US',
    city: 'Lexington',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2404,
    longitude: -74.3654,
    postal_code: '12452'
  },
  '12453': {
    country: 'US',
    city: 'Malden On Hudson',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0987,
    longitude: -73.9354,
    postal_code: '12453'
  },
  '12454': {
    country: 'US',
    city: 'Maplecrest',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2995,
    longitude: -74.1655,
    postal_code: '12454'
  },
  '12455': {
    country: 'US',
    city: 'Margaretville',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.1852,
    longitude: -74.6178,
    postal_code: '12455'
  },
  '12456': {
    country: 'US',
    city: 'Mount Marion',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0357,
    longitude: -74.0002,
    postal_code: '12456'
  },
  '12457': {
    country: 'US',
    city: 'Mount Tremper',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0435,
    longitude: -74.2485,
    postal_code: '12457'
  },
  '12458': {
    country: 'US',
    city: 'Napanoch',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.759,
    longitude: -74.3804,
    postal_code: '12458'
  },
  '12459': {
    country: 'US',
    city: 'New Kingston',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.2094,
    longitude: -74.6837,
    postal_code: '12459'
  },
  '12460': {
    country: 'US',
    city: 'Oak Hill',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.4098,
    longitude: -74.1524,
    postal_code: '12460'
  },
  '12461': {
    country: 'US',
    city: 'Olivebridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.875,
    longitude: -74.2734,
    postal_code: '12461'
  },
  '12463': {
    country: 'US',
    city: 'Palenville',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.1729,
    longitude: -74.0167,
    postal_code: '12463'
  },
  '12464': {
    country: 'US',
    city: 'Phoenicia',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0848,
    longitude: -74.3154,
    postal_code: '12464'
  },
  '12465': {
    country: 'US',
    city: 'Pine Hill',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.1331,
    longitude: -74.4802,
    postal_code: '12465'
  },
  '12466': {
    country: 'US',
    city: 'Port Ewen',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8948,
    longitude: -73.9767,
    postal_code: '12466'
  },
  '12468': {
    country: 'US',
    city: 'Prattsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2979,
    longitude: -74.3895,
    postal_code: '12468'
  },
  '12469': {
    country: 'US',
    city: 'Preston Hollow',
    state: 'New York',
    state_short: 'NY',
    county: 'Albany',
    latitude: 42.4563,
    longitude: -74.242,
    postal_code: '12469'
  },
  '12470': {
    country: 'US',
    city: 'Purling',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2959,
    longitude: -74.0773,
    postal_code: '12470'
  },
  '12471': {
    country: 'US',
    city: 'Rifton',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8403,
    longitude: -74.0306,
    postal_code: '12471'
  },
  '12472': {
    country: 'US',
    city: 'Rosendale',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8402,
    longitude: -74.073,
    postal_code: '12472'
  },
  '12473': {
    country: 'US',
    city: 'Round Top',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2678,
    longitude: -74.0523,
    postal_code: '12473'
  },
  '12474': {
    country: 'US',
    city: 'Roxbury',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.2957,
    longitude: -74.5631,
    postal_code: '12474'
  },
  '12475': {
    country: 'US',
    city: 'Ruby',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0176,
    longitude: -74.0079,
    postal_code: '12475'
  },
  '12477': {
    country: 'US',
    city: 'Saugerties',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0738,
    longitude: -73.9797,
    postal_code: '12477'
  },
  '12480': {
    country: 'US',
    city: 'Shandaken',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.1363,
    longitude: -74.3774,
    postal_code: '12480'
  },
  '12481': {
    country: 'US',
    city: 'Shokan',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.9767,
    longitude: -74.2119,
    postal_code: '12481'
  },
  '12482': {
    country: 'US',
    city: 'South Cairo',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2681,
    longitude: -73.9554,
    postal_code: '12482'
  },
  '12483': {
    country: 'US',
    city: 'Spring Glen',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.6639,
    longitude: -74.4245,
    postal_code: '12483'
  },
  '12484': {
    country: 'US',
    city: 'Stone Ridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8616,
    longitude: -74.1697,
    postal_code: '12484'
  },
  '12485': {
    country: 'US',
    city: 'Tannersville',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.1956,
    longitude: -74.1338,
    postal_code: '12485'
  },
  '12486': {
    country: 'US',
    city: 'Tillson',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8338,
    longitude: -74.0626,
    postal_code: '12486'
  },
  '12487': {
    country: 'US',
    city: 'Ulster Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.8651,
    longitude: -73.9948,
    postal_code: '12487'
  },
  '12489': {
    country: 'US',
    city: 'Wawarsing',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.7606,
    longitude: -74.3535,
    postal_code: '12489'
  },
  '12490': {
    country: 'US',
    city: 'West Camp',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.1231,
    longitude: -73.9349,
    postal_code: '12490'
  },
  '12491': {
    country: 'US',
    city: 'West Hurley',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.9973,
    longitude: -74.1049,
    postal_code: '12491'
  },
  '12492': {
    country: 'US',
    city: 'West Kill',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.2046,
    longitude: -74.362,
    postal_code: '12492'
  },
  '12493': {
    country: 'US',
    city: 'West Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.7953,
    longitude: -73.9809,
    postal_code: '12493'
  },
  '12494': {
    country: 'US',
    city: 'West Shokan',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.9673,
    longitude: -74.2871,
    postal_code: '12494'
  },
  '12495': {
    country: 'US',
    city: 'Willow',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0849,
    longitude: -74.2407,
    postal_code: '12495'
  },
  '12496': {
    country: 'US',
    city: 'Windham',
    state: 'New York',
    state_short: 'NY',
    county: 'Greene',
    latitude: 42.3175,
    longitude: -74.262,
    postal_code: '12496'
  },
  '12498': {
    country: 'US',
    city: 'Woodstock',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 42.0348,
    longitude: -74.112,
    postal_code: '12498'
  },
  '12501': {
    country: 'US',
    city: 'Amenia',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.8447,
    longitude: -73.5542,
    postal_code: '12501'
  },
  '12502': {
    country: 'US',
    city: 'Ancram',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.0851,
    longitude: -73.6424,
    postal_code: '12502'
  },
  '12503': {
    country: 'US',
    city: 'Ancramdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.0381,
    longitude: -73.5819,
    postal_code: '12503'
  },
  '12504': {
    country: 'US',
    city: 'Annandale On Hudson',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 42.0129,
    longitude: -73.9082,
    postal_code: '12504'
  },
  '12506': {
    country: 'US',
    city: 'Bangall',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.8756,
    longitude: -73.6912,
    postal_code: '12506'
  },
  '12507': {
    country: 'US',
    city: 'Barrytown',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 42.0005,
    longitude: -73.9199,
    postal_code: '12507'
  },
  '12508': {
    country: 'US',
    city: 'Beacon',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.5097,
    longitude: -73.9634,
    postal_code: '12508'
  },
  '12510': {
    country: 'US',
    city: 'Billings',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.6712,
    longitude: -73.7632,
    postal_code: '12510'
  },
  '12511': {
    country: 'US',
    city: 'Castle Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.5462,
    longitude: -73.9596,
    postal_code: '12511'
  },
  '12512': {
    country: 'US',
    city: 'Chelsea',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.5528,
    longitude: -73.9682,
    postal_code: '12512'
  },
  '12513': {
    country: 'US',
    city: 'Claverack',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.2251,
    longitude: -73.7346,
    postal_code: '12513'
  },
  '12514': {
    country: 'US',
    city: 'Clinton Corners',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.8693,
    longitude: -73.7659,
    postal_code: '12514'
  },
  '12515': {
    country: 'US',
    city: 'Clintondale',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.6749,
    longitude: -74.0557,
    postal_code: '12515'
  },
  '12516': {
    country: 'US',
    city: 'Copake',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.1113,
    longitude: -73.5526,
    postal_code: '12516'
  },
  '12517': {
    country: 'US',
    city: 'Copake Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.1367,
    longitude: -73.5108,
    postal_code: '12517'
  },
  '12518': {
    country: 'US',
    city: 'Cornwall',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4162,
    longitude: -74.0395,
    postal_code: '12518'
  },
  '12520': {
    country: 'US',
    city: 'Cornwall On Hudson',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.433,
    longitude: -74.0061,
    postal_code: '12520'
  },
  '12521': {
    country: 'US',
    city: 'Craryville',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.176,
    longitude: -73.6571,
    postal_code: '12521'
  },
  '12522': {
    country: 'US',
    city: 'Dover Plains',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.7351,
    longitude: -73.587,
    postal_code: '12522'
  },
  '12523': {
    country: 'US',
    city: 'Elizaville',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.0902,
    longitude: -73.7818,
    postal_code: '12523'
  },
  '12524': {
    country: 'US',
    city: 'Fishkill',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.5404,
    longitude: -73.8979,
    postal_code: '12524'
  },
  '12525': {
    country: 'US',
    city: 'Gardiner',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.6576,
    longitude: -74.1672,
    postal_code: '12525'
  },
  '12526': {
    country: 'US',
    city: 'Germantown',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.1219,
    longitude: -73.8625,
    postal_code: '12526'
  },
  '12527': {
    country: 'US',
    city: 'Glenham',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.5202,
    longitude: -73.9333,
    postal_code: '12527'
  },
  '12528': {
    country: 'US',
    city: 'Highland',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.7167,
    longitude: -73.9928,
    postal_code: '12528'
  },
  '12529': {
    country: 'US',
    city: 'Hillsdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.1868,
    longitude: -73.5483,
    postal_code: '12529'
  },
  '12530': {
    country: 'US',
    city: 'Hollowville',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.2054,
    longitude: -73.6912,
    postal_code: '12530'
  },
  '12531': {
    country: 'US',
    city: 'Holmes',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.5325,
    longitude: -73.6628,
    postal_code: '12531'
  },
  '12533': {
    country: 'US',
    city: 'Hopewell Junction',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.5603,
    longitude: -73.7939,
    postal_code: '12533'
  },
  '12534': {
    country: 'US',
    city: 'Hudson',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.247,
    longitude: -73.7552,
    postal_code: '12534'
  },
  '12537': {
    country: 'US',
    city: 'Hughsonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.5824,
    longitude: -73.9363,
    postal_code: '12537'
  },
  '12538': {
    country: 'US',
    city: 'Hyde Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.7887,
    longitude: -73.9063,
    postal_code: '12538'
  },
  '12540': {
    country: 'US',
    city: 'Lagrangeville',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.6615,
    longitude: -73.745,
    postal_code: '12540'
  },
  '12541': {
    country: 'US',
    city: 'Livingston',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.1421,
    longitude: -73.7574,
    postal_code: '12541'
  },
  '12542': {
    country: 'US',
    city: 'Marlboro',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.6056,
    longitude: -73.988,
    postal_code: '12542'
  },
  '12543': {
    country: 'US',
    city: 'Maybrook',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4886,
    longitude: -74.2163,
    postal_code: '12543'
  },
  '12544': {
    country: 'US',
    city: 'Mellenville',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.2596,
    longitude: -73.668,
    postal_code: '12544'
  },
  '12545': {
    country: 'US',
    city: 'Millbrook',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.7803,
    longitude: -73.6885,
    postal_code: '12545'
  },
  '12546': {
    country: 'US',
    city: 'Millerton',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.9536,
    longitude: -73.5287,
    postal_code: '12546'
  },
  '12547': {
    country: 'US',
    city: 'Milton',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.6535,
    longitude: -73.9772,
    postal_code: '12547'
  },
  '12548': {
    country: 'US',
    city: 'Modena',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.6503,
    longitude: -74.1036,
    postal_code: '12548'
  },
  '12549': {
    country: 'US',
    city: 'Montgomery',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.5333,
    longitude: -74.2534,
    postal_code: '12549'
  },
  '12550': {
    country: 'US',
    city: 'Newburgh',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.5372,
    longitude: -74.0526,
    postal_code: '12550'
  },
  '12551': {
    country: 'US',
    city: 'Newburgh',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.5034,
    longitude: -74.0104,
    postal_code: '12551'
  },
  '12552': {
    country: 'US',
    city: 'Newburgh',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.5034,
    longitude: -74.0104,
    postal_code: '12552'
  },
  '12553': {
    country: 'US',
    city: 'New Windsor',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4724,
    longitude: -74.0566,
    postal_code: '12553'
  },
  '12555': {
    country: 'US',
    city: 'Newburgh',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.5034,
    longitude: -74.0104,
    postal_code: '12555'
  },
  '12561': {
    country: 'US',
    city: 'New Paltz',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.7464,
    longitude: -74.1092,
    postal_code: '12561'
  },
  '12563': {
    country: 'US',
    city: 'Patterson',
    state: 'New York',
    state_short: 'NY',
    county: 'Putnam',
    latitude: 41.4888,
    longitude: -73.5815,
    postal_code: '12563'
  },
  '12564': {
    country: 'US',
    city: 'Pawling',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.5749,
    longitude: -73.5948,
    postal_code: '12564'
  },
  '12565': {
    country: 'US',
    city: 'Philmont',
    state: 'New York',
    state_short: 'NY',
    county: 'Columbia',
    latitude: 42.2485,
    longitude: -73.6463,
    postal_code: '12565'
  },
  '12566': {
    country: 'US',
    city: 'Pine Bush',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.6178,
    longitude: -74.3263,
    postal_code: '12566'
  },
  '12567': {
    country: 'US',
    city: 'Pine Plains',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.9896,
    longitude: -73.6602,
    postal_code: '12567'
  },
  '12568': {
    country: 'US',
    city: 'Plattekill',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.6412,
    longitude: -74.0781,
    postal_code: '12568'
  },
  '12569': {
    country: 'US',
    city: 'Pleasant Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.747,
    longitude: -73.8143,
    postal_code: '12569'
  },
  '12570': {
    country: 'US',
    city: 'Poughquag',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.6194,
    longitude: -73.6783,
    postal_code: '12570'
  },
  '12571': {
    country: 'US',
    city: 'Red Hook',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 42.0064,
    longitude: -73.8546,
    postal_code: '12571'
  },
  '12572': {
    country: 'US',
    city: 'Rhinebeck',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.9272,
    longitude: -73.8888,
    postal_code: '12572'
  },
  '12574': {
    country: 'US',
    city: 'Rhinecliff',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.9151,
    longitude: -73.9517,
    postal_code: '12574'
  },
  '12575': {
    country: 'US',
    city: 'Rock Tavern',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4575,
    longitude: -74.1659,
    postal_code: '12575'
  },
  '12577': {
    country: 'US',
    city: 'Salisbury Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4497,
    longitude: -74.1214,
    postal_code: '12577'
  },
  '12578': {
    country: 'US',
    city: 'Salt Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.805,
    longitude: -73.8013,
    postal_code: '12578'
  },
  '12580': {
    country: 'US',
    city: 'Staatsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.8502,
    longitude: -73.8988,
    postal_code: '12580'
  },
  '12581': {
    country: 'US',
    city: 'Stanfordville',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.8877,
    longitude: -73.6945,
    postal_code: '12581'
  },
  '12582': {
    country: 'US',
    city: 'Stormville',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.5512,
    longitude: -73.7255,
    postal_code: '12582'
  },
  '12583': {
    country: 'US',
    city: 'Tivoli',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 42.0579,
    longitude: -73.9025,
    postal_code: '12583'
  },
  '12584': {
    country: 'US',
    city: 'Vails Gate',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4641,
    longitude: -74.0591,
    postal_code: '12584'
  },
  '12585': {
    country: 'US',
    city: 'Verbank',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.7227,
    longitude: -73.7184,
    postal_code: '12585'
  },
  '12586': {
    country: 'US',
    city: 'Walden',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.5596,
    longitude: -74.1764,
    postal_code: '12586'
  },
  '12588': {
    country: 'US',
    city: 'Walker Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.6337,
    longitude: -74.3779,
    postal_code: '12588'
  },
  '12589': {
    country: 'US',
    city: 'Wallkill',
    state: 'New York',
    state_short: 'NY',
    county: 'Ulster',
    latitude: 41.616,
    longitude: -74.1439,
    postal_code: '12589'
  },
  '12590': {
    country: 'US',
    city: 'Wappingers Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.595,
    longitude: -73.8876,
    postal_code: '12590'
  },
  '12592': {
    country: 'US',
    city: 'Wassaic',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.7759,
    longitude: -73.5544,
    postal_code: '12592'
  },
  '12594': {
    country: 'US',
    city: 'Wingdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.6538,
    longitude: -73.5556,
    postal_code: '12594'
  },
  '12601': {
    country: 'US',
    city: 'Poughkeepsie',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.7035,
    longitude: -73.9117,
    postal_code: '12601'
  },
  '12602': {
    country: 'US',
    city: 'Poughkeepsie',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.7599,
    longitude: -73.7437,
    postal_code: '12602'
  },
  '12603': {
    country: 'US',
    city: 'Poughkeepsie',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.6907,
    longitude: -73.8621,
    postal_code: '12603'
  },
  '12604': {
    country: 'US',
    city: 'Poughkeepsie',
    state: 'New York',
    state_short: 'NY',
    county: 'Dutchess',
    latitude: 41.7599,
    longitude: -73.7437,
    postal_code: '12604'
  },
  '12701': {
    country: 'US',
    city: 'Monticello',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6516,
    longitude: -74.7007,
    postal_code: '12701'
  },
  '12719': {
    country: 'US',
    city: 'Barryville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.4912,
    longitude: -74.9152,
    postal_code: '12719'
  },
  '12720': {
    country: 'US',
    city: 'Bethel',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6693,
    longitude: -74.894,
    postal_code: '12720'
  },
  '12721': {
    country: 'US',
    city: 'Bloomingburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.5644,
    longitude: -74.4304,
    postal_code: '12721'
  },
  '12722': {
    country: 'US',
    city: 'Burlingham',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.5901,
    longitude: -74.3821,
    postal_code: '12722'
  },
  '12723': {
    country: 'US',
    city: 'Callicoon',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7673,
    longitude: -75.0563,
    postal_code: '12723'
  },
  '12724': {
    country: 'US',
    city: 'Callicoon Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8368,
    longitude: -74.9466,
    postal_code: '12724'
  },
  '12725': {
    country: 'US',
    city: 'Claryville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.9657,
    longitude: -74.5293,
    postal_code: '12725'
  },
  '12726': {
    country: 'US',
    city: 'Cochecton',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.692,
    longitude: -74.9741,
    postal_code: '12726'
  },
  '12727': {
    country: 'US',
    city: 'Cochecton Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6548,
    longitude: -74.9827,
    postal_code: '12727'
  },
  '12729': {
    country: 'US',
    city: 'Cuddebackville',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4776,
    longitude: -74.5976,
    postal_code: '12729'
  },
  '12732': {
    country: 'US',
    city: 'Eldred',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.5328,
    longitude: -74.8968,
    postal_code: '12732'
  },
  '12733': {
    country: 'US',
    city: 'Fallsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7273,
    longitude: -74.6154,
    postal_code: '12733'
  },
  '12734': {
    country: 'US',
    city: 'Ferndale',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7349,
    longitude: -74.7345,
    postal_code: '12734'
  },
  '12736': {
    country: 'US',
    city: 'Fremont Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8782,
    longitude: -75.0343,
    postal_code: '12736'
  },
  '12737': {
    country: 'US',
    city: 'Glen Spey',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.4858,
    longitude: -74.7995,
    postal_code: '12737'
  },
  '12738': {
    country: 'US',
    city: 'Glen Wild',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6545,
    longitude: -74.5833,
    postal_code: '12738'
  },
  '12740': {
    country: 'US',
    city: 'Grahamsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8807,
    longitude: -74.5127,
    postal_code: '12740'
  },
  '12741': {
    country: 'US',
    city: 'Hankins',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8391,
    longitude: -75.0534,
    postal_code: '12741'
  },
  '12742': {
    country: 'US',
    city: 'Harris',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7143,
    longitude: -74.7263,
    postal_code: '12742'
  },
  '12743': {
    country: 'US',
    city: 'Highland Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.5309,
    longitude: -74.8516,
    postal_code: '12743'
  },
  '12745': {
    country: 'US',
    city: 'Hortonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7629,
    longitude: -75.0306,
    postal_code: '12745'
  },
  '12746': {
    country: 'US',
    city: 'Huguenot',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4179,
    longitude: -74.631,
    postal_code: '12746'
  },
  '12747': {
    country: 'US',
    city: 'Hurleyville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7356,
    longitude: -74.6743,
    postal_code: '12747'
  },
  '12748': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7784,
    longitude: -74.9196,
    postal_code: '12748'
  },
  '12749': {
    country: 'US',
    city: 'Kauneonga Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6874,
    longitude: -74.8358,
    postal_code: '12749'
  },
  '12750': {
    country: 'US',
    city: 'Kenoza Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7296,
    longitude: -74.9611,
    postal_code: '12750'
  },
  '12751': {
    country: 'US',
    city: 'Kiamesha Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6838,
    longitude: -74.6724,
    postal_code: '12751'
  },
  '12752': {
    country: 'US',
    city: 'Lake Huntington',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6782,
    longitude: -74.9949,
    postal_code: '12752'
  },
  '12754': {
    country: 'US',
    city: 'Liberty',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7962,
    longitude: -74.7484,
    postal_code: '12754'
  },
  '12758': {
    country: 'US',
    city: 'Livingston Manor',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8778,
    longitude: -74.827,
    postal_code: '12758'
  },
  '12759': {
    country: 'US',
    city: 'Loch Sheldrake',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7789,
    longitude: -74.6614,
    postal_code: '12759'
  },
  '12760': {
    country: 'US',
    city: 'Long Eddy',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8644,
    longitude: -75.0942,
    postal_code: '12760'
  },
  '12762': {
    country: 'US',
    city: 'Mongaup Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.681,
    longitude: -74.8028,
    postal_code: '12762'
  },
  '12763': {
    country: 'US',
    city: 'Mountain Dale',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6918,
    longitude: -74.5358,
    postal_code: '12763'
  },
  '12764': {
    country: 'US',
    city: 'Narrowsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.5921,
    longitude: -75.0107,
    postal_code: '12764'
  },
  '12765': {
    country: 'US',
    city: 'Neversink',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8492,
    longitude: -74.6127,
    postal_code: '12765'
  },
  '12766': {
    country: 'US',
    city: 'North Branch',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8142,
    longitude: -74.9824,
    postal_code: '12766'
  },
  '12767': {
    country: 'US',
    city: 'Obernburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8448,
    longitude: -75.0071,
    postal_code: '12767'
  },
  '12768': {
    country: 'US',
    city: 'Parksville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8517,
    longitude: -74.7359,
    postal_code: '12768'
  },
  '12769': {
    country: 'US',
    city: 'Phillipsport',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6515,
    longitude: -74.4362,
    postal_code: '12769'
  },
  '12770': {
    country: 'US',
    city: 'Pond Eddy',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.4511,
    longitude: -74.841,
    postal_code: '12770'
  },
  '12771': {
    country: 'US',
    city: 'Port Jervis',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.3786,
    longitude: -74.6691,
    postal_code: '12771'
  },
  '12775': {
    country: 'US',
    city: 'Rock Hill',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6134,
    longitude: -74.5872,
    postal_code: '12775'
  },
  '12776': {
    country: 'US',
    city: 'Roscoe',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.9609,
    longitude: -74.9346,
    postal_code: '12776'
  },
  '12777': {
    country: 'US',
    city: 'Forestburgh',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.5691,
    longitude: -74.7241,
    postal_code: '12777'
  },
  '12778': {
    country: 'US',
    city: 'Smallwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6615,
    longitude: -74.8178,
    postal_code: '12778'
  },
  '12779': {
    country: 'US',
    city: 'South Fallsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7042,
    longitude: -74.6444,
    postal_code: '12779'
  },
  '12780': {
    country: 'US',
    city: 'Sparrow Bush',
    state: 'New York',
    state_short: 'NY',
    county: 'Orange',
    latitude: 41.4443,
    longitude: -74.7213,
    postal_code: '12780'
  },
  '12781': {
    country: 'US',
    city: 'Summitville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6215,
    longitude: -74.4507,
    postal_code: '12781'
  },
  '12783': {
    country: 'US',
    city: 'Swan Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7285,
    longitude: -74.8341,
    postal_code: '12783'
  },
  '12784': {
    country: 'US',
    city: 'Thompsonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6681,
    longitude: -74.6252,
    postal_code: '12784'
  },
  '12785': {
    country: 'US',
    city: 'Westbrookville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.4987,
    longitude: -74.5585,
    postal_code: '12785'
  },
  '12786': {
    country: 'US',
    city: 'White Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.6485,
    longitude: -74.8654,
    postal_code: '12786'
  },
  '12787': {
    country: 'US',
    city: 'White Sulphur Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8002,
    longitude: -74.8286,
    postal_code: '12787'
  },
  '12788': {
    country: 'US',
    city: 'Woodbourne',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.7708,
    longitude: -74.5928,
    postal_code: '12788'
  },
  '12789': {
    country: 'US',
    city: 'Woodridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.717,
    longitude: -74.5815,
    postal_code: '12789'
  },
  '12790': {
    country: 'US',
    city: 'Wurtsboro',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.5877,
    longitude: -74.5039,
    postal_code: '12790'
  },
  '12791': {
    country: 'US',
    city: 'Youngsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.8032,
    longitude: -74.8888,
    postal_code: '12791'
  },
  '12792': {
    country: 'US',
    city: 'Yulan',
    state: 'New York',
    state_short: 'NY',
    county: 'Sullivan',
    latitude: 41.5226,
    longitude: -74.9329,
    postal_code: '12792'
  },
  '12801': {
    country: 'US',
    city: 'Glens Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.3115,
    longitude: -73.6448,
    postal_code: '12801'
  },
  '12803': {
    country: 'US',
    city: 'South Glens Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.2836,
    longitude: -73.6294,
    postal_code: '12803'
  },
  '12804': {
    country: 'US',
    city: 'Queensbury',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.329,
    longitude: -73.6818,
    postal_code: '12804'
  },
  '12808': {
    country: 'US',
    city: 'Adirondack',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.7165,
    longitude: -73.7825,
    postal_code: '12808'
  },
  '12809': {
    country: 'US',
    city: 'Argyle',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.2381,
    longitude: -73.4641,
    postal_code: '12809'
  },
  '12810': {
    country: 'US',
    city: 'Athol',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.4839,
    longitude: -73.8817,
    postal_code: '12810'
  },
  '12811': {
    country: 'US',
    city: 'Bakers Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.6148,
    longitude: -74.0249,
    postal_code: '12811'
  },
  '12812': {
    country: 'US',
    city: 'Blue Mountain Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.8553,
    longitude: -74.4435,
    postal_code: '12812'
  },
  '12814': {
    country: 'US',
    city: 'Bolton Landing',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.5766,
    longitude: -73.6714,
    postal_code: '12814'
  },
  '12815': {
    country: 'US',
    city: 'Brant Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.6989,
    longitude: -73.7205,
    postal_code: '12815'
  },
  '12816': {
    country: 'US',
    city: 'Cambridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.0466,
    longitude: -73.3814,
    postal_code: '12816'
  },
  '12817': {
    country: 'US',
    city: 'Chestertown',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.6451,
    longitude: -73.8066,
    postal_code: '12817'
  },
  '12819': {
    country: 'US',
    city: 'Clemons',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.6435,
    longitude: -73.4326,
    postal_code: '12819'
  },
  '12820': {
    country: 'US',
    city: 'Cleverdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.4718,
    longitude: -73.6393,
    postal_code: '12820'
  },
  '12821': {
    country: 'US',
    city: 'Comstock',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.4614,
    longitude: -73.4033,
    postal_code: '12821'
  },
  '12822': {
    country: 'US',
    city: 'Corinth',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.2426,
    longitude: -73.8369,
    postal_code: '12822'
  },
  '12823': {
    country: 'US',
    city: 'Cossayuna',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.1837,
    longitude: -73.4268,
    postal_code: '12823'
  },
  '12824': {
    country: 'US',
    city: 'Diamond Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.5156,
    longitude: -73.7001,
    postal_code: '12824'
  },
  '12827': {
    country: 'US',
    city: 'Fort Ann',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.4285,
    longitude: -73.4784,
    postal_code: '12827'
  },
  '12828': {
    country: 'US',
    city: 'Fort Edward',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.2653,
    longitude: -73.5822,
    postal_code: '12828'
  },
  '12831': {
    country: 'US',
    city: 'Gansevoort',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.1803,
    longitude: -73.7053,
    postal_code: '12831'
  },
  '12832': {
    country: 'US',
    city: 'Granville',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.3776,
    longitude: -73.2978,
    postal_code: '12832'
  },
  '12833': {
    country: 'US',
    city: 'Greenfield Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.1401,
    longitude: -73.8398,
    postal_code: '12833'
  },
  '12834': {
    country: 'US',
    city: 'Greenwich',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.0947,
    longitude: -73.503,
    postal_code: '12834'
  },
  '12835': {
    country: 'US',
    city: 'Hadley',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.3173,
    longitude: -73.8482,
    postal_code: '12835'
  },
  '12836': {
    country: 'US',
    city: 'Hague',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.7463,
    longitude: -73.5282,
    postal_code: '12836'
  },
  '12837': {
    country: 'US',
    city: 'Hampton',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.5248,
    longitude: -73.2518,
    postal_code: '12837'
  },
  '12838': {
    country: 'US',
    city: 'Hartford',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.3493,
    longitude: -73.4049,
    postal_code: '12838'
  },
  '12839': {
    country: 'US',
    city: 'Hudson Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.3149,
    longitude: -73.5746,
    postal_code: '12839'
  },
  '12841': {
    country: 'US',
    city: 'Huletts Landing',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.6392,
    longitude: -73.5071,
    postal_code: '12841'
  },
  '12842': {
    country: 'US',
    city: 'Indian Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.7606,
    longitude: -74.2766,
    postal_code: '12842'
  },
  '12843': {
    country: 'US',
    city: 'Johnsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.586,
    longitude: -73.9165,
    postal_code: '12843'
  },
  '12844': {
    country: 'US',
    city: 'Kattskill Bay',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.4754,
    longitude: -73.6272,
    postal_code: '12844'
  },
  '12845': {
    country: 'US',
    city: 'Lake George',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.4167,
    longitude: -73.6975,
    postal_code: '12845'
  },
  '12846': {
    country: 'US',
    city: 'Lake Luzerne',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.3165,
    longitude: -73.8228,
    postal_code: '12846'
  },
  '12847': {
    country: 'US',
    city: 'Long Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.9477,
    longitude: -74.4662,
    postal_code: '12847'
  },
  '12848': {
    country: 'US',
    city: 'Middle Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.1001,
    longitude: -73.5246,
    postal_code: '12848'
  },
  '12849': {
    country: 'US',
    city: 'Middle Granville',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.4508,
    longitude: -73.3031,
    postal_code: '12849'
  },
  '12850': {
    country: 'US',
    city: 'Middle Grove',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.0975,
    longitude: -74.0167,
    postal_code: '12850'
  },
  '12851': {
    country: 'US',
    city: 'Minerva',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 43.7811,
    longitude: -73.9835,
    postal_code: '12851'
  },
  '12852': {
    country: 'US',
    city: 'Newcomb',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 43.946,
    longitude: -74.1299,
    postal_code: '12852'
  },
  '12853': {
    country: 'US',
    city: 'North Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.6978,
    longitude: -73.986,
    postal_code: '12853'
  },
  '12854': {
    country: 'US',
    city: 'North Granville',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.4525,
    longitude: -73.341,
    postal_code: '12854'
  },
  '12855': {
    country: 'US',
    city: 'North Hudson',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 43.9869,
    longitude: -73.7121,
    postal_code: '12855'
  },
  '12856': {
    country: 'US',
    city: 'North River',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.7237,
    longitude: -74.0873,
    postal_code: '12856'
  },
  '12857': {
    country: 'US',
    city: 'Olmstedville',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 43.7799,
    longitude: -73.9335,
    postal_code: '12857'
  },
  '12858': {
    country: 'US',
    city: 'Paradox',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 43.8914,
    longitude: -73.645,
    postal_code: '12858'
  },
  '12859': {
    country: 'US',
    city: 'Porter Corners',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.1724,
    longitude: -73.8839,
    postal_code: '12859'
  },
  '12860': {
    country: 'US',
    city: 'Pottersville',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.7312,
    longitude: -73.8193,
    postal_code: '12860'
  },
  '12861': {
    country: 'US',
    city: 'Putnam Station',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.756,
    longitude: -73.4123,
    postal_code: '12861'
  },
  '12862': {
    country: 'US',
    city: 'Riparius',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.6617,
    longitude: -73.8971,
    postal_code: '12862'
  },
  '12863': {
    country: 'US',
    city: 'Rock City Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.0662,
    longitude: -73.9215,
    postal_code: '12863'
  },
  '12864': {
    country: 'US',
    city: 'Sabael',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.7284,
    longitude: -74.3057,
    postal_code: '12864'
  },
  '12865': {
    country: 'US',
    city: 'Salem',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.1828,
    longitude: -73.3327,
    postal_code: '12865'
  },
  '12866': {
    country: 'US',
    city: 'Saratoga Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.0708,
    longitude: -73.7408,
    postal_code: '12866'
  },
  '12870': {
    country: 'US',
    city: 'Schroon Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 43.8412,
    longitude: -73.7674,
    postal_code: '12870'
  },
  '12871': {
    country: 'US',
    city: 'Schuylerville',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.0878,
    longitude: -73.6007,
    postal_code: '12871'
  },
  '12872': {
    country: 'US',
    city: 'Severance',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 43.8759,
    longitude: -73.7304,
    postal_code: '12872'
  },
  '12873': {
    country: 'US',
    city: 'Shushan',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.1106,
    longitude: -73.3231,
    postal_code: '12873'
  },
  '12874': {
    country: 'US',
    city: 'Silver Bay',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.6978,
    longitude: -73.5071,
    postal_code: '12874'
  },
  '12878': {
    country: 'US',
    city: 'Stony Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.4214,
    longitude: -73.9495,
    postal_code: '12878'
  },
  '12879': {
    country: 'US',
    city: 'Newcomb',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 43.9697,
    longitude: -74.1662,
    postal_code: '12879'
  },
  '12883': {
    country: 'US',
    city: 'Ticonderoga',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 43.8463,
    longitude: -73.4426,
    postal_code: '12883'
  },
  '12884': {
    country: 'US',
    city: 'Victory Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Saratoga',
    latitude: 43.0884,
    longitude: -73.5917,
    postal_code: '12884'
  },
  '12885': {
    country: 'US',
    city: 'Warrensburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.5003,
    longitude: -73.792,
    postal_code: '12885'
  },
  '12886': {
    country: 'US',
    city: 'Wevertown',
    state: 'New York',
    state_short: 'NY',
    county: 'Warren',
    latitude: 43.6313,
    longitude: -73.9364,
    postal_code: '12886'
  },
  '12887': {
    country: 'US',
    city: 'Whitehall',
    state: 'New York',
    state_short: 'NY',
    county: 'Washington',
    latitude: 43.5531,
    longitude: -73.3864,
    postal_code: '12887'
  },
  '12901': {
    country: 'US',
    city: 'Plattsburgh',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.6927,
    longitude: -73.466,
    postal_code: '12901'
  },
  '12903': {
    country: 'US',
    city: 'Plattsburgh',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.6854,
    longitude: -73.4474,
    postal_code: '12903'
  },
  '12910': {
    country: 'US',
    city: 'Altona',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.8816,
    longitude: -73.6408,
    postal_code: '12910'
  },
  '12911': {
    country: 'US',
    city: 'Keeseville',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.505,
    longitude: -73.4801,
    postal_code: '12911'
  },
  '12912': {
    country: 'US',
    city: 'Au Sable Forks',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.4499,
    longitude: -73.6857,
    postal_code: '12912'
  },
  '12913': {
    country: 'US',
    city: 'Bloomingdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.3985,
    longitude: -74.0829,
    postal_code: '12913'
  },
  '12914': {
    country: 'US',
    city: 'Bombay',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.9479,
    longitude: -74.5947,
    postal_code: '12914'
  },
  '12915': {
    country: 'US',
    city: 'Brainardsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.8578,
    longitude: -74.0335,
    postal_code: '12915'
  },
  '12916': {
    country: 'US',
    city: 'Brushton',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.8282,
    longitude: -74.5223,
    postal_code: '12916'
  },
  '12917': {
    country: 'US',
    city: 'Burke',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.9177,
    longitude: -74.1731,
    postal_code: '12917'
  },
  '12918': {
    country: 'US',
    city: 'Cadyville',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.6862,
    longitude: -73.6702,
    postal_code: '12918'
  },
  '12919': {
    country: 'US',
    city: 'Champlain',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.9773,
    longitude: -73.4466,
    postal_code: '12919'
  },
  '12920': {
    country: 'US',
    city: 'Chateaugay',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.9088,
    longitude: -74.0741,
    postal_code: '12920'
  },
  '12921': {
    country: 'US',
    city: 'Chazy',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.8884,
    longitude: -73.4501,
    postal_code: '12921'
  },
  '12922': {
    country: 'US',
    city: 'Childwold',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.2867,
    longitude: -74.6759,
    postal_code: '12922'
  },
  '12923': {
    country: 'US',
    city: 'Churubusco',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.9432,
    longitude: -73.9355,
    postal_code: '12923'
  },
  '12924': {
    country: 'US',
    city: 'Keeseville',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.4777,
    longitude: -73.5843,
    postal_code: '12924'
  },
  '12926': {
    country: 'US',
    city: 'Constable',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.9417,
    longitude: -74.3297,
    postal_code: '12926'
  },
  '12927': {
    country: 'US',
    city: 'Cranberry Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.229,
    longitude: -74.8581,
    postal_code: '12927'
  },
  '12928': {
    country: 'US',
    city: 'Crown Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 43.9526,
    longitude: -73.4665,
    postal_code: '12928'
  },
  '12929': {
    country: 'US',
    city: 'Dannemora',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.72,
    longitude: -73.7192,
    postal_code: '12929'
  },
  '12930': {
    country: 'US',
    city: 'Dickinson Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.7233,
    longitude: -74.5523,
    postal_code: '12930'
  },
  '12932': {
    country: 'US',
    city: 'Elizabethtown',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.2245,
    longitude: -73.6011,
    postal_code: '12932'
  },
  '12933': {
    country: 'US',
    city: 'Ellenburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.8939,
    longitude: -73.8365,
    postal_code: '12933'
  },
  '12934': {
    country: 'US',
    city: 'Ellenburg Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.8444,
    longitude: -73.8685,
    postal_code: '12934'
  },
  '12935': {
    country: 'US',
    city: 'Ellenburg Depot',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.9163,
    longitude: -73.7876,
    postal_code: '12935'
  },
  '12936': {
    country: 'US',
    city: 'Essex',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.2807,
    longitude: -73.3731,
    postal_code: '12936'
  },
  '12937': {
    country: 'US',
    city: 'Fort Covington',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.9731,
    longitude: -74.4929,
    postal_code: '12937'
  },
  '12939': {
    country: 'US',
    city: 'Gabriels',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.432,
    longitude: -74.181,
    postal_code: '12939'
  },
  '12941': {
    country: 'US',
    city: 'Jay',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.3734,
    longitude: -73.7247,
    postal_code: '12941'
  },
  '12942': {
    country: 'US',
    city: 'Keene',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.2555,
    longitude: -73.7915,
    postal_code: '12942'
  },
  '12943': {
    country: 'US',
    city: 'Keene Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.2024,
    longitude: -73.7731,
    postal_code: '12943'
  },
  '12944': {
    country: 'US',
    city: 'Keeseville',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.4999,
    longitude: -73.4745,
    postal_code: '12944'
  },
  '12945': {
    country: 'US',
    city: 'Lake Clear',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.3375,
    longitude: -74.2381,
    postal_code: '12945'
  },
  '12946': {
    country: 'US',
    city: 'Lake Placid',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.2796,
    longitude: -73.982,
    postal_code: '12946'
  },
  '12949': {
    country: 'US',
    city: 'Lawrenceville',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.7469,
    longitude: -74.6604,
    postal_code: '12949'
  },
  '12950': {
    country: 'US',
    city: 'Lewis',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.3075,
    longitude: -73.5491,
    postal_code: '12950'
  },
  '12952': {
    country: 'US',
    city: 'Lyon Mountain',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.7255,
    longitude: -73.9195,
    postal_code: '12952'
  },
  '12953': {
    country: 'US',
    city: 'Malone',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.8482,
    longitude: -74.2928,
    postal_code: '12953'
  },
  '12955': {
    country: 'US',
    city: 'Lyon Mountain',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.8043,
    longitude: -73.973,
    postal_code: '12955'
  },
  '12956': {
    country: 'US',
    city: 'Mineville',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.0876,
    longitude: -73.5236,
    postal_code: '12956'
  },
  '12957': {
    country: 'US',
    city: 'Moira',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.8504,
    longitude: -74.5603,
    postal_code: '12957'
  },
  '12958': {
    country: 'US',
    city: 'Mooers',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.9592,
    longitude: -73.5834,
    postal_code: '12958'
  },
  '12959': {
    country: 'US',
    city: 'Mooers Forks',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.9602,
    longitude: -73.673,
    postal_code: '12959'
  },
  '12960': {
    country: 'US',
    city: 'Moriah',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.0438,
    longitude: -73.5079,
    postal_code: '12960'
  },
  '12961': {
    country: 'US',
    city: 'Moriah Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.0612,
    longitude: -73.5098,
    postal_code: '12961'
  },
  '12962': {
    country: 'US',
    city: 'Morrisonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.6894,
    longitude: -73.5772,
    postal_code: '12962'
  },
  '12964': {
    country: 'US',
    city: 'New Russia',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.1595,
    longitude: -73.6059,
    postal_code: '12964'
  },
  '12965': {
    country: 'US',
    city: 'Nicholville',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.703,
    longitude: -74.6805,
    postal_code: '12965'
  },
  '12966': {
    country: 'US',
    city: 'North Bangor',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.8532,
    longitude: -74.4191,
    postal_code: '12966'
  },
  '12967': {
    country: 'US',
    city: 'North Lawrence',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.775,
    longitude: -74.6653,
    postal_code: '12967'
  },
  '12969': {
    country: 'US',
    city: 'Owls Head',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.7308,
    longitude: -74.1342,
    postal_code: '12969'
  },
  '12970': {
    country: 'US',
    city: 'Paul Smiths',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.445,
    longitude: -74.2664,
    postal_code: '12970'
  },
  '12972': {
    country: 'US',
    city: 'Peru',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.5851,
    longitude: -73.5293,
    postal_code: '12972'
  },
  '12973': {
    country: 'US',
    city: 'Piercefield',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.2343,
    longitude: -74.556,
    postal_code: '12973'
  },
  '12974': {
    country: 'US',
    city: 'Port Henry',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.0465,
    longitude: -73.4705,
    postal_code: '12974'
  },
  '12975': {
    country: 'US',
    city: 'Port Kent',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.5264,
    longitude: -73.4092,
    postal_code: '12975'
  },
  '12976': {
    country: 'US',
    city: 'Rainbow Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.467,
    longitude: -74.1729,
    postal_code: '12976'
  },
  '12977': {
    country: 'US',
    city: 'Ray Brook',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.2802,
    longitude: -74.0795,
    postal_code: '12977'
  },
  '12978': {
    country: 'US',
    city: 'Redford',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.6164,
    longitude: -73.8087,
    postal_code: '12978'
  },
  '12979': {
    country: 'US',
    city: 'Rouses Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.9884,
    longitude: -73.3691,
    postal_code: '12979'
  },
  '12980': {
    country: 'US',
    city: 'Saint Regis Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.6578,
    longitude: -74.5155,
    postal_code: '12980'
  },
  '12981': {
    country: 'US',
    city: 'Saranac',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.7032,
    longitude: -73.7481,
    postal_code: '12981'
  },
  '12983': {
    country: 'US',
    city: 'Saranac Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.3243,
    longitude: -74.133,
    postal_code: '12983'
  },
  '12985': {
    country: 'US',
    city: 'Schuyler Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.6287,
    longitude: -73.5579,
    postal_code: '12985'
  },
  '12986': {
    country: 'US',
    city: 'Tupper Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.232,
    longitude: -74.4905,
    postal_code: '12986'
  },
  '12987': {
    country: 'US',
    city: 'Upper Jay',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.3364,
    longitude: -73.7757,
    postal_code: '12987'
  },
  '12989': {
    country: 'US',
    city: 'Vermontville',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.4601,
    longitude: -74.0573,
    postal_code: '12989'
  },
  '12992': {
    country: 'US',
    city: 'West Chazy',
    state: 'New York',
    state_short: 'NY',
    county: 'Clinton',
    latitude: 44.797,
    longitude: -73.5112,
    postal_code: '12992'
  },
  '12993': {
    country: 'US',
    city: 'Westport',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.205,
    longitude: -73.4702,
    postal_code: '12993'
  },
  '12995': {
    country: 'US',
    city: 'Whippleville',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.8057,
    longitude: -74.2523,
    postal_code: '12995'
  },
  '12996': {
    country: 'US',
    city: 'Willsboro',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.3604,
    longitude: -73.3963,
    postal_code: '12996'
  },
  '12997': {
    country: 'US',
    city: 'Wilmington',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.3755,
    longitude: -73.8431,
    postal_code: '12997'
  },
  '12998': {
    country: 'US',
    city: 'Witherbee',
    state: 'New York',
    state_short: 'NY',
    county: 'Essex',
    latitude: 44.0827,
    longitude: -73.5306,
    postal_code: '12998'
  },
  '13020': {
    country: 'US',
    city: 'Apulia Station',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.8187,
    longitude: -76.0724,
    postal_code: '13020'
  },
  '13021': {
    country: 'US',
    city: 'Auburn',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.93,
    longitude: -76.5626,
    postal_code: '13021'
  },
  '13022': {
    country: 'US',
    city: 'Auburn',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.9317,
    longitude: -76.5661,
    postal_code: '13022'
  },
  '13024': {
    country: 'US',
    city: 'Auburn',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.9317,
    longitude: -76.5661,
    postal_code: '13024'
  },
  '13026': {
    country: 'US',
    city: 'Aurora',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.7472,
    longitude: -76.6775,
    postal_code: '13026'
  },
  '13027': {
    country: 'US',
    city: 'Baldwinsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.162,
    longitude: -76.3237,
    postal_code: '13027'
  },
  '13028': {
    country: 'US',
    city: 'Bernhards Bay',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.2717,
    longitude: -75.9373,
    postal_code: '13028'
  },
  '13029': {
    country: 'US',
    city: 'Brewerton',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.2252,
    longitude: -76.1351,
    postal_code: '13029'
  },
  '13030': {
    country: 'US',
    city: 'Bridgeport',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.159,
    longitude: -75.97,
    postal_code: '13030'
  },
  '13031': {
    country: 'US',
    city: 'Camillus',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0417,
    longitude: -76.2807,
    postal_code: '13031'
  },
  '13032': {
    country: 'US',
    city: 'Canastota',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 43.0878,
    longitude: -75.7602,
    postal_code: '13032'
  },
  '13033': {
    country: 'US',
    city: 'Cato',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 43.1794,
    longitude: -76.5648,
    postal_code: '13033'
  },
  '13034': {
    country: 'US',
    city: 'Cayuga',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.9142,
    longitude: -76.7024,
    postal_code: '13034'
  },
  '13035': {
    country: 'US',
    city: 'Cazenovia',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.938,
    longitude: -75.8392,
    postal_code: '13035'
  },
  '13036': {
    country: 'US',
    city: 'Central Square',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.309,
    longitude: -76.1849,
    postal_code: '13036'
  },
  '13037': {
    country: 'US',
    city: 'Chittenango',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 43.0552,
    longitude: -75.8768,
    postal_code: '13037'
  },
  '13039': {
    country: 'US',
    city: 'Cicero',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.1707,
    longitude: -76.0962,
    postal_code: '13039'
  },
  '13040': {
    country: 'US',
    city: 'Cincinnatus',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.5385,
    longitude: -75.903,
    postal_code: '13040'
  },
  '13041': {
    country: 'US',
    city: 'Clay',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.1737,
    longitude: -76.1707,
    postal_code: '13041'
  },
  '13042': {
    country: 'US',
    city: 'Cleveland',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.2432,
    longitude: -75.8537,
    postal_code: '13042'
  },
  '13043': {
    country: 'US',
    city: 'Clockville',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 43.042,
    longitude: -75.7408,
    postal_code: '13043'
  },
  '13044': {
    country: 'US',
    city: 'Constantia',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.2728,
    longitude: -76.0042,
    postal_code: '13044'
  },
  '13045': {
    country: 'US',
    city: 'Cortland',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.5952,
    longitude: -76.1857,
    postal_code: '13045'
  },
  '13051': {
    country: 'US',
    city: 'Delphi Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.8765,
    longitude: -75.9135,
    postal_code: '13051'
  },
  '13052': {
    country: 'US',
    city: 'De Ruyter',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.7494,
    longitude: -75.8582,
    postal_code: '13052'
  },
  '13053': {
    country: 'US',
    city: 'Dryden',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.4861,
    longitude: -76.2872,
    postal_code: '13053'
  },
  '13054': {
    country: 'US',
    city: 'Durhamville',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1579,
    longitude: -75.6714,
    postal_code: '13054'
  },
  '13056': {
    country: 'US',
    city: 'East Homer',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.6772,
    longitude: -76.1052,
    postal_code: '13056'
  },
  '13057': {
    country: 'US',
    city: 'East Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0734,
    longitude: -76.0558,
    postal_code: '13057'
  },
  '13060': {
    country: 'US',
    city: 'Elbridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0252,
    longitude: -76.4352,
    postal_code: '13060'
  },
  '13061': {
    country: 'US',
    city: 'Erieville',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.8562,
    longitude: -75.7543,
    postal_code: '13061'
  },
  '13062': {
    country: 'US',
    city: 'Etna',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.4851,
    longitude: -76.3835,
    postal_code: '13062'
  },
  '13063': {
    country: 'US',
    city: 'Fabius',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.8531,
    longitude: -75.9836,
    postal_code: '13063'
  },
  '13064': {
    country: 'US',
    city: 'Fair Haven',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.4313,
    longitude: -76.2004,
    postal_code: '13064'
  },
  '13065': {
    country: 'US',
    city: 'Fayette',
    state: 'New York',
    state_short: 'NY',
    county: 'Seneca',
    latitude: 42.8227,
    longitude: -76.802,
    postal_code: '13065'
  },
  '13066': {
    country: 'US',
    city: 'Fayetteville',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0268,
    longitude: -76.0145,
    postal_code: '13066'
  },
  '13068': {
    country: 'US',
    city: 'Freeville',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.4998,
    longitude: -76.3636,
    postal_code: '13068'
  },
  '13069': {
    country: 'US',
    city: 'Fulton',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.3211,
    longitude: -76.4034,
    postal_code: '13069'
  },
  '13071': {
    country: 'US',
    city: 'Genoa',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.6746,
    longitude: -76.5418,
    postal_code: '13071'
  },
  '13072': {
    country: 'US',
    city: 'Georgetown',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.7631,
    longitude: -75.7443,
    postal_code: '13072'
  },
  '13073': {
    country: 'US',
    city: 'Groton',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.5855,
    longitude: -76.3633,
    postal_code: '13073'
  },
  '13074': {
    country: 'US',
    city: 'Hannibal',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.3111,
    longitude: -76.546,
    postal_code: '13074'
  },
  '13076': {
    country: 'US',
    city: 'Hastings',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.3527,
    longitude: -76.1477,
    postal_code: '13076'
  },
  '13077': {
    country: 'US',
    city: 'Homer',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.6726,
    longitude: -76.1878,
    postal_code: '13077'
  },
  '13078': {
    country: 'US',
    city: 'Jamesville',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.983,
    longitude: -76.0766,
    postal_code: '13078'
  },
  '13080': {
    country: 'US',
    city: 'Jordan',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0651,
    longitude: -76.4598,
    postal_code: '13080'
  },
  '13081': {
    country: 'US',
    city: 'King Ferry',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.6635,
    longitude: -76.6216,
    postal_code: '13081'
  },
  '13082': {
    country: 'US',
    city: 'Kirkville',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0981,
    longitude: -75.955,
    postal_code: '13082'
  },
  '13083': {
    country: 'US',
    city: 'Lacona',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.6429,
    longitude: -76.0503,
    postal_code: '13083'
  },
  '13084': {
    country: 'US',
    city: 'La Fayette',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.891,
    longitude: -76.1061,
    postal_code: '13084'
  },
  '13087': {
    country: 'US',
    city: 'Little York',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.707,
    longitude: -76.1561,
    postal_code: '13087'
  },
  '13088': {
    country: 'US',
    city: 'Liverpool',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.1099,
    longitude: -76.187,
    postal_code: '13088'
  },
  '13089': {
    country: 'US',
    city: 'Liverpool',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0214,
    longitude: -76.1977,
    postal_code: '13089'
  },
  '13090': {
    country: 'US',
    city: 'Liverpool',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.1528,
    longitude: -76.2235,
    postal_code: '13090'
  },
  '13092': {
    country: 'US',
    city: 'Locke',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.6558,
    longitude: -76.4154,
    postal_code: '13092'
  },
  '13093': {
    country: 'US',
    city: 'Lycoming',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.4987,
    longitude: -76.3858,
    postal_code: '13093'
  },
  '13101': {
    country: 'US',
    city: 'Mc Graw',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.5948,
    longitude: -76.082,
    postal_code: '13101'
  },
  '13102': {
    country: 'US',
    city: 'Mc Lean',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.5542,
    longitude: -76.2927,
    postal_code: '13102'
  },
  '13103': {
    country: 'US',
    city: 'Mallory',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.3237,
    longitude: -76.1166,
    postal_code: '13103'
  },
  '13104': {
    country: 'US',
    city: 'Manlius',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.9904,
    longitude: -75.9703,
    postal_code: '13104'
  },
  '13107': {
    country: 'US',
    city: 'Maple View',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.4578,
    longitude: -76.1534,
    postal_code: '13107'
  },
  '13108': {
    country: 'US',
    city: 'Marcellus',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.9821,
    longitude: -76.3323,
    postal_code: '13108'
  },
  '13110': {
    country: 'US',
    city: 'Marietta',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.8974,
    longitude: -76.2806,
    postal_code: '13110'
  },
  '13111': {
    country: 'US',
    city: 'Martville',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 43.2661,
    longitude: -76.6289,
    postal_code: '13111'
  },
  '13112': {
    country: 'US',
    city: 'Memphis',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0934,
    longitude: -76.403,
    postal_code: '13112'
  },
  '13113': {
    country: 'US',
    city: 'Meridian',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 43.1656,
    longitude: -76.5369,
    postal_code: '13113'
  },
  '13114': {
    country: 'US',
    city: 'Mexico',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.4605,
    longitude: -76.2446,
    postal_code: '13114'
  },
  '13115': {
    country: 'US',
    city: 'Minetto',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.3977,
    longitude: -76.4824,
    postal_code: '13115'
  },
  '13116': {
    country: 'US',
    city: 'Minoa',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0772,
    longitude: -76.0098,
    postal_code: '13116'
  },
  '13117': {
    country: 'US',
    city: 'Montezuma',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 43.0101,
    longitude: -76.7033,
    postal_code: '13117'
  },
  '13118': {
    country: 'US',
    city: 'Moravia',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.7355,
    longitude: -76.399,
    postal_code: '13118'
  },
  '13119': {
    country: 'US',
    city: 'Mottville',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.9745,
    longitude: -76.4408,
    postal_code: '13119'
  },
  '13120': {
    country: 'US',
    city: 'Nedrow',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.9559,
    longitude: -76.1529,
    postal_code: '13120'
  },
  '13121': {
    country: 'US',
    city: 'New Haven',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.4834,
    longitude: -76.315,
    postal_code: '13121'
  },
  '13122': {
    country: 'US',
    city: 'New Woodstock',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.8441,
    longitude: -75.8635,
    postal_code: '13122'
  },
  '13123': {
    country: 'US',
    city: 'North Bay',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.2364,
    longitude: -75.7769,
    postal_code: '13123'
  },
  '13124': {
    country: 'US',
    city: 'North Pitcher',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.6372,
    longitude: -75.8164,
    postal_code: '13124'
  },
  '13126': {
    country: 'US',
    city: 'Oswego',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.4394,
    longitude: -76.4613,
    postal_code: '13126'
  },
  '13131': {
    country: 'US',
    city: 'Parish',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.4153,
    longitude: -76.1,
    postal_code: '13131'
  },
  '13132': {
    country: 'US',
    city: 'Pennellville',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.2609,
    longitude: -76.2395,
    postal_code: '13132'
  },
  '13134': {
    country: 'US',
    city: 'Peterboro',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.9686,
    longitude: -75.6794,
    postal_code: '13134'
  },
  '13135': {
    country: 'US',
    city: 'Phoenix',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.2468,
    longitude: -76.3064,
    postal_code: '13135'
  },
  '13136': {
    country: 'US',
    city: 'Pitcher',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.5969,
    longitude: -75.8465,
    postal_code: '13136'
  },
  '13137': {
    country: 'US',
    city: 'Plainville',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.1577,
    longitude: -76.447,
    postal_code: '13137'
  },
  '13138': {
    country: 'US',
    city: 'Pompey',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.899,
    longitude: -76.016,
    postal_code: '13138'
  },
  '13139': {
    country: 'US',
    city: 'Poplar Ridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.7421,
    longitude: -76.6285,
    postal_code: '13139'
  },
  '13140': {
    country: 'US',
    city: 'Port Byron',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 43.0427,
    longitude: -76.6449,
    postal_code: '13140'
  },
  '13141': {
    country: 'US',
    city: 'Preble',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.795,
    longitude: -76.2141,
    postal_code: '13141'
  },
  '13142': {
    country: 'US',
    city: 'Pulaski',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.5562,
    longitude: -76.1252,
    postal_code: '13142'
  },
  '13143': {
    country: 'US',
    city: 'Red Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2291,
    longitude: -76.7146,
    postal_code: '13143'
  },
  '13144': {
    country: 'US',
    city: 'Richland',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.5776,
    longitude: -76.0029,
    postal_code: '13144'
  },
  '13145': {
    country: 'US',
    city: 'Sandy Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.6517,
    longitude: -76.1264,
    postal_code: '13145'
  },
  '13146': {
    country: 'US',
    city: 'Savannah',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.0934,
    longitude: -76.7565,
    postal_code: '13146'
  },
  '13147': {
    country: 'US',
    city: 'Scipio Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.7708,
    longitude: -76.5862,
    postal_code: '13147'
  },
  '13148': {
    country: 'US',
    city: 'Seneca Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Seneca',
    latitude: 42.9094,
    longitude: -76.7925,
    postal_code: '13148'
  },
  '13152': {
    country: 'US',
    city: 'Skaneateles',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.9258,
    longitude: -76.4052,
    postal_code: '13152'
  },
  '13153': {
    country: 'US',
    city: 'Skaneateles Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.9931,
    longitude: -76.456,
    postal_code: '13153'
  },
  '13154': {
    country: 'US',
    city: 'South Butler',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.1344,
    longitude: -76.7656,
    postal_code: '13154'
  },
  '13155': {
    country: 'US',
    city: 'South Otselic',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.6626,
    longitude: -75.7669,
    postal_code: '13155'
  },
  '13156': {
    country: 'US',
    city: 'Sterling',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 43.3296,
    longitude: -76.6747,
    postal_code: '13156'
  },
  '13157': {
    country: 'US',
    city: 'Sylvan Beach',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1965,
    longitude: -75.7305,
    postal_code: '13157'
  },
  '13158': {
    country: 'US',
    city: 'Truxton',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.7085,
    longitude: -76.0189,
    postal_code: '13158'
  },
  '13159': {
    country: 'US',
    city: 'Tully',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.807,
    longitude: -76.1394,
    postal_code: '13159'
  },
  '13160': {
    country: 'US',
    city: 'Union Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 42.8335,
    longitude: -76.674,
    postal_code: '13160'
  },
  '13162': {
    country: 'US',
    city: 'Verona Beach',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1885,
    longitude: -75.7126,
    postal_code: '13162'
  },
  '13163': {
    country: 'US',
    city: 'Wampsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 43.0753,
    longitude: -75.7069,
    postal_code: '13163'
  },
  '13164': {
    country: 'US',
    city: 'Warners',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0932,
    longitude: -76.2904,
    postal_code: '13164'
  },
  '13165': {
    country: 'US',
    city: 'Waterloo',
    state: 'New York',
    state_short: 'NY',
    county: 'Seneca',
    latitude: 42.9045,
    longitude: -76.8755,
    postal_code: '13165'
  },
  '13166': {
    country: 'US',
    city: 'Weedsport',
    state: 'New York',
    state_short: 'NY',
    county: 'Cayuga',
    latitude: 43.0489,
    longitude: -76.5425,
    postal_code: '13166'
  },
  '13167': {
    country: 'US',
    city: 'West Monroe',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.2882,
    longitude: -76.0797,
    postal_code: '13167'
  },
  '13201': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0481,
    longitude: -76.1474,
    postal_code: '13201'
  },
  '13202': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.041,
    longitude: -76.1489,
    postal_code: '13202'
  },
  '13203': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0607,
    longitude: -76.1369,
    postal_code: '13203'
  },
  '13204': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0444,
    longitude: -76.1758,
    postal_code: '13204'
  },
  '13205': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0123,
    longitude: -76.1452,
    postal_code: '13205'
  },
  '13206': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0677,
    longitude: -76.1102,
    postal_code: '13206'
  },
  '13207': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0195,
    longitude: -76.165,
    postal_code: '13207'
  },
  '13208': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.073,
    longitude: -76.1486,
    postal_code: '13208'
  },
  '13209': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0847,
    longitude: -76.2405,
    postal_code: '13209'
  },
  '13210': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0354,
    longitude: -76.1282,
    postal_code: '13210'
  },
  '13211': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.1036,
    longitude: -76.1195,
    postal_code: '13211'
  },
  '13212': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.1226,
    longitude: -76.1284,
    postal_code: '13212'
  },
  '13214': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0397,
    longitude: -76.0722,
    postal_code: '13214'
  },
  '13215': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 42.9722,
    longitude: -76.2276,
    postal_code: '13215'
  },
  '13217': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0214,
    longitude: -76.1977,
    postal_code: '13217'
  },
  '13218': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0214,
    longitude: -76.1977,
    postal_code: '13218'
  },
  '13219': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0409,
    longitude: -76.2262,
    postal_code: '13219'
  },
  '13220': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.1234,
    longitude: -76.1282,
    postal_code: '13220'
  },
  '13221': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0481,
    longitude: -76.1474,
    postal_code: '13221'
  },
  '13224': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0421,
    longitude: -76.1046,
    postal_code: '13224'
  },
  '13225': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0214,
    longitude: -76.1977,
    postal_code: '13225'
  },
  '13235': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0559,
    longitude: -76.1526,
    postal_code: '13235'
  },
  '13244': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0377,
    longitude: -76.1396,
    postal_code: '13244'
  },
  '13250': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0214,
    longitude: -76.1977,
    postal_code: '13250'
  },
  '13251': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0214,
    longitude: -76.1977,
    postal_code: '13251'
  },
  '13252': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.051,
    longitude: -76.1567,
    postal_code: '13252'
  },
  '13261': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0481,
    longitude: -76.1474,
    postal_code: '13261'
  },
  '13290': {
    country: 'US',
    city: 'Syracuse',
    state: 'New York',
    state_short: 'NY',
    county: 'Onondaga',
    latitude: 43.0676,
    longitude: -76.1714,
    postal_code: '13290'
  },
  '13301': {
    country: 'US',
    city: 'Alder Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.4157,
    longitude: -75.2137,
    postal_code: '13301'
  },
  '13302': {
    country: 'US',
    city: 'Altmar',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.497,
    longitude: -75.9719,
    postal_code: '13302'
  },
  '13303': {
    country: 'US',
    city: 'Ava',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.3445,
    longitude: -75.4509,
    postal_code: '13303'
  },
  '13304': {
    country: 'US',
    city: 'Barneveld',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.2237,
    longitude: -75.1612,
    postal_code: '13304'
  },
  '13305': {
    country: 'US',
    city: 'Beaver Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.887,
    longitude: -75.4274,
    postal_code: '13305'
  },
  '13308': {
    country: 'US',
    city: 'Blossvale',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.2303,
    longitude: -75.6873,
    postal_code: '13308'
  },
  '13309': {
    country: 'US',
    city: 'Boonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.4786,
    longitude: -75.344,
    postal_code: '13309'
  },
  '13310': {
    country: 'US',
    city: 'Bouckville',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.894,
    longitude: -75.5678,
    postal_code: '13310'
  },
  '13312': {
    country: 'US',
    city: 'Brantingham',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.6887,
    longitude: -75.2921,
    postal_code: '13312'
  },
  '13313': {
    country: 'US',
    city: 'Bridgewater',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 42.8792,
    longitude: -75.2672,
    postal_code: '13313'
  },
  '13314': {
    country: 'US',
    city: 'Brookfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.8128,
    longitude: -75.3177,
    postal_code: '13314'
  },
  '13315': {
    country: 'US',
    city: 'Burlington Flats',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.7516,
    longitude: -75.169,
    postal_code: '13315'
  },
  '13316': {
    country: 'US',
    city: 'Camden',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.3392,
    longitude: -75.7543,
    postal_code: '13316'
  },
  '13317': {
    country: 'US',
    city: 'Canajoharie',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.8671,
    longitude: -74.5956,
    postal_code: '13317'
  },
  '13318': {
    country: 'US',
    city: 'Cassville',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 42.9069,
    longitude: -75.2607,
    postal_code: '13318'
  },
  '13319': {
    country: 'US',
    city: 'Chadwicks',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0226,
    longitude: -75.2656,
    postal_code: '13319'
  },
  '13320': {
    country: 'US',
    city: 'Cherry Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.7823,
    longitude: -74.7444,
    postal_code: '13320'
  },
  '13321': {
    country: 'US',
    city: 'Clark Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0923,
    longitude: -75.3796,
    postal_code: '13321'
  },
  '13322': {
    country: 'US',
    city: 'Clayville',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 42.9801,
    longitude: -75.251,
    postal_code: '13322'
  },
  '13323': {
    country: 'US',
    city: 'Clinton',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0586,
    longitude: -75.3808,
    postal_code: '13323'
  },
  '13324': {
    country: 'US',
    city: 'Cold Brook',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.3024,
    longitude: -74.9977,
    postal_code: '13324'
  },
  '13325': {
    country: 'US',
    city: 'Constableville',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.563,
    longitude: -75.4584,
    postal_code: '13325'
  },
  '13326': {
    country: 'US',
    city: 'Cooperstown',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.7005,
    longitude: -74.9243,
    postal_code: '13326'
  },
  '13327': {
    country: 'US',
    city: 'Croghan',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.9095,
    longitude: -75.3542,
    postal_code: '13327'
  },
  '13328': {
    country: 'US',
    city: 'Deansboro',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 42.9818,
    longitude: -75.4383,
    postal_code: '13328'
  },
  '13329': {
    country: 'US',
    city: 'Dolgeville',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.1042,
    longitude: -74.7643,
    postal_code: '13329'
  },
  '13331': {
    country: 'US',
    city: 'Eagle Bay',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.8167,
    longitude: -74.8862,
    postal_code: '13331'
  },
  '13332': {
    country: 'US',
    city: 'Earlville',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.7197,
    longitude: -75.5589,
    postal_code: '13332'
  },
  '13333': {
    country: 'US',
    city: 'East Springfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.8336,
    longitude: -74.8233,
    postal_code: '13333'
  },
  '13334': {
    country: 'US',
    city: 'Eaton',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.8484,
    longitude: -75.6314,
    postal_code: '13334'
  },
  '13335': {
    country: 'US',
    city: 'Edmeston',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.6979,
    longitude: -75.2438,
    postal_code: '13335'
  },
  '13337': {
    country: 'US',
    city: 'Fly Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.7252,
    longitude: -74.9869,
    postal_code: '13337'
  },
  '13338': {
    country: 'US',
    city: 'Forestport',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.4737,
    longitude: -75.1787,
    postal_code: '13338'
  },
  '13339': {
    country: 'US',
    city: 'Fort Plain',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9372,
    longitude: -74.6433,
    postal_code: '13339'
  },
  '13340': {
    country: 'US',
    city: 'Frankfort',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.044,
    longitude: -75.1072,
    postal_code: '13340'
  },
  '13341': {
    country: 'US',
    city: 'Franklin Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0361,
    longitude: -75.3962,
    postal_code: '13341'
  },
  '13342': {
    country: 'US',
    city: 'Garrattsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.6315,
    longitude: -75.1866,
    postal_code: '13342'
  },
  '13343': {
    country: 'US',
    city: 'Glenfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.7323,
    longitude: -75.3669,
    postal_code: '13343'
  },
  '13345': {
    country: 'US',
    city: 'Greig',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.6886,
    longitude: -75.3302,
    postal_code: '13345'
  },
  '13346': {
    country: 'US',
    city: 'Hamilton',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.8231,
    longitude: -75.5434,
    postal_code: '13346'
  },
  '13348': {
    country: 'US',
    city: 'Hartwick',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.695,
    longitude: -75.055,
    postal_code: '13348'
  },
  '13350': {
    country: 'US',
    city: 'Herkimer',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.0307,
    longitude: -74.9876,
    postal_code: '13350'
  },
  '13352': {
    country: 'US',
    city: 'Hinckley',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.3126,
    longitude: -75.1169,
    postal_code: '13352'
  },
  '13353': {
    country: 'US',
    city: 'Hoffmeister',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.3915,
    longitude: -74.74,
    postal_code: '13353'
  },
  '13354': {
    country: 'US',
    city: 'Holland Patent',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.2484,
    longitude: -75.2535,
    postal_code: '13354'
  },
  '13355': {
    country: 'US',
    city: 'Hubbardsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.8237,
    longitude: -75.4367,
    postal_code: '13355'
  },
  '13357': {
    country: 'US',
    city: 'Ilion',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.0064,
    longitude: -75.0484,
    postal_code: '13357'
  },
  '13360': {
    country: 'US',
    city: 'Inlet',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.748,
    longitude: -74.7846,
    postal_code: '13360'
  },
  '13361': {
    country: 'US',
    city: 'Jordanville',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 42.9148,
    longitude: -74.9515,
    postal_code: '13361'
  },
  '13362': {
    country: 'US',
    city: 'Knoxboro',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 42.9803,
    longitude: -75.5186,
    postal_code: '13362'
  },
  '13363': {
    country: 'US',
    city: 'Lee Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.3148,
    longitude: -75.5055,
    postal_code: '13363'
  },
  '13364': {
    country: 'US',
    city: 'Leonardsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.8087,
    longitude: -75.2527,
    postal_code: '13364'
  },
  '13365': {
    country: 'US',
    city: 'Little Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.0474,
    longitude: -74.8606,
    postal_code: '13365'
  },
  '13367': {
    country: 'US',
    city: 'Lowville',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.7893,
    longitude: -75.4156,
    postal_code: '13367'
  },
  '13368': {
    country: 'US',
    city: 'Lyons Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.6262,
    longitude: -75.3553,
    postal_code: '13368'
  },
  '13401': {
    country: 'US',
    city: 'Mc Connellsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.2672,
    longitude: -75.6882,
    postal_code: '13401'
  },
  '13402': {
    country: 'US',
    city: 'Madison',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.8969,
    longitude: -75.5076,
    postal_code: '13402'
  },
  '13403': {
    country: 'US',
    city: 'Marcy',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1639,
    longitude: -75.2783,
    postal_code: '13403'
  },
  '13404': {
    country: 'US',
    city: 'Martinsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.7376,
    longitude: -75.4696,
    postal_code: '13404'
  },
  '13406': {
    country: 'US',
    city: 'Middleville',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.1369,
    longitude: -74.924,
    postal_code: '13406'
  },
  '13407': {
    country: 'US',
    city: 'Mohawk',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 42.99,
    longitude: -74.9853,
    postal_code: '13407'
  },
  '13408': {
    country: 'US',
    city: 'Morrisville',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.9108,
    longitude: -75.6487,
    postal_code: '13408'
  },
  '13409': {
    country: 'US',
    city: 'Munnsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.9863,
    longitude: -75.594,
    postal_code: '13409'
  },
  '13410': {
    country: 'US',
    city: 'Nelliston',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9379,
    longitude: -74.6117,
    postal_code: '13410'
  },
  '13411': {
    country: 'US',
    city: 'New Berlin',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.6224,
    longitude: -75.3474,
    postal_code: '13411'
  },
  '13413': {
    country: 'US',
    city: 'New Hartford',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0654,
    longitude: -75.2906,
    postal_code: '13413'
  },
  '13415': {
    country: 'US',
    city: 'New Lisbon',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.5904,
    longitude: -75.1957,
    postal_code: '13415'
  },
  '13416': {
    country: 'US',
    city: 'Newport',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.18,
    longitude: -74.9861,
    postal_code: '13416'
  },
  '13417': {
    country: 'US',
    city: 'New York Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1,
    longitude: -75.2937,
    postal_code: '13417'
  },
  '13418': {
    country: 'US',
    city: 'North Brookfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.8501,
    longitude: -75.3815,
    postal_code: '13418'
  },
  '13420': {
    country: 'US',
    city: 'Old Forge',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.7435,
    longitude: -74.8935,
    postal_code: '13420'
  },
  '13421': {
    country: 'US',
    city: 'Oneida',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 43.0862,
    longitude: -75.6508,
    postal_code: '13421'
  },
  '13424': {
    country: 'US',
    city: 'Oriskany',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1524,
    longitude: -75.3434,
    postal_code: '13424'
  },
  '13425': {
    country: 'US',
    city: 'Oriskany Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 42.9576,
    longitude: -75.4838,
    postal_code: '13425'
  },
  '13426': {
    country: 'US',
    city: 'Orwell',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.5628,
    longitude: -75.9968,
    postal_code: '13426'
  },
  '13428': {
    country: 'US',
    city: 'Palatine Bridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 42.9221,
    longitude: -74.5708,
    postal_code: '13428'
  },
  '13431': {
    country: 'US',
    city: 'Poland',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.2115,
    longitude: -75.0731,
    postal_code: '13431'
  },
  '13433': {
    country: 'US',
    city: 'Port Leyden',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.5802,
    longitude: -75.3263,
    postal_code: '13433'
  },
  '13435': {
    country: 'US',
    city: 'Prospect',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.3052,
    longitude: -75.1502,
    postal_code: '13435'
  },
  '13436': {
    country: 'US',
    city: 'Raquette Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Hamilton',
    latitude: 43.8131,
    longitude: -74.6574,
    postal_code: '13436'
  },
  '13437': {
    country: 'US',
    city: 'Redfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.5658,
    longitude: -75.8242,
    postal_code: '13437'
  },
  '13438': {
    country: 'US',
    city: 'Remsen',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.3385,
    longitude: -75.1616,
    postal_code: '13438'
  },
  '13439': {
    country: 'US',
    city: 'Richfield Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.8402,
    longitude: -74.9716,
    postal_code: '13439'
  },
  '13440': {
    country: 'US',
    city: 'Rome',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.2193,
    longitude: -75.4498,
    postal_code: '13440'
  },
  '13441': {
    country: 'US',
    city: 'Rome',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.2264,
    longitude: -75.4083,
    postal_code: '13441'
  },
  '13442': {
    country: 'US',
    city: 'Rome',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.2128,
    longitude: -75.4557,
    postal_code: '13442'
  },
  '13449': {
    country: 'US',
    city: 'Rome',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.2393,
    longitude: -75.478,
    postal_code: '13449'
  },
  '13450': {
    country: 'US',
    city: 'Roseboom',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.708,
    longitude: -74.8025,
    postal_code: '13450'
  },
  '13452': {
    country: 'US',
    city: 'Saint Johnsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Montgomery',
    latitude: 43.017,
    longitude: -74.646,
    postal_code: '13452'
  },
  '13454': {
    country: 'US',
    city: 'Salisbury Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.1625,
    longitude: -74.7809,
    postal_code: '13454'
  },
  '13455': {
    country: 'US',
    city: 'Sangerfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 42.9162,
    longitude: -75.3545,
    postal_code: '13455'
  },
  '13456': {
    country: 'US',
    city: 'Sauquoit',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0073,
    longitude: -75.2626,
    postal_code: '13456'
  },
  '13457': {
    country: 'US',
    city: 'Schuyler Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.7758,
    longitude: -75.0485,
    postal_code: '13457'
  },
  '13459': {
    country: 'US',
    city: 'Sharon Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Schoharie',
    latitude: 42.7634,
    longitude: -74.5919,
    postal_code: '13459'
  },
  '13460': {
    country: 'US',
    city: 'Sherburne',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.6859,
    longitude: -75.483,
    postal_code: '13460'
  },
  '13461': {
    country: 'US',
    city: 'Sherrill',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0704,
    longitude: -75.599,
    postal_code: '13461'
  },
  '13464': {
    country: 'US',
    city: 'Smyrna',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.6896,
    longitude: -75.6121,
    postal_code: '13464'
  },
  '13465': {
    country: 'US',
    city: 'Solsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.9106,
    longitude: -75.5177,
    postal_code: '13465'
  },
  '13468': {
    country: 'US',
    city: 'Springfield Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.8388,
    longitude: -74.859,
    postal_code: '13468'
  },
  '13469': {
    country: 'US',
    city: 'Stittville',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.2229,
    longitude: -75.2899,
    postal_code: '13469'
  },
  '13470': {
    country: 'US',
    city: 'Stratford',
    state: 'New York',
    state_short: 'NY',
    county: 'Fulton',
    latitude: 43.1791,
    longitude: -74.6768,
    postal_code: '13470'
  },
  '13471': {
    country: 'US',
    city: 'Taberg',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.3366,
    longitude: -75.6027,
    postal_code: '13471'
  },
  '13472': {
    country: 'US',
    city: 'Thendara',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 43.7001,
    longitude: -75.0018,
    postal_code: '13472'
  },
  '13473': {
    country: 'US',
    city: 'Turin',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.6441,
    longitude: -75.4132,
    postal_code: '13473'
  },
  '13475': {
    country: 'US',
    city: 'Van Hornesville',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 42.8951,
    longitude: -74.8276,
    postal_code: '13475'
  },
  '13476': {
    country: 'US',
    city: 'Vernon',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0945,
    longitude: -75.5627,
    postal_code: '13476'
  },
  '13477': {
    country: 'US',
    city: 'Vernon Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0443,
    longitude: -75.521,
    postal_code: '13477'
  },
  '13478': {
    country: 'US',
    city: 'Verona',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1473,
    longitude: -75.5724,
    postal_code: '13478'
  },
  '13479': {
    country: 'US',
    city: 'Washington Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0538,
    longitude: -75.2716,
    postal_code: '13479'
  },
  '13480': {
    country: 'US',
    city: 'Waterville',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 42.9332,
    longitude: -75.3815,
    postal_code: '13480'
  },
  '13482': {
    country: 'US',
    city: 'West Burlington',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.7043,
    longitude: -75.1849,
    postal_code: '13482'
  },
  '13483': {
    country: 'US',
    city: 'Westdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.4117,
    longitude: -75.8226,
    postal_code: '13483'
  },
  '13484': {
    country: 'US',
    city: 'West Eaton',
    state: 'New York',
    state_short: 'NY',
    county: 'Madison',
    latitude: 42.8545,
    longitude: -75.656,
    postal_code: '13484'
  },
  '13485': {
    country: 'US',
    city: 'West Edmeston',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.7868,
    longitude: -75.3149,
    postal_code: '13485'
  },
  '13486': {
    country: 'US',
    city: 'Westernville',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.3294,
    longitude: -75.3151,
    postal_code: '13486'
  },
  '13488': {
    country: 'US',
    city: 'Westford',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.6809,
    longitude: -74.7653,
    postal_code: '13488'
  },
  '13489': {
    country: 'US',
    city: 'West Leyden',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.4597,
    longitude: -75.5127,
    postal_code: '13489'
  },
  '13490': {
    country: 'US',
    city: 'Westmoreland',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1017,
    longitude: -75.4533,
    postal_code: '13490'
  },
  '13491': {
    country: 'US',
    city: 'West Winfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Herkimer',
    latitude: 42.8826,
    longitude: -75.1835,
    postal_code: '13491'
  },
  '13492': {
    country: 'US',
    city: 'Whitesboro',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1158,
    longitude: -75.3095,
    postal_code: '13492'
  },
  '13493': {
    country: 'US',
    city: 'Williamstown',
    state: 'New York',
    state_short: 'NY',
    county: 'Oswego',
    latitude: 43.4106,
    longitude: -75.9044,
    postal_code: '13493'
  },
  '13494': {
    country: 'US',
    city: 'Woodgate',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.5249,
    longitude: -75.1428,
    postal_code: '13494'
  },
  '13495': {
    country: 'US',
    city: 'Yorkville',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1116,
    longitude: -75.2756,
    postal_code: '13495'
  },
  '13501': {
    country: 'US',
    city: 'Utica',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.0871,
    longitude: -75.2315,
    postal_code: '13501'
  },
  '13502': {
    country: 'US',
    city: 'Utica',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1067,
    longitude: -75.2314,
    postal_code: '13502'
  },
  '13503': {
    country: 'US',
    city: 'Utica',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1019,
    longitude: -75.2312,
    postal_code: '13503'
  },
  '13504': {
    country: 'US',
    city: 'Utica',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1009,
    longitude: -75.2327,
    postal_code: '13504'
  },
  '13505': {
    country: 'US',
    city: 'Utica',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1009,
    longitude: -75.2327,
    postal_code: '13505'
  },
  '13599': {
    country: 'US',
    city: 'Utica',
    state: 'New York',
    state_short: 'NY',
    county: 'Oneida',
    latitude: 43.1009,
    longitude: -75.2327,
    postal_code: '13599'
  },
  '13601': {
    country: 'US',
    city: 'Watertown',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.9743,
    longitude: -75.9122,
    postal_code: '13601'
  },
  '13602': {
    country: 'US',
    city: 'Fort Drum',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0354,
    longitude: -75.754,
    postal_code: '13602'
  },
  '13603': {
    country: 'US',
    city: 'Watertown',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.9087,
    longitude: -75.8967,
    postal_code: '13603'
  },
  '13605': {
    country: 'US',
    city: 'Adams',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.8062,
    longitude: -76.049,
    postal_code: '13605'
  },
  '13606': {
    country: 'US',
    city: 'Adams Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.8631,
    longitude: -76.0041,
    postal_code: '13606'
  },
  '13607': {
    country: 'US',
    city: 'Alexandria Bay',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.3359,
    longitude: -75.9177,
    postal_code: '13607'
  },
  '13608': {
    country: 'US',
    city: 'Antwerp',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.2358,
    longitude: -75.6005,
    postal_code: '13608'
  },
  '13611': {
    country: 'US',
    city: 'Belleville',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.778,
    longitude: -76.1259,
    postal_code: '13611'
  },
  '13612': {
    country: 'US',
    city: 'Black River',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0042,
    longitude: -75.7958,
    postal_code: '13612'
  },
  '13613': {
    country: 'US',
    city: 'Brasher Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.8467,
    longitude: -74.7473,
    postal_code: '13613'
  },
  '13614': {
    country: 'US',
    city: 'Brier Hill',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.5525,
    longitude: -75.6722,
    postal_code: '13614'
  },
  '13615': {
    country: 'US',
    city: 'Brownville',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.007,
    longitude: -75.9841,
    postal_code: '13615'
  },
  '13616': {
    country: 'US',
    city: 'Calcium',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0265,
    longitude: -75.8499,
    postal_code: '13616'
  },
  '13617': {
    country: 'US',
    city: 'Canton',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.5924,
    longitude: -75.1628,
    postal_code: '13617'
  },
  '13618': {
    country: 'US',
    city: 'Cape Vincent',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.1244,
    longitude: -76.3164,
    postal_code: '13618'
  },
  '13619': {
    country: 'US',
    city: 'Carthage',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.9791,
    longitude: -75.6013,
    postal_code: '13619'
  },
  '13620': {
    country: 'US',
    city: 'Castorland',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.8843,
    longitude: -75.4604,
    postal_code: '13620'
  },
  '13621': {
    country: 'US',
    city: 'Chase Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.8672,
    longitude: -75.073,
    postal_code: '13621'
  },
  '13622': {
    country: 'US',
    city: 'Chaumont',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0848,
    longitude: -76.1232,
    postal_code: '13622'
  },
  '13623': {
    country: 'US',
    city: 'Chippewa Bay',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.442,
    longitude: -75.7569,
    postal_code: '13623'
  },
  '13624': {
    country: 'US',
    city: 'Clayton',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.1442,
    longitude: -76.062,
    postal_code: '13624'
  },
  '13625': {
    country: 'US',
    city: 'Colton',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.5016,
    longitude: -74.9327,
    postal_code: '13625'
  },
  '13626': {
    country: 'US',
    city: 'Copenhagen',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.8801,
    longitude: -75.6839,
    postal_code: '13626'
  },
  '13627': {
    country: 'US',
    city: 'Deer River',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.9298,
    longitude: -75.5891,
    postal_code: '13627'
  },
  '13628': {
    country: 'US',
    city: 'Deferiet',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0356,
    longitude: -75.6838,
    postal_code: '13628'
  },
  '13630': {
    country: 'US',
    city: 'De Kalb Junction',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.4896,
    longitude: -75.2871,
    postal_code: '13630'
  },
  '13631': {
    country: 'US',
    city: 'Denmark',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 43.8998,
    longitude: -75.5824,
    postal_code: '13631'
  },
  '13632': {
    country: 'US',
    city: 'Depauville',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.1395,
    longitude: -76.0616,
    postal_code: '13632'
  },
  '13633': {
    country: 'US',
    city: 'De Peyster',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.4989,
    longitude: -75.4772,
    postal_code: '13633'
  },
  '13634': {
    country: 'US',
    city: 'Dexter',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0069,
    longitude: -76.065,
    postal_code: '13634'
  },
  '13635': {
    country: 'US',
    city: 'Edwards',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.311,
    longitude: -75.2522,
    postal_code: '13635'
  },
  '13636': {
    country: 'US',
    city: 'Ellisburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.7434,
    longitude: -76.1165,
    postal_code: '13636'
  },
  '13637': {
    country: 'US',
    city: 'Evans Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0817,
    longitude: -75.8305,
    postal_code: '13637'
  },
  '13638': {
    country: 'US',
    city: 'Felts Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0204,
    longitude: -75.7524,
    postal_code: '13638'
  },
  '13639': {
    country: 'US',
    city: 'Fine',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.2541,
    longitude: -75.1379,
    postal_code: '13639'
  },
  '13640': {
    country: 'US',
    city: 'Wellesley Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.3213,
    longitude: -76.0172,
    postal_code: '13640'
  },
  '13641': {
    country: 'US',
    city: 'Fishers Landing',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.2752,
    longitude: -76.0049,
    postal_code: '13641'
  },
  '13642': {
    country: 'US',
    city: 'Gouverneur',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.3283,
    longitude: -75.4651,
    postal_code: '13642'
  },
  '13643': {
    country: 'US',
    city: 'Great Bend',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0342,
    longitude: -75.7188,
    postal_code: '13643'
  },
  '13645': {
    country: 'US',
    city: 'Hailesboro',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.3101,
    longitude: -75.4463,
    postal_code: '13645'
  },
  '13646': {
    country: 'US',
    city: 'Hammond',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.4502,
    longitude: -75.6727,
    postal_code: '13646'
  },
  '13647': {
    country: 'US',
    city: 'Hannawa Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.6087,
    longitude: -74.9732,
    postal_code: '13647'
  },
  '13648': {
    country: 'US',
    city: 'Harrisville',
    state: 'New York',
    state_short: 'NY',
    county: 'Lewis',
    latitude: 44.1613,
    longitude: -75.3252,
    postal_code: '13648'
  },
  '13649': {
    country: 'US',
    city: 'Helena',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.9217,
    longitude: -74.7068,
    postal_code: '13649'
  },
  '13650': {
    country: 'US',
    city: 'Henderson',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.8467,
    longitude: -76.2352,
    postal_code: '13650'
  },
  '13651': {
    country: 'US',
    city: 'Henderson Harbor',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.8708,
    longitude: -76.1809,
    postal_code: '13651'
  },
  '13652': {
    country: 'US',
    city: 'Hermon',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.4448,
    longitude: -75.1987,
    postal_code: '13652'
  },
  '13654': {
    country: 'US',
    city: 'Heuvelton',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.593,
    longitude: -75.4203,
    postal_code: '13654'
  },
  '13655': {
    country: 'US',
    city: 'Hogansburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Franklin',
    latitude: 44.9825,
    longitude: -74.6626,
    postal_code: '13655'
  },
  '13656': {
    country: 'US',
    city: 'La Fargeville',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.1987,
    longitude: -75.9569,
    postal_code: '13656'
  },
  '13657': {
    country: 'US',
    city: 'Limerick',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0292,
    longitude: -76.043,
    postal_code: '13657'
  },
  '13658': {
    country: 'US',
    city: 'Lisbon',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.7184,
    longitude: -75.2694,
    postal_code: '13658'
  },
  '13659': {
    country: 'US',
    city: 'Lorraine',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.7568,
    longitude: -75.9053,
    postal_code: '13659'
  },
  '13660': {
    country: 'US',
    city: 'Madrid',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.769,
    longitude: -75.1413,
    postal_code: '13660'
  },
  '13661': {
    country: 'US',
    city: 'Mannsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.7179,
    longitude: -76.082,
    postal_code: '13661'
  },
  '13662': {
    country: 'US',
    city: 'Massena',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.9322,
    longitude: -74.8845,
    postal_code: '13662'
  },
  '13664': {
    country: 'US',
    city: 'Morristown',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.5843,
    longitude: -75.6453,
    postal_code: '13664'
  },
  '13665': {
    country: 'US',
    city: 'Natural Bridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.063,
    longitude: -75.5039,
    postal_code: '13665'
  },
  '13666': {
    country: 'US',
    city: 'Newton Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.2177,
    longitude: -74.9423,
    postal_code: '13666'
  },
  '13667': {
    country: 'US',
    city: 'Norfolk',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.8424,
    longitude: -74.9577,
    postal_code: '13667'
  },
  '13668': {
    country: 'US',
    city: 'Norwood',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.7472,
    longitude: -74.9992,
    postal_code: '13668'
  },
  '13669': {
    country: 'US',
    city: 'Ogdensburg',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.6902,
    longitude: -75.4774,
    postal_code: '13669'
  },
  '13670': {
    country: 'US',
    city: 'Oswegatchie',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.1933,
    longitude: -75.0659,
    postal_code: '13670'
  },
  '13671': {
    country: 'US',
    city: 'Oxbow',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.2873,
    longitude: -75.623,
    postal_code: '13671'
  },
  '13672': {
    country: 'US',
    city: 'Parishville',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.5927,
    longitude: -74.7941,
    postal_code: '13672'
  },
  '13673': {
    country: 'US',
    city: 'Philadelphia',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.1589,
    longitude: -75.7099,
    postal_code: '13673'
  },
  '13674': {
    country: 'US',
    city: 'Pierrepont Manor',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.7334,
    longitude: -76.0543,
    postal_code: '13674'
  },
  '13675': {
    country: 'US',
    city: 'Plessis',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.2774,
    longitude: -75.8496,
    postal_code: '13675'
  },
  '13676': {
    country: 'US',
    city: 'Potsdam',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.6592,
    longitude: -74.9681,
    postal_code: '13676'
  },
  '13677': {
    country: 'US',
    city: 'Pyrites',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.5148,
    longitude: -75.1858,
    postal_code: '13677'
  },
  '13678': {
    country: 'US',
    city: 'Raymondville',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.8287,
    longitude: -74.9798,
    postal_code: '13678'
  },
  '13679': {
    country: 'US',
    city: 'Redwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.3211,
    longitude: -75.815,
    postal_code: '13679'
  },
  '13680': {
    country: 'US',
    city: 'Rensselaer Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.5943,
    longitude: -75.3237,
    postal_code: '13680'
  },
  '13681': {
    country: 'US',
    city: 'Richville',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.44,
    longitude: -75.3777,
    postal_code: '13681'
  },
  '13682': {
    country: 'US',
    city: 'Rodman',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.8622,
    longitude: -75.8719,
    postal_code: '13682'
  },
  '13683': {
    country: 'US',
    city: 'Rooseveltown',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.9728,
    longitude: -74.731,
    postal_code: '13683'
  },
  '13684': {
    country: 'US',
    city: 'Russell',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.3824,
    longitude: -75.1043,
    postal_code: '13684'
  },
  '13685': {
    country: 'US',
    city: 'Sackets Harbor',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 43.9398,
    longitude: -76.105,
    postal_code: '13685'
  },
  '13687': {
    country: 'US',
    city: 'South Colton',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.5041,
    longitude: -74.8607,
    postal_code: '13687'
  },
  '13690': {
    country: 'US',
    city: 'Star Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.1578,
    longitude: -75.033,
    postal_code: '13690'
  },
  '13691': {
    country: 'US',
    city: 'Theresa',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.2113,
    longitude: -75.8014,
    postal_code: '13691'
  },
  '13692': {
    country: 'US',
    city: 'Thousand Island Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.2898,
    longitude: -76.0262,
    postal_code: '13692'
  },
  '13693': {
    country: 'US',
    city: 'Three Mile Bay',
    state: 'New York',
    state_short: 'NY',
    county: 'Jefferson',
    latitude: 44.0551,
    longitude: -76.2689,
    postal_code: '13693'
  },
  '13694': {
    country: 'US',
    city: 'Waddington',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.8564,
    longitude: -75.2049,
    postal_code: '13694'
  },
  '13695': {
    country: 'US',
    city: 'Wanakena',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.1408,
    longitude: -74.9125,
    postal_code: '13695'
  },
  '13696': {
    country: 'US',
    city: 'West Stockholm',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.7137,
    longitude: -74.9008,
    postal_code: '13696'
  },
  '13697': {
    country: 'US',
    city: 'Winthrop',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.7583,
    longitude: -74.8066,
    postal_code: '13697'
  },
  '13699': {
    country: 'US',
    city: 'Potsdam',
    state: 'New York',
    state_short: 'NY',
    county: 'St. Lawrence',
    latitude: 44.6698,
    longitude: -74.9813,
    postal_code: '13699'
  },
  '13730': {
    country: 'US',
    city: 'Afton',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.2417,
    longitude: -75.5366,
    postal_code: '13730'
  },
  '13731': {
    country: 'US',
    city: 'Andes',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.1569,
    longitude: -74.7887,
    postal_code: '13731'
  },
  '13732': {
    country: 'US',
    city: 'Apalachin',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.0556,
    longitude: -76.1519,
    postal_code: '13732'
  },
  '13733': {
    country: 'US',
    city: 'Bainbridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.312,
    longitude: -75.4894,
    postal_code: '13733'
  },
  '13734': {
    country: 'US',
    city: 'Barton',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.0695,
    longitude: -76.3983,
    postal_code: '13734'
  },
  '13736': {
    country: 'US',
    city: 'Berkshire',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.3074,
    longitude: -76.192,
    postal_code: '13736'
  },
  '13737': {
    country: 'US',
    city: 'Bible School Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0805,
    longitude: -76.0973,
    postal_code: '13737'
  },
  '13738': {
    country: 'US',
    city: 'Blodgett Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.5673,
    longitude: -76.1238,
    postal_code: '13738'
  },
  '13739': {
    country: 'US',
    city: 'Bloomville',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.3522,
    longitude: -74.8071,
    postal_code: '13739'
  },
  '13740': {
    country: 'US',
    city: 'Bovina Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.2709,
    longitude: -74.7661,
    postal_code: '13740'
  },
  '13743': {
    country: 'US',
    city: 'Candor',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.2063,
    longitude: -76.3322,
    postal_code: '13743'
  },
  '13744': {
    country: 'US',
    city: 'Castle Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.2568,
    longitude: -75.9087,
    postal_code: '13744'
  },
  '13745': {
    country: 'US',
    city: 'Chenango Bridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1738,
    longitude: -75.8728,
    postal_code: '13745'
  },
  '13746': {
    country: 'US',
    city: 'Chenango Forks',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.2778,
    longitude: -75.8462,
    postal_code: '13746'
  },
  '13747': {
    country: 'US',
    city: 'Colliersville',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.5051,
    longitude: -74.9821,
    postal_code: '13747'
  },
  '13748': {
    country: 'US',
    city: 'Conklin',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0454,
    longitude: -75.8076,
    postal_code: '13748'
  },
  '13749': {
    country: 'US',
    city: 'Corbettsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0156,
    longitude: -75.7905,
    postal_code: '13749'
  },
  '13750': {
    country: 'US',
    city: 'Davenport',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.4711,
    longitude: -74.8357,
    postal_code: '13750'
  },
  '13751': {
    country: 'US',
    city: 'Davenport Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.4508,
    longitude: -74.9013,
    postal_code: '13751'
  },
  '13752': {
    country: 'US',
    city: 'Delancey',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.2884,
    longitude: -75.1051,
    postal_code: '13752'
  },
  '13753': {
    country: 'US',
    city: 'Delhi',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.2937,
    longitude: -74.9207,
    postal_code: '13753'
  },
  '13754': {
    country: 'US',
    city: 'Deposit',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0666,
    longitude: -75.4287,
    postal_code: '13754'
  },
  '13755': {
    country: 'US',
    city: 'Downsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.0716,
    longitude: -75.0152,
    postal_code: '13755'
  },
  '13756': {
    country: 'US',
    city: 'East Branch',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.0039,
    longitude: -75.1226,
    postal_code: '13756'
  },
  '13757': {
    country: 'US',
    city: 'East Meredith',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.4101,
    longitude: -74.8987,
    postal_code: '13757'
  },
  '13758': {
    country: 'US',
    city: 'East Pharsalia',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.5835,
    longitude: -75.7219,
    postal_code: '13758'
  },
  '13760': {
    country: 'US',
    city: 'Endicott',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1506,
    longitude: -76.0551,
    postal_code: '13760'
  },
  '13761': {
    country: 'US',
    city: 'Endicott',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0984,
    longitude: -76.0494,
    postal_code: '13761'
  },
  '13762': {
    country: 'US',
    city: 'Endwell',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1129,
    longitude: -76.021,
    postal_code: '13762'
  },
  '13763': {
    country: 'US',
    city: 'Endicott',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0984,
    longitude: -76.0494,
    postal_code: '13763'
  },
  '13774': {
    country: 'US',
    city: 'Fishs Eddy',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 41.9654,
    longitude: -75.1594,
    postal_code: '13774'
  },
  '13775': {
    country: 'US',
    city: 'Franklin',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.3421,
    longitude: -75.1485,
    postal_code: '13775'
  },
  '13776': {
    country: 'US',
    city: 'Gilbertsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.4715,
    longitude: -75.3257,
    postal_code: '13776'
  },
  '13777': {
    country: 'US',
    city: 'Glen Aubrey',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.2573,
    longitude: -75.9805,
    postal_code: '13777'
  },
  '13778': {
    country: 'US',
    city: 'Greene',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.3401,
    longitude: -75.7342,
    postal_code: '13778'
  },
  '13780': {
    country: 'US',
    city: 'Guilford',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.4269,
    longitude: -75.4823,
    postal_code: '13780'
  },
  '13782': {
    country: 'US',
    city: 'Hamden',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.1787,
    longitude: -74.9984,
    postal_code: '13782'
  },
  '13783': {
    country: 'US',
    city: 'Hancock',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 41.9914,
    longitude: -75.2647,
    postal_code: '13783'
  },
  '13784': {
    country: 'US',
    city: 'Harford',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.4262,
    longitude: -76.2266,
    postal_code: '13784'
  },
  '13786': {
    country: 'US',
    city: 'Harpersfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.4499,
    longitude: -74.6871,
    postal_code: '13786'
  },
  '13787': {
    country: 'US',
    city: 'Harpursville',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1823,
    longitude: -75.6545,
    postal_code: '13787'
  },
  '13788': {
    country: 'US',
    city: 'Hobart',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.3594,
    longitude: -74.6759,
    postal_code: '13788'
  },
  '13790': {
    country: 'US',
    city: 'Johnson City',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1267,
    longitude: -75.9685,
    postal_code: '13790'
  },
  '13794': {
    country: 'US',
    city: 'Killawog',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.4006,
    longitude: -76.0208,
    postal_code: '13794'
  },
  '13795': {
    country: 'US',
    city: 'Kirkwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0695,
    longitude: -75.7967,
    postal_code: '13795'
  },
  '13796': {
    country: 'US',
    city: 'Laurens',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.5383,
    longitude: -75.1279,
    postal_code: '13796'
  },
  '13797': {
    country: 'US',
    city: 'Lisle',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.3409,
    longitude: -76.0302,
    postal_code: '13797'
  },
  '13801': {
    country: 'US',
    city: 'Mc Donough',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.5068,
    longitude: -75.7623,
    postal_code: '13801'
  },
  '13802': {
    country: 'US',
    city: 'Maine',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.2538,
    longitude: -76.0464,
    postal_code: '13802'
  },
  '13803': {
    country: 'US',
    city: 'Marathon',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.4527,
    longitude: -76.0395,
    postal_code: '13803'
  },
  '13804': {
    country: 'US',
    city: 'Masonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.2102,
    longitude: -75.3739,
    postal_code: '13804'
  },
  '13806': {
    country: 'US',
    city: 'Meridale',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.3734,
    longitude: -74.965,
    postal_code: '13806'
  },
  '13807': {
    country: 'US',
    city: 'Milford',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.6148,
    longitude: -74.9685,
    postal_code: '13807'
  },
  '13808': {
    country: 'US',
    city: 'Morris',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.5478,
    longitude: -75.2448,
    postal_code: '13808'
  },
  '13809': {
    country: 'US',
    city: 'Mount Upton',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.4081,
    longitude: -75.4003,
    postal_code: '13809'
  },
  '13810': {
    country: 'US',
    city: 'Mount Vision',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.6068,
    longitude: -75.1264,
    postal_code: '13810'
  },
  '13811': {
    country: 'US',
    city: 'Newark Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.2281,
    longitude: -76.1625,
    postal_code: '13811'
  },
  '13812': {
    country: 'US',
    city: 'Nichols',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.0301,
    longitude: -76.354,
    postal_code: '13812'
  },
  '13813': {
    country: 'US',
    city: 'Nineveh',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1625,
    longitude: -75.5484,
    postal_code: '13813'
  },
  '13814': {
    country: 'US',
    city: 'North Norwich',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.6036,
    longitude: -75.5282,
    postal_code: '13814'
  },
  '13815': {
    country: 'US',
    city: 'Norwich',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.5414,
    longitude: -75.5274,
    postal_code: '13815'
  },
  '13820': {
    country: 'US',
    city: 'Oneonta',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.4625,
    longitude: -75.0491,
    postal_code: '13820'
  },
  '13825': {
    country: 'US',
    city: 'Otego',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.4133,
    longitude: -75.2079,
    postal_code: '13825'
  },
  '13826': {
    country: 'US',
    city: 'Ouaquaga',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1262,
    longitude: -75.6471,
    postal_code: '13826'
  },
  '13827': {
    country: 'US',
    city: 'Owego',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.1138,
    longitude: -76.2528,
    postal_code: '13827'
  },
  '13830': {
    country: 'US',
    city: 'Oxford',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.4379,
    longitude: -75.5673,
    postal_code: '13830'
  },
  '13832': {
    country: 'US',
    city: 'Plymouth',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.6336,
    longitude: -75.6172,
    postal_code: '13832'
  },
  '13833': {
    country: 'US',
    city: 'Port Crane',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1958,
    longitude: -75.7591,
    postal_code: '13833'
  },
  '13834': {
    country: 'US',
    city: 'Portlandville',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.5304,
    longitude: -74.9671,
    postal_code: '13834'
  },
  '13835': {
    country: 'US',
    city: 'Richford',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.3945,
    longitude: -76.1865,
    postal_code: '13835'
  },
  '13838': {
    country: 'US',
    city: 'Sidney',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.3074,
    longitude: -75.3908,
    postal_code: '13838'
  },
  '13839': {
    country: 'US',
    city: 'Sidney Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.2441,
    longitude: -75.2871,
    postal_code: '13839'
  },
  '13840': {
    country: 'US',
    city: 'Smithboro',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.0395,
    longitude: -76.4004,
    postal_code: '13840'
  },
  '13841': {
    country: 'US',
    city: 'Smithville Flats',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.3989,
    longitude: -75.8237,
    postal_code: '13841'
  },
  '13842': {
    country: 'US',
    city: 'South Kortright',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.377,
    longitude: -74.7259,
    postal_code: '13842'
  },
  '13843': {
    country: 'US',
    city: 'South New Berlin',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.5295,
    longitude: -75.3852,
    postal_code: '13843'
  },
  '13844': {
    country: 'US',
    city: 'South Plymouth',
    state: 'New York',
    state_short: 'NY',
    county: 'Chenango',
    latitude: 42.6053,
    longitude: -75.633,
    postal_code: '13844'
  },
  '13845': {
    country: 'US',
    city: 'Tioga Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.0562,
    longitude: -76.348,
    postal_code: '13845'
  },
  '13846': {
    country: 'US',
    city: 'Treadwell',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.363,
    longitude: -75.0588,
    postal_code: '13846'
  },
  '13847': {
    country: 'US',
    city: 'Trout Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.2037,
    longitude: -75.2793,
    postal_code: '13847'
  },
  '13848': {
    country: 'US',
    city: 'Tunnel',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.2147,
    longitude: -75.7277,
    postal_code: '13848'
  },
  '13849': {
    country: 'US',
    city: 'Unadilla',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.3252,
    longitude: -75.3366,
    postal_code: '13849'
  },
  '13850': {
    country: 'US',
    city: 'Vestal',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0771,
    longitude: -76.0118,
    postal_code: '13850'
  },
  '13851': {
    country: 'US',
    city: 'Vestal',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0851,
    longitude: -76.0538,
    postal_code: '13851'
  },
  '13856': {
    country: 'US',
    city: 'Walton',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.1756,
    longitude: -75.1532,
    postal_code: '13856'
  },
  '13859': {
    country: 'US',
    city: 'Wells Bridge',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.3709,
    longitude: -75.2486,
    postal_code: '13859'
  },
  '13860': {
    country: 'US',
    city: 'West Davenport',
    state: 'New York',
    state_short: 'NY',
    county: 'Delaware',
    latitude: 42.4459,
    longitude: -74.9632,
    postal_code: '13860'
  },
  '13861': {
    country: 'US',
    city: 'West Oneonta',
    state: 'New York',
    state_short: 'NY',
    county: 'Otsego',
    latitude: 42.5011,
    longitude: -75.1409,
    postal_code: '13861'
  },
  '13862': {
    country: 'US',
    city: 'Whitney Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.3384,
    longitude: -75.9522,
    postal_code: '13862'
  },
  '13863': {
    country: 'US',
    city: 'Willet',
    state: 'New York',
    state_short: 'NY',
    county: 'Cortland',
    latitude: 42.452,
    longitude: -75.9014,
    postal_code: '13863'
  },
  '13864': {
    country: 'US',
    city: 'Willseyville',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.3029,
    longitude: -76.3897,
    postal_code: '13864'
  },
  '13865': {
    country: 'US',
    city: 'Windsor',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0759,
    longitude: -75.6405,
    postal_code: '13865'
  },
  '13901': {
    country: 'US',
    city: 'Binghamton',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1463,
    longitude: -75.8865,
    postal_code: '13901'
  },
  '13902': {
    country: 'US',
    city: 'Binghamton',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1054,
    longitude: -75.8876,
    postal_code: '13902'
  },
  '13903': {
    country: 'US',
    city: 'Binghamton',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.0811,
    longitude: -75.8977,
    postal_code: '13903'
  },
  '13904': {
    country: 'US',
    city: 'Binghamton',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1171,
    longitude: -75.8653,
    postal_code: '13904'
  },
  '13905': {
    country: 'US',
    city: 'Binghamton',
    state: 'New York',
    state_short: 'NY',
    county: 'Broome',
    latitude: 42.1151,
    longitude: -75.9309,
    postal_code: '13905'
  },
  '14001': {
    country: 'US',
    city: 'Akron',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 43.0249,
    longitude: -78.5084,
    postal_code: '14001'
  },
  '14004': {
    country: 'US',
    city: 'Alden',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8984,
    longitude: -78.5257,
    postal_code: '14004'
  },
  '14005': {
    country: 'US',
    city: 'Alexander',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 42.9159,
    longitude: -78.2589,
    postal_code: '14005'
  },
  '14006': {
    country: 'US',
    city: 'Angola',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.6366,
    longitude: -79.0497,
    postal_code: '14006'
  },
  '14008': {
    country: 'US',
    city: 'Appleton',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.3105,
    longitude: -78.6372,
    postal_code: '14008'
  },
  '14009': {
    country: 'US',
    city: 'Arcade',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.563,
    longitude: -78.4134,
    postal_code: '14009'
  },
  '14010': {
    country: 'US',
    city: 'Athol Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14010'
  },
  '14011': {
    country: 'US',
    city: 'Attica',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.8499,
    longitude: -78.2798,
    postal_code: '14011'
  },
  '14012': {
    country: 'US',
    city: 'Barker',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.3368,
    longitude: -78.542,
    postal_code: '14012'
  },
  '14013': {
    country: 'US',
    city: 'Basom',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 43.0807,
    longitude: -78.3951,
    postal_code: '14013'
  },
  '14020': {
    country: 'US',
    city: 'Batavia',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 43.0003,
    longitude: -78.1929,
    postal_code: '14020'
  },
  '14021': {
    country: 'US',
    city: 'Batavia',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 42.9981,
    longitude: -78.1848,
    postal_code: '14021'
  },
  '14024': {
    country: 'US',
    city: 'Bliss',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.5799,
    longitude: -78.2581,
    postal_code: '14024'
  },
  '14025': {
    country: 'US',
    city: 'Boston',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.6314,
    longitude: -78.7391,
    postal_code: '14025'
  },
  '14026': {
    country: 'US',
    city: 'Bowmansville',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.941,
    longitude: -78.688,
    postal_code: '14026'
  },
  '14027': {
    country: 'US',
    city: 'Brant',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.5707,
    longitude: -79.0308,
    postal_code: '14027'
  },
  '14028': {
    country: 'US',
    city: 'Burt',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.3221,
    longitude: -78.7141,
    postal_code: '14028'
  },
  '14029': {
    country: 'US',
    city: 'Centerville',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.4743,
    longitude: -78.2474,
    postal_code: '14029'
  },
  '14030': {
    country: 'US',
    city: 'Chaffee',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.5605,
    longitude: -78.5025,
    postal_code: '14030'
  },
  '14031': {
    country: 'US',
    city: 'Clarence',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.981,
    longitude: -78.6162,
    postal_code: '14031'
  },
  '14032': {
    country: 'US',
    city: 'Clarence Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 43.0362,
    longitude: -78.639,
    postal_code: '14032'
  },
  '14033': {
    country: 'US',
    city: 'Colden',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.6551,
    longitude: -78.6921,
    postal_code: '14033'
  },
  '14034': {
    country: 'US',
    city: 'Collins',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.5001,
    longitude: -78.893,
    postal_code: '14034'
  },
  '14035': {
    country: 'US',
    city: 'Collins Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.4906,
    longitude: -78.8499,
    postal_code: '14035'
  },
  '14036': {
    country: 'US',
    city: 'Corfu',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 42.9777,
    longitude: -78.3929,
    postal_code: '14036'
  },
  '14037': {
    country: 'US',
    city: 'Cowlesville',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.8112,
    longitude: -78.4481,
    postal_code: '14037'
  },
  '14038': {
    country: 'US',
    city: 'Crittenden',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9473,
    longitude: -78.4744,
    postal_code: '14038'
  },
  '14039': {
    country: 'US',
    city: 'Dale',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.8263,
    longitude: -78.1749,
    postal_code: '14039'
  },
  '14040': {
    country: 'US',
    city: 'Darien Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 42.8948,
    longitude: -78.3878,
    postal_code: '14040'
  },
  '14041': {
    country: 'US',
    city: 'Dayton',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.4086,
    longitude: -78.9844,
    postal_code: '14041'
  },
  '14042': {
    country: 'US',
    city: 'Delevan',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.4926,
    longitude: -78.4793,
    postal_code: '14042'
  },
  '14043': {
    country: 'US',
    city: 'Depew',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.905,
    longitude: -78.7041,
    postal_code: '14043'
  },
  '14047': {
    country: 'US',
    city: 'Derby',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.6974,
    longitude: -78.9834,
    postal_code: '14047'
  },
  '14048': {
    country: 'US',
    city: 'Dunkirk',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.4877,
    longitude: -79.3283,
    postal_code: '14048'
  },
  '14051': {
    country: 'US',
    city: 'East Amherst',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 43.0429,
    longitude: -78.6988,
    postal_code: '14051'
  },
  '14052': {
    country: 'US',
    city: 'East Aurora',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7701,
    longitude: -78.602,
    postal_code: '14052'
  },
  '14054': {
    country: 'US',
    city: 'East Bethany',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 42.9166,
    longitude: -78.1342,
    postal_code: '14054'
  },
  '14055': {
    country: 'US',
    city: 'East Concord',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.5466,
    longitude: -78.611,
    postal_code: '14055'
  },
  '14056': {
    country: 'US',
    city: 'East Pembroke',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 42.9912,
    longitude: -78.3122,
    postal_code: '14056'
  },
  '14057': {
    country: 'US',
    city: 'Eden',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.6506,
    longitude: -78.8781,
    postal_code: '14057'
  },
  '14058': {
    country: 'US',
    city: 'Elba',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 43.0897,
    longitude: -78.1704,
    postal_code: '14058'
  },
  '14059': {
    country: 'US',
    city: 'Elma',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.834,
    longitude: -78.6343,
    postal_code: '14059'
  },
  '14060': {
    country: 'US',
    city: 'Farmersville Station',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.4276,
    longitude: -78.3608,
    postal_code: '14060'
  },
  '14061': {
    country: 'US',
    city: 'Farnham',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.5946,
    longitude: -79.084,
    postal_code: '14061'
  },
  '14062': {
    country: 'US',
    city: 'Forestville',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.4482,
    longitude: -79.1607,
    postal_code: '14062'
  },
  '14063': {
    country: 'US',
    city: 'Fredonia',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.4333,
    longitude: -79.3339,
    postal_code: '14063'
  },
  '14065': {
    country: 'US',
    city: 'Freedom',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.4897,
    longitude: -78.3501,
    postal_code: '14065'
  },
  '14066': {
    country: 'US',
    city: 'Gainesville',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.619,
    longitude: -78.1795,
    postal_code: '14066'
  },
  '14067': {
    country: 'US',
    city: 'Gasport',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.2106,
    longitude: -78.5745,
    postal_code: '14067'
  },
  '14068': {
    country: 'US',
    city: 'Getzville',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 43.024,
    longitude: -78.7532,
    postal_code: '14068'
  },
  '14069': {
    country: 'US',
    city: 'Glenwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.6001,
    longitude: -78.6386,
    postal_code: '14069'
  },
  '14070': {
    country: 'US',
    city: 'Gowanda',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.4712,
    longitude: -78.9339,
    postal_code: '14070'
  },
  '14072': {
    country: 'US',
    city: 'Grand Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 43.0183,
    longitude: -78.9591,
    postal_code: '14072'
  },
  '14075': {
    country: 'US',
    city: 'Hamburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7334,
    longitude: -78.8389,
    postal_code: '14075'
  },
  '14080': {
    country: 'US',
    city: 'Holland',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.6396,
    longitude: -78.5439,
    postal_code: '14080'
  },
  '14081': {
    country: 'US',
    city: 'Irving',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.5739,
    longitude: -79.0596,
    postal_code: '14081'
  },
  '14082': {
    country: 'US',
    city: 'Java Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.6634,
    longitude: -78.3925,
    postal_code: '14082'
  },
  '14083': {
    country: 'US',
    city: 'Java Village',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.6769,
    longitude: -78.441,
    postal_code: '14083'
  },
  '14085': {
    country: 'US',
    city: 'Lake View',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7215,
    longitude: -78.9327,
    postal_code: '14085'
  },
  '14086': {
    country: 'US',
    city: 'Lancaster',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9017,
    longitude: -78.6631,
    postal_code: '14086'
  },
  '14091': {
    country: 'US',
    city: 'Lawtons',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.5404,
    longitude: -78.9212,
    postal_code: '14091'
  },
  '14092': {
    country: 'US',
    city: 'Lewiston',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.1722,
    longitude: -79.0215,
    postal_code: '14092'
  },
  '14094': {
    country: 'US',
    city: 'Lockport',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.16,
    longitude: -78.6923,
    postal_code: '14094'
  },
  '14095': {
    country: 'US',
    city: 'Lockport',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.1706,
    longitude: -78.6903,
    postal_code: '14095'
  },
  '14098': {
    country: 'US',
    city: 'Lyndonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.3233,
    longitude: -78.3811,
    postal_code: '14098'
  },
  '14101': {
    country: 'US',
    city: 'Machias',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.4083,
    longitude: -78.5059,
    postal_code: '14101'
  },
  '14102': {
    country: 'US',
    city: 'Marilla',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8332,
    longitude: -78.5587,
    postal_code: '14102'
  },
  '14103': {
    country: 'US',
    city: 'Medina',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.2184,
    longitude: -78.3874,
    postal_code: '14103'
  },
  '14105': {
    country: 'US',
    city: 'Middleport',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.2183,
    longitude: -78.4841,
    postal_code: '14105'
  },
  '14107': {
    country: 'US',
    city: 'Model City',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.1851,
    longitude: -78.9837,
    postal_code: '14107'
  },
  '14108': {
    country: 'US',
    city: 'Newfane',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.2724,
    longitude: -78.707,
    postal_code: '14108'
  },
  '14109': {
    country: 'US',
    city: 'Niagara University',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.138,
    longitude: -79.0342,
    postal_code: '14109'
  },
  '14110': {
    country: 'US',
    city: 'North Boston',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.6856,
    longitude: -78.7767,
    postal_code: '14110'
  },
  '14111': {
    country: 'US',
    city: 'North Collins',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.5896,
    longitude: -78.9107,
    postal_code: '14111'
  },
  '14112': {
    country: 'US',
    city: 'North Evans',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.697,
    longitude: -78.9414,
    postal_code: '14112'
  },
  '14113': {
    country: 'US',
    city: 'North Java',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.6776,
    longitude: -78.338,
    postal_code: '14113'
  },
  '14120': {
    country: 'US',
    city: 'North Tonawanda',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.0498,
    longitude: -78.851,
    postal_code: '14120'
  },
  '14125': {
    country: 'US',
    city: 'Oakfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 43.0717,
    longitude: -78.2702,
    postal_code: '14125'
  },
  '14126': {
    country: 'US',
    city: 'Olcott',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.3378,
    longitude: -78.7148,
    postal_code: '14126'
  },
  '14127': {
    country: 'US',
    city: 'Orchard Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7639,
    longitude: -78.7518,
    postal_code: '14127'
  },
  '14129': {
    country: 'US',
    city: 'Perrysburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.4723,
    longitude: -78.9981,
    postal_code: '14129'
  },
  '14130': {
    country: 'US',
    city: 'Pike',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.5565,
    longitude: -78.1528,
    postal_code: '14130'
  },
  '14131': {
    country: 'US',
    city: 'Ransomville',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.2286,
    longitude: -78.8982,
    postal_code: '14131'
  },
  '14132': {
    country: 'US',
    city: 'Sanborn',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.1419,
    longitude: -78.8785,
    postal_code: '14132'
  },
  '14133': {
    country: 'US',
    city: 'Sandusky',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.4891,
    longitude: -78.367,
    postal_code: '14133'
  },
  '14134': {
    country: 'US',
    city: 'Sardinia',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.5323,
    longitude: -78.5172,
    postal_code: '14134'
  },
  '14135': {
    country: 'US',
    city: 'Sheridan',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.489,
    longitude: -79.239,
    postal_code: '14135'
  },
  '14136': {
    country: 'US',
    city: 'Silver Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.5357,
    longitude: -79.1628,
    postal_code: '14136'
  },
  '14138': {
    country: 'US',
    city: 'South Dayton',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.3718,
    longitude: -79.0501,
    postal_code: '14138'
  },
  '14139': {
    country: 'US',
    city: 'South Wales',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7063,
    longitude: -78.5452,
    postal_code: '14139'
  },
  '14140': {
    country: 'US',
    city: 'Spring Brook',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8072,
    longitude: -78.6676,
    postal_code: '14140'
  },
  '14141': {
    country: 'US',
    city: 'Springville',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.52,
    longitude: -78.6847,
    postal_code: '14141'
  },
  '14143': {
    country: 'US',
    city: 'Stafford',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 42.9829,
    longitude: -78.0898,
    postal_code: '14143'
  },
  '14144': {
    country: 'US',
    city: 'Stella Niagara',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.1995,
    longitude: -79.0425,
    postal_code: '14144'
  },
  '14145': {
    country: 'US',
    city: 'Strykersville',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.7249,
    longitude: -78.4347,
    postal_code: '14145'
  },
  '14150': {
    country: 'US',
    city: 'Tonawanda',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 43.0028,
    longitude: -78.8547,
    postal_code: '14150'
  },
  '14151': {
    country: 'US',
    city: 'Tonawanda',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 43.0203,
    longitude: -78.8803,
    postal_code: '14151'
  },
  '14166': {
    country: 'US',
    city: 'Van Buren Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.4511,
    longitude: -79.4154,
    postal_code: '14166'
  },
  '14167': {
    country: 'US',
    city: 'Varysburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.7459,
    longitude: -78.3167,
    postal_code: '14167'
  },
  '14168': {
    country: 'US',
    city: 'Versailles',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.5189,
    longitude: -78.9959,
    postal_code: '14168'
  },
  '14169': {
    country: 'US',
    city: 'Wales Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.53,
    postal_code: '14169'
  },
  '14170': {
    country: 'US',
    city: 'West Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7053,
    longitude: -78.6779,
    postal_code: '14170'
  },
  '14171': {
    country: 'US',
    city: 'West Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.4315,
    longitude: -78.628,
    postal_code: '14171'
  },
  '14172': {
    country: 'US',
    city: 'Wilson',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.2968,
    longitude: -78.8244,
    postal_code: '14172'
  },
  '14173': {
    country: 'US',
    city: 'Yorkshire',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.5247,
    longitude: -78.4755,
    postal_code: '14173'
  },
  '14174': {
    country: 'US',
    city: 'Youngstown',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.2461,
    longitude: -79.0245,
    postal_code: '14174'
  },
  '14201': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8967,
    longitude: -78.8846,
    postal_code: '14201'
  },
  '14202': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.887,
    longitude: -78.8779,
    postal_code: '14202'
  },
  '14203': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8939,
    longitude: -78.8681,
    postal_code: '14203'
  },
  '14204': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.884,
    longitude: -78.8597,
    postal_code: '14204'
  },
  '14205': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8864,
    longitude: -78.8784,
    postal_code: '14205'
  },
  '14206': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8811,
    longitude: -78.8104,
    postal_code: '14206'
  },
  '14207': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9491,
    longitude: -78.8978,
    postal_code: '14207'
  },
  '14208': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9154,
    longitude: -78.8505,
    postal_code: '14208'
  },
  '14209': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.913,
    longitude: -78.8656,
    postal_code: '14209'
  },
  '14210': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8614,
    longitude: -78.8205,
    postal_code: '14210'
  },
  '14211': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9082,
    longitude: -78.8225,
    postal_code: '14211'
  },
  '14212': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8946,
    longitude: -78.8245,
    postal_code: '14212'
  },
  '14213': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9167,
    longitude: -78.8895,
    postal_code: '14213'
  },
  '14214': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9414,
    longitude: -78.8374,
    postal_code: '14214'
  },
  '14215': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9335,
    longitude: -78.8115,
    postal_code: '14215'
  },
  '14216': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9499,
    longitude: -78.8599,
    postal_code: '14216'
  },
  '14217': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9719,
    longitude: -78.8769,
    postal_code: '14217'
  },
  '14218': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8146,
    longitude: -78.8078,
    postal_code: '14218'
  },
  '14219': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7863,
    longitude: -78.8264,
    postal_code: '14219'
  },
  '14220': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8441,
    longitude: -78.8182,
    postal_code: '14220'
  },
  '14221': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9685,
    longitude: -78.7492,
    postal_code: '14221'
  },
  '14222': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9164,
    longitude: -78.8763,
    postal_code: '14222'
  },
  '14223': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9731,
    longitude: -78.845,
    postal_code: '14223'
  },
  '14224': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8371,
    longitude: -78.7484,
    postal_code: '14224'
  },
  '14225': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9255,
    longitude: -78.7481,
    postal_code: '14225'
  },
  '14226': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9744,
    longitude: -78.7949,
    postal_code: '14226'
  },
  '14227': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8853,
    longitude: -78.7462,
    postal_code: '14227'
  },
  '14228': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 43.0408,
    longitude: -78.7812,
    postal_code: '14228'
  },
  '14231': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14231'
  },
  '14233': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14233'
  },
  '14240': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14240'
  },
  '14241': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.9383,
    longitude: -78.7441,
    postal_code: '14241'
  },
  '14260': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14260'
  },
  '14261': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14261'
  },
  '14263': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14263'
  },
  '14264': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8856,
    longitude: -78.8735,
    postal_code: '14264'
  },
  '14265': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14265'
  },
  '14267': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14267'
  },
  '14269': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14269'
  },
  '14270': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14270'
  },
  '14272': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.7684,
    longitude: -78.8871,
    postal_code: '14272'
  },
  '14273': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.755,
    longitude: -78.7849,
    postal_code: '14273'
  },
  '14276': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8864,
    longitude: -78.8784,
    postal_code: '14276'
  },
  '14280': {
    country: 'US',
    city: 'Buffalo',
    state: 'New York',
    state_short: 'NY',
    county: 'Erie',
    latitude: 42.8864,
    longitude: -78.8784,
    postal_code: '14280'
  },
  '14301': {
    country: 'US',
    city: 'Niagara Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.0955,
    longitude: -79.0414,
    postal_code: '14301'
  },
  '14302': {
    country: 'US',
    city: 'Niagara Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.0945,
    longitude: -79.0567,
    postal_code: '14302'
  },
  '14303': {
    country: 'US',
    city: 'Niagara Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.0878,
    longitude: -79.037,
    postal_code: '14303'
  },
  '14304': {
    country: 'US',
    city: 'Niagara Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.0908,
    longitude: -78.9644,
    postal_code: '14304'
  },
  '14305': {
    country: 'US',
    city: 'Niagara Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Niagara',
    latitude: 43.1146,
    longitude: -79.0378,
    postal_code: '14305'
  },
  '14410': {
    country: 'US',
    city: 'Adams Basin',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1953,
    longitude: -77.8559,
    postal_code: '14410'
  },
  '14411': {
    country: 'US',
    city: 'Albion',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.2398,
    longitude: -78.2068,
    postal_code: '14411'
  },
  '14413': {
    country: 'US',
    city: 'Alton',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2227,
    longitude: -76.9821,
    postal_code: '14413'
  },
  '14414': {
    country: 'US',
    city: 'Avon',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.903,
    longitude: -77.7274,
    postal_code: '14414'
  },
  '14415': {
    country: 'US',
    city: 'Bellona',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.7554,
    longitude: -77.0217,
    postal_code: '14415'
  },
  '14416': {
    country: 'US',
    city: 'Bergen',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 43.0869,
    longitude: -77.9603,
    postal_code: '14416'
  },
  '14418': {
    country: 'US',
    city: 'Branchport',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.6065,
    longitude: -77.2052,
    postal_code: '14418'
  },
  '14420': {
    country: 'US',
    city: 'Brockport',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.2128,
    longitude: -77.9368,
    postal_code: '14420'
  },
  '14422': {
    country: 'US',
    city: 'Byron',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 43.0738,
    longitude: -78.0629,
    postal_code: '14422'
  },
  '14423': {
    country: 'US',
    city: 'Caledonia',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.9567,
    longitude: -77.8493,
    postal_code: '14423'
  },
  '14424': {
    country: 'US',
    city: 'Canandaigua',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.8689,
    longitude: -77.2846,
    postal_code: '14424'
  },
  '14425': {
    country: 'US',
    city: 'Farmington',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.958,
    longitude: -77.3083,
    postal_code: '14425'
  },
  '14427': {
    country: 'US',
    city: 'Castile',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.6359,
    longitude: -78.0547,
    postal_code: '14427'
  },
  '14428': {
    country: 'US',
    city: 'Churchville',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.0749,
    longitude: -77.835,
    postal_code: '14428'
  },
  '14429': {
    country: 'US',
    city: 'Clarendon',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.1934,
    longitude: -78.0647,
    postal_code: '14429'
  },
  '14430': {
    country: 'US',
    city: 'Clarkson',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.2331,
    longitude: -77.9275,
    postal_code: '14430'
  },
  '14432': {
    country: 'US',
    city: 'Clifton Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.9632,
    longitude: -77.144,
    postal_code: '14432'
  },
  '14433': {
    country: 'US',
    city: 'Clyde',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.0855,
    longitude: -76.8725,
    postal_code: '14433'
  },
  '14435': {
    country: 'US',
    city: 'Conesus',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.7216,
    longitude: -77.6747,
    postal_code: '14435'
  },
  '14437': {
    country: 'US',
    city: 'Dansville',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.57,
    longitude: -77.7109,
    postal_code: '14437'
  },
  '14441': {
    country: 'US',
    city: 'Dresden',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.6846,
    longitude: -76.9564,
    postal_code: '14441'
  },
  '14443': {
    country: 'US',
    city: 'East Bloomfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.901,
    longitude: -77.4233,
    postal_code: '14443'
  },
  '14445': {
    country: 'US',
    city: 'East Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1128,
    longitude: -77.4906,
    postal_code: '14445'
  },
  '14449': {
    country: 'US',
    city: 'East Williamson',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2354,
    longitude: -77.1376,
    postal_code: '14449'
  },
  '14450': {
    country: 'US',
    city: 'Fairport',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.0892,
    longitude: -77.436,
    postal_code: '14450'
  },
  '14452': {
    country: 'US',
    city: 'Fancher',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.2448,
    longitude: -78.0911,
    postal_code: '14452'
  },
  '14453': {
    country: 'US',
    city: 'Fishers',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 43.0109,
    longitude: -77.4705,
    postal_code: '14453'
  },
  '14454': {
    country: 'US',
    city: 'Geneseo',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.7938,
    longitude: -77.7996,
    postal_code: '14454'
  },
  '14456': {
    country: 'US',
    city: 'Geneva',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.8637,
    longitude: -76.9913,
    postal_code: '14456'
  },
  '14461': {
    country: 'US',
    city: 'Gorham',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.799,
    longitude: -77.1316,
    postal_code: '14461'
  },
  '14462': {
    country: 'US',
    city: 'Groveland',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.676,
    longitude: -77.7573,
    postal_code: '14462'
  },
  '14463': {
    country: 'US',
    city: 'Hall',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.7966,
    longitude: -77.0639,
    postal_code: '14463'
  },
  '14464': {
    country: 'US',
    city: 'Hamlin',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.3076,
    longitude: -77.927,
    postal_code: '14464'
  },
  '14466': {
    country: 'US',
    city: 'Hemlock',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.78,
    longitude: -77.582,
    postal_code: '14466'
  },
  '14467': {
    country: 'US',
    city: 'Henrietta',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.0483,
    longitude: -77.6122,
    postal_code: '14467'
  },
  '14468': {
    country: 'US',
    city: 'Hilton',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.2923,
    longitude: -77.7905,
    postal_code: '14468'
  },
  '14469': {
    country: 'US',
    city: 'Bloomfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.8851,
    longitude: -77.46,
    postal_code: '14469'
  },
  '14470': {
    country: 'US',
    city: 'Holley',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.2159,
    longitude: -78.0731,
    postal_code: '14470'
  },
  '14471': {
    country: 'US',
    city: 'Honeoye',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.7901,
    longitude: -77.5169,
    postal_code: '14471'
  },
  '14472': {
    country: 'US',
    city: 'Honeoye Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 42.9695,
    longitude: -77.5781,
    postal_code: '14472'
  },
  '14475': {
    country: 'US',
    city: 'Ionia',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.938,
    longitude: -77.5009,
    postal_code: '14475'
  },
  '14476': {
    country: 'US',
    city: 'Kendall',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.3284,
    longitude: -78.0304,
    postal_code: '14476'
  },
  '14477': {
    country: 'US',
    city: 'Kent',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.3341,
    longitude: -78.1355,
    postal_code: '14477'
  },
  '14478': {
    country: 'US',
    city: 'Keuka Park',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.5708,
    longitude: -77.1226,
    postal_code: '14478'
  },
  '14479': {
    country: 'US',
    city: 'Knowlesville',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.2363,
    longitude: -78.3138,
    postal_code: '14479'
  },
  '14480': {
    country: 'US',
    city: 'Lakeville',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.8296,
    longitude: -77.7149,
    postal_code: '14480'
  },
  '14481': {
    country: 'US',
    city: 'Leicester',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.7739,
    longitude: -77.899,
    postal_code: '14481'
  },
  '14482': {
    country: 'US',
    city: 'Le Roy',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 42.9774,
    longitude: -77.9851,
    postal_code: '14482'
  },
  '14485': {
    country: 'US',
    city: 'Lima',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.9012,
    longitude: -77.6083,
    postal_code: '14485'
  },
  '14486': {
    country: 'US',
    city: 'Linwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 42.8943,
    longitude: -77.9216,
    postal_code: '14486'
  },
  '14487': {
    country: 'US',
    city: 'Livonia',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.8135,
    longitude: -77.6635,
    postal_code: '14487'
  },
  '14488': {
    country: 'US',
    city: 'Livonia Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.8215,
    longitude: -77.6386,
    postal_code: '14488'
  },
  '14489': {
    country: 'US',
    city: 'Lyons',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.0777,
    longitude: -76.9896,
    postal_code: '14489'
  },
  '14502': {
    country: 'US',
    city: 'Macedon',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.0784,
    longitude: -77.3372,
    postal_code: '14502'
  },
  '14504': {
    country: 'US',
    city: 'Manchester',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.9689,
    longitude: -77.2332,
    postal_code: '14504'
  },
  '14505': {
    country: 'US',
    city: 'Marion',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.1546,
    longitude: -77.1863,
    postal_code: '14505'
  },
  '14506': {
    country: 'US',
    city: 'Mendon',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 42.9953,
    longitude: -77.5001,
    postal_code: '14506'
  },
  '14507': {
    country: 'US',
    city: 'Middlesex',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.6976,
    longitude: -77.2805,
    postal_code: '14507'
  },
  '14508': {
    country: 'US',
    city: 'Morton',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.3281,
    longitude: -77.9953,
    postal_code: '14508'
  },
  '14510': {
    country: 'US',
    city: 'Mount Morris',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.6835,
    longitude: -77.8664,
    postal_code: '14510'
  },
  '14511': {
    country: 'US',
    city: 'Mumford',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 42.9928,
    longitude: -77.8603,
    postal_code: '14511'
  },
  '14512': {
    country: 'US',
    city: 'Naples',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.6404,
    longitude: -77.3901,
    postal_code: '14512'
  },
  '14513': {
    country: 'US',
    city: 'Newark',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.0519,
    longitude: -77.0946,
    postal_code: '14513'
  },
  '14514': {
    country: 'US',
    city: 'North Chili',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1186,
    longitude: -77.8005,
    postal_code: '14514'
  },
  '14515': {
    country: 'US',
    city: 'North Greece',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.2578,
    longitude: -77.7351,
    postal_code: '14515'
  },
  '14516': {
    country: 'US',
    city: 'North Rose',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.1964,
    longitude: -76.9152,
    postal_code: '14516'
  },
  '14517': {
    country: 'US',
    city: 'Nunda',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.5867,
    longitude: -77.918,
    postal_code: '14517'
  },
  '14518': {
    country: 'US',
    city: 'Oaks Corners',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.9319,
    longitude: -77.0118,
    postal_code: '14518'
  },
  '14519': {
    country: 'US',
    city: 'Ontario',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2291,
    longitude: -77.3088,
    postal_code: '14519'
  },
  '14520': {
    country: 'US',
    city: 'Ontario Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2259,
    longitude: -77.3058,
    postal_code: '14520'
  },
  '14521': {
    country: 'US',
    city: 'Ovid',
    state: 'New York',
    state_short: 'NY',
    county: 'Seneca',
    latitude: 42.6898,
    longitude: -76.7941,
    postal_code: '14521'
  },
  '14522': {
    country: 'US',
    city: 'Palmyra',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.0622,
    longitude: -77.2218,
    postal_code: '14522'
  },
  '14525': {
    country: 'US',
    city: 'Pavilion',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 42.9102,
    longitude: -78.0269,
    postal_code: '14525'
  },
  '14526': {
    country: 'US',
    city: 'Penfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1396,
    longitude: -77.456,
    postal_code: '14526'
  },
  '14527': {
    country: 'US',
    city: 'Penn Yan',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.6645,
    longitude: -77.0569,
    postal_code: '14527'
  },
  '14529': {
    country: 'US',
    city: 'Perkinsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.5398,
    longitude: -77.6283,
    postal_code: '14529'
  },
  '14530': {
    country: 'US',
    city: 'Perry',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.7229,
    longitude: -78.0059,
    postal_code: '14530'
  },
  '14532': {
    country: 'US',
    city: 'Phelps',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.9582,
    longitude: -77.0473,
    postal_code: '14532'
  },
  '14533': {
    country: 'US',
    city: 'Piffard',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.8435,
    longitude: -77.8962,
    postal_code: '14533'
  },
  '14534': {
    country: 'US',
    city: 'Pittsford',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.0695,
    longitude: -77.5141,
    postal_code: '14534'
  },
  '14536': {
    country: 'US',
    city: 'Portageville',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.557,
    longitude: -78.0856,
    postal_code: '14536'
  },
  '14537': {
    country: 'US',
    city: 'Port Gibson',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 43.0345,
    longitude: -77.1575,
    postal_code: '14537'
  },
  '14538': {
    country: 'US',
    city: 'Pultneyville',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2836,
    longitude: -77.142,
    postal_code: '14538'
  },
  '14539': {
    country: 'US',
    city: 'Retsof',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.8343,
    longitude: -77.8779,
    postal_code: '14539'
  },
  '14541': {
    country: 'US',
    city: 'Romulus',
    state: 'New York',
    state_short: 'NY',
    county: 'Seneca',
    latitude: 42.7497,
    longitude: -76.8449,
    postal_code: '14541'
  },
  '14542': {
    country: 'US',
    city: 'Rose',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.1448,
    longitude: -76.8608,
    postal_code: '14542'
  },
  '14543': {
    country: 'US',
    city: 'Rush',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 42.9966,
    longitude: -77.6665,
    postal_code: '14543'
  },
  '14544': {
    country: 'US',
    city: 'Rushville',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.7597,
    longitude: -77.2395,
    postal_code: '14544'
  },
  '14545': {
    country: 'US',
    city: 'Scottsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.6649,
    longitude: -77.701,
    postal_code: '14545'
  },
  '14546': {
    country: 'US',
    city: 'Scottsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.0246,
    longitude: -77.7743,
    postal_code: '14546'
  },
  '14547': {
    country: 'US',
    city: 'Seneca Castle',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.887,
    longitude: -77.0961,
    postal_code: '14547'
  },
  '14548': {
    country: 'US',
    city: 'Shortsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.9761,
    longitude: -77.2439,
    postal_code: '14548'
  },
  '14549': {
    country: 'US',
    city: 'Silver Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.6929,
    longitude: -78.0224,
    postal_code: '14549'
  },
  '14550': {
    country: 'US',
    city: 'Silver Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.6742,
    longitude: -78.0845,
    postal_code: '14550'
  },
  '14551': {
    country: 'US',
    city: 'Sodus',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2217,
    longitude: -77.0514,
    postal_code: '14551'
  },
  '14555': {
    country: 'US',
    city: 'Sodus Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2546,
    longitude: -76.9835,
    postal_code: '14555'
  },
  '14556': {
    country: 'US',
    city: 'Sonyea',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.679,
    longitude: -77.8272,
    postal_code: '14556'
  },
  '14557': {
    country: 'US',
    city: 'South Byron',
    state: 'New York',
    state_short: 'NY',
    county: 'Genesee',
    latitude: 43.0416,
    longitude: -78.0573,
    postal_code: '14557'
  },
  '14558': {
    country: 'US',
    city: 'South Lima',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.8554,
    longitude: -77.6876,
    postal_code: '14558'
  },
  '14559': {
    country: 'US',
    city: 'Spencerport',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1895,
    longitude: -77.8043,
    postal_code: '14559'
  },
  '14560': {
    country: 'US',
    city: 'Springwater',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.6776,
    longitude: -77.5775,
    postal_code: '14560'
  },
  '14561': {
    country: 'US',
    city: 'Stanley',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.8303,
    longitude: -77.1207,
    postal_code: '14561'
  },
  '14563': {
    country: 'US',
    city: 'Union Hill',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2228,
    longitude: -77.3717,
    postal_code: '14563'
  },
  '14564': {
    country: 'US',
    city: 'Victor',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.9866,
    longitude: -77.418,
    postal_code: '14564'
  },
  '14568': {
    country: 'US',
    city: 'Walworth',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.1402,
    longitude: -77.2858,
    postal_code: '14568'
  },
  '14569': {
    country: 'US',
    city: 'Warsaw',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.741,
    longitude: -78.1429,
    postal_code: '14569'
  },
  '14571': {
    country: 'US',
    city: 'Waterport',
    state: 'New York',
    state_short: 'NY',
    county: 'Orleans',
    latitude: 43.3326,
    longitude: -78.243,
    postal_code: '14571'
  },
  '14572': {
    country: 'US',
    city: 'Wayland',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.5593,
    longitude: -77.5906,
    postal_code: '14572'
  },
  '14580': {
    country: 'US',
    city: 'Webster',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.2196,
    longitude: -77.4616,
    postal_code: '14580'
  },
  '14585': {
    country: 'US',
    city: 'West Bloomfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Ontario',
    latitude: 42.9063,
    longitude: -77.5531,
    postal_code: '14585'
  },
  '14586': {
    country: 'US',
    city: 'West Henrietta',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.0397,
    longitude: -77.6871,
    postal_code: '14586'
  },
  '14588': {
    country: 'US',
    city: 'Willard',
    state: 'New York',
    state_short: 'NY',
    county: 'Seneca',
    latitude: 42.6835,
    longitude: -76.8724,
    postal_code: '14588'
  },
  '14589': {
    country: 'US',
    city: 'Williamson',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2421,
    longitude: -77.17,
    postal_code: '14589'
  },
  '14590': {
    country: 'US',
    city: 'Wolcott',
    state: 'New York',
    state_short: 'NY',
    county: 'Wayne',
    latitude: 43.2341,
    longitude: -76.8217,
    postal_code: '14590'
  },
  '14591': {
    country: 'US',
    city: 'Wyoming',
    state: 'New York',
    state_short: 'NY',
    county: 'Wyoming',
    latitude: 42.8317,
    longitude: -78.0833,
    postal_code: '14591'
  },
  '14592': {
    country: 'US',
    city: 'York',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.8757,
    longitude: -77.8835,
    postal_code: '14592'
  },
  '14602': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14602'
  },
  '14603': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1616,
    longitude: -77.6068,
    postal_code: '14603'
  },
  '14604': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1577,
    longitude: -77.608,
    postal_code: '14604'
  },
  '14605': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1698,
    longitude: -77.6007,
    postal_code: '14605'
  },
  '14606': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1685,
    longitude: -77.6845,
    postal_code: '14606'
  },
  '14607': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1501,
    longitude: -77.589,
    postal_code: '14607'
  },
  '14608': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1521,
    longitude: -77.6258,
    postal_code: '14608'
  },
  '14609': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.174,
    longitude: -77.5637,
    postal_code: '14609'
  },
  '14610': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1452,
    longitude: -77.5495,
    postal_code: '14610'
  },
  '14611': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1484,
    longitude: -77.6394,
    postal_code: '14611'
  },
  '14612': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.2566,
    longitude: -77.6652,
    postal_code: '14612'
  },
  '14613': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1831,
    longitude: -77.6393,
    postal_code: '14613'
  },
  '14614': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1558,
    longitude: -77.6142,
    postal_code: '14614'
  },
  '14615': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.2058,
    longitude: -77.6521,
    postal_code: '14615'
  },
  '14616': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.2346,
    longitude: -77.6577,
    postal_code: '14616'
  },
  '14617': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.2203,
    longitude: -77.5994,
    postal_code: '14617'
  },
  '14618': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1122,
    longitude: -77.5618,
    postal_code: '14618'
  },
  '14619': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1367,
    longitude: -77.6481,
    postal_code: '14619'
  },
  '14620': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1317,
    longitude: -77.6062,
    postal_code: '14620'
  },
  '14621': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1834,
    longitude: -77.6043,
    postal_code: '14621'
  },
  '14622': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.214,
    longitude: -77.5555,
    postal_code: '14622'
  },
  '14623': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.0834,
    longitude: -77.6344,
    postal_code: '14623'
  },
  '14624': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1216,
    longitude: -77.7311,
    postal_code: '14624'
  },
  '14625': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1522,
    longitude: -77.5057,
    postal_code: '14625'
  },
  '14626': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.2126,
    longitude: -77.704,
    postal_code: '14626'
  },
  '14627': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1275,
    longitude: -77.6277,
    postal_code: '14627'
  },
  '14638': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14638'
  },
  '14639': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14639'
  },
  '14642': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14642'
  },
  '14643': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1548,
    longitude: -77.6156,
    postal_code: '14643'
  },
  '14644': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14644'
  },
  '14646': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14646'
  },
  '14647': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1548,
    longitude: -77.6156,
    postal_code: '14647'
  },
  '14649': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14649'
  },
  '14650': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1548,
    longitude: -77.6156,
    postal_code: '14650'
  },
  '14651': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.1548,
    longitude: -77.6156,
    postal_code: '14651'
  },
  '14652': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14652'
  },
  '14653': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14653'
  },
  '14692': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14692'
  },
  '14694': {
    country: 'US',
    city: 'Rochester',
    state: 'New York',
    state_short: 'NY',
    county: 'Monroe',
    latitude: 43.286,
    longitude: -77.6843,
    postal_code: '14694'
  },
  '14701': {
    country: 'US',
    city: 'Jamestown',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.0928,
    longitude: -79.244,
    postal_code: '14701'
  },
  '14702': {
    country: 'US',
    city: 'Jamestown',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.097,
    longitude: -79.2353,
    postal_code: '14702'
  },
  '14706': {
    country: 'US',
    city: 'Allegany',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.0918,
    longitude: -78.4999,
    postal_code: '14706'
  },
  '14707': {
    country: 'US',
    city: 'Allentown',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.0737,
    longitude: -78.0594,
    postal_code: '14707'
  },
  '14708': {
    country: 'US',
    city: 'Alma',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.0126,
    longitude: -78.0578,
    postal_code: '14708'
  },
  '14709': {
    country: 'US',
    city: 'Angelica',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.3263,
    longitude: -77.9947,
    postal_code: '14709'
  },
  '14710': {
    country: 'US',
    city: 'Ashville',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.1084,
    longitude: -79.4056,
    postal_code: '14710'
  },
  '14711': {
    country: 'US',
    city: 'Belfast',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.32,
    longitude: -78.0943,
    postal_code: '14711'
  },
  '14712': {
    country: 'US',
    city: 'Bemus Point',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.1513,
    longitude: -79.3581,
    postal_code: '14712'
  },
  '14714': {
    country: 'US',
    city: 'Black Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.2855,
    longitude: -78.2312,
    postal_code: '14714'
  },
  '14715': {
    country: 'US',
    city: 'Bolivar',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.0704,
    longitude: -78.1448,
    postal_code: '14715'
  },
  '14716': {
    country: 'US',
    city: 'Brocton',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.394,
    longitude: -79.4344,
    postal_code: '14716'
  },
  '14717': {
    country: 'US',
    city: 'Caneadea',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.364,
    longitude: -78.184,
    postal_code: '14717'
  },
  '14718': {
    country: 'US',
    city: 'Cassadaga',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.3504,
    longitude: -79.2993,
    postal_code: '14718'
  },
  '14719': {
    country: 'US',
    city: 'Cattaraugus',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.3333,
    longitude: -78.8885,
    postal_code: '14719'
  },
  '14720': {
    country: 'US',
    city: 'Celoron',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.1095,
    longitude: -79.2831,
    postal_code: '14720'
  },
  '14721': {
    country: 'US',
    city: 'Ceres',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.0137,
    longitude: -78.2648,
    postal_code: '14721'
  },
  '14722': {
    country: 'US',
    city: 'Chautauqua',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.2098,
    longitude: -79.4667,
    postal_code: '14722'
  },
  '14723': {
    country: 'US',
    city: 'Cherry Creek',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.3127,
    longitude: -79.1203,
    postal_code: '14723'
  },
  '14724': {
    country: 'US',
    city: 'Clymer',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.0557,
    longitude: -79.6685,
    postal_code: '14724'
  },
  '14726': {
    country: 'US',
    city: 'Conewango Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.2625,
    longitude: -79.022,
    postal_code: '14726'
  },
  '14727': {
    country: 'US',
    city: 'Cuba',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.1882,
    longitude: -78.2751,
    postal_code: '14727'
  },
  '14728': {
    country: 'US',
    city: 'Dewittville',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.2394,
    longitude: -79.4193,
    postal_code: '14728'
  },
  '14729': {
    country: 'US',
    city: 'East Otto',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.3971,
    longitude: -78.7432,
    postal_code: '14729'
  },
  '14730': {
    country: 'US',
    city: 'East Randolph',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.1747,
    longitude: -78.9473,
    postal_code: '14730'
  },
  '14731': {
    country: 'US',
    city: 'Ellicottville',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.2959,
    longitude: -78.6606,
    postal_code: '14731'
  },
  '14732': {
    country: 'US',
    city: 'Ellington',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.2291,
    longitude: -79.1135,
    postal_code: '14732'
  },
  '14733': {
    country: 'US',
    city: 'Falconer',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.1239,
    longitude: -79.1895,
    postal_code: '14733'
  },
  '14735': {
    country: 'US',
    city: 'Fillmore',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.4508,
    longitude: -78.1043,
    postal_code: '14735'
  },
  '14736': {
    country: 'US',
    city: 'Findley Lake',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.1204,
    longitude: -79.7349,
    postal_code: '14736'
  },
  '14737': {
    country: 'US',
    city: 'Franklinville',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.3388,
    longitude: -78.44,
    postal_code: '14737'
  },
  '14738': {
    country: 'US',
    city: 'Frewsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.0528,
    longitude: -79.1318,
    postal_code: '14738'
  },
  '14739': {
    country: 'US',
    city: 'Friendship',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.1907,
    longitude: -78.1359,
    postal_code: '14739'
  },
  '14740': {
    country: 'US',
    city: 'Gerry',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.2147,
    longitude: -79.1649,
    postal_code: '14740'
  },
  '14741': {
    country: 'US',
    city: 'Great Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.2083,
    longitude: -78.6208,
    postal_code: '14741'
  },
  '14742': {
    country: 'US',
    city: 'Greenhurst',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.1205,
    longitude: -79.3096,
    postal_code: '14742'
  },
  '14743': {
    country: 'US',
    city: 'Hinsdale',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.1979,
    longitude: -78.4159,
    postal_code: '14743'
  },
  '14744': {
    country: 'US',
    city: 'Houghton',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.4228,
    longitude: -78.2063,
    postal_code: '14744'
  },
  '14745': {
    country: 'US',
    city: 'Hume',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.4777,
    longitude: -78.1403,
    postal_code: '14745'
  },
  '14747': {
    country: 'US',
    city: 'Kennedy',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.1508,
    longitude: -79.0964,
    postal_code: '14747'
  },
  '14748': {
    country: 'US',
    city: 'Kill Buck',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.145,
    longitude: -78.6466,
    postal_code: '14748'
  },
  '14750': {
    country: 'US',
    city: 'Lakewood',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.0973,
    longitude: -79.3291,
    postal_code: '14750'
  },
  '14751': {
    country: 'US',
    city: 'Leon',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.2983,
    longitude: -79.0062,
    postal_code: '14751'
  },
  '14752': {
    country: 'US',
    city: 'Lily Dale',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.3524,
    longitude: -79.3235,
    postal_code: '14752'
  },
  '14753': {
    country: 'US',
    city: 'Limestone',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.0639,
    longitude: -78.632,
    postal_code: '14753'
  },
  '14754': {
    country: 'US',
    city: 'Little Genesee',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.0319,
    longitude: -78.2097,
    postal_code: '14754'
  },
  '14755': {
    country: 'US',
    city: 'Little Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.2541,
    longitude: -78.8093,
    postal_code: '14755'
  },
  '14756': {
    country: 'US',
    city: 'Maple Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.1967,
    longitude: -79.4239,
    postal_code: '14756'
  },
  '14757': {
    country: 'US',
    city: 'Mayville',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.2409,
    longitude: -79.4963,
    postal_code: '14757'
  },
  '14758': {
    country: 'US',
    city: 'Niobe',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.0126,
    longitude: -79.4495,
    postal_code: '14758'
  },
  '14760': {
    country: 'US',
    city: 'Olean',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.0821,
    longitude: -78.426,
    postal_code: '14760'
  },
  '14766': {
    country: 'US',
    city: 'Otto',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.3575,
    longitude: -78.8073,
    postal_code: '14766'
  },
  '14767': {
    country: 'US',
    city: 'Panama',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.057,
    longitude: -79.4815,
    postal_code: '14767'
  },
  '14769': {
    country: 'US',
    city: 'Portland',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.3858,
    longitude: -79.4589,
    postal_code: '14769'
  },
  '14770': {
    country: 'US',
    city: 'Portville',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.0273,
    longitude: -78.3314,
    postal_code: '14770'
  },
  '14772': {
    country: 'US',
    city: 'Randolph',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.1631,
    longitude: -78.96,
    postal_code: '14772'
  },
  '14774': {
    country: 'US',
    city: 'Richburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.0884,
    longitude: -78.1533,
    postal_code: '14774'
  },
  '14775': {
    country: 'US',
    city: 'Ripley',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.2482,
    longitude: -79.7121,
    postal_code: '14775'
  },
  '14777': {
    country: 'US',
    city: 'Rushford',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.3923,
    longitude: -78.2536,
    postal_code: '14777'
  },
  '14778': {
    country: 'US',
    city: 'Saint Bonaventure',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.0803,
    longitude: -78.475,
    postal_code: '14778'
  },
  '14779': {
    country: 'US',
    city: 'Salamanca',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.1604,
    longitude: -78.7304,
    postal_code: '14779'
  },
  '14781': {
    country: 'US',
    city: 'Sherman',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.1631,
    longitude: -79.5857,
    postal_code: '14781'
  },
  '14782': {
    country: 'US',
    city: 'Sinclairville',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.2455,
    longitude: -79.2673,
    postal_code: '14782'
  },
  '14783': {
    country: 'US',
    city: 'Steamburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.082,
    longitude: -78.9177,
    postal_code: '14783'
  },
  '14784': {
    country: 'US',
    city: 'Stockton',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.3182,
    longitude: -79.3758,
    postal_code: '14784'
  },
  '14785': {
    country: 'US',
    city: 'Stow',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.1557,
    longitude: -79.4122,
    postal_code: '14785'
  },
  '14786': {
    country: 'US',
    city: 'West Clarksville',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.1227,
    longitude: -78.2213,
    postal_code: '14786'
  },
  '14787': {
    country: 'US',
    city: 'Westfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Chautauqua',
    latitude: 42.322,
    longitude: -79.5726,
    postal_code: '14787'
  },
  '14788': {
    country: 'US',
    city: 'Westons Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Cattaraugus',
    latitude: 42.0621,
    longitude: -78.378,
    postal_code: '14788'
  },
  '14801': {
    country: 'US',
    city: 'Addison',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.0983,
    longitude: -77.266,
    postal_code: '14801'
  },
  '14802': {
    country: 'US',
    city: 'Alfred',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.2534,
    longitude: -77.7893,
    postal_code: '14802'
  },
  '14803': {
    country: 'US',
    city: 'Alfred Station',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.2558,
    longitude: -77.7781,
    postal_code: '14803'
  },
  '14804': {
    country: 'US',
    city: 'Almond',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.316,
    longitude: -77.778,
    postal_code: '14804'
  },
  '14805': {
    country: 'US',
    city: 'Alpine',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.351,
    longitude: -76.7348,
    postal_code: '14805'
  },
  '14806': {
    country: 'US',
    city: 'Andover',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.1575,
    longitude: -77.792,
    postal_code: '14806'
  },
  '14807': {
    country: 'US',
    city: 'Arkport',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.4225,
    longitude: -77.6918,
    postal_code: '14807'
  },
  '14808': {
    country: 'US',
    city: 'Atlanta',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.5596,
    longitude: -77.4669,
    postal_code: '14808'
  },
  '14809': {
    country: 'US',
    city: 'Avoca',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.3679,
    longitude: -77.4641,
    postal_code: '14809'
  },
  '14810': {
    country: 'US',
    city: 'Bath',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.3575,
    longitude: -77.3028,
    postal_code: '14810'
  },
  '14812': {
    country: 'US',
    city: 'Beaver Dams',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.2798,
    longitude: -76.972,
    postal_code: '14812'
  },
  '14813': {
    country: 'US',
    city: 'Belmont',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.2334,
    longitude: -78.011,
    postal_code: '14813'
  },
  '14814': {
    country: 'US',
    city: 'Big Flats',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.1455,
    longitude: -76.9527,
    postal_code: '14814'
  },
  '14815': {
    country: 'US',
    city: 'Bradford',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.3825,
    longitude: -77.0913,
    postal_code: '14815'
  },
  '14816': {
    country: 'US',
    city: 'Breesport',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.1939,
    longitude: -76.7361,
    postal_code: '14816'
  },
  '14817': {
    country: 'US',
    city: 'Brooktondale',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.3765,
    longitude: -76.3668,
    postal_code: '14817'
  },
  '14818': {
    country: 'US',
    city: 'Burdett',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.4394,
    longitude: -76.8292,
    postal_code: '14818'
  },
  '14819': {
    country: 'US',
    city: 'Cameron',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.2128,
    longitude: -77.4403,
    postal_code: '14819'
  },
  '14820': {
    country: 'US',
    city: 'Cameron Mills',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.1925,
    longitude: -77.365,
    postal_code: '14820'
  },
  '14821': {
    country: 'US',
    city: 'Campbell',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.2386,
    longitude: -77.2066,
    postal_code: '14821'
  },
  '14822': {
    country: 'US',
    city: 'Canaseraga',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.4585,
    longitude: -77.7954,
    postal_code: '14822'
  },
  '14823': {
    country: 'US',
    city: 'Canisteo',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.2635,
    longitude: -77.5897,
    postal_code: '14823'
  },
  '14824': {
    country: 'US',
    city: 'Cayuta',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.2774,
    longitude: -76.6974,
    postal_code: '14824'
  },
  '14825': {
    country: 'US',
    city: 'Chemung',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.0392,
    longitude: -76.6202,
    postal_code: '14825'
  },
  '14826': {
    country: 'US',
    city: 'Cohocton',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.5003,
    longitude: -77.4998,
    postal_code: '14826'
  },
  '14827': {
    country: 'US',
    city: 'Coopers Plains',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.1785,
    longitude: -77.1414,
    postal_code: '14827'
  },
  '14830': {
    country: 'US',
    city: 'Corning',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.1383,
    longitude: -77.0475,
    postal_code: '14830'
  },
  '14831': {
    country: 'US',
    city: 'Corning',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.1429,
    longitude: -77.0547,
    postal_code: '14831'
  },
  '14836': {
    country: 'US',
    city: 'Dalton',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.5449,
    longitude: -77.9289,
    postal_code: '14836'
  },
  '14837': {
    country: 'US',
    city: 'Dundee',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.5053,
    longitude: -77.0028,
    postal_code: '14837'
  },
  '14838': {
    country: 'US',
    city: 'Erin',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.1859,
    longitude: -76.6819,
    postal_code: '14838'
  },
  '14839': {
    country: 'US',
    city: 'Greenwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.1398,
    longitude: -77.636,
    postal_code: '14839'
  },
  '14840': {
    country: 'US',
    city: 'Hammondsport',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.4312,
    longitude: -77.1977,
    postal_code: '14840'
  },
  '14841': {
    country: 'US',
    city: 'Hector',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.4966,
    longitude: -76.8786,
    postal_code: '14841'
  },
  '14842': {
    country: 'US',
    city: 'Himrod',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.5945,
    longitude: -76.9508,
    postal_code: '14842'
  },
  '14843': {
    country: 'US',
    city: 'Hornell',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.3274,
    longitude: -77.6569,
    postal_code: '14843'
  },
  '14845': {
    country: 'US',
    city: 'Horseheads',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.1805,
    longitude: -76.8345,
    postal_code: '14845'
  },
  '14846': {
    country: 'US',
    city: 'Hunt',
    state: 'New York',
    state_short: 'NY',
    county: 'Livingston',
    latitude: 42.5388,
    longitude: -77.9818,
    postal_code: '14846'
  },
  '14847': {
    country: 'US',
    city: 'Interlaken',
    state: 'New York',
    state_short: 'NY',
    county: 'Seneca',
    latitude: 42.6165,
    longitude: -76.7268,
    postal_code: '14847'
  },
  '14850': {
    country: 'US',
    city: 'Ithaca',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.4406,
    longitude: -76.4966,
    postal_code: '14850'
  },
  '14851': {
    country: 'US',
    city: 'Ithaca',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.4607,
    longitude: -76.5054,
    postal_code: '14851'
  },
  '14852': {
    country: 'US',
    city: 'Ithaca',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.4451,
    longitude: -76.4672,
    postal_code: '14852'
  },
  '14853': {
    country: 'US',
    city: 'Ithaca',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.4474,
    longitude: -76.4837,
    postal_code: '14853'
  },
  '14854': {
    country: 'US',
    city: 'Jacksonville',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.5084,
    longitude: -76.6149,
    postal_code: '14854'
  },
  '14855': {
    country: 'US',
    city: 'Jasper',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.129,
    longitude: -77.4999,
    postal_code: '14855'
  },
  '14856': {
    country: 'US',
    city: 'Kanona',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.3742,
    longitude: -77.3648,
    postal_code: '14856'
  },
  '14857': {
    country: 'US',
    city: 'Lakemont',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.5126,
    longitude: -76.927,
    postal_code: '14857'
  },
  '14858': {
    country: 'US',
    city: 'Lindley',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.0284,
    longitude: -77.1397,
    postal_code: '14858'
  },
  '14859': {
    country: 'US',
    city: 'Lockwood',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.1149,
    longitude: -76.5366,
    postal_code: '14859'
  },
  '14860': {
    country: 'US',
    city: 'Lodi',
    state: 'New York',
    state_short: 'NY',
    county: 'Seneca',
    latitude: 42.5966,
    longitude: -76.8339,
    postal_code: '14860'
  },
  '14861': {
    country: 'US',
    city: 'Lowman',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.0694,
    longitude: -76.693,
    postal_code: '14861'
  },
  '14863': {
    country: 'US',
    city: 'Mecklenburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.4516,
    longitude: -76.7067,
    postal_code: '14863'
  },
  '14864': {
    country: 'US',
    city: 'Millport',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.2581,
    longitude: -76.8392,
    postal_code: '14864'
  },
  '14865': {
    country: 'US',
    city: 'Montour Falls',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.3437,
    longitude: -76.8396,
    postal_code: '14865'
  },
  '14867': {
    country: 'US',
    city: 'Newfield',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.3621,
    longitude: -76.592,
    postal_code: '14867'
  },
  '14869': {
    country: 'US',
    city: 'Odessa',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.3609,
    longitude: -76.7717,
    postal_code: '14869'
  },
  '14870': {
    country: 'US',
    city: 'Painted Post',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.171,
    longitude: -77.1194,
    postal_code: '14870'
  },
  '14871': {
    country: 'US',
    city: 'Pine City',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.0419,
    longitude: -76.8815,
    postal_code: '14871'
  },
  '14872': {
    country: 'US',
    city: 'Pine Valley',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.2348,
    longitude: -76.8652,
    postal_code: '14872'
  },
  '14873': {
    country: 'US',
    city: 'Prattsburgh',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.5224,
    longitude: -77.2983,
    postal_code: '14873'
  },
  '14874': {
    country: 'US',
    city: 'Pulteney',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.5233,
    longitude: -77.1691,
    postal_code: '14874'
  },
  '14876': {
    country: 'US',
    city: 'Reading Center',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.4297,
    longitude: -76.9258,
    postal_code: '14876'
  },
  '14877': {
    country: 'US',
    city: 'Rexville',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.0726,
    longitude: -77.6767,
    postal_code: '14877'
  },
  '14878': {
    country: 'US',
    city: 'Rock Stream',
    state: 'New York',
    state_short: 'NY',
    county: 'Yates',
    latitude: 42.4485,
    longitude: -76.9364,
    postal_code: '14878'
  },
  '14879': {
    country: 'US',
    city: 'Savona',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.3041,
    longitude: -77.2083,
    postal_code: '14879'
  },
  '14880': {
    country: 'US',
    city: 'Scio',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.1697,
    longitude: -77.99,
    postal_code: '14880'
  },
  '14881': {
    country: 'US',
    city: 'Slaterville Springs',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.4025,
    longitude: -76.3608,
    postal_code: '14881'
  },
  '14882': {
    country: 'US',
    city: 'Lansing',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.5645,
    longitude: -76.5375,
    postal_code: '14882'
  },
  '14883': {
    country: 'US',
    city: 'Spencer',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.2467,
    longitude: -76.4899,
    postal_code: '14883'
  },
  '14884': {
    country: 'US',
    city: 'Swain',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.4773,
    longitude: -77.889,
    postal_code: '14884'
  },
  '14885': {
    country: 'US',
    city: 'Troupsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.0501,
    longitude: -77.5502,
    postal_code: '14885'
  },
  '14886': {
    country: 'US',
    city: 'Trumansburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Tompkins',
    latitude: 42.521,
    longitude: -76.6681,
    postal_code: '14886'
  },
  '14887': {
    country: 'US',
    city: 'Tyrone',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.3994,
    longitude: -77.0268,
    postal_code: '14887'
  },
  '14889': {
    country: 'US',
    city: 'Van Etten',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.2085,
    longitude: -76.5717,
    postal_code: '14889'
  },
  '14891': {
    country: 'US',
    city: 'Watkins Glen',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.3771,
    longitude: -76.9022,
    postal_code: '14891'
  },
  '14892': {
    country: 'US',
    city: 'Waverly',
    state: 'New York',
    state_short: 'NY',
    county: 'Tioga',
    latitude: 42.0172,
    longitude: -76.5333,
    postal_code: '14892'
  },
  '14893': {
    country: 'US',
    city: 'Wayne',
    state: 'New York',
    state_short: 'NY',
    county: 'Schuyler',
    latitude: 42.4741,
    longitude: -77.0977,
    postal_code: '14893'
  },
  '14894': {
    country: 'US',
    city: 'Wellsburg',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.0273,
    longitude: -76.7723,
    postal_code: '14894'
  },
  '14895': {
    country: 'US',
    city: 'Wellsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.1108,
    longitude: -77.9419,
    postal_code: '14895'
  },
  '14897': {
    country: 'US',
    city: 'Whitesville',
    state: 'New York',
    state_short: 'NY',
    county: 'Allegany',
    latitude: 42.0456,
    longitude: -77.8106,
    postal_code: '14897'
  },
  '14898': {
    country: 'US',
    city: 'Woodhull',
    state: 'New York',
    state_short: 'NY',
    county: 'Steuben',
    latitude: 42.0736,
    longitude: -77.4203,
    postal_code: '14898'
  },
  '14901': {
    country: 'US',
    city: 'Elmira',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.1008,
    longitude: -76.812,
    postal_code: '14901'
  },
  '14902': {
    country: 'US',
    city: 'Elmira',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.1473,
    longitude: -76.7509,
    postal_code: '14902'
  },
  '14903': {
    country: 'US',
    city: 'Elmira',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.1198,
    longitude: -76.8877,
    postal_code: '14903'
  },
  '14904': {
    country: 'US',
    city: 'Elmira',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.0729,
    longitude: -76.8037,
    postal_code: '14904'
  },
  '14905': {
    country: 'US',
    city: 'Elmira',
    state: 'New York',
    state_short: 'NY',
    county: 'Chemung',
    latitude: 42.0869,
    longitude: -76.8397,
    postal_code: '14905'
  },
  '15001': {
    country: 'US',
    city: 'Aliquippa',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.5921,
    longitude: -80.3197,
    postal_code: '15001'
  },
  '15003': {
    country: 'US',
    city: 'Ambridge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.6005,
    longitude: -80.2105,
    postal_code: '15003'
  },
  '15004': {
    country: 'US',
    city: 'Atlasburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.3413,
    longitude: -80.3822,
    postal_code: '15004'
  },
  '15005': {
    country: 'US',
    city: 'Baden',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.6416,
    longitude: -80.1985,
    postal_code: '15005'
  },
  '15006': {
    country: 'US',
    city: 'Bairdford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6312,
    longitude: -79.8814,
    postal_code: '15006'
  },
  '15007': {
    country: 'US',
    city: 'Bakerstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6478,
    longitude: -79.931,
    postal_code: '15007'
  },
  '15009': {
    country: 'US',
    city: 'Beaver',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.6972,
    longitude: -80.3365,
    postal_code: '15009'
  },
  '15010': {
    country: 'US',
    city: 'Beaver Falls',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.7687,
    longitude: -80.3592,
    postal_code: '15010'
  },
  '15012': {
    country: 'US',
    city: 'Belle Vernon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.1588,
    longitude: -79.8122,
    postal_code: '15012'
  },
  '15014': {
    country: 'US',
    city: 'Brackenridge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6082,
    longitude: -79.7414,
    postal_code: '15014'
  },
  '15015': {
    country: 'US',
    city: 'Bradfordwoods',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6372,
    longitude: -80.0811,
    postal_code: '15015'
  },
  '15017': {
    country: 'US',
    city: 'Bridgeville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3472,
    longitude: -80.1153,
    postal_code: '15017'
  },
  '15018': {
    country: 'US',
    city: 'Buena Vista',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.2773,
    longitude: -79.7992,
    postal_code: '15018'
  },
  '15019': {
    country: 'US',
    city: 'Bulger',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.4051,
    longitude: -80.3622,
    postal_code: '15019'
  },
  '15020': {
    country: 'US',
    city: 'Bunola',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.2373,
    longitude: -79.9506,
    postal_code: '15020'
  },
  '15021': {
    country: 'US',
    city: 'Burgettstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.3821,
    longitude: -80.4044,
    postal_code: '15021'
  },
  '15022': {
    country: 'US',
    city: 'Charleroi',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1404,
    longitude: -79.92,
    postal_code: '15022'
  },
  '15024': {
    country: 'US',
    city: 'Cheswick',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5681,
    longitude: -79.841,
    postal_code: '15024'
  },
  '15025': {
    country: 'US',
    city: 'Clairton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3243,
    longitude: -79.9317,
    postal_code: '15025'
  },
  '15026': {
    country: 'US',
    city: 'Clinton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5131,
    longitude: -80.3423,
    postal_code: '15026'
  },
  '15027': {
    country: 'US',
    city: 'Conway',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.6649,
    longitude: -80.2349,
    postal_code: '15027'
  },
  '15028': {
    country: 'US',
    city: 'Coulters',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15028'
  },
  '15030': {
    country: 'US',
    city: 'Creighton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5821,
    longitude: -79.7818,
    postal_code: '15030'
  },
  '15031': {
    country: 'US',
    city: 'Cuddy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3547,
    longitude: -80.1669,
    postal_code: '15031'
  },
  '15032': {
    country: 'US',
    city: 'Curtisville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6423,
    longitude: -79.8509,
    postal_code: '15032'
  },
  '15033': {
    country: 'US',
    city: 'Donora',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1768,
    longitude: -79.8619,
    postal_code: '15033'
  },
  '15034': {
    country: 'US',
    city: 'Dravosburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3527,
    longitude: -79.8895,
    postal_code: '15034'
  },
  '15035': {
    country: 'US',
    city: 'East Mc Keesport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3839,
    longitude: -79.8079,
    postal_code: '15035'
  },
  '15037': {
    country: 'US',
    city: 'Elizabeth',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.2656,
    longitude: -79.8568,
    postal_code: '15037'
  },
  '15038': {
    country: 'US',
    city: 'Elrama',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2521,
    longitude: -79.9252,
    postal_code: '15038'
  },
  '15042': {
    country: 'US',
    city: 'Freedom',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.683,
    longitude: -80.2147,
    postal_code: '15042'
  },
  '15043': {
    country: 'US',
    city: 'Georgetown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.5743,
    longitude: -80.49,
    postal_code: '15043'
  },
  '15044': {
    country: 'US',
    city: 'Gibsonia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6252,
    longitude: -79.9443,
    postal_code: '15044'
  },
  '15045': {
    country: 'US',
    city: 'Glassport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.326,
    longitude: -79.8883,
    postal_code: '15045'
  },
  '15046': {
    country: 'US',
    city: 'Crescent',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5563,
    longitude: -80.2286,
    postal_code: '15046'
  },
  '15047': {
    country: 'US',
    city: 'Greenock',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3123,
    longitude: -79.8067,
    postal_code: '15047'
  },
  '15049': {
    country: 'US',
    city: 'Harwick',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5574,
    longitude: -79.8051,
    postal_code: '15049'
  },
  '15050': {
    country: 'US',
    city: 'Hookstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.5622,
    longitude: -80.4388,
    postal_code: '15050'
  },
  '15051': {
    country: 'US',
    city: 'Indianola',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5695,
    longitude: -79.8637,
    postal_code: '15051'
  },
  '15052': {
    country: 'US',
    city: 'Industry',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.6671,
    longitude: -80.4151,
    postal_code: '15052'
  },
  '15053': {
    country: 'US',
    city: 'Joffre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.38,
    longitude: -80.3605,
    postal_code: '15053'
  },
  '15054': {
    country: 'US',
    city: 'Langeloth',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.3625,
    longitude: -80.4086,
    postal_code: '15054'
  },
  '15055': {
    country: 'US',
    city: 'Lawrence',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.3058,
    longitude: -80.1219,
    postal_code: '15055'
  },
  '15056': {
    country: 'US',
    city: 'Leetsdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5662,
    longitude: -80.2099,
    postal_code: '15056'
  },
  '15057': {
    country: 'US',
    city: 'Mc Donald',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.3697,
    longitude: -80.2562,
    postal_code: '15057'
  },
  '15059': {
    country: 'US',
    city: 'Midland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.6793,
    longitude: -80.4908,
    postal_code: '15059'
  },
  '15060': {
    country: 'US',
    city: 'Midway',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.368,
    longitude: -80.2921,
    postal_code: '15060'
  },
  '15061': {
    country: 'US',
    city: 'Monaca',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.6718,
    longitude: -80.2917,
    postal_code: '15061'
  },
  '15062': {
    country: 'US',
    city: 'Monessen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1524,
    longitude: -79.8835,
    postal_code: '15062'
  },
  '15063': {
    country: 'US',
    city: 'Monongahela',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1937,
    longitude: -79.9241,
    postal_code: '15063'
  },
  '15064': {
    country: 'US',
    city: 'Morgan',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3542,
    longitude: -80.1398,
    postal_code: '15064'
  },
  '15065': {
    country: 'US',
    city: 'Natrona Heights',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6421,
    longitude: -79.7273,
    postal_code: '15065'
  },
  '15066': {
    country: 'US',
    city: 'New Brighton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.7393,
    longitude: -80.2972,
    postal_code: '15066'
  },
  '15067': {
    country: 'US',
    city: 'New Eagle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2066,
    longitude: -79.9534,
    postal_code: '15067'
  },
  '15068': {
    country: 'US',
    city: 'New Kensington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.5616,
    longitude: -79.7129,
    postal_code: '15068'
  },
  '15069': {
    country: 'US',
    city: 'New Kensington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.5698,
    longitude: -79.7648,
    postal_code: '15069'
  },
  '15071': {
    country: 'US',
    city: 'Oakdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4033,
    longitude: -80.1842,
    postal_code: '15071'
  },
  '15072': {
    country: 'US',
    city: 'Pricedale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1393,
    longitude: -79.8562,
    postal_code: '15072'
  },
  '15074': {
    country: 'US',
    city: 'Rochester',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.7157,
    longitude: -80.2603,
    postal_code: '15074'
  },
  '15075': {
    country: 'US',
    city: 'Rural Ridge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5856,
    longitude: -79.8287,
    postal_code: '15075'
  },
  '15076': {
    country: 'US',
    city: 'Russellton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6073,
    longitude: -79.8347,
    postal_code: '15076'
  },
  '15077': {
    country: 'US',
    city: 'Shippingport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.632,
    longitude: -80.414,
    postal_code: '15077'
  },
  '15078': {
    country: 'US',
    city: 'Slovan',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.3584,
    longitude: -80.3879,
    postal_code: '15078'
  },
  '15081': {
    country: 'US',
    city: 'South Heights',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.5763,
    longitude: -80.2383,
    postal_code: '15081'
  },
  '15082': {
    country: 'US',
    city: 'Sturgeon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3863,
    longitude: -80.2083,
    postal_code: '15082'
  },
  '15083': {
    country: 'US',
    city: 'Sutersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2382,
    longitude: -79.7922,
    postal_code: '15083'
  },
  '15084': {
    country: 'US',
    city: 'Tarentum',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6187,
    longitude: -79.7852,
    postal_code: '15084'
  },
  '15085': {
    country: 'US',
    city: 'Trafford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3857,
    longitude: -79.7223,
    postal_code: '15085'
  },
  '15086': {
    country: 'US',
    city: 'Warrendale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6534,
    longitude: -80.0795,
    postal_code: '15086'
  },
  '15087': {
    country: 'US',
    city: 'Webster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1851,
    longitude: -79.8487,
    postal_code: '15087'
  },
  '15088': {
    country: 'US',
    city: 'West Elizabeth',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.2716,
    longitude: -79.8966,
    postal_code: '15088'
  },
  '15089': {
    country: 'US',
    city: 'West Newton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2075,
    longitude: -79.758,
    postal_code: '15089'
  },
  '15090': {
    country: 'US',
    city: 'Wexford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.612,
    longitude: -80.0649,
    postal_code: '15090'
  },
  '15091': {
    country: 'US',
    city: 'Wildwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5942,
    longitude: -79.9701,
    postal_code: '15091'
  },
  '15095': {
    country: 'US',
    city: 'Warrendale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6534,
    longitude: -80.0795,
    postal_code: '15095'
  },
  '15096': {
    country: 'US',
    city: 'Warrendale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.6534,
    longitude: -80.0795,
    postal_code: '15096'
  },
  '15101': {
    country: 'US',
    city: 'Allison Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.57,
    longitude: -79.9665,
    postal_code: '15101'
  },
  '15102': {
    country: 'US',
    city: 'Bethel Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.321,
    longitude: -80.0398,
    postal_code: '15102'
  },
  '15104': {
    country: 'US',
    city: 'Braddock',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4038,
    longitude: -79.8625,
    postal_code: '15104'
  },
  '15106': {
    country: 'US',
    city: 'Carnegie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4029,
    longitude: -80.0915,
    postal_code: '15106'
  },
  '15108': {
    country: 'US',
    city: 'Coraopolis',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5,
    longitude: -80.1996,
    postal_code: '15108'
  },
  '15110': {
    country: 'US',
    city: 'Duquesne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3704,
    longitude: -79.8522,
    postal_code: '15110'
  },
  '15112': {
    country: 'US',
    city: 'East Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4036,
    longitude: -79.8389,
    postal_code: '15112'
  },
  '15116': {
    country: 'US',
    city: 'Glenshaw',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5375,
    longitude: -79.9644,
    postal_code: '15116'
  },
  '15120': {
    country: 'US',
    city: 'Homestead',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3926,
    longitude: -79.9052,
    postal_code: '15120'
  },
  '15122': {
    country: 'US',
    city: 'West Mifflin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3634,
    longitude: -79.8664,
    postal_code: '15122'
  },
  '15123': {
    country: 'US',
    city: 'West Mifflin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15123'
  },
  '15126': {
    country: 'US',
    city: 'Imperial',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4584,
    longitude: -80.2649,
    postal_code: '15126'
  },
  '15127': {
    country: 'US',
    city: 'Ingomar',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5809,
    longitude: -80.0609,
    postal_code: '15127'
  },
  '15129': {
    country: 'US',
    city: 'South Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.2932,
    longitude: -79.996,
    postal_code: '15129'
  },
  '15131': {
    country: 'US',
    city: 'Mckeesport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3478,
    longitude: -79.8642,
    postal_code: '15131'
  },
  '15132': {
    country: 'US',
    city: 'Mckeesport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3478,
    longitude: -79.8642,
    postal_code: '15132'
  },
  '15133': {
    country: 'US',
    city: 'Mckeesport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3478,
    longitude: -79.8642,
    postal_code: '15133'
  },
  '15134': {
    country: 'US',
    city: 'Mckeesport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3478,
    longitude: -79.8642,
    postal_code: '15134'
  },
  '15135': {
    country: 'US',
    city: 'Mckeesport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3478,
    longitude: -79.8642,
    postal_code: '15135'
  },
  '15136': {
    country: 'US',
    city: 'Mc Kees Rocks',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4717,
    longitude: -80.0876,
    postal_code: '15136'
  },
  '15137': {
    country: 'US',
    city: 'North Versailles',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3762,
    longitude: -79.8124,
    postal_code: '15137'
  },
  '15139': {
    country: 'US',
    city: 'Oakmont',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5196,
    longitude: -79.8369,
    postal_code: '15139'
  },
  '15140': {
    country: 'US',
    city: 'Pitcairn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4048,
    longitude: -79.777,
    postal_code: '15140'
  },
  '15142': {
    country: 'US',
    city: 'Presto',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3847,
    longitude: -80.1209,
    postal_code: '15142'
  },
  '15143': {
    country: 'US',
    city: 'Sewickley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.557,
    longitude: -80.1578,
    postal_code: '15143'
  },
  '15144': {
    country: 'US',
    city: 'Springdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.544,
    longitude: -79.7844,
    postal_code: '15144'
  },
  '15145': {
    country: 'US',
    city: 'Turtle Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4113,
    longitude: -79.822,
    postal_code: '15145'
  },
  '15146': {
    country: 'US',
    city: 'Monroeville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.429,
    longitude: -79.7623,
    postal_code: '15146'
  },
  '15147': {
    country: 'US',
    city: 'Verona',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4927,
    longitude: -79.8345,
    postal_code: '15147'
  },
  '15148': {
    country: 'US',
    city: 'Wilmerding',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3934,
    longitude: -79.7951,
    postal_code: '15148'
  },
  '15201': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4752,
    longitude: -79.9528,
    postal_code: '15201'
  },
  '15202': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5053,
    longitude: -80.0685,
    postal_code: '15202'
  },
  '15203': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4254,
    longitude: -79.9799,
    postal_code: '15203'
  },
  '15204': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4554,
    longitude: -80.0644,
    postal_code: '15204'
  },
  '15205': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4322,
    longitude: -80.1021,
    postal_code: '15205'
  },
  '15206': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4723,
    longitude: -79.9132,
    postal_code: '15206'
  },
  '15207': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4003,
    longitude: -79.9338,
    postal_code: '15207'
  },
  '15208': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4532,
    longitude: -79.8995,
    postal_code: '15208'
  },
  '15209': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4999,
    longitude: -79.9739,
    postal_code: '15209'
  },
  '15210': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4072,
    longitude: -79.9839,
    postal_code: '15210'
  },
  '15211': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4295,
    longitude: -80.0144,
    postal_code: '15211'
  },
  '15212': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4406,
    longitude: -79.9959,
    postal_code: '15212'
  },
  '15213': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.444,
    longitude: -79.9552,
    postal_code: '15213'
  },
  '15214': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4865,
    longitude: -80.014,
    postal_code: '15214'
  },
  '15215': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5048,
    longitude: -79.9138,
    postal_code: '15215'
  },
  '15216': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4001,
    longitude: -80.0462,
    postal_code: '15216'
  },
  '15217': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4308,
    longitude: -79.9205,
    postal_code: '15217'
  },
  '15218': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4237,
    longitude: -79.89,
    postal_code: '15218'
  },
  '15219': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4423,
    longitude: -79.983,
    postal_code: '15219'
  },
  '15220': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4181,
    longitude: -80.0526,
    postal_code: '15220'
  },
  '15221': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4346,
    longitude: -79.8655,
    postal_code: '15221'
  },
  '15222': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4477,
    longitude: -79.9933,
    postal_code: '15222'
  },
  '15223': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5051,
    longitude: -79.9528,
    postal_code: '15223'
  },
  '15224': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4642,
    longitude: -79.9448,
    postal_code: '15224'
  },
  '15225': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5051,
    longitude: -80.1155,
    postal_code: '15225'
  },
  '15226': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4001,
    longitude: -80.0161,
    postal_code: '15226'
  },
  '15227': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3805,
    longitude: -79.9667,
    postal_code: '15227'
  },
  '15228': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3696,
    longitude: -80.0439,
    postal_code: '15228'
  },
  '15229': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5203,
    longitude: -80.037,
    postal_code: '15229'
  },
  '15230': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15230'
  },
  '15231': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4961,
    longitude: -80.2466,
    postal_code: '15231'
  },
  '15232': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4525,
    longitude: -79.9319,
    postal_code: '15232'
  },
  '15233': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4608,
    longitude: -80.0348,
    postal_code: '15233'
  },
  '15234': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3688,
    longitude: -80.0224,
    postal_code: '15234'
  },
  '15235': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4598,
    longitude: -79.8224,
    postal_code: '15235'
  },
  '15236': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3351,
    longitude: -79.9832,
    postal_code: '15236'
  },
  '15237': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5488,
    longitude: -80.0474,
    postal_code: '15237'
  },
  '15238': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.5346,
    longitude: -79.8805,
    postal_code: '15238'
  },
  '15239': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4837,
    longitude: -79.7381,
    postal_code: '15239'
  },
  '15240': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15240'
  },
  '15241': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3323,
    longitude: -80.081,
    postal_code: '15241'
  },
  '15242': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15242'
  },
  '15243': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.3748,
    longitude: -80.0731,
    postal_code: '15243'
  },
  '15244': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15244'
  },
  '15250': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15250'
  },
  '15251': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15251'
  },
  '15252': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15252'
  },
  '15253': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15253'
  },
  '15254': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15254'
  },
  '15255': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15255'
  },
  '15257': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15257'
  },
  '15258': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15258'
  },
  '15259': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15259'
  },
  '15260': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4432,
    longitude: -79.9531,
    postal_code: '15260'
  },
  '15261': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15261'
  },
  '15262': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15262'
  },
  '15264': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15264'
  },
  '15265': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15265'
  },
  '15267': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15267'
  },
  '15268': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15268'
  },
  '15270': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15270'
  },
  '15272': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15272'
  },
  '15274': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15274'
  },
  '15275': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4495,
    longitude: -80.1795,
    postal_code: '15275'
  },
  '15276': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15276'
  },
  '15277': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15277'
  },
  '15278': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15278'
  },
  '15279': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15279'
  },
  '15281': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15281'
  },
  '15282': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4406,
    longitude: -79.9959,
    postal_code: '15282'
  },
  '15283': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15283'
  },
  '15286': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15286'
  },
  '15289': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4446,
    longitude: -79.9451,
    postal_code: '15289'
  },
  '15290': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.4344,
    longitude: -80.0248,
    postal_code: '15290'
  },
  '15295': {
    country: 'US',
    city: 'Pittsburgh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Allegheny',
    latitude: 40.463,
    longitude: -80.0108,
    postal_code: '15295'
  },
  '15301': {
    country: 'US',
    city: 'Washington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1717,
    longitude: -80.256,
    postal_code: '15301'
  },
  '15310': {
    country: 'US',
    city: 'Aleppo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.8246,
    longitude: -80.4579,
    postal_code: '15310'
  },
  '15311': {
    country: 'US',
    city: 'Amity',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0498,
    longitude: -80.1681,
    postal_code: '15311'
  },
  '15312': {
    country: 'US',
    city: 'Avella',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2734,
    longitude: -80.4565,
    postal_code: '15312'
  },
  '15313': {
    country: 'US',
    city: 'Beallsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0655,
    longitude: -80.0221,
    postal_code: '15313'
  },
  '15314': {
    country: 'US',
    city: 'Bentleyville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1187,
    longitude: -80.007,
    postal_code: '15314'
  },
  '15315': {
    country: 'US',
    city: 'Bobtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.7612,
    longitude: -79.9814,
    postal_code: '15315'
  },
  '15316': {
    country: 'US',
    city: 'Brave',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.7245,
    longitude: -80.2537,
    postal_code: '15316'
  },
  '15317': {
    country: 'US',
    city: 'Canonsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2706,
    longitude: -80.1668,
    postal_code: '15317'
  },
  '15320': {
    country: 'US',
    city: 'Carmichaels',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.8825,
    longitude: -79.971,
    postal_code: '15320'
  },
  '15321': {
    country: 'US',
    city: 'Cecil',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.3234,
    longitude: -80.1879,
    postal_code: '15321'
  },
  '15322': {
    country: 'US',
    city: 'Clarksville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.9667,
    longitude: -80.0452,
    postal_code: '15322'
  },
  '15323': {
    country: 'US',
    city: 'Claysville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1179,
    longitude: -80.4103,
    postal_code: '15323'
  },
  '15324': {
    country: 'US',
    city: 'Cokeburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1008,
    longitude: -80.0652,
    postal_code: '15324'
  },
  '15325': {
    country: 'US',
    city: 'Crucible',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.9502,
    longitude: -79.9676,
    postal_code: '15325'
  },
  '15327': {
    country: 'US',
    city: 'Dilliner',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.7555,
    longitude: -79.9771,
    postal_code: '15327'
  },
  '15329': {
    country: 'US',
    city: 'Prosperity',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0224,
    longitude: -80.2613,
    postal_code: '15329'
  },
  '15330': {
    country: 'US',
    city: 'Eighty Four',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1868,
    longitude: -80.0627,
    postal_code: '15330'
  },
  '15331': {
    country: 'US',
    city: 'Ellsworth',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1073,
    longitude: -80.0204,
    postal_code: '15331'
  },
  '15332': {
    country: 'US',
    city: 'Finleyville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2593,
    longitude: -79.9753,
    postal_code: '15332'
  },
  '15333': {
    country: 'US',
    city: 'Fredericktown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0026,
    longitude: -79.9981,
    postal_code: '15333'
  },
  '15334': {
    country: 'US',
    city: 'Garards Fort',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.8083,
    longitude: -79.9679,
    postal_code: '15334'
  },
  '15336': {
    country: 'US',
    city: 'Gastonville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2601,
    longitude: -79.9927,
    postal_code: '15336'
  },
  '15337': {
    country: 'US',
    city: 'Graysville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.9092,
    longitude: -80.3952,
    postal_code: '15337'
  },
  '15338': {
    country: 'US',
    city: 'Greensboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.8045,
    longitude: -79.9399,
    postal_code: '15338'
  },
  '15339': {
    country: 'US',
    city: 'Hendersonville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.3018,
    longitude: -80.1543,
    postal_code: '15339'
  },
  '15340': {
    country: 'US',
    city: 'Hickory',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2925,
    longitude: -80.3025,
    postal_code: '15340'
  },
  '15341': {
    country: 'US',
    city: 'Holbrook',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.8489,
    longitude: -80.3385,
    postal_code: '15341'
  },
  '15342': {
    country: 'US',
    city: 'Houston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2425,
    longitude: -80.2209,
    postal_code: '15342'
  },
  '15344': {
    country: 'US',
    city: 'Jefferson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.9334,
    longitude: -80.0503,
    postal_code: '15344'
  },
  '15345': {
    country: 'US',
    city: 'Marianna',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0331,
    longitude: -80.1145,
    postal_code: '15345'
  },
  '15346': {
    country: 'US',
    city: 'Mather',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.9354,
    longitude: -80.0753,
    postal_code: '15346'
  },
  '15347': {
    country: 'US',
    city: 'Meadow Lands',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2174,
    longitude: -80.2269,
    postal_code: '15347'
  },
  '15348': {
    country: 'US',
    city: 'Millsboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 39.9867,
    longitude: -79.9937,
    postal_code: '15348'
  },
  '15349': {
    country: 'US',
    city: 'Mount Morris',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.774,
    longitude: -80.0246,
    postal_code: '15349'
  },
  '15350': {
    country: 'US',
    city: 'Muse',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2928,
    longitude: -80.2003,
    postal_code: '15350'
  },
  '15351': {
    country: 'US',
    city: 'Nemacolin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.8779,
    longitude: -79.9258,
    postal_code: '15351'
  },
  '15352': {
    country: 'US',
    city: 'New Freeport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.7507,
    longitude: -80.4542,
    postal_code: '15352'
  },
  '15353': {
    country: 'US',
    city: 'Nineveh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.9615,
    longitude: -80.3078,
    postal_code: '15353'
  },
  '15357': {
    country: 'US',
    city: 'Rices Landing',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.944,
    longitude: -79.9857,
    postal_code: '15357'
  },
  '15358': {
    country: 'US',
    city: 'Richeyville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0563,
    longitude: -79.9926,
    postal_code: '15358'
  },
  '15359': {
    country: 'US',
    city: 'Rogersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.8765,
    longitude: -80.2758,
    postal_code: '15359'
  },
  '15360': {
    country: 'US',
    city: 'Scenery Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0859,
    longitude: -80.0703,
    postal_code: '15360'
  },
  '15361': {
    country: 'US',
    city: 'Southview',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.3282,
    longitude: -80.2563,
    postal_code: '15361'
  },
  '15362': {
    country: 'US',
    city: 'Spraggs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.7447,
    longitude: -80.2145,
    postal_code: '15362'
  },
  '15363': {
    country: 'US',
    city: 'Strabane',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2505,
    longitude: -80.1984,
    postal_code: '15363'
  },
  '15364': {
    country: 'US',
    city: 'Sycamore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.9415,
    longitude: -80.2906,
    postal_code: '15364'
  },
  '15365': {
    country: 'US',
    city: 'Taylorstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1611,
    longitude: -80.3781,
    postal_code: '15365'
  },
  '15366': {
    country: 'US',
    city: 'Van Voorhis',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1562,
    longitude: -79.9742,
    postal_code: '15366'
  },
  '15367': {
    country: 'US',
    city: 'Venetia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2755,
    longitude: -80.0598,
    postal_code: '15367'
  },
  '15368': {
    country: 'US',
    city: 'Vestaburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0144,
    longitude: -79.9889,
    postal_code: '15368'
  },
  '15370': {
    country: 'US',
    city: 'Waynesburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.8917,
    longitude: -80.1795,
    postal_code: '15370'
  },
  '15376': {
    country: 'US',
    city: 'West Alexander',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1065,
    longitude: -80.4978,
    postal_code: '15376'
  },
  '15377': {
    country: 'US',
    city: 'West Finley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 39.9914,
    longitude: -80.4408,
    postal_code: '15377'
  },
  '15378': {
    country: 'US',
    city: 'Westland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2775,
    longitude: -80.2731,
    postal_code: '15378'
  },
  '15379': {
    country: 'US',
    city: 'West Middletown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.2417,
    longitude: -80.4242,
    postal_code: '15379'
  },
  '15380': {
    country: 'US',
    city: 'Wind Ridge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Greene',
    latitude: 39.8971,
    longitude: -80.4647,
    postal_code: '15380'
  },
  '15401': {
    country: 'US',
    city: 'Uniontown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8897,
    longitude: -79.7282,
    postal_code: '15401'
  },
  '15410': {
    country: 'US',
    city: 'Adah',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8965,
    longitude: -79.922,
    postal_code: '15410'
  },
  '15411': {
    country: 'US',
    city: 'Addison',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.7476,
    longitude: -79.3454,
    postal_code: '15411'
  },
  '15412': {
    country: 'US',
    city: 'Allenport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0959,
    longitude: -79.8499,
    postal_code: '15412'
  },
  '15413': {
    country: 'US',
    city: 'Allison',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9868,
    longitude: -79.8637,
    postal_code: '15413'
  },
  '15415': {
    country: 'US',
    city: 'Brier Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9808,
    longitude: -79.8504,
    postal_code: '15415'
  },
  '15416': {
    country: 'US',
    city: 'Brownfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.857,
    longitude: -79.712,
    postal_code: '15416'
  },
  '15417': {
    country: 'US',
    city: 'Brownsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0237,
    longitude: -79.8839,
    postal_code: '15417'
  },
  '15419': {
    country: 'US',
    city: 'California',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0625,
    longitude: -79.8953,
    postal_code: '15419'
  },
  '15420': {
    country: 'US',
    city: 'Cardale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9567,
    longitude: -79.8662,
    postal_code: '15420'
  },
  '15421': {
    country: 'US',
    city: 'Chalk Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8451,
    longitude: -79.5991,
    postal_code: '15421'
  },
  '15422': {
    country: 'US',
    city: 'Chestnut Ridge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9812,
    longitude: -79.8116,
    postal_code: '15422'
  },
  '15423': {
    country: 'US',
    city: 'Coal Center',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0973,
    longitude: -79.8839,
    postal_code: '15423'
  },
  '15424': {
    country: 'US',
    city: 'Confluence',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.8129,
    longitude: -79.3567,
    postal_code: '15424'
  },
  '15425': {
    country: 'US',
    city: 'Connellsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0265,
    longitude: -79.5566,
    postal_code: '15425'
  },
  '15427': {
    country: 'US',
    city: 'Daisytown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0743,
    longitude: -79.967,
    postal_code: '15427'
  },
  '15428': {
    country: 'US',
    city: 'Dawson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0638,
    longitude: -79.6602,
    postal_code: '15428'
  },
  '15429': {
    country: 'US',
    city: 'Denbo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0081,
    longitude: -79.9392,
    postal_code: '15429'
  },
  '15430': {
    country: 'US',
    city: 'Dickerson Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0418,
    longitude: -79.66,
    postal_code: '15430'
  },
  '15431': {
    country: 'US',
    city: 'Dunbar',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9722,
    longitude: -79.6431,
    postal_code: '15431'
  },
  '15432': {
    country: 'US',
    city: 'Dunlevy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.1151,
    longitude: -79.8627,
    postal_code: '15432'
  },
  '15433': {
    country: 'US',
    city: 'East Millsboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9822,
    longitude: -79.9664,
    postal_code: '15433'
  },
  '15434': {
    country: 'US',
    city: 'Elco',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0797,
    longitude: -79.8758,
    postal_code: '15434'
  },
  '15435': {
    country: 'US',
    city: 'Fairbank',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9434,
    longitude: -79.8495,
    postal_code: '15435'
  },
  '15436': {
    country: 'US',
    city: 'Fairchance',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8229,
    longitude: -79.7551,
    postal_code: '15436'
  },
  '15437': {
    country: 'US',
    city: 'Farmington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.807,
    longitude: -79.5832,
    postal_code: '15437'
  },
  '15438': {
    country: 'US',
    city: 'Fayette City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0988,
    longitude: -79.8366,
    postal_code: '15438'
  },
  '15439': {
    country: 'US',
    city: 'Gans',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.7429,
    longitude: -79.8242,
    postal_code: '15439'
  },
  '15440': {
    country: 'US',
    city: 'Gibbon Glade',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.7364,
    longitude: -79.5736,
    postal_code: '15440'
  },
  '15442': {
    country: 'US',
    city: 'Grindstone',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0084,
    longitude: -79.8404,
    postal_code: '15442'
  },
  '15443': {
    country: 'US',
    city: 'Hibbs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9317,
    longitude: -79.8825,
    postal_code: '15443'
  },
  '15444': {
    country: 'US',
    city: 'Hiller',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.012,
    longitude: -79.9088,
    postal_code: '15444'
  },
  '15445': {
    country: 'US',
    city: 'Hopwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8728,
    longitude: -79.6568,
    postal_code: '15445'
  },
  '15446': {
    country: 'US',
    city: 'Indian Head',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0248,
    longitude: -79.395,
    postal_code: '15446'
  },
  '15447': {
    country: 'US',
    city: 'Isabella',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.946,
    longitude: -79.9393,
    postal_code: '15447'
  },
  '15448': {
    country: 'US',
    city: 'Jacobs Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1331,
    longitude: -79.7417,
    postal_code: '15448'
  },
  '15449': {
    country: 'US',
    city: 'Keisterville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9636,
    longitude: -79.7853,
    postal_code: '15449'
  },
  '15450': {
    country: 'US',
    city: 'La Belle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9983,
    longitude: -79.9754,
    postal_code: '15450'
  },
  '15451': {
    country: 'US',
    city: 'Lake Lynn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.7501,
    longitude: -79.8618,
    postal_code: '15451'
  },
  '15454': {
    country: 'US',
    city: 'Leckrone',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8615,
    longitude: -79.8701,
    postal_code: '15454'
  },
  '15455': {
    country: 'US',
    city: 'Leisenring',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9985,
    longitude: -79.6435,
    postal_code: '15455'
  },
  '15456': {
    country: 'US',
    city: 'Lemont Furnace',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9311,
    longitude: -79.6477,
    postal_code: '15456'
  },
  '15458': {
    country: 'US',
    city: 'Mc Clellandtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8882,
    longitude: -79.8694,
    postal_code: '15458'
  },
  '15459': {
    country: 'US',
    city: 'Markleysburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.7597,
    longitude: -79.46,
    postal_code: '15459'
  },
  '15460': {
    country: 'US',
    city: 'Martin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8065,
    longitude: -79.9098,
    postal_code: '15460'
  },
  '15461': {
    country: 'US',
    city: 'Masontown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8424,
    longitude: -79.9003,
    postal_code: '15461'
  },
  '15462': {
    country: 'US',
    city: 'Melcroft',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.052,
    longitude: -79.3892,
    postal_code: '15462'
  },
  '15463': {
    country: 'US',
    city: 'Merrittstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8825,
    longitude: -79.8336,
    postal_code: '15463'
  },
  '15464': {
    country: 'US',
    city: 'Mill Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.968,
    longitude: -79.4622,
    postal_code: '15464'
  },
  '15465': {
    country: 'US',
    city: 'Mount Braddock',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9321,
    longitude: -79.6522,
    postal_code: '15465'
  },
  '15466': {
    country: 'US',
    city: 'Newell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0751,
    longitude: -79.8943,
    postal_code: '15466'
  },
  '15467': {
    country: 'US',
    city: 'New Geneva',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.7884,
    longitude: -79.9092,
    postal_code: '15467'
  },
  '15468': {
    country: 'US',
    city: 'New Salem',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9242,
    longitude: -79.8356,
    postal_code: '15468'
  },
  '15469': {
    country: 'US',
    city: 'Normalville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0418,
    longitude: -79.4151,
    postal_code: '15469'
  },
  '15470': {
    country: 'US',
    city: 'Ohiopyle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8507,
    longitude: -79.523,
    postal_code: '15470'
  },
  '15472': {
    country: 'US',
    city: 'Oliver',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9183,
    longitude: -79.7158,
    postal_code: '15472'
  },
  '15473': {
    country: 'US',
    city: 'Perryopolis',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0646,
    longitude: -79.7756,
    postal_code: '15473'
  },
  '15474': {
    country: 'US',
    city: 'Point Marion',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.7351,
    longitude: -79.899,
    postal_code: '15474'
  },
  '15475': {
    country: 'US',
    city: 'Republic',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9626,
    longitude: -79.8767,
    postal_code: '15475'
  },
  '15476': {
    country: 'US',
    city: 'Ronco',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8697,
    longitude: -79.9206,
    postal_code: '15476'
  },
  '15477': {
    country: 'US',
    city: 'Roscoe',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0787,
    longitude: -79.8656,
    postal_code: '15477'
  },
  '15478': {
    country: 'US',
    city: 'Smithfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.7923,
    longitude: -79.8127,
    postal_code: '15478'
  },
  '15479': {
    country: 'US',
    city: 'Smithton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1553,
    longitude: -79.7381,
    postal_code: '15479'
  },
  '15480': {
    country: 'US',
    city: 'Smock',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9961,
    longitude: -79.7678,
    postal_code: '15480'
  },
  '15482': {
    country: 'US',
    city: 'Star Junction',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0651,
    longitude: -79.769,
    postal_code: '15482'
  },
  '15483': {
    country: 'US',
    city: 'Stockdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Washington',
    latitude: 40.0826,
    longitude: -79.8501,
    postal_code: '15483'
  },
  '15484': {
    country: 'US',
    city: 'Uledi',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.8936,
    longitude: -79.7856,
    postal_code: '15484'
  },
  '15485': {
    country: 'US',
    city: 'Ursina',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.8173,
    longitude: -79.3306,
    postal_code: '15485'
  },
  '15486': {
    country: 'US',
    city: 'Vanderbilt',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0246,
    longitude: -79.6955,
    postal_code: '15486'
  },
  '15488': {
    country: 'US',
    city: 'Waltersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9763,
    longitude: -79.7689,
    postal_code: '15488'
  },
  '15489': {
    country: 'US',
    city: 'West Leisenring',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 39.9561,
    longitude: -79.7007,
    postal_code: '15489'
  },
  '15490': {
    country: 'US',
    city: 'White',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0726,
    longitude: -79.4251,
    postal_code: '15490'
  },
  '15492': {
    country: 'US',
    city: 'Wickhaven',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.1194,
    longitude: -79.7623,
    postal_code: '15492'
  },
  '15501': {
    country: 'US',
    city: 'Somerset',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.0248,
    longitude: -79.0808,
    postal_code: '15501'
  },
  '15502': {
    country: 'US',
    city: 'Hidden Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.046,
    longitude: -79.2585,
    postal_code: '15502'
  },
  '15510': {
    country: 'US',
    city: 'Somerset',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.9633,
    longitude: -79.0409,
    postal_code: '15510'
  },
  '15520': {
    country: 'US',
    city: 'Acosta',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.1104,
    longitude: -79.0689,
    postal_code: '15520'
  },
  '15521': {
    country: 'US',
    city: 'Alum Bank',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.1858,
    longitude: -78.6206,
    postal_code: '15521'
  },
  '15522': {
    country: 'US',
    city: 'Bedford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 39.9908,
    longitude: -78.5261,
    postal_code: '15522'
  },
  '15530': {
    country: 'US',
    city: 'Berlin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.9188,
    longitude: -78.9637,
    postal_code: '15530'
  },
  '15531': {
    country: 'US',
    city: 'Boswell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.1918,
    longitude: -79.0362,
    postal_code: '15531'
  },
  '15532': {
    country: 'US',
    city: 'Boynton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.7673,
    longitude: -79.062,
    postal_code: '15532'
  },
  '15533': {
    country: 'US',
    city: 'Breezewood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 39.9905,
    longitude: -78.2453,
    postal_code: '15533'
  },
  '15534': {
    country: 'US',
    city: 'Buffalo Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 39.922,
    longitude: -78.6996,
    postal_code: '15534'
  },
  '15535': {
    country: 'US',
    city: 'Clearville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 39.8533,
    longitude: -78.4392,
    postal_code: '15535'
  },
  '15536': {
    country: 'US',
    city: 'Crystal Spring',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 39.92,
    longitude: -78.2258,
    postal_code: '15536'
  },
  '15537': {
    country: 'US',
    city: 'Everett',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.0098,
    longitude: -78.3713,
    postal_code: '15537'
  },
  '15538': {
    country: 'US',
    city: 'Fairhope',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.868,
    longitude: -78.8362,
    postal_code: '15538'
  },
  '15539': {
    country: 'US',
    city: 'Fishertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.1217,
    longitude: -78.5856,
    postal_code: '15539'
  },
  '15540': {
    country: 'US',
    city: 'Fort Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.7961,
    longitude: -79.2472,
    postal_code: '15540'
  },
  '15541': {
    country: 'US',
    city: 'Friedens',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.011,
    longitude: -78.9033,
    postal_code: '15541'
  },
  '15542': {
    country: 'US',
    city: 'Garrett',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.8646,
    longitude: -79.0616,
    postal_code: '15542'
  },
  '15544': {
    country: 'US',
    city: 'Gray',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.1377,
    longitude: -79.0926,
    postal_code: '15544'
  },
  '15545': {
    country: 'US',
    city: 'Hyndman',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 39.8049,
    longitude: -78.7335,
    postal_code: '15545'
  },
  '15546': {
    country: 'US',
    city: 'Jenners',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.1431,
    longitude: -79.0428,
    postal_code: '15546'
  },
  '15547': {
    country: 'US',
    city: 'Jennerstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.1595,
    longitude: -79.0616,
    postal_code: '15547'
  },
  '15548': {
    country: 'US',
    city: 'Kantner',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.1011,
    longitude: -78.9387,
    postal_code: '15548'
  },
  '15549': {
    country: 'US',
    city: 'Listie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.0208,
    longitude: -79.0123,
    postal_code: '15549'
  },
  '15550': {
    country: 'US',
    city: 'Manns Choice',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 39.9808,
    longitude: -78.6423,
    postal_code: '15550'
  },
  '15551': {
    country: 'US',
    city: 'Markleton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.8691,
    longitude: -79.2879,
    postal_code: '15551'
  },
  '15552': {
    country: 'US',
    city: 'Meyersdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.7905,
    longitude: -79.0261,
    postal_code: '15552'
  },
  '15553': {
    country: 'US',
    city: 'New Baltimore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.9828,
    longitude: -78.7719,
    postal_code: '15553'
  },
  '15554': {
    country: 'US',
    city: 'New Paris',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.1254,
    longitude: -78.6184,
    postal_code: '15554'
  },
  '15555': {
    country: 'US',
    city: 'Quecreek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.0914,
    longitude: -79.0872,
    postal_code: '15555'
  },
  '15557': {
    country: 'US',
    city: 'Rockwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.9373,
    longitude: -79.1865,
    postal_code: '15557'
  },
  '15558': {
    country: 'US',
    city: 'Salisbury',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.7531,
    longitude: -79.0835,
    postal_code: '15558'
  },
  '15559': {
    country: 'US',
    city: 'Schellsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.0487,
    longitude: -78.6482,
    postal_code: '15559'
  },
  '15560': {
    country: 'US',
    city: 'Shanksville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.0171,
    longitude: -78.9077,
    postal_code: '15560'
  },
  '15561': {
    country: 'US',
    city: 'Sipesville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.0964,
    longitude: -79.0902,
    postal_code: '15561'
  },
  '15562': {
    country: 'US',
    city: 'Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.748,
    longitude: -79.1237,
    postal_code: '15562'
  },
  '15563': {
    country: 'US',
    city: 'Stoystown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.0948,
    longitude: -78.9658,
    postal_code: '15563'
  },
  '15564': {
    country: 'US',
    city: 'Wellersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.7299,
    longitude: -78.8441,
    postal_code: '15564'
  },
  '15565': {
    country: 'US',
    city: 'West Salisbury',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 39.7542,
    longitude: -79.095,
    postal_code: '15565'
  },
  '15601': {
    country: 'US',
    city: 'Greensburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3074,
    longitude: -79.5424,
    postal_code: '15601'
  },
  '15605': {
    country: 'US',
    city: 'Greensburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3602,
    longitude: -79.4398,
    postal_code: '15605'
  },
  '15606': {
    country: 'US',
    city: 'Greensburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3015,
    longitude: -79.5389,
    postal_code: '15606'
  },
  '15610': {
    country: 'US',
    city: 'Acme',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1201,
    longitude: -79.4051,
    postal_code: '15610'
  },
  '15611': {
    country: 'US',
    city: 'Adamsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3126,
    longitude: -79.6565,
    postal_code: '15611'
  },
  '15612': {
    country: 'US',
    city: 'Alverton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1373,
    longitude: -79.5997,
    postal_code: '15612'
  },
  '15613': {
    country: 'US',
    city: 'Apollo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.5565,
    longitude: -79.5772,
    postal_code: '15613'
  },
  '15615': {
    country: 'US',
    city: 'Ardara',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3578,
    longitude: -79.735,
    postal_code: '15615'
  },
  '15616': {
    country: 'US',
    city: 'Armbrust',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2237,
    longitude: -79.5512,
    postal_code: '15616'
  },
  '15617': {
    country: 'US',
    city: 'Arona',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.268,
    longitude: -79.6591,
    postal_code: '15617'
  },
  '15618': {
    country: 'US',
    city: 'Avonmore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.5221,
    longitude: -79.4853,
    postal_code: '15618'
  },
  '15619': {
    country: 'US',
    city: 'Bovard',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3198,
    longitude: -79.5034,
    postal_code: '15619'
  },
  '15620': {
    country: 'US',
    city: 'Bradenville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3238,
    longitude: -79.3434,
    postal_code: '15620'
  },
  '15621': {
    country: 'US',
    city: 'Calumet',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2109,
    longitude: -79.4853,
    postal_code: '15621'
  },
  '15622': {
    country: 'US',
    city: 'Champion',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.044,
    longitude: -79.3247,
    postal_code: '15622'
  },
  '15623': {
    country: 'US',
    city: 'Claridge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3692,
    longitude: -79.619,
    postal_code: '15623'
  },
  '15624': {
    country: 'US',
    city: 'Crabtree',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3623,
    longitude: -79.4706,
    postal_code: '15624'
  },
  '15625': {
    country: 'US',
    city: 'Darragh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2665,
    longitude: -79.6787,
    postal_code: '15625'
  },
  '15626': {
    country: 'US',
    city: 'Delmont',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.4139,
    longitude: -79.5764,
    postal_code: '15626'
  },
  '15627': {
    country: 'US',
    city: 'Derry',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3349,
    longitude: -79.3326,
    postal_code: '15627'
  },
  '15628': {
    country: 'US',
    city: 'Donegal',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.0996,
    longitude: -79.381,
    postal_code: '15628'
  },
  '15629': {
    country: 'US',
    city: 'East Vandergrift',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.598,
    longitude: -79.5624,
    postal_code: '15629'
  },
  '15631': {
    country: 'US',
    city: 'Everson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fayette',
    latitude: 40.0911,
    longitude: -79.5873,
    postal_code: '15631'
  },
  '15632': {
    country: 'US',
    city: 'Export',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.4252,
    longitude: -79.611,
    postal_code: '15632'
  },
  '15633': {
    country: 'US',
    city: 'Forbes Road',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.354,
    longitude: -79.5217,
    postal_code: '15633'
  },
  '15634': {
    country: 'US',
    city: 'Grapeville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3239,
    longitude: -79.6049,
    postal_code: '15634'
  },
  '15635': {
    country: 'US',
    city: 'Hannastown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.352,
    longitude: -79.4979,
    postal_code: '15635'
  },
  '15636': {
    country: 'US',
    city: 'Harrison City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.366,
    longitude: -79.6565,
    postal_code: '15636'
  },
  '15637': {
    country: 'US',
    city: 'Herminie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2455,
    longitude: -79.7172,
    postal_code: '15637'
  },
  '15638': {
    country: 'US',
    city: 'Hostetter',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2662,
    longitude: -79.3984,
    postal_code: '15638'
  },
  '15639': {
    country: 'US',
    city: 'Hunker',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2149,
    longitude: -79.5824,
    postal_code: '15639'
  },
  '15640': {
    country: 'US',
    city: 'Hutchinson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2251,
    longitude: -79.7328,
    postal_code: '15640'
  },
  '15641': {
    country: 'US',
    city: 'Hyde Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.6311,
    longitude: -79.5899,
    postal_code: '15641'
  },
  '15642': {
    country: 'US',
    city: 'Irwin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3191,
    longitude: -79.7205,
    postal_code: '15642'
  },
  '15644': {
    country: 'US',
    city: 'Jeannette',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3295,
    longitude: -79.6144,
    postal_code: '15644'
  },
  '15646': {
    country: 'US',
    city: 'Jones Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.0889,
    longitude: -79.3427,
    postal_code: '15646'
  },
  '15647': {
    country: 'US',
    city: 'Larimer',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3453,
    longitude: -79.7264,
    postal_code: '15647'
  },
  '15650': {
    country: 'US',
    city: 'Latrobe',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2926,
    longitude: -79.4103,
    postal_code: '15650'
  },
  '15655': {
    country: 'US',
    city: 'Laughlintown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.212,
    longitude: -79.1978,
    postal_code: '15655'
  },
  '15656': {
    country: 'US',
    city: 'Leechburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.6344,
    longitude: -79.6201,
    postal_code: '15656'
  },
  '15658': {
    country: 'US',
    city: 'Ligonier',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2431,
    longitude: -79.2375,
    postal_code: '15658'
  },
  '15660': {
    country: 'US',
    city: 'Lowber',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2459,
    longitude: -79.7709,
    postal_code: '15660'
  },
  '15661': {
    country: 'US',
    city: 'Loyalhanna',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3222,
    longitude: -79.3601,
    postal_code: '15661'
  },
  '15662': {
    country: 'US',
    city: 'Luxor',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3342,
    longitude: -79.4781,
    postal_code: '15662'
  },
  '15663': {
    country: 'US',
    city: 'Madison',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2467,
    longitude: -79.676,
    postal_code: '15663'
  },
  '15664': {
    country: 'US',
    city: 'Mammoth',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3108,
    longitude: -79.6172,
    postal_code: '15664'
  },
  '15665': {
    country: 'US',
    city: 'Manor',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.334,
    longitude: -79.67,
    postal_code: '15665'
  },
  '15666': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1742,
    longitude: -79.5134,
    postal_code: '15666'
  },
  '15668': {
    country: 'US',
    city: 'Murrysville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.4467,
    longitude: -79.6842,
    postal_code: '15668'
  },
  '15670': {
    country: 'US',
    city: 'New Alexandria',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3987,
    longitude: -79.422,
    postal_code: '15670'
  },
  '15671': {
    country: 'US',
    city: 'New Derry',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3494,
    longitude: -79.3225,
    postal_code: '15671'
  },
  '15672': {
    country: 'US',
    city: 'New Stanton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2192,
    longitude: -79.6095,
    postal_code: '15672'
  },
  '15673': {
    country: 'US',
    city: 'North Apollo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.593,
    longitude: -79.5581,
    postal_code: '15673'
  },
  '15674': {
    country: 'US',
    city: 'Norvelt',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.216,
    longitude: -79.4872,
    postal_code: '15674'
  },
  '15675': {
    country: 'US',
    city: 'Penn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3301,
    longitude: -79.6413,
    postal_code: '15675'
  },
  '15676': {
    country: 'US',
    city: 'Pleasant Unity',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2424,
    longitude: -79.4542,
    postal_code: '15676'
  },
  '15677': {
    country: 'US',
    city: 'Rector',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1864,
    longitude: -79.2473,
    postal_code: '15677'
  },
  '15678': {
    country: 'US',
    city: 'Rillton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2872,
    longitude: -79.7262,
    postal_code: '15678'
  },
  '15679': {
    country: 'US',
    city: 'Ruffs Dale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1585,
    longitude: -79.6277,
    postal_code: '15679'
  },
  '15680': {
    country: 'US',
    city: 'Salina',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.5189,
    longitude: -79.4959,
    postal_code: '15680'
  },
  '15681': {
    country: 'US',
    city: 'Saltsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.5429,
    longitude: -79.3978,
    postal_code: '15681'
  },
  '15682': {
    country: 'US',
    city: 'Schenley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.6845,
    longitude: -79.6617,
    postal_code: '15682'
  },
  '15683': {
    country: 'US',
    city: 'Scottdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1029,
    longitude: -79.593,
    postal_code: '15683'
  },
  '15684': {
    country: 'US',
    city: 'Slickville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.4653,
    longitude: -79.5067,
    postal_code: '15684'
  },
  '15685': {
    country: 'US',
    city: 'Southwest',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3602,
    longitude: -79.4398,
    postal_code: '15685'
  },
  '15686': {
    country: 'US',
    city: 'Spring Church',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.6153,
    longitude: -79.4545,
    postal_code: '15686'
  },
  '15687': {
    country: 'US',
    city: 'Stahlstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1386,
    longitude: -79.3445,
    postal_code: '15687'
  },
  '15688': {
    country: 'US',
    city: 'Tarrs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1686,
    longitude: -79.5848,
    postal_code: '15688'
  },
  '15689': {
    country: 'US',
    city: 'United',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2184,
    longitude: -79.4878,
    postal_code: '15689'
  },
  '15690': {
    country: 'US',
    city: 'Vandergrift',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.6418,
    longitude: -79.5399,
    postal_code: '15690'
  },
  '15691': {
    country: 'US',
    city: 'Wendel',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.294,
    longitude: -79.6866,
    postal_code: '15691'
  },
  '15692': {
    country: 'US',
    city: 'Westmoreland City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3294,
    longitude: -79.6806,
    postal_code: '15692'
  },
  '15693': {
    country: 'US',
    city: 'Whitney',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2534,
    longitude: -79.4103,
    postal_code: '15693'
  },
  '15695': {
    country: 'US',
    city: 'Wyano',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.1962,
    longitude: -79.6942,
    postal_code: '15695'
  },
  '15696': {
    country: 'US',
    city: 'Youngstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2793,
    longitude: -79.3661,
    postal_code: '15696'
  },
  '15697': {
    country: 'US',
    city: 'Youngwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2395,
    longitude: -79.5823,
    postal_code: '15697'
  },
  '15698': {
    country: 'US',
    city: 'Yukon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.2155,
    longitude: -79.6849,
    postal_code: '15698'
  },
  '15701': {
    country: 'US',
    city: 'Indiana',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.6196,
    longitude: -79.1596,
    postal_code: '15701'
  },
  '15705': {
    country: 'US',
    city: 'Indiana',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.6215,
    longitude: -79.1525,
    postal_code: '15705'
  },
  '15710': {
    country: 'US',
    city: 'Alverda',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.6303,
    longitude: -78.857,
    postal_code: '15710'
  },
  '15711': {
    country: 'US',
    city: 'Anita',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.0018,
    longitude: -78.9666,
    postal_code: '15711'
  },
  '15712': {
    country: 'US',
    city: 'Arcadia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7817,
    longitude: -78.8536,
    postal_code: '15712'
  },
  '15713': {
    country: 'US',
    city: 'Aultman',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.5698,
    longitude: -79.2617,
    postal_code: '15713'
  },
  '15714': {
    country: 'US',
    city: 'Northern Cambria',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6661,
    longitude: -78.792,
    postal_code: '15714'
  },
  '15715': {
    country: 'US',
    city: 'Big Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 40.9704,
    longitude: -78.8758,
    postal_code: '15715'
  },
  '15716': {
    country: 'US',
    city: 'Black Lick',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.4725,
    longitude: -79.1869,
    postal_code: '15716'
  },
  '15717': {
    country: 'US',
    city: 'Blairsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.4413,
    longitude: -79.2533,
    postal_code: '15717'
  },
  '15720': {
    country: 'US',
    city: 'Brush Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.5292,
    longitude: -79.0846,
    postal_code: '15720'
  },
  '15721': {
    country: 'US',
    city: 'Burnside',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.8134,
    longitude: -78.7865,
    postal_code: '15721'
  },
  '15722': {
    country: 'US',
    city: 'Carrolltown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.5891,
    longitude: -78.7037,
    postal_code: '15722'
  },
  '15723': {
    country: 'US',
    city: 'Chambersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7056,
    longitude: -79.1615,
    postal_code: '15723'
  },
  '15724': {
    country: 'US',
    city: 'Cherry Tree',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7554,
    longitude: -78.8474,
    postal_code: '15724'
  },
  '15725': {
    country: 'US',
    city: 'Clarksburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.5039,
    longitude: -79.3677,
    postal_code: '15725'
  },
  '15727': {
    country: 'US',
    city: 'Clune',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.5503,
    longitude: -79.3237,
    postal_code: '15727'
  },
  '15728': {
    country: 'US',
    city: 'Clymer',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.6688,
    longitude: -79.0119,
    postal_code: '15728'
  },
  '15729': {
    country: 'US',
    city: 'Commodore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7016,
    longitude: -78.9134,
    postal_code: '15729'
  },
  '15730': {
    country: 'US',
    city: 'Coolspring',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.0431,
    longitude: -79.0836,
    postal_code: '15730'
  },
  '15731': {
    country: 'US',
    city: 'Coral',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.4992,
    longitude: -79.1739,
    postal_code: '15731'
  },
  '15732': {
    country: 'US',
    city: 'Creekside',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7199,
    longitude: -79.2014,
    postal_code: '15732'
  },
  '15733': {
    country: 'US',
    city: 'De Lancey',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.1411,
    longitude: -78.9629,
    postal_code: '15733'
  },
  '15734': {
    country: 'US',
    city: 'Dixonville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7165,
    longitude: -79.007,
    postal_code: '15734'
  },
  '15736': {
    country: 'US',
    city: 'Elderton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.6922,
    longitude: -79.3422,
    postal_code: '15736'
  },
  '15737': {
    country: 'US',
    city: 'Elmora',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6076,
    longitude: -78.7524,
    postal_code: '15737'
  },
  '15738': {
    country: 'US',
    city: 'Emeigh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6948,
    longitude: -78.787,
    postal_code: '15738'
  },
  '15739': {
    country: 'US',
    city: 'Ernest',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.678,
    longitude: -79.1657,
    postal_code: '15739'
  },
  '15741': {
    country: 'US',
    city: 'Gipsy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.8073,
    longitude: -78.8764,
    postal_code: '15741'
  },
  '15742': {
    country: 'US',
    city: 'Glen Campbell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.8508,
    longitude: -78.8555,
    postal_code: '15742'
  },
  '15744': {
    country: 'US',
    city: 'Hamilton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 40.9233,
    longitude: -79.0831,
    postal_code: '15744'
  },
  '15745': {
    country: 'US',
    city: 'Heilwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.6207,
    longitude: -78.9186,
    postal_code: '15745'
  },
  '15746': {
    country: 'US',
    city: 'Hillsdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7517,
    longitude: -78.885,
    postal_code: '15746'
  },
  '15747': {
    country: 'US',
    city: 'Home',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7834,
    longitude: -79.1641,
    postal_code: '15747'
  },
  '15748': {
    country: 'US',
    city: 'Homer City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.5245,
    longitude: -79.0843,
    postal_code: '15748'
  },
  '15750': {
    country: 'US',
    city: 'Josephine',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.4828,
    longitude: -79.185,
    postal_code: '15750'
  },
  '15752': {
    country: 'US',
    city: 'Kent',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.5412,
    longitude: -79.282,
    postal_code: '15752'
  },
  '15753': {
    country: 'US',
    city: 'La Jose',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.7876,
    longitude: -78.6514,
    postal_code: '15753'
  },
  '15754': {
    country: 'US',
    city: 'Lucernemines',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.5567,
    longitude: -79.1515,
    postal_code: '15754'
  },
  '15756': {
    country: 'US',
    city: 'Mc Intyre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.5684,
    longitude: -79.2999,
    postal_code: '15756'
  },
  '15757': {
    country: 'US',
    city: 'Mahaffey',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.8875,
    longitude: -78.7353,
    postal_code: '15757'
  },
  '15758': {
    country: 'US',
    city: 'Marchand',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.8576,
    longitude: -79.0228,
    postal_code: '15758'
  },
  '15759': {
    country: 'US',
    city: 'Marion Center',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7814,
    longitude: -79.0252,
    postal_code: '15759'
  },
  '15760': {
    country: 'US',
    city: 'Marsteller',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6522,
    longitude: -78.8054,
    postal_code: '15760'
  },
  '15761': {
    country: 'US',
    city: 'Mentcle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.6373,
    longitude: -78.8823,
    postal_code: '15761'
  },
  '15762': {
    country: 'US',
    city: 'Nicktown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6016,
    longitude: -78.8114,
    postal_code: '15762'
  },
  '15763': {
    country: 'US',
    city: 'Northpoint',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.9037,
    longitude: -79.1257,
    postal_code: '15763'
  },
  '15764': {
    country: 'US',
    city: 'Oliveburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 40.9926,
    longitude: -79.0291,
    postal_code: '15764'
  },
  '15765': {
    country: 'US',
    city: 'Penn Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.6213,
    longitude: -78.9943,
    postal_code: '15765'
  },
  '15767': {
    country: 'US',
    city: 'Punxsutawney',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 40.9479,
    longitude: -78.9681,
    postal_code: '15767'
  },
  '15770': {
    country: 'US',
    city: 'Ringgold',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 40.9997,
    longitude: -79.1766,
    postal_code: '15770'
  },
  '15771': {
    country: 'US',
    city: 'Rochester Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.8308,
    longitude: -78.9892,
    postal_code: '15771'
  },
  '15772': {
    country: 'US',
    city: 'Rossiter',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.8846,
    longitude: -78.9414,
    postal_code: '15772'
  },
  '15773': {
    country: 'US',
    city: 'Saint Benedict',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6298,
    longitude: -78.7329,
    postal_code: '15773'
  },
  '15774': {
    country: 'US',
    city: 'Shelocta',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.6388,
    longitude: -79.3191,
    postal_code: '15774'
  },
  '15775': {
    country: 'US',
    city: 'Spangler',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6351,
    longitude: -78.7692,
    postal_code: '15775'
  },
  '15776': {
    country: 'US',
    city: 'Sprankle Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.0133,
    longitude: -79.114,
    postal_code: '15776'
  },
  '15777': {
    country: 'US',
    city: 'Starford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7027,
    longitude: -78.9588,
    postal_code: '15777'
  },
  '15778': {
    country: 'US',
    city: 'Timblin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 40.9684,
    longitude: -79.2014,
    postal_code: '15778'
  },
  '15779': {
    country: 'US',
    city: 'Torrance',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3992,
    longitude: -79.2177,
    postal_code: '15779'
  },
  '15780': {
    country: 'US',
    city: 'Valier',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 40.9159,
    longitude: -79.0448,
    postal_code: '15780'
  },
  '15781': {
    country: 'US',
    city: 'Walston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 40.9651,
    longitude: -78.9948,
    postal_code: '15781'
  },
  '15783': {
    country: 'US',
    city: 'West Lebanon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.6026,
    longitude: -79.3537,
    postal_code: '15783'
  },
  '15784': {
    country: 'US',
    city: 'Worthville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.0238,
    longitude: -79.1387,
    postal_code: '15784'
  },
  '15801': {
    country: 'US',
    city: 'Du Bois',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.126,
    longitude: -78.7527,
    postal_code: '15801'
  },
  '15821': {
    country: 'US',
    city: 'Benezett',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.3253,
    longitude: -78.3576,
    postal_code: '15821'
  },
  '15822': {
    country: 'US',
    city: 'Brandy Camp',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.3209,
    longitude: -78.6875,
    postal_code: '15822'
  },
  '15823': {
    country: 'US',
    city: 'Brockport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.2822,
    longitude: -78.7128,
    postal_code: '15823'
  },
  '15824': {
    country: 'US',
    city: 'Brockway',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.2406,
    longitude: -78.8116,
    postal_code: '15824'
  },
  '15825': {
    country: 'US',
    city: 'Brookville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.1627,
    longitude: -79.0816,
    postal_code: '15825'
  },
  '15827': {
    country: 'US',
    city: 'Byrnedale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.2865,
    longitude: -78.5051,
    postal_code: '15827'
  },
  '15828': {
    country: 'US',
    city: 'Clarington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Forest',
    latitude: 41.3233,
    longitude: -79.1466,
    postal_code: '15828'
  },
  '15829': {
    country: 'US',
    city: 'Corsica',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.1762,
    longitude: -79.1976,
    postal_code: '15829'
  },
  '15831': {
    country: 'US',
    city: 'Dagus Mines',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.3539,
    longitude: -78.6056,
    postal_code: '15831'
  },
  '15832': {
    country: 'US',
    city: 'Driftwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cameron',
    latitude: 41.3764,
    longitude: -78.1632,
    postal_code: '15832'
  },
  '15834': {
    country: 'US',
    city: 'Emporium',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cameron',
    latitude: 41.5177,
    longitude: -78.2536,
    postal_code: '15834'
  },
  '15840': {
    country: 'US',
    city: 'Falls Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.1455,
    longitude: -78.8128,
    postal_code: '15840'
  },
  '15841': {
    country: 'US',
    city: 'Force',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.2629,
    longitude: -78.5298,
    postal_code: '15841'
  },
  '15845': {
    country: 'US',
    city: 'Johnsonburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.4928,
    longitude: -78.6783,
    postal_code: '15845'
  },
  '15846': {
    country: 'US',
    city: 'Kersey',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.3563,
    longitude: -78.6015,
    postal_code: '15846'
  },
  '15847': {
    country: 'US',
    city: 'Knox Dale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.0862,
    longitude: -79.0264,
    postal_code: '15847'
  },
  '15848': {
    country: 'US',
    city: 'Luthersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.0532,
    longitude: -78.7428,
    postal_code: '15848'
  },
  '15849': {
    country: 'US',
    city: 'Penfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.2085,
    longitude: -78.5791,
    postal_code: '15849'
  },
  '15851': {
    country: 'US',
    city: 'Reynoldsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.097,
    longitude: -78.8886,
    postal_code: '15851'
  },
  '15853': {
    country: 'US',
    city: 'Ridgway',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.3621,
    longitude: -78.7262,
    postal_code: '15853'
  },
  '15856': {
    country: 'US',
    city: 'Rockton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.0806,
    longitude: -78.6577,
    postal_code: '15856'
  },
  '15857': {
    country: 'US',
    city: 'Saint Marys',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.4289,
    longitude: -78.5505,
    postal_code: '15857'
  },
  '15860': {
    country: 'US',
    city: 'Sigel',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.3099,
    longitude: -79.054,
    postal_code: '15860'
  },
  '15861': {
    country: 'US',
    city: 'Sinnamahoning',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cameron',
    latitude: 41.3762,
    longitude: -78.0661,
    postal_code: '15861'
  },
  '15863': {
    country: 'US',
    city: 'Stump Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.0126,
    longitude: -78.8373,
    postal_code: '15863'
  },
  '15864': {
    country: 'US',
    city: 'Summerville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.1058,
    longitude: -79.1726,
    postal_code: '15864'
  },
  '15865': {
    country: 'US',
    city: 'Sykesville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Jefferson',
    latitude: 41.0514,
    longitude: -78.8195,
    postal_code: '15865'
  },
  '15866': {
    country: 'US',
    city: 'Troutville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.0256,
    longitude: -78.7853,
    postal_code: '15866'
  },
  '15868': {
    country: 'US',
    city: 'Weedville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.2685,
    longitude: -78.4952,
    postal_code: '15868'
  },
  '15870': {
    country: 'US',
    city: 'Wilcox',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.5735,
    longitude: -78.6823,
    postal_code: '15870'
  },
  '15901': {
    country: 'US',
    city: 'Johnstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.326,
    longitude: -78.9141,
    postal_code: '15901'
  },
  '15902': {
    country: 'US',
    city: 'Johnstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3078,
    longitude: -78.8969,
    postal_code: '15902'
  },
  '15904': {
    country: 'US',
    city: 'Johnstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.285,
    longitude: -78.8654,
    postal_code: '15904'
  },
  '15905': {
    country: 'US',
    city: 'Johnstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3072,
    longitude: -78.943,
    postal_code: '15905'
  },
  '15906': {
    country: 'US',
    city: 'Johnstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3522,
    longitude: -78.9383,
    postal_code: '15906'
  },
  '15907': {
    country: 'US',
    city: 'Johnstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3254,
    longitude: -78.9151,
    postal_code: '15907'
  },
  '15909': {
    country: 'US',
    city: 'Johnstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.4096,
    longitude: -78.8712,
    postal_code: '15909'
  },
  '15915': {
    country: 'US',
    city: 'Johnstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3267,
    longitude: -78.922,
    postal_code: '15915'
  },
  '15920': {
    country: 'US',
    city: 'Armagh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.4603,
    longitude: -79.0097,
    postal_code: '15920'
  },
  '15921': {
    country: 'US',
    city: 'Beaverdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3173,
    longitude: -78.7009,
    postal_code: '15921'
  },
  '15922': {
    country: 'US',
    city: 'Belsano',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.5176,
    longitude: -78.8767,
    postal_code: '15922'
  },
  '15923': {
    country: 'US',
    city: 'Bolivar',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3673,
    longitude: -79.1605,
    postal_code: '15923'
  },
  '15924': {
    country: 'US',
    city: 'Cairnbrook',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.1149,
    longitude: -78.7851,
    postal_code: '15924'
  },
  '15925': {
    country: 'US',
    city: 'Cassandra',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.4085,
    longitude: -78.6407,
    postal_code: '15925'
  },
  '15926': {
    country: 'US',
    city: 'Central City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.0913,
    longitude: -78.8448,
    postal_code: '15926'
  },
  '15927': {
    country: 'US',
    city: 'Colver',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.5383,
    longitude: -78.7865,
    postal_code: '15927'
  },
  '15928': {
    country: 'US',
    city: 'Davidsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.2245,
    longitude: -78.9363,
    postal_code: '15928'
  },
  '15929': {
    country: 'US',
    city: 'Dilltown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.4665,
    longitude: -79.0025,
    postal_code: '15929'
  },
  '15930': {
    country: 'US',
    city: 'Dunlo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.2939,
    longitude: -78.7192,
    postal_code: '15930'
  },
  '15931': {
    country: 'US',
    city: 'Ebensburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.4801,
    longitude: -78.7263,
    postal_code: '15931'
  },
  '15934': {
    country: 'US',
    city: 'Elton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.2799,
    longitude: -78.8032,
    postal_code: '15934'
  },
  '15935': {
    country: 'US',
    city: 'Hollsopple',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.2343,
    longitude: -78.9515,
    postal_code: '15935'
  },
  '15936': {
    country: 'US',
    city: 'Hooversville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.1488,
    longitude: -78.9141,
    postal_code: '15936'
  },
  '15937': {
    country: 'US',
    city: 'Jerome',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.2084,
    longitude: -78.9873,
    postal_code: '15937'
  },
  '15938': {
    country: 'US',
    city: 'Lilly',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.4238,
    longitude: -78.6231,
    postal_code: '15938'
  },
  '15940': {
    country: 'US',
    city: 'Loretto',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.5105,
    longitude: -78.6294,
    postal_code: '15940'
  },
  '15942': {
    country: 'US',
    city: 'Mineral Point',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3793,
    longitude: -78.8352,
    postal_code: '15942'
  },
  '15943': {
    country: 'US',
    city: 'Nanty Glo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.4704,
    longitude: -78.8375,
    postal_code: '15943'
  },
  '15944': {
    country: 'US',
    city: 'New Florence',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.3823,
    longitude: -79.0968,
    postal_code: '15944'
  },
  '15945': {
    country: 'US',
    city: 'Parkhill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3612,
    longitude: -78.8709,
    postal_code: '15945'
  },
  '15946': {
    country: 'US',
    city: 'Portage',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3724,
    longitude: -78.6328,
    postal_code: '15946'
  },
  '15948': {
    country: 'US',
    city: 'Revloc',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.49,
    longitude: -78.7646,
    postal_code: '15948'
  },
  '15949': {
    country: 'US',
    city: 'Robinson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.4042,
    longitude: -79.1395,
    postal_code: '15949'
  },
  '15951': {
    country: 'US',
    city: 'Saint Michael',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.337,
    longitude: -78.7709,
    postal_code: '15951'
  },
  '15952': {
    country: 'US',
    city: 'Salix',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.2986,
    longitude: -78.7816,
    postal_code: '15952'
  },
  '15953': {
    country: 'US',
    city: 'Seanor',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.212,
    longitude: -78.8984,
    postal_code: '15953'
  },
  '15954': {
    country: 'US',
    city: 'Seward',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Westmoreland',
    latitude: 40.4099,
    longitude: -79.0232,
    postal_code: '15954'
  },
  '15955': {
    country: 'US',
    city: 'Sidman',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3299,
    longitude: -78.746,
    postal_code: '15955'
  },
  '15956': {
    country: 'US',
    city: 'South Fork',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3629,
    longitude: -78.7887,
    postal_code: '15956'
  },
  '15957': {
    country: 'US',
    city: 'Strongstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.5727,
    longitude: -78.9,
    postal_code: '15957'
  },
  '15958': {
    country: 'US',
    city: 'Summerhill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.389,
    longitude: -78.756,
    postal_code: '15958'
  },
  '15959': {
    country: 'US',
    city: 'Tire Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.268,
    longitude: -78.9156,
    postal_code: '15959'
  },
  '15960': {
    country: 'US',
    city: 'Twin Rocks',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.4967,
    longitude: -78.8659,
    postal_code: '15960'
  },
  '15961': {
    country: 'US',
    city: 'Vintondale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.4939,
    longitude: -78.9427,
    postal_code: '15961'
  },
  '15962': {
    country: 'US',
    city: 'Wilmore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.3887,
    longitude: -78.7186,
    postal_code: '15962'
  },
  '15963': {
    country: 'US',
    city: 'Windber',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Somerset',
    latitude: 40.2287,
    longitude: -78.8303,
    postal_code: '15963'
  },
  '16001': {
    country: 'US',
    city: 'Butler',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.8859,
    longitude: -79.934,
    postal_code: '16001'
  },
  '16002': {
    country: 'US',
    city: 'Butler',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.8409,
    longitude: -79.8592,
    postal_code: '16002'
  },
  '16003': {
    country: 'US',
    city: 'Butler',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.8612,
    longitude: -79.8953,
    postal_code: '16003'
  },
  '16016': {
    country: 'US',
    city: 'Boyers',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.1084,
    longitude: -79.8989,
    postal_code: '16016'
  },
  '16017': {
    country: 'US',
    city: 'Boyers',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.9211,
    longitude: -79.9276,
    postal_code: '16017'
  },
  '16018': {
    country: 'US',
    city: 'Boyers',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.9211,
    longitude: -79.9276,
    postal_code: '16018'
  },
  '16020': {
    country: 'US',
    city: 'Boyers',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.1092,
    longitude: -79.9047,
    postal_code: '16020'
  },
  '16021': {
    country: 'US',
    city: 'Branchton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.0726,
    longitude: -79.9856,
    postal_code: '16021'
  },
  '16022': {
    country: 'US',
    city: 'Bruin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.0571,
    longitude: -79.7291,
    postal_code: '16022'
  },
  '16023': {
    country: 'US',
    city: 'Cabot',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.7924,
    longitude: -79.76,
    postal_code: '16023'
  },
  '16024': {
    country: 'US',
    city: 'Callery',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.7406,
    longitude: -80.0364,
    postal_code: '16024'
  },
  '16025': {
    country: 'US',
    city: 'Chicora',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.9458,
    longitude: -79.7462,
    postal_code: '16025'
  },
  '16027': {
    country: 'US',
    city: 'Connoquenessing',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.8178,
    longitude: -80.0142,
    postal_code: '16027'
  },
  '16028': {
    country: 'US',
    city: 'East Brady',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 40.9744,
    longitude: -79.6302,
    postal_code: '16028'
  },
  '16029': {
    country: 'US',
    city: 'East Butler',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.8778,
    longitude: -79.8464,
    postal_code: '16029'
  },
  '16030': {
    country: 'US',
    city: 'Eau Claire',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.1348,
    longitude: -79.7981,
    postal_code: '16030'
  },
  '16033': {
    country: 'US',
    city: 'Evans City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.7808,
    longitude: -80.0592,
    postal_code: '16033'
  },
  '16034': {
    country: 'US',
    city: 'Fenelton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.8555,
    longitude: -79.7372,
    postal_code: '16034'
  },
  '16035': {
    country: 'US',
    city: 'Forestville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.1068,
    longitude: -80.007,
    postal_code: '16035'
  },
  '16036': {
    country: 'US',
    city: 'Foxburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.1431,
    longitude: -79.6798,
    postal_code: '16036'
  },
  '16037': {
    country: 'US',
    city: 'Harmony',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.8496,
    longitude: -80.1381,
    postal_code: '16037'
  },
  '16038': {
    country: 'US',
    city: 'Harrisville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.1631,
    longitude: -79.9796,
    postal_code: '16038'
  },
  '16039': {
    country: 'US',
    city: 'Herman',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.8301,
    longitude: -79.8112,
    postal_code: '16039'
  },
  '16040': {
    country: 'US',
    city: 'Hilliards',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.1009,
    longitude: -79.8215,
    postal_code: '16040'
  },
  '16041': {
    country: 'US',
    city: 'Karns City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.0022,
    longitude: -79.716,
    postal_code: '16041'
  },
  '16045': {
    country: 'US',
    city: 'Lyndora',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.8528,
    longitude: -79.9167,
    postal_code: '16045'
  },
  '16046': {
    country: 'US',
    city: 'Mars',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.7005,
    longitude: -80.0358,
    postal_code: '16046'
  },
  '16048': {
    country: 'US',
    city: 'North Washington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.0472,
    longitude: -79.8089,
    postal_code: '16048'
  },
  '16049': {
    country: 'US',
    city: 'Parker',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 41.1009,
    longitude: -79.6889,
    postal_code: '16049'
  },
  '16050': {
    country: 'US',
    city: 'Petrolia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.0442,
    longitude: -79.7711,
    postal_code: '16050'
  },
  '16051': {
    country: 'US',
    city: 'Portersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.9468,
    longitude: -80.139,
    postal_code: '16051'
  },
  '16052': {
    country: 'US',
    city: 'Prospect',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.9049,
    longitude: -80.0679,
    postal_code: '16052'
  },
  '16053': {
    country: 'US',
    city: 'Renfrew',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.81,
    longitude: -79.977,
    postal_code: '16053'
  },
  '16054': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.1617,
    longitude: -79.6528,
    postal_code: '16054'
  },
  '16055': {
    country: 'US',
    city: 'Sarver',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.7143,
    longitude: -79.7424,
    postal_code: '16055'
  },
  '16056': {
    country: 'US',
    city: 'Saxonburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.7361,
    longitude: -79.8352,
    postal_code: '16056'
  },
  '16057': {
    country: 'US',
    city: 'Slippery Rock',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.0454,
    longitude: -80.0468,
    postal_code: '16057'
  },
  '16058': {
    country: 'US',
    city: 'Turkey City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.1853,
    longitude: -79.6134,
    postal_code: '16058'
  },
  '16059': {
    country: 'US',
    city: 'Valencia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.7018,
    longitude: -79.9235,
    postal_code: '16059'
  },
  '16061': {
    country: 'US',
    city: 'West Sunbury',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 41.0026,
    longitude: -79.8751,
    postal_code: '16061'
  },
  '16063': {
    country: 'US',
    city: 'Zelienople',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.7609,
    longitude: -80.1094,
    postal_code: '16063'
  },
  '16066': {
    country: 'US',
    city: 'Cranberry Township',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Butler',
    latitude: 40.685,
    longitude: -80.1071,
    postal_code: '16066'
  },
  '16101': {
    country: 'US',
    city: 'New Castle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 40.9922,
    longitude: -80.3284,
    postal_code: '16101'
  },
  '16102': {
    country: 'US',
    city: 'New Castle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 40.9677,
    longitude: -80.3907,
    postal_code: '16102'
  },
  '16103': {
    country: 'US',
    city: 'New Castle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 40.9897,
    longitude: -80.3084,
    postal_code: '16103'
  },
  '16105': {
    country: 'US',
    city: 'New Castle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 41.0241,
    longitude: -80.3454,
    postal_code: '16105'
  },
  '16107': {
    country: 'US',
    city: 'New Castle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 40.9897,
    longitude: -80.3084,
    postal_code: '16107'
  },
  '16108': {
    country: 'US',
    city: 'New Castle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 40.9897,
    longitude: -80.3084,
    postal_code: '16108'
  },
  '16110': {
    country: 'US',
    city: 'Adamsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.5067,
    longitude: -80.3765,
    postal_code: '16110'
  },
  '16111': {
    country: 'US',
    city: 'Atlantic',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.5333,
    longitude: -80.2863,
    postal_code: '16111'
  },
  '16112': {
    country: 'US',
    city: 'Bessemer',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 40.9755,
    longitude: -80.4937,
    postal_code: '16112'
  },
  '16113': {
    country: 'US',
    city: 'Clark',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.2866,
    longitude: -80.4237,
    postal_code: '16113'
  },
  '16114': {
    country: 'US',
    city: 'Clarks Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.3931,
    longitude: -80.1837,
    postal_code: '16114'
  },
  '16115': {
    country: 'US',
    city: 'Darlington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.7968,
    longitude: -80.4556,
    postal_code: '16115'
  },
  '16116': {
    country: 'US',
    city: 'Edinburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 41.0276,
    longitude: -80.4632,
    postal_code: '16116'
  },
  '16117': {
    country: 'US',
    city: 'Ellwood City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 40.8729,
    longitude: -80.2559,
    postal_code: '16117'
  },
  '16120': {
    country: 'US',
    city: 'Enon Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 40.8721,
    longitude: -80.4612,
    postal_code: '16120'
  },
  '16121': {
    country: 'US',
    city: 'Farrell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.211,
    longitude: -80.4944,
    postal_code: '16121'
  },
  '16123': {
    country: 'US',
    city: 'Fombell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.8125,
    longitude: -80.2073,
    postal_code: '16123'
  },
  '16124': {
    country: 'US',
    city: 'Fredonia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.3241,
    longitude: -80.2699,
    postal_code: '16124'
  },
  '16125': {
    country: 'US',
    city: 'Greenville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.4057,
    longitude: -80.3739,
    postal_code: '16125'
  },
  '16127': {
    country: 'US',
    city: 'Grove City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.1607,
    longitude: -80.0841,
    postal_code: '16127'
  },
  '16130': {
    country: 'US',
    city: 'Hadley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.4432,
    longitude: -80.2153,
    postal_code: '16130'
  },
  '16131': {
    country: 'US',
    city: 'Hartstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.5509,
    longitude: -80.3813,
    postal_code: '16131'
  },
  '16132': {
    country: 'US',
    city: 'Hillsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 41.0113,
    longitude: -80.4975,
    postal_code: '16132'
  },
  '16133': {
    country: 'US',
    city: 'Jackson Center',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.2801,
    longitude: -80.1037,
    postal_code: '16133'
  },
  '16134': {
    country: 'US',
    city: 'Jamestown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.4566,
    longitude: -80.4492,
    postal_code: '16134'
  },
  '16136': {
    country: 'US',
    city: 'Koppel',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.8348,
    longitude: -80.328,
    postal_code: '16136'
  },
  '16137': {
    country: 'US',
    city: 'Mercer',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.2325,
    longitude: -80.234,
    postal_code: '16137'
  },
  '16140': {
    country: 'US',
    city: 'New Bedford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 41.0973,
    longitude: -80.5048,
    postal_code: '16140'
  },
  '16141': {
    country: 'US',
    city: 'New Galilee',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Beaver',
    latitude: 40.8569,
    longitude: -80.3939,
    postal_code: '16141'
  },
  '16142': {
    country: 'US',
    city: 'New Wilmington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 41.1382,
    longitude: -80.3245,
    postal_code: '16142'
  },
  '16143': {
    country: 'US',
    city: 'Pulaski',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 41.0942,
    longitude: -80.4685,
    postal_code: '16143'
  },
  '16145': {
    country: 'US',
    city: 'Sandy Lake',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.3383,
    longitude: -80.0497,
    postal_code: '16145'
  },
  '16146': {
    country: 'US',
    city: 'Sharon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.2316,
    longitude: -80.4993,
    postal_code: '16146'
  },
  '16148': {
    country: 'US',
    city: 'Hermitage',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.2326,
    longitude: -80.453,
    postal_code: '16148'
  },
  '16150': {
    country: 'US',
    city: 'Sharpsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.2676,
    longitude: -80.4656,
    postal_code: '16150'
  },
  '16151': {
    country: 'US',
    city: 'Sheakleyville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.4435,
    longitude: -80.2055,
    postal_code: '16151'
  },
  '16153': {
    country: 'US',
    city: 'Stoneboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.3439,
    longitude: -80.0976,
    postal_code: '16153'
  },
  '16154': {
    country: 'US',
    city: 'Transfer',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.3244,
    longitude: -80.4197,
    postal_code: '16154'
  },
  '16155': {
    country: 'US',
    city: 'Villa Maria',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 41.0737,
    longitude: -80.5073,
    postal_code: '16155'
  },
  '16156': {
    country: 'US',
    city: 'Volant',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 41.0938,
    longitude: -80.2441,
    postal_code: '16156'
  },
  '16157': {
    country: 'US',
    city: 'Wampum',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 40.8819,
    longitude: -80.3392,
    postal_code: '16157'
  },
  '16159': {
    country: 'US',
    city: 'West Middlesex',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.1741,
    longitude: -80.4528,
    postal_code: '16159'
  },
  '16160': {
    country: 'US',
    city: 'West Pittsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 40.9301,
    longitude: -80.3611,
    postal_code: '16160'
  },
  '16161': {
    country: 'US',
    city: 'Wheatland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.2009,
    longitude: -80.4978,
    postal_code: '16161'
  },
  '16172': {
    country: 'US',
    city: 'New Wilmington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lawrence',
    latitude: 41.12,
    longitude: -80.3332,
    postal_code: '16172'
  },
  '16201': {
    country: 'US',
    city: 'Kittanning',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.8155,
    longitude: -79.5107,
    postal_code: '16201'
  },
  '16210': {
    country: 'US',
    city: 'Adrian',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.9049,
    longitude: -79.5074,
    postal_code: '16210'
  },
  '16211': {
    country: 'US',
    city: 'Beyer',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7865,
    longitude: -79.2011,
    postal_code: '16211'
  },
  '16212': {
    country: 'US',
    city: 'Cadogan',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.7539,
    longitude: -79.5798,
    postal_code: '16212'
  },
  '16213': {
    country: 'US',
    city: 'Callensburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.1259,
    longitude: -79.5584,
    postal_code: '16213'
  },
  '16214': {
    country: 'US',
    city: 'Clarion',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.2123,
    longitude: -79.3773,
    postal_code: '16214'
  },
  '16217': {
    country: 'US',
    city: 'Cooksburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Forest',
    latitude: 41.3384,
    longitude: -79.1971,
    postal_code: '16217'
  },
  '16218': {
    country: 'US',
    city: 'Cowansville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.923,
    longitude: -79.5946,
    postal_code: '16218'
  },
  '16220': {
    country: 'US',
    city: 'Crown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.3898,
    longitude: -79.2709,
    postal_code: '16220'
  },
  '16221': {
    country: 'US',
    city: 'Curllsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.2023,
    longitude: -79.4538,
    postal_code: '16221'
  },
  '16222': {
    country: 'US',
    city: 'Dayton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.8741,
    longitude: -79.2686,
    postal_code: '16222'
  },
  '16223': {
    country: 'US',
    city: 'Distant',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.9695,
    longitude: -79.3567,
    postal_code: '16223'
  },
  '16224': {
    country: 'US',
    city: 'Fairmount City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.0429,
    longitude: -79.2784,
    postal_code: '16224'
  },
  '16225': {
    country: 'US',
    city: 'Fisher',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.2665,
    longitude: -79.2425,
    postal_code: '16225'
  },
  '16226': {
    country: 'US',
    city: 'Ford City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.7384,
    longitude: -79.5122,
    postal_code: '16226'
  },
  '16228': {
    country: 'US',
    city: 'Ford Cliff',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.7608,
    longitude: -79.5358,
    postal_code: '16228'
  },
  '16229': {
    country: 'US',
    city: 'Freeport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.7033,
    longitude: -79.663,
    postal_code: '16229'
  },
  '16230': {
    country: 'US',
    city: 'Hawthorn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.0178,
    longitude: -79.2799,
    postal_code: '16230'
  },
  '16232': {
    country: 'US',
    city: 'Knox',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.2245,
    longitude: -79.5194,
    postal_code: '16232'
  },
  '16233': {
    country: 'US',
    city: 'Leeper',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.3671,
    longitude: -79.3022,
    postal_code: '16233'
  },
  '16234': {
    country: 'US',
    city: 'Limestone',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.2023,
    longitude: -79.4538,
    postal_code: '16234'
  },
  '16235': {
    country: 'US',
    city: 'Lucinda',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.3124,
    longitude: -79.3754,
    postal_code: '16235'
  },
  '16236': {
    country: 'US',
    city: 'Mc Grann',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.7818,
    longitude: -79.5218,
    postal_code: '16236'
  },
  '16238': {
    country: 'US',
    city: 'Manorville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.7863,
    longitude: -79.5213,
    postal_code: '16238'
  },
  '16239': {
    country: 'US',
    city: 'Marienville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Forest',
    latitude: 41.4622,
    longitude: -79.1306,
    postal_code: '16239'
  },
  '16240': {
    country: 'US',
    city: 'Mayport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 40.9906,
    longitude: -79.2617,
    postal_code: '16240'
  },
  '16242': {
    country: 'US',
    city: 'New Bethlehem',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 40.9999,
    longitude: -79.3527,
    postal_code: '16242'
  },
  '16244': {
    country: 'US',
    city: 'Nu Mine',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.8478,
    longitude: -79.4516,
    postal_code: '16244'
  },
  '16245': {
    country: 'US',
    city: 'Oak Ridge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 41.0076,
    longitude: -79.2959,
    postal_code: '16245'
  },
  '16246': {
    country: 'US',
    city: 'Plumville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.7931,
    longitude: -79.1806,
    postal_code: '16246'
  },
  '16248': {
    country: 'US',
    city: 'Rimersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.0411,
    longitude: -79.5017,
    postal_code: '16248'
  },
  '16249': {
    country: 'US',
    city: 'Rural Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.7885,
    longitude: -79.2991,
    postal_code: '16249'
  },
  '16250': {
    country: 'US',
    city: 'Sagamore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.7774,
    longitude: -79.2336,
    postal_code: '16250'
  },
  '16253': {
    country: 'US',
    city: 'Seminole',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.9548,
    longitude: -79.3428,
    postal_code: '16253'
  },
  '16254': {
    country: 'US',
    city: 'Shippenville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.2475,
    longitude: -79.4332,
    postal_code: '16254'
  },
  '16255': {
    country: 'US',
    city: 'Sligo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.1139,
    longitude: -79.4805,
    postal_code: '16255'
  },
  '16256': {
    country: 'US',
    city: 'Smicksburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Indiana',
    latitude: 40.8372,
    longitude: -79.1614,
    postal_code: '16256'
  },
  '16257': {
    country: 'US',
    city: 'Snydersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.3328,
    longitude: -79.3556,
    postal_code: '16257'
  },
  '16258': {
    country: 'US',
    city: 'Strattanville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.1955,
    longitude: -79.3082,
    postal_code: '16258'
  },
  '16259': {
    country: 'US',
    city: 'Templeton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.9419,
    longitude: -79.4499,
    postal_code: '16259'
  },
  '16260': {
    country: 'US',
    city: 'Vowinckel',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.3957,
    longitude: -79.2266,
    postal_code: '16260'
  },
  '16261': {
    country: 'US',
    city: 'Widnoon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.9612,
    longitude: -79.4673,
    postal_code: '16261'
  },
  '16262': {
    country: 'US',
    city: 'Worthington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.8378,
    longitude: -79.6298,
    postal_code: '16262'
  },
  '16263': {
    country: 'US',
    city: 'Yatesboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Armstrong',
    latitude: 40.8001,
    longitude: -79.3342,
    postal_code: '16263'
  },
  '16301': {
    country: 'US',
    city: 'Oil City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.4319,
    longitude: -79.6916,
    postal_code: '16301'
  },
  '16311': {
    country: 'US',
    city: 'Carlton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mercer',
    latitude: 41.4815,
    longitude: -80.0203,
    postal_code: '16311'
  },
  '16312': {
    country: 'US',
    city: 'Chandlers Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.9358,
    longitude: -79.3097,
    postal_code: '16312'
  },
  '16313': {
    country: 'US',
    city: 'Clarendon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.7302,
    longitude: -79.1719,
    postal_code: '16313'
  },
  '16314': {
    country: 'US',
    city: 'Cochranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.5205,
    longitude: -80.0573,
    postal_code: '16314'
  },
  '16316': {
    country: 'US',
    city: 'Conneaut Lake',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.6189,
    longitude: -80.3086,
    postal_code: '16316'
  },
  '16317': {
    country: 'US',
    city: 'Cooperstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.498,
    longitude: -79.8757,
    postal_code: '16317'
  },
  '16319': {
    country: 'US',
    city: 'Cranberry',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.3197,
    longitude: -79.6317,
    postal_code: '16319'
  },
  '16321': {
    country: 'US',
    city: 'East Hickory',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Forest',
    latitude: 41.584,
    longitude: -79.4017,
    postal_code: '16321'
  },
  '16322': {
    country: 'US',
    city: 'Endeavor',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Forest',
    latitude: 41.5895,
    longitude: -79.385,
    postal_code: '16322'
  },
  '16323': {
    country: 'US',
    city: 'Franklin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.4048,
    longitude: -79.8309,
    postal_code: '16323'
  },
  '16326': {
    country: 'US',
    city: 'Fryburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.3717,
    longitude: -79.4134,
    postal_code: '16326'
  },
  '16327': {
    country: 'US',
    city: 'Guys Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.6333,
    longitude: -79.9714,
    postal_code: '16327'
  },
  '16328': {
    country: 'US',
    city: 'Hydetown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.6526,
    longitude: -79.727,
    postal_code: '16328'
  },
  '16329': {
    country: 'US',
    city: 'Irvine',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.8117,
    longitude: -79.2643,
    postal_code: '16329'
  },
  '16331': {
    country: 'US',
    city: 'Kossuth',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.2926,
    longitude: -79.5655,
    postal_code: '16331'
  },
  '16332': {
    country: 'US',
    city: 'Lickingville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.3789,
    longitude: -79.3715,
    postal_code: '16332'
  },
  '16333': {
    country: 'US',
    city: 'Ludlow',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.7284,
    longitude: -78.9434,
    postal_code: '16333'
  },
  '16334': {
    country: 'US',
    city: 'Marble',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.3261,
    longitude: -79.4459,
    postal_code: '16334'
  },
  '16335': {
    country: 'US',
    city: 'Meadville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.6338,
    longitude: -80.1488,
    postal_code: '16335'
  },
  '16340': {
    country: 'US',
    city: 'Pittsfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.8366,
    longitude: -79.4196,
    postal_code: '16340'
  },
  '16341': {
    country: 'US',
    city: 'Pleasantville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.5867,
    longitude: -79.5685,
    postal_code: '16341'
  },
  '16342': {
    country: 'US',
    city: 'Polk',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.3583,
    longitude: -79.9346,
    postal_code: '16342'
  },
  '16343': {
    country: 'US',
    city: 'Reno',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.411,
    longitude: -79.7493,
    postal_code: '16343'
  },
  '16344': {
    country: 'US',
    city: 'Rouseville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.4717,
    longitude: -79.6881,
    postal_code: '16344'
  },
  '16345': {
    country: 'US',
    city: 'Russell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.9461,
    longitude: -79.1271,
    postal_code: '16345'
  },
  '16346': {
    country: 'US',
    city: 'Seneca',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.3744,
    longitude: -79.6759,
    postal_code: '16346'
  },
  '16347': {
    country: 'US',
    city: 'Sheffield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.7005,
    longitude: -79.0348,
    postal_code: '16347'
  },
  '16350': {
    country: 'US',
    city: 'Sugar Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.9475,
    longitude: -79.3186,
    postal_code: '16350'
  },
  '16351': {
    country: 'US',
    city: 'Tidioute',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.703,
    longitude: -79.3752,
    postal_code: '16351'
  },
  '16352': {
    country: 'US',
    city: 'Tiona',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.7434,
    longitude: -79.0528,
    postal_code: '16352'
  },
  '16353': {
    country: 'US',
    city: 'Tionesta',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Forest',
    latitude: 41.5116,
    longitude: -79.3663,
    postal_code: '16353'
  },
  '16354': {
    country: 'US',
    city: 'Titusville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.6382,
    longitude: -79.6855,
    postal_code: '16354'
  },
  '16360': {
    country: 'US',
    city: 'Townville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.6856,
    longitude: -79.8767,
    postal_code: '16360'
  },
  '16361': {
    country: 'US',
    city: 'Tylersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.3848,
    longitude: -79.3223,
    postal_code: '16361'
  },
  '16362': {
    country: 'US',
    city: 'Utica',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.4798,
    longitude: -79.9403,
    postal_code: '16362'
  },
  '16364': {
    country: 'US',
    city: 'Venus',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.3761,
    longitude: -79.5048,
    postal_code: '16364'
  },
  '16365': {
    country: 'US',
    city: 'Warren',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.8436,
    longitude: -79.1726,
    postal_code: '16365'
  },
  '16366': {
    country: 'US',
    city: 'Warren',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.8439,
    longitude: -79.145,
    postal_code: '16366'
  },
  '16367': {
    country: 'US',
    city: 'Warren',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.8439,
    longitude: -79.145,
    postal_code: '16367'
  },
  '16368': {
    country: 'US',
    city: 'Irvine',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.8117,
    longitude: -79.2643,
    postal_code: '16368'
  },
  '16369': {
    country: 'US',
    city: 'Irvine',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.8117,
    longitude: -79.2643,
    postal_code: '16369'
  },
  '16370': {
    country: 'US',
    city: 'West Hickory',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Forest',
    latitude: 41.5687,
    longitude: -79.4081,
    postal_code: '16370'
  },
  '16371': {
    country: 'US',
    city: 'Youngsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.8537,
    longitude: -79.3187,
    postal_code: '16371'
  },
  '16372': {
    country: 'US',
    city: 'Clintonville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.2002,
    longitude: -79.8734,
    postal_code: '16372'
  },
  '16373': {
    country: 'US',
    city: 'Emlenton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.2028,
    longitude: -79.747,
    postal_code: '16373'
  },
  '16374': {
    country: 'US',
    city: 'Kennerdell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Venango',
    latitude: 41.2848,
    longitude: -79.7393,
    postal_code: '16374'
  },
  '16375': {
    country: 'US',
    city: 'Lamartine',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clarion',
    latitude: 41.2226,
    longitude: -79.6339,
    postal_code: '16375'
  },
  '16388': {
    country: 'US',
    city: 'Meadville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.6596,
    longitude: -80.1576,
    postal_code: '16388'
  },
  '16401': {
    country: 'US',
    city: 'Albion',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.8947,
    longitude: -80.3111,
    postal_code: '16401'
  },
  '16402': {
    country: 'US',
    city: 'Bear Lake',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.9701,
    longitude: -79.4614,
    postal_code: '16402'
  },
  '16403': {
    country: 'US',
    city: 'Cambridge Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.8034,
    longitude: -80.0594,
    postal_code: '16403'
  },
  '16404': {
    country: 'US',
    city: 'Centerville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.7243,
    longitude: -79.79,
    postal_code: '16404'
  },
  '16405': {
    country: 'US',
    city: 'Columbus',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.9382,
    longitude: -79.5731,
    postal_code: '16405'
  },
  '16406': {
    country: 'US',
    city: 'Conneautville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.7455,
    longitude: -80.3445,
    postal_code: '16406'
  },
  '16407': {
    country: 'US',
    city: 'Corry',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.9226,
    longitude: -79.6567,
    postal_code: '16407'
  },
  '16410': {
    country: 'US',
    city: 'Cranesville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.9162,
    longitude: -80.3085,
    postal_code: '16410'
  },
  '16411': {
    country: 'US',
    city: 'East Springfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.9794,
    longitude: -80.4303,
    postal_code: '16411'
  },
  '16412': {
    country: 'US',
    city: 'Edinboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.8756,
    longitude: -80.1356,
    postal_code: '16412'
  },
  '16413': {
    country: 'US',
    city: 'Elgin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.9031,
    longitude: -79.7437,
    postal_code: '16413'
  },
  '16415': {
    country: 'US',
    city: 'Fairview',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.0407,
    longitude: -80.2395,
    postal_code: '16415'
  },
  '16416': {
    country: 'US',
    city: 'Garland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.8188,
    longitude: -79.4463,
    postal_code: '16416'
  },
  '16417': {
    country: 'US',
    city: 'Girard',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.9896,
    longitude: -80.3178,
    postal_code: '16417'
  },
  '16420': {
    country: 'US',
    city: 'Grand Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.6963,
    longitude: -79.5473,
    postal_code: '16420'
  },
  '16421': {
    country: 'US',
    city: 'Harborcreek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1767,
    longitude: -79.9416,
    postal_code: '16421'
  },
  '16422': {
    country: 'US',
    city: 'Harmonsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.6609,
    longitude: -80.3178,
    postal_code: '16422'
  },
  '16423': {
    country: 'US',
    city: 'Lake City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.0204,
    longitude: -80.3388,
    postal_code: '16423'
  },
  '16424': {
    country: 'US',
    city: 'Linesville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.6244,
    longitude: -80.4523,
    postal_code: '16424'
  },
  '16426': {
    country: 'US',
    city: 'Mc Kean',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.999,
    longitude: -80.1473,
    postal_code: '16426'
  },
  '16427': {
    country: 'US',
    city: 'Mill Village',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.8773,
    longitude: -79.9692,
    postal_code: '16427'
  },
  '16428': {
    country: 'US',
    city: 'North East',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.2008,
    longitude: -79.8332,
    postal_code: '16428'
  },
  '16430': {
    country: 'US',
    city: 'North Springfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.9999,
    longitude: -80.4258,
    postal_code: '16430'
  },
  '16432': {
    country: 'US',
    city: 'Riceville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.7776,
    longitude: -79.8028,
    postal_code: '16432'
  },
  '16433': {
    country: 'US',
    city: 'Saegertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.7268,
    longitude: -80.1479,
    postal_code: '16433'
  },
  '16434': {
    country: 'US',
    city: 'Spartansburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.7936,
    longitude: -79.6849,
    postal_code: '16434'
  },
  '16435': {
    country: 'US',
    city: 'Springboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.8113,
    longitude: -80.3753,
    postal_code: '16435'
  },
  '16436': {
    country: 'US',
    city: 'Spring Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Warren',
    latitude: 41.92,
    longitude: -79.4482,
    postal_code: '16436'
  },
  '16438': {
    country: 'US',
    city: 'Union City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.8939,
    longitude: -79.8455,
    postal_code: '16438'
  },
  '16440': {
    country: 'US',
    city: 'Venango',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Crawford',
    latitude: 41.764,
    longitude: -80.0982,
    postal_code: '16440'
  },
  '16441': {
    country: 'US',
    city: 'Waterford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.9603,
    longitude: -79.9996,
    postal_code: '16441'
  },
  '16442': {
    country: 'US',
    city: 'Wattsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.0391,
    longitude: -79.8363,
    postal_code: '16442'
  },
  '16443': {
    country: 'US',
    city: 'West Springfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.9465,
    longitude: -80.465,
    postal_code: '16443'
  },
  '16444': {
    country: 'US',
    city: 'Edinboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.8707,
    longitude: -80.1218,
    postal_code: '16444'
  },
  '16475': {
    country: 'US',
    city: 'Albion',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 41.8906,
    longitude: -80.3665,
    postal_code: '16475'
  },
  '16501': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.126,
    longitude: -80.086,
    postal_code: '16501'
  },
  '16502': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1133,
    longitude: -80.0976,
    postal_code: '16502'
  },
  '16503': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1265,
    longitude: -80.064,
    postal_code: '16503'
  },
  '16504': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1108,
    longitude: -80.0521,
    postal_code: '16504'
  },
  '16505': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1109,
    longitude: -80.1534,
    postal_code: '16505'
  },
  '16506': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.0738,
    longitude: -80.1484,
    postal_code: '16506'
  },
  '16507': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1316,
    longitude: -80.0864,
    postal_code: '16507'
  },
  '16508': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.0976,
    longitude: -80.0935,
    postal_code: '16508'
  },
  '16509': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.0763,
    longitude: -80.0668,
    postal_code: '16509'
  },
  '16510': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1087,
    longitude: -79.9535,
    postal_code: '16510'
  },
  '16511': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1553,
    longitude: -80.0177,
    postal_code: '16511'
  },
  '16512': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1292,
    longitude: -80.0851,
    postal_code: '16512'
  },
  '16514': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1827,
    longitude: -80.0649,
    postal_code: '16514'
  },
  '16515': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1827,
    longitude: -80.0649,
    postal_code: '16515'
  },
  '16522': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1292,
    longitude: -80.0851,
    postal_code: '16522'
  },
  '16530': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1292,
    longitude: -80.0851,
    postal_code: '16530'
  },
  '16531': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1827,
    longitude: -80.0649,
    postal_code: '16531'
  },
  '16534': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1292,
    longitude: -80.0851,
    postal_code: '16534'
  },
  '16538': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1292,
    longitude: -80.0851,
    postal_code: '16538'
  },
  '16541': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1292,
    longitude: -80.0851,
    postal_code: '16541'
  },
  '16544': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1827,
    longitude: -80.0649,
    postal_code: '16544'
  },
  '16546': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1827,
    longitude: -80.0649,
    postal_code: '16546'
  },
  '16550': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1292,
    longitude: -80.0851,
    postal_code: '16550'
  },
  '16553': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1292,
    longitude: -80.0851,
    postal_code: '16553'
  },
  '16563': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1192,
    longitude: -79.9865,
    postal_code: '16563'
  },
  '16565': {
    country: 'US',
    city: 'Erie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Erie',
    latitude: 42.1827,
    longitude: -80.0649,
    postal_code: '16565'
  },
  '16601': {
    country: 'US',
    city: 'Altoona',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.5209,
    longitude: -78.4089,
    postal_code: '16601'
  },
  '16602': {
    country: 'US',
    city: 'Altoona',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.5052,
    longitude: -78.3905,
    postal_code: '16602'
  },
  '16603': {
    country: 'US',
    city: 'Altoona',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.5018,
    longitude: -78.41,
    postal_code: '16603'
  },
  '16611': {
    country: 'US',
    city: 'Alexandria',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.5485,
    longitude: -78.0951,
    postal_code: '16611'
  },
  '16613': {
    country: 'US',
    city: 'Ashville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.5513,
    longitude: -78.5346,
    postal_code: '16613'
  },
  '16616': {
    country: 'US',
    city: 'Beccaria',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.7683,
    longitude: -78.4455,
    postal_code: '16616'
  },
  '16617': {
    country: 'US',
    city: 'Bellwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.6039,
    longitude: -78.3372,
    postal_code: '16617'
  },
  '16619': {
    country: 'US',
    city: 'Blandburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6847,
    longitude: -78.4137,
    postal_code: '16619'
  },
  '16620': {
    country: 'US',
    city: 'Brisbin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.8387,
    longitude: -78.3526,
    postal_code: '16620'
  },
  '16621': {
    country: 'US',
    city: 'Broad Top',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.2019,
    longitude: -78.1406,
    postal_code: '16621'
  },
  '16622': {
    country: 'US',
    city: 'Calvin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.3367,
    longitude: -78.0272,
    postal_code: '16622'
  },
  '16623': {
    country: 'US',
    city: 'Cassville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.294,
    longitude: -78.0272,
    postal_code: '16623'
  },
  '16624': {
    country: 'US',
    city: 'Chest Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.5702,
    longitude: -78.5991,
    postal_code: '16624'
  },
  '16625': {
    country: 'US',
    city: 'Claysburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.2821,
    longitude: -78.5083,
    postal_code: '16625'
  },
  '16627': {
    country: 'US',
    city: 'Coalport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.7503,
    longitude: -78.5352,
    postal_code: '16627'
  },
  '16629': {
    country: 'US',
    city: 'Coupon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.5218,
    longitude: -78.5263,
    postal_code: '16629'
  },
  '16630': {
    country: 'US',
    city: 'Cresson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.4608,
    longitude: -78.5861,
    postal_code: '16630'
  },
  '16631': {
    country: 'US',
    city: 'Curryville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.2759,
    longitude: -78.3383,
    postal_code: '16631'
  },
  '16633': {
    country: 'US',
    city: 'Defiance',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.1601,
    longitude: -78.2346,
    postal_code: '16633'
  },
  '16634': {
    country: 'US',
    city: 'Dudley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.2054,
    longitude: -78.1814,
    postal_code: '16634'
  },
  '16635': {
    country: 'US',
    city: 'Duncansville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.4262,
    longitude: -78.4383,
    postal_code: '16635'
  },
  '16636': {
    country: 'US',
    city: 'Dysart',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6088,
    longitude: -78.5271,
    postal_code: '16636'
  },
  '16637': {
    country: 'US',
    city: 'East Freedom',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.3282,
    longitude: -78.4475,
    postal_code: '16637'
  },
  '16638': {
    country: 'US',
    city: 'Entriken',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.334,
    longitude: -78.1989,
    postal_code: '16638'
  },
  '16639': {
    country: 'US',
    city: 'Fallentimber',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6867,
    longitude: -78.4992,
    postal_code: '16639'
  },
  '16640': {
    country: 'US',
    city: 'Flinton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6876,
    longitude: -78.5523,
    postal_code: '16640'
  },
  '16641': {
    country: 'US',
    city: 'Gallitzin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.4823,
    longitude: -78.5517,
    postal_code: '16641'
  },
  '16644': {
    country: 'US',
    city: 'Glasgow',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.7178,
    longitude: -78.4637,
    postal_code: '16644'
  },
  '16645': {
    country: 'US',
    city: 'Glen Hope',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.7984,
    longitude: -78.4999,
    postal_code: '16645'
  },
  '16646': {
    country: 'US',
    city: 'Hastings',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6659,
    longitude: -78.7029,
    postal_code: '16646'
  },
  '16647': {
    country: 'US',
    city: 'Hesston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.4121,
    longitude: -78.1281,
    postal_code: '16647'
  },
  '16648': {
    country: 'US',
    city: 'Hollidaysburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.4387,
    longitude: -78.3686,
    postal_code: '16648'
  },
  '16650': {
    country: 'US',
    city: 'Hopewell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.1192,
    longitude: -78.3129,
    postal_code: '16650'
  },
  '16651': {
    country: 'US',
    city: 'Houtzdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.8305,
    longitude: -78.3618,
    postal_code: '16651'
  },
  '16652': {
    country: 'US',
    city: 'Huntingdon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.5023,
    longitude: -78.005,
    postal_code: '16652'
  },
  '16654': {
    country: 'US',
    city: 'Huntingdon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.4848,
    longitude: -78.0103,
    postal_code: '16654'
  },
  '16655': {
    country: 'US',
    city: 'Imler',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.2067,
    longitude: -78.5228,
    postal_code: '16655'
  },
  '16656': {
    country: 'US',
    city: 'Irvona',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.8017,
    longitude: -78.5602,
    postal_code: '16656'
  },
  '16657': {
    country: 'US',
    city: 'James Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.3567,
    longitude: -78.1887,
    postal_code: '16657'
  },
  '16659': {
    country: 'US',
    city: 'Loysburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.1609,
    longitude: -78.375,
    postal_code: '16659'
  },
  '16660': {
    country: 'US',
    city: 'Mc Connellstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.4025,
    longitude: -77.967,
    postal_code: '16660'
  },
  '16661': {
    country: 'US',
    city: 'Madera',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.8271,
    longitude: -78.4275,
    postal_code: '16661'
  },
  '16662': {
    country: 'US',
    city: 'Martinsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.2951,
    longitude: -78.3244,
    postal_code: '16662'
  },
  '16663': {
    country: 'US',
    city: 'Morann',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.7864,
    longitude: -78.367,
    postal_code: '16663'
  },
  '16664': {
    country: 'US',
    city: 'New Enterprise',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.2,
    longitude: -78.4259,
    postal_code: '16664'
  },
  '16665': {
    country: 'US',
    city: 'Newry',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.3937,
    longitude: -78.4356,
    postal_code: '16665'
  },
  '16666': {
    country: 'US',
    city: 'Osceola Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.8722,
    longitude: -78.2757,
    postal_code: '16666'
  },
  '16667': {
    country: 'US',
    city: 'Osterburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.1695,
    longitude: -78.5203,
    postal_code: '16667'
  },
  '16668': {
    country: 'US',
    city: 'Patton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.623,
    longitude: -78.635,
    postal_code: '16668'
  },
  '16669': {
    country: 'US',
    city: 'Petersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.603,
    longitude: -77.9984,
    postal_code: '16669'
  },
  '16670': {
    country: 'US',
    city: 'Queen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.2592,
    longitude: -78.5075,
    postal_code: '16670'
  },
  '16671': {
    country: 'US',
    city: 'Ramey',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.8015,
    longitude: -78.3998,
    postal_code: '16671'
  },
  '16672': {
    country: 'US',
    city: 'Riddlesburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.162,
    longitude: -78.2545,
    postal_code: '16672'
  },
  '16673': {
    country: 'US',
    city: 'Roaring Spring',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.3277,
    longitude: -78.3928,
    postal_code: '16673'
  },
  '16674': {
    country: 'US',
    city: 'Robertsdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.1787,
    longitude: -78.1117,
    postal_code: '16674'
  },
  '16675': {
    country: 'US',
    city: 'Saint Boniface',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.6712,
    longitude: -78.6755,
    postal_code: '16675'
  },
  '16677': {
    country: 'US',
    city: 'Sandy Ridge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8136,
    longitude: -78.239,
    postal_code: '16677'
  },
  '16678': {
    country: 'US',
    city: 'Saxton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.2233,
    longitude: -78.2471,
    postal_code: '16678'
  },
  '16679': {
    country: 'US',
    city: 'Six Mile Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.1576,
    longitude: -78.2108,
    postal_code: '16679'
  },
  '16680': {
    country: 'US',
    city: 'Smithmill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.7507,
    longitude: -78.4012,
    postal_code: '16680'
  },
  '16681': {
    country: 'US',
    city: 'Smokerun',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9891,
    longitude: -78.4224,
    postal_code: '16681'
  },
  '16682': {
    country: 'US',
    city: 'Sproul',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.2711,
    longitude: -78.4588,
    postal_code: '16682'
  },
  '16683': {
    country: 'US',
    city: 'Spruce Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.6218,
    longitude: -78.1361,
    postal_code: '16683'
  },
  '16684': {
    country: 'US',
    city: 'Tipton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.6367,
    longitude: -78.3036,
    postal_code: '16684'
  },
  '16685': {
    country: 'US',
    city: 'Todd',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.271,
    longitude: -78.0772,
    postal_code: '16685'
  },
  '16686': {
    country: 'US',
    city: 'Tyrone',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.6619,
    longitude: -78.2419,
    postal_code: '16686'
  },
  '16689': {
    country: 'US',
    city: 'Waterfall',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 40.1276,
    longitude: -78.0628,
    postal_code: '16689'
  },
  '16691': {
    country: 'US',
    city: 'Wells Tannery',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 40.101,
    longitude: -78.1403,
    postal_code: '16691'
  },
  '16692': {
    country: 'US',
    city: 'Westover',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.7615,
    longitude: -78.7355,
    postal_code: '16692'
  },
  '16693': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Blair',
    latitude: 40.4025,
    longitude: -78.2558,
    postal_code: '16693'
  },
  '16694': {
    country: 'US',
    city: 'Wood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.166,
    longitude: -78.1381,
    postal_code: '16694'
  },
  '16695': {
    country: 'US',
    city: 'Woodbury',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 40.2185,
    longitude: -78.3666,
    postal_code: '16695'
  },
  '16698': {
    country: 'US',
    city: 'Houtzdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.8251,
    longitude: -78.3511,
    postal_code: '16698'
  },
  '16699': {
    country: 'US',
    city: 'Cresson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cambria',
    latitude: 40.4845,
    longitude: -78.7022,
    postal_code: '16699'
  },
  '16701': {
    country: 'US',
    city: 'Bradford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.9547,
    longitude: -78.654,
    postal_code: '16701'
  },
  '16720': {
    country: 'US',
    city: 'Austin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.6296,
    longitude: -78.0908,
    postal_code: '16720'
  },
  '16724': {
    country: 'US',
    city: 'Crosby',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.7451,
    longitude: -78.3903,
    postal_code: '16724'
  },
  '16725': {
    country: 'US',
    city: 'Custer City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.9059,
    longitude: -78.6517,
    postal_code: '16725'
  },
  '16726': {
    country: 'US',
    city: 'Cyclone',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.8186,
    longitude: -78.5957,
    postal_code: '16726'
  },
  '16727': {
    country: 'US',
    city: 'Derrick City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.9726,
    longitude: -78.5626,
    postal_code: '16727'
  },
  '16728': {
    country: 'US',
    city: 'De Young',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.4159,
    longitude: -78.6651,
    postal_code: '16728'
  },
  '16729': {
    country: 'US',
    city: 'Duke Center',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.954,
    longitude: -78.4923,
    postal_code: '16729'
  },
  '16730': {
    country: 'US',
    city: 'East Smethport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.8087,
    longitude: -78.4195,
    postal_code: '16730'
  },
  '16731': {
    country: 'US',
    city: 'Eldred',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.9489,
    longitude: -78.3884,
    postal_code: '16731'
  },
  '16732': {
    country: 'US',
    city: 'Gifford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.8581,
    longitude: -78.5964,
    postal_code: '16732'
  },
  '16733': {
    country: 'US',
    city: 'Hazel Hurst',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.7059,
    longitude: -78.5825,
    postal_code: '16733'
  },
  '16734': {
    country: 'US',
    city: 'James City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Elk',
    latitude: 41.6192,
    longitude: -78.8394,
    postal_code: '16734'
  },
  '16735': {
    country: 'US',
    city: 'Kane',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.6619,
    longitude: -78.7978,
    postal_code: '16735'
  },
  '16738': {
    country: 'US',
    city: 'Lewis Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.8211,
    longitude: -78.6805,
    postal_code: '16738'
  },
  '16740': {
    country: 'US',
    city: 'Mount Jewett',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.7247,
    longitude: -78.6446,
    postal_code: '16740'
  },
  '16743': {
    country: 'US',
    city: 'Port Allegany',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.8169,
    longitude: -78.2799,
    postal_code: '16743'
  },
  '16744': {
    country: 'US',
    city: 'Rew',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.9015,
    longitude: -78.5392,
    postal_code: '16744'
  },
  '16745': {
    country: 'US',
    city: 'Rixford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.9262,
    longitude: -78.4942,
    postal_code: '16745'
  },
  '16746': {
    country: 'US',
    city: 'Roulette',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.7738,
    longitude: -78.1538,
    postal_code: '16746'
  },
  '16748': {
    country: 'US',
    city: 'Shinglehouse',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.9572,
    longitude: -78.1906,
    postal_code: '16748'
  },
  '16749': {
    country: 'US',
    city: 'Smethport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.8021,
    longitude: -78.4702,
    postal_code: '16749'
  },
  '16750': {
    country: 'US',
    city: 'Turtlepoint',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'McKean',
    latitude: 41.8847,
    longitude: -78.3308,
    postal_code: '16750'
  },
  '16801': {
    country: 'US',
    city: 'State College',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.7925,
    longitude: -77.8523,
    postal_code: '16801'
  },
  '16802': {
    country: 'US',
    city: 'University Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.7997,
    longitude: -77.8623,
    postal_code: '16802'
  },
  '16803': {
    country: 'US',
    city: 'State College',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8082,
    longitude: -77.8926,
    postal_code: '16803'
  },
  '16804': {
    country: 'US',
    city: 'State College',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.7934,
    longitude: -77.86,
    postal_code: '16804'
  },
  '16805': {
    country: 'US',
    city: 'State College',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.7934,
    longitude: -77.86,
    postal_code: '16805'
  },
  '16820': {
    country: 'US',
    city: 'Aaronsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8987,
    longitude: -77.4562,
    postal_code: '16820'
  },
  '16821': {
    country: 'US',
    city: 'Allport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.967,
    longitude: -78.2014,
    postal_code: '16821'
  },
  '16822': {
    country: 'US',
    city: 'Beech Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.0845,
    longitude: -77.5851,
    postal_code: '16822'
  },
  '16823': {
    country: 'US',
    city: 'Bellefonte',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8978,
    longitude: -77.7732,
    postal_code: '16823'
  },
  '16825': {
    country: 'US',
    city: 'Bigler',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9848,
    longitude: -78.3064,
    postal_code: '16825'
  },
  '16826': {
    country: 'US',
    city: 'Blanchard',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 41.0642,
    longitude: -77.5978,
    postal_code: '16826'
  },
  '16827': {
    country: 'US',
    city: 'Boalsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.7793,
    longitude: -77.7822,
    postal_code: '16827'
  },
  '16828': {
    country: 'US',
    city: 'Centre Hall',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8254,
    longitude: -77.6742,
    postal_code: '16828'
  },
  '16829': {
    country: 'US',
    city: 'Clarence',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 41.0585,
    longitude: -77.9312,
    postal_code: '16829'
  },
  '16830': {
    country: 'US',
    city: 'Clearfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.021,
    longitude: -78.4435,
    postal_code: '16830'
  },
  '16832': {
    country: 'US',
    city: 'Coburn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8383,
    longitude: -77.4509,
    postal_code: '16832'
  },
  '16833': {
    country: 'US',
    city: 'Curwensville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.966,
    longitude: -78.5272,
    postal_code: '16833'
  },
  '16834': {
    country: 'US',
    city: 'Drifting',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.0242,
    longitude: -78.1083,
    postal_code: '16834'
  },
  '16835': {
    country: 'US',
    city: 'Fleming',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.9046,
    longitude: -77.8752,
    postal_code: '16835'
  },
  '16836': {
    country: 'US',
    city: 'Frenchville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.1038,
    longitude: -78.2345,
    postal_code: '16836'
  },
  '16837': {
    country: 'US',
    city: 'Glen Richey',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9458,
    longitude: -78.475,
    postal_code: '16837'
  },
  '16838': {
    country: 'US',
    city: 'Grampian',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9818,
    longitude: -78.5949,
    postal_code: '16838'
  },
  '16839': {
    country: 'US',
    city: 'Grassflat',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.0036,
    longitude: -78.1104,
    postal_code: '16839'
  },
  '16840': {
    country: 'US',
    city: 'Hawk Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.923,
    longitude: -78.2037,
    postal_code: '16840'
  },
  '16841': {
    country: 'US',
    city: 'Howard',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 41.0203,
    longitude: -77.6702,
    postal_code: '16841'
  },
  '16843': {
    country: 'US',
    city: 'Hyde',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.003,
    longitude: -78.4642,
    postal_code: '16843'
  },
  '16844': {
    country: 'US',
    city: 'Julian',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8917,
    longitude: -77.9332,
    postal_code: '16844'
  },
  '16845': {
    country: 'US',
    city: 'Karthaus',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.1136,
    longitude: -78.0875,
    postal_code: '16845'
  },
  '16847': {
    country: 'US',
    city: 'Kylertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9934,
    longitude: -78.1672,
    postal_code: '16847'
  },
  '16848': {
    country: 'US',
    city: 'Lamar',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.0162,
    longitude: -77.5311,
    postal_code: '16848'
  },
  '16849': {
    country: 'US',
    city: 'Lanse',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9605,
    longitude: -78.1144,
    postal_code: '16849'
  },
  '16850': {
    country: 'US',
    city: 'Lecontes Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.0831,
    longitude: -78.2833,
    postal_code: '16850'
  },
  '16851': {
    country: 'US',
    city: 'Lemont',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8082,
    longitude: -77.8125,
    postal_code: '16851'
  },
  '16852': {
    country: 'US',
    city: 'Madisonburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.9334,
    longitude: -77.495,
    postal_code: '16852'
  },
  '16853': {
    country: 'US',
    city: 'Milesburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.9417,
    longitude: -77.785,
    postal_code: '16853'
  },
  '16854': {
    country: 'US',
    city: 'Millheim',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8935,
    longitude: -77.4733,
    postal_code: '16854'
  },
  '16855': {
    country: 'US',
    city: 'Mineral Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9981,
    longitude: -78.3636,
    postal_code: '16855'
  },
  '16856': {
    country: 'US',
    city: 'Mingoville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.9299,
    longitude: -77.6389,
    postal_code: '16856'
  },
  '16858': {
    country: 'US',
    city: 'Morrisdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.0001,
    longitude: -78.2357,
    postal_code: '16858'
  },
  '16859': {
    country: 'US',
    city: 'Moshannon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 41.0342,
    longitude: -78.0095,
    postal_code: '16859'
  },
  '16860': {
    country: 'US',
    city: 'Munson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9468,
    longitude: -78.1718,
    postal_code: '16860'
  },
  '16861': {
    country: 'US',
    city: 'New Millport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.8652,
    longitude: -78.5237,
    postal_code: '16861'
  },
  '16863': {
    country: 'US',
    city: 'Olanta',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9056,
    longitude: -78.5001,
    postal_code: '16863'
  },
  '16864': {
    country: 'US',
    city: 'Orviston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.9722,
    longitude: -77.7602,
    postal_code: '16864'
  },
  '16865': {
    country: 'US',
    city: 'Pennsylvania Furnace',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.71,
    longitude: -77.996,
    postal_code: '16865'
  },
  '16866': {
    country: 'US',
    city: 'Philipsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8863,
    longitude: -78.219,
    postal_code: '16866'
  },
  '16868': {
    country: 'US',
    city: 'Pine Grove Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.7337,
    longitude: -77.8856,
    postal_code: '16868'
  },
  '16870': {
    country: 'US',
    city: 'Port Matilda',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8018,
    longitude: -78.0788,
    postal_code: '16870'
  },
  '16871': {
    country: 'US',
    city: 'Pottersdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.1868,
    longitude: -78.0341,
    postal_code: '16871'
  },
  '16872': {
    country: 'US',
    city: 'Rebersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.9549,
    longitude: -77.4053,
    postal_code: '16872'
  },
  '16873': {
    country: 'US',
    city: 'Shawville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.0692,
    longitude: -78.3581,
    postal_code: '16873'
  },
  '16874': {
    country: 'US',
    city: 'Snow Shoe',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 41.0376,
    longitude: -77.9523,
    postal_code: '16874'
  },
  '16875': {
    country: 'US',
    city: 'Spring Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.8578,
    longitude: -77.574,
    postal_code: '16875'
  },
  '16876': {
    country: 'US',
    city: 'Wallaceton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9616,
    longitude: -78.2926,
    postal_code: '16876'
  },
  '16877': {
    country: 'US',
    city: 'Warriors Mark',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.7414,
    longitude: -78.0775,
    postal_code: '16877'
  },
  '16878': {
    country: 'US',
    city: 'West Decatur',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9493,
    longitude: -78.3129,
    postal_code: '16878'
  },
  '16879': {
    country: 'US',
    city: 'Winburne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 40.9696,
    longitude: -78.1508,
    postal_code: '16879'
  },
  '16881': {
    country: 'US',
    city: 'Woodland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clearfield',
    latitude: 41.0098,
    longitude: -78.3214,
    postal_code: '16881'
  },
  '16882': {
    country: 'US',
    city: 'Woodward',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Centre',
    latitude: 40.9116,
    longitude: -77.3483,
    postal_code: '16882'
  },
  '16901': {
    country: 'US',
    city: 'Wellsboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.7373,
    longitude: -77.308,
    postal_code: '16901'
  },
  '16910': {
    country: 'US',
    city: 'Alba',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.7051,
    longitude: -76.8283,
    postal_code: '16910'
  },
  '16911': {
    country: 'US',
    city: 'Arnot',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.6626,
    longitude: -77.123,
    postal_code: '16911'
  },
  '16912': {
    country: 'US',
    city: 'Blossburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.6698,
    longitude: -77.0797,
    postal_code: '16912'
  },
  '16914': {
    country: 'US',
    city: 'Columbia Cross Roads',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.8638,
    longitude: -76.7825,
    postal_code: '16914'
  },
  '16915': {
    country: 'US',
    city: 'Coudersport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.7762,
    longitude: -77.9567,
    postal_code: '16915'
  },
  '16917': {
    country: 'US',
    city: 'Covington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.7448,
    longitude: -77.0772,
    postal_code: '16917'
  },
  '16920': {
    country: 'US',
    city: 'Elkland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.9882,
    longitude: -77.3134,
    postal_code: '16920'
  },
  '16921': {
    country: 'US',
    city: 'Gaines',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.7471,
    longitude: -77.568,
    postal_code: '16921'
  },
  '16922': {
    country: 'US',
    city: 'Galeton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.723,
    longitude: -77.6548,
    postal_code: '16922'
  },
  '16923': {
    country: 'US',
    city: 'Genesee',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.9401,
    longitude: -77.8725,
    postal_code: '16923'
  },
  '16925': {
    country: 'US',
    city: 'Gillett',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.9568,
    longitude: -76.7713,
    postal_code: '16925'
  },
  '16926': {
    country: 'US',
    city: 'Granville Summit',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.6973,
    longitude: -76.7218,
    postal_code: '16926'
  },
  '16927': {
    country: 'US',
    city: 'Harrison Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.9587,
    longitude: -77.6587,
    postal_code: '16927'
  },
  '16928': {
    country: 'US',
    city: 'Knoxville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.9596,
    longitude: -77.4357,
    postal_code: '16928'
  },
  '16929': {
    country: 'US',
    city: 'Lawrenceville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.9783,
    longitude: -77.1136,
    postal_code: '16929'
  },
  '16930': {
    country: 'US',
    city: 'Liberty',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.5656,
    longitude: -77.1195,
    postal_code: '16930'
  },
  '16932': {
    country: 'US',
    city: 'Mainesburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.784,
    longitude: -76.9983,
    postal_code: '16932'
  },
  '16933': {
    country: 'US',
    city: 'Mansfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.8123,
    longitude: -77.0716,
    postal_code: '16933'
  },
  '16935': {
    country: 'US',
    city: 'Middlebury Center',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.8664,
    longitude: -77.3128,
    postal_code: '16935'
  },
  '16936': {
    country: 'US',
    city: 'Millerton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.9625,
    longitude: -76.9748,
    postal_code: '16936'
  },
  '16937': {
    country: 'US',
    city: 'Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.9656,
    longitude: -77.7111,
    postal_code: '16937'
  },
  '16938': {
    country: 'US',
    city: 'Morris',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.5475,
    longitude: -77.292,
    postal_code: '16938'
  },
  '16939': {
    country: 'US',
    city: 'Morris Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.6775,
    longitude: -77.0152,
    postal_code: '16939'
  },
  '16940': {
    country: 'US',
    city: 'Nelson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.9787,
    longitude: -77.2419,
    postal_code: '16940'
  },
  '16941': {
    country: 'US',
    city: 'Genesee',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.9883,
    longitude: -77.758,
    postal_code: '16941'
  },
  '16942': {
    country: 'US',
    city: 'Osceola',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.9848,
    longitude: -77.354,
    postal_code: '16942'
  },
  '16943': {
    country: 'US',
    city: 'Sabinsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.8408,
    longitude: -77.6155,
    postal_code: '16943'
  },
  '16945': {
    country: 'US',
    city: 'Sylvania',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.8051,
    longitude: -76.8569,
    postal_code: '16945'
  },
  '16946': {
    country: 'US',
    city: 'Tioga',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.9125,
    longitude: -77.1393,
    postal_code: '16946'
  },
  '16947': {
    country: 'US',
    city: 'Troy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.7781,
    longitude: -76.7711,
    postal_code: '16947'
  },
  '16948': {
    country: 'US',
    city: 'Ulysses',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.8459,
    longitude: -77.7126,
    postal_code: '16948'
  },
  '16950': {
    country: 'US',
    city: 'Westfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.9193,
    longitude: -77.523,
    postal_code: '16950'
  },
  '17001': {
    country: 'US',
    city: 'Camp Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.216,
    longitude: -76.925,
    postal_code: '17001'
  },
  '17002': {
    country: 'US',
    city: 'Allensville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.5075,
    longitude: -77.8392,
    postal_code: '17002'
  },
  '17003': {
    country: 'US',
    city: 'Annville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.3456,
    longitude: -76.5447,
    postal_code: '17003'
  },
  '17004': {
    country: 'US',
    city: 'Belleville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.6016,
    longitude: -77.7358,
    postal_code: '17004'
  },
  '17005': {
    country: 'US',
    city: 'Berrysburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.601,
    longitude: -76.8117,
    postal_code: '17005'
  },
  '17006': {
    country: 'US',
    city: 'Blain',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.3293,
    longitude: -77.5117,
    postal_code: '17006'
  },
  '17007': {
    country: 'US',
    city: 'Boiling Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.1449,
    longitude: -77.1195,
    postal_code: '17007'
  },
  '17009': {
    country: 'US',
    city: 'Burnham',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.6361,
    longitude: -77.5625,
    postal_code: '17009'
  },
  '17010': {
    country: 'US',
    city: 'Campbelltown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.2776,
    longitude: -76.5852,
    postal_code: '17010'
  },
  '17011': {
    country: 'US',
    city: 'Camp Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.2352,
    longitude: -76.9291,
    postal_code: '17011'
  },
  '17012': {
    country: 'US',
    city: 'Camp Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.2398,
    longitude: -76.92,
    postal_code: '17012'
  },
  '17013': {
    country: 'US',
    city: 'Carlisle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.2417,
    longitude: -77.1983,
    postal_code: '17013'
  },
  '17014': {
    country: 'US',
    city: 'Cocolamus',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.6479,
    longitude: -77.2125,
    postal_code: '17014'
  },
  '17015': {
    country: 'US',
    city: 'Carlisle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.1772,
    longitude: -77.2312,
    postal_code: '17015'
  },
  '17016': {
    country: 'US',
    city: 'Cornwall',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.2755,
    longitude: -76.4053,
    postal_code: '17016'
  },
  '17017': {
    country: 'US',
    city: 'Dalmatia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.6483,
    longitude: -76.8797,
    postal_code: '17017'
  },
  '17018': {
    country: 'US',
    city: 'Dauphin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.3846,
    longitude: -76.9283,
    postal_code: '17018'
  },
  '17019': {
    country: 'US',
    city: 'Dillsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.0964,
    longitude: -77.0339,
    postal_code: '17019'
  },
  '17020': {
    country: 'US',
    city: 'Duncannon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.4087,
    longitude: -77.0473,
    postal_code: '17020'
  },
  '17021': {
    country: 'US',
    city: 'East Waterford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.3542,
    longitude: -77.6528,
    postal_code: '17021'
  },
  '17022': {
    country: 'US',
    city: 'Elizabethtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1553,
    longitude: -76.6025,
    postal_code: '17022'
  },
  '17023': {
    country: 'US',
    city: 'Elizabethville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.555,
    longitude: -76.8355,
    postal_code: '17023'
  },
  '17024': {
    country: 'US',
    city: 'Elliottsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.4096,
    longitude: -77.3121,
    postal_code: '17024'
  },
  '17025': {
    country: 'US',
    city: 'Enola',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.2922,
    longitude: -76.9432,
    postal_code: '17025'
  },
  '17026': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.4524,
    longitude: -76.4267,
    postal_code: '17026'
  },
  '17027': {
    country: 'US',
    city: 'Grantham',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.158,
    longitude: -76.9964,
    postal_code: '17027'
  },
  '17028': {
    country: 'US',
    city: 'Grantville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.3606,
    longitude: -76.6713,
    postal_code: '17028'
  },
  '17029': {
    country: 'US',
    city: 'Granville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.5537,
    longitude: -77.6255,
    postal_code: '17029'
  },
  '17030': {
    country: 'US',
    city: 'Gratz',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.6082,
    longitude: -76.7375,
    postal_code: '17030'
  },
  '17032': {
    country: 'US',
    city: 'Halifax',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.476,
    longitude: -76.894,
    postal_code: '17032'
  },
  '17033': {
    country: 'US',
    city: 'Hershey',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2638,
    longitude: -76.6545,
    postal_code: '17033'
  },
  '17034': {
    country: 'US',
    city: 'Highspire',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2083,
    longitude: -76.7853,
    postal_code: '17034'
  },
  '17035': {
    country: 'US',
    city: 'Honey Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.4309,
    longitude: -77.5761,
    postal_code: '17035'
  },
  '17036': {
    country: 'US',
    city: 'Hummelstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2782,
    longitude: -76.7094,
    postal_code: '17036'
  },
  '17037': {
    country: 'US',
    city: 'Ickesburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.4363,
    longitude: -77.397,
    postal_code: '17037'
  },
  '17038': {
    country: 'US',
    city: 'Jonestown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.4361,
    longitude: -76.5038,
    postal_code: '17038'
  },
  '17039': {
    country: 'US',
    city: 'Kleinfeltersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.3005,
    longitude: -76.2584,
    postal_code: '17039'
  },
  '17040': {
    country: 'US',
    city: 'Landisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.3326,
    longitude: -77.3191,
    postal_code: '17040'
  },
  '17041': {
    country: 'US',
    city: 'Lawn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.2236,
    longitude: -76.538,
    postal_code: '17041'
  },
  '17042': {
    country: 'US',
    city: 'Lebanon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.3316,
    longitude: -76.3976,
    postal_code: '17042'
  },
  '17043': {
    country: 'US',
    city: 'Lemoyne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.2469,
    longitude: -76.9001,
    postal_code: '17043'
  },
  '17044': {
    country: 'US',
    city: 'Lewistown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.5994,
    longitude: -77.5756,
    postal_code: '17044'
  },
  '17045': {
    country: 'US',
    city: 'Liverpool',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.5753,
    longitude: -77.0083,
    postal_code: '17045'
  },
  '17046': {
    country: 'US',
    city: 'Lebanon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.3812,
    longitude: -76.4368,
    postal_code: '17046'
  },
  '17047': {
    country: 'US',
    city: 'Loysville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.3658,
    longitude: -77.4138,
    postal_code: '17047'
  },
  '17048': {
    country: 'US',
    city: 'Lykens',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.5909,
    longitude: -76.7074,
    postal_code: '17048'
  },
  '17049': {
    country: 'US',
    city: 'Mc Alisterville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.6469,
    longitude: -77.2602,
    postal_code: '17049'
  },
  '17050': {
    country: 'US',
    city: 'Mechanicsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.2122,
    longitude: -77.0265,
    postal_code: '17050'
  },
  '17051': {
    country: 'US',
    city: 'Mc Veytown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.5046,
    longitude: -77.7186,
    postal_code: '17051'
  },
  '17052': {
    country: 'US',
    city: 'Mapleton Depot',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.3864,
    longitude: -77.9604,
    postal_code: '17052'
  },
  '17053': {
    country: 'US',
    city: 'Marysville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.3351,
    longitude: -76.9722,
    postal_code: '17053'
  },
  '17054': {
    country: 'US',
    city: 'Mattawana',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.496,
    longitude: -77.7237,
    postal_code: '17054'
  },
  '17055': {
    country: 'US',
    city: 'Mechanicsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.179,
    longitude: -77.0036,
    postal_code: '17055'
  },
  '17056': {
    country: 'US',
    city: 'Mexico',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.5373,
    longitude: -77.3543,
    postal_code: '17056'
  },
  '17057': {
    country: 'US',
    city: 'Middletown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2041,
    longitude: -76.7331,
    postal_code: '17057'
  },
  '17058': {
    country: 'US',
    city: 'Mifflin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.5695,
    longitude: -77.4028,
    postal_code: '17058'
  },
  '17059': {
    country: 'US',
    city: 'Mifflintown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.5727,
    longitude: -77.3761,
    postal_code: '17059'
  },
  '17060': {
    country: 'US',
    city: 'Mill Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.4679,
    longitude: -77.8967,
    postal_code: '17060'
  },
  '17061': {
    country: 'US',
    city: 'Millersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.5587,
    longitude: -76.9305,
    postal_code: '17061'
  },
  '17062': {
    country: 'US',
    city: 'Millerstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.5505,
    longitude: -77.1298,
    postal_code: '17062'
  },
  '17063': {
    country: 'US',
    city: 'Milroy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.7203,
    longitude: -77.5567,
    postal_code: '17063'
  },
  '17064': {
    country: 'US',
    city: 'Mount Gretna',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.2476,
    longitude: -76.4711,
    postal_code: '17064'
  },
  '17065': {
    country: 'US',
    city: 'Mount Holly Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.1115,
    longitude: -77.1902,
    postal_code: '17065'
  },
  '17066': {
    country: 'US',
    city: 'Mount Union',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.3901,
    longitude: -77.8637,
    postal_code: '17066'
  },
  '17067': {
    country: 'US',
    city: 'Myerstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.3789,
    longitude: -76.3143,
    postal_code: '17067'
  },
  '17068': {
    country: 'US',
    city: 'New Bloomfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.4193,
    longitude: -77.1938,
    postal_code: '17068'
  },
  '17069': {
    country: 'US',
    city: 'New Buffalo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.4543,
    longitude: -76.9697,
    postal_code: '17069'
  },
  '17070': {
    country: 'US',
    city: 'New Cumberland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.2151,
    longitude: -76.8689,
    postal_code: '17070'
  },
  '17071': {
    country: 'US',
    city: 'New Germantown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.309,
    longitude: -77.5697,
    postal_code: '17071'
  },
  '17072': {
    country: 'US',
    city: 'New Kingstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.2306,
    longitude: -77.0794,
    postal_code: '17072'
  },
  '17073': {
    country: 'US',
    city: 'Newmanstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.3495,
    longitude: -76.2133,
    postal_code: '17073'
  },
  '17074': {
    country: 'US',
    city: 'Newport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.4827,
    longitude: -77.1659,
    postal_code: '17074'
  },
  '17075': {
    country: 'US',
    city: 'Newton Hamilton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.3931,
    longitude: -77.8316,
    postal_code: '17075'
  },
  '17076': {
    country: 'US',
    city: 'Oakland Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.6147,
    longitude: -77.3192,
    postal_code: '17076'
  },
  '17077': {
    country: 'US',
    city: 'Ono',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.4029,
    longitude: -76.5347,
    postal_code: '17077'
  },
  '17078': {
    country: 'US',
    city: 'Palmyra',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.3011,
    longitude: -76.5886,
    postal_code: '17078'
  },
  '17080': {
    country: 'US',
    city: 'Pillow',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.6392,
    longitude: -76.8029,
    postal_code: '17080'
  },
  '17081': {
    country: 'US',
    city: 'Plainfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.203,
    longitude: -77.2848,
    postal_code: '17081'
  },
  '17082': {
    country: 'US',
    city: 'Port Royal',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.5107,
    longitude: -77.431,
    postal_code: '17082'
  },
  '17083': {
    country: 'US',
    city: 'Quentin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.2796,
    longitude: -76.4397,
    postal_code: '17083'
  },
  '17084': {
    country: 'US',
    city: 'Reedsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.6722,
    longitude: -77.6116,
    postal_code: '17084'
  },
  '17085': {
    country: 'US',
    city: 'Rexmont',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.2771,
    longitude: -76.3857,
    postal_code: '17085'
  },
  '17086': {
    country: 'US',
    city: 'Richfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.6884,
    longitude: -77.1223,
    postal_code: '17086'
  },
  '17087': {
    country: 'US',
    city: 'Richland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.3806,
    longitude: -76.2654,
    postal_code: '17087'
  },
  '17088': {
    country: 'US',
    city: 'Schaefferstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lebanon',
    latitude: 40.2984,
    longitude: -76.2944,
    postal_code: '17088'
  },
  '17089': {
    country: 'US',
    city: 'Camp Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.2697,
    longitude: -76.936,
    postal_code: '17089'
  },
  '17090': {
    country: 'US',
    city: 'Shermans Dale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Perry',
    latitude: 40.3299,
    longitude: -77.1809,
    postal_code: '17090'
  },
  '17093': {
    country: 'US',
    city: 'Summerdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.3078,
    longitude: -76.9312,
    postal_code: '17093'
  },
  '17094': {
    country: 'US',
    city: 'Thompsontown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Juniata',
    latitude: 40.5908,
    longitude: -77.2076,
    postal_code: '17094'
  },
  '17097': {
    country: 'US',
    city: 'Wiconisco',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.5734,
    longitude: -76.6914,
    postal_code: '17097'
  },
  '17098': {
    country: 'US',
    city: 'Williamstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.5808,
    longitude: -76.6223,
    postal_code: '17098'
  },
  '17099': {
    country: 'US',
    city: 'Yeagertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Mifflin',
    latitude: 40.6431,
    longitude: -77.5805,
    postal_code: '17099'
  },
  '17101': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2618,
    longitude: -76.8831,
    postal_code: '17101'
  },
  '17102': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2728,
    longitude: -76.891,
    postal_code: '17102'
  },
  '17103': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2724,
    longitude: -76.861,
    postal_code: '17103'
  },
  '17104': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2597,
    longitude: -76.8594,
    postal_code: '17104'
  },
  '17105': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2785,
    longitude: -76.8752,
    postal_code: '17105'
  },
  '17106': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2927,
    longitude: -76.8501,
    postal_code: '17106'
  },
  '17107': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.297,
    longitude: -76.8764,
    postal_code: '17107'
  },
  '17108': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.3086,
    longitude: -76.8017,
    postal_code: '17108'
  },
  '17109': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.291,
    longitude: -76.8203,
    postal_code: '17109'
  },
  '17110': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.303,
    longitude: -76.8862,
    postal_code: '17110'
  },
  '17111': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2721,
    longitude: -76.8017,
    postal_code: '17111'
  },
  '17112': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.3352,
    longitude: -76.7914,
    postal_code: '17112'
  },
  '17113': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.239,
    longitude: -76.8416,
    postal_code: '17113'
  },
  '17120': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2657,
    longitude: -76.8827,
    postal_code: '17120'
  },
  '17121': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2944,
    longitude: -76.8938,
    postal_code: '17121'
  },
  '17122': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2498,
    longitude: -76.8712,
    postal_code: '17122'
  },
  '17123': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2675,
    longitude: -76.8837,
    postal_code: '17123'
  },
  '17124': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2675,
    longitude: -76.886,
    postal_code: '17124'
  },
  '17125': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2662,
    longitude: -76.8829,
    postal_code: '17125'
  },
  '17126': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2618,
    longitude: -76.88,
    postal_code: '17126'
  },
  '17127': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2615,
    longitude: -76.8809,
    postal_code: '17127'
  },
  '17128': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2737,
    longitude: -76.8844,
    postal_code: '17128'
  },
  '17129': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2615,
    longitude: -76.8809,
    postal_code: '17129'
  },
  '17130': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.2702,
    longitude: -76.8829,
    postal_code: '17130'
  },
  '17140': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.3086,
    longitude: -76.8464,
    postal_code: '17140'
  },
  '17177': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Dauphin',
    latitude: 40.299,
    longitude: -76.8472,
    postal_code: '17177'
  },
  '17201': {
    country: 'US',
    city: 'Chambersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.9313,
    longitude: -77.6579,
    postal_code: '17201'
  },
  '17202': {
    country: 'US',
    city: 'Chambersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.9072,
    longitude: -77.636,
    postal_code: '17202'
  },
  '17210': {
    country: 'US',
    city: 'Amberson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.1704,
    longitude: -77.6772,
    postal_code: '17210'
  },
  '17211': {
    country: 'US',
    city: 'Artemas',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bedford',
    latitude: 39.7575,
    longitude: -78.4031,
    postal_code: '17211'
  },
  '17212': {
    country: 'US',
    city: 'Big Cove Tannery',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 39.8127,
    longitude: -78.0645,
    postal_code: '17212'
  },
  '17213': {
    country: 'US',
    city: 'Blairs Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.2548,
    longitude: -77.7695,
    postal_code: '17213'
  },
  '17214': {
    country: 'US',
    city: 'Blue Ridge Summit',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.7399,
    longitude: -77.4707,
    postal_code: '17214'
  },
  '17215': {
    country: 'US',
    city: 'Burnt Cabins',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 40.0753,
    longitude: -77.9017,
    postal_code: '17215'
  },
  '17217': {
    country: 'US',
    city: 'Concord',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.2252,
    longitude: -77.725,
    postal_code: '17217'
  },
  '17219': {
    country: 'US',
    city: 'Doylesburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.2184,
    longitude: -77.6797,
    postal_code: '17219'
  },
  '17220': {
    country: 'US',
    city: 'Dry Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.1781,
    longitude: -77.7353,
    postal_code: '17220'
  },
  '17221': {
    country: 'US',
    city: 'Fannettsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.0717,
    longitude: -77.821,
    postal_code: '17221'
  },
  '17222': {
    country: 'US',
    city: 'Fayetteville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.9065,
    longitude: -77.531,
    postal_code: '17222'
  },
  '17223': {
    country: 'US',
    city: 'Fort Littleton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 40.078,
    longitude: -77.9532,
    postal_code: '17223'
  },
  '17224': {
    country: 'US',
    city: 'Fort Loudon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.9547,
    longitude: -77.8984,
    postal_code: '17224'
  },
  '17225': {
    country: 'US',
    city: 'Greencastle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.7818,
    longitude: -77.747,
    postal_code: '17225'
  },
  '17228': {
    country: 'US',
    city: 'Harrisonville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 39.9761,
    longitude: -78.0841,
    postal_code: '17228'
  },
  '17229': {
    country: 'US',
    city: 'Hustontown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 40.0441,
    longitude: -78.0148,
    postal_code: '17229'
  },
  '17231': {
    country: 'US',
    city: 'Lemasters',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.8594,
    longitude: -77.8579,
    postal_code: '17231'
  },
  '17232': {
    country: 'US',
    city: 'Lurgan',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.1055,
    longitude: -77.6405,
    postal_code: '17232'
  },
  '17233': {
    country: 'US',
    city: 'Mc Connellsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 39.9443,
    longitude: -77.9901,
    postal_code: '17233'
  },
  '17235': {
    country: 'US',
    city: 'Marion',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.8587,
    longitude: -77.6981,
    postal_code: '17235'
  },
  '17236': {
    country: 'US',
    city: 'Mercersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.8195,
    longitude: -77.9073,
    postal_code: '17236'
  },
  '17237': {
    country: 'US',
    city: 'Mont Alto',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.8417,
    longitude: -77.5537,
    postal_code: '17237'
  },
  '17238': {
    country: 'US',
    city: 'Needmore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 39.8713,
    longitude: -78.1439,
    postal_code: '17238'
  },
  '17239': {
    country: 'US',
    city: 'Neelyton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.1371,
    longitude: -77.858,
    postal_code: '17239'
  },
  '17240': {
    country: 'US',
    city: 'Newburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.1333,
    longitude: -77.5669,
    postal_code: '17240'
  },
  '17241': {
    country: 'US',
    city: 'Newville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.1855,
    longitude: -77.4114,
    postal_code: '17241'
  },
  '17243': {
    country: 'US',
    city: 'Orbisonia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.2389,
    longitude: -77.9069,
    postal_code: '17243'
  },
  '17244': {
    country: 'US',
    city: 'Orrstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.0731,
    longitude: -77.6398,
    postal_code: '17244'
  },
  '17246': {
    country: 'US',
    city: 'Pleasant Hall',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.0531,
    longitude: -77.6539,
    postal_code: '17246'
  },
  '17247': {
    country: 'US',
    city: 'Quincy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.7995,
    longitude: -77.5811,
    postal_code: '17247'
  },
  '17249': {
    country: 'US',
    city: 'Rockhill Furnace',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.2414,
    longitude: -77.8995,
    postal_code: '17249'
  },
  '17250': {
    country: 'US',
    city: 'Rouzerville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.7364,
    longitude: -77.5247,
    postal_code: '17250'
  },
  '17251': {
    country: 'US',
    city: 'Roxbury',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.1104,
    longitude: -77.6619,
    postal_code: '17251'
  },
  '17252': {
    country: 'US',
    city: 'Saint Thomas',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.9241,
    longitude: -77.7908,
    postal_code: '17252'
  },
  '17253': {
    country: 'US',
    city: 'Saltillo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.2133,
    longitude: -78.0069,
    postal_code: '17253'
  },
  '17254': {
    country: 'US',
    city: 'Scotland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.9696,
    longitude: -77.5848,
    postal_code: '17254'
  },
  '17255': {
    country: 'US',
    city: 'Shade Gap',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.173,
    longitude: -77.868,
    postal_code: '17255'
  },
  '17256': {
    country: 'US',
    city: 'Shady Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.7834,
    longitude: -77.6749,
    postal_code: '17256'
  },
  '17257': {
    country: 'US',
    city: 'Shippensburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.0514,
    longitude: -77.5195,
    postal_code: '17257'
  },
  '17260': {
    country: 'US',
    city: 'Shirleysburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.2979,
    longitude: -77.8742,
    postal_code: '17260'
  },
  '17261': {
    country: 'US',
    city: 'South Mountain',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.8465,
    longitude: -77.4878,
    postal_code: '17261'
  },
  '17262': {
    country: 'US',
    city: 'Spring Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.1734,
    longitude: -77.7092,
    postal_code: '17262'
  },
  '17263': {
    country: 'US',
    city: 'State Line',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.7248,
    longitude: -77.7186,
    postal_code: '17263'
  },
  '17264': {
    country: 'US',
    city: 'Three Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Huntingdon',
    latitude: 40.1834,
    longitude: -77.9941,
    postal_code: '17264'
  },
  '17265': {
    country: 'US',
    city: 'Upperstrasburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.058,
    longitude: -77.7368,
    postal_code: '17265'
  },
  '17266': {
    country: 'US',
    city: 'Walnut Bottom',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Cumberland',
    latitude: 40.086,
    longitude: -77.409,
    postal_code: '17266'
  },
  '17267': {
    country: 'US',
    city: 'Warfordsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Fulton',
    latitude: 39.7698,
    longitude: -78.1986,
    postal_code: '17267'
  },
  '17268': {
    country: 'US',
    city: 'Waynesboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.7635,
    longitude: -77.5674,
    postal_code: '17268'
  },
  '17271': {
    country: 'US',
    city: 'Willow Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 40.1137,
    longitude: -77.7969,
    postal_code: '17271'
  },
  '17272': {
    country: 'US',
    city: 'Zullinger',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Franklin',
    latitude: 39.7714,
    longitude: -77.627,
    postal_code: '17272'
  },
  '17301': {
    country: 'US',
    city: 'Abbottstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.8881,
    longitude: -76.9931,
    postal_code: '17301'
  },
  '17302': {
    country: 'US',
    city: 'Airville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.821,
    longitude: -76.4012,
    postal_code: '17302'
  },
  '17303': {
    country: 'US',
    city: 'Arendtsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.9236,
    longitude: -77.3001,
    postal_code: '17303'
  },
  '17304': {
    country: 'US',
    city: 'Aspers',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.9765,
    longitude: -77.2287,
    postal_code: '17304'
  },
  '17306': {
    country: 'US',
    city: 'Bendersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.9792,
    longitude: -77.2496,
    postal_code: '17306'
  },
  '17307': {
    country: 'US',
    city: 'Biglerville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.9281,
    longitude: -77.2885,
    postal_code: '17307'
  },
  '17309': {
    country: 'US',
    city: 'Brogue',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.8608,
    longitude: -76.4673,
    postal_code: '17309'
  },
  '17310': {
    country: 'US',
    city: 'Cashtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.8906,
    longitude: -77.3566,
    postal_code: '17310'
  },
  '17311': {
    country: 'US',
    city: 'Codorus',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.8718,
    longitude: -76.7599,
    postal_code: '17311'
  },
  '17312': {
    country: 'US',
    city: 'Craley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9467,
    longitude: -76.5066,
    postal_code: '17312'
  },
  '17313': {
    country: 'US',
    city: 'Dallastown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9124,
    longitude: -76.6535,
    postal_code: '17313'
  },
  '17314': {
    country: 'US',
    city: 'Delta',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.7518,
    longitude: -76.3441,
    postal_code: '17314'
  },
  '17315': {
    country: 'US',
    city: 'Dover',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.0062,
    longitude: -76.8555,
    postal_code: '17315'
  },
  '17316': {
    country: 'US',
    city: 'East Berlin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.9645,
    longitude: -77.0073,
    postal_code: '17316'
  },
  '17317': {
    country: 'US',
    city: 'East Prospect',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9701,
    longitude: -76.5217,
    postal_code: '17317'
  },
  '17318': {
    country: 'US',
    city: 'Emigsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.0215,
    longitude: -76.7266,
    postal_code: '17318'
  },
  '17319': {
    country: 'US',
    city: 'Etters',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.1545,
    longitude: -76.8019,
    postal_code: '17319'
  },
  '17320': {
    country: 'US',
    city: 'Fairfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.7808,
    longitude: -77.3619,
    postal_code: '17320'
  },
  '17321': {
    country: 'US',
    city: 'Fawn Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.751,
    longitude: -76.4392,
    postal_code: '17321'
  },
  '17322': {
    country: 'US',
    city: 'Felton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.836,
    longitude: -76.5937,
    postal_code: '17322'
  },
  '17323': {
    country: 'US',
    city: 'Franklintown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.075,
    longitude: -77.0279,
    postal_code: '17323'
  },
  '17324': {
    country: 'US',
    city: 'Gardners',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 40.0428,
    longitude: -77.1877,
    postal_code: '17324'
  },
  '17325': {
    country: 'US',
    city: 'Gettysburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.832,
    longitude: -77.2223,
    postal_code: '17325'
  },
  '17327': {
    country: 'US',
    city: 'Glen Rock',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.7813,
    longitude: -76.7477,
    postal_code: '17327'
  },
  '17329': {
    country: 'US',
    city: 'Glenville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.757,
    longitude: -76.8153,
    postal_code: '17329'
  },
  '17331': {
    country: 'US',
    city: 'Hanover',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.7943,
    longitude: -76.9812,
    postal_code: '17331'
  },
  '17332': {
    country: 'US',
    city: 'Hanover',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.8007,
    longitude: -76.983,
    postal_code: '17332'
  },
  '17333': {
    country: 'US',
    city: 'Hanover',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.8007,
    longitude: -76.983,
    postal_code: '17333'
  },
  '17334': {
    country: 'US',
    city: 'Hanover',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.805,
    longitude: -76.9736,
    postal_code: '17334'
  },
  '17335': {
    country: 'US',
    city: 'Hanover',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.8144,
    longitude: -76.9837,
    postal_code: '17335'
  },
  '17337': {
    country: 'US',
    city: 'Idaville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 40.0157,
    longitude: -77.2001,
    postal_code: '17337'
  },
  '17339': {
    country: 'US',
    city: 'Lewisberry',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.1463,
    longitude: -76.87,
    postal_code: '17339'
  },
  '17340': {
    country: 'US',
    city: 'Littlestown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.7495,
    longitude: -77.1003,
    postal_code: '17340'
  },
  '17342': {
    country: 'US',
    city: 'Loganville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.8536,
    longitude: -76.708,
    postal_code: '17342'
  },
  '17343': {
    country: 'US',
    city: 'Mc Knightstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.8692,
    longitude: -77.3292,
    postal_code: '17343'
  },
  '17344': {
    country: 'US',
    city: 'Mc Sherrystown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.801,
    longitude: -77.0229,
    postal_code: '17344'
  },
  '17345': {
    country: 'US',
    city: 'Manchester',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.0695,
    longitude: -76.7332,
    postal_code: '17345'
  },
  '17347': {
    country: 'US',
    city: 'Mount Wolf',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.0711,
    longitude: -76.6966,
    postal_code: '17347'
  },
  '17349': {
    country: 'US',
    city: 'New Freedom',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.7423,
    longitude: -76.6841,
    postal_code: '17349'
  },
  '17350': {
    country: 'US',
    city: 'New Oxford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.8775,
    longitude: -77.0643,
    postal_code: '17350'
  },
  '17352': {
    country: 'US',
    city: 'New Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.76,
    longitude: -76.5042,
    postal_code: '17352'
  },
  '17353': {
    country: 'US',
    city: 'Orrtanna',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.881,
    longitude: -77.3806,
    postal_code: '17353'
  },
  '17354': {
    country: 'US',
    city: 'Porters Sideling',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.8246,
    longitude: -76.8993,
    postal_code: '17354'
  },
  '17355': {
    country: 'US',
    city: 'Railroad',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.7573,
    longitude: -76.6994,
    postal_code: '17355'
  },
  '17356': {
    country: 'US',
    city: 'Red Lion',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9026,
    longitude: -76.6081,
    postal_code: '17356'
  },
  '17358': {
    country: 'US',
    city: 'Rossville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.973,
    longitude: -76.6878,
    postal_code: '17358'
  },
  '17360': {
    country: 'US',
    city: 'Seven Valleys',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.8556,
    longitude: -76.7383,
    postal_code: '17360'
  },
  '17361': {
    country: 'US',
    city: 'Shrewsbury',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.7601,
    longitude: -76.6748,
    postal_code: '17361'
  },
  '17362': {
    country: 'US',
    city: 'Spring Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.8572,
    longitude: -76.8774,
    postal_code: '17362'
  },
  '17363': {
    country: 'US',
    city: 'Stewartstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.772,
    longitude: -76.597,
    postal_code: '17363'
  },
  '17364': {
    country: 'US',
    city: 'Thomasville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9346,
    longitude: -76.8822,
    postal_code: '17364'
  },
  '17365': {
    country: 'US',
    city: 'Wellsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.0557,
    longitude: -76.9443,
    postal_code: '17365'
  },
  '17366': {
    country: 'US',
    city: 'Windsor',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9233,
    longitude: -76.5591,
    postal_code: '17366'
  },
  '17368': {
    country: 'US',
    city: 'Wrightsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9966,
    longitude: -76.527,
    postal_code: '17368'
  },
  '17370': {
    country: 'US',
    city: 'York Haven',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.1222,
    longitude: -76.7737,
    postal_code: '17370'
  },
  '17371': {
    country: 'US',
    city: 'York New Salem',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9006,
    longitude: -76.7896,
    postal_code: '17371'
  },
  '17372': {
    country: 'US',
    city: 'York Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 40.0084,
    longitude: -77.1061,
    postal_code: '17372'
  },
  '17375': {
    country: 'US',
    city: 'Peach Glen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Adams',
    latitude: 39.8948,
    longitude: -77.2135,
    postal_code: '17375'
  },
  '17401': {
    country: 'US',
    city: 'York',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9635,
    longitude: -76.7269,
    postal_code: '17401'
  },
  '17402': {
    country: 'US',
    city: 'York',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.959,
    longitude: -76.6592,
    postal_code: '17402'
  },
  '17403': {
    country: 'US',
    city: 'York',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9494,
    longitude: -76.713,
    postal_code: '17403'
  },
  '17404': {
    country: 'US',
    city: 'York',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.0023,
    longitude: -76.7712,
    postal_code: '17404'
  },
  '17405': {
    country: 'US',
    city: 'York',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.0086,
    longitude: -76.5972,
    postal_code: '17405'
  },
  '17406': {
    country: 'US',
    city: 'York',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 40.0046,
    longitude: -76.5947,
    postal_code: '17406'
  },
  '17407': {
    country: 'US',
    city: 'York',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.8833,
    longitude: -76.712,
    postal_code: '17407'
  },
  '17408': {
    country: 'US',
    city: 'York',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'York',
    latitude: 39.9492,
    longitude: -76.8018,
    postal_code: '17408'
  },
  '17501': {
    country: 'US',
    city: 'Akron',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1573,
    longitude: -76.2042,
    postal_code: '17501'
  },
  '17502': {
    country: 'US',
    city: 'Bainbridge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1086,
    longitude: -76.6726,
    postal_code: '17502'
  },
  '17503': {
    country: 'US',
    city: 'Bart',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0185,
    longitude: -76.2976,
    postal_code: '17503'
  },
  '17504': {
    country: 'US',
    city: 'Bausman',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.024,
    longitude: -76.3281,
    postal_code: '17504'
  },
  '17505': {
    country: 'US',
    city: 'Bird In Hand',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0561,
    longitude: -76.183,
    postal_code: '17505'
  },
  '17506': {
    country: 'US',
    city: 'Blue Ball',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1173,
    longitude: -76.0524,
    postal_code: '17506'
  },
  '17507': {
    country: 'US',
    city: 'Bowmansville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1966,
    longitude: -76.016,
    postal_code: '17507'
  },
  '17508': {
    country: 'US',
    city: 'Brownstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.124,
    longitude: -76.2176,
    postal_code: '17508'
  },
  '17509': {
    country: 'US',
    city: 'Christiana',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.904,
    longitude: -76.0401,
    postal_code: '17509'
  },
  '17512': {
    country: 'US',
    city: 'Columbia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0391,
    longitude: -76.4862,
    postal_code: '17512'
  },
  '17516': {
    country: 'US',
    city: 'Conestoga',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.9403,
    longitude: -76.3575,
    postal_code: '17516'
  },
  '17517': {
    country: 'US',
    city: 'Denver',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.2297,
    longitude: -76.1157,
    postal_code: '17517'
  },
  '17518': {
    country: 'US',
    city: 'Drumore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.8183,
    longitude: -76.2499,
    postal_code: '17518'
  },
  '17519': {
    country: 'US',
    city: 'East Earl',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1395,
    longitude: -76.0276,
    postal_code: '17519'
  },
  '17520': {
    country: 'US',
    city: 'East Petersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1008,
    longitude: -76.3512,
    postal_code: '17520'
  },
  '17521': {
    country: 'US',
    city: 'Elm',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.2044,
    longitude: -76.3464,
    postal_code: '17521'
  },
  '17522': {
    country: 'US',
    city: 'Ephrata',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1756,
    longitude: -76.1821,
    postal_code: '17522'
  },
  '17527': {
    country: 'US',
    city: 'Gap',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.002,
    longitude: -75.9978,
    postal_code: '17527'
  },
  '17528': {
    country: 'US',
    city: 'Goodville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1257,
    longitude: -76.0033,
    postal_code: '17528'
  },
  '17529': {
    country: 'US',
    city: 'Gordonville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0353,
    longitude: -76.1106,
    postal_code: '17529'
  },
  '17532': {
    country: 'US',
    city: 'Holtwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.8631,
    longitude: -76.3008,
    postal_code: '17532'
  },
  '17533': {
    country: 'US',
    city: 'Hopeland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.2339,
    longitude: -76.2607,
    postal_code: '17533'
  },
  '17534': {
    country: 'US',
    city: 'Intercourse',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0367,
    longitude: -76.1069,
    postal_code: '17534'
  },
  '17535': {
    country: 'US',
    city: 'Kinzers',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0053,
    longitude: -76.0493,
    postal_code: '17535'
  },
  '17536': {
    country: 'US',
    city: 'Kirkwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.8257,
    longitude: -76.0933,
    postal_code: '17536'
  },
  '17537': {
    country: 'US',
    city: 'Lampeter',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.9885,
    longitude: -76.2387,
    postal_code: '17537'
  },
  '17538': {
    country: 'US',
    city: 'Landisville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0892,
    longitude: -76.4156,
    postal_code: '17538'
  },
  '17540': {
    country: 'US',
    city: 'Leola',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0964,
    longitude: -76.1921,
    postal_code: '17540'
  },
  '17543': {
    country: 'US',
    city: 'Lititz',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1846,
    longitude: -76.3015,
    postal_code: '17543'
  },
  '17545': {
    country: 'US',
    city: 'Manheim',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1702,
    longitude: -76.4168,
    postal_code: '17545'
  },
  '17547': {
    country: 'US',
    city: 'Marietta',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0664,
    longitude: -76.5645,
    postal_code: '17547'
  },
  '17549': {
    country: 'US',
    city: 'Martindale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1545,
    longitude: -76.0876,
    postal_code: '17549'
  },
  '17550': {
    country: 'US',
    city: 'Maytown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0754,
    longitude: -76.5822,
    postal_code: '17550'
  },
  '17551': {
    country: 'US',
    city: 'Millersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.9982,
    longitude: -76.3566,
    postal_code: '17551'
  },
  '17552': {
    country: 'US',
    city: 'Mount Joy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1083,
    longitude: -76.5103,
    postal_code: '17552'
  },
  '17554': {
    country: 'US',
    city: 'Mountville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0427,
    longitude: -76.4277,
    postal_code: '17554'
  },
  '17555': {
    country: 'US',
    city: 'Narvon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1252,
    longitude: -75.9756,
    postal_code: '17555'
  },
  '17557': {
    country: 'US',
    city: 'New Holland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1005,
    longitude: -76.0801,
    postal_code: '17557'
  },
  '17560': {
    country: 'US',
    city: 'New Providence',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.9098,
    longitude: -76.2243,
    postal_code: '17560'
  },
  '17562': {
    country: 'US',
    city: 'Paradise',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.9852,
    longitude: -76.1081,
    postal_code: '17562'
  },
  '17563': {
    country: 'US',
    city: 'Peach Bottom',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.7705,
    longitude: -76.1791,
    postal_code: '17563'
  },
  '17564': {
    country: 'US',
    city: 'Penryn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0185,
    longitude: -76.2976,
    postal_code: '17564'
  },
  '17565': {
    country: 'US',
    city: 'Pequea',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.9058,
    longitude: -76.3209,
    postal_code: '17565'
  },
  '17566': {
    country: 'US',
    city: 'Quarryville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.8949,
    longitude: -76.1465,
    postal_code: '17566'
  },
  '17567': {
    country: 'US',
    city: 'Reamstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.2099,
    longitude: -76.1164,
    postal_code: '17567'
  },
  '17568': {
    country: 'US',
    city: 'Refton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.9469,
    longitude: -76.2322,
    postal_code: '17568'
  },
  '17569': {
    country: 'US',
    city: 'Reinholds',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.2688,
    longitude: -76.1013,
    postal_code: '17569'
  },
  '17570': {
    country: 'US',
    city: 'Rheems',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1301,
    longitude: -76.5705,
    postal_code: '17570'
  },
  '17572': {
    country: 'US',
    city: 'Ronks',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0208,
    longitude: -76.1661,
    postal_code: '17572'
  },
  '17573': {
    country: 'US',
    city: 'Ronks',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0262,
    longitude: -76.1686,
    postal_code: '17573'
  },
  '17575': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0647,
    longitude: -76.4343,
    postal_code: '17575'
  },
  '17576': {
    country: 'US',
    city: 'Smoketown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0376,
    longitude: -76.1969,
    postal_code: '17576'
  },
  '17578': {
    country: 'US',
    city: 'Stevens',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.2194,
    longitude: -76.1626,
    postal_code: '17578'
  },
  '17579': {
    country: 'US',
    city: 'Strasburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.9701,
    longitude: -76.1848,
    postal_code: '17579'
  },
  '17580': {
    country: 'US',
    city: 'Talmage',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1168,
    longitude: -76.2131,
    postal_code: '17580'
  },
  '17581': {
    country: 'US',
    city: 'Terre Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1585,
    longitude: -76.0511,
    postal_code: '17581'
  },
  '17582': {
    country: 'US',
    city: 'Washington Boro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.9881,
    longitude: -76.4402,
    postal_code: '17582'
  },
  '17583': {
    country: 'US',
    city: 'West Willow',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.9723,
    longitude: -76.2873,
    postal_code: '17583'
  },
  '17584': {
    country: 'US',
    city: 'Willow Street',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 39.967,
    longitude: -76.2752,
    postal_code: '17584'
  },
  '17585': {
    country: 'US',
    city: 'Witmer',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0484,
    longitude: -76.2114,
    postal_code: '17585'
  },
  '17601': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0766,
    longitude: -76.3107,
    postal_code: '17601'
  },
  '17602': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0335,
    longitude: -76.2844,
    postal_code: '17602'
  },
  '17603': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0091,
    longitude: -76.3671,
    postal_code: '17603'
  },
  '17604': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0651,
    longitude: -76.3356,
    postal_code: '17604'
  },
  '17605': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0185,
    longitude: -76.2976,
    postal_code: '17605'
  },
  '17606': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.1102,
    longitude: -76.3054,
    postal_code: '17606'
  },
  '17607': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0185,
    longitude: -76.2976,
    postal_code: '17607'
  },
  '17608': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0185,
    longitude: -76.2976,
    postal_code: '17608'
  },
  '17611': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0379,
    longitude: -76.3055,
    postal_code: '17611'
  },
  '17622': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0418,
    longitude: -76.3012,
    postal_code: '17622'
  },
  '17699': {
    country: 'US',
    city: 'Lancaster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.0185,
    longitude: -76.2976,
    postal_code: '17699'
  },
  '17701': {
    country: 'US',
    city: 'Williamsport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.2412,
    longitude: -77.0011,
    postal_code: '17701'
  },
  '17702': {
    country: 'US',
    city: 'Williamsport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.1943,
    longitude: -77.0547,
    postal_code: '17702'
  },
  '17703': {
    country: 'US',
    city: 'Williamsport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.2412,
    longitude: -77.0011,
    postal_code: '17703'
  },
  '17705': {
    country: 'US',
    city: 'Williamsport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.3327,
    longitude: -77.0236,
    postal_code: '17705'
  },
  '17720': {
    country: 'US',
    city: 'Antes Fort',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.1865,
    longitude: -77.2184,
    postal_code: '17720'
  },
  '17721': {
    country: 'US',
    city: 'Avis',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.186,
    longitude: -77.3162,
    postal_code: '17721'
  },
  '17723': {
    country: 'US',
    city: 'Jersey Shore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.432,
    longitude: -77.4562,
    postal_code: '17723'
  },
  '17724': {
    country: 'US',
    city: 'Canton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.6538,
    longitude: -76.8582,
    postal_code: '17724'
  },
  '17726': {
    country: 'US',
    city: 'Castanea',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.1227,
    longitude: -77.4317,
    postal_code: '17726'
  },
  '17727': {
    country: 'US',
    city: 'Cedar Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.5299,
    longitude: -77.4917,
    postal_code: '17727'
  },
  '17728': {
    country: 'US',
    city: 'Cogan Station',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.3152,
    longitude: -77.069,
    postal_code: '17728'
  },
  '17729': {
    country: 'US',
    city: 'Cross Fork',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Potter',
    latitude: 41.4737,
    longitude: -77.8095,
    postal_code: '17729'
  },
  '17730': {
    country: 'US',
    city: 'Dewart',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 41.1092,
    longitude: -76.8766,
    postal_code: '17730'
  },
  '17731': {
    country: 'US',
    city: 'Eagles Mere',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Sullivan',
    latitude: 41.4109,
    longitude: -76.5819,
    postal_code: '17731'
  },
  '17735': {
    country: 'US',
    city: 'Grover',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.6145,
    longitude: -76.8672,
    postal_code: '17735'
  },
  '17737': {
    country: 'US',
    city: 'Hughesville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.256,
    longitude: -76.7141,
    postal_code: '17737'
  },
  '17739': {
    country: 'US',
    city: 'Jersey Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.3573,
    longitude: -77.4066,
    postal_code: '17739'
  },
  '17740': {
    country: 'US',
    city: 'Jersey Shore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.247,
    longitude: -77.2706,
    postal_code: '17740'
  },
  '17742': {
    country: 'US',
    city: 'Lairdsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.2359,
    longitude: -76.605,
    postal_code: '17742'
  },
  '17744': {
    country: 'US',
    city: 'Linden',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.2472,
    longitude: -77.1527,
    postal_code: '17744'
  },
  '17745': {
    country: 'US',
    city: 'Lock Haven',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.1425,
    longitude: -77.4436,
    postal_code: '17745'
  },
  '17747': {
    country: 'US',
    city: 'Loganton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.0283,
    longitude: -77.3204,
    postal_code: '17747'
  },
  '17748': {
    country: 'US',
    city: 'Mc Elhattan',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.1355,
    longitude: -77.37,
    postal_code: '17748'
  },
  '17749': {
    country: 'US',
    city: 'Mc Ewensville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 41.072,
    longitude: -76.8184,
    postal_code: '17749'
  },
  '17750': {
    country: 'US',
    city: 'Mackeyville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.0545,
    longitude: -77.4614,
    postal_code: '17750'
  },
  '17751': {
    country: 'US',
    city: 'Mill Hall',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.0867,
    longitude: -77.4836,
    postal_code: '17751'
  },
  '17752': {
    country: 'US',
    city: 'Montgomery',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.1788,
    longitude: -76.8839,
    postal_code: '17752'
  },
  '17754': {
    country: 'US',
    city: 'Montoursville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.2663,
    longitude: -76.903,
    postal_code: '17754'
  },
  '17756': {
    country: 'US',
    city: 'Muncy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.2137,
    longitude: -76.7633,
    postal_code: '17756'
  },
  '17758': {
    country: 'US',
    city: 'Muncy Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Sullivan',
    latitude: 41.3426,
    longitude: -76.5852,
    postal_code: '17758'
  },
  '17760': {
    country: 'US',
    city: 'North Bend',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.4083,
    longitude: -77.6641,
    postal_code: '17760'
  },
  '17762': {
    country: 'US',
    city: 'Picture Rocks',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.2798,
    longitude: -76.713,
    postal_code: '17762'
  },
  '17763': {
    country: 'US',
    city: 'Ralston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.5038,
    longitude: -76.9583,
    postal_code: '17763'
  },
  '17764': {
    country: 'US',
    city: 'Renovo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.3334,
    longitude: -77.7448,
    postal_code: '17764'
  },
  '17765': {
    country: 'US',
    city: 'Roaring Branch',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Tioga',
    latitude: 41.5692,
    longitude: -76.9421,
    postal_code: '17765'
  },
  '17768': {
    country: 'US',
    city: 'Shunk',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Sullivan',
    latitude: 41.5536,
    longitude: -76.7455,
    postal_code: '17768'
  },
  '17769': {
    country: 'US',
    city: 'Slate Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.4728,
    longitude: -77.5016,
    postal_code: '17769'
  },
  '17771': {
    country: 'US',
    city: 'Trout Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.4125,
    longitude: -77.0098,
    postal_code: '17771'
  },
  '17772': {
    country: 'US',
    city: 'Turbotville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 41.1119,
    longitude: -76.7425,
    postal_code: '17772'
  },
  '17774': {
    country: 'US',
    city: 'Unityville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.2436,
    longitude: -76.5183,
    postal_code: '17774'
  },
  '17776': {
    country: 'US',
    city: 'Waterville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lycoming',
    latitude: 41.3114,
    longitude: -77.3604,
    postal_code: '17776'
  },
  '17777': {
    country: 'US',
    city: 'Watsontown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 41.102,
    longitude: -76.8532,
    postal_code: '17777'
  },
  '17778': {
    country: 'US',
    city: 'Westport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.2745,
    longitude: -77.9315,
    postal_code: '17778'
  },
  '17779': {
    country: 'US',
    city: 'Woolrich',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Clinton',
    latitude: 41.1948,
    longitude: -77.3764,
    postal_code: '17779'
  },
  '17801': {
    country: 'US',
    city: 'Sunbury',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.8551,
    longitude: -76.7776,
    postal_code: '17801'
  },
  '17810': {
    country: 'US',
    city: 'Allenwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 41.1264,
    longitude: -76.9724,
    postal_code: '17810'
  },
  '17812': {
    country: 'US',
    city: 'Beaver Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.7528,
    longitude: -77.2318,
    postal_code: '17812'
  },
  '17813': {
    country: 'US',
    city: 'Beavertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.7774,
    longitude: -77.1691,
    postal_code: '17813'
  },
  '17814': {
    country: 'US',
    city: 'Benton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 41.2231,
    longitude: -76.3406,
    postal_code: '17814'
  },
  '17815': {
    country: 'US',
    city: 'Bloomsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 41.0115,
    longitude: -76.4384,
    postal_code: '17815'
  },
  '17820': {
    country: 'US',
    city: 'Catawissa',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 40.918,
    longitude: -76.4416,
    postal_code: '17820'
  },
  '17821': {
    country: 'US',
    city: 'Danville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montour',
    latitude: 40.9799,
    longitude: -76.6229,
    postal_code: '17821'
  },
  '17822': {
    country: 'US',
    city: 'Danville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montour',
    latitude: 40.9674,
    longitude: -76.6049,
    postal_code: '17822'
  },
  '17823': {
    country: 'US',
    city: 'Dornsife',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.7571,
    longitude: -76.7626,
    postal_code: '17823'
  },
  '17824': {
    country: 'US',
    city: 'Elysburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.8639,
    longitude: -76.5569,
    postal_code: '17824'
  },
  '17827': {
    country: 'US',
    city: 'Freeburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.765,
    longitude: -76.9395,
    postal_code: '17827'
  },
  '17829': {
    country: 'US',
    city: 'Hartleton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 40.9005,
    longitude: -77.1558,
    postal_code: '17829'
  },
  '17830': {
    country: 'US',
    city: 'Herndon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.6918,
    longitude: -76.8008,
    postal_code: '17830'
  },
  '17831': {
    country: 'US',
    city: 'Hummels Wharf',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.8344,
    longitude: -76.8354,
    postal_code: '17831'
  },
  '17832': {
    country: 'US',
    city: 'Marion Heights',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.8046,
    longitude: -76.4651,
    postal_code: '17832'
  },
  '17833': {
    country: 'US',
    city: 'Kreamer',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.8031,
    longitude: -76.9639,
    postal_code: '17833'
  },
  '17834': {
    country: 'US',
    city: 'Kulpmont',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.7933,
    longitude: -76.4744,
    postal_code: '17834'
  },
  '17835': {
    country: 'US',
    city: 'Laurelton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 40.8831,
    longitude: -77.1986,
    postal_code: '17835'
  },
  '17836': {
    country: 'US',
    city: 'Leck Kill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.7101,
    longitude: -76.6271,
    postal_code: '17836'
  },
  '17837': {
    country: 'US',
    city: 'Lewisburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 40.9702,
    longitude: -76.9099,
    postal_code: '17837'
  },
  '17840': {
    country: 'US',
    city: 'Locust Gap',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.7727,
    longitude: -76.4383,
    postal_code: '17840'
  },
  '17841': {
    country: 'US',
    city: 'Mc Clure',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.6991,
    longitude: -77.3758,
    postal_code: '17841'
  },
  '17842': {
    country: 'US',
    city: 'Middleburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.7977,
    longitude: -77.0468,
    postal_code: '17842'
  },
  '17843': {
    country: 'US',
    city: 'Beaver Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.7462,
    longitude: -77.2094,
    postal_code: '17843'
  },
  '17844': {
    country: 'US',
    city: 'Mifflinburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 40.9218,
    longitude: -77.0505,
    postal_code: '17844'
  },
  '17845': {
    country: 'US',
    city: 'Millmont',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 40.8803,
    longitude: -77.1941,
    postal_code: '17845'
  },
  '17846': {
    country: 'US',
    city: 'Millville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 41.1261,
    longitude: -76.5208,
    postal_code: '17846'
  },
  '17847': {
    country: 'US',
    city: 'Milton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 41.0168,
    longitude: -76.8398,
    postal_code: '17847'
  },
  '17850': {
    country: 'US',
    city: 'Montandon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.9661,
    longitude: -76.8575,
    postal_code: '17850'
  },
  '17851': {
    country: 'US',
    city: 'Mount Carmel',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.7955,
    longitude: -76.4195,
    postal_code: '17851'
  },
  '17853': {
    country: 'US',
    city: 'Mount Pleasant Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.7237,
    longitude: -77.0135,
    postal_code: '17853'
  },
  '17855': {
    country: 'US',
    city: 'New Berlin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 40.8802,
    longitude: -76.9861,
    postal_code: '17855'
  },
  '17856': {
    country: 'US',
    city: 'New Columbia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 41.0541,
    longitude: -76.9019,
    postal_code: '17856'
  },
  '17857': {
    country: 'US',
    city: 'Northumberland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.9044,
    longitude: -76.7908,
    postal_code: '17857'
  },
  '17858': {
    country: 'US',
    city: 'Numidia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 40.8806,
    longitude: -76.4022,
    postal_code: '17858'
  },
  '17859': {
    country: 'US',
    city: 'Orangeville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 41.1016,
    longitude: -76.381,
    postal_code: '17859'
  },
  '17860': {
    country: 'US',
    city: 'Paxinos',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.8448,
    longitude: -76.635,
    postal_code: '17860'
  },
  '17861': {
    country: 'US',
    city: 'Paxtonville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.7725,
    longitude: -77.0833,
    postal_code: '17861'
  },
  '17862': {
    country: 'US',
    city: 'Penns Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.8598,
    longitude: -77.0553,
    postal_code: '17862'
  },
  '17864': {
    country: 'US',
    city: 'Port Trevorton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.6996,
    longitude: -76.9082,
    postal_code: '17864'
  },
  '17865': {
    country: 'US',
    city: 'Potts Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.9937,
    longitude: -76.7891,
    postal_code: '17865'
  },
  '17866': {
    country: 'US',
    city: 'Coal Township',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.7917,
    longitude: -76.5519,
    postal_code: '17866'
  },
  '17867': {
    country: 'US',
    city: 'Rebuck',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.7141,
    longitude: -76.6886,
    postal_code: '17867'
  },
  '17868': {
    country: 'US',
    city: 'Riverside',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.9529,
    longitude: -76.6311,
    postal_code: '17868'
  },
  '17870': {
    country: 'US',
    city: 'Selinsgrove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.8224,
    longitude: -76.8683,
    postal_code: '17870'
  },
  '17872': {
    country: 'US',
    city: 'Shamokin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.789,
    longitude: -76.5588,
    postal_code: '17872'
  },
  '17876': {
    country: 'US',
    city: 'Shamokin Dam',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.8459,
    longitude: -76.8224,
    postal_code: '17876'
  },
  '17877': {
    country: 'US',
    city: 'Snydertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.879,
    longitude: -76.6673,
    postal_code: '17877'
  },
  '17878': {
    country: 'US',
    city: 'Stillwater',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 41.1515,
    longitude: -76.3696,
    postal_code: '17878'
  },
  '17880': {
    country: 'US',
    city: 'Swengel',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 40.8923,
    longitude: -77.1266,
    postal_code: '17880'
  },
  '17881': {
    country: 'US',
    city: 'Trevorton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northumberland',
    latitude: 40.7819,
    longitude: -76.6702,
    postal_code: '17881'
  },
  '17882': {
    country: 'US',
    city: 'Troxelville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Snyder',
    latitude: 40.8031,
    longitude: -77.2053,
    postal_code: '17882'
  },
  '17883': {
    country: 'US',
    city: 'Vicksburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 40.9387,
    longitude: -76.988,
    postal_code: '17883'
  },
  '17884': {
    country: 'US',
    city: 'Washingtonville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montour',
    latitude: 41.0518,
    longitude: -76.6747,
    postal_code: '17884'
  },
  '17885': {
    country: 'US',
    city: 'Weikert',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 40.8584,
    longitude: -77.2953,
    postal_code: '17885'
  },
  '17886': {
    country: 'US',
    city: 'West Milton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 41.0179,
    longitude: -76.8708,
    postal_code: '17886'
  },
  '17887': {
    country: 'US',
    city: 'White Deer',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 41.0759,
    longitude: -76.8747,
    postal_code: '17887'
  },
  '17888': {
    country: 'US',
    city: 'Wilburton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 40.8121,
    longitude: -76.3929,
    postal_code: '17888'
  },
  '17889': {
    country: 'US',
    city: 'Winfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Union',
    latitude: 40.8908,
    longitude: -76.8718,
    postal_code: '17889'
  },
  '17901': {
    country: 'US',
    city: 'Pottsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.684,
    longitude: -76.2123,
    postal_code: '17901'
  },
  '17920': {
    country: 'US',
    city: 'Aristes',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 40.8167,
    longitude: -76.3503,
    postal_code: '17920'
  },
  '17921': {
    country: 'US',
    city: 'Ashland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7732,
    longitude: -76.343,
    postal_code: '17921'
  },
  '17922': {
    country: 'US',
    city: 'Auburn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.5962,
    longitude: -76.1344,
    postal_code: '17922'
  },
  '17923': {
    country: 'US',
    city: 'Branchdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6643,
    longitude: -76.3328,
    postal_code: '17923'
  },
  '17925': {
    country: 'US',
    city: 'Brockton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7508,
    longitude: -76.0629,
    postal_code: '17925'
  },
  '17929': {
    country: 'US',
    city: 'Cressona',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6284,
    longitude: -76.195,
    postal_code: '17929'
  },
  '17930': {
    country: 'US',
    city: 'Cumbola',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7114,
    longitude: -76.1392,
    postal_code: '17930'
  },
  '17931': {
    country: 'US',
    city: 'Frackville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7825,
    longitude: -76.2311,
    postal_code: '17931'
  },
  '17932': {
    country: 'US',
    city: 'Frackville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.784,
    longitude: -76.2302,
    postal_code: '17932'
  },
  '17933': {
    country: 'US',
    city: 'Friedensburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6026,
    longitude: -76.2408,
    postal_code: '17933'
  },
  '17934': {
    country: 'US',
    city: 'Gilberton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7986,
    longitude: -76.2155,
    postal_code: '17934'
  },
  '17935': {
    country: 'US',
    city: 'Girardville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7922,
    longitude: -76.2858,
    postal_code: '17935'
  },
  '17936': {
    country: 'US',
    city: 'Gordon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7489,
    longitude: -76.3355,
    postal_code: '17936'
  },
  '17938': {
    country: 'US',
    city: 'Hegins',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6669,
    longitude: -76.4732,
    postal_code: '17938'
  },
  '17941': {
    country: 'US',
    city: 'Klingerstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6676,
    longitude: -76.6507,
    postal_code: '17941'
  },
  '17943': {
    country: 'US',
    city: 'Lavelle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7634,
    longitude: -76.3827,
    postal_code: '17943'
  },
  '17944': {
    country: 'US',
    city: 'Llewellyn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6729,
    longitude: -76.2791,
    postal_code: '17944'
  },
  '17945': {
    country: 'US',
    city: 'Locustdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7773,
    longitude: -76.3761,
    postal_code: '17945'
  },
  '17946': {
    country: 'US',
    city: 'Lost Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.8081,
    longitude: -76.2407,
    postal_code: '17946'
  },
  '17948': {
    country: 'US',
    city: 'Mahanoy City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.8123,
    longitude: -76.1396,
    postal_code: '17948'
  },
  '17949': {
    country: 'US',
    city: 'Mahanoy Plane',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.794,
    longitude: -76.2433,
    postal_code: '17949'
  },
  '17951': {
    country: 'US',
    city: 'Mar Lin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.683,
    longitude: -76.2387,
    postal_code: '17951'
  },
  '17952': {
    country: 'US',
    city: 'Mary D',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7473,
    longitude: -76.0628,
    postal_code: '17952'
  },
  '17953': {
    country: 'US',
    city: 'Middleport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7331,
    longitude: -76.0858,
    postal_code: '17953'
  },
  '17954': {
    country: 'US',
    city: 'Minersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6904,
    longitude: -76.2597,
    postal_code: '17954'
  },
  '17957': {
    country: 'US',
    city: 'Muir',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.5926,
    longitude: -76.5161,
    postal_code: '17957'
  },
  '17959': {
    country: 'US',
    city: 'New Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7195,
    longitude: -76.1158,
    postal_code: '17959'
  },
  '17960': {
    country: 'US',
    city: 'New Ringgold',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7149,
    longitude: -75.9484,
    postal_code: '17960'
  },
  '17961': {
    country: 'US',
    city: 'Orwigsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6431,
    longitude: -76.084,
    postal_code: '17961'
  },
  '17963': {
    country: 'US',
    city: 'Pine Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.5671,
    longitude: -76.3269,
    postal_code: '17963'
  },
  '17964': {
    country: 'US',
    city: 'Pitman',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7049,
    longitude: -76.5233,
    postal_code: '17964'
  },
  '17965': {
    country: 'US',
    city: 'Port Carbon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6977,
    longitude: -76.166,
    postal_code: '17965'
  },
  '17966': {
    country: 'US',
    city: 'Ravine',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.5745,
    longitude: -76.4038,
    postal_code: '17966'
  },
  '17967': {
    country: 'US',
    city: 'Ringtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.8559,
    longitude: -76.2349,
    postal_code: '17967'
  },
  '17968': {
    country: 'US',
    city: 'Sacramento',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6352,
    longitude: -76.6128,
    postal_code: '17968'
  },
  '17970': {
    country: 'US',
    city: 'Saint Clair',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7193,
    longitude: -76.1924,
    postal_code: '17970'
  },
  '17972': {
    country: 'US',
    city: 'Schuylkill Haven',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6306,
    longitude: -76.17,
    postal_code: '17972'
  },
  '17974': {
    country: 'US',
    city: 'Seltzer',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6954,
    longitude: -76.2338,
    postal_code: '17974'
  },
  '17976': {
    country: 'US',
    city: 'Shenandoah',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.8167,
    longitude: -76.2035,
    postal_code: '17976'
  },
  '17978': {
    country: 'US',
    city: 'Spring Glen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6258,
    longitude: -76.6218,
    postal_code: '17978'
  },
  '17979': {
    country: 'US',
    city: 'Summit Station',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.5545,
    longitude: -76.2038,
    postal_code: '17979'
  },
  '17980': {
    country: 'US',
    city: 'Tower City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.5845,
    longitude: -76.55,
    postal_code: '17980'
  },
  '17981': {
    country: 'US',
    city: 'Tremont',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6343,
    longitude: -76.3905,
    postal_code: '17981'
  },
  '17982': {
    country: 'US',
    city: 'Tuscarora',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7815,
    longitude: -76.0134,
    postal_code: '17982'
  },
  '17983': {
    country: 'US',
    city: 'Valley View',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.6445,
    longitude: -76.5448,
    postal_code: '17983'
  },
  '17985': {
    country: 'US',
    city: 'Zion Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.9141,
    longitude: -76.1931,
    postal_code: '17985'
  },
  '18001': {
    country: 'US',
    city: 'Lehigh Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6934,
    longitude: -75.4712,
    postal_code: '18001'
  },
  '18002': {
    country: 'US',
    city: 'Lehigh Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6656,
    longitude: -75.4262,
    postal_code: '18002'
  },
  '18003': {
    country: 'US',
    city: 'Lehigh Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6934,
    longitude: -75.4712,
    postal_code: '18003'
  },
  '18011': {
    country: 'US',
    city: 'Alburtis',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5145,
    longitude: -75.6029,
    postal_code: '18011'
  },
  '18012': {
    country: 'US',
    city: 'Aquashicola',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.8133,
    longitude: -75.592,
    postal_code: '18012'
  },
  '18013': {
    country: 'US',
    city: 'Bangor',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.853,
    longitude: -75.1718,
    postal_code: '18013'
  },
  '18014': {
    country: 'US',
    city: 'Bath',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.7551,
    longitude: -75.4086,
    postal_code: '18014'
  },
  '18015': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6002,
    longitude: -75.3805,
    postal_code: '18015'
  },
  '18016': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6934,
    longitude: -75.4712,
    postal_code: '18016'
  },
  '18017': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6622,
    longitude: -75.3903,
    postal_code: '18017'
  },
  '18018': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6278,
    longitude: -75.3928,
    postal_code: '18018'
  },
  '18020': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6693,
    longitude: -75.3349,
    postal_code: '18020'
  },
  '18025': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6259,
    longitude: -75.3705,
    postal_code: '18025'
  },
  '18030': {
    country: 'US',
    city: 'Bowmanstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.8011,
    longitude: -75.6614,
    postal_code: '18030'
  },
  '18031': {
    country: 'US',
    city: 'Breinigsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5526,
    longitude: -75.6553,
    postal_code: '18031'
  },
  '18032': {
    country: 'US',
    city: 'Catasauqua',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6557,
    longitude: -75.4693,
    postal_code: '18032'
  },
  '18034': {
    country: 'US',
    city: 'Center Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5396,
    longitude: -75.4242,
    postal_code: '18034'
  },
  '18035': {
    country: 'US',
    city: 'Cherryville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.751,
    longitude: -75.5394,
    postal_code: '18035'
  },
  '18036': {
    country: 'US',
    city: 'Coopersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5076,
    longitude: -75.3888,
    postal_code: '18036'
  },
  '18037': {
    country: 'US',
    city: 'Coplay',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6701,
    longitude: -75.4955,
    postal_code: '18037'
  },
  '18038': {
    country: 'US',
    city: 'Danielsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.7866,
    longitude: -75.5186,
    postal_code: '18038'
  },
  '18039': {
    country: 'US',
    city: 'Durham',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.5757,
    longitude: -75.2232,
    postal_code: '18039'
  },
  '18040': {
    country: 'US',
    city: 'Easton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.7449,
    longitude: -75.2217,
    postal_code: '18040'
  },
  '18041': {
    country: 'US',
    city: 'East Greenville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.4119,
    longitude: -75.5056,
    postal_code: '18041'
  },
  '18042': {
    country: 'US',
    city: 'Easton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6516,
    longitude: -75.224,
    postal_code: '18042'
  },
  '18043': {
    country: 'US',
    city: 'Easton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6884,
    longitude: -75.2207,
    postal_code: '18043'
  },
  '18044': {
    country: 'US',
    city: 'Easton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6884,
    longitude: -75.2207,
    postal_code: '18044'
  },
  '18045': {
    country: 'US',
    city: 'Easton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6957,
    longitude: -75.2865,
    postal_code: '18045'
  },
  '18046': {
    country: 'US',
    city: 'East Texas',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5476,
    longitude: -75.5613,
    postal_code: '18046'
  },
  '18049': {
    country: 'US',
    city: 'Emmaus',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5293,
    longitude: -75.501,
    postal_code: '18049'
  },
  '18051': {
    country: 'US',
    city: 'Fogelsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.593,
    longitude: -75.6568,
    postal_code: '18051'
  },
  '18052': {
    country: 'US',
    city: 'Whitehall',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6567,
    longitude: -75.5041,
    postal_code: '18052'
  },
  '18053': {
    country: 'US',
    city: 'Germansville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.7118,
    longitude: -75.7147,
    postal_code: '18053'
  },
  '18054': {
    country: 'US',
    city: 'Green Lane',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3534,
    longitude: -75.4351,
    postal_code: '18054'
  },
  '18055': {
    country: 'US',
    city: 'Hellertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.5817,
    longitude: -75.3255,
    postal_code: '18055'
  },
  '18056': {
    country: 'US',
    city: 'Hereford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4491,
    longitude: -75.5491,
    postal_code: '18056'
  },
  '18058': {
    country: 'US',
    city: 'Kunkletown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 40.8999,
    longitude: -75.476,
    postal_code: '18058'
  },
  '18059': {
    country: 'US',
    city: 'Laurys Station',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.7177,
    longitude: -75.5335,
    postal_code: '18059'
  },
  '18060': {
    country: 'US',
    city: 'Limeport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.509,
    longitude: -75.4471,
    postal_code: '18060'
  },
  '18062': {
    country: 'US',
    city: 'Macungie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5285,
    longitude: -75.5666,
    postal_code: '18062'
  },
  '18063': {
    country: 'US',
    city: 'Martins Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.7825,
    longitude: -75.1735,
    postal_code: '18063'
  },
  '18064': {
    country: 'US',
    city: 'Nazareth',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.745,
    longitude: -75.3199,
    postal_code: '18064'
  },
  '18065': {
    country: 'US',
    city: 'Neffs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6967,
    longitude: -75.6116,
    postal_code: '18065'
  },
  '18066': {
    country: 'US',
    city: 'New Tripoli',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6545,
    longitude: -75.7417,
    postal_code: '18066'
  },
  '18067': {
    country: 'US',
    city: 'Northampton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.6998,
    longitude: -75.4874,
    postal_code: '18067'
  },
  '18068': {
    country: 'US',
    city: 'Old Zionsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.4865,
    longitude: -75.5202,
    postal_code: '18068'
  },
  '18069': {
    country: 'US',
    city: 'Orefield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6248,
    longitude: -75.5974,
    postal_code: '18069'
  },
  '18070': {
    country: 'US',
    city: 'Palm',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.4317,
    longitude: -75.5331,
    postal_code: '18070'
  },
  '18071': {
    country: 'US',
    city: 'Palmerton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.817,
    longitude: -75.6011,
    postal_code: '18071'
  },
  '18072': {
    country: 'US',
    city: 'Pen Argyl',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.8518,
    longitude: -75.2701,
    postal_code: '18072'
  },
  '18073': {
    country: 'US',
    city: 'Pennsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3911,
    longitude: -75.4866,
    postal_code: '18073'
  },
  '18074': {
    country: 'US',
    city: 'Perkiomenville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3157,
    longitude: -75.5022,
    postal_code: '18074'
  },
  '18076': {
    country: 'US',
    city: 'Red Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3758,
    longitude: -75.4846,
    postal_code: '18076'
  },
  '18077': {
    country: 'US',
    city: 'Riegelsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.5782,
    longitude: -75.2191,
    postal_code: '18077'
  },
  '18078': {
    country: 'US',
    city: 'Schnecksville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6819,
    longitude: -75.6239,
    postal_code: '18078'
  },
  '18079': {
    country: 'US',
    city: 'Slatedale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.7455,
    longitude: -75.6592,
    postal_code: '18079'
  },
  '18080': {
    country: 'US',
    city: 'Slatington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.7345,
    longitude: -75.6186,
    postal_code: '18080'
  },
  '18081': {
    country: 'US',
    city: 'Springtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.5565,
    longitude: -75.2893,
    postal_code: '18081'
  },
  '18083': {
    country: 'US',
    city: 'Stockertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.754,
    longitude: -75.2621,
    postal_code: '18083'
  },
  '18084': {
    country: 'US',
    city: 'Sumneytown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3278,
    longitude: -75.454,
    postal_code: '18084'
  },
  '18085': {
    country: 'US',
    city: 'Tatamy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.7406,
    longitude: -75.2549,
    postal_code: '18085'
  },
  '18086': {
    country: 'US',
    city: 'Treichlers',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.7361,
    longitude: -75.5449,
    postal_code: '18086'
  },
  '18087': {
    country: 'US',
    city: 'Trexlertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5482,
    longitude: -75.5961,
    postal_code: '18087'
  },
  '18088': {
    country: 'US',
    city: 'Walnutport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.7615,
    longitude: -75.5657,
    postal_code: '18088'
  },
  '18091': {
    country: 'US',
    city: 'Wind Gap',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.8169,
    longitude: -75.3264,
    postal_code: '18091'
  },
  '18092': {
    country: 'US',
    city: 'Zionsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.4734,
    longitude: -75.5261,
    postal_code: '18092'
  },
  '18098': {
    country: 'US',
    city: 'Emmaus',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5395,
    longitude: -75.4969,
    postal_code: '18098'
  },
  '18099': {
    country: 'US',
    city: 'Emmaus',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5395,
    longitude: -75.4969,
    postal_code: '18099'
  },
  '18101': {
    country: 'US',
    city: 'Allentown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6026,
    longitude: -75.4691,
    postal_code: '18101'
  },
  '18102': {
    country: 'US',
    city: 'Allentown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6068,
    longitude: -75.4781,
    postal_code: '18102'
  },
  '18103': {
    country: 'US',
    city: 'Allentown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5891,
    longitude: -75.4645,
    postal_code: '18103'
  },
  '18104': {
    country: 'US',
    city: 'Allentown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6018,
    longitude: -75.5225,
    postal_code: '18104'
  },
  '18105': {
    country: 'US',
    city: 'Allentown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6084,
    longitude: -75.4902,
    postal_code: '18105'
  },
  '18106': {
    country: 'US',
    city: 'Allentown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5824,
    longitude: -75.5911,
    postal_code: '18106'
  },
  '18109': {
    country: 'US',
    city: 'Allentown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.6366,
    longitude: -75.4405,
    postal_code: '18109'
  },
  '18195': {
    country: 'US',
    city: 'Allentown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lehigh',
    latitude: 40.5843,
    longitude: -75.6248,
    postal_code: '18195'
  },
  '18201': {
    country: 'US',
    city: 'Hazleton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 40.9488,
    longitude: -75.9563,
    postal_code: '18201'
  },
  '18202': {
    country: 'US',
    city: 'Hazleton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 40.9506,
    longitude: -76.0487,
    postal_code: '18202'
  },
  '18210': {
    country: 'US',
    city: 'Albrightsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.9748,
    longitude: -75.5842,
    postal_code: '18210'
  },
  '18211': {
    country: 'US',
    city: 'Andreas',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7465,
    longitude: -75.8342,
    postal_code: '18211'
  },
  '18212': {
    country: 'US',
    city: 'Ashfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.7841,
    longitude: -75.7083,
    postal_code: '18212'
  },
  '18214': {
    country: 'US',
    city: 'Barnesville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.8138,
    longitude: -76.0611,
    postal_code: '18214'
  },
  '18216': {
    country: 'US',
    city: 'Beaver Meadows',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.9281,
    longitude: -75.9146,
    postal_code: '18216'
  },
  '18218': {
    country: 'US',
    city: 'Coaldale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.8219,
    longitude: -75.9104,
    postal_code: '18218'
  },
  '18219': {
    country: 'US',
    city: 'Conyngham',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 40.9907,
    longitude: -76.0564,
    postal_code: '18219'
  },
  '18220': {
    country: 'US',
    city: 'Delano',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.8389,
    longitude: -76.0694,
    postal_code: '18220'
  },
  '18221': {
    country: 'US',
    city: 'Drifton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.0016,
    longitude: -75.9057,
    postal_code: '18221'
  },
  '18222': {
    country: 'US',
    city: 'Drums',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.0255,
    longitude: -75.9768,
    postal_code: '18222'
  },
  '18223': {
    country: 'US',
    city: 'Ebervale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 40.9862,
    longitude: -75.9413,
    postal_code: '18223'
  },
  '18224': {
    country: 'US',
    city: 'Freeland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.0196,
    longitude: -75.888,
    postal_code: '18224'
  },
  '18225': {
    country: 'US',
    city: 'Harleigh',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 40.9806,
    longitude: -75.9713,
    postal_code: '18225'
  },
  '18229': {
    country: 'US',
    city: 'Jim Thorpe',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.87,
    longitude: -75.7397,
    postal_code: '18229'
  },
  '18230': {
    country: 'US',
    city: 'Junedale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.9234,
    longitude: -75.9416,
    postal_code: '18230'
  },
  '18231': {
    country: 'US',
    city: 'Kelayres',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.9002,
    longitude: -76.0054,
    postal_code: '18231'
  },
  '18232': {
    country: 'US',
    city: 'Lansford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.8314,
    longitude: -75.8828,
    postal_code: '18232'
  },
  '18234': {
    country: 'US',
    city: 'Lattimer Mines',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 40.9925,
    longitude: -75.9626,
    postal_code: '18234'
  },
  '18235': {
    country: 'US',
    city: 'Lehighton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.8299,
    longitude: -75.6974,
    postal_code: '18235'
  },
  '18237': {
    country: 'US',
    city: 'Mcadoo',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.8979,
    longitude: -75.9971,
    postal_code: '18237'
  },
  '18239': {
    country: 'US',
    city: 'Milnesville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 40.9961,
    longitude: -75.9815,
    postal_code: '18239'
  },
  '18240': {
    country: 'US',
    city: 'Nesquehoning',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.8626,
    longitude: -75.8239,
    postal_code: '18240'
  },
  '18241': {
    country: 'US',
    city: 'Nuremberg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.9395,
    longitude: -76.1716,
    postal_code: '18241'
  },
  '18242': {
    country: 'US',
    city: 'Oneida',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.911,
    longitude: -76.1223,
    postal_code: '18242'
  },
  '18244': {
    country: 'US',
    city: 'Parryville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.8181,
    longitude: -75.6727,
    postal_code: '18244'
  },
  '18245': {
    country: 'US',
    city: 'Quakake',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.8518,
    longitude: -76.036,
    postal_code: '18245'
  },
  '18246': {
    country: 'US',
    city: 'Rock Glen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 40.9654,
    longitude: -76.1844,
    postal_code: '18246'
  },
  '18247': {
    country: 'US',
    city: 'Saint Johns',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.0281,
    longitude: -76.006,
    postal_code: '18247'
  },
  '18248': {
    country: 'US',
    city: 'Sheppton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.902,
    longitude: -76.1188,
    postal_code: '18248'
  },
  '18249': {
    country: 'US',
    city: 'Sugarloaf',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 40.9971,
    longitude: -76.0717,
    postal_code: '18249'
  },
  '18250': {
    country: 'US',
    city: 'Summit Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.8255,
    longitude: -75.8693,
    postal_code: '18250'
  },
  '18251': {
    country: 'US',
    city: 'Sybertsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.0015,
    longitude: -76.0763,
    postal_code: '18251'
  },
  '18252': {
    country: 'US',
    city: 'Tamaqua',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.7983,
    longitude: -75.9735,
    postal_code: '18252'
  },
  '18254': {
    country: 'US',
    city: 'Tresckow',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.9159,
    longitude: -75.9657,
    postal_code: '18254'
  },
  '18255': {
    country: 'US',
    city: 'Weatherly',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 40.9411,
    longitude: -75.8306,
    postal_code: '18255'
  },
  '18256': {
    country: 'US',
    city: 'Weston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 40.9448,
    longitude: -76.1452,
    postal_code: '18256'
  },
  '18301': {
    country: 'US',
    city: 'East Stroudsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0367,
    longitude: -75.1735,
    postal_code: '18301'
  },
  '18302': {
    country: 'US',
    city: 'East Stroudsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.0936,
    longitude: -75.1187,
    postal_code: '18302'
  },
  '18320': {
    country: 'US',
    city: 'Analomink',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0712,
    longitude: -75.2364,
    postal_code: '18320'
  },
  '18321': {
    country: 'US',
    city: 'Bartonsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.008,
    longitude: -75.2967,
    postal_code: '18321'
  },
  '18322': {
    country: 'US',
    city: 'Brodheadsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 40.9309,
    longitude: -75.4104,
    postal_code: '18322'
  },
  '18323': {
    country: 'US',
    city: 'Buck Hill Falls',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.1879,
    longitude: -75.2657,
    postal_code: '18323'
  },
  '18324': {
    country: 'US',
    city: 'Bushkill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.1285,
    longitude: -75.0132,
    postal_code: '18324'
  },
  '18325': {
    country: 'US',
    city: 'Canadensis',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.2338,
    longitude: -75.2573,
    postal_code: '18325'
  },
  '18326': {
    country: 'US',
    city: 'Cresco',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.154,
    longitude: -75.2805,
    postal_code: '18326'
  },
  '18327': {
    country: 'US',
    city: 'Delaware Water Gap',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 40.9793,
    longitude: -75.143,
    postal_code: '18327'
  },
  '18328': {
    country: 'US',
    city: 'Dingmans Ferry',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.24,
    longitude: -74.938,
    postal_code: '18328'
  },
  '18330': {
    country: 'US',
    city: 'Effort',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 40.9669,
    longitude: -75.4523,
    postal_code: '18330'
  },
  '18331': {
    country: 'US',
    city: 'Gilbert',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 40.9089,
    longitude: -75.4314,
    postal_code: '18331'
  },
  '18332': {
    country: 'US',
    city: 'Henryville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0889,
    longitude: -75.2798,
    postal_code: '18332'
  },
  '18333': {
    country: 'US',
    city: 'Kresgeville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 40.8982,
    longitude: -75.5074,
    postal_code: '18333'
  },
  '18334': {
    country: 'US',
    city: 'Long Pond',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0677,
    longitude: -75.4482,
    postal_code: '18334'
  },
  '18335': {
    country: 'US',
    city: 'Marshalls Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0508,
    longitude: -75.2083,
    postal_code: '18335'
  },
  '18336': {
    country: 'US',
    city: 'Matamoras',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.3674,
    longitude: -74.7154,
    postal_code: '18336'
  },
  '18337': {
    country: 'US',
    city: 'Milford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.3228,
    longitude: -74.8824,
    postal_code: '18337'
  },
  '18340': {
    country: 'US',
    city: 'Millrift',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.4093,
    longitude: -74.7432,
    postal_code: '18340'
  },
  '18341': {
    country: 'US',
    city: 'Minisink Hills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0004,
    longitude: -75.1371,
    postal_code: '18341'
  },
  '18342': {
    country: 'US',
    city: 'Mountainhome',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.1737,
    longitude: -75.271,
    postal_code: '18342'
  },
  '18343': {
    country: 'US',
    city: 'Mount Bethel',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.9008,
    longitude: -75.1115,
    postal_code: '18343'
  },
  '18344': {
    country: 'US',
    city: 'Mount Pocono',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.1216,
    longitude: -75.3529,
    postal_code: '18344'
  },
  '18346': {
    country: 'US',
    city: 'Pocono Summit',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.1353,
    longitude: -75.4079,
    postal_code: '18346'
  },
  '18347': {
    country: 'US',
    city: 'Pocono Lake',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.1187,
    longitude: -75.5559,
    postal_code: '18347'
  },
  '18348': {
    country: 'US',
    city: 'Pocono Lake Preserve',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0338,
    longitude: -75.308,
    postal_code: '18348'
  },
  '18349': {
    country: 'US',
    city: 'Pocono Manor',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.1001,
    longitude: -75.3588,
    postal_code: '18349'
  },
  '18350': {
    country: 'US',
    city: 'Pocono Pines',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.1054,
    longitude: -75.476,
    postal_code: '18350'
  },
  '18351': {
    country: 'US',
    city: 'Portland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Northampton',
    latitude: 40.9214,
    longitude: -75.097,
    postal_code: '18351'
  },
  '18352': {
    country: 'US',
    city: 'Reeders',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.019,
    longitude: -75.336,
    postal_code: '18352'
  },
  '18353': {
    country: 'US',
    city: 'Saylorsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 40.8965,
    longitude: -75.367,
    postal_code: '18353'
  },
  '18354': {
    country: 'US',
    city: 'Sciota',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 40.9257,
    longitude: -75.325,
    postal_code: '18354'
  },
  '18355': {
    country: 'US',
    city: 'Scotrun',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0751,
    longitude: -75.3265,
    postal_code: '18355'
  },
  '18356': {
    country: 'US',
    city: 'Shawnee On Delaware',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0123,
    longitude: -75.1107,
    postal_code: '18356'
  },
  '18357': {
    country: 'US',
    city: 'Skytop',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.2336,
    longitude: -75.2389,
    postal_code: '18357'
  },
  '18360': {
    country: 'US',
    city: 'Stroudsburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 40.9877,
    longitude: -75.2485,
    postal_code: '18360'
  },
  '18370': {
    country: 'US',
    city: 'Swiftwater',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0879,
    longitude: -75.3483,
    postal_code: '18370'
  },
  '18371': {
    country: 'US',
    city: 'Tamiment',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.1423,
    longitude: -75.0271,
    postal_code: '18371'
  },
  '18372': {
    country: 'US',
    city: 'Tannersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0472,
    longitude: -75.3244,
    postal_code: '18372'
  },
  '18403': {
    country: 'US',
    city: 'Archbald',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4876,
    longitude: -75.5334,
    postal_code: '18403'
  },
  '18405': {
    country: 'US',
    city: 'Beach Lake',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.6034,
    longitude: -75.1165,
    postal_code: '18405'
  },
  '18407': {
    country: 'US',
    city: 'Carbondale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.5831,
    longitude: -75.5056,
    postal_code: '18407'
  },
  '18410': {
    country: 'US',
    city: 'Chinchilla',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4865,
    longitude: -75.6933,
    postal_code: '18410'
  },
  '18411': {
    country: 'US',
    city: 'Clarks Summit',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4878,
    longitude: -75.7057,
    postal_code: '18411'
  },
  '18413': {
    country: 'US',
    city: 'Clifford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.649,
    longitude: -75.5988,
    postal_code: '18413'
  },
  '18414': {
    country: 'US',
    city: 'Dalton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.5395,
    longitude: -75.7037,
    postal_code: '18414'
  },
  '18415': {
    country: 'US',
    city: 'Damascus',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.7366,
    longitude: -75.1312,
    postal_code: '18415'
  },
  '18416': {
    country: 'US',
    city: 'Elmhurst',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.3756,
    longitude: -75.548,
    postal_code: '18416'
  },
  '18417': {
    country: 'US',
    city: 'Equinunk',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.8117,
    longitude: -75.1891,
    postal_code: '18417'
  },
  '18419': {
    country: 'US',
    city: 'Factoryville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wyoming',
    latitude: 41.5762,
    longitude: -75.7652,
    postal_code: '18419'
  },
  '18420': {
    country: 'US',
    city: 'Fleetville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.5981,
    longitude: -75.7146,
    postal_code: '18420'
  },
  '18421': {
    country: 'US',
    city: 'Forest City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.6515,
    longitude: -75.4666,
    postal_code: '18421'
  },
  '18424': {
    country: 'US',
    city: 'Gouldsboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.2448,
    longitude: -75.5037,
    postal_code: '18424'
  },
  '18425': {
    country: 'US',
    city: 'Greeley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.4372,
    longitude: -75.0125,
    postal_code: '18425'
  },
  '18426': {
    country: 'US',
    city: 'Greentown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.3321,
    longitude: -75.2819,
    postal_code: '18426'
  },
  '18427': {
    country: 'US',
    city: 'Hamlin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.4047,
    longitude: -75.4069,
    postal_code: '18427'
  },
  '18428': {
    country: 'US',
    city: 'Hawley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.4787,
    longitude: -75.1978,
    postal_code: '18428'
  },
  '18430': {
    country: 'US',
    city: 'Herrick Center',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.742,
    longitude: -75.4894,
    postal_code: '18430'
  },
  '18431': {
    country: 'US',
    city: 'Honesdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.5792,
    longitude: -75.2528,
    postal_code: '18431'
  },
  '18433': {
    country: 'US',
    city: 'Jermyn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.5744,
    longitude: -75.5881,
    postal_code: '18433'
  },
  '18434': {
    country: 'US',
    city: 'Jessup',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4724,
    longitude: -75.5689,
    postal_code: '18434'
  },
  '18435': {
    country: 'US',
    city: 'Lackawaxen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.482,
    longitude: -74.986,
    postal_code: '18435'
  },
  '18436': {
    country: 'US',
    city: 'Lake Ariel',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.4395,
    longitude: -75.4313,
    postal_code: '18436'
  },
  '18437': {
    country: 'US',
    city: 'Lake Como',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.8666,
    longitude: -75.3231,
    postal_code: '18437'
  },
  '18438': {
    country: 'US',
    city: 'Lakeville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.4223,
    longitude: -75.2607,
    postal_code: '18438'
  },
  '18439': {
    country: 'US',
    city: 'Lakewood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.8171,
    longitude: -75.3838,
    postal_code: '18439'
  },
  '18440': {
    country: 'US',
    city: 'La Plume',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.5516,
    longitude: -75.7676,
    postal_code: '18440'
  },
  '18441': {
    country: 'US',
    city: 'Lenoxville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.6595,
    longitude: -75.6282,
    postal_code: '18441'
  },
  '18443': {
    country: 'US',
    city: 'Milanville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.667,
    longitude: -75.1185,
    postal_code: '18443'
  },
  '18444': {
    country: 'US',
    city: 'Moscow',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.3432,
    longitude: -75.5301,
    postal_code: '18444'
  },
  '18445': {
    country: 'US',
    city: 'Newfoundland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.3041,
    longitude: -75.3384,
    postal_code: '18445'
  },
  '18446': {
    country: 'US',
    city: 'Nicholson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wyoming',
    latitude: 41.6412,
    longitude: -75.7641,
    postal_code: '18446'
  },
  '18447': {
    country: 'US',
    city: 'Olyphant',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4677,
    longitude: -75.6015,
    postal_code: '18447'
  },
  '18448': {
    country: 'US',
    city: 'Olyphant',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4649,
    longitude: -75.5962,
    postal_code: '18448'
  },
  '18449': {
    country: 'US',
    city: 'Orson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.8137,
    longitude: -75.448,
    postal_code: '18449'
  },
  '18451': {
    country: 'US',
    city: 'Paupack',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.3962,
    longitude: -75.1963,
    postal_code: '18451'
  },
  '18452': {
    country: 'US',
    city: 'Peckville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4821,
    longitude: -75.5899,
    postal_code: '18452'
  },
  '18453': {
    country: 'US',
    city: 'Pleasant Mount',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.7322,
    longitude: -75.3989,
    postal_code: '18453'
  },
  '18454': {
    country: 'US',
    city: 'Poyntelle',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.8206,
    longitude: -75.4199,
    postal_code: '18454'
  },
  '18455': {
    country: 'US',
    city: 'Preston Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.8867,
    longitude: -75.356,
    postal_code: '18455'
  },
  '18456': {
    country: 'US',
    city: 'Prompton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.582,
    longitude: -75.3207,
    postal_code: '18456'
  },
  '18457': {
    country: 'US',
    city: 'Rowland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.4719,
    longitude: -75.0485,
    postal_code: '18457'
  },
  '18458': {
    country: 'US',
    city: 'Shohola',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.4182,
    longitude: -74.918,
    postal_code: '18458'
  },
  '18459': {
    country: 'US',
    city: 'South Canaan',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.616,
    longitude: -75.2747,
    postal_code: '18459'
  },
  '18460': {
    country: 'US',
    city: 'South Sterling',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.2643,
    longitude: -75.348,
    postal_code: '18460'
  },
  '18461': {
    country: 'US',
    city: 'Starlight',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.9251,
    longitude: -75.3212,
    postal_code: '18461'
  },
  '18462': {
    country: 'US',
    city: 'Starrucca',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.8907,
    longitude: -75.449,
    postal_code: '18462'
  },
  '18463': {
    country: 'US',
    city: 'Sterling',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.3412,
    longitude: -75.3899,
    postal_code: '18463'
  },
  '18464': {
    country: 'US',
    city: 'Tafton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Pike',
    latitude: 41.4045,
    longitude: -75.183,
    postal_code: '18464'
  },
  '18465': {
    country: 'US',
    city: 'Thompson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.834,
    longitude: -75.5342,
    postal_code: '18465'
  },
  '18466': {
    country: 'US',
    city: 'Tobyhanna',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.1836,
    longitude: -75.3918,
    postal_code: '18466'
  },
  '18469': {
    country: 'US',
    city: 'Tyler Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.7011,
    longitude: -75.1422,
    postal_code: '18469'
  },
  '18470': {
    country: 'US',
    city: 'Union Dale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.7079,
    longitude: -75.5465,
    postal_code: '18470'
  },
  '18471': {
    country: 'US',
    city: 'Waverly',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.5238,
    longitude: -75.6906,
    postal_code: '18471'
  },
  '18472': {
    country: 'US',
    city: 'Waymart',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.5703,
    longitude: -75.4065,
    postal_code: '18472'
  },
  '18473': {
    country: 'US',
    city: 'White Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wayne',
    latitude: 41.5262,
    longitude: -75.2035,
    postal_code: '18473'
  },
  '18501': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4019,
    longitude: -75.6376,
    postal_code: '18501'
  },
  '18502': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4092,
    longitude: -75.6649,
    postal_code: '18502'
  },
  '18503': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4095,
    longitude: -75.6642,
    postal_code: '18503'
  },
  '18504': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4128,
    longitude: -75.6861,
    postal_code: '18504'
  },
  '18505': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.3914,
    longitude: -75.6657,
    postal_code: '18505'
  },
  '18507': {
    country: 'US',
    city: 'Moosic',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.3554,
    longitude: -75.6805,
    postal_code: '18507'
  },
  '18508': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4389,
    longitude: -75.6625,
    postal_code: '18508'
  },
  '18509': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4274,
    longitude: -75.6465,
    postal_code: '18509'
  },
  '18510': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.408,
    longitude: -75.6484,
    postal_code: '18510'
  },
  '18512': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.435,
    longitude: -75.6103,
    postal_code: '18512'
  },
  '18515': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4035,
    longitude: -75.7065,
    postal_code: '18515'
  },
  '18517': {
    country: 'US',
    city: 'Taylor',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.3904,
    longitude: -75.7158,
    postal_code: '18517'
  },
  '18518': {
    country: 'US',
    city: 'Old Forge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.3701,
    longitude: -75.7391,
    postal_code: '18518'
  },
  '18519': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4092,
    longitude: -75.6649,
    postal_code: '18519'
  },
  '18540': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4019,
    longitude: -75.6376,
    postal_code: '18540'
  },
  '18577': {
    country: 'US',
    city: 'Scranton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.4019,
    longitude: -75.6376,
    postal_code: '18577'
  },
  '18601': {
    country: 'US',
    city: 'Beach Haven',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.0684,
    longitude: -76.1758,
    postal_code: '18601'
  },
  '18602': {
    country: 'US',
    city: 'Bear Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.1922,
    longitude: -75.7161,
    postal_code: '18602'
  },
  '18603': {
    country: 'US',
    city: 'Berwick',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 41.0665,
    longitude: -76.2443,
    postal_code: '18603'
  },
  '18610': {
    country: 'US',
    city: 'Blakeslee',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Monroe',
    latitude: 41.0485,
    longitude: -75.5343,
    postal_code: '18610'
  },
  '18611': {
    country: 'US',
    city: 'Cambra',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.1981,
    longitude: -76.3058,
    postal_code: '18611'
  },
  '18612': {
    country: 'US',
    city: 'Dallas',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3494,
    longitude: -75.9834,
    postal_code: '18612'
  },
  '18614': {
    country: 'US',
    city: 'Dushore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Sullivan',
    latitude: 41.5232,
    longitude: -76.4021,
    postal_code: '18614'
  },
  '18615': {
    country: 'US',
    city: 'Falls',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wyoming',
    latitude: 41.4667,
    longitude: -75.856,
    postal_code: '18615'
  },
  '18616': {
    country: 'US',
    city: 'Forksville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Sullivan',
    latitude: 41.5269,
    longitude: -76.6008,
    postal_code: '18616'
  },
  '18617': {
    country: 'US',
    city: 'Glen Lyon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.1746,
    longitude: -76.0746,
    postal_code: '18617'
  },
  '18618': {
    country: 'US',
    city: 'Harveys Lake',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3592,
    longitude: -76.0451,
    postal_code: '18618'
  },
  '18619': {
    country: 'US',
    city: 'Hillsgrove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Sullivan',
    latitude: 41.4482,
    longitude: -76.6979,
    postal_code: '18619'
  },
  '18621': {
    country: 'US',
    city: 'Hunlock Creek',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2459,
    longitude: -76.0879,
    postal_code: '18621'
  },
  '18622': {
    country: 'US',
    city: 'Huntington Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.1906,
    longitude: -76.2335,
    postal_code: '18622'
  },
  '18623': {
    country: 'US',
    city: 'Laceyville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wyoming',
    latitude: 41.6662,
    longitude: -76.1426,
    postal_code: '18623'
  },
  '18624': {
    country: 'US',
    city: 'Lake Harmony',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Carbon',
    latitude: 41.0542,
    longitude: -75.6331,
    postal_code: '18624'
  },
  '18625': {
    country: 'US',
    city: 'Lake Winola',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wyoming',
    latitude: 41.5096,
    longitude: -75.8508,
    postal_code: '18625'
  },
  '18626': {
    country: 'US',
    city: 'Laporte',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Sullivan',
    latitude: 41.4329,
    longitude: -76.5169,
    postal_code: '18626'
  },
  '18627': {
    country: 'US',
    city: 'Lehman',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3166,
    longitude: -76.021,
    postal_code: '18627'
  },
  '18628': {
    country: 'US',
    city: 'Lopez',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Sullivan',
    latitude: 41.418,
    longitude: -76.3002,
    postal_code: '18628'
  },
  '18629': {
    country: 'US',
    city: 'Mehoopany',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wyoming',
    latitude: 41.5587,
    longitude: -76.1035,
    postal_code: '18629'
  },
  '18630': {
    country: 'US',
    city: 'Meshoppen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wyoming',
    latitude: 41.6392,
    longitude: -76.0155,
    postal_code: '18630'
  },
  '18631': {
    country: 'US',
    city: 'Mifflinville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Columbia',
    latitude: 41.0323,
    longitude: -76.308,
    postal_code: '18631'
  },
  '18632': {
    country: 'US',
    city: 'Mildred',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Sullivan',
    latitude: 41.4792,
    longitude: -76.3831,
    postal_code: '18632'
  },
  '18634': {
    country: 'US',
    city: 'Nanticoke',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.1963,
    longitude: -76.0044,
    postal_code: '18634'
  },
  '18635': {
    country: 'US',
    city: 'Nescopeck',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.0469,
    longitude: -76.1981,
    postal_code: '18635'
  },
  '18636': {
    country: 'US',
    city: 'Noxen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wyoming',
    latitude: 41.4181,
    longitude: -76.046,
    postal_code: '18636'
  },
  '18640': {
    country: 'US',
    city: 'Pittston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3175,
    longitude: -75.7885,
    postal_code: '18640'
  },
  '18641': {
    country: 'US',
    city: 'Pittston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3361,
    longitude: -75.7304,
    postal_code: '18641'
  },
  '18642': {
    country: 'US',
    city: 'Duryea',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3486,
    longitude: -75.7611,
    postal_code: '18642'
  },
  '18643': {
    country: 'US',
    city: 'Pittston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3665,
    longitude: -75.8362,
    postal_code: '18643'
  },
  '18644': {
    country: 'US',
    city: 'Wyoming',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3197,
    longitude: -75.8541,
    postal_code: '18644'
  },
  '18651': {
    country: 'US',
    city: 'Plymouth',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2458,
    longitude: -75.9481,
    postal_code: '18651'
  },
  '18653': {
    country: 'US',
    city: 'Ransom',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lackawanna',
    latitude: 41.3945,
    longitude: -75.8242,
    postal_code: '18653'
  },
  '18654': {
    country: 'US',
    city: 'Shawanese',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3501,
    longitude: -76.0319,
    postal_code: '18654'
  },
  '18655': {
    country: 'US',
    city: 'Shickshinny',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.1513,
    longitude: -76.1081,
    postal_code: '18655'
  },
  '18656': {
    country: 'US',
    city: 'Sweet Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3066,
    longitude: -76.1339,
    postal_code: '18656'
  },
  '18657': {
    country: 'US',
    city: 'Tunkhannock',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Wyoming',
    latitude: 41.5387,
    longitude: -75.9466,
    postal_code: '18657'
  },
  '18660': {
    country: 'US',
    city: 'Wapwallopen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.068,
    longitude: -76.0857,
    postal_code: '18660'
  },
  '18661': {
    country: 'US',
    city: 'White Haven',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.079,
    longitude: -75.7715,
    postal_code: '18661'
  },
  '18690': {
    country: 'US',
    city: 'Dallas',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.3362,
    longitude: -75.9633,
    postal_code: '18690'
  },
  '18701': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2436,
    longitude: -75.885,
    postal_code: '18701'
  },
  '18702': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2208,
    longitude: -75.7736,
    postal_code: '18702'
  },
  '18703': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2421,
    longitude: -75.8857,
    postal_code: '18703'
  },
  '18704': {
    country: 'US',
    city: 'Kingston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2742,
    longitude: -75.8903,
    postal_code: '18704'
  },
  '18705': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2689,
    longitude: -75.8453,
    postal_code: '18705'
  },
  '18706': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2044,
    longitude: -75.9113,
    postal_code: '18706'
  },
  '18707': {
    country: 'US',
    city: 'Mountain Top',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.135,
    longitude: -75.9376,
    postal_code: '18707'
  },
  '18708': {
    country: 'US',
    city: 'Shavertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2998,
    longitude: -75.9711,
    postal_code: '18708'
  },
  '18709': {
    country: 'US',
    city: 'Luzerne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2843,
    longitude: -75.8935,
    postal_code: '18709'
  },
  '18710': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2722,
    longitude: -75.8801,
    postal_code: '18710'
  },
  '18711': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2722,
    longitude: -75.8801,
    postal_code: '18711'
  },
  '18762': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2722,
    longitude: -75.8801,
    postal_code: '18762'
  },
  '18764': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2722,
    longitude: -75.8801,
    postal_code: '18764'
  },
  '18765': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2722,
    longitude: -75.8801,
    postal_code: '18765'
  },
  '18766': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2448,
    longitude: -75.8896,
    postal_code: '18766'
  },
  '18767': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2722,
    longitude: -75.8801,
    postal_code: '18767'
  },
  '18769': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2722,
    longitude: -75.8801,
    postal_code: '18769'
  },
  '18773': {
    country: 'US',
    city: 'Wilkes Barre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Luzerne',
    latitude: 41.2722,
    longitude: -75.8801,
    postal_code: '18773'
  },
  '18801': {
    country: 'US',
    city: 'Montrose',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.8396,
    longitude: -75.8821,
    postal_code: '18801'
  },
  '18810': {
    country: 'US',
    city: 'Athens',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.949,
    longitude: -76.4889,
    postal_code: '18810'
  },
  '18812': {
    country: 'US',
    city: 'Brackney',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.9666,
    longitude: -75.9375,
    postal_code: '18812'
  },
  '18813': {
    country: 'US',
    city: 'Brooklyn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.7512,
    longitude: -75.8069,
    postal_code: '18813'
  },
  '18814': {
    country: 'US',
    city: 'Burlington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.7817,
    longitude: -76.6056,
    postal_code: '18814'
  },
  '18815': {
    country: 'US',
    city: 'Camptown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.7312,
    longitude: -76.2347,
    postal_code: '18815'
  },
  '18816': {
    country: 'US',
    city: 'Dimock',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.7465,
    longitude: -75.8983,
    postal_code: '18816'
  },
  '18817': {
    country: 'US',
    city: 'East Smithfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.8645,
    longitude: -76.6255,
    postal_code: '18817'
  },
  '18818': {
    country: 'US',
    city: 'Friendsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.9164,
    longitude: -76.0257,
    postal_code: '18818'
  },
  '18820': {
    country: 'US',
    city: 'Gibson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.8034,
    longitude: -75.6441,
    postal_code: '18820'
  },
  '18821': {
    country: 'US',
    city: 'Great Bend',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.9717,
    longitude: -75.7432,
    postal_code: '18821'
  },
  '18822': {
    country: 'US',
    city: 'Hallstead',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.9598,
    longitude: -75.7826,
    postal_code: '18822'
  },
  '18823': {
    country: 'US',
    city: 'Harford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.7761,
    longitude: -75.6935,
    postal_code: '18823'
  },
  '18824': {
    country: 'US',
    city: 'Hop Bottom',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.6932,
    longitude: -75.7897,
    postal_code: '18824'
  },
  '18825': {
    country: 'US',
    city: 'Jackson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.8203,
    longitude: -75.8046,
    postal_code: '18825'
  },
  '18826': {
    country: 'US',
    city: 'Kingsley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.7659,
    longitude: -75.7831,
    postal_code: '18826'
  },
  '18827': {
    country: 'US',
    city: 'Lanesboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.957,
    longitude: -75.5849,
    postal_code: '18827'
  },
  '18828': {
    country: 'US',
    city: 'Lawton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.7573,
    longitude: -76.0912,
    postal_code: '18828'
  },
  '18829': {
    country: 'US',
    city: 'Le Raysville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.8434,
    longitude: -76.1796,
    postal_code: '18829'
  },
  '18830': {
    country: 'US',
    city: 'Little Meadows',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.9766,
    longitude: -76.1185,
    postal_code: '18830'
  },
  '18831': {
    country: 'US',
    city: 'Milan',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.8966,
    longitude: -76.5328,
    postal_code: '18831'
  },
  '18832': {
    country: 'US',
    city: 'Monroeton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.7135,
    longitude: -76.4872,
    postal_code: '18832'
  },
  '18833': {
    country: 'US',
    city: 'New Albany',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.5987,
    longitude: -76.4398,
    postal_code: '18833'
  },
  '18834': {
    country: 'US',
    city: 'New Milford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.8664,
    longitude: -75.7171,
    postal_code: '18834'
  },
  '18837': {
    country: 'US',
    city: 'Rome',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.8634,
    longitude: -76.3015,
    postal_code: '18837'
  },
  '18840': {
    country: 'US',
    city: 'Sayre',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.9842,
    longitude: -76.5218,
    postal_code: '18840'
  },
  '18842': {
    country: 'US',
    city: 'South Gibson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.7342,
    longitude: -75.6302,
    postal_code: '18842'
  },
  '18843': {
    country: 'US',
    city: 'South Montrose',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.7973,
    longitude: -75.8913,
    postal_code: '18843'
  },
  '18844': {
    country: 'US',
    city: 'Springville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.7147,
    longitude: -75.9025,
    postal_code: '18844'
  },
  '18845': {
    country: 'US',
    city: 'Stevensville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.7718,
    longitude: -76.1717,
    postal_code: '18845'
  },
  '18846': {
    country: 'US',
    city: 'Sugar Run',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.6041,
    longitude: -76.2598,
    postal_code: '18846'
  },
  '18847': {
    country: 'US',
    city: 'Susquehanna',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Susquehanna',
    latitude: 41.9487,
    longitude: -75.5862,
    postal_code: '18847'
  },
  '18848': {
    country: 'US',
    city: 'Towanda',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.7638,
    longitude: -76.4645,
    postal_code: '18848'
  },
  '18850': {
    country: 'US',
    city: 'Ulster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.8408,
    longitude: -76.4876,
    postal_code: '18850'
  },
  '18851': {
    country: 'US',
    city: 'Warren Center',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.9394,
    longitude: -76.1964,
    postal_code: '18851'
  },
  '18853': {
    country: 'US',
    city: 'Wyalusing',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.7015,
    longitude: -76.2754,
    postal_code: '18853'
  },
  '18854': {
    country: 'US',
    city: 'Wysox',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bradford',
    latitude: 41.7826,
    longitude: -76.3834,
    postal_code: '18854'
  },
  '18901': {
    country: 'US',
    city: 'Doylestown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3054,
    longitude: -75.1489,
    postal_code: '18901'
  },
  '18902': {
    country: 'US',
    city: 'Doylestown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3477,
    longitude: -75.0968,
    postal_code: '18902'
  },
  '18910': {
    country: 'US',
    city: 'Bedminster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.4259,
    longitude: -75.1791,
    postal_code: '18910'
  },
  '18911': {
    country: 'US',
    city: 'Blooming Glen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3695,
    longitude: -75.2482,
    postal_code: '18911'
  },
  '18912': {
    country: 'US',
    city: 'Buckingham',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3237,
    longitude: -75.0599,
    postal_code: '18912'
  },
  '18913': {
    country: 'US',
    city: 'Carversville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3865,
    longitude: -75.0627,
    postal_code: '18913'
  },
  '18914': {
    country: 'US',
    city: 'Chalfont',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2892,
    longitude: -75.2149,
    postal_code: '18914'
  },
  '18915': {
    country: 'US',
    city: 'Colmar',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2728,
    longitude: -75.2563,
    postal_code: '18915'
  },
  '18916': {
    country: 'US',
    city: 'Danboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3545,
    longitude: -75.1327,
    postal_code: '18916'
  },
  '18917': {
    country: 'US',
    city: 'Dublin',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.375,
    longitude: -75.2045,
    postal_code: '18917'
  },
  '18918': {
    country: 'US',
    city: 'Earlington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.32,
    longitude: -75.3742,
    postal_code: '18918'
  },
  '18920': {
    country: 'US',
    city: 'Erwinna',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.5087,
    longitude: -75.0804,
    postal_code: '18920'
  },
  '18921': {
    country: 'US',
    city: 'Ferndale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.5337,
    longitude: -75.1788,
    postal_code: '18921'
  },
  '18922': {
    country: 'US',
    city: 'Forest Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2923,
    longitude: -75.0591,
    postal_code: '18922'
  },
  '18923': {
    country: 'US',
    city: 'Fountainville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3578,
    longitude: -75.1711,
    postal_code: '18923'
  },
  '18924': {
    country: 'US',
    city: 'Franconia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3081,
    longitude: -75.3586,
    postal_code: '18924'
  },
  '18925': {
    country: 'US',
    city: 'Furlong',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2945,
    longitude: -75.0649,
    postal_code: '18925'
  },
  '18927': {
    country: 'US',
    city: 'Hilltown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3235,
    longitude: -75.2548,
    postal_code: '18927'
  },
  '18928': {
    country: 'US',
    city: 'Holicong',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.336,
    longitude: -75.0429,
    postal_code: '18928'
  },
  '18929': {
    country: 'US',
    city: 'Jamison',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2566,
    longitude: -75.0961,
    postal_code: '18929'
  },
  '18930': {
    country: 'US',
    city: 'Kintnersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.531,
    longitude: -75.2117,
    postal_code: '18930'
  },
  '18931': {
    country: 'US',
    city: 'Lahaska',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3286,
    longitude: -75.1028,
    postal_code: '18931'
  },
  '18932': {
    country: 'US',
    city: 'Line Lexington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2888,
    longitude: -75.2555,
    postal_code: '18932'
  },
  '18933': {
    country: 'US',
    city: 'Lumberville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3286,
    longitude: -75.1028,
    postal_code: '18933'
  },
  '18934': {
    country: 'US',
    city: 'Mechanicsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3494,
    longitude: -75.0676,
    postal_code: '18934'
  },
  '18935': {
    country: 'US',
    city: 'Milford Square',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.437,
    longitude: -75.3988,
    postal_code: '18935'
  },
  '18936': {
    country: 'US',
    city: 'Montgomeryville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2241,
    longitude: -75.231,
    postal_code: '18936'
  },
  '18938': {
    country: 'US',
    city: 'New Hope',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3556,
    longitude: -74.9839,
    postal_code: '18938'
  },
  '18940': {
    country: 'US',
    city: 'Newtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.263,
    longitude: -74.9555,
    postal_code: '18940'
  },
  '18942': {
    country: 'US',
    city: 'Ottsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.4592,
    longitude: -75.157,
    postal_code: '18942'
  },
  '18943': {
    country: 'US',
    city: 'Penns Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.269,
    longitude: -75.0094,
    postal_code: '18943'
  },
  '18944': {
    country: 'US',
    city: 'Perkasie',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3765,
    longitude: -75.2648,
    postal_code: '18944'
  },
  '18946': {
    country: 'US',
    city: 'Pineville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2962,
    longitude: -75.0057,
    postal_code: '18946'
  },
  '18947': {
    country: 'US',
    city: 'Pipersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.4262,
    longitude: -75.1074,
    postal_code: '18947'
  },
  '18949': {
    country: 'US',
    city: 'Plumsteadville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.388,
    longitude: -75.1425,
    postal_code: '18949'
  },
  '18950': {
    country: 'US',
    city: 'Point Pleasant',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.4226,
    longitude: -75.0663,
    postal_code: '18950'
  },
  '18951': {
    country: 'US',
    city: 'Quakertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.4411,
    longitude: -75.3507,
    postal_code: '18951'
  },
  '18953': {
    country: 'US',
    city: 'Revere',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.5154,
    longitude: -75.161,
    postal_code: '18953'
  },
  '18954': {
    country: 'US',
    city: 'Richboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2167,
    longitude: -75.0029,
    postal_code: '18954'
  },
  '18955': {
    country: 'US',
    city: 'Richlandtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.4796,
    longitude: -75.3146,
    postal_code: '18955'
  },
  '18956': {
    country: 'US',
    city: 'Rushland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2628,
    longitude: -75.0157,
    postal_code: '18956'
  },
  '18957': {
    country: 'US',
    city: 'Salford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3029,
    longitude: -75.4486,
    postal_code: '18957'
  },
  '18958': {
    country: 'US',
    city: 'Salfordville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2904,
    longitude: -75.4355,
    postal_code: '18958'
  },
  '18960': {
    country: 'US',
    city: 'Sellersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.362,
    longitude: -75.319,
    postal_code: '18960'
  },
  '18962': {
    country: 'US',
    city: 'Silverdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3445,
    longitude: -75.2687,
    postal_code: '18962'
  },
  '18963': {
    country: 'US',
    city: 'Solebury',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3807,
    longitude: -75.0082,
    postal_code: '18963'
  },
  '18964': {
    country: 'US',
    city: 'Souderton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2884,
    longitude: -75.341,
    postal_code: '18964'
  },
  '18966': {
    country: 'US',
    city: 'Southampton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1868,
    longitude: -75.0071,
    postal_code: '18966'
  },
  '18968': {
    country: 'US',
    city: 'Spinnerstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.439,
    longitude: -75.4371,
    postal_code: '18968'
  },
  '18969': {
    country: 'US',
    city: 'Telford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3205,
    longitude: -75.352,
    postal_code: '18969'
  },
  '18970': {
    country: 'US',
    city: 'Trumbauersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.4107,
    longitude: -75.3782,
    postal_code: '18970'
  },
  '18971': {
    country: 'US',
    city: 'Tylersport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.347,
    longitude: -75.377,
    postal_code: '18971'
  },
  '18972': {
    country: 'US',
    city: 'Upper Black Eddy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.5411,
    longitude: -75.1259,
    postal_code: '18972'
  },
  '18974': {
    country: 'US',
    city: 'Warminster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2067,
    longitude: -75.0905,
    postal_code: '18974'
  },
  '18976': {
    country: 'US',
    city: 'Warrington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2464,
    longitude: -75.1354,
    postal_code: '18976'
  },
  '18977': {
    country: 'US',
    city: 'Washington Crossing',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2849,
    longitude: -74.8778,
    postal_code: '18977'
  },
  '18979': {
    country: 'US',
    city: 'Woxall',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3107,
    longitude: -75.4488,
    postal_code: '18979'
  },
  '18980': {
    country: 'US',
    city: 'Wycombe',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2742,
    longitude: -75.0205,
    postal_code: '18980'
  },
  '18981': {
    country: 'US',
    city: 'Zionhill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.4843,
    longitude: -75.3938,
    postal_code: '18981'
  },
  '18991': {
    country: 'US',
    city: 'Warminster',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.3286,
    longitude: -75.1028,
    postal_code: '18991'
  },
  '19001': {
    country: 'US',
    city: 'Abington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1238,
    longitude: -75.1148,
    postal_code: '19001'
  },
  '19002': {
    country: 'US',
    city: 'Ambler',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1809,
    longitude: -75.2156,
    postal_code: '19002'
  },
  '19003': {
    country: 'US',
    city: 'Ardmore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 40.002,
    longitude: -75.2966,
    postal_code: '19003'
  },
  '19004': {
    country: 'US',
    city: 'Bala Cynwyd',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.0118,
    longitude: -75.2342,
    postal_code: '19004'
  },
  '19006': {
    country: 'US',
    city: 'Huntingdon Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1284,
    longitude: -75.0607,
    postal_code: '19006'
  },
  '19007': {
    country: 'US',
    city: 'Bristol',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1159,
    longitude: -74.8536,
    postal_code: '19007'
  },
  '19008': {
    country: 'US',
    city: 'Broomall',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9747,
    longitude: -75.3602,
    postal_code: '19008'
  },
  '19009': {
    country: 'US',
    city: 'Bryn Athyn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.135,
    longitude: -75.0623,
    postal_code: '19009'
  },
  '19010': {
    country: 'US',
    city: 'Bryn Mawr',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 40.0236,
    longitude: -75.3295,
    postal_code: '19010'
  },
  '19012': {
    country: 'US',
    city: 'Cheltenham',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.0603,
    longitude: -75.1048,
    postal_code: '19012'
  },
  '19013': {
    country: 'US',
    city: 'Chester',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8498,
    longitude: -75.3747,
    postal_code: '19013'
  },
  '19014': {
    country: 'US',
    city: 'Aston',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8643,
    longitude: -75.4332,
    postal_code: '19014'
  },
  '19015': {
    country: 'US',
    city: 'Brookhaven',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8654,
    longitude: -75.3885,
    postal_code: '19015'
  },
  '19016': {
    country: 'US',
    city: 'Chester',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.934,
    longitude: -75.406,
    postal_code: '19016'
  },
  '19017': {
    country: 'US',
    city: 'Chester Heights',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8901,
    longitude: -75.4755,
    postal_code: '19017'
  },
  '19018': {
    country: 'US',
    city: 'Clifton Heights',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9218,
    longitude: -75.2876,
    postal_code: '19018'
  },
  '19019': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19019'
  },
  '19020': {
    country: 'US',
    city: 'Bensalem',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1109,
    longitude: -74.9378,
    postal_code: '19020'
  },
  '19021': {
    country: 'US',
    city: 'Croydon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.0933,
    longitude: -74.8991,
    postal_code: '19021'
  },
  '19022': {
    country: 'US',
    city: 'Crum Lynne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8685,
    longitude: -75.3374,
    postal_code: '19022'
  },
  '19023': {
    country: 'US',
    city: 'Darby',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9176,
    longitude: -75.2696,
    postal_code: '19023'
  },
  '19025': {
    country: 'US',
    city: 'Dresher',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1431,
    longitude: -75.1624,
    postal_code: '19025'
  },
  '19026': {
    country: 'US',
    city: 'Drexel Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9503,
    longitude: -75.304,
    postal_code: '19026'
  },
  '19027': {
    country: 'US',
    city: 'Elkins Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.075,
    longitude: -75.1315,
    postal_code: '19027'
  },
  '19028': {
    country: 'US',
    city: 'Edgemont',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9748,
    longitude: -75.4505,
    postal_code: '19028'
  },
  '19029': {
    country: 'US',
    city: 'Essington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8621,
    longitude: -75.2971,
    postal_code: '19029'
  },
  '19030': {
    country: 'US',
    city: 'Fairless Hills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1748,
    longitude: -74.8519,
    postal_code: '19030'
  },
  '19031': {
    country: 'US',
    city: 'Flourtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1068,
    longitude: -75.2115,
    postal_code: '19031'
  },
  '19032': {
    country: 'US',
    city: 'Folcroft',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8905,
    longitude: -75.2821,
    postal_code: '19032'
  },
  '19033': {
    country: 'US',
    city: 'Folsom',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8901,
    longitude: -75.3296,
    postal_code: '19033'
  },
  '19034': {
    country: 'US',
    city: 'Fort Washington',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1386,
    longitude: -75.2022,
    postal_code: '19034'
  },
  '19035': {
    country: 'US',
    city: 'Gladwyne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.0451,
    longitude: -75.2821,
    postal_code: '19035'
  },
  '19036': {
    country: 'US',
    city: 'Glenolden',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9048,
    longitude: -75.2946,
    postal_code: '19036'
  },
  '19037': {
    country: 'US',
    city: 'Glen Riddle Lima',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.934,
    longitude: -75.406,
    postal_code: '19037'
  },
  '19038': {
    country: 'US',
    city: 'Glenside',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1096,
    longitude: -75.155,
    postal_code: '19038'
  },
  '19039': {
    country: 'US',
    city: 'Gradyville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9432,
    longitude: -75.4694,
    postal_code: '19039'
  },
  '19040': {
    country: 'US',
    city: 'Hatboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1785,
    longitude: -75.1072,
    postal_code: '19040'
  },
  '19041': {
    country: 'US',
    city: 'Haverford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 40.0097,
    longitude: -75.3121,
    postal_code: '19041'
  },
  '19043': {
    country: 'US',
    city: 'Holmes',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9003,
    longitude: -75.3087,
    postal_code: '19043'
  },
  '19044': {
    country: 'US',
    city: 'Horsham',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1821,
    longitude: -75.1479,
    postal_code: '19044'
  },
  '19046': {
    country: 'US',
    city: 'Jenkintown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.098,
    longitude: -75.1078,
    postal_code: '19046'
  },
  '19047': {
    country: 'US',
    city: 'Langhorne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1813,
    longitude: -74.9104,
    postal_code: '19047'
  },
  '19048': {
    country: 'US',
    city: 'Langhorne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1735,
    longitude: -74.9246,
    postal_code: '19048'
  },
  '19049': {
    country: 'US',
    city: 'Langhorne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1746,
    longitude: -74.9227,
    postal_code: '19049'
  },
  '19050': {
    country: 'US',
    city: 'Lansdowne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9375,
    longitude: -75.2637,
    postal_code: '19050'
  },
  '19052': {
    country: 'US',
    city: 'Lenni',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8942,
    longitude: -75.4456,
    postal_code: '19052'
  },
  '19053': {
    country: 'US',
    city: 'Feasterville Trevose',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1547,
    longitude: -74.9904,
    postal_code: '19053'
  },
  '19054': {
    country: 'US',
    city: 'Levittown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1681,
    longitude: -74.8231,
    postal_code: '19054'
  },
  '19055': {
    country: 'US',
    city: 'Levittown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1483,
    longitude: -74.8371,
    postal_code: '19055'
  },
  '19056': {
    country: 'US',
    city: 'Levittown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1519,
    longitude: -74.8826,
    postal_code: '19056'
  },
  '19057': {
    country: 'US',
    city: 'Levittown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1434,
    longitude: -74.8614,
    postal_code: '19057'
  },
  '19058': {
    country: 'US',
    city: 'Levittown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.1551,
    longitude: -74.8288,
    postal_code: '19058'
  },
  '19060': {
    country: 'US',
    city: 'Garnet Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.852,
    longitude: -75.5007,
    postal_code: '19060'
  },
  '19061': {
    country: 'US',
    city: 'Marcus Hook',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8295,
    longitude: -75.4354,
    postal_code: '19061'
  },
  '19063': {
    country: 'US',
    city: 'Media',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9188,
    longitude: -75.3991,
    postal_code: '19063'
  },
  '19064': {
    country: 'US',
    city: 'Springfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9296,
    longitude: -75.3338,
    postal_code: '19064'
  },
  '19065': {
    country: 'US',
    city: 'Media',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9211,
    longitude: -75.3861,
    postal_code: '19065'
  },
  '19066': {
    country: 'US',
    city: 'Merion Station',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.003,
    longitude: -75.2503,
    postal_code: '19066'
  },
  '19067': {
    country: 'US',
    city: 'Morrisville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Bucks',
    latitude: 40.2084,
    longitude: -74.8291,
    postal_code: '19067'
  },
  '19070': {
    country: 'US',
    city: 'Morton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9063,
    longitude: -75.3238,
    postal_code: '19070'
  },
  '19072': {
    country: 'US',
    city: 'Narberth',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.0177,
    longitude: -75.2594,
    postal_code: '19072'
  },
  '19073': {
    country: 'US',
    city: 'Newtown Square',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9863,
    longitude: -75.407,
    postal_code: '19073'
  },
  '19074': {
    country: 'US',
    city: 'Norwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.887,
    longitude: -75.2972,
    postal_code: '19074'
  },
  '19075': {
    country: 'US',
    city: 'Oreland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1132,
    longitude: -75.1869,
    postal_code: '19075'
  },
  '19076': {
    country: 'US',
    city: 'Prospect Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8857,
    longitude: -75.3082,
    postal_code: '19076'
  },
  '19078': {
    country: 'US',
    city: 'Ridley Park',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8784,
    longitude: -75.3215,
    postal_code: '19078'
  },
  '19079': {
    country: 'US',
    city: 'Sharon Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9035,
    longitude: -75.2695,
    postal_code: '19079'
  },
  '19080': {
    country: 'US',
    city: 'Wayne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 40.044,
    longitude: -75.3877,
    postal_code: '19080'
  },
  '19081': {
    country: 'US',
    city: 'Swarthmore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8967,
    longitude: -75.3474,
    postal_code: '19081'
  },
  '19082': {
    country: 'US',
    city: 'Upper Darby',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9579,
    longitude: -75.2681,
    postal_code: '19082'
  },
  '19083': {
    country: 'US',
    city: 'Havertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9774,
    longitude: -75.3106,
    postal_code: '19083'
  },
  '19085': {
    country: 'US',
    city: 'Villanova',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 40.0399,
    longitude: -75.3459,
    postal_code: '19085'
  },
  '19086': {
    country: 'US',
    city: 'Wallingford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8871,
    longitude: -75.3721,
    postal_code: '19086'
  },
  '19087': {
    country: 'US',
    city: 'Wayne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 40.0612,
    longitude: -75.3999,
    postal_code: '19087'
  },
  '19088': {
    country: 'US',
    city: 'Wayne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.934,
    longitude: -75.406,
    postal_code: '19088'
  },
  '19089': {
    country: 'US',
    city: 'Wayne',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 40.044,
    longitude: -75.3877,
    postal_code: '19089'
  },
  '19090': {
    country: 'US',
    city: 'Willow Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1567,
    longitude: -75.1269,
    postal_code: '19090'
  },
  '19091': {
    country: 'US',
    city: 'Media',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.934,
    longitude: -75.406,
    postal_code: '19091'
  },
  '19092': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19092'
  },
  '19093': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19093'
  },
  '19094': {
    country: 'US',
    city: 'Woodlyn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.876,
    longitude: -75.3463,
    postal_code: '19094'
  },
  '19095': {
    country: 'US',
    city: 'Wyncote',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.0867,
    longitude: -75.1524,
    postal_code: '19095'
  },
  '19096': {
    country: 'US',
    city: 'Wynnewood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40,
    longitude: -75.276,
    postal_code: '19096'
  },
  '19098': {
    country: 'US',
    city: 'Holmes',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8954,
    longitude: -75.3107,
    postal_code: '19098'
  },
  '19099': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9523,
    longitude: -75.1638,
    postal_code: '19099'
  },
  '19101': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19101'
  },
  '19102': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9489,
    longitude: -75.1661,
    postal_code: '19102'
  },
  '19103': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9513,
    longitude: -75.1741,
    postal_code: '19103'
  },
  '19104': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9597,
    longitude: -75.2024,
    postal_code: '19104'
  },
  '19105': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9523,
    longitude: -75.1638,
    postal_code: '19105'
  },
  '19106': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9474,
    longitude: -75.1473,
    postal_code: '19106'
  },
  '19107': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9487,
    longitude: -75.1593,
    postal_code: '19107'
  },
  '19108': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9598,
    longitude: -75.1616,
    postal_code: '19108'
  },
  '19109': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9496,
    longitude: -75.1637,
    postal_code: '19109'
  },
  '19110': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9502,
    longitude: -75.1636,
    postal_code: '19110'
  },
  '19111': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0596,
    longitude: -75.0818,
    postal_code: '19111'
  },
  '19112': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.8893,
    longitude: -75.1782,
    postal_code: '19112'
  },
  '19113': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.865,
    longitude: -75.2752,
    postal_code: '19113'
  },
  '19114': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0634,
    longitude: -74.999,
    postal_code: '19114'
  },
  '19115': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0903,
    longitude: -75.041,
    postal_code: '19115'
  },
  '19116': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.1166,
    longitude: -75.0198,
    postal_code: '19116'
  },
  '19118': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0723,
    longitude: -75.2034,
    postal_code: '19118'
  },
  '19119': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0547,
    longitude: -75.1866,
    postal_code: '19119'
  },
  '19120': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0343,
    longitude: -75.1213,
    postal_code: '19120'
  },
  '19121': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9811,
    longitude: -75.174,
    postal_code: '19121'
  },
  '19122': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.978,
    longitude: -75.1459,
    postal_code: '19122'
  },
  '19123': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.966,
    longitude: -75.151,
    postal_code: '19123'
  },
  '19124': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0178,
    longitude: -75.0895,
    postal_code: '19124'
  },
  '19125': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9788,
    longitude: -75.1262,
    postal_code: '19125'
  },
  '19126': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.0568,
    longitude: -75.1379,
    postal_code: '19126'
  },
  '19127': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0275,
    longitude: -75.2242,
    postal_code: '19127'
  },
  '19128': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0402,
    longitude: -75.2231,
    postal_code: '19128'
  },
  '19129': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0118,
    longitude: -75.1861,
    postal_code: '19129'
  },
  '19130': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9677,
    longitude: -75.1735,
    postal_code: '19130'
  },
  '19131': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9845,
    longitude: -75.2282,
    postal_code: '19131'
  },
  '19132': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9954,
    longitude: -75.1698,
    postal_code: '19132'
  },
  '19133': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9925,
    longitude: -75.1415,
    postal_code: '19133'
  },
  '19134': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9925,
    longitude: -75.1133,
    postal_code: '19134'
  },
  '19135': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0247,
    longitude: -75.0518,
    postal_code: '19135'
  },
  '19136': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0422,
    longitude: -75.0244,
    postal_code: '19136'
  },
  '19137': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0008,
    longitude: -75.0727,
    postal_code: '19137'
  },
  '19138': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0568,
    longitude: -75.1569,
    postal_code: '19138'
  },
  '19139': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9612,
    longitude: -75.2303,
    postal_code: '19139'
  },
  '19140': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0118,
    longitude: -75.1456,
    postal_code: '19140'
  },
  '19141': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0365,
    longitude: -75.1451,
    postal_code: '19141'
  },
  '19142': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9223,
    longitude: -75.2338,
    postal_code: '19142'
  },
  '19143': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9448,
    longitude: -75.2288,
    postal_code: '19143'
  },
  '19144': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0338,
    longitude: -75.1731,
    postal_code: '19144'
  },
  '19145': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9227,
    longitude: -75.1812,
    postal_code: '19145'
  },
  '19146': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9379,
    longitude: -75.1794,
    postal_code: '19146'
  },
  '19147': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9362,
    longitude: -75.1563,
    postal_code: '19147'
  },
  '19148': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9207,
    longitude: -75.1595,
    postal_code: '19148'
  },
  '19149': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0369,
    longitude: -75.0664,
    postal_code: '19149'
  },
  '19150': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0726,
    longitude: -75.1706,
    postal_code: '19150'
  },
  '19151': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9772,
    longitude: -75.2545,
    postal_code: '19151'
  },
  '19152': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0606,
    longitude: -75.0471,
    postal_code: '19152'
  },
  '19153': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9055,
    longitude: -75.2444,
    postal_code: '19153'
  },
  '19154': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0897,
    longitude: -74.9781,
    postal_code: '19154'
  },
  '19155': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19155'
  },
  '19160': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19160'
  },
  '19161': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19161'
  },
  '19162': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19162'
  },
  '19170': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19170'
  },
  '19171': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9523,
    longitude: -75.1638,
    postal_code: '19171'
  },
  '19172': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9473,
    longitude: -75.15,
    postal_code: '19172'
  },
  '19173': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9523,
    longitude: -75.1638,
    postal_code: '19173'
  },
  '19175': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9906,
    longitude: -75.1296,
    postal_code: '19175'
  },
  '19176': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9522,
    longitude: -75.1642,
    postal_code: '19176'
  },
  '19177': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19177'
  },
  '19178': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19178'
  },
  '19179': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19179'
  },
  '19181': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19181'
  },
  '19182': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19182'
  },
  '19183': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9523,
    longitude: -75.1638,
    postal_code: '19183'
  },
  '19184': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19184'
  },
  '19185': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19185'
  },
  '19187': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9523,
    longitude: -75.1638,
    postal_code: '19187'
  },
  '19188': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19188'
  },
  '19190': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9484,
    longitude: -75.1442,
    postal_code: '19190'
  },
  '19191': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9523,
    longitude: -75.1638,
    postal_code: '19191'
  },
  '19192': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9511,
    longitude: -75.1676,
    postal_code: '19192'
  },
  '19193': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19193'
  },
  '19194': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19194'
  },
  '19195': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 39.9525,
    longitude: -75.1645,
    postal_code: '19195'
  },
  '19196': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19196'
  },
  '19197': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19197'
  },
  '19244': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19244'
  },
  '19255': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Philadelphia',
    latitude: 40.0018,
    longitude: -75.1179,
    postal_code: '19255'
  },
  '19301': {
    country: 'US',
    city: 'Paoli',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0426,
    longitude: -75.4827,
    postal_code: '19301'
  },
  '19310': {
    country: 'US',
    city: 'Atglen',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9458,
    longitude: -75.9703,
    postal_code: '19310'
  },
  '19311': {
    country: 'US',
    city: 'Avondale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.8219,
    longitude: -75.7687,
    postal_code: '19311'
  },
  '19312': {
    country: 'US',
    city: 'Berwyn',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0412,
    longitude: -75.4475,
    postal_code: '19312'
  },
  '19316': {
    country: 'US',
    city: 'Brandamore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.055,
    longitude: -75.8378,
    postal_code: '19316'
  },
  '19317': {
    country: 'US',
    city: 'Chadds Ford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8648,
    longitude: -75.5885,
    postal_code: '19317'
  },
  '19318': {
    country: 'US',
    city: 'Chatham',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.8524,
    longitude: -75.8118,
    postal_code: '19318'
  },
  '19319': {
    country: 'US',
    city: 'Cheyney',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9209,
    longitude: -75.5222,
    postal_code: '19319'
  },
  '19320': {
    country: 'US',
    city: 'Coatesville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9843,
    longitude: -75.8253,
    postal_code: '19320'
  },
  '19330': {
    country: 'US',
    city: 'Cochranville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.8757,
    longitude: -75.9214,
    postal_code: '19330'
  },
  '19331': {
    country: 'US',
    city: 'Concordville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8823,
    longitude: -75.5165,
    postal_code: '19331'
  },
  '19333': {
    country: 'US',
    city: 'Devon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0452,
    longitude: -75.4227,
    postal_code: '19333'
  },
  '19335': {
    country: 'US',
    city: 'Downingtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0161,
    longitude: -75.7183,
    postal_code: '19335'
  },
  '19339': {
    country: 'US',
    city: 'Concordville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.934,
    longitude: -75.406,
    postal_code: '19339'
  },
  '19340': {
    country: 'US',
    city: 'Concordville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.8851,
    longitude: -75.5202,
    postal_code: '19340'
  },
  '19341': {
    country: 'US',
    city: 'Exton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0468,
    longitude: -75.6432,
    postal_code: '19341'
  },
  '19342': {
    country: 'US',
    city: 'Glen Mills',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9015,
    longitude: -75.5049,
    postal_code: '19342'
  },
  '19343': {
    country: 'US',
    city: 'Glenmoore',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0846,
    longitude: -75.7711,
    postal_code: '19343'
  },
  '19344': {
    country: 'US',
    city: 'Honey Brook',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0832,
    longitude: -75.8843,
    postal_code: '19344'
  },
  '19345': {
    country: 'US',
    city: 'Immaculata',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.8478,
    longitude: -75.7193,
    postal_code: '19345'
  },
  '19346': {
    country: 'US',
    city: 'Kelton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.8087,
    longitude: -75.878,
    postal_code: '19346'
  },
  '19347': {
    country: 'US',
    city: 'Kemblesville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.749,
    longitude: -75.8241,
    postal_code: '19347'
  },
  '19348': {
    country: 'US',
    city: 'Kennett Square',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.855,
    longitude: -75.7,
    postal_code: '19348'
  },
  '19350': {
    country: 'US',
    city: 'Landenberg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.7696,
    longitude: -75.7807,
    postal_code: '19350'
  },
  '19351': {
    country: 'US',
    city: 'Lewisville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.7226,
    longitude: -75.8749,
    postal_code: '19351'
  },
  '19352': {
    country: 'US',
    city: 'Lincoln University',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.7787,
    longitude: -75.8876,
    postal_code: '19352'
  },
  '19353': {
    country: 'US',
    city: 'Lionville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0132,
    longitude: -75.499,
    postal_code: '19353'
  },
  '19354': {
    country: 'US',
    city: 'Lyndell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0582,
    longitude: -75.7433,
    postal_code: '19354'
  },
  '19355': {
    country: 'US',
    city: 'Malvern',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0468,
    longitude: -75.531,
    postal_code: '19355'
  },
  '19357': {
    country: 'US',
    city: 'Mendenhall',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.854,
    longitude: -75.6413,
    postal_code: '19357'
  },
  '19358': {
    country: 'US',
    city: 'Modena',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9621,
    longitude: -75.8025,
    postal_code: '19358'
  },
  '19360': {
    country: 'US',
    city: 'New London',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.7261,
    longitude: -75.7857,
    postal_code: '19360'
  },
  '19362': {
    country: 'US',
    city: 'Nottingham',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.7441,
    longitude: -76.0356,
    postal_code: '19362'
  },
  '19363': {
    country: 'US',
    city: 'Oxford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.7827,
    longitude: -75.9815,
    postal_code: '19363'
  },
  '19365': {
    country: 'US',
    city: 'Parkesburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9654,
    longitude: -75.926,
    postal_code: '19365'
  },
  '19366': {
    country: 'US',
    city: 'Pocopson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9004,
    longitude: -75.6258,
    postal_code: '19366'
  },
  '19367': {
    country: 'US',
    city: 'Pomeroy',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9643,
    longitude: -75.8866,
    postal_code: '19367'
  },
  '19369': {
    country: 'US',
    city: 'Sadsburyville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9903,
    longitude: -75.8936,
    postal_code: '19369'
  },
  '19371': {
    country: 'US',
    city: 'Suplee',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0995,
    longitude: -75.8791,
    postal_code: '19371'
  },
  '19372': {
    country: 'US',
    city: 'Thorndale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9984,
    longitude: -75.759,
    postal_code: '19372'
  },
  '19373': {
    country: 'US',
    city: 'Thornton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Delaware',
    latitude: 39.9041,
    longitude: -75.5313,
    postal_code: '19373'
  },
  '19374': {
    country: 'US',
    city: 'Toughkenamon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.8315,
    longitude: -75.7574,
    postal_code: '19374'
  },
  '19375': {
    country: 'US',
    city: 'Unionville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.8954,
    longitude: -75.7344,
    postal_code: '19375'
  },
  '19376': {
    country: 'US',
    city: 'Wagontown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9832,
    longitude: -75.7481,
    postal_code: '19376'
  },
  '19380': {
    country: 'US',
    city: 'West Chester',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9845,
    longitude: -75.5962,
    postal_code: '19380'
  },
  '19381': {
    country: 'US',
    city: 'West Chester',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.961,
    longitude: -75.608,
    postal_code: '19381'
  },
  '19382': {
    country: 'US',
    city: 'West Chester',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9441,
    longitude: -75.5882,
    postal_code: '19382'
  },
  '19383': {
    country: 'US',
    city: 'West Chester',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.961,
    longitude: -75.608,
    postal_code: '19383'
  },
  '19390': {
    country: 'US',
    city: 'West Grove',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.8253,
    longitude: -75.8374,
    postal_code: '19390'
  },
  '19395': {
    country: 'US',
    city: 'Westtown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9328,
    longitude: -75.5448,
    postal_code: '19395'
  },
  '19397': {
    country: 'US',
    city: 'Southeastern',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9832,
    longitude: -75.7481,
    postal_code: '19397'
  },
  '19398': {
    country: 'US',
    city: 'Southeastern',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9832,
    longitude: -75.7481,
    postal_code: '19398'
  },
  '19399': {
    country: 'US',
    city: 'Southeastern',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9832,
    longitude: -75.7481,
    postal_code: '19399'
  },
  '19401': {
    country: 'US',
    city: 'Norristown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1245,
    longitude: -75.3304,
    postal_code: '19401'
  },
  '19403': {
    country: 'US',
    city: 'Norristown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1496,
    longitude: -75.3796,
    postal_code: '19403'
  },
  '19404': {
    country: 'US',
    city: 'Norristown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2119,
    longitude: -75.3559,
    postal_code: '19404'
  },
  '19405': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.103,
    longitude: -75.3402,
    postal_code: '19405'
  },
  '19406': {
    country: 'US',
    city: 'King Of Prussia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.0956,
    longitude: -75.3737,
    postal_code: '19406'
  },
  '19407': {
    country: 'US',
    city: 'Audubon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2119,
    longitude: -75.3559,
    postal_code: '19407'
  },
  '19408': {
    country: 'US',
    city: 'Eagleville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1566,
    longitude: -75.4131,
    postal_code: '19408'
  },
  '19409': {
    country: 'US',
    city: 'Fairview Village',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1579,
    longitude: -75.3871,
    postal_code: '19409'
  },
  '19415': {
    country: 'US',
    city: 'Eagleville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1572,
    longitude: -75.406,
    postal_code: '19415'
  },
  '19421': {
    country: 'US',
    city: 'Birchrunville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 39.9832,
    longitude: -75.7481,
    postal_code: '19421'
  },
  '19422': {
    country: 'US',
    city: 'Blue Bell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1576,
    longitude: -75.2799,
    postal_code: '19422'
  },
  '19423': {
    country: 'US',
    city: 'Cedars',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2174,
    longitude: -75.365,
    postal_code: '19423'
  },
  '19424': {
    country: 'US',
    city: 'Blue Bell',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1523,
    longitude: -75.2663,
    postal_code: '19424'
  },
  '19425': {
    country: 'US',
    city: 'Chester Springs',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0978,
    longitude: -75.6398,
    postal_code: '19425'
  },
  '19426': {
    country: 'US',
    city: 'Collegeville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1913,
    longitude: -75.4373,
    postal_code: '19426'
  },
  '19428': {
    country: 'US',
    city: 'Conshohocken',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.0825,
    longitude: -75.3044,
    postal_code: '19428'
  },
  '19429': {
    country: 'US',
    city: 'Conshohocken',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.0793,
    longitude: -75.3016,
    postal_code: '19429'
  },
  '19430': {
    country: 'US',
    city: 'Creamery',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1851,
    longitude: -75.42,
    postal_code: '19430'
  },
  '19432': {
    country: 'US',
    city: 'Devault',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0796,
    longitude: -75.56,
    postal_code: '19432'
  },
  '19435': {
    country: 'US',
    city: 'Frederick',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3275,
    longitude: -75.5692,
    postal_code: '19435'
  },
  '19436': {
    country: 'US',
    city: 'Gwynedd',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2021,
    longitude: -75.2507,
    postal_code: '19436'
  },
  '19437': {
    country: 'US',
    city: 'Gwynedd Valley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1811,
    longitude: -75.2579,
    postal_code: '19437'
  },
  '19438': {
    country: 'US',
    city: 'Harleysville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2659,
    longitude: -75.3883,
    postal_code: '19438'
  },
  '19440': {
    country: 'US',
    city: 'Hatfield',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2778,
    longitude: -75.2975,
    postal_code: '19440'
  },
  '19441': {
    country: 'US',
    city: 'Harleysville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2795,
    longitude: -75.3871,
    postal_code: '19441'
  },
  '19442': {
    country: 'US',
    city: 'Kimberton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.1307,
    longitude: -75.5721,
    postal_code: '19442'
  },
  '19443': {
    country: 'US',
    city: 'Kulpsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.241,
    longitude: -75.3439,
    postal_code: '19443'
  },
  '19444': {
    country: 'US',
    city: 'Lafayette Hill',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.0896,
    longitude: -75.2601,
    postal_code: '19444'
  },
  '19446': {
    country: 'US',
    city: 'Lansdale',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2378,
    longitude: -75.2955,
    postal_code: '19446'
  },
  '19450': {
    country: 'US',
    city: 'Lederach',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2119,
    longitude: -75.3559,
    postal_code: '19450'
  },
  '19451': {
    country: 'US',
    city: 'Mainland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2119,
    longitude: -75.3559,
    postal_code: '19451'
  },
  '19453': {
    country: 'US',
    city: 'Mont Clare',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1364,
    longitude: -75.4999,
    postal_code: '19453'
  },
  '19454': {
    country: 'US',
    city: 'North Wales',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2166,
    longitude: -75.2565,
    postal_code: '19454'
  },
  '19455': {
    country: 'US',
    city: 'North Wales',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2109,
    longitude: -75.2782,
    postal_code: '19455'
  },
  '19456': {
    country: 'US',
    city: 'Oaks',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1318,
    longitude: -75.4596,
    postal_code: '19456'
  },
  '19457': {
    country: 'US',
    city: 'Parker Ford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.1995,
    longitude: -75.5838,
    postal_code: '19457'
  },
  '19460': {
    country: 'US',
    city: 'Phoenixville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.1267,
    longitude: -75.5272,
    postal_code: '19460'
  },
  '19462': {
    country: 'US',
    city: 'Plymouth Meeting',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1077,
    longitude: -75.2796,
    postal_code: '19462'
  },
  '19464': {
    country: 'US',
    city: 'Pottstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2635,
    longitude: -75.6172,
    postal_code: '19464'
  },
  '19465': {
    country: 'US',
    city: 'Pottstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.1919,
    longitude: -75.6653,
    postal_code: '19465'
  },
  '19468': {
    country: 'US',
    city: 'Royersford',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2075,
    longitude: -75.5329,
    postal_code: '19468'
  },
  '19470': {
    country: 'US',
    city: 'Saint Peters',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.1867,
    longitude: -75.7288,
    postal_code: '19470'
  },
  '19472': {
    country: 'US',
    city: 'Sassamansville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3448,
    longitude: -75.5775,
    postal_code: '19472'
  },
  '19473': {
    country: 'US',
    city: 'Schwenksville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2471,
    longitude: -75.4602,
    postal_code: '19473'
  },
  '19474': {
    country: 'US',
    city: 'Skippack',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2251,
    longitude: -75.4031,
    postal_code: '19474'
  },
  '19475': {
    country: 'US',
    city: 'Spring City',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.1765,
    longitude: -75.5697,
    postal_code: '19475'
  },
  '19477': {
    country: 'US',
    city: 'Spring House',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1839,
    longitude: -75.2327,
    postal_code: '19477'
  },
  '19478': {
    country: 'US',
    city: 'Spring Mount',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2759,
    longitude: -75.4618,
    postal_code: '19478'
  },
  '19480': {
    country: 'US',
    city: 'Uwchland',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.098,
    longitude: -75.688,
    postal_code: '19480'
  },
  '19481': {
    country: 'US',
    city: 'Valley Forge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0923,
    longitude: -75.4537,
    postal_code: '19481'
  },
  '19482': {
    country: 'US',
    city: 'Valley Forge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0803,
    longitude: -75.4554,
    postal_code: '19482'
  },
  '19484': {
    country: 'US',
    city: 'Valley Forge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2119,
    longitude: -75.3559,
    postal_code: '19484'
  },
  '19486': {
    country: 'US',
    city: 'West Point',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2033,
    longitude: -75.3019,
    postal_code: '19486'
  },
  '19490': {
    country: 'US',
    city: 'Worcester',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.1929,
    longitude: -75.3576,
    postal_code: '19490'
  },
  '19492': {
    country: 'US',
    city: 'Zieglerville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.2901,
    longitude: -75.503,
    postal_code: '19492'
  },
  '19493': {
    country: 'US',
    city: 'Valley Forge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0971,
    longitude: -75.4696,
    postal_code: '19493'
  },
  '19494': {
    country: 'US',
    city: 'Valley Forge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0971,
    longitude: -75.4696,
    postal_code: '19494'
  },
  '19495': {
    country: 'US',
    city: 'Valley Forge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0971,
    longitude: -75.4696,
    postal_code: '19495'
  },
  '19496': {
    country: 'US',
    city: 'Valley Forge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.0971,
    longitude: -75.4696,
    postal_code: '19496'
  },
  '19501': {
    country: 'US',
    city: 'Adamstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Lancaster',
    latitude: 40.2423,
    longitude: -76.0577,
    postal_code: '19501'
  },
  '19503': {
    country: 'US',
    city: 'Bally',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.405,
    longitude: -75.5769,
    postal_code: '19503'
  },
  '19504': {
    country: 'US',
    city: 'Barto',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3815,
    longitude: -75.5749,
    postal_code: '19504'
  },
  '19505': {
    country: 'US',
    city: 'Bechtelsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3795,
    longitude: -75.6257,
    postal_code: '19505'
  },
  '19506': {
    country: 'US',
    city: 'Bernville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4551,
    longitude: -76.1247,
    postal_code: '19506'
  },
  '19507': {
    country: 'US',
    city: 'Bethel',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4808,
    longitude: -76.2742,
    postal_code: '19507'
  },
  '19508': {
    country: 'US',
    city: 'Birdsboro',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.2563,
    longitude: -75.8344,
    postal_code: '19508'
  },
  '19510': {
    country: 'US',
    city: 'Blandon',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4435,
    longitude: -75.8837,
    postal_code: '19510'
  },
  '19511': {
    country: 'US',
    city: 'Bowers',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4821,
    longitude: -75.7435,
    postal_code: '19511'
  },
  '19512': {
    country: 'US',
    city: 'Boyertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3339,
    longitude: -75.6604,
    postal_code: '19512'
  },
  '19516': {
    country: 'US',
    city: 'Centerport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4858,
    longitude: -76.006,
    postal_code: '19516'
  },
  '19518': {
    country: 'US',
    city: 'Douglassville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.2709,
    longitude: -75.7397,
    postal_code: '19518'
  },
  '19519': {
    country: 'US',
    city: 'Earlville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.319,
    longitude: -75.7334,
    postal_code: '19519'
  },
  '19520': {
    country: 'US',
    city: 'Elverson',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Chester',
    latitude: 40.1568,
    longitude: -75.7866,
    postal_code: '19520'
  },
  '19522': {
    country: 'US',
    city: 'Fleetwood',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4473,
    longitude: -75.8185,
    postal_code: '19522'
  },
  '19523': {
    country: 'US',
    city: 'Geigertown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.2026,
    longitude: -75.8363,
    postal_code: '19523'
  },
  '19525': {
    country: 'US',
    city: 'Gilbertsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Montgomery',
    latitude: 40.3059,
    longitude: -75.5953,
    postal_code: '19525'
  },
  '19526': {
    country: 'US',
    city: 'Hamburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.5488,
    longitude: -75.9874,
    postal_code: '19526'
  },
  '19529': {
    country: 'US',
    city: 'Kempton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.6328,
    longitude: -75.8513,
    postal_code: '19529'
  },
  '19530': {
    country: 'US',
    city: 'Kutztown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.5214,
    longitude: -75.7774,
    postal_code: '19530'
  },
  '19533': {
    country: 'US',
    city: 'Leesport',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4152,
    longitude: -75.9944,
    postal_code: '19533'
  },
  '19534': {
    country: 'US',
    city: 'Lenhartsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.5736,
    longitude: -75.865,
    postal_code: '19534'
  },
  '19535': {
    country: 'US',
    city: 'Limekiln',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3356,
    longitude: -75.801,
    postal_code: '19535'
  },
  '19536': {
    country: 'US',
    city: 'Lyon Station',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.5218,
    longitude: -75.7419,
    postal_code: '19536'
  },
  '19538': {
    country: 'US',
    city: 'Maxatawny',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.5426,
    longitude: -75.6888,
    postal_code: '19538'
  },
  '19539': {
    country: 'US',
    city: 'Mertztown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4992,
    longitude: -75.6872,
    postal_code: '19539'
  },
  '19540': {
    country: 'US',
    city: 'Mohnton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.2584,
    longitude: -75.9833,
    postal_code: '19540'
  },
  '19541': {
    country: 'US',
    city: 'Mohrsville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4783,
    longitude: -76.0125,
    postal_code: '19541'
  },
  '19543': {
    country: 'US',
    city: 'Morgantown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.1552,
    longitude: -75.8998,
    postal_code: '19543'
  },
  '19544': {
    country: 'US',
    city: 'Mount Aetna',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4187,
    longitude: -76.2944,
    postal_code: '19544'
  },
  '19545': {
    country: 'US',
    city: 'New Berlinville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3397,
    longitude: -75.6302,
    postal_code: '19545'
  },
  '19547': {
    country: 'US',
    city: 'Oley',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3833,
    longitude: -75.7706,
    postal_code: '19547'
  },
  '19548': {
    country: 'US',
    city: 'Pine Forge',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.2829,
    longitude: -75.6907,
    postal_code: '19548'
  },
  '19549': {
    country: 'US',
    city: 'Port Clinton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Schuylkill',
    latitude: 40.5818,
    longitude: -76.0267,
    postal_code: '19549'
  },
  '19550': {
    country: 'US',
    city: 'Rehrersburg',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4553,
    longitude: -76.2453,
    postal_code: '19550'
  },
  '19551': {
    country: 'US',
    city: 'Robesonia',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3553,
    longitude: -76.1366,
    postal_code: '19551'
  },
  '19554': {
    country: 'US',
    city: 'Shartlesville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.5129,
    longitude: -76.1044,
    postal_code: '19554'
  },
  '19555': {
    country: 'US',
    city: 'Shoemakersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4955,
    longitude: -75.9603,
    postal_code: '19555'
  },
  '19559': {
    country: 'US',
    city: 'Strausstown',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4945,
    longitude: -76.1825,
    postal_code: '19559'
  },
  '19560': {
    country: 'US',
    city: 'Temple',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4025,
    longitude: -75.9046,
    postal_code: '19560'
  },
  '19562': {
    country: 'US',
    city: 'Topton',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.5029,
    longitude: -75.7015,
    postal_code: '19562'
  },
  '19564': {
    country: 'US',
    city: 'Virginville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.524,
    longitude: -75.873,
    postal_code: '19564'
  },
  '19565': {
    country: 'US',
    city: 'Wernersville',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3293,
    longitude: -76.0901,
    postal_code: '19565'
  },
  '19567': {
    country: 'US',
    city: 'Womelsdorf',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3743,
    longitude: -76.1985,
    postal_code: '19567'
  },
  '19601': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3466,
    longitude: -75.9351,
    postal_code: '19601'
  },
  '19602': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3306,
    longitude: -75.9192,
    postal_code: '19602'
  },
  '19603': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3884,
    longitude: -75.9631,
    postal_code: '19603'
  },
  '19604': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3507,
    longitude: -75.9143,
    postal_code: '19604'
  },
  '19605': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3886,
    longitude: -75.9328,
    postal_code: '19605'
  },
  '19606': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3351,
    longitude: -75.875,
    postal_code: '19606'
  },
  '19607': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.2995,
    longitude: -75.9876,
    postal_code: '19607'
  },
  '19608': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.3113,
    longitude: -76.0345,
    postal_code: '19608'
  },
  '19609': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.328,
    longitude: -75.9908,
    postal_code: '19609'
  },
  '19610': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.338,
    longitude: -75.978,
    postal_code: '19610'
  },
  '19611': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.325,
    longitude: -75.9442,
    postal_code: '19611'
  },
  '19612': {
    country: 'US',
    city: 'Reading',
    state: 'Pennsylvania',
    state_short: 'PA',
    county: 'Berks',
    latitude: 40.4389,
    longitude: -75.8853,
    postal_code: '19612'
  },
  '19701': {
    country: 'US',
    city: 'Bear',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.6102,
    longitude: -75.6747,
    postal_code: '19701'
  },
  '19702': {
    country: 'US',
    city: 'Newark',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.6349,
    longitude: -75.6993,
    postal_code: '19702'
  },
  '19703': {
    country: 'US',
    city: 'Claymont',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.8044,
    longitude: -75.4649,
    postal_code: '19703'
  },
  '19706': {
    country: 'US',
    city: 'Delaware City',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5725,
    longitude: -75.5957,
    postal_code: '19706'
  },
  '19707': {
    country: 'US',
    city: 'Hockessin',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.776,
    longitude: -75.6889,
    postal_code: '19707'
  },
  '19708': {
    country: 'US',
    city: 'Kirkwood',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5776,
    longitude: -75.6901,
    postal_code: '19708'
  },
  '19709': {
    country: 'US',
    city: 'Middletown',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.4815,
    longitude: -75.6832,
    postal_code: '19709'
  },
  '19710': {
    country: 'US',
    city: 'Montchanin',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7898,
    longitude: -75.5891,
    postal_code: '19710'
  },
  '19711': {
    country: 'US',
    city: 'Newark',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7011,
    longitude: -75.7375,
    postal_code: '19711'
  },
  '19712': {
    country: 'US',
    city: 'Newark',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19712'
  },
  '19713': {
    country: 'US',
    city: 'Newark',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.6699,
    longitude: -75.7151,
    postal_code: '19713'
  },
  '19714': {
    country: 'US',
    city: 'Newark',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19714'
  },
  '19715': {
    country: 'US',
    city: 'Newark',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19715'
  },
  '19716': {
    country: 'US',
    city: 'Newark',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.6896,
    longitude: -75.7584,
    postal_code: '19716'
  },
  '19717': {
    country: 'US',
    city: 'Newark',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.6837,
    longitude: -75.7497,
    postal_code: '19717'
  },
  '19718': {
    country: 'US',
    city: 'Newark',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19718'
  },
  '19720': {
    country: 'US',
    city: 'New Castle',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5929,
    longitude: -75.6515,
    postal_code: '19720'
  },
  '19721': {
    country: 'US',
    city: 'New Castle',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19721'
  },
  '19725': {
    country: 'US',
    city: 'Newark',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19725'
  },
  '19726': {
    country: 'US',
    city: 'New Castle',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.6621,
    longitude: -75.5663,
    postal_code: '19726'
  },
  '19730': {
    country: 'US',
    city: 'Odessa',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.4592,
    longitude: -75.6536,
    postal_code: '19730'
  },
  '19731': {
    country: 'US',
    city: 'Port Penn',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5129,
    longitude: -75.5852,
    postal_code: '19731'
  },
  '19732': {
    country: 'US',
    city: 'Rockland',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7939,
    longitude: -75.5738,
    postal_code: '19732'
  },
  '19733': {
    country: 'US',
    city: 'Saint Georges',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5555,
    longitude: -75.6505,
    postal_code: '19733'
  },
  '19734': {
    country: 'US',
    city: 'Townsend',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.3819,
    longitude: -75.6834,
    postal_code: '19734'
  },
  '19735': {
    country: 'US',
    city: 'Winterthur',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.8029,
    longitude: -75.5916,
    postal_code: '19735'
  },
  '19736': {
    country: 'US',
    city: 'Yorklyn',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.8082,
    longitude: -75.6755,
    postal_code: '19736'
  },
  '19801': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7378,
    longitude: -75.5497,
    postal_code: '19801'
  },
  '19802': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7564,
    longitude: -75.534,
    postal_code: '19802'
  },
  '19803': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7994,
    longitude: -75.5317,
    postal_code: '19803'
  },
  '19804': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7168,
    longitude: -75.6184,
    postal_code: '19804'
  },
  '19805': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7434,
    longitude: -75.5827,
    postal_code: '19805'
  },
  '19806': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7571,
    longitude: -75.5635,
    postal_code: '19806'
  },
  '19807': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7949,
    longitude: -75.6161,
    postal_code: '19807'
  },
  '19808': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7359,
    longitude: -75.6647,
    postal_code: '19808'
  },
  '19809': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7647,
    longitude: -75.5069,
    postal_code: '19809'
  },
  '19810': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.8188,
    longitude: -75.5064,
    postal_code: '19810'
  },
  '19850': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19850'
  },
  '19880': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19880'
  },
  '19884': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7459,
    longitude: -75.5466,
    postal_code: '19884'
  },
  '19885': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7459,
    longitude: -75.5466,
    postal_code: '19885'
  },
  '19886': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7459,
    longitude: -75.5466,
    postal_code: '19886'
  },
  '19890': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19890'
  },
  '19891': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19891'
  },
  '19892': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19892'
  },
  '19893': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7459,
    longitude: -75.5466,
    postal_code: '19893'
  },
  '19894': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7459,
    longitude: -75.5466,
    postal_code: '19894'
  },
  '19895': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7459,
    longitude: -75.5466,
    postal_code: '19895'
  },
  '19896': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19896'
  },
  '19897': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.5645,
    longitude: -75.597,
    postal_code: '19897'
  },
  '19898': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7459,
    longitude: -75.5466,
    postal_code: '19898'
  },
  '19899': {
    country: 'US',
    city: 'Wilmington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'New Castle',
    latitude: 39.7459,
    longitude: -75.5466,
    postal_code: '19899'
  },
  '19901': {
    country: 'US',
    city: 'Dover',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.1564,
    longitude: -75.4955,
    postal_code: '19901'
  },
  '19902': {
    country: 'US',
    city: 'Dover Afb',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.1253,
    longitude: -75.4818,
    postal_code: '19902'
  },
  '19903': {
    country: 'US',
    city: 'Dover',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.1087,
    longitude: -75.448,
    postal_code: '19903'
  },
  '19904': {
    country: 'US',
    city: 'Dover',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.1605,
    longitude: -75.5974,
    postal_code: '19904'
  },
  '19905': {
    country: 'US',
    city: 'Dover',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.1582,
    longitude: -75.5244,
    postal_code: '19905'
  },
  '19906': {
    country: 'US',
    city: 'Dover',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.157,
    longitude: -75.5294,
    postal_code: '19906'
  },
  '19930': {
    country: 'US',
    city: 'Bethany Beach',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.531,
    longitude: -75.0674,
    postal_code: '19930'
  },
  '19931': {
    country: 'US',
    city: 'Bethel',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.5685,
    longitude: -75.6243,
    postal_code: '19931'
  },
  '19933': {
    country: 'US',
    city: 'Bridgeville',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.7366,
    longitude: -75.6088,
    postal_code: '19933'
  },
  '19934': {
    country: 'US',
    city: 'Camden Wyoming',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.0991,
    longitude: -75.5966,
    postal_code: '19934'
  },
  '19936': {
    country: 'US',
    city: 'Cheswold',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.2184,
    longitude: -75.5848,
    postal_code: '19936'
  },
  '19938': {
    country: 'US',
    city: 'Clayton',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.2564,
    longitude: -75.6904,
    postal_code: '19938'
  },
  '19939': {
    country: 'US',
    city: 'Dagsboro',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.5596,
    longitude: -75.2113,
    postal_code: '19939'
  },
  '19940': {
    country: 'US',
    city: 'Delmar',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.477,
    longitude: -75.5759,
    postal_code: '19940'
  },
  '19941': {
    country: 'US',
    city: 'Ellendale',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.8057,
    longitude: -75.4056,
    postal_code: '19941'
  },
  '19943': {
    country: 'US',
    city: 'Felton',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.0225,
    longitude: -75.5829,
    postal_code: '19943'
  },
  '19944': {
    country: 'US',
    city: 'Fenwick Island',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.4865,
    longitude: -75.0589,
    postal_code: '19944'
  },
  '19945': {
    country: 'US',
    city: 'Frankford',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.5177,
    longitude: -75.2006,
    postal_code: '19945'
  },
  '19946': {
    country: 'US',
    city: 'Frederica',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.0342,
    longitude: -75.4545,
    postal_code: '19946'
  },
  '19947': {
    country: 'US',
    city: 'Georgetown',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.679,
    longitude: -75.3932,
    postal_code: '19947'
  },
  '19950': {
    country: 'US',
    city: 'Greenwood',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.8175,
    longitude: -75.5935,
    postal_code: '19950'
  },
  '19951': {
    country: 'US',
    city: 'Harbeson',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.6723,
    longitude: -75.2236,
    postal_code: '19951'
  },
  '19952': {
    country: 'US',
    city: 'Harrington',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 38.924,
    longitude: -75.5843,
    postal_code: '19952'
  },
  '19953': {
    country: 'US',
    city: 'Hartly',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.1542,
    longitude: -75.6935,
    postal_code: '19953'
  },
  '19954': {
    country: 'US',
    city: 'Houston',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 38.9114,
    longitude: -75.5064,
    postal_code: '19954'
  },
  '19955': {
    country: 'US',
    city: 'Kenton',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.2256,
    longitude: -75.6642,
    postal_code: '19955'
  },
  '19956': {
    country: 'US',
    city: 'Laurel',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.5537,
    longitude: -75.5631,
    postal_code: '19956'
  },
  '19958': {
    country: 'US',
    city: 'Lewes',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.7381,
    longitude: -75.1747,
    postal_code: '19958'
  },
  '19960': {
    country: 'US',
    city: 'Lincoln',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.8613,
    longitude: -75.4,
    postal_code: '19960'
  },
  '19961': {
    country: 'US',
    city: 'Little Creek',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.1663,
    longitude: -75.4483,
    postal_code: '19961'
  },
  '19962': {
    country: 'US',
    city: 'Magnolia',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.0735,
    longitude: -75.5083,
    postal_code: '19962'
  },
  '19963': {
    country: 'US',
    city: 'Milford',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.9218,
    longitude: -75.4299,
    postal_code: '19963'
  },
  '19964': {
    country: 'US',
    city: 'Marydel',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.0998,
    longitude: -75.7287,
    postal_code: '19964'
  },
  '19966': {
    country: 'US',
    city: 'Millsboro',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.6595,
    longitude: -75.2464,
    postal_code: '19966'
  },
  '19967': {
    country: 'US',
    city: 'Millville',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.5496,
    longitude: -75.1232,
    postal_code: '19967'
  },
  '19968': {
    country: 'US',
    city: 'Milton',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.7687,
    longitude: -75.2953,
    postal_code: '19968'
  },
  '19969': {
    country: 'US',
    city: 'Nassau',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.7521,
    longitude: -75.1877,
    postal_code: '19969'
  },
  '19970': {
    country: 'US',
    city: 'Ocean View',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.5617,
    longitude: -75.0966,
    postal_code: '19970'
  },
  '19971': {
    country: 'US',
    city: 'Rehoboth Beach',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.7209,
    longitude: -75.076,
    postal_code: '19971'
  },
  '19973': {
    country: 'US',
    city: 'Seaford',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.6404,
    longitude: -75.6041,
    postal_code: '19973'
  },
  '19975': {
    country: 'US',
    city: 'Selbyville',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Sussex',
    latitude: 38.4654,
    longitude: -75.1573,
    postal_code: '19975'
  },
  '19977': {
    country: 'US',
    city: 'Smyrna',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.2934,
    longitude: -75.6008,
    postal_code: '19977'
  },
  '19979': {
    country: 'US',
    city: 'Viola',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.0419,
    longitude: -75.5726,
    postal_code: '19979'
  },
  '19980': {
    country: 'US',
    city: 'Woodside',
    state: 'Delaware',
    state_short: 'DE',
    county: 'Kent',
    latitude: 39.0724,
    longitude: -75.5711,
    postal_code: '19980'
  },
  '20001': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9122,
    longitude: -77.0177,
    postal_code: '20001'
  },
  '20002': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9024,
    longitude: -76.9901,
    postal_code: '20002'
  },
  '20003': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8829,
    longitude: -76.9895,
    postal_code: '20003'
  },
  '20004': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.893,
    longitude: -77.0263,
    postal_code: '20004'
  },
  '20005': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9067,
    longitude: -77.0312,
    postal_code: '20005'
  },
  '20006': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8964,
    longitude: -77.0447,
    postal_code: '20006'
  },
  '20007': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9144,
    longitude: -77.074,
    postal_code: '20007'
  },
  '20008': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9363,
    longitude: -77.0599,
    postal_code: '20008'
  },
  '20009': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9202,
    longitude: -77.0375,
    postal_code: '20009'
  },
  '20010': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9327,
    longitude: -77.0322,
    postal_code: '20010'
  },
  '20011': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9518,
    longitude: -77.0203,
    postal_code: '20011'
  },
  '20012': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9757,
    longitude: -77.0282,
    postal_code: '20012'
  },
  '20013': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20013'
  },
  '20015': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9658,
    longitude: -77.068,
    postal_code: '20015'
  },
  '20016': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9381,
    longitude: -77.086,
    postal_code: '20016'
  },
  '20017': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9367,
    longitude: -76.994,
    postal_code: '20017'
  },
  '20018': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9277,
    longitude: -76.9762,
    postal_code: '20018'
  },
  '20019': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8902,
    longitude: -76.9376,
    postal_code: '20019'
  },
  '20020': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.86,
    longitude: -76.9742,
    postal_code: '20020'
  },
  '20022': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.945,
    longitude: -77.0364,
    postal_code: '20022'
  },
  '20024': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8759,
    longitude: -77.016,
    postal_code: '20024'
  },
  '20026': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20026'
  },
  '20027': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9007,
    longitude: -77.0501,
    postal_code: '20027'
  },
  '20029': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20029'
  },
  '20030': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20030'
  },
  '20032': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8338,
    longitude: -76.9995,
    postal_code: '20032'
  },
  '20033': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20033'
  },
  '20035': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20035'
  },
  '20036': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9087,
    longitude: -77.0414,
    postal_code: '20036'
  },
  '20037': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9014,
    longitude: -77.0504,
    postal_code: '20037'
  },
  '20038': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20038'
  },
  '20039': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20039'
  },
  '20040': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20040'
  },
  '20041': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20041'
  },
  '20042': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20042'
  },
  '20043': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20043'
  },
  '20044': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20044'
  },
  '20045': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8966,
    longitude: -77.0319,
    postal_code: '20045'
  },
  '20047': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20047'
  },
  '20049': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8959,
    longitude: -77.021,
    postal_code: '20049'
  },
  '20050': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20050'
  },
  '20052': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9001,
    longitude: -77.0479,
    postal_code: '20052'
  },
  '20053': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20053'
  },
  '20055': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9016,
    longitude: -77.021,
    postal_code: '20055'
  },
  '20056': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20056'
  },
  '20057': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20057'
  },
  '20058': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20058'
  },
  '20059': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20059'
  },
  '20060': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.918,
    longitude: -77.0204,
    postal_code: '20060'
  },
  '20061': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20061'
  },
  '20062': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9,
    longitude: -77.0369,
    postal_code: '20062'
  },
  '20063': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9053,
    longitude: -77.0466,
    postal_code: '20063'
  },
  '20064': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9332,
    longitude: -76.9963,
    postal_code: '20064'
  },
  '20065': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8834,
    longitude: -77.0282,
    postal_code: '20065'
  },
  '20066': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20066'
  },
  '20067': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20067'
  },
  '20068': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20068'
  },
  '20069': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20069'
  },
  '20070': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20070'
  },
  '20071': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20071'
  },
  '20073': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.897,
    longitude: -77.0251,
    postal_code: '20073'
  },
  '20074': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20074'
  },
  '20075': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20075'
  },
  '20076': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20076'
  },
  '20077': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20077'
  },
  '20078': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20078'
  },
  '20080': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20080'
  },
  '20081': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20081'
  },
  '20082': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20082'
  },
  '20090': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20090'
  },
  '20091': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20091'
  },
  '20101': {
    country: 'US',
    city: 'Dulles',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0021,
    longitude: -77.4421,
    postal_code: '20101'
  },
  '20102': {
    country: 'US',
    city: 'Dulles',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0853,
    longitude: -77.6452,
    postal_code: '20102'
  },
  '20103': {
    country: 'US',
    city: 'Dulles',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 38.9962,
    longitude: -77.45,
    postal_code: '20103'
  },
  '20104': {
    country: 'US',
    city: 'Dulles',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0853,
    longitude: -77.6452,
    postal_code: '20104'
  },
  '20105': {
    country: 'US',
    city: 'Aldie',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 38.9577,
    longitude: -77.6038,
    postal_code: '20105'
  },
  '20106': {
    country: 'US',
    city: 'Amissville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rappahannock',
    latitude: 38.6842,
    longitude: -78.0168,
    postal_code: '20106'
  },
  '20108': {
    country: 'US',
    city: 'Manassas',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Manassas (city)',
    latitude: 38.7447,
    longitude: -77.4872,
    postal_code: '20108'
  },
  '20109': {
    country: 'US',
    city: 'Manassas',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Manassas (city)',
    latitude: 38.793,
    longitude: -77.5266,
    postal_code: '20109'
  },
  '20110': {
    country: 'US',
    city: 'Manassas',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Manassas (city)',
    latitude: 38.7492,
    longitude: -77.4878,
    postal_code: '20110'
  },
  '20111': {
    country: 'US',
    city: 'Manassas',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Manassas Park (city)',
    latitude: 38.7707,
    longitude: -77.4494,
    postal_code: '20111'
  },
  '20112': {
    country: 'US',
    city: 'Manassas',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Manassas Park (city)',
    latitude: 38.6665,
    longitude: -77.4248,
    postal_code: '20112'
  },
  '20113': {
    country: 'US',
    city: 'Manassas',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Manassas Park (city)',
    latitude: 38.7709,
    longitude: -77.4494,
    postal_code: '20113'
  },
  '20115': {
    country: 'US',
    city: 'Marshall',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.8405,
    longitude: -77.8911,
    postal_code: '20115'
  },
  '20116': {
    country: 'US',
    city: 'Marshall',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.8537,
    longitude: -77.8601,
    postal_code: '20116'
  },
  '20117': {
    country: 'US',
    city: 'Middleburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 38.9687,
    longitude: -77.7355,
    postal_code: '20117'
  },
  '20118': {
    country: 'US',
    city: 'Middleburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 38.9687,
    longitude: -77.7355,
    postal_code: '20118'
  },
  '20119': {
    country: 'US',
    city: 'Catlett',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.637,
    longitude: -77.6383,
    postal_code: '20119'
  },
  '20120': {
    country: 'US',
    city: 'Centreville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8448,
    longitude: -77.467,
    postal_code: '20120'
  },
  '20121': {
    country: 'US',
    city: 'Centreville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8195,
    longitude: -77.4558,
    postal_code: '20121'
  },
  '20122': {
    country: 'US',
    city: 'Centreville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '20122'
  },
  '20124': {
    country: 'US',
    city: 'Clifton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7818,
    longitude: -77.3818,
    postal_code: '20124'
  },
  '20128': {
    country: 'US',
    city: 'Orlean',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.7416,
    longitude: -77.9774,
    postal_code: '20128'
  },
  '20129': {
    country: 'US',
    city: 'Paeonian Springs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1603,
    longitude: -77.6097,
    postal_code: '20129'
  },
  '20130': {
    country: 'US',
    city: 'Paris',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 39.0048,
    longitude: -77.9546,
    postal_code: '20130'
  },
  '20131': {
    country: 'US',
    city: 'Philomont',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.058,
    longitude: -77.7434,
    postal_code: '20131'
  },
  '20132': {
    country: 'US',
    city: 'Purcellville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1436,
    longitude: -77.7342,
    postal_code: '20132'
  },
  '20134': {
    country: 'US',
    city: 'Purcellville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1522,
    longitude: -77.7026,
    postal_code: '20134'
  },
  '20135': {
    country: 'US',
    city: 'Bluemont',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0823,
    longitude: -77.8467,
    postal_code: '20135'
  },
  '20136': {
    country: 'US',
    city: 'Bristow',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.7343,
    longitude: -77.5474,
    postal_code: '20136'
  },
  '20137': {
    country: 'US',
    city: 'Broad Run',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.8237,
    longitude: -77.713,
    postal_code: '20137'
  },
  '20138': {
    country: 'US',
    city: 'Calverton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.6338,
    longitude: -77.6869,
    postal_code: '20138'
  },
  '20139': {
    country: 'US',
    city: 'Casanova',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.6543,
    longitude: -77.7025,
    postal_code: '20139'
  },
  '20140': {
    country: 'US',
    city: 'Rectortown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.9163,
    longitude: -77.8648,
    postal_code: '20140'
  },
  '20141': {
    country: 'US',
    city: 'Round Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1164,
    longitude: -77.7802,
    postal_code: '20141'
  },
  '20142': {
    country: 'US',
    city: 'Round Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1307,
    longitude: -77.7747,
    postal_code: '20142'
  },
  '20143': {
    country: 'US',
    city: 'Catharpin',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.8543,
    longitude: -77.5719,
    postal_code: '20143'
  },
  '20144': {
    country: 'US',
    city: 'Delaplane',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.9113,
    longitude: -77.9674,
    postal_code: '20144'
  },
  '20146': {
    country: 'US',
    city: 'Ashburn',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0853,
    longitude: -77.6452,
    postal_code: '20146'
  },
  '20147': {
    country: 'US',
    city: 'Ashburn',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0373,
    longitude: -77.4805,
    postal_code: '20147'
  },
  '20148': {
    country: 'US',
    city: 'Ashburn',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0142,
    longitude: -77.5285,
    postal_code: '20148'
  },
  '20149': {
    country: 'US',
    city: 'Ashburn',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0437,
    longitude: -77.4875,
    postal_code: '20149'
  },
  '20151': {
    country: 'US',
    city: 'Chantilly',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8867,
    longitude: -77.4457,
    postal_code: '20151'
  },
  '20152': {
    country: 'US',
    city: 'Chantilly',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 38.8976,
    longitude: -77.5092,
    postal_code: '20152'
  },
  '20153': {
    country: 'US',
    city: 'Chantilly',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '20153'
  },
  '20155': {
    country: 'US',
    city: 'Gainesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.8157,
    longitude: -77.6216,
    postal_code: '20155'
  },
  '20156': {
    country: 'US',
    city: 'Gainesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.7219,
    longitude: -77.4669,
    postal_code: '20156'
  },
  '20158': {
    country: 'US',
    city: 'Hamilton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1383,
    longitude: -77.6573,
    postal_code: '20158'
  },
  '20159': {
    country: 'US',
    city: 'Hamilton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1339,
    longitude: -77.6621,
    postal_code: '20159'
  },
  '20160': {
    country: 'US',
    city: 'Lincoln',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0985,
    longitude: -77.6883,
    postal_code: '20160'
  },
  '20163': {
    country: 'US',
    city: 'Sterling',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0062,
    longitude: -77.4286,
    postal_code: '20163'
  },
  '20164': {
    country: 'US',
    city: 'Sterling',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.023,
    longitude: -77.3994,
    postal_code: '20164'
  },
  '20165': {
    country: 'US',
    city: 'Sterling',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0472,
    longitude: -77.3866,
    postal_code: '20165'
  },
  '20166': {
    country: 'US',
    city: 'Sterling',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 38.9814,
    longitude: -77.4723,
    postal_code: '20166'
  },
  '20167': {
    country: 'US',
    city: 'Sterling',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.0062,
    longitude: -77.4286,
    postal_code: '20167'
  },
  '20168': {
    country: 'US',
    city: 'Haymarket',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.8121,
    longitude: -77.6364,
    postal_code: '20168'
  },
  '20169': {
    country: 'US',
    city: 'Haymarket',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.8674,
    longitude: -77.6445,
    postal_code: '20169'
  },
  '20170': {
    country: 'US',
    city: 'Herndon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9839,
    longitude: -77.3675,
    postal_code: '20170'
  },
  '20171': {
    country: 'US',
    city: 'Herndon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9252,
    longitude: -77.3928,
    postal_code: '20171'
  },
  '20172': {
    country: 'US',
    city: 'Herndon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9696,
    longitude: -77.3861,
    postal_code: '20172'
  },
  '20175': {
    country: 'US',
    city: 'Leesburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.042,
    longitude: -77.6054,
    postal_code: '20175'
  },
  '20176': {
    country: 'US',
    city: 'Leesburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1821,
    longitude: -77.5359,
    postal_code: '20176'
  },
  '20177': {
    country: 'US',
    city: 'Leesburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1157,
    longitude: -77.5636,
    postal_code: '20177'
  },
  '20178': {
    country: 'US',
    city: 'Leesburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1157,
    longitude: -77.5636,
    postal_code: '20178'
  },
  '20180': {
    country: 'US',
    city: 'Lovettsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.2204,
    longitude: -77.6596,
    postal_code: '20180'
  },
  '20181': {
    country: 'US',
    city: 'Nokesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.7,
    longitude: -77.5483,
    postal_code: '20181'
  },
  '20182': {
    country: 'US',
    city: 'Nokesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.7009,
    longitude: -77.5857,
    postal_code: '20182'
  },
  '20184': {
    country: 'US',
    city: 'Upperville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.9627,
    longitude: -77.8847,
    postal_code: '20184'
  },
  '20185': {
    country: 'US',
    city: 'Upperville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.993,
    longitude: -77.8799,
    postal_code: '20185'
  },
  '20186': {
    country: 'US',
    city: 'Warrenton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.6898,
    longitude: -77.8361,
    postal_code: '20186'
  },
  '20187': {
    country: 'US',
    city: 'Warrenton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.7153,
    longitude: -77.7417,
    postal_code: '20187'
  },
  '20188': {
    country: 'US',
    city: 'Warrenton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.7656,
    longitude: -77.8203,
    postal_code: '20188'
  },
  '20189': {
    country: 'US',
    city: 'Dulles',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.009,
    longitude: -77.4421,
    postal_code: '20189'
  },
  '20190': {
    country: 'US',
    city: 'Reston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9615,
    longitude: -77.3418,
    postal_code: '20190'
  },
  '20191': {
    country: 'US',
    city: 'Reston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9318,
    longitude: -77.3527,
    postal_code: '20191'
  },
  '20192': {
    country: 'US',
    city: 'Herndon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '20192'
  },
  '20194': {
    country: 'US',
    city: 'Reston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9807,
    longitude: -77.3419,
    postal_code: '20194'
  },
  '20195': {
    country: 'US',
    city: 'Reston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '20195'
  },
  '20196': {
    country: 'US',
    city: 'Reston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '20196'
  },
  '20197': {
    country: 'US',
    city: 'Waterford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 39.1882,
    longitude: -77.63,
    postal_code: '20197'
  },
  '20198': {
    country: 'US',
    city: 'The Plains',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.8707,
    longitude: -77.7608,
    postal_code: '20198'
  },
  '20201': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20201'
  },
  '20202': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20202'
  },
  '20203': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9053,
    longitude: -77.0466,
    postal_code: '20203'
  },
  '20204': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20204'
  },
  '20206': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20206'
  },
  '20207': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20207'
  },
  '20208': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8966,
    longitude: -77.0117,
    postal_code: '20208'
  },
  '20210': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20210'
  },
  '20211': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20211'
  },
  '20212': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20212'
  },
  '20213': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20213'
  },
  '20214': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20214'
  },
  '20215': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20215'
  },
  '20216': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8919,
    longitude: -77.0141,
    postal_code: '20216'
  },
  '20217': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20217'
  },
  '20218': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20218'
  },
  '20219': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20219'
  },
  '20220': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20220'
  },
  '20221': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20221'
  },
  '20222': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20222'
  },
  '20223': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20223'
  },
  '20224': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20224'
  },
  '20226': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20226'
  },
  '20227': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20227'
  },
  '20228': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20228'
  },
  '20229': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20229'
  },
  '20230': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20230'
  },
  '20232': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9006,
    longitude: -77.0391,
    postal_code: '20232'
  },
  '20233': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20233'
  },
  '20235': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9154,
    longitude: -77.0572,
    postal_code: '20235'
  },
  '20237': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.895,
    longitude: -77.0367,
    postal_code: '20237'
  },
  '20238': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20238'
  },
  '20239': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20239'
  },
  '20240': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8971,
    longitude: -77.0409,
    postal_code: '20240'
  },
  '20241': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20241'
  },
  '20242': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8678,
    longitude: -77.0289,
    postal_code: '20242'
  },
  '20244': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20244'
  },
  '20245': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20245'
  },
  '20250': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8873,
    longitude: -77.0327,
    postal_code: '20250'
  },
  '20251': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20251'
  },
  '20252': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9584,
    longitude: -77.0519,
    postal_code: '20252'
  },
  '20254': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20254'
  },
  '20260': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20260'
  },
  '20261': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20261'
  },
  '20262': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20262'
  },
  '20265': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20265'
  },
  '20266': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20266'
  },
  '20268': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20268'
  },
  '20270': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20270'
  },
  '20277': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20277'
  },
  '20289': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20289'
  },
  '20299': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20299'
  },
  '20301': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8894,
    longitude: -77.0311,
    postal_code: '20301'
  },
  '20303': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20303'
  },
  '20306': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20306'
  },
  '20310': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20310'
  },
  '20314': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20314'
  },
  '20317': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9363,
    longitude: -77.0123,
    postal_code: '20317'
  },
  '20318': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20318'
  },
  '20319': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8667,
    longitude: -77.0166,
    postal_code: '20319'
  },
  '20330': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20330'
  },
  '20340': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20340'
  },
  '20350': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20350'
  },
  '20355': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0369,
    postal_code: '20355'
  },
  '20370': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20370'
  },
  '20372': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20372'
  },
  '20373': {
    country: 'US',
    city: 'Naval Anacost Annex',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20373'
  },
  '20374': {
    country: 'US',
    city: 'Washington Navy Yard',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20374'
  },
  '20375': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8262,
    longitude: -77.0174,
    postal_code: '20375'
  },
  '20376': {
    country: 'US',
    city: 'Washington Navy Yard',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9164,
    longitude: -76.9947,
    postal_code: '20376'
  },
  '20380': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20380'
  },
  '20388': {
    country: 'US',
    city: 'Washington Navy Yard',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20388'
  },
  '20389': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20389'
  },
  '20390': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20390'
  },
  '20391': {
    country: 'US',
    city: 'Washington Navy Yard',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20391'
  },
  '20392': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20392'
  },
  '20393': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20393'
  },
  '20394': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20394'
  },
  '20395': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20395'
  },
  '20398': {
    country: 'US',
    city: 'Washington Navy Yard',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20398'
  },
  '20401': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20401'
  },
  '20402': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20402'
  },
  '20403': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20403'
  },
  '20404': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8992,
    longitude: -77.0089,
    postal_code: '20404'
  },
  '20405': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20405'
  },
  '20406': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20406'
  },
  '20407': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20407'
  },
  '20408': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20408'
  },
  '20409': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20409'
  },
  '20410': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20410'
  },
  '20411': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.884,
    longitude: -77.0221,
    postal_code: '20411'
  },
  '20412': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8953,
    longitude: -77.0221,
    postal_code: '20412'
  },
  '20413': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20413'
  },
  '20414': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.884,
    longitude: -77.0221,
    postal_code: '20414'
  },
  '20415': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20415'
  },
  '20416': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20416'
  },
  '20417': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.907,
    longitude: -77.0058,
    postal_code: '20417'
  },
  '20418': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9043,
    longitude: -77.0572,
    postal_code: '20418'
  },
  '20419': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20419'
  },
  '20420': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9035,
    longitude: -77.0276,
    postal_code: '20420'
  },
  '20421': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20421'
  },
  '20422': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20422'
  },
  '20423': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20423'
  },
  '20424': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20424'
  },
  '20425': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20425'
  },
  '20426': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20426'
  },
  '20427': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9021,
    longitude: -77.0476,
    postal_code: '20427'
  },
  '20428': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20428'
  },
  '20429': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20429'
  },
  '20431': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8986,
    longitude: -77.0428,
    postal_code: '20431'
  },
  '20433': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9,
    longitude: -77.042,
    postal_code: '20433'
  },
  '20434': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20434'
  },
  '20435': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8994,
    longitude: -77.0403,
    postal_code: '20435'
  },
  '20436': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8959,
    longitude: -77.0211,
    postal_code: '20436'
  },
  '20437': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9028,
    longitude: -77.0485,
    postal_code: '20437'
  },
  '20439': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20439'
  },
  '20440': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9139,
    longitude: -77.0453,
    postal_code: '20440'
  },
  '20441': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9239,
    longitude: -77.0363,
    postal_code: '20441'
  },
  '20442': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.896,
    longitude: -77.0177,
    postal_code: '20442'
  },
  '20444': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20444'
  },
  '20447': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8847,
    longitude: -77.0252,
    postal_code: '20447'
  },
  '20451': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8977,
    longitude: -77.0444,
    postal_code: '20451'
  },
  '20453': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20453'
  },
  '20456': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8981,
    longitude: -77.0401,
    postal_code: '20456'
  },
  '20460': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8764,
    longitude: -77.0188,
    postal_code: '20460'
  },
  '20463': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20463'
  },
  '20468': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20468'
  },
  '20469': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20469'
  },
  '20470': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20470'
  },
  '20472': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20472'
  },
  '20500': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8946,
    longitude: -77.0355,
    postal_code: '20500'
  },
  '20501': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8987,
    longitude: -77.0362,
    postal_code: '20501'
  },
  '20502': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8987,
    longitude: -77.0362,
    postal_code: '20502'
  },
  '20503': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9007,
    longitude: -77.0431,
    postal_code: '20503'
  },
  '20504': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20504'
  },
  '20505': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20505'
  },
  '20506': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8994,
    longitude: -77.0377,
    postal_code: '20506'
  },
  '20507': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20507'
  },
  '20508': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20508'
  },
  '20509': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8987,
    longitude: -77.0356,
    postal_code: '20509'
  },
  '20510': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20510'
  },
  '20511': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '20511'
  },
  '20515': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20515'
  },
  '20520': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8932,
    longitude: -77.049,
    postal_code: '20520'
  },
  '20521': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20521'
  },
  '20522': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8932,
    longitude: -77.049,
    postal_code: '20522'
  },
  '20523': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8945,
    longitude: -77.0478,
    postal_code: '20523'
  },
  '20524': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9024,
    longitude: -77.0326,
    postal_code: '20524'
  },
  '20525': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20525'
  },
  '20526': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9022,
    longitude: -77.0437,
    postal_code: '20526'
  },
  '20527': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9029,
    longitude: -77.0361,
    postal_code: '20527'
  },
  '20528': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0369,
    postal_code: '20528'
  },
  '20529': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8973,
    longitude: -77.0142,
    postal_code: '20529'
  },
  '20530': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8976,
    longitude: -77.027,
    postal_code: '20530'
  },
  '20531': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8938,
    longitude: -77.0218,
    postal_code: '20531'
  },
  '20533': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9011,
    longitude: -77.0326,
    postal_code: '20533'
  },
  '20534': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8941,
    longitude: -77.0125,
    postal_code: '20534'
  },
  '20535': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8941,
    longitude: -77.0251,
    postal_code: '20535'
  },
  '20536': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9012,
    longitude: -77.0169,
    postal_code: '20536'
  },
  '20537': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8941,
    longitude: -77.0251,
    postal_code: '20537'
  },
  '20538': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20538'
  },
  '20539': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20539'
  },
  '20540': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8874,
    longitude: -77.0047,
    postal_code: '20540'
  },
  '20541': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8874,
    longitude: -77.0047,
    postal_code: '20541'
  },
  '20542': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9408,
    longitude: -77.0283,
    postal_code: '20542'
  },
  '20543': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20543'
  },
  '20544': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20544'
  },
  '20546': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.891,
    longitude: -77.0211,
    postal_code: '20546'
  },
  '20547': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20547'
  },
  '20548': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8981,
    longitude: -77.0177,
    postal_code: '20548'
  },
  '20549': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20549'
  },
  '20551': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.892,
    longitude: -77.0452,
    postal_code: '20551'
  },
  '20552': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20552'
  },
  '20553': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8873,
    longitude: -77.0231,
    postal_code: '20553'
  },
  '20554': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20554'
  },
  '20555': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9,
    longitude: -77.0401,
    postal_code: '20555'
  },
  '20557': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8874,
    longitude: -77.0047,
    postal_code: '20557'
  },
  '20559': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8874,
    longitude: -77.0047,
    postal_code: '20559'
  },
  '20560': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20560'
  },
  '20565': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8919,
    longitude: -77.0189,
    postal_code: '20565'
  },
  '20566': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8971,
    longitude: -77.0554,
    postal_code: '20566'
  },
  '20570': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8991,
    longitude: -77.0401,
    postal_code: '20570'
  },
  '20571': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9006,
    longitude: -77.0346,
    postal_code: '20571'
  },
  '20572': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20572'
  },
  '20573': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20573'
  },
  '20575': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20575'
  },
  '20576': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8937,
    longitude: -77.0236,
    postal_code: '20576'
  },
  '20577': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9008,
    longitude: -77.0345,
    postal_code: '20577'
  },
  '20578': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20578'
  },
  '20579': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9043,
    longitude: -77.0446,
    postal_code: '20579'
  },
  '20580': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20580'
  },
  '20581': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20581'
  },
  '20585': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20585'
  },
  '20586': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.9022,
    longitude: -77.0474,
    postal_code: '20586'
  },
  '20588': {
    country: 'US',
    city: 'Dhs',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 38.7504,
    longitude: -76.8544,
    postal_code: '20588'
  },
  '20590': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.884,
    longitude: -77.0221,
    postal_code: '20590'
  },
  '20591': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20591'
  },
  '20593': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20593'
  },
  '20594': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8849,
    longitude: -77.0184,
    postal_code: '20594'
  },
  '20597': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20597'
  },
  '20598': {
    country: 'US',
    city: 'Dhs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Loudoun',
    latitude: 38.9904,
    longitude: -77.4477,
    postal_code: '20598'
  },
  '20599': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8933,
    longitude: -77.0146,
    postal_code: '20599'
  },
  '20601': {
    country: 'US',
    city: 'Waldorf',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.6371,
    longitude: -76.8778,
    postal_code: '20601'
  },
  '20602': {
    country: 'US',
    city: 'Waldorf',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.584,
    longitude: -76.8942,
    postal_code: '20602'
  },
  '20603': {
    country: 'US',
    city: 'Waldorf',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.6294,
    longitude: -76.9769,
    postal_code: '20603'
  },
  '20604': {
    country: 'US',
    city: 'Waldorf',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.5095,
    longitude: -76.9817,
    postal_code: '20604'
  },
  '20606': {
    country: 'US',
    city: 'Abell',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.2471,
    longitude: -76.7481,
    postal_code: '20606'
  },
  '20607': {
    country: 'US',
    city: 'Accokeek',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.672,
    longitude: -77.0162,
    postal_code: '20607'
  },
  '20608': {
    country: 'US',
    city: 'Aquasco',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.5825,
    longitude: -76.7149,
    postal_code: '20608'
  },
  '20609': {
    country: 'US',
    city: 'Avenue',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.2826,
    longitude: -76.7466,
    postal_code: '20609'
  },
  '20610': {
    country: 'US',
    city: 'Barstow',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.5254,
    longitude: -76.6161,
    postal_code: '20610'
  },
  '20611': {
    country: 'US',
    city: 'Bel Alton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.4731,
    longitude: -76.9789,
    postal_code: '20611'
  },
  '20612': {
    country: 'US',
    city: 'Benedict',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.5093,
    longitude: -76.6797,
    postal_code: '20612'
  },
  '20613': {
    country: 'US',
    city: 'Brandywine',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.6922,
    longitude: -76.832,
    postal_code: '20613'
  },
  '20615': {
    country: 'US',
    city: 'Broomes Island',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.418,
    longitude: -76.5478,
    postal_code: '20615'
  },
  '20616': {
    country: 'US',
    city: 'Bryans Road',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.6415,
    longitude: -77.0766,
    postal_code: '20616'
  },
  '20617': {
    country: 'US',
    city: 'Bryantown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.5426,
    longitude: -76.8465,
    postal_code: '20617'
  },
  '20618': {
    country: 'US',
    city: 'Bushwood',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.2844,
    longitude: -76.7929,
    postal_code: '20618'
  },
  '20619': {
    country: 'US',
    city: 'California',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.3006,
    longitude: -76.5312,
    postal_code: '20619'
  },
  '20620': {
    country: 'US',
    city: 'Callaway',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.2275,
    longitude: -76.521,
    postal_code: '20620'
  },
  '20621': {
    country: 'US',
    city: 'Chaptico',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.351,
    longitude: -76.7833,
    postal_code: '20621'
  },
  '20622': {
    country: 'US',
    city: 'Charlotte Hall',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.475,
    longitude: -76.8038,
    postal_code: '20622'
  },
  '20623': {
    country: 'US',
    city: 'Cheltenham',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.7531,
    longitude: -76.8369,
    postal_code: '20623'
  },
  '20624': {
    country: 'US',
    city: 'Clements',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.3407,
    longitude: -76.7264,
    postal_code: '20624'
  },
  '20625': {
    country: 'US',
    city: 'Cobb Island',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.262,
    longitude: -76.8502,
    postal_code: '20625'
  },
  '20626': {
    country: 'US',
    city: 'Coltons Point',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.237,
    longitude: -76.7646,
    postal_code: '20626'
  },
  '20627': {
    country: 'US',
    city: 'Compton',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.2768,
    longitude: -76.704,
    postal_code: '20627'
  },
  '20628': {
    country: 'US',
    city: 'Dameron',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.1533,
    longitude: -76.3575,
    postal_code: '20628'
  },
  '20629': {
    country: 'US',
    city: 'Dowell',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.3364,
    longitude: -76.4524,
    postal_code: '20629'
  },
  '20630': {
    country: 'US',
    city: 'Drayden',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.1719,
    longitude: -76.4731,
    postal_code: '20630'
  },
  '20632': {
    country: 'US',
    city: 'Faulkner',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.4382,
    longitude: -76.9729,
    postal_code: '20632'
  },
  '20634': {
    country: 'US',
    city: 'Great Mills',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.2674,
    longitude: -76.4954,
    postal_code: '20634'
  },
  '20635': {
    country: 'US',
    city: 'Helen',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.3121,
    longitude: -76.6077,
    postal_code: '20635'
  },
  '20636': {
    country: 'US',
    city: 'Hollywood',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.3524,
    longitude: -76.5626,
    postal_code: '20636'
  },
  '20637': {
    country: 'US',
    city: 'Hughesville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.5207,
    longitude: -76.7817,
    postal_code: '20637'
  },
  '20639': {
    country: 'US',
    city: 'Huntingtown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.6095,
    longitude: -76.6003,
    postal_code: '20639'
  },
  '20640': {
    country: 'US',
    city: 'Indian Head',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.6001,
    longitude: -77.1622,
    postal_code: '20640'
  },
  '20643': {
    country: 'US',
    city: 'Ironsides',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.5039,
    longitude: -77.1483,
    postal_code: '20643'
  },
  '20645': {
    country: 'US',
    city: 'Issue',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.2926,
    longitude: -76.9059,
    postal_code: '20645'
  },
  '20646': {
    country: 'US',
    city: 'La Plata',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.5257,
    longitude: -76.9865,
    postal_code: '20646'
  },
  '20650': {
    country: 'US',
    city: 'Leonardtown',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.2774,
    longitude: -76.638,
    postal_code: '20650'
  },
  '20653': {
    country: 'US',
    city: 'Lexington Park',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.2495,
    longitude: -76.4529,
    postal_code: '20653'
  },
  '20656': {
    country: 'US',
    city: 'Loveville',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.3593,
    longitude: -76.6833,
    postal_code: '20656'
  },
  '20657': {
    country: 'US',
    city: 'Lusby',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.3661,
    longitude: -76.4346,
    postal_code: '20657'
  },
  '20658': {
    country: 'US',
    city: 'Marbury',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.5633,
    longitude: -77.1596,
    postal_code: '20658'
  },
  '20659': {
    country: 'US',
    city: 'Mechanicsville',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.4293,
    longitude: -76.7254,
    postal_code: '20659'
  },
  '20660': {
    country: 'US',
    city: 'Morganza',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.3754,
    longitude: -76.6955,
    postal_code: '20660'
  },
  '20661': {
    country: 'US',
    city: 'Mount Victoria',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.3436,
    longitude: -76.8846,
    postal_code: '20661'
  },
  '20662': {
    country: 'US',
    city: 'Nanjemoy',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.4462,
    longitude: -77.1983,
    postal_code: '20662'
  },
  '20664': {
    country: 'US',
    city: 'Newburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.3298,
    longitude: -76.9175,
    postal_code: '20664'
  },
  '20667': {
    country: 'US',
    city: 'Park Hall',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.2177,
    longitude: -76.4429,
    postal_code: '20667'
  },
  '20670': {
    country: 'US',
    city: 'Patuxent River',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.2791,
    longitude: -76.4381,
    postal_code: '20670'
  },
  '20674': {
    country: 'US',
    city: 'Piney Point',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.1397,
    longitude: -76.5047,
    postal_code: '20674'
  },
  '20675': {
    country: 'US',
    city: 'Pomfret',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.5855,
    longitude: -77.0093,
    postal_code: '20675'
  },
  '20676': {
    country: 'US',
    city: 'Port Republic',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.4952,
    longitude: -76.5349,
    postal_code: '20676'
  },
  '20677': {
    country: 'US',
    city: 'Port Tobacco',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.4994,
    longitude: -77.0419,
    postal_code: '20677'
  },
  '20678': {
    country: 'US',
    city: 'Prince Frederick',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.5336,
    longitude: -76.5955,
    postal_code: '20678'
  },
  '20680': {
    country: 'US',
    city: 'Ridge',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.1169,
    longitude: -76.3711,
    postal_code: '20680'
  },
  '20682': {
    country: 'US',
    city: 'Rock Point',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.2836,
    longitude: -76.8481,
    postal_code: '20682'
  },
  '20684': {
    country: 'US',
    city: 'Saint Inigoes',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.1441,
    longitude: -76.4083,
    postal_code: '20684'
  },
  '20685': {
    country: 'US',
    city: 'Saint Leonard',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.4501,
    longitude: -76.511,
    postal_code: '20685'
  },
  '20686': {
    country: 'US',
    city: 'Saint Marys City',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.1871,
    longitude: -76.4344,
    postal_code: '20686'
  },
  '20687': {
    country: 'US',
    city: 'Scotland',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.0828,
    longitude: -76.3477,
    postal_code: '20687'
  },
  '20688': {
    country: 'US',
    city: 'Solomons',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.3293,
    longitude: -76.4651,
    postal_code: '20688'
  },
  '20689': {
    country: 'US',
    city: 'Sunderland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.649,
    longitude: -76.5767,
    postal_code: '20689'
  },
  '20690': {
    country: 'US',
    city: 'Tall Timbers',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.1653,
    longitude: -76.5399,
    postal_code: '20690'
  },
  '20692': {
    country: 'US',
    city: 'Valley Lee',
    state: 'Maryland',
    state_short: 'MD',
    county: "St. Mary's",
    latitude: 38.1899,
    longitude: -76.5087,
    postal_code: '20692'
  },
  '20693': {
    country: 'US',
    city: 'Welcome',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.4672,
    longitude: -77.095,
    postal_code: '20693'
  },
  '20695': {
    country: 'US',
    city: 'White Plains',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Charles',
    latitude: 38.5974,
    longitude: -76.9903,
    postal_code: '20695'
  },
  '20697': {
    country: 'US',
    city: 'Southern Md Facility',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20697'
  },
  '20701': {
    country: 'US',
    city: 'Annapolis Junction',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.1332,
    longitude: -76.7988,
    postal_code: '20701'
  },
  '20703': {
    country: 'US',
    city: 'Lanham',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20703'
  },
  '20704': {
    country: 'US',
    city: 'Beltsville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 39.0348,
    longitude: -76.9075,
    postal_code: '20704'
  },
  '20705': {
    country: 'US',
    city: 'Beltsville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 39.0455,
    longitude: -76.9242,
    postal_code: '20705'
  },
  '20706': {
    country: 'US',
    city: 'Lanham',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9675,
    longitude: -76.8551,
    postal_code: '20706'
  },
  '20707': {
    country: 'US',
    city: 'Laurel',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 39.1077,
    longitude: -76.872,
    postal_code: '20707'
  },
  '20708': {
    country: 'US',
    city: 'Laurel',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 39.0499,
    longitude: -76.8345,
    postal_code: '20708'
  },
  '20709': {
    country: 'US',
    city: 'Laurel',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 39.0993,
    longitude: -76.8483,
    postal_code: '20709'
  },
  '20710': {
    country: 'US',
    city: 'Bladensburg',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9421,
    longitude: -76.9261,
    postal_code: '20710'
  },
  '20711': {
    country: 'US',
    city: 'Lothian',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.8029,
    longitude: -76.6628,
    postal_code: '20711'
  },
  '20712': {
    country: 'US',
    city: 'Mount Rainier',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9431,
    longitude: -76.9652,
    postal_code: '20712'
  },
  '20714': {
    country: 'US',
    city: 'North Beach',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.7119,
    longitude: -76.5367,
    postal_code: '20714'
  },
  '20715': {
    country: 'US',
    city: 'Bowie',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9797,
    longitude: -76.7435,
    postal_code: '20715'
  },
  '20716': {
    country: 'US',
    city: 'Bowie',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9263,
    longitude: -76.7098,
    postal_code: '20716'
  },
  '20717': {
    country: 'US',
    city: 'Bowie',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20717'
  },
  '20718': {
    country: 'US',
    city: 'Bowie',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20718'
  },
  '20719': {
    country: 'US',
    city: 'Bowie',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20719'
  },
  '20720': {
    country: 'US',
    city: 'Bowie',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9885,
    longitude: -76.791,
    postal_code: '20720'
  },
  '20721': {
    country: 'US',
    city: 'Bowie',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9194,
    longitude: -76.7871,
    postal_code: '20721'
  },
  '20722': {
    country: 'US',
    city: 'Brentwood',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9407,
    longitude: -76.9531,
    postal_code: '20722'
  },
  '20723': {
    country: 'US',
    city: 'Laurel',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.1208,
    longitude: -76.8435,
    postal_code: '20723'
  },
  '20724': {
    country: 'US',
    city: 'Laurel',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.0958,
    longitude: -76.8155,
    postal_code: '20724'
  },
  '20725': {
    country: 'US',
    city: 'Laurel',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 39.0993,
    longitude: -76.8483,
    postal_code: '20725'
  },
  '20726': {
    country: 'US',
    city: 'Laurel',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 39.0993,
    longitude: -76.8483,
    postal_code: '20726'
  },
  '20731': {
    country: 'US',
    city: 'Capitol Heights',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8851,
    longitude: -76.9158,
    postal_code: '20731'
  },
  '20732': {
    country: 'US',
    city: 'Chesapeake Beach',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.6698,
    longitude: -76.5376,
    postal_code: '20732'
  },
  '20733': {
    country: 'US',
    city: 'Churchton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.8018,
    longitude: -76.5248,
    postal_code: '20733'
  },
  '20735': {
    country: 'US',
    city: 'Clinton',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.7549,
    longitude: -76.9026,
    postal_code: '20735'
  },
  '20736': {
    country: 'US',
    city: 'Owings',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.6955,
    longitude: -76.6061,
    postal_code: '20736'
  },
  '20737': {
    country: 'US',
    city: 'Riverdale',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9601,
    longitude: -76.9147,
    postal_code: '20737'
  },
  '20738': {
    country: 'US',
    city: 'Riverdale',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20738'
  },
  '20740': {
    country: 'US',
    city: 'College Park',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9963,
    longitude: -76.9299,
    postal_code: '20740'
  },
  '20741': {
    country: 'US',
    city: 'College Park',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20741'
  },
  '20742': {
    country: 'US',
    city: 'College Park',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9896,
    longitude: -76.9457,
    postal_code: '20742'
  },
  '20743': {
    country: 'US',
    city: 'Capitol Heights',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8897,
    longitude: -76.8925,
    postal_code: '20743'
  },
  '20744': {
    country: 'US',
    city: 'Fort Washington',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.7587,
    longitude: -76.9835,
    postal_code: '20744'
  },
  '20745': {
    country: 'US',
    city: 'Oxon Hill',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8108,
    longitude: -76.9898,
    postal_code: '20745'
  },
  '20746': {
    country: 'US',
    city: 'Suitland',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8425,
    longitude: -76.9222,
    postal_code: '20746'
  },
  '20747': {
    country: 'US',
    city: 'District Heights',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8539,
    longitude: -76.8891,
    postal_code: '20747'
  },
  '20748': {
    country: 'US',
    city: 'Temple Hills',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8222,
    longitude: -76.9478,
    postal_code: '20748'
  },
  '20749': {
    country: 'US',
    city: 'Fort Washington',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20749'
  },
  '20750': {
    country: 'US',
    city: 'Oxon Hill',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8034,
    longitude: -76.9897,
    postal_code: '20750'
  },
  '20751': {
    country: 'US',
    city: 'Deale',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.7829,
    longitude: -76.5515,
    postal_code: '20751'
  },
  '20752': {
    country: 'US',
    city: 'Suitland',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8487,
    longitude: -76.9239,
    postal_code: '20752'
  },
  '20753': {
    country: 'US',
    city: 'District Heights',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20753'
  },
  '20754': {
    country: 'US',
    city: 'Dunkirk',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Calvert',
    latitude: 38.7408,
    longitude: -76.6427,
    postal_code: '20754'
  },
  '20755': {
    country: 'US',
    city: 'Fort George G Meade',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.1059,
    longitude: -76.7467,
    postal_code: '20755'
  },
  '20757': {
    country: 'US',
    city: 'Temple Hills',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.814,
    longitude: -76.9455,
    postal_code: '20757'
  },
  '20758': {
    country: 'US',
    city: 'Friendship',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.7361,
    longitude: -76.5835,
    postal_code: '20758'
  },
  '20759': {
    country: 'US',
    city: 'Fulton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.1502,
    longitude: -76.93,
    postal_code: '20759'
  },
  '20762': {
    country: 'US',
    city: 'Andrews Air Force Base',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8062,
    longitude: -76.8756,
    postal_code: '20762'
  },
  '20763': {
    country: 'US',
    city: 'Savage',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.138,
    longitude: -76.8218,
    postal_code: '20763'
  },
  '20764': {
    country: 'US',
    city: 'Shady Side',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.8368,
    longitude: -76.5109,
    postal_code: '20764'
  },
  '20765': {
    country: 'US',
    city: 'Galesville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.8446,
    longitude: -76.5456,
    postal_code: '20765'
  },
  '20768': {
    country: 'US',
    city: 'Greenbelt',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 39.0046,
    longitude: -76.8755,
    postal_code: '20768'
  },
  '20769': {
    country: 'US',
    city: 'Glenn Dale',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9766,
    longitude: -76.8053,
    postal_code: '20769'
  },
  '20770': {
    country: 'US',
    city: 'Greenbelt',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9996,
    longitude: -76.884,
    postal_code: '20770'
  },
  '20771': {
    country: 'US',
    city: 'Greenbelt',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 39.0046,
    longitude: -76.8755,
    postal_code: '20771'
  },
  '20772': {
    country: 'US',
    city: 'Upper Marlboro',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8377,
    longitude: -76.798,
    postal_code: '20772'
  },
  '20773': {
    country: 'US',
    city: 'Upper Marlboro',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8159,
    longitude: -76.7497,
    postal_code: '20773'
  },
  '20774': {
    country: 'US',
    city: 'Upper Marlboro',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8682,
    longitude: -76.8156,
    postal_code: '20774'
  },
  '20775': {
    country: 'US',
    city: 'Upper Marlboro',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20775'
  },
  '20776': {
    country: 'US',
    city: 'Harwood',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.8582,
    longitude: -76.6145,
    postal_code: '20776'
  },
  '20777': {
    country: 'US',
    city: 'Highland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.1843,
    longitude: -76.9686,
    postal_code: '20777'
  },
  '20778': {
    country: 'US',
    city: 'West River',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.8252,
    longitude: -76.5391,
    postal_code: '20778'
  },
  '20779': {
    country: 'US',
    city: 'Tracys Landing',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.7671,
    longitude: -76.5752,
    postal_code: '20779'
  },
  '20781': {
    country: 'US',
    city: 'Hyattsville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9506,
    longitude: -76.9347,
    postal_code: '20781'
  },
  '20782': {
    country: 'US',
    city: 'Hyattsville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9647,
    longitude: -76.9649,
    postal_code: '20782'
  },
  '20783': {
    country: 'US',
    city: 'Hyattsville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 39.0005,
    longitude: -76.9723,
    postal_code: '20783'
  },
  '20784': {
    country: 'US',
    city: 'Hyattsville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9513,
    longitude: -76.8958,
    postal_code: '20784'
  },
  '20785': {
    country: 'US',
    city: 'Hyattsville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9223,
    longitude: -76.8755,
    postal_code: '20785'
  },
  '20787': {
    country: 'US',
    city: 'Hyattsville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9871,
    longitude: -76.9824,
    postal_code: '20787'
  },
  '20788': {
    country: 'US',
    city: 'Hyattsville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9694,
    longitude: -76.9509,
    postal_code: '20788'
  },
  '20790': {
    country: 'US',
    city: 'Capitol Heights',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20790'
  },
  '20791': {
    country: 'US',
    city: 'Capitol Heights',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20791'
  },
  '20792': {
    country: 'US',
    city: 'Upper Marlboro',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8158,
    longitude: -76.75,
    postal_code: '20792'
  },
  '20794': {
    country: 'US',
    city: 'Jessup',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.1484,
    longitude: -76.7922,
    postal_code: '20794'
  },
  '20797': {
    country: 'US',
    city: 'Southern Md Facility',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20797'
  },
  '20799': {
    country: 'US',
    city: 'Capitol Heights',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.8336,
    longitude: -76.8777,
    postal_code: '20799'
  },
  '20810': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9806,
    longitude: -77.1008,
    postal_code: '20810'
  },
  '20811': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9806,
    longitude: -77.1008,
    postal_code: '20811'
  },
  '20812': {
    country: 'US',
    city: 'Glen Echo',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9693,
    longitude: -77.1435,
    postal_code: '20812'
  },
  '20813': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.144,
    longitude: -77.2076,
    postal_code: '20813'
  },
  '20814': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0003,
    longitude: -77.1022,
    postal_code: '20814'
  },
  '20815': {
    country: 'US',
    city: 'Chevy Chase',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.978,
    longitude: -77.082,
    postal_code: '20815'
  },
  '20816': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9585,
    longitude: -77.1153,
    postal_code: '20816'
  },
  '20817': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9896,
    longitude: -77.1538,
    postal_code: '20817'
  },
  '20818': {
    country: 'US',
    city: 'Cabin John',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9743,
    longitude: -77.1591,
    postal_code: '20818'
  },
  '20824': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9807,
    longitude: -77.1003,
    postal_code: '20824'
  },
  '20825': {
    country: 'US',
    city: 'Chevy Chase',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0029,
    longitude: -77.0712,
    postal_code: '20825'
  },
  '20827': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.144,
    longitude: -77.2076,
    postal_code: '20827'
  },
  '20830': {
    country: 'US',
    city: 'Olney',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1552,
    longitude: -77.0667,
    postal_code: '20830'
  },
  '20832': {
    country: 'US',
    city: 'Olney',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1526,
    longitude: -77.0749,
    postal_code: '20832'
  },
  '20833': {
    country: 'US',
    city: 'Brookeville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1871,
    longitude: -77.0603,
    postal_code: '20833'
  },
  '20837': {
    country: 'US',
    city: 'Poolesville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1386,
    longitude: -77.4067,
    postal_code: '20837'
  },
  '20838': {
    country: 'US',
    city: 'Barnesville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.2233,
    longitude: -77.3764,
    postal_code: '20838'
  },
  '20839': {
    country: 'US',
    city: 'Beallsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.179,
    longitude: -77.4128,
    postal_code: '20839'
  },
  '20841': {
    country: 'US',
    city: 'Boyds',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.21,
    longitude: -77.3167,
    postal_code: '20841'
  },
  '20842': {
    country: 'US',
    city: 'Dickerson',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.2126,
    longitude: -77.4199,
    postal_code: '20842'
  },
  '20847': {
    country: 'US',
    city: 'Rockville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.084,
    longitude: -77.1528,
    postal_code: '20847'
  },
  '20848': {
    country: 'US',
    city: 'Rockville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.144,
    longitude: -77.2076,
    postal_code: '20848'
  },
  '20849': {
    country: 'US',
    city: 'Rockville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.084,
    longitude: -77.1528,
    postal_code: '20849'
  },
  '20850': {
    country: 'US',
    city: 'Rockville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.087,
    longitude: -77.168,
    postal_code: '20850'
  },
  '20851': {
    country: 'US',
    city: 'Rockville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0763,
    longitude: -77.1234,
    postal_code: '20851'
  },
  '20852': {
    country: 'US',
    city: 'Rockville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0496,
    longitude: -77.1204,
    postal_code: '20852'
  },
  '20853': {
    country: 'US',
    city: 'Rockville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0887,
    longitude: -77.095,
    postal_code: '20853'
  },
  '20854': {
    country: 'US',
    city: 'Potomac',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0388,
    longitude: -77.1922,
    postal_code: '20854'
  },
  '20855': {
    country: 'US',
    city: 'Derwood',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1345,
    longitude: -77.1477,
    postal_code: '20855'
  },
  '20857': {
    country: 'US',
    city: 'Rockville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.084,
    longitude: -77.1528,
    postal_code: '20857'
  },
  '20859': {
    country: 'US',
    city: 'Potomac',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.144,
    longitude: -77.2076,
    postal_code: '20859'
  },
  '20860': {
    country: 'US',
    city: 'Sandy Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1503,
    longitude: -77.0291,
    postal_code: '20860'
  },
  '20861': {
    country: 'US',
    city: 'Ashton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.151,
    longitude: -76.9924,
    postal_code: '20861'
  },
  '20862': {
    country: 'US',
    city: 'Brinklow',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1838,
    longitude: -77.0163,
    postal_code: '20862'
  },
  '20866': {
    country: 'US',
    city: 'Burtonsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0922,
    longitude: -76.9339,
    postal_code: '20866'
  },
  '20868': {
    country: 'US',
    city: 'Spencerville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1223,
    longitude: -76.9722,
    postal_code: '20868'
  },
  '20871': {
    country: 'US',
    city: 'Clarksburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.2387,
    longitude: -77.2794,
    postal_code: '20871'
  },
  '20872': {
    country: 'US',
    city: 'Damascus',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.2761,
    longitude: -77.2131,
    postal_code: '20872'
  },
  '20874': {
    country: 'US',
    city: 'Germantown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1355,
    longitude: -77.2822,
    postal_code: '20874'
  },
  '20875': {
    country: 'US',
    city: 'Germantown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.144,
    longitude: -77.2076,
    postal_code: '20875'
  },
  '20876': {
    country: 'US',
    city: 'Germantown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.188,
    longitude: -77.2358,
    postal_code: '20876'
  },
  '20877': {
    country: 'US',
    city: 'Gaithersburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1419,
    longitude: -77.189,
    postal_code: '20877'
  },
  '20878': {
    country: 'US',
    city: 'Gaithersburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1125,
    longitude: -77.2515,
    postal_code: '20878'
  },
  '20879': {
    country: 'US',
    city: 'Gaithersburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.173,
    longitude: -77.1855,
    postal_code: '20879'
  },
  '20880': {
    country: 'US',
    city: 'Washington Grove',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1388,
    longitude: -77.1726,
    postal_code: '20880'
  },
  '20882': {
    country: 'US',
    city: 'Gaithersburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.2335,
    longitude: -77.1458,
    postal_code: '20882'
  },
  '20883': {
    country: 'US',
    city: 'Gaithersburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0883,
    longitude: -77.1568,
    postal_code: '20883'
  },
  '20884': {
    country: 'US',
    city: 'Gaithersburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.144,
    longitude: -77.2076,
    postal_code: '20884'
  },
  '20885': {
    country: 'US',
    city: 'Gaithersburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1874,
    longitude: -77.2028,
    postal_code: '20885'
  },
  '20886': {
    country: 'US',
    city: 'Montgomery Village',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1757,
    longitude: -77.1873,
    postal_code: '20886'
  },
  '20889': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.144,
    longitude: -77.2076,
    postal_code: '20889'
  },
  '20891': {
    country: 'US',
    city: 'Kensington',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0257,
    longitude: -77.0764,
    postal_code: '20891'
  },
  '20892': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0024,
    longitude: -77.1034,
    postal_code: '20892'
  },
  '20894': {
    country: 'US',
    city: 'Bethesda',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.144,
    longitude: -77.2076,
    postal_code: '20894'
  },
  '20895': {
    country: 'US',
    city: 'Kensington',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0298,
    longitude: -77.0793,
    postal_code: '20895'
  },
  '20896': {
    country: 'US',
    city: 'Garrett Park',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0365,
    longitude: -77.0931,
    postal_code: '20896'
  },
  '20897': {
    country: 'US',
    city: 'Suburb Maryland Fac',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.144,
    longitude: -77.2076,
    postal_code: '20897'
  },
  '20898': {
    country: 'US',
    city: 'Gaithersburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.144,
    longitude: -77.2076,
    postal_code: '20898'
  },
  '20899': {
    country: 'US',
    city: 'Gaithersburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1403,
    longitude: -77.222,
    postal_code: '20899'
  },
  '20901': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0191,
    longitude: -77.0076,
    postal_code: '20901'
  },
  '20902': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.04,
    longitude: -77.0444,
    postal_code: '20902'
  },
  '20903': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0095,
    longitude: -76.9846,
    postal_code: '20903'
  },
  '20904': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0668,
    longitude: -76.9969,
    postal_code: '20904'
  },
  '20905': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.1148,
    longitude: -77.0059,
    postal_code: '20905'
  },
  '20906': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.084,
    longitude: -77.0613,
    postal_code: '20906'
  },
  '20907': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9907,
    longitude: -77.0261,
    postal_code: '20907'
  },
  '20908': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9907,
    longitude: -77.0261,
    postal_code: '20908'
  },
  '20910': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9982,
    longitude: -77.0338,
    postal_code: '20910'
  },
  '20911': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9907,
    longitude: -77.0261,
    postal_code: '20911'
  },
  '20912': {
    country: 'US',
    city: 'Takoma Park',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9832,
    longitude: -77.0007,
    postal_code: '20912'
  },
  '20913': {
    country: 'US',
    city: 'Takoma Park',
    state: 'Maryland',
    state_short: 'MD',
    county: "Prince George's",
    latitude: 38.9779,
    longitude: -77.0075,
    postal_code: '20913'
  },
  '20914': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9907,
    longitude: -77.0261,
    postal_code: '20914'
  },
  '20915': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9907,
    longitude: -77.0261,
    postal_code: '20915'
  },
  '20916': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9907,
    longitude: -77.0261,
    postal_code: '20916'
  },
  '20918': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9907,
    longitude: -77.0261,
    postal_code: '20918'
  },
  '20993': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 39.0336,
    longitude: -76.9861,
    postal_code: '20993'
  },
  '20997': {
    country: 'US',
    city: 'Silver Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Montgomery',
    latitude: 38.9907,
    longitude: -77.0261,
    postal_code: '20997'
  },
  '21001': {
    country: 'US',
    city: 'Aberdeen',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.5109,
    longitude: -76.1805,
    postal_code: '21001'
  },
  '21005': {
    country: 'US',
    city: 'Aberdeen Proving Ground',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.4771,
    longitude: -76.1208,
    postal_code: '21005'
  },
  '21009': {
    country: 'US',
    city: 'Abingdon',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.4744,
    longitude: -76.2997,
    postal_code: '21009'
  },
  '21010': {
    country: 'US',
    city: 'Gunpowder',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.3982,
    longitude: -76.2743,
    postal_code: '21010'
  },
  '21012': {
    country: 'US',
    city: 'Arnold',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.0476,
    longitude: -76.4941,
    postal_code: '21012'
  },
  '21013': {
    country: 'US',
    city: 'Baldwin',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.5194,
    longitude: -76.4927,
    postal_code: '21013'
  },
  '21014': {
    country: 'US',
    city: 'Bel Air',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.5394,
    longitude: -76.3564,
    postal_code: '21014'
  },
  '21015': {
    country: 'US',
    city: 'Bel Air',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.5303,
    longitude: -76.3153,
    postal_code: '21015'
  },
  '21017': {
    country: 'US',
    city: 'Belcamp',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.4756,
    longitude: -76.242,
    postal_code: '21017'
  },
  '21018': {
    country: 'US',
    city: 'Benson',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.5093,
    longitude: -76.3851,
    postal_code: '21018'
  },
  '21020': {
    country: 'US',
    city: 'Boring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.5213,
    longitude: -76.8047,
    postal_code: '21020'
  },
  '21022': {
    country: 'US',
    city: 'Brooklandville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3979,
    longitude: -76.6717,
    postal_code: '21022'
  },
  '21023': {
    country: 'US',
    city: 'Butler',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.533,
    longitude: -76.7432,
    postal_code: '21023'
  },
  '21027': {
    country: 'US',
    city: 'Chase',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3634,
    longitude: -76.3711,
    postal_code: '21027'
  },
  '21028': {
    country: 'US',
    city: 'Churchville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.5648,
    longitude: -76.249,
    postal_code: '21028'
  },
  '21029': {
    country: 'US',
    city: 'Clarksville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2125,
    longitude: -76.9515,
    postal_code: '21029'
  },
  '21030': {
    country: 'US',
    city: 'Cockeysville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4919,
    longitude: -76.6677,
    postal_code: '21030'
  },
  '21031': {
    country: 'US',
    city: 'Hunt Valley',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4805,
    longitude: -76.6553,
    postal_code: '21031'
  },
  '21032': {
    country: 'US',
    city: 'Crownsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.0489,
    longitude: -76.5935,
    postal_code: '21032'
  },
  '21034': {
    country: 'US',
    city: 'Darlington',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.654,
    longitude: -76.2278,
    postal_code: '21034'
  },
  '21035': {
    country: 'US',
    city: 'Davidsonville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9374,
    longitude: -76.6375,
    postal_code: '21035'
  },
  '21036': {
    country: 'US',
    city: 'Dayton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2339,
    longitude: -76.9968,
    postal_code: '21036'
  },
  '21037': {
    country: 'US',
    city: 'Edgewater',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9149,
    longitude: -76.5424,
    postal_code: '21037'
  },
  '21040': {
    country: 'US',
    city: 'Edgewood',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.4277,
    longitude: -76.3055,
    postal_code: '21040'
  },
  '21041': {
    country: 'US',
    city: 'Ellicott City',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2364,
    longitude: -76.9419,
    postal_code: '21041'
  },
  '21042': {
    country: 'US',
    city: 'Ellicott City',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2726,
    longitude: -76.8614,
    postal_code: '21042'
  },
  '21043': {
    country: 'US',
    city: 'Ellicott City',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2548,
    longitude: -76.8001,
    postal_code: '21043'
  },
  '21044': {
    country: 'US',
    city: 'Columbia',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2141,
    longitude: -76.8788,
    postal_code: '21044'
  },
  '21045': {
    country: 'US',
    city: 'Columbia',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2051,
    longitude: -76.8322,
    postal_code: '21045'
  },
  '21046': {
    country: 'US',
    city: 'Columbia',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.1702,
    longitude: -76.8538,
    postal_code: '21046'
  },
  '21047': {
    country: 'US',
    city: 'Fallston',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.527,
    longitude: -76.4328,
    postal_code: '21047'
  },
  '21048': {
    country: 'US',
    city: 'Finksburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.4991,
    longitude: -76.9101,
    postal_code: '21048'
  },
  '21050': {
    country: 'US',
    city: 'Forest Hill',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.5755,
    longitude: -76.4008,
    postal_code: '21050'
  },
  '21051': {
    country: 'US',
    city: 'Fork',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4731,
    longitude: -76.4484,
    postal_code: '21051'
  },
  '21052': {
    country: 'US',
    city: 'Fort Howard',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.207,
    longitude: -76.4456,
    postal_code: '21052'
  },
  '21053': {
    country: 'US',
    city: 'Freeland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.694,
    longitude: -76.7223,
    postal_code: '21053'
  },
  '21054': {
    country: 'US',
    city: 'Gambrills',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.0407,
    longitude: -76.6819,
    postal_code: '21054'
  },
  '21056': {
    country: 'US',
    city: 'Gibson Island',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.0751,
    longitude: -76.4324,
    postal_code: '21056'
  },
  '21057': {
    country: 'US',
    city: 'Glen Arm',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4575,
    longitude: -76.5153,
    postal_code: '21057'
  },
  '21060': {
    country: 'US',
    city: 'Glen Burnie',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.1702,
    longitude: -76.5798,
    postal_code: '21060'
  },
  '21061': {
    country: 'US',
    city: 'Glen Burnie',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.1618,
    longitude: -76.6297,
    postal_code: '21061'
  },
  '21062': {
    country: 'US',
    city: 'Glen Burnie',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9742,
    longitude: -76.5949,
    postal_code: '21062'
  },
  '21065': {
    country: 'US',
    city: 'Hunt Valley',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4883,
    longitude: -76.6538,
    postal_code: '21065'
  },
  '21071': {
    country: 'US',
    city: 'Glyndon',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.477,
    longitude: -76.815,
    postal_code: '21071'
  },
  '21074': {
    country: 'US',
    city: 'Hampstead',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.6146,
    longitude: -76.8644,
    postal_code: '21074'
  },
  '21075': {
    country: 'US',
    city: 'Elkridge',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2058,
    longitude: -76.7531,
    postal_code: '21075'
  },
  '21076': {
    country: 'US',
    city: 'Hanover',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.1551,
    longitude: -76.7215,
    postal_code: '21076'
  },
  '21077': {
    country: 'US',
    city: 'Harmans',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.1561,
    longitude: -76.6977,
    postal_code: '21077'
  },
  '21078': {
    country: 'US',
    city: 'Havre De Grace',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.5523,
    longitude: -76.1171,
    postal_code: '21078'
  },
  '21082': {
    country: 'US',
    city: 'Hydes',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.474,
    longitude: -76.4695,
    postal_code: '21082'
  },
  '21084': {
    country: 'US',
    city: 'Jarrettsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.6162,
    longitude: -76.4684,
    postal_code: '21084'
  },
  '21085': {
    country: 'US',
    city: 'Joppa',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.4242,
    longitude: -76.3541,
    postal_code: '21085'
  },
  '21087': {
    country: 'US',
    city: 'Kingsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4558,
    longitude: -76.4147,
    postal_code: '21087'
  },
  '21088': {
    country: 'US',
    city: 'Lineboro',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.7187,
    longitude: -76.8439,
    postal_code: '21088'
  },
  '21090': {
    country: 'US',
    city: 'Linthicum Heights',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.2092,
    longitude: -76.6681,
    postal_code: '21090'
  },
  '21092': {
    country: 'US',
    city: 'Long Green',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4729,
    longitude: -76.523,
    postal_code: '21092'
  },
  '21093': {
    country: 'US',
    city: 'Lutherville Timonium',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4332,
    longitude: -76.6546,
    postal_code: '21093'
  },
  '21094': {
    country: 'US',
    city: 'Lutherville Timonium',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.439,
    longitude: -76.5921,
    postal_code: '21094'
  },
  '21102': {
    country: 'US',
    city: 'Manchester',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.6747,
    longitude: -76.8941,
    postal_code: '21102'
  },
  '21104': {
    country: 'US',
    city: 'Marriottsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.3342,
    longitude: -76.9132,
    postal_code: '21104'
  },
  '21105': {
    country: 'US',
    city: 'Maryland Line',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.7114,
    longitude: -76.6595,
    postal_code: '21105'
  },
  '21106': {
    country: 'US',
    city: 'Mayo',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.8876,
    longitude: -76.5119,
    postal_code: '21106'
  },
  '21108': {
    country: 'US',
    city: 'Millersville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.1041,
    longitude: -76.619,
    postal_code: '21108'
  },
  '21111': {
    country: 'US',
    city: 'Monkton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.5662,
    longitude: -76.5979,
    postal_code: '21111'
  },
  '21113': {
    country: 'US',
    city: 'Odenton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.0762,
    longitude: -76.6996,
    postal_code: '21113'
  },
  '21114': {
    country: 'US',
    city: 'Crofton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.0112,
    longitude: -76.6802,
    postal_code: '21114'
  },
  '21117': {
    country: 'US',
    city: 'Owings Mills',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4269,
    longitude: -76.7769,
    postal_code: '21117'
  },
  '21120': {
    country: 'US',
    city: 'Parkton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.6422,
    longitude: -76.6737,
    postal_code: '21120'
  },
  '21122': {
    country: 'US',
    city: 'Pasadena',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.1206,
    longitude: -76.495,
    postal_code: '21122'
  },
  '21123': {
    country: 'US',
    city: 'Pasadena',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9742,
    longitude: -76.5949,
    postal_code: '21123'
  },
  '21128': {
    country: 'US',
    city: 'Perry Hall',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.401,
    longitude: -76.451,
    postal_code: '21128'
  },
  '21130': {
    country: 'US',
    city: 'Perryman',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.4716,
    longitude: -76.2117,
    postal_code: '21130'
  },
  '21131': {
    country: 'US',
    city: 'Phoenix',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4833,
    longitude: -76.5776,
    postal_code: '21131'
  },
  '21132': {
    country: 'US',
    city: 'Pylesville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.6959,
    longitude: -76.4113,
    postal_code: '21132'
  },
  '21133': {
    country: 'US',
    city: 'Randallstown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3746,
    longitude: -76.8002,
    postal_code: '21133'
  },
  '21136': {
    country: 'US',
    city: 'Reisterstown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.46,
    longitude: -76.8135,
    postal_code: '21136'
  },
  '21139': {
    country: 'US',
    city: 'Riderwood',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4093,
    longitude: -76.6486,
    postal_code: '21139'
  },
  '21140': {
    country: 'US',
    city: 'Riva',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9504,
    longitude: -76.5854,
    postal_code: '21140'
  },
  '21144': {
    country: 'US',
    city: 'Severn',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.1275,
    longitude: -76.698,
    postal_code: '21144'
  },
  '21146': {
    country: 'US',
    city: 'Severna Park',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.0811,
    longitude: -76.5577,
    postal_code: '21146'
  },
  '21150': {
    country: 'US',
    city: 'Simpsonville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2364,
    longitude: -76.9419,
    postal_code: '21150'
  },
  '21152': {
    country: 'US',
    city: 'Sparks Glencoe',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.5483,
    longitude: -76.6819,
    postal_code: '21152'
  },
  '21153': {
    country: 'US',
    city: 'Stevenson',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4104,
    longitude: -76.713,
    postal_code: '21153'
  },
  '21154': {
    country: 'US',
    city: 'Street',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.6574,
    longitude: -76.3713,
    postal_code: '21154'
  },
  '21155': {
    country: 'US',
    city: 'Upperco',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.5676,
    longitude: -76.7972,
    postal_code: '21155'
  },
  '21156': {
    country: 'US',
    city: 'Upper Falls',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4372,
    longitude: -76.3966,
    postal_code: '21156'
  },
  '21157': {
    country: 'US',
    city: 'Westminster',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.5642,
    longitude: -76.9807,
    postal_code: '21157'
  },
  '21158': {
    country: 'US',
    city: 'Westminster',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.607,
    longitude: -77.0294,
    postal_code: '21158'
  },
  '21160': {
    country: 'US',
    city: 'Whiteford',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.7077,
    longitude: -76.316,
    postal_code: '21160'
  },
  '21161': {
    country: 'US',
    city: 'White Hall',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Harford',
    latitude: 39.6618,
    longitude: -76.5666,
    postal_code: '21161'
  },
  '21162': {
    country: 'US',
    city: 'White Marsh',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3923,
    longitude: -76.4132,
    postal_code: '21162'
  },
  '21163': {
    country: 'US',
    city: 'Woodstock',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3498,
    longitude: -76.8456,
    postal_code: '21163'
  },
  '21201': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2946,
    longitude: -76.6252,
    postal_code: '21201'
  },
  '21202': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2998,
    longitude: -76.6075,
    postal_code: '21202'
  },
  '21203': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21203'
  },
  '21204': {
    country: 'US',
    city: 'Towson',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4072,
    longitude: -76.6038,
    postal_code: '21204'
  },
  '21205': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3009,
    longitude: -76.5799,
    postal_code: '21205'
  },
  '21206': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3365,
    longitude: -76.5411,
    postal_code: '21206'
  },
  '21207': {
    country: 'US',
    city: 'Gwynn Oak',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3296,
    longitude: -76.7341,
    postal_code: '21207'
  },
  '21208': {
    country: 'US',
    city: 'Pikesville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3764,
    longitude: -76.729,
    postal_code: '21208'
  },
  '21209': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3716,
    longitude: -76.6744,
    postal_code: '21209'
  },
  '21210': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3507,
    longitude: -76.6321,
    postal_code: '21210'
  },
  '21211': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3316,
    longitude: -76.6336,
    postal_code: '21211'
  },
  '21212': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3626,
    longitude: -76.61,
    postal_code: '21212'
  },
  '21213': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3127,
    longitude: -76.581,
    postal_code: '21213'
  },
  '21214': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3521,
    longitude: -76.5644,
    postal_code: '21214'
  },
  '21215': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3446,
    longitude: -76.6794,
    postal_code: '21215'
  },
  '21216': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3093,
    longitude: -76.6699,
    postal_code: '21216'
  },
  '21217': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3064,
    longitude: -76.6393,
    postal_code: '21217'
  },
  '21218': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.3265,
    longitude: -76.6048,
    postal_code: '21218'
  },
  '21219': {
    country: 'US',
    city: 'Sparrows Point',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.2296,
    longitude: -76.4455,
    postal_code: '21219'
  },
  '21220': {
    country: 'US',
    city: 'Middle River',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3401,
    longitude: -76.4153,
    postal_code: '21220'
  },
  '21221': {
    country: 'US',
    city: 'Essex',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3086,
    longitude: -76.4533,
    postal_code: '21221'
  },
  '21222': {
    country: 'US',
    city: 'Dundalk',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.2655,
    longitude: -76.4935,
    postal_code: '21222'
  },
  '21223': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.287,
    longitude: -76.6476,
    postal_code: '21223'
  },
  '21224': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2876,
    longitude: -76.5568,
    postal_code: '21224'
  },
  '21225': {
    country: 'US',
    city: 'Brooklyn',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.2259,
    longitude: -76.6153,
    postal_code: '21225'
  },
  '21226': {
    country: 'US',
    city: 'Curtis Bay',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.2109,
    longitude: -76.5597,
    postal_code: '21226'
  },
  '21227': {
    country: 'US',
    city: 'Halethorpe',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.2309,
    longitude: -76.6969,
    postal_code: '21227'
  },
  '21228': {
    country: 'US',
    city: 'Catonsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.2782,
    longitude: -76.7401,
    postal_code: '21228'
  },
  '21229': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2856,
    longitude: -76.6899,
    postal_code: '21229'
  },
  '21230': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2645,
    longitude: -76.6224,
    postal_code: '21230'
  },
  '21231': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2892,
    longitude: -76.59,
    postal_code: '21231'
  },
  '21233': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21233'
  },
  '21234': {
    country: 'US',
    city: 'Parkville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3876,
    longitude: -76.5418,
    postal_code: '21234'
  },
  '21235': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21235'
  },
  '21236': {
    country: 'US',
    city: 'Nottingham',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3914,
    longitude: -76.4871,
    postal_code: '21236'
  },
  '21237': {
    country: 'US',
    city: 'Rosedale',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3361,
    longitude: -76.5014,
    postal_code: '21237'
  },
  '21239': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.361,
    longitude: -76.5891,
    postal_code: '21239'
  },
  '21240': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.1753,
    longitude: -76.6732,
    postal_code: '21240'
  },
  '21241': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21241'
  },
  '21244': {
    country: 'US',
    city: 'Windsor Mill',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3331,
    longitude: -76.7849,
    postal_code: '21244'
  },
  '21250': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.2582,
    longitude: -76.7131,
    postal_code: '21250'
  },
  '21251': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.439,
    longitude: -76.5921,
    postal_code: '21251'
  },
  '21252': {
    country: 'US',
    city: 'Towson',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4015,
    longitude: -76.6019,
    postal_code: '21252'
  },
  '21263': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21263'
  },
  '21264': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21264'
  },
  '21270': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21270'
  },
  '21273': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21273'
  },
  '21275': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21275'
  },
  '21278': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21278'
  },
  '21279': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21279'
  },
  '21280': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21280'
  },
  '21281': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21281'
  },
  '21282': {
    country: 'US',
    city: 'Pikesville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.3743,
    longitude: -76.7225,
    postal_code: '21282'
  },
  '21284': {
    country: 'US',
    city: 'Towson',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4015,
    longitude: -76.6019,
    postal_code: '21284'
  },
  '21285': {
    country: 'US',
    city: 'Towson',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4015,
    longitude: -76.6019,
    postal_code: '21285'
  },
  '21286': {
    country: 'US',
    city: 'Towson',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore',
    latitude: 39.4143,
    longitude: -76.5761,
    postal_code: '21286'
  },
  '21287': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2975,
    longitude: -76.5927,
    postal_code: '21287'
  },
  '21288': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21288'
  },
  '21289': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21289'
  },
  '21290': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2933,
    longitude: -76.6238,
    postal_code: '21290'
  },
  '21297': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21297'
  },
  '21298': {
    country: 'US',
    city: 'Baltimore',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Baltimore (city)',
    latitude: 39.2847,
    longitude: -76.6205,
    postal_code: '21298'
  },
  '21401': {
    country: 'US',
    city: 'Annapolis',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9898,
    longitude: -76.5501,
    postal_code: '21401'
  },
  '21402': {
    country: 'US',
    city: 'Annapolis',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9871,
    longitude: -76.4715,
    postal_code: '21402'
  },
  '21403': {
    country: 'US',
    city: 'Annapolis',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9524,
    longitude: -76.491,
    postal_code: '21403'
  },
  '21404': {
    country: 'US',
    city: 'Annapolis',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9784,
    longitude: -76.4922,
    postal_code: '21404'
  },
  '21405': {
    country: 'US',
    city: 'Annapolis',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.0305,
    longitude: -76.5515,
    postal_code: '21405'
  },
  '21409': {
    country: 'US',
    city: 'Annapolis',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 39.0416,
    longitude: -76.4377,
    postal_code: '21409'
  },
  '21411': {
    country: 'US',
    city: 'Annapolis',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9742,
    longitude: -76.5949,
    postal_code: '21411'
  },
  '21412': {
    country: 'US',
    city: 'Annapolis',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Anne Arundel',
    latitude: 38.9742,
    longitude: -76.5949,
    postal_code: '21412'
  },
  '21501': {
    country: 'US',
    city: 'Cumberland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.6529,
    longitude: -78.7625,
    postal_code: '21501'
  },
  '21502': {
    country: 'US',
    city: 'Cumberland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.5992,
    longitude: -78.8444,
    postal_code: '21502'
  },
  '21503': {
    country: 'US',
    city: 'Cumberland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.6529,
    longitude: -78.7625,
    postal_code: '21503'
  },
  '21504': {
    country: 'US',
    city: 'Cumberland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.5807,
    longitude: -78.6906,
    postal_code: '21504'
  },
  '21505': {
    country: 'US',
    city: 'Cumberland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.594,
    longitude: -78.8434,
    postal_code: '21505'
  },
  '21520': {
    country: 'US',
    city: 'Accident',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Garrett',
    latitude: 39.6355,
    longitude: -79.3085,
    postal_code: '21520'
  },
  '21521': {
    country: 'US',
    city: 'Barton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.5331,
    longitude: -79.0281,
    postal_code: '21521'
  },
  '21522': {
    country: 'US',
    city: 'Bittinger',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Garrett',
    latitude: 39.6023,
    longitude: -79.2234,
    postal_code: '21522'
  },
  '21523': {
    country: 'US',
    city: 'Bloomington',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Garrett',
    latitude: 39.4861,
    longitude: -79.083,
    postal_code: '21523'
  },
  '21524': {
    country: 'US',
    city: 'Corriganville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.6967,
    longitude: -78.8031,
    postal_code: '21524'
  },
  '21528': {
    country: 'US',
    city: 'Eckhart Mines',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.6528,
    longitude: -78.9014,
    postal_code: '21528'
  },
  '21529': {
    country: 'US',
    city: 'Ellerslie',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.7083,
    longitude: -78.7774,
    postal_code: '21529'
  },
  '21530': {
    country: 'US',
    city: 'Flintstone',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.6993,
    longitude: -78.5739,
    postal_code: '21530'
  },
  '21531': {
    country: 'US',
    city: 'Friendsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Garrett',
    latitude: 39.6665,
    longitude: -79.4219,
    postal_code: '21531'
  },
  '21532': {
    country: 'US',
    city: 'Frostburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.6494,
    longitude: -78.9306,
    postal_code: '21532'
  },
  '21536': {
    country: 'US',
    city: 'Grantsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Garrett',
    latitude: 39.6551,
    longitude: -79.1241,
    postal_code: '21536'
  },
  '21538': {
    country: 'US',
    city: 'Kitzmiller',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Garrett',
    latitude: 39.4169,
    longitude: -79.2222,
    postal_code: '21538'
  },
  '21539': {
    country: 'US',
    city: 'Lonaconing',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.5757,
    longitude: -78.9915,
    postal_code: '21539'
  },
  '21540': {
    country: 'US',
    city: 'Luke',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.4774,
    longitude: -79.0594,
    postal_code: '21540'
  },
  '21541': {
    country: 'US',
    city: 'Mc Henry',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Garrett',
    latitude: 39.5656,
    longitude: -79.3823,
    postal_code: '21541'
  },
  '21542': {
    country: 'US',
    city: 'Midland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.5901,
    longitude: -78.9497,
    postal_code: '21542'
  },
  '21543': {
    country: 'US',
    city: 'Midlothian',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.6343,
    longitude: -78.95,
    postal_code: '21543'
  },
  '21545': {
    country: 'US',
    city: 'Mount Savage',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.6991,
    longitude: -78.8739,
    postal_code: '21545'
  },
  '21550': {
    country: 'US',
    city: 'Oakland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Garrett',
    latitude: 39.4339,
    longitude: -79.3167,
    postal_code: '21550'
  },
  '21555': {
    country: 'US',
    city: 'Oldtown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.5846,
    longitude: -78.6044,
    postal_code: '21555'
  },
  '21556': {
    country: 'US',
    city: 'Pinto',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.5725,
    longitude: -78.844,
    postal_code: '21556'
  },
  '21557': {
    country: 'US',
    city: 'Rawlings',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.5214,
    longitude: -78.9062,
    postal_code: '21557'
  },
  '21560': {
    country: 'US',
    city: 'Spring Gap',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.5654,
    longitude: -78.7164,
    postal_code: '21560'
  },
  '21561': {
    country: 'US',
    city: 'Swanton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Garrett',
    latitude: 39.4764,
    longitude: -79.2402,
    postal_code: '21561'
  },
  '21562': {
    country: 'US',
    city: 'Westernport',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.4905,
    longitude: -79.014,
    postal_code: '21562'
  },
  '21601': {
    country: 'US',
    city: 'Easton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.7768,
    longitude: -76.0758,
    postal_code: '21601'
  },
  '21607': {
    country: 'US',
    city: 'Barclay',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 39.1299,
    longitude: -75.8601,
    postal_code: '21607'
  },
  '21609': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 38.7406,
    longitude: -75.9587,
    postal_code: '21609'
  },
  '21610': {
    country: 'US',
    city: 'Betterton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Kent',
    latitude: 39.3655,
    longitude: -76.0639,
    postal_code: '21610'
  },
  '21612': {
    country: 'US',
    city: 'Bozman',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.7515,
    longitude: -76.2764,
    postal_code: '21612'
  },
  '21613': {
    country: 'US',
    city: 'Cambridge',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.5643,
    longitude: -76.0874,
    postal_code: '21613'
  },
  '21617': {
    country: 'US',
    city: 'Centreville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 39.0564,
    longitude: -76.045,
    postal_code: '21617'
  },
  '21619': {
    country: 'US',
    city: 'Chester',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 38.9583,
    longitude: -76.2842,
    postal_code: '21619'
  },
  '21620': {
    country: 'US',
    city: 'Chestertown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Kent',
    latitude: 39.2125,
    longitude: -76.0802,
    postal_code: '21620'
  },
  '21622': {
    country: 'US',
    city: 'Church Creek',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.4278,
    longitude: -76.1696,
    postal_code: '21622'
  },
  '21623': {
    country: 'US',
    city: 'Church Hill',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 39.146,
    longitude: -75.988,
    postal_code: '21623'
  },
  '21624': {
    country: 'US',
    city: 'Claiborne',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.8368,
    longitude: -76.2714,
    postal_code: '21624'
  },
  '21625': {
    country: 'US',
    city: 'Cordova',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.8704,
    longitude: -76.0029,
    postal_code: '21625'
  },
  '21626': {
    country: 'US',
    city: 'Crapo',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.3295,
    longitude: -76.1142,
    postal_code: '21626'
  },
  '21627': {
    country: 'US',
    city: 'Crocheron',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.2426,
    longitude: -76.0531,
    postal_code: '21627'
  },
  '21628': {
    country: 'US',
    city: 'Crumpton',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 39.233,
    longitude: -75.9195,
    postal_code: '21628'
  },
  '21629': {
    country: 'US',
    city: 'Denton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 38.8595,
    longitude: -75.8357,
    postal_code: '21629'
  },
  '21631': {
    country: 'US',
    city: 'East New Market',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.5921,
    longitude: -75.9568,
    postal_code: '21631'
  },
  '21632': {
    country: 'US',
    city: 'Federalsburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 38.7147,
    longitude: -75.7754,
    postal_code: '21632'
  },
  '21634': {
    country: 'US',
    city: 'Fishing Creek',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.3223,
    longitude: -76.2244,
    postal_code: '21634'
  },
  '21635': {
    country: 'US',
    city: 'Galena',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Kent',
    latitude: 39.3374,
    longitude: -75.8717,
    postal_code: '21635'
  },
  '21636': {
    country: 'US',
    city: 'Goldsboro',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 39.023,
    longitude: -75.7926,
    postal_code: '21636'
  },
  '21638': {
    country: 'US',
    city: 'Grasonville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 38.9456,
    longitude: -76.1997,
    postal_code: '21638'
  },
  '21639': {
    country: 'US',
    city: 'Greensboro',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 38.9616,
    longitude: -75.8059,
    postal_code: '21639'
  },
  '21640': {
    country: 'US',
    city: 'Henderson',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 39.0672,
    longitude: -75.7948,
    postal_code: '21640'
  },
  '21641': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 38.9206,
    longitude: -75.9388,
    postal_code: '21641'
  },
  '21643': {
    country: 'US',
    city: 'Hurlock',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.6438,
    longitude: -75.863,
    postal_code: '21643'
  },
  '21644': {
    country: 'US',
    city: 'Ingleside',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 39.1182,
    longitude: -75.8769,
    postal_code: '21644'
  },
  '21645': {
    country: 'US',
    city: 'Kennedyville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Kent',
    latitude: 39.2978,
    longitude: -75.9818,
    postal_code: '21645'
  },
  '21647': {
    country: 'US',
    city: 'Mcdaniel',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.8192,
    longitude: -76.2806,
    postal_code: '21647'
  },
  '21648': {
    country: 'US',
    city: 'Madison',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.4782,
    longitude: -76.2412,
    postal_code: '21648'
  },
  '21649': {
    country: 'US',
    city: 'Marydel',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 39.1082,
    longitude: -75.7622,
    postal_code: '21649'
  },
  '21650': {
    country: 'US',
    city: 'Massey',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Kent',
    latitude: 39.3126,
    longitude: -75.8215,
    postal_code: '21650'
  },
  '21651': {
    country: 'US',
    city: 'Millington',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Kent',
    latitude: 39.2743,
    longitude: -75.8951,
    postal_code: '21651'
  },
  '21652': {
    country: 'US',
    city: 'Neavitt',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.7246,
    longitude: -76.2824,
    postal_code: '21652'
  },
  '21653': {
    country: 'US',
    city: 'Newcomb',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.7518,
    longitude: -76.178,
    postal_code: '21653'
  },
  '21654': {
    country: 'US',
    city: 'Oxford',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.6864,
    longitude: -76.1538,
    postal_code: '21654'
  },
  '21655': {
    country: 'US',
    city: 'Preston',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 38.7465,
    longitude: -75.9163,
    postal_code: '21655'
  },
  '21656': {
    country: 'US',
    city: 'Price',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 39.0346,
    longitude: -76.0921,
    postal_code: '21656'
  },
  '21657': {
    country: 'US',
    city: 'Queen Anne',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 38.9456,
    longitude: -75.9777,
    postal_code: '21657'
  },
  '21658': {
    country: 'US',
    city: 'Queenstown',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 39.0025,
    longitude: -76.1424,
    postal_code: '21658'
  },
  '21659': {
    country: 'US',
    city: 'Rhodesdale',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.603,
    longitude: -75.7749,
    postal_code: '21659'
  },
  '21660': {
    country: 'US',
    city: 'Ridgely',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 38.9568,
    longitude: -75.8848,
    postal_code: '21660'
  },
  '21661': {
    country: 'US',
    city: 'Rock Hall',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Kent',
    latitude: 39.1344,
    longitude: -76.2305,
    postal_code: '21661'
  },
  '21662': {
    country: 'US',
    city: 'Royal Oak',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.714,
    longitude: -76.2097,
    postal_code: '21662'
  },
  '21663': {
    country: 'US',
    city: 'Saint Michaels',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.783,
    longitude: -76.2215,
    postal_code: '21663'
  },
  '21664': {
    country: 'US',
    city: 'Secretary',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.6093,
    longitude: -75.9474,
    postal_code: '21664'
  },
  '21665': {
    country: 'US',
    city: 'Sherwood',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.7374,
    longitude: -76.3278,
    postal_code: '21665'
  },
  '21666': {
    country: 'US',
    city: 'Stevensville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 38.9394,
    longitude: -76.3371,
    postal_code: '21666'
  },
  '21667': {
    country: 'US',
    city: 'Still Pond',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Kent',
    latitude: 39.3293,
    longitude: -76.0455,
    postal_code: '21667'
  },
  '21668': {
    country: 'US',
    city: 'Sudlersville',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 39.1823,
    longitude: -75.85,
    postal_code: '21668'
  },
  '21669': {
    country: 'US',
    city: 'Taylors Island',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.4631,
    longitude: -76.2964,
    postal_code: '21669'
  },
  '21670': {
    country: 'US',
    city: 'Templeville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Caroline',
    latitude: 39.1362,
    longitude: -75.766,
    postal_code: '21670'
  },
  '21671': {
    country: 'US',
    city: 'Tilghman',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.7063,
    longitude: -76.3377,
    postal_code: '21671'
  },
  '21672': {
    country: 'US',
    city: 'Toddville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.2726,
    longitude: -76.0596,
    postal_code: '21672'
  },
  '21673': {
    country: 'US',
    city: 'Trappe',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.6647,
    longitude: -76.0507,
    postal_code: '21673'
  },
  '21675': {
    country: 'US',
    city: 'Wingate',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.2899,
    longitude: -76.0863,
    postal_code: '21675'
  },
  '21676': {
    country: 'US',
    city: 'Wittman',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.7846,
    longitude: -76.3001,
    postal_code: '21676'
  },
  '21677': {
    country: 'US',
    city: 'Woolford',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.5026,
    longitude: -76.1833,
    postal_code: '21677'
  },
  '21678': {
    country: 'US',
    city: 'Worton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Kent',
    latitude: 39.2963,
    longitude: -76.1008,
    postal_code: '21678'
  },
  '21679': {
    country: 'US',
    city: 'Wye Mills',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Talbot',
    latitude: 38.9281,
    longitude: -76.0814,
    postal_code: '21679'
  },
  '21690': {
    country: 'US',
    city: 'Chestertown',
    state: 'Maryland',
    state_short: 'MD',
    county: "Queen Anne's",
    latitude: 39.0346,
    longitude: -76.0921,
    postal_code: '21690'
  },
  '21701': {
    country: 'US',
    city: 'Frederick',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.4461,
    longitude: -77.335,
    postal_code: '21701'
  },
  '21702': {
    country: 'US',
    city: 'Frederick',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.4926,
    longitude: -77.4612,
    postal_code: '21702'
  },
  '21703': {
    country: 'US',
    city: 'Frederick',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.3647,
    longitude: -77.4636,
    postal_code: '21703'
  },
  '21704': {
    country: 'US',
    city: 'Frederick',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.3455,
    longitude: -77.3832,
    postal_code: '21704'
  },
  '21705': {
    country: 'US',
    city: 'Frederick',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.4143,
    longitude: -77.4105,
    postal_code: '21705'
  },
  '21709': {
    country: 'US',
    city: 'Frederick',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.47,
    longitude: -77.3921,
    postal_code: '21709'
  },
  '21710': {
    country: 'US',
    city: 'Adamstown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.291,
    longitude: -77.4552,
    postal_code: '21710'
  },
  '21711': {
    country: 'US',
    city: 'Big Pool',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6457,
    longitude: -78.0104,
    postal_code: '21711'
  },
  '21713': {
    country: 'US',
    city: 'Boonsboro',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.552,
    longitude: -77.6957,
    postal_code: '21713'
  },
  '21714': {
    country: 'US',
    city: 'Braddock Heights',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.4203,
    longitude: -77.5051,
    postal_code: '21714'
  },
  '21715': {
    country: 'US',
    city: 'Brownsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.3869,
    longitude: -77.658,
    postal_code: '21715'
  },
  '21716': {
    country: 'US',
    city: 'Brunswick',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.3164,
    longitude: -77.623,
    postal_code: '21716'
  },
  '21717': {
    country: 'US',
    city: 'Buckeystown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.3309,
    longitude: -77.4274,
    postal_code: '21717'
  },
  '21718': {
    country: 'US',
    city: 'Burkittsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.3923,
    longitude: -77.6275,
    postal_code: '21718'
  },
  '21719': {
    country: 'US',
    city: 'Cascade',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6958,
    longitude: -77.4955,
    postal_code: '21719'
  },
  '21720': {
    country: 'US',
    city: 'Cavetown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6473,
    longitude: -77.5842,
    postal_code: '21720'
  },
  '21721': {
    country: 'US',
    city: 'Chewsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6425,
    longitude: -77.6372,
    postal_code: '21721'
  },
  '21722': {
    country: 'US',
    city: 'Clear Spring',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6658,
    longitude: -77.9064,
    postal_code: '21722'
  },
  '21723': {
    country: 'US',
    city: 'Cooksville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.3211,
    longitude: -77.0051,
    postal_code: '21723'
  },
  '21727': {
    country: 'US',
    city: 'Emmitsburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.694,
    longitude: -77.3357,
    postal_code: '21727'
  },
  '21733': {
    country: 'US',
    city: 'Fairplay',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.5594,
    longitude: -77.7604,
    postal_code: '21733'
  },
  '21734': {
    country: 'US',
    city: 'Funkstown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.609,
    longitude: -77.7044,
    postal_code: '21734'
  },
  '21737': {
    country: 'US',
    city: 'Glenelg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2546,
    longitude: -77.0198,
    postal_code: '21737'
  },
  '21738': {
    country: 'US',
    city: 'Glenwood',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2795,
    longitude: -77.0148,
    postal_code: '21738'
  },
  '21740': {
    country: 'US',
    city: 'Hagerstown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.632,
    longitude: -77.7372,
    postal_code: '21740'
  },
  '21741': {
    country: 'US',
    city: 'Hagerstown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6418,
    longitude: -77.72,
    postal_code: '21741'
  },
  '21742': {
    country: 'US',
    city: 'Hagerstown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6573,
    longitude: -77.6921,
    postal_code: '21742'
  },
  '21746': {
    country: 'US',
    city: 'Hagerstown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.5638,
    longitude: -77.7206,
    postal_code: '21746'
  },
  '21747': {
    country: 'US',
    city: 'Hagerstown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6418,
    longitude: -77.72,
    postal_code: '21747'
  },
  '21749': {
    country: 'US',
    city: 'Hagerstown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6418,
    longitude: -77.72,
    postal_code: '21749'
  },
  '21750': {
    country: 'US',
    city: 'Hancock',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6991,
    longitude: -78.1762,
    postal_code: '21750'
  },
  '21754': {
    country: 'US',
    city: 'Ijamsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.3267,
    longitude: -77.2964,
    postal_code: '21754'
  },
  '21755': {
    country: 'US',
    city: 'Jefferson',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.3653,
    longitude: -77.5441,
    postal_code: '21755'
  },
  '21756': {
    country: 'US',
    city: 'Keedysville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.4563,
    longitude: -77.6944,
    postal_code: '21756'
  },
  '21757': {
    country: 'US',
    city: 'Keymar',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.5656,
    longitude: -77.2817,
    postal_code: '21757'
  },
  '21758': {
    country: 'US',
    city: 'Knoxville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.3479,
    longitude: -77.6513,
    postal_code: '21758'
  },
  '21759': {
    country: 'US',
    city: 'Ladiesburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.5694,
    longitude: -77.2905,
    postal_code: '21759'
  },
  '21762': {
    country: 'US',
    city: 'Libertytown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.4822,
    longitude: -77.2468,
    postal_code: '21762'
  },
  '21765': {
    country: 'US',
    city: 'Lisbon',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.3378,
    longitude: -77.072,
    postal_code: '21765'
  },
  '21766': {
    country: 'US',
    city: 'Little Orleans',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Allegany',
    latitude: 39.6876,
    longitude: -78.3781,
    postal_code: '21766'
  },
  '21767': {
    country: 'US',
    city: 'Maugansville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.6996,
    longitude: -77.7499,
    postal_code: '21767'
  },
  '21769': {
    country: 'US',
    city: 'Middletown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.4416,
    longitude: -77.5502,
    postal_code: '21769'
  },
  '21770': {
    country: 'US',
    city: 'Monrovia',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.3512,
    longitude: -77.2494,
    postal_code: '21770'
  },
  '21771': {
    country: 'US',
    city: 'Mount Airy',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.3881,
    longitude: -77.1723,
    postal_code: '21771'
  },
  '21773': {
    country: 'US',
    city: 'Myersville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.5282,
    longitude: -77.5513,
    postal_code: '21773'
  },
  '21774': {
    country: 'US',
    city: 'New Market',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.4096,
    longitude: -77.2759,
    postal_code: '21774'
  },
  '21775': {
    country: 'US',
    city: 'New Midway',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.5645,
    longitude: -77.2947,
    postal_code: '21775'
  },
  '21776': {
    country: 'US',
    city: 'New Windsor',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.5162,
    longitude: -77.1034,
    postal_code: '21776'
  },
  '21777': {
    country: 'US',
    city: 'Point Of Rocks',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.2791,
    longitude: -77.5328,
    postal_code: '21777'
  },
  '21778': {
    country: 'US',
    city: 'Rocky Ridge',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.6057,
    longitude: -77.3296,
    postal_code: '21778'
  },
  '21779': {
    country: 'US',
    city: 'Rohrersville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.4431,
    longitude: -77.658,
    postal_code: '21779'
  },
  '21780': {
    country: 'US',
    city: 'Sabillasville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.6828,
    longitude: -77.4693,
    postal_code: '21780'
  },
  '21781': {
    country: 'US',
    city: 'Saint James',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.5699,
    longitude: -77.7607,
    postal_code: '21781'
  },
  '21782': {
    country: 'US',
    city: 'Sharpsburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.4424,
    longitude: -77.7511,
    postal_code: '21782'
  },
  '21783': {
    country: 'US',
    city: 'Smithsburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.647,
    longitude: -77.5706,
    postal_code: '21783'
  },
  '21784': {
    country: 'US',
    city: 'Sykesville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.4567,
    longitude: -76.9696,
    postal_code: '21784'
  },
  '21787': {
    country: 'US',
    city: 'Taneytown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.6658,
    longitude: -77.1691,
    postal_code: '21787'
  },
  '21788': {
    country: 'US',
    city: 'Thurmont',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.6109,
    longitude: -77.3989,
    postal_code: '21788'
  },
  '21790': {
    country: 'US',
    city: 'Tuscarora',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.2667,
    longitude: -77.5101,
    postal_code: '21790'
  },
  '21791': {
    country: 'US',
    city: 'Union Bridge',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.5799,
    longitude: -77.1319,
    postal_code: '21791'
  },
  '21792': {
    country: 'US',
    city: 'Unionville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.4748,
    longitude: -77.1855,
    postal_code: '21792'
  },
  '21793': {
    country: 'US',
    city: 'Walkersville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.4787,
    longitude: -77.3484,
    postal_code: '21793'
  },
  '21794': {
    country: 'US',
    city: 'West Friendship',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Howard',
    latitude: 39.2934,
    longitude: -76.966,
    postal_code: '21794'
  },
  '21795': {
    country: 'US',
    city: 'Williamsport',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Washington',
    latitude: 39.593,
    longitude: -77.8087,
    postal_code: '21795'
  },
  '21797': {
    country: 'US',
    city: 'Woodbine',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Carroll',
    latitude: 39.3464,
    longitude: -77.0647,
    postal_code: '21797'
  },
  '21798': {
    country: 'US',
    city: 'Woodsboro',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Frederick',
    latitude: 39.5311,
    longitude: -77.2972,
    postal_code: '21798'
  },
  '21801': {
    country: 'US',
    city: 'Salisbury',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.3824,
    longitude: -75.6336,
    postal_code: '21801'
  },
  '21802': {
    country: 'US',
    city: 'Salisbury',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.3884,
    longitude: -75.6276,
    postal_code: '21802'
  },
  '21803': {
    country: 'US',
    city: 'Salisbury',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.3884,
    longitude: -75.6276,
    postal_code: '21803'
  },
  '21804': {
    country: 'US',
    city: 'Salisbury',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.3508,
    longitude: -75.5338,
    postal_code: '21804'
  },
  '21810': {
    country: 'US',
    city: 'Allen',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.2873,
    longitude: -75.688,
    postal_code: '21810'
  },
  '21811': {
    country: 'US',
    city: 'Berlin',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.3475,
    longitude: -75.1866,
    postal_code: '21811'
  },
  '21813': {
    country: 'US',
    city: 'Bishopville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.4296,
    longitude: -75.1855,
    postal_code: '21813'
  },
  '21814': {
    country: 'US',
    city: 'Bivalve',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.2953,
    longitude: -75.8914,
    postal_code: '21814'
  },
  '21817': {
    country: 'US',
    city: 'Crisfield',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 37.9845,
    longitude: -75.8429,
    postal_code: '21817'
  },
  '21821': {
    country: 'US',
    city: 'Deal Island',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 38.1533,
    longitude: -75.9496,
    postal_code: '21821'
  },
  '21822': {
    country: 'US',
    city: 'Eden',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.2807,
    longitude: -75.651,
    postal_code: '21822'
  },
  '21824': {
    country: 'US',
    city: 'Ewell',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 37.9938,
    longitude: -76.0351,
    postal_code: '21824'
  },
  '21826': {
    country: 'US',
    city: 'Fruitland',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.3225,
    longitude: -75.6228,
    postal_code: '21826'
  },
  '21829': {
    country: 'US',
    city: 'Girdletree',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.0958,
    longitude: -75.3902,
    postal_code: '21829'
  },
  '21830': {
    country: 'US',
    city: 'Hebron',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.4026,
    longitude: -75.6963,
    postal_code: '21830'
  },
  '21835': {
    country: 'US',
    city: 'Linkwood',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.5403,
    longitude: -75.963,
    postal_code: '21835'
  },
  '21836': {
    country: 'US',
    city: 'Manokin',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 38.1154,
    longitude: -75.7558,
    postal_code: '21836'
  },
  '21837': {
    country: 'US',
    city: 'Mardela Springs',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.4864,
    longitude: -75.7414,
    postal_code: '21837'
  },
  '21838': {
    country: 'US',
    city: 'Marion Station',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 38.0266,
    longitude: -75.7579,
    postal_code: '21838'
  },
  '21840': {
    country: 'US',
    city: 'Nanticoke',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.2672,
    longitude: -75.9021,
    postal_code: '21840'
  },
  '21841': {
    country: 'US',
    city: 'Newark',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.2489,
    longitude: -75.2893,
    postal_code: '21841'
  },
  '21842': {
    country: 'US',
    city: 'Ocean City',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.3365,
    longitude: -75.0849,
    postal_code: '21842'
  },
  '21843': {
    country: 'US',
    city: 'Ocean City',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.3365,
    longitude: -75.0849,
    postal_code: '21843'
  },
  '21849': {
    country: 'US',
    city: 'Parsonsburg',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.3914,
    longitude: -75.4737,
    postal_code: '21849'
  },
  '21850': {
    country: 'US',
    city: 'Pittsville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.3755,
    longitude: -75.4076,
    postal_code: '21850'
  },
  '21851': {
    country: 'US',
    city: 'Pocomoke City',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.0714,
    longitude: -75.555,
    postal_code: '21851'
  },
  '21852': {
    country: 'US',
    city: 'Powellville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.3287,
    longitude: -75.3755,
    postal_code: '21852'
  },
  '21853': {
    country: 'US',
    city: 'Princess Anne',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 38.1919,
    longitude: -75.7072,
    postal_code: '21853'
  },
  '21856': {
    country: 'US',
    city: 'Quantico',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.3339,
    longitude: -75.7851,
    postal_code: '21856'
  },
  '21857': {
    country: 'US',
    city: 'Rehobeth',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 38.039,
    longitude: -75.663,
    postal_code: '21857'
  },
  '21861': {
    country: 'US',
    city: 'Sharptown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.5389,
    longitude: -75.7192,
    postal_code: '21861'
  },
  '21862': {
    country: 'US',
    city: 'Showell',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.4003,
    longitude: -75.2166,
    postal_code: '21862'
  },
  '21863': {
    country: 'US',
    city: 'Snow Hill',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.1868,
    longitude: -75.405,
    postal_code: '21863'
  },
  '21864': {
    country: 'US',
    city: 'Stockton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.0452,
    longitude: -75.4108,
    postal_code: '21864'
  },
  '21865': {
    country: 'US',
    city: 'Tyaskin',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.2833,
    longitude: -75.8465,
    postal_code: '21865'
  },
  '21866': {
    country: 'US',
    city: 'Tylerton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 37.9666,
    longitude: -76.0235,
    postal_code: '21866'
  },
  '21867': {
    country: 'US',
    city: 'Upper Fairmount',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 38.104,
    longitude: -75.7913,
    postal_code: '21867'
  },
  '21869': {
    country: 'US',
    city: 'Vienna',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Dorchester',
    latitude: 38.4774,
    longitude: -75.8729,
    postal_code: '21869'
  },
  '21871': {
    country: 'US',
    city: 'Westover',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 38.101,
    longitude: -75.7406,
    postal_code: '21871'
  },
  '21872': {
    country: 'US',
    city: 'Whaleyville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Worcester',
    latitude: 38.4121,
    longitude: -75.2811,
    postal_code: '21872'
  },
  '21874': {
    country: 'US',
    city: 'Willards',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.3939,
    longitude: -75.3552,
    postal_code: '21874'
  },
  '21875': {
    country: 'US',
    city: 'Delmar',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Wicomico',
    latitude: 38.4445,
    longitude: -75.5583,
    postal_code: '21875'
  },
  '21890': {
    country: 'US',
    city: 'Westover',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Somerset',
    latitude: 38.0927,
    longitude: -75.8882,
    postal_code: '21890'
  },
  '21901': {
    country: 'US',
    city: 'North East',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.6045,
    longitude: -75.9538,
    postal_code: '21901'
  },
  '21902': {
    country: 'US',
    city: 'Perry Point',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.553,
    longitude: -76.0725,
    postal_code: '21902'
  },
  '21903': {
    country: 'US',
    city: 'Perryville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.5649,
    longitude: -76.0592,
    postal_code: '21903'
  },
  '21904': {
    country: 'US',
    city: 'Port Deposit',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.6151,
    longitude: -76.0633,
    postal_code: '21904'
  },
  '21911': {
    country: 'US',
    city: 'Rising Sun',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.6882,
    longitude: -76.0492,
    postal_code: '21911'
  },
  '21912': {
    country: 'US',
    city: 'Warwick',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.4283,
    longitude: -75.7996,
    postal_code: '21912'
  },
  '21913': {
    country: 'US',
    city: 'Cecilton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.4015,
    longitude: -75.8654,
    postal_code: '21913'
  },
  '21914': {
    country: 'US',
    city: 'Charlestown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.5729,
    longitude: -75.9795,
    postal_code: '21914'
  },
  '21915': {
    country: 'US',
    city: 'Chesapeake City',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.5133,
    longitude: -75.8406,
    postal_code: '21915'
  },
  '21916': {
    country: 'US',
    city: 'Childs',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.6462,
    longitude: -75.8716,
    postal_code: '21916'
  },
  '21917': {
    country: 'US',
    city: 'Colora',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.6695,
    longitude: -76.0934,
    postal_code: '21917'
  },
  '21918': {
    country: 'US',
    city: 'Conowingo',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.6778,
    longitude: -76.1572,
    postal_code: '21918'
  },
  '21919': {
    country: 'US',
    city: 'Earleville',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.4271,
    longitude: -75.9403,
    postal_code: '21919'
  },
  '21920': {
    country: 'US',
    city: 'Elk Mills',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.658,
    longitude: -75.8282,
    postal_code: '21920'
  },
  '21921': {
    country: 'US',
    city: 'Elkton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.6264,
    longitude: -75.8458,
    postal_code: '21921'
  },
  '21922': {
    country: 'US',
    city: 'Elkton',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.6068,
    longitude: -75.8333,
    postal_code: '21922'
  },
  '21930': {
    country: 'US',
    city: 'Georgetown',
    state: 'Maryland',
    state_short: 'MD',
    county: 'Cecil',
    latitude: 39.3662,
    longitude: -75.8845,
    postal_code: '21930'
  },
  '22003': {
    country: 'US',
    city: 'Annandale',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8307,
    longitude: -77.2142,
    postal_code: '22003'
  },
  '22009': {
    country: 'US',
    city: 'Burke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22009'
  },
  '22015': {
    country: 'US',
    city: 'Burke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7894,
    longitude: -77.2818,
    postal_code: '22015'
  },
  '22025': {
    country: 'US',
    city: 'Dumfries',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.5993,
    longitude: -77.3403,
    postal_code: '22025'
  },
  '22026': {
    country: 'US',
    city: 'Dumfries',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.5669,
    longitude: -77.2921,
    postal_code: '22026'
  },
  '22027': {
    country: 'US',
    city: 'Dunn Loring',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8954,
    longitude: -77.2214,
    postal_code: '22027'
  },
  '22030': {
    country: 'US',
    city: 'Fairfax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax (city)',
    latitude: 38.8458,
    longitude: -77.3242,
    postal_code: '22030'
  },
  '22031': {
    country: 'US',
    city: 'Fairfax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax (city)',
    latitude: 38.8604,
    longitude: -77.2649,
    postal_code: '22031'
  },
  '22032': {
    country: 'US',
    city: 'Fairfax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8177,
    longitude: -77.2925,
    postal_code: '22032'
  },
  '22033': {
    country: 'US',
    city: 'Fairfax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8776,
    longitude: -77.3885,
    postal_code: '22033'
  },
  '22034': {
    country: 'US',
    city: 'Fairfax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22034'
  },
  '22035': {
    country: 'US',
    city: 'Fairfax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8557,
    longitude: -77.3616,
    postal_code: '22035'
  },
  '22036': {
    country: 'US',
    city: 'Fairfax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7351,
    longitude: -77.0797,
    postal_code: '22036'
  },
  '22037': {
    country: 'US',
    city: 'Fairfax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22037'
  },
  '22038': {
    country: 'US',
    city: 'Fairfax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax (city)',
    latitude: 38.8528,
    longitude: -77.302,
    postal_code: '22038'
  },
  '22039': {
    country: 'US',
    city: 'Fairfax Station',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7602,
    longitude: -77.3064,
    postal_code: '22039'
  },
  '22040': {
    country: 'US',
    city: 'Falls Church',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Falls Church (city)',
    latitude: 38.8842,
    longitude: -77.1718,
    postal_code: '22040'
  },
  '22041': {
    country: 'US',
    city: 'Falls Church',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8502,
    longitude: -77.1448,
    postal_code: '22041'
  },
  '22042': {
    country: 'US',
    city: 'Falls Church',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8635,
    longitude: -77.1939,
    postal_code: '22042'
  },
  '22043': {
    country: 'US',
    city: 'Falls Church',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8994,
    longitude: -77.1895,
    postal_code: '22043'
  },
  '22044': {
    country: 'US',
    city: 'Falls Church',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8589,
    longitude: -77.1548,
    postal_code: '22044'
  },
  '22046': {
    country: 'US',
    city: 'Falls Church',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Falls Church (city)',
    latitude: 38.8856,
    longitude: -77.1802,
    postal_code: '22046'
  },
  '22060': {
    country: 'US',
    city: 'Fort Belvoir',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.6947,
    longitude: -77.1433,
    postal_code: '22060'
  },
  '22066': {
    country: 'US',
    city: 'Great Falls',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 39.0039,
    longitude: -77.3083,
    postal_code: '22066'
  },
  '22067': {
    country: 'US',
    city: 'Greenway',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9645,
    longitude: -77.2331,
    postal_code: '22067'
  },
  '22079': {
    country: 'US',
    city: 'Lorton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.6929,
    longitude: -77.204,
    postal_code: '22079'
  },
  '22081': {
    country: 'US',
    city: 'Merrifield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8739,
    longitude: -77.2345,
    postal_code: '22081'
  },
  '22082': {
    country: 'US',
    city: 'Merrifield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8743,
    longitude: -77.2269,
    postal_code: '22082'
  },
  '22095': {
    country: 'US',
    city: 'Herndon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9696,
    longitude: -77.3861,
    postal_code: '22095'
  },
  '22096': {
    country: 'US',
    city: 'Reston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22096'
  },
  '22101': {
    country: 'US',
    city: 'Mc Lean',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9326,
    longitude: -77.1706,
    postal_code: '22101'
  },
  '22102': {
    country: 'US',
    city: 'Mc Lean',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.953,
    longitude: -77.2295,
    postal_code: '22102'
  },
  '22103': {
    country: 'US',
    city: 'West Mclean',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22103'
  },
  '22106': {
    country: 'US',
    city: 'Mc Lean',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9335,
    longitude: -77.1797,
    postal_code: '22106'
  },
  '22107': {
    country: 'US',
    city: 'Mc Lean',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9321,
    longitude: -77.1841,
    postal_code: '22107'
  },
  '22108': {
    country: 'US',
    city: 'Mc Lean',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9321,
    longitude: -77.1841,
    postal_code: '22108'
  },
  '22109': {
    country: 'US',
    city: 'Mc Lean',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.9335,
    longitude: -77.1797,
    postal_code: '22109'
  },
  '22116': {
    country: 'US',
    city: 'Merrifield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8715,
    longitude: -77.2344,
    postal_code: '22116'
  },
  '22118': {
    country: 'US',
    city: 'Merrifield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22118'
  },
  '22119': {
    country: 'US',
    city: 'Merrifield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22119'
  },
  '22121': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7192,
    longitude: -77.1073,
    postal_code: '22121'
  },
  '22122': {
    country: 'US',
    city: 'Newington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7384,
    longitude: -77.185,
    postal_code: '22122'
  },
  '22124': {
    country: 'US',
    city: 'Oakton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8852,
    longitude: -77.3233,
    postal_code: '22124'
  },
  '22125': {
    country: 'US',
    city: 'Occoquan',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.6816,
    longitude: -77.2605,
    postal_code: '22125'
  },
  '22134': {
    country: 'US',
    city: 'Quantico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.531,
    longitude: -77.3358,
    postal_code: '22134'
  },
  '22135': {
    country: 'US',
    city: 'Quantico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.5223,
    longitude: -77.2936,
    postal_code: '22135'
  },
  '22150': {
    country: 'US',
    city: 'Springfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7797,
    longitude: -77.1866,
    postal_code: '22150'
  },
  '22151': {
    country: 'US',
    city: 'Springfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8029,
    longitude: -77.2116,
    postal_code: '22151'
  },
  '22152': {
    country: 'US',
    city: 'Springfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7757,
    longitude: -77.2337,
    postal_code: '22152'
  },
  '22153': {
    country: 'US',
    city: 'Springfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7449,
    longitude: -77.237,
    postal_code: '22153'
  },
  '22156': {
    country: 'US',
    city: 'Springfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22156'
  },
  '22158': {
    country: 'US',
    city: 'Springfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22158'
  },
  '22159': {
    country: 'US',
    city: 'Springfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22159'
  },
  '22160': {
    country: 'US',
    city: 'Springfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22160'
  },
  '22161': {
    country: 'US',
    city: 'Springfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7893,
    longitude: -77.1872,
    postal_code: '22161'
  },
  '22172': {
    country: 'US',
    city: 'Triangle',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.5509,
    longitude: -77.3229,
    postal_code: '22172'
  },
  '22180': {
    country: 'US',
    city: 'Vienna',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8935,
    longitude: -77.2532,
    postal_code: '22180'
  },
  '22181': {
    country: 'US',
    city: 'Vienna',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8977,
    longitude: -77.288,
    postal_code: '22181'
  },
  '22182': {
    country: 'US',
    city: 'Vienna',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.928,
    longitude: -77.2649,
    postal_code: '22182'
  },
  '22183': {
    country: 'US',
    city: 'Vienna',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22183'
  },
  '22185': {
    country: 'US',
    city: 'Vienna',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8318,
    longitude: -77.2888,
    postal_code: '22185'
  },
  '22191': {
    country: 'US',
    city: 'Woodbridge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.6356,
    longitude: -77.2683,
    postal_code: '22191'
  },
  '22192': {
    country: 'US',
    city: 'Woodbridge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.676,
    longitude: -77.3163,
    postal_code: '22192'
  },
  '22193': {
    country: 'US',
    city: 'Woodbridge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.6438,
    longitude: -77.3451,
    postal_code: '22193'
  },
  '22194': {
    country: 'US',
    city: 'Woodbridge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.6582,
    longitude: -77.2497,
    postal_code: '22194'
  },
  '22195': {
    country: 'US',
    city: 'Woodbridge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince William',
    latitude: 38.6582,
    longitude: -77.2497,
    postal_code: '22195'
  },
  '22199': {
    country: 'US',
    city: 'Lorton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7043,
    longitude: -77.2278,
    postal_code: '22199'
  },
  '22201': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8871,
    longitude: -77.0932,
    postal_code: '22201'
  },
  '22202': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8565,
    longitude: -77.0592,
    postal_code: '22202'
  },
  '22203': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8738,
    longitude: -77.1142,
    postal_code: '22203'
  },
  '22204': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.859,
    longitude: -77.0997,
    postal_code: '22204'
  },
  '22205': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8836,
    longitude: -77.1395,
    postal_code: '22205'
  },
  '22206': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8415,
    longitude: -77.0905,
    postal_code: '22206'
  },
  '22207': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.9033,
    longitude: -77.1263,
    postal_code: '22207'
  },
  '22209': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8926,
    longitude: -77.0753,
    postal_code: '22209'
  },
  '22210': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8808,
    longitude: -77.1129,
    postal_code: '22210'
  },
  '22211': {
    country: 'US',
    city: 'Fort Myer',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8921,
    longitude: -77.0794,
    postal_code: '22211'
  },
  '22212': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8808,
    longitude: -77.1129,
    postal_code: '22212'
  },
  '22213': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8954,
    longitude: -77.1633,
    postal_code: '22213'
  },
  '22214': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8688,
    longitude: -77.0739,
    postal_code: '22214'
  },
  '22215': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8808,
    longitude: -77.1129,
    postal_code: '22215'
  },
  '22216': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8808,
    longitude: -77.1129,
    postal_code: '22216'
  },
  '22217': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8808,
    longitude: -77.1129,
    postal_code: '22217'
  },
  '22219': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8808,
    longitude: -77.1129,
    postal_code: '22219'
  },
  '22225': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8808,
    longitude: -77.1129,
    postal_code: '22225'
  },
  '22226': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8834,
    longitude: -77.1028,
    postal_code: '22226'
  },
  '22227': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8808,
    longitude: -77.1129,
    postal_code: '22227'
  },
  '22230': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8797,
    longitude: -77.1108,
    postal_code: '22230'
  },
  '22240': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8566,
    longitude: -77.0518,
    postal_code: '22240'
  },
  '22241': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8808,
    longitude: -77.1129,
    postal_code: '22241'
  },
  '22242': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8509,
    longitude: -77.0523,
    postal_code: '22242'
  },
  '22243': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8605,
    longitude: -77.0516,
    postal_code: '22243'
  },
  '22244': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8545,
    longitude: -77.052,
    postal_code: '22244'
  },
  '22245': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8518,
    longitude: -77.0523,
    postal_code: '22245'
  },
  '22246': {
    country: 'US',
    city: 'Arlington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Arlington',
    latitude: 38.8808,
    longitude: -77.1129,
    postal_code: '22246'
  },
  '22301': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.82,
    longitude: -77.0589,
    postal_code: '22301'
  },
  '22302': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.8276,
    longitude: -77.0896,
    postal_code: '22302'
  },
  '22303': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7912,
    longitude: -77.0814,
    postal_code: '22303'
  },
  '22304': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.8149,
    longitude: -77.121,
    postal_code: '22304'
  },
  '22305': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.8372,
    longitude: -77.064,
    postal_code: '22305'
  },
  '22306': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7589,
    longitude: -77.0873,
    postal_code: '22306'
  },
  '22307': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7714,
    longitude: -77.0657,
    postal_code: '22307'
  },
  '22308': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7316,
    longitude: -77.0604,
    postal_code: '22308'
  },
  '22309': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7192,
    longitude: -77.1073,
    postal_code: '22309'
  },
  '22310': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7794,
    longitude: -77.1194,
    postal_code: '22310'
  },
  '22311': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.832,
    longitude: -77.12,
    postal_code: '22311'
  },
  '22312': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.8191,
    longitude: -77.1484,
    postal_code: '22312'
  },
  '22313': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.8158,
    longitude: -77.0901,
    postal_code: '22313'
  },
  '22314': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.806,
    longitude: -77.0529,
    postal_code: '22314'
  },
  '22315': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fairfax',
    latitude: 38.7596,
    longitude: -77.1485,
    postal_code: '22315'
  },
  '22320': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.8044,
    longitude: -77.0467,
    postal_code: '22320'
  },
  '22331': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.8013,
    longitude: -77.0707,
    postal_code: '22331'
  },
  '22332': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.8031,
    longitude: -77.0727,
    postal_code: '22332'
  },
  '22333': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.8158,
    longitude: -77.0901,
    postal_code: '22333'
  },
  '22334': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.8158,
    longitude: -77.0901,
    postal_code: '22334'
  },
  '22350': {
    country: 'US',
    city: 'Alexandria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Alexandria',
    latitude: 38.8045,
    longitude: -77.0509,
    postal_code: '22350'
  },
  '22401': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fredericksburg (city)',
    latitude: 38.2995,
    longitude: -77.4772,
    postal_code: '22401'
  },
  '22402': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fredericksburg (city)',
    latitude: 38.2996,
    longitude: -77.4897,
    postal_code: '22402'
  },
  '22403': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Stafford',
    latitude: 38.4173,
    longitude: -77.4608,
    postal_code: '22403'
  },
  '22404': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fredericksburg (city)',
    latitude: 38.3032,
    longitude: -77.4605,
    postal_code: '22404'
  },
  '22405': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Stafford',
    latitude: 38.3365,
    longitude: -77.4366,
    postal_code: '22405'
  },
  '22406': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Stafford',
    latitude: 38.3796,
    longitude: -77.5349,
    postal_code: '22406'
  },
  '22407': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Spotsylvania',
    latitude: 38.2688,
    longitude: -77.5476,
    postal_code: '22407'
  },
  '22408': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Spotsylvania',
    latitude: 38.2481,
    longitude: -77.4681,
    postal_code: '22408'
  },
  '22412': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Spotsylvania',
    latitude: 38.1847,
    longitude: -77.6626,
    postal_code: '22412'
  },
  '22427': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Caroline',
    latitude: 38.0137,
    longitude: -77.1802,
    postal_code: '22427'
  },
  '22428': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Caroline',
    latitude: 38.0496,
    longitude: -77.3466,
    postal_code: '22428'
  },
  '22430': {
    country: 'US',
    city: 'Brooke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Stafford',
    latitude: 38.3857,
    longitude: -77.3743,
    postal_code: '22430'
  },
  '22432': {
    country: 'US',
    city: 'Burgess',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northumberland',
    latitude: 37.8687,
    longitude: -76.3542,
    postal_code: '22432'
  },
  '22433': {
    country: 'US',
    city: 'Burr Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Orange',
    latitude: 38.3446,
    longitude: -77.8719,
    postal_code: '22433'
  },
  '22435': {
    country: 'US',
    city: 'Callao',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northumberland',
    latitude: 37.9773,
    longitude: -76.5732,
    postal_code: '22435'
  },
  '22436': {
    country: 'US',
    city: 'Caret',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Essex',
    latitude: 37.9826,
    longitude: -76.9614,
    postal_code: '22436'
  },
  '22437': {
    country: 'US',
    city: 'Center Cross',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Essex',
    latitude: 37.7929,
    longitude: -76.7548,
    postal_code: '22437'
  },
  '22438': {
    country: 'US',
    city: 'Champlain',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Essex',
    latitude: 38.0214,
    longitude: -76.9719,
    postal_code: '22438'
  },
  '22442': {
    country: 'US',
    city: 'Coles Point',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Westmoreland',
    latitude: 38.1437,
    longitude: -76.6355,
    postal_code: '22442'
  },
  '22443': {
    country: 'US',
    city: 'Colonial Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Westmoreland',
    latitude: 38.1792,
    longitude: -76.9959,
    postal_code: '22443'
  },
  '22446': {
    country: 'US',
    city: 'Corbin',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Caroline',
    latitude: 38.1996,
    longitude: -77.3889,
    postal_code: '22446'
  },
  '22448': {
    country: 'US',
    city: 'Dahlgren',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King George',
    latitude: 38.3375,
    longitude: -77.0429,
    postal_code: '22448'
  },
  '22451': {
    country: 'US',
    city: 'Dogue',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King George',
    latitude: 38.2321,
    longitude: -77.2158,
    postal_code: '22451'
  },
  '22454': {
    country: 'US',
    city: 'Dunnsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Essex',
    latitude: 37.8527,
    longitude: -76.8475,
    postal_code: '22454'
  },
  '22456': {
    country: 'US',
    city: 'Edwardsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northumberland',
    latitude: 37.9065,
    longitude: -76.3652,
    postal_code: '22456'
  },
  '22460': {
    country: 'US',
    city: 'Farnham',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Richmond',
    latitude: 37.874,
    longitude: -76.605,
    postal_code: '22460'
  },
  '22463': {
    country: 'US',
    city: 'Garrisonville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Stafford',
    latitude: 38.4684,
    longitude: -77.4612,
    postal_code: '22463'
  },
  '22469': {
    country: 'US',
    city: 'Hague',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Westmoreland',
    latitude: 38.0573,
    longitude: -76.6616,
    postal_code: '22469'
  },
  '22471': {
    country: 'US',
    city: 'Hartwood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Stafford',
    latitude: 38.3993,
    longitude: -77.5814,
    postal_code: '22471'
  },
  '22472': {
    country: 'US',
    city: 'Haynesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Richmond',
    latitude: 37.9505,
    longitude: -76.6367,
    postal_code: '22472'
  },
  '22473': {
    country: 'US',
    city: 'Heathsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northumberland',
    latitude: 37.9072,
    longitude: -76.4178,
    postal_code: '22473'
  },
  '22476': {
    country: 'US',
    city: 'Hustle',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Essex',
    latitude: 38.0329,
    longitude: -77.0633,
    postal_code: '22476'
  },
  '22480': {
    country: 'US',
    city: 'Irvington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lancaster',
    latitude: 37.6645,
    longitude: -76.416,
    postal_code: '22480'
  },
  '22481': {
    country: 'US',
    city: 'Jersey',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King George',
    latitude: 38.2115,
    longitude: -77.1394,
    postal_code: '22481'
  },
  '22482': {
    country: 'US',
    city: 'Kilmarnock',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lancaster',
    latitude: 37.7162,
    longitude: -76.382,
    postal_code: '22482'
  },
  '22485': {
    country: 'US',
    city: 'King George',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King George',
    latitude: 38.2811,
    longitude: -77.126,
    postal_code: '22485'
  },
  '22488': {
    country: 'US',
    city: 'Kinsale',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Westmoreland',
    latitude: 38.0505,
    longitude: -76.5855,
    postal_code: '22488'
  },
  '22501': {
    country: 'US',
    city: 'Ladysmith',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Caroline',
    latitude: 38.0176,
    longitude: -77.5153,
    postal_code: '22501'
  },
  '22503': {
    country: 'US',
    city: 'Lancaster',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lancaster',
    latitude: 37.7395,
    longitude: -76.5002,
    postal_code: '22503'
  },
  '22504': {
    country: 'US',
    city: 'Laneview',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Essex',
    latitude: 37.7681,
    longitude: -76.7117,
    postal_code: '22504'
  },
  '22507': {
    country: 'US',
    city: 'Lively',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lancaster',
    latitude: 37.7572,
    longitude: -76.5118,
    postal_code: '22507'
  },
  '22508': {
    country: 'US',
    city: 'Locust Grove',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Orange',
    latitude: 38.3352,
    longitude: -77.7709,
    postal_code: '22508'
  },
  '22509': {
    country: 'US',
    city: 'Loretto',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Essex',
    latitude: 38.0789,
    longitude: -77.0485,
    postal_code: '22509'
  },
  '22511': {
    country: 'US',
    city: 'Lottsburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northumberland',
    latitude: 37.9792,
    longitude: -76.5018,
    postal_code: '22511'
  },
  '22513': {
    country: 'US',
    city: 'Merry Point',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lancaster',
    latitude: 37.7338,
    longitude: -76.4825,
    postal_code: '22513'
  },
  '22514': {
    country: 'US',
    city: 'Milford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Caroline',
    latitude: 38.0058,
    longitude: -77.3185,
    postal_code: '22514'
  },
  '22517': {
    country: 'US',
    city: 'Mollusk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lancaster',
    latitude: 37.7301,
    longitude: -76.538,
    postal_code: '22517'
  },
  '22520': {
    country: 'US',
    city: 'Montross',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Westmoreland',
    latitude: 38.1105,
    longitude: -76.7828,
    postal_code: '22520'
  },
  '22523': {
    country: 'US',
    city: 'Morattico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lancaster',
    latitude: 37.7928,
    longitude: -76.6093,
    postal_code: '22523'
  },
  '22524': {
    country: 'US',
    city: 'Mount Holly',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Westmoreland',
    latitude: 38.1182,
    longitude: -76.6805,
    postal_code: '22524'
  },
  '22526': {
    country: 'US',
    city: 'Ninde',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King George',
    latitude: 38.2712,
    longitude: -77.0561,
    postal_code: '22526'
  },
  '22528': {
    country: 'US',
    city: 'Nuttsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lancaster',
    latitude: 37.7935,
    longitude: -76.5508,
    postal_code: '22528'
  },
  '22529': {
    country: 'US',
    city: 'Oldhams',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Westmoreland',
    latitude: 38.0181,
    longitude: -76.6861,
    postal_code: '22529'
  },
  '22530': {
    country: 'US',
    city: 'Ophelia',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northumberland',
    latitude: 37.9094,
    longitude: -76.2934,
    postal_code: '22530'
  },
  '22534': {
    country: 'US',
    city: 'Partlow',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Spotsylvania',
    latitude: 38.0641,
    longitude: -77.6586,
    postal_code: '22534'
  },
  '22535': {
    country: 'US',
    city: 'Port Royal',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Caroline',
    latitude: 38.1621,
    longitude: -77.1837,
    postal_code: '22535'
  },
  '22538': {
    country: 'US',
    city: 'Rappahannock Academy',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Caroline',
    latitude: 38.2079,
    longitude: -77.2502,
    postal_code: '22538'
  },
  '22539': {
    country: 'US',
    city: 'Reedville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northumberland',
    latitude: 37.857,
    longitude: -76.2829,
    postal_code: '22539'
  },
  '22542': {
    country: 'US',
    city: 'Rhoadesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Orange',
    latitude: 38.2863,
    longitude: -77.923,
    postal_code: '22542'
  },
  '22544': {
    country: 'US',
    city: 'Rollins Fork',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King George',
    latitude: 38.1849,
    longitude: -77.0622,
    postal_code: '22544'
  },
  '22545': {
    country: 'US',
    city: 'Ruby',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Stafford',
    latitude: 38.5086,
    longitude: -77.543,
    postal_code: '22545'
  },
  '22546': {
    country: 'US',
    city: 'Ruther Glen',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Caroline',
    latitude: 37.9451,
    longitude: -77.4714,
    postal_code: '22546'
  },
  '22547': {
    country: 'US',
    city: 'Sealston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King George',
    latitude: 38.2711,
    longitude: -77.1726,
    postal_code: '22547'
  },
  '22548': {
    country: 'US',
    city: 'Sharps',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Richmond',
    latitude: 37.824,
    longitude: -76.7008,
    postal_code: '22548'
  },
  '22551': {
    country: 'US',
    city: 'Spotsylvania',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Spotsylvania',
    latitude: 38.162,
    longitude: -77.6854,
    postal_code: '22551'
  },
  '22552': {
    country: 'US',
    city: 'Sparta',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Caroline',
    latitude: 38.0094,
    longitude: -77.2251,
    postal_code: '22552'
  },
  '22553': {
    country: 'US',
    city: 'Spotsylvania',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Spotsylvania',
    latitude: 38.271,
    longitude: -77.6447,
    postal_code: '22553'
  },
  '22554': {
    country: 'US',
    city: 'Stafford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Stafford',
    latitude: 38.4586,
    longitude: -77.4306,
    postal_code: '22554'
  },
  '22555': {
    country: 'US',
    city: 'Stafford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Stafford',
    latitude: 38.4221,
    longitude: -77.4083,
    postal_code: '22555'
  },
  '22556': {
    country: 'US',
    city: 'Stafford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Stafford',
    latitude: 38.4718,
    longitude: -77.5102,
    postal_code: '22556'
  },
  '22558': {
    country: 'US',
    city: 'Stratford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Westmoreland',
    latitude: 38.1484,
    longitude: -76.8413,
    postal_code: '22558'
  },
  '22560': {
    country: 'US',
    city: 'Tappahannock',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Essex',
    latitude: 37.9146,
    longitude: -76.9125,
    postal_code: '22560'
  },
  '22565': {
    country: 'US',
    city: 'Thornburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Spotsylvania',
    latitude: 38.1372,
    longitude: -77.5189,
    postal_code: '22565'
  },
  '22567': {
    country: 'US',
    city: 'Unionville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Orange',
    latitude: 38.2383,
    longitude: -77.9193,
    postal_code: '22567'
  },
  '22570': {
    country: 'US',
    city: 'Village',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Richmond',
    latitude: 37.9472,
    longitude: -76.6154,
    postal_code: '22570'
  },
  '22572': {
    country: 'US',
    city: 'Warsaw',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Richmond',
    latitude: 37.9695,
    longitude: -76.7665,
    postal_code: '22572'
  },
  '22576': {
    country: 'US',
    city: 'Weems',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lancaster',
    latitude: 37.6847,
    longitude: -76.4313,
    postal_code: '22576'
  },
  '22577': {
    country: 'US',
    city: 'Sandy Point',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Westmoreland',
    latitude: 38.0673,
    longitude: -76.5503,
    postal_code: '22577'
  },
  '22578': {
    country: 'US',
    city: 'White Stone',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lancaster',
    latitude: 37.6886,
    longitude: -76.3616,
    postal_code: '22578'
  },
  '22579': {
    country: 'US',
    city: 'Wicomico Church',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northumberland',
    latitude: 37.8025,
    longitude: -76.3636,
    postal_code: '22579'
  },
  '22580': {
    country: 'US',
    city: 'Woodford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Caroline',
    latitude: 38.0847,
    longitude: -77.4399,
    postal_code: '22580'
  },
  '22581': {
    country: 'US',
    city: 'Zacata',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Westmoreland',
    latitude: 38.1218,
    longitude: -76.7903,
    postal_code: '22581'
  },
  '22601': {
    country: 'US',
    city: 'Winchester',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Winchester (city)',
    latitude: 39.1858,
    longitude: -78.1827,
    postal_code: '22601'
  },
  '22602': {
    country: 'US',
    city: 'Winchester',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Frederick',
    latitude: 39.1501,
    longitude: -78.269,
    postal_code: '22602'
  },
  '22603': {
    country: 'US',
    city: 'Winchester',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Frederick',
    latitude: 39.264,
    longitude: -78.1989,
    postal_code: '22603'
  },
  '22604': {
    country: 'US',
    city: 'Winchester',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Winchester (city)',
    latitude: 39.1676,
    longitude: -78.1686,
    postal_code: '22604'
  },
  '22610': {
    country: 'US',
    city: 'Bentonville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Warren',
    latitude: 38.8187,
    longitude: -78.2757,
    postal_code: '22610'
  },
  '22611': {
    country: 'US',
    city: 'Berryville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Clarke',
    latitude: 39.1532,
    longitude: -77.9688,
    postal_code: '22611'
  },
  '22620': {
    country: 'US',
    city: 'Boyce',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Clarke',
    latitude: 39.0698,
    longitude: -78.0203,
    postal_code: '22620'
  },
  '22622': {
    country: 'US',
    city: 'Brucetown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Frederick',
    latitude: 39.2543,
    longitude: -78.0664,
    postal_code: '22622'
  },
  '22623': {
    country: 'US',
    city: 'Chester Gap',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rappahannock',
    latitude: 38.8537,
    longitude: -78.1412,
    postal_code: '22623'
  },
  '22624': {
    country: 'US',
    city: 'Clear Brook',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Frederick',
    latitude: 39.2655,
    longitude: -78.0988,
    postal_code: '22624'
  },
  '22625': {
    country: 'US',
    city: 'Cross Junction',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Frederick',
    latitude: 39.3744,
    longitude: -78.3081,
    postal_code: '22625'
  },
  '22626': {
    country: 'US',
    city: 'Fishers Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.9834,
    longitude: -78.4038,
    postal_code: '22626'
  },
  '22627': {
    country: 'US',
    city: 'Flint Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rappahannock',
    latitude: 38.7629,
    longitude: -78.1,
    postal_code: '22627'
  },
  '22630': {
    country: 'US',
    city: 'Front Royal',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Warren',
    latitude: 38.9268,
    longitude: -78.1796,
    postal_code: '22630'
  },
  '22637': {
    country: 'US',
    city: 'Gore',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Frederick',
    latitude: 39.2887,
    longitude: -78.3417,
    postal_code: '22637'
  },
  '22639': {
    country: 'US',
    city: 'Hume',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.8273,
    longitude: -77.9949,
    postal_code: '22639'
  },
  '22640': {
    country: 'US',
    city: 'Huntly',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rappahannock',
    latitude: 38.8129,
    longitude: -78.1165,
    postal_code: '22640'
  },
  '22641': {
    country: 'US',
    city: 'Strasburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 39.0808,
    longitude: -78.3913,
    postal_code: '22641'
  },
  '22642': {
    country: 'US',
    city: 'Linden',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Warren',
    latitude: 38.8812,
    longitude: -78.0478,
    postal_code: '22642'
  },
  '22643': {
    country: 'US',
    city: 'Markham',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.904,
    longitude: -78.0019,
    postal_code: '22643'
  },
  '22644': {
    country: 'US',
    city: 'Maurertown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.9441,
    longitude: -78.4658,
    postal_code: '22644'
  },
  '22645': {
    country: 'US',
    city: 'Middletown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Frederick',
    latitude: 39.0367,
    longitude: -78.2815,
    postal_code: '22645'
  },
  '22646': {
    country: 'US',
    city: 'Millwood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Clarke',
    latitude: 39.0696,
    longitude: -78.0378,
    postal_code: '22646'
  },
  '22649': {
    country: 'US',
    city: 'Middletown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Warren',
    latitude: 39.0048,
    longitude: -78.2478,
    postal_code: '22649'
  },
  '22650': {
    country: 'US',
    city: 'Rileyville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Page',
    latitude: 38.7564,
    longitude: -78.3873,
    postal_code: '22650'
  },
  '22652': {
    country: 'US',
    city: 'Fort Valley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.8407,
    longitude: -78.4276,
    postal_code: '22652'
  },
  '22654': {
    country: 'US',
    city: 'Star Tannery',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Frederick',
    latitude: 39.0908,
    longitude: -78.4343,
    postal_code: '22654'
  },
  '22655': {
    country: 'US',
    city: 'Stephens City',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Frederick',
    latitude: 39.0834,
    longitude: -78.1907,
    postal_code: '22655'
  },
  '22656': {
    country: 'US',
    city: 'Stephenson',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Frederick',
    latitude: 39.1973,
    longitude: -78.0941,
    postal_code: '22656'
  },
  '22657': {
    country: 'US',
    city: 'Strasburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.9884,
    longitude: -78.3386,
    postal_code: '22657'
  },
  '22660': {
    country: 'US',
    city: 'Toms Brook',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.9476,
    longitude: -78.4331,
    postal_code: '22660'
  },
  '22663': {
    country: 'US',
    city: 'White Post',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Clarke',
    latitude: 39.058,
    longitude: -78.1105,
    postal_code: '22663'
  },
  '22664': {
    country: 'US',
    city: 'Woodstock',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.887,
    longitude: -78.5217,
    postal_code: '22664'
  },
  '22701': {
    country: 'US',
    city: 'Culpeper',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.5117,
    longitude: -77.9928,
    postal_code: '22701'
  },
  '22709': {
    country: 'US',
    city: 'Aroda',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.3256,
    longitude: -78.2366,
    postal_code: '22709'
  },
  '22711': {
    country: 'US',
    city: 'Banco',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.4512,
    longitude: -78.2817,
    postal_code: '22711'
  },
  '22712': {
    country: 'US',
    city: 'Bealeton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.5453,
    longitude: -77.7561,
    postal_code: '22712'
  },
  '22713': {
    country: 'US',
    city: 'Boston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.5382,
    longitude: -78.1423,
    postal_code: '22713'
  },
  '22714': {
    country: 'US',
    city: 'Brandy Station',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.511,
    longitude: -77.9037,
    postal_code: '22714'
  },
  '22715': {
    country: 'US',
    city: 'Brightwood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.4114,
    longitude: -78.1698,
    postal_code: '22715'
  },
  '22716': {
    country: 'US',
    city: 'Castleton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rappahannock',
    latitude: 38.6032,
    longitude: -78.1208,
    postal_code: '22716'
  },
  '22718': {
    country: 'US',
    city: 'Elkwood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.4652,
    longitude: -77.817,
    postal_code: '22718'
  },
  '22719': {
    country: 'US',
    city: 'Etlan',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.5095,
    longitude: -78.2638,
    postal_code: '22719'
  },
  '22720': {
    country: 'US',
    city: 'Goldvein',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.4725,
    longitude: -77.6423,
    postal_code: '22720'
  },
  '22722': {
    country: 'US',
    city: 'Haywood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.4535,
    longitude: -78.2522,
    postal_code: '22722'
  },
  '22723': {
    country: 'US',
    city: 'Hood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.3549,
    longitude: -78.3828,
    postal_code: '22723'
  },
  '22724': {
    country: 'US',
    city: 'Jeffersonton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.6256,
    longitude: -77.9069,
    postal_code: '22724'
  },
  '22725': {
    country: 'US',
    city: 'Leon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.4285,
    longitude: -78.2678,
    postal_code: '22725'
  },
  '22726': {
    country: 'US',
    city: 'Lignum',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.3956,
    longitude: -77.83,
    postal_code: '22726'
  },
  '22727': {
    country: 'US',
    city: 'Madison',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.37,
    longitude: -78.2976,
    postal_code: '22727'
  },
  '22728': {
    country: 'US',
    city: 'Midland',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.5671,
    longitude: -77.7127,
    postal_code: '22728'
  },
  '22729': {
    country: 'US',
    city: 'Mitchells',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.3744,
    longitude: -78.0105,
    postal_code: '22729'
  },
  '22730': {
    country: 'US',
    city: 'Oakpark',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.3674,
    longitude: -78.1628,
    postal_code: '22730'
  },
  '22731': {
    country: 'US',
    city: 'Pratts',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.3366,
    longitude: -78.2573,
    postal_code: '22731'
  },
  '22732': {
    country: 'US',
    city: 'Radiant',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.3146,
    longitude: -78.1899,
    postal_code: '22732'
  },
  '22733': {
    country: 'US',
    city: 'Rapidan',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.3392,
    longitude: -78.0476,
    postal_code: '22733'
  },
  '22734': {
    country: 'US',
    city: 'Remington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.5309,
    longitude: -77.8037,
    postal_code: '22734'
  },
  '22735': {
    country: 'US',
    city: 'Reva',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.4604,
    longitude: -78.1572,
    postal_code: '22735'
  },
  '22736': {
    country: 'US',
    city: 'Richardsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.3924,
    longitude: -77.7195,
    postal_code: '22736'
  },
  '22737': {
    country: 'US',
    city: 'Rixeyville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.5852,
    longitude: -78.0282,
    postal_code: '22737'
  },
  '22738': {
    country: 'US',
    city: 'Rochelle',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.3215,
    longitude: -78.3042,
    postal_code: '22738'
  },
  '22739': {
    country: 'US',
    city: 'Somerville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.5059,
    longitude: -77.5956,
    postal_code: '22739'
  },
  '22740': {
    country: 'US',
    city: 'Sperryville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rappahannock',
    latitude: 38.6203,
    longitude: -78.2466,
    postal_code: '22740'
  },
  '22741': {
    country: 'US',
    city: 'Stevensburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Culpeper',
    latitude: 38.4441,
    longitude: -77.8842,
    postal_code: '22741'
  },
  '22742': {
    country: 'US',
    city: 'Sumerduck',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fauquier',
    latitude: 38.4674,
    longitude: -77.7044,
    postal_code: '22742'
  },
  '22743': {
    country: 'US',
    city: 'Syria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.4972,
    longitude: -78.3229,
    postal_code: '22743'
  },
  '22746': {
    country: 'US',
    city: 'Viewtown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rappahannock',
    latitude: 38.6457,
    longitude: -78.0258,
    postal_code: '22746'
  },
  '22747': {
    country: 'US',
    city: 'Washington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rappahannock',
    latitude: 38.7078,
    longitude: -78.1566,
    postal_code: '22747'
  },
  '22748': {
    country: 'US',
    city: 'Wolftown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.3557,
    longitude: -78.3475,
    postal_code: '22748'
  },
  '22749': {
    country: 'US',
    city: 'Woodville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rappahannock',
    latitude: 38.6482,
    longitude: -78.1739,
    postal_code: '22749'
  },
  '22801': {
    country: 'US',
    city: 'Harrisonburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Harrisonburg',
    latitude: 38.4489,
    longitude: -78.8714,
    postal_code: '22801'
  },
  '22802': {
    country: 'US',
    city: 'Harrisonburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.4905,
    longitude: -78.8179,
    postal_code: '22802'
  },
  '22803': {
    country: 'US',
    city: 'Harrisonburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Harrisonburg',
    latitude: 38.4496,
    longitude: -78.8689,
    postal_code: '22803'
  },
  '22807': {
    country: 'US',
    city: 'Harrisonburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Harrisonburg',
    latitude: 38.4409,
    longitude: -78.8742,
    postal_code: '22807'
  },
  '22810': {
    country: 'US',
    city: 'Basye',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.8089,
    longitude: -78.7776,
    postal_code: '22810'
  },
  '22811': {
    country: 'US',
    city: 'Bergton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.7925,
    longitude: -78.9668,
    postal_code: '22811'
  },
  '22812': {
    country: 'US',
    city: 'Bridgewater',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.3859,
    longitude: -78.9937,
    postal_code: '22812'
  },
  '22815': {
    country: 'US',
    city: 'Broadway',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.6083,
    longitude: -78.7875,
    postal_code: '22815'
  },
  '22820': {
    country: 'US',
    city: 'Criders',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.7497,
    longitude: -78.9974,
    postal_code: '22820'
  },
  '22821': {
    country: 'US',
    city: 'Dayton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.4707,
    longitude: -79.085,
    postal_code: '22821'
  },
  '22824': {
    country: 'US',
    city: 'Edinburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.8432,
    longitude: -78.6003,
    postal_code: '22824'
  },
  '22827': {
    country: 'US',
    city: 'Elkton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.4025,
    longitude: -78.6321,
    postal_code: '22827'
  },
  '22830': {
    country: 'US',
    city: 'Fulks Run',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.6339,
    longitude: -78.9358,
    postal_code: '22830'
  },
  '22831': {
    country: 'US',
    city: 'Hinton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.5689,
    longitude: -79.0885,
    postal_code: '22831'
  },
  '22832': {
    country: 'US',
    city: 'Keezletown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.4659,
    longitude: -78.7499,
    postal_code: '22832'
  },
  '22833': {
    country: 'US',
    city: 'Lacey Spring',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.5284,
    longitude: -78.8552,
    postal_code: '22833'
  },
  '22834': {
    country: 'US',
    city: 'Linville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.5557,
    longitude: -78.8961,
    postal_code: '22834'
  },
  '22835': {
    country: 'US',
    city: 'Luray',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Page',
    latitude: 38.6548,
    longitude: -78.4596,
    postal_code: '22835'
  },
  '22840': {
    country: 'US',
    city: 'Mc Gaheysville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.3712,
    longitude: -78.7411,
    postal_code: '22840'
  },
  '22841': {
    country: 'US',
    city: 'Mount Crawford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.3457,
    longitude: -78.8957,
    postal_code: '22841'
  },
  '22842': {
    country: 'US',
    city: 'Mount Jackson',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.7857,
    longitude: -78.6803,
    postal_code: '22842'
  },
  '22843': {
    country: 'US',
    city: 'Mount Solon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.3328,
    longitude: -79.1028,
    postal_code: '22843'
  },
  '22844': {
    country: 'US',
    city: 'New Market',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.6606,
    longitude: -78.6714,
    postal_code: '22844'
  },
  '22845': {
    country: 'US',
    city: 'Orkney Springs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.7937,
    longitude: -78.8111,
    postal_code: '22845'
  },
  '22846': {
    country: 'US',
    city: 'Penn Laird',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.375,
    longitude: -78.7795,
    postal_code: '22846'
  },
  '22847': {
    country: 'US',
    city: 'Quicksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Shenandoah',
    latitude: 38.7201,
    longitude: -78.7213,
    postal_code: '22847'
  },
  '22848': {
    country: 'US',
    city: 'Pleasant Valley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.3847,
    longitude: -78.8914,
    postal_code: '22848'
  },
  '22849': {
    country: 'US',
    city: 'Shenandoah',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Page',
    latitude: 38.501,
    longitude: -78.609,
    postal_code: '22849'
  },
  '22850': {
    country: 'US',
    city: 'Singers Glen',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.5574,
    longitude: -78.9227,
    postal_code: '22850'
  },
  '22851': {
    country: 'US',
    city: 'Stanley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Page',
    latitude: 38.566,
    longitude: -78.5093,
    postal_code: '22851'
  },
  '22853': {
    country: 'US',
    city: 'Timberville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.6476,
    longitude: -78.7717,
    postal_code: '22853'
  },
  '22901': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.0936,
    longitude: -78.5611,
    postal_code: '22901'
  },
  '22902': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlottesville (city)',
    latitude: 38.0266,
    longitude: -78.4805,
    postal_code: '22902'
  },
  '22903': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlottesville (city)',
    latitude: 38.0339,
    longitude: -78.4924,
    postal_code: '22903'
  },
  '22904': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlottesville (city)',
    latitude: 38.0401,
    longitude: -78.4851,
    postal_code: '22904'
  },
  '22905': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlottesville (city)',
    latitude: 38.0401,
    longitude: -78.4851,
    postal_code: '22905'
  },
  '22906': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlottesville (city)',
    latitude: 38.0401,
    longitude: -78.4851,
    postal_code: '22906'
  },
  '22907': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlottesville (city)',
    latitude: 38.0401,
    longitude: -78.4851,
    postal_code: '22907'
  },
  '22908': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlottesville (city)',
    latitude: 38.0401,
    longitude: -78.4851,
    postal_code: '22908'
  },
  '22909': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.0245,
    longitude: -78.4482,
    postal_code: '22909'
  },
  '22910': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlottesville (city)',
    latitude: 38.0401,
    longitude: -78.4851,
    postal_code: '22910'
  },
  '22911': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.0995,
    longitude: -78.4085,
    postal_code: '22911'
  },
  '22920': {
    country: 'US',
    city: 'Afton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.9626,
    longitude: -78.841,
    postal_code: '22920'
  },
  '22922': {
    country: 'US',
    city: 'Arrington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.6902,
    longitude: -78.9479,
    postal_code: '22922'
  },
  '22923': {
    country: 'US',
    city: 'Barboursville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Orange',
    latitude: 38.2095,
    longitude: -78.3098,
    postal_code: '22923'
  },
  '22924': {
    country: 'US',
    city: 'Batesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.0013,
    longitude: -78.7271,
    postal_code: '22924'
  },
  '22931': {
    country: 'US',
    city: 'Covesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 37.908,
    longitude: -78.7127,
    postal_code: '22931'
  },
  '22932': {
    country: 'US',
    city: 'Crozet',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.1296,
    longitude: -78.7106,
    postal_code: '22932'
  },
  '22935': {
    country: 'US',
    city: 'Dyke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Greene',
    latitude: 38.2705,
    longitude: -78.5578,
    postal_code: '22935'
  },
  '22936': {
    country: 'US',
    city: 'Earlysville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.1578,
    longitude: -78.4919,
    postal_code: '22936'
  },
  '22937': {
    country: 'US',
    city: 'Esmont',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 37.8125,
    longitude: -78.6106,
    postal_code: '22937'
  },
  '22938': {
    country: 'US',
    city: 'Faber',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.8475,
    longitude: -78.7565,
    postal_code: '22938'
  },
  '22939': {
    country: 'US',
    city: 'Fishersville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.0964,
    longitude: -78.9929,
    postal_code: '22939'
  },
  '22940': {
    country: 'US',
    city: 'Free Union',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.1944,
    longitude: -78.5849,
    postal_code: '22940'
  },
  '22942': {
    country: 'US',
    city: 'Gordonsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Orange',
    latitude: 38.1759,
    longitude: -78.1815,
    postal_code: '22942'
  },
  '22943': {
    country: 'US',
    city: 'Greenwood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.0415,
    longitude: -78.7833,
    postal_code: '22943'
  },
  '22945': {
    country: 'US',
    city: 'Ivy',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.0654,
    longitude: -78.5958,
    postal_code: '22945'
  },
  '22946': {
    country: 'US',
    city: 'Keene',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 37.856,
    longitude: -78.5699,
    postal_code: '22946'
  },
  '22947': {
    country: 'US',
    city: 'Keswick',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.0531,
    longitude: -78.3396,
    postal_code: '22947'
  },
  '22948': {
    country: 'US',
    city: 'Locust Dale',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.3572,
    longitude: -78.1229,
    postal_code: '22948'
  },
  '22949': {
    country: 'US',
    city: 'Lovingston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.7924,
    longitude: -78.8684,
    postal_code: '22949'
  },
  '22952': {
    country: 'US',
    city: 'Lyndhurst',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 37.9744,
    longitude: -78.9361,
    postal_code: '22952'
  },
  '22957': {
    country: 'US',
    city: 'Montpelier Station',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Orange',
    latitude: 38.227,
    longitude: -78.1768,
    postal_code: '22957'
  },
  '22958': {
    country: 'US',
    city: 'Nellysford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.902,
    longitude: -78.9004,
    postal_code: '22958'
  },
  '22959': {
    country: 'US',
    city: 'North Garden',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 37.9336,
    longitude: -78.6351,
    postal_code: '22959'
  },
  '22960': {
    country: 'US',
    city: 'Orange',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Orange',
    latitude: 38.2192,
    longitude: -78.0461,
    postal_code: '22960'
  },
  '22963': {
    country: 'US',
    city: 'Palmyra',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fluvanna',
    latitude: 37.8931,
    longitude: -78.3386,
    postal_code: '22963'
  },
  '22964': {
    country: 'US',
    city: 'Piney River',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.7051,
    longitude: -79.0231,
    postal_code: '22964'
  },
  '22965': {
    country: 'US',
    city: 'Quinque',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Greene',
    latitude: 38.3301,
    longitude: -78.475,
    postal_code: '22965'
  },
  '22967': {
    country: 'US',
    city: 'Roseland',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.8077,
    longitude: -78.9712,
    postal_code: '22967'
  },
  '22968': {
    country: 'US',
    city: 'Ruckersville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Greene',
    latitude: 38.2586,
    longitude: -78.4074,
    postal_code: '22968'
  },
  '22969': {
    country: 'US',
    city: 'Schuyler',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.7976,
    longitude: -78.6925,
    postal_code: '22969'
  },
  '22971': {
    country: 'US',
    city: 'Shipman',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.76,
    longitude: -78.8105,
    postal_code: '22971'
  },
  '22972': {
    country: 'US',
    city: 'Somerset',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Orange',
    latitude: 38.1997,
    longitude: -78.2394,
    postal_code: '22972'
  },
  '22973': {
    country: 'US',
    city: 'Stanardsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Greene',
    latitude: 38.3121,
    longitude: -78.482,
    postal_code: '22973'
  },
  '22974': {
    country: 'US',
    city: 'Troy',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fluvanna',
    latitude: 37.9636,
    longitude: -78.2535,
    postal_code: '22974'
  },
  '22976': {
    country: 'US',
    city: 'Tyro',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.8391,
    longitude: -79.0693,
    postal_code: '22976'
  },
  '22980': {
    country: 'US',
    city: 'Waynesboro',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Waynesboro (city)',
    latitude: 38.0774,
    longitude: -78.9035,
    postal_code: '22980'
  },
  '22987': {
    country: 'US',
    city: 'White Hall',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 38.1179,
    longitude: -78.6614,
    postal_code: '22987'
  },
  '22989': {
    country: 'US',
    city: 'Woodberry Forest',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Madison',
    latitude: 38.2912,
    longitude: -78.1225,
    postal_code: '22989'
  },
  '23001': {
    country: 'US',
    city: 'Achilles',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.2882,
    longitude: -76.426,
    postal_code: '23001'
  },
  '23002': {
    country: 'US',
    city: 'Amelia Court House',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Amelia',
    latitude: 37.3428,
    longitude: -77.9841,
    postal_code: '23002'
  },
  '23003': {
    country: 'US',
    city: 'Ark',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.4323,
    longitude: -76.6016,
    postal_code: '23003'
  },
  '23004': {
    country: 'US',
    city: 'Arvonia',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buckingham',
    latitude: 37.6714,
    longitude: -78.3889,
    postal_code: '23004'
  },
  '23005': {
    country: 'US',
    city: 'Ashland',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hanover',
    latitude: 37.7395,
    longitude: -77.4784,
    postal_code: '23005'
  },
  '23009': {
    country: 'US',
    city: 'Aylett',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King William',
    latitude: 37.8221,
    longitude: -77.1885,
    postal_code: '23009'
  },
  '23011': {
    country: 'US',
    city: 'Barhamsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'New Kent',
    latitude: 37.4617,
    longitude: -76.8321,
    postal_code: '23011'
  },
  '23014': {
    country: 'US',
    city: 'Beaumont',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.7338,
    longitude: -77.8881,
    postal_code: '23014'
  },
  '23015': {
    country: 'US',
    city: 'Beaverdam',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hanover',
    latitude: 37.9038,
    longitude: -77.6308,
    postal_code: '23015'
  },
  '23018': {
    country: 'US',
    city: 'Bena',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.271,
    longitude: -76.4555,
    postal_code: '23018'
  },
  '23021': {
    country: 'US',
    city: 'Bohannon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.3905,
    longitude: -76.3614,
    postal_code: '23021'
  },
  '23022': {
    country: 'US',
    city: 'Bremo Bluff',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fluvanna',
    latitude: 37.7453,
    longitude: -78.2672,
    postal_code: '23022'
  },
  '23023': {
    country: 'US',
    city: 'Bruington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King and Queen',
    latitude: 37.7801,
    longitude: -76.9402,
    postal_code: '23023'
  },
  '23024': {
    country: 'US',
    city: 'Bumpass',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Louisa',
    latitude: 37.8984,
    longitude: -77.7966,
    postal_code: '23024'
  },
  '23025': {
    country: 'US',
    city: 'Cardinal',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.4128,
    longitude: -76.3616,
    postal_code: '23025'
  },
  '23027': {
    country: 'US',
    city: 'Cartersville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Cumberland',
    latitude: 37.6479,
    longitude: -78.1389,
    postal_code: '23027'
  },
  '23030': {
    country: 'US',
    city: 'Charles City',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charles City',
    latitude: 37.3663,
    longitude: -77.1082,
    postal_code: '23030'
  },
  '23031': {
    country: 'US',
    city: 'Christchurch',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.5984,
    longitude: -76.4476,
    postal_code: '23031'
  },
  '23032': {
    country: 'US',
    city: 'Church View',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.675,
    longitude: -76.6629,
    postal_code: '23032'
  },
  '23035': {
    country: 'US',
    city: 'Cobbs Creek',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.4959,
    longitude: -76.3863,
    postal_code: '23035'
  },
  '23038': {
    country: 'US',
    city: 'Columbia',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.7147,
    longitude: -78.1794,
    postal_code: '23038'
  },
  '23039': {
    country: 'US',
    city: 'Crozier',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.6718,
    longitude: -77.794,
    postal_code: '23039'
  },
  '23040': {
    country: 'US',
    city: 'Cumberland',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Cumberland',
    latitude: 37.5019,
    longitude: -78.2581,
    postal_code: '23040'
  },
  '23043': {
    country: 'US',
    city: 'Deltaville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.5549,
    longitude: -76.3468,
    postal_code: '23043'
  },
  '23045': {
    country: 'US',
    city: 'Diggs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.4296,
    longitude: -76.2629,
    postal_code: '23045'
  },
  '23047': {
    country: 'US',
    city: 'Doswell',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hanover',
    latitude: 37.8417,
    longitude: -77.5125,
    postal_code: '23047'
  },
  '23050': {
    country: 'US',
    city: 'Dutton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.5001,
    longitude: -76.454,
    postal_code: '23050'
  },
  '23055': {
    country: 'US',
    city: 'Fork Union',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fluvanna',
    latitude: 37.7715,
    longitude: -78.2355,
    postal_code: '23055'
  },
  '23056': {
    country: 'US',
    city: 'Foster',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.4529,
    longitude: -76.3849,
    postal_code: '23056'
  },
  '23058': {
    country: 'US',
    city: 'Glen Allen',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.5313,
    longitude: -77.4161,
    postal_code: '23058'
  },
  '23059': {
    country: 'US',
    city: 'Glen Allen',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hanover',
    latitude: 37.7284,
    longitude: -77.5544,
    postal_code: '23059'
  },
  '23060': {
    country: 'US',
    city: 'Glen Allen',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.6628,
    longitude: -77.534,
    postal_code: '23060'
  },
  '23061': {
    country: 'US',
    city: 'Gloucester',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.4126,
    longitude: -76.5464,
    postal_code: '23061'
  },
  '23062': {
    country: 'US',
    city: 'Gloucester Point',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.2585,
    longitude: -76.4959,
    postal_code: '23062'
  },
  '23063': {
    country: 'US',
    city: 'Goochland',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.7688,
    longitude: -78.0112,
    postal_code: '23063'
  },
  '23064': {
    country: 'US',
    city: 'Grimstead',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.4961,
    longitude: -76.3,
    postal_code: '23064'
  },
  '23065': {
    country: 'US',
    city: 'Gum Spring',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.7982,
    longitude: -77.9375,
    postal_code: '23065'
  },
  '23066': {
    country: 'US',
    city: 'Gwynn',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.5043,
    longitude: -76.2886,
    postal_code: '23066'
  },
  '23067': {
    country: 'US',
    city: 'Hadensville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.8252,
    longitude: -77.9899,
    postal_code: '23067'
  },
  '23068': {
    country: 'US',
    city: 'Hallieford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.4937,
    longitude: -76.3402,
    postal_code: '23068'
  },
  '23069': {
    country: 'US',
    city: 'Hanover',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hanover',
    latitude: 37.7701,
    longitude: -77.3216,
    postal_code: '23069'
  },
  '23070': {
    country: 'US',
    city: 'Hardyville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.5512,
    longitude: -76.3844,
    postal_code: '23070'
  },
  '23071': {
    country: 'US',
    city: 'Hartfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.5594,
    longitude: -76.4769,
    postal_code: '23071'
  },
  '23072': {
    country: 'US',
    city: 'Hayes',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.2916,
    longitude: -76.4905,
    postal_code: '23072'
  },
  '23075': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.546,
    longitude: -77.3278,
    postal_code: '23075'
  },
  '23076': {
    country: 'US',
    city: 'Hudgins',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.4766,
    longitude: -76.3089,
    postal_code: '23076'
  },
  '23079': {
    country: 'US',
    city: 'Jamaica',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.73,
    longitude: -76.689,
    postal_code: '23079'
  },
  '23081': {
    country: 'US',
    city: 'Jamestown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'James City',
    latitude: 37.2235,
    longitude: -76.7833,
    postal_code: '23081'
  },
  '23083': {
    country: 'US',
    city: 'Jetersville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Amelia',
    latitude: 37.3176,
    longitude: -78.104,
    postal_code: '23083'
  },
  '23084': {
    country: 'US',
    city: 'Kents Store',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Fluvanna',
    latitude: 37.8942,
    longitude: -78.1208,
    postal_code: '23084'
  },
  '23085': {
    country: 'US',
    city: 'King And Queen Court House',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King and Queen',
    latitude: 37.6699,
    longitude: -76.8775,
    postal_code: '23085'
  },
  '23086': {
    country: 'US',
    city: 'King William',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King William',
    latitude: 37.7202,
    longitude: -77.0998,
    postal_code: '23086'
  },
  '23089': {
    country: 'US',
    city: 'Lanexa',
    state: 'Virginia',
    state_short: 'VA',
    county: 'New Kent',
    latitude: 37.4194,
    longitude: -76.9027,
    postal_code: '23089'
  },
  '23090': {
    country: 'US',
    city: 'Lightfoot',
    state: 'Virginia',
    state_short: 'VA',
    county: 'York',
    latitude: 37.3407,
    longitude: -76.7544,
    postal_code: '23090'
  },
  '23091': {
    country: 'US',
    city: 'Little Plymouth',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King and Queen',
    latitude: 37.6642,
    longitude: -76.8255,
    postal_code: '23091'
  },
  '23092': {
    country: 'US',
    city: 'Locust Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.5826,
    longitude: -76.4984,
    postal_code: '23092'
  },
  '23093': {
    country: 'US',
    city: 'Louisa',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Louisa',
    latitude: 38.0132,
    longitude: -78.0347,
    postal_code: '23093'
  },
  '23102': {
    country: 'US',
    city: 'Maidens',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.7126,
    longitude: -77.8315,
    postal_code: '23102'
  },
  '23103': {
    country: 'US',
    city: 'Manakin Sabot',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.638,
    longitude: -77.7077,
    postal_code: '23103'
  },
  '23105': {
    country: 'US',
    city: 'Mannboro',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Amelia',
    latitude: 37.345,
    longitude: -77.9449,
    postal_code: '23105'
  },
  '23106': {
    country: 'US',
    city: 'Manquin',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King William',
    latitude: 37.7184,
    longitude: -77.186,
    postal_code: '23106'
  },
  '23107': {
    country: 'US',
    city: 'Maryus',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.2864,
    longitude: -76.4048,
    postal_code: '23107'
  },
  '23108': {
    country: 'US',
    city: 'Mascot',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King and Queen',
    latitude: 37.6271,
    longitude: -76.7072,
    postal_code: '23108'
  },
  '23109': {
    country: 'US',
    city: 'Mathews',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.4383,
    longitude: -76.3544,
    postal_code: '23109'
  },
  '23110': {
    country: 'US',
    city: 'Mattaponi',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King and Queen',
    latitude: 37.6135,
    longitude: -76.8101,
    postal_code: '23110'
  },
  '23111': {
    country: 'US',
    city: 'Mechanicsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hanover',
    latitude: 37.6281,
    longitude: -77.3395,
    postal_code: '23111'
  },
  '23112': {
    country: 'US',
    city: 'Midlothian',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.431,
    longitude: -77.6545,
    postal_code: '23112'
  },
  '23113': {
    country: 'US',
    city: 'Midlothian',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.5109,
    longitude: -77.6429,
    postal_code: '23113'
  },
  '23114': {
    country: 'US',
    city: 'Midlothian',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.4815,
    longitude: -77.6419,
    postal_code: '23114'
  },
  '23115': {
    country: 'US',
    city: 'Millers Tavern',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Essex',
    latitude: 37.8282,
    longitude: -76.945,
    postal_code: '23115'
  },
  '23116': {
    country: 'US',
    city: 'Mechanicsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hanover',
    latitude: 37.6691,
    longitude: -77.3294,
    postal_code: '23116'
  },
  '23117': {
    country: 'US',
    city: 'Mineral',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Louisa',
    latitude: 37.9986,
    longitude: -77.8781,
    postal_code: '23117'
  },
  '23119': {
    country: 'US',
    city: 'Moon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.4487,
    longitude: -76.2766,
    postal_code: '23119'
  },
  '23120': {
    country: 'US',
    city: 'Moseley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.426,
    longitude: -77.7585,
    postal_code: '23120'
  },
  '23123': {
    country: 'US',
    city: 'New Canton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buckingham',
    latitude: 37.6645,
    longitude: -78.3112,
    postal_code: '23123'
  },
  '23124': {
    country: 'US',
    city: 'New Kent',
    state: 'Virginia',
    state_short: 'VA',
    county: 'New Kent',
    latitude: 37.553,
    longitude: -77.0742,
    postal_code: '23124'
  },
  '23125': {
    country: 'US',
    city: 'New Point',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.3438,
    longitude: -76.2878,
    postal_code: '23125'
  },
  '23126': {
    country: 'US',
    city: 'Newtown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King and Queen',
    latitude: 37.9226,
    longitude: -77.1449,
    postal_code: '23126'
  },
  '23127': {
    country: 'US',
    city: 'Norge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'James City',
    latitude: 37.3688,
    longitude: -76.7705,
    postal_code: '23127'
  },
  '23128': {
    country: 'US',
    city: 'North',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.4401,
    longitude: -76.4238,
    postal_code: '23128'
  },
  '23129': {
    country: 'US',
    city: 'Oilville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.704,
    longitude: -77.7853,
    postal_code: '23129'
  },
  '23130': {
    country: 'US',
    city: 'Onemo',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.3985,
    longitude: -76.2941,
    postal_code: '23130'
  },
  '23131': {
    country: 'US',
    city: 'Ordinary',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.3122,
    longitude: -76.5188,
    postal_code: '23131'
  },
  '23138': {
    country: 'US',
    city: 'Port Haywood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.386,
    longitude: -76.3172,
    postal_code: '23138'
  },
  '23139': {
    country: 'US',
    city: 'Powhatan',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Powhatan',
    latitude: 37.5421,
    longitude: -77.9189,
    postal_code: '23139'
  },
  '23140': {
    country: 'US',
    city: 'Providence Forge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'New Kent',
    latitude: 37.4418,
    longitude: -77.0436,
    postal_code: '23140'
  },
  '23141': {
    country: 'US',
    city: 'Quinton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'New Kent',
    latitude: 37.5185,
    longitude: -77.1486,
    postal_code: '23141'
  },
  '23146': {
    country: 'US',
    city: 'Rockville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hanover',
    latitude: 37.7337,
    longitude: -77.7,
    postal_code: '23146'
  },
  '23147': {
    country: 'US',
    city: 'Ruthville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charles City',
    latitude: 37.3796,
    longitude: -77.0348,
    postal_code: '23147'
  },
  '23148': {
    country: 'US',
    city: 'Saint Stephens Church',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King and Queen',
    latitude: 37.8479,
    longitude: -77.055,
    postal_code: '23148'
  },
  '23149': {
    country: 'US',
    city: 'Saluda',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.5949,
    longitude: -76.5921,
    postal_code: '23149'
  },
  '23150': {
    country: 'US',
    city: 'Sandston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.5157,
    longitude: -77.2758,
    postal_code: '23150'
  },
  '23153': {
    country: 'US',
    city: 'Sandy Hook',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.7526,
    longitude: -77.9125,
    postal_code: '23153'
  },
  '23154': {
    country: 'US',
    city: 'Schley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.3896,
    longitude: -76.4549,
    postal_code: '23154'
  },
  '23155': {
    country: 'US',
    city: 'Severn',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.4182,
    longitude: -76.5084,
    postal_code: '23155'
  },
  '23156': {
    country: 'US',
    city: 'Shacklefords',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King and Queen',
    latitude: 37.5213,
    longitude: -76.7099,
    postal_code: '23156'
  },
  '23160': {
    country: 'US',
    city: 'State Farm',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Goochland',
    latitude: 37.6454,
    longitude: -77.8283,
    postal_code: '23160'
  },
  '23161': {
    country: 'US',
    city: 'Stevensville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King and Queen',
    latitude: 37.7146,
    longitude: -76.9352,
    postal_code: '23161'
  },
  '23162': {
    country: 'US',
    city: 'Studley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hanover',
    latitude: 37.6757,
    longitude: -77.2908,
    postal_code: '23162'
  },
  '23163': {
    country: 'US',
    city: 'Susan',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mathews',
    latitude: 37.3508,
    longitude: -76.3161,
    postal_code: '23163'
  },
  '23168': {
    country: 'US',
    city: 'Toano',
    state: 'Virginia',
    state_short: 'VA',
    county: 'James City',
    latitude: 37.3901,
    longitude: -76.8253,
    postal_code: '23168'
  },
  '23169': {
    country: 'US',
    city: 'Topping',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.6009,
    longitude: -76.4575,
    postal_code: '23169'
  },
  '23170': {
    country: 'US',
    city: 'Trevilians',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Louisa',
    latitude: 38.0515,
    longitude: -78.0728,
    postal_code: '23170'
  },
  '23173': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5538,
    longitude: -77.4603,
    postal_code: '23173'
  },
  '23175': {
    country: 'US',
    city: 'Urbanna',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.6356,
    longitude: -76.5918,
    postal_code: '23175'
  },
  '23176': {
    country: 'US',
    city: 'Wake',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.5681,
    longitude: -76.4323,
    postal_code: '23176'
  },
  '23177': {
    country: 'US',
    city: 'Walkerton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King and Queen',
    latitude: 37.7554,
    longitude: -77.0185,
    postal_code: '23177'
  },
  '23178': {
    country: 'US',
    city: 'Ware Neck',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.4004,
    longitude: -76.4529,
    postal_code: '23178'
  },
  '23180': {
    country: 'US',
    city: 'Water View',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Middlesex',
    latitude: 37.7105,
    longitude: -76.6227,
    postal_code: '23180'
  },
  '23181': {
    country: 'US',
    city: 'West Point',
    state: 'Virginia',
    state_short: 'VA',
    county: 'King William',
    latitude: 37.5556,
    longitude: -76.8237,
    postal_code: '23181'
  },
  '23183': {
    country: 'US',
    city: 'White Marsh',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.3632,
    longitude: -76.5325,
    postal_code: '23183'
  },
  '23184': {
    country: 'US',
    city: 'Wicomico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.291,
    longitude: -76.5094,
    postal_code: '23184'
  },
  '23185': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'James City',
    latitude: 37.2732,
    longitude: -76.7324,
    postal_code: '23185'
  },
  '23186': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Williamsburg (city)',
    latitude: 37.3105,
    longitude: -76.7468,
    postal_code: '23186'
  },
  '23187': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Williamsburg (city)',
    latitude: 37.3105,
    longitude: -76.7468,
    postal_code: '23187'
  },
  '23188': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'James City',
    latitude: 37.3178,
    longitude: -76.7634,
    postal_code: '23188'
  },
  '23190': {
    country: 'US',
    city: 'Woods Cross Roads',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Gloucester',
    latitude: 37.482,
    longitude: -76.6362,
    postal_code: '23190'
  },
  '23192': {
    country: 'US',
    city: 'Montpelier',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hanover',
    latitude: 37.8177,
    longitude: -77.6924,
    postal_code: '23192'
  },
  '23218': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23218'
  },
  '23219': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5463,
    longitude: -77.4378,
    postal_code: '23219'
  },
  '23220': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5498,
    longitude: -77.4588,
    postal_code: '23220'
  },
  '23221': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5583,
    longitude: -77.4845,
    postal_code: '23221'
  },
  '23222': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5748,
    longitude: -77.4267,
    postal_code: '23222'
  },
  '23223': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5477,
    longitude: -77.3948,
    postal_code: '23223'
  },
  '23224': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.4955,
    longitude: -77.471,
    postal_code: '23224'
  },
  '23225': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5158,
    longitude: -77.5047,
    postal_code: '23225'
  },
  '23226': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.5825,
    longitude: -77.5197,
    postal_code: '23226'
  },
  '23227': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.6247,
    longitude: -77.4351,
    postal_code: '23227'
  },
  '23228': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.4638,
    longitude: -77.398,
    postal_code: '23228'
  },
  '23229': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.4638,
    longitude: -77.398,
    postal_code: '23229'
  },
  '23230': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.5921,
    longitude: -77.4952,
    postal_code: '23230'
  },
  '23231': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.4638,
    longitude: -77.398,
    postal_code: '23231'
  },
  '23232': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5202,
    longitude: -77.4084,
    postal_code: '23232'
  },
  '23233': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.4638,
    longitude: -77.398,
    postal_code: '23233'
  },
  '23234': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.4373,
    longitude: -77.4788,
    postal_code: '23234'
  },
  '23235': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.5133,
    longitude: -77.5646,
    postal_code: '23235'
  },
  '23236': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.4782,
    longitude: -77.5854,
    postal_code: '23236'
  },
  '23237': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.4011,
    longitude: -77.4615,
    postal_code: '23237'
  },
  '23238': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.52,
    longitude: -77.4369,
    postal_code: '23238'
  },
  '23241': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23241'
  },
  '23242': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.4638,
    longitude: -77.398,
    postal_code: '23242'
  },
  '23249': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23249'
  },
  '23250': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.5075,
    longitude: -77.3329,
    postal_code: '23250'
  },
  '23255': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.4638,
    longitude: -77.398,
    postal_code: '23255'
  },
  '23260': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5538,
    longitude: -77.4603,
    postal_code: '23260'
  },
  '23261': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5538,
    longitude: -77.4603,
    postal_code: '23261'
  },
  '23269': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5538,
    longitude: -77.4603,
    postal_code: '23269'
  },
  '23273': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5302,
    longitude: -77.4753,
    postal_code: '23273'
  },
  '23274': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23274'
  },
  '23276': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23276'
  },
  '23278': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23278'
  },
  '23279': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23279'
  },
  '23282': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5538,
    longitude: -77.4603,
    postal_code: '23282'
  },
  '23284': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5494,
    longitude: -77.4512,
    postal_code: '23284'
  },
  '23285': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23285'
  },
  '23286': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23286'
  },
  '23288': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.4638,
    longitude: -77.398,
    postal_code: '23288'
  },
  '23289': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.5313,
    longitude: -77.4161,
    postal_code: '23289'
  },
  '23290': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23290'
  },
  '23291': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23291'
  },
  '23292': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23292'
  },
  '23293': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5242,
    longitude: -77.4932,
    postal_code: '23293'
  },
  '23294': {
    country: 'US',
    city: 'Henrico',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henrico',
    latitude: 37.631,
    longitude: -77.546,
    postal_code: '23294'
  },
  '23295': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5532,
    longitude: -77.4621,
    postal_code: '23295'
  },
  '23297': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.3897,
    longitude: -77.5613,
    postal_code: '23297'
  },
  '23298': {
    country: 'US',
    city: 'Richmond',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Richmond',
    latitude: 37.5406,
    longitude: -77.4316,
    postal_code: '23298'
  },
  '23301': {
    country: 'US',
    city: 'Accomac',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.7159,
    longitude: -75.6803,
    postal_code: '23301'
  },
  '23302': {
    country: 'US',
    city: 'Assawoman',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.8658,
    longitude: -75.5277,
    postal_code: '23302'
  },
  '23303': {
    country: 'US',
    city: 'Atlantic',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9073,
    longitude: -75.5089,
    postal_code: '23303'
  },
  '23304': {
    country: 'US',
    city: 'Battery Park',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Isle of Wight',
    latitude: 36.9965,
    longitude: -76.5741,
    postal_code: '23304'
  },
  '23306': {
    country: 'US',
    city: 'Belle Haven',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.5645,
    longitude: -75.8694,
    postal_code: '23306'
  },
  '23307': {
    country: 'US',
    city: 'Birdsnest',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.439,
    longitude: -75.881,
    postal_code: '23307'
  },
  '23308': {
    country: 'US',
    city: 'Bloxom',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.8273,
    longitude: -75.6156,
    postal_code: '23308'
  },
  '23310': {
    country: 'US',
    city: 'Cape Charles',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.2799,
    longitude: -75.9721,
    postal_code: '23310'
  },
  '23313': {
    country: 'US',
    city: 'Capeville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.2019,
    longitude: -75.9524,
    postal_code: '23313'
  },
  '23314': {
    country: 'US',
    city: 'Carrollton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Isle of Wight',
    latitude: 36.955,
    longitude: -76.543,
    postal_code: '23314'
  },
  '23315': {
    country: 'US',
    city: 'Carrsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Isle of Wight',
    latitude: 36.7454,
    longitude: -76.8365,
    postal_code: '23315'
  },
  '23316': {
    country: 'US',
    city: 'Cheriton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.2896,
    longitude: -75.9713,
    postal_code: '23316'
  },
  '23320': {
    country: 'US',
    city: 'Chesapeake',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesapeake (city)',
    latitude: 36.7352,
    longitude: -76.2384,
    postal_code: '23320'
  },
  '23321': {
    country: 'US',
    city: 'Chesapeake',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesapeake (city)',
    latitude: 36.8012,
    longitude: -76.423,
    postal_code: '23321'
  },
  '23322': {
    country: 'US',
    city: 'Chesapeake',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesapeake (city)',
    latitude: 36.6434,
    longitude: -76.242,
    postal_code: '23322'
  },
  '23323': {
    country: 'US',
    city: 'Chesapeake',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesapeake (city)',
    latitude: 36.7634,
    longitude: -76.3397,
    postal_code: '23323'
  },
  '23324': {
    country: 'US',
    city: 'Chesapeake',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesapeake (city)',
    latitude: 36.8056,
    longitude: -76.2666,
    postal_code: '23324'
  },
  '23325': {
    country: 'US',
    city: 'Chesapeake',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesapeake (city)',
    latitude: 36.814,
    longitude: -76.2406,
    postal_code: '23325'
  },
  '23326': {
    country: 'US',
    city: 'Chesapeake',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesapeake (city)',
    latitude: 36.777,
    longitude: -76.2394,
    postal_code: '23326'
  },
  '23327': {
    country: 'US',
    city: 'Chesapeake',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesapeake (city)',
    latitude: 36.7085,
    longitude: -76.2785,
    postal_code: '23327'
  },
  '23328': {
    country: 'US',
    city: 'Chesapeake',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesapeake (city)',
    latitude: 36.7085,
    longitude: -76.2785,
    postal_code: '23328'
  },
  '23336': {
    country: 'US',
    city: 'Chincoteague Island',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9299,
    longitude: -75.3624,
    postal_code: '23336'
  },
  '23337': {
    country: 'US',
    city: 'Wallops Island',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9186,
    longitude: -75.4905,
    postal_code: '23337'
  },
  '23341': {
    country: 'US',
    city: 'Craddockville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.5773,
    longitude: -75.8646,
    postal_code: '23341'
  },
  '23345': {
    country: 'US',
    city: 'Davis Wharf',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.5529,
    longitude: -75.878,
    postal_code: '23345'
  },
  '23347': {
    country: 'US',
    city: 'Eastville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.3526,
    longitude: -75.9458,
    postal_code: '23347'
  },
  '23350': {
    country: 'US',
    city: 'Exmore',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.5117,
    longitude: -75.8526,
    postal_code: '23350'
  },
  '23354': {
    country: 'US',
    city: 'Franktown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.4588,
    longitude: -75.9007,
    postal_code: '23354'
  },
  '23356': {
    country: 'US',
    city: 'Greenbackville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 38.0064,
    longitude: -75.4029,
    postal_code: '23356'
  },
  '23357': {
    country: 'US',
    city: 'Greenbush',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.7681,
    longitude: -75.6664,
    postal_code: '23357'
  },
  '23358': {
    country: 'US',
    city: 'Hacksneck',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.6393,
    longitude: -75.865,
    postal_code: '23358'
  },
  '23359': {
    country: 'US',
    city: 'Hallwood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.8837,
    longitude: -75.6041,
    postal_code: '23359'
  },
  '23389': {
    country: 'US',
    city: 'Harborton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.6618,
    longitude: -75.8305,
    postal_code: '23389'
  },
  '23395': {
    country: 'US',
    city: 'Horntown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9699,
    longitude: -75.4716,
    postal_code: '23395'
  },
  '23396': {
    country: 'US',
    city: 'Oak Hall',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9237,
    longitude: -75.5551,
    postal_code: '23396'
  },
  '23397': {
    country: 'US',
    city: 'Isle Of Wight',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Isle of Wight',
    latitude: 36.8989,
    longitude: -76.6877,
    postal_code: '23397'
  },
  '23398': {
    country: 'US',
    city: 'Jamesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.5151,
    longitude: -75.9305,
    postal_code: '23398'
  },
  '23399': {
    country: 'US',
    city: 'Jenkins Bridge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9162,
    longitude: -75.6168,
    postal_code: '23399'
  },
  '23401': {
    country: 'US',
    city: 'Keller',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.6193,
    longitude: -75.7638,
    postal_code: '23401'
  },
  '23404': {
    country: 'US',
    city: 'Locustville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.6534,
    longitude: -75.6735,
    postal_code: '23404'
  },
  '23405': {
    country: 'US',
    city: 'Machipongo',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.4014,
    longitude: -75.9082,
    postal_code: '23405'
  },
  '23407': {
    country: 'US',
    city: 'Mappsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.846,
    longitude: -75.5666,
    postal_code: '23407'
  },
  '23408': {
    country: 'US',
    city: 'Marionville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.4556,
    longitude: -75.8473,
    postal_code: '23408'
  },
  '23409': {
    country: 'US',
    city: 'Mears',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.8692,
    longitude: -75.6354,
    postal_code: '23409'
  },
  '23410': {
    country: 'US',
    city: 'Melfa',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.6407,
    longitude: -75.7454,
    postal_code: '23410'
  },
  '23412': {
    country: 'US',
    city: 'Modest Town',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.7904,
    longitude: -75.6035,
    postal_code: '23412'
  },
  '23413': {
    country: 'US',
    city: 'Nassawadox',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.4728,
    longitude: -75.858,
    postal_code: '23413'
  },
  '23414': {
    country: 'US',
    city: 'Nelsonia',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.8169,
    longitude: -75.5832,
    postal_code: '23414'
  },
  '23415': {
    country: 'US',
    city: 'New Church',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9525,
    longitude: -75.5112,
    postal_code: '23415'
  },
  '23416': {
    country: 'US',
    city: 'Oak Hall',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9229,
    longitude: -75.5386,
    postal_code: '23416'
  },
  '23417': {
    country: 'US',
    city: 'Onancock',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.7102,
    longitude: -75.7528,
    postal_code: '23417'
  },
  '23418': {
    country: 'US',
    city: 'Onley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.6704,
    longitude: -75.6992,
    postal_code: '23418'
  },
  '23419': {
    country: 'US',
    city: 'Oyster',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.3074,
    longitude: -75.9269,
    postal_code: '23419'
  },
  '23420': {
    country: 'US',
    city: 'Painter',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.5828,
    longitude: -75.8066,
    postal_code: '23420'
  },
  '23421': {
    country: 'US',
    city: 'Parksley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.7744,
    longitude: -75.6386,
    postal_code: '23421'
  },
  '23422': {
    country: 'US',
    city: 'Pungoteague',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.6304,
    longitude: -75.8135,
    postal_code: '23422'
  },
  '23423': {
    country: 'US',
    city: 'Quinby',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.5423,
    longitude: -75.7412,
    postal_code: '23423'
  },
  '23424': {
    country: 'US',
    city: 'Rescue',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Isle of Wight',
    latitude: 36.9969,
    longitude: -76.5645,
    postal_code: '23424'
  },
  '23426': {
    country: 'US',
    city: 'Sanford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9229,
    longitude: -75.6781,
    postal_code: '23426'
  },
  '23427': {
    country: 'US',
    city: 'Saxis',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9264,
    longitude: -75.722,
    postal_code: '23427'
  },
  '23429': {
    country: 'US',
    city: 'Seaview',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.2711,
    longitude: -75.9536,
    postal_code: '23429'
  },
  '23430': {
    country: 'US',
    city: 'Smithfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Isle of Wight',
    latitude: 36.9811,
    longitude: -76.6368,
    postal_code: '23430'
  },
  '23431': {
    country: 'US',
    city: 'Smithfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Isle of Wight',
    latitude: 36.8989,
    longitude: -76.6877,
    postal_code: '23431'
  },
  '23432': {
    country: 'US',
    city: 'Suffolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Suffolk',
    latitude: 36.8668,
    longitude: -76.5598,
    postal_code: '23432'
  },
  '23433': {
    country: 'US',
    city: 'Suffolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Suffolk',
    latitude: 36.909,
    longitude: -76.4929,
    postal_code: '23433'
  },
  '23434': {
    country: 'US',
    city: 'Suffolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Suffolk',
    latitude: 36.7304,
    longitude: -76.5931,
    postal_code: '23434'
  },
  '23435': {
    country: 'US',
    city: 'Suffolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Suffolk',
    latitude: 36.8544,
    longitude: -76.4664,
    postal_code: '23435'
  },
  '23436': {
    country: 'US',
    city: 'Suffolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Suffolk',
    latitude: 36.8926,
    longitude: -76.5142,
    postal_code: '23436'
  },
  '23437': {
    country: 'US',
    city: 'Suffolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Suffolk',
    latitude: 36.6526,
    longitude: -76.792,
    postal_code: '23437'
  },
  '23438': {
    country: 'US',
    city: 'Suffolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Suffolk',
    latitude: 36.5913,
    longitude: -76.6871,
    postal_code: '23438'
  },
  '23439': {
    country: 'US',
    city: 'Suffolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Suffolk',
    latitude: 36.7461,
    longitude: -76.6653,
    postal_code: '23439'
  },
  '23440': {
    country: 'US',
    city: 'Tangier',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.8245,
    longitude: -75.993,
    postal_code: '23440'
  },
  '23441': {
    country: 'US',
    city: 'Tasley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.7104,
    longitude: -75.7005,
    postal_code: '23441'
  },
  '23442': {
    country: 'US',
    city: 'Temperanceville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.8954,
    longitude: -75.5528,
    postal_code: '23442'
  },
  '23443': {
    country: 'US',
    city: 'Townsend',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.1882,
    longitude: -75.969,
    postal_code: '23443'
  },
  '23450': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.844,
    longitude: -76.1204,
    postal_code: '23450'
  },
  '23451': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.8585,
    longitude: -76.0019,
    postal_code: '23451'
  },
  '23452': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.8348,
    longitude: -76.0961,
    postal_code: '23452'
  },
  '23453': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'City of Suffolk',
    latitude: 36.776,
    longitude: -76.0766,
    postal_code: '23453'
  },
  '23454': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.8282,
    longitude: -76.0237,
    postal_code: '23454'
  },
  '23455': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.8881,
    longitude: -76.1446,
    postal_code: '23455'
  },
  '23456': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.7341,
    longitude: -76.0359,
    postal_code: '23456'
  },
  '23457': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.6224,
    longitude: -76.0249,
    postal_code: '23457'
  },
  '23458': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.8529,
    longitude: -75.978,
    postal_code: '23458'
  },
  '23459': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.9216,
    longitude: -76.0171,
    postal_code: '23459'
  },
  '23460': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.808,
    longitude: -76.0284,
    postal_code: '23460'
  },
  '23461': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.7754,
    longitude: -75.9633,
    postal_code: '23461'
  },
  '23462': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.8392,
    longitude: -76.1522,
    postal_code: '23462'
  },
  '23463': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.7957,
    longitude: -76.0126,
    postal_code: '23463'
  },
  '23464': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.7978,
    longitude: -76.1759,
    postal_code: '23464'
  },
  '23465': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.8512,
    longitude: -76.1692,
    postal_code: '23465'
  },
  '23466': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.7957,
    longitude: -76.0126,
    postal_code: '23466'
  },
  '23467': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.7957,
    longitude: -76.0126,
    postal_code: '23467'
  },
  '23471': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.7957,
    longitude: -76.0126,
    postal_code: '23471'
  },
  '23479': {
    country: 'US',
    city: 'Virginia Beach',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Virginia Beach (city)',
    latitude: 36.7957,
    longitude: -76.0126,
    postal_code: '23479'
  },
  '23480': {
    country: 'US',
    city: 'Wachapreague',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.6044,
    longitude: -75.6925,
    postal_code: '23480'
  },
  '23482': {
    country: 'US',
    city: 'Wardtown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.5349,
    longitude: -75.8769,
    postal_code: '23482'
  },
  '23483': {
    country: 'US',
    city: 'Wattsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9437,
    longitude: -75.5023,
    postal_code: '23483'
  },
  '23486': {
    country: 'US',
    city: 'Willis Wharf',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Northampton',
    latitude: 37.5157,
    longitude: -75.8066,
    postal_code: '23486'
  },
  '23487': {
    country: 'US',
    city: 'Windsor',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Isle of Wight',
    latitude: 36.8369,
    longitude: -76.7324,
    postal_code: '23487'
  },
  '23488': {
    country: 'US',
    city: 'Withams',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Accomack',
    latitude: 37.9482,
    longitude: -75.6019,
    postal_code: '23488'
  },
  '23501': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8959,
    longitude: -76.2085,
    postal_code: '23501'
  },
  '23502': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8546,
    longitude: -76.2143,
    postal_code: '23502'
  },
  '23503': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.9442,
    longitude: -76.252,
    postal_code: '23503'
  },
  '23504': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8586,
    longitude: -76.2686,
    postal_code: '23504'
  },
  '23505': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.9168,
    longitude: -76.2875,
    postal_code: '23505'
  },
  '23506': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.9312,
    longitude: -76.2397,
    postal_code: '23506'
  },
  '23507': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8645,
    longitude: -76.3004,
    postal_code: '23507'
  },
  '23508': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8859,
    longitude: -76.3004,
    postal_code: '23508'
  },
  '23509': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8787,
    longitude: -76.2604,
    postal_code: '23509'
  },
  '23510': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8529,
    longitude: -76.2878,
    postal_code: '23510'
  },
  '23511': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.9356,
    longitude: -76.3034,
    postal_code: '23511'
  },
  '23513': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8914,
    longitude: -76.2396,
    postal_code: '23513'
  },
  '23514': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8468,
    longitude: -76.2852,
    postal_code: '23514'
  },
  '23515': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.9312,
    longitude: -76.2397,
    postal_code: '23515'
  },
  '23517': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8695,
    longitude: -76.2945,
    postal_code: '23517'
  },
  '23518': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.9202,
    longitude: -76.216,
    postal_code: '23518'
  },
  '23519': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.9312,
    longitude: -76.2397,
    postal_code: '23519'
  },
  '23523': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.8294,
    longitude: -76.2701,
    postal_code: '23523'
  },
  '23529': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.9312,
    longitude: -76.2397,
    postal_code: '23529'
  },
  '23541': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.9312,
    longitude: -76.2397,
    postal_code: '23541'
  },
  '23551': {
    country: 'US',
    city: 'Norfolk',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norfolk (city)',
    latitude: 36.9312,
    longitude: -76.2397,
    postal_code: '23551'
  },
  '23601': {
    country: 'US',
    city: 'Newport News',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 37.058,
    longitude: -76.4607,
    postal_code: '23601'
  },
  '23602': {
    country: 'US',
    city: 'Newport News',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 37.1132,
    longitude: -76.5179,
    postal_code: '23602'
  },
  '23603': {
    country: 'US',
    city: 'Newport News',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 37.1989,
    longitude: -76.5821,
    postal_code: '23603'
  },
  '23604': {
    country: 'US',
    city: 'Fort Eustis',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 37.1574,
    longitude: -76.5845,
    postal_code: '23604'
  },
  '23605': {
    country: 'US',
    city: 'Newport News',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 37.0156,
    longitude: -76.4332,
    postal_code: '23605'
  },
  '23606': {
    country: 'US',
    city: 'Newport News',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 37.0768,
    longitude: -76.4967,
    postal_code: '23606'
  },
  '23607': {
    country: 'US',
    city: 'Newport News',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 36.9864,
    longitude: -76.4165,
    postal_code: '23607'
  },
  '23608': {
    country: 'US',
    city: 'Newport News',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 37.1526,
    longitude: -76.542,
    postal_code: '23608'
  },
  '23609': {
    country: 'US',
    city: 'Newport News',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 37.1959,
    longitude: -76.5248,
    postal_code: '23609'
  },
  '23612': {
    country: 'US',
    city: 'Newport News',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 37.1959,
    longitude: -76.5248,
    postal_code: '23612'
  },
  '23628': {
    country: 'US',
    city: 'Newport News',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Newport News (city)',
    latitude: 37.1959,
    longitude: -76.5248,
    postal_code: '23628'
  },
  '23630': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0727,
    longitude: -76.3899,
    postal_code: '23630'
  },
  '23651': {
    country: 'US',
    city: 'Fort Monroe',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.018,
    longitude: -76.3044,
    postal_code: '23651'
  },
  '23661': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0074,
    longitude: -76.3801,
    postal_code: '23661'
  },
  '23662': {
    country: 'US',
    city: 'Poquoson',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Poquoson (city)',
    latitude: 37.1313,
    longitude: -76.3807,
    postal_code: '23662'
  },
  '23663': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0318,
    longitude: -76.3199,
    postal_code: '23663'
  },
  '23664': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0566,
    longitude: -76.2966,
    postal_code: '23664'
  },
  '23665': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0831,
    longitude: -76.36,
    postal_code: '23665'
  },
  '23666': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0462,
    longitude: -76.4096,
    postal_code: '23666'
  },
  '23667': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0193,
    longitude: -76.3318,
    postal_code: '23667'
  },
  '23668': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0206,
    longitude: -76.3377,
    postal_code: '23668'
  },
  '23669': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0436,
    longitude: -76.3426,
    postal_code: '23669'
  },
  '23670': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0727,
    longitude: -76.3899,
    postal_code: '23670'
  },
  '23681': {
    country: 'US',
    city: 'Hampton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hampton (city)',
    latitude: 37.0727,
    longitude: -76.3899,
    postal_code: '23681'
  },
  '23690': {
    country: 'US',
    city: 'Yorktown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'York',
    latitude: 37.2287,
    longitude: -76.5423,
    postal_code: '23690'
  },
  '23691': {
    country: 'US',
    city: 'Yorktown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'York',
    latitude: 37.2517,
    longitude: -76.552,
    postal_code: '23691'
  },
  '23692': {
    country: 'US',
    city: 'Yorktown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'York',
    latitude: 37.1686,
    longitude: -76.4581,
    postal_code: '23692'
  },
  '23693': {
    country: 'US',
    city: 'Yorktown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'York',
    latitude: 37.1256,
    longitude: -76.4469,
    postal_code: '23693'
  },
  '23694': {
    country: 'US',
    city: 'Lackey',
    state: 'Virginia',
    state_short: 'VA',
    county: 'York',
    latitude: 37.2232,
    longitude: -76.559,
    postal_code: '23694'
  },
  '23696': {
    country: 'US',
    city: 'Seaford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'York',
    latitude: 37.1885,
    longitude: -76.429,
    postal_code: '23696'
  },
  '23701': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Portsmouth (city)',
    latitude: 36.8089,
    longitude: -76.3671,
    postal_code: '23701'
  },
  '23702': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Portsmouth (city)',
    latitude: 36.8035,
    longitude: -76.327,
    postal_code: '23702'
  },
  '23703': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Portsmouth (city)',
    latitude: 36.8695,
    longitude: -76.3869,
    postal_code: '23703'
  },
  '23704': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Portsmouth (city)',
    latitude: 36.8298,
    longitude: -76.3146,
    postal_code: '23704'
  },
  '23705': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Portsmouth (city)',
    latitude: 36.8686,
    longitude: -76.3552,
    postal_code: '23705'
  },
  '23707': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Portsmouth (city)',
    latitude: 36.8362,
    longitude: -76.344,
    postal_code: '23707'
  },
  '23708': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Portsmouth (city)',
    latitude: 36.8354,
    longitude: -76.2983,
    postal_code: '23708'
  },
  '23709': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Portsmouth (city)',
    latitude: 36.8686,
    longitude: -76.3552,
    postal_code: '23709'
  },
  '23801': {
    country: 'US',
    city: 'Fort Lee',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince George',
    latitude: 37.2447,
    longitude: -77.3341,
    postal_code: '23801'
  },
  '23803': {
    country: 'US',
    city: 'Petersburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Petersburg (city)',
    latitude: 37.2123,
    longitude: -77.4814,
    postal_code: '23803'
  },
  '23804': {
    country: 'US',
    city: 'Petersburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Petersburg (city)',
    latitude: 37.2048,
    longitude: -77.3928,
    postal_code: '23804'
  },
  '23805': {
    country: 'US',
    city: 'Petersburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Petersburg (city)',
    latitude: 37.1819,
    longitude: -77.3854,
    postal_code: '23805'
  },
  '23806': {
    country: 'US',
    city: 'Virginia State University',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Petersburg (city)',
    latitude: 37.2327,
    longitude: -77.385,
    postal_code: '23806'
  },
  '23821': {
    country: 'US',
    city: 'Alberta',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.8806,
    longitude: -77.9055,
    postal_code: '23821'
  },
  '23822': {
    country: 'US',
    city: 'Ammon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dinwiddie',
    latitude: 37.0724,
    longitude: -77.6475,
    postal_code: '23822'
  },
  '23824': {
    country: 'US',
    city: 'Blackstone',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nottoway',
    latitude: 37.0916,
    longitude: -77.9851,
    postal_code: '23824'
  },
  '23827': {
    country: 'US',
    city: 'Boykins',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Southampton',
    latitude: 36.5951,
    longitude: -77.1975,
    postal_code: '23827'
  },
  '23828': {
    country: 'US',
    city: 'Branchville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Southampton',
    latitude: 36.5787,
    longitude: -77.2704,
    postal_code: '23828'
  },
  '23829': {
    country: 'US',
    city: 'Capron',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Southampton',
    latitude: 36.7243,
    longitude: -77.2394,
    postal_code: '23829'
  },
  '23830': {
    country: 'US',
    city: 'Carson',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dinwiddie',
    latitude: 37.0393,
    longitude: -77.4351,
    postal_code: '23830'
  },
  '23831': {
    country: 'US',
    city: 'Chester',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.3429,
    longitude: -77.4156,
    postal_code: '23831'
  },
  '23832': {
    country: 'US',
    city: 'Chesterfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.3923,
    longitude: -77.5668,
    postal_code: '23832'
  },
  '23833': {
    country: 'US',
    city: 'Church Road',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dinwiddie',
    latitude: 37.195,
    longitude: -77.6646,
    postal_code: '23833'
  },
  '23834': {
    country: 'US',
    city: 'Colonial Heights',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Colonial Heights (city',
    latitude: 37.27,
    longitude: -77.4038,
    postal_code: '23834'
  },
  '23836': {
    country: 'US',
    city: 'Chester',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.3475,
    longitude: -77.3386,
    postal_code: '23836'
  },
  '23837': {
    country: 'US',
    city: 'Courtland',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Southampton',
    latitude: 36.7225,
    longitude: -77.0783,
    postal_code: '23837'
  },
  '23838': {
    country: 'US',
    city: 'Chesterfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Chesterfield',
    latitude: 37.3333,
    longitude: -77.6343,
    postal_code: '23838'
  },
  '23839': {
    country: 'US',
    city: 'Dendron',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Surry',
    latitude: 37.0981,
    longitude: -76.8966,
    postal_code: '23839'
  },
  '23840': {
    country: 'US',
    city: 'Dewitt',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dinwiddie',
    latitude: 37.0535,
    longitude: -77.6426,
    postal_code: '23840'
  },
  '23841': {
    country: 'US',
    city: 'Dinwiddie',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dinwiddie',
    latitude: 37.0833,
    longitude: -77.5585,
    postal_code: '23841'
  },
  '23842': {
    country: 'US',
    city: 'Disputanta',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince George',
    latitude: 37.1483,
    longitude: -77.2732,
    postal_code: '23842'
  },
  '23843': {
    country: 'US',
    city: 'Dolphin',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.8318,
    longitude: -77.7887,
    postal_code: '23843'
  },
  '23844': {
    country: 'US',
    city: 'Drewryville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Southampton',
    latitude: 36.6854,
    longitude: -77.3591,
    postal_code: '23844'
  },
  '23845': {
    country: 'US',
    city: 'Ebony',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.5722,
    longitude: -77.9888,
    postal_code: '23845'
  },
  '23846': {
    country: 'US',
    city: 'Elberon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Surry',
    latitude: 37.0701,
    longitude: -76.8337,
    postal_code: '23846'
  },
  '23847': {
    country: 'US',
    city: 'Emporia',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Emporia (city)',
    latitude: 36.6857,
    longitude: -77.563,
    postal_code: '23847'
  },
  '23850': {
    country: 'US',
    city: 'Ford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dinwiddie',
    latitude: 37.1349,
    longitude: -77.7364,
    postal_code: '23850'
  },
  '23851': {
    country: 'US',
    city: 'Franklin',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin (city)',
    latitude: 36.6786,
    longitude: -76.9391,
    postal_code: '23851'
  },
  '23856': {
    country: 'US',
    city: 'Freeman',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.7893,
    longitude: -77.7208,
    postal_code: '23856'
  },
  '23857': {
    country: 'US',
    city: 'Gasburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.5856,
    longitude: -77.8836,
    postal_code: '23857'
  },
  '23860': {
    country: 'US',
    city: 'Hopewell',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Hopewell (city)',
    latitude: 37.2876,
    longitude: -77.295,
    postal_code: '23860'
  },
  '23866': {
    country: 'US',
    city: 'Ivor',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Southampton',
    latitude: 36.9071,
    longitude: -76.8861,
    postal_code: '23866'
  },
  '23867': {
    country: 'US',
    city: 'Jarratt',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Sussex',
    latitude: 36.8191,
    longitude: -77.4832,
    postal_code: '23867'
  },
  '23868': {
    country: 'US',
    city: 'Lawrenceville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.7342,
    longitude: -77.8136,
    postal_code: '23868'
  },
  '23870': {
    country: 'US',
    city: 'Jarratt',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Greensville',
    latitude: 36.8143,
    longitude: -77.4683,
    postal_code: '23870'
  },
  '23872': {
    country: 'US',
    city: 'Mc Kenney',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dinwiddie',
    latitude: 36.9986,
    longitude: -77.7396,
    postal_code: '23872'
  },
  '23873': {
    country: 'US',
    city: 'Meredithville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.8088,
    longitude: -77.9544,
    postal_code: '23873'
  },
  '23874': {
    country: 'US',
    city: 'Newsoms',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Southampton',
    latitude: 36.6148,
    longitude: -77.1069,
    postal_code: '23874'
  },
  '23875': {
    country: 'US',
    city: 'Prince George',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince George',
    latitude: 37.2333,
    longitude: -77.2747,
    postal_code: '23875'
  },
  '23876': {
    country: 'US',
    city: 'Rawlings',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.953,
    longitude: -77.8237,
    postal_code: '23876'
  },
  '23878': {
    country: 'US',
    city: 'Sedley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Southampton',
    latitude: 36.776,
    longitude: -76.9841,
    postal_code: '23878'
  },
  '23879': {
    country: 'US',
    city: 'Skippers',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Greensville',
    latitude: 36.5958,
    longitude: -77.5921,
    postal_code: '23879'
  },
  '23881': {
    country: 'US',
    city: 'Spring Grove',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Surry',
    latitude: 37.1901,
    longitude: -76.9923,
    postal_code: '23881'
  },
  '23882': {
    country: 'US',
    city: 'Stony Creek',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Sussex',
    latitude: 36.9361,
    longitude: -77.4443,
    postal_code: '23882'
  },
  '23883': {
    country: 'US',
    city: 'Surry',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Surry',
    latitude: 37.126,
    longitude: -76.7651,
    postal_code: '23883'
  },
  '23884': {
    country: 'US',
    city: 'Sussex',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Sussex',
    latitude: 36.9151,
    longitude: -77.2791,
    postal_code: '23884'
  },
  '23885': {
    country: 'US',
    city: 'Sutherland',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dinwiddie',
    latitude: 37.1901,
    longitude: -77.5655,
    postal_code: '23885'
  },
  '23887': {
    country: 'US',
    city: 'Valentines',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.565,
    longitude: -77.8384,
    postal_code: '23887'
  },
  '23888': {
    country: 'US',
    city: 'Wakefield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Sussex',
    latitude: 36.9757,
    longitude: -76.979,
    postal_code: '23888'
  },
  '23889': {
    country: 'US',
    city: 'Warfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.9011,
    longitude: -77.7673,
    postal_code: '23889'
  },
  '23890': {
    country: 'US',
    city: 'Waverly',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Sussex',
    latitude: 37.025,
    longitude: -77.1055,
    postal_code: '23890'
  },
  '23891': {
    country: 'US',
    city: 'Waverly',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Sussex',
    latitude: 37.0508,
    longitude: -77.2124,
    postal_code: '23891'
  },
  '23893': {
    country: 'US',
    city: 'White Plains',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.6335,
    longitude: -77.9592,
    postal_code: '23893'
  },
  '23894': {
    country: 'US',
    city: 'Wilsons',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dinwiddie',
    latitude: 37.1157,
    longitude: -77.8352,
    postal_code: '23894'
  },
  '23897': {
    country: 'US',
    city: 'Yale',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Sussex',
    latitude: 36.8373,
    longitude: -77.287,
    postal_code: '23897'
  },
  '23898': {
    country: 'US',
    city: 'Zuni',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Isle of Wight',
    latitude: 36.8437,
    longitude: -76.811,
    postal_code: '23898'
  },
  '23899': {
    country: 'US',
    city: 'Claremont',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Surry',
    latitude: 37.2279,
    longitude: -76.9641,
    postal_code: '23899'
  },
  '23901': {
    country: 'US',
    city: 'Farmville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince Edward',
    latitude: 37.3027,
    longitude: -78.4076,
    postal_code: '23901'
  },
  '23909': {
    country: 'US',
    city: 'Farmville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince Edward',
    latitude: 37.3016,
    longitude: -78.3949,
    postal_code: '23909'
  },
  '23915': {
    country: 'US',
    city: 'Baskerville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mecklenburg',
    latitude: 36.7236,
    longitude: -78.279,
    postal_code: '23915'
  },
  '23917': {
    country: 'US',
    city: 'Boydton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mecklenburg',
    latitude: 36.6544,
    longitude: -78.3752,
    postal_code: '23917'
  },
  '23919': {
    country: 'US',
    city: 'Bracey',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mecklenburg',
    latitude: 36.5763,
    longitude: -78.1059,
    postal_code: '23919'
  },
  '23920': {
    country: 'US',
    city: 'Brodnax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Brunswick',
    latitude: 36.7319,
    longitude: -77.9898,
    postal_code: '23920'
  },
  '23921': {
    country: 'US',
    city: 'Buckingham',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buckingham',
    latitude: 37.5833,
    longitude: -78.5985,
    postal_code: '23921'
  },
  '23922': {
    country: 'US',
    city: 'Burkeville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nottoway',
    latitude: 37.1952,
    longitude: -78.1961,
    postal_code: '23922'
  },
  '23923': {
    country: 'US',
    city: 'Charlotte Court House',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlotte',
    latitude: 37.0879,
    longitude: -78.6378,
    postal_code: '23923'
  },
  '23924': {
    country: 'US',
    city: 'Chase City',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mecklenburg',
    latitude: 36.8053,
    longitude: -78.4553,
    postal_code: '23924'
  },
  '23927': {
    country: 'US',
    city: 'Clarksville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mecklenburg',
    latitude: 36.624,
    longitude: -78.5569,
    postal_code: '23927'
  },
  '23930': {
    country: 'US',
    city: 'Crewe',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nottoway',
    latitude: 37.1657,
    longitude: -78.1059,
    postal_code: '23930'
  },
  '23934': {
    country: 'US',
    city: 'Cullen',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlotte',
    latitude: 37.1552,
    longitude: -78.6459,
    postal_code: '23934'
  },
  '23936': {
    country: 'US',
    city: 'Dillwyn',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buckingham',
    latitude: 37.4124,
    longitude: -78.4349,
    postal_code: '23936'
  },
  '23937': {
    country: 'US',
    city: 'Drakes Branch',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlotte',
    latitude: 36.9688,
    longitude: -78.5615,
    postal_code: '23937'
  },
  '23938': {
    country: 'US',
    city: 'Dundas',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lunenburg',
    latitude: 36.9053,
    longitude: -78.01,
    postal_code: '23938'
  },
  '23939': {
    country: 'US',
    city: 'Evergreen',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Appomattox',
    latitude: 37.3185,
    longitude: -78.7658,
    postal_code: '23939'
  },
  '23941': {
    country: 'US',
    city: 'Fort Mitchell',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lunenburg',
    latitude: 36.9182,
    longitude: -78.4861,
    postal_code: '23941'
  },
  '23942': {
    country: 'US',
    city: 'Green Bay',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince Edward',
    latitude: 37.1234,
    longitude: -78.3072,
    postal_code: '23942'
  },
  '23943': {
    country: 'US',
    city: 'Hampden Sydney',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince Edward',
    latitude: 37.2381,
    longitude: -78.4618,
    postal_code: '23943'
  },
  '23944': {
    country: 'US',
    city: 'Kenbridge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lunenburg',
    latitude: 36.933,
    longitude: -78.1242,
    postal_code: '23944'
  },
  '23947': {
    country: 'US',
    city: 'Keysville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlotte',
    latitude: 37.0411,
    longitude: -78.4699,
    postal_code: '23947'
  },
  '23950': {
    country: 'US',
    city: 'La Crosse',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mecklenburg',
    latitude: 36.6613,
    longitude: -78.0754,
    postal_code: '23950'
  },
  '23952': {
    country: 'US',
    city: 'Lunenburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lunenburg',
    latitude: 36.9291,
    longitude: -78.2915,
    postal_code: '23952'
  },
  '23954': {
    country: 'US',
    city: 'Meherrin',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince Edward',
    latitude: 37.1013,
    longitude: -78.3874,
    postal_code: '23954'
  },
  '23955': {
    country: 'US',
    city: 'Nottoway',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nottoway',
    latitude: 37.1161,
    longitude: -78.0578,
    postal_code: '23955'
  },
  '23958': {
    country: 'US',
    city: 'Pamplin',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Appomattox',
    latitude: 37.2653,
    longitude: -78.6517,
    postal_code: '23958'
  },
  '23959': {
    country: 'US',
    city: 'Phenix',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlotte',
    latitude: 37.0925,
    longitude: -78.7912,
    postal_code: '23959'
  },
  '23960': {
    country: 'US',
    city: 'Prospect',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince Edward',
    latitude: 37.3058,
    longitude: -78.5462,
    postal_code: '23960'
  },
  '23962': {
    country: 'US',
    city: 'Randolph',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlotte',
    latitude: 36.9515,
    longitude: -78.7054,
    postal_code: '23962'
  },
  '23963': {
    country: 'US',
    city: 'Red House',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlotte',
    latitude: 37.1914,
    longitude: -78.8145,
    postal_code: '23963'
  },
  '23964': {
    country: 'US',
    city: 'Red Oak',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlotte',
    latitude: 36.7724,
    longitude: -78.6321,
    postal_code: '23964'
  },
  '23966': {
    country: 'US',
    city: 'Rice',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Prince Edward',
    latitude: 37.2721,
    longitude: -78.2793,
    postal_code: '23966'
  },
  '23967': {
    country: 'US',
    city: 'Saxe',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlotte',
    latitude: 36.9053,
    longitude: -78.6321,
    postal_code: '23967'
  },
  '23968': {
    country: 'US',
    city: 'Skipwith',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mecklenburg',
    latitude: 36.7317,
    longitude: -78.5306,
    postal_code: '23968'
  },
  '23970': {
    country: 'US',
    city: 'South Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mecklenburg',
    latitude: 36.7124,
    longitude: -78.1534,
    postal_code: '23970'
  },
  '23974': {
    country: 'US',
    city: 'Victoria',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lunenburg',
    latitude: 36.9835,
    longitude: -78.2372,
    postal_code: '23974'
  },
  '23976': {
    country: 'US',
    city: 'Wylliesburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Charlotte',
    latitude: 36.8391,
    longitude: -78.5982,
    postal_code: '23976'
  },
  '24001': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24001'
  },
  '24002': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24002'
  },
  '24003': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24003'
  },
  '24004': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24004'
  },
  '24005': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24005'
  },
  '24006': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24006'
  },
  '24007': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24007'
  },
  '24008': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24008'
  },
  '24009': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24009'
  },
  '24010': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24010'
  },
  '24011': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.269,
    longitude: -79.942,
    postal_code: '24011'
  },
  '24012': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.3029,
    longitude: -79.9322,
    postal_code: '24012'
  },
  '24013': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2677,
    longitude: -79.9247,
    postal_code: '24013'
  },
  '24014': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2327,
    longitude: -79.9463,
    postal_code: '24014'
  },
  '24015': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2584,
    longitude: -79.9807,
    postal_code: '24015'
  },
  '24016': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2704,
    longitude: -79.9535,
    postal_code: '24016'
  },
  '24017': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2937,
    longitude: -79.9902,
    postal_code: '24017'
  },
  '24018': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke',
    latitude: 37.2503,
    longitude: -80.0525,
    postal_code: '24018'
  },
  '24019': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.3515,
    longitude: -79.9052,
    postal_code: '24019'
  },
  '24020': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke',
    latitude: 37.3589,
    longitude: -79.9448,
    postal_code: '24020'
  },
  '24022': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2784,
    longitude: -79.9332,
    postal_code: '24022'
  },
  '24023': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.271,
    longitude: -79.9414,
    postal_code: '24023'
  },
  '24024': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24024'
  },
  '24025': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24025'
  },
  '24026': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24026'
  },
  '24027': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24027'
  },
  '24028': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24028'
  },
  '24029': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24029'
  },
  '24030': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24030'
  },
  '24031': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24031'
  },
  '24032': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24032'
  },
  '24033': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24033'
  },
  '24034': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24034'
  },
  '24035': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24035'
  },
  '24036': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24036'
  },
  '24037': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24037'
  },
  '24038': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24038'
  },
  '24040': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2742,
    longitude: -79.9579,
    postal_code: '24040'
  },
  '24042': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2717,
    longitude: -79.9392,
    postal_code: '24042'
  },
  '24043': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke (city)',
    latitude: 37.2692,
    longitude: -79.9399,
    postal_code: '24043'
  },
  '24050': {
    country: 'US',
    city: 'Roanoke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.5551,
    longitude: -79.7862,
    postal_code: '24050'
  },
  '24053': {
    country: 'US',
    city: 'Ararat',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Patrick',
    latitude: 36.6139,
    longitude: -80.5093,
    postal_code: '24053'
  },
  '24054': {
    country: 'US',
    city: 'Axton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henry',
    latitude: 36.6596,
    longitude: -79.712,
    postal_code: '24054'
  },
  '24055': {
    country: 'US',
    city: 'Bassett',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henry',
    latitude: 36.7534,
    longitude: -80.0055,
    postal_code: '24055'
  },
  '24058': {
    country: 'US',
    city: 'Belspring',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pulaski',
    latitude: 37.1982,
    longitude: -80.6151,
    postal_code: '24058'
  },
  '24059': {
    country: 'US',
    city: 'Bent Mountain',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke',
    latitude: 37.1549,
    longitude: -80.1215,
    postal_code: '24059'
  },
  '24060': {
    country: 'US',
    city: 'Blacksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.2563,
    longitude: -80.4347,
    postal_code: '24060'
  },
  '24061': {
    country: 'US',
    city: 'Blacksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.1791,
    longitude: -80.3515,
    postal_code: '24061'
  },
  '24062': {
    country: 'US',
    city: 'Blacksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.2296,
    longitude: -80.4139,
    postal_code: '24062'
  },
  '24063': {
    country: 'US',
    city: 'Blacksburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.2296,
    longitude: -80.4139,
    postal_code: '24063'
  },
  '24064': {
    country: 'US',
    city: 'Blue Ridge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.3885,
    longitude: -79.8172,
    postal_code: '24064'
  },
  '24065': {
    country: 'US',
    city: 'Boones Mill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin',
    latitude: 37.1331,
    longitude: -79.9556,
    postal_code: '24065'
  },
  '24066': {
    country: 'US',
    city: 'Buchanan',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.5479,
    longitude: -79.7123,
    postal_code: '24066'
  },
  '24067': {
    country: 'US',
    city: 'Callaway',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin',
    latitude: 37.0285,
    longitude: -80.0496,
    postal_code: '24067'
  },
  '24068': {
    country: 'US',
    city: 'Christiansburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.1548,
    longitude: -80.4184,
    postal_code: '24068'
  },
  '24069': {
    country: 'US',
    city: 'Cascade',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.5927,
    longitude: -79.6574,
    postal_code: '24069'
  },
  '24070': {
    country: 'US',
    city: 'Catawba',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke',
    latitude: 37.3696,
    longitude: -80.1284,
    postal_code: '24070'
  },
  '24072': {
    country: 'US',
    city: 'Check',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Floyd',
    latitude: 37.0446,
    longitude: -80.228,
    postal_code: '24072'
  },
  '24073': {
    country: 'US',
    city: 'Christiansburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.1353,
    longitude: -80.4188,
    postal_code: '24073'
  },
  '24076': {
    country: 'US',
    city: 'Claudville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Patrick',
    latitude: 36.5896,
    longitude: -80.4184,
    postal_code: '24076'
  },
  '24077': {
    country: 'US',
    city: 'Cloverdale',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.3651,
    longitude: -79.9056,
    postal_code: '24077'
  },
  '24078': {
    country: 'US',
    city: 'Collinsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henry',
    latitude: 36.7238,
    longitude: -79.9142,
    postal_code: '24078'
  },
  '24079': {
    country: 'US',
    city: 'Copper Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Floyd',
    latitude: 37.0556,
    longitude: -80.1525,
    postal_code: '24079'
  },
  '24082': {
    country: 'US',
    city: 'Critz',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Patrick',
    latitude: 36.6211,
    longitude: -80.1298,
    postal_code: '24082'
  },
  '24083': {
    country: 'US',
    city: 'Daleville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.4125,
    longitude: -79.9211,
    postal_code: '24083'
  },
  '24084': {
    country: 'US',
    city: 'Dublin',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pulaski',
    latitude: 37.1057,
    longitude: -80.6853,
    postal_code: '24084'
  },
  '24085': {
    country: 'US',
    city: 'Eagle Rock',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.6667,
    longitude: -79.8172,
    postal_code: '24085'
  },
  '24086': {
    country: 'US',
    city: 'Eggleston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Giles',
    latitude: 37.2743,
    longitude: -80.6343,
    postal_code: '24086'
  },
  '24087': {
    country: 'US',
    city: 'Elliston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke',
    latitude: 37.2165,
    longitude: -80.2325,
    postal_code: '24087'
  },
  '24088': {
    country: 'US',
    city: 'Ferrum',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin',
    latitude: 36.9168,
    longitude: -80.0349,
    postal_code: '24088'
  },
  '24089': {
    country: 'US',
    city: 'Fieldale',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henry',
    latitude: 36.7064,
    longitude: -79.9652,
    postal_code: '24089'
  },
  '24090': {
    country: 'US',
    city: 'Fincastle',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.4911,
    longitude: -79.8511,
    postal_code: '24090'
  },
  '24091': {
    country: 'US',
    city: 'Floyd',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Floyd',
    latitude: 36.8957,
    longitude: -80.3275,
    postal_code: '24091'
  },
  '24092': {
    country: 'US',
    city: 'Glade Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin',
    latitude: 36.9859,
    longitude: -79.773,
    postal_code: '24092'
  },
  '24093': {
    country: 'US',
    city: 'Glen Lyn',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Giles',
    latitude: 37.3669,
    longitude: -80.8634,
    postal_code: '24093'
  },
  '24095': {
    country: 'US',
    city: 'Goodview',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.2123,
    longitude: -79.7429,
    postal_code: '24095'
  },
  '24101': {
    country: 'US',
    city: 'Hardy',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.2145,
    longitude: -79.8127,
    postal_code: '24101'
  },
  '24102': {
    country: 'US',
    city: 'Henry',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin',
    latitude: 36.8393,
    longitude: -79.9904,
    postal_code: '24102'
  },
  '24104': {
    country: 'US',
    city: 'Huddleston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.1442,
    longitude: -79.491,
    postal_code: '24104'
  },
  '24105': {
    country: 'US',
    city: 'Indian Valley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Floyd',
    latitude: 36.899,
    longitude: -80.5757,
    postal_code: '24105'
  },
  '24111': {
    country: 'US',
    city: 'Mc Coy',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.1742,
    longitude: -80.3957,
    postal_code: '24111'
  },
  '24112': {
    country: 'US',
    city: 'Martinsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Martinsville (city)',
    latitude: 36.6871,
    longitude: -79.8691,
    postal_code: '24112'
  },
  '24113': {
    country: 'US',
    city: 'Martinsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Martinsville (city)',
    latitude: 36.6796,
    longitude: -79.8652,
    postal_code: '24113'
  },
  '24114': {
    country: 'US',
    city: 'Martinsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Martinsville (city)',
    latitude: 36.6796,
    longitude: -79.8652,
    postal_code: '24114'
  },
  '24115': {
    country: 'US',
    city: 'Martinsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Martinsville (city)',
    latitude: 36.6796,
    longitude: -79.8652,
    postal_code: '24115'
  },
  '24120': {
    country: 'US',
    city: 'Meadows Of Dan',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Patrick',
    latitude: 36.725,
    longitude: -80.4022,
    postal_code: '24120'
  },
  '24121': {
    country: 'US',
    city: 'Moneta',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.1784,
    longitude: -79.6521,
    postal_code: '24121'
  },
  '24122': {
    country: 'US',
    city: 'Montvale',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.407,
    longitude: -79.7175,
    postal_code: '24122'
  },
  '24124': {
    country: 'US',
    city: 'Narrows',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Giles',
    latitude: 37.3198,
    longitude: -80.8548,
    postal_code: '24124'
  },
  '24126': {
    country: 'US',
    city: 'Newbern',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pulaski',
    latitude: 37.0692,
    longitude: -80.6891,
    postal_code: '24126'
  },
  '24127': {
    country: 'US',
    city: 'New Castle',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Craig',
    latitude: 37.4871,
    longitude: -80.1704,
    postal_code: '24127'
  },
  '24128': {
    country: 'US',
    city: 'Newport',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Giles',
    latitude: 37.3069,
    longitude: -80.5099,
    postal_code: '24128'
  },
  '24129': {
    country: 'US',
    city: 'New River',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pulaski',
    latitude: 37.0964,
    longitude: -80.6081,
    postal_code: '24129'
  },
  '24130': {
    country: 'US',
    city: 'Oriskany',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.6165,
    longitude: -79.9837,
    postal_code: '24130'
  },
  '24131': {
    country: 'US',
    city: 'Paint Bank',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Craig',
    latitude: 37.5745,
    longitude: -80.2544,
    postal_code: '24131'
  },
  '24132': {
    country: 'US',
    city: 'Parrott',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pulaski',
    latitude: 37.2048,
    longitude: -80.6205,
    postal_code: '24132'
  },
  '24133': {
    country: 'US',
    city: 'Patrick Springs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Patrick',
    latitude: 36.6744,
    longitude: -80.1388,
    postal_code: '24133'
  },
  '24134': {
    country: 'US',
    city: 'Pearisburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Giles',
    latitude: 37.3041,
    longitude: -80.7267,
    postal_code: '24134'
  },
  '24136': {
    country: 'US',
    city: 'Pembroke',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Giles',
    latitude: 37.3312,
    longitude: -80.5975,
    postal_code: '24136'
  },
  '24137': {
    country: 'US',
    city: 'Penhook',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin',
    latitude: 36.9201,
    longitude: -79.6645,
    postal_code: '24137'
  },
  '24138': {
    country: 'US',
    city: 'Pilot',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.0565,
    longitude: -80.3229,
    postal_code: '24138'
  },
  '24139': {
    country: 'US',
    city: 'Pittsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.9716,
    longitude: -79.4794,
    postal_code: '24139'
  },
  '24141': {
    country: 'US',
    city: 'Radford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Radford (city)',
    latitude: 37.1197,
    longitude: -80.5565,
    postal_code: '24141'
  },
  '24142': {
    country: 'US',
    city: 'Radford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Radford (city)',
    latitude: 37.1387,
    longitude: -80.548,
    postal_code: '24142'
  },
  '24143': {
    country: 'US',
    city: 'Radford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Radford (city)',
    latitude: 37.1226,
    longitude: -80.5629,
    postal_code: '24143'
  },
  '24146': {
    country: 'US',
    city: 'Redwood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin',
    latitude: 37.0069,
    longitude: -79.9139,
    postal_code: '24146'
  },
  '24147': {
    country: 'US',
    city: 'Rich Creek',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Giles',
    latitude: 37.385,
    longitude: -80.8227,
    postal_code: '24147'
  },
  '24148': {
    country: 'US',
    city: 'Ridgeway',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henry',
    latitude: 36.5874,
    longitude: -79.8686,
    postal_code: '24148'
  },
  '24149': {
    country: 'US',
    city: 'Riner',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.0322,
    longitude: -80.4353,
    postal_code: '24149'
  },
  '24150': {
    country: 'US',
    city: 'Ripplemead',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Giles',
    latitude: 37.3665,
    longitude: -80.6717,
    postal_code: '24150'
  },
  '24151': {
    country: 'US',
    city: 'Rocky Mount',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin',
    latitude: 36.9891,
    longitude: -79.884,
    postal_code: '24151'
  },
  '24153': {
    country: 'US',
    city: 'Salem',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Salem (city)',
    latitude: 37.2853,
    longitude: -80.0692,
    postal_code: '24153'
  },
  '24155': {
    country: 'US',
    city: 'Salem',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Salem (city)',
    latitude: 37.2884,
    longitude: -80.0671,
    postal_code: '24155'
  },
  '24157': {
    country: 'US',
    city: 'Salem',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Salem (city)',
    latitude: 37.2884,
    longitude: -80.0671,
    postal_code: '24157'
  },
  '24161': {
    country: 'US',
    city: 'Sandy Level',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.991,
    longitude: -79.5614,
    postal_code: '24161'
  },
  '24162': {
    country: 'US',
    city: 'Shawsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Montgomery',
    latitude: 37.1466,
    longitude: -80.2715,
    postal_code: '24162'
  },
  '24165': {
    country: 'US',
    city: 'Spencer',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henry',
    latitude: 36.5968,
    longitude: -80.0373,
    postal_code: '24165'
  },
  '24167': {
    country: 'US',
    city: 'Staffordsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Giles',
    latitude: 37.2491,
    longitude: -80.7344,
    postal_code: '24167'
  },
  '24168': {
    country: 'US',
    city: 'Stanleytown',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Henry',
    latitude: 36.7348,
    longitude: -79.9465,
    postal_code: '24168'
  },
  '24171': {
    country: 'US',
    city: 'Stuart',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Patrick',
    latitude: 36.6517,
    longitude: -80.2392,
    postal_code: '24171'
  },
  '24174': {
    country: 'US',
    city: 'Thaxton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.36,
    longitude: -79.6522,
    postal_code: '24174'
  },
  '24175': {
    country: 'US',
    city: 'Troutville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.4019,
    longitude: -79.8786,
    postal_code: '24175'
  },
  '24176': {
    country: 'US',
    city: 'Union Hall',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin',
    latitude: 37.0131,
    longitude: -79.6864,
    postal_code: '24176'
  },
  '24177': {
    country: 'US',
    city: 'Vesta',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Patrick',
    latitude: 36.7241,
    longitude: -80.3581,
    postal_code: '24177'
  },
  '24178': {
    country: 'US',
    city: 'Villamont',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.3105,
    longitude: -79.7898,
    postal_code: '24178'
  },
  '24179': {
    country: 'US',
    city: 'Vinton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Roanoke',
    latitude: 37.2757,
    longitude: -79.8775,
    postal_code: '24179'
  },
  '24184': {
    country: 'US',
    city: 'Wirtz',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Franklin',
    latitude: 37.0818,
    longitude: -79.7573,
    postal_code: '24184'
  },
  '24185': {
    country: 'US',
    city: 'Woolwine',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Patrick',
    latitude: 36.7921,
    longitude: -80.2774,
    postal_code: '24185'
  },
  '24201': {
    country: 'US',
    city: 'Bristol',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bristol (city)',
    latitude: 36.6181,
    longitude: -82.1823,
    postal_code: '24201'
  },
  '24202': {
    country: 'US',
    city: 'Bristol',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bristol (city)',
    latitude: 36.6609,
    longitude: -82.2126,
    postal_code: '24202'
  },
  '24203': {
    country: 'US',
    city: 'Bristol',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bristol (city)',
    latitude: 36.5965,
    longitude: -82.1885,
    postal_code: '24203'
  },
  '24205': {
    country: 'US',
    city: 'Bristol',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bristol (city)',
    latitude: 36.595,
    longitude: -82.1819,
    postal_code: '24205'
  },
  '24209': {
    country: 'US',
    city: 'Bristol',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bristol (city)',
    latitude: 36.5965,
    longitude: -82.1885,
    postal_code: '24209'
  },
  '24210': {
    country: 'US',
    city: 'Abingdon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Washington',
    latitude: 36.6916,
    longitude: -82.02,
    postal_code: '24210'
  },
  '24211': {
    country: 'US',
    city: 'Abingdon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Washington',
    latitude: 36.6673,
    longitude: -81.9648,
    postal_code: '24211'
  },
  '24212': {
    country: 'US',
    city: 'Abingdon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Washington',
    latitude: 36.6909,
    longitude: -81.9708,
    postal_code: '24212'
  },
  '24215': {
    country: 'US',
    city: 'Andover',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wise',
    latitude: 36.9234,
    longitude: -82.7964,
    postal_code: '24215'
  },
  '24216': {
    country: 'US',
    city: 'Appalachia',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wise',
    latitude: 36.9438,
    longitude: -82.7976,
    postal_code: '24216'
  },
  '24217': {
    country: 'US',
    city: 'Bee',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dickenson',
    latitude: 37.1014,
    longitude: -82.1856,
    postal_code: '24217'
  },
  '24218': {
    country: 'US',
    city: 'Ben Hur',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lee',
    latitude: 36.7435,
    longitude: -83.2234,
    postal_code: '24218'
  },
  '24219': {
    country: 'US',
    city: 'Big Stone Gap',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wise',
    latitude: 36.8581,
    longitude: -82.7627,
    postal_code: '24219'
  },
  '24220': {
    country: 'US',
    city: 'Birchleaf',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dickenson',
    latitude: 37.1394,
    longitude: -82.2433,
    postal_code: '24220'
  },
  '24221': {
    country: 'US',
    city: 'Blackwater',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lee',
    latitude: 36.639,
    longitude: -82.9866,
    postal_code: '24221'
  },
  '24224': {
    country: 'US',
    city: 'Castlewood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Russell',
    latitude: 36.8764,
    longitude: -82.2876,
    postal_code: '24224'
  },
  '24225': {
    country: 'US',
    city: 'Cleveland',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Russell',
    latitude: 36.9502,
    longitude: -82.1318,
    postal_code: '24225'
  },
  '24226': {
    country: 'US',
    city: 'Clinchco',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dickenson',
    latitude: 37.1406,
    longitude: -82.3425,
    postal_code: '24226'
  },
  '24228': {
    country: 'US',
    city: 'Clintwood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dickenson',
    latitude: 37.1592,
    longitude: -82.4453,
    postal_code: '24228'
  },
  '24230': {
    country: 'US',
    city: 'Coeburn',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wise',
    latitude: 36.9605,
    longitude: -82.4735,
    postal_code: '24230'
  },
  '24236': {
    country: 'US',
    city: 'Damascus',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Washington',
    latitude: 36.6403,
    longitude: -81.7776,
    postal_code: '24236'
  },
  '24237': {
    country: 'US',
    city: 'Dante',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Russell',
    latitude: 37.0054,
    longitude: -82.2815,
    postal_code: '24237'
  },
  '24239': {
    country: 'US',
    city: 'Davenport',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.1134,
    longitude: -82.1501,
    postal_code: '24239'
  },
  '24243': {
    country: 'US',
    city: 'Dryden',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lee',
    latitude: 36.7818,
    longitude: -82.9305,
    postal_code: '24243'
  },
  '24244': {
    country: 'US',
    city: 'Duffield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Scott',
    latitude: 36.7114,
    longitude: -82.8337,
    postal_code: '24244'
  },
  '24245': {
    country: 'US',
    city: 'Dungannon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Scott',
    latitude: 36.8242,
    longitude: -82.496,
    postal_code: '24245'
  },
  '24246': {
    country: 'US',
    city: 'East Stone Gap',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wise',
    latitude: 36.8668,
    longitude: -82.7424,
    postal_code: '24246'
  },
  '24248': {
    country: 'US',
    city: 'Ewing',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lee',
    latitude: 36.6237,
    longitude: -83.5047,
    postal_code: '24248'
  },
  '24250': {
    country: 'US',
    city: 'Fort Blackmore',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Scott',
    latitude: 36.7438,
    longitude: -82.6102,
    postal_code: '24250'
  },
  '24251': {
    country: 'US',
    city: 'Gate City',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Scott',
    latitude: 36.646,
    longitude: -82.6112,
    postal_code: '24251'
  },
  '24256': {
    country: 'US',
    city: 'Haysi',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dickenson',
    latitude: 37.2206,
    longitude: -82.2853,
    postal_code: '24256'
  },
  '24258': {
    country: 'US',
    city: 'Hiltons',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Scott',
    latitude: 36.6498,
    longitude: -82.4299,
    postal_code: '24258'
  },
  '24260': {
    country: 'US',
    city: 'Honaker',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Russell',
    latitude: 37.0395,
    longitude: -82.0246,
    postal_code: '24260'
  },
  '24263': {
    country: 'US',
    city: 'Jonesville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lee',
    latitude: 36.6896,
    longitude: -83.1362,
    postal_code: '24263'
  },
  '24265': {
    country: 'US',
    city: 'Keokee',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lee',
    latitude: 36.8239,
    longitude: -82.9772,
    postal_code: '24265'
  },
  '24266': {
    country: 'US',
    city: 'Lebanon',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Russell',
    latitude: 36.8809,
    longitude: -82.0956,
    postal_code: '24266'
  },
  '24269': {
    country: 'US',
    city: 'Mc Clure',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dickenson',
    latitude: 37.0814,
    longitude: -82.3806,
    postal_code: '24269'
  },
  '24270': {
    country: 'US',
    city: 'Mendota',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Washington',
    latitude: 36.7223,
    longitude: -82.2649,
    postal_code: '24270'
  },
  '24271': {
    country: 'US',
    city: 'Nickelsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Scott',
    latitude: 36.7502,
    longitude: -82.4202,
    postal_code: '24271'
  },
  '24272': {
    country: 'US',
    city: 'Nora',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dickenson',
    latitude: 37.0182,
    longitude: -82.35,
    postal_code: '24272'
  },
  '24273': {
    country: 'US',
    city: 'Norton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Norton (city)',
    latitude: 36.9378,
    longitude: -82.6249,
    postal_code: '24273'
  },
  '24277': {
    country: 'US',
    city: 'Pennington Gap',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lee',
    latitude: 36.7508,
    longitude: -83.0223,
    postal_code: '24277'
  },
  '24279': {
    country: 'US',
    city: 'Pound',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wise',
    latitude: 37.0927,
    longitude: -82.6016,
    postal_code: '24279'
  },
  '24280': {
    country: 'US',
    city: 'Rosedale',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Russell',
    latitude: 36.9593,
    longitude: -81.9312,
    postal_code: '24280'
  },
  '24281': {
    country: 'US',
    city: 'Rose Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lee',
    latitude: 36.6583,
    longitude: -83.3486,
    postal_code: '24281'
  },
  '24282': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lee',
    latitude: 36.8315,
    longitude: -83.0518,
    postal_code: '24282'
  },
  '24283': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wise',
    latitude: 36.9323,
    longitude: -82.3418,
    postal_code: '24283'
  },
  '24290': {
    country: 'US',
    city: 'Weber City',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Scott',
    latitude: 36.6137,
    longitude: -82.5618,
    postal_code: '24290'
  },
  '24292': {
    country: 'US',
    city: 'Whitetop',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Grayson',
    latitude: 36.6106,
    longitude: -81.5839,
    postal_code: '24292'
  },
  '24293': {
    country: 'US',
    city: 'Wise',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wise',
    latitude: 36.975,
    longitude: -82.5947,
    postal_code: '24293'
  },
  '24301': {
    country: 'US',
    city: 'Pulaski',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pulaski',
    latitude: 37.0567,
    longitude: -80.771,
    postal_code: '24301'
  },
  '24311': {
    country: 'US',
    city: 'Atkins',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Smyth',
    latitude: 36.8665,
    longitude: -81.4048,
    postal_code: '24311'
  },
  '24312': {
    country: 'US',
    city: 'Austinville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wythe',
    latitude: 36.8195,
    longitude: -80.8583,
    postal_code: '24312'
  },
  '24313': {
    country: 'US',
    city: 'Barren Springs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wythe',
    latitude: 36.9078,
    longitude: -80.809,
    postal_code: '24313'
  },
  '24314': {
    country: 'US',
    city: 'Bastian',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bland',
    latitude: 37.1574,
    longitude: -81.1989,
    postal_code: '24314'
  },
  '24315': {
    country: 'US',
    city: 'Bland',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bland',
    latitude: 37.1376,
    longitude: -81.0201,
    postal_code: '24315'
  },
  '24316': {
    country: 'US',
    city: 'Broadford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Smyth',
    latitude: 36.9546,
    longitude: -81.673,
    postal_code: '24316'
  },
  '24317': {
    country: 'US',
    city: 'Cana',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Carroll',
    latitude: 36.5957,
    longitude: -80.6705,
    postal_code: '24317'
  },
  '24318': {
    country: 'US',
    city: 'Ceres',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bland',
    latitude: 37.0046,
    longitude: -81.3643,
    postal_code: '24318'
  },
  '24319': {
    country: 'US',
    city: 'Chilhowie',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Smyth',
    latitude: 36.7719,
    longitude: -81.6651,
    postal_code: '24319'
  },
  '24322': {
    country: 'US',
    city: 'Cripple Creek',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wythe',
    latitude: 36.8086,
    longitude: -81.1039,
    postal_code: '24322'
  },
  '24323': {
    country: 'US',
    city: 'Crockett',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wythe',
    latitude: 36.8768,
    longitude: -81.2089,
    postal_code: '24323'
  },
  '24324': {
    country: 'US',
    city: 'Draper',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pulaski',
    latitude: 36.9697,
    longitude: -80.8188,
    postal_code: '24324'
  },
  '24325': {
    country: 'US',
    city: 'Dugspur',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Carroll',
    latitude: 36.8145,
    longitude: -80.6123,
    postal_code: '24325'
  },
  '24326': {
    country: 'US',
    city: 'Elk Creek',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Grayson',
    latitude: 36.7306,
    longitude: -81.1915,
    postal_code: '24326'
  },
  '24327': {
    country: 'US',
    city: 'Emory',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Washington',
    latitude: 36.78,
    longitude: -81.8171,
    postal_code: '24327'
  },
  '24328': {
    country: 'US',
    city: 'Fancy Gap',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Carroll',
    latitude: 36.664,
    longitude: -80.6907,
    postal_code: '24328'
  },
  '24330': {
    country: 'US',
    city: 'Fries',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Grayson',
    latitude: 36.7247,
    longitude: -81.0042,
    postal_code: '24330'
  },
  '24333': {
    country: 'US',
    city: 'Galax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Galax (city)',
    latitude: 36.6565,
    longitude: -80.9117,
    postal_code: '24333'
  },
  '24340': {
    country: 'US',
    city: 'Glade Spring',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Washington',
    latitude: 36.7779,
    longitude: -81.7676,
    postal_code: '24340'
  },
  '24343': {
    country: 'US',
    city: 'Hillsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Carroll',
    latitude: 36.7442,
    longitude: -80.7197,
    postal_code: '24343'
  },
  '24347': {
    country: 'US',
    city: 'Hiwassee',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pulaski',
    latitude: 36.9756,
    longitude: -80.6695,
    postal_code: '24347'
  },
  '24348': {
    country: 'US',
    city: 'Independence',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Grayson',
    latitude: 36.6294,
    longitude: -81.1582,
    postal_code: '24348'
  },
  '24350': {
    country: 'US',
    city: 'Ivanhoe',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wythe',
    latitude: 36.8272,
    longitude: -80.9779,
    postal_code: '24350'
  },
  '24351': {
    country: 'US',
    city: 'Lambsburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Carroll',
    latitude: 36.5775,
    longitude: -80.7601,
    postal_code: '24351'
  },
  '24352': {
    country: 'US',
    city: 'Laurel Fork',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Carroll',
    latitude: 36.7073,
    longitude: -80.5148,
    postal_code: '24352'
  },
  '24354': {
    country: 'US',
    city: 'Marion',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Smyth',
    latitude: 36.8273,
    longitude: -81.5349,
    postal_code: '24354'
  },
  '24360': {
    country: 'US',
    city: 'Max Meadows',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wythe',
    latitude: 36.9056,
    longitude: -80.9244,
    postal_code: '24360'
  },
  '24361': {
    country: 'US',
    city: 'Meadowview',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Washington',
    latitude: 36.7612,
    longitude: -81.8512,
    postal_code: '24361'
  },
  '24363': {
    country: 'US',
    city: 'Mouth Of Wilson',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Grayson',
    latitude: 36.6104,
    longitude: -81.3955,
    postal_code: '24363'
  },
  '24366': {
    country: 'US',
    city: 'Rocky Gap',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bland',
    latitude: 37.2578,
    longitude: -81.1159,
    postal_code: '24366'
  },
  '24368': {
    country: 'US',
    city: 'Rural Retreat',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wythe',
    latitude: 36.8836,
    longitude: -81.2879,
    postal_code: '24368'
  },
  '24370': {
    country: 'US',
    city: 'Saltville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Smyth',
    latitude: 36.8927,
    longitude: -81.7402,
    postal_code: '24370'
  },
  '24374': {
    country: 'US',
    city: 'Speedwell',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wythe',
    latitude: 36.7998,
    longitude: -81.1834,
    postal_code: '24374'
  },
  '24375': {
    country: 'US',
    city: 'Sugar Grove',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Smyth',
    latitude: 36.7736,
    longitude: -81.4084,
    postal_code: '24375'
  },
  '24377': {
    country: 'US',
    city: 'Tannersville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 36.9763,
    longitude: -81.628,
    postal_code: '24377'
  },
  '24378': {
    country: 'US',
    city: 'Troutdale',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Grayson',
    latitude: 36.8611,
    longitude: -81.4929,
    postal_code: '24378'
  },
  '24380': {
    country: 'US',
    city: 'Willis',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Floyd',
    latitude: 36.8749,
    longitude: -80.4909,
    postal_code: '24380'
  },
  '24381': {
    country: 'US',
    city: 'Woodlawn',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Carroll',
    latitude: 36.7879,
    longitude: -80.8824,
    postal_code: '24381'
  },
  '24382': {
    country: 'US',
    city: 'Wytheville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Wythe',
    latitude: 36.9407,
    longitude: -81.0941,
    postal_code: '24382'
  },
  '24401': {
    country: 'US',
    city: 'Staunton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Staunton (city)',
    latitude: 38.1574,
    longitude: -79.0651,
    postal_code: '24401'
  },
  '24402': {
    country: 'US',
    city: 'Staunton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Staunton (city)',
    latitude: 38.1593,
    longitude: -79.0629,
    postal_code: '24402'
  },
  '24411': {
    country: 'US',
    city: 'Augusta Springs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.1025,
    longitude: -79.3195,
    postal_code: '24411'
  },
  '24412': {
    country: 'US',
    city: 'Bacova',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bath',
    latitude: 38.054,
    longitude: -79.8437,
    postal_code: '24412'
  },
  '24413': {
    country: 'US',
    city: 'Blue Grass',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Highland',
    latitude: 38.5152,
    longitude: -79.5613,
    postal_code: '24413'
  },
  '24415': {
    country: 'US',
    city: 'Brownsburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockbridge',
    latitude: 37.9285,
    longitude: -79.3192,
    postal_code: '24415'
  },
  '24416': {
    country: 'US',
    city: 'Buena Vista',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buena Vista (city)',
    latitude: 37.7396,
    longitude: -79.3523,
    postal_code: '24416'
  },
  '24421': {
    country: 'US',
    city: 'Churchville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.2346,
    longitude: -79.1791,
    postal_code: '24421'
  },
  '24422': {
    country: 'US',
    city: 'Clifton Forge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Alleghany',
    latitude: 37.8203,
    longitude: -79.8054,
    postal_code: '24422'
  },
  '24426': {
    country: 'US',
    city: 'Covington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Covington (city)',
    latitude: 37.7802,
    longitude: -79.987,
    postal_code: '24426'
  },
  '24430': {
    country: 'US',
    city: 'Craigsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.0768,
    longitude: -79.3619,
    postal_code: '24430'
  },
  '24431': {
    country: 'US',
    city: 'Crimora',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.1684,
    longitude: -78.8413,
    postal_code: '24431'
  },
  '24432': {
    country: 'US',
    city: 'Deerfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.1842,
    longitude: -79.4152,
    postal_code: '24432'
  },
  '24433': {
    country: 'US',
    city: 'Doe Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Highland',
    latitude: 38.4321,
    longitude: -79.4445,
    postal_code: '24433'
  },
  '24435': {
    country: 'US',
    city: 'Fairfield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockbridge',
    latitude: 37.8778,
    longitude: -79.2979,
    postal_code: '24435'
  },
  '24437': {
    country: 'US',
    city: 'Fort Defiance',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.2109,
    longitude: -78.9326,
    postal_code: '24437'
  },
  '24438': {
    country: 'US',
    city: 'Glen Wilton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Botetourt',
    latitude: 37.7529,
    longitude: -79.8189,
    postal_code: '24438'
  },
  '24439': {
    country: 'US',
    city: 'Goshen',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockbridge',
    latitude: 37.9878,
    longitude: -79.4773,
    postal_code: '24439'
  },
  '24440': {
    country: 'US',
    city: 'Greenville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.0018,
    longitude: -79.1359,
    postal_code: '24440'
  },
  '24441': {
    country: 'US',
    city: 'Grottoes',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.2484,
    longitude: -78.8255,
    postal_code: '24441'
  },
  '24442': {
    country: 'US',
    city: 'Head Waters',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Highland',
    latitude: 38.2946,
    longitude: -79.4353,
    postal_code: '24442'
  },
  '24445': {
    country: 'US',
    city: 'Hot Springs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bath',
    latitude: 37.9638,
    longitude: -79.8717,
    postal_code: '24445'
  },
  '24448': {
    country: 'US',
    city: 'Iron Gate',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Alleghany',
    latitude: 37.7965,
    longitude: -79.786,
    postal_code: '24448'
  },
  '24450': {
    country: 'US',
    city: 'Lexington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lexington (city)',
    latitude: 37.7885,
    longitude: -79.4581,
    postal_code: '24450'
  },
  '24457': {
    country: 'US',
    city: 'Low Moor',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Alleghany',
    latitude: 37.7887,
    longitude: -79.8837,
    postal_code: '24457'
  },
  '24458': {
    country: 'US',
    city: 'Mc Dowell',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Highland',
    latitude: 38.3266,
    longitude: -79.4988,
    postal_code: '24458'
  },
  '24459': {
    country: 'US',
    city: 'Middlebrook',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.0242,
    longitude: -79.2812,
    postal_code: '24459'
  },
  '24460': {
    country: 'US',
    city: 'Millboro',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bath',
    latitude: 38.0553,
    longitude: -79.7464,
    postal_code: '24460'
  },
  '24463': {
    country: 'US',
    city: 'Mint Spring',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.1797,
    longitude: -79.1413,
    postal_code: '24463'
  },
  '24464': {
    country: 'US',
    city: 'Montebello',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.8654,
    longitude: -79.0804,
    postal_code: '24464'
  },
  '24465': {
    country: 'US',
    city: 'Monterey',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Highland',
    latitude: 38.4058,
    longitude: -79.5941,
    postal_code: '24465'
  },
  '24467': {
    country: 'US',
    city: 'Mount Sidney',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.2612,
    longitude: -78.973,
    postal_code: '24467'
  },
  '24468': {
    country: 'US',
    city: 'Mustoe',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Highland',
    latitude: 38.386,
    longitude: -79.5592,
    postal_code: '24468'
  },
  '24469': {
    country: 'US',
    city: 'New Hope',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.1979,
    longitude: -78.9059,
    postal_code: '24469'
  },
  '24471': {
    country: 'US',
    city: 'Port Republic',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockingham',
    latitude: 38.3226,
    longitude: -78.7886,
    postal_code: '24471'
  },
  '24472': {
    country: 'US',
    city: 'Raphine',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockbridge',
    latitude: 37.9374,
    longitude: -79.2219,
    postal_code: '24472'
  },
  '24473': {
    country: 'US',
    city: 'Rockbridge Baths',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockbridge',
    latitude: 37.8965,
    longitude: -79.3874,
    postal_code: '24473'
  },
  '24474': {
    country: 'US',
    city: 'Selma',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Alleghany',
    latitude: 37.8062,
    longitude: -79.8404,
    postal_code: '24474'
  },
  '24476': {
    country: 'US',
    city: 'Steeles Tavern',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 37.9705,
    longitude: -79.2233,
    postal_code: '24476'
  },
  '24477': {
    country: 'US',
    city: 'Stuarts Draft',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.0144,
    longitude: -79.0298,
    postal_code: '24477'
  },
  '24479': {
    country: 'US',
    city: 'Swoope',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.1591,
    longitude: -79.1873,
    postal_code: '24479'
  },
  '24482': {
    country: 'US',
    city: 'Verona',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.2037,
    longitude: -79.0059,
    postal_code: '24482'
  },
  '24483': {
    country: 'US',
    city: 'Vesuvius',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockbridge',
    latitude: 37.8378,
    longitude: -79.2135,
    postal_code: '24483'
  },
  '24484': {
    country: 'US',
    city: 'Warm Springs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bath',
    latitude: 38.1437,
    longitude: -79.8207,
    postal_code: '24484'
  },
  '24485': {
    country: 'US',
    city: 'West Augusta',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.2744,
    longitude: -79.3201,
    postal_code: '24485'
  },
  '24486': {
    country: 'US',
    city: 'Weyers Cave',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Augusta',
    latitude: 38.2931,
    longitude: -78.9235,
    postal_code: '24486'
  },
  '24487': {
    country: 'US',
    city: 'Williamsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bath',
    latitude: 38.1898,
    longitude: -79.5879,
    postal_code: '24487'
  },
  '24501': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lynchburg (city)',
    latitude: 37.353,
    longitude: -79.1557,
    postal_code: '24501'
  },
  '24502': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lynchburg (city)',
    latitude: 37.3825,
    longitude: -79.2181,
    postal_code: '24502'
  },
  '24503': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lynchburg (city)',
    latitude: 37.4376,
    longitude: -79.205,
    postal_code: '24503'
  },
  '24504': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lynchburg (city)',
    latitude: 37.361,
    longitude: -79.0544,
    postal_code: '24504'
  },
  '24505': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lynchburg (city)',
    latitude: 37.4138,
    longitude: -79.1422,
    postal_code: '24505'
  },
  '24506': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lynchburg (city)',
    latitude: 37.3817,
    longitude: -79.161,
    postal_code: '24506'
  },
  '24513': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Campbell',
    latitude: 37.2458,
    longitude: -79.1335,
    postal_code: '24513'
  },
  '24514': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lynchburg (city)',
    latitude: 37.4009,
    longitude: -79.1785,
    postal_code: '24514'
  },
  '24515': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Lynchburg (city)',
    latitude: 37.4009,
    longitude: -79.1785,
    postal_code: '24515'
  },
  '24517': {
    country: 'US',
    city: 'Altavista',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Campbell',
    latitude: 37.1222,
    longitude: -79.2911,
    postal_code: '24517'
  },
  '24520': {
    country: 'US',
    city: 'Alton',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Halifax',
    latitude: 36.5894,
    longitude: -79.0202,
    postal_code: '24520'
  },
  '24521': {
    country: 'US',
    city: 'Amherst',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Amherst',
    latitude: 37.6027,
    longitude: -79.0506,
    postal_code: '24521'
  },
  '24522': {
    country: 'US',
    city: 'Appomattox',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Appomattox',
    latitude: 37.353,
    longitude: -78.8224,
    postal_code: '24522'
  },
  '24523': {
    country: 'US',
    city: 'Bedford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford (city)',
    latitude: 37.3153,
    longitude: -79.5331,
    postal_code: '24523'
  },
  '24526': {
    country: 'US',
    city: 'Big Island',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.5306,
    longitude: -79.3827,
    postal_code: '24526'
  },
  '24527': {
    country: 'US',
    city: 'Blairs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.6799,
    longitude: -79.3731,
    postal_code: '24527'
  },
  '24528': {
    country: 'US',
    city: 'Brookneal',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Campbell',
    latitude: 37.0827,
    longitude: -78.9227,
    postal_code: '24528'
  },
  '24529': {
    country: 'US',
    city: 'Buffalo Junction',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mecklenburg',
    latitude: 36.6168,
    longitude: -78.6093,
    postal_code: '24529'
  },
  '24530': {
    country: 'US',
    city: 'Callands',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.7647,
    longitude: -79.6288,
    postal_code: '24530'
  },
  '24531': {
    country: 'US',
    city: 'Chatham',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.831,
    longitude: -79.4297,
    postal_code: '24531'
  },
  '24533': {
    country: 'US',
    city: 'Clifford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Amherst',
    latitude: 37.538,
    longitude: -78.9463,
    postal_code: '24533'
  },
  '24534': {
    country: 'US',
    city: 'Clover',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Halifax',
    latitude: 36.8637,
    longitude: -78.7862,
    postal_code: '24534'
  },
  '24535': {
    country: 'US',
    city: 'Cluster Springs',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Halifax',
    latitude: 36.6113,
    longitude: -78.9455,
    postal_code: '24535'
  },
  '24536': {
    country: 'US',
    city: 'Coleman Falls',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.4887,
    longitude: -79.3158,
    postal_code: '24536'
  },
  '24538': {
    country: 'US',
    city: 'Concord',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Campbell',
    latitude: 37.337,
    longitude: -78.9804,
    postal_code: '24538'
  },
  '24539': {
    country: 'US',
    city: 'Crystal Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Halifax',
    latitude: 36.8513,
    longitude: -78.9179,
    postal_code: '24539'
  },
  '24540': {
    country: 'US',
    city: 'Danville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Danville (city)',
    latitude: 36.6218,
    longitude: -79.4124,
    postal_code: '24540'
  },
  '24541': {
    country: 'US',
    city: 'Danville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Danville (city)',
    latitude: 36.5779,
    longitude: -79.4411,
    postal_code: '24541'
  },
  '24543': {
    country: 'US',
    city: 'Danville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Danville (city)',
    latitude: 36.5927,
    longitude: -79.411,
    postal_code: '24543'
  },
  '24549': {
    country: 'US',
    city: 'Dry Fork',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.743,
    longitude: -79.458,
    postal_code: '24549'
  },
  '24550': {
    country: 'US',
    city: 'Evington',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Campbell',
    latitude: 37.261,
    longitude: -79.2317,
    postal_code: '24550'
  },
  '24551': {
    country: 'US',
    city: 'Forest',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.3379,
    longitude: -79.2791,
    postal_code: '24551'
  },
  '24553': {
    country: 'US',
    city: 'Gladstone',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.5473,
    longitude: -78.8508,
    postal_code: '24553'
  },
  '24554': {
    country: 'US',
    city: 'Gladys',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Campbell',
    latitude: 37.1386,
    longitude: -79.1048,
    postal_code: '24554'
  },
  '24555': {
    country: 'US',
    city: 'Glasgow',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockbridge',
    latitude: 37.643,
    longitude: -79.459,
    postal_code: '24555'
  },
  '24556': {
    country: 'US',
    city: 'Goode',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.3755,
    longitude: -79.4039,
    postal_code: '24556'
  },
  '24557': {
    country: 'US',
    city: 'Gretna',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.9695,
    longitude: -79.3389,
    postal_code: '24557'
  },
  '24558': {
    country: 'US',
    city: 'Halifax',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Halifax',
    latitude: 36.7626,
    longitude: -78.9415,
    postal_code: '24558'
  },
  '24562': {
    country: 'US',
    city: 'Howardsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 37.7511,
    longitude: -78.6313,
    postal_code: '24562'
  },
  '24563': {
    country: 'US',
    city: 'Hurt',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 37.0798,
    longitude: -79.3,
    postal_code: '24563'
  },
  '24565': {
    country: 'US',
    city: 'Java',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.8586,
    longitude: -79.187,
    postal_code: '24565'
  },
  '24566': {
    country: 'US',
    city: 'Keeling',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.7156,
    longitude: -79.2783,
    postal_code: '24566'
  },
  '24569': {
    country: 'US',
    city: 'Long Island',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Campbell',
    latitude: 37.0644,
    longitude: -79.1219,
    postal_code: '24569'
  },
  '24570': {
    country: 'US',
    city: 'Lowry',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Bedford',
    latitude: 37.3484,
    longitude: -79.4271,
    postal_code: '24570'
  },
  '24571': {
    country: 'US',
    city: 'Lynch Station',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Campbell',
    latitude: 37.1528,
    longitude: -79.3297,
    postal_code: '24571'
  },
  '24572': {
    country: 'US',
    city: 'Madison Heights',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Amherst',
    latitude: 37.4531,
    longitude: -79.1141,
    postal_code: '24572'
  },
  '24574': {
    country: 'US',
    city: 'Monroe',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Amherst',
    latitude: 37.5414,
    longitude: -79.1703,
    postal_code: '24574'
  },
  '24576': {
    country: 'US',
    city: 'Naruna',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Campbell',
    latitude: 37.2458,
    longitude: -79.1335,
    postal_code: '24576'
  },
  '24577': {
    country: 'US',
    city: 'Nathalie',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Halifax',
    latitude: 36.9356,
    longitude: -78.972,
    postal_code: '24577'
  },
  '24578': {
    country: 'US',
    city: 'Natural Bridge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockbridge',
    latitude: 37.6606,
    longitude: -79.533,
    postal_code: '24578'
  },
  '24579': {
    country: 'US',
    city: 'Natural Bridge Station',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Rockbridge',
    latitude: 37.598,
    longitude: -79.525,
    postal_code: '24579'
  },
  '24580': {
    country: 'US',
    city: 'Nelson',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Mecklenburg',
    latitude: 36.5586,
    longitude: -78.671,
    postal_code: '24580'
  },
  '24581': {
    country: 'US',
    city: 'Norwood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.6432,
    longitude: -78.8089,
    postal_code: '24581'
  },
  '24586': {
    country: 'US',
    city: 'Ringgold',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.6035,
    longitude: -79.2988,
    postal_code: '24586'
  },
  '24588': {
    country: 'US',
    city: 'Rustburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Campbell',
    latitude: 37.2545,
    longitude: -79.1215,
    postal_code: '24588'
  },
  '24589': {
    country: 'US',
    city: 'Scottsburg',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Halifax',
    latitude: 36.7862,
    longitude: -78.7866,
    postal_code: '24589'
  },
  '24590': {
    country: 'US',
    city: 'Scottsville',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Albemarle',
    latitude: 37.8049,
    longitude: -78.474,
    postal_code: '24590'
  },
  '24592': {
    country: 'US',
    city: 'South Boston',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Halifax',
    latitude: 36.6963,
    longitude: -78.9188,
    postal_code: '24592'
  },
  '24593': {
    country: 'US',
    city: 'Spout Spring',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Appomattox',
    latitude: 37.3643,
    longitude: -78.9059,
    postal_code: '24593'
  },
  '24594': {
    country: 'US',
    city: 'Sutherlin',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Pittsylvania',
    latitude: 36.6258,
    longitude: -79.195,
    postal_code: '24594'
  },
  '24595': {
    country: 'US',
    city: 'Sweet Briar',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Amherst',
    latitude: 37.5529,
    longitude: -79.0664,
    postal_code: '24595'
  },
  '24597': {
    country: 'US',
    city: 'Vernon Hill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Halifax',
    latitude: 36.7853,
    longitude: -79.1095,
    postal_code: '24597'
  },
  '24598': {
    country: 'US',
    city: 'Virgilina',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Halifax',
    latitude: 36.6062,
    longitude: -78.7605,
    postal_code: '24598'
  },
  '24599': {
    country: 'US',
    city: 'Wingina',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Nelson',
    latitude: 37.6399,
    longitude: -78.7239,
    postal_code: '24599'
  },
  '24601': {
    country: 'US',
    city: 'Amonate',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.1909,
    longitude: -81.6387,
    postal_code: '24601'
  },
  '24602': {
    country: 'US',
    city: 'Bandy',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.1661,
    longitude: -81.6508,
    postal_code: '24602'
  },
  '24603': {
    country: 'US',
    city: 'Big Rock',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.3532,
    longitude: -82.1968,
    postal_code: '24603'
  },
  '24604': {
    country: 'US',
    city: 'Bishop',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.2033,
    longitude: -81.5575,
    postal_code: '24604'
  },
  '24605': {
    country: 'US',
    city: 'Bluefield',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.2526,
    longitude: -81.2712,
    postal_code: '24605'
  },
  '24606': {
    country: 'US',
    city: 'Boissevain',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.2818,
    longitude: -81.3804,
    postal_code: '24606'
  },
  '24607': {
    country: 'US',
    city: 'Breaks',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Dickenson',
    latitude: 37.2959,
    longitude: -82.281,
    postal_code: '24607'
  },
  '24608': {
    country: 'US',
    city: 'Burkes Garden',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.0982,
    longitude: -81.3409,
    postal_code: '24608'
  },
  '24609': {
    country: 'US',
    city: 'Cedar Bluff',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.0876,
    longitude: -81.759,
    postal_code: '24609'
  },
  '24612': {
    country: 'US',
    city: 'Doran',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.0926,
    longitude: -81.8335,
    postal_code: '24612'
  },
  '24613': {
    country: 'US',
    city: 'Falls Mills',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.271,
    longitude: -81.3182,
    postal_code: '24613'
  },
  '24614': {
    country: 'US',
    city: 'Grundy',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.2967,
    longitude: -82.1061,
    postal_code: '24614'
  },
  '24619': {
    country: 'US',
    city: 'Horsepen',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.1355,
    longitude: -81.5634,
    postal_code: '24619'
  },
  '24620': {
    country: 'US',
    city: 'Hurley',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.4017,
    longitude: -82.0262,
    postal_code: '24620'
  },
  '24622': {
    country: 'US',
    city: 'Jewell Ridge',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.2094,
    longitude: -81.7956,
    postal_code: '24622'
  },
  '24624': {
    country: 'US',
    city: 'Keen Mountain',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.2023,
    longitude: -81.9862,
    postal_code: '24624'
  },
  '24627': {
    country: 'US',
    city: 'Mavisdale',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.288,
    longitude: -82.0274,
    postal_code: '24627'
  },
  '24628': {
    country: 'US',
    city: 'Maxie',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.3012,
    longitude: -82.1743,
    postal_code: '24628'
  },
  '24630': {
    country: 'US',
    city: 'North Tazewell',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.1756,
    longitude: -81.5399,
    postal_code: '24630'
  },
  '24631': {
    country: 'US',
    city: 'Oakwood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.2138,
    longitude: -81.9917,
    postal_code: '24631'
  },
  '24634': {
    country: 'US',
    city: 'Pilgrims Knob',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.2746,
    longitude: -81.9024,
    postal_code: '24634'
  },
  '24635': {
    country: 'US',
    city: 'Pocahontas',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.3054,
    longitude: -81.3425,
    postal_code: '24635'
  },
  '24637': {
    country: 'US',
    city: 'Pounding Mill',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.0596,
    longitude: -81.7301,
    postal_code: '24637'
  },
  '24639': {
    country: 'US',
    city: 'Raven',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.1481,
    longitude: -81.8896,
    postal_code: '24639'
  },
  '24640': {
    country: 'US',
    city: 'Red Ash',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.1134,
    longitude: -81.871,
    postal_code: '24640'
  },
  '24641': {
    country: 'US',
    city: 'Richlands',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.0941,
    longitude: -81.8123,
    postal_code: '24641'
  },
  '24646': {
    country: 'US',
    city: 'Rowe',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.1276,
    longitude: -82.0274,
    postal_code: '24646'
  },
  '24647': {
    country: 'US',
    city: 'Shortt Gap',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.1573,
    longitude: -81.8729,
    postal_code: '24647'
  },
  '24649': {
    country: 'US',
    city: 'Swords Creek',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Russell',
    latitude: 37.0738,
    longitude: -81.9084,
    postal_code: '24649'
  },
  '24651': {
    country: 'US',
    city: 'Tazewell',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Tazewell',
    latitude: 37.1078,
    longitude: -81.51,
    postal_code: '24651'
  },
  '24656': {
    country: 'US',
    city: 'Vansant',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.1738,
    longitude: -82.1277,
    postal_code: '24656'
  },
  '24657': {
    country: 'US',
    city: 'Whitewood',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.2251,
    longitude: -81.8605,
    postal_code: '24657'
  },
  '24658': {
    country: 'US',
    city: 'Wolford',
    state: 'Virginia',
    state_short: 'VA',
    county: 'Buchanan',
    latitude: 37.288,
    longitude: -82.0274,
    postal_code: '24658'
  },
  '24701': {
    country: 'US',
    city: 'Bluefield',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.2698,
    longitude: -81.2223,
    postal_code: '24701'
  },
  '24712': {
    country: 'US',
    city: 'Athens',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.4323,
    longitude: -80.9974,
    postal_code: '24712'
  },
  '24714': {
    country: 'US',
    city: 'Beeson',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.4909,
    longitude: -81.2062,
    postal_code: '24714'
  },
  '24715': {
    country: 'US',
    city: 'Bramwell',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.3321,
    longitude: -81.3256,
    postal_code: '24715'
  },
  '24716': {
    country: 'US',
    city: 'Bud',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5376,
    longitude: -81.3793,
    postal_code: '24716'
  },
  '24719': {
    country: 'US',
    city: 'Covel',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.4909,
    longitude: -81.3226,
    postal_code: '24719'
  },
  '24724': {
    country: 'US',
    city: 'Freeman',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.3309,
    longitude: -81.2998,
    postal_code: '24724'
  },
  '24726': {
    country: 'US',
    city: 'Herndon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5054,
    longitude: -81.3404,
    postal_code: '24726'
  },
  '24729': {
    country: 'US',
    city: 'Hiawatha',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.4401,
    longitude: -81.244,
    postal_code: '24729'
  },
  '24731': {
    country: 'US',
    city: 'Kegley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.3971,
    longitude: -81.1318,
    postal_code: '24731'
  },
  '24732': {
    country: 'US',
    city: 'Kellysville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.3448,
    longitude: -80.9279,
    postal_code: '24732'
  },
  '24733': {
    country: 'US',
    city: 'Lashmeet',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.442,
    longitude: -81.2138,
    postal_code: '24733'
  },
  '24736': {
    country: 'US',
    city: 'Matoaka',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.4201,
    longitude: -81.2432,
    postal_code: '24736'
  },
  '24737': {
    country: 'US',
    city: 'Montcalm',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.3539,
    longitude: -81.2518,
    postal_code: '24737'
  },
  '24738': {
    country: 'US',
    city: 'Nemours',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.3015,
    longitude: -81.3043,
    postal_code: '24738'
  },
  '24739': {
    country: 'US',
    city: 'Princeton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.3662,
    longitude: -81.1026,
    postal_code: '24739'
  },
  '24740': {
    country: 'US',
    city: 'Princeton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.3799,
    longitude: -81.1175,
    postal_code: '24740'
  },
  '24747': {
    country: 'US',
    city: 'Rock',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.4142,
    longitude: -81.1784,
    postal_code: '24747'
  },
  '24751': {
    country: 'US',
    city: 'Wolfe',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.3073,
    longitude: -81.3287,
    postal_code: '24751'
  },
  '24801': {
    country: 'US',
    city: 'Welch',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4329,
    longitude: -81.5846,
    postal_code: '24801'
  },
  '24808': {
    country: 'US',
    city: 'Anawalt',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3362,
    longitude: -81.4151,
    postal_code: '24808'
  },
  '24811': {
    country: 'US',
    city: 'Avondale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4126,
    longitude: -81.7843,
    postal_code: '24811'
  },
  '24813': {
    country: 'US',
    city: 'Bartley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3407,
    longitude: -81.7376,
    postal_code: '24813'
  },
  '24815': {
    country: 'US',
    city: 'Berwind',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.2687,
    longitude: -81.6668,
    postal_code: '24815'
  },
  '24816': {
    country: 'US',
    city: 'Big Sandy',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4643,
    longitude: -81.6982,
    postal_code: '24816'
  },
  '24817': {
    country: 'US',
    city: 'Bradshaw',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3518,
    longitude: -81.7987,
    postal_code: '24817'
  },
  '24818': {
    country: 'US',
    city: 'Brenton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5988,
    longitude: -81.6264,
    postal_code: '24818'
  },
  '24822': {
    country: 'US',
    city: 'Clear Fork',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6309,
    longitude: -81.6829,
    postal_code: '24822'
  },
  '24823': {
    country: 'US',
    city: 'Coal Mountain',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6696,
    longitude: -81.7284,
    postal_code: '24823'
  },
  '24826': {
    country: 'US',
    city: 'Cucumber',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.2779,
    longitude: -81.6268,
    postal_code: '24826'
  },
  '24827': {
    country: 'US',
    city: 'Cyclone',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.7329,
    longitude: -81.6887,
    postal_code: '24827'
  },
  '24828': {
    country: 'US',
    city: 'Davy',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4784,
    longitude: -81.6534,
    postal_code: '24828'
  },
  '24829': {
    country: 'US',
    city: 'Eckman',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3752,
    longitude: -81.6539,
    postal_code: '24829'
  },
  '24830': {
    country: 'US',
    city: 'Elbert',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3354,
    longitude: -81.5393,
    postal_code: '24830'
  },
  '24831': {
    country: 'US',
    city: 'Elkhorn',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3857,
    longitude: -81.4109,
    postal_code: '24831'
  },
  '24834': {
    country: 'US',
    city: 'Fanrock',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5532,
    longitude: -81.6305,
    postal_code: '24834'
  },
  '24836': {
    country: 'US',
    city: 'Gary',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3658,
    longitude: -81.5544,
    postal_code: '24836'
  },
  '24839': {
    country: 'US',
    city: 'Hanover',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.563,
    longitude: -81.8136,
    postal_code: '24839'
  },
  '24843': {
    country: 'US',
    city: 'Hensley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.474,
    longitude: -81.6998,
    postal_code: '24843'
  },
  '24844': {
    country: 'US',
    city: 'Iaeger',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4499,
    longitude: -81.812,
    postal_code: '24844'
  },
  '24845': {
    country: 'US',
    city: 'Ikes Fork',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5221,
    longitude: -81.8043,
    postal_code: '24845'
  },
  '24846': {
    country: 'US',
    city: 'Isaban',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.5301,
    longitude: -81.8882,
    postal_code: '24846'
  },
  '24847': {
    country: 'US',
    city: 'Itmann',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5734,
    longitude: -81.4182,
    postal_code: '24847'
  },
  '24848': {
    country: 'US',
    city: 'Jenkinjones',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.2937,
    longitude: -81.4259,
    postal_code: '24848'
  },
  '24849': {
    country: 'US',
    city: 'Jesse',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6608,
    longitude: -81.5628,
    postal_code: '24849'
  },
  '24850': {
    country: 'US',
    city: 'Jolo',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3324,
    longitude: -81.8316,
    postal_code: '24850'
  },
  '24851': {
    country: 'US',
    city: 'Justice',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.5887,
    longitude: -81.8354,
    postal_code: '24851'
  },
  '24853': {
    country: 'US',
    city: 'Kimball',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4421,
    longitude: -81.512,
    postal_code: '24853'
  },
  '24854': {
    country: 'US',
    city: 'Kopperston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.744,
    longitude: -81.575,
    postal_code: '24854'
  },
  '24855': {
    country: 'US',
    city: 'Kyle',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4082,
    longitude: -81.4257,
    postal_code: '24855'
  },
  '24857': {
    country: 'US',
    city: 'Lynco',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6982,
    longitude: -81.6771,
    postal_code: '24857'
  },
  '24859': {
    country: 'US',
    city: 'Marianna',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6033,
    longitude: -81.54,
    postal_code: '24859'
  },
  '24860': {
    country: 'US',
    city: 'Matheny',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6624,
    longitude: -81.607,
    postal_code: '24860'
  },
  '24861': {
    country: 'US',
    city: 'Maybeury',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3709,
    longitude: -81.3665,
    postal_code: '24861'
  },
  '24862': {
    country: 'US',
    city: 'Mohawk',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.5048,
    longitude: -81.9151,
    postal_code: '24862'
  },
  '24866': {
    country: 'US',
    city: 'Newhall',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.2637,
    longitude: -81.6187,
    postal_code: '24866'
  },
  '24867': {
    country: 'US',
    city: 'New Richmond',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5701,
    longitude: -81.4887,
    postal_code: '24867'
  },
  '24868': {
    country: 'US',
    city: 'Northfork',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4194,
    longitude: -81.4058,
    postal_code: '24868'
  },
  '24869': {
    country: 'US',
    city: 'North Spring',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5682,
    longitude: -81.8254,
    postal_code: '24869'
  },
  '24870': {
    country: 'US',
    city: 'Oceana',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.7407,
    longitude: -81.5831,
    postal_code: '24870'
  },
  '24871': {
    country: 'US',
    city: 'Pageton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3493,
    longitude: -81.4654,
    postal_code: '24871'
  },
  '24872': {
    country: 'US',
    city: 'Panther',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4834,
    longitude: -81.896,
    postal_code: '24872'
  },
  '24873': {
    country: 'US',
    city: 'Paynesville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3315,
    longitude: -81.8915,
    postal_code: '24873'
  },
  '24874': {
    country: 'US',
    city: 'Pineville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.584,
    longitude: -81.5336,
    postal_code: '24874'
  },
  '24878': {
    country: 'US',
    city: 'Premier',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4223,
    longitude: -81.6437,
    postal_code: '24878'
  },
  '24879': {
    country: 'US',
    city: 'Raysal',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3244,
    longitude: -81.7614,
    postal_code: '24879'
  },
  '24880': {
    country: 'US',
    city: 'Rock View',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6179,
    longitude: -81.539,
    postal_code: '24880'
  },
  '24881': {
    country: 'US',
    city: 'Roderfield',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.4504,
    longitude: -81.7057,
    postal_code: '24881'
  },
  '24882': {
    country: 'US',
    city: 'Simon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6197,
    longitude: -81.7586,
    postal_code: '24882'
  },
  '24884': {
    country: 'US',
    city: 'Squire',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.2384,
    longitude: -81.6098,
    postal_code: '24884'
  },
  '24887': {
    country: 'US',
    city: 'Switchback',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3712,
    longitude: -81.3857,
    postal_code: '24887'
  },
  '24888': {
    country: 'US',
    city: 'Thorpe',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3687,
    longitude: -81.5165,
    postal_code: '24888'
  },
  '24892': {
    country: 'US',
    city: 'War',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3012,
    longitude: -81.6909,
    postal_code: '24892'
  },
  '24894': {
    country: 'US',
    city: 'Warriormine',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.2929,
    longitude: -81.6971,
    postal_code: '24894'
  },
  '24895': {
    country: 'US',
    city: 'Wilcoe',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'McDowell',
    latitude: 37.3752,
    longitude: -81.6539,
    postal_code: '24895'
  },
  '24898': {
    country: 'US',
    city: 'Wyoming',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5823,
    longitude: -81.6018,
    postal_code: '24898'
  },
  '24901': {
    country: 'US',
    city: 'Lewisburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.8083,
    longitude: -80.4407,
    postal_code: '24901'
  },
  '24902': {
    country: 'US',
    city: 'Fairlea',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.7747,
    longitude: -80.4615,
    postal_code: '24902'
  },
  '24910': {
    country: 'US',
    city: 'Alderson',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.7371,
    longitude: -80.661,
    postal_code: '24910'
  },
  '24915': {
    country: 'US',
    city: 'Arbovale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.4546,
    longitude: -79.7934,
    postal_code: '24915'
  },
  '24916': {
    country: 'US',
    city: 'Asbury',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.8313,
    longitude: -80.5747,
    postal_code: '24916'
  },
  '24918': {
    country: 'US',
    city: 'Ballard',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.498,
    longitude: -80.7614,
    postal_code: '24918'
  },
  '24920': {
    country: 'US',
    city: 'Bartow',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.5513,
    longitude: -79.7952,
    postal_code: '24920'
  },
  '24924': {
    country: 'US',
    city: 'Buckeye',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.186,
    longitude: -80.1345,
    postal_code: '24924'
  },
  '24925': {
    country: 'US',
    city: 'Caldwell',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.7807,
    longitude: -80.394,
    postal_code: '24925'
  },
  '24927': {
    country: 'US',
    city: 'Cass',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.388,
    longitude: -79.9906,
    postal_code: '24927'
  },
  '24931': {
    country: 'US',
    city: 'Crawley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.9397,
    longitude: -80.6309,
    postal_code: '24931'
  },
  '24934': {
    country: 'US',
    city: 'Dunmore',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.3636,
    longitude: -79.8556,
    postal_code: '24934'
  },
  '24935': {
    country: 'US',
    city: 'Forest Hill',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Summers',
    latitude: 37.5608,
    longitude: -80.8111,
    postal_code: '24935'
  },
  '24938': {
    country: 'US',
    city: 'Frankford',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.9154,
    longitude: -80.3353,
    postal_code: '24938'
  },
  '24941': {
    country: 'US',
    city: 'Gap Mills',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.589,
    longitude: -80.3401,
    postal_code: '24941'
  },
  '24943': {
    country: 'US',
    city: 'Grassy Meadows',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.8268,
    longitude: -80.7209,
    postal_code: '24943'
  },
  '24944': {
    country: 'US',
    city: 'Green Bank',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.4097,
    longitude: -79.8015,
    postal_code: '24944'
  },
  '24945': {
    country: 'US',
    city: 'Greenville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.5435,
    longitude: -80.6826,
    postal_code: '24945'
  },
  '24946': {
    country: 'US',
    city: 'Hillsboro',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.1109,
    longitude: -80.2641,
    postal_code: '24946'
  },
  '24951': {
    country: 'US',
    city: 'Lindside',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.4817,
    longitude: -80.6203,
    postal_code: '24951'
  },
  '24954': {
    country: 'US',
    city: 'Marlinton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.2586,
    longitude: -80.1046,
    postal_code: '24954'
  },
  '24957': {
    country: 'US',
    city: 'Maxwelton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.8765,
    longitude: -80.4192,
    postal_code: '24957'
  },
  '24962': {
    country: 'US',
    city: 'Pence Springs',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Summers',
    latitude: 37.6621,
    longitude: -80.7202,
    postal_code: '24962'
  },
  '24963': {
    country: 'US',
    city: 'Peterstown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.4147,
    longitude: -80.7686,
    postal_code: '24963'
  },
  '24966': {
    country: 'US',
    city: 'Renick',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.9922,
    longitude: -80.3653,
    postal_code: '24966'
  },
  '24970': {
    country: 'US',
    city: 'Ronceverte',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.7418,
    longitude: -80.4626,
    postal_code: '24970'
  },
  '24974': {
    country: 'US',
    city: 'Secondcreek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.6654,
    longitude: -80.454,
    postal_code: '24974'
  },
  '24976': {
    country: 'US',
    city: 'Sinks Grove',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.6643,
    longitude: -80.5409,
    postal_code: '24976'
  },
  '24977': {
    country: 'US',
    city: 'Smoot',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.8772,
    longitude: -80.668,
    postal_code: '24977'
  },
  '24981': {
    country: 'US',
    city: 'Talcott',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Summers',
    latitude: 37.6491,
    longitude: -80.7461,
    postal_code: '24981'
  },
  '24983': {
    country: 'US',
    city: 'Union',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.5802,
    longitude: -80.5248,
    postal_code: '24983'
  },
  '24984': {
    country: 'US',
    city: 'Waiteville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.4645,
    longitude: -80.4634,
    postal_code: '24984'
  },
  '24985': {
    country: 'US',
    city: 'Wayside',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.5937,
    longitude: -80.7458,
    postal_code: '24985'
  },
  '24986': {
    country: 'US',
    city: 'White Sulphur Springs',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.7827,
    longitude: -80.3129,
    postal_code: '24986'
  },
  '24991': {
    country: 'US',
    city: 'Williamsburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.9701,
    longitude: -80.4923,
    postal_code: '24991'
  },
  '24993': {
    country: 'US',
    city: 'Wolfcreek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monroe',
    latitude: 37.552,
    longitude: -80.5397,
    postal_code: '24993'
  },
  '25002': {
    country: 'US',
    city: 'Alloy',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1376,
    longitude: -81.2743,
    postal_code: '25002'
  },
  '25003': {
    country: 'US',
    city: 'Alum Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2868,
    longitude: -81.8051,
    postal_code: '25003'
  },
  '25005': {
    country: 'US',
    city: 'Amma',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Roane',
    latitude: 38.5687,
    longitude: -81.2657,
    postal_code: '25005'
  },
  '25007': {
    country: 'US',
    city: 'Arnett',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8334,
    longitude: -81.4301,
    postal_code: '25007'
  },
  '25008': {
    country: 'US',
    city: 'Artie',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.94,
    longitude: -81.3545,
    postal_code: '25008'
  },
  '25009': {
    country: 'US',
    city: 'Ashford',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.1823,
    longitude: -81.7113,
    postal_code: '25009'
  },
  '25011': {
    country: 'US',
    city: 'Bancroft',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.5086,
    longitude: -81.841,
    postal_code: '25011'
  },
  '25015': {
    country: 'US',
    city: 'Belle',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2324,
    longitude: -81.5024,
    postal_code: '25015'
  },
  '25019': {
    country: 'US',
    city: 'Bickmore',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.3729,
    longitude: -81.0868,
    postal_code: '25019'
  },
  '25021': {
    country: 'US',
    city: 'Bim',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 37.9218,
    longitude: -81.6893,
    postal_code: '25021'
  },
  '25022': {
    country: 'US',
    city: 'Blair',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.8784,
    longitude: -81.8279,
    postal_code: '25022'
  },
  '25024': {
    country: 'US',
    city: 'Bloomingrose',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.1412,
    longitude: -81.6367,
    postal_code: '25024'
  },
  '25025': {
    country: 'US',
    city: 'Blount',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3121,
    longitude: -81.4052,
    postal_code: '25025'
  },
  '25026': {
    country: 'US',
    city: 'Blue Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.4908,
    longitude: -81.3928,
    postal_code: '25026'
  },
  '25028': {
    country: 'US',
    city: 'Bob White',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 37.9543,
    longitude: -81.7185,
    postal_code: '25028'
  },
  '25030': {
    country: 'US',
    city: 'Bomont',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.4498,
    longitude: -81.2323,
    postal_code: '25030'
  },
  '25031': {
    country: 'US',
    city: 'Boomer',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1519,
    longitude: -81.2656,
    postal_code: '25031'
  },
  '25033': {
    country: 'US',
    city: 'Buffalo',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.6092,
    longitude: -81.95,
    postal_code: '25033'
  },
  '25035': {
    country: 'US',
    city: 'Cabin Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.193,
    longitude: -81.4984,
    postal_code: '25035'
  },
  '25036': {
    country: 'US',
    city: 'Cannelton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1943,
    longitude: -81.2959,
    postal_code: '25036'
  },
  '25039': {
    country: 'US',
    city: 'Cedar Grove',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2212,
    longitude: -81.4279,
    postal_code: '25039'
  },
  '25040': {
    country: 'US',
    city: 'Charlton Heights',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1237,
    longitude: -81.234,
    postal_code: '25040'
  },
  '25043': {
    country: 'US',
    city: 'Clay',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.4764,
    longitude: -81.0696,
    postal_code: '25043'
  },
  '25044': {
    country: 'US',
    city: 'Clear Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.9162,
    longitude: -81.3443,
    postal_code: '25044'
  },
  '25045': {
    country: 'US',
    city: 'Clendenin',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.4539,
    longitude: -81.3572,
    postal_code: '25045'
  },
  '25047': {
    country: 'US',
    city: 'Clothier',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.9468,
    longitude: -81.8104,
    postal_code: '25047'
  },
  '25048': {
    country: 'US',
    city: 'Colcord',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.9439,
    longitude: -81.4369,
    postal_code: '25048'
  },
  '25049': {
    country: 'US',
    city: 'Comfort',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.1372,
    longitude: -81.5962,
    postal_code: '25049'
  },
  '25051': {
    country: 'US',
    city: 'Costa',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.1591,
    longitude: -81.7092,
    postal_code: '25051'
  },
  '25053': {
    country: 'US',
    city: 'Danville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.0809,
    longitude: -81.834,
    postal_code: '25053'
  },
  '25054': {
    country: 'US',
    city: 'Dawes',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.1429,
    longitude: -81.4521,
    postal_code: '25054'
  },
  '25057': {
    country: 'US',
    city: 'Deep Water',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1268,
    longitude: -81.2637,
    postal_code: '25057'
  },
  '25059': {
    country: 'US',
    city: 'Dixie',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.2509,
    longitude: -81.1932,
    postal_code: '25059'
  },
  '25060': {
    country: 'US',
    city: 'Dorothy',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.9622,
    longitude: -81.4968,
    postal_code: '25060'
  },
  '25061': {
    country: 'US',
    city: 'Drybranch',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2968,
    longitude: -81.5547,
    postal_code: '25061'
  },
  '25062': {
    country: 'US',
    city: 'Dry Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8749,
    longitude: -81.4412,
    postal_code: '25062'
  },
  '25063': {
    country: 'US',
    city: 'Duck',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.5647,
    longitude: -80.9764,
    postal_code: '25063'
  },
  '25064': {
    country: 'US',
    city: 'Dunbar',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3683,
    longitude: -81.7425,
    postal_code: '25064'
  },
  '25067': {
    country: 'US',
    city: 'East Bank',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2099,
    longitude: -81.4381,
    postal_code: '25067'
  },
  '25070': {
    country: 'US',
    city: 'Eleanor',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.5436,
    longitude: -81.9414,
    postal_code: '25070'
  },
  '25071': {
    country: 'US',
    city: 'Elkview',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.4429,
    longitude: -81.4804,
    postal_code: '25071'
  },
  '25075': {
    country: 'US',
    city: 'Eskdale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.0505,
    longitude: -81.4227,
    postal_code: '25075'
  },
  '25076': {
    country: 'US',
    city: 'Ethel',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.8665,
    longitude: -81.9123,
    postal_code: '25076'
  },
  '25079': {
    country: 'US',
    city: 'Falling Rock',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.4951,
    longitude: -81.3984,
    postal_code: '25079'
  },
  '25081': {
    country: 'US',
    city: 'Foster',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.0908,
    longitude: -81.7659,
    postal_code: '25081'
  },
  '25082': {
    country: 'US',
    city: 'Fraziers Bottom',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.5517,
    longitude: -82.0111,
    postal_code: '25082'
  },
  '25083': {
    country: 'US',
    city: 'Gallagher',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.0704,
    longitude: -81.3625,
    postal_code: '25083'
  },
  '25085': {
    country: 'US',
    city: 'Gauley Bridge',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1689,
    longitude: -81.196,
    postal_code: '25085'
  },
  '25086': {
    country: 'US',
    city: 'Glasgow',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2154,
    longitude: -81.4246,
    postal_code: '25086'
  },
  '25088': {
    country: 'US',
    city: 'Glen',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.3943,
    longitude: -81.2426,
    postal_code: '25088'
  },
  '25090': {
    country: 'US',
    city: 'Glen Ferris',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1532,
    longitude: -81.2148,
    postal_code: '25090'
  },
  '25093': {
    country: 'US',
    city: 'Gordon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 37.987,
    longitude: -81.6954,
    postal_code: '25093'
  },
  '25102': {
    country: 'US',
    city: 'Handley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.1879,
    longitude: -81.3657,
    postal_code: '25102'
  },
  '25103': {
    country: 'US',
    city: 'Hansford',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.207,
    longitude: -81.3929,
    postal_code: '25103'
  },
  '25106': {
    country: 'US',
    city: 'Henderson',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.8265,
    longitude: -82.1364,
    postal_code: '25106'
  },
  '25107': {
    country: 'US',
    city: 'Hernshaw',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.1942,
    longitude: -81.613,
    postal_code: '25107'
  },
  '25108': {
    country: 'US',
    city: 'Hewett',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 37.9581,
    longitude: -81.8509,
    postal_code: '25108'
  },
  '25109': {
    country: 'US',
    city: 'Hometown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.5329,
    longitude: -81.8612,
    postal_code: '25109'
  },
  '25110': {
    country: 'US',
    city: 'Hugheston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2095,
    longitude: -81.3726,
    postal_code: '25110'
  },
  '25111': {
    country: 'US',
    city: 'Indore',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.3498,
    longitude: -81.1269,
    postal_code: '25111'
  },
  '25112': {
    country: 'US',
    city: 'Institute',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3837,
    longitude: -81.7654,
    postal_code: '25112'
  },
  '25113': {
    country: 'US',
    city: 'Ivydale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.5357,
    longitude: -81.0348,
    postal_code: '25113'
  },
  '25114': {
    country: 'US',
    city: 'Jeffrey',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 37.9715,
    longitude: -81.8215,
    postal_code: '25114'
  },
  '25115': {
    country: 'US',
    city: 'Kanawha Falls',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1426,
    longitude: -81.2065,
    postal_code: '25115'
  },
  '25118': {
    country: 'US',
    city: 'Kimberly',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1249,
    longitude: -81.3217,
    postal_code: '25118'
  },
  '25119': {
    country: 'US',
    city: 'Kincaid',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.0407,
    longitude: -81.2701,
    postal_code: '25119'
  },
  '25121': {
    country: 'US',
    city: 'Lake',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.9204,
    longitude: -81.9034,
    postal_code: '25121'
  },
  '25123': {
    country: 'US',
    city: 'Leon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.7048,
    longitude: -81.9145,
    postal_code: '25123'
  },
  '25124': {
    country: 'US',
    city: 'Liberty',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.62,
    longitude: -81.7678,
    postal_code: '25124'
  },
  '25125': {
    country: 'US',
    city: 'Lizemores',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.3366,
    longitude: -81.1633,
    postal_code: '25125'
  },
  '25126': {
    country: 'US',
    city: 'London',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.196,
    longitude: -81.3714,
    postal_code: '25126'
  },
  '25130': {
    country: 'US',
    city: 'Madison',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.0444,
    longitude: -81.7938,
    postal_code: '25130'
  },
  '25132': {
    country: 'US',
    city: 'Mammoth',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2634,
    longitude: -81.3721,
    postal_code: '25132'
  },
  '25133': {
    country: 'US',
    city: 'Maysel',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.4939,
    longitude: -81.1227,
    postal_code: '25133'
  },
  '25134': {
    country: 'US',
    city: 'Miami',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.1523,
    longitude: -81.4479,
    postal_code: '25134'
  },
  '25136': {
    country: 'US',
    city: 'Montgomery',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1725,
    longitude: -81.3222,
    postal_code: '25136'
  },
  '25139': {
    country: 'US',
    city: 'Mount Carbon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1399,
    longitude: -81.2931,
    postal_code: '25139'
  },
  '25140': {
    country: 'US',
    city: 'Naoma',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8676,
    longitude: -81.4873,
    postal_code: '25140'
  },
  '25141': {
    country: 'US',
    city: 'Nebo',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.6271,
    longitude: -81.0257,
    postal_code: '25141'
  },
  '25142': {
    country: 'US',
    city: 'Nellis',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.153,
    longitude: -81.7321,
    postal_code: '25142'
  },
  '25143': {
    country: 'US',
    city: 'Nitro',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.4193,
    longitude: -81.8293,
    postal_code: '25143'
  },
  '25148': {
    country: 'US',
    city: 'Orgas',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.0492,
    longitude: -81.5688,
    postal_code: '25148'
  },
  '25149': {
    country: 'US',
    city: 'Ottawa',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 37.9587,
    longitude: -81.8165,
    postal_code: '25149'
  },
  '25152': {
    country: 'US',
    city: 'Page',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.0529,
    longitude: -81.2698,
    postal_code: '25152'
  },
  '25154': {
    country: 'US',
    city: 'Peytona',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.125,
    longitude: -81.7018,
    postal_code: '25154'
  },
  '25156': {
    country: 'US',
    city: 'Pinch',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.402,
    longitude: -81.4611,
    postal_code: '25156'
  },
  '25159': {
    country: 'US',
    city: 'Poca',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.4791,
    longitude: -81.7838,
    postal_code: '25159'
  },
  '25160': {
    country: 'US',
    city: 'Pond Gap',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2904,
    longitude: -81.2866,
    postal_code: '25160'
  },
  '25161': {
    country: 'US',
    city: 'Powellton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.099,
    longitude: -81.324,
    postal_code: '25161'
  },
  '25162': {
    country: 'US',
    city: 'Pratt',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2101,
    longitude: -81.3851,
    postal_code: '25162'
  },
  '25164': {
    country: 'US',
    city: 'Procious',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.4912,
    longitude: -81.1989,
    postal_code: '25164'
  },
  '25165': {
    country: 'US',
    city: 'Racine',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.141,
    longitude: -81.6588,
    postal_code: '25165'
  },
  '25168': {
    country: 'US',
    city: 'Red House',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.5463,
    longitude: -81.9045,
    postal_code: '25168'
  },
  '25169': {
    country: 'US',
    city: 'Ridgeview',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.1486,
    longitude: -81.7725,
    postal_code: '25169'
  },
  '25173': {
    country: 'US',
    city: 'Robson',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.0973,
    longitude: -81.2488,
    postal_code: '25173'
  },
  '25174': {
    country: 'US',
    city: 'Rock Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8547,
    longitude: -81.4184,
    postal_code: '25174'
  },
  '25177': {
    country: 'US',
    city: 'Saint Albans',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3774,
    longitude: -81.8305,
    postal_code: '25177'
  },
  '25180': {
    country: 'US',
    city: 'Saxon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7901,
    longitude: -81.4346,
    postal_code: '25180'
  },
  '25181': {
    country: 'US',
    city: 'Seth',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.0971,
    longitude: -81.6393,
    postal_code: '25181'
  },
  '25183': {
    country: 'US',
    city: 'Sharples',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.9204,
    longitude: -81.8293,
    postal_code: '25183'
  },
  '25185': {
    country: 'US',
    city: 'Mount Olive',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.2379,
    longitude: -81.2334,
    postal_code: '25185'
  },
  '25186': {
    country: 'US',
    city: 'Smithers',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1812,
    longitude: -81.3071,
    postal_code: '25186'
  },
  '25187': {
    country: 'US',
    city: 'Southside',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.712,
    longitude: -81.9966,
    postal_code: '25187'
  },
  '25193': {
    country: 'US',
    city: 'Sylvester',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.0091,
    longitude: -81.5492,
    postal_code: '25193'
  },
  '25201': {
    country: 'US',
    city: 'Tad',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3334,
    longitude: -81.4943,
    postal_code: '25201'
  },
  '25202': {
    country: 'US',
    city: 'Tornado',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3357,
    longitude: -81.8476,
    postal_code: '25202'
  },
  '25203': {
    country: 'US',
    city: 'Turtle Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.0298,
    longitude: -81.8729,
    postal_code: '25203'
  },
  '25204': {
    country: 'US',
    city: 'Twilight',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 37.926,
    longitude: -81.6141,
    postal_code: '25204'
  },
  '25205': {
    country: 'US',
    city: 'Uneeda',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.0357,
    longitude: -81.7807,
    postal_code: '25205'
  },
  '25206': {
    country: 'US',
    city: 'Van',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 37.9712,
    longitude: -81.7022,
    postal_code: '25206'
  },
  '25208': {
    country: 'US',
    city: 'Wharton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 37.9034,
    longitude: -81.6773,
    postal_code: '25208'
  },
  '25209': {
    country: 'US',
    city: 'Whitesville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 37.9916,
    longitude: -81.5422,
    postal_code: '25209'
  },
  '25211': {
    country: 'US',
    city: 'Widen',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.4662,
    longitude: -80.8573,
    postal_code: '25211'
  },
  '25213': {
    country: 'US',
    city: 'Winfield',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.5072,
    longitude: -81.89,
    postal_code: '25213'
  },
  '25214': {
    country: 'US',
    city: 'Winifrede',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.1852,
    longitude: -81.5587,
    postal_code: '25214'
  },
  '25231': {
    country: 'US',
    city: 'Advent',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.6104,
    longitude: -81.5587,
    postal_code: '25231'
  },
  '25234': {
    country: 'US',
    city: 'Arnoldsburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Calhoun',
    latitude: 38.8227,
    longitude: -81.1551,
    postal_code: '25234'
  },
  '25235': {
    country: 'US',
    city: 'Chloe',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Calhoun',
    latitude: 38.6686,
    longitude: -81.0944,
    postal_code: '25235'
  },
  '25239': {
    country: 'US',
    city: 'Cottageville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.8633,
    longitude: -81.8186,
    postal_code: '25239'
  },
  '25241': {
    country: 'US',
    city: 'Evans',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.8113,
    longitude: -81.7909,
    postal_code: '25241'
  },
  '25243': {
    country: 'US',
    city: 'Gandeeville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Roane',
    latitude: 38.7034,
    longitude: -81.4243,
    postal_code: '25243'
  },
  '25244': {
    country: 'US',
    city: 'Gay',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.7706,
    longitude: -81.5532,
    postal_code: '25244'
  },
  '25245': {
    country: 'US',
    city: 'Given',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.707,
    longitude: -81.7223,
    postal_code: '25245'
  },
  '25247': {
    country: 'US',
    city: 'Hartford',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 39.004,
    longitude: -81.989,
    postal_code: '25247'
  },
  '25248': {
    country: 'US',
    city: 'Kenna',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.6232,
    longitude: -81.6217,
    postal_code: '25248'
  },
  '25251': {
    country: 'US',
    city: 'Left Hand',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Roane',
    latitude: 38.6063,
    longitude: -81.2467,
    postal_code: '25251'
  },
  '25252': {
    country: 'US',
    city: 'Le Roy',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.8888,
    longitude: -81.5326,
    postal_code: '25252'
  },
  '25253': {
    country: 'US',
    city: 'Letart',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.9311,
    longitude: -81.9751,
    postal_code: '25253'
  },
  '25259': {
    country: 'US',
    city: 'Looneyville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Roane',
    latitude: 38.6641,
    longitude: -81.2822,
    postal_code: '25259'
  },
  '25260': {
    country: 'US',
    city: 'Mason',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 39.0064,
    longitude: -82.0282,
    postal_code: '25260'
  },
  '25261': {
    country: 'US',
    city: 'Millstone',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Calhoun',
    latitude: 38.8015,
    longitude: -81.0962,
    postal_code: '25261'
  },
  '25262': {
    country: 'US',
    city: 'Millwood',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.8962,
    longitude: -81.8248,
    postal_code: '25262'
  },
  '25264': {
    country: 'US',
    city: 'Mount Alto',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.8639,
    longitude: -81.8784,
    postal_code: '25264'
  },
  '25265': {
    country: 'US',
    city: 'New Haven',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.9863,
    longitude: -81.9652,
    postal_code: '25265'
  },
  '25266': {
    country: 'US',
    city: 'Newton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Roane',
    latitude: 38.606,
    longitude: -81.1623,
    postal_code: '25266'
  },
  '25267': {
    country: 'US',
    city: 'Normantown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Gilmer',
    latitude: 38.8802,
    longitude: -80.9419,
    postal_code: '25267'
  },
  '25268': {
    country: 'US',
    city: 'Orma',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Calhoun',
    latitude: 38.7481,
    longitude: -81.0998,
    postal_code: '25268'
  },
  '25270': {
    country: 'US',
    city: 'Reedy',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Roane',
    latitude: 38.8889,
    longitude: -81.4399,
    postal_code: '25270'
  },
  '25271': {
    country: 'US',
    city: 'Ripley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.809,
    longitude: -81.702,
    postal_code: '25271'
  },
  '25275': {
    country: 'US',
    city: 'Sandyville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.9093,
    longitude: -81.6543,
    postal_code: '25275'
  },
  '25276': {
    country: 'US',
    city: 'Spencer',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Roane',
    latitude: 38.7897,
    longitude: -81.3303,
    postal_code: '25276'
  },
  '25285': {
    country: 'US',
    city: 'Wallback',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.5432,
    longitude: -81.1114,
    postal_code: '25285'
  },
  '25286': {
    country: 'US',
    city: 'Walton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Roane',
    latitude: 38.6023,
    longitude: -81.3958,
    postal_code: '25286'
  },
  '25287': {
    country: 'US',
    city: 'West Columbia',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.9574,
    longitude: -82.0517,
    postal_code: '25287'
  },
  '25301': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.349,
    longitude: -81.6306,
    postal_code: '25301'
  },
  '25302': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.4015,
    longitude: -81.5841,
    postal_code: '25302'
  },
  '25303': {
    country: 'US',
    city: 'South Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3684,
    longitude: -81.6996,
    postal_code: '25303'
  },
  '25304': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3173,
    longitude: -81.5903,
    postal_code: '25304'
  },
  '25305': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3358,
    longitude: -81.6123,
    postal_code: '25305'
  },
  '25306': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3175,
    longitude: -81.5274,
    postal_code: '25306'
  },
  '25309': {
    country: 'US',
    city: 'South Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3684,
    longitude: -81.6996,
    postal_code: '25309'
  },
  '25311': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.349,
    longitude: -81.5993,
    postal_code: '25311'
  },
  '25312': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.4096,
    longitude: -81.6747,
    postal_code: '25312'
  },
  '25313': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.4142,
    longitude: -81.7582,
    postal_code: '25313'
  },
  '25314': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3274,
    longitude: -81.669,
    postal_code: '25314'
  },
  '25315': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2351,
    longitude: -81.5536,
    postal_code: '25315'
  },
  '25317': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3356,
    longitude: -81.6138,
    postal_code: '25317'
  },
  '25320': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.5428,
    longitude: -81.6334,
    postal_code: '25320'
  },
  '25321': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25321'
  },
  '25322': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25322'
  },
  '25323': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25323'
  },
  '25324': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25324'
  },
  '25325': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25325'
  },
  '25326': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2968,
    longitude: -81.5547,
    postal_code: '25326'
  },
  '25327': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25327'
  },
  '25328': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25328'
  },
  '25329': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25329'
  },
  '25330': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25330'
  },
  '25331': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25331'
  },
  '25332': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25332'
  },
  '25333': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25333'
  },
  '25334': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25334'
  },
  '25335': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25335'
  },
  '25336': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25336'
  },
  '25337': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25337'
  },
  '25338': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25338'
  },
  '25339': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25339'
  },
  '25350': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25350'
  },
  '25356': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2968,
    longitude: -81.5547,
    postal_code: '25356'
  },
  '25357': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2968,
    longitude: -81.5547,
    postal_code: '25357'
  },
  '25358': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2968,
    longitude: -81.5547,
    postal_code: '25358'
  },
  '25360': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2968,
    longitude: -81.5547,
    postal_code: '25360'
  },
  '25361': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2968,
    longitude: -81.5547,
    postal_code: '25361'
  },
  '25362': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2968,
    longitude: -81.5547,
    postal_code: '25362'
  },
  '25364': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2968,
    longitude: -81.5547,
    postal_code: '25364'
  },
  '25365': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.2968,
    longitude: -81.5547,
    postal_code: '25365'
  },
  '25375': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25375'
  },
  '25387': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25387'
  },
  '25389': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.354,
    longitude: -81.6394,
    postal_code: '25389'
  },
  '25392': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25392'
  },
  '25396': {
    country: 'US',
    city: 'Charleston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Kanawha',
    latitude: 38.3498,
    longitude: -81.6326,
    postal_code: '25396'
  },
  '25401': {
    country: 'US',
    city: 'Martinsburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.4567,
    longitude: -77.9727,
    postal_code: '25401'
  },
  '25402': {
    country: 'US',
    city: 'Martinsburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.4562,
    longitude: -77.9639,
    postal_code: '25402'
  },
  '25403': {
    country: 'US',
    city: 'Martinsburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.5227,
    longitude: -77.9747,
    postal_code: '25403'
  },
  '25404': {
    country: 'US',
    city: 'Martinsburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.4852,
    longitude: -77.8954,
    postal_code: '25404'
  },
  '25405': {
    country: 'US',
    city: 'Martinsburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.4092,
    longitude: -77.9615,
    postal_code: '25405'
  },
  '25410': {
    country: 'US',
    city: 'Bakerton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.3159,
    longitude: -77.8772,
    postal_code: '25410'
  },
  '25411': {
    country: 'US',
    city: 'Berkeley Springs',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Morgan',
    latitude: 39.5526,
    longitude: -78.1877,
    postal_code: '25411'
  },
  '25413': {
    country: 'US',
    city: 'Bunker Hill',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.3276,
    longitude: -78.0487,
    postal_code: '25413'
  },
  '25414': {
    country: 'US',
    city: 'Charles Town',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.2771,
    longitude: -77.8563,
    postal_code: '25414'
  },
  '25419': {
    country: 'US',
    city: 'Falling Waters',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.5865,
    longitude: -77.8852,
    postal_code: '25419'
  },
  '25420': {
    country: 'US',
    city: 'Gerrardstown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.378,
    longitude: -78.1141,
    postal_code: '25420'
  },
  '25421': {
    country: 'US',
    city: 'Glengary',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.3813,
    longitude: -78.1435,
    postal_code: '25421'
  },
  '25422': {
    country: 'US',
    city: 'Great Cacapon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Morgan',
    latitude: 39.5836,
    longitude: -78.3331,
    postal_code: '25422'
  },
  '25423': {
    country: 'US',
    city: 'Halltown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.314,
    longitude: -77.7978,
    postal_code: '25423'
  },
  '25425': {
    country: 'US',
    city: 'Harpers Ferry',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.3153,
    longitude: -77.7694,
    postal_code: '25425'
  },
  '25427': {
    country: 'US',
    city: 'Hedgesville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.5214,
    longitude: -78.0912,
    postal_code: '25427'
  },
  '25428': {
    country: 'US',
    city: 'Inwood',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.3701,
    longitude: -78.0242,
    postal_code: '25428'
  },
  '25430': {
    country: 'US',
    city: 'Kearneysville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.322,
    longitude: -77.9588,
    postal_code: '25430'
  },
  '25431': {
    country: 'US',
    city: 'Levels',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.4976,
    longitude: -78.5508,
    postal_code: '25431'
  },
  '25432': {
    country: 'US',
    city: 'Millville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.294,
    longitude: -77.7858,
    postal_code: '25432'
  },
  '25434': {
    country: 'US',
    city: 'Paw Paw',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Morgan',
    latitude: 39.4923,
    longitude: -78.4586,
    postal_code: '25434'
  },
  '25437': {
    country: 'US',
    city: 'Points',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.4401,
    longitude: -78.6103,
    postal_code: '25437'
  },
  '25438': {
    country: 'US',
    city: 'Ranson',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.2995,
    longitude: -77.8606,
    postal_code: '25438'
  },
  '25440': {
    country: 'US',
    city: 'Ridgeway',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Berkeley',
    latitude: 39.2973,
    longitude: -78.0717,
    postal_code: '25440'
  },
  '25441': {
    country: 'US',
    city: 'Rippon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.3159,
    longitude: -77.8772,
    postal_code: '25441'
  },
  '25442': {
    country: 'US',
    city: 'Shenandoah Junction',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.3716,
    longitude: -77.8229,
    postal_code: '25442'
  },
  '25443': {
    country: 'US',
    city: 'Shepherdstown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.4311,
    longitude: -77.8158,
    postal_code: '25443'
  },
  '25444': {
    country: 'US',
    city: 'Slanesville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.3732,
    longitude: -78.5228,
    postal_code: '25444'
  },
  '25446': {
    country: 'US',
    city: 'Summit Point',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jefferson',
    latitude: 39.2338,
    longitude: -77.9586,
    postal_code: '25446'
  },
  '25501': {
    country: 'US',
    city: 'Alkol',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.1609,
    longitude: -81.984,
    postal_code: '25501'
  },
  '25502': {
    country: 'US',
    city: 'Apple Grove',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.6575,
    longitude: -82.1068,
    postal_code: '25502'
  },
  '25503': {
    country: 'US',
    city: 'Ashton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.6032,
    longitude: -82.1123,
    postal_code: '25503'
  },
  '25504': {
    country: 'US',
    city: 'Barboursville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.3893,
    longitude: -82.296,
    postal_code: '25504'
  },
  '25505': {
    country: 'US',
    city: 'Big Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 38.0043,
    longitude: -82.0401,
    postal_code: '25505'
  },
  '25506': {
    country: 'US',
    city: 'Branchland',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.2694,
    longitude: -82.2684,
    postal_code: '25506'
  },
  '25507': {
    country: 'US',
    city: 'Ceredo',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.3965,
    longitude: -82.5588,
    postal_code: '25507'
  },
  '25508': {
    country: 'US',
    city: 'Chapmanville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.9737,
    longitude: -82.0174,
    postal_code: '25508'
  },
  '25510': {
    country: 'US',
    city: 'Culloden',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4198,
    longitude: -82.069,
    postal_code: '25510'
  },
  '25511': {
    country: 'US',
    city: 'Dunlow',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.0231,
    longitude: -82.4315,
    postal_code: '25511'
  },
  '25512': {
    country: 'US',
    city: 'East Lynn',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.1995,
    longitude: -82.3279,
    postal_code: '25512'
  },
  '25514': {
    country: 'US',
    city: 'Fort Gay',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.1335,
    longitude: -82.5541,
    postal_code: '25514'
  },
  '25515': {
    country: 'US',
    city: 'Gallipolis Ferry',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.765,
    longitude: -82.1535,
    postal_code: '25515'
  },
  '25517': {
    country: 'US',
    city: 'Genoa',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.1321,
    longitude: -82.4183,
    postal_code: '25517'
  },
  '25520': {
    country: 'US',
    city: 'Glenwood',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.5909,
    longitude: -82.1773,
    postal_code: '25520'
  },
  '25521': {
    country: 'US',
    city: 'Griffithsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.2197,
    longitude: -81.9995,
    postal_code: '25521'
  },
  '25523': {
    country: 'US',
    city: 'Hamlin',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.2896,
    longitude: -82.1047,
    postal_code: '25523'
  },
  '25524': {
    country: 'US',
    city: 'Harts',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.0341,
    longitude: -82.1008,
    postal_code: '25524'
  },
  '25526': {
    country: 'US',
    city: 'Hurricane',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.4257,
    longitude: -81.9943,
    postal_code: '25526'
  },
  '25529': {
    country: 'US',
    city: 'Julian',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Boone',
    latitude: 38.1264,
    longitude: -81.8642,
    postal_code: '25529'
  },
  '25530': {
    country: 'US',
    city: 'Kenova',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.3569,
    longitude: -82.5255,
    postal_code: '25530'
  },
  '25534': {
    country: 'US',
    city: 'Kiahsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.0915,
    longitude: -82.3274,
    postal_code: '25534'
  },
  '25535': {
    country: 'US',
    city: 'Lavalette',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.3305,
    longitude: -82.4287,
    postal_code: '25535'
  },
  '25537': {
    country: 'US',
    city: 'Lesage',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.477,
    longitude: -82.2957,
    postal_code: '25537'
  },
  '25540': {
    country: 'US',
    city: 'Midkiff',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.1762,
    longitude: -82.1788,
    postal_code: '25540'
  },
  '25541': {
    country: 'US',
    city: 'Milton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.414,
    longitude: -82.1458,
    postal_code: '25541'
  },
  '25544': {
    country: 'US',
    city: 'Myra',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.2198,
    longitude: -82.1351,
    postal_code: '25544'
  },
  '25545': {
    country: 'US',
    city: 'Ona',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4529,
    longitude: -82.2181,
    postal_code: '25545'
  },
  '25547': {
    country: 'US',
    city: 'Pecks Mill',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.9298,
    longitude: -81.9654,
    postal_code: '25547'
  },
  '25550': {
    country: 'US',
    city: 'Point Pleasant',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mason',
    latitude: 38.8632,
    longitude: -82.1132,
    postal_code: '25550'
  },
  '25555': {
    country: 'US',
    city: 'Prichard',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.23,
    longitude: -82.5753,
    postal_code: '25555'
  },
  '25557': {
    country: 'US',
    city: 'Ranger',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.097,
    longitude: -82.1633,
    postal_code: '25557'
  },
  '25559': {
    country: 'US',
    city: 'Salt Rock',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.3265,
    longitude: -82.247,
    postal_code: '25559'
  },
  '25560': {
    country: 'US',
    city: 'Scott Depot',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.4501,
    longitude: -81.9005,
    postal_code: '25560'
  },
  '25562': {
    country: 'US',
    city: 'Shoals',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.3432,
    longitude: -82.4905,
    postal_code: '25562'
  },
  '25564': {
    country: 'US',
    city: 'Sod',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.2559,
    longitude: -81.891,
    postal_code: '25564'
  },
  '25565': {
    country: 'US',
    city: 'Spurlockville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.1057,
    longitude: -82.0272,
    postal_code: '25565'
  },
  '25567': {
    country: 'US',
    city: 'Sumerco',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.2312,
    longitude: -81.9115,
    postal_code: '25567'
  },
  '25569': {
    country: 'US',
    city: 'Teays',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Putnam',
    latitude: 38.4754,
    longitude: -81.8805,
    postal_code: '25569'
  },
  '25570': {
    country: 'US',
    city: 'Wayne',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.2258,
    longitude: -82.4339,
    postal_code: '25570'
  },
  '25571': {
    country: 'US',
    city: 'West Hamlin',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.2886,
    longitude: -82.1843,
    postal_code: '25571'
  },
  '25572': {
    country: 'US',
    city: 'Woodville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.1615,
    longitude: -81.8921,
    postal_code: '25572'
  },
  '25573': {
    country: 'US',
    city: 'Yawkey',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lincoln',
    latitude: 38.2193,
    longitude: -81.9568,
    postal_code: '25573'
  },
  '25601': {
    country: 'US',
    city: 'Logan',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.8487,
    longitude: -81.9935,
    postal_code: '25601'
  },
  '25606': {
    country: 'US',
    city: 'Accoville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7687,
    longitude: -81.8371,
    postal_code: '25606'
  },
  '25607': {
    country: 'US',
    city: 'Amherstdale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7959,
    longitude: -81.7833,
    postal_code: '25607'
  },
  '25608': {
    country: 'US',
    city: 'Baisden',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.584,
    longitude: -81.9051,
    postal_code: '25608'
  },
  '25611': {
    country: 'US',
    city: 'Bruno',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.6907,
    longitude: -81.869,
    postal_code: '25611'
  },
  '25612': {
    country: 'US',
    city: 'Chauncey',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7662,
    longitude: -81.9876,
    postal_code: '25612'
  },
  '25614': {
    country: 'US',
    city: 'Cora',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.8332,
    longitude: -81.9024,
    postal_code: '25614'
  },
  '25617': {
    country: 'US',
    city: 'Davin',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7265,
    longitude: -81.8057,
    postal_code: '25617'
  },
  '25621': {
    country: 'US',
    city: 'Gilbert',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.6366,
    longitude: -81.9501,
    postal_code: '25621'
  },
  '25624': {
    country: 'US',
    city: 'Henlawson',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.9023,
    longitude: -81.9882,
    postal_code: '25624'
  },
  '25625': {
    country: 'US',
    city: 'Holden',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.83,
    longitude: -82.0585,
    postal_code: '25625'
  },
  '25628': {
    country: 'US',
    city: 'Kistler',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7562,
    longitude: -81.8601,
    postal_code: '25628'
  },
  '25630': {
    country: 'US',
    city: 'Lorado',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7957,
    longitude: -81.7148,
    postal_code: '25630'
  },
  '25632': {
    country: 'US',
    city: 'Lyburn',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7656,
    longitude: -81.9217,
    postal_code: '25632'
  },
  '25634': {
    country: 'US',
    city: 'Mallory',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7307,
    longitude: -81.8379,
    postal_code: '25634'
  },
  '25635': {
    country: 'US',
    city: 'Man',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7357,
    longitude: -81.8754,
    postal_code: '25635'
  },
  '25637': {
    country: 'US',
    city: 'Mount Gay',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.8415,
    longitude: -82.0087,
    postal_code: '25637'
  },
  '25638': {
    country: 'US',
    city: 'Omar',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7557,
    longitude: -81.9996,
    postal_code: '25638'
  },
  '25639': {
    country: 'US',
    city: 'Peach Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.8752,
    longitude: -81.9826,
    postal_code: '25639'
  },
  '25644': {
    country: 'US',
    city: 'Sarah Ann',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7112,
    longitude: -81.9873,
    postal_code: '25644'
  },
  '25646': {
    country: 'US',
    city: 'Stollings',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.8303,
    longitude: -81.9639,
    postal_code: '25646'
  },
  '25647': {
    country: 'US',
    city: 'Switzer',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7846,
    longitude: -81.9908,
    postal_code: '25647'
  },
  '25649': {
    country: 'US',
    city: 'Verdunville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.8445,
    longitude: -82.0551,
    postal_code: '25649'
  },
  '25650': {
    country: 'US',
    city: 'Verner',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.6634,
    longitude: -81.8504,
    postal_code: '25650'
  },
  '25651': {
    country: 'US',
    city: 'Wharncliffe',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.5554,
    longitude: -81.9657,
    postal_code: '25651'
  },
  '25652': {
    country: 'US',
    city: 'Whitman',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.8035,
    longitude: -82.0466,
    postal_code: '25652'
  },
  '25653': {
    country: 'US',
    city: 'Wilkinson',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.8279,
    longitude: -81.9976,
    postal_code: '25653'
  },
  '25654': {
    country: 'US',
    city: 'Yolyn',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Logan',
    latitude: 37.7818,
    longitude: -81.892,
    postal_code: '25654'
  },
  '25661': {
    country: 'US',
    city: 'Williamson',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.6902,
    longitude: -82.2642,
    postal_code: '25661'
  },
  '25665': {
    country: 'US',
    city: 'Borderland',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.7214,
    longitude: -82.3084,
    postal_code: '25665'
  },
  '25666': {
    country: 'US',
    city: 'Breeden',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.9198,
    longitude: -82.2547,
    postal_code: '25666'
  },
  '25667': {
    country: 'US',
    city: 'Chattaroy',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.7047,
    longitude: -82.2739,
    postal_code: '25667'
  },
  '25669': {
    country: 'US',
    city: 'Crum',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 37.9389,
    longitude: -82.4624,
    postal_code: '25669'
  },
  '25670': {
    country: 'US',
    city: 'Delbarton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.7189,
    longitude: -82.1931,
    postal_code: '25670'
  },
  '25671': {
    country: 'US',
    city: 'Dingess',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.8703,
    longitude: -82.1951,
    postal_code: '25671'
  },
  '25672': {
    country: 'US',
    city: 'Edgarton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.7431,
    longitude: -82.108,
    postal_code: '25672'
  },
  '25674': {
    country: 'US',
    city: 'Kermit',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.8642,
    longitude: -82.3741,
    postal_code: '25674'
  },
  '25676': {
    country: 'US',
    city: 'Lenore',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.799,
    longitude: -82.2868,
    postal_code: '25676'
  },
  '25678': {
    country: 'US',
    city: 'Matewan',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.5903,
    longitude: -82.0652,
    postal_code: '25678'
  },
  '25685': {
    country: 'US',
    city: 'Naugatuck',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.7898,
    longitude: -82.349,
    postal_code: '25685'
  },
  '25686': {
    country: 'US',
    city: 'Newtown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.7431,
    longitude: -82.108,
    postal_code: '25686'
  },
  '25688': {
    country: 'US',
    city: 'North Matewan',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.6265,
    longitude: -82.1482,
    postal_code: '25688'
  },
  '25690': {
    country: 'US',
    city: 'Ragland',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.6898,
    longitude: -82.1244,
    postal_code: '25690'
  },
  '25691': {
    country: 'US',
    city: 'Rawl',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.6498,
    longitude: -82.2196,
    postal_code: '25691'
  },
  '25692': {
    country: 'US',
    city: 'Red Jacket',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.6484,
    longitude: -82.1401,
    postal_code: '25692'
  },
  '25696': {
    country: 'US',
    city: 'Varney',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mingo',
    latitude: 37.6693,
    longitude: -82.1221,
    postal_code: '25696'
  },
  '25699': {
    country: 'US',
    city: 'Wilsondale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 37.9537,
    longitude: -82.3276,
    postal_code: '25699'
  },
  '25701': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4097,
    longitude: -82.4423,
    postal_code: '25701'
  },
  '25702': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4286,
    longitude: -82.3911,
    postal_code: '25702'
  },
  '25703': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4211,
    longitude: -82.4227,
    postal_code: '25703'
  },
  '25704': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.3849,
    longitude: -82.5036,
    postal_code: '25704'
  },
  '25705': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4096,
    longitude: -82.369,
    postal_code: '25705'
  },
  '25706': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25706'
  },
  '25707': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25707'
  },
  '25708': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4192,
    longitude: -82.4452,
    postal_code: '25708'
  },
  '25709': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wayne',
    latitude: 38.4192,
    longitude: -82.4452,
    postal_code: '25709'
  },
  '25710': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4192,
    longitude: -82.4452,
    postal_code: '25710'
  },
  '25711': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25711'
  },
  '25712': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25712'
  },
  '25713': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25713'
  },
  '25714': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25714'
  },
  '25715': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25715'
  },
  '25716': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25716'
  },
  '25717': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4192,
    longitude: -82.4452,
    postal_code: '25717'
  },
  '25718': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4192,
    longitude: -82.4452,
    postal_code: '25718'
  },
  '25719': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4192,
    longitude: -82.4452,
    postal_code: '25719'
  },
  '25720': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25720'
  },
  '25721': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4192,
    longitude: -82.4452,
    postal_code: '25721'
  },
  '25722': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4192,
    longitude: -82.4452,
    postal_code: '25722'
  },
  '25723': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4192,
    longitude: -82.4452,
    postal_code: '25723'
  },
  '25724': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4192,
    longitude: -82.4452,
    postal_code: '25724'
  },
  '25725': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25725'
  },
  '25726': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25726'
  },
  '25727': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25727'
  },
  '25728': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25728'
  },
  '25729': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25729'
  },
  '25755': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4221,
    longitude: -82.4317,
    postal_code: '25755'
  },
  '25770': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25770'
  },
  '25771': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25771'
  },
  '25772': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25772'
  },
  '25773': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25773'
  },
  '25774': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25774'
  },
  '25775': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25775'
  },
  '25776': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25776'
  },
  '25777': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25777'
  },
  '25778': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25778'
  },
  '25779': {
    country: 'US',
    city: 'Huntington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Cabell',
    latitude: 38.4134,
    longitude: -82.2774,
    postal_code: '25779'
  },
  '25801': {
    country: 'US',
    city: 'Beckley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7932,
    longitude: -81.2061,
    postal_code: '25801'
  },
  '25802': {
    country: 'US',
    city: 'Beckley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7489,
    longitude: -81.2245,
    postal_code: '25802'
  },
  '25810': {
    country: 'US',
    city: 'Allen Junction',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5893,
    longitude: -81.3507,
    postal_code: '25810'
  },
  '25811': {
    country: 'US',
    city: 'Amigo',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5965,
    longitude: -81.3209,
    postal_code: '25811'
  },
  '25812': {
    country: 'US',
    city: 'Ansted',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1369,
    longitude: -81.0955,
    postal_code: '25812'
  },
  '25813': {
    country: 'US',
    city: 'Beaver',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7516,
    longitude: -81.0731,
    postal_code: '25813'
  },
  '25817': {
    country: 'US',
    city: 'Bolt',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7673,
    longitude: -81.4156,
    postal_code: '25817'
  },
  '25818': {
    country: 'US',
    city: 'Bradley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8654,
    longitude: -81.194,
    postal_code: '25818'
  },
  '25820': {
    country: 'US',
    city: 'Camp Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.4841,
    longitude: -81.1111,
    postal_code: '25820'
  },
  '25823': {
    country: 'US',
    city: 'Coal City',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.679,
    longitude: -81.2104,
    postal_code: '25823'
  },
  '25825': {
    country: 'US',
    city: 'Cool Ridge',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.6655,
    longitude: -81.0933,
    postal_code: '25825'
  },
  '25826': {
    country: 'US',
    city: 'Corinne',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5729,
    longitude: -81.3565,
    postal_code: '25826'
  },
  '25827': {
    country: 'US',
    city: 'Crab Orchard',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7385,
    longitude: -81.2536,
    postal_code: '25827'
  },
  '25831': {
    country: 'US',
    city: 'Danese',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.9567,
    longitude: -80.9379,
    postal_code: '25831'
  },
  '25832': {
    country: 'US',
    city: 'Daniels',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7256,
    longitude: -81.1087,
    postal_code: '25832'
  },
  '25833': {
    country: 'US',
    city: 'Dothan',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.0413,
    longitude: -81.0648,
    postal_code: '25833'
  },
  '25836': {
    country: 'US',
    city: 'Eccles',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7792,
    longitude: -81.2635,
    postal_code: '25836'
  },
  '25837': {
    country: 'US',
    city: 'Edmond',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.0544,
    longitude: -81.0326,
    postal_code: '25837'
  },
  '25839': {
    country: 'US',
    city: 'Fairdale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7815,
    longitude: -81.3632,
    postal_code: '25839'
  },
  '25840': {
    country: 'US',
    city: 'Fayetteville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.064,
    longitude: -81.0943,
    postal_code: '25840'
  },
  '25841': {
    country: 'US',
    city: 'Flat Top',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.55,
    longitude: -81.128,
    postal_code: '25841'
  },
  '25843': {
    country: 'US',
    city: 'Ghent',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.6208,
    longitude: -81.1019,
    postal_code: '25843'
  },
  '25844': {
    country: 'US',
    city: 'Glen Daniel',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8251,
    longitude: -81.3858,
    postal_code: '25844'
  },
  '25845': {
    country: 'US',
    city: 'Glen Fork',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6959,
    longitude: -81.529,
    postal_code: '25845'
  },
  '25846': {
    country: 'US',
    city: 'Glen Jean',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.9265,
    longitude: -81.1501,
    postal_code: '25846'
  },
  '25848': {
    country: 'US',
    city: 'Glen Rogers',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.7217,
    longitude: -81.429,
    postal_code: '25848'
  },
  '25849': {
    country: 'US',
    city: 'Glen White',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7306,
    longitude: -81.2799,
    postal_code: '25849'
  },
  '25851': {
    country: 'US',
    city: 'Harper',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8025,
    longitude: -81.2828,
    postal_code: '25851'
  },
  '25853': {
    country: 'US',
    city: 'Helen',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.6375,
    longitude: -81.3138,
    postal_code: '25853'
  },
  '25854': {
    country: 'US',
    city: 'Hico',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1094,
    longitude: -80.9638,
    postal_code: '25854'
  },
  '25855': {
    country: 'US',
    city: 'Hilltop',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.9429,
    longitude: -81.1509,
    postal_code: '25855'
  },
  '25857': {
    country: 'US',
    city: 'Josephine',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.6221,
    longitude: -81.2176,
    postal_code: '25857'
  },
  '25860': {
    country: 'US',
    city: 'Lanark',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8276,
    longitude: -81.1462,
    postal_code: '25860'
  },
  '25862': {
    country: 'US',
    city: 'Lansing',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.0869,
    longitude: -81.0588,
    postal_code: '25862'
  },
  '25864': {
    country: 'US',
    city: 'Layland',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.8893,
    longitude: -80.9712,
    postal_code: '25864'
  },
  '25865': {
    country: 'US',
    city: 'Lester',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7265,
    longitude: -81.3095,
    postal_code: '25865'
  },
  '25866': {
    country: 'US',
    city: 'Lochgelly',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.0107,
    longitude: -81.1451,
    postal_code: '25866'
  },
  '25868': {
    country: 'US',
    city: 'Lookout',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.0654,
    longitude: -80.9684,
    postal_code: '25868'
  },
  '25870': {
    country: 'US',
    city: 'Maben',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6387,
    longitude: -81.394,
    postal_code: '25870'
  },
  '25871': {
    country: 'US',
    city: 'Mabscott',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.77,
    longitude: -81.2103,
    postal_code: '25871'
  },
  '25873': {
    country: 'US',
    city: 'Mac Arthur',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7517,
    longitude: -81.2148,
    postal_code: '25873'
  },
  '25875': {
    country: 'US',
    city: 'Mc Graws',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6033,
    longitude: -81.54,
    postal_code: '25875'
  },
  '25876': {
    country: 'US',
    city: 'Saulsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6429,
    longitude: -81.4568,
    postal_code: '25876'
  },
  '25878': {
    country: 'US',
    city: 'Midway',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7171,
    longitude: -81.24,
    postal_code: '25878'
  },
  '25879': {
    country: 'US',
    city: 'Minden',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.9758,
    longitude: -81.115,
    postal_code: '25879'
  },
  '25880': {
    country: 'US',
    city: 'Mount Hope',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.8782,
    longitude: -81.2058,
    postal_code: '25880'
  },
  '25882': {
    country: 'US',
    city: 'Mullens',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5842,
    longitude: -81.389,
    postal_code: '25882'
  },
  '25888': {
    country: 'US',
    city: 'Mount Hope',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.893,
    longitude: -81.1668,
    postal_code: '25888'
  },
  '25901': {
    country: 'US',
    city: 'Oak Hill',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.9723,
    longitude: -81.1487,
    postal_code: '25901'
  },
  '25902': {
    country: 'US',
    city: 'Odd',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.593,
    longitude: -81.1872,
    postal_code: '25902'
  },
  '25904': {
    country: 'US',
    city: 'Pax',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.911,
    longitude: -81.2648,
    postal_code: '25904'
  },
  '25906': {
    country: 'US',
    city: 'Piney View',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8376,
    longitude: -81.1286,
    postal_code: '25906'
  },
  '25907': {
    country: 'US',
    city: 'Prince',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.8532,
    longitude: -81.0817,
    postal_code: '25907'
  },
  '25908': {
    country: 'US',
    city: 'Princewick',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.6724,
    longitude: -81.2561,
    postal_code: '25908'
  },
  '25909': {
    country: 'US',
    city: 'Prosperity',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8381,
    longitude: -81.2004,
    postal_code: '25909'
  },
  '25911': {
    country: 'US',
    city: 'Raleigh',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7585,
    longitude: -81.1675,
    postal_code: '25911'
  },
  '25913': {
    country: 'US',
    city: 'Ravencliff',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.7141,
    longitude: -81.4895,
    postal_code: '25913'
  },
  '25915': {
    country: 'US',
    city: 'Rhodell',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.6254,
    longitude: -81.2749,
    postal_code: '25915'
  },
  '25916': {
    country: 'US',
    city: 'Sabine',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.6787,
    longitude: -81.5015,
    postal_code: '25916'
  },
  '25917': {
    country: 'US',
    city: 'Scarbro',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.9488,
    longitude: -81.1531,
    postal_code: '25917'
  },
  '25918': {
    country: 'US',
    city: 'Shady Spring',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7416,
    longitude: -81.0149,
    postal_code: '25918'
  },
  '25919': {
    country: 'US',
    city: 'Skelton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8005,
    longitude: -81.1806,
    postal_code: '25919'
  },
  '25920': {
    country: 'US',
    city: 'Slab Fork',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.6871,
    longitude: -81.334,
    postal_code: '25920'
  },
  '25921': {
    country: 'US',
    city: 'Sophia',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7076,
    longitude: -81.2507,
    postal_code: '25921'
  },
  '25922': {
    country: 'US',
    city: 'Spanishburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.4605,
    longitude: -81.1128,
    postal_code: '25922'
  },
  '25927': {
    country: 'US',
    city: 'Stanaford',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.8159,
    longitude: -81.1523,
    postal_code: '25927'
  },
  '25928': {
    country: 'US',
    city: 'Stephenson',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.5798,
    longitude: -81.3339,
    postal_code: '25928'
  },
  '25932': {
    country: 'US',
    city: 'Surveyor',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.7565,
    longitude: -81.3092,
    postal_code: '25932'
  },
  '25936': {
    country: 'US',
    city: 'Thurmond',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.9554,
    longitude: -81.0714,
    postal_code: '25936'
  },
  '25938': {
    country: 'US',
    city: 'Victor',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1402,
    longitude: -81.0322,
    postal_code: '25938'
  },
  '25942': {
    country: 'US',
    city: 'Winona',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.0465,
    longitude: -80.9923,
    postal_code: '25942'
  },
  '25943': {
    country: 'US',
    city: 'Wyco',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wyoming',
    latitude: 37.599,
    longitude: -81.3429,
    postal_code: '25943'
  },
  '25951': {
    country: 'US',
    city: 'Hinton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Summers',
    latitude: 37.664,
    longitude: -80.8678,
    postal_code: '25951'
  },
  '25958': {
    country: 'US',
    city: 'Charmco',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 38.0023,
    longitude: -80.737,
    postal_code: '25958'
  },
  '25962': {
    country: 'US',
    city: 'Rainelle',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.9661,
    longitude: -80.7821,
    postal_code: '25962'
  },
  '25966': {
    country: 'US',
    city: 'Green Sulphur Springs',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Summers',
    latitude: 37.6485,
    longitude: -80.8774,
    postal_code: '25966'
  },
  '25969': {
    country: 'US',
    city: 'Jumping Branch',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Summers',
    latitude: 37.6188,
    longitude: -81.0018,
    postal_code: '25969'
  },
  '25971': {
    country: 'US',
    city: 'Lerona',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mercer',
    latitude: 37.4925,
    longitude: -80.9796,
    postal_code: '25971'
  },
  '25972': {
    country: 'US',
    city: 'Leslie',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 38.0446,
    longitude: -80.7229,
    postal_code: '25972'
  },
  '25976': {
    country: 'US',
    city: 'Meadow Bridge',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.878,
    longitude: -80.8521,
    postal_code: '25976'
  },
  '25977': {
    country: 'US',
    city: 'Meadow Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Summers',
    latitude: 37.8101,
    longitude: -80.9231,
    postal_code: '25977'
  },
  '25978': {
    country: 'US',
    city: 'Nimitz',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Summers',
    latitude: 37.6286,
    longitude: -80.9446,
    postal_code: '25978'
  },
  '25979': {
    country: 'US',
    city: 'Pipestem',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Summers',
    latitude: 37.5303,
    longitude: -80.9463,
    postal_code: '25979'
  },
  '25981': {
    country: 'US',
    city: 'Quinwood',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 38.0576,
    longitude: -80.7068,
    postal_code: '25981'
  },
  '25984': {
    country: 'US',
    city: 'Rupert',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Greenbrier',
    latitude: 37.9685,
    longitude: -80.6696,
    postal_code: '25984'
  },
  '25985': {
    country: 'US',
    city: 'Sandstone',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Summers',
    latitude: 37.7599,
    longitude: -80.8716,
    postal_code: '25985'
  },
  '25986': {
    country: 'US',
    city: 'Spring Dale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 37.8798,
    longitude: -80.8034,
    postal_code: '25986'
  },
  '25989': {
    country: 'US',
    city: 'White Oak',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Raleigh',
    latitude: 37.6929,
    longitude: -81.0729,
    postal_code: '25989'
  },
  '26003': {
    country: 'US',
    city: 'Wheeling',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ohio',
    latitude: 40.1027,
    longitude: -80.6476,
    postal_code: '26003'
  },
  '26030': {
    country: 'US',
    city: 'Beech Bottom',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Brooke',
    latitude: 40.2259,
    longitude: -80.6513,
    postal_code: '26030'
  },
  '26031': {
    country: 'US',
    city: 'Benwood',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marshall',
    latitude: 40.0181,
    longitude: -80.7342,
    postal_code: '26031'
  },
  '26032': {
    country: 'US',
    city: 'Bethany',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Brooke',
    latitude: 40.2107,
    longitude: -80.5503,
    postal_code: '26032'
  },
  '26033': {
    country: 'US',
    city: 'Cameron',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marshall',
    latitude: 39.8227,
    longitude: -80.5669,
    postal_code: '26033'
  },
  '26034': {
    country: 'US',
    city: 'Chester',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hancock',
    latitude: 40.5981,
    longitude: -80.5584,
    postal_code: '26034'
  },
  '26035': {
    country: 'US',
    city: 'Colliers',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Brooke',
    latitude: 40.3484,
    longitude: -80.5493,
    postal_code: '26035'
  },
  '26036': {
    country: 'US',
    city: 'Dallas',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marshall',
    latitude: 39.9783,
    longitude: -80.5508,
    postal_code: '26036'
  },
  '26037': {
    country: 'US',
    city: 'Follansbee',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Brooke',
    latitude: 40.3296,
    longitude: -80.585,
    postal_code: '26037'
  },
  '26038': {
    country: 'US',
    city: 'Glen Dale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marshall',
    latitude: 39.9597,
    longitude: -80.7323,
    postal_code: '26038'
  },
  '26039': {
    country: 'US',
    city: 'Glen Easton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marshall',
    latitude: 39.8531,
    longitude: -80.6661,
    postal_code: '26039'
  },
  '26040': {
    country: 'US',
    city: 'Mcmechen',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marshall',
    latitude: 39.9881,
    longitude: -80.7315,
    postal_code: '26040'
  },
  '26041': {
    country: 'US',
    city: 'Moundsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marshall',
    latitude: 39.9148,
    longitude: -80.731,
    postal_code: '26041'
  },
  '26047': {
    country: 'US',
    city: 'New Cumberland',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hancock',
    latitude: 40.5228,
    longitude: -80.5901,
    postal_code: '26047'
  },
  '26050': {
    country: 'US',
    city: 'Newell',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hancock',
    latitude: 40.614,
    longitude: -80.6011,
    postal_code: '26050'
  },
  '26055': {
    country: 'US',
    city: 'Proctor',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marshall',
    latitude: 39.7207,
    longitude: -80.7618,
    postal_code: '26055'
  },
  '26056': {
    country: 'US',
    city: 'New Manchester',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hancock',
    latitude: 40.5315,
    longitude: -80.5801,
    postal_code: '26056'
  },
  '26058': {
    country: 'US',
    city: 'Short Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Brooke',
    latitude: 40.1856,
    longitude: -80.6745,
    postal_code: '26058'
  },
  '26059': {
    country: 'US',
    city: 'Triadelphia',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ohio',
    latitude: 40.0699,
    longitude: -80.5953,
    postal_code: '26059'
  },
  '26060': {
    country: 'US',
    city: 'Valley Grove',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ohio',
    latitude: 40.0946,
    longitude: -80.5551,
    postal_code: '26060'
  },
  '26062': {
    country: 'US',
    city: 'Weirton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hancock',
    latitude: 40.4137,
    longitude: -80.5683,
    postal_code: '26062'
  },
  '26070': {
    country: 'US',
    city: 'Wellsburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Brooke',
    latitude: 40.2548,
    longitude: -80.5961,
    postal_code: '26070'
  },
  '26074': {
    country: 'US',
    city: 'West Liberty',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ohio',
    latitude: 40.1698,
    longitude: -80.5958,
    postal_code: '26074'
  },
  '26075': {
    country: 'US',
    city: 'Windsor Heights',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Brooke',
    latitude: 40.1927,
    longitude: -80.6659,
    postal_code: '26075'
  },
  '26101': {
    country: 'US',
    city: 'Parkersburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.2644,
    longitude: -81.5354,
    postal_code: '26101'
  },
  '26102': {
    country: 'US',
    city: 'Parkersburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.2183,
    longitude: -81.4979,
    postal_code: '26102'
  },
  '26103': {
    country: 'US',
    city: 'Parkersburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.2364,
    longitude: -81.5405,
    postal_code: '26103'
  },
  '26104': {
    country: 'US',
    city: 'Parkersburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.2804,
    longitude: -81.4936,
    postal_code: '26104'
  },
  '26105': {
    country: 'US',
    city: 'Vienna',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.3251,
    longitude: -81.5411,
    postal_code: '26105'
  },
  '26106': {
    country: 'US',
    city: 'Parkersburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.2183,
    longitude: -81.4979,
    postal_code: '26106'
  },
  '26120': {
    country: 'US',
    city: 'Mineral Wells',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.2183,
    longitude: -81.4979,
    postal_code: '26120'
  },
  '26121': {
    country: 'US',
    city: 'Mineral Wells',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.2183,
    longitude: -81.4979,
    postal_code: '26121'
  },
  '26133': {
    country: 'US',
    city: 'Belleville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.1314,
    longitude: -81.6923,
    postal_code: '26133'
  },
  '26134': {
    country: 'US',
    city: 'Belmont',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pleasants',
    latitude: 39.3795,
    longitude: -81.2584,
    postal_code: '26134'
  },
  '26136': {
    country: 'US',
    city: 'Big Bend',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Calhoun',
    latitude: 38.9719,
    longitude: -81.136,
    postal_code: '26136'
  },
  '26137': {
    country: 'US',
    city: 'Big Springs',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Calhoun',
    latitude: 38.9854,
    longitude: -81.0721,
    postal_code: '26137'
  },
  '26138': {
    country: 'US',
    city: 'Brohard',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wirt',
    latitude: 39.0338,
    longitude: -81.1775,
    postal_code: '26138'
  },
  '26141': {
    country: 'US',
    city: 'Creston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wirt',
    latitude: 38.9378,
    longitude: -81.2369,
    postal_code: '26141'
  },
  '26142': {
    country: 'US',
    city: 'Davisville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.2126,
    longitude: -81.4799,
    postal_code: '26142'
  },
  '26143': {
    country: 'US',
    city: 'Elizabeth',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wirt',
    latitude: 39.0566,
    longitude: -81.3989,
    postal_code: '26143'
  },
  '26146': {
    country: 'US',
    city: 'Friendly',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tyler',
    latitude: 39.4961,
    longitude: -81.0572,
    postal_code: '26146'
  },
  '26147': {
    country: 'US',
    city: 'Grantsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Calhoun',
    latitude: 38.9324,
    longitude: -81.0763,
    postal_code: '26147'
  },
  '26148': {
    country: 'US',
    city: 'Macfarlan',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ritchie',
    latitude: 39.0806,
    longitude: -81.1776,
    postal_code: '26148'
  },
  '26149': {
    country: 'US',
    city: 'Middlebourne',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tyler',
    latitude: 39.4942,
    longitude: -80.9076,
    postal_code: '26149'
  },
  '26150': {
    country: 'US',
    city: 'Mineral Wells',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.1897,
    longitude: -81.5139,
    postal_code: '26150'
  },
  '26151': {
    country: 'US',
    city: 'Mount Zion',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Calhoun',
    latitude: 38.8286,
    longitude: -81.1322,
    postal_code: '26151'
  },
  '26152': {
    country: 'US',
    city: 'Munday',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wirt',
    latitude: 39.0054,
    longitude: -81.2062,
    postal_code: '26152'
  },
  '26155': {
    country: 'US',
    city: 'New Martinsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.6588,
    longitude: -80.8565,
    postal_code: '26155'
  },
  '26159': {
    country: 'US',
    city: 'Paden City',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.6044,
    longitude: -80.9265,
    postal_code: '26159'
  },
  '26160': {
    country: 'US',
    city: 'Palestine',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wirt',
    latitude: 38.9741,
    longitude: -81.428,
    postal_code: '26160'
  },
  '26161': {
    country: 'US',
    city: 'Petroleum',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ritchie',
    latitude: 39.1741,
    longitude: -81.2406,
    postal_code: '26161'
  },
  '26162': {
    country: 'US',
    city: 'Porters Falls',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.5798,
    longitude: -80.7762,
    postal_code: '26162'
  },
  '26164': {
    country: 'US',
    city: 'Ravenswood',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Jackson',
    latitude: 38.9468,
    longitude: -81.7524,
    postal_code: '26164'
  },
  '26167': {
    country: 'US',
    city: 'Reader',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.5836,
    longitude: -80.7516,
    postal_code: '26167'
  },
  '26169': {
    country: 'US',
    city: 'Rockport',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.0798,
    longitude: -81.5713,
    postal_code: '26169'
  },
  '26170': {
    country: 'US',
    city: 'Saint Marys',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pleasants',
    latitude: 39.386,
    longitude: -81.1725,
    postal_code: '26170'
  },
  '26175': {
    country: 'US',
    city: 'Sistersville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tyler',
    latitude: 39.5584,
    longitude: -80.9818,
    postal_code: '26175'
  },
  '26178': {
    country: 'US',
    city: 'Smithville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ritchie',
    latitude: 39.0671,
    longitude: -81.062,
    postal_code: '26178'
  },
  '26180': {
    country: 'US',
    city: 'Walker',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.1821,
    longitude: -81.3866,
    postal_code: '26180'
  },
  '26181': {
    country: 'US',
    city: 'Washington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.2068,
    longitude: -81.6577,
    postal_code: '26181'
  },
  '26184': {
    country: 'US',
    city: 'Waverly',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.3157,
    longitude: -81.3819,
    postal_code: '26184'
  },
  '26187': {
    country: 'US',
    city: 'Williamstown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wood',
    latitude: 39.3824,
    longitude: -81.4566,
    postal_code: '26187'
  },
  '26201': {
    country: 'US',
    city: 'Buckhannon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Upshur',
    latitude: 38.9755,
    longitude: -80.2407,
    postal_code: '26201'
  },
  '26202': {
    country: 'US',
    city: 'Fenwick',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.2447,
    longitude: -80.635,
    postal_code: '26202'
  },
  '26203': {
    country: 'US',
    city: 'Erbacon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Webster',
    latitude: 38.5419,
    longitude: -80.5742,
    postal_code: '26203'
  },
  '26205': {
    country: 'US',
    city: 'Craigsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.3254,
    longitude: -80.6444,
    postal_code: '26205'
  },
  '26206': {
    country: 'US',
    city: 'Cowen',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Webster',
    latitude: 38.4133,
    longitude: -80.5358,
    postal_code: '26206'
  },
  '26208': {
    country: 'US',
    city: 'Camden On Gauley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Webster',
    latitude: 38.3871,
    longitude: -80.5907,
    postal_code: '26208'
  },
  '26209': {
    country: 'US',
    city: 'Snowshoe',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.4133,
    longitude: -80.0068,
    postal_code: '26209'
  },
  '26210': {
    country: 'US',
    city: 'Adrian',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Upshur',
    latitude: 38.8993,
    longitude: -80.2269,
    postal_code: '26210'
  },
  '26215': {
    country: 'US',
    city: 'Cleveland',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Upshur',
    latitude: 38.7133,
    longitude: -80.388,
    postal_code: '26215'
  },
  '26217': {
    country: 'US',
    city: 'Diana',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Webster',
    latitude: 38.5755,
    longitude: -80.432,
    postal_code: '26217'
  },
  '26218': {
    country: 'US',
    city: 'French Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Upshur',
    latitude: 38.8993,
    longitude: -80.2269,
    postal_code: '26218'
  },
  '26219': {
    country: 'US',
    city: 'Frenchton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Upshur',
    latitude: 38.8993,
    longitude: -80.2269,
    postal_code: '26219'
  },
  '26222': {
    country: 'US',
    city: 'Hacker Valley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Webster',
    latitude: 38.6526,
    longitude: -80.3837,
    postal_code: '26222'
  },
  '26224': {
    country: 'US',
    city: 'Helvetia',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.7713,
    longitude: -80.1382,
    postal_code: '26224'
  },
  '26228': {
    country: 'US',
    city: 'Kanawha Head',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Upshur',
    latitude: 38.7618,
    longitude: -80.3727,
    postal_code: '26228'
  },
  '26229': {
    country: 'US',
    city: 'Lorentz',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Upshur',
    latitude: 39.0109,
    longitude: -80.3023,
    postal_code: '26229'
  },
  '26230': {
    country: 'US',
    city: 'Pickens',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.6551,
    longitude: -80.2117,
    postal_code: '26230'
  },
  '26234': {
    country: 'US',
    city: 'Rock Cave',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Upshur',
    latitude: 38.7811,
    longitude: -80.3163,
    postal_code: '26234'
  },
  '26236': {
    country: 'US',
    city: 'Selbyville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Upshur',
    latitude: 38.754,
    longitude: -80.2365,
    postal_code: '26236'
  },
  '26237': {
    country: 'US',
    city: 'Tallmansville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Upshur',
    latitude: 38.8564,
    longitude: -80.158,
    postal_code: '26237'
  },
  '26238': {
    country: 'US',
    city: 'Volga',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Barbour',
    latitude: 39.0784,
    longitude: -80.1354,
    postal_code: '26238'
  },
  '26241': {
    country: 'US',
    city: 'Elkins',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.9253,
    longitude: -79.8471,
    postal_code: '26241'
  },
  '26250': {
    country: 'US',
    city: 'Belington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Barbour',
    latitude: 39.0244,
    longitude: -79.9495,
    postal_code: '26250'
  },
  '26253': {
    country: 'US',
    city: 'Beverly',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.828,
    longitude: -79.8651,
    postal_code: '26253'
  },
  '26254': {
    country: 'US',
    city: 'Bowden',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.9087,
    longitude: -79.7098,
    postal_code: '26254'
  },
  '26257': {
    country: 'US',
    city: 'Coalton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.9144,
    longitude: -79.9767,
    postal_code: '26257'
  },
  '26259': {
    country: 'US',
    city: 'Dailey',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.7987,
    longitude: -79.8962,
    postal_code: '26259'
  },
  '26260': {
    country: 'US',
    city: 'Davis',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tucker',
    latitude: 39.0882,
    longitude: -79.4563,
    postal_code: '26260'
  },
  '26261': {
    country: 'US',
    city: 'Richwood',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.223,
    longitude: -80.5445,
    postal_code: '26261'
  },
  '26263': {
    country: 'US',
    city: 'Dryfork',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.9489,
    longitude: -79.4376,
    postal_code: '26263'
  },
  '26264': {
    country: 'US',
    city: 'Durbin',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.5457,
    longitude: -79.8251,
    postal_code: '26264'
  },
  '26266': {
    country: 'US',
    city: 'Upperglade',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Webster',
    latitude: 38.4185,
    longitude: -80.4965,
    postal_code: '26266'
  },
  '26267': {
    country: 'US',
    city: 'Ellamore',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.9243,
    longitude: -80.0904,
    postal_code: '26267'
  },
  '26268': {
    country: 'US',
    city: 'Glady',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.7534,
    longitude: -79.8147,
    postal_code: '26268'
  },
  '26269': {
    country: 'US',
    city: 'Hambleton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tucker',
    latitude: 39.0821,
    longitude: -79.6453,
    postal_code: '26269'
  },
  '26270': {
    country: 'US',
    city: 'Harman',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.9234,
    longitude: -79.5246,
    postal_code: '26270'
  },
  '26271': {
    country: 'US',
    city: 'Hendricks',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tucker',
    latitude: 39.0748,
    longitude: -79.6303,
    postal_code: '26271'
  },
  '26273': {
    country: 'US',
    city: 'Huttonsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.6784,
    longitude: -79.9775,
    postal_code: '26273'
  },
  '26275': {
    country: 'US',
    city: 'Junior',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Barbour',
    latitude: 38.9784,
    longitude: -79.9494,
    postal_code: '26275'
  },
  '26276': {
    country: 'US',
    city: 'Kerens',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 39.0299,
    longitude: -79.7754,
    postal_code: '26276'
  },
  '26278': {
    country: 'US',
    city: 'Mabie',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.8765,
    longitude: -79.9831,
    postal_code: '26278'
  },
  '26280': {
    country: 'US',
    city: 'Mill Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.7325,
    longitude: -79.9786,
    postal_code: '26280'
  },
  '26282': {
    country: 'US',
    city: 'Monterville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.5517,
    longitude: -80.1224,
    postal_code: '26282'
  },
  '26283': {
    country: 'US',
    city: 'Montrose',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 39.0964,
    longitude: -79.7896,
    postal_code: '26283'
  },
  '26285': {
    country: 'US',
    city: 'Norton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.9318,
    longitude: -79.9656,
    postal_code: '26285'
  },
  '26287': {
    country: 'US',
    city: 'Parsons',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tucker',
    latitude: 39.096,
    longitude: -79.6787,
    postal_code: '26287'
  },
  '26288': {
    country: 'US',
    city: 'Webster Springs',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Webster',
    latitude: 38.4832,
    longitude: -80.4476,
    postal_code: '26288'
  },
  '26289': {
    country: 'US',
    city: 'Red Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tucker',
    latitude: 39.1179,
    longitude: -79.5637,
    postal_code: '26289'
  },
  '26291': {
    country: 'US',
    city: 'Slatyfork',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pocahontas',
    latitude: 38.388,
    longitude: -79.9906,
    postal_code: '26291'
  },
  '26292': {
    country: 'US',
    city: 'Thomas',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tucker',
    latitude: 39.1505,
    longitude: -79.503,
    postal_code: '26292'
  },
  '26293': {
    country: 'US',
    city: 'Valley Bend',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.7718,
    longitude: -79.9253,
    postal_code: '26293'
  },
  '26294': {
    country: 'US',
    city: 'Valley Head',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.5315,
    longitude: -80.0343,
    postal_code: '26294'
  },
  '26296': {
    country: 'US',
    city: 'Whitmer',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Randolph',
    latitude: 38.7516,
    longitude: -79.5819,
    postal_code: '26296'
  },
  '26298': {
    country: 'US',
    city: 'Bergoo',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Webster',
    latitude: 38.4865,
    longitude: -80.3006,
    postal_code: '26298'
  },
  '26301': {
    country: 'US',
    city: 'Clarksburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.2874,
    longitude: -80.3419,
    postal_code: '26301'
  },
  '26302': {
    country: 'US',
    city: 'Clarksburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.2677,
    longitude: -80.3077,
    postal_code: '26302'
  },
  '26306': {
    country: 'US',
    city: 'Clarksburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.2852,
    longitude: -80.3853,
    postal_code: '26306'
  },
  '26320': {
    country: 'US',
    city: 'Alma',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tyler',
    latitude: 39.4506,
    longitude: -80.8701,
    postal_code: '26320'
  },
  '26321': {
    country: 'US',
    city: 'Alum Bridge',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lewis',
    latitude: 39.0425,
    longitude: -80.6882,
    postal_code: '26321'
  },
  '26323': {
    country: 'US',
    city: 'Anmoore',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.2587,
    longitude: -80.2875,
    postal_code: '26323'
  },
  '26325': {
    country: 'US',
    city: 'Auburn',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ritchie',
    latitude: 39.0864,
    longitude: -80.8837,
    postal_code: '26325'
  },
  '26327': {
    country: 'US',
    city: 'Berea',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ritchie',
    latitude: 39.1365,
    longitude: -80.9337,
    postal_code: '26327'
  },
  '26330': {
    country: 'US',
    city: 'Bridgeport',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.2954,
    longitude: -80.2427,
    postal_code: '26330'
  },
  '26335': {
    country: 'US',
    city: 'Burnsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Braxton',
    latitude: 38.7775,
    longitude: -80.657,
    postal_code: '26335'
  },
  '26337': {
    country: 'US',
    city: 'Cairo',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ritchie',
    latitude: 39.2325,
    longitude: -81.1554,
    postal_code: '26337'
  },
  '26338': {
    country: 'US',
    city: 'Camden',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lewis',
    latitude: 39.0893,
    longitude: -80.5915,
    postal_code: '26338'
  },
  '26339': {
    country: 'US',
    city: 'Center Point',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Doddridge',
    latitude: 39.4125,
    longitude: -80.6352,
    postal_code: '26339'
  },
  '26342': {
    country: 'US',
    city: 'Coxs Mills',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Gilmer',
    latitude: 39.0301,
    longitude: -80.8498,
    postal_code: '26342'
  },
  '26343': {
    country: 'US',
    city: 'Crawford',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lewis',
    latitude: 38.872,
    longitude: -80.4368,
    postal_code: '26343'
  },
  '26346': {
    country: 'US',
    city: 'Ellenboro',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ritchie',
    latitude: 39.2969,
    longitude: -81.0621,
    postal_code: '26346'
  },
  '26347': {
    country: 'US',
    city: 'Flemington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Taylor',
    latitude: 39.2665,
    longitude: -80.132,
    postal_code: '26347'
  },
  '26348': {
    country: 'US',
    city: 'Folsom',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.4693,
    longitude: -80.5258,
    postal_code: '26348'
  },
  '26349': {
    country: 'US',
    city: 'Galloway',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Barbour',
    latitude: 39.2318,
    longitude: -80.122,
    postal_code: '26349'
  },
  '26351': {
    country: 'US',
    city: 'Glenville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Gilmer',
    latitude: 38.9409,
    longitude: -80.8313,
    postal_code: '26351'
  },
  '26354': {
    country: 'US',
    city: 'Grafton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Taylor',
    latitude: 39.3414,
    longitude: -80.0285,
    postal_code: '26354'
  },
  '26361': {
    country: 'US',
    city: 'Gypsy',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.3687,
    longitude: -80.3179,
    postal_code: '26361'
  },
  '26362': {
    country: 'US',
    city: 'Harrisville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ritchie',
    latitude: 39.1453,
    longitude: -81.0341,
    postal_code: '26362'
  },
  '26366': {
    country: 'US',
    city: 'Haywood',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.3812,
    longitude: -80.3359,
    postal_code: '26366'
  },
  '26369': {
    country: 'US',
    city: 'Hepzibah',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.3312,
    longitude: -80.3325,
    postal_code: '26369'
  },
  '26372': {
    country: 'US',
    city: 'Horner',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lewis',
    latitude: 38.9726,
    longitude: -80.3656,
    postal_code: '26372'
  },
  '26374': {
    country: 'US',
    city: 'Independence',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.4464,
    longitude: -79.8822,
    postal_code: '26374'
  },
  '26376': {
    country: 'US',
    city: 'Ireland',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lewis',
    latitude: 38.7763,
    longitude: -80.4567,
    postal_code: '26376'
  },
  '26377': {
    country: 'US',
    city: 'Jacksonburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.5416,
    longitude: -80.6437,
    postal_code: '26377'
  },
  '26378': {
    country: 'US',
    city: 'Jane Lew',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lewis',
    latitude: 39.11,
    longitude: -80.4311,
    postal_code: '26378'
  },
  '26384': {
    country: 'US',
    city: 'Linn',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Gilmer',
    latitude: 38.9573,
    longitude: -80.73,
    postal_code: '26384'
  },
  '26385': {
    country: 'US',
    city: 'Lost Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.1725,
    longitude: -80.3777,
    postal_code: '26385'
  },
  '26386': {
    country: 'US',
    city: 'Lumberport',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.3781,
    longitude: -80.3676,
    postal_code: '26386'
  },
  '26404': {
    country: 'US',
    city: 'Meadowbrook',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.3518,
    longitude: -80.3187,
    postal_code: '26404'
  },
  '26405': {
    country: 'US',
    city: 'Moatsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Barbour',
    latitude: 39.2234,
    longitude: -79.9017,
    postal_code: '26405'
  },
  '26408': {
    country: 'US',
    city: 'Mount Clare',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.2107,
    longitude: -80.2899,
    postal_code: '26408'
  },
  '26410': {
    country: 'US',
    city: 'Newburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.4027,
    longitude: -79.828,
    postal_code: '26410'
  },
  '26411': {
    country: 'US',
    city: 'New Milton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Doddridge',
    latitude: 39.1899,
    longitude: -80.7076,
    postal_code: '26411'
  },
  '26412': {
    country: 'US',
    city: 'Orlando',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lewis',
    latitude: 38.8712,
    longitude: -80.5937,
    postal_code: '26412'
  },
  '26415': {
    country: 'US',
    city: 'Pennsboro',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ritchie',
    latitude: 39.2791,
    longitude: -81.0019,
    postal_code: '26415'
  },
  '26416': {
    country: 'US',
    city: 'Philippi',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Barbour',
    latitude: 39.1613,
    longitude: -80.0492,
    postal_code: '26416'
  },
  '26419': {
    country: 'US',
    city: 'Pine Grove',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.5755,
    longitude: -80.6667,
    postal_code: '26419'
  },
  '26421': {
    country: 'US',
    city: 'Pullman',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Ritchie',
    latitude: 39.19,
    longitude: -80.9347,
    postal_code: '26421'
  },
  '26422': {
    country: 'US',
    city: 'Reynoldsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.2884,
    longitude: -80.4348,
    postal_code: '26422'
  },
  '26424': {
    country: 'US',
    city: 'Rosemont',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Taylor',
    latitude: 39.2687,
    longitude: -80.1629,
    postal_code: '26424'
  },
  '26425': {
    country: 'US',
    city: 'Rowlesburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.313,
    longitude: -79.7049,
    postal_code: '26425'
  },
  '26426': {
    country: 'US',
    city: 'Salem',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.2829,
    longitude: -80.559,
    postal_code: '26426'
  },
  '26430': {
    country: 'US',
    city: 'Sand Fork',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Gilmer',
    latitude: 38.914,
    longitude: -80.7498,
    postal_code: '26430'
  },
  '26431': {
    country: 'US',
    city: 'Shinnston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.3888,
    longitude: -80.2834,
    postal_code: '26431'
  },
  '26434': {
    country: 'US',
    city: 'Shirley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Tyler',
    latitude: 39.3981,
    longitude: -80.7651,
    postal_code: '26434'
  },
  '26435': {
    country: 'US',
    city: 'Simpson',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Taylor',
    latitude: 39.2668,
    longitude: -80.0934,
    postal_code: '26435'
  },
  '26436': {
    country: 'US',
    city: 'Smithburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Doddridge',
    latitude: 39.2893,
    longitude: -80.7343,
    postal_code: '26436'
  },
  '26437': {
    country: 'US',
    city: 'Smithfield',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.5134,
    longitude: -80.5582,
    postal_code: '26437'
  },
  '26438': {
    country: 'US',
    city: 'Spelter',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.3465,
    longitude: -80.3206,
    postal_code: '26438'
  },
  '26440': {
    country: 'US',
    city: 'Thornton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Taylor',
    latitude: 39.3329,
    longitude: -79.9096,
    postal_code: '26440'
  },
  '26443': {
    country: 'US',
    city: 'Troy',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Gilmer',
    latitude: 39.0839,
    longitude: -80.7549,
    postal_code: '26443'
  },
  '26444': {
    country: 'US',
    city: 'Tunnelton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.3625,
    longitude: -79.7478,
    postal_code: '26444'
  },
  '26447': {
    country: 'US',
    city: 'Walkersville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lewis',
    latitude: 38.8507,
    longitude: -80.4802,
    postal_code: '26447'
  },
  '26448': {
    country: 'US',
    city: 'Wallace',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.4117,
    longitude: -80.4865,
    postal_code: '26448'
  },
  '26451': {
    country: 'US',
    city: 'West Milford',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.1996,
    longitude: -80.4026,
    postal_code: '26451'
  },
  '26452': {
    country: 'US',
    city: 'Weston',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Lewis',
    latitude: 39.0411,
    longitude: -80.4737,
    postal_code: '26452'
  },
  '26456': {
    country: 'US',
    city: 'West Union',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Doddridge',
    latitude: 39.2762,
    longitude: -80.7911,
    postal_code: '26456'
  },
  '26463': {
    country: 'US',
    city: 'Wyatt',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.4351,
    longitude: -80.3534,
    postal_code: '26463'
  },
  '26501': {
    country: 'US',
    city: 'Morgantown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6099,
    longitude: -79.9831,
    postal_code: '26501'
  },
  '26502': {
    country: 'US',
    city: 'Morgantown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6253,
    longitude: -79.9672,
    postal_code: '26502'
  },
  '26504': {
    country: 'US',
    city: 'Morgantown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.5785,
    longitude: -80.093,
    postal_code: '26504'
  },
  '26505': {
    country: 'US',
    city: 'Morgantown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6505,
    longitude: -79.944,
    postal_code: '26505'
  },
  '26506': {
    country: 'US',
    city: 'Morgantown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6453,
    longitude: -79.9627,
    postal_code: '26506'
  },
  '26507': {
    country: 'US',
    city: 'Morgantown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6808,
    longitude: -79.8365,
    postal_code: '26507'
  },
  '26508': {
    country: 'US',
    city: 'Morgantown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.5953,
    longitude: -79.9229,
    postal_code: '26508'
  },
  '26519': {
    country: 'US',
    city: 'Albright',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.5701,
    longitude: -79.6358,
    postal_code: '26519'
  },
  '26520': {
    country: 'US',
    city: 'Arthurdale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.4951,
    longitude: -79.8151,
    postal_code: '26520'
  },
  '26521': {
    country: 'US',
    city: 'Blacksville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.7142,
    longitude: -80.2265,
    postal_code: '26521'
  },
  '26524': {
    country: 'US',
    city: 'Bretz',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.5423,
    longitude: -79.8006,
    postal_code: '26524'
  },
  '26525': {
    country: 'US',
    city: 'Bruceton Mills',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.6454,
    longitude: -79.6159,
    postal_code: '26525'
  },
  '26527': {
    country: 'US',
    city: 'Cassville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6656,
    longitude: -80.0637,
    postal_code: '26527'
  },
  '26531': {
    country: 'US',
    city: 'Dellslow',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6053,
    longitude: -79.8958,
    postal_code: '26531'
  },
  '26534': {
    country: 'US',
    city: 'Granville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6459,
    longitude: -79.9873,
    postal_code: '26534'
  },
  '26537': {
    country: 'US',
    city: 'Kingwood',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.4819,
    longitude: -79.7063,
    postal_code: '26537'
  },
  '26541': {
    country: 'US',
    city: 'Maidsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6884,
    longitude: -79.9801,
    postal_code: '26541'
  },
  '26542': {
    country: 'US',
    city: 'Masontown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.5731,
    longitude: -79.7906,
    postal_code: '26542'
  },
  '26543': {
    country: 'US',
    city: 'Osage',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6587,
    longitude: -80.0076,
    postal_code: '26543'
  },
  '26544': {
    country: 'US',
    city: 'Pentress',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.5785,
    longitude: -80.093,
    postal_code: '26544'
  },
  '26546': {
    country: 'US',
    city: 'Pursglove',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6746,
    longitude: -80.035,
    postal_code: '26546'
  },
  '26547': {
    country: 'US',
    city: 'Reedsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.5127,
    longitude: -79.812,
    postal_code: '26547'
  },
  '26554': {
    country: 'US',
    city: 'Fairmont',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.4941,
    longitude: -80.2213,
    postal_code: '26554'
  },
  '26555': {
    country: 'US',
    city: 'Fairmont',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.5143,
    longitude: -80.2185,
    postal_code: '26555'
  },
  '26559': {
    country: 'US',
    city: 'Barrackville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.5052,
    longitude: -80.1725,
    postal_code: '26559'
  },
  '26560': {
    country: 'US',
    city: 'Baxter',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.544,
    longitude: -80.1423,
    postal_code: '26560'
  },
  '26561': {
    country: 'US',
    city: 'Big Run',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.5845,
    longitude: -80.5776,
    postal_code: '26561'
  },
  '26562': {
    country: 'US',
    city: 'Burton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.6511,
    longitude: -80.4161,
    postal_code: '26562'
  },
  '26563': {
    country: 'US',
    city: 'Carolina',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.4782,
    longitude: -80.272,
    postal_code: '26563'
  },
  '26566': {
    country: 'US',
    city: 'Colfax',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.4348,
    longitude: -80.1317,
    postal_code: '26566'
  },
  '26568': {
    country: 'US',
    city: 'Enterprise',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Harrison',
    latitude: 39.4209,
    longitude: -80.2779,
    postal_code: '26568'
  },
  '26570': {
    country: 'US',
    city: 'Fairview',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.6103,
    longitude: -80.2529,
    postal_code: '26570'
  },
  '26571': {
    country: 'US',
    city: 'Farmington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.5029,
    longitude: -80.2566,
    postal_code: '26571'
  },
  '26572': {
    country: 'US',
    city: 'Four States',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.4798,
    longitude: -80.3098,
    postal_code: '26572'
  },
  '26574': {
    country: 'US',
    city: 'Grant Town',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.5587,
    longitude: -80.1805,
    postal_code: '26574'
  },
  '26575': {
    country: 'US',
    city: 'Hundred',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.6917,
    longitude: -80.4746,
    postal_code: '26575'
  },
  '26576': {
    country: 'US',
    city: 'Idamay',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.4877,
    longitude: -80.2666,
    postal_code: '26576'
  },
  '26578': {
    country: 'US',
    city: 'Kingmont',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.4466,
    longitude: -80.1762,
    postal_code: '26578'
  },
  '26581': {
    country: 'US',
    city: 'Littleton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Wetzel',
    latitude: 39.6592,
    longitude: -80.5695,
    postal_code: '26581'
  },
  '26582': {
    country: 'US',
    city: 'Mannington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.522,
    longitude: -80.3552,
    postal_code: '26582'
  },
  '26585': {
    country: 'US',
    city: 'Metz',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.6078,
    longitude: -80.4168,
    postal_code: '26585'
  },
  '26586': {
    country: 'US',
    city: 'Montana Mines',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.5191,
    longitude: -80.0897,
    postal_code: '26586'
  },
  '26587': {
    country: 'US',
    city: 'Rachel',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.5225,
    longitude: -80.2957,
    postal_code: '26587'
  },
  '26588': {
    country: 'US',
    city: 'Rivesville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.5568,
    longitude: -80.1417,
    postal_code: '26588'
  },
  '26590': {
    country: 'US',
    city: 'Wana',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Monongalia',
    latitude: 39.6735,
    longitude: -80.3183,
    postal_code: '26590'
  },
  '26591': {
    country: 'US',
    city: 'Worthington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Marion',
    latitude: 39.4522,
    longitude: -80.2905,
    postal_code: '26591'
  },
  '26601': {
    country: 'US',
    city: 'Sutton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Braxton',
    latitude: 38.6541,
    longitude: -80.676,
    postal_code: '26601'
  },
  '26610': {
    country: 'US',
    city: 'Birch River',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.4914,
    longitude: -80.7459,
    postal_code: '26610'
  },
  '26611': {
    country: 'US',
    city: 'Cedarville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Gilmer',
    latitude: 38.8451,
    longitude: -80.8323,
    postal_code: '26611'
  },
  '26615': {
    country: 'US',
    city: 'Copen',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Braxton',
    latitude: 38.8376,
    longitude: -80.7295,
    postal_code: '26615'
  },
  '26617': {
    country: 'US',
    city: 'Dille',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Clay',
    latitude: 38.489,
    longitude: -80.8257,
    postal_code: '26617'
  },
  '26619': {
    country: 'US',
    city: 'Exchange',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Braxton',
    latitude: 38.7358,
    longitude: -80.7324,
    postal_code: '26619'
  },
  '26621': {
    country: 'US',
    city: 'Flatwoods',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Braxton',
    latitude: 38.7232,
    longitude: -80.6498,
    postal_code: '26621'
  },
  '26623': {
    country: 'US',
    city: 'Frametown',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Braxton',
    latitude: 38.6305,
    longitude: -80.8589,
    postal_code: '26623'
  },
  '26624': {
    country: 'US',
    city: 'Gassaway',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Braxton',
    latitude: 38.6862,
    longitude: -80.7863,
    postal_code: '26624'
  },
  '26627': {
    country: 'US',
    city: 'Heaters',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Braxton',
    latitude: 38.7621,
    longitude: -80.6406,
    postal_code: '26627'
  },
  '26629': {
    country: 'US',
    city: 'Little Birch',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Braxton',
    latitude: 38.579,
    longitude: -80.7068,
    postal_code: '26629'
  },
  '26631': {
    country: 'US',
    city: 'Napier',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Braxton',
    latitude: 38.7909,
    longitude: -80.5887,
    postal_code: '26631'
  },
  '26636': {
    country: 'US',
    city: 'Rosedale',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Gilmer',
    latitude: 38.7393,
    longitude: -80.9429,
    postal_code: '26636'
  },
  '26638': {
    country: 'US',
    city: 'Shock',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Gilmer',
    latitude: 38.7617,
    longitude: -81.0045,
    postal_code: '26638'
  },
  '26651': {
    country: 'US',
    city: 'Summersville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.3009,
    longitude: -80.8353,
    postal_code: '26651'
  },
  '26656': {
    country: 'US',
    city: 'Belva',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.2491,
    longitude: -81.1566,
    postal_code: '26656'
  },
  '26660': {
    country: 'US',
    city: 'Calvin',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.3368,
    longitude: -80.7145,
    postal_code: '26660'
  },
  '26662': {
    country: 'US',
    city: 'Canvas',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.2624,
    longitude: -80.7446,
    postal_code: '26662'
  },
  '26667': {
    country: 'US',
    city: 'Drennen',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.3184,
    longitude: -80.8342,
    postal_code: '26667'
  },
  '26671': {
    country: 'US',
    city: 'Gilboa',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.3184,
    longitude: -80.8342,
    postal_code: '26671'
  },
  '26675': {
    country: 'US',
    city: 'Keslers Cross Lanes',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.2354,
    longitude: -80.9357,
    postal_code: '26675'
  },
  '26676': {
    country: 'US',
    city: 'Leivasy',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.1558,
    longitude: -80.6827,
    postal_code: '26676'
  },
  '26678': {
    country: 'US',
    city: 'Mount Lookout',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.1821,
    longitude: -80.9108,
    postal_code: '26678'
  },
  '26679': {
    country: 'US',
    city: 'Mount Nebo',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.1653,
    longitude: -80.7976,
    postal_code: '26679'
  },
  '26680': {
    country: 'US',
    city: 'Nallen',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Fayette',
    latitude: 38.1107,
    longitude: -80.877,
    postal_code: '26680'
  },
  '26681': {
    country: 'US',
    city: 'Nettie',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.209,
    longitude: -80.6953,
    postal_code: '26681'
  },
  '26684': {
    country: 'US',
    city: 'Pool',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.1637,
    longitude: -80.8643,
    postal_code: '26684'
  },
  '26690': {
    country: 'US',
    city: 'Swiss',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.2255,
    longitude: -81.1273,
    postal_code: '26690'
  },
  '26691': {
    country: 'US',
    city: 'Tioga',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Nicholas',
    latitude: 38.4045,
    longitude: -80.6667,
    postal_code: '26691'
  },
  '26704': {
    country: 'US',
    city: 'Augusta',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.2998,
    longitude: -78.5931,
    postal_code: '26704'
  },
  '26705': {
    country: 'US',
    city: 'Aurora',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.3181,
    longitude: -79.5764,
    postal_code: '26705'
  },
  '26707': {
    country: 'US',
    city: 'Bayard',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Grant',
    latitude: 39.2729,
    longitude: -79.3645,
    postal_code: '26707'
  },
  '26710': {
    country: 'US',
    city: 'Burlington',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mineral',
    latitude: 39.3376,
    longitude: -78.9181,
    postal_code: '26710'
  },
  '26711': {
    country: 'US',
    city: 'Capon Bridge',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.2802,
    longitude: -78.4644,
    postal_code: '26711'
  },
  '26714': {
    country: 'US',
    city: 'Delray',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.1945,
    longitude: -78.6042,
    postal_code: '26714'
  },
  '26716': {
    country: 'US',
    city: 'Eglon',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.2925,
    longitude: -79.5109,
    postal_code: '26716'
  },
  '26717': {
    country: 'US',
    city: 'Elk Garden',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mineral',
    latitude: 39.3631,
    longitude: -79.1539,
    postal_code: '26717'
  },
  '26719': {
    country: 'US',
    city: 'Fort Ashby',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mineral',
    latitude: 39.497,
    longitude: -78.7743,
    postal_code: '26719'
  },
  '26720': {
    country: 'US',
    city: 'Gormania',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Grant',
    latitude: 39.2803,
    longitude: -79.3296,
    postal_code: '26720'
  },
  '26722': {
    country: 'US',
    city: 'Green Spring',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.5029,
    longitude: -78.6272,
    postal_code: '26722'
  },
  '26726': {
    country: 'US',
    city: 'Keyser',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mineral',
    latitude: 39.4527,
    longitude: -78.8926,
    postal_code: '26726'
  },
  '26731': {
    country: 'US',
    city: 'Lahmansville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Grant',
    latitude: 39.1642,
    longitude: -79.0433,
    postal_code: '26731'
  },
  '26739': {
    country: 'US',
    city: 'Mount Storm',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Grant',
    latitude: 39.2652,
    longitude: -79.2255,
    postal_code: '26739'
  },
  '26743': {
    country: 'US',
    city: 'New Creek',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mineral',
    latitude: 39.3612,
    longitude: -79.0452,
    postal_code: '26743'
  },
  '26750': {
    country: 'US',
    city: 'Piedmont',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mineral',
    latitude: 39.4799,
    longitude: -79.0489,
    postal_code: '26750'
  },
  '26753': {
    country: 'US',
    city: 'Ridgeley',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mineral',
    latitude: 39.5636,
    longitude: -78.7887,
    postal_code: '26753'
  },
  '26755': {
    country: 'US',
    city: 'Rio',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.1847,
    longitude: -78.727,
    postal_code: '26755'
  },
  '26757': {
    country: 'US',
    city: 'Romney',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.3424,
    longitude: -78.7481,
    postal_code: '26757'
  },
  '26761': {
    country: 'US',
    city: 'Shanks',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.2849,
    longitude: -78.6995,
    postal_code: '26761'
  },
  '26763': {
    country: 'US',
    city: 'Springfield',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.463,
    longitude: -78.695,
    postal_code: '26763'
  },
  '26764': {
    country: 'US',
    city: 'Terra Alta',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Preston',
    latitude: 39.4456,
    longitude: -79.5464,
    postal_code: '26764'
  },
  '26767': {
    country: 'US',
    city: 'Wiley Ford',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Mineral',
    latitude: 39.6133,
    longitude: -78.775,
    postal_code: '26767'
  },
  '26801': {
    country: 'US',
    city: 'Baker',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hardy',
    latitude: 39.0656,
    longitude: -78.7748,
    postal_code: '26801'
  },
  '26802': {
    country: 'US',
    city: 'Brandywine',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pendleton',
    latitude: 38.6357,
    longitude: -79.2096,
    postal_code: '26802'
  },
  '26804': {
    country: 'US',
    city: 'Circleville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pendleton',
    latitude: 38.623,
    longitude: -79.5367,
    postal_code: '26804'
  },
  '26807': {
    country: 'US',
    city: 'Franklin',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pendleton',
    latitude: 38.64,
    longitude: -79.3538,
    postal_code: '26807'
  },
  '26808': {
    country: 'US',
    city: 'High View',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.2225,
    longitude: -78.4425,
    postal_code: '26808'
  },
  '26810': {
    country: 'US',
    city: 'Lost City',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hardy',
    latitude: 38.9812,
    longitude: -78.7448,
    postal_code: '26810'
  },
  '26812': {
    country: 'US',
    city: 'Mathias',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hardy',
    latitude: 38.8744,
    longitude: -78.8814,
    postal_code: '26812'
  },
  '26814': {
    country: 'US',
    city: 'Riverton',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pendleton',
    latitude: 38.744,
    longitude: -79.4359,
    postal_code: '26814'
  },
  '26815': {
    country: 'US',
    city: 'Sugar Grove',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pendleton',
    latitude: 38.489,
    longitude: -79.3593,
    postal_code: '26815'
  },
  '26817': {
    country: 'US',
    city: 'Bloomery',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.3604,
    longitude: -78.3917,
    postal_code: '26817'
  },
  '26818': {
    country: 'US',
    city: 'Fisher',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hardy',
    latitude: 39.075,
    longitude: -79.0342,
    postal_code: '26818'
  },
  '26823': {
    country: 'US',
    city: 'Capon Springs',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.1362,
    longitude: -78.4845,
    postal_code: '26823'
  },
  '26833': {
    country: 'US',
    city: 'Maysville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Grant',
    latitude: 39.0712,
    longitude: -79.1937,
    postal_code: '26833'
  },
  '26836': {
    country: 'US',
    city: 'Moorefield',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hardy',
    latitude: 38.9907,
    longitude: -78.9745,
    postal_code: '26836'
  },
  '26838': {
    country: 'US',
    city: 'Milam',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hardy',
    latitude: 38.8125,
    longitude: -79.0729,
    postal_code: '26838'
  },
  '26845': {
    country: 'US',
    city: 'Old Fields',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hardy',
    latitude: 39.1502,
    longitude: -78.9509,
    postal_code: '26845'
  },
  '26847': {
    country: 'US',
    city: 'Petersburg',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Grant',
    latitude: 38.9908,
    longitude: -79.1293,
    postal_code: '26847'
  },
  '26851': {
    country: 'US',
    city: 'Wardensville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hardy',
    latitude: 39.0562,
    longitude: -78.6175,
    postal_code: '26851'
  },
  '26852': {
    country: 'US',
    city: 'Purgitsville',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.2681,
    longitude: -78.906,
    postal_code: '26852'
  },
  '26855': {
    country: 'US',
    city: 'Cabins',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Grant',
    latitude: 38.9512,
    longitude: -79.2783,
    postal_code: '26855'
  },
  '26865': {
    country: 'US',
    city: 'Yellow Spring',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Hampshire',
    latitude: 39.1823,
    longitude: -78.5095,
    postal_code: '26865'
  },
  '26866': {
    country: 'US',
    city: 'Upper Tract',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pendleton',
    latitude: 38.795,
    longitude: -79.2586,
    postal_code: '26866'
  },
  '26884': {
    country: 'US',
    city: 'Seneca Rocks',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pendleton',
    latitude: 38.8296,
    longitude: -79.3866,
    postal_code: '26884'
  },
  '26886': {
    country: 'US',
    city: 'Onego',
    state: 'West Virginia',
    state_short: 'WV',
    county: 'Pendleton',
    latitude: 38.8017,
    longitude: -79.4507,
    postal_code: '26886'
  },
  '27006': {
    country: 'US',
    city: 'Advance',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davie',
    latitude: 36.0065,
    longitude: -80.4463,
    postal_code: '27006'
  },
  '27007': {
    country: 'US',
    city: 'Ararat',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.3859,
    longitude: -80.5895,
    postal_code: '27007'
  },
  '27009': {
    country: 'US',
    city: 'Belews Creek',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.2269,
    longitude: -80.093,
    postal_code: '27009'
  },
  '27010': {
    country: 'US',
    city: 'Bethania',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.183,
    longitude: -80.3387,
    postal_code: '27010'
  },
  '27011': {
    country: 'US',
    city: 'Boonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Yadkin',
    latitude: 36.234,
    longitude: -80.6357,
    postal_code: '27011'
  },
  '27012': {
    country: 'US',
    city: 'Clemmons',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0341,
    longitude: -80.3962,
    postal_code: '27012'
  },
  '27013': {
    country: 'US',
    city: 'Cleveland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.7374,
    longitude: -80.7113,
    postal_code: '27013'
  },
  '27014': {
    country: 'US',
    city: 'Cooleemee',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davie',
    latitude: 35.8104,
    longitude: -80.5559,
    postal_code: '27014'
  },
  '27016': {
    country: 'US',
    city: 'Danbury',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stokes',
    latitude: 36.4559,
    longitude: -80.2194,
    postal_code: '27016'
  },
  '27017': {
    country: 'US',
    city: 'Dobson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.3698,
    longitude: -80.7101,
    postal_code: '27017'
  },
  '27018': {
    country: 'US',
    city: 'East Bend',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Yadkin',
    latitude: 36.2032,
    longitude: -80.5284,
    postal_code: '27018'
  },
  '27019': {
    country: 'US',
    city: 'Germanton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stokes',
    latitude: 36.2725,
    longitude: -80.2254,
    postal_code: '27019'
  },
  '27020': {
    country: 'US',
    city: 'Hamptonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Yadkin',
    latitude: 36.1242,
    longitude: -80.8137,
    postal_code: '27020'
  },
  '27021': {
    country: 'US',
    city: 'King',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stokes',
    latitude: 36.295,
    longitude: -80.356,
    postal_code: '27021'
  },
  '27022': {
    country: 'US',
    city: 'Lawsonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stokes',
    latitude: 36.5132,
    longitude: -80.2103,
    postal_code: '27022'
  },
  '27023': {
    country: 'US',
    city: 'Lewisville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0967,
    longitude: -80.4206,
    postal_code: '27023'
  },
  '27024': {
    country: 'US',
    city: 'Lowgap',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.503,
    longitude: -80.7889,
    postal_code: '27024'
  },
  '27025': {
    country: 'US',
    city: 'Madison',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rockingham',
    latitude: 36.3695,
    longitude: -79.9654,
    postal_code: '27025'
  },
  '27027': {
    country: 'US',
    city: 'Mayodan',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rockingham',
    latitude: 36.4277,
    longitude: -79.9567,
    postal_code: '27027'
  },
  '27028': {
    country: 'US',
    city: 'Mocksville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davie',
    latitude: 35.922,
    longitude: -80.537,
    postal_code: '27028'
  },
  '27030': {
    country: 'US',
    city: 'Mount Airy',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.5007,
    longitude: -80.6119,
    postal_code: '27030'
  },
  '27031': {
    country: 'US',
    city: 'White Plains',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.3994,
    longitude: -80.7081,
    postal_code: '27031'
  },
  '27040': {
    country: 'US',
    city: 'Pfafftown',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.1669,
    longitude: -80.3798,
    postal_code: '27040'
  },
  '27041': {
    country: 'US',
    city: 'Pilot Mountain',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.4109,
    longitude: -80.4921,
    postal_code: '27041'
  },
  '27042': {
    country: 'US',
    city: 'Pine Hall',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stokes',
    latitude: 36.3482,
    longitude: -80.0495,
    postal_code: '27042'
  },
  '27043': {
    country: 'US',
    city: 'Pinnacle',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stokes',
    latitude: 36.3339,
    longitude: -80.4391,
    postal_code: '27043'
  },
  '27045': {
    country: 'US',
    city: 'Rural Hall',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.2293,
    longitude: -80.2936,
    postal_code: '27045'
  },
  '27046': {
    country: 'US',
    city: 'Sandy Ridge',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stokes',
    latitude: 36.49,
    longitude: -80.0859,
    postal_code: '27046'
  },
  '27047': {
    country: 'US',
    city: 'Siloam',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.3002,
    longitude: -80.5772,
    postal_code: '27047'
  },
  '27048': {
    country: 'US',
    city: 'Stoneville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rockingham',
    latitude: 36.4705,
    longitude: -79.9013,
    postal_code: '27048'
  },
  '27049': {
    country: 'US',
    city: 'Toast',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.5004,
    longitude: -80.6265,
    postal_code: '27049'
  },
  '27050': {
    country: 'US',
    city: 'Tobaccoville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.2336,
    longitude: -80.3915,
    postal_code: '27050'
  },
  '27051': {
    country: 'US',
    city: 'Walkertown',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.18,
    longitude: -80.1629,
    postal_code: '27051'
  },
  '27052': {
    country: 'US',
    city: 'Walnut Cove',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stokes',
    latitude: 36.3189,
    longitude: -80.1484,
    postal_code: '27052'
  },
  '27053': {
    country: 'US',
    city: 'Westfield',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.4674,
    longitude: -80.3677,
    postal_code: '27053'
  },
  '27054': {
    country: 'US',
    city: 'Woodleaf',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.7901,
    longitude: -80.6051,
    postal_code: '27054'
  },
  '27055': {
    country: 'US',
    city: 'Yadkinville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Yadkin',
    latitude: 36.1277,
    longitude: -80.653,
    postal_code: '27055'
  },
  '27094': {
    country: 'US',
    city: 'Rural Hall',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.2404,
    longitude: -80.2934,
    postal_code: '27094'
  },
  '27098': {
    country: 'US',
    city: 'Rural Hall',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.2404,
    longitude: -80.2934,
    postal_code: '27098'
  },
  '27099': {
    country: 'US',
    city: 'Rural Hall',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.2404,
    longitude: -80.2934,
    postal_code: '27099'
  },
  '27101': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.1024,
    longitude: -80.2228,
    postal_code: '27101'
  },
  '27102': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0323,
    longitude: -80.3962,
    postal_code: '27102'
  },
  '27103': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0671,
    longitude: -80.3025,
    postal_code: '27103'
  },
  '27104': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.092,
    longitude: -80.3224,
    postal_code: '27104'
  },
  '27105': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.144,
    longitude: -80.2376,
    postal_code: '27105'
  },
  '27106': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.1428,
    longitude: -80.3069,
    postal_code: '27106'
  },
  '27107': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0403,
    longitude: -80.1933,
    postal_code: '27107'
  },
  '27108': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0275,
    longitude: -80.2073,
    postal_code: '27108'
  },
  '27109': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0275,
    longitude: -80.2073,
    postal_code: '27109'
  },
  '27110': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0275,
    longitude: -80.2073,
    postal_code: '27110'
  },
  '27111': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0999,
    longitude: -80.2442,
    postal_code: '27111'
  },
  '27113': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0999,
    longitude: -80.2442,
    postal_code: '27113'
  },
  '27114': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0999,
    longitude: -80.2442,
    postal_code: '27114'
  },
  '27115': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0999,
    longitude: -80.2442,
    postal_code: '27115'
  },
  '27116': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0999,
    longitude: -80.2442,
    postal_code: '27116'
  },
  '27117': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0275,
    longitude: -80.2073,
    postal_code: '27117'
  },
  '27120': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0999,
    longitude: -80.2442,
    postal_code: '27120'
  },
  '27127': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0425,
    longitude: -80.2609,
    postal_code: '27127'
  },
  '27130': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0275,
    longitude: -80.2073,
    postal_code: '27130'
  },
  '27150': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0275,
    longitude: -80.2073,
    postal_code: '27150'
  },
  '27152': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0275,
    longitude: -80.2073,
    postal_code: '27152'
  },
  '27155': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0275,
    longitude: -80.2073,
    postal_code: '27155'
  },
  '27157': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0275,
    longitude: -80.2073,
    postal_code: '27157'
  },
  '27198': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0275,
    longitude: -80.2073,
    postal_code: '27198'
  },
  '27199': {
    country: 'US',
    city: 'Winston-Salem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.0999,
    longitude: -80.2442,
    postal_code: '27199'
  },
  '27201': {
    country: 'US',
    city: 'Alamance',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 36.0318,
    longitude: -79.4856,
    postal_code: '27201'
  },
  '27202': {
    country: 'US',
    city: 'Altamahaw',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 36.1855,
    longitude: -79.5055,
    postal_code: '27202'
  },
  '27203': {
    country: 'US',
    city: 'Asheboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.7323,
    longitude: -79.7893,
    postal_code: '27203'
  },
  '27204': {
    country: 'US',
    city: 'Asheboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.7883,
    longitude: -79.7206,
    postal_code: '27204'
  },
  '27205': {
    country: 'US',
    city: 'Asheboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.6396,
    longitude: -79.8509,
    postal_code: '27205'
  },
  '27207': {
    country: 'US',
    city: 'Bear Creek',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chatham',
    latitude: 35.6129,
    longitude: -79.3726,
    postal_code: '27207'
  },
  '27208': {
    country: 'US',
    city: 'Bennett',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chatham',
    latitude: 35.5673,
    longitude: -79.5225,
    postal_code: '27208'
  },
  '27209': {
    country: 'US',
    city: 'Biscoe',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Montgomery',
    latitude: 35.3599,
    longitude: -79.7798,
    postal_code: '27209'
  },
  '27212': {
    country: 'US',
    city: 'Blanch',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caswell',
    latitude: 36.4664,
    longitude: -79.2755,
    postal_code: '27212'
  },
  '27213': {
    country: 'US',
    city: 'Bonlee',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chatham',
    latitude: 35.6482,
    longitude: -79.4149,
    postal_code: '27213'
  },
  '27214': {
    country: 'US',
    city: 'Browns Summit',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.2019,
    longitude: -79.7101,
    postal_code: '27214'
  },
  '27215': {
    country: 'US',
    city: 'Burlington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 36.0318,
    longitude: -79.4889,
    postal_code: '27215'
  },
  '27216': {
    country: 'US',
    city: 'Burlington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 36.0475,
    longitude: -79.4797,
    postal_code: '27216'
  },
  '27217': {
    country: 'US',
    city: 'Burlington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 36.1288,
    longitude: -79.4114,
    postal_code: '27217'
  },
  '27228': {
    country: 'US',
    city: 'Bynum',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chatham',
    latitude: 35.7835,
    longitude: -79.1298,
    postal_code: '27228'
  },
  '27229': {
    country: 'US',
    city: 'Candor',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Montgomery',
    latitude: 35.2234,
    longitude: -79.7876,
    postal_code: '27229'
  },
  '27230': {
    country: 'US',
    city: 'Cedar Falls',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.8028,
    longitude: -79.8797,
    postal_code: '27230'
  },
  '27231': {
    country: 'US',
    city: 'Cedar Grove',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Orange',
    latitude: 36.202,
    longitude: -79.1666,
    postal_code: '27231'
  },
  '27233': {
    country: 'US',
    city: 'Climax',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 35.9345,
    longitude: -79.7019,
    postal_code: '27233'
  },
  '27235': {
    country: 'US',
    city: 'Colfax',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.1003,
    longitude: -80.0103,
    postal_code: '27235'
  },
  '27237': {
    country: 'US',
    city: 'Cumnock',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lee',
    latitude: 35.4676,
    longitude: -79.1653,
    postal_code: '27237'
  },
  '27239': {
    country: 'US',
    city: 'Denton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 35.6196,
    longitude: -80.0959,
    postal_code: '27239'
  },
  '27242': {
    country: 'US',
    city: 'Eagle Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.336,
    longitude: -79.631,
    postal_code: '27242'
  },
  '27243': {
    country: 'US',
    city: 'Efland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Orange',
    latitude: 36.0912,
    longitude: -79.1884,
    postal_code: '27243'
  },
  '27244': {
    country: 'US',
    city: 'Elon',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 36.1029,
    longitude: -79.5067,
    postal_code: '27244'
  },
  '27247': {
    country: 'US',
    city: 'Ether',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Montgomery',
    latitude: 35.4514,
    longitude: -79.7834,
    postal_code: '27247'
  },
  '27248': {
    country: 'US',
    city: 'Franklinville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.7917,
    longitude: -79.7132,
    postal_code: '27248'
  },
  '27249': {
    country: 'US',
    city: 'Gibsonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.1183,
    longitude: -79.5685,
    postal_code: '27249'
  },
  '27252': {
    country: 'US',
    city: 'Goldston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chatham',
    latitude: 35.5643,
    longitude: -79.3385,
    postal_code: '27252'
  },
  '27253': {
    country: 'US',
    city: 'Graham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 36.031,
    longitude: -79.3814,
    postal_code: '27253'
  },
  '27256': {
    country: 'US',
    city: 'Gulf',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chatham',
    latitude: 35.5604,
    longitude: -79.2817,
    postal_code: '27256'
  },
  '27258': {
    country: 'US',
    city: 'Haw River',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 36.0915,
    longitude: -79.3642,
    postal_code: '27258'
  },
  '27259': {
    country: 'US',
    city: 'Highfalls',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.4921,
    longitude: -79.479,
    postal_code: '27259'
  },
  '27260': {
    country: 'US',
    city: 'High Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 35.954,
    longitude: -79.9884,
    postal_code: '27260'
  },
  '27261': {
    country: 'US',
    city: 'High Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0807,
    longitude: -80.0244,
    postal_code: '27261'
  },
  '27262': {
    country: 'US',
    city: 'High Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 35.9734,
    longitude: -80.0107,
    postal_code: '27262'
  },
  '27263': {
    country: 'US',
    city: 'High Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 35.9359,
    longitude: -79.9395,
    postal_code: '27263'
  },
  '27264': {
    country: 'US',
    city: 'High Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0807,
    longitude: -80.0244,
    postal_code: '27264'
  },
  '27265': {
    country: 'US',
    city: 'High Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0036,
    longitude: -80.0036,
    postal_code: '27265'
  },
  '27268': {
    country: 'US',
    city: 'High Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 35.9698,
    longitude: -79.9936,
    postal_code: '27268'
  },
  '27278': {
    country: 'US',
    city: 'Hillsborough',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Orange',
    latitude: 36.0756,
    longitude: -79.0914,
    postal_code: '27278'
  },
  '27281': {
    country: 'US',
    city: 'Jackson Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.2259,
    longitude: -79.6685,
    postal_code: '27281'
  },
  '27282': {
    country: 'US',
    city: 'Jamestown',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 35.999,
    longitude: -79.9293,
    postal_code: '27282'
  },
  '27283': {
    country: 'US',
    city: 'Julian',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 35.9543,
    longitude: -79.6386,
    postal_code: '27283'
  },
  '27284': {
    country: 'US',
    city: 'Kernersville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.1165,
    longitude: -80.0831,
    postal_code: '27284'
  },
  '27285': {
    country: 'US',
    city: 'Kernersville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Forsyth',
    latitude: 36.1199,
    longitude: -80.0737,
    postal_code: '27285'
  },
  '27288': {
    country: 'US',
    city: 'Eden',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rockingham',
    latitude: 36.5,
    longitude: -79.759,
    postal_code: '27288'
  },
  '27289': {
    country: 'US',
    city: 'Eden',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rockingham',
    latitude: 36.3921,
    longitude: -79.7731,
    postal_code: '27289'
  },
  '27291': {
    country: 'US',
    city: 'Leasburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caswell',
    latitude: 36.4151,
    longitude: -79.1942,
    postal_code: '27291'
  },
  '27292': {
    country: 'US',
    city: 'Lexington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 35.8231,
    longitude: -80.262,
    postal_code: '27292'
  },
  '27293': {
    country: 'US',
    city: 'Lexington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 35.8829,
    longitude: -80.276,
    postal_code: '27293'
  },
  '27294': {
    country: 'US',
    city: 'Lexington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 35.824,
    longitude: -80.2534,
    postal_code: '27294'
  },
  '27295': {
    country: 'US',
    city: 'Lexington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 35.8684,
    longitude: -80.315,
    postal_code: '27295'
  },
  '27298': {
    country: 'US',
    city: 'Liberty',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.8729,
    longitude: -79.5821,
    postal_code: '27298'
  },
  '27299': {
    country: 'US',
    city: 'Linwood',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 35.7562,
    longitude: -80.3749,
    postal_code: '27299'
  },
  '27301': {
    country: 'US',
    city: 'Mc Leansville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.1163,
    longitude: -79.6684,
    postal_code: '27301'
  },
  '27302': {
    country: 'US',
    city: 'Mebane',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 36.0979,
    longitude: -79.2719,
    postal_code: '27302'
  },
  '27305': {
    country: 'US',
    city: 'Milton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caswell',
    latitude: 36.5158,
    longitude: -79.2087,
    postal_code: '27305'
  },
  '27306': {
    country: 'US',
    city: 'Mount Gilead',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Montgomery',
    latitude: 35.2274,
    longitude: -79.9871,
    postal_code: '27306'
  },
  '27310': {
    country: 'US',
    city: 'Oak Ridge',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.1673,
    longitude: -79.9804,
    postal_code: '27310'
  },
  '27311': {
    country: 'US',
    city: 'Pelham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caswell',
    latitude: 36.4899,
    longitude: -79.4736,
    postal_code: '27311'
  },
  '27312': {
    country: 'US',
    city: 'Pittsboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chatham',
    latitude: 35.7694,
    longitude: -79.1755,
    postal_code: '27312'
  },
  '27313': {
    country: 'US',
    city: 'Pleasant Garden',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 35.9522,
    longitude: -79.7549,
    postal_code: '27313'
  },
  '27314': {
    country: 'US',
    city: 'Prospect Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caswell',
    latitude: 36.2932,
    longitude: -79.2156,
    postal_code: '27314'
  },
  '27315': {
    country: 'US',
    city: 'Providence',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caswell',
    latitude: 36.4873,
    longitude: -79.4041,
    postal_code: '27315'
  },
  '27316': {
    country: 'US',
    city: 'Ramseur',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.7143,
    longitude: -79.6469,
    postal_code: '27316'
  },
  '27317': {
    country: 'US',
    city: 'Randleman',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.8488,
    longitude: -79.8018,
    postal_code: '27317'
  },
  '27320': {
    country: 'US',
    city: 'Reidsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rockingham',
    latitude: 36.3432,
    longitude: -79.6642,
    postal_code: '27320'
  },
  '27323': {
    country: 'US',
    city: 'Reidsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rockingham',
    latitude: 36.3921,
    longitude: -79.7731,
    postal_code: '27323'
  },
  '27325': {
    country: 'US',
    city: 'Robbins',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.4528,
    longitude: -79.582,
    postal_code: '27325'
  },
  '27326': {
    country: 'US',
    city: 'Ruffin',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rockingham',
    latitude: 36.4428,
    longitude: -79.5606,
    postal_code: '27326'
  },
  '27330': {
    country: 'US',
    city: 'Sanford',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lee',
    latitude: 35.4698,
    longitude: -79.1717,
    postal_code: '27330'
  },
  '27331': {
    country: 'US',
    city: 'Sanford',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lee',
    latitude: 35.4799,
    longitude: -79.1803,
    postal_code: '27331'
  },
  '27332': {
    country: 'US',
    city: 'Sanford',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lee',
    latitude: 35.4469,
    longitude: -79.138,
    postal_code: '27332'
  },
  '27340': {
    country: 'US',
    city: 'Saxapahaw',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 35.9488,
    longitude: -79.3297,
    postal_code: '27340'
  },
  '27341': {
    country: 'US',
    city: 'Seagrove',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.5283,
    longitude: -79.6979,
    postal_code: '27341'
  },
  '27342': {
    country: 'US',
    city: 'Sedalia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0756,
    longitude: -79.6206,
    postal_code: '27342'
  },
  '27343': {
    country: 'US',
    city: 'Semora',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Person',
    latitude: 36.5114,
    longitude: -79.0942,
    postal_code: '27343'
  },
  '27344': {
    country: 'US',
    city: 'Siler City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chatham',
    latitude: 35.7354,
    longitude: -79.4566,
    postal_code: '27344'
  },
  '27349': {
    country: 'US',
    city: 'Snow Camp',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 35.9066,
    longitude: -79.4279,
    postal_code: '27349'
  },
  '27350': {
    country: 'US',
    city: 'Sophia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.8298,
    longitude: -79.8986,
    postal_code: '27350'
  },
  '27351': {
    country: 'US',
    city: 'Southmont',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 35.6605,
    longitude: -80.2836,
    postal_code: '27351'
  },
  '27355': {
    country: 'US',
    city: 'Staley',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.796,
    longitude: -79.5514,
    postal_code: '27355'
  },
  '27356': {
    country: 'US',
    city: 'Star',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Montgomery',
    latitude: 35.4211,
    longitude: -79.7826,
    postal_code: '27356'
  },
  '27357': {
    country: 'US',
    city: 'Stokesdale',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.2552,
    longitude: -79.9705,
    postal_code: '27357'
  },
  '27358': {
    country: 'US',
    city: 'Summerfield',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.2245,
    longitude: -79.8901,
    postal_code: '27358'
  },
  '27359': {
    country: 'US',
    city: 'Swepsonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alamance',
    latitude: 36.0214,
    longitude: -79.3574,
    postal_code: '27359'
  },
  '27360': {
    country: 'US',
    city: 'Thomasville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 35.8713,
    longitude: -80.0913,
    postal_code: '27360'
  },
  '27361': {
    country: 'US',
    city: 'Thomasville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 35.8829,
    longitude: -80.276,
    postal_code: '27361'
  },
  '27370': {
    country: 'US',
    city: 'Trinity',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Randolph',
    latitude: 35.8429,
    longitude: -79.9902,
    postal_code: '27370'
  },
  '27371': {
    country: 'US',
    city: 'Troy',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Montgomery',
    latitude: 35.3777,
    longitude: -79.9093,
    postal_code: '27371'
  },
  '27373': {
    country: 'US',
    city: 'Wallburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 36.0101,
    longitude: -80.1392,
    postal_code: '27373'
  },
  '27374': {
    country: 'US',
    city: 'Welcome',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Davidson',
    latitude: 35.9029,
    longitude: -80.257,
    postal_code: '27374'
  },
  '27375': {
    country: 'US',
    city: 'Wentworth',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rockingham',
    latitude: 36.3921,
    longitude: -79.7731,
    postal_code: '27375'
  },
  '27376': {
    country: 'US',
    city: 'West End',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.2512,
    longitude: -79.536,
    postal_code: '27376'
  },
  '27377': {
    country: 'US',
    city: 'Whitsett',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.033,
    longitude: -79.5972,
    postal_code: '27377'
  },
  '27379': {
    country: 'US',
    city: 'Yanceyville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caswell',
    latitude: 36.3907,
    longitude: -79.3465,
    postal_code: '27379'
  },
  '27401': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0697,
    longitude: -79.7682,
    postal_code: '27401'
  },
  '27402': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27402'
  },
  '27403': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0641,
    longitude: -79.8202,
    postal_code: '27403'
  },
  '27404': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27404'
  },
  '27405': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.1214,
    longitude: -79.7733,
    postal_code: '27405'
  },
  '27406': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.022,
    longitude: -79.7821,
    postal_code: '27406'
  },
  '27407': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0334,
    longitude: -79.8626,
    postal_code: '27407'
  },
  '27408': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.1064,
    longitude: -79.8165,
    postal_code: '27408'
  },
  '27409': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0777,
    longitude: -79.9086,
    postal_code: '27409'
  },
  '27410': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.1032,
    longitude: -79.8794,
    postal_code: '27410'
  },
  '27411': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27411'
  },
  '27412': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0661,
    longitude: -79.8067,
    postal_code: '27412'
  },
  '27413': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27413'
  },
  '27415': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27415'
  },
  '27416': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27416'
  },
  '27417': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27417'
  },
  '27419': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27419'
  },
  '27420': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.113,
    longitude: -79.7759,
    postal_code: '27420'
  },
  '27425': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27425'
  },
  '27427': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27427'
  },
  '27429': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27429'
  },
  '27435': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27435'
  },
  '27438': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27438'
  },
  '27455': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.1824,
    longitude: -79.806,
    postal_code: '27455'
  },
  '27495': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27495'
  },
  '27497': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0798,
    longitude: -79.8282,
    postal_code: '27497'
  },
  '27498': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27498'
  },
  '27499': {
    country: 'US',
    city: 'Greensboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Guilford',
    latitude: 36.0726,
    longitude: -79.792,
    postal_code: '27499'
  },
  '27501': {
    country: 'US',
    city: 'Angier',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.4897,
    longitude: -78.7249,
    postal_code: '27501'
  },
  '27502': {
    country: 'US',
    city: 'Apex',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7225,
    longitude: -78.8408,
    postal_code: '27502'
  },
  '27503': {
    country: 'US',
    city: 'Bahama',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.1566,
    longitude: -78.8903,
    postal_code: '27503'
  },
  '27504': {
    country: 'US',
    city: 'Benson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.4037,
    longitude: -78.5421,
    postal_code: '27504'
  },
  '27505': {
    country: 'US',
    city: 'Broadway',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lee',
    latitude: 35.4181,
    longitude: -79.0435,
    postal_code: '27505'
  },
  '27506': {
    country: 'US',
    city: 'Buies Creek',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.4132,
    longitude: -78.7356,
    postal_code: '27506'
  },
  '27507': {
    country: 'US',
    city: 'Bullock',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Granville',
    latitude: 36.5076,
    longitude: -78.5646,
    postal_code: '27507'
  },
  '27508': {
    country: 'US',
    city: 'Bunn',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Franklin',
    latitude: 35.9577,
    longitude: -78.2498,
    postal_code: '27508'
  },
  '27509': {
    country: 'US',
    city: 'Butner',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Granville',
    latitude: 36.1359,
    longitude: -78.7636,
    postal_code: '27509'
  },
  '27510': {
    country: 'US',
    city: 'Carrboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Orange',
    latitude: 35.9055,
    longitude: -79.0901,
    postal_code: '27510'
  },
  '27511': {
    country: 'US',
    city: 'Cary',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7641,
    longitude: -78.7786,
    postal_code: '27511'
  },
  '27512': {
    country: 'US',
    city: 'Cary',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8084,
    longitude: -78.8395,
    postal_code: '27512'
  },
  '27513': {
    country: 'US',
    city: 'Cary',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7956,
    longitude: -78.7941,
    postal_code: '27513'
  },
  '27514': {
    country: 'US',
    city: 'Chapel Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Orange',
    latitude: 35.9203,
    longitude: -79.0372,
    postal_code: '27514'
  },
  '27515': {
    country: 'US',
    city: 'Chapel Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Orange',
    latitude: 35.9132,
    longitude: -79.0558,
    postal_code: '27515'
  },
  '27516': {
    country: 'US',
    city: 'Chapel Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Orange',
    latitude: 35.9162,
    longitude: -79.0999,
    postal_code: '27516'
  },
  '27517': {
    country: 'US',
    city: 'Chapel Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Orange',
    latitude: 35.9182,
    longitude: -79.0035,
    postal_code: '27517'
  },
  '27518': {
    country: 'US',
    city: 'Cary',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7299,
    longitude: -78.7735,
    postal_code: '27518'
  },
  '27519': {
    country: 'US',
    city: 'Cary',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8072,
    longitude: -78.887,
    postal_code: '27519'
  },
  '27520': {
    country: 'US',
    city: 'Clayton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.6348,
    longitude: -78.451,
    postal_code: '27520'
  },
  '27521': {
    country: 'US',
    city: 'Coats',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.4082,
    longitude: -78.6627,
    postal_code: '27521'
  },
  '27522': {
    country: 'US',
    city: 'Creedmoor',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Granville',
    latitude: 36.1224,
    longitude: -78.6861,
    postal_code: '27522'
  },
  '27523': {
    country: 'US',
    city: 'Apex',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7225,
    longitude: -78.8408,
    postal_code: '27523'
  },
  '27524': {
    country: 'US',
    city: 'Four Oaks',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.404,
    longitude: -78.4153,
    postal_code: '27524'
  },
  '27525': {
    country: 'US',
    city: 'Franklinton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Franklin',
    latitude: 36.0955,
    longitude: -78.4486,
    postal_code: '27525'
  },
  '27526': {
    country: 'US',
    city: 'Fuquay Varina',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.58,
    longitude: -78.7908,
    postal_code: '27526'
  },
  '27527': {
    country: 'US',
    city: 'Clayton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Granville',
    latitude: 35.6577,
    longitude: -78.3837,
    postal_code: '27527'
  },
  '27528': {
    country: 'US',
    city: 'Clayton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Granville',
    latitude: 35.6555,
    longitude: -78.4611,
    postal_code: '27528'
  },
  '27529': {
    country: 'US',
    city: 'Garner',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.6813,
    longitude: -78.5975,
    postal_code: '27529'
  },
  '27530': {
    country: 'US',
    city: 'Goldsboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wayne',
    latitude: 35.3683,
    longitude: -78.0929,
    postal_code: '27530'
  },
  '27531': {
    country: 'US',
    city: 'Goldsboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wayne',
    latitude: 35.343,
    longitude: -77.9644,
    postal_code: '27531'
  },
  '27532': {
    country: 'US',
    city: 'Goldsboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wayne',
    latitude: 35.372,
    longitude: -78.0524,
    postal_code: '27532'
  },
  '27533': {
    country: 'US',
    city: 'Goldsboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wayne',
    latitude: 35.372,
    longitude: -78.0524,
    postal_code: '27533'
  },
  '27534': {
    country: 'US',
    city: 'Goldsboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wayne',
    latitude: 35.3664,
    longitude: -77.9221,
    postal_code: '27534'
  },
  '27536': {
    country: 'US',
    city: 'Henderson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Vance',
    latitude: 36.3301,
    longitude: -78.3981,
    postal_code: '27536'
  },
  '27537': {
    country: 'US',
    city: 'Henderson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Vance',
    latitude: 36.36,
    longitude: -78.3906,
    postal_code: '27537'
  },
  '27539': {
    country: 'US',
    city: 'Apex',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7225,
    longitude: -78.8408,
    postal_code: '27539'
  },
  '27540': {
    country: 'US',
    city: 'Holly Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.6263,
    longitude: -78.8458,
    postal_code: '27540'
  },
  '27541': {
    country: 'US',
    city: 'Hurdle Mills',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Person',
    latitude: 36.2518,
    longitude: -79.0827,
    postal_code: '27541'
  },
  '27542': {
    country: 'US',
    city: 'Kenly',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.6077,
    longitude: -78.1382,
    postal_code: '27542'
  },
  '27543': {
    country: 'US',
    city: 'Kipling',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.4799,
    longitude: -78.8211,
    postal_code: '27543'
  },
  '27544': {
    country: 'US',
    city: 'Kittrell',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Vance',
    latitude: 36.2042,
    longitude: -78.4241,
    postal_code: '27544'
  },
  '27545': {
    country: 'US',
    city: 'Knightdale',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7789,
    longitude: -78.4898,
    postal_code: '27545'
  },
  '27546': {
    country: 'US',
    city: 'Lillington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.332,
    longitude: -78.9212,
    postal_code: '27546'
  },
  '27549': {
    country: 'US',
    city: 'Louisburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Franklin',
    latitude: 36.0578,
    longitude: -78.2586,
    postal_code: '27549'
  },
  '27551': {
    country: 'US',
    city: 'Macon',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Warren',
    latitude: 36.507,
    longitude: -77.9975,
    postal_code: '27551'
  },
  '27552': {
    country: 'US',
    city: 'Mamers',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.4168,
    longitude: -78.9334,
    postal_code: '27552'
  },
  '27553': {
    country: 'US',
    city: 'Manson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Vance',
    latitude: 36.4603,
    longitude: -78.2952,
    postal_code: '27553'
  },
  '27555': {
    country: 'US',
    city: 'Micro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.5638,
    longitude: -78.2044,
    postal_code: '27555'
  },
  '27556': {
    country: 'US',
    city: 'Middleburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Vance',
    latitude: 36.3996,
    longitude: -78.3225,
    postal_code: '27556'
  },
  '27557': {
    country: 'US',
    city: 'Middlesex',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.7665,
    longitude: -78.2062,
    postal_code: '27557'
  },
  '27559': {
    country: 'US',
    city: 'Moncure',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chatham',
    latitude: 35.6306,
    longitude: -79.0839,
    postal_code: '27559'
  },
  '27560': {
    country: 'US',
    city: 'Morrisville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8344,
    longitude: -78.8466,
    postal_code: '27560'
  },
  '27562': {
    country: 'US',
    city: 'New Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.6809,
    longitude: -78.9365,
    postal_code: '27562'
  },
  '27563': {
    country: 'US',
    city: 'Norlina',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Warren',
    latitude: 36.4754,
    longitude: -78.1895,
    postal_code: '27563'
  },
  '27565': {
    country: 'US',
    city: 'Oxford',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Granville',
    latitude: 36.3313,
    longitude: -78.6134,
    postal_code: '27565'
  },
  '27568': {
    country: 'US',
    city: 'Pine Level',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.5132,
    longitude: -78.2444,
    postal_code: '27568'
  },
  '27569': {
    country: 'US',
    city: 'Princeton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.4558,
    longitude: -78.1674,
    postal_code: '27569'
  },
  '27570': {
    country: 'US',
    city: 'Ridgeway',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Warren',
    latitude: 36.4357,
    longitude: -78.2367,
    postal_code: '27570'
  },
  '27571': {
    country: 'US',
    city: 'Rolesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.9156,
    longitude: -78.4658,
    postal_code: '27571'
  },
  '27572': {
    country: 'US',
    city: 'Rougemont',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.2393,
    longitude: -78.9019,
    postal_code: '27572'
  },
  '27573': {
    country: 'US',
    city: 'Roxboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Person',
    latitude: 36.4059,
    longitude: -78.9737,
    postal_code: '27573'
  },
  '27574': {
    country: 'US',
    city: 'Roxboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Granville',
    latitude: 36.3942,
    longitude: -78.9863,
    postal_code: '27574'
  },
  '27576': {
    country: 'US',
    city: 'Selma',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.5565,
    longitude: -78.264,
    postal_code: '27576'
  },
  '27577': {
    country: 'US',
    city: 'Smithfield',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.5068,
    longitude: -78.3479,
    postal_code: '27577'
  },
  '27581': {
    country: 'US',
    city: 'Stem',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Granville',
    latitude: 36.1999,
    longitude: -78.7222,
    postal_code: '27581'
  },
  '27582': {
    country: 'US',
    city: 'Stovall',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Granville',
    latitude: 36.4482,
    longitude: -78.5703,
    postal_code: '27582'
  },
  '27583': {
    country: 'US',
    city: 'Timberlake',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Person',
    latitude: 36.2918,
    longitude: -78.9353,
    postal_code: '27583'
  },
  '27584': {
    country: 'US',
    city: 'Townsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Vance',
    latitude: 36.4946,
    longitude: -78.4236,
    postal_code: '27584'
  },
  '27586': {
    country: 'US',
    city: 'Vaughan',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Warren',
    latitude: 36.4265,
    longitude: -78.0036,
    postal_code: '27586'
  },
  '27587': {
    country: 'US',
    city: 'Wake Forest',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.9815,
    longitude: -78.5392,
    postal_code: '27587'
  },
  '27588': {
    country: 'US',
    city: 'Wake Forest',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.9731,
    longitude: -78.4508,
    postal_code: '27588'
  },
  '27589': {
    country: 'US',
    city: 'Warrenton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Warren',
    latitude: 36.3539,
    longitude: -78.1594,
    postal_code: '27589'
  },
  '27591': {
    country: 'US',
    city: 'Wendell',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.798,
    longitude: -78.3926,
    postal_code: '27591'
  },
  '27592': {
    country: 'US',
    city: 'Willow Spring',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.547,
    longitude: -78.6717,
    postal_code: '27592'
  },
  '27593': {
    country: 'US',
    city: 'Wilsons Mills',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Johnston',
    latitude: 35.5907,
    longitude: -78.3607,
    postal_code: '27593'
  },
  '27594': {
    country: 'US',
    city: 'Wise',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Warren',
    latitude: 36.4865,
    longitude: -78.1708,
    postal_code: '27594'
  },
  '27596': {
    country: 'US',
    city: 'Youngsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Franklin',
    latitude: 36.0249,
    longitude: -78.4744,
    postal_code: '27596'
  },
  '27597': {
    country: 'US',
    city: 'Zebulon',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8321,
    longitude: -78.3174,
    postal_code: '27597'
  },
  '27599': {
    country: 'US',
    city: 'Chapel Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Orange',
    latitude: 36.0525,
    longitude: -79.1077,
    postal_code: '27599'
  },
  '27601': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7727,
    longitude: -78.6324,
    postal_code: '27601'
  },
  '27602': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7587,
    longitude: -78.6711,
    postal_code: '27602'
  },
  '27603': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7076,
    longitude: -78.6563,
    postal_code: '27603'
  },
  '27604': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8334,
    longitude: -78.5799,
    postal_code: '27604'
  },
  '27605': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7908,
    longitude: -78.653,
    postal_code: '27605'
  },
  '27606': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7645,
    longitude: -78.7112,
    postal_code: '27606'
  },
  '27607': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8014,
    longitude: -78.6877,
    postal_code: '27607'
  },
  '27608': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8077,
    longitude: -78.6463,
    postal_code: '27608'
  },
  '27609': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.848,
    longitude: -78.6317,
    postal_code: '27609'
  },
  '27610': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7667,
    longitude: -78.6008,
    postal_code: '27610'
  },
  '27611': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27611'
  },
  '27612': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.852,
    longitude: -78.6841,
    postal_code: '27612'
  },
  '27613': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8949,
    longitude: -78.7051,
    postal_code: '27613'
  },
  '27614': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.9457,
    longitude: -78.6433,
    postal_code: '27614'
  },
  '27615': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8887,
    longitude: -78.6393,
    postal_code: '27615'
  },
  '27616': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8673,
    longitude: -78.5381,
    postal_code: '27616'
  },
  '27617': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.9034,
    longitude: -78.7447,
    postal_code: '27617'
  },
  '27619': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8515,
    longitude: -78.6314,
    postal_code: '27619'
  },
  '27620': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27620'
  },
  '27621': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27621'
  },
  '27622': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27622'
  },
  '27623': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27623'
  },
  '27624': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27624'
  },
  '27625': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27625'
  },
  '27626': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27626'
  },
  '27627': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27627'
  },
  '27628': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27628'
  },
  '27629': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.8175,
    longitude: -78.5524,
    postal_code: '27629'
  },
  '27634': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27634'
  },
  '27635': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27635'
  },
  '27636': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27636'
  },
  '27640': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27640'
  },
  '27650': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27650'
  },
  '27656': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27656'
  },
  '27658': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27658'
  },
  '27661': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27661'
  },
  '27668': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27668'
  },
  '27675': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27675'
  },
  '27676': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27676'
  },
  '27690': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27690'
  },
  '27695': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27695'
  },
  '27697': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7721,
    longitude: -78.6386,
    postal_code: '27697'
  },
  '27698': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27698'
  },
  '27699': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wake',
    latitude: 35.7977,
    longitude: -78.6253,
    postal_code: '27699'
  },
  '27701': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 35.9967,
    longitude: -78.8966,
    postal_code: '27701'
  },
  '27702': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.0512,
    longitude: -78.8577,
    postal_code: '27702'
  },
  '27703': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 35.9781,
    longitude: -78.8439,
    postal_code: '27703'
  },
  '27704': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.0383,
    longitude: -78.8764,
    postal_code: '27704'
  },
  '27705': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.0218,
    longitude: -78.9478,
    postal_code: '27705'
  },
  '27706': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 35.997,
    longitude: -78.9422,
    postal_code: '27706'
  },
  '27707': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 35.9631,
    longitude: -78.9315,
    postal_code: '27707'
  },
  '27708': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.0287,
    longitude: -78.924,
    postal_code: '27708'
  },
  '27709': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.0512,
    longitude: -78.8577,
    postal_code: '27709'
  },
  '27710': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.0512,
    longitude: -78.8577,
    postal_code: '27710'
  },
  '27711': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.0512,
    longitude: -78.8577,
    postal_code: '27711'
  },
  '27712': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.0918,
    longitude: -78.9299,
    postal_code: '27712'
  },
  '27713': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 35.9112,
    longitude: -78.9178,
    postal_code: '27713'
  },
  '27715': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.0512,
    longitude: -78.8577,
    postal_code: '27715'
  },
  '27717': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 36.0512,
    longitude: -78.8577,
    postal_code: '27717'
  },
  '27722': {
    country: 'US',
    city: 'Durham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Durham',
    latitude: 35.994,
    longitude: -78.8986,
    postal_code: '27722'
  },
  '27801': {
    country: 'US',
    city: 'Rocky Mount',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Edgecombe',
    latitude: 35.9427,
    longitude: -77.7608,
    postal_code: '27801'
  },
  '27802': {
    country: 'US',
    city: 'Rocky Mount',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Edgecombe',
    latitude: 35.9356,
    longitude: -77.7808,
    postal_code: '27802'
  },
  '27803': {
    country: 'US',
    city: 'Rocky Mount',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Nash',
    latitude: 35.9238,
    longitude: -77.835,
    postal_code: '27803'
  },
  '27804': {
    country: 'US',
    city: 'Rocky Mount',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Nash',
    latitude: 35.9904,
    longitude: -77.8468,
    postal_code: '27804'
  },
  '27805': {
    country: 'US',
    city: 'Aulander',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bertie',
    latitude: 36.1475,
    longitude: -77.1141,
    postal_code: '27805'
  },
  '27806': {
    country: 'US',
    city: 'Aurora',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Beaufort',
    latitude: 35.3023,
    longitude: -76.7994,
    postal_code: '27806'
  },
  '27807': {
    country: 'US',
    city: 'Bailey',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Nash',
    latitude: 35.8072,
    longitude: -78.0892,
    postal_code: '27807'
  },
  '27808': {
    country: 'US',
    city: 'Bath',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Beaufort',
    latitude: 35.4702,
    longitude: -76.7715,
    postal_code: '27808'
  },
  '27809': {
    country: 'US',
    city: 'Battleboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Edgecombe',
    latitude: 36.0067,
    longitude: -77.7074,
    postal_code: '27809'
  },
  '27810': {
    country: 'US',
    city: 'Belhaven',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Beaufort',
    latitude: 35.4458,
    longitude: -76.6391,
    postal_code: '27810'
  },
  '27811': {
    country: 'US',
    city: 'Bellarthur',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.5847,
    longitude: -77.5134,
    postal_code: '27811'
  },
  '27812': {
    country: 'US',
    city: 'Bethel',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.7909,
    longitude: -77.3748,
    postal_code: '27812'
  },
  '27813': {
    country: 'US',
    city: 'Black Creek',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilson',
    latitude: 35.6352,
    longitude: -77.933,
    postal_code: '27813'
  },
  '27814': {
    country: 'US',
    city: 'Blounts Creek',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Beaufort',
    latitude: 35.382,
    longitude: -76.925,
    postal_code: '27814'
  },
  '27815': {
    country: 'US',
    city: 'Rocky Mount',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Nash',
    latitude: 35.8979,
    longitude: -77.7935,
    postal_code: '27815'
  },
  '27816': {
    country: 'US',
    city: 'Castalia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Nash',
    latitude: 36.0901,
    longitude: -78.0705,
    postal_code: '27816'
  },
  '27817': {
    country: 'US',
    city: 'Chocowinity',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Beaufort',
    latitude: 35.4814,
    longitude: -77.0868,
    postal_code: '27817'
  },
  '27818': {
    country: 'US',
    city: 'Como',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hertford',
    latitude: 36.5015,
    longitude: -77.0094,
    postal_code: '27818'
  },
  '27819': {
    country: 'US',
    city: 'Conetoe',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Edgecombe',
    latitude: 35.8178,
    longitude: -77.4533,
    postal_code: '27819'
  },
  '27820': {
    country: 'US',
    city: 'Conway',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.4164,
    longitude: -77.2502,
    postal_code: '27820'
  },
  '27821': {
    country: 'US',
    city: 'Edward',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Beaufort',
    latitude: 35.3236,
    longitude: -76.8794,
    postal_code: '27821'
  },
  '27822': {
    country: 'US',
    city: 'Elm City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilson',
    latitude: 35.811,
    longitude: -77.856,
    postal_code: '27822'
  },
  '27823': {
    country: 'US',
    city: 'Enfield',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Halifax',
    latitude: 36.1973,
    longitude: -77.7129,
    postal_code: '27823'
  },
  '27824': {
    country: 'US',
    city: 'Engelhard',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hyde',
    latitude: 35.5149,
    longitude: -76.0303,
    postal_code: '27824'
  },
  '27825': {
    country: 'US',
    city: 'Everetts',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Martin',
    latitude: 35.8349,
    longitude: -77.1736,
    postal_code: '27825'
  },
  '27826': {
    country: 'US',
    city: 'Fairfield',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hyde',
    latitude: 35.5659,
    longitude: -76.2318,
    postal_code: '27826'
  },
  '27827': {
    country: 'US',
    city: 'Falkland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.6966,
    longitude: -77.5132,
    postal_code: '27827'
  },
  '27828': {
    country: 'US',
    city: 'Farmville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.5806,
    longitude: -77.5793,
    postal_code: '27828'
  },
  '27829': {
    country: 'US',
    city: 'Fountain',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.674,
    longitude: -77.64,
    postal_code: '27829'
  },
  '27830': {
    country: 'US',
    city: 'Fremont',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wayne',
    latitude: 35.5553,
    longitude: -77.958,
    postal_code: '27830'
  },
  '27831': {
    country: 'US',
    city: 'Garysburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.4761,
    longitude: -77.5719,
    postal_code: '27831'
  },
  '27832': {
    country: 'US',
    city: 'Gaston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.5004,
    longitude: -77.645,
    postal_code: '27832'
  },
  '27833': {
    country: 'US',
    city: 'Greenville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.5804,
    longitude: -77.3926,
    postal_code: '27833'
  },
  '27834': {
    country: 'US',
    city: 'Greenville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.6192,
    longitude: -77.3975,
    postal_code: '27834'
  },
  '27835': {
    country: 'US',
    city: 'Greenville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.5885,
    longitude: -77.3531,
    postal_code: '27835'
  },
  '27836': {
    country: 'US',
    city: 'Greenville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.5804,
    longitude: -77.3926,
    postal_code: '27836'
  },
  '27837': {
    country: 'US',
    city: 'Grimesland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.558,
    longitude: -77.2566,
    postal_code: '27837'
  },
  '27839': {
    country: 'US',
    city: 'Halifax',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Halifax',
    latitude: 36.3049,
    longitude: -77.5607,
    postal_code: '27839'
  },
  '27840': {
    country: 'US',
    city: 'Hamilton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Martin',
    latitude: 35.9446,
    longitude: -77.2097,
    postal_code: '27840'
  },
  '27841': {
    country: 'US',
    city: 'Hassell',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Martin',
    latitude: 35.9086,
    longitude: -77.2763,
    postal_code: '27841'
  },
  '27842': {
    country: 'US',
    city: 'Henrico',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.5274,
    longitude: -77.8546,
    postal_code: '27842'
  },
  '27843': {
    country: 'US',
    city: 'Hobgood',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Halifax',
    latitude: 36.0594,
    longitude: -77.4061,
    postal_code: '27843'
  },
  '27844': {
    country: 'US',
    city: 'Hollister',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Halifax',
    latitude: 36.259,
    longitude: -77.9319,
    postal_code: '27844'
  },
  '27845': {
    country: 'US',
    city: 'Jackson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.3896,
    longitude: -77.4214,
    postal_code: '27845'
  },
  '27846': {
    country: 'US',
    city: 'Jamesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Martin',
    latitude: 35.7839,
    longitude: -76.8983,
    postal_code: '27846'
  },
  '27847': {
    country: 'US',
    city: 'Kelford',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bertie',
    latitude: 36.1871,
    longitude: -77.1898,
    postal_code: '27847'
  },
  '27849': {
    country: 'US',
    city: 'Lewiston Woodville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bertie',
    latitude: 36.0933,
    longitude: -77.1439,
    postal_code: '27849'
  },
  '27850': {
    country: 'US',
    city: 'Littleton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Halifax',
    latitude: 36.4169,
    longitude: -77.8528,
    postal_code: '27850'
  },
  '27851': {
    country: 'US',
    city: 'Lucama',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilson',
    latitude: 35.6415,
    longitude: -78.0197,
    postal_code: '27851'
  },
  '27852': {
    country: 'US',
    city: 'Macclesfield',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Edgecombe',
    latitude: 35.751,
    longitude: -77.6706,
    postal_code: '27852'
  },
  '27853': {
    country: 'US',
    city: 'Margarettsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.5245,
    longitude: -77.326,
    postal_code: '27853'
  },
  '27855': {
    country: 'US',
    city: 'Murfreesboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hertford',
    latitude: 36.4319,
    longitude: -77.1027,
    postal_code: '27855'
  },
  '27856': {
    country: 'US',
    city: 'Nashville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Nash',
    latitude: 35.9843,
    longitude: -77.9595,
    postal_code: '27856'
  },
  '27857': {
    country: 'US',
    city: 'Oak City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Martin',
    latitude: 35.9597,
    longitude: -77.3004,
    postal_code: '27857'
  },
  '27858': {
    country: 'US',
    city: 'Greenville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.5866,
    longitude: -77.3485,
    postal_code: '27858'
  },
  '27860': {
    country: 'US',
    city: 'Pantego',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Beaufort',
    latitude: 35.6201,
    longitude: -76.6988,
    postal_code: '27860'
  },
  '27861': {
    country: 'US',
    city: 'Parmele',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Martin',
    latitude: 35.8165,
    longitude: -77.3141,
    postal_code: '27861'
  },
  '27862': {
    country: 'US',
    city: 'Pendleton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.4937,
    longitude: -77.1911,
    postal_code: '27862'
  },
  '27863': {
    country: 'US',
    city: 'Pikeville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wayne',
    latitude: 35.4929,
    longitude: -77.9578,
    postal_code: '27863'
  },
  '27864': {
    country: 'US',
    city: 'Pinetops',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Edgecombe',
    latitude: 35.7885,
    longitude: -77.6378,
    postal_code: '27864'
  },
  '27865': {
    country: 'US',
    city: 'Pinetown',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Beaufort',
    latitude: 35.5758,
    longitude: -76.8076,
    postal_code: '27865'
  },
  '27866': {
    country: 'US',
    city: 'Pleasant Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.5212,
    longitude: -77.5192,
    postal_code: '27866'
  },
  '27867': {
    country: 'US',
    city: 'Potecasi',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.3632,
    longitude: -77.2391,
    postal_code: '27867'
  },
  '27868': {
    country: 'US',
    city: 'Red Oak',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Nash',
    latitude: 36.0548,
    longitude: -77.9116,
    postal_code: '27868'
  },
  '27869': {
    country: 'US',
    city: 'Rich Square',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.2773,
    longitude: -77.2969,
    postal_code: '27869'
  },
  '27870': {
    country: 'US',
    city: 'Roanoke Rapids',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Halifax',
    latitude: 36.4461,
    longitude: -77.6731,
    postal_code: '27870'
  },
  '27871': {
    country: 'US',
    city: 'Robersonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Martin',
    latitude: 35.8218,
    longitude: -77.26,
    postal_code: '27871'
  },
  '27872': {
    country: 'US',
    city: 'Roxobel',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bertie',
    latitude: 36.195,
    longitude: -77.2602,
    postal_code: '27872'
  },
  '27873': {
    country: 'US',
    city: 'Saratoga',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilson',
    latitude: 35.6538,
    longitude: -77.7755,
    postal_code: '27873'
  },
  '27874': {
    country: 'US',
    city: 'Scotland Neck',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Halifax',
    latitude: 36.1301,
    longitude: -77.4273,
    postal_code: '27874'
  },
  '27875': {
    country: 'US',
    city: 'Scranton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hyde',
    latitude: 35.4717,
    longitude: -76.4951,
    postal_code: '27875'
  },
  '27876': {
    country: 'US',
    city: 'Seaboard',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.4886,
    longitude: -77.4113,
    postal_code: '27876'
  },
  '27877': {
    country: 'US',
    city: 'Severn',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.5152,
    longitude: -77.1897,
    postal_code: '27877'
  },
  '27878': {
    country: 'US',
    city: 'Sharpsburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Nash',
    latitude: 35.8654,
    longitude: -77.8389,
    postal_code: '27878'
  },
  '27879': {
    country: 'US',
    city: 'Simpson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.5752,
    longitude: -77.2786,
    postal_code: '27879'
  },
  '27880': {
    country: 'US',
    city: 'Sims',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilson',
    latitude: 35.7435,
    longitude: -78.0859,
    postal_code: '27880'
  },
  '27881': {
    country: 'US',
    city: 'Speed',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Edgecombe',
    latitude: 35.9679,
    longitude: -77.4452,
    postal_code: '27881'
  },
  '27882': {
    country: 'US',
    city: 'Spring Hope',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Nash',
    latitude: 35.9302,
    longitude: -78.1085,
    postal_code: '27882'
  },
  '27883': {
    country: 'US',
    city: 'Stantonsburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilson',
    latitude: 35.594,
    longitude: -77.8378,
    postal_code: '27883'
  },
  '27884': {
    country: 'US',
    city: 'Stokes',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.7104,
    longitude: -77.2722,
    postal_code: '27884'
  },
  '27885': {
    country: 'US',
    city: 'Swanquarter',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hyde',
    latitude: 35.4222,
    longitude: -76.2875,
    postal_code: '27885'
  },
  '27886': {
    country: 'US',
    city: 'Tarboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Edgecombe',
    latitude: 35.8983,
    longitude: -77.5421,
    postal_code: '27886'
  },
  '27887': {
    country: 'US',
    city: 'Tillery',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Halifax',
    latitude: 36.2352,
    longitude: -77.5026,
    postal_code: '27887'
  },
  '27888': {
    country: 'US',
    city: 'Walstonburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Greene',
    latitude: 35.5882,
    longitude: -77.6983,
    postal_code: '27888'
  },
  '27889': {
    country: 'US',
    city: 'Washington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Beaufort',
    latitude: 35.5884,
    longitude: -77.1404,
    postal_code: '27889'
  },
  '27890': {
    country: 'US',
    city: 'Weldon',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Halifax',
    latitude: 36.4206,
    longitude: -77.6035,
    postal_code: '27890'
  },
  '27891': {
    country: 'US',
    city: 'Whitakers',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Nash',
    latitude: 36.0758,
    longitude: -77.7167,
    postal_code: '27891'
  },
  '27892': {
    country: 'US',
    city: 'Williamston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Martin',
    latitude: 35.8212,
    longitude: -77.1022,
    postal_code: '27892'
  },
  '27893': {
    country: 'US',
    city: 'Wilson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilson',
    latitude: 35.727,
    longitude: -77.9227,
    postal_code: '27893'
  },
  '27894': {
    country: 'US',
    city: 'Wilson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilson',
    latitude: 35.7158,
    longitude: -77.9043,
    postal_code: '27894'
  },
  '27895': {
    country: 'US',
    city: 'Wilson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilson',
    latitude: 35.7199,
    longitude: -77.9267,
    postal_code: '27895'
  },
  '27896': {
    country: 'US',
    city: 'Wilson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilson',
    latitude: 35.7715,
    longitude: -77.973,
    postal_code: '27896'
  },
  '27897': {
    country: 'US',
    city: 'Woodland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Northampton',
    latitude: 36.325,
    longitude: -77.2107,
    postal_code: '27897'
  },
  '27906': {
    country: 'US',
    city: 'Elizabeth City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pasquotank',
    latitude: 36.2854,
    longitude: -76.2133,
    postal_code: '27906'
  },
  '27907': {
    country: 'US',
    city: 'Elizabeth City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pasquotank',
    latitude: 36.285,
    longitude: -76.2553,
    postal_code: '27907'
  },
  '27909': {
    country: 'US',
    city: 'Elizabeth City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pasquotank',
    latitude: 36.2951,
    longitude: -76.2445,
    postal_code: '27909'
  },
  '27910': {
    country: 'US',
    city: 'Ahoskie',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hertford',
    latitude: 36.2957,
    longitude: -76.9966,
    postal_code: '27910'
  },
  '27915': {
    country: 'US',
    city: 'Avon',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.3521,
    longitude: -75.5104,
    postal_code: '27915'
  },
  '27916': {
    country: 'US',
    city: 'Aydlett',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.3045,
    longitude: -75.9029,
    postal_code: '27916'
  },
  '27917': {
    country: 'US',
    city: 'Barco',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.3588,
    longitude: -75.9793,
    postal_code: '27917'
  },
  '27919': {
    country: 'US',
    city: 'Belvidere',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Perquimans',
    latitude: 36.3096,
    longitude: -76.5436,
    postal_code: '27919'
  },
  '27920': {
    country: 'US',
    city: 'Buxton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.2677,
    longitude: -75.5424,
    postal_code: '27920'
  },
  '27921': {
    country: 'US',
    city: 'Camden',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Camden',
    latitude: 36.325,
    longitude: -76.15,
    postal_code: '27921'
  },
  '27922': {
    country: 'US',
    city: 'Cofield',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hertford',
    latitude: 36.3333,
    longitude: -76.8746,
    postal_code: '27922'
  },
  '27923': {
    country: 'US',
    city: 'Coinjock',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.3751,
    longitude: -75.934,
    postal_code: '27923'
  },
  '27924': {
    country: 'US',
    city: 'Colerain',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bertie',
    latitude: 36.1901,
    longitude: -76.8548,
    postal_code: '27924'
  },
  '27925': {
    country: 'US',
    city: 'Columbia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Tyrrell',
    latitude: 35.9057,
    longitude: -76.2345,
    postal_code: '27925'
  },
  '27926': {
    country: 'US',
    city: 'Corapeake',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gates',
    latitude: 36.5179,
    longitude: -76.5979,
    postal_code: '27926'
  },
  '27927': {
    country: 'US',
    city: 'Corolla',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.3206,
    longitude: -75.8132,
    postal_code: '27927'
  },
  '27928': {
    country: 'US',
    city: 'Creswell',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Washington',
    latitude: 35.8653,
    longitude: -76.4196,
    postal_code: '27928'
  },
  '27929': {
    country: 'US',
    city: 'Currituck',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.4398,
    longitude: -76.0055,
    postal_code: '27929'
  },
  '27930': {
    country: 'US',
    city: 'Hertford',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Perquimans',
    latitude: 36.1902,
    longitude: -76.4661,
    postal_code: '27930'
  },
  '27932': {
    country: 'US',
    city: 'Edenton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chowan',
    latitude: 36.0908,
    longitude: -76.6224,
    postal_code: '27932'
  },
  '27935': {
    country: 'US',
    city: 'Eure',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gates',
    latitude: 36.4341,
    longitude: -76.8463,
    postal_code: '27935'
  },
  '27936': {
    country: 'US',
    city: 'Frisco',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.2338,
    longitude: -75.6145,
    postal_code: '27936'
  },
  '27937': {
    country: 'US',
    city: 'Gates',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gates',
    latitude: 36.5036,
    longitude: -76.7646,
    postal_code: '27937'
  },
  '27938': {
    country: 'US',
    city: 'Gatesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gates',
    latitude: 36.4072,
    longitude: -76.7325,
    postal_code: '27938'
  },
  '27939': {
    country: 'US',
    city: 'Grandy',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.2339,
    longitude: -75.8768,
    postal_code: '27939'
  },
  '27941': {
    country: 'US',
    city: 'Harbinger',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.1025,
    longitude: -75.8169,
    postal_code: '27941'
  },
  '27942': {
    country: 'US',
    city: 'Harrellsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hertford',
    latitude: 36.2866,
    longitude: -76.7767,
    postal_code: '27942'
  },
  '27943': {
    country: 'US',
    city: 'Hatteras',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.2193,
    longitude: -75.6902,
    postal_code: '27943'
  },
  '27944': {
    country: 'US',
    city: 'Hertford',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Perquimans',
    latitude: 36.1685,
    longitude: -76.3731,
    postal_code: '27944'
  },
  '27946': {
    country: 'US',
    city: 'Hobbsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gates',
    latitude: 36.3543,
    longitude: -76.6178,
    postal_code: '27946'
  },
  '27947': {
    country: 'US',
    city: 'Jarvisburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.1714,
    longitude: -75.8621,
    postal_code: '27947'
  },
  '27948': {
    country: 'US',
    city: 'Kill Devil Hills',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 36.0088,
    longitude: -75.6757,
    postal_code: '27948'
  },
  '27949': {
    country: 'US',
    city: 'Kitty Hawk',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 36.0646,
    longitude: -75.7057,
    postal_code: '27949'
  },
  '27950': {
    country: 'US',
    city: 'Knotts Island',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.5232,
    longitude: -75.9702,
    postal_code: '27950'
  },
  '27953': {
    country: 'US',
    city: 'Manns Harbor',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.8238,
    longitude: -75.8467,
    postal_code: '27953'
  },
  '27954': {
    country: 'US',
    city: 'Manteo',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.8948,
    longitude: -75.6714,
    postal_code: '27954'
  },
  '27956': {
    country: 'US',
    city: 'Maple',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.3987,
    longitude: -76.0039,
    postal_code: '27956'
  },
  '27957': {
    country: 'US',
    city: 'Merry Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bertie',
    latitude: 36.0871,
    longitude: -76.7776,
    postal_code: '27957'
  },
  '27958': {
    country: 'US',
    city: 'Moyock',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.4871,
    longitude: -76.1146,
    postal_code: '27958'
  },
  '27959': {
    country: 'US',
    city: 'Nags Head',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.8865,
    longitude: -75.6038,
    postal_code: '27959'
  },
  '27960': {
    country: 'US',
    city: 'Ocracoke',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hyde',
    latitude: 35.1397,
    longitude: -75.8931,
    postal_code: '27960'
  },
  '27962': {
    country: 'US',
    city: 'Plymouth',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Washington',
    latitude: 35.8508,
    longitude: -76.7431,
    postal_code: '27962'
  },
  '27964': {
    country: 'US',
    city: 'Point Harbor',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.0781,
    longitude: -75.7983,
    postal_code: '27964'
  },
  '27965': {
    country: 'US',
    city: 'Poplar Branch',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.2737,
    longitude: -75.9097,
    postal_code: '27965'
  },
  '27966': {
    country: 'US',
    city: 'Powells Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.1207,
    longitude: -75.8273,
    postal_code: '27966'
  },
  '27967': {
    country: 'US',
    city: 'Powellsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bertie',
    latitude: 36.2257,
    longitude: -76.933,
    postal_code: '27967'
  },
  '27968': {
    country: 'US',
    city: 'Rodanthe',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.5935,
    longitude: -75.4679,
    postal_code: '27968'
  },
  '27969': {
    country: 'US',
    city: 'Roduco',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gates',
    latitude: 36.4618,
    longitude: -76.8125,
    postal_code: '27969'
  },
  '27970': {
    country: 'US',
    city: 'Roper',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Washington',
    latitude: 35.8994,
    longitude: -76.5809,
    postal_code: '27970'
  },
  '27972': {
    country: 'US',
    city: 'Salvo',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.5546,
    longitude: -75.4693,
    postal_code: '27972'
  },
  '27973': {
    country: 'US',
    city: 'Shawboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Currituck',
    latitude: 36.3779,
    longitude: -76.0945,
    postal_code: '27973'
  },
  '27974': {
    country: 'US',
    city: 'Shiloh',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Camden',
    latitude: 36.2584,
    longitude: -76.0432,
    postal_code: '27974'
  },
  '27976': {
    country: 'US',
    city: 'South Mills',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Camden',
    latitude: 36.4536,
    longitude: -76.3033,
    postal_code: '27976'
  },
  '27978': {
    country: 'US',
    city: 'Stumpy Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.6985,
    longitude: -75.7728,
    postal_code: '27978'
  },
  '27979': {
    country: 'US',
    city: 'Sunbury',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gates',
    latitude: 36.4316,
    longitude: -76.6096,
    postal_code: '27979'
  },
  '27980': {
    country: 'US',
    city: 'Tyner',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Chowan',
    latitude: 36.2502,
    longitude: -76.6428,
    postal_code: '27980'
  },
  '27981': {
    country: 'US',
    city: 'Wanchese',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.8622,
    longitude: -75.6437,
    postal_code: '27981'
  },
  '27982': {
    country: 'US',
    city: 'Waves',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Dare',
    latitude: 35.565,
    longitude: -75.4663,
    postal_code: '27982'
  },
  '27983': {
    country: 'US',
    city: 'Windsor',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bertie',
    latitude: 36.0159,
    longitude: -76.9336,
    postal_code: '27983'
  },
  '27985': {
    country: 'US',
    city: 'Winfall',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Perquimans',
    latitude: 36.2193,
    longitude: -76.4667,
    postal_code: '27985'
  },
  '27986': {
    country: 'US',
    city: 'Winton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hertford',
    latitude: 36.3826,
    longitude: -76.936,
    postal_code: '27986'
  },
  '28001': {
    country: 'US',
    city: 'Albemarle',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stanly',
    latitude: 35.3573,
    longitude: -80.2044,
    postal_code: '28001'
  },
  '28002': {
    country: 'US',
    city: 'Albemarle',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stanly',
    latitude: 35.3501,
    longitude: -80.2001,
    postal_code: '28002'
  },
  '28006': {
    country: 'US',
    city: 'Alexis',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.4069,
    longitude: -81.0947,
    postal_code: '28006'
  },
  '28007': {
    country: 'US',
    city: 'Ansonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Anson',
    latitude: 35.1046,
    longitude: -80.1092,
    postal_code: '28007'
  },
  '28009': {
    country: 'US',
    city: 'Badin',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stanly',
    latitude: 35.406,
    longitude: -80.1167,
    postal_code: '28009'
  },
  '28010': {
    country: 'US',
    city: 'Barium Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.7192,
    longitude: -80.8991,
    postal_code: '28010'
  },
  '28012': {
    country: 'US',
    city: 'Belmont',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.244,
    longitude: -81.044,
    postal_code: '28012'
  },
  '28016': {
    country: 'US',
    city: 'Bessemer City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.2849,
    longitude: -81.2863,
    postal_code: '28016'
  },
  '28017': {
    country: 'US',
    city: 'Boiling Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.2543,
    longitude: -81.667,
    postal_code: '28017'
  },
  '28018': {
    country: 'US',
    city: 'Bostic',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.4533,
    longitude: -81.8118,
    postal_code: '28018'
  },
  '28019': {
    country: 'US',
    city: 'Caroleen',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.281,
    longitude: -81.7962,
    postal_code: '28019'
  },
  '28020': {
    country: 'US',
    city: 'Casar',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.5145,
    longitude: -81.6357,
    postal_code: '28020'
  },
  '28021': {
    country: 'US',
    city: 'Cherryville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.3747,
    longitude: -81.3509,
    postal_code: '28021'
  },
  '28023': {
    country: 'US',
    city: 'China Grove',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.5669,
    longitude: -80.59,
    postal_code: '28023'
  },
  '28024': {
    country: 'US',
    city: 'Cliffside',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.2416,
    longitude: -81.7755,
    postal_code: '28024'
  },
  '28025': {
    country: 'US',
    city: 'Concord',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cabarrus',
    latitude: 35.3716,
    longitude: -80.53,
    postal_code: '28025'
  },
  '28026': {
    country: 'US',
    city: 'Concord',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cabarrus',
    latitude: 35.3463,
    longitude: -80.5411,
    postal_code: '28026'
  },
  '28027': {
    country: 'US',
    city: 'Concord',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cabarrus',
    latitude: 35.4141,
    longitude: -80.6162,
    postal_code: '28027'
  },
  '28031': {
    country: 'US',
    city: 'Cornelius',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.4733,
    longitude: -80.8726,
    postal_code: '28031'
  },
  '28032': {
    country: 'US',
    city: 'Cramerton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.2396,
    longitude: -81.0831,
    postal_code: '28032'
  },
  '28033': {
    country: 'US',
    city: 'Crouse',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lincoln',
    latitude: 35.421,
    longitude: -81.3054,
    postal_code: '28033'
  },
  '28034': {
    country: 'US',
    city: 'Dallas',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.3349,
    longitude: -81.1862,
    postal_code: '28034'
  },
  '28035': {
    country: 'US',
    city: 'Davidson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.4993,
    longitude: -80.8486,
    postal_code: '28035'
  },
  '28036': {
    country: 'US',
    city: 'Davidson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.4858,
    longitude: -80.794,
    postal_code: '28036'
  },
  '28037': {
    country: 'US',
    city: 'Denver',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lincoln',
    latitude: 35.4837,
    longitude: -80.9898,
    postal_code: '28037'
  },
  '28038': {
    country: 'US',
    city: 'Earl',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.197,
    longitude: -81.5386,
    postal_code: '28038'
  },
  '28039': {
    country: 'US',
    city: 'East Spencer',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.6805,
    longitude: -80.4354,
    postal_code: '28039'
  },
  '28040': {
    country: 'US',
    city: 'Ellenboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.3344,
    longitude: -81.7707,
    postal_code: '28040'
  },
  '28041': {
    country: 'US',
    city: 'Faith',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.5817,
    longitude: -80.4581,
    postal_code: '28041'
  },
  '28042': {
    country: 'US',
    city: 'Fallston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.429,
    longitude: -81.5015,
    postal_code: '28042'
  },
  '28043': {
    country: 'US',
    city: 'Forest City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.325,
    longitude: -81.846,
    postal_code: '28043'
  },
  '28052': {
    country: 'US',
    city: 'Gastonia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.2449,
    longitude: -81.2194,
    postal_code: '28052'
  },
  '28053': {
    country: 'US',
    city: 'Gastonia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.2751,
    longitude: -81.2134,
    postal_code: '28053'
  },
  '28054': {
    country: 'US',
    city: 'Gastonia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.2495,
    longitude: -81.133,
    postal_code: '28054'
  },
  '28055': {
    country: 'US',
    city: 'Gastonia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.284,
    longitude: -81.1897,
    postal_code: '28055'
  },
  '28056': {
    country: 'US',
    city: 'Gastonia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.2172,
    longitude: -81.1252,
    postal_code: '28056'
  },
  '28070': {
    country: 'US',
    city: 'Huntersville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.4107,
    longitude: -80.8429,
    postal_code: '28070'
  },
  '28071': {
    country: 'US',
    city: 'Gold Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.5498,
    longitude: -80.3346,
    postal_code: '28071'
  },
  '28072': {
    country: 'US',
    city: 'Granite Quarry',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.6109,
    longitude: -80.4361,
    postal_code: '28072'
  },
  '28073': {
    country: 'US',
    city: 'Grover',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.1836,
    longitude: -81.4552,
    postal_code: '28073'
  },
  '28074': {
    country: 'US',
    city: 'Harris',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.2247,
    longitude: -81.8851,
    postal_code: '28074'
  },
  '28075': {
    country: 'US',
    city: 'Harrisburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cabarrus',
    latitude: 35.3247,
    longitude: -80.6594,
    postal_code: '28075'
  },
  '28076': {
    country: 'US',
    city: 'Henrietta',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.2579,
    longitude: -81.7962,
    postal_code: '28076'
  },
  '28077': {
    country: 'US',
    city: 'High Shoals',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.4041,
    longitude: -81.2029,
    postal_code: '28077'
  },
  '28078': {
    country: 'US',
    city: 'Huntersville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.4011,
    longitude: -80.8695,
    postal_code: '28078'
  },
  '28079': {
    country: 'US',
    city: 'Indian Trail',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Union',
    latitude: 35.0831,
    longitude: -80.6597,
    postal_code: '28079'
  },
  '28080': {
    country: 'US',
    city: 'Iron Station',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lincoln',
    latitude: 35.4657,
    longitude: -81.107,
    postal_code: '28080'
  },
  '28081': {
    country: 'US',
    city: 'Kannapolis',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cabarrus',
    latitude: 35.502,
    longitude: -80.6359,
    postal_code: '28081'
  },
  '28082': {
    country: 'US',
    city: 'Kannapolis',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cabarrus',
    latitude: 35.3463,
    longitude: -80.5411,
    postal_code: '28082'
  },
  '28083': {
    country: 'US',
    city: 'Kannapolis',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cabarrus',
    latitude: 35.4848,
    longitude: -80.6015,
    postal_code: '28083'
  },
  '28086': {
    country: 'US',
    city: 'Kings Mountain',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.2516,
    longitude: -81.3806,
    postal_code: '28086'
  },
  '28088': {
    country: 'US',
    city: 'Landis',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.5435,
    longitude: -80.6129,
    postal_code: '28088'
  },
  '28089': {
    country: 'US',
    city: 'Lattimore',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.3182,
    longitude: -81.6607,
    postal_code: '28089'
  },
  '28090': {
    country: 'US',
    city: 'Lawndale',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.4449,
    longitude: -81.5336,
    postal_code: '28090'
  },
  '28091': {
    country: 'US',
    city: 'Lilesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Anson',
    latitude: 34.9689,
    longitude: -79.9721,
    postal_code: '28091'
  },
  '28092': {
    country: 'US',
    city: 'Lincolnton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lincoln',
    latitude: 35.4851,
    longitude: -81.1818,
    postal_code: '28092'
  },
  '28093': {
    country: 'US',
    city: 'Lincolnton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lincoln',
    latitude: 35.4848,
    longitude: -81.2395,
    postal_code: '28093'
  },
  '28097': {
    country: 'US',
    city: 'Locust',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stanly',
    latitude: 35.2704,
    longitude: -80.4211,
    postal_code: '28097'
  },
  '28098': {
    country: 'US',
    city: 'Lowell',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.2655,
    longitude: -81.096,
    postal_code: '28098'
  },
  '28101': {
    country: 'US',
    city: 'Mc Adenville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.2578,
    longitude: -81.0788,
    postal_code: '28101'
  },
  '28102': {
    country: 'US',
    city: 'Mc Farlan',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Anson',
    latitude: 34.8136,
    longitude: -79.9776,
    postal_code: '28102'
  },
  '28103': {
    country: 'US',
    city: 'Marshville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Union',
    latitude: 35.0167,
    longitude: -80.3781,
    postal_code: '28103'
  },
  '28104': {
    country: 'US',
    city: 'Matthews',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.0604,
    longitude: -80.6958,
    postal_code: '28104'
  },
  '28105': {
    country: 'US',
    city: 'Matthews',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1149,
    longitude: -80.705,
    postal_code: '28105'
  },
  '28106': {
    country: 'US',
    city: 'Matthews',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28106'
  },
  '28107': {
    country: 'US',
    city: 'Midland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cabarrus',
    latitude: 35.2477,
    longitude: -80.5319,
    postal_code: '28107'
  },
  '28108': {
    country: 'US',
    city: 'Mineral Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Union',
    latitude: 34.9379,
    longitude: -80.6687,
    postal_code: '28108'
  },
  '28109': {
    country: 'US',
    city: 'Misenheimer',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stanly',
    latitude: 35.4849,
    longitude: -80.2884,
    postal_code: '28109'
  },
  '28110': {
    country: 'US',
    city: 'Monroe',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Union',
    latitude: 35.0178,
    longitude: -80.5372,
    postal_code: '28110'
  },
  '28111': {
    country: 'US',
    city: 'Monroe',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Union',
    latitude: 35.0112,
    longitude: -80.5587,
    postal_code: '28111'
  },
  '28112': {
    country: 'US',
    city: 'Monroe',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Union',
    latitude: 34.8946,
    longitude: -80.554,
    postal_code: '28112'
  },
  '28114': {
    country: 'US',
    city: 'Mooresboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.2295,
    longitude: -81.7492,
    postal_code: '28114'
  },
  '28115': {
    country: 'US',
    city: 'Mooresville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.5774,
    longitude: -80.8226,
    postal_code: '28115'
  },
  '28117': {
    country: 'US',
    city: 'Mooresville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.584,
    longitude: -80.8685,
    postal_code: '28117'
  },
  '28119': {
    country: 'US',
    city: 'Morven',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Anson',
    latitude: 34.8511,
    longitude: -80.0025,
    postal_code: '28119'
  },
  '28120': {
    country: 'US',
    city: 'Mount Holly',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.3119,
    longitude: -81.0306,
    postal_code: '28120'
  },
  '28123': {
    country: 'US',
    city: 'Mount Mourne',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.5432,
    longitude: -80.8473,
    postal_code: '28123'
  },
  '28124': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cabarrus',
    latitude: 35.4146,
    longitude: -80.4171,
    postal_code: '28124'
  },
  '28125': {
    country: 'US',
    city: 'Mount Ulla',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.6389,
    longitude: -80.7239,
    postal_code: '28125'
  },
  '28126': {
    country: 'US',
    city: 'Newell',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2768,
    longitude: -80.7165,
    postal_code: '28126'
  },
  '28127': {
    country: 'US',
    city: 'New London',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stanly',
    latitude: 35.4285,
    longitude: -80.2057,
    postal_code: '28127'
  },
  '28128': {
    country: 'US',
    city: 'Norwood',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stanly',
    latitude: 35.2275,
    longitude: -80.1433,
    postal_code: '28128'
  },
  '28129': {
    country: 'US',
    city: 'Oakboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stanly',
    latitude: 35.246,
    longitude: -80.3413,
    postal_code: '28129'
  },
  '28130': {
    country: 'US',
    city: 'Paw Creek',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28130'
  },
  '28133': {
    country: 'US',
    city: 'Peachland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Anson',
    latitude: 35.0054,
    longitude: -80.2829,
    postal_code: '28133'
  },
  '28134': {
    country: 'US',
    city: 'Pineville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.0709,
    longitude: -80.8859,
    postal_code: '28134'
  },
  '28135': {
    country: 'US',
    city: 'Polkton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Anson',
    latitude: 34.9823,
    longitude: -80.1538,
    postal_code: '28135'
  },
  '28136': {
    country: 'US',
    city: 'Polkville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.3961,
    longitude: -81.6303,
    postal_code: '28136'
  },
  '28137': {
    country: 'US',
    city: 'Richfield',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stanly',
    latitude: 35.4541,
    longitude: -80.2838,
    postal_code: '28137'
  },
  '28138': {
    country: 'US',
    city: 'Rockwell',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.5494,
    longitude: -80.4226,
    postal_code: '28138'
  },
  '28139': {
    country: 'US',
    city: 'Rutherfordton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.3706,
    longitude: -81.9781,
    postal_code: '28139'
  },
  '28144': {
    country: 'US',
    city: 'Salisbury',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.6515,
    longitude: -80.4889,
    postal_code: '28144'
  },
  '28145': {
    country: 'US',
    city: 'Salisbury',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.6827,
    longitude: -80.4771,
    postal_code: '28145'
  },
  '28146': {
    country: 'US',
    city: 'Salisbury',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.6187,
    longitude: -80.4022,
    postal_code: '28146'
  },
  '28147': {
    country: 'US',
    city: 'Salisbury',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.6817,
    longitude: -80.5615,
    postal_code: '28147'
  },
  '28150': {
    country: 'US',
    city: 'Shelby',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.312,
    longitude: -81.5568,
    postal_code: '28150'
  },
  '28151': {
    country: 'US',
    city: 'Shelby',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.2924,
    longitude: -81.5356,
    postal_code: '28151'
  },
  '28152': {
    country: 'US',
    city: 'Shelby',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.2689,
    longitude: -81.5337,
    postal_code: '28152'
  },
  '28159': {
    country: 'US',
    city: 'Spencer',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rowan',
    latitude: 35.6917,
    longitude: -80.4327,
    postal_code: '28159'
  },
  '28160': {
    country: 'US',
    city: 'Spindale',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.3601,
    longitude: -81.9251,
    postal_code: '28160'
  },
  '28163': {
    country: 'US',
    city: 'Stanfield',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Stanly',
    latitude: 35.2106,
    longitude: -80.4407,
    postal_code: '28163'
  },
  '28164': {
    country: 'US',
    city: 'Stanley',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Gaston',
    latitude: 35.3516,
    longitude: -81.0959,
    postal_code: '28164'
  },
  '28166': {
    country: 'US',
    city: 'Troutman',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.6863,
    longitude: -80.8822,
    postal_code: '28166'
  },
  '28167': {
    country: 'US',
    city: 'Union Mills',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.4732,
    longitude: -81.9685,
    postal_code: '28167'
  },
  '28168': {
    country: 'US',
    city: 'Vale',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lincoln',
    latitude: 35.5188,
    longitude: -81.4589,
    postal_code: '28168'
  },
  '28169': {
    country: 'US',
    city: 'Waco',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cleveland',
    latitude: 35.3615,
    longitude: -81.4287,
    postal_code: '28169'
  },
  '28170': {
    country: 'US',
    city: 'Wadesboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Anson',
    latitude: 34.9809,
    longitude: -80.0696,
    postal_code: '28170'
  },
  '28173': {
    country: 'US',
    city: 'Waxhaw',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Union',
    latitude: 34.9251,
    longitude: -80.7278,
    postal_code: '28173'
  },
  '28174': {
    country: 'US',
    city: 'Wingate',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Union',
    latitude: 34.9847,
    longitude: -80.4476,
    postal_code: '28174'
  },
  '28201': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28201'
  },
  '28202': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.229,
    longitude: -80.8419,
    postal_code: '28202'
  },
  '28203': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2081,
    longitude: -80.8583,
    postal_code: '28203'
  },
  '28204': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2132,
    longitude: -80.8231,
    postal_code: '28204'
  },
  '28205': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.22,
    longitude: -80.7881,
    postal_code: '28205'
  },
  '28206': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2522,
    longitude: -80.8265,
    postal_code: '28206'
  },
  '28207': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1935,
    longitude: -80.8272,
    postal_code: '28207'
  },
  '28208': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2358,
    longitude: -80.8964,
    postal_code: '28208'
  },
  '28209': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1796,
    longitude: -80.8559,
    postal_code: '28209'
  },
  '28210': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1316,
    longitude: -80.8577,
    postal_code: '28210'
  },
  '28211': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1677,
    longitude: -80.7932,
    postal_code: '28211'
  },
  '28212': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1908,
    longitude: -80.7448,
    postal_code: '28212'
  },
  '28213': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2836,
    longitude: -80.7638,
    postal_code: '28213'
  },
  '28214': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2731,
    longitude: -80.9571,
    postal_code: '28214'
  },
  '28215': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.244,
    longitude: -80.7387,
    postal_code: '28215'
  },
  '28216': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2834,
    longitude: -80.8702,
    postal_code: '28216'
  },
  '28217': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1714,
    longitude: -80.9084,
    postal_code: '28217'
  },
  '28218': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28218'
  },
  '28219': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28219'
  },
  '28220': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28220'
  },
  '28221': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28221'
  },
  '28222': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28222'
  },
  '28223': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.3041,
    longitude: -80.7267,
    postal_code: '28223'
  },
  '28224': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28224'
  },
  '28226': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.0869,
    longitude: -80.8167,
    postal_code: '28226'
  },
  '28227': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1936,
    longitude: -80.6846,
    postal_code: '28227'
  },
  '28228': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28228'
  },
  '28229': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28229'
  },
  '28230': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28230'
  },
  '28231': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28231'
  },
  '28232': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28232'
  },
  '28233': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28233'
  },
  '28234': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28234'
  },
  '28235': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28235'
  },
  '28236': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28236'
  },
  '28237': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28237'
  },
  '28241': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28241'
  },
  '28242': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28242'
  },
  '28243': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28243'
  },
  '28244': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28244'
  },
  '28246': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2275,
    longitude: -80.8425,
    postal_code: '28246'
  },
  '28247': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.0656,
    longitude: -80.8511,
    postal_code: '28247'
  },
  '28250': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28250'
  },
  '28253': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28253'
  },
  '28254': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28254'
  },
  '28255': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28255'
  },
  '28256': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28256'
  },
  '28258': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28258'
  },
  '28260': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28260'
  },
  '28262': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.3183,
    longitude: -80.7476,
    postal_code: '28262'
  },
  '28263': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2268,
    longitude: -80.8432,
    postal_code: '28263'
  },
  '28265': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28265'
  },
  '28266': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28266'
  },
  '28269': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2886,
    longitude: -80.8209,
    postal_code: '28269'
  },
  '28270': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1355,
    longitude: -80.7669,
    postal_code: '28270'
  },
  '28271': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 35.216,
    longitude: -80.8358,
    postal_code: '28271'
  },
  '28272': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28272'
  },
  '28273': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1287,
    longitude: -80.9338,
    postal_code: '28273'
  },
  '28274': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.1879,
    longitude: -80.8317,
    postal_code: '28274'
  },
  '28275': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28275'
  },
  '28277': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.0552,
    longitude: -80.8195,
    postal_code: '28277'
  },
  '28278': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2072,
    longitude: -80.9568,
    postal_code: '28278'
  },
  '28280': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28280'
  },
  '28281': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28281'
  },
  '28282': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2242,
    longitude: -80.8447,
    postal_code: '28282'
  },
  '28284': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28284'
  },
  '28285': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28285'
  },
  '28287': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28287'
  },
  '28288': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28288'
  },
  '28289': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2271,
    longitude: -80.8431,
    postal_code: '28289'
  },
  '28290': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28290'
  },
  '28296': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.2252,
    longitude: -80.8458,
    postal_code: '28296'
  },
  '28297': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28297'
  },
  '28299': {
    country: 'US',
    city: 'Charlotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mecklenburg',
    latitude: 35.26,
    longitude: -80.8042,
    postal_code: '28299'
  },
  '28301': {
    country: 'US',
    city: 'Fayetteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0743,
    longitude: -78.8836,
    postal_code: '28301'
  },
  '28302': {
    country: 'US',
    city: 'Fayetteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0343,
    longitude: -78.9088,
    postal_code: '28302'
  },
  '28303': {
    country: 'US',
    city: 'Fayetteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0742,
    longitude: -78.965,
    postal_code: '28303'
  },
  '28304': {
    country: 'US',
    city: 'Fayetteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0257,
    longitude: -78.9705,
    postal_code: '28304'
  },
  '28305': {
    country: 'US',
    city: 'Fayetteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.056,
    longitude: -78.9047,
    postal_code: '28305'
  },
  '28306': {
    country: 'US',
    city: 'Fayetteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0019,
    longitude: -78.9364,
    postal_code: '28306'
  },
  '28307': {
    country: 'US',
    city: 'Fort Bragg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.1416,
    longitude: -79.0025,
    postal_code: '28307'
  },
  '28308': {
    country: 'US',
    city: 'Pope Army Airfield',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0635,
    longitude: -78.8943,
    postal_code: '28308'
  },
  '28309': {
    country: 'US',
    city: 'Fayetteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0397,
    longitude: -78.8429,
    postal_code: '28309'
  },
  '28310': {
    country: 'US',
    city: 'Fort Bragg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0506,
    longitude: -78.8038,
    postal_code: '28310'
  },
  '28311': {
    country: 'US',
    city: 'Fayetteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.1294,
    longitude: -78.8982,
    postal_code: '28311'
  },
  '28312': {
    country: 'US',
    city: 'Fayetteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 34.9549,
    longitude: -78.7408,
    postal_code: '28312'
  },
  '28314': {
    country: 'US',
    city: 'Fayetteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0583,
    longitude: -79.008,
    postal_code: '28314'
  },
  '28315': {
    country: 'US',
    city: 'Aberdeen',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.1216,
    longitude: -79.445,
    postal_code: '28315'
  },
  '28318': {
    country: 'US',
    city: 'Autryville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Sampson',
    latitude: 35.0997,
    longitude: -78.6021,
    postal_code: '28318'
  },
  '28319': {
    country: 'US',
    city: 'Barnesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.4088,
    longitude: -79.0467,
    postal_code: '28319'
  },
  '28320': {
    country: 'US',
    city: 'Bladenboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bladen',
    latitude: 34.5658,
    longitude: -78.7793,
    postal_code: '28320'
  },
  '28323': {
    country: 'US',
    city: 'Bunnlevel',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.3119,
    longitude: -78.8405,
    postal_code: '28323'
  },
  '28325': {
    country: 'US',
    city: 'Calypso',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 35.1538,
    longitude: -78.1053,
    postal_code: '28325'
  },
  '28326': {
    country: 'US',
    city: 'Cameron',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.3106,
    longitude: -79.3472,
    postal_code: '28326'
  },
  '28327': {
    country: 'US',
    city: 'Carthage',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.3061,
    longitude: -79.3969,
    postal_code: '28327'
  },
  '28328': {
    country: 'US',
    city: 'Clinton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Sampson',
    latitude: 35.0151,
    longitude: -78.326,
    postal_code: '28328'
  },
  '28329': {
    country: 'US',
    city: 'Clinton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Sampson',
    latitude: 34.9979,
    longitude: -78.3233,
    postal_code: '28329'
  },
  '28330': {
    country: 'US',
    city: 'Cordova',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Richmond',
    latitude: 34.9129,
    longitude: -79.822,
    postal_code: '28330'
  },
  '28331': {
    country: 'US',
    city: 'Cumberland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0049,
    longitude: -78.9673,
    postal_code: '28331'
  },
  '28332': {
    country: 'US',
    city: 'Dublin',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bladen',
    latitude: 34.6568,
    longitude: -78.7264,
    postal_code: '28332'
  },
  '28333': {
    country: 'US',
    city: 'Dudley',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wayne',
    latitude: 35.2926,
    longitude: -78.0273,
    postal_code: '28333'
  },
  '28334': {
    country: 'US',
    city: 'Dunn',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.3165,
    longitude: -78.6151,
    postal_code: '28334'
  },
  '28335': {
    country: 'US',
    city: 'Dunn',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.3063,
    longitude: -78.6089,
    postal_code: '28335'
  },
  '28337': {
    country: 'US',
    city: 'Elizabethtown',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bladen',
    latitude: 34.6471,
    longitude: -78.5747,
    postal_code: '28337'
  },
  '28338': {
    country: 'US',
    city: 'Ellerbe',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Richmond',
    latitude: 35.0914,
    longitude: -79.7524,
    postal_code: '28338'
  },
  '28339': {
    country: 'US',
    city: 'Erwin',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.3287,
    longitude: -78.6859,
    postal_code: '28339'
  },
  '28340': {
    country: 'US',
    city: 'Fairmont',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.472,
    longitude: -79.1407,
    postal_code: '28340'
  },
  '28341': {
    country: 'US',
    city: 'Faison',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 35.1199,
    longitude: -78.118,
    postal_code: '28341'
  },
  '28342': {
    country: 'US',
    city: 'Falcon',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.1899,
    longitude: -78.6483,
    postal_code: '28342'
  },
  '28343': {
    country: 'US',
    city: 'Gibson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Scotland',
    latitude: 34.7549,
    longitude: -79.5839,
    postal_code: '28343'
  },
  '28344': {
    country: 'US',
    city: 'Godwin',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.1969,
    longitude: -78.6625,
    postal_code: '28344'
  },
  '28345': {
    country: 'US',
    city: 'Hamlet',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Richmond',
    latitude: 34.8894,
    longitude: -79.7022,
    postal_code: '28345'
  },
  '28347': {
    country: 'US',
    city: 'Hoffman',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Richmond',
    latitude: 35.0326,
    longitude: -79.56,
    postal_code: '28347'
  },
  '28348': {
    country: 'US',
    city: 'Hope Mills',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 34.9536,
    longitude: -78.9354,
    postal_code: '28348'
  },
  '28349': {
    country: 'US',
    city: 'Kenansville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 35.0152,
    longitude: -77.8969,
    postal_code: '28349'
  },
  '28350': {
    country: 'US',
    city: 'Lakeview',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.2438,
    longitude: -79.3086,
    postal_code: '28350'
  },
  '28351': {
    country: 'US',
    city: 'Laurel Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Scotland',
    latitude: 34.8238,
    longitude: -79.5491,
    postal_code: '28351'
  },
  '28352': {
    country: 'US',
    city: 'Laurinburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Scotland',
    latitude: 34.7599,
    longitude: -79.4673,
    postal_code: '28352'
  },
  '28353': {
    country: 'US',
    city: 'Laurinburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Scotland',
    latitude: 34.7818,
    longitude: -79.4824,
    postal_code: '28353'
  },
  '28355': {
    country: 'US',
    city: 'Lemon Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lee',
    latitude: 35.3896,
    longitude: -79.1945,
    postal_code: '28355'
  },
  '28356': {
    country: 'US',
    city: 'Linden',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.2276,
    longitude: -78.8004,
    postal_code: '28356'
  },
  '28357': {
    country: 'US',
    city: 'Lumber Bridge',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.8762,
    longitude: -79.0664,
    postal_code: '28357'
  },
  '28358': {
    country: 'US',
    city: 'Lumberton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.6293,
    longitude: -79.0083,
    postal_code: '28358'
  },
  '28359': {
    country: 'US',
    city: 'Lumberton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.6077,
    longitude: -79.0144,
    postal_code: '28359'
  },
  '28360': {
    country: 'US',
    city: 'Lumberton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.6697,
    longitude: -79.1084,
    postal_code: '28360'
  },
  '28362': {
    country: 'US',
    city: 'Marietta',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.3791,
    longitude: -79.1279,
    postal_code: '28362'
  },
  '28363': {
    country: 'US',
    city: 'Marston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Richmond',
    latitude: 34.9882,
    longitude: -79.5823,
    postal_code: '28363'
  },
  '28364': {
    country: 'US',
    city: 'Maxton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.7334,
    longitude: -79.3097,
    postal_code: '28364'
  },
  '28365': {
    country: 'US',
    city: 'Mount Olive',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wayne',
    latitude: 35.2109,
    longitude: -78.0983,
    postal_code: '28365'
  },
  '28366': {
    country: 'US',
    city: 'Newton Grove',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Sampson',
    latitude: 35.2301,
    longitude: -78.3594,
    postal_code: '28366'
  },
  '28367': {
    country: 'US',
    city: 'Norman',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Richmond',
    latitude: 35.1704,
    longitude: -79.7228,
    postal_code: '28367'
  },
  '28368': {
    country: 'US',
    city: 'Olivia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Harnett',
    latitude: 35.3596,
    longitude: -79.1097,
    postal_code: '28368'
  },
  '28369': {
    country: 'US',
    city: 'Orrum',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.4473,
    longitude: -79.031,
    postal_code: '28369'
  },
  '28370': {
    country: 'US',
    city: 'Pinehurst',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.2162,
    longitude: -79.4524,
    postal_code: '28370'
  },
  '28371': {
    country: 'US',
    city: 'Parkton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.9006,
    longitude: -78.9969,
    postal_code: '28371'
  },
  '28372': {
    country: 'US',
    city: 'Pembroke',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.6902,
    longitude: -79.1834,
    postal_code: '28372'
  },
  '28373': {
    country: 'US',
    city: 'Pinebluff',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.1007,
    longitude: -79.4663,
    postal_code: '28373'
  },
  '28374': {
    country: 'US',
    city: 'Pinehurst',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.1884,
    longitude: -79.4732,
    postal_code: '28374'
  },
  '28375': {
    country: 'US',
    city: 'Proctorville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.4713,
    longitude: -79.0414,
    postal_code: '28375'
  },
  '28376': {
    country: 'US',
    city: 'Raeford',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Hoke',
    latitude: 34.989,
    longitude: -79.2228,
    postal_code: '28376'
  },
  '28377': {
    country: 'US',
    city: 'Red Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.8083,
    longitude: -79.1636,
    postal_code: '28377'
  },
  '28378': {
    country: 'US',
    city: 'Rex',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.8543,
    longitude: -79.0642,
    postal_code: '28378'
  },
  '28379': {
    country: 'US',
    city: 'Rockingham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Richmond',
    latitude: 34.9336,
    longitude: -79.7666,
    postal_code: '28379'
  },
  '28380': {
    country: 'US',
    city: 'Rockingham',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Richmond',
    latitude: 34.9942,
    longitude: -79.7673,
    postal_code: '28380'
  },
  '28382': {
    country: 'US',
    city: 'Roseboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Sampson',
    latitude: 34.9639,
    longitude: -78.5133,
    postal_code: '28382'
  },
  '28383': {
    country: 'US',
    city: 'Rowland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.5887,
    longitude: -79.2618,
    postal_code: '28383'
  },
  '28384': {
    country: 'US',
    city: 'Saint Pauls',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.801,
    longitude: -78.9731,
    postal_code: '28384'
  },
  '28385': {
    country: 'US',
    city: 'Salemburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Sampson',
    latitude: 35.0515,
    longitude: -78.4714,
    postal_code: '28385'
  },
  '28386': {
    country: 'US',
    city: 'Shannon',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Robeson',
    latitude: 34.8988,
    longitude: -79.1806,
    postal_code: '28386'
  },
  '28387': {
    country: 'US',
    city: 'Southern Pines',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.1697,
    longitude: -79.3957,
    postal_code: '28387'
  },
  '28388': {
    country: 'US',
    city: 'Southern Pines',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.2803,
    longitude: -79.4327,
    postal_code: '28388'
  },
  '28390': {
    country: 'US',
    city: 'Spring Lake',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.183,
    longitude: -78.9786,
    postal_code: '28390'
  },
  '28391': {
    country: 'US',
    city: 'Stedman',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.0347,
    longitude: -78.6949,
    postal_code: '28391'
  },
  '28392': {
    country: 'US',
    city: 'Tar Heel',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bladen',
    latitude: 34.7465,
    longitude: -78.8134,
    postal_code: '28392'
  },
  '28393': {
    country: 'US',
    city: 'Turkey',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Sampson',
    latitude: 34.9857,
    longitude: -78.2121,
    postal_code: '28393'
  },
  '28394': {
    country: 'US',
    city: 'Vass',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Moore',
    latitude: 35.2171,
    longitude: -79.2562,
    postal_code: '28394'
  },
  '28395': {
    country: 'US',
    city: 'Wade',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cumberland',
    latitude: 35.1606,
    longitude: -78.7249,
    postal_code: '28395'
  },
  '28396': {
    country: 'US',
    city: 'Wagram',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Scotland',
    latitude: 34.9044,
    longitude: -79.3959,
    postal_code: '28396'
  },
  '28398': {
    country: 'US',
    city: 'Warsaw',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 35.018,
    longitude: -78.031,
    postal_code: '28398'
  },
  '28399': {
    country: 'US',
    city: 'White Oak',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bladen',
    latitude: 34.7662,
    longitude: -78.7301,
    postal_code: '28399'
  },
  '28401': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.2257,
    longitude: -77.9447,
    postal_code: '28401'
  },
  '28402': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.3405,
    longitude: -77.9014,
    postal_code: '28402'
  },
  '28403': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.2237,
    longitude: -77.8862,
    postal_code: '28403'
  },
  '28404': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 34.2257,
    longitude: -77.9447,
    postal_code: '28404'
  },
  '28405': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.2651,
    longitude: -77.867,
    postal_code: '28405'
  },
  '28406': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.0881,
    longitude: -77.8526,
    postal_code: '28406'
  },
  '28407': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.0881,
    longitude: -77.8526,
    postal_code: '28407'
  },
  '28408': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.2257,
    longitude: -77.9447,
    postal_code: '28408'
  },
  '28409': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.1663,
    longitude: -77.8723,
    postal_code: '28409'
  },
  '28410': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.0881,
    longitude: -77.8526,
    postal_code: '28410'
  },
  '28411': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.3033,
    longitude: -77.8039,
    postal_code: '28411'
  },
  '28412': {
    country: 'US',
    city: 'Wilmington',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.1572,
    longitude: -77.9141,
    postal_code: '28412'
  },
  '28420': {
    country: 'US',
    city: 'Ash',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 34.0659,
    longitude: -78.5056,
    postal_code: '28420'
  },
  '28421': {
    country: 'US',
    city: 'Atkinson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pender',
    latitude: 34.5304,
    longitude: -78.1671,
    postal_code: '28421'
  },
  '28422': {
    country: 'US',
    city: 'Bolivia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 34.026,
    longitude: -78.1681,
    postal_code: '28422'
  },
  '28423': {
    country: 'US',
    city: 'Bolton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.3091,
    longitude: -78.3372,
    postal_code: '28423'
  },
  '28424': {
    country: 'US',
    city: 'Brunswick',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.2907,
    longitude: -78.6994,
    postal_code: '28424'
  },
  '28425': {
    country: 'US',
    city: 'Burgaw',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pender',
    latitude: 34.5487,
    longitude: -77.9403,
    postal_code: '28425'
  },
  '28428': {
    country: 'US',
    city: 'Carolina Beach',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.0366,
    longitude: -77.8963,
    postal_code: '28428'
  },
  '28429': {
    country: 'US',
    city: 'Castle Hayne',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.3236,
    longitude: -77.9108,
    postal_code: '28429'
  },
  '28430': {
    country: 'US',
    city: 'Cerro Gordo',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.3025,
    longitude: -78.9216,
    postal_code: '28430'
  },
  '28431': {
    country: 'US',
    city: 'Chadbourn',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.3223,
    longitude: -78.8267,
    postal_code: '28431'
  },
  '28432': {
    country: 'US',
    city: 'Clarendon',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.1761,
    longitude: -78.7637,
    postal_code: '28432'
  },
  '28433': {
    country: 'US',
    city: 'Clarkton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bladen',
    latitude: 34.503,
    longitude: -78.6313,
    postal_code: '28433'
  },
  '28434': {
    country: 'US',
    city: 'Council',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bladen',
    latitude: 34.429,
    longitude: -78.4115,
    postal_code: '28434'
  },
  '28435': {
    country: 'US',
    city: 'Currie',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pender',
    latitude: 34.4497,
    longitude: -78.0925,
    postal_code: '28435'
  },
  '28436': {
    country: 'US',
    city: 'Delco',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.2838,
    longitude: -78.2607,
    postal_code: '28436'
  },
  '28438': {
    country: 'US',
    city: 'Evergreen',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.4204,
    longitude: -78.8947,
    postal_code: '28438'
  },
  '28439': {
    country: 'US',
    city: 'Fair Bluff',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.3023,
    longitude: -79.0175,
    postal_code: '28439'
  },
  '28441': {
    country: 'US',
    city: 'Garland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Sampson',
    latitude: 34.7897,
    longitude: -78.4309,
    postal_code: '28441'
  },
  '28442': {
    country: 'US',
    city: 'Hallsboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.3181,
    longitude: -78.6043,
    postal_code: '28442'
  },
  '28443': {
    country: 'US',
    city: 'Hampstead',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pender',
    latitude: 34.3879,
    longitude: -77.6628,
    postal_code: '28443'
  },
  '28444': {
    country: 'US',
    city: 'Harrells',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Sampson',
    latitude: 34.6769,
    longitude: -78.243,
    postal_code: '28444'
  },
  '28445': {
    country: 'US',
    city: 'Holly Ridge',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.4954,
    longitude: -77.555,
    postal_code: '28445'
  },
  '28447': {
    country: 'US',
    city: 'Ivanhoe',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Sampson',
    latitude: 34.6233,
    longitude: -78.2342,
    postal_code: '28447'
  },
  '28448': {
    country: 'US',
    city: 'Kelly',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Bladen',
    latitude: 34.4591,
    longitude: -78.2942,
    postal_code: '28448'
  },
  '28449': {
    country: 'US',
    city: 'Kure Beach',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 33.9927,
    longitude: -77.9099,
    postal_code: '28449'
  },
  '28450': {
    country: 'US',
    city: 'Lake Waccamaw',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.3394,
    longitude: -78.5102,
    postal_code: '28450'
  },
  '28451': {
    country: 'US',
    city: 'Leland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 34.268,
    longitude: -78.0578,
    postal_code: '28451'
  },
  '28452': {
    country: 'US',
    city: 'Longwood',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 33.9973,
    longitude: -78.5541,
    postal_code: '28452'
  },
  '28453': {
    country: 'US',
    city: 'Magnolia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 34.8957,
    longitude: -78.0432,
    postal_code: '28453'
  },
  '28454': {
    country: 'US',
    city: 'Maple Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pender',
    latitude: 34.6594,
    longitude: -77.7117,
    postal_code: '28454'
  },
  '28455': {
    country: 'US',
    city: 'Nakina',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.1153,
    longitude: -78.657,
    postal_code: '28455'
  },
  '28456': {
    country: 'US',
    city: 'Riegelwood',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.3471,
    longitude: -78.2575,
    postal_code: '28456'
  },
  '28457': {
    country: 'US',
    city: 'Rocky Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pender',
    latitude: 34.4344,
    longitude: -77.9234,
    postal_code: '28457'
  },
  '28458': {
    country: 'US',
    city: 'Rose Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 34.8235,
    longitude: -78.0166,
    postal_code: '28458'
  },
  '28459': {
    country: 'US',
    city: 'Shallotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 33.9334,
    longitude: -78.4129,
    postal_code: '28459'
  },
  '28460': {
    country: 'US',
    city: 'Sneads Ferry',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.5426,
    longitude: -77.4038,
    postal_code: '28460'
  },
  '28461': {
    country: 'US',
    city: 'Southport',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 33.9654,
    longitude: -78.0359,
    postal_code: '28461'
  },
  '28462': {
    country: 'US',
    city: 'Supply',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 34.0231,
    longitude: -78.2884,
    postal_code: '28462'
  },
  '28463': {
    country: 'US',
    city: 'Tabor City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.1233,
    longitude: -78.8232,
    postal_code: '28463'
  },
  '28464': {
    country: 'US',
    city: 'Teachey',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 34.77,
    longitude: -78.0221,
    postal_code: '28464'
  },
  '28465': {
    country: 'US',
    city: 'Oak Island',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 33.9161,
    longitude: -78.1255,
    postal_code: '28465'
  },
  '28466': {
    country: 'US',
    city: 'Wallace',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 34.7542,
    longitude: -77.9429,
    postal_code: '28466'
  },
  '28467': {
    country: 'US',
    city: 'Calabash',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 33.9047,
    longitude: -78.5744,
    postal_code: '28467'
  },
  '28468': {
    country: 'US',
    city: 'Sunset Beach',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 33.8836,
    longitude: -78.52,
    postal_code: '28468'
  },
  '28469': {
    country: 'US',
    city: 'Ocean Isle Beach',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 33.8913,
    longitude: -78.4298,
    postal_code: '28469'
  },
  '28470': {
    country: 'US',
    city: 'Shallotte',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 33.9637,
    longitude: -78.4064,
    postal_code: '28470'
  },
  '28472': {
    country: 'US',
    city: 'Whiteville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Columbus',
    latitude: 34.3241,
    longitude: -78.716,
    postal_code: '28472'
  },
  '28478': {
    country: 'US',
    city: 'Willard',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pender',
    latitude: 34.6845,
    longitude: -78.0234,
    postal_code: '28478'
  },
  '28479': {
    country: 'US',
    city: 'Winnabow',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Brunswick',
    latitude: 34.1553,
    longitude: -78.0558,
    postal_code: '28479'
  },
  '28480': {
    country: 'US',
    city: 'Wrightsville Beach',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'New Hanover',
    latitude: 34.2228,
    longitude: -77.7932,
    postal_code: '28480'
  },
  '28501': {
    country: 'US',
    city: 'Kinston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lenoir',
    latitude: 35.2783,
    longitude: -77.586,
    postal_code: '28501'
  },
  '28502': {
    country: 'US',
    city: 'Kinston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lenoir',
    latitude: 35.2627,
    longitude: -77.5816,
    postal_code: '28502'
  },
  '28503': {
    country: 'US',
    city: 'Kinston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lenoir',
    latitude: 35.3191,
    longitude: -77.595,
    postal_code: '28503'
  },
  '28504': {
    country: 'US',
    city: 'Kinston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lenoir',
    latitude: 35.206,
    longitude: -77.6576,
    postal_code: '28504'
  },
  '28508': {
    country: 'US',
    city: 'Albertson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 35.1176,
    longitude: -77.8515,
    postal_code: '28508'
  },
  '28509': {
    country: 'US',
    city: 'Alliance',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pamlico',
    latitude: 35.1449,
    longitude: -76.8022,
    postal_code: '28509'
  },
  '28510': {
    country: 'US',
    city: 'Arapahoe',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pamlico',
    latitude: 35.0055,
    longitude: -76.8149,
    postal_code: '28510'
  },
  '28511': {
    country: 'US',
    city: 'Atlantic',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.8888,
    longitude: -76.3521,
    postal_code: '28511'
  },
  '28512': {
    country: 'US',
    city: 'Atlantic Beach',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.6991,
    longitude: -76.7402,
    postal_code: '28512'
  },
  '28513': {
    country: 'US',
    city: 'Ayden',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.4565,
    longitude: -77.4051,
    postal_code: '28513'
  },
  '28515': {
    country: 'US',
    city: 'Bayboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pamlico',
    latitude: 35.1526,
    longitude: -76.7518,
    postal_code: '28515'
  },
  '28516': {
    country: 'US',
    city: 'Beaufort',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.758,
    longitude: -76.6228,
    postal_code: '28516'
  },
  '28518': {
    country: 'US',
    city: 'Beulaville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 34.934,
    longitude: -77.7697,
    postal_code: '28518'
  },
  '28519': {
    country: 'US',
    city: 'Bridgeton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 35.1215,
    longitude: -77.0208,
    postal_code: '28519'
  },
  '28520': {
    country: 'US',
    city: 'Cedar Island',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 35.0082,
    longitude: -76.316,
    postal_code: '28520'
  },
  '28521': {
    country: 'US',
    city: 'Chinquapin',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Duplin',
    latitude: 34.8276,
    longitude: -77.7636,
    postal_code: '28521'
  },
  '28522': {
    country: 'US',
    city: 'Comfort',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jones',
    latitude: 35.0051,
    longitude: -77.5231,
    postal_code: '28522'
  },
  '28523': {
    country: 'US',
    city: 'Cove City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 35.2023,
    longitude: -77.2963,
    postal_code: '28523'
  },
  '28524': {
    country: 'US',
    city: 'Davis',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.7974,
    longitude: -76.4602,
    postal_code: '28524'
  },
  '28525': {
    country: 'US',
    city: 'Deep Run',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lenoir',
    latitude: 35.163,
    longitude: -77.6928,
    postal_code: '28525'
  },
  '28526': {
    country: 'US',
    city: 'Dover',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 35.2554,
    longitude: -77.3646,
    postal_code: '28526'
  },
  '28527': {
    country: 'US',
    city: 'Ernul',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 35.2547,
    longitude: -77.0502,
    postal_code: '28527'
  },
  '28528': {
    country: 'US',
    city: 'Gloucester',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.7344,
    longitude: -76.5394,
    postal_code: '28528'
  },
  '28529': {
    country: 'US',
    city: 'Grantsboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pamlico',
    latitude: 35.0668,
    longitude: -76.871,
    postal_code: '28529'
  },
  '28530': {
    country: 'US',
    city: 'Grifton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.3757,
    longitude: -77.4193,
    postal_code: '28530'
  },
  '28531': {
    country: 'US',
    city: 'Harkers Island',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.6966,
    longitude: -76.5583,
    postal_code: '28531'
  },
  '28532': {
    country: 'US',
    city: 'Havelock',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 34.8968,
    longitude: -76.89,
    postal_code: '28532'
  },
  '28533': {
    country: 'US',
    city: 'Cherry Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 34.9038,
    longitude: -76.9,
    postal_code: '28533'
  },
  '28537': {
    country: 'US',
    city: 'Hobucken',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pamlico',
    latitude: 35.2518,
    longitude: -76.5696,
    postal_code: '28537'
  },
  '28538': {
    country: 'US',
    city: 'Hookerton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Greene',
    latitude: 35.438,
    longitude: -77.5656,
    postal_code: '28538'
  },
  '28539': {
    country: 'US',
    city: 'Hubert',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.6993,
    longitude: -77.2079,
    postal_code: '28539'
  },
  '28540': {
    country: 'US',
    city: 'Jacksonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.7375,
    longitude: -77.4628,
    postal_code: '28540'
  },
  '28541': {
    country: 'US',
    city: 'Jacksonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.6921,
    longitude: -77.3912,
    postal_code: '28541'
  },
  '28542': {
    country: 'US',
    city: 'Camp Lejeune',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.664,
    longitude: -77.4637,
    postal_code: '28542'
  },
  '28543': {
    country: 'US',
    city: 'Tarawa Terrace',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.7354,
    longitude: -77.3831,
    postal_code: '28543'
  },
  '28544': {
    country: 'US',
    city: 'Midway Park',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.727,
    longitude: -77.32,
    postal_code: '28544'
  },
  '28545': {
    country: 'US',
    city: 'Mccutcheon Field',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.6921,
    longitude: -77.3912,
    postal_code: '28545'
  },
  '28546': {
    country: 'US',
    city: 'Jacksonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.774,
    longitude: -77.3781,
    postal_code: '28546'
  },
  '28547': {
    country: 'US',
    city: 'Camp Lejeune',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.6909,
    longitude: -77.3614,
    postal_code: '28547'
  },
  '28551': {
    country: 'US',
    city: 'La Grange',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lenoir',
    latitude: 35.3054,
    longitude: -77.7686,
    postal_code: '28551'
  },
  '28552': {
    country: 'US',
    city: 'Lowland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pamlico',
    latitude: 35.306,
    longitude: -76.5777,
    postal_code: '28552'
  },
  '28553': {
    country: 'US',
    city: 'Marshallberg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.7265,
    longitude: -76.5173,
    postal_code: '28553'
  },
  '28554': {
    country: 'US',
    city: 'Maury',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Greene',
    latitude: 35.4821,
    longitude: -77.5861,
    postal_code: '28554'
  },
  '28555': {
    country: 'US',
    city: 'Maysville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jones',
    latitude: 34.8691,
    longitude: -77.2315,
    postal_code: '28555'
  },
  '28556': {
    country: 'US',
    city: 'Merritt',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pamlico',
    latitude: 35.1224,
    longitude: -76.6719,
    postal_code: '28556'
  },
  '28557': {
    country: 'US',
    city: 'Morehead City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.7253,
    longitude: -76.7531,
    postal_code: '28557'
  },
  '28560': {
    country: 'US',
    city: 'New Bern',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 35.1204,
    longitude: -76.9842,
    postal_code: '28560'
  },
  '28561': {
    country: 'US',
    city: 'New Bern',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 35.1037,
    longitude: -77.0759,
    postal_code: '28561'
  },
  '28562': {
    country: 'US',
    city: 'New Bern',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 35.1004,
    longitude: -77.1029,
    postal_code: '28562'
  },
  '28563': {
    country: 'US',
    city: 'New Bern',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 35.1109,
    longitude: -77.0634,
    postal_code: '28563'
  },
  '28564': {
    country: 'US',
    city: 'New Bern',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 35.1109,
    longitude: -77.0634,
    postal_code: '28564'
  },
  '28570': {
    country: 'US',
    city: 'Newport',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.7551,
    longitude: -76.9069,
    postal_code: '28570'
  },
  '28571': {
    country: 'US',
    city: 'Oriental',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pamlico',
    latitude: 35.0364,
    longitude: -76.7015,
    postal_code: '28571'
  },
  '28572': {
    country: 'US',
    city: 'Pink Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Lenoir',
    latitude: 35.0573,
    longitude: -77.6943,
    postal_code: '28572'
  },
  '28573': {
    country: 'US',
    city: 'Pollocksville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jones',
    latitude: 35.0151,
    longitude: -77.2287,
    postal_code: '28573'
  },
  '28574': {
    country: 'US',
    city: 'Richlands',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.8624,
    longitude: -77.5863,
    postal_code: '28574'
  },
  '28575': {
    country: 'US',
    city: 'Salter Path',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.6885,
    longitude: -76.8861,
    postal_code: '28575'
  },
  '28577': {
    country: 'US',
    city: 'Sealevel',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.8769,
    longitude: -76.3898,
    postal_code: '28577'
  },
  '28578': {
    country: 'US',
    city: 'Seven Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wayne',
    latitude: 35.2105,
    longitude: -77.9146,
    postal_code: '28578'
  },
  '28579': {
    country: 'US',
    city: 'Smyrna',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.7593,
    longitude: -76.5274,
    postal_code: '28579'
  },
  '28580': {
    country: 'US',
    city: 'Snow Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Greene',
    latitude: 35.4438,
    longitude: -77.6956,
    postal_code: '28580'
  },
  '28581': {
    country: 'US',
    city: 'Stacy',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.8412,
    longitude: -76.4289,
    postal_code: '28581'
  },
  '28582': {
    country: 'US',
    city: 'Stella',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.7777,
    longitude: -77.1308,
    postal_code: '28582'
  },
  '28583': {
    country: 'US',
    city: 'Stonewall',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pamlico',
    latitude: 35.1371,
    longitude: -76.7406,
    postal_code: '28583'
  },
  '28584': {
    country: 'US',
    city: 'Swansboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Onslow',
    latitude: 34.6991,
    longitude: -77.135,
    postal_code: '28584'
  },
  '28585': {
    country: 'US',
    city: 'Trenton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jones',
    latitude: 35.0745,
    longitude: -77.4595,
    postal_code: '28585'
  },
  '28586': {
    country: 'US',
    city: 'Vanceboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Craven',
    latitude: 35.3063,
    longitude: -77.1716,
    postal_code: '28586'
  },
  '28587': {
    country: 'US',
    city: 'Vandemere',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pamlico',
    latitude: 35.1913,
    longitude: -76.6604,
    postal_code: '28587'
  },
  '28589': {
    country: 'US',
    city: 'Williston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.7893,
    longitude: -76.5052,
    postal_code: '28589'
  },
  '28590': {
    country: 'US',
    city: 'Winterville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Pitt',
    latitude: 35.5336,
    longitude: -77.391,
    postal_code: '28590'
  },
  '28594': {
    country: 'US',
    city: 'Emerald Isle',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Carteret',
    latitude: 34.6662,
    longitude: -77.026,
    postal_code: '28594'
  },
  '28601': {
    country: 'US',
    city: 'Hickory',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Catawba',
    latitude: 35.7576,
    longitude: -81.3289,
    postal_code: '28601'
  },
  '28602': {
    country: 'US',
    city: 'Hickory',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Catawba',
    latitude: 35.6884,
    longitude: -81.3612,
    postal_code: '28602'
  },
  '28603': {
    country: 'US',
    city: 'Hickory',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Catawba',
    latitude: 35.6799,
    longitude: -81.2872,
    postal_code: '28603'
  },
  '28604': {
    country: 'US',
    city: 'Banner Elk',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Avery',
    latitude: 36.1705,
    longitude: -81.8412,
    postal_code: '28604'
  },
  '28605': {
    country: 'US',
    city: 'Blowing Rock',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Watauga',
    latitude: 36.1355,
    longitude: -81.6996,
    postal_code: '28605'
  },
  '28606': {
    country: 'US',
    city: 'Boomer',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.0552,
    longitude: -81.3137,
    postal_code: '28606'
  },
  '28607': {
    country: 'US',
    city: 'Boone',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Watauga',
    latitude: 36.2142,
    longitude: -81.666,
    postal_code: '28607'
  },
  '28608': {
    country: 'US',
    city: 'Boone',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Watauga',
    latitude: 36.2168,
    longitude: -81.6746,
    postal_code: '28608'
  },
  '28609': {
    country: 'US',
    city: 'Catawba',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Catawba',
    latitude: 35.6757,
    longitude: -81.0503,
    postal_code: '28609'
  },
  '28610': {
    country: 'US',
    city: 'Claremont',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Catawba',
    latitude: 35.7211,
    longitude: -81.1297,
    postal_code: '28610'
  },
  '28611': {
    country: 'US',
    city: 'Collettsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caldwell',
    latitude: 35.9946,
    longitude: -81.7266,
    postal_code: '28611'
  },
  '28612': {
    country: 'US',
    city: 'Connelly Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.7429,
    longitude: -81.5134,
    postal_code: '28612'
  },
  '28613': {
    country: 'US',
    city: 'Conover',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Catawba',
    latitude: 35.7313,
    longitude: -81.2165,
    postal_code: '28613'
  },
  '28615': {
    country: 'US',
    city: 'Creston',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.45,
    longitude: -81.6506,
    postal_code: '28615'
  },
  '28616': {
    country: 'US',
    city: 'Crossnore',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Avery',
    latitude: 36.0218,
    longitude: -81.9296,
    postal_code: '28616'
  },
  '28617': {
    country: 'US',
    city: 'Crumpler',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.4641,
    longitude: -81.4039,
    postal_code: '28617'
  },
  '28618': {
    country: 'US',
    city: 'Deep Gap',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Watauga',
    latitude: 36.2136,
    longitude: -81.5163,
    postal_code: '28618'
  },
  '28619': {
    country: 'US',
    city: 'Drexel',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.7579,
    longitude: -81.6043,
    postal_code: '28619'
  },
  '28621': {
    country: 'US',
    city: 'Elkin',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.2872,
    longitude: -80.8554,
    postal_code: '28621'
  },
  '28622': {
    country: 'US',
    city: 'Elk Park',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Avery',
    latitude: 36.1646,
    longitude: -81.9639,
    postal_code: '28622'
  },
  '28623': {
    country: 'US',
    city: 'Ennice',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alleghany',
    latitude: 36.5253,
    longitude: -80.9771,
    postal_code: '28623'
  },
  '28624': {
    country: 'US',
    city: 'Ferguson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.1283,
    longitude: -81.3864,
    postal_code: '28624'
  },
  '28625': {
    country: 'US',
    city: 'Statesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.8651,
    longitude: -80.8858,
    postal_code: '28625'
  },
  '28626': {
    country: 'US',
    city: 'Fleetwood',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.2814,
    longitude: -81.514,
    postal_code: '28626'
  },
  '28627': {
    country: 'US',
    city: 'Glade Valley',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alleghany',
    latitude: 36.4429,
    longitude: -81.0168,
    postal_code: '28627'
  },
  '28628': {
    country: 'US',
    city: 'Glen Alpine',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.729,
    longitude: -81.7793,
    postal_code: '28628'
  },
  '28629': {
    country: 'US',
    city: 'Glendale Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.3405,
    longitude: -81.3653,
    postal_code: '28629'
  },
  '28630': {
    country: 'US',
    city: 'Granite Falls',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caldwell',
    latitude: 35.7965,
    longitude: -81.4306,
    postal_code: '28630'
  },
  '28631': {
    country: 'US',
    city: 'Grassy Creek',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.5415,
    longitude: -81.4468,
    postal_code: '28631'
  },
  '28633': {
    country: 'US',
    city: 'Lenoir',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caldwell',
    latitude: 35.914,
    longitude: -81.539,
    postal_code: '28633'
  },
  '28634': {
    country: 'US',
    city: 'Harmony',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.958,
    longitude: -80.7585,
    postal_code: '28634'
  },
  '28635': {
    country: 'US',
    city: 'Hays',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.31,
    longitude: -81.1161,
    postal_code: '28635'
  },
  '28636': {
    country: 'US',
    city: 'Hiddenite',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alexander',
    latitude: 35.9504,
    longitude: -81.0487,
    postal_code: '28636'
  },
  '28637': {
    country: 'US',
    city: 'Hildebran',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.718,
    longitude: -81.4194,
    postal_code: '28637'
  },
  '28638': {
    country: 'US',
    city: 'Hudson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caldwell',
    latitude: 35.8403,
    longitude: -81.4897,
    postal_code: '28638'
  },
  '28640': {
    country: 'US',
    city: 'Jefferson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.409,
    longitude: -81.4396,
    postal_code: '28640'
  },
  '28641': {
    country: 'US',
    city: 'Jonas Ridge',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.9726,
    longitude: -81.8948,
    postal_code: '28641'
  },
  '28642': {
    country: 'US',
    city: 'Jonesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Yadkin',
    latitude: 36.2286,
    longitude: -80.787,
    postal_code: '28642'
  },
  '28643': {
    country: 'US',
    city: 'Lansing',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.5176,
    longitude: -81.5269,
    postal_code: '28643'
  },
  '28644': {
    country: 'US',
    city: 'Laurel Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.4449,
    longitude: -81.2606,
    postal_code: '28644'
  },
  '28645': {
    country: 'US',
    city: 'Lenoir',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caldwell',
    latitude: 35.9149,
    longitude: -81.5398,
    postal_code: '28645'
  },
  '28646': {
    country: 'US',
    city: 'Linville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Avery',
    latitude: 36.0665,
    longitude: -81.8704,
    postal_code: '28646'
  },
  '28647': {
    country: 'US',
    city: 'Linville Falls',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.9593,
    longitude: -81.9429,
    postal_code: '28647'
  },
  '28649': {
    country: 'US',
    city: 'Mc Grady',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.3103,
    longitude: -81.1912,
    postal_code: '28649'
  },
  '28650': {
    country: 'US',
    city: 'Maiden',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Catawba',
    latitude: 35.5759,
    longitude: -81.1745,
    postal_code: '28650'
  },
  '28651': {
    country: 'US',
    city: 'Millers Creek',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.2119,
    longitude: -81.2485,
    postal_code: '28651'
  },
  '28652': {
    country: 'US',
    city: 'Minneapolis',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Avery',
    latitude: 36.0993,
    longitude: -81.9871,
    postal_code: '28652'
  },
  '28653': {
    country: 'US',
    city: 'Montezuma',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Avery',
    latitude: 36.0651,
    longitude: -81.9017,
    postal_code: '28653'
  },
  '28654': {
    country: 'US',
    city: 'Moravian Falls',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.0788,
    longitude: -81.1781,
    postal_code: '28654'
  },
  '28655': {
    country: 'US',
    city: 'Morganton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.7346,
    longitude: -81.7042,
    postal_code: '28655'
  },
  '28656': {
    country: 'US',
    city: 'North Wilkesboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.2384,
    longitude: -81.046,
    postal_code: '28656'
  },
  '28657': {
    country: 'US',
    city: 'Newland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Avery',
    latitude: 36.059,
    longitude: -81.9303,
    postal_code: '28657'
  },
  '28658': {
    country: 'US',
    city: 'Newton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Catawba',
    latitude: 35.6498,
    longitude: -81.2425,
    postal_code: '28658'
  },
  '28659': {
    country: 'US',
    city: 'North Wilkesboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.2017,
    longitude: -81.1286,
    postal_code: '28659'
  },
  '28660': {
    country: 'US',
    city: 'Olin',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.9593,
    longitude: -80.8511,
    postal_code: '28660'
  },
  '28661': {
    country: 'US',
    city: 'Patterson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caldwell',
    latitude: 35.9973,
    longitude: -81.5626,
    postal_code: '28661'
  },
  '28662': {
    country: 'US',
    city: 'Pineola',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Avery',
    latitude: 36.0285,
    longitude: -81.8915,
    postal_code: '28662'
  },
  '28663': {
    country: 'US',
    city: 'Piney Creek',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alleghany',
    latitude: 36.5276,
    longitude: -81.3019,
    postal_code: '28663'
  },
  '28664': {
    country: 'US',
    city: 'Plumtree',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Avery',
    latitude: 36.0441,
    longitude: -82.0037,
    postal_code: '28664'
  },
  '28665': {
    country: 'US',
    city: 'Purlear',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.1964,
    longitude: -81.3528,
    postal_code: '28665'
  },
  '28666': {
    country: 'US',
    city: 'Icard',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.7274,
    longitude: -81.4706,
    postal_code: '28666'
  },
  '28667': {
    country: 'US',
    city: 'Rhodhiss',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Caldwell',
    latitude: 35.7772,
    longitude: -81.4302,
    postal_code: '28667'
  },
  '28668': {
    country: 'US',
    city: 'Roaring Gap',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alleghany',
    latitude: 36.3836,
    longitude: -81.0188,
    postal_code: '28668'
  },
  '28669': {
    country: 'US',
    city: 'Roaring River',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.1916,
    longitude: -81.0004,
    postal_code: '28669'
  },
  '28670': {
    country: 'US',
    city: 'Ronda',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.2059,
    longitude: -80.927,
    postal_code: '28670'
  },
  '28671': {
    country: 'US',
    city: 'Rutherford College',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.7485,
    longitude: -81.5226,
    postal_code: '28671'
  },
  '28672': {
    country: 'US',
    city: 'Scottville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.4833,
    longitude: -81.3276,
    postal_code: '28672'
  },
  '28673': {
    country: 'US',
    city: 'Sherrills Ford',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Catawba',
    latitude: 35.5962,
    longitude: -81.0339,
    postal_code: '28673'
  },
  '28675': {
    country: 'US',
    city: 'Sparta',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alleghany',
    latitude: 36.5089,
    longitude: -81.1384,
    postal_code: '28675'
  },
  '28676': {
    country: 'US',
    city: 'State Road',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Surry',
    latitude: 36.3422,
    longitude: -80.8653,
    postal_code: '28676'
  },
  '28677': {
    country: 'US',
    city: 'Statesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.799,
    longitude: -80.894,
    postal_code: '28677'
  },
  '28678': {
    country: 'US',
    city: 'Stony Point',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alexander',
    latitude: 35.8661,
    longitude: -81.0641,
    postal_code: '28678'
  },
  '28679': {
    country: 'US',
    city: 'Sugar Grove',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Watauga',
    latitude: 36.2627,
    longitude: -81.8441,
    postal_code: '28679'
  },
  '28680': {
    country: 'US',
    city: 'Morganton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.7507,
    longitude: -81.6953,
    postal_code: '28680'
  },
  '28681': {
    country: 'US',
    city: 'Taylorsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Alexander',
    latitude: 35.901,
    longitude: -81.2124,
    postal_code: '28681'
  },
  '28682': {
    country: 'US',
    city: 'Terrell',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Catawba',
    latitude: 35.5836,
    longitude: -80.9631,
    postal_code: '28682'
  },
  '28683': {
    country: 'US',
    city: 'Thurmond',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.3562,
    longitude: -80.9317,
    postal_code: '28683'
  },
  '28684': {
    country: 'US',
    city: 'Todd',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.3245,
    longitude: -81.5874,
    postal_code: '28684'
  },
  '28685': {
    country: 'US',
    city: 'Traphill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.3301,
    longitude: -81.0151,
    postal_code: '28685'
  },
  '28687': {
    country: 'US',
    city: 'Statesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.7826,
    longitude: -80.8873,
    postal_code: '28687'
  },
  '28688': {
    country: 'US',
    city: 'Turnersburg',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.9085,
    longitude: -80.8073,
    postal_code: '28688'
  },
  '28689': {
    country: 'US',
    city: 'Union Grove',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 36.0369,
    longitude: -80.8967,
    postal_code: '28689'
  },
  '28690': {
    country: 'US',
    city: 'Valdese',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Burke',
    latitude: 35.7447,
    longitude: -81.567,
    postal_code: '28690'
  },
  '28691': {
    country: 'US',
    city: 'Valle Crucis',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Watauga',
    latitude: 36.2092,
    longitude: -81.7789,
    postal_code: '28691'
  },
  '28692': {
    country: 'US',
    city: 'Vilas',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Watauga',
    latitude: 36.2574,
    longitude: -81.7652,
    postal_code: '28692'
  },
  '28693': {
    country: 'US',
    city: 'Warrensville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.4572,
    longitude: -81.5465,
    postal_code: '28693'
  },
  '28694': {
    country: 'US',
    city: 'West Jefferson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Ashe',
    latitude: 36.3776,
    longitude: -81.4872,
    postal_code: '28694'
  },
  '28697': {
    country: 'US',
    city: 'Wilkesboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Wilkes',
    latitude: 36.1359,
    longitude: -81.1573,
    postal_code: '28697'
  },
  '28698': {
    country: 'US',
    city: 'Zionville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Watauga',
    latitude: 36.3194,
    longitude: -81.7476,
    postal_code: '28698'
  },
  '28699': {
    country: 'US',
    city: 'Scotts',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Iredell',
    latitude: 35.831,
    longitude: -81.0076,
    postal_code: '28699'
  },
  '28701': {
    country: 'US',
    city: 'Alexander',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.7064,
    longitude: -82.6311,
    postal_code: '28701'
  },
  '28702': {
    country: 'US',
    city: 'Almond',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Swain',
    latitude: 35.3698,
    longitude: -83.5652,
    postal_code: '28702'
  },
  '28704': {
    country: 'US',
    city: 'Arden',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.4637,
    longitude: -82.5354,
    postal_code: '28704'
  },
  '28705': {
    country: 'US',
    city: 'Bakersville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mitchell',
    latitude: 36.0286,
    longitude: -82.1711,
    postal_code: '28705'
  },
  '28707': {
    country: 'US',
    city: 'Balsam',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jackson',
    latitude: 35.4268,
    longitude: -83.0851,
    postal_code: '28707'
  },
  '28708': {
    country: 'US',
    city: 'Balsam Grove',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Transylvania',
    latitude: 35.2298,
    longitude: -82.8779,
    postal_code: '28708'
  },
  '28709': {
    country: 'US',
    city: 'Barnardsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.7748,
    longitude: -82.4567,
    postal_code: '28709'
  },
  '28710': {
    country: 'US',
    city: 'Bat Cave',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.4515,
    longitude: -82.2871,
    postal_code: '28710'
  },
  '28711': {
    country: 'US',
    city: 'Black Mountain',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.5986,
    longitude: -82.2902,
    postal_code: '28711'
  },
  '28712': {
    country: 'US',
    city: 'Brevard',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Transylvania',
    latitude: 35.2208,
    longitude: -82.7404,
    postal_code: '28712'
  },
  '28713': {
    country: 'US',
    city: 'Bryson City',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Swain',
    latitude: 35.4241,
    longitude: -83.4392,
    postal_code: '28713'
  },
  '28714': {
    country: 'US',
    city: 'Burnsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Yancey',
    latitude: 35.903,
    longitude: -82.2876,
    postal_code: '28714'
  },
  '28715': {
    country: 'US',
    city: 'Candler',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.5376,
    longitude: -82.7001,
    postal_code: '28715'
  },
  '28716': {
    country: 'US',
    city: 'Canton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Haywood',
    latitude: 35.5127,
    longitude: -82.8413,
    postal_code: '28716'
  },
  '28717': {
    country: 'US',
    city: 'Cashiers',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jackson',
    latitude: 35.0971,
    longitude: -83.0871,
    postal_code: '28717'
  },
  '28718': {
    country: 'US',
    city: 'Cedar Mountain',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Transylvania',
    latitude: 35.1508,
    longitude: -82.6374,
    postal_code: '28718'
  },
  '28719': {
    country: 'US',
    city: 'Cherokee',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Swain',
    latitude: 35.5094,
    longitude: -83.3144,
    postal_code: '28719'
  },
  '28720': {
    country: 'US',
    city: 'Chimney Rock',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.4369,
    longitude: -82.2417,
    postal_code: '28720'
  },
  '28721': {
    country: 'US',
    city: 'Clyde',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Haywood',
    latitude: 35.5597,
    longitude: -82.9216,
    postal_code: '28721'
  },
  '28722': {
    country: 'US',
    city: 'Columbus',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Polk',
    latitude: 35.2532,
    longitude: -82.1971,
    postal_code: '28722'
  },
  '28723': {
    country: 'US',
    city: 'Cullowhee',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jackson',
    latitude: 35.2409,
    longitude: -83.1475,
    postal_code: '28723'
  },
  '28724': {
    country: 'US',
    city: 'Dana',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.3293,
    longitude: -82.3754,
    postal_code: '28724'
  },
  '28725': {
    country: 'US',
    city: 'Dillsboro',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jackson',
    latitude: 35.3735,
    longitude: -83.2592,
    postal_code: '28725'
  },
  '28726': {
    country: 'US',
    city: 'East Flat Rock',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.2799,
    longitude: -82.4204,
    postal_code: '28726'
  },
  '28727': {
    country: 'US',
    city: 'Edneyville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.394,
    longitude: -82.3409,
    postal_code: '28727'
  },
  '28728': {
    country: 'US',
    city: 'Enka',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.4988,
    longitude: -82.708,
    postal_code: '28728'
  },
  '28729': {
    country: 'US',
    city: 'Etowah',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.3172,
    longitude: -82.5977,
    postal_code: '28729'
  },
  '28730': {
    country: 'US',
    city: 'Fairview',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.5258,
    longitude: -82.3985,
    postal_code: '28730'
  },
  '28731': {
    country: 'US',
    city: 'Flat Rock',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.289,
    longitude: -82.3916,
    postal_code: '28731'
  },
  '28732': {
    country: 'US',
    city: 'Fletcher',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.4499,
    longitude: -82.4966,
    postal_code: '28732'
  },
  '28733': {
    country: 'US',
    city: 'Fontana Dam',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Graham',
    latitude: 35.4231,
    longitude: -83.7795,
    postal_code: '28733'
  },
  '28734': {
    country: 'US',
    city: 'Franklin',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Macon',
    latitude: 35.181,
    longitude: -83.3885,
    postal_code: '28734'
  },
  '28735': {
    country: 'US',
    city: 'Gerton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.4759,
    longitude: -82.3505,
    postal_code: '28735'
  },
  '28736': {
    country: 'US',
    city: 'Glenville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jackson',
    latitude: 35.1882,
    longitude: -83.09,
    postal_code: '28736'
  },
  '28737': {
    country: 'US',
    city: 'Glenwood',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'McDowell',
    latitude: 35.7387,
    longitude: -82.0595,
    postal_code: '28737'
  },
  '28738': {
    country: 'US',
    city: 'Hazelwood',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Haywood',
    latitude: 35.4683,
    longitude: -83.0028,
    postal_code: '28738'
  },
  '28739': {
    country: 'US',
    city: 'Hendersonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.3192,
    longitude: -82.5,
    postal_code: '28739'
  },
  '28740': {
    country: 'US',
    city: 'Green Mountain',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Yancey',
    latitude: 35.9937,
    longitude: -82.259,
    postal_code: '28740'
  },
  '28741': {
    country: 'US',
    city: 'Highlands',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Macon',
    latitude: 35.0705,
    longitude: -83.216,
    postal_code: '28741'
  },
  '28742': {
    country: 'US',
    city: 'Horse Shoe',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.3432,
    longitude: -82.5565,
    postal_code: '28742'
  },
  '28743': {
    country: 'US',
    city: 'Hot Springs',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Madison',
    latitude: 35.8082,
    longitude: -82.9005,
    postal_code: '28743'
  },
  '28744': {
    country: 'US',
    city: 'Franklin',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Macon',
    latitude: 35.1823,
    longitude: -83.3815,
    postal_code: '28744'
  },
  '28745': {
    country: 'US',
    city: 'Lake Junaluska',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Haywood',
    latitude: 35.5258,
    longitude: -82.9704,
    postal_code: '28745'
  },
  '28746': {
    country: 'US',
    city: 'Lake Lure',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Rutherford',
    latitude: 35.4464,
    longitude: -82.1752,
    postal_code: '28746'
  },
  '28747': {
    country: 'US',
    city: 'Lake Toxaway',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Transylvania',
    latitude: 35.1451,
    longitude: -82.9191,
    postal_code: '28747'
  },
  '28748': {
    country: 'US',
    city: 'Leicester',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6498,
    longitude: -82.7106,
    postal_code: '28748'
  },
  '28749': {
    country: 'US',
    city: 'Little Switzerland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'McDowell',
    latitude: 35.8493,
    longitude: -82.0904,
    postal_code: '28749'
  },
  '28750': {
    country: 'US',
    city: 'Lynn',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Polk',
    latitude: 35.2362,
    longitude: -82.2362,
    postal_code: '28750'
  },
  '28751': {
    country: 'US',
    city: 'Maggie Valley',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Haywood',
    latitude: 35.5201,
    longitude: -83.0929,
    postal_code: '28751'
  },
  '28752': {
    country: 'US',
    city: 'Marion',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'McDowell',
    latitude: 35.6819,
    longitude: -82.018,
    postal_code: '28752'
  },
  '28753': {
    country: 'US',
    city: 'Marshall',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Madison',
    latitude: 35.8597,
    longitude: -82.7105,
    postal_code: '28753'
  },
  '28754': {
    country: 'US',
    city: 'Mars Hill',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Madison',
    latitude: 35.8528,
    longitude: -82.5254,
    postal_code: '28754'
  },
  '28755': {
    country: 'US',
    city: 'Micaville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Yancey',
    latitude: 35.9096,
    longitude: -82.2132,
    postal_code: '28755'
  },
  '28756': {
    country: 'US',
    city: 'Mill Spring',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Polk',
    latitude: 35.3338,
    longitude: -82.1557,
    postal_code: '28756'
  },
  '28757': {
    country: 'US',
    city: 'Montreat',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6415,
    longitude: -82.3156,
    postal_code: '28757'
  },
  '28758': {
    country: 'US',
    city: 'Mountain Home',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.3711,
    longitude: -82.4938,
    postal_code: '28758'
  },
  '28759': {
    country: 'US',
    city: 'Mills River',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.3906,
    longitude: -82.568,
    postal_code: '28759'
  },
  '28760': {
    country: 'US',
    city: 'Naples',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.3817,
    longitude: -82.4813,
    postal_code: '28760'
  },
  '28761': {
    country: 'US',
    city: 'Nebo',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'McDowell',
    latitude: 35.6732,
    longitude: -81.9056,
    postal_code: '28761'
  },
  '28762': {
    country: 'US',
    city: 'Old Fort',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'McDowell',
    latitude: 35.6169,
    longitude: -82.1686,
    postal_code: '28762'
  },
  '28763': {
    country: 'US',
    city: 'Otto',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Macon',
    latitude: 35.0515,
    longitude: -83.3854,
    postal_code: '28763'
  },
  '28765': {
    country: 'US',
    city: 'Penland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mitchell',
    latitude: 35.9219,
    longitude: -82.1107,
    postal_code: '28765'
  },
  '28766': {
    country: 'US',
    city: 'Penrose',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Transylvania',
    latitude: 35.2524,
    longitude: -82.6222,
    postal_code: '28766'
  },
  '28768': {
    country: 'US',
    city: 'Pisgah Forest',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Transylvania',
    latitude: 35.2599,
    longitude: -82.6695,
    postal_code: '28768'
  },
  '28770': {
    country: 'US',
    city: 'Ridgecrest',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6186,
    longitude: -82.3005,
    postal_code: '28770'
  },
  '28771': {
    country: 'US',
    city: 'Robbinsville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Graham',
    latitude: 35.3259,
    longitude: -83.7888,
    postal_code: '28771'
  },
  '28772': {
    country: 'US',
    city: 'Rosman',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Transylvania',
    latitude: 35.1437,
    longitude: -82.8212,
    postal_code: '28772'
  },
  '28773': {
    country: 'US',
    city: 'Saluda',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Polk',
    latitude: 35.2383,
    longitude: -82.3306,
    postal_code: '28773'
  },
  '28774': {
    country: 'US',
    city: 'Sapphire',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Transylvania',
    latitude: 35.0666,
    longitude: -83.0019,
    postal_code: '28774'
  },
  '28775': {
    country: 'US',
    city: 'Scaly Mountain',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Macon',
    latitude: 35.0311,
    longitude: -83.3274,
    postal_code: '28775'
  },
  '28776': {
    country: 'US',
    city: 'Skyland',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.4835,
    longitude: -82.5207,
    postal_code: '28776'
  },
  '28777': {
    country: 'US',
    city: 'Spruce Pine',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Mitchell',
    latitude: 35.906,
    longitude: -82.0705,
    postal_code: '28777'
  },
  '28778': {
    country: 'US',
    city: 'Swannanoa',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6172,
    longitude: -82.407,
    postal_code: '28778'
  },
  '28779': {
    country: 'US',
    city: 'Sylva',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jackson',
    latitude: 35.3481,
    longitude: -83.2031,
    postal_code: '28779'
  },
  '28781': {
    country: 'US',
    city: 'Topton',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cherokee',
    latitude: 35.2151,
    longitude: -83.6461,
    postal_code: '28781'
  },
  '28782': {
    country: 'US',
    city: 'Tryon',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Polk',
    latitude: 35.2157,
    longitude: -82.2394,
    postal_code: '28782'
  },
  '28783': {
    country: 'US',
    city: 'Tuckasegee',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jackson',
    latitude: 35.2599,
    longitude: -83.0749,
    postal_code: '28783'
  },
  '28784': {
    country: 'US',
    city: 'Tuxedo',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.2174,
    longitude: -82.4178,
    postal_code: '28784'
  },
  '28785': {
    country: 'US',
    city: 'Waynesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Haywood',
    latitude: 35.533,
    longitude: -82.9719,
    postal_code: '28785'
  },
  '28786': {
    country: 'US',
    city: 'Waynesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Haywood',
    latitude: 35.5018,
    longitude: -82.9913,
    postal_code: '28786'
  },
  '28787': {
    country: 'US',
    city: 'Weaverville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.7126,
    longitude: -82.5491,
    postal_code: '28787'
  },
  '28788': {
    country: 'US',
    city: 'Webster',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jackson',
    latitude: 35.3462,
    longitude: -83.2193,
    postal_code: '28788'
  },
  '28789': {
    country: 'US',
    city: 'Whittier',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Jackson',
    latitude: 35.4469,
    longitude: -83.2872,
    postal_code: '28789'
  },
  '28790': {
    country: 'US',
    city: 'Zirconia',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.2153,
    longitude: -82.4574,
    postal_code: '28790'
  },
  '28791': {
    country: 'US',
    city: 'Hendersonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.3464,
    longitude: -82.525,
    postal_code: '28791'
  },
  '28792': {
    country: 'US',
    city: 'Hendersonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.3613,
    longitude: -82.4264,
    postal_code: '28792'
  },
  '28793': {
    country: 'US',
    city: 'Hendersonville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Henderson',
    latitude: 35.2927,
    longitude: -82.5036,
    postal_code: '28793'
  },
  '28801': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.5971,
    longitude: -82.5565,
    postal_code: '28801'
  },
  '28802': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6237,
    longitude: -82.6671,
    postal_code: '28802'
  },
  '28803': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.5393,
    longitude: -82.518,
    postal_code: '28803'
  },
  '28804': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6374,
    longitude: -82.5646,
    postal_code: '28804'
  },
  '28805': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6004,
    longitude: -82.4918,
    postal_code: '28805'
  },
  '28806': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.5808,
    longitude: -82.6078,
    postal_code: '28806'
  },
  '28810': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6203,
    longitude: -82.5286,
    postal_code: '28810'
  },
  '28813': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.5004,
    longitude: -82.5026,
    postal_code: '28813'
  },
  '28814': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6648,
    longitude: -82.4927,
    postal_code: '28814'
  },
  '28815': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6203,
    longitude: -82.5286,
    postal_code: '28815'
  },
  '28816': {
    country: 'US',
    city: 'Asheville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Buncombe',
    latitude: 35.6203,
    longitude: -82.5286,
    postal_code: '28816'
  },
  '28901': {
    country: 'US',
    city: 'Andrews',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cherokee',
    latitude: 35.1959,
    longitude: -83.8228,
    postal_code: '28901'
  },
  '28902': {
    country: 'US',
    city: 'Brasstown',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Clay',
    latitude: 35.0261,
    longitude: -83.9567,
    postal_code: '28902'
  },
  '28903': {
    country: 'US',
    city: 'Culberson',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cherokee',
    latitude: 34.9917,
    longitude: -84.1677,
    postal_code: '28903'
  },
  '28904': {
    country: 'US',
    city: 'Hayesville',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Clay',
    latitude: 35.0417,
    longitude: -83.7867,
    postal_code: '28904'
  },
  '28905': {
    country: 'US',
    city: 'Marble',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cherokee',
    latitude: 35.1475,
    longitude: -83.9381,
    postal_code: '28905'
  },
  '28906': {
    country: 'US',
    city: 'Murphy',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Cherokee',
    latitude: 35.1312,
    longitude: -84.0388,
    postal_code: '28906'
  },
  '28909': {
    country: 'US',
    city: 'Warne',
    state: 'North Carolina',
    state_short: 'NC',
    county: 'Clay',
    latitude: 35.0024,
    longitude: -83.9045,
    postal_code: '28909'
  },
  '29001': {
    country: 'US',
    city: 'Alcolu',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Clarendon',
    latitude: 33.7684,
    longitude: -80.1788,
    postal_code: '29001'
  },
  '29002': {
    country: 'US',
    city: 'Ballentine',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.1265,
    longitude: -81.2348,
    postal_code: '29002'
  },
  '29003': {
    country: 'US',
    city: 'Bamberg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Bamberg',
    latitude: 33.2779,
    longitude: -81.0177,
    postal_code: '29003'
  },
  '29006': {
    country: 'US',
    city: 'Batesburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.8702,
    longitude: -81.5505,
    postal_code: '29006'
  },
  '29009': {
    country: 'US',
    city: 'Bethune',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Kershaw',
    latitude: 34.4201,
    longitude: -80.3662,
    postal_code: '29009'
  },
  '29010': {
    country: 'US',
    city: 'Bishopville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lee',
    latitude: 34.2241,
    longitude: -80.275,
    postal_code: '29010'
  },
  '29014': {
    country: 'US',
    city: 'Blackstock',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chester',
    latitude: 34.5779,
    longitude: -81.1249,
    postal_code: '29014'
  },
  '29015': {
    country: 'US',
    city: 'Blair',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Fairfield',
    latitude: 34.4967,
    longitude: -81.3459,
    postal_code: '29015'
  },
  '29016': {
    country: 'US',
    city: 'Blythewood',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.1911,
    longitude: -80.9758,
    postal_code: '29016'
  },
  '29018': {
    country: 'US',
    city: 'Bowman',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.3475,
    longitude: -80.6709,
    postal_code: '29018'
  },
  '29020': {
    country: 'US',
    city: 'Camden',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Kershaw',
    latitude: 34.2696,
    longitude: -80.591,
    postal_code: '29020'
  },
  '29021': {
    country: 'US',
    city: 'Camden',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Kershaw',
    latitude: 34.2974,
    longitude: -80.6089,
    postal_code: '29021'
  },
  '29030': {
    country: 'US',
    city: 'Cameron',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Calhoun',
    latitude: 33.5703,
    longitude: -80.7081,
    postal_code: '29030'
  },
  '29031': {
    country: 'US',
    city: 'Carlisle',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Union',
    latitude: 34.567,
    longitude: -81.4814,
    postal_code: '29031'
  },
  '29032': {
    country: 'US',
    city: 'Cassatt',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Kershaw',
    latitude: 34.3424,
    longitude: -80.5,
    postal_code: '29032'
  },
  '29033': {
    country: 'US',
    city: 'Cayce',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.9626,
    longitude: -81.0671,
    postal_code: '29033'
  },
  '29036': {
    country: 'US',
    city: 'Chapin',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 34.1312,
    longitude: -81.3318,
    postal_code: '29036'
  },
  '29037': {
    country: 'US',
    city: 'Chappells',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Newberry',
    latitude: 34.1856,
    longitude: -81.8644,
    postal_code: '29037'
  },
  '29038': {
    country: 'US',
    city: 'Cope',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.3726,
    longitude: -80.9631,
    postal_code: '29038'
  },
  '29039': {
    country: 'US',
    city: 'Cordova',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.4275,
    longitude: -80.8857,
    postal_code: '29039'
  },
  '29040': {
    country: 'US',
    city: 'Dalzell',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 34.0144,
    longitude: -80.4665,
    postal_code: '29040'
  },
  '29041': {
    country: 'US',
    city: 'Davis Station',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Clarendon',
    latitude: 33.6027,
    longitude: -80.2645,
    postal_code: '29041'
  },
  '29042': {
    country: 'US',
    city: 'Denmark',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Bamberg',
    latitude: 33.3209,
    longitude: -81.1407,
    postal_code: '29042'
  },
  '29044': {
    country: 'US',
    city: 'Eastover',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 33.9153,
    longitude: -80.6996,
    postal_code: '29044'
  },
  '29045': {
    country: 'US',
    city: 'Elgin',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Kershaw',
    latitude: 34.162,
    longitude: -80.8113,
    postal_code: '29045'
  },
  '29046': {
    country: 'US',
    city: 'Elliott',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lee',
    latitude: 34.1071,
    longitude: -80.1637,
    postal_code: '29046'
  },
  '29047': {
    country: 'US',
    city: 'Elloree',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.531,
    longitude: -80.572,
    postal_code: '29047'
  },
  '29048': {
    country: 'US',
    city: 'Eutawville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.3922,
    longitude: -80.32,
    postal_code: '29048'
  },
  '29051': {
    country: 'US',
    city: 'Gable',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Clarendon',
    latitude: 33.8644,
    longitude: -80.1276,
    postal_code: '29051'
  },
  '29052': {
    country: 'US',
    city: 'Gadsden',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 33.8455,
    longitude: -80.7532,
    postal_code: '29052'
  },
  '29053': {
    country: 'US',
    city: 'Gaston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.8337,
    longitude: -81.1174,
    postal_code: '29053'
  },
  '29054': {
    country: 'US',
    city: 'Gilbert',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.9581,
    longitude: -81.3914,
    postal_code: '29054'
  },
  '29055': {
    country: 'US',
    city: 'Great Falls',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chester',
    latitude: 34.5705,
    longitude: -80.9133,
    postal_code: '29055'
  },
  '29056': {
    country: 'US',
    city: 'Greeleyville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Williamsburg',
    latitude: 33.5966,
    longitude: -79.9802,
    postal_code: '29056'
  },
  '29058': {
    country: 'US',
    city: 'Heath Springs',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lancaster',
    latitude: 34.6024,
    longitude: -80.7103,
    postal_code: '29058'
  },
  '29059': {
    country: 'US',
    city: 'Holly Hill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.3276,
    longitude: -80.4024,
    postal_code: '29059'
  },
  '29061': {
    country: 'US',
    city: 'Hopkins',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 33.9349,
    longitude: -80.8449,
    postal_code: '29061'
  },
  '29062': {
    country: 'US',
    city: 'Horatio',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 34.0213,
    longitude: -80.5659,
    postal_code: '29062'
  },
  '29063': {
    country: 'US',
    city: 'Irmo',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.1103,
    longitude: -81.1966,
    postal_code: '29063'
  },
  '29065': {
    country: 'US',
    city: 'Jenkinsville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Fairfield',
    latitude: 34.2717,
    longitude: -81.2712,
    postal_code: '29065'
  },
  '29067': {
    country: 'US',
    city: 'Kershaw',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lancaster',
    latitude: 34.5578,
    longitude: -80.5546,
    postal_code: '29067'
  },
  '29069': {
    country: 'US',
    city: 'Lamar',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Darlington',
    latitude: 34.1862,
    longitude: -80.0963,
    postal_code: '29069'
  },
  '29070': {
    country: 'US',
    city: 'Leesville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.9132,
    longitude: -81.4598,
    postal_code: '29070'
  },
  '29071': {
    country: 'US',
    city: 'Lexington',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.9815,
    longitude: -81.2362,
    postal_code: '29071'
  },
  '29072': {
    country: 'US',
    city: 'Lexington',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.9724,
    longitude: -81.2359,
    postal_code: '29072'
  },
  '29073': {
    country: 'US',
    city: 'Lexington',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.8632,
    longitude: -81.2351,
    postal_code: '29073'
  },
  '29074': {
    country: 'US',
    city: 'Liberty Hill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Kershaw',
    latitude: 34.457,
    longitude: -80.7892,
    postal_code: '29074'
  },
  '29075': {
    country: 'US',
    city: 'Little Mountain',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Newberry',
    latitude: 34.1676,
    longitude: -81.4184,
    postal_code: '29075'
  },
  '29078': {
    country: 'US',
    city: 'Lugoff',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Kershaw',
    latitude: 34.2296,
    longitude: -80.7147,
    postal_code: '29078'
  },
  '29079': {
    country: 'US',
    city: 'Lydia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Darlington',
    latitude: 34.289,
    longitude: -80.1123,
    postal_code: '29079'
  },
  '29080': {
    country: 'US',
    city: 'Lynchburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lee',
    latitude: 34.0526,
    longitude: -80.0988,
    postal_code: '29080'
  },
  '29081': {
    country: 'US',
    city: 'Ehrhardt',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Bamberg',
    latitude: 33.1044,
    longitude: -81.0221,
    postal_code: '29081'
  },
  '29082': {
    country: 'US',
    city: 'Lodge',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 33.0377,
    longitude: -80.9417,
    postal_code: '29082'
  },
  '29101': {
    country: 'US',
    city: 'Mc Bee',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chesterfield',
    latitude: 34.4606,
    longitude: -80.2544,
    postal_code: '29101'
  },
  '29102': {
    country: 'US',
    city: 'Manning',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Clarendon',
    latitude: 33.6184,
    longitude: -80.2116,
    postal_code: '29102'
  },
  '29104': {
    country: 'US',
    city: 'Mayesville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 33.9502,
    longitude: -80.2173,
    postal_code: '29104'
  },
  '29105': {
    country: 'US',
    city: 'Monetta',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Saluda',
    latitude: 33.8084,
    longitude: -81.5956,
    postal_code: '29105'
  },
  '29107': {
    country: 'US',
    city: 'Neeses',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.5318,
    longitude: -81.1232,
    postal_code: '29107'
  },
  '29108': {
    country: 'US',
    city: 'Newberry',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Newberry',
    latitude: 34.2847,
    longitude: -81.6157,
    postal_code: '29108'
  },
  '29111': {
    country: 'US',
    city: 'New Zion',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Clarendon',
    latitude: 33.7957,
    longitude: -80.0148,
    postal_code: '29111'
  },
  '29112': {
    country: 'US',
    city: 'North',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.6211,
    longitude: -81.0601,
    postal_code: '29112'
  },
  '29113': {
    country: 'US',
    city: 'Norway',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.4534,
    longitude: -81.1097,
    postal_code: '29113'
  },
  '29114': {
    country: 'US',
    city: 'Olanta',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 33.9285,
    longitude: -79.9153,
    postal_code: '29114'
  },
  '29115': {
    country: 'US',
    city: 'Orangeburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.5025,
    longitude: -80.8593,
    postal_code: '29115'
  },
  '29116': {
    country: 'US',
    city: 'Orangeburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.4959,
    longitude: -80.8246,
    postal_code: '29116'
  },
  '29117': {
    country: 'US',
    city: 'Orangeburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.4918,
    longitude: -80.8556,
    postal_code: '29117'
  },
  '29118': {
    country: 'US',
    city: 'Orangeburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.5675,
    longitude: -80.9248,
    postal_code: '29118'
  },
  '29122': {
    country: 'US',
    city: 'Peak',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Newberry',
    latitude: 34.2386,
    longitude: -81.3271,
    postal_code: '29122'
  },
  '29123': {
    country: 'US',
    city: 'Pelion',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.7766,
    longitude: -81.2502,
    postal_code: '29123'
  },
  '29125': {
    country: 'US',
    city: 'Pinewood',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 33.7642,
    longitude: -80.4978,
    postal_code: '29125'
  },
  '29126': {
    country: 'US',
    city: 'Pomaria',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Newberry',
    latitude: 34.3063,
    longitude: -81.45,
    postal_code: '29126'
  },
  '29127': {
    country: 'US',
    city: 'Prosperity',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Newberry',
    latitude: 34.1832,
    longitude: -81.5324,
    postal_code: '29127'
  },
  '29128': {
    country: 'US',
    city: 'Rembert',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 34.1085,
    longitude: -80.4945,
    postal_code: '29128'
  },
  '29129': {
    country: 'US',
    city: 'Ridge Spring',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Saluda',
    latitude: 33.8714,
    longitude: -81.6559,
    postal_code: '29129'
  },
  '29130': {
    country: 'US',
    city: 'Ridgeway',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Fairfield',
    latitude: 34.3167,
    longitude: -80.9288,
    postal_code: '29130'
  },
  '29132': {
    country: 'US',
    city: 'Rion',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Fairfield',
    latitude: 34.3074,
    longitude: -81.1252,
    postal_code: '29132'
  },
  '29133': {
    country: 'US',
    city: 'Rowesville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.3703,
    longitude: -80.8331,
    postal_code: '29133'
  },
  '29135': {
    country: 'US',
    city: 'Saint Matthews',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Calhoun',
    latitude: 33.6967,
    longitude: -80.8678,
    postal_code: '29135'
  },
  '29137': {
    country: 'US',
    city: 'Salley',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5972,
    longitude: -81.3184,
    postal_code: '29137'
  },
  '29138': {
    country: 'US',
    city: 'Saluda',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Saluda',
    latitude: 34.0175,
    longitude: -81.7754,
    postal_code: '29138'
  },
  '29142': {
    country: 'US',
    city: 'Santee',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.4835,
    longitude: -80.4805,
    postal_code: '29142'
  },
  '29143': {
    country: 'US',
    city: 'Sardinia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Clarendon',
    latitude: 33.8343,
    longitude: -80.0829,
    postal_code: '29143'
  },
  '29145': {
    country: 'US',
    city: 'Silverstreet',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Newberry',
    latitude: 34.2207,
    longitude: -81.7741,
    postal_code: '29145'
  },
  '29146': {
    country: 'US',
    city: 'Springfield',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.5343,
    longitude: -81.2499,
    postal_code: '29146'
  },
  '29147': {
    country: 'US',
    city: 'State Park',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0907,
    longitude: -80.9662,
    postal_code: '29147'
  },
  '29148': {
    country: 'US',
    city: 'Summerton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Clarendon',
    latitude: 33.5946,
    longitude: -80.3606,
    postal_code: '29148'
  },
  '29150': {
    country: 'US',
    city: 'Sumter',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 33.9137,
    longitude: -80.3542,
    postal_code: '29150'
  },
  '29151': {
    country: 'US',
    city: 'Sumter',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 33.8999,
    longitude: -80.3743,
    postal_code: '29151'
  },
  '29152': {
    country: 'US',
    city: 'Shaw Afb',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 33.9204,
    longitude: -80.3415,
    postal_code: '29152'
  },
  '29153': {
    country: 'US',
    city: 'Sumter',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 33.9446,
    longitude: -80.3206,
    postal_code: '29153'
  },
  '29154': {
    country: 'US',
    city: 'Sumter',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 33.8821,
    longitude: -80.4028,
    postal_code: '29154'
  },
  '29160': {
    country: 'US',
    city: 'Swansea',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.7339,
    longitude: -81.0933,
    postal_code: '29160'
  },
  '29161': {
    country: 'US',
    city: 'Timmonsville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 34.1012,
    longitude: -79.9378,
    postal_code: '29161'
  },
  '29162': {
    country: 'US',
    city: 'Turbeville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Clarendon',
    latitude: 33.8907,
    longitude: -80.0184,
    postal_code: '29162'
  },
  '29163': {
    country: 'US',
    city: 'Vance',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.4139,
    longitude: -80.4617,
    postal_code: '29163'
  },
  '29164': {
    country: 'US',
    city: 'Wagener',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.6494,
    longitude: -81.3995,
    postal_code: '29164'
  },
  '29166': {
    country: 'US',
    city: 'Ward',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Saluda',
    latitude: 33.8849,
    longitude: -81.7283,
    postal_code: '29166'
  },
  '29168': {
    country: 'US',
    city: 'Wedgefield',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Sumter',
    latitude: 33.8953,
    longitude: -80.498,
    postal_code: '29168'
  },
  '29169': {
    country: 'US',
    city: 'West Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.995,
    longitude: -81.0888,
    postal_code: '29169'
  },
  '29170': {
    country: 'US',
    city: 'West Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.9568,
    longitude: -81.1405,
    postal_code: '29170'
  },
  '29171': {
    country: 'US',
    city: 'West Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.9252,
    longitude: -81.2483,
    postal_code: '29171'
  },
  '29172': {
    country: 'US',
    city: 'West Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.9,
    longitude: -81.091,
    postal_code: '29172'
  },
  '29175': {
    country: 'US',
    city: 'Westville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Kershaw',
    latitude: 34.4418,
    longitude: -80.6033,
    postal_code: '29175'
  },
  '29177': {
    country: 'US',
    city: 'White Rock',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.1504,
    longitude: -81.2601,
    postal_code: '29177'
  },
  '29178': {
    country: 'US',
    city: 'Whitmire',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Newberry',
    latitude: 34.4955,
    longitude: -81.606,
    postal_code: '29178'
  },
  '29180': {
    country: 'US',
    city: 'Winnsboro',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Fairfield',
    latitude: 34.381,
    longitude: -81.109,
    postal_code: '29180'
  },
  '29201': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0004,
    longitude: -81.0334,
    postal_code: '29201'
  },
  '29202': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0007,
    longitude: -81.0348,
    postal_code: '29202'
  },
  '29203': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0635,
    longitude: -81.0265,
    postal_code: '29203'
  },
  '29204': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.026,
    longitude: -81.0046,
    postal_code: '29204'
  },
  '29205': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 33.9903,
    longitude: -80.9997,
    postal_code: '29205'
  },
  '29206': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0247,
    longitude: -80.9532,
    postal_code: '29206'
  },
  '29207': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0197,
    longitude: -80.9391,
    postal_code: '29207'
  },
  '29208': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0007,
    longitude: -81.0348,
    postal_code: '29208'
  },
  '29209': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 33.9659,
    longitude: -80.9355,
    postal_code: '29209'
  },
  '29210': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0479,
    longitude: -81.1101,
    postal_code: '29210'
  },
  '29211': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0007,
    longitude: -81.0348,
    postal_code: '29211'
  },
  '29212': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 34.0726,
    longitude: -81.1796,
    postal_code: '29212'
  },
  '29214': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0007,
    longitude: -81.0348,
    postal_code: '29214'
  },
  '29215': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29215'
  },
  '29216': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0007,
    longitude: -81.0348,
    postal_code: '29216'
  },
  '29217': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0007,
    longitude: -81.0348,
    postal_code: '29217'
  },
  '29218': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0007,
    longitude: -81.0348,
    postal_code: '29218'
  },
  '29219': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29219'
  },
  '29220': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0007,
    longitude: -81.0348,
    postal_code: '29220'
  },
  '29221': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29221'
  },
  '29222': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29222'
  },
  '29223': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0853,
    longitude: -80.9167,
    postal_code: '29223'
  },
  '29224': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29224'
  },
  '29225': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.0007,
    longitude: -81.0348,
    postal_code: '29225'
  },
  '29226': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29226'
  },
  '29227': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29227'
  },
  '29228': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lexington',
    latitude: 33.9252,
    longitude: -81.2483,
    postal_code: '29228'
  },
  '29229': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.1388,
    longitude: -80.8892,
    postal_code: '29229'
  },
  '29230': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.1075,
    longitude: -81.0626,
    postal_code: '29230'
  },
  '29240': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29240'
  },
  '29250': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29250'
  },
  '29260': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29260'
  },
  '29290': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29290'
  },
  '29292': {
    country: 'US',
    city: 'Columbia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Richland',
    latitude: 34.006,
    longitude: -80.9708,
    postal_code: '29292'
  },
  '29301': {
    country: 'US',
    city: 'Spartanburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9352,
    longitude: -81.9654,
    postal_code: '29301'
  },
  '29302': {
    country: 'US',
    city: 'Spartanburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.8939,
    longitude: -81.834,
    postal_code: '29302'
  },
  '29303': {
    country: 'US',
    city: 'Spartanburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9803,
    longitude: -81.9168,
    postal_code: '29303'
  },
  '29304': {
    country: 'US',
    city: 'Spartanburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9166,
    longitude: -81.8639,
    postal_code: '29304'
  },
  '29305': {
    country: 'US',
    city: 'Spartanburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9496,
    longitude: -81.932,
    postal_code: '29305'
  },
  '29306': {
    country: 'US',
    city: 'Spartanburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.8937,
    longitude: -81.9228,
    postal_code: '29306'
  },
  '29307': {
    country: 'US',
    city: 'Spartanburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9839,
    longitude: -81.8313,
    postal_code: '29307'
  },
  '29316': {
    country: 'US',
    city: 'Boiling Springs',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 35.0465,
    longitude: -81.9818,
    postal_code: '29316'
  },
  '29319': {
    country: 'US',
    city: 'Spartanburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9496,
    longitude: -81.932,
    postal_code: '29319'
  },
  '29320': {
    country: 'US',
    city: 'Arcadia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9572,
    longitude: -81.9916,
    postal_code: '29320'
  },
  '29321': {
    country: 'US',
    city: 'Buffalo',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Union',
    latitude: 34.7247,
    longitude: -81.6826,
    postal_code: '29321'
  },
  '29322': {
    country: 'US',
    city: 'Campobello',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 35.108,
    longitude: -82.1403,
    postal_code: '29322'
  },
  '29323': {
    country: 'US',
    city: 'Chesnee',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 35.1154,
    longitude: -81.8678,
    postal_code: '29323'
  },
  '29324': {
    country: 'US',
    city: 'Clifton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9796,
    longitude: -81.8179,
    postal_code: '29324'
  },
  '29325': {
    country: 'US',
    city: 'Clinton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Laurens',
    latitude: 34.4707,
    longitude: -81.8772,
    postal_code: '29325'
  },
  '29329': {
    country: 'US',
    city: 'Converse',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9921,
    longitude: -81.8348,
    postal_code: '29329'
  },
  '29330': {
    country: 'US',
    city: 'Cowpens',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 35.039,
    longitude: -81.822,
    postal_code: '29330'
  },
  '29331': {
    country: 'US',
    city: 'Cross Anchor',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.6383,
    longitude: -81.8567,
    postal_code: '29331'
  },
  '29332': {
    country: 'US',
    city: 'Cross Hill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Laurens',
    latitude: 34.2693,
    longitude: -81.9843,
    postal_code: '29332'
  },
  '29333': {
    country: 'US',
    city: 'Drayton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9685,
    longitude: -81.9064,
    postal_code: '29333'
  },
  '29334': {
    country: 'US',
    city: 'Duncan',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9176,
    longitude: -82.1258,
    postal_code: '29334'
  },
  '29335': {
    country: 'US',
    city: 'Enoree',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.671,
    longitude: -81.9237,
    postal_code: '29335'
  },
  '29336': {
    country: 'US',
    city: 'Fairforest',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9565,
    longitude: -82.0101,
    postal_code: '29336'
  },
  '29338': {
    country: 'US',
    city: 'Fingerville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 35.134,
    longitude: -82.0065,
    postal_code: '29338'
  },
  '29340': {
    country: 'US',
    city: 'Gaffney',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Cherokee',
    latitude: 35.0615,
    longitude: -81.6491,
    postal_code: '29340'
  },
  '29341': {
    country: 'US',
    city: 'Gaffney',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Cherokee',
    latitude: 35.0999,
    longitude: -81.7056,
    postal_code: '29341'
  },
  '29342': {
    country: 'US',
    city: 'Gaffney',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Cherokee',
    latitude: 34.9963,
    longitude: -81.6524,
    postal_code: '29342'
  },
  '29346': {
    country: 'US',
    city: 'Glendale',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9448,
    longitude: -81.8376,
    postal_code: '29346'
  },
  '29348': {
    country: 'US',
    city: 'Gramling',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 35.0768,
    longitude: -82.1326,
    postal_code: '29348'
  },
  '29349': {
    country: 'US',
    city: 'Inman',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 35.0528,
    longitude: -82.054,
    postal_code: '29349'
  },
  '29351': {
    country: 'US',
    city: 'Joanna',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Laurens',
    latitude: 34.4068,
    longitude: -81.8191,
    postal_code: '29351'
  },
  '29353': {
    country: 'US',
    city: 'Jonesville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Union',
    latitude: 34.8125,
    longitude: -81.6431,
    postal_code: '29353'
  },
  '29355': {
    country: 'US',
    city: 'Kinards',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Newberry',
    latitude: 34.3804,
    longitude: -81.7773,
    postal_code: '29355'
  },
  '29356': {
    country: 'US',
    city: 'Landrum',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 35.1565,
    longitude: -82.2115,
    postal_code: '29356'
  },
  '29360': {
    country: 'US',
    city: 'Laurens',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Laurens',
    latitude: 34.499,
    longitude: -82.0143,
    postal_code: '29360'
  },
  '29364': {
    country: 'US',
    city: 'Lockhart',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Union',
    latitude: 34.7865,
    longitude: -81.462,
    postal_code: '29364'
  },
  '29365': {
    country: 'US',
    city: 'Lyman',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9684,
    longitude: -82.1435,
    postal_code: '29365'
  },
  '29368': {
    country: 'US',
    city: 'Mayo',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 35.084,
    longitude: -81.8598,
    postal_code: '29368'
  },
  '29369': {
    country: 'US',
    city: 'Moore',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.8646,
    longitude: -82.0215,
    postal_code: '29369'
  },
  '29370': {
    country: 'US',
    city: 'Mountville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Laurens',
    latitude: 34.3408,
    longitude: -81.9584,
    postal_code: '29370'
  },
  '29372': {
    country: 'US',
    city: 'Pacolet',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9017,
    longitude: -81.7587,
    postal_code: '29372'
  },
  '29373': {
    country: 'US',
    city: 'Pacolet Mills',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9226,
    longitude: -81.7426,
    postal_code: '29373'
  },
  '29374': {
    country: 'US',
    city: 'Pauline',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.7855,
    longitude: -81.8492,
    postal_code: '29374'
  },
  '29375': {
    country: 'US',
    city: 'Reidville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.8636,
    longitude: -82.1101,
    postal_code: '29375'
  },
  '29376': {
    country: 'US',
    city: 'Roebuck',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.8688,
    longitude: -81.9526,
    postal_code: '29376'
  },
  '29377': {
    country: 'US',
    city: 'Startex',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9285,
    longitude: -82.099,
    postal_code: '29377'
  },
  '29378': {
    country: 'US',
    city: 'Una',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.969,
    longitude: -81.9704,
    postal_code: '29378'
  },
  '29379': {
    country: 'US',
    city: 'Union',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Union',
    latitude: 34.7269,
    longitude: -81.6202,
    postal_code: '29379'
  },
  '29384': {
    country: 'US',
    city: 'Waterloo',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Laurens',
    latitude: 34.3367,
    longitude: -82.088,
    postal_code: '29384'
  },
  '29385': {
    country: 'US',
    city: 'Wellford',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.9514,
    longitude: -82.0927,
    postal_code: '29385'
  },
  '29386': {
    country: 'US',
    city: 'White Stone',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.8882,
    longitude: -81.969,
    postal_code: '29386'
  },
  '29388': {
    country: 'US',
    city: 'Woodruff',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Spartanburg',
    latitude: 34.7579,
    longitude: -82.0447,
    postal_code: '29388'
  },
  '29395': {
    country: 'US',
    city: 'Jonesville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Union',
    latitude: 34.8466,
    longitude: -81.7227,
    postal_code: '29395'
  },
  '29401': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7795,
    longitude: -79.9371,
    postal_code: '29401'
  },
  '29402': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7766,
    longitude: -79.9309,
    postal_code: '29402'
  },
  '29403': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7976,
    longitude: -79.9493,
    postal_code: '29403'
  },
  '29404': {
    country: 'US',
    city: 'Charleston Afb',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8982,
    longitude: -80.0686,
    postal_code: '29404'
  },
  '29405': {
    country: 'US',
    city: 'North Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.853,
    longitude: -79.9913,
    postal_code: '29405'
  },
  '29406': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.9352,
    longitude: -80.0325,
    postal_code: '29406'
  },
  '29407': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7993,
    longitude: -80.006,
    postal_code: '29407'
  },
  '29409': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7961,
    longitude: -79.9605,
    postal_code: '29409'
  },
  '29410': {
    country: 'US',
    city: 'Hanahan',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 32.9185,
    longitude: -80.022,
    postal_code: '29410'
  },
  '29412': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.718,
    longitude: -79.9537,
    postal_code: '29412'
  },
  '29413': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8488,
    longitude: -79.8577,
    postal_code: '29413'
  },
  '29414': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8215,
    longitude: -80.0568,
    postal_code: '29414'
  },
  '29415': {
    country: 'US',
    city: 'North Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8546,
    longitude: -79.9748,
    postal_code: '29415'
  },
  '29416': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8488,
    longitude: -79.8577,
    postal_code: '29416'
  },
  '29417': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8488,
    longitude: -79.8577,
    postal_code: '29417'
  },
  '29418': {
    country: 'US',
    city: 'North Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8929,
    longitude: -80.0458,
    postal_code: '29418'
  },
  '29419': {
    country: 'US',
    city: 'North Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8546,
    longitude: -79.9748,
    postal_code: '29419'
  },
  '29420': {
    country: 'US',
    city: 'North Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dorchester',
    latitude: 32.9336,
    longitude: -80.1026,
    postal_code: '29420'
  },
  '29422': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8488,
    longitude: -79.8577,
    postal_code: '29422'
  },
  '29423': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8488,
    longitude: -79.8577,
    postal_code: '29423'
  },
  '29424': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7831,
    longitude: -79.937,
    postal_code: '29424'
  },
  '29425': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7862,
    longitude: -79.9471,
    postal_code: '29425'
  },
  '29426': {
    country: 'US',
    city: 'Adams Run',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.779,
    longitude: -80.3288,
    postal_code: '29426'
  },
  '29429': {
    country: 'US',
    city: 'Awendaw',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 33.0063,
    longitude: -79.6561,
    postal_code: '29429'
  },
  '29431': {
    country: 'US',
    city: 'Bonneau',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.2703,
    longitude: -79.8736,
    postal_code: '29431'
  },
  '29432': {
    country: 'US',
    city: 'Branchville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Orangeburg',
    latitude: 33.2628,
    longitude: -80.8059,
    postal_code: '29432'
  },
  '29433': {
    country: 'US',
    city: 'Canadys',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 33.0614,
    longitude: -80.6312,
    postal_code: '29433'
  },
  '29434': {
    country: 'US',
    city: 'Cordesville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.1362,
    longitude: -79.8826,
    postal_code: '29434'
  },
  '29435': {
    country: 'US',
    city: 'Cottageville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 32.9612,
    longitude: -80.4794,
    postal_code: '29435'
  },
  '29436': {
    country: 'US',
    city: 'Cross',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.3364,
    longitude: -80.1859,
    postal_code: '29436'
  },
  '29437': {
    country: 'US',
    city: 'Dorchester',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dorchester',
    latitude: 33.1247,
    longitude: -80.4034,
    postal_code: '29437'
  },
  '29438': {
    country: 'US',
    city: 'Edisto Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 32.5486,
    longitude: -80.307,
    postal_code: '29438'
  },
  '29439': {
    country: 'US',
    city: 'Folly Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.663,
    longitude: -79.927,
    postal_code: '29439'
  },
  '29440': {
    country: 'US',
    city: 'Georgetown',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Georgetown',
    latitude: 33.4308,
    longitude: -79.3235,
    postal_code: '29440'
  },
  '29442': {
    country: 'US',
    city: 'Georgetown',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Georgetown',
    latitude: 33.4037,
    longitude: -79.2264,
    postal_code: '29442'
  },
  '29445': {
    country: 'US',
    city: 'Goose Creek',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.058,
    longitude: -80.0101,
    postal_code: '29445'
  },
  '29446': {
    country: 'US',
    city: 'Green Pond',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 32.6628,
    longitude: -80.528,
    postal_code: '29446'
  },
  '29447': {
    country: 'US',
    city: 'Grover',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dorchester',
    latitude: 33.1052,
    longitude: -80.5948,
    postal_code: '29447'
  },
  '29448': {
    country: 'US',
    city: 'Harleyville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dorchester',
    latitude: 33.2205,
    longitude: -80.4501,
    postal_code: '29448'
  },
  '29449': {
    country: 'US',
    city: 'Hollywood',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7105,
    longitude: -80.2744,
    postal_code: '29449'
  },
  '29450': {
    country: 'US',
    city: 'Huger',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.0439,
    longitude: -79.7841,
    postal_code: '29450'
  },
  '29451': {
    country: 'US',
    city: 'Isle Of Palms',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7943,
    longitude: -79.7729,
    postal_code: '29451'
  },
  '29452': {
    country: 'US',
    city: 'Jacksonboro',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 32.7305,
    longitude: -80.4609,
    postal_code: '29452'
  },
  '29453': {
    country: 'US',
    city: 'Jamestown',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.2221,
    longitude: -79.6282,
    postal_code: '29453'
  },
  '29455': {
    country: 'US',
    city: 'Johns Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7924,
    longitude: -80.1081,
    postal_code: '29455'
  },
  '29456': {
    country: 'US',
    city: 'Ladson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 32.993,
    longitude: -80.1257,
    postal_code: '29456'
  },
  '29457': {
    country: 'US',
    city: 'Johns Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7924,
    longitude: -80.1081,
    postal_code: '29457'
  },
  '29458': {
    country: 'US',
    city: 'Mc Clellanville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 33.1194,
    longitude: -79.5074,
    postal_code: '29458'
  },
  '29461': {
    country: 'US',
    city: 'Moncks Corner',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.1971,
    longitude: -80.0233,
    postal_code: '29461'
  },
  '29464': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8473,
    longitude: -79.8206,
    postal_code: '29464'
  },
  '29465': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7941,
    longitude: -79.8626,
    postal_code: '29465'
  },
  '29466': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.8674,
    longitude: -79.8049,
    postal_code: '29466'
  },
  '29468': {
    country: 'US',
    city: 'Pineville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.4199,
    longitude: -80.0932,
    postal_code: '29468'
  },
  '29469': {
    country: 'US',
    city: 'Pinopolis',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.2241,
    longitude: -80.0398,
    postal_code: '29469'
  },
  '29470': {
    country: 'US',
    city: 'Ravenel',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7881,
    longitude: -80.2223,
    postal_code: '29470'
  },
  '29471': {
    country: 'US',
    city: 'Reevesville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dorchester',
    latitude: 33.1872,
    longitude: -80.6672,
    postal_code: '29471'
  },
  '29472': {
    country: 'US',
    city: 'Ridgeville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dorchester',
    latitude: 33.108,
    longitude: -80.3086,
    postal_code: '29472'
  },
  '29474': {
    country: 'US',
    city: 'Round O',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 32.9405,
    longitude: -80.5739,
    postal_code: '29474'
  },
  '29475': {
    country: 'US',
    city: 'Ruffin',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 33.0103,
    longitude: -80.8137,
    postal_code: '29475'
  },
  '29476': {
    country: 'US',
    city: 'Russellville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.3971,
    longitude: -79.9717,
    postal_code: '29476'
  },
  '29477': {
    country: 'US',
    city: 'Saint George',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dorchester',
    latitude: 33.1845,
    longitude: -80.5732,
    postal_code: '29477'
  },
  '29479': {
    country: 'US',
    city: 'Saint Stephen',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.3362,
    longitude: -79.9236,
    postal_code: '29479'
  },
  '29481': {
    country: 'US',
    city: 'Smoaks',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 33.0963,
    longitude: -80.813,
    postal_code: '29481'
  },
  '29482': {
    country: 'US',
    city: 'Sullivans Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.7637,
    longitude: -79.8399,
    postal_code: '29482'
  },
  '29483': {
    country: 'US',
    city: 'Summerville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dorchester',
    latitude: 33.028,
    longitude: -80.1739,
    postal_code: '29483'
  },
  '29484': {
    country: 'US',
    city: 'Summerville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dorchester',
    latitude: 33.0023,
    longitude: -80.2267,
    postal_code: '29484'
  },
  '29485': {
    country: 'US',
    city: 'Summerville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dorchester',
    latitude: 32.9756,
    longitude: -80.1831,
    postal_code: '29485'
  },
  '29486': {
    country: 'US',
    city: 'Summerville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 33.0185,
    longitude: -80.1756,
    postal_code: '29486'
  },
  '29487': {
    country: 'US',
    city: 'Wadmalaw Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Charleston',
    latitude: 32.6529,
    longitude: -80.1829,
    postal_code: '29487'
  },
  '29488': {
    country: 'US',
    city: 'Walterboro',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 32.9052,
    longitude: -80.6668,
    postal_code: '29488'
  },
  '29492': {
    country: 'US',
    city: 'Charleston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Berkeley',
    latitude: 32.9668,
    longitude: -79.8528,
    postal_code: '29492'
  },
  '29493': {
    country: 'US',
    city: 'Williams',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 33.0327,
    longitude: -80.8445,
    postal_code: '29493'
  },
  '29501': {
    country: 'US',
    city: 'Florence',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 34.1838,
    longitude: -79.7728,
    postal_code: '29501'
  },
  '29502': {
    country: 'US',
    city: 'Florence',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 34.201,
    longitude: -79.7847,
    postal_code: '29502'
  },
  '29503': {
    country: 'US',
    city: 'Florence',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 34.063,
    longitude: -79.6506,
    postal_code: '29503'
  },
  '29504': {
    country: 'US',
    city: 'Florence',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 34.0416,
    longitude: -79.6933,
    postal_code: '29504'
  },
  '29505': {
    country: 'US',
    city: 'Florence',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 34.1231,
    longitude: -79.6893,
    postal_code: '29505'
  },
  '29506': {
    country: 'US',
    city: 'Florence',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 34.2153,
    longitude: -79.6468,
    postal_code: '29506'
  },
  '29510': {
    country: 'US',
    city: 'Andrews',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Georgetown',
    latitude: 33.4525,
    longitude: -79.5604,
    postal_code: '29510'
  },
  '29511': {
    country: 'US',
    city: 'Aynor',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.9823,
    longitude: -79.1777,
    postal_code: '29511'
  },
  '29512': {
    country: 'US',
    city: 'Bennettsville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marlboro',
    latitude: 34.6255,
    longitude: -79.6898,
    postal_code: '29512'
  },
  '29516': {
    country: 'US',
    city: 'Blenheim',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marlboro',
    latitude: 34.5171,
    longitude: -79.6442,
    postal_code: '29516'
  },
  '29518': {
    country: 'US',
    city: 'Cades',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Williamsburg',
    latitude: 33.7886,
    longitude: -79.848,
    postal_code: '29518'
  },
  '29519': {
    country: 'US',
    city: 'Centenary',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marion',
    latitude: 34.027,
    longitude: -79.3537,
    postal_code: '29519'
  },
  '29520': {
    country: 'US',
    city: 'Cheraw',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chesterfield',
    latitude: 34.6863,
    longitude: -79.9174,
    postal_code: '29520'
  },
  '29525': {
    country: 'US',
    city: 'Clio',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marlboro',
    latitude: 34.5805,
    longitude: -79.5453,
    postal_code: '29525'
  },
  '29526': {
    country: 'US',
    city: 'Conway',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.8731,
    longitude: -79.0557,
    postal_code: '29526'
  },
  '29527': {
    country: 'US',
    city: 'Conway',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.7827,
    longitude: -79.1473,
    postal_code: '29527'
  },
  '29528': {
    country: 'US',
    city: 'Conway',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.9358,
    longitude: -78.9192,
    postal_code: '29528'
  },
  '29530': {
    country: 'US',
    city: 'Coward',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 33.9905,
    longitude: -79.7515,
    postal_code: '29530'
  },
  '29532': {
    country: 'US',
    city: 'Darlington',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Darlington',
    latitude: 34.3003,
    longitude: -79.8732,
    postal_code: '29532'
  },
  '29536': {
    country: 'US',
    city: 'Dillon',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dillon',
    latitude: 34.4146,
    longitude: -79.377,
    postal_code: '29536'
  },
  '29540': {
    country: 'US',
    city: 'Darlington',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Darlington',
    latitude: 34.3843,
    longitude: -79.8422,
    postal_code: '29540'
  },
  '29541': {
    country: 'US',
    city: 'Effingham',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 34.0946,
    longitude: -79.7918,
    postal_code: '29541'
  },
  '29543': {
    country: 'US',
    city: 'Fork',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dillon',
    latitude: 34.2873,
    longitude: -79.2501,
    postal_code: '29543'
  },
  '29544': {
    country: 'US',
    city: 'Galivants Ferry',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 34.0563,
    longitude: -79.2464,
    postal_code: '29544'
  },
  '29545': {
    country: 'US',
    city: 'Green Sea',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 34.1668,
    longitude: -78.9589,
    postal_code: '29545'
  },
  '29546': {
    country: 'US',
    city: 'Gresham',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marion',
    latitude: 33.9069,
    longitude: -79.3564,
    postal_code: '29546'
  },
  '29547': {
    country: 'US',
    city: 'Hamer',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dillon',
    latitude: 34.4849,
    longitude: -79.3502,
    postal_code: '29547'
  },
  '29550': {
    country: 'US',
    city: 'Hartsville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Darlington',
    latitude: 34.3756,
    longitude: -80.0842,
    postal_code: '29550'
  },
  '29551': {
    country: 'US',
    city: 'Hartsville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Darlington',
    latitude: 34.3659,
    longitude: -80.1324,
    postal_code: '29551'
  },
  '29554': {
    country: 'US',
    city: 'Hemingway',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Williamsburg',
    latitude: 33.7419,
    longitude: -79.4489,
    postal_code: '29554'
  },
  '29555': {
    country: 'US',
    city: 'Johnsonville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 33.8299,
    longitude: -79.4783,
    postal_code: '29555'
  },
  '29556': {
    country: 'US',
    city: 'Kingstree',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Williamsburg',
    latitude: 33.6878,
    longitude: -79.7832,
    postal_code: '29556'
  },
  '29560': {
    country: 'US',
    city: 'Lake City',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 33.8655,
    longitude: -79.7418,
    postal_code: '29560'
  },
  '29563': {
    country: 'US',
    city: 'Lake View',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dillon',
    latitude: 34.3445,
    longitude: -79.1929,
    postal_code: '29563'
  },
  '29564': {
    country: 'US',
    city: 'Lane',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Williamsburg',
    latitude: 33.5083,
    longitude: -79.8715,
    postal_code: '29564'
  },
  '29565': {
    country: 'US',
    city: 'Latta',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dillon',
    latitude: 34.3398,
    longitude: -79.4417,
    postal_code: '29565'
  },
  '29566': {
    country: 'US',
    city: 'Little River',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.8768,
    longitude: -78.6508,
    postal_code: '29566'
  },
  '29567': {
    country: 'US',
    city: 'Little Rock',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Dillon',
    latitude: 34.5613,
    longitude: -79.4335,
    postal_code: '29567'
  },
  '29568': {
    country: 'US',
    city: 'Longs',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.9064,
    longitude: -78.7934,
    postal_code: '29568'
  },
  '29569': {
    country: 'US',
    city: 'Loris',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 34.0558,
    longitude: -78.9161,
    postal_code: '29569'
  },
  '29570': {
    country: 'US',
    city: 'Mc Coll',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marlboro',
    latitude: 34.6704,
    longitude: -79.5597,
    postal_code: '29570'
  },
  '29571': {
    country: 'US',
    city: 'Marion',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marion',
    latitude: 34.1562,
    longitude: -79.3898,
    postal_code: '29571'
  },
  '29572': {
    country: 'US',
    city: 'Myrtle Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.7587,
    longitude: -78.8044,
    postal_code: '29572'
  },
  '29574': {
    country: 'US',
    city: 'Mullins',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marion',
    latitude: 34.2044,
    longitude: -79.2542,
    postal_code: '29574'
  },
  '29575': {
    country: 'US',
    city: 'Myrtle Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.6286,
    longitude: -78.9736,
    postal_code: '29575'
  },
  '29576': {
    country: 'US',
    city: 'Murrells Inlet',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Georgetown',
    latitude: 33.5507,
    longitude: -79.0528,
    postal_code: '29576'
  },
  '29577': {
    country: 'US',
    city: 'Myrtle Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.6994,
    longitude: -78.9137,
    postal_code: '29577'
  },
  '29578': {
    country: 'US',
    city: 'Myrtle Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.7389,
    longitude: -78.9996,
    postal_code: '29578'
  },
  '29579': {
    country: 'US',
    city: 'Myrtle Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.7377,
    longitude: -78.9787,
    postal_code: '29579'
  },
  '29580': {
    country: 'US',
    city: 'Nesmith',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Williamsburg',
    latitude: 33.6625,
    longitude: -79.5589,
    postal_code: '29580'
  },
  '29581': {
    country: 'US',
    city: 'Nichols',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 34.2426,
    longitude: -79.1625,
    postal_code: '29581'
  },
  '29582': {
    country: 'US',
    city: 'North Myrtle Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.8343,
    longitude: -78.6606,
    postal_code: '29582'
  },
  '29583': {
    country: 'US',
    city: 'Pamplico',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 33.9922,
    longitude: -79.5929,
    postal_code: '29583'
  },
  '29584': {
    country: 'US',
    city: 'Patrick',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chesterfield',
    latitude: 34.5598,
    longitude: -80.065,
    postal_code: '29584'
  },
  '29585': {
    country: 'US',
    city: 'Pawleys Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Georgetown',
    latitude: 33.4508,
    longitude: -79.1341,
    postal_code: '29585'
  },
  '29587': {
    country: 'US',
    city: 'Myrtle Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.6211,
    longitude: -78.9632,
    postal_code: '29587'
  },
  '29588': {
    country: 'US',
    city: 'Myrtle Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.7226,
    longitude: -78.9782,
    postal_code: '29588'
  },
  '29589': {
    country: 'US',
    city: 'Rains',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marion',
    latitude: 34.1053,
    longitude: -79.3221,
    postal_code: '29589'
  },
  '29590': {
    country: 'US',
    city: 'Salters',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Williamsburg',
    latitude: 33.5611,
    longitude: -79.83,
    postal_code: '29590'
  },
  '29591': {
    country: 'US',
    city: 'Scranton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Florence',
    latitude: 33.9281,
    longitude: -79.7731,
    postal_code: '29591'
  },
  '29592': {
    country: 'US',
    city: 'Sellers',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marion',
    latitude: 34.258,
    longitude: -79.4783,
    postal_code: '29592'
  },
  '29593': {
    country: 'US',
    city: 'Society Hill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Darlington',
    latitude: 34.4512,
    longitude: -79.8865,
    postal_code: '29593'
  },
  '29594': {
    country: 'US',
    city: 'Tatum',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marlboro',
    latitude: 34.6405,
    longitude: -79.5869,
    postal_code: '29594'
  },
  '29596': {
    country: 'US',
    city: 'Wallace',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Marlboro',
    latitude: 34.7307,
    longitude: -79.8011,
    postal_code: '29596'
  },
  '29597': {
    country: 'US',
    city: 'North Myrtle Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.816,
    longitude: -78.68,
    postal_code: '29597'
  },
  '29598': {
    country: 'US',
    city: 'North Myrtle Beach',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Horry',
    latitude: 33.816,
    longitude: -78.68,
    postal_code: '29598'
  },
  '29601': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8472,
    longitude: -82.406,
    postal_code: '29601'
  },
  '29602': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8007,
    longitude: -82.3956,
    postal_code: '29602'
  },
  '29603': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8377,
    longitude: -82.3715,
    postal_code: '29603'
  },
  '29604': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8497,
    longitude: -82.4538,
    postal_code: '29604'
  },
  '29605': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8001,
    longitude: -82.3932,
    postal_code: '29605'
  },
  '29606': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8497,
    longitude: -82.4538,
    postal_code: '29606'
  },
  '29607': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8285,
    longitude: -82.3516,
    postal_code: '29607'
  },
  '29608': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8497,
    longitude: -82.4538,
    postal_code: '29608'
  },
  '29609': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8921,
    longitude: -82.4002,
    postal_code: '29609'
  },
  '29610': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8497,
    longitude: -82.4538,
    postal_code: '29610'
  },
  '29611': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8533,
    longitude: -82.4493,
    postal_code: '29611'
  },
  '29612': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8497,
    longitude: -82.4538,
    postal_code: '29612'
  },
  '29613': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.9249,
    longitude: -82.4331,
    postal_code: '29613'
  },
  '29614': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8724,
    longitude: -82.3626,
    postal_code: '29614'
  },
  '29615': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8661,
    longitude: -82.3198,
    postal_code: '29615'
  },
  '29616': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8526,
    longitude: -82.394,
    postal_code: '29616'
  },
  '29617': {
    country: 'US',
    city: 'Greenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.912,
    longitude: -82.4666,
    postal_code: '29617'
  },
  '29620': {
    country: 'US',
    city: 'Abbeville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Abbeville',
    latitude: 34.1819,
    longitude: -82.3785,
    postal_code: '29620'
  },
  '29621': {
    country: 'US',
    city: 'Anderson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.5261,
    longitude: -82.6304,
    postal_code: '29621'
  },
  '29622': {
    country: 'US',
    city: 'Anderson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.5034,
    longitude: -82.6501,
    postal_code: '29622'
  },
  '29623': {
    country: 'US',
    city: 'Anderson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.4381,
    longitude: -82.8354,
    postal_code: '29623'
  },
  '29624': {
    country: 'US',
    city: 'Anderson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.4373,
    longitude: -82.6251,
    postal_code: '29624'
  },
  '29625': {
    country: 'US',
    city: 'Anderson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.5271,
    longitude: -82.7087,
    postal_code: '29625'
  },
  '29626': {
    country: 'US',
    city: 'Anderson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.4596,
    longitude: -82.7614,
    postal_code: '29626'
  },
  '29627': {
    country: 'US',
    city: 'Belton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.5087,
    longitude: -82.5101,
    postal_code: '29627'
  },
  '29628': {
    country: 'US',
    city: 'Calhoun Falls',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Abbeville',
    latitude: 34.0999,
    longitude: -82.5805,
    postal_code: '29628'
  },
  '29630': {
    country: 'US',
    city: 'Central',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.7401,
    longitude: -82.7947,
    postal_code: '29630'
  },
  '29631': {
    country: 'US',
    city: 'Clemson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.6831,
    longitude: -82.825,
    postal_code: '29631'
  },
  '29632': {
    country: 'US',
    city: 'Clemson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.6834,
    longitude: -82.8374,
    postal_code: '29632'
  },
  '29633': {
    country: 'US',
    city: 'Clemson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.6834,
    longitude: -82.8374,
    postal_code: '29633'
  },
  '29634': {
    country: 'US',
    city: 'Clemson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.6834,
    longitude: -82.8374,
    postal_code: '29634'
  },
  '29635': {
    country: 'US',
    city: 'Cleveland',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 35.0654,
    longitude: -82.6093,
    postal_code: '29635'
  },
  '29636': {
    country: 'US',
    city: 'Conestee',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.7682,
    longitude: -82.3498,
    postal_code: '29636'
  },
  '29638': {
    country: 'US',
    city: 'Donalds',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Abbeville',
    latitude: 34.3304,
    longitude: -82.3367,
    postal_code: '29638'
  },
  '29639': {
    country: 'US',
    city: 'Due West',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Abbeville',
    latitude: 34.3344,
    longitude: -82.4002,
    postal_code: '29639'
  },
  '29640': {
    country: 'US',
    city: 'Easley',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.829,
    longitude: -82.5796,
    postal_code: '29640'
  },
  '29641': {
    country: 'US',
    city: 'Easley',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.8135,
    longitude: -82.6531,
    postal_code: '29641'
  },
  '29642': {
    country: 'US',
    city: 'Easley',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.7901,
    longitude: -82.5937,
    postal_code: '29642'
  },
  '29643': {
    country: 'US',
    city: 'Fair Play',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.5112,
    longitude: -82.9854,
    postal_code: '29643'
  },
  '29644': {
    country: 'US',
    city: 'Fountain Inn',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.6578,
    longitude: -82.2441,
    postal_code: '29644'
  },
  '29645': {
    country: 'US',
    city: 'Gray Court',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Laurens',
    latitude: 34.5953,
    longitude: -82.1147,
    postal_code: '29645'
  },
  '29646': {
    country: 'US',
    city: 'Greenwood',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenwood',
    latitude: 34.1758,
    longitude: -82.1562,
    postal_code: '29646'
  },
  '29647': {
    country: 'US',
    city: 'Greenwood',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenwood',
    latitude: 34.1788,
    longitude: -82.1631,
    postal_code: '29647'
  },
  '29648': {
    country: 'US',
    city: 'Greenwood',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenwood',
    latitude: 34.2124,
    longitude: -82.1681,
    postal_code: '29648'
  },
  '29649': {
    country: 'US',
    city: 'Greenwood',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenwood',
    latitude: 34.223,
    longitude: -82.1582,
    postal_code: '29649'
  },
  '29650': {
    country: 'US',
    city: 'Greer',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.8968,
    longitude: -82.2674,
    postal_code: '29650'
  },
  '29651': {
    country: 'US',
    city: 'Greer',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.9453,
    longitude: -82.2209,
    postal_code: '29651'
  },
  '29652': {
    country: 'US',
    city: 'Greer',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.9387,
    longitude: -82.2271,
    postal_code: '29652'
  },
  '29653': {
    country: 'US',
    city: 'Hodges',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenwood',
    latitude: 34.2882,
    longitude: -82.2142,
    postal_code: '29653'
  },
  '29654': {
    country: 'US',
    city: 'Honea Path',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.4417,
    longitude: -82.4255,
    postal_code: '29654'
  },
  '29655': {
    country: 'US',
    city: 'Iva',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.3198,
    longitude: -82.6574,
    postal_code: '29655'
  },
  '29656': {
    country: 'US',
    city: 'La France',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.6252,
    longitude: -82.7654,
    postal_code: '29656'
  },
  '29657': {
    country: 'US',
    city: 'Liberty',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.7872,
    longitude: -82.6974,
    postal_code: '29657'
  },
  '29658': {
    country: 'US',
    city: 'Long Creek',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.7634,
    longitude: -83.2776,
    postal_code: '29658'
  },
  '29659': {
    country: 'US',
    city: 'Lowndesville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Abbeville',
    latitude: 34.2126,
    longitude: -82.6496,
    postal_code: '29659'
  },
  '29661': {
    country: 'US',
    city: 'Marietta',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 35.0296,
    longitude: -82.5136,
    postal_code: '29661'
  },
  '29662': {
    country: 'US',
    city: 'Mauldin',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.7807,
    longitude: -82.3035,
    postal_code: '29662'
  },
  '29664': {
    country: 'US',
    city: 'Mountain Rest',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.8541,
    longitude: -83.1134,
    postal_code: '29664'
  },
  '29665': {
    country: 'US',
    city: 'Newry',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.7247,
    longitude: -82.9106,
    postal_code: '29665'
  },
  '29666': {
    country: 'US',
    city: 'Ninety Six',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenwood',
    latitude: 34.1652,
    longitude: -82.0292,
    postal_code: '29666'
  },
  '29667': {
    country: 'US',
    city: 'Norris',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.7648,
    longitude: -82.7567,
    postal_code: '29667'
  },
  '29669': {
    country: 'US',
    city: 'Pelzer',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.6461,
    longitude: -82.4673,
    postal_code: '29669'
  },
  '29670': {
    country: 'US',
    city: 'Pendleton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.6369,
    longitude: -82.7406,
    postal_code: '29670'
  },
  '29671': {
    country: 'US',
    city: 'Pickens',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.9024,
    longitude: -82.7058,
    postal_code: '29671'
  },
  '29672': {
    country: 'US',
    city: 'Seneca',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.774,
    longitude: -82.9457,
    postal_code: '29672'
  },
  '29673': {
    country: 'US',
    city: 'Piedmont',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.7244,
    longitude: -82.4702,
    postal_code: '29673'
  },
  '29675': {
    country: 'US',
    city: 'Richland',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.7641,
    longitude: -83.0883,
    postal_code: '29675'
  },
  '29676': {
    country: 'US',
    city: 'Salem',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.8728,
    longitude: -82.9607,
    postal_code: '29676'
  },
  '29677': {
    country: 'US',
    city: 'Sandy Springs',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.5975,
    longitude: -82.7568,
    postal_code: '29677'
  },
  '29678': {
    country: 'US',
    city: 'Seneca',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.6828,
    longitude: -82.9362,
    postal_code: '29678'
  },
  '29679': {
    country: 'US',
    city: 'Seneca',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.6857,
    longitude: -82.9532,
    postal_code: '29679'
  },
  '29680': {
    country: 'US',
    city: 'Simpsonville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.6962,
    longitude: -82.2893,
    postal_code: '29680'
  },
  '29681': {
    country: 'US',
    city: 'Simpsonville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.7512,
    longitude: -82.255,
    postal_code: '29681'
  },
  '29682': {
    country: 'US',
    city: 'Six Mile',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.8283,
    longitude: -82.8278,
    postal_code: '29682'
  },
  '29683': {
    country: 'US',
    city: 'Slater',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 35.0289,
    longitude: -82.4941,
    postal_code: '29683'
  },
  '29684': {
    country: 'US',
    city: 'Starr',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.3962,
    longitude: -82.6897,
    postal_code: '29684'
  },
  '29685': {
    country: 'US',
    city: 'Sunset',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Pickens',
    latitude: 34.9706,
    longitude: -82.8076,
    postal_code: '29685'
  },
  '29686': {
    country: 'US',
    city: 'Tamassee',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.897,
    longitude: -83.0375,
    postal_code: '29686'
  },
  '29687': {
    country: 'US',
    city: 'Taylors',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 34.9245,
    longitude: -82.3197,
    postal_code: '29687'
  },
  '29688': {
    country: 'US',
    city: 'Tigerville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 35.0477,
    longitude: -82.353,
    postal_code: '29688'
  },
  '29689': {
    country: 'US',
    city: 'Townville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.5226,
    longitude: -82.8963,
    postal_code: '29689'
  },
  '29690': {
    country: 'US',
    city: 'Travelers Rest',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenville',
    latitude: 35.0039,
    longitude: -82.4272,
    postal_code: '29690'
  },
  '29691': {
    country: 'US',
    city: 'Walhalla',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.7648,
    longitude: -83.064,
    postal_code: '29691'
  },
  '29692': {
    country: 'US',
    city: 'Ware Shoals',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenwood',
    latitude: 34.4025,
    longitude: -82.2678,
    postal_code: '29692'
  },
  '29693': {
    country: 'US',
    city: 'Westminster',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.6965,
    longitude: -83.1535,
    postal_code: '29693'
  },
  '29695': {
    country: 'US',
    city: 'Hodges',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenwood',
    latitude: 34.2876,
    longitude: -82.2443,
    postal_code: '29695'
  },
  '29696': {
    country: 'US',
    city: 'West Union',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Oconee',
    latitude: 34.7516,
    longitude: -83.0399,
    postal_code: '29696'
  },
  '29697': {
    country: 'US',
    city: 'Williamston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Anderson',
    latitude: 34.6206,
    longitude: -82.511,
    postal_code: '29697'
  },
  '29702': {
    country: 'US',
    city: 'Blacksburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Cherokee',
    latitude: 35.1095,
    longitude: -81.4942,
    postal_code: '29702'
  },
  '29703': {
    country: 'US',
    city: 'Bowling Green',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 35.1499,
    longitude: -81.2123,
    postal_code: '29703'
  },
  '29704': {
    country: 'US',
    city: 'Catawba',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 34.8507,
    longitude: -80.9342,
    postal_code: '29704'
  },
  '29706': {
    country: 'US',
    city: 'Chester',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chester',
    latitude: 34.7149,
    longitude: -81.2186,
    postal_code: '29706'
  },
  '29707': {
    country: 'US',
    city: 'Fort Mill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lancaster',
    latitude: 34.9773,
    longitude: -80.8584,
    postal_code: '29707'
  },
  '29708': {
    country: 'US',
    city: 'Fort Mill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 35.0502,
    longitude: -80.9908,
    postal_code: '29708'
  },
  '29709': {
    country: 'US',
    city: 'Chesterfield',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chesterfield',
    latitude: 34.7278,
    longitude: -80.0958,
    postal_code: '29709'
  },
  '29710': {
    country: 'US',
    city: 'Clover',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 35.1065,
    longitude: -81.2201,
    postal_code: '29710'
  },
  '29712': {
    country: 'US',
    city: 'Edgemoor',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chester',
    latitude: 34.797,
    longitude: -80.9891,
    postal_code: '29712'
  },
  '29714': {
    country: 'US',
    city: 'Fort Lawn',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chester',
    latitude: 34.7006,
    longitude: -80.9092,
    postal_code: '29714'
  },
  '29715': {
    country: 'US',
    city: 'Fort Mill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 35.0108,
    longitude: -80.9266,
    postal_code: '29715'
  },
  '29716': {
    country: 'US',
    city: 'Fort Mill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 35.0074,
    longitude: -80.9451,
    postal_code: '29716'
  },
  '29717': {
    country: 'US',
    city: 'Hickory Grove',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 34.9708,
    longitude: -81.4306,
    postal_code: '29717'
  },
  '29718': {
    country: 'US',
    city: 'Jefferson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chesterfield',
    latitude: 34.6546,
    longitude: -80.3639,
    postal_code: '29718'
  },
  '29720': {
    country: 'US',
    city: 'Lancaster',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lancaster',
    latitude: 34.749,
    longitude: -80.7616,
    postal_code: '29720'
  },
  '29721': {
    country: 'US',
    city: 'Lancaster',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lancaster',
    latitude: 34.6994,
    longitude: -80.7817,
    postal_code: '29721'
  },
  '29722': {
    country: 'US',
    city: 'Lancaster',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lancaster',
    latitude: 34.7673,
    longitude: -80.6589,
    postal_code: '29722'
  },
  '29724': {
    country: 'US',
    city: 'Lando',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chester',
    latitude: 34.7763,
    longitude: -81.0101,
    postal_code: '29724'
  },
  '29726': {
    country: 'US',
    city: 'Mc Connells',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 34.8677,
    longitude: -81.2368,
    postal_code: '29726'
  },
  '29727': {
    country: 'US',
    city: 'Mount Croghan',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chesterfield',
    latitude: 34.7439,
    longitude: -80.2428,
    postal_code: '29727'
  },
  '29728': {
    country: 'US',
    city: 'Pageland',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chesterfield',
    latitude: 34.7722,
    longitude: -80.3966,
    postal_code: '29728'
  },
  '29729': {
    country: 'US',
    city: 'Richburg',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chester',
    latitude: 34.6822,
    longitude: -80.9891,
    postal_code: '29729'
  },
  '29730': {
    country: 'US',
    city: 'Rock Hill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 34.9151,
    longitude: -81.0129,
    postal_code: '29730'
  },
  '29731': {
    country: 'US',
    city: 'Rock Hill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 34.9926,
    longitude: -81.1787,
    postal_code: '29731'
  },
  '29732': {
    country: 'US',
    city: 'Rock Hill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 34.9681,
    longitude: -81.0489,
    postal_code: '29732'
  },
  '29733': {
    country: 'US',
    city: 'Rock Hill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 34.9926,
    longitude: -81.1787,
    postal_code: '29733'
  },
  '29734': {
    country: 'US',
    city: 'Rock Hill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 34.9926,
    longitude: -81.1787,
    postal_code: '29734'
  },
  '29741': {
    country: 'US',
    city: 'Ruby',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Chesterfield',
    latitude: 34.7379,
    longitude: -80.191,
    postal_code: '29741'
  },
  '29742': {
    country: 'US',
    city: 'Sharon',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 34.9084,
    longitude: -81.3729,
    postal_code: '29742'
  },
  '29743': {
    country: 'US',
    city: 'Smyrna',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 35.0308,
    longitude: -81.3896,
    postal_code: '29743'
  },
  '29744': {
    country: 'US',
    city: 'Van Wyck',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Lancaster',
    latitude: 34.8585,
    longitude: -80.8459,
    postal_code: '29744'
  },
  '29745': {
    country: 'US',
    city: 'York',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'York',
    latitude: 34.9947,
    longitude: -81.2245,
    postal_code: '29745'
  },
  '29801': {
    country: 'US',
    city: 'Aiken',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.553,
    longitude: -81.7194,
    postal_code: '29801'
  },
  '29802': {
    country: 'US',
    city: 'Aiken',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5604,
    longitude: -81.7196,
    postal_code: '29802'
  },
  '29803': {
    country: 'US',
    city: 'Aiken',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5059,
    longitude: -81.6951,
    postal_code: '29803'
  },
  '29804': {
    country: 'US',
    city: 'Aiken',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5377,
    longitude: -81.5999,
    postal_code: '29804'
  },
  '29805': {
    country: 'US',
    city: 'Aiken',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.6993,
    longitude: -81.6465,
    postal_code: '29805'
  },
  '29808': {
    country: 'US',
    city: 'Aiken',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5377,
    longitude: -81.5999,
    postal_code: '29808'
  },
  '29809': {
    country: 'US',
    city: 'New Ellenton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.413,
    longitude: -81.69,
    postal_code: '29809'
  },
  '29810': {
    country: 'US',
    city: 'Allendale',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Allendale',
    latitude: 33.0077,
    longitude: -81.3203,
    postal_code: '29810'
  },
  '29812': {
    country: 'US',
    city: 'Barnwell',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Barnwell',
    latitude: 33.2335,
    longitude: -81.3523,
    postal_code: '29812'
  },
  '29813': {
    country: 'US',
    city: 'Hilda',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Barnwell',
    latitude: 33.2735,
    longitude: -81.247,
    postal_code: '29813'
  },
  '29816': {
    country: 'US',
    city: 'Bath',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5046,
    longitude: -81.869,
    postal_code: '29816'
  },
  '29817': {
    country: 'US',
    city: 'Blackville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Barnwell',
    latitude: 33.3645,
    longitude: -81.2718,
    postal_code: '29817'
  },
  '29819': {
    country: 'US',
    city: 'Bradley',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenwood',
    latitude: 34.0326,
    longitude: -82.2027,
    postal_code: '29819'
  },
  '29821': {
    country: 'US',
    city: 'Clarks Hill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'McCormick',
    latitude: 33.6505,
    longitude: -82.1488,
    postal_code: '29821'
  },
  '29822': {
    country: 'US',
    city: 'Clearwater',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.4997,
    longitude: -81.8649,
    postal_code: '29822'
  },
  '29824': {
    country: 'US',
    city: 'Edgefield',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Edgefield',
    latitude: 33.8056,
    longitude: -81.966,
    postal_code: '29824'
  },
  '29826': {
    country: 'US',
    city: 'Elko',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Barnwell',
    latitude: 33.3804,
    longitude: -81.3796,
    postal_code: '29826'
  },
  '29827': {
    country: 'US',
    city: 'Fairfax',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Allendale',
    latitude: 32.9515,
    longitude: -81.2586,
    postal_code: '29827'
  },
  '29828': {
    country: 'US',
    city: 'Gloverville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5182,
    longitude: -81.8277,
    postal_code: '29828'
  },
  '29829': {
    country: 'US',
    city: 'Graniteville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.563,
    longitude: -81.8147,
    postal_code: '29829'
  },
  '29831': {
    country: 'US',
    city: 'Jackson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.3389,
    longitude: -81.7976,
    postal_code: '29831'
  },
  '29832': {
    country: 'US',
    city: 'Johnston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Edgefield',
    latitude: 33.8231,
    longitude: -81.7972,
    postal_code: '29832'
  },
  '29834': {
    country: 'US',
    city: 'Langley',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5179,
    longitude: -81.844,
    postal_code: '29834'
  },
  '29835': {
    country: 'US',
    city: 'Mc Cormick',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'McCormick',
    latitude: 33.9146,
    longitude: -82.2719,
    postal_code: '29835'
  },
  '29836': {
    country: 'US',
    city: 'Martin',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Allendale',
    latitude: 33.1093,
    longitude: -81.5003,
    postal_code: '29836'
  },
  '29838': {
    country: 'US',
    city: 'Modoc',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'McCormick',
    latitude: 33.7052,
    longitude: -82.2187,
    postal_code: '29838'
  },
  '29839': {
    country: 'US',
    city: 'Montmorenci',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5073,
    longitude: -81.5992,
    postal_code: '29839'
  },
  '29840': {
    country: 'US',
    city: 'Mount Carmel',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'McCormick',
    latitude: 34.0065,
    longitude: -82.5324,
    postal_code: '29840'
  },
  '29841': {
    country: 'US',
    city: 'North Augusta',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5251,
    longitude: -81.9394,
    postal_code: '29841'
  },
  '29842': {
    country: 'US',
    city: 'Beech Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.4707,
    longitude: -81.8639,
    postal_code: '29842'
  },
  '29843': {
    country: 'US',
    city: 'Olar',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Bamberg',
    latitude: 33.1836,
    longitude: -81.164,
    postal_code: '29843'
  },
  '29844': {
    country: 'US',
    city: 'Parksville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'McCormick',
    latitude: 33.7854,
    longitude: -82.2111,
    postal_code: '29844'
  },
  '29845': {
    country: 'US',
    city: 'Plum Branch',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'McCormick',
    latitude: 33.8329,
    longitude: -82.248,
    postal_code: '29845'
  },
  '29846': {
    country: 'US',
    city: 'Sycamore',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Allendale',
    latitude: 33.0241,
    longitude: -81.2258,
    postal_code: '29846'
  },
  '29847': {
    country: 'US',
    city: 'Trenton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Edgefield',
    latitude: 33.6938,
    longitude: -81.8534,
    postal_code: '29847'
  },
  '29848': {
    country: 'US',
    city: 'Troy',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Greenwood',
    latitude: 33.9876,
    longitude: -82.2979,
    postal_code: '29848'
  },
  '29849': {
    country: 'US',
    city: 'Ulmer',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Allendale',
    latitude: 33.0988,
    longitude: -81.2068,
    postal_code: '29849'
  },
  '29850': {
    country: 'US',
    city: 'Vaucluse',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.6126,
    longitude: -81.8098,
    postal_code: '29850'
  },
  '29851': {
    country: 'US',
    city: 'Warrenville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.526,
    longitude: -81.7956,
    postal_code: '29851'
  },
  '29853': {
    country: 'US',
    city: 'Williston',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Barnwell',
    latitude: 33.3926,
    longitude: -81.4161,
    postal_code: '29853'
  },
  '29856': {
    country: 'US',
    city: 'Windsor',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.4674,
    longitude: -81.5136,
    postal_code: '29856'
  },
  '29860': {
    country: 'US',
    city: 'North Augusta',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.6028,
    longitude: -81.9748,
    postal_code: '29860'
  },
  '29861': {
    country: 'US',
    city: 'North Augusta',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Aiken',
    latitude: 33.5018,
    longitude: -81.9651,
    postal_code: '29861'
  },
  '29899': {
    country: 'US',
    city: 'Mc Cormick',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'McCormick',
    latitude: 33.8356,
    longitude: -82.32,
    postal_code: '29899'
  },
  '29901': {
    country: 'US',
    city: 'Beaufort',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.4244,
    longitude: -80.5653,
    postal_code: '29901'
  },
  '29902': {
    country: 'US',
    city: 'Beaufort',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.4316,
    longitude: -80.6698,
    postal_code: '29902'
  },
  '29903': {
    country: 'US',
    city: 'Beaufort',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.444,
    longitude: -80.7352,
    postal_code: '29903'
  },
  '29904': {
    country: 'US',
    city: 'Beaufort',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.3906,
    longitude: -80.661,
    postal_code: '29904'
  },
  '29905': {
    country: 'US',
    city: 'Parris Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.3281,
    longitude: -80.6915,
    postal_code: '29905'
  },
  '29906': {
    country: 'US',
    city: 'Beaufort',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.3823,
    longitude: -80.7603,
    postal_code: '29906'
  },
  '29907': {
    country: 'US',
    city: 'Beaufort',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.4316,
    longitude: -80.6698,
    postal_code: '29907'
  },
  '29909': {
    country: 'US',
    city: 'Okatie',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Jasper',
    latitude: 32.3301,
    longitude: -80.8435,
    postal_code: '29909'
  },
  '29910': {
    country: 'US',
    city: 'Bluffton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.2513,
    longitude: -80.8721,
    postal_code: '29910'
  },
  '29911': {
    country: 'US',
    city: 'Brunson',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.9413,
    longitude: -81.1807,
    postal_code: '29911'
  },
  '29912': {
    country: 'US',
    city: 'Coosawhatchie',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Jasper',
    latitude: 32.5888,
    longitude: -80.9273,
    postal_code: '29912'
  },
  '29913': {
    country: 'US',
    city: 'Crocketville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.9166,
    longitude: -81.0773,
    postal_code: '29913'
  },
  '29914': {
    country: 'US',
    city: 'Dale',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.5527,
    longitude: -80.677,
    postal_code: '29914'
  },
  '29915': {
    country: 'US',
    city: 'Daufuskie Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.1145,
    longitude: -80.8686,
    postal_code: '29915'
  },
  '29916': {
    country: 'US',
    city: 'Early Branch',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.723,
    longitude: -80.9634,
    postal_code: '29916'
  },
  '29918': {
    country: 'US',
    city: 'Estill',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.7375,
    longitude: -81.2178,
    postal_code: '29918'
  },
  '29920': {
    country: 'US',
    city: 'Saint Helena Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.3716,
    longitude: -80.5383,
    postal_code: '29920'
  },
  '29921': {
    country: 'US',
    city: 'Furman',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.6816,
    longitude: -81.1882,
    postal_code: '29921'
  },
  '29922': {
    country: 'US',
    city: 'Garnett',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.6007,
    longitude: -81.2396,
    postal_code: '29922'
  },
  '29923': {
    country: 'US',
    city: 'Gifford',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.8596,
    longitude: -81.239,
    postal_code: '29923'
  },
  '29924': {
    country: 'US',
    city: 'Hampton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.8729,
    longitude: -81.0973,
    postal_code: '29924'
  },
  '29925': {
    country: 'US',
    city: 'Hilton Head Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.2132,
    longitude: -80.7997,
    postal_code: '29925'
  },
  '29926': {
    country: 'US',
    city: 'Hilton Head Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.2077,
    longitude: -80.7476,
    postal_code: '29926'
  },
  '29927': {
    country: 'US',
    city: 'Hardeeville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Jasper',
    latitude: 32.2635,
    longitude: -81.0669,
    postal_code: '29927'
  },
  '29928': {
    country: 'US',
    city: 'Hilton Head Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.1665,
    longitude: -80.7569,
    postal_code: '29928'
  },
  '29929': {
    country: 'US',
    city: 'Islandton',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Colleton',
    latitude: 32.9503,
    longitude: -80.8916,
    postal_code: '29929'
  },
  '29931': {
    country: 'US',
    city: 'Lobeco',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.5527,
    longitude: -80.7434,
    postal_code: '29931'
  },
  '29932': {
    country: 'US',
    city: 'Luray',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.8138,
    longitude: -81.2398,
    postal_code: '29932'
  },
  '29933': {
    country: 'US',
    city: 'Miley',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.9479,
    longitude: -81.0318,
    postal_code: '29933'
  },
  '29934': {
    country: 'US',
    city: 'Pineland',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Jasper',
    latitude: 32.5931,
    longitude: -81.1078,
    postal_code: '29934'
  },
  '29935': {
    country: 'US',
    city: 'Port Royal',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.3874,
    longitude: -80.6844,
    postal_code: '29935'
  },
  '29936': {
    country: 'US',
    city: 'Ridgeland',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Jasper',
    latitude: 32.4807,
    longitude: -80.9804,
    postal_code: '29936'
  },
  '29938': {
    country: 'US',
    city: 'Hilton Head Island',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.3906,
    longitude: -80.661,
    postal_code: '29938'
  },
  '29939': {
    country: 'US',
    city: 'Scotia',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.6786,
    longitude: -81.2455,
    postal_code: '29939'
  },
  '29940': {
    country: 'US',
    city: 'Seabrook',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.5693,
    longitude: -80.7192,
    postal_code: '29940'
  },
  '29941': {
    country: 'US',
    city: 'Sheldon',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Beaufort',
    latitude: 32.5431,
    longitude: -80.8126,
    postal_code: '29941'
  },
  '29943': {
    country: 'US',
    city: 'Tillman',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Jasper',
    latitude: 32.468,
    longitude: -81.1667,
    postal_code: '29943'
  },
  '29944': {
    country: 'US',
    city: 'Varnville',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.8349,
    longitude: -81.0288,
    postal_code: '29944'
  },
  '29945': {
    country: 'US',
    city: 'Yemassee',
    state: 'South Carolina',
    state_short: 'SC',
    county: 'Hampton',
    latitude: 32.643,
    longitude: -80.8129,
    postal_code: '29945'
  },
  '30002': {
    country: 'US',
    city: 'Avondale Estates',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7717,
    longitude: -84.2607,
    postal_code: '30002'
  },
  '30003': {
    country: 'US',
    city: 'Norcross',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9604,
    longitude: -84.0379,
    postal_code: '30003'
  },
  '30004': {
    country: 'US',
    city: 'Alpharetta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 34.1124,
    longitude: -84.302,
    postal_code: '30004'
  },
  '30005': {
    country: 'US',
    city: 'Alpharetta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 34.0782,
    longitude: -84.2281,
    postal_code: '30005'
  },
  '30006': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9526,
    longitude: -84.5499,
    postal_code: '30006'
  },
  '30007': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9125,
    longitude: -84.5572,
    postal_code: '30007'
  },
  '30008': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.8972,
    longitude: -84.592,
    postal_code: '30008'
  },
  '30009': {
    country: 'US',
    city: 'Alpharetta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 34.077,
    longitude: -84.3033,
    postal_code: '30009'
  },
  '30010': {
    country: 'US',
    city: 'Norcross',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9604,
    longitude: -84.0379,
    postal_code: '30010'
  },
  '30011': {
    country: 'US',
    city: 'Auburn',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Barrow',
    latitude: 34.0191,
    longitude: -83.8261,
    postal_code: '30011'
  },
  '30012': {
    country: 'US',
    city: 'Conyers',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rockdale',
    latitude: 33.7192,
    longitude: -84.0021,
    postal_code: '30012'
  },
  '30013': {
    country: 'US',
    city: 'Conyers',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rockdale',
    latitude: 33.6436,
    longitude: -83.9684,
    postal_code: '30013'
  },
  '30014': {
    country: 'US',
    city: 'Covington',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Newton',
    latitude: 33.5293,
    longitude: -83.8496,
    postal_code: '30014'
  },
  '30015': {
    country: 'US',
    city: 'Covington',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Newton',
    latitude: 33.5968,
    longitude: -83.8602,
    postal_code: '30015'
  },
  '30016': {
    country: 'US',
    city: 'Covington',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Newton',
    latitude: 33.5146,
    longitude: -83.8626,
    postal_code: '30016'
  },
  '30017': {
    country: 'US',
    city: 'Grayson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.8901,
    longitude: -83.9632,
    postal_code: '30017'
  },
  '30018': {
    country: 'US',
    city: 'Jersey',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walton',
    latitude: 33.718,
    longitude: -83.8015,
    postal_code: '30018'
  },
  '30019': {
    country: 'US',
    city: 'Dacula',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9883,
    longitude: -83.8795,
    postal_code: '30019'
  },
  '30021': {
    country: 'US',
    city: 'Clarkston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8101,
    longitude: -84.2388,
    postal_code: '30021'
  },
  '30022': {
    country: 'US',
    city: 'Alpharetta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 34.0268,
    longitude: -84.2422,
    postal_code: '30022'
  },
  '30023': {
    country: 'US',
    city: 'Alpharetta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 34.0754,
    longitude: -84.2941,
    postal_code: '30023'
  },
  '30024': {
    country: 'US',
    city: 'Suwanee',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 34.0425,
    longitude: -84.0262,
    postal_code: '30024'
  },
  '30025': {
    country: 'US',
    city: 'Social Circle',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walton',
    latitude: 33.6791,
    longitude: -83.6835,
    postal_code: '30025'
  },
  '30026': {
    country: 'US',
    city: 'North Metro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9792,
    longitude: -84.1263,
    postal_code: '30026'
  },
  '30028': {
    country: 'US',
    city: 'Cumming',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Forsyth',
    latitude: 34.2897,
    longitude: -84.1796,
    postal_code: '30028'
  },
  '30029': {
    country: 'US',
    city: 'North Metro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9792,
    longitude: -84.1263,
    postal_code: '30029'
  },
  '30030': {
    country: 'US',
    city: 'Decatur',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7699,
    longitude: -84.295,
    postal_code: '30030'
  },
  '30031': {
    country: 'US',
    city: 'Decatur',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7748,
    longitude: -84.2963,
    postal_code: '30031'
  },
  '30032': {
    country: 'US',
    city: 'Decatur',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7408,
    longitude: -84.2632,
    postal_code: '30032'
  },
  '30033': {
    country: 'US',
    city: 'Decatur',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8123,
    longitude: -84.2819,
    postal_code: '30033'
  },
  '30034': {
    country: 'US',
    city: 'Decatur',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.6954,
    longitude: -84.2489,
    postal_code: '30034'
  },
  '30035': {
    country: 'US',
    city: 'Decatur',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7278,
    longitude: -84.2143,
    postal_code: '30035'
  },
  '30036': {
    country: 'US',
    city: 'Decatur',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7748,
    longitude: -84.2963,
    postal_code: '30036'
  },
  '30037': {
    country: 'US',
    city: 'Decatur',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7748,
    longitude: -84.2963,
    postal_code: '30037'
  },
  '30038': {
    country: 'US',
    city: 'Lithonia',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.6823,
    longitude: -84.161,
    postal_code: '30038'
  },
  '30039': {
    country: 'US',
    city: 'Snellville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.8178,
    longitude: -84.0229,
    postal_code: '30039'
  },
  '30040': {
    country: 'US',
    city: 'Cumming',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Forsyth',
    latitude: 34.2321,
    longitude: -84.158,
    postal_code: '30040'
  },
  '30041': {
    country: 'US',
    city: 'Cumming',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Forsyth',
    latitude: 34.2037,
    longitude: -84.1031,
    postal_code: '30041'
  },
  '30042': {
    country: 'US',
    city: 'Lawrenceville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9295,
    longitude: -84.1032,
    postal_code: '30042'
  },
  '30043': {
    country: 'US',
    city: 'Lawrenceville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 34.0031,
    longitude: -84.0126,
    postal_code: '30043'
  },
  '30044': {
    country: 'US',
    city: 'Lawrenceville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9418,
    longitude: -84.0706,
    postal_code: '30044'
  },
  '30045': {
    country: 'US',
    city: 'Lawrenceville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9367,
    longitude: -83.9573,
    postal_code: '30045'
  },
  '30046': {
    country: 'US',
    city: 'Lawrenceville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9496,
    longitude: -83.9942,
    postal_code: '30046'
  },
  '30047': {
    country: 'US',
    city: 'Lilburn',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.8656,
    longitude: -84.0725,
    postal_code: '30047'
  },
  '30048': {
    country: 'US',
    city: 'Lilburn',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9604,
    longitude: -84.0379,
    postal_code: '30048'
  },
  '30049': {
    country: 'US',
    city: 'Lawrenceville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9495,
    longitude: -83.9922,
    postal_code: '30049'
  },
  '30052': {
    country: 'US',
    city: 'Loganville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.8769,
    longitude: -83.8968,
    postal_code: '30052'
  },
  '30054': {
    country: 'US',
    city: 'Oxford',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Newton',
    latitude: 33.6706,
    longitude: -83.874,
    postal_code: '30054'
  },
  '30055': {
    country: 'US',
    city: 'Mansfield',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jasper',
    latitude: 33.4314,
    longitude: -83.7908,
    postal_code: '30055'
  },
  '30056': {
    country: 'US',
    city: 'Newborn',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Newton',
    latitude: 33.5149,
    longitude: -83.7072,
    postal_code: '30056'
  },
  '30058': {
    country: 'US',
    city: 'Lithonia',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7356,
    longitude: -84.1009,
    postal_code: '30058'
  },
  '30060': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9382,
    longitude: -84.5403,
    postal_code: '30060'
  },
  '30061': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9328,
    longitude: -84.556,
    postal_code: '30061'
  },
  '30062': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 34.0025,
    longitude: -84.4633,
    postal_code: '30062'
  },
  '30063': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9653,
    longitude: -84.5112,
    postal_code: '30063'
  },
  '30064': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9343,
    longitude: -84.6076,
    postal_code: '30064'
  },
  '30065': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9125,
    longitude: -84.5572,
    postal_code: '30065'
  },
  '30066': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 34.0378,
    longitude: -84.5038,
    postal_code: '30066'
  },
  '30067': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9282,
    longitude: -84.4733,
    postal_code: '30067'
  },
  '30068': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9679,
    longitude: -84.4385,
    postal_code: '30068'
  },
  '30069': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9125,
    longitude: -84.5572,
    postal_code: '30069'
  },
  '30070': {
    country: 'US',
    city: 'Porterdale',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Newton',
    latitude: 33.5712,
    longitude: -83.8951,
    postal_code: '30070'
  },
  '30071': {
    country: 'US',
    city: 'Norcross',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9381,
    longitude: -84.1972,
    postal_code: '30071'
  },
  '30072': {
    country: 'US',
    city: 'Pine Lake',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7906,
    longitude: -84.2053,
    postal_code: '30072'
  },
  '30074': {
    country: 'US',
    city: 'Redan',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7454,
    longitude: -84.1316,
    postal_code: '30074'
  },
  '30075': {
    country: 'US',
    city: 'Roswell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 34.0408,
    longitude: -84.3859,
    postal_code: '30075'
  },
  '30076': {
    country: 'US',
    city: 'Roswell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 34.0213,
    longitude: -84.3104,
    postal_code: '30076'
  },
  '30077': {
    country: 'US',
    city: 'Roswell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 34.0232,
    longitude: -84.3616,
    postal_code: '30077'
  },
  '30078': {
    country: 'US',
    city: 'Snellville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.8635,
    longitude: -84.0081,
    postal_code: '30078'
  },
  '30079': {
    country: 'US',
    city: 'Scottdale',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7934,
    longitude: -84.2585,
    postal_code: '30079'
  },
  '30080': {
    country: 'US',
    city: 'Smyrna',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.8796,
    longitude: -84.5023,
    postal_code: '30080'
  },
  '30081': {
    country: 'US',
    city: 'Smyrna',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.8588,
    longitude: -84.7106,
    postal_code: '30081'
  },
  '30082': {
    country: 'US',
    city: 'Smyrna',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.8631,
    longitude: -84.5382,
    postal_code: '30082'
  },
  '30083': {
    country: 'US',
    city: 'Stone Mountain',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7942,
    longitude: -84.2018,
    postal_code: '30083'
  },
  '30084': {
    country: 'US',
    city: 'Tucker',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.857,
    longitude: -84.216,
    postal_code: '30084'
  },
  '30085': {
    country: 'US',
    city: 'Tucker',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8545,
    longitude: -84.2171,
    postal_code: '30085'
  },
  '30086': {
    country: 'US',
    city: 'Stone Mountain',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '30086'
  },
  '30087': {
    country: 'US',
    city: 'Stone Mountain',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8082,
    longitude: -84.1702,
    postal_code: '30087'
  },
  '30088': {
    country: 'US',
    city: 'Stone Mountain',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.758,
    longitude: -84.1802,
    postal_code: '30088'
  },
  '30090': {
    country: 'US',
    city: 'Marietta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9525,
    longitude: -84.5471,
    postal_code: '30090'
  },
  '30091': {
    country: 'US',
    city: 'Norcross',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9604,
    longitude: -84.0379,
    postal_code: '30091'
  },
  '30092': {
    country: 'US',
    city: 'Peachtree Corners',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9701,
    longitude: -84.2216,
    postal_code: '30092'
  },
  '30093': {
    country: 'US',
    city: 'Norcross',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.906,
    longitude: -84.184,
    postal_code: '30093'
  },
  '30094': {
    country: 'US',
    city: 'Conyers',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rockdale',
    latitude: 33.6111,
    longitude: -84.0683,
    postal_code: '30094'
  },
  '30095': {
    country: 'US',
    city: 'Duluth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 34.0256,
    longitude: -84.1304,
    postal_code: '30095'
  },
  '30096': {
    country: 'US',
    city: 'Duluth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9845,
    longitude: -84.1529,
    postal_code: '30096'
  },
  '30097': {
    country: 'US',
    city: 'Duluth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 34.026,
    longitude: -84.147,
    postal_code: '30097'
  },
  '30098': {
    country: 'US',
    city: 'Duluth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9604,
    longitude: -84.0379,
    postal_code: '30098'
  },
  '30099': {
    country: 'US',
    city: 'Duluth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9595,
    longitude: -84.105,
    postal_code: '30099'
  },
  '30101': {
    country: 'US',
    city: 'Acworth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 34.0756,
    longitude: -84.6477,
    postal_code: '30101'
  },
  '30102': {
    country: 'US',
    city: 'Acworth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 34.0707,
    longitude: -84.5894,
    postal_code: '30102'
  },
  '30103': {
    country: 'US',
    city: 'Adairsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bartow',
    latitude: 34.3595,
    longitude: -84.9176,
    postal_code: '30103'
  },
  '30104': {
    country: 'US',
    city: 'Aragon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Polk',
    latitude: 34.0666,
    longitude: -85.0696,
    postal_code: '30104'
  },
  '30105': {
    country: 'US',
    city: 'Armuchee',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.4411,
    longitude: -85.1843,
    postal_code: '30105'
  },
  '30106': {
    country: 'US',
    city: 'Austell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.8369,
    longitude: -84.6307,
    postal_code: '30106'
  },
  '30107': {
    country: 'US',
    city: 'Ball Ground',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cherokee',
    latitude: 34.3393,
    longitude: -84.3758,
    postal_code: '30107'
  },
  '30108': {
    country: 'US',
    city: 'Bowdon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.5373,
    longitude: -85.2533,
    postal_code: '30108'
  },
  '30109': {
    country: 'US',
    city: 'Bowdon Junction',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.6534,
    longitude: -85.1362,
    postal_code: '30109'
  },
  '30110': {
    country: 'US',
    city: 'Bremen',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Haralson',
    latitude: 33.7309,
    longitude: -85.1286,
    postal_code: '30110'
  },
  '30111': {
    country: 'US',
    city: 'Clarkdale',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9125,
    longitude: -84.5572,
    postal_code: '30111'
  },
  '30112': {
    country: 'US',
    city: 'Carrollton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.5809,
    longitude: -85.0792,
    postal_code: '30112'
  },
  '30113': {
    country: 'US',
    city: 'Buchanan',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Haralson',
    latitude: 33.8283,
    longitude: -85.1506,
    postal_code: '30113'
  },
  '30114': {
    country: 'US',
    city: 'Canton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cherokee',
    latitude: 34.2505,
    longitude: -84.4909,
    postal_code: '30114'
  },
  '30115': {
    country: 'US',
    city: 'Canton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cherokee',
    latitude: 34.1993,
    longitude: -84.4199,
    postal_code: '30115'
  },
  '30116': {
    country: 'US',
    city: 'Carrollton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.6045,
    longitude: -85.0499,
    postal_code: '30116'
  },
  '30117': {
    country: 'US',
    city: 'Carrollton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.5798,
    longitude: -85.0812,
    postal_code: '30117'
  },
  '30118': {
    country: 'US',
    city: 'Carrollton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.5712,
    longitude: -85.0961,
    postal_code: '30118'
  },
  '30119': {
    country: 'US',
    city: 'Carrollton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.6189,
    longitude: -85.0736,
    postal_code: '30119'
  },
  '30120': {
    country: 'US',
    city: 'Cartersville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bartow',
    latitude: 34.187,
    longitude: -84.8204,
    postal_code: '30120'
  },
  '30121': {
    country: 'US',
    city: 'Cartersville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bartow',
    latitude: 34.2079,
    longitude: -84.7673,
    postal_code: '30121'
  },
  '30122': {
    country: 'US',
    city: 'Lithia Springs',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Douglas',
    latitude: 33.7655,
    longitude: -84.6469,
    postal_code: '30122'
  },
  '30123': {
    country: 'US',
    city: 'Cassville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bartow',
    latitude: 34.2442,
    longitude: -84.8457,
    postal_code: '30123'
  },
  '30124': {
    country: 'US',
    city: 'Cave Spring',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.1167,
    longitude: -85.3379,
    postal_code: '30124'
  },
  '30125': {
    country: 'US',
    city: 'Cedartown',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Polk',
    latitude: 34.0112,
    longitude: -85.2459,
    postal_code: '30125'
  },
  '30126': {
    country: 'US',
    city: 'Mableton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.8332,
    longitude: -84.6031,
    postal_code: '30126'
  },
  '30127': {
    country: 'US',
    city: 'Powder Springs',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9135,
    longitude: -84.6859,
    postal_code: '30127'
  },
  '30129': {
    country: 'US',
    city: 'Coosa',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.3333,
    longitude: -85.2337,
    postal_code: '30129'
  },
  '30132': {
    country: 'US',
    city: 'Dallas',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Paulding',
    latitude: 33.9163,
    longitude: -84.8278,
    postal_code: '30132'
  },
  '30133': {
    country: 'US',
    city: 'Douglasville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Douglas',
    latitude: 33.6897,
    longitude: -84.7446,
    postal_code: '30133'
  },
  '30134': {
    country: 'US',
    city: 'Douglasville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Douglas',
    latitude: 33.7606,
    longitude: -84.7477,
    postal_code: '30134'
  },
  '30135': {
    country: 'US',
    city: 'Douglasville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Douglas',
    latitude: 33.6989,
    longitude: -84.7454,
    postal_code: '30135'
  },
  '30137': {
    country: 'US',
    city: 'Emerson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bartow',
    latitude: 34.12,
    longitude: -84.757,
    postal_code: '30137'
  },
  '30138': {
    country: 'US',
    city: 'Esom Hill',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Polk',
    latitude: 33.949,
    longitude: -85.3877,
    postal_code: '30138'
  },
  '30139': {
    country: 'US',
    city: 'Fairmount',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gordon',
    latitude: 34.4362,
    longitude: -84.6999,
    postal_code: '30139'
  },
  '30140': {
    country: 'US',
    city: 'Felton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Haralson',
    latitude: 33.8823,
    longitude: -85.2349,
    postal_code: '30140'
  },
  '30141': {
    country: 'US',
    city: 'Hiram',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Paulding',
    latitude: 33.8673,
    longitude: -84.7699,
    postal_code: '30141'
  },
  '30142': {
    country: 'US',
    city: 'Holly Springs',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cherokee',
    latitude: 34.174,
    longitude: -84.5013,
    postal_code: '30142'
  },
  '30143': {
    country: 'US',
    city: 'Jasper',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pickens',
    latitude: 34.462,
    longitude: -84.4759,
    postal_code: '30143'
  },
  '30144': {
    country: 'US',
    city: 'Kennesaw',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 34.0287,
    longitude: -84.6047,
    postal_code: '30144'
  },
  '30145': {
    country: 'US',
    city: 'Kingston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bartow',
    latitude: 34.2501,
    longitude: -84.9973,
    postal_code: '30145'
  },
  '30146': {
    country: 'US',
    city: 'Lebanon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cherokee',
    latitude: 34.2429,
    longitude: -84.4583,
    postal_code: '30146'
  },
  '30147': {
    country: 'US',
    city: 'Lindale',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.1707,
    longitude: -85.1825,
    postal_code: '30147'
  },
  '30148': {
    country: 'US',
    city: 'Marble Hill',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pickens',
    latitude: 34.4398,
    longitude: -84.303,
    postal_code: '30148'
  },
  '30149': {
    country: 'US',
    city: 'Mount Berry',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.2804,
    longitude: -85.1821,
    postal_code: '30149'
  },
  '30150': {
    country: 'US',
    city: 'Mount Zion',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.643,
    longitude: -85.1818,
    postal_code: '30150'
  },
  '30151': {
    country: 'US',
    city: 'Nelson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cherokee',
    latitude: 34.382,
    longitude: -84.371,
    postal_code: '30151'
  },
  '30152': {
    country: 'US',
    city: 'Kennesaw',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.9951,
    longitude: -84.6544,
    postal_code: '30152'
  },
  '30153': {
    country: 'US',
    city: 'Rockmart',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Polk',
    latitude: 33.9979,
    longitude: -85.0594,
    postal_code: '30153'
  },
  '30154': {
    country: 'US',
    city: 'Douglasville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Douglas',
    latitude: 33.7515,
    longitude: -84.7477,
    postal_code: '30154'
  },
  '30156': {
    country: 'US',
    city: 'Kennesaw',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 34.0177,
    longitude: -84.625,
    postal_code: '30156'
  },
  '30157': {
    country: 'US',
    city: 'Dallas',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Paulding',
    latitude: 33.9045,
    longitude: -84.8621,
    postal_code: '30157'
  },
  '30160': {
    country: 'US',
    city: 'Kennesaw',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 34.0177,
    longitude: -84.625,
    postal_code: '30160'
  },
  '30161': {
    country: 'US',
    city: 'Rome',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.2507,
    longitude: -85.1465,
    postal_code: '30161'
  },
  '30162': {
    country: 'US',
    city: 'Rome',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.2905,
    longitude: -85.2138,
    postal_code: '30162'
  },
  '30163': {
    country: 'US',
    city: 'Rome',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.3333,
    longitude: -85.2337,
    postal_code: '30163'
  },
  '30164': {
    country: 'US',
    city: 'Rome',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.3333,
    longitude: -85.2337,
    postal_code: '30164'
  },
  '30165': {
    country: 'US',
    city: 'Rome',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.2837,
    longitude: -85.2231,
    postal_code: '30165'
  },
  '30168': {
    country: 'US',
    city: 'Austell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 33.7838,
    longitude: -84.5952,
    postal_code: '30168'
  },
  '30169': {
    country: 'US',
    city: 'Canton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cherokee',
    latitude: 34.234,
    longitude: -84.4904,
    postal_code: '30169'
  },
  '30170': {
    country: 'US',
    city: 'Roopville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.4322,
    longitude: -85.1671,
    postal_code: '30170'
  },
  '30171': {
    country: 'US',
    city: 'Rydal',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bartow',
    latitude: 34.3379,
    longitude: -84.7376,
    postal_code: '30171'
  },
  '30172': {
    country: 'US',
    city: 'Shannon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.3333,
    longitude: -85.2337,
    postal_code: '30172'
  },
  '30173': {
    country: 'US',
    city: 'Silver Creek',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Floyd',
    latitude: 34.1593,
    longitude: -85.1429,
    postal_code: '30173'
  },
  '30175': {
    country: 'US',
    city: 'Talking Rock',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pickens',
    latitude: 34.5394,
    longitude: -84.4912,
    postal_code: '30175'
  },
  '30176': {
    country: 'US',
    city: 'Tallapoosa',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Haralson',
    latitude: 33.7602,
    longitude: -85.3,
    postal_code: '30176'
  },
  '30177': {
    country: 'US',
    city: 'Tate',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pickens',
    latitude: 34.4027,
    longitude: -84.3785,
    postal_code: '30177'
  },
  '30178': {
    country: 'US',
    city: 'Taylorsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bartow',
    latitude: 34.1229,
    longitude: -84.9739,
    postal_code: '30178'
  },
  '30179': {
    country: 'US',
    city: 'Temple',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.7677,
    longitude: -85.0133,
    postal_code: '30179'
  },
  '30180': {
    country: 'US',
    city: 'Villa Rica',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.7173,
    longitude: -84.9297,
    postal_code: '30180'
  },
  '30182': {
    country: 'US',
    city: 'Waco',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Haralson',
    latitude: 33.684,
    longitude: -85.2197,
    postal_code: '30182'
  },
  '30183': {
    country: 'US',
    city: 'Waleska',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cherokee',
    latitude: 34.3217,
    longitude: -84.562,
    postal_code: '30183'
  },
  '30184': {
    country: 'US',
    city: 'White',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bartow',
    latitude: 34.2717,
    longitude: -84.7383,
    postal_code: '30184'
  },
  '30185': {
    country: 'US',
    city: 'Whitesburg',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Carroll',
    latitude: 33.5111,
    longitude: -84.9254,
    postal_code: '30185'
  },
  '30187': {
    country: 'US',
    city: 'Winston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Douglas',
    latitude: 33.6634,
    longitude: -84.8639,
    postal_code: '30187'
  },
  '30188': {
    country: 'US',
    city: 'Woodstock',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cherokee',
    latitude: 34.106,
    longitude: -84.5117,
    postal_code: '30188'
  },
  '30189': {
    country: 'US',
    city: 'Woodstock',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cherokee',
    latitude: 34.1281,
    longitude: -84.5717,
    postal_code: '30189'
  },
  '30204': {
    country: 'US',
    city: 'Barnesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lamar',
    latitude: 33.0457,
    longitude: -84.1515,
    postal_code: '30204'
  },
  '30205': {
    country: 'US',
    city: 'Brooks',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fayette',
    latitude: 33.2984,
    longitude: -84.4769,
    postal_code: '30205'
  },
  '30206': {
    country: 'US',
    city: 'Concord',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pike',
    latitude: 33.0998,
    longitude: -84.447,
    postal_code: '30206'
  },
  '30212': {
    country: 'US',
    city: 'Experiment',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Spalding',
    latitude: 33.2782,
    longitude: -84.2888,
    postal_code: '30212'
  },
  '30213': {
    country: 'US',
    city: 'Fairburn',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.5648,
    longitude: -84.5809,
    postal_code: '30213'
  },
  '30214': {
    country: 'US',
    city: 'Fayetteville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fayette',
    latitude: 33.4679,
    longitude: -84.4806,
    postal_code: '30214'
  },
  '30215': {
    country: 'US',
    city: 'Fayetteville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fayette',
    latitude: 33.3943,
    longitude: -84.4738,
    postal_code: '30215'
  },
  '30216': {
    country: 'US',
    city: 'Flovilla',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Butts',
    latitude: 33.25,
    longitude: -83.9079,
    postal_code: '30216'
  },
  '30217': {
    country: 'US',
    city: 'Franklin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Heard',
    latitude: 33.278,
    longitude: -85.134,
    postal_code: '30217'
  },
  '30218': {
    country: 'US',
    city: 'Gay',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Meriwether',
    latitude: 33.1274,
    longitude: -84.5836,
    postal_code: '30218'
  },
  '30220': {
    country: 'US',
    city: 'Grantville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.2473,
    longitude: -84.835,
    postal_code: '30220'
  },
  '30222': {
    country: 'US',
    city: 'Greenville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Meriwether',
    latitude: 33.0468,
    longitude: -84.7402,
    postal_code: '30222'
  },
  '30223': {
    country: 'US',
    city: 'Griffin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Spalding',
    latitude: 33.2549,
    longitude: -84.2728,
    postal_code: '30223'
  },
  '30224': {
    country: 'US',
    city: 'Griffin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Spalding',
    latitude: 33.2404,
    longitude: -84.2734,
    postal_code: '30224'
  },
  '30228': {
    country: 'US',
    city: 'Hampton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Henry',
    latitude: 33.4124,
    longitude: -84.2947,
    postal_code: '30228'
  },
  '30229': {
    country: 'US',
    city: 'Haralson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.2322,
    longitude: -84.5685,
    postal_code: '30229'
  },
  '30230': {
    country: 'US',
    city: 'Hogansville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Troup',
    latitude: 33.1644,
    longitude: -84.9309,
    postal_code: '30230'
  },
  '30233': {
    country: 'US',
    city: 'Jackson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Butts',
    latitude: 33.282,
    longitude: -83.9784,
    postal_code: '30233'
  },
  '30234': {
    country: 'US',
    city: 'Jenkinsburg',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Butts',
    latitude: 33.3224,
    longitude: -84.0287,
    postal_code: '30234'
  },
  '30236': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.5242,
    longitude: -84.359,
    postal_code: '30236'
  },
  '30237': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.5007,
    longitude: -84.3513,
    postal_code: '30237'
  },
  '30238': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.4944,
    longitude: -84.3797,
    postal_code: '30238'
  },
  '30240': {
    country: 'US',
    city: 'Lagrange',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Troup',
    latitude: 33.0243,
    longitude: -85.0739,
    postal_code: '30240'
  },
  '30241': {
    country: 'US',
    city: 'Lagrange',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Troup',
    latitude: 33.0249,
    longitude: -84.9577,
    postal_code: '30241'
  },
  '30248': {
    country: 'US',
    city: 'Locust Grove',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Henry',
    latitude: 33.3449,
    longitude: -84.0982,
    postal_code: '30248'
  },
  '30250': {
    country: 'US',
    city: 'Lovejoy',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.4429,
    longitude: -84.3136,
    postal_code: '30250'
  },
  '30251': {
    country: 'US',
    city: 'Luthersville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Meriwether',
    latitude: 33.2101,
    longitude: -84.7444,
    postal_code: '30251'
  },
  '30252': {
    country: 'US',
    city: 'Mcdonough',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Henry',
    latitude: 33.4768,
    longitude: -84.055,
    postal_code: '30252'
  },
  '30253': {
    country: 'US',
    city: 'Mcdonough',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Henry',
    latitude: 33.451,
    longitude: -84.1544,
    postal_code: '30253'
  },
  '30256': {
    country: 'US',
    city: 'Meansville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pike',
    latitude: 33.0134,
    longitude: -84.3169,
    postal_code: '30256'
  },
  '30257': {
    country: 'US',
    city: 'Milner',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lamar',
    latitude: 33.141,
    longitude: -84.1759,
    postal_code: '30257'
  },
  '30258': {
    country: 'US',
    city: 'Molena',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pike',
    latitude: 32.9978,
    longitude: -84.4558,
    postal_code: '30258'
  },
  '30259': {
    country: 'US',
    city: 'Moreland',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.2734,
    longitude: -84.7566,
    postal_code: '30259'
  },
  '30260': {
    country: 'US',
    city: 'Morrow',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.5849,
    longitude: -84.3247,
    postal_code: '30260'
  },
  '30261': {
    country: 'US',
    city: 'Lagrange',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Troup',
    latitude: 33.0457,
    longitude: -85.049,
    postal_code: '30261'
  },
  '30263': {
    country: 'US',
    city: 'Newnan',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.3696,
    longitude: -84.8194,
    postal_code: '30263'
  },
  '30264': {
    country: 'US',
    city: 'Newnan',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.361,
    longitude: -84.8142,
    postal_code: '30264'
  },
  '30265': {
    country: 'US',
    city: 'Newnan',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.3958,
    longitude: -84.7121,
    postal_code: '30265'
  },
  '30266': {
    country: 'US',
    city: 'Orchard Hill',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Spalding',
    latitude: 33.2657,
    longitude: -84.299,
    postal_code: '30266'
  },
  '30268': {
    country: 'US',
    city: 'Palmetto',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.5242,
    longitude: -84.679,
    postal_code: '30268'
  },
  '30269': {
    country: 'US',
    city: 'Peachtree City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fayette',
    latitude: 33.3915,
    longitude: -84.5635,
    postal_code: '30269'
  },
  '30270': {
    country: 'US',
    city: 'Peachtree City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Whitfield',
    latitude: 33.3968,
    longitude: -84.59,
    postal_code: '30270'
  },
  '30271': {
    country: 'US',
    city: 'Newnan',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.3514,
    longitude: -84.7561,
    postal_code: '30271'
  },
  '30272': {
    country: 'US',
    city: 'Red Oak',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.6259,
    longitude: -84.5163,
    postal_code: '30272'
  },
  '30273': {
    country: 'US',
    city: 'Rex',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.5808,
    longitude: -84.2782,
    postal_code: '30273'
  },
  '30274': {
    country: 'US',
    city: 'Riverdale',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.5531,
    longitude: -84.4003,
    postal_code: '30274'
  },
  '30275': {
    country: 'US',
    city: 'Sargent',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.4299,
    longitude: -84.8744,
    postal_code: '30275'
  },
  '30276': {
    country: 'US',
    city: 'Senoia',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.2845,
    longitude: -84.5918,
    postal_code: '30276'
  },
  '30277': {
    country: 'US',
    city: 'Sharpsburg',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.4013,
    longitude: -84.654,
    postal_code: '30277'
  },
  '30281': {
    country: 'US',
    city: 'Stockbridge',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Henry',
    latitude: 33.5633,
    longitude: -84.2165,
    postal_code: '30281'
  },
  '30284': {
    country: 'US',
    city: 'Sunny Side',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Spalding',
    latitude: 33.3453,
    longitude: -84.2898,
    postal_code: '30284'
  },
  '30285': {
    country: 'US',
    city: 'The Rock',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Upson',
    latitude: 32.9717,
    longitude: -84.2424,
    postal_code: '30285'
  },
  '30286': {
    country: 'US',
    city: 'Thomaston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Upson',
    latitude: 32.9015,
    longitude: -84.3324,
    postal_code: '30286'
  },
  '30287': {
    country: 'US',
    city: 'Morrow',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.5007,
    longitude: -84.3513,
    postal_code: '30287'
  },
  '30288': {
    country: 'US',
    city: 'Conley',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.6369,
    longitude: -84.3371,
    postal_code: '30288'
  },
  '30289': {
    country: 'US',
    city: 'Turin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coweta',
    latitude: 33.326,
    longitude: -84.6371,
    postal_code: '30289'
  },
  '30290': {
    country: 'US',
    city: 'Tyrone',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fayette',
    latitude: 33.4719,
    longitude: -84.5914,
    postal_code: '30290'
  },
  '30291': {
    country: 'US',
    city: 'Union City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.5832,
    longitude: -84.5499,
    postal_code: '30291'
  },
  '30292': {
    country: 'US',
    city: 'Williamson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pike',
    latitude: 33.1598,
    longitude: -84.3795,
    postal_code: '30292'
  },
  '30293': {
    country: 'US',
    city: 'Woodbury',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Meriwether',
    latitude: 32.9813,
    longitude: -84.5986,
    postal_code: '30293'
  },
  '30294': {
    country: 'US',
    city: 'Ellenwood',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.6166,
    longitude: -84.2939,
    postal_code: '30294'
  },
  '30295': {
    country: 'US',
    city: 'Zebulon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pike',
    latitude: 33.1002,
    longitude: -84.3108,
    postal_code: '30295'
  },
  '30296': {
    country: 'US',
    city: 'Riverdale',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.5667,
    longitude: -84.4364,
    postal_code: '30296'
  },
  '30297': {
    country: 'US',
    city: 'Forest Park',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.6115,
    longitude: -84.3745,
    postal_code: '30297'
  },
  '30298': {
    country: 'US',
    city: 'Forest Park',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clayton',
    latitude: 33.5007,
    longitude: -84.3513,
    postal_code: '30298'
  },
  '30301': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30301'
  },
  '30302': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.749,
    longitude: -84.388,
    postal_code: '30302'
  },
  '30303': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7525,
    longitude: -84.3888,
    postal_code: '30303'
  },
  '30304': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8482,
    longitude: -84.4293,
    postal_code: '30304'
  },
  '30305': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.832,
    longitude: -84.3851,
    postal_code: '30305'
  },
  '30306': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.786,
    longitude: -84.3514,
    postal_code: '30306'
  },
  '30307': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7691,
    longitude: -84.336,
    postal_code: '30307'
  },
  '30308': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7718,
    longitude: -84.3757,
    postal_code: '30308'
  },
  '30309': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7984,
    longitude: -84.3883,
    postal_code: '30309'
  },
  '30310': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7278,
    longitude: -84.4232,
    postal_code: '30310'
  },
  '30311': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.723,
    longitude: -84.4702,
    postal_code: '30311'
  },
  '30312': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7467,
    longitude: -84.3781,
    postal_code: '30312'
  },
  '30313': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7683,
    longitude: -84.3935,
    postal_code: '30313'
  },
  '30314': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7561,
    longitude: -84.4255,
    postal_code: '30314'
  },
  '30315': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7051,
    longitude: -84.3808,
    postal_code: '30315'
  },
  '30316': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7217,
    longitude: -84.3339,
    postal_code: '30316'
  },
  '30317': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7498,
    longitude: -84.3169,
    postal_code: '30317'
  },
  '30318': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7865,
    longitude: -84.4454,
    postal_code: '30318'
  },
  '30319': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8687,
    longitude: -84.3351,
    postal_code: '30319'
  },
  '30320': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.6568,
    longitude: -84.4236,
    postal_code: '30320'
  },
  '30321': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.749,
    longitude: -84.388,
    postal_code: '30321'
  },
  '30322': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.7952,
    longitude: -84.3248,
    postal_code: '30322'
  },
  '30324': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8206,
    longitude: -84.3549,
    postal_code: '30324'
  },
  '30325': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30325'
  },
  '30326': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8482,
    longitude: -84.3582,
    postal_code: '30326'
  },
  '30327': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8627,
    longitude: -84.42,
    postal_code: '30327'
  },
  '30328': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.9335,
    longitude: -84.3958,
    postal_code: '30328'
  },
  '30329': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8236,
    longitude: -84.3214,
    postal_code: '30329'
  },
  '30331': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7224,
    longitude: -84.5205,
    postal_code: '30331'
  },
  '30332': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7763,
    longitude: -84.398,
    postal_code: '30332'
  },
  '30333': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '30333'
  },
  '30334': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7489,
    longitude: -84.3872,
    postal_code: '30334'
  },
  '30336': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7406,
    longitude: -84.5545,
    postal_code: '30336'
  },
  '30337': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.6428,
    longitude: -84.4618,
    postal_code: '30337'
  },
  '30338': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.9669,
    longitude: -84.3249,
    postal_code: '30338'
  },
  '30339': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8713,
    longitude: -84.4629,
    postal_code: '30339'
  },
  '30340': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8932,
    longitude: -84.2539,
    postal_code: '30340'
  },
  '30341': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8879,
    longitude: -84.2905,
    postal_code: '30341'
  },
  '30342': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8842,
    longitude: -84.3761,
    postal_code: '30342'
  },
  '30343': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.749,
    longitude: -84.388,
    postal_code: '30343'
  },
  '30344': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.6919,
    longitude: -84.448,
    postal_code: '30344'
  },
  '30345': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8513,
    longitude: -84.287,
    postal_code: '30345'
  },
  '30346': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.9267,
    longitude: -84.3334,
    postal_code: '30346'
  },
  '30348': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30348'
  },
  '30349': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.6053,
    longitude: -84.4813,
    postal_code: '30349'
  },
  '30350': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.9795,
    longitude: -84.3411,
    postal_code: '30350'
  },
  '30353': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.749,
    longitude: -84.388,
    postal_code: '30353'
  },
  '30354': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.6675,
    longitude: -84.3896,
    postal_code: '30354'
  },
  '30355': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30355'
  },
  '30356': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '30356'
  },
  '30357': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30357'
  },
  '30358': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.749,
    longitude: -84.388,
    postal_code: '30358'
  },
  '30359': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '30359'
  },
  '30360': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.9378,
    longitude: -84.2716,
    postal_code: '30360'
  },
  '30361': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30361'
  },
  '30362': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '30362'
  },
  '30363': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.791,
    longitude: -84.3992,
    postal_code: '30363'
  },
  '30364': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30364'
  },
  '30366': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '30366'
  },
  '30368': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30368'
  },
  '30369': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30369'
  },
  '30370': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.749,
    longitude: -84.388,
    postal_code: '30370'
  },
  '30371': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30371'
  },
  '30374': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.749,
    longitude: -84.388,
    postal_code: '30374'
  },
  '30375': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30375'
  },
  '30377': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30377'
  },
  '30378': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30378'
  },
  '30380': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30380'
  },
  '30384': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30384'
  },
  '30385': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30385'
  },
  '30388': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30388'
  },
  '30392': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.749,
    longitude: -84.388,
    postal_code: '30392'
  },
  '30394': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.749,
    longitude: -84.388,
    postal_code: '30394'
  },
  '30396': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30396'
  },
  '30398': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '30398'
  },
  '30401': {
    country: 'US',
    city: 'Swainsboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Emanuel',
    latitude: 32.5699,
    longitude: -82.3462,
    postal_code: '30401'
  },
  '30410': {
    country: 'US',
    city: 'Ailey',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Montgomery',
    latitude: 32.2143,
    longitude: -82.4762,
    postal_code: '30410'
  },
  '30411': {
    country: 'US',
    city: 'Alamo',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wheeler',
    latitude: 32.133,
    longitude: -82.7944,
    postal_code: '30411'
  },
  '30412': {
    country: 'US',
    city: 'Alston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Montgomery',
    latitude: 32.0775,
    longitude: -82.4825,
    postal_code: '30412'
  },
  '30413': {
    country: 'US',
    city: 'Bartow',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jefferson',
    latitude: 32.8632,
    longitude: -82.4708,
    postal_code: '30413'
  },
  '30414': {
    country: 'US',
    city: 'Bellville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Evans',
    latitude: 32.1524,
    longitude: -81.9743,
    postal_code: '30414'
  },
  '30415': {
    country: 'US',
    city: 'Brooklet',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bulloch',
    latitude: 32.294,
    longitude: -81.628,
    postal_code: '30415'
  },
  '30417': {
    country: 'US',
    city: 'Claxton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Evans',
    latitude: 32.165,
    longitude: -81.908,
    postal_code: '30417'
  },
  '30420': {
    country: 'US',
    city: 'Cobbtown',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tattnall',
    latitude: 32.2642,
    longitude: -82.1333,
    postal_code: '30420'
  },
  '30421': {
    country: 'US',
    city: 'Collins',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tattnall',
    latitude: 32.1852,
    longitude: -82.1095,
    postal_code: '30421'
  },
  '30423': {
    country: 'US',
    city: 'Daisy',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Evans',
    latitude: 32.1519,
    longitude: -81.8346,
    postal_code: '30423'
  },
  '30424': {
    country: 'US',
    city: 'Dover',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Screven',
    latitude: 32.5771,
    longitude: -81.7151,
    postal_code: '30424'
  },
  '30425': {
    country: 'US',
    city: 'Garfield',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Emanuel',
    latitude: 32.6549,
    longitude: -82.1002,
    postal_code: '30425'
  },
  '30426': {
    country: 'US',
    city: 'Girard',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Burke',
    latitude: 33.0437,
    longitude: -81.7106,
    postal_code: '30426'
  },
  '30427': {
    country: 'US',
    city: 'Glennville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tattnall',
    latitude: 31.9467,
    longitude: -81.9483,
    postal_code: '30427'
  },
  '30428': {
    country: 'US',
    city: 'Glenwood',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wheeler',
    latitude: 32.1572,
    longitude: -82.6759,
    postal_code: '30428'
  },
  '30429': {
    country: 'US',
    city: 'Hagan',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Evans',
    latitude: 32.156,
    longitude: -81.9337,
    postal_code: '30429'
  },
  '30434': {
    country: 'US',
    city: 'Louisville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jefferson',
    latitude: 33.0163,
    longitude: -82.3836,
    postal_code: '30434'
  },
  '30436': {
    country: 'US',
    city: 'Lyons',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Toombs',
    latitude: 32.1711,
    longitude: -82.3078,
    postal_code: '30436'
  },
  '30438': {
    country: 'US',
    city: 'Manassas',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tattnall',
    latitude: 32.1605,
    longitude: -82.0196,
    postal_code: '30438'
  },
  '30439': {
    country: 'US',
    city: 'Metter',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Candler',
    latitude: 32.401,
    longitude: -82.0607,
    postal_code: '30439'
  },
  '30441': {
    country: 'US',
    city: 'Midville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Burke',
    latitude: 32.8638,
    longitude: -82.2042,
    postal_code: '30441'
  },
  '30442': {
    country: 'US',
    city: 'Millen',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jenkins',
    latitude: 32.7877,
    longitude: -81.9618,
    postal_code: '30442'
  },
  '30445': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Montgomery',
    latitude: 32.184,
    longitude: -82.5867,
    postal_code: '30445'
  },
  '30446': {
    country: 'US',
    city: 'Newington',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Screven',
    latitude: 32.581,
    longitude: -81.4826,
    postal_code: '30446'
  },
  '30447': {
    country: 'US',
    city: 'Norristown',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Emanuel',
    latitude: 32.5041,
    longitude: -82.4848,
    postal_code: '30447'
  },
  '30448': {
    country: 'US',
    city: 'Nunez',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Emanuel',
    latitude: 32.4759,
    longitude: -82.3726,
    postal_code: '30448'
  },
  '30449': {
    country: 'US',
    city: 'Oliver',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Screven',
    latitude: 32.5281,
    longitude: -81.5332,
    postal_code: '30449'
  },
  '30450': {
    country: 'US',
    city: 'Portal',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bulloch',
    latitude: 32.555,
    longitude: -81.9123,
    postal_code: '30450'
  },
  '30451': {
    country: 'US',
    city: 'Pulaski',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Candler',
    latitude: 32.3902,
    longitude: -81.9568,
    postal_code: '30451'
  },
  '30452': {
    country: 'US',
    city: 'Register',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bulloch',
    latitude: 32.3382,
    longitude: -81.8728,
    postal_code: '30452'
  },
  '30453': {
    country: 'US',
    city: 'Reidsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tattnall',
    latitude: 32.0548,
    longitude: -82.1478,
    postal_code: '30453'
  },
  '30454': {
    country: 'US',
    city: 'Rockledge',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Laurens',
    latitude: 32.3915,
    longitude: -82.7479,
    postal_code: '30454'
  },
  '30455': {
    country: 'US',
    city: 'Rocky Ford',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Screven',
    latitude: 32.723,
    longitude: -81.7933,
    postal_code: '30455'
  },
  '30456': {
    country: 'US',
    city: 'Sardis',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Burke',
    latitude: 32.9809,
    longitude: -81.773,
    postal_code: '30456'
  },
  '30457': {
    country: 'US',
    city: 'Soperton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Treutlen',
    latitude: 32.3869,
    longitude: -82.5871,
    postal_code: '30457'
  },
  '30458': {
    country: 'US',
    city: 'Statesboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bulloch',
    latitude: 32.4408,
    longitude: -81.774,
    postal_code: '30458'
  },
  '30459': {
    country: 'US',
    city: 'Statesboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bulloch',
    latitude: 32.447,
    longitude: -81.7777,
    postal_code: '30459'
  },
  '30460': {
    country: 'US',
    city: 'Statesboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bulloch',
    latitude: 32.4179,
    longitude: -81.7823,
    postal_code: '30460'
  },
  '30461': {
    country: 'US',
    city: 'Statesboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bulloch',
    latitude: 32.45,
    longitude: -81.7158,
    postal_code: '30461'
  },
  '30464': {
    country: 'US',
    city: 'Stillmore',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Emanuel',
    latitude: 32.4421,
    longitude: -82.2151,
    postal_code: '30464'
  },
  '30467': {
    country: 'US',
    city: 'Sylvania',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Screven',
    latitude: 32.7439,
    longitude: -81.6287,
    postal_code: '30467'
  },
  '30470': {
    country: 'US',
    city: 'Tarrytown',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Montgomery',
    latitude: 32.3188,
    longitude: -82.5593,
    postal_code: '30470'
  },
  '30471': {
    country: 'US',
    city: 'Twin City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Emanuel',
    latitude: 32.6129,
    longitude: -82.1979,
    postal_code: '30471'
  },
  '30473': {
    country: 'US',
    city: 'Uvalda',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Montgomery',
    latitude: 32.0483,
    longitude: -82.5089,
    postal_code: '30473'
  },
  '30474': {
    country: 'US',
    city: 'Vidalia',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Toombs',
    latitude: 32.1934,
    longitude: -82.4067,
    postal_code: '30474'
  },
  '30475': {
    country: 'US',
    city: 'Vidalia',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Toombs',
    latitude: 32.1775,
    longitude: -82.3739,
    postal_code: '30475'
  },
  '30477': {
    country: 'US',
    city: 'Wadley',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jefferson',
    latitude: 32.8747,
    longitude: -82.4025,
    postal_code: '30477'
  },
  '30499': {
    country: 'US',
    city: 'Reidsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tattnall',
    latitude: 32.0869,
    longitude: -82.1179,
    postal_code: '30499'
  },
  '30501': {
    country: 'US',
    city: 'Gainesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.3073,
    longitude: -83.8256,
    postal_code: '30501'
  },
  '30502': {
    country: 'US',
    city: 'Chestnut Mountain',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.213,
    longitude: -83.7949,
    postal_code: '30502'
  },
  '30503': {
    country: 'US',
    city: 'Gainesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.3454,
    longitude: -83.9505,
    postal_code: '30503'
  },
  '30504': {
    country: 'US',
    city: 'Gainesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.2723,
    longitude: -83.8793,
    postal_code: '30504'
  },
  '30506': {
    country: 'US',
    city: 'Gainesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.3562,
    longitude: -83.8882,
    postal_code: '30506'
  },
  '30507': {
    country: 'US',
    city: 'Gainesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.2591,
    longitude: -83.7716,
    postal_code: '30507'
  },
  '30510': {
    country: 'US',
    city: 'Alto',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Habersham',
    latitude: 34.4591,
    longitude: -83.602,
    postal_code: '30510'
  },
  '30511': {
    country: 'US',
    city: 'Baldwin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Banks',
    latitude: 34.4574,
    longitude: -83.476,
    postal_code: '30511'
  },
  '30512': {
    country: 'US',
    city: 'Blairsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Union',
    latitude: 34.8763,
    longitude: -83.992,
    postal_code: '30512'
  },
  '30513': {
    country: 'US',
    city: 'Blue Ridge',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fannin',
    latitude: 34.8555,
    longitude: -84.3281,
    postal_code: '30513'
  },
  '30514': {
    country: 'US',
    city: 'Blairsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Union',
    latitude: 34.8762,
    longitude: -83.9582,
    postal_code: '30514'
  },
  '30515': {
    country: 'US',
    city: 'Buford',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 33.9604,
    longitude: -84.0379,
    postal_code: '30515'
  },
  '30516': {
    country: 'US',
    city: 'Bowersville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hart',
    latitude: 34.3996,
    longitude: -83.0484,
    postal_code: '30516'
  },
  '30517': {
    country: 'US',
    city: 'Braselton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jackson',
    latitude: 34.1389,
    longitude: -83.7812,
    postal_code: '30517'
  },
  '30518': {
    country: 'US',
    city: 'Buford',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 34.1124,
    longitude: -83.9965,
    postal_code: '30518'
  },
  '30519': {
    country: 'US',
    city: 'Buford',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gwinnett',
    latitude: 34.0797,
    longitude: -83.9308,
    postal_code: '30519'
  },
  '30520': {
    country: 'US',
    city: 'Canon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Franklin',
    latitude: 34.3478,
    longitude: -83.1267,
    postal_code: '30520'
  },
  '30521': {
    country: 'US',
    city: 'Carnesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Franklin',
    latitude: 34.3631,
    longitude: -83.2547,
    postal_code: '30521'
  },
  '30522': {
    country: 'US',
    city: 'Cherry Log',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gilmer',
    latitude: 34.7804,
    longitude: -84.3908,
    postal_code: '30522'
  },
  '30523': {
    country: 'US',
    city: 'Clarkesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Habersham',
    latitude: 34.645,
    longitude: -83.5243,
    postal_code: '30523'
  },
  '30525': {
    country: 'US',
    city: 'Clayton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rabun',
    latitude: 34.8826,
    longitude: -83.4065,
    postal_code: '30525'
  },
  '30527': {
    country: 'US',
    city: 'Clermont',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.4761,
    longitude: -83.7853,
    postal_code: '30527'
  },
  '30528': {
    country: 'US',
    city: 'Cleveland',
    state: 'Georgia',
    state_short: 'GA',
    county: 'White',
    latitude: 34.5839,
    longitude: -83.75,
    postal_code: '30528'
  },
  '30529': {
    country: 'US',
    city: 'Commerce',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jackson',
    latitude: 34.2134,
    longitude: -83.448,
    postal_code: '30529'
  },
  '30530': {
    country: 'US',
    city: 'Commerce',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jackson',
    latitude: 34.1683,
    longitude: -83.4022,
    postal_code: '30530'
  },
  '30531': {
    country: 'US',
    city: 'Cornelia',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Habersham',
    latitude: 34.5209,
    longitude: -83.5453,
    postal_code: '30531'
  },
  '30533': {
    country: 'US',
    city: 'Dahlonega',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lumpkin',
    latitude: 34.5299,
    longitude: -83.9798,
    postal_code: '30533'
  },
  '30534': {
    country: 'US',
    city: 'Dawsonville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dawson',
    latitude: 34.4537,
    longitude: -84.155,
    postal_code: '30534'
  },
  '30535': {
    country: 'US',
    city: 'Demorest',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Habersham',
    latitude: 34.5756,
    longitude: -83.5696,
    postal_code: '30535'
  },
  '30536': {
    country: 'US',
    city: 'Ellijay',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gilmer',
    latitude: 34.655,
    longitude: -84.3554,
    postal_code: '30536'
  },
  '30537': {
    country: 'US',
    city: 'Dillard',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rabun',
    latitude: 34.9777,
    longitude: -83.3199,
    postal_code: '30537'
  },
  '30538': {
    country: 'US',
    city: 'Eastanollee',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Stephens',
    latitude: 34.5037,
    longitude: -83.2587,
    postal_code: '30538'
  },
  '30539': {
    country: 'US',
    city: 'East Ellijay',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gilmer',
    latitude: 34.684,
    longitude: -84.4727,
    postal_code: '30539'
  },
  '30540': {
    country: 'US',
    city: 'Ellijay',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gilmer',
    latitude: 34.6775,
    longitude: -84.4812,
    postal_code: '30540'
  },
  '30541': {
    country: 'US',
    city: 'Epworth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fannin',
    latitude: 34.9129,
    longitude: -84.5391,
    postal_code: '30541'
  },
  '30542': {
    country: 'US',
    city: 'Flowery Branch',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.1819,
    longitude: -83.9024,
    postal_code: '30542'
  },
  '30543': {
    country: 'US',
    city: 'Gillsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.3001,
    longitude: -83.6757,
    postal_code: '30543'
  },
  '30545': {
    country: 'US',
    city: 'Helen',
    state: 'Georgia',
    state_short: 'GA',
    county: 'White',
    latitude: 34.6866,
    longitude: -83.7399,
    postal_code: '30545'
  },
  '30546': {
    country: 'US',
    city: 'Hiawassee',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Towns',
    latitude: 34.9063,
    longitude: -83.7274,
    postal_code: '30546'
  },
  '30547': {
    country: 'US',
    city: 'Homer',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Banks',
    latitude: 34.3563,
    longitude: -83.4974,
    postal_code: '30547'
  },
  '30548': {
    country: 'US',
    city: 'Hoschton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jackson',
    latitude: 34.0866,
    longitude: -83.7803,
    postal_code: '30548'
  },
  '30549': {
    country: 'US',
    city: 'Jefferson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jackson',
    latitude: 34.1062,
    longitude: -83.5708,
    postal_code: '30549'
  },
  '30552': {
    country: 'US',
    city: 'Lakemont',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rabun',
    latitude: 34.7617,
    longitude: -83.4038,
    postal_code: '30552'
  },
  '30553': {
    country: 'US',
    city: 'Lavonia',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Franklin',
    latitude: 34.4528,
    longitude: -83.113,
    postal_code: '30553'
  },
  '30554': {
    country: 'US',
    city: 'Lula',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.3876,
    longitude: -83.6663,
    postal_code: '30554'
  },
  '30555': {
    country: 'US',
    city: 'Mc Caysville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fannin',
    latitude: 34.9739,
    longitude: -84.4291,
    postal_code: '30555'
  },
  '30557': {
    country: 'US',
    city: 'Martin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Stephens',
    latitude: 34.487,
    longitude: -83.1849,
    postal_code: '30557'
  },
  '30558': {
    country: 'US',
    city: 'Maysville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Banks',
    latitude: 34.2529,
    longitude: -83.5616,
    postal_code: '30558'
  },
  '30559': {
    country: 'US',
    city: 'Mineral Bluff',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fannin',
    latitude: 34.9341,
    longitude: -84.2541,
    postal_code: '30559'
  },
  '30560': {
    country: 'US',
    city: 'Morganton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fannin',
    latitude: 34.8714,
    longitude: -84.2115,
    postal_code: '30560'
  },
  '30562': {
    country: 'US',
    city: 'Mountain City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rabun',
    latitude: 34.9181,
    longitude: -83.3854,
    postal_code: '30562'
  },
  '30563': {
    country: 'US',
    city: 'Mount Airy',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Habersham',
    latitude: 34.5678,
    longitude: -83.4713,
    postal_code: '30563'
  },
  '30564': {
    country: 'US',
    city: 'Murrayville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.4342,
    longitude: -83.8949,
    postal_code: '30564'
  },
  '30565': {
    country: 'US',
    city: 'Nicholson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jackson',
    latitude: 34.097,
    longitude: -83.421,
    postal_code: '30565'
  },
  '30566': {
    country: 'US',
    city: 'Oakwood',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hall',
    latitude: 34.2372,
    longitude: -83.894,
    postal_code: '30566'
  },
  '30567': {
    country: 'US',
    city: 'Pendergrass',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jackson',
    latitude: 34.1796,
    longitude: -83.6634,
    postal_code: '30567'
  },
  '30568': {
    country: 'US',
    city: 'Rabun Gap',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rabun',
    latitude: 34.9576,
    longitude: -83.3901,
    postal_code: '30568'
  },
  '30571': {
    country: 'US',
    city: 'Sautee Nacoochee',
    state: 'Georgia',
    state_short: 'GA',
    county: 'White',
    latitude: 34.7162,
    longitude: -83.6931,
    postal_code: '30571'
  },
  '30572': {
    country: 'US',
    city: 'Suches',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Union',
    latitude: 34.7253,
    longitude: -84.0496,
    postal_code: '30572'
  },
  '30573': {
    country: 'US',
    city: 'Tallulah Falls',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rabun',
    latitude: 34.742,
    longitude: -83.3977,
    postal_code: '30573'
  },
  '30575': {
    country: 'US',
    city: 'Talmo',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jackson',
    latitude: 34.1951,
    longitude: -83.7187,
    postal_code: '30575'
  },
  '30576': {
    country: 'US',
    city: 'Tiger',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rabun',
    latitude: 34.8174,
    longitude: -83.4333,
    postal_code: '30576'
  },
  '30577': {
    country: 'US',
    city: 'Toccoa',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Stephens',
    latitude: 34.5665,
    longitude: -83.3114,
    postal_code: '30577'
  },
  '30580': {
    country: 'US',
    city: 'Turnerville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Habersham',
    latitude: 34.6766,
    longitude: -83.4358,
    postal_code: '30580'
  },
  '30581': {
    country: 'US',
    city: 'Wiley',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Rabun',
    latitude: 34.8045,
    longitude: -83.4191,
    postal_code: '30581'
  },
  '30582': {
    country: 'US',
    city: 'Young Harris',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Towns',
    latitude: 34.9588,
    longitude: -83.8688,
    postal_code: '30582'
  },
  '30597': {
    country: 'US',
    city: 'Dahlonega',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lumpkin',
    latitude: 34.5277,
    longitude: -83.9809,
    postal_code: '30597'
  },
  '30598': {
    country: 'US',
    city: 'Toccoa Falls',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Stephens',
    latitude: 34.5942,
    longitude: -83.3557,
    postal_code: '30598'
  },
  '30599': {
    country: 'US',
    city: 'Commerce',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jackson',
    latitude: 34.204,
    longitude: -83.4571,
    postal_code: '30599'
  },
  '30601': {
    country: 'US',
    city: 'Athens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 33.9761,
    longitude: -83.3632,
    postal_code: '30601'
  },
  '30602': {
    country: 'US',
    city: 'Athens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 33.9433,
    longitude: -83.3724,
    postal_code: '30602'
  },
  '30603': {
    country: 'US',
    city: 'Athens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 33.9609,
    longitude: -83.3779,
    postal_code: '30603'
  },
  '30604': {
    country: 'US',
    city: 'Athens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 33.9443,
    longitude: -83.3891,
    postal_code: '30604'
  },
  '30605': {
    country: 'US',
    city: 'Athens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 33.9321,
    longitude: -83.3525,
    postal_code: '30605'
  },
  '30606': {
    country: 'US',
    city: 'Athens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 33.9461,
    longitude: -83.418,
    postal_code: '30606'
  },
  '30607': {
    country: 'US',
    city: 'Athens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 34.007,
    longitude: -83.4278,
    postal_code: '30607'
  },
  '30608': {
    country: 'US',
    city: 'Athens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 33.9443,
    longitude: -83.3891,
    postal_code: '30608'
  },
  '30609': {
    country: 'US',
    city: 'Athens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 33.9464,
    longitude: -83.3774,
    postal_code: '30609'
  },
  '30612': {
    country: 'US',
    city: 'Athens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 33.9443,
    longitude: -83.3891,
    postal_code: '30612'
  },
  '30619': {
    country: 'US',
    city: 'Arnoldsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Oglethorpe',
    latitude: 33.8803,
    longitude: -83.2341,
    postal_code: '30619'
  },
  '30620': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Barrow',
    latitude: 33.9261,
    longitude: -83.7282,
    postal_code: '30620'
  },
  '30621': {
    country: 'US',
    city: 'Bishop',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Oconee',
    latitude: 33.8081,
    longitude: -83.4777,
    postal_code: '30621'
  },
  '30622': {
    country: 'US',
    city: 'Bogart',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Oconee',
    latitude: 33.934,
    longitude: -83.5055,
    postal_code: '30622'
  },
  '30623': {
    country: 'US',
    city: 'Bostwick',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Morgan',
    latitude: 33.7373,
    longitude: -83.5143,
    postal_code: '30623'
  },
  '30624': {
    country: 'US',
    city: 'Bowman',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Elbert',
    latitude: 34.1919,
    longitude: -83.0284,
    postal_code: '30624'
  },
  '30625': {
    country: 'US',
    city: 'Buckhead',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Morgan',
    latitude: 33.5368,
    longitude: -83.3435,
    postal_code: '30625'
  },
  '30627': {
    country: 'US',
    city: 'Carlton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Madison',
    latitude: 33.9852,
    longitude: -83.0038,
    postal_code: '30627'
  },
  '30628': {
    country: 'US',
    city: 'Colbert',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Madison',
    latitude: 34.0382,
    longitude: -83.2191,
    postal_code: '30628'
  },
  '30629': {
    country: 'US',
    city: 'Comer',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Madison',
    latitude: 34.0888,
    longitude: -83.122,
    postal_code: '30629'
  },
  '30630': {
    country: 'US',
    city: 'Crawford',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Oglethorpe',
    latitude: 33.9076,
    longitude: -83.1646,
    postal_code: '30630'
  },
  '30631': {
    country: 'US',
    city: 'Crawfordville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Taliaferro',
    latitude: 33.5708,
    longitude: -82.8872,
    postal_code: '30631'
  },
  '30633': {
    country: 'US',
    city: 'Danielsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Madison',
    latitude: 34.1708,
    longitude: -83.2758,
    postal_code: '30633'
  },
  '30634': {
    country: 'US',
    city: 'Dewy Rose',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Elbert',
    latitude: 34.1853,
    longitude: -82.9434,
    postal_code: '30634'
  },
  '30635': {
    country: 'US',
    city: 'Elberton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Elbert',
    latitude: 34.1082,
    longitude: -82.8448,
    postal_code: '30635'
  },
  '30638': {
    country: 'US',
    city: 'Farmington',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Oconee',
    latitude: 33.7558,
    longitude: -83.4201,
    postal_code: '30638'
  },
  '30639': {
    country: 'US',
    city: 'Franklin Springs',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Franklin',
    latitude: 34.2743,
    longitude: -83.147,
    postal_code: '30639'
  },
  '30641': {
    country: 'US',
    city: 'Good Hope',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walton',
    latitude: 33.7613,
    longitude: -83.595,
    postal_code: '30641'
  },
  '30642': {
    country: 'US',
    city: 'Greensboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Greene',
    latitude: 33.5637,
    longitude: -83.1702,
    postal_code: '30642'
  },
  '30643': {
    country: 'US',
    city: 'Hartwell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hart',
    latitude: 34.3571,
    longitude: -82.9296,
    postal_code: '30643'
  },
  '30645': {
    country: 'US',
    city: 'High Shoals',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Morgan',
    latitude: 33.8013,
    longitude: -83.5164,
    postal_code: '30645'
  },
  '30646': {
    country: 'US',
    city: 'Hull',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Madison',
    latitude: 34.0478,
    longitude: -83.311,
    postal_code: '30646'
  },
  '30647': {
    country: 'US',
    city: 'Ila',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Madison',
    latitude: 34.1726,
    longitude: -83.2921,
    postal_code: '30647'
  },
  '30648': {
    country: 'US',
    city: 'Lexington',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Oglethorpe',
    latitude: 33.8799,
    longitude: -83.0858,
    postal_code: '30648'
  },
  '30650': {
    country: 'US',
    city: 'Madison',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Morgan',
    latitude: 33.5947,
    longitude: -83.4618,
    postal_code: '30650'
  },
  '30655': {
    country: 'US',
    city: 'Monroe',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walton',
    latitude: 33.7883,
    longitude: -83.7013,
    postal_code: '30655'
  },
  '30656': {
    country: 'US',
    city: 'Monroe',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walton',
    latitude: 33.8386,
    longitude: -83.7104,
    postal_code: '30656'
  },
  '30660': {
    country: 'US',
    city: 'Rayle',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilkes',
    latitude: 33.7856,
    longitude: -82.9504,
    postal_code: '30660'
  },
  '30662': {
    country: 'US',
    city: 'Royston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Franklin',
    latitude: 34.2778,
    longitude: -83.1406,
    postal_code: '30662'
  },
  '30663': {
    country: 'US',
    city: 'Rutledge',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Morgan',
    latitude: 33.6163,
    longitude: -83.6023,
    postal_code: '30663'
  },
  '30664': {
    country: 'US',
    city: 'Sharon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Taliaferro',
    latitude: 33.56,
    longitude: -82.7948,
    postal_code: '30664'
  },
  '30665': {
    country: 'US',
    city: 'Siloam',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Greene',
    latitude: 33.5368,
    longitude: -83.081,
    postal_code: '30665'
  },
  '30666': {
    country: 'US',
    city: 'Statham',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Barrow',
    latitude: 33.9602,
    longitude: -83.5893,
    postal_code: '30666'
  },
  '30667': {
    country: 'US',
    city: 'Stephens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Oglethorpe',
    latitude: 33.7715,
    longitude: -83.1159,
    postal_code: '30667'
  },
  '30668': {
    country: 'US',
    city: 'Tignall',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilkes',
    latitude: 33.8778,
    longitude: -82.747,
    postal_code: '30668'
  },
  '30669': {
    country: 'US',
    city: 'Union Point',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Greene',
    latitude: 33.6345,
    longitude: -83.0879,
    postal_code: '30669'
  },
  '30671': {
    country: 'US',
    city: 'Maxeys',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Oglethorpe',
    latitude: 33.7405,
    longitude: -83.1706,
    postal_code: '30671'
  },
  '30673': {
    country: 'US',
    city: 'Washington',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilkes',
    latitude: 33.7265,
    longitude: -82.7429,
    postal_code: '30673'
  },
  '30677': {
    country: 'US',
    city: 'Watkinsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Oconee',
    latitude: 33.8542,
    longitude: -83.408,
    postal_code: '30677'
  },
  '30678': {
    country: 'US',
    city: 'White Plains',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Greene',
    latitude: 33.4581,
    longitude: -83.0802,
    postal_code: '30678'
  },
  '30680': {
    country: 'US',
    city: 'Winder',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Barrow',
    latitude: 33.9985,
    longitude: -83.7115,
    postal_code: '30680'
  },
  '30683': {
    country: 'US',
    city: 'Winterville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clarke',
    latitude: 33.9543,
    longitude: -83.2906,
    postal_code: '30683'
  },
  '30701': {
    country: 'US',
    city: 'Calhoun',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gordon',
    latitude: 34.4965,
    longitude: -84.9345,
    postal_code: '30701'
  },
  '30703': {
    country: 'US',
    city: 'Calhoun',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gordon',
    latitude: 34.5026,
    longitude: -84.9511,
    postal_code: '30703'
  },
  '30705': {
    country: 'US',
    city: 'Chatsworth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Murray',
    latitude: 34.7589,
    longitude: -84.7943,
    postal_code: '30705'
  },
  '30707': {
    country: 'US',
    city: 'Chickamauga',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walker',
    latitude: 34.858,
    longitude: -85.3221,
    postal_code: '30707'
  },
  '30708': {
    country: 'US',
    city: 'Cisco',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Murray',
    latitude: 34.9618,
    longitude: -84.6646,
    postal_code: '30708'
  },
  '30710': {
    country: 'US',
    city: 'Cohutta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Whitfield',
    latitude: 34.9326,
    longitude: -84.9458,
    postal_code: '30710'
  },
  '30711': {
    country: 'US',
    city: 'Crandall',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Murray',
    latitude: 34.9412,
    longitude: -84.7645,
    postal_code: '30711'
  },
  '30719': {
    country: 'US',
    city: 'Dalton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Whitfield',
    latitude: 34.7698,
    longitude: -84.9702,
    postal_code: '30719'
  },
  '30720': {
    country: 'US',
    city: 'Dalton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Whitfield',
    latitude: 34.7635,
    longitude: -84.9875,
    postal_code: '30720'
  },
  '30721': {
    country: 'US',
    city: 'Dalton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Whitfield',
    latitude: 34.7792,
    longitude: -84.9339,
    postal_code: '30721'
  },
  '30722': {
    country: 'US',
    city: 'Dalton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Whitfield',
    latitude: 34.7595,
    longitude: -84.9513,
    postal_code: '30722'
  },
  '30724': {
    country: 'US',
    city: 'Eton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Murray',
    latitude: 34.8251,
    longitude: -84.7633,
    postal_code: '30724'
  },
  '30725': {
    country: 'US',
    city: 'Flintstone',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walker',
    latitude: 34.9252,
    longitude: -85.3524,
    postal_code: '30725'
  },
  '30726': {
    country: 'US',
    city: 'Graysville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Catoosa',
    latitude: 34.9765,
    longitude: -85.1413,
    postal_code: '30726'
  },
  '30728': {
    country: 'US',
    city: 'La Fayette',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walker',
    latitude: 34.692,
    longitude: -85.2602,
    postal_code: '30728'
  },
  '30730': {
    country: 'US',
    city: 'Lyerly',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chattooga',
    latitude: 34.403,
    longitude: -85.4038,
    postal_code: '30730'
  },
  '30731': {
    country: 'US',
    city: 'Menlo',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chattooga',
    latitude: 34.5864,
    longitude: -85.4774,
    postal_code: '30731'
  },
  '30732': {
    country: 'US',
    city: 'Oakman',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gordon',
    latitude: 34.569,
    longitude: -84.7119,
    postal_code: '30732'
  },
  '30733': {
    country: 'US',
    city: 'Plainville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gordon',
    latitude: 34.4145,
    longitude: -85.0312,
    postal_code: '30733'
  },
  '30734': {
    country: 'US',
    city: 'Ranger',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gordon',
    latitude: 34.5404,
    longitude: -84.727,
    postal_code: '30734'
  },
  '30735': {
    country: 'US',
    city: 'Resaca',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gordon',
    latitude: 34.5833,
    longitude: -84.9068,
    postal_code: '30735'
  },
  '30736': {
    country: 'US',
    city: 'Ringgold',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Catoosa',
    latitude: 34.9205,
    longitude: -85.1549,
    postal_code: '30736'
  },
  '30738': {
    country: 'US',
    city: 'Rising Fawn',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dade',
    latitude: 34.8139,
    longitude: -85.5019,
    postal_code: '30738'
  },
  '30739': {
    country: 'US',
    city: 'Rock Spring',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walker',
    latitude: 34.8065,
    longitude: -85.2415,
    postal_code: '30739'
  },
  '30740': {
    country: 'US',
    city: 'Rocky Face',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Whitfield',
    latitude: 34.7745,
    longitude: -85.0561,
    postal_code: '30740'
  },
  '30741': {
    country: 'US',
    city: 'Rossville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walker',
    latitude: 34.9535,
    longitude: -85.2968,
    postal_code: '30741'
  },
  '30742': {
    country: 'US',
    city: 'Fort Oglethorpe',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Catoosa',
    latitude: 34.9506,
    longitude: -85.2432,
    postal_code: '30742'
  },
  '30746': {
    country: 'US',
    city: 'Sugar Valley',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Gordon',
    latitude: 34.5519,
    longitude: -85.026,
    postal_code: '30746'
  },
  '30747': {
    country: 'US',
    city: 'Summerville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chattooga',
    latitude: 34.4859,
    longitude: -85.3362,
    postal_code: '30747'
  },
  '30750': {
    country: 'US',
    city: 'Lookout Mountain',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Walker',
    latitude: 34.9335,
    longitude: -85.3794,
    postal_code: '30750'
  },
  '30751': {
    country: 'US',
    city: 'Tennga',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Murray',
    latitude: 34.9814,
    longitude: -84.7352,
    postal_code: '30751'
  },
  '30752': {
    country: 'US',
    city: 'Trenton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dade',
    latitude: 34.9017,
    longitude: -85.5171,
    postal_code: '30752'
  },
  '30753': {
    country: 'US',
    city: 'Trion',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chattooga',
    latitude: 34.5468,
    longitude: -85.3112,
    postal_code: '30753'
  },
  '30755': {
    country: 'US',
    city: 'Tunnel Hill',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Whitfield',
    latitude: 34.8541,
    longitude: -85.0468,
    postal_code: '30755'
  },
  '30756': {
    country: 'US',
    city: 'Varnell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Whitfield',
    latitude: 34.9012,
    longitude: -84.9738,
    postal_code: '30756'
  },
  '30757': {
    country: 'US',
    city: 'Wildwood',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dade',
    latitude: 34.9779,
    longitude: -85.4305,
    postal_code: '30757'
  },
  '30802': {
    country: 'US',
    city: 'Appling',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Columbia',
    latitude: 33.6271,
    longitude: -82.2856,
    postal_code: '30802'
  },
  '30803': {
    country: 'US',
    city: 'Avera',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jefferson',
    latitude: 33.141,
    longitude: -82.515,
    postal_code: '30803'
  },
  '30805': {
    country: 'US',
    city: 'Blythe',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.2941,
    longitude: -82.203,
    postal_code: '30805'
  },
  '30806': {
    country: 'US',
    city: 'Boneville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'McDuffie',
    latitude: 33.528,
    longitude: -82.5104,
    postal_code: '30806'
  },
  '30807': {
    country: 'US',
    city: 'Camak',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Warren',
    latitude: 33.4535,
    longitude: -82.646,
    postal_code: '30807'
  },
  '30808': {
    country: 'US',
    city: 'Dearing',
    state: 'Georgia',
    state_short: 'GA',
    county: 'McDuffie',
    latitude: 33.407,
    longitude: -82.3955,
    postal_code: '30808'
  },
  '30809': {
    country: 'US',
    city: 'Evans',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Columbia',
    latitude: 33.5412,
    longitude: -82.1398,
    postal_code: '30809'
  },
  '30810': {
    country: 'US',
    city: 'Gibson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Glascock',
    latitude: 33.2471,
    longitude: -82.5766,
    postal_code: '30810'
  },
  '30811': {
    country: 'US',
    city: 'Gough',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Burke',
    latitude: 33.0918,
    longitude: -82.2265,
    postal_code: '30811'
  },
  '30812': {
    country: 'US',
    city: 'Gracewood',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.3726,
    longitude: -82.0321,
    postal_code: '30812'
  },
  '30813': {
    country: 'US',
    city: 'Grovetown',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Columbia',
    latitude: 33.4504,
    longitude: -82.1982,
    postal_code: '30813'
  },
  '30814': {
    country: 'US',
    city: 'Harlem',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Columbia',
    latitude: 33.417,
    longitude: -82.3097,
    postal_code: '30814'
  },
  '30815': {
    country: 'US',
    city: 'Hephzibah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.3433,
    longitude: -82.0887,
    postal_code: '30815'
  },
  '30816': {
    country: 'US',
    city: 'Keysville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Burke',
    latitude: 33.1719,
    longitude: -82.1834,
    postal_code: '30816'
  },
  '30817': {
    country: 'US',
    city: 'Lincolnton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lincoln',
    latitude: 33.7773,
    longitude: -82.4435,
    postal_code: '30817'
  },
  '30818': {
    country: 'US',
    city: 'Matthews',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jefferson',
    latitude: 33.2652,
    longitude: -82.3294,
    postal_code: '30818'
  },
  '30819': {
    country: 'US',
    city: 'Mesena',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Warren',
    latitude: 33.4599,
    longitude: -82.5901,
    postal_code: '30819'
  },
  '30820': {
    country: 'US',
    city: 'Mitchell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Glascock',
    latitude: 33.2052,
    longitude: -82.6817,
    postal_code: '30820'
  },
  '30821': {
    country: 'US',
    city: 'Norwood',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Warren',
    latitude: 33.4789,
    longitude: -82.7356,
    postal_code: '30821'
  },
  '30822': {
    country: 'US',
    city: 'Perkins',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jenkins',
    latitude: 32.9129,
    longitude: -81.8538,
    postal_code: '30822'
  },
  '30823': {
    country: 'US',
    city: 'Stapleton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jefferson',
    latitude: 33.1894,
    longitude: -82.4597,
    postal_code: '30823'
  },
  '30824': {
    country: 'US',
    city: 'Thomson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'McDuffie',
    latitude: 33.4774,
    longitude: -82.4942,
    postal_code: '30824'
  },
  '30828': {
    country: 'US',
    city: 'Warrenton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Warren',
    latitude: 33.4091,
    longitude: -82.6357,
    postal_code: '30828'
  },
  '30830': {
    country: 'US',
    city: 'Waynesboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Burke',
    latitude: 33.1013,
    longitude: -81.9908,
    postal_code: '30830'
  },
  '30833': {
    country: 'US',
    city: 'Wrens',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jefferson',
    latitude: 33.2104,
    longitude: -82.381,
    postal_code: '30833'
  },
  '30901': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.4601,
    longitude: -81.973,
    postal_code: '30901'
  },
  '30903': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.386,
    longitude: -82.091,
    postal_code: '30903'
  },
  '30904': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.4737,
    longitude: -82.0131,
    postal_code: '30904'
  },
  '30905': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.413,
    longitude: -82.1337,
    postal_code: '30905'
  },
  '30906': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.3589,
    longitude: -82.0099,
    postal_code: '30906'
  },
  '30907': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.5229,
    longitude: -82.0852,
    postal_code: '30907'
  },
  '30909': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.4717,
    longitude: -82.0834,
    postal_code: '30909'
  },
  '30912': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.4705,
    longitude: -81.9881,
    postal_code: '30912'
  },
  '30914': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.386,
    longitude: -82.091,
    postal_code: '30914'
  },
  '30916': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.386,
    longitude: -82.091,
    postal_code: '30916'
  },
  '30917': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Columbia',
    latitude: 33.5277,
    longitude: -82.2355,
    postal_code: '30917'
  },
  '30919': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.386,
    longitude: -82.091,
    postal_code: '30919'
  },
  '30999': {
    country: 'US',
    city: 'Augusta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Richmond',
    latitude: 33.386,
    longitude: -82.091,
    postal_code: '30999'
  },
  '31001': {
    country: 'US',
    city: 'Abbeville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilcox',
    latitude: 31.9648,
    longitude: -83.3068,
    postal_code: '31001'
  },
  '31002': {
    country: 'US',
    city: 'Adrian',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Emanuel',
    latitude: 32.5635,
    longitude: -82.5695,
    postal_code: '31002'
  },
  '31003': {
    country: 'US',
    city: 'Allentown',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilkinson',
    latitude: 32.6064,
    longitude: -83.2095,
    postal_code: '31003'
  },
  '31004': {
    country: 'US',
    city: 'Bolingbroke',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Monroe',
    latitude: 32.947,
    longitude: -83.8006,
    postal_code: '31004'
  },
  '31005': {
    country: 'US',
    city: 'Bonaire',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.546,
    longitude: -83.6047,
    postal_code: '31005'
  },
  '31006': {
    country: 'US',
    city: 'Butler',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Taylor',
    latitude: 32.5726,
    longitude: -84.2343,
    postal_code: '31006'
  },
  '31007': {
    country: 'US',
    city: 'Byromville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dooly',
    latitude: 32.189,
    longitude: -83.9332,
    postal_code: '31007'
  },
  '31008': {
    country: 'US',
    city: 'Byron',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Peach',
    latitude: 32.6181,
    longitude: -83.789,
    postal_code: '31008'
  },
  '31009': {
    country: 'US',
    city: 'Cadwell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Laurens',
    latitude: 32.3174,
    longitude: -83.0268,
    postal_code: '31009'
  },
  '31010': {
    country: 'US',
    city: 'Cordele',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Crisp',
    latitude: 31.9172,
    longitude: -83.7854,
    postal_code: '31010'
  },
  '31011': {
    country: 'US',
    city: 'Chauncey',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dodge',
    latitude: 32.1046,
    longitude: -83.0646,
    postal_code: '31011'
  },
  '31012': {
    country: 'US',
    city: 'Chester',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dodge',
    latitude: 32.3982,
    longitude: -83.174,
    postal_code: '31012'
  },
  '31013': {
    country: 'US',
    city: 'Clinchfield',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.3386,
    longitude: -83.6926,
    postal_code: '31013'
  },
  '31014': {
    country: 'US',
    city: 'Cochran',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bleckley',
    latitude: 32.3981,
    longitude: -83.3229,
    postal_code: '31014'
  },
  '31015': {
    country: 'US',
    city: 'Cordele',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Crisp',
    latitude: 31.9566,
    longitude: -83.7835,
    postal_code: '31015'
  },
  '31016': {
    country: 'US',
    city: 'Culloden',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Monroe',
    latitude: 32.9003,
    longitude: -84.0626,
    postal_code: '31016'
  },
  '31017': {
    country: 'US',
    city: 'Danville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Twiggs',
    latitude: 32.6057,
    longitude: -83.2454,
    postal_code: '31017'
  },
  '31018': {
    country: 'US',
    city: 'Davisboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Washington',
    latitude: 32.9443,
    longitude: -82.6227,
    postal_code: '31018'
  },
  '31019': {
    country: 'US',
    city: 'Dexter',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Laurens',
    latitude: 32.4356,
    longitude: -83.0528,
    postal_code: '31019'
  },
  '31020': {
    country: 'US',
    city: 'Dry Branch',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Twiggs',
    latitude: 32.7151,
    longitude: -83.4558,
    postal_code: '31020'
  },
  '31021': {
    country: 'US',
    city: 'Dublin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Laurens',
    latitude: 32.4593,
    longitude: -82.9381,
    postal_code: '31021'
  },
  '31022': {
    country: 'US',
    city: 'Dudley',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Laurens',
    latitude: 32.5259,
    longitude: -83.0899,
    postal_code: '31022'
  },
  '31023': {
    country: 'US',
    city: 'Eastman',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dodge',
    latitude: 32.2084,
    longitude: -83.186,
    postal_code: '31023'
  },
  '31024': {
    country: 'US',
    city: 'Eatonton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Putnam',
    latitude: 33.3127,
    longitude: -83.3628,
    postal_code: '31024'
  },
  '31025': {
    country: 'US',
    city: 'Elko',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.3336,
    longitude: -83.7304,
    postal_code: '31025'
  },
  '31026': {
    country: 'US',
    city: 'Eatonton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Putnam',
    latitude: 33.3304,
    longitude: -83.377,
    postal_code: '31026'
  },
  '31027': {
    country: 'US',
    city: 'East Dublin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Laurens',
    latitude: 32.5482,
    longitude: -82.8718,
    postal_code: '31027'
  },
  '31028': {
    country: 'US',
    city: 'Centerville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.6344,
    longitude: -83.6768,
    postal_code: '31028'
  },
  '31029': {
    country: 'US',
    city: 'Forsyth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Monroe',
    latitude: 33.0508,
    longitude: -83.9362,
    postal_code: '31029'
  },
  '31030': {
    country: 'US',
    city: 'Fort Valley',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Peach',
    latitude: 32.5496,
    longitude: -83.8887,
    postal_code: '31030'
  },
  '31031': {
    country: 'US',
    city: 'Gordon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilkinson',
    latitude: 32.8813,
    longitude: -83.3029,
    postal_code: '31031'
  },
  '31032': {
    country: 'US',
    city: 'Gray',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jones',
    latitude: 33.0172,
    longitude: -83.54,
    postal_code: '31032'
  },
  '31033': {
    country: 'US',
    city: 'Haddock',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jones',
    latitude: 33.0519,
    longitude: -83.4312,
    postal_code: '31033'
  },
  '31034': {
    country: 'US',
    city: 'Hardwick',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Baldwin',
    latitude: 33.0227,
    longitude: -83.247,
    postal_code: '31034'
  },
  '31035': {
    country: 'US',
    city: 'Harrison',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Washington',
    latitude: 32.842,
    longitude: -82.7159,
    postal_code: '31035'
  },
  '31036': {
    country: 'US',
    city: 'Hawkinsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pulaski',
    latitude: 32.2778,
    longitude: -83.4948,
    postal_code: '31036'
  },
  '31037': {
    country: 'US',
    city: 'Mc Rae Helena',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Telfair',
    latitude: 32.0738,
    longitude: -82.9146,
    postal_code: '31037'
  },
  '31038': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jasper',
    latitude: 33.1421,
    longitude: -83.6404,
    postal_code: '31038'
  },
  '31039': {
    country: 'US',
    city: 'Howard',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Taylor',
    latitude: 32.596,
    longitude: -84.3844,
    postal_code: '31039'
  },
  '31040': {
    country: 'US',
    city: 'Dublin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Laurens',
    latitude: 32.5401,
    longitude: -82.915,
    postal_code: '31040'
  },
  '31041': {
    country: 'US',
    city: 'Ideal',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Macon',
    latitude: 32.3696,
    longitude: -84.188,
    postal_code: '31041'
  },
  '31042': {
    country: 'US',
    city: 'Irwinton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilkinson',
    latitude: 32.8088,
    longitude: -83.174,
    postal_code: '31042'
  },
  '31044': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Twiggs',
    latitude: 32.7411,
    longitude: -83.3859,
    postal_code: '31044'
  },
  '31045': {
    country: 'US',
    city: 'Jewell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Warren',
    latitude: 33.2963,
    longitude: -82.7774,
    postal_code: '31045'
  },
  '31046': {
    country: 'US',
    city: 'Juliette',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Monroe',
    latitude: 33.1194,
    longitude: -83.8235,
    postal_code: '31046'
  },
  '31047': {
    country: 'US',
    city: 'Kathleen',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.4672,
    longitude: -83.6128,
    postal_code: '31047'
  },
  '31049': {
    country: 'US',
    city: 'Kite',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Johnson',
    latitude: 32.7078,
    longitude: -82.5274,
    postal_code: '31049'
  },
  '31050': {
    country: 'US',
    city: 'Knoxville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Crawford',
    latitude: 32.7243,
    longitude: -83.9977,
    postal_code: '31050'
  },
  '31051': {
    country: 'US',
    city: 'Lilly',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dooly',
    latitude: 32.1471,
    longitude: -83.8774,
    postal_code: '31051'
  },
  '31052': {
    country: 'US',
    city: 'Lizella',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.7773,
    longitude: -83.825,
    postal_code: '31052'
  },
  '31054': {
    country: 'US',
    city: 'Mc Intyre',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilkinson',
    latitude: 32.8468,
    longitude: -83.204,
    postal_code: '31054'
  },
  '31055': {
    country: 'US',
    city: 'Mc Rae Helena',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Telfair',
    latitude: 32.068,
    longitude: -82.9007,
    postal_code: '31055'
  },
  '31057': {
    country: 'US',
    city: 'Marshallville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Macon',
    latitude: 32.452,
    longitude: -83.9435,
    postal_code: '31057'
  },
  '31058': {
    country: 'US',
    city: 'Mauk',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Taylor',
    latitude: 32.509,
    longitude: -84.3999,
    postal_code: '31058'
  },
  '31059': {
    country: 'US',
    city: 'Milledgeville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Baldwin',
    latitude: 33.0846,
    longitude: -83.238,
    postal_code: '31059'
  },
  '31060': {
    country: 'US',
    city: 'Milan',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Telfair',
    latitude: 31.9618,
    longitude: -83.0588,
    postal_code: '31060'
  },
  '31061': {
    country: 'US',
    city: 'Milledgeville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Baldwin',
    latitude: 33.08,
    longitude: -83.2379,
    postal_code: '31061'
  },
  '31062': {
    country: 'US',
    city: 'Milledgeville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Baldwin',
    latitude: 33.0491,
    longitude: -83.2174,
    postal_code: '31062'
  },
  '31063': {
    country: 'US',
    city: 'Montezuma',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dooly',
    latitude: 32.3026,
    longitude: -84.0041,
    postal_code: '31063'
  },
  '31064': {
    country: 'US',
    city: 'Monticello',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jasper',
    latitude: 33.3118,
    longitude: -83.714,
    postal_code: '31064'
  },
  '31065': {
    country: 'US',
    city: 'Montrose',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Laurens',
    latitude: 32.5619,
    longitude: -83.1601,
    postal_code: '31065'
  },
  '31066': {
    country: 'US',
    city: 'Musella',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Crawford',
    latitude: 32.8202,
    longitude: -84.0456,
    postal_code: '31066'
  },
  '31067': {
    country: 'US',
    city: 'Oconee',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Washington',
    latitude: 32.8554,
    longitude: -82.9499,
    postal_code: '31067'
  },
  '31068': {
    country: 'US',
    city: 'Oglethorpe',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Macon',
    latitude: 32.2842,
    longitude: -84.083,
    postal_code: '31068'
  },
  '31069': {
    country: 'US',
    city: 'Perry',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.4605,
    longitude: -83.7283,
    postal_code: '31069'
  },
  '31070': {
    country: 'US',
    city: 'Pinehurst',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dooly',
    latitude: 32.1967,
    longitude: -83.7209,
    postal_code: '31070'
  },
  '31071': {
    country: 'US',
    city: 'Pineview',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilcox',
    latitude: 32.0907,
    longitude: -83.5158,
    postal_code: '31071'
  },
  '31072': {
    country: 'US',
    city: 'Pitts',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilcox',
    latitude: 31.9425,
    longitude: -83.5579,
    postal_code: '31072'
  },
  '31075': {
    country: 'US',
    city: 'Rentz',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Laurens',
    latitude: 32.3799,
    longitude: -82.967,
    postal_code: '31075'
  },
  '31076': {
    country: 'US',
    city: 'Reynolds',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Taylor',
    latitude: 32.5541,
    longitude: -84.1011,
    postal_code: '31076'
  },
  '31077': {
    country: 'US',
    city: 'Rhine',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dodge',
    latitude: 31.938,
    longitude: -83.1837,
    postal_code: '31077'
  },
  '31078': {
    country: 'US',
    city: 'Roberta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Crawford',
    latitude: 32.7222,
    longitude: -84.0451,
    postal_code: '31078'
  },
  '31079': {
    country: 'US',
    city: 'Rochelle',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilcox',
    latitude: 31.9491,
    longitude: -83.445,
    postal_code: '31079'
  },
  '31081': {
    country: 'US',
    city: 'Rupert',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Taylor',
    latitude: 32.4325,
    longitude: -84.2738,
    postal_code: '31081'
  },
  '31082': {
    country: 'US',
    city: 'Sandersville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Washington',
    latitude: 32.975,
    longitude: -82.8406,
    postal_code: '31082'
  },
  '31083': {
    country: 'US',
    city: 'Scotland',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Telfair',
    latitude: 32.0488,
    longitude: -82.8171,
    postal_code: '31083'
  },
  '31084': {
    country: 'US',
    city: 'Seville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilcox',
    latitude: 31.9605,
    longitude: -83.601,
    postal_code: '31084'
  },
  '31085': {
    country: 'US',
    city: 'Shady Dale',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jasper',
    latitude: 33.435,
    longitude: -83.6269,
    postal_code: '31085'
  },
  '31086': {
    country: 'US',
    city: 'Smarr',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Monroe',
    latitude: 32.9895,
    longitude: -83.8761,
    postal_code: '31086'
  },
  '31087': {
    country: 'US',
    city: 'Sparta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Hancock',
    latitude: 33.2571,
    longitude: -83.0892,
    postal_code: '31087'
  },
  '31088': {
    country: 'US',
    city: 'Warner Robins',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.5934,
    longitude: -83.6416,
    postal_code: '31088'
  },
  '31089': {
    country: 'US',
    city: 'Tennille',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Washington',
    latitude: 32.9063,
    longitude: -82.84,
    postal_code: '31089'
  },
  '31090': {
    country: 'US',
    city: 'Toomsboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wilkinson',
    latitude: 32.8219,
    longitude: -83.0844,
    postal_code: '31090'
  },
  '31091': {
    country: 'US',
    city: 'Unadilla',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dooly',
    latitude: 32.2558,
    longitude: -83.7447,
    postal_code: '31091'
  },
  '31092': {
    country: 'US',
    city: 'Vienna',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dooly',
    latitude: 32.0913,
    longitude: -83.7922,
    postal_code: '31092'
  },
  '31093': {
    country: 'US',
    city: 'Warner Robins',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.6368,
    longitude: -83.6395,
    postal_code: '31093'
  },
  '31094': {
    country: 'US',
    city: 'Warthen',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Washington',
    latitude: 33.1255,
    longitude: -82.8039,
    postal_code: '31094'
  },
  '31095': {
    country: 'US',
    city: 'Warner Robins',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.4874,
    longitude: -83.6697,
    postal_code: '31095'
  },
  '31096': {
    country: 'US',
    city: 'Wrightsville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Johnson',
    latitude: 32.7219,
    longitude: -82.7262,
    postal_code: '31096'
  },
  '31097': {
    country: 'US',
    city: 'Yatesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Upson',
    latitude: 32.9156,
    longitude: -84.1593,
    postal_code: '31097'
  },
  '31098': {
    country: 'US',
    city: 'Warner Robins',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.6181,
    longitude: -83.5739,
    postal_code: '31098'
  },
  '31099': {
    country: 'US',
    city: 'Warner Robins',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Houston',
    latitude: 32.6462,
    longitude: -83.6513,
    postal_code: '31099'
  },
  '31106': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31106'
  },
  '31107': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31107'
  },
  '31119': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '31119'
  },
  '31126': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31126'
  },
  '31131': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31131'
  },
  '31136': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.7473,
    longitude: -84.3824,
    postal_code: '31136'
  },
  '31139': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31139'
  },
  '31141': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '31141'
  },
  '31144': {
    country: 'US',
    city: 'Kennesaw',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cobb',
    latitude: 34.0233,
    longitude: -84.6156,
    postal_code: '31144'
  },
  '31145': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '31145'
  },
  '31146': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '31146'
  },
  '31150': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31150'
  },
  '31156': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31156'
  },
  '31169': {
    country: 'US',
    city: 'Peachtree City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fayette',
    latitude: 33.3967,
    longitude: -84.5958,
    postal_code: '31169'
  },
  '31192': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31192'
  },
  '31193': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31193'
  },
  '31195': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31195'
  },
  '31196': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Fulton',
    latitude: 33.8444,
    longitude: -84.4741,
    postal_code: '31196'
  },
  '31201': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8095,
    longitude: -83.6168,
    postal_code: '31201'
  },
  '31202': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8407,
    longitude: -83.6324,
    postal_code: '31202'
  },
  '31203': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8067,
    longitude: -83.6913,
    postal_code: '31203'
  },
  '31204': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8424,
    longitude: -83.6766,
    postal_code: '31204'
  },
  '31205': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8067,
    longitude: -83.6913,
    postal_code: '31205'
  },
  '31206': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.7914,
    longitude: -83.679,
    postal_code: '31206'
  },
  '31207': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8304,
    longitude: -83.6486,
    postal_code: '31207'
  },
  '31208': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8067,
    longitude: -83.6913,
    postal_code: '31208'
  },
  '31209': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8067,
    longitude: -83.6913,
    postal_code: '31209'
  },
  '31210': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8926,
    longitude: -83.7455,
    postal_code: '31210'
  },
  '31211': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8869,
    longitude: -83.6021,
    postal_code: '31211'
  },
  '31213': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8393,
    longitude: -83.6388,
    postal_code: '31213'
  },
  '31216': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.7486,
    longitude: -83.7477,
    postal_code: '31216'
  },
  '31217': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8118,
    longitude: -83.565,
    postal_code: '31217'
  },
  '31220': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8595,
    longitude: -83.802,
    postal_code: '31220'
  },
  '31221': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8407,
    longitude: -83.6324,
    postal_code: '31221'
  },
  '31294': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8407,
    longitude: -83.6324,
    postal_code: '31294'
  },
  '31295': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8102,
    longitude: -83.569,
    postal_code: '31295'
  },
  '31296': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.8067,
    longitude: -83.6913,
    postal_code: '31296'
  },
  '31297': {
    country: 'US',
    city: 'Macon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bibb',
    latitude: 32.7004,
    longitude: -83.6572,
    postal_code: '31297'
  },
  '31301': {
    country: 'US',
    city: 'Allenhurst',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Liberty',
    latitude: 31.7741,
    longitude: -81.6186,
    postal_code: '31301'
  },
  '31302': {
    country: 'US',
    city: 'Bloomingdale',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.1177,
    longitude: -81.3085,
    postal_code: '31302'
  },
  '31303': {
    country: 'US',
    city: 'Clyo',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Effingham',
    latitude: 32.5126,
    longitude: -81.3086,
    postal_code: '31303'
  },
  '31304': {
    country: 'US',
    city: 'Crescent',
    state: 'Georgia',
    state_short: 'GA',
    county: 'McIntosh',
    latitude: 31.5094,
    longitude: -81.3695,
    postal_code: '31304'
  },
  '31305': {
    country: 'US',
    city: 'Darien',
    state: 'Georgia',
    state_short: 'GA',
    county: 'McIntosh',
    latitude: 31.3826,
    longitude: -81.4312,
    postal_code: '31305'
  },
  '31307': {
    country: 'US',
    city: 'Eden',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Effingham',
    latitude: 32.1736,
    longitude: -81.3992,
    postal_code: '31307'
  },
  '31308': {
    country: 'US',
    city: 'Ellabell',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bryan',
    latitude: 32.1273,
    longitude: -81.4983,
    postal_code: '31308'
  },
  '31309': {
    country: 'US',
    city: 'Fleming',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Liberty',
    latitude: 31.8645,
    longitude: -81.4232,
    postal_code: '31309'
  },
  '31310': {
    country: 'US',
    city: 'Hinesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Liberty',
    latitude: 31.8068,
    longitude: -81.4371,
    postal_code: '31310'
  },
  '31312': {
    country: 'US',
    city: 'Guyton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Effingham',
    latitude: 32.314,
    longitude: -81.3896,
    postal_code: '31312'
  },
  '31313': {
    country: 'US',
    city: 'Hinesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Liberty',
    latitude: 31.8513,
    longitude: -81.6072,
    postal_code: '31313'
  },
  '31314': {
    country: 'US',
    city: 'Fort Stewart',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Liberty',
    latitude: 31.8701,
    longitude: -81.6318,
    postal_code: '31314'
  },
  '31315': {
    country: 'US',
    city: 'Fort Stewart',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Liberty',
    latitude: 31.8938,
    longitude: -81.5902,
    postal_code: '31315'
  },
  '31316': {
    country: 'US',
    city: 'Ludowici',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Long',
    latitude: 31.7705,
    longitude: -81.7453,
    postal_code: '31316'
  },
  '31318': {
    country: 'US',
    city: 'Meldrim',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Effingham',
    latitude: 32.1499,
    longitude: -81.3722,
    postal_code: '31318'
  },
  '31319': {
    country: 'US',
    city: 'Meridian',
    state: 'Georgia',
    state_short: 'GA',
    county: 'McIntosh',
    latitude: 31.459,
    longitude: -81.3689,
    postal_code: '31319'
  },
  '31320': {
    country: 'US',
    city: 'Midway',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Liberty',
    latitude: 31.8018,
    longitude: -81.3909,
    postal_code: '31320'
  },
  '31321': {
    country: 'US',
    city: 'Pembroke',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bryan',
    latitude: 32.1812,
    longitude: -81.6656,
    postal_code: '31321'
  },
  '31322': {
    country: 'US',
    city: 'Pooler',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.1149,
    longitude: -81.252,
    postal_code: '31322'
  },
  '31323': {
    country: 'US',
    city: 'Riceboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Liberty',
    latitude: 31.7357,
    longitude: -81.4671,
    postal_code: '31323'
  },
  '31324': {
    country: 'US',
    city: 'Richmond Hill',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bryan',
    latitude: 31.8962,
    longitude: -81.294,
    postal_code: '31324'
  },
  '31326': {
    country: 'US',
    city: 'Rincon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Effingham',
    latitude: 32.296,
    longitude: -81.2354,
    postal_code: '31326'
  },
  '31327': {
    country: 'US',
    city: 'Sapelo Island',
    state: 'Georgia',
    state_short: 'GA',
    county: 'McIntosh',
    latitude: 31.3974,
    longitude: -81.2787,
    postal_code: '31327'
  },
  '31328': {
    country: 'US',
    city: 'Tybee Island',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.0068,
    longitude: -80.8509,
    postal_code: '31328'
  },
  '31329': {
    country: 'US',
    city: 'Springfield',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Effingham',
    latitude: 32.3697,
    longitude: -81.3618,
    postal_code: '31329'
  },
  '31331': {
    country: 'US',
    city: 'Townsend',
    state: 'Georgia',
    state_short: 'GA',
    county: 'McIntosh',
    latitude: 31.5673,
    longitude: -81.4182,
    postal_code: '31331'
  },
  '31333': {
    country: 'US',
    city: 'Walthourville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Liberty',
    latitude: 31.7705,
    longitude: -81.6208,
    postal_code: '31333'
  },
  '31401': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.0749,
    longitude: -81.0883,
    postal_code: '31401'
  },
  '31402': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.0835,
    longitude: -81.0998,
    postal_code: '31402'
  },
  '31403': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 31.9714,
    longitude: -81.0716,
    postal_code: '31403'
  },
  '31404': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.0543,
    longitude: -81.0492,
    postal_code: '31404'
  },
  '31405': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.0391,
    longitude: -81.1242,
    postal_code: '31405'
  },
  '31406': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 31.989,
    longitude: -81.0979,
    postal_code: '31406'
  },
  '31407': {
    country: 'US',
    city: 'Port Wentworth',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.1491,
    longitude: -81.1632,
    postal_code: '31407'
  },
  '31408': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.1082,
    longitude: -81.1746,
    postal_code: '31408'
  },
  '31409': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.0093,
    longitude: -81.157,
    postal_code: '31409'
  },
  '31410': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.0175,
    longitude: -80.997,
    postal_code: '31410'
  },
  '31411': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 31.9268,
    longitude: -81.0381,
    postal_code: '31411'
  },
  '31412': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.0835,
    longitude: -81.0998,
    postal_code: '31412'
  },
  '31414': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 31.9714,
    longitude: -81.0716,
    postal_code: '31414'
  },
  '31415': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.0753,
    longitude: -81.1289,
    postal_code: '31415'
  },
  '31416': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 32.0053,
    longitude: -81.0477,
    postal_code: '31416'
  },
  '31418': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 31.9714,
    longitude: -81.0716,
    postal_code: '31418'
  },
  '31419': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 31.9959,
    longitude: -81.2358,
    postal_code: '31419'
  },
  '31420': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 31.9714,
    longitude: -81.0716,
    postal_code: '31420'
  },
  '31421': {
    country: 'US',
    city: 'Savannah',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chatham',
    latitude: 31.9714,
    longitude: -81.0716,
    postal_code: '31421'
  },
  '31501': {
    country: 'US',
    city: 'Waycross',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Ware',
    latitude: 31.2243,
    longitude: -82.3596,
    postal_code: '31501'
  },
  '31502': {
    country: 'US',
    city: 'Waycross',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Ware',
    latitude: 31.2137,
    longitude: -82.3557,
    postal_code: '31502'
  },
  '31503': {
    country: 'US',
    city: 'Waycross',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Ware',
    latitude: 31.2137,
    longitude: -82.3557,
    postal_code: '31503'
  },
  '31510': {
    country: 'US',
    city: 'Alma',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Bacon',
    latitude: 31.5465,
    longitude: -82.4633,
    postal_code: '31510'
  },
  '31512': {
    country: 'US',
    city: 'Ambrose',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coffee',
    latitude: 31.5367,
    longitude: -82.9976,
    postal_code: '31512'
  },
  '31513': {
    country: 'US',
    city: 'Baxley',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Appling',
    latitude: 31.7837,
    longitude: -82.3486,
    postal_code: '31513'
  },
  '31515': {
    country: 'US',
    city: 'Baxley',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Appling',
    latitude: 31.7783,
    longitude: -82.3485,
    postal_code: '31515'
  },
  '31516': {
    country: 'US',
    city: 'Blackshear',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pierce',
    latitude: 31.2931,
    longitude: -82.2617,
    postal_code: '31516'
  },
  '31518': {
    country: 'US',
    city: 'Bristol',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pierce',
    latitude: 31.4833,
    longitude: -82.215,
    postal_code: '31518'
  },
  '31519': {
    country: 'US',
    city: 'Broxton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coffee',
    latitude: 31.6484,
    longitude: -82.905,
    postal_code: '31519'
  },
  '31520': {
    country: 'US',
    city: 'Brunswick',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Glynn',
    latitude: 31.1807,
    longitude: -81.4949,
    postal_code: '31520'
  },
  '31521': {
    country: 'US',
    city: 'Brunswick',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Glynn',
    latitude: 31.15,
    longitude: -81.4915,
    postal_code: '31521'
  },
  '31522': {
    country: 'US',
    city: 'Saint Simons Island',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Glynn',
    latitude: 31.23,
    longitude: -81.3502,
    postal_code: '31522'
  },
  '31523': {
    country: 'US',
    city: 'Brunswick',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Glynn',
    latitude: 31.2189,
    longitude: -81.546,
    postal_code: '31523'
  },
  '31524': {
    country: 'US',
    city: 'Brunswick',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Glynn',
    latitude: 31.222,
    longitude: -81.4826,
    postal_code: '31524'
  },
  '31525': {
    country: 'US',
    city: 'Brunswick',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Glynn',
    latitude: 31.2804,
    longitude: -81.5305,
    postal_code: '31525'
  },
  '31527': {
    country: 'US',
    city: 'Jekyll Island',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Glynn',
    latitude: 31.074,
    longitude: -81.4128,
    postal_code: '31527'
  },
  '31532': {
    country: 'US',
    city: 'Denton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jeff Davis',
    latitude: 31.7226,
    longitude: -82.7526,
    postal_code: '31532'
  },
  '31533': {
    country: 'US',
    city: 'Douglas',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coffee',
    latitude: 31.4973,
    longitude: -82.8465,
    postal_code: '31533'
  },
  '31534': {
    country: 'US',
    city: 'Douglas',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coffee',
    latitude: 31.5088,
    longitude: -82.8499,
    postal_code: '31534'
  },
  '31535': {
    country: 'US',
    city: 'Douglas',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coffee',
    latitude: 31.4551,
    longitude: -82.8561,
    postal_code: '31535'
  },
  '31537': {
    country: 'US',
    city: 'Folkston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Charlton',
    latitude: 30.8508,
    longitude: -82.0116,
    postal_code: '31537'
  },
  '31539': {
    country: 'US',
    city: 'Hazlehurst',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Jeff Davis',
    latitude: 31.8606,
    longitude: -82.5909,
    postal_code: '31539'
  },
  '31542': {
    country: 'US',
    city: 'Hoboken',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Brantley',
    latitude: 31.1431,
    longitude: -82.1207,
    postal_code: '31542'
  },
  '31543': {
    country: 'US',
    city: 'Hortense',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Brantley',
    latitude: 31.3199,
    longitude: -81.9596,
    postal_code: '31543'
  },
  '31544': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Telfair',
    latitude: 31.8481,
    longitude: -82.975,
    postal_code: '31544'
  },
  '31545': {
    country: 'US',
    city: 'Jesup',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wayne',
    latitude: 31.6043,
    longitude: -81.8871,
    postal_code: '31545'
  },
  '31546': {
    country: 'US',
    city: 'Jesup',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wayne',
    latitude: 31.5319,
    longitude: -81.8049,
    postal_code: '31546'
  },
  '31547': {
    country: 'US',
    city: 'Kings Bay',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Camden',
    latitude: 30.7906,
    longitude: -81.5607,
    postal_code: '31547'
  },
  '31548': {
    country: 'US',
    city: 'Kingsland',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Camden',
    latitude: 30.7977,
    longitude: -81.7075,
    postal_code: '31548'
  },
  '31549': {
    country: 'US',
    city: 'Lumber City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Telfair',
    latitude: 31.9251,
    longitude: -82.7073,
    postal_code: '31549'
  },
  '31550': {
    country: 'US',
    city: 'Manor',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Ware',
    latitude: 31.1088,
    longitude: -82.5742,
    postal_code: '31550'
  },
  '31551': {
    country: 'US',
    city: 'Mershon',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pierce',
    latitude: 31.4783,
    longitude: -82.217,
    postal_code: '31551'
  },
  '31552': {
    country: 'US',
    city: 'Millwood',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Ware',
    latitude: 31.2506,
    longitude: -82.6441,
    postal_code: '31552'
  },
  '31553': {
    country: 'US',
    city: 'Nahunta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Brantley',
    latitude: 31.1827,
    longitude: -81.9722,
    postal_code: '31553'
  },
  '31554': {
    country: 'US',
    city: 'Nicholls',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coffee',
    latitude: 31.4498,
    longitude: -82.6032,
    postal_code: '31554'
  },
  '31555': {
    country: 'US',
    city: 'Odum',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wayne',
    latitude: 31.6999,
    longitude: -81.9943,
    postal_code: '31555'
  },
  '31556': {
    country: 'US',
    city: 'Offerman',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pierce',
    latitude: 31.4126,
    longitude: -82.1155,
    postal_code: '31556'
  },
  '31557': {
    country: 'US',
    city: 'Patterson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Pierce',
    latitude: 31.3903,
    longitude: -82.1274,
    postal_code: '31557'
  },
  '31558': {
    country: 'US',
    city: 'Saint Marys',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Camden',
    latitude: 30.7305,
    longitude: -81.5465,
    postal_code: '31558'
  },
  '31560': {
    country: 'US',
    city: 'Screven',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wayne',
    latitude: 31.5168,
    longitude: -82.0397,
    postal_code: '31560'
  },
  '31561': {
    country: 'US',
    city: 'Sea Island',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Glynn',
    latitude: 31.1989,
    longitude: -81.3322,
    postal_code: '31561'
  },
  '31562': {
    country: 'US',
    city: 'Saint George',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Appling',
    latitude: 30.5224,
    longitude: -82.0376,
    postal_code: '31562'
  },
  '31563': {
    country: 'US',
    city: 'Surrency',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Appling',
    latitude: 31.6489,
    longitude: -82.1982,
    postal_code: '31563'
  },
  '31564': {
    country: 'US',
    city: 'Waresboro',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Ware',
    latitude: 31.248,
    longitude: -82.4737,
    postal_code: '31564'
  },
  '31565': {
    country: 'US',
    city: 'Waverly',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Camden',
    latitude: 31.0427,
    longitude: -81.5697,
    postal_code: '31565'
  },
  '31566': {
    country: 'US',
    city: 'Waynesville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Brantley',
    latitude: 31.2448,
    longitude: -81.8039,
    postal_code: '31566'
  },
  '31567': {
    country: 'US',
    city: 'West Green',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Coffee',
    latitude: 31.6143,
    longitude: -82.7243,
    postal_code: '31567'
  },
  '31568': {
    country: 'US',
    city: 'White Oak',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Camden',
    latitude: 30.9952,
    longitude: -81.7782,
    postal_code: '31568'
  },
  '31569': {
    country: 'US',
    city: 'Woodbine',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Camden',
    latitude: 30.9437,
    longitude: -81.6783,
    postal_code: '31569'
  },
  '31598': {
    country: 'US',
    city: 'Jesup',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wayne',
    latitude: 31.5782,
    longitude: -81.8802,
    postal_code: '31598'
  },
  '31599': {
    country: 'US',
    city: 'Jesup',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Wayne',
    latitude: 31.5782,
    longitude: -81.8802,
    postal_code: '31599'
  },
  '31601': {
    country: 'US',
    city: 'Valdosta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.7539,
    longitude: -83.3321,
    postal_code: '31601'
  },
  '31602': {
    country: 'US',
    city: 'Valdosta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.8931,
    longitude: -83.3278,
    postal_code: '31602'
  },
  '31603': {
    country: 'US',
    city: 'Valdosta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.828,
    longitude: -83.2522,
    postal_code: '31603'
  },
  '31604': {
    country: 'US',
    city: 'Valdosta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.828,
    longitude: -83.2522,
    postal_code: '31604'
  },
  '31605': {
    country: 'US',
    city: 'Valdosta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.946,
    longitude: -83.2474,
    postal_code: '31605'
  },
  '31606': {
    country: 'US',
    city: 'Valdosta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.7989,
    longitude: -83.1891,
    postal_code: '31606'
  },
  '31620': {
    country: 'US',
    city: 'Adel',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cook',
    latitude: 31.1251,
    longitude: -83.4213,
    postal_code: '31620'
  },
  '31622': {
    country: 'US',
    city: 'Alapaha',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Berrien',
    latitude: 31.394,
    longitude: -83.2132,
    postal_code: '31622'
  },
  '31623': {
    country: 'US',
    city: 'Argyle',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clinch',
    latitude: 31.0744,
    longitude: -82.644,
    postal_code: '31623'
  },
  '31624': {
    country: 'US',
    city: 'Axson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Atkinson',
    latitude: 31.3035,
    longitude: -82.7319,
    postal_code: '31624'
  },
  '31625': {
    country: 'US',
    city: 'Barney',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Brooks',
    latitude: 31.0074,
    longitude: -83.5219,
    postal_code: '31625'
  },
  '31626': {
    country: 'US',
    city: 'Boston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Thomas',
    latitude: 30.7855,
    longitude: -83.7971,
    postal_code: '31626'
  },
  '31627': {
    country: 'US',
    city: 'Cecil',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cook',
    latitude: 31.0403,
    longitude: -83.3915,
    postal_code: '31627'
  },
  '31629': {
    country: 'US',
    city: 'Dixie',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Brooks',
    latitude: 30.7722,
    longitude: -83.6792,
    postal_code: '31629'
  },
  '31630': {
    country: 'US',
    city: 'Du Pont',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clinch',
    latitude: 30.9885,
    longitude: -82.8715,
    postal_code: '31630'
  },
  '31631': {
    country: 'US',
    city: 'Fargo',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clinch',
    latitude: 30.6819,
    longitude: -82.5665,
    postal_code: '31631'
  },
  '31632': {
    country: 'US',
    city: 'Hahira',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.9913,
    longitude: -83.3727,
    postal_code: '31632'
  },
  '31634': {
    country: 'US',
    city: 'Homerville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clinch',
    latitude: 31.0509,
    longitude: -82.7613,
    postal_code: '31634'
  },
  '31635': {
    country: 'US',
    city: 'Lakeland',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lanier',
    latitude: 31.0381,
    longitude: -83.0889,
    postal_code: '31635'
  },
  '31636': {
    country: 'US',
    city: 'Lake Park',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.6906,
    longitude: -83.1753,
    postal_code: '31636'
  },
  '31637': {
    country: 'US',
    city: 'Lenox',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cook',
    latitude: 31.2664,
    longitude: -83.4481,
    postal_code: '31637'
  },
  '31638': {
    country: 'US',
    city: 'Morven',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Brooks',
    latitude: 30.9416,
    longitude: -83.4993,
    postal_code: '31638'
  },
  '31639': {
    country: 'US',
    city: 'Nashville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Berrien',
    latitude: 31.2074,
    longitude: -83.2319,
    postal_code: '31639'
  },
  '31641': {
    country: 'US',
    city: 'Naylor',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.8985,
    longitude: -83.1224,
    postal_code: '31641'
  },
  '31642': {
    country: 'US',
    city: 'Pearson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Atkinson',
    latitude: 31.3106,
    longitude: -82.8591,
    postal_code: '31642'
  },
  '31643': {
    country: 'US',
    city: 'Quitman',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Brooks',
    latitude: 30.7797,
    longitude: -83.5567,
    postal_code: '31643'
  },
  '31645': {
    country: 'US',
    city: 'Ray City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Berrien',
    latitude: 31.0825,
    longitude: -83.2143,
    postal_code: '31645'
  },
  '31647': {
    country: 'US',
    city: 'Sparks',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Cook',
    latitude: 31.179,
    longitude: -83.4476,
    postal_code: '31647'
  },
  '31648': {
    country: 'US',
    city: 'Statenville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Echols',
    latitude: 30.7065,
    longitude: -83.019,
    postal_code: '31648'
  },
  '31649': {
    country: 'US',
    city: 'Stockton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lanier',
    latitude: 31.0229,
    longitude: -83.0139,
    postal_code: '31649'
  },
  '31650': {
    country: 'US',
    city: 'Willacoochee',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Atkinson',
    latitude: 31.3455,
    longitude: -83.0449,
    postal_code: '31650'
  },
  '31698': {
    country: 'US',
    city: 'Valdosta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.8485,
    longitude: -83.2878,
    postal_code: '31698'
  },
  '31699': {
    country: 'US',
    city: 'Moody Afb',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lowndes',
    latitude: 30.9785,
    longitude: -83.2165,
    postal_code: '31699'
  },
  '31701': {
    country: 'US',
    city: 'Albany',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dougherty',
    latitude: 31.5678,
    longitude: -84.1619,
    postal_code: '31701'
  },
  '31702': {
    country: 'US',
    city: 'Albany',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dougherty',
    latitude: 31.5948,
    longitude: -84.1948,
    postal_code: '31702'
  },
  '31703': {
    country: 'US',
    city: 'Albany',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dougherty',
    latitude: 31.5785,
    longitude: -84.1557,
    postal_code: '31703'
  },
  '31704': {
    country: 'US',
    city: 'Albany',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dougherty',
    latitude: 31.55,
    longitude: -84.0612,
    postal_code: '31704'
  },
  '31705': {
    country: 'US',
    city: 'Albany',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dougherty',
    latitude: 31.5464,
    longitude: -84.0783,
    postal_code: '31705'
  },
  '31706': {
    country: 'US',
    city: 'Albany',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dougherty',
    latitude: 31.5593,
    longitude: -84.1765,
    postal_code: '31706'
  },
  '31707': {
    country: 'US',
    city: 'Albany',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dougherty',
    latitude: 31.5789,
    longitude: -84.2118,
    postal_code: '31707'
  },
  '31708': {
    country: 'US',
    city: 'Albany',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dougherty',
    latitude: 31.5911,
    longitude: -84.1324,
    postal_code: '31708'
  },
  '31709': {
    country: 'US',
    city: 'Americus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Sumter',
    latitude: 32.0404,
    longitude: -84.2153,
    postal_code: '31709'
  },
  '31711': {
    country: 'US',
    city: 'Andersonville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Sumter',
    latitude: 32.1559,
    longitude: -84.164,
    postal_code: '31711'
  },
  '31712': {
    country: 'US',
    city: 'Arabi',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Crisp',
    latitude: 31.8496,
    longitude: -83.7277,
    postal_code: '31712'
  },
  '31714': {
    country: 'US',
    city: 'Ashburn',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Turner',
    latitude: 31.7059,
    longitude: -83.6608,
    postal_code: '31714'
  },
  '31716': {
    country: 'US',
    city: 'Baconton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Mitchell',
    latitude: 31.3878,
    longitude: -84.1135,
    postal_code: '31716'
  },
  '31719': {
    country: 'US',
    city: 'Americus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Sumter',
    latitude: 32.0833,
    longitude: -84.2975,
    postal_code: '31719'
  },
  '31720': {
    country: 'US',
    city: 'Barwick',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Brooks',
    latitude: 30.8902,
    longitude: -83.7407,
    postal_code: '31720'
  },
  '31721': {
    country: 'US',
    city: 'Albany',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Baker',
    latitude: 31.5105,
    longitude: -84.3087,
    postal_code: '31721'
  },
  '31722': {
    country: 'US',
    city: 'Berlin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Colquitt',
    latitude: 31.0682,
    longitude: -83.6238,
    postal_code: '31722'
  },
  '31727': {
    country: 'US',
    city: 'Brookfield',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tift',
    latitude: 31.4417,
    longitude: -83.5037,
    postal_code: '31727'
  },
  '31730': {
    country: 'US',
    city: 'Camilla',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Mitchell',
    latitude: 31.2199,
    longitude: -84.2297,
    postal_code: '31730'
  },
  '31733': {
    country: 'US',
    city: 'Chula',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tift',
    latitude: 31.5439,
    longitude: -83.5509,
    postal_code: '31733'
  },
  '31735': {
    country: 'US',
    city: 'Cobb',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Sumter',
    latitude: 31.9617,
    longitude: -83.9581,
    postal_code: '31735'
  },
  '31738': {
    country: 'US',
    city: 'Coolidge',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Thomas',
    latitude: 30.9859,
    longitude: -83.8752,
    postal_code: '31738'
  },
  '31739': {
    country: 'US',
    city: 'Cotton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Mitchell',
    latitude: 31.1616,
    longitude: -84.0668,
    postal_code: '31739'
  },
  '31743': {
    country: 'US',
    city: 'De Soto',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Sumter',
    latitude: 31.9502,
    longitude: -84.0271,
    postal_code: '31743'
  },
  '31744': {
    country: 'US',
    city: 'Doerun',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Colquitt',
    latitude: 31.3136,
    longitude: -83.9253,
    postal_code: '31744'
  },
  '31747': {
    country: 'US',
    city: 'Ellenton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Colquitt',
    latitude: 31.1789,
    longitude: -83.5889,
    postal_code: '31747'
  },
  '31749': {
    country: 'US',
    city: 'Enigma',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Berrien',
    latitude: 31.3736,
    longitude: -83.3552,
    postal_code: '31749'
  },
  '31750': {
    country: 'US',
    city: 'Fitzgerald',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Ben Hill',
    latitude: 31.7248,
    longitude: -83.2495,
    postal_code: '31750'
  },
  '31753': {
    country: 'US',
    city: 'Funston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Colquitt',
    latitude: 31.2054,
    longitude: -83.8776,
    postal_code: '31753'
  },
  '31756': {
    country: 'US',
    city: 'Hartsfield',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Colquitt',
    latitude: 31.2173,
    longitude: -83.9704,
    postal_code: '31756'
  },
  '31757': {
    country: 'US',
    city: 'Thomasville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Thomas',
    latitude: 30.8536,
    longitude: -83.8883,
    postal_code: '31757'
  },
  '31758': {
    country: 'US',
    city: 'Thomasville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Thomas',
    latitude: 30.8366,
    longitude: -83.9788,
    postal_code: '31758'
  },
  '31760': {
    country: 'US',
    city: 'Irwinville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Irwin',
    latitude: 31.7063,
    longitude: -83.4086,
    postal_code: '31760'
  },
  '31763': {
    country: 'US',
    city: 'Leesburg',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lee',
    latitude: 31.6812,
    longitude: -84.1593,
    postal_code: '31763'
  },
  '31764': {
    country: 'US',
    city: 'Leslie',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Sumter',
    latitude: 31.954,
    longitude: -84.0783,
    postal_code: '31764'
  },
  '31765': {
    country: 'US',
    city: 'Meigs',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Thomas',
    latitude: 31.0625,
    longitude: -84.0824,
    postal_code: '31765'
  },
  '31768': {
    country: 'US',
    city: 'Moultrie',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Colquitt',
    latitude: 31.1792,
    longitude: -83.7641,
    postal_code: '31768'
  },
  '31769': {
    country: 'US',
    city: 'Mystic',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Irwin',
    latitude: 31.6209,
    longitude: -83.2498,
    postal_code: '31769'
  },
  '31771': {
    country: 'US',
    city: 'Norman Park',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Colquitt',
    latitude: 31.2462,
    longitude: -83.6549,
    postal_code: '31771'
  },
  '31772': {
    country: 'US',
    city: 'Oakfield',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Worth',
    latitude: 31.7798,
    longitude: -83.9706,
    postal_code: '31772'
  },
  '31773': {
    country: 'US',
    city: 'Ochlocknee',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Thomas',
    latitude: 30.9742,
    longitude: -84.055,
    postal_code: '31773'
  },
  '31774': {
    country: 'US',
    city: 'Ocilla',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Irwin',
    latitude: 31.5929,
    longitude: -83.2565,
    postal_code: '31774'
  },
  '31775': {
    country: 'US',
    city: 'Omega',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tift',
    latitude: 31.33,
    longitude: -83.5974,
    postal_code: '31775'
  },
  '31776': {
    country: 'US',
    city: 'Moultrie',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Colquitt',
    latitude: 31.1727,
    longitude: -83.7924,
    postal_code: '31776'
  },
  '31778': {
    country: 'US',
    city: 'Pavo',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Thomas',
    latitude: 30.9401,
    longitude: -83.7409,
    postal_code: '31778'
  },
  '31779': {
    country: 'US',
    city: 'Pelham',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Mitchell',
    latitude: 31.1272,
    longitude: -84.1564,
    postal_code: '31779'
  },
  '31780': {
    country: 'US',
    city: 'Plains',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Sumter',
    latitude: 32.0339,
    longitude: -84.3586,
    postal_code: '31780'
  },
  '31781': {
    country: 'US',
    city: 'Poulan',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Worth',
    latitude: 31.5384,
    longitude: -83.7843,
    postal_code: '31781'
  },
  '31782': {
    country: 'US',
    city: 'Putney',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Dougherty',
    latitude: 31.5431,
    longitude: -84.2196,
    postal_code: '31782'
  },
  '31783': {
    country: 'US',
    city: 'Rebecca',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Turner',
    latitude: 31.7979,
    longitude: -83.5235,
    postal_code: '31783'
  },
  '31784': {
    country: 'US',
    city: 'Sale City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Mitchell',
    latitude: 31.26,
    longitude: -84.0422,
    postal_code: '31784'
  },
  '31787': {
    country: 'US',
    city: 'Smithville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Lee',
    latitude: 31.8847,
    longitude: -84.2271,
    postal_code: '31787'
  },
  '31788': {
    country: 'US',
    city: 'Moultrie',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Colquitt',
    latitude: 31.0855,
    longitude: -83.6821,
    postal_code: '31788'
  },
  '31789': {
    country: 'US',
    city: 'Sumner',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Worth',
    latitude: 31.513,
    longitude: -83.7385,
    postal_code: '31789'
  },
  '31790': {
    country: 'US',
    city: 'Sycamore',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Turner',
    latitude: 31.6553,
    longitude: -83.6064,
    postal_code: '31790'
  },
  '31791': {
    country: 'US',
    city: 'Sylvester',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Worth',
    latitude: 31.5393,
    longitude: -83.8607,
    postal_code: '31791'
  },
  '31792': {
    country: 'US',
    city: 'Thomasville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Thomas',
    latitude: 30.8385,
    longitude: -83.9696,
    postal_code: '31792'
  },
  '31793': {
    country: 'US',
    city: 'Tifton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tift',
    latitude: 31.4619,
    longitude: -83.5873,
    postal_code: '31793'
  },
  '31794': {
    country: 'US',
    city: 'Tifton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tift',
    latitude: 31.4639,
    longitude: -83.4999,
    postal_code: '31794'
  },
  '31795': {
    country: 'US',
    city: 'Ty Ty',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Tift',
    latitude: 31.4718,
    longitude: -83.6468,
    postal_code: '31795'
  },
  '31796': {
    country: 'US',
    city: 'Warwick',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Worth',
    latitude: 31.831,
    longitude: -83.9188,
    postal_code: '31796'
  },
  '31798': {
    country: 'US',
    city: 'Wray',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Irwin',
    latitude: 31.5952,
    longitude: -83.1075,
    postal_code: '31798'
  },
  '31799': {
    country: 'US',
    city: 'Thomasville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Thomas',
    latitude: 30.8366,
    longitude: -83.9788,
    postal_code: '31799'
  },
  '31801': {
    country: 'US',
    city: 'Box Springs',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Talbot',
    latitude: 32.5195,
    longitude: -84.5927,
    postal_code: '31801'
  },
  '31803': {
    country: 'US',
    city: 'Buena Vista',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Marion',
    latitude: 32.2542,
    longitude: -84.4898,
    postal_code: '31803'
  },
  '31804': {
    country: 'US',
    city: 'Cataula',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Harris',
    latitude: 32.6242,
    longitude: -84.9207,
    postal_code: '31804'
  },
  '31805': {
    country: 'US',
    city: 'Cusseta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Chattahoochee',
    latitude: 32.299,
    longitude: -84.7645,
    postal_code: '31805'
  },
  '31806': {
    country: 'US',
    city: 'Ellaville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Schley',
    latitude: 32.239,
    longitude: -84.3039,
    postal_code: '31806'
  },
  '31807': {
    country: 'US',
    city: 'Ellerslie',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Harris',
    latitude: 32.6312,
    longitude: -84.7895,
    postal_code: '31807'
  },
  '31808': {
    country: 'US',
    city: 'Fortson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.6288,
    longitude: -85.0017,
    postal_code: '31808'
  },
  '31810': {
    country: 'US',
    city: 'Geneva',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Talbot',
    latitude: 32.5799,
    longitude: -84.5508,
    postal_code: '31810'
  },
  '31811': {
    country: 'US',
    city: 'Hamilton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Harris',
    latitude: 32.7418,
    longitude: -84.8848,
    postal_code: '31811'
  },
  '31812': {
    country: 'US',
    city: 'Junction City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Talbot',
    latitude: 32.608,
    longitude: -84.4574,
    postal_code: '31812'
  },
  '31814': {
    country: 'US',
    city: 'Louvale',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Stewart',
    latitude: 32.1752,
    longitude: -84.8252,
    postal_code: '31814'
  },
  '31815': {
    country: 'US',
    city: 'Lumpkin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Stewart',
    latitude: 32.0435,
    longitude: -84.8022,
    postal_code: '31815'
  },
  '31816': {
    country: 'US',
    city: 'Manchester',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Meriwether',
    latitude: 32.8721,
    longitude: -84.6312,
    postal_code: '31816'
  },
  '31820': {
    country: 'US',
    city: 'Midland',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.5616,
    longitude: -84.8559,
    postal_code: '31820'
  },
  '31821': {
    country: 'US',
    city: 'Omaha',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Stewart',
    latitude: 32.1176,
    longitude: -84.9711,
    postal_code: '31821'
  },
  '31822': {
    country: 'US',
    city: 'Pine Mountain',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Harris',
    latitude: 32.8735,
    longitude: -84.896,
    postal_code: '31822'
  },
  '31823': {
    country: 'US',
    city: 'Pine Mountain Valley',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Harris',
    latitude: 32.8226,
    longitude: -84.8204,
    postal_code: '31823'
  },
  '31824': {
    country: 'US',
    city: 'Preston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Webster',
    latitude: 32.074,
    longitude: -84.5489,
    postal_code: '31824'
  },
  '31825': {
    country: 'US',
    city: 'Richland',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Stewart',
    latitude: 32.0846,
    longitude: -84.6667,
    postal_code: '31825'
  },
  '31826': {
    country: 'US',
    city: 'Shiloh',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Harris',
    latitude: 32.8101,
    longitude: -84.696,
    postal_code: '31826'
  },
  '31827': {
    country: 'US',
    city: 'Talbotton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Talbot',
    latitude: 32.6797,
    longitude: -84.5462,
    postal_code: '31827'
  },
  '31829': {
    country: 'US',
    city: 'Upatoi',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.5601,
    longitude: -84.7448,
    postal_code: '31829'
  },
  '31830': {
    country: 'US',
    city: 'Warm Springs',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Meriwether',
    latitude: 32.9063,
    longitude: -84.7167,
    postal_code: '31830'
  },
  '31831': {
    country: 'US',
    city: 'Waverly Hall',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Harris',
    latitude: 32.6793,
    longitude: -84.7425,
    postal_code: '31831'
  },
  '31832': {
    country: 'US',
    city: 'Weston',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Webster',
    latitude: 31.9637,
    longitude: -84.5756,
    postal_code: '31832'
  },
  '31833': {
    country: 'US',
    city: 'West Point',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Troup',
    latitude: 32.8337,
    longitude: -85.1197,
    postal_code: '31833'
  },
  '31836': {
    country: 'US',
    city: 'Woodland',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Talbot',
    latitude: 32.79,
    longitude: -84.5654,
    postal_code: '31836'
  },
  '31901': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.473,
    longitude: -84.9795,
    postal_code: '31901'
  },
  '31902': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.5243,
    longitude: -84.9558,
    postal_code: '31902'
  },
  '31903': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.4245,
    longitude: -84.9481,
    postal_code: '31903'
  },
  '31904': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.5161,
    longitude: -84.9785,
    postal_code: '31904'
  },
  '31905': {
    country: 'US',
    city: 'Fort Benning',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.3923,
    longitude: -84.9315,
    postal_code: '31905'
  },
  '31906': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.4638,
    longitude: -84.9484,
    postal_code: '31906'
  },
  '31907': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.4779,
    longitude: -84.898,
    postal_code: '31907'
  },
  '31908': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.5349,
    longitude: -84.9065,
    postal_code: '31908'
  },
  '31909': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.5369,
    longitude: -84.9274,
    postal_code: '31909'
  },
  '31914': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.491,
    longitude: -84.8741,
    postal_code: '31914'
  },
  '31917': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.491,
    longitude: -84.8741,
    postal_code: '31917'
  },
  '31993': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.4821,
    longitude: -84.9771,
    postal_code: '31993'
  },
  '31995': {
    country: 'US',
    city: 'Fort Benning',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.4958,
    longitude: -84.964,
    postal_code: '31995'
  },
  '31997': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.491,
    longitude: -84.8741,
    postal_code: '31997'
  },
  '31998': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.491,
    longitude: -84.8741,
    postal_code: '31998'
  },
  '31999': {
    country: 'US',
    city: 'Columbus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Muscogee',
    latitude: 32.461,
    longitude: -84.9877,
    postal_code: '31999'
  },
  '32003': {
    country: 'US',
    city: 'Fleming Island',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 30.0933,
    longitude: -81.719,
    postal_code: '32003'
  },
  '32004': {
    country: 'US',
    city: 'Ponte Vedra Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 30.2397,
    longitude: -81.3856,
    postal_code: '32004'
  },
  '32006': {
    country: 'US',
    city: 'Fleming Island',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 30.107,
    longitude: -81.7167,
    postal_code: '32006'
  },
  '32007': {
    country: 'US',
    city: 'Bostwick',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.7996,
    longitude: -81.6273,
    postal_code: '32007'
  },
  '32008': {
    country: 'US',
    city: 'Branford',
    state: 'Florida',
    state_short: 'FL',
    county: 'Suwannee',
    latitude: 29.9395,
    longitude: -82.8993,
    postal_code: '32008'
  },
  '32009': {
    country: 'US',
    city: 'Bryceville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Nassau',
    latitude: 30.4193,
    longitude: -81.9724,
    postal_code: '32009'
  },
  '32011': {
    country: 'US',
    city: 'Callahan',
    state: 'Florida',
    state_short: 'FL',
    county: 'Nassau',
    latitude: 30.552,
    longitude: -81.8145,
    postal_code: '32011'
  },
  '32013': {
    country: 'US',
    city: 'Day',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lafayette',
    latitude: 30.1941,
    longitude: -83.2913,
    postal_code: '32013'
  },
  '32024': {
    country: 'US',
    city: 'Lake City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Columbia',
    latitude: 30.1055,
    longitude: -82.6878,
    postal_code: '32024'
  },
  '32025': {
    country: 'US',
    city: 'Lake City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Columbia',
    latitude: 30.1601,
    longitude: -82.6396,
    postal_code: '32025'
  },
  '32026': {
    country: 'US',
    city: 'Raiford',
    state: 'Florida',
    state_short: 'FL',
    county: 'Union',
    latitude: 30.0699,
    longitude: -82.1927,
    postal_code: '32026'
  },
  '32030': {
    country: 'US',
    city: 'Doctors Inlet',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 30.1056,
    longitude: -81.769,
    postal_code: '32030'
  },
  '32033': {
    country: 'US',
    city: 'Elkton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 29.7882,
    longitude: -81.462,
    postal_code: '32033'
  },
  '32034': {
    country: 'US',
    city: 'Fernandina Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Nassau',
    latitude: 30.6697,
    longitude: -81.4626,
    postal_code: '32034'
  },
  '32035': {
    country: 'US',
    city: 'Fernandina Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Nassau',
    latitude: 30.6697,
    longitude: -81.4626,
    postal_code: '32035'
  },
  '32038': {
    country: 'US',
    city: 'Fort White',
    state: 'Florida',
    state_short: 'FL',
    county: 'Columbia',
    latitude: 29.9207,
    longitude: -82.6879,
    postal_code: '32038'
  },
  '32040': {
    country: 'US',
    city: 'Glen Saint Mary',
    state: 'Florida',
    state_short: 'FL',
    county: 'Baker',
    latitude: 30.2861,
    longitude: -82.2041,
    postal_code: '32040'
  },
  '32041': {
    country: 'US',
    city: 'Yulee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Nassau',
    latitude: 30.6233,
    longitude: -81.5902,
    postal_code: '32041'
  },
  '32042': {
    country: 'US',
    city: 'Graham',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bradford',
    latitude: 29.9689,
    longitude: -82.1226,
    postal_code: '32042'
  },
  '32043': {
    country: 'US',
    city: 'Green Cove Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 29.9983,
    longitude: -81.7647,
    postal_code: '32043'
  },
  '32044': {
    country: 'US',
    city: 'Hampton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bradford',
    latitude: 29.8575,
    longitude: -82.1483,
    postal_code: '32044'
  },
  '32046': {
    country: 'US',
    city: 'Hilliard',
    state: 'Florida',
    state_short: 'FL',
    county: 'Nassau',
    latitude: 30.6884,
    longitude: -81.9345,
    postal_code: '32046'
  },
  '32050': {
    country: 'US',
    city: 'Middleburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 30.0689,
    longitude: -81.8604,
    postal_code: '32050'
  },
  '32052': {
    country: 'US',
    city: 'Jasper',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hamilton',
    latitude: 30.5029,
    longitude: -82.9322,
    postal_code: '32052'
  },
  '32053': {
    country: 'US',
    city: 'Jennings',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hamilton',
    latitude: 30.5482,
    longitude: -83.135,
    postal_code: '32053'
  },
  '32054': {
    country: 'US',
    city: 'Lake Butler',
    state: 'Florida',
    state_short: 'FL',
    county: 'Union',
    latitude: 30.0035,
    longitude: -82.3828,
    postal_code: '32054'
  },
  '32055': {
    country: 'US',
    city: 'Lake City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Columbia',
    latitude: 30.2702,
    longitude: -82.6254,
    postal_code: '32055'
  },
  '32056': {
    country: 'US',
    city: 'Lake City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Columbia',
    latitude: 30.1897,
    longitude: -82.6393,
    postal_code: '32056'
  },
  '32058': {
    country: 'US',
    city: 'Lawtey',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bradford',
    latitude: 30.0472,
    longitude: -82.1055,
    postal_code: '32058'
  },
  '32059': {
    country: 'US',
    city: 'Lee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Madison',
    latitude: 30.3979,
    longitude: -83.2844,
    postal_code: '32059'
  },
  '32060': {
    country: 'US',
    city: 'Live Oak',
    state: 'Florida',
    state_short: 'FL',
    county: 'Suwannee',
    latitude: 30.1759,
    longitude: -83.0304,
    postal_code: '32060'
  },
  '32061': {
    country: 'US',
    city: 'Lulu',
    state: 'Florida',
    state_short: 'FL',
    county: 'Columbia',
    latitude: 30.0754,
    longitude: -82.5385,
    postal_code: '32061'
  },
  '32062': {
    country: 'US',
    city: 'Mc Alpin',
    state: 'Florida',
    state_short: 'FL',
    county: 'Suwannee',
    latitude: 30.1509,
    longitude: -82.9662,
    postal_code: '32062'
  },
  '32063': {
    country: 'US',
    city: 'Macclenny',
    state: 'Florida',
    state_short: 'FL',
    county: 'Baker',
    latitude: 30.2737,
    longitude: -82.1325,
    postal_code: '32063'
  },
  '32064': {
    country: 'US',
    city: 'Live Oak',
    state: 'Florida',
    state_short: 'FL',
    county: 'Suwannee',
    latitude: 30.2956,
    longitude: -82.9844,
    postal_code: '32064'
  },
  '32065': {
    country: 'US',
    city: 'Orange Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 30.1382,
    longitude: -81.7742,
    postal_code: '32065'
  },
  '32066': {
    country: 'US',
    city: 'Mayo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lafayette',
    latitude: 30.04,
    longitude: -83.1462,
    postal_code: '32066'
  },
  '32067': {
    country: 'US',
    city: 'Orange Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 30.1661,
    longitude: -81.7065,
    postal_code: '32067'
  },
  '32068': {
    country: 'US',
    city: 'Middleburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 30.084,
    longitude: -81.8645,
    postal_code: '32068'
  },
  '32071': {
    country: 'US',
    city: 'O Brien',
    state: 'Florida',
    state_short: 'FL',
    county: 'Suwannee',
    latitude: 30.0381,
    longitude: -82.93,
    postal_code: '32071'
  },
  '32072': {
    country: 'US',
    city: 'Olustee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Baker',
    latitude: 30.2041,
    longitude: -82.4287,
    postal_code: '32072'
  },
  '32073': {
    country: 'US',
    city: 'Orange Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 30.1637,
    longitude: -81.7291,
    postal_code: '32073'
  },
  '32079': {
    country: 'US',
    city: 'Penney Farms',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 29.9849,
    longitude: -81.8022,
    postal_code: '32079'
  },
  '32080': {
    country: 'US',
    city: 'Saint Augustine',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 29.7964,
    longitude: -81.2649,
    postal_code: '32080'
  },
  '32081': {
    country: 'US',
    city: 'Ponte Vedra',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 30.1204,
    longitude: -81.4128,
    postal_code: '32081'
  },
  '32082': {
    country: 'US',
    city: 'Ponte Vedra Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 30.1223,
    longitude: -81.3627,
    postal_code: '32082'
  },
  '32083': {
    country: 'US',
    city: 'Raiford',
    state: 'Florida',
    state_short: 'FL',
    county: 'Union',
    latitude: 30.0704,
    longitude: -82.2001,
    postal_code: '32083'
  },
  '32084': {
    country: 'US',
    city: 'Saint Augustine',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 29.9175,
    longitude: -81.3668,
    postal_code: '32084'
  },
  '32085': {
    country: 'US',
    city: 'Saint Augustine',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 29.9377,
    longitude: -81.4206,
    postal_code: '32085'
  },
  '32086': {
    country: 'US',
    city: 'Saint Augustine',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 29.8285,
    longitude: -81.3237,
    postal_code: '32086'
  },
  '32087': {
    country: 'US',
    city: 'Sanderson',
    state: 'Florida',
    state_short: 'FL',
    county: 'Baker',
    latitude: 30.2522,
    longitude: -82.2729,
    postal_code: '32087'
  },
  '32091': {
    country: 'US',
    city: 'Starke',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bradford',
    latitude: 29.9583,
    longitude: -82.1185,
    postal_code: '32091'
  },
  '32092': {
    country: 'US',
    city: 'Saint Augustine',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 29.9475,
    longitude: -81.5264,
    postal_code: '32092'
  },
  '32094': {
    country: 'US',
    city: 'Wellborn',
    state: 'Florida',
    state_short: 'FL',
    county: 'Suwannee',
    latitude: 30.1796,
    longitude: -82.8505,
    postal_code: '32094'
  },
  '32095': {
    country: 'US',
    city: 'Saint Augustine',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 30.011,
    longitude: -81.4108,
    postal_code: '32095'
  },
  '32096': {
    country: 'US',
    city: 'White Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hamilton',
    latitude: 30.3387,
    longitude: -82.7765,
    postal_code: '32096'
  },
  '32097': {
    country: 'US',
    city: 'Yulee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Nassau',
    latitude: 30.6222,
    longitude: -81.5906,
    postal_code: '32097'
  },
  '32099': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3375,
    longitude: -81.7686,
    postal_code: '32099'
  },
  '32102': {
    country: 'US',
    city: 'Astor',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 29.165,
    longitude: -81.5399,
    postal_code: '32102'
  },
  '32105': {
    country: 'US',
    city: 'Barberville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2005,
    longitude: -81.4065,
    postal_code: '32105'
  },
  '32110': {
    country: 'US',
    city: 'Bunnell',
    state: 'Florida',
    state_short: 'FL',
    county: 'Flagler',
    latitude: 29.4562,
    longitude: -81.3244,
    postal_code: '32110'
  },
  '32111': {
    country: 'US',
    city: 'Candler',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.0607,
    longitude: -81.969,
    postal_code: '32111'
  },
  '32112': {
    country: 'US',
    city: 'Crescent City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.4272,
    longitude: -81.5579,
    postal_code: '32112'
  },
  '32113': {
    country: 'US',
    city: 'Citra',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.3918,
    longitude: -82.1062,
    postal_code: '32113'
  },
  '32114': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2012,
    longitude: -81.0371,
    postal_code: '32114'
  },
  '32115': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2108,
    longitude: -81.0228,
    postal_code: '32115'
  },
  '32116': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.1091,
    longitude: -80.9843,
    postal_code: '32116'
  },
  '32117': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2353,
    longitude: -81.0658,
    postal_code: '32117'
  },
  '32118': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2219,
    longitude: -81.0095,
    postal_code: '32118'
  },
  '32119': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.16,
    longitude: -81.0269,
    postal_code: '32119'
  },
  '32120': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2108,
    longitude: -81.0228,
    postal_code: '32120'
  },
  '32121': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2108,
    longitude: -81.0228,
    postal_code: '32121'
  },
  '32122': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2108,
    longitude: -81.0228,
    postal_code: '32122'
  },
  '32123': {
    country: 'US',
    city: 'Port Orange',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.1383,
    longitude: -80.9956,
    postal_code: '32123'
  },
  '32124': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.1419,
    longitude: -81.1402,
    postal_code: '32124'
  },
  '32125': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2108,
    longitude: -81.0228,
    postal_code: '32125'
  },
  '32126': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2108,
    longitude: -81.0228,
    postal_code: '32126'
  },
  '32127': {
    country: 'US',
    city: 'Port Orange',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.1383,
    longitude: -80.9956,
    postal_code: '32127'
  },
  '32128': {
    country: 'US',
    city: 'Port Orange',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.1383,
    longitude: -80.9956,
    postal_code: '32128'
  },
  '32129': {
    country: 'US',
    city: 'Port Orange',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.1372,
    longitude: -81.0241,
    postal_code: '32129'
  },
  '32130': {
    country: 'US',
    city: 'De Leon Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.1166,
    longitude: -81.3488,
    postal_code: '32130'
  },
  '32131': {
    country: 'US',
    city: 'East Palatka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.6609,
    longitude: -81.5879,
    postal_code: '32131'
  },
  '32132': {
    country: 'US',
    city: 'Edgewater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.9818,
    longitude: -80.9103,
    postal_code: '32132'
  },
  '32133': {
    country: 'US',
    city: 'Eastlake Weir',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.0088,
    longitude: -81.9094,
    postal_code: '32133'
  },
  '32134': {
    country: 'US',
    city: 'Fort Mc Coy',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.391,
    longitude: -81.8551,
    postal_code: '32134'
  },
  '32135': {
    country: 'US',
    city: 'Palm Coast',
    state: 'Florida',
    state_short: 'FL',
    county: 'Flagler',
    latitude: 29.4661,
    longitude: -81.2828,
    postal_code: '32135'
  },
  '32136': {
    country: 'US',
    city: 'Flagler Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Flagler',
    latitude: 29.475,
    longitude: -81.1303,
    postal_code: '32136'
  },
  '32137': {
    country: 'US',
    city: 'Palm Coast',
    state: 'Florida',
    state_short: 'FL',
    county: 'Flagler',
    latitude: 29.5565,
    longitude: -81.219,
    postal_code: '32137'
  },
  '32138': {
    country: 'US',
    city: 'Grandin',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.7277,
    longitude: -81.9184,
    postal_code: '32138'
  },
  '32139': {
    country: 'US',
    city: 'Georgetown',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.3842,
    longitude: -81.6183,
    postal_code: '32139'
  },
  '32140': {
    country: 'US',
    city: 'Florahome',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.7581,
    longitude: -81.8622,
    postal_code: '32140'
  },
  '32141': {
    country: 'US',
    city: 'Edgewater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.9455,
    longitude: -80.8969,
    postal_code: '32141'
  },
  '32142': {
    country: 'US',
    city: 'Palm Coast',
    state: 'Florida',
    state_short: 'FL',
    county: 'Flagler',
    latitude: 29.4661,
    longitude: -81.2828,
    postal_code: '32142'
  },
  '32143': {
    country: 'US',
    city: 'Palm Coast',
    state: 'Florida',
    state_short: 'FL',
    county: 'Flagler',
    latitude: 29.585,
    longitude: -81.2078,
    postal_code: '32143'
  },
  '32145': {
    country: 'US',
    city: 'Hastings',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 29.7051,
    longitude: -81.4909,
    postal_code: '32145'
  },
  '32147': {
    country: 'US',
    city: 'Hollister',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.6227,
    longitude: -81.8137,
    postal_code: '32147'
  },
  '32148': {
    country: 'US',
    city: 'Interlachen',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.627,
    longitude: -81.8894,
    postal_code: '32148'
  },
  '32149': {
    country: 'US',
    city: 'Interlachen',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.6242,
    longitude: -81.8926,
    postal_code: '32149'
  },
  '32157': {
    country: 'US',
    city: 'Lake Como',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.4839,
    longitude: -81.5729,
    postal_code: '32157'
  },
  '32158': {
    country: 'US',
    city: 'Lady Lake',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.9175,
    longitude: -81.9229,
    postal_code: '32158'
  },
  '32159': {
    country: 'US',
    city: 'Lady Lake',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.9299,
    longitude: -81.9256,
    postal_code: '32159'
  },
  '32160': {
    country: 'US',
    city: 'Lake Geneva',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 29.7683,
    longitude: -81.9907,
    postal_code: '32160'
  },
  '32162': {
    country: 'US',
    city: 'The Villages',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sumter',
    latitude: 28.9341,
    longitude: -81.9599,
    postal_code: '32162'
  },
  '32163': {
    country: 'US',
    city: 'The Villages',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sumter',
    latitude: 28.9338,
    longitude: -81.9914,
    postal_code: '32163'
  },
  '32164': {
    country: 'US',
    city: 'Palm Coast',
    state: 'Florida',
    state_short: 'FL',
    county: 'Flagler',
    latitude: 29.4861,
    longitude: -81.2045,
    postal_code: '32164'
  },
  '32168': {
    country: 'US',
    city: 'New Smyrna Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.0247,
    longitude: -80.9584,
    postal_code: '32168'
  },
  '32169': {
    country: 'US',
    city: 'New Smyrna Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.0172,
    longitude: -80.8885,
    postal_code: '32169'
  },
  '32170': {
    country: 'US',
    city: 'New Smyrna Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.0258,
    longitude: -80.927,
    postal_code: '32170'
  },
  '32173': {
    country: 'US',
    city: 'Ormond Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2858,
    longitude: -81.0559,
    postal_code: '32173'
  },
  '32174': {
    country: 'US',
    city: 'Ormond Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2833,
    longitude: -81.0882,
    postal_code: '32174'
  },
  '32175': {
    country: 'US',
    city: 'Ormond Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2858,
    longitude: -81.0559,
    postal_code: '32175'
  },
  '32176': {
    country: 'US',
    city: 'Ormond Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.3222,
    longitude: -81.0584,
    postal_code: '32176'
  },
  '32177': {
    country: 'US',
    city: 'Palatka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.6577,
    longitude: -81.6595,
    postal_code: '32177'
  },
  '32178': {
    country: 'US',
    city: 'Palatka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.6486,
    longitude: -81.6376,
    postal_code: '32178'
  },
  '32179': {
    country: 'US',
    city: 'Ocklawaha',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.0643,
    longitude: -81.8857,
    postal_code: '32179'
  },
  '32180': {
    country: 'US',
    city: 'Pierson',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2226,
    longitude: -81.4353,
    postal_code: '32180'
  },
  '32181': {
    country: 'US',
    city: 'Pomona Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.5002,
    longitude: -81.5915,
    postal_code: '32181'
  },
  '32182': {
    country: 'US',
    city: 'Orange Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.4856,
    longitude: -81.9589,
    postal_code: '32182'
  },
  '32183': {
    country: 'US',
    city: 'Ocklawaha',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.0597,
    longitude: -81.9051,
    postal_code: '32183'
  },
  '32185': {
    country: 'US',
    city: 'Putnam Hall',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.7368,
    longitude: -81.958,
    postal_code: '32185'
  },
  '32187': {
    country: 'US',
    city: 'San Mateo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.5888,
    longitude: -81.5921,
    postal_code: '32187'
  },
  '32189': {
    country: 'US',
    city: 'Satsuma',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.5594,
    longitude: -81.6406,
    postal_code: '32189'
  },
  '32190': {
    country: 'US',
    city: 'Seville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.3201,
    longitude: -81.5279,
    postal_code: '32190'
  },
  '32192': {
    country: 'US',
    city: 'Sparr',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.3268,
    longitude: -82.1046,
    postal_code: '32192'
  },
  '32193': {
    country: 'US',
    city: 'Welaka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.4905,
    longitude: -81.653,
    postal_code: '32193'
  },
  '32195': {
    country: 'US',
    city: 'Weirsdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 28.9782,
    longitude: -81.8932,
    postal_code: '32195'
  },
  '32198': {
    country: 'US',
    city: 'Daytona Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.2108,
    longitude: -81.0228,
    postal_code: '32198'
  },
  '32201': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3894,
    longitude: -81.6808,
    postal_code: '32201'
  },
  '32202': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3299,
    longitude: -81.6517,
    postal_code: '32202'
  },
  '32203': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3228,
    longitude: -81.547,
    postal_code: '32203'
  },
  '32204': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3189,
    longitude: -81.6854,
    postal_code: '32204'
  },
  '32205': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3172,
    longitude: -81.722,
    postal_code: '32205'
  },
  '32206': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3511,
    longitude: -81.6488,
    postal_code: '32206'
  },
  '32207': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2908,
    longitude: -81.6321,
    postal_code: '32207'
  },
  '32208': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3937,
    longitude: -81.6889,
    postal_code: '32208'
  },
  '32209': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3584,
    longitude: -81.692,
    postal_code: '32209'
  },
  '32210': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2687,
    longitude: -81.7473,
    postal_code: '32210'
  },
  '32211': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.348,
    longitude: -81.5882,
    postal_code: '32211'
  },
  '32212': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2132,
    longitude: -81.69,
    postal_code: '32212'
  },
  '32214': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32214'
  },
  '32216': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2787,
    longitude: -81.5831,
    postal_code: '32216'
  },
  '32217': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2407,
    longitude: -81.617,
    postal_code: '32217'
  },
  '32218': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.4507,
    longitude: -81.6626,
    postal_code: '32218'
  },
  '32219': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.4034,
    longitude: -81.7635,
    postal_code: '32219'
  },
  '32220': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.329,
    longitude: -81.8176,
    postal_code: '32220'
  },
  '32221': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2837,
    longitude: -81.8202,
    postal_code: '32221'
  },
  '32222': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2292,
    longitude: -81.8131,
    postal_code: '32222'
  },
  '32223': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.1548,
    longitude: -81.63,
    postal_code: '32223'
  },
  '32224': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3031,
    longitude: -81.4404,
    postal_code: '32224'
  },
  '32225': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.351,
    longitude: -81.5061,
    postal_code: '32225'
  },
  '32226': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.4735,
    longitude: -81.5448,
    postal_code: '32226'
  },
  '32227': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3802,
    longitude: -81.416,
    postal_code: '32227'
  },
  '32228': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3824,
    longitude: -81.4369,
    postal_code: '32228'
  },
  '32229': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32229'
  },
  '32231': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32231'
  },
  '32232': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32232'
  },
  '32233': {
    country: 'US',
    city: 'Atlantic Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3483,
    longitude: -81.4159,
    postal_code: '32233'
  },
  '32234': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2757,
    longitude: -81.9686,
    postal_code: '32234'
  },
  '32235': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32235'
  },
  '32236': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32236'
  },
  '32237': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32237'
  },
  '32238': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32238'
  },
  '32239': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32239'
  },
  '32240': {
    country: 'US',
    city: 'Jacksonville Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2947,
    longitude: -81.3931,
    postal_code: '32240'
  },
  '32241': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32241'
  },
  '32244': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2231,
    longitude: -81.7556,
    postal_code: '32244'
  },
  '32245': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32245'
  },
  '32246': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2933,
    longitude: -81.5092,
    postal_code: '32246'
  },
  '32247': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3449,
    longitude: -81.6831,
    postal_code: '32247'
  },
  '32250': {
    country: 'US',
    city: 'Jacksonville Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2801,
    longitude: -81.4165,
    postal_code: '32250'
  },
  '32254': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3415,
    longitude: -81.7358,
    postal_code: '32254'
  },
  '32255': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3322,
    longitude: -81.6557,
    postal_code: '32255'
  },
  '32256': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.2214,
    longitude: -81.5571,
    postal_code: '32256'
  },
  '32257': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.1927,
    longitude: -81.605,
    postal_code: '32257'
  },
  '32258': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.1459,
    longitude: -81.5739,
    postal_code: '32258'
  },
  '32259': {
    country: 'US',
    city: 'Saint Johns',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 30.0815,
    longitude: -81.5477,
    postal_code: '32259'
  },
  '32260': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Saint Johns',
    latitude: 29.9377,
    longitude: -81.4206,
    postal_code: '32260'
  },
  '32266': {
    country: 'US',
    city: 'Neptune Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3155,
    longitude: -81.4051,
    postal_code: '32266'
  },
  '32277': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Duval',
    latitude: 30.3704,
    longitude: -81.5864,
    postal_code: '32277'
  },
  '32301': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4286,
    longitude: -84.2593,
    postal_code: '32301'
  },
  '32302': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4383,
    longitude: -84.2807,
    postal_code: '32302'
  },
  '32303': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4874,
    longitude: -84.3189,
    postal_code: '32303'
  },
  '32304': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4478,
    longitude: -84.3211,
    postal_code: '32304'
  },
  '32305': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.334,
    longitude: -84.287,
    postal_code: '32305'
  },
  '32306': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4425,
    longitude: -84.2986,
    postal_code: '32306'
  },
  '32307': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4257,
    longitude: -84.2877,
    postal_code: '32307'
  },
  '32308': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4771,
    longitude: -84.2246,
    postal_code: '32308'
  },
  '32309': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.5422,
    longitude: -84.1413,
    postal_code: '32309'
  },
  '32310': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.3991,
    longitude: -84.3298,
    postal_code: '32310'
  },
  '32311': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4156,
    longitude: -84.187,
    postal_code: '32311'
  },
  '32312': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.5185,
    longitude: -84.2627,
    postal_code: '32312'
  },
  '32313': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4793,
    longitude: -84.3462,
    postal_code: '32313'
  },
  '32314': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4793,
    longitude: -84.3462,
    postal_code: '32314'
  },
  '32315': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4793,
    longitude: -84.3462,
    postal_code: '32315'
  },
  '32316': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4793,
    longitude: -84.3462,
    postal_code: '32316'
  },
  '32317': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4651,
    longitude: -84.1128,
    postal_code: '32317'
  },
  '32318': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Franklin',
    latitude: 30.4383,
    longitude: -84.2807,
    postal_code: '32318'
  },
  '32320': {
    country: 'US',
    city: 'Apalachicola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Franklin',
    latitude: 29.7255,
    longitude: -85.0063,
    postal_code: '32320'
  },
  '32321': {
    country: 'US',
    city: 'Bristol',
    state: 'Florida',
    state_short: 'FL',
    county: 'Liberty',
    latitude: 30.4223,
    longitude: -84.9466,
    postal_code: '32321'
  },
  '32322': {
    country: 'US',
    city: 'Carrabelle',
    state: 'Florida',
    state_short: 'FL',
    county: 'Franklin',
    latitude: 29.8692,
    longitude: -84.6358,
    postal_code: '32322'
  },
  '32323': {
    country: 'US',
    city: 'Lanark Village',
    state: 'Florida',
    state_short: 'FL',
    county: 'Franklin',
    latitude: 29.8826,
    longitude: -84.5964,
    postal_code: '32323'
  },
  '32324': {
    country: 'US',
    city: 'Chattahoochee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gadsden',
    latitude: 30.6834,
    longitude: -84.828,
    postal_code: '32324'
  },
  '32326': {
    country: 'US',
    city: 'Crawfordville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Wakulla',
    latitude: 30.176,
    longitude: -84.3752,
    postal_code: '32326'
  },
  '32327': {
    country: 'US',
    city: 'Crawfordville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Wakulla',
    latitude: 30.2108,
    longitude: -84.3205,
    postal_code: '32327'
  },
  '32328': {
    country: 'US',
    city: 'Eastpoint',
    state: 'Florida',
    state_short: 'FL',
    county: 'Franklin',
    latitude: 29.7495,
    longitude: -84.8162,
    postal_code: '32328'
  },
  '32329': {
    country: 'US',
    city: 'Apalachicola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Franklin',
    latitude: 29.726,
    longitude: -84.9856,
    postal_code: '32329'
  },
  '32330': {
    country: 'US',
    city: 'Greensboro',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gadsden',
    latitude: 30.5696,
    longitude: -84.7453,
    postal_code: '32330'
  },
  '32331': {
    country: 'US',
    city: 'Greenville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Madison',
    latitude: 30.4512,
    longitude: -83.6474,
    postal_code: '32331'
  },
  '32332': {
    country: 'US',
    city: 'Gretna',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gadsden',
    latitude: 30.6171,
    longitude: -84.6599,
    postal_code: '32332'
  },
  '32333': {
    country: 'US',
    city: 'Havana',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gadsden',
    latitude: 30.6092,
    longitude: -84.4143,
    postal_code: '32333'
  },
  '32334': {
    country: 'US',
    city: 'Hosford',
    state: 'Florida',
    state_short: 'FL',
    county: 'Liberty',
    latitude: 30.3639,
    longitude: -84.8054,
    postal_code: '32334'
  },
  '32335': {
    country: 'US',
    city: 'Sumatra',
    state: 'Florida',
    state_short: 'FL',
    county: 'Liberty',
    latitude: 30.0206,
    longitude: -84.9806,
    postal_code: '32335'
  },
  '32336': {
    country: 'US',
    city: 'Lamont',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jefferson',
    latitude: 30.3772,
    longitude: -83.8129,
    postal_code: '32336'
  },
  '32337': {
    country: 'US',
    city: 'Lloyd',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jefferson',
    latitude: 30.4778,
    longitude: -84.0228,
    postal_code: '32337'
  },
  '32340': {
    country: 'US',
    city: 'Madison',
    state: 'Florida',
    state_short: 'FL',
    county: 'Madison',
    latitude: 30.4802,
    longitude: -83.4067,
    postal_code: '32340'
  },
  '32341': {
    country: 'US',
    city: 'Madison',
    state: 'Florida',
    state_short: 'FL',
    county: 'Madison',
    latitude: 30.4776,
    longitude: -83.3914,
    postal_code: '32341'
  },
  '32343': {
    country: 'US',
    city: 'Midway',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gadsden',
    latitude: 30.485,
    longitude: -84.4768,
    postal_code: '32343'
  },
  '32344': {
    country: 'US',
    city: 'Monticello',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jefferson',
    latitude: 30.5197,
    longitude: -83.8925,
    postal_code: '32344'
  },
  '32345': {
    country: 'US',
    city: 'Monticello',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jefferson',
    latitude: 30.5451,
    longitude: -83.8713,
    postal_code: '32345'
  },
  '32346': {
    country: 'US',
    city: 'Panacea',
    state: 'Florida',
    state_short: 'FL',
    county: 'Wakulla',
    latitude: 30.0153,
    longitude: -84.3912,
    postal_code: '32346'
  },
  '32347': {
    country: 'US',
    city: 'Perry',
    state: 'Florida',
    state_short: 'FL',
    county: 'Taylor',
    latitude: 30.1668,
    longitude: -83.616,
    postal_code: '32347'
  },
  '32348': {
    country: 'US',
    city: 'Perry',
    state: 'Florida',
    state_short: 'FL',
    county: 'Taylor',
    latitude: 29.9665,
    longitude: -83.6594,
    postal_code: '32348'
  },
  '32350': {
    country: 'US',
    city: 'Pinetta',
    state: 'Florida',
    state_short: 'FL',
    county: 'Madison',
    latitude: 30.5997,
    longitude: -83.3405,
    postal_code: '32350'
  },
  '32351': {
    country: 'US',
    city: 'Quincy',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gadsden',
    latitude: 30.5867,
    longitude: -84.6094,
    postal_code: '32351'
  },
  '32352': {
    country: 'US',
    city: 'Quincy',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gadsden',
    latitude: 30.6512,
    longitude: -84.5866,
    postal_code: '32352'
  },
  '32353': {
    country: 'US',
    city: 'Quincy',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gadsden',
    latitude: 30.5497,
    longitude: -84.6069,
    postal_code: '32353'
  },
  '32355': {
    country: 'US',
    city: 'Saint Marks',
    state: 'Florida',
    state_short: 'FL',
    county: 'Wakulla',
    latitude: 30.1631,
    longitude: -84.2083,
    postal_code: '32355'
  },
  '32356': {
    country: 'US',
    city: 'Salem',
    state: 'Florida',
    state_short: 'FL',
    county: 'Taylor',
    latitude: 29.8539,
    longitude: -83.4421,
    postal_code: '32356'
  },
  '32357': {
    country: 'US',
    city: 'Shady Grove',
    state: 'Florida',
    state_short: 'FL',
    county: 'Taylor',
    latitude: 30.288,
    longitude: -83.6318,
    postal_code: '32357'
  },
  '32358': {
    country: 'US',
    city: 'Sopchoppy',
    state: 'Florida',
    state_short: 'FL',
    county: 'Wakulla',
    latitude: 30.0714,
    longitude: -84.4549,
    postal_code: '32358'
  },
  '32359': {
    country: 'US',
    city: 'Steinhatchee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Taylor',
    latitude: 29.6739,
    longitude: -83.3723,
    postal_code: '32359'
  },
  '32360': {
    country: 'US',
    city: 'Telogia',
    state: 'Florida',
    state_short: 'FL',
    county: 'Liberty',
    latitude: 30.3511,
    longitude: -84.8203,
    postal_code: '32360'
  },
  '32361': {
    country: 'US',
    city: 'Wacissa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jefferson',
    latitude: 30.3585,
    longitude: -83.9871,
    postal_code: '32361'
  },
  '32362': {
    country: 'US',
    city: 'Woodville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.3193,
    longitude: -84.2674,
    postal_code: '32362'
  },
  '32395': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4793,
    longitude: -84.3462,
    postal_code: '32395'
  },
  '32399': {
    country: 'US',
    city: 'Tallahassee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Leon',
    latitude: 30.4383,
    longitude: -84.2807,
    postal_code: '32399'
  },
  '32401': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.1606,
    longitude: -85.6494,
    postal_code: '32401'
  },
  '32402': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.2345,
    longitude: -85.692,
    postal_code: '32402'
  },
  '32403': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.0583,
    longitude: -85.5762,
    postal_code: '32403'
  },
  '32404': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.1653,
    longitude: -85.5763,
    postal_code: '32404'
  },
  '32405': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.1949,
    longitude: -85.6727,
    postal_code: '32405'
  },
  '32406': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.2345,
    longitude: -85.692,
    postal_code: '32406'
  },
  '32407': {
    country: 'US',
    city: 'Panama City Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.2007,
    longitude: -85.8136,
    postal_code: '32407'
  },
  '32408': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.1655,
    longitude: -85.7116,
    postal_code: '32408'
  },
  '32409': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.3117,
    longitude: -85.6923,
    postal_code: '32409'
  },
  '32410': {
    country: 'US',
    city: 'Mexico Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 29.9395,
    longitude: -85.4096,
    postal_code: '32410'
  },
  '32411': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.2345,
    longitude: -85.692,
    postal_code: '32411'
  },
  '32412': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.2345,
    longitude: -85.692,
    postal_code: '32412'
  },
  '32413': {
    country: 'US',
    city: 'Panama City Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.3105,
    longitude: -85.9106,
    postal_code: '32413'
  },
  '32417': {
    country: 'US',
    city: 'Panama City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.1595,
    longitude: -85.6598,
    postal_code: '32417'
  },
  '32420': {
    country: 'US',
    city: 'Alford',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.6412,
    longitude: -85.3756,
    postal_code: '32420'
  },
  '32421': {
    country: 'US',
    city: 'Altha',
    state: 'Florida',
    state_short: 'FL',
    county: 'Calhoun',
    latitude: 30.5319,
    longitude: -85.1704,
    postal_code: '32421'
  },
  '32422': {
    country: 'US',
    city: 'Argyle',
    state: 'Florida',
    state_short: 'FL',
    county: 'Walton',
    latitude: 30.7056,
    longitude: -86.0314,
    postal_code: '32422'
  },
  '32423': {
    country: 'US',
    city: 'Bascom',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.9514,
    longitude: -85.0972,
    postal_code: '32423'
  },
  '32424': {
    country: 'US',
    city: 'Blountstown',
    state: 'Florida',
    state_short: 'FL',
    county: 'Calhoun',
    latitude: 30.4394,
    longitude: -85.062,
    postal_code: '32424'
  },
  '32425': {
    country: 'US',
    city: 'Bonifay',
    state: 'Florida',
    state_short: 'FL',
    county: 'Holmes',
    latitude: 30.8464,
    longitude: -85.69,
    postal_code: '32425'
  },
  '32426': {
    country: 'US',
    city: 'Campbellton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.9563,
    longitude: -85.3766,
    postal_code: '32426'
  },
  '32427': {
    country: 'US',
    city: 'Caryville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Washington',
    latitude: 30.7123,
    longitude: -85.8014,
    postal_code: '32427'
  },
  '32428': {
    country: 'US',
    city: 'Chipley',
    state: 'Florida',
    state_short: 'FL',
    county: 'Washington',
    latitude: 30.7107,
    longitude: -85.5486,
    postal_code: '32428'
  },
  '32430': {
    country: 'US',
    city: 'Clarksville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Calhoun',
    latitude: 30.3568,
    longitude: -85.1898,
    postal_code: '32430'
  },
  '32431': {
    country: 'US',
    city: 'Cottondale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.8004,
    longitude: -85.3847,
    postal_code: '32431'
  },
  '32432': {
    country: 'US',
    city: 'Cypress',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.7158,
    longitude: -85.0784,
    postal_code: '32432'
  },
  '32433': {
    country: 'US',
    city: 'Defuniak Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Walton',
    latitude: 30.8494,
    longitude: -86.2023,
    postal_code: '32433'
  },
  '32434': {
    country: 'US',
    city: 'Mossy Head',
    state: 'Florida',
    state_short: 'FL',
    county: 'Walton',
    latitude: 30.7432,
    longitude: -86.3149,
    postal_code: '32434'
  },
  '32435': {
    country: 'US',
    city: 'Defuniak Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Walton',
    latitude: 30.721,
    longitude: -86.1152,
    postal_code: '32435'
  },
  '32437': {
    country: 'US',
    city: 'Ebro',
    state: 'Florida',
    state_short: 'FL',
    county: 'Washington',
    latitude: 30.4352,
    longitude: -85.8881,
    postal_code: '32437'
  },
  '32438': {
    country: 'US',
    city: 'Fountain',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.4753,
    longitude: -85.4293,
    postal_code: '32438'
  },
  '32439': {
    country: 'US',
    city: 'Freeport',
    state: 'Florida',
    state_short: 'FL',
    county: 'Walton',
    latitude: 30.4896,
    longitude: -86.1684,
    postal_code: '32439'
  },
  '32440': {
    country: 'US',
    city: 'Graceville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.9426,
    longitude: -85.5136,
    postal_code: '32440'
  },
  '32442': {
    country: 'US',
    city: 'Grand Ridge',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.7148,
    longitude: -85.021,
    postal_code: '32442'
  },
  '32443': {
    country: 'US',
    city: 'Greenwood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.8667,
    longitude: -85.1153,
    postal_code: '32443'
  },
  '32444': {
    country: 'US',
    city: 'Lynn Haven',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.2362,
    longitude: -85.6467,
    postal_code: '32444'
  },
  '32445': {
    country: 'US',
    city: 'Malone',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.9602,
    longitude: -85.1639,
    postal_code: '32445'
  },
  '32446': {
    country: 'US',
    city: 'Marianna',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.7996,
    longitude: -85.2293,
    postal_code: '32446'
  },
  '32447': {
    country: 'US',
    city: 'Marianna',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.7603,
    longitude: -85.2022,
    postal_code: '32447'
  },
  '32448': {
    country: 'US',
    city: 'Marianna',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.6749,
    longitude: -85.2122,
    postal_code: '32448'
  },
  '32449': {
    country: 'US',
    city: 'Wewahitchka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Calhoun',
    latitude: 30.4059,
    longitude: -85.1974,
    postal_code: '32449'
  },
  '32452': {
    country: 'US',
    city: 'Noma',
    state: 'Florida',
    state_short: 'FL',
    county: 'Holmes',
    latitude: 30.9821,
    longitude: -85.6185,
    postal_code: '32452'
  },
  '32455': {
    country: 'US',
    city: 'Ponce De Leon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Holmes',
    latitude: 30.7041,
    longitude: -85.9546,
    postal_code: '32455'
  },
  '32456': {
    country: 'US',
    city: 'Port Saint Joe',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gulf',
    latitude: 29.8119,
    longitude: -85.303,
    postal_code: '32456'
  },
  '32457': {
    country: 'US',
    city: 'Port Saint Joe',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gulf',
    latitude: 29.8119,
    longitude: -85.303,
    postal_code: '32457'
  },
  '32459': {
    country: 'US',
    city: 'Santa Rosa Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Walton',
    latitude: 30.3659,
    longitude: -86.2458,
    postal_code: '32459'
  },
  '32460': {
    country: 'US',
    city: 'Sneads',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jackson',
    latitude: 30.7276,
    longitude: -84.9337,
    postal_code: '32460'
  },
  '32461': {
    country: 'US',
    city: 'Rosemary Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Walton',
    latitude: 30.2835,
    longitude: -86.0305,
    postal_code: '32461'
  },
  '32462': {
    country: 'US',
    city: 'Vernon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Washington',
    latitude: 30.6267,
    longitude: -85.7553,
    postal_code: '32462'
  },
  '32463': {
    country: 'US',
    city: 'Wausau',
    state: 'Florida',
    state_short: 'FL',
    county: 'Washington',
    latitude: 30.6321,
    longitude: -85.5888,
    postal_code: '32463'
  },
  '32464': {
    country: 'US',
    city: 'Westville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Holmes',
    latitude: 30.8747,
    longitude: -85.913,
    postal_code: '32464'
  },
  '32465': {
    country: 'US',
    city: 'Wewahitchka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gulf',
    latitude: 30.0933,
    longitude: -85.2048,
    postal_code: '32465'
  },
  '32466': {
    country: 'US',
    city: 'Youngstown',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bay',
    latitude: 30.3269,
    longitude: -85.5169,
    postal_code: '32466'
  },
  '32501': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4223,
    longitude: -87.2248,
    postal_code: '32501'
  },
  '32502': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4095,
    longitude: -87.2229,
    postal_code: '32502'
  },
  '32503': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4564,
    longitude: -87.2104,
    postal_code: '32503'
  },
  '32504': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4873,
    longitude: -87.1872,
    postal_code: '32504'
  },
  '32505': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4481,
    longitude: -87.2589,
    postal_code: '32505'
  },
  '32506': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4129,
    longitude: -87.3092,
    postal_code: '32506'
  },
  '32507': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.3737,
    longitude: -87.3126,
    postal_code: '32507'
  },
  '32508': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.3511,
    longitude: -87.2749,
    postal_code: '32508'
  },
  '32509': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4643,
    longitude: -87.3403,
    postal_code: '32509'
  },
  '32511': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4061,
    longitude: -87.2917,
    postal_code: '32511'
  },
  '32512': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.3943,
    longitude: -87.2991,
    postal_code: '32512'
  },
  '32513': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.5571,
    longitude: -87.2596,
    postal_code: '32513'
  },
  '32514': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.5241,
    longitude: -87.2167,
    postal_code: '32514'
  },
  '32516': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4213,
    longitude: -87.2169,
    postal_code: '32516'
  },
  '32520': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4124,
    longitude: -87.2035,
    postal_code: '32520'
  },
  '32521': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4213,
    longitude: -87.2169,
    postal_code: '32521'
  },
  '32522': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4213,
    longitude: -87.2169,
    postal_code: '32522'
  },
  '32523': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4213,
    longitude: -87.2169,
    postal_code: '32523'
  },
  '32524': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4213,
    longitude: -87.2169,
    postal_code: '32524'
  },
  '32526': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4756,
    longitude: -87.3179,
    postal_code: '32526'
  },
  '32530': {
    country: 'US',
    city: 'Bagdad',
    state: 'Florida',
    state_short: 'FL',
    county: 'Santa Rosa',
    latitude: 30.5986,
    longitude: -87.0315,
    postal_code: '32530'
  },
  '32531': {
    country: 'US',
    city: 'Baker',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.8316,
    longitude: -86.677,
    postal_code: '32531'
  },
  '32533': {
    country: 'US',
    city: 'Cantonment',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.6143,
    longitude: -87.3251,
    postal_code: '32533'
  },
  '32534': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.5301,
    longitude: -87.2793,
    postal_code: '32534'
  },
  '32535': {
    country: 'US',
    city: 'Century',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.9687,
    longitude: -87.3216,
    postal_code: '32535'
  },
  '32536': {
    country: 'US',
    city: 'Crestview',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.7644,
    longitude: -86.5917,
    postal_code: '32536'
  },
  '32537': {
    country: 'US',
    city: 'Milligan',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.742,
    longitude: -86.6552,
    postal_code: '32537'
  },
  '32538': {
    country: 'US',
    city: 'Paxton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Walton',
    latitude: 30.9709,
    longitude: -86.3111,
    postal_code: '32538'
  },
  '32539': {
    country: 'US',
    city: 'Crestview',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.7773,
    longitude: -86.4832,
    postal_code: '32539'
  },
  '32540': {
    country: 'US',
    city: 'Destin',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.3935,
    longitude: -86.4958,
    postal_code: '32540'
  },
  '32541': {
    country: 'US',
    city: 'Destin',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.3949,
    longitude: -86.4692,
    postal_code: '32541'
  },
  '32542': {
    country: 'US',
    city: 'Eglin Afb',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.5393,
    longitude: -86.6087,
    postal_code: '32542'
  },
  '32544': {
    country: 'US',
    city: 'Hurlburt Field',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.4229,
    longitude: -86.6985,
    postal_code: '32544'
  },
  '32547': {
    country: 'US',
    city: 'Fort Walton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.4487,
    longitude: -86.6255,
    postal_code: '32547'
  },
  '32548': {
    country: 'US',
    city: 'Fort Walton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.4206,
    longitude: -86.6286,
    postal_code: '32548'
  },
  '32549': {
    country: 'US',
    city: 'Fort Walton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.4208,
    longitude: -86.6194,
    postal_code: '32549'
  },
  '32550': {
    country: 'US',
    city: 'Miramar Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Walton',
    latitude: 30.385,
    longitude: -86.3473,
    postal_code: '32550'
  },
  '32559': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.3563,
    longitude: -87.2773,
    postal_code: '32559'
  },
  '32560': {
    country: 'US',
    city: 'Gonzalez',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.5818,
    longitude: -87.2929,
    postal_code: '32560'
  },
  '32561': {
    country: 'US',
    city: 'Gulf Breeze',
    state: 'Florida',
    state_short: 'FL',
    county: 'Santa Rosa',
    latitude: 30.3571,
    longitude: -87.1639,
    postal_code: '32561'
  },
  '32562': {
    country: 'US',
    city: 'Gulf Breeze',
    state: 'Florida',
    state_short: 'FL',
    county: 'Santa Rosa',
    latitude: 30.3571,
    longitude: -87.1639,
    postal_code: '32562'
  },
  '32563': {
    country: 'US',
    city: 'Gulf Breeze',
    state: 'Florida',
    state_short: 'FL',
    county: 'Santa Rosa',
    latitude: 30.3962,
    longitude: -87.0274,
    postal_code: '32563'
  },
  '32564': {
    country: 'US',
    city: 'Holt',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.7416,
    longitude: -86.7198,
    postal_code: '32564'
  },
  '32565': {
    country: 'US',
    city: 'Jay',
    state: 'Florida',
    state_short: 'FL',
    county: 'Santa Rosa',
    latitude: 30.8985,
    longitude: -87.1332,
    postal_code: '32565'
  },
  '32566': {
    country: 'US',
    city: 'Navarre',
    state: 'Florida',
    state_short: 'FL',
    county: 'Santa Rosa',
    latitude: 30.4212,
    longitude: -86.8926,
    postal_code: '32566'
  },
  '32567': {
    country: 'US',
    city: 'Laurel Hill',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.9524,
    longitude: -86.4003,
    postal_code: '32567'
  },
  '32568': {
    country: 'US',
    city: 'Mc David',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.8686,
    longitude: -87.4539,
    postal_code: '32568'
  },
  '32569': {
    country: 'US',
    city: 'Mary Esther',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.4085,
    longitude: -86.7352,
    postal_code: '32569'
  },
  '32570': {
    country: 'US',
    city: 'Milton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Santa Rosa',
    latitude: 30.6604,
    longitude: -87.0473,
    postal_code: '32570'
  },
  '32571': {
    country: 'US',
    city: 'Milton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Santa Rosa',
    latitude: 30.6698,
    longitude: -87.1794,
    postal_code: '32571'
  },
  '32572': {
    country: 'US',
    city: 'Milton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Santa Rosa',
    latitude: 30.6592,
    longitude: -87.0497,
    postal_code: '32572'
  },
  '32577': {
    country: 'US',
    city: 'Molino',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.6902,
    longitude: -87.3852,
    postal_code: '32577'
  },
  '32578': {
    country: 'US',
    city: 'Niceville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.5169,
    longitude: -86.4822,
    postal_code: '32578'
  },
  '32579': {
    country: 'US',
    city: 'Shalimar',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.4456,
    longitude: -86.5717,
    postal_code: '32579'
  },
  '32580': {
    country: 'US',
    city: 'Valparaiso',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.5092,
    longitude: -86.5009,
    postal_code: '32580'
  },
  '32583': {
    country: 'US',
    city: 'Milton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Santa Rosa',
    latitude: 30.5761,
    longitude: -87.0663,
    postal_code: '32583'
  },
  '32588': {
    country: 'US',
    city: 'Niceville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okaloosa',
    latitude: 30.6612,
    longitude: -86.5945,
    postal_code: '32588'
  },
  '32591': {
    country: 'US',
    city: 'Pensacola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Escambia',
    latitude: 30.4213,
    longitude: -87.2169,
    postal_code: '32591'
  },
  '32601': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6489,
    longitude: -82.325,
    postal_code: '32601'
  },
  '32602': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6299,
    longitude: -82.3966,
    postal_code: '32602'
  },
  '32603': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6515,
    longitude: -82.3493,
    postal_code: '32603'
  },
  '32604': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.5733,
    longitude: -82.3979,
    postal_code: '32604'
  },
  '32605': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6785,
    longitude: -82.3679,
    postal_code: '32605'
  },
  '32606': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6954,
    longitude: -82.4023,
    postal_code: '32606'
  },
  '32607': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6456,
    longitude: -82.4033,
    postal_code: '32607'
  },
  '32608': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6132,
    longitude: -82.3873,
    postal_code: '32608'
  },
  '32609': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.7005,
    longitude: -82.308,
    postal_code: '32609'
  },
  '32610': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6813,
    longitude: -82.3539,
    postal_code: '32610'
  },
  '32611': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6813,
    longitude: -82.3539,
    postal_code: '32611'
  },
  '32612': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6813,
    longitude: -82.3539,
    postal_code: '32612'
  },
  '32614': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6813,
    longitude: -82.3539,
    postal_code: '32614'
  },
  '32615': {
    country: 'US',
    city: 'Alachua',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.8135,
    longitude: -82.472,
    postal_code: '32615'
  },
  '32616': {
    country: 'US',
    city: 'Alachua',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.792,
    longitude: -82.496,
    postal_code: '32616'
  },
  '32617': {
    country: 'US',
    city: 'Anthony',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.3048,
    longitude: -82.1262,
    postal_code: '32617'
  },
  '32618': {
    country: 'US',
    city: 'Archer',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.5597,
    longitude: -82.5108,
    postal_code: '32618'
  },
  '32619': {
    country: 'US',
    city: 'Bell',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gilchrist',
    latitude: 29.7837,
    longitude: -82.8711,
    postal_code: '32619'
  },
  '32621': {
    country: 'US',
    city: 'Bronson',
    state: 'Florida',
    state_short: 'FL',
    county: 'Levy',
    latitude: 29.461,
    longitude: -82.6356,
    postal_code: '32621'
  },
  '32622': {
    country: 'US',
    city: 'Brooker',
    state: 'Florida',
    state_short: 'FL',
    county: 'Bradford',
    latitude: 29.919,
    longitude: -82.2956,
    postal_code: '32622'
  },
  '32625': {
    country: 'US',
    city: 'Cedar Key',
    state: 'Florida',
    state_short: 'FL',
    county: 'Levy',
    latitude: 29.171,
    longitude: -83.0168,
    postal_code: '32625'
  },
  '32626': {
    country: 'US',
    city: 'Chiefland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Levy',
    latitude: 29.4832,
    longitude: -82.8809,
    postal_code: '32626'
  },
  '32627': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6516,
    longitude: -82.3248,
    postal_code: '32627'
  },
  '32628': {
    country: 'US',
    city: 'Cross City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Dixie',
    latitude: 29.6372,
    longitude: -83.2032,
    postal_code: '32628'
  },
  '32631': {
    country: 'US',
    city: 'Earleton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.7439,
    longitude: -82.1034,
    postal_code: '32631'
  },
  '32633': {
    country: 'US',
    city: 'Evinston',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.4869,
    longitude: -82.2312,
    postal_code: '32633'
  },
  '32634': {
    country: 'US',
    city: 'Fairfield',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.3509,
    longitude: -82.2765,
    postal_code: '32634'
  },
  '32635': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6813,
    longitude: -82.3539,
    postal_code: '32635'
  },
  '32639': {
    country: 'US',
    city: 'Gulf Hammock',
    state: 'Florida',
    state_short: 'FL',
    county: 'Levy',
    latitude: 29.2447,
    longitude: -82.7402,
    postal_code: '32639'
  },
  '32640': {
    country: 'US',
    city: 'Hawthorne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.574,
    longitude: -82.1056,
    postal_code: '32640'
  },
  '32641': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6824,
    longitude: -82.2014,
    postal_code: '32641'
  },
  '32643': {
    country: 'US',
    city: 'High Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.841,
    longitude: -82.6156,
    postal_code: '32643'
  },
  '32644': {
    country: 'US',
    city: 'Chiefland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Levy',
    latitude: 29.4602,
    longitude: -82.8553,
    postal_code: '32644'
  },
  '32648': {
    country: 'US',
    city: 'Horseshoe Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Dixie',
    latitude: 29.4869,
    longitude: -83.2616,
    postal_code: '32648'
  },
  '32653': {
    country: 'US',
    city: 'Gainesville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.7728,
    longitude: -82.3782,
    postal_code: '32653'
  },
  '32654': {
    country: 'US',
    city: 'Island Grove',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.4536,
    longitude: -82.1065,
    postal_code: '32654'
  },
  '32655': {
    country: 'US',
    city: 'High Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.8175,
    longitude: -82.6006,
    postal_code: '32655'
  },
  '32656': {
    country: 'US',
    city: 'Keystone Heights',
    state: 'Florida',
    state_short: 'FL',
    county: 'Clay',
    latitude: 29.7976,
    longitude: -81.9899,
    postal_code: '32656'
  },
  '32658': {
    country: 'US',
    city: 'La Crosse',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.8433,
    longitude: -82.4048,
    postal_code: '32658'
  },
  '32662': {
    country: 'US',
    city: 'Lochloosa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.5116,
    longitude: -82.1004,
    postal_code: '32662'
  },
  '32663': {
    country: 'US',
    city: 'Lowell',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.3424,
    longitude: -82.2126,
    postal_code: '32663'
  },
  '32664': {
    country: 'US',
    city: 'Mc Intosh',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.438,
    longitude: -82.2295,
    postal_code: '32664'
  },
  '32666': {
    country: 'US',
    city: 'Melrose',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 29.7325,
    longitude: -82.0279,
    postal_code: '32666'
  },
  '32667': {
    country: 'US',
    city: 'Micanopy',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.5122,
    longitude: -82.3053,
    postal_code: '32667'
  },
  '32668': {
    country: 'US',
    city: 'Morriston',
    state: 'Florida',
    state_short: 'FL',
    county: 'Levy',
    latitude: 29.2813,
    longitude: -82.4917,
    postal_code: '32668'
  },
  '32669': {
    country: 'US',
    city: 'Newberry',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.6609,
    longitude: -82.5852,
    postal_code: '32669'
  },
  '32680': {
    country: 'US',
    city: 'Old Town',
    state: 'Florida',
    state_short: 'FL',
    county: 'Dixie',
    latitude: 29.6699,
    longitude: -83.005,
    postal_code: '32680'
  },
  '32681': {
    country: 'US',
    city: 'Orange Lake',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.4236,
    longitude: -82.2168,
    postal_code: '32681'
  },
  '32683': {
    country: 'US',
    city: 'Otter Creek',
    state: 'Florida',
    state_short: 'FL',
    county: 'Levy',
    latitude: 29.3109,
    longitude: -82.7945,
    postal_code: '32683'
  },
  '32686': {
    country: 'US',
    city: 'Reddick',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.3754,
    longitude: -82.244,
    postal_code: '32686'
  },
  '32692': {
    country: 'US',
    city: 'Suwannee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Dixie',
    latitude: 29.3295,
    longitude: -83.14,
    postal_code: '32692'
  },
  '32693': {
    country: 'US',
    city: 'Trenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Gilchrist',
    latitude: 29.6133,
    longitude: -82.8176,
    postal_code: '32693'
  },
  '32694': {
    country: 'US',
    city: 'Waldo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Alachua',
    latitude: 29.7871,
    longitude: -82.1608,
    postal_code: '32694'
  },
  '32696': {
    country: 'US',
    city: 'Williston',
    state: 'Florida',
    state_short: 'FL',
    county: 'Levy',
    latitude: 29.3977,
    longitude: -82.4856,
    postal_code: '32696'
  },
  '32697': {
    country: 'US',
    city: 'Worthington Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Union',
    latitude: 29.9315,
    longitude: -82.4255,
    postal_code: '32697'
  },
  '32701': {
    country: 'US',
    city: 'Altamonte Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.6666,
    longitude: -81.365,
    postal_code: '32701'
  },
  '32702': {
    country: 'US',
    city: 'Altoona',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 29.0219,
    longitude: -81.6323,
    postal_code: '32702'
  },
  '32703': {
    country: 'US',
    city: 'Apopka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.6354,
    longitude: -81.4888,
    postal_code: '32703'
  },
  '32704': {
    country: 'US',
    city: 'Apopka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.6762,
    longitude: -81.5119,
    postal_code: '32704'
  },
  '32706': {
    country: 'US',
    city: 'Cassadaga',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.9664,
    longitude: -81.2371,
    postal_code: '32706'
  },
  '32707': {
    country: 'US',
    city: 'Casselberry',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.6617,
    longitude: -81.3122,
    postal_code: '32707'
  },
  '32708': {
    country: 'US',
    city: 'Winter Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.6831,
    longitude: -81.2814,
    postal_code: '32708'
  },
  '32709': {
    country: 'US',
    city: 'Christmas',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5462,
    longitude: -81.0116,
    postal_code: '32709'
  },
  '32710': {
    country: 'US',
    city: 'Clarcona',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.6128,
    longitude: -81.4987,
    postal_code: '32710'
  },
  '32712': {
    country: 'US',
    city: 'Apopka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.712,
    longitude: -81.5136,
    postal_code: '32712'
  },
  '32713': {
    country: 'US',
    city: 'Debary',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.8846,
    longitude: -81.3065,
    postal_code: '32713'
  },
  '32714': {
    country: 'US',
    city: 'Altamonte Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.6625,
    longitude: -81.4117,
    postal_code: '32714'
  },
  '32715': {
    country: 'US',
    city: 'Altamonte Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.6611,
    longitude: -81.3656,
    postal_code: '32715'
  },
  '32716': {
    country: 'US',
    city: 'Altamonte Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7448,
    longitude: -81.2233,
    postal_code: '32716'
  },
  '32718': {
    country: 'US',
    city: 'Casselberry',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7448,
    longitude: -81.2233,
    postal_code: '32718'
  },
  '32719': {
    country: 'US',
    city: 'Winter Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7448,
    longitude: -81.2233,
    postal_code: '32719'
  },
  '32720': {
    country: 'US',
    city: 'Deland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.0266,
    longitude: -81.3349,
    postal_code: '32720'
  },
  '32721': {
    country: 'US',
    city: 'Deland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.9973,
    longitude: -81.2995,
    postal_code: '32721'
  },
  '32722': {
    country: 'US',
    city: 'Glenwood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.0861,
    longitude: -81.3542,
    postal_code: '32722'
  },
  '32723': {
    country: 'US',
    city: 'Deland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.0275,
    longitude: -81.3068,
    postal_code: '32723'
  },
  '32724': {
    country: 'US',
    city: 'Deland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.0422,
    longitude: -81.2863,
    postal_code: '32724'
  },
  '32725': {
    country: 'US',
    city: 'Deltona',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.8989,
    longitude: -81.2473,
    postal_code: '32725'
  },
  '32726': {
    country: 'US',
    city: 'Eustis',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.855,
    longitude: -81.6789,
    postal_code: '32726'
  },
  '32727': {
    country: 'US',
    city: 'Eustis',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.8555,
    longitude: -81.6741,
    postal_code: '32727'
  },
  '32728': {
    country: 'US',
    city: 'Deltona',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.0227,
    longitude: -81.1722,
    postal_code: '32728'
  },
  '32730': {
    country: 'US',
    city: 'Casselberry',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.6513,
    longitude: -81.3418,
    postal_code: '32730'
  },
  '32732': {
    country: 'US',
    city: 'Geneva',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7503,
    longitude: -81.1114,
    postal_code: '32732'
  },
  '32733': {
    country: 'US',
    city: 'Goldenrod',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.6133,
    longitude: -81.2581,
    postal_code: '32733'
  },
  '32735': {
    country: 'US',
    city: 'Grand Island',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.8866,
    longitude: -81.7391,
    postal_code: '32735'
  },
  '32736': {
    country: 'US',
    city: 'Eustis',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.9102,
    longitude: -81.5235,
    postal_code: '32736'
  },
  '32738': {
    country: 'US',
    city: 'Deltona',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.9093,
    longitude: -81.1922,
    postal_code: '32738'
  },
  '32739': {
    country: 'US',
    city: 'Deltona',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 29.0227,
    longitude: -81.1722,
    postal_code: '32739'
  },
  '32744': {
    country: 'US',
    city: 'Lake Helen',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.9806,
    longitude: -81.2334,
    postal_code: '32744'
  },
  '32745': {
    country: 'US',
    city: 'Mid Florida',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.7676,
    longitude: -81.3522,
    postal_code: '32745'
  },
  '32746': {
    country: 'US',
    city: 'Lake Mary',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7577,
    longitude: -81.3508,
    postal_code: '32746'
  },
  '32747': {
    country: 'US',
    city: 'Lake Monroe',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.8272,
    longitude: -81.3329,
    postal_code: '32747'
  },
  '32750': {
    country: 'US',
    city: 'Longwood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.712,
    longitude: -81.3552,
    postal_code: '32750'
  },
  '32751': {
    country: 'US',
    city: 'Maitland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.6255,
    longitude: -81.3646,
    postal_code: '32751'
  },
  '32752': {
    country: 'US',
    city: 'Longwood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7448,
    longitude: -81.2233,
    postal_code: '32752'
  },
  '32753': {
    country: 'US',
    city: 'Debary',
    state: 'Florida',
    state_short: 'FL',
    county: 'Jefferson',
    latitude: 28.883,
    longitude: -81.3087,
    postal_code: '32753'
  },
  '32754': {
    country: 'US',
    city: 'Mims',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.6974,
    longitude: -80.8663,
    postal_code: '32754'
  },
  '32756': {
    country: 'US',
    city: 'Mount Dora',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.8111,
    longitude: -81.6536,
    postal_code: '32756'
  },
  '32757': {
    country: 'US',
    city: 'Mount Dora',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.774,
    longitude: -81.6439,
    postal_code: '32757'
  },
  '32759': {
    country: 'US',
    city: 'Oak Hill',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.87,
    longitude: -80.8551,
    postal_code: '32759'
  },
  '32762': {
    country: 'US',
    city: 'Oviedo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7448,
    longitude: -81.2233,
    postal_code: '32762'
  },
  '32763': {
    country: 'US',
    city: 'Orange City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.9453,
    longitude: -81.2995,
    postal_code: '32763'
  },
  '32764': {
    country: 'US',
    city: 'Osteen',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.8426,
    longitude: -81.1562,
    postal_code: '32764'
  },
  '32765': {
    country: 'US',
    city: 'Oviedo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.6513,
    longitude: -81.2066,
    postal_code: '32765'
  },
  '32766': {
    country: 'US',
    city: 'Oviedo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.6607,
    longitude: -81.1134,
    postal_code: '32766'
  },
  '32767': {
    country: 'US',
    city: 'Paisley',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.9993,
    longitude: -81.503,
    postal_code: '32767'
  },
  '32768': {
    country: 'US',
    city: 'Plymouth',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.6985,
    longitude: -81.5698,
    postal_code: '32768'
  },
  '32771': {
    country: 'US',
    city: 'Sanford',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.8013,
    longitude: -81.285,
    postal_code: '32771'
  },
  '32772': {
    country: 'US',
    city: 'Sanford',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.8072,
    longitude: -81.2502,
    postal_code: '32772'
  },
  '32773': {
    country: 'US',
    city: 'Sanford',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7644,
    longitude: -81.282,
    postal_code: '32773'
  },
  '32774': {
    country: 'US',
    city: 'Orange City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Volusia',
    latitude: 28.9489,
    longitude: -81.2987,
    postal_code: '32774'
  },
  '32775': {
    country: 'US',
    city: 'Scottsmoor',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.7702,
    longitude: -80.872,
    postal_code: '32775'
  },
  '32776': {
    country: 'US',
    city: 'Sorrento',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.8035,
    longitude: -81.5323,
    postal_code: '32776'
  },
  '32777': {
    country: 'US',
    city: 'Tangerine',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.765,
    longitude: -81.6306,
    postal_code: '32777'
  },
  '32778': {
    country: 'US',
    city: 'Tavares',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.801,
    longitude: -81.734,
    postal_code: '32778'
  },
  '32779': {
    country: 'US',
    city: 'Longwood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7168,
    longitude: -81.4126,
    postal_code: '32779'
  },
  '32780': {
    country: 'US',
    city: 'Titusville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.5697,
    longitude: -80.8191,
    postal_code: '32780'
  },
  '32781': {
    country: 'US',
    city: 'Titusville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.6122,
    longitude: -80.8076,
    postal_code: '32781'
  },
  '32783': {
    country: 'US',
    city: 'Titusville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.6122,
    longitude: -80.8076,
    postal_code: '32783'
  },
  '32784': {
    country: 'US',
    city: 'Umatilla',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.9254,
    longitude: -81.6801,
    postal_code: '32784'
  },
  '32789': {
    country: 'US',
    city: 'Winter Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5978,
    longitude: -81.3534,
    postal_code: '32789'
  },
  '32790': {
    country: 'US',
    city: 'Winter Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32790'
  },
  '32791': {
    country: 'US',
    city: 'Longwood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7448,
    longitude: -81.2233,
    postal_code: '32791'
  },
  '32792': {
    country: 'US',
    city: 'Winter Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5974,
    longitude: -81.3036,
    postal_code: '32792'
  },
  '32793': {
    country: 'US',
    city: 'Winter Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32793'
  },
  '32794': {
    country: 'US',
    city: 'Maitland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.6278,
    longitude: -81.3631,
    postal_code: '32794'
  },
  '32795': {
    country: 'US',
    city: 'Lake Mary',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7448,
    longitude: -81.2233,
    postal_code: '32795'
  },
  '32796': {
    country: 'US',
    city: 'Titusville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.6271,
    longitude: -80.8429,
    postal_code: '32796'
  },
  '32798': {
    country: 'US',
    city: 'Zellwood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.7194,
    longitude: -81.5762,
    postal_code: '32798'
  },
  '32799': {
    country: 'US',
    city: 'Mid Florida',
    state: 'Florida',
    state_short: 'FL',
    county: 'Seminole',
    latitude: 28.7448,
    longitude: -81.2233,
    postal_code: '32799'
  },
  '32801': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5399,
    longitude: -81.3727,
    postal_code: '32801'
  },
  '32802': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5383,
    longitude: -81.3792,
    postal_code: '32802'
  },
  '32803': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5559,
    longitude: -81.3535,
    postal_code: '32803'
  },
  '32804': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5754,
    longitude: -81.3955,
    postal_code: '32804'
  },
  '32805': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5302,
    longitude: -81.4045,
    postal_code: '32805'
  },
  '32806': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.514,
    longitude: -81.357,
    postal_code: '32806'
  },
  '32807': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5515,
    longitude: -81.3051,
    postal_code: '32807'
  },
  '32808': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5803,
    longitude: -81.4396,
    postal_code: '32808'
  },
  '32809': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.4637,
    longitude: -81.3948,
    postal_code: '32809'
  },
  '32810': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.6214,
    longitude: -81.4294,
    postal_code: '32810'
  },
  '32811': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5163,
    longitude: -81.4516,
    postal_code: '32811'
  },
  '32812': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.4998,
    longitude: -81.3288,
    postal_code: '32812'
  },
  '32814': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5702,
    longitude: -81.3265,
    postal_code: '32814'
  },
  '32815': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3067,
    longitude: -80.6862,
    postal_code: '32815'
  },
  '32816': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32816'
  },
  '32817': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5891,
    longitude: -81.2277,
    postal_code: '32817'
  },
  '32818': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5801,
    longitude: -81.4846,
    postal_code: '32818'
  },
  '32819': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.4522,
    longitude: -81.4678,
    postal_code: '32819'
  },
  '32820': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5725,
    longitude: -81.1219,
    postal_code: '32820'
  },
  '32821': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.3957,
    longitude: -81.4666,
    postal_code: '32821'
  },
  '32822': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.4944,
    longitude: -81.2902,
    postal_code: '32822'
  },
  '32824': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.3932,
    longitude: -81.3622,
    postal_code: '32824'
  },
  '32825': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5469,
    longitude: -81.2571,
    postal_code: '32825'
  },
  '32826': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5826,
    longitude: -81.1907,
    postal_code: '32826'
  },
  '32827': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.4317,
    longitude: -81.343,
    postal_code: '32827'
  },
  '32828': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5523,
    longitude: -81.1795,
    postal_code: '32828'
  },
  '32829': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.4671,
    longitude: -81.2417,
    postal_code: '32829'
  },
  '32830': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.3822,
    longitude: -81.569,
    postal_code: '32830'
  },
  '32831': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.4656,
    longitude: -81.151,
    postal_code: '32831'
  },
  '32832': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.3774,
    longitude: -81.1888,
    postal_code: '32832'
  },
  '32833': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5088,
    longitude: -81.0703,
    postal_code: '32833'
  },
  '32834': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32834'
  },
  '32835': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5289,
    longitude: -81.4787,
    postal_code: '32835'
  },
  '32836': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.4115,
    longitude: -81.525,
    postal_code: '32836'
  },
  '32837': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.3949,
    longitude: -81.4179,
    postal_code: '32837'
  },
  '32839': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.4871,
    longitude: -81.4082,
    postal_code: '32839'
  },
  '32853': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32853'
  },
  '32854': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32854'
  },
  '32855': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5383,
    longitude: -81.3792,
    postal_code: '32855'
  },
  '32856': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5484,
    longitude: -81.4201,
    postal_code: '32856'
  },
  '32857': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32857'
  },
  '32858': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32858'
  },
  '32859': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.4429,
    longitude: -81.4026,
    postal_code: '32859'
  },
  '32860': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32860'
  },
  '32861': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32861'
  },
  '32862': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5383,
    longitude: -81.3792,
    postal_code: '32862'
  },
  '32867': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32867'
  },
  '32868': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32868'
  },
  '32869': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32869'
  },
  '32872': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5383,
    longitude: -81.3792,
    postal_code: '32872'
  },
  '32877': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32877'
  },
  '32878': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32878'
  },
  '32885': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5456,
    longitude: -81.3782,
    postal_code: '32885'
  },
  '32886': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5383,
    longitude: -81.3792,
    postal_code: '32886'
  },
  '32887': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32887'
  },
  '32891': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5663,
    longitude: -81.2608,
    postal_code: '32891'
  },
  '32896': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5419,
    longitude: -81.3791,
    postal_code: '32896'
  },
  '32897': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5383,
    longitude: -81.3792,
    postal_code: '32897'
  },
  '32899': {
    country: 'US',
    city: 'Orlando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3067,
    longitude: -80.6862,
    postal_code: '32899'
  },
  '32901': {
    country: 'US',
    city: 'Melbourne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0691,
    longitude: -80.62,
    postal_code: '32901'
  },
  '32902': {
    country: 'US',
    city: 'Melbourne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0836,
    longitude: -80.6081,
    postal_code: '32902'
  },
  '32903': {
    country: 'US',
    city: 'Indialantic',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.1091,
    longitude: -80.5787,
    postal_code: '32903'
  },
  '32904': {
    country: 'US',
    city: 'Melbourne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0673,
    longitude: -80.678,
    postal_code: '32904'
  },
  '32905': {
    country: 'US',
    city: 'Palm Bay',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0313,
    longitude: -80.5995,
    postal_code: '32905'
  },
  '32906': {
    country: 'US',
    city: 'Palm Bay',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0671,
    longitude: -80.6503,
    postal_code: '32906'
  },
  '32907': {
    country: 'US',
    city: 'Palm Bay',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0168,
    longitude: -80.6739,
    postal_code: '32907'
  },
  '32908': {
    country: 'US',
    city: 'Palm Bay',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 27.9816,
    longitude: -80.6894,
    postal_code: '32908'
  },
  '32909': {
    country: 'US',
    city: 'Palm Bay',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 27.9694,
    longitude: -80.6473,
    postal_code: '32909'
  },
  '32910': {
    country: 'US',
    city: 'Palm Bay',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0345,
    longitude: -80.5887,
    postal_code: '32910'
  },
  '32911': {
    country: 'US',
    city: 'Palm Bay',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0345,
    longitude: -80.5887,
    postal_code: '32911'
  },
  '32912': {
    country: 'US',
    city: 'Melbourne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0836,
    longitude: -80.6081,
    postal_code: '32912'
  },
  '32919': {
    country: 'US',
    city: 'Melbourne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0836,
    longitude: -80.6081,
    postal_code: '32919'
  },
  '32920': {
    country: 'US',
    city: 'Cape Canaveral',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3903,
    longitude: -80.6043,
    postal_code: '32920'
  },
  '32922': {
    country: 'US',
    city: 'Cocoa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3672,
    longitude: -80.7465,
    postal_code: '32922'
  },
  '32923': {
    country: 'US',
    city: 'Cocoa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.4275,
    longitude: -80.829,
    postal_code: '32923'
  },
  '32924': {
    country: 'US',
    city: 'Cocoa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3067,
    longitude: -80.6862,
    postal_code: '32924'
  },
  '32925': {
    country: 'US',
    city: 'Patrick Afb',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.1743,
    longitude: -80.584,
    postal_code: '32925'
  },
  '32926': {
    country: 'US',
    city: 'Cocoa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.391,
    longitude: -80.787,
    postal_code: '32926'
  },
  '32927': {
    country: 'US',
    city: 'Cocoa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.4566,
    longitude: -80.7978,
    postal_code: '32927'
  },
  '32931': {
    country: 'US',
    city: 'Cocoa Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3325,
    longitude: -80.6121,
    postal_code: '32931'
  },
  '32932': {
    country: 'US',
    city: 'Cocoa Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3206,
    longitude: -80.6092,
    postal_code: '32932'
  },
  '32934': {
    country: 'US',
    city: 'Melbourne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.1331,
    longitude: -80.7112,
    postal_code: '32934'
  },
  '32935': {
    country: 'US',
    city: 'Melbourne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.1384,
    longitude: -80.6524,
    postal_code: '32935'
  },
  '32936': {
    country: 'US',
    city: 'Melbourne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0836,
    longitude: -80.6081,
    postal_code: '32936'
  },
  '32937': {
    country: 'US',
    city: 'Satellite Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.178,
    longitude: -80.602,
    postal_code: '32937'
  },
  '32940': {
    country: 'US',
    city: 'Melbourne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.2061,
    longitude: -80.685,
    postal_code: '32940'
  },
  '32941': {
    country: 'US',
    city: 'Melbourne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 27.9246,
    longitude: -80.5235,
    postal_code: '32941'
  },
  '32948': {
    country: 'US',
    city: 'Fellsmere',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.7643,
    longitude: -80.6019,
    postal_code: '32948'
  },
  '32949': {
    country: 'US',
    city: 'Grant',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 27.9289,
    longitude: -80.5264,
    postal_code: '32949'
  },
  '32950': {
    country: 'US',
    city: 'Malabar',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 27.9761,
    longitude: -80.5788,
    postal_code: '32950'
  },
  '32951': {
    country: 'US',
    city: 'Melbourne Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.0219,
    longitude: -80.5389,
    postal_code: '32951'
  },
  '32952': {
    country: 'US',
    city: 'Merritt Island',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.2764,
    longitude: -80.6568,
    postal_code: '32952'
  },
  '32953': {
    country: 'US',
    city: 'Merritt Island',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3888,
    longitude: -80.7301,
    postal_code: '32953'
  },
  '32954': {
    country: 'US',
    city: 'Merritt Island',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.5392,
    longitude: -80.672,
    postal_code: '32954'
  },
  '32955': {
    country: 'US',
    city: 'Rockledge',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3134,
    longitude: -80.7319,
    postal_code: '32955'
  },
  '32956': {
    country: 'US',
    city: 'Rockledge',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3298,
    longitude: -80.7323,
    postal_code: '32956'
  },
  '32957': {
    country: 'US',
    city: 'Roseland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.8359,
    longitude: -80.4931,
    postal_code: '32957'
  },
  '32958': {
    country: 'US',
    city: 'Sebastian',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.7901,
    longitude: -80.4784,
    postal_code: '32958'
  },
  '32959': {
    country: 'US',
    city: 'Sharpes',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 28.3067,
    longitude: -80.6862,
    postal_code: '32959'
  },
  '32960': {
    country: 'US',
    city: 'Vero Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.633,
    longitude: -80.4031,
    postal_code: '32960'
  },
  '32961': {
    country: 'US',
    city: 'Vero Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.6175,
    longitude: -80.4231,
    postal_code: '32961'
  },
  '32962': {
    country: 'US',
    city: 'Vero Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.5885,
    longitude: -80.3923,
    postal_code: '32962'
  },
  '32963': {
    country: 'US',
    city: 'Vero Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.6898,
    longitude: -80.3757,
    postal_code: '32963'
  },
  '32964': {
    country: 'US',
    city: 'Vero Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.6386,
    longitude: -80.3973,
    postal_code: '32964'
  },
  '32965': {
    country: 'US',
    city: 'Vero Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.6386,
    longitude: -80.3973,
    postal_code: '32965'
  },
  '32966': {
    country: 'US',
    city: 'Vero Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.6372,
    longitude: -80.4794,
    postal_code: '32966'
  },
  '32967': {
    country: 'US',
    city: 'Vero Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.6972,
    longitude: -80.4416,
    postal_code: '32967'
  },
  '32968': {
    country: 'US',
    city: 'Vero Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.5999,
    longitude: -80.4382,
    postal_code: '32968'
  },
  '32969': {
    country: 'US',
    city: 'Vero Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.709,
    longitude: -80.5726,
    postal_code: '32969'
  },
  '32970': {
    country: 'US',
    city: 'Wabasso',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.7484,
    longitude: -80.4362,
    postal_code: '32970'
  },
  '32971': {
    country: 'US',
    city: 'Winter Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.7192,
    longitude: -80.4206,
    postal_code: '32971'
  },
  '32976': {
    country: 'US',
    city: 'Sebastian',
    state: 'Florida',
    state_short: 'FL',
    county: 'Brevard',
    latitude: 27.8679,
    longitude: -80.5416,
    postal_code: '32976'
  },
  '32978': {
    country: 'US',
    city: 'Sebastian',
    state: 'Florida',
    state_short: 'FL',
    county: 'Indian River',
    latitude: 27.709,
    longitude: -80.5726,
    postal_code: '32978'
  },
  '33001': {
    country: 'US',
    city: 'Long Key',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 24.8306,
    longitude: -80.8049,
    postal_code: '33001'
  },
  '33002': {
    country: 'US',
    city: 'Hialeah',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8576,
    longitude: -80.2781,
    postal_code: '33002'
  },
  '33004': {
    country: 'US',
    city: 'Dania',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0476,
    longitude: -80.1447,
    postal_code: '33004'
  },
  '33008': {
    country: 'US',
    city: 'Hallandale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 25.9812,
    longitude: -80.1484,
    postal_code: '33008'
  },
  '33009': {
    country: 'US',
    city: 'Hallandale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 25.985,
    longitude: -80.1407,
    postal_code: '33009'
  },
  '33010': {
    country: 'US',
    city: 'Hialeah',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8325,
    longitude: -80.2808,
    postal_code: '33010'
  },
  '33011': {
    country: 'US',
    city: 'Hialeah',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8576,
    longitude: -80.2781,
    postal_code: '33011'
  },
  '33012': {
    country: 'US',
    city: 'Hialeah',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8654,
    longitude: -80.3059,
    postal_code: '33012'
  },
  '33013': {
    country: 'US',
    city: 'Hialeah',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8594,
    longitude: -80.2725,
    postal_code: '33013'
  },
  '33014': {
    country: 'US',
    city: 'Hialeah',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8963,
    longitude: -80.3063,
    postal_code: '33014'
  },
  '33015': {
    country: 'US',
    city: 'Hialeah',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.9388,
    longitude: -80.3165,
    postal_code: '33015'
  },
  '33016': {
    country: 'US',
    city: 'Hialeah',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8803,
    longitude: -80.3368,
    postal_code: '33016'
  },
  '33017': {
    country: 'US',
    city: 'Hialeah',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8576,
    longitude: -80.2781,
    postal_code: '33017'
  },
  '33018': {
    country: 'US',
    city: 'Hialeah',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.9098,
    longitude: -80.3889,
    postal_code: '33018'
  },
  '33019': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.007,
    longitude: -80.1219,
    postal_code: '33019'
  },
  '33020': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0161,
    longitude: -80.1517,
    postal_code: '33020'
  },
  '33021': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0218,
    longitude: -80.1891,
    postal_code: '33021'
  },
  '33022': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0134,
    longitude: -80.1442,
    postal_code: '33022'
  },
  '33023': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 25.9894,
    longitude: -80.2153,
    postal_code: '33023'
  },
  '33024': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0296,
    longitude: -80.2489,
    postal_code: '33024'
  },
  '33025': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 25.9921,
    longitude: -80.2712,
    postal_code: '33025'
  },
  '33026': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0229,
    longitude: -80.2974,
    postal_code: '33026'
  },
  '33027': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 25.9974,
    longitude: -80.3248,
    postal_code: '33027'
  },
  '33028': {
    country: 'US',
    city: 'Pembroke Pines',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0185,
    longitude: -80.3449,
    postal_code: '33028'
  },
  '33029': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 25.9924,
    longitude: -80.4089,
    postal_code: '33029'
  },
  '33030': {
    country: 'US',
    city: 'Homestead',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.4766,
    longitude: -80.4839,
    postal_code: '33030'
  },
  '33031': {
    country: 'US',
    city: 'Homestead',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.5323,
    longitude: -80.5075,
    postal_code: '33031'
  },
  '33032': {
    country: 'US',
    city: 'Homestead',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.5303,
    longitude: -80.3918,
    postal_code: '33032'
  },
  '33033': {
    country: 'US',
    city: 'Homestead',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.4906,
    longitude: -80.438,
    postal_code: '33033'
  },
  '33034': {
    country: 'US',
    city: 'Homestead',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.2846,
    longitude: -80.6246,
    postal_code: '33034'
  },
  '33035': {
    country: 'US',
    city: 'Homestead',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.4573,
    longitude: -80.4572,
    postal_code: '33035'
  },
  '33036': {
    country: 'US',
    city: 'Islamorada',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 24.9233,
    longitude: -80.63,
    postal_code: '33036'
  },
  '33037': {
    country: 'US',
    city: 'Key Largo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 25.0865,
    longitude: -80.4473,
    postal_code: '33037'
  },
  '33039': {
    country: 'US',
    city: 'Homestead',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.5021,
    longitude: -80.3997,
    postal_code: '33039'
  },
  '33040': {
    country: 'US',
    city: 'Key West',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 24.5552,
    longitude: -81.7816,
    postal_code: '33040'
  },
  '33041': {
    country: 'US',
    city: 'Key West',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 24.5552,
    longitude: -81.7816,
    postal_code: '33041'
  },
  '33042': {
    country: 'US',
    city: 'Summerland Key',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 24.667,
    longitude: -81.5099,
    postal_code: '33042'
  },
  '33043': {
    country: 'US',
    city: 'Big Pine Key',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 24.68,
    longitude: -81.362,
    postal_code: '33043'
  },
  '33045': {
    country: 'US',
    city: 'Key West',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 24.5552,
    longitude: -81.7816,
    postal_code: '33045'
  },
  '33050': {
    country: 'US',
    city: 'Marathon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 24.7279,
    longitude: -81.0386,
    postal_code: '33050'
  },
  '33051': {
    country: 'US',
    city: 'Key Colony Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 24.7234,
    longitude: -81.0203,
    postal_code: '33051'
  },
  '33052': {
    country: 'US',
    city: 'Marathon Shores',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 24.7233,
    longitude: -81.0632,
    postal_code: '33052'
  },
  '33054': {
    country: 'US',
    city: 'Opa Locka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.9097,
    longitude: -80.247,
    postal_code: '33054'
  },
  '33055': {
    country: 'US',
    city: 'Opa Locka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.9476,
    longitude: -80.2778,
    postal_code: '33055'
  },
  '33056': {
    country: 'US',
    city: 'Miami Gardens',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.942,
    longitude: -80.2456,
    postal_code: '33056'
  },
  '33060': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2315,
    longitude: -80.1235,
    postal_code: '33060'
  },
  '33061': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2539,
    longitude: -80.1342,
    postal_code: '33061'
  },
  '33062': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2343,
    longitude: -80.0941,
    postal_code: '33062'
  },
  '33063': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2674,
    longitude: -80.2092,
    postal_code: '33063'
  },
  '33064': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2785,
    longitude: -80.1157,
    postal_code: '33064'
  },
  '33065': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2729,
    longitude: -80.2603,
    postal_code: '33065'
  },
  '33066': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2535,
    longitude: -80.1775,
    postal_code: '33066'
  },
  '33067': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.3033,
    longitude: -80.2415,
    postal_code: '33067'
  },
  '33068': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.216,
    longitude: -80.2205,
    postal_code: '33068'
  },
  '33069': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2288,
    longitude: -80.1635,
    postal_code: '33069'
  },
  '33070': {
    country: 'US',
    city: 'Tavernier',
    state: 'Florida',
    state_short: 'FL',
    county: 'Monroe',
    latitude: 25.0108,
    longitude: -80.5218,
    postal_code: '33070'
  },
  '33071': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2435,
    longitude: -80.2601,
    postal_code: '33071'
  },
  '33072': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2335,
    longitude: -80.0924,
    postal_code: '33072'
  },
  '33073': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2997,
    longitude: -80.181,
    postal_code: '33073'
  },
  '33074': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2379,
    longitude: -80.1248,
    postal_code: '33074'
  },
  '33075': {
    country: 'US',
    city: 'Coral Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2712,
    longitude: -80.2706,
    postal_code: '33075'
  },
  '33076': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.3168,
    longitude: -80.2753,
    postal_code: '33076'
  },
  '33077': {
    country: 'US',
    city: 'Pompano Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2379,
    longitude: -80.1248,
    postal_code: '33077'
  },
  '33081': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0112,
    longitude: -80.1495,
    postal_code: '33081'
  },
  '33082': {
    country: 'US',
    city: 'Pembroke Pines',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0031,
    longitude: -80.2239,
    postal_code: '33082'
  },
  '33083': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0112,
    longitude: -80.1495,
    postal_code: '33083'
  },
  '33084': {
    country: 'US',
    city: 'Hollywood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0112,
    longitude: -80.1495,
    postal_code: '33084'
  },
  '33090': {
    country: 'US',
    city: 'Homestead',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.5584,
    longitude: -80.4582,
    postal_code: '33090'
  },
  '33092': {
    country: 'US',
    city: 'Homestead',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.5584,
    longitude: -80.4582,
    postal_code: '33092'
  },
  '33093': {
    country: 'US',
    city: 'Margate',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2445,
    longitude: -80.2064,
    postal_code: '33093'
  },
  '33097': {
    country: 'US',
    city: 'Coconut Creek',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.2517,
    longitude: -80.1789,
    postal_code: '33097'
  },
  '33101': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7791,
    longitude: -80.1978,
    postal_code: '33101'
  },
  '33102': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33102'
  },
  '33106': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8527,
    longitude: -80.3012,
    postal_code: '33106'
  },
  '33109': {
    country: 'US',
    city: 'Miami Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7588,
    longitude: -80.1376,
    postal_code: '33109'
  },
  '33111': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33111'
  },
  '33112': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Putnam',
    latitude: 25.7964,
    longitude: -80.3849,
    postal_code: '33112'
  },
  '33114': {
    country: 'US',
    city: 'Coral Gables',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7215,
    longitude: -80.2684,
    postal_code: '33114'
  },
  '33116': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33116'
  },
  '33119': {
    country: 'US',
    city: 'Miami Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7845,
    longitude: -80.132,
    postal_code: '33119'
  },
  '33122': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8001,
    longitude: -80.281,
    postal_code: '33122'
  },
  '33124': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33124'
  },
  '33125': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7825,
    longitude: -80.2341,
    postal_code: '33125'
  },
  '33126': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7763,
    longitude: -80.2919,
    postal_code: '33126'
  },
  '33127': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8143,
    longitude: -80.2051,
    postal_code: '33127'
  },
  '33128': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7756,
    longitude: -80.2089,
    postal_code: '33128'
  },
  '33129': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7559,
    longitude: -80.2013,
    postal_code: '33129'
  },
  '33130': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7672,
    longitude: -80.2059,
    postal_code: '33130'
  },
  '33131': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7629,
    longitude: -80.1895,
    postal_code: '33131'
  },
  '33132': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7867,
    longitude: -80.18,
    postal_code: '33132'
  },
  '33133': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7378,
    longitude: -80.2248,
    postal_code: '33133'
  },
  '33134': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.768,
    longitude: -80.2714,
    postal_code: '33134'
  },
  '33135': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7664,
    longitude: -80.2317,
    postal_code: '33135'
  },
  '33136': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7864,
    longitude: -80.2042,
    postal_code: '33136'
  },
  '33137': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8156,
    longitude: -80.1897,
    postal_code: '33137'
  },
  '33138': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8521,
    longitude: -80.1821,
    postal_code: '33138'
  },
  '33139': {
    country: 'US',
    city: 'Miami Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7873,
    longitude: -80.1564,
    postal_code: '33139'
  },
  '33140': {
    country: 'US',
    city: 'Miami Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8198,
    longitude: -80.1337,
    postal_code: '33140'
  },
  '33141': {
    country: 'US',
    city: 'Miami Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8486,
    longitude: -80.1446,
    postal_code: '33141'
  },
  '33142': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.813,
    longitude: -80.232,
    postal_code: '33142'
  },
  '33143': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7022,
    longitude: -80.2978,
    postal_code: '33143'
  },
  '33144': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7626,
    longitude: -80.3096,
    postal_code: '33144'
  },
  '33145': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7539,
    longitude: -80.2253,
    postal_code: '33145'
  },
  '33146': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7205,
    longitude: -80.2728,
    postal_code: '33146'
  },
  '33147': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8507,
    longitude: -80.2366,
    postal_code: '33147'
  },
  '33149': {
    country: 'US',
    city: 'Key Biscayne',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.6921,
    longitude: -80.1625,
    postal_code: '33149'
  },
  '33150': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8512,
    longitude: -80.207,
    postal_code: '33150'
  },
  '33151': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8321,
    longitude: -80.2094,
    postal_code: '33151'
  },
  '33152': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7955,
    longitude: -80.3129,
    postal_code: '33152'
  },
  '33153': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8655,
    longitude: -80.1936,
    postal_code: '33153'
  },
  '33154': {
    country: 'US',
    city: 'Miami Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7907,
    longitude: -80.13,
    postal_code: '33154'
  },
  '33155': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7392,
    longitude: -80.3103,
    postal_code: '33155'
  },
  '33156': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.6682,
    longitude: -80.2973,
    postal_code: '33156'
  },
  '33157': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.6062,
    longitude: -80.3426,
    postal_code: '33157'
  },
  '33158': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.6364,
    longitude: -80.3187,
    postal_code: '33158'
  },
  '33160': {
    country: 'US',
    city: 'North Miami Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.9449,
    longitude: -80.1391,
    postal_code: '33160'
  },
  '33161': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8934,
    longitude: -80.1758,
    postal_code: '33161'
  },
  '33162': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.9286,
    longitude: -80.183,
    postal_code: '33162'
  },
  '33163': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.945,
    longitude: -80.2145,
    postal_code: '33163'
  },
  '33164': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33164'
  },
  '33165': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7343,
    longitude: -80.3588,
    postal_code: '33165'
  },
  '33166': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8301,
    longitude: -80.2926,
    postal_code: '33166'
  },
  '33167': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8856,
    longitude: -80.2292,
    postal_code: '33167'
  },
  '33168': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8902,
    longitude: -80.2101,
    postal_code: '33168'
  },
  '33169': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.9441,
    longitude: -80.2144,
    postal_code: '33169'
  },
  '33170': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.5584,
    longitude: -80.4582,
    postal_code: '33170'
  },
  '33172': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7735,
    longitude: -80.3572,
    postal_code: '33172'
  },
  '33173': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.6992,
    longitude: -80.3618,
    postal_code: '33173'
  },
  '33174': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7628,
    longitude: -80.3611,
    postal_code: '33174'
  },
  '33175': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7341,
    longitude: -80.4068,
    postal_code: '33175'
  },
  '33176': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.6574,
    longitude: -80.3627,
    postal_code: '33176'
  },
  '33177': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.5968,
    longitude: -80.4046,
    postal_code: '33177'
  },
  '33178': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8141,
    longitude: -80.3549,
    postal_code: '33178'
  },
  '33179': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.9571,
    longitude: -80.1814,
    postal_code: '33179'
  },
  '33180': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.9597,
    longitude: -80.1403,
    postal_code: '33180'
  },
  '33181': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.8965,
    longitude: -80.157,
    postal_code: '33181'
  },
  '33182': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7877,
    longitude: -80.4166,
    postal_code: '33182'
  },
  '33183': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7,
    longitude: -80.413,
    postal_code: '33183'
  },
  '33184': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7574,
    longitude: -80.403,
    postal_code: '33184'
  },
  '33185': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7274,
    longitude: -80.4497,
    postal_code: '33185'
  },
  '33186': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.6694,
    longitude: -80.4085,
    postal_code: '33186'
  },
  '33187': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.596,
    longitude: -80.507,
    postal_code: '33187'
  },
  '33188': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33188'
  },
  '33189': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.573,
    longitude: -80.3374,
    postal_code: '33189'
  },
  '33190': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.5593,
    longitude: -80.3483,
    postal_code: '33190'
  },
  '33191': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33191'
  },
  '33192': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33192'
  },
  '33193': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.6964,
    longitude: -80.4401,
    postal_code: '33193'
  },
  '33194': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.5584,
    longitude: -80.4582,
    postal_code: '33194'
  },
  '33195': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7729,
    longitude: -80.187,
    postal_code: '33195'
  },
  '33196': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.6615,
    longitude: -80.441,
    postal_code: '33196'
  },
  '33197': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33197'
  },
  '33198': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33198'
  },
  '33199': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33199'
  },
  '33206': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33206'
  },
  '33222': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7577,
    longitude: -80.3748,
    postal_code: '33222'
  },
  '33231': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33231'
  },
  '33233': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33233'
  },
  '33234': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33234'
  },
  '33238': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33238'
  },
  '33239': {
    country: 'US',
    city: 'Miami Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7907,
    longitude: -80.13,
    postal_code: '33239'
  },
  '33242': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33242'
  },
  '33243': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33243'
  },
  '33245': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33245'
  },
  '33247': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33247'
  },
  '33255': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33255'
  },
  '33256': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33256'
  },
  '33257': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33257'
  },
  '33261': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33261'
  },
  '33265': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33265'
  },
  '33266': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33266'
  },
  '33269': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33269'
  },
  '33280': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33280'
  },
  '33283': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33283'
  },
  '33296': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33296'
  },
  '33299': {
    country: 'US',
    city: 'Miami',
    state: 'Florida',
    state_short: 'FL',
    county: 'Miami-Dade',
    latitude: 25.7743,
    longitude: -80.1937,
    postal_code: '33299'
  },
  '33301': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1216,
    longitude: -80.1288,
    postal_code: '33301'
  },
  '33302': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33302'
  },
  '33303': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1969,
    longitude: -80.0952,
    postal_code: '33303'
  },
  '33304': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1387,
    longitude: -80.1218,
    postal_code: '33304'
  },
  '33305': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1497,
    longitude: -80.1229,
    postal_code: '33305'
  },
  '33306': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1656,
    longitude: -80.1118,
    postal_code: '33306'
  },
  '33307': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33307'
  },
  '33308': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0984,
    longitude: -80.1822,
    postal_code: '33308'
  },
  '33309': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1817,
    longitude: -80.1746,
    postal_code: '33309'
  },
  '33310': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1443,
    longitude: -80.2069,
    postal_code: '33310'
  },
  '33311': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1421,
    longitude: -80.1728,
    postal_code: '33311'
  },
  '33312': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0968,
    longitude: -80.181,
    postal_code: '33312'
  },
  '33313': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1487,
    longitude: -80.2075,
    postal_code: '33313'
  },
  '33314': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0697,
    longitude: -80.2246,
    postal_code: '33314'
  },
  '33315': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0989,
    longitude: -80.1541,
    postal_code: '33315'
  },
  '33316': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1042,
    longitude: -80.126,
    postal_code: '33316'
  },
  '33317': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1122,
    longitude: -80.2264,
    postal_code: '33317'
  },
  '33318': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1184,
    longitude: -80.252,
    postal_code: '33318'
  },
  '33319': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1848,
    longitude: -80.2406,
    postal_code: '33319'
  },
  '33320': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1625,
    longitude: -80.2582,
    postal_code: '33320'
  },
  '33321': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.212,
    longitude: -80.2696,
    postal_code: '33321'
  },
  '33322': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1502,
    longitude: -80.2745,
    postal_code: '33322'
  },
  '33323': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.152,
    longitude: -80.3165,
    postal_code: '33323'
  },
  '33324': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1255,
    longitude: -80.2644,
    postal_code: '33324'
  },
  '33325': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1097,
    longitude: -80.3215,
    postal_code: '33325'
  },
  '33326': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1158,
    longitude: -80.3681,
    postal_code: '33326'
  },
  '33327': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1168,
    longitude: -80.4156,
    postal_code: '33327'
  },
  '33328': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0671,
    longitude: -80.2723,
    postal_code: '33328'
  },
  '33329': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33329'
  },
  '33330': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0663,
    longitude: -80.3339,
    postal_code: '33330'
  },
  '33331': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.048,
    longitude: -80.3749,
    postal_code: '33331'
  },
  '33332': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0596,
    longitude: -80.4146,
    postal_code: '33332'
  },
  '33334': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1845,
    longitude: -80.1344,
    postal_code: '33334'
  },
  '33335': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.0892,
    longitude: -80.336,
    postal_code: '33335'
  },
  '33336': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1219,
    longitude: -80.1436,
    postal_code: '33336'
  },
  '33337': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.129,
    longitude: -80.2601,
    postal_code: '33337'
  },
  '33338': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33338'
  },
  '33339': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33339'
  },
  '33340': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33340'
  },
  '33345': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1654,
    longitude: -80.2959,
    postal_code: '33345'
  },
  '33346': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33346'
  },
  '33348': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33348'
  },
  '33349': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33349'
  },
  '33351': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1793,
    longitude: -80.2746,
    postal_code: '33351'
  },
  '33355': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33355'
  },
  '33359': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1223,
    longitude: -80.1434,
    postal_code: '33359'
  },
  '33388': {
    country: 'US',
    city: 'Plantation',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1342,
    longitude: -80.2318,
    postal_code: '33388'
  },
  '33394': {
    country: 'US',
    city: 'Fort Lauderdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.1221,
    longitude: -80.139,
    postal_code: '33394'
  },
  '33401': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7165,
    longitude: -80.0679,
    postal_code: '33401'
  },
  '33402': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7153,
    longitude: -80.0534,
    postal_code: '33402'
  },
  '33403': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.8035,
    longitude: -80.0756,
    postal_code: '33403'
  },
  '33404': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7832,
    longitude: -80.0638,
    postal_code: '33404'
  },
  '33405': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.67,
    longitude: -80.0582,
    postal_code: '33405'
  },
  '33406': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6396,
    longitude: -80.0827,
    postal_code: '33406'
  },
  '33407': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7492,
    longitude: -80.0725,
    postal_code: '33407'
  },
  '33408': {
    country: 'US',
    city: 'North Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.8289,
    longitude: -80.0603,
    postal_code: '33408'
  },
  '33409': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7162,
    longitude: -80.0965,
    postal_code: '33409'
  },
  '33410': {
    country: 'US',
    city: 'Palm Beach Gardens',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.8234,
    longitude: -80.1387,
    postal_code: '33410'
  },
  '33411': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6644,
    longitude: -80.1741,
    postal_code: '33411'
  },
  '33412': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.8055,
    longitude: -80.2482,
    postal_code: '33412'
  },
  '33413': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6555,
    longitude: -80.1596,
    postal_code: '33413'
  },
  '33414': {
    country: 'US',
    city: 'Wellington',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6587,
    longitude: -80.2414,
    postal_code: '33414'
  },
  '33415': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.656,
    longitude: -80.126,
    postal_code: '33415'
  },
  '33416': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6654,
    longitude: -80.0929,
    postal_code: '33416'
  },
  '33417': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7197,
    longitude: -80.1248,
    postal_code: '33417'
  },
  '33418': {
    country: 'US',
    city: 'Palm Beach Gardens',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.8234,
    longitude: -80.1387,
    postal_code: '33418'
  },
  '33419': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7153,
    longitude: -80.0534,
    postal_code: '33419'
  },
  '33420': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7153,
    longitude: -80.0534,
    postal_code: '33420'
  },
  '33421': {
    country: 'US',
    city: 'Royal Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7084,
    longitude: -80.2306,
    postal_code: '33421'
  },
  '33422': {
    country: 'US',
    city: 'West Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7153,
    longitude: -80.0534,
    postal_code: '33422'
  },
  '33424': {
    country: 'US',
    city: 'Boynton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5253,
    longitude: -80.0664,
    postal_code: '33424'
  },
  '33425': {
    country: 'US',
    city: 'Boynton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5253,
    longitude: -80.0664,
    postal_code: '33425'
  },
  '33426': {
    country: 'US',
    city: 'Boynton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5175,
    longitude: -80.0834,
    postal_code: '33426'
  },
  '33427': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.376,
    longitude: -80.1072,
    postal_code: '33427'
  },
  '33428': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3446,
    longitude: -80.2109,
    postal_code: '33428'
  },
  '33429': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3587,
    longitude: -80.0831,
    postal_code: '33429'
  },
  '33430': {
    country: 'US',
    city: 'Belle Glade',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6843,
    longitude: -80.6724,
    postal_code: '33430'
  },
  '33431': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3799,
    longitude: -80.0975,
    postal_code: '33431'
  },
  '33432': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3462,
    longitude: -80.0844,
    postal_code: '33432'
  },
  '33433': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3464,
    longitude: -80.1564,
    postal_code: '33433'
  },
  '33434': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3839,
    longitude: -80.1749,
    postal_code: '33434'
  },
  '33435': {
    country: 'US',
    city: 'Boynton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5254,
    longitude: -80.061,
    postal_code: '33435'
  },
  '33436': {
    country: 'US',
    city: 'Boynton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5354,
    longitude: -80.1124,
    postal_code: '33436'
  },
  '33437': {
    country: 'US',
    city: 'Boynton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5312,
    longitude: -80.1418,
    postal_code: '33437'
  },
  '33438': {
    country: 'US',
    city: 'Canal Point',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.8592,
    longitude: -80.6337,
    postal_code: '33438'
  },
  '33440': {
    country: 'US',
    city: 'Clewiston',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hendry',
    latitude: 26.7172,
    longitude: -80.9492,
    postal_code: '33440'
  },
  '33441': {
    country: 'US',
    city: 'Deerfield Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.3096,
    longitude: -80.0992,
    postal_code: '33441'
  },
  '33442': {
    country: 'US',
    city: 'Deerfield Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.3124,
    longitude: -80.1412,
    postal_code: '33442'
  },
  '33443': {
    country: 'US',
    city: 'Deerfield Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.3184,
    longitude: -80.0998,
    postal_code: '33443'
  },
  '33444': {
    country: 'US',
    city: 'Delray Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.4564,
    longitude: -80.0793,
    postal_code: '33444'
  },
  '33445': {
    country: 'US',
    city: 'Delray Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.4564,
    longitude: -80.1054,
    postal_code: '33445'
  },
  '33446': {
    country: 'US',
    city: 'Delray Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.4517,
    longitude: -80.158,
    postal_code: '33446'
  },
  '33448': {
    country: 'US',
    city: 'Delray Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.4615,
    longitude: -80.0728,
    postal_code: '33448'
  },
  '33449': {
    country: 'US',
    city: 'Lake Worth',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6048,
    longitude: -80.2149,
    postal_code: '33449'
  },
  '33454': {
    country: 'US',
    city: 'Greenacres',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6237,
    longitude: -80.1253,
    postal_code: '33454'
  },
  '33455': {
    country: 'US',
    city: 'Hobe Sound',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.0813,
    longitude: -80.1509,
    postal_code: '33455'
  },
  '33458': {
    country: 'US',
    city: 'Jupiter',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.9339,
    longitude: -80.1201,
    postal_code: '33458'
  },
  '33459': {
    country: 'US',
    city: 'Lake Harbor',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6931,
    longitude: -80.8145,
    postal_code: '33459'
  },
  '33460': {
    country: 'US',
    city: 'Lake Worth',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6182,
    longitude: -80.056,
    postal_code: '33460'
  },
  '33461': {
    country: 'US',
    city: 'Lake Worth',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6232,
    longitude: -80.0946,
    postal_code: '33461'
  },
  '33462': {
    country: 'US',
    city: 'Lake Worth',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5747,
    longitude: -80.0794,
    postal_code: '33462'
  },
  '33463': {
    country: 'US',
    city: 'Lake Worth',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5955,
    longitude: -80.1291,
    postal_code: '33463'
  },
  '33464': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3587,
    longitude: -80.0831,
    postal_code: '33464'
  },
  '33465': {
    country: 'US',
    city: 'Lake Worth',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6283,
    longitude: -80.1326,
    postal_code: '33465'
  },
  '33466': {
    country: 'US',
    city: 'Lake Worth',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6171,
    longitude: -80.0723,
    postal_code: '33466'
  },
  '33467': {
    country: 'US',
    city: 'Lake Worth',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6104,
    longitude: -80.1683,
    postal_code: '33467'
  },
  '33468': {
    country: 'US',
    city: 'Jupiter',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.9342,
    longitude: -80.0942,
    postal_code: '33468'
  },
  '33469': {
    country: 'US',
    city: 'Jupiter',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.9831,
    longitude: -80.108,
    postal_code: '33469'
  },
  '33470': {
    country: 'US',
    city: 'Loxahatchee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7383,
    longitude: -80.276,
    postal_code: '33470'
  },
  '33471': {
    country: 'US',
    city: 'Moore Haven',
    state: 'Florida',
    state_short: 'FL',
    county: 'Glades',
    latitude: 26.8327,
    longitude: -81.2188,
    postal_code: '33471'
  },
  '33472': {
    country: 'US',
    city: 'Boynton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5384,
    longitude: -80.1856,
    postal_code: '33472'
  },
  '33473': {
    country: 'US',
    city: 'Boynton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5088,
    longitude: -80.1896,
    postal_code: '33473'
  },
  '33474': {
    country: 'US',
    city: 'Boynton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.5253,
    longitude: -80.0664,
    postal_code: '33474'
  },
  '33475': {
    country: 'US',
    city: 'Hobe Sound',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.0595,
    longitude: -80.1364,
    postal_code: '33475'
  },
  '33476': {
    country: 'US',
    city: 'Pahokee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.8142,
    longitude: -80.6629,
    postal_code: '33476'
  },
  '33477': {
    country: 'US',
    city: 'Jupiter',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.9217,
    longitude: -80.077,
    postal_code: '33477'
  },
  '33478': {
    country: 'US',
    city: 'Jupiter',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.9212,
    longitude: -80.2144,
    postal_code: '33478'
  },
  '33480': {
    country: 'US',
    city: 'Palm Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.7206,
    longitude: -80.0388,
    postal_code: '33480'
  },
  '33481': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3587,
    longitude: -80.0831,
    postal_code: '33481'
  },
  '33482': {
    country: 'US',
    city: 'Delray Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.4615,
    longitude: -80.0728,
    postal_code: '33482'
  },
  '33483': {
    country: 'US',
    city: 'Delray Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.4546,
    longitude: -80.0656,
    postal_code: '33483'
  },
  '33484': {
    country: 'US',
    city: 'Delray Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.4543,
    longitude: -80.1346,
    postal_code: '33484'
  },
  '33486': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3481,
    longitude: -80.1104,
    postal_code: '33486'
  },
  '33487': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.4116,
    longitude: -80.0928,
    postal_code: '33487'
  },
  '33488': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3587,
    longitude: -80.0831,
    postal_code: '33488'
  },
  '33493': {
    country: 'US',
    city: 'South Bay',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.6701,
    longitude: -80.7312,
    postal_code: '33493'
  },
  '33496': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.403,
    longitude: -80.1813,
    postal_code: '33496'
  },
  '33497': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3587,
    longitude: -80.0831,
    postal_code: '33497'
  },
  '33498': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3907,
    longitude: -80.2161,
    postal_code: '33498'
  },
  '33499': {
    country: 'US',
    city: 'Boca Raton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Palm Beach',
    latitude: 26.3587,
    longitude: -80.0831,
    postal_code: '33499'
  },
  '33503': {
    country: 'US',
    city: 'Balm',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.7648,
    longitude: -82.2734,
    postal_code: '33503'
  },
  '33508': {
    country: 'US',
    city: 'Brandon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9318,
    longitude: -82.295,
    postal_code: '33508'
  },
  '33509': {
    country: 'US',
    city: 'Brandon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9378,
    longitude: -82.2859,
    postal_code: '33509'
  },
  '33510': {
    country: 'US',
    city: 'Brandon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9551,
    longitude: -82.2966,
    postal_code: '33510'
  },
  '33511': {
    country: 'US',
    city: 'Brandon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9056,
    longitude: -82.2881,
    postal_code: '33511'
  },
  '33513': {
    country: 'US',
    city: 'Bushnell',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sumter',
    latitude: 28.6611,
    longitude: -82.1553,
    postal_code: '33513'
  },
  '33514': {
    country: 'US',
    city: 'Center Hill',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sumter',
    latitude: 28.6635,
    longitude: -81.9963,
    postal_code: '33514'
  },
  '33521': {
    country: 'US',
    city: 'Coleman',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sumter',
    latitude: 28.7997,
    longitude: -82.0701,
    postal_code: '33521'
  },
  '33523': {
    country: 'US',
    city: 'Dade City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.4247,
    longitude: -82.2185,
    postal_code: '33523'
  },
  '33524': {
    country: 'US',
    city: 'Crystal Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.1822,
    longitude: -82.1523,
    postal_code: '33524'
  },
  '33525': {
    country: 'US',
    city: 'Dade City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.3318,
    longitude: -82.2446,
    postal_code: '33525'
  },
  '33526': {
    country: 'US',
    city: 'Dade City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.3101,
    longitude: -82.2478,
    postal_code: '33526'
  },
  '33527': {
    country: 'US',
    city: 'Dover',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.992,
    longitude: -82.2138,
    postal_code: '33527'
  },
  '33530': {
    country: 'US',
    city: 'Durant',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9068,
    longitude: -82.1767,
    postal_code: '33530'
  },
  '33534': {
    country: 'US',
    city: 'Gibsonton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.8411,
    longitude: -82.3698,
    postal_code: '33534'
  },
  '33537': {
    country: 'US',
    city: 'Lacoochee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.4658,
    longitude: -82.172,
    postal_code: '33537'
  },
  '33538': {
    country: 'US',
    city: 'Lake Panasoffkee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sumter',
    latitude: 28.7953,
    longitude: -82.1363,
    postal_code: '33538'
  },
  '33539': {
    country: 'US',
    city: 'Zephyrhills',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.213,
    longitude: -82.1657,
    postal_code: '33539'
  },
  '33540': {
    country: 'US',
    city: 'Zephyrhills',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2151,
    longitude: -82.1506,
    postal_code: '33540'
  },
  '33541': {
    country: 'US',
    city: 'Zephyrhills',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2311,
    longitude: -82.2057,
    postal_code: '33541'
  },
  '33542': {
    country: 'US',
    city: 'Zephyrhills',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2303,
    longitude: -82.1779,
    postal_code: '33542'
  },
  '33543': {
    country: 'US',
    city: 'Wesley Chapel',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2397,
    longitude: -82.3279,
    postal_code: '33543'
  },
  '33544': {
    country: 'US',
    city: 'Wesley Chapel',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2397,
    longitude: -82.3279,
    postal_code: '33544'
  },
  '33545': {
    country: 'US',
    city: 'Wesley Chapel',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2697,
    longitude: -82.2903,
    postal_code: '33545'
  },
  '33547': {
    country: 'US',
    city: 'Lithia',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.8293,
    longitude: -82.1357,
    postal_code: '33547'
  },
  '33548': {
    country: 'US',
    city: 'Lutz',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.1385,
    longitude: -82.4821,
    postal_code: '33548'
  },
  '33549': {
    country: 'US',
    city: 'Lutz',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.1367,
    longitude: -82.461,
    postal_code: '33549'
  },
  '33550': {
    country: 'US',
    city: 'Mango',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9797,
    longitude: -82.3065,
    postal_code: '33550'
  },
  '33556': {
    country: 'US',
    city: 'Odessa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.1421,
    longitude: -82.5905,
    postal_code: '33556'
  },
  '33558': {
    country: 'US',
    city: 'Lutz',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.1474,
    longitude: -82.5152,
    postal_code: '33558'
  },
  '33559': {
    country: 'US',
    city: 'Lutz',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.1801,
    longitude: -82.4169,
    postal_code: '33559'
  },
  '33563': {
    country: 'US',
    city: 'Plant City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.013,
    longitude: -82.1339,
    postal_code: '33563'
  },
  '33564': {
    country: 'US',
    city: 'Plant City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0296,
    longitude: -82.1347,
    postal_code: '33564'
  },
  '33565': {
    country: 'US',
    city: 'Plant City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0699,
    longitude: -82.1576,
    postal_code: '33565'
  },
  '33566': {
    country: 'US',
    city: 'Plant City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0094,
    longitude: -82.1138,
    postal_code: '33566'
  },
  '33567': {
    country: 'US',
    city: 'Plant City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.922,
    longitude: -82.1216,
    postal_code: '33567'
  },
  '33568': {
    country: 'US',
    city: 'Riverview',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33568'
  },
  '33569': {
    country: 'US',
    city: 'Riverview',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.845,
    longitude: -82.3125,
    postal_code: '33569'
  },
  '33570': {
    country: 'US',
    city: 'Ruskin',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.7015,
    longitude: -82.4355,
    postal_code: '33570'
  },
  '33571': {
    country: 'US',
    city: 'Sun City Center',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.7201,
    longitude: -82.453,
    postal_code: '33571'
  },
  '33572': {
    country: 'US',
    city: 'Apollo Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.7716,
    longitude: -82.4102,
    postal_code: '33572'
  },
  '33573': {
    country: 'US',
    city: 'Sun City Center',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.7147,
    longitude: -82.3538,
    postal_code: '33573'
  },
  '33574': {
    country: 'US',
    city: 'Saint Leo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.3348,
    longitude: -82.2693,
    postal_code: '33574'
  },
  '33575': {
    country: 'US',
    city: 'Ruskin',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.7144,
    longitude: -82.4291,
    postal_code: '33575'
  },
  '33576': {
    country: 'US',
    city: 'San Antonio',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.3371,
    longitude: -82.2882,
    postal_code: '33576'
  },
  '33578': {
    country: 'US',
    city: 'Riverview',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 27.8633,
    longitude: -82.3499,
    postal_code: '33578'
  },
  '33579': {
    country: 'US',
    city: 'Riverview',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.8024,
    longitude: -82.2755,
    postal_code: '33579'
  },
  '33583': {
    country: 'US',
    city: 'Seffner',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33583'
  },
  '33584': {
    country: 'US',
    city: 'Seffner',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9922,
    longitude: -82.2863,
    postal_code: '33584'
  },
  '33585': {
    country: 'US',
    city: 'Sumterville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sumter',
    latitude: 28.7356,
    longitude: -82.0616,
    postal_code: '33585'
  },
  '33586': {
    country: 'US',
    city: 'Sun City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.6784,
    longitude: -82.4787,
    postal_code: '33586'
  },
  '33587': {
    country: 'US',
    city: 'Sydney',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9634,
    longitude: -82.2073,
    postal_code: '33587'
  },
  '33592': {
    country: 'US',
    city: 'Thonotosassa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0617,
    longitude: -82.3082,
    postal_code: '33592'
  },
  '33593': {
    country: 'US',
    city: 'Trilby',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.4625,
    longitude: -82.1948,
    postal_code: '33593'
  },
  '33594': {
    country: 'US',
    city: 'Valrico',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9408,
    longitude: -82.242,
    postal_code: '33594'
  },
  '33595': {
    country: 'US',
    city: 'Valrico',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9379,
    longitude: -82.2364,
    postal_code: '33595'
  },
  '33596': {
    country: 'US',
    city: 'Valrico',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.8925,
    longitude: -82.243,
    postal_code: '33596'
  },
  '33597': {
    country: 'US',
    city: 'Webster',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sumter',
    latitude: 28.549,
    longitude: -82.0805,
    postal_code: '33597'
  },
  '33598': {
    country: 'US',
    city: 'Wimauma',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.7015,
    longitude: -82.3151,
    postal_code: '33598'
  },
  '33601': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9961,
    longitude: -82.582,
    postal_code: '33601'
  },
  '33602': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9614,
    longitude: -82.4597,
    postal_code: '33602'
  },
  '33603': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9845,
    longitude: -82.463,
    postal_code: '33603'
  },
  '33604': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0173,
    longitude: -82.4578,
    postal_code: '33604'
  },
  '33605': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9671,
    longitude: -82.4334,
    postal_code: '33605'
  },
  '33606': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9475,
    longitude: -82.4584,
    postal_code: '33606'
  },
  '33607': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9625,
    longitude: -82.4895,
    postal_code: '33607'
  },
  '33608': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.8434,
    longitude: -82.4884,
    postal_code: '33608'
  },
  '33609': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9425,
    longitude: -82.5057,
    postal_code: '33609'
  },
  '33610': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9951,
    longitude: -82.4046,
    postal_code: '33610'
  },
  '33611': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.8914,
    longitude: -82.5067,
    postal_code: '33611'
  },
  '33612': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0502,
    longitude: -82.45,
    postal_code: '33612'
  },
  '33613': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0772,
    longitude: -82.4455,
    postal_code: '33613'
  },
  '33614': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0091,
    longitude: -82.5034,
    postal_code: '33614'
  },
  '33615': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0081,
    longitude: -82.5805,
    postal_code: '33615'
  },
  '33616': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.8742,
    longitude: -82.5203,
    postal_code: '33616'
  },
  '33617': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0384,
    longitude: -82.3949,
    postal_code: '33617'
  },
  '33618': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0763,
    longitude: -82.4852,
    postal_code: '33618'
  },
  '33619': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9382,
    longitude: -82.3756,
    postal_code: '33619'
  },
  '33620': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.06,
    longitude: -82.4079,
    postal_code: '33620'
  },
  '33621': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.8491,
    longitude: -82.4946,
    postal_code: '33621'
  },
  '33622': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9475,
    longitude: -82.4584,
    postal_code: '33622'
  },
  '33623': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9475,
    longitude: -82.4584,
    postal_code: '33623'
  },
  '33624': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.079,
    longitude: -82.5268,
    postal_code: '33624'
  },
  '33625': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0726,
    longitude: -82.559,
    postal_code: '33625'
  },
  '33626': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0509,
    longitude: -82.6164,
    postal_code: '33626'
  },
  '33629': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.921,
    longitude: -82.5079,
    postal_code: '33629'
  },
  '33630': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33630'
  },
  '33631': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33631'
  },
  '33633': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33633'
  },
  '33634': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0068,
    longitude: -82.556,
    postal_code: '33634'
  },
  '33635': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0301,
    longitude: -82.6048,
    postal_code: '33635'
  },
  '33637': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.0338,
    longitude: -82.3659,
    postal_code: '33637'
  },
  '33646': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.094,
    longitude: -82.4021,
    postal_code: '33646'
  },
  '33647': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 28.1147,
    longitude: -82.3678,
    postal_code: '33647'
  },
  '33650': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33650'
  },
  '33655': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9475,
    longitude: -82.4584,
    postal_code: '33655'
  },
  '33660': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33660'
  },
  '33661': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33661'
  },
  '33662': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33662'
  },
  '33663': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33663'
  },
  '33664': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33664'
  },
  '33672': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9475,
    longitude: -82.4584,
    postal_code: '33672'
  },
  '33673': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33673'
  },
  '33674': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33674'
  },
  '33675': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33675'
  },
  '33677': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33677'
  },
  '33679': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33679'
  },
  '33680': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33680'
  },
  '33681': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33681'
  },
  '33682': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33682'
  },
  '33684': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33684'
  },
  '33685': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33685'
  },
  '33686': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33686'
  },
  '33687': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33687'
  },
  '33688': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33688'
  },
  '33689': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.9475,
    longitude: -82.4584,
    postal_code: '33689'
  },
  '33694': {
    country: 'US',
    city: 'Tampa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hillsborough',
    latitude: 27.872,
    longitude: -82.4388,
    postal_code: '33694'
  },
  '33701': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.7723,
    longitude: -82.6386,
    postal_code: '33701'
  },
  '33702': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8427,
    longitude: -82.6448,
    postal_code: '33702'
  },
  '33703': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.817,
    longitude: -82.6264,
    postal_code: '33703'
  },
  '33704': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.7954,
    longitude: -82.6373,
    postal_code: '33704'
  },
  '33705': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.7391,
    longitude: -82.6435,
    postal_code: '33705'
  },
  '33706': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.7456,
    longitude: -82.7516,
    postal_code: '33706'
  },
  '33707': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.7549,
    longitude: -82.7208,
    postal_code: '33707'
  },
  '33708': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8116,
    longitude: -82.8014,
    postal_code: '33708'
  },
  '33709': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8201,
    longitude: -82.7308,
    postal_code: '33709'
  },
  '33710': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.7898,
    longitude: -82.7243,
    postal_code: '33710'
  },
  '33711': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.7465,
    longitude: -82.6897,
    postal_code: '33711'
  },
  '33712': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.7353,
    longitude: -82.6663,
    postal_code: '33712'
  },
  '33713': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.789,
    longitude: -82.6779,
    postal_code: '33713'
  },
  '33714': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8176,
    longitude: -82.6776,
    postal_code: '33714'
  },
  '33715': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.6705,
    longitude: -82.7119,
    postal_code: '33715'
  },
  '33716': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8738,
    longitude: -82.64,
    postal_code: '33716'
  },
  '33729': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8819,
    longitude: -82.6644,
    postal_code: '33729'
  },
  '33730': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.7709,
    longitude: -82.6793,
    postal_code: '33730'
  },
  '33731': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33731'
  },
  '33732': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33732'
  },
  '33733': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.7709,
    longitude: -82.6793,
    postal_code: '33733'
  },
  '33734': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33734'
  },
  '33736': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33736'
  },
  '33737': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33737'
  },
  '33738': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33738'
  },
  '33740': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33740'
  },
  '33741': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33741'
  },
  '33742': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33742'
  },
  '33743': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33743'
  },
  '33744': {
    country: 'US',
    city: 'Bay Pines',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8142,
    longitude: -82.7782,
    postal_code: '33744'
  },
  '33747': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33747'
  },
  '33755': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.9781,
    longitude: -82.7815,
    postal_code: '33755'
  },
  '33756': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.947,
    longitude: -82.7943,
    postal_code: '33756'
  },
  '33757': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33757'
  },
  '33758': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33758'
  },
  '33759': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.975,
    longitude: -82.7019,
    postal_code: '33759'
  },
  '33760': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.9004,
    longitude: -82.7152,
    postal_code: '33760'
  },
  '33761': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.031,
    longitude: -82.7239,
    postal_code: '33761'
  },
  '33762': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8942,
    longitude: -82.6746,
    postal_code: '33762'
  },
  '33763': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.0173,
    longitude: -82.7461,
    postal_code: '33763'
  },
  '33764': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.916,
    longitude: -82.7343,
    postal_code: '33764'
  },
  '33765': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.9902,
    longitude: -82.7433,
    postal_code: '33765'
  },
  '33766': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33766'
  },
  '33767': {
    country: 'US',
    city: 'Clearwater Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.9598,
    longitude: -82.8286,
    postal_code: '33767'
  },
  '33769': {
    country: 'US',
    city: 'Clearwater',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33769'
  },
  '33770': {
    country: 'US',
    city: 'Largo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.917,
    longitude: -82.8027,
    postal_code: '33770'
  },
  '33771': {
    country: 'US',
    city: 'Largo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.9085,
    longitude: -82.7568,
    postal_code: '33771'
  },
  '33772': {
    country: 'US',
    city: 'Seminole',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8466,
    longitude: -82.7954,
    postal_code: '33772'
  },
  '33773': {
    country: 'US',
    city: 'Largo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8802,
    longitude: -82.7534,
    postal_code: '33773'
  },
  '33774': {
    country: 'US',
    city: 'Largo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8839,
    longitude: -82.8265,
    postal_code: '33774'
  },
  '33775': {
    country: 'US',
    city: 'Seminole',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8397,
    longitude: -82.7912,
    postal_code: '33775'
  },
  '33776': {
    country: 'US',
    city: 'Seminole',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8505,
    longitude: -82.8263,
    postal_code: '33776'
  },
  '33777': {
    country: 'US',
    city: 'Seminole',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8688,
    longitude: -82.7344,
    postal_code: '33777'
  },
  '33778': {
    country: 'US',
    city: 'Largo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.884,
    longitude: -82.8025,
    postal_code: '33778'
  },
  '33779': {
    country: 'US',
    city: 'Largo',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8397,
    longitude: -82.7725,
    postal_code: '33779'
  },
  '33780': {
    country: 'US',
    city: 'Pinellas Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33780'
  },
  '33781': {
    country: 'US',
    city: 'Pinellas Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8387,
    longitude: -82.7151,
    postal_code: '33781'
  },
  '33782': {
    country: 'US',
    city: 'Pinellas Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8681,
    longitude: -82.7086,
    postal_code: '33782'
  },
  '33784': {
    country: 'US',
    city: 'Saint Petersburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '33784'
  },
  '33785': {
    country: 'US',
    city: 'Indian Rocks Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8868,
    longitude: -82.8435,
    postal_code: '33785'
  },
  '33786': {
    country: 'US',
    city: 'Belleair Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.9229,
    longitude: -82.8393,
    postal_code: '33786'
  },
  '33801': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0381,
    longitude: -81.9392,
    postal_code: '33801'
  },
  '33802': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.021,
    longitude: -81.9852,
    postal_code: '33802'
  },
  '33803': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.014,
    longitude: -81.9523,
    postal_code: '33803'
  },
  '33804': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0395,
    longitude: -81.9498,
    postal_code: '33804'
  },
  '33805': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.072,
    longitude: -81.9609,
    postal_code: '33805'
  },
  '33806': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0395,
    longitude: -81.9498,
    postal_code: '33806'
  },
  '33807': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0395,
    longitude: -81.9498,
    postal_code: '33807'
  },
  '33809': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.1762,
    longitude: -81.9591,
    postal_code: '33809'
  },
  '33810': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.1479,
    longitude: -82.0372,
    postal_code: '33810'
  },
  '33811': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.9865,
    longitude: -82.0139,
    postal_code: '33811'
  },
  '33812': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.9729,
    longitude: -81.8931,
    postal_code: '33812'
  },
  '33813': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.9611,
    longitude: -81.9398,
    postal_code: '33813'
  },
  '33815': {
    country: 'US',
    city: 'Lakeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0496,
    longitude: -82.0069,
    postal_code: '33815'
  },
  '33820': {
    country: 'US',
    city: 'Alturas',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0026,
    longitude: -81.6186,
    postal_code: '33820'
  },
  '33823': {
    country: 'US',
    city: 'Auburndale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0724,
    longitude: -81.8122,
    postal_code: '33823'
  },
  '33825': {
    country: 'US',
    city: 'Avon Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.6001,
    longitude: -81.5015,
    postal_code: '33825'
  },
  '33826': {
    country: 'US',
    city: 'Avon Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.5959,
    longitude: -81.5062,
    postal_code: '33826'
  },
  '33827': {
    country: 'US',
    city: 'Babson Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.8163,
    longitude: -81.5139,
    postal_code: '33827'
  },
  '33830': {
    country: 'US',
    city: 'Bartow',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.8957,
    longitude: -81.8127,
    postal_code: '33830'
  },
  '33831': {
    country: 'US',
    city: 'Bartow',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.8964,
    longitude: -81.8431,
    postal_code: '33831'
  },
  '33834': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hardee',
    latitude: 27.6019,
    longitude: -81.8507,
    postal_code: '33834'
  },
  '33835': {
    country: 'US',
    city: 'Bradley',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.6993,
    longitude: -81.9494,
    postal_code: '33835'
  },
  '33836': {
    country: 'US',
    city: 'Davenport',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.1672,
    longitude: -81.6316,
    postal_code: '33836'
  },
  '33837': {
    country: 'US',
    city: 'Davenport',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.1963,
    longitude: -81.6079,
    postal_code: '33837'
  },
  '33838': {
    country: 'US',
    city: 'Dundee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0194,
    longitude: -81.6212,
    postal_code: '33838'
  },
  '33839': {
    country: 'US',
    city: 'Eagle Lake',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.9787,
    longitude: -81.7564,
    postal_code: '33839'
  },
  '33840': {
    country: 'US',
    city: 'Eaton Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0086,
    longitude: -81.9076,
    postal_code: '33840'
  },
  '33841': {
    country: 'US',
    city: 'Fort Meade',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.7464,
    longitude: -81.7823,
    postal_code: '33841'
  },
  '33843': {
    country: 'US',
    city: 'Frostproof',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.7211,
    longitude: -81.5148,
    postal_code: '33843'
  },
  '33844': {
    country: 'US',
    city: 'Haines City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.1145,
    longitude: -81.6201,
    postal_code: '33844'
  },
  '33845': {
    country: 'US',
    city: 'Haines City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0026,
    longitude: -81.6186,
    postal_code: '33845'
  },
  '33846': {
    country: 'US',
    city: 'Highland City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.9647,
    longitude: -81.8672,
    postal_code: '33846'
  },
  '33847': {
    country: 'US',
    city: 'Homeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.8178,
    longitude: -81.8245,
    postal_code: '33847'
  },
  '33848': {
    country: 'US',
    city: 'Intercession City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.2774,
    longitude: -81.5069,
    postal_code: '33848'
  },
  '33849': {
    country: 'US',
    city: 'Kathleen',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.1975,
    longitude: -82.0396,
    postal_code: '33849'
  },
  '33850': {
    country: 'US',
    city: 'Lake Alfred',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0895,
    longitude: -81.7271,
    postal_code: '33850'
  },
  '33851': {
    country: 'US',
    city: 'Lake Hamilton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0369,
    longitude: -81.628,
    postal_code: '33851'
  },
  '33852': {
    country: 'US',
    city: 'Lake Placid',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.2945,
    longitude: -81.3649,
    postal_code: '33852'
  },
  '33853': {
    country: 'US',
    city: 'Lake Wales',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.9002,
    longitude: -81.5847,
    postal_code: '33853'
  },
  '33854': {
    country: 'US',
    city: 'Lakeshore',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.966,
    longitude: -81.6965,
    postal_code: '33854'
  },
  '33855': {
    country: 'US',
    city: 'Indian Lake Estates',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.798,
    longitude: -81.3572,
    postal_code: '33855'
  },
  '33856': {
    country: 'US',
    city: 'Nalcrest',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.8557,
    longitude: -81.4309,
    postal_code: '33856'
  },
  '33857': {
    country: 'US',
    city: 'Lorida',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.415,
    longitude: -81.1965,
    postal_code: '33857'
  },
  '33858': {
    country: 'US',
    city: 'Loughman',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.242,
    longitude: -81.5667,
    postal_code: '33858'
  },
  '33859': {
    country: 'US',
    city: 'Lake Wales',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.8774,
    longitude: -81.6221,
    postal_code: '33859'
  },
  '33860': {
    country: 'US',
    city: 'Mulberry',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.902,
    longitude: -82.0015,
    postal_code: '33860'
  },
  '33862': {
    country: 'US',
    city: 'Lake Placid',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.2931,
    longitude: -81.3629,
    postal_code: '33862'
  },
  '33863': {
    country: 'US',
    city: 'Nichols',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.8903,
    longitude: -82.0315,
    postal_code: '33863'
  },
  '33865': {
    country: 'US',
    city: 'Ona',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hardee',
    latitude: 27.4127,
    longitude: -81.928,
    postal_code: '33865'
  },
  '33867': {
    country: 'US',
    city: 'River Ranch',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.7686,
    longitude: -81.1966,
    postal_code: '33867'
  },
  '33868': {
    country: 'US',
    city: 'Polk City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.1987,
    longitude: -81.8083,
    postal_code: '33868'
  },
  '33870': {
    country: 'US',
    city: 'Sebring',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.4924,
    longitude: -81.4357,
    postal_code: '33870'
  },
  '33871': {
    country: 'US',
    city: 'Sebring',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.4858,
    longitude: -81.4079,
    postal_code: '33871'
  },
  '33872': {
    country: 'US',
    city: 'Sebring',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.4703,
    longitude: -81.4872,
    postal_code: '33872'
  },
  '33873': {
    country: 'US',
    city: 'Wauchula',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hardee',
    latitude: 27.5517,
    longitude: -81.8074,
    postal_code: '33873'
  },
  '33875': {
    country: 'US',
    city: 'Sebring',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.4676,
    longitude: -81.4581,
    postal_code: '33875'
  },
  '33876': {
    country: 'US',
    city: 'Sebring',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.4287,
    longitude: -81.3519,
    postal_code: '33876'
  },
  '33877': {
    country: 'US',
    city: 'Waverly',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.9769,
    longitude: -81.6144,
    postal_code: '33877'
  },
  '33880': {
    country: 'US',
    city: 'Winter Haven',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.9873,
    longitude: -81.7625,
    postal_code: '33880'
  },
  '33881': {
    country: 'US',
    city: 'Winter Haven',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0452,
    longitude: -81.7325,
    postal_code: '33881'
  },
  '33882': {
    country: 'US',
    city: 'Winter Haven',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0294,
    longitude: -81.7321,
    postal_code: '33882'
  },
  '33883': {
    country: 'US',
    city: 'Winter Haven',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0222,
    longitude: -81.7329,
    postal_code: '33883'
  },
  '33884': {
    country: 'US',
    city: 'Winter Haven',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.981,
    longitude: -81.6736,
    postal_code: '33884'
  },
  '33885': {
    country: 'US',
    city: 'Winter Haven',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0026,
    longitude: -81.6186,
    postal_code: '33885'
  },
  '33888': {
    country: 'US',
    city: 'Winter Haven',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0231,
    longitude: -81.7234,
    postal_code: '33888'
  },
  '33890': {
    country: 'US',
    city: 'Zolfo Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hardee',
    latitude: 27.48,
    longitude: -81.7423,
    postal_code: '33890'
  },
  '33896': {
    country: 'US',
    city: 'Davenport',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.2531,
    longitude: -81.6509,
    postal_code: '33896'
  },
  '33897': {
    country: 'US',
    city: 'Davenport',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.3112,
    longitude: -81.6643,
    postal_code: '33897'
  },
  '33898': {
    country: 'US',
    city: 'Lake Wales',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 27.8643,
    longitude: -81.5719,
    postal_code: '33898'
  },
  '33901': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6204,
    longitude: -81.8725,
    postal_code: '33901'
  },
  '33902': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6239,
    longitude: -81.8836,
    postal_code: '33902'
  },
  '33903': {
    country: 'US',
    city: 'North Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.693,
    longitude: -81.9125,
    postal_code: '33903'
  },
  '33904': {
    country: 'US',
    city: 'Cape Coral',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6065,
    longitude: -81.9502,
    postal_code: '33904'
  },
  '33905': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6693,
    longitude: -81.7605,
    postal_code: '33905'
  },
  '33906': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5529,
    longitude: -81.9486,
    postal_code: '33906'
  },
  '33907': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5681,
    longitude: -81.8736,
    postal_code: '33907'
  },
  '33908': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5025,
    longitude: -81.9276,
    postal_code: '33908'
  },
  '33909': {
    country: 'US',
    city: 'Cape Coral',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6939,
    longitude: -81.9452,
    postal_code: '33909'
  },
  '33910': {
    country: 'US',
    city: 'Cape Coral',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5529,
    longitude: -81.9486,
    postal_code: '33910'
  },
  '33911': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5963,
    longitude: -81.8824,
    postal_code: '33911'
  },
  '33912': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.4972,
    longitude: -81.8246,
    postal_code: '33912'
  },
  '33913': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5228,
    longitude: -81.7065,
    postal_code: '33913'
  },
  '33914': {
    country: 'US',
    city: 'Cape Coral',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5557,
    longitude: -82.0206,
    postal_code: '33914'
  },
  '33915': {
    country: 'US',
    city: 'Cape Coral',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6599,
    longitude: -81.8934,
    postal_code: '33915'
  },
  '33916': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6466,
    longitude: -81.8429,
    postal_code: '33916'
  },
  '33917': {
    country: 'US',
    city: 'North Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.7357,
    longitude: -81.8435,
    postal_code: '33917'
  },
  '33918': {
    country: 'US',
    city: 'North Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6673,
    longitude: -81.8801,
    postal_code: '33918'
  },
  '33919': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5567,
    longitude: -81.9034,
    postal_code: '33919'
  },
  '33920': {
    country: 'US',
    city: 'Alva',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.7147,
    longitude: -81.6351,
    postal_code: '33920'
  },
  '33921': {
    country: 'US',
    city: 'Boca Grande',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.7545,
    longitude: -82.2611,
    postal_code: '33921'
  },
  '33922': {
    country: 'US',
    city: 'Bokeelia',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6627,
    longitude: -82.1401,
    postal_code: '33922'
  },
  '33924': {
    country: 'US',
    city: 'Captiva',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5215,
    longitude: -82.1802,
    postal_code: '33924'
  },
  '33927': {
    country: 'US',
    city: 'El Jobean',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.9324,
    longitude: -82.2168,
    postal_code: '33927'
  },
  '33928': {
    country: 'US',
    city: 'Estero',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.4351,
    longitude: -81.8102,
    postal_code: '33928'
  },
  '33929': {
    country: 'US',
    city: 'Estero',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.4381,
    longitude: -81.8068,
    postal_code: '33929'
  },
  '33930': {
    country: 'US',
    city: 'Felda',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hendry',
    latitude: 26.5398,
    longitude: -81.4356,
    postal_code: '33930'
  },
  '33931': {
    country: 'US',
    city: 'Fort Myers Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.4527,
    longitude: -81.9501,
    postal_code: '33931'
  },
  '33932': {
    country: 'US',
    city: 'Fort Myers Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5529,
    longitude: -81.9486,
    postal_code: '33932'
  },
  '33935': {
    country: 'US',
    city: 'Labelle',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hendry',
    latitude: 26.7321,
    longitude: -81.434,
    postal_code: '33935'
  },
  '33936': {
    country: 'US',
    city: 'Lehigh Acres',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5936,
    longitude: -81.6619,
    postal_code: '33936'
  },
  '33938': {
    country: 'US',
    city: 'Murdock',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.902,
    longitude: -82,
    postal_code: '33938'
  },
  '33944': {
    country: 'US',
    city: 'Palmdale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Glades',
    latitude: 26.9464,
    longitude: -81.3091,
    postal_code: '33944'
  },
  '33945': {
    country: 'US',
    city: 'Pineland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6583,
    longitude: -82.1434,
    postal_code: '33945'
  },
  '33946': {
    country: 'US',
    city: 'Placida',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.8323,
    longitude: -82.2648,
    postal_code: '33946'
  },
  '33947': {
    country: 'US',
    city: 'Rotonda West',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.8842,
    longitude: -82.2691,
    postal_code: '33947'
  },
  '33948': {
    country: 'US',
    city: 'Port Charlotte',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.9827,
    longitude: -82.1412,
    postal_code: '33948'
  },
  '33949': {
    country: 'US',
    city: 'Port Charlotte',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.9939,
    longitude: -82.0984,
    postal_code: '33949'
  },
  '33950': {
    country: 'US',
    city: 'Punta Gorda',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.9152,
    longitude: -82.0532,
    postal_code: '33950'
  },
  '33951': {
    country: 'US',
    city: 'Punta Gorda',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.9708,
    longitude: -81.9845,
    postal_code: '33951'
  },
  '33952': {
    country: 'US',
    city: 'Port Charlotte',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.9905,
    longitude: -82.0964,
    postal_code: '33952'
  },
  '33953': {
    country: 'US',
    city: 'Port Charlotte',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 27.004,
    longitude: -82.2117,
    postal_code: '33953'
  },
  '33954': {
    country: 'US',
    city: 'Port Charlotte',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 27.0228,
    longitude: -82.1108,
    postal_code: '33954'
  },
  '33955': {
    country: 'US',
    city: 'Punta Gorda',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.824,
    longitude: -81.9547,
    postal_code: '33955'
  },
  '33956': {
    country: 'US',
    city: 'Saint James City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.529,
    longitude: -82.0916,
    postal_code: '33956'
  },
  '33957': {
    country: 'US',
    city: 'Sanibel',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.4514,
    longitude: -82.0868,
    postal_code: '33957'
  },
  '33960': {
    country: 'US',
    city: 'Venus',
    state: 'Florida',
    state_short: 'FL',
    county: 'Highlands',
    latitude: 27.1203,
    longitude: -81.3909,
    postal_code: '33960'
  },
  '33965': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.4637,
    longitude: -81.7722,
    postal_code: '33965'
  },
  '33966': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5824,
    longitude: -81.832,
    postal_code: '33966'
  },
  '33967': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 26.4722,
    longitude: -81.8122,
    postal_code: '33967'
  },
  '33970': {
    country: 'US',
    city: 'Lehigh Acres',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5647,
    longitude: -81.6208,
    postal_code: '33970'
  },
  '33971': {
    country: 'US',
    city: 'Lehigh Acres',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6388,
    longitude: -81.6992,
    postal_code: '33971'
  },
  '33972': {
    country: 'US',
    city: 'Lehigh Acres',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6492,
    longitude: -81.6167,
    postal_code: '33972'
  },
  '33973': {
    country: 'US',
    city: 'Lehigh Acres',
    state: 'Florida',
    state_short: 'FL',
    county: 'Broward',
    latitude: 26.602,
    longitude: -81.7311,
    postal_code: '33973'
  },
  '33974': {
    country: 'US',
    city: 'Lehigh Acres',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5677,
    longitude: -81.5954,
    postal_code: '33974'
  },
  '33975': {
    country: 'US',
    city: 'Labelle',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hendry',
    latitude: 26.7633,
    longitude: -81.4388,
    postal_code: '33975'
  },
  '33976': {
    country: 'US',
    city: 'Lehigh Acres',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5952,
    longitude: -81.6849,
    postal_code: '33976'
  },
  '33980': {
    country: 'US',
    city: 'Punta Gorda',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.9298,
    longitude: -82.0454,
    postal_code: '33980'
  },
  '33981': {
    country: 'US',
    city: 'Port Charlotte',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.9379,
    longitude: -82.2388,
    postal_code: '33981'
  },
  '33982': {
    country: 'US',
    city: 'Punta Gorda',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 26.9668,
    longitude: -81.9545,
    postal_code: '33982'
  },
  '33983': {
    country: 'US',
    city: 'Punta Gorda',
    state: 'Florida',
    state_short: 'FL',
    county: 'Charlotte',
    latitude: 27.0074,
    longitude: -82.0163,
    postal_code: '33983'
  },
  '33990': {
    country: 'US',
    city: 'Cape Coral',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6265,
    longitude: -81.9677,
    postal_code: '33990'
  },
  '33991': {
    country: 'US',
    city: 'Cape Coral',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6281,
    longitude: -82.0182,
    postal_code: '33991'
  },
  '33993': {
    country: 'US',
    city: 'Cape Coral',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.6786,
    longitude: -82.0254,
    postal_code: '33993'
  },
  '33994': {
    country: 'US',
    city: 'Fort Myers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.5529,
    longitude: -81.9486,
    postal_code: '33994'
  },
  '34002': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 8.9733,
    longitude: -79.5556,
    postal_code: '34002'
  },
  '34003': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 8.95,
    longitude: -79.55,
    postal_code: '34003'
  },
  '34004': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 9,
    longitude: -79.75,
    postal_code: '34004'
  },
  '34005': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 9.2917,
    longitude: -79.9077,
    postal_code: '34005'
  },
  '34006': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 8.9171,
    longitude: -79.5843,
    postal_code: '34006'
  },
  '34007': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 8.9395,
    longitude: -79.5491,
    postal_code: '34007'
  },
  '34008': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 9.3219,
    longitude: -79.8697,
    postal_code: '34008'
  },
  '34009': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 8.9667,
    longitude: -79.55,
    postal_code: '34009'
  },
  '34010': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.0818,
    longitude: -87.2068,
    postal_code: '34010'
  },
  '34011': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 8.9667,
    longitude: -79.55,
    postal_code: '34011'
  },
  '34020': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 9.9333,
    longitude: -84.0833,
    postal_code: '34020'
  },
  '34021': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 12.1328,
    longitude: -86.2504,
    postal_code: '34021'
  },
  '34022': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.0818,
    longitude: -87.2068,
    postal_code: '34022'
  },
  '34023': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 13.6894,
    longitude: -89.1872,
    postal_code: '34023'
  },
  '34024': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.6407,
    longitude: -90.5133,
    postal_code: '34024'
  },
  '34030': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -22.9114,
    longitude: -43.1737,
    postal_code: '34030'
  },
  '34031': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -12.0432,
    longitude: -77.0282,
    postal_code: '34031'
  },
  '34032': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -16.5,
    longitude: -68.15,
    postal_code: '34032'
  },
  '34033': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -33.4569,
    longitude: -70.6483,
    postal_code: '34033'
  },
  '34034': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -34.6131,
    longitude: -58.3772,
    postal_code: '34034'
  },
  '34035': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -34.9033,
    longitude: -56.1882,
    postal_code: '34035'
  },
  '34036': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -25.3007,
    longitude: -57.6359,
    postal_code: '34036'
  },
  '34037': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 10.488,
    longitude: -66.8792,
    postal_code: '34037'
  },
  '34038': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 4.6097,
    longitude: -74.0818,
    postal_code: '34038'
  },
  '34039': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -0.2299,
    longitude: -78.525,
    postal_code: '34039'
  },
  '34040': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 18.4663,
    longitude: -66.1057,
    postal_code: '34040'
  },
  '34041': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 18.5001,
    longitude: -69.9886,
    postal_code: '34041'
  },
  '34042': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.3824,
    longitude: -87.6212,
    postal_code: '34042'
  },
  '34045': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 9.9333,
    longitude: -84.0833,
    postal_code: '34045'
  },
  '34046': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.0818,
    longitude: -87.2068,
    postal_code: '34046'
  },
  '34050': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 18.4786,
    longitude: -67.1688,
    postal_code: '34050'
  },
  '34051': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 18.235,
    longitude: -65.6291,
    postal_code: '34051'
  },
  '34053': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 18.4269,
    longitude: -66.1846,
    postal_code: '34053'
  },
  '34054': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 17.05,
    longitude: -61.8,
    postal_code: '34054'
  },
  '34058': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.4333,
    longitude: -77.95,
    postal_code: '34058'
  },
  '34059': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 8.9395,
    longitude: -79.5491,
    postal_code: '34059'
  },
  '34060': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 9.4008,
    longitude: -79.8697,
    postal_code: '34060'
  },
  '34061': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 8.95,
    longitude: -79.5833,
    postal_code: '34061'
  },
  '34101': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.1423,
    longitude: -81.796,
    postal_code: '34101'
  },
  '34102': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.134,
    longitude: -81.7953,
    postal_code: '34102'
  },
  '34103': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.1917,
    longitude: -81.8039,
    postal_code: '34103'
  },
  '34104': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.1529,
    longitude: -81.7417,
    postal_code: '34104'
  },
  '34105': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.1938,
    longitude: -81.7636,
    postal_code: '34105'
  },
  '34106': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.1423,
    longitude: -81.796,
    postal_code: '34106'
  },
  '34107': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.1423,
    longitude: -81.796,
    postal_code: '34107'
  },
  '34108': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.2416,
    longitude: -81.8071,
    postal_code: '34108'
  },
  '34109': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.2534,
    longitude: -81.7644,
    postal_code: '34109'
  },
  '34110': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.2823,
    longitude: -81.7573,
    postal_code: '34110'
  },
  '34112': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.1184,
    longitude: -81.7361,
    postal_code: '34112'
  },
  '34113': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.0426,
    longitude: -81.7182,
    postal_code: '34113'
  },
  '34114': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.0143,
    longitude: -81.5856,
    postal_code: '34114'
  },
  '34116': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.1873,
    longitude: -81.711,
    postal_code: '34116'
  },
  '34117': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.1156,
    longitude: -81.5239,
    postal_code: '34117'
  },
  '34119': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.2665,
    longitude: -81.7146,
    postal_code: '34119'
  },
  '34120': {
    country: 'US',
    city: 'Naples',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.3304,
    longitude: -81.5871,
    postal_code: '34120'
  },
  '34133': {
    country: 'US',
    city: 'Bonita Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.3398,
    longitude: -81.7787,
    postal_code: '34133'
  },
  '34134': {
    country: 'US',
    city: 'Bonita Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.3626,
    longitude: -81.8183,
    postal_code: '34134'
  },
  '34135': {
    country: 'US',
    city: 'Bonita Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.3771,
    longitude: -81.7334,
    postal_code: '34135'
  },
  '34136': {
    country: 'US',
    city: 'Bonita Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lee',
    latitude: 26.3398,
    longitude: -81.7787,
    postal_code: '34136'
  },
  '34137': {
    country: 'US',
    city: 'Copeland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 25.9542,
    longitude: -81.3575,
    postal_code: '34137'
  },
  '34138': {
    country: 'US',
    city: 'Chokoloskee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 25.8129,
    longitude: -81.362,
    postal_code: '34138'
  },
  '34139': {
    country: 'US',
    city: 'Everglades City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 25.857,
    longitude: -81.3778,
    postal_code: '34139'
  },
  '34140': {
    country: 'US',
    city: 'Goodland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 25.9248,
    longitude: -81.6456,
    postal_code: '34140'
  },
  '34141': {
    country: 'US',
    city: 'Ochopee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 25.8734,
    longitude: -81.1599,
    postal_code: '34141'
  },
  '34142': {
    country: 'US',
    city: 'Immokalee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.1844,
    longitude: -81.4152,
    postal_code: '34142'
  },
  '34143': {
    country: 'US',
    city: 'Immokalee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 26.4642,
    longitude: -81.5047,
    postal_code: '34143'
  },
  '34145': {
    country: 'US',
    city: 'Marco Island',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 25.9388,
    longitude: -81.6968,
    postal_code: '34145'
  },
  '34146': {
    country: 'US',
    city: 'Marco Island',
    state: 'Florida',
    state_short: 'FL',
    county: 'Collier',
    latitude: 25.9412,
    longitude: -81.7184,
    postal_code: '34146'
  },
  '34201': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4047,
    longitude: -82.4705,
    postal_code: '34201'
  },
  '34202': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4067,
    longitude: -82.39,
    postal_code: '34202'
  },
  '34203': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4547,
    longitude: -82.5359,
    postal_code: '34203'
  },
  '34204': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4272,
    longitude: -82.4387,
    postal_code: '34204'
  },
  '34205': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4841,
    longitude: -82.5834,
    postal_code: '34205'
  },
  '34206': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4989,
    longitude: -82.5748,
    postal_code: '34206'
  },
  '34207': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4394,
    longitude: -82.5778,
    postal_code: '34207'
  },
  '34208': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4678,
    longitude: -82.512,
    postal_code: '34208'
  },
  '34209': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4759,
    longitude: -82.6167,
    postal_code: '34209'
  },
  '34210': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4544,
    longitude: -82.6358,
    postal_code: '34210'
  },
  '34211': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.45,
    longitude: -82.3773,
    postal_code: '34211'
  },
  '34212': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4978,
    longitude: -82.4101,
    postal_code: '34212'
  },
  '34215': {
    country: 'US',
    city: 'Cortez',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4713,
    longitude: -82.6823,
    postal_code: '34215'
  },
  '34216': {
    country: 'US',
    city: 'Anna Maria',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.5291,
    longitude: -82.7317,
    postal_code: '34216'
  },
  '34217': {
    country: 'US',
    city: 'Bradenton Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4859,
    longitude: -82.7102,
    postal_code: '34217'
  },
  '34218': {
    country: 'US',
    city: 'Holmes Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4995,
    longitude: -82.7099,
    postal_code: '34218'
  },
  '34219': {
    country: 'US',
    city: 'Parrish',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.5572,
    longitude: -82.396,
    postal_code: '34219'
  },
  '34220': {
    country: 'US',
    city: 'Palmetto',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.5214,
    longitude: -82.5723,
    postal_code: '34220'
  },
  '34221': {
    country: 'US',
    city: 'Palmetto',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.5429,
    longitude: -82.563,
    postal_code: '34221'
  },
  '34222': {
    country: 'US',
    city: 'Ellenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.5382,
    longitude: -82.5006,
    postal_code: '34222'
  },
  '34223': {
    country: 'US',
    city: 'Englewood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 26.9667,
    longitude: -82.3599,
    postal_code: '34223'
  },
  '34224': {
    country: 'US',
    city: 'Englewood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 26.92,
    longitude: -82.3048,
    postal_code: '34224'
  },
  '34228': {
    country: 'US',
    city: 'Longboat Key',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4125,
    longitude: -82.659,
    postal_code: '34228'
  },
  '34229': {
    country: 'US',
    city: 'Osprey',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.1838,
    longitude: -82.4853,
    postal_code: '34229'
  },
  '34230': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.335,
    longitude: -82.5372,
    postal_code: '34230'
  },
  '34231': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.2666,
    longitude: -82.5163,
    postal_code: '34231'
  },
  '34232': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.3262,
    longitude: -82.4724,
    postal_code: '34232'
  },
  '34233': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.2866,
    longitude: -82.477,
    postal_code: '34233'
  },
  '34234': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.3688,
    longitude: -82.5268,
    postal_code: '34234'
  },
  '34235': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.3672,
    longitude: -82.4848,
    postal_code: '34235'
  },
  '34236': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.3269,
    longitude: -82.5433,
    postal_code: '34236'
  },
  '34237': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.3369,
    longitude: -82.5128,
    postal_code: '34237'
  },
  '34238': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.2427,
    longitude: -82.4751,
    postal_code: '34238'
  },
  '34239': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.3111,
    longitude: -82.5195,
    postal_code: '34239'
  },
  '34240': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.339,
    longitude: -82.3473,
    postal_code: '34240'
  },
  '34241': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.2822,
    longitude: -82.4181,
    postal_code: '34241'
  },
  '34242': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.2566,
    longitude: -82.5398,
    postal_code: '34242'
  },
  '34243': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4072,
    longitude: -82.5303,
    postal_code: '34243'
  },
  '34250': {
    country: 'US',
    city: 'Terra Ceia',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.5722,
    longitude: -82.5832,
    postal_code: '34250'
  },
  '34251': {
    country: 'US',
    city: 'Myakka City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.3648,
    longitude: -82.1849,
    postal_code: '34251'
  },
  '34260': {
    country: 'US',
    city: 'Manasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4272,
    longitude: -82.4387,
    postal_code: '34260'
  },
  '34264': {
    country: 'US',
    city: 'Oneco',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4475,
    longitude: -82.5462,
    postal_code: '34264'
  },
  '34265': {
    country: 'US',
    city: 'Arcadia',
    state: 'Florida',
    state_short: 'FL',
    county: 'DeSoto',
    latitude: 27.1861,
    longitude: -81.8099,
    postal_code: '34265'
  },
  '34266': {
    country: 'US',
    city: 'Arcadia',
    state: 'Florida',
    state_short: 'FL',
    county: 'DeSoto',
    latitude: 27.1861,
    longitude: -81.8667,
    postal_code: '34266'
  },
  '34267': {
    country: 'US',
    city: 'Fort Ogden',
    state: 'Florida',
    state_short: 'FL',
    county: 'DeSoto',
    latitude: 27.1861,
    longitude: -81.8099,
    postal_code: '34267'
  },
  '34268': {
    country: 'US',
    city: 'Nocatee',
    state: 'Florida',
    state_short: 'FL',
    county: 'DeSoto',
    latitude: 27.1603,
    longitude: -81.8823,
    postal_code: '34268'
  },
  '34269': {
    country: 'US',
    city: 'Arcadia',
    state: 'Florida',
    state_short: 'FL',
    county: 'DeSoto',
    latitude: 27.0675,
    longitude: -81.9855,
    postal_code: '34269'
  },
  '34270': {
    country: 'US',
    city: 'Tallevast',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4054,
    longitude: -82.5435,
    postal_code: '34270'
  },
  '34272': {
    country: 'US',
    city: 'Laurel',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.147,
    longitude: -82.4255,
    postal_code: '34272'
  },
  '34274': {
    country: 'US',
    city: 'Nokomis',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.144,
    longitude: -82.4645,
    postal_code: '34274'
  },
  '34275': {
    country: 'US',
    city: 'Nokomis',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.1384,
    longitude: -82.4518,
    postal_code: '34275'
  },
  '34276': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.3364,
    longitude: -82.5307,
    postal_code: '34276'
  },
  '34277': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.3364,
    longitude: -82.5307,
    postal_code: '34277'
  },
  '34278': {
    country: 'US',
    city: 'Sarasota',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.3316,
    longitude: -82.5285,
    postal_code: '34278'
  },
  '34280': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4272,
    longitude: -82.4387,
    postal_code: '34280'
  },
  '34281': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4272,
    longitude: -82.4387,
    postal_code: '34281'
  },
  '34282': {
    country: 'US',
    city: 'Bradenton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Manatee',
    latitude: 27.4272,
    longitude: -82.4387,
    postal_code: '34282'
  },
  '34284': {
    country: 'US',
    city: 'Venice',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.1675,
    longitude: -82.381,
    postal_code: '34284'
  },
  '34285': {
    country: 'US',
    city: 'Venice',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.0933,
    longitude: -82.4498,
    postal_code: '34285'
  },
  '34286': {
    country: 'US',
    city: 'North Port',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.0748,
    longitude: -82.1756,
    postal_code: '34286'
  },
  '34287': {
    country: 'US',
    city: 'North Port',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.0478,
    longitude: -82.2416,
    postal_code: '34287'
  },
  '34288': {
    country: 'US',
    city: 'North Port',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.0498,
    longitude: -82.1288,
    postal_code: '34288'
  },
  '34289': {
    country: 'US',
    city: 'North Port',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.0808,
    longitude: -82.1516,
    postal_code: '34289'
  },
  '34290': {
    country: 'US',
    city: 'North Port',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.0459,
    longitude: -82.2491,
    postal_code: '34290'
  },
  '34291': {
    country: 'US',
    city: 'North Port',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.0997,
    longitude: -82.2095,
    postal_code: '34291'
  },
  '34292': {
    country: 'US',
    city: 'Venice',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.09,
    longitude: -82.37,
    postal_code: '34292'
  },
  '34293': {
    country: 'US',
    city: 'Venice',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.0606,
    longitude: -82.352,
    postal_code: '34293'
  },
  '34295': {
    country: 'US',
    city: 'Englewood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sarasota',
    latitude: 27.086,
    longitude: -82.4389,
    postal_code: '34295'
  },
  '34420': {
    country: 'US',
    city: 'Belleview',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.0531,
    longitude: -82.0375,
    postal_code: '34420'
  },
  '34421': {
    country: 'US',
    city: 'Belleview',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.2407,
    longitude: -82.0875,
    postal_code: '34421'
  },
  '34423': {
    country: 'US',
    city: 'Crystal River',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.867,
    longitude: -82.5727,
    postal_code: '34423'
  },
  '34428': {
    country: 'US',
    city: 'Crystal River',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.9584,
    longitude: -82.5993,
    postal_code: '34428'
  },
  '34429': {
    country: 'US',
    city: 'Crystal River',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.8547,
    longitude: -82.6669,
    postal_code: '34429'
  },
  '34430': {
    country: 'US',
    city: 'Dunnellon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.0491,
    longitude: -82.4609,
    postal_code: '34430'
  },
  '34431': {
    country: 'US',
    city: 'Dunnellon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1392,
    longitude: -82.5328,
    postal_code: '34431'
  },
  '34432': {
    country: 'US',
    city: 'Dunnellon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1015,
    longitude: -82.3413,
    postal_code: '34432'
  },
  '34433': {
    country: 'US',
    city: 'Dunnellon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.9949,
    longitude: -82.5196,
    postal_code: '34433'
  },
  '34434': {
    country: 'US',
    city: 'Dunnellon',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.9938,
    longitude: -82.4241,
    postal_code: '34434'
  },
  '34436': {
    country: 'US',
    city: 'Floral City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.7304,
    longitude: -82.3077,
    postal_code: '34436'
  },
  '34441': {
    country: 'US',
    city: 'Hernando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.9311,
    longitude: -82.372,
    postal_code: '34441'
  },
  '34442': {
    country: 'US',
    city: 'Hernando',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.9223,
    longitude: -82.39,
    postal_code: '34442'
  },
  '34445': {
    country: 'US',
    city: 'Holder',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.9669,
    longitude: -82.4207,
    postal_code: '34445'
  },
  '34446': {
    country: 'US',
    city: 'Homosassa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.7508,
    longitude: -82.5139,
    postal_code: '34446'
  },
  '34447': {
    country: 'US',
    city: 'Homosassa Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.8049,
    longitude: -82.5743,
    postal_code: '34447'
  },
  '34448': {
    country: 'US',
    city: 'Homosassa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.788,
    longitude: -82.568,
    postal_code: '34448'
  },
  '34449': {
    country: 'US',
    city: 'Inglis',
    state: 'Florida',
    state_short: 'FL',
    county: 'Levy',
    latitude: 29.0955,
    longitude: -82.6561,
    postal_code: '34449'
  },
  '34450': {
    country: 'US',
    city: 'Inverness',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.834,
    longitude: -82.2822,
    postal_code: '34450'
  },
  '34451': {
    country: 'US',
    city: 'Inverness',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.8358,
    longitude: -82.3304,
    postal_code: '34451'
  },
  '34452': {
    country: 'US',
    city: 'Inverness',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.8358,
    longitude: -82.3304,
    postal_code: '34452'
  },
  '34453': {
    country: 'US',
    city: 'Inverness',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.8723,
    longitude: -82.3454,
    postal_code: '34453'
  },
  '34460': {
    country: 'US',
    city: 'Lecanto',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.8593,
    longitude: -82.5087,
    postal_code: '34460'
  },
  '34461': {
    country: 'US',
    city: 'Lecanto',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.8516,
    longitude: -82.4876,
    postal_code: '34461'
  },
  '34464': {
    country: 'US',
    city: 'Beverly Hills',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.9169,
    longitude: -82.4582,
    postal_code: '34464'
  },
  '34465': {
    country: 'US',
    city: 'Beverly Hills',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.9295,
    longitude: -82.4892,
    postal_code: '34465'
  },
  '34470': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1989,
    longitude: -82.0874,
    postal_code: '34470'
  },
  '34471': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1605,
    longitude: -82.1288,
    postal_code: '34471'
  },
  '34472': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1253,
    longitude: -82.0086,
    postal_code: '34472'
  },
  '34473': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.0058,
    longitude: -82.1828,
    postal_code: '34473'
  },
  '34474': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1565,
    longitude: -82.2095,
    postal_code: '34474'
  },
  '34475': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.2573,
    longitude: -82.161,
    postal_code: '34475'
  },
  '34476': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1159,
    longitude: -82.2422,
    postal_code: '34476'
  },
  '34477': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1872,
    longitude: -82.1401,
    postal_code: '34477'
  },
  '34478': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1872,
    longitude: -82.1123,
    postal_code: '34478'
  },
  '34479': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.2541,
    longitude: -82.1095,
    postal_code: '34479'
  },
  '34480': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1056,
    longitude: -82.098,
    postal_code: '34480'
  },
  '34481': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.1281,
    longitude: -82.2975,
    postal_code: '34481'
  },
  '34482': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.2611,
    longitude: -82.2195,
    postal_code: '34482'
  },
  '34483': {
    country: 'US',
    city: 'Ocala',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.2407,
    longitude: -82.0875,
    postal_code: '34483'
  },
  '34484': {
    country: 'US',
    city: 'Oxford',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sumter',
    latitude: 28.9059,
    longitude: -82.0612,
    postal_code: '34484'
  },
  '34487': {
    country: 'US',
    city: 'Homosassa',
    state: 'Florida',
    state_short: 'FL',
    county: 'Citrus',
    latitude: 28.7814,
    longitude: -82.6151,
    postal_code: '34487'
  },
  '34488': {
    country: 'US',
    city: 'Silver Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.2635,
    longitude: -81.9532,
    postal_code: '34488'
  },
  '34489': {
    country: 'US',
    city: 'Silver Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.2152,
    longitude: -82.0972,
    postal_code: '34489'
  },
  '34491': {
    country: 'US',
    city: 'Summerfield',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 29.0112,
    longitude: -82.0325,
    postal_code: '34491'
  },
  '34492': {
    country: 'US',
    city: 'Summerfield',
    state: 'Florida',
    state_short: 'FL',
    county: 'Marion',
    latitude: 28.998,
    longitude: -82.0161,
    postal_code: '34492'
  },
  '34498': {
    country: 'US',
    city: 'Yankeetown',
    state: 'Florida',
    state_short: 'FL',
    county: 'Levy',
    latitude: 29.0305,
    longitude: -82.719,
    postal_code: '34498'
  },
  '34601': {
    country: 'US',
    city: 'Brooksville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.5658,
    longitude: -82.3737,
    postal_code: '34601'
  },
  '34602': {
    country: 'US',
    city: 'Brooksville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.5093,
    longitude: -82.2957,
    postal_code: '34602'
  },
  '34603': {
    country: 'US',
    city: 'Brooksville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.5642,
    longitude: -82.4165,
    postal_code: '34603'
  },
  '34604': {
    country: 'US',
    city: 'Brooksville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.4409,
    longitude: -82.4612,
    postal_code: '34604'
  },
  '34605': {
    country: 'US',
    city: 'Brooksville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.5059,
    longitude: -82.4226,
    postal_code: '34605'
  },
  '34606': {
    country: 'US',
    city: 'Spring Hill',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.4655,
    longitude: -82.5981,
    postal_code: '34606'
  },
  '34607': {
    country: 'US',
    city: 'Spring Hill',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.5065,
    longitude: -82.6267,
    postal_code: '34607'
  },
  '34608': {
    country: 'US',
    city: 'Spring Hill',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.4797,
    longitude: -82.5562,
    postal_code: '34608'
  },
  '34609': {
    country: 'US',
    city: 'Spring Hill',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.4794,
    longitude: -82.5083,
    postal_code: '34609'
  },
  '34610': {
    country: 'US',
    city: 'Spring Hill',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.4079,
    longitude: -82.5398,
    postal_code: '34610'
  },
  '34611': {
    country: 'US',
    city: 'Spring Hill',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.5642,
    longitude: -82.4165,
    postal_code: '34611'
  },
  '34613': {
    country: 'US',
    city: 'Brooksville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.5466,
    longitude: -82.5213,
    postal_code: '34613'
  },
  '34614': {
    country: 'US',
    city: 'Brooksville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.6622,
    longitude: -82.5236,
    postal_code: '34614'
  },
  '34636': {
    country: 'US',
    city: 'Istachatta',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.655,
    longitude: -82.2677,
    postal_code: '34636'
  },
  '34637': {
    country: 'US',
    city: 'Land O Lakes',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2782,
    longitude: -82.4625,
    postal_code: '34637'
  },
  '34638': {
    country: 'US',
    city: 'Land O Lakes',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2478,
    longitude: -82.4962,
    postal_code: '34638'
  },
  '34639': {
    country: 'US',
    city: 'Land O Lakes',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2258,
    longitude: -82.4547,
    postal_code: '34639'
  },
  '34652': {
    country: 'US',
    city: 'New Port Richey',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2326,
    longitude: -82.7327,
    postal_code: '34652'
  },
  '34653': {
    country: 'US',
    city: 'New Port Richey',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2444,
    longitude: -82.6986,
    postal_code: '34653'
  },
  '34654': {
    country: 'US',
    city: 'New Port Richey',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.3022,
    longitude: -82.6264,
    postal_code: '34654'
  },
  '34655': {
    country: 'US',
    city: 'New Port Richey',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2129,
    longitude: -82.6807,
    postal_code: '34655'
  },
  '34656': {
    country: 'US',
    city: 'New Port Richey',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2442,
    longitude: -82.7193,
    postal_code: '34656'
  },
  '34660': {
    country: 'US',
    city: 'Ozona',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.067,
    longitude: -82.7784,
    postal_code: '34660'
  },
  '34661': {
    country: 'US',
    city: 'Nobleton',
    state: 'Florida',
    state_short: 'FL',
    county: 'Hernando',
    latitude: 28.6436,
    longitude: -82.2638,
    postal_code: '34661'
  },
  '34667': {
    country: 'US',
    city: 'Hudson',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.3648,
    longitude: -82.6757,
    postal_code: '34667'
  },
  '34668': {
    country: 'US',
    city: 'Port Richey',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.3011,
    longitude: -82.6927,
    postal_code: '34668'
  },
  '34669': {
    country: 'US',
    city: 'Hudson',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.3506,
    longitude: -82.6288,
    postal_code: '34669'
  },
  '34673': {
    country: 'US',
    city: 'Port Richey',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2717,
    longitude: -82.7195,
    postal_code: '34673'
  },
  '34674': {
    country: 'US',
    city: 'Hudson',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.3644,
    longitude: -82.6934,
    postal_code: '34674'
  },
  '34677': {
    country: 'US',
    city: 'Oldsmar',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.046,
    longitude: -82.6848,
    postal_code: '34677'
  },
  '34679': {
    country: 'US',
    city: 'Aripeka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.4302,
    longitude: -82.6616,
    postal_code: '34679'
  },
  '34680': {
    country: 'US',
    city: 'Elfers',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.2167,
    longitude: -82.7223,
    postal_code: '34680'
  },
  '34681': {
    country: 'US',
    city: 'Crystal Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.0914,
    longitude: -82.7798,
    postal_code: '34681'
  },
  '34682': {
    country: 'US',
    city: 'Palm Harbor',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.0781,
    longitude: -82.7637,
    postal_code: '34682'
  },
  '34683': {
    country: 'US',
    city: 'Palm Harbor',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.0662,
    longitude: -82.7585,
    postal_code: '34683'
  },
  '34684': {
    country: 'US',
    city: 'Palm Harbor',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.0848,
    longitude: -82.7253,
    postal_code: '34684'
  },
  '34685': {
    country: 'US',
    city: 'Palm Harbor',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.0967,
    longitude: -82.6964,
    postal_code: '34685'
  },
  '34688': {
    country: 'US',
    city: 'Tarpon Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.1458,
    longitude: -82.6825,
    postal_code: '34688'
  },
  '34689': {
    country: 'US',
    city: 'Tarpon Springs',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.1385,
    longitude: -82.743,
    postal_code: '34689'
  },
  '34690': {
    country: 'US',
    city: 'Holiday',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.1913,
    longitude: -82.7279,
    postal_code: '34690'
  },
  '34691': {
    country: 'US',
    city: 'Holiday',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.1913,
    longitude: -82.756,
    postal_code: '34691'
  },
  '34692': {
    country: 'US',
    city: 'Holiday',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pasco',
    latitude: 28.188,
    longitude: -82.7346,
    postal_code: '34692'
  },
  '34695': {
    country: 'US',
    city: 'Safety Harbor',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.0096,
    longitude: -82.6967,
    postal_code: '34695'
  },
  '34697': {
    country: 'US',
    city: 'Dunedin',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 27.8918,
    longitude: -82.7248,
    postal_code: '34697'
  },
  '34698': {
    country: 'US',
    city: 'Dunedin',
    state: 'Florida',
    state_short: 'FL',
    county: 'Pinellas',
    latitude: 28.0284,
    longitude: -82.7794,
    postal_code: '34698'
  },
  '34705': {
    country: 'US',
    city: 'Astatula',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.7088,
    longitude: -81.7195,
    postal_code: '34705'
  },
  '34711': {
    country: 'US',
    city: 'Clermont',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.5525,
    longitude: -81.7574,
    postal_code: '34711'
  },
  '34712': {
    country: 'US',
    city: 'Clermont',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.5494,
    longitude: -81.7729,
    postal_code: '34712'
  },
  '34713': {
    country: 'US',
    city: 'Clermont',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.5494,
    longitude: -81.7729,
    postal_code: '34713'
  },
  '34714': {
    country: 'US',
    city: 'Clermont',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.4113,
    longitude: -81.7812,
    postal_code: '34714'
  },
  '34715': {
    country: 'US',
    city: 'Clermont',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.6259,
    longitude: -81.7306,
    postal_code: '34715'
  },
  '34729': {
    country: 'US',
    city: 'Ferndale',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.6219,
    longitude: -81.7034,
    postal_code: '34729'
  },
  '34731': {
    country: 'US',
    city: 'Fruitland Park',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.8639,
    longitude: -81.8998,
    postal_code: '34731'
  },
  '34734': {
    country: 'US',
    city: 'Gotha',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5384,
    longitude: -81.5208,
    postal_code: '34734'
  },
  '34736': {
    country: 'US',
    city: 'Groveland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.5644,
    longitude: -81.8745,
    postal_code: '34736'
  },
  '34737': {
    country: 'US',
    city: 'Howey In The Hills',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.6971,
    longitude: -81.7976,
    postal_code: '34737'
  },
  '34739': {
    country: 'US',
    city: 'Kenansville',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 27.8767,
    longitude: -81.05,
    postal_code: '34739'
  },
  '34740': {
    country: 'US',
    city: 'Killarney',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5454,
    longitude: -81.6507,
    postal_code: '34740'
  },
  '34741': {
    country: 'US',
    city: 'Kissimmee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.3051,
    longitude: -81.4242,
    postal_code: '34741'
  },
  '34742': {
    country: 'US',
    city: 'Kissimmee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.3047,
    longitude: -81.4167,
    postal_code: '34742'
  },
  '34743': {
    country: 'US',
    city: 'Kissimmee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.3306,
    longitude: -81.3544,
    postal_code: '34743'
  },
  '34744': {
    country: 'US',
    city: 'Kissimmee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.3078,
    longitude: -81.3681,
    postal_code: '34744'
  },
  '34745': {
    country: 'US',
    city: 'Kissimmee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.3047,
    longitude: -81.4167,
    postal_code: '34745'
  },
  '34746': {
    country: 'US',
    city: 'Kissimmee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.268,
    longitude: -81.4675,
    postal_code: '34746'
  },
  '34747': {
    country: 'US',
    city: 'Kissimmee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.3037,
    longitude: -81.5898,
    postal_code: '34747'
  },
  '34748': {
    country: 'US',
    city: 'Leesburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.808,
    longitude: -81.8858,
    postal_code: '34748'
  },
  '34749': {
    country: 'US',
    city: 'Leesburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.8108,
    longitude: -81.8779,
    postal_code: '34749'
  },
  '34753': {
    country: 'US',
    city: 'Mascotte',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.583,
    longitude: -81.8941,
    postal_code: '34753'
  },
  '34755': {
    country: 'US',
    city: 'Minneola',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.5744,
    longitude: -81.7462,
    postal_code: '34755'
  },
  '34756': {
    country: 'US',
    city: 'Montverde',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.5972,
    longitude: -81.6794,
    postal_code: '34756'
  },
  '34758': {
    country: 'US',
    city: 'Kissimmee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.1984,
    longitude: -81.487,
    postal_code: '34758'
  },
  '34759': {
    country: 'US',
    city: 'Kissimmee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Polk',
    latitude: 28.0946,
    longitude: -81.499,
    postal_code: '34759'
  },
  '34760': {
    country: 'US',
    city: 'Oakland',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5547,
    longitude: -81.6322,
    postal_code: '34760'
  },
  '34761': {
    country: 'US',
    city: 'Ocoee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5837,
    longitude: -81.5326,
    postal_code: '34761'
  },
  '34762': {
    country: 'US',
    city: 'Okahumpka',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.7545,
    longitude: -81.9151,
    postal_code: '34762'
  },
  '34769': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.248,
    longitude: -81.2876,
    postal_code: '34769'
  },
  '34770': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.2489,
    longitude: -81.2812,
    postal_code: '34770'
  },
  '34771': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.273,
    longitude: -81.2003,
    postal_code: '34771'
  },
  '34772': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.1905,
    longitude: -81.2645,
    postal_code: '34772'
  },
  '34773': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Florida',
    state_short: 'FL',
    county: 'Osceola',
    latitude: 28.1293,
    longitude: -81.0176,
    postal_code: '34773'
  },
  '34777': {
    country: 'US',
    city: 'Winter Garden',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5416,
    longitude: -81.6058,
    postal_code: '34777'
  },
  '34778': {
    country: 'US',
    city: 'Winter Garden',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5653,
    longitude: -81.5862,
    postal_code: '34778'
  },
  '34785': {
    country: 'US',
    city: 'Wildwood',
    state: 'Florida',
    state_short: 'FL',
    county: 'Sumter',
    latitude: 28.8454,
    longitude: -82.0347,
    postal_code: '34785'
  },
  '34786': {
    country: 'US',
    city: 'Windermere',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5006,
    longitude: -81.5354,
    postal_code: '34786'
  },
  '34787': {
    country: 'US',
    city: 'Winter Garden',
    state: 'Florida',
    state_short: 'FL',
    county: 'Orange',
    latitude: 28.5423,
    longitude: -81.5911,
    postal_code: '34787'
  },
  '34788': {
    country: 'US',
    city: 'Leesburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.8887,
    longitude: -81.7827,
    postal_code: '34788'
  },
  '34789': {
    country: 'US',
    city: 'Leesburg',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.8108,
    longitude: -81.8779,
    postal_code: '34789'
  },
  '34797': {
    country: 'US',
    city: 'Yalaha',
    state: 'Florida',
    state_short: 'FL',
    county: 'Lake',
    latitude: 28.7444,
    longitude: -81.8263,
    postal_code: '34797'
  },
  '34945': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.4382,
    longitude: -80.444,
    postal_code: '34945'
  },
  '34946': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.5008,
    longitude: -80.36,
    postal_code: '34946'
  },
  '34947': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.4493,
    longitude: -80.3592,
    postal_code: '34947'
  },
  '34948': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.3822,
    longitude: -80.409,
    postal_code: '34948'
  },
  '34949': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.3896,
    longitude: -80.2615,
    postal_code: '34949'
  },
  '34950': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.4486,
    longitude: -80.3385,
    postal_code: '34950'
  },
  '34951': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.5391,
    longitude: -80.4052,
    postal_code: '34951'
  },
  '34952': {
    country: 'US',
    city: 'Port Saint Lucie',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.2889,
    longitude: -80.298,
    postal_code: '34952'
  },
  '34953': {
    country: 'US',
    city: 'Port Saint Lucie',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.2625,
    longitude: -80.3793,
    postal_code: '34953'
  },
  '34954': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.3822,
    longitude: -80.409,
    postal_code: '34954'
  },
  '34956': {
    country: 'US',
    city: 'Indiantown',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.0615,
    longitude: -80.4803,
    postal_code: '34956'
  },
  '34957': {
    country: 'US',
    city: 'Jensen Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.2356,
    longitude: -80.2277,
    postal_code: '34957'
  },
  '34958': {
    country: 'US',
    city: 'Jensen Beach',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.2424,
    longitude: -80.2246,
    postal_code: '34958'
  },
  '34972': {
    country: 'US',
    city: 'Okeechobee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okeechobee',
    latitude: 27.4203,
    longitude: -80.9454,
    postal_code: '34972'
  },
  '34973': {
    country: 'US',
    city: 'Okeechobee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okeechobee',
    latitude: 27.2439,
    longitude: -80.8298,
    postal_code: '34973'
  },
  '34974': {
    country: 'US',
    city: 'Okeechobee',
    state: 'Florida',
    state_short: 'FL',
    county: 'Okeechobee',
    latitude: 27.2002,
    longitude: -80.841,
    postal_code: '34974'
  },
  '34979': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.3822,
    longitude: -80.409,
    postal_code: '34979'
  },
  '34981': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.4049,
    longitude: -80.3623,
    postal_code: '34981'
  },
  '34982': {
    country: 'US',
    city: 'Fort Pierce',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.3908,
    longitude: -80.3246,
    postal_code: '34982'
  },
  '34983': {
    country: 'US',
    city: 'Port Saint Lucie',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.3094,
    longitude: -80.345,
    postal_code: '34983'
  },
  '34984': {
    country: 'US',
    city: 'Port Saint Lucie',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.2655,
    longitude: -80.3389,
    postal_code: '34984'
  },
  '34985': {
    country: 'US',
    city: 'Port Saint Lucie',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.2939,
    longitude: -80.3503,
    postal_code: '34985'
  },
  '34986': {
    country: 'US',
    city: 'Port Saint Lucie',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.3215,
    longitude: -80.403,
    postal_code: '34986'
  },
  '34987': {
    country: 'US',
    city: 'Port Saint Lucie',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.2606,
    longitude: -80.4771,
    postal_code: '34987'
  },
  '34988': {
    country: 'US',
    city: 'Port Saint Lucie',
    state: 'Florida',
    state_short: 'FL',
    county: 'St. Lucie',
    latitude: 27.3868,
    longitude: -80.5037,
    postal_code: '34988'
  },
  '34990': {
    country: 'US',
    city: 'Palm City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.1656,
    longitude: -80.2916,
    postal_code: '34990'
  },
  '34991': {
    country: 'US',
    city: 'Palm City',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.1678,
    longitude: -80.2662,
    postal_code: '34991'
  },
  '34992': {
    country: 'US',
    city: 'Port Salerno',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.1442,
    longitude: -80.2006,
    postal_code: '34992'
  },
  '34994': {
    country: 'US',
    city: 'Stuart',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.1968,
    longitude: -80.2538,
    postal_code: '34994'
  },
  '34995': {
    country: 'US',
    city: 'Stuart',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.1754,
    longitude: -80.2415,
    postal_code: '34995'
  },
  '34996': {
    country: 'US',
    city: 'Stuart',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.1929,
    longitude: -80.2164,
    postal_code: '34996'
  },
  '34997': {
    country: 'US',
    city: 'Stuart',
    state: 'Florida',
    state_short: 'FL',
    county: 'Martin',
    latitude: 27.1398,
    longitude: -80.2129,
    postal_code: '34997'
  },
  '35004': {
    country: 'US',
    city: 'Moody',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.6035,
    longitude: -86.4668,
    postal_code: '35004'
  },
  '35005': {
    country: 'US',
    city: 'Adamsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5884,
    longitude: -86.9597,
    postal_code: '35005'
  },
  '35006': {
    country: 'US',
    city: 'Adger',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4343,
    longitude: -87.1675,
    postal_code: '35006'
  },
  '35007': {
    country: 'US',
    city: 'Alabaster',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.1934,
    longitude: -86.7944,
    postal_code: '35007'
  },
  '35010': {
    country: 'US',
    city: 'Alexander City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tallapoosa',
    latitude: 32.9164,
    longitude: -85.9368,
    postal_code: '35010'
  },
  '35011': {
    country: 'US',
    city: 'Alexander City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tallapoosa',
    latitude: 32.9902,
    longitude: -85.958,
    postal_code: '35011'
  },
  '35013': {
    country: 'US',
    city: 'Allgood',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Blount',
    latitude: 33.9222,
    longitude: -86.4493,
    postal_code: '35013'
  },
  '35014': {
    country: 'US',
    city: 'Alpine',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Talladega',
    latitude: 33.404,
    longitude: -86.2339,
    postal_code: '35014'
  },
  '35015': {
    country: 'US',
    city: 'Alton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5793,
    longitude: -86.6375,
    postal_code: '35015'
  },
  '35016': {
    country: 'US',
    city: 'Arab',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marshall',
    latitude: 34.3283,
    longitude: -86.4896,
    postal_code: '35016'
  },
  '35019': {
    country: 'US',
    city: 'Baileyton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.2683,
    longitude: -86.6213,
    postal_code: '35019'
  },
  '35020': {
    country: 'US',
    city: 'Bessemer',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.409,
    longitude: -86.9475,
    postal_code: '35020'
  },
  '35021': {
    country: 'US',
    city: 'Bessemer',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35021'
  },
  '35022': {
    country: 'US',
    city: 'Bessemer',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.3224,
    longitude: -86.9657,
    postal_code: '35022'
  },
  '35023': {
    country: 'US',
    city: 'Bessemer',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.468,
    longitude: -87.0924,
    postal_code: '35023'
  },
  '35031': {
    country: 'US',
    city: 'Blountsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Blount',
    latitude: 34.0929,
    longitude: -86.5686,
    postal_code: '35031'
  },
  '35032': {
    country: 'US',
    city: 'Bon Air',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Talladega',
    latitude: 33.2649,
    longitude: -86.3364,
    postal_code: '35032'
  },
  '35033': {
    country: 'US',
    city: 'Bremen',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 33.9737,
    longitude: -87.0043,
    postal_code: '35033'
  },
  '35034': {
    country: 'US',
    city: 'Brent',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bibb',
    latitude: 32.9357,
    longitude: -87.2114,
    postal_code: '35034'
  },
  '35035': {
    country: 'US',
    city: 'Brierfield',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bibb',
    latitude: 33.0427,
    longitude: -86.9517,
    postal_code: '35035'
  },
  '35036': {
    country: 'US',
    city: 'Brookside',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.6379,
    longitude: -86.9167,
    postal_code: '35036'
  },
  '35038': {
    country: 'US',
    city: 'Burnwell',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.7079,
    longitude: -87.0875,
    postal_code: '35038'
  },
  '35040': {
    country: 'US',
    city: 'Calera',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.1098,
    longitude: -86.756,
    postal_code: '35040'
  },
  '35042': {
    country: 'US',
    city: 'Centreville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bibb',
    latitude: 32.9503,
    longitude: -87.1192,
    postal_code: '35042'
  },
  '35043': {
    country: 'US',
    city: 'Chelsea',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.2982,
    longitude: -86.6563,
    postal_code: '35043'
  },
  '35044': {
    country: 'US',
    city: 'Childersburg',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Talladega',
    latitude: 33.2459,
    longitude: -86.3641,
    postal_code: '35044'
  },
  '35045': {
    country: 'US',
    city: 'Clanton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chilton',
    latitude: 32.8355,
    longitude: -86.6425,
    postal_code: '35045'
  },
  '35046': {
    country: 'US',
    city: 'Clanton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chilton',
    latitude: 32.9229,
    longitude: -86.545,
    postal_code: '35046'
  },
  '35048': {
    country: 'US',
    city: 'Clay',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.7026,
    longitude: -86.5997,
    postal_code: '35048'
  },
  '35049': {
    country: 'US',
    city: 'Cleveland',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Blount',
    latitude: 33.9778,
    longitude: -86.5598,
    postal_code: '35049'
  },
  '35051': {
    country: 'US',
    city: 'Columbiana',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.177,
    longitude: -86.6161,
    postal_code: '35051'
  },
  '35052': {
    country: 'US',
    city: 'Cook Springs',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.5895,
    longitude: -86.3923,
    postal_code: '35052'
  },
  '35053': {
    country: 'US',
    city: 'Crane Hill',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.0821,
    longitude: -87.0484,
    postal_code: '35053'
  },
  '35054': {
    country: 'US',
    city: 'Cropwell',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.5296,
    longitude: -86.3007,
    postal_code: '35054'
  },
  '35055': {
    country: 'US',
    city: 'Cullman',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.1761,
    longitude: -86.8298,
    postal_code: '35055'
  },
  '35056': {
    country: 'US',
    city: 'Cullman',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.1748,
    longitude: -86.8436,
    postal_code: '35056'
  },
  '35057': {
    country: 'US',
    city: 'Cullman',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.0878,
    longitude: -86.9447,
    postal_code: '35057'
  },
  '35058': {
    country: 'US',
    city: 'Cullman',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.2098,
    longitude: -86.752,
    postal_code: '35058'
  },
  '35060': {
    country: 'US',
    city: 'Docena',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5589,
    longitude: -86.9294,
    postal_code: '35060'
  },
  '35061': {
    country: 'US',
    city: 'Dolomite',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4654,
    longitude: -86.9564,
    postal_code: '35061'
  },
  '35062': {
    country: 'US',
    city: 'Dora',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.7357,
    longitude: -87.0546,
    postal_code: '35062'
  },
  '35063': {
    country: 'US',
    city: 'Empire',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.8256,
    longitude: -87.0161,
    postal_code: '35063'
  },
  '35064': {
    country: 'US',
    city: 'Fairfield',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4735,
    longitude: -86.9183,
    postal_code: '35064'
  },
  '35068': {
    country: 'US',
    city: 'Fultondale',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.6017,
    longitude: -86.8265,
    postal_code: '35068'
  },
  '35070': {
    country: 'US',
    city: 'Garden City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.0058,
    longitude: -86.7499,
    postal_code: '35070'
  },
  '35071': {
    country: 'US',
    city: 'Gardendale',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.7189,
    longitude: -86.8225,
    postal_code: '35071'
  },
  '35072': {
    country: 'US',
    city: 'Goodwater',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coosa',
    latitude: 33.0746,
    longitude: -86.0781,
    postal_code: '35072'
  },
  '35073': {
    country: 'US',
    city: 'Graysville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.6539,
    longitude: -86.9803,
    postal_code: '35073'
  },
  '35074': {
    country: 'US',
    city: 'Green Pond',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bibb',
    latitude: 33.2251,
    longitude: -87.1264,
    postal_code: '35074'
  },
  '35077': {
    country: 'US',
    city: 'Hanceville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.0516,
    longitude: -86.7848,
    postal_code: '35077'
  },
  '35078': {
    country: 'US',
    city: 'Harpersville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.3234,
    longitude: -86.4424,
    postal_code: '35078'
  },
  '35079': {
    country: 'US',
    city: 'Hayden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Blount',
    latitude: 33.9534,
    longitude: -86.7455,
    postal_code: '35079'
  },
  '35080': {
    country: 'US',
    city: 'Helena',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.2663,
    longitude: -86.902,
    postal_code: '35080'
  },
  '35082': {
    country: 'US',
    city: 'Hollins',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clay',
    latitude: 33.1162,
    longitude: -86.1386,
    postal_code: '35082'
  },
  '35083': {
    country: 'US',
    city: 'Holly Pond',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.1901,
    longitude: -86.6174,
    postal_code: '35083'
  },
  '35085': {
    country: 'US',
    city: 'Jemison',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chilton',
    latitude: 32.9805,
    longitude: -86.7181,
    postal_code: '35085'
  },
  '35087': {
    country: 'US',
    city: 'Joppa',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.2837,
    longitude: -86.5519,
    postal_code: '35087'
  },
  '35089': {
    country: 'US',
    city: 'Kellyton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coosa',
    latitude: 32.9791,
    longitude: -86.0484,
    postal_code: '35089'
  },
  '35091': {
    country: 'US',
    city: 'Kimberly',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.7684,
    longitude: -86.8084,
    postal_code: '35091'
  },
  '35094': {
    country: 'US',
    city: 'Leeds',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5283,
    longitude: -86.5748,
    postal_code: '35094'
  },
  '35096': {
    country: 'US',
    city: 'Lincoln',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Talladega',
    latitude: 33.6059,
    longitude: -86.1112,
    postal_code: '35096'
  },
  '35097': {
    country: 'US',
    city: 'Locust Fork',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Blount',
    latitude: 33.905,
    longitude: -86.6261,
    postal_code: '35097'
  },
  '35098': {
    country: 'US',
    city: 'Logan',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.1377,
    longitude: -87.0266,
    postal_code: '35098'
  },
  '35111': {
    country: 'US',
    city: 'Mc Calla',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.2845,
    longitude: -87.1024,
    postal_code: '35111'
  },
  '35112': {
    country: 'US',
    city: 'Margaret',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.6862,
    longitude: -86.475,
    postal_code: '35112'
  },
  '35114': {
    country: 'US',
    city: 'Maylene',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.2317,
    longitude: -86.8727,
    postal_code: '35114'
  },
  '35115': {
    country: 'US',
    city: 'Montevallo',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.1248,
    longitude: -86.8622,
    postal_code: '35115'
  },
  '35116': {
    country: 'US',
    city: 'Morris',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.7392,
    longitude: -86.7726,
    postal_code: '35116'
  },
  '35117': {
    country: 'US',
    city: 'Mount Olive',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.6768,
    longitude: -86.8717,
    postal_code: '35117'
  },
  '35118': {
    country: 'US',
    city: 'Mulga',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5363,
    longitude: -87.051,
    postal_code: '35118'
  },
  '35119': {
    country: 'US',
    city: 'New Castle',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.649,
    longitude: -86.7686,
    postal_code: '35119'
  },
  '35120': {
    country: 'US',
    city: 'Odenville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.6756,
    longitude: -86.409,
    postal_code: '35120'
  },
  '35121': {
    country: 'US',
    city: 'Oneonta',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Blount',
    latitude: 33.9259,
    longitude: -86.4741,
    postal_code: '35121'
  },
  '35123': {
    country: 'US',
    city: 'Palmerdale',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.7387,
    longitude: -86.6461,
    postal_code: '35123'
  },
  '35124': {
    country: 'US',
    city: 'Pelham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.2932,
    longitude: -86.768,
    postal_code: '35124'
  },
  '35125': {
    country: 'US',
    city: 'Pell City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.5979,
    longitude: -86.3432,
    postal_code: '35125'
  },
  '35126': {
    country: 'US',
    city: 'Pinson',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.7299,
    longitude: -86.6451,
    postal_code: '35126'
  },
  '35127': {
    country: 'US',
    city: 'Pleasant Grove',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4883,
    longitude: -86.9766,
    postal_code: '35127'
  },
  '35128': {
    country: 'US',
    city: 'Pell City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.5873,
    longitude: -86.3366,
    postal_code: '35128'
  },
  '35130': {
    country: 'US',
    city: 'Quinton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.6561,
    longitude: -87.1007,
    postal_code: '35130'
  },
  '35131': {
    country: 'US',
    city: 'Ragland',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.7367,
    longitude: -86.1619,
    postal_code: '35131'
  },
  '35133': {
    country: 'US',
    city: 'Remlap',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Blount',
    latitude: 33.8168,
    longitude: -86.6008,
    postal_code: '35133'
  },
  '35135': {
    country: 'US',
    city: 'Riverside',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.6166,
    longitude: -86.1996,
    postal_code: '35135'
  },
  '35136': {
    country: 'US',
    city: 'Rockford',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coosa',
    latitude: 32.878,
    longitude: -86.24,
    postal_code: '35136'
  },
  '35137': {
    country: 'US',
    city: 'Saginaw',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.2063,
    longitude: -86.7812,
    postal_code: '35137'
  },
  '35139': {
    country: 'US',
    city: 'Sayre',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.7111,
    longitude: -86.9759,
    postal_code: '35139'
  },
  '35142': {
    country: 'US',
    city: 'Shannon',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4069,
    longitude: -86.8735,
    postal_code: '35142'
  },
  '35143': {
    country: 'US',
    city: 'Shelby',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.0785,
    longitude: -86.5536,
    postal_code: '35143'
  },
  '35144': {
    country: 'US',
    city: 'Siluria',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.2293,
    longitude: -86.825,
    postal_code: '35144'
  },
  '35146': {
    country: 'US',
    city: 'Springville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.7386,
    longitude: -86.4394,
    postal_code: '35146'
  },
  '35147': {
    country: 'US',
    city: 'Sterrett',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.4461,
    longitude: -86.4917,
    postal_code: '35147'
  },
  '35148': {
    country: 'US',
    city: 'Sumiton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.768,
    longitude: -87.0445,
    postal_code: '35148'
  },
  '35149': {
    country: 'US',
    city: 'Sycamore',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Talladega',
    latitude: 33.2512,
    longitude: -86.2025,
    postal_code: '35149'
  },
  '35150': {
    country: 'US',
    city: 'Sylacauga',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Talladega',
    latitude: 33.1717,
    longitude: -86.2713,
    postal_code: '35150'
  },
  '35151': {
    country: 'US',
    city: 'Sylacauga',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Talladega',
    latitude: 33.1756,
    longitude: -86.3574,
    postal_code: '35151'
  },
  '35160': {
    country: 'US',
    city: 'Talladega',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Talladega',
    latitude: 33.4354,
    longitude: -86.1134,
    postal_code: '35160'
  },
  '35161': {
    country: 'US',
    city: 'Talladega',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Talladega',
    latitude: 33.4202,
    longitude: -86.1028,
    postal_code: '35161'
  },
  '35171': {
    country: 'US',
    city: 'Thorsby',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chilton',
    latitude: 32.9157,
    longitude: -86.7158,
    postal_code: '35171'
  },
  '35172': {
    country: 'US',
    city: 'Trafford',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.819,
    longitude: -86.7434,
    postal_code: '35172'
  },
  '35173': {
    country: 'US',
    city: 'Trussville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.6339,
    longitude: -86.5981,
    postal_code: '35173'
  },
  '35175': {
    country: 'US',
    city: 'Union Grove',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marshall',
    latitude: 34.4093,
    longitude: -86.4628,
    postal_code: '35175'
  },
  '35176': {
    country: 'US',
    city: 'Vandiver',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.4807,
    longitude: -86.5013,
    postal_code: '35176'
  },
  '35178': {
    country: 'US',
    city: 'Vincent',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.401,
    longitude: -86.3994,
    postal_code: '35178'
  },
  '35179': {
    country: 'US',
    city: 'Vinemont',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cullman',
    latitude: 34.2621,
    longitude: -86.9125,
    postal_code: '35179'
  },
  '35180': {
    country: 'US',
    city: 'Warrior',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.8529,
    longitude: -86.8198,
    postal_code: '35180'
  },
  '35181': {
    country: 'US',
    city: 'Watson',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35181'
  },
  '35182': {
    country: 'US',
    city: 'Wattsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.7068,
    longitude: -86.2567,
    postal_code: '35182'
  },
  '35183': {
    country: 'US',
    city: 'Weogufka',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coosa',
    latitude: 33.0238,
    longitude: -86.3042,
    postal_code: '35183'
  },
  '35184': {
    country: 'US',
    city: 'West Blocton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bibb',
    latitude: 33.1424,
    longitude: -87.1369,
    postal_code: '35184'
  },
  '35185': {
    country: 'US',
    city: 'Westover',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.3356,
    longitude: -86.5437,
    postal_code: '35185'
  },
  '35186': {
    country: 'US',
    city: 'Wilsonville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.2293,
    longitude: -86.5299,
    postal_code: '35186'
  },
  '35187': {
    country: 'US',
    city: 'Wilton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.081,
    longitude: -86.8793,
    postal_code: '35187'
  },
  '35188': {
    country: 'US',
    city: 'Woodstock',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bibb',
    latitude: 33.2068,
    longitude: -87.15,
    postal_code: '35188'
  },
  '35201': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5207,
    longitude: -86.8025,
    postal_code: '35201'
  },
  '35202': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5207,
    longitude: -86.8025,
    postal_code: '35202'
  },
  '35203': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.521,
    longitude: -86.8066,
    postal_code: '35203'
  },
  '35204': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5179,
    longitude: -86.8372,
    postal_code: '35204'
  },
  '35205': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4951,
    longitude: -86.8059,
    postal_code: '35205'
  },
  '35206': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5678,
    longitude: -86.7199,
    postal_code: '35206'
  },
  '35207': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5594,
    longitude: -86.8153,
    postal_code: '35207'
  },
  '35208': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4977,
    longitude: -86.8799,
    postal_code: '35208'
  },
  '35209': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4653,
    longitude: -86.8082,
    postal_code: '35209'
  },
  '35210': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5452,
    longitude: -86.6655,
    postal_code: '35210'
  },
  '35211': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4816,
    longitude: -86.859,
    postal_code: '35211'
  },
  '35212': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5409,
    longitude: -86.7495,
    postal_code: '35212'
  },
  '35213': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5066,
    longitude: -86.7428,
    postal_code: '35213'
  },
  '35214': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5554,
    longitude: -86.887,
    postal_code: '35214'
  },
  '35215': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.6493,
    longitude: -86.7057,
    postal_code: '35215'
  },
  '35216': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4188,
    longitude: -86.7867,
    postal_code: '35216'
  },
  '35217': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5887,
    longitude: -86.765,
    postal_code: '35217'
  },
  '35218': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.506,
    longitude: -86.893,
    postal_code: '35218'
  },
  '35219': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35219'
  },
  '35220': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35220'
  },
  '35221': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4523,
    longitude: -86.8935,
    postal_code: '35221'
  },
  '35222': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5219,
    longitude: -86.7666,
    postal_code: '35222'
  },
  '35223': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4995,
    longitude: -86.7268,
    postal_code: '35223'
  },
  '35224': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5191,
    longitude: -86.9342,
    postal_code: '35224'
  },
  '35226': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.3967,
    longitude: -86.8346,
    postal_code: '35226'
  },
  '35228': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4558,
    longitude: -86.9118,
    postal_code: '35228'
  },
  '35229': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4629,
    longitude: -86.7904,
    postal_code: '35229'
  },
  '35231': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35231'
  },
  '35232': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35232'
  },
  '35233': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5062,
    longitude: -86.8003,
    postal_code: '35233'
  },
  '35234': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5378,
    longitude: -86.8068,
    postal_code: '35234'
  },
  '35235': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.6328,
    longitude: -86.6493,
    postal_code: '35235'
  },
  '35236': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35236'
  },
  '35237': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5207,
    longitude: -86.8025,
    postal_code: '35237'
  },
  '35238': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35238'
  },
  '35242': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Shelby',
    latitude: 33.3813,
    longitude: -86.7046,
    postal_code: '35242'
  },
  '35243': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4459,
    longitude: -86.7502,
    postal_code: '35243'
  },
  '35244': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.3538,
    longitude: -86.8254,
    postal_code: '35244'
  },
  '35246': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5207,
    longitude: -86.8025,
    postal_code: '35246'
  },
  '35249': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5207,
    longitude: -86.8025,
    postal_code: '35249'
  },
  '35253': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35253'
  },
  '35254': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35254'
  },
  '35255': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35255'
  },
  '35259': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35259'
  },
  '35260': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35260'
  },
  '35261': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35261'
  },
  '35266': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.3729,
    longitude: -86.8531,
    postal_code: '35266'
  },
  '35270': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5206,
    longitude: -86.8025,
    postal_code: '35270'
  },
  '35282': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35282'
  },
  '35283': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5207,
    longitude: -86.8025,
    postal_code: '35283'
  },
  '35285': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35285'
  },
  '35287': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5207,
    longitude: -86.8025,
    postal_code: '35287'
  },
  '35288': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.4458,
    longitude: -86.9285,
    postal_code: '35288'
  },
  '35290': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35290'
  },
  '35291': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5207,
    longitude: -86.8025,
    postal_code: '35291'
  },
  '35292': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5207,
    longitude: -86.8025,
    postal_code: '35292'
  },
  '35293': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35293'
  },
  '35294': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35294'
  },
  '35295': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5207,
    longitude: -86.8025,
    postal_code: '35295'
  },
  '35296': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35296'
  },
  '35297': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35297'
  },
  '35298': {
    country: 'US',
    city: 'Birmingham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jefferson',
    latitude: 33.5446,
    longitude: -86.9292,
    postal_code: '35298'
  },
  '35401': {
    country: 'US',
    city: 'Tuscaloosa',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.1969,
    longitude: -87.5627,
    postal_code: '35401'
  },
  '35402': {
    country: 'US',
    city: 'Tuscaloosa',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.3066,
    longitude: -87.4532,
    postal_code: '35402'
  },
  '35403': {
    country: 'US',
    city: 'Tuscaloosa',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.2045,
    longitude: -87.527,
    postal_code: '35403'
  },
  '35404': {
    country: 'US',
    city: 'Tuscaloosa',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.1826,
    longitude: -87.4827,
    postal_code: '35404'
  },
  '35405': {
    country: 'US',
    city: 'Tuscaloosa',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.1617,
    longitude: -87.5144,
    postal_code: '35405'
  },
  '35406': {
    country: 'US',
    city: 'Tuscaloosa',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.2722,
    longitude: -87.536,
    postal_code: '35406'
  },
  '35407': {
    country: 'US',
    city: 'Tuscaloosa',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.3066,
    longitude: -87.4532,
    postal_code: '35407'
  },
  '35440': {
    country: 'US',
    city: 'Abernant',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.311,
    longitude: -87.1898,
    postal_code: '35440'
  },
  '35441': {
    country: 'US',
    city: 'Akron',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Hale',
    latitude: 32.8553,
    longitude: -87.7219,
    postal_code: '35441'
  },
  '35442': {
    country: 'US',
    city: 'Aliceville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pickens',
    latitude: 33.1228,
    longitude: -88.1667,
    postal_code: '35442'
  },
  '35443': {
    country: 'US',
    city: 'Boligee',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Greene',
    latitude: 32.7746,
    longitude: -88.0267,
    postal_code: '35443'
  },
  '35444': {
    country: 'US',
    city: 'Brookwood',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.2775,
    longitude: -87.309,
    postal_code: '35444'
  },
  '35446': {
    country: 'US',
    city: 'Buhl',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.2306,
    longitude: -87.7547,
    postal_code: '35446'
  },
  '35447': {
    country: 'US',
    city: 'Carrollton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pickens',
    latitude: 33.2485,
    longitude: -88.1321,
    postal_code: '35447'
  },
  '35448': {
    country: 'US',
    city: 'Clinton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Greene',
    latitude: 32.9137,
    longitude: -87.9925,
    postal_code: '35448'
  },
  '35449': {
    country: 'US',
    city: 'Coaling',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.1359,
    longitude: -87.3384,
    postal_code: '35449'
  },
  '35452': {
    country: 'US',
    city: 'Coker',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.2934,
    longitude: -87.6888,
    postal_code: '35452'
  },
  '35453': {
    country: 'US',
    city: 'Cottondale',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.1767,
    longitude: -87.3871,
    postal_code: '35453'
  },
  '35456': {
    country: 'US',
    city: 'Duncanville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.0618,
    longitude: -87.4422,
    postal_code: '35456'
  },
  '35457': {
    country: 'US',
    city: 'Echola',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.294,
    longitude: -87.7766,
    postal_code: '35457'
  },
  '35458': {
    country: 'US',
    city: 'Elrod',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.2562,
    longitude: -87.7922,
    postal_code: '35458'
  },
  '35459': {
    country: 'US',
    city: 'Emelle',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Sumter',
    latitude: 32.755,
    longitude: -88.3057,
    postal_code: '35459'
  },
  '35460': {
    country: 'US',
    city: 'Epes',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Sumter',
    latitude: 32.672,
    longitude: -88.1206,
    postal_code: '35460'
  },
  '35461': {
    country: 'US',
    city: 'Ethelsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pickens',
    latitude: 33.4157,
    longitude: -88.2167,
    postal_code: '35461'
  },
  '35462': {
    country: 'US',
    city: 'Eutaw',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Greene',
    latitude: 32.8889,
    longitude: -87.9303,
    postal_code: '35462'
  },
  '35463': {
    country: 'US',
    city: 'Fosters',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.0843,
    longitude: -87.6975,
    postal_code: '35463'
  },
  '35464': {
    country: 'US',
    city: 'Gainesville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Sumter',
    latitude: 32.821,
    longitude: -88.1589,
    postal_code: '35464'
  },
  '35466': {
    country: 'US',
    city: 'Gordo',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pickens',
    latitude: 33.3469,
    longitude: -87.9005,
    postal_code: '35466'
  },
  '35468': {
    country: 'US',
    city: 'Kellerman',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.3407,
    longitude: -87.3047,
    postal_code: '35468'
  },
  '35469': {
    country: 'US',
    city: 'Knoxville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Greene',
    latitude: 32.9824,
    longitude: -87.7919,
    postal_code: '35469'
  },
  '35470': {
    country: 'US',
    city: 'Livingston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Sumter',
    latitude: 32.5843,
    longitude: -88.1872,
    postal_code: '35470'
  },
  '35471': {
    country: 'US',
    city: 'Mc Shan',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pickens',
    latitude: 33.2626,
    longitude: -88.0885,
    postal_code: '35471'
  },
  '35473': {
    country: 'US',
    city: 'Northport',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.2723,
    longitude: -87.5825,
    postal_code: '35473'
  },
  '35474': {
    country: 'US',
    city: 'Moundville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Hale',
    latitude: 32.9108,
    longitude: -87.5935,
    postal_code: '35474'
  },
  '35475': {
    country: 'US',
    city: 'Northport',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.3992,
    longitude: -87.5599,
    postal_code: '35475'
  },
  '35476': {
    country: 'US',
    city: 'Northport',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.2273,
    longitude: -87.5943,
    postal_code: '35476'
  },
  '35477': {
    country: 'US',
    city: 'Panola',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Sumter',
    latitude: 32.8726,
    longitude: -88.2374,
    postal_code: '35477'
  },
  '35478': {
    country: 'US',
    city: 'Peterson',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.3066,
    longitude: -87.4532,
    postal_code: '35478'
  },
  '35480': {
    country: 'US',
    city: 'Ralph',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.1274,
    longitude: -87.7624,
    postal_code: '35480'
  },
  '35481': {
    country: 'US',
    city: 'Reform',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pickens',
    latitude: 33.3959,
    longitude: -88.0203,
    postal_code: '35481'
  },
  '35482': {
    country: 'US',
    city: 'Samantha',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.3066,
    longitude: -87.4532,
    postal_code: '35482'
  },
  '35486': {
    country: 'US',
    city: 'Tuscaloosa',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.2098,
    longitude: -87.5692,
    postal_code: '35486'
  },
  '35487': {
    country: 'US',
    city: 'Tuscaloosa',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.3072,
    longitude: -87.5859,
    postal_code: '35487'
  },
  '35490': {
    country: 'US',
    city: 'Vance',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tuscaloosa',
    latitude: 33.175,
    longitude: -87.2574,
    postal_code: '35490'
  },
  '35491': {
    country: 'US',
    city: 'West Greene',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Greene',
    latitude: 32.9237,
    longitude: -88.0853,
    postal_code: '35491'
  },
  '35501': {
    country: 'US',
    city: 'Jasper',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.8312,
    longitude: -87.2775,
    postal_code: '35501'
  },
  '35502': {
    country: 'US',
    city: 'Jasper',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.8312,
    longitude: -87.2775,
    postal_code: '35502'
  },
  '35503': {
    country: 'US',
    city: 'Jasper',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.9152,
    longitude: -87.3074,
    postal_code: '35503'
  },
  '35504': {
    country: 'US',
    city: 'Jasper',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.8927,
    longitude: -87.1667,
    postal_code: '35504'
  },
  '35540': {
    country: 'US',
    city: 'Addison',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Winston',
    latitude: 34.2056,
    longitude: -87.1948,
    postal_code: '35540'
  },
  '35541': {
    country: 'US',
    city: 'Arley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Winston',
    latitude: 34.0632,
    longitude: -87.1828,
    postal_code: '35541'
  },
  '35542': {
    country: 'US',
    city: 'Bankston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Fayette',
    latitude: 33.7008,
    longitude: -87.6897,
    postal_code: '35542'
  },
  '35543': {
    country: 'US',
    city: 'Bear Creek',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marion',
    latitude: 34.1649,
    longitude: -87.7396,
    postal_code: '35543'
  },
  '35544': {
    country: 'US',
    city: 'Beaverton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lamar',
    latitude: 33.9429,
    longitude: -88.0157,
    postal_code: '35544'
  },
  '35545': {
    country: 'US',
    city: 'Belk',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Fayette',
    latitude: 33.6476,
    longitude: -87.9314,
    postal_code: '35545'
  },
  '35546': {
    country: 'US',
    city: 'Berry',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Fayette',
    latitude: 33.6945,
    longitude: -87.6226,
    postal_code: '35546'
  },
  '35548': {
    country: 'US',
    city: 'Brilliant',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marion',
    latitude: 34.0377,
    longitude: -87.7563,
    postal_code: '35548'
  },
  '35549': {
    country: 'US',
    city: 'Carbon Hill',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.9093,
    longitude: -87.5403,
    postal_code: '35549'
  },
  '35550': {
    country: 'US',
    city: 'Cordova',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.768,
    longitude: -87.1841,
    postal_code: '35550'
  },
  '35551': {
    country: 'US',
    city: 'Delmar',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Winston',
    latitude: 34.1701,
    longitude: -87.6059,
    postal_code: '35551'
  },
  '35552': {
    country: 'US',
    city: 'Detroit',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lamar',
    latitude: 34.0282,
    longitude: -88.17,
    postal_code: '35552'
  },
  '35553': {
    country: 'US',
    city: 'Double Springs',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Winston',
    latitude: 34.1387,
    longitude: -87.3974,
    postal_code: '35553'
  },
  '35554': {
    country: 'US',
    city: 'Eldridge',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.9315,
    longitude: -87.6194,
    postal_code: '35554'
  },
  '35555': {
    country: 'US',
    city: 'Fayette',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Fayette',
    latitude: 33.6974,
    longitude: -87.8346,
    postal_code: '35555'
  },
  '35559': {
    country: 'US',
    city: 'Glen Allen',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Fayette',
    latitude: 33.9151,
    longitude: -87.7417,
    postal_code: '35559'
  },
  '35560': {
    country: 'US',
    city: 'Goodsprings',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.6795,
    longitude: -87.2374,
    postal_code: '35560'
  },
  '35563': {
    country: 'US',
    city: 'Guin',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marion',
    latitude: 33.9676,
    longitude: -87.9024,
    postal_code: '35563'
  },
  '35564': {
    country: 'US',
    city: 'Hackleburg',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marion',
    latitude: 34.2678,
    longitude: -87.8608,
    postal_code: '35564'
  },
  '35565': {
    country: 'US',
    city: 'Haleyville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Winston',
    latitude: 34.2314,
    longitude: -87.5938,
    postal_code: '35565'
  },
  '35570': {
    country: 'US',
    city: 'Hamilton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marion',
    latitude: 34.1534,
    longitude: -88.0085,
    postal_code: '35570'
  },
  '35571': {
    country: 'US',
    city: 'Hodges',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Franklin',
    latitude: 34.3417,
    longitude: -87.959,
    postal_code: '35571'
  },
  '35572': {
    country: 'US',
    city: 'Houston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Winston',
    latitude: 34.118,
    longitude: -87.2618,
    postal_code: '35572'
  },
  '35573': {
    country: 'US',
    city: 'Kansas',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.9018,
    longitude: -87.552,
    postal_code: '35573'
  },
  '35574': {
    country: 'US',
    city: 'Kennedy',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lamar',
    latitude: 33.6153,
    longitude: -87.9948,
    postal_code: '35574'
  },
  '35575': {
    country: 'US',
    city: 'Lynn',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Winston',
    latitude: 34.053,
    longitude: -87.5398,
    postal_code: '35575'
  },
  '35576': {
    country: 'US',
    city: 'Millport',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lamar',
    latitude: 33.5401,
    longitude: -88.2,
    postal_code: '35576'
  },
  '35577': {
    country: 'US',
    city: 'Natural Bridge',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Winston',
    latitude: 34.0934,
    longitude: -87.6008,
    postal_code: '35577'
  },
  '35578': {
    country: 'US',
    city: 'Nauvoo',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.9299,
    longitude: -87.4324,
    postal_code: '35578'
  },
  '35579': {
    country: 'US',
    city: 'Oakman',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.7002,
    longitude: -87.3686,
    postal_code: '35579'
  },
  '35580': {
    country: 'US',
    city: 'Parrish',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.7213,
    longitude: -87.2658,
    postal_code: '35580'
  },
  '35581': {
    country: 'US',
    city: 'Phil Campbell',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Franklin',
    latitude: 34.347,
    longitude: -87.7154,
    postal_code: '35581'
  },
  '35582': {
    country: 'US',
    city: 'Red Bay',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Franklin',
    latitude: 34.4513,
    longitude: -88.1129,
    postal_code: '35582'
  },
  '35584': {
    country: 'US',
    city: 'Sipsey',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.8201,
    longitude: -87.0779,
    postal_code: '35584'
  },
  '35585': {
    country: 'US',
    city: 'Spruce Pine',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Franklin',
    latitude: 34.4246,
    longitude: -87.8145,
    postal_code: '35585'
  },
  '35586': {
    country: 'US',
    city: 'Sulligent',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lamar',
    latitude: 33.8549,
    longitude: -88.1508,
    postal_code: '35586'
  },
  '35587': {
    country: 'US',
    city: 'Townley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Walker',
    latitude: 33.847,
    longitude: -87.4372,
    postal_code: '35587'
  },
  '35592': {
    country: 'US',
    city: 'Vernon',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lamar',
    latitude: 33.7613,
    longitude: -88.0979,
    postal_code: '35592'
  },
  '35593': {
    country: 'US',
    city: 'Vina',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Franklin',
    latitude: 34.3712,
    longitude: -88.0774,
    postal_code: '35593'
  },
  '35594': {
    country: 'US',
    city: 'Winfield',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marion',
    latitude: 33.9303,
    longitude: -87.7972,
    postal_code: '35594'
  },
  '35601': {
    country: 'US',
    city: 'Decatur',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.5896,
    longitude: -86.9887,
    postal_code: '35601'
  },
  '35602': {
    country: 'US',
    city: 'Decatur',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.6059,
    longitude: -86.9833,
    postal_code: '35602'
  },
  '35603': {
    country: 'US',
    city: 'Decatur',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.5484,
    longitude: -87.0004,
    postal_code: '35603'
  },
  '35609': {
    country: 'US',
    city: 'Decatur',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.6059,
    longitude: -86.9833,
    postal_code: '35609'
  },
  '35610': {
    country: 'US',
    city: 'Anderson',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.9424,
    longitude: -87.2734,
    postal_code: '35610'
  },
  '35611': {
    country: 'US',
    city: 'Athens',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.8036,
    longitude: -86.9707,
    postal_code: '35611'
  },
  '35612': {
    country: 'US',
    city: 'Athens',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.7749,
    longitude: -87.0305,
    postal_code: '35612'
  },
  '35613': {
    country: 'US',
    city: 'Athens',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.8317,
    longitude: -86.8867,
    postal_code: '35613'
  },
  '35614': {
    country: 'US',
    city: 'Athens',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.8474,
    longitude: -87.0607,
    postal_code: '35614'
  },
  '35615': {
    country: 'US',
    city: 'Belle Mina',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.6568,
    longitude: -86.8792,
    postal_code: '35615'
  },
  '35616': {
    country: 'US',
    city: 'Cherokee',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Colbert',
    latitude: 34.7442,
    longitude: -87.9724,
    postal_code: '35616'
  },
  '35617': {
    country: 'US',
    city: 'Cloverdale',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.9387,
    longitude: -87.7714,
    postal_code: '35617'
  },
  '35618': {
    country: 'US',
    city: 'Courtland',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lawrence',
    latitude: 34.6719,
    longitude: -87.3143,
    postal_code: '35618'
  },
  '35619': {
    country: 'US',
    city: 'Danville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.3873,
    longitude: -87.1454,
    postal_code: '35619'
  },
  '35620': {
    country: 'US',
    city: 'Elkmont',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.929,
    longitude: -86.9739,
    postal_code: '35620'
  },
  '35621': {
    country: 'US',
    city: 'Eva',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.3323,
    longitude: -86.7589,
    postal_code: '35621'
  },
  '35622': {
    country: 'US',
    city: 'Falkville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.3477,
    longitude: -86.9131,
    postal_code: '35622'
  },
  '35630': {
    country: 'US',
    city: 'Florence',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.8305,
    longitude: -87.656,
    postal_code: '35630'
  },
  '35631': {
    country: 'US',
    city: 'Florence',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.87,
    longitude: -87.7069,
    postal_code: '35631'
  },
  '35632': {
    country: 'US',
    city: 'Florence',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.87,
    longitude: -87.7069,
    postal_code: '35632'
  },
  '35633': {
    country: 'US',
    city: 'Florence',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.8825,
    longitude: -87.7398,
    postal_code: '35633'
  },
  '35634': {
    country: 'US',
    city: 'Florence',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.9186,
    longitude: -87.6136,
    postal_code: '35634'
  },
  '35640': {
    country: 'US',
    city: 'Hartselle',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.4482,
    longitude: -86.9242,
    postal_code: '35640'
  },
  '35643': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lawrence',
    latitude: 34.6476,
    longitude: -87.1804,
    postal_code: '35643'
  },
  '35645': {
    country: 'US',
    city: 'Killen',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.9016,
    longitude: -87.5082,
    postal_code: '35645'
  },
  '35646': {
    country: 'US',
    city: 'Leighton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Colbert',
    latitude: 34.711,
    longitude: -87.5221,
    postal_code: '35646'
  },
  '35647': {
    country: 'US',
    city: 'Lester',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.9596,
    longitude: -87.1006,
    postal_code: '35647'
  },
  '35648': {
    country: 'US',
    city: 'Lexington',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.9559,
    longitude: -87.3935,
    postal_code: '35648'
  },
  '35649': {
    country: 'US',
    city: 'Mooresville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.6259,
    longitude: -86.8746,
    postal_code: '35649'
  },
  '35650': {
    country: 'US',
    city: 'Moulton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lawrence',
    latitude: 34.5058,
    longitude: -87.2224,
    postal_code: '35650'
  },
  '35651': {
    country: 'US',
    city: 'Mount Hope',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lawrence',
    latitude: 34.4793,
    longitude: -87.4769,
    postal_code: '35651'
  },
  '35652': {
    country: 'US',
    city: 'Rogersville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.8495,
    longitude: -87.3237,
    postal_code: '35652'
  },
  '35653': {
    country: 'US',
    city: 'Russellville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Franklin',
    latitude: 34.5066,
    longitude: -87.7257,
    postal_code: '35653'
  },
  '35654': {
    country: 'US',
    city: 'Russellville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Franklin',
    latitude: 34.4675,
    longitude: -87.6478,
    postal_code: '35654'
  },
  '35660': {
    country: 'US',
    city: 'Sheffield',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Colbert',
    latitude: 34.7578,
    longitude: -87.6971,
    postal_code: '35660'
  },
  '35661': {
    country: 'US',
    city: 'Muscle Shoals',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Colbert',
    latitude: 34.7561,
    longitude: -87.6304,
    postal_code: '35661'
  },
  '35662': {
    country: 'US',
    city: 'Muscle Shoals',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Colbert',
    latitude: 34.7448,
    longitude: -87.6675,
    postal_code: '35662'
  },
  '35670': {
    country: 'US',
    city: 'Somerville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.4995,
    longitude: -86.8009,
    postal_code: '35670'
  },
  '35671': {
    country: 'US',
    city: 'Tanner',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.6796,
    longitude: -86.9625,
    postal_code: '35671'
  },
  '35672': {
    country: 'US',
    city: 'Town Creek',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lawrence',
    latitude: 34.6491,
    longitude: -87.4262,
    postal_code: '35672'
  },
  '35673': {
    country: 'US',
    city: 'Trinity',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.5918,
    longitude: -87.0913,
    postal_code: '35673'
  },
  '35674': {
    country: 'US',
    city: 'Tuscumbia',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Colbert',
    latitude: 34.6874,
    longitude: -87.6833,
    postal_code: '35674'
  },
  '35677': {
    country: 'US',
    city: 'Waterloo',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lauderdale',
    latitude: 34.9357,
    longitude: -87.9624,
    postal_code: '35677'
  },
  '35699': {
    country: 'US',
    city: 'Decatur',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.6059,
    longitude: -86.9833,
    postal_code: '35699'
  },
  '35739': {
    country: 'US',
    city: 'Ardmore',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.9804,
    longitude: -86.8346,
    postal_code: '35739'
  },
  '35740': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.9446,
    longitude: -85.7277,
    postal_code: '35740'
  },
  '35741': {
    country: 'US',
    city: 'Brownsboro',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7167,
    longitude: -86.4687,
    postal_code: '35741'
  },
  '35742': {
    country: 'US',
    city: 'Capshaw',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Limestone',
    latitude: 34.7731,
    longitude: -86.7928,
    postal_code: '35742'
  },
  '35744': {
    country: 'US',
    city: 'Dutton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.6046,
    longitude: -85.9067,
    postal_code: '35744'
  },
  '35745': {
    country: 'US',
    city: 'Estillfork',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.913,
    longitude: -86.1716,
    postal_code: '35745'
  },
  '35746': {
    country: 'US',
    city: 'Fackler',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.8259,
    longitude: -85.9846,
    postal_code: '35746'
  },
  '35747': {
    country: 'US',
    city: 'Grant',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marshall',
    latitude: 34.4959,
    longitude: -86.259,
    postal_code: '35747'
  },
  '35748': {
    country: 'US',
    city: 'Gurley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.714,
    longitude: -86.394,
    postal_code: '35748'
  },
  '35749': {
    country: 'US',
    city: 'Harvest',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.8273,
    longitude: -86.7499,
    postal_code: '35749'
  },
  '35750': {
    country: 'US',
    city: 'Hazel Green',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.9496,
    longitude: -86.5935,
    postal_code: '35750'
  },
  '35751': {
    country: 'US',
    city: 'Hollytree',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.7955,
    longitude: -86.2743,
    postal_code: '35751'
  },
  '35752': {
    country: 'US',
    city: 'Hollywood',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.7304,
    longitude: -85.9532,
    postal_code: '35752'
  },
  '35754': {
    country: 'US',
    city: 'Laceys Spring',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.4996,
    longitude: -86.6129,
    postal_code: '35754'
  },
  '35755': {
    country: 'US',
    city: 'Langston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.5001,
    longitude: -86.1353,
    postal_code: '35755'
  },
  '35756': {
    country: 'US',
    city: 'Madison',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.643,
    longitude: -86.8168,
    postal_code: '35756'
  },
  '35757': {
    country: 'US',
    city: 'Madison',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7782,
    longitude: -86.7442,
    postal_code: '35757'
  },
  '35758': {
    country: 'US',
    city: 'Madison',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7108,
    longitude: -86.7425,
    postal_code: '35758'
  },
  '35759': {
    country: 'US',
    city: 'Meridianville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.8618,
    longitude: -86.5789,
    postal_code: '35759'
  },
  '35760': {
    country: 'US',
    city: 'New Hope',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.5494,
    longitude: -86.3961,
    postal_code: '35760'
  },
  '35761': {
    country: 'US',
    city: 'New Market',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.9,
    longitude: -86.4487,
    postal_code: '35761'
  },
  '35762': {
    country: 'US',
    city: 'Normal',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.789,
    longitude: -86.5719,
    postal_code: '35762'
  },
  '35763': {
    country: 'US',
    city: 'Owens Cross Roads',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.6215,
    longitude: -86.4644,
    postal_code: '35763'
  },
  '35764': {
    country: 'US',
    city: 'Paint Rock',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.7031,
    longitude: -86.3068,
    postal_code: '35764'
  },
  '35765': {
    country: 'US',
    city: 'Pisgah',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.6809,
    longitude: -85.8478,
    postal_code: '35765'
  },
  '35766': {
    country: 'US',
    city: 'Princeton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.8713,
    longitude: -86.2513,
    postal_code: '35766'
  },
  '35767': {
    country: 'US',
    city: 'Ryland',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7698,
    longitude: -86.4808,
    postal_code: '35767'
  },
  '35768': {
    country: 'US',
    city: 'Scottsboro',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.7507,
    longitude: -86.1422,
    postal_code: '35768'
  },
  '35769': {
    country: 'US',
    city: 'Scottsboro',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.618,
    longitude: -86.0562,
    postal_code: '35769'
  },
  '35771': {
    country: 'US',
    city: 'Section',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.5433,
    longitude: -85.994,
    postal_code: '35771'
  },
  '35772': {
    country: 'US',
    city: 'Stevenson',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.8769,
    longitude: -85.8508,
    postal_code: '35772'
  },
  '35773': {
    country: 'US',
    city: 'Toney',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.9116,
    longitude: -86.7,
    postal_code: '35773'
  },
  '35774': {
    country: 'US',
    city: 'Trenton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.7632,
    longitude: -86.2295,
    postal_code: '35774'
  },
  '35775': {
    country: 'US',
    city: 'Valhermoso Springs',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Morgan',
    latitude: 34.542,
    longitude: -86.6871,
    postal_code: '35775'
  },
  '35776': {
    country: 'US',
    city: 'Woodville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.6689,
    longitude: -86.2296,
    postal_code: '35776'
  },
  '35801': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7269,
    longitude: -86.5673,
    postal_code: '35801'
  },
  '35802': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.6679,
    longitude: -86.5603,
    postal_code: '35802'
  },
  '35803': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.6205,
    longitude: -86.551,
    postal_code: '35803'
  },
  '35804': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7284,
    longitude: -86.5853,
    postal_code: '35804'
  },
  '35805': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7059,
    longitude: -86.6165,
    postal_code: '35805'
  },
  '35806': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7448,
    longitude: -86.6704,
    postal_code: '35806'
  },
  '35807': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.734,
    longitude: -86.5229,
    postal_code: '35807'
  },
  '35808': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.6845,
    longitude: -86.6538,
    postal_code: '35808'
  },
  '35809': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.734,
    longitude: -86.5229,
    postal_code: '35809'
  },
  '35810': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7784,
    longitude: -86.6091,
    postal_code: '35810'
  },
  '35811': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7789,
    longitude: -86.5438,
    postal_code: '35811'
  },
  '35812': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.734,
    longitude: -86.5229,
    postal_code: '35812'
  },
  '35813': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.734,
    longitude: -86.5229,
    postal_code: '35813'
  },
  '35814': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.734,
    longitude: -86.5229,
    postal_code: '35814'
  },
  '35815': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.734,
    longitude: -86.5229,
    postal_code: '35815'
  },
  '35816': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7389,
    longitude: -86.6249,
    postal_code: '35816'
  },
  '35824': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.6583,
    longitude: -86.7295,
    postal_code: '35824'
  },
  '35893': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7304,
    longitude: -86.5861,
    postal_code: '35893'
  },
  '35894': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.734,
    longitude: -86.5229,
    postal_code: '35894'
  },
  '35895': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7304,
    longitude: -86.5861,
    postal_code: '35895'
  },
  '35896': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7543,
    longitude: -86.6546,
    postal_code: '35896'
  },
  '35897': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.734,
    longitude: -86.5229,
    postal_code: '35897'
  },
  '35898': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.734,
    longitude: -86.5229,
    postal_code: '35898'
  },
  '35899': {
    country: 'US',
    city: 'Huntsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Madison',
    latitude: 34.7339,
    longitude: -86.6456,
    postal_code: '35899'
  },
  '35901': {
    country: 'US',
    city: 'Gadsden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 34.048,
    longitude: -85.9246,
    postal_code: '35901'
  },
  '35902': {
    country: 'US',
    city: 'Gadsden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 34.0143,
    longitude: -86.0066,
    postal_code: '35902'
  },
  '35903': {
    country: 'US',
    city: 'Gadsden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 33.9845,
    longitude: -85.9077,
    postal_code: '35903'
  },
  '35904': {
    country: 'US',
    city: 'Gadsden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 34.0217,
    longitude: -86.0495,
    postal_code: '35904'
  },
  '35905': {
    country: 'US',
    city: 'Gadsden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 33.9352,
    longitude: -85.9576,
    postal_code: '35905'
  },
  '35906': {
    country: 'US',
    city: 'Rainbow City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 33.9318,
    longitude: -86.0898,
    postal_code: '35906'
  },
  '35907': {
    country: 'US',
    city: 'Gadsden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 33.8979,
    longitude: -86.0245,
    postal_code: '35907'
  },
  '35950': {
    country: 'US',
    city: 'Albertville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marshall',
    latitude: 34.2739,
    longitude: -86.2064,
    postal_code: '35950'
  },
  '35951': {
    country: 'US',
    city: 'Albertville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marshall',
    latitude: 34.3223,
    longitude: -86.1935,
    postal_code: '35951'
  },
  '35952': {
    country: 'US',
    city: 'Altoona',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 34.071,
    longitude: -86.2096,
    postal_code: '35952'
  },
  '35953': {
    country: 'US',
    city: 'Ashville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.8374,
    longitude: -86.2552,
    postal_code: '35953'
  },
  '35954': {
    country: 'US',
    city: 'Attalla',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 34.0296,
    longitude: -86.0967,
    postal_code: '35954'
  },
  '35956': {
    country: 'US',
    city: 'Boaz',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marshall',
    latitude: 34.1437,
    longitude: -86.148,
    postal_code: '35956'
  },
  '35957': {
    country: 'US',
    city: 'Boaz',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marshall',
    latitude: 34.1921,
    longitude: -86.1941,
    postal_code: '35957'
  },
  '35958': {
    country: 'US',
    city: 'Bryant',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.945,
    longitude: -85.6324,
    postal_code: '35958'
  },
  '35959': {
    country: 'US',
    city: 'Cedar Bluff',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cherokee',
    latitude: 34.2417,
    longitude: -85.6026,
    postal_code: '35959'
  },
  '35960': {
    country: 'US',
    city: 'Centre',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cherokee',
    latitude: 34.1116,
    longitude: -85.6092,
    postal_code: '35960'
  },
  '35961': {
    country: 'US',
    city: 'Collinsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.268,
    longitude: -85.862,
    postal_code: '35961'
  },
  '35962': {
    country: 'US',
    city: 'Crossville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.2588,
    longitude: -86.0306,
    postal_code: '35962'
  },
  '35963': {
    country: 'US',
    city: 'Dawson',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.3568,
    longitude: -85.9292,
    postal_code: '35963'
  },
  '35964': {
    country: 'US',
    city: 'Douglas',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marshall',
    latitude: 34.1737,
    longitude: -86.3236,
    postal_code: '35964'
  },
  '35966': {
    country: 'US',
    city: 'Flat Rock',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.8076,
    longitude: -85.7084,
    postal_code: '35966'
  },
  '35967': {
    country: 'US',
    city: 'Fort Payne',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.4368,
    longitude: -85.7124,
    postal_code: '35967'
  },
  '35968': {
    country: 'US',
    city: 'Fort Payne',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.4898,
    longitude: -85.7657,
    postal_code: '35968'
  },
  '35971': {
    country: 'US',
    city: 'Fyffe',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.4373,
    longitude: -85.9288,
    postal_code: '35971'
  },
  '35972': {
    country: 'US',
    city: 'Gallant',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 33.9976,
    longitude: -86.2346,
    postal_code: '35972'
  },
  '35973': {
    country: 'US',
    city: 'Gaylesville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cherokee',
    latitude: 34.3573,
    longitude: -85.5589,
    postal_code: '35973'
  },
  '35974': {
    country: 'US',
    city: 'Geraldine',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.3592,
    longitude: -86.024,
    postal_code: '35974'
  },
  '35975': {
    country: 'US',
    city: 'Groveoak',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.4359,
    longitude: -86.04,
    postal_code: '35975'
  },
  '35976': {
    country: 'US',
    city: 'Guntersville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marshall',
    latitude: 34.3449,
    longitude: -86.2752,
    postal_code: '35976'
  },
  '35978': {
    country: 'US',
    city: 'Henagar',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.6186,
    longitude: -85.7274,
    postal_code: '35978'
  },
  '35979': {
    country: 'US',
    city: 'Higdon',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Jackson',
    latitude: 34.8391,
    longitude: -85.6196,
    postal_code: '35979'
  },
  '35980': {
    country: 'US',
    city: 'Horton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marshall',
    latitude: 34.19,
    longitude: -86.3173,
    postal_code: '35980'
  },
  '35981': {
    country: 'US',
    city: 'Ider',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.7351,
    longitude: -85.6416,
    postal_code: '35981'
  },
  '35983': {
    country: 'US',
    city: 'Leesburg',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cherokee',
    latitude: 34.1911,
    longitude: -85.7705,
    postal_code: '35983'
  },
  '35984': {
    country: 'US',
    city: 'Mentone',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.5872,
    longitude: -85.5777,
    postal_code: '35984'
  },
  '35986': {
    country: 'US',
    city: 'Rainsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.4989,
    longitude: -85.8446,
    postal_code: '35986'
  },
  '35987': {
    country: 'US',
    city: 'Steele',
    state: 'Alabama',
    state_short: 'AL',
    county: 'St. Clair',
    latitude: 33.9417,
    longitude: -86.2289,
    postal_code: '35987'
  },
  '35988': {
    country: 'US',
    city: 'Sylvania',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.559,
    longitude: -85.7921,
    postal_code: '35988'
  },
  '35989': {
    country: 'US',
    city: 'Valley Head',
    state: 'Alabama',
    state_short: 'AL',
    county: 'DeKalb',
    latitude: 34.5697,
    longitude: -85.6272,
    postal_code: '35989'
  },
  '35990': {
    country: 'US',
    city: 'Walnut Grove',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Etowah',
    latitude: 34.0657,
    longitude: -86.3064,
    postal_code: '35990'
  },
  '36003': {
    country: 'US',
    city: 'Autaugaville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Autauga',
    latitude: 32.4625,
    longitude: -86.7149,
    postal_code: '36003'
  },
  '36005': {
    country: 'US',
    city: 'Banks',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pike',
    latitude: 31.8368,
    longitude: -85.7998,
    postal_code: '36005'
  },
  '36006': {
    country: 'US',
    city: 'Billingsley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Autauga',
    latitude: 32.6596,
    longitude: -86.7178,
    postal_code: '36006'
  },
  '36008': {
    country: 'US',
    city: 'Booth',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Autauga',
    latitude: 32.5016,
    longitude: -86.5727,
    postal_code: '36008'
  },
  '36009': {
    country: 'US',
    city: 'Brantley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Crenshaw',
    latitude: 31.571,
    longitude: -86.2743,
    postal_code: '36009'
  },
  '36010': {
    country: 'US',
    city: 'Brundidge',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pike',
    latitude: 31.7013,
    longitude: -85.8177,
    postal_code: '36010'
  },
  '36013': {
    country: 'US',
    city: 'Cecil',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2937,
    longitude: -85.9827,
    postal_code: '36013'
  },
  '36015': {
    country: 'US',
    city: 'Chapman',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Butler',
    latitude: 31.6427,
    longitude: -86.7343,
    postal_code: '36015'
  },
  '36016': {
    country: 'US',
    city: 'Clayton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Barbour',
    latitude: 31.8874,
    longitude: -85.4509,
    postal_code: '36016'
  },
  '36017': {
    country: 'US',
    city: 'Clio',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Barbour',
    latitude: 31.6852,
    longitude: -85.5904,
    postal_code: '36017'
  },
  '36020': {
    country: 'US',
    city: 'Coosada',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Elmore',
    latitude: 32.5056,
    longitude: -86.3322,
    postal_code: '36020'
  },
  '36022': {
    country: 'US',
    city: 'Deatsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Elmore',
    latitude: 32.6082,
    longitude: -86.3958,
    postal_code: '36022'
  },
  '36023': {
    country: 'US',
    city: 'East Tallassee',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tallapoosa',
    latitude: 32.8002,
    longitude: -85.8034,
    postal_code: '36023'
  },
  '36024': {
    country: 'US',
    city: 'Eclectic',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Elmore',
    latitude: 32.6542,
    longitude: -86.0316,
    postal_code: '36024'
  },
  '36025': {
    country: 'US',
    city: 'Elmore',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Elmore',
    latitude: 32.5454,
    longitude: -86.3161,
    postal_code: '36025'
  },
  '36026': {
    country: 'US',
    city: 'Equality',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coosa',
    latitude: 32.8139,
    longitude: -86.1051,
    postal_code: '36026'
  },
  '36027': {
    country: 'US',
    city: 'Eufaula',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Barbour',
    latitude: 31.9051,
    longitude: -85.1656,
    postal_code: '36027'
  },
  '36028': {
    country: 'US',
    city: 'Dozier',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Crenshaw',
    latitude: 31.5066,
    longitude: -86.3663,
    postal_code: '36028'
  },
  '36029': {
    country: 'US',
    city: 'Fitzpatrick',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bullock',
    latitude: 32.2165,
    longitude: -85.8888,
    postal_code: '36029'
  },
  '36030': {
    country: 'US',
    city: 'Forest Home',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Butler',
    latitude: 31.8501,
    longitude: -86.8372,
    postal_code: '36030'
  },
  '36031': {
    country: 'US',
    city: 'Fort Davis',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Macon',
    latitude: 32.2507,
    longitude: -85.7524,
    postal_code: '36031'
  },
  '36032': {
    country: 'US',
    city: 'Fort Deposit',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lowndes',
    latitude: 31.996,
    longitude: -86.5761,
    postal_code: '36032'
  },
  '36033': {
    country: 'US',
    city: 'Georgiana',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Butler',
    latitude: 31.6287,
    longitude: -86.734,
    postal_code: '36033'
  },
  '36034': {
    country: 'US',
    city: 'Glenwood',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Crenshaw',
    latitude: 31.6641,
    longitude: -86.171,
    postal_code: '36034'
  },
  '36035': {
    country: 'US',
    city: 'Goshen',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pike',
    latitude: 31.7946,
    longitude: -86.1277,
    postal_code: '36035'
  },
  '36036': {
    country: 'US',
    city: 'Grady',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.0194,
    longitude: -86.1292,
    postal_code: '36036'
  },
  '36037': {
    country: 'US',
    city: 'Greenville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Butler',
    latitude: 31.81,
    longitude: -86.6229,
    postal_code: '36037'
  },
  '36038': {
    country: 'US',
    city: 'Gantt',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Covington',
    latitude: 31.4068,
    longitude: -86.4841,
    postal_code: '36038'
  },
  '36039': {
    country: 'US',
    city: 'Hardaway',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Macon',
    latitude: 32.3129,
    longitude: -85.8838,
    postal_code: '36039'
  },
  '36040': {
    country: 'US',
    city: 'Hayneville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lowndes',
    latitude: 32.1957,
    longitude: -86.655,
    postal_code: '36040'
  },
  '36041': {
    country: 'US',
    city: 'Highland Home',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Crenshaw',
    latitude: 31.9283,
    longitude: -86.2971,
    postal_code: '36041'
  },
  '36042': {
    country: 'US',
    city: 'Honoraville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Crenshaw',
    latitude: 31.8784,
    longitude: -86.3981,
    postal_code: '36042'
  },
  '36043': {
    country: 'US',
    city: 'Hope Hull',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2243,
    longitude: -86.3932,
    postal_code: '36043'
  },
  '36045': {
    country: 'US',
    city: 'Kent',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Elmore',
    latitude: 32.619,
    longitude: -85.9486,
    postal_code: '36045'
  },
  '36046': {
    country: 'US',
    city: 'Lapine',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 31.9657,
    longitude: -86.2844,
    postal_code: '36046'
  },
  '36047': {
    country: 'US',
    city: 'Letohatchee',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lowndes',
    latitude: 32.0862,
    longitude: -86.488,
    postal_code: '36047'
  },
  '36048': {
    country: 'US',
    city: 'Louisville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Barbour',
    latitude: 31.7943,
    longitude: -85.5581,
    postal_code: '36048'
  },
  '36049': {
    country: 'US',
    city: 'Luverne',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Crenshaw',
    latitude: 31.7074,
    longitude: -86.2566,
    postal_code: '36049'
  },
  '36051': {
    country: 'US',
    city: 'Marbury',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Autauga',
    latitude: 32.6841,
    longitude: -86.4946,
    postal_code: '36051'
  },
  '36052': {
    country: 'US',
    city: 'Mathews',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.1283,
    longitude: -86.0413,
    postal_code: '36052'
  },
  '36053': {
    country: 'US',
    city: 'Midway',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bullock',
    latitude: 32.0955,
    longitude: -85.5315,
    postal_code: '36053'
  },
  '36054': {
    country: 'US',
    city: 'Millbrook',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Elmore',
    latitude: 32.4995,
    longitude: -86.3641,
    postal_code: '36054'
  },
  '36057': {
    country: 'US',
    city: 'Mount Meigs',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36057'
  },
  '36061': {
    country: 'US',
    city: 'Perote',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bullock',
    latitude: 32.0928,
    longitude: -85.7046,
    postal_code: '36061'
  },
  '36062': {
    country: 'US',
    city: 'Petrey',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Crenshaw',
    latitude: 31.8488,
    longitude: -86.2077,
    postal_code: '36062'
  },
  '36064': {
    country: 'US',
    city: 'Pike Road',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3357,
    longitude: -86.0959,
    postal_code: '36064'
  },
  '36065': {
    country: 'US',
    city: 'Pine Level',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.0726,
    longitude: -86.0606,
    postal_code: '36065'
  },
  '36066': {
    country: 'US',
    city: 'Prattville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Autauga',
    latitude: 32.4787,
    longitude: -86.43,
    postal_code: '36066'
  },
  '36067': {
    country: 'US',
    city: 'Prattville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Autauga',
    latitude: 32.4715,
    longitude: -86.4831,
    postal_code: '36067'
  },
  '36068': {
    country: 'US',
    city: 'Prattville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Autauga',
    latitude: 32.464,
    longitude: -86.4597,
    postal_code: '36068'
  },
  '36069': {
    country: 'US',
    city: 'Ramer',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.0677,
    longitude: -86.2463,
    postal_code: '36069'
  },
  '36071': {
    country: 'US',
    city: 'Rutledge',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Crenshaw',
    latitude: 31.7062,
    longitude: -86.3715,
    postal_code: '36071'
  },
  '36072': {
    country: 'US',
    city: 'Eufaula',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Barbour',
    latitude: 31.8913,
    longitude: -85.1455,
    postal_code: '36072'
  },
  '36075': {
    country: 'US',
    city: 'Shorter',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Macon',
    latitude: 32.3836,
    longitude: -85.9162,
    postal_code: '36075'
  },
  '36078': {
    country: 'US',
    city: 'Tallassee',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Elmore',
    latitude: 32.551,
    longitude: -85.8978,
    postal_code: '36078'
  },
  '36079': {
    country: 'US',
    city: 'Troy',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pike',
    latitude: 31.7595,
    longitude: -86.0107,
    postal_code: '36079'
  },
  '36080': {
    country: 'US',
    city: 'Titus',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Elmore',
    latitude: 32.69,
    longitude: -86.2393,
    postal_code: '36080'
  },
  '36081': {
    country: 'US',
    city: 'Troy',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pike',
    latitude: 31.7945,
    longitude: -85.9655,
    postal_code: '36081'
  },
  '36082': {
    country: 'US',
    city: 'Troy',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Pike',
    latitude: 31.8028,
    longitude: -85.9546,
    postal_code: '36082'
  },
  '36083': {
    country: 'US',
    city: 'Tuskegee',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Macon',
    latitude: 32.4316,
    longitude: -85.6861,
    postal_code: '36083'
  },
  '36087': {
    country: 'US',
    city: 'Tuskegee Institute',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Macon',
    latitude: 32.4093,
    longitude: -85.738,
    postal_code: '36087'
  },
  '36088': {
    country: 'US',
    city: 'Tuskegee Institute',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Macon',
    latitude: 32.4145,
    longitude: -85.7253,
    postal_code: '36088'
  },
  '36089': {
    country: 'US',
    city: 'Union Springs',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bullock',
    latitude: 32.1443,
    longitude: -85.7149,
    postal_code: '36089'
  },
  '36091': {
    country: 'US',
    city: 'Verbena',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chilton',
    latitude: 32.7499,
    longitude: -86.5114,
    postal_code: '36091'
  },
  '36092': {
    country: 'US',
    city: 'Wetumpka',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Elmore',
    latitude: 32.5927,
    longitude: -86.2153,
    postal_code: '36092'
  },
  '36093': {
    country: 'US',
    city: 'Wetumpka',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Elmore',
    latitude: 32.5622,
    longitude: -86.0994,
    postal_code: '36093'
  },
  '36101': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.357,
    longitude: -86.2578,
    postal_code: '36101'
  },
  '36102': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36102'
  },
  '36103': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3462,
    longitude: -86.2873,
    postal_code: '36103'
  },
  '36104': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.373,
    longitude: -86.3081,
    postal_code: '36104'
  },
  '36105': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3257,
    longitude: -86.3104,
    postal_code: '36105'
  },
  '36106': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3543,
    longitude: -86.2673,
    postal_code: '36106'
  },
  '36107': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3804,
    longitude: -86.2799,
    postal_code: '36107'
  },
  '36108': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3417,
    longitude: -86.3529,
    postal_code: '36108'
  },
  '36109': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3834,
    longitude: -86.2434,
    postal_code: '36109'
  },
  '36110': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.4217,
    longitude: -86.275,
    postal_code: '36110'
  },
  '36111': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3374,
    longitude: -86.2715,
    postal_code: '36111'
  },
  '36112': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3808,
    longitude: -86.3491,
    postal_code: '36112'
  },
  '36113': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3846,
    longitude: -86.3039,
    postal_code: '36113'
  },
  '36114': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.404,
    longitude: -86.2539,
    postal_code: '36114'
  },
  '36115': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.4076,
    longitude: -86.2474,
    postal_code: '36115'
  },
  '36116': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3129,
    longitude: -86.2421,
    postal_code: '36116'
  },
  '36117': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3736,
    longitude: -86.1833,
    postal_code: '36117'
  },
  '36118': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36118'
  },
  '36119': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36119'
  },
  '36120': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36120'
  },
  '36121': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36121'
  },
  '36123': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.3668,
    longitude: -86.3,
    postal_code: '36123'
  },
  '36124': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36124'
  },
  '36125': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36125'
  },
  '36130': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36130'
  },
  '36131': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36131'
  },
  '36132': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36132'
  },
  '36135': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36135'
  },
  '36140': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36140'
  },
  '36141': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36141'
  },
  '36142': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36142'
  },
  '36177': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36177'
  },
  '36191': {
    country: 'US',
    city: 'Montgomery',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Montgomery',
    latitude: 32.2334,
    longitude: -86.2085,
    postal_code: '36191'
  },
  '36201': {
    country: 'US',
    city: 'Anniston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.6539,
    longitude: -85.8382,
    postal_code: '36201'
  },
  '36202': {
    country: 'US',
    city: 'Anniston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.7622,
    longitude: -85.8378,
    postal_code: '36202'
  },
  '36203': {
    country: 'US',
    city: 'Oxford',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.5815,
    longitude: -85.8328,
    postal_code: '36203'
  },
  '36204': {
    country: 'US',
    city: 'Anniston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.7622,
    longitude: -85.8378,
    postal_code: '36204'
  },
  '36205': {
    country: 'US',
    city: 'Anniston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.7358,
    longitude: -85.7933,
    postal_code: '36205'
  },
  '36206': {
    country: 'US',
    city: 'Anniston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.7191,
    longitude: -85.8389,
    postal_code: '36206'
  },
  '36207': {
    country: 'US',
    city: 'Anniston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.6803,
    longitude: -85.7137,
    postal_code: '36207'
  },
  '36250': {
    country: 'US',
    city: 'Alexandria',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.7808,
    longitude: -85.8924,
    postal_code: '36250'
  },
  '36251': {
    country: 'US',
    city: 'Ashland',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clay',
    latitude: 33.2474,
    longitude: -85.829,
    postal_code: '36251'
  },
  '36253': {
    country: 'US',
    city: 'Bynum',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.7622,
    longitude: -85.8378,
    postal_code: '36253'
  },
  '36254': {
    country: 'US',
    city: 'Choccolocco',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.6573,
    longitude: -85.6991,
    postal_code: '36254'
  },
  '36255': {
    country: 'US',
    city: 'Cragford',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clay',
    latitude: 33.2171,
    longitude: -85.7108,
    postal_code: '36255'
  },
  '36256': {
    country: 'US',
    city: 'Daviston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tallapoosa',
    latitude: 33.0335,
    longitude: -85.7538,
    postal_code: '36256'
  },
  '36257': {
    country: 'US',
    city: 'De Armanville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.7622,
    longitude: -85.8378,
    postal_code: '36257'
  },
  '36258': {
    country: 'US',
    city: 'Delta',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clay',
    latitude: 33.4573,
    longitude: -85.6793,
    postal_code: '36258'
  },
  '36260': {
    country: 'US',
    city: 'Eastaboga',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.6031,
    longitude: -85.9608,
    postal_code: '36260'
  },
  '36261': {
    country: 'US',
    city: 'Edwardsville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cleburne',
    latitude: 33.7066,
    longitude: -85.5099,
    postal_code: '36261'
  },
  '36262': {
    country: 'US',
    city: 'Fruithurst',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cleburne',
    latitude: 33.7717,
    longitude: -85.4381,
    postal_code: '36262'
  },
  '36263': {
    country: 'US',
    city: 'Graham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Randolph',
    latitude: 33.463,
    longitude: -85.334,
    postal_code: '36263'
  },
  '36264': {
    country: 'US',
    city: 'Heflin',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cleburne',
    latitude: 33.6115,
    longitude: -85.5885,
    postal_code: '36264'
  },
  '36265': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.831,
    longitude: -85.7752,
    postal_code: '36265'
  },
  '36266': {
    country: 'US',
    city: 'Lineville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clay',
    latitude: 33.3286,
    longitude: -85.7346,
    postal_code: '36266'
  },
  '36267': {
    country: 'US',
    city: 'Millerville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clay',
    latitude: 33.1915,
    longitude: -85.9258,
    postal_code: '36267'
  },
  '36268': {
    country: 'US',
    city: 'Munford',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Talladega',
    latitude: 33.541,
    longitude: -85.9363,
    postal_code: '36268'
  },
  '36269': {
    country: 'US',
    city: 'Muscadine',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cleburne',
    latitude: 33.7529,
    longitude: -85.3789,
    postal_code: '36269'
  },
  '36271': {
    country: 'US',
    city: 'Ohatchee',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.7788,
    longitude: -86.0254,
    postal_code: '36271'
  },
  '36272': {
    country: 'US',
    city: 'Piedmont',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.8389,
    longitude: -85.646,
    postal_code: '36272'
  },
  '36273': {
    country: 'US',
    city: 'Ranburne',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cleburne',
    latitude: 33.5616,
    longitude: -85.3786,
    postal_code: '36273'
  },
  '36274': {
    country: 'US',
    city: 'Roanoke',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Randolph',
    latitude: 33.1512,
    longitude: -85.3722,
    postal_code: '36274'
  },
  '36275': {
    country: 'US',
    city: 'Spring Garden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Cherokee',
    latitude: 33.9778,
    longitude: -85.5521,
    postal_code: '36275'
  },
  '36276': {
    country: 'US',
    city: 'Wadley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Randolph',
    latitude: 33.1492,
    longitude: -85.5513,
    postal_code: '36276'
  },
  '36277': {
    country: 'US',
    city: 'Weaver',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.7563,
    longitude: -85.8107,
    postal_code: '36277'
  },
  '36278': {
    country: 'US',
    city: 'Wedowee',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Randolph',
    latitude: 33.3019,
    longitude: -85.4737,
    postal_code: '36278'
  },
  '36279': {
    country: 'US',
    city: 'Wellington',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Calhoun',
    latitude: 33.8226,
    longitude: -85.8927,
    postal_code: '36279'
  },
  '36280': {
    country: 'US',
    city: 'Woodland',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Randolph',
    latitude: 33.3743,
    longitude: -85.3997,
    postal_code: '36280'
  },
  '36301': {
    country: 'US',
    city: 'Dothan',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.1481,
    longitude: -85.3718,
    postal_code: '36301'
  },
  '36302': {
    country: 'US',
    city: 'Dothan',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.2232,
    longitude: -85.3905,
    postal_code: '36302'
  },
  '36303': {
    country: 'US',
    city: 'Dothan',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.2668,
    longitude: -85.3971,
    postal_code: '36303'
  },
  '36304': {
    country: 'US',
    city: 'Dothan',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.156,
    longitude: -85.3559,
    postal_code: '36304'
  },
  '36305': {
    country: 'US',
    city: 'Dothan',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.2197,
    longitude: -85.4828,
    postal_code: '36305'
  },
  '36310': {
    country: 'US',
    city: 'Abbeville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Henry',
    latitude: 31.5755,
    longitude: -85.279,
    postal_code: '36310'
  },
  '36311': {
    country: 'US',
    city: 'Ariton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dale',
    latitude: 31.583,
    longitude: -85.7077,
    postal_code: '36311'
  },
  '36312': {
    country: 'US',
    city: 'Ashford',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.1888,
    longitude: -85.2535,
    postal_code: '36312'
  },
  '36313': {
    country: 'US',
    city: 'Bellwood',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Geneva',
    latitude: 31.1732,
    longitude: -85.7933,
    postal_code: '36313'
  },
  '36314': {
    country: 'US',
    city: 'Black',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Geneva',
    latitude: 31.0132,
    longitude: -85.7456,
    postal_code: '36314'
  },
  '36316': {
    country: 'US',
    city: 'Chancellor',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Geneva',
    latitude: 31.1767,
    longitude: -85.8135,
    postal_code: '36316'
  },
  '36317': {
    country: 'US',
    city: 'Clopton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dale',
    latitude: 31.6446,
    longitude: -85.3911,
    postal_code: '36317'
  },
  '36318': {
    country: 'US',
    city: 'Coffee Springs',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Geneva',
    latitude: 31.1388,
    longitude: -85.9182,
    postal_code: '36318'
  },
  '36319': {
    country: 'US',
    city: 'Columbia',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.3352,
    longitude: -85.1455,
    postal_code: '36319'
  },
  '36320': {
    country: 'US',
    city: 'Cottonwood',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.0507,
    longitude: -85.3154,
    postal_code: '36320'
  },
  '36321': {
    country: 'US',
    city: 'Cowarts',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.2025,
    longitude: -85.3014,
    postal_code: '36321'
  },
  '36322': {
    country: 'US',
    city: 'Daleville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dale',
    latitude: 31.2811,
    longitude: -85.7305,
    postal_code: '36322'
  },
  '36323': {
    country: 'US',
    city: 'Elba',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coffee',
    latitude: 31.4137,
    longitude: -86.0777,
    postal_code: '36323'
  },
  '36330': {
    country: 'US',
    city: 'Enterprise',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coffee',
    latitude: 31.3408,
    longitude: -85.8421,
    postal_code: '36330'
  },
  '36331': {
    country: 'US',
    city: 'Enterprise',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coffee',
    latitude: 31.2978,
    longitude: -85.9036,
    postal_code: '36331'
  },
  '36340': {
    country: 'US',
    city: 'Geneva',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Geneva',
    latitude: 31.0414,
    longitude: -85.8847,
    postal_code: '36340'
  },
  '36343': {
    country: 'US',
    city: 'Gordon',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.1002,
    longitude: -85.1234,
    postal_code: '36343'
  },
  '36344': {
    country: 'US',
    city: 'Hartford',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Geneva',
    latitude: 31.086,
    longitude: -85.7192,
    postal_code: '36344'
  },
  '36345': {
    country: 'US',
    city: 'Headland',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Henry',
    latitude: 31.3534,
    longitude: -85.3323,
    postal_code: '36345'
  },
  '36346': {
    country: 'US',
    city: 'Jack',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coffee',
    latitude: 31.5652,
    longitude: -85.971,
    postal_code: '36346'
  },
  '36349': {
    country: 'US',
    city: 'Malvern',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Geneva',
    latitude: 31.1212,
    longitude: -85.5592,
    postal_code: '36349'
  },
  '36350': {
    country: 'US',
    city: 'Midland City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dale',
    latitude: 31.3191,
    longitude: -85.4938,
    postal_code: '36350'
  },
  '36351': {
    country: 'US',
    city: 'New Brockton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coffee',
    latitude: 31.369,
    longitude: -85.9404,
    postal_code: '36351'
  },
  '36352': {
    country: 'US',
    city: 'Newton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dale',
    latitude: 31.3311,
    longitude: -85.5992,
    postal_code: '36352'
  },
  '36353': {
    country: 'US',
    city: 'Newville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Henry',
    latitude: 31.4403,
    longitude: -85.3287,
    postal_code: '36353'
  },
  '36360': {
    country: 'US',
    city: 'Ozark',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dale',
    latitude: 31.4391,
    longitude: -85.6436,
    postal_code: '36360'
  },
  '36361': {
    country: 'US',
    city: 'Ozark',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dale',
    latitude: 31.4591,
    longitude: -85.6405,
    postal_code: '36361'
  },
  '36362': {
    country: 'US',
    city: 'Fort Rucker',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dale',
    latitude: 31.348,
    longitude: -85.7214,
    postal_code: '36362'
  },
  '36370': {
    country: 'US',
    city: 'Pansey',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.1403,
    longitude: -85.1573,
    postal_code: '36370'
  },
  '36371': {
    country: 'US',
    city: 'Pinckard',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dale',
    latitude: 31.3074,
    longitude: -85.5263,
    postal_code: '36371'
  },
  '36373': {
    country: 'US',
    city: 'Shorterville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Henry',
    latitude: 31.501,
    longitude: -85.1135,
    postal_code: '36373'
  },
  '36374': {
    country: 'US',
    city: 'Skipperville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dale',
    latitude: 31.5748,
    longitude: -85.537,
    postal_code: '36374'
  },
  '36375': {
    country: 'US',
    city: 'Slocomb',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Geneva',
    latitude: 31.0956,
    longitude: -85.583,
    postal_code: '36375'
  },
  '36376': {
    country: 'US',
    city: 'Webb',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Houston',
    latitude: 31.2656,
    longitude: -85.2544,
    postal_code: '36376'
  },
  '36401': {
    country: 'US',
    city: 'Evergreen',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Conecuh',
    latitude: 31.458,
    longitude: -86.9258,
    postal_code: '36401'
  },
  '36420': {
    country: 'US',
    city: 'Andalusia',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Covington',
    latitude: 31.2971,
    longitude: -86.4905,
    postal_code: '36420'
  },
  '36421': {
    country: 'US',
    city: 'Andalusia',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 31.3247,
    longitude: -86.5067,
    postal_code: '36421'
  },
  '36425': {
    country: 'US',
    city: 'Beatrice',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.7273,
    longitude: -87.1719,
    postal_code: '36425'
  },
  '36426': {
    country: 'US',
    city: 'Brewton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Escambia',
    latitude: 31.1294,
    longitude: -87.0961,
    postal_code: '36426'
  },
  '36427': {
    country: 'US',
    city: 'Brewton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Escambia',
    latitude: 31.1052,
    longitude: -87.0722,
    postal_code: '36427'
  },
  '36429': {
    country: 'US',
    city: 'Brooklyn',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Conecuh',
    latitude: 31.2627,
    longitude: -86.7711,
    postal_code: '36429'
  },
  '36432': {
    country: 'US',
    city: 'Castleberry',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Conecuh',
    latitude: 31.2991,
    longitude: -87.0225,
    postal_code: '36432'
  },
  '36435': {
    country: 'US',
    city: 'Coy',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 31.8889,
    longitude: -87.3926,
    postal_code: '36435'
  },
  '36436': {
    country: 'US',
    city: 'Dickinson',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clarke',
    latitude: 31.7635,
    longitude: -87.7106,
    postal_code: '36436'
  },
  '36439': {
    country: 'US',
    city: 'Excel',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.4279,
    longitude: -87.3414,
    postal_code: '36439'
  },
  '36441': {
    country: 'US',
    city: 'Flomaton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Escambia',
    latitude: 31.0402,
    longitude: -87.2664,
    postal_code: '36441'
  },
  '36442': {
    country: 'US',
    city: 'Florala',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Covington',
    latitude: 31.0172,
    longitude: -86.3385,
    postal_code: '36442'
  },
  '36444': {
    country: 'US',
    city: 'Franklin',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.7238,
    longitude: -87.4412,
    postal_code: '36444'
  },
  '36445': {
    country: 'US',
    city: 'Frisco City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.4235,
    longitude: -87.3817,
    postal_code: '36445'
  },
  '36446': {
    country: 'US',
    city: 'Fulton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clarke',
    latitude: 31.7925,
    longitude: -87.72,
    postal_code: '36446'
  },
  '36449': {
    country: 'US',
    city: 'Goodway',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.3368,
    longitude: -87.4258,
    postal_code: '36449'
  },
  '36451': {
    country: 'US',
    city: 'Grove Hill',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clarke',
    latitude: 31.7088,
    longitude: -87.7772,
    postal_code: '36451'
  },
  '36453': {
    country: 'US',
    city: 'Kinston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Coffee',
    latitude: 31.1926,
    longitude: -86.1514,
    postal_code: '36453'
  },
  '36454': {
    country: 'US',
    city: 'Lenox',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Conecuh',
    latitude: 31.3366,
    longitude: -87.1855,
    postal_code: '36454'
  },
  '36455': {
    country: 'US',
    city: 'Lockhart',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Covington',
    latitude: 31.0105,
    longitude: -86.3497,
    postal_code: '36455'
  },
  '36456': {
    country: 'US',
    city: 'Mc Kenzie',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Butler',
    latitude: 31.545,
    longitude: -86.7356,
    postal_code: '36456'
  },
  '36457': {
    country: 'US',
    city: 'Megargel',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.3612,
    longitude: -87.4353,
    postal_code: '36457'
  },
  '36458': {
    country: 'US',
    city: 'Mexia',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.5147,
    longitude: -87.4084,
    postal_code: '36458'
  },
  '36460': {
    country: 'US',
    city: 'Monroeville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.5153,
    longitude: -87.341,
    postal_code: '36460'
  },
  '36461': {
    country: 'US',
    city: 'Monroeville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.5221,
    longitude: -87.3411,
    postal_code: '36461'
  },
  '36467': {
    country: 'US',
    city: 'Opp',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Covington',
    latitude: 31.2799,
    longitude: -86.2571,
    postal_code: '36467'
  },
  '36470': {
    country: 'US',
    city: 'Perdue Hill',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.5141,
    longitude: -87.4933,
    postal_code: '36470'
  },
  '36471': {
    country: 'US',
    city: 'Peterman',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.59,
    longitude: -87.26,
    postal_code: '36471'
  },
  '36473': {
    country: 'US',
    city: 'Range',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Conecuh',
    latitude: 31.3058,
    longitude: -87.2137,
    postal_code: '36473'
  },
  '36474': {
    country: 'US',
    city: 'Red Level',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Covington',
    latitude: 31.4399,
    longitude: -86.6121,
    postal_code: '36474'
  },
  '36475': {
    country: 'US',
    city: 'Repton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Conecuh',
    latitude: 31.4256,
    longitude: -87.172,
    postal_code: '36475'
  },
  '36476': {
    country: 'US',
    city: 'River Falls',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Covington',
    latitude: 31.3533,
    longitude: -86.541,
    postal_code: '36476'
  },
  '36477': {
    country: 'US',
    city: 'Samson',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Geneva',
    latitude: 31.1049,
    longitude: -86.0674,
    postal_code: '36477'
  },
  '36480': {
    country: 'US',
    city: 'Uriah',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.3135,
    longitude: -87.5705,
    postal_code: '36480'
  },
  '36481': {
    country: 'US',
    city: 'Vredenburgh',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Monroe',
    latitude: 31.8085,
    longitude: -87.3164,
    postal_code: '36481'
  },
  '36482': {
    country: 'US',
    city: 'Whatley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clarke',
    latitude: 31.6395,
    longitude: -87.6565,
    postal_code: '36482'
  },
  '36483': {
    country: 'US',
    city: 'Wing',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Covington',
    latitude: 31.0223,
    longitude: -86.6293,
    postal_code: '36483'
  },
  '36502': {
    country: 'US',
    city: 'Atmore',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Escambia',
    latitude: 31.0572,
    longitude: -87.4873,
    postal_code: '36502'
  },
  '36503': {
    country: 'US',
    city: 'Atmore',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Escambia',
    latitude: 31.0238,
    longitude: -87.4939,
    postal_code: '36503'
  },
  '36504': {
    country: 'US',
    city: 'Atmore',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Escambia',
    latitude: 31.0158,
    longitude: -87.4972,
    postal_code: '36504'
  },
  '36505': {
    country: 'US',
    city: 'Axis',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.9394,
    longitude: -88.0159,
    postal_code: '36505'
  },
  '36507': {
    country: 'US',
    city: 'Bay Minette',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.8635,
    longitude: -87.7644,
    postal_code: '36507'
  },
  '36509': {
    country: 'US',
    city: 'Bayou La Batre',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.408,
    longitude: -88.2507,
    postal_code: '36509'
  },
  '36511': {
    country: 'US',
    city: 'Bon Secour',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.3128,
    longitude: -87.7431,
    postal_code: '36511'
  },
  '36512': {
    country: 'US',
    city: 'Bucks',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 31.0105,
    longitude: -88.0242,
    postal_code: '36512'
  },
  '36513': {
    country: 'US',
    city: 'Calvert',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.1552,
    longitude: -88.01,
    postal_code: '36513'
  },
  '36518': {
    country: 'US',
    city: 'Chatom',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.4876,
    longitude: -88.2699,
    postal_code: '36518'
  },
  '36521': {
    country: 'US',
    city: 'Chunchula',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.9912,
    longitude: -88.1311,
    postal_code: '36521'
  },
  '36522': {
    country: 'US',
    city: 'Citronelle',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 31.0425,
    longitude: -88.2549,
    postal_code: '36522'
  },
  '36523': {
    country: 'US',
    city: 'Coden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.3903,
    longitude: -88.2076,
    postal_code: '36523'
  },
  '36524': {
    country: 'US',
    city: 'Coffeeville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clarke',
    latitude: 31.7843,
    longitude: -88.0715,
    postal_code: '36524'
  },
  '36525': {
    country: 'US',
    city: 'Creola',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.9013,
    longitude: -88.0174,
    postal_code: '36525'
  },
  '36526': {
    country: 'US',
    city: 'Daphne',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.6197,
    longitude: -87.8895,
    postal_code: '36526'
  },
  '36527': {
    country: 'US',
    city: 'Spanish Fort',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.6959,
    longitude: -87.8867,
    postal_code: '36527'
  },
  '36528': {
    country: 'US',
    city: 'Dauphin Island',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.2521,
    longitude: -88.1096,
    postal_code: '36528'
  },
  '36529': {
    country: 'US',
    city: 'Deer Park',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.2168,
    longitude: -88.3172,
    postal_code: '36529'
  },
  '36530': {
    country: 'US',
    city: 'Elberta',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.3942,
    longitude: -87.589,
    postal_code: '36530'
  },
  '36532': {
    country: 'US',
    city: 'Fairhope',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.5012,
    longitude: -87.8835,
    postal_code: '36532'
  },
  '36533': {
    country: 'US',
    city: 'Fairhope',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.4841,
    longitude: -87.8606,
    postal_code: '36533'
  },
  '36535': {
    country: 'US',
    city: 'Foley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.4007,
    longitude: -87.6857,
    postal_code: '36535'
  },
  '36536': {
    country: 'US',
    city: 'Foley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.4222,
    longitude: -87.7064,
    postal_code: '36536'
  },
  '36538': {
    country: 'US',
    city: 'Frankville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.6468,
    longitude: -88.1332,
    postal_code: '36538'
  },
  '36539': {
    country: 'US',
    city: 'Fruitdale',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.3488,
    longitude: -88.3766,
    postal_code: '36539'
  },
  '36540': {
    country: 'US',
    city: 'Gainestown',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clarke',
    latitude: 31.4258,
    longitude: -87.6822,
    postal_code: '36540'
  },
  '36541': {
    country: 'US',
    city: 'Grand Bay',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.4983,
    longitude: -88.3282,
    postal_code: '36541'
  },
  '36542': {
    country: 'US',
    city: 'Gulf Shores',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.2717,
    longitude: -87.7589,
    postal_code: '36542'
  },
  '36543': {
    country: 'US',
    city: 'Huxford',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Escambia',
    latitude: 31.2202,
    longitude: -87.4619,
    postal_code: '36543'
  },
  '36544': {
    country: 'US',
    city: 'Irvington',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.4802,
    longitude: -88.2396,
    postal_code: '36544'
  },
  '36545': {
    country: 'US',
    city: 'Jackson',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clarke',
    latitude: 31.5131,
    longitude: -87.8672,
    postal_code: '36545'
  },
  '36547': {
    country: 'US',
    city: 'Gulf Shores',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.246,
    longitude: -87.7008,
    postal_code: '36547'
  },
  '36548': {
    country: 'US',
    city: 'Leroy',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.491,
    longitude: -87.9687,
    postal_code: '36548'
  },
  '36549': {
    country: 'US',
    city: 'Lillian',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.3972,
    longitude: -87.4745,
    postal_code: '36549'
  },
  '36550': {
    country: 'US',
    city: 'Little River',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 31.2246,
    longitude: -87.7548,
    postal_code: '36550'
  },
  '36551': {
    country: 'US',
    city: 'Loxley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.618,
    longitude: -87.7562,
    postal_code: '36551'
  },
  '36553': {
    country: 'US',
    city: 'Mc Intosh',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.2361,
    longitude: -88.0511,
    postal_code: '36553'
  },
  '36555': {
    country: 'US',
    city: 'Magnolia Springs',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.3996,
    longitude: -87.7761,
    postal_code: '36555'
  },
  '36556': {
    country: 'US',
    city: 'Malcolm',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.1913,
    longitude: -88.0075,
    postal_code: '36556'
  },
  '36558': {
    country: 'US',
    city: 'Millry',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.6269,
    longitude: -88.3559,
    postal_code: '36558'
  },
  '36559': {
    country: 'US',
    city: 'Montrose',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.5623,
    longitude: -87.9005,
    postal_code: '36559'
  },
  '36560': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 31.0974,
    longitude: -88.035,
    postal_code: '36560'
  },
  '36561': {
    country: 'US',
    city: 'Orange Beach',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.281,
    longitude: -87.5815,
    postal_code: '36561'
  },
  '36562': {
    country: 'US',
    city: 'Perdido',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 31.0403,
    longitude: -87.6667,
    postal_code: '36562'
  },
  '36564': {
    country: 'US',
    city: 'Point Clear',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.4741,
    longitude: -87.9192,
    postal_code: '36564'
  },
  '36567': {
    country: 'US',
    city: 'Robertsdale',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.5616,
    longitude: -87.6373,
    postal_code: '36567'
  },
  '36568': {
    country: 'US',
    city: 'Saint Elmo',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.5035,
    longitude: -88.2542,
    postal_code: '36568'
  },
  '36569': {
    country: 'US',
    city: 'Saint Stephens',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.533,
    longitude: -88.0521,
    postal_code: '36569'
  },
  '36571': {
    country: 'US',
    city: 'Saraland',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.8332,
    longitude: -88.0934,
    postal_code: '36571'
  },
  '36572': {
    country: 'US',
    city: 'Satsuma',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.8516,
    longitude: -88.0533,
    postal_code: '36572'
  },
  '36574': {
    country: 'US',
    city: 'Seminole',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.5048,
    longitude: -87.4659,
    postal_code: '36574'
  },
  '36575': {
    country: 'US',
    city: 'Semmes',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.7544,
    longitude: -88.2667,
    postal_code: '36575'
  },
  '36576': {
    country: 'US',
    city: 'Silverhill',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.5222,
    longitude: -87.7457,
    postal_code: '36576'
  },
  '36577': {
    country: 'US',
    city: 'Spanish Fort',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.6749,
    longitude: -87.9153,
    postal_code: '36577'
  },
  '36578': {
    country: 'US',
    city: 'Stapleton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.7476,
    longitude: -87.7787,
    postal_code: '36578'
  },
  '36579': {
    country: 'US',
    city: 'Stockton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 31.0129,
    longitude: -87.8633,
    postal_code: '36579'
  },
  '36580': {
    country: 'US',
    city: 'Summerdale',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Baldwin',
    latitude: 30.4752,
    longitude: -87.6992,
    postal_code: '36580'
  },
  '36581': {
    country: 'US',
    city: 'Sunflower',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.3696,
    longitude: -88.0122,
    postal_code: '36581'
  },
  '36582': {
    country: 'US',
    city: 'Theodore',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.5444,
    longitude: -88.1807,
    postal_code: '36582'
  },
  '36583': {
    country: 'US',
    city: 'Tibbie',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.4067,
    longitude: -88.1758,
    postal_code: '36583'
  },
  '36584': {
    country: 'US',
    city: 'Vinegar Bend',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.2584,
    longitude: -88.3865,
    postal_code: '36584'
  },
  '36585': {
    country: 'US',
    city: 'Wagarville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Washington',
    latitude: 31.4102,
    longitude: -88.0705,
    postal_code: '36585'
  },
  '36587': {
    country: 'US',
    city: 'Wilmer',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.8137,
    longitude: -88.3332,
    postal_code: '36587'
  },
  '36590': {
    country: 'US',
    city: 'Theodore',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.488,
    longitude: -88.2533,
    postal_code: '36590'
  },
  '36601': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6944,
    longitude: -88.0431,
    postal_code: '36601'
  },
  '36602': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6888,
    longitude: -88.0453,
    postal_code: '36602'
  },
  '36603': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6921,
    longitude: -88.0562,
    postal_code: '36603'
  },
  '36604': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.682,
    longitude: -88.0678,
    postal_code: '36604'
  },
  '36605': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6341,
    longitude: -88.0846,
    postal_code: '36605'
  },
  '36606': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6729,
    longitude: -88.1009,
    postal_code: '36606'
  },
  '36607': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6975,
    longitude: -88.1029,
    postal_code: '36607'
  },
  '36608': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6817,
    longitude: -88.2945,
    postal_code: '36608'
  },
  '36609': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6605,
    longitude: -88.1618,
    postal_code: '36609'
  },
  '36610': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.7309,
    longitude: -88.0789,
    postal_code: '36610'
  },
  '36611': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.7705,
    longitude: -88.0781,
    postal_code: '36611'
  },
  '36612': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.7518,
    longitude: -88.1131,
    postal_code: '36612'
  },
  '36613': {
    country: 'US',
    city: 'Eight Mile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.7951,
    longitude: -88.1823,
    postal_code: '36613'
  },
  '36615': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6411,
    longitude: -88.0622,
    postal_code: '36615'
  },
  '36616': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.671,
    longitude: -88.1267,
    postal_code: '36616'
  },
  '36617': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.7145,
    longitude: -88.0918,
    postal_code: '36617'
  },
  '36618': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.7322,
    longitude: -88.1758,
    postal_code: '36618'
  },
  '36619': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.5928,
    longitude: -88.1946,
    postal_code: '36619'
  },
  '36628': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6944,
    longitude: -88.0431,
    postal_code: '36628'
  },
  '36633': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6589,
    longitude: -88.178,
    postal_code: '36633'
  },
  '36640': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6589,
    longitude: -88.178,
    postal_code: '36640'
  },
  '36641': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6589,
    longitude: -88.178,
    postal_code: '36641'
  },
  '36644': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6944,
    longitude: -88.0431,
    postal_code: '36644'
  },
  '36652': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6944,
    longitude: -88.0431,
    postal_code: '36652'
  },
  '36660': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6589,
    longitude: -88.178,
    postal_code: '36660'
  },
  '36663': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6944,
    longitude: -88.0431,
    postal_code: '36663'
  },
  '36670': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6589,
    longitude: -88.178,
    postal_code: '36670'
  },
  '36671': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6589,
    longitude: -88.178,
    postal_code: '36671'
  },
  '36675': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6944,
    longitude: -88.0431,
    postal_code: '36675'
  },
  '36685': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6589,
    longitude: -88.178,
    postal_code: '36685'
  },
  '36688': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6962,
    longitude: -88.1821,
    postal_code: '36688'
  },
  '36689': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6589,
    longitude: -88.178,
    postal_code: '36689'
  },
  '36691': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6944,
    longitude: -88.0431,
    postal_code: '36691'
  },
  '36693': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6311,
    longitude: -88.1588,
    postal_code: '36693'
  },
  '36695': {
    country: 'US',
    city: 'Mobile',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Mobile',
    latitude: 30.6474,
    longitude: -88.2292,
    postal_code: '36695'
  },
  '36701': {
    country: 'US',
    city: 'Selma',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dallas',
    latitude: 32.4197,
    longitude: -87.0245,
    postal_code: '36701'
  },
  '36702': {
    country: 'US',
    city: 'Selma',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dallas',
    latitude: 32.4074,
    longitude: -87.0211,
    postal_code: '36702'
  },
  '36703': {
    country: 'US',
    city: 'Selma',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dallas',
    latitude: 32.4156,
    longitude: -87.0135,
    postal_code: '36703'
  },
  '36720': {
    country: 'US',
    city: 'Alberta',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 32.1585,
    longitude: -87.3468,
    postal_code: '36720'
  },
  '36722': {
    country: 'US',
    city: 'Arlington',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 32.0668,
    longitude: -87.5597,
    postal_code: '36722'
  },
  '36723': {
    country: 'US',
    city: 'Boykin',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 32.0779,
    longitude: -87.2814,
    postal_code: '36723'
  },
  '36726': {
    country: 'US',
    city: 'Camden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 32.0047,
    longitude: -87.295,
    postal_code: '36726'
  },
  '36727': {
    country: 'US',
    city: 'Campbell',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clarke',
    latitude: 31.9213,
    longitude: -88.008,
    postal_code: '36727'
  },
  '36728': {
    country: 'US',
    city: 'Catherine',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 32.182,
    longitude: -87.4836,
    postal_code: '36728'
  },
  '36732': {
    country: 'US',
    city: 'Demopolis',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marengo',
    latitude: 32.4908,
    longitude: -87.8397,
    postal_code: '36732'
  },
  '36736': {
    country: 'US',
    city: 'Dixons Mills',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marengo',
    latitude: 32.0672,
    longitude: -87.7454,
    postal_code: '36736'
  },
  '36738': {
    country: 'US',
    city: 'Faunsdale',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marengo',
    latitude: 32.4233,
    longitude: -87.6181,
    postal_code: '36738'
  },
  '36740': {
    country: 'US',
    city: 'Forkland',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Greene',
    latitude: 32.6108,
    longitude: -87.8441,
    postal_code: '36740'
  },
  '36741': {
    country: 'US',
    city: 'Furman',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 32.0061,
    longitude: -86.9817,
    postal_code: '36741'
  },
  '36742': {
    country: 'US',
    city: 'Gallion',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Hale',
    latitude: 32.5298,
    longitude: -87.6985,
    postal_code: '36742'
  },
  '36744': {
    country: 'US',
    city: 'Greensboro',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Hale',
    latitude: 32.7167,
    longitude: -87.5905,
    postal_code: '36744'
  },
  '36745': {
    country: 'US',
    city: 'Jefferson',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marengo',
    latitude: 32.414,
    longitude: -87.8986,
    postal_code: '36745'
  },
  '36748': {
    country: 'US',
    city: 'Linden',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marengo',
    latitude: 32.3053,
    longitude: -87.7954,
    postal_code: '36748'
  },
  '36749': {
    country: 'US',
    city: 'Jones',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Autauga',
    latitude: 32.5104,
    longitude: -86.8138,
    postal_code: '36749'
  },
  '36750': {
    country: 'US',
    city: 'Maplesville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chilton',
    latitude: 32.8045,
    longitude: -86.8717,
    postal_code: '36750'
  },
  '36751': {
    country: 'US',
    city: 'Lower Peach Tree',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 31.8504,
    longitude: -87.5682,
    postal_code: '36751'
  },
  '36752': {
    country: 'US',
    city: 'Lowndesboro',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lowndes',
    latitude: 32.2943,
    longitude: -86.6471,
    postal_code: '36752'
  },
  '36753': {
    country: 'US',
    city: 'Mc Williams',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 32.0481,
    longitude: -87.2623,
    postal_code: '36753'
  },
  '36754': {
    country: 'US',
    city: 'Magnolia',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marengo',
    latitude: 32.1419,
    longitude: -87.6998,
    postal_code: '36754'
  },
  '36756': {
    country: 'US',
    city: 'Marion',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Perry',
    latitude: 32.6463,
    longitude: -87.3314,
    postal_code: '36756'
  },
  '36758': {
    country: 'US',
    city: 'Plantersville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dallas',
    latitude: 32.6176,
    longitude: -86.9475,
    postal_code: '36758'
  },
  '36759': {
    country: 'US',
    city: 'Marion Junction',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dallas',
    latitude: 32.4266,
    longitude: -87.2702,
    postal_code: '36759'
  },
  '36761': {
    country: 'US',
    city: 'Minter',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dallas',
    latitude: 32.1899,
    longitude: -87.0633,
    postal_code: '36761'
  },
  '36763': {
    country: 'US',
    city: 'Myrtlewood',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marengo',
    latitude: 32.2516,
    longitude: -87.9494,
    postal_code: '36763'
  },
  '36764': {
    country: 'US',
    city: 'Nanafalia',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marengo',
    latitude: 32.1129,
    longitude: -87.9881,
    postal_code: '36764'
  },
  '36765': {
    country: 'US',
    city: 'Newbern',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Hale',
    latitude: 32.5515,
    longitude: -87.5615,
    postal_code: '36765'
  },
  '36766': {
    country: 'US',
    city: 'Oak Hill',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 31.947,
    longitude: -87.0745,
    postal_code: '36766'
  },
  '36767': {
    country: 'US',
    city: 'Orrville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dallas',
    latitude: 32.2949,
    longitude: -87.2214,
    postal_code: '36767'
  },
  '36768': {
    country: 'US',
    city: 'Pine Apple',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 31.9265,
    longitude: -87.0047,
    postal_code: '36768'
  },
  '36769': {
    country: 'US',
    city: 'Pine Hill',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Wilcox',
    latitude: 31.9735,
    longitude: -87.5771,
    postal_code: '36769'
  },
  '36773': {
    country: 'US',
    city: 'Safford',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dallas',
    latitude: 32.3002,
    longitude: -87.3693,
    postal_code: '36773'
  },
  '36775': {
    country: 'US',
    city: 'Sardis',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dallas',
    latitude: 32.2844,
    longitude: -86.992,
    postal_code: '36775'
  },
  '36776': {
    country: 'US',
    city: 'Sawyerville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Hale',
    latitude: 32.7578,
    longitude: -87.7402,
    postal_code: '36776'
  },
  '36782': {
    country: 'US',
    city: 'Sweet Water',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marengo',
    latitude: 32.0773,
    longitude: -87.9228,
    postal_code: '36782'
  },
  '36783': {
    country: 'US',
    city: 'Thomaston',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Marengo',
    latitude: 32.2538,
    longitude: -87.5974,
    postal_code: '36783'
  },
  '36784': {
    country: 'US',
    city: 'Thomasville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Clarke',
    latitude: 31.9067,
    longitude: -87.7598,
    postal_code: '36784'
  },
  '36785': {
    country: 'US',
    city: 'Tyler',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Dallas',
    latitude: 32.3239,
    longitude: -86.8646,
    postal_code: '36785'
  },
  '36786': {
    country: 'US',
    city: 'Uniontown',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Perry',
    latitude: 32.447,
    longitude: -87.4934,
    postal_code: '36786'
  },
  '36790': {
    country: 'US',
    city: 'Stanton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chilton',
    latitude: 32.7332,
    longitude: -86.8963,
    postal_code: '36790'
  },
  '36792': {
    country: 'US',
    city: 'Randolph',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bibb',
    latitude: 32.8888,
    longitude: -86.907,
    postal_code: '36792'
  },
  '36793': {
    country: 'US',
    city: 'Lawley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Bibb',
    latitude: 32.8646,
    longitude: -86.9567,
    postal_code: '36793'
  },
  '36801': {
    country: 'US',
    city: 'Opelika',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.6454,
    longitude: -85.3783,
    postal_code: '36801'
  },
  '36802': {
    country: 'US',
    city: 'Opelika',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.5782,
    longitude: -85.349,
    postal_code: '36802'
  },
  '36803': {
    country: 'US',
    city: 'Opelika',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.6454,
    longitude: -85.3783,
    postal_code: '36803'
  },
  '36804': {
    country: 'US',
    city: 'Opelika',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.5768,
    longitude: -85.312,
    postal_code: '36804'
  },
  '36830': {
    country: 'US',
    city: 'Auburn',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.5475,
    longitude: -85.4682,
    postal_code: '36830'
  },
  '36831': {
    country: 'US',
    city: 'Auburn',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.6099,
    longitude: -85.4808,
    postal_code: '36831'
  },
  '36832': {
    country: 'US',
    city: 'Auburn',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.592,
    longitude: -85.5189,
    postal_code: '36832'
  },
  '36849': {
    country: 'US',
    city: 'Auburn University',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.6024,
    longitude: -85.4873,
    postal_code: '36849'
  },
  '36850': {
    country: 'US',
    city: 'Camp Hill',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tallapoosa',
    latitude: 32.7827,
    longitude: -85.6474,
    postal_code: '36850'
  },
  '36851': {
    country: 'US',
    city: 'Cottonton',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.1468,
    longitude: -85.0738,
    postal_code: '36851'
  },
  '36852': {
    country: 'US',
    city: 'Cusseta',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chambers',
    latitude: 32.7831,
    longitude: -85.2756,
    postal_code: '36852'
  },
  '36853': {
    country: 'US',
    city: 'Dadeville',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tallapoosa',
    latitude: 32.8224,
    longitude: -85.7704,
    postal_code: '36853'
  },
  '36854': {
    country: 'US',
    city: 'Valley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chambers',
    latitude: 32.8113,
    longitude: -85.1749,
    postal_code: '36854'
  },
  '36855': {
    country: 'US',
    city: 'Five Points',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chambers',
    latitude: 33.0424,
    longitude: -85.3243,
    postal_code: '36855'
  },
  '36856': {
    country: 'US',
    city: 'Fort Mitchell',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.2965,
    longitude: -84.9972,
    postal_code: '36856'
  },
  '36858': {
    country: 'US',
    city: 'Hatchechubbee',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.2845,
    longitude: -85.3029,
    postal_code: '36858'
  },
  '36859': {
    country: 'US',
    city: 'Holy Trinity',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.2298,
    longitude: -85.0082,
    postal_code: '36859'
  },
  '36860': {
    country: 'US',
    city: 'Hurtsboro',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.2454,
    longitude: -85.3959,
    postal_code: '36860'
  },
  '36861': {
    country: 'US',
    city: 'Jacksons Gap',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Tallapoosa',
    latitude: 32.8797,
    longitude: -85.8487,
    postal_code: '36861'
  },
  '36862': {
    country: 'US',
    city: 'Lafayette',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chambers',
    latitude: 32.9252,
    longitude: -85.4426,
    postal_code: '36862'
  },
  '36863': {
    country: 'US',
    city: 'Lanett',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Chambers',
    latitude: 32.8616,
    longitude: -85.2161,
    postal_code: '36863'
  },
  '36865': {
    country: 'US',
    city: 'Loachapoka',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.6043,
    longitude: -85.5933,
    postal_code: '36865'
  },
  '36866': {
    country: 'US',
    city: 'Notasulga',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Macon',
    latitude: 32.5437,
    longitude: -85.6871,
    postal_code: '36866'
  },
  '36867': {
    country: 'US',
    city: 'Phenix City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.4978,
    longitude: -85.0234,
    postal_code: '36867'
  },
  '36868': {
    country: 'US',
    city: 'Phenix City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.4646,
    longitude: -85.0206,
    postal_code: '36868'
  },
  '36869': {
    country: 'US',
    city: 'Phenix City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.4204,
    longitude: -85.0796,
    postal_code: '36869'
  },
  '36870': {
    country: 'US',
    city: 'Phenix City',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.4781,
    longitude: -85.124,
    postal_code: '36870'
  },
  '36871': {
    country: 'US',
    city: 'Pittsview',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.1733,
    longitude: -85.1312,
    postal_code: '36871'
  },
  '36872': {
    country: 'US',
    city: 'Valley',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.5782,
    longitude: -85.349,
    postal_code: '36872'
  },
  '36874': {
    country: 'US',
    city: 'Salem',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.6212,
    longitude: -85.184,
    postal_code: '36874'
  },
  '36875': {
    country: 'US',
    city: 'Seale',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Russell',
    latitude: 32.3053,
    longitude: -85.1677,
    postal_code: '36875'
  },
  '36877': {
    country: 'US',
    city: 'Smiths Station',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.5401,
    longitude: -85.0985,
    postal_code: '36877'
  },
  '36879': {
    country: 'US',
    city: 'Waverly',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Lee',
    latitude: 32.7632,
    longitude: -85.5144,
    postal_code: '36879'
  },
  '36901': {
    country: 'US',
    city: 'Bellamy',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Sumter',
    latitude: 32.449,
    longitude: -88.1336,
    postal_code: '36901'
  },
  '36904': {
    country: 'US',
    city: 'Butler',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Choctaw',
    latitude: 32.0829,
    longitude: -88.2064,
    postal_code: '36904'
  },
  '36907': {
    country: 'US',
    city: 'Cuba',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Sumter',
    latitude: 32.411,
    longitude: -88.3611,
    postal_code: '36907'
  },
  '36908': {
    country: 'US',
    city: 'Gilbertown',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Choctaw',
    latitude: 31.8666,
    longitude: -88.3265,
    postal_code: '36908'
  },
  '36910': {
    country: 'US',
    city: 'Jachin',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Choctaw',
    latitude: 32.2299,
    longitude: -88.1684,
    postal_code: '36910'
  },
  '36912': {
    country: 'US',
    city: 'Lisman',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Choctaw',
    latitude: 32.2177,
    longitude: -88.3234,
    postal_code: '36912'
  },
  '36913': {
    country: 'US',
    city: 'Melvin',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Choctaw',
    latitude: 31.9304,
    longitude: -88.4589,
    postal_code: '36913'
  },
  '36915': {
    country: 'US',
    city: 'Needham',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Choctaw',
    latitude: 31.9879,
    longitude: -88.3367,
    postal_code: '36915'
  },
  '36916': {
    country: 'US',
    city: 'Pennington',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Choctaw',
    latitude: 32.2094,
    longitude: -88.049,
    postal_code: '36916'
  },
  '36919': {
    country: 'US',
    city: 'Silas',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Choctaw',
    latitude: 31.7668,
    longitude: -88.3091,
    postal_code: '36919'
  },
  '36921': {
    country: 'US',
    city: 'Toxey',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Choctaw',
    latitude: 31.9155,
    longitude: -88.151,
    postal_code: '36921'
  },
  '36922': {
    country: 'US',
    city: 'Ward',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Choctaw',
    latitude: 32.3038,
    longitude: -88.1476,
    postal_code: '36922'
  },
  '36925': {
    country: 'US',
    city: 'York',
    state: 'Alabama',
    state_short: 'AL',
    county: 'Sumter',
    latitude: 32.4728,
    longitude: -88.2683,
    postal_code: '36925'
  },
  '37010': {
    country: 'US',
    city: 'Adams',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Robertson',
    latitude: 36.5582,
    longitude: -87.1226,
    postal_code: '37010'
  },
  '37011': {
    country: 'US',
    city: 'Antioch',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.0601,
    longitude: -86.6722,
    postal_code: '37011'
  },
  '37012': {
    country: 'US',
    city: 'Alexandria',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'DeKalb',
    latitude: 36.0711,
    longitude: -86.0372,
    postal_code: '37012'
  },
  '37013': {
    country: 'US',
    city: 'Antioch',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.0595,
    longitude: -86.6592,
    postal_code: '37013'
  },
  '37014': {
    country: 'US',
    city: 'Arrington',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 35.8566,
    longitude: -86.6633,
    postal_code: '37014'
  },
  '37015': {
    country: 'US',
    city: 'Ashland City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cheatham',
    latitude: 36.2731,
    longitude: -87.0447,
    postal_code: '37015'
  },
  '37016': {
    country: 'US',
    city: 'Auburntown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cannon',
    latitude: 35.9338,
    longitude: -86.1126,
    postal_code: '37016'
  },
  '37018': {
    country: 'US',
    city: 'Beechgrove',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Coffee',
    latitude: 35.6447,
    longitude: -86.2046,
    postal_code: '37018'
  },
  '37019': {
    country: 'US',
    city: 'Belfast',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Marshall',
    latitude: 35.4069,
    longitude: -86.7095,
    postal_code: '37019'
  },
  '37020': {
    country: 'US',
    city: 'Bell Buckle',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bedford',
    latitude: 35.6381,
    longitude: -86.3949,
    postal_code: '37020'
  },
  '37022': {
    country: 'US',
    city: 'Bethpage',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sumner',
    latitude: 36.5186,
    longitude: -86.3146,
    postal_code: '37022'
  },
  '37023': {
    country: 'US',
    city: 'Big Rock',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Stewart',
    latitude: 36.5716,
    longitude: -87.7378,
    postal_code: '37023'
  },
  '37024': {
    country: 'US',
    city: 'Brentwood',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 36.0331,
    longitude: -86.7828,
    postal_code: '37024'
  },
  '37025': {
    country: 'US',
    city: 'Bon Aqua',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hickman',
    latitude: 35.9471,
    longitude: -87.2996,
    postal_code: '37025'
  },
  '37026': {
    country: 'US',
    city: 'Bradyville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cannon',
    latitude: 35.7053,
    longitude: -86.0912,
    postal_code: '37026'
  },
  '37027': {
    country: 'US',
    city: 'Brentwood',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 36.0063,
    longitude: -86.7909,
    postal_code: '37027'
  },
  '37028': {
    country: 'US',
    city: 'Bumpus Mills',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Stewart',
    latitude: 36.6226,
    longitude: -87.8614,
    postal_code: '37028'
  },
  '37029': {
    country: 'US',
    city: 'Burns',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dickson',
    latitude: 36.0471,
    longitude: -87.3061,
    postal_code: '37029'
  },
  '37030': {
    country: 'US',
    city: 'Carthage',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Smith',
    latitude: 36.2763,
    longitude: -85.9517,
    postal_code: '37030'
  },
  '37031': {
    country: 'US',
    city: 'Castalian Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sumner',
    latitude: 36.3806,
    longitude: -86.3107,
    postal_code: '37031'
  },
  '37032': {
    country: 'US',
    city: 'Cedar Hill',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Robertson',
    latitude: 36.5514,
    longitude: -86.9992,
    postal_code: '37032'
  },
  '37033': {
    country: 'US',
    city: 'Centerville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hickman',
    latitude: 35.7797,
    longitude: -87.4775,
    postal_code: '37033'
  },
  '37034': {
    country: 'US',
    city: 'Chapel Hill',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Marshall',
    latitude: 35.6354,
    longitude: -86.6836,
    postal_code: '37034'
  },
  '37035': {
    country: 'US',
    city: 'Chapmansboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cheatham',
    latitude: 36.3768,
    longitude: -87.1127,
    postal_code: '37035'
  },
  '37036': {
    country: 'US',
    city: 'Charlotte',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dickson',
    latitude: 36.2326,
    longitude: -87.2816,
    postal_code: '37036'
  },
  '37037': {
    country: 'US',
    city: 'Christiana',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.696,
    longitude: -86.368,
    postal_code: '37037'
  },
  '37040': {
    country: 'US',
    city: 'Clarksville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Montgomery',
    latitude: 36.522,
    longitude: -87.349,
    postal_code: '37040'
  },
  '37041': {
    country: 'US',
    city: 'Clarksville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Montgomery',
    latitude: 36.4774,
    longitude: -87.3772,
    postal_code: '37041'
  },
  '37042': {
    country: 'US',
    city: 'Clarksville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Montgomery',
    latitude: 36.5853,
    longitude: -87.4186,
    postal_code: '37042'
  },
  '37043': {
    country: 'US',
    city: 'Clarksville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Montgomery',
    latitude: 36.5107,
    longitude: -87.2757,
    postal_code: '37043'
  },
  '37044': {
    country: 'US',
    city: 'Clarksville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Montgomery',
    latitude: 36.5314,
    longitude: -87.353,
    postal_code: '37044'
  },
  '37046': {
    country: 'US',
    city: 'College Grove',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 35.7832,
    longitude: -86.7495,
    postal_code: '37046'
  },
  '37047': {
    country: 'US',
    city: 'Cornersville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Marshall',
    latitude: 35.3409,
    longitude: -86.8286,
    postal_code: '37047'
  },
  '37048': {
    country: 'US',
    city: 'Cottontown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sumner',
    latitude: 36.4912,
    longitude: -86.6033,
    postal_code: '37048'
  },
  '37049': {
    country: 'US',
    city: 'Cross Plains',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Robertson',
    latitude: 36.5531,
    longitude: -86.6761,
    postal_code: '37049'
  },
  '37050': {
    country: 'US',
    city: 'Cumberland City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Stewart',
    latitude: 36.3729,
    longitude: -87.6306,
    postal_code: '37050'
  },
  '37051': {
    country: 'US',
    city: 'Cumberland Furnace',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dickson',
    latitude: 36.2721,
    longitude: -87.3914,
    postal_code: '37051'
  },
  '37052': {
    country: 'US',
    city: 'Cunningham',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Montgomery',
    latitude: 36.3789,
    longitude: -87.4245,
    postal_code: '37052'
  },
  '37055': {
    country: 'US',
    city: 'Dickson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dickson',
    latitude: 36.076,
    longitude: -87.3995,
    postal_code: '37055'
  },
  '37056': {
    country: 'US',
    city: 'Dickson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dickson',
    latitude: 36.1469,
    longitude: -87.353,
    postal_code: '37056'
  },
  '37057': {
    country: 'US',
    city: 'Dixon Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Smith',
    latitude: 36.4455,
    longitude: -86.0533,
    postal_code: '37057'
  },
  '37058': {
    country: 'US',
    city: 'Dover',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Stewart',
    latitude: 36.4942,
    longitude: -87.8787,
    postal_code: '37058'
  },
  '37059': {
    country: 'US',
    city: 'Dowelltown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'DeKalb',
    latitude: 35.9725,
    longitude: -85.9055,
    postal_code: '37059'
  },
  '37060': {
    country: 'US',
    city: 'Eagleville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.7492,
    longitude: -86.6327,
    postal_code: '37060'
  },
  '37061': {
    country: 'US',
    city: 'Erin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Houston',
    latitude: 36.3067,
    longitude: -87.679,
    postal_code: '37061'
  },
  '37062': {
    country: 'US',
    city: 'Fairview',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 35.9755,
    longitude: -87.1321,
    postal_code: '37062'
  },
  '37063': {
    country: 'US',
    city: 'Fosterville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.6551,
    longitude: -86.403,
    postal_code: '37063'
  },
  '37064': {
    country: 'US',
    city: 'Franklin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 35.9328,
    longitude: -86.8788,
    postal_code: '37064'
  },
  '37065': {
    country: 'US',
    city: 'Franklin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 35.9251,
    longitude: -86.8689,
    postal_code: '37065'
  },
  '37066': {
    country: 'US',
    city: 'Gallatin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sumner',
    latitude: 36.3834,
    longitude: -86.4512,
    postal_code: '37066'
  },
  '37067': {
    country: 'US',
    city: 'Franklin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 35.9121,
    longitude: -86.7655,
    postal_code: '37067'
  },
  '37068': {
    country: 'US',
    city: 'Franklin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 35.8746,
    longitude: -86.9076,
    postal_code: '37068'
  },
  '37069': {
    country: 'US',
    city: 'Franklin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 35.9796,
    longitude: -86.9106,
    postal_code: '37069'
  },
  '37070': {
    country: 'US',
    city: 'Goodlettsville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.3231,
    longitude: -86.7133,
    postal_code: '37070'
  },
  '37071': {
    country: 'US',
    city: 'Gladeville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wilson',
    latitude: 36.1562,
    longitude: -86.3049,
    postal_code: '37071'
  },
  '37072': {
    country: 'US',
    city: 'Goodlettsville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.3417,
    longitude: -86.7212,
    postal_code: '37072'
  },
  '37073': {
    country: 'US',
    city: 'Greenbrier',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Robertson',
    latitude: 36.4229,
    longitude: -86.7914,
    postal_code: '37073'
  },
  '37074': {
    country: 'US',
    city: 'Hartsville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Trousdale',
    latitude: 36.3947,
    longitude: -86.1704,
    postal_code: '37074'
  },
  '37075': {
    country: 'US',
    city: 'Hendersonville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sumner',
    latitude: 36.3054,
    longitude: -86.6072,
    postal_code: '37075'
  },
  '37076': {
    country: 'US',
    city: 'Hermitage',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1848,
    longitude: -86.6002,
    postal_code: '37076'
  },
  '37077': {
    country: 'US',
    city: 'Hendersonville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sumner',
    latitude: 36.3047,
    longitude: -86.6211,
    postal_code: '37077'
  },
  '37078': {
    country: 'US',
    city: 'Hurricane Mills',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Humphreys',
    latitude: 35.924,
    longitude: -87.8105,
    postal_code: '37078'
  },
  '37079': {
    country: 'US',
    city: 'Indian Mound',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Stewart',
    latitude: 36.4946,
    longitude: -87.6804,
    postal_code: '37079'
  },
  '37080': {
    country: 'US',
    city: 'Joelton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.329,
    longitude: -86.9163,
    postal_code: '37080'
  },
  '37082': {
    country: 'US',
    city: 'Kingston Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cheatham',
    latitude: 36.0953,
    longitude: -87.1156,
    postal_code: '37082'
  },
  '37083': {
    country: 'US',
    city: 'Lafayette',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Macon',
    latitude: 36.539,
    longitude: -86.0242,
    postal_code: '37083'
  },
  '37085': {
    country: 'US',
    city: 'Lascassas',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.9495,
    longitude: -86.3112,
    postal_code: '37085'
  },
  '37086': {
    country: 'US',
    city: 'La Vergne',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 36.0127,
    longitude: -86.56,
    postal_code: '37086'
  },
  '37087': {
    country: 'US',
    city: 'Lebanon',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wilson',
    latitude: 36.2098,
    longitude: -86.3024,
    postal_code: '37087'
  },
  '37088': {
    country: 'US',
    city: 'Lebanon',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wilson',
    latitude: 36.2081,
    longitude: -86.2911,
    postal_code: '37088'
  },
  '37089': {
    country: 'US',
    city: 'La Vergne',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.8596,
    longitude: -86.421,
    postal_code: '37089'
  },
  '37090': {
    country: 'US',
    city: 'Lebanon',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wilson',
    latitude: 36.1185,
    longitude: -86.263,
    postal_code: '37090'
  },
  '37091': {
    country: 'US',
    city: 'Lewisburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Marshall',
    latitude: 35.4596,
    longitude: -86.7812,
    postal_code: '37091'
  },
  '37095': {
    country: 'US',
    city: 'Liberty',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'DeKalb',
    latitude: 36.0067,
    longitude: -85.9678,
    postal_code: '37095'
  },
  '37096': {
    country: 'US',
    city: 'Linden',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Perry',
    latitude: 35.5944,
    longitude: -87.8567,
    postal_code: '37096'
  },
  '37097': {
    country: 'US',
    city: 'Lobelville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Perry',
    latitude: 35.7467,
    longitude: -87.8251,
    postal_code: '37097'
  },
  '37098': {
    country: 'US',
    city: 'Lyles',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hickman',
    latitude: 35.8502,
    longitude: -87.3127,
    postal_code: '37098'
  },
  '37101': {
    country: 'US',
    city: 'Mc Ewen',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Humphreys',
    latitude: 36.1185,
    longitude: -87.6422,
    postal_code: '37101'
  },
  '37110': {
    country: 'US',
    city: 'Mcminnville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Warren',
    latitude: 35.6834,
    longitude: -85.77,
    postal_code: '37110'
  },
  '37111': {
    country: 'US',
    city: 'Mcminnville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Warren',
    latitude: 35.6834,
    longitude: -85.77,
    postal_code: '37111'
  },
  '37115': {
    country: 'US',
    city: 'Madison',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.2604,
    longitude: -86.7046,
    postal_code: '37115'
  },
  '37116': {
    country: 'US',
    city: 'Madison',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37116'
  },
  '37118': {
    country: 'US',
    city: 'Milton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.9221,
    longitude: -86.1824,
    postal_code: '37118'
  },
  '37119': {
    country: 'US',
    city: 'Mitchellville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sumner',
    latitude: 36.632,
    longitude: -86.539,
    postal_code: '37119'
  },
  '37121': {
    country: 'US',
    city: 'Mount Juliet',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wilson',
    latitude: 36.2001,
    longitude: -86.5186,
    postal_code: '37121'
  },
  '37122': {
    country: 'US',
    city: 'Mount Juliet',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wilson',
    latitude: 36.1897,
    longitude: -86.5023,
    postal_code: '37122'
  },
  '37127': {
    country: 'US',
    city: 'Murfreesboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.763,
    longitude: -86.3722,
    postal_code: '37127'
  },
  '37128': {
    country: 'US',
    city: 'Murfreesboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.8454,
    longitude: -86.4867,
    postal_code: '37128'
  },
  '37129': {
    country: 'US',
    city: 'Murfreesboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.871,
    longitude: -86.4181,
    postal_code: '37129'
  },
  '37130': {
    country: 'US',
    city: 'Murfreesboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.8456,
    longitude: -86.3903,
    postal_code: '37130'
  },
  '37131': {
    country: 'US',
    city: 'Murfreesboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.8596,
    longitude: -86.421,
    postal_code: '37131'
  },
  '37132': {
    country: 'US',
    city: 'Murfreesboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.8596,
    longitude: -86.421,
    postal_code: '37132'
  },
  '37133': {
    country: 'US',
    city: 'Murfreesboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.8456,
    longitude: -86.3903,
    postal_code: '37133'
  },
  '37134': {
    country: 'US',
    city: 'New Johnsonville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Humphreys',
    latitude: 36.0088,
    longitude: -87.9547,
    postal_code: '37134'
  },
  '37135': {
    country: 'US',
    city: 'Nolensville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 35.9307,
    longitude: -86.6829,
    postal_code: '37135'
  },
  '37136': {
    country: 'US',
    city: 'Norene',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wilson',
    latitude: 36.1562,
    longitude: -86.3049,
    postal_code: '37136'
  },
  '37137': {
    country: 'US',
    city: 'Nunnelly',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hickman',
    latitude: 35.8763,
    longitude: -87.5067,
    postal_code: '37137'
  },
  '37138': {
    country: 'US',
    city: 'Old Hickory',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.2416,
    longitude: -86.6117,
    postal_code: '37138'
  },
  '37140': {
    country: 'US',
    city: 'Only',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hickman',
    latitude: 35.8679,
    longitude: -87.6655,
    postal_code: '37140'
  },
  '37141': {
    country: 'US',
    city: 'Orlinda',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Robertson',
    latitude: 36.611,
    longitude: -86.699,
    postal_code: '37141'
  },
  '37142': {
    country: 'US',
    city: 'Palmyra',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Montgomery',
    latitude: 36.4176,
    longitude: -87.4914,
    postal_code: '37142'
  },
  '37143': {
    country: 'US',
    city: 'Pegram',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cheatham',
    latitude: 36.1129,
    longitude: -87.0316,
    postal_code: '37143'
  },
  '37144': {
    country: 'US',
    city: 'Petersburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lincoln',
    latitude: 35.2924,
    longitude: -86.6447,
    postal_code: '37144'
  },
  '37145': {
    country: 'US',
    city: 'Pleasant Shade',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Smith',
    latitude: 36.3462,
    longitude: -85.9189,
    postal_code: '37145'
  },
  '37146': {
    country: 'US',
    city: 'Pleasant View',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cheatham',
    latitude: 36.3783,
    longitude: -87.0395,
    postal_code: '37146'
  },
  '37148': {
    country: 'US',
    city: 'Portland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sumner',
    latitude: 36.5673,
    longitude: -86.5059,
    postal_code: '37148'
  },
  '37149': {
    country: 'US',
    city: 'Readyville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cannon',
    latitude: 35.7989,
    longitude: -86.2415,
    postal_code: '37149'
  },
  '37150': {
    country: 'US',
    city: 'Red Boiling Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Macon',
    latitude: 36.5177,
    longitude: -85.8662,
    postal_code: '37150'
  },
  '37151': {
    country: 'US',
    city: 'Riddleton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Smith',
    latitude: 36.3506,
    longitude: -86.0335,
    postal_code: '37151'
  },
  '37152': {
    country: 'US',
    city: 'Ridgetop',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Robertson',
    latitude: 36.4025,
    longitude: -86.7722,
    postal_code: '37152'
  },
  '37153': {
    country: 'US',
    city: 'Rockvale',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.745,
    longitude: -86.5352,
    postal_code: '37153'
  },
  '37160': {
    country: 'US',
    city: 'Shelbyville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bedford',
    latitude: 35.4863,
    longitude: -86.4624,
    postal_code: '37160'
  },
  '37161': {
    country: 'US',
    city: 'Shelbyville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bedford',
    latitude: 35.4834,
    longitude: -86.4603,
    postal_code: '37161'
  },
  '37162': {
    country: 'US',
    city: 'Shelbyville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bedford',
    latitude: 35.5108,
    longitude: -86.45,
    postal_code: '37162'
  },
  '37165': {
    country: 'US',
    city: 'Slayden',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dickson',
    latitude: 36.2948,
    longitude: -87.4703,
    postal_code: '37165'
  },
  '37166': {
    country: 'US',
    city: 'Smithville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'DeKalb',
    latitude: 35.9299,
    longitude: -85.8046,
    postal_code: '37166'
  },
  '37167': {
    country: 'US',
    city: 'Smyrna',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rutherford',
    latitude: 35.9656,
    longitude: -86.5048,
    postal_code: '37167'
  },
  '37171': {
    country: 'US',
    city: 'Southside',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Montgomery',
    latitude: 36.3626,
    longitude: -87.3061,
    postal_code: '37171'
  },
  '37172': {
    country: 'US',
    city: 'Springfield',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Robertson',
    latitude: 36.5018,
    longitude: -86.8769,
    postal_code: '37172'
  },
  '37174': {
    country: 'US',
    city: 'Spring Hill',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Maury',
    latitude: 35.7173,
    longitude: -86.9048,
    postal_code: '37174'
  },
  '37175': {
    country: 'US',
    city: 'Stewart',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Houston',
    latitude: 36.3241,
    longitude: -87.8725,
    postal_code: '37175'
  },
  '37178': {
    country: 'US',
    city: 'Tennessee Ridge',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Houston',
    latitude: 36.3297,
    longitude: -87.7808,
    postal_code: '37178'
  },
  '37179': {
    country: 'US',
    city: 'Thompsons Station',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Williamson',
    latitude: 35.809,
    longitude: -86.8913,
    postal_code: '37179'
  },
  '37180': {
    country: 'US',
    city: 'Unionville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bedford',
    latitude: 35.6224,
    longitude: -86.5639,
    postal_code: '37180'
  },
  '37181': {
    country: 'US',
    city: 'Vanleer',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dickson',
    latitude: 36.2237,
    longitude: -87.4565,
    postal_code: '37181'
  },
  '37183': {
    country: 'US',
    city: 'Wartrace',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bedford',
    latitude: 35.5123,
    longitude: -86.3277,
    postal_code: '37183'
  },
  '37184': {
    country: 'US',
    city: 'Watertown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wilson',
    latitude: 36.0953,
    longitude: -86.1434,
    postal_code: '37184'
  },
  '37185': {
    country: 'US',
    city: 'Waverly',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Humphreys',
    latitude: 36.0997,
    longitude: -87.7991,
    postal_code: '37185'
  },
  '37186': {
    country: 'US',
    city: 'Westmoreland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sumner',
    latitude: 36.5756,
    longitude: -86.2353,
    postal_code: '37186'
  },
  '37187': {
    country: 'US',
    city: 'White Bluff',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dickson',
    latitude: 36.1253,
    longitude: -87.219,
    postal_code: '37187'
  },
  '37188': {
    country: 'US',
    city: 'White House',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Robertson',
    latitude: 36.46,
    longitude: -86.6705,
    postal_code: '37188'
  },
  '37189': {
    country: 'US',
    city: 'Whites Creek',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.2744,
    longitude: -86.8292,
    postal_code: '37189'
  },
  '37190': {
    country: 'US',
    city: 'Woodbury',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cannon',
    latitude: 35.8143,
    longitude: -86.05,
    postal_code: '37190'
  },
  '37191': {
    country: 'US',
    city: 'Woodlawn',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Montgomery',
    latitude: 36.5147,
    longitude: -87.5393,
    postal_code: '37191'
  },
  '37201': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1657,
    longitude: -86.7781,
    postal_code: '37201'
  },
  '37202': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.3403,
    longitude: -86.8273,
    postal_code: '37202'
  },
  '37203': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1504,
    longitude: -86.7916,
    postal_code: '37203'
  },
  '37204': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1067,
    longitude: -86.7743,
    postal_code: '37204'
  },
  '37205': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1114,
    longitude: -86.869,
    postal_code: '37205'
  },
  '37206': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1798,
    longitude: -86.7411,
    postal_code: '37206'
  },
  '37207': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.2195,
    longitude: -86.774,
    postal_code: '37207'
  },
  '37208': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1762,
    longitude: -86.8076,
    postal_code: '37208'
  },
  '37209': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1546,
    longitude: -86.8602,
    postal_code: '37209'
  },
  '37210': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1379,
    longitude: -86.741,
    postal_code: '37210'
  },
  '37211': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.0725,
    longitude: -86.724,
    postal_code: '37211'
  },
  '37212': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1337,
    longitude: -86.8006,
    postal_code: '37212'
  },
  '37213': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1663,
    longitude: -86.7669,
    postal_code: '37213'
  },
  '37214': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1633,
    longitude: -86.6609,
    postal_code: '37214'
  },
  '37215': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.0986,
    longitude: -86.8219,
    postal_code: '37215'
  },
  '37216': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.2125,
    longitude: -86.7257,
    postal_code: '37216'
  },
  '37217': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1052,
    longitude: -86.6591,
    postal_code: '37217'
  },
  '37218': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.2071,
    longitude: -86.8456,
    postal_code: '37218'
  },
  '37219': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1678,
    longitude: -86.7837,
    postal_code: '37219'
  },
  '37220': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.0641,
    longitude: -86.7697,
    postal_code: '37220'
  },
  '37221': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.0662,
    longitude: -86.9639,
    postal_code: '37221'
  },
  '37222': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1659,
    longitude: -86.7844,
    postal_code: '37222'
  },
  '37224': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37224'
  },
  '37227': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37227'
  },
  '37228': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1901,
    longitude: -86.8053,
    postal_code: '37228'
  },
  '37229': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37229'
  },
  '37230': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37230'
  },
  '37232': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37232'
  },
  '37234': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37234'
  },
  '37235': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37235'
  },
  '37236': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37236'
  },
  '37238': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37238'
  },
  '37240': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37240'
  },
  '37241': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37241'
  },
  '37242': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37242'
  },
  '37243': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.165,
    longitude: -86.7821,
    postal_code: '37243'
  },
  '37244': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37244'
  },
  '37246': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1586,
    longitude: -86.79,
    postal_code: '37246'
  },
  '37250': {
    country: 'US',
    city: 'Nashville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Davidson',
    latitude: 36.1866,
    longitude: -86.7852,
    postal_code: '37250'
  },
  '37301': {
    country: 'US',
    city: 'Altamont',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grundy',
    latitude: 35.4258,
    longitude: -85.7635,
    postal_code: '37301'
  },
  '37302': {
    country: 'US',
    city: 'Apison',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0149,
    longitude: -85.0164,
    postal_code: '37302'
  },
  '37303': {
    country: 'US',
    city: 'Athens',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McMinn',
    latitude: 35.4574,
    longitude: -84.6043,
    postal_code: '37303'
  },
  '37304': {
    country: 'US',
    city: 'Bakewell',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.3452,
    longitude: -85.138,
    postal_code: '37304'
  },
  '37305': {
    country: 'US',
    city: 'Beersheba Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grundy',
    latitude: 35.4652,
    longitude: -85.6942,
    postal_code: '37305'
  },
  '37306': {
    country: 'US',
    city: 'Belvidere',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Franklin',
    latitude: 35.1415,
    longitude: -86.1728,
    postal_code: '37306'
  },
  '37307': {
    country: 'US',
    city: 'Benton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Polk',
    latitude: 35.173,
    longitude: -84.6544,
    postal_code: '37307'
  },
  '37308': {
    country: 'US',
    city: 'Birchwood',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.352,
    longitude: -84.9618,
    postal_code: '37308'
  },
  '37309': {
    country: 'US',
    city: 'Calhoun',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McMinn',
    latitude: 35.3228,
    longitude: -84.7364,
    postal_code: '37309'
  },
  '37310': {
    country: 'US',
    city: 'Charleston',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bradley',
    latitude: 35.2556,
    longitude: -84.7666,
    postal_code: '37310'
  },
  '37311': {
    country: 'US',
    city: 'Cleveland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bradley',
    latitude: 35.1313,
    longitude: -84.875,
    postal_code: '37311'
  },
  '37312': {
    country: 'US',
    city: 'Cleveland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bradley',
    latitude: 35.2023,
    longitude: -84.8476,
    postal_code: '37312'
  },
  '37313': {
    country: 'US',
    city: 'Coalmont',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grundy',
    latitude: 35.3516,
    longitude: -85.7003,
    postal_code: '37313'
  },
  '37314': {
    country: 'US',
    city: 'Cokercreek',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Monroe',
    latitude: 35.2467,
    longitude: -84.3031,
    postal_code: '37314'
  },
  '37315': {
    country: 'US',
    city: 'Collegedale',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0479,
    longitude: -85.0574,
    postal_code: '37315'
  },
  '37316': {
    country: 'US',
    city: 'Conasauga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Polk',
    latitude: 34.9971,
    longitude: -84.7327,
    postal_code: '37316'
  },
  '37317': {
    country: 'US',
    city: 'Copperhill',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Polk',
    latitude: 35.0207,
    longitude: -84.3748,
    postal_code: '37317'
  },
  '37318': {
    country: 'US',
    city: 'Cowan',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Franklin',
    latitude: 35.1783,
    longitude: -86.0167,
    postal_code: '37318'
  },
  '37320': {
    country: 'US',
    city: 'Cleveland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bradley',
    latitude: 35.1727,
    longitude: -84.8619,
    postal_code: '37320'
  },
  '37321': {
    country: 'US',
    city: 'Dayton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rhea',
    latitude: 35.5002,
    longitude: -85.0135,
    postal_code: '37321'
  },
  '37322': {
    country: 'US',
    city: 'Decatur',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Meigs',
    latitude: 35.5072,
    longitude: -84.8081,
    postal_code: '37322'
  },
  '37323': {
    country: 'US',
    city: 'Cleveland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bradley',
    latitude: 35.1361,
    longitude: -84.8457,
    postal_code: '37323'
  },
  '37324': {
    country: 'US',
    city: 'Decherd',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Franklin',
    latitude: 35.2326,
    longitude: -86.0589,
    postal_code: '37324'
  },
  '37325': {
    country: 'US',
    city: 'Delano',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Polk',
    latitude: 35.2612,
    longitude: -84.6024,
    postal_code: '37325'
  },
  '37326': {
    country: 'US',
    city: 'Ducktown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Polk',
    latitude: 35.0339,
    longitude: -84.3792,
    postal_code: '37326'
  },
  '37327': {
    country: 'US',
    city: 'Dunlap',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sequatchie',
    latitude: 35.3842,
    longitude: -85.3925,
    postal_code: '37327'
  },
  '37328': {
    country: 'US',
    city: 'Elora',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lincoln',
    latitude: 35.0295,
    longitude: -86.3481,
    postal_code: '37328'
  },
  '37329': {
    country: 'US',
    city: 'Englewood',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McMinn',
    latitude: 35.4272,
    longitude: -84.4833,
    postal_code: '37329'
  },
  '37330': {
    country: 'US',
    city: 'Estill Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Franklin',
    latitude: 35.2705,
    longitude: -86.1396,
    postal_code: '37330'
  },
  '37331': {
    country: 'US',
    city: 'Etowah',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McMinn',
    latitude: 35.3314,
    longitude: -84.5283,
    postal_code: '37331'
  },
  '37332': {
    country: 'US',
    city: 'Evensville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rhea',
    latitude: 35.6153,
    longitude: -85.0228,
    postal_code: '37332'
  },
  '37333': {
    country: 'US',
    city: 'Farner',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Polk',
    latitude: 35.1449,
    longitude: -84.3209,
    postal_code: '37333'
  },
  '37334': {
    country: 'US',
    city: 'Fayetteville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lincoln',
    latitude: 35.1527,
    longitude: -86.5664,
    postal_code: '37334'
  },
  '37335': {
    country: 'US',
    city: 'Flintville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lincoln',
    latitude: 35.0581,
    longitude: -86.413,
    postal_code: '37335'
  },
  '37336': {
    country: 'US',
    city: 'Georgetown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Meigs',
    latitude: 35.2932,
    longitude: -84.9127,
    postal_code: '37336'
  },
  '37337': {
    country: 'US',
    city: 'Grandview',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rhea',
    latitude: 35.7608,
    longitude: -84.8615,
    postal_code: '37337'
  },
  '37338': {
    country: 'US',
    city: 'Graysville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rhea',
    latitude: 35.4484,
    longitude: -85.179,
    postal_code: '37338'
  },
  '37339': {
    country: 'US',
    city: 'Gruetli Laager',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grundy',
    latitude: 35.3723,
    longitude: -85.618,
    postal_code: '37339'
  },
  '37340': {
    country: 'US',
    city: 'Guild',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Marion',
    latitude: 35.0178,
    longitude: -85.5116,
    postal_code: '37340'
  },
  '37341': {
    country: 'US',
    city: 'Harrison',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.1679,
    longitude: -85.0945,
    postal_code: '37341'
  },
  '37342': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Coffee',
    latitude: 35.3699,
    longitude: -85.9724,
    postal_code: '37342'
  },
  '37343': {
    country: 'US',
    city: 'Hixson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.1591,
    longitude: -85.2182,
    postal_code: '37343'
  },
  '37345': {
    country: 'US',
    city: 'Huntland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Franklin',
    latitude: 35.0512,
    longitude: -86.2694,
    postal_code: '37345'
  },
  '37347': {
    country: 'US',
    city: 'Jasper',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Marion',
    latitude: 35.097,
    longitude: -85.5897,
    postal_code: '37347'
  },
  '37348': {
    country: 'US',
    city: 'Kelso',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lincoln',
    latitude: 35.1024,
    longitude: -86.4683,
    postal_code: '37348'
  },
  '37349': {
    country: 'US',
    city: 'Manchester',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Coffee',
    latitude: 35.4976,
    longitude: -86.0748,
    postal_code: '37349'
  },
  '37350': {
    country: 'US',
    city: 'Lookout Mountain',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 34.9948,
    longitude: -85.3506,
    postal_code: '37350'
  },
  '37351': {
    country: 'US',
    city: 'Lupton City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.1045,
    longitude: -85.2631,
    postal_code: '37351'
  },
  '37352': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Moore',
    latitude: 35.2706,
    longitude: -86.372,
    postal_code: '37352'
  },
  '37353': {
    country: 'US',
    city: 'Mc Donald',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bradley',
    latitude: 35.0869,
    longitude: -84.9892,
    postal_code: '37353'
  },
  '37354': {
    country: 'US',
    city: 'Madisonville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Monroe',
    latitude: 35.4916,
    longitude: -84.3395,
    postal_code: '37354'
  },
  '37355': {
    country: 'US',
    city: 'Manchester',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Coffee',
    latitude: 35.4958,
    longitude: -86.0816,
    postal_code: '37355'
  },
  '37356': {
    country: 'US',
    city: 'Monteagle',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grundy',
    latitude: 35.2402,
    longitude: -85.8228,
    postal_code: '37356'
  },
  '37357': {
    country: 'US',
    city: 'Morrison',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Warren',
    latitude: 35.6029,
    longitude: -85.9197,
    postal_code: '37357'
  },
  '37359': {
    country: 'US',
    city: 'Mulberry',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lincoln',
    latitude: 35.1941,
    longitude: -86.4217,
    postal_code: '37359'
  },
  '37360': {
    country: 'US',
    city: 'Normandy',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bedford',
    latitude: 35.4296,
    longitude: -86.2557,
    postal_code: '37360'
  },
  '37361': {
    country: 'US',
    city: 'Ocoee',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Polk',
    latitude: 35.1025,
    longitude: -84.7136,
    postal_code: '37361'
  },
  '37362': {
    country: 'US',
    city: 'Old Fort',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Polk',
    latitude: 35.2829,
    longitude: -85.2568,
    postal_code: '37362'
  },
  '37363': {
    country: 'US',
    city: 'Ooltewah',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0781,
    longitude: -85.0635,
    postal_code: '37363'
  },
  '37364': {
    country: 'US',
    city: 'Cleveland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bradley',
    latitude: 35.1727,
    longitude: -84.8619,
    postal_code: '37364'
  },
  '37365': {
    country: 'US',
    city: 'Palmer',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grundy',
    latitude: 35.3741,
    longitude: -85.5643,
    postal_code: '37365'
  },
  '37366': {
    country: 'US',
    city: 'Pelham',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grundy',
    latitude: 35.314,
    longitude: -85.8441,
    postal_code: '37366'
  },
  '37367': {
    country: 'US',
    city: 'Pikeville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Bledsoe',
    latitude: 35.6408,
    longitude: -85.2077,
    postal_code: '37367'
  },
  '37369': {
    country: 'US',
    city: 'Reliance',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Polk',
    latitude: 35.1807,
    longitude: -84.541,
    postal_code: '37369'
  },
  '37370': {
    country: 'US',
    city: 'Riceville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McMinn',
    latitude: 35.3731,
    longitude: -84.6951,
    postal_code: '37370'
  },
  '37371': {
    country: 'US',
    city: 'Athens',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McMinn',
    latitude: 35.4429,
    longitude: -84.593,
    postal_code: '37371'
  },
  '37373': {
    country: 'US',
    city: 'Sale Creek',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.3858,
    longitude: -85.1023,
    postal_code: '37373'
  },
  '37374': {
    country: 'US',
    city: 'Sequatchie',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Marion',
    latitude: 35.1634,
    longitude: -85.6371,
    postal_code: '37374'
  },
  '37375': {
    country: 'US',
    city: 'Sewanee',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Franklin',
    latitude: 35.2011,
    longitude: -85.9126,
    postal_code: '37375'
  },
  '37376': {
    country: 'US',
    city: 'Sherwood',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Franklin',
    latitude: 35.0452,
    longitude: -85.9302,
    postal_code: '37376'
  },
  '37377': {
    country: 'US',
    city: 'Signal Mountain',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.1494,
    longitude: -85.3362,
    postal_code: '37377'
  },
  '37378': {
    country: 'US',
    city: 'Smartt',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Warren',
    latitude: 35.6403,
    longitude: -85.8366,
    postal_code: '37378'
  },
  '37379': {
    country: 'US',
    city: 'Soddy Daisy',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.2527,
    longitude: -85.163,
    postal_code: '37379'
  },
  '37380': {
    country: 'US',
    city: 'South Pittsburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Marion',
    latitude: 35.028,
    longitude: -85.7225,
    postal_code: '37380'
  },
  '37381': {
    country: 'US',
    city: 'Spring City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Rhea',
    latitude: 35.6935,
    longitude: -84.8198,
    postal_code: '37381'
  },
  '37382': {
    country: 'US',
    city: 'Summitville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Coffee',
    latitude: 35.5595,
    longitude: -85.9925,
    postal_code: '37382'
  },
  '37383': {
    country: 'US',
    city: 'Sewanee',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Franklin',
    latitude: 35.1805,
    longitude: -85.9035,
    postal_code: '37383'
  },
  '37384': {
    country: 'US',
    city: 'Soddy Daisy',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.2211,
    longitude: -85.2091,
    postal_code: '37384'
  },
  '37385': {
    country: 'US',
    city: 'Tellico Plains',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Monroe',
    latitude: 35.3562,
    longitude: -84.3068,
    postal_code: '37385'
  },
  '37387': {
    country: 'US',
    city: 'Tracy City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grundy',
    latitude: 35.2721,
    longitude: -85.7362,
    postal_code: '37387'
  },
  '37388': {
    country: 'US',
    city: 'Tullahoma',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Coffee',
    latitude: 35.3468,
    longitude: -86.22,
    postal_code: '37388'
  },
  '37389': {
    country: 'US',
    city: 'Arnold Afb',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Coffee',
    latitude: 35.4976,
    longitude: -86.0748,
    postal_code: '37389'
  },
  '37391': {
    country: 'US',
    city: 'Turtletown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Polk',
    latitude: 35.1081,
    longitude: -84.3544,
    postal_code: '37391'
  },
  '37394': {
    country: 'US',
    city: 'Viola',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Warren',
    latitude: 35.5384,
    longitude: -85.8591,
    postal_code: '37394'
  },
  '37396': {
    country: 'US',
    city: 'Whiteside',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Marion',
    latitude: 34.9873,
    longitude: -85.4977,
    postal_code: '37396'
  },
  '37397': {
    country: 'US',
    city: 'Whitwell',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Marion',
    latitude: 35.1972,
    longitude: -85.5011,
    postal_code: '37397'
  },
  '37398': {
    country: 'US',
    city: 'Winchester',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Franklin',
    latitude: 35.1864,
    longitude: -86.113,
    postal_code: '37398'
  },
  '37401': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0456,
    longitude: -85.3097,
    postal_code: '37401'
  },
  '37402': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0463,
    longitude: -85.3161,
    postal_code: '37402'
  },
  '37403': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.045,
    longitude: -85.2965,
    postal_code: '37403'
  },
  '37404': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0306,
    longitude: -85.2722,
    postal_code: '37404'
  },
  '37405': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0768,
    longitude: -85.3082,
    postal_code: '37405'
  },
  '37406': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0614,
    longitude: -85.2478,
    postal_code: '37406'
  },
  '37407': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0024,
    longitude: -85.2849,
    postal_code: '37407'
  },
  '37408': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0292,
    longitude: -85.3068,
    postal_code: '37408'
  },
  '37409': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 34.9981,
    longitude: -85.331,
    postal_code: '37409'
  },
  '37410': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0018,
    longitude: -85.3138,
    postal_code: '37410'
  },
  '37411': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0271,
    longitude: -85.2356,
    postal_code: '37411'
  },
  '37412': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0015,
    longitude: -85.2384,
    postal_code: '37412'
  },
  '37414': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0456,
    longitude: -85.3097,
    postal_code: '37414'
  },
  '37415': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.1273,
    longitude: -85.2802,
    postal_code: '37415'
  },
  '37416': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0942,
    longitude: -85.1757,
    postal_code: '37416'
  },
  '37419': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0331,
    longitude: -85.3687,
    postal_code: '37419'
  },
  '37421': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.025,
    longitude: -85.1459,
    postal_code: '37421'
  },
  '37422': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0456,
    longitude: -85.3097,
    postal_code: '37422'
  },
  '37424': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0456,
    longitude: -85.3097,
    postal_code: '37424'
  },
  '37450': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamilton',
    latitude: 35.0456,
    longitude: -85.3097,
    postal_code: '37450'
  },
  '37501': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1693,
    longitude: -89.9904,
    postal_code: '37501'
  },
  '37544': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1496,
    longitude: -90.0487,
    postal_code: '37544'
  },
  '37601': {
    country: 'US',
    city: 'Johnson City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.3339,
    longitude: -82.3408,
    postal_code: '37601'
  },
  '37602': {
    country: 'US',
    city: 'Johnson City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.2717,
    longitude: -82.5012,
    postal_code: '37602'
  },
  '37604': {
    country: 'US',
    city: 'Johnson City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.3107,
    longitude: -82.381,
    postal_code: '37604'
  },
  '37605': {
    country: 'US',
    city: 'Johnson City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.3158,
    longitude: -82.3838,
    postal_code: '37605'
  },
  '37614': {
    country: 'US',
    city: 'Johnson City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.1571,
    longitude: -82.5626,
    postal_code: '37614'
  },
  '37615': {
    country: 'US',
    city: 'Johnson City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.3996,
    longitude: -82.4523,
    postal_code: '37615'
  },
  '37616': {
    country: 'US',
    city: 'Afton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Greene',
    latitude: 36.2265,
    longitude: -82.7476,
    postal_code: '37616'
  },
  '37617': {
    country: 'US',
    city: 'Blountville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.5356,
    longitude: -82.3656,
    postal_code: '37617'
  },
  '37618': {
    country: 'US',
    city: 'Bluff City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.4774,
    longitude: -82.2362,
    postal_code: '37618'
  },
  '37620': {
    country: 'US',
    city: 'Bristol',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.5686,
    longitude: -82.1819,
    postal_code: '37620'
  },
  '37621': {
    country: 'US',
    city: 'Bristol',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.5042,
    longitude: -82.2645,
    postal_code: '37621'
  },
  '37625': {
    country: 'US',
    city: 'Bristol',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.5951,
    longitude: -82.1887,
    postal_code: '37625'
  },
  '37640': {
    country: 'US',
    city: 'Butler',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Johnson',
    latitude: 36.3282,
    longitude: -81.9856,
    postal_code: '37640'
  },
  '37641': {
    country: 'US',
    city: 'Chuckey',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Greene',
    latitude: 36.2569,
    longitude: -82.6905,
    postal_code: '37641'
  },
  '37642': {
    country: 'US',
    city: 'Church Hill',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hawkins',
    latitude: 36.5399,
    longitude: -82.7252,
    postal_code: '37642'
  },
  '37643': {
    country: 'US',
    city: 'Elizabethton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carter',
    latitude: 36.3445,
    longitude: -82.2015,
    postal_code: '37643'
  },
  '37644': {
    country: 'US',
    city: 'Elizabethton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carter',
    latitude: 36.3487,
    longitude: -82.2107,
    postal_code: '37644'
  },
  '37645': {
    country: 'US',
    city: 'Mount Carmel',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hawkins',
    latitude: 36.5629,
    longitude: -82.6532,
    postal_code: '37645'
  },
  '37650': {
    country: 'US',
    city: 'Erwin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Unicoi',
    latitude: 36.1342,
    longitude: -82.4163,
    postal_code: '37650'
  },
  '37656': {
    country: 'US',
    city: 'Fall Branch',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.4158,
    longitude: -82.6256,
    postal_code: '37656'
  },
  '37657': {
    country: 'US',
    city: 'Flag Pond',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Unicoi',
    latitude: 36.0085,
    longitude: -82.5623,
    postal_code: '37657'
  },
  '37658': {
    country: 'US',
    city: 'Hampton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carter',
    latitude: 36.2577,
    longitude: -82.1891,
    postal_code: '37658'
  },
  '37659': {
    country: 'US',
    city: 'Jonesborough',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.2954,
    longitude: -82.4902,
    postal_code: '37659'
  },
  '37660': {
    country: 'US',
    city: 'Kingsport',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.5484,
    longitude: -82.5618,
    postal_code: '37660'
  },
  '37662': {
    country: 'US',
    city: 'Kingsport',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.5484,
    longitude: -82.5618,
    postal_code: '37662'
  },
  '37663': {
    country: 'US',
    city: 'Kingsport',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.4715,
    longitude: -82.4834,
    postal_code: '37663'
  },
  '37664': {
    country: 'US',
    city: 'Kingsport',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.5208,
    longitude: -82.5168,
    postal_code: '37664'
  },
  '37665': {
    country: 'US',
    city: 'Kingsport',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.5799,
    longitude: -82.5733,
    postal_code: '37665'
  },
  '37669': {
    country: 'US',
    city: 'Kingsport',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.5484,
    longitude: -82.5618,
    postal_code: '37669'
  },
  '37680': {
    country: 'US',
    city: 'Laurel Bloomery',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Johnson',
    latitude: 36.5793,
    longitude: -81.7254,
    postal_code: '37680'
  },
  '37681': {
    country: 'US',
    city: 'Limestone',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.254,
    longitude: -82.625,
    postal_code: '37681'
  },
  '37682': {
    country: 'US',
    city: 'Milligan College',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carter',
    latitude: 36.296,
    longitude: -82.3054,
    postal_code: '37682'
  },
  '37683': {
    country: 'US',
    city: 'Mountain City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Johnson',
    latitude: 36.4657,
    longitude: -81.814,
    postal_code: '37683'
  },
  '37684': {
    country: 'US',
    city: 'Mountain Home',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.3107,
    longitude: -82.3732,
    postal_code: '37684'
  },
  '37686': {
    country: 'US',
    city: 'Piney Flats',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.4461,
    longitude: -82.334,
    postal_code: '37686'
  },
  '37687': {
    country: 'US',
    city: 'Roan Mountain',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carter',
    latitude: 36.1773,
    longitude: -82.081,
    postal_code: '37687'
  },
  '37688': {
    country: 'US',
    city: 'Shady Valley',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Johnson',
    latitude: 36.5272,
    longitude: -81.9068,
    postal_code: '37688'
  },
  '37690': {
    country: 'US',
    city: 'Telford',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Washington',
    latitude: 36.2451,
    longitude: -82.5369,
    postal_code: '37690'
  },
  '37691': {
    country: 'US',
    city: 'Trade',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Johnson',
    latitude: 36.3683,
    longitude: -81.7572,
    postal_code: '37691'
  },
  '37692': {
    country: 'US',
    city: 'Unicoi',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Unicoi',
    latitude: 36.2066,
    longitude: -82.322,
    postal_code: '37692'
  },
  '37694': {
    country: 'US',
    city: 'Watauga',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carter',
    latitude: 36.3702,
    longitude: -82.2683,
    postal_code: '37694'
  },
  '37699': {
    country: 'US',
    city: 'Piney Flats',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sullivan',
    latitude: 36.4195,
    longitude: -82.304,
    postal_code: '37699'
  },
  '37701': {
    country: 'US',
    city: 'Alcoa',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.7852,
    longitude: -83.9809,
    postal_code: '37701'
  },
  '37705': {
    country: 'US',
    city: 'Andersonville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Anderson',
    latitude: 36.1915,
    longitude: -84.0544,
    postal_code: '37705'
  },
  '37707': {
    country: 'US',
    city: 'Arthur',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.5481,
    longitude: -83.6707,
    postal_code: '37707'
  },
  '37708': {
    country: 'US',
    city: 'Bean Station',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grainger',
    latitude: 36.3249,
    longitude: -83.3142,
    postal_code: '37708'
  },
  '37709': {
    country: 'US',
    city: 'Blaine',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grainger',
    latitude: 36.1583,
    longitude: -83.6783,
    postal_code: '37709'
  },
  '37710': {
    country: 'US',
    city: 'Briceville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Anderson',
    latitude: 36.1626,
    longitude: -84.2992,
    postal_code: '37710'
  },
  '37711': {
    country: 'US',
    city: 'Bulls Gap',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hawkins',
    latitude: 36.2832,
    longitude: -83.0374,
    postal_code: '37711'
  },
  '37713': {
    country: 'US',
    city: 'Bybee',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cocke',
    latitude: 36.0482,
    longitude: -83.1793,
    postal_code: '37713'
  },
  '37714': {
    country: 'US',
    city: 'Caryville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Campbell',
    latitude: 36.3028,
    longitude: -84.203,
    postal_code: '37714'
  },
  '37715': {
    country: 'US',
    city: 'Clairfield',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.565,
    longitude: -83.9397,
    postal_code: '37715'
  },
  '37716': {
    country: 'US',
    city: 'Clinton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Anderson',
    latitude: 36.0869,
    longitude: -84.1897,
    postal_code: '37716'
  },
  '37717': {
    country: 'US',
    city: 'Clinton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Anderson',
    latitude: 36.11,
    longitude: -84.1672,
    postal_code: '37717'
  },
  '37719': {
    country: 'US',
    city: 'Coalfield',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Morgan',
    latitude: 36.029,
    longitude: -84.4208,
    postal_code: '37719'
  },
  '37721': {
    country: 'US',
    city: 'Corryton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 36.12,
    longitude: -83.813,
    postal_code: '37721'
  },
  '37722': {
    country: 'US',
    city: 'Cosby',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cocke',
    latitude: 35.8347,
    longitude: -83.2187,
    postal_code: '37722'
  },
  '37723': {
    country: 'US',
    city: 'Crab Orchard',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cumberland',
    latitude: 35.9537,
    longitude: -84.8205,
    postal_code: '37723'
  },
  '37724': {
    country: 'US',
    city: 'Cumberland Gap',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.5504,
    longitude: -83.681,
    postal_code: '37724'
  },
  '37725': {
    country: 'US',
    city: 'Dandridge',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Jefferson',
    latitude: 36.0008,
    longitude: -83.4233,
    postal_code: '37725'
  },
  '37726': {
    country: 'US',
    city: 'Deer Lodge',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Morgan',
    latitude: 36.2176,
    longitude: -84.8191,
    postal_code: '37726'
  },
  '37727': {
    country: 'US',
    city: 'Del Rio',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cocke',
    latitude: 35.883,
    longitude: -83.015,
    postal_code: '37727'
  },
  '37729': {
    country: 'US',
    city: 'Duff',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Campbell',
    latitude: 36.5086,
    longitude: -84.0454,
    postal_code: '37729'
  },
  '37730': {
    country: 'US',
    city: 'Eagan',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.552,
    longitude: -83.9769,
    postal_code: '37730'
  },
  '37731': {
    country: 'US',
    city: 'Eidson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hawkins',
    latitude: 36.4995,
    longitude: -83.0827,
    postal_code: '37731'
  },
  '37732': {
    country: 'US',
    city: 'Elgin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Scott',
    latitude: 36.3323,
    longitude: -84.6063,
    postal_code: '37732'
  },
  '37733': {
    country: 'US',
    city: 'Rugby',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Morgan',
    latitude: 36.3519,
    longitude: -84.7137,
    postal_code: '37733'
  },
  '37737': {
    country: 'US',
    city: 'Friendsville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.7523,
    longitude: -84.1061,
    postal_code: '37737'
  },
  '37738': {
    country: 'US',
    city: 'Gatlinburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sevier',
    latitude: 35.729,
    longitude: -83.4874,
    postal_code: '37738'
  },
  '37742': {
    country: 'US',
    city: 'Greenback',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Loudon',
    latitude: 35.6567,
    longitude: -84.171,
    postal_code: '37742'
  },
  '37743': {
    country: 'US',
    city: 'Greeneville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Greene',
    latitude: 36.1316,
    longitude: -82.8692,
    postal_code: '37743'
  },
  '37744': {
    country: 'US',
    city: 'Greeneville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Greene',
    latitude: 36.1683,
    longitude: -82.8548,
    postal_code: '37744'
  },
  '37745': {
    country: 'US',
    city: 'Greeneville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Greene',
    latitude: 36.2455,
    longitude: -82.8238,
    postal_code: '37745'
  },
  '37748': {
    country: 'US',
    city: 'Harriman',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Roane',
    latitude: 35.9348,
    longitude: -84.5155,
    postal_code: '37748'
  },
  '37752': {
    country: 'US',
    city: 'Harrogate',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.5767,
    longitude: -83.6073,
    postal_code: '37752'
  },
  '37753': {
    country: 'US',
    city: 'Hartford',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cocke',
    latitude: 35.8256,
    longitude: -83.0996,
    postal_code: '37753'
  },
  '37754': {
    country: 'US',
    city: 'Heiskell',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 36.115,
    longitude: -84.0438,
    postal_code: '37754'
  },
  '37755': {
    country: 'US',
    city: 'Helenwood',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Scott',
    latitude: 36.4329,
    longitude: -84.5381,
    postal_code: '37755'
  },
  '37756': {
    country: 'US',
    city: 'Huntsville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Scott',
    latitude: 36.3983,
    longitude: -84.4288,
    postal_code: '37756'
  },
  '37757': {
    country: 'US',
    city: 'Jacksboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Campbell',
    latitude: 36.3266,
    longitude: -84.1928,
    postal_code: '37757'
  },
  '37760': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Jefferson',
    latitude: 36.1163,
    longitude: -83.481,
    postal_code: '37760'
  },
  '37762': {
    country: 'US',
    city: 'Jellico',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Campbell',
    latitude: 36.577,
    longitude: -84.126,
    postal_code: '37762'
  },
  '37763': {
    country: 'US',
    city: 'Kingston',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Roane',
    latitude: 35.8528,
    longitude: -84.4971,
    postal_code: '37763'
  },
  '37764': {
    country: 'US',
    city: 'Kodak',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sevier',
    latitude: 35.9722,
    longitude: -83.6171,
    postal_code: '37764'
  },
  '37765': {
    country: 'US',
    city: 'Kyles Ford',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hancock',
    latitude: 36.5721,
    longitude: -83.0507,
    postal_code: '37765'
  },
  '37766': {
    country: 'US',
    city: 'La Follette',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Campbell',
    latitude: 36.4248,
    longitude: -84.0907,
    postal_code: '37766'
  },
  '37769': {
    country: 'US',
    city: 'Lake City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Anderson',
    latitude: 36.2032,
    longitude: -84.1386,
    postal_code: '37769'
  },
  '37770': {
    country: 'US',
    city: 'Lancing',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Morgan',
    latitude: 36.1456,
    longitude: -84.7135,
    postal_code: '37770'
  },
  '37771': {
    country: 'US',
    city: 'Lenoir City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Loudon',
    latitude: 35.8104,
    longitude: -84.2665,
    postal_code: '37771'
  },
  '37772': {
    country: 'US',
    city: 'Lenoir City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Loudon',
    latitude: 35.7883,
    longitude: -84.2188,
    postal_code: '37772'
  },
  '37773': {
    country: 'US',
    city: 'Lone Mountain',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.3906,
    longitude: -83.5849,
    postal_code: '37773'
  },
  '37774': {
    country: 'US',
    city: 'Loudon',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Loudon',
    latitude: 35.7292,
    longitude: -84.3436,
    postal_code: '37774'
  },
  '37777': {
    country: 'US',
    city: 'Louisville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.8375,
    longitude: -84.0089,
    postal_code: '37777'
  },
  '37778': {
    country: 'US',
    city: 'Lowland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamblen',
    latitude: 36.2133,
    longitude: -83.2752,
    postal_code: '37778'
  },
  '37779': {
    country: 'US',
    city: 'Luttrell',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Union',
    latitude: 36.2001,
    longitude: -83.76,
    postal_code: '37779'
  },
  '37801': {
    country: 'US',
    city: 'Maryville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.6884,
    longitude: -84.0769,
    postal_code: '37801'
  },
  '37802': {
    country: 'US',
    city: 'Maryville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.7565,
    longitude: -83.9705,
    postal_code: '37802'
  },
  '37803': {
    country: 'US',
    city: 'Maryville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.6539,
    longitude: -83.9956,
    postal_code: '37803'
  },
  '37804': {
    country: 'US',
    city: 'Maryville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.7991,
    longitude: -83.8852,
    postal_code: '37804'
  },
  '37806': {
    country: 'US',
    city: 'Mascot',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 36.0844,
    longitude: -83.7411,
    postal_code: '37806'
  },
  '37807': {
    country: 'US',
    city: 'Maynardville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Union',
    latitude: 36.2343,
    longitude: -83.8403,
    postal_code: '37807'
  },
  '37809': {
    country: 'US',
    city: 'Midway',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Greene',
    latitude: 36.151,
    longitude: -83.0281,
    postal_code: '37809'
  },
  '37810': {
    country: 'US',
    city: 'Mohawk',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Greene',
    latitude: 36.1869,
    longitude: -83.0902,
    postal_code: '37810'
  },
  '37811': {
    country: 'US',
    city: 'Mooresburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hawkins',
    latitude: 36.3621,
    longitude: -83.2095,
    postal_code: '37811'
  },
  '37813': {
    country: 'US',
    city: 'Morristown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamblen',
    latitude: 36.1957,
    longitude: -83.2755,
    postal_code: '37813'
  },
  '37814': {
    country: 'US',
    city: 'Morristown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamblen',
    latitude: 36.2248,
    longitude: -83.3119,
    postal_code: '37814'
  },
  '37815': {
    country: 'US',
    city: 'Morristown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamblen',
    latitude: 36.2133,
    longitude: -83.2752,
    postal_code: '37815'
  },
  '37816': {
    country: 'US',
    city: 'Morristown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamblen',
    latitude: 36.2133,
    longitude: -83.2752,
    postal_code: '37816'
  },
  '37818': {
    country: 'US',
    city: 'Mosheim',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Greene',
    latitude: 36.1839,
    longitude: -82.9674,
    postal_code: '37818'
  },
  '37819': {
    country: 'US',
    city: 'Newcomb',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Campbell',
    latitude: 36.5428,
    longitude: -84.1798,
    postal_code: '37819'
  },
  '37820': {
    country: 'US',
    city: 'New Market',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Jefferson',
    latitude: 36.081,
    longitude: -83.5673,
    postal_code: '37820'
  },
  '37821': {
    country: 'US',
    city: 'Newport',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cocke',
    latitude: 35.9544,
    longitude: -83.2027,
    postal_code: '37821'
  },
  '37822': {
    country: 'US',
    city: 'Newport',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cocke',
    latitude: 35.967,
    longitude: -83.1877,
    postal_code: '37822'
  },
  '37824': {
    country: 'US',
    city: 'New Tazewell',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.45,
    longitude: -83.5911,
    postal_code: '37824'
  },
  '37825': {
    country: 'US',
    city: 'New Tazewell',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.4245,
    longitude: -83.6469,
    postal_code: '37825'
  },
  '37826': {
    country: 'US',
    city: 'Niota',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McMinn',
    latitude: 35.5819,
    longitude: -84.5721,
    postal_code: '37826'
  },
  '37828': {
    country: 'US',
    city: 'Norris',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Anderson',
    latitude: 36.2005,
    longitude: -84.0858,
    postal_code: '37828'
  },
  '37829': {
    country: 'US',
    city: 'Oakdale',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Morgan',
    latitude: 36.01,
    longitude: -84.5753,
    postal_code: '37829'
  },
  '37830': {
    country: 'US',
    city: 'Oak Ridge',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Anderson',
    latitude: 36.0159,
    longitude: -84.2623,
    postal_code: '37830'
  },
  '37831': {
    country: 'US',
    city: 'Oak Ridge',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Anderson',
    latitude: 36.106,
    longitude: -84.1958,
    postal_code: '37831'
  },
  '37840': {
    country: 'US',
    city: 'Oliver Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Morgan',
    latitude: 36.0366,
    longitude: -84.3486,
    postal_code: '37840'
  },
  '37841': {
    country: 'US',
    city: 'Oneida',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Scott',
    latitude: 36.5053,
    longitude: -84.5293,
    postal_code: '37841'
  },
  '37843': {
    country: 'US',
    city: 'Parrottsville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cocke',
    latitude: 35.9983,
    longitude: -83.0736,
    postal_code: '37843'
  },
  '37845': {
    country: 'US',
    city: 'Petros',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Morgan',
    latitude: 36.0959,
    longitude: -84.4424,
    postal_code: '37845'
  },
  '37846': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Loudon',
    latitude: 35.6781,
    longitude: -84.4312,
    postal_code: '37846'
  },
  '37847': {
    country: 'US',
    city: 'Pioneer',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Campbell',
    latitude: 36.4695,
    longitude: -84.2907,
    postal_code: '37847'
  },
  '37848': {
    country: 'US',
    city: 'Powder Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grainger',
    latitude: 36.2357,
    longitude: -83.6801,
    postal_code: '37848'
  },
  '37849': {
    country: 'US',
    city: 'Powell',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 36.0435,
    longitude: -84.04,
    postal_code: '37849'
  },
  '37851': {
    country: 'US',
    city: 'Pruden',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.5862,
    longitude: -83.901,
    postal_code: '37851'
  },
  '37852': {
    country: 'US',
    city: 'Robbins',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Scott',
    latitude: 36.3527,
    longitude: -84.5904,
    postal_code: '37852'
  },
  '37853': {
    country: 'US',
    city: 'Rockford',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.8305,
    longitude: -83.9412,
    postal_code: '37853'
  },
  '37854': {
    country: 'US',
    city: 'Rockwood',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Roane',
    latitude: 35.8587,
    longitude: -84.6842,
    postal_code: '37854'
  },
  '37857': {
    country: 'US',
    city: 'Rogersville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hawkins',
    latitude: 36.4162,
    longitude: -83.0108,
    postal_code: '37857'
  },
  '37860': {
    country: 'US',
    city: 'Russellville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamblen',
    latitude: 36.2567,
    longitude: -83.1914,
    postal_code: '37860'
  },
  '37861': {
    country: 'US',
    city: 'Rutledge',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grainger',
    latitude: 36.2501,
    longitude: -83.5125,
    postal_code: '37861'
  },
  '37862': {
    country: 'US',
    city: 'Sevierville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sevier',
    latitude: 35.8452,
    longitude: -83.5386,
    postal_code: '37862'
  },
  '37863': {
    country: 'US',
    city: 'Pigeon Forge',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sevier',
    latitude: 35.7922,
    longitude: -83.5638,
    postal_code: '37863'
  },
  '37864': {
    country: 'US',
    city: 'Sevierville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sevier',
    latitude: 35.8542,
    longitude: -83.6138,
    postal_code: '37864'
  },
  '37865': {
    country: 'US',
    city: 'Seymour',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sevier',
    latitude: 35.87,
    longitude: -83.7495,
    postal_code: '37865'
  },
  '37866': {
    country: 'US',
    city: 'Sharps Chapel',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Union',
    latitude: 36.3685,
    longitude: -83.8127,
    postal_code: '37866'
  },
  '37867': {
    country: 'US',
    city: 'Shawanee',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.5803,
    longitude: -83.6473,
    postal_code: '37867'
  },
  '37868': {
    country: 'US',
    city: 'Pigeon Forge',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sevier',
    latitude: 35.8809,
    longitude: -83.5561,
    postal_code: '37868'
  },
  '37869': {
    country: 'US',
    city: 'Sneedville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hancock',
    latitude: 36.5275,
    longitude: -83.2529,
    postal_code: '37869'
  },
  '37870': {
    country: 'US',
    city: 'Speedwell',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.4797,
    longitude: -83.8135,
    postal_code: '37870'
  },
  '37871': {
    country: 'US',
    city: 'Strawberry Plains',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Jefferson',
    latitude: 36.0687,
    longitude: -83.6568,
    postal_code: '37871'
  },
  '37872': {
    country: 'US',
    city: 'Sunbright',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Morgan',
    latitude: 36.2622,
    longitude: -84.6984,
    postal_code: '37872'
  },
  '37873': {
    country: 'US',
    city: 'Surgoinsville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hawkins',
    latitude: 36.4741,
    longitude: -82.8306,
    postal_code: '37873'
  },
  '37874': {
    country: 'US',
    city: 'Sweetwater',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Monroe',
    latitude: 35.6015,
    longitude: -84.461,
    postal_code: '37874'
  },
  '37876': {
    country: 'US',
    city: 'Sevierville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Sevier',
    latitude: 35.866,
    longitude: -83.4793,
    postal_code: '37876'
  },
  '37877': {
    country: 'US',
    city: 'Talbott',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamblen',
    latitude: 36.16,
    longitude: -83.4129,
    postal_code: '37877'
  },
  '37878': {
    country: 'US',
    city: 'Tallassee',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.5844,
    longitude: -83.9923,
    postal_code: '37878'
  },
  '37879': {
    country: 'US',
    city: 'Tazewell',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Claiborne',
    latitude: 36.471,
    longitude: -83.5552,
    postal_code: '37879'
  },
  '37880': {
    country: 'US',
    city: 'Ten Mile',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Meigs',
    latitude: 35.6862,
    longitude: -84.6548,
    postal_code: '37880'
  },
  '37881': {
    country: 'US',
    city: 'Thorn Hill',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grainger',
    latitude: 36.3917,
    longitude: -83.366,
    postal_code: '37881'
  },
  '37882': {
    country: 'US',
    city: 'Townsend',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.6784,
    longitude: -83.7572,
    postal_code: '37882'
  },
  '37885': {
    country: 'US',
    city: 'Vonore',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Monroe',
    latitude: 35.5354,
    longitude: -84.1778,
    postal_code: '37885'
  },
  '37886': {
    country: 'US',
    city: 'Walland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Blount',
    latitude: 35.7533,
    longitude: -83.8243,
    postal_code: '37886'
  },
  '37887': {
    country: 'US',
    city: 'Wartburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Morgan',
    latitude: 36.1016,
    longitude: -84.5652,
    postal_code: '37887'
  },
  '37888': {
    country: 'US',
    city: 'Washburn',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Grainger',
    latitude: 36.3106,
    longitude: -83.5937,
    postal_code: '37888'
  },
  '37890': {
    country: 'US',
    city: 'White Pine',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Jefferson',
    latitude: 36.0776,
    longitude: -83.2998,
    postal_code: '37890'
  },
  '37891': {
    country: 'US',
    city: 'Whitesburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hamblen',
    latitude: 36.2621,
    longitude: -83.1458,
    postal_code: '37891'
  },
  '37892': {
    country: 'US',
    city: 'Winfield',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Scott',
    latitude: 36.5598,
    longitude: -84.434,
    postal_code: '37892'
  },
  '37901': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 36.0323,
    longitude: -83.8848,
    postal_code: '37901'
  },
  '37902': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9625,
    longitude: -83.9209,
    postal_code: '37902'
  },
  '37909': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.946,
    longitude: -84.0235,
    postal_code: '37909'
  },
  '37912': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 36.0055,
    longitude: -83.9773,
    postal_code: '37912'
  },
  '37914': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9918,
    longitude: -83.8496,
    postal_code: '37914'
  },
  '37915': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9721,
    longitude: -83.901,
    postal_code: '37915'
  },
  '37916': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9556,
    longitude: -83.9336,
    postal_code: '37916'
  },
  '37917': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.998,
    longitude: -83.9152,
    postal_code: '37917'
  },
  '37918': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 36.0501,
    longitude: -83.9226,
    postal_code: '37918'
  },
  '37919': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9244,
    longitude: -84.0015,
    postal_code: '37919'
  },
  '37920': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.8929,
    longitude: -83.9387,
    postal_code: '37920'
  },
  '37921': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9793,
    longitude: -84.003,
    postal_code: '37921'
  },
  '37922': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.858,
    longitude: -84.1194,
    postal_code: '37922'
  },
  '37923': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9331,
    longitude: -84.0761,
    postal_code: '37923'
  },
  '37924': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 36.032,
    longitude: -83.8021,
    postal_code: '37924'
  },
  '37927': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9901,
    longitude: -83.9622,
    postal_code: '37927'
  },
  '37928': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9901,
    longitude: -83.9622,
    postal_code: '37928'
  },
  '37929': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9606,
    longitude: -83.9207,
    postal_code: '37929'
  },
  '37930': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9029,
    longitude: -83.9536,
    postal_code: '37930'
  },
  '37931': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9924,
    longitude: -84.1201,
    postal_code: '37931'
  },
  '37932': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9335,
    longitude: -84.1481,
    postal_code: '37932'
  },
  '37933': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9901,
    longitude: -83.9622,
    postal_code: '37933'
  },
  '37934': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.8762,
    longitude: -84.1746,
    postal_code: '37934'
  },
  '37938': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 36.1055,
    longitude: -83.946,
    postal_code: '37938'
  },
  '37939': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9901,
    longitude: -83.9622,
    postal_code: '37939'
  },
  '37940': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9901,
    longitude: -83.9622,
    postal_code: '37940'
  },
  '37950': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9901,
    longitude: -83.9622,
    postal_code: '37950'
  },
  '37995': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9606,
    longitude: -83.9207,
    postal_code: '37995'
  },
  '37996': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9901,
    longitude: -83.9622,
    postal_code: '37996'
  },
  '37997': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9901,
    longitude: -83.9622,
    postal_code: '37997'
  },
  '37998': {
    country: 'US',
    city: 'Knoxville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Knox',
    latitude: 35.9351,
    longitude: -83.7503,
    postal_code: '37998'
  },
  '38001': {
    country: 'US',
    city: 'Alamo',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Crockett',
    latitude: 35.8017,
    longitude: -89.1765,
    postal_code: '38001'
  },
  '38002': {
    country: 'US',
    city: 'Arlington',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2752,
    longitude: -89.7295,
    postal_code: '38002'
  },
  '38004': {
    country: 'US',
    city: 'Atoka',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Tipton',
    latitude: 35.4412,
    longitude: -89.7781,
    postal_code: '38004'
  },
  '38006': {
    country: 'US',
    city: 'Bells',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Crockett',
    latitude: 35.7198,
    longitude: -89.0729,
    postal_code: '38006'
  },
  '38007': {
    country: 'US',
    city: 'Bogota',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dyer',
    latitude: 36.164,
    longitude: -89.4384,
    postal_code: '38007'
  },
  '38008': {
    country: 'US',
    city: 'Bolivar',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardeman',
    latitude: 35.2461,
    longitude: -89.0007,
    postal_code: '38008'
  },
  '38010': {
    country: 'US',
    city: 'Braden',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fayette',
    latitude: 35.2886,
    longitude: -89.488,
    postal_code: '38010'
  },
  '38011': {
    country: 'US',
    city: 'Brighton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Tipton',
    latitude: 35.484,
    longitude: -89.7251,
    postal_code: '38011'
  },
  '38012': {
    country: 'US',
    city: 'Brownsville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Haywood',
    latitude: 35.61,
    longitude: -89.2624,
    postal_code: '38012'
  },
  '38014': {
    country: 'US',
    city: 'Brunswick',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2673,
    longitude: -89.7687,
    postal_code: '38014'
  },
  '38015': {
    country: 'US',
    city: 'Burlison',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Tipton',
    latitude: 35.5399,
    longitude: -89.8177,
    postal_code: '38015'
  },
  '38016': {
    country: 'US',
    city: 'Cordova',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1602,
    longitude: -89.7816,
    postal_code: '38016'
  },
  '38017': {
    country: 'US',
    city: 'Collierville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0551,
    longitude: -89.6767,
    postal_code: '38017'
  },
  '38018': {
    country: 'US',
    city: 'Cordova',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1556,
    longitude: -89.7762,
    postal_code: '38018'
  },
  '38019': {
    country: 'US',
    city: 'Covington',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Tipton',
    latitude: 35.5598,
    longitude: -89.6501,
    postal_code: '38019'
  },
  '38021': {
    country: 'US',
    city: 'Crockett Mills',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Crockett',
    latitude: 35.8676,
    longitude: -89.1692,
    postal_code: '38021'
  },
  '38023': {
    country: 'US',
    city: 'Drummonds',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Tipton',
    latitude: 35.4452,
    longitude: -89.9236,
    postal_code: '38023'
  },
  '38024': {
    country: 'US',
    city: 'Dyersburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dyer',
    latitude: 36.0444,
    longitude: -89.3836,
    postal_code: '38024'
  },
  '38025': {
    country: 'US',
    city: 'Dyersburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dyer',
    latitude: 36.0345,
    longitude: -89.3856,
    postal_code: '38025'
  },
  '38027': {
    country: 'US',
    city: 'Collierville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.042,
    longitude: -89.6645,
    postal_code: '38027'
  },
  '38028': {
    country: 'US',
    city: 'Eads',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1551,
    longitude: -89.676,
    postal_code: '38028'
  },
  '38029': {
    country: 'US',
    city: 'Ellendale',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2306,
    longitude: -89.8259,
    postal_code: '38029'
  },
  '38030': {
    country: 'US',
    city: 'Finley',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dyer',
    latitude: 36.0169,
    longitude: -89.5134,
    postal_code: '38030'
  },
  '38034': {
    country: 'US',
    city: 'Friendship',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Crockett',
    latitude: 35.909,
    longitude: -89.2453,
    postal_code: '38034'
  },
  '38036': {
    country: 'US',
    city: 'Gallaway',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fayette',
    latitude: 35.3274,
    longitude: -89.6198,
    postal_code: '38036'
  },
  '38037': {
    country: 'US',
    city: 'Gates',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lauderdale',
    latitude: 35.796,
    longitude: -89.3938,
    postal_code: '38037'
  },
  '38039': {
    country: 'US',
    city: 'Grand Junction',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardeman',
    latitude: 35.049,
    longitude: -89.187,
    postal_code: '38039'
  },
  '38040': {
    country: 'US',
    city: 'Halls',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lauderdale',
    latitude: 35.8862,
    longitude: -89.4147,
    postal_code: '38040'
  },
  '38041': {
    country: 'US',
    city: 'Henning',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lauderdale',
    latitude: 35.6593,
    longitude: -89.6962,
    postal_code: '38041'
  },
  '38042': {
    country: 'US',
    city: 'Hickory Valley',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardeman',
    latitude: 35.158,
    longitude: -89.1309,
    postal_code: '38042'
  },
  '38044': {
    country: 'US',
    city: 'Hornsby',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardeman',
    latitude: 35.2197,
    longitude: -88.8263,
    postal_code: '38044'
  },
  '38045': {
    country: 'US',
    city: 'Laconia',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fayette',
    latitude: 35.2904,
    longitude: -89.2322,
    postal_code: '38045'
  },
  '38046': {
    country: 'US',
    city: 'La Grange',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fayette',
    latitude: 35.0454,
    longitude: -89.2437,
    postal_code: '38046'
  },
  '38047': {
    country: 'US',
    city: 'Lenox',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dyer',
    latitude: 36.0892,
    longitude: -89.4984,
    postal_code: '38047'
  },
  '38048': {
    country: 'US',
    city: 'Macon',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fayette',
    latitude: 35.1507,
    longitude: -89.4814,
    postal_code: '38048'
  },
  '38049': {
    country: 'US',
    city: 'Mason',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Tipton',
    latitude: 35.438,
    longitude: -89.5518,
    postal_code: '38049'
  },
  '38050': {
    country: 'US',
    city: 'Maury City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Crockett',
    latitude: 35.8379,
    longitude: -89.2273,
    postal_code: '38050'
  },
  '38052': {
    country: 'US',
    city: 'Middleton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardeman',
    latitude: 35.0818,
    longitude: -88.9046,
    postal_code: '38052'
  },
  '38053': {
    country: 'US',
    city: 'Millington',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.3185,
    longitude: -89.9054,
    postal_code: '38053'
  },
  '38054': {
    country: 'US',
    city: 'Millington',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.3341,
    longitude: -89.8706,
    postal_code: '38054'
  },
  '38055': {
    country: 'US',
    city: 'Millington',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.3415,
    longitude: -89.8973,
    postal_code: '38055'
  },
  '38057': {
    country: 'US',
    city: 'Moscow',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fayette',
    latitude: 35.0588,
    longitude: -89.3595,
    postal_code: '38057'
  },
  '38058': {
    country: 'US',
    city: 'Munford',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Tipton',
    latitude: 35.459,
    longitude: -89.8197,
    postal_code: '38058'
  },
  '38059': {
    country: 'US',
    city: 'Newbern',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dyer',
    latitude: 36.1009,
    longitude: -89.2514,
    postal_code: '38059'
  },
  '38060': {
    country: 'US',
    city: 'Oakland',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fayette',
    latitude: 35.229,
    longitude: -89.5151,
    postal_code: '38060'
  },
  '38061': {
    country: 'US',
    city: 'Pocahontas',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardeman',
    latitude: 35.0315,
    longitude: -88.8116,
    postal_code: '38061'
  },
  '38063': {
    country: 'US',
    city: 'Ripley',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lauderdale',
    latitude: 35.7527,
    longitude: -89.535,
    postal_code: '38063'
  },
  '38066': {
    country: 'US',
    city: 'Rossville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fayette',
    latitude: 35.0768,
    longitude: -89.5678,
    postal_code: '38066'
  },
  '38067': {
    country: 'US',
    city: 'Saulsbury',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardeman',
    latitude: 35.0498,
    longitude: -89.0769,
    postal_code: '38067'
  },
  '38068': {
    country: 'US',
    city: 'Somerville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fayette',
    latitude: 35.2772,
    longitude: -89.3918,
    postal_code: '38068'
  },
  '38069': {
    country: 'US',
    city: 'Stanton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Haywood',
    latitude: 35.4482,
    longitude: -89.3326,
    postal_code: '38069'
  },
  '38070': {
    country: 'US',
    city: 'Tigrett',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dyer',
    latitude: 35.9515,
    longitude: -89.2401,
    postal_code: '38070'
  },
  '38071': {
    country: 'US',
    city: 'Tipton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Tipton',
    latitude: 35.4143,
    longitude: -89.8188,
    postal_code: '38071'
  },
  '38075': {
    country: 'US',
    city: 'Whiteville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardeman',
    latitude: 35.3191,
    longitude: -89.1337,
    postal_code: '38075'
  },
  '38076': {
    country: 'US',
    city: 'Williston',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fayette',
    latitude: 35.1576,
    longitude: -89.3684,
    postal_code: '38076'
  },
  '38077': {
    country: 'US',
    city: 'Wynnburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lake',
    latitude: 36.3281,
    longitude: -89.4744,
    postal_code: '38077'
  },
  '38079': {
    country: 'US',
    city: 'Tiptonville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lake',
    latitude: 36.3846,
    longitude: -89.4649,
    postal_code: '38079'
  },
  '38080': {
    country: 'US',
    city: 'Ridgely',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lake',
    latitude: 36.2638,
    longitude: -89.4858,
    postal_code: '38080'
  },
  '38083': {
    country: 'US',
    city: 'Millington',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38083'
  },
  '38088': {
    country: 'US',
    city: 'Cordova',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1556,
    longitude: -89.7762,
    postal_code: '38088'
  },
  '38101': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1495,
    longitude: -90.049,
    postal_code: '38101'
  },
  '38103': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.144,
    longitude: -90.048,
    postal_code: '38103'
  },
  '38104': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1334,
    longitude: -90.0046,
    postal_code: '38104'
  },
  '38105': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1497,
    longitude: -90.033,
    postal_code: '38105'
  },
  '38106': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1021,
    longitude: -90.033,
    postal_code: '38106'
  },
  '38107': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1831,
    longitude: -90.0201,
    postal_code: '38107'
  },
  '38108': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1787,
    longitude: -89.9682,
    postal_code: '38108'
  },
  '38109': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0425,
    longitude: -90.0732,
    postal_code: '38109'
  },
  '38111': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1076,
    longitude: -89.9457,
    postal_code: '38111'
  },
  '38112': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1483,
    longitude: -89.9729,
    postal_code: '38112'
  },
  '38113': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1274,
    longitude: -89.9845,
    postal_code: '38113'
  },
  '38114': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0981,
    longitude: -89.9825,
    postal_code: '38114'
  },
  '38115': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0579,
    longitude: -89.864,
    postal_code: '38115'
  },
  '38116': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0303,
    longitude: -90.0123,
    postal_code: '38116'
  },
  '38117': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1124,
    longitude: -89.9034,
    postal_code: '38117'
  },
  '38118': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0514,
    longitude: -89.9265,
    postal_code: '38118'
  },
  '38119': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0821,
    longitude: -89.8501,
    postal_code: '38119'
  },
  '38120': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1207,
    longitude: -89.8651,
    postal_code: '38120'
  },
  '38122': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1572,
    longitude: -89.9268,
    postal_code: '38122'
  },
  '38124': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38124'
  },
  '38125': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0312,
    longitude: -89.8124,
    postal_code: '38125'
  },
  '38126': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1255,
    longitude: -90.0424,
    postal_code: '38126'
  },
  '38127': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.251,
    longitude: -90.0296,
    postal_code: '38127'
  },
  '38128': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2213,
    longitude: -89.9413,
    postal_code: '38128'
  },
  '38130': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38130'
  },
  '38131': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0664,
    longitude: -89.9921,
    postal_code: '38131'
  },
  '38132': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.072,
    longitude: -89.9886,
    postal_code: '38132'
  },
  '38133': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2054,
    longitude: -89.8036,
    postal_code: '38133'
  },
  '38134': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1845,
    longitude: -89.8574,
    postal_code: '38134'
  },
  '38135': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2323,
    longitude: -89.8509,
    postal_code: '38135'
  },
  '38136': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38136'
  },
  '38137': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38137'
  },
  '38138': {
    country: 'US',
    city: 'Germantown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0883,
    longitude: -89.8053,
    postal_code: '38138'
  },
  '38139': {
    country: 'US',
    city: 'Germantown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0874,
    longitude: -89.7703,
    postal_code: '38139'
  },
  '38141': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.0231,
    longitude: -89.8492,
    postal_code: '38141'
  },
  '38145': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38145'
  },
  '38148': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1495,
    longitude: -90.049,
    postal_code: '38148'
  },
  '38150': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1495,
    longitude: -90.049,
    postal_code: '38150'
  },
  '38151': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38151'
  },
  '38152': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38152'
  },
  '38157': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38157'
  },
  '38159': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1495,
    longitude: -90.049,
    postal_code: '38159'
  },
  '38161': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38161'
  },
  '38163': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38163'
  },
  '38166': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38166'
  },
  '38167': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38167'
  },
  '38168': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38168'
  },
  '38173': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.1495,
    longitude: -90.049,
    postal_code: '38173'
  },
  '38174': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38174'
  },
  '38175': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38175'
  },
  '38177': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38177'
  },
  '38181': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38181'
  },
  '38182': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38182'
  },
  '38183': {
    country: 'US',
    city: 'Germantown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38183'
  },
  '38184': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38184'
  },
  '38186': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38186'
  },
  '38187': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38187'
  },
  '38188': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38188'
  },
  '38190': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38190'
  },
  '38193': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38193'
  },
  '38194': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38194'
  },
  '38197': {
    country: 'US',
    city: 'Memphis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Shelby',
    latitude: 35.2017,
    longitude: -89.9715,
    postal_code: '38197'
  },
  '38201': {
    country: 'US',
    city: 'Mc Kenzie',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 36.1272,
    longitude: -88.5134,
    postal_code: '38201'
  },
  '38220': {
    country: 'US',
    city: 'Atwood',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 35.9663,
    longitude: -88.6247,
    postal_code: '38220'
  },
  '38221': {
    country: 'US',
    city: 'Big Sandy',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Benton',
    latitude: 36.2285,
    longitude: -88.0627,
    postal_code: '38221'
  },
  '38222': {
    country: 'US',
    city: 'Buchanan',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henry',
    latitude: 36.4146,
    longitude: -88.1515,
    postal_code: '38222'
  },
  '38223': {
    country: 'US',
    city: 'Como',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henry',
    latitude: 36.2931,
    longitude: -88.5123,
    postal_code: '38223'
  },
  '38224': {
    country: 'US',
    city: 'Cottage Grove',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henry',
    latitude: 36.3479,
    longitude: -88.4614,
    postal_code: '38224'
  },
  '38225': {
    country: 'US',
    city: 'Dresden',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Weakley',
    latitude: 36.295,
    longitude: -88.6963,
    postal_code: '38225'
  },
  '38226': {
    country: 'US',
    city: 'Dukedom',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Weakley',
    latitude: 36.4796,
    longitude: -88.6926,
    postal_code: '38226'
  },
  '38229': {
    country: 'US',
    city: 'Gleason',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Weakley',
    latitude: 36.2117,
    longitude: -88.6184,
    postal_code: '38229'
  },
  '38230': {
    country: 'US',
    city: 'Greenfield',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Weakley',
    latitude: 36.1485,
    longitude: -88.7453,
    postal_code: '38230'
  },
  '38231': {
    country: 'US',
    city: 'Henry',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henry',
    latitude: 36.2039,
    longitude: -88.4198,
    postal_code: '38231'
  },
  '38232': {
    country: 'US',
    city: 'Hornbeak',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Obion',
    latitude: 36.3596,
    longitude: -89.3056,
    postal_code: '38232'
  },
  '38233': {
    country: 'US',
    city: 'Kenton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 36.1906,
    longitude: -89.0229,
    postal_code: '38233'
  },
  '38235': {
    country: 'US',
    city: 'Mc Lemoresville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 35.9778,
    longitude: -88.5744,
    postal_code: '38235'
  },
  '38236': {
    country: 'US',
    city: 'Mansfield',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henry',
    latitude: 36.1847,
    longitude: -88.2859,
    postal_code: '38236'
  },
  '38237': {
    country: 'US',
    city: 'Martin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Weakley',
    latitude: 36.3425,
    longitude: -88.8554,
    postal_code: '38237'
  },
  '38238': {
    country: 'US',
    city: 'Martin',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Weakley',
    latitude: 36.3434,
    longitude: -88.8503,
    postal_code: '38238'
  },
  '38240': {
    country: 'US',
    city: 'Obion',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Obion',
    latitude: 36.2657,
    longitude: -89.2805,
    postal_code: '38240'
  },
  '38241': {
    country: 'US',
    city: 'Palmersville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Weakley',
    latitude: 36.3948,
    longitude: -88.6142,
    postal_code: '38241'
  },
  '38242': {
    country: 'US',
    city: 'Paris',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henry',
    latitude: 36.3005,
    longitude: -88.3093,
    postal_code: '38242'
  },
  '38251': {
    country: 'US',
    city: 'Puryear',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henry',
    latitude: 36.4456,
    longitude: -88.3472,
    postal_code: '38251'
  },
  '38253': {
    country: 'US',
    city: 'Rives',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Obion',
    latitude: 36.3108,
    longitude: -89.0731,
    postal_code: '38253'
  },
  '38254': {
    country: 'US',
    city: 'Samburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Obion',
    latitude: 36.3791,
    longitude: -89.3538,
    postal_code: '38254'
  },
  '38255': {
    country: 'US',
    city: 'Sharon',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Weakley',
    latitude: 36.2395,
    longitude: -88.8477,
    postal_code: '38255'
  },
  '38256': {
    country: 'US',
    city: 'Springville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henry',
    latitude: 36.265,
    longitude: -88.1459,
    postal_code: '38256'
  },
  '38257': {
    country: 'US',
    city: 'South Fulton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Obion',
    latitude: 36.4814,
    longitude: -88.8808,
    postal_code: '38257'
  },
  '38258': {
    country: 'US',
    city: 'Trezevant',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 36.0172,
    longitude: -88.61,
    postal_code: '38258'
  },
  '38259': {
    country: 'US',
    city: 'Trimble',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Dyer',
    latitude: 36.2011,
    longitude: -89.1863,
    postal_code: '38259'
  },
  '38260': {
    country: 'US',
    city: 'Troy',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Obion',
    latitude: 36.3416,
    longitude: -89.1611,
    postal_code: '38260'
  },
  '38261': {
    country: 'US',
    city: 'Union City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Obion',
    latitude: 36.4263,
    longitude: -89.0667,
    postal_code: '38261'
  },
  '38271': {
    country: 'US',
    city: 'Woodland Mills',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Obion',
    latitude: 36.4583,
    longitude: -89.2332,
    postal_code: '38271'
  },
  '38281': {
    country: 'US',
    city: 'Union City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Obion',
    latitude: 36.4242,
    longitude: -89.057,
    postal_code: '38281'
  },
  '38301': {
    country: 'US',
    city: 'Jackson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.6102,
    longitude: -88.814,
    postal_code: '38301'
  },
  '38302': {
    country: 'US',
    city: 'Jackson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.6124,
    longitude: -88.8412,
    postal_code: '38302'
  },
  '38303': {
    country: 'US',
    city: 'Jackson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.6124,
    longitude: -88.8412,
    postal_code: '38303'
  },
  '38305': {
    country: 'US',
    city: 'Jackson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.6829,
    longitude: -88.8281,
    postal_code: '38305'
  },
  '38308': {
    country: 'US',
    city: 'Jackson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.6124,
    longitude: -88.8412,
    postal_code: '38308'
  },
  '38310': {
    country: 'US',
    city: 'Adamsville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McNairy',
    latitude: 35.2556,
    longitude: -88.4134,
    postal_code: '38310'
  },
  '38311': {
    country: 'US',
    city: 'Bath Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Decatur',
    latitude: 35.4521,
    longitude: -88.1286,
    postal_code: '38311'
  },
  '38313': {
    country: 'US',
    city: 'Beech Bluff',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.5922,
    longitude: -88.6396,
    postal_code: '38313'
  },
  '38314': {
    country: 'US',
    city: 'Jackson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.6124,
    longitude: -88.8412,
    postal_code: '38314'
  },
  '38315': {
    country: 'US',
    city: 'Bethel Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McNairy',
    latitude: 35.2289,
    longitude: -88.644,
    postal_code: '38315'
  },
  '38316': {
    country: 'US',
    city: 'Bradford',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 36.0645,
    longitude: -88.8046,
    postal_code: '38316'
  },
  '38317': {
    country: 'US',
    city: 'Bruceton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 36.0268,
    longitude: -88.2518,
    postal_code: '38317'
  },
  '38318': {
    country: 'US',
    city: 'Buena Vista',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 35.9431,
    longitude: -88.2926,
    postal_code: '38318'
  },
  '38320': {
    country: 'US',
    city: 'Camden',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Benton',
    latitude: 36.0556,
    longitude: -88.1119,
    postal_code: '38320'
  },
  '38321': {
    country: 'US',
    city: 'Cedar Grove',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 35.8613,
    longitude: -88.5517,
    postal_code: '38321'
  },
  '38324': {
    country: 'US',
    city: 'Clarksburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 35.8675,
    longitude: -88.3934,
    postal_code: '38324'
  },
  '38326': {
    country: 'US',
    city: 'Counce',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardin',
    latitude: 35.0394,
    longitude: -88.2936,
    postal_code: '38326'
  },
  '38327': {
    country: 'US',
    city: 'Crump',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardin',
    latitude: 35.2191,
    longitude: -88.3192,
    postal_code: '38327'
  },
  '38328': {
    country: 'US',
    city: 'Darden',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henderson',
    latitude: 35.663,
    longitude: -88.2177,
    postal_code: '38328'
  },
  '38329': {
    country: 'US',
    city: 'Decaturville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Decatur',
    latitude: 35.5585,
    longitude: -88.1334,
    postal_code: '38329'
  },
  '38330': {
    country: 'US',
    city: 'Dyer',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 36.0716,
    longitude: -89.0192,
    postal_code: '38330'
  },
  '38331': {
    country: 'US',
    city: 'Eaton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 35.9693,
    longitude: -89.1319,
    postal_code: '38331'
  },
  '38332': {
    country: 'US',
    city: 'Enville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Chester',
    latitude: 35.4393,
    longitude: -88.4206,
    postal_code: '38332'
  },
  '38333': {
    country: 'US',
    city: 'Eva',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Benton',
    latitude: 36.1142,
    longitude: -87.982,
    postal_code: '38333'
  },
  '38334': {
    country: 'US',
    city: 'Finger',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McNairy',
    latitude: 35.3544,
    longitude: -88.5876,
    postal_code: '38334'
  },
  '38336': {
    country: 'US',
    city: 'Fruitvale',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Crockett',
    latitude: 35.7484,
    longitude: -89.0327,
    postal_code: '38336'
  },
  '38337': {
    country: 'US',
    city: 'Gadsden',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Crockett',
    latitude: 35.7799,
    longitude: -88.9929,
    postal_code: '38337'
  },
  '38338': {
    country: 'US',
    city: 'Gibson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 35.8708,
    longitude: -88.8464,
    postal_code: '38338'
  },
  '38339': {
    country: 'US',
    city: 'Guys',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McNairy',
    latitude: 35.0142,
    longitude: -88.5208,
    postal_code: '38339'
  },
  '38340': {
    country: 'US',
    city: 'Henderson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Chester',
    latitude: 35.4269,
    longitude: -88.6398,
    postal_code: '38340'
  },
  '38341': {
    country: 'US',
    city: 'Holladay',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Benton',
    latitude: 35.8951,
    longitude: -88.0912,
    postal_code: '38341'
  },
  '38342': {
    country: 'US',
    city: 'Hollow Rock',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 36.0381,
    longitude: -88.2737,
    postal_code: '38342'
  },
  '38343': {
    country: 'US',
    city: 'Humboldt',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 35.837,
    longitude: -88.9057,
    postal_code: '38343'
  },
  '38344': {
    country: 'US',
    city: 'Huntingdon',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 36.0062,
    longitude: -88.4202,
    postal_code: '38344'
  },
  '38345': {
    country: 'US',
    city: 'Huron',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henderson',
    latitude: 35.6144,
    longitude: -88.5195,
    postal_code: '38345'
  },
  '38346': {
    country: 'US',
    city: 'Idlewild',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 36.0306,
    longitude: -88.8056,
    postal_code: '38346'
  },
  '38347': {
    country: 'US',
    city: 'Jacks Creek',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Chester',
    latitude: 35.4782,
    longitude: -88.5019,
    postal_code: '38347'
  },
  '38348': {
    country: 'US',
    city: 'Lavinia',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 35.8583,
    longitude: -88.6324,
    postal_code: '38348'
  },
  '38351': {
    country: 'US',
    city: 'Lexington',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henderson',
    latitude: 35.6512,
    longitude: -88.3927,
    postal_code: '38351'
  },
  '38352': {
    country: 'US',
    city: 'Luray',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Chester',
    latitude: 35.5355,
    longitude: -88.5379,
    postal_code: '38352'
  },
  '38355': {
    country: 'US',
    city: 'Medina',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 35.8081,
    longitude: -88.7627,
    postal_code: '38355'
  },
  '38356': {
    country: 'US',
    city: 'Medon',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.4345,
    longitude: -88.8953,
    postal_code: '38356'
  },
  '38357': {
    country: 'US',
    city: 'Michie',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McNairy',
    latitude: 35.0603,
    longitude: -88.4405,
    postal_code: '38357'
  },
  '38358': {
    country: 'US',
    city: 'Milan',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 35.9251,
    longitude: -88.7688,
    postal_code: '38358'
  },
  '38359': {
    country: 'US',
    city: 'Milledgeville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McNairy',
    latitude: 35.3729,
    longitude: -88.3675,
    postal_code: '38359'
  },
  '38361': {
    country: 'US',
    city: 'Morris Chapel',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardin',
    latitude: 35.3026,
    longitude: -88.3176,
    postal_code: '38361'
  },
  '38362': {
    country: 'US',
    city: 'Oakfield',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.7242,
    longitude: -88.7801,
    postal_code: '38362'
  },
  '38363': {
    country: 'US',
    city: 'Parsons',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Decatur',
    latitude: 35.6664,
    longitude: -88.1195,
    postal_code: '38363'
  },
  '38365': {
    country: 'US',
    city: 'Pickwick Dam',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardin',
    latitude: 35.0383,
    longitude: -88.2256,
    postal_code: '38365'
  },
  '38366': {
    country: 'US',
    city: 'Pinson',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.4781,
    longitude: -88.7304,
    postal_code: '38366'
  },
  '38367': {
    country: 'US',
    city: 'Ramer',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McNairy',
    latitude: 35.0642,
    longitude: -88.6017,
    postal_code: '38367'
  },
  '38368': {
    country: 'US',
    city: 'Reagan',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henderson',
    latitude: 35.5096,
    longitude: -88.3508,
    postal_code: '38368'
  },
  '38369': {
    country: 'US',
    city: 'Rutherford',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 36.13,
    longitude: -88.9849,
    postal_code: '38369'
  },
  '38370': {
    country: 'US',
    city: 'Saltillo',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardin',
    latitude: 35.3815,
    longitude: -88.2472,
    postal_code: '38370'
  },
  '38371': {
    country: 'US',
    city: 'Sardis',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henderson',
    latitude: 35.4386,
    longitude: -88.3058,
    postal_code: '38371'
  },
  '38372': {
    country: 'US',
    city: 'Savannah',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardin',
    latitude: 35.2023,
    longitude: -88.2005,
    postal_code: '38372'
  },
  '38374': {
    country: 'US',
    city: 'Scotts Hill',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Decatur',
    latitude: 35.505,
    longitude: -88.2405,
    postal_code: '38374'
  },
  '38375': {
    country: 'US',
    city: 'Selmer',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McNairy',
    latitude: 35.1691,
    longitude: -88.5958,
    postal_code: '38375'
  },
  '38376': {
    country: 'US',
    city: 'Shiloh',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardin',
    latitude: 35.1154,
    longitude: -88.3617,
    postal_code: '38376'
  },
  '38377': {
    country: 'US',
    city: 'Silerton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardeman',
    latitude: 35.356,
    longitude: -88.8269,
    postal_code: '38377'
  },
  '38378': {
    country: 'US',
    city: 'Spring Creek',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.7668,
    longitude: -88.6839,
    postal_code: '38378'
  },
  '38379': {
    country: 'US',
    city: 'Stantonville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McNairy',
    latitude: 35.1809,
    longitude: -88.4364,
    postal_code: '38379'
  },
  '38380': {
    country: 'US',
    city: 'Sugar Tree',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Decatur',
    latitude: 35.792,
    longitude: -88.0322,
    postal_code: '38380'
  },
  '38381': {
    country: 'US',
    city: 'Toone',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardeman',
    latitude: 35.3574,
    longitude: -88.9353,
    postal_code: '38381'
  },
  '38382': {
    country: 'US',
    city: 'Trenton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 35.9712,
    longitude: -88.9507,
    postal_code: '38382'
  },
  '38387': {
    country: 'US',
    city: 'Westport',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 35.8845,
    longitude: -88.2892,
    postal_code: '38387'
  },
  '38388': {
    country: 'US',
    city: 'Wildersville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Henderson',
    latitude: 35.7698,
    longitude: -88.4388,
    postal_code: '38388'
  },
  '38389': {
    country: 'US',
    city: 'Yorkville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Gibson',
    latitude: 36.1354,
    longitude: -89.1117,
    postal_code: '38389'
  },
  '38390': {
    country: 'US',
    city: 'Yuma',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Carroll',
    latitude: 35.868,
    longitude: -88.3819,
    postal_code: '38390'
  },
  '38391': {
    country: 'US',
    city: 'Denmark',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.5571,
    longitude: -88.9759,
    postal_code: '38391'
  },
  '38392': {
    country: 'US',
    city: 'Mercer',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Madison',
    latitude: 35.4816,
    longitude: -89.0271,
    postal_code: '38392'
  },
  '38393': {
    country: 'US',
    city: 'Chewalla',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'McNairy',
    latitude: 34.9969,
    longitude: -88.6456,
    postal_code: '38393'
  },
  '38401': {
    country: 'US',
    city: 'Columbia',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Maury',
    latitude: 35.6156,
    longitude: -87.038,
    postal_code: '38401'
  },
  '38402': {
    country: 'US',
    city: 'Columbia',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Maury',
    latitude: 35.6151,
    longitude: -87.0353,
    postal_code: '38402'
  },
  '38425': {
    country: 'US',
    city: 'Clifton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wayne',
    latitude: 35.3819,
    longitude: -87.95,
    postal_code: '38425'
  },
  '38449': {
    country: 'US',
    city: 'Ardmore',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Giles',
    latitude: 35.0574,
    longitude: -86.8796,
    postal_code: '38449'
  },
  '38450': {
    country: 'US',
    city: 'Collinwood',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wayne',
    latitude: 35.1635,
    longitude: -87.7185,
    postal_code: '38450'
  },
  '38451': {
    country: 'US',
    city: 'Culleoka',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Maury',
    latitude: 35.4749,
    longitude: -87.0005,
    postal_code: '38451'
  },
  '38452': {
    country: 'US',
    city: 'Cypress Inn',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wayne',
    latitude: 35.0579,
    longitude: -87.7883,
    postal_code: '38452'
  },
  '38453': {
    country: 'US',
    city: 'Dellrose',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lincoln',
    latitude: 35.1214,
    longitude: -86.8121,
    postal_code: '38453'
  },
  '38454': {
    country: 'US',
    city: 'Duck River',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hickman',
    latitude: 35.748,
    longitude: -87.3577,
    postal_code: '38454'
  },
  '38455': {
    country: 'US',
    city: 'Elkton',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Giles',
    latitude: 35.0523,
    longitude: -86.8886,
    postal_code: '38455'
  },
  '38456': {
    country: 'US',
    city: 'Ethridge',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lawrence',
    latitude: 35.3326,
    longitude: -87.3039,
    postal_code: '38456'
  },
  '38457': {
    country: 'US',
    city: 'Five Points',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lawrence',
    latitude: 35.031,
    longitude: -87.2961,
    postal_code: '38457'
  },
  '38459': {
    country: 'US',
    city: 'Frankewing',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Giles',
    latitude: 35.1834,
    longitude: -86.85,
    postal_code: '38459'
  },
  '38460': {
    country: 'US',
    city: 'Goodspring',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Giles',
    latitude: 35.1167,
    longitude: -87.1278,
    postal_code: '38460'
  },
  '38461': {
    country: 'US',
    city: 'Hampshire',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Maury',
    latitude: 35.5915,
    longitude: -87.3251,
    postal_code: '38461'
  },
  '38462': {
    country: 'US',
    city: 'Hohenwald',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lewis',
    latitude: 35.5266,
    longitude: -87.4891,
    postal_code: '38462'
  },
  '38463': {
    country: 'US',
    city: 'Iron City',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lawrence',
    latitude: 35.0563,
    longitude: -87.6473,
    postal_code: '38463'
  },
  '38464': {
    country: 'US',
    city: 'Lawrenceburg',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lawrence',
    latitude: 35.2507,
    longitude: -87.3526,
    postal_code: '38464'
  },
  '38468': {
    country: 'US',
    city: 'Leoma',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lawrence',
    latitude: 35.1382,
    longitude: -87.3168,
    postal_code: '38468'
  },
  '38469': {
    country: 'US',
    city: 'Loretto',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lawrence',
    latitude: 35.0728,
    longitude: -87.427,
    postal_code: '38469'
  },
  '38471': {
    country: 'US',
    city: 'Lutts',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wayne',
    latitude: 35.1138,
    longitude: -87.8923,
    postal_code: '38471'
  },
  '38472': {
    country: 'US',
    city: 'Lynnville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Giles',
    latitude: 35.3792,
    longitude: -87.0629,
    postal_code: '38472'
  },
  '38473': {
    country: 'US',
    city: 'Minor Hill',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Giles',
    latitude: 35.05,
    longitude: -87.1522,
    postal_code: '38473'
  },
  '38474': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Maury',
    latitude: 35.5301,
    longitude: -87.2037,
    postal_code: '38474'
  },
  '38475': {
    country: 'US',
    city: 'Olivehill',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hardin',
    latitude: 35.2667,
    longitude: -88.039,
    postal_code: '38475'
  },
  '38476': {
    country: 'US',
    city: 'Primm Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Hickman',
    latitude: 35.8104,
    longitude: -87.253,
    postal_code: '38476'
  },
  '38477': {
    country: 'US',
    city: 'Prospect',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Giles',
    latitude: 35.0666,
    longitude: -87.0174,
    postal_code: '38477'
  },
  '38478': {
    country: 'US',
    city: 'Pulaski',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Giles',
    latitude: 35.2093,
    longitude: -87.0393,
    postal_code: '38478'
  },
  '38481': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lawrence',
    latitude: 35.0376,
    longitude: -87.5018,
    postal_code: '38481'
  },
  '38482': {
    country: 'US',
    city: 'Santa Fe',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Maury',
    latitude: 35.7588,
    longitude: -87.1515,
    postal_code: '38482'
  },
  '38483': {
    country: 'US',
    city: 'Summertown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lawrence',
    latitude: 35.4307,
    longitude: -87.3198,
    postal_code: '38483'
  },
  '38485': {
    country: 'US',
    city: 'Waynesboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Wayne',
    latitude: 35.322,
    longitude: -87.7395,
    postal_code: '38485'
  },
  '38486': {
    country: 'US',
    city: 'Westpoint',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lawrence',
    latitude: 35.1393,
    longitude: -87.538,
    postal_code: '38486'
  },
  '38487': {
    country: 'US',
    city: 'Williamsport',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Maury',
    latitude: 35.6494,
    longitude: -87.2257,
    postal_code: '38487'
  },
  '38488': {
    country: 'US',
    city: 'Taft',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Lincoln',
    latitude: 35.0517,
    longitude: -86.6447,
    postal_code: '38488'
  },
  '38501': {
    country: 'US',
    city: 'Cookeville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Putnam',
    latitude: 36.2178,
    longitude: -85.5423,
    postal_code: '38501'
  },
  '38502': {
    country: 'US',
    city: 'Cookeville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Putnam',
    latitude: 36.1628,
    longitude: -85.5016,
    postal_code: '38502'
  },
  '38503': {
    country: 'US',
    city: 'Cookeville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Putnam',
    latitude: 36.1628,
    longitude: -85.5016,
    postal_code: '38503'
  },
  '38504': {
    country: 'US',
    city: 'Allardt',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fentress',
    latitude: 36.3818,
    longitude: -84.7756,
    postal_code: '38504'
  },
  '38505': {
    country: 'US',
    city: 'Cookeville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Putnam',
    latitude: 36.1628,
    longitude: -85.5016,
    postal_code: '38505'
  },
  '38506': {
    country: 'US',
    city: 'Cookeville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Putnam',
    latitude: 36.1819,
    longitude: -85.4408,
    postal_code: '38506'
  },
  '38541': {
    country: 'US',
    city: 'Allons',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Overton',
    latitude: 36.497,
    longitude: -85.3197,
    postal_code: '38541'
  },
  '38542': {
    country: 'US',
    city: 'Allred',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Overton',
    latitude: 36.324,
    longitude: -85.2031,
    postal_code: '38542'
  },
  '38543': {
    country: 'US',
    city: 'Alpine',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Overton',
    latitude: 36.3803,
    longitude: -85.1522,
    postal_code: '38543'
  },
  '38544': {
    country: 'US',
    city: 'Baxter',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Putnam',
    latitude: 36.1249,
    longitude: -85.6378,
    postal_code: '38544'
  },
  '38545': {
    country: 'US',
    city: 'Bloomington Springs',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Putnam',
    latitude: 36.2321,
    longitude: -85.6628,
    postal_code: '38545'
  },
  '38547': {
    country: 'US',
    city: 'Brush Creek',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Smith',
    latitude: 36.1107,
    longitude: -86.0203,
    postal_code: '38547'
  },
  '38548': {
    country: 'US',
    city: 'Buffalo Valley',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Putnam',
    latitude: 36.1833,
    longitude: -85.7589,
    postal_code: '38548'
  },
  '38549': {
    country: 'US',
    city: 'Byrdstown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Pickett',
    latitude: 36.5709,
    longitude: -85.1456,
    postal_code: '38549'
  },
  '38550': {
    country: 'US',
    city: 'Campaign',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Warren',
    latitude: 35.7734,
    longitude: -85.6192,
    postal_code: '38550'
  },
  '38551': {
    country: 'US',
    city: 'Celina',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Clay',
    latitude: 36.5475,
    longitude: -85.4966,
    postal_code: '38551'
  },
  '38552': {
    country: 'US',
    city: 'Chestnut Mound',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Smith',
    latitude: 36.2028,
    longitude: -85.8264,
    postal_code: '38552'
  },
  '38553': {
    country: 'US',
    city: 'Clarkrange',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fentress',
    latitude: 36.2112,
    longitude: -84.9777,
    postal_code: '38553'
  },
  '38554': {
    country: 'US',
    city: 'Crawford',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Overton',
    latitude: 36.2361,
    longitude: -85.1688,
    postal_code: '38554'
  },
  '38555': {
    country: 'US',
    city: 'Crossville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cumberland',
    latitude: 35.9623,
    longitude: -85.0514,
    postal_code: '38555'
  },
  '38556': {
    country: 'US',
    city: 'Jamestown',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fentress',
    latitude: 36.4245,
    longitude: -84.9357,
    postal_code: '38556'
  },
  '38557': {
    country: 'US',
    city: 'Crossville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cumberland',
    latitude: 35.9885,
    longitude: -85.0124,
    postal_code: '38557'
  },
  '38558': {
    country: 'US',
    city: 'Crossville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cumberland',
    latitude: 36.0077,
    longitude: -84.8719,
    postal_code: '38558'
  },
  '38559': {
    country: 'US',
    city: 'Doyle',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'White',
    latitude: 35.8356,
    longitude: -85.5191,
    postal_code: '38559'
  },
  '38560': {
    country: 'US',
    city: 'Elmwood',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Smith',
    latitude: 36.2355,
    longitude: -85.8808,
    postal_code: '38560'
  },
  '38562': {
    country: 'US',
    city: 'Gainesboro',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Jackson',
    latitude: 36.3438,
    longitude: -85.6355,
    postal_code: '38562'
  },
  '38563': {
    country: 'US',
    city: 'Gordonsville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Smith',
    latitude: 36.1843,
    longitude: -86.0008,
    postal_code: '38563'
  },
  '38564': {
    country: 'US',
    city: 'Granville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Jackson',
    latitude: 36.2768,
    longitude: -85.7475,
    postal_code: '38564'
  },
  '38565': {
    country: 'US',
    city: 'Grimsley',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fentress',
    latitude: 36.2598,
    longitude: -85.0015,
    postal_code: '38565'
  },
  '38567': {
    country: 'US',
    city: 'Hickman',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Smith',
    latitude: 36.1197,
    longitude: -85.9023,
    postal_code: '38567'
  },
  '38568': {
    country: 'US',
    city: 'Hilham',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Overton',
    latitude: 36.4227,
    longitude: -85.4464,
    postal_code: '38568'
  },
  '38569': {
    country: 'US',
    city: 'Lancaster',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Smith',
    latitude: 36.0954,
    longitude: -85.8551,
    postal_code: '38569'
  },
  '38570': {
    country: 'US',
    city: 'Livingston',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Overton',
    latitude: 36.389,
    longitude: -85.3205,
    postal_code: '38570'
  },
  '38571': {
    country: 'US',
    city: 'Crossville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cumberland',
    latitude: 36.0527,
    longitude: -85.0138,
    postal_code: '38571'
  },
  '38572': {
    country: 'US',
    city: 'Crossville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cumberland',
    latitude: 35.8487,
    longitude: -85.1308,
    postal_code: '38572'
  },
  '38573': {
    country: 'US',
    city: 'Monroe',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Overton',
    latitude: 36.4642,
    longitude: -85.2164,
    postal_code: '38573'
  },
  '38574': {
    country: 'US',
    city: 'Monterey',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Putnam',
    latitude: 36.1509,
    longitude: -85.2542,
    postal_code: '38574'
  },
  '38575': {
    country: 'US',
    city: 'Moss',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Clay',
    latitude: 36.5966,
    longitude: -85.6772,
    postal_code: '38575'
  },
  '38577': {
    country: 'US',
    city: 'Pall Mall',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fentress',
    latitude: 36.5781,
    longitude: -85.0384,
    postal_code: '38577'
  },
  '38578': {
    country: 'US',
    city: 'Pleasant Hill',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Cumberland',
    latitude: 35.9813,
    longitude: -85.1984,
    postal_code: '38578'
  },
  '38579': {
    country: 'US',
    city: 'Quebeck',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'White',
    latitude: 35.8303,
    longitude: -85.552,
    postal_code: '38579'
  },
  '38580': {
    country: 'US',
    city: 'Rickman',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Overton',
    latitude: 36.2812,
    longitude: -85.3048,
    postal_code: '38580'
  },
  '38581': {
    country: 'US',
    city: 'Rock Island',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Warren',
    latitude: 35.7668,
    longitude: -85.6788,
    postal_code: '38581'
  },
  '38582': {
    country: 'US',
    city: 'Silver Point',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Putnam',
    latitude: 36.1006,
    longitude: -85.7338,
    postal_code: '38582'
  },
  '38583': {
    country: 'US',
    city: 'Sparta',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'White',
    latitude: 35.9439,
    longitude: -85.4392,
    postal_code: '38583'
  },
  '38585': {
    country: 'US',
    city: 'Spencer',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Van Buren',
    latitude: 35.7279,
    longitude: -85.4287,
    postal_code: '38585'
  },
  '38587': {
    country: 'US',
    city: 'Walling',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'White',
    latitude: 35.8695,
    longitude: -85.6185,
    postal_code: '38587'
  },
  '38588': {
    country: 'US',
    city: 'Whitleyville',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Jackson',
    latitude: 36.5158,
    longitude: -85.6899,
    postal_code: '38588'
  },
  '38589': {
    country: 'US',
    city: 'Wilder',
    state: 'Tennessee',
    state_short: 'TN',
    county: 'Fentress',
    latitude: 36.2822,
    longitude: -85.0913,
    postal_code: '38589'
  },
  '38601': {
    country: 'US',
    city: 'Abbeville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lafayette',
    latitude: 34.4719,
    longitude: -89.4475,
    postal_code: '38601'
  },
  '38602': {
    country: 'US',
    city: 'Arkabutla',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tate',
    latitude: 34.6863,
    longitude: -90.1068,
    postal_code: '38602'
  },
  '38603': {
    country: 'US',
    city: 'Ashland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Benton',
    latitude: 34.8346,
    longitude: -89.1427,
    postal_code: '38603'
  },
  '38606': {
    country: 'US',
    city: 'Batesville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Panola',
    latitude: 34.3115,
    longitude: -89.9443,
    postal_code: '38606'
  },
  '38609': {
    country: 'US',
    city: 'Belen',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Quitman',
    latitude: 34.2351,
    longitude: -90.2927,
    postal_code: '38609'
  },
  '38610': {
    country: 'US',
    city: 'Blue Mountain',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tippah',
    latitude: 34.671,
    longitude: -89.0088,
    postal_code: '38610'
  },
  '38611': {
    country: 'US',
    city: 'Byhalia',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marshall',
    latitude: 34.8854,
    longitude: -89.6763,
    postal_code: '38611'
  },
  '38614': {
    country: 'US',
    city: 'Clarksdale',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Coahoma',
    latitude: 34.256,
    longitude: -90.6348,
    postal_code: '38614'
  },
  '38617': {
    country: 'US',
    city: 'Coahoma',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Coahoma',
    latitude: 34.3668,
    longitude: -90.5232,
    postal_code: '38617'
  },
  '38618': {
    country: 'US',
    city: 'Coldwater',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tate',
    latitude: 34.6924,
    longitude: -89.9869,
    postal_code: '38618'
  },
  '38619': {
    country: 'US',
    city: 'Como',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Panola',
    latitude: 34.5137,
    longitude: -89.9155,
    postal_code: '38619'
  },
  '38620': {
    country: 'US',
    city: 'Courtland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Panola',
    latitude: 34.2596,
    longitude: -89.9396,
    postal_code: '38620'
  },
  '38621': {
    country: 'US',
    city: 'Crenshaw',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Panola',
    latitude: 34.4491,
    longitude: -90.1701,
    postal_code: '38621'
  },
  '38622': {
    country: 'US',
    city: 'Crowder',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Quitman',
    latitude: 34.1731,
    longitude: -90.1364,
    postal_code: '38622'
  },
  '38623': {
    country: 'US',
    city: 'Darling',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Quitman',
    latitude: 34.359,
    longitude: -90.2743,
    postal_code: '38623'
  },
  '38625': {
    country: 'US',
    city: 'Dumas',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tippah',
    latitude: 34.6491,
    longitude: -88.8072,
    postal_code: '38625'
  },
  '38626': {
    country: 'US',
    city: 'Dundee',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tunica',
    latitude: 34.5357,
    longitude: -90.3693,
    postal_code: '38626'
  },
  '38627': {
    country: 'US',
    city: 'Etta',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Union',
    latitude: 34.4352,
    longitude: -89.1767,
    postal_code: '38627'
  },
  '38628': {
    country: 'US',
    city: 'Falcon',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Quitman',
    latitude: 34.3932,
    longitude: -90.2565,
    postal_code: '38628'
  },
  '38629': {
    country: 'US',
    city: 'Falkner',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tippah',
    latitude: 34.8417,
    longitude: -88.9525,
    postal_code: '38629'
  },
  '38630': {
    country: 'US',
    city: 'Farrell',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Coahoma',
    latitude: 34.2643,
    longitude: -90.6723,
    postal_code: '38630'
  },
  '38631': {
    country: 'US',
    city: 'Friars Point',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Coahoma',
    latitude: 34.3668,
    longitude: -90.6376,
    postal_code: '38631'
  },
  '38632': {
    country: 'US',
    city: 'Hernando',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'DeSoto',
    latitude: 34.8096,
    longitude: -90.0095,
    postal_code: '38632'
  },
  '38633': {
    country: 'US',
    city: 'Hickory Flat',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Benton',
    latitude: 34.6244,
    longitude: -89.1862,
    postal_code: '38633'
  },
  '38634': {
    country: 'US',
    city: 'Holly Springs',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marshall',
    latitude: 34.7676,
    longitude: -89.4487,
    postal_code: '38634'
  },
  '38635': {
    country: 'US',
    city: 'Holly Springs',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marshall',
    latitude: 34.7471,
    longitude: -89.4897,
    postal_code: '38635'
  },
  '38637': {
    country: 'US',
    city: 'Horn Lake',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'DeSoto',
    latitude: 34.9519,
    longitude: -90.0507,
    postal_code: '38637'
  },
  '38638': {
    country: 'US',
    city: 'Independence',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tate',
    latitude: 34.6959,
    longitude: -89.8288,
    postal_code: '38638'
  },
  '38639': {
    country: 'US',
    city: 'Jonestown',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Coahoma',
    latitude: 34.3037,
    longitude: -90.4294,
    postal_code: '38639'
  },
  '38641': {
    country: 'US',
    city: 'Lake Cormorant',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'DeSoto',
    latitude: 34.8937,
    longitude: -90.1608,
    postal_code: '38641'
  },
  '38642': {
    country: 'US',
    city: 'Lamar',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marshall',
    latitude: 34.9271,
    longitude: -89.3163,
    postal_code: '38642'
  },
  '38643': {
    country: 'US',
    city: 'Lambert',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Quitman',
    latitude: 34.1837,
    longitude: -90.263,
    postal_code: '38643'
  },
  '38644': {
    country: 'US',
    city: 'Lula',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Coahoma',
    latitude: 34.4618,
    longitude: -90.4981,
    postal_code: '38644'
  },
  '38645': {
    country: 'US',
    city: 'Lyon',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Coahoma',
    latitude: 34.2474,
    longitude: -90.4981,
    postal_code: '38645'
  },
  '38646': {
    country: 'US',
    city: 'Marks',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Quitman',
    latitude: 34.2607,
    longitude: -90.2816,
    postal_code: '38646'
  },
  '38647': {
    country: 'US',
    city: 'Michigan City',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Benton',
    latitude: 34.9608,
    longitude: -89.2267,
    postal_code: '38647'
  },
  '38649': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marshall',
    latitude: 34.9536,
    longitude: -89.523,
    postal_code: '38649'
  },
  '38650': {
    country: 'US',
    city: 'Myrtle',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Union',
    latitude: 34.5402,
    longitude: -89.1157,
    postal_code: '38650'
  },
  '38651': {
    country: 'US',
    city: 'Nesbit',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'DeSoto',
    latitude: 34.8992,
    longitude: -90.0122,
    postal_code: '38651'
  },
  '38652': {
    country: 'US',
    city: 'New Albany',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Union',
    latitude: 34.4851,
    longitude: -89.0031,
    postal_code: '38652'
  },
  '38654': {
    country: 'US',
    city: 'Olive Branch',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'DeSoto',
    latitude: 34.9441,
    longitude: -89.8544,
    postal_code: '38654'
  },
  '38655': {
    country: 'US',
    city: 'Oxford',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lafayette',
    latitude: 34.3308,
    longitude: -89.4835,
    postal_code: '38655'
  },
  '38658': {
    country: 'US',
    city: 'Pope',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Panola',
    latitude: 34.1906,
    longitude: -90.0027,
    postal_code: '38658'
  },
  '38659': {
    country: 'US',
    city: 'Potts Camp',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marshall',
    latitude: 34.6047,
    longitude: -89.3151,
    postal_code: '38659'
  },
  '38661': {
    country: 'US',
    city: 'Red Banks',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marshall',
    latitude: 34.8937,
    longitude: -89.5649,
    postal_code: '38661'
  },
  '38663': {
    country: 'US',
    city: 'Ripley',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tippah',
    latitude: 34.7509,
    longitude: -88.924,
    postal_code: '38663'
  },
  '38664': {
    country: 'US',
    city: 'Robinsonville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tunica',
    latitude: 34.8093,
    longitude: -90.3052,
    postal_code: '38664'
  },
  '38665': {
    country: 'US',
    city: 'Sarah',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Panola',
    latitude: 34.591,
    longitude: -90.162,
    postal_code: '38665'
  },
  '38666': {
    country: 'US',
    city: 'Sardis',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Panola',
    latitude: 34.4276,
    longitude: -89.9221,
    postal_code: '38666'
  },
  '38668': {
    country: 'US',
    city: 'Senatobia',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tate',
    latitude: 34.6323,
    longitude: -89.8855,
    postal_code: '38668'
  },
  '38669': {
    country: 'US',
    city: 'Sherard',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Coahoma',
    latitude: 34.1953,
    longitude: -90.7335,
    postal_code: '38669'
  },
  '38670': {
    country: 'US',
    city: 'Sledge',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tunica',
    latitude: 34.3823,
    longitude: -90.209,
    postal_code: '38670'
  },
  '38671': {
    country: 'US',
    city: 'Southaven',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'DeSoto',
    latitude: 34.9771,
    longitude: -89.9992,
    postal_code: '38671'
  },
  '38672': {
    country: 'US',
    city: 'Southaven',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'DeSoto',
    latitude: 34.9474,
    longitude: -89.9258,
    postal_code: '38672'
  },
  '38673': {
    country: 'US',
    city: 'Taylor',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lafayette',
    latitude: 34.2849,
    longitude: -89.6273,
    postal_code: '38673'
  },
  '38674': {
    country: 'US',
    city: 'Tiplersville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tippah',
    latitude: 34.9029,
    longitude: -88.9157,
    postal_code: '38674'
  },
  '38675': {
    country: 'US',
    city: 'Tula',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lafayette',
    latitude: 34.3582,
    longitude: -89.4839,
    postal_code: '38675'
  },
  '38676': {
    country: 'US',
    city: 'Tunica',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tunica',
    latitude: 34.6884,
    longitude: -90.3685,
    postal_code: '38676'
  },
  '38677': {
    country: 'US',
    city: 'University',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lafayette',
    latitude: 34.3659,
    longitude: -89.5254,
    postal_code: '38677'
  },
  '38679': {
    country: 'US',
    city: 'Victoria',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marshall',
    latitude: 34.8708,
    longitude: -89.6408,
    postal_code: '38679'
  },
  '38680': {
    country: 'US',
    city: 'Walls',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'DeSoto',
    latitude: 34.9641,
    longitude: -90.1208,
    postal_code: '38680'
  },
  '38683': {
    country: 'US',
    city: 'Walnut',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tippah',
    latitude: 34.9527,
    longitude: -88.9053,
    postal_code: '38683'
  },
  '38685': {
    country: 'US',
    city: 'Waterford',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lafayette',
    latitude: 34.5476,
    longitude: -89.6129,
    postal_code: '38685'
  },
  '38686': {
    country: 'US',
    city: 'Walls',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'DeSoto',
    latitude: 34.9582,
    longitude: -90.1526,
    postal_code: '38686'
  },
  '38701': {
    country: 'US',
    city: 'Greenville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.3787,
    longitude: -91.0468,
    postal_code: '38701'
  },
  '38702': {
    country: 'US',
    city: 'Greenville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.4258,
    longitude: -90.9946,
    postal_code: '38702'
  },
  '38703': {
    country: 'US',
    city: 'Greenville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.4085,
    longitude: -91.0228,
    postal_code: '38703'
  },
  '38704': {
    country: 'US',
    city: 'Greenville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.2534,
    longitude: -90.9185,
    postal_code: '38704'
  },
  '38720': {
    country: 'US',
    city: 'Alligator',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 34.1294,
    longitude: -90.7382,
    postal_code: '38720'
  },
  '38721': {
    country: 'US',
    city: 'Anguilla',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sharkey',
    latitude: 32.9739,
    longitude: -90.8316,
    postal_code: '38721'
  },
  '38722': {
    country: 'US',
    city: 'Arcola',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.2698,
    longitude: -90.8798,
    postal_code: '38722'
  },
  '38723': {
    country: 'US',
    city: 'Avon',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.2301,
    longitude: -91.0473,
    postal_code: '38723'
  },
  '38725': {
    country: 'US',
    city: 'Benoit',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.6448,
    longitude: -91.0338,
    postal_code: '38725'
  },
  '38726': {
    country: 'US',
    city: 'Beulah',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.788,
    longitude: -90.9795,
    postal_code: '38726'
  },
  '38730': {
    country: 'US',
    city: 'Boyle',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.6891,
    longitude: -90.7838,
    postal_code: '38730'
  },
  '38731': {
    country: 'US',
    city: 'Chatham',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.1007,
    longitude: -91.0979,
    postal_code: '38731'
  },
  '38732': {
    country: 'US',
    city: 'Cleveland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.743,
    longitude: -90.7309,
    postal_code: '38732'
  },
  '38733': {
    country: 'US',
    city: 'Cleveland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.624,
    longitude: -90.8195,
    postal_code: '38733'
  },
  '38736': {
    country: 'US',
    city: 'Doddsville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sunflower',
    latitude: 33.63,
    longitude: -90.5264,
    postal_code: '38736'
  },
  '38737': {
    country: 'US',
    city: 'Drew',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sunflower',
    latitude: 33.867,
    longitude: -90.5406,
    postal_code: '38737'
  },
  '38738': {
    country: 'US',
    city: 'Parchman',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sunflower',
    latitude: 33.9179,
    longitude: -90.4965,
    postal_code: '38738'
  },
  '38739': {
    country: 'US',
    city: 'Dublin',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Coahoma',
    latitude: 34.0604,
    longitude: -90.5011,
    postal_code: '38739'
  },
  '38740': {
    country: 'US',
    city: 'Duncan',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 34.047,
    longitude: -90.7652,
    postal_code: '38740'
  },
  '38744': {
    country: 'US',
    city: 'Glen Allan',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.0254,
    longitude: -91.0092,
    postal_code: '38744'
  },
  '38745': {
    country: 'US',
    city: 'Grace',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Issaquena',
    latitude: 32.9775,
    longitude: -90.983,
    postal_code: '38745'
  },
  '38746': {
    country: 'US',
    city: 'Gunnison',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.9439,
    longitude: -90.9319,
    postal_code: '38746'
  },
  '38748': {
    country: 'US',
    city: 'Hollandale',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.149,
    longitude: -90.9041,
    postal_code: '38748'
  },
  '38749': {
    country: 'US',
    city: 'Indianola',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sunflower',
    latitude: 33.451,
    longitude: -90.6551,
    postal_code: '38749'
  },
  '38751': {
    country: 'US',
    city: 'Indianola',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sunflower',
    latitude: 33.451,
    longitude: -90.6551,
    postal_code: '38751'
  },
  '38753': {
    country: 'US',
    city: 'Inverness',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sunflower',
    latitude: 33.349,
    longitude: -90.6051,
    postal_code: '38753'
  },
  '38754': {
    country: 'US',
    city: 'Isola',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Humphreys',
    latitude: 33.2471,
    longitude: -90.604,
    postal_code: '38754'
  },
  '38756': {
    country: 'US',
    city: 'Leland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.3985,
    longitude: -90.8839,
    postal_code: '38756'
  },
  '38759': {
    country: 'US',
    city: 'Merigold',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.8373,
    longitude: -90.7256,
    postal_code: '38759'
  },
  '38760': {
    country: 'US',
    city: 'Metcalfe',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.4546,
    longitude: -90.9943,
    postal_code: '38760'
  },
  '38761': {
    country: 'US',
    city: 'Moorhead',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sunflower',
    latitude: 33.4783,
    longitude: -90.5143,
    postal_code: '38761'
  },
  '38762': {
    country: 'US',
    city: 'Mound Bayou',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.886,
    longitude: -90.7302,
    postal_code: '38762'
  },
  '38764': {
    country: 'US',
    city: 'Pace',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.7915,
    longitude: -90.857,
    postal_code: '38764'
  },
  '38765': {
    country: 'US',
    city: 'Panther Burn',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sharkey',
    latitude: 33.0662,
    longitude: -90.8684,
    postal_code: '38765'
  },
  '38767': {
    country: 'US',
    city: 'Rena Lara',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Coahoma',
    latitude: 34.1412,
    longitude: -90.7865,
    postal_code: '38767'
  },
  '38768': {
    country: 'US',
    city: 'Rome',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sunflower',
    latitude: 33.9632,
    longitude: -90.4784,
    postal_code: '38768'
  },
  '38769': {
    country: 'US',
    city: 'Rosedale',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.8432,
    longitude: -90.9923,
    postal_code: '38769'
  },
  '38771': {
    country: 'US',
    city: 'Ruleville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sunflower',
    latitude: 33.7241,
    longitude: -90.5527,
    postal_code: '38771'
  },
  '38772': {
    country: 'US',
    city: 'Scott',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.5629,
    longitude: -91.0391,
    postal_code: '38772'
  },
  '38773': {
    country: 'US',
    city: 'Shaw',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.6021,
    longitude: -90.7746,
    postal_code: '38773'
  },
  '38774': {
    country: 'US',
    city: 'Shelby',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.9491,
    longitude: -90.7625,
    postal_code: '38774'
  },
  '38776': {
    country: 'US',
    city: 'Stoneville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.4096,
    longitude: -90.9104,
    postal_code: '38776'
  },
  '38778': {
    country: 'US',
    city: 'Sunflower',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sunflower',
    latitude: 33.5567,
    longitude: -90.5451,
    postal_code: '38778'
  },
  '38780': {
    country: 'US',
    city: 'Wayside',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.269,
    longitude: -91.0337,
    postal_code: '38780'
  },
  '38781': {
    country: 'US',
    city: 'Winstonville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Bolivar',
    latitude: 33.9101,
    longitude: -90.7515,
    postal_code: '38781'
  },
  '38782': {
    country: 'US',
    city: 'Winterville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Washington',
    latitude: 33.5018,
    longitude: -91.0584,
    postal_code: '38782'
  },
  '38801': {
    country: 'US',
    city: 'Tupelo',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.2538,
    longitude: -88.7209,
    postal_code: '38801'
  },
  '38802': {
    country: 'US',
    city: 'Tupelo',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.2345,
    longitude: -88.7671,
    postal_code: '38802'
  },
  '38803': {
    country: 'US',
    city: 'Tupelo',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.1876,
    longitude: -88.7785,
    postal_code: '38803'
  },
  '38804': {
    country: 'US',
    city: 'Tupelo',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.2823,
    longitude: -88.6715,
    postal_code: '38804'
  },
  '38820': {
    country: 'US',
    city: 'Algoma',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pontotoc',
    latitude: 34.1715,
    longitude: -89.0328,
    postal_code: '38820'
  },
  '38821': {
    country: 'US',
    city: 'Amory',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Monroe',
    latitude: 33.9844,
    longitude: -88.4709,
    postal_code: '38821'
  },
  '38824': {
    country: 'US',
    city: 'Baldwyn',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.528,
    longitude: -88.6375,
    postal_code: '38824'
  },
  '38825': {
    country: 'US',
    city: 'Becker',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Monroe',
    latitude: 33.8698,
    longitude: -88.4622,
    postal_code: '38825'
  },
  '38826': {
    country: 'US',
    city: 'Belden',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.3057,
    longitude: -88.8462,
    postal_code: '38826'
  },
  '38827': {
    country: 'US',
    city: 'Belmont',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tishomingo',
    latitude: 34.5102,
    longitude: -88.2309,
    postal_code: '38827'
  },
  '38828': {
    country: 'US',
    city: 'Blue Springs',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Union',
    latitude: 34.4273,
    longitude: -88.859,
    postal_code: '38828'
  },
  '38829': {
    country: 'US',
    city: 'Booneville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Prentiss',
    latitude: 34.6694,
    longitude: -88.5443,
    postal_code: '38829'
  },
  '38833': {
    country: 'US',
    city: 'Burnsville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tishomingo',
    latitude: 34.8579,
    longitude: -88.307,
    postal_code: '38833'
  },
  '38834': {
    country: 'US',
    city: 'Corinth',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Alcorn',
    latitude: 34.8759,
    longitude: -88.5916,
    postal_code: '38834'
  },
  '38835': {
    country: 'US',
    city: 'Corinth',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Alcorn',
    latitude: 34.9205,
    longitude: -88.5218,
    postal_code: '38835'
  },
  '38838': {
    country: 'US',
    city: 'Dennis',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tishomingo',
    latitude: 34.5497,
    longitude: -88.2117,
    postal_code: '38838'
  },
  '38839': {
    country: 'US',
    city: 'Derma',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Calhoun',
    latitude: 33.8562,
    longitude: -89.3032,
    postal_code: '38839'
  },
  '38841': {
    country: 'US',
    city: 'Ecru',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pontotoc',
    latitude: 34.3366,
    longitude: -89.0109,
    postal_code: '38841'
  },
  '38843': {
    country: 'US',
    city: 'Fulton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Itawamba',
    latitude: 34.2745,
    longitude: -88.3793,
    postal_code: '38843'
  },
  '38844': {
    country: 'US',
    city: 'Gattman',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Monroe',
    latitude: 33.8742,
    longitude: -88.2588,
    postal_code: '38844'
  },
  '38846': {
    country: 'US',
    city: 'Glen',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Alcorn',
    latitude: 34.878,
    longitude: -88.4359,
    postal_code: '38846'
  },
  '38847': {
    country: 'US',
    city: 'Golden',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Itawamba',
    latitude: 34.4812,
    longitude: -88.1842,
    postal_code: '38847'
  },
  '38848': {
    country: 'US',
    city: 'Greenwood Springs',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Monroe',
    latitude: 33.8871,
    longitude: -88.3089,
    postal_code: '38848'
  },
  '38849': {
    country: 'US',
    city: 'Guntown',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.4563,
    longitude: -88.6975,
    postal_code: '38849'
  },
  '38850': {
    country: 'US',
    city: 'Houlka',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Chickasaw',
    latitude: 34.0561,
    longitude: -89.0531,
    postal_code: '38850'
  },
  '38851': {
    country: 'US',
    city: 'Houston',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Chickasaw',
    latitude: 33.9026,
    longitude: -88.967,
    postal_code: '38851'
  },
  '38852': {
    country: 'US',
    city: 'Iuka',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tishomingo',
    latitude: 34.8089,
    longitude: -88.1983,
    postal_code: '38852'
  },
  '38855': {
    country: 'US',
    city: 'Mantachie',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Itawamba',
    latitude: 34.3243,
    longitude: -88.4912,
    postal_code: '38855'
  },
  '38856': {
    country: 'US',
    city: 'Marietta',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Prentiss',
    latitude: 34.5011,
    longitude: -88.4498,
    postal_code: '38856'
  },
  '38857': {
    country: 'US',
    city: 'Mooreville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.2808,
    longitude: -88.595,
    postal_code: '38857'
  },
  '38858': {
    country: 'US',
    city: 'Nettleton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Itawamba',
    latitude: 34.0821,
    longitude: -88.6052,
    postal_code: '38858'
  },
  '38859': {
    country: 'US',
    city: 'New Site',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Prentiss',
    latitude: 34.5315,
    longitude: -88.3875,
    postal_code: '38859'
  },
  '38860': {
    country: 'US',
    city: 'Okolona',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Chickasaw',
    latitude: 33.9694,
    longitude: -88.7638,
    postal_code: '38860'
  },
  '38862': {
    country: 'US',
    city: 'Plantersville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.2011,
    longitude: -88.6335,
    postal_code: '38862'
  },
  '38863': {
    country: 'US',
    city: 'Pontotoc',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pontotoc',
    latitude: 34.217,
    longitude: -88.9868,
    postal_code: '38863'
  },
  '38864': {
    country: 'US',
    city: 'Randolph',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pontotoc',
    latitude: 34.1617,
    longitude: -89.1795,
    postal_code: '38864'
  },
  '38865': {
    country: 'US',
    city: 'Rienzi',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Alcorn',
    latitude: 34.7968,
    longitude: -88.5795,
    postal_code: '38865'
  },
  '38866': {
    country: 'US',
    city: 'Saltillo',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.3831,
    longitude: -88.6674,
    postal_code: '38866'
  },
  '38868': {
    country: 'US',
    city: 'Shannon',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.121,
    longitude: -88.7342,
    postal_code: '38868'
  },
  '38869': {
    country: 'US',
    city: 'Sherman',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pontotoc',
    latitude: 34.3575,
    longitude: -88.8351,
    postal_code: '38869'
  },
  '38870': {
    country: 'US',
    city: 'Smithville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Monroe',
    latitude: 34.0648,
    longitude: -88.39,
    postal_code: '38870'
  },
  '38871': {
    country: 'US',
    city: 'Thaxton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pontotoc',
    latitude: 34.3136,
    longitude: -89.1515,
    postal_code: '38871'
  },
  '38873': {
    country: 'US',
    city: 'Tishomingo',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tishomingo',
    latitude: 34.6478,
    longitude: -88.2194,
    postal_code: '38873'
  },
  '38874': {
    country: 'US',
    city: 'Toccopola',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lafayette',
    latitude: 34.2265,
    longitude: -89.2519,
    postal_code: '38874'
  },
  '38875': {
    country: 'US',
    city: 'Trebloc',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Chickasaw',
    latitude: 33.9068,
    longitude: -88.9554,
    postal_code: '38875'
  },
  '38876': {
    country: 'US',
    city: 'Tremont',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Itawamba',
    latitude: 34.2335,
    longitude: -88.2392,
    postal_code: '38876'
  },
  '38877': {
    country: 'US',
    city: 'Van Vleet',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Chickasaw',
    latitude: 33.9871,
    longitude: -88.8988,
    postal_code: '38877'
  },
  '38878': {
    country: 'US',
    city: 'Vardaman',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Calhoun',
    latitude: 33.919,
    longitude: -89.1921,
    postal_code: '38878'
  },
  '38879': {
    country: 'US',
    city: 'Verona',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lee',
    latitude: 34.1943,
    longitude: -88.7198,
    postal_code: '38879'
  },
  '38880': {
    country: 'US',
    city: 'Wheeler',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Prentiss',
    latitude: 34.6126,
    longitude: -88.5822,
    postal_code: '38880'
  },
  '38901': {
    country: 'US',
    city: 'Grenada',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Grenada',
    latitude: 33.7751,
    longitude: -89.8087,
    postal_code: '38901'
  },
  '38902': {
    country: 'US',
    city: 'Grenada',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Grenada',
    latitude: 33.769,
    longitude: -89.8084,
    postal_code: '38902'
  },
  '38913': {
    country: 'US',
    city: 'Banner',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Calhoun',
    latitude: 34.0949,
    longitude: -89.4154,
    postal_code: '38913'
  },
  '38914': {
    country: 'US',
    city: 'Big Creek',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Calhoun',
    latitude: 33.8514,
    longitude: -89.4378,
    postal_code: '38914'
  },
  '38915': {
    country: 'US',
    city: 'Bruce',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Calhoun',
    latitude: 34.0066,
    longitude: -89.3484,
    postal_code: '38915'
  },
  '38916': {
    country: 'US',
    city: 'Calhoun City',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Calhoun',
    latitude: 33.8408,
    longitude: -89.3178,
    postal_code: '38916'
  },
  '38917': {
    country: 'US',
    city: 'Carrollton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Carroll',
    latitude: 33.5208,
    longitude: -89.9506,
    postal_code: '38917'
  },
  '38920': {
    country: 'US',
    city: 'Cascilla',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tallahatchie',
    latitude: 33.9079,
    longitude: -90.0362,
    postal_code: '38920'
  },
  '38921': {
    country: 'US',
    city: 'Charleston',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tallahatchie',
    latitude: 33.9726,
    longitude: -90.1116,
    postal_code: '38921'
  },
  '38922': {
    country: 'US',
    city: 'Coffeeville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yalobusha',
    latitude: 33.9215,
    longitude: -89.6782,
    postal_code: '38922'
  },
  '38923': {
    country: 'US',
    city: 'Coila',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Carroll',
    latitude: 33.3731,
    longitude: -89.9915,
    postal_code: '38923'
  },
  '38924': {
    country: 'US',
    city: 'Cruger',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Holmes',
    latitude: 33.311,
    longitude: -90.2316,
    postal_code: '38924'
  },
  '38925': {
    country: 'US',
    city: 'Duck Hill',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Montgomery',
    latitude: 33.5632,
    longitude: -89.6419,
    postal_code: '38925'
  },
  '38926': {
    country: 'US',
    city: 'Elliott',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Grenada',
    latitude: 33.6895,
    longitude: -89.7583,
    postal_code: '38926'
  },
  '38927': {
    country: 'US',
    city: 'Enid',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tallahatchie',
    latitude: 34.1263,
    longitude: -90.0044,
    postal_code: '38927'
  },
  '38928': {
    country: 'US',
    city: 'Glendora',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tallahatchie',
    latitude: 33.8573,
    longitude: -90.288,
    postal_code: '38928'
  },
  '38929': {
    country: 'US',
    city: 'Gore Springs',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Grenada',
    latitude: 33.7245,
    longitude: -89.5754,
    postal_code: '38929'
  },
  '38930': {
    country: 'US',
    city: 'Greenwood',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leflore',
    latitude: 33.5159,
    longitude: -90.1726,
    postal_code: '38930'
  },
  '38935': {
    country: 'US',
    city: 'Greenwood',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leflore',
    latitude: 33.5162,
    longitude: -90.1795,
    postal_code: '38935'
  },
  '38940': {
    country: 'US',
    city: 'Holcomb',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Grenada',
    latitude: 33.7551,
    longitude: -90.0333,
    postal_code: '38940'
  },
  '38941': {
    country: 'US',
    city: 'Itta Bena',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leflore',
    latitude: 33.4798,
    longitude: -90.3394,
    postal_code: '38941'
  },
  '38943': {
    country: 'US',
    city: 'Mc Carley',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Carroll',
    latitude: 33.5864,
    longitude: -89.8513,
    postal_code: '38943'
  },
  '38944': {
    country: 'US',
    city: 'Minter City',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leflore',
    latitude: 33.7515,
    longitude: -90.3133,
    postal_code: '38944'
  },
  '38945': {
    country: 'US',
    city: 'Money',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leflore',
    latitude: 33.6507,
    longitude: -90.1969,
    postal_code: '38945'
  },
  '38946': {
    country: 'US',
    city: 'Morgan City',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leflore',
    latitude: 33.3807,
    longitude: -90.3504,
    postal_code: '38946'
  },
  '38947': {
    country: 'US',
    city: 'North Carrollton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Carroll',
    latitude: 33.5324,
    longitude: -89.9001,
    postal_code: '38947'
  },
  '38948': {
    country: 'US',
    city: 'Oakland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yalobusha',
    latitude: 34.0756,
    longitude: -89.8877,
    postal_code: '38948'
  },
  '38949': {
    country: 'US',
    city: 'Paris',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lafayette',
    latitude: 34.1787,
    longitude: -89.4598,
    postal_code: '38949'
  },
  '38950': {
    country: 'US',
    city: 'Philipp',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tallahatchie',
    latitude: 33.7574,
    longitude: -90.2091,
    postal_code: '38950'
  },
  '38951': {
    country: 'US',
    city: 'Pittsboro',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Calhoun',
    latitude: 33.9443,
    longitude: -89.3376,
    postal_code: '38951'
  },
  '38952': {
    country: 'US',
    city: 'Schlater',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leflore',
    latitude: 33.6244,
    longitude: -90.362,
    postal_code: '38952'
  },
  '38953': {
    country: 'US',
    city: 'Scobey',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yalobusha',
    latitude: 33.9253,
    longitude: -89.891,
    postal_code: '38953'
  },
  '38954': {
    country: 'US',
    city: 'Sidon',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Carroll',
    latitude: 33.4095,
    longitude: -90.1779,
    postal_code: '38954'
  },
  '38955': {
    country: 'US',
    city: 'Slate Spring',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Calhoun',
    latitude: 33.7439,
    longitude: -89.3753,
    postal_code: '38955'
  },
  '38957': {
    country: 'US',
    city: 'Sumner',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tallahatchie',
    latitude: 33.9833,
    longitude: -90.3895,
    postal_code: '38957'
  },
  '38958': {
    country: 'US',
    city: 'Swan Lake',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tallahatchie',
    latitude: 33.9292,
    longitude: -90.1885,
    postal_code: '38958'
  },
  '38959': {
    country: 'US',
    city: 'Swiftown',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leflore',
    latitude: 33.3809,
    longitude: -90.3496,
    postal_code: '38959'
  },
  '38960': {
    country: 'US',
    city: 'Tie Plant',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Grenada',
    latitude: 33.7872,
    longitude: -89.8219,
    postal_code: '38960'
  },
  '38961': {
    country: 'US',
    city: 'Tillatoba',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yalobusha',
    latitude: 33.9852,
    longitude: -89.8945,
    postal_code: '38961'
  },
  '38962': {
    country: 'US',
    city: 'Tippo',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tallahatchie',
    latitude: 33.9121,
    longitude: -90.1806,
    postal_code: '38962'
  },
  '38963': {
    country: 'US',
    city: 'Tutwiler',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tallahatchie',
    latitude: 34.0148,
    longitude: -90.4318,
    postal_code: '38963'
  },
  '38964': {
    country: 'US',
    city: 'Vance',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Quitman',
    latitude: 34.0936,
    longitude: -90.3752,
    postal_code: '38964'
  },
  '38965': {
    country: 'US',
    city: 'Water Valley',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yalobusha',
    latitude: 34.1525,
    longitude: -89.638,
    postal_code: '38965'
  },
  '38966': {
    country: 'US',
    city: 'Webb',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Tallahatchie',
    latitude: 33.9473,
    longitude: -90.3443,
    postal_code: '38966'
  },
  '38967': {
    country: 'US',
    city: 'Winona',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Montgomery',
    latitude: 33.4858,
    longitude: -89.7277,
    postal_code: '38967'
  },
  '39038': {
    country: 'US',
    city: 'Belzoni',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Humphreys',
    latitude: 33.1842,
    longitude: -90.4924,
    postal_code: '39038'
  },
  '39039': {
    country: 'US',
    city: 'Benton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yazoo',
    latitude: 32.8158,
    longitude: -90.2815,
    postal_code: '39039'
  },
  '39040': {
    country: 'US',
    city: 'Bentonia',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yazoo',
    latitude: 32.7,
    longitude: -90.3722,
    postal_code: '39040'
  },
  '39041': {
    country: 'US',
    city: 'Bolton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3778,
    longitude: -90.4474,
    postal_code: '39041'
  },
  '39042': {
    country: 'US',
    city: 'Brandon',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.3038,
    longitude: -89.964,
    postal_code: '39042'
  },
  '39043': {
    country: 'US',
    city: 'Brandon',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.269,
    longitude: -89.9875,
    postal_code: '39043'
  },
  '39044': {
    country: 'US',
    city: 'Braxton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Simpson',
    latitude: 32.0016,
    longitude: -89.9672,
    postal_code: '39044'
  },
  '39045': {
    country: 'US',
    city: 'Camden',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Madison',
    latitude: 32.7853,
    longitude: -89.8698,
    postal_code: '39045'
  },
  '39046': {
    country: 'US',
    city: 'Canton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Madison',
    latitude: 32.6205,
    longitude: -90.0061,
    postal_code: '39046'
  },
  '39047': {
    country: 'US',
    city: 'Brandon',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.3886,
    longitude: -89.9584,
    postal_code: '39047'
  },
  '39051': {
    country: 'US',
    city: 'Carthage',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leake',
    latitude: 32.7852,
    longitude: -89.5241,
    postal_code: '39051'
  },
  '39054': {
    country: 'US',
    city: 'Cary',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sharkey',
    latitude: 32.806,
    longitude: -90.9268,
    postal_code: '39054'
  },
  '39056': {
    country: 'US',
    city: 'Clinton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3411,
    longitude: -90.3229,
    postal_code: '39056'
  },
  '39057': {
    country: 'US',
    city: 'Conehatta',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Newton',
    latitude: 32.467,
    longitude: -89.269,
    postal_code: '39057'
  },
  '39058': {
    country: 'US',
    city: 'Clinton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3415,
    longitude: -90.3218,
    postal_code: '39058'
  },
  '39059': {
    country: 'US',
    city: 'Crystal Springs',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Copiah',
    latitude: 31.9933,
    longitude: -90.3744,
    postal_code: '39059'
  },
  '39060': {
    country: 'US',
    city: 'Clinton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3113,
    longitude: -90.3972,
    postal_code: '39060'
  },
  '39061': {
    country: 'US',
    city: 'Delta City',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sharkey',
    latitude: 33.074,
    longitude: -90.7951,
    postal_code: '39061'
  },
  '39062': {
    country: 'US',
    city: 'D Lo',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Simpson',
    latitude: 31.9857,
    longitude: -89.9012,
    postal_code: '39062'
  },
  '39063': {
    country: 'US',
    city: 'Durant',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Holmes',
    latitude: 33.0828,
    longitude: -89.8612,
    postal_code: '39063'
  },
  '39066': {
    country: 'US',
    city: 'Edwards',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.316,
    longitude: -90.5984,
    postal_code: '39066'
  },
  '39067': {
    country: 'US',
    city: 'Ethel',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Attala',
    latitude: 33.1204,
    longitude: -89.4659,
    postal_code: '39067'
  },
  '39069': {
    country: 'US',
    city: 'Fayette',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jefferson',
    latitude: 31.7121,
    longitude: -91.0582,
    postal_code: '39069'
  },
  '39071': {
    country: 'US',
    city: 'Flora',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Madison',
    latitude: 32.5578,
    longitude: -90.3235,
    postal_code: '39071'
  },
  '39073': {
    country: 'US',
    city: 'Florence',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.1535,
    longitude: -90.1312,
    postal_code: '39073'
  },
  '39074': {
    country: 'US',
    city: 'Forest',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Scott',
    latitude: 32.347,
    longitude: -89.4672,
    postal_code: '39074'
  },
  '39077': {
    country: 'US',
    city: 'Gallman',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Copiah',
    latitude: 31.9288,
    longitude: -90.3926,
    postal_code: '39077'
  },
  '39078': {
    country: 'US',
    city: 'Georgetown',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Copiah',
    latitude: 31.8552,
    longitude: -90.2127,
    postal_code: '39078'
  },
  '39079': {
    country: 'US',
    city: 'Goodman',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Holmes',
    latitude: 32.9892,
    longitude: -89.9433,
    postal_code: '39079'
  },
  '39080': {
    country: 'US',
    city: 'Harperville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Scott',
    latitude: 32.4925,
    longitude: -89.4946,
    postal_code: '39080'
  },
  '39081': {
    country: 'US',
    city: 'Harriston',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jefferson',
    latitude: 31.74,
    longitude: -91.0509,
    postal_code: '39081'
  },
  '39082': {
    country: 'US',
    city: 'Harrisville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Simpson',
    latitude: 31.9676,
    longitude: -90.1054,
    postal_code: '39082'
  },
  '39083': {
    country: 'US',
    city: 'Hazlehurst',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Copiah',
    latitude: 31.8562,
    longitude: -90.4051,
    postal_code: '39083'
  },
  '39086': {
    country: 'US',
    city: 'Hermanville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Claiborne',
    latitude: 31.9564,
    longitude: -90.8446,
    postal_code: '39086'
  },
  '39087': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Scott',
    latitude: 32.4706,
    longitude: -89.5171,
    postal_code: '39087'
  },
  '39088': {
    country: 'US',
    city: 'Holly Bluff',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yazoo',
    latitude: 32.8415,
    longitude: -90.7231,
    postal_code: '39088'
  },
  '39090': {
    country: 'US',
    city: 'Kosciusko',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Attala',
    latitude: 33.0446,
    longitude: -89.5724,
    postal_code: '39090'
  },
  '39092': {
    country: 'US',
    city: 'Lake',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Scott',
    latitude: 32.3459,
    longitude: -89.3414,
    postal_code: '39092'
  },
  '39094': {
    country: 'US',
    city: 'Lena',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leake',
    latitude: 32.6446,
    longitude: -89.5494,
    postal_code: '39094'
  },
  '39095': {
    country: 'US',
    city: 'Lexington',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Holmes',
    latitude: 33.125,
    longitude: -90.0557,
    postal_code: '39095'
  },
  '39096': {
    country: 'US',
    city: 'Lorman',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jefferson',
    latitude: 31.8383,
    longitude: -91.0957,
    postal_code: '39096'
  },
  '39097': {
    country: 'US',
    city: 'Louise',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Humphreys',
    latitude: 32.9919,
    longitude: -90.5893,
    postal_code: '39097'
  },
  '39098': {
    country: 'US',
    city: 'Ludlow',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Scott',
    latitude: 32.566,
    longitude: -89.7135,
    postal_code: '39098'
  },
  '39107': {
    country: 'US',
    city: 'Mc Adams',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Attala',
    latitude: 33.0828,
    longitude: -89.6412,
    postal_code: '39107'
  },
  '39108': {
    country: 'US',
    city: 'Mc Cool',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Attala',
    latitude: 33.1632,
    longitude: -89.3268,
    postal_code: '39108'
  },
  '39109': {
    country: 'US',
    city: 'Madden',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leake',
    latitude: 32.6909,
    longitude: -89.3554,
    postal_code: '39109'
  },
  '39110': {
    country: 'US',
    city: 'Madison',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Madison',
    latitude: 32.4671,
    longitude: -90.1087,
    postal_code: '39110'
  },
  '39111': {
    country: 'US',
    city: 'Magee',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Simpson',
    latitude: 31.8495,
    longitude: -89.7503,
    postal_code: '39111'
  },
  '39113': {
    country: 'US',
    city: 'Mayersville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Issaquena',
    latitude: 32.9013,
    longitude: -91.0286,
    postal_code: '39113'
  },
  '39114': {
    country: 'US',
    city: 'Mendenhall',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Simpson',
    latitude: 31.9494,
    longitude: -89.8095,
    postal_code: '39114'
  },
  '39115': {
    country: 'US',
    city: 'Midnight',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Humphreys',
    latitude: 33.0496,
    longitude: -90.5734,
    postal_code: '39115'
  },
  '39116': {
    country: 'US',
    city: 'Mize',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Smith',
    latitude: 31.8473,
    longitude: -89.5741,
    postal_code: '39116'
  },
  '39117': {
    country: 'US',
    city: 'Morton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Scott',
    latitude: 32.4029,
    longitude: -89.5949,
    postal_code: '39117'
  },
  '39119': {
    country: 'US',
    city: 'Mount Olive',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Covington',
    latitude: 31.7613,
    longitude: -89.6551,
    postal_code: '39119'
  },
  '39120': {
    country: 'US',
    city: 'Natchez',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Adams',
    latitude: 31.5492,
    longitude: -91.3642,
    postal_code: '39120'
  },
  '39121': {
    country: 'US',
    city: 'Natchez',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Adams',
    latitude: 31.4706,
    longitude: -91.4044,
    postal_code: '39121'
  },
  '39122': {
    country: 'US',
    city: 'Natchez',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Adams',
    latitude: 31.5604,
    longitude: -91.4032,
    postal_code: '39122'
  },
  '39130': {
    country: 'US',
    city: 'Madison',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Madison',
    latitude: 32.4618,
    longitude: -90.1154,
    postal_code: '39130'
  },
  '39140': {
    country: 'US',
    city: 'Newhebron',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lawrence',
    latitude: 31.73,
    longitude: -90.0153,
    postal_code: '39140'
  },
  '39144': {
    country: 'US',
    city: 'Pattison',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Claiborne',
    latitude: 31.851,
    longitude: -90.8283,
    postal_code: '39144'
  },
  '39145': {
    country: 'US',
    city: 'Pelahatchie',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.318,
    longitude: -89.7913,
    postal_code: '39145'
  },
  '39146': {
    country: 'US',
    city: 'Pickens',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Holmes',
    latitude: 32.8905,
    longitude: -89.9737,
    postal_code: '39146'
  },
  '39148': {
    country: 'US',
    city: 'Piney Woods',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.0627,
    longitude: -89.9929,
    postal_code: '39148'
  },
  '39149': {
    country: 'US',
    city: 'Pinola',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Simpson',
    latitude: 31.8277,
    longitude: -90.0088,
    postal_code: '39149'
  },
  '39150': {
    country: 'US',
    city: 'Port Gibson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Claiborne',
    latitude: 31.961,
    longitude: -90.984,
    postal_code: '39150'
  },
  '39151': {
    country: 'US',
    city: 'Puckett',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.1062,
    longitude: -89.7836,
    postal_code: '39151'
  },
  '39152': {
    country: 'US',
    city: 'Pulaski',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Scott',
    latitude: 32.2745,
    longitude: -89.5627,
    postal_code: '39152'
  },
  '39153': {
    country: 'US',
    city: 'Raleigh',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Smith',
    latitude: 32.0508,
    longitude: -89.5088,
    postal_code: '39153'
  },
  '39154': {
    country: 'US',
    city: 'Raymond',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.1961,
    longitude: -90.4753,
    postal_code: '39154'
  },
  '39156': {
    country: 'US',
    city: 'Redwood',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Warren',
    latitude: 32.4867,
    longitude: -90.7859,
    postal_code: '39156'
  },
  '39157': {
    country: 'US',
    city: 'Ridgeland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Madison',
    latitude: 32.4122,
    longitude: -90.1207,
    postal_code: '39157'
  },
  '39158': {
    country: 'US',
    city: 'Ridgeland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Madison',
    latitude: 32.4285,
    longitude: -90.1323,
    postal_code: '39158'
  },
  '39159': {
    country: 'US',
    city: 'Rolling Fork',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Sharkey',
    latitude: 32.8733,
    longitude: -90.8833,
    postal_code: '39159'
  },
  '39160': {
    country: 'US',
    city: 'Sallis',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Attala',
    latitude: 33.001,
    longitude: -89.7552,
    postal_code: '39160'
  },
  '39161': {
    country: 'US',
    city: 'Sandhill',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.4983,
    longitude: -89.8686,
    postal_code: '39161'
  },
  '39162': {
    country: 'US',
    city: 'Satartia',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yazoo',
    latitude: 32.6125,
    longitude: -90.5965,
    postal_code: '39162'
  },
  '39163': {
    country: 'US',
    city: 'Sharon',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Madison',
    latitude: 32.6424,
    longitude: -90.0907,
    postal_code: '39163'
  },
  '39165': {
    country: 'US',
    city: 'Sibley',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Adams',
    latitude: 31.4706,
    longitude: -91.4044,
    postal_code: '39165'
  },
  '39166': {
    country: 'US',
    city: 'Silver City',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Humphreys',
    latitude: 33.0507,
    longitude: -90.4928,
    postal_code: '39166'
  },
  '39167': {
    country: 'US',
    city: 'Star',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.094,
    longitude: -90.0462,
    postal_code: '39167'
  },
  '39168': {
    country: 'US',
    city: 'Taylorsville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Smith',
    latitude: 31.8394,
    longitude: -89.4049,
    postal_code: '39168'
  },
  '39169': {
    country: 'US',
    city: 'Tchula',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Holmes',
    latitude: 33.1624,
    longitude: -90.25,
    postal_code: '39169'
  },
  '39170': {
    country: 'US',
    city: 'Terry',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.1147,
    longitude: -90.3241,
    postal_code: '39170'
  },
  '39171': {
    country: 'US',
    city: 'Thomastown',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leake',
    latitude: 32.864,
    longitude: -89.6695,
    postal_code: '39171'
  },
  '39173': {
    country: 'US',
    city: 'Tinsley',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yazoo',
    latitude: 32.7628,
    longitude: -90.3628,
    postal_code: '39173'
  },
  '39174': {
    country: 'US',
    city: 'Tougaloo',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3953,
    longitude: -90.1544,
    postal_code: '39174'
  },
  '39175': {
    country: 'US',
    city: 'Utica',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.1229,
    longitude: -90.6047,
    postal_code: '39175'
  },
  '39176': {
    country: 'US',
    city: 'Vaiden',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Carroll',
    latitude: 33.3343,
    longitude: -89.757,
    postal_code: '39176'
  },
  '39177': {
    country: 'US',
    city: 'Valley Park',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Issaquena',
    latitude: 32.6323,
    longitude: -90.8542,
    postal_code: '39177'
  },
  '39179': {
    country: 'US',
    city: 'Vaughan',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yazoo',
    latitude: 32.8015,
    longitude: -90.0981,
    postal_code: '39179'
  },
  '39180': {
    country: 'US',
    city: 'Vicksburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Warren',
    latitude: 32.3258,
    longitude: -90.8507,
    postal_code: '39180'
  },
  '39181': {
    country: 'US',
    city: 'Vicksburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Warren',
    latitude: 32.3486,
    longitude: -90.8642,
    postal_code: '39181'
  },
  '39182': {
    country: 'US',
    city: 'Vicksburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Warren',
    latitude: 32.3486,
    longitude: -90.8642,
    postal_code: '39182'
  },
  '39183': {
    country: 'US',
    city: 'Vicksburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Warren',
    latitude: 32.386,
    longitude: -90.84,
    postal_code: '39183'
  },
  '39189': {
    country: 'US',
    city: 'Walnut Grove',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Leake',
    latitude: 32.6134,
    longitude: -89.4105,
    postal_code: '39189'
  },
  '39190': {
    country: 'US',
    city: 'Washington',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Adams',
    latitude: 31.5788,
    longitude: -91.2993,
    postal_code: '39190'
  },
  '39191': {
    country: 'US',
    city: 'Wesson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Copiah',
    latitude: 31.6901,
    longitude: -90.4131,
    postal_code: '39191'
  },
  '39192': {
    country: 'US',
    city: 'West',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Holmes',
    latitude: 33.1883,
    longitude: -89.7695,
    postal_code: '39192'
  },
  '39193': {
    country: 'US',
    city: 'Whitfield',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.2348,
    longitude: -90.0726,
    postal_code: '39193'
  },
  '39194': {
    country: 'US',
    city: 'Yazoo City',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Yazoo',
    latitude: 32.8594,
    longitude: -90.4031,
    postal_code: '39194'
  },
  '39201': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2935,
    longitude: -90.1867,
    postal_code: '39201'
  },
  '39202': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3149,
    longitude: -90.1782,
    postal_code: '39202'
  },
  '39203': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3081,
    longitude: -90.2021,
    postal_code: '39203'
  },
  '39204': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2832,
    longitude: -90.2306,
    postal_code: '39204'
  },
  '39205': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39205'
  },
  '39206': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.37,
    longitude: -90.1738,
    postal_code: '39206'
  },
  '39207': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39207'
  },
  '39208': {
    country: 'US',
    city: 'Pearl',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.2604,
    longitude: -90.0897,
    postal_code: '39208'
  },
  '39209': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3184,
    longitude: -90.2446,
    postal_code: '39209'
  },
  '39210': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3218,
    longitude: -90.1771,
    postal_code: '39210'
  },
  '39211': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3739,
    longitude: -90.1293,
    postal_code: '39211'
  },
  '39212': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2435,
    longitude: -90.2612,
    postal_code: '39212'
  },
  '39213': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3553,
    longitude: -90.2171,
    postal_code: '39213'
  },
  '39215': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39215'
  },
  '39216': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3386,
    longitude: -90.1708,
    postal_code: '39216'
  },
  '39217': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2974,
    longitude: -90.2081,
    postal_code: '39217'
  },
  '39218': {
    country: 'US',
    city: 'Richland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.2287,
    longitude: -90.1591,
    postal_code: '39218'
  },
  '39225': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39225'
  },
  '39232': {
    country: 'US',
    city: 'Flowood',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.3299,
    longitude: -90.0915,
    postal_code: '39232'
  },
  '39236': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39236'
  },
  '39250': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39250'
  },
  '39269': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.3003,
    longitude: -90.1886,
    postal_code: '39269'
  },
  '39271': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39271'
  },
  '39272': {
    country: 'US',
    city: 'Byram',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.1888,
    longitude: -90.2595,
    postal_code: '39272'
  },
  '39282': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39282'
  },
  '39283': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39283'
  },
  '39284': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39284'
  },
  '39286': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39286'
  },
  '39288': {
    country: 'US',
    city: 'Pearl',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.2746,
    longitude: -90.132,
    postal_code: '39288'
  },
  '39289': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39289'
  },
  '39296': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hinds',
    latitude: 32.2988,
    longitude: -90.1848,
    postal_code: '39296'
  },
  '39298': {
    country: 'US',
    city: 'Jackson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Rankin',
    latitude: 32.3199,
    longitude: -89.9923,
    postal_code: '39298'
  },
  '39301': {
    country: 'US',
    city: 'Meridian',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.3574,
    longitude: -88.656,
    postal_code: '39301'
  },
  '39302': {
    country: 'US',
    city: 'Meridian',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.3643,
    longitude: -88.7037,
    postal_code: '39302'
  },
  '39303': {
    country: 'US',
    city: 'Meridian',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.4012,
    longitude: -88.6523,
    postal_code: '39303'
  },
  '39304': {
    country: 'US',
    city: 'Meridian',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.4208,
    longitude: -88.646,
    postal_code: '39304'
  },
  '39305': {
    country: 'US',
    city: 'Meridian',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.4401,
    longitude: -88.6783,
    postal_code: '39305'
  },
  '39307': {
    country: 'US',
    city: 'Meridian',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.3736,
    longitude: -88.7436,
    postal_code: '39307'
  },
  '39309': {
    country: 'US',
    city: 'Meridian',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.5505,
    longitude: -88.6132,
    postal_code: '39309'
  },
  '39320': {
    country: 'US',
    city: 'Bailey',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.4676,
    longitude: -88.7228,
    postal_code: '39320'
  },
  '39322': {
    country: 'US',
    city: 'Buckatunna',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Wayne',
    latitude: 31.5391,
    longitude: -88.5289,
    postal_code: '39322'
  },
  '39323': {
    country: 'US',
    city: 'Chunky',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Newton',
    latitude: 32.3505,
    longitude: -88.9551,
    postal_code: '39323'
  },
  '39324': {
    country: 'US',
    city: 'Clara',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Wayne',
    latitude: 31.5937,
    longitude: -88.7048,
    postal_code: '39324'
  },
  '39325': {
    country: 'US',
    city: 'Collinsville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.5643,
    longitude: -88.875,
    postal_code: '39325'
  },
  '39326': {
    country: 'US',
    city: 'Daleville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.5511,
    longitude: -88.6609,
    postal_code: '39326'
  },
  '39327': {
    country: 'US',
    city: 'Decatur',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Newton',
    latitude: 32.4358,
    longitude: -89.1168,
    postal_code: '39327'
  },
  '39328': {
    country: 'US',
    city: 'De Kalb',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Kemper',
    latitude: 32.7162,
    longitude: -88.7332,
    postal_code: '39328'
  },
  '39330': {
    country: 'US',
    city: 'Enterprise',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Clarke',
    latitude: 32.1563,
    longitude: -88.8474,
    postal_code: '39330'
  },
  '39332': {
    country: 'US',
    city: 'Hickory',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Newton',
    latitude: 32.32,
    longitude: -89.0093,
    postal_code: '39332'
  },
  '39335': {
    country: 'US',
    city: 'Lauderdale',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.5022,
    longitude: -88.4956,
    postal_code: '39335'
  },
  '39336': {
    country: 'US',
    city: 'Lawrence',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Newton',
    latitude: 32.2876,
    longitude: -89.2724,
    postal_code: '39336'
  },
  '39337': {
    country: 'US',
    city: 'Little Rock',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Newton',
    latitude: 32.5049,
    longitude: -88.9955,
    postal_code: '39337'
  },
  '39338': {
    country: 'US',
    city: 'Louin',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jasper',
    latitude: 32.0997,
    longitude: -89.2199,
    postal_code: '39338'
  },
  '39339': {
    country: 'US',
    city: 'Louisville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Winston',
    latitude: 33.1058,
    longitude: -89.0287,
    postal_code: '39339'
  },
  '39341': {
    country: 'US',
    city: 'Macon',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Noxubee',
    latitude: 33.1027,
    longitude: -88.5781,
    postal_code: '39341'
  },
  '39342': {
    country: 'US',
    city: 'Marion',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.4245,
    longitude: -88.6483,
    postal_code: '39342'
  },
  '39345': {
    country: 'US',
    city: 'Newton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Newton',
    latitude: 32.3244,
    longitude: -89.184,
    postal_code: '39345'
  },
  '39346': {
    country: 'US',
    city: 'Noxapater',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Winston',
    latitude: 32.9789,
    longitude: -89.1221,
    postal_code: '39346'
  },
  '39347': {
    country: 'US',
    city: 'Pachuta',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Clarke',
    latitude: 32.0217,
    longitude: -88.8851,
    postal_code: '39347'
  },
  '39348': {
    country: 'US',
    city: 'Paulding',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jasper',
    latitude: 32.0167,
    longitude: -89.0584,
    postal_code: '39348'
  },
  '39350': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Neshoba',
    latitude: 32.7572,
    longitude: -89.1154,
    postal_code: '39350'
  },
  '39352': {
    country: 'US',
    city: 'Porterville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Kemper',
    latitude: 32.6368,
    longitude: -88.4984,
    postal_code: '39352'
  },
  '39354': {
    country: 'US',
    city: 'Preston',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Kemper',
    latitude: 32.8826,
    longitude: -88.8289,
    postal_code: '39354'
  },
  '39355': {
    country: 'US',
    city: 'Quitman',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Clarke',
    latitude: 32.0668,
    longitude: -88.6788,
    postal_code: '39355'
  },
  '39356': {
    country: 'US',
    city: 'Rose Hill',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jasper',
    latitude: 32.1489,
    longitude: -89.0011,
    postal_code: '39356'
  },
  '39358': {
    country: 'US',
    city: 'Scooba',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Kemper',
    latitude: 32.8402,
    longitude: -88.4883,
    postal_code: '39358'
  },
  '39359': {
    country: 'US',
    city: 'Sebastopol',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Scott',
    latitude: 32.5729,
    longitude: -89.3351,
    postal_code: '39359'
  },
  '39360': {
    country: 'US',
    city: 'Shubuta',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Clarke',
    latitude: 31.9607,
    longitude: -88.7168,
    postal_code: '39360'
  },
  '39361': {
    country: 'US',
    city: 'Shuqualak',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Noxubee',
    latitude: 32.9769,
    longitude: -88.5594,
    postal_code: '39361'
  },
  '39362': {
    country: 'US',
    city: 'State Line',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Greene',
    latitude: 31.3916,
    longitude: -88.5171,
    postal_code: '39362'
  },
  '39363': {
    country: 'US',
    city: 'Stonewall',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Clarke',
    latitude: 32.1564,
    longitude: -88.7748,
    postal_code: '39363'
  },
  '39364': {
    country: 'US',
    city: 'Toomsuba',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lauderdale',
    latitude: 32.4296,
    longitude: -88.4892,
    postal_code: '39364'
  },
  '39365': {
    country: 'US',
    city: 'Union',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Neshoba',
    latitude: 32.6439,
    longitude: -89.112,
    postal_code: '39365'
  },
  '39366': {
    country: 'US',
    city: 'Vossburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jasper',
    latitude: 31.9506,
    longitude: -88.9707,
    postal_code: '39366'
  },
  '39367': {
    country: 'US',
    city: 'Waynesboro',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Wayne',
    latitude: 31.6754,
    longitude: -88.6782,
    postal_code: '39367'
  },
  '39401': {
    country: 'US',
    city: 'Hattiesburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Forrest',
    latitude: 31.3146,
    longitude: -89.3065,
    postal_code: '39401'
  },
  '39402': {
    country: 'US',
    city: 'Hattiesburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Forrest',
    latitude: 31.3098,
    longitude: -89.3775,
    postal_code: '39402'
  },
  '39403': {
    country: 'US',
    city: 'Hattiesburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Forrest',
    latitude: 31.3566,
    longitude: -89.3824,
    postal_code: '39403'
  },
  '39404': {
    country: 'US',
    city: 'Hattiesburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Forrest',
    latitude: 31.1721,
    longitude: -89.2948,
    postal_code: '39404'
  },
  '39406': {
    country: 'US',
    city: 'Hattiesburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Forrest',
    latitude: 31.1721,
    longitude: -89.2948,
    postal_code: '39406'
  },
  '39407': {
    country: 'US',
    city: 'Hattiesburg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Forrest',
    latitude: 31.1721,
    longitude: -89.2948,
    postal_code: '39407'
  },
  '39421': {
    country: 'US',
    city: 'Bassfield',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jefferson Davis',
    latitude: 31.5037,
    longitude: -89.7027,
    postal_code: '39421'
  },
  '39422': {
    country: 'US',
    city: 'Bay Springs',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jasper',
    latitude: 31.9449,
    longitude: -89.2338,
    postal_code: '39422'
  },
  '39423': {
    country: 'US',
    city: 'Beaumont',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Perry',
    latitude: 31.1693,
    longitude: -88.92,
    postal_code: '39423'
  },
  '39425': {
    country: 'US',
    city: 'Brooklyn',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Forrest',
    latitude: 31.0591,
    longitude: -89.0796,
    postal_code: '39425'
  },
  '39426': {
    country: 'US',
    city: 'Carriere',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pearl River',
    latitude: 30.6178,
    longitude: -89.5779,
    postal_code: '39426'
  },
  '39427': {
    country: 'US',
    city: 'Carson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jefferson Davis',
    latitude: 31.4814,
    longitude: -89.8279,
    postal_code: '39427'
  },
  '39428': {
    country: 'US',
    city: 'Collins',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Covington',
    latitude: 31.6707,
    longitude: -89.5438,
    postal_code: '39428'
  },
  '39429': {
    country: 'US',
    city: 'Columbia',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marion',
    latitude: 31.2559,
    longitude: -89.7998,
    postal_code: '39429'
  },
  '39436': {
    country: 'US',
    city: 'Eastabuchie',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jones',
    latitude: 31.4555,
    longitude: -89.302,
    postal_code: '39436'
  },
  '39437': {
    country: 'US',
    city: 'Ellisville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jones',
    latitude: 31.5797,
    longitude: -89.2231,
    postal_code: '39437'
  },
  '39439': {
    country: 'US',
    city: 'Heidelberg',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jasper',
    latitude: 31.882,
    longitude: -88.9981,
    postal_code: '39439'
  },
  '39440': {
    country: 'US',
    city: 'Laurel',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jones',
    latitude: 31.7054,
    longitude: -89.1312,
    postal_code: '39440'
  },
  '39441': {
    country: 'US',
    city: 'Laurel',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jones',
    latitude: 31.7277,
    longitude: -89.0755,
    postal_code: '39441'
  },
  '39442': {
    country: 'US',
    city: 'Laurel',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jones',
    latitude: 31.6823,
    longitude: -89.0406,
    postal_code: '39442'
  },
  '39443': {
    country: 'US',
    city: 'Laurel',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jones',
    latitude: 31.7138,
    longitude: -89.0759,
    postal_code: '39443'
  },
  '39451': {
    country: 'US',
    city: 'Leakesville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Greene',
    latitude: 31.1238,
    longitude: -88.5595,
    postal_code: '39451'
  },
  '39452': {
    country: 'US',
    city: 'Lucedale',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'George',
    latitude: 30.8668,
    longitude: -88.649,
    postal_code: '39452'
  },
  '39455': {
    country: 'US',
    city: 'Lumberton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lamar',
    latitude: 31.0013,
    longitude: -89.4523,
    postal_code: '39455'
  },
  '39456': {
    country: 'US',
    city: 'Mc Lain',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Greene',
    latitude: 31.0867,
    longitude: -88.7977,
    postal_code: '39456'
  },
  '39457': {
    country: 'US',
    city: 'Mc Neill',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pearl River',
    latitude: 30.6669,
    longitude: -89.6734,
    postal_code: '39457'
  },
  '39459': {
    country: 'US',
    city: 'Moselle',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jones',
    latitude: 31.4836,
    longitude: -89.3206,
    postal_code: '39459'
  },
  '39460': {
    country: 'US',
    city: 'Moss',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jasper',
    latitude: 31.8152,
    longitude: -89.1778,
    postal_code: '39460'
  },
  '39461': {
    country: 'US',
    city: 'Neely',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Greene',
    latitude: 31.1793,
    longitude: -88.7198,
    postal_code: '39461'
  },
  '39462': {
    country: 'US',
    city: 'New Augusta',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Perry',
    latitude: 31.2026,
    longitude: -89.0367,
    postal_code: '39462'
  },
  '39463': {
    country: 'US',
    city: 'Nicholson',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pearl River',
    latitude: 30.5637,
    longitude: -89.5578,
    postal_code: '39463'
  },
  '39464': {
    country: 'US',
    city: 'Ovett',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Perry',
    latitude: 31.4738,
    longitude: -89.0396,
    postal_code: '39464'
  },
  '39465': {
    country: 'US',
    city: 'Petal',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Forrest',
    latitude: 31.3472,
    longitude: -89.2222,
    postal_code: '39465'
  },
  '39466': {
    country: 'US',
    city: 'Picayune',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pearl River',
    latitude: 30.5418,
    longitude: -89.691,
    postal_code: '39466'
  },
  '39470': {
    country: 'US',
    city: 'Poplarville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pearl River',
    latitude: 30.8524,
    longitude: -89.5648,
    postal_code: '39470'
  },
  '39474': {
    country: 'US',
    city: 'Prentiss',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jefferson Davis',
    latitude: 31.6057,
    longitude: -89.8735,
    postal_code: '39474'
  },
  '39475': {
    country: 'US',
    city: 'Purvis',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lamar',
    latitude: 31.1496,
    longitude: -89.4623,
    postal_code: '39475'
  },
  '39476': {
    country: 'US',
    city: 'Richton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Perry',
    latitude: 31.3434,
    longitude: -88.9101,
    postal_code: '39476'
  },
  '39477': {
    country: 'US',
    city: 'Sandersville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jones',
    latitude: 31.7909,
    longitude: -89.0414,
    postal_code: '39477'
  },
  '39478': {
    country: 'US',
    city: 'Sandy Hook',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marion',
    latitude: 31.0479,
    longitude: -89.8183,
    postal_code: '39478'
  },
  '39479': {
    country: 'US',
    city: 'Seminary',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Covington',
    latitude: 31.5285,
    longitude: -89.4816,
    postal_code: '39479'
  },
  '39480': {
    country: 'US',
    city: 'Soso',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jones',
    latitude: 31.7594,
    longitude: -89.3082,
    postal_code: '39480'
  },
  '39481': {
    country: 'US',
    city: 'Stringer',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jasper',
    latitude: 31.8447,
    longitude: -89.2621,
    postal_code: '39481'
  },
  '39482': {
    country: 'US',
    city: 'Sumrall',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lamar',
    latitude: 31.36,
    longitude: -89.5777,
    postal_code: '39482'
  },
  '39483': {
    country: 'US',
    city: 'Foxworth',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marion',
    latitude: 31.2473,
    longitude: -89.9311,
    postal_code: '39483'
  },
  '39501': {
    country: 'US',
    city: 'Gulfport',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.3826,
    longitude: -89.0976,
    postal_code: '39501'
  },
  '39502': {
    country: 'US',
    city: 'Gulfport',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.4158,
    longitude: -89.0684,
    postal_code: '39502'
  },
  '39503': {
    country: 'US',
    city: 'Gulfport',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.4601,
    longitude: -89.0886,
    postal_code: '39503'
  },
  '39505': {
    country: 'US',
    city: 'Gulfport',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.4158,
    longitude: -89.0684,
    postal_code: '39505'
  },
  '39506': {
    country: 'US',
    city: 'Gulfport',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.4158,
    longitude: -89.0684,
    postal_code: '39506'
  },
  '39507': {
    country: 'US',
    city: 'Gulfport',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.3962,
    longitude: -89.0353,
    postal_code: '39507'
  },
  '39520': {
    country: 'US',
    city: 'Bay Saint Louis',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hancock',
    latitude: 30.2956,
    longitude: -89.4632,
    postal_code: '39520'
  },
  '39521': {
    country: 'US',
    city: 'Bay Saint Louis',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hancock',
    latitude: 30.4032,
    longitude: -89.4982,
    postal_code: '39521'
  },
  '39522': {
    country: 'US',
    city: 'Stennis Space Center',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hancock',
    latitude: 30.4032,
    longitude: -89.4982,
    postal_code: '39522'
  },
  '39525': {
    country: 'US',
    city: 'Diamondhead',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hancock',
    latitude: 30.3728,
    longitude: -89.3779,
    postal_code: '39525'
  },
  '39529': {
    country: 'US',
    city: 'Stennis Space Center',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hancock',
    latitude: 30.4032,
    longitude: -89.4982,
    postal_code: '39529'
  },
  '39530': {
    country: 'US',
    city: 'Biloxi',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.4035,
    longitude: -88.8971,
    postal_code: '39530'
  },
  '39531': {
    country: 'US',
    city: 'Biloxi',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.4033,
    longitude: -88.9605,
    postal_code: '39531'
  },
  '39532': {
    country: 'US',
    city: 'Biloxi',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.511,
    longitude: -88.9681,
    postal_code: '39532'
  },
  '39533': {
    country: 'US',
    city: 'Biloxi',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.396,
    longitude: -88.8853,
    postal_code: '39533'
  },
  '39534': {
    country: 'US',
    city: 'Biloxi',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.4067,
    longitude: -88.9211,
    postal_code: '39534'
  },
  '39535': {
    country: 'US',
    city: 'Biloxi',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.4158,
    longitude: -89.0684,
    postal_code: '39535'
  },
  '39540': {
    country: 'US',
    city: 'Diberville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.4383,
    longitude: -88.8989,
    postal_code: '39540'
  },
  '39552': {
    country: 'US',
    city: 'Escatawpa',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.4411,
    longitude: -88.6345,
    postal_code: '39552'
  },
  '39553': {
    country: 'US',
    city: 'Gautier',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.398,
    longitude: -88.6412,
    postal_code: '39553'
  },
  '39555': {
    country: 'US',
    city: 'Hurley',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.7132,
    longitude: -88.5197,
    postal_code: '39555'
  },
  '39556': {
    country: 'US',
    city: 'Kiln',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hancock',
    latitude: 30.4875,
    longitude: -89.4187,
    postal_code: '39556'
  },
  '39558': {
    country: 'US',
    city: 'Lakeshore',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hancock',
    latitude: 30.2391,
    longitude: -89.4595,
    postal_code: '39558'
  },
  '39560': {
    country: 'US',
    city: 'Long Beach',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.3598,
    longitude: -89.1646,
    postal_code: '39560'
  },
  '39561': {
    country: 'US',
    city: 'Mc Henry',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Stone',
    latitude: 30.6975,
    longitude: -89.1536,
    postal_code: '39561'
  },
  '39562': {
    country: 'US',
    city: 'Moss Point',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.5471,
    longitude: -88.4927,
    postal_code: '39562'
  },
  '39563': {
    country: 'US',
    city: 'Moss Point',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.425,
    longitude: -88.5233,
    postal_code: '39563'
  },
  '39564': {
    country: 'US',
    city: 'Ocean Springs',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.4404,
    longitude: -88.7801,
    postal_code: '39564'
  },
  '39565': {
    country: 'US',
    city: 'Vancleave',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.5405,
    longitude: -88.6875,
    postal_code: '39565'
  },
  '39566': {
    country: 'US',
    city: 'Ocean Springs',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.4113,
    longitude: -88.8278,
    postal_code: '39566'
  },
  '39567': {
    country: 'US',
    city: 'Pascagoula',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.3658,
    longitude: -88.5561,
    postal_code: '39567'
  },
  '39568': {
    country: 'US',
    city: 'Pascagoula',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.4411,
    longitude: -88.6345,
    postal_code: '39568'
  },
  '39569': {
    country: 'US',
    city: 'Pascagoula',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.3658,
    longitude: -88.5561,
    postal_code: '39569'
  },
  '39571': {
    country: 'US',
    city: 'Pass Christian',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.3989,
    longitude: -89.2843,
    postal_code: '39571'
  },
  '39572': {
    country: 'US',
    city: 'Pearlington',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hancock',
    latitude: 30.2508,
    longitude: -89.6011,
    postal_code: '39572'
  },
  '39573': {
    country: 'US',
    city: 'Perkinston',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Stone',
    latitude: 30.7669,
    longitude: -89.14,
    postal_code: '39573'
  },
  '39574': {
    country: 'US',
    city: 'Saucier',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Harrison',
    latitude: 30.5961,
    longitude: -89.1477,
    postal_code: '39574'
  },
  '39576': {
    country: 'US',
    city: 'Waveland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Hancock',
    latitude: 30.2914,
    longitude: -89.3837,
    postal_code: '39576'
  },
  '39577': {
    country: 'US',
    city: 'Wiggins',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Stone',
    latitude: 30.8609,
    longitude: -89.1324,
    postal_code: '39577'
  },
  '39581': {
    country: 'US',
    city: 'Pascagoula',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.3664,
    longitude: -88.5289,
    postal_code: '39581'
  },
  '39595': {
    country: 'US',
    city: 'Pascagoula',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jackson',
    latitude: 30.3658,
    longitude: -88.5561,
    postal_code: '39595'
  },
  '39601': {
    country: 'US',
    city: 'Brookhaven',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lincoln',
    latitude: 31.5858,
    longitude: -90.452,
    postal_code: '39601'
  },
  '39602': {
    country: 'US',
    city: 'Brookhaven',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lincoln',
    latitude: 31.5334,
    longitude: -90.4907,
    postal_code: '39602'
  },
  '39603': {
    country: 'US',
    city: 'Brookhaven',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lincoln',
    latitude: 31.6123,
    longitude: -90.4467,
    postal_code: '39603'
  },
  '39629': {
    country: 'US',
    city: 'Bogue Chitto',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lincoln',
    latitude: 31.4563,
    longitude: -90.4732,
    postal_code: '39629'
  },
  '39630': {
    country: 'US',
    city: 'Bude',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Franklin',
    latitude: 31.4593,
    longitude: -90.8457,
    postal_code: '39630'
  },
  '39631': {
    country: 'US',
    city: 'Centreville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Wilkinson',
    latitude: 31.0893,
    longitude: -91.096,
    postal_code: '39631'
  },
  '39632': {
    country: 'US',
    city: 'Chatawa',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pike',
    latitude: 31.1145,
    longitude: -90.4875,
    postal_code: '39632'
  },
  '39633': {
    country: 'US',
    city: 'Crosby',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Amite',
    latitude: 31.291,
    longitude: -91.1849,
    postal_code: '39633'
  },
  '39635': {
    country: 'US',
    city: 'Fernwood',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pike',
    latitude: 31.1855,
    longitude: -90.449,
    postal_code: '39635'
  },
  '39638': {
    country: 'US',
    city: 'Gloster',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Amite',
    latitude: 31.1903,
    longitude: -90.924,
    postal_code: '39638'
  },
  '39641': {
    country: 'US',
    city: 'Jayess',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lawrence',
    latitude: 31.4036,
    longitude: -90.1864,
    postal_code: '39641'
  },
  '39643': {
    country: 'US',
    city: 'Kokomo',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Marion',
    latitude: 31.244,
    longitude: -90.0194,
    postal_code: '39643'
  },
  '39645': {
    country: 'US',
    city: 'Liberty',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Amite',
    latitude: 31.1384,
    longitude: -90.7133,
    postal_code: '39645'
  },
  '39647': {
    country: 'US',
    city: 'Mc Call Creek',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Franklin',
    latitude: 31.4908,
    longitude: -90.7836,
    postal_code: '39647'
  },
  '39648': {
    country: 'US',
    city: 'Mccomb',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pike',
    latitude: 31.1769,
    longitude: -90.4016,
    postal_code: '39648'
  },
  '39649': {
    country: 'US',
    city: 'Mccomb',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pike',
    latitude: 31.2438,
    longitude: -90.4532,
    postal_code: '39649'
  },
  '39652': {
    country: 'US',
    city: 'Magnolia',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pike',
    latitude: 31.1217,
    longitude: -90.4831,
    postal_code: '39652'
  },
  '39653': {
    country: 'US',
    city: 'Meadville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Franklin',
    latitude: 31.4285,
    longitude: -90.8578,
    postal_code: '39653'
  },
  '39654': {
    country: 'US',
    city: 'Monticello',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lawrence',
    latitude: 31.5238,
    longitude: -90.128,
    postal_code: '39654'
  },
  '39656': {
    country: 'US',
    city: 'Oak Vale',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lawrence',
    latitude: 31.442,
    longitude: -89.9827,
    postal_code: '39656'
  },
  '39657': {
    country: 'US',
    city: 'Osyka',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pike',
    latitude: 31.0217,
    longitude: -90.479,
    postal_code: '39657'
  },
  '39661': {
    country: 'US',
    city: 'Roxie',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Franklin',
    latitude: 31.504,
    longitude: -91.0625,
    postal_code: '39661'
  },
  '39662': {
    country: 'US',
    city: 'Ruth',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lincoln',
    latitude: 31.3795,
    longitude: -90.2867,
    postal_code: '39662'
  },
  '39663': {
    country: 'US',
    city: 'Silver Creek',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lawrence',
    latitude: 31.5755,
    longitude: -90.0173,
    postal_code: '39663'
  },
  '39664': {
    country: 'US',
    city: 'Smithdale',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Amite',
    latitude: 31.3672,
    longitude: -90.6752,
    postal_code: '39664'
  },
  '39665': {
    country: 'US',
    city: 'Sontag',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lawrence',
    latitude: 31.6524,
    longitude: -90.2043,
    postal_code: '39665'
  },
  '39666': {
    country: 'US',
    city: 'Summit',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Pike',
    latitude: 31.2975,
    longitude: -90.4654,
    postal_code: '39666'
  },
  '39667': {
    country: 'US',
    city: 'Tylertown',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Walthall',
    latitude: 31.1466,
    longitude: -90.1169,
    postal_code: '39667'
  },
  '39668': {
    country: 'US',
    city: 'Union Church',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Jefferson',
    latitude: 31.7105,
    longitude: -90.8259,
    postal_code: '39668'
  },
  '39669': {
    country: 'US',
    city: 'Woodville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Wilkinson',
    latitude: 31.1459,
    longitude: -91.3076,
    postal_code: '39669'
  },
  '39701': {
    country: 'US',
    city: 'Columbus',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lowndes',
    latitude: 33.4109,
    longitude: -88.5047,
    postal_code: '39701'
  },
  '39702': {
    country: 'US',
    city: 'Columbus',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lowndes',
    latitude: 33.4812,
    longitude: -88.3554,
    postal_code: '39702'
  },
  '39703': {
    country: 'US',
    city: 'Columbus',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lowndes',
    latitude: 33.4841,
    longitude: -88.284,
    postal_code: '39703'
  },
  '39704': {
    country: 'US',
    city: 'Columbus',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lowndes',
    latitude: 33.5485,
    longitude: -88.3796,
    postal_code: '39704'
  },
  '39705': {
    country: 'US',
    city: 'Columbus',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lowndes',
    latitude: 33.5508,
    longitude: -88.4865,
    postal_code: '39705'
  },
  '39710': {
    country: 'US',
    city: 'Columbus',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lowndes',
    latitude: 33.5163,
    longitude: -88.4601,
    postal_code: '39710'
  },
  '39730': {
    country: 'US',
    city: 'Aberdeen',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Monroe',
    latitude: 33.8284,
    longitude: -88.538,
    postal_code: '39730'
  },
  '39735': {
    country: 'US',
    city: 'Ackerman',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Choctaw',
    latitude: 33.3517,
    longitude: -89.2014,
    postal_code: '39735'
  },
  '39736': {
    country: 'US',
    city: 'Artesia',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lowndes',
    latitude: 33.4115,
    longitude: -88.6443,
    postal_code: '39736'
  },
  '39737': {
    country: 'US',
    city: 'Bellefontaine',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Webster',
    latitude: 33.6487,
    longitude: -89.3101,
    postal_code: '39737'
  },
  '39739': {
    country: 'US',
    city: 'Brooksville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Noxubee',
    latitude: 33.2346,
    longitude: -88.5823,
    postal_code: '39739'
  },
  '39740': {
    country: 'US',
    city: 'Caledonia',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Monroe',
    latitude: 33.6865,
    longitude: -88.3145,
    postal_code: '39740'
  },
  '39741': {
    country: 'US',
    city: 'Cedarbluff',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Clay',
    latitude: 33.6162,
    longitude: -88.8267,
    postal_code: '39741'
  },
  '39743': {
    country: 'US',
    city: 'Crawford',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lowndes',
    latitude: 33.3219,
    longitude: -88.5671,
    postal_code: '39743'
  },
  '39744': {
    country: 'US',
    city: 'Eupora',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Webster',
    latitude: 33.5494,
    longitude: -89.2904,
    postal_code: '39744'
  },
  '39745': {
    country: 'US',
    city: 'French Camp',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Choctaw',
    latitude: 33.2898,
    longitude: -89.3999,
    postal_code: '39745'
  },
  '39746': {
    country: 'US',
    city: 'Hamilton',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Monroe',
    latitude: 33.7404,
    longitude: -88.4112,
    postal_code: '39746'
  },
  '39747': {
    country: 'US',
    city: 'Kilmichael',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Montgomery',
    latitude: 33.4165,
    longitude: -89.5696,
    postal_code: '39747'
  },
  '39750': {
    country: 'US',
    city: 'Maben',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Webster',
    latitude: 33.5686,
    longitude: -89.0605,
    postal_code: '39750'
  },
  '39751': {
    country: 'US',
    city: 'Mantee',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Calhoun',
    latitude: 33.7052,
    longitude: -89.1051,
    postal_code: '39751'
  },
  '39752': {
    country: 'US',
    city: 'Mathiston',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Webster',
    latitude: 33.5415,
    longitude: -89.1275,
    postal_code: '39752'
  },
  '39753': {
    country: 'US',
    city: 'Mayhew',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lowndes',
    latitude: 33.4838,
    longitude: -88.6409,
    postal_code: '39753'
  },
  '39754': {
    country: 'US',
    city: 'Montpelier',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Clay',
    latitude: 33.7179,
    longitude: -88.9478,
    postal_code: '39754'
  },
  '39755': {
    country: 'US',
    city: 'Pheba',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Clay',
    latitude: 33.5961,
    longitude: -88.9518,
    postal_code: '39755'
  },
  '39756': {
    country: 'US',
    city: 'Prairie',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Monroe',
    latitude: 33.7836,
    longitude: -88.6642,
    postal_code: '39756'
  },
  '39759': {
    country: 'US',
    city: 'Starkville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Oktibbeha',
    latitude: 33.4505,
    longitude: -88.8196,
    postal_code: '39759'
  },
  '39760': {
    country: 'US',
    city: 'Starkville',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Oktibbeha',
    latitude: 33.4599,
    longitude: -88.8322,
    postal_code: '39760'
  },
  '39762': {
    country: 'US',
    city: 'Mississippi State',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Oktibbeha',
    latitude: 33.4156,
    longitude: -88.7433,
    postal_code: '39762'
  },
  '39766': {
    country: 'US',
    city: 'Steens',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Lowndes',
    latitude: 33.5671,
    longitude: -88.3278,
    postal_code: '39766'
  },
  '39767': {
    country: 'US',
    city: 'Stewart',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Montgomery',
    latitude: 33.5101,
    longitude: -89.479,
    postal_code: '39767'
  },
  '39769': {
    country: 'US',
    city: 'Sturgis',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Oktibbeha',
    latitude: 33.357,
    longitude: -89.0473,
    postal_code: '39769'
  },
  '39771': {
    country: 'US',
    city: 'Walthall',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Webster',
    latitude: 33.607,
    longitude: -89.2752,
    postal_code: '39771'
  },
  '39772': {
    country: 'US',
    city: 'Weir',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Choctaw',
    latitude: 33.2645,
    longitude: -89.2804,
    postal_code: '39772'
  },
  '39773': {
    country: 'US',
    city: 'West Point',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Clay',
    latitude: 33.6076,
    longitude: -88.6503,
    postal_code: '39773'
  },
  '39776': {
    country: 'US',
    city: 'Woodland',
    state: 'Mississippi',
    state_short: 'MS',
    county: 'Chickasaw',
    latitude: 33.8066,
    longitude: -89.0256,
    postal_code: '39776'
  },
  '39813': {
    country: 'US',
    city: 'Arlington',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Columbia',
    latitude: 31.442,
    longitude: -84.7241,
    postal_code: '39813'
  },
  '39815': {
    country: 'US',
    city: 'Attapulgus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Decatur',
    latitude: 30.7493,
    longitude: -84.4846,
    postal_code: '39815'
  },
  '39817': {
    country: 'US',
    city: 'Bainbridge',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Miller',
    latitude: 30.9381,
    longitude: -84.5934,
    postal_code: '39817'
  },
  '39818': {
    country: 'US',
    city: 'Bainbridge',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Decatur',
    latitude: 30.8845,
    longitude: -84.5655,
    postal_code: '39818'
  },
  '39819': {
    country: 'US',
    city: 'Bainbridge',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Decatur',
    latitude: 30.9039,
    longitude: -84.569,
    postal_code: '39819'
  },
  '39823': {
    country: 'US',
    city: 'Blakely',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clay',
    latitude: 31.3791,
    longitude: -84.9353,
    postal_code: '39823'
  },
  '39824': {
    country: 'US',
    city: 'Bluffton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clay',
    latitude: 31.52,
    longitude: -84.867,
    postal_code: '39824'
  },
  '39825': {
    country: 'US',
    city: 'Brinson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Seminole',
    latitude: 30.9788,
    longitude: -84.7373,
    postal_code: '39825'
  },
  '39826': {
    country: 'US',
    city: 'Bronwood',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Terrell',
    latitude: 31.8308,
    longitude: -84.364,
    postal_code: '39826'
  },
  '39827': {
    country: 'US',
    city: 'Cairo',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Grady',
    latitude: 30.9528,
    longitude: -84.2069,
    postal_code: '39827'
  },
  '39828': {
    country: 'US',
    city: 'Cairo',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Terrell',
    latitude: 30.8768,
    longitude: -84.2091,
    postal_code: '39828'
  },
  '39829': {
    country: 'US',
    city: 'Calvary',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Grady',
    latitude: 30.7462,
    longitude: -84.3125,
    postal_code: '39829'
  },
  '39832': {
    country: 'US',
    city: 'Cedar Springs',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Early',
    latitude: 31.1745,
    longitude: -85.037,
    postal_code: '39832'
  },
  '39834': {
    country: 'US',
    city: 'Climax',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Early',
    latitude: 30.8757,
    longitude: -84.445,
    postal_code: '39834'
  },
  '39836': {
    country: 'US',
    city: 'Coleman',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clay',
    latitude: 31.6726,
    longitude: -84.8903,
    postal_code: '39836'
  },
  '39837': {
    country: 'US',
    city: 'Colquitt',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Baker',
    latitude: 31.173,
    longitude: -84.731,
    postal_code: '39837'
  },
  '39840': {
    country: 'US',
    city: 'Cuthbert',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Ben Hill',
    latitude: 31.7706,
    longitude: -84.7936,
    postal_code: '39840'
  },
  '39841': {
    country: 'US',
    city: 'Damascus',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Baker',
    latitude: 31.2984,
    longitude: -84.7175,
    postal_code: '39841'
  },
  '39842': {
    country: 'US',
    city: 'Dawson',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Calhoun',
    latitude: 31.7703,
    longitude: -84.5238,
    postal_code: '39842'
  },
  '39845': {
    country: 'US',
    city: 'Donalsonville',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Baker',
    latitude: 31.0505,
    longitude: -84.8801,
    postal_code: '39845'
  },
  '39846': {
    country: 'US',
    city: 'Edison',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clay',
    latitude: 31.5607,
    longitude: -84.7381,
    postal_code: '39846'
  },
  '39851': {
    country: 'US',
    city: 'Fort Gaines',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clay',
    latitude: 31.6264,
    longitude: -85.0548,
    postal_code: '39851'
  },
  '39852': {
    country: 'US',
    city: 'Fowlstown',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Decatur',
    latitude: 30.8024,
    longitude: -84.5471,
    postal_code: '39852'
  },
  '39854': {
    country: 'US',
    city: 'Georgetown',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clay',
    latitude: 31.8854,
    longitude: -85.1059,
    postal_code: '39854'
  },
  '39859': {
    country: 'US',
    city: 'Iron City',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Seminole',
    latitude: 31.013,
    longitude: -84.8138,
    postal_code: '39859'
  },
  '39861': {
    country: 'US',
    city: 'Jakin',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Seminole',
    latitude: 31.1824,
    longitude: -85.0121,
    postal_code: '39861'
  },
  '39862': {
    country: 'US',
    city: 'Leary',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Baker',
    latitude: 31.4849,
    longitude: -84.5128,
    postal_code: '39862'
  },
  '39866': {
    country: 'US',
    city: 'Morgan',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Calhoun',
    latitude: 31.5374,
    longitude: -84.5994,
    postal_code: '39866'
  },
  '39867': {
    country: 'US',
    city: 'Morris',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Clay',
    latitude: 31.8343,
    longitude: -84.9255,
    postal_code: '39867'
  },
  '39870': {
    country: 'US',
    city: 'Newton',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Baker',
    latitude: 31.3164,
    longitude: -84.3367,
    postal_code: '39870'
  },
  '39877': {
    country: 'US',
    city: 'Parrott',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Webster',
    latitude: 31.8939,
    longitude: -84.5112,
    postal_code: '39877'
  },
  '39885': {
    country: 'US',
    city: 'Sasser',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Terrell',
    latitude: 31.7191,
    longitude: -84.3479,
    postal_code: '39885'
  },
  '39886': {
    country: 'US',
    city: 'Shellman',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Calhoun',
    latitude: 31.7608,
    longitude: -84.6092,
    postal_code: '39886'
  },
  '39897': {
    country: 'US',
    city: 'Whigham',
    state: 'Georgia',
    state_short: 'GA',
    county: 'Grady',
    latitude: 30.8836,
    longitude: -84.3246,
    postal_code: '39897'
  },
  '39901': {
    country: 'US',
    city: 'Atlanta',
    state: 'Georgia',
    state_short: 'GA',
    county: 'DeKalb',
    latitude: 33.8913,
    longitude: -84.0746,
    postal_code: '39901'
  },
  '40003': {
    country: 'US',
    city: 'Bagdad',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Shelby',
    latitude: 38.2608,
    longitude: -85.0651,
    postal_code: '40003'
  },
  '40004': {
    country: 'US',
    city: 'Bardstown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nelson',
    latitude: 37.8083,
    longitude: -85.4613,
    postal_code: '40004'
  },
  '40006': {
    country: 'US',
    city: 'Bedford',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Trimble',
    latitude: 38.5864,
    longitude: -85.3133,
    postal_code: '40006'
  },
  '40007': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.4529,
    longitude: -85.0169,
    postal_code: '40007'
  },
  '40008': {
    country: 'US',
    city: 'Bloomfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nelson',
    latitude: 37.908,
    longitude: -85.2862,
    postal_code: '40008'
  },
  '40009': {
    country: 'US',
    city: 'Bradfordsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marion',
    latitude: 37.4662,
    longitude: -85.1436,
    postal_code: '40009'
  },
  '40010': {
    country: 'US',
    city: 'Buckner',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Oldham',
    latitude: 38.3735,
    longitude: -85.4507,
    postal_code: '40010'
  },
  '40011': {
    country: 'US',
    city: 'Campbellsburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.5231,
    longitude: -85.1611,
    postal_code: '40011'
  },
  '40012': {
    country: 'US',
    city: 'Chaplin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nelson',
    latitude: 37.9025,
    longitude: -85.2015,
    postal_code: '40012'
  },
  '40013': {
    country: 'US',
    city: 'Coxs Creek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nelson',
    latitude: 37.9125,
    longitude: -85.4658,
    postal_code: '40013'
  },
  '40014': {
    country: 'US',
    city: 'Crestwood',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Oldham',
    latitude: 38.3326,
    longitude: -85.461,
    postal_code: '40014'
  },
  '40018': {
    country: 'US',
    city: 'Eastwood',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2331,
    longitude: -85.4558,
    postal_code: '40018'
  },
  '40019': {
    country: 'US',
    city: 'Eminence',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.3696,
    longitude: -85.1782,
    postal_code: '40019'
  },
  '40020': {
    country: 'US',
    city: 'Fairfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nelson',
    latitude: 37.934,
    longitude: -85.3862,
    postal_code: '40020'
  },
  '40022': {
    country: 'US',
    city: 'Finchville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Shelby',
    latitude: 38.1561,
    longitude: -85.3476,
    postal_code: '40022'
  },
  '40023': {
    country: 'US',
    city: 'Fisherville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1652,
    longitude: -85.4282,
    postal_code: '40023'
  },
  '40025': {
    country: 'US',
    city: 'Glenview',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2997,
    longitude: -85.6487,
    postal_code: '40025'
  },
  '40026': {
    country: 'US',
    city: 'Goshen',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Oldham',
    latitude: 38.4113,
    longitude: -85.5708,
    postal_code: '40026'
  },
  '40027': {
    country: 'US',
    city: 'Harrods Creek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.3297,
    longitude: -85.633,
    postal_code: '40027'
  },
  '40031': {
    country: 'US',
    city: 'La Grange',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Oldham',
    latitude: 38.4029,
    longitude: -85.3928,
    postal_code: '40031'
  },
  '40032': {
    country: 'US',
    city: 'La Grange',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Oldham',
    latitude: 38.4046,
    longitude: -85.4605,
    postal_code: '40032'
  },
  '40033': {
    country: 'US',
    city: 'Lebanon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marion',
    latitude: 37.5658,
    longitude: -85.2668,
    postal_code: '40033'
  },
  '40036': {
    country: 'US',
    city: 'Lockport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.4219,
    longitude: -84.9586,
    postal_code: '40036'
  },
  '40037': {
    country: 'US',
    city: 'Loretto',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marion',
    latitude: 37.6421,
    longitude: -85.4113,
    postal_code: '40037'
  },
  '40040': {
    country: 'US',
    city: 'Mackville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Washington',
    latitude: 37.7568,
    longitude: -85.059,
    postal_code: '40040'
  },
  '40041': {
    country: 'US',
    city: 'Masonic Home',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2536,
    longitude: -85.6622,
    postal_code: '40041'
  },
  '40045': {
    country: 'US',
    city: 'Milton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Trimble',
    latitude: 38.6925,
    longitude: -85.3659,
    postal_code: '40045'
  },
  '40046': {
    country: 'US',
    city: 'Mount Eden',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Spencer',
    latitude: 38.0352,
    longitude: -85.1641,
    postal_code: '40046'
  },
  '40047': {
    country: 'US',
    city: 'Mount Washington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bullitt',
    latitude: 38.0452,
    longitude: -85.5586,
    postal_code: '40047'
  },
  '40048': {
    country: 'US',
    city: 'Nazareth',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nelson',
    latitude: 37.8472,
    longitude: -85.4694,
    postal_code: '40048'
  },
  '40049': {
    country: 'US',
    city: 'Nerinx',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marion',
    latitude: 37.6603,
    longitude: -85.3932,
    postal_code: '40049'
  },
  '40050': {
    country: 'US',
    city: 'New Castle',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.4374,
    longitude: -85.1756,
    postal_code: '40050'
  },
  '40051': {
    country: 'US',
    city: 'New Haven',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nelson',
    latitude: 37.6438,
    longitude: -85.547,
    postal_code: '40051'
  },
  '40052': {
    country: 'US',
    city: 'New Hope',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nelson',
    latitude: 37.632,
    longitude: -85.5083,
    postal_code: '40052'
  },
  '40055': {
    country: 'US',
    city: 'Pendleton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.4809,
    longitude: -85.317,
    postal_code: '40055'
  },
  '40056': {
    country: 'US',
    city: 'Pewee Valley',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Oldham',
    latitude: 38.3039,
    longitude: -85.4834,
    postal_code: '40056'
  },
  '40057': {
    country: 'US',
    city: 'Pleasureville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.3598,
    longitude: -85.1072,
    postal_code: '40057'
  },
  '40058': {
    country: 'US',
    city: 'Port Royal',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.4941,
    longitude: -85.1242,
    postal_code: '40058'
  },
  '40059': {
    country: 'US',
    city: 'Prospect',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.356,
    longitude: -85.6083,
    postal_code: '40059'
  },
  '40060': {
    country: 'US',
    city: 'Raywick',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marion',
    latitude: 37.538,
    longitude: -85.43,
    postal_code: '40060'
  },
  '40061': {
    country: 'US',
    city: 'Saint Catharine',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Washington',
    latitude: 37.774,
    longitude: -85.2011,
    postal_code: '40061'
  },
  '40062': {
    country: 'US',
    city: 'Saint Francis',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marion',
    latitude: 37.6047,
    longitude: -85.4251,
    postal_code: '40062'
  },
  '40063': {
    country: 'US',
    city: 'Saint Mary',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marion',
    latitude: 37.5795,
    longitude: -85.3551,
    postal_code: '40063'
  },
  '40065': {
    country: 'US',
    city: 'Shelbyville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Shelby',
    latitude: 38.2162,
    longitude: -85.2243,
    postal_code: '40065'
  },
  '40066': {
    country: 'US',
    city: 'Shelbyville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Shelby',
    latitude: 38.1973,
    longitude: -85.2122,
    postal_code: '40066'
  },
  '40067': {
    country: 'US',
    city: 'Simpsonville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Shelby',
    latitude: 38.2312,
    longitude: -85.3548,
    postal_code: '40067'
  },
  '40068': {
    country: 'US',
    city: 'Smithfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.3933,
    longitude: -85.2656,
    postal_code: '40068'
  },
  '40069': {
    country: 'US',
    city: 'Springfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Washington',
    latitude: 37.7342,
    longitude: -85.2107,
    postal_code: '40069'
  },
  '40070': {
    country: 'US',
    city: 'Sulphur',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.4921,
    longitude: -85.252,
    postal_code: '40070'
  },
  '40071': {
    country: 'US',
    city: 'Taylorsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Spencer',
    latitude: 38.0471,
    longitude: -85.3829,
    postal_code: '40071'
  },
  '40075': {
    country: 'US',
    city: 'Turners Station',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henry',
    latitude: 38.5521,
    longitude: -85.1019,
    postal_code: '40075'
  },
  '40076': {
    country: 'US',
    city: 'Waddy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Shelby',
    latitude: 38.1054,
    longitude: -85.1288,
    postal_code: '40076'
  },
  '40077': {
    country: 'US',
    city: 'Westport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Oldham',
    latitude: 38.4921,
    longitude: -85.4524,
    postal_code: '40077'
  },
  '40078': {
    country: 'US',
    city: 'Willisburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Washington',
    latitude: 37.8373,
    longitude: -85.1362,
    postal_code: '40078'
  },
  '40104': {
    country: 'US',
    city: 'Battletown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Meade',
    latitude: 38.0393,
    longitude: -86.2991,
    postal_code: '40104'
  },
  '40107': {
    country: 'US',
    city: 'Boston',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nelson',
    latitude: 37.7408,
    longitude: -85.5948,
    postal_code: '40107'
  },
  '40108': {
    country: 'US',
    city: 'Brandenburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Meade',
    latitude: 37.9662,
    longitude: -86.1084,
    postal_code: '40108'
  },
  '40109': {
    country: 'US',
    city: 'Brooks',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bullitt',
    latitude: 38.0546,
    longitude: -85.7713,
    postal_code: '40109'
  },
  '40110': {
    country: 'US',
    city: 'Clermont',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bullitt',
    latitude: 37.9346,
    longitude: -85.6555,
    postal_code: '40110'
  },
  '40111': {
    country: 'US',
    city: 'Cloverport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.7731,
    longitude: -86.6282,
    postal_code: '40111'
  },
  '40115': {
    country: 'US',
    city: 'Custer',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.7358,
    longitude: -86.2378,
    postal_code: '40115'
  },
  '40117': {
    country: 'US',
    city: 'Ekron',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Meade',
    latitude: 37.9113,
    longitude: -86.1542,
    postal_code: '40117'
  },
  '40118': {
    country: 'US',
    city: 'Fairdale',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1087,
    longitude: -85.7549,
    postal_code: '40118'
  },
  '40119': {
    country: 'US',
    city: 'Falls Of Rough',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grayson',
    latitude: 37.5522,
    longitude: -86.4836,
    postal_code: '40119'
  },
  '40121': {
    country: 'US',
    city: 'Fort Knox',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.8928,
    longitude: -85.9489,
    postal_code: '40121'
  },
  '40122': {
    country: 'US',
    city: 'Fort Knox',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.8911,
    longitude: -85.9636,
    postal_code: '40122'
  },
  '40129': {
    country: 'US',
    city: 'Hillview',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bullitt',
    latitude: 38.0764,
    longitude: -85.6774,
    postal_code: '40129'
  },
  '40140': {
    country: 'US',
    city: 'Garfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.7828,
    longitude: -86.3575,
    postal_code: '40140'
  },
  '40142': {
    country: 'US',
    city: 'Guston',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Meade',
    latitude: 37.8951,
    longitude: -86.2155,
    postal_code: '40142'
  },
  '40143': {
    country: 'US',
    city: 'Hardinsburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.7512,
    longitude: -86.4537,
    postal_code: '40143'
  },
  '40144': {
    country: 'US',
    city: 'Harned',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.8023,
    longitude: -86.4148,
    postal_code: '40144'
  },
  '40145': {
    country: 'US',
    city: 'Hudson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.6506,
    longitude: -86.3012,
    postal_code: '40145'
  },
  '40146': {
    country: 'US',
    city: 'Irvington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.8762,
    longitude: -86.2965,
    postal_code: '40146'
  },
  '40150': {
    country: 'US',
    city: 'Lebanon Junction',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bullitt',
    latitude: 37.8511,
    longitude: -85.7246,
    postal_code: '40150'
  },
  '40152': {
    country: 'US',
    city: 'Mc Daniels',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.6194,
    longitude: -86.4491,
    postal_code: '40152'
  },
  '40153': {
    country: 'US',
    city: 'Mc Quady',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.8023,
    longitude: -86.4148,
    postal_code: '40153'
  },
  '40155': {
    country: 'US',
    city: 'Muldraugh',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Meade',
    latitude: 37.9371,
    longitude: -85.9918,
    postal_code: '40155'
  },
  '40157': {
    country: 'US',
    city: 'Payneville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Meade',
    latitude: 38.0301,
    longitude: -86.4082,
    postal_code: '40157'
  },
  '40159': {
    country: 'US',
    city: 'Radcliff',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.7235,
    longitude: -85.9769,
    postal_code: '40159'
  },
  '40160': {
    country: 'US',
    city: 'Radcliff',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.8267,
    longitude: -85.9404,
    postal_code: '40160'
  },
  '40161': {
    country: 'US',
    city: 'Rhodelia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Meade',
    latitude: 38.006,
    longitude: -86.3984,
    postal_code: '40161'
  },
  '40162': {
    country: 'US',
    city: 'Rineyville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.7525,
    longitude: -85.9954,
    postal_code: '40162'
  },
  '40165': {
    country: 'US',
    city: 'Shepherdsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bullitt',
    latitude: 38.0045,
    longitude: -85.6888,
    postal_code: '40165'
  },
  '40166': {
    country: 'US',
    city: 'Shepherdsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bullitt',
    latitude: 37.9885,
    longitude: -85.7159,
    postal_code: '40166'
  },
  '40170': {
    country: 'US',
    city: 'Stephensport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.9048,
    longitude: -86.524,
    postal_code: '40170'
  },
  '40171': {
    country: 'US',
    city: 'Union Star',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.9616,
    longitude: -86.4615,
    postal_code: '40171'
  },
  '40175': {
    country: 'US',
    city: 'Vine Grove',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.8589,
    longitude: -86.0069,
    postal_code: '40175'
  },
  '40176': {
    country: 'US',
    city: 'Webster',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.9237,
    longitude: -86.3441,
    postal_code: '40176'
  },
  '40177': {
    country: 'US',
    city: 'West Point',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.9954,
    longitude: -85.9545,
    postal_code: '40177'
  },
  '40178': {
    country: 'US',
    city: 'Westview',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breckinridge',
    latitude: 37.6792,
    longitude: -86.4273,
    postal_code: '40178'
  },
  '40201': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40201'
  },
  '40202': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2507,
    longitude: -85.7476,
    postal_code: '40202'
  },
  '40203': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2493,
    longitude: -85.7694,
    postal_code: '40203'
  },
  '40204': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2369,
    longitude: -85.7249,
    postal_code: '40204'
  },
  '40205': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2222,
    longitude: -85.6885,
    postal_code: '40205'
  },
  '40206': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2503,
    longitude: -85.7034,
    postal_code: '40206'
  },
  '40207': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2628,
    longitude: -85.6663,
    postal_code: '40207'
  },
  '40208': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.22,
    longitude: -85.7648,
    postal_code: '40208'
  },
  '40209': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1901,
    longitude: -85.7519,
    postal_code: '40209'
  },
  '40210': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2306,
    longitude: -85.7905,
    postal_code: '40210'
  },
  '40211': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.242,
    longitude: -85.8127,
    postal_code: '40211'
  },
  '40212': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2651,
    longitude: -85.8045,
    postal_code: '40212'
  },
  '40213': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1839,
    longitude: -85.7106,
    postal_code: '40213'
  },
  '40214': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1593,
    longitude: -85.778,
    postal_code: '40214'
  },
  '40215': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1913,
    longitude: -85.7847,
    postal_code: '40215'
  },
  '40216': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1865,
    longitude: -85.8335,
    postal_code: '40216'
  },
  '40217': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2174,
    longitude: -85.7404,
    postal_code: '40217'
  },
  '40218': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.654,
    postal_code: '40218'
  },
  '40219': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1381,
    longitude: -85.6953,
    postal_code: '40219'
  },
  '40220': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2149,
    longitude: -85.6245,
    postal_code: '40220'
  },
  '40221': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40221'
  },
  '40222': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2674,
    longitude: -85.6237,
    postal_code: '40222'
  },
  '40223': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2651,
    longitude: -85.5582,
    postal_code: '40223'
  },
  '40224': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40224'
  },
  '40225': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40225'
  },
  '40228': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1442,
    longitude: -85.6265,
    postal_code: '40228'
  },
  '40229': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.0891,
    longitude: -85.6548,
    postal_code: '40229'
  },
  '40231': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40231'
  },
  '40232': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40232'
  },
  '40233': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40233'
  },
  '40241': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.3045,
    longitude: -85.5815,
    postal_code: '40241'
  },
  '40242': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2785,
    longitude: -85.594,
    postal_code: '40242'
  },
  '40243': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2422,
    longitude: -85.5353,
    postal_code: '40243'
  },
  '40245': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2683,
    longitude: -85.4845,
    postal_code: '40245'
  },
  '40250': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40250'
  },
  '40251': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40251'
  },
  '40252': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40252'
  },
  '40253': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40253'
  },
  '40255': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40255'
  },
  '40256': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40256'
  },
  '40257': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40257'
  },
  '40258': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1457,
    longitude: -85.8641,
    postal_code: '40258'
  },
  '40259': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2542,
    longitude: -85.7594,
    postal_code: '40259'
  },
  '40261': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40261'
  },
  '40266': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40266'
  },
  '40268': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40268'
  },
  '40269': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40269'
  },
  '40270': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2542,
    longitude: -85.7594,
    postal_code: '40270'
  },
  '40272': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.0846,
    longitude: -85.851,
    postal_code: '40272'
  },
  '40280': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2467,
    longitude: -85.6853,
    postal_code: '40280'
  },
  '40281': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40281'
  },
  '40282': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40282'
  },
  '40283': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40283'
  },
  '40285': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40285'
  },
  '40287': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40287'
  },
  '40289': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2542,
    longitude: -85.7594,
    postal_code: '40289'
  },
  '40290': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40290'
  },
  '40291': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1313,
    longitude: -85.5754,
    postal_code: '40291'
  },
  '40292': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.189,
    longitude: -85.6768,
    postal_code: '40292'
  },
  '40293': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2542,
    longitude: -85.7594,
    postal_code: '40293'
  },
  '40294': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2542,
    longitude: -85.7594,
    postal_code: '40294'
  },
  '40295': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2542,
    longitude: -85.7594,
    postal_code: '40295'
  },
  '40296': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2542,
    longitude: -85.7594,
    postal_code: '40296'
  },
  '40297': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2542,
    longitude: -85.7594,
    postal_code: '40297'
  },
  '40298': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.2542,
    longitude: -85.7594,
    postal_code: '40298'
  },
  '40299': {
    country: 'US',
    city: 'Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jefferson',
    latitude: 38.1768,
    longitude: -85.5218,
    postal_code: '40299'
  },
  '40310': {
    country: 'US',
    city: 'Burgin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Mercer',
    latitude: 37.7534,
    longitude: -84.7666,
    postal_code: '40310'
  },
  '40311': {
    country: 'US',
    city: 'Carlisle',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nicholas',
    latitude: 38.3212,
    longitude: -84.0279,
    postal_code: '40311'
  },
  '40312': {
    country: 'US',
    city: 'Clay City',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Powell',
    latitude: 37.8524,
    longitude: -83.9309,
    postal_code: '40312'
  },
  '40313': {
    country: 'US',
    city: 'Clearfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Rowan',
    latitude: 38.1287,
    longitude: -83.4425,
    postal_code: '40313'
  },
  '40316': {
    country: 'US',
    city: 'Denniston',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Menifee',
    latitude: 37.9159,
    longitude: -83.538,
    postal_code: '40316'
  },
  '40317': {
    country: 'US',
    city: 'Elliottville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Rowan',
    latitude: 38.1765,
    longitude: -83.2682,
    postal_code: '40317'
  },
  '40319': {
    country: 'US',
    city: 'Farmers',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Rowan',
    latitude: 38.1403,
    longitude: -83.5337,
    postal_code: '40319'
  },
  '40322': {
    country: 'US',
    city: 'Frenchburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Menifee',
    latitude: 37.947,
    longitude: -83.6084,
    postal_code: '40322'
  },
  '40324': {
    country: 'US',
    city: 'Georgetown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Scott',
    latitude: 38.2117,
    longitude: -84.5562,
    postal_code: '40324'
  },
  '40328': {
    country: 'US',
    city: 'Gravel Switch',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marion',
    latitude: 37.5758,
    longitude: -85.0824,
    postal_code: '40328'
  },
  '40330': {
    country: 'US',
    city: 'Harrodsburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Mercer',
    latitude: 37.8033,
    longitude: -84.8607,
    postal_code: '40330'
  },
  '40334': {
    country: 'US',
    city: 'Hope',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Montgomery',
    latitude: 38.0161,
    longitude: -83.7713,
    postal_code: '40334'
  },
  '40336': {
    country: 'US',
    city: 'Irvine',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Estill',
    latitude: 37.6858,
    longitude: -83.9862,
    postal_code: '40336'
  },
  '40337': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Montgomery',
    latitude: 37.964,
    longitude: -83.8558,
    postal_code: '40337'
  },
  '40339': {
    country: 'US',
    city: 'Keene',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jessamine',
    latitude: 37.9434,
    longitude: -84.6419,
    postal_code: '40339'
  },
  '40340': {
    country: 'US',
    city: 'Nicholasville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jessamine',
    latitude: 37.8662,
    longitude: -84.5795,
    postal_code: '40340'
  },
  '40342': {
    country: 'US',
    city: 'Lawrenceburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Anderson',
    latitude: 38.0189,
    longitude: -84.9299,
    postal_code: '40342'
  },
  '40346': {
    country: 'US',
    city: 'Means',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Menifee',
    latitude: 37.9807,
    longitude: -83.7447,
    postal_code: '40346'
  },
  '40347': {
    country: 'US',
    city: 'Midway',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Woodford',
    latitude: 38.1487,
    longitude: -84.6928,
    postal_code: '40347'
  },
  '40348': {
    country: 'US',
    city: 'Millersburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bourbon',
    latitude: 38.2963,
    longitude: -84.1513,
    postal_code: '40348'
  },
  '40350': {
    country: 'US',
    city: 'Moorefield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Nicholas',
    latitude: 38.2926,
    longitude: -83.8929,
    postal_code: '40350'
  },
  '40351': {
    country: 'US',
    city: 'Morehead',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Rowan',
    latitude: 38.199,
    longitude: -83.4436,
    postal_code: '40351'
  },
  '40353': {
    country: 'US',
    city: 'Mount Sterling',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Montgomery',
    latitude: 38.0548,
    longitude: -83.9388,
    postal_code: '40353'
  },
  '40355': {
    country: 'US',
    city: 'New Liberty',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Owen',
    latitude: 38.6277,
    longitude: -84.8756,
    postal_code: '40355'
  },
  '40356': {
    country: 'US',
    city: 'Nicholasville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jessamine',
    latitude: 37.8808,
    longitude: -84.5646,
    postal_code: '40356'
  },
  '40357': {
    country: 'US',
    city: 'North Middletown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bourbon',
    latitude: 38.1464,
    longitude: -84.1077,
    postal_code: '40357'
  },
  '40358': {
    country: 'US',
    city: 'Olympia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bath',
    latitude: 38.0849,
    longitude: -83.7008,
    postal_code: '40358'
  },
  '40359': {
    country: 'US',
    city: 'Owenton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Owen',
    latitude: 38.4986,
    longitude: -84.8086,
    postal_code: '40359'
  },
  '40360': {
    country: 'US',
    city: 'Owingsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bath',
    latitude: 38.1532,
    longitude: -83.7564,
    postal_code: '40360'
  },
  '40361': {
    country: 'US',
    city: 'Paris',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bourbon',
    latitude: 38.2083,
    longitude: -84.245,
    postal_code: '40361'
  },
  '40362': {
    country: 'US',
    city: 'Paris',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bourbon',
    latitude: 38.2098,
    longitude: -84.253,
    postal_code: '40362'
  },
  '40363': {
    country: 'US',
    city: 'Perry Park',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Owen',
    latitude: 38.5218,
    longitude: -85.0139,
    postal_code: '40363'
  },
  '40366': {
    country: 'US',
    city: 'Preston',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bath',
    latitude: 38.0867,
    longitude: -83.7571,
    postal_code: '40366'
  },
  '40370': {
    country: 'US',
    city: 'Sadieville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Scott',
    latitude: 38.3908,
    longitude: -84.5384,
    postal_code: '40370'
  },
  '40371': {
    country: 'US',
    city: 'Salt Lick',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bath',
    latitude: 38.104,
    longitude: -83.6316,
    postal_code: '40371'
  },
  '40372': {
    country: 'US',
    city: 'Salvisa',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Mercer',
    latitude: 37.9167,
    longitude: -84.8577,
    postal_code: '40372'
  },
  '40374': {
    country: 'US',
    city: 'Sharpsburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bath',
    latitude: 38.2147,
    longitude: -83.8932,
    postal_code: '40374'
  },
  '40376': {
    country: 'US',
    city: 'Slade',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Powell',
    latitude: 37.7951,
    longitude: -83.7041,
    postal_code: '40376'
  },
  '40379': {
    country: 'US',
    city: 'Stamping Ground',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Scott',
    latitude: 38.2888,
    longitude: -84.6818,
    postal_code: '40379'
  },
  '40380': {
    country: 'US',
    city: 'Stanton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Powell',
    latitude: 37.8223,
    longitude: -83.7853,
    postal_code: '40380'
  },
  '40383': {
    country: 'US',
    city: 'Versailles',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Woodford',
    latitude: 38.0413,
    longitude: -84.7287,
    postal_code: '40383'
  },
  '40384': {
    country: 'US',
    city: 'Versailles',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Woodford',
    latitude: 38.0526,
    longitude: -84.7299,
    postal_code: '40384'
  },
  '40385': {
    country: 'US',
    city: 'Waco',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Madison',
    latitude: 37.7426,
    longitude: -84.1441,
    postal_code: '40385'
  },
  '40387': {
    country: 'US',
    city: 'Wellington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Menifee',
    latitude: 37.978,
    longitude: -83.4675,
    postal_code: '40387'
  },
  '40390': {
    country: 'US',
    city: 'Wilmore',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jessamine',
    latitude: 37.8602,
    longitude: -84.6714,
    postal_code: '40390'
  },
  '40391': {
    country: 'US',
    city: 'Winchester',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clark',
    latitude: 37.9872,
    longitude: -84.1789,
    postal_code: '40391'
  },
  '40392': {
    country: 'US',
    city: 'Winchester',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clark',
    latitude: 37.9901,
    longitude: -84.1797,
    postal_code: '40392'
  },
  '40402': {
    country: 'US',
    city: 'Annville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jackson',
    latitude: 37.3052,
    longitude: -83.9711,
    postal_code: '40402'
  },
  '40403': {
    country: 'US',
    city: 'Berea',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Madison',
    latitude: 37.5799,
    longitude: -84.2749,
    postal_code: '40403'
  },
  '40404': {
    country: 'US',
    city: 'Berea',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Madison',
    latitude: 37.5687,
    longitude: -84.2963,
    postal_code: '40404'
  },
  '40405': {
    country: 'US',
    city: 'Bighill',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Madison',
    latitude: 37.5545,
    longitude: -84.2083,
    postal_code: '40405'
  },
  '40409': {
    country: 'US',
    city: 'Brodhead',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Rockcastle',
    latitude: 37.3815,
    longitude: -84.4336,
    postal_code: '40409'
  },
  '40410': {
    country: 'US',
    city: 'Bryantsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Garrard',
    latitude: 37.7145,
    longitude: -84.6491,
    postal_code: '40410'
  },
  '40419': {
    country: 'US',
    city: 'Crab Orchard',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lincoln',
    latitude: 37.4465,
    longitude: -84.4939,
    postal_code: '40419'
  },
  '40422': {
    country: 'US',
    city: 'Danville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyle',
    latitude: 37.6465,
    longitude: -84.7747,
    postal_code: '40422'
  },
  '40423': {
    country: 'US',
    city: 'Danville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyle',
    latitude: 37.6456,
    longitude: -84.7722,
    postal_code: '40423'
  },
  '40434': {
    country: 'US',
    city: 'Gray Hawk',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jackson',
    latitude: 37.3951,
    longitude: -83.9402,
    postal_code: '40434'
  },
  '40437': {
    country: 'US',
    city: 'Hustonville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lincoln',
    latitude: 37.4595,
    longitude: -84.8528,
    postal_code: '40437'
  },
  '40440': {
    country: 'US',
    city: 'Junction City',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyle',
    latitude: 37.5822,
    longitude: -84.8028,
    postal_code: '40440'
  },
  '40442': {
    country: 'US',
    city: 'Kings Mountain',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lincoln',
    latitude: 37.3818,
    longitude: -84.7148,
    postal_code: '40442'
  },
  '40444': {
    country: 'US',
    city: 'Lancaster',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Garrard',
    latitude: 37.6584,
    longitude: -84.5969,
    postal_code: '40444'
  },
  '40445': {
    country: 'US',
    city: 'Livingston',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Rockcastle',
    latitude: 37.3074,
    longitude: -84.2318,
    postal_code: '40445'
  },
  '40447': {
    country: 'US',
    city: 'Mc Kee',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jackson',
    latitude: 37.4338,
    longitude: -84.0251,
    postal_code: '40447'
  },
  '40448': {
    country: 'US',
    city: 'Mc Kinney',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lincoln',
    latitude: 37.446,
    longitude: -84.6998,
    postal_code: '40448'
  },
  '40452': {
    country: 'US',
    city: 'Mitchellsburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyle',
    latitude: 37.6033,
    longitude: -84.9492,
    postal_code: '40452'
  },
  '40456': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Rockcastle',
    latitude: 37.3983,
    longitude: -84.3379,
    postal_code: '40456'
  },
  '40460': {
    country: 'US',
    city: 'Orlando',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Rockcastle',
    latitude: 37.3734,
    longitude: -84.2528,
    postal_code: '40460'
  },
  '40461': {
    country: 'US',
    city: 'Paint Lick',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Garrard',
    latitude: 37.6092,
    longitude: -84.4269,
    postal_code: '40461'
  },
  '40464': {
    country: 'US',
    city: 'Parksville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyle',
    latitude: 37.5778,
    longitude: -84.9281,
    postal_code: '40464'
  },
  '40468': {
    country: 'US',
    city: 'Perryville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyle',
    latitude: 37.6375,
    longitude: -84.9665,
    postal_code: '40468'
  },
  '40472': {
    country: 'US',
    city: 'Ravenna',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Estill',
    latitude: 37.6867,
    longitude: -83.9387,
    postal_code: '40472'
  },
  '40473': {
    country: 'US',
    city: 'Renfro Valley',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Rockcastle',
    latitude: 37.3879,
    longitude: -84.3316,
    postal_code: '40473'
  },
  '40475': {
    country: 'US',
    city: 'Richmond',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Madison',
    latitude: 37.7546,
    longitude: -84.2955,
    postal_code: '40475'
  },
  '40476': {
    country: 'US',
    city: 'Richmond',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Madison',
    latitude: 37.7479,
    longitude: -84.2947,
    postal_code: '40476'
  },
  '40481': {
    country: 'US',
    city: 'Sandgap',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jackson',
    latitude: 37.4862,
    longitude: -84.0905,
    postal_code: '40481'
  },
  '40484': {
    country: 'US',
    city: 'Stanford',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lincoln',
    latitude: 37.5245,
    longitude: -84.6912,
    postal_code: '40484'
  },
  '40486': {
    country: 'US',
    city: 'Tyner',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jackson',
    latitude: 37.3693,
    longitude: -83.8619,
    postal_code: '40486'
  },
  '40488': {
    country: 'US',
    city: 'Waneta',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Jackson',
    latitude: 37.4748,
    longitude: -84.0427,
    postal_code: '40488'
  },
  '40489': {
    country: 'US',
    city: 'Waynesburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lincoln',
    latitude: 37.3499,
    longitude: -84.6655,
    postal_code: '40489'
  },
  '40492': {
    country: 'US',
    city: 'Wildie',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Rockcastle',
    latitude: 37.4234,
    longitude: -84.3022,
    postal_code: '40492'
  },
  '40502': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0174,
    longitude: -84.4854,
    postal_code: '40502'
  },
  '40503': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.001,
    longitude: -84.5282,
    postal_code: '40503'
  },
  '40504': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0406,
    longitude: -84.5433,
    postal_code: '40504'
  },
  '40505': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0612,
    longitude: -84.4583,
    postal_code: '40505'
  },
  '40506': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0287,
    longitude: -84.5075,
    postal_code: '40506'
  },
  '40507': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0464,
    longitude: -84.4953,
    postal_code: '40507'
  },
  '40508': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0513,
    longitude: -84.499,
    postal_code: '40508'
  },
  '40509': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0102,
    longitude: -84.4274,
    postal_code: '40509'
  },
  '40510': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0702,
    longitude: -84.591,
    postal_code: '40510'
  },
  '40511': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0932,
    longitude: -84.5007,
    postal_code: '40511'
  },
  '40512': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40512'
  },
  '40513': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0139,
    longitude: -84.5815,
    postal_code: '40513'
  },
  '40514': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 37.9833,
    longitude: -84.5767,
    postal_code: '40514'
  },
  '40515': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 37.9651,
    longitude: -84.4708,
    postal_code: '40515'
  },
  '40516': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0544,
    longitude: -84.3548,
    postal_code: '40516'
  },
  '40517': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 37.9849,
    longitude: -84.4816,
    postal_code: '40517'
  },
  '40522': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40522'
  },
  '40523': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40523'
  },
  '40524': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40524'
  },
  '40526': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40526'
  },
  '40533': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40533'
  },
  '40536': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0321,
    longitude: -84.5084,
    postal_code: '40536'
  },
  '40544': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40544'
  },
  '40546': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40546'
  },
  '40550': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40550'
  },
  '40555': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40555'
  },
  '40574': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40574'
  },
  '40575': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40575'
  },
  '40576': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40576'
  },
  '40577': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40577'
  },
  '40578': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40578'
  },
  '40579': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40579'
  },
  '40580': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40580'
  },
  '40581': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40581'
  },
  '40582': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40582'
  },
  '40583': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40583'
  },
  '40588': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40588'
  },
  '40591': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0283,
    longitude: -84.4715,
    postal_code: '40591'
  },
  '40598': {
    country: 'US',
    city: 'Lexington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fayette',
    latitude: 38.0771,
    longitude: -84.5296,
    postal_code: '40598'
  },
  '40601': {
    country: 'US',
    city: 'Frankfort',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Franklin',
    latitude: 38.2281,
    longitude: -84.8697,
    postal_code: '40601'
  },
  '40602': {
    country: 'US',
    city: 'Frankfort',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Franklin',
    latitude: 38.2341,
    longitude: -84.8748,
    postal_code: '40602'
  },
  '40603': {
    country: 'US',
    city: 'Frankfort',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Franklin',
    latitude: 38.2341,
    longitude: -84.8748,
    postal_code: '40603'
  },
  '40604': {
    country: 'US',
    city: 'Frankfort',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Franklin',
    latitude: 38.2341,
    longitude: -84.8748,
    postal_code: '40604'
  },
  '40618': {
    country: 'US',
    city: 'Frankfort',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Franklin',
    latitude: 38.2341,
    longitude: -84.8748,
    postal_code: '40618'
  },
  '40619': {
    country: 'US',
    city: 'Frankfort',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Franklin',
    latitude: 38.2341,
    longitude: -84.8748,
    postal_code: '40619'
  },
  '40620': {
    country: 'US',
    city: 'Frankfort',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Franklin',
    latitude: 38.2341,
    longitude: -84.8748,
    postal_code: '40620'
  },
  '40621': {
    country: 'US',
    city: 'Frankfort',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Franklin',
    latitude: 38.2009,
    longitude: -84.8733,
    postal_code: '40621'
  },
  '40622': {
    country: 'US',
    city: 'Frankfort',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Franklin',
    latitude: 38.2009,
    longitude: -84.8733,
    postal_code: '40622'
  },
  '40701': {
    country: 'US',
    city: 'Corbin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Whitley',
    latitude: 36.9344,
    longitude: -84.1021,
    postal_code: '40701'
  },
  '40702': {
    country: 'US',
    city: 'Corbin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Whitley',
    latitude: 36.9374,
    longitude: -84.1031,
    postal_code: '40702'
  },
  '40724': {
    country: 'US',
    city: 'Bush',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.0926,
    longitude: -83.8702,
    postal_code: '40724'
  },
  '40729': {
    country: 'US',
    city: 'East Bernstadt',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.2488,
    longitude: -84.138,
    postal_code: '40729'
  },
  '40730': {
    country: 'US',
    city: 'Emlyn',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Whitley',
    latitude: 36.7039,
    longitude: -84.1415,
    postal_code: '40730'
  },
  '40734': {
    country: 'US',
    city: 'Gray',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.9467,
    longitude: -83.9828,
    postal_code: '40734'
  },
  '40737': {
    country: 'US',
    city: 'Keavy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.0156,
    longitude: -84.1436,
    postal_code: '40737'
  },
  '40740': {
    country: 'US',
    city: 'Lily',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.0256,
    longitude: -84.0282,
    postal_code: '40740'
  },
  '40741': {
    country: 'US',
    city: 'London',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.1549,
    longitude: -84.0961,
    postal_code: '40741'
  },
  '40742': {
    country: 'US',
    city: 'London',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.1376,
    longitude: -84.1156,
    postal_code: '40742'
  },
  '40743': {
    country: 'US',
    city: 'London',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.1345,
    longitude: -84.0457,
    postal_code: '40743'
  },
  '40744': {
    country: 'US',
    city: 'London',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.0995,
    longitude: -84.1131,
    postal_code: '40744'
  },
  '40745': {
    country: 'US',
    city: 'London',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.1376,
    longitude: -84.1156,
    postal_code: '40745'
  },
  '40750': {
    country: 'US',
    city: 'London',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.1289,
    longitude: -84.0833,
    postal_code: '40750'
  },
  '40755': {
    country: 'US',
    city: 'Pittsburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Laurel',
    latitude: 37.1678,
    longitude: -84.1166,
    postal_code: '40755'
  },
  '40759': {
    country: 'US',
    city: 'Rockholds',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Whitley',
    latitude: 36.8242,
    longitude: -84.104,
    postal_code: '40759'
  },
  '40763': {
    country: 'US',
    city: 'Siler',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Whitley',
    latitude: 36.7025,
    longitude: -83.955,
    postal_code: '40763'
  },
  '40769': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Whitley',
    latitude: 36.747,
    longitude: -84.1394,
    postal_code: '40769'
  },
  '40771': {
    country: 'US',
    city: 'Woodbine',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.8586,
    longitude: -84.0347,
    postal_code: '40771'
  },
  '40801': {
    country: 'US',
    city: 'Ages Brookside',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8654,
    longitude: -83.2879,
    postal_code: '40801'
  },
  '40803': {
    country: 'US',
    city: 'Asher',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.0114,
    longitude: -83.4553,
    postal_code: '40803'
  },
  '40806': {
    country: 'US',
    city: 'Baxter',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8749,
    longitude: -83.3141,
    postal_code: '40806'
  },
  '40807': {
    country: 'US',
    city: 'Benham',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.9648,
    longitude: -82.9485,
    postal_code: '40807'
  },
  '40808': {
    country: 'US',
    city: 'Big Laurel',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.9798,
    longitude: -83.2171,
    postal_code: '40808'
  },
  '40810': {
    country: 'US',
    city: 'Bledsoe',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.934,
    longitude: -83.3242,
    postal_code: '40810'
  },
  '40813': {
    country: 'US',
    city: 'Calvin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.7223,
    longitude: -83.6221,
    postal_code: '40813'
  },
  '40815': {
    country: 'US',
    city: 'Cawood',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.7812,
    longitude: -83.2914,
    postal_code: '40815'
  },
  '40816': {
    country: 'US',
    city: 'Chappell',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.0098,
    longitude: -83.3494,
    postal_code: '40816'
  },
  '40818': {
    country: 'US',
    city: 'Coalgood',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8202,
    longitude: -83.2445,
    postal_code: '40818'
  },
  '40819': {
    country: 'US',
    city: 'Coldiron',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8201,
    longitude: -83.4707,
    postal_code: '40819'
  },
  '40820': {
    country: 'US',
    city: 'Cranks',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.7558,
    longitude: -83.1838,
    postal_code: '40820'
  },
  '40823': {
    country: 'US',
    city: 'Cumberland',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.9711,
    longitude: -82.9771,
    postal_code: '40823'
  },
  '40824': {
    country: 'US',
    city: 'Dayhoit',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8356,
    longitude: -83.3753,
    postal_code: '40824'
  },
  '40826': {
    country: 'US',
    city: 'Eolia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.0618,
    longitude: -82.7706,
    postal_code: '40826'
  },
  '40827': {
    country: 'US',
    city: 'Essie',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.0547,
    longitude: -83.4561,
    postal_code: '40827'
  },
  '40828': {
    country: 'US',
    city: 'Evarts',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8398,
    longitude: -83.2233,
    postal_code: '40828'
  },
  '40829': {
    country: 'US',
    city: 'Grays Knob',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.7974,
    longitude: -83.2645,
    postal_code: '40829'
  },
  '40830': {
    country: 'US',
    city: 'Gulston',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.7656,
    longitude: -83.3262,
    postal_code: '40830'
  },
  '40831': {
    country: 'US',
    city: 'Harlan',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.7596,
    longitude: -83.3499,
    postal_code: '40831'
  },
  '40840': {
    country: 'US',
    city: 'Helton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 36.954,
    longitude: -83.3907,
    postal_code: '40840'
  },
  '40843': {
    country: 'US',
    city: 'Holmes Mill',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8757,
    longitude: -82.9943,
    postal_code: '40843'
  },
  '40844': {
    country: 'US',
    city: 'Hoskinston',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.0773,
    longitude: -83.3916,
    postal_code: '40844'
  },
  '40845': {
    country: 'US',
    city: 'Hulen',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.7714,
    longitude: -83.5551,
    postal_code: '40845'
  },
  '40847': {
    country: 'US',
    city: 'Kenvir',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8537,
    longitude: -83.1571,
    postal_code: '40847'
  },
  '40849': {
    country: 'US',
    city: 'Lejunior',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8899,
    longitude: -83.1433,
    postal_code: '40849'
  },
  '40854': {
    country: 'US',
    city: 'Loyall',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8481,
    longitude: -83.353,
    postal_code: '40854'
  },
  '40855': {
    country: 'US',
    city: 'Lynch',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.9603,
    longitude: -82.9198,
    postal_code: '40855'
  },
  '40856': {
    country: 'US',
    city: 'Miracle',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.7606,
    longitude: -83.5841,
    postal_code: '40856'
  },
  '40858': {
    country: 'US',
    city: 'Mozelle',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.0037,
    longitude: -83.4135,
    postal_code: '40858'
  },
  '40862': {
    country: 'US',
    city: 'Partridge',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.0129,
    longitude: -82.8795,
    postal_code: '40862'
  },
  '40863': {
    country: 'US',
    city: 'Pathfork',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.7521,
    longitude: -83.4629,
    postal_code: '40863'
  },
  '40865': {
    country: 'US',
    city: 'Putney',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.9039,
    longitude: -83.2381,
    postal_code: '40865'
  },
  '40868': {
    country: 'US',
    city: 'Stinnett',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.0883,
    longitude: -83.3907,
    postal_code: '40868'
  },
  '40870': {
    country: 'US',
    city: 'Totz',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.9448,
    longitude: -83.1177,
    postal_code: '40870'
  },
  '40873': {
    country: 'US',
    city: 'Wallins Creek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8165,
    longitude: -83.419,
    postal_code: '40873'
  },
  '40874': {
    country: 'US',
    city: 'Warbranch',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 36.984,
    longitude: -83.4667,
    postal_code: '40874'
  },
  '40902': {
    country: 'US',
    city: 'Arjay',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.8283,
    longitude: -83.6415,
    postal_code: '40902'
  },
  '40903': {
    country: 'US',
    city: 'Artemus',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.8388,
    longitude: -83.8329,
    postal_code: '40903'
  },
  '40906': {
    country: 'US',
    city: 'Barbourville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.8665,
    longitude: -83.8888,
    postal_code: '40906'
  },
  '40913': {
    country: 'US',
    city: 'Beverly',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.9442,
    longitude: -83.556,
    postal_code: '40913'
  },
  '40914': {
    country: 'US',
    city: 'Big Creek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clay',
    latitude: 37.1623,
    longitude: -83.5688,
    postal_code: '40914'
  },
  '40915': {
    country: 'US',
    city: 'Bimble',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.8868,
    longitude: -83.8282,
    postal_code: '40915'
  },
  '40921': {
    country: 'US',
    city: 'Bryants Store',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.7708,
    longitude: -83.9009,
    postal_code: '40921'
  },
  '40923': {
    country: 'US',
    city: 'Cannon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.9195,
    longitude: -83.851,
    postal_code: '40923'
  },
  '40927': {
    country: 'US',
    city: 'Closplint',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.8713,
    longitude: -83.0414,
    postal_code: '40927'
  },
  '40930': {
    country: 'US',
    city: 'Dewitt',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.877,
    longitude: -83.7377,
    postal_code: '40930'
  },
  '40932': {
    country: 'US',
    city: 'Fall Rock',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clay',
    latitude: 37.2198,
    longitude: -83.7883,
    postal_code: '40932'
  },
  '40935': {
    country: 'US',
    city: 'Flat Lick',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.829,
    longitude: -83.7702,
    postal_code: '40935'
  },
  '40939': {
    country: 'US',
    city: 'Fourmile',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.7934,
    longitude: -83.7419,
    postal_code: '40939'
  },
  '40940': {
    country: 'US',
    city: 'Frakes',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.607,
    longitude: -83.9484,
    postal_code: '40940'
  },
  '40941': {
    country: 'US',
    city: 'Garrard',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clay',
    latitude: 37.1237,
    longitude: -83.7463,
    postal_code: '40941'
  },
  '40943': {
    country: 'US',
    city: 'Girdler',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.9691,
    longitude: -83.8539,
    postal_code: '40943'
  },
  '40944': {
    country: 'US',
    city: 'Goose Rock',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clay',
    latitude: 37.0904,
    longitude: -83.6944,
    postal_code: '40944'
  },
  '40946': {
    country: 'US',
    city: 'Green Road',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.8713,
    longitude: -83.83,
    postal_code: '40946'
  },
  '40949': {
    country: 'US',
    city: 'Heidrick',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.8904,
    longitude: -83.8716,
    postal_code: '40949'
  },
  '40951': {
    country: 'US',
    city: 'Hima',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clay',
    latitude: 37.1206,
    longitude: -83.7783,
    postal_code: '40951'
  },
  '40953': {
    country: 'US',
    city: 'Hinkle',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.9362,
    longitude: -83.8045,
    postal_code: '40953'
  },
  '40955': {
    country: 'US',
    city: 'Ingram',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.7692,
    longitude: -83.7082,
    postal_code: '40955'
  },
  '40958': {
    country: 'US',
    city: 'Kettle Island',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.8093,
    longitude: -83.5892,
    postal_code: '40958'
  },
  '40962': {
    country: 'US',
    city: 'Manchester',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clay',
    latitude: 37.1511,
    longitude: -83.7793,
    postal_code: '40962'
  },
  '40964': {
    country: 'US',
    city: 'Mary Alice',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harlan',
    latitude: 36.7843,
    longitude: -83.3299,
    postal_code: '40964'
  },
  '40965': {
    country: 'US',
    city: 'Middlesboro',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.6172,
    longitude: -83.7231,
    postal_code: '40965'
  },
  '40972': {
    country: 'US',
    city: 'Oneida',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clay',
    latitude: 37.2695,
    longitude: -83.6491,
    postal_code: '40972'
  },
  '40977': {
    country: 'US',
    city: 'Pineville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.7159,
    longitude: -83.7668,
    postal_code: '40977'
  },
  '40979': {
    country: 'US',
    city: 'Roark',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.0226,
    longitude: -83.5152,
    postal_code: '40979'
  },
  '40981': {
    country: 'US',
    city: 'Saul',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.3313,
    longitude: -83.387,
    postal_code: '40981'
  },
  '40982': {
    country: 'US',
    city: 'Scalf',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.9327,
    longitude: -83.6901,
    postal_code: '40982'
  },
  '40983': {
    country: 'US',
    city: 'Sextons Creek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clay',
    latitude: 37.333,
    longitude: -83.7666,
    postal_code: '40983'
  },
  '40988': {
    country: 'US',
    city: 'Stoney Fork',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bell',
    latitude: 36.8323,
    longitude: -83.5346,
    postal_code: '40988'
  },
  '40995': {
    country: 'US',
    city: 'Trosper',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.761,
    longitude: -83.8155,
    postal_code: '40995'
  },
  '40997': {
    country: 'US',
    city: 'Walker',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knox',
    latitude: 36.8911,
    longitude: -83.6616,
    postal_code: '40997'
  },
  '41001': {
    country: 'US',
    city: 'Alexandria',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 38.9406,
    longitude: -84.3943,
    postal_code: '41001'
  },
  '41002': {
    country: 'US',
    city: 'Augusta',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bracken',
    latitude: 38.763,
    longitude: -83.9954,
    postal_code: '41002'
  },
  '41003': {
    country: 'US',
    city: 'Berry',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harrison',
    latitude: 38.516,
    longitude: -84.3611,
    postal_code: '41003'
  },
  '41004': {
    country: 'US',
    city: 'Brooksville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bracken',
    latitude: 38.6644,
    longitude: -84.0786,
    postal_code: '41004'
  },
  '41005': {
    country: 'US',
    city: 'Burlington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boone',
    latitude: 39.015,
    longitude: -84.7736,
    postal_code: '41005'
  },
  '41006': {
    country: 'US',
    city: 'Butler',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pendleton',
    latitude: 38.8013,
    longitude: -84.3446,
    postal_code: '41006'
  },
  '41007': {
    country: 'US',
    city: 'California',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 38.9056,
    longitude: -84.3171,
    postal_code: '41007'
  },
  '41008': {
    country: 'US',
    city: 'Carrollton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carroll',
    latitude: 38.6696,
    longitude: -85.173,
    postal_code: '41008'
  },
  '41010': {
    country: 'US',
    city: 'Corinth',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grant',
    latitude: 38.5302,
    longitude: -84.5846,
    postal_code: '41010'
  },
  '41011': {
    country: 'US',
    city: 'Covington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 39.0708,
    longitude: -84.5212,
    postal_code: '41011'
  },
  '41012': {
    country: 'US',
    city: 'Covington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 38.9447,
    longitude: -84.5205,
    postal_code: '41012'
  },
  '41014': {
    country: 'US',
    city: 'Covington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 39.0642,
    longitude: -84.5118,
    postal_code: '41014'
  },
  '41015': {
    country: 'US',
    city: 'Latonia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 39.0217,
    longitude: -84.4989,
    postal_code: '41015'
  },
  '41016': {
    country: 'US',
    city: 'Covington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 39.0873,
    longitude: -84.549,
    postal_code: '41016'
  },
  '41017': {
    country: 'US',
    city: 'Ft Mitchell',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 39.03,
    longitude: -84.559,
    postal_code: '41017'
  },
  '41018': {
    country: 'US',
    city: 'Erlanger',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 39.0082,
    longitude: -84.5977,
    postal_code: '41018'
  },
  '41019': {
    country: 'US',
    city: 'Covington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 38.9447,
    longitude: -84.5205,
    postal_code: '41019'
  },
  '41021': {
    country: 'US',
    city: 'Hebron',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boone',
    latitude: 39.029,
    longitude: -84.6195,
    postal_code: '41021'
  },
  '41022': {
    country: 'US',
    city: 'Florence',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boone',
    latitude: 38.9624,
    longitude: -84.7478,
    postal_code: '41022'
  },
  '41025': {
    country: 'US',
    city: 'Erlanger',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 39.0603,
    longitude: -84.6309,
    postal_code: '41025'
  },
  '41030': {
    country: 'US',
    city: 'Crittenden',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grant',
    latitude: 38.7741,
    longitude: -84.5982,
    postal_code: '41030'
  },
  '41031': {
    country: 'US',
    city: 'Cynthiana',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Harrison',
    latitude: 38.3964,
    longitude: -84.2949,
    postal_code: '41031'
  },
  '41033': {
    country: 'US',
    city: 'De Mossville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pendleton',
    latitude: 38.7623,
    longitude: -84.4859,
    postal_code: '41033'
  },
  '41034': {
    country: 'US',
    city: 'Dover',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Mason',
    latitude: 38.691,
    longitude: -83.8718,
    postal_code: '41034'
  },
  '41035': {
    country: 'US',
    city: 'Dry Ridge',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grant',
    latitude: 38.7049,
    longitude: -84.6237,
    postal_code: '41035'
  },
  '41037': {
    country: 'US',
    city: 'Elizaville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fleming',
    latitude: 38.4195,
    longitude: -83.8255,
    postal_code: '41037'
  },
  '41039': {
    country: 'US',
    city: 'Ewing',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fleming',
    latitude: 38.4157,
    longitude: -83.8733,
    postal_code: '41039'
  },
  '41040': {
    country: 'US',
    city: 'Falmouth',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pendleton',
    latitude: 38.6643,
    longitude: -84.3451,
    postal_code: '41040'
  },
  '41041': {
    country: 'US',
    city: 'Flemingsburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fleming',
    latitude: 38.428,
    longitude: -83.708,
    postal_code: '41041'
  },
  '41042': {
    country: 'US',
    city: 'Florence',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boone',
    latitude: 38.9941,
    longitude: -84.642,
    postal_code: '41042'
  },
  '41043': {
    country: 'US',
    city: 'Foster',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bracken',
    latitude: 38.7506,
    longitude: -84.1566,
    postal_code: '41043'
  },
  '41044': {
    country: 'US',
    city: 'Germantown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bracken',
    latitude: 38.6357,
    longitude: -83.99,
    postal_code: '41044'
  },
  '41045': {
    country: 'US',
    city: 'Ghent',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carroll',
    latitude: 38.7188,
    longitude: -85.0557,
    postal_code: '41045'
  },
  '41046': {
    country: 'US',
    city: 'Glencoe',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Gallatin',
    latitude: 38.7229,
    longitude: -84.8116,
    postal_code: '41046'
  },
  '41048': {
    country: 'US',
    city: 'Hebron',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boone',
    latitude: 39.0755,
    longitude: -84.7007,
    postal_code: '41048'
  },
  '41049': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fleming',
    latitude: 38.2929,
    longitude: -83.6697,
    postal_code: '41049'
  },
  '41051': {
    country: 'US',
    city: 'Independence',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 38.9354,
    longitude: -84.5479,
    postal_code: '41051'
  },
  '41052': {
    country: 'US',
    city: 'Jonesville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grant',
    latitude: 38.6398,
    longitude: -84.7755,
    postal_code: '41052'
  },
  '41053': {
    country: 'US',
    city: 'Kenton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 38.8684,
    longitude: -84.4561,
    postal_code: '41053'
  },
  '41054': {
    country: 'US',
    city: 'Mason',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grant',
    latitude: 38.5932,
    longitude: -84.5786,
    postal_code: '41054'
  },
  '41055': {
    country: 'US',
    city: 'Mayslick',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Mason',
    latitude: 38.522,
    longitude: -83.861,
    postal_code: '41055'
  },
  '41056': {
    country: 'US',
    city: 'Maysville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Mason',
    latitude: 38.6207,
    longitude: -83.8067,
    postal_code: '41056'
  },
  '41059': {
    country: 'US',
    city: 'Melbourne',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 39.0067,
    longitude: -84.3538,
    postal_code: '41059'
  },
  '41061': {
    country: 'US',
    city: 'Milford',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Bracken',
    latitude: 38.5817,
    longitude: -84.1566,
    postal_code: '41061'
  },
  '41062': {
    country: 'US',
    city: 'Minerva',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Mason',
    latitude: 38.7054,
    longitude: -83.9191,
    postal_code: '41062'
  },
  '41063': {
    country: 'US',
    city: 'Morning View',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Kenton',
    latitude: 38.8394,
    longitude: -84.5069,
    postal_code: '41063'
  },
  '41064': {
    country: 'US',
    city: 'Mount Olivet',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Robertson',
    latitude: 38.5218,
    longitude: -84.048,
    postal_code: '41064'
  },
  '41065': {
    country: 'US',
    city: 'Muses Mills',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fleming',
    latitude: 38.3501,
    longitude: -83.5271,
    postal_code: '41065'
  },
  '41071': {
    country: 'US',
    city: 'Newport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 39.0563,
    longitude: -84.4787,
    postal_code: '41071'
  },
  '41072': {
    country: 'US',
    city: 'Newport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 38.9638,
    longitude: -84.3689,
    postal_code: '41072'
  },
  '41073': {
    country: 'US',
    city: 'Bellevue',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 39.1024,
    longitude: -84.4787,
    postal_code: '41073'
  },
  '41074': {
    country: 'US',
    city: 'Dayton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 39.1114,
    longitude: -84.4712,
    postal_code: '41074'
  },
  '41075': {
    country: 'US',
    city: 'Fort Thomas',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 39.0786,
    longitude: -84.4523,
    postal_code: '41075'
  },
  '41076': {
    country: 'US',
    city: 'Newport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 39.0262,
    longitude: -84.4408,
    postal_code: '41076'
  },
  '41080': {
    country: 'US',
    city: 'Petersburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boone',
    latitude: 39.0416,
    longitude: -84.8371,
    postal_code: '41080'
  },
  '41081': {
    country: 'US',
    city: 'Plummers Landing',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fleming',
    latitude: 38.3481,
    longitude: -83.7186,
    postal_code: '41081'
  },
  '41083': {
    country: 'US',
    city: 'Sanders',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carroll',
    latitude: 38.661,
    longitude: -84.9732,
    postal_code: '41083'
  },
  '41085': {
    country: 'US',
    city: 'Silver Grove',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 39.0343,
    longitude: -84.3908,
    postal_code: '41085'
  },
  '41086': {
    country: 'US',
    city: 'Sparta',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Gallatin',
    latitude: 38.7256,
    longitude: -84.8813,
    postal_code: '41086'
  },
  '41091': {
    country: 'US',
    city: 'Union',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boone',
    latitude: 38.9435,
    longitude: -84.7274,
    postal_code: '41091'
  },
  '41092': {
    country: 'US',
    city: 'Verona',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boone',
    latitude: 38.839,
    longitude: -84.6907,
    postal_code: '41092'
  },
  '41093': {
    country: 'US',
    city: 'Wallingford',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fleming',
    latitude: 38.3374,
    longitude: -83.5625,
    postal_code: '41093'
  },
  '41094': {
    country: 'US',
    city: 'Walton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boone',
    latitude: 38.8875,
    longitude: -84.6328,
    postal_code: '41094'
  },
  '41095': {
    country: 'US',
    city: 'Warsaw',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Gallatin',
    latitude: 38.7807,
    longitude: -84.8496,
    postal_code: '41095'
  },
  '41096': {
    country: 'US',
    city: 'Washington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Mason',
    latitude: 38.6123,
    longitude: -83.808,
    postal_code: '41096'
  },
  '41097': {
    country: 'US',
    city: 'Williamstown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grant',
    latitude: 38.6292,
    longitude: -84.5744,
    postal_code: '41097'
  },
  '41098': {
    country: 'US',
    city: 'Worthville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carroll',
    latitude: 38.6179,
    longitude: -85.0663,
    postal_code: '41098'
  },
  '41099': {
    country: 'US',
    city: 'Newport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Campbell',
    latitude: 38.9638,
    longitude: -84.3689,
    postal_code: '41099'
  },
  '41101': {
    country: 'US',
    city: 'Ashland',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyd',
    latitude: 38.4722,
    longitude: -82.6461,
    postal_code: '41101'
  },
  '41102': {
    country: 'US',
    city: 'Ashland',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyd',
    latitude: 38.4218,
    longitude: -82.7173,
    postal_code: '41102'
  },
  '41105': {
    country: 'US',
    city: 'Ashland',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyd',
    latitude: 38.3703,
    longitude: -82.6948,
    postal_code: '41105'
  },
  '41114': {
    country: 'US',
    city: 'Ashland',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyd',
    latitude: 38.3703,
    longitude: -82.6948,
    postal_code: '41114'
  },
  '41121': {
    country: 'US',
    city: 'Argillite',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Greenup',
    latitude: 38.4322,
    longitude: -82.8094,
    postal_code: '41121'
  },
  '41124': {
    country: 'US',
    city: 'Blaine',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lawrence',
    latitude: 38.027,
    longitude: -82.8513,
    postal_code: '41124'
  },
  '41128': {
    country: 'US',
    city: 'Carter',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carter',
    latitude: 38.4339,
    longitude: -83.1336,
    postal_code: '41128'
  },
  '41129': {
    country: 'US',
    city: 'Catlettsburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyd',
    latitude: 38.3799,
    longitude: -82.6321,
    postal_code: '41129'
  },
  '41132': {
    country: 'US',
    city: 'Denton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carter',
    latitude: 38.2597,
    longitude: -82.8562,
    postal_code: '41132'
  },
  '41135': {
    country: 'US',
    city: 'Emerson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lewis',
    latitude: 38.3563,
    longitude: -83.2877,
    postal_code: '41135'
  },
  '41139': {
    country: 'US',
    city: 'Flatwoods',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Greenup',
    latitude: 38.5188,
    longitude: -82.7212,
    postal_code: '41139'
  },
  '41141': {
    country: 'US',
    city: 'Garrison',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lewis',
    latitude: 38.5869,
    longitude: -83.2,
    postal_code: '41141'
  },
  '41142': {
    country: 'US',
    city: 'Grahn',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carter',
    latitude: 38.2897,
    longitude: -83.0811,
    postal_code: '41142'
  },
  '41143': {
    country: 'US',
    city: 'Grayson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carter',
    latitude: 38.3326,
    longitude: -82.9485,
    postal_code: '41143'
  },
  '41144': {
    country: 'US',
    city: 'Greenup',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Greenup',
    latitude: 38.5368,
    longitude: -82.9191,
    postal_code: '41144'
  },
  '41146': {
    country: 'US',
    city: 'Hitchins',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carter',
    latitude: 38.2766,
    longitude: -82.8982,
    postal_code: '41146'
  },
  '41149': {
    country: 'US',
    city: 'Isonville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Elliott',
    latitude: 38.0462,
    longitude: -83.0506,
    postal_code: '41149'
  },
  '41159': {
    country: 'US',
    city: 'Martha',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lawrence',
    latitude: 38.0152,
    longitude: -82.9558,
    postal_code: '41159'
  },
  '41160': {
    country: 'US',
    city: 'Mazie',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lawrence',
    latitude: 38.027,
    longitude: -82.9724,
    postal_code: '41160'
  },
  '41164': {
    country: 'US',
    city: 'Olive Hill',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carter',
    latitude: 38.3001,
    longitude: -83.1741,
    postal_code: '41164'
  },
  '41166': {
    country: 'US',
    city: 'Quincy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lewis',
    latitude: 38.6271,
    longitude: -83.1056,
    postal_code: '41166'
  },
  '41168': {
    country: 'US',
    city: 'Rush',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Boyd',
    latitude: 38.3089,
    longitude: -82.7476,
    postal_code: '41168'
  },
  '41169': {
    country: 'US',
    city: 'Russell',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Greenup',
    latitude: 38.5173,
    longitude: -82.6977,
    postal_code: '41169'
  },
  '41171': {
    country: 'US',
    city: 'Sandy Hook',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Elliott',
    latitude: 38.1316,
    longitude: -83.0797,
    postal_code: '41171'
  },
  '41173': {
    country: 'US',
    city: 'Soldier',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carter',
    latitude: 38.262,
    longitude: -83.2847,
    postal_code: '41173'
  },
  '41174': {
    country: 'US',
    city: 'South Portsmouth',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Greenup',
    latitude: 38.7087,
    longitude: -83.0162,
    postal_code: '41174'
  },
  '41175': {
    country: 'US',
    city: 'South Shore',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Greenup',
    latitude: 38.7148,
    longitude: -82.9366,
    postal_code: '41175'
  },
  '41179': {
    country: 'US',
    city: 'Vanceburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lewis',
    latitude: 38.4699,
    longitude: -83.2702,
    postal_code: '41179'
  },
  '41180': {
    country: 'US',
    city: 'Webbville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lawrence',
    latitude: 38.1634,
    longitude: -82.7897,
    postal_code: '41180'
  },
  '41181': {
    country: 'US',
    city: 'Willard',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carter',
    latitude: 38.2045,
    longitude: -82.9076,
    postal_code: '41181'
  },
  '41183': {
    country: 'US',
    city: 'Worthington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Greenup',
    latitude: 38.5511,
    longitude: -82.7396,
    postal_code: '41183'
  },
  '41189': {
    country: 'US',
    city: 'Tollesboro',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lewis',
    latitude: 38.5723,
    longitude: -83.5605,
    postal_code: '41189'
  },
  '41201': {
    country: 'US',
    city: 'Adams',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lawrence',
    latitude: 38.0741,
    longitude: -82.7447,
    postal_code: '41201'
  },
  '41203': {
    country: 'US',
    city: 'Beauty',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Martin',
    latitude: 37.8401,
    longitude: -82.439,
    postal_code: '41203'
  },
  '41204': {
    country: 'US',
    city: 'Boons Camp',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.8216,
    longitude: -82.6704,
    postal_code: '41204'
  },
  '41214': {
    country: 'US',
    city: 'Debord',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Martin',
    latitude: 37.8265,
    longitude: -82.5513,
    postal_code: '41214'
  },
  '41216': {
    country: 'US',
    city: 'East Point',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.7579,
    longitude: -82.8182,
    postal_code: '41216'
  },
  '41219': {
    country: 'US',
    city: 'Flatgap',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.9231,
    longitude: -82.9221,
    postal_code: '41219'
  },
  '41222': {
    country: 'US',
    city: 'Hagerhill',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.7805,
    longitude: -82.8416,
    postal_code: '41222'
  },
  '41224': {
    country: 'US',
    city: 'Inez',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Martin',
    latitude: 37.8754,
    longitude: -82.5337,
    postal_code: '41224'
  },
  '41226': {
    country: 'US',
    city: 'Keaton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.9856,
    longitude: -82.9602,
    postal_code: '41226'
  },
  '41230': {
    country: 'US',
    city: 'Louisa',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lawrence',
    latitude: 38.1043,
    longitude: -82.6056,
    postal_code: '41230'
  },
  '41231': {
    country: 'US',
    city: 'Lovely',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Martin',
    latitude: 37.8259,
    longitude: -82.4013,
    postal_code: '41231'
  },
  '41232': {
    country: 'US',
    city: 'Lowmansville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lawrence',
    latitude: 37.9315,
    longitude: -82.7349,
    postal_code: '41232'
  },
  '41234': {
    country: 'US',
    city: 'Meally',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.8078,
    longitude: -82.7415,
    postal_code: '41234'
  },
  '41238': {
    country: 'US',
    city: 'Oil Springs',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.8101,
    longitude: -82.9424,
    postal_code: '41238'
  },
  '41240': {
    country: 'US',
    city: 'Paintsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.8242,
    longitude: -82.7945,
    postal_code: '41240'
  },
  '41250': {
    country: 'US',
    city: 'Pilgrim',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Martin',
    latitude: 37.7379,
    longitude: -82.4523,
    postal_code: '41250'
  },
  '41254': {
    country: 'US',
    city: 'River',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.8795,
    longitude: -82.7251,
    postal_code: '41254'
  },
  '41255': {
    country: 'US',
    city: 'Sitka',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.8922,
    longitude: -82.8446,
    postal_code: '41255'
  },
  '41256': {
    country: 'US',
    city: 'Staffordsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.8253,
    longitude: -82.8823,
    postal_code: '41256'
  },
  '41257': {
    country: 'US',
    city: 'Stambaugh',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.9166,
    longitude: -82.8034,
    postal_code: '41257'
  },
  '41260': {
    country: 'US',
    city: 'Thelma',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.822,
    longitude: -82.7526,
    postal_code: '41260'
  },
  '41262': {
    country: 'US',
    city: 'Tomahawk',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Martin',
    latitude: 37.8687,
    longitude: -82.5971,
    postal_code: '41262'
  },
  '41263': {
    country: 'US',
    city: 'Tutor Key',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.8507,
    longitude: -82.7602,
    postal_code: '41263'
  },
  '41264': {
    country: 'US',
    city: 'Ulysses',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lawrence',
    latitude: 37.9416,
    longitude: -82.6739,
    postal_code: '41264'
  },
  '41265': {
    country: 'US',
    city: 'Van Lear',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.7481,
    longitude: -82.7054,
    postal_code: '41265'
  },
  '41267': {
    country: 'US',
    city: 'Warfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Martin',
    latitude: 37.875,
    longitude: -82.4386,
    postal_code: '41267'
  },
  '41268': {
    country: 'US',
    city: 'West Van Lear',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.7862,
    longitude: -82.7807,
    postal_code: '41268'
  },
  '41271': {
    country: 'US',
    city: 'Williamsport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.8313,
    longitude: -82.7264,
    postal_code: '41271'
  },
  '41274': {
    country: 'US',
    city: 'Wittensville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Johnson',
    latitude: 37.8703,
    longitude: -82.81,
    postal_code: '41274'
  },
  '41301': {
    country: 'US',
    city: 'Campton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Wolfe',
    latitude: 37.7287,
    longitude: -83.494,
    postal_code: '41301'
  },
  '41310': {
    country: 'US',
    city: 'Bays',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breathitt',
    latitude: 37.6405,
    longitude: -83.2441,
    postal_code: '41310'
  },
  '41311': {
    country: 'US',
    city: 'Beattyville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lee',
    latitude: 37.5999,
    longitude: -83.714,
    postal_code: '41311'
  },
  '41314': {
    country: 'US',
    city: 'Booneville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Owsley',
    latitude: 37.4965,
    longitude: -83.6574,
    postal_code: '41314'
  },
  '41317': {
    country: 'US',
    city: 'Clayhole',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breathitt',
    latitude: 37.4476,
    longitude: -83.1892,
    postal_code: '41317'
  },
  '41332': {
    country: 'US',
    city: 'Hazel Green',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Wolfe',
    latitude: 37.7981,
    longitude: -83.421,
    postal_code: '41332'
  },
  '41339': {
    country: 'US',
    city: 'Jackson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breathitt',
    latitude: 37.4868,
    longitude: -83.2913,
    postal_code: '41339'
  },
  '41347': {
    country: 'US',
    city: 'Lone',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lee',
    latitude: 37.5442,
    longitude: -83.6008,
    postal_code: '41347'
  },
  '41348': {
    country: 'US',
    city: 'Lost Creek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breathitt',
    latitude: 37.4418,
    longitude: -83.2976,
    postal_code: '41348'
  },
  '41351': {
    country: 'US',
    city: 'Mistletoe',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Owsley',
    latitude: 37.3126,
    longitude: -83.5916,
    postal_code: '41351'
  },
  '41352': {
    country: 'US',
    city: 'Mize',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Morgan',
    latitude: 37.8235,
    longitude: -83.3335,
    postal_code: '41352'
  },
  '41360': {
    country: 'US',
    city: 'Pine Ridge',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Wolfe',
    latitude: 37.7749,
    longitude: -83.6321,
    postal_code: '41360'
  },
  '41364': {
    country: 'US',
    city: 'Ricetown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Owsley',
    latitude: 37.3871,
    longitude: -83.6752,
    postal_code: '41364'
  },
  '41365': {
    country: 'US',
    city: 'Rogers',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Wolfe',
    latitude: 37.7093,
    longitude: -83.6314,
    postal_code: '41365'
  },
  '41366': {
    country: 'US',
    city: 'Rousseau',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breathitt',
    latitude: 37.5946,
    longitude: -83.2187,
    postal_code: '41366'
  },
  '41367': {
    country: 'US',
    city: 'Rowdy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.397,
    longitude: -83.227,
    postal_code: '41367'
  },
  '41368': {
    country: 'US',
    city: 'Saint Helens',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lee',
    latitude: 37.5829,
    longitude: -83.6471,
    postal_code: '41368'
  },
  '41385': {
    country: 'US',
    city: 'Vancleve',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breathitt',
    latitude: 37.6477,
    longitude: -83.3806,
    postal_code: '41385'
  },
  '41386': {
    country: 'US',
    city: 'Vincent',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Owsley',
    latitude: 37.3986,
    longitude: -83.7049,
    postal_code: '41386'
  },
  '41390': {
    country: 'US',
    city: 'Whick',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Breathitt',
    latitude: 37.4102,
    longitude: -83.3747,
    postal_code: '41390'
  },
  '41397': {
    country: 'US',
    city: 'Zoe',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lee',
    latitude: 37.6867,
    longitude: -83.6702,
    postal_code: '41397'
  },
  '41408': {
    country: 'US',
    city: 'Cannel City',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Morgan',
    latitude: 37.7901,
    longitude: -83.2741,
    postal_code: '41408'
  },
  '41413': {
    country: 'US',
    city: 'Crockett',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Morgan',
    latitude: 37.9856,
    longitude: -83.0907,
    postal_code: '41413'
  },
  '41421': {
    country: 'US',
    city: 'Elkfork',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Morgan',
    latitude: 37.9708,
    longitude: -83.1193,
    postal_code: '41421'
  },
  '41425': {
    country: 'US',
    city: 'Ezel',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Morgan',
    latitude: 37.8912,
    longitude: -83.4444,
    postal_code: '41425'
  },
  '41426': {
    country: 'US',
    city: 'Falcon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Magoffin',
    latitude: 37.7843,
    longitude: -83.0109,
    postal_code: '41426'
  },
  '41451': {
    country: 'US',
    city: 'Malone',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Morgan',
    latitude: 37.872,
    longitude: -83.2582,
    postal_code: '41451'
  },
  '41464': {
    country: 'US',
    city: 'Royalton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Magoffin',
    latitude: 37.6751,
    longitude: -83.0218,
    postal_code: '41464'
  },
  '41465': {
    country: 'US',
    city: 'Salyersville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Magoffin',
    latitude: 37.7325,
    longitude: -83.0298,
    postal_code: '41465'
  },
  '41472': {
    country: 'US',
    city: 'West Liberty',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Morgan',
    latitude: 37.9215,
    longitude: -83.2596,
    postal_code: '41472'
  },
  '41477': {
    country: 'US',
    city: 'Wrigley',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Morgan',
    latitude: 37.914,
    longitude: -83.2338,
    postal_code: '41477'
  },
  '41501': {
    country: 'US',
    city: 'Pikeville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5161,
    longitude: -82.5173,
    postal_code: '41501'
  },
  '41502': {
    country: 'US',
    city: 'Pikeville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.4793,
    longitude: -82.5188,
    postal_code: '41502'
  },
  '41503': {
    country: 'US',
    city: 'South Williamson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.667,
    longitude: -82.2886,
    postal_code: '41503'
  },
  '41512': {
    country: 'US',
    city: 'Ashcamp',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.2589,
    longitude: -82.4613,
    postal_code: '41512'
  },
  '41513': {
    country: 'US',
    city: 'Belcher',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.3478,
    longitude: -82.3406,
    postal_code: '41513'
  },
  '41514': {
    country: 'US',
    city: 'Belfry',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.6401,
    longitude: -82.2573,
    postal_code: '41514'
  },
  '41517': {
    country: 'US',
    city: 'Burdine',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1893,
    longitude: -82.599,
    postal_code: '41517'
  },
  '41519': {
    country: 'US',
    city: 'Canada',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5899,
    longitude: -82.3308,
    postal_code: '41519'
  },
  '41520': {
    country: 'US',
    city: 'Dorton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.279,
    longitude: -82.5773,
    postal_code: '41520'
  },
  '41522': {
    country: 'US',
    city: 'Elkhorn City',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.3109,
    longitude: -82.4091,
    postal_code: '41522'
  },
  '41524': {
    country: 'US',
    city: 'Fedscreek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.4266,
    longitude: -82.2562,
    postal_code: '41524'
  },
  '41526': {
    country: 'US',
    city: 'Fords Branch',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.4332,
    longitude: -82.511,
    postal_code: '41526'
  },
  '41527': {
    country: 'US',
    city: 'Forest Hills',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.6432,
    longitude: -82.2991,
    postal_code: '41527'
  },
  '41528': {
    country: 'US',
    city: 'Freeburn',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5512,
    longitude: -82.1434,
    postal_code: '41528'
  },
  '41531': {
    country: 'US',
    city: 'Hardy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5928,
    longitude: -82.2328,
    postal_code: '41531'
  },
  '41534': {
    country: 'US',
    city: 'Hellier',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.2876,
    longitude: -82.4713,
    postal_code: '41534'
  },
  '41535': {
    country: 'US',
    city: 'Huddy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5964,
    longitude: -82.2797,
    postal_code: '41535'
  },
  '41537': {
    country: 'US',
    city: 'Jenkins',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1911,
    longitude: -82.6513,
    postal_code: '41537'
  },
  '41538': {
    country: 'US',
    city: 'Jonancy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.3165,
    longitude: -82.5829,
    postal_code: '41538'
  },
  '41539': {
    country: 'US',
    city: 'Kimper',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5068,
    longitude: -82.3271,
    postal_code: '41539'
  },
  '41540': {
    country: 'US',
    city: 'Lick Creek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.3592,
    longitude: -82.3092,
    postal_code: '41540'
  },
  '41542': {
    country: 'US',
    city: 'Lookout',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.3148,
    longitude: -82.465,
    postal_code: '41542'
  },
  '41543': {
    country: 'US',
    city: 'Mc Andrews',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5624,
    longitude: -82.2803,
    postal_code: '41543'
  },
  '41544': {
    country: 'US',
    city: 'Mc Carr',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5969,
    longitude: -82.1674,
    postal_code: '41544'
  },
  '41547': {
    country: 'US',
    city: 'Majestic',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5311,
    longitude: -82.0916,
    postal_code: '41547'
  },
  '41548': {
    country: 'US',
    city: 'Mouthcard',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.3815,
    longitude: -82.2727,
    postal_code: '41548'
  },
  '41549': {
    country: 'US',
    city: 'Myra',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.2898,
    longitude: -82.5999,
    postal_code: '41549'
  },
  '41553': {
    country: 'US',
    city: 'Phelps',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.4987,
    longitude: -82.1584,
    postal_code: '41553'
  },
  '41554': {
    country: 'US',
    city: 'Phyllis',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.4484,
    longitude: -82.2985,
    postal_code: '41554'
  },
  '41555': {
    country: 'US',
    city: 'Pinsonfork',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5466,
    longitude: -82.2621,
    postal_code: '41555'
  },
  '41557': {
    country: 'US',
    city: 'Raccoon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.4935,
    longitude: -82.4221,
    postal_code: '41557'
  },
  '41558': {
    country: 'US',
    city: 'Ransom',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5426,
    longitude: -82.2085,
    postal_code: '41558'
  },
  '41559': {
    country: 'US',
    city: 'Regina',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.3744,
    longitude: -82.3831,
    postal_code: '41559'
  },
  '41560': {
    country: 'US',
    city: 'Robinson Creek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.3799,
    longitude: -82.5616,
    postal_code: '41560'
  },
  '41561': {
    country: 'US',
    city: 'Rockhouse',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.3316,
    longitude: -82.4626,
    postal_code: '41561'
  },
  '41562': {
    country: 'US',
    city: 'Shelbiana',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.4063,
    longitude: -82.4673,
    postal_code: '41562'
  },
  '41563': {
    country: 'US',
    city: 'Shelby Gap',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.2311,
    longitude: -82.5286,
    postal_code: '41563'
  },
  '41564': {
    country: 'US',
    city: 'Sidney',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.6076,
    longitude: -82.3648,
    postal_code: '41564'
  },
  '41566': {
    country: 'US',
    city: 'Steele',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.4029,
    longitude: -82.2071,
    postal_code: '41566'
  },
  '41567': {
    country: 'US',
    city: 'Stone',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5859,
    longitude: -82.271,
    postal_code: '41567'
  },
  '41568': {
    country: 'US',
    city: 'Stopover',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.5093,
    longitude: -82.0784,
    postal_code: '41568'
  },
  '41571': {
    country: 'US',
    city: 'Varney',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.6275,
    longitude: -82.4356,
    postal_code: '41571'
  },
  '41572': {
    country: 'US',
    city: 'Virgie',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pike',
    latitude: 37.3197,
    longitude: -82.6114,
    postal_code: '41572'
  },
  '41601': {
    country: 'US',
    city: 'Allen',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.6074,
    longitude: -82.7225,
    postal_code: '41601'
  },
  '41602': {
    country: 'US',
    city: 'Auxier',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.737,
    longitude: -82.7582,
    postal_code: '41602'
  },
  '41603': {
    country: 'US',
    city: 'Banner',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5707,
    longitude: -82.6806,
    postal_code: '41603'
  },
  '41604': {
    country: 'US',
    city: 'Beaver',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.3774,
    longitude: -82.6869,
    postal_code: '41604'
  },
  '41605': {
    country: 'US',
    city: 'Betsy Layne',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5555,
    longitude: -82.6267,
    postal_code: '41605'
  },
  '41606': {
    country: 'US',
    city: 'Bevinsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.3465,
    longitude: -82.7403,
    postal_code: '41606'
  },
  '41607': {
    country: 'US',
    city: 'Blue River',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.6253,
    longitude: -82.8418,
    postal_code: '41607'
  },
  '41612': {
    country: 'US',
    city: 'Bypro',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.3465,
    longitude: -82.7165,
    postal_code: '41612'
  },
  '41615': {
    country: 'US',
    city: 'Dana',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5467,
    longitude: -82.6705,
    postal_code: '41615'
  },
  '41616': {
    country: 'US',
    city: 'David',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5767,
    longitude: -82.8708,
    postal_code: '41616'
  },
  '41619': {
    country: 'US',
    city: 'Drift',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.4933,
    longitude: -82.7575,
    postal_code: '41619'
  },
  '41621': {
    country: 'US',
    city: 'Dwale',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.6246,
    longitude: -82.7227,
    postal_code: '41621'
  },
  '41622': {
    country: 'US',
    city: 'Eastern',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.517,
    longitude: -82.806,
    postal_code: '41622'
  },
  '41630': {
    country: 'US',
    city: 'Garrett',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.4798,
    longitude: -82.8316,
    postal_code: '41630'
  },
  '41631': {
    country: 'US',
    city: 'Grethel',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.4576,
    longitude: -82.6645,
    postal_code: '41631'
  },
  '41632': {
    country: 'US',
    city: 'Gunlock',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Magoffin',
    latitude: 37.5892,
    longitude: -82.9452,
    postal_code: '41632'
  },
  '41635': {
    country: 'US',
    city: 'Harold',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5368,
    longitude: -82.6332,
    postal_code: '41635'
  },
  '41636': {
    country: 'US',
    city: 'Hi Hat',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.3994,
    longitude: -82.7292,
    postal_code: '41636'
  },
  '41640': {
    country: 'US',
    city: 'Hueysville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5087,
    longitude: -82.8518,
    postal_code: '41640'
  },
  '41642': {
    country: 'US',
    city: 'Ivel',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5956,
    longitude: -82.6463,
    postal_code: '41642'
  },
  '41643': {
    country: 'US',
    city: 'Lackey',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.471,
    longitude: -82.8294,
    postal_code: '41643'
  },
  '41645': {
    country: 'US',
    city: 'Langley',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5381,
    longitude: -82.7976,
    postal_code: '41645'
  },
  '41647': {
    country: 'US',
    city: 'Mc Dowell',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.4355,
    longitude: -82.7203,
    postal_code: '41647'
  },
  '41649': {
    country: 'US',
    city: 'Martin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5708,
    longitude: -82.7819,
    postal_code: '41649'
  },
  '41650': {
    country: 'US',
    city: 'Melvin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.3536,
    longitude: -82.6763,
    postal_code: '41650'
  },
  '41651': {
    country: 'US',
    city: 'Minnie',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.4548,
    longitude: -82.7646,
    postal_code: '41651'
  },
  '41653': {
    country: 'US',
    city: 'Prestonsburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.661,
    longitude: -82.7636,
    postal_code: '41653'
  },
  '41655': {
    country: 'US',
    city: 'Printer',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5055,
    longitude: -82.706,
    postal_code: '41655'
  },
  '41659': {
    country: 'US',
    city: 'Stanville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5741,
    longitude: -82.6271,
    postal_code: '41659'
  },
  '41660': {
    country: 'US',
    city: 'Teaberry',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.422,
    longitude: -82.6405,
    postal_code: '41660'
  },
  '41663': {
    country: 'US',
    city: 'Tram',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.5725,
    longitude: -82.6452,
    postal_code: '41663'
  },
  '41666': {
    country: 'US',
    city: 'Wayland',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.4367,
    longitude: -82.801,
    postal_code: '41666'
  },
  '41667': {
    country: 'US',
    city: 'Weeksbury',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.317,
    longitude: -82.7041,
    postal_code: '41667'
  },
  '41669': {
    country: 'US',
    city: 'Wheelwright',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Floyd',
    latitude: 37.3314,
    longitude: -82.7156,
    postal_code: '41669'
  },
  '41701': {
    country: 'US',
    city: 'Hazard',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.2983,
    longitude: -83.1912,
    postal_code: '41701'
  },
  '41702': {
    country: 'US',
    city: 'Hazard',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.2495,
    longitude: -83.1932,
    postal_code: '41702'
  },
  '41712': {
    country: 'US',
    city: 'Ary',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.3638,
    longitude: -83.1546,
    postal_code: '41712'
  },
  '41713': {
    country: 'US',
    city: 'Avawam',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.2254,
    longitude: -83.2736,
    postal_code: '41713'
  },
  '41714': {
    country: 'US',
    city: 'Bear Branch',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.1939,
    longitude: -83.5035,
    postal_code: '41714'
  },
  '41719': {
    country: 'US',
    city: 'Bonnyman',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.2983,
    longitude: -83.2544,
    postal_code: '41719'
  },
  '41721': {
    country: 'US',
    city: 'Buckhorn',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.301,
    longitude: -83.4936,
    postal_code: '41721'
  },
  '41722': {
    country: 'US',
    city: 'Bulan',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.315,
    longitude: -83.1561,
    postal_code: '41722'
  },
  '41723': {
    country: 'US',
    city: 'Busy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.2632,
    longitude: -83.3007,
    postal_code: '41723'
  },
  '41725': {
    country: 'US',
    city: 'Carrie',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.3318,
    longitude: -83.0327,
    postal_code: '41725'
  },
  '41727': {
    country: 'US',
    city: 'Chavies',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.3479,
    longitude: -83.3563,
    postal_code: '41727'
  },
  '41729': {
    country: 'US',
    city: 'Combs',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.2649,
    longitude: -83.2174,
    postal_code: '41729'
  },
  '41731': {
    country: 'US',
    city: 'Cornettsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.134,
    longitude: -83.0768,
    postal_code: '41731'
  },
  '41735': {
    country: 'US',
    city: 'Delphia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.0233,
    longitude: -83.0956,
    postal_code: '41735'
  },
  '41736': {
    country: 'US',
    city: 'Dice',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.3758,
    longitude: -83.2419,
    postal_code: '41736'
  },
  '41739': {
    country: 'US',
    city: 'Dwarf',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.3394,
    longitude: -83.1303,
    postal_code: '41739'
  },
  '41740': {
    country: 'US',
    city: 'Emmalena',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.3499,
    longitude: -83.0471,
    postal_code: '41740'
  },
  '41743': {
    country: 'US',
    city: 'Fisty',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.3064,
    longitude: -83.0904,
    postal_code: '41743'
  },
  '41745': {
    country: 'US',
    city: 'Gays Creek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.3353,
    longitude: -83.4347,
    postal_code: '41745'
  },
  '41746': {
    country: 'US',
    city: 'Happy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.2101,
    longitude: -83.0917,
    postal_code: '41746'
  },
  '41749': {
    country: 'US',
    city: 'Hyden',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.1877,
    longitude: -83.4169,
    postal_code: '41749'
  },
  '41751': {
    country: 'US',
    city: 'Jeff',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.2184,
    longitude: -83.1427,
    postal_code: '41751'
  },
  '41754': {
    country: 'US',
    city: 'Krypton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.3003,
    longitude: -83.3219,
    postal_code: '41754'
  },
  '41759': {
    country: 'US',
    city: 'Sassafras',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.2207,
    longitude: -83.0552,
    postal_code: '41759'
  },
  '41760': {
    country: 'US',
    city: 'Scuddy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.1971,
    longitude: -83.0817,
    postal_code: '41760'
  },
  '41762': {
    country: 'US',
    city: 'Sizerock',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.2187,
    longitude: -83.4974,
    postal_code: '41762'
  },
  '41763': {
    country: 'US',
    city: 'Slemp',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.0705,
    longitude: -83.1136,
    postal_code: '41763'
  },
  '41764': {
    country: 'US',
    city: 'Smilax',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.1214,
    longitude: -83.2539,
    postal_code: '41764'
  },
  '41766': {
    country: 'US',
    city: 'Thousandsticks',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.1851,
    longitude: -83.4288,
    postal_code: '41766'
  },
  '41772': {
    country: 'US',
    city: 'Vest',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.4073,
    longitude: -83.0153,
    postal_code: '41772'
  },
  '41773': {
    country: 'US',
    city: 'Vicco',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.202,
    longitude: -83.0658,
    postal_code: '41773'
  },
  '41774': {
    country: 'US',
    city: 'Viper',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.1585,
    longitude: -83.1405,
    postal_code: '41774'
  },
  '41775': {
    country: 'US',
    city: 'Wendover',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.1079,
    longitude: -83.3563,
    postal_code: '41775'
  },
  '41776': {
    country: 'US',
    city: 'Wooton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.1671,
    longitude: -83.2913,
    postal_code: '41776'
  },
  '41777': {
    country: 'US',
    city: 'Yeaddiss',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Leslie',
    latitude: 37.0565,
    longitude: -83.2361,
    postal_code: '41777'
  },
  '41778': {
    country: 'US',
    city: 'Yerkes',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Perry',
    latitude: 37.283,
    longitude: -83.3212,
    postal_code: '41778'
  },
  '41804': {
    country: 'US',
    city: 'Blackey',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1551,
    longitude: -82.9956,
    postal_code: '41804'
  },
  '41810': {
    country: 'US',
    city: 'Cromona',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1865,
    longitude: -82.6971,
    postal_code: '41810'
  },
  '41812': {
    country: 'US',
    city: 'Deane',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.2409,
    longitude: -82.7696,
    postal_code: '41812'
  },
  '41815': {
    country: 'US',
    city: 'Ermine',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1615,
    longitude: -82.7974,
    postal_code: '41815'
  },
  '41817': {
    country: 'US',
    city: 'Garner',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.3582,
    longitude: -82.9252,
    postal_code: '41817'
  },
  '41819': {
    country: 'US',
    city: 'Gordon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 36.9891,
    longitude: -83.0655,
    postal_code: '41819'
  },
  '41821': {
    country: 'US',
    city: 'Hallie',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.0837,
    longitude: -83.0027,
    postal_code: '41821'
  },
  '41822': {
    country: 'US',
    city: 'Hindman',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.3359,
    longitude: -82.9804,
    postal_code: '41822'
  },
  '41824': {
    country: 'US',
    city: 'Isom',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1932,
    longitude: -82.8754,
    postal_code: '41824'
  },
  '41825': {
    country: 'US',
    city: 'Jackhorn',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.2246,
    longitude: -82.7228,
    postal_code: '41825'
  },
  '41826': {
    country: 'US',
    city: 'Jeremiah',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1699,
    longitude: -82.9258,
    postal_code: '41826'
  },
  '41828': {
    country: 'US',
    city: 'Kite',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.3136,
    longitude: -82.7975,
    postal_code: '41828'
  },
  '41831': {
    country: 'US',
    city: 'Leburn',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.3794,
    longitude: -82.9523,
    postal_code: '41831'
  },
  '41832': {
    country: 'US',
    city: 'Letcher',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1531,
    longitude: -82.9548,
    postal_code: '41832'
  },
  '41833': {
    country: 'US',
    city: 'Linefork',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.0233,
    longitude: -82.9878,
    postal_code: '41833'
  },
  '41834': {
    country: 'US',
    city: 'Littcarr',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.2409,
    longitude: -82.9488,
    postal_code: '41834'
  },
  '41835': {
    country: 'US',
    city: 'Mc Roberts',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.2137,
    longitude: -82.6734,
    postal_code: '41835'
  },
  '41836': {
    country: 'US',
    city: 'Mallie',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.2692,
    longitude: -82.9161,
    postal_code: '41836'
  },
  '41837': {
    country: 'US',
    city: 'Mayking',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1302,
    longitude: -82.7427,
    postal_code: '41837'
  },
  '41838': {
    country: 'US',
    city: 'Millstone',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1766,
    longitude: -82.7517,
    postal_code: '41838'
  },
  '41839': {
    country: 'US',
    city: 'Mousie',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.4331,
    longitude: -82.9018,
    postal_code: '41839'
  },
  '41840': {
    country: 'US',
    city: 'Neon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1876,
    longitude: -82.711,
    postal_code: '41840'
  },
  '41843': {
    country: 'US',
    city: 'Pine Top',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.2868,
    longitude: -82.8741,
    postal_code: '41843'
  },
  '41844': {
    country: 'US',
    city: 'Pippa Passes',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.3491,
    longitude: -82.8742,
    postal_code: '41844'
  },
  '41845': {
    country: 'US',
    city: 'Premium',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1252,
    longitude: -82.9035,
    postal_code: '41845'
  },
  '41847': {
    country: 'US',
    city: 'Redfox',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.2172,
    longitude: -82.9424,
    postal_code: '41847'
  },
  '41848': {
    country: 'US',
    city: 'Roxana',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1102,
    longitude: -82.9407,
    postal_code: '41848'
  },
  '41849': {
    country: 'US',
    city: 'Seco',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1765,
    longitude: -82.7358,
    postal_code: '41849'
  },
  '41855': {
    country: 'US',
    city: 'Thornton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1906,
    longitude: -82.7851,
    postal_code: '41855'
  },
  '41858': {
    country: 'US',
    city: 'Whitesburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Letcher',
    latitude: 37.1388,
    longitude: -82.855,
    postal_code: '41858'
  },
  '41859': {
    country: 'US',
    city: 'Dema',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.3976,
    longitude: -82.7836,
    postal_code: '41859'
  },
  '41861': {
    country: 'US',
    city: 'Raven',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.4043,
    longitude: -82.8261,
    postal_code: '41861'
  },
  '41862': {
    country: 'US',
    city: 'Topmost',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Knott',
    latitude: 37.3485,
    longitude: -82.7926,
    postal_code: '41862'
  },
  '42001': {
    country: 'US',
    city: 'Paducah',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCracken',
    latitude: 37.0634,
    longitude: -88.6632,
    postal_code: '42001'
  },
  '42002': {
    country: 'US',
    city: 'Paducah',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCracken',
    latitude: 37.0855,
    longitude: -88.7125,
    postal_code: '42002'
  },
  '42003': {
    country: 'US',
    city: 'Paducah',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCracken',
    latitude: 37.0368,
    longitude: -88.5934,
    postal_code: '42003'
  },
  '42020': {
    country: 'US',
    city: 'Almo',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Calloway',
    latitude: 36.6923,
    longitude: -88.2929,
    postal_code: '42020'
  },
  '42021': {
    country: 'US',
    city: 'Arlington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carlisle',
    latitude: 36.7903,
    longitude: -89.0128,
    postal_code: '42021'
  },
  '42022': {
    country: 'US',
    city: 'Bandana',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ballard',
    latitude: 37.1459,
    longitude: -88.9455,
    postal_code: '42022'
  },
  '42023': {
    country: 'US',
    city: 'Bardwell',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carlisle',
    latitude: 36.8634,
    longitude: -89.0209,
    postal_code: '42023'
  },
  '42024': {
    country: 'US',
    city: 'Barlow',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ballard',
    latitude: 37.0493,
    longitude: -89.0408,
    postal_code: '42024'
  },
  '42025': {
    country: 'US',
    city: 'Benton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marshall',
    latitude: 36.8806,
    longitude: -88.3548,
    postal_code: '42025'
  },
  '42027': {
    country: 'US',
    city: 'Boaz',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.93,
    longitude: -88.6223,
    postal_code: '42027'
  },
  '42028': {
    country: 'US',
    city: 'Burna',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Livingston',
    latitude: 37.2392,
    longitude: -88.3379,
    postal_code: '42028'
  },
  '42029': {
    country: 'US',
    city: 'Calvert City',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marshall',
    latitude: 37.0147,
    longitude: -88.3811,
    postal_code: '42029'
  },
  '42031': {
    country: 'US',
    city: 'Clinton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hickman',
    latitude: 36.6675,
    longitude: -88.9676,
    postal_code: '42031'
  },
  '42032': {
    country: 'US',
    city: 'Columbus',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hickman',
    latitude: 36.7598,
    longitude: -89.1034,
    postal_code: '42032'
  },
  '42033': {
    country: 'US',
    city: 'Crayne',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Crittenden',
    latitude: 37.2706,
    longitude: -88.0825,
    postal_code: '42033'
  },
  '42035': {
    country: 'US',
    city: 'Cunningham',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carlisle',
    latitude: 36.8963,
    longitude: -88.8728,
    postal_code: '42035'
  },
  '42036': {
    country: 'US',
    city: 'Dexter',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Calloway',
    latitude: 36.7155,
    longitude: -88.236,
    postal_code: '42036'
  },
  '42037': {
    country: 'US',
    city: 'Dycusburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Crittenden',
    latitude: 37.1595,
    longitude: -88.1845,
    postal_code: '42037'
  },
  '42038': {
    country: 'US',
    city: 'Eddyville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lyon',
    latitude: 37.0664,
    longitude: -88.0494,
    postal_code: '42038'
  },
  '42039': {
    country: 'US',
    city: 'Fancy Farm',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.7767,
    longitude: -88.7918,
    postal_code: '42039'
  },
  '42040': {
    country: 'US',
    city: 'Farmington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.6,
    longitude: -88.5186,
    postal_code: '42040'
  },
  '42041': {
    country: 'US',
    city: 'Fulton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fulton',
    latitude: 36.5475,
    longitude: -88.8749,
    postal_code: '42041'
  },
  '42044': {
    country: 'US',
    city: 'Gilbertsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marshall',
    latitude: 36.9655,
    longitude: -88.2667,
    postal_code: '42044'
  },
  '42045': {
    country: 'US',
    city: 'Grand Rivers',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Livingston',
    latitude: 37.0762,
    longitude: -88.2647,
    postal_code: '42045'
  },
  '42047': {
    country: 'US',
    city: 'Hampton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Livingston',
    latitude: 37.2831,
    longitude: -88.3714,
    postal_code: '42047'
  },
  '42048': {
    country: 'US',
    city: 'Hardin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Marshall',
    latitude: 36.7762,
    longitude: -88.2622,
    postal_code: '42048'
  },
  '42049': {
    country: 'US',
    city: 'Hazel',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Calloway',
    latitude: 36.5422,
    longitude: -88.3319,
    postal_code: '42049'
  },
  '42050': {
    country: 'US',
    city: 'Hickman',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Fulton',
    latitude: 36.5593,
    longitude: -89.1947,
    postal_code: '42050'
  },
  '42051': {
    country: 'US',
    city: 'Hickory',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.8478,
    longitude: -88.6788,
    postal_code: '42051'
  },
  '42053': {
    country: 'US',
    city: 'Kevil',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCracken',
    latitude: 37.0872,
    longitude: -88.8764,
    postal_code: '42053'
  },
  '42054': {
    country: 'US',
    city: 'Kirksey',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Calloway',
    latitude: 36.6731,
    longitude: -88.4238,
    postal_code: '42054'
  },
  '42055': {
    country: 'US',
    city: 'Kuttawa',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Lyon',
    latitude: 37.0619,
    longitude: -88.1498,
    postal_code: '42055'
  },
  '42056': {
    country: 'US',
    city: 'La Center',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ballard',
    latitude: 37.083,
    longitude: -88.973,
    postal_code: '42056'
  },
  '42058': {
    country: 'US',
    city: 'Ledbetter',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Livingston',
    latitude: 37.0611,
    longitude: -88.4665,
    postal_code: '42058'
  },
  '42060': {
    country: 'US',
    city: 'Lovelaceville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ballard',
    latitude: 36.9687,
    longitude: -88.8309,
    postal_code: '42060'
  },
  '42061': {
    country: 'US',
    city: 'Lowes',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.8862,
    longitude: -88.7728,
    postal_code: '42061'
  },
  '42063': {
    country: 'US',
    city: 'Lynnville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.5603,
    longitude: -88.5692,
    postal_code: '42063'
  },
  '42064': {
    country: 'US',
    city: 'Marion',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Crittenden',
    latitude: 37.3254,
    longitude: -88.1005,
    postal_code: '42064'
  },
  '42066': {
    country: 'US',
    city: 'Mayfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.7327,
    longitude: -88.6506,
    postal_code: '42066'
  },
  '42069': {
    country: 'US',
    city: 'Melber',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.9197,
    longitude: -88.752,
    postal_code: '42069'
  },
  '42070': {
    country: 'US',
    city: 'Milburn',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Carlisle',
    latitude: 36.7987,
    longitude: -88.8998,
    postal_code: '42070'
  },
  '42071': {
    country: 'US',
    city: 'Murray',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Calloway',
    latitude: 36.6099,
    longitude: -88.3032,
    postal_code: '42071'
  },
  '42076': {
    country: 'US',
    city: 'New Concord',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Calloway',
    latitude: 36.55,
    longitude: -88.0955,
    postal_code: '42076'
  },
  '42078': {
    country: 'US',
    city: 'Salem',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Livingston',
    latitude: 37.2553,
    longitude: -88.2711,
    postal_code: '42078'
  },
  '42079': {
    country: 'US',
    city: 'Sedalia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.5748,
    longitude: -88.5815,
    postal_code: '42079'
  },
  '42081': {
    country: 'US',
    city: 'Smithland',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Livingston',
    latitude: 37.2387,
    longitude: -88.3757,
    postal_code: '42081'
  },
  '42082': {
    country: 'US',
    city: 'Symsonia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.9044,
    longitude: -88.5153,
    postal_code: '42082'
  },
  '42083': {
    country: 'US',
    city: 'Tiline',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Livingston',
    latitude: 37.1457,
    longitude: -88.285,
    postal_code: '42083'
  },
  '42085': {
    country: 'US',
    city: 'Water Valley',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.5694,
    longitude: -88.8084,
    postal_code: '42085'
  },
  '42086': {
    country: 'US',
    city: 'West Paducah',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCracken',
    latitude: 37.0922,
    longitude: -88.7611,
    postal_code: '42086'
  },
  '42087': {
    country: 'US',
    city: 'Wickliffe',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ballard',
    latitude: 36.968,
    longitude: -89.0177,
    postal_code: '42087'
  },
  '42088': {
    country: 'US',
    city: 'Wingo',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Graves',
    latitude: 36.6253,
    longitude: -88.7394,
    postal_code: '42088'
  },
  '42101': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Warren',
    latitude: 37.0174,
    longitude: -86.4518,
    postal_code: '42101'
  },
  '42102': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Warren',
    latitude: 36.9223,
    longitude: -86.387,
    postal_code: '42102'
  },
  '42103': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Warren',
    latitude: 36.9663,
    longitude: -86.3933,
    postal_code: '42103'
  },
  '42104': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Warren',
    latitude: 36.9375,
    longitude: -86.4481,
    postal_code: '42104'
  },
  '42120': {
    country: 'US',
    city: 'Adolphus',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Allen',
    latitude: 36.6775,
    longitude: -86.2636,
    postal_code: '42120'
  },
  '42122': {
    country: 'US',
    city: 'Alvaton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Warren',
    latitude: 36.863,
    longitude: -86.3632,
    postal_code: '42122'
  },
  '42123': {
    country: 'US',
    city: 'Austin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Barren',
    latitude: 36.8124,
    longitude: -85.985,
    postal_code: '42123'
  },
  '42124': {
    country: 'US',
    city: 'Beaumont',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Metcalfe',
    latitude: 36.8705,
    longitude: -85.644,
    postal_code: '42124'
  },
  '42127': {
    country: 'US',
    city: 'Cave City',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Barren',
    latitude: 37.117,
    longitude: -85.9443,
    postal_code: '42127'
  },
  '42128': {
    country: 'US',
    city: 'Drake',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Warren',
    latitude: 36.9834,
    longitude: -86.394,
    postal_code: '42128'
  },
  '42129': {
    country: 'US',
    city: 'Edmonton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Metcalfe',
    latitude: 37.0008,
    longitude: -85.6095,
    postal_code: '42129'
  },
  '42130': {
    country: 'US',
    city: 'Eighty Eight',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Barren',
    latitude: 36.913,
    longitude: -85.7753,
    postal_code: '42130'
  },
  '42131': {
    country: 'US',
    city: 'Etoile',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Barren',
    latitude: 36.8134,
    longitude: -85.9173,
    postal_code: '42131'
  },
  '42133': {
    country: 'US',
    city: 'Fountain Run',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Monroe',
    latitude: 36.72,
    longitude: -85.952,
    postal_code: '42133'
  },
  '42134': {
    country: 'US',
    city: 'Franklin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Simpson',
    latitude: 36.7254,
    longitude: -86.57,
    postal_code: '42134'
  },
  '42135': {
    country: 'US',
    city: 'Franklin',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Simpson',
    latitude: 36.7223,
    longitude: -86.5772,
    postal_code: '42135'
  },
  '42140': {
    country: 'US',
    city: 'Gamaliel',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Monroe',
    latitude: 36.654,
    longitude: -85.8134,
    postal_code: '42140'
  },
  '42141': {
    country: 'US',
    city: 'Glasgow',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Barren',
    latitude: 36.9882,
    longitude: -85.9221,
    postal_code: '42141'
  },
  '42142': {
    country: 'US',
    city: 'Glasgow',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Barren',
    latitude: 36.9959,
    longitude: -85.9119,
    postal_code: '42142'
  },
  '42151': {
    country: 'US',
    city: 'Hestand',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Monroe',
    latitude: 36.6535,
    longitude: -85.5698,
    postal_code: '42151'
  },
  '42152': {
    country: 'US',
    city: 'Hiseville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Barren',
    latitude: 37.0988,
    longitude: -85.8165,
    postal_code: '42152'
  },
  '42153': {
    country: 'US',
    city: 'Holland',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Allen',
    latitude: 36.6673,
    longitude: -86.0498,
    postal_code: '42153'
  },
  '42154': {
    country: 'US',
    city: 'Knob Lick',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Metcalfe',
    latitude: 37.0556,
    longitude: -85.6985,
    postal_code: '42154'
  },
  '42156': {
    country: 'US',
    city: 'Lucas',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Barren',
    latitude: 36.8516,
    longitude: -86.0523,
    postal_code: '42156'
  },
  '42157': {
    country: 'US',
    city: 'Mount Hermon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Monroe',
    latitude: 36.7723,
    longitude: -85.8195,
    postal_code: '42157'
  },
  '42159': {
    country: 'US',
    city: 'Oakland',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Warren',
    latitude: 36.9985,
    longitude: -86.2501,
    postal_code: '42159'
  },
  '42160': {
    country: 'US',
    city: 'Park City',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Barren',
    latitude: 37.0939,
    longitude: -86.0464,
    postal_code: '42160'
  },
  '42163': {
    country: 'US',
    city: 'Rocky Hill',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Edmonson',
    latitude: 37.0689,
    longitude: -86.1364,
    postal_code: '42163'
  },
  '42164': {
    country: 'US',
    city: 'Scottsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Allen',
    latitude: 36.7614,
    longitude: -86.1929,
    postal_code: '42164'
  },
  '42166': {
    country: 'US',
    city: 'Summer Shade',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Metcalfe',
    latitude: 36.8883,
    longitude: -85.7083,
    postal_code: '42166'
  },
  '42167': {
    country: 'US',
    city: 'Tompkinsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Monroe',
    latitude: 36.7236,
    longitude: -85.7009,
    postal_code: '42167'
  },
  '42170': {
    country: 'US',
    city: 'Woodburn',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Warren',
    latitude: 36.8557,
    longitude: -86.5623,
    postal_code: '42170'
  },
  '42171': {
    country: 'US',
    city: 'Smiths Grove',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Warren',
    latitude: 37.0581,
    longitude: -86.1938,
    postal_code: '42171'
  },
  '42201': {
    country: 'US',
    city: 'Aberdeen',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Butler',
    latitude: 37.2539,
    longitude: -86.6817,
    postal_code: '42201'
  },
  '42202': {
    country: 'US',
    city: 'Adairville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Logan',
    latitude: 36.6914,
    longitude: -86.8585,
    postal_code: '42202'
  },
  '42204': {
    country: 'US',
    city: 'Allensville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Todd',
    latitude: 36.7167,
    longitude: -87.0661,
    postal_code: '42204'
  },
  '42206': {
    country: 'US',
    city: 'Auburn',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Logan',
    latitude: 36.8818,
    longitude: -86.7198,
    postal_code: '42206'
  },
  '42207': {
    country: 'US',
    city: 'Bee Spring',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Edmonson',
    latitude: 37.2975,
    longitude: -86.2794,
    postal_code: '42207'
  },
  '42210': {
    country: 'US',
    city: 'Brownsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Edmonson',
    latitude: 37.2229,
    longitude: -86.2923,
    postal_code: '42210'
  },
  '42211': {
    country: 'US',
    city: 'Cadiz',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Trigg',
    latitude: 36.802,
    longitude: -87.8286,
    postal_code: '42211'
  },
  '42214': {
    country: 'US',
    city: 'Center',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Metcalfe',
    latitude: 37.1177,
    longitude: -85.7038,
    postal_code: '42214'
  },
  '42215': {
    country: 'US',
    city: 'Cerulean',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Trigg',
    latitude: 36.9496,
    longitude: -87.6648,
    postal_code: '42215'
  },
  '42216': {
    country: 'US',
    city: 'Clifty',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Todd',
    latitude: 37.0045,
    longitude: -87.1521,
    postal_code: '42216'
  },
  '42217': {
    country: 'US',
    city: 'Crofton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Christian',
    latitude: 37.0344,
    longitude: -87.4891,
    postal_code: '42217'
  },
  '42219': {
    country: 'US',
    city: 'Dunbar',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Butler',
    latitude: 37.1709,
    longitude: -86.7678,
    postal_code: '42219'
  },
  '42220': {
    country: 'US',
    city: 'Elkton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Todd',
    latitude: 36.9094,
    longitude: -87.1678,
    postal_code: '42220'
  },
  '42221': {
    country: 'US',
    city: 'Fairview',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Christian',
    latitude: 36.8434,
    longitude: -87.3039,
    postal_code: '42221'
  },
  '42223': {
    country: 'US',
    city: 'Fort Campbell',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Christian',
    latitude: 36.6543,
    longitude: -87.4606,
    postal_code: '42223'
  },
  '42232': {
    country: 'US',
    city: 'Gracey',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Christian',
    latitude: 36.8564,
    longitude: -87.6545,
    postal_code: '42232'
  },
  '42234': {
    country: 'US',
    city: 'Guthrie',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Todd',
    latitude: 36.7149,
    longitude: -87.1502,
    postal_code: '42234'
  },
  '42236': {
    country: 'US',
    city: 'Herndon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Christian',
    latitude: 36.7085,
    longitude: -87.6082,
    postal_code: '42236'
  },
  '42240': {
    country: 'US',
    city: 'Hopkinsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Christian',
    latitude: 36.8621,
    longitude: -87.4851,
    postal_code: '42240'
  },
  '42241': {
    country: 'US',
    city: 'Hopkinsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Christian',
    latitude: 36.8656,
    longitude: -87.4912,
    postal_code: '42241'
  },
  '42252': {
    country: 'US',
    city: 'Jetson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Butler',
    latitude: 37.2483,
    longitude: -86.5093,
    postal_code: '42252'
  },
  '42254': {
    country: 'US',
    city: 'La Fayette',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Christian',
    latitude: 36.6582,
    longitude: -87.6563,
    postal_code: '42254'
  },
  '42256': {
    country: 'US',
    city: 'Lewisburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Logan',
    latitude: 37.0037,
    longitude: -86.9887,
    postal_code: '42256'
  },
  '42259': {
    country: 'US',
    city: 'Mammoth Cave',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Edmonson',
    latitude: 37.2802,
    longitude: -86.1688,
    postal_code: '42259'
  },
  '42261': {
    country: 'US',
    city: 'Morgantown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Butler',
    latitude: 37.2002,
    longitude: -86.6859,
    postal_code: '42261'
  },
  '42262': {
    country: 'US',
    city: 'Oak Grove',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Christian',
    latitude: 36.6652,
    longitude: -87.4255,
    postal_code: '42262'
  },
  '42265': {
    country: 'US',
    city: 'Olmstead',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Logan',
    latitude: 36.7595,
    longitude: -86.9804,
    postal_code: '42265'
  },
  '42266': {
    country: 'US',
    city: 'Pembroke',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Christian',
    latitude: 36.7759,
    longitude: -87.3556,
    postal_code: '42266'
  },
  '42273': {
    country: 'US',
    city: 'Rochester',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Butler',
    latitude: 37.2048,
    longitude: -86.8592,
    postal_code: '42273'
  },
  '42274': {
    country: 'US',
    city: 'Rockfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Warren',
    latitude: 36.9241,
    longitude: -86.5979,
    postal_code: '42274'
  },
  '42275': {
    country: 'US',
    city: 'Roundhill',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Edmonson',
    latitude: 37.256,
    longitude: -86.407,
    postal_code: '42275'
  },
  '42276': {
    country: 'US',
    city: 'Russellville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Logan',
    latitude: 36.8453,
    longitude: -86.8823,
    postal_code: '42276'
  },
  '42280': {
    country: 'US',
    city: 'Sharon Grove',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Todd',
    latitude: 36.9278,
    longitude: -87.1003,
    postal_code: '42280'
  },
  '42285': {
    country: 'US',
    city: 'Sweeden',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Edmonson',
    latitude: 37.2641,
    longitude: -86.2978,
    postal_code: '42285'
  },
  '42286': {
    country: 'US',
    city: 'Trenton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Todd',
    latitude: 36.7314,
    longitude: -87.2611,
    postal_code: '42286'
  },
  '42288': {
    country: 'US',
    city: 'Woodbury',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Butler',
    latitude: 37.1834,
    longitude: -86.6339,
    postal_code: '42288'
  },
  '42301': {
    country: 'US',
    city: 'Owensboro',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.7513,
    longitude: -87.1554,
    postal_code: '42301'
  },
  '42302': {
    country: 'US',
    city: 'Owensboro',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.7455,
    longitude: -87.1128,
    postal_code: '42302'
  },
  '42303': {
    country: 'US',
    city: 'Owensboro',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.7559,
    longitude: -87.0803,
    postal_code: '42303'
  },
  '42304': {
    country: 'US',
    city: 'Owensboro',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.7455,
    longitude: -87.1128,
    postal_code: '42304'
  },
  '42320': {
    country: 'US',
    city: 'Beaver Dam',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.387,
    longitude: -86.8729,
    postal_code: '42320'
  },
  '42321': {
    country: 'US',
    city: 'Beech Creek',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.1716,
    longitude: -87.0559,
    postal_code: '42321'
  },
  '42322': {
    country: 'US',
    city: 'Beech Grove',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McLean',
    latitude: 37.6162,
    longitude: -87.3964,
    postal_code: '42322'
  },
  '42323': {
    country: 'US',
    city: 'Beechmont',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.1737,
    longitude: -87.0349,
    postal_code: '42323'
  },
  '42324': {
    country: 'US',
    city: 'Belton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.1511,
    longitude: -86.9774,
    postal_code: '42324'
  },
  '42325': {
    country: 'US',
    city: 'Bremen',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.343,
    longitude: -87.233,
    postal_code: '42325'
  },
  '42326': {
    country: 'US',
    city: 'Browder',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.1999,
    longitude: -87.0001,
    postal_code: '42326'
  },
  '42327': {
    country: 'US',
    city: 'Calhoun',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McLean',
    latitude: 37.575,
    longitude: -87.2773,
    postal_code: '42327'
  },
  '42328': {
    country: 'US',
    city: 'Centertown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.4079,
    longitude: -87.009,
    postal_code: '42328'
  },
  '42330': {
    country: 'US',
    city: 'Central City',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.3007,
    longitude: -87.1202,
    postal_code: '42330'
  },
  '42332': {
    country: 'US',
    city: 'Cleaton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.2533,
    longitude: -87.0897,
    postal_code: '42332'
  },
  '42333': {
    country: 'US',
    city: 'Cromwell',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.3418,
    longitude: -86.77,
    postal_code: '42333'
  },
  '42334': {
    country: 'US',
    city: 'Curdsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.735,
    longitude: -87.3317,
    postal_code: '42334'
  },
  '42337': {
    country: 'US',
    city: 'Drakesboro',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.2136,
    longitude: -87.048,
    postal_code: '42337'
  },
  '42338': {
    country: 'US',
    city: 'Dundee',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.552,
    longitude: -86.7776,
    postal_code: '42338'
  },
  '42339': {
    country: 'US',
    city: 'Dunmor',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.084,
    longitude: -87.0079,
    postal_code: '42339'
  },
  '42343': {
    country: 'US',
    city: 'Fordsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.6297,
    longitude: -86.7263,
    postal_code: '42343'
  },
  '42344': {
    country: 'US',
    city: 'Graham',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.2467,
    longitude: -87.2976,
    postal_code: '42344'
  },
  '42345': {
    country: 'US',
    city: 'Greenville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.2076,
    longitude: -87.1806,
    postal_code: '42345'
  },
  '42347': {
    country: 'US',
    city: 'Hartford',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.4785,
    longitude: -86.918,
    postal_code: '42347'
  },
  '42348': {
    country: 'US',
    city: 'Hawesville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hancock',
    latitude: 37.8503,
    longitude: -86.7638,
    postal_code: '42348'
  },
  '42349': {
    country: 'US',
    city: 'Horse Branch',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.4234,
    longitude: -86.6987,
    postal_code: '42349'
  },
  '42350': {
    country: 'US',
    city: 'Island',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McLean',
    latitude: 37.4471,
    longitude: -87.1692,
    postal_code: '42350'
  },
  '42351': {
    country: 'US',
    city: 'Lewisport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hancock',
    latitude: 37.909,
    longitude: -86.8957,
    postal_code: '42351'
  },
  '42352': {
    country: 'US',
    city: 'Livermore',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McLean',
    latitude: 37.5045,
    longitude: -87.1239,
    postal_code: '42352'
  },
  '42354': {
    country: 'US',
    city: 'Mc Henry',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.3778,
    longitude: -86.9298,
    postal_code: '42354'
  },
  '42355': {
    country: 'US',
    city: 'Maceo',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.8436,
    longitude: -86.9999,
    postal_code: '42355'
  },
  '42356': {
    country: 'US',
    city: 'Maple Mount',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.6932,
    longitude: -87.3238,
    postal_code: '42356'
  },
  '42361': {
    country: 'US',
    city: 'Olaton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.5259,
    longitude: -86.6875,
    postal_code: '42361'
  },
  '42364': {
    country: 'US',
    city: 'Pellville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hancock',
    latitude: 37.7523,
    longitude: -86.8136,
    postal_code: '42364'
  },
  '42366': {
    country: 'US',
    city: 'Philpot',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.7183,
    longitude: -86.9372,
    postal_code: '42366'
  },
  '42367': {
    country: 'US',
    city: 'Powderly',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.2429,
    longitude: -87.1549,
    postal_code: '42367'
  },
  '42368': {
    country: 'US',
    city: 'Reynolds Station',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hancock',
    latitude: 37.7403,
    longitude: -86.7414,
    postal_code: '42368'
  },
  '42369': {
    country: 'US',
    city: 'Rockport',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.3554,
    longitude: -87.0065,
    postal_code: '42369'
  },
  '42370': {
    country: 'US',
    city: 'Rosine',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Ohio',
    latitude: 37.449,
    longitude: -86.7389,
    postal_code: '42370'
  },
  '42371': {
    country: 'US',
    city: 'Rumsey',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McLean',
    latitude: 37.5076,
    longitude: -87.2806,
    postal_code: '42371'
  },
  '42372': {
    country: 'US',
    city: 'Sacramento',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McLean',
    latitude: 37.4177,
    longitude: -87.2736,
    postal_code: '42372'
  },
  '42374': {
    country: 'US',
    city: 'South Carrollton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Muhlenberg',
    latitude: 37.3375,
    longitude: -87.1419,
    postal_code: '42374'
  },
  '42376': {
    country: 'US',
    city: 'Utica',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.6206,
    longitude: -87.0591,
    postal_code: '42376'
  },
  '42377': {
    country: 'US',
    city: 'West Louisville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.6967,
    longitude: -87.2869,
    postal_code: '42377'
  },
  '42378': {
    country: 'US',
    city: 'Whitesville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Daviess',
    latitude: 37.6834,
    longitude: -86.8699,
    postal_code: '42378'
  },
  '42402': {
    country: 'US',
    city: 'Baskett',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henderson',
    latitude: 37.8709,
    longitude: -87.4625,
    postal_code: '42402'
  },
  '42404': {
    country: 'US',
    city: 'Clay',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Webster',
    latitude: 37.4756,
    longitude: -87.8368,
    postal_code: '42404'
  },
  '42406': {
    country: 'US',
    city: 'Corydon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henderson',
    latitude: 37.7443,
    longitude: -87.7,
    postal_code: '42406'
  },
  '42408': {
    country: 'US',
    city: 'Dawson Springs',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hopkins',
    latitude: 37.1964,
    longitude: -87.6821,
    postal_code: '42408'
  },
  '42409': {
    country: 'US',
    city: 'Dixon',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Webster',
    latitude: 37.5106,
    longitude: -87.7019,
    postal_code: '42409'
  },
  '42410': {
    country: 'US',
    city: 'Earlington',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hopkins',
    latitude: 37.2742,
    longitude: -87.5119,
    postal_code: '42410'
  },
  '42411': {
    country: 'US',
    city: 'Fredonia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Caldwell',
    latitude: 37.213,
    longitude: -88.0112,
    postal_code: '42411'
  },
  '42413': {
    country: 'US',
    city: 'Hanson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hopkins',
    latitude: 37.4382,
    longitude: -87.4751,
    postal_code: '42413'
  },
  '42419': {
    country: 'US',
    city: 'Henderson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henderson',
    latitude: 37.8072,
    longitude: -87.5991,
    postal_code: '42419'
  },
  '42420': {
    country: 'US',
    city: 'Henderson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henderson',
    latitude: 37.8274,
    longitude: -87.5632,
    postal_code: '42420'
  },
  '42431': {
    country: 'US',
    city: 'Madisonville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hopkins',
    latitude: 37.3256,
    longitude: -87.4953,
    postal_code: '42431'
  },
  '42436': {
    country: 'US',
    city: 'Manitou',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hopkins',
    latitude: 37.4067,
    longitude: -87.5639,
    postal_code: '42436'
  },
  '42437': {
    country: 'US',
    city: 'Morganfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Union',
    latitude: 37.6869,
    longitude: -87.9439,
    postal_code: '42437'
  },
  '42440': {
    country: 'US',
    city: 'Mortons Gap',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hopkins',
    latitude: 37.249,
    longitude: -87.4615,
    postal_code: '42440'
  },
  '42441': {
    country: 'US',
    city: 'Nebo',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hopkins',
    latitude: 37.3683,
    longitude: -87.6865,
    postal_code: '42441'
  },
  '42442': {
    country: 'US',
    city: 'Nortonville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hopkins',
    latitude: 37.1834,
    longitude: -87.4605,
    postal_code: '42442'
  },
  '42444': {
    country: 'US',
    city: 'Poole',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Webster',
    latitude: 37.641,
    longitude: -87.6439,
    postal_code: '42444'
  },
  '42445': {
    country: 'US',
    city: 'Princeton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Caldwell',
    latitude: 37.1151,
    longitude: -87.8632,
    postal_code: '42445'
  },
  '42450': {
    country: 'US',
    city: 'Providence',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Webster',
    latitude: 37.405,
    longitude: -87.7505,
    postal_code: '42450'
  },
  '42451': {
    country: 'US',
    city: 'Reed',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henderson',
    latitude: 37.8588,
    longitude: -87.3704,
    postal_code: '42451'
  },
  '42452': {
    country: 'US',
    city: 'Robards',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henderson',
    latitude: 37.6758,
    longitude: -87.5266,
    postal_code: '42452'
  },
  '42453': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hopkins',
    latitude: 37.1604,
    longitude: -87.5697,
    postal_code: '42453'
  },
  '42455': {
    country: 'US',
    city: 'Sebree',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Webster',
    latitude: 37.5889,
    longitude: -87.5255,
    postal_code: '42455'
  },
  '42456': {
    country: 'US',
    city: 'Slaughters',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Webster',
    latitude: 37.5054,
    longitude: -87.5053,
    postal_code: '42456'
  },
  '42457': {
    country: 'US',
    city: 'Smith Mills',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henderson',
    latitude: 37.8323,
    longitude: -87.7889,
    postal_code: '42457'
  },
  '42458': {
    country: 'US',
    city: 'Spottsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Henderson',
    latitude: 37.8399,
    longitude: -87.4247,
    postal_code: '42458'
  },
  '42459': {
    country: 'US',
    city: 'Sturgis',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Union',
    latitude: 37.5487,
    longitude: -87.9965,
    postal_code: '42459'
  },
  '42460': {
    country: 'US',
    city: 'Sullivan',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Union',
    latitude: 37.494,
    longitude: -87.9328,
    postal_code: '42460'
  },
  '42461': {
    country: 'US',
    city: 'Uniontown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Union',
    latitude: 37.7677,
    longitude: -87.9263,
    postal_code: '42461'
  },
  '42462': {
    country: 'US',
    city: 'Waverly',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Union',
    latitude: 37.743,
    longitude: -87.8067,
    postal_code: '42462'
  },
  '42463': {
    country: 'US',
    city: 'Wheatcroft',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Webster',
    latitude: 37.488,
    longitude: -87.8661,
    postal_code: '42463'
  },
  '42464': {
    country: 'US',
    city: 'White Plains',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hopkins',
    latitude: 37.1788,
    longitude: -87.3644,
    postal_code: '42464'
  },
  '42501': {
    country: 'US',
    city: 'Somerset',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 37.1029,
    longitude: -84.5443,
    postal_code: '42501'
  },
  '42502': {
    country: 'US',
    city: 'Somerset',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 37.092,
    longitude: -84.6041,
    postal_code: '42502'
  },
  '42503': {
    country: 'US',
    city: 'Somerset',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 37.1566,
    longitude: -84.5248,
    postal_code: '42503'
  },
  '42516': {
    country: 'US',
    city: 'Bethelridge',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Casey',
    latitude: 37.2337,
    longitude: -84.7583,
    postal_code: '42516'
  },
  '42518': {
    country: 'US',
    city: 'Bronston',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 36.9525,
    longitude: -84.6314,
    postal_code: '42518'
  },
  '42519': {
    country: 'US',
    city: 'Burnside',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 36.9393,
    longitude: -84.5357,
    postal_code: '42519'
  },
  '42528': {
    country: 'US',
    city: 'Dunnville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Casey',
    latitude: 37.1906,
    longitude: -84.9835,
    postal_code: '42528'
  },
  '42533': {
    country: 'US',
    city: 'Ferguson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 37.0677,
    longitude: -84.5927,
    postal_code: '42533'
  },
  '42539': {
    country: 'US',
    city: 'Liberty',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Casey',
    latitude: 37.3146,
    longitude: -84.9719,
    postal_code: '42539'
  },
  '42541': {
    country: 'US',
    city: 'Middleburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Casey',
    latitude: 37.3591,
    longitude: -84.8321,
    postal_code: '42541'
  },
  '42544': {
    country: 'US',
    city: 'Nancy',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 37.0631,
    longitude: -84.7192,
    postal_code: '42544'
  },
  '42553': {
    country: 'US',
    city: 'Science Hill',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 37.1683,
    longitude: -84.6487,
    postal_code: '42553'
  },
  '42558': {
    country: 'US',
    city: 'Tateville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 36.9492,
    longitude: -84.5815,
    postal_code: '42558'
  },
  '42564': {
    country: 'US',
    city: 'West Somerset',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 37.1114,
    longitude: -84.5929,
    postal_code: '42564'
  },
  '42565': {
    country: 'US',
    city: 'Windsor',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Casey',
    latitude: 37.1568,
    longitude: -84.8799,
    postal_code: '42565'
  },
  '42566': {
    country: 'US',
    city: 'Yosemite',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Casey',
    latitude: 37.3074,
    longitude: -84.8124,
    postal_code: '42566'
  },
  '42567': {
    country: 'US',
    city: 'Eubank',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Pulaski',
    latitude: 37.2642,
    longitude: -84.6066,
    postal_code: '42567'
  },
  '42602': {
    country: 'US',
    city: 'Albany',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clinton',
    latitude: 36.6857,
    longitude: -85.1407,
    postal_code: '42602'
  },
  '42603': {
    country: 'US',
    city: 'Alpha',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Clinton',
    latitude: 36.7824,
    longitude: -85.0275,
    postal_code: '42603'
  },
  '42629': {
    country: 'US',
    city: 'Jamestown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Russell',
    latitude: 36.968,
    longitude: -85.0968,
    postal_code: '42629'
  },
  '42631': {
    country: 'US',
    city: 'Marshes Siding',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCreary',
    latitude: 36.7437,
    longitude: -84.4808,
    postal_code: '42631'
  },
  '42633': {
    country: 'US',
    city: 'Monticello',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Wayne',
    latitude: 36.8674,
    longitude: -84.8254,
    postal_code: '42633'
  },
  '42634': {
    country: 'US',
    city: 'Parkers Lake',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCreary',
    latitude: 36.8347,
    longitude: -84.4436,
    postal_code: '42634'
  },
  '42635': {
    country: 'US',
    city: 'Pine Knot',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCreary',
    latitude: 36.6807,
    longitude: -84.3984,
    postal_code: '42635'
  },
  '42638': {
    country: 'US',
    city: 'Revelo',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCreary',
    latitude: 36.6734,
    longitude: -84.4722,
    postal_code: '42638'
  },
  '42642': {
    country: 'US',
    city: 'Russell Springs',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Russell',
    latitude: 37.0551,
    longitude: -85.0375,
    postal_code: '42642'
  },
  '42647': {
    country: 'US',
    city: 'Stearns',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCreary',
    latitude: 36.7082,
    longitude: -84.5165,
    postal_code: '42647'
  },
  '42649': {
    country: 'US',
    city: 'Strunk',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCreary',
    latitude: 36.6191,
    longitude: -84.4308,
    postal_code: '42649'
  },
  '42653': {
    country: 'US',
    city: 'Whitley City',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'McCreary',
    latitude: 36.7217,
    longitude: -84.4676,
    postal_code: '42653'
  },
  '42701': {
    country: 'US',
    city: 'Elizabethtown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.6848,
    longitude: -85.8784,
    postal_code: '42701'
  },
  '42702': {
    country: 'US',
    city: 'Elizabethtown',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.694,
    longitude: -85.8591,
    postal_code: '42702'
  },
  '42712': {
    country: 'US',
    city: 'Big Clifty',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grayson',
    latitude: 37.5278,
    longitude: -86.1395,
    postal_code: '42712'
  },
  '42713': {
    country: 'US',
    city: 'Bonnieville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hart',
    latitude: 37.3741,
    longitude: -85.896,
    postal_code: '42713'
  },
  '42715': {
    country: 'US',
    city: 'Breeding',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Adair',
    latitude: 36.9553,
    longitude: -85.4036,
    postal_code: '42715'
  },
  '42716': {
    country: 'US',
    city: 'Buffalo',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Larue',
    latitude: 37.512,
    longitude: -85.6986,
    postal_code: '42716'
  },
  '42717': {
    country: 'US',
    city: 'Burkesville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Cumberland',
    latitude: 36.8068,
    longitude: -85.397,
    postal_code: '42717'
  },
  '42718': {
    country: 'US',
    city: 'Campbellsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Taylor',
    latitude: 37.3466,
    longitude: -85.3508,
    postal_code: '42718'
  },
  '42719': {
    country: 'US',
    city: 'Campbellsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Taylor',
    latitude: 37.3379,
    longitude: -85.3304,
    postal_code: '42719'
  },
  '42720': {
    country: 'US',
    city: 'Cane Valley',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Adair',
    latitude: 37.1803,
    longitude: -85.3197,
    postal_code: '42720'
  },
  '42721': {
    country: 'US',
    city: 'Caneyville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grayson',
    latitude: 37.4222,
    longitude: -86.4702,
    postal_code: '42721'
  },
  '42722': {
    country: 'US',
    city: 'Canmer',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hart',
    latitude: 37.2696,
    longitude: -85.7203,
    postal_code: '42722'
  },
  '42724': {
    country: 'US',
    city: 'Cecilia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.669,
    longitude: -86.0545,
    postal_code: '42724'
  },
  '42726': {
    country: 'US',
    city: 'Clarkson',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grayson',
    latitude: 37.4336,
    longitude: -86.2083,
    postal_code: '42726'
  },
  '42728': {
    country: 'US',
    city: 'Columbia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Adair',
    latitude: 37.1161,
    longitude: -85.2656,
    postal_code: '42728'
  },
  '42729': {
    country: 'US',
    city: 'Cub Run',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hart',
    latitude: 37.3149,
    longitude: -86.0813,
    postal_code: '42729'
  },
  '42732': {
    country: 'US',
    city: 'Eastview',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.6061,
    longitude: -86.0906,
    postal_code: '42732'
  },
  '42733': {
    country: 'US',
    city: 'Elk Horn',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Taylor',
    latitude: 37.3393,
    longitude: -85.1918,
    postal_code: '42733'
  },
  '42740': {
    country: 'US',
    city: 'Glendale',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.6034,
    longitude: -85.8921,
    postal_code: '42740'
  },
  '42741': {
    country: 'US',
    city: 'Glens Fork',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Adair',
    latitude: 37.007,
    longitude: -85.2487,
    postal_code: '42741'
  },
  '42742': {
    country: 'US',
    city: 'Gradyville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Adair',
    latitude: 37.0912,
    longitude: -85.465,
    postal_code: '42742'
  },
  '42743': {
    country: 'US',
    city: 'Greensburg',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Green',
    latitude: 37.243,
    longitude: -85.5236,
    postal_code: '42743'
  },
  '42746': {
    country: 'US',
    city: 'Hardyville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hart',
    latitude: 37.2249,
    longitude: -85.7542,
    postal_code: '42746'
  },
  '42748': {
    country: 'US',
    city: 'Hodgenville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Larue',
    latitude: 37.5746,
    longitude: -85.7232,
    postal_code: '42748'
  },
  '42749': {
    country: 'US',
    city: 'Horse Cave',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hart',
    latitude: 37.1849,
    longitude: -85.8785,
    postal_code: '42749'
  },
  '42753': {
    country: 'US',
    city: 'Knifley',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Adair',
    latitude: 37.2313,
    longitude: -85.172,
    postal_code: '42753'
  },
  '42754': {
    country: 'US',
    city: 'Leitchfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grayson',
    latitude: 37.4603,
    longitude: -86.3249,
    postal_code: '42754'
  },
  '42755': {
    country: 'US',
    city: 'Leitchfield',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grayson',
    latitude: 37.4801,
    longitude: -86.2939,
    postal_code: '42755'
  },
  '42757': {
    country: 'US',
    city: 'Magnolia',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Larue',
    latitude: 37.4165,
    longitude: -85.7308,
    postal_code: '42757'
  },
  '42758': {
    country: 'US',
    city: 'Mannsville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Taylor',
    latitude: 37.3726,
    longitude: -85.1966,
    postal_code: '42758'
  },
  '42759': {
    country: 'US',
    city: 'Marrowbone',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Cumberland',
    latitude: 36.8306,
    longitude: -85.5015,
    postal_code: '42759'
  },
  '42762': {
    country: 'US',
    city: 'Millwood',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Grayson',
    latitude: 37.4446,
    longitude: -86.4008,
    postal_code: '42762'
  },
  '42764': {
    country: 'US',
    city: 'Mount Sherman',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Larue',
    latitude: 37.426,
    longitude: -85.6191,
    postal_code: '42764'
  },
  '42765': {
    country: 'US',
    city: 'Munfordville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hart',
    latitude: 37.2898,
    longitude: -85.9201,
    postal_code: '42765'
  },
  '42776': {
    country: 'US',
    city: 'Sonora',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.5221,
    longitude: -85.923,
    postal_code: '42776'
  },
  '42782': {
    country: 'US',
    city: 'Summersville',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Green',
    latitude: 37.3419,
    longitude: -85.6194,
    postal_code: '42782'
  },
  '42784': {
    country: 'US',
    city: 'Upton',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.4568,
    longitude: -85.9086,
    postal_code: '42784'
  },
  '42788': {
    country: 'US',
    city: 'White Mills',
    state: 'Kentucky',
    state_short: 'KY',
    county: 'Hardin',
    latitude: 37.5438,
    longitude: -86.0395,
    postal_code: '42788'
  },
  '43001': {
    country: 'US',
    city: 'Alexandria',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.106,
    longitude: -82.6077,
    postal_code: '43001'
  },
  '43002': {
    country: 'US',
    city: 'Amlin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.072,
    longitude: -83.1792,
    postal_code: '43002'
  },
  '43003': {
    country: 'US',
    city: 'Ashley',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Delaware',
    latitude: 40.4163,
    longitude: -82.9542,
    postal_code: '43003'
  },
  '43004': {
    country: 'US',
    city: 'Blacklick',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.021,
    longitude: -82.8079,
    postal_code: '43004'
  },
  '43005': {
    country: 'US',
    city: 'Bladensburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Knox',
    latitude: 40.2845,
    longitude: -82.284,
    postal_code: '43005'
  },
  '43006': {
    country: 'US',
    city: 'Brinkhaven',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Knox',
    latitude: 40.4583,
    longitude: -82.1553,
    postal_code: '43006'
  },
  '43007': {
    country: 'US',
    city: 'Broadway',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Union',
    latitude: 40.3406,
    longitude: -83.4163,
    postal_code: '43007'
  },
  '43008': {
    country: 'US',
    city: 'Buckeye Lake',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 39.9337,
    longitude: -82.4724,
    postal_code: '43008'
  },
  '43009': {
    country: 'US',
    city: 'Cable',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Champaign',
    latitude: 40.1784,
    longitude: -83.647,
    postal_code: '43009'
  },
  '43010': {
    country: 'US',
    city: 'Catawba',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.9991,
    longitude: -83.6222,
    postal_code: '43010'
  },
  '43011': {
    country: 'US',
    city: 'Centerburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Knox',
    latitude: 40.2865,
    longitude: -82.68,
    postal_code: '43011'
  },
  '43013': {
    country: 'US',
    city: 'Croton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.2376,
    longitude: -82.6989,
    postal_code: '43013'
  },
  '43014': {
    country: 'US',
    city: 'Danville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Knox',
    latitude: 40.4557,
    longitude: -82.2639,
    postal_code: '43014'
  },
  '43015': {
    country: 'US',
    city: 'Delaware',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Delaware',
    latitude: 40.2932,
    longitude: -83.0723,
    postal_code: '43015'
  },
  '43016': {
    country: 'US',
    city: 'Dublin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0985,
    longitude: -83.1537,
    postal_code: '43016'
  },
  '43017': {
    country: 'US',
    city: 'Dublin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.1093,
    longitude: -83.1146,
    postal_code: '43017'
  },
  '43018': {
    country: 'US',
    city: 'Etna',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 39.9572,
    longitude: -82.6837,
    postal_code: '43018'
  },
  '43019': {
    country: 'US',
    city: 'Fredericktown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Knox',
    latitude: 40.4976,
    longitude: -82.5857,
    postal_code: '43019'
  },
  '43021': {
    country: 'US',
    city: 'Galena',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Delaware',
    latitude: 40.2011,
    longitude: -82.8749,
    postal_code: '43021'
  },
  '43022': {
    country: 'US',
    city: 'Gambier',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Knox',
    latitude: 40.3782,
    longitude: -82.3828,
    postal_code: '43022'
  },
  '43023': {
    country: 'US',
    city: 'Granville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.0788,
    longitude: -82.5194,
    postal_code: '43023'
  },
  '43025': {
    country: 'US',
    city: 'Hebron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 39.9535,
    longitude: -82.4919,
    postal_code: '43025'
  },
  '43026': {
    country: 'US',
    city: 'Hilliard',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0322,
    longitude: -83.1383,
    postal_code: '43026'
  },
  '43027': {
    country: 'US',
    city: 'Homer',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.2528,
    longitude: -82.5177,
    postal_code: '43027'
  },
  '43028': {
    country: 'US',
    city: 'Howard',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Knox',
    latitude: 40.4158,
    longitude: -82.3334,
    postal_code: '43028'
  },
  '43029': {
    country: 'US',
    city: 'Irwin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Union',
    latitude: 40.1284,
    longitude: -83.4587,
    postal_code: '43029'
  },
  '43030': {
    country: 'US',
    city: 'Jacksontown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 39.9595,
    longitude: -82.4129,
    postal_code: '43030'
  },
  '43031': {
    country: 'US',
    city: 'Johnstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.1445,
    longitude: -82.6973,
    postal_code: '43031'
  },
  '43032': {
    country: 'US',
    city: 'Kilbourne',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Delaware',
    latitude: 40.3286,
    longitude: -82.9588,
    postal_code: '43032'
  },
  '43033': {
    country: 'US',
    city: 'Kirkersville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 39.961,
    longitude: -82.5998,
    postal_code: '43033'
  },
  '43035': {
    country: 'US',
    city: 'Lewis Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Delaware',
    latitude: 40.1879,
    longitude: -82.9878,
    postal_code: '43035'
  },
  '43036': {
    country: 'US',
    city: 'Magnetic Springs',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Union',
    latitude: 40.3528,
    longitude: -83.2634,
    postal_code: '43036'
  },
  '43037': {
    country: 'US',
    city: 'Martinsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Knox',
    latitude: 40.268,
    longitude: -82.3567,
    postal_code: '43037'
  },
  '43040': {
    country: 'US',
    city: 'Marysville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Union',
    latitude: 40.2477,
    longitude: -83.3622,
    postal_code: '43040'
  },
  '43041': {
    country: 'US',
    city: 'Marysville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Union',
    latitude: 40.2364,
    longitude: -83.3671,
    postal_code: '43041'
  },
  '43044': {
    country: 'US',
    city: 'Mechanicsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Champaign',
    latitude: 40.0647,
    longitude: -83.5724,
    postal_code: '43044'
  },
  '43045': {
    country: 'US',
    city: 'Milford Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Union',
    latitude: 40.1817,
    longitude: -83.4373,
    postal_code: '43045'
  },
  '43046': {
    country: 'US',
    city: 'Millersport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.8993,
    longitude: -82.5283,
    postal_code: '43046'
  },
  '43047': {
    country: 'US',
    city: 'Mingo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Champaign',
    latitude: 40.2049,
    longitude: -83.6466,
    postal_code: '43047'
  },
  '43048': {
    country: 'US',
    city: 'Mount Liberty',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Knox',
    latitude: 40.3467,
    longitude: -82.6302,
    postal_code: '43048'
  },
  '43050': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Knox',
    latitude: 40.3849,
    longitude: -82.4873,
    postal_code: '43050'
  },
  '43054': {
    country: 'US',
    city: 'New Albany',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0847,
    longitude: -82.7988,
    postal_code: '43054'
  },
  '43055': {
    country: 'US',
    city: 'Newark',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.0724,
    longitude: -82.4046,
    postal_code: '43055'
  },
  '43056': {
    country: 'US',
    city: 'Heath',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.0197,
    longitude: -82.3875,
    postal_code: '43056'
  },
  '43058': {
    country: 'US',
    city: 'Newark',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.0581,
    longitude: -82.4013,
    postal_code: '43058'
  },
  '43060': {
    country: 'US',
    city: 'North Lewisburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Champaign',
    latitude: 40.2229,
    longitude: -83.5615,
    postal_code: '43060'
  },
  '43061': {
    country: 'US',
    city: 'Ostrander',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Delaware',
    latitude: 40.274,
    longitude: -83.1978,
    postal_code: '43061'
  },
  '43062': {
    country: 'US',
    city: 'Pataskala',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.0009,
    longitude: -82.6687,
    postal_code: '43062'
  },
  '43064': {
    country: 'US',
    city: 'Plain City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Madison',
    latitude: 40.0974,
    longitude: -83.269,
    postal_code: '43064'
  },
  '43065': {
    country: 'US',
    city: 'Powell',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Delaware',
    latitude: 40.1834,
    longitude: -83.0912,
    postal_code: '43065'
  },
  '43066': {
    country: 'US',
    city: 'Radnor',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Delaware',
    latitude: 40.3918,
    longitude: -83.1781,
    postal_code: '43066'
  },
  '43067': {
    country: 'US',
    city: 'Raymond',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Union',
    latitude: 40.338,
    longitude: -83.4655,
    postal_code: '43067'
  },
  '43068': {
    country: 'US',
    city: 'Reynoldsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9551,
    longitude: -82.8035,
    postal_code: '43068'
  },
  '43069': {
    country: 'US',
    city: 'Reynoldsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9555,
    longitude: -82.7992,
    postal_code: '43069'
  },
  '43070': {
    country: 'US',
    city: 'Rosewood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Champaign',
    latitude: 40.2158,
    longitude: -83.9579,
    postal_code: '43070'
  },
  '43071': {
    country: 'US',
    city: 'Saint Louisville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.1818,
    longitude: -82.356,
    postal_code: '43071'
  },
  '43072': {
    country: 'US',
    city: 'Saint Paris',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Champaign',
    latitude: 40.1058,
    longitude: -83.9631,
    postal_code: '43072'
  },
  '43073': {
    country: 'US',
    city: 'Summit Station',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 39.9965,
    longitude: -82.754,
    postal_code: '43073'
  },
  '43074': {
    country: 'US',
    city: 'Sunbury',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Delaware',
    latitude: 40.2655,
    longitude: -82.8511,
    postal_code: '43074'
  },
  '43076': {
    country: 'US',
    city: 'Thornville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.8974,
    longitude: -82.4071,
    postal_code: '43076'
  },
  '43077': {
    country: 'US',
    city: 'Unionville Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Union',
    latitude: 40.1367,
    longitude: -83.3408,
    postal_code: '43077'
  },
  '43078': {
    country: 'US',
    city: 'Urbana',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Champaign',
    latitude: 40.1066,
    longitude: -83.7671,
    postal_code: '43078'
  },
  '43080': {
    country: 'US',
    city: 'Utica',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.2441,
    longitude: -82.4135,
    postal_code: '43080'
  },
  '43081': {
    country: 'US',
    city: 'Westerville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.1146,
    longitude: -82.9105,
    postal_code: '43081'
  },
  '43082': {
    country: 'US',
    city: 'Westerville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Delaware',
    latitude: 40.1524,
    longitude: -82.882,
    postal_code: '43082'
  },
  '43083': {
    country: 'US',
    city: 'Westville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Champaign',
    latitude: 40.1022,
    longitude: -83.8379,
    postal_code: '43083'
  },
  '43084': {
    country: 'US',
    city: 'Woodstock',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Champaign',
    latitude: 40.1816,
    longitude: -83.5461,
    postal_code: '43084'
  },
  '43085': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0999,
    longitude: -83.0157,
    postal_code: '43085'
  },
  '43086': {
    country: 'US',
    city: 'Westerville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43086'
  },
  '43093': {
    country: 'US',
    city: 'Newark',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 40.0951,
    longitude: -82.4827,
    postal_code: '43093'
  },
  '43101': {
    country: 'US',
    city: 'Adelphi',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.4667,
    longitude: -82.7471,
    postal_code: '43101'
  },
  '43102': {
    country: 'US',
    city: 'Amanda',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.6251,
    longitude: -82.7552,
    postal_code: '43102'
  },
  '43103': {
    country: 'US',
    city: 'Ashville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pickaway',
    latitude: 39.7316,
    longitude: -82.9446,
    postal_code: '43103'
  },
  '43105': {
    country: 'US',
    city: 'Baltimore',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.8645,
    longitude: -82.624,
    postal_code: '43105'
  },
  '43106': {
    country: 'US',
    city: 'Bloomingburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fayette',
    latitude: 39.6286,
    longitude: -83.4095,
    postal_code: '43106'
  },
  '43107': {
    country: 'US',
    city: 'Bremen',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.6909,
    longitude: -82.4096,
    postal_code: '43107'
  },
  '43109': {
    country: 'US',
    city: 'Brice',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9181,
    longitude: -82.8321,
    postal_code: '43109'
  },
  '43110': {
    country: 'US',
    city: 'Canal Winchester',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.8349,
    longitude: -82.8044,
    postal_code: '43110'
  },
  '43111': {
    country: 'US',
    city: 'Carbon Hill',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hocking',
    latitude: 39.5045,
    longitude: -82.2429,
    postal_code: '43111'
  },
  '43112': {
    country: 'US',
    city: 'Carroll',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.7957,
    longitude: -82.7084,
    postal_code: '43112'
  },
  '43113': {
    country: 'US',
    city: 'Circleville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pickaway',
    latitude: 39.5988,
    longitude: -82.93,
    postal_code: '43113'
  },
  '43115': {
    country: 'US',
    city: 'Clarksburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.4904,
    longitude: -83.1563,
    postal_code: '43115'
  },
  '43116': {
    country: 'US',
    city: 'Commercial Point',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pickaway',
    latitude: 39.7699,
    longitude: -83.0602,
    postal_code: '43116'
  },
  '43117': {
    country: 'US',
    city: 'Derby',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pickaway',
    latitude: 39.7687,
    longitude: -83.2057,
    postal_code: '43117'
  },
  '43119': {
    country: 'US',
    city: 'Galloway',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9366,
    longitude: -83.1838,
    postal_code: '43119'
  },
  '43123': {
    country: 'US',
    city: 'Grove City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.8814,
    longitude: -83.0839,
    postal_code: '43123'
  },
  '43125': {
    country: 'US',
    city: 'Groveport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.8581,
    longitude: -82.8872,
    postal_code: '43125'
  },
  '43126': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.8139,
    longitude: -83.1664,
    postal_code: '43126'
  },
  '43127': {
    country: 'US',
    city: 'Haydenville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hocking',
    latitude: 39.4766,
    longitude: -82.3281,
    postal_code: '43127'
  },
  '43128': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fayette',
    latitude: 39.659,
    longitude: -83.5687,
    postal_code: '43128'
  },
  '43130': {
    country: 'US',
    city: 'Lancaster',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.7187,
    longitude: -82.6031,
    postal_code: '43130'
  },
  '43135': {
    country: 'US',
    city: 'Laurelville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hocking',
    latitude: 39.4757,
    longitude: -82.7212,
    postal_code: '43135'
  },
  '43136': {
    country: 'US',
    city: 'Lithopolis',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.8013,
    longitude: -82.8125,
    postal_code: '43136'
  },
  '43137': {
    country: 'US',
    city: 'Lockbourne',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pickaway',
    latitude: 39.8142,
    longitude: -82.9764,
    postal_code: '43137'
  },
  '43138': {
    country: 'US',
    city: 'Logan',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hocking',
    latitude: 39.5372,
    longitude: -82.4126,
    postal_code: '43138'
  },
  '43140': {
    country: 'US',
    city: 'London',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Madison',
    latitude: 39.9001,
    longitude: -83.4439,
    postal_code: '43140'
  },
  '43142': {
    country: 'US',
    city: 'Milledgeville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fayette',
    latitude: 39.5937,
    longitude: -83.5873,
    postal_code: '43142'
  },
  '43143': {
    country: 'US',
    city: 'Mount Sterling',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Madison',
    latitude: 39.7175,
    longitude: -83.2806,
    postal_code: '43143'
  },
  '43144': {
    country: 'US',
    city: 'Murray City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hocking',
    latitude: 39.5055,
    longitude: -82.1711,
    postal_code: '43144'
  },
  '43145': {
    country: 'US',
    city: 'New Holland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pickaway',
    latitude: 39.5589,
    longitude: -83.2504,
    postal_code: '43145'
  },
  '43146': {
    country: 'US',
    city: 'Orient',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pickaway',
    latitude: 39.7954,
    longitude: -83.1543,
    postal_code: '43146'
  },
  '43147': {
    country: 'US',
    city: 'Pickerington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.9061,
    longitude: -82.7563,
    postal_code: '43147'
  },
  '43148': {
    country: 'US',
    city: 'Pleasantville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.8227,
    longitude: -82.5043,
    postal_code: '43148'
  },
  '43149': {
    country: 'US',
    city: 'Rockbridge',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hocking',
    latitude: 39.5509,
    longitude: -82.5626,
    postal_code: '43149'
  },
  '43150': {
    country: 'US',
    city: 'Rushville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.7674,
    longitude: -82.428,
    postal_code: '43150'
  },
  '43151': {
    country: 'US',
    city: 'Sedalia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Madison',
    latitude: 39.733,
    longitude: -83.4764,
    postal_code: '43151'
  },
  '43152': {
    country: 'US',
    city: 'South Bloomingville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hocking',
    latitude: 39.4174,
    longitude: -82.5923,
    postal_code: '43152'
  },
  '43153': {
    country: 'US',
    city: 'South Solon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Madison',
    latitude: 39.7423,
    longitude: -83.597,
    postal_code: '43153'
  },
  '43154': {
    country: 'US',
    city: 'Stoutsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.6067,
    longitude: -82.8193,
    postal_code: '43154'
  },
  '43155': {
    country: 'US',
    city: 'Sugar Grove',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.6277,
    longitude: -82.5321,
    postal_code: '43155'
  },
  '43156': {
    country: 'US',
    city: 'Tarlton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pickaway',
    latitude: 39.5535,
    longitude: -82.7763,
    postal_code: '43156'
  },
  '43157': {
    country: 'US',
    city: 'Thurston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fairfield',
    latitude: 39.8427,
    longitude: -82.5462,
    postal_code: '43157'
  },
  '43158': {
    country: 'US',
    city: 'Union Furnace',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hocking',
    latitude: 39.4617,
    longitude: -82.3563,
    postal_code: '43158'
  },
  '43160': {
    country: 'US',
    city: 'Washington Court House',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fayette',
    latitude: 39.537,
    longitude: -83.455,
    postal_code: '43160'
  },
  '43162': {
    country: 'US',
    city: 'West Jefferson',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Madison',
    latitude: 39.9424,
    longitude: -83.2853,
    postal_code: '43162'
  },
  '43164': {
    country: 'US',
    city: 'Williamsport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pickaway',
    latitude: 39.6117,
    longitude: -83.1251,
    postal_code: '43164'
  },
  '43194': {
    country: 'US',
    city: 'Lockbourne',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.8092,
    longitude: -82.9725,
    postal_code: '43194'
  },
  '43195': {
    country: 'US',
    city: 'Groveport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.8614,
    longitude: -82.8916,
    postal_code: '43195'
  },
  '43199': {
    country: 'US',
    city: 'Groveport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43199'
  },
  '43201': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9952,
    longitude: -83.0047,
    postal_code: '43201'
  },
  '43202': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0201,
    longitude: -83.0118,
    postal_code: '43202'
  },
  '43203': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9719,
    longitude: -82.9691,
    postal_code: '43203'
  },
  '43204': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9523,
    longitude: -83.078,
    postal_code: '43204'
  },
  '43205': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9569,
    longitude: -82.9644,
    postal_code: '43205'
  },
  '43206': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9426,
    longitude: -82.9748,
    postal_code: '43206'
  },
  '43207': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9046,
    longitude: -82.9703,
    postal_code: '43207'
  },
  '43209': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9539,
    longitude: -82.9301,
    postal_code: '43209'
  },
  '43210': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0028,
    longitude: -83.0164,
    postal_code: '43210'
  },
  '43211': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0118,
    longitude: -82.9732,
    postal_code: '43211'
  },
  '43212': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9874,
    longitude: -83.0456,
    postal_code: '43212'
  },
  '43213': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9727,
    longitude: -82.8329,
    postal_code: '43213'
  },
  '43214': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0535,
    longitude: -83.0187,
    postal_code: '43214'
  },
  '43215': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9671,
    longitude: -83.0044,
    postal_code: '43215'
  },
  '43216': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43216'
  },
  '43217': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.8277,
    longitude: -82.9342,
    postal_code: '43217'
  },
  '43218': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43218'
  },
  '43219': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0326,
    longitude: -82.9103,
    postal_code: '43219'
  },
  '43220': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0495,
    longitude: -83.0669,
    postal_code: '43220'
  },
  '43221': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0226,
    longitude: -83.0776,
    postal_code: '43221'
  },
  '43222': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9576,
    longitude: -83.0311,
    postal_code: '43222'
  },
  '43223': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9388,
    longitude: -83.0463,
    postal_code: '43223'
  },
  '43224': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0425,
    longitude: -82.9689,
    postal_code: '43224'
  },
  '43226': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43226'
  },
  '43227': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9444,
    longitude: -82.8903,
    postal_code: '43227'
  },
  '43228': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.9648,
    longitude: -83.126,
    postal_code: '43228'
  },
  '43229': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0839,
    longitude: -82.9726,
    postal_code: '43229'
  },
  '43230': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.0347,
    longitude: -82.8726,
    postal_code: '43230'
  },
  '43231': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.081,
    longitude: -82.9383,
    postal_code: '43231'
  },
  '43232': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.923,
    longitude: -82.8664,
    postal_code: '43232'
  },
  '43234': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43234'
  },
  '43235': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.1088,
    longitude: -82.9742,
    postal_code: '43235'
  },
  '43236': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.1357,
    longitude: -83.0076,
    postal_code: '43236'
  },
  '43240': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 40.1454,
    longitude: -82.9817,
    postal_code: '43240'
  },
  '43251': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43251'
  },
  '43260': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43260'
  },
  '43266': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43266'
  },
  '43268': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43268'
  },
  '43270': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43270'
  },
  '43271': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43271'
  },
  '43272': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43272'
  },
  '43279': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43279'
  },
  '43287': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43287'
  },
  '43291': {
    country: 'US',
    city: 'Columbus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Franklin',
    latitude: 39.969,
    longitude: -83.0114,
    postal_code: '43291'
  },
  '43301': {
    country: 'US',
    city: 'Marion',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Marion',
    latitude: 40.6166,
    longitude: -83.0693,
    postal_code: '43301'
  },
  '43302': {
    country: 'US',
    city: 'Marion',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Marion',
    latitude: 40.5876,
    longitude: -83.1271,
    postal_code: '43302'
  },
  '43310': {
    country: 'US',
    city: 'Belle Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.5024,
    longitude: -83.7688,
    postal_code: '43310'
  },
  '43311': {
    country: 'US',
    city: 'Bellefontaine',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.3605,
    longitude: -83.7571,
    postal_code: '43311'
  },
  '43314': {
    country: 'US',
    city: 'Caledonia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Marion',
    latitude: 40.6272,
    longitude: -82.9925,
    postal_code: '43314'
  },
  '43315': {
    country: 'US',
    city: 'Cardington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morrow',
    latitude: 40.5066,
    longitude: -82.9337,
    postal_code: '43315'
  },
  '43316': {
    country: 'US',
    city: 'Carey',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wyandot',
    latitude: 40.9486,
    longitude: -83.3836,
    postal_code: '43316'
  },
  '43317': {
    country: 'US',
    city: 'Chesterville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morrow',
    latitude: 40.4789,
    longitude: -82.6828,
    postal_code: '43317'
  },
  '43318': {
    country: 'US',
    city: 'De Graff',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.3058,
    longitude: -83.9153,
    postal_code: '43318'
  },
  '43319': {
    country: 'US',
    city: 'East Liberty',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.3077,
    longitude: -83.5862,
    postal_code: '43319'
  },
  '43320': {
    country: 'US',
    city: 'Edison',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morrow',
    latitude: 40.5905,
    longitude: -82.9023,
    postal_code: '43320'
  },
  '43321': {
    country: 'US',
    city: 'Fulton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morrow',
    latitude: 40.4626,
    longitude: -82.8288,
    postal_code: '43321'
  },
  '43322': {
    country: 'US',
    city: 'Green Camp',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Marion',
    latitude: 40.5322,
    longitude: -83.2078,
    postal_code: '43322'
  },
  '43323': {
    country: 'US',
    city: 'Harpster',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wyandot',
    latitude: 40.7475,
    longitude: -83.2343,
    postal_code: '43323'
  },
  '43324': {
    country: 'US',
    city: 'Huntsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.4413,
    longitude: -83.7927,
    postal_code: '43324'
  },
  '43325': {
    country: 'US',
    city: 'Iberia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morrow',
    latitude: 40.6703,
    longitude: -82.8435,
    postal_code: '43325'
  },
  '43326': {
    country: 'US',
    city: 'Kenton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hardin',
    latitude: 40.6404,
    longitude: -83.6111,
    postal_code: '43326'
  },
  '43330': {
    country: 'US',
    city: 'Kirby',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wyandot',
    latitude: 40.813,
    longitude: -83.4196,
    postal_code: '43330'
  },
  '43331': {
    country: 'US',
    city: 'Lakeview',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.5019,
    longitude: -83.9202,
    postal_code: '43331'
  },
  '43332': {
    country: 'US',
    city: 'La Rue',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Marion',
    latitude: 40.5789,
    longitude: -83.3734,
    postal_code: '43332'
  },
  '43333': {
    country: 'US',
    city: 'Lewistown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.4277,
    longitude: -83.9209,
    postal_code: '43333'
  },
  '43334': {
    country: 'US',
    city: 'Marengo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morrow',
    latitude: 40.3895,
    longitude: -82.8121,
    postal_code: '43334'
  },
  '43335': {
    country: 'US',
    city: 'Martel',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Marion',
    latitude: 40.6685,
    longitude: -82.91,
    postal_code: '43335'
  },
  '43336': {
    country: 'US',
    city: 'Middleburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.2926,
    longitude: -83.5824,
    postal_code: '43336'
  },
  '43337': {
    country: 'US',
    city: 'Morral',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Marion',
    latitude: 40.6954,
    longitude: -83.2046,
    postal_code: '43337'
  },
  '43338': {
    country: 'US',
    city: 'Mount Gilead',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morrow',
    latitude: 40.5384,
    longitude: -82.8062,
    postal_code: '43338'
  },
  '43340': {
    country: 'US',
    city: 'Mount Victory',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hardin',
    latitude: 40.5232,
    longitude: -83.4942,
    postal_code: '43340'
  },
  '43341': {
    country: 'US',
    city: 'New Bloomington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Marion',
    latitude: 40.6073,
    longitude: -83.3224,
    postal_code: '43341'
  },
  '43342': {
    country: 'US',
    city: 'Prospect',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Marion',
    latitude: 40.4727,
    longitude: -83.1763,
    postal_code: '43342'
  },
  '43343': {
    country: 'US',
    city: 'Quincy',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.2876,
    longitude: -83.9744,
    postal_code: '43343'
  },
  '43344': {
    country: 'US',
    city: 'Richwood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Union',
    latitude: 40.437,
    longitude: -83.3136,
    postal_code: '43344'
  },
  '43345': {
    country: 'US',
    city: 'Ridgeway',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hardin',
    latitude: 40.5209,
    longitude: -83.5702,
    postal_code: '43345'
  },
  '43346': {
    country: 'US',
    city: 'Roundhead',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hardin',
    latitude: 40.5732,
    longitude: -83.8462,
    postal_code: '43346'
  },
  '43347': {
    country: 'US',
    city: 'Rushsylvania',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.4658,
    longitude: -83.6598,
    postal_code: '43347'
  },
  '43348': {
    country: 'US',
    city: 'Russells Point',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.4675,
    longitude: -83.887,
    postal_code: '43348'
  },
  '43349': {
    country: 'US',
    city: 'Shauck',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morrow',
    latitude: 40.6142,
    longitude: -82.6619,
    postal_code: '43349'
  },
  '43350': {
    country: 'US',
    city: 'Sparta',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morrow',
    latitude: 40.5289,
    longitude: -82.8229,
    postal_code: '43350'
  },
  '43351': {
    country: 'US',
    city: 'Upper Sandusky',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wyandot',
    latitude: 40.8249,
    longitude: -83.2977,
    postal_code: '43351'
  },
  '43356': {
    country: 'US',
    city: 'Waldo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Marion',
    latitude: 40.4605,
    longitude: -83.0706,
    postal_code: '43356'
  },
  '43357': {
    country: 'US',
    city: 'West Liberty',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.2625,
    longitude: -83.7528,
    postal_code: '43357'
  },
  '43358': {
    country: 'US',
    city: 'West Mansfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.4043,
    longitude: -83.5243,
    postal_code: '43358'
  },
  '43359': {
    country: 'US',
    city: 'Wharton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wyandot',
    latitude: 40.8612,
    longitude: -83.463,
    postal_code: '43359'
  },
  '43360': {
    country: 'US',
    city: 'Zanesfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Logan',
    latitude: 40.3211,
    longitude: -83.6405,
    postal_code: '43360'
  },
  '43402': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.3815,
    longitude: -83.6507,
    postal_code: '43402'
  },
  '43403': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.377,
    longitude: -83.6371,
    postal_code: '43403'
  },
  '43406': {
    country: 'US',
    city: 'Bradner',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.3298,
    longitude: -83.4456,
    postal_code: '43406'
  },
  '43407': {
    country: 'US',
    city: 'Burgoon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Sandusky',
    latitude: 41.268,
    longitude: -83.2475,
    postal_code: '43407'
  },
  '43408': {
    country: 'US',
    city: 'Clay Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.5686,
    longitude: -83.3632,
    postal_code: '43408'
  },
  '43410': {
    country: 'US',
    city: 'Clyde',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Sandusky',
    latitude: 41.3024,
    longitude: -82.9918,
    postal_code: '43410'
  },
  '43412': {
    country: 'US',
    city: 'Curtice',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.6477,
    longitude: -83.2858,
    postal_code: '43412'
  },
  '43413': {
    country: 'US',
    city: 'Cygnet',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.2401,
    longitude: -83.6433,
    postal_code: '43413'
  },
  '43414': {
    country: 'US',
    city: 'Dunbridge',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.4581,
    longitude: -83.6102,
    postal_code: '43414'
  },
  '43416': {
    country: 'US',
    city: 'Elmore',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.4681,
    longitude: -83.2767,
    postal_code: '43416'
  },
  '43420': {
    country: 'US',
    city: 'Fremont',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Sandusky',
    latitude: 41.3498,
    longitude: -83.1181,
    postal_code: '43420'
  },
  '43430': {
    country: 'US',
    city: 'Genoa',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.53,
    longitude: -83.359,
    postal_code: '43430'
  },
  '43431': {
    country: 'US',
    city: 'Gibsonburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Sandusky',
    latitude: 41.3805,
    longitude: -83.3358,
    postal_code: '43431'
  },
  '43432': {
    country: 'US',
    city: 'Graytown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.5647,
    longitude: -83.2613,
    postal_code: '43432'
  },
  '43433': {
    country: 'US',
    city: 'Gypsum',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.5038,
    longitude: -82.8708,
    postal_code: '43433'
  },
  '43434': {
    country: 'US',
    city: 'Harbor View',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6934,
    longitude: -83.4444,
    postal_code: '43434'
  },
  '43435': {
    country: 'US',
    city: 'Helena',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Sandusky',
    latitude: 41.326,
    longitude: -83.3186,
    postal_code: '43435'
  },
  '43436': {
    country: 'US',
    city: 'Isle Saint George',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.7153,
    longitude: -82.8227,
    postal_code: '43436'
  },
  '43437': {
    country: 'US',
    city: 'Jerry City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.2559,
    longitude: -83.6022,
    postal_code: '43437'
  },
  '43438': {
    country: 'US',
    city: 'Kelleys Island',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Erie',
    latitude: 41.6008,
    longitude: -82.7068,
    postal_code: '43438'
  },
  '43439': {
    country: 'US',
    city: 'Lacarne',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.5181,
    longitude: -83.0413,
    postal_code: '43439'
  },
  '43440': {
    country: 'US',
    city: 'Lakeside Marblehead',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.5247,
    longitude: -82.7766,
    postal_code: '43440'
  },
  '43441': {
    country: 'US',
    city: 'Lemoyne',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.4959,
    longitude: -83.4741,
    postal_code: '43441'
  },
  '43442': {
    country: 'US',
    city: 'Lindsey',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Sandusky',
    latitude: 41.4147,
    longitude: -83.2135,
    postal_code: '43442'
  },
  '43443': {
    country: 'US',
    city: 'Luckey',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.4517,
    longitude: -83.4674,
    postal_code: '43443'
  },
  '43445': {
    country: 'US',
    city: 'Martin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.5694,
    longitude: -83.3116,
    postal_code: '43445'
  },
  '43446': {
    country: 'US',
    city: 'Middle Bass',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.6835,
    longitude: -82.8047,
    postal_code: '43446'
  },
  '43447': {
    country: 'US',
    city: 'Millbury',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.5611,
    longitude: -83.4381,
    postal_code: '43447'
  },
  '43449': {
    country: 'US',
    city: 'Oak Harbor',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.5236,
    longitude: -83.1278,
    postal_code: '43449'
  },
  '43450': {
    country: 'US',
    city: 'Pemberville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.4023,
    longitude: -83.4736,
    postal_code: '43450'
  },
  '43451': {
    country: 'US',
    city: 'Portage',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.3127,
    longitude: -83.6143,
    postal_code: '43451'
  },
  '43452': {
    country: 'US',
    city: 'Port Clinton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.512,
    longitude: -82.9377,
    postal_code: '43452'
  },
  '43456': {
    country: 'US',
    city: 'Put In Bay',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.6514,
    longitude: -82.8226,
    postal_code: '43456'
  },
  '43457': {
    country: 'US',
    city: 'Risingsun',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.2706,
    longitude: -83.4326,
    postal_code: '43457'
  },
  '43458': {
    country: 'US',
    city: 'Rocky Ridge',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.5302,
    longitude: -83.2128,
    postal_code: '43458'
  },
  '43460': {
    country: 'US',
    city: 'Rossford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.6049,
    longitude: -83.5638,
    postal_code: '43460'
  },
  '43462': {
    country: 'US',
    city: 'Rudolph',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.2967,
    longitude: -83.6832,
    postal_code: '43462'
  },
  '43463': {
    country: 'US',
    city: 'Stony Ridge',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.5091,
    longitude: -83.5085,
    postal_code: '43463'
  },
  '43464': {
    country: 'US',
    city: 'Vickery',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Sandusky',
    latitude: 41.391,
    longitude: -82.899,
    postal_code: '43464'
  },
  '43465': {
    country: 'US',
    city: 'Walbridge',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.5861,
    longitude: -83.493,
    postal_code: '43465'
  },
  '43466': {
    country: 'US',
    city: 'Wayne',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.2993,
    longitude: -83.4701,
    postal_code: '43466'
  },
  '43467': {
    country: 'US',
    city: 'West Millgrove',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.2426,
    longitude: -83.4896,
    postal_code: '43467'
  },
  '43468': {
    country: 'US',
    city: 'Williston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ottawa',
    latitude: 41.6037,
    longitude: -83.3399,
    postal_code: '43468'
  },
  '43469': {
    country: 'US',
    city: 'Woodville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Sandusky',
    latitude: 41.4512,
    longitude: -83.3646,
    postal_code: '43469'
  },
  '43501': {
    country: 'US',
    city: 'Alvordton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Williams',
    latitude: 41.6625,
    longitude: -84.4355,
    postal_code: '43501'
  },
  '43502': {
    country: 'US',
    city: 'Archbold',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fulton',
    latitude: 41.5333,
    longitude: -84.3048,
    postal_code: '43502'
  },
  '43504': {
    country: 'US',
    city: 'Berkey',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6989,
    longitude: -83.831,
    postal_code: '43504'
  },
  '43505': {
    country: 'US',
    city: 'Blakeslee',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Williams',
    latitude: 41.5239,
    longitude: -84.7303,
    postal_code: '43505'
  },
  '43506': {
    country: 'US',
    city: 'Bryan',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Williams',
    latitude: 41.4748,
    longitude: -84.5629,
    postal_code: '43506'
  },
  '43510': {
    country: 'US',
    city: 'Colton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.4199,
    longitude: -84.0137,
    postal_code: '43510'
  },
  '43511': {
    country: 'US',
    city: 'Custar',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.2953,
    longitude: -83.8349,
    postal_code: '43511'
  },
  '43512': {
    country: 'US',
    city: 'Defiance',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Defiance',
    latitude: 41.2799,
    longitude: -84.3626,
    postal_code: '43512'
  },
  '43515': {
    country: 'US',
    city: 'Delta',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fulton',
    latitude: 41.5577,
    longitude: -83.9866,
    postal_code: '43515'
  },
  '43516': {
    country: 'US',
    city: 'Deshler',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.2239,
    longitude: -83.8964,
    postal_code: '43516'
  },
  '43517': {
    country: 'US',
    city: 'Edgerton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Williams',
    latitude: 41.4425,
    longitude: -84.7349,
    postal_code: '43517'
  },
  '43518': {
    country: 'US',
    city: 'Edon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Williams',
    latitude: 41.5842,
    longitude: -84.757,
    postal_code: '43518'
  },
  '43519': {
    country: 'US',
    city: 'Evansport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Defiance',
    latitude: 41.4222,
    longitude: -84.3966,
    postal_code: '43519'
  },
  '43520': {
    country: 'US',
    city: 'Farmer',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Defiance',
    latitude: 41.3907,
    longitude: -84.6313,
    postal_code: '43520'
  },
  '43521': {
    country: 'US',
    city: 'Fayette',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fulton',
    latitude: 41.6717,
    longitude: -84.325,
    postal_code: '43521'
  },
  '43522': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.4379,
    longitude: -83.8552,
    postal_code: '43522'
  },
  '43523': {
    country: 'US',
    city: 'Grelton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.3411,
    longitude: -84.0005,
    postal_code: '43523'
  },
  '43524': {
    country: 'US',
    city: 'Hamler',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.2292,
    longitude: -84.0341,
    postal_code: '43524'
  },
  '43525': {
    country: 'US',
    city: 'Haskins',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.4667,
    longitude: -83.7055,
    postal_code: '43525'
  },
  '43526': {
    country: 'US',
    city: 'Hicksville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Defiance',
    latitude: 41.3034,
    longitude: -84.7589,
    postal_code: '43526'
  },
  '43527': {
    country: 'US',
    city: 'Holgate',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.2549,
    longitude: -84.1447,
    postal_code: '43527'
  },
  '43528': {
    country: 'US',
    city: 'Holland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6226,
    longitude: -83.7257,
    postal_code: '43528'
  },
  '43529': {
    country: 'US',
    city: 'Hoytville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.1872,
    longitude: -83.7847,
    postal_code: '43529'
  },
  '43530': {
    country: 'US',
    city: 'Jewell',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Defiance',
    latitude: 41.3258,
    longitude: -84.2793,
    postal_code: '43530'
  },
  '43531': {
    country: 'US',
    city: 'Kunkle',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Williams',
    latitude: 41.6367,
    longitude: -84.4952,
    postal_code: '43531'
  },
  '43532': {
    country: 'US',
    city: 'Liberty Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.4514,
    longitude: -83.9859,
    postal_code: '43532'
  },
  '43533': {
    country: 'US',
    city: 'Lyons',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fulton',
    latitude: 41.6905,
    longitude: -84.0624,
    postal_code: '43533'
  },
  '43534': {
    country: 'US',
    city: 'Mc Clure',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.3773,
    longitude: -83.9425,
    postal_code: '43534'
  },
  '43535': {
    country: 'US',
    city: 'Malinta',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.3084,
    longitude: -84.0457,
    postal_code: '43535'
  },
  '43536': {
    country: 'US',
    city: 'Mark Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Defiance',
    latitude: 41.2917,
    longitude: -84.6278,
    postal_code: '43536'
  },
  '43537': {
    country: 'US',
    city: 'Maumee',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.5817,
    longitude: -83.6628,
    postal_code: '43537'
  },
  '43540': {
    country: 'US',
    city: 'Metamora',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fulton',
    latitude: 41.6952,
    longitude: -83.926,
    postal_code: '43540'
  },
  '43541': {
    country: 'US',
    city: 'Milton Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.3009,
    longitude: -83.8296,
    postal_code: '43541'
  },
  '43542': {
    country: 'US',
    city: 'Monclova',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.5684,
    longitude: -83.7757,
    postal_code: '43542'
  },
  '43543': {
    country: 'US',
    city: 'Montpelier',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Williams',
    latitude: 41.5982,
    longitude: -84.6147,
    postal_code: '43543'
  },
  '43545': {
    country: 'US',
    city: 'Napoleon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.391,
    longitude: -84.1433,
    postal_code: '43545'
  },
  '43547': {
    country: 'US',
    city: 'Neapolis',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.492,
    longitude: -83.8706,
    postal_code: '43547'
  },
  '43548': {
    country: 'US',
    city: 'New Bavaria',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.2037,
    longitude: -84.1683,
    postal_code: '43548'
  },
  '43549': {
    country: 'US',
    city: 'Ney',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Defiance',
    latitude: 41.3781,
    longitude: -84.5269,
    postal_code: '43549'
  },
  '43550': {
    country: 'US',
    city: 'Okolona',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.3267,
    longitude: -84.1117,
    postal_code: '43550'
  },
  '43551': {
    country: 'US',
    city: 'Perrysburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.5429,
    longitude: -83.5927,
    postal_code: '43551'
  },
  '43552': {
    country: 'US',
    city: 'Perrysburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.557,
    longitude: -83.6272,
    postal_code: '43552'
  },
  '43553': {
    country: 'US',
    city: 'Pettisville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fulton',
    latitude: 41.5306,
    longitude: -84.2297,
    postal_code: '43553'
  },
  '43554': {
    country: 'US',
    city: 'Pioneer',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Williams',
    latitude: 41.6656,
    longitude: -84.5363,
    postal_code: '43554'
  },
  '43555': {
    country: 'US',
    city: 'Ridgeville Corners',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Henry',
    latitude: 41.4351,
    longitude: -84.2544,
    postal_code: '43555'
  },
  '43556': {
    country: 'US',
    city: 'Sherwood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Defiance',
    latitude: 41.2946,
    longitude: -84.5417,
    postal_code: '43556'
  },
  '43557': {
    country: 'US',
    city: 'Stryker',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Williams',
    latitude: 41.4861,
    longitude: -84.4089,
    postal_code: '43557'
  },
  '43558': {
    country: 'US',
    city: 'Swanton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fulton',
    latitude: 41.5945,
    longitude: -83.8718,
    postal_code: '43558'
  },
  '43560': {
    country: 'US',
    city: 'Sylvania',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.708,
    longitude: -83.7068,
    postal_code: '43560'
  },
  '43565': {
    country: 'US',
    city: 'Tontogany',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.4192,
    longitude: -83.7408,
    postal_code: '43565'
  },
  '43566': {
    country: 'US',
    city: 'Waterville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.5022,
    longitude: -83.7331,
    postal_code: '43566'
  },
  '43567': {
    country: 'US',
    city: 'Wauseon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Fulton',
    latitude: 41.5668,
    longitude: -84.1537,
    postal_code: '43567'
  },
  '43569': {
    country: 'US',
    city: 'Weston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.3516,
    longitude: -83.7973,
    postal_code: '43569'
  },
  '43570': {
    country: 'US',
    city: 'West Unity',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Williams',
    latitude: 41.5756,
    longitude: -84.4421,
    postal_code: '43570'
  },
  '43571': {
    country: 'US',
    city: 'Whitehouse',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.5194,
    longitude: -83.8115,
    postal_code: '43571'
  },
  '43601': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.7207,
    longitude: -83.5694,
    postal_code: '43601'
  },
  '43603': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6868,
    longitude: -83.4394,
    postal_code: '43603'
  },
  '43604': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6614,
    longitude: -83.5249,
    postal_code: '43604'
  },
  '43605': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6525,
    longitude: -83.5085,
    postal_code: '43605'
  },
  '43606': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6712,
    longitude: -83.606,
    postal_code: '43606'
  },
  '43607': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6504,
    longitude: -83.5974,
    postal_code: '43607'
  },
  '43608': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6779,
    longitude: -83.5344,
    postal_code: '43608'
  },
  '43609': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6298,
    longitude: -83.5773,
    postal_code: '43609'
  },
  '43610': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6767,
    longitude: -83.5573,
    postal_code: '43610'
  },
  '43611': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.7045,
    longitude: -83.4892,
    postal_code: '43611'
  },
  '43612': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.7046,
    longitude: -83.5656,
    postal_code: '43612'
  },
  '43613': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.7039,
    longitude: -83.6034,
    postal_code: '43613'
  },
  '43614': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6028,
    longitude: -83.6292,
    postal_code: '43614'
  },
  '43615': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6492,
    longitude: -83.6706,
    postal_code: '43615'
  },
  '43616': {
    country: 'US',
    city: 'Oregon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6418,
    longitude: -83.4714,
    postal_code: '43616'
  },
  '43617': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6668,
    longitude: -83.717,
    postal_code: '43617'
  },
  '43619': {
    country: 'US',
    city: 'Northwood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.608,
    longitude: -83.4806,
    postal_code: '43619'
  },
  '43620': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6654,
    longitude: -83.5536,
    postal_code: '43620'
  },
  '43623': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.708,
    longitude: -83.6434,
    postal_code: '43623'
  },
  '43635': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6868,
    longitude: -83.4394,
    postal_code: '43635'
  },
  '43652': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6868,
    longitude: -83.4394,
    postal_code: '43652'
  },
  '43654': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6868,
    longitude: -83.4394,
    postal_code: '43654'
  },
  '43656': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6782,
    longitude: -83.4972,
    postal_code: '43656'
  },
  '43657': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6868,
    longitude: -83.4394,
    postal_code: '43657'
  },
  '43659': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6868,
    longitude: -83.4394,
    postal_code: '43659'
  },
  '43660': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6546,
    longitude: -83.5329,
    postal_code: '43660'
  },
  '43661': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6782,
    longitude: -83.4972,
    postal_code: '43661'
  },
  '43666': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6782,
    longitude: -83.4972,
    postal_code: '43666'
  },
  '43667': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6868,
    longitude: -83.4394,
    postal_code: '43667'
  },
  '43681': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6868,
    longitude: -83.4394,
    postal_code: '43681'
  },
  '43682': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6868,
    longitude: -83.4394,
    postal_code: '43682'
  },
  '43697': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6868,
    longitude: -83.4394,
    postal_code: '43697'
  },
  '43699': {
    country: 'US',
    city: 'Toledo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lucas',
    latitude: 41.6538,
    longitude: -83.6589,
    postal_code: '43699'
  },
  '43701': {
    country: 'US',
    city: 'Zanesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.9274,
    longitude: -82.0041,
    postal_code: '43701'
  },
  '43702': {
    country: 'US',
    city: 'Zanesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.9403,
    longitude: -82.0132,
    postal_code: '43702'
  },
  '43711': {
    country: 'US',
    city: 'Ava',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Noble',
    latitude: 39.8317,
    longitude: -81.5748,
    postal_code: '43711'
  },
  '43713': {
    country: 'US',
    city: 'Barnesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 39.9853,
    longitude: -81.1375,
    postal_code: '43713'
  },
  '43716': {
    country: 'US',
    city: 'Beallsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.8484,
    longitude: -81.0368,
    postal_code: '43716'
  },
  '43717': {
    country: 'US',
    city: 'Belle Valley',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Noble',
    latitude: 39.7906,
    longitude: -81.5562,
    postal_code: '43717'
  },
  '43718': {
    country: 'US',
    city: 'Belmont',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.032,
    longitude: -81.0066,
    postal_code: '43718'
  },
  '43719': {
    country: 'US',
    city: 'Bethesda',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.0192,
    longitude: -81.0767,
    postal_code: '43719'
  },
  '43720': {
    country: 'US',
    city: 'Blue Rock',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.8,
    longitude: -81.891,
    postal_code: '43720'
  },
  '43721': {
    country: 'US',
    city: 'Brownsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 39.947,
    longitude: -82.2565,
    postal_code: '43721'
  },
  '43722': {
    country: 'US',
    city: 'Buffalo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 39.9159,
    longitude: -81.5201,
    postal_code: '43722'
  },
  '43723': {
    country: 'US',
    city: 'Byesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 39.9623,
    longitude: -81.5485,
    postal_code: '43723'
  },
  '43724': {
    country: 'US',
    city: 'Caldwell',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Noble',
    latitude: 39.7467,
    longitude: -81.5153,
    postal_code: '43724'
  },
  '43725': {
    country: 'US',
    city: 'Cambridge',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 40.027,
    longitude: -81.582,
    postal_code: '43725'
  },
  '43727': {
    country: 'US',
    city: 'Chandlersville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.8897,
    longitude: -81.8301,
    postal_code: '43727'
  },
  '43728': {
    country: 'US',
    city: 'Chesterhill',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morgan',
    latitude: 39.4953,
    longitude: -81.8773,
    postal_code: '43728'
  },
  '43730': {
    country: 'US',
    city: 'Corning',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.6361,
    longitude: -82.1102,
    postal_code: '43730'
  },
  '43731': {
    country: 'US',
    city: 'Crooksville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.7623,
    longitude: -82.084,
    postal_code: '43731'
  },
  '43732': {
    country: 'US',
    city: 'Cumberland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 39.852,
    longitude: -81.6576,
    postal_code: '43732'
  },
  '43733': {
    country: 'US',
    city: 'Derwent',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 39.9236,
    longitude: -81.543,
    postal_code: '43733'
  },
  '43734': {
    country: 'US',
    city: 'Duncan Falls',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.8778,
    longitude: -81.9117,
    postal_code: '43734'
  },
  '43735': {
    country: 'US',
    city: 'East Fultonham',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.8501,
    longitude: -82.1221,
    postal_code: '43735'
  },
  '43736': {
    country: 'US',
    city: 'Fairview',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 40.065,
    longitude: -81.2558,
    postal_code: '43736'
  },
  '43738': {
    country: 'US',
    city: 'Fultonham',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.8555,
    longitude: -82.1378,
    postal_code: '43738'
  },
  '43739': {
    country: 'US',
    city: 'Glenford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.8699,
    longitude: -82.3026,
    postal_code: '43739'
  },
  '43740': {
    country: 'US',
    city: 'Gratiot',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Licking',
    latitude: 39.9522,
    longitude: -82.2128,
    postal_code: '43740'
  },
  '43746': {
    country: 'US',
    city: 'Hopewell',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.9601,
    longitude: -82.1756,
    postal_code: '43746'
  },
  '43747': {
    country: 'US',
    city: 'Jerusalem',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.8488,
    longitude: -81.0921,
    postal_code: '43747'
  },
  '43748': {
    country: 'US',
    city: 'Junction City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.6965,
    longitude: -82.3155,
    postal_code: '43748'
  },
  '43749': {
    country: 'US',
    city: 'Kimbolton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 40.1589,
    longitude: -81.549,
    postal_code: '43749'
  },
  '43750': {
    country: 'US',
    city: 'Kipling',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 39.9945,
    longitude: -81.5006,
    postal_code: '43750'
  },
  '43752': {
    country: 'US',
    city: 'Laings',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.7171,
    longitude: -81.01,
    postal_code: '43752'
  },
  '43754': {
    country: 'US',
    city: 'Lewisville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.7684,
    longitude: -81.2316,
    postal_code: '43754'
  },
  '43755': {
    country: 'US',
    city: 'Lore City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 40.0459,
    longitude: -81.4479,
    postal_code: '43755'
  },
  '43756': {
    country: 'US',
    city: 'Mcconnelsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morgan',
    latitude: 39.6487,
    longitude: -81.8532,
    postal_code: '43756'
  },
  '43757': {
    country: 'US',
    city: 'Malaga',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.8594,
    longitude: -81.1516,
    postal_code: '43757'
  },
  '43758': {
    country: 'US',
    city: 'Malta',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morgan',
    latitude: 39.6482,
    longitude: -81.9127,
    postal_code: '43758'
  },
  '43759': {
    country: 'US',
    city: 'Morristown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.0631,
    longitude: -81.0743,
    postal_code: '43759'
  },
  '43760': {
    country: 'US',
    city: 'Mount Perry',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.8788,
    longitude: -82.188,
    postal_code: '43760'
  },
  '43761': {
    country: 'US',
    city: 'Moxahala',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.6626,
    longitude: -82.1363,
    postal_code: '43761'
  },
  '43762': {
    country: 'US',
    city: 'New Concord',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 40.0088,
    longitude: -81.7387,
    postal_code: '43762'
  },
  '43764': {
    country: 'US',
    city: 'New Lexington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.7174,
    longitude: -82.2019,
    postal_code: '43764'
  },
  '43766': {
    country: 'US',
    city: 'New Straitsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.5869,
    longitude: -82.2488,
    postal_code: '43766'
  },
  '43767': {
    country: 'US',
    city: 'Norwich',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.9934,
    longitude: -81.8024,
    postal_code: '43767'
  },
  '43768': {
    country: 'US',
    city: 'Old Washington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 40.0392,
    longitude: -81.4522,
    postal_code: '43768'
  },
  '43771': {
    country: 'US',
    city: 'Philo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.8458,
    longitude: -81.9175,
    postal_code: '43771'
  },
  '43772': {
    country: 'US',
    city: 'Pleasant City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 39.9095,
    longitude: -81.558,
    postal_code: '43772'
  },
  '43773': {
    country: 'US',
    city: 'Quaker City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 39.9866,
    longitude: -81.2899,
    postal_code: '43773'
  },
  '43777': {
    country: 'US',
    city: 'Roseville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.8187,
    longitude: -82.0792,
    postal_code: '43777'
  },
  '43778': {
    country: 'US',
    city: 'Salesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 40.0081,
    longitude: -81.3728,
    postal_code: '43778'
  },
  '43779': {
    country: 'US',
    city: 'Sarahsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Noble',
    latitude: 39.8162,
    longitude: -81.4243,
    postal_code: '43779'
  },
  '43780': {
    country: 'US',
    city: 'Senecaville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Guernsey',
    latitude: 39.9337,
    longitude: -81.458,
    postal_code: '43780'
  },
  '43782': {
    country: 'US',
    city: 'Shawnee',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.611,
    longitude: -82.2085,
    postal_code: '43782'
  },
  '43783': {
    country: 'US',
    city: 'Somerset',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Perry',
    latitude: 39.7936,
    longitude: -82.2991,
    postal_code: '43783'
  },
  '43786': {
    country: 'US',
    city: 'Stafford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.7112,
    longitude: -81.2758,
    postal_code: '43786'
  },
  '43787': {
    country: 'US',
    city: 'Stockport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Morgan',
    latitude: 39.549,
    longitude: -81.8262,
    postal_code: '43787'
  },
  '43788': {
    country: 'US',
    city: 'Summerfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Noble',
    latitude: 39.8036,
    longitude: -81.332,
    postal_code: '43788'
  },
  '43791': {
    country: 'US',
    city: 'White Cottage',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 39.8713,
    longitude: -82.0987,
    postal_code: '43791'
  },
  '43793': {
    country: 'US',
    city: 'Woodsfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.7515,
    longitude: -81.0759,
    postal_code: '43793'
  },
  '43802': {
    country: 'US',
    city: 'Adamsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 40.0793,
    longitude: -81.8718,
    postal_code: '43802'
  },
  '43803': {
    country: 'US',
    city: 'Bakersville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Coshocton',
    latitude: 40.3572,
    longitude: -81.6436,
    postal_code: '43803'
  },
  '43804': {
    country: 'US',
    city: 'Baltic',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.4476,
    longitude: -81.6792,
    postal_code: '43804'
  },
  '43805': {
    country: 'US',
    city: 'Blissfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Coshocton',
    latitude: 40.3983,
    longitude: -81.9688,
    postal_code: '43805'
  },
  '43811': {
    country: 'US',
    city: 'Conesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Coshocton',
    latitude: 40.1804,
    longitude: -81.8951,
    postal_code: '43811'
  },
  '43812': {
    country: 'US',
    city: 'Coshocton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Coshocton',
    latitude: 40.2754,
    longitude: -81.866,
    postal_code: '43812'
  },
  '43821': {
    country: 'US',
    city: 'Dresden',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 40.1069,
    longitude: -81.9998,
    postal_code: '43821'
  },
  '43822': {
    country: 'US',
    city: 'Frazeysburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 40.1316,
    longitude: -82.1293,
    postal_code: '43822'
  },
  '43824': {
    country: 'US',
    city: 'Fresno',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Coshocton',
    latitude: 40.3711,
    longitude: -81.7623,
    postal_code: '43824'
  },
  '43828': {
    country: 'US',
    city: 'Keene',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Coshocton',
    latitude: 40.3513,
    longitude: -81.8736,
    postal_code: '43828'
  },
  '43830': {
    country: 'US',
    city: 'Nashport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 40.0386,
    longitude: -82.0998,
    postal_code: '43830'
  },
  '43832': {
    country: 'US',
    city: 'Newcomerstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.2739,
    longitude: -81.594,
    postal_code: '43832'
  },
  '43836': {
    country: 'US',
    city: 'Plainfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Coshocton',
    latitude: 40.2087,
    longitude: -81.7197,
    postal_code: '43836'
  },
  '43837': {
    country: 'US',
    city: 'Port Washington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.3404,
    longitude: -81.5215,
    postal_code: '43837'
  },
  '43840': {
    country: 'US',
    city: 'Stone Creek',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.4052,
    longitude: -81.589,
    postal_code: '43840'
  },
  '43842': {
    country: 'US',
    city: 'Trinway',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Muskingum',
    latitude: 40.1412,
    longitude: -82.0099,
    postal_code: '43842'
  },
  '43843': {
    country: 'US',
    city: 'Walhonding',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Coshocton',
    latitude: 40.3407,
    longitude: -82.1559,
    postal_code: '43843'
  },
  '43844': {
    country: 'US',
    city: 'Warsaw',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Coshocton',
    latitude: 40.3172,
    longitude: -82.056,
    postal_code: '43844'
  },
  '43845': {
    country: 'US',
    city: 'West Lafayette',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Coshocton',
    latitude: 40.2718,
    longitude: -81.7361,
    postal_code: '43845'
  },
  '43901': {
    country: 'US',
    city: 'Adena',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.2126,
    longitude: -80.8815,
    postal_code: '43901'
  },
  '43902': {
    country: 'US',
    city: 'Alledonia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 39.9053,
    longitude: -80.9578,
    postal_code: '43902'
  },
  '43903': {
    country: 'US',
    city: 'Amsterdam',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.4731,
    longitude: -80.9596,
    postal_code: '43903'
  },
  '43905': {
    country: 'US',
    city: 'Barton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.1073,
    longitude: -80.8412,
    postal_code: '43905'
  },
  '43906': {
    country: 'US',
    city: 'Bellaire',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.0204,
    longitude: -80.7638,
    postal_code: '43906'
  },
  '43907': {
    country: 'US',
    city: 'Cadiz',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.2632,
    longitude: -81.0307,
    postal_code: '43907'
  },
  '43908': {
    country: 'US',
    city: 'Bergholz',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.5195,
    longitude: -80.884,
    postal_code: '43908'
  },
  '43909': {
    country: 'US',
    city: 'Blaine',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.0701,
    longitude: -80.8176,
    postal_code: '43909'
  },
  '43910': {
    country: 'US',
    city: 'Bloomingdale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.3742,
    longitude: -80.8072,
    postal_code: '43910'
  },
  '43912': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.0752,
    longitude: -80.7747,
    postal_code: '43912'
  },
  '43913': {
    country: 'US',
    city: 'Brilliant',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.2683,
    longitude: -80.6319,
    postal_code: '43913'
  },
  '43914': {
    country: 'US',
    city: 'Cameron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.7687,
    longitude: -80.9454,
    postal_code: '43914'
  },
  '43915': {
    country: 'US',
    city: 'Clarington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.7819,
    longitude: -80.9113,
    postal_code: '43915'
  },
  '43916': {
    country: 'US',
    city: 'Colerain',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.1224,
    longitude: -80.8149,
    postal_code: '43916'
  },
  '43917': {
    country: 'US',
    city: 'Dillonvale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.2251,
    longitude: -80.8031,
    postal_code: '43917'
  },
  '43920': {
    country: 'US',
    city: 'East Liverpool',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.6774,
    longitude: -80.6006,
    postal_code: '43920'
  },
  '43925': {
    country: 'US',
    city: 'East Springfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.4506,
    longitude: -80.8604,
    postal_code: '43925'
  },
  '43926': {
    country: 'US',
    city: 'Empire',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.5092,
    longitude: -80.6241,
    postal_code: '43926'
  },
  '43927': {
    country: 'US',
    city: 'Fairpoint',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.1225,
    longitude: -80.938,
    postal_code: '43927'
  },
  '43928': {
    country: 'US',
    city: 'Glencoe',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.0109,
    longitude: -80.8993,
    postal_code: '43928'
  },
  '43930': {
    country: 'US',
    city: 'Hammondsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.5676,
    longitude: -80.7659,
    postal_code: '43930'
  },
  '43931': {
    country: 'US',
    city: 'Hannibal',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.6673,
    longitude: -80.872,
    postal_code: '43931'
  },
  '43932': {
    country: 'US',
    city: 'Irondale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.5111,
    longitude: -80.7915,
    postal_code: '43932'
  },
  '43933': {
    country: 'US',
    city: 'Jacobsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 39.9372,
    longitude: -80.888,
    postal_code: '43933'
  },
  '43934': {
    country: 'US',
    city: 'Lansing',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.0756,
    longitude: -80.7901,
    postal_code: '43934'
  },
  '43935': {
    country: 'US',
    city: 'Martins Ferry',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.1036,
    longitude: -80.7361,
    postal_code: '43935'
  },
  '43937': {
    country: 'US',
    city: 'Maynard',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.1302,
    longitude: -80.8773,
    postal_code: '43937'
  },
  '43938': {
    country: 'US',
    city: 'Mingo Junction',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.3203,
    longitude: -80.625,
    postal_code: '43938'
  },
  '43939': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.1753,
    longitude: -80.7979,
    postal_code: '43939'
  },
  '43940': {
    country: 'US',
    city: 'Neffs',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.0284,
    longitude: -80.8245,
    postal_code: '43940'
  },
  '43941': {
    country: 'US',
    city: 'Piney Fork',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.2639,
    longitude: -80.8358,
    postal_code: '43941'
  },
  '43942': {
    country: 'US',
    city: 'Powhatan Point',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 39.8679,
    longitude: -80.8168,
    postal_code: '43942'
  },
  '43943': {
    country: 'US',
    city: 'Rayland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.2083,
    longitude: -80.7125,
    postal_code: '43943'
  },
  '43944': {
    country: 'US',
    city: 'Richmond',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.4261,
    longitude: -80.7613,
    postal_code: '43944'
  },
  '43945': {
    country: 'US',
    city: 'Salineville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.6195,
    longitude: -80.835,
    postal_code: '43945'
  },
  '43946': {
    country: 'US',
    city: 'Sardis',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.6526,
    longitude: -80.9243,
    postal_code: '43946'
  },
  '43947': {
    country: 'US',
    city: 'Shadyside',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 39.9675,
    longitude: -80.7643,
    postal_code: '43947'
  },
  '43948': {
    country: 'US',
    city: 'Smithfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.2709,
    longitude: -80.7815,
    postal_code: '43948'
  },
  '43950': {
    country: 'US',
    city: 'Saint Clairsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.0778,
    longitude: -80.9788,
    postal_code: '43950'
  },
  '43951': {
    country: 'US',
    city: 'Lafferty',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.111,
    longitude: -81.0102,
    postal_code: '43951'
  },
  '43952': {
    country: 'US',
    city: 'Steubenville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.3698,
    longitude: -80.634,
    postal_code: '43952'
  },
  '43953': {
    country: 'US',
    city: 'Steubenville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.3524,
    longitude: -80.6781,
    postal_code: '43953'
  },
  '43961': {
    country: 'US',
    city: 'Stratton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.5185,
    longitude: -80.6285,
    postal_code: '43961'
  },
  '43962': {
    country: 'US',
    city: 'Summitville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.6781,
    longitude: -80.8862,
    postal_code: '43962'
  },
  '43963': {
    country: 'US',
    city: 'Tiltonsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.1681,
    longitude: -80.6996,
    postal_code: '43963'
  },
  '43964': {
    country: 'US',
    city: 'Toronto',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.4733,
    longitude: -80.6325,
    postal_code: '43964'
  },
  '43967': {
    country: 'US',
    city: 'Warnock',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.0226,
    longitude: -80.9373,
    postal_code: '43967'
  },
  '43968': {
    country: 'US',
    city: 'Wellsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.6171,
    longitude: -80.6621,
    postal_code: '43968'
  },
  '43970': {
    country: 'US',
    city: 'Wolf Run',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.4673,
    longitude: -80.8893,
    postal_code: '43970'
  },
  '43971': {
    country: 'US',
    city: 'Yorkville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jefferson',
    latitude: 40.1581,
    longitude: -80.7077,
    postal_code: '43971'
  },
  '43972': {
    country: 'US',
    city: 'Bannock',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.1032,
    longitude: -80.9756,
    postal_code: '43972'
  },
  '43973': {
    country: 'US',
    city: 'Freeport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.1925,
    longitude: -81.277,
    postal_code: '43973'
  },
  '43974': {
    country: 'US',
    city: 'Harrisville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.1815,
    longitude: -80.8882,
    postal_code: '43974'
  },
  '43976': {
    country: 'US',
    city: 'Hopedale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.3496,
    longitude: -80.9021,
    postal_code: '43976'
  },
  '43977': {
    country: 'US',
    city: 'Flushing',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.1451,
    longitude: -81.0757,
    postal_code: '43977'
  },
  '43981': {
    country: 'US',
    city: 'New Athens',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.1839,
    longitude: -80.9959,
    postal_code: '43981'
  },
  '43983': {
    country: 'US',
    city: 'Piedmont',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.1507,
    longitude: -81.2145,
    postal_code: '43983'
  },
  '43984': {
    country: 'US',
    city: 'New Rumley',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.4017,
    longitude: -81.0309,
    postal_code: '43984'
  },
  '43985': {
    country: 'US',
    city: 'Holloway',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Belmont',
    latitude: 40.1623,
    longitude: -81.1326,
    postal_code: '43985'
  },
  '43986': {
    country: 'US',
    city: 'Jewett',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.3745,
    longitude: -81.0004,
    postal_code: '43986'
  },
  '43988': {
    country: 'US',
    city: 'Scio',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.4012,
    longitude: -81.1016,
    postal_code: '43988'
  },
  '44001': {
    country: 'US',
    city: 'Amherst',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.3617,
    longitude: -82.2538,
    postal_code: '44001'
  },
  '44003': {
    country: 'US',
    city: 'Andover',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.6225,
    longitude: -80.5754,
    postal_code: '44003'
  },
  '44004': {
    country: 'US',
    city: 'Ashtabula',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.8679,
    longitude: -80.7947,
    postal_code: '44004'
  },
  '44005': {
    country: 'US',
    city: 'Ashtabula',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.8651,
    longitude: -80.7898,
    postal_code: '44005'
  },
  '44010': {
    country: 'US',
    city: 'Austinburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.7554,
    longitude: -80.8584,
    postal_code: '44010'
  },
  '44011': {
    country: 'US',
    city: 'Avon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.4467,
    longitude: -82.0204,
    postal_code: '44011'
  },
  '44012': {
    country: 'US',
    city: 'Avon Lake',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.5019,
    longitude: -82.0111,
    postal_code: '44012'
  },
  '44017': {
    country: 'US',
    city: 'Berea',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3676,
    longitude: -81.8618,
    postal_code: '44017'
  },
  '44021': {
    country: 'US',
    city: 'Burton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.4527,
    longitude: -81.1526,
    postal_code: '44021'
  },
  '44022': {
    country: 'US',
    city: 'Chagrin Falls',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4494,
    longitude: -81.4314,
    postal_code: '44022'
  },
  '44023': {
    country: 'US',
    city: 'Chagrin Falls',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.3872,
    longitude: -81.3042,
    postal_code: '44023'
  },
  '44024': {
    country: 'US',
    city: 'Chardon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.5719,
    longitude: -81.2056,
    postal_code: '44024'
  },
  '44026': {
    country: 'US',
    city: 'Chesterland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.5344,
    longitude: -81.3421,
    postal_code: '44026'
  },
  '44028': {
    country: 'US',
    city: 'Columbia Station',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.3187,
    longitude: -81.9344,
    postal_code: '44028'
  },
  '44030': {
    country: 'US',
    city: 'Conneaut',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.9345,
    longitude: -80.5803,
    postal_code: '44030'
  },
  '44032': {
    country: 'US',
    city: 'Dorset',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.659,
    longitude: -80.6683,
    postal_code: '44032'
  },
  '44033': {
    country: 'US',
    city: 'East Claridon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.5333,
    longitude: -81.1112,
    postal_code: '44033'
  },
  '44035': {
    country: 'US',
    city: 'Elyria',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.3724,
    longitude: -82.1051,
    postal_code: '44035'
  },
  '44036': {
    country: 'US',
    city: 'Elyria',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.4015,
    longitude: -82.0771,
    postal_code: '44036'
  },
  '44039': {
    country: 'US',
    city: 'North Ridgeville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.3964,
    longitude: -82.0033,
    postal_code: '44039'
  },
  '44040': {
    country: 'US',
    city: 'Gates Mills',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5324,
    longitude: -81.415,
    postal_code: '44040'
  },
  '44041': {
    country: 'US',
    city: 'Geneva',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.8029,
    longitude: -80.9474,
    postal_code: '44041'
  },
  '44044': {
    country: 'US',
    city: 'Grafton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.2854,
    longitude: -82.0431,
    postal_code: '44044'
  },
  '44045': {
    country: 'US',
    city: 'Grand River',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.7427,
    longitude: -81.2821,
    postal_code: '44045'
  },
  '44046': {
    country: 'US',
    city: 'Huntsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.5384,
    longitude: -81.0734,
    postal_code: '44046'
  },
  '44047': {
    country: 'US',
    city: 'Jefferson',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.7335,
    longitude: -80.7562,
    postal_code: '44047'
  },
  '44048': {
    country: 'US',
    city: 'Kingsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.8723,
    longitude: -80.6601,
    postal_code: '44048'
  },
  '44049': {
    country: 'US',
    city: 'Kipton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.2664,
    longitude: -82.3064,
    postal_code: '44049'
  },
  '44050': {
    country: 'US',
    city: 'Lagrange',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.2423,
    longitude: -82.1279,
    postal_code: '44050'
  },
  '44052': {
    country: 'US',
    city: 'Lorain',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.4578,
    longitude: -82.171,
    postal_code: '44052'
  },
  '44053': {
    country: 'US',
    city: 'Lorain',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.432,
    longitude: -82.2038,
    postal_code: '44053'
  },
  '44054': {
    country: 'US',
    city: 'Sheffield Lake',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.4823,
    longitude: -82.0965,
    postal_code: '44054'
  },
  '44055': {
    country: 'US',
    city: 'Lorain',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.4361,
    longitude: -82.135,
    postal_code: '44055'
  },
  '44056': {
    country: 'US',
    city: 'Macedonia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.3222,
    longitude: -81.4996,
    postal_code: '44056'
  },
  '44057': {
    country: 'US',
    city: 'Madison',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.8054,
    longitude: -81.0588,
    postal_code: '44057'
  },
  '44060': {
    country: 'US',
    city: 'Mentor',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.6895,
    longitude: -81.3421,
    postal_code: '44060'
  },
  '44061': {
    country: 'US',
    city: 'Mentor',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.6662,
    longitude: -81.3396,
    postal_code: '44061'
  },
  '44062': {
    country: 'US',
    city: 'Middlefield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.4455,
    longitude: -81.0373,
    postal_code: '44062'
  },
  '44064': {
    country: 'US',
    city: 'Montville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.6034,
    longitude: -81.057,
    postal_code: '44064'
  },
  '44065': {
    country: 'US',
    city: 'Newbury',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.475,
    longitude: -81.2345,
    postal_code: '44065'
  },
  '44067': {
    country: 'US',
    city: 'Northfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.3208,
    longitude: -81.5429,
    postal_code: '44067'
  },
  '44068': {
    country: 'US',
    city: 'North Kingsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.9046,
    longitude: -80.685,
    postal_code: '44068'
  },
  '44070': {
    country: 'US',
    city: 'North Olmsted',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4201,
    longitude: -81.9131,
    postal_code: '44070'
  },
  '44072': {
    country: 'US',
    city: 'Novelty',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.4763,
    longitude: -81.3342,
    postal_code: '44072'
  },
  '44073': {
    country: 'US',
    city: 'Novelty',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.4948,
    longitude: -81.3407,
    postal_code: '44073'
  },
  '44074': {
    country: 'US',
    city: 'Oberlin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.2899,
    longitude: -82.2229,
    postal_code: '44074'
  },
  '44076': {
    country: 'US',
    city: 'Orwell',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.5293,
    longitude: -80.8208,
    postal_code: '44076'
  },
  '44077': {
    country: 'US',
    city: 'Painesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.7079,
    longitude: -81.199,
    postal_code: '44077'
  },
  '44080': {
    country: 'US',
    city: 'Parkman',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.3706,
    longitude: -81.0651,
    postal_code: '44080'
  },
  '44081': {
    country: 'US',
    city: 'Perry',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.7679,
    longitude: -81.1433,
    postal_code: '44081'
  },
  '44082': {
    country: 'US',
    city: 'Pierpont',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.7677,
    longitude: -80.5741,
    postal_code: '44082'
  },
  '44084': {
    country: 'US',
    city: 'Rock Creek',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.6603,
    longitude: -80.8606,
    postal_code: '44084'
  },
  '44085': {
    country: 'US',
    city: 'Rome',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.6053,
    longitude: -80.8709,
    postal_code: '44085'
  },
  '44086': {
    country: 'US',
    city: 'Thompson',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Geauga',
    latitude: 41.6762,
    longitude: -81.0573,
    postal_code: '44086'
  },
  '44087': {
    country: 'US',
    city: 'Twinsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.3289,
    longitude: -81.4559,
    postal_code: '44087'
  },
  '44088': {
    country: 'US',
    city: 'Unionville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.7833,
    longitude: -81.0034,
    postal_code: '44088'
  },
  '44089': {
    country: 'US',
    city: 'Vermilion',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Erie',
    latitude: 41.41,
    longitude: -82.3554,
    postal_code: '44089'
  },
  '44090': {
    country: 'US',
    city: 'Wellington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lorain',
    latitude: 41.1712,
    longitude: -82.2269,
    postal_code: '44090'
  },
  '44092': {
    country: 'US',
    city: 'Wickliffe',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.6046,
    longitude: -81.4692,
    postal_code: '44092'
  },
  '44093': {
    country: 'US',
    city: 'Williamsfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.5383,
    longitude: -80.5964,
    postal_code: '44093'
  },
  '44094': {
    country: 'US',
    city: 'Willoughby',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.6302,
    longitude: -81.4076,
    postal_code: '44094'
  },
  '44095': {
    country: 'US',
    city: 'Eastlake',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.6587,
    longitude: -81.4445,
    postal_code: '44095'
  },
  '44096': {
    country: 'US',
    city: 'Willoughby',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.6398,
    longitude: -81.4065,
    postal_code: '44096'
  },
  '44097': {
    country: 'US',
    city: 'Eastlake',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lake',
    latitude: 41.6539,
    longitude: -81.4504,
    postal_code: '44097'
  },
  '44099': {
    country: 'US',
    city: 'Windsor',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashtabula',
    latitude: 41.5623,
    longitude: -80.9667,
    postal_code: '44099'
  },
  '44101': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5234,
    longitude: -81.5996,
    postal_code: '44101'
  },
  '44102': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4735,
    longitude: -81.7398,
    postal_code: '44102'
  },
  '44103': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5157,
    longitude: -81.6405,
    postal_code: '44103'
  },
  '44104': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4809,
    longitude: -81.6245,
    postal_code: '44104'
  },
  '44105': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4509,
    longitude: -81.619,
    postal_code: '44105'
  },
  '44106': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5084,
    longitude: -81.6076,
    postal_code: '44106'
  },
  '44107': {
    country: 'US',
    city: 'Lakewood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4847,
    longitude: -81.8018,
    postal_code: '44107'
  },
  '44108': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5349,
    longitude: -81.609,
    postal_code: '44108'
  },
  '44109': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4458,
    longitude: -81.7033,
    postal_code: '44109'
  },
  '44110': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5636,
    longitude: -81.5733,
    postal_code: '44110'
  },
  '44111': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4571,
    longitude: -81.7844,
    postal_code: '44111'
  },
  '44112': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5356,
    longitude: -81.5737,
    postal_code: '44112'
  },
  '44113': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4816,
    longitude: -81.7018,
    postal_code: '44113'
  },
  '44114': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5064,
    longitude: -81.6743,
    postal_code: '44114'
  },
  '44115': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4946,
    longitude: -81.667,
    postal_code: '44115'
  },
  '44116': {
    country: 'US',
    city: 'Rocky River',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4694,
    longitude: -81.8512,
    postal_code: '44116'
  },
  '44117': {
    country: 'US',
    city: 'Euclid',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5696,
    longitude: -81.5257,
    postal_code: '44117'
  },
  '44118': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5008,
    longitude: -81.5516,
    postal_code: '44118'
  },
  '44119': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5882,
    longitude: -81.5468,
    postal_code: '44119'
  },
  '44120': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4714,
    longitude: -81.5839,
    postal_code: '44120'
  },
  '44121': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5277,
    longitude: -81.5323,
    postal_code: '44121'
  },
  '44122': {
    country: 'US',
    city: 'Beachwood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4701,
    longitude: -81.5232,
    postal_code: '44122'
  },
  '44123': {
    country: 'US',
    city: 'Euclid',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.6025,
    longitude: -81.5258,
    postal_code: '44123'
  },
  '44124': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.501,
    longitude: -81.4694,
    postal_code: '44124'
  },
  '44125': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4335,
    longitude: -81.6323,
    postal_code: '44125'
  },
  '44126': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4429,
    longitude: -81.853,
    postal_code: '44126'
  },
  '44127': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4701,
    longitude: -81.649,
    postal_code: '44127'
  },
  '44128': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4416,
    longitude: -81.5486,
    postal_code: '44128'
  },
  '44129': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3897,
    longitude: -81.7351,
    postal_code: '44129'
  },
  '44130': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3826,
    longitude: -81.7964,
    postal_code: '44130'
  },
  '44131': {
    country: 'US',
    city: 'Independence',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3809,
    longitude: -81.6642,
    postal_code: '44131'
  },
  '44132': {
    country: 'US',
    city: 'Euclid',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.6069,
    longitude: -81.4993,
    postal_code: '44132'
  },
  '44133': {
    country: 'US',
    city: 'North Royalton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3232,
    longitude: -81.7457,
    postal_code: '44133'
  },
  '44134': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3853,
    longitude: -81.7044,
    postal_code: '44134'
  },
  '44135': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4342,
    longitude: -81.8044,
    postal_code: '44135'
  },
  '44136': {
    country: 'US',
    city: 'Strongsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3132,
    longitude: -81.8285,
    postal_code: '44136'
  },
  '44137': {
    country: 'US',
    city: 'Maple Heights',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4105,
    longitude: -81.5603,
    postal_code: '44137'
  },
  '44138': {
    country: 'US',
    city: 'Olmsted Falls',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3734,
    longitude: -81.9158,
    postal_code: '44138'
  },
  '44139': {
    country: 'US',
    city: 'Solon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3866,
    longitude: -81.4421,
    postal_code: '44139'
  },
  '44140': {
    country: 'US',
    city: 'Bay Village',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4841,
    longitude: -81.9289,
    postal_code: '44140'
  },
  '44141': {
    country: 'US',
    city: 'Brecksville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3166,
    longitude: -81.6261,
    postal_code: '44141'
  },
  '44142': {
    country: 'US',
    city: 'Brookpark',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3979,
    longitude: -81.8118,
    postal_code: '44142'
  },
  '44143': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.5592,
    longitude: -81.4828,
    postal_code: '44143'
  },
  '44144': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4385,
    longitude: -81.7398,
    postal_code: '44144'
  },
  '44145': {
    country: 'US',
    city: 'Westlake',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4535,
    longitude: -81.9218,
    postal_code: '44145'
  },
  '44146': {
    country: 'US',
    city: 'Bedford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3921,
    longitude: -81.5232,
    postal_code: '44146'
  },
  '44147': {
    country: 'US',
    city: 'Broadview Heights',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.3141,
    longitude: -81.6731,
    postal_code: '44147'
  },
  '44149': {
    country: 'US',
    city: 'Strongsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.3134,
    longitude: -81.8562,
    postal_code: '44149'
  },
  '44181': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.6857,
    longitude: -81.6728,
    postal_code: '44181'
  },
  '44188': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.6857,
    longitude: -81.6728,
    postal_code: '44188'
  },
  '44190': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.6857,
    longitude: -81.6728,
    postal_code: '44190'
  },
  '44191': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.6857,
    longitude: -81.6728,
    postal_code: '44191'
  },
  '44192': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4995,
    longitude: -81.6954,
    postal_code: '44192'
  },
  '44193': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4995,
    longitude: -81.6954,
    postal_code: '44193'
  },
  '44194': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.6857,
    longitude: -81.6728,
    postal_code: '44194'
  },
  '44195': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.6857,
    longitude: -81.6728,
    postal_code: '44195'
  },
  '44197': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.6857,
    longitude: -81.6728,
    postal_code: '44197'
  },
  '44198': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.6857,
    longitude: -81.6728,
    postal_code: '44198'
  },
  '44199': {
    country: 'US',
    city: 'Cleveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Cuyahoga',
    latitude: 41.4995,
    longitude: -81.6954,
    postal_code: '44199'
  },
  '44201': {
    country: 'US',
    city: 'Atwater',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.0335,
    longitude: -81.1985,
    postal_code: '44201'
  },
  '44202': {
    country: 'US',
    city: 'Aurora',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.3176,
    longitude: -81.3454,
    postal_code: '44202'
  },
  '44203': {
    country: 'US',
    city: 'Barberton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0197,
    longitude: -81.6212,
    postal_code: '44203'
  },
  '44210': {
    country: 'US',
    city: 'Bath',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1889,
    longitude: -81.6362,
    postal_code: '44210'
  },
  '44211': {
    country: 'US',
    city: 'Brady Lake',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.1698,
    longitude: -81.3124,
    postal_code: '44211'
  },
  '44212': {
    country: 'US',
    city: 'Brunswick',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.2471,
    longitude: -81.828,
    postal_code: '44212'
  },
  '44214': {
    country: 'US',
    city: 'Burbank',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.9637,
    longitude: -81.9958,
    postal_code: '44214'
  },
  '44215': {
    country: 'US',
    city: 'Chippewa Lake',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.0653,
    longitude: -81.9017,
    postal_code: '44215'
  },
  '44216': {
    country: 'US',
    city: 'Clinton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 40.9391,
    longitude: -81.5871,
    postal_code: '44216'
  },
  '44217': {
    country: 'US',
    city: 'Creston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.9788,
    longitude: -81.9211,
    postal_code: '44217'
  },
  '44221': {
    country: 'US',
    city: 'Cuyahoga Falls',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1401,
    longitude: -81.479,
    postal_code: '44221'
  },
  '44222': {
    country: 'US',
    city: 'Cuyahoga Falls',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1339,
    longitude: -81.4846,
    postal_code: '44222'
  },
  '44223': {
    country: 'US',
    city: 'Cuyahoga Falls',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1464,
    longitude: -81.5107,
    postal_code: '44223'
  },
  '44224': {
    country: 'US',
    city: 'Stow',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1748,
    longitude: -81.438,
    postal_code: '44224'
  },
  '44230': {
    country: 'US',
    city: 'Doylestown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.965,
    longitude: -81.6848,
    postal_code: '44230'
  },
  '44231': {
    country: 'US',
    city: 'Garrettsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.2988,
    longitude: -81.0704,
    postal_code: '44231'
  },
  '44232': {
    country: 'US',
    city: 'Green',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 40.9325,
    longitude: -81.462,
    postal_code: '44232'
  },
  '44233': {
    country: 'US',
    city: 'Hinckley',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.2419,
    longitude: -81.7453,
    postal_code: '44233'
  },
  '44234': {
    country: 'US',
    city: 'Hiram',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.3323,
    longitude: -81.1464,
    postal_code: '44234'
  },
  '44235': {
    country: 'US',
    city: 'Homerville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.0267,
    longitude: -82.125,
    postal_code: '44235'
  },
  '44236': {
    country: 'US',
    city: 'Hudson',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.2458,
    longitude: -81.4367,
    postal_code: '44236'
  },
  '44237': {
    country: 'US',
    city: 'Hudson',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1287,
    longitude: -81.54,
    postal_code: '44237'
  },
  '44240': {
    country: 'US',
    city: 'Kent',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.1449,
    longitude: -81.3498,
    postal_code: '44240'
  },
  '44241': {
    country: 'US',
    city: 'Streetsboro',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.2491,
    longitude: -81.3383,
    postal_code: '44241'
  },
  '44242': {
    country: 'US',
    city: 'Kent',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.1537,
    longitude: -81.3579,
    postal_code: '44242'
  },
  '44243': {
    country: 'US',
    city: 'Kent',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.1475,
    longitude: -81.3415,
    postal_code: '44243'
  },
  '44250': {
    country: 'US',
    city: 'Lakemore',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0222,
    longitude: -81.4279,
    postal_code: '44250'
  },
  '44251': {
    country: 'US',
    city: 'Westfield Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.0288,
    longitude: -81.9283,
    postal_code: '44251'
  },
  '44253': {
    country: 'US',
    city: 'Litchfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.1668,
    longitude: -82.0157,
    postal_code: '44253'
  },
  '44254': {
    country: 'US',
    city: 'Lodi',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.0327,
    longitude: -82.0147,
    postal_code: '44254'
  },
  '44255': {
    country: 'US',
    city: 'Mantua',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.2941,
    longitude: -81.2283,
    postal_code: '44255'
  },
  '44256': {
    country: 'US',
    city: 'Medina',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.1404,
    longitude: -81.8584,
    postal_code: '44256'
  },
  '44258': {
    country: 'US',
    city: 'Medina',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.1276,
    longitude: -81.8411,
    postal_code: '44258'
  },
  '44260': {
    country: 'US',
    city: 'Mogadore',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0382,
    longitude: -81.359,
    postal_code: '44260'
  },
  '44262': {
    country: 'US',
    city: 'Munroe Falls',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.142,
    longitude: -81.4376,
    postal_code: '44262'
  },
  '44264': {
    country: 'US',
    city: 'Peninsula',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.2256,
    longitude: -81.54,
    postal_code: '44264'
  },
  '44265': {
    country: 'US',
    city: 'Randolph',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.0328,
    longitude: -81.2484,
    postal_code: '44265'
  },
  '44266': {
    country: 'US',
    city: 'Ravenna',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.1649,
    longitude: -81.2337,
    postal_code: '44266'
  },
  '44270': {
    country: 'US',
    city: 'Rittman',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.9684,
    longitude: -81.7826,
    postal_code: '44270'
  },
  '44272': {
    country: 'US',
    city: 'Rootstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.0995,
    longitude: -81.2026,
    postal_code: '44272'
  },
  '44273': {
    country: 'US',
    city: 'Seville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.0227,
    longitude: -81.8562,
    postal_code: '44273'
  },
  '44274': {
    country: 'US',
    city: 'Sharon Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.0992,
    longitude: -81.7343,
    postal_code: '44274'
  },
  '44275': {
    country: 'US',
    city: 'Spencer',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.0983,
    longitude: -82.0999,
    postal_code: '44275'
  },
  '44276': {
    country: 'US',
    city: 'Sterling',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.9369,
    longitude: -81.8305,
    postal_code: '44276'
  },
  '44278': {
    country: 'US',
    city: 'Tallmadge',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0975,
    longitude: -81.426,
    postal_code: '44278'
  },
  '44280': {
    country: 'US',
    city: 'Valley City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.2368,
    longitude: -81.9245,
    postal_code: '44280'
  },
  '44281': {
    country: 'US',
    city: 'Wadsworth',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.0384,
    longitude: -81.7374,
    postal_code: '44281'
  },
  '44282': {
    country: 'US',
    city: 'Wadsworth',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Medina',
    latitude: 41.0256,
    longitude: -81.7299,
    postal_code: '44282'
  },
  '44285': {
    country: 'US',
    city: 'Wayland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.1597,
    longitude: -81.07,
    postal_code: '44285'
  },
  '44286': {
    country: 'US',
    city: 'Richfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.2371,
    longitude: -81.6467,
    postal_code: '44286'
  },
  '44287': {
    country: 'US',
    city: 'West Salem',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.9485,
    longitude: -82.1066,
    postal_code: '44287'
  },
  '44288': {
    country: 'US',
    city: 'Windham',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.2392,
    longitude: -81.0535,
    postal_code: '44288'
  },
  '44301': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0449,
    longitude: -81.52,
    postal_code: '44301'
  },
  '44302': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.092,
    longitude: -81.542,
    postal_code: '44302'
  },
  '44303': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1025,
    longitude: -81.5386,
    postal_code: '44303'
  },
  '44304': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0814,
    longitude: -81.519,
    postal_code: '44304'
  },
  '44305': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.076,
    longitude: -81.4644,
    postal_code: '44305'
  },
  '44306': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0479,
    longitude: -81.4916,
    postal_code: '44306'
  },
  '44307': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0695,
    longitude: -81.5488,
    postal_code: '44307'
  },
  '44308': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0796,
    longitude: -81.5194,
    postal_code: '44308'
  },
  '44309': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0962,
    longitude: -81.5123,
    postal_code: '44309'
  },
  '44310': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1075,
    longitude: -81.5006,
    postal_code: '44310'
  },
  '44311': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0638,
    longitude: -81.52,
    postal_code: '44311'
  },
  '44312': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0334,
    longitude: -81.4385,
    postal_code: '44312'
  },
  '44313': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.122,
    longitude: -81.5685,
    postal_code: '44313'
  },
  '44314': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0408,
    longitude: -81.5598,
    postal_code: '44314'
  },
  '44315': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.028,
    longitude: -81.4632,
    postal_code: '44315'
  },
  '44316': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0675,
    longitude: -81.4847,
    postal_code: '44316'
  },
  '44317': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0525,
    longitude: -81.5291,
    postal_code: '44317'
  },
  '44319': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 40.9791,
    longitude: -81.5347,
    postal_code: '44319'
  },
  '44320': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0835,
    longitude: -81.5674,
    postal_code: '44320'
  },
  '44321': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1002,
    longitude: -81.6443,
    postal_code: '44321'
  },
  '44325': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0764,
    longitude: -81.5103,
    postal_code: '44325'
  },
  '44326': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.0814,
    longitude: -81.519,
    postal_code: '44326'
  },
  '44328': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.076,
    longitude: -81.5206,
    postal_code: '44328'
  },
  '44333': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1552,
    longitude: -81.6314,
    postal_code: '44333'
  },
  '44334': {
    country: 'US',
    city: 'Fairlawn',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1278,
    longitude: -81.6098,
    postal_code: '44334'
  },
  '44372': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1287,
    longitude: -81.54,
    postal_code: '44372'
  },
  '44396': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1287,
    longitude: -81.54,
    postal_code: '44396'
  },
  '44398': {
    country: 'US',
    city: 'Akron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 41.1287,
    longitude: -81.54,
    postal_code: '44398'
  },
  '44401': {
    country: 'US',
    city: 'Berlin Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0243,
    longitude: -80.9341,
    postal_code: '44401'
  },
  '44402': {
    country: 'US',
    city: 'Bristolville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.3797,
    longitude: -80.8568,
    postal_code: '44402'
  },
  '44403': {
    country: 'US',
    city: 'Brookfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.248,
    longitude: -80.5788,
    postal_code: '44403'
  },
  '44404': {
    country: 'US',
    city: 'Burghill',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.3347,
    longitude: -80.5441,
    postal_code: '44404'
  },
  '44405': {
    country: 'US',
    city: 'Campbell',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0778,
    longitude: -80.5897,
    postal_code: '44405'
  },
  '44406': {
    country: 'US',
    city: 'Canfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0293,
    longitude: -80.7564,
    postal_code: '44406'
  },
  '44408': {
    country: 'US',
    city: 'Columbiana',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.8853,
    longitude: -80.6975,
    postal_code: '44408'
  },
  '44410': {
    country: 'US',
    city: 'Cortland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.3251,
    longitude: -80.7327,
    postal_code: '44410'
  },
  '44411': {
    country: 'US',
    city: 'Deerfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.0359,
    longitude: -81.0528,
    postal_code: '44411'
  },
  '44412': {
    country: 'US',
    city: 'Diamond',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Portage',
    latitude: 41.0935,
    longitude: -81.0425,
    postal_code: '44412'
  },
  '44413': {
    country: 'US',
    city: 'East Palestine',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.8406,
    longitude: -80.5465,
    postal_code: '44413'
  },
  '44415': {
    country: 'US',
    city: 'Elkton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.763,
    longitude: -80.7042,
    postal_code: '44415'
  },
  '44416': {
    country: 'US',
    city: 'Ellsworth',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0242,
    longitude: -80.8573,
    postal_code: '44416'
  },
  '44417': {
    country: 'US',
    city: 'Farmdale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.3923,
    longitude: -80.6628,
    postal_code: '44417'
  },
  '44418': {
    country: 'US',
    city: 'Fowler',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.3349,
    longitude: -80.6059,
    postal_code: '44418'
  },
  '44420': {
    country: 'US',
    city: 'Girard',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.1611,
    longitude: -80.6933,
    postal_code: '44420'
  },
  '44422': {
    country: 'US',
    city: 'Greenford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 40.9439,
    longitude: -80.7915,
    postal_code: '44422'
  },
  '44423': {
    country: 'US',
    city: 'Hanoverton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.7731,
    longitude: -80.9144,
    postal_code: '44423'
  },
  '44424': {
    country: 'US',
    city: 'Hartford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.3091,
    longitude: -80.5847,
    postal_code: '44424'
  },
  '44425': {
    country: 'US',
    city: 'Hubbard',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.1624,
    longitude: -80.5762,
    postal_code: '44425'
  },
  '44427': {
    country: 'US',
    city: 'Kensington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.7142,
    longitude: -80.9381,
    postal_code: '44427'
  },
  '44428': {
    country: 'US',
    city: 'Kinsman',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.4354,
    longitude: -80.5765,
    postal_code: '44428'
  },
  '44429': {
    country: 'US',
    city: 'Lake Milton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.1014,
    longitude: -80.9724,
    postal_code: '44429'
  },
  '44430': {
    country: 'US',
    city: 'Leavittsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.2445,
    longitude: -80.8869,
    postal_code: '44430'
  },
  '44431': {
    country: 'US',
    city: 'Leetonia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.8631,
    longitude: -80.7585,
    postal_code: '44431'
  },
  '44432': {
    country: 'US',
    city: 'Lisbon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.7592,
    longitude: -80.7587,
    postal_code: '44432'
  },
  '44436': {
    country: 'US',
    city: 'Lowellville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0503,
    longitude: -80.5416,
    postal_code: '44436'
  },
  '44437': {
    country: 'US',
    city: 'Mc Donald',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.158,
    longitude: -80.7312,
    postal_code: '44437'
  },
  '44438': {
    country: 'US',
    city: 'Masury',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.2255,
    longitude: -80.5326,
    postal_code: '44438'
  },
  '44439': {
    country: 'US',
    city: 'Mesopotamia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.4584,
    longitude: -80.9551,
    postal_code: '44439'
  },
  '44440': {
    country: 'US',
    city: 'Mineral Ridge',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.1318,
    longitude: -80.7553,
    postal_code: '44440'
  },
  '44441': {
    country: 'US',
    city: 'Negley',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.7579,
    longitude: -80.553,
    postal_code: '44441'
  },
  '44442': {
    country: 'US',
    city: 'New Middletown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 40.9646,
    longitude: -80.5534,
    postal_code: '44442'
  },
  '44443': {
    country: 'US',
    city: 'New Springfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 40.9265,
    longitude: -80.5856,
    postal_code: '44443'
  },
  '44444': {
    country: 'US',
    city: 'Newton Falls',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.191,
    longitude: -80.9701,
    postal_code: '44444'
  },
  '44445': {
    country: 'US',
    city: 'New Waterford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.8489,
    longitude: -80.6209,
    postal_code: '44445'
  },
  '44446': {
    country: 'US',
    city: 'Niles',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.1824,
    longitude: -80.7558,
    postal_code: '44446'
  },
  '44449': {
    country: 'US',
    city: 'North Benton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 40.9876,
    longitude: -81.0162,
    postal_code: '44449'
  },
  '44450': {
    country: 'US',
    city: 'North Bloomfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.4569,
    longitude: -80.8068,
    postal_code: '44450'
  },
  '44451': {
    country: 'US',
    city: 'North Jackson',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.088,
    longitude: -80.8623,
    postal_code: '44451'
  },
  '44452': {
    country: 'US',
    city: 'North Lima',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 40.9649,
    longitude: -80.6549,
    postal_code: '44452'
  },
  '44453': {
    country: 'US',
    city: 'Orangeville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.3252,
    longitude: -80.5336,
    postal_code: '44453'
  },
  '44454': {
    country: 'US',
    city: 'Petersburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 40.9049,
    longitude: -80.54,
    postal_code: '44454'
  },
  '44455': {
    country: 'US',
    city: 'Rogers',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.7789,
    longitude: -80.6202,
    postal_code: '44455'
  },
  '44460': {
    country: 'US',
    city: 'Salem',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.9,
    longitude: -80.8619,
    postal_code: '44460'
  },
  '44470': {
    country: 'US',
    city: 'Southington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.2983,
    longitude: -80.9485,
    postal_code: '44470'
  },
  '44471': {
    country: 'US',
    city: 'Struthers',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0508,
    longitude: -80.5985,
    postal_code: '44471'
  },
  '44473': {
    country: 'US',
    city: 'Vienna',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.2175,
    longitude: -80.655,
    postal_code: '44473'
  },
  '44481': {
    country: 'US',
    city: 'Warren',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.1724,
    longitude: -80.8718,
    postal_code: '44481'
  },
  '44482': {
    country: 'US',
    city: 'Warren',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.3174,
    longitude: -80.7613,
    postal_code: '44482'
  },
  '44483': {
    country: 'US',
    city: 'Warren',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.2639,
    longitude: -80.8164,
    postal_code: '44483'
  },
  '44484': {
    country: 'US',
    city: 'Warren',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.2318,
    longitude: -80.7642,
    postal_code: '44484'
  },
  '44485': {
    country: 'US',
    city: 'Warren',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.2405,
    longitude: -80.8441,
    postal_code: '44485'
  },
  '44486': {
    country: 'US',
    city: 'Warren',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.3174,
    longitude: -80.7613,
    postal_code: '44486'
  },
  '44490': {
    country: 'US',
    city: 'Washingtonville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.8973,
    longitude: -80.7631,
    postal_code: '44490'
  },
  '44491': {
    country: 'US',
    city: 'West Farmington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Trumbull',
    latitude: 41.3508,
    longitude: -80.9672,
    postal_code: '44491'
  },
  '44492': {
    country: 'US',
    city: 'West Point',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.7093,
    longitude: -80.7014,
    postal_code: '44492'
  },
  '44493': {
    country: 'US',
    city: 'Winona',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.8286,
    longitude: -80.8967,
    postal_code: '44493'
  },
  '44501': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0171,
    longitude: -80.8029,
    postal_code: '44501'
  },
  '44502': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0774,
    longitude: -80.6409,
    postal_code: '44502'
  },
  '44503': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.102,
    longitude: -80.65,
    postal_code: '44503'
  },
  '44504': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.1237,
    longitude: -80.6539,
    postal_code: '44504'
  },
  '44505': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.1257,
    longitude: -80.6277,
    postal_code: '44505'
  },
  '44506': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.096,
    longitude: -80.6259,
    postal_code: '44506'
  },
  '44507': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0732,
    longitude: -80.6553,
    postal_code: '44507'
  },
  '44509': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.105,
    longitude: -80.6945,
    postal_code: '44509'
  },
  '44510': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.1197,
    longitude: -80.6672,
    postal_code: '44510'
  },
  '44511': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0704,
    longitude: -80.6931,
    postal_code: '44511'
  },
  '44512': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0252,
    longitude: -80.6687,
    postal_code: '44512'
  },
  '44513': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0171,
    longitude: -80.8029,
    postal_code: '44513'
  },
  '44514': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0093,
    longitude: -80.6183,
    postal_code: '44514'
  },
  '44515': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.0979,
    longitude: -80.7598,
    postal_code: '44515'
  },
  '44555': {
    country: 'US',
    city: 'Youngstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 41.1039,
    longitude: -80.6436,
    postal_code: '44555'
  },
  '44601': {
    country: 'US',
    city: 'Alliance',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.9158,
    longitude: -81.1182,
    postal_code: '44601'
  },
  '44606': {
    country: 'US',
    city: 'Apple Creek',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.7551,
    longitude: -81.8093,
    postal_code: '44606'
  },
  '44607': {
    country: 'US',
    city: 'Augusta',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Carroll',
    latitude: 40.686,
    longitude: -81.0219,
    postal_code: '44607'
  },
  '44608': {
    country: 'US',
    city: 'Beach City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.6562,
    longitude: -81.5851,
    postal_code: '44608'
  },
  '44609': {
    country: 'US',
    city: 'Beloit',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 40.8957,
    longitude: -80.9897,
    postal_code: '44609'
  },
  '44610': {
    country: 'US',
    city: 'Berlin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.5612,
    longitude: -81.7943,
    postal_code: '44610'
  },
  '44611': {
    country: 'US',
    city: 'Big Prairie',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.6188,
    longitude: -82.072,
    postal_code: '44611'
  },
  '44612': {
    country: 'US',
    city: 'Bolivar',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.6347,
    longitude: -81.4464,
    postal_code: '44612'
  },
  '44613': {
    country: 'US',
    city: 'Brewster',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7142,
    longitude: -81.5957,
    postal_code: '44613'
  },
  '44614': {
    country: 'US',
    city: 'Canal Fulton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8887,
    longitude: -81.5773,
    postal_code: '44614'
  },
  '44615': {
    country: 'US',
    city: 'Carrollton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Carroll',
    latitude: 40.5787,
    longitude: -81.0818,
    postal_code: '44615'
  },
  '44617': {
    country: 'US',
    city: 'Charm',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.5071,
    longitude: -81.7829,
    postal_code: '44617'
  },
  '44618': {
    country: 'US',
    city: 'Dalton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.7793,
    longitude: -81.7008,
    postal_code: '44618'
  },
  '44619': {
    country: 'US',
    city: 'Damascus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 40.9021,
    longitude: -80.9628,
    postal_code: '44619'
  },
  '44620': {
    country: 'US',
    city: 'Dellroy',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Carroll',
    latitude: 40.5861,
    longitude: -81.1986,
    postal_code: '44620'
  },
  '44621': {
    country: 'US',
    city: 'Dennison',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.4089,
    longitude: -81.3203,
    postal_code: '44621'
  },
  '44622': {
    country: 'US',
    city: 'Dover',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.5343,
    longitude: -81.4763,
    postal_code: '44622'
  },
  '44624': {
    country: 'US',
    city: 'Dundee',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.589,
    longitude: -81.6058,
    postal_code: '44624'
  },
  '44625': {
    country: 'US',
    city: 'East Rochester',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.7563,
    longitude: -81.0175,
    postal_code: '44625'
  },
  '44626': {
    country: 'US',
    city: 'East Sparta',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.6971,
    longitude: -81.3687,
    postal_code: '44626'
  },
  '44627': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.686,
    longitude: -81.8518,
    postal_code: '44627'
  },
  '44628': {
    country: 'US',
    city: 'Glenmont',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.5217,
    longitude: -82.1505,
    postal_code: '44628'
  },
  '44629': {
    country: 'US',
    city: 'Gnadenhutten',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.3584,
    longitude: -81.4343,
    postal_code: '44629'
  },
  '44630': {
    country: 'US',
    city: 'Greentown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.9295,
    longitude: -81.4001,
    postal_code: '44630'
  },
  '44632': {
    country: 'US',
    city: 'Hartville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.9618,
    longitude: -81.3239,
    postal_code: '44632'
  },
  '44633': {
    country: 'US',
    city: 'Holmesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.633,
    longitude: -81.9275,
    postal_code: '44633'
  },
  '44634': {
    country: 'US',
    city: 'Homeworth',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.8301,
    longitude: -81.0333,
    postal_code: '44634'
  },
  '44636': {
    country: 'US',
    city: 'Kidron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.7384,
    longitude: -81.7428,
    postal_code: '44636'
  },
  '44637': {
    country: 'US',
    city: 'Killbuck',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.4933,
    longitude: -81.9837,
    postal_code: '44637'
  },
  '44638': {
    country: 'US',
    city: 'Lakeville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.652,
    longitude: -82.1455,
    postal_code: '44638'
  },
  '44639': {
    country: 'US',
    city: 'Leesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Carroll',
    latitude: 40.4517,
    longitude: -81.212,
    postal_code: '44639'
  },
  '44640': {
    country: 'US',
    city: 'Limaville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.9836,
    longitude: -81.1497,
    postal_code: '44640'
  },
  '44641': {
    country: 'US',
    city: 'Louisville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8477,
    longitude: -81.2595,
    postal_code: '44641'
  },
  '44643': {
    country: 'US',
    city: 'Magnolia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.6514,
    longitude: -81.3076,
    postal_code: '44643'
  },
  '44644': {
    country: 'US',
    city: 'Malvern',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Carroll',
    latitude: 40.6845,
    longitude: -81.1838,
    postal_code: '44644'
  },
  '44645': {
    country: 'US',
    city: 'Marshallville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.9067,
    longitude: -81.7225,
    postal_code: '44645'
  },
  '44646': {
    country: 'US',
    city: 'Massillon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8116,
    longitude: -81.4973,
    postal_code: '44646'
  },
  '44647': {
    country: 'US',
    city: 'Massillon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7959,
    longitude: -81.5533,
    postal_code: '44647'
  },
  '44648': {
    country: 'US',
    city: 'Massillon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8118,
    longitude: -81.3683,
    postal_code: '44648'
  },
  '44650': {
    country: 'US',
    city: 'Maximo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8746,
    longitude: -81.1739,
    postal_code: '44650'
  },
  '44651': {
    country: 'US',
    city: 'Mechanicstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Carroll',
    latitude: 40.6263,
    longitude: -80.956,
    postal_code: '44651'
  },
  '44652': {
    country: 'US',
    city: 'Middlebranch',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8951,
    longitude: -81.3262,
    postal_code: '44652'
  },
  '44653': {
    country: 'US',
    city: 'Midvale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.438,
    longitude: -81.3729,
    postal_code: '44653'
  },
  '44654': {
    country: 'US',
    city: 'Millersburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.5567,
    longitude: -81.8324,
    postal_code: '44654'
  },
  '44656': {
    country: 'US',
    city: 'Mineral City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.5705,
    longitude: -81.3436,
    postal_code: '44656'
  },
  '44657': {
    country: 'US',
    city: 'Minerva',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.742,
    longitude: -81.1031,
    postal_code: '44657'
  },
  '44659': {
    country: 'US',
    city: 'Mount Eaton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.6949,
    longitude: -81.7026,
    postal_code: '44659'
  },
  '44660': {
    country: 'US',
    city: 'Mount Hope',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.6223,
    longitude: -81.7825,
    postal_code: '44660'
  },
  '44661': {
    country: 'US',
    city: 'Nashville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.5956,
    longitude: -82.113,
    postal_code: '44661'
  },
  '44662': {
    country: 'US',
    city: 'Navarre',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7204,
    longitude: -81.5338,
    postal_code: '44662'
  },
  '44663': {
    country: 'US',
    city: 'New Philadelphia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.4845,
    longitude: -81.4358,
    postal_code: '44663'
  },
  '44665': {
    country: 'US',
    city: 'North Georgetown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Columbiana',
    latitude: 40.8436,
    longitude: -80.9794,
    postal_code: '44665'
  },
  '44666': {
    country: 'US',
    city: 'North Lawrence',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8387,
    longitude: -81.6299,
    postal_code: '44666'
  },
  '44667': {
    country: 'US',
    city: 'Orrville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.8458,
    longitude: -81.7741,
    postal_code: '44667'
  },
  '44669': {
    country: 'US',
    city: 'Paris',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8014,
    longitude: -81.154,
    postal_code: '44669'
  },
  '44670': {
    country: 'US',
    city: 'Robertsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7647,
    longitude: -81.1879,
    postal_code: '44670'
  },
  '44671': {
    country: 'US',
    city: 'Sandyville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.6442,
    longitude: -81.3653,
    postal_code: '44671'
  },
  '44672': {
    country: 'US',
    city: 'Sebring',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mahoning',
    latitude: 40.9227,
    longitude: -81.0232,
    postal_code: '44672'
  },
  '44675': {
    country: 'US',
    city: 'Sherrodsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Carroll',
    latitude: 40.5184,
    longitude: -81.2339,
    postal_code: '44675'
  },
  '44676': {
    country: 'US',
    city: 'Shreve',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.6926,
    longitude: -82.0325,
    postal_code: '44676'
  },
  '44677': {
    country: 'US',
    city: 'Smithville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.8592,
    longitude: -81.8633,
    postal_code: '44677'
  },
  '44678': {
    country: 'US',
    city: 'Somerdale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.5649,
    longitude: -81.3524,
    postal_code: '44678'
  },
  '44679': {
    country: 'US',
    city: 'Stillwater',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.3234,
    longitude: -81.3084,
    postal_code: '44679'
  },
  '44680': {
    country: 'US',
    city: 'Strasburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.6003,
    longitude: -81.5366,
    postal_code: '44680'
  },
  '44681': {
    country: 'US',
    city: 'Sugarcreek',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.5148,
    longitude: -81.6604,
    postal_code: '44681'
  },
  '44682': {
    country: 'US',
    city: 'Tuscarawas',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.3959,
    longitude: -81.4069,
    postal_code: '44682'
  },
  '44683': {
    country: 'US',
    city: 'Uhrichsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.3905,
    longitude: -81.3374,
    postal_code: '44683'
  },
  '44685': {
    country: 'US',
    city: 'Uniontown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Summit',
    latitude: 40.9637,
    longitude: -81.4211,
    postal_code: '44685'
  },
  '44687': {
    country: 'US',
    city: 'Walnut Creek',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.5415,
    longitude: -81.7218,
    postal_code: '44687'
  },
  '44688': {
    country: 'US',
    city: 'Waynesburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.6829,
    longitude: -81.2659,
    postal_code: '44688'
  },
  '44689': {
    country: 'US',
    city: 'Wilmot',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.6567,
    longitude: -81.6352,
    postal_code: '44689'
  },
  '44690': {
    country: 'US',
    city: 'Winesburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Holmes',
    latitude: 40.6164,
    longitude: -81.6951,
    postal_code: '44690'
  },
  '44691': {
    country: 'US',
    city: 'Wooster',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wayne',
    latitude: 40.8094,
    longitude: -81.9483,
    postal_code: '44691'
  },
  '44693': {
    country: 'US',
    city: 'Deersville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.2947,
    longitude: -81.1839,
    postal_code: '44693'
  },
  '44695': {
    country: 'US',
    city: 'Bowerston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.4371,
    longitude: -81.1862,
    postal_code: '44695'
  },
  '44697': {
    country: 'US',
    city: 'Zoar',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Tuscarawas',
    latitude: 40.6142,
    longitude: -81.4223,
    postal_code: '44697'
  },
  '44699': {
    country: 'US',
    city: 'Tippecanoe',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Harrison',
    latitude: 40.2797,
    longitude: -81.2919,
    postal_code: '44699'
  },
  '44701': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7824,
    longitude: -81.3712,
    postal_code: '44701'
  },
  '44702': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8027,
    longitude: -81.3739,
    postal_code: '44702'
  },
  '44703': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8098,
    longitude: -81.3814,
    postal_code: '44703'
  },
  '44704': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7991,
    longitude: -81.3537,
    postal_code: '44704'
  },
  '44705': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8259,
    longitude: -81.3399,
    postal_code: '44705'
  },
  '44706': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.768,
    longitude: -81.4119,
    postal_code: '44706'
  },
  '44707': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7598,
    longitude: -81.35,
    postal_code: '44707'
  },
  '44708': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.812,
    longitude: -81.4241,
    postal_code: '44708'
  },
  '44709': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8423,
    longitude: -81.3862,
    postal_code: '44709'
  },
  '44710': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7911,
    longitude: -81.4169,
    postal_code: '44710'
  },
  '44711': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8118,
    longitude: -81.3683,
    postal_code: '44711'
  },
  '44714': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8272,
    longitude: -81.361,
    postal_code: '44714'
  },
  '44718': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8465,
    longitude: -81.4408,
    postal_code: '44718'
  },
  '44720': {
    country: 'US',
    city: 'North Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7989,
    longitude: -81.3784,
    postal_code: '44720'
  },
  '44721': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8834,
    longitude: -81.3328,
    postal_code: '44721'
  },
  '44730': {
    country: 'US',
    city: 'East Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7873,
    longitude: -81.2826,
    postal_code: '44730'
  },
  '44735': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8118,
    longitude: -81.3683,
    postal_code: '44735'
  },
  '44750': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.7846,
    longitude: -81.4189,
    postal_code: '44750'
  },
  '44767': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8957,
    longitude: -81.4246,
    postal_code: '44767'
  },
  '44799': {
    country: 'US',
    city: 'Canton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Stark',
    latitude: 40.8118,
    longitude: -81.3683,
    postal_code: '44799'
  },
  '44802': {
    country: 'US',
    city: 'Alvada',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.0463,
    longitude: -83.377,
    postal_code: '44802'
  },
  '44804': {
    country: 'US',
    city: 'Arcadia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 41.1116,
    longitude: -83.5019,
    postal_code: '44804'
  },
  '44805': {
    country: 'US',
    city: 'Ashland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashland',
    latitude: 40.8559,
    longitude: -82.3189,
    postal_code: '44805'
  },
  '44807': {
    country: 'US',
    city: 'Attica',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.0773,
    longitude: -82.9032,
    postal_code: '44807'
  },
  '44809': {
    country: 'US',
    city: 'Bascom',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.1328,
    longitude: -83.2854,
    postal_code: '44809'
  },
  '44811': {
    country: 'US',
    city: 'Bellevue',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.2684,
    longitude: -82.8577,
    postal_code: '44811'
  },
  '44813': {
    country: 'US',
    city: 'Bellville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.6136,
    longitude: -82.5175,
    postal_code: '44813'
  },
  '44814': {
    country: 'US',
    city: 'Berlin Heights',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Erie',
    latitude: 41.3205,
    longitude: -82.4777,
    postal_code: '44814'
  },
  '44815': {
    country: 'US',
    city: 'Bettsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.2469,
    longitude: -83.2398,
    postal_code: '44815'
  },
  '44816': {
    country: 'US',
    city: 'Birmingham',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Erie',
    latitude: 41.3349,
    longitude: -82.355,
    postal_code: '44816'
  },
  '44817': {
    country: 'US',
    city: 'Bloomdale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.1815,
    longitude: -83.5724,
    postal_code: '44817'
  },
  '44818': {
    country: 'US',
    city: 'Bloomville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.0182,
    longitude: -82.9899,
    postal_code: '44818'
  },
  '44820': {
    country: 'US',
    city: 'Bucyrus',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Crawford',
    latitude: 40.8103,
    longitude: -82.9698,
    postal_code: '44820'
  },
  '44822': {
    country: 'US',
    city: 'Butler',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.5438,
    longitude: -82.399,
    postal_code: '44822'
  },
  '44824': {
    country: 'US',
    city: 'Castalia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Erie',
    latitude: 41.3872,
    longitude: -82.7994,
    postal_code: '44824'
  },
  '44825': {
    country: 'US',
    city: 'Chatfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Crawford',
    latitude: 40.9542,
    longitude: -82.9432,
    postal_code: '44825'
  },
  '44826': {
    country: 'US',
    city: 'Collins',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.245,
    longitude: -82.4904,
    postal_code: '44826'
  },
  '44827': {
    country: 'US',
    city: 'Crestline',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Crawford',
    latitude: 40.7927,
    longitude: -82.7367,
    postal_code: '44827'
  },
  '44828': {
    country: 'US',
    city: 'Flat Rock',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.2371,
    longitude: -82.8597,
    postal_code: '44828'
  },
  '44830': {
    country: 'US',
    city: 'Fostoria',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.1623,
    longitude: -83.4139,
    postal_code: '44830'
  },
  '44833': {
    country: 'US',
    city: 'Galion',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Crawford',
    latitude: 40.7303,
    longitude: -82.7939,
    postal_code: '44833'
  },
  '44836': {
    country: 'US',
    city: 'Green Springs',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.2281,
    longitude: -83.0885,
    postal_code: '44836'
  },
  '44837': {
    country: 'US',
    city: 'Greenwich',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.0407,
    longitude: -82.5133,
    postal_code: '44837'
  },
  '44838': {
    country: 'US',
    city: 'Hayesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashland',
    latitude: 40.7731,
    longitude: -82.2624,
    postal_code: '44838'
  },
  '44839': {
    country: 'US',
    city: 'Huron',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Erie',
    latitude: 41.3757,
    longitude: -82.5386,
    postal_code: '44839'
  },
  '44840': {
    country: 'US',
    city: 'Jeromesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashland',
    latitude: 40.8134,
    longitude: -82.1861,
    postal_code: '44840'
  },
  '44841': {
    country: 'US',
    city: 'Kansas',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.2512,
    longitude: -83.306,
    postal_code: '44841'
  },
  '44842': {
    country: 'US',
    city: 'Loudonville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashland',
    latitude: 40.6361,
    longitude: -82.2356,
    postal_code: '44842'
  },
  '44843': {
    country: 'US',
    city: 'Lucas',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.7039,
    longitude: -82.4087,
    postal_code: '44843'
  },
  '44844': {
    country: 'US',
    city: 'Mc Cutchenville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wyandot',
    latitude: 40.9752,
    longitude: -83.2637,
    postal_code: '44844'
  },
  '44845': {
    country: 'US',
    city: 'Melmore',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.0242,
    longitude: -83.1098,
    postal_code: '44845'
  },
  '44846': {
    country: 'US',
    city: 'Milan',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Erie',
    latitude: 41.3111,
    longitude: -82.6126,
    postal_code: '44846'
  },
  '44847': {
    country: 'US',
    city: 'Monroeville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.2181,
    longitude: -82.7023,
    postal_code: '44847'
  },
  '44848': {
    country: 'US',
    city: 'Nankin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashland',
    latitude: 40.9206,
    longitude: -82.2817,
    postal_code: '44848'
  },
  '44849': {
    country: 'US',
    city: 'Nevada',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wyandot',
    latitude: 40.8259,
    longitude: -83.1266,
    postal_code: '44849'
  },
  '44850': {
    country: 'US',
    city: 'New Haven',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.035,
    longitude: -82.677,
    postal_code: '44850'
  },
  '44851': {
    country: 'US',
    city: 'New London',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.0906,
    longitude: -82.3966,
    postal_code: '44851'
  },
  '44853': {
    country: 'US',
    city: 'New Riegel',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.0514,
    longitude: -83.3185,
    postal_code: '44853'
  },
  '44854': {
    country: 'US',
    city: 'New Washington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Crawford',
    latitude: 40.9571,
    longitude: -82.8504,
    postal_code: '44854'
  },
  '44855': {
    country: 'US',
    city: 'North Fairfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.103,
    longitude: -82.5998,
    postal_code: '44855'
  },
  '44856': {
    country: 'US',
    city: 'North Robinson',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Crawford',
    latitude: 40.7934,
    longitude: -82.8566,
    postal_code: '44856'
  },
  '44857': {
    country: 'US',
    city: 'Norwalk',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.2403,
    longitude: -82.6078,
    postal_code: '44857'
  },
  '44859': {
    country: 'US',
    city: 'Nova',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashland',
    latitude: 41.0282,
    longitude: -82.3384,
    postal_code: '44859'
  },
  '44860': {
    country: 'US',
    city: 'Oceola',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Crawford',
    latitude: 40.8357,
    longitude: -83.1029,
    postal_code: '44860'
  },
  '44861': {
    country: 'US',
    city: 'Old Fort',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.2395,
    longitude: -83.1479,
    postal_code: '44861'
  },
  '44862': {
    country: 'US',
    city: 'Ontario',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.7729,
    longitude: -82.5321,
    postal_code: '44862'
  },
  '44864': {
    country: 'US',
    city: 'Perrysville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashland',
    latitude: 40.6606,
    longitude: -82.3213,
    postal_code: '44864'
  },
  '44865': {
    country: 'US',
    city: 'Plymouth',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 41.0003,
    longitude: -82.6635,
    postal_code: '44865'
  },
  '44866': {
    country: 'US',
    city: 'Polk',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashland',
    latitude: 40.9343,
    longitude: -82.2126,
    postal_code: '44866'
  },
  '44867': {
    country: 'US',
    city: 'Republic',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.1259,
    longitude: -83.0194,
    postal_code: '44867'
  },
  '44870': {
    country: 'US',
    city: 'Sandusky',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Erie',
    latitude: 41.4349,
    longitude: -82.7063,
    postal_code: '44870'
  },
  '44871': {
    country: 'US',
    city: 'Sandusky',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Erie',
    latitude: 41.4489,
    longitude: -82.708,
    postal_code: '44871'
  },
  '44874': {
    country: 'US',
    city: 'Savannah',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashland',
    latitude: 40.9653,
    longitude: -82.3652,
    postal_code: '44874'
  },
  '44875': {
    country: 'US',
    city: 'Shelby',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.8784,
    longitude: -82.6549,
    postal_code: '44875'
  },
  '44878': {
    country: 'US',
    city: 'Shiloh',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.934,
    longitude: -82.5222,
    postal_code: '44878'
  },
  '44880': {
    country: 'US',
    city: 'Sullivan',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ashland',
    latitude: 41.0368,
    longitude: -82.2172,
    postal_code: '44880'
  },
  '44881': {
    country: 'US',
    city: 'Sulphur Springs',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Crawford',
    latitude: 40.8709,
    longitude: -82.8771,
    postal_code: '44881'
  },
  '44882': {
    country: 'US',
    city: 'Sycamore',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wyandot',
    latitude: 40.9413,
    longitude: -83.1492,
    postal_code: '44882'
  },
  '44883': {
    country: 'US',
    city: 'Tiffin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Seneca',
    latitude: 41.1238,
    longitude: -83.1844,
    postal_code: '44883'
  },
  '44887': {
    country: 'US',
    city: 'Tiro',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Crawford',
    latitude: 40.881,
    longitude: -82.797,
    postal_code: '44887'
  },
  '44888': {
    country: 'US',
    city: 'Willard',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.0531,
    longitude: -82.7263,
    postal_code: '44888'
  },
  '44889': {
    country: 'US',
    city: 'Wakeman',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.2637,
    longitude: -82.3782,
    postal_code: '44889'
  },
  '44890': {
    country: 'US',
    city: 'Willard',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Huron',
    latitude: 41.0628,
    longitude: -82.7288,
    postal_code: '44890'
  },
  '44901': {
    country: 'US',
    city: 'Mansfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.8508,
    longitude: -82.5114,
    postal_code: '44901'
  },
  '44902': {
    country: 'US',
    city: 'Mansfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.7559,
    longitude: -82.5123,
    postal_code: '44902'
  },
  '44903': {
    country: 'US',
    city: 'Mansfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.7623,
    longitude: -82.5254,
    postal_code: '44903'
  },
  '44904': {
    country: 'US',
    city: 'Mansfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.6824,
    longitude: -82.5286,
    postal_code: '44904'
  },
  '44905': {
    country: 'US',
    city: 'Mansfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.7779,
    longitude: -82.4613,
    postal_code: '44905'
  },
  '44906': {
    country: 'US',
    city: 'Mansfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.7627,
    longitude: -82.5593,
    postal_code: '44906'
  },
  '44907': {
    country: 'US',
    city: 'Mansfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Richland',
    latitude: 40.7345,
    longitude: -82.5198,
    postal_code: '44907'
  },
  '45001': {
    country: 'US',
    city: 'Addyston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1374,
    longitude: -84.7096,
    postal_code: '45001'
  },
  '45002': {
    country: 'US',
    city: 'Cleves',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1937,
    longitude: -84.734,
    postal_code: '45002'
  },
  '45003': {
    country: 'US',
    city: 'College Corner',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.5755,
    longitude: -84.805,
    postal_code: '45003'
  },
  '45004': {
    country: 'US',
    city: 'Collinsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.5153,
    longitude: -84.6094,
    postal_code: '45004'
  },
  '45005': {
    country: 'US',
    city: 'Franklin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.5357,
    longitude: -84.303,
    postal_code: '45005'
  },
  '45011': {
    country: 'US',
    city: 'Hamilton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.4059,
    longitude: -84.5221,
    postal_code: '45011'
  },
  '45012': {
    country: 'US',
    city: 'Hamilton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.441,
    longitude: -84.5757,
    postal_code: '45012'
  },
  '45013': {
    country: 'US',
    city: 'Hamilton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.4361,
    longitude: -84.6185,
    postal_code: '45013'
  },
  '45014': {
    country: 'US',
    city: 'Fairfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.3266,
    longitude: -84.5479,
    postal_code: '45014'
  },
  '45015': {
    country: 'US',
    city: 'Hamilton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.3494,
    longitude: -84.5376,
    postal_code: '45015'
  },
  '45018': {
    country: 'US',
    city: 'Fairfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.441,
    longitude: -84.5757,
    postal_code: '45018'
  },
  '45030': {
    country: 'US',
    city: 'Harrison',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2592,
    longitude: -84.7837,
    postal_code: '45030'
  },
  '45032': {
    country: 'US',
    city: 'Harveysburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.5013,
    longitude: -84.0067,
    postal_code: '45032'
  },
  '45033': {
    country: 'US',
    city: 'Hooven',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1773,
    longitude: -84.7627,
    postal_code: '45033'
  },
  '45034': {
    country: 'US',
    city: 'Kings Mills',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.358,
    longitude: -84.2473,
    postal_code: '45034'
  },
  '45036': {
    country: 'US',
    city: 'Lebanon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.4293,
    longitude: -84.1735,
    postal_code: '45036'
  },
  '45039': {
    country: 'US',
    city: 'Maineville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.317,
    longitude: -84.2438,
    postal_code: '45039'
  },
  '45040': {
    country: 'US',
    city: 'Mason',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.3357,
    longitude: -84.3149,
    postal_code: '45040'
  },
  '45041': {
    country: 'US',
    city: 'Miamitown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2159,
    longitude: -84.7041,
    postal_code: '45041'
  },
  '45042': {
    country: 'US',
    city: 'Middletown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.5321,
    longitude: -84.3896,
    postal_code: '45042'
  },
  '45044': {
    country: 'US',
    city: 'Middletown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.5151,
    longitude: -84.3983,
    postal_code: '45044'
  },
  '45050': {
    country: 'US',
    city: 'Monroe',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.4413,
    longitude: -84.3652,
    postal_code: '45050'
  },
  '45051': {
    country: 'US',
    city: 'Mount Saint Joseph',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.0965,
    longitude: -84.6431,
    postal_code: '45051'
  },
  '45052': {
    country: 'US',
    city: 'North Bend',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1536,
    longitude: -84.7273,
    postal_code: '45052'
  },
  '45053': {
    country: 'US',
    city: 'Okeana',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.3537,
    longitude: -84.7761,
    postal_code: '45053'
  },
  '45054': {
    country: 'US',
    city: 'Oregonia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.4145,
    longitude: -84.0511,
    postal_code: '45054'
  },
  '45055': {
    country: 'US',
    city: 'Overpeck',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.4518,
    longitude: -84.5152,
    postal_code: '45055'
  },
  '45056': {
    country: 'US',
    city: 'Oxford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.507,
    longitude: -84.7452,
    postal_code: '45056'
  },
  '45061': {
    country: 'US',
    city: 'Ross',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.3124,
    longitude: -84.6483,
    postal_code: '45061'
  },
  '45062': {
    country: 'US',
    city: 'Seven Mile',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.479,
    longitude: -84.5512,
    postal_code: '45062'
  },
  '45063': {
    country: 'US',
    city: 'Shandon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.3258,
    longitude: -84.7121,
    postal_code: '45063'
  },
  '45064': {
    country: 'US',
    city: 'Somerville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.5554,
    longitude: -84.6219,
    postal_code: '45064'
  },
  '45065': {
    country: 'US',
    city: 'South Lebanon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.3715,
    longitude: -84.2108,
    postal_code: '45065'
  },
  '45066': {
    country: 'US',
    city: 'Springboro',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.563,
    longitude: -84.2288,
    postal_code: '45066'
  },
  '45067': {
    country: 'US',
    city: 'Trenton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.4799,
    longitude: -84.4598,
    postal_code: '45067'
  },
  '45068': {
    country: 'US',
    city: 'Waynesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.5285,
    longitude: -84.0815,
    postal_code: '45068'
  },
  '45069': {
    country: 'US',
    city: 'West Chester',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.3402,
    longitude: -84.3998,
    postal_code: '45069'
  },
  '45070': {
    country: 'US',
    city: 'West Elkton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Preble',
    latitude: 39.5897,
    longitude: -84.5581,
    postal_code: '45070'
  },
  '45071': {
    country: 'US',
    city: 'West Chester',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Butler',
    latitude: 39.3317,
    longitude: -84.4072,
    postal_code: '45071'
  },
  '45101': {
    country: 'US',
    city: 'Aberdeen',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 38.6709,
    longitude: -83.7637,
    postal_code: '45101'
  },
  '45102': {
    country: 'US',
    city: 'Amelia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 39.0211,
    longitude: -84.2112,
    postal_code: '45102'
  },
  '45103': {
    country: 'US',
    city: 'Batavia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 39.0957,
    longitude: -84.1451,
    postal_code: '45103'
  },
  '45105': {
    country: 'US',
    city: 'Bentonville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Adams',
    latitude: 38.7498,
    longitude: -83.6126,
    postal_code: '45105'
  },
  '45106': {
    country: 'US',
    city: 'Bethel',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 38.9424,
    longitude: -84.0919,
    postal_code: '45106'
  },
  '45107': {
    country: 'US',
    city: 'Blanchester',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clinton',
    latitude: 39.3034,
    longitude: -83.974,
    postal_code: '45107'
  },
  '45111': {
    country: 'US',
    city: 'Camp Dennison',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1962,
    longitude: -84.2897,
    postal_code: '45111'
  },
  '45112': {
    country: 'US',
    city: 'Chilo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 38.7926,
    longitude: -84.1382,
    postal_code: '45112'
  },
  '45113': {
    country: 'US',
    city: 'Clarksville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clinton',
    latitude: 39.4042,
    longitude: -83.9594,
    postal_code: '45113'
  },
  '45114': {
    country: 'US',
    city: 'Cuba',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clinton',
    latitude: 39.3568,
    longitude: -83.8572,
    postal_code: '45114'
  },
  '45115': {
    country: 'US',
    city: 'Decatur',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 38.8237,
    longitude: -83.6995,
    postal_code: '45115'
  },
  '45118': {
    country: 'US',
    city: 'Fayetteville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 39.1862,
    longitude: -83.9501,
    postal_code: '45118'
  },
  '45119': {
    country: 'US',
    city: 'Feesburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 38.8806,
    longitude: -84.0087,
    postal_code: '45119'
  },
  '45120': {
    country: 'US',
    city: 'Felicity',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 38.8262,
    longitude: -84.0986,
    postal_code: '45120'
  },
  '45121': {
    country: 'US',
    city: 'Georgetown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 38.8717,
    longitude: -83.9092,
    postal_code: '45121'
  },
  '45122': {
    country: 'US',
    city: 'Goshen',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 39.2209,
    longitude: -84.1188,
    postal_code: '45122'
  },
  '45123': {
    country: 'US',
    city: 'Greenfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Highland',
    latitude: 39.3478,
    longitude: -83.3898,
    postal_code: '45123'
  },
  '45130': {
    country: 'US',
    city: 'Hamersville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 38.92,
    longitude: -83.9931,
    postal_code: '45130'
  },
  '45131': {
    country: 'US',
    city: 'Higginsport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 38.7903,
    longitude: -83.9666,
    postal_code: '45131'
  },
  '45132': {
    country: 'US',
    city: 'Highland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Highland',
    latitude: 39.3445,
    longitude: -83.5974,
    postal_code: '45132'
  },
  '45133': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Highland',
    latitude: 39.1679,
    longitude: -83.6064,
    postal_code: '45133'
  },
  '45135': {
    country: 'US',
    city: 'Leesburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Highland',
    latitude: 39.3458,
    longitude: -83.5481,
    postal_code: '45135'
  },
  '45140': {
    country: 'US',
    city: 'Loveland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 39.2445,
    longitude: -84.2588,
    postal_code: '45140'
  },
  '45142': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Highland',
    latitude: 39.2119,
    longitude: -83.8021,
    postal_code: '45142'
  },
  '45144': {
    country: 'US',
    city: 'Manchester',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Adams',
    latitude: 38.6982,
    longitude: -83.6181,
    postal_code: '45144'
  },
  '45146': {
    country: 'US',
    city: 'Martinsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clinton',
    latitude: 39.3127,
    longitude: -83.8005,
    postal_code: '45146'
  },
  '45147': {
    country: 'US',
    city: 'Miamiville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 39.2137,
    longitude: -84.3002,
    postal_code: '45147'
  },
  '45148': {
    country: 'US',
    city: 'Midland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clinton',
    latitude: 39.2917,
    longitude: -83.8931,
    postal_code: '45148'
  },
  '45150': {
    country: 'US',
    city: 'Milford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 39.1657,
    longitude: -84.233,
    postal_code: '45150'
  },
  '45152': {
    country: 'US',
    city: 'Morrow',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.3476,
    longitude: -84.1181,
    postal_code: '45152'
  },
  '45153': {
    country: 'US',
    city: 'Moscow',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 38.8583,
    longitude: -84.1958,
    postal_code: '45153'
  },
  '45154': {
    country: 'US',
    city: 'Mount Orab',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 39.0454,
    longitude: -83.948,
    postal_code: '45154'
  },
  '45155': {
    country: 'US',
    city: 'Mowrystown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Highland',
    latitude: 39.0388,
    longitude: -83.7505,
    postal_code: '45155'
  },
  '45156': {
    country: 'US',
    city: 'Neville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 38.8101,
    longitude: -84.2122,
    postal_code: '45156'
  },
  '45157': {
    country: 'US',
    city: 'New Richmond',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 38.9537,
    longitude: -84.2379,
    postal_code: '45157'
  },
  '45158': {
    country: 'US',
    city: 'Newtonsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 39.182,
    longitude: -84.0863,
    postal_code: '45158'
  },
  '45159': {
    country: 'US',
    city: 'New Vienna',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clinton',
    latitude: 39.3321,
    longitude: -83.6882,
    postal_code: '45159'
  },
  '45160': {
    country: 'US',
    city: 'Owensville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 39.1232,
    longitude: -84.135,
    postal_code: '45160'
  },
  '45162': {
    country: 'US',
    city: 'Pleasant Plain',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Warren',
    latitude: 39.2884,
    longitude: -84.0967,
    postal_code: '45162'
  },
  '45164': {
    country: 'US',
    city: 'Port William',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clinton',
    latitude: 39.5514,
    longitude: -83.7881,
    postal_code: '45164'
  },
  '45166': {
    country: 'US',
    city: 'Reesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clinton',
    latitude: 39.4805,
    longitude: -83.6772,
    postal_code: '45166'
  },
  '45167': {
    country: 'US',
    city: 'Ripley',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 38.7551,
    longitude: -83.8227,
    postal_code: '45167'
  },
  '45168': {
    country: 'US',
    city: 'Russellville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 38.8511,
    longitude: -83.7625,
    postal_code: '45168'
  },
  '45169': {
    country: 'US',
    city: 'Sabina',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clinton',
    latitude: 39.49,
    longitude: -83.6503,
    postal_code: '45169'
  },
  '45171': {
    country: 'US',
    city: 'Sardinia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 38.9832,
    longitude: -83.7966,
    postal_code: '45171'
  },
  '45172': {
    country: 'US',
    city: 'Sinking Spring',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Highland',
    latitude: 39.0738,
    longitude: -83.3853,
    postal_code: '45172'
  },
  '45174': {
    country: 'US',
    city: 'Terrace Park',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1602,
    longitude: -84.3098,
    postal_code: '45174'
  },
  '45176': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 39.0753,
    longitude: -84.0432,
    postal_code: '45176'
  },
  '45177': {
    country: 'US',
    city: 'Wilmington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clinton',
    latitude: 39.4488,
    longitude: -83.8417,
    postal_code: '45177'
  },
  '45201': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45201'
  },
  '45202': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1072,
    longitude: -84.502,
    postal_code: '45202'
  },
  '45203': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1075,
    longitude: -84.5257,
    postal_code: '45203'
  },
  '45204': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.0963,
    longitude: -84.5719,
    postal_code: '45204'
  },
  '45205': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1104,
    longitude: -84.5757,
    postal_code: '45205'
  },
  '45206': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1269,
    longitude: -84.4853,
    postal_code: '45206'
  },
  '45207': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1397,
    longitude: -84.4706,
    postal_code: '45207'
  },
  '45208': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1361,
    longitude: -84.4355,
    postal_code: '45208'
  },
  '45209': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1516,
    longitude: -84.4278,
    postal_code: '45209'
  },
  '45211': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1524,
    longitude: -84.5967,
    postal_code: '45211'
  },
  '45212': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1642,
    longitude: -84.4522,
    postal_code: '45212'
  },
  '45213': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1802,
    longitude: -84.4204,
    postal_code: '45213'
  },
  '45214': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1219,
    longitude: -84.5506,
    postal_code: '45214'
  },
  '45215': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2353,
    longitude: -84.4619,
    postal_code: '45215'
  },
  '45216': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2003,
    longitude: -84.4859,
    postal_code: '45216'
  },
  '45217': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1661,
    longitude: -84.4959,
    postal_code: '45217'
  },
  '45218': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2663,
    longitude: -84.5221,
    postal_code: '45218'
  },
  '45219': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.127,
    longitude: -84.5131,
    postal_code: '45219'
  },
  '45220': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1432,
    longitude: -84.5217,
    postal_code: '45220'
  },
  '45221': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45221'
  },
  '45222': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45222'
  },
  '45223': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1696,
    longitude: -84.5478,
    postal_code: '45223'
  },
  '45224': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1991,
    longitude: -84.5251,
    postal_code: '45224'
  },
  '45225': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1447,
    longitude: -84.5533,
    postal_code: '45225'
  },
  '45226': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1174,
    longitude: -84.4312,
    postal_code: '45226'
  },
  '45227': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1539,
    longitude: -84.3854,
    postal_code: '45227'
  },
  '45229': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.149,
    longitude: -84.4892,
    postal_code: '45229'
  },
  '45230': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.0713,
    longitude: -84.3758,
    postal_code: '45230'
  },
  '45231': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2418,
    longitude: -84.5437,
    postal_code: '45231'
  },
  '45232': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1859,
    longitude: -84.5141,
    postal_code: '45232'
  },
  '45233': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.111,
    longitude: -84.6594,
    postal_code: '45233'
  },
  '45234': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45234'
  },
  '45235': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45235'
  },
  '45236': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.21,
    longitude: -84.395,
    postal_code: '45236'
  },
  '45237': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.188,
    longitude: -84.458,
    postal_code: '45237'
  },
  '45238': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1092,
    longitude: -84.6108,
    postal_code: '45238'
  },
  '45239': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2045,
    longitude: -84.5799,
    postal_code: '45239'
  },
  '45240': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2851,
    longitude: -84.5288,
    postal_code: '45240'
  },
  '45241': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2638,
    longitude: -84.4092,
    postal_code: '45241'
  },
  '45242': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2447,
    longitude: -84.3455,
    postal_code: '45242'
  },
  '45243': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1848,
    longitude: -84.3448,
    postal_code: '45243'
  },
  '45244': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1191,
    longitude: -84.351,
    postal_code: '45244'
  },
  '45245': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clermont',
    latitude: 39.0688,
    longitude: -84.2802,
    postal_code: '45245'
  },
  '45246': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2839,
    longitude: -84.4744,
    postal_code: '45246'
  },
  '45247': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2208,
    longitude: -84.6418,
    postal_code: '45247'
  },
  '45248': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1652,
    longitude: -84.6625,
    postal_code: '45248'
  },
  '45249': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2692,
    longitude: -84.3307,
    postal_code: '45249'
  },
  '45250': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45250'
  },
  '45251': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2672,
    longitude: -84.5993,
    postal_code: '45251'
  },
  '45252': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2668,
    longitude: -84.6283,
    postal_code: '45252'
  },
  '45253': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45253'
  },
  '45254': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45254'
  },
  '45255': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.0584,
    longitude: -84.3396,
    postal_code: '45255'
  },
  '45258': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45258'
  },
  '45262': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45262'
  },
  '45263': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45263'
  },
  '45264': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45264'
  },
  '45267': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45267'
  },
  '45268': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45268'
  },
  '45269': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45269'
  },
  '45270': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45270'
  },
  '45271': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45271'
  },
  '45273': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45273'
  },
  '45274': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45274'
  },
  '45275': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Brown',
    latitude: 39.162,
    longitude: -84.4569,
    postal_code: '45275'
  },
  '45277': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45277'
  },
  '45280': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.162,
    longitude: -84.4569,
    postal_code: '45280'
  },
  '45296': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45296'
  },
  '45298': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45298'
  },
  '45299': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.2622,
    longitude: -84.5093,
    postal_code: '45299'
  },
  '45301': {
    country: 'US',
    city: 'Alpha',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.7117,
    longitude: -84.0233,
    postal_code: '45301'
  },
  '45302': {
    country: 'US',
    city: 'Anna',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.4051,
    longitude: -84.2103,
    postal_code: '45302'
  },
  '45303': {
    country: 'US',
    city: 'Ansonia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.2151,
    longitude: -84.6406,
    postal_code: '45303'
  },
  '45304': {
    country: 'US',
    city: 'Arcanum',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 39.9888,
    longitude: -84.5312,
    postal_code: '45304'
  },
  '45305': {
    country: 'US',
    city: 'Bellbrook',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.6402,
    longitude: -84.0824,
    postal_code: '45305'
  },
  '45306': {
    country: 'US',
    city: 'Botkins',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.4659,
    longitude: -84.178,
    postal_code: '45306'
  },
  '45307': {
    country: 'US',
    city: 'Bowersville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.5806,
    longitude: -83.7249,
    postal_code: '45307'
  },
  '45308': {
    country: 'US',
    city: 'Bradford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 40.1286,
    longitude: -84.4293,
    postal_code: '45308'
  },
  '45309': {
    country: 'US',
    city: 'Brookville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.8414,
    longitude: -84.4165,
    postal_code: '45309'
  },
  '45310': {
    country: 'US',
    city: 'Burkettsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mercer',
    latitude: 40.3541,
    longitude: -84.6435,
    postal_code: '45310'
  },
  '45311': {
    country: 'US',
    city: 'Camden',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Preble',
    latitude: 39.6134,
    longitude: -84.61,
    postal_code: '45311'
  },
  '45312': {
    country: 'US',
    city: 'Casstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 40.0716,
    longitude: -84.1088,
    postal_code: '45312'
  },
  '45314': {
    country: 'US',
    city: 'Cedarville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.7484,
    longitude: -83.8013,
    postal_code: '45314'
  },
  '45315': {
    country: 'US',
    city: 'Clayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.8551,
    longitude: -84.3399,
    postal_code: '45315'
  },
  '45316': {
    country: 'US',
    city: 'Clifton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.797,
    longitude: -83.8256,
    postal_code: '45316'
  },
  '45317': {
    country: 'US',
    city: 'Conover',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 40.1457,
    longitude: -84.0283,
    postal_code: '45317'
  },
  '45318': {
    country: 'US',
    city: 'Covington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 40.1176,
    longitude: -84.3496,
    postal_code: '45318'
  },
  '45319': {
    country: 'US',
    city: 'Donnelsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.9189,
    longitude: -83.9449,
    postal_code: '45319'
  },
  '45320': {
    country: 'US',
    city: 'Eaton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Preble',
    latitude: 39.7426,
    longitude: -84.6508,
    postal_code: '45320'
  },
  '45321': {
    country: 'US',
    city: 'Eldorado',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Preble',
    latitude: 39.8882,
    longitude: -84.6786,
    postal_code: '45321'
  },
  '45322': {
    country: 'US',
    city: 'Englewood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.877,
    longitude: -84.3319,
    postal_code: '45322'
  },
  '45323': {
    country: 'US',
    city: 'Enon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.8663,
    longitude: -83.9385,
    postal_code: '45323'
  },
  '45324': {
    country: 'US',
    city: 'Fairborn',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.8053,
    longitude: -84.0198,
    postal_code: '45324'
  },
  '45325': {
    country: 'US',
    city: 'Farmersville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.6867,
    longitude: -84.4205,
    postal_code: '45325'
  },
  '45326': {
    country: 'US',
    city: 'Fletcher',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 40.1445,
    longitude: -84.101,
    postal_code: '45326'
  },
  '45327': {
    country: 'US',
    city: 'Germantown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.6244,
    longitude: -84.3764,
    postal_code: '45327'
  },
  '45328': {
    country: 'US',
    city: 'Gettysburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.1147,
    longitude: -84.4934,
    postal_code: '45328'
  },
  '45330': {
    country: 'US',
    city: 'Gratis',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Preble',
    latitude: 39.6483,
    longitude: -84.5282,
    postal_code: '45330'
  },
  '45331': {
    country: 'US',
    city: 'Greenville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.0987,
    longitude: -84.6342,
    postal_code: '45331'
  },
  '45332': {
    country: 'US',
    city: 'Hollansburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 39.9899,
    longitude: -84.7901,
    postal_code: '45332'
  },
  '45333': {
    country: 'US',
    city: 'Houston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.2535,
    longitude: -84.3521,
    postal_code: '45333'
  },
  '45334': {
    country: 'US',
    city: 'Jackson Center',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.4358,
    longitude: -84.0457,
    postal_code: '45334'
  },
  '45335': {
    country: 'US',
    city: 'Jamestown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.6428,
    longitude: -83.7504,
    postal_code: '45335'
  },
  '45336': {
    country: 'US',
    city: 'Kettlersville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.4413,
    longitude: -84.2626,
    postal_code: '45336'
  },
  '45337': {
    country: 'US',
    city: 'Laura',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 39.9785,
    longitude: -84.3994,
    postal_code: '45337'
  },
  '45338': {
    country: 'US',
    city: 'Lewisburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Preble',
    latitude: 39.8534,
    longitude: -84.5369,
    postal_code: '45338'
  },
  '45339': {
    country: 'US',
    city: 'Ludlow Falls',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 39.9871,
    longitude: -84.3334,
    postal_code: '45339'
  },
  '45340': {
    country: 'US',
    city: 'Maplewood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.3643,
    longitude: -84.0565,
    postal_code: '45340'
  },
  '45341': {
    country: 'US',
    city: 'Medway',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.8789,
    longitude: -84.0218,
    postal_code: '45341'
  },
  '45342': {
    country: 'US',
    city: 'Miamisburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.6321,
    longitude: -84.2675,
    postal_code: '45342'
  },
  '45343': {
    country: 'US',
    city: 'Miamisburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45343'
  },
  '45344': {
    country: 'US',
    city: 'New Carlisle',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.93,
    longitude: -84.0217,
    postal_code: '45344'
  },
  '45345': {
    country: 'US',
    city: 'New Lebanon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7398,
    longitude: -84.3956,
    postal_code: '45345'
  },
  '45346': {
    country: 'US',
    city: 'New Madison',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 39.9687,
    longitude: -84.7222,
    postal_code: '45346'
  },
  '45347': {
    country: 'US',
    city: 'New Paris',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Preble',
    latitude: 39.8617,
    longitude: -84.7793,
    postal_code: '45347'
  },
  '45348': {
    country: 'US',
    city: 'New Weston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.3349,
    longitude: -84.6308,
    postal_code: '45348'
  },
  '45349': {
    country: 'US',
    city: 'North Hampton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.9893,
    longitude: -83.9389,
    postal_code: '45349'
  },
  '45350': {
    country: 'US',
    city: 'North Star',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.3243,
    longitude: -84.5737,
    postal_code: '45350'
  },
  '45351': {
    country: 'US',
    city: 'Osgood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.3406,
    longitude: -84.4963,
    postal_code: '45351'
  },
  '45352': {
    country: 'US',
    city: 'Palestine',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.0503,
    longitude: -84.7457,
    postal_code: '45352'
  },
  '45353': {
    country: 'US',
    city: 'Pemberton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.2952,
    longitude: -84.0323,
    postal_code: '45353'
  },
  '45354': {
    country: 'US',
    city: 'Phillipsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.9054,
    longitude: -84.4028,
    postal_code: '45354'
  },
  '45356': {
    country: 'US',
    city: 'Piqua',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 40.1486,
    longitude: -84.2531,
    postal_code: '45356'
  },
  '45358': {
    country: 'US',
    city: 'Pitsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 39.987,
    longitude: -84.4866,
    postal_code: '45358'
  },
  '45359': {
    country: 'US',
    city: 'Pleasant Hill',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 40.0531,
    longitude: -84.3436,
    postal_code: '45359'
  },
  '45360': {
    country: 'US',
    city: 'Port Jefferson',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.3307,
    longitude: -84.0926,
    postal_code: '45360'
  },
  '45361': {
    country: 'US',
    city: 'Potsdam',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 39.9635,
    longitude: -84.4145,
    postal_code: '45361'
  },
  '45362': {
    country: 'US',
    city: 'Rossburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.2946,
    longitude: -84.6264,
    postal_code: '45362'
  },
  '45363': {
    country: 'US',
    city: 'Russia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.2341,
    longitude: -84.4123,
    postal_code: '45363'
  },
  '45365': {
    country: 'US',
    city: 'Sidney',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.2874,
    longitude: -84.1622,
    postal_code: '45365'
  },
  '45367': {
    country: 'US',
    city: 'Sidney',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.2842,
    longitude: -84.1555,
    postal_code: '45367'
  },
  '45368': {
    country: 'US',
    city: 'South Charleston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.8543,
    longitude: -83.6653,
    postal_code: '45368'
  },
  '45369': {
    country: 'US',
    city: 'South Vienna',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.9473,
    longitude: -83.6157,
    postal_code: '45369'
  },
  '45370': {
    country: 'US',
    city: 'Spring Valley',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.6083,
    longitude: -84.026,
    postal_code: '45370'
  },
  '45371': {
    country: 'US',
    city: 'Tipp City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 39.942,
    longitude: -84.1663,
    postal_code: '45371'
  },
  '45372': {
    country: 'US',
    city: 'Tremont City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 40.0139,
    longitude: -83.8333,
    postal_code: '45372'
  },
  '45373': {
    country: 'US',
    city: 'Troy',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 40.0374,
    longitude: -84.2032,
    postal_code: '45373'
  },
  '45374': {
    country: 'US',
    city: 'Troy',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 40.04,
    longitude: -84.2298,
    postal_code: '45374'
  },
  '45377': {
    country: 'US',
    city: 'Vandalia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.8883,
    longitude: -84.2023,
    postal_code: '45377'
  },
  '45378': {
    country: 'US',
    city: 'Verona',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Preble',
    latitude: 39.9029,
    longitude: -84.4887,
    postal_code: '45378'
  },
  '45380': {
    country: 'US',
    city: 'Versailles',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.2273,
    longitude: -84.4957,
    postal_code: '45380'
  },
  '45381': {
    country: 'US',
    city: 'West Alexandria',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Preble',
    latitude: 39.7259,
    longitude: -84.5352,
    postal_code: '45381'
  },
  '45382': {
    country: 'US',
    city: 'West Manchester',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Preble',
    latitude: 39.9026,
    longitude: -84.6194,
    postal_code: '45382'
  },
  '45383': {
    country: 'US',
    city: 'West Milton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Miami',
    latitude: 39.9531,
    longitude: -84.3242,
    postal_code: '45383'
  },
  '45384': {
    country: 'US',
    city: 'Wilberforce',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.7128,
    longitude: -83.8781,
    postal_code: '45384'
  },
  '45385': {
    country: 'US',
    city: 'Xenia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.6842,
    longitude: -83.9369,
    postal_code: '45385'
  },
  '45387': {
    country: 'US',
    city: 'Yellow Springs',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.7996,
    longitude: -83.8891,
    postal_code: '45387'
  },
  '45388': {
    country: 'US',
    city: 'Yorkshire',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.3283,
    longitude: -84.4836,
    postal_code: '45388'
  },
  '45389': {
    country: 'US',
    city: 'Christiansburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Champaign',
    latitude: 40.0564,
    longitude: -84.0254,
    postal_code: '45389'
  },
  '45390': {
    country: 'US',
    city: 'Union City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Darke',
    latitude: 40.2018,
    longitude: -84.7832,
    postal_code: '45390'
  },
  '45401': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45401'
  },
  '45402': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7563,
    longitude: -84.1895,
    postal_code: '45402'
  },
  '45403': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7617,
    longitude: -84.1498,
    postal_code: '45403'
  },
  '45404': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7862,
    longitude: -84.1622,
    postal_code: '45404'
  },
  '45405': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7899,
    longitude: -84.2135,
    postal_code: '45405'
  },
  '45406': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7821,
    longitude: -84.2373,
    postal_code: '45406'
  },
  '45409': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7238,
    longitude: -84.1854,
    postal_code: '45409'
  },
  '45410': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7474,
    longitude: -84.16,
    postal_code: '45410'
  },
  '45412': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7589,
    longitude: -84.1916,
    postal_code: '45412'
  },
  '45413': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45413'
  },
  '45414': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.8285,
    longitude: -84.2024,
    postal_code: '45414'
  },
  '45415': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.8355,
    longitude: -84.2613,
    postal_code: '45415'
  },
  '45416': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.8011,
    longitude: -84.2578,
    postal_code: '45416'
  },
  '45417': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7528,
    longitude: -84.247,
    postal_code: '45417'
  },
  '45419': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7155,
    longitude: -84.1637,
    postal_code: '45419'
  },
  '45420': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7171,
    longitude: -84.1342,
    postal_code: '45420'
  },
  '45422': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7581,
    longitude: -84.2001,
    postal_code: '45422'
  },
  '45423': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7589,
    longitude: -84.1916,
    postal_code: '45423'
  },
  '45424': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.8353,
    longitude: -84.1123,
    postal_code: '45424'
  },
  '45426': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7982,
    longitude: -84.3211,
    postal_code: '45426'
  },
  '45428': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7467,
    longitude: -84.2593,
    postal_code: '45428'
  },
  '45429': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.6841,
    longitude: -84.1633,
    postal_code: '45429'
  },
  '45430': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7092,
    longitude: -84.1048,
    postal_code: '45430'
  },
  '45431': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.7574,
    longitude: -84.0569,
    postal_code: '45431'
  },
  '45432': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.739,
    longitude: -84.0856,
    postal_code: '45432'
  },
  '45433': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Greene',
    latitude: 39.8138,
    longitude: -84.059,
    postal_code: '45433'
  },
  '45434': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7216,
    longitude: -84.029,
    postal_code: '45434'
  },
  '45435': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45435'
  },
  '45437': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45437'
  },
  '45439': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.701,
    longitude: -84.2187,
    postal_code: '45439'
  },
  '45440': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.6749,
    longitude: -84.1136,
    postal_code: '45440'
  },
  '45441': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45441'
  },
  '45448': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45448'
  },
  '45449': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.6651,
    longitude: -84.2401,
    postal_code: '45449'
  },
  '45458': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.6062,
    longitude: -84.1695,
    postal_code: '45458'
  },
  '45459': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.6464,
    longitude: -84.1717,
    postal_code: '45459'
  },
  '45469': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7405,
    longitude: -84.1789,
    postal_code: '45469'
  },
  '45470': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45470'
  },
  '45475': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45475'
  },
  '45479': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7344,
    longitude: -84.1944,
    postal_code: '45479'
  },
  '45481': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45481'
  },
  '45482': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45482'
  },
  '45490': {
    country: 'US',
    city: 'Dayton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Montgomery',
    latitude: 39.7505,
    longitude: -84.2686,
    postal_code: '45490'
  },
  '45501': {
    country: 'US',
    city: 'Springfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.9271,
    longitude: -83.8132,
    postal_code: '45501'
  },
  '45502': {
    country: 'US',
    city: 'Springfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.9242,
    longitude: -83.8088,
    postal_code: '45502'
  },
  '45503': {
    country: 'US',
    city: 'Springfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.9528,
    longitude: -83.7804,
    postal_code: '45503'
  },
  '45504': {
    country: 'US',
    city: 'Springfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.9408,
    longitude: -83.8343,
    postal_code: '45504'
  },
  '45505': {
    country: 'US',
    city: 'Springfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.9106,
    longitude: -83.7856,
    postal_code: '45505'
  },
  '45506': {
    country: 'US',
    city: 'Springfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Clark',
    latitude: 39.9104,
    longitude: -83.8275,
    postal_code: '45506'
  },
  '45601': {
    country: 'US',
    city: 'Chillicothe',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.338,
    longitude: -82.9895,
    postal_code: '45601'
  },
  '45612': {
    country: 'US',
    city: 'Bainbridge',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.2131,
    longitude: -83.2763,
    postal_code: '45612'
  },
  '45613': {
    country: 'US',
    city: 'Beaver',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pike',
    latitude: 39.0332,
    longitude: -82.8514,
    postal_code: '45613'
  },
  '45614': {
    country: 'US',
    city: 'Bidwell',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Gallia',
    latitude: 38.9276,
    longitude: -82.2701,
    postal_code: '45614'
  },
  '45616': {
    country: 'US',
    city: 'Blue Creek',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Adams',
    latitude: 38.7776,
    longitude: -83.3302,
    postal_code: '45616'
  },
  '45617': {
    country: 'US',
    city: 'Bourneville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.2804,
    longitude: -83.159,
    postal_code: '45617'
  },
  '45618': {
    country: 'US',
    city: 'Cherry Fork',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Adams',
    latitude: 38.8922,
    longitude: -83.6218,
    postal_code: '45618'
  },
  '45619': {
    country: 'US',
    city: 'Chesapeake',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lawrence',
    latitude: 38.4551,
    longitude: -82.4504,
    postal_code: '45619'
  },
  '45620': {
    country: 'US',
    city: 'Cheshire',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Gallia',
    latitude: 38.9587,
    longitude: -82.1235,
    postal_code: '45620'
  },
  '45621': {
    country: 'US',
    city: 'Coalton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jackson',
    latitude: 39.1108,
    longitude: -82.6077,
    postal_code: '45621'
  },
  '45622': {
    country: 'US',
    city: 'Creola',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Vinton',
    latitude: 39.3543,
    longitude: -82.503,
    postal_code: '45622'
  },
  '45623': {
    country: 'US',
    city: 'Crown City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Gallia',
    latitude: 38.6135,
    longitude: -82.2657,
    postal_code: '45623'
  },
  '45624': {
    country: 'US',
    city: 'Cynthiana',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pike',
    latitude: 39.1737,
    longitude: -83.3485,
    postal_code: '45624'
  },
  '45628': {
    country: 'US',
    city: 'Frankfort',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.391,
    longitude: -83.2034,
    postal_code: '45628'
  },
  '45629': {
    country: 'US',
    city: 'Franklin Furnace',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.6282,
    longitude: -82.8145,
    postal_code: '45629'
  },
  '45630': {
    country: 'US',
    city: 'Friendship',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.6991,
    longitude: -83.0916,
    postal_code: '45630'
  },
  '45631': {
    country: 'US',
    city: 'Gallipolis',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Gallia',
    latitude: 38.8148,
    longitude: -82.229,
    postal_code: '45631'
  },
  '45633': {
    country: 'US',
    city: 'Hallsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.4656,
    longitude: -82.7479,
    postal_code: '45633'
  },
  '45634': {
    country: 'US',
    city: 'Hamden',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Vinton',
    latitude: 39.1685,
    longitude: -82.51,
    postal_code: '45634'
  },
  '45636': {
    country: 'US',
    city: 'Haverhill',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.5848,
    longitude: -82.8321,
    postal_code: '45636'
  },
  '45638': {
    country: 'US',
    city: 'Ironton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lawrence',
    latitude: 38.5294,
    longitude: -82.6654,
    postal_code: '45638'
  },
  '45640': {
    country: 'US',
    city: 'Jackson',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jackson',
    latitude: 39.0428,
    longitude: -82.6472,
    postal_code: '45640'
  },
  '45642': {
    country: 'US',
    city: 'Jasper',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pike',
    latitude: 39.0523,
    longitude: -83.05,
    postal_code: '45642'
  },
  '45643': {
    country: 'US',
    city: 'Kerr',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Gallia',
    latitude: 38.8683,
    longitude: -82.2562,
    postal_code: '45643'
  },
  '45644': {
    country: 'US',
    city: 'Kingston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.4414,
    longitude: -82.8488,
    postal_code: '45644'
  },
  '45645': {
    country: 'US',
    city: 'Kitts Hill',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lawrence',
    latitude: 38.5649,
    longitude: -82.5489,
    postal_code: '45645'
  },
  '45646': {
    country: 'US',
    city: 'Latham',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pike',
    latitude: 39.0804,
    longitude: -83.3283,
    postal_code: '45646'
  },
  '45647': {
    country: 'US',
    city: 'Londonderry',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.2723,
    longitude: -82.7833,
    postal_code: '45647'
  },
  '45648': {
    country: 'US',
    city: 'Lucasville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.8938,
    longitude: -82.994,
    postal_code: '45648'
  },
  '45650': {
    country: 'US',
    city: 'Lynx',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Adams',
    latitude: 38.7391,
    longitude: -83.4262,
    postal_code: '45650'
  },
  '45651': {
    country: 'US',
    city: 'Mc Arthur',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Vinton',
    latitude: 39.28,
    longitude: -82.4753,
    postal_code: '45651'
  },
  '45652': {
    country: 'US',
    city: 'Mc Dermott',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.8362,
    longitude: -83.0689,
    postal_code: '45652'
  },
  '45653': {
    country: 'US',
    city: 'Minford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.8751,
    longitude: -82.8555,
    postal_code: '45653'
  },
  '45654': {
    country: 'US',
    city: 'New Plymouth',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Vinton',
    latitude: 39.3884,
    longitude: -82.3892,
    postal_code: '45654'
  },
  '45656': {
    country: 'US',
    city: 'Oak Hill',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jackson',
    latitude: 38.8916,
    longitude: -82.5883,
    postal_code: '45656'
  },
  '45657': {
    country: 'US',
    city: 'Otway',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.852,
    longitude: -83.2222,
    postal_code: '45657'
  },
  '45658': {
    country: 'US',
    city: 'Patriot',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Gallia',
    latitude: 38.777,
    longitude: -82.4275,
    postal_code: '45658'
  },
  '45659': {
    country: 'US',
    city: 'Pedro',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lawrence',
    latitude: 38.6503,
    longitude: -82.6477,
    postal_code: '45659'
  },
  '45660': {
    country: 'US',
    city: 'Peebles',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Adams',
    latitude: 38.9869,
    longitude: -83.3687,
    postal_code: '45660'
  },
  '45661': {
    country: 'US',
    city: 'Piketon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pike',
    latitude: 39.0437,
    longitude: -83.121,
    postal_code: '45661'
  },
  '45662': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.7932,
    longitude: -82.9306,
    postal_code: '45662'
  },
  '45663': {
    country: 'US',
    city: 'West Portsmouth',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.7495,
    longitude: -83.1335,
    postal_code: '45663'
  },
  '45669': {
    country: 'US',
    city: 'Proctorville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lawrence',
    latitude: 38.4635,
    longitude: -82.3523,
    postal_code: '45669'
  },
  '45671': {
    country: 'US',
    city: 'Rarden',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.9577,
    longitude: -83.2365,
    postal_code: '45671'
  },
  '45672': {
    country: 'US',
    city: 'Ray',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Vinton',
    latitude: 39.2077,
    longitude: -82.6908,
    postal_code: '45672'
  },
  '45673': {
    country: 'US',
    city: 'Richmond Dale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.2039,
    longitude: -82.812,
    postal_code: '45673'
  },
  '45674': {
    country: 'US',
    city: 'Rio Grande',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Gallia',
    latitude: 38.8814,
    longitude: -82.3782,
    postal_code: '45674'
  },
  '45675': {
    country: 'US',
    city: 'Rock Camp',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lawrence',
    latitude: 38.5367,
    longitude: -82.5327,
    postal_code: '45675'
  },
  '45677': {
    country: 'US',
    city: 'Scioto Furnace',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.7941,
    longitude: -82.7555,
    postal_code: '45677'
  },
  '45678': {
    country: 'US',
    city: 'Scottown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lawrence',
    latitude: 38.594,
    longitude: -82.3967,
    postal_code: '45678'
  },
  '45679': {
    country: 'US',
    city: 'Seaman',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Adams',
    latitude: 38.9621,
    longitude: -83.5936,
    postal_code: '45679'
  },
  '45680': {
    country: 'US',
    city: 'South Point',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lawrence',
    latitude: 38.4339,
    longitude: -82.5529,
    postal_code: '45680'
  },
  '45681': {
    country: 'US',
    city: 'South Salem',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Ross',
    latitude: 39.3021,
    longitude: -83.272,
    postal_code: '45681'
  },
  '45682': {
    country: 'US',
    city: 'South Webster',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.82,
    longitude: -82.7202,
    postal_code: '45682'
  },
  '45683': {
    country: 'US',
    city: 'Stockdale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pike',
    latitude: 38.9555,
    longitude: -82.8584,
    postal_code: '45683'
  },
  '45684': {
    country: 'US',
    city: 'Stout',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.6546,
    longitude: -83.209,
    postal_code: '45684'
  },
  '45685': {
    country: 'US',
    city: 'Thurman',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Gallia',
    latitude: 38.8705,
    longitude: -82.4063,
    postal_code: '45685'
  },
  '45686': {
    country: 'US',
    city: 'Vinton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Gallia',
    latitude: 38.9783,
    longitude: -82.357,
    postal_code: '45686'
  },
  '45687': {
    country: 'US',
    city: 'Wakefield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pike',
    latitude: 38.9703,
    longitude: -83.0199,
    postal_code: '45687'
  },
  '45688': {
    country: 'US',
    city: 'Waterloo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lawrence',
    latitude: 38.7182,
    longitude: -82.5174,
    postal_code: '45688'
  },
  '45690': {
    country: 'US',
    city: 'Waverly',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Pike',
    latitude: 39.1264,
    longitude: -83.0049,
    postal_code: '45690'
  },
  '45692': {
    country: 'US',
    city: 'Wellston',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Jackson',
    latitude: 39.1189,
    longitude: -82.5485,
    postal_code: '45692'
  },
  '45693': {
    country: 'US',
    city: 'West Union',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Adams',
    latitude: 38.8017,
    longitude: -83.5333,
    postal_code: '45693'
  },
  '45694': {
    country: 'US',
    city: 'Wheelersburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.7418,
    longitude: -82.8204,
    postal_code: '45694'
  },
  '45695': {
    country: 'US',
    city: 'Wilkesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Vinton',
    latitude: 39.1416,
    longitude: -82.3682,
    postal_code: '45695'
  },
  '45696': {
    country: 'US',
    city: 'Willow Wood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Lawrence',
    latitude: 38.594,
    longitude: -82.453,
    postal_code: '45696'
  },
  '45697': {
    country: 'US',
    city: 'Winchester',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Adams',
    latitude: 38.9353,
    longitude: -83.6661,
    postal_code: '45697'
  },
  '45698': {
    country: 'US',
    city: 'Zaleski',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Vinton',
    latitude: 39.283,
    longitude: -82.3977,
    postal_code: '45698'
  },
  '45699': {
    country: 'US',
    city: 'Lucasville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Scioto',
    latitude: 38.8795,
    longitude: -82.9968,
    postal_code: '45699'
  },
  '45701': {
    country: 'US',
    city: 'Athens',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.3178,
    longitude: -82.102,
    postal_code: '45701'
  },
  '45710': {
    country: 'US',
    city: 'Albany',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.2097,
    longitude: -82.2177,
    postal_code: '45710'
  },
  '45711': {
    country: 'US',
    city: 'Amesville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.4086,
    longitude: -81.965,
    postal_code: '45711'
  },
  '45712': {
    country: 'US',
    city: 'Barlow',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.3987,
    longitude: -81.6649,
    postal_code: '45712'
  },
  '45713': {
    country: 'US',
    city: 'Bartlett',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.4195,
    longitude: -81.8157,
    postal_code: '45713'
  },
  '45714': {
    country: 'US',
    city: 'Belpre',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.2868,
    longitude: -81.5968,
    postal_code: '45714'
  },
  '45715': {
    country: 'US',
    city: 'Beverly',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.5714,
    longitude: -81.6346,
    postal_code: '45715'
  },
  '45716': {
    country: 'US',
    city: 'Buchtel',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.462,
    longitude: -82.1818,
    postal_code: '45716'
  },
  '45717': {
    country: 'US',
    city: 'Carbondale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.3778,
    longitude: -82.271,
    postal_code: '45717'
  },
  '45719': {
    country: 'US',
    city: 'Chauncey',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.4002,
    longitude: -82.1302,
    postal_code: '45719'
  },
  '45720': {
    country: 'US',
    city: 'Chester',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 39.0856,
    longitude: -81.9214,
    postal_code: '45720'
  },
  '45721': {
    country: 'US',
    city: 'Coal Run',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.5676,
    longitude: -81.5812,
    postal_code: '45721'
  },
  '45723': {
    country: 'US',
    city: 'Coolville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.2141,
    longitude: -81.8329,
    postal_code: '45723'
  },
  '45724': {
    country: 'US',
    city: 'Cutler',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.4042,
    longitude: -81.7657,
    postal_code: '45724'
  },
  '45727': {
    country: 'US',
    city: 'Dexter City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Noble',
    latitude: 39.6526,
    longitude: -81.4672,
    postal_code: '45727'
  },
  '45729': {
    country: 'US',
    city: 'Fleming',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.4136,
    longitude: -81.6078,
    postal_code: '45729'
  },
  '45732': {
    country: 'US',
    city: 'Glouster',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.4978,
    longitude: -82.0871,
    postal_code: '45732'
  },
  '45734': {
    country: 'US',
    city: 'Graysville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Monroe',
    latitude: 39.663,
    longitude: -81.1822,
    postal_code: '45734'
  },
  '45735': {
    country: 'US',
    city: 'Guysville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.2493,
    longitude: -81.922,
    postal_code: '45735'
  },
  '45739': {
    country: 'US',
    city: 'Hockingport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.1979,
    longitude: -81.7446,
    postal_code: '45739'
  },
  '45740': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.477,
    longitude: -82.0794,
    postal_code: '45740'
  },
  '45741': {
    country: 'US',
    city: 'Langsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 39.0697,
    longitude: -82.2499,
    postal_code: '45741'
  },
  '45742': {
    country: 'US',
    city: 'Little Hocking',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.28,
    longitude: -81.7071,
    postal_code: '45742'
  },
  '45743': {
    country: 'US',
    city: 'Long Bottom',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 39.0802,
    longitude: -81.8887,
    postal_code: '45743'
  },
  '45744': {
    country: 'US',
    city: 'Lowell',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.5385,
    longitude: -81.5197,
    postal_code: '45744'
  },
  '45745': {
    country: 'US',
    city: 'Lower Salem',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.5611,
    longitude: -81.3968,
    postal_code: '45745'
  },
  '45746': {
    country: 'US',
    city: 'Macksburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.6204,
    longitude: -81.4471,
    postal_code: '45746'
  },
  '45750': {
    country: 'US',
    city: 'Marietta',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.4281,
    longitude: -81.4644,
    postal_code: '45750'
  },
  '45760': {
    country: 'US',
    city: 'Middleport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 38.9993,
    longitude: -82.06,
    postal_code: '45760'
  },
  '45761': {
    country: 'US',
    city: 'Millfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.4077,
    longitude: -82.1113,
    postal_code: '45761'
  },
  '45764': {
    country: 'US',
    city: 'Nelsonville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.4556,
    longitude: -82.2309,
    postal_code: '45764'
  },
  '45766': {
    country: 'US',
    city: 'New Marshfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.3228,
    longitude: -82.2594,
    postal_code: '45766'
  },
  '45767': {
    country: 'US',
    city: 'New Matamoras',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.5288,
    longitude: -81.094,
    postal_code: '45767'
  },
  '45768': {
    country: 'US',
    city: 'Newport',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.3971,
    longitude: -81.2402,
    postal_code: '45768'
  },
  '45769': {
    country: 'US',
    city: 'Pomeroy',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 39.0607,
    longitude: -82.0331,
    postal_code: '45769'
  },
  '45770': {
    country: 'US',
    city: 'Portland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 38.9999,
    longitude: -81.8135,
    postal_code: '45770'
  },
  '45771': {
    country: 'US',
    city: 'Racine',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 38.9786,
    longitude: -81.9258,
    postal_code: '45771'
  },
  '45772': {
    country: 'US',
    city: 'Reedsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 39.149,
    longitude: -81.7924,
    postal_code: '45772'
  },
  '45773': {
    country: 'US',
    city: 'Reno',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.4585,
    longitude: -81.2673,
    postal_code: '45773'
  },
  '45775': {
    country: 'US',
    city: 'Rutland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 39.0909,
    longitude: -82.1486,
    postal_code: '45775'
  },
  '45776': {
    country: 'US',
    city: 'Shade',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.2129,
    longitude: -82.0218,
    postal_code: '45776'
  },
  '45777': {
    country: 'US',
    city: 'Sharpsburg',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.4359,
    longitude: -81.9115,
    postal_code: '45777'
  },
  '45778': {
    country: 'US',
    city: 'Stewart',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.3213,
    longitude: -81.8929,
    postal_code: '45778'
  },
  '45779': {
    country: 'US',
    city: 'Syracuse',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 38.9976,
    longitude: -81.974,
    postal_code: '45779'
  },
  '45780': {
    country: 'US',
    city: 'The Plains',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.3662,
    longitude: -82.1341,
    postal_code: '45780'
  },
  '45782': {
    country: 'US',
    city: 'Trimble',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Athens',
    latitude: 39.4845,
    longitude: -82.0805,
    postal_code: '45782'
  },
  '45783': {
    country: 'US',
    city: 'Tuppers Plains',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Meigs',
    latitude: 39.1683,
    longitude: -81.8423,
    postal_code: '45783'
  },
  '45784': {
    country: 'US',
    city: 'Vincent',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.3374,
    longitude: -81.6743,
    postal_code: '45784'
  },
  '45786': {
    country: 'US',
    city: 'Waterford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.5159,
    longitude: -81.6559,
    postal_code: '45786'
  },
  '45787': {
    country: 'US',
    city: 'Watertown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.4659,
    longitude: -81.6332,
    postal_code: '45787'
  },
  '45788': {
    country: 'US',
    city: 'Whipple',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.5136,
    longitude: -81.3668,
    postal_code: '45788'
  },
  '45789': {
    country: 'US',
    city: 'Wingett Run',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Washington',
    latitude: 39.5428,
    longitude: -81.284,
    postal_code: '45789'
  },
  '45801': {
    country: 'US',
    city: 'Lima',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.7641,
    longitude: -84.0973,
    postal_code: '45801'
  },
  '45802': {
    country: 'US',
    city: 'Lima',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.7818,
    longitude: -84.1386,
    postal_code: '45802'
  },
  '45804': {
    country: 'US',
    city: 'Lima',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.7275,
    longitude: -84.089,
    postal_code: '45804'
  },
  '45805': {
    country: 'US',
    city: 'Lima',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.7399,
    longitude: -84.1459,
    postal_code: '45805'
  },
  '45806': {
    country: 'US',
    city: 'Lima',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.6752,
    longitude: -84.1244,
    postal_code: '45806'
  },
  '45807': {
    country: 'US',
    city: 'Lima',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.7955,
    longitude: -84.1383,
    postal_code: '45807'
  },
  '45808': {
    country: 'US',
    city: 'Beaverdam',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.8314,
    longitude: -83.9758,
    postal_code: '45808'
  },
  '45809': {
    country: 'US',
    city: 'Gomer',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.8451,
    longitude: -84.1872,
    postal_code: '45809'
  },
  '45810': {
    country: 'US',
    city: 'Ada',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hardin',
    latitude: 40.7709,
    longitude: -83.8154,
    postal_code: '45810'
  },
  '45812': {
    country: 'US',
    city: 'Alger',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hardin',
    latitude: 40.706,
    longitude: -83.825,
    postal_code: '45812'
  },
  '45813': {
    country: 'US',
    city: 'Antwerp',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Paulding',
    latitude: 41.1887,
    longitude: -84.7448,
    postal_code: '45813'
  },
  '45814': {
    country: 'US',
    city: 'Arlington',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 40.8761,
    longitude: -83.6685,
    postal_code: '45814'
  },
  '45815': {
    country: 'US',
    city: 'Belmore',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 41.1539,
    longitude: -83.9413,
    postal_code: '45815'
  },
  '45816': {
    country: 'US',
    city: 'Benton Ridge',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 41.0031,
    longitude: -83.7931,
    postal_code: '45816'
  },
  '45817': {
    country: 'US',
    city: 'Bluffton',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.879,
    longitude: -83.8914,
    postal_code: '45817'
  },
  '45819': {
    country: 'US',
    city: 'Buckland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.6241,
    longitude: -84.2603,
    postal_code: '45819'
  },
  '45820': {
    country: 'US',
    city: 'Cairo',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.8304,
    longitude: -84.0852,
    postal_code: '45820'
  },
  '45821': {
    country: 'US',
    city: 'Cecil',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Paulding',
    latitude: 41.2174,
    longitude: -84.6296,
    postal_code: '45821'
  },
  '45822': {
    country: 'US',
    city: 'Celina',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mercer',
    latitude: 40.5566,
    longitude: -84.6287,
    postal_code: '45822'
  },
  '45826': {
    country: 'US',
    city: 'Chickasaw',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mercer',
    latitude: 40.437,
    longitude: -84.4933,
    postal_code: '45826'
  },
  '45827': {
    country: 'US',
    city: 'Cloverdale',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 41.0379,
    longitude: -84.2938,
    postal_code: '45827'
  },
  '45828': {
    country: 'US',
    city: 'Coldwater',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mercer',
    latitude: 40.4846,
    longitude: -84.6517,
    postal_code: '45828'
  },
  '45830': {
    country: 'US',
    city: 'Columbus Grove',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 40.9137,
    longitude: -84.0705,
    postal_code: '45830'
  },
  '45831': {
    country: 'US',
    city: 'Continental',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 41.1148,
    longitude: -84.2358,
    postal_code: '45831'
  },
  '45832': {
    country: 'US',
    city: 'Convoy',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Van Wert',
    latitude: 40.927,
    longitude: -84.7238,
    postal_code: '45832'
  },
  '45833': {
    country: 'US',
    city: 'Delphos',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.8336,
    longitude: -84.3247,
    postal_code: '45833'
  },
  '45835': {
    country: 'US',
    city: 'Dola',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hardin',
    latitude: 40.7598,
    longitude: -83.7001,
    postal_code: '45835'
  },
  '45836': {
    country: 'US',
    city: 'Dunkirk',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hardin',
    latitude: 40.7824,
    longitude: -83.6339,
    postal_code: '45836'
  },
  '45837': {
    country: 'US',
    city: 'Dupont',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 41.0463,
    longitude: -84.3195,
    postal_code: '45837'
  },
  '45838': {
    country: 'US',
    city: 'Elgin',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Van Wert',
    latitude: 40.7425,
    longitude: -84.4758,
    postal_code: '45838'
  },
  '45839': {
    country: 'US',
    city: 'Findlay',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 41.0442,
    longitude: -83.6499,
    postal_code: '45839'
  },
  '45840': {
    country: 'US',
    city: 'Findlay',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 41.0449,
    longitude: -83.6457,
    postal_code: '45840'
  },
  '45841': {
    country: 'US',
    city: 'Jenera',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 40.9004,
    longitude: -83.7256,
    postal_code: '45841'
  },
  '45843': {
    country: 'US',
    city: 'Forest',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hardin',
    latitude: 40.7816,
    longitude: -83.5351,
    postal_code: '45843'
  },
  '45844': {
    country: 'US',
    city: 'Fort Jennings',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 40.9484,
    longitude: -84.2374,
    postal_code: '45844'
  },
  '45845': {
    country: 'US',
    city: 'Fort Loramie',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Shelby',
    latitude: 40.3306,
    longitude: -84.3741,
    postal_code: '45845'
  },
  '45846': {
    country: 'US',
    city: 'Fort Recovery',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mercer',
    latitude: 40.4018,
    longitude: -84.7613,
    postal_code: '45846'
  },
  '45848': {
    country: 'US',
    city: 'Glandorf',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 41.0289,
    longitude: -84.0791,
    postal_code: '45848'
  },
  '45849': {
    country: 'US',
    city: 'Grover Hill',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Paulding',
    latitude: 41.0245,
    longitude: -84.4956,
    postal_code: '45849'
  },
  '45850': {
    country: 'US',
    city: 'Harrod',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.7177,
    longitude: -83.9436,
    postal_code: '45850'
  },
  '45851': {
    country: 'US',
    city: 'Haviland',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Paulding',
    latitude: 41.0329,
    longitude: -84.6139,
    postal_code: '45851'
  },
  '45853': {
    country: 'US',
    city: 'Kalida',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 40.9828,
    longitude: -84.1994,
    postal_code: '45853'
  },
  '45854': {
    country: 'US',
    city: 'Lafayette',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.7582,
    longitude: -83.9499,
    postal_code: '45854'
  },
  '45855': {
    country: 'US',
    city: 'Latty',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Paulding',
    latitude: 41.0879,
    longitude: -84.5841,
    postal_code: '45855'
  },
  '45856': {
    country: 'US',
    city: 'Leipsic',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 41.1092,
    longitude: -83.9957,
    postal_code: '45856'
  },
  '45858': {
    country: 'US',
    city: 'Mc Comb',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 41.1125,
    longitude: -83.8014,
    postal_code: '45858'
  },
  '45859': {
    country: 'US',
    city: 'Mc Guffey',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hardin',
    latitude: 40.6899,
    longitude: -83.8111,
    postal_code: '45859'
  },
  '45860': {
    country: 'US',
    city: 'Maria Stein',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mercer',
    latitude: 40.4062,
    longitude: -84.5076,
    postal_code: '45860'
  },
  '45861': {
    country: 'US',
    city: 'Melrose',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Paulding',
    latitude: 41.0812,
    longitude: -84.4287,
    postal_code: '45861'
  },
  '45862': {
    country: 'US',
    city: 'Mendon',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mercer',
    latitude: 40.6777,
    longitude: -84.5152,
    postal_code: '45862'
  },
  '45863': {
    country: 'US',
    city: 'Middle Point',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Van Wert',
    latitude: 40.8993,
    longitude: -84.4541,
    postal_code: '45863'
  },
  '45864': {
    country: 'US',
    city: 'Miller City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 41.1038,
    longitude: -84.1315,
    postal_code: '45864'
  },
  '45865': {
    country: 'US',
    city: 'Minster',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.391,
    longitude: -84.3729,
    postal_code: '45865'
  },
  '45866': {
    country: 'US',
    city: 'Montezuma',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mercer',
    latitude: 40.489,
    longitude: -84.5494,
    postal_code: '45866'
  },
  '45867': {
    country: 'US',
    city: 'Mount Blanchard',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 40.8929,
    longitude: -83.5553,
    postal_code: '45867'
  },
  '45868': {
    country: 'US',
    city: 'Mount Cory',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 40.9437,
    longitude: -83.8093,
    postal_code: '45868'
  },
  '45869': {
    country: 'US',
    city: 'New Bremen',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.4389,
    longitude: -84.3821,
    postal_code: '45869'
  },
  '45870': {
    country: 'US',
    city: 'New Hampshire',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.5563,
    longitude: -83.9514,
    postal_code: '45870'
  },
  '45871': {
    country: 'US',
    city: 'New Knoxville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.5039,
    longitude: -84.3118,
    postal_code: '45871'
  },
  '45872': {
    country: 'US',
    city: 'North Baltimore',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Wood',
    latitude: 41.1867,
    longitude: -83.6806,
    postal_code: '45872'
  },
  '45873': {
    country: 'US',
    city: 'Oakwood',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Paulding',
    latitude: 41.0908,
    longitude: -84.3969,
    postal_code: '45873'
  },
  '45874': {
    country: 'US',
    city: 'Ohio City',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Van Wert',
    latitude: 40.7854,
    longitude: -84.6731,
    postal_code: '45874'
  },
  '45875': {
    country: 'US',
    city: 'Ottawa',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 41.0192,
    longitude: -84.0472,
    postal_code: '45875'
  },
  '45876': {
    country: 'US',
    city: 'Ottoville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 40.9323,
    longitude: -84.3388,
    postal_code: '45876'
  },
  '45877': {
    country: 'US',
    city: 'Pandora',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 40.9509,
    longitude: -83.9521,
    postal_code: '45877'
  },
  '45879': {
    country: 'US',
    city: 'Paulding',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Paulding',
    latitude: 41.141,
    longitude: -84.5722,
    postal_code: '45879'
  },
  '45880': {
    country: 'US',
    city: 'Payne',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Paulding',
    latitude: 41.0807,
    longitude: -84.7341,
    postal_code: '45880'
  },
  '45881': {
    country: 'US',
    city: 'Rawson',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 40.9589,
    longitude: -83.7841,
    postal_code: '45881'
  },
  '45882': {
    country: 'US',
    city: 'Rockford',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mercer',
    latitude: 40.6771,
    longitude: -84.6642,
    postal_code: '45882'
  },
  '45883': {
    country: 'US',
    city: 'Saint Henry',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Mercer',
    latitude: 40.4091,
    longitude: -84.6333,
    postal_code: '45883'
  },
  '45884': {
    country: 'US',
    city: 'Saint Johns',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.5559,
    longitude: -84.0836,
    postal_code: '45884'
  },
  '45885': {
    country: 'US',
    city: 'Saint Marys',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.544,
    longitude: -84.3944,
    postal_code: '45885'
  },
  '45886': {
    country: 'US',
    city: 'Scott',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Van Wert',
    latitude: 40.9892,
    longitude: -84.5845,
    postal_code: '45886'
  },
  '45887': {
    country: 'US',
    city: 'Spencerville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Allen',
    latitude: 40.7038,
    longitude: -84.3413,
    postal_code: '45887'
  },
  '45888': {
    country: 'US',
    city: 'Uniopolis',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.6023,
    longitude: -84.086,
    postal_code: '45888'
  },
  '45889': {
    country: 'US',
    city: 'Van Buren',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 41.1327,
    longitude: -83.6473,
    postal_code: '45889'
  },
  '45890': {
    country: 'US',
    city: 'Vanlue',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 40.9583,
    longitude: -83.4971,
    postal_code: '45890'
  },
  '45891': {
    country: 'US',
    city: 'Van Wert',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Van Wert',
    latitude: 40.8689,
    longitude: -84.5904,
    postal_code: '45891'
  },
  '45893': {
    country: 'US',
    city: 'Vaughnsville',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Putnam',
    latitude: 40.8821,
    longitude: -84.148,
    postal_code: '45893'
  },
  '45894': {
    country: 'US',
    city: 'Venedocia',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Van Wert',
    latitude: 40.7685,
    longitude: -84.462,
    postal_code: '45894'
  },
  '45895': {
    country: 'US',
    city: 'Wapakoneta',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.569,
    longitude: -84.1774,
    postal_code: '45895'
  },
  '45896': {
    country: 'US',
    city: 'Waynesfield',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Auglaize',
    latitude: 40.6072,
    longitude: -83.9585,
    postal_code: '45896'
  },
  '45897': {
    country: 'US',
    city: 'Williamstown',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hancock',
    latitude: 40.834,
    longitude: -83.6518,
    postal_code: '45897'
  },
  '45898': {
    country: 'US',
    city: 'Willshire',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Van Wert',
    latitude: 40.7346,
    longitude: -84.7777,
    postal_code: '45898'
  },
  '45899': {
    country: 'US',
    city: 'Wren',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Van Wert',
    latitude: 40.8009,
    longitude: -84.7752,
    postal_code: '45899'
  },
  '45999': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Ohio',
    state_short: 'OH',
    county: 'Hamilton',
    latitude: 39.1668,
    longitude: -84.5382,
    postal_code: '45999'
  },
  '46001': {
    country: 'US',
    city: 'Alexandria',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.2561,
    longitude: -85.6681,
    postal_code: '46001'
  },
  '46011': {
    country: 'US',
    city: 'Anderson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.1146,
    longitude: -85.7253,
    postal_code: '46011'
  },
  '46012': {
    country: 'US',
    city: 'Anderson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.1309,
    longitude: -85.6536,
    postal_code: '46012'
  },
  '46013': {
    country: 'US',
    city: 'Anderson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.0619,
    longitude: -85.6801,
    postal_code: '46013'
  },
  '46014': {
    country: 'US',
    city: 'Anderson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.1617,
    longitude: -85.7197,
    postal_code: '46014'
  },
  '46015': {
    country: 'US',
    city: 'Anderson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.0938,
    longitude: -85.6578,
    postal_code: '46015'
  },
  '46016': {
    country: 'US',
    city: 'Anderson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.0988,
    longitude: -85.6846,
    postal_code: '46016'
  },
  '46017': {
    country: 'US',
    city: 'Anderson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.0742,
    longitude: -85.6069,
    postal_code: '46017'
  },
  '46018': {
    country: 'US',
    city: 'Anderson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.1617,
    longitude: -85.7197,
    postal_code: '46018'
  },
  '46030': {
    country: 'US',
    city: 'Arcadia',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 40.1776,
    longitude: -86.0409,
    postal_code: '46030'
  },
  '46031': {
    country: 'US',
    city: 'Atlanta',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 40.2103,
    longitude: -86.019,
    postal_code: '46031'
  },
  '46032': {
    country: 'US',
    city: 'Carmel',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 39.9712,
    longitude: -86.1245,
    postal_code: '46032'
  },
  '46033': {
    country: 'US',
    city: 'Carmel',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 39.9744,
    longitude: -86.0829,
    postal_code: '46033'
  },
  '46034': {
    country: 'US',
    city: 'Cicero',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 40.1298,
    longitude: -86.0381,
    postal_code: '46034'
  },
  '46035': {
    country: 'US',
    city: 'Colfax',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clinton',
    latitude: 40.1956,
    longitude: -86.6593,
    postal_code: '46035'
  },
  '46036': {
    country: 'US',
    city: 'Elwood',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.2803,
    longitude: -85.8391,
    postal_code: '46036'
  },
  '46037': {
    country: 'US',
    city: 'Fishers',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 39.9559,
    longitude: -85.9601,
    postal_code: '46037'
  },
  '46038': {
    country: 'US',
    city: 'Fishers',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 39.9575,
    longitude: -86.023,
    postal_code: '46038'
  },
  '46039': {
    country: 'US',
    city: 'Forest',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clinton',
    latitude: 40.3757,
    longitude: -86.3201,
    postal_code: '46039'
  },
  '46040': {
    country: 'US',
    city: 'Fortville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hancock',
    latitude: 39.9323,
    longitude: -85.848,
    postal_code: '46040'
  },
  '46041': {
    country: 'US',
    city: 'Frankfort',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clinton',
    latitude: 40.3044,
    longitude: -86.4689,
    postal_code: '46041'
  },
  '46044': {
    country: 'US',
    city: 'Frankton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.2285,
    longitude: -85.7791,
    postal_code: '46044'
  },
  '46045': {
    country: 'US',
    city: 'Goldsmith',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tipton',
    latitude: 40.2898,
    longitude: -86.1494,
    postal_code: '46045'
  },
  '46047': {
    country: 'US',
    city: 'Hobbs',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tipton',
    latitude: 40.2836,
    longitude: -85.9475,
    postal_code: '46047'
  },
  '46048': {
    country: 'US',
    city: 'Ingalls',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 39.9573,
    longitude: -85.7981,
    postal_code: '46048'
  },
  '46049': {
    country: 'US',
    city: 'Kempton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tipton',
    latitude: 40.2884,
    longitude: -86.2297,
    postal_code: '46049'
  },
  '46050': {
    country: 'US',
    city: 'Kirklin',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clinton',
    latitude: 40.2031,
    longitude: -86.3324,
    postal_code: '46050'
  },
  '46051': {
    country: 'US',
    city: 'Lapel',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.0854,
    longitude: -85.844,
    postal_code: '46051'
  },
  '46052': {
    country: 'US',
    city: 'Lebanon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Boone',
    latitude: 40.0449,
    longitude: -86.4641,
    postal_code: '46052'
  },
  '46055': {
    country: 'US',
    city: 'Mccordsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hancock',
    latitude: 39.9081,
    longitude: -85.9228,
    postal_code: '46055'
  },
  '46056': {
    country: 'US',
    city: 'Markleville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 39.9944,
    longitude: -85.6227,
    postal_code: '46056'
  },
  '46057': {
    country: 'US',
    city: 'Michigantown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clinton',
    latitude: 40.3108,
    longitude: -86.3753,
    postal_code: '46057'
  },
  '46058': {
    country: 'US',
    city: 'Mulberry',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clinton',
    latitude: 40.3433,
    longitude: -86.6613,
    postal_code: '46058'
  },
  '46060': {
    country: 'US',
    city: 'Noblesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 40.0563,
    longitude: -86.0163,
    postal_code: '46060'
  },
  '46061': {
    country: 'US',
    city: 'Noblesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 40.0725,
    longitude: -86.0523,
    postal_code: '46061'
  },
  '46062': {
    country: 'US',
    city: 'Noblesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 40.0617,
    longitude: -86.0555,
    postal_code: '46062'
  },
  '46063': {
    country: 'US',
    city: 'Orestes',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.2715,
    longitude: -85.7281,
    postal_code: '46063'
  },
  '46064': {
    country: 'US',
    city: 'Pendleton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 39.9975,
    longitude: -85.7466,
    postal_code: '46064'
  },
  '46065': {
    country: 'US',
    city: 'Rossville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clinton',
    latitude: 40.4109,
    longitude: -86.608,
    postal_code: '46065'
  },
  '46067': {
    country: 'US',
    city: 'Sedalia',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clinton',
    latitude: 40.4156,
    longitude: -86.5147,
    postal_code: '46067'
  },
  '46068': {
    country: 'US',
    city: 'Sharpsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tipton',
    latitude: 40.3732,
    longitude: -86.1086,
    postal_code: '46068'
  },
  '46069': {
    country: 'US',
    city: 'Sheridan',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 40.135,
    longitude: -86.2205,
    postal_code: '46069'
  },
  '46070': {
    country: 'US',
    city: 'Summitville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Madison',
    latitude: 40.3398,
    longitude: -85.6403,
    postal_code: '46070'
  },
  '46071': {
    country: 'US',
    city: 'Thorntown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Boone',
    latitude: 40.1133,
    longitude: -86.5898,
    postal_code: '46071'
  },
  '46072': {
    country: 'US',
    city: 'Tipton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tipton',
    latitude: 40.2817,
    longitude: -86.0433,
    postal_code: '46072'
  },
  '46074': {
    country: 'US',
    city: 'Westfield',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 40.0489,
    longitude: -86.1499,
    postal_code: '46074'
  },
  '46075': {
    country: 'US',
    city: 'Whitestown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Boone',
    latitude: 40,
    longitude: -86.3507,
    postal_code: '46075'
  },
  '46076': {
    country: 'US',
    city: 'Windfall',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tipton',
    latitude: 40.3669,
    longitude: -85.9476,
    postal_code: '46076'
  },
  '46077': {
    country: 'US',
    city: 'Zionsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Boone',
    latitude: 39.9561,
    longitude: -86.2767,
    postal_code: '46077'
  },
  '46082': {
    country: 'US',
    city: 'Carmel',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 40.0725,
    longitude: -86.0523,
    postal_code: '46082'
  },
  '46085': {
    country: 'US',
    city: 'Fishers',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 39.9556,
    longitude: -86.0139,
    postal_code: '46085'
  },
  '46102': {
    country: 'US',
    city: 'Advance',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Boone',
    latitude: 39.9956,
    longitude: -86.6198,
    postal_code: '46102'
  },
  '46103': {
    country: 'US',
    city: 'Amo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.6886,
    longitude: -86.6136,
    postal_code: '46103'
  },
  '46104': {
    country: 'US',
    city: 'Arlington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Rush',
    latitude: 39.6488,
    longitude: -85.5828,
    postal_code: '46104'
  },
  '46105': {
    country: 'US',
    city: 'Bainbridge',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Putnam',
    latitude: 39.7612,
    longitude: -86.812,
    postal_code: '46105'
  },
  '46106': {
    country: 'US',
    city: 'Bargersville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Johnson',
    latitude: 39.5,
    longitude: -86.1797,
    postal_code: '46106'
  },
  '46107': {
    country: 'US',
    city: 'Beech Grove',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7154,
    longitude: -86.0933,
    postal_code: '46107'
  },
  '46110': {
    country: 'US',
    city: 'Boggstown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Shelby',
    latitude: 39.5666,
    longitude: -85.9141,
    postal_code: '46110'
  },
  '46111': {
    country: 'US',
    city: 'Brooklyn',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Morgan',
    latitude: 39.5396,
    longitude: -86.3701,
    postal_code: '46111'
  },
  '46112': {
    country: 'US',
    city: 'Brownsburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.8466,
    longitude: -86.3869,
    postal_code: '46112'
  },
  '46113': {
    country: 'US',
    city: 'Camby',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.6405,
    longitude: -86.3118,
    postal_code: '46113'
  },
  '46115': {
    country: 'US',
    city: 'Carthage',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Rush',
    latitude: 39.7466,
    longitude: -85.5754,
    postal_code: '46115'
  },
  '46117': {
    country: 'US',
    city: 'Charlottesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hancock',
    latitude: 39.8388,
    longitude: -85.6258,
    postal_code: '46117'
  },
  '46118': {
    country: 'US',
    city: 'Clayton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.6682,
    longitude: -86.4959,
    postal_code: '46118'
  },
  '46120': {
    country: 'US',
    city: 'Cloverdale',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Putnam',
    latitude: 39.5148,
    longitude: -86.7939,
    postal_code: '46120'
  },
  '46121': {
    country: 'US',
    city: 'Coatesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.6878,
    longitude: -86.6703,
    postal_code: '46121'
  },
  '46122': {
    country: 'US',
    city: 'Danville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.7628,
    longitude: -86.5343,
    postal_code: '46122'
  },
  '46123': {
    country: 'US',
    city: 'Avon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.7629,
    longitude: -86.3996,
    postal_code: '46123'
  },
  '46124': {
    country: 'US',
    city: 'Edinburgh',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Johnson',
    latitude: 39.3626,
    longitude: -85.9707,
    postal_code: '46124'
  },
  '46125': {
    country: 'US',
    city: 'Eminence',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Morgan',
    latitude: 39.5214,
    longitude: -86.6414,
    postal_code: '46125'
  },
  '46126': {
    country: 'US',
    city: 'Fairland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Shelby',
    latitude: 39.6295,
    longitude: -85.8913,
    postal_code: '46126'
  },
  '46127': {
    country: 'US',
    city: 'Falmouth',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Rush',
    latitude: 39.729,
    longitude: -85.3331,
    postal_code: '46127'
  },
  '46128': {
    country: 'US',
    city: 'Fillmore',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Putnam',
    latitude: 39.6475,
    longitude: -86.7469,
    postal_code: '46128'
  },
  '46129': {
    country: 'US',
    city: 'Finly',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hancock',
    latitude: 39.8215,
    longitude: -85.7652,
    postal_code: '46129'
  },
  '46130': {
    country: 'US',
    city: 'Fountaintown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Shelby',
    latitude: 39.6751,
    longitude: -85.7848,
    postal_code: '46130'
  },
  '46131': {
    country: 'US',
    city: 'Franklin',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Johnson',
    latitude: 39.4854,
    longitude: -86.0608,
    postal_code: '46131'
  },
  '46133': {
    country: 'US',
    city: 'Glenwood',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fayette',
    latitude: 39.6124,
    longitude: -85.2735,
    postal_code: '46133'
  },
  '46135': {
    country: 'US',
    city: 'Greencastle',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Putnam',
    latitude: 39.6495,
    longitude: -86.8686,
    postal_code: '46135'
  },
  '46140': {
    country: 'US',
    city: 'Greenfield',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hancock',
    latitude: 39.7902,
    longitude: -85.8141,
    postal_code: '46140'
  },
  '46142': {
    country: 'US',
    city: 'Greenwood',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Johnson',
    latitude: 39.6224,
    longitude: -86.149,
    postal_code: '46142'
  },
  '46143': {
    country: 'US',
    city: 'Greenwood',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Johnson',
    latitude: 39.596,
    longitude: -86.1309,
    postal_code: '46143'
  },
  '46144': {
    country: 'US',
    city: 'Gwynneville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Shelby',
    latitude: 39.6617,
    longitude: -85.6476,
    postal_code: '46144'
  },
  '46146': {
    country: 'US',
    city: 'Homer',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Rush',
    latitude: 39.5781,
    longitude: -85.578,
    postal_code: '46146'
  },
  '46147': {
    country: 'US',
    city: 'Jamestown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Boone',
    latitude: 39.9579,
    longitude: -86.6236,
    postal_code: '46147'
  },
  '46148': {
    country: 'US',
    city: 'Knightstown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.806,
    longitude: -85.5261,
    postal_code: '46148'
  },
  '46149': {
    country: 'US',
    city: 'Lizton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.8843,
    longitude: -86.5429,
    postal_code: '46149'
  },
  '46150': {
    country: 'US',
    city: 'Manilla',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Rush',
    latitude: 39.5742,
    longitude: -85.6194,
    postal_code: '46150'
  },
  '46151': {
    country: 'US',
    city: 'Martinsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Morgan',
    latitude: 39.4776,
    longitude: -86.4668,
    postal_code: '46151'
  },
  '46154': {
    country: 'US',
    city: 'Maxwell',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hancock',
    latitude: 39.8568,
    longitude: -85.7684,
    postal_code: '46154'
  },
  '46155': {
    country: 'US',
    city: 'Mays',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Rush',
    latitude: 39.7437,
    longitude: -85.43,
    postal_code: '46155'
  },
  '46156': {
    country: 'US',
    city: 'Milroy',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Rush',
    latitude: 39.4955,
    longitude: -85.5044,
    postal_code: '46156'
  },
  '46157': {
    country: 'US',
    city: 'Monrovia',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Morgan',
    latitude: 39.5714,
    longitude: -86.4894,
    postal_code: '46157'
  },
  '46158': {
    country: 'US',
    city: 'Mooresville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Morgan',
    latitude: 39.5915,
    longitude: -86.3642,
    postal_code: '46158'
  },
  '46160': {
    country: 'US',
    city: 'Morgantown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Brown',
    latitude: 39.3628,
    longitude: -86.2803,
    postal_code: '46160'
  },
  '46161': {
    country: 'US',
    city: 'Morristown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Shelby',
    latitude: 39.6675,
    longitude: -85.6934,
    postal_code: '46161'
  },
  '46162': {
    country: 'US',
    city: 'Needham',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Johnson',
    latitude: 39.5605,
    longitude: -85.9656,
    postal_code: '46162'
  },
  '46163': {
    country: 'US',
    city: 'New Palestine',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hancock',
    latitude: 39.7233,
    longitude: -85.9052,
    postal_code: '46163'
  },
  '46164': {
    country: 'US',
    city: 'Nineveh',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Johnson',
    latitude: 39.3656,
    longitude: -86.0976,
    postal_code: '46164'
  },
  '46165': {
    country: 'US',
    city: 'North Salem',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.8671,
    longitude: -86.6388,
    postal_code: '46165'
  },
  '46166': {
    country: 'US',
    city: 'Paragon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Morgan',
    latitude: 39.4042,
    longitude: -86.5779,
    postal_code: '46166'
  },
  '46167': {
    country: 'US',
    city: 'Pittsboro',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.8615,
    longitude: -86.4645,
    postal_code: '46167'
  },
  '46168': {
    country: 'US',
    city: 'Plainfield',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.6893,
    longitude: -86.3919,
    postal_code: '46168'
  },
  '46170': {
    country: 'US',
    city: 'Putnamville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Putnam',
    latitude: 39.5742,
    longitude: -86.8653,
    postal_code: '46170'
  },
  '46171': {
    country: 'US',
    city: 'Reelsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Putnam',
    latitude: 39.5464,
    longitude: -86.95,
    postal_code: '46171'
  },
  '46172': {
    country: 'US',
    city: 'Roachdale',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Putnam',
    latitude: 39.8325,
    longitude: -86.7902,
    postal_code: '46172'
  },
  '46173': {
    country: 'US',
    city: 'Rushville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Rush',
    latitude: 39.6192,
    longitude: -85.4321,
    postal_code: '46173'
  },
  '46175': {
    country: 'US',
    city: 'Russellville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Putnam',
    latitude: 39.8366,
    longitude: -86.967,
    postal_code: '46175'
  },
  '46176': {
    country: 'US',
    city: 'Shelbyville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Shelby',
    latitude: 39.5043,
    longitude: -85.7875,
    postal_code: '46176'
  },
  '46180': {
    country: 'US',
    city: 'Stilesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hendricks',
    latitude: 39.6391,
    longitude: -86.6182,
    postal_code: '46180'
  },
  '46181': {
    country: 'US',
    city: 'Trafalgar',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Johnson',
    latitude: 39.3696,
    longitude: -86.1838,
    postal_code: '46181'
  },
  '46182': {
    country: 'US',
    city: 'Waldron',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Shelby',
    latitude: 39.4688,
    longitude: -85.6644,
    postal_code: '46182'
  },
  '46183': {
    country: 'US',
    city: 'West Newton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.6531,
    longitude: -86.2828,
    postal_code: '46183'
  },
  '46184': {
    country: 'US',
    city: 'Whiteland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Johnson',
    latitude: 39.5611,
    longitude: -86.0723,
    postal_code: '46184'
  },
  '46186': {
    country: 'US',
    city: 'Wilkinson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hancock',
    latitude: 39.8957,
    longitude: -85.6144,
    postal_code: '46186'
  },
  '46201': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.775,
    longitude: -86.1093,
    postal_code: '46201'
  },
  '46202': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7851,
    longitude: -86.1595,
    postal_code: '46202'
  },
  '46203': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.743,
    longitude: -86.1179,
    postal_code: '46203'
  },
  '46204': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.772,
    longitude: -86.1535,
    postal_code: '46204'
  },
  '46205': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8268,
    longitude: -86.1386,
    postal_code: '46205'
  },
  '46206': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7613,
    longitude: -86.1613,
    postal_code: '46206'
  },
  '46207': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7673,
    longitude: -86.1606,
    postal_code: '46207'
  },
  '46208': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8299,
    longitude: -86.1794,
    postal_code: '46208'
  },
  '46209': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46209'
  },
  '46211': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46211'
  },
  '46213': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.9213,
    longitude: -86.0344,
    postal_code: '46213'
  },
  '46214': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7924,
    longitude: -86.2875,
    postal_code: '46214'
  },
  '46216': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8647,
    longitude: -86.0136,
    postal_code: '46216'
  },
  '46217': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.6782,
    longitude: -86.1976,
    postal_code: '46217'
  },
  '46218': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8082,
    longitude: -86.1014,
    postal_code: '46218'
  },
  '46219': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7821,
    longitude: -86.0495,
    postal_code: '46219'
  },
  '46220': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8647,
    longitude: -86.1181,
    postal_code: '46220'
  },
  '46221': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7509,
    longitude: -86.1924,
    postal_code: '46221'
  },
  '46222': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.789,
    longitude: -86.2136,
    postal_code: '46222'
  },
  '46224': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.794,
    longitude: -86.2707,
    postal_code: '46224'
  },
  '46225': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7406,
    longitude: -86.1569,
    postal_code: '46225'
  },
  '46226': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8326,
    longitude: -86.0836,
    postal_code: '46226'
  },
  '46227': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.6789,
    longitude: -86.1302,
    postal_code: '46227'
  },
  '46228': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8462,
    longitude: -86.1951,
    postal_code: '46228'
  },
  '46229': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7886,
    longitude: -85.9779,
    postal_code: '46229'
  },
  '46230': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46230'
  },
  '46231': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7038,
    longitude: -86.3029,
    postal_code: '46231'
  },
  '46234': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8088,
    longitude: -86.3163,
    postal_code: '46234'
  },
  '46235': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8471,
    longitude: -85.9741,
    postal_code: '46235'
  },
  '46236': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8689,
    longitude: -85.9765,
    postal_code: '46236'
  },
  '46237': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.673,
    longitude: -86.0757,
    postal_code: '46237'
  },
  '46239': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7265,
    longitude: -86.0005,
    postal_code: '46239'
  },
  '46240': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.9069,
    longitude: -86.1238,
    postal_code: '46240'
  },
  '46241': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7096,
    longitude: -86.2614,
    postal_code: '46241'
  },
  '46242': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46242'
  },
  '46244': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46244'
  },
  '46247': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46247'
  },
  '46249': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.859,
    longitude: -86.0061,
    postal_code: '46249'
  },
  '46250': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.9048,
    longitude: -86.0673,
    postal_code: '46250'
  },
  '46251': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46251'
  },
  '46253': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46253'
  },
  '46254': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.849,
    longitude: -86.272,
    postal_code: '46254'
  },
  '46255': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7684,
    longitude: -86.158,
    postal_code: '46255'
  },
  '46256': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8977,
    longitude: -86.0094,
    postal_code: '46256'
  },
  '46259': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.667,
    longitude: -85.9981,
    postal_code: '46259'
  },
  '46260': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8977,
    longitude: -86.1797,
    postal_code: '46260'
  },
  '46262': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 39.7684,
    longitude: -86.158,
    postal_code: '46262'
  },
  '46266': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7684,
    longitude: -86.158,
    postal_code: '46266'
  },
  '46268': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8682,
    longitude: -86.2123,
    postal_code: '46268'
  },
  '46274': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46274'
  },
  '46275': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46275'
  },
  '46277': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46277'
  },
  '46278': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.8726,
    longitude: -86.2768,
    postal_code: '46278'
  },
  '46280': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 39.9416,
    longitude: -86.1157,
    postal_code: '46280'
  },
  '46282': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46282'
  },
  '46283': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46283'
  },
  '46285': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46285'
  },
  '46290': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Hamilton',
    latitude: 39.9347,
    longitude: -86.1633,
    postal_code: '46290'
  },
  '46291': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46291'
  },
  '46295': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46295'
  },
  '46296': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.796,
    longitude: -86.1495,
    postal_code: '46296'
  },
  '46298': {
    country: 'US',
    city: 'Indianapolis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marion',
    latitude: 39.7795,
    longitude: -86.1328,
    postal_code: '46298'
  },
  '46301': {
    country: 'US',
    city: 'Beverly Shores',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Porter',
    latitude: 41.6925,
    longitude: -86.9775,
    postal_code: '46301'
  },
  '46302': {
    country: 'US',
    city: 'Boone Grove',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Porter',
    latitude: 41.3542,
    longitude: -87.1304,
    postal_code: '46302'
  },
  '46303': {
    country: 'US',
    city: 'Cedar Lake',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.3713,
    longitude: -87.4764,
    postal_code: '46303'
  },
  '46304': {
    country: 'US',
    city: 'Chesterton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Porter',
    latitude: 41.6143,
    longitude: -87.047,
    postal_code: '46304'
  },
  '46307': {
    country: 'US',
    city: 'Crown Point',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.4236,
    longitude: -87.3556,
    postal_code: '46307'
  },
  '46308': {
    country: 'US',
    city: 'Crown Point',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.417,
    longitude: -87.3653,
    postal_code: '46308'
  },
  '46310': {
    country: 'US',
    city: 'Demotte',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jasper',
    latitude: 41.1713,
    longitude: -87.2491,
    postal_code: '46310'
  },
  '46311': {
    country: 'US',
    city: 'Dyer',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.492,
    longitude: -87.5108,
    postal_code: '46311'
  },
  '46312': {
    country: 'US',
    city: 'East Chicago',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.6349,
    longitude: -87.4627,
    postal_code: '46312'
  },
  '46319': {
    country: 'US',
    city: 'Griffith',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5335,
    longitude: -87.4228,
    postal_code: '46319'
  },
  '46320': {
    country: 'US',
    city: 'Hammond',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.6099,
    longitude: -87.5079,
    postal_code: '46320'
  },
  '46321': {
    country: 'US',
    city: 'Munster',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5544,
    longitude: -87.5011,
    postal_code: '46321'
  },
  '46322': {
    country: 'US',
    city: 'Highland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.55,
    longitude: -87.4569,
    postal_code: '46322'
  },
  '46323': {
    country: 'US',
    city: 'Hammond',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5878,
    longitude: -87.4532,
    postal_code: '46323'
  },
  '46324': {
    country: 'US',
    city: 'Hammond',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.584,
    longitude: -87.5034,
    postal_code: '46324'
  },
  '46325': {
    country: 'US',
    city: 'Hammond',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.4615,
    longitude: -87.3728,
    postal_code: '46325'
  },
  '46327': {
    country: 'US',
    city: 'Hammond',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.6327,
    longitude: -87.5113,
    postal_code: '46327'
  },
  '46340': {
    country: 'US',
    city: 'Hanna',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.4088,
    longitude: -86.7759,
    postal_code: '46340'
  },
  '46341': {
    country: 'US',
    city: 'Hebron',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Porter',
    latitude: 41.3155,
    longitude: -87.2088,
    postal_code: '46341'
  },
  '46342': {
    country: 'US',
    city: 'Hobart',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5263,
    longitude: -87.2525,
    postal_code: '46342'
  },
  '46345': {
    country: 'US',
    city: 'Kingsbury',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.526,
    longitude: -86.6997,
    postal_code: '46345'
  },
  '46346': {
    country: 'US',
    city: 'Kingsford Heights',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.4802,
    longitude: -86.6919,
    postal_code: '46346'
  },
  '46347': {
    country: 'US',
    city: 'Kouts',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Porter',
    latitude: 41.3091,
    longitude: -87.024,
    postal_code: '46347'
  },
  '46348': {
    country: 'US',
    city: 'La Crosse',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.3157,
    longitude: -86.8682,
    postal_code: '46348'
  },
  '46349': {
    country: 'US',
    city: 'Lake Village',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Newton',
    latitude: 41.1387,
    longitude: -87.4454,
    postal_code: '46349'
  },
  '46350': {
    country: 'US',
    city: 'La Porte',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.5994,
    longitude: -86.7077,
    postal_code: '46350'
  },
  '46352': {
    country: 'US',
    city: 'La Porte',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.499,
    longitude: -86.7099,
    postal_code: '46352'
  },
  '46355': {
    country: 'US',
    city: 'Leroy',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.3594,
    longitude: -87.2708,
    postal_code: '46355'
  },
  '46356': {
    country: 'US',
    city: 'Lowell',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.2845,
    longitude: -87.4191,
    postal_code: '46356'
  },
  '46360': {
    country: 'US',
    city: 'Michigan City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.698,
    longitude: -86.8699,
    postal_code: '46360'
  },
  '46361': {
    country: 'US',
    city: 'Michigan City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.7035,
    longitude: -86.9151,
    postal_code: '46361'
  },
  '46365': {
    country: 'US',
    city: 'Mill Creek',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.6091,
    longitude: -86.544,
    postal_code: '46365'
  },
  '46366': {
    country: 'US',
    city: 'North Judson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Starke',
    latitude: 41.215,
    longitude: -86.7759,
    postal_code: '46366'
  },
  '46368': {
    country: 'US',
    city: 'Portage',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Porter',
    latitude: 41.5672,
    longitude: -87.1757,
    postal_code: '46368'
  },
  '46371': {
    country: 'US',
    city: 'Rolling Prairie',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.7229,
    longitude: -86.5841,
    postal_code: '46371'
  },
  '46372': {
    country: 'US',
    city: 'Roselawn',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Newton',
    latitude: 41.1434,
    longitude: -87.322,
    postal_code: '46372'
  },
  '46373': {
    country: 'US',
    city: 'Saint John',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.4495,
    longitude: -87.4764,
    postal_code: '46373'
  },
  '46374': {
    country: 'US',
    city: 'San Pierre',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Starke',
    latitude: 41.2111,
    longitude: -86.8725,
    postal_code: '46374'
  },
  '46375': {
    country: 'US',
    city: 'Schererville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.4922,
    longitude: -87.4605,
    postal_code: '46375'
  },
  '46376': {
    country: 'US',
    city: 'Schneider',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.19,
    longitude: -87.4779,
    postal_code: '46376'
  },
  '46377': {
    country: 'US',
    city: 'Shelby',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.1917,
    longitude: -87.3398,
    postal_code: '46377'
  },
  '46379': {
    country: 'US',
    city: 'Sumava Resorts',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Newton',
    latitude: 41.1671,
    longitude: -87.4335,
    postal_code: '46379'
  },
  '46380': {
    country: 'US',
    city: 'Tefft',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jasper',
    latitude: 41.1948,
    longitude: -86.9686,
    postal_code: '46380'
  },
  '46381': {
    country: 'US',
    city: 'Thayer',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Newton',
    latitude: 41.1711,
    longitude: -87.3313,
    postal_code: '46381'
  },
  '46382': {
    country: 'US',
    city: 'Union Mills',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.4602,
    longitude: -86.8355,
    postal_code: '46382'
  },
  '46383': {
    country: 'US',
    city: 'Valparaiso',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Porter',
    latitude: 41.4547,
    longitude: -87.0656,
    postal_code: '46383'
  },
  '46384': {
    country: 'US',
    city: 'Valparaiso',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Porter',
    latitude: 41.4905,
    longitude: -87.0761,
    postal_code: '46384'
  },
  '46385': {
    country: 'US',
    city: 'Valparaiso',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Porter',
    latitude: 41.4706,
    longitude: -87.0783,
    postal_code: '46385'
  },
  '46390': {
    country: 'US',
    city: 'Wanatah',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.3845,
    longitude: -86.8764,
    postal_code: '46390'
  },
  '46391': {
    country: 'US',
    city: 'Westville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaPorte',
    latitude: 41.5365,
    longitude: -86.9013,
    postal_code: '46391'
  },
  '46392': {
    country: 'US',
    city: 'Wheatfield',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jasper',
    latitude: 41.1779,
    longitude: -87.0699,
    postal_code: '46392'
  },
  '46393': {
    country: 'US',
    city: 'Wheeler',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Porter',
    latitude: 41.5116,
    longitude: -87.1792,
    postal_code: '46393'
  },
  '46394': {
    country: 'US',
    city: 'Whiting',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.6787,
    longitude: -87.5005,
    postal_code: '46394'
  },
  '46401': {
    country: 'US',
    city: 'Gary',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5907,
    longitude: -87.3199,
    postal_code: '46401'
  },
  '46402': {
    country: 'US',
    city: 'Gary',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5997,
    longitude: -87.3385,
    postal_code: '46402'
  },
  '46403': {
    country: 'US',
    city: 'Gary',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.6036,
    longitude: -87.259,
    postal_code: '46403'
  },
  '46404': {
    country: 'US',
    city: 'Gary',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5899,
    longitude: -87.3732,
    postal_code: '46404'
  },
  '46405': {
    country: 'US',
    city: 'Lake Station',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5686,
    longitude: -87.2622,
    postal_code: '46405'
  },
  '46406': {
    country: 'US',
    city: 'Gary',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5878,
    longitude: -87.4062,
    postal_code: '46406'
  },
  '46407': {
    country: 'US',
    city: 'Gary',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5804,
    longitude: -87.335,
    postal_code: '46407'
  },
  '46408': {
    country: 'US',
    city: 'Gary',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5422,
    longitude: -87.3588,
    postal_code: '46408'
  },
  '46409': {
    country: 'US',
    city: 'Gary',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.5412,
    longitude: -87.3271,
    postal_code: '46409'
  },
  '46410': {
    country: 'US',
    city: 'Merrillville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.4957,
    longitude: -87.3509,
    postal_code: '46410'
  },
  '46411': {
    country: 'US',
    city: 'Merrillville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lake',
    latitude: 41.4615,
    longitude: -87.3728,
    postal_code: '46411'
  },
  '46501': {
    country: 'US',
    city: 'Argos',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marshall',
    latitude: 41.2308,
    longitude: -86.2506,
    postal_code: '46501'
  },
  '46502': {
    country: 'US',
    city: 'Atwood',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.254,
    longitude: -85.9697,
    postal_code: '46502'
  },
  '46504': {
    country: 'US',
    city: 'Bourbon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marshall',
    latitude: 41.3098,
    longitude: -86.1174,
    postal_code: '46504'
  },
  '46506': {
    country: 'US',
    city: 'Bremen',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marshall',
    latitude: 41.4467,
    longitude: -86.1932,
    postal_code: '46506'
  },
  '46507': {
    country: 'US',
    city: 'Bristol',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.7169,
    longitude: -85.8262,
    postal_code: '46507'
  },
  '46508': {
    country: 'US',
    city: 'Burket',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.1547,
    longitude: -85.9693,
    postal_code: '46508'
  },
  '46510': {
    country: 'US',
    city: 'Claypool',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.1165,
    longitude: -85.8686,
    postal_code: '46510'
  },
  '46511': {
    country: 'US',
    city: 'Culver',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marshall',
    latitude: 41.22,
    longitude: -86.4269,
    postal_code: '46511'
  },
  '46513': {
    country: 'US',
    city: 'Donaldson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marshall',
    latitude: 41.3619,
    longitude: -86.4443,
    postal_code: '46513'
  },
  '46514': {
    country: 'US',
    city: 'Elkhart',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.7101,
    longitude: -85.9729,
    postal_code: '46514'
  },
  '46515': {
    country: 'US',
    city: 'Elkhart',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.682,
    longitude: -85.9767,
    postal_code: '46515'
  },
  '46516': {
    country: 'US',
    city: 'Elkhart',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.6763,
    longitude: -85.9621,
    postal_code: '46516'
  },
  '46517': {
    country: 'US',
    city: 'Elkhart',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.6469,
    longitude: -85.9728,
    postal_code: '46517'
  },
  '46524': {
    country: 'US',
    city: 'Etna Green',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.2918,
    longitude: -86.035,
    postal_code: '46524'
  },
  '46526': {
    country: 'US',
    city: 'Goshen',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.5848,
    longitude: -85.8581,
    postal_code: '46526'
  },
  '46527': {
    country: 'US',
    city: 'Goshen',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.5977,
    longitude: -85.8581,
    postal_code: '46527'
  },
  '46528': {
    country: 'US',
    city: 'Goshen',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.6248,
    longitude: -85.8391,
    postal_code: '46528'
  },
  '46530': {
    country: 'US',
    city: 'Granger',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.7427,
    longitude: -86.1411,
    postal_code: '46530'
  },
  '46531': {
    country: 'US',
    city: 'Grovertown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Starke',
    latitude: 41.3496,
    longitude: -86.5243,
    postal_code: '46531'
  },
  '46532': {
    country: 'US',
    city: 'Hamlet',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Starke',
    latitude: 41.3931,
    longitude: -86.5948,
    postal_code: '46532'
  },
  '46534': {
    country: 'US',
    city: 'Knox',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Starke',
    latitude: 41.2809,
    longitude: -86.6082,
    postal_code: '46534'
  },
  '46536': {
    country: 'US',
    city: 'Lakeville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.5253,
    longitude: -86.2714,
    postal_code: '46536'
  },
  '46537': {
    country: 'US',
    city: 'Lapaz',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marshall',
    latitude: 41.4599,
    longitude: -86.3102,
    postal_code: '46537'
  },
  '46538': {
    country: 'US',
    city: 'Leesburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.3266,
    longitude: -85.816,
    postal_code: '46538'
  },
  '46539': {
    country: 'US',
    city: 'Mentone',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.1615,
    longitude: -86.0299,
    postal_code: '46539'
  },
  '46540': {
    country: 'US',
    city: 'Middlebury',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.6754,
    longitude: -85.7114,
    postal_code: '46540'
  },
  '46542': {
    country: 'US',
    city: 'Milford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.4011,
    longitude: -85.8554,
    postal_code: '46542'
  },
  '46543': {
    country: 'US',
    city: 'Millersburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.5335,
    longitude: -85.7072,
    postal_code: '46543'
  },
  '46544': {
    country: 'US',
    city: 'Mishawaka',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.6507,
    longitude: -86.1623,
    postal_code: '46544'
  },
  '46545': {
    country: 'US',
    city: 'Mishawaka',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.6835,
    longitude: -86.1682,
    postal_code: '46545'
  },
  '46546': {
    country: 'US',
    city: 'Mishawaka',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.662,
    longitude: -86.1586,
    postal_code: '46546'
  },
  '46550': {
    country: 'US',
    city: 'Nappanee',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.4493,
    longitude: -85.9945,
    postal_code: '46550'
  },
  '46552': {
    country: 'US',
    city: 'New Carlisle',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.7051,
    longitude: -86.4838,
    postal_code: '46552'
  },
  '46553': {
    country: 'US',
    city: 'New Paris',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.4917,
    longitude: -85.8338,
    postal_code: '46553'
  },
  '46554': {
    country: 'US',
    city: 'North Liberty',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.5425,
    longitude: -86.4133,
    postal_code: '46554'
  },
  '46555': {
    country: 'US',
    city: 'North Webster',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.3326,
    longitude: -85.7103,
    postal_code: '46555'
  },
  '46556': {
    country: 'US',
    city: 'Notre Dame',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.7002,
    longitude: -86.2379,
    postal_code: '46556'
  },
  '46561': {
    country: 'US',
    city: 'Osceola',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.6695,
    longitude: -86.087,
    postal_code: '46561'
  },
  '46562': {
    country: 'US',
    city: 'Pierceton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.2124,
    longitude: -85.7061,
    postal_code: '46562'
  },
  '46563': {
    country: 'US',
    city: 'Plymouth',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marshall',
    latitude: 41.353,
    longitude: -86.3015,
    postal_code: '46563'
  },
  '46565': {
    country: 'US',
    city: 'Shipshewana',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaGrange',
    latitude: 41.6633,
    longitude: -85.5932,
    postal_code: '46565'
  },
  '46567': {
    country: 'US',
    city: 'Syracuse',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.4065,
    longitude: -85.7184,
    postal_code: '46567'
  },
  '46570': {
    country: 'US',
    city: 'Tippecanoe',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marshall',
    latitude: 41.2166,
    longitude: -86.1095,
    postal_code: '46570'
  },
  '46571': {
    country: 'US',
    city: 'Topeka',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaGrange',
    latitude: 41.5634,
    longitude: -85.5317,
    postal_code: '46571'
  },
  '46572': {
    country: 'US',
    city: 'Tyner',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Marshall',
    latitude: 41.4098,
    longitude: -86.4025,
    postal_code: '46572'
  },
  '46573': {
    country: 'US',
    city: 'Wakarusa',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Elkhart',
    latitude: 41.5401,
    longitude: -86.0205,
    postal_code: '46573'
  },
  '46574': {
    country: 'US',
    city: 'Walkerton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.4667,
    longitude: -86.4831,
    postal_code: '46574'
  },
  '46580': {
    country: 'US',
    city: 'Warsaw',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.2438,
    longitude: -85.8508,
    postal_code: '46580'
  },
  '46581': {
    country: 'US',
    city: 'Warsaw',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.2394,
    longitude: -85.8643,
    postal_code: '46581'
  },
  '46582': {
    country: 'US',
    city: 'Warsaw',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.2842,
    longitude: -85.8547,
    postal_code: '46582'
  },
  '46590': {
    country: 'US',
    city: 'Winona Lake',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.211,
    longitude: -85.8305,
    postal_code: '46590'
  },
  '46595': {
    country: 'US',
    city: 'Wyatt',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.5259,
    longitude: -86.1694,
    postal_code: '46595'
  },
  '46601': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.6727,
    longitude: -86.2535,
    postal_code: '46601'
  },
  '46613': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.6546,
    longitude: -86.2479,
    postal_code: '46613'
  },
  '46614': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.6255,
    longitude: -86.2433,
    postal_code: '46614'
  },
  '46615': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.6741,
    longitude: -86.2104,
    postal_code: '46615'
  },
  '46616': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.6919,
    longitude: -86.2647,
    postal_code: '46616'
  },
  '46617': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.685,
    longitude: -86.2351,
    postal_code: '46617'
  },
  '46619': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.6674,
    longitude: -86.3153,
    postal_code: '46619'
  },
  '46624': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.7332,
    longitude: -86.2833,
    postal_code: '46624'
  },
  '46626': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.6774,
    longitude: -86.2525,
    postal_code: '46626'
  },
  '46628': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.7015,
    longitude: -86.2949,
    postal_code: '46628'
  },
  '46634': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.5968,
    longitude: -86.293,
    postal_code: '46634'
  },
  '46635': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.7168,
    longitude: -86.2078,
    postal_code: '46635'
  },
  '46637': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.7299,
    longitude: -86.2407,
    postal_code: '46637'
  },
  '46660': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.5968,
    longitude: -86.293,
    postal_code: '46660'
  },
  '46680': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.5968,
    longitude: -86.293,
    postal_code: '46680'
  },
  '46699': {
    country: 'US',
    city: 'South Bend',
    state: 'Indiana',
    state_short: 'IN',
    county: 'St. Joseph',
    latitude: 41.5968,
    longitude: -86.293,
    postal_code: '46699'
  },
  '46701': {
    country: 'US',
    city: 'Albion',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Noble',
    latitude: 41.3482,
    longitude: -85.4142,
    postal_code: '46701'
  },
  '46702': {
    country: 'US',
    city: 'Andrews',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Huntington',
    latitude: 40.8618,
    longitude: -85.6067,
    postal_code: '46702'
  },
  '46703': {
    country: 'US',
    city: 'Angola',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Steuben',
    latitude: 41.6563,
    longitude: -85.0198,
    postal_code: '46703'
  },
  '46704': {
    country: 'US',
    city: 'Arcola',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.1038,
    longitude: -85.2925,
    postal_code: '46704'
  },
  '46705': {
    country: 'US',
    city: 'Ashley',
    state: 'Indiana',
    state_short: 'IN',
    county: 'DeKalb',
    latitude: 41.5347,
    longitude: -85.0504,
    postal_code: '46705'
  },
  '46706': {
    country: 'US',
    city: 'Auburn',
    state: 'Indiana',
    state_short: 'IN',
    county: 'DeKalb',
    latitude: 41.359,
    longitude: -85.0468,
    postal_code: '46706'
  },
  '46710': {
    country: 'US',
    city: 'Avilla',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Noble',
    latitude: 41.3689,
    longitude: -85.2414,
    postal_code: '46710'
  },
  '46711': {
    country: 'US',
    city: 'Berne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Adams',
    latitude: 40.6716,
    longitude: -84.9343,
    postal_code: '46711'
  },
  '46713': {
    country: 'US',
    city: 'Bippus',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Huntington',
    latitude: 40.9442,
    longitude: -85.6239,
    postal_code: '46713'
  },
  '46714': {
    country: 'US',
    city: 'Bluffton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wells',
    latitude: 40.7368,
    longitude: -85.1622,
    postal_code: '46714'
  },
  '46721': {
    country: 'US',
    city: 'Butler',
    state: 'Indiana',
    state_short: 'IN',
    county: 'DeKalb',
    latitude: 41.4287,
    longitude: -84.8787,
    postal_code: '46721'
  },
  '46723': {
    country: 'US',
    city: 'Churubusco',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Whitley',
    latitude: 41.229,
    longitude: -85.3244,
    postal_code: '46723'
  },
  '46725': {
    country: 'US',
    city: 'Columbia City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Whitley',
    latitude: 41.1619,
    longitude: -85.4737,
    postal_code: '46725'
  },
  '46730': {
    country: 'US',
    city: 'Corunna',
    state: 'Indiana',
    state_short: 'IN',
    county: 'DeKalb',
    latitude: 41.4504,
    longitude: -85.137,
    postal_code: '46730'
  },
  '46731': {
    country: 'US',
    city: 'Craigville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wells',
    latitude: 40.793,
    longitude: -85.0904,
    postal_code: '46731'
  },
  '46732': {
    country: 'US',
    city: 'Cromwell',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Noble',
    latitude: 41.3751,
    longitude: -85.6031,
    postal_code: '46732'
  },
  '46733': {
    country: 'US',
    city: 'Decatur',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Adams',
    latitude: 40.8273,
    longitude: -84.9314,
    postal_code: '46733'
  },
  '46737': {
    country: 'US',
    city: 'Fremont',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Steuben',
    latitude: 41.7331,
    longitude: -84.9452,
    postal_code: '46737'
  },
  '46738': {
    country: 'US',
    city: 'Garrett',
    state: 'Indiana',
    state_short: 'IN',
    county: 'DeKalb',
    latitude: 41.3482,
    longitude: -85.1347,
    postal_code: '46738'
  },
  '46740': {
    country: 'US',
    city: 'Geneva',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Adams',
    latitude: 40.6071,
    longitude: -84.9621,
    postal_code: '46740'
  },
  '46741': {
    country: 'US',
    city: 'Grabill',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.2108,
    longitude: -84.9406,
    postal_code: '46741'
  },
  '46742': {
    country: 'US',
    city: 'Hamilton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Steuben',
    latitude: 41.5566,
    longitude: -84.8951,
    postal_code: '46742'
  },
  '46743': {
    country: 'US',
    city: 'Harlan',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.2285,
    longitude: -84.8386,
    postal_code: '46743'
  },
  '46745': {
    country: 'US',
    city: 'Hoagland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 40.9524,
    longitude: -85.0075,
    postal_code: '46745'
  },
  '46746': {
    country: 'US',
    city: 'Howe',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaGrange',
    latitude: 41.7286,
    longitude: -85.4727,
    postal_code: '46746'
  },
  '46747': {
    country: 'US',
    city: 'Hudson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Steuben',
    latitude: 41.5328,
    longitude: -85.0811,
    postal_code: '46747'
  },
  '46748': {
    country: 'US',
    city: 'Huntertown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.2391,
    longitude: -85.1677,
    postal_code: '46748'
  },
  '46750': {
    country: 'US',
    city: 'Huntington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Huntington',
    latitude: 40.8811,
    longitude: -85.5054,
    postal_code: '46750'
  },
  '46755': {
    country: 'US',
    city: 'Kendallville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Noble',
    latitude: 41.4482,
    longitude: -85.2609,
    postal_code: '46755'
  },
  '46759': {
    country: 'US',
    city: 'Keystone',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wells',
    latitude: 40.5897,
    longitude: -85.2767,
    postal_code: '46759'
  },
  '46760': {
    country: 'US',
    city: 'Kimmell',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Noble',
    latitude: 41.3953,
    longitude: -85.5483,
    postal_code: '46760'
  },
  '46761': {
    country: 'US',
    city: 'Lagrange',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaGrange',
    latitude: 41.652,
    longitude: -85.404,
    postal_code: '46761'
  },
  '46763': {
    country: 'US',
    city: 'Laotto',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Noble',
    latitude: 41.2991,
    longitude: -85.1901,
    postal_code: '46763'
  },
  '46764': {
    country: 'US',
    city: 'Larwill',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Whitley',
    latitude: 41.1646,
    longitude: -85.6139,
    postal_code: '46764'
  },
  '46765': {
    country: 'US',
    city: 'Leo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.2249,
    longitude: -85.0301,
    postal_code: '46765'
  },
  '46766': {
    country: 'US',
    city: 'Liberty Center',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wells',
    latitude: 40.7002,
    longitude: -85.2774,
    postal_code: '46766'
  },
  '46767': {
    country: 'US',
    city: 'Ligonier',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Noble',
    latitude: 41.4662,
    longitude: -85.5927,
    postal_code: '46767'
  },
  '46769': {
    country: 'US',
    city: 'Linn Grove',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Adams',
    latitude: 40.645,
    longitude: -85.033,
    postal_code: '46769'
  },
  '46770': {
    country: 'US',
    city: 'Markle',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Huntington',
    latitude: 40.8331,
    longitude: -85.319,
    postal_code: '46770'
  },
  '46771': {
    country: 'US',
    city: 'Mongo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaGrange',
    latitude: 41.6848,
    longitude: -85.2797,
    postal_code: '46771'
  },
  '46772': {
    country: 'US',
    city: 'Monroe',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Adams',
    latitude: 40.7005,
    longitude: -84.8441,
    postal_code: '46772'
  },
  '46773': {
    country: 'US',
    city: 'Monroeville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 40.987,
    longitude: -84.8937,
    postal_code: '46773'
  },
  '46774': {
    country: 'US',
    city: 'New Haven',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0699,
    longitude: -85.0117,
    postal_code: '46774'
  },
  '46776': {
    country: 'US',
    city: 'Orland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Steuben',
    latitude: 41.7309,
    longitude: -85.1465,
    postal_code: '46776'
  },
  '46777': {
    country: 'US',
    city: 'Ossian',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wells',
    latitude: 40.8806,
    longitude: -85.157,
    postal_code: '46777'
  },
  '46778': {
    country: 'US',
    city: 'Petroleum',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wells',
    latitude: 40.6114,
    longitude: -85.1526,
    postal_code: '46778'
  },
  '46779': {
    country: 'US',
    city: 'Pleasant Lake',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Steuben',
    latitude: 41.5843,
    longitude: -85.0213,
    postal_code: '46779'
  },
  '46780': {
    country: 'US',
    city: 'Pleasant Mills',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Adams',
    latitude: 40.7778,
    longitude: -84.8422,
    postal_code: '46780'
  },
  '46781': {
    country: 'US',
    city: 'Poneto',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wells',
    latitude: 40.6419,
    longitude: -85.2562,
    postal_code: '46781'
  },
  '46782': {
    country: 'US',
    city: 'Preble',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Adams',
    latitude: 40.832,
    longitude: -85.0054,
    postal_code: '46782'
  },
  '46783': {
    country: 'US',
    city: 'Roanoke',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Huntington',
    latitude: 40.96,
    longitude: -85.3526,
    postal_code: '46783'
  },
  '46784': {
    country: 'US',
    city: 'Rome City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Noble',
    latitude: 41.4849,
    longitude: -85.3743,
    postal_code: '46784'
  },
  '46785': {
    country: 'US',
    city: 'Saint Joe',
    state: 'Indiana',
    state_short: 'IN',
    county: 'DeKalb',
    latitude: 41.324,
    longitude: -84.9043,
    postal_code: '46785'
  },
  '46786': {
    country: 'US',
    city: 'South Milford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaGrange',
    latitude: 41.5326,
    longitude: -85.2722,
    postal_code: '46786'
  },
  '46787': {
    country: 'US',
    city: 'South Whitley',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Whitley',
    latitude: 41.0726,
    longitude: -85.6143,
    postal_code: '46787'
  },
  '46788': {
    country: 'US',
    city: 'Spencerville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'DeKalb',
    latitude: 41.2696,
    longitude: -84.9398,
    postal_code: '46788'
  },
  '46789': {
    country: 'US',
    city: 'Stroh',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaGrange',
    latitude: 41.5835,
    longitude: -85.1992,
    postal_code: '46789'
  },
  '46791': {
    country: 'US',
    city: 'Uniondale',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wells',
    latitude: 40.8369,
    longitude: -85.2403,
    postal_code: '46791'
  },
  '46792': {
    country: 'US',
    city: 'Warren',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Huntington',
    latitude: 40.6886,
    longitude: -85.4183,
    postal_code: '46792'
  },
  '46793': {
    country: 'US',
    city: 'Waterloo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'DeKalb',
    latitude: 41.4402,
    longitude: -85.0221,
    postal_code: '46793'
  },
  '46794': {
    country: 'US',
    city: 'Wawaka',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Noble',
    latitude: 41.457,
    longitude: -85.4822,
    postal_code: '46794'
  },
  '46795': {
    country: 'US',
    city: 'Wolcottville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'LaGrange',
    latitude: 41.557,
    longitude: -85.315,
    postal_code: '46795'
  },
  '46796': {
    country: 'US',
    city: 'Wolflake',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Noble',
    latitude: 41.3361,
    longitude: -85.5002,
    postal_code: '46796'
  },
  '46797': {
    country: 'US',
    city: 'Woodburn',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.1253,
    longitude: -84.8533,
    postal_code: '46797'
  },
  '46798': {
    country: 'US',
    city: 'Yoder',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 40.9371,
    longitude: -85.1958,
    postal_code: '46798'
  },
  '46799': {
    country: 'US',
    city: 'Zanesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 40.9188,
    longitude: -85.2824,
    postal_code: '46799'
  },
  '46801': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46801'
  },
  '46802': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0707,
    longitude: -85.1543,
    postal_code: '46802'
  },
  '46803': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0695,
    longitude: -85.1074,
    postal_code: '46803'
  },
  '46804': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0508,
    longitude: -85.256,
    postal_code: '46804'
  },
  '46805': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0977,
    longitude: -85.1189,
    postal_code: '46805'
  },
  '46806': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.048,
    longitude: -85.1135,
    postal_code: '46806'
  },
  '46807': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0491,
    longitude: -85.1462,
    postal_code: '46807'
  },
  '46808': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0939,
    longitude: -85.1621,
    postal_code: '46808'
  },
  '46809': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0254,
    longitude: -85.1834,
    postal_code: '46809'
  },
  '46814': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0456,
    longitude: -85.3058,
    postal_code: '46814'
  },
  '46815': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.1053,
    longitude: -85.0624,
    postal_code: '46815'
  },
  '46816': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0165,
    longitude: -85.0976,
    postal_code: '46816'
  },
  '46818': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.1468,
    longitude: -85.2067,
    postal_code: '46818'
  },
  '46819': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0052,
    longitude: -85.1527,
    postal_code: '46819'
  },
  '46825': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.1465,
    longitude: -85.1232,
    postal_code: '46825'
  },
  '46835': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.1371,
    longitude: -85.0685,
    postal_code: '46835'
  },
  '46845': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.1958,
    longitude: -85.1191,
    postal_code: '46845'
  },
  '46850': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46850'
  },
  '46851': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46851'
  },
  '46852': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46852'
  },
  '46853': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46853'
  },
  '46854': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46854'
  },
  '46855': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46855'
  },
  '46856': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46856'
  },
  '46857': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46857'
  },
  '46858': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46858'
  },
  '46859': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46859'
  },
  '46860': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46860'
  },
  '46861': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46861'
  },
  '46862': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46862'
  },
  '46863': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46863'
  },
  '46864': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46864'
  },
  '46865': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.1263,
    longitude: -85.0907,
    postal_code: '46865'
  },
  '46866': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46866'
  },
  '46867': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46867'
  },
  '46868': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46868'
  },
  '46869': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46869'
  },
  '46885': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46885'
  },
  '46895': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46895'
  },
  '46896': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46896'
  },
  '46897': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46897'
  },
  '46898': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46898'
  },
  '46899': {
    country: 'US',
    city: 'Fort Wayne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Allen',
    latitude: 41.0938,
    longitude: -85.0707,
    postal_code: '46899'
  },
  '46901': {
    country: 'US',
    city: 'Kokomo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Howard',
    latitude: 40.4988,
    longitude: -86.1453,
    postal_code: '46901'
  },
  '46902': {
    country: 'US',
    city: 'Kokomo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Howard',
    latitude: 40.4509,
    longitude: -86.1352,
    postal_code: '46902'
  },
  '46903': {
    country: 'US',
    city: 'Kokomo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Howard',
    latitude: 40.4696,
    longitude: -86.1189,
    postal_code: '46903'
  },
  '46904': {
    country: 'US',
    city: 'Kokomo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Howard',
    latitude: 40.4696,
    longitude: -86.1189,
    postal_code: '46904'
  },
  '46910': {
    country: 'US',
    city: 'Akron',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fulton',
    latitude: 41.0389,
    longitude: -86.0395,
    postal_code: '46910'
  },
  '46911': {
    country: 'US',
    city: 'Amboy',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Miami',
    latitude: 40.6105,
    longitude: -85.9497,
    postal_code: '46911'
  },
  '46912': {
    country: 'US',
    city: 'Athens',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fulton',
    latitude: 41.0537,
    longitude: -86.1253,
    postal_code: '46912'
  },
  '46913': {
    country: 'US',
    city: 'Bringhurst',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Carroll',
    latitude: 40.5163,
    longitude: -86.5204,
    postal_code: '46913'
  },
  '46914': {
    country: 'US',
    city: 'Bunker Hill',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Miami',
    latitude: 40.6423,
    longitude: -86.0961,
    postal_code: '46914'
  },
  '46915': {
    country: 'US',
    city: 'Burlington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Carroll',
    latitude: 40.4777,
    longitude: -86.3866,
    postal_code: '46915'
  },
  '46916': {
    country: 'US',
    city: 'Burrows',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Carroll',
    latitude: 40.6767,
    longitude: -86.5075,
    postal_code: '46916'
  },
  '46917': {
    country: 'US',
    city: 'Camden',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Carroll',
    latitude: 40.5996,
    longitude: -86.5152,
    postal_code: '46917'
  },
  '46919': {
    country: 'US',
    city: 'Converse',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Miami',
    latitude: 40.577,
    longitude: -85.8763,
    postal_code: '46919'
  },
  '46920': {
    country: 'US',
    city: 'Cutler',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Carroll',
    latitude: 40.4785,
    longitude: -86.4462,
    postal_code: '46920'
  },
  '46921': {
    country: 'US',
    city: 'Deedsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Miami',
    latitude: 40.9103,
    longitude: -86.1011,
    postal_code: '46921'
  },
  '46922': {
    country: 'US',
    city: 'Delong',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fulton',
    latitude: 41.1384,
    longitude: -86.4164,
    postal_code: '46922'
  },
  '46923': {
    country: 'US',
    city: 'Delphi',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Carroll',
    latitude: 40.5734,
    longitude: -86.6788,
    postal_code: '46923'
  },
  '46926': {
    country: 'US',
    city: 'Denver',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Miami',
    latitude: 40.867,
    longitude: -86.0752,
    postal_code: '46926'
  },
  '46928': {
    country: 'US',
    city: 'Fairmount',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.4188,
    longitude: -85.6713,
    postal_code: '46928'
  },
  '46929': {
    country: 'US',
    city: 'Flora',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Carroll',
    latitude: 40.5445,
    longitude: -86.5016,
    postal_code: '46929'
  },
  '46930': {
    country: 'US',
    city: 'Fowlerton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.4099,
    longitude: -85.571,
    postal_code: '46930'
  },
  '46931': {
    country: 'US',
    city: 'Fulton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fulton',
    latitude: 40.9473,
    longitude: -86.2628,
    postal_code: '46931'
  },
  '46932': {
    country: 'US',
    city: 'Galveston',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Cass',
    latitude: 40.5862,
    longitude: -86.1972,
    postal_code: '46932'
  },
  '46933': {
    country: 'US',
    city: 'Gas City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.4879,
    longitude: -85.6055,
    postal_code: '46933'
  },
  '46935': {
    country: 'US',
    city: 'Grass Creek',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fulton',
    latitude: 40.9475,
    longitude: -86.4044,
    postal_code: '46935'
  },
  '46936': {
    country: 'US',
    city: 'Greentown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Howard',
    latitude: 40.4791,
    longitude: -85.9582,
    postal_code: '46936'
  },
  '46937': {
    country: 'US',
    city: 'Hemlock',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Howard',
    latitude: 40.4187,
    longitude: -86.0181,
    postal_code: '46937'
  },
  '46938': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.4815,
    longitude: -85.6365,
    postal_code: '46938'
  },
  '46939': {
    country: 'US',
    city: 'Kewanna',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fulton',
    latitude: 41.0087,
    longitude: -86.4061,
    postal_code: '46939'
  },
  '46940': {
    country: 'US',
    city: 'La Fontaine',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wabash',
    latitude: 40.6909,
    longitude: -85.6971,
    postal_code: '46940'
  },
  '46941': {
    country: 'US',
    city: 'Lagro',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wabash',
    latitude: 40.8199,
    longitude: -85.7204,
    postal_code: '46941'
  },
  '46942': {
    country: 'US',
    city: 'Lake Cicott',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Cass',
    latitude: 40.7361,
    longitude: -86.3734,
    postal_code: '46942'
  },
  '46943': {
    country: 'US',
    city: 'Laketon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wabash',
    latitude: 40.9743,
    longitude: -85.8375,
    postal_code: '46943'
  },
  '46945': {
    country: 'US',
    city: 'Leiters Ford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fulton',
    latitude: 41.1217,
    longitude: -86.3858,
    postal_code: '46945'
  },
  '46946': {
    country: 'US',
    city: 'Liberty Mills',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wabash',
    latitude: 41.0356,
    longitude: -85.7358,
    postal_code: '46946'
  },
  '46947': {
    country: 'US',
    city: 'Logansport',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Cass',
    latitude: 40.7604,
    longitude: -86.3599,
    postal_code: '46947'
  },
  '46950': {
    country: 'US',
    city: 'Lucerne',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Cass',
    latitude: 40.8614,
    longitude: -86.4077,
    postal_code: '46950'
  },
  '46951': {
    country: 'US',
    city: 'Macy',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Miami',
    latitude: 40.9618,
    longitude: -86.1264,
    postal_code: '46951'
  },
  '46952': {
    country: 'US',
    city: 'Marion',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.5743,
    longitude: -85.6741,
    postal_code: '46952'
  },
  '46953': {
    country: 'US',
    city: 'Marion',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.5359,
    longitude: -85.6616,
    postal_code: '46953'
  },
  '46957': {
    country: 'US',
    city: 'Matthews',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.3887,
    longitude: -85.4994,
    postal_code: '46957'
  },
  '46958': {
    country: 'US',
    city: 'Mexico',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Miami',
    latitude: 40.8224,
    longitude: -86.1162,
    postal_code: '46958'
  },
  '46959': {
    country: 'US',
    city: 'Miami',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Miami',
    latitude: 40.6147,
    longitude: -86.1064,
    postal_code: '46959'
  },
  '46960': {
    country: 'US',
    city: 'Monterey',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pulaski',
    latitude: 41.1383,
    longitude: -86.5179,
    postal_code: '46960'
  },
  '46961': {
    country: 'US',
    city: 'New Waverly',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Cass',
    latitude: 40.7621,
    longitude: -86.1936,
    postal_code: '46961'
  },
  '46962': {
    country: 'US',
    city: 'North Manchester',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wabash',
    latitude: 40.9986,
    longitude: -85.7842,
    postal_code: '46962'
  },
  '46965': {
    country: 'US',
    city: 'Oakford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Howard',
    latitude: 40.4127,
    longitude: -86.1015,
    postal_code: '46965'
  },
  '46967': {
    country: 'US',
    city: 'Onward',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Cass',
    latitude: 40.6947,
    longitude: -86.1951,
    postal_code: '46967'
  },
  '46968': {
    country: 'US',
    city: 'Ora',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Starke',
    latitude: 41.1756,
    longitude: -86.5543,
    postal_code: '46968'
  },
  '46970': {
    country: 'US',
    city: 'Peru',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Miami',
    latitude: 40.7492,
    longitude: -86.068,
    postal_code: '46970'
  },
  '46971': {
    country: 'US',
    city: 'Grissom Arb',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Miami',
    latitude: 40.6683,
    longitude: -86.1378,
    postal_code: '46971'
  },
  '46974': {
    country: 'US',
    city: 'Roann',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wabash',
    latitude: 40.9482,
    longitude: -85.9301,
    postal_code: '46974'
  },
  '46975': {
    country: 'US',
    city: 'Rochester',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fulton',
    latitude: 41.0655,
    longitude: -86.231,
    postal_code: '46975'
  },
  '46977': {
    country: 'US',
    city: 'Rockfield',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Carroll',
    latitude: 40.6411,
    longitude: -86.5739,
    postal_code: '46977'
  },
  '46978': {
    country: 'US',
    city: 'Royal Center',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Cass',
    latitude: 40.8645,
    longitude: -86.5078,
    postal_code: '46978'
  },
  '46979': {
    country: 'US',
    city: 'Russiaville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Howard',
    latitude: 40.4151,
    longitude: -86.2675,
    postal_code: '46979'
  },
  '46980': {
    country: 'US',
    city: 'Servia',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wabash',
    latitude: 40.9567,
    longitude: -85.7405,
    postal_code: '46980'
  },
  '46982': {
    country: 'US',
    city: 'Silver Lake',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Kosciusko',
    latitude: 41.0743,
    longitude: -85.8792,
    postal_code: '46982'
  },
  '46984': {
    country: 'US',
    city: 'Somerset',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wabash',
    latitude: 40.6714,
    longitude: -85.8288,
    postal_code: '46984'
  },
  '46985': {
    country: 'US',
    city: 'Star City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pulaski',
    latitude: 40.9602,
    longitude: -86.5404,
    postal_code: '46985'
  },
  '46986': {
    country: 'US',
    city: 'Swayzee',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.5112,
    longitude: -85.8265,
    postal_code: '46986'
  },
  '46987': {
    country: 'US',
    city: 'Sweetser',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.5647,
    longitude: -85.7656,
    postal_code: '46987'
  },
  '46988': {
    country: 'US',
    city: 'Twelve Mile',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Cass',
    latitude: 40.8547,
    longitude: -86.2126,
    postal_code: '46988'
  },
  '46989': {
    country: 'US',
    city: 'Upland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.4548,
    longitude: -85.499,
    postal_code: '46989'
  },
  '46990': {
    country: 'US',
    city: 'Urbana',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wabash',
    latitude: 40.8987,
    longitude: -85.7485,
    postal_code: '46990'
  },
  '46991': {
    country: 'US',
    city: 'Van Buren',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Grant',
    latitude: 40.6174,
    longitude: -85.5148,
    postal_code: '46991'
  },
  '46992': {
    country: 'US',
    city: 'Wabash',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wabash',
    latitude: 40.7909,
    longitude: -85.8321,
    postal_code: '46992'
  },
  '46994': {
    country: 'US',
    city: 'Walton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Cass',
    latitude: 40.6772,
    longitude: -86.2805,
    postal_code: '46994'
  },
  '46995': {
    country: 'US',
    city: 'West Middleton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Howard',
    latitude: 40.4421,
    longitude: -86.2155,
    postal_code: '46995'
  },
  '46996': {
    country: 'US',
    city: 'Winamac',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pulaski',
    latitude: 41.0562,
    longitude: -86.6307,
    postal_code: '46996'
  },
  '46998': {
    country: 'US',
    city: 'Young America',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Cass',
    latitude: 40.5686,
    longitude: -86.3467,
    postal_code: '46998'
  },
  '47001': {
    country: 'US',
    city: 'Aurora',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dearborn',
    latitude: 39.0719,
    longitude: -84.9452,
    postal_code: '47001'
  },
  '47003': {
    country: 'US',
    city: 'West College Corner',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Franklin',
    latitude: 39.5676,
    longitude: -84.8161,
    postal_code: '47003'
  },
  '47006': {
    country: 'US',
    city: 'Batesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 39.3001,
    longitude: -85.2221,
    postal_code: '47006'
  },
  '47010': {
    country: 'US',
    city: 'Bath',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Franklin',
    latitude: 39.4992,
    longitude: -84.836,
    postal_code: '47010'
  },
  '47011': {
    country: 'US',
    city: 'Bennington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Switzerland',
    latitude: 38.876,
    longitude: -85.0719,
    postal_code: '47011'
  },
  '47012': {
    country: 'US',
    city: 'Brookville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Franklin',
    latitude: 39.4213,
    longitude: -84.9994,
    postal_code: '47012'
  },
  '47016': {
    country: 'US',
    city: 'Cedar Grove',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Franklin',
    latitude: 39.3459,
    longitude: -84.8924,
    postal_code: '47016'
  },
  '47017': {
    country: 'US',
    city: 'Cross Plains',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 38.949,
    longitude: -85.2122,
    postal_code: '47017'
  },
  '47018': {
    country: 'US',
    city: 'Dillsboro',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dearborn',
    latitude: 38.9962,
    longitude: -85.055,
    postal_code: '47018'
  },
  '47019': {
    country: 'US',
    city: 'East Enterprise',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Switzerland',
    latitude: 38.8728,
    longitude: -84.988,
    postal_code: '47019'
  },
  '47020': {
    country: 'US',
    city: 'Florence',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Switzerland',
    latitude: 38.8224,
    longitude: -84.9399,
    postal_code: '47020'
  },
  '47021': {
    country: 'US',
    city: 'Friendship',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 38.9703,
    longitude: -85.1504,
    postal_code: '47021'
  },
  '47022': {
    country: 'US',
    city: 'Guilford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dearborn',
    latitude: 39.2059,
    longitude: -84.9616,
    postal_code: '47022'
  },
  '47023': {
    country: 'US',
    city: 'Holton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 39.0498,
    longitude: -85.3739,
    postal_code: '47023'
  },
  '47024': {
    country: 'US',
    city: 'Laurel',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Franklin',
    latitude: 39.4916,
    longitude: -85.208,
    postal_code: '47024'
  },
  '47025': {
    country: 'US',
    city: 'Lawrenceburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dearborn',
    latitude: 39.1401,
    longitude: -84.8658,
    postal_code: '47025'
  },
  '47030': {
    country: 'US',
    city: 'Metamora',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Franklin',
    latitude: 39.4288,
    longitude: -85.1504,
    postal_code: '47030'
  },
  '47031': {
    country: 'US',
    city: 'Milan',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 39.1503,
    longitude: -85.1324,
    postal_code: '47031'
  },
  '47032': {
    country: 'US',
    city: 'Moores Hill',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dearborn',
    latitude: 39.0945,
    longitude: -85.0638,
    postal_code: '47032'
  },
  '47033': {
    country: 'US',
    city: 'Morris',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 39.2814,
    longitude: -85.1739,
    postal_code: '47033'
  },
  '47034': {
    country: 'US',
    city: 'Napoleon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 39.2048,
    longitude: -85.327,
    postal_code: '47034'
  },
  '47035': {
    country: 'US',
    city: 'New Trenton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Franklin',
    latitude: 39.31,
    longitude: -84.9007,
    postal_code: '47035'
  },
  '47036': {
    country: 'US',
    city: 'Oldenburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Franklin',
    latitude: 39.3862,
    longitude: -85.2385,
    postal_code: '47036'
  },
  '47037': {
    country: 'US',
    city: 'Osgood',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 39.1573,
    longitude: -85.2938,
    postal_code: '47037'
  },
  '47038': {
    country: 'US',
    city: 'Patriot',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Switzerland',
    latitude: 38.8537,
    longitude: -84.8515,
    postal_code: '47038'
  },
  '47039': {
    country: 'US',
    city: 'Pierceville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 39.1116,
    longitude: -85.2551,
    postal_code: '47039'
  },
  '47040': {
    country: 'US',
    city: 'Rising Sun',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ohio',
    latitude: 38.9567,
    longitude: -84.8807,
    postal_code: '47040'
  },
  '47041': {
    country: 'US',
    city: 'Sunman',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 39.237,
    longitude: -85.0947,
    postal_code: '47041'
  },
  '47042': {
    country: 'US',
    city: 'Versailles',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Ripley',
    latitude: 39.0511,
    longitude: -85.2235,
    postal_code: '47042'
  },
  '47043': {
    country: 'US',
    city: 'Vevay',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Switzerland',
    latitude: 38.7724,
    longitude: -85.0852,
    postal_code: '47043'
  },
  '47060': {
    country: 'US',
    city: 'West Harrison',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dearborn',
    latitude: 39.3008,
    longitude: -84.881,
    postal_code: '47060'
  },
  '47102': {
    country: 'US',
    city: 'Austin',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Scott',
    latitude: 38.7478,
    longitude: -85.7962,
    postal_code: '47102'
  },
  '47104': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.5399,
    longitude: -85.4218,
    postal_code: '47104'
  },
  '47106': {
    country: 'US',
    city: 'Borden',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.4362,
    longitude: -85.9215,
    postal_code: '47106'
  },
  '47107': {
    country: 'US',
    city: 'Bradford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.3678,
    longitude: -86.0619,
    postal_code: '47107'
  },
  '47108': {
    country: 'US',
    city: 'Campbellsburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Washington',
    latitude: 38.669,
    longitude: -86.2358,
    postal_code: '47108'
  },
  '47110': {
    country: 'US',
    city: 'Central',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.097,
    longitude: -86.1723,
    postal_code: '47110'
  },
  '47111': {
    country: 'US',
    city: 'Charlestown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.4568,
    longitude: -85.6606,
    postal_code: '47111'
  },
  '47112': {
    country: 'US',
    city: 'Corydon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.2189,
    longitude: -86.1145,
    postal_code: '47112'
  },
  '47114': {
    country: 'US',
    city: 'Crandall',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.2876,
    longitude: -86.0664,
    postal_code: '47114'
  },
  '47115': {
    country: 'US',
    city: 'Depauw',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.3361,
    longitude: -86.2109,
    postal_code: '47115'
  },
  '47116': {
    country: 'US',
    city: 'Eckerty',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Crawford',
    latitude: 38.3185,
    longitude: -86.6059,
    postal_code: '47116'
  },
  '47117': {
    country: 'US',
    city: 'Elizabeth',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.1244,
    longitude: -85.9589,
    postal_code: '47117'
  },
  '47118': {
    country: 'US',
    city: 'English',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Crawford',
    latitude: 38.3258,
    longitude: -86.4429,
    postal_code: '47118'
  },
  '47119': {
    country: 'US',
    city: 'Floyds Knobs',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Floyd',
    latitude: 38.351,
    longitude: -85.8996,
    postal_code: '47119'
  },
  '47120': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Washington',
    latitude: 38.4331,
    longitude: -86.1897,
    postal_code: '47120'
  },
  '47122': {
    country: 'US',
    city: 'Georgetown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Floyd',
    latitude: 38.3029,
    longitude: -85.9617,
    postal_code: '47122'
  },
  '47123': {
    country: 'US',
    city: 'Grantsburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Crawford',
    latitude: 38.282,
    longitude: -86.4843,
    postal_code: '47123'
  },
  '47124': {
    country: 'US',
    city: 'Greenville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Floyd',
    latitude: 38.3535,
    longitude: -86.0083,
    postal_code: '47124'
  },
  '47125': {
    country: 'US',
    city: 'Hardinsburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Washington',
    latitude: 38.4626,
    longitude: -86.318,
    postal_code: '47125'
  },
  '47126': {
    country: 'US',
    city: 'Henryville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.5398,
    longitude: -85.7734,
    postal_code: '47126'
  },
  '47129': {
    country: 'US',
    city: 'Clarksville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.311,
    longitude: -85.7645,
    postal_code: '47129'
  },
  '47130': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.3078,
    longitude: -85.7359,
    postal_code: '47130'
  },
  '47131': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.437,
    longitude: -85.705,
    postal_code: '47131'
  },
  '47132': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.2868,
    longitude: -85.7321,
    postal_code: '47132'
  },
  '47133': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.2868,
    longitude: -85.7321,
    postal_code: '47133'
  },
  '47134': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.2868,
    longitude: -85.7321,
    postal_code: '47134'
  },
  '47135': {
    country: 'US',
    city: 'Laconia',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.0527,
    longitude: -86.0844,
    postal_code: '47135'
  },
  '47136': {
    country: 'US',
    city: 'Lanesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.2448,
    longitude: -85.9593,
    postal_code: '47136'
  },
  '47137': {
    country: 'US',
    city: 'Leavenworth',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Crawford',
    latitude: 38.1865,
    longitude: -86.3838,
    postal_code: '47137'
  },
  '47138': {
    country: 'US',
    city: 'Lexington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Scott',
    latitude: 38.6523,
    longitude: -85.6252,
    postal_code: '47138'
  },
  '47140': {
    country: 'US',
    city: 'Marengo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Crawford',
    latitude: 38.3736,
    longitude: -86.3578,
    postal_code: '47140'
  },
  '47141': {
    country: 'US',
    city: 'Marysville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.5856,
    longitude: -85.6436,
    postal_code: '47141'
  },
  '47142': {
    country: 'US',
    city: 'Mauckport',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.0437,
    longitude: -86.1846,
    postal_code: '47142'
  },
  '47143': {
    country: 'US',
    city: 'Memphis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.4642,
    longitude: -85.7775,
    postal_code: '47143'
  },
  '47144': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.2868,
    longitude: -85.7321,
    postal_code: '47144'
  },
  '47145': {
    country: 'US',
    city: 'Milltown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Crawford',
    latitude: 38.3445,
    longitude: -86.3003,
    postal_code: '47145'
  },
  '47146': {
    country: 'US',
    city: 'Mount Saint Francis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Floyd',
    latitude: 38.2985,
    longitude: -85.897,
    postal_code: '47146'
  },
  '47147': {
    country: 'US',
    city: 'Nabb',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.6128,
    longitude: -85.5217,
    postal_code: '47147'
  },
  '47150': {
    country: 'US',
    city: 'New Albany',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Floyd',
    latitude: 38.3089,
    longitude: -85.8221,
    postal_code: '47150'
  },
  '47151': {
    country: 'US',
    city: 'New Albany',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Floyd',
    latitude: 38.2856,
    longitude: -85.8241,
    postal_code: '47151'
  },
  '47160': {
    country: 'US',
    city: 'New Middletown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.1645,
    longitude: -86.051,
    postal_code: '47160'
  },
  '47161': {
    country: 'US',
    city: 'New Salisbury',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.3399,
    longitude: -86.0887,
    postal_code: '47161'
  },
  '47162': {
    country: 'US',
    city: 'New Washington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.5575,
    longitude: -85.4599,
    postal_code: '47162'
  },
  '47163': {
    country: 'US',
    city: 'Otisco',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.5423,
    longitude: -85.6647,
    postal_code: '47163'
  },
  '47164': {
    country: 'US',
    city: 'Palmyra',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.4105,
    longitude: -86.0888,
    postal_code: '47164'
  },
  '47165': {
    country: 'US',
    city: 'Pekin',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Washington',
    latitude: 38.4931,
    longitude: -86.017,
    postal_code: '47165'
  },
  '47166': {
    country: 'US',
    city: 'Ramsey',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Harrison',
    latitude: 38.3034,
    longitude: -86.169,
    postal_code: '47166'
  },
  '47167': {
    country: 'US',
    city: 'Salem',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Washington',
    latitude: 38.6071,
    longitude: -86.0787,
    postal_code: '47167'
  },
  '47170': {
    country: 'US',
    city: 'Scottsburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Scott',
    latitude: 38.6885,
    longitude: -85.7987,
    postal_code: '47170'
  },
  '47172': {
    country: 'US',
    city: 'Sellersburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.4046,
    longitude: -85.788,
    postal_code: '47172'
  },
  '47174': {
    country: 'US',
    city: 'Sulphur',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Crawford',
    latitude: 38.2259,
    longitude: -86.4908,
    postal_code: '47174'
  },
  '47175': {
    country: 'US',
    city: 'Taswell',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Crawford',
    latitude: 38.3463,
    longitude: -86.5387,
    postal_code: '47175'
  },
  '47177': {
    country: 'US',
    city: 'Underwood',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.6037,
    longitude: -85.7744,
    postal_code: '47177'
  },
  '47190': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.437,
    longitude: -85.705,
    postal_code: '47190'
  },
  '47199': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clark',
    latitude: 38.2868,
    longitude: -85.7321,
    postal_code: '47199'
  },
  '47201': {
    country: 'US',
    city: 'Columbus',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Bartholomew',
    latitude: 39.2055,
    longitude: -85.9317,
    postal_code: '47201'
  },
  '47202': {
    country: 'US',
    city: 'Columbus',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Bartholomew',
    latitude: 39.2014,
    longitude: -85.9214,
    postal_code: '47202'
  },
  '47203': {
    country: 'US',
    city: 'Columbus',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Bartholomew',
    latitude: 39.2301,
    longitude: -85.8855,
    postal_code: '47203'
  },
  '47220': {
    country: 'US',
    city: 'Brownstown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jackson',
    latitude: 38.8836,
    longitude: -86.0486,
    postal_code: '47220'
  },
  '47223': {
    country: 'US',
    city: 'Butlerville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jennings',
    latitude: 39.0491,
    longitude: -85.4789,
    postal_code: '47223'
  },
  '47224': {
    country: 'US',
    city: 'Canaan',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jefferson',
    latitude: 38.887,
    longitude: -85.2213,
    postal_code: '47224'
  },
  '47225': {
    country: 'US',
    city: 'Clarksburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Decatur',
    latitude: 39.4241,
    longitude: -85.3477,
    postal_code: '47225'
  },
  '47226': {
    country: 'US',
    city: 'Clifford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Bartholomew',
    latitude: 39.2826,
    longitude: -85.8685,
    postal_code: '47226'
  },
  '47227': {
    country: 'US',
    city: 'Commiskey',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jennings',
    latitude: 38.8526,
    longitude: -85.6434,
    postal_code: '47227'
  },
  '47228': {
    country: 'US',
    city: 'Cortland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jackson',
    latitude: 38.9745,
    longitude: -85.9628,
    postal_code: '47228'
  },
  '47229': {
    country: 'US',
    city: 'Crothersville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jackson',
    latitude: 38.8067,
    longitude: -85.847,
    postal_code: '47229'
  },
  '47230': {
    country: 'US',
    city: 'Deputy',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jefferson',
    latitude: 38.7759,
    longitude: -85.6304,
    postal_code: '47230'
  },
  '47231': {
    country: 'US',
    city: 'Dupont',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jefferson',
    latitude: 38.8905,
    longitude: -85.5092,
    postal_code: '47231'
  },
  '47232': {
    country: 'US',
    city: 'Elizabethtown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Bartholomew',
    latitude: 39.1243,
    longitude: -85.8153,
    postal_code: '47232'
  },
  '47234': {
    country: 'US',
    city: 'Flat Rock',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Shelby',
    latitude: 39.3774,
    longitude: -85.7679,
    postal_code: '47234'
  },
  '47235': {
    country: 'US',
    city: 'Freetown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jackson',
    latitude: 38.9957,
    longitude: -86.1241,
    postal_code: '47235'
  },
  '47236': {
    country: 'US',
    city: 'Grammer',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Bartholomew',
    latitude: 39.1522,
    longitude: -85.7261,
    postal_code: '47236'
  },
  '47240': {
    country: 'US',
    city: 'Greensburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Decatur',
    latitude: 39.2998,
    longitude: -85.4918,
    postal_code: '47240'
  },
  '47243': {
    country: 'US',
    city: 'Hanover',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jefferson',
    latitude: 38.7138,
    longitude: -85.4763,
    postal_code: '47243'
  },
  '47244': {
    country: 'US',
    city: 'Hartsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Bartholomew',
    latitude: 39.2676,
    longitude: -85.698,
    postal_code: '47244'
  },
  '47245': {
    country: 'US',
    city: 'Hayden',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jennings',
    latitude: 38.9831,
    longitude: -85.7405,
    postal_code: '47245'
  },
  '47246': {
    country: 'US',
    city: 'Hope',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Bartholomew',
    latitude: 39.3039,
    longitude: -85.7714,
    postal_code: '47246'
  },
  '47247': {
    country: 'US',
    city: 'Jonesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Bartholomew',
    latitude: 39.0593,
    longitude: -85.8886,
    postal_code: '47247'
  },
  '47249': {
    country: 'US',
    city: 'Kurtz',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jackson',
    latitude: 38.9606,
    longitude: -86.2033,
    postal_code: '47249'
  },
  '47250': {
    country: 'US',
    city: 'Madison',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jefferson',
    latitude: 38.7649,
    longitude: -85.407,
    postal_code: '47250'
  },
  '47260': {
    country: 'US',
    city: 'Medora',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jackson',
    latitude: 38.8255,
    longitude: -86.1897,
    postal_code: '47260'
  },
  '47261': {
    country: 'US',
    city: 'Millhousen',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Decatur',
    latitude: 39.292,
    longitude: -85.4918,
    postal_code: '47261'
  },
  '47263': {
    country: 'US',
    city: 'New Point',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Decatur',
    latitude: 39.3083,
    longitude: -85.3297,
    postal_code: '47263'
  },
  '47264': {
    country: 'US',
    city: 'Norman',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jackson',
    latitude: 38.9526,
    longitude: -86.275,
    postal_code: '47264'
  },
  '47265': {
    country: 'US',
    city: 'North Vernon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jennings',
    latitude: 39.0018,
    longitude: -85.6272,
    postal_code: '47265'
  },
  '47270': {
    country: 'US',
    city: 'Paris Crossing',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jennings',
    latitude: 38.8558,
    longitude: -85.7487,
    postal_code: '47270'
  },
  '47272': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Decatur',
    latitude: 39.4277,
    longitude: -85.5994,
    postal_code: '47272'
  },
  '47273': {
    country: 'US',
    city: 'Scipio',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jennings',
    latitude: 39.0665,
    longitude: -85.7129,
    postal_code: '47273'
  },
  '47274': {
    country: 'US',
    city: 'Seymour',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jackson',
    latitude: 38.9571,
    longitude: -85.8825,
    postal_code: '47274'
  },
  '47280': {
    country: 'US',
    city: 'Taylorsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Bartholomew',
    latitude: 39.2953,
    longitude: -85.9498,
    postal_code: '47280'
  },
  '47281': {
    country: 'US',
    city: 'Vallonia',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jackson',
    latitude: 38.8174,
    longitude: -86.069,
    postal_code: '47281'
  },
  '47282': {
    country: 'US',
    city: 'Vernon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jennings',
    latitude: 38.9848,
    longitude: -85.6094,
    postal_code: '47282'
  },
  '47283': {
    country: 'US',
    city: 'Westport',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Decatur',
    latitude: 39.1749,
    longitude: -85.5856,
    postal_code: '47283'
  },
  '47302': {
    country: 'US',
    city: 'Muncie',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.1684,
    longitude: -85.3807,
    postal_code: '47302'
  },
  '47303': {
    country: 'US',
    city: 'Muncie',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.218,
    longitude: -85.379,
    postal_code: '47303'
  },
  '47304': {
    country: 'US',
    city: 'Muncie',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.2111,
    longitude: -85.4291,
    postal_code: '47304'
  },
  '47305': {
    country: 'US',
    city: 'Muncie',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.1933,
    longitude: -85.3862,
    postal_code: '47305'
  },
  '47306': {
    country: 'US',
    city: 'Muncie',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.2023,
    longitude: -85.4082,
    postal_code: '47306'
  },
  '47307': {
    country: 'US',
    city: 'Muncie',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.1621,
    longitude: -85.4428,
    postal_code: '47307'
  },
  '47308': {
    country: 'US',
    city: 'Muncie',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.2279,
    longitude: -85.3967,
    postal_code: '47308'
  },
  '47320': {
    country: 'US',
    city: 'Albany',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.292,
    longitude: -85.258,
    postal_code: '47320'
  },
  '47322': {
    country: 'US',
    city: 'Bentonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fayette',
    latitude: 39.657,
    longitude: -85.168,
    postal_code: '47322'
  },
  '47324': {
    country: 'US',
    city: 'Boston',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.7412,
    longitude: -84.8519,
    postal_code: '47324'
  },
  '47325': {
    country: 'US',
    city: 'Brownsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Union',
    latitude: 39.6845,
    longitude: -84.9881,
    postal_code: '47325'
  },
  '47326': {
    country: 'US',
    city: 'Bryant',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jay',
    latitude: 40.5446,
    longitude: -84.9117,
    postal_code: '47326'
  },
  '47327': {
    country: 'US',
    city: 'Cambridge City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.8182,
    longitude: -85.1685,
    postal_code: '47327'
  },
  '47330': {
    country: 'US',
    city: 'Centerville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.8081,
    longitude: -85.0032,
    postal_code: '47330'
  },
  '47331': {
    country: 'US',
    city: 'Connersville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fayette',
    latitude: 39.6435,
    longitude: -85.1464,
    postal_code: '47331'
  },
  '47334': {
    country: 'US',
    city: 'Daleville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.1257,
    longitude: -85.511,
    postal_code: '47334'
  },
  '47335': {
    country: 'US',
    city: 'Dublin',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.8127,
    longitude: -85.2044,
    postal_code: '47335'
  },
  '47336': {
    country: 'US',
    city: 'Dunkirk',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jay',
    latitude: 40.3883,
    longitude: -85.2255,
    postal_code: '47336'
  },
  '47337': {
    country: 'US',
    city: 'Dunreith',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.8031,
    longitude: -85.4371,
    postal_code: '47337'
  },
  '47338': {
    country: 'US',
    city: 'Eaton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.3377,
    longitude: -85.3544,
    postal_code: '47338'
  },
  '47339': {
    country: 'US',
    city: 'Economy',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.9781,
    longitude: -85.088,
    postal_code: '47339'
  },
  '47340': {
    country: 'US',
    city: 'Farmland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Randolph',
    latitude: 40.1945,
    longitude: -85.1254,
    postal_code: '47340'
  },
  '47341': {
    country: 'US',
    city: 'Fountain City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.9634,
    longitude: -84.909,
    postal_code: '47341'
  },
  '47342': {
    country: 'US',
    city: 'Gaston',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.2948,
    longitude: -85.4898,
    postal_code: '47342'
  },
  '47344': {
    country: 'US',
    city: 'Greensboro',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.8789,
    longitude: -85.464,
    postal_code: '47344'
  },
  '47345': {
    country: 'US',
    city: 'Greens Fork',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.8916,
    longitude: -85.0494,
    postal_code: '47345'
  },
  '47346': {
    country: 'US',
    city: 'Hagerstown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.9228,
    longitude: -85.1601,
    postal_code: '47346'
  },
  '47348': {
    country: 'US',
    city: 'Hartford City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Blackford',
    latitude: 40.4541,
    longitude: -85.3758,
    postal_code: '47348'
  },
  '47351': {
    country: 'US',
    city: 'Kennard',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.904,
    longitude: -85.5205,
    postal_code: '47351'
  },
  '47352': {
    country: 'US',
    city: 'Lewisville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.8283,
    longitude: -85.3621,
    postal_code: '47352'
  },
  '47353': {
    country: 'US',
    city: 'Liberty',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Union',
    latitude: 39.6126,
    longitude: -84.9089,
    postal_code: '47353'
  },
  '47354': {
    country: 'US',
    city: 'Losantville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Randolph',
    latitude: 40.0475,
    longitude: -85.2108,
    postal_code: '47354'
  },
  '47355': {
    country: 'US',
    city: 'Lynn',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Randolph',
    latitude: 40.0519,
    longitude: -84.93,
    postal_code: '47355'
  },
  '47356': {
    country: 'US',
    city: 'Middletown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 40.0475,
    longitude: -85.5368,
    postal_code: '47356'
  },
  '47357': {
    country: 'US',
    city: 'Milton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.7763,
    longitude: -85.1423,
    postal_code: '47357'
  },
  '47358': {
    country: 'US',
    city: 'Modoc',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Randolph',
    latitude: 40.0582,
    longitude: -85.0919,
    postal_code: '47358'
  },
  '47359': {
    country: 'US',
    city: 'Montpelier',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Blackford',
    latitude: 40.5577,
    longitude: -85.2513,
    postal_code: '47359'
  },
  '47360': {
    country: 'US',
    city: 'Mooreland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.9941,
    longitude: -85.2581,
    postal_code: '47360'
  },
  '47361': {
    country: 'US',
    city: 'Mount Summit',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 40.0042,
    longitude: -85.3851,
    postal_code: '47361'
  },
  '47362': {
    country: 'US',
    city: 'New Castle',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.9208,
    longitude: -85.3663,
    postal_code: '47362'
  },
  '47366': {
    country: 'US',
    city: 'New Lisbon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.8634,
    longitude: -85.263,
    postal_code: '47366'
  },
  '47367': {
    country: 'US',
    city: 'Oakville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.0792,
    longitude: -85.39,
    postal_code: '47367'
  },
  '47368': {
    country: 'US',
    city: 'Parker City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Randolph',
    latitude: 40.1938,
    longitude: -85.1963,
    postal_code: '47368'
  },
  '47369': {
    country: 'US',
    city: 'Pennville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jay',
    latitude: 40.5082,
    longitude: -85.1492,
    postal_code: '47369'
  },
  '47370': {
    country: 'US',
    city: 'Pershing',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.8613,
    longitude: -85.1474,
    postal_code: '47370'
  },
  '47371': {
    country: 'US',
    city: 'Portland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jay',
    latitude: 40.4306,
    longitude: -84.9928,
    postal_code: '47371'
  },
  '47373': {
    country: 'US',
    city: 'Redkey',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jay',
    latitude: 40.3265,
    longitude: -85.162,
    postal_code: '47373'
  },
  '47374': {
    country: 'US',
    city: 'Richmond',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.8324,
    longitude: -84.8936,
    postal_code: '47374'
  },
  '47375': {
    country: 'US',
    city: 'Richmond',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.8289,
    longitude: -84.8902,
    postal_code: '47375'
  },
  '47380': {
    country: 'US',
    city: 'Ridgeville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Randolph',
    latitude: 40.2804,
    longitude: -85.0371,
    postal_code: '47380'
  },
  '47381': {
    country: 'US',
    city: 'Salamonia',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jay',
    latitude: 40.3818,
    longitude: -84.8669,
    postal_code: '47381'
  },
  '47382': {
    country: 'US',
    city: 'Saratoga',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Randolph',
    latitude: 40.2347,
    longitude: -84.9185,
    postal_code: '47382'
  },
  '47383': {
    country: 'US',
    city: 'Selma',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.1693,
    longitude: -85.2738,
    postal_code: '47383'
  },
  '47384': {
    country: 'US',
    city: 'Shirley',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.9158,
    longitude: -85.5182,
    postal_code: '47384'
  },
  '47385': {
    country: 'US',
    city: 'Spiceland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.8271,
    longitude: -85.445,
    postal_code: '47385'
  },
  '47386': {
    country: 'US',
    city: 'Springport',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 40.0527,
    longitude: -85.3833,
    postal_code: '47386'
  },
  '47387': {
    country: 'US',
    city: 'Straughn',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 39.8319,
    longitude: -85.2724,
    postal_code: '47387'
  },
  '47388': {
    country: 'US',
    city: 'Sulphur Springs',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Henry',
    latitude: 40.006,
    longitude: -85.4434,
    postal_code: '47388'
  },
  '47390': {
    country: 'US',
    city: 'Union City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Randolph',
    latitude: 40.2024,
    longitude: -84.8268,
    postal_code: '47390'
  },
  '47392': {
    country: 'US',
    city: 'Webster',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.9119,
    longitude: -84.9351,
    postal_code: '47392'
  },
  '47393': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Wayne',
    latitude: 39.958,
    longitude: -84.9984,
    postal_code: '47393'
  },
  '47394': {
    country: 'US',
    city: 'Winchester',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Randolph',
    latitude: 40.1696,
    longitude: -85.0044,
    postal_code: '47394'
  },
  '47396': {
    country: 'US',
    city: 'Yorktown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Delaware',
    latitude: 40.1836,
    longitude: -85.496,
    postal_code: '47396'
  },
  '47401': {
    country: 'US',
    city: 'Bloomington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.1401,
    longitude: -86.5083,
    postal_code: '47401'
  },
  '47402': {
    country: 'US',
    city: 'Bloomington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.1732,
    longitude: -86.5015,
    postal_code: '47402'
  },
  '47403': {
    country: 'US',
    city: 'Bloomington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.1263,
    longitude: -86.5769,
    postal_code: '47403'
  },
  '47404': {
    country: 'US',
    city: 'Bloomington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.195,
    longitude: -86.5757,
    postal_code: '47404'
  },
  '47405': {
    country: 'US',
    city: 'Bloomington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.1682,
    longitude: -86.5186,
    postal_code: '47405'
  },
  '47406': {
    country: 'US',
    city: 'Bloomington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.1745,
    longitude: -86.5183,
    postal_code: '47406'
  },
  '47407': {
    country: 'US',
    city: 'Bloomington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.1732,
    longitude: -86.5015,
    postal_code: '47407'
  },
  '47408': {
    country: 'US',
    city: 'Bloomington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.2303,
    longitude: -86.4692,
    postal_code: '47408'
  },
  '47420': {
    country: 'US',
    city: 'Avoca',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lawrence',
    latitude: 38.9132,
    longitude: -86.5489,
    postal_code: '47420'
  },
  '47421': {
    country: 'US',
    city: 'Bedford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lawrence',
    latitude: 38.8729,
    longitude: -86.4871,
    postal_code: '47421'
  },
  '47424': {
    country: 'US',
    city: 'Bloomfield',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 39.0295,
    longitude: -86.8675,
    postal_code: '47424'
  },
  '47426': {
    country: 'US',
    city: 'Clear Creek',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.1732,
    longitude: -86.5015,
    postal_code: '47426'
  },
  '47427': {
    country: 'US',
    city: 'Coal City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Owen',
    latitude: 39.2303,
    longitude: -87.0458,
    postal_code: '47427'
  },
  '47429': {
    country: 'US',
    city: 'Ellettsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.2545,
    longitude: -86.6196,
    postal_code: '47429'
  },
  '47431': {
    country: 'US',
    city: 'Freedom',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Owen',
    latitude: 39.2151,
    longitude: -86.85,
    postal_code: '47431'
  },
  '47432': {
    country: 'US',
    city: 'French Lick',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Orange',
    latitude: 38.5324,
    longitude: -86.6196,
    postal_code: '47432'
  },
  '47433': {
    country: 'US',
    city: 'Gosport',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Owen',
    latitude: 39.345,
    longitude: -86.6583,
    postal_code: '47433'
  },
  '47434': {
    country: 'US',
    city: 'Harrodsburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.013,
    longitude: -86.5457,
    postal_code: '47434'
  },
  '47435': {
    country: 'US',
    city: 'Helmsburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Brown',
    latitude: 39.1963,
    longitude: -86.2302,
    postal_code: '47435'
  },
  '47436': {
    country: 'US',
    city: 'Heltonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lawrence',
    latitude: 38.948,
    longitude: -86.3703,
    postal_code: '47436'
  },
  '47437': {
    country: 'US',
    city: 'Huron',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lawrence',
    latitude: 38.7222,
    longitude: -86.671,
    postal_code: '47437'
  },
  '47438': {
    country: 'US',
    city: 'Jasonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 39.1723,
    longitude: -87.2023,
    postal_code: '47438'
  },
  '47439': {
    country: 'US',
    city: 'Koleen',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 39.0371,
    longitude: -86.9616,
    postal_code: '47439'
  },
  '47441': {
    country: 'US',
    city: 'Linton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 39.0461,
    longitude: -87.1723,
    postal_code: '47441'
  },
  '47443': {
    country: 'US',
    city: 'Lyons',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 38.9717,
    longitude: -87.1016,
    postal_code: '47443'
  },
  '47445': {
    country: 'US',
    city: 'Midland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 39.122,
    longitude: -87.1917,
    postal_code: '47445'
  },
  '47446': {
    country: 'US',
    city: 'Mitchell',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lawrence',
    latitude: 38.7426,
    longitude: -86.4761,
    postal_code: '47446'
  },
  '47448': {
    country: 'US',
    city: 'Nashville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Brown',
    latitude: 39.2367,
    longitude: -86.222,
    postal_code: '47448'
  },
  '47449': {
    country: 'US',
    city: 'Newberry',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 38.9229,
    longitude: -87.0081,
    postal_code: '47449'
  },
  '47451': {
    country: 'US',
    city: 'Oolitic',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lawrence',
    latitude: 38.8938,
    longitude: -86.5246,
    postal_code: '47451'
  },
  '47452': {
    country: 'US',
    city: 'Orleans',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Orange',
    latitude: 38.6535,
    longitude: -86.4532,
    postal_code: '47452'
  },
  '47453': {
    country: 'US',
    city: 'Owensburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 38.9253,
    longitude: -86.7742,
    postal_code: '47453'
  },
  '47454': {
    country: 'US',
    city: 'Paoli',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Orange',
    latitude: 38.5507,
    longitude: -86.449,
    postal_code: '47454'
  },
  '47455': {
    country: 'US',
    city: 'Patricksburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Owen',
    latitude: 39.3165,
    longitude: -86.9562,
    postal_code: '47455'
  },
  '47456': {
    country: 'US',
    city: 'Quincy',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Owen',
    latitude: 39.4429,
    longitude: -86.6696,
    postal_code: '47456'
  },
  '47457': {
    country: 'US',
    city: 'Scotland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 38.9128,
    longitude: -86.9039,
    postal_code: '47457'
  },
  '47458': {
    country: 'US',
    city: 'Smithville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.0712,
    longitude: -86.5069,
    postal_code: '47458'
  },
  '47459': {
    country: 'US',
    city: 'Solsberry',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 39.119,
    longitude: -86.7378,
    postal_code: '47459'
  },
  '47460': {
    country: 'US',
    city: 'Spencer',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Owen',
    latitude: 39.2891,
    longitude: -86.7789,
    postal_code: '47460'
  },
  '47462': {
    country: 'US',
    city: 'Springville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lawrence',
    latitude: 38.9507,
    longitude: -86.6139,
    postal_code: '47462'
  },
  '47463': {
    country: 'US',
    city: 'Stanford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.0859,
    longitude: -86.6689,
    postal_code: '47463'
  },
  '47464': {
    country: 'US',
    city: 'Stinesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.2998,
    longitude: -86.6482,
    postal_code: '47464'
  },
  '47465': {
    country: 'US',
    city: 'Switz City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 39.0369,
    longitude: -87.0502,
    postal_code: '47465'
  },
  '47467': {
    country: 'US',
    city: 'Tunnelton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lawrence',
    latitude: 38.7687,
    longitude: -86.3428,
    postal_code: '47467'
  },
  '47468': {
    country: 'US',
    city: 'Unionville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Monroe',
    latitude: 39.2514,
    longitude: -86.4189,
    postal_code: '47468'
  },
  '47469': {
    country: 'US',
    city: 'West Baden Springs',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Orange',
    latitude: 38.6208,
    longitude: -86.5899,
    postal_code: '47469'
  },
  '47470': {
    country: 'US',
    city: 'Williams',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Lawrence',
    latitude: 38.7735,
    longitude: -86.6285,
    postal_code: '47470'
  },
  '47471': {
    country: 'US',
    city: 'Worthington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Greene',
    latitude: 39.123,
    longitude: -86.9991,
    postal_code: '47471'
  },
  '47501': {
    country: 'US',
    city: 'Washington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Daviess',
    latitude: 38.6536,
    longitude: -87.1707,
    postal_code: '47501'
  },
  '47512': {
    country: 'US',
    city: 'Bicknell',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.7727,
    longitude: -87.3137,
    postal_code: '47512'
  },
  '47513': {
    country: 'US',
    city: 'Birdseye',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.3006,
    longitude: -86.7102,
    postal_code: '47513'
  },
  '47514': {
    country: 'US',
    city: 'Branchville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Perry',
    latitude: 38.1572,
    longitude: -86.5859,
    postal_code: '47514'
  },
  '47515': {
    country: 'US',
    city: 'Bristow',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Perry',
    latitude: 38.1981,
    longitude: -86.6821,
    postal_code: '47515'
  },
  '47516': {
    country: 'US',
    city: 'Bruceville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.7563,
    longitude: -87.4313,
    postal_code: '47516'
  },
  '47519': {
    country: 'US',
    city: 'Cannelburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Daviess',
    latitude: 38.6698,
    longitude: -86.9983,
    postal_code: '47519'
  },
  '47520': {
    country: 'US',
    city: 'Cannelton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Perry',
    latitude: 37.9495,
    longitude: -86.7156,
    postal_code: '47520'
  },
  '47521': {
    country: 'US',
    city: 'Celestine',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.3878,
    longitude: -86.7568,
    postal_code: '47521'
  },
  '47522': {
    country: 'US',
    city: 'Crane',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Martin',
    latitude: 38.8949,
    longitude: -86.9002,
    postal_code: '47522'
  },
  '47523': {
    country: 'US',
    city: 'Dale',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.1706,
    longitude: -87.007,
    postal_code: '47523'
  },
  '47524': {
    country: 'US',
    city: 'Decker',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.5076,
    longitude: -87.5537,
    postal_code: '47524'
  },
  '47525': {
    country: 'US',
    city: 'Derby',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Perry',
    latitude: 38.0239,
    longitude: -86.577,
    postal_code: '47525'
  },
  '47527': {
    country: 'US',
    city: 'Dubois',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.4728,
    longitude: -86.7832,
    postal_code: '47527'
  },
  '47528': {
    country: 'US',
    city: 'Edwardsport',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.8106,
    longitude: -87.2519,
    postal_code: '47528'
  },
  '47529': {
    country: 'US',
    city: 'Elnora',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Daviess',
    latitude: 38.8772,
    longitude: -87.085,
    postal_code: '47529'
  },
  '47531': {
    country: 'US',
    city: 'Evanston',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.0224,
    longitude: -86.8364,
    postal_code: '47531'
  },
  '47532': {
    country: 'US',
    city: 'Ferdinand',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.2336,
    longitude: -86.8607,
    postal_code: '47532'
  },
  '47535': {
    country: 'US',
    city: 'Freelandville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.8627,
    longitude: -87.3127,
    postal_code: '47535'
  },
  '47536': {
    country: 'US',
    city: 'Fulda',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.1114,
    longitude: -86.8361,
    postal_code: '47536'
  },
  '47537': {
    country: 'US',
    city: 'Gentryville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.0855,
    longitude: -87.0441,
    postal_code: '47537'
  },
  '47541': {
    country: 'US',
    city: 'Holland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.2456,
    longitude: -87.0361,
    postal_code: '47541'
  },
  '47542': {
    country: 'US',
    city: 'Huntingburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.2979,
    longitude: -86.9533,
    postal_code: '47542'
  },
  '47545': {
    country: 'US',
    city: 'Ireland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.4137,
    longitude: -87.0009,
    postal_code: '47545'
  },
  '47546': {
    country: 'US',
    city: 'Jasper',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.3604,
    longitude: -86.9295,
    postal_code: '47546'
  },
  '47547': {
    country: 'US',
    city: 'Jasper',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.3647,
    longitude: -86.8762,
    postal_code: '47547'
  },
  '47549': {
    country: 'US',
    city: 'Jasper',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.3647,
    longitude: -86.8762,
    postal_code: '47549'
  },
  '47550': {
    country: 'US',
    city: 'Lamar',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.0429,
    longitude: -86.9497,
    postal_code: '47550'
  },
  '47551': {
    country: 'US',
    city: 'Leopold',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Perry',
    latitude: 38.1011,
    longitude: -86.6044,
    postal_code: '47551'
  },
  '47552': {
    country: 'US',
    city: 'Lincoln City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.1242,
    longitude: -86.996,
    postal_code: '47552'
  },
  '47553': {
    country: 'US',
    city: 'Loogootee',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Martin',
    latitude: 38.6629,
    longitude: -86.9137,
    postal_code: '47553'
  },
  '47556': {
    country: 'US',
    city: 'Mariah Hill',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.1664,
    longitude: -86.9172,
    postal_code: '47556'
  },
  '47557': {
    country: 'US',
    city: 'Monroe City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.6001,
    longitude: -87.3641,
    postal_code: '47557'
  },
  '47558': {
    country: 'US',
    city: 'Montgomery',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Daviess',
    latitude: 38.6521,
    longitude: -87.0476,
    postal_code: '47558'
  },
  '47561': {
    country: 'US',
    city: 'Oaktown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.8579,
    longitude: -87.3879,
    postal_code: '47561'
  },
  '47562': {
    country: 'US',
    city: 'Odon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Daviess',
    latitude: 38.8187,
    longitude: -86.9752,
    postal_code: '47562'
  },
  '47564': {
    country: 'US',
    city: 'Otwell',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pike',
    latitude: 38.4662,
    longitude: -87.0985,
    postal_code: '47564'
  },
  '47567': {
    country: 'US',
    city: 'Petersburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pike',
    latitude: 38.4789,
    longitude: -87.2883,
    postal_code: '47567'
  },
  '47568': {
    country: 'US',
    city: 'Plainville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Daviess',
    latitude: 38.8062,
    longitude: -87.1522,
    postal_code: '47568'
  },
  '47573': {
    country: 'US',
    city: 'Ragsdale',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.7441,
    longitude: -87.3214,
    postal_code: '47573'
  },
  '47574': {
    country: 'US',
    city: 'Rome',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Perry',
    latitude: 37.9372,
    longitude: -86.534,
    postal_code: '47574'
  },
  '47575': {
    country: 'US',
    city: 'Saint Anthony',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.3211,
    longitude: -86.8234,
    postal_code: '47575'
  },
  '47576': {
    country: 'US',
    city: 'Saint Croix',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Perry',
    latitude: 38.1834,
    longitude: -86.6058,
    postal_code: '47576'
  },
  '47577': {
    country: 'US',
    city: 'Saint Meinrad',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.1712,
    longitude: -86.8092,
    postal_code: '47577'
  },
  '47578': {
    country: 'US',
    city: 'Sandborn',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.8817,
    longitude: -87.2025,
    postal_code: '47578'
  },
  '47579': {
    country: 'US',
    city: 'Santa Claus',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.1176,
    longitude: -86.9286,
    postal_code: '47579'
  },
  '47580': {
    country: 'US',
    city: 'Schnellville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Dubois',
    latitude: 38.3414,
    longitude: -86.7565,
    postal_code: '47580'
  },
  '47581': {
    country: 'US',
    city: 'Shoals',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Martin',
    latitude: 38.6791,
    longitude: -86.7761,
    postal_code: '47581'
  },
  '47584': {
    country: 'US',
    city: 'Spurgeon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pike',
    latitude: 38.2523,
    longitude: -87.2572,
    postal_code: '47584'
  },
  '47585': {
    country: 'US',
    city: 'Stendal',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pike',
    latitude: 38.2835,
    longitude: -87.1205,
    postal_code: '47585'
  },
  '47586': {
    country: 'US',
    city: 'Tell City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Perry',
    latitude: 37.9655,
    longitude: -86.7457,
    postal_code: '47586'
  },
  '47588': {
    country: 'US',
    city: 'Troy',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.0331,
    longitude: -86.7981,
    postal_code: '47588'
  },
  '47590': {
    country: 'US',
    city: 'Velpen',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pike',
    latitude: 38.368,
    longitude: -87.099,
    postal_code: '47590'
  },
  '47591': {
    country: 'US',
    city: 'Vincennes',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.6734,
    longitude: -87.5098,
    postal_code: '47591'
  },
  '47596': {
    country: 'US',
    city: 'Westphalia',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.8628,
    longitude: -87.2256,
    postal_code: '47596'
  },
  '47597': {
    country: 'US',
    city: 'Wheatland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Knox',
    latitude: 38.6671,
    longitude: -87.3062,
    postal_code: '47597'
  },
  '47598': {
    country: 'US',
    city: 'Winslow',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pike',
    latitude: 38.364,
    longitude: -87.2223,
    postal_code: '47598'
  },
  '47601': {
    country: 'US',
    city: 'Boonville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warrick',
    latitude: 38.0474,
    longitude: -87.262,
    postal_code: '47601'
  },
  '47610': {
    country: 'US',
    city: 'Chandler',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warrick',
    latitude: 38.0423,
    longitude: -87.3756,
    postal_code: '47610'
  },
  '47611': {
    country: 'US',
    city: 'Chrisney',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 38.0148,
    longitude: -87.0364,
    postal_code: '47611'
  },
  '47612': {
    country: 'US',
    city: 'Cynthiana',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Posey',
    latitude: 38.193,
    longitude: -87.6992,
    postal_code: '47612'
  },
  '47613': {
    country: 'US',
    city: 'Elberfeld',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warrick',
    latitude: 38.2053,
    longitude: -87.4179,
    postal_code: '47613'
  },
  '47615': {
    country: 'US',
    city: 'Grandview',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 37.9703,
    longitude: -86.9568,
    postal_code: '47615'
  },
  '47616': {
    country: 'US',
    city: 'Griffin',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Posey',
    latitude: 38.2069,
    longitude: -87.9166,
    postal_code: '47616'
  },
  '47617': {
    country: 'US',
    city: 'Hatfield',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 37.9036,
    longitude: -87.2499,
    postal_code: '47617'
  },
  '47618': {
    country: 'US',
    city: 'Inglefield',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 38.1081,
    longitude: -87.5589,
    postal_code: '47618'
  },
  '47619': {
    country: 'US',
    city: 'Lynnville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warrick',
    latitude: 38.1961,
    longitude: -87.2935,
    postal_code: '47619'
  },
  '47620': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Posey',
    latitude: 37.9506,
    longitude: -87.8569,
    postal_code: '47620'
  },
  '47629': {
    country: 'US',
    city: 'Newburgh',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warrick',
    latitude: 38.0624,
    longitude: -87.2452,
    postal_code: '47629'
  },
  '47630': {
    country: 'US',
    city: 'Newburgh',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warrick',
    latitude: 37.9637,
    longitude: -87.3938,
    postal_code: '47630'
  },
  '47631': {
    country: 'US',
    city: 'New Harmony',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Posey',
    latitude: 38.1245,
    longitude: -87.9172,
    postal_code: '47631'
  },
  '47633': {
    country: 'US',
    city: 'Poseyville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Posey',
    latitude: 38.172,
    longitude: -87.8027,
    postal_code: '47633'
  },
  '47634': {
    country: 'US',
    city: 'Richland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 37.9136,
    longitude: -87.201,
    postal_code: '47634'
  },
  '47635': {
    country: 'US',
    city: 'Rockport',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Spencer',
    latitude: 37.8858,
    longitude: -87.077,
    postal_code: '47635'
  },
  '47637': {
    country: 'US',
    city: 'Tennyson',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warrick',
    latitude: 38.1232,
    longitude: -87.1388,
    postal_code: '47637'
  },
  '47638': {
    country: 'US',
    city: 'Wadesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Posey',
    latitude: 38.0828,
    longitude: -87.7543,
    postal_code: '47638'
  },
  '47639': {
    country: 'US',
    city: 'Haubstadt',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.1895,
    longitude: -87.5798,
    postal_code: '47639'
  },
  '47640': {
    country: 'US',
    city: 'Hazleton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.4623,
    longitude: -87.4983,
    postal_code: '47640'
  },
  '47647': {
    country: 'US',
    city: 'Buckskin',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.2284,
    longitude: -87.412,
    postal_code: '47647'
  },
  '47648': {
    country: 'US',
    city: 'Fort Branch',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.2471,
    longitude: -87.5682,
    postal_code: '47648'
  },
  '47649': {
    country: 'US',
    city: 'Francisco',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.333,
    longitude: -87.4536,
    postal_code: '47649'
  },
  '47654': {
    country: 'US',
    city: 'Mackey',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.2523,
    longitude: -87.3917,
    postal_code: '47654'
  },
  '47660': {
    country: 'US',
    city: 'Oakland City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.3361,
    longitude: -87.3519,
    postal_code: '47660'
  },
  '47665': {
    country: 'US',
    city: 'Owensville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.2744,
    longitude: -87.7091,
    postal_code: '47665'
  },
  '47666': {
    country: 'US',
    city: 'Patoka',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.4143,
    longitude: -87.5958,
    postal_code: '47666'
  },
  '47670': {
    country: 'US',
    city: 'Princeton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.3525,
    longitude: -87.5691,
    postal_code: '47670'
  },
  '47683': {
    country: 'US',
    city: 'Somerville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Gibson',
    latitude: 38.2764,
    longitude: -87.3778,
    postal_code: '47683'
  },
  '47701': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47701'
  },
  '47702': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47702'
  },
  '47703': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47703'
  },
  '47704': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47704'
  },
  '47705': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47705'
  },
  '47706': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47706'
  },
  '47708': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9718,
    longitude: -87.572,
    postal_code: '47708'
  },
  '47710': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 38.0086,
    longitude: -87.5746,
    postal_code: '47710'
  },
  '47711': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 38.0617,
    longitude: -87.5548,
    postal_code: '47711'
  },
  '47712': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.929,
    longitude: -87.6604,
    postal_code: '47712'
  },
  '47713': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9623,
    longitude: -87.5577,
    postal_code: '47713'
  },
  '47714': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9591,
    longitude: -87.5293,
    postal_code: '47714'
  },
  '47715': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9678,
    longitude: -87.4855,
    postal_code: '47715'
  },
  '47716': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47716'
  },
  '47719': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47719'
  },
  '47720': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 38.0599,
    longitude: -87.638,
    postal_code: '47720'
  },
  '47721': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.978,
    longitude: -87.6008,
    postal_code: '47721'
  },
  '47722': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9702,
    longitude: -87.542,
    postal_code: '47722'
  },
  '47724': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47724'
  },
  '47725': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 38.1071,
    longitude: -87.5256,
    postal_code: '47725'
  },
  '47728': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47728'
  },
  '47730': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47730'
  },
  '47731': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47731'
  },
  '47732': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47732'
  },
  '47733': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47733'
  },
  '47734': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47734'
  },
  '47735': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47735'
  },
  '47736': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47736'
  },
  '47737': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47737'
  },
  '47740': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47740'
  },
  '47747': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9971,
    longitude: -87.575,
    postal_code: '47747'
  },
  '47750': {
    country: 'US',
    city: 'Evansville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vanderburgh',
    latitude: 37.9623,
    longitude: -87.5055,
    postal_code: '47750'
  },
  '47801': {
    country: 'US',
    city: 'Terre Haute',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.4667,
    longitude: -87.4139,
    postal_code: '47801'
  },
  '47802': {
    country: 'US',
    city: 'Terre Haute',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.407,
    longitude: -87.402,
    postal_code: '47802'
  },
  '47803': {
    country: 'US',
    city: 'Terre Haute',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.4657,
    longitude: -87.354,
    postal_code: '47803'
  },
  '47804': {
    country: 'US',
    city: 'Terre Haute',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.4937,
    longitude: -87.3945,
    postal_code: '47804'
  },
  '47805': {
    country: 'US',
    city: 'Terre Haute',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.5327,
    longitude: -87.3255,
    postal_code: '47805'
  },
  '47807': {
    country: 'US',
    city: 'Terre Haute',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.471,
    longitude: -87.4009,
    postal_code: '47807'
  },
  '47808': {
    country: 'US',
    city: 'Terre Haute',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.4336,
    longitude: -87.4101,
    postal_code: '47808'
  },
  '47809': {
    country: 'US',
    city: 'Terre Haute',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.471,
    longitude: -87.4111,
    postal_code: '47809'
  },
  '47830': {
    country: 'US',
    city: 'Bellmore',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Parke',
    latitude: 39.7791,
    longitude: -87.2218,
    postal_code: '47830'
  },
  '47831': {
    country: 'US',
    city: 'Blanford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vermillion',
    latitude: 39.665,
    longitude: -87.5206,
    postal_code: '47831'
  },
  '47832': {
    country: 'US',
    city: 'Bloomingdale',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Parke',
    latitude: 39.8348,
    longitude: -87.256,
    postal_code: '47832'
  },
  '47833': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.3812,
    longitude: -87.0052,
    postal_code: '47833'
  },
  '47834': {
    country: 'US',
    city: 'Brazil',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.521,
    longitude: -87.1278,
    postal_code: '47834'
  },
  '47836': {
    country: 'US',
    city: 'Bridgeton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Parke',
    latitude: 39.645,
    longitude: -87.1775,
    postal_code: '47836'
  },
  '47837': {
    country: 'US',
    city: 'Carbon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.5913,
    longitude: -87.1132,
    postal_code: '47837'
  },
  '47838': {
    country: 'US',
    city: 'Carlisle',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Sullivan',
    latitude: 38.9763,
    longitude: -87.3667,
    postal_code: '47838'
  },
  '47840': {
    country: 'US',
    city: 'Centerpoint',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.391,
    longitude: -87.0935,
    postal_code: '47840'
  },
  '47841': {
    country: 'US',
    city: 'Clay City',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.2727,
    longitude: -87.1082,
    postal_code: '47841'
  },
  '47842': {
    country: 'US',
    city: 'Clinton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vermillion',
    latitude: 39.6591,
    longitude: -87.4208,
    postal_code: '47842'
  },
  '47845': {
    country: 'US',
    city: 'Coalmont',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.1934,
    longitude: -87.2311,
    postal_code: '47845'
  },
  '47846': {
    country: 'US',
    city: 'Cory',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.3435,
    longitude: -87.1956,
    postal_code: '47846'
  },
  '47847': {
    country: 'US',
    city: 'Dana',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vermillion',
    latitude: 39.839,
    longitude: -87.4712,
    postal_code: '47847'
  },
  '47848': {
    country: 'US',
    city: 'Dugger',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Sullivan',
    latitude: 39.0691,
    longitude: -87.2599,
    postal_code: '47848'
  },
  '47849': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Sullivan',
    latitude: 39.1901,
    longitude: -87.547,
    postal_code: '47849'
  },
  '47850': {
    country: 'US',
    city: 'Farmersburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Sullivan',
    latitude: 39.2685,
    longitude: -87.5024,
    postal_code: '47850'
  },
  '47851': {
    country: 'US',
    city: 'Fontanet',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.5737,
    longitude: -87.2454,
    postal_code: '47851'
  },
  '47852': {
    country: 'US',
    city: 'Graysville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Sullivan',
    latitude: 39.0805,
    longitude: -87.4496,
    postal_code: '47852'
  },
  '47853': {
    country: 'US',
    city: 'Harmony',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.5372,
    longitude: -87.0729,
    postal_code: '47853'
  },
  '47854': {
    country: 'US',
    city: 'Hillsdale',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vermillion',
    latitude: 39.7712,
    longitude: -87.4041,
    postal_code: '47854'
  },
  '47855': {
    country: 'US',
    city: 'Hymera',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Sullivan',
    latitude: 39.1833,
    longitude: -87.2994,
    postal_code: '47855'
  },
  '47857': {
    country: 'US',
    city: 'Knightsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.5269,
    longitude: -87.0869,
    postal_code: '47857'
  },
  '47858': {
    country: 'US',
    city: 'Lewis',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.2733,
    longitude: -87.2637,
    postal_code: '47858'
  },
  '47859': {
    country: 'US',
    city: 'Marshall',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Parke',
    latitude: 39.9062,
    longitude: -87.178,
    postal_code: '47859'
  },
  '47860': {
    country: 'US',
    city: 'Mecca',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Parke',
    latitude: 39.7219,
    longitude: -87.3303,
    postal_code: '47860'
  },
  '47861': {
    country: 'US',
    city: 'Merom',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Sullivan',
    latitude: 39.0564,
    longitude: -87.5675,
    postal_code: '47861'
  },
  '47862': {
    country: 'US',
    city: 'Montezuma',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Parke',
    latitude: 39.7961,
    longitude: -87.3607,
    postal_code: '47862'
  },
  '47863': {
    country: 'US',
    city: 'New Goshen',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.5803,
    longitude: -87.4616,
    postal_code: '47863'
  },
  '47865': {
    country: 'US',
    city: 'Paxton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Sullivan',
    latitude: 39.0212,
    longitude: -87.3886,
    postal_code: '47865'
  },
  '47866': {
    country: 'US',
    city: 'Pimento',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.2929,
    longitude: -87.3355,
    postal_code: '47866'
  },
  '47868': {
    country: 'US',
    city: 'Poland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.4462,
    longitude: -86.9631,
    postal_code: '47868'
  },
  '47869': {
    country: 'US',
    city: 'Prairie Creek',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.275,
    longitude: -87.4972,
    postal_code: '47869'
  },
  '47870': {
    country: 'US',
    city: 'Prairieton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.3712,
    longitude: -87.4759,
    postal_code: '47870'
  },
  '47871': {
    country: 'US',
    city: 'Riley',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.39,
    longitude: -87.3,
    postal_code: '47871'
  },
  '47872': {
    country: 'US',
    city: 'Rockville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Parke',
    latitude: 39.7682,
    longitude: -87.1978,
    postal_code: '47872'
  },
  '47874': {
    country: 'US',
    city: 'Rosedale',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Parke',
    latitude: 39.6239,
    longitude: -87.3086,
    postal_code: '47874'
  },
  '47875': {
    country: 'US',
    city: 'Saint Bernice',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vermillion',
    latitude: 39.8778,
    longitude: -87.4436,
    postal_code: '47875'
  },
  '47876': {
    country: 'US',
    city: 'Saint Mary Of The Woods',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.5109,
    longitude: -87.4672,
    postal_code: '47876'
  },
  '47878': {
    country: 'US',
    city: 'Seelyville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.4928,
    longitude: -87.2665,
    postal_code: '47878'
  },
  '47879': {
    country: 'US',
    city: 'Shelburn',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Sullivan',
    latitude: 39.1784,
    longitude: -87.3936,
    postal_code: '47879'
  },
  '47880': {
    country: 'US',
    city: 'Shepardsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.6006,
    longitude: -87.4196,
    postal_code: '47880'
  },
  '47881': {
    country: 'US',
    city: 'Staunton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Clay',
    latitude: 39.4875,
    longitude: -87.1889,
    postal_code: '47881'
  },
  '47882': {
    country: 'US',
    city: 'Sullivan',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Sullivan',
    latitude: 39.101,
    longitude: -87.4102,
    postal_code: '47882'
  },
  '47884': {
    country: 'US',
    city: 'Universal',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vermillion',
    latitude: 39.6221,
    longitude: -87.4548,
    postal_code: '47884'
  },
  '47885': {
    country: 'US',
    city: 'West Terre Haute',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vigo',
    latitude: 39.4984,
    longitude: -87.4688,
    postal_code: '47885'
  },
  '47901': {
    country: 'US',
    city: 'Lafayette',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.4177,
    longitude: -86.8884,
    postal_code: '47901'
  },
  '47902': {
    country: 'US',
    city: 'Lafayette',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.3887,
    longitude: -86.8949,
    postal_code: '47902'
  },
  '47903': {
    country: 'US',
    city: 'Lafayette',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.3044,
    longitude: -86.8245,
    postal_code: '47903'
  },
  '47904': {
    country: 'US',
    city: 'Lafayette',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.4276,
    longitude: -86.8735,
    postal_code: '47904'
  },
  '47905': {
    country: 'US',
    city: 'Lafayette',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.4001,
    longitude: -86.8602,
    postal_code: '47905'
  },
  '47906': {
    country: 'US',
    city: 'West Lafayette',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.444,
    longitude: -86.9237,
    postal_code: '47906'
  },
  '47907': {
    country: 'US',
    city: 'West Lafayette',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.4249,
    longitude: -86.9162,
    postal_code: '47907'
  },
  '47909': {
    country: 'US',
    city: 'Lafayette',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.3228,
    longitude: -86.8881,
    postal_code: '47909'
  },
  '47916': {
    country: 'US',
    city: 'Alamo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 39.9836,
    longitude: -87.0554,
    postal_code: '47916'
  },
  '47917': {
    country: 'US',
    city: 'Ambia',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Benton',
    latitude: 40.4793,
    longitude: -87.4796,
    postal_code: '47917'
  },
  '47918': {
    country: 'US',
    city: 'Attica',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fountain',
    latitude: 40.2811,
    longitude: -87.2241,
    postal_code: '47918'
  },
  '47920': {
    country: 'US',
    city: 'Battle Ground',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.5248,
    longitude: -86.8238,
    postal_code: '47920'
  },
  '47921': {
    country: 'US',
    city: 'Boswell',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Benton',
    latitude: 40.518,
    longitude: -87.3789,
    postal_code: '47921'
  },
  '47922': {
    country: 'US',
    city: 'Brook',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Newton',
    latitude: 40.8655,
    longitude: -87.3527,
    postal_code: '47922'
  },
  '47923': {
    country: 'US',
    city: 'Brookston',
    state: 'Indiana',
    state_short: 'IN',
    county: 'White',
    latitude: 40.6011,
    longitude: -86.8753,
    postal_code: '47923'
  },
  '47924': {
    country: 'US',
    city: 'Buck Creek',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.4876,
    longitude: -86.7631,
    postal_code: '47924'
  },
  '47925': {
    country: 'US',
    city: 'Buffalo',
    state: 'Indiana',
    state_short: 'IN',
    county: 'White',
    latitude: 40.8825,
    longitude: -86.7453,
    postal_code: '47925'
  },
  '47926': {
    country: 'US',
    city: 'Burnettsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'White',
    latitude: 40.7612,
    longitude: -86.5936,
    postal_code: '47926'
  },
  '47928': {
    country: 'US',
    city: 'Cayuga',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vermillion',
    latitude: 39.93,
    longitude: -87.459,
    postal_code: '47928'
  },
  '47929': {
    country: 'US',
    city: 'Chalmers',
    state: 'Indiana',
    state_short: 'IN',
    county: 'White',
    latitude: 40.6693,
    longitude: -86.8626,
    postal_code: '47929'
  },
  '47930': {
    country: 'US',
    city: 'Clarks Hill',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.247,
    longitude: -86.725,
    postal_code: '47930'
  },
  '47932': {
    country: 'US',
    city: 'Covington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fountain',
    latitude: 40.1326,
    longitude: -87.3819,
    postal_code: '47932'
  },
  '47933': {
    country: 'US',
    city: 'Crawfordsville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 40.0325,
    longitude: -86.9074,
    postal_code: '47933'
  },
  '47940': {
    country: 'US',
    city: 'Darlington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 40.1118,
    longitude: -86.7645,
    postal_code: '47940'
  },
  '47941': {
    country: 'US',
    city: 'Dayton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.3761,
    longitude: -86.774,
    postal_code: '47941'
  },
  '47942': {
    country: 'US',
    city: 'Earl Park',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Benton',
    latitude: 40.6945,
    longitude: -87.4232,
    postal_code: '47942'
  },
  '47943': {
    country: 'US',
    city: 'Fair Oaks',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jasper',
    latitude: 41.075,
    longitude: -87.2575,
    postal_code: '47943'
  },
  '47944': {
    country: 'US',
    city: 'Fowler',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Benton',
    latitude: 40.6255,
    longitude: -87.309,
    postal_code: '47944'
  },
  '47946': {
    country: 'US',
    city: 'Francesville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pulaski',
    latitude: 40.9709,
    longitude: -86.8553,
    postal_code: '47946'
  },
  '47948': {
    country: 'US',
    city: 'Goodland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Newton',
    latitude: 40.7669,
    longitude: -87.2999,
    postal_code: '47948'
  },
  '47949': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fountain',
    latitude: 40.0835,
    longitude: -87.1545,
    postal_code: '47949'
  },
  '47950': {
    country: 'US',
    city: 'Idaville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'White',
    latitude: 40.7674,
    longitude: -86.6556,
    postal_code: '47950'
  },
  '47951': {
    country: 'US',
    city: 'Kentland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Newton',
    latitude: 40.7877,
    longitude: -87.4471,
    postal_code: '47951'
  },
  '47952': {
    country: 'US',
    city: 'Kingman',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fountain',
    latitude: 40.0174,
    longitude: -87.2569,
    postal_code: '47952'
  },
  '47954': {
    country: 'US',
    city: 'Ladoga',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 39.9132,
    longitude: -86.8031,
    postal_code: '47954'
  },
  '47955': {
    country: 'US',
    city: 'Linden',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 40.1833,
    longitude: -86.8905,
    postal_code: '47955'
  },
  '47957': {
    country: 'US',
    city: 'Medaryville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Pulaski',
    latitude: 41.0897,
    longitude: -86.8808,
    postal_code: '47957'
  },
  '47958': {
    country: 'US',
    city: 'Mellott',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fountain',
    latitude: 40.1641,
    longitude: -87.1475,
    postal_code: '47958'
  },
  '47959': {
    country: 'US',
    city: 'Monon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'White',
    latitude: 40.8612,
    longitude: -86.8635,
    postal_code: '47959'
  },
  '47960': {
    country: 'US',
    city: 'Monticello',
    state: 'Indiana',
    state_short: 'IN',
    county: 'White',
    latitude: 40.7626,
    longitude: -86.755,
    postal_code: '47960'
  },
  '47962': {
    country: 'US',
    city: 'Montmorenci',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.4732,
    longitude: -87.0276,
    postal_code: '47962'
  },
  '47963': {
    country: 'US',
    city: 'Morocco',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Newton',
    latitude: 40.9645,
    longitude: -87.4187,
    postal_code: '47963'
  },
  '47964': {
    country: 'US',
    city: 'Mount Ayr',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Newton',
    latitude: 40.9521,
    longitude: -87.2986,
    postal_code: '47964'
  },
  '47965': {
    country: 'US',
    city: 'New Market',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 39.9524,
    longitude: -86.9206,
    postal_code: '47965'
  },
  '47966': {
    country: 'US',
    city: 'Newport',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vermillion',
    latitude: 39.8842,
    longitude: -87.4086,
    postal_code: '47966'
  },
  '47967': {
    country: 'US',
    city: 'New Richmond',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 40.1812,
    longitude: -86.9789,
    postal_code: '47967'
  },
  '47968': {
    country: 'US',
    city: 'New Ross',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 39.9883,
    longitude: -86.7528,
    postal_code: '47968'
  },
  '47969': {
    country: 'US',
    city: 'Newtown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fountain',
    latitude: 40.2035,
    longitude: -87.147,
    postal_code: '47969'
  },
  '47970': {
    country: 'US',
    city: 'Otterbein',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Benton',
    latitude: 40.517,
    longitude: -87.1225,
    postal_code: '47970'
  },
  '47971': {
    country: 'US',
    city: 'Oxford',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Benton',
    latitude: 40.5217,
    longitude: -87.2526,
    postal_code: '47971'
  },
  '47974': {
    country: 'US',
    city: 'Perrysville',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Vermillion',
    latitude: 40.0737,
    longitude: -87.4648,
    postal_code: '47974'
  },
  '47975': {
    country: 'US',
    city: 'Pine Village',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warren',
    latitude: 40.4328,
    longitude: -87.2317,
    postal_code: '47975'
  },
  '47977': {
    country: 'US',
    city: 'Remington',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jasper',
    latitude: 40.7667,
    longitude: -87.1599,
    postal_code: '47977'
  },
  '47978': {
    country: 'US',
    city: 'Rensselaer',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Jasper',
    latitude: 40.9948,
    longitude: -87.1037,
    postal_code: '47978'
  },
  '47980': {
    country: 'US',
    city: 'Reynolds',
    state: 'Indiana',
    state_short: 'IN',
    county: 'White',
    latitude: 40.7606,
    longitude: -86.8691,
    postal_code: '47980'
  },
  '47981': {
    country: 'US',
    city: 'Romney',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.258,
    longitude: -86.9208,
    postal_code: '47981'
  },
  '47982': {
    country: 'US',
    city: 'State Line',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warren',
    latitude: 40.1967,
    longitude: -87.5265,
    postal_code: '47982'
  },
  '47983': {
    country: 'US',
    city: 'Stockwell',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.2845,
    longitude: -86.7724,
    postal_code: '47983'
  },
  '47984': {
    country: 'US',
    city: 'Talbot',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Benton',
    latitude: 40.5053,
    longitude: -87.4542,
    postal_code: '47984'
  },
  '47986': {
    country: 'US',
    city: 'Templeton',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Benton',
    latitude: 40.5055,
    longitude: -87.207,
    postal_code: '47986'
  },
  '47987': {
    country: 'US',
    city: 'Veedersburg',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fountain',
    latitude: 40.1186,
    longitude: -87.2602,
    postal_code: '47987'
  },
  '47988': {
    country: 'US',
    city: 'Wallace',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Fountain',
    latitude: 39.9865,
    longitude: -87.1477,
    postal_code: '47988'
  },
  '47989': {
    country: 'US',
    city: 'Waveland',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 39.902,
    longitude: -87.0178,
    postal_code: '47989'
  },
  '47990': {
    country: 'US',
    city: 'Waynetown',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 40.0858,
    longitude: -87.0512,
    postal_code: '47990'
  },
  '47991': {
    country: 'US',
    city: 'West Lebanon',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warren',
    latitude: 40.2659,
    longitude: -87.4384,
    postal_code: '47991'
  },
  '47992': {
    country: 'US',
    city: 'Westpoint',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.3581,
    longitude: -87.0575,
    postal_code: '47992'
  },
  '47993': {
    country: 'US',
    city: 'Williamsport',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Warren',
    latitude: 40.3142,
    longitude: -87.4039,
    postal_code: '47993'
  },
  '47994': {
    country: 'US',
    city: 'Wingate',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Montgomery',
    latitude: 40.1666,
    longitude: -87.0664,
    postal_code: '47994'
  },
  '47995': {
    country: 'US',
    city: 'Wolcott',
    state: 'Indiana',
    state_short: 'IN',
    county: 'White',
    latitude: 40.7516,
    longitude: -87.029,
    postal_code: '47995'
  },
  '47996': {
    country: 'US',
    city: 'West Lafayette',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Tippecanoe',
    latitude: 40.3887,
    longitude: -86.8949,
    postal_code: '47996'
  },
  '47997': {
    country: 'US',
    city: 'Yeoman',
    state: 'Indiana',
    state_short: 'IN',
    county: 'Carroll',
    latitude: 40.6677,
    longitude: -86.7236,
    postal_code: '47997'
  },
  '48001': {
    country: 'US',
    city: 'Algonac',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.6367,
    longitude: -82.5866,
    postal_code: '48001'
  },
  '48002': {
    country: 'US',
    city: 'Allenton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.9388,
    longitude: -82.9198,
    postal_code: '48002'
  },
  '48003': {
    country: 'US',
    city: 'Almont',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 42.926,
    longitude: -83.0362,
    postal_code: '48003'
  },
  '48004': {
    country: 'US',
    city: 'Anchorville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.8241,
    longitude: -82.6652,
    postal_code: '48004'
  },
  '48005': {
    country: 'US',
    city: 'Armada',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.8409,
    longitude: -82.8899,
    postal_code: '48005'
  },
  '48006': {
    country: 'US',
    city: 'Avoca',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 43.0752,
    longitude: -82.6955,
    postal_code: '48006'
  },
  '48007': {
    country: 'US',
    city: 'Troy',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6061,
    longitude: -83.2976,
    postal_code: '48007'
  },
  '48009': {
    country: 'US',
    city: 'Birmingham',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5444,
    longitude: -83.2133,
    postal_code: '48009'
  },
  '48012': {
    country: 'US',
    city: 'Birmingham',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5467,
    longitude: -83.2113,
    postal_code: '48012'
  },
  '48014': {
    country: 'US',
    city: 'Capac',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 43.0263,
    longitude: -82.9298,
    postal_code: '48014'
  },
  '48015': {
    country: 'US',
    city: 'Center Line',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.4788,
    longitude: -83.0248,
    postal_code: '48015'
  },
  '48017': {
    country: 'US',
    city: 'Clawson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5365,
    longitude: -83.1503,
    postal_code: '48017'
  },
  '48021': {
    country: 'US',
    city: 'Eastpointe',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.4658,
    longitude: -82.9459,
    postal_code: '48021'
  },
  '48022': {
    country: 'US',
    city: 'Emmett',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.9872,
    longitude: -82.7854,
    postal_code: '48022'
  },
  '48023': {
    country: 'US',
    city: 'Fair Haven',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.7023,
    longitude: -82.6688,
    postal_code: '48023'
  },
  '48025': {
    country: 'US',
    city: 'Franklin',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5219,
    longitude: -83.2519,
    postal_code: '48025'
  },
  '48026': {
    country: 'US',
    city: 'Fraser',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5423,
    longitude: -82.947,
    postal_code: '48026'
  },
  '48027': {
    country: 'US',
    city: 'Goodells',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.9443,
    longitude: -82.6916,
    postal_code: '48027'
  },
  '48028': {
    country: 'US',
    city: 'Harsens Island',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.585,
    longitude: -82.586,
    postal_code: '48028'
  },
  '48030': {
    country: 'US',
    city: 'Hazel Park',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4608,
    longitude: -83.0982,
    postal_code: '48030'
  },
  '48032': {
    country: 'US',
    city: 'Jeddo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 43.1223,
    longitude: -82.5998,
    postal_code: '48032'
  },
  '48033': {
    country: 'US',
    city: 'Southfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4723,
    longitude: -83.294,
    postal_code: '48033'
  },
  '48034': {
    country: 'US',
    city: 'Southfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4969,
    longitude: -83.2911,
    postal_code: '48034'
  },
  '48035': {
    country: 'US',
    city: 'Clinton Township',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5512,
    longitude: -82.9167,
    postal_code: '48035'
  },
  '48036': {
    country: 'US',
    city: 'Clinton Township',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5938,
    longitude: -82.9133,
    postal_code: '48036'
  },
  '48037': {
    country: 'US',
    city: 'Southfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5675,
    longitude: -83.15,
    postal_code: '48037'
  },
  '48038': {
    country: 'US',
    city: 'Clinton Township',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.6206,
    longitude: -82.9608,
    postal_code: '48038'
  },
  '48039': {
    country: 'US',
    city: 'Marine City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.6859,
    longitude: -82.5499,
    postal_code: '48039'
  },
  '48040': {
    country: 'US',
    city: 'Marysville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.9135,
    longitude: -82.4813,
    postal_code: '48040'
  },
  '48041': {
    country: 'US',
    city: 'Memphis',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.941,
    longitude: -82.8046,
    postal_code: '48041'
  },
  '48042': {
    country: 'US',
    city: 'Macomb',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.6735,
    longitude: -82.9163,
    postal_code: '48042'
  },
  '48043': {
    country: 'US',
    city: 'Mount Clemens',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5978,
    longitude: -82.8823,
    postal_code: '48043'
  },
  '48044': {
    country: 'US',
    city: 'Macomb',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.6442,
    longitude: -82.8985,
    postal_code: '48044'
  },
  '48045': {
    country: 'US',
    city: 'Harrison Township',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5877,
    longitude: -82.8215,
    postal_code: '48045'
  },
  '48046': {
    country: 'US',
    city: 'Mount Clemens',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5973,
    longitude: -82.878,
    postal_code: '48046'
  },
  '48047': {
    country: 'US',
    city: 'New Baltimore',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.673,
    longitude: -82.7753,
    postal_code: '48047'
  },
  '48048': {
    country: 'US',
    city: 'New Haven',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.7443,
    longitude: -82.8042,
    postal_code: '48048'
  },
  '48049': {
    country: 'US',
    city: 'North Street',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 43.0349,
    longitude: -82.5748,
    postal_code: '48049'
  },
  '48050': {
    country: 'US',
    city: 'New Haven',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.7859,
    longitude: -82.7979,
    postal_code: '48050'
  },
  '48051': {
    country: 'US',
    city: 'New Baltimore',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.6938,
    longitude: -82.8207,
    postal_code: '48051'
  },
  '48054': {
    country: 'US',
    city: 'East China',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.7769,
    longitude: -82.5437,
    postal_code: '48054'
  },
  '48059': {
    country: 'US',
    city: 'Fort Gratiot',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 43.0888,
    longitude: -82.4905,
    postal_code: '48059'
  },
  '48060': {
    country: 'US',
    city: 'Port Huron',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.9958,
    longitude: -82.4599,
    postal_code: '48060'
  },
  '48061': {
    country: 'US',
    city: 'Port Huron',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.9709,
    longitude: -82.4249,
    postal_code: '48061'
  },
  '48062': {
    country: 'US',
    city: 'Richmond',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.8389,
    longitude: -82.7996,
    postal_code: '48062'
  },
  '48063': {
    country: 'US',
    city: 'Columbus',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.8892,
    longitude: -82.6675,
    postal_code: '48063'
  },
  '48064': {
    country: 'US',
    city: 'Casco',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.7674,
    longitude: -82.6728,
    postal_code: '48064'
  },
  '48065': {
    country: 'US',
    city: 'Romeo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.84,
    longitude: -83.0388,
    postal_code: '48065'
  },
  '48066': {
    country: 'US',
    city: 'Roseville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5034,
    longitude: -82.9387,
    postal_code: '48066'
  },
  '48067': {
    country: 'US',
    city: 'Royal Oak',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4906,
    longitude: -83.1366,
    postal_code: '48067'
  },
  '48068': {
    country: 'US',
    city: 'Royal Oak',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4895,
    longitude: -83.1446,
    postal_code: '48068'
  },
  '48069': {
    country: 'US',
    city: 'Pleasant Ridge',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.471,
    longitude: -83.1438,
    postal_code: '48069'
  },
  '48070': {
    country: 'US',
    city: 'Huntington Woods',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4825,
    longitude: -83.1749,
    postal_code: '48070'
  },
  '48071': {
    country: 'US',
    city: 'Madison Heights',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5016,
    longitude: -83.1027,
    postal_code: '48071'
  },
  '48072': {
    country: 'US',
    city: 'Berkley',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5028,
    longitude: -83.1887,
    postal_code: '48072'
  },
  '48073': {
    country: 'US',
    city: 'Royal Oak',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.519,
    longitude: -83.157,
    postal_code: '48073'
  },
  '48074': {
    country: 'US',
    city: 'Smiths Creek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.9051,
    longitude: -82.5679,
    postal_code: '48074'
  },
  '48075': {
    country: 'US',
    city: 'Southfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4638,
    longitude: -83.2255,
    postal_code: '48075'
  },
  '48076': {
    country: 'US',
    city: 'Southfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4981,
    longitude: -83.2058,
    postal_code: '48076'
  },
  '48079': {
    country: 'US',
    city: 'Saint Clair',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 42.8255,
    longitude: -82.5133,
    postal_code: '48079'
  },
  '48080': {
    country: 'US',
    city: 'Saint Clair Shores',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.4706,
    longitude: -82.9022,
    postal_code: '48080'
  },
  '48081': {
    country: 'US',
    city: 'Saint Clair Shores',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.4941,
    longitude: -82.899,
    postal_code: '48081'
  },
  '48082': {
    country: 'US',
    city: 'Saint Clair Shores',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5269,
    longitude: -82.8841,
    postal_code: '48082'
  },
  '48083': {
    country: 'US',
    city: 'Troy',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5597,
    longitude: -83.1138,
    postal_code: '48083'
  },
  '48084': {
    country: 'US',
    city: 'Troy',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5627,
    longitude: -83.1799,
    postal_code: '48084'
  },
  '48085': {
    country: 'US',
    city: 'Troy',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6006,
    longitude: -83.1198,
    postal_code: '48085'
  },
  '48086': {
    country: 'US',
    city: 'Southfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4734,
    longitude: -83.2219,
    postal_code: '48086'
  },
  '48088': {
    country: 'US',
    city: 'Warren',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5164,
    longitude: -82.9832,
    postal_code: '48088'
  },
  '48089': {
    country: 'US',
    city: 'Warren',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.4685,
    longitude: -82.9974,
    postal_code: '48089'
  },
  '48090': {
    country: 'US',
    city: 'Warren',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.4775,
    longitude: -83.0277,
    postal_code: '48090'
  },
  '48091': {
    country: 'US',
    city: 'Warren',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.4665,
    longitude: -83.0593,
    postal_code: '48091'
  },
  '48092': {
    country: 'US',
    city: 'Warren',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5125,
    longitude: -83.0643,
    postal_code: '48092'
  },
  '48093': {
    country: 'US',
    city: 'Warren',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5149,
    longitude: -82.9968,
    postal_code: '48093'
  },
  '48094': {
    country: 'US',
    city: 'Washington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.7262,
    longitude: -83.0268,
    postal_code: '48094'
  },
  '48095': {
    country: 'US',
    city: 'Washington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.7682,
    longitude: -83.0395,
    postal_code: '48095'
  },
  '48096': {
    country: 'US',
    city: 'Ray',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.754,
    longitude: -82.9163,
    postal_code: '48096'
  },
  '48097': {
    country: 'US',
    city: 'Yale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Clair',
    latitude: 43.126,
    longitude: -82.8295,
    postal_code: '48097'
  },
  '48098': {
    country: 'US',
    city: 'Troy',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5991,
    longitude: -83.1789,
    postal_code: '48098'
  },
  '48099': {
    country: 'US',
    city: 'Troy',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5876,
    longitude: -83.1737,
    postal_code: '48099'
  },
  '48101': {
    country: 'US',
    city: 'Allen Park',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2522,
    longitude: -83.212,
    postal_code: '48101'
  },
  '48103': {
    country: 'US',
    city: 'Ann Arbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.2794,
    longitude: -83.784,
    postal_code: '48103'
  },
  '48104': {
    country: 'US',
    city: 'Ann Arbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.2694,
    longitude: -83.7282,
    postal_code: '48104'
  },
  '48105': {
    country: 'US',
    city: 'Ann Arbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.3042,
    longitude: -83.7068,
    postal_code: '48105'
  },
  '48106': {
    country: 'US',
    city: 'Ann Arbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.2535,
    longitude: -83.8366,
    postal_code: '48106'
  },
  '48107': {
    country: 'US',
    city: 'Ann Arbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.2535,
    longitude: -83.8366,
    postal_code: '48107'
  },
  '48108': {
    country: 'US',
    city: 'Ann Arbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.2328,
    longitude: -83.7015,
    postal_code: '48108'
  },
  '48109': {
    country: 'US',
    city: 'Ann Arbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.2776,
    longitude: -83.7409,
    postal_code: '48109'
  },
  '48110': {
    country: 'US',
    city: 'Azalia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 42.0082,
    longitude: -83.6643,
    postal_code: '48110'
  },
  '48111': {
    country: 'US',
    city: 'Belleville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.1949,
    longitude: -83.4854,
    postal_code: '48111'
  },
  '48112': {
    country: 'US',
    city: 'Belleville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2048,
    longitude: -83.4852,
    postal_code: '48112'
  },
  '48113': {
    country: 'US',
    city: 'Ann Arbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.2535,
    longitude: -83.8366,
    postal_code: '48113'
  },
  '48114': {
    country: 'US',
    city: 'Brighton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.57,
    longitude: -83.7484,
    postal_code: '48114'
  },
  '48115': {
    country: 'US',
    city: 'Bridgewater',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.1602,
    longitude: -83.9117,
    postal_code: '48115'
  },
  '48116': {
    country: 'US',
    city: 'Brighton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.5371,
    longitude: -83.7756,
    postal_code: '48116'
  },
  '48117': {
    country: 'US',
    city: 'Carleton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 42.0529,
    longitude: -83.3755,
    postal_code: '48117'
  },
  '48118': {
    country: 'US',
    city: 'Chelsea',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.3207,
    longitude: -84.0334,
    postal_code: '48118'
  },
  '48120': {
    country: 'US',
    city: 'Dearborn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3223,
    longitude: -83.1763,
    postal_code: '48120'
  },
  '48121': {
    country: 'US',
    city: 'Dearborn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48121'
  },
  '48122': {
    country: 'US',
    city: 'Melvindale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2812,
    longitude: -83.1826,
    postal_code: '48122'
  },
  '48123': {
    country: 'US',
    city: 'Dearborn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48123'
  },
  '48124': {
    country: 'US',
    city: 'Dearborn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2941,
    longitude: -83.2536,
    postal_code: '48124'
  },
  '48125': {
    country: 'US',
    city: 'Dearborn Heights',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2768,
    longitude: -83.2606,
    postal_code: '48125'
  },
  '48126': {
    country: 'US',
    city: 'Dearborn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3349,
    longitude: -83.1801,
    postal_code: '48126'
  },
  '48127': {
    country: 'US',
    city: 'Dearborn Heights',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3353,
    longitude: -83.2864,
    postal_code: '48127'
  },
  '48128': {
    country: 'US',
    city: 'Dearborn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.32,
    longitude: -83.2701,
    postal_code: '48128'
  },
  '48130': {
    country: 'US',
    city: 'Dexter',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.3583,
    longitude: -83.9,
    postal_code: '48130'
  },
  '48131': {
    country: 'US',
    city: 'Dundee',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.9514,
    longitude: -83.6522,
    postal_code: '48131'
  },
  '48133': {
    country: 'US',
    city: 'Erie',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.7829,
    longitude: -83.4958,
    postal_code: '48133'
  },
  '48134': {
    country: 'US',
    city: 'Flat Rock',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.1055,
    longitude: -83.2795,
    postal_code: '48134'
  },
  '48135': {
    country: 'US',
    city: 'Garden City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3242,
    longitude: -83.3402,
    postal_code: '48135'
  },
  '48136': {
    country: 'US',
    city: 'Garden City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48136'
  },
  '48137': {
    country: 'US',
    city: 'Gregory',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.496,
    longitude: -84.0847,
    postal_code: '48137'
  },
  '48138': {
    country: 'US',
    city: 'Grosse Ile',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.1352,
    longitude: -83.1561,
    postal_code: '48138'
  },
  '48139': {
    country: 'US',
    city: 'Hamburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.4523,
    longitude: -83.8147,
    postal_code: '48139'
  },
  '48140': {
    country: 'US',
    city: 'Ida',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.8549,
    longitude: -83.5916,
    postal_code: '48140'
  },
  '48141': {
    country: 'US',
    city: 'Inkster',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.294,
    longitude: -83.3146,
    postal_code: '48141'
  },
  '48143': {
    country: 'US',
    city: 'Lakeland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.4573,
    longitude: -83.8366,
    postal_code: '48143'
  },
  '48144': {
    country: 'US',
    city: 'Lambertville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.7531,
    longitude: -83.6259,
    postal_code: '48144'
  },
  '48145': {
    country: 'US',
    city: 'La Salle',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.8585,
    longitude: -83.4715,
    postal_code: '48145'
  },
  '48146': {
    country: 'US',
    city: 'Lincoln Park',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2422,
    longitude: -83.1807,
    postal_code: '48146'
  },
  '48150': {
    country: 'US',
    city: 'Livonia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3615,
    longitude: -83.3649,
    postal_code: '48150'
  },
  '48151': {
    country: 'US',
    city: 'Livonia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3684,
    longitude: -83.3527,
    postal_code: '48151'
  },
  '48152': {
    country: 'US',
    city: 'Livonia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4258,
    longitude: -83.3636,
    postal_code: '48152'
  },
  '48153': {
    country: 'US',
    city: 'Livonia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3684,
    longitude: -83.3527,
    postal_code: '48153'
  },
  '48154': {
    country: 'US',
    city: 'Livonia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3958,
    longitude: -83.3772,
    postal_code: '48154'
  },
  '48157': {
    country: 'US',
    city: 'Luna Pier',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.8146,
    longitude: -83.4382,
    postal_code: '48157'
  },
  '48158': {
    country: 'US',
    city: 'Manchester',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.1555,
    longitude: -84.0332,
    postal_code: '48158'
  },
  '48159': {
    country: 'US',
    city: 'Maybee',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 42.0288,
    longitude: -83.5179,
    postal_code: '48159'
  },
  '48160': {
    country: 'US',
    city: 'Milan',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 42.0914,
    longitude: -83.6776,
    postal_code: '48160'
  },
  '48161': {
    country: 'US',
    city: 'Monroe',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.908,
    longitude: -83.4719,
    postal_code: '48161'
  },
  '48162': {
    country: 'US',
    city: 'Monroe',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.9293,
    longitude: -83.4448,
    postal_code: '48162'
  },
  '48164': {
    country: 'US',
    city: 'New Boston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.1449,
    longitude: -83.3589,
    postal_code: '48164'
  },
  '48165': {
    country: 'US',
    city: 'New Hudson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5076,
    longitude: -83.6342,
    postal_code: '48165'
  },
  '48166': {
    country: 'US',
    city: 'Newport',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.9766,
    longitude: -83.2804,
    postal_code: '48166'
  },
  '48167': {
    country: 'US',
    city: 'Northville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4262,
    longitude: -83.4794,
    postal_code: '48167'
  },
  '48168': {
    country: 'US',
    city: 'Northville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4086,
    longitude: -83.4978,
    postal_code: '48168'
  },
  '48169': {
    country: 'US',
    city: 'Pinckney',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.4596,
    longitude: -83.9099,
    postal_code: '48169'
  },
  '48170': {
    country: 'US',
    city: 'Plymouth',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3688,
    longitude: -83.4799,
    postal_code: '48170'
  },
  '48173': {
    country: 'US',
    city: 'Rockwood',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.0731,
    longitude: -83.2127,
    postal_code: '48173'
  },
  '48174': {
    country: 'US',
    city: 'Romulus',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2223,
    longitude: -83.3966,
    postal_code: '48174'
  },
  '48175': {
    country: 'US',
    city: 'Salem',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.4056,
    longitude: -83.5781,
    postal_code: '48175'
  },
  '48176': {
    country: 'US',
    city: 'Saline',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.1698,
    longitude: -83.7849,
    postal_code: '48176'
  },
  '48177': {
    country: 'US',
    city: 'Samaria',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.8076,
    longitude: -83.5793,
    postal_code: '48177'
  },
  '48178': {
    country: 'US',
    city: 'South Lyon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4567,
    longitude: -83.659,
    postal_code: '48178'
  },
  '48179': {
    country: 'US',
    city: 'South Rockwood',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 42.0624,
    longitude: -83.2663,
    postal_code: '48179'
  },
  '48180': {
    country: 'US',
    city: 'Taylor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2317,
    longitude: -83.2673,
    postal_code: '48180'
  },
  '48182': {
    country: 'US',
    city: 'Temperance',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.7682,
    longitude: -83.5797,
    postal_code: '48182'
  },
  '48183': {
    country: 'US',
    city: 'Trenton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.1382,
    longitude: -83.2179,
    postal_code: '48183'
  },
  '48184': {
    country: 'US',
    city: 'Wayne',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2768,
    longitude: -83.3758,
    postal_code: '48184'
  },
  '48185': {
    country: 'US',
    city: 'Westland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3358,
    longitude: -83.3846,
    postal_code: '48185'
  },
  '48186': {
    country: 'US',
    city: 'Westland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.289,
    longitude: -83.3686,
    postal_code: '48186'
  },
  '48187': {
    country: 'US',
    city: 'Canton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.332,
    longitude: -83.4695,
    postal_code: '48187'
  },
  '48188': {
    country: 'US',
    city: 'Canton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.291,
    longitude: -83.465,
    postal_code: '48188'
  },
  '48189': {
    country: 'US',
    city: 'Whitmore Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.4289,
    longitude: -83.7828,
    postal_code: '48189'
  },
  '48190': {
    country: 'US',
    city: 'Whittaker',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.1244,
    longitude: -83.5946,
    postal_code: '48190'
  },
  '48191': {
    country: 'US',
    city: 'Willis',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.1292,
    longitude: -83.5687,
    postal_code: '48191'
  },
  '48192': {
    country: 'US',
    city: 'Wyandotte',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2084,
    longitude: -83.1616,
    postal_code: '48192'
  },
  '48193': {
    country: 'US',
    city: 'Riverview',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.1782,
    longitude: -83.2461,
    postal_code: '48193'
  },
  '48195': {
    country: 'US',
    city: 'Southgate',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2044,
    longitude: -83.1999,
    postal_code: '48195'
  },
  '48197': {
    country: 'US',
    city: 'Ypsilanti',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.2325,
    longitude: -83.6336,
    postal_code: '48197'
  },
  '48198': {
    country: 'US',
    city: 'Ypsilanti',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Washtenaw',
    latitude: 42.2439,
    longitude: -83.583,
    postal_code: '48198'
  },
  '48201': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3474,
    longitude: -83.0604,
    postal_code: '48201'
  },
  '48202': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.377,
    longitude: -83.0796,
    postal_code: '48202'
  },
  '48203': {
    country: 'US',
    city: 'Highland Park',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4212,
    longitude: -83.1009,
    postal_code: '48203'
  },
  '48204': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3661,
    longitude: -83.1422,
    postal_code: '48204'
  },
  '48205': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4313,
    longitude: -82.9813,
    postal_code: '48205'
  },
  '48206': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3749,
    longitude: -83.1087,
    postal_code: '48206'
  },
  '48207': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3524,
    longitude: -83.0271,
    postal_code: '48207'
  },
  '48208': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3495,
    longitude: -83.0927,
    postal_code: '48208'
  },
  '48209': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3097,
    longitude: -83.1155,
    postal_code: '48209'
  },
  '48210': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3376,
    longitude: -83.1303,
    postal_code: '48210'
  },
  '48211': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3809,
    longitude: -83.0409,
    postal_code: '48211'
  },
  '48212': {
    country: 'US',
    city: 'Hamtramck',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4081,
    longitude: -83.0583,
    postal_code: '48212'
  },
  '48213': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3982,
    longitude: -82.9925,
    postal_code: '48213'
  },
  '48214': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3669,
    longitude: -82.9938,
    postal_code: '48214'
  },
  '48215': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3773,
    longitude: -82.9513,
    postal_code: '48215'
  },
  '48216': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3275,
    longitude: -83.0827,
    postal_code: '48216'
  },
  '48217': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2719,
    longitude: -83.1545,
    postal_code: '48217'
  },
  '48218': {
    country: 'US',
    city: 'River Rouge',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2692,
    longitude: -83.1364,
    postal_code: '48218'
  },
  '48219': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.426,
    longitude: -83.2495,
    postal_code: '48219'
  },
  '48220': {
    country: 'US',
    city: 'Ferndale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4586,
    longitude: -83.1363,
    postal_code: '48220'
  },
  '48221': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.426,
    longitude: -83.15,
    postal_code: '48221'
  },
  '48222': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48222'
  },
  '48223': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3945,
    longitude: -83.2454,
    postal_code: '48223'
  },
  '48224': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4098,
    longitude: -82.9441,
    postal_code: '48224'
  },
  '48225': {
    country: 'US',
    city: 'Harper Woods',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4377,
    longitude: -82.9289,
    postal_code: '48225'
  },
  '48226': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3333,
    longitude: -83.0484,
    postal_code: '48226'
  },
  '48227': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3883,
    longitude: -83.1937,
    postal_code: '48227'
  },
  '48228': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3547,
    longitude: -83.2168,
    postal_code: '48228'
  },
  '48229': {
    country: 'US',
    city: 'Ecorse',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2519,
    longitude: -83.1489,
    postal_code: '48229'
  },
  '48230': {
    country: 'US',
    city: 'Grosse Pointe',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3847,
    longitude: -82.9244,
    postal_code: '48230'
  },
  '48231': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48231'
  },
  '48232': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48232'
  },
  '48233': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48233'
  },
  '48234': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4337,
    longitude: -83.0434,
    postal_code: '48234'
  },
  '48235': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4261,
    longitude: -83.1951,
    postal_code: '48235'
  },
  '48236': {
    country: 'US',
    city: 'Grosse Pointe',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4274,
    longitude: -82.9002,
    postal_code: '48236'
  },
  '48237': {
    country: 'US',
    city: 'Oak Park',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4662,
    longitude: -83.184,
    postal_code: '48237'
  },
  '48238': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3959,
    longitude: -83.1411,
    postal_code: '48238'
  },
  '48239': {
    country: 'US',
    city: 'Redford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3756,
    longitude: -83.2889,
    postal_code: '48239'
  },
  '48240': {
    country: 'US',
    city: 'Redford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.4264,
    longitude: -83.3017,
    postal_code: '48240'
  },
  '48242': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2166,
    longitude: -83.3532,
    postal_code: '48242'
  },
  '48243': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48243'
  },
  '48244': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48244'
  },
  '48255': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48255'
  },
  '48260': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48260'
  },
  '48264': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48264'
  },
  '48265': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48265'
  },
  '48266': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48266'
  },
  '48267': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48267'
  },
  '48268': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48268'
  },
  '48269': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48269'
  },
  '48272': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48272'
  },
  '48275': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48275'
  },
  '48277': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48277'
  },
  '48278': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48278'
  },
  '48279': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.3314,
    longitude: -83.0458,
    postal_code: '48279'
  },
  '48288': {
    country: 'US',
    city: 'Detroit',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wayne',
    latitude: 42.2399,
    longitude: -83.1508,
    postal_code: '48288'
  },
  '48301': {
    country: 'US',
    city: 'Bloomfield Hills',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5445,
    longitude: -83.2792,
    postal_code: '48301'
  },
  '48302': {
    country: 'US',
    city: 'Bloomfield Hills',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5848,
    longitude: -83.2821,
    postal_code: '48302'
  },
  '48303': {
    country: 'US',
    city: 'Bloomfield Hills',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5836,
    longitude: -83.2455,
    postal_code: '48303'
  },
  '48304': {
    country: 'US',
    city: 'Bloomfield Hills',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.587,
    longitude: -83.2359,
    postal_code: '48304'
  },
  '48306': {
    country: 'US',
    city: 'Rochester',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.7262,
    longitude: -83.1566,
    postal_code: '48306'
  },
  '48307': {
    country: 'US',
    city: 'Rochester',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6593,
    longitude: -83.1225,
    postal_code: '48307'
  },
  '48308': {
    country: 'US',
    city: 'Rochester',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6806,
    longitude: -83.1338,
    postal_code: '48308'
  },
  '48309': {
    country: 'US',
    city: 'Rochester',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6626,
    longitude: -83.1837,
    postal_code: '48309'
  },
  '48310': {
    country: 'US',
    city: 'Sterling Heights',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5648,
    longitude: -83.0701,
    postal_code: '48310'
  },
  '48311': {
    country: 'US',
    city: 'Sterling Heights',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5803,
    longitude: -83.0302,
    postal_code: '48311'
  },
  '48312': {
    country: 'US',
    city: 'Sterling Heights',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.5592,
    longitude: -83.0029,
    postal_code: '48312'
  },
  '48313': {
    country: 'US',
    city: 'Sterling Heights',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.6005,
    longitude: -82.9998,
    postal_code: '48313'
  },
  '48314': {
    country: 'US',
    city: 'Sterling Heights',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.6124,
    longitude: -83.0345,
    postal_code: '48314'
  },
  '48315': {
    country: 'US',
    city: 'Utica',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.671,
    longitude: -82.9988,
    postal_code: '48315'
  },
  '48316': {
    country: 'US',
    city: 'Utica',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.6885,
    longitude: -83.0548,
    postal_code: '48316'
  },
  '48317': {
    country: 'US',
    city: 'Utica',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.6413,
    longitude: -83.0504,
    postal_code: '48317'
  },
  '48318': {
    country: 'US',
    city: 'Utica',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.6261,
    longitude: -83.0335,
    postal_code: '48318'
  },
  '48320': {
    country: 'US',
    city: 'Keego Harbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6114,
    longitude: -83.3356,
    postal_code: '48320'
  },
  '48321': {
    country: 'US',
    city: 'Auburn Hills',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6601,
    longitude: -83.3863,
    postal_code: '48321'
  },
  '48322': {
    country: 'US',
    city: 'West Bloomfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5424,
    longitude: -83.3793,
    postal_code: '48322'
  },
  '48323': {
    country: 'US',
    city: 'West Bloomfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5683,
    longitude: -83.3805,
    postal_code: '48323'
  },
  '48324': {
    country: 'US',
    city: 'West Bloomfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5961,
    longitude: -83.3819,
    postal_code: '48324'
  },
  '48325': {
    country: 'US',
    city: 'West Bloomfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6601,
    longitude: -83.3863,
    postal_code: '48325'
  },
  '48326': {
    country: 'US',
    city: 'Auburn Hills',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6583,
    longitude: -83.2375,
    postal_code: '48326'
  },
  '48327': {
    country: 'US',
    city: 'Waterford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6438,
    longitude: -83.4076,
    postal_code: '48327'
  },
  '48328': {
    country: 'US',
    city: 'Waterford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6429,
    longitude: -83.3546,
    postal_code: '48328'
  },
  '48329': {
    country: 'US',
    city: 'Waterford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6877,
    longitude: -83.3879,
    postal_code: '48329'
  },
  '48330': {
    country: 'US',
    city: 'Drayton Plains',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6754,
    longitude: -83.3637,
    postal_code: '48330'
  },
  '48331': {
    country: 'US',
    city: 'Farmington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5051,
    longitude: -83.4072,
    postal_code: '48331'
  },
  '48332': {
    country: 'US',
    city: 'Farmington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4645,
    longitude: -83.3763,
    postal_code: '48332'
  },
  '48333': {
    country: 'US',
    city: 'Farmington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4645,
    longitude: -83.3763,
    postal_code: '48333'
  },
  '48334': {
    country: 'US',
    city: 'Farmington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5065,
    longitude: -83.3484,
    postal_code: '48334'
  },
  '48335': {
    country: 'US',
    city: 'Farmington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4617,
    longitude: -83.4053,
    postal_code: '48335'
  },
  '48336': {
    country: 'US',
    city: 'Farmington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4656,
    longitude: -83.3638,
    postal_code: '48336'
  },
  '48340': {
    country: 'US',
    city: 'Pontiac',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.668,
    longitude: -83.2893,
    postal_code: '48340'
  },
  '48341': {
    country: 'US',
    city: 'Pontiac',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6294,
    longitude: -83.3041,
    postal_code: '48341'
  },
  '48342': {
    country: 'US',
    city: 'Pontiac',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6439,
    longitude: -83.2792,
    postal_code: '48342'
  },
  '48343': {
    country: 'US',
    city: 'Pontiac',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6389,
    longitude: -83.291,
    postal_code: '48343'
  },
  '48346': {
    country: 'US',
    city: 'Clarkston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.7239,
    longitude: -83.4232,
    postal_code: '48346'
  },
  '48347': {
    country: 'US',
    city: 'Clarkston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6601,
    longitude: -83.3863,
    postal_code: '48347'
  },
  '48348': {
    country: 'US',
    city: 'Clarkston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.7605,
    longitude: -83.404,
    postal_code: '48348'
  },
  '48350': {
    country: 'US',
    city: 'Davisburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.7496,
    longitude: -83.5358,
    postal_code: '48350'
  },
  '48353': {
    country: 'US',
    city: 'Hartland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.6356,
    longitude: -83.7147,
    postal_code: '48353'
  },
  '48356': {
    country: 'US',
    city: 'Highland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6692,
    longitude: -83.5895,
    postal_code: '48356'
  },
  '48357': {
    country: 'US',
    city: 'Highland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6595,
    longitude: -83.637,
    postal_code: '48357'
  },
  '48359': {
    country: 'US',
    city: 'Lake Orion',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.7231,
    longitude: -83.2769,
    postal_code: '48359'
  },
  '48360': {
    country: 'US',
    city: 'Lake Orion',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.7429,
    longitude: -83.272,
    postal_code: '48360'
  },
  '48361': {
    country: 'US',
    city: 'Lake Orion',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.7845,
    longitude: -83.2397,
    postal_code: '48361'
  },
  '48362': {
    country: 'US',
    city: 'Lake Orion',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.7763,
    longitude: -83.2748,
    postal_code: '48362'
  },
  '48363': {
    country: 'US',
    city: 'Oakland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.7732,
    longitude: -83.1711,
    postal_code: '48363'
  },
  '48366': {
    country: 'US',
    city: 'Lakeville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.8214,
    longitude: -83.1502,
    postal_code: '48366'
  },
  '48367': {
    country: 'US',
    city: 'Leonard',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.8437,
    longitude: -83.1406,
    postal_code: '48367'
  },
  '48370': {
    country: 'US',
    city: 'Oxford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.8265,
    longitude: -83.2005,
    postal_code: '48370'
  },
  '48371': {
    country: 'US',
    city: 'Oxford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.8223,
    longitude: -83.2829,
    postal_code: '48371'
  },
  '48374': {
    country: 'US',
    city: 'Novi',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4735,
    longitude: -83.5224,
    postal_code: '48374'
  },
  '48375': {
    country: 'US',
    city: 'Novi',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4604,
    longitude: -83.4577,
    postal_code: '48375'
  },
  '48376': {
    country: 'US',
    city: 'Novi',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.471,
    longitude: -83.4748,
    postal_code: '48376'
  },
  '48377': {
    country: 'US',
    city: 'Novi',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.4992,
    longitude: -83.4773,
    postal_code: '48377'
  },
  '48380': {
    country: 'US',
    city: 'Milford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.602,
    longitude: -83.6508,
    postal_code: '48380'
  },
  '48381': {
    country: 'US',
    city: 'Milford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5758,
    longitude: -83.5924,
    postal_code: '48381'
  },
  '48382': {
    country: 'US',
    city: 'Commerce Township',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5834,
    longitude: -83.5009,
    postal_code: '48382'
  },
  '48383': {
    country: 'US',
    city: 'White Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.658,
    longitude: -83.5398,
    postal_code: '48383'
  },
  '48386': {
    country: 'US',
    city: 'White Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.641,
    longitude: -83.4738,
    postal_code: '48386'
  },
  '48387': {
    country: 'US',
    city: 'Union Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.6148,
    longitude: -83.4469,
    postal_code: '48387'
  },
  '48390': {
    country: 'US',
    city: 'Walled Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5582,
    longitude: -83.4773,
    postal_code: '48390'
  },
  '48391': {
    country: 'US',
    city: 'Walled Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.5378,
    longitude: -83.481,
    postal_code: '48391'
  },
  '48393': {
    country: 'US',
    city: 'Wixom',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.534,
    longitude: -83.5285,
    postal_code: '48393'
  },
  '48397': {
    country: 'US',
    city: 'Warren',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Macomb',
    latitude: 42.4917,
    longitude: -83.0402,
    postal_code: '48397'
  },
  '48401': {
    country: 'US',
    city: 'Applegate',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.3619,
    longitude: -82.6479,
    postal_code: '48401'
  },
  '48410': {
    country: 'US',
    city: 'Argyle',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.5589,
    longitude: -82.9455,
    postal_code: '48410'
  },
  '48411': {
    country: 'US',
    city: 'Atlas',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.94,
    longitude: -83.5369,
    postal_code: '48411'
  },
  '48412': {
    country: 'US',
    city: 'Attica',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 43.0547,
    longitude: -83.1668,
    postal_code: '48412'
  },
  '48413': {
    country: 'US',
    city: 'Bad Axe',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.8067,
    longitude: -83.0054,
    postal_code: '48413'
  },
  '48414': {
    country: 'US',
    city: 'Bancroft',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 42.882,
    longitude: -84.1207,
    postal_code: '48414'
  },
  '48415': {
    country: 'US',
    city: 'Birch Run',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.2649,
    longitude: -83.7903,
    postal_code: '48415'
  },
  '48416': {
    country: 'US',
    city: 'Brown City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.2171,
    longitude: -82.9978,
    postal_code: '48416'
  },
  '48417': {
    country: 'US',
    city: 'Burt',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.2404,
    longitude: -83.9511,
    postal_code: '48417'
  },
  '48418': {
    country: 'US',
    city: 'Byron',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 42.8059,
    longitude: -83.973,
    postal_code: '48418'
  },
  '48419': {
    country: 'US',
    city: 'Carsonville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.4258,
    longitude: -82.6022,
    postal_code: '48419'
  },
  '48420': {
    country: 'US',
    city: 'Clio',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.1779,
    longitude: -83.7249,
    postal_code: '48420'
  },
  '48421': {
    country: 'US',
    city: 'Columbiaville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 43.1503,
    longitude: -83.3811,
    postal_code: '48421'
  },
  '48422': {
    country: 'US',
    city: 'Croswell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.2622,
    longitude: -82.6337,
    postal_code: '48422'
  },
  '48423': {
    country: 'US',
    city: 'Davison',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0348,
    longitude: -83.5268,
    postal_code: '48423'
  },
  '48426': {
    country: 'US',
    city: 'Decker',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.4775,
    longitude: -83.0638,
    postal_code: '48426'
  },
  '48427': {
    country: 'US',
    city: 'Deckerville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.5151,
    longitude: -82.7191,
    postal_code: '48427'
  },
  '48428': {
    country: 'US',
    city: 'Dryden',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 42.9378,
    longitude: -83.1501,
    postal_code: '48428'
  },
  '48429': {
    country: 'US',
    city: 'Durand',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 42.9117,
    longitude: -83.9877,
    postal_code: '48429'
  },
  '48430': {
    country: 'US',
    city: 'Fenton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.7851,
    longitude: -83.7294,
    postal_code: '48430'
  },
  '48432': {
    country: 'US',
    city: 'Filion',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.9014,
    longitude: -82.9825,
    postal_code: '48432'
  },
  '48433': {
    country: 'US',
    city: 'Flushing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.072,
    longitude: -83.8424,
    postal_code: '48433'
  },
  '48434': {
    country: 'US',
    city: 'Forestville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.6632,
    longitude: -82.6133,
    postal_code: '48434'
  },
  '48435': {
    country: 'US',
    city: 'Fostoria',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.2645,
    longitude: -83.3796,
    postal_code: '48435'
  },
  '48436': {
    country: 'US',
    city: 'Gaines',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.8813,
    longitude: -83.8855,
    postal_code: '48436'
  },
  '48437': {
    country: 'US',
    city: 'Genesee',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.1123,
    longitude: -83.6154,
    postal_code: '48437'
  },
  '48438': {
    country: 'US',
    city: 'Goodrich',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9147,
    longitude: -83.4845,
    postal_code: '48438'
  },
  '48439': {
    country: 'US',
    city: 'Grand Blanc',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9282,
    longitude: -83.6264,
    postal_code: '48439'
  },
  '48440': {
    country: 'US',
    city: 'Hadley',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 42.9545,
    longitude: -83.4033,
    postal_code: '48440'
  },
  '48441': {
    country: 'US',
    city: 'Harbor Beach',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.8312,
    longitude: -82.6886,
    postal_code: '48441'
  },
  '48442': {
    country: 'US',
    city: 'Holly',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.7905,
    longitude: -83.6127,
    postal_code: '48442'
  },
  '48444': {
    country: 'US',
    city: 'Imlay City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 43.0425,
    longitude: -83.0708,
    postal_code: '48444'
  },
  '48445': {
    country: 'US',
    city: 'Kinde',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.948,
    longitude: -82.9755,
    postal_code: '48445'
  },
  '48446': {
    country: 'US',
    city: 'Lapeer',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 43.0579,
    longitude: -83.3332,
    postal_code: '48446'
  },
  '48449': {
    country: 'US',
    city: 'Lennon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9693,
    longitude: -83.9279,
    postal_code: '48449'
  },
  '48450': {
    country: 'US',
    city: 'Lexington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.2435,
    longitude: -82.5301,
    postal_code: '48450'
  },
  '48451': {
    country: 'US',
    city: 'Linden',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.8104,
    longitude: -83.7993,
    postal_code: '48451'
  },
  '48453': {
    country: 'US',
    city: 'Marlette',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.3399,
    longitude: -83.0573,
    postal_code: '48453'
  },
  '48454': {
    country: 'US',
    city: 'Melvin',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.193,
    longitude: -82.8393,
    postal_code: '48454'
  },
  '48455': {
    country: 'US',
    city: 'Metamora',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 42.9414,
    longitude: -83.2891,
    postal_code: '48455'
  },
  '48456': {
    country: 'US',
    city: 'Minden City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.6814,
    longitude: -82.7299,
    postal_code: '48456'
  },
  '48457': {
    country: 'US',
    city: 'Montrose',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.1754,
    longitude: -83.8824,
    postal_code: '48457'
  },
  '48458': {
    country: 'US',
    city: 'Mount Morris',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.116,
    longitude: -83.6895,
    postal_code: '48458'
  },
  '48460': {
    country: 'US',
    city: 'New Lothrop',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 43.1388,
    longitude: -83.9851,
    postal_code: '48460'
  },
  '48461': {
    country: 'US',
    city: 'North Branch',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 43.2069,
    longitude: -83.2267,
    postal_code: '48461'
  },
  '48462': {
    country: 'US',
    city: 'Ortonville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oakland',
    latitude: 42.8409,
    longitude: -83.4288,
    postal_code: '48462'
  },
  '48463': {
    country: 'US',
    city: 'Otisville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.1706,
    longitude: -83.5172,
    postal_code: '48463'
  },
  '48464': {
    country: 'US',
    city: 'Otter Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 43.2183,
    longitude: -83.4242,
    postal_code: '48464'
  },
  '48465': {
    country: 'US',
    city: 'Palms',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.6257,
    longitude: -82.7017,
    postal_code: '48465'
  },
  '48466': {
    country: 'US',
    city: 'Peck',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.2694,
    longitude: -82.8193,
    postal_code: '48466'
  },
  '48467': {
    country: 'US',
    city: 'Port Austin',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 44.0223,
    longitude: -82.9984,
    postal_code: '48467'
  },
  '48468': {
    country: 'US',
    city: 'Port Hope',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.928,
    longitude: -82.7529,
    postal_code: '48468'
  },
  '48469': {
    country: 'US',
    city: 'Port Sanilac',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.4329,
    longitude: -82.5468,
    postal_code: '48469'
  },
  '48470': {
    country: 'US',
    city: 'Ruth',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.7404,
    longitude: -82.7414,
    postal_code: '48470'
  },
  '48471': {
    country: 'US',
    city: 'Sandusky',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.4055,
    longitude: -82.8409,
    postal_code: '48471'
  },
  '48472': {
    country: 'US',
    city: 'Snover',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Sanilac',
    latitude: 43.4886,
    longitude: -82.9301,
    postal_code: '48472'
  },
  '48473': {
    country: 'US',
    city: 'Swartz Creek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9468,
    longitude: -83.817,
    postal_code: '48473'
  },
  '48475': {
    country: 'US',
    city: 'Ubly',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.6896,
    longitude: -82.964,
    postal_code: '48475'
  },
  '48476': {
    country: 'US',
    city: 'Vernon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 42.9406,
    longitude: -84.0328,
    postal_code: '48476'
  },
  '48480': {
    country: 'US',
    city: 'Grand Blanc',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.918,
    longitude: -83.617,
    postal_code: '48480'
  },
  '48501': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9659,
    longitude: -83.7808,
    postal_code: '48501'
  },
  '48502': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0151,
    longitude: -83.6948,
    postal_code: '48502'
  },
  '48503': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0128,
    longitude: -83.6914,
    postal_code: '48503'
  },
  '48504': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0573,
    longitude: -83.7498,
    postal_code: '48504'
  },
  '48505': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0634,
    longitude: -83.7001,
    postal_code: '48505'
  },
  '48506': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0653,
    longitude: -83.631,
    postal_code: '48506'
  },
  '48507': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9821,
    longitude: -83.734,
    postal_code: '48507'
  },
  '48509': {
    country: 'US',
    city: 'Burton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0259,
    longitude: -83.6041,
    postal_code: '48509'
  },
  '48519': {
    country: 'US',
    city: 'Burton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9859,
    longitude: -83.6135,
    postal_code: '48519'
  },
  '48529': {
    country: 'US',
    city: 'Burton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9744,
    longitude: -83.6629,
    postal_code: '48529'
  },
  '48531': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.002,
    longitude: -83.6925,
    postal_code: '48531'
  },
  '48532': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0111,
    longitude: -83.803,
    postal_code: '48532'
  },
  '48550': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0349,
    longitude: -83.6887,
    postal_code: '48550'
  },
  '48551': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.979,
    longitude: -83.7131,
    postal_code: '48551'
  },
  '48552': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9779,
    longitude: -83.7131,
    postal_code: '48552'
  },
  '48553': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9736,
    longitude: -83.7203,
    postal_code: '48553'
  },
  '48554': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 42.9722,
    longitude: -83.7946,
    postal_code: '48554'
  },
  '48555': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0113,
    longitude: -83.7108,
    postal_code: '48555'
  },
  '48556': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0327,
    longitude: -83.6463,
    postal_code: '48556'
  },
  '48557': {
    country: 'US',
    city: 'Flint',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Genesee',
    latitude: 43.0806,
    longitude: -83.7837,
    postal_code: '48557'
  },
  '48601': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.4047,
    longitude: -83.9156,
    postal_code: '48601'
  },
  '48602': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.4248,
    longitude: -83.9745,
    postal_code: '48602'
  },
  '48603': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.4631,
    longitude: -84.0297,
    postal_code: '48603'
  },
  '48604': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.4732,
    longitude: -83.9514,
    postal_code: '48604'
  },
  '48605': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.4588,
    longitude: -84.0518,
    postal_code: '48605'
  },
  '48606': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.3485,
    longitude: -84.0326,
    postal_code: '48606'
  },
  '48607': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.4301,
    longitude: -83.9319,
    postal_code: '48607'
  },
  '48608': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.3485,
    longitude: -84.0326,
    postal_code: '48608'
  },
  '48609': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.3869,
    longitude: -84.0926,
    postal_code: '48609'
  },
  '48610': {
    country: 'US',
    city: 'Alger',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ogemaw',
    latitude: 44.1395,
    longitude: -84.1872,
    postal_code: '48610'
  },
  '48611': {
    country: 'US',
    city: 'Auburn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.608,
    longitude: -84.1027,
    postal_code: '48611'
  },
  '48612': {
    country: 'US',
    city: 'Beaverton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gladwin',
    latitude: 43.8866,
    longitude: -84.4241,
    postal_code: '48612'
  },
  '48613': {
    country: 'US',
    city: 'Bentley',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.9267,
    longitude: -84.1053,
    postal_code: '48613'
  },
  '48614': {
    country: 'US',
    city: 'Brant',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.2548,
    longitude: -84.2978,
    postal_code: '48614'
  },
  '48615': {
    country: 'US',
    city: 'Breckenridge',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.3935,
    longitude: -84.5023,
    postal_code: '48615'
  },
  '48616': {
    country: 'US',
    city: 'Chesaning',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.1824,
    longitude: -84.1122,
    postal_code: '48616'
  },
  '48617': {
    country: 'US',
    city: 'Clare',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clare',
    latitude: 43.8223,
    longitude: -84.7635,
    postal_code: '48617'
  },
  '48618': {
    country: 'US',
    city: 'Coleman',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.7494,
    longitude: -84.5911,
    postal_code: '48618'
  },
  '48619': {
    country: 'US',
    city: 'Comins',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oscoda',
    latitude: 44.8264,
    longitude: -84.0261,
    postal_code: '48619'
  },
  '48620': {
    country: 'US',
    city: 'Edenville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.7774,
    longitude: -84.3813,
    postal_code: '48620'
  },
  '48621': {
    country: 'US',
    city: 'Fairview',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oscoda',
    latitude: 44.7205,
    longitude: -84.0525,
    postal_code: '48621'
  },
  '48622': {
    country: 'US',
    city: 'Farwell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clare',
    latitude: 43.8342,
    longitude: -84.8754,
    postal_code: '48622'
  },
  '48623': {
    country: 'US',
    city: 'Freeland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.5161,
    longitude: -84.1822,
    postal_code: '48623'
  },
  '48624': {
    country: 'US',
    city: 'Gladwin',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gladwin',
    latitude: 44.0296,
    longitude: -84.4968,
    postal_code: '48624'
  },
  '48625': {
    country: 'US',
    city: 'Harrison',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clare',
    latitude: 44.0285,
    longitude: -84.7729,
    postal_code: '48625'
  },
  '48626': {
    country: 'US',
    city: 'Hemlock',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.4099,
    longitude: -84.2266,
    postal_code: '48626'
  },
  '48627': {
    country: 'US',
    city: 'Higgins Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Roscommon',
    latitude: 44.3871,
    longitude: -84.7013,
    postal_code: '48627'
  },
  '48628': {
    country: 'US',
    city: 'Hope',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.7882,
    longitude: -84.3296,
    postal_code: '48628'
  },
  '48629': {
    country: 'US',
    city: 'Houghton Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Roscommon',
    latitude: 44.3147,
    longitude: -84.7647,
    postal_code: '48629'
  },
  '48630': {
    country: 'US',
    city: 'Houghton Lake Heights',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Roscommon',
    latitude: 44.3275,
    longitude: -84.7745,
    postal_code: '48630'
  },
  '48631': {
    country: 'US',
    city: 'Kawkawlin',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.6794,
    longitude: -83.9927,
    postal_code: '48631'
  },
  '48632': {
    country: 'US',
    city: 'Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clare',
    latitude: 43.8575,
    longitude: -85.0219,
    postal_code: '48632'
  },
  '48633': {
    country: 'US',
    city: 'Lake George',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clare',
    latitude: 43.9634,
    longitude: -84.947,
    postal_code: '48633'
  },
  '48634': {
    country: 'US',
    city: 'Linwood',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.7714,
    longitude: -84.0513,
    postal_code: '48634'
  },
  '48635': {
    country: 'US',
    city: 'Lupton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ogemaw',
    latitude: 44.3976,
    longitude: -83.9905,
    postal_code: '48635'
  },
  '48636': {
    country: 'US',
    city: 'Luzerne',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oscoda',
    latitude: 44.6001,
    longitude: -84.2931,
    postal_code: '48636'
  },
  '48637': {
    country: 'US',
    city: 'Merrill',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.3939,
    longitude: -84.3308,
    postal_code: '48637'
  },
  '48638': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gladwin',
    latitude: 43.4343,
    longitude: -84.0091,
    postal_code: '48638'
  },
  '48640': {
    country: 'US',
    city: 'Midland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.6376,
    longitude: -84.268,
    postal_code: '48640'
  },
  '48641': {
    country: 'US',
    city: 'Midland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.5383,
    longitude: -84.3878,
    postal_code: '48641'
  },
  '48642': {
    country: 'US',
    city: 'Midland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.6375,
    longitude: -84.1979,
    postal_code: '48642'
  },
  '48647': {
    country: 'US',
    city: 'Mio',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oscoda',
    latitude: 44.6665,
    longitude: -84.1353,
    postal_code: '48647'
  },
  '48649': {
    country: 'US',
    city: 'Oakley',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.1505,
    longitude: -84.2094,
    postal_code: '48649'
  },
  '48650': {
    country: 'US',
    city: 'Pinconning',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.8491,
    longitude: -84.0082,
    postal_code: '48650'
  },
  '48651': {
    country: 'US',
    city: 'Prudenville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Roscommon',
    latitude: 44.2974,
    longitude: -84.6627,
    postal_code: '48651'
  },
  '48652': {
    country: 'US',
    city: 'Rhodes',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gladwin',
    latitude: 43.8517,
    longitude: -84.2134,
    postal_code: '48652'
  },
  '48653': {
    country: 'US',
    city: 'Roscommon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Roscommon',
    latitude: 44.4839,
    longitude: -84.6601,
    postal_code: '48653'
  },
  '48654': {
    country: 'US',
    city: 'Rose City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ogemaw',
    latitude: 44.4167,
    longitude: -84.1256,
    postal_code: '48654'
  },
  '48655': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.2863,
    longitude: -84.1598,
    postal_code: '48655'
  },
  '48656': {
    country: 'US',
    city: 'Saint Helen',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Roscommon',
    latitude: 44.3665,
    longitude: -84.4247,
    postal_code: '48656'
  },
  '48657': {
    country: 'US',
    city: 'Sanford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.7204,
    longitude: -84.3954,
    postal_code: '48657'
  },
  '48658': {
    country: 'US',
    city: 'Standish',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Arenac',
    latitude: 43.9733,
    longitude: -83.9433,
    postal_code: '48658'
  },
  '48659': {
    country: 'US',
    city: 'Sterling',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Arenac',
    latitude: 44.0669,
    longitude: -84.0505,
    postal_code: '48659'
  },
  '48661': {
    country: 'US',
    city: 'West Branch',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ogemaw',
    latitude: 44.279,
    longitude: -84.2286,
    postal_code: '48661'
  },
  '48662': {
    country: 'US',
    city: 'Wheeler',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.3962,
    longitude: -84.4243,
    postal_code: '48662'
  },
  '48663': {
    country: 'US',
    city: 'Saginaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.4195,
    longitude: -83.9508,
    postal_code: '48663'
  },
  '48667': {
    country: 'US',
    city: 'Midland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.6156,
    longitude: -84.2472,
    postal_code: '48667'
  },
  '48670': {
    country: 'US',
    city: 'Midland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.6375,
    longitude: -84.2568,
    postal_code: '48670'
  },
  '48674': {
    country: 'US',
    city: 'Midland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.6129,
    longitude: -84.1971,
    postal_code: '48674'
  },
  '48686': {
    country: 'US',
    city: 'Midland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Midland',
    latitude: 43.6473,
    longitude: -84.3873,
    postal_code: '48686'
  },
  '48701': {
    country: 'US',
    city: 'Akron',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.5844,
    longitude: -83.5393,
    postal_code: '48701'
  },
  '48703': {
    country: 'US',
    city: 'Au Gres',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Arenac',
    latitude: 44.0338,
    longitude: -83.702,
    postal_code: '48703'
  },
  '48705': {
    country: 'US',
    city: 'Barton City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alcona',
    latitude: 44.702,
    longitude: -83.5994,
    postal_code: '48705'
  },
  '48706': {
    country: 'US',
    city: 'Bay City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.6088,
    longitude: -83.953,
    postal_code: '48706'
  },
  '48707': {
    country: 'US',
    city: 'Bay City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.7378,
    longitude: -83.9333,
    postal_code: '48707'
  },
  '48708': {
    country: 'US',
    city: 'Bay City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.5821,
    longitude: -83.8781,
    postal_code: '48708'
  },
  '48710': {
    country: 'US',
    city: 'University Center',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.5566,
    longitude: -83.9943,
    postal_code: '48710'
  },
  '48720': {
    country: 'US',
    city: 'Bay Port',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.8377,
    longitude: -83.3525,
    postal_code: '48720'
  },
  '48721': {
    country: 'US',
    city: 'Black River',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alcona',
    latitude: 44.8138,
    longitude: -83.3407,
    postal_code: '48721'
  },
  '48722': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.3553,
    longitude: -83.8549,
    postal_code: '48722'
  },
  '48723': {
    country: 'US',
    city: 'Caro',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.4833,
    longitude: -83.3835,
    postal_code: '48723'
  },
  '48724': {
    country: 'US',
    city: 'Carrollton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.4586,
    longitude: -83.9303,
    postal_code: '48724'
  },
  '48725': {
    country: 'US',
    city: 'Caseville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.9429,
    longitude: -83.2659,
    postal_code: '48725'
  },
  '48726': {
    country: 'US',
    city: 'Cass City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.5797,
    longitude: -83.1733,
    postal_code: '48726'
  },
  '48727': {
    country: 'US',
    city: 'Clifford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 43.31,
    longitude: -83.1741,
    postal_code: '48727'
  },
  '48728': {
    country: 'US',
    city: 'Curran',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alcona',
    latitude: 44.7336,
    longitude: -83.832,
    postal_code: '48728'
  },
  '48729': {
    country: 'US',
    city: 'Deford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.4735,
    longitude: -83.1702,
    postal_code: '48729'
  },
  '48730': {
    country: 'US',
    city: 'East Tawas',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iosco',
    latitude: 44.3008,
    longitude: -83.4776,
    postal_code: '48730'
  },
  '48731': {
    country: 'US',
    city: 'Elkton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.8344,
    longitude: -83.1786,
    postal_code: '48731'
  },
  '48732': {
    country: 'US',
    city: 'Essexville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.6069,
    longitude: -83.8217,
    postal_code: '48732'
  },
  '48733': {
    country: 'US',
    city: 'Fairgrove',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.5126,
    longitude: -83.5835,
    postal_code: '48733'
  },
  '48734': {
    country: 'US',
    city: 'Frankenmuth',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.341,
    longitude: -83.7475,
    postal_code: '48734'
  },
  '48735': {
    country: 'US',
    city: 'Gagetown',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.6543,
    longitude: -83.2628,
    postal_code: '48735'
  },
  '48737': {
    country: 'US',
    city: 'Glennie',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alcona',
    latitude: 44.5582,
    longitude: -83.6899,
    postal_code: '48737'
  },
  '48738': {
    country: 'US',
    city: 'Greenbush',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alcona',
    latitude: 44.548,
    longitude: -83.3269,
    postal_code: '48738'
  },
  '48739': {
    country: 'US',
    city: 'Hale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iosco',
    latitude: 44.3819,
    longitude: -83.8359,
    postal_code: '48739'
  },
  '48740': {
    country: 'US',
    city: 'Harrisville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alcona',
    latitude: 44.6546,
    longitude: -83.3424,
    postal_code: '48740'
  },
  '48741': {
    country: 'US',
    city: 'Kingston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.3982,
    longitude: -83.1847,
    postal_code: '48741'
  },
  '48742': {
    country: 'US',
    city: 'Lincoln',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alcona',
    latitude: 44.7111,
    longitude: -83.3947,
    postal_code: '48742'
  },
  '48743': {
    country: 'US',
    city: 'Long Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iosco',
    latitude: 44.4197,
    longitude: -83.8719,
    postal_code: '48743'
  },
  '48744': {
    country: 'US',
    city: 'Mayville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.3562,
    longitude: -83.3725,
    postal_code: '48744'
  },
  '48745': {
    country: 'US',
    city: 'Mikado',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alcona',
    latitude: 44.5833,
    longitude: -83.4355,
    postal_code: '48745'
  },
  '48746': {
    country: 'US',
    city: 'Millington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.2718,
    longitude: -83.5619,
    postal_code: '48746'
  },
  '48747': {
    country: 'US',
    city: 'Munger',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Bay',
    latitude: 43.5286,
    longitude: -83.7672,
    postal_code: '48747'
  },
  '48748': {
    country: 'US',
    city: 'National City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iosco',
    latitude: 44.3137,
    longitude: -83.6839,
    postal_code: '48748'
  },
  '48749': {
    country: 'US',
    city: 'Omer',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Arenac',
    latitude: 44.0499,
    longitude: -83.843,
    postal_code: '48749'
  },
  '48750': {
    country: 'US',
    city: 'Oscoda',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iosco',
    latitude: 44.4465,
    longitude: -83.3619,
    postal_code: '48750'
  },
  '48754': {
    country: 'US',
    city: 'Owendale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.7206,
    longitude: -83.2307,
    postal_code: '48754'
  },
  '48755': {
    country: 'US',
    city: 'Pigeon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.8179,
    longitude: -83.2755,
    postal_code: '48755'
  },
  '48756': {
    country: 'US',
    city: 'Prescott',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ogemaw',
    latitude: 44.21,
    longitude: -84.0212,
    postal_code: '48756'
  },
  '48757': {
    country: 'US',
    city: 'Reese',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.4531,
    longitude: -83.7015,
    postal_code: '48757'
  },
  '48758': {
    country: 'US',
    city: 'Richville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.4073,
    longitude: -83.6762,
    postal_code: '48758'
  },
  '48759': {
    country: 'US',
    city: 'Sebewaing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Huron',
    latitude: 43.7289,
    longitude: -83.4366,
    postal_code: '48759'
  },
  '48760': {
    country: 'US',
    city: 'Silverwood',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lapeer',
    latitude: 43.3141,
    longitude: -83.272,
    postal_code: '48760'
  },
  '48761': {
    country: 'US',
    city: 'South Branch',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ogemaw',
    latitude: 44.5014,
    longitude: -83.8686,
    postal_code: '48761'
  },
  '48762': {
    country: 'US',
    city: 'Spruce',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alcona',
    latitude: 44.8224,
    longitude: -83.5044,
    postal_code: '48762'
  },
  '48763': {
    country: 'US',
    city: 'Tawas City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iosco',
    latitude: 44.2675,
    longitude: -83.5449,
    postal_code: '48763'
  },
  '48764': {
    country: 'US',
    city: 'Tawas City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iosco',
    latitude: 44.2665,
    longitude: -83.5192,
    postal_code: '48764'
  },
  '48765': {
    country: 'US',
    city: 'Turner',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Arenac',
    latitude: 44.1105,
    longitude: -83.7019,
    postal_code: '48765'
  },
  '48766': {
    country: 'US',
    city: 'Twining',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Arenac',
    latitude: 44.1293,
    longitude: -83.8491,
    postal_code: '48766'
  },
  '48767': {
    country: 'US',
    city: 'Unionville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.6473,
    longitude: -83.4699,
    postal_code: '48767'
  },
  '48768': {
    country: 'US',
    city: 'Vassar',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Tuscola',
    latitude: 43.3691,
    longitude: -83.5844,
    postal_code: '48768'
  },
  '48770': {
    country: 'US',
    city: 'Whittemore',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iosco',
    latitude: 44.2325,
    longitude: -83.8068,
    postal_code: '48770'
  },
  '48787': {
    country: 'US',
    city: 'Frankenmuth',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Saginaw',
    latitude: 43.335,
    longitude: -83.7494,
    postal_code: '48787'
  },
  '48801': {
    country: 'US',
    city: 'Alma',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.3809,
    longitude: -84.6635,
    postal_code: '48801'
  },
  '48804': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Isabella',
    latitude: 43.6406,
    longitude: -84.8474,
    postal_code: '48804'
  },
  '48805': {
    country: 'US',
    city: 'Okemos',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.5992,
    longitude: -84.372,
    postal_code: '48805'
  },
  '48806': {
    country: 'US',
    city: 'Ashley',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.1891,
    longitude: -84.488,
    postal_code: '48806'
  },
  '48807': {
    country: 'US',
    city: 'Bannister',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.1615,
    longitude: -84.3597,
    postal_code: '48807'
  },
  '48808': {
    country: 'US',
    city: 'Bath',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clinton',
    latitude: 42.8206,
    longitude: -84.4545,
    postal_code: '48808'
  },
  '48809': {
    country: 'US',
    city: 'Belding',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 43.0885,
    longitude: -85.2313,
    postal_code: '48809'
  },
  '48811': {
    country: 'US',
    city: 'Carson City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.1695,
    longitude: -84.8653,
    postal_code: '48811'
  },
  '48812': {
    country: 'US',
    city: 'Cedar Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.4138,
    longitude: -84.9753,
    postal_code: '48812'
  },
  '48813': {
    country: 'US',
    city: 'Charlotte',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.5702,
    longitude: -84.8352,
    postal_code: '48813'
  },
  '48815': {
    country: 'US',
    city: 'Clarksville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 42.8302,
    longitude: -85.2494,
    postal_code: '48815'
  },
  '48816': {
    country: 'US',
    city: 'Cohoctah',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.7598,
    longitude: -83.9486,
    postal_code: '48816'
  },
  '48817': {
    country: 'US',
    city: 'Corunna',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 43.0414,
    longitude: -84.0276,
    postal_code: '48817'
  },
  '48818': {
    country: 'US',
    city: 'Crystal',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.2624,
    longitude: -84.8993,
    postal_code: '48818'
  },
  '48819': {
    country: 'US',
    city: 'Dansville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.5505,
    longitude: -84.2939,
    postal_code: '48819'
  },
  '48820': {
    country: 'US',
    city: 'Dewitt',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clinton',
    latitude: 42.8428,
    longitude: -84.5797,
    postal_code: '48820'
  },
  '48821': {
    country: 'US',
    city: 'Dimondale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.6501,
    longitude: -84.6486,
    postal_code: '48821'
  },
  '48822': {
    country: 'US',
    city: 'Eagle',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clinton',
    latitude: 42.8263,
    longitude: -84.759,
    postal_code: '48822'
  },
  '48823': {
    country: 'US',
    city: 'East Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7388,
    longitude: -84.4764,
    postal_code: '48823'
  },
  '48824': {
    country: 'US',
    city: 'East Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7283,
    longitude: -84.4882,
    postal_code: '48824'
  },
  '48825': {
    country: 'US',
    city: 'East Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7238,
    longitude: -84.4648,
    postal_code: '48825'
  },
  '48826': {
    country: 'US',
    city: 'East Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.737,
    longitude: -84.4839,
    postal_code: '48826'
  },
  '48827': {
    country: 'US',
    city: 'Eaton Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.5166,
    longitude: -84.6565,
    postal_code: '48827'
  },
  '48829': {
    country: 'US',
    city: 'Edmore',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.4116,
    longitude: -85.028,
    postal_code: '48829'
  },
  '48830': {
    country: 'US',
    city: 'Elm Hall',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.3639,
    longitude: -84.8362,
    postal_code: '48830'
  },
  '48831': {
    country: 'US',
    city: 'Elsie',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clinton',
    latitude: 43.0886,
    longitude: -84.3869,
    postal_code: '48831'
  },
  '48832': {
    country: 'US',
    city: 'Elwell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.4106,
    longitude: -84.7631,
    postal_code: '48832'
  },
  '48833': {
    country: 'US',
    city: 'Eureka',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clinton',
    latitude: 43.1036,
    longitude: -84.5139,
    postal_code: '48833'
  },
  '48834': {
    country: 'US',
    city: 'Fenwick',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.1497,
    longitude: -85.0666,
    postal_code: '48834'
  },
  '48835': {
    country: 'US',
    city: 'Fowler',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clinton',
    latitude: 42.9941,
    longitude: -84.76,
    postal_code: '48835'
  },
  '48836': {
    country: 'US',
    city: 'Fowlerville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.6614,
    longitude: -84.0721,
    postal_code: '48836'
  },
  '48837': {
    country: 'US',
    city: 'Grand Ledge',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.7529,
    longitude: -84.7373,
    postal_code: '48837'
  },
  '48838': {
    country: 'US',
    city: 'Greenville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.1793,
    longitude: -85.2497,
    postal_code: '48838'
  },
  '48840': {
    country: 'US',
    city: 'Haslett',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7531,
    longitude: -84.3989,
    postal_code: '48840'
  },
  '48841': {
    country: 'US',
    city: 'Henderson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 43.1143,
    longitude: -84.2369,
    postal_code: '48841'
  },
  '48842': {
    country: 'US',
    city: 'Holt',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.6394,
    longitude: -84.5242,
    postal_code: '48842'
  },
  '48843': {
    country: 'US',
    city: 'Howell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.6159,
    longitude: -83.9248,
    postal_code: '48843'
  },
  '48844': {
    country: 'US',
    city: 'Howell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.6035,
    longitude: -83.9112,
    postal_code: '48844'
  },
  '48845': {
    country: 'US',
    city: 'Hubbardston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 43.0923,
    longitude: -84.8422,
    postal_code: '48845'
  },
  '48846': {
    country: 'US',
    city: 'Ionia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 42.9859,
    longitude: -85.071,
    postal_code: '48846'
  },
  '48847': {
    country: 'US',
    city: 'Ithaca',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.2828,
    longitude: -84.6088,
    postal_code: '48847'
  },
  '48848': {
    country: 'US',
    city: 'Laingsburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 42.8627,
    longitude: -84.353,
    postal_code: '48848'
  },
  '48849': {
    country: 'US',
    city: 'Lake Odessa',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 42.7863,
    longitude: -85.1357,
    postal_code: '48849'
  },
  '48850': {
    country: 'US',
    city: 'Lakeview',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.4269,
    longitude: -85.2924,
    postal_code: '48850'
  },
  '48851': {
    country: 'US',
    city: 'Lyons',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 42.9634,
    longitude: -84.9209,
    postal_code: '48851'
  },
  '48852': {
    country: 'US',
    city: 'Mcbrides',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.3503,
    longitude: -85.0517,
    postal_code: '48852'
  },
  '48853': {
    country: 'US',
    city: 'Maple Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clinton',
    latitude: 43.0992,
    longitude: -84.6898,
    postal_code: '48853'
  },
  '48854': {
    country: 'US',
    city: 'Mason',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.5796,
    longitude: -84.4561,
    postal_code: '48854'
  },
  '48855': {
    country: 'US',
    city: 'Howell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Livingston',
    latitude: 42.6871,
    longitude: -83.914,
    postal_code: '48855'
  },
  '48856': {
    country: 'US',
    city: 'Middleton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.1689,
    longitude: -84.7552,
    postal_code: '48856'
  },
  '48857': {
    country: 'US',
    city: 'Morrice',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 42.8385,
    longitude: -84.1768,
    postal_code: '48857'
  },
  '48858': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Isabella',
    latitude: 43.6013,
    longitude: -84.7736,
    postal_code: '48858'
  },
  '48859': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Isabella',
    latitude: 43.5647,
    longitude: -84.8473,
    postal_code: '48859'
  },
  '48860': {
    country: 'US',
    city: 'Muir',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 43.0439,
    longitude: -84.9391,
    postal_code: '48860'
  },
  '48861': {
    country: 'US',
    city: 'Mulliken',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.7377,
    longitude: -84.8979,
    postal_code: '48861'
  },
  '48862': {
    country: 'US',
    city: 'North Star',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.2484,
    longitude: -84.5433,
    postal_code: '48862'
  },
  '48864': {
    country: 'US',
    city: 'Okemos',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7053,
    longitude: -84.4187,
    postal_code: '48864'
  },
  '48865': {
    country: 'US',
    city: 'Orleans',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 43.0895,
    longitude: -85.1165,
    postal_code: '48865'
  },
  '48866': {
    country: 'US',
    city: 'Ovid',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clinton',
    latitude: 42.9969,
    longitude: -84.3649,
    postal_code: '48866'
  },
  '48867': {
    country: 'US',
    city: 'Owosso',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 42.9934,
    longitude: -84.1595,
    postal_code: '48867'
  },
  '48870': {
    country: 'US',
    city: 'Palo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 43.1125,
    longitude: -84.9856,
    postal_code: '48870'
  },
  '48871': {
    country: 'US',
    city: 'Perrinton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.1649,
    longitude: -84.666,
    postal_code: '48871'
  },
  '48872': {
    country: 'US',
    city: 'Perry',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 42.82,
    longitude: -84.2313,
    postal_code: '48872'
  },
  '48873': {
    country: 'US',
    city: 'Pewamo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 43.0007,
    longitude: -84.8492,
    postal_code: '48873'
  },
  '48874': {
    country: 'US',
    city: 'Pompeii',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.1863,
    longitude: -84.6017,
    postal_code: '48874'
  },
  '48875': {
    country: 'US',
    city: 'Portland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 42.8624,
    longitude: -84.9139,
    postal_code: '48875'
  },
  '48876': {
    country: 'US',
    city: 'Potterville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.6398,
    longitude: -84.7346,
    postal_code: '48876'
  },
  '48877': {
    country: 'US',
    city: 'Riverdale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.4017,
    longitude: -84.8429,
    postal_code: '48877'
  },
  '48878': {
    country: 'US',
    city: 'Rosebush',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Isabella',
    latitude: 43.6843,
    longitude: -84.7833,
    postal_code: '48878'
  },
  '48879': {
    country: 'US',
    city: 'Saint Johns',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clinton',
    latitude: 43.0059,
    longitude: -84.5719,
    postal_code: '48879'
  },
  '48880': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.4278,
    longitude: -84.5952,
    postal_code: '48880'
  },
  '48881': {
    country: 'US',
    city: 'Saranac',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 42.9357,
    longitude: -85.203,
    postal_code: '48881'
  },
  '48882': {
    country: 'US',
    city: 'Shaftsburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Shiawassee',
    latitude: 42.804,
    longitude: -84.2959,
    postal_code: '48882'
  },
  '48883': {
    country: 'US',
    city: 'Shepherd',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Isabella',
    latitude: 43.5657,
    longitude: -84.5873,
    postal_code: '48883'
  },
  '48884': {
    country: 'US',
    city: 'Sheridan',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.2126,
    longitude: -85.0468,
    postal_code: '48884'
  },
  '48885': {
    country: 'US',
    city: 'Sidney',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.2358,
    longitude: -85.1207,
    postal_code: '48885'
  },
  '48886': {
    country: 'US',
    city: 'Six Lakes',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.4337,
    longitude: -85.1416,
    postal_code: '48886'
  },
  '48887': {
    country: 'US',
    city: 'Smyrna',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ionia',
    latitude: 43.0595,
    longitude: -85.2628,
    postal_code: '48887'
  },
  '48888': {
    country: 'US',
    city: 'Stanton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.3058,
    longitude: -85.0995,
    postal_code: '48888'
  },
  '48889': {
    country: 'US',
    city: 'Sumner',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gratiot',
    latitude: 43.3091,
    longitude: -84.7907,
    postal_code: '48889'
  },
  '48890': {
    country: 'US',
    city: 'Sunfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.7693,
    longitude: -84.9813,
    postal_code: '48890'
  },
  '48891': {
    country: 'US',
    city: 'Vestaburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.387,
    longitude: -84.9082,
    postal_code: '48891'
  },
  '48892': {
    country: 'US',
    city: 'Webberville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.663,
    longitude: -84.1701,
    postal_code: '48892'
  },
  '48893': {
    country: 'US',
    city: 'Weidman',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Isabella',
    latitude: 43.6453,
    longitude: -85.0046,
    postal_code: '48893'
  },
  '48894': {
    country: 'US',
    city: 'Westphalia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Clinton',
    latitude: 42.9328,
    longitude: -84.8083,
    postal_code: '48894'
  },
  '48895': {
    country: 'US',
    city: 'Williamston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.6967,
    longitude: -84.2926,
    postal_code: '48895'
  },
  '48896': {
    country: 'US',
    city: 'Winn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Isabella',
    latitude: 43.5234,
    longitude: -84.9017,
    postal_code: '48896'
  },
  '48897': {
    country: 'US',
    city: 'Woodland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Barry',
    latitude: 42.7057,
    longitude: -85.1326,
    postal_code: '48897'
  },
  '48901': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5555,
    postal_code: '48901'
  },
  '48906': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7635,
    longitude: -84.558,
    postal_code: '48906'
  },
  '48908': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.5961,
    longitude: -84.8382,
    postal_code: '48908'
  },
  '48909': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5555,
    postal_code: '48909'
  },
  '48910': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7008,
    longitude: -84.549,
    postal_code: '48910'
  },
  '48911': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.6797,
    longitude: -84.5772,
    postal_code: '48911'
  },
  '48912': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7371,
    longitude: -84.5244,
    postal_code: '48912'
  },
  '48913': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5555,
    postal_code: '48913'
  },
  '48915': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7391,
    longitude: -84.5704,
    postal_code: '48915'
  },
  '48916': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5555,
    postal_code: '48916'
  },
  '48917': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.7376,
    longitude: -84.6244,
    postal_code: '48917'
  },
  '48918': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5555,
    postal_code: '48918'
  },
  '48919': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7286,
    longitude: -84.5517,
    postal_code: '48919'
  },
  '48922': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5587,
    postal_code: '48922'
  },
  '48924': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5555,
    postal_code: '48924'
  },
  '48929': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5587,
    postal_code: '48929'
  },
  '48930': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5587,
    postal_code: '48930'
  },
  '48933': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7334,
    longitude: -84.5571,
    postal_code: '48933'
  },
  '48937': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7487,
    longitude: -84.559,
    postal_code: '48937'
  },
  '48951': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7035,
    longitude: -84.5365,
    postal_code: '48951'
  },
  '48956': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5587,
    postal_code: '48956'
  },
  '48980': {
    country: 'US',
    city: 'Lansing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.7325,
    longitude: -84.5555,
    postal_code: '48980'
  },
  '49001': {
    country: 'US',
    city: 'Kalamazoo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2736,
    longitude: -85.5457,
    postal_code: '49001'
  },
  '49002': {
    country: 'US',
    city: 'Portage',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.1938,
    longitude: -85.5639,
    postal_code: '49002'
  },
  '49003': {
    country: 'US',
    city: 'Kalamazoo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2454,
    longitude: -85.5299,
    postal_code: '49003'
  },
  '49004': {
    country: 'US',
    city: 'Kalamazoo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.3518,
    longitude: -85.5621,
    postal_code: '49004'
  },
  '49005': {
    country: 'US',
    city: 'Kalamazoo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2917,
    longitude: -85.5872,
    postal_code: '49005'
  },
  '49006': {
    country: 'US',
    city: 'Kalamazoo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2922,
    longitude: -85.633,
    postal_code: '49006'
  },
  '49007': {
    country: 'US',
    city: 'Kalamazoo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.3024,
    longitude: -85.5882,
    postal_code: '49007'
  },
  '49008': {
    country: 'US',
    city: 'Kalamazoo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2624,
    longitude: -85.6096,
    postal_code: '49008'
  },
  '49009': {
    country: 'US',
    city: 'Kalamazoo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2809,
    longitude: -85.6863,
    postal_code: '49009'
  },
  '49010': {
    country: 'US',
    city: 'Allegan',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.5256,
    longitude: -85.8661,
    postal_code: '49010'
  },
  '49011': {
    country: 'US',
    city: 'Athens',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.103,
    longitude: -85.2317,
    postal_code: '49011'
  },
  '49012': {
    country: 'US',
    city: 'Augusta',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.3563,
    longitude: -85.354,
    postal_code: '49012'
  },
  '49013': {
    country: 'US',
    city: 'Bangor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.3312,
    longitude: -86.1311,
    postal_code: '49013'
  },
  '49014': {
    country: 'US',
    city: 'Battle Creek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.303,
    longitude: -85.1304,
    postal_code: '49014'
  },
  '49015': {
    country: 'US',
    city: 'Battle Creek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.3028,
    longitude: -85.2128,
    postal_code: '49015'
  },
  '49016': {
    country: 'US',
    city: 'Battle Creek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.2464,
    longitude: -85.0045,
    postal_code: '49016'
  },
  '49017': {
    country: 'US',
    city: 'Battle Creek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.3173,
    longitude: -85.1782,
    postal_code: '49017'
  },
  '49018': {
    country: 'US',
    city: 'Battle Creek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.2464,
    longitude: -85.0045,
    postal_code: '49018'
  },
  '49019': {
    country: 'US',
    city: 'Kalamazoo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2454,
    longitude: -85.5299,
    postal_code: '49019'
  },
  '49020': {
    country: 'US',
    city: 'Bedford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.3953,
    longitude: -85.2322,
    postal_code: '49020'
  },
  '49021': {
    country: 'US',
    city: 'Bellevue',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.4525,
    longitude: -85.0489,
    postal_code: '49021'
  },
  '49022': {
    country: 'US',
    city: 'Benton Harbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 42.1086,
    longitude: -86.4234,
    postal_code: '49022'
  },
  '49023': {
    country: 'US',
    city: 'Benton Harbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 42.1167,
    longitude: -86.4542,
    postal_code: '49023'
  },
  '49024': {
    country: 'US',
    city: 'Portage',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.1974,
    longitude: -85.6194,
    postal_code: '49024'
  },
  '49026': {
    country: 'US',
    city: 'Bloomingdale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.3842,
    longitude: -85.9568,
    postal_code: '49026'
  },
  '49027': {
    country: 'US',
    city: 'Breedsville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.3465,
    longitude: -86.0725,
    postal_code: '49027'
  },
  '49028': {
    country: 'US',
    city: 'Bronson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Branch',
    latitude: 41.8643,
    longitude: -85.1838,
    postal_code: '49028'
  },
  '49029': {
    country: 'US',
    city: 'Burlington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.1239,
    longitude: -85.105,
    postal_code: '49029'
  },
  '49030': {
    country: 'US',
    city: 'Burr Oak',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Joseph',
    latitude: 41.8459,
    longitude: -85.3345,
    postal_code: '49030'
  },
  '49031': {
    country: 'US',
    city: 'Cassopolis',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cass',
    latitude: 41.8968,
    longitude: -85.9923,
    postal_code: '49031'
  },
  '49032': {
    country: 'US',
    city: 'Centreville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Joseph',
    latitude: 41.9217,
    longitude: -85.4963,
    postal_code: '49032'
  },
  '49033': {
    country: 'US',
    city: 'Ceresco',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.2127,
    longitude: -85.1128,
    postal_code: '49033'
  },
  '49034': {
    country: 'US',
    city: 'Climax',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.234,
    longitude: -85.3238,
    postal_code: '49034'
  },
  '49035': {
    country: 'US',
    city: 'Cloverdale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Barry',
    latitude: 42.5951,
    longitude: -85.3086,
    postal_code: '49035'
  },
  '49036': {
    country: 'US',
    city: 'Coldwater',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Branch',
    latitude: 41.9255,
    longitude: -85.0057,
    postal_code: '49036'
  },
  '49037': {
    country: 'US',
    city: 'Battle Creek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.3453,
    longitude: -85.2178,
    postal_code: '49037'
  },
  '49038': {
    country: 'US',
    city: 'Coloma',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 42.203,
    longitude: -86.3225,
    postal_code: '49038'
  },
  '49039': {
    country: 'US',
    city: 'Hagar Shores',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 42.2248,
    longitude: -86.3723,
    postal_code: '49039'
  },
  '49040': {
    country: 'US',
    city: 'Colon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Joseph',
    latitude: 41.9576,
    longitude: -85.3306,
    postal_code: '49040'
  },
  '49041': {
    country: 'US',
    city: 'Comstock',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2867,
    longitude: -85.5133,
    postal_code: '49041'
  },
  '49042': {
    country: 'US',
    city: 'Constantine',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Joseph',
    latitude: 41.846,
    longitude: -85.6571,
    postal_code: '49042'
  },
  '49043': {
    country: 'US',
    city: 'Covert',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.2911,
    longitude: -86.2743,
    postal_code: '49043'
  },
  '49045': {
    country: 'US',
    city: 'Decatur',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.1012,
    longitude: -86.0338,
    postal_code: '49045'
  },
  '49046': {
    country: 'US',
    city: 'Delton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Barry',
    latitude: 42.5141,
    longitude: -85.4067,
    postal_code: '49046'
  },
  '49047': {
    country: 'US',
    city: 'Dowagiac',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cass',
    latitude: 41.991,
    longitude: -86.1168,
    postal_code: '49047'
  },
  '49048': {
    country: 'US',
    city: 'Kalamazoo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.3189,
    longitude: -85.5152,
    postal_code: '49048'
  },
  '49050': {
    country: 'US',
    city: 'Dowling',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Barry',
    latitude: 42.5015,
    longitude: -85.2495,
    postal_code: '49050'
  },
  '49051': {
    country: 'US',
    city: 'East Leroy',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.1961,
    longitude: -85.2311,
    postal_code: '49051'
  },
  '49052': {
    country: 'US',
    city: 'Fulton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.1391,
    longitude: -85.3227,
    postal_code: '49052'
  },
  '49053': {
    country: 'US',
    city: 'Galesburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2948,
    longitude: -85.4237,
    postal_code: '49053'
  },
  '49055': {
    country: 'US',
    city: 'Gobles',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.3702,
    longitude: -85.8536,
    postal_code: '49055'
  },
  '49056': {
    country: 'US',
    city: 'Grand Junction',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.3761,
    longitude: -86.0541,
    postal_code: '49056'
  },
  '49057': {
    country: 'US',
    city: 'Hartford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.2088,
    longitude: -86.1687,
    postal_code: '49057'
  },
  '49058': {
    country: 'US',
    city: 'Hastings',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Barry',
    latitude: 42.643,
    longitude: -85.2937,
    postal_code: '49058'
  },
  '49060': {
    country: 'US',
    city: 'Hickory Corners',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Barry',
    latitude: 42.4237,
    longitude: -85.3998,
    postal_code: '49060'
  },
  '49061': {
    country: 'US',
    city: 'Jones',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cass',
    latitude: 41.9129,
    longitude: -85.8341,
    postal_code: '49061'
  },
  '49062': {
    country: 'US',
    city: 'Kendall',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.3618,
    longitude: -85.8141,
    postal_code: '49062'
  },
  '49063': {
    country: 'US',
    city: 'Lacota',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.4136,
    longitude: -86.1298,
    postal_code: '49063'
  },
  '49064': {
    country: 'US',
    city: 'Lawrence',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.2076,
    longitude: -86.0525,
    postal_code: '49064'
  },
  '49065': {
    country: 'US',
    city: 'Lawton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.1545,
    longitude: -85.829,
    postal_code: '49065'
  },
  '49066': {
    country: 'US',
    city: 'Leonidas',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Joseph',
    latitude: 42.0294,
    longitude: -85.3497,
    postal_code: '49066'
  },
  '49067': {
    country: 'US',
    city: 'Marcellus',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cass',
    latitude: 42.0275,
    longitude: -85.7988,
    postal_code: '49067'
  },
  '49068': {
    country: 'US',
    city: 'Marshall',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.272,
    longitude: -84.9583,
    postal_code: '49068'
  },
  '49070': {
    country: 'US',
    city: 'Martin',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.537,
    longitude: -85.6417,
    postal_code: '49070'
  },
  '49071': {
    country: 'US',
    city: 'Mattawan',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.2451,
    longitude: -85.7943,
    postal_code: '49071'
  },
  '49072': {
    country: 'US',
    city: 'Mendon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Joseph',
    latitude: 42.0143,
    longitude: -85.4727,
    postal_code: '49072'
  },
  '49073': {
    country: 'US',
    city: 'Nashville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Barry',
    latitude: 42.5937,
    longitude: -85.122,
    postal_code: '49073'
  },
  '49074': {
    country: 'US',
    city: 'Nazareth',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2454,
    longitude: -85.5299,
    postal_code: '49074'
  },
  '49075': {
    country: 'US',
    city: 'Nottawa',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Joseph',
    latitude: 41.9189,
    longitude: -85.4489,
    postal_code: '49075'
  },
  '49076': {
    country: 'US',
    city: 'Olivet',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.4459,
    longitude: -84.8973,
    postal_code: '49076'
  },
  '49077': {
    country: 'US',
    city: 'Oshtemo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.2589,
    longitude: -85.6775,
    postal_code: '49077'
  },
  '49078': {
    country: 'US',
    city: 'Otsego',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.4723,
    longitude: -85.7035,
    postal_code: '49078'
  },
  '49079': {
    country: 'US',
    city: 'Paw Paw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.2349,
    longitude: -85.9005,
    postal_code: '49079'
  },
  '49080': {
    country: 'US',
    city: 'Plainwell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.4544,
    longitude: -85.5994,
    postal_code: '49080'
  },
  '49081': {
    country: 'US',
    city: 'Portage',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.1718,
    longitude: -85.6178,
    postal_code: '49081'
  },
  '49082': {
    country: 'US',
    city: 'Quincy',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Branch',
    latitude: 41.9442,
    longitude: -84.8839,
    postal_code: '49082'
  },
  '49083': {
    country: 'US',
    city: 'Richland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.3757,
    longitude: -85.4447,
    postal_code: '49083'
  },
  '49084': {
    country: 'US',
    city: 'Riverside',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 42.1834,
    longitude: -86.3828,
    postal_code: '49084'
  },
  '49085': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 42.064,
    longitude: -86.4783,
    postal_code: '49085'
  },
  '49087': {
    country: 'US',
    city: 'Schoolcraft',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.1329,
    longitude: -85.6637,
    postal_code: '49087'
  },
  '49088': {
    country: 'US',
    city: 'Scotts',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.1819,
    longitude: -85.4685,
    postal_code: '49088'
  },
  '49089': {
    country: 'US',
    city: 'Sherwood',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Branch',
    latitude: 42.0107,
    longitude: -85.2408,
    postal_code: '49089'
  },
  '49090': {
    country: 'US',
    city: 'South Haven',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Van Buren',
    latitude: 42.4041,
    longitude: -86.2542,
    postal_code: '49090'
  },
  '49091': {
    country: 'US',
    city: 'Sturgis',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Joseph',
    latitude: 41.8089,
    longitude: -85.4264,
    postal_code: '49091'
  },
  '49092': {
    country: 'US',
    city: 'Tekonsha',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.0863,
    longitude: -84.9926,
    postal_code: '49092'
  },
  '49093': {
    country: 'US',
    city: 'Three Rivers',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Joseph',
    latitude: 41.9596,
    longitude: -85.6371,
    postal_code: '49093'
  },
  '49094': {
    country: 'US',
    city: 'Union City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Branch',
    latitude: 42.0551,
    longitude: -85.1356,
    postal_code: '49094'
  },
  '49095': {
    country: 'US',
    city: 'Vandalia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cass',
    latitude: 41.8955,
    longitude: -85.8755,
    postal_code: '49095'
  },
  '49096': {
    country: 'US',
    city: 'Vermontville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Eaton',
    latitude: 42.6392,
    longitude: -85.011,
    postal_code: '49096'
  },
  '49097': {
    country: 'US',
    city: 'Vicksburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalamazoo',
    latitude: 42.1209,
    longitude: -85.5024,
    postal_code: '49097'
  },
  '49098': {
    country: 'US',
    city: 'Watervliet',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 42.1938,
    longitude: -86.2604,
    postal_code: '49098'
  },
  '49099': {
    country: 'US',
    city: 'White Pigeon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'St. Joseph',
    latitude: 41.7929,
    longitude: -85.675,
    postal_code: '49099'
  },
  '49101': {
    country: 'US',
    city: 'Baroda',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.9488,
    longitude: -86.4913,
    postal_code: '49101'
  },
  '49102': {
    country: 'US',
    city: 'Berrien Center',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.9484,
    longitude: -86.285,
    postal_code: '49102'
  },
  '49103': {
    country: 'US',
    city: 'Berrien Springs',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.948,
    longitude: -86.354,
    postal_code: '49103'
  },
  '49104': {
    country: 'US',
    city: 'Berrien Springs',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.9464,
    longitude: -86.3389,
    postal_code: '49104'
  },
  '49106': {
    country: 'US',
    city: 'Bridgman',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.9362,
    longitude: -86.5543,
    postal_code: '49106'
  },
  '49107': {
    country: 'US',
    city: 'Buchanan',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.8327,
    longitude: -86.3708,
    postal_code: '49107'
  },
  '49111': {
    country: 'US',
    city: 'Eau Claire',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 42.0151,
    longitude: -86.2972,
    postal_code: '49111'
  },
  '49112': {
    country: 'US',
    city: 'Edwardsburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cass',
    latitude: 41.7913,
    longitude: -86.0263,
    postal_code: '49112'
  },
  '49113': {
    country: 'US',
    city: 'Galien',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.8198,
    longitude: -86.5035,
    postal_code: '49113'
  },
  '49115': {
    country: 'US',
    city: 'Harbert',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.8771,
    longitude: -86.6302,
    postal_code: '49115'
  },
  '49116': {
    country: 'US',
    city: 'Lakeside',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.8485,
    longitude: -86.6694,
    postal_code: '49116'
  },
  '49117': {
    country: 'US',
    city: 'New Buffalo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.7927,
    longitude: -86.746,
    postal_code: '49117'
  },
  '49119': {
    country: 'US',
    city: 'New Troy',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.8744,
    longitude: -86.549,
    postal_code: '49119'
  },
  '49120': {
    country: 'US',
    city: 'Niles',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.8202,
    longitude: -86.2368,
    postal_code: '49120'
  },
  '49125': {
    country: 'US',
    city: 'Sawyer',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.8829,
    longitude: -86.5885,
    postal_code: '49125'
  },
  '49126': {
    country: 'US',
    city: 'Sodus',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 42.0521,
    longitude: -86.3921,
    postal_code: '49126'
  },
  '49127': {
    country: 'US',
    city: 'Stevensville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 42.022,
    longitude: -86.5119,
    postal_code: '49127'
  },
  '49128': {
    country: 'US',
    city: 'Three Oaks',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.815,
    longitude: -86.6154,
    postal_code: '49128'
  },
  '49129': {
    country: 'US',
    city: 'Union Pier',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Berrien',
    latitude: 41.8255,
    longitude: -86.6911,
    postal_code: '49129'
  },
  '49130': {
    country: 'US',
    city: 'Union',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cass',
    latitude: 41.7827,
    longitude: -85.8529,
    postal_code: '49130'
  },
  '49201': {
    country: 'US',
    city: 'Jackson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.2545,
    longitude: -84.3875,
    postal_code: '49201'
  },
  '49202': {
    country: 'US',
    city: 'Jackson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.2634,
    longitude: -84.4083,
    postal_code: '49202'
  },
  '49203': {
    country: 'US',
    city: 'Jackson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.229,
    longitude: -84.4132,
    postal_code: '49203'
  },
  '49204': {
    country: 'US',
    city: 'Jackson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.2459,
    longitude: -84.4013,
    postal_code: '49204'
  },
  '49220': {
    country: 'US',
    city: 'Addison',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 42.0036,
    longitude: -84.3122,
    postal_code: '49220'
  },
  '49221': {
    country: 'US',
    city: 'Adrian',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.9005,
    longitude: -84.0446,
    postal_code: '49221'
  },
  '49224': {
    country: 'US',
    city: 'Albion',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.2581,
    longitude: -84.7561,
    postal_code: '49224'
  },
  '49227': {
    country: 'US',
    city: 'Allen',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 41.957,
    longitude: -84.7677,
    postal_code: '49227'
  },
  '49228': {
    country: 'US',
    city: 'Blissfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.8276,
    longitude: -83.8773,
    postal_code: '49228'
  },
  '49229': {
    country: 'US',
    city: 'Britton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.9887,
    longitude: -83.8377,
    postal_code: '49229'
  },
  '49230': {
    country: 'US',
    city: 'Brooklyn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.1044,
    longitude: -84.2414,
    postal_code: '49230'
  },
  '49232': {
    country: 'US',
    city: 'Camden',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 41.7361,
    longitude: -84.7245,
    postal_code: '49232'
  },
  '49233': {
    country: 'US',
    city: 'Cement City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 42.0604,
    longitude: -84.3254,
    postal_code: '49233'
  },
  '49234': {
    country: 'US',
    city: 'Clarklake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.1235,
    longitude: -84.3521,
    postal_code: '49234'
  },
  '49235': {
    country: 'US',
    city: 'Clayton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.8636,
    longitude: -84.2147,
    postal_code: '49235'
  },
  '49236': {
    country: 'US',
    city: 'Clinton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 42.0639,
    longitude: -83.9442,
    postal_code: '49236'
  },
  '49237': {
    country: 'US',
    city: 'Concord',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.1875,
    longitude: -84.6529,
    postal_code: '49237'
  },
  '49238': {
    country: 'US',
    city: 'Deerfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.8909,
    longitude: -83.7847,
    postal_code: '49238'
  },
  '49239': {
    country: 'US',
    city: 'Frontier',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 41.7825,
    longitude: -84.6047,
    postal_code: '49239'
  },
  '49240': {
    country: 'US',
    city: 'Grass Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.2711,
    longitude: -84.194,
    postal_code: '49240'
  },
  '49241': {
    country: 'US',
    city: 'Hanover',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.1025,
    longitude: -84.5848,
    postal_code: '49241'
  },
  '49242': {
    country: 'US',
    city: 'Hillsdale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 41.924,
    longitude: -84.6208,
    postal_code: '49242'
  },
  '49245': {
    country: 'US',
    city: 'Homer',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Calhoun',
    latitude: 42.1416,
    longitude: -84.8157,
    postal_code: '49245'
  },
  '49246': {
    country: 'US',
    city: 'Horton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.1191,
    longitude: -84.4976,
    postal_code: '49246'
  },
  '49247': {
    country: 'US',
    city: 'Hudson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.8581,
    longitude: -84.338,
    postal_code: '49247'
  },
  '49248': {
    country: 'US',
    city: 'Jasper',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.7704,
    longitude: -84.0011,
    postal_code: '49248'
  },
  '49249': {
    country: 'US',
    city: 'Jerome',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 42.0484,
    longitude: -84.4455,
    postal_code: '49249'
  },
  '49250': {
    country: 'US',
    city: 'Jonesville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 41.9798,
    longitude: -84.6451,
    postal_code: '49250'
  },
  '49251': {
    country: 'US',
    city: 'Leslie',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.4603,
    longitude: -84.4207,
    postal_code: '49251'
  },
  '49252': {
    country: 'US',
    city: 'Litchfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 42.0369,
    longitude: -84.7673,
    postal_code: '49252'
  },
  '49253': {
    country: 'US',
    city: 'Manitou Beach',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.9671,
    longitude: -84.2767,
    postal_code: '49253'
  },
  '49254': {
    country: 'US',
    city: 'Michigan Center',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.2271,
    longitude: -84.3161,
    postal_code: '49254'
  },
  '49255': {
    country: 'US',
    city: 'Montgomery',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Branch',
    latitude: 41.7928,
    longitude: -84.8496,
    postal_code: '49255'
  },
  '49256': {
    country: 'US',
    city: 'Morenci',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.7386,
    longitude: -84.2192,
    postal_code: '49256'
  },
  '49257': {
    country: 'US',
    city: 'Moscow',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 42.0548,
    longitude: -84.5039,
    postal_code: '49257'
  },
  '49258': {
    country: 'US',
    city: 'Mosherville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 42.0603,
    longitude: -84.6594,
    postal_code: '49258'
  },
  '49259': {
    country: 'US',
    city: 'Munith',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.3703,
    longitude: -84.2485,
    postal_code: '49259'
  },
  '49261': {
    country: 'US',
    city: 'Napoleon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.1643,
    longitude: -84.2458,
    postal_code: '49261'
  },
  '49262': {
    country: 'US',
    city: 'North Adams',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 41.9715,
    longitude: -84.5208,
    postal_code: '49262'
  },
  '49263': {
    country: 'US',
    city: 'Norvell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.1582,
    longitude: -84.1838,
    postal_code: '49263'
  },
  '49264': {
    country: 'US',
    city: 'Onondaga',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.4487,
    longitude: -84.5535,
    postal_code: '49264'
  },
  '49265': {
    country: 'US',
    city: 'Onsted',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 42.0295,
    longitude: -84.1839,
    postal_code: '49265'
  },
  '49266': {
    country: 'US',
    city: 'Osseo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 41.8384,
    longitude: -84.5974,
    postal_code: '49266'
  },
  '49267': {
    country: 'US',
    city: 'Ottawa Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.7683,
    longitude: -83.6856,
    postal_code: '49267'
  },
  '49268': {
    country: 'US',
    city: 'Palmyra',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.8729,
    longitude: -83.9263,
    postal_code: '49268'
  },
  '49269': {
    country: 'US',
    city: 'Parma',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.2739,
    longitude: -84.5999,
    postal_code: '49269'
  },
  '49270': {
    country: 'US',
    city: 'Petersburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Monroe',
    latitude: 41.8757,
    longitude: -83.6877,
    postal_code: '49270'
  },
  '49271': {
    country: 'US',
    city: 'Pittsford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 41.8963,
    longitude: -84.444,
    postal_code: '49271'
  },
  '49272': {
    country: 'US',
    city: 'Pleasant Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.3903,
    longitude: -84.3428,
    postal_code: '49272'
  },
  '49274': {
    country: 'US',
    city: 'Reading',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 41.8442,
    longitude: -84.7648,
    postal_code: '49274'
  },
  '49276': {
    country: 'US',
    city: 'Riga',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.7953,
    longitude: -83.8011,
    postal_code: '49276'
  },
  '49277': {
    country: 'US',
    city: 'Rives Junction',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.3887,
    longitude: -84.4589,
    postal_code: '49277'
  },
  '49279': {
    country: 'US',
    city: 'Sand Creek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.8259,
    longitude: -84.1016,
    postal_code: '49279'
  },
  '49281': {
    country: 'US',
    city: 'Somerset',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 42.023,
    longitude: -84.3794,
    postal_code: '49281'
  },
  '49282': {
    country: 'US',
    city: 'Somerset Center',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 42.0388,
    longitude: -84.406,
    postal_code: '49282'
  },
  '49283': {
    country: 'US',
    city: 'Spring Arbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.2069,
    longitude: -84.5501,
    postal_code: '49283'
  },
  '49284': {
    country: 'US',
    city: 'Springport',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Jackson',
    latitude: 42.3805,
    longitude: -84.7039,
    postal_code: '49284'
  },
  '49285': {
    country: 'US',
    city: 'Stockbridge',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ingham',
    latitude: 42.4604,
    longitude: -84.1752,
    postal_code: '49285'
  },
  '49286': {
    country: 'US',
    city: 'Tecumseh',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.9953,
    longitude: -83.9555,
    postal_code: '49286'
  },
  '49287': {
    country: 'US',
    city: 'Tipton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 42.0369,
    longitude: -84.0768,
    postal_code: '49287'
  },
  '49288': {
    country: 'US',
    city: 'Waldron',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Hillsdale',
    latitude: 41.7386,
    longitude: -84.4496,
    postal_code: '49288'
  },
  '49289': {
    country: 'US',
    city: 'Weston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lenawee',
    latitude: 41.7683,
    longitude: -84.1076,
    postal_code: '49289'
  },
  '49301': {
    country: 'US',
    city: 'Ada',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9566,
    longitude: -85.481,
    postal_code: '49301'
  },
  '49302': {
    country: 'US',
    city: 'Alto',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.8243,
    longitude: -85.4256,
    postal_code: '49302'
  },
  '49303': {
    country: 'US',
    city: 'Bailey',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.2768,
    longitude: -85.8313,
    postal_code: '49303'
  },
  '49304': {
    country: 'US',
    city: 'Baldwin',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lake',
    latitude: 43.8894,
    longitude: -85.8819,
    postal_code: '49304'
  },
  '49305': {
    country: 'US',
    city: 'Barryton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mecosta',
    latitude: 43.7507,
    longitude: -85.1547,
    postal_code: '49305'
  },
  '49306': {
    country: 'US',
    city: 'Belmont',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.075,
    longitude: -85.5632,
    postal_code: '49306'
  },
  '49307': {
    country: 'US',
    city: 'Big Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mecosta',
    latitude: 43.6897,
    longitude: -85.4797,
    postal_code: '49307'
  },
  '49309': {
    country: 'US',
    city: 'Bitely',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Newaygo',
    latitude: 43.7321,
    longitude: -85.8778,
    postal_code: '49309'
  },
  '49310': {
    country: 'US',
    city: 'Blanchard',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Isabella',
    latitude: 43.5228,
    longitude: -85.0589,
    postal_code: '49310'
  },
  '49311': {
    country: 'US',
    city: 'Bradley',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.633,
    longitude: -85.643,
    postal_code: '49311'
  },
  '49312': {
    country: 'US',
    city: 'Brohman',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Newaygo',
    latitude: 43.6853,
    longitude: -85.8159,
    postal_code: '49312'
  },
  '49314': {
    country: 'US',
    city: 'Burnips',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.7312,
    longitude: -85.8404,
    postal_code: '49314'
  },
  '49315': {
    country: 'US',
    city: 'Byron Center',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.8016,
    longitude: -85.7136,
    postal_code: '49315'
  },
  '49316': {
    country: 'US',
    city: 'Caledonia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.8187,
    longitude: -85.5244,
    postal_code: '49316'
  },
  '49317': {
    country: 'US',
    city: 'Cannonsburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0712,
    longitude: -85.4809,
    postal_code: '49317'
  },
  '49318': {
    country: 'US',
    city: 'Casnovia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.2381,
    longitude: -85.8254,
    postal_code: '49318'
  },
  '49319': {
    country: 'US',
    city: 'Cedar Springs',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.2215,
    longitude: -85.5452,
    postal_code: '49319'
  },
  '49320': {
    country: 'US',
    city: 'Chippewa Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mecosta',
    latitude: 43.7553,
    longitude: -85.2783,
    postal_code: '49320'
  },
  '49321': {
    country: 'US',
    city: 'Comstock Park',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0578,
    longitude: -85.6845,
    postal_code: '49321'
  },
  '49322': {
    country: 'US',
    city: 'Coral',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.3645,
    longitude: -85.3792,
    postal_code: '49322'
  },
  '49323': {
    country: 'US',
    city: 'Dorr',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.7231,
    longitude: -85.7628,
    postal_code: '49323'
  },
  '49325': {
    country: 'US',
    city: 'Freeport',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Barry',
    latitude: 42.7661,
    longitude: -85.3133,
    postal_code: '49325'
  },
  '49326': {
    country: 'US',
    city: 'Gowen',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.2239,
    longitude: -85.3159,
    postal_code: '49326'
  },
  '49327': {
    country: 'US',
    city: 'Grant',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Newaygo',
    latitude: 43.3392,
    longitude: -85.8368,
    postal_code: '49327'
  },
  '49328': {
    country: 'US',
    city: 'Hopkins',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.6421,
    longitude: -85.7322,
    postal_code: '49328'
  },
  '49329': {
    country: 'US',
    city: 'Howard City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.4083,
    longitude: -85.4856,
    postal_code: '49329'
  },
  '49330': {
    country: 'US',
    city: 'Kent City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.2362,
    longitude: -85.7399,
    postal_code: '49330'
  },
  '49331': {
    country: 'US',
    city: 'Lowell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.955,
    longitude: -85.3653,
    postal_code: '49331'
  },
  '49332': {
    country: 'US',
    city: 'Mecosta',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mecosta',
    latitude: 43.6813,
    longitude: -85.228,
    postal_code: '49332'
  },
  '49333': {
    country: 'US',
    city: 'Middleville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Barry',
    latitude: 42.6932,
    longitude: -85.4759,
    postal_code: '49333'
  },
  '49335': {
    country: 'US',
    city: 'Moline',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.737,
    longitude: -85.6635,
    postal_code: '49335'
  },
  '49336': {
    country: 'US',
    city: 'Morley',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mecosta',
    latitude: 43.506,
    longitude: -85.4473,
    postal_code: '49336'
  },
  '49337': {
    country: 'US',
    city: 'Newaygo',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Newaygo',
    latitude: 43.4198,
    longitude: -85.7594,
    postal_code: '49337'
  },
  '49338': {
    country: 'US',
    city: 'Paris',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mecosta',
    latitude: 43.7677,
    longitude: -85.5213,
    postal_code: '49338'
  },
  '49339': {
    country: 'US',
    city: 'Pierson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.3355,
    longitude: -85.5134,
    postal_code: '49339'
  },
  '49340': {
    country: 'US',
    city: 'Remus',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mecosta',
    latitude: 43.6032,
    longitude: -85.1574,
    postal_code: '49340'
  },
  '49341': {
    country: 'US',
    city: 'Rockford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.1152,
    longitude: -85.5136,
    postal_code: '49341'
  },
  '49342': {
    country: 'US',
    city: 'Rodney',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mecosta',
    latitude: 43.7379,
    longitude: -85.3219,
    postal_code: '49342'
  },
  '49343': {
    country: 'US',
    city: 'Sand Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.2981,
    longitude: -85.5367,
    postal_code: '49343'
  },
  '49344': {
    country: 'US',
    city: 'Shelbyville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.5936,
    longitude: -85.6282,
    postal_code: '49344'
  },
  '49345': {
    country: 'US',
    city: 'Sparta',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.1619,
    longitude: -85.6877,
    postal_code: '49345'
  },
  '49346': {
    country: 'US',
    city: 'Stanwood',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mecosta',
    latitude: 43.6017,
    longitude: -85.4446,
    postal_code: '49346'
  },
  '49347': {
    country: 'US',
    city: 'Trufant',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montcalm',
    latitude: 43.3156,
    longitude: -85.3685,
    postal_code: '49347'
  },
  '49348': {
    country: 'US',
    city: 'Wayland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.6643,
    longitude: -85.6191,
    postal_code: '49348'
  },
  '49349': {
    country: 'US',
    city: 'White Cloud',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Newaygo',
    latitude: 43.5398,
    longitude: -85.7586,
    postal_code: '49349'
  },
  '49351': {
    country: 'US',
    city: 'Rockford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.12,
    longitude: -85.56,
    postal_code: '49351'
  },
  '49355': {
    country: 'US',
    city: 'Ada',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9542,
    longitude: -85.4889,
    postal_code: '49355'
  },
  '49356': {
    country: 'US',
    city: 'Ada',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9542,
    longitude: -85.4889,
    postal_code: '49356'
  },
  '49357': {
    country: 'US',
    city: 'Ada',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9542,
    longitude: -85.4889,
    postal_code: '49357'
  },
  '49401': {
    country: 'US',
    city: 'Allendale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.9711,
    longitude: -85.9249,
    postal_code: '49401'
  },
  '49402': {
    country: 'US',
    city: 'Branch',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lake',
    latitude: 43.9333,
    longitude: -86.0345,
    postal_code: '49402'
  },
  '49403': {
    country: 'US',
    city: 'Conklin',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 43.1494,
    longitude: -85.8538,
    postal_code: '49403'
  },
  '49404': {
    country: 'US',
    city: 'Coopersville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 43.0601,
    longitude: -85.9517,
    postal_code: '49404'
  },
  '49405': {
    country: 'US',
    city: 'Custer',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mason',
    latitude: 43.9324,
    longitude: -86.1777,
    postal_code: '49405'
  },
  '49406': {
    country: 'US',
    city: 'Douglas',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.6369,
    longitude: -86.2022,
    postal_code: '49406'
  },
  '49408': {
    country: 'US',
    city: 'Fennville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.5777,
    longitude: -86.1249,
    postal_code: '49408'
  },
  '49409': {
    country: 'US',
    city: 'Ferrysburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 43.0809,
    longitude: -86.2154,
    postal_code: '49409'
  },
  '49410': {
    country: 'US',
    city: 'Fountain',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mason',
    latitude: 44.0363,
    longitude: -86.2169,
    postal_code: '49410'
  },
  '49411': {
    country: 'US',
    city: 'Free Soil',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mason',
    latitude: 44.112,
    longitude: -86.2652,
    postal_code: '49411'
  },
  '49412': {
    country: 'US',
    city: 'Fremont',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Newaygo',
    latitude: 43.4652,
    longitude: -85.9626,
    postal_code: '49412'
  },
  '49413': {
    country: 'US',
    city: 'Fremont',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Newaygo',
    latitude: 43.5543,
    longitude: -85.8009,
    postal_code: '49413'
  },
  '49415': {
    country: 'US',
    city: 'Fruitport',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.1443,
    longitude: -86.1388,
    postal_code: '49415'
  },
  '49416': {
    country: 'US',
    city: 'Glenn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.5203,
    longitude: -86.2275,
    postal_code: '49416'
  },
  '49417': {
    country: 'US',
    city: 'Grand Haven',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 43.0378,
    longitude: -86.1912,
    postal_code: '49417'
  },
  '49418': {
    country: 'US',
    city: 'Grandville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.8939,
    longitude: -85.7619,
    postal_code: '49418'
  },
  '49419': {
    country: 'US',
    city: 'Hamilton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.6881,
    longitude: -85.9747,
    postal_code: '49419'
  },
  '49420': {
    country: 'US',
    city: 'Hart',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oceana',
    latitude: 43.7068,
    longitude: -86.3142,
    postal_code: '49420'
  },
  '49421': {
    country: 'US',
    city: 'Hesperia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oceana',
    latitude: 43.5965,
    longitude: -86.0607,
    postal_code: '49421'
  },
  '49422': {
    country: 'US',
    city: 'Holland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.7875,
    longitude: -86.1089,
    postal_code: '49422'
  },
  '49423': {
    country: 'US',
    city: 'Holland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.7692,
    longitude: -86.1164,
    postal_code: '49423'
  },
  '49424': {
    country: 'US',
    city: 'Holland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.8135,
    longitude: -86.1426,
    postal_code: '49424'
  },
  '49425': {
    country: 'US',
    city: 'Holton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.4415,
    longitude: -86.1509,
    postal_code: '49425'
  },
  '49426': {
    country: 'US',
    city: 'Hudsonville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.8748,
    longitude: -85.8751,
    postal_code: '49426'
  },
  '49427': {
    country: 'US',
    city: 'Jamestown',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.8267,
    longitude: -85.8444,
    postal_code: '49427'
  },
  '49428': {
    country: 'US',
    city: 'Jenison',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.9104,
    longitude: -85.8276,
    postal_code: '49428'
  },
  '49429': {
    country: 'US',
    city: 'Jenison',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.9072,
    longitude: -85.792,
    postal_code: '49429'
  },
  '49430': {
    country: 'US',
    city: 'Lamont',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 43.0103,
    longitude: -85.8975,
    postal_code: '49430'
  },
  '49431': {
    country: 'US',
    city: 'Ludington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mason',
    latitude: 43.9688,
    longitude: -86.4403,
    postal_code: '49431'
  },
  '49434': {
    country: 'US',
    city: 'Macatawa',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.7697,
    longitude: -86.2055,
    postal_code: '49434'
  },
  '49435': {
    country: 'US',
    city: 'Marne',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 43.0532,
    longitude: -85.842,
    postal_code: '49435'
  },
  '49436': {
    country: 'US',
    city: 'Mears',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oceana',
    latitude: 43.6825,
    longitude: -86.4533,
    postal_code: '49436'
  },
  '49437': {
    country: 'US',
    city: 'Montague',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.4242,
    longitude: -86.374,
    postal_code: '49437'
  },
  '49440': {
    country: 'US',
    city: 'Muskegon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.2326,
    longitude: -86.2492,
    postal_code: '49440'
  },
  '49441': {
    country: 'US',
    city: 'Muskegon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.1962,
    longitude: -86.2738,
    postal_code: '49441'
  },
  '49442': {
    country: 'US',
    city: 'Muskegon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.2329,
    longitude: -86.1885,
    postal_code: '49442'
  },
  '49443': {
    country: 'US',
    city: 'Muskegon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.2342,
    longitude: -86.2484,
    postal_code: '49443'
  },
  '49444': {
    country: 'US',
    city: 'Muskegon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.1791,
    longitude: -86.1989,
    postal_code: '49444'
  },
  '49445': {
    country: 'US',
    city: 'Muskegon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.2952,
    longitude: -86.279,
    postal_code: '49445'
  },
  '49446': {
    country: 'US',
    city: 'New Era',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oceana',
    latitude: 43.5556,
    longitude: -86.3448,
    postal_code: '49446'
  },
  '49448': {
    country: 'US',
    city: 'Nunica',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 43.0883,
    longitude: -86.1028,
    postal_code: '49448'
  },
  '49449': {
    country: 'US',
    city: 'Pentwater',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oceana',
    latitude: 43.8237,
    longitude: -86.3868,
    postal_code: '49449'
  },
  '49450': {
    country: 'US',
    city: 'Pullman',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.4651,
    longitude: -86.0799,
    postal_code: '49450'
  },
  '49451': {
    country: 'US',
    city: 'Ravenna',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.2091,
    longitude: -85.9648,
    postal_code: '49451'
  },
  '49452': {
    country: 'US',
    city: 'Rothbury',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oceana',
    latitude: 43.5116,
    longitude: -86.3446,
    postal_code: '49452'
  },
  '49453': {
    country: 'US',
    city: 'Saugatuck',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Allegan',
    latitude: 42.6456,
    longitude: -86.1655,
    postal_code: '49453'
  },
  '49454': {
    country: 'US',
    city: 'Scottville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mason',
    latitude: 43.9547,
    longitude: -86.2801,
    postal_code: '49454'
  },
  '49455': {
    country: 'US',
    city: 'Shelby',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oceana',
    latitude: 43.6079,
    longitude: -86.3615,
    postal_code: '49455'
  },
  '49456': {
    country: 'US',
    city: 'Spring Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 43.0887,
    longitude: -86.1915,
    postal_code: '49456'
  },
  '49457': {
    country: 'US',
    city: 'Twin Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.3414,
    longitude: -86.1633,
    postal_code: '49457'
  },
  '49458': {
    country: 'US',
    city: 'Walhalla',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mason',
    latitude: 43.9536,
    longitude: -86.1148,
    postal_code: '49458'
  },
  '49459': {
    country: 'US',
    city: 'Walkerville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Oceana',
    latitude: 43.7626,
    longitude: -86.083,
    postal_code: '49459'
  },
  '49460': {
    country: 'US',
    city: 'West Olive',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.9099,
    longitude: -86.1317,
    postal_code: '49460'
  },
  '49461': {
    country: 'US',
    city: 'Whitehall',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.3904,
    longitude: -86.3315,
    postal_code: '49461'
  },
  '49463': {
    country: 'US',
    city: 'Sylvan Beach',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Muskegon',
    latitude: 43.372,
    longitude: -86.4237,
    postal_code: '49463'
  },
  '49464': {
    country: 'US',
    city: 'Zeeland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 42.8256,
    longitude: -86.0104,
    postal_code: '49464'
  },
  '49468': {
    country: 'US',
    city: 'Grandville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9097,
    longitude: -85.7631,
    postal_code: '49468'
  },
  '49501': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9842,
    longitude: -85.6291,
    postal_code: '49501'
  },
  '49502': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9634,
    longitude: -85.6681,
    postal_code: '49502'
  },
  '49503': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9659,
    longitude: -85.6527,
    postal_code: '49503'
  },
  '49504': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9737,
    longitude: -85.7265,
    postal_code: '49504'
  },
  '49505': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.012,
    longitude: -85.6309,
    postal_code: '49505'
  },
  '49506': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.944,
    longitude: -85.6213,
    postal_code: '49506'
  },
  '49507': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9318,
    longitude: -85.6542,
    postal_code: '49507'
  },
  '49508': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.8894,
    longitude: -85.6219,
    postal_code: '49508'
  },
  '49509': {
    country: 'US',
    city: 'Wyoming',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9134,
    longitude: -85.7053,
    postal_code: '49509'
  },
  '49510': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0314,
    longitude: -85.5503,
    postal_code: '49510'
  },
  '49512': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.8802,
    longitude: -85.5352,
    postal_code: '49512'
  },
  '49514': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0314,
    longitude: -85.5503,
    postal_code: '49514'
  },
  '49515': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0314,
    longitude: -85.5503,
    postal_code: '49515'
  },
  '49516': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0314,
    longitude: -85.5503,
    postal_code: '49516'
  },
  '49518': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0314,
    longitude: -85.5503,
    postal_code: '49518'
  },
  '49519': {
    country: 'US',
    city: 'Wyoming',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.8977,
    longitude: -85.7186,
    postal_code: '49519'
  },
  '49523': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9634,
    longitude: -85.6681,
    postal_code: '49523'
  },
  '49525': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0135,
    longitude: -85.6027,
    postal_code: '49525'
  },
  '49528': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.8811,
    longitude: -85.6239,
    postal_code: '49528'
  },
  '49530': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0314,
    longitude: -85.5503,
    postal_code: '49530'
  },
  '49534': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ottawa',
    latitude: 43.0141,
    longitude: -85.7543,
    postal_code: '49534'
  },
  '49544': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0073,
    longitude: -85.7255,
    postal_code: '49544'
  },
  '49546': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.928,
    longitude: -85.5483,
    postal_code: '49546'
  },
  '49548': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.8697,
    longitude: -85.6628,
    postal_code: '49548'
  },
  '49555': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 42.9634,
    longitude: -85.6681,
    postal_code: '49555'
  },
  '49560': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0314,
    longitude: -85.5503,
    postal_code: '49560'
  },
  '49588': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0314,
    longitude: -85.5503,
    postal_code: '49588'
  },
  '49599': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kent',
    latitude: 43.0314,
    longitude: -85.5503,
    postal_code: '49599'
  },
  '49601': {
    country: 'US',
    city: 'Cadillac',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wexford',
    latitude: 44.2504,
    longitude: -85.43,
    postal_code: '49601'
  },
  '49610': {
    country: 'US',
    city: 'Acme',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.7894,
    longitude: -85.4884,
    postal_code: '49610'
  },
  '49611': {
    country: 'US',
    city: 'Alba',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Antrim',
    latitude: 44.9737,
    longitude: -84.9745,
    postal_code: '49611'
  },
  '49612': {
    country: 'US',
    city: 'Alden',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Antrim',
    latitude: 44.8779,
    longitude: -85.2241,
    postal_code: '49612'
  },
  '49613': {
    country: 'US',
    city: 'Arcadia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Manistee',
    latitude: 44.5232,
    longitude: -86.2061,
    postal_code: '49613'
  },
  '49614': {
    country: 'US',
    city: 'Bear Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Manistee',
    latitude: 44.4311,
    longitude: -86.1425,
    postal_code: '49614'
  },
  '49615': {
    country: 'US',
    city: 'Bellaire',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Antrim',
    latitude: 44.9765,
    longitude: -85.2265,
    postal_code: '49615'
  },
  '49616': {
    country: 'US',
    city: 'Benzonia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Benzie',
    latitude: 44.5955,
    longitude: -86.0961,
    postal_code: '49616'
  },
  '49617': {
    country: 'US',
    city: 'Beulah',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Benzie',
    latitude: 44.6306,
    longitude: -86.0025,
    postal_code: '49617'
  },
  '49618': {
    country: 'US',
    city: 'Boon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wexford',
    latitude: 44.2916,
    longitude: -85.6144,
    postal_code: '49618'
  },
  '49619': {
    country: 'US',
    city: 'Brethren',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Manistee',
    latitude: 44.2966,
    longitude: -85.9964,
    postal_code: '49619'
  },
  '49620': {
    country: 'US',
    city: 'Buckley',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wexford',
    latitude: 44.5199,
    longitude: -85.6935,
    postal_code: '49620'
  },
  '49621': {
    country: 'US',
    city: 'Cedar',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Leelanau',
    latitude: 44.8605,
    longitude: -85.8138,
    postal_code: '49621'
  },
  '49622': {
    country: 'US',
    city: 'Central Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Antrim',
    latitude: 45.0748,
    longitude: -85.2673,
    postal_code: '49622'
  },
  '49623': {
    country: 'US',
    city: 'Chase',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lake',
    latitude: 43.8892,
    longitude: -85.6356,
    postal_code: '49623'
  },
  '49625': {
    country: 'US',
    city: 'Copemish',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Manistee',
    latitude: 44.4503,
    longitude: -85.8879,
    postal_code: '49625'
  },
  '49626': {
    country: 'US',
    city: 'Eastlake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Manistee',
    latitude: 44.2413,
    longitude: -86.2913,
    postal_code: '49626'
  },
  '49627': {
    country: 'US',
    city: 'Eastport',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Antrim',
    latitude: 45.1072,
    longitude: -85.3501,
    postal_code: '49627'
  },
  '49628': {
    country: 'US',
    city: 'Elberta',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Benzie',
    latitude: 44.6194,
    longitude: -86.2265,
    postal_code: '49628'
  },
  '49629': {
    country: 'US',
    city: 'Elk Rapids',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Antrim',
    latitude: 44.8955,
    longitude: -85.4082,
    postal_code: '49629'
  },
  '49630': {
    country: 'US',
    city: 'Empire',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Leelanau',
    latitude: 44.8144,
    longitude: -85.9999,
    postal_code: '49630'
  },
  '49631': {
    country: 'US',
    city: 'Evart',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Osceola',
    latitude: 43.8888,
    longitude: -85.2649,
    postal_code: '49631'
  },
  '49632': {
    country: 'US',
    city: 'Falmouth',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Missaukee',
    latitude: 44.2529,
    longitude: -85.017,
    postal_code: '49632'
  },
  '49633': {
    country: 'US',
    city: 'Fife Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.5721,
    longitude: -85.2531,
    postal_code: '49633'
  },
  '49634': {
    country: 'US',
    city: 'Filer City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Manistee',
    latitude: 44.2153,
    longitude: -86.2873,
    postal_code: '49634'
  },
  '49635': {
    country: 'US',
    city: 'Frankfort',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Benzie',
    latitude: 44.6311,
    longitude: -86.2212,
    postal_code: '49635'
  },
  '49636': {
    country: 'US',
    city: 'Glen Arbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Leelanau',
    latitude: 44.8734,
    longitude: -85.9887,
    postal_code: '49636'
  },
  '49637': {
    country: 'US',
    city: 'Grawn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.6428,
    longitude: -85.7113,
    postal_code: '49637'
  },
  '49638': {
    country: 'US',
    city: 'Harrietta',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wexford',
    latitude: 44.2972,
    longitude: -85.7396,
    postal_code: '49638'
  },
  '49639': {
    country: 'US',
    city: 'Hersey',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Osceola',
    latitude: 43.8481,
    longitude: -85.4059,
    postal_code: '49639'
  },
  '49640': {
    country: 'US',
    city: 'Honor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Benzie',
    latitude: 44.6954,
    longitude: -86.0376,
    postal_code: '49640'
  },
  '49642': {
    country: 'US',
    city: 'Idlewild',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lake',
    latitude: 43.8872,
    longitude: -85.7759,
    postal_code: '49642'
  },
  '49643': {
    country: 'US',
    city: 'Interlochen',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.6518,
    longitude: -85.8022,
    postal_code: '49643'
  },
  '49644': {
    country: 'US',
    city: 'Irons',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lake',
    latitude: 44.0966,
    longitude: -85.939,
    postal_code: '49644'
  },
  '49645': {
    country: 'US',
    city: 'Kaleva',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Manistee',
    latitude: 44.3692,
    longitude: -86.0467,
    postal_code: '49645'
  },
  '49646': {
    country: 'US',
    city: 'Kalkaska',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalkaska',
    latitude: 44.7355,
    longitude: -85.1207,
    postal_code: '49646'
  },
  '49648': {
    country: 'US',
    city: 'Kewadin',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Antrim',
    latitude: 45.0126,
    longitude: -85.3539,
    postal_code: '49648'
  },
  '49649': {
    country: 'US',
    city: 'Kingsley',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.5756,
    longitude: -85.5262,
    postal_code: '49649'
  },
  '49650': {
    country: 'US',
    city: 'Lake Ann',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Benzie',
    latitude: 44.7317,
    longitude: -85.8528,
    postal_code: '49650'
  },
  '49651': {
    country: 'US',
    city: 'Lake City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Missaukee',
    latitude: 44.3767,
    longitude: -85.0946,
    postal_code: '49651'
  },
  '49653': {
    country: 'US',
    city: 'Lake Leelanau',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Leelanau',
    latitude: 44.9856,
    longitude: -85.7329,
    postal_code: '49653'
  },
  '49654': {
    country: 'US',
    city: 'Leland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Leelanau',
    latitude: 45.0793,
    longitude: -85.9896,
    postal_code: '49654'
  },
  '49655': {
    country: 'US',
    city: 'Leroy',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Osceola',
    latitude: 44.0457,
    longitude: -85.43,
    postal_code: '49655'
  },
  '49656': {
    country: 'US',
    city: 'Luther',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Lake',
    latitude: 44.0545,
    longitude: -85.6826,
    postal_code: '49656'
  },
  '49657': {
    country: 'US',
    city: 'Mc Bain',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Missaukee',
    latitude: 44.2172,
    longitude: -85.2283,
    postal_code: '49657'
  },
  '49659': {
    country: 'US',
    city: 'Mancelona',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Antrim',
    latitude: 44.9116,
    longitude: -85.0634,
    postal_code: '49659'
  },
  '49660': {
    country: 'US',
    city: 'Manistee',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Manistee',
    latitude: 44.2635,
    longitude: -86.1825,
    postal_code: '49660'
  },
  '49663': {
    country: 'US',
    city: 'Manton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wexford',
    latitude: 44.4152,
    longitude: -85.4202,
    postal_code: '49663'
  },
  '49664': {
    country: 'US',
    city: 'Maple City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Leelanau',
    latitude: 44.859,
    longitude: -85.8814,
    postal_code: '49664'
  },
  '49665': {
    country: 'US',
    city: 'Marion',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Osceola',
    latitude: 44.0463,
    longitude: -85.155,
    postal_code: '49665'
  },
  '49666': {
    country: 'US',
    city: 'Mayfield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.6261,
    longitude: -85.5287,
    postal_code: '49666'
  },
  '49667': {
    country: 'US',
    city: 'Merritt',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Missaukee',
    latitude: 44.3433,
    longitude: -84.9446,
    postal_code: '49667'
  },
  '49668': {
    country: 'US',
    city: 'Mesick',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Wexford',
    latitude: 44.4075,
    longitude: -85.7061,
    postal_code: '49668'
  },
  '49670': {
    country: 'US',
    city: 'Northport',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Leelanau',
    latitude: 45.1157,
    longitude: -85.6173,
    postal_code: '49670'
  },
  '49673': {
    country: 'US',
    city: 'Old Mission',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.9559,
    longitude: -85.49,
    postal_code: '49673'
  },
  '49674': {
    country: 'US',
    city: 'Omena',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Leelanau',
    latitude: 45.0556,
    longitude: -85.589,
    postal_code: '49674'
  },
  '49675': {
    country: 'US',
    city: 'Onekama',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Manistee',
    latitude: 44.3646,
    longitude: -86.21,
    postal_code: '49675'
  },
  '49676': {
    country: 'US',
    city: 'Rapid City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalkaska',
    latitude: 44.8449,
    longitude: -85.2943,
    postal_code: '49676'
  },
  '49677': {
    country: 'US',
    city: 'Reed City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Osceola',
    latitude: 43.8868,
    longitude: -85.513,
    postal_code: '49677'
  },
  '49679': {
    country: 'US',
    city: 'Sears',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Osceola',
    latitude: 43.8776,
    longitude: -85.1465,
    postal_code: '49679'
  },
  '49680': {
    country: 'US',
    city: 'South Boardman',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Kalkaska',
    latitude: 44.6401,
    longitude: -85.2472,
    postal_code: '49680'
  },
  '49682': {
    country: 'US',
    city: 'Suttons Bay',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Leelanau',
    latitude: 44.9656,
    longitude: -85.6423,
    postal_code: '49682'
  },
  '49683': {
    country: 'US',
    city: 'Thompsonville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Benzie',
    latitude: 44.5198,
    longitude: -85.946,
    postal_code: '49683'
  },
  '49684': {
    country: 'US',
    city: 'Traverse City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.694,
    longitude: -85.6763,
    postal_code: '49684'
  },
  '49685': {
    country: 'US',
    city: 'Traverse City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.8162,
    longitude: -85.5751,
    postal_code: '49685'
  },
  '49686': {
    country: 'US',
    city: 'Traverse City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.7631,
    longitude: -85.6206,
    postal_code: '49686'
  },
  '49688': {
    country: 'US',
    city: 'Tustin',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Osceola',
    latitude: 44.1072,
    longitude: -85.4901,
    postal_code: '49688'
  },
  '49689': {
    country: 'US',
    city: 'Wellston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Manistee',
    latitude: 44.2133,
    longitude: -85.9555,
    postal_code: '49689'
  },
  '49690': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.802,
    longitude: -85.4347,
    postal_code: '49690'
  },
  '49696': {
    country: 'US',
    city: 'Traverse City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Grand Traverse',
    latitude: 44.8162,
    longitude: -85.5751,
    postal_code: '49696'
  },
  '49701': {
    country: 'US',
    city: 'Mackinaw City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cheboygan',
    latitude: 45.7777,
    longitude: -84.7298,
    postal_code: '49701'
  },
  '49705': {
    country: 'US',
    city: 'Afton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cheboygan',
    latitude: 45.3637,
    longitude: -84.4695,
    postal_code: '49705'
  },
  '49706': {
    country: 'US',
    city: 'Alanson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Emmet',
    latitude: 45.4405,
    longitude: -84.7924,
    postal_code: '49706'
  },
  '49707': {
    country: 'US',
    city: 'Alpena',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alpena',
    latitude: 45.079,
    longitude: -83.4602,
    postal_code: '49707'
  },
  '49709': {
    country: 'US',
    city: 'Atlanta',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montmorency',
    latitude: 44.9924,
    longitude: -84.148,
    postal_code: '49709'
  },
  '49710': {
    country: 'US',
    city: 'Barbeau',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.2848,
    longitude: -84.2173,
    postal_code: '49710'
  },
  '49711': {
    country: 'US',
    city: 'Bay Shore',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Charlevoix',
    latitude: 45.3583,
    longitude: -85.0973,
    postal_code: '49711'
  },
  '49712': {
    country: 'US',
    city: 'Boyne City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Charlevoix',
    latitude: 45.2052,
    longitude: -85.0183,
    postal_code: '49712'
  },
  '49713': {
    country: 'US',
    city: 'Boyne Falls',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Charlevoix',
    latitude: 45.2116,
    longitude: -84.8916,
    postal_code: '49713'
  },
  '49715': {
    country: 'US',
    city: 'Brimley',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.3891,
    longitude: -84.6981,
    postal_code: '49715'
  },
  '49716': {
    country: 'US',
    city: 'Brutus',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cheboygan',
    latitude: 45.5068,
    longitude: -84.7467,
    postal_code: '49716'
  },
  '49717': {
    country: 'US',
    city: 'Burt Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cheboygan',
    latitude: 45.4408,
    longitude: -84.7112,
    postal_code: '49717'
  },
  '49718': {
    country: 'US',
    city: 'Carp Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Emmet',
    latitude: 45.7424,
    longitude: -84.7735,
    postal_code: '49718'
  },
  '49719': {
    country: 'US',
    city: 'Cedarville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mackinac',
    latitude: 46.0024,
    longitude: -84.3488,
    postal_code: '49719'
  },
  '49720': {
    country: 'US',
    city: 'Charlevoix',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Charlevoix',
    latitude: 45.2654,
    longitude: -85.2297,
    postal_code: '49720'
  },
  '49721': {
    country: 'US',
    city: 'Cheboygan',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cheboygan',
    latitude: 45.608,
    longitude: -84.4867,
    postal_code: '49721'
  },
  '49722': {
    country: 'US',
    city: 'Conway',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Emmet',
    latitude: 45.4167,
    longitude: -84.867,
    postal_code: '49722'
  },
  '49723': {
    country: 'US',
    city: 'Cross Village',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Emmet',
    latitude: 45.6254,
    longitude: -85.0419,
    postal_code: '49723'
  },
  '49724': {
    country: 'US',
    city: 'Dafter',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.327,
    longitude: -84.3878,
    postal_code: '49724'
  },
  '49725': {
    country: 'US',
    city: 'De Tour Village',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 45.9939,
    longitude: -83.9396,
    postal_code: '49725'
  },
  '49726': {
    country: 'US',
    city: 'Drummond Island',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.0055,
    longitude: -83.7367,
    postal_code: '49726'
  },
  '49727': {
    country: 'US',
    city: 'East Jordan',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Charlevoix',
    latitude: 45.1539,
    longitude: -85.1387,
    postal_code: '49727'
  },
  '49728': {
    country: 'US',
    city: 'Eckerman',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.4088,
    longitude: -85.0136,
    postal_code: '49728'
  },
  '49729': {
    country: 'US',
    city: 'Ellsworth',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Antrim',
    latitude: 45.1599,
    longitude: -85.2633,
    postal_code: '49729'
  },
  '49730': {
    country: 'US',
    city: 'Elmira',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Otsego',
    latitude: 45.0645,
    longitude: -84.8562,
    postal_code: '49730'
  },
  '49733': {
    country: 'US',
    city: 'Frederic',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Crawford',
    latitude: 44.8382,
    longitude: -84.6824,
    postal_code: '49733'
  },
  '49734': {
    country: 'US',
    city: 'Gaylord',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Otsego',
    latitude: 45.0275,
    longitude: -84.6748,
    postal_code: '49734'
  },
  '49735': {
    country: 'US',
    city: 'Gaylord',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Otsego',
    latitude: 45.0125,
    longitude: -84.6723,
    postal_code: '49735'
  },
  '49736': {
    country: 'US',
    city: 'Goetzville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.079,
    longitude: -84.145,
    postal_code: '49736'
  },
  '49737': {
    country: 'US',
    city: 'Good Hart',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Emmet',
    latitude: 45.5802,
    longitude: -85.1137,
    postal_code: '49737'
  },
  '49738': {
    country: 'US',
    city: 'Grayling',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Crawford',
    latitude: 44.671,
    longitude: -84.6913,
    postal_code: '49738'
  },
  '49739': {
    country: 'US',
    city: 'Grayling',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Crawford',
    latitude: 44.683,
    longitude: -84.6104,
    postal_code: '49739'
  },
  '49740': {
    country: 'US',
    city: 'Harbor Springs',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Emmet',
    latitude: 45.5251,
    longitude: -85.0062,
    postal_code: '49740'
  },
  '49743': {
    country: 'US',
    city: 'Hawks',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Presque Isle',
    latitude: 45.2975,
    longitude: -83.8542,
    postal_code: '49743'
  },
  '49744': {
    country: 'US',
    city: 'Herron',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alpena',
    latitude: 45.0124,
    longitude: -83.6535,
    postal_code: '49744'
  },
  '49745': {
    country: 'US',
    city: 'Hessel',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mackinac',
    latitude: 46.0217,
    longitude: -84.4284,
    postal_code: '49745'
  },
  '49746': {
    country: 'US',
    city: 'Hillman',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montmorency',
    latitude: 45.0691,
    longitude: -83.9468,
    postal_code: '49746'
  },
  '49747': {
    country: 'US',
    city: 'Hubbard Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alpena',
    latitude: 44.8947,
    longitude: -83.6059,
    postal_code: '49747'
  },
  '49748': {
    country: 'US',
    city: 'Hulbert',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.3908,
    longitude: -85.1764,
    postal_code: '49748'
  },
  '49749': {
    country: 'US',
    city: 'Indian River',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cheboygan',
    latitude: 45.427,
    longitude: -84.5956,
    postal_code: '49749'
  },
  '49751': {
    country: 'US',
    city: 'Johannesburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Otsego',
    latitude: 45.0152,
    longitude: -84.3857,
    postal_code: '49751'
  },
  '49752': {
    country: 'US',
    city: 'Kinross',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.275,
    longitude: -84.5148,
    postal_code: '49752'
  },
  '49753': {
    country: 'US',
    city: 'Lachine',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alpena',
    latitude: 45.0429,
    longitude: -83.7491,
    postal_code: '49753'
  },
  '49755': {
    country: 'US',
    city: 'Levering',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Emmet',
    latitude: 45.6377,
    longitude: -84.7986,
    postal_code: '49755'
  },
  '49756': {
    country: 'US',
    city: 'Lewiston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Montmorency',
    latitude: 44.8538,
    longitude: -84.2973,
    postal_code: '49756'
  },
  '49757': {
    country: 'US',
    city: 'Mackinac Island',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mackinac',
    latitude: 45.8578,
    longitude: -84.6245,
    postal_code: '49757'
  },
  '49759': {
    country: 'US',
    city: 'Millersburg',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Presque Isle',
    latitude: 45.4309,
    longitude: -84.0854,
    postal_code: '49759'
  },
  '49760': {
    country: 'US',
    city: 'Moran',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mackinac',
    latitude: 46.0441,
    longitude: -85.0078,
    postal_code: '49760'
  },
  '49761': {
    country: 'US',
    city: 'Mullett Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cheboygan',
    latitude: 45.5595,
    longitude: -84.5221,
    postal_code: '49761'
  },
  '49762': {
    country: 'US',
    city: 'Naubinway',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mackinac',
    latitude: 46.126,
    longitude: -85.4462,
    postal_code: '49762'
  },
  '49764': {
    country: 'US',
    city: 'Oden',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Emmet',
    latitude: 45.4236,
    longitude: -84.8281,
    postal_code: '49764'
  },
  '49765': {
    country: 'US',
    city: 'Onaway',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Presque Isle',
    latitude: 45.3365,
    longitude: -84.2123,
    postal_code: '49765'
  },
  '49766': {
    country: 'US',
    city: 'Ossineke',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alpena',
    latitude: 44.9104,
    longitude: -83.4592,
    postal_code: '49766'
  },
  '49768': {
    country: 'US',
    city: 'Paradise',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.5951,
    longitude: -85.0973,
    postal_code: '49768'
  },
  '49769': {
    country: 'US',
    city: 'Pellston',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Emmet',
    latitude: 45.5703,
    longitude: -84.8425,
    postal_code: '49769'
  },
  '49770': {
    country: 'US',
    city: 'Petoskey',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Emmet',
    latitude: 45.3559,
    longitude: -84.9133,
    postal_code: '49770'
  },
  '49774': {
    country: 'US',
    city: 'Pickford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.1559,
    longitude: -84.3663,
    postal_code: '49774'
  },
  '49775': {
    country: 'US',
    city: 'Pointe Aux Pins',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mackinac',
    latitude: 45.7549,
    longitude: -84.447,
    postal_code: '49775'
  },
  '49776': {
    country: 'US',
    city: 'Posen',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Presque Isle',
    latitude: 45.2233,
    longitude: -83.6393,
    postal_code: '49776'
  },
  '49777': {
    country: 'US',
    city: 'Presque Isle',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Presque Isle',
    latitude: 45.2969,
    longitude: -83.5023,
    postal_code: '49777'
  },
  '49779': {
    country: 'US',
    city: 'Rogers City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Presque Isle',
    latitude: 45.4123,
    longitude: -83.8355,
    postal_code: '49779'
  },
  '49780': {
    country: 'US',
    city: 'Rudyard',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.2585,
    longitude: -84.8004,
    postal_code: '49780'
  },
  '49781': {
    country: 'US',
    city: 'Saint Ignace',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mackinac',
    latitude: 45.9985,
    longitude: -84.6978,
    postal_code: '49781'
  },
  '49782': {
    country: 'US',
    city: 'Beaver Island',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Charlevoix',
    latitude: 45.6684,
    longitude: -85.5473,
    postal_code: '49782'
  },
  '49783': {
    country: 'US',
    city: 'Sault Sainte Marie',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.4151,
    longitude: -84.2854,
    postal_code: '49783'
  },
  '49784': {
    country: 'US',
    city: 'Kincheloe',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.4108,
    longitude: -84.3365,
    postal_code: '49784'
  },
  '49785': {
    country: 'US',
    city: 'Kincheloe',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.1815,
    longitude: -84.4054,
    postal_code: '49785'
  },
  '49786': {
    country: 'US',
    city: 'Kincheloe',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.4108,
    longitude: -84.3365,
    postal_code: '49786'
  },
  '49788': {
    country: 'US',
    city: 'Kincheloe',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.2627,
    longitude: -84.4626,
    postal_code: '49788'
  },
  '49791': {
    country: 'US',
    city: 'Topinabee',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cheboygan',
    latitude: 45.4838,
    longitude: -84.5936,
    postal_code: '49791'
  },
  '49792': {
    country: 'US',
    city: 'Tower',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cheboygan',
    latitude: 45.3588,
    longitude: -84.2953,
    postal_code: '49792'
  },
  '49793': {
    country: 'US',
    city: 'Trout Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Chippewa',
    latitude: 46.1936,
    longitude: -85.019,
    postal_code: '49793'
  },
  '49795': {
    country: 'US',
    city: 'Vanderbilt',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Otsego',
    latitude: 45.1532,
    longitude: -84.6511,
    postal_code: '49795'
  },
  '49796': {
    country: 'US',
    city: 'Walloon Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Charlevoix',
    latitude: 45.2661,
    longitude: -84.9337,
    postal_code: '49796'
  },
  '49797': {
    country: 'US',
    city: 'Waters',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Otsego',
    latitude: 44.8702,
    longitude: -84.6598,
    postal_code: '49797'
  },
  '49799': {
    country: 'US',
    city: 'Wolverine',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Cheboygan',
    latitude: 45.2861,
    longitude: -84.6065,
    postal_code: '49799'
  },
  '49801': {
    country: 'US',
    city: 'Iron Mountain',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 45.8219,
    longitude: -88.0683,
    postal_code: '49801'
  },
  '49802': {
    country: 'US',
    city: 'Kingsford',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 45.8006,
    longitude: -88.0773,
    postal_code: '49802'
  },
  '49805': {
    country: 'US',
    city: 'Allouez',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Keweenaw',
    latitude: 47.2879,
    longitude: -88.4129,
    postal_code: '49805'
  },
  '49806': {
    country: 'US',
    city: 'Au Train',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alger',
    latitude: 46.4446,
    longitude: -86.9115,
    postal_code: '49806'
  },
  '49807': {
    country: 'US',
    city: 'Bark River',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Delta',
    latitude: 45.6956,
    longitude: -87.2073,
    postal_code: '49807'
  },
  '49808': {
    country: 'US',
    city: 'Big Bay',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.8158,
    longitude: -87.7285,
    postal_code: '49808'
  },
  '49812': {
    country: 'US',
    city: 'Carney',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.5917,
    longitude: -87.5439,
    postal_code: '49812'
  },
  '49814': {
    country: 'US',
    city: 'Champion',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.4685,
    longitude: -87.8306,
    postal_code: '49814'
  },
  '49815': {
    country: 'US',
    city: 'Channing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 46.1546,
    longitude: -88.0772,
    postal_code: '49815'
  },
  '49816': {
    country: 'US',
    city: 'Chatham',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alger',
    latitude: 46.3477,
    longitude: -86.929,
    postal_code: '49816'
  },
  '49817': {
    country: 'US',
    city: 'Cooks',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Schoolcraft',
    latitude: 45.8997,
    longitude: -86.5307,
    postal_code: '49817'
  },
  '49818': {
    country: 'US',
    city: 'Cornell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Delta',
    latitude: 45.9104,
    longitude: -87.2237,
    postal_code: '49818'
  },
  '49819': {
    country: 'US',
    city: 'Arnold',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.0508,
    longitude: -87.4921,
    postal_code: '49819'
  },
  '49820': {
    country: 'US',
    city: 'Curtis',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mackinac',
    latitude: 46.2048,
    longitude: -85.7865,
    postal_code: '49820'
  },
  '49821': {
    country: 'US',
    city: 'Daggett',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.4887,
    longitude: -87.6079,
    postal_code: '49821'
  },
  '49822': {
    country: 'US',
    city: 'Deerton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alger',
    latitude: 46.427,
    longitude: -87.0497,
    postal_code: '49822'
  },
  '49825': {
    country: 'US',
    city: 'Eben Junction',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alger',
    latitude: 46.3549,
    longitude: -86.9701,
    postal_code: '49825'
  },
  '49826': {
    country: 'US',
    city: 'Rumely',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alger',
    latitude: 46.3483,
    longitude: -87.0332,
    postal_code: '49826'
  },
  '49827': {
    country: 'US',
    city: 'Engadine',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mackinac',
    latitude: 46.204,
    longitude: -85.6005,
    postal_code: '49827'
  },
  '49829': {
    country: 'US',
    city: 'Escanaba',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Delta',
    latitude: 45.7659,
    longitude: -87.089,
    postal_code: '49829'
  },
  '49831': {
    country: 'US',
    city: 'Felch',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 45.9972,
    longitude: -87.8257,
    postal_code: '49831'
  },
  '49833': {
    country: 'US',
    city: 'Little Lake',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.2888,
    longitude: -87.3418,
    postal_code: '49833'
  },
  '49834': {
    country: 'US',
    city: 'Foster City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 45.9438,
    longitude: -87.7584,
    postal_code: '49834'
  },
  '49835': {
    country: 'US',
    city: 'Garden',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Delta',
    latitude: 45.7549,
    longitude: -86.5776,
    postal_code: '49835'
  },
  '49836': {
    country: 'US',
    city: 'Germfask',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Schoolcraft',
    latitude: 46.2363,
    longitude: -85.9159,
    postal_code: '49836'
  },
  '49837': {
    country: 'US',
    city: 'Gladstone',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Delta',
    latitude: 45.8813,
    longitude: -87.1152,
    postal_code: '49837'
  },
  '49838': {
    country: 'US',
    city: 'Gould City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Mackinac',
    latitude: 46.0747,
    longitude: -85.7338,
    postal_code: '49838'
  },
  '49839': {
    country: 'US',
    city: 'Grand Marais',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alger',
    latitude: 46.6529,
    longitude: -85.9836,
    postal_code: '49839'
  },
  '49840': {
    country: 'US',
    city: 'Gulliver',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Schoolcraft',
    latitude: 46.0115,
    longitude: -86.0214,
    postal_code: '49840'
  },
  '49841': {
    country: 'US',
    city: 'Gwinn',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.3311,
    longitude: -87.4397,
    postal_code: '49841'
  },
  '49845': {
    country: 'US',
    city: 'Harris',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.6922,
    longitude: -87.3513,
    postal_code: '49845'
  },
  '49847': {
    country: 'US',
    city: 'Hermansville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.7149,
    longitude: -87.6227,
    postal_code: '49847'
  },
  '49848': {
    country: 'US',
    city: 'Ingalls',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.3748,
    longitude: -87.6206,
    postal_code: '49848'
  },
  '49849': {
    country: 'US',
    city: 'Ishpeming',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.4387,
    longitude: -87.7091,
    postal_code: '49849'
  },
  '49852': {
    country: 'US',
    city: 'Loretto',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 45.7772,
    longitude: -87.8151,
    postal_code: '49852'
  },
  '49853': {
    country: 'US',
    city: 'Mc Millan',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Luce',
    latitude: 46.297,
    longitude: -85.7307,
    postal_code: '49853'
  },
  '49854': {
    country: 'US',
    city: 'Manistique',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Schoolcraft',
    latitude: 46.0062,
    longitude: -86.2555,
    postal_code: '49854'
  },
  '49855': {
    country: 'US',
    city: 'Marquette',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.5786,
    longitude: -87.4545,
    postal_code: '49855'
  },
  '49858': {
    country: 'US',
    city: 'Menominee',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.1343,
    longitude: -87.6215,
    postal_code: '49858'
  },
  '49861': {
    country: 'US',
    city: 'Michigamme',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.5308,
    longitude: -88.0914,
    postal_code: '49861'
  },
  '49862': {
    country: 'US',
    city: 'Munising',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alger',
    latitude: 46.3994,
    longitude: -86.6983,
    postal_code: '49862'
  },
  '49863': {
    country: 'US',
    city: 'Nadeau',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.6089,
    longitude: -87.5529,
    postal_code: '49863'
  },
  '49864': {
    country: 'US',
    city: 'Nahma',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Delta',
    latitude: 45.8424,
    longitude: -86.6558,
    postal_code: '49864'
  },
  '49865': {
    country: 'US',
    city: 'National Mine',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.6041,
    longitude: -87.6148,
    postal_code: '49865'
  },
  '49866': {
    country: 'US',
    city: 'Negaunee',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.5007,
    longitude: -87.5829,
    postal_code: '49866'
  },
  '49868': {
    country: 'US',
    city: 'Newberry',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Luce',
    latitude: 46.3438,
    longitude: -85.515,
    postal_code: '49868'
  },
  '49870': {
    country: 'US',
    city: 'Norway',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 45.7862,
    longitude: -87.9047,
    postal_code: '49870'
  },
  '49871': {
    country: 'US',
    city: 'Palmer',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.4416,
    longitude: -87.5764,
    postal_code: '49871'
  },
  '49872': {
    country: 'US',
    city: 'Perkins',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Delta',
    latitude: 45.9925,
    longitude: -87.0792,
    postal_code: '49872'
  },
  '49873': {
    country: 'US',
    city: 'Perronville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.841,
    longitude: -87.4816,
    postal_code: '49873'
  },
  '49874': {
    country: 'US',
    city: 'Powers',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.6792,
    longitude: -87.5319,
    postal_code: '49874'
  },
  '49876': {
    country: 'US',
    city: 'Quinnesec',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 45.7999,
    longitude: -87.9922,
    postal_code: '49876'
  },
  '49877': {
    country: 'US',
    city: 'Ralph',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 46.1297,
    longitude: -87.7367,
    postal_code: '49877'
  },
  '49878': {
    country: 'US',
    city: 'Rapid River',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Delta',
    latitude: 45.9107,
    longitude: -86.8845,
    postal_code: '49878'
  },
  '49879': {
    country: 'US',
    city: 'Republic',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.368,
    longitude: -87.9938,
    postal_code: '49879'
  },
  '49880': {
    country: 'US',
    city: 'Rock',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Delta',
    latitude: 46.0503,
    longitude: -87.1331,
    postal_code: '49880'
  },
  '49881': {
    country: 'US',
    city: 'Sagola',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 46.0813,
    longitude: -88.0676,
    postal_code: '49881'
  },
  '49883': {
    country: 'US',
    city: 'Seney',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Schoolcraft',
    latitude: 46.388,
    longitude: -85.9708,
    postal_code: '49883'
  },
  '49884': {
    country: 'US',
    city: 'Shingleton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alger',
    latitude: 46.3512,
    longitude: -86.4823,
    postal_code: '49884'
  },
  '49885': {
    country: 'US',
    city: 'Skandia',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Marquette',
    latitude: 46.349,
    longitude: -87.2481,
    postal_code: '49885'
  },
  '49886': {
    country: 'US',
    city: 'Spalding',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.6958,
    longitude: -87.511,
    postal_code: '49886'
  },
  '49887': {
    country: 'US',
    city: 'Stephenson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.4166,
    longitude: -87.6258,
    postal_code: '49887'
  },
  '49891': {
    country: 'US',
    city: 'Trenary',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alger',
    latitude: 46.1947,
    longitude: -86.9502,
    postal_code: '49891'
  },
  '49892': {
    country: 'US',
    city: 'Vulcan',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Dickinson',
    latitude: 45.7623,
    longitude: -87.8168,
    postal_code: '49892'
  },
  '49893': {
    country: 'US',
    city: 'Wallace',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.3017,
    longitude: -87.5805,
    postal_code: '49893'
  },
  '49894': {
    country: 'US',
    city: 'Wells',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Delta',
    latitude: 45.7849,
    longitude: -87.0734,
    postal_code: '49894'
  },
  '49895': {
    country: 'US',
    city: 'Wetmore',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Alger',
    latitude: 46.3535,
    longitude: -86.6345,
    postal_code: '49895'
  },
  '49896': {
    country: 'US',
    city: 'Wilson',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Menominee',
    latitude: 45.6649,
    longitude: -87.3997,
    postal_code: '49896'
  },
  '49901': {
    country: 'US',
    city: 'Ahmeek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Keweenaw',
    latitude: 47.2933,
    longitude: -88.3897,
    postal_code: '49901'
  },
  '49902': {
    country: 'US',
    city: 'Alpha',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iron',
    latitude: 46.0438,
    longitude: -88.3771,
    postal_code: '49902'
  },
  '49903': {
    country: 'US',
    city: 'Amasa',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iron',
    latitude: 46.2321,
    longitude: -88.4508,
    postal_code: '49903'
  },
  '49905': {
    country: 'US',
    city: 'Atlantic Mine',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.0937,
    longitude: -88.6613,
    postal_code: '49905'
  },
  '49908': {
    country: 'US',
    city: 'Baraga',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Baraga',
    latitude: 46.8048,
    longitude: -88.5758,
    postal_code: '49908'
  },
  '49910': {
    country: 'US',
    city: 'Bergland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ontonagon',
    latitude: 46.5881,
    longitude: -89.5973,
    postal_code: '49910'
  },
  '49911': {
    country: 'US',
    city: 'Bessemer',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gogebic',
    latitude: 46.4802,
    longitude: -90.0515,
    postal_code: '49911'
  },
  '49912': {
    country: 'US',
    city: 'Bruce Crossing',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ontonagon',
    latitude: 46.528,
    longitude: -89.168,
    postal_code: '49912'
  },
  '49913': {
    country: 'US',
    city: 'Calumet',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.2439,
    longitude: -88.4588,
    postal_code: '49913'
  },
  '49915': {
    country: 'US',
    city: 'Caspian',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iron',
    latitude: 46.0643,
    longitude: -88.6329,
    postal_code: '49915'
  },
  '49916': {
    country: 'US',
    city: 'Chassell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.002,
    longitude: -88.5546,
    postal_code: '49916'
  },
  '49917': {
    country: 'US',
    city: 'Copper City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.2842,
    longitude: -88.3875,
    postal_code: '49917'
  },
  '49918': {
    country: 'US',
    city: 'Copper Harbor',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Keweenaw',
    latitude: 47.4688,
    longitude: -87.8884,
    postal_code: '49918'
  },
  '49919': {
    country: 'US',
    city: 'Covington',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Baraga',
    latitude: 46.5413,
    longitude: -88.5371,
    postal_code: '49919'
  },
  '49920': {
    country: 'US',
    city: 'Crystal Falls',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iron',
    latitude: 46.1093,
    longitude: -88.3505,
    postal_code: '49920'
  },
  '49921': {
    country: 'US',
    city: 'Dodgeville',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.0914,
    longitude: -88.5809,
    postal_code: '49921'
  },
  '49922': {
    country: 'US',
    city: 'Dollar Bay',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.1196,
    longitude: -88.5115,
    postal_code: '49922'
  },
  '49925': {
    country: 'US',
    city: 'Ewen',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ontonagon',
    latitude: 46.5404,
    longitude: -89.3145,
    postal_code: '49925'
  },
  '49927': {
    country: 'US',
    city: 'Gaastra',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iron',
    latitude: 46.0526,
    longitude: -88.5917,
    postal_code: '49927'
  },
  '49929': {
    country: 'US',
    city: 'Greenland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ontonagon',
    latitude: 46.7809,
    longitude: -89.1146,
    postal_code: '49929'
  },
  '49930': {
    country: 'US',
    city: 'Hancock',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.1367,
    longitude: -88.5654,
    postal_code: '49930'
  },
  '49931': {
    country: 'US',
    city: 'Houghton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.1158,
    longitude: -88.558,
    postal_code: '49931'
  },
  '49934': {
    country: 'US',
    city: 'Hubbell',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.1733,
    longitude: -88.4293,
    postal_code: '49934'
  },
  '49935': {
    country: 'US',
    city: 'Iron River',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iron',
    latitude: 46.093,
    longitude: -88.646,
    postal_code: '49935'
  },
  '49938': {
    country: 'US',
    city: 'Ironwood',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gogebic',
    latitude: 46.4638,
    longitude: -90.1588,
    postal_code: '49938'
  },
  '49942': {
    country: 'US',
    city: 'Kearsarge',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.2694,
    longitude: -88.4184,
    postal_code: '49942'
  },
  '49945': {
    country: 'US',
    city: 'Lake Linden',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.1784,
    longitude: -88.2973,
    postal_code: '49945'
  },
  '49946': {
    country: 'US',
    city: 'Lanse',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Baraga',
    latitude: 46.7704,
    longitude: -88.421,
    postal_code: '49946'
  },
  '49947': {
    country: 'US',
    city: 'Marenisco',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gogebic',
    latitude: 46.3551,
    longitude: -89.6774,
    postal_code: '49947'
  },
  '49948': {
    country: 'US',
    city: 'Mass City',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ontonagon',
    latitude: 46.7474,
    longitude: -89.0649,
    postal_code: '49948'
  },
  '49950': {
    country: 'US',
    city: 'Mohawk',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Keweenaw',
    latitude: 47.3526,
    longitude: -88.2889,
    postal_code: '49950'
  },
  '49952': {
    country: 'US',
    city: 'Nisula',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 46.7748,
    longitude: -88.8799,
    postal_code: '49952'
  },
  '49953': {
    country: 'US',
    city: 'Ontonagon',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ontonagon',
    latitude: 46.8275,
    longitude: -89.3485,
    postal_code: '49953'
  },
  '49955': {
    country: 'US',
    city: 'Painesdale',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.0404,
    longitude: -88.6704,
    postal_code: '49955'
  },
  '49958': {
    country: 'US',
    city: 'Pelkie',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 46.7949,
    longitude: -88.6251,
    postal_code: '49958'
  },
  '49959': {
    country: 'US',
    city: 'Ramsay',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gogebic',
    latitude: 46.4727,
    longitude: -89.9976,
    postal_code: '49959'
  },
  '49960': {
    country: 'US',
    city: 'Rockland',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ontonagon',
    latitude: 46.7537,
    longitude: -89.1958,
    postal_code: '49960'
  },
  '49961': {
    country: 'US',
    city: 'Sidnaw',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 46.5047,
    longitude: -88.7085,
    postal_code: '49961'
  },
  '49962': {
    country: 'US',
    city: 'Skanee',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Baraga',
    latitude: 46.8747,
    longitude: -88.1732,
    postal_code: '49962'
  },
  '49963': {
    country: 'US',
    city: 'South Range',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 47.0719,
    longitude: -88.652,
    postal_code: '49963'
  },
  '49964': {
    country: 'US',
    city: 'Stambaugh',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Iron',
    latitude: 46.0797,
    longitude: -88.629,
    postal_code: '49964'
  },
  '49965': {
    country: 'US',
    city: 'Toivola',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Houghton',
    latitude: 46.9109,
    longitude: -88.8532,
    postal_code: '49965'
  },
  '49967': {
    country: 'US',
    city: 'Trout Creek',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ontonagon',
    latitude: 46.4864,
    longitude: -89.027,
    postal_code: '49967'
  },
  '49968': {
    country: 'US',
    city: 'Wakefield',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gogebic',
    latitude: 46.4752,
    longitude: -89.9424,
    postal_code: '49968'
  },
  '49969': {
    country: 'US',
    city: 'Watersmeet',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Gogebic',
    latitude: 46.2542,
    longitude: -89.2108,
    postal_code: '49969'
  },
  '49970': {
    country: 'US',
    city: 'Watton',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Baraga',
    latitude: 46.5413,
    longitude: -88.6049,
    postal_code: '49970'
  },
  '49971': {
    country: 'US',
    city: 'White Pine',
    state: 'Michigan',
    state_short: 'MI',
    county: 'Ontonagon',
    latitude: 46.7538,
    longitude: -89.584,
    postal_code: '49971'
  },
  '50001': {
    country: 'US',
    city: 'Ackworth',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.3737,
    longitude: -93.3767,
    postal_code: '50001'
  },
  '50002': {
    country: 'US',
    city: 'Adair',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Adair',
    latitude: 41.5137,
    longitude: -94.6444,
    postal_code: '50002'
  },
  '50003': {
    country: 'US',
    city: 'Adel',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.6221,
    longitude: -94.038,
    postal_code: '50003'
  },
  '50005': {
    country: 'US',
    city: 'Albion',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 42.1143,
    longitude: -92.9882,
    postal_code: '50005'
  },
  '50006': {
    country: 'US',
    city: 'Alden',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.5179,
    longitude: -93.3841,
    postal_code: '50006'
  },
  '50007': {
    country: 'US',
    city: 'Alleman',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.8159,
    longitude: -93.6063,
    postal_code: '50007'
  },
  '50008': {
    country: 'US',
    city: 'Allerton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wayne',
    latitude: 40.7023,
    longitude: -93.3724,
    postal_code: '50008'
  },
  '50009': {
    country: 'US',
    city: 'Altoona',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6475,
    longitude: -93.4724,
    postal_code: '50009'
  },
  '50010': {
    country: 'US',
    city: 'Ames',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.0379,
    longitude: -93.6003,
    postal_code: '50010'
  },
  '50011': {
    country: 'US',
    city: 'Ames',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.036,
    longitude: -93.4652,
    postal_code: '50011'
  },
  '50012': {
    country: 'US',
    city: 'Ames',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.0236,
    longitude: -93.6485,
    postal_code: '50012'
  },
  '50013': {
    country: 'US',
    city: 'Ames',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.0235,
    longitude: -93.6408,
    postal_code: '50013'
  },
  '50014': {
    country: 'US',
    city: 'Ames',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.0486,
    longitude: -93.6945,
    postal_code: '50014'
  },
  '50020': {
    country: 'US',
    city: 'Anita',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cass',
    latitude: 41.45,
    longitude: -94.7794,
    postal_code: '50020'
  },
  '50021': {
    country: 'US',
    city: 'Ankeny',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.7212,
    longitude: -93.5682,
    postal_code: '50021'
  },
  '50022': {
    country: 'US',
    city: 'Atlantic',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cass',
    latitude: 41.4003,
    longitude: -95.0101,
    postal_code: '50022'
  },
  '50023': {
    country: 'US',
    city: 'Ankeny',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.7194,
    longitude: -93.6266,
    postal_code: '50023'
  },
  '50025': {
    country: 'US',
    city: 'Audubon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Audubon',
    latitude: 41.73,
    longitude: -94.9164,
    postal_code: '50025'
  },
  '50026': {
    country: 'US',
    city: 'Bagley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Guthrie',
    latitude: 41.8341,
    longitude: -94.4415,
    postal_code: '50026'
  },
  '50027': {
    country: 'US',
    city: 'Barnes City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mahaska',
    latitude: 41.4883,
    longitude: -92.4703,
    postal_code: '50027'
  },
  '50028': {
    country: 'US',
    city: 'Baxter',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.8204,
    longitude: -93.1583,
    postal_code: '50028'
  },
  '50029': {
    country: 'US',
    city: 'Bayard',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Guthrie',
    latitude: 41.8519,
    longitude: -94.5583,
    postal_code: '50029'
  },
  '50031': {
    country: 'US',
    city: 'Beaver',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Boone',
    latitude: 42.0387,
    longitude: -94.1436,
    postal_code: '50031'
  },
  '50032': {
    country: 'US',
    city: 'Berwick',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6658,
    longitude: -93.5432,
    postal_code: '50032'
  },
  '50033': {
    country: 'US',
    city: 'Bevington',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Madison',
    latitude: 41.363,
    longitude: -93.7928,
    postal_code: '50033'
  },
  '50034': {
    country: 'US',
    city: 'Blairsburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hamilton',
    latitude: 42.4937,
    longitude: -93.6594,
    postal_code: '50034'
  },
  '50035': {
    country: 'US',
    city: 'Bondurant',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.7017,
    longitude: -93.4527,
    postal_code: '50035'
  },
  '50036': {
    country: 'US',
    city: 'Boone',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Boone',
    latitude: 42.0694,
    longitude: -93.8781,
    postal_code: '50036'
  },
  '50037': {
    country: 'US',
    city: 'Boone',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Boone',
    latitude: 42.0597,
    longitude: -93.8802,
    postal_code: '50037'
  },
  '50038': {
    country: 'US',
    city: 'Booneville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.5242,
    longitude: -93.8838,
    postal_code: '50038'
  },
  '50039': {
    country: 'US',
    city: 'Bouton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.8284,
    longitude: -93.9963,
    postal_code: '50039'
  },
  '50040': {
    country: 'US',
    city: 'Boxholm',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Boone',
    latitude: 42.1706,
    longitude: -94.1052,
    postal_code: '50040'
  },
  '50041': {
    country: 'US',
    city: 'Bradford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Franklin',
    latitude: 42.633,
    longitude: -93.2449,
    postal_code: '50041'
  },
  '50042': {
    country: 'US',
    city: 'Brayton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Audubon',
    latitude: 41.5524,
    longitude: -94.974,
    postal_code: '50042'
  },
  '50043': {
    country: 'US',
    city: 'Buckeye',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.4179,
    longitude: -93.3755,
    postal_code: '50043'
  },
  '50044': {
    country: 'US',
    city: 'Bussey',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.2094,
    longitude: -92.8963,
    postal_code: '50044'
  },
  '50046': {
    country: 'US',
    city: 'Cambridge',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 41.9025,
    longitude: -93.5305,
    postal_code: '50046'
  },
  '50047': {
    country: 'US',
    city: 'Carlisle',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.4814,
    longitude: -93.4921,
    postal_code: '50047'
  },
  '50048': {
    country: 'US',
    city: 'Casey',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Guthrie',
    latitude: 41.505,
    longitude: -94.5194,
    postal_code: '50048'
  },
  '50049': {
    country: 'US',
    city: 'Chariton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lucas',
    latitude: 41.0226,
    longitude: -93.3042,
    postal_code: '50049'
  },
  '50050': {
    country: 'US',
    city: 'Churdan',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Greene',
    latitude: 42.1614,
    longitude: -94.4933,
    postal_code: '50050'
  },
  '50051': {
    country: 'US',
    city: 'Clemons',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 42.09,
    longitude: -93.1688,
    postal_code: '50051'
  },
  '50052': {
    country: 'US',
    city: 'Clio',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wayne',
    latitude: 40.6342,
    longitude: -93.448,
    postal_code: '50052'
  },
  '50054': {
    country: 'US',
    city: 'Colfax',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.6788,
    longitude: -93.2588,
    postal_code: '50054'
  },
  '50055': {
    country: 'US',
    city: 'Collins',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 41.9032,
    longitude: -93.3008,
    postal_code: '50055'
  },
  '50056': {
    country: 'US',
    city: 'Colo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.0131,
    longitude: -93.3071,
    postal_code: '50056'
  },
  '50057': {
    country: 'US',
    city: 'Columbia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.1838,
    longitude: -93.1582,
    postal_code: '50057'
  },
  '50058': {
    country: 'US',
    city: 'Coon Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 41.894,
    longitude: -94.68,
    postal_code: '50058'
  },
  '50059': {
    country: 'US',
    city: 'Cooper',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Greene',
    latitude: 42.0363,
    longitude: -94.397,
    postal_code: '50059'
  },
  '50060': {
    country: 'US',
    city: 'Corydon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wayne',
    latitude: 40.7771,
    longitude: -93.3274,
    postal_code: '50060'
  },
  '50061': {
    country: 'US',
    city: 'Cumming',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.4849,
    longitude: -93.7539,
    postal_code: '50061'
  },
  '50062': {
    country: 'US',
    city: 'Melcher Dallas',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.225,
    longitude: -93.2413,
    postal_code: '50062'
  },
  '50063': {
    country: 'US',
    city: 'Dallas Center',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.6862,
    longitude: -93.9707,
    postal_code: '50063'
  },
  '50064': {
    country: 'US',
    city: 'Dana',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Greene',
    latitude: 42.1075,
    longitude: -94.2408,
    postal_code: '50064'
  },
  '50065': {
    country: 'US',
    city: 'Davis City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Decatur',
    latitude: 40.6346,
    longitude: -93.8105,
    postal_code: '50065'
  },
  '50066': {
    country: 'US',
    city: 'Dawson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.8324,
    longitude: -94.2171,
    postal_code: '50066'
  },
  '50067': {
    country: 'US',
    city: 'Decatur',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Decatur',
    latitude: 40.743,
    longitude: -93.8317,
    postal_code: '50067'
  },
  '50068': {
    country: 'US',
    city: 'Derby',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lucas',
    latitude: 40.9355,
    longitude: -93.4796,
    postal_code: '50068'
  },
  '50069': {
    country: 'US',
    city: 'De Soto',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.5208,
    longitude: -94.0303,
    postal_code: '50069'
  },
  '50070': {
    country: 'US',
    city: 'Dexter',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.5198,
    longitude: -94.2145,
    postal_code: '50070'
  },
  '50071': {
    country: 'US',
    city: 'Dows',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wright',
    latitude: 42.661,
    longitude: -93.5058,
    postal_code: '50071'
  },
  '50072': {
    country: 'US',
    city: 'Earlham',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Madison',
    latitude: 41.4709,
    longitude: -94.1294,
    postal_code: '50072'
  },
  '50073': {
    country: 'US',
    city: 'Elkhart',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.7917,
    longitude: -93.5222,
    postal_code: '50073'
  },
  '50074': {
    country: 'US',
    city: 'Ellston',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ringgold',
    latitude: 40.8505,
    longitude: -94.0841,
    postal_code: '50074'
  },
  '50075': {
    country: 'US',
    city: 'Ellsworth',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hamilton',
    latitude: 42.3009,
    longitude: -93.549,
    postal_code: '50075'
  },
  '50076': {
    country: 'US',
    city: 'Exira',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Audubon',
    latitude: 41.5768,
    longitude: -94.8571,
    postal_code: '50076'
  },
  '50078': {
    country: 'US',
    city: 'Ferguson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 41.9374,
    longitude: -92.865,
    postal_code: '50078'
  },
  '50099': {
    country: 'US',
    city: 'Boone',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Boone',
    latitude: 42.0597,
    longitude: -93.8802,
    postal_code: '50099'
  },
  '50101': {
    country: 'US',
    city: 'Galt',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wright',
    latitude: 42.6912,
    longitude: -93.6035,
    postal_code: '50101'
  },
  '50102': {
    country: 'US',
    city: 'Garden City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.2455,
    longitude: -93.3955,
    postal_code: '50102'
  },
  '50103': {
    country: 'US',
    city: 'Garden Grove',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Decatur',
    latitude: 40.7711,
    longitude: -93.6117,
    postal_code: '50103'
  },
  '50104': {
    country: 'US',
    city: 'Gibson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.4868,
    longitude: -92.3867,
    postal_code: '50104'
  },
  '50105': {
    country: 'US',
    city: 'Gilbert',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.1148,
    longitude: -93.6399,
    postal_code: '50105'
  },
  '50106': {
    country: 'US',
    city: 'Gilman',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 41.9012,
    longitude: -92.8101,
    postal_code: '50106'
  },
  '50107': {
    country: 'US',
    city: 'Grand Junction',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Greene',
    latitude: 42.0296,
    longitude: -94.2336,
    postal_code: '50107'
  },
  '50108': {
    country: 'US',
    city: 'Grand River',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Decatur',
    latitude: 40.8137,
    longitude: -93.9544,
    postal_code: '50108'
  },
  '50109': {
    country: 'US',
    city: 'Granger',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.7611,
    longitude: -93.8244,
    postal_code: '50109'
  },
  '50110': {
    country: 'US',
    city: 'Gray',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Audubon',
    latitude: 41.8401,
    longitude: -94.9812,
    postal_code: '50110'
  },
  '50111': {
    country: 'US',
    city: 'Grimes',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.7018,
    longitude: -93.7821,
    postal_code: '50111'
  },
  '50112': {
    country: 'US',
    city: 'Grinnell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Poweshiek',
    latitude: 41.7421,
    longitude: -92.7344,
    postal_code: '50112'
  },
  '50115': {
    country: 'US',
    city: 'Guthrie Center',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Guthrie',
    latitude: 41.6837,
    longitude: -94.4864,
    postal_code: '50115'
  },
  '50116': {
    country: 'US',
    city: 'Hamilton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.19,
    longitude: -92.959,
    postal_code: '50116'
  },
  '50117': {
    country: 'US',
    city: 'Hamlin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Audubon',
    latitude: 41.6506,
    longitude: -94.9157,
    postal_code: '50117'
  },
  '50118': {
    country: 'US',
    city: 'Hartford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.4596,
    longitude: -93.4022,
    postal_code: '50118'
  },
  '50119': {
    country: 'US',
    city: 'Harvey',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.303,
    longitude: -92.9318,
    postal_code: '50119'
  },
  '50120': {
    country: 'US',
    city: 'Haverhill',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 41.9337,
    longitude: -92.9829,
    postal_code: '50120'
  },
  '50122': {
    country: 'US',
    city: 'Hubbard',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.3022,
    longitude: -93.2939,
    postal_code: '50122'
  },
  '50123': {
    country: 'US',
    city: 'Humeston',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wayne',
    latitude: 40.8469,
    longitude: -93.5101,
    postal_code: '50123'
  },
  '50124': {
    country: 'US',
    city: 'Huxley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 41.8994,
    longitude: -93.6024,
    postal_code: '50124'
  },
  '50125': {
    country: 'US',
    city: 'Indianola',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.3143,
    longitude: -93.588,
    postal_code: '50125'
  },
  '50126': {
    country: 'US',
    city: 'Iowa Falls',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.5138,
    longitude: -93.2709,
    postal_code: '50126'
  },
  '50127': {
    country: 'US',
    city: 'Ira',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.7771,
    longitude: -93.2058,
    postal_code: '50127'
  },
  '50128': {
    country: 'US',
    city: 'Jamaica',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Guthrie',
    latitude: 41.8419,
    longitude: -94.3204,
    postal_code: '50128'
  },
  '50129': {
    country: 'US',
    city: 'Jefferson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Greene',
    latitude: 42.0093,
    longitude: -94.3888,
    postal_code: '50129'
  },
  '50130': {
    country: 'US',
    city: 'Jewell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hamilton',
    latitude: 42.3139,
    longitude: -93.6428,
    postal_code: '50130'
  },
  '50131': {
    country: 'US',
    city: 'Johnston',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.673,
    longitude: -93.7028,
    postal_code: '50131'
  },
  '50132': {
    country: 'US',
    city: 'Kamrar',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hamilton',
    latitude: 42.3945,
    longitude: -93.7279,
    postal_code: '50132'
  },
  '50133': {
    country: 'US',
    city: 'Kellerton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ringgold',
    latitude: 40.6861,
    longitude: -94.0607,
    postal_code: '50133'
  },
  '50134': {
    country: 'US',
    city: 'Kelley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 41.9488,
    longitude: -93.6644,
    postal_code: '50134'
  },
  '50135': {
    country: 'US',
    city: 'Kellogg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.7184,
    longitude: -92.9114,
    postal_code: '50135'
  },
  '50136': {
    country: 'US',
    city: 'Keswick',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.4613,
    longitude: -92.2395,
    postal_code: '50136'
  },
  '50137': {
    country: 'US',
    city: 'Killduff',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.608,
    longitude: -92.9046,
    postal_code: '50137'
  },
  '50138': {
    country: 'US',
    city: 'Knoxville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.3164,
    longitude: -93.0954,
    postal_code: '50138'
  },
  '50139': {
    country: 'US',
    city: 'Lacona',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.1988,
    longitude: -93.3838,
    postal_code: '50139'
  },
  '50140': {
    country: 'US',
    city: 'Lamoni',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Decatur',
    latitude: 40.6229,
    longitude: -93.9349,
    postal_code: '50140'
  },
  '50141': {
    country: 'US',
    city: 'Laurel',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 41.8829,
    longitude: -92.9263,
    postal_code: '50141'
  },
  '50142': {
    country: 'US',
    city: 'Le Grand',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 42.0066,
    longitude: -92.7756,
    postal_code: '50142'
  },
  '50143': {
    country: 'US',
    city: 'Leighton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mahaska',
    latitude: 41.3647,
    longitude: -92.8081,
    postal_code: '50143'
  },
  '50144': {
    country: 'US',
    city: 'Leon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Decatur',
    latitude: 40.7373,
    longitude: -93.7429,
    postal_code: '50144'
  },
  '50145': {
    country: 'US',
    city: 'Liberty Center',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.2071,
    longitude: -93.4993,
    postal_code: '50145'
  },
  '50146': {
    country: 'US',
    city: 'Linden',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.6422,
    longitude: -94.2406,
    postal_code: '50146'
  },
  '50147': {
    country: 'US',
    city: 'Lineville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wayne',
    latitude: 40.6004,
    longitude: -93.4862,
    postal_code: '50147'
  },
  '50148': {
    country: 'US',
    city: 'Liscomb',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 42.184,
    longitude: -92.9833,
    postal_code: '50148'
  },
  '50149': {
    country: 'US',
    city: 'Lorimor',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Union',
    latitude: 41.1233,
    longitude: -94.0639,
    postal_code: '50149'
  },
  '50150': {
    country: 'US',
    city: 'Lovilia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monroe',
    latitude: 41.1272,
    longitude: -92.9293,
    postal_code: '50150'
  },
  '50151': {
    country: 'US',
    city: 'Lucas',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lucas',
    latitude: 41.0585,
    longitude: -93.4836,
    postal_code: '50151'
  },
  '50152': {
    country: 'US',
    city: 'Luther',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Boone',
    latitude: 41.9387,
    longitude: -93.8371,
    postal_code: '50152'
  },
  '50153': {
    country: 'US',
    city: 'Lynnville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.5698,
    longitude: -92.7877,
    postal_code: '50153'
  },
  '50154': {
    country: 'US',
    city: 'Mc Callsburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.1665,
    longitude: -93.3978,
    postal_code: '50154'
  },
  '50155': {
    country: 'US',
    city: 'Macksburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Madison',
    latitude: 41.2104,
    longitude: -94.1825,
    postal_code: '50155'
  },
  '50156': {
    country: 'US',
    city: 'Madrid',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Boone',
    latitude: 41.8841,
    longitude: -93.8448,
    postal_code: '50156'
  },
  '50157': {
    country: 'US',
    city: 'Malcom',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Poweshiek',
    latitude: 41.7695,
    longitude: -92.5354,
    postal_code: '50157'
  },
  '50158': {
    country: 'US',
    city: 'Marshalltown',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 42.0405,
    longitude: -92.9127,
    postal_code: '50158'
  },
  '50160': {
    country: 'US',
    city: 'Martensdale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.373,
    longitude: -93.7358,
    postal_code: '50160'
  },
  '50161': {
    country: 'US',
    city: 'Maxwell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 41.8967,
    longitude: -93.4001,
    postal_code: '50161'
  },
  '50162': {
    country: 'US',
    city: 'Melbourne',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 41.9323,
    longitude: -93.0856,
    postal_code: '50162'
  },
  '50163': {
    country: 'US',
    city: 'Melcher Dallas',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.225,
    longitude: -93.2413,
    postal_code: '50163'
  },
  '50164': {
    country: 'US',
    city: 'Menlo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Guthrie',
    latitude: 41.5348,
    longitude: -94.409,
    postal_code: '50164'
  },
  '50165': {
    country: 'US',
    city: 'Millerton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wayne',
    latitude: 40.8494,
    longitude: -93.3055,
    postal_code: '50165'
  },
  '50166': {
    country: 'US',
    city: 'Milo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.2904,
    longitude: -93.4454,
    postal_code: '50166'
  },
  '50167': {
    country: 'US',
    city: 'Minburn',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.7562,
    longitude: -94.015,
    postal_code: '50167'
  },
  '50168': {
    country: 'US',
    city: 'Mingo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.7898,
    longitude: -93.288,
    postal_code: '50168'
  },
  '50169': {
    country: 'US',
    city: 'Mitchellville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6609,
    longitude: -93.37,
    postal_code: '50169'
  },
  '50170': {
    country: 'US',
    city: 'Monroe',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.5222,
    longitude: -93.1019,
    postal_code: '50170'
  },
  '50171': {
    country: 'US',
    city: 'Montezuma',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Poweshiek',
    latitude: 41.5928,
    longitude: -92.5276,
    postal_code: '50171'
  },
  '50173': {
    country: 'US',
    city: 'Montour',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 41.9709,
    longitude: -92.7006,
    postal_code: '50173'
  },
  '50174': {
    country: 'US',
    city: 'Murray',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clarke',
    latitude: 41.0376,
    longitude: -93.953,
    postal_code: '50174'
  },
  '50201': {
    country: 'US',
    city: 'Nevada',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.019,
    longitude: -93.4462,
    postal_code: '50201'
  },
  '50206': {
    country: 'US',
    city: 'New Providence',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.2693,
    longitude: -93.1753,
    postal_code: '50206'
  },
  '50207': {
    country: 'US',
    city: 'New Sharon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mahaska',
    latitude: 41.464,
    longitude: -92.6848,
    postal_code: '50207'
  },
  '50208': {
    country: 'US',
    city: 'Newton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.6992,
    longitude: -93.0455,
    postal_code: '50208'
  },
  '50210': {
    country: 'US',
    city: 'New Virginia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.1883,
    longitude: -93.6976,
    postal_code: '50210'
  },
  '50211': {
    country: 'US',
    city: 'Norwalk',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.4861,
    longitude: -93.6573,
    postal_code: '50211'
  },
  '50212': {
    country: 'US',
    city: 'Ogden',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Boone',
    latitude: 42.0351,
    longitude: -94.0063,
    postal_code: '50212'
  },
  '50213': {
    country: 'US',
    city: 'Osceola',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clarke',
    latitude: 41.0295,
    longitude: -93.7712,
    postal_code: '50213'
  },
  '50214': {
    country: 'US',
    city: 'Otley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.4518,
    longitude: -93.0348,
    postal_code: '50214'
  },
  '50216': {
    country: 'US',
    city: 'Panora',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Guthrie',
    latitude: 41.6967,
    longitude: -94.3606,
    postal_code: '50216'
  },
  '50217': {
    country: 'US',
    city: 'Paton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Greene',
    latitude: 42.1658,
    longitude: -94.273,
    postal_code: '50217'
  },
  '50218': {
    country: 'US',
    city: 'Patterson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Madison',
    latitude: 41.3499,
    longitude: -93.8803,
    postal_code: '50218'
  },
  '50219': {
    country: 'US',
    city: 'Pella',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.4082,
    longitude: -92.9172,
    postal_code: '50219'
  },
  '50220': {
    country: 'US',
    city: 'Perry',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.8397,
    longitude: -94.1022,
    postal_code: '50220'
  },
  '50222': {
    country: 'US',
    city: 'Peru',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Madison',
    latitude: 41.2145,
    longitude: -93.9437,
    postal_code: '50222'
  },
  '50223': {
    country: 'US',
    city: 'Pilot Mound',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Boone',
    latitude: 42.1604,
    longitude: -94.0102,
    postal_code: '50223'
  },
  '50225': {
    country: 'US',
    city: 'Pleasantville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.3738,
    longitude: -93.2712,
    postal_code: '50225'
  },
  '50226': {
    country: 'US',
    city: 'Polk City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.7549,
    longitude: -93.6893,
    postal_code: '50226'
  },
  '50227': {
    country: 'US',
    city: 'Popejoy',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Franklin',
    latitude: 42.5974,
    longitude: -93.4279,
    postal_code: '50227'
  },
  '50228': {
    country: 'US',
    city: 'Prairie City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.5854,
    longitude: -93.241,
    postal_code: '50228'
  },
  '50229': {
    country: 'US',
    city: 'Prole',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.383,
    longitude: -93.7344,
    postal_code: '50229'
  },
  '50230': {
    country: 'US',
    city: 'Radcliffe',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.3241,
    longitude: -93.4234,
    postal_code: '50230'
  },
  '50231': {
    country: 'US',
    city: 'Randall',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hamilton',
    latitude: 42.2383,
    longitude: -93.6008,
    postal_code: '50231'
  },
  '50232': {
    country: 'US',
    city: 'Reasnor',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.5798,
    longitude: -93.0198,
    postal_code: '50232'
  },
  '50233': {
    country: 'US',
    city: 'Redfield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.5873,
    longitude: -94.1897,
    postal_code: '50233'
  },
  '50234': {
    country: 'US',
    city: 'Rhodes',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 41.9177,
    longitude: -93.1796,
    postal_code: '50234'
  },
  '50235': {
    country: 'US',
    city: 'Rippey',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Greene',
    latitude: 41.9257,
    longitude: -94.2132,
    postal_code: '50235'
  },
  '50236': {
    country: 'US',
    city: 'Roland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.1646,
    longitude: -93.5063,
    postal_code: '50236'
  },
  '50237': {
    country: 'US',
    city: 'Runnells',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5515,
    longitude: -93.4202,
    postal_code: '50237'
  },
  '50238': {
    country: 'US',
    city: 'Russell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lucas',
    latitude: 40.9456,
    longitude: -93.1784,
    postal_code: '50238'
  },
  '50239': {
    country: 'US',
    city: 'Saint Anthony',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 42.152,
    longitude: -93.1824,
    postal_code: '50239'
  },
  '50240': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Madison',
    latitude: 41.2924,
    longitude: -93.8242,
    postal_code: '50240'
  },
  '50241': {
    country: 'US',
    city: 'Saint Marys',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Warren',
    latitude: 41.3084,
    longitude: -93.7369,
    postal_code: '50241'
  },
  '50242': {
    country: 'US',
    city: 'Searsboro',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Poweshiek',
    latitude: 41.5656,
    longitude: -92.6987,
    postal_code: '50242'
  },
  '50243': {
    country: 'US',
    city: 'Sheldahl',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.8571,
    longitude: -93.6973,
    postal_code: '50243'
  },
  '50244': {
    country: 'US',
    city: 'Slater',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 41.8765,
    longitude: -93.6812,
    postal_code: '50244'
  },
  '50246': {
    country: 'US',
    city: 'Stanhope',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hamilton',
    latitude: 42.2904,
    longitude: -93.7751,
    postal_code: '50246'
  },
  '50247': {
    country: 'US',
    city: 'State Center',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marshall',
    latitude: 42.0109,
    longitude: -93.1673,
    postal_code: '50247'
  },
  '50248': {
    country: 'US',
    city: 'Story City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.1835,
    longitude: -93.5988,
    postal_code: '50248'
  },
  '50249': {
    country: 'US',
    city: 'Stratford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hamilton',
    latitude: 42.2847,
    longitude: -93.9029,
    postal_code: '50249'
  },
  '50250': {
    country: 'US',
    city: 'Stuart',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Guthrie',
    latitude: 41.5033,
    longitude: -94.3186,
    postal_code: '50250'
  },
  '50251': {
    country: 'US',
    city: 'Sully',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jasper',
    latitude: 41.574,
    longitude: -92.8451,
    postal_code: '50251'
  },
  '50252': {
    country: 'US',
    city: 'Swan',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.4636,
    longitude: -93.301,
    postal_code: '50252'
  },
  '50254': {
    country: 'US',
    city: 'Thayer',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Union',
    latitude: 41.0029,
    longitude: -94.0686,
    postal_code: '50254'
  },
  '50255': {
    country: 'US',
    city: 'Thornburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.4547,
    longitude: -92.3357,
    postal_code: '50255'
  },
  '50256': {
    country: 'US',
    city: 'Tracy',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Marion',
    latitude: 41.2756,
    longitude: -92.8753,
    postal_code: '50256'
  },
  '50257': {
    country: 'US',
    city: 'Truro',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Madison',
    latitude: 41.2076,
    longitude: -93.841,
    postal_code: '50257'
  },
  '50258': {
    country: 'US',
    city: 'Union',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.2521,
    longitude: -93.0562,
    postal_code: '50258'
  },
  '50259': {
    country: 'US',
    city: 'Gifford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.3831,
    longitude: -93.2506,
    postal_code: '50259'
  },
  '50261': {
    country: 'US',
    city: 'Van Meter',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.4699,
    longitude: -93.9207,
    postal_code: '50261'
  },
  '50262': {
    country: 'US',
    city: 'Van Wert',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Decatur',
    latitude: 40.8645,
    longitude: -93.8073,
    postal_code: '50262'
  },
  '50263': {
    country: 'US',
    city: 'Waukee',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.593,
    longitude: -93.8592,
    postal_code: '50263'
  },
  '50264': {
    country: 'US',
    city: 'Weldon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Decatur',
    latitude: 40.8739,
    longitude: -93.7387,
    postal_code: '50264'
  },
  '50265': {
    country: 'US',
    city: 'West Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5805,
    longitude: -93.7447,
    postal_code: '50265'
  },
  '50266': {
    country: 'US',
    city: 'West Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5695,
    longitude: -93.7994,
    postal_code: '50266'
  },
  '50268': {
    country: 'US',
    city: 'What Cheer',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.3953,
    longitude: -92.3548,
    postal_code: '50268'
  },
  '50269': {
    country: 'US',
    city: 'Whitten',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.2718,
    longitude: -93.0214,
    postal_code: '50269'
  },
  '50271': {
    country: 'US',
    city: 'Williams',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hamilton',
    latitude: 42.4883,
    longitude: -93.5449,
    postal_code: '50271'
  },
  '50272': {
    country: 'US',
    city: 'Williamson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lucas',
    latitude: 41.0959,
    longitude: -93.2559,
    postal_code: '50272'
  },
  '50273': {
    country: 'US',
    city: 'Winterset',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Madison',
    latitude: 41.3391,
    longitude: -94.0088,
    postal_code: '50273'
  },
  '50274': {
    country: 'US',
    city: 'Wiota',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cass',
    latitude: 41.3846,
    longitude: -94.8405,
    postal_code: '50274'
  },
  '50275': {
    country: 'US',
    city: 'Woodburn',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clarke',
    latitude: 41,
    longitude: -93.6083,
    postal_code: '50275'
  },
  '50276': {
    country: 'US',
    city: 'Woodward',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dallas',
    latitude: 41.8452,
    longitude: -93.9056,
    postal_code: '50276'
  },
  '50277': {
    country: 'US',
    city: 'Yale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Guthrie',
    latitude: 41.7753,
    longitude: -94.3503,
    postal_code: '50277'
  },
  '50278': {
    country: 'US',
    city: 'Zearing',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Story',
    latitude: 42.1535,
    longitude: -93.2928,
    postal_code: '50278'
  },
  '50301': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50301'
  },
  '50302': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50302'
  },
  '50303': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50303'
  },
  '50304': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50304'
  },
  '50305': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50305'
  },
  '50306': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50306'
  },
  '50307': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6005,
    longitude: -93.6091,
    postal_code: '50307'
  },
  '50308': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50308'
  },
  '50309': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5887,
    longitude: -93.6212,
    postal_code: '50309'
  },
  '50310': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6255,
    longitude: -93.6736,
    postal_code: '50310'
  },
  '50311': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.601,
    longitude: -93.6729,
    postal_code: '50311'
  },
  '50312': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5855,
    longitude: -93.6719,
    postal_code: '50312'
  },
  '50313': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6381,
    longitude: -93.6203,
    postal_code: '50313'
  },
  '50314': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.603,
    longitude: -93.633,
    postal_code: '50314'
  },
  '50315': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5444,
    longitude: -93.6192,
    postal_code: '50315'
  },
  '50316': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6092,
    longitude: -93.6,
    postal_code: '50316'
  },
  '50317': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6122,
    longitude: -93.5296,
    postal_code: '50317'
  },
  '50318': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50318'
  },
  '50319': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5921,
    longitude: -93.604,
    postal_code: '50319'
  },
  '50320': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5487,
    longitude: -93.5827,
    postal_code: '50320'
  },
  '50321': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5476,
    longitude: -93.6618,
    postal_code: '50321'
  },
  '50322': {
    country: 'US',
    city: 'Urbandale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6295,
    longitude: -93.723,
    postal_code: '50322'
  },
  '50323': {
    country: 'US',
    city: 'Urbandale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6294,
    longitude: -93.7717,
    postal_code: '50323'
  },
  '50324': {
    country: 'US',
    city: 'Windsor Heights',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6032,
    longitude: -93.7152,
    postal_code: '50324'
  },
  '50325': {
    country: 'US',
    city: 'Clive',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6067,
    longitude: -93.7457,
    postal_code: '50325'
  },
  '50327': {
    country: 'US',
    city: 'Pleasant Hill',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5839,
    longitude: -93.5199,
    postal_code: '50327'
  },
  '50328': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50328'
  },
  '50329': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50329'
  },
  '50330': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50330'
  },
  '50331': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50331'
  },
  '50332': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50332'
  },
  '50333': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50333'
  },
  '50334': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50334'
  },
  '50335': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50335'
  },
  '50336': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50336'
  },
  '50339': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50339'
  },
  '50340': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50340'
  },
  '50359': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50359'
  },
  '50360': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50360'
  },
  '50361': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50361'
  },
  '50362': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50362'
  },
  '50363': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50363'
  },
  '50364': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50364'
  },
  '50367': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50367'
  },
  '50368': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50368'
  },
  '50369': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50369'
  },
  '50380': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50380'
  },
  '50381': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50381'
  },
  '50391': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50391'
  },
  '50392': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.5878,
    longitude: -93.6274,
    postal_code: '50392'
  },
  '50393': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50393'
  },
  '50394': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50394'
  },
  '50395': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50395'
  },
  '50396': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50396'
  },
  '50398': {
    country: 'US',
    city: 'Urbandale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6267,
    longitude: -93.7122,
    postal_code: '50398'
  },
  '50401': {
    country: 'US',
    city: 'Mason City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 43.1499,
    longitude: -93.1954,
    postal_code: '50401'
  },
  '50402': {
    country: 'US',
    city: 'Mason City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 43.1536,
    longitude: -93.201,
    postal_code: '50402'
  },
  '50420': {
    country: 'US',
    city: 'Alexander',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Franklin',
    latitude: 42.8114,
    longitude: -93.445,
    postal_code: '50420'
  },
  '50421': {
    country: 'US',
    city: 'Belmond',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wright',
    latitude: 42.8511,
    longitude: -93.62,
    postal_code: '50421'
  },
  '50423': {
    country: 'US',
    city: 'Britt',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hancock',
    latitude: 43.1006,
    longitude: -93.7752,
    postal_code: '50423'
  },
  '50424': {
    country: 'US',
    city: 'Buffalo Center',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winnebago',
    latitude: 43.3732,
    longitude: -93.9376,
    postal_code: '50424'
  },
  '50426': {
    country: 'US',
    city: 'Carpenter',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mitchell',
    latitude: 43.415,
    longitude: -93.0149,
    postal_code: '50426'
  },
  '50427': {
    country: 'US',
    city: 'Chapin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Franklin',
    latitude: 42.8349,
    longitude: -93.2226,
    postal_code: '50427'
  },
  '50428': {
    country: 'US',
    city: 'Clear Lake',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 43.1406,
    longitude: -93.385,
    postal_code: '50428'
  },
  '50430': {
    country: 'US',
    city: 'Corwith',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hancock',
    latitude: 42.9927,
    longitude: -93.9575,
    postal_code: '50430'
  },
  '50431': {
    country: 'US',
    city: 'Coulter',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Franklin',
    latitude: 42.7382,
    longitude: -93.37,
    postal_code: '50431'
  },
  '50432': {
    country: 'US',
    city: 'Crystal Lake',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hancock',
    latitude: 43.2233,
    longitude: -93.7924,
    postal_code: '50432'
  },
  '50433': {
    country: 'US',
    city: 'Dougherty',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 42.9222,
    longitude: -93.0421,
    postal_code: '50433'
  },
  '50434': {
    country: 'US',
    city: 'Fertile',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Worth',
    latitude: 43.2644,
    longitude: -93.4183,
    postal_code: '50434'
  },
  '50435': {
    country: 'US',
    city: 'Floyd',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Floyd',
    latitude: 43.143,
    longitude: -92.724,
    postal_code: '50435'
  },
  '50436': {
    country: 'US',
    city: 'Forest City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winnebago',
    latitude: 43.2692,
    longitude: -93.6356,
    postal_code: '50436'
  },
  '50438': {
    country: 'US',
    city: 'Garner',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hancock',
    latitude: 43.1144,
    longitude: -93.5944,
    postal_code: '50438'
  },
  '50439': {
    country: 'US',
    city: 'Goodell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hancock',
    latitude: 42.9383,
    longitude: -93.5824,
    postal_code: '50439'
  },
  '50440': {
    country: 'US',
    city: 'Grafton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Worth',
    latitude: 43.311,
    longitude: -93.0797,
    postal_code: '50440'
  },
  '50441': {
    country: 'US',
    city: 'Hampton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Franklin',
    latitude: 42.7405,
    longitude: -93.211,
    postal_code: '50441'
  },
  '50444': {
    country: 'US',
    city: 'Hanlontown',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Worth',
    latitude: 43.2802,
    longitude: -93.3788,
    postal_code: '50444'
  },
  '50446': {
    country: 'US',
    city: 'Joice',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Worth',
    latitude: 43.3761,
    longitude: -93.4454,
    postal_code: '50446'
  },
  '50447': {
    country: 'US',
    city: 'Kanawha',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hancock',
    latitude: 42.9434,
    longitude: -93.7728,
    postal_code: '50447'
  },
  '50448': {
    country: 'US',
    city: 'Kensett',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Worth',
    latitude: 43.3536,
    longitude: -93.2105,
    postal_code: '50448'
  },
  '50449': {
    country: 'US',
    city: 'Klemme',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hancock',
    latitude: 43.0136,
    longitude: -93.5879,
    postal_code: '50449'
  },
  '50450': {
    country: 'US',
    city: 'Lake Mills',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winnebago',
    latitude: 43.4167,
    longitude: -93.5336,
    postal_code: '50450'
  },
  '50451': {
    country: 'US',
    city: 'Lakota',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.3772,
    longitude: -94.0938,
    postal_code: '50451'
  },
  '50452': {
    country: 'US',
    city: 'Latimer',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Franklin',
    latitude: 42.7598,
    longitude: -93.3518,
    postal_code: '50452'
  },
  '50453': {
    country: 'US',
    city: 'Leland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winnebago',
    latitude: 43.3425,
    longitude: -93.6895,
    postal_code: '50453'
  },
  '50454': {
    country: 'US',
    city: 'Little Cedar',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mitchell',
    latitude: 43.3919,
    longitude: -92.7344,
    postal_code: '50454'
  },
  '50455': {
    country: 'US',
    city: 'Mc Intire',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mitchell',
    latitude: 43.45,
    longitude: -92.6048,
    postal_code: '50455'
  },
  '50456': {
    country: 'US',
    city: 'Manly',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Worth',
    latitude: 43.2891,
    longitude: -93.2018,
    postal_code: '50456'
  },
  '50457': {
    country: 'US',
    city: 'Meservey',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 42.9137,
    longitude: -93.4773,
    postal_code: '50457'
  },
  '50458': {
    country: 'US',
    city: 'Nora Springs',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Floyd',
    latitude: 43.1479,
    longitude: -93.0002,
    postal_code: '50458'
  },
  '50459': {
    country: 'US',
    city: 'Northwood',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Worth',
    latitude: 43.4506,
    longitude: -93.2419,
    postal_code: '50459'
  },
  '50460': {
    country: 'US',
    city: 'Orchard',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mitchell',
    latitude: 43.2355,
    longitude: -92.72,
    postal_code: '50460'
  },
  '50461': {
    country: 'US',
    city: 'Osage',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mitchell',
    latitude: 43.2872,
    longitude: -92.8144,
    postal_code: '50461'
  },
  '50464': {
    country: 'US',
    city: 'Plymouth',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 43.246,
    longitude: -93.1226,
    postal_code: '50464'
  },
  '50465': {
    country: 'US',
    city: 'Rake',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winnebago',
    latitude: 43.4767,
    longitude: -93.9171,
    postal_code: '50465'
  },
  '50466': {
    country: 'US',
    city: 'Riceville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Howard',
    latitude: 43.3719,
    longitude: -92.5554,
    postal_code: '50466'
  },
  '50467': {
    country: 'US',
    city: 'Rock Falls',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 43.2066,
    longitude: -93.0857,
    postal_code: '50467'
  },
  '50468': {
    country: 'US',
    city: 'Rockford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Floyd',
    latitude: 43.0573,
    longitude: -92.952,
    postal_code: '50468'
  },
  '50469': {
    country: 'US',
    city: 'Rockwell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 43.0032,
    longitude: -93.2167,
    postal_code: '50469'
  },
  '50470': {
    country: 'US',
    city: 'Rowan',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wright',
    latitude: 42.7596,
    longitude: -93.557,
    postal_code: '50470'
  },
  '50471': {
    country: 'US',
    city: 'Rudd',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Floyd',
    latitude: 43.142,
    longitude: -92.8885,
    postal_code: '50471'
  },
  '50472': {
    country: 'US',
    city: 'Saint Ansgar',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mitchell',
    latitude: 43.4061,
    longitude: -92.9235,
    postal_code: '50472'
  },
  '50473': {
    country: 'US',
    city: 'Scarville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winnebago',
    latitude: 43.4673,
    longitude: -93.6423,
    postal_code: '50473'
  },
  '50475': {
    country: 'US',
    city: 'Sheffield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Franklin',
    latitude: 42.8779,
    longitude: -93.2156,
    postal_code: '50475'
  },
  '50476': {
    country: 'US',
    city: 'Stacyville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mitchell',
    latitude: 43.4457,
    longitude: -92.761,
    postal_code: '50476'
  },
  '50477': {
    country: 'US',
    city: 'Swaledale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 42.9622,
    longitude: -93.3115,
    postal_code: '50477'
  },
  '50478': {
    country: 'US',
    city: 'Thompson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winnebago',
    latitude: 43.3875,
    longitude: -93.7517,
    postal_code: '50478'
  },
  '50479': {
    country: 'US',
    city: 'Thornton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 42.9684,
    longitude: -93.4088,
    postal_code: '50479'
  },
  '50480': {
    country: 'US',
    city: 'Titonka',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.2456,
    longitude: -94.0367,
    postal_code: '50480'
  },
  '50481': {
    country: 'US',
    city: 'Toeterville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mitchell',
    latitude: 43.3564,
    longitude: -92.789,
    postal_code: '50481'
  },
  '50482': {
    country: 'US',
    city: 'Ventura',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cerro Gordo',
    latitude: 43.1262,
    longitude: -93.4706,
    postal_code: '50482'
  },
  '50483': {
    country: 'US',
    city: 'Wesley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.0977,
    longitude: -94.0038,
    postal_code: '50483'
  },
  '50484': {
    country: 'US',
    city: 'Woden',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hancock',
    latitude: 43.2226,
    longitude: -93.9109,
    postal_code: '50484'
  },
  '50501': {
    country: 'US',
    city: 'Fort Dodge',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.5088,
    longitude: -94.1807,
    postal_code: '50501'
  },
  '50510': {
    country: 'US',
    city: 'Albert City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buena Vista',
    latitude: 42.7784,
    longitude: -94.9824,
    postal_code: '50510'
  },
  '50511': {
    country: 'US',
    city: 'Algona',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.066,
    longitude: -94.2306,
    postal_code: '50511'
  },
  '50514': {
    country: 'US',
    city: 'Armstrong',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Emmet',
    latitude: 43.4024,
    longitude: -94.4853,
    postal_code: '50514'
  },
  '50515': {
    country: 'US',
    city: 'Ayrshire',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Palo Alto',
    latitude: 43.037,
    longitude: -94.8478,
    postal_code: '50515'
  },
  '50516': {
    country: 'US',
    city: 'Badger',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.6144,
    longitude: -94.1461,
    postal_code: '50516'
  },
  '50517': {
    country: 'US',
    city: 'Bancroft',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.2935,
    longitude: -94.2108,
    postal_code: '50517'
  },
  '50518': {
    country: 'US',
    city: 'Barnum',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.5159,
    longitude: -94.3703,
    postal_code: '50518'
  },
  '50519': {
    country: 'US',
    city: 'Bode',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.8661,
    longitude: -94.2781,
    postal_code: '50519'
  },
  '50520': {
    country: 'US',
    city: 'Bradgate',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.7794,
    longitude: -94.4002,
    postal_code: '50520'
  },
  '50521': {
    country: 'US',
    city: 'Burnside',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.3413,
    longitude: -94.1143,
    postal_code: '50521'
  },
  '50522': {
    country: 'US',
    city: 'Burt',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.2062,
    longitude: -94.2122,
    postal_code: '50522'
  },
  '50523': {
    country: 'US',
    city: 'Callender',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.3494,
    longitude: -94.282,
    postal_code: '50523'
  },
  '50524': {
    country: 'US',
    city: 'Clare',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.5954,
    longitude: -94.308,
    postal_code: '50524'
  },
  '50525': {
    country: 'US',
    city: 'Clarion',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wright',
    latitude: 42.7271,
    longitude: -93.7277,
    postal_code: '50525'
  },
  '50526': {
    country: 'US',
    city: 'Clarion',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wright',
    latitude: 42.733,
    longitude: -93.7352,
    postal_code: '50526'
  },
  '50527': {
    country: 'US',
    city: 'Curlew',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Palo Alto',
    latitude: 42.9633,
    longitude: -94.7975,
    postal_code: '50527'
  },
  '50528': {
    country: 'US',
    city: 'Cylinder',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Palo Alto',
    latitude: 43.1507,
    longitude: -94.5107,
    postal_code: '50528'
  },
  '50529': {
    country: 'US',
    city: 'Dakota City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.7214,
    longitude: -94.1993,
    postal_code: '50529'
  },
  '50530': {
    country: 'US',
    city: 'Dayton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.2594,
    longitude: -94.061,
    postal_code: '50530'
  },
  '50531': {
    country: 'US',
    city: 'Dolliver',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Emmet',
    latitude: 43.4664,
    longitude: -94.6242,
    postal_code: '50531'
  },
  '50532': {
    country: 'US',
    city: 'Duncombe',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.4493,
    longitude: -94.0592,
    postal_code: '50532'
  },
  '50533': {
    country: 'US',
    city: 'Eagle Grove',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wright',
    latitude: 42.6609,
    longitude: -93.9049,
    postal_code: '50533'
  },
  '50535': {
    country: 'US',
    city: 'Early',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sac',
    latitude: 42.4483,
    longitude: -95.1726,
    postal_code: '50535'
  },
  '50536': {
    country: 'US',
    city: 'Emmetsburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Palo Alto',
    latitude: 43.1082,
    longitude: -94.6825,
    postal_code: '50536'
  },
  '50538': {
    country: 'US',
    city: 'Farnhamville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Calhoun',
    latitude: 42.2692,
    longitude: -94.4226,
    postal_code: '50538'
  },
  '50539': {
    country: 'US',
    city: 'Fenton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.242,
    longitude: -94.4044,
    postal_code: '50539'
  },
  '50540': {
    country: 'US',
    city: 'Fonda',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pocahontas',
    latitude: 42.6051,
    longitude: -94.8297,
    postal_code: '50540'
  },
  '50541': {
    country: 'US',
    city: 'Gilmore City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.7067,
    longitude: -94.4108,
    postal_code: '50541'
  },
  '50542': {
    country: 'US',
    city: 'Goldfield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wright',
    latitude: 42.7581,
    longitude: -93.9194,
    postal_code: '50542'
  },
  '50543': {
    country: 'US',
    city: 'Gowrie',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.2767,
    longitude: -94.2987,
    postal_code: '50543'
  },
  '50544': {
    country: 'US',
    city: 'Harcourt',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.2531,
    longitude: -94.1961,
    postal_code: '50544'
  },
  '50545': {
    country: 'US',
    city: 'Hardy',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.7975,
    longitude: -94.0799,
    postal_code: '50545'
  },
  '50546': {
    country: 'US',
    city: 'Havelock',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pocahontas',
    latitude: 42.8411,
    longitude: -94.7252,
    postal_code: '50546'
  },
  '50548': {
    country: 'US',
    city: 'Humboldt',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.7196,
    longitude: -94.2132,
    postal_code: '50548'
  },
  '50551': {
    country: 'US',
    city: 'Jolley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Calhoun',
    latitude: 42.4871,
    longitude: -94.757,
    postal_code: '50551'
  },
  '50552': {
    country: 'US',
    city: 'Knierim',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Calhoun',
    latitude: 42.4555,
    longitude: -94.4569,
    postal_code: '50552'
  },
  '50554': {
    country: 'US',
    city: 'Laurens',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pocahontas',
    latitude: 42.8409,
    longitude: -94.8508,
    postal_code: '50554'
  },
  '50556': {
    country: 'US',
    city: 'Ledyard',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.4346,
    longitude: -94.1503,
    postal_code: '50556'
  },
  '50557': {
    country: 'US',
    city: 'Lehigh',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.3526,
    longitude: -94.0342,
    postal_code: '50557'
  },
  '50558': {
    country: 'US',
    city: 'Livermore',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.8666,
    longitude: -94.1747,
    postal_code: '50558'
  },
  '50559': {
    country: 'US',
    city: 'Lone Rock',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.1666,
    longitude: -94.3586,
    postal_code: '50559'
  },
  '50560': {
    country: 'US',
    city: 'Lu Verne',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 42.9868,
    longitude: -94.0959,
    postal_code: '50560'
  },
  '50561': {
    country: 'US',
    city: 'Lytton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Calhoun',
    latitude: 42.423,
    longitude: -94.8597,
    postal_code: '50561'
  },
  '50562': {
    country: 'US',
    city: 'Mallard',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Palo Alto',
    latitude: 42.9417,
    longitude: -94.6746,
    postal_code: '50562'
  },
  '50563': {
    country: 'US',
    city: 'Manson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Calhoun',
    latitude: 42.5285,
    longitude: -94.5304,
    postal_code: '50563'
  },
  '50565': {
    country: 'US',
    city: 'Marathon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buena Vista',
    latitude: 42.8612,
    longitude: -94.9836,
    postal_code: '50565'
  },
  '50566': {
    country: 'US',
    city: 'Moorland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.434,
    longitude: -94.3199,
    postal_code: '50566'
  },
  '50567': {
    country: 'US',
    city: 'Nemaha',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sac',
    latitude: 42.518,
    longitude: -95.0939,
    postal_code: '50567'
  },
  '50568': {
    country: 'US',
    city: 'Newell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buena Vista',
    latitude: 42.6157,
    longitude: -94.9946,
    postal_code: '50568'
  },
  '50569': {
    country: 'US',
    city: 'Otho',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.4031,
    longitude: -94.1388,
    postal_code: '50569'
  },
  '50570': {
    country: 'US',
    city: 'Ottosen',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.8764,
    longitude: -94.3786,
    postal_code: '50570'
  },
  '50571': {
    country: 'US',
    city: 'Palmer',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pocahontas',
    latitude: 42.6419,
    longitude: -94.5432,
    postal_code: '50571'
  },
  '50573': {
    country: 'US',
    city: 'Plover',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pocahontas',
    latitude: 42.8783,
    longitude: -94.6205,
    postal_code: '50573'
  },
  '50574': {
    country: 'US',
    city: 'Pocahontas',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pocahontas',
    latitude: 42.7296,
    longitude: -94.6737,
    postal_code: '50574'
  },
  '50575': {
    country: 'US',
    city: 'Pomeroy',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Calhoun',
    latitude: 42.5511,
    longitude: -94.6839,
    postal_code: '50575'
  },
  '50576': {
    country: 'US',
    city: 'Rembrandt',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buena Vista',
    latitude: 42.826,
    longitude: -95.1652,
    postal_code: '50576'
  },
  '50577': {
    country: 'US',
    city: 'Renwick',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.8491,
    longitude: -94.0074,
    postal_code: '50577'
  },
  '50578': {
    country: 'US',
    city: 'Ringsted',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Emmet',
    latitude: 43.2977,
    longitude: -94.5291,
    postal_code: '50578'
  },
  '50579': {
    country: 'US',
    city: 'Rockwell City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Calhoun',
    latitude: 42.3961,
    longitude: -94.6292,
    postal_code: '50579'
  },
  '50581': {
    country: 'US',
    city: 'Rolfe',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pocahontas',
    latitude: 42.8082,
    longitude: -94.5166,
    postal_code: '50581'
  },
  '50582': {
    country: 'US',
    city: 'Rutland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.7635,
    longitude: -94.2719,
    postal_code: '50582'
  },
  '50583': {
    country: 'US',
    city: 'Sac City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sac',
    latitude: 42.4262,
    longitude: -94.9796,
    postal_code: '50583'
  },
  '50585': {
    country: 'US',
    city: 'Sioux Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buena Vista',
    latitude: 42.9065,
    longitude: -95.1388,
    postal_code: '50585'
  },
  '50586': {
    country: 'US',
    city: 'Somers',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Calhoun',
    latitude: 42.3561,
    longitude: -94.4464,
    postal_code: '50586'
  },
  '50588': {
    country: 'US',
    city: 'Storm Lake',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buena Vista',
    latitude: 42.6474,
    longitude: -95.1961,
    postal_code: '50588'
  },
  '50590': {
    country: 'US',
    city: 'Swea City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.4022,
    longitude: -94.3193,
    postal_code: '50590'
  },
  '50591': {
    country: 'US',
    city: 'Thor',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Humboldt',
    latitude: 42.6889,
    longitude: -94.039,
    postal_code: '50591'
  },
  '50592': {
    country: 'US',
    city: 'Truesdale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buena Vista',
    latitude: 42.7262,
    longitude: -95.1853,
    postal_code: '50592'
  },
  '50593': {
    country: 'US',
    city: 'Varina',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pocahontas',
    latitude: 42.6598,
    longitude: -94.8979,
    postal_code: '50593'
  },
  '50594': {
    country: 'US',
    city: 'Vincent',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Webster',
    latitude: 42.5623,
    longitude: -94.0335,
    postal_code: '50594'
  },
  '50595': {
    country: 'US',
    city: 'Webster City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hamilton',
    latitude: 42.4657,
    longitude: -93.8262,
    postal_code: '50595'
  },
  '50597': {
    country: 'US',
    city: 'West Bend',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Palo Alto',
    latitude: 42.9574,
    longitude: -94.4564,
    postal_code: '50597'
  },
  '50598': {
    country: 'US',
    city: 'Whittemore',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Kossuth',
    latitude: 43.0619,
    longitude: -94.4269,
    postal_code: '50598'
  },
  '50599': {
    country: 'US',
    city: 'Woolstock',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wright',
    latitude: 42.5844,
    longitude: -93.8216,
    postal_code: '50599'
  },
  '50601': {
    country: 'US',
    city: 'Ackley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.5527,
    longitude: -93.0608,
    postal_code: '50601'
  },
  '50602': {
    country: 'US',
    city: 'Allison',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.7615,
    longitude: -92.783,
    postal_code: '50602'
  },
  '50603': {
    country: 'US',
    city: 'Alta Vista',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Chickasaw',
    latitude: 43.1482,
    longitude: -92.4237,
    postal_code: '50603'
  },
  '50604': {
    country: 'US',
    city: 'Aplington',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.587,
    longitude: -92.8751,
    postal_code: '50604'
  },
  '50605': {
    country: 'US',
    city: 'Aredale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.8318,
    longitude: -93.021,
    postal_code: '50605'
  },
  '50606': {
    country: 'US',
    city: 'Arlington',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.7338,
    longitude: -91.6668,
    postal_code: '50606'
  },
  '50607': {
    country: 'US',
    city: 'Aurora',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.6203,
    longitude: -91.7335,
    postal_code: '50607'
  },
  '50608': {
    country: 'US',
    city: 'Austinville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.5824,
    longitude: -92.9599,
    postal_code: '50608'
  },
  '50609': {
    country: 'US',
    city: 'Beaman',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Grundy',
    latitude: 42.2367,
    longitude: -92.8162,
    postal_code: '50609'
  },
  '50611': {
    country: 'US',
    city: 'Bristow',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.7741,
    longitude: -92.8864,
    postal_code: '50611'
  },
  '50612': {
    country: 'US',
    city: 'Buckingham',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 42.2552,
    longitude: -92.4098,
    postal_code: '50612'
  },
  '50613': {
    country: 'US',
    city: 'Cedar Falls',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.5241,
    longitude: -92.4497,
    postal_code: '50613'
  },
  '50614': {
    country: 'US',
    city: 'Cedar Falls',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.4698,
    longitude: -92.3095,
    postal_code: '50614'
  },
  '50616': {
    country: 'US',
    city: 'Charles City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Floyd',
    latitude: 43.0683,
    longitude: -92.6761,
    postal_code: '50616'
  },
  '50619': {
    country: 'US',
    city: 'Clarksville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.8041,
    longitude: -92.6588,
    postal_code: '50619'
  },
  '50620': {
    country: 'US',
    city: 'Colwell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Floyd',
    latitude: 43.1584,
    longitude: -92.5905,
    postal_code: '50620'
  },
  '50621': {
    country: 'US',
    city: 'Conrad',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Grundy',
    latitude: 42.2342,
    longitude: -92.8865,
    postal_code: '50621'
  },
  '50622': {
    country: 'US',
    city: 'Denver',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Bremer',
    latitude: 42.6731,
    longitude: -92.3417,
    postal_code: '50622'
  },
  '50623': {
    country: 'US',
    city: 'Dewar',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.5253,
    longitude: -92.2207,
    postal_code: '50623'
  },
  '50624': {
    country: 'US',
    city: 'Dike',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Grundy',
    latitude: 42.473,
    longitude: -92.6122,
    postal_code: '50624'
  },
  '50625': {
    country: 'US',
    city: 'Dumont',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.7368,
    longitude: -92.9673,
    postal_code: '50625'
  },
  '50626': {
    country: 'US',
    city: 'Dunkerton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.578,
    longitude: -92.1588,
    postal_code: '50626'
  },
  '50627': {
    country: 'US',
    city: 'Eldora',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.3572,
    longitude: -93.1037,
    postal_code: '50627'
  },
  '50628': {
    country: 'US',
    city: 'Elma',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Howard',
    latitude: 43.2786,
    longitude: -92.3981,
    postal_code: '50628'
  },
  '50629': {
    country: 'US',
    city: 'Fairbank',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.6162,
    longitude: -92.0326,
    postal_code: '50629'
  },
  '50630': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Chickasaw',
    latitude: 42.9593,
    longitude: -92.1983,
    postal_code: '50630'
  },
  '50631': {
    country: 'US',
    city: 'Frederika',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Bremer',
    latitude: 42.8827,
    longitude: -92.3063,
    postal_code: '50631'
  },
  '50632': {
    country: 'US',
    city: 'Garwin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 42.0872,
    longitude: -92.6894,
    postal_code: '50632'
  },
  '50633': {
    country: 'US',
    city: 'Geneva',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Franklin',
    latitude: 42.6755,
    longitude: -93.1294,
    postal_code: '50633'
  },
  '50634': {
    country: 'US',
    city: 'Gilbertville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.4161,
    longitude: -92.2149,
    postal_code: '50634'
  },
  '50635': {
    country: 'US',
    city: 'Gladbrook',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 42.1774,
    longitude: -92.7141,
    postal_code: '50635'
  },
  '50636': {
    country: 'US',
    city: 'Greene',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.8958,
    longitude: -92.8024,
    postal_code: '50636'
  },
  '50638': {
    country: 'US',
    city: 'Grundy Center',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Grundy',
    latitude: 42.3672,
    longitude: -92.7773,
    postal_code: '50638'
  },
  '50641': {
    country: 'US',
    city: 'Hazleton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.6076,
    longitude: -91.9105,
    postal_code: '50641'
  },
  '50642': {
    country: 'US',
    city: 'Holland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Grundy',
    latitude: 42.4011,
    longitude: -92.7999,
    postal_code: '50642'
  },
  '50643': {
    country: 'US',
    city: 'Hudson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.3905,
    longitude: -92.4549,
    postal_code: '50643'
  },
  '50644': {
    country: 'US',
    city: 'Independence',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.4674,
    longitude: -91.8803,
    postal_code: '50644'
  },
  '50645': {
    country: 'US',
    city: 'Ionia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Chickasaw',
    latitude: 43.0403,
    longitude: -92.4865,
    postal_code: '50645'
  },
  '50647': {
    country: 'US',
    city: 'Janesville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Bremer',
    latitude: 42.6465,
    longitude: -92.4791,
    postal_code: '50647'
  },
  '50648': {
    country: 'US',
    city: 'Jesup',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.4823,
    longitude: -92.0456,
    postal_code: '50648'
  },
  '50649': {
    country: 'US',
    city: 'Kesley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.6626,
    longitude: -92.9112,
    postal_code: '50649'
  },
  '50650': {
    country: 'US',
    city: 'Lamont',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.5946,
    longitude: -91.6701,
    postal_code: '50650'
  },
  '50651': {
    country: 'US',
    city: 'La Porte City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.3416,
    longitude: -92.1862,
    postal_code: '50651'
  },
  '50652': {
    country: 'US',
    city: 'Lincoln',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 42.2643,
    longitude: -92.693,
    postal_code: '50652'
  },
  '50653': {
    country: 'US',
    city: 'Marble Rock',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Floyd',
    latitude: 42.9645,
    longitude: -92.8917,
    postal_code: '50653'
  },
  '50654': {
    country: 'US',
    city: 'Masonville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Delaware',
    latitude: 42.4794,
    longitude: -91.5913,
    postal_code: '50654'
  },
  '50655': {
    country: 'US',
    city: 'Maynard',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.7752,
    longitude: -91.8908,
    postal_code: '50655'
  },
  '50657': {
    country: 'US',
    city: 'Morrison',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Grundy',
    latitude: 42.3433,
    longitude: -92.6738,
    postal_code: '50657'
  },
  '50658': {
    country: 'US',
    city: 'Nashua',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Chickasaw',
    latitude: 42.9528,
    longitude: -92.5298,
    postal_code: '50658'
  },
  '50659': {
    country: 'US',
    city: 'New Hampton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Chickasaw',
    latitude: 43.056,
    longitude: -92.3131,
    postal_code: '50659'
  },
  '50660': {
    country: 'US',
    city: 'New Hartford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.5831,
    longitude: -92.6164,
    postal_code: '50660'
  },
  '50661': {
    country: 'US',
    city: 'North Washington',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Chickasaw',
    latitude: 43.1174,
    longitude: -92.4144,
    postal_code: '50661'
  },
  '50662': {
    country: 'US',
    city: 'Oelwein',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.6811,
    longitude: -91.9131,
    postal_code: '50662'
  },
  '50664': {
    country: 'US',
    city: 'Oran',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.7012,
    longitude: -92.0762,
    postal_code: '50664'
  },
  '50665': {
    country: 'US',
    city: 'Parkersburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.5714,
    longitude: -92.7688,
    postal_code: '50665'
  },
  '50666': {
    country: 'US',
    city: 'Plainfield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Bremer',
    latitude: 42.8503,
    longitude: -92.5116,
    postal_code: '50666'
  },
  '50667': {
    country: 'US',
    city: 'Raymond',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.4672,
    longitude: -92.2162,
    postal_code: '50667'
  },
  '50668': {
    country: 'US',
    city: 'Readlyn',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Bremer',
    latitude: 42.6929,
    longitude: -92.2154,
    postal_code: '50668'
  },
  '50669': {
    country: 'US',
    city: 'Reinbeck',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Grundy',
    latitude: 42.3131,
    longitude: -92.5948,
    postal_code: '50669'
  },
  '50670': {
    country: 'US',
    city: 'Shell Rock',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Butler',
    latitude: 42.7061,
    longitude: -92.5888,
    postal_code: '50670'
  },
  '50671': {
    country: 'US',
    city: 'Stanley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.6356,
    longitude: -91.8127,
    postal_code: '50671'
  },
  '50672': {
    country: 'US',
    city: 'Steamboat Rock',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Hardin',
    latitude: 42.4178,
    longitude: -93.0629,
    postal_code: '50672'
  },
  '50673': {
    country: 'US',
    city: 'Stout',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Grundy',
    latitude: 42.5264,
    longitude: -92.7119,
    postal_code: '50673'
  },
  '50674': {
    country: 'US',
    city: 'Sumner',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Bremer',
    latitude: 42.8419,
    longitude: -92.1186,
    postal_code: '50674'
  },
  '50675': {
    country: 'US',
    city: 'Traer',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 42.1847,
    longitude: -92.4827,
    postal_code: '50675'
  },
  '50676': {
    country: 'US',
    city: 'Tripoli',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Bremer',
    latitude: 42.8105,
    longitude: -92.2659,
    postal_code: '50676'
  },
  '50677': {
    country: 'US',
    city: 'Waverly',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Bremer',
    latitude: 42.7748,
    longitude: -92.404,
    postal_code: '50677'
  },
  '50680': {
    country: 'US',
    city: 'Wellsburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Grundy',
    latitude: 42.4494,
    longitude: -92.9398,
    postal_code: '50680'
  },
  '50681': {
    country: 'US',
    city: 'Westgate',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.7812,
    longitude: -92.0122,
    postal_code: '50681'
  },
  '50682': {
    country: 'US',
    city: 'Winthrop',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.4912,
    longitude: -91.7315,
    postal_code: '50682'
  },
  '50701': {
    country: 'US',
    city: 'Waterloo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.4778,
    longitude: -92.3661,
    postal_code: '50701'
  },
  '50702': {
    country: 'US',
    city: 'Waterloo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.4731,
    longitude: -92.3365,
    postal_code: '50702'
  },
  '50703': {
    country: 'US',
    city: 'Waterloo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.5149,
    longitude: -92.3269,
    postal_code: '50703'
  },
  '50704': {
    country: 'US',
    city: 'Waterloo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.4698,
    longitude: -92.3095,
    postal_code: '50704'
  },
  '50707': {
    country: 'US',
    city: 'Evansdale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Black Hawk',
    latitude: 42.4755,
    longitude: -92.2812,
    postal_code: '50707'
  },
  '50801': {
    country: 'US',
    city: 'Creston',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Union',
    latitude: 41.0499,
    longitude: -94.3471,
    postal_code: '50801'
  },
  '50830': {
    country: 'US',
    city: 'Afton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Union',
    latitude: 41.0401,
    longitude: -94.194,
    postal_code: '50830'
  },
  '50831': {
    country: 'US',
    city: 'Arispe',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Union',
    latitude: 40.9314,
    longitude: -94.2193,
    postal_code: '50831'
  },
  '50833': {
    country: 'US',
    city: 'Bedford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Taylor',
    latitude: 40.6683,
    longitude: -94.7324,
    postal_code: '50833'
  },
  '50835': {
    country: 'US',
    city: 'Benton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ringgold',
    latitude: 40.7082,
    longitude: -94.3689,
    postal_code: '50835'
  },
  '50836': {
    country: 'US',
    city: 'Blockton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Taylor',
    latitude: 40.6242,
    longitude: -94.5049,
    postal_code: '50836'
  },
  '50837': {
    country: 'US',
    city: 'Bridgewater',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Adair',
    latitude: 41.2589,
    longitude: -94.6669,
    postal_code: '50837'
  },
  '50839': {
    country: 'US',
    city: 'Carbon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Adams',
    latitude: 41.0487,
    longitude: -94.8231,
    postal_code: '50839'
  },
  '50840': {
    country: 'US',
    city: 'Clearfield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Taylor',
    latitude: 40.7935,
    longitude: -94.4944,
    postal_code: '50840'
  },
  '50841': {
    country: 'US',
    city: 'Corning',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Adams',
    latitude: 40.9871,
    longitude: -94.7358,
    postal_code: '50841'
  },
  '50842': {
    country: 'US',
    city: 'Cromwell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Union',
    latitude: 41.0398,
    longitude: -94.4616,
    postal_code: '50842'
  },
  '50843': {
    country: 'US',
    city: 'Cumberland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cass',
    latitude: 41.2632,
    longitude: -94.871,
    postal_code: '50843'
  },
  '50845': {
    country: 'US',
    city: 'Diagonal',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ringgold',
    latitude: 40.8226,
    longitude: -94.3552,
    postal_code: '50845'
  },
  '50846': {
    country: 'US',
    city: 'Fontanelle',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Adair',
    latitude: 41.2896,
    longitude: -94.5419,
    postal_code: '50846'
  },
  '50847': {
    country: 'US',
    city: 'Grant',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Montgomery',
    latitude: 41.1427,
    longitude: -94.9842,
    postal_code: '50847'
  },
  '50848': {
    country: 'US',
    city: 'Gravity',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Taylor',
    latitude: 40.7909,
    longitude: -94.7506,
    postal_code: '50848'
  },
  '50849': {
    country: 'US',
    city: 'Greenfield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Adair',
    latitude: 41.3133,
    longitude: -94.4408,
    postal_code: '50849'
  },
  '50851': {
    country: 'US',
    city: 'Lenox',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Taylor',
    latitude: 40.8841,
    longitude: -94.5612,
    postal_code: '50851'
  },
  '50853': {
    country: 'US',
    city: 'Massena',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cass',
    latitude: 41.2499,
    longitude: -94.7649,
    postal_code: '50853'
  },
  '50854': {
    country: 'US',
    city: 'Mount Ayr',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ringgold',
    latitude: 40.7142,
    longitude: -94.2321,
    postal_code: '50854'
  },
  '50857': {
    country: 'US',
    city: 'Nodaway',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Adams',
    latitude: 40.954,
    longitude: -94.8731,
    postal_code: '50857'
  },
  '50858': {
    country: 'US',
    city: 'Orient',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Adair',
    latitude: 41.2192,
    longitude: -94.3705,
    postal_code: '50858'
  },
  '50859': {
    country: 'US',
    city: 'Prescott',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Adams',
    latitude: 41.0577,
    longitude: -94.5942,
    postal_code: '50859'
  },
  '50860': {
    country: 'US',
    city: 'Redding',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ringgold',
    latitude: 40.6053,
    longitude: -94.3595,
    postal_code: '50860'
  },
  '50861': {
    country: 'US',
    city: 'Shannon City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Union',
    latitude: 40.9017,
    longitude: -94.2633,
    postal_code: '50861'
  },
  '50862': {
    country: 'US',
    city: 'Sharpsburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Taylor',
    latitude: 40.795,
    longitude: -94.6416,
    postal_code: '50862'
  },
  '50863': {
    country: 'US',
    city: 'Tingley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ringgold',
    latitude: 40.8516,
    longitude: -94.1908,
    postal_code: '50863'
  },
  '50864': {
    country: 'US',
    city: 'Villisca',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Montgomery',
    latitude: 40.9437,
    longitude: -94.9796,
    postal_code: '50864'
  },
  '50936': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50936'
  },
  '50940': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50940'
  },
  '50947': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50947'
  },
  '50950': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50950'
  },
  '50980': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50980'
  },
  '50981': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6727,
    longitude: -93.5722,
    postal_code: '50981'
  },
  '50982': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6006,
    longitude: -93.6089,
    postal_code: '50982'
  },
  '50983': {
    country: 'US',
    city: 'Des Moines',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Polk',
    latitude: 41.6006,
    longitude: -93.6089,
    postal_code: '50983'
  },
  '51001': {
    country: 'US',
    city: 'Akron',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Plymouth',
    latitude: 42.8354,
    longitude: -96.5225,
    postal_code: '51001'
  },
  '51002': {
    country: 'US',
    city: 'Alta',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buena Vista',
    latitude: 42.6771,
    longitude: -95.3129,
    postal_code: '51002'
  },
  '51003': {
    country: 'US',
    city: 'Alton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 42.9782,
    longitude: -96.0173,
    postal_code: '51003'
  },
  '51004': {
    country: 'US',
    city: 'Anthon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.387,
    longitude: -95.8948,
    postal_code: '51004'
  },
  '51005': {
    country: 'US',
    city: 'Aurelia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cherokee',
    latitude: 42.6912,
    longitude: -95.4381,
    postal_code: '51005'
  },
  '51006': {
    country: 'US',
    city: 'Battle Creek',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ida',
    latitude: 42.3327,
    longitude: -95.6045,
    postal_code: '51006'
  },
  '51007': {
    country: 'US',
    city: 'Bronson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.4292,
    longitude: -96.1986,
    postal_code: '51007'
  },
  '51008': {
    country: 'US',
    city: 'Brunsville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Plymouth',
    latitude: 42.8093,
    longitude: -96.2626,
    postal_code: '51008'
  },
  '51009': {
    country: 'US',
    city: 'Calumet',
    state: 'Iowa',
    state_short: 'IA',
    county: "O'Brien",
    latitude: 42.9444,
    longitude: -95.5515,
    postal_code: '51009'
  },
  '51010': {
    country: 'US',
    city: 'Castana',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monona',
    latitude: 42.0744,
    longitude: -95.9086,
    postal_code: '51010'
  },
  '51011': {
    country: 'US',
    city: 'Chatsworth',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 42.9163,
    longitude: -96.5145,
    postal_code: '51011'
  },
  '51012': {
    country: 'US',
    city: 'Cherokee',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cherokee',
    latitude: 42.7482,
    longitude: -95.5568,
    postal_code: '51012'
  },
  '51014': {
    country: 'US',
    city: 'Cleghorn',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cherokee',
    latitude: 42.8086,
    longitude: -95.7124,
    postal_code: '51014'
  },
  '51015': {
    country: 'US',
    city: 'Climbing Hill',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.345,
    longitude: -96.0875,
    postal_code: '51015'
  },
  '51016': {
    country: 'US',
    city: 'Correctionville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.4743,
    longitude: -95.7804,
    postal_code: '51016'
  },
  '51018': {
    country: 'US',
    city: 'Cushing',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.4642,
    longitude: -95.6775,
    postal_code: '51018'
  },
  '51019': {
    country: 'US',
    city: 'Danbury',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.2693,
    longitude: -95.7263,
    postal_code: '51019'
  },
  '51020': {
    country: 'US',
    city: 'Galva',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ida',
    latitude: 42.5105,
    longitude: -95.4298,
    postal_code: '51020'
  },
  '51022': {
    country: 'US',
    city: 'Granville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 42.9693,
    longitude: -95.8972,
    postal_code: '51022'
  },
  '51023': {
    country: 'US',
    city: 'Hawarden',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 43.0013,
    longitude: -96.473,
    postal_code: '51023'
  },
  '51024': {
    country: 'US',
    city: 'Hinton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Plymouth',
    latitude: 42.604,
    longitude: -96.305,
    postal_code: '51024'
  },
  '51025': {
    country: 'US',
    city: 'Holstein',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ida',
    latitude: 42.4948,
    longitude: -95.565,
    postal_code: '51025'
  },
  '51026': {
    country: 'US',
    city: 'Hornick',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.2897,
    longitude: -96.0796,
    postal_code: '51026'
  },
  '51027': {
    country: 'US',
    city: 'Ireton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 42.9666,
    longitude: -96.3232,
    postal_code: '51027'
  },
  '51028': {
    country: 'US',
    city: 'Kingsley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Plymouth',
    latitude: 42.6191,
    longitude: -95.9762,
    postal_code: '51028'
  },
  '51029': {
    country: 'US',
    city: 'Larrabee',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cherokee',
    latitude: 42.8608,
    longitude: -95.5453,
    postal_code: '51029'
  },
  '51030': {
    country: 'US',
    city: 'Lawton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.5047,
    longitude: -96.2189,
    postal_code: '51030'
  },
  '51031': {
    country: 'US',
    city: 'Le Mars',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Plymouth',
    latitude: 42.7962,
    longitude: -96.1704,
    postal_code: '51031'
  },
  '51033': {
    country: 'US',
    city: 'Linn Grove',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buena Vista',
    latitude: 42.8742,
    longitude: -95.2512,
    postal_code: '51033'
  },
  '51034': {
    country: 'US',
    city: 'Mapleton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monona',
    latitude: 42.1585,
    longitude: -95.7909,
    postal_code: '51034'
  },
  '51035': {
    country: 'US',
    city: 'Marcus',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cherokee',
    latitude: 42.82,
    longitude: -95.8034,
    postal_code: '51035'
  },
  '51036': {
    country: 'US',
    city: 'Maurice',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 42.9626,
    longitude: -96.1655,
    postal_code: '51036'
  },
  '51037': {
    country: 'US',
    city: 'Meriden',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cherokee',
    latitude: 42.7874,
    longitude: -95.6408,
    postal_code: '51037'
  },
  '51038': {
    country: 'US',
    city: 'Merrill',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Plymouth',
    latitude: 42.7047,
    longitude: -96.2283,
    postal_code: '51038'
  },
  '51039': {
    country: 'US',
    city: 'Moville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.4819,
    longitude: -96.0656,
    postal_code: '51039'
  },
  '51040': {
    country: 'US',
    city: 'Onawa',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monona',
    latitude: 42.037,
    longitude: -96.1072,
    postal_code: '51040'
  },
  '51041': {
    country: 'US',
    city: 'Orange City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 43.0185,
    longitude: -96.0565,
    postal_code: '51041'
  },
  '51044': {
    country: 'US',
    city: 'Oto',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.2646,
    longitude: -95.8616,
    postal_code: '51044'
  },
  '51045': {
    country: 'US',
    city: 'Oyens',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Plymouth',
    latitude: 42.8199,
    longitude: -96.0566,
    postal_code: '51045'
  },
  '51046': {
    country: 'US',
    city: 'Paullina',
    state: 'Iowa',
    state_short: 'IA',
    county: "O'Brien",
    latitude: 42.9703,
    longitude: -95.7083,
    postal_code: '51046'
  },
  '51047': {
    country: 'US',
    city: 'Peterson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clay',
    latitude: 42.9328,
    longitude: -95.3377,
    postal_code: '51047'
  },
  '51048': {
    country: 'US',
    city: 'Pierson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.5335,
    longitude: -95.8922,
    postal_code: '51048'
  },
  '51049': {
    country: 'US',
    city: 'Quimby',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cherokee',
    latitude: 42.6303,
    longitude: -95.642,
    postal_code: '51049'
  },
  '51050': {
    country: 'US',
    city: 'Remsen',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Plymouth',
    latitude: 42.8149,
    longitude: -95.9544,
    postal_code: '51050'
  },
  '51051': {
    country: 'US',
    city: 'Rodney',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monona',
    latitude: 42.198,
    longitude: -95.9769,
    postal_code: '51051'
  },
  '51052': {
    country: 'US',
    city: 'Salix',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.3306,
    longitude: -96.2879,
    postal_code: '51052'
  },
  '51053': {
    country: 'US',
    city: 'Schaller',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sac',
    latitude: 42.5055,
    longitude: -95.2846,
    postal_code: '51053'
  },
  '51054': {
    country: 'US',
    city: 'Sergeant Bluff',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.4019,
    longitude: -96.3534,
    postal_code: '51054'
  },
  '51055': {
    country: 'US',
    city: 'Sloan',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.2427,
    longitude: -96.2306,
    postal_code: '51055'
  },
  '51056': {
    country: 'US',
    city: 'Smithland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.2408,
    longitude: -95.9486,
    postal_code: '51056'
  },
  '51058': {
    country: 'US',
    city: 'Sutherland',
    state: 'Iowa',
    state_short: 'IA',
    county: "O'Brien",
    latitude: 42.9826,
    longitude: -95.4807,
    postal_code: '51058'
  },
  '51060': {
    country: 'US',
    city: 'Ute',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monona',
    latitude: 42.0581,
    longitude: -95.7125,
    postal_code: '51060'
  },
  '51061': {
    country: 'US',
    city: 'Washta',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cherokee',
    latitude: 42.5781,
    longitude: -95.7295,
    postal_code: '51061'
  },
  '51062': {
    country: 'US',
    city: 'Westfield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Plymouth',
    latitude: 42.6953,
    longitude: -96.492,
    postal_code: '51062'
  },
  '51063': {
    country: 'US',
    city: 'Whiting',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monona',
    latitude: 42.1522,
    longitude: -96.1822,
    postal_code: '51063'
  },
  '51101': {
    country: 'US',
    city: 'Sioux City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.4972,
    longitude: -96.4029,
    postal_code: '51101'
  },
  '51102': {
    country: 'US',
    city: 'Sioux City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.3684,
    longitude: -96.318,
    postal_code: '51102'
  },
  '51103': {
    country: 'US',
    city: 'Sioux City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.5068,
    longitude: -96.4295,
    postal_code: '51103'
  },
  '51104': {
    country: 'US',
    city: 'Sioux City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.5254,
    longitude: -96.4005,
    postal_code: '51104'
  },
  '51105': {
    country: 'US',
    city: 'Sioux City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.5032,
    longitude: -96.3829,
    postal_code: '51105'
  },
  '51106': {
    country: 'US',
    city: 'Sioux City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.4671,
    longitude: -96.3528,
    postal_code: '51106'
  },
  '51108': {
    country: 'US',
    city: 'Sioux City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.5469,
    longitude: -96.3617,
    postal_code: '51108'
  },
  '51109': {
    country: 'US',
    city: 'Sioux City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.5173,
    longitude: -96.4803,
    postal_code: '51109'
  },
  '51111': {
    country: 'US',
    city: 'Sioux City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Woodbury',
    latitude: 42.4089,
    longitude: -96.3713,
    postal_code: '51111'
  },
  '51201': {
    country: 'US',
    city: 'Sheldon',
    state: 'Iowa',
    state_short: 'IA',
    county: "O'Brien",
    latitude: 43.1808,
    longitude: -95.8401,
    postal_code: '51201'
  },
  '51230': {
    country: 'US',
    city: 'Alvord',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lyon',
    latitude: 43.3201,
    longitude: -96.291,
    postal_code: '51230'
  },
  '51231': {
    country: 'US',
    city: 'Archer',
    state: 'Iowa',
    state_short: 'IA',
    county: "O'Brien",
    latitude: 43.1179,
    longitude: -95.7417,
    postal_code: '51231'
  },
  '51232': {
    country: 'US',
    city: 'Ashton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Osceola',
    latitude: 43.3053,
    longitude: -95.7655,
    postal_code: '51232'
  },
  '51234': {
    country: 'US',
    city: 'Boyden',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 43.2034,
    longitude: -95.9823,
    postal_code: '51234'
  },
  '51235': {
    country: 'US',
    city: 'Doon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lyon',
    latitude: 43.2872,
    longitude: -96.202,
    postal_code: '51235'
  },
  '51237': {
    country: 'US',
    city: 'George',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lyon',
    latitude: 43.3439,
    longitude: -95.9894,
    postal_code: '51237'
  },
  '51238': {
    country: 'US',
    city: 'Hospers',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 43.0793,
    longitude: -95.9142,
    postal_code: '51238'
  },
  '51239': {
    country: 'US',
    city: 'Hull',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 43.1952,
    longitude: -96.1392,
    postal_code: '51239'
  },
  '51240': {
    country: 'US',
    city: 'Inwood',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lyon',
    latitude: 43.3047,
    longitude: -96.4363,
    postal_code: '51240'
  },
  '51241': {
    country: 'US',
    city: 'Larchwood',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lyon',
    latitude: 43.4387,
    longitude: -96.4252,
    postal_code: '51241'
  },
  '51242': {
    country: 'US',
    city: 'Lester',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lyon',
    latitude: 43.4443,
    longitude: -96.3361,
    postal_code: '51242'
  },
  '51243': {
    country: 'US',
    city: 'Little Rock',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lyon',
    latitude: 43.4496,
    longitude: -95.8926,
    postal_code: '51243'
  },
  '51244': {
    country: 'US',
    city: 'Matlock',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 43.2439,
    longitude: -95.9353,
    postal_code: '51244'
  },
  '51245': {
    country: 'US',
    city: 'Primghar',
    state: 'Iowa',
    state_short: 'IA',
    county: "O'Brien",
    latitude: 43.0759,
    longitude: -95.6533,
    postal_code: '51245'
  },
  '51246': {
    country: 'US',
    city: 'Rock Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lyon',
    latitude: 43.4272,
    longitude: -96.1781,
    postal_code: '51246'
  },
  '51247': {
    country: 'US',
    city: 'Rock Valley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 43.2014,
    longitude: -96.3103,
    postal_code: '51247'
  },
  '51248': {
    country: 'US',
    city: 'Sanborn',
    state: 'Iowa',
    state_short: 'IA',
    county: "O'Brien",
    latitude: 43.189,
    longitude: -95.6622,
    postal_code: '51248'
  },
  '51249': {
    country: 'US',
    city: 'Sibley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Osceola',
    latitude: 43.4069,
    longitude: -95.7424,
    postal_code: '51249'
  },
  '51250': {
    country: 'US',
    city: 'Sioux Center',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sioux',
    latitude: 43.0814,
    longitude: -96.18,
    postal_code: '51250'
  },
  '51301': {
    country: 'US',
    city: 'Spencer',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clay',
    latitude: 43.1451,
    longitude: -95.1457,
    postal_code: '51301'
  },
  '51331': {
    country: 'US',
    city: 'Arnolds Park',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dickinson',
    latitude: 43.3637,
    longitude: -95.1282,
    postal_code: '51331'
  },
  '51333': {
    country: 'US',
    city: 'Dickens',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clay',
    latitude: 43.1548,
    longitude: -94.9854,
    postal_code: '51333'
  },
  '51334': {
    country: 'US',
    city: 'Estherville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Emmet',
    latitude: 43.4017,
    longitude: -94.818,
    postal_code: '51334'
  },
  '51338': {
    country: 'US',
    city: 'Everly',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clay',
    latitude: 43.1609,
    longitude: -95.3228,
    postal_code: '51338'
  },
  '51340': {
    country: 'US',
    city: 'Fostoria',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clay',
    latitude: 43.0824,
    longitude: -95.1511,
    postal_code: '51340'
  },
  '51341': {
    country: 'US',
    city: 'Gillett Grove',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clay',
    latitude: 43.0157,
    longitude: -95.0431,
    postal_code: '51341'
  },
  '51342': {
    country: 'US',
    city: 'Graettinger',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Palo Alto',
    latitude: 43.2277,
    longitude: -94.7334,
    postal_code: '51342'
  },
  '51343': {
    country: 'US',
    city: 'Greenville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clay',
    latitude: 43.0333,
    longitude: -95.0582,
    postal_code: '51343'
  },
  '51345': {
    country: 'US',
    city: 'Harris',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Osceola',
    latitude: 43.4568,
    longitude: -95.4426,
    postal_code: '51345'
  },
  '51346': {
    country: 'US',
    city: 'Hartley',
    state: 'Iowa',
    state_short: 'IA',
    county: "O'Brien",
    latitude: 43.18,
    longitude: -95.4817,
    postal_code: '51346'
  },
  '51347': {
    country: 'US',
    city: 'Lake Park',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dickinson',
    latitude: 43.4452,
    longitude: -95.3216,
    postal_code: '51347'
  },
  '51350': {
    country: 'US',
    city: 'Melvin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Osceola',
    latitude: 43.2845,
    longitude: -95.593,
    postal_code: '51350'
  },
  '51351': {
    country: 'US',
    city: 'Milford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dickinson',
    latitude: 43.3196,
    longitude: -95.1615,
    postal_code: '51351'
  },
  '51354': {
    country: 'US',
    city: 'Ocheyedan',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Osceola',
    latitude: 43.391,
    longitude: -95.5204,
    postal_code: '51354'
  },
  '51355': {
    country: 'US',
    city: 'Okoboji',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dickinson',
    latitude: 43.3889,
    longitude: -95.1369,
    postal_code: '51355'
  },
  '51357': {
    country: 'US',
    city: 'Royal',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clay',
    latitude: 43.0488,
    longitude: -95.2679,
    postal_code: '51357'
  },
  '51358': {
    country: 'US',
    city: 'Ruthven',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Palo Alto',
    latitude: 43.1448,
    longitude: -94.8842,
    postal_code: '51358'
  },
  '51360': {
    country: 'US',
    city: 'Spirit Lake',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dickinson',
    latitude: 43.4262,
    longitude: -95.1123,
    postal_code: '51360'
  },
  '51363': {
    country: 'US',
    city: 'Superior',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dickinson',
    latitude: 43.4318,
    longitude: -94.9469,
    postal_code: '51363'
  },
  '51364': {
    country: 'US',
    city: 'Terril',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dickinson',
    latitude: 43.3241,
    longitude: -94.9737,
    postal_code: '51364'
  },
  '51365': {
    country: 'US',
    city: 'Wallingford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Emmet',
    latitude: 43.3189,
    longitude: -94.7964,
    postal_code: '51365'
  },
  '51366': {
    country: 'US',
    city: 'Webb',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clay',
    latitude: 42.9502,
    longitude: -94.9951,
    postal_code: '51366'
  },
  '51401': {
    country: 'US',
    city: 'Carroll',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 42.072,
    longitude: -94.8667,
    postal_code: '51401'
  },
  '51430': {
    country: 'US',
    city: 'Arcadia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 42.0573,
    longitude: -95.0371,
    postal_code: '51430'
  },
  '51431': {
    country: 'US',
    city: 'Arthur',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ida',
    latitude: 42.3363,
    longitude: -95.3675,
    postal_code: '51431'
  },
  '51432': {
    country: 'US',
    city: 'Aspinwall',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 41.9119,
    longitude: -95.1356,
    postal_code: '51432'
  },
  '51433': {
    country: 'US',
    city: 'Auburn',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sac',
    latitude: 42.2763,
    longitude: -94.8711,
    postal_code: '51433'
  },
  '51436': {
    country: 'US',
    city: 'Breda',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 42.1758,
    longitude: -95.0007,
    postal_code: '51436'
  },
  '51439': {
    country: 'US',
    city: 'Charter Oak',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 42.0707,
    longitude: -95.5992,
    postal_code: '51439'
  },
  '51440': {
    country: 'US',
    city: 'Dedham',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 41.9055,
    longitude: -94.8149,
    postal_code: '51440'
  },
  '51441': {
    country: 'US',
    city: 'Deloit',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 42.1238,
    longitude: -95.3045,
    postal_code: '51441'
  },
  '51442': {
    country: 'US',
    city: 'Denison',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 42.0196,
    longitude: -95.3636,
    postal_code: '51442'
  },
  '51443': {
    country: 'US',
    city: 'Glidden',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 42.0618,
    longitude: -94.7148,
    postal_code: '51443'
  },
  '51444': {
    country: 'US',
    city: 'Halbur',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 42.0048,
    longitude: -94.9731,
    postal_code: '51444'
  },
  '51445': {
    country: 'US',
    city: 'Ida Grove',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Ida',
    latitude: 42.34,
    longitude: -95.4645,
    postal_code: '51445'
  },
  '51446': {
    country: 'US',
    city: 'Irwin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Shelby',
    latitude: 41.7751,
    longitude: -95.1919,
    postal_code: '51446'
  },
  '51447': {
    country: 'US',
    city: 'Kirkman',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Shelby',
    latitude: 41.7308,
    longitude: -95.2671,
    postal_code: '51447'
  },
  '51448': {
    country: 'US',
    city: 'Kiron',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 42.1794,
    longitude: -95.3115,
    postal_code: '51448'
  },
  '51449': {
    country: 'US',
    city: 'Lake City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Calhoun',
    latitude: 42.2705,
    longitude: -94.7458,
    postal_code: '51449'
  },
  '51450': {
    country: 'US',
    city: 'Lake View',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sac',
    latitude: 42.3154,
    longitude: -95.0486,
    postal_code: '51450'
  },
  '51451': {
    country: 'US',
    city: 'Lanesboro',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 42.1845,
    longitude: -94.6917,
    postal_code: '51451'
  },
  '51452': {
    country: 'US',
    city: 'Lidderdale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 42.1254,
    longitude: -94.7754,
    postal_code: '51452'
  },
  '51453': {
    country: 'US',
    city: 'Lohrville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Calhoun',
    latitude: 42.2619,
    longitude: -94.5566,
    postal_code: '51453'
  },
  '51454': {
    country: 'US',
    city: 'Manilla',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 41.8922,
    longitude: -95.2391,
    postal_code: '51454'
  },
  '51455': {
    country: 'US',
    city: 'Manning',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 41.9072,
    longitude: -95.0556,
    postal_code: '51455'
  },
  '51458': {
    country: 'US',
    city: 'Odebolt',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sac',
    latitude: 42.3095,
    longitude: -95.2501,
    postal_code: '51458'
  },
  '51459': {
    country: 'US',
    city: 'Ralston',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 42.0414,
    longitude: -94.6321,
    postal_code: '51459'
  },
  '51460': {
    country: 'US',
    city: 'Ricketts',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 42.1304,
    longitude: -95.5746,
    postal_code: '51460'
  },
  '51461': {
    country: 'US',
    city: 'Schleswig',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 42.1646,
    longitude: -95.4443,
    postal_code: '51461'
  },
  '51462': {
    country: 'US',
    city: 'Scranton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Greene',
    latitude: 42.0124,
    longitude: -94.5518,
    postal_code: '51462'
  },
  '51463': {
    country: 'US',
    city: 'Templeton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Carroll',
    latitude: 41.9121,
    longitude: -94.932,
    postal_code: '51463'
  },
  '51465': {
    country: 'US',
    city: 'Vail',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 42.0338,
    longitude: -95.1778,
    postal_code: '51465'
  },
  '51466': {
    country: 'US',
    city: 'Wall Lake',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Sac',
    latitude: 42.2653,
    longitude: -95.0852,
    postal_code: '51466'
  },
  '51467': {
    country: 'US',
    city: 'Westside',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 42.1084,
    longitude: -95.1273,
    postal_code: '51467'
  },
  '51501': {
    country: 'US',
    city: 'Council Bluffs',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.2324,
    longitude: -95.8751,
    postal_code: '51501'
  },
  '51502': {
    country: 'US',
    city: 'Council Bluffs',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.2619,
    longitude: -95.8608,
    postal_code: '51502'
  },
  '51503': {
    country: 'US',
    city: 'Council Bluffs',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.2616,
    longitude: -95.8251,
    postal_code: '51503'
  },
  '51510': {
    country: 'US',
    city: 'Carter Lake',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.2925,
    longitude: -95.9141,
    postal_code: '51510'
  },
  '51520': {
    country: 'US',
    city: 'Arion',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 41.9504,
    longitude: -95.4623,
    postal_code: '51520'
  },
  '51521': {
    country: 'US',
    city: 'Avoca',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.4768,
    longitude: -95.3509,
    postal_code: '51521'
  },
  '51523': {
    country: 'US',
    city: 'Blencoe',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monona',
    latitude: 41.9237,
    longitude: -96.0827,
    postal_code: '51523'
  },
  '51525': {
    country: 'US',
    city: 'Carson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.2367,
    longitude: -95.4181,
    postal_code: '51525'
  },
  '51526': {
    country: 'US',
    city: 'Crescent',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.3644,
    longitude: -95.8276,
    postal_code: '51526'
  },
  '51527': {
    country: 'US',
    city: 'Defiance',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Shelby',
    latitude: 41.8053,
    longitude: -95.3537,
    postal_code: '51527'
  },
  '51528': {
    country: 'US',
    city: 'Dow City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Crawford',
    latitude: 41.9134,
    longitude: -95.4862,
    postal_code: '51528'
  },
  '51529': {
    country: 'US',
    city: 'Dunlap',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Harrison',
    latitude: 41.8603,
    longitude: -95.6257,
    postal_code: '51529'
  },
  '51530': {
    country: 'US',
    city: 'Earling',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Shelby',
    latitude: 41.7925,
    longitude: -95.421,
    postal_code: '51530'
  },
  '51531': {
    country: 'US',
    city: 'Elk Horn',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Shelby',
    latitude: 41.5829,
    longitude: -95.07,
    postal_code: '51531'
  },
  '51532': {
    country: 'US',
    city: 'Elliott',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Montgomery',
    latitude: 41.1306,
    longitude: -95.1602,
    postal_code: '51532'
  },
  '51533': {
    country: 'US',
    city: 'Emerson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mills',
    latitude: 41.0284,
    longitude: -95.4106,
    postal_code: '51533'
  },
  '51534': {
    country: 'US',
    city: 'Glenwood',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mills',
    latitude: 41.047,
    longitude: -95.7389,
    postal_code: '51534'
  },
  '51535': {
    country: 'US',
    city: 'Griswold',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cass',
    latitude: 41.235,
    longitude: -95.1375,
    postal_code: '51535'
  },
  '51536': {
    country: 'US',
    city: 'Hancock',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.3832,
    longitude: -95.3445,
    postal_code: '51536'
  },
  '51537': {
    country: 'US',
    city: 'Harlan',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Shelby',
    latitude: 41.6437,
    longitude: -95.3111,
    postal_code: '51537'
  },
  '51540': {
    country: 'US',
    city: 'Hastings',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mills',
    latitude: 41.0132,
    longitude: -95.5008,
    postal_code: '51540'
  },
  '51541': {
    country: 'US',
    city: 'Henderson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mills',
    latitude: 41.1254,
    longitude: -95.4586,
    postal_code: '51541'
  },
  '51542': {
    country: 'US',
    city: 'Honey Creek',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.463,
    longitude: -95.8802,
    postal_code: '51542'
  },
  '51543': {
    country: 'US',
    city: 'Kimballton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Audubon',
    latitude: 41.6352,
    longitude: -95.0553,
    postal_code: '51543'
  },
  '51544': {
    country: 'US',
    city: 'Lewis',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cass',
    latitude: 41.2963,
    longitude: -95.062,
    postal_code: '51544'
  },
  '51545': {
    country: 'US',
    city: 'Little Sioux',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Harrison',
    latitude: 41.8083,
    longitude: -96.0314,
    postal_code: '51545'
  },
  '51546': {
    country: 'US',
    city: 'Logan',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Harrison',
    latitude: 41.643,
    longitude: -95.7889,
    postal_code: '51546'
  },
  '51548': {
    country: 'US',
    city: 'Mc Clelland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.2932,
    longitude: -95.6813,
    postal_code: '51548'
  },
  '51549': {
    country: 'US',
    city: 'Macedonia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.1916,
    longitude: -95.4314,
    postal_code: '51549'
  },
  '51550': {
    country: 'US',
    city: 'Magnolia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Harrison',
    latitude: 41.6921,
    longitude: -95.8742,
    postal_code: '51550'
  },
  '51551': {
    country: 'US',
    city: 'Malvern',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mills',
    latitude: 41.0074,
    longitude: -95.584,
    postal_code: '51551'
  },
  '51552': {
    country: 'US',
    city: 'Marne',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cass',
    latitude: 41.4547,
    longitude: -95.0973,
    postal_code: '51552'
  },
  '51553': {
    country: 'US',
    city: 'Minden',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.4654,
    longitude: -95.5519,
    postal_code: '51553'
  },
  '51554': {
    country: 'US',
    city: 'Mineola',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mills',
    latitude: 41.1414,
    longitude: -95.7005,
    postal_code: '51554'
  },
  '51555': {
    country: 'US',
    city: 'Missouri Valley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Harrison',
    latitude: 41.5644,
    longitude: -95.8913,
    postal_code: '51555'
  },
  '51556': {
    country: 'US',
    city: 'Modale',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Harrison',
    latitude: 41.6371,
    longitude: -95.9852,
    postal_code: '51556'
  },
  '51557': {
    country: 'US',
    city: 'Mondamin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Harrison',
    latitude: 41.7089,
    longitude: -96.0072,
    postal_code: '51557'
  },
  '51558': {
    country: 'US',
    city: 'Moorhead',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monona',
    latitude: 41.9186,
    longitude: -95.829,
    postal_code: '51558'
  },
  '51559': {
    country: 'US',
    city: 'Neola',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.4564,
    longitude: -95.6794,
    postal_code: '51559'
  },
  '51560': {
    country: 'US',
    city: 'Oakland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.312,
    longitude: -95.379,
    postal_code: '51560'
  },
  '51561': {
    country: 'US',
    city: 'Pacific Junction',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mills',
    latitude: 41.0271,
    longitude: -95.8046,
    postal_code: '51561'
  },
  '51562': {
    country: 'US',
    city: 'Panama',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Shelby',
    latitude: 41.7288,
    longitude: -95.487,
    postal_code: '51562'
  },
  '51563': {
    country: 'US',
    city: 'Persia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Harrison',
    latitude: 41.5647,
    longitude: -95.5658,
    postal_code: '51563'
  },
  '51564': {
    country: 'US',
    city: 'Pisgah',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Harrison',
    latitude: 41.8214,
    longitude: -95.9192,
    postal_code: '51564'
  },
  '51565': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Shelby',
    latitude: 41.6484,
    longitude: -95.5108,
    postal_code: '51565'
  },
  '51566': {
    country: 'US',
    city: 'Red Oak',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Montgomery',
    latitude: 41.0079,
    longitude: -95.2269,
    postal_code: '51566'
  },
  '51570': {
    country: 'US',
    city: 'Shelby',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.5339,
    longitude: -95.4469,
    postal_code: '51570'
  },
  '51571': {
    country: 'US',
    city: 'Silver City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mills',
    latitude: 41.113,
    longitude: -95.6269,
    postal_code: '51571'
  },
  '51572': {
    country: 'US',
    city: 'Soldier',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monona',
    latitude: 41.9942,
    longitude: -95.7825,
    postal_code: '51572'
  },
  '51573': {
    country: 'US',
    city: 'Stanton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Montgomery',
    latitude: 40.9859,
    longitude: -95.0988,
    postal_code: '51573'
  },
  '51575': {
    country: 'US',
    city: 'Treynor',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.2307,
    longitude: -95.6086,
    postal_code: '51575'
  },
  '51576': {
    country: 'US',
    city: 'Underwood',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.3603,
    longitude: -95.627,
    postal_code: '51576'
  },
  '51577': {
    country: 'US',
    city: 'Walnut',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.4623,
    longitude: -95.218,
    postal_code: '51577'
  },
  '51578': {
    country: 'US',
    city: 'Westphalia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Shelby',
    latitude: 41.7199,
    longitude: -95.3952,
    postal_code: '51578'
  },
  '51579': {
    country: 'US',
    city: 'Woodbine',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Harrison',
    latitude: 41.7373,
    longitude: -95.7008,
    postal_code: '51579'
  },
  '51591': {
    country: 'US',
    city: 'Red Oak',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Montgomery',
    latitude: 41.0303,
    longitude: -95.1562,
    postal_code: '51591'
  },
  '51593': {
    country: 'US',
    city: 'Harlan',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Pottawattamie',
    latitude: 41.498,
    longitude: -95.3636,
    postal_code: '51593'
  },
  '51601': {
    country: 'US',
    city: 'Shenandoah',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.7581,
    longitude: -95.3648,
    postal_code: '51601'
  },
  '51603': {
    country: 'US',
    city: 'Shenandoah',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.7656,
    longitude: -95.3722,
    postal_code: '51603'
  },
  '51630': {
    country: 'US',
    city: 'Blanchard',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.6122,
    longitude: -95.2054,
    postal_code: '51630'
  },
  '51631': {
    country: 'US',
    city: 'Braddyville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.5991,
    longitude: -94.9956,
    postal_code: '51631'
  },
  '51632': {
    country: 'US',
    city: 'Clarinda',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.7491,
    longitude: -95.038,
    postal_code: '51632'
  },
  '51636': {
    country: 'US',
    city: 'Coin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.6686,
    longitude: -95.2217,
    postal_code: '51636'
  },
  '51637': {
    country: 'US',
    city: 'College Springs',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.6249,
    longitude: -95.1157,
    postal_code: '51637'
  },
  '51638': {
    country: 'US',
    city: 'Essex',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.8404,
    longitude: -95.2888,
    postal_code: '51638'
  },
  '51639': {
    country: 'US',
    city: 'Farragut',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fremont',
    latitude: 40.7215,
    longitude: -95.4776,
    postal_code: '51639'
  },
  '51640': {
    country: 'US',
    city: 'Hamburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fremont',
    latitude: 40.6149,
    longitude: -95.6258,
    postal_code: '51640'
  },
  '51645': {
    country: 'US',
    city: 'Imogene',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fremont',
    latitude: 40.8631,
    longitude: -95.4358,
    postal_code: '51645'
  },
  '51646': {
    country: 'US',
    city: 'New Market',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Taylor',
    latitude: 40.7422,
    longitude: -94.8917,
    postal_code: '51646'
  },
  '51647': {
    country: 'US',
    city: 'Northboro',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.6127,
    longitude: -95.3096,
    postal_code: '51647'
  },
  '51648': {
    country: 'US',
    city: 'Percival',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fremont',
    latitude: 40.7401,
    longitude: -95.8093,
    postal_code: '51648'
  },
  '51649': {
    country: 'US',
    city: 'Randolph',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fremont',
    latitude: 40.8704,
    longitude: -95.5624,
    postal_code: '51649'
  },
  '51650': {
    country: 'US',
    city: 'Riverton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fremont',
    latitude: 40.6629,
    longitude: -95.5773,
    postal_code: '51650'
  },
  '51651': {
    country: 'US',
    city: 'Shambaugh',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.6575,
    longitude: -95.025,
    postal_code: '51651'
  },
  '51652': {
    country: 'US',
    city: 'Sidney',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fremont',
    latitude: 40.7507,
    longitude: -95.6403,
    postal_code: '51652'
  },
  '51653': {
    country: 'US',
    city: 'Tabor',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fremont',
    latitude: 40.9004,
    longitude: -95.6729,
    postal_code: '51653'
  },
  '51654': {
    country: 'US',
    city: 'Thurman',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fremont',
    latitude: 40.8424,
    longitude: -95.7605,
    postal_code: '51654'
  },
  '51656': {
    country: 'US',
    city: 'Yorktown',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Page',
    latitude: 40.7374,
    longitude: -95.1562,
    postal_code: '51656'
  },
  '52001': {
    country: 'US',
    city: 'Dubuque',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.515,
    longitude: -90.6819,
    postal_code: '52001'
  },
  '52002': {
    country: 'US',
    city: 'Dubuque',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.5122,
    longitude: -90.7384,
    postal_code: '52002'
  },
  '52003': {
    country: 'US',
    city: 'Dubuque',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.4649,
    longitude: -90.6829,
    postal_code: '52003'
  },
  '52004': {
    country: 'US',
    city: 'Dubuque',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.5006,
    longitude: -90.6646,
    postal_code: '52004'
  },
  '52030': {
    country: 'US',
    city: 'Andrew',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.1536,
    longitude: -90.5924,
    postal_code: '52030'
  },
  '52031': {
    country: 'US',
    city: 'Bellevue',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.2582,
    longitude: -90.4356,
    postal_code: '52031'
  },
  '52032': {
    country: 'US',
    city: 'Bernard',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.3321,
    longitude: -90.863,
    postal_code: '52032'
  },
  '52033': {
    country: 'US',
    city: 'Cascade',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.287,
    longitude: -91.0144,
    postal_code: '52033'
  },
  '52035': {
    country: 'US',
    city: 'Colesburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Delaware',
    latitude: 42.6383,
    longitude: -91.1963,
    postal_code: '52035'
  },
  '52036': {
    country: 'US',
    city: 'Delaware',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Delaware',
    latitude: 42.4766,
    longitude: -91.3112,
    postal_code: '52036'
  },
  '52037': {
    country: 'US',
    city: 'Delmar',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.9961,
    longitude: -90.6087,
    postal_code: '52037'
  },
  '52038': {
    country: 'US',
    city: 'Dundee',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Delaware',
    latitude: 42.594,
    longitude: -91.5477,
    postal_code: '52038'
  },
  '52039': {
    country: 'US',
    city: 'Durango',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.5526,
    longitude: -90.8608,
    postal_code: '52039'
  },
  '52040': {
    country: 'US',
    city: 'Dyersville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.4833,
    longitude: -91.1183,
    postal_code: '52040'
  },
  '52041': {
    country: 'US',
    city: 'Earlville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Delaware',
    latitude: 42.5001,
    longitude: -91.2597,
    postal_code: '52041'
  },
  '52042': {
    country: 'US',
    city: 'Edgewood',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.6541,
    longitude: -91.395,
    postal_code: '52042'
  },
  '52043': {
    country: 'US',
    city: 'Elkader',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.8496,
    longitude: -91.4143,
    postal_code: '52043'
  },
  '52044': {
    country: 'US',
    city: 'Elkport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.7624,
    longitude: -91.3203,
    postal_code: '52044'
  },
  '52045': {
    country: 'US',
    city: 'Epworth',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.4439,
    longitude: -90.9313,
    postal_code: '52045'
  },
  '52046': {
    country: 'US',
    city: 'Farley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.4451,
    longitude: -91.0083,
    postal_code: '52046'
  },
  '52047': {
    country: 'US',
    city: 'Farmersburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.9522,
    longitude: -91.339,
    postal_code: '52047'
  },
  '52048': {
    country: 'US',
    city: 'Garber',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.7453,
    longitude: -91.2593,
    postal_code: '52048'
  },
  '52049': {
    country: 'US',
    city: 'Garnavillo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.8762,
    longitude: -91.2156,
    postal_code: '52049'
  },
  '52050': {
    country: 'US',
    city: 'Greeley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Delaware',
    latitude: 42.5939,
    longitude: -91.3233,
    postal_code: '52050'
  },
  '52052': {
    country: 'US',
    city: 'Guttenberg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.7594,
    longitude: -91.119,
    postal_code: '52052'
  },
  '52053': {
    country: 'US',
    city: 'Holy Cross',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.5808,
    longitude: -90.973,
    postal_code: '52053'
  },
  '52054': {
    country: 'US',
    city: 'La Motte',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.3044,
    longitude: -90.6177,
    postal_code: '52054'
  },
  '52056': {
    country: 'US',
    city: 'Luxemburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.5783,
    longitude: -91.0621,
    postal_code: '52056'
  },
  '52057': {
    country: 'US',
    city: 'Manchester',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Delaware',
    latitude: 42.4834,
    longitude: -91.449,
    postal_code: '52057'
  },
  '52060': {
    country: 'US',
    city: 'Maquoketa',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.0778,
    longitude: -90.6771,
    postal_code: '52060'
  },
  '52064': {
    country: 'US',
    city: 'Miles',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.1006,
    longitude: -90.3367,
    postal_code: '52064'
  },
  '52065': {
    country: 'US',
    city: 'New Vienna',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.5678,
    longitude: -91.0976,
    postal_code: '52065'
  },
  '52066': {
    country: 'US',
    city: 'North Buena Vista',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.6783,
    longitude: -90.9518,
    postal_code: '52066'
  },
  '52068': {
    country: 'US',
    city: 'Peosta',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.4435,
    longitude: -90.8094,
    postal_code: '52068'
  },
  '52069': {
    country: 'US',
    city: 'Preston',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.0539,
    longitude: -90.3954,
    postal_code: '52069'
  },
  '52070': {
    country: 'US',
    city: 'Sabula',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.0737,
    longitude: -90.1955,
    postal_code: '52070'
  },
  '52071': {
    country: 'US',
    city: 'Saint Donatus',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.3196,
    longitude: -90.4903,
    postal_code: '52071'
  },
  '52072': {
    country: 'US',
    city: 'Saint Olaf',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.922,
    longitude: -91.3778,
    postal_code: '52072'
  },
  '52073': {
    country: 'US',
    city: 'Sherrill',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.6171,
    longitude: -90.8115,
    postal_code: '52073'
  },
  '52074': {
    country: 'US',
    city: 'Spragueville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.0727,
    longitude: -90.473,
    postal_code: '52074'
  },
  '52075': {
    country: 'US',
    city: 'Springbrook',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.1674,
    longitude: -90.4846,
    postal_code: '52075'
  },
  '52076': {
    country: 'US',
    city: 'Strawberry Point',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.6832,
    longitude: -91.5409,
    postal_code: '52076'
  },
  '52077': {
    country: 'US',
    city: 'Volga',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 42.7904,
    longitude: -91.5429,
    postal_code: '52077'
  },
  '52078': {
    country: 'US',
    city: 'Worthington',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.393,
    longitude: -91.1069,
    postal_code: '52078'
  },
  '52079': {
    country: 'US',
    city: 'Zwingle',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.2775,
    longitude: -90.7507,
    postal_code: '52079'
  },
  '52099': {
    country: 'US',
    city: 'Dubuque',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Dubuque',
    latitude: 42.5006,
    longitude: -90.6646,
    postal_code: '52099'
  },
  '52101': {
    country: 'US',
    city: 'Decorah',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winneshiek',
    latitude: 43.3227,
    longitude: -91.7939,
    postal_code: '52101'
  },
  '52132': {
    country: 'US',
    city: 'Calmar',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winneshiek',
    latitude: 43.1974,
    longitude: -91.9126,
    postal_code: '52132'
  },
  '52133': {
    country: 'US',
    city: 'Castalia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winneshiek',
    latitude: 43.1361,
    longitude: -91.6643,
    postal_code: '52133'
  },
  '52134': {
    country: 'US',
    city: 'Chester',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Howard',
    latitude: 43.473,
    longitude: -92.4155,
    postal_code: '52134'
  },
  '52135': {
    country: 'US',
    city: 'Clermont',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 43.013,
    longitude: -91.6552,
    postal_code: '52135'
  },
  '52136': {
    country: 'US',
    city: 'Cresco',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Howard',
    latitude: 43.363,
    longitude: -92.126,
    postal_code: '52136'
  },
  '52140': {
    country: 'US',
    city: 'Dorchester',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Allamakee',
    latitude: 43.4421,
    longitude: -91.5076,
    postal_code: '52140'
  },
  '52141': {
    country: 'US',
    city: 'Elgin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.9548,
    longitude: -91.6454,
    postal_code: '52141'
  },
  '52142': {
    country: 'US',
    city: 'Fayette',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.8494,
    longitude: -91.8139,
    postal_code: '52142'
  },
  '52144': {
    country: 'US',
    city: 'Fort Atkinson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winneshiek',
    latitude: 43.1339,
    longitude: -91.9134,
    postal_code: '52144'
  },
  '52146': {
    country: 'US',
    city: 'Harpers Ferry',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Allamakee',
    latitude: 43.1698,
    longitude: -91.2188,
    postal_code: '52146'
  },
  '52147': {
    country: 'US',
    city: 'Hawkeye',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.9487,
    longitude: -91.9578,
    postal_code: '52147'
  },
  '52149': {
    country: 'US',
    city: 'Highlandville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winneshiek',
    latitude: 43.4638,
    longitude: -91.6899,
    postal_code: '52149'
  },
  '52151': {
    country: 'US',
    city: 'Lansing',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Allamakee',
    latitude: 43.3661,
    longitude: -91.266,
    postal_code: '52151'
  },
  '52154': {
    country: 'US',
    city: 'Lawler',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Chickasaw',
    latitude: 43.0932,
    longitude: -92.144,
    postal_code: '52154'
  },
  '52155': {
    country: 'US',
    city: 'Lime Springs',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Howard',
    latitude: 43.4556,
    longitude: -92.2733,
    postal_code: '52155'
  },
  '52156': {
    country: 'US',
    city: 'Luana',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 43.0493,
    longitude: -91.4583,
    postal_code: '52156'
  },
  '52157': {
    country: 'US',
    city: 'Mc Gregor',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 43.0237,
    longitude: -91.1887,
    postal_code: '52157'
  },
  '52158': {
    country: 'US',
    city: 'Marquette',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 43.036,
    longitude: -91.1931,
    postal_code: '52158'
  },
  '52159': {
    country: 'US',
    city: 'Monona',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clayton',
    latitude: 43.034,
    longitude: -91.3934,
    postal_code: '52159'
  },
  '52160': {
    country: 'US',
    city: 'New Albin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Allamakee',
    latitude: 43.4899,
    longitude: -91.2942,
    postal_code: '52160'
  },
  '52161': {
    country: 'US',
    city: 'Ossian',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winneshiek',
    latitude: 43.1379,
    longitude: -91.7734,
    postal_code: '52161'
  },
  '52162': {
    country: 'US',
    city: 'Postville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Allamakee',
    latitude: 43.0036,
    longitude: -91.5478,
    postal_code: '52162'
  },
  '52163': {
    country: 'US',
    city: 'Protivin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Howard',
    latitude: 43.217,
    longitude: -92.0927,
    postal_code: '52163'
  },
  '52164': {
    country: 'US',
    city: 'Randalia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.8626,
    longitude: -91.8846,
    postal_code: '52164'
  },
  '52165': {
    country: 'US',
    city: 'Ridgeway',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winneshiek',
    latitude: 43.2979,
    longitude: -92.0034,
    postal_code: '52165'
  },
  '52166': {
    country: 'US',
    city: 'Saint Lucas',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 43.0664,
    longitude: -91.9335,
    postal_code: '52166'
  },
  '52168': {
    country: 'US',
    city: 'Spillville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Winneshiek',
    latitude: 43.2032,
    longitude: -91.9441,
    postal_code: '52168'
  },
  '52169': {
    country: 'US',
    city: 'Wadena',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.855,
    longitude: -91.6639,
    postal_code: '52169'
  },
  '52170': {
    country: 'US',
    city: 'Waterville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Allamakee',
    latitude: 43.2078,
    longitude: -91.2976,
    postal_code: '52170'
  },
  '52171': {
    country: 'US',
    city: 'Waucoma',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 43.0433,
    longitude: -92.0302,
    postal_code: '52171'
  },
  '52172': {
    country: 'US',
    city: 'Waukon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Allamakee',
    latitude: 43.264,
    longitude: -91.48,
    postal_code: '52172'
  },
  '52175': {
    country: 'US',
    city: 'West Union',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Fayette',
    latitude: 42.9438,
    longitude: -91.8334,
    postal_code: '52175'
  },
  '52201': {
    country: 'US',
    city: 'Ainsworth',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Washington',
    latitude: 41.3202,
    longitude: -91.5472,
    postal_code: '52201'
  },
  '52202': {
    country: 'US',
    city: 'Alburnett',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.1583,
    longitude: -91.6392,
    postal_code: '52202'
  },
  '52203': {
    country: 'US',
    city: 'Amana',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.8313,
    longitude: -91.8855,
    postal_code: '52203'
  },
  '52204': {
    country: 'US',
    city: 'Amana',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.8,
    longitude: -91.8707,
    postal_code: '52204'
  },
  '52205': {
    country: 'US',
    city: 'Anamosa',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jones',
    latitude: 42.1078,
    longitude: -91.2851,
    postal_code: '52205'
  },
  '52206': {
    country: 'US',
    city: 'Atkins',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 41.9881,
    longitude: -91.876,
    postal_code: '52206'
  },
  '52207': {
    country: 'US',
    city: 'Baldwin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.0732,
    longitude: -90.82,
    postal_code: '52207'
  },
  '52208': {
    country: 'US',
    city: 'Belle Plaine',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 41.8992,
    longitude: -92.2574,
    postal_code: '52208'
  },
  '52209': {
    country: 'US',
    city: 'Blairstown',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 41.9063,
    longitude: -92.0829,
    postal_code: '52209'
  },
  '52210': {
    country: 'US',
    city: 'Brandon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.3309,
    longitude: -92.0059,
    postal_code: '52210'
  },
  '52211': {
    country: 'US',
    city: 'Brooklyn',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Poweshiek',
    latitude: 41.7321,
    longitude: -92.4285,
    postal_code: '52211'
  },
  '52212': {
    country: 'US',
    city: 'Center Junction',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jones',
    latitude: 42.1161,
    longitude: -91.0871,
    postal_code: '52212'
  },
  '52213': {
    country: 'US',
    city: 'Center Point',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.1898,
    longitude: -91.7758,
    postal_code: '52213'
  },
  '52214': {
    country: 'US',
    city: 'Central City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.1982,
    longitude: -91.4914,
    postal_code: '52214'
  },
  '52215': {
    country: 'US',
    city: 'Chelsea',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 41.9131,
    longitude: -92.4078,
    postal_code: '52215'
  },
  '52216': {
    country: 'US',
    city: 'Clarence',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cedar',
    latitude: 41.8896,
    longitude: -91.0605,
    postal_code: '52216'
  },
  '52217': {
    country: 'US',
    city: 'Clutier',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 42.0789,
    longitude: -92.3763,
    postal_code: '52217'
  },
  '52218': {
    country: 'US',
    city: 'Coggon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.2792,
    longitude: -91.5413,
    postal_code: '52218'
  },
  '52219': {
    country: 'US',
    city: 'Prairieburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.2383,
    longitude: -91.4224,
    postal_code: '52219'
  },
  '52220': {
    country: 'US',
    city: 'Conroy',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.7292,
    longitude: -91.9977,
    postal_code: '52220'
  },
  '52221': {
    country: 'US',
    city: 'Guernsey',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Poweshiek',
    latitude: 41.6378,
    longitude: -92.3479,
    postal_code: '52221'
  },
  '52222': {
    country: 'US',
    city: 'Deep River',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Poweshiek',
    latitude: 41.5717,
    longitude: -92.3645,
    postal_code: '52222'
  },
  '52223': {
    country: 'US',
    city: 'Delhi',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Delaware',
    latitude: 42.4297,
    longitude: -91.331,
    postal_code: '52223'
  },
  '52224': {
    country: 'US',
    city: 'Dysart',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 42.169,
    longitude: -92.3187,
    postal_code: '52224'
  },
  '52225': {
    country: 'US',
    city: 'Elberon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 42.0088,
    longitude: -92.3314,
    postal_code: '52225'
  },
  '52227': {
    country: 'US',
    city: 'Ely',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 41.8943,
    longitude: -91.5738,
    postal_code: '52227'
  },
  '52228': {
    country: 'US',
    city: 'Fairfax',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 41.9153,
    longitude: -91.7801,
    postal_code: '52228'
  },
  '52229': {
    country: 'US',
    city: 'Garrison',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 42.1588,
    longitude: -92.1644,
    postal_code: '52229'
  },
  '52231': {
    country: 'US',
    city: 'Harper',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.3728,
    longitude: -92.0388,
    postal_code: '52231'
  },
  '52232': {
    country: 'US',
    city: 'Hartwick',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Poweshiek',
    latitude: 41.808,
    longitude: -92.3538,
    postal_code: '52232'
  },
  '52233': {
    country: 'US',
    city: 'Hiawatha',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0425,
    longitude: -91.677,
    postal_code: '52233'
  },
  '52235': {
    country: 'US',
    city: 'Hills',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.5557,
    longitude: -91.5308,
    postal_code: '52235'
  },
  '52236': {
    country: 'US',
    city: 'Homestead',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.7339,
    longitude: -91.8795,
    postal_code: '52236'
  },
  '52237': {
    country: 'US',
    city: 'Hopkinton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Delaware',
    latitude: 42.3422,
    longitude: -91.2461,
    postal_code: '52237'
  },
  '52240': {
    country: 'US',
    city: 'Iowa City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.6355,
    longitude: -91.5016,
    postal_code: '52240'
  },
  '52241': {
    country: 'US',
    city: 'Coralville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.6937,
    longitude: -91.5906,
    postal_code: '52241'
  },
  '52242': {
    country: 'US',
    city: 'Iowa City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.6627,
    longitude: -91.5477,
    postal_code: '52242'
  },
  '52243': {
    country: 'US',
    city: 'Iowa City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.6611,
    longitude: -91.5302,
    postal_code: '52243'
  },
  '52244': {
    country: 'US',
    city: 'Iowa City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.5727,
    longitude: -91.6619,
    postal_code: '52244'
  },
  '52245': {
    country: 'US',
    city: 'Iowa City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.6649,
    longitude: -91.5151,
    postal_code: '52245'
  },
  '52246': {
    country: 'US',
    city: 'Iowa City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.6438,
    longitude: -91.5669,
    postal_code: '52246'
  },
  '52247': {
    country: 'US',
    city: 'Kalona',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Washington',
    latitude: 41.4823,
    longitude: -91.7057,
    postal_code: '52247'
  },
  '52248': {
    country: 'US',
    city: 'Keota',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.3504,
    longitude: -91.9641,
    postal_code: '52248'
  },
  '52249': {
    country: 'US',
    city: 'Keystone',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 42.0156,
    longitude: -92.2179,
    postal_code: '52249'
  },
  '52251': {
    country: 'US',
    city: 'Ladora',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.7564,
    longitude: -92.1873,
    postal_code: '52251'
  },
  '52252': {
    country: 'US',
    city: 'Langworthy',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jones',
    latitude: 42.192,
    longitude: -91.2258,
    postal_code: '52252'
  },
  '52253': {
    country: 'US',
    city: 'Lisbon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 41.9212,
    longitude: -91.3861,
    postal_code: '52253'
  },
  '52254': {
    country: 'US',
    city: 'Lost Nation',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.9745,
    longitude: -90.8265,
    postal_code: '52254'
  },
  '52255': {
    country: 'US',
    city: 'Lowden',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cedar',
    latitude: 41.8594,
    longitude: -90.9382,
    postal_code: '52255'
  },
  '52257': {
    country: 'US',
    city: 'Luzerne',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 41.9061,
    longitude: -92.1793,
    postal_code: '52257'
  },
  '52301': {
    country: 'US',
    city: 'Marengo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.7863,
    longitude: -92.072,
    postal_code: '52301'
  },
  '52302': {
    country: 'US',
    city: 'Marion',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0411,
    longitude: -91.5941,
    postal_code: '52302'
  },
  '52305': {
    country: 'US',
    city: 'Martelle',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jones',
    latitude: 42.0035,
    longitude: -91.3217,
    postal_code: '52305'
  },
  '52306': {
    country: 'US',
    city: 'Mechanicsville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cedar',
    latitude: 41.9039,
    longitude: -91.2764,
    postal_code: '52306'
  },
  '52307': {
    country: 'US',
    city: 'Middle Amana',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.7954,
    longitude: -91.9018,
    postal_code: '52307'
  },
  '52308': {
    country: 'US',
    city: 'Millersburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.5733,
    longitude: -92.1599,
    postal_code: '52308'
  },
  '52309': {
    country: 'US',
    city: 'Monmouth',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jackson',
    latitude: 42.0772,
    longitude: -90.8786,
    postal_code: '52309'
  },
  '52310': {
    country: 'US',
    city: 'Monticello',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jones',
    latitude: 42.2326,
    longitude: -91.1989,
    postal_code: '52310'
  },
  '52312': {
    country: 'US',
    city: 'Morley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jones',
    latitude: 42.006,
    longitude: -91.2459,
    postal_code: '52312'
  },
  '52313': {
    country: 'US',
    city: 'Mount Auburn',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 42.255,
    longitude: -92.0927,
    postal_code: '52313'
  },
  '52314': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 41.9287,
    longitude: -91.4274,
    postal_code: '52314'
  },
  '52315': {
    country: 'US',
    city: 'Newhall',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 41.9927,
    longitude: -91.9779,
    postal_code: '52315'
  },
  '52316': {
    country: 'US',
    city: 'North English',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.5241,
    longitude: -92.0893,
    postal_code: '52316'
  },
  '52317': {
    country: 'US',
    city: 'North Liberty',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.7443,
    longitude: -91.6061,
    postal_code: '52317'
  },
  '52318': {
    country: 'US',
    city: 'Norway',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 41.8992,
    longitude: -91.892,
    postal_code: '52318'
  },
  '52320': {
    country: 'US',
    city: 'Olin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jones',
    latitude: 41.9954,
    longitude: -91.1409,
    postal_code: '52320'
  },
  '52321': {
    country: 'US',
    city: 'Onslow',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jones',
    latitude: 42.107,
    longitude: -91.0151,
    postal_code: '52321'
  },
  '52322': {
    country: 'US',
    city: 'Oxford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.6507,
    longitude: -91.7724,
    postal_code: '52322'
  },
  '52323': {
    country: 'US',
    city: 'Oxford Junction',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jones',
    latitude: 41.9854,
    longitude: -90.9543,
    postal_code: '52323'
  },
  '52324': {
    country: 'US',
    city: 'Palo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0365,
    longitude: -91.7877,
    postal_code: '52324'
  },
  '52325': {
    country: 'US',
    city: 'Parnell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.5702,
    longitude: -92.0052,
    postal_code: '52325'
  },
  '52326': {
    country: 'US',
    city: 'Quasqueton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.3956,
    longitude: -91.7679,
    postal_code: '52326'
  },
  '52327': {
    country: 'US',
    city: 'Riverside',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Washington',
    latitude: 41.4758,
    longitude: -91.5741,
    postal_code: '52327'
  },
  '52328': {
    country: 'US',
    city: 'Robins',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0731,
    longitude: -91.6648,
    postal_code: '52328'
  },
  '52329': {
    country: 'US',
    city: 'Rowley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Buchanan',
    latitude: 42.37,
    longitude: -91.8441,
    postal_code: '52329'
  },
  '52330': {
    country: 'US',
    city: 'Ryan',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Delaware',
    latitude: 42.3436,
    longitude: -91.4848,
    postal_code: '52330'
  },
  '52332': {
    country: 'US',
    city: 'Shellsburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 42.0848,
    longitude: -91.8746,
    postal_code: '52332'
  },
  '52333': {
    country: 'US',
    city: 'Solon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.8099,
    longitude: -91.5086,
    postal_code: '52333'
  },
  '52334': {
    country: 'US',
    city: 'South Amana',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.7576,
    longitude: -91.9263,
    postal_code: '52334'
  },
  '52335': {
    country: 'US',
    city: 'South English',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.4529,
    longitude: -92.068,
    postal_code: '52335'
  },
  '52336': {
    country: 'US',
    city: 'Springville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0607,
    longitude: -91.4394,
    postal_code: '52336'
  },
  '52337': {
    country: 'US',
    city: 'Stanwood',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cedar',
    latitude: 41.8963,
    longitude: -91.1662,
    postal_code: '52337'
  },
  '52338': {
    country: 'US',
    city: 'Swisher',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.8268,
    longitude: -91.6739,
    postal_code: '52338'
  },
  '52339': {
    country: 'US',
    city: 'Tama',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 41.9616,
    longitude: -92.58,
    postal_code: '52339'
  },
  '52340': {
    country: 'US',
    city: 'Tiffin',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.7018,
    longitude: -91.6773,
    postal_code: '52340'
  },
  '52341': {
    country: 'US',
    city: 'Toddville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.1039,
    longitude: -91.729,
    postal_code: '52341'
  },
  '52342': {
    country: 'US',
    city: 'Toledo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 42.0077,
    longitude: -92.5662,
    postal_code: '52342'
  },
  '52344': {
    country: 'US',
    city: 'Troy Mills',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.29,
    longitude: -91.6824,
    postal_code: '52344'
  },
  '52345': {
    country: 'US',
    city: 'Urbana',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 42.2365,
    longitude: -91.8881,
    postal_code: '52345'
  },
  '52346': {
    country: 'US',
    city: 'Van Horne',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 42.0194,
    longitude: -92.1046,
    postal_code: '52346'
  },
  '52347': {
    country: 'US',
    city: 'Victor',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.729,
    longitude: -92.2842,
    postal_code: '52347'
  },
  '52348': {
    country: 'US',
    city: 'Vining',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Tama',
    latitude: 41.99,
    longitude: -92.3799,
    postal_code: '52348'
  },
  '52349': {
    country: 'US',
    city: 'Vinton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 42.1743,
    longitude: -91.9892,
    postal_code: '52349'
  },
  '52351': {
    country: 'US',
    city: 'Walford',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 41.877,
    longitude: -91.8369,
    postal_code: '52351'
  },
  '52352': {
    country: 'US',
    city: 'Walker',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.2867,
    longitude: -91.7807,
    postal_code: '52352'
  },
  '52353': {
    country: 'US',
    city: 'Washington',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Washington',
    latitude: 41.3015,
    longitude: -91.6987,
    postal_code: '52353'
  },
  '52354': {
    country: 'US',
    city: 'Watkins',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Benton',
    latitude: 41.904,
    longitude: -91.9965,
    postal_code: '52354'
  },
  '52355': {
    country: 'US',
    city: 'Webster',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.4364,
    longitude: -92.1765,
    postal_code: '52355'
  },
  '52356': {
    country: 'US',
    city: 'Wellman',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Washington',
    latitude: 41.47,
    longitude: -91.84,
    postal_code: '52356'
  },
  '52358': {
    country: 'US',
    city: 'West Branch',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cedar',
    latitude: 41.6726,
    longitude: -91.3141,
    postal_code: '52358'
  },
  '52359': {
    country: 'US',
    city: 'West Chester',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Washington',
    latitude: 41.3585,
    longitude: -91.8075,
    postal_code: '52359'
  },
  '52361': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Iowa',
    latitude: 41.6393,
    longitude: -92.024,
    postal_code: '52361'
  },
  '52362': {
    country: 'US',
    city: 'Wyoming',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jones',
    latitude: 42.0604,
    longitude: -90.994,
    postal_code: '52362'
  },
  '52401': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 41.9743,
    longitude: -91.6554,
    postal_code: '52401'
  },
  '52402': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0188,
    longitude: -91.6612,
    postal_code: '52402'
  },
  '52403': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 41.9843,
    longitude: -91.6259,
    postal_code: '52403'
  },
  '52404': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 41.9521,
    longitude: -91.6853,
    postal_code: '52404'
  },
  '52405': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 41.9804,
    longitude: -91.7098,
    postal_code: '52405'
  },
  '52406': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0083,
    longitude: -91.6441,
    postal_code: '52406'
  },
  '52407': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0794,
    longitude: -91.5992,
    postal_code: '52407'
  },
  '52408': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0794,
    longitude: -91.5992,
    postal_code: '52408'
  },
  '52409': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0794,
    longitude: -91.5992,
    postal_code: '52409'
  },
  '52410': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0794,
    longitude: -91.5992,
    postal_code: '52410'
  },
  '52411': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0493,
    longitude: -91.7263,
    postal_code: '52411'
  },
  '52497': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0794,
    longitude: -91.5992,
    postal_code: '52497'
  },
  '52498': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0794,
    longitude: -91.5992,
    postal_code: '52498'
  },
  '52499': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Linn',
    latitude: 42.0794,
    longitude: -91.5992,
    postal_code: '52499'
  },
  '52501': {
    country: 'US',
    city: 'Ottumwa',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wapello',
    latitude: 41.0309,
    longitude: -92.4098,
    postal_code: '52501'
  },
  '52530': {
    country: 'US',
    city: 'Agency',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wapello',
    latitude: 40.9957,
    longitude: -92.3162,
    postal_code: '52530'
  },
  '52531': {
    country: 'US',
    city: 'Albia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monroe',
    latitude: 41.0287,
    longitude: -92.7946,
    postal_code: '52531'
  },
  '52533': {
    country: 'US',
    city: 'Batavia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jefferson',
    latitude: 40.9905,
    longitude: -92.1431,
    postal_code: '52533'
  },
  '52534': {
    country: 'US',
    city: 'Beacon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mahaska',
    latitude: 41.272,
    longitude: -92.6819,
    postal_code: '52534'
  },
  '52535': {
    country: 'US',
    city: 'Birmingham',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Van Buren',
    latitude: 40.8626,
    longitude: -91.9534,
    postal_code: '52535'
  },
  '52536': {
    country: 'US',
    city: 'Blakesburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wapello',
    latitude: 40.9555,
    longitude: -92.6195,
    postal_code: '52536'
  },
  '52537': {
    country: 'US',
    city: 'Bloomfield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Davis',
    latitude: 40.7324,
    longitude: -92.3987,
    postal_code: '52537'
  },
  '52540': {
    country: 'US',
    city: 'Brighton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Washington',
    latitude: 41.1543,
    longitude: -91.8284,
    postal_code: '52540'
  },
  '52542': {
    country: 'US',
    city: 'Cantril',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Van Buren',
    latitude: 40.6546,
    longitude: -92.0465,
    postal_code: '52542'
  },
  '52543': {
    country: 'US',
    city: 'Cedar',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mahaska',
    latitude: 41.2121,
    longitude: -92.509,
    postal_code: '52543'
  },
  '52544': {
    country: 'US',
    city: 'Centerville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Appanoose',
    latitude: 40.7326,
    longitude: -92.8728,
    postal_code: '52544'
  },
  '52548': {
    country: 'US',
    city: 'Chillicothe',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wapello',
    latitude: 41.086,
    longitude: -92.5303,
    postal_code: '52548'
  },
  '52549': {
    country: 'US',
    city: 'Cincinnati',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Appanoose',
    latitude: 40.6344,
    longitude: -92.9219,
    postal_code: '52549'
  },
  '52550': {
    country: 'US',
    city: 'Delta',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.3167,
    longitude: -92.3359,
    postal_code: '52550'
  },
  '52551': {
    country: 'US',
    city: 'Douds',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Van Buren',
    latitude: 40.8378,
    longitude: -92.0859,
    postal_code: '52551'
  },
  '52552': {
    country: 'US',
    city: 'Drakesville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Davis',
    latitude: 40.8253,
    longitude: -92.5051,
    postal_code: '52552'
  },
  '52553': {
    country: 'US',
    city: 'Eddyville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wapello',
    latitude: 41.1533,
    longitude: -92.6221,
    postal_code: '52553'
  },
  '52554': {
    country: 'US',
    city: 'Eldon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wapello',
    latitude: 40.9261,
    longitude: -92.2268,
    postal_code: '52554'
  },
  '52555': {
    country: 'US',
    city: 'Exline',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Appanoose',
    latitude: 40.6414,
    longitude: -92.827,
    postal_code: '52555'
  },
  '52556': {
    country: 'US',
    city: 'Fairfield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jefferson',
    latitude: 41.0039,
    longitude: -91.9576,
    postal_code: '52556'
  },
  '52557': {
    country: 'US',
    city: 'Fairfield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jefferson',
    latitude: 41.0166,
    longitude: -91.9682,
    postal_code: '52557'
  },
  '52560': {
    country: 'US',
    city: 'Floris',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Davis',
    latitude: 40.8591,
    longitude: -92.3247,
    postal_code: '52560'
  },
  '52561': {
    country: 'US',
    city: 'Fremont',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mahaska',
    latitude: 41.2117,
    longitude: -92.4362,
    postal_code: '52561'
  },
  '52562': {
    country: 'US',
    city: 'Hayesville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.3363,
    longitude: -92.1784,
    postal_code: '52562'
  },
  '52563': {
    country: 'US',
    city: 'Hedrick',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.1826,
    longitude: -92.3097,
    postal_code: '52563'
  },
  '52565': {
    country: 'US',
    city: 'Keosauqua',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Van Buren',
    latitude: 40.7424,
    longitude: -91.9704,
    postal_code: '52565'
  },
  '52566': {
    country: 'US',
    city: 'Kirkville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wapello',
    latitude: 41.1438,
    longitude: -92.5006,
    postal_code: '52566'
  },
  '52567': {
    country: 'US',
    city: 'Libertyville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jefferson',
    latitude: 40.9499,
    longitude: -92.025,
    postal_code: '52567'
  },
  '52568': {
    country: 'US',
    city: 'Martinsburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.1948,
    longitude: -92.2715,
    postal_code: '52568'
  },
  '52569': {
    country: 'US',
    city: 'Melrose',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Monroe',
    latitude: 40.9665,
    longitude: -93.0145,
    postal_code: '52569'
  },
  '52570': {
    country: 'US',
    city: 'Milton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Van Buren',
    latitude: 40.6721,
    longitude: -92.1438,
    postal_code: '52570'
  },
  '52571': {
    country: 'US',
    city: 'Moravia',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Appanoose',
    latitude: 40.8825,
    longitude: -92.8534,
    postal_code: '52571'
  },
  '52572': {
    country: 'US',
    city: 'Moulton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Appanoose',
    latitude: 40.6866,
    longitude: -92.6833,
    postal_code: '52572'
  },
  '52573': {
    country: 'US',
    city: 'Mount Sterling',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Van Buren',
    latitude: 40.6458,
    longitude: -91.9027,
    postal_code: '52573'
  },
  '52574': {
    country: 'US',
    city: 'Mystic',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Appanoose',
    latitude: 40.7883,
    longitude: -92.9284,
    postal_code: '52574'
  },
  '52576': {
    country: 'US',
    city: 'Ollie',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.2001,
    longitude: -92.1354,
    postal_code: '52576'
  },
  '52577': {
    country: 'US',
    city: 'Oskaloosa',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mahaska',
    latitude: 41.2942,
    longitude: -92.6439,
    postal_code: '52577'
  },
  '52580': {
    country: 'US',
    city: 'Packwood',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jefferson',
    latitude: 41.1228,
    longitude: -92.0664,
    postal_code: '52580'
  },
  '52581': {
    country: 'US',
    city: 'Plano',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Appanoose',
    latitude: 40.7751,
    longitude: -93.0386,
    postal_code: '52581'
  },
  '52583': {
    country: 'US',
    city: 'Promise City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wayne',
    latitude: 40.7585,
    longitude: -93.1527,
    postal_code: '52583'
  },
  '52584': {
    country: 'US',
    city: 'Pulaski',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Davis',
    latitude: 40.6941,
    longitude: -92.258,
    postal_code: '52584'
  },
  '52585': {
    country: 'US',
    city: 'Richland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.1939,
    longitude: -91.9739,
    postal_code: '52585'
  },
  '52586': {
    country: 'US',
    city: 'Rose Hill',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mahaska',
    latitude: 41.3309,
    longitude: -92.472,
    postal_code: '52586'
  },
  '52588': {
    country: 'US',
    city: 'Selma',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Van Buren',
    latitude: 40.864,
    longitude: -92.1736,
    postal_code: '52588'
  },
  '52590': {
    country: 'US',
    city: 'Seymour',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Wayne',
    latitude: 40.6724,
    longitude: -93.1368,
    postal_code: '52590'
  },
  '52591': {
    country: 'US',
    city: 'Sigourney',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Keokuk',
    latitude: 41.3301,
    longitude: -92.2019,
    postal_code: '52591'
  },
  '52593': {
    country: 'US',
    city: 'Udell',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Appanoose',
    latitude: 40.7808,
    longitude: -92.7421,
    postal_code: '52593'
  },
  '52594': {
    country: 'US',
    city: 'Unionville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Appanoose',
    latitude: 40.8416,
    longitude: -92.6935,
    postal_code: '52594'
  },
  '52595': {
    country: 'US',
    city: 'University Park',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Mahaska',
    latitude: 41.2864,
    longitude: -92.6184,
    postal_code: '52595'
  },
  '52601': {
    country: 'US',
    city: 'Burlington',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Des Moines',
    latitude: 40.8087,
    longitude: -91.117,
    postal_code: '52601'
  },
  '52619': {
    country: 'US',
    city: 'Argyle',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.5317,
    longitude: -91.5663,
    postal_code: '52619'
  },
  '52620': {
    country: 'US',
    city: 'Bonaparte',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Van Buren',
    latitude: 40.7148,
    longitude: -91.7897,
    postal_code: '52620'
  },
  '52621': {
    country: 'US',
    city: 'Crawfordsville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Washington',
    latitude: 41.2093,
    longitude: -91.5414,
    postal_code: '52621'
  },
  '52623': {
    country: 'US',
    city: 'Danville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Des Moines',
    latitude: 40.854,
    longitude: -91.314,
    postal_code: '52623'
  },
  '52624': {
    country: 'US',
    city: 'Denmark',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.7417,
    longitude: -91.3374,
    postal_code: '52624'
  },
  '52625': {
    country: 'US',
    city: 'Donnellson',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.6612,
    longitude: -91.5745,
    postal_code: '52625'
  },
  '52626': {
    country: 'US',
    city: 'Farmington',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Van Buren',
    latitude: 40.6397,
    longitude: -91.7447,
    postal_code: '52626'
  },
  '52627': {
    country: 'US',
    city: 'Fort Madison',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.633,
    longitude: -91.3398,
    postal_code: '52627'
  },
  '52630': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Henry',
    latitude: 40.8372,
    longitude: -91.7119,
    postal_code: '52630'
  },
  '52631': {
    country: 'US',
    city: 'Houghton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.77,
    longitude: -91.6138,
    postal_code: '52631'
  },
  '52632': {
    country: 'US',
    city: 'Keokuk',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.4094,
    longitude: -91.3982,
    postal_code: '52632'
  },
  '52635': {
    country: 'US',
    city: 'Lockridge',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Jefferson',
    latitude: 41.0119,
    longitude: -91.7645,
    postal_code: '52635'
  },
  '52637': {
    country: 'US',
    city: 'Mediapolis',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Des Moines',
    latitude: 41.0059,
    longitude: -91.1321,
    postal_code: '52637'
  },
  '52638': {
    country: 'US',
    city: 'Middletown',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Des Moines',
    latitude: 40.8296,
    longitude: -91.2631,
    postal_code: '52638'
  },
  '52639': {
    country: 'US',
    city: 'Montrose',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.5139,
    longitude: -91.424,
    postal_code: '52639'
  },
  '52640': {
    country: 'US',
    city: 'Morning Sun',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Louisa',
    latitude: 41.0984,
    longitude: -91.2581,
    postal_code: '52640'
  },
  '52641': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Henry',
    latitude: 40.9646,
    longitude: -91.5614,
    postal_code: '52641'
  },
  '52642': {
    country: 'US',
    city: 'Rome',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Henry',
    latitude: 40.9774,
    longitude: -91.6923,
    postal_code: '52642'
  },
  '52644': {
    country: 'US',
    city: 'Mount Union',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Henry',
    latitude: 41.0374,
    longitude: -91.4138,
    postal_code: '52644'
  },
  '52645': {
    country: 'US',
    city: 'New London',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Henry',
    latitude: 40.9161,
    longitude: -91.3986,
    postal_code: '52645'
  },
  '52646': {
    country: 'US',
    city: 'Oakville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Louisa',
    latitude: 41.1003,
    longitude: -91.0439,
    postal_code: '52646'
  },
  '52647': {
    country: 'US',
    city: 'Olds',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Henry',
    latitude: 41.1342,
    longitude: -91.5449,
    postal_code: '52647'
  },
  '52648': {
    country: 'US',
    city: 'Pilot Grove',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.7628,
    longitude: -91.5368,
    postal_code: '52648'
  },
  '52649': {
    country: 'US',
    city: 'Salem',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Henry',
    latitude: 40.8568,
    longitude: -91.6336,
    postal_code: '52649'
  },
  '52650': {
    country: 'US',
    city: 'Sperry',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Des Moines',
    latitude: 40.9419,
    longitude: -91.185,
    postal_code: '52650'
  },
  '52651': {
    country: 'US',
    city: 'Stockport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Van Buren',
    latitude: 40.8584,
    longitude: -91.8141,
    postal_code: '52651'
  },
  '52652': {
    country: 'US',
    city: 'Swedesburg',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Henry',
    latitude: 41.1043,
    longitude: -91.547,
    postal_code: '52652'
  },
  '52653': {
    country: 'US',
    city: 'Wapello',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Louisa',
    latitude: 41.207,
    longitude: -91.1719,
    postal_code: '52653'
  },
  '52654': {
    country: 'US',
    city: 'Wayland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Henry',
    latitude: 41.1449,
    longitude: -91.6589,
    postal_code: '52654'
  },
  '52655': {
    country: 'US',
    city: 'West Burlington',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Des Moines',
    latitude: 40.8321,
    longitude: -91.1799,
    postal_code: '52655'
  },
  '52656': {
    country: 'US',
    city: 'West Point',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.715,
    longitude: -91.4397,
    postal_code: '52656'
  },
  '52657': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.7695,
    longitude: -91.5171,
    postal_code: '52657'
  },
  '52658': {
    country: 'US',
    city: 'Wever',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Lee',
    latitude: 40.7067,
    longitude: -91.2268,
    postal_code: '52658'
  },
  '52659': {
    country: 'US',
    city: 'Winfield',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Henry',
    latitude: 41.1257,
    longitude: -91.4379,
    postal_code: '52659'
  },
  '52660': {
    country: 'US',
    city: 'Yarmouth',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Des Moines',
    latitude: 40.9835,
    longitude: -91.3026,
    postal_code: '52660'
  },
  '52701': {
    country: 'US',
    city: 'Andover',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.9792,
    longitude: -90.2518,
    postal_code: '52701'
  },
  '52720': {
    country: 'US',
    city: 'Atalissa',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Muscatine',
    latitude: 41.5614,
    longitude: -91.1748,
    postal_code: '52720'
  },
  '52721': {
    country: 'US',
    city: 'Bennett',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cedar',
    latitude: 41.7353,
    longitude: -90.9656,
    postal_code: '52721'
  },
  '52722': {
    country: 'US',
    city: 'Bettendorf',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.5509,
    longitude: -90.4942,
    postal_code: '52722'
  },
  '52726': {
    country: 'US',
    city: 'Blue Grass',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.5112,
    longitude: -90.738,
    postal_code: '52726'
  },
  '52727': {
    country: 'US',
    city: 'Bryant',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.9629,
    longitude: -90.3388,
    postal_code: '52727'
  },
  '52728': {
    country: 'US',
    city: 'Buffalo',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.4564,
    longitude: -90.7235,
    postal_code: '52728'
  },
  '52729': {
    country: 'US',
    city: 'Calamus',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.8111,
    longitude: -90.7416,
    postal_code: '52729'
  },
  '52730': {
    country: 'US',
    city: 'Camanche',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.7886,
    longitude: -90.2709,
    postal_code: '52730'
  },
  '52731': {
    country: 'US',
    city: 'Charlotte',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.9779,
    longitude: -90.4782,
    postal_code: '52731'
  },
  '52732': {
    country: 'US',
    city: 'Clinton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.8517,
    longitude: -90.2078,
    postal_code: '52732'
  },
  '52733': {
    country: 'US',
    city: 'Clinton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.8445,
    longitude: -90.1887,
    postal_code: '52733'
  },
  '52734': {
    country: 'US',
    city: 'Clinton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.8444,
    longitude: -90.1885,
    postal_code: '52734'
  },
  '52736': {
    country: 'US',
    city: 'Clinton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.8445,
    longitude: -90.1887,
    postal_code: '52736'
  },
  '52737': {
    country: 'US',
    city: 'Columbus City',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Louisa',
    latitude: 41.2593,
    longitude: -91.3747,
    postal_code: '52737'
  },
  '52738': {
    country: 'US',
    city: 'Columbus Junction',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Louisa',
    latitude: 41.2926,
    longitude: -91.3006,
    postal_code: '52738'
  },
  '52739': {
    country: 'US',
    city: 'Conesville',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Muscatine',
    latitude: 41.3805,
    longitude: -91.3465,
    postal_code: '52739'
  },
  '52742': {
    country: 'US',
    city: 'De Witt',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.8267,
    longitude: -90.5237,
    postal_code: '52742'
  },
  '52745': {
    country: 'US',
    city: 'Dixon',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.7113,
    longitude: -90.7553,
    postal_code: '52745'
  },
  '52746': {
    country: 'US',
    city: 'Donahue',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.7093,
    longitude: -90.6829,
    postal_code: '52746'
  },
  '52747': {
    country: 'US',
    city: 'Durant',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cedar',
    latitude: 41.6145,
    longitude: -90.91,
    postal_code: '52747'
  },
  '52748': {
    country: 'US',
    city: 'Eldridge',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.6635,
    longitude: -90.5634,
    postal_code: '52748'
  },
  '52749': {
    country: 'US',
    city: 'Fruitland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Muscatine',
    latitude: 41.3486,
    longitude: -91.1237,
    postal_code: '52749'
  },
  '52750': {
    country: 'US',
    city: 'Goose Lake',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.9799,
    longitude: -90.3819,
    postal_code: '52750'
  },
  '52751': {
    country: 'US',
    city: 'Grand Mound',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.8501,
    longitude: -90.6695,
    postal_code: '52751'
  },
  '52752': {
    country: 'US',
    city: 'Grandview',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Louisa',
    latitude: 41.2759,
    longitude: -91.1885,
    postal_code: '52752'
  },
  '52753': {
    country: 'US',
    city: 'Le Claire',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.6082,
    longitude: -90.3628,
    postal_code: '52753'
  },
  '52754': {
    country: 'US',
    city: 'Letts',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Louisa',
    latitude: 41.293,
    longitude: -91.204,
    postal_code: '52754'
  },
  '52755': {
    country: 'US',
    city: 'Lone Tree',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Johnson',
    latitude: 41.4988,
    longitude: -91.4363,
    postal_code: '52755'
  },
  '52756': {
    country: 'US',
    city: 'Long Grove',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.7213,
    longitude: -90.5534,
    postal_code: '52756'
  },
  '52757': {
    country: 'US',
    city: 'Low Moor',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.8017,
    longitude: -90.3549,
    postal_code: '52757'
  },
  '52758': {
    country: 'US',
    city: 'Mc Causland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.743,
    longitude: -90.4539,
    postal_code: '52758'
  },
  '52759': {
    country: 'US',
    city: 'Montpelier',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Muscatine',
    latitude: 41.4625,
    longitude: -90.8088,
    postal_code: '52759'
  },
  '52760': {
    country: 'US',
    city: 'Moscow',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Muscatine',
    latitude: 41.5646,
    longitude: -91.0859,
    postal_code: '52760'
  },
  '52761': {
    country: 'US',
    city: 'Muscatine',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Muscatine',
    latitude: 41.4304,
    longitude: -91.0509,
    postal_code: '52761'
  },
  '52765': {
    country: 'US',
    city: 'New Liberty',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.7132,
    longitude: -90.8599,
    postal_code: '52765'
  },
  '52766': {
    country: 'US',
    city: 'Nichols',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Muscatine',
    latitude: 41.4766,
    longitude: -91.2931,
    postal_code: '52766'
  },
  '52767': {
    country: 'US',
    city: 'Pleasant Valley',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.5746,
    longitude: -90.4213,
    postal_code: '52767'
  },
  '52768': {
    country: 'US',
    city: 'Princeton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.6858,
    longitude: -90.3706,
    postal_code: '52768'
  },
  '52769': {
    country: 'US',
    city: 'Stockton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Muscatine',
    latitude: 41.5914,
    longitude: -90.8588,
    postal_code: '52769'
  },
  '52771': {
    country: 'US',
    city: 'Teeds Grove',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.9991,
    longitude: -90.2187,
    postal_code: '52771'
  },
  '52772': {
    country: 'US',
    city: 'Tipton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Cedar',
    latitude: 41.7563,
    longitude: -91.1362,
    postal_code: '52772'
  },
  '52773': {
    country: 'US',
    city: 'Walcott',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.6453,
    longitude: -90.7256,
    postal_code: '52773'
  },
  '52774': {
    country: 'US',
    city: 'Welton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.9081,
    longitude: -90.5954,
    postal_code: '52774'
  },
  '52776': {
    country: 'US',
    city: 'West Liberty',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Muscatine',
    latitude: 41.5701,
    longitude: -91.2668,
    postal_code: '52776'
  },
  '52777': {
    country: 'US',
    city: 'Wheatland',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Clinton',
    latitude: 41.8259,
    longitude: -90.8402,
    postal_code: '52777'
  },
  '52778': {
    country: 'US',
    city: 'Wilton',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Muscatine',
    latitude: 41.5858,
    longitude: -91.01,
    postal_code: '52778'
  },
  '52801': {
    country: 'US',
    city: 'Davenport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.5208,
    longitude: -90.5745,
    postal_code: '52801'
  },
  '52802': {
    country: 'US',
    city: 'Davenport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.5164,
    longitude: -90.6141,
    postal_code: '52802'
  },
  '52803': {
    country: 'US',
    city: 'Davenport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.5385,
    longitude: -90.5613,
    postal_code: '52803'
  },
  '52804': {
    country: 'US',
    city: 'Davenport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.5386,
    longitude: -90.6115,
    postal_code: '52804'
  },
  '52805': {
    country: 'US',
    city: 'Davenport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.613,
    longitude: -90.6063,
    postal_code: '52805'
  },
  '52806': {
    country: 'US',
    city: 'Davenport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.5733,
    longitude: -90.6038,
    postal_code: '52806'
  },
  '52807': {
    country: 'US',
    city: 'Davenport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.5618,
    longitude: -90.5403,
    postal_code: '52807'
  },
  '52808': {
    country: 'US',
    city: 'Davenport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.613,
    longitude: -90.6063,
    postal_code: '52808'
  },
  '52809': {
    country: 'US',
    city: 'Davenport',
    state: 'Iowa',
    state_short: 'IA',
    county: 'Scott',
    latitude: 41.613,
    longitude: -90.6063,
    postal_code: '52809'
  },
  '53001': {
    country: 'US',
    city: 'Adell',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.6151,
    longitude: -88.0254,
    postal_code: '53001'
  },
  '53002': {
    country: 'US',
    city: 'Allenton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.4681,
    longitude: -88.3539,
    postal_code: '53002'
  },
  '53003': {
    country: 'US',
    city: 'Ashippun',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.2119,
    longitude: -88.5162,
    postal_code: '53003'
  },
  '53004': {
    country: 'US',
    city: 'Belgium',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ozaukee',
    latitude: 43.4995,
    longitude: -87.8509,
    postal_code: '53004'
  },
  '53005': {
    country: 'US',
    city: 'Brookfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.0622,
    longitude: -88.098,
    postal_code: '53005'
  },
  '53006': {
    country: 'US',
    city: 'Brownsville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.6028,
    longitude: -88.523,
    postal_code: '53006'
  },
  '53007': {
    country: 'US',
    city: 'Butler',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.1054,
    longitude: -88.071,
    postal_code: '53007'
  },
  '53008': {
    country: 'US',
    city: 'Brookfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.0606,
    longitude: -88.1065,
    postal_code: '53008'
  },
  '53010': {
    country: 'US',
    city: 'Campbellsport',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.6042,
    longitude: -88.2729,
    postal_code: '53010'
  },
  '53011': {
    country: 'US',
    city: 'Cascade',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.6591,
    longitude: -88.0947,
    postal_code: '53011'
  },
  '53012': {
    country: 'US',
    city: 'Cedarburg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ozaukee',
    latitude: 43.3034,
    longitude: -88.0029,
    postal_code: '53012'
  },
  '53013': {
    country: 'US',
    city: 'Cedar Grove',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.5751,
    longitude: -87.8401,
    postal_code: '53013'
  },
  '53014': {
    country: 'US',
    city: 'Chilton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Calumet',
    latitude: 44.0242,
    longitude: -88.1827,
    postal_code: '53014'
  },
  '53015': {
    country: 'US',
    city: 'Cleveland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 43.9217,
    longitude: -87.7673,
    postal_code: '53015'
  },
  '53016': {
    country: 'US',
    city: 'Clyman',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.3107,
    longitude: -88.7144,
    postal_code: '53016'
  },
  '53017': {
    country: 'US',
    city: 'Colgate',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.1998,
    longitude: -88.2406,
    postal_code: '53017'
  },
  '53018': {
    country: 'US',
    city: 'Delafield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.05,
    longitude: -88.3972,
    postal_code: '53018'
  },
  '53019': {
    country: 'US',
    city: 'Eden',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.6959,
    longitude: -88.3266,
    postal_code: '53019'
  },
  '53020': {
    country: 'US',
    city: 'Elkhart Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.8436,
    longitude: -87.9767,
    postal_code: '53020'
  },
  '53021': {
    country: 'US',
    city: 'Fredonia',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ozaukee',
    latitude: 43.477,
    longitude: -87.9612,
    postal_code: '53021'
  },
  '53022': {
    country: 'US',
    city: 'Germantown',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.2189,
    longitude: -88.1165,
    postal_code: '53022'
  },
  '53023': {
    country: 'US',
    city: 'Glenbeulah',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.7666,
    longitude: -88.1049,
    postal_code: '53023'
  },
  '53024': {
    country: 'US',
    city: 'Grafton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ozaukee',
    latitude: 43.3231,
    longitude: -87.9521,
    postal_code: '53024'
  },
  '53026': {
    country: 'US',
    city: 'Greenbush',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.7767,
    longitude: -88.084,
    postal_code: '53026'
  },
  '53027': {
    country: 'US',
    city: 'Hartford',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.3157,
    longitude: -88.3707,
    postal_code: '53027'
  },
  '53029': {
    country: 'US',
    city: 'Hartland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.1172,
    longitude: -88.3446,
    postal_code: '53029'
  },
  '53031': {
    country: 'US',
    city: 'Hingham',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.6394,
    longitude: -87.9157,
    postal_code: '53031'
  },
  '53032': {
    country: 'US',
    city: 'Horicon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.4469,
    longitude: -88.631,
    postal_code: '53032'
  },
  '53033': {
    country: 'US',
    city: 'Hubertus',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.2343,
    longitude: -88.2311,
    postal_code: '53033'
  },
  '53034': {
    country: 'US',
    city: 'Hustisford',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.3305,
    longitude: -88.603,
    postal_code: '53034'
  },
  '53035': {
    country: 'US',
    city: 'Iron Ridge',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.3934,
    longitude: -88.5441,
    postal_code: '53035'
  },
  '53036': {
    country: 'US',
    city: 'Ixonia',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jefferson',
    latitude: 43.1477,
    longitude: -88.5886,
    postal_code: '53036'
  },
  '53037': {
    country: 'US',
    city: 'Jackson',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.3252,
    longitude: -88.1626,
    postal_code: '53037'
  },
  '53038': {
    country: 'US',
    city: 'Johnson Creek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jefferson',
    latitude: 43.0751,
    longitude: -88.7836,
    postal_code: '53038'
  },
  '53039': {
    country: 'US',
    city: 'Juneau',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.3792,
    longitude: -88.6845,
    postal_code: '53039'
  },
  '53040': {
    country: 'US',
    city: 'Kewaskum',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.5214,
    longitude: -88.1925,
    postal_code: '53040'
  },
  '53042': {
    country: 'US',
    city: 'Kiel',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 43.9341,
    longitude: -87.9956,
    postal_code: '53042'
  },
  '53044': {
    country: 'US',
    city: 'Kohler',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.7381,
    longitude: -87.7867,
    postal_code: '53044'
  },
  '53045': {
    country: 'US',
    city: 'Brookfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.0668,
    longitude: -88.1469,
    postal_code: '53045'
  },
  '53046': {
    country: 'US',
    city: 'Lannon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.1497,
    longitude: -88.1639,
    postal_code: '53046'
  },
  '53047': {
    country: 'US',
    city: 'Lebanon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.2568,
    longitude: -88.6281,
    postal_code: '53047'
  },
  '53048': {
    country: 'US',
    city: 'Lomira',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.5795,
    longitude: -88.4513,
    postal_code: '53048'
  },
  '53049': {
    country: 'US',
    city: 'Malone',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.8753,
    longitude: -88.2871,
    postal_code: '53049'
  },
  '53050': {
    country: 'US',
    city: 'Mayville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.5045,
    longitude: -88.5451,
    postal_code: '53050'
  },
  '53051': {
    country: 'US',
    city: 'Menomonee Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.1602,
    longitude: -88.1128,
    postal_code: '53051'
  },
  '53052': {
    country: 'US',
    city: 'Menomonee Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.1789,
    longitude: -88.1173,
    postal_code: '53052'
  },
  '53056': {
    country: 'US',
    city: 'Merton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.146,
    longitude: -88.3097,
    postal_code: '53056'
  },
  '53057': {
    country: 'US',
    city: 'Mount Calvary',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.8142,
    longitude: -88.2399,
    postal_code: '53057'
  },
  '53058': {
    country: 'US',
    city: 'Nashotah',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.1118,
    longitude: -88.4089,
    postal_code: '53058'
  },
  '53059': {
    country: 'US',
    city: 'Neosho',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.2978,
    longitude: -88.5205,
    postal_code: '53059'
  },
  '53060': {
    country: 'US',
    city: 'Newburg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.4338,
    longitude: -88.0623,
    postal_code: '53060'
  },
  '53061': {
    country: 'US',
    city: 'New Holstein',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Calumet',
    latitude: 43.9446,
    longitude: -88.0911,
    postal_code: '53061'
  },
  '53062': {
    country: 'US',
    city: 'New Holstein',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Calumet',
    latitude: 44.0679,
    longitude: -88.2231,
    postal_code: '53062'
  },
  '53063': {
    country: 'US',
    city: 'Newton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 43.9836,
    longitude: -87.7848,
    postal_code: '53063'
  },
  '53064': {
    country: 'US',
    city: 'North Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.1561,
    longitude: -88.3707,
    postal_code: '53064'
  },
  '53065': {
    country: 'US',
    city: 'Oakfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.6864,
    longitude: -88.5569,
    postal_code: '53065'
  },
  '53066': {
    country: 'US',
    city: 'Oconomowoc',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.1095,
    longitude: -88.4862,
    postal_code: '53066'
  },
  '53069': {
    country: 'US',
    city: 'Okauchee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.113,
    longitude: -88.4323,
    postal_code: '53069'
  },
  '53070': {
    country: 'US',
    city: 'Oostburg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.6229,
    longitude: -87.797,
    postal_code: '53070'
  },
  '53072': {
    country: 'US',
    city: 'Pewaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.0788,
    longitude: -88.2729,
    postal_code: '53072'
  },
  '53073': {
    country: 'US',
    city: 'Plymouth',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.7526,
    longitude: -87.9779,
    postal_code: '53073'
  },
  '53074': {
    country: 'US',
    city: 'Port Washington',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ozaukee',
    latitude: 43.3955,
    longitude: -87.8797,
    postal_code: '53074'
  },
  '53075': {
    country: 'US',
    city: 'Random Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.5672,
    longitude: -87.9816,
    postal_code: '53075'
  },
  '53076': {
    country: 'US',
    city: 'Richfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.2739,
    longitude: -88.2155,
    postal_code: '53076'
  },
  '53078': {
    country: 'US',
    city: 'Rubicon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.3121,
    longitude: -88.4528,
    postal_code: '53078'
  },
  '53079': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.8074,
    longitude: -88.1845,
    postal_code: '53079'
  },
  '53080': {
    country: 'US',
    city: 'Saukville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ozaukee',
    latitude: 43.3913,
    longitude: -87.9568,
    postal_code: '53080'
  },
  '53081': {
    country: 'US',
    city: 'Sheboygan',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.741,
    longitude: -87.7247,
    postal_code: '53081'
  },
  '53082': {
    country: 'US',
    city: 'Sheboygan',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.7183,
    longitude: -87.6187,
    postal_code: '53082'
  },
  '53083': {
    country: 'US',
    city: 'Sheboygan',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.8176,
    longitude: -87.766,
    postal_code: '53083'
  },
  '53085': {
    country: 'US',
    city: 'Sheboygan Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.7266,
    longitude: -87.8242,
    postal_code: '53085'
  },
  '53086': {
    country: 'US',
    city: 'Slinger',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.3322,
    longitude: -88.2824,
    postal_code: '53086'
  },
  '53088': {
    country: 'US',
    city: 'Stockbridge',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Calumet',
    latitude: 44.0717,
    longitude: -88.299,
    postal_code: '53088'
  },
  '53089': {
    country: 'US',
    city: 'Sussex',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.1441,
    longitude: -88.2271,
    postal_code: '53089'
  },
  '53090': {
    country: 'US',
    city: 'West Bend',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.4438,
    longitude: -88.1963,
    postal_code: '53090'
  },
  '53091': {
    country: 'US',
    city: 'Theresa',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.5045,
    longitude: -88.4478,
    postal_code: '53091'
  },
  '53092': {
    country: 'US',
    city: 'Mequon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ozaukee',
    latitude: 43.2364,
    longitude: -87.9845,
    postal_code: '53092'
  },
  '53093': {
    country: 'US',
    city: 'Waldo',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sheboygan',
    latitude: 43.6571,
    longitude: -87.9722,
    postal_code: '53093'
  },
  '53094': {
    country: 'US',
    city: 'Watertown',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jefferson',
    latitude: 43.193,
    longitude: -88.7185,
    postal_code: '53094'
  },
  '53095': {
    country: 'US',
    city: 'West Bend',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washington',
    latitude: 43.4224,
    longitude: -88.1845,
    postal_code: '53095'
  },
  '53097': {
    country: 'US',
    city: 'Mequon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ozaukee',
    latitude: 43.2461,
    longitude: -88.0046,
    postal_code: '53097'
  },
  '53098': {
    country: 'US',
    city: 'Watertown',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.2764,
    longitude: -88.7154,
    postal_code: '53098'
  },
  '53099': {
    country: 'US',
    city: 'Woodland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.3703,
    longitude: -88.5187,
    postal_code: '53099'
  },
  '53101': {
    country: 'US',
    city: 'Bassett',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5406,
    longitude: -88.2279,
    postal_code: '53101'
  },
  '53102': {
    country: 'US',
    city: 'Benet Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5001,
    longitude: -88.08,
    postal_code: '53102'
  },
  '53103': {
    country: 'US',
    city: 'Big Bend',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 42.8885,
    longitude: -88.2122,
    postal_code: '53103'
  },
  '53104': {
    country: 'US',
    city: 'Bristol',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5325,
    longitude: -88.0478,
    postal_code: '53104'
  },
  '53105': {
    country: 'US',
    city: 'Burlington',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.666,
    longitude: -88.2749,
    postal_code: '53105'
  },
  '53108': {
    country: 'US',
    city: 'Caledonia',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.8295,
    longitude: -87.9228,
    postal_code: '53108'
  },
  '53109': {
    country: 'US',
    city: 'Camp Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.536,
    longitude: -88.1444,
    postal_code: '53109'
  },
  '53110': {
    country: 'US',
    city: 'Cudahy',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.949,
    longitude: -87.862,
    postal_code: '53110'
  },
  '53114': {
    country: 'US',
    city: 'Darien',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.5973,
    longitude: -88.7508,
    postal_code: '53114'
  },
  '53115': {
    country: 'US',
    city: 'Delavan',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.6227,
    longitude: -88.6277,
    postal_code: '53115'
  },
  '53118': {
    country: 'US',
    city: 'Dousman',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.0142,
    longitude: -88.4726,
    postal_code: '53118'
  },
  '53119': {
    country: 'US',
    city: 'Eagle',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 42.8809,
    longitude: -88.4674,
    postal_code: '53119'
  },
  '53120': {
    country: 'US',
    city: 'East Troy',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.8035,
    longitude: -88.4092,
    postal_code: '53120'
  },
  '53121': {
    country: 'US',
    city: 'Elkhorn',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.7009,
    longitude: -88.5462,
    postal_code: '53121'
  },
  '53122': {
    country: 'US',
    city: 'Elm Grove',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.0479,
    longitude: -88.0854,
    postal_code: '53122'
  },
  '53125': {
    country: 'US',
    city: 'Fontana',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.5429,
    longitude: -88.5684,
    postal_code: '53125'
  },
  '53126': {
    country: 'US',
    city: 'Franksville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7859,
    longitude: -87.9873,
    postal_code: '53126'
  },
  '53127': {
    country: 'US',
    city: 'Genesee Depot',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 42.9601,
    longitude: -88.3745,
    postal_code: '53127'
  },
  '53128': {
    country: 'US',
    city: 'Genoa City',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.5323,
    longitude: -88.3485,
    postal_code: '53128'
  },
  '53129': {
    country: 'US',
    city: 'Greendale',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.9373,
    longitude: -87.9943,
    postal_code: '53129'
  },
  '53130': {
    country: 'US',
    city: 'Hales Corners',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.941,
    longitude: -88.0503,
    postal_code: '53130'
  },
  '53132': {
    country: 'US',
    city: 'Franklin',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.9017,
    longitude: -88.0086,
    postal_code: '53132'
  },
  '53137': {
    country: 'US',
    city: 'Helenville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jefferson',
    latitude: 43.009,
    longitude: -88.6726,
    postal_code: '53137'
  },
  '53138': {
    country: 'US',
    city: 'Honey Creek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.7483,
    longitude: -88.3079,
    postal_code: '53138'
  },
  '53139': {
    country: 'US',
    city: 'Kansasville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7012,
    longitude: -88.118,
    postal_code: '53139'
  },
  '53140': {
    country: 'US',
    city: 'Kenosha',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.6052,
    longitude: -87.8299,
    postal_code: '53140'
  },
  '53141': {
    country: 'US',
    city: 'Kenosha',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5847,
    longitude: -87.8212,
    postal_code: '53141'
  },
  '53142': {
    country: 'US',
    city: 'Kenosha',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.556,
    longitude: -87.8705,
    postal_code: '53142'
  },
  '53143': {
    country: 'US',
    city: 'Kenosha',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5617,
    longitude: -87.8301,
    postal_code: '53143'
  },
  '53144': {
    country: 'US',
    city: 'Kenosha',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.6058,
    longitude: -87.8762,
    postal_code: '53144'
  },
  '53146': {
    country: 'US',
    city: 'New Berlin',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 42.974,
    longitude: -88.1553,
    postal_code: '53146'
  },
  '53147': {
    country: 'US',
    city: 'Lake Geneva',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.5881,
    longitude: -88.4554,
    postal_code: '53147'
  },
  '53148': {
    country: 'US',
    city: 'Lyons',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.6487,
    longitude: -88.359,
    postal_code: '53148'
  },
  '53149': {
    country: 'US',
    city: 'Mukwonago',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 42.8821,
    longitude: -88.3451,
    postal_code: '53149'
  },
  '53150': {
    country: 'US',
    city: 'Muskego',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 42.9047,
    longitude: -88.1214,
    postal_code: '53150'
  },
  '53151': {
    country: 'US',
    city: 'New Berlin',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 42.9822,
    longitude: -88.0946,
    postal_code: '53151'
  },
  '53152': {
    country: 'US',
    city: 'New Munster',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5746,
    longitude: -88.2326,
    postal_code: '53152'
  },
  '53153': {
    country: 'US',
    city: 'North Prairie',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 42.9385,
    longitude: -88.395,
    postal_code: '53153'
  },
  '53154': {
    country: 'US',
    city: 'Oak Creek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.8892,
    longitude: -87.9027,
    postal_code: '53154'
  },
  '53156': {
    country: 'US',
    city: 'Palmyra',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jefferson',
    latitude: 42.8793,
    longitude: -88.5903,
    postal_code: '53156'
  },
  '53157': {
    country: 'US',
    city: 'Pell Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.54,
    longitude: -88.3582,
    postal_code: '53157'
  },
  '53158': {
    country: 'US',
    city: 'Pleasant Prairie',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5293,
    longitude: -87.8855,
    postal_code: '53158'
  },
  '53159': {
    country: 'US',
    city: 'Powers Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5557,
    longitude: -88.2969,
    postal_code: '53159'
  },
  '53167': {
    country: 'US',
    city: 'Rochester',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7414,
    longitude: -88.2243,
    postal_code: '53167'
  },
  '53168': {
    country: 'US',
    city: 'Salem',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5709,
    longitude: -88.1287,
    postal_code: '53168'
  },
  '53170': {
    country: 'US',
    city: 'Silver Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.552,
    longitude: -88.1608,
    postal_code: '53170'
  },
  '53171': {
    country: 'US',
    city: 'Somers',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.6423,
    longitude: -87.9032,
    postal_code: '53171'
  },
  '53172': {
    country: 'US',
    city: 'South Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.9105,
    longitude: -87.8646,
    postal_code: '53172'
  },
  '53176': {
    country: 'US',
    city: 'Springfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.636,
    longitude: -88.416,
    postal_code: '53176'
  },
  '53177': {
    country: 'US',
    city: 'Sturtevant',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.6967,
    longitude: -87.9031,
    postal_code: '53177'
  },
  '53178': {
    country: 'US',
    city: 'Sullivan',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jefferson',
    latitude: 42.9982,
    longitude: -88.6026,
    postal_code: '53178'
  },
  '53179': {
    country: 'US',
    city: 'Trevor',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5201,
    longitude: -88.1387,
    postal_code: '53179'
  },
  '53181': {
    country: 'US',
    city: 'Twin Lakes',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5232,
    longitude: -88.2573,
    postal_code: '53181'
  },
  '53182': {
    country: 'US',
    city: 'Union Grove',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.6896,
    longitude: -88.039,
    postal_code: '53182'
  },
  '53183': {
    country: 'US',
    city: 'Wales',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.0088,
    longitude: -88.3787,
    postal_code: '53183'
  },
  '53184': {
    country: 'US',
    city: 'Walworth',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.535,
    longitude: -88.6027,
    postal_code: '53184'
  },
  '53185': {
    country: 'US',
    city: 'Waterford',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7632,
    longitude: -88.1974,
    postal_code: '53185'
  },
  '53186': {
    country: 'US',
    city: 'Waukesha',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 42.9993,
    longitude: -88.2196,
    postal_code: '53186'
  },
  '53187': {
    country: 'US',
    city: 'Waukesha',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.0117,
    longitude: -88.2315,
    postal_code: '53187'
  },
  '53188': {
    country: 'US',
    city: 'Waukesha',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 43.0128,
    longitude: -88.2705,
    postal_code: '53188'
  },
  '53189': {
    country: 'US',
    city: 'Waukesha',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waukesha',
    latitude: 42.9516,
    longitude: -88.2963,
    postal_code: '53189'
  },
  '53190': {
    country: 'US',
    city: 'Whitewater',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.8272,
    longitude: -88.7429,
    postal_code: '53190'
  },
  '53191': {
    country: 'US',
    city: 'Williams Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.5767,
    longitude: -88.5431,
    postal_code: '53191'
  },
  '53192': {
    country: 'US',
    city: 'Wilmot',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5128,
    longitude: -88.182,
    postal_code: '53192'
  },
  '53194': {
    country: 'US',
    city: 'Woodworth',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kenosha',
    latitude: 42.5581,
    longitude: -88.0012,
    postal_code: '53194'
  },
  '53195': {
    country: 'US',
    city: 'Zenda',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.5136,
    longitude: -88.4843,
    postal_code: '53195'
  },
  '53201': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0389,
    longitude: -87.9065,
    postal_code: '53201'
  },
  '53202': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0506,
    longitude: -87.8968,
    postal_code: '53202'
  },
  '53203': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0403,
    longitude: -87.9154,
    postal_code: '53203'
  },
  '53204': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0158,
    longitude: -87.9317,
    postal_code: '53204'
  },
  '53205': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0528,
    longitude: -87.9353,
    postal_code: '53205'
  },
  '53206': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0753,
    longitude: -87.9347,
    postal_code: '53206'
  },
  '53207': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.9751,
    longitude: -87.8947,
    postal_code: '53207'
  },
  '53208': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0488,
    longitude: -87.9625,
    postal_code: '53208'
  },
  '53209': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.1188,
    longitude: -87.9478,
    postal_code: '53209'
  },
  '53210': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0685,
    longitude: -87.9715,
    postal_code: '53210'
  },
  '53211': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.082,
    longitude: -87.8895,
    postal_code: '53211'
  },
  '53212': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0712,
    longitude: -87.9084,
    postal_code: '53212'
  },
  '53213': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0488,
    longitude: -88.0015,
    postal_code: '53213'
  },
  '53214': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0215,
    longitude: -88.0176,
    postal_code: '53214'
  },
  '53215': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.006,
    longitude: -87.9429,
    postal_code: '53215'
  },
  '53216': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0859,
    longitude: -87.9742,
    postal_code: '53216'
  },
  '53217': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.1409,
    longitude: -87.9073,
    postal_code: '53217'
  },
  '53218': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.1122,
    longitude: -87.9932,
    postal_code: '53218'
  },
  '53219': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.9959,
    longitude: -87.9944,
    postal_code: '53219'
  },
  '53220': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.9559,
    longitude: -87.9933,
    postal_code: '53220'
  },
  '53221': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.9549,
    longitude: -87.9447,
    postal_code: '53221'
  },
  '53222': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0828,
    longitude: -88.0269,
    postal_code: '53222'
  },
  '53223': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.1624,
    longitude: -87.9898,
    postal_code: '53223'
  },
  '53224': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.1594,
    longitude: -88.0327,
    postal_code: '53224'
  },
  '53225': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.1154,
    longitude: -88.0346,
    postal_code: '53225'
  },
  '53226': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0493,
    longitude: -88.0414,
    postal_code: '53226'
  },
  '53227': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.9949,
    longitude: -88.0364,
    postal_code: '53227'
  },
  '53228': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.9676,
    longitude: -88.0434,
    postal_code: '53228'
  },
  '53233': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0407,
    longitude: -87.9357,
    postal_code: '53233'
  },
  '53234': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0389,
    longitude: -87.9065,
    postal_code: '53234'
  },
  '53235': {
    country: 'US',
    city: 'Saint Francis',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 42.9731,
    longitude: -87.8687,
    postal_code: '53235'
  },
  '53237': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0389,
    longitude: -87.9065,
    postal_code: '53237'
  },
  '53259': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0387,
    longitude: -87.9139,
    postal_code: '53259'
  },
  '53263': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0746,
    longitude: -88.0604,
    postal_code: '53263'
  },
  '53267': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.044,
    longitude: -87.9098,
    postal_code: '53267'
  },
  '53268': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0385,
    longitude: -87.9096,
    postal_code: '53268'
  },
  '53274': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0389,
    longitude: -87.9065,
    postal_code: '53274'
  },
  '53278': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0389,
    longitude: -87.9024,
    postal_code: '53278'
  },
  '53288': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0406,
    longitude: -87.9098,
    postal_code: '53288'
  },
  '53290': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0373,
    longitude: -87.914,
    postal_code: '53290'
  },
  '53293': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0408,
    longitude: -87.9191,
    postal_code: '53293'
  },
  '53295': {
    country: 'US',
    city: 'Milwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Milwaukee',
    latitude: 43.0389,
    longitude: -87.9065,
    postal_code: '53295'
  },
  '53401': {
    country: 'US',
    city: 'Racine',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7272,
    longitude: -87.676,
    postal_code: '53401'
  },
  '53402': {
    country: 'US',
    city: 'Racine',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7726,
    longitude: -87.796,
    postal_code: '53402'
  },
  '53403': {
    country: 'US',
    city: 'Racine',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.706,
    longitude: -87.8014,
    postal_code: '53403'
  },
  '53404': {
    country: 'US',
    city: 'Racine',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7433,
    longitude: -87.8053,
    postal_code: '53404'
  },
  '53405': {
    country: 'US',
    city: 'Racine',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7161,
    longitude: -87.8233,
    postal_code: '53405'
  },
  '53406': {
    country: 'US',
    city: 'Racine',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7242,
    longitude: -87.8551,
    postal_code: '53406'
  },
  '53407': {
    country: 'US',
    city: 'Racine',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7312,
    longitude: -87.7828,
    postal_code: '53407'
  },
  '53408': {
    country: 'US',
    city: 'Racine',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Racine',
    latitude: 42.7272,
    longitude: -87.676,
    postal_code: '53408'
  },
  '53501': {
    country: 'US',
    city: 'Afton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.6055,
    longitude: -89.0704,
    postal_code: '53501'
  },
  '53502': {
    country: 'US',
    city: 'Albany',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green',
    latitude: 42.7155,
    longitude: -89.4357,
    postal_code: '53502'
  },
  '53503': {
    country: 'US',
    city: 'Arena',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 43.1521,
    longitude: -89.9386,
    postal_code: '53503'
  },
  '53504': {
    country: 'US',
    city: 'Argyle',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lafayette',
    latitude: 42.6955,
    longitude: -89.8598,
    postal_code: '53504'
  },
  '53505': {
    country: 'US',
    city: 'Avalon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.6593,
    longitude: -88.8307,
    postal_code: '53505'
  },
  '53506': {
    country: 'US',
    city: 'Avoca',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 43.1571,
    longitude: -90.2889,
    postal_code: '53506'
  },
  '53507': {
    country: 'US',
    city: 'Barneveld',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 43.0158,
    longitude: -89.9042,
    postal_code: '53507'
  },
  '53508': {
    country: 'US',
    city: 'Belleville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 42.8669,
    longitude: -89.5377,
    postal_code: '53508'
  },
  '53510': {
    country: 'US',
    city: 'Belmont',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lafayette',
    latitude: 42.7308,
    longitude: -90.3359,
    postal_code: '53510'
  },
  '53511': {
    country: 'US',
    city: 'Beloit',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.562,
    longitude: -89.086,
    postal_code: '53511'
  },
  '53512': {
    country: 'US',
    city: 'Beloit',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.5083,
    longitude: -89.0318,
    postal_code: '53512'
  },
  '53515': {
    country: 'US',
    city: 'Black Earth',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.1322,
    longitude: -89.739,
    postal_code: '53515'
  },
  '53516': {
    country: 'US',
    city: 'Blanchardville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lafayette',
    latitude: 42.8062,
    longitude: -89.8555,
    postal_code: '53516'
  },
  '53517': {
    country: 'US',
    city: 'Blue Mounds',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.005,
    longitude: -89.8345,
    postal_code: '53517'
  },
  '53518': {
    country: 'US',
    city: 'Blue River',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 43.2366,
    longitude: -90.5873,
    postal_code: '53518'
  },
  '53520': {
    country: 'US',
    city: 'Brodhead',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green',
    latitude: 42.6111,
    longitude: -89.3714,
    postal_code: '53520'
  },
  '53521': {
    country: 'US',
    city: 'Brooklyn',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green',
    latitude: 42.8423,
    longitude: -89.3818,
    postal_code: '53521'
  },
  '53522': {
    country: 'US',
    city: 'Browntown',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green',
    latitude: 42.5579,
    longitude: -89.7816,
    postal_code: '53522'
  },
  '53523': {
    country: 'US',
    city: 'Cambridge',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 42.9918,
    longitude: -89.0209,
    postal_code: '53523'
  },
  '53525': {
    country: 'US',
    city: 'Clinton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.5535,
    longitude: -88.8705,
    postal_code: '53525'
  },
  '53526': {
    country: 'US',
    city: 'Cobb',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 42.966,
    longitude: -90.3324,
    postal_code: '53526'
  },
  '53527': {
    country: 'US',
    city: 'Cottage Grove',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0784,
    longitude: -89.2017,
    postal_code: '53527'
  },
  '53528': {
    country: 'US',
    city: 'Cross Plains',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.1132,
    longitude: -89.6397,
    postal_code: '53528'
  },
  '53529': {
    country: 'US',
    city: 'Dane',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.2424,
    longitude: -89.5117,
    postal_code: '53529'
  },
  '53530': {
    country: 'US',
    city: 'Darlington',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lafayette',
    latitude: 42.6878,
    longitude: -90.1106,
    postal_code: '53530'
  },
  '53531': {
    country: 'US',
    city: 'Deerfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0571,
    longitude: -89.0862,
    postal_code: '53531'
  },
  '53532': {
    country: 'US',
    city: 'Deforest',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.2478,
    longitude: -89.3437,
    postal_code: '53532'
  },
  '53533': {
    country: 'US',
    city: 'Dodgeville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 42.9698,
    longitude: -90.1404,
    postal_code: '53533'
  },
  '53534': {
    country: 'US',
    city: 'Edgerton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.8386,
    longitude: -89.0642,
    postal_code: '53534'
  },
  '53535': {
    country: 'US',
    city: 'Edmund',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 42.9675,
    longitude: -90.2646,
    postal_code: '53535'
  },
  '53536': {
    country: 'US',
    city: 'Evansville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.7732,
    longitude: -89.2877,
    postal_code: '53536'
  },
  '53537': {
    country: 'US',
    city: 'Footville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.6726,
    longitude: -89.2112,
    postal_code: '53537'
  },
  '53538': {
    country: 'US',
    city: 'Fort Atkinson',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jefferson',
    latitude: 42.9229,
    longitude: -88.8467,
    postal_code: '53538'
  },
  '53540': {
    country: 'US',
    city: 'Gotham',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Richland',
    latitude: 43.2233,
    longitude: -90.2915,
    postal_code: '53540'
  },
  '53541': {
    country: 'US',
    city: 'Gratiot',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lafayette',
    latitude: 42.5759,
    longitude: -90.0243,
    postal_code: '53541'
  },
  '53542': {
    country: 'US',
    city: 'Hanover',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.6323,
    longitude: -89.1594,
    postal_code: '53542'
  },
  '53543': {
    country: 'US',
    city: 'Highland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 43.0524,
    longitude: -90.365,
    postal_code: '53543'
  },
  '53544': {
    country: 'US',
    city: 'Hollandale',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 42.8773,
    longitude: -89.913,
    postal_code: '53544'
  },
  '53545': {
    country: 'US',
    city: 'Janesville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.7355,
    longitude: -89.0405,
    postal_code: '53545'
  },
  '53546': {
    country: 'US',
    city: 'Janesville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.6683,
    longitude: -89.0025,
    postal_code: '53546'
  },
  '53547': {
    country: 'US',
    city: 'Janesville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.7294,
    longitude: -89.0301,
    postal_code: '53547'
  },
  '53548': {
    country: 'US',
    city: 'Janesville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.6854,
    longitude: -89.1287,
    postal_code: '53548'
  },
  '53549': {
    country: 'US',
    city: 'Jefferson',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jefferson',
    latitude: 43.0006,
    longitude: -88.8078,
    postal_code: '53549'
  },
  '53550': {
    country: 'US',
    city: 'Juda',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green',
    latitude: 42.5679,
    longitude: -89.5026,
    postal_code: '53550'
  },
  '53551': {
    country: 'US',
    city: 'Lake Mills',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jefferson',
    latitude: 43.0816,
    longitude: -88.9133,
    postal_code: '53551'
  },
  '53553': {
    country: 'US',
    city: 'Linden',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 42.9184,
    longitude: -90.2797,
    postal_code: '53553'
  },
  '53554': {
    country: 'US',
    city: 'Livingston',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.9044,
    longitude: -90.442,
    postal_code: '53554'
  },
  '53555': {
    country: 'US',
    city: 'Lodi',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.327,
    longitude: -89.5554,
    postal_code: '53555'
  },
  '53556': {
    country: 'US',
    city: 'Lone Rock',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Richland',
    latitude: 43.2208,
    longitude: -90.2352,
    postal_code: '53556'
  },
  '53557': {
    country: 'US',
    city: 'Lowell',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.342,
    longitude: -88.7873,
    postal_code: '53557'
  },
  '53558': {
    country: 'US',
    city: 'Mc Farland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0107,
    longitude: -89.2948,
    postal_code: '53558'
  },
  '53559': {
    country: 'US',
    city: 'Marshall',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.1636,
    longitude: -89.0753,
    postal_code: '53559'
  },
  '53560': {
    country: 'US',
    city: 'Mazomanie',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.1847,
    longitude: -89.7636,
    postal_code: '53560'
  },
  '53561': {
    country: 'US',
    city: 'Merrimac',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.3855,
    longitude: -89.6323,
    postal_code: '53561'
  },
  '53562': {
    country: 'US',
    city: 'Middleton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.1052,
    longitude: -89.5073,
    postal_code: '53562'
  },
  '53563': {
    country: 'US',
    city: 'Milton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.7791,
    longitude: -88.9531,
    postal_code: '53563'
  },
  '53565': {
    country: 'US',
    city: 'Mineral Point',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 42.8547,
    longitude: -90.1646,
    postal_code: '53565'
  },
  '53566': {
    country: 'US',
    city: 'Monroe',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green',
    latitude: 42.5991,
    longitude: -89.6403,
    postal_code: '53566'
  },
  '53569': {
    country: 'US',
    city: 'Montfort',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.9757,
    longitude: -90.4447,
    postal_code: '53569'
  },
  '53570': {
    country: 'US',
    city: 'Monticello',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green',
    latitude: 42.7415,
    longitude: -89.6081,
    postal_code: '53570'
  },
  '53571': {
    country: 'US',
    city: 'Morrisonville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.2773,
    longitude: -89.3564,
    postal_code: '53571'
  },
  '53572': {
    country: 'US',
    city: 'Mount Horeb',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.002,
    longitude: -89.7414,
    postal_code: '53572'
  },
  '53573': {
    country: 'US',
    city: 'Muscoda',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 43.2074,
    longitude: -90.457,
    postal_code: '53573'
  },
  '53574': {
    country: 'US',
    city: 'New Glarus',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green',
    latitude: 42.8143,
    longitude: -89.6437,
    postal_code: '53574'
  },
  '53575': {
    country: 'US',
    city: 'Oregon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 42.9295,
    longitude: -89.387,
    postal_code: '53575'
  },
  '53576': {
    country: 'US',
    city: 'Orfordville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rock',
    latitude: 42.6278,
    longitude: -89.2533,
    postal_code: '53576'
  },
  '53577': {
    country: 'US',
    city: 'Plain',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.2927,
    longitude: -90.0558,
    postal_code: '53577'
  },
  '53578': {
    country: 'US',
    city: 'Prairie Du Sac',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.2956,
    longitude: -89.7452,
    postal_code: '53578'
  },
  '53579': {
    country: 'US',
    city: 'Reeseville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.3013,
    longitude: -88.8571,
    postal_code: '53579'
  },
  '53580': {
    country: 'US',
    city: 'Rewey',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 42.8594,
    longitude: -90.3804,
    postal_code: '53580'
  },
  '53581': {
    country: 'US',
    city: 'Richland Center',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Richland',
    latitude: 43.3661,
    longitude: -90.4302,
    postal_code: '53581'
  },
  '53582': {
    country: 'US',
    city: 'Ridgeway',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 43.0077,
    longitude: -89.9889,
    postal_code: '53582'
  },
  '53583': {
    country: 'US',
    city: 'Sauk City',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.2686,
    longitude: -89.7418,
    postal_code: '53583'
  },
  '53584': {
    country: 'US',
    city: 'Sextonville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Richland',
    latitude: 43.2794,
    longitude: -90.2876,
    postal_code: '53584'
  },
  '53585': {
    country: 'US',
    city: 'Sharon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Walworth',
    latitude: 42.5194,
    longitude: -88.7265,
    postal_code: '53585'
  },
  '53586': {
    country: 'US',
    city: 'Shullsburg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lafayette',
    latitude: 42.5786,
    longitude: -90.2266,
    postal_code: '53586'
  },
  '53587': {
    country: 'US',
    city: 'South Wayne',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lafayette',
    latitude: 42.5822,
    longitude: -89.8888,
    postal_code: '53587'
  },
  '53588': {
    country: 'US',
    city: 'Spring Green',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.1883,
    longitude: -90.0676,
    postal_code: '53588'
  },
  '53589': {
    country: 'US',
    city: 'Stoughton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 42.929,
    longitude: -89.224,
    postal_code: '53589'
  },
  '53590': {
    country: 'US',
    city: 'Sun Prairie',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.1869,
    longitude: -89.2227,
    postal_code: '53590'
  },
  '53593': {
    country: 'US',
    city: 'Verona',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 42.9999,
    longitude: -89.5522,
    postal_code: '53593'
  },
  '53594': {
    country: 'US',
    city: 'Waterloo',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jefferson',
    latitude: 43.1832,
    longitude: -88.9838,
    postal_code: '53594'
  },
  '53595': {
    country: 'US',
    city: 'Dodgeville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iowa',
    latitude: 42.9761,
    longitude: -90.1413,
    postal_code: '53595'
  },
  '53596': {
    country: 'US',
    city: 'Sun Prairie',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.1924,
    longitude: -89.2629,
    postal_code: '53596'
  },
  '53597': {
    country: 'US',
    city: 'Waunakee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.1818,
    longitude: -89.4532,
    postal_code: '53597'
  },
  '53598': {
    country: 'US',
    city: 'Windsor',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.2078,
    longitude: -89.3418,
    postal_code: '53598'
  },
  '53599': {
    country: 'US',
    city: 'Woodford',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lafayette',
    latitude: 42.6493,
    longitude: -89.8622,
    postal_code: '53599'
  },
  '53701': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53701'
  },
  '53702': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53702'
  },
  '53703': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0775,
    longitude: -89.3831,
    postal_code: '53703'
  },
  '53704': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.1205,
    longitude: -89.3523,
    postal_code: '53704'
  },
  '53705': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.073,
    longitude: -89.4528,
    postal_code: '53705'
  },
  '53706': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0769,
    longitude: -89.4094,
    postal_code: '53706'
  },
  '53707': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53707'
  },
  '53708': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53708'
  },
  '53711': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0356,
    longitude: -89.4526,
    postal_code: '53711'
  },
  '53713': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0372,
    longitude: -89.3971,
    postal_code: '53713'
  },
  '53714': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0977,
    longitude: -89.3118,
    postal_code: '53714'
  },
  '53715': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0653,
    longitude: -89.4,
    postal_code: '53715'
  },
  '53716': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0631,
    longitude: -89.3133,
    postal_code: '53716'
  },
  '53717': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0736,
    longitude: -89.508,
    postal_code: '53717'
  },
  '53718': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0984,
    longitude: -89.2734,
    postal_code: '53718'
  },
  '53719': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0321,
    longitude: -89.4993,
    postal_code: '53719'
  },
  '53725': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53725'
  },
  '53726': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53726'
  },
  '53744': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53744'
  },
  '53774': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0469,
    longitude: -89.374,
    postal_code: '53774'
  },
  '53777': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53777'
  },
  '53778': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53778'
  },
  '53779': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0982,
    longitude: -89.3242,
    postal_code: '53779'
  },
  '53782': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53782'
  },
  '53783': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.1596,
    longitude: -89.2852,
    postal_code: '53783'
  },
  '53784': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0489,
    longitude: -89.3384,
    postal_code: '53784'
  },
  '53785': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53785'
  },
  '53786': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53786'
  },
  '53788': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0767,
    longitude: -89.3763,
    postal_code: '53788'
  },
  '53789': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53789'
  },
  '53790': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53790'
  },
  '53791': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53791'
  },
  '53792': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53792'
  },
  '53793': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53793'
  },
  '53794': {
    country: 'US',
    city: 'Madison',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dane',
    latitude: 43.0696,
    longitude: -89.4239,
    postal_code: '53794'
  },
  '53801': {
    country: 'US',
    city: 'Bagley',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.9207,
    longitude: -91.0685,
    postal_code: '53801'
  },
  '53802': {
    country: 'US',
    city: 'Beetown',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.795,
    longitude: -90.8854,
    postal_code: '53802'
  },
  '53803': {
    country: 'US',
    city: 'Benton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lafayette',
    latitude: 42.5545,
    longitude: -90.3508,
    postal_code: '53803'
  },
  '53804': {
    country: 'US',
    city: 'Bloomington',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.8726,
    longitude: -90.9099,
    postal_code: '53804'
  },
  '53805': {
    country: 'US',
    city: 'Boscobel',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 43.1393,
    longitude: -90.7061,
    postal_code: '53805'
  },
  '53806': {
    country: 'US',
    city: 'Cassville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.7288,
    longitude: -90.9608,
    postal_code: '53806'
  },
  '53807': {
    country: 'US',
    city: 'Cuba City',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.5991,
    longitude: -90.4749,
    postal_code: '53807'
  },
  '53808': {
    country: 'US',
    city: 'Dickeyville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.6263,
    longitude: -90.5931,
    postal_code: '53808'
  },
  '53809': {
    country: 'US',
    city: 'Fennimore',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.9886,
    longitude: -90.655,
    postal_code: '53809'
  },
  '53810': {
    country: 'US',
    city: 'Glen Haven',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.8204,
    longitude: -91.0005,
    postal_code: '53810'
  },
  '53811': {
    country: 'US',
    city: 'Hazel Green',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.5359,
    longitude: -90.5118,
    postal_code: '53811'
  },
  '53812': {
    country: 'US',
    city: 'Kieler',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.5814,
    longitude: -90.6026,
    postal_code: '53812'
  },
  '53813': {
    country: 'US',
    city: 'Lancaster',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.8445,
    longitude: -90.7109,
    postal_code: '53813'
  },
  '53816': {
    country: 'US',
    city: 'Mount Hope',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.9689,
    longitude: -90.8665,
    postal_code: '53816'
  },
  '53817': {
    country: 'US',
    city: 'Patch Grove',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.9422,
    longitude: -90.9646,
    postal_code: '53817'
  },
  '53818': {
    country: 'US',
    city: 'Platteville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.7393,
    longitude: -90.4854,
    postal_code: '53818'
  },
  '53820': {
    country: 'US',
    city: 'Potosi',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.6884,
    longitude: -90.7002,
    postal_code: '53820'
  },
  '53821': {
    country: 'US',
    city: 'Prairie Du Chien',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Crawford',
    latitude: 43.0426,
    longitude: -91.1193,
    postal_code: '53821'
  },
  '53824': {
    country: 'US',
    city: 'Sinsinawa',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.5236,
    longitude: -90.5393,
    postal_code: '53824'
  },
  '53825': {
    country: 'US',
    city: 'Stitzer',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 42.9214,
    longitude: -90.6079,
    postal_code: '53825'
  },
  '53826': {
    country: 'US',
    city: 'Wauzeka',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Crawford',
    latitude: 43.1143,
    longitude: -90.9239,
    postal_code: '53826'
  },
  '53827': {
    country: 'US',
    city: 'Woodman',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Grant',
    latitude: 43.0577,
    longitude: -90.8245,
    postal_code: '53827'
  },
  '53901': {
    country: 'US',
    city: 'Portage',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.5501,
    longitude: -89.4714,
    postal_code: '53901'
  },
  '53910': {
    country: 'US',
    city: 'Adams',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Adams',
    latitude: 43.8967,
    longitude: -89.8219,
    postal_code: '53910'
  },
  '53911': {
    country: 'US',
    city: 'Arlington',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.3269,
    longitude: -89.3631,
    postal_code: '53911'
  },
  '53913': {
    country: 'US',
    city: 'Baraboo',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.4825,
    longitude: -89.7462,
    postal_code: '53913'
  },
  '53916': {
    country: 'US',
    city: 'Beaver Dam',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.4616,
    longitude: -88.8407,
    postal_code: '53916'
  },
  '53919': {
    country: 'US',
    city: 'Brandon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.7258,
    longitude: -88.7841,
    postal_code: '53919'
  },
  '53920': {
    country: 'US',
    city: 'Briggsville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marquette',
    latitude: 43.6759,
    longitude: -89.5803,
    postal_code: '53920'
  },
  '53922': {
    country: 'US',
    city: 'Burnett',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.5116,
    longitude: -88.7176,
    postal_code: '53922'
  },
  '53923': {
    country: 'US',
    city: 'Cambria',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.5724,
    longitude: -89.1156,
    postal_code: '53923'
  },
  '53924': {
    country: 'US',
    city: 'Cazenovia',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Richland',
    latitude: 43.4987,
    longitude: -90.2796,
    postal_code: '53924'
  },
  '53925': {
    country: 'US',
    city: 'Columbus',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.3315,
    longitude: -89.0271,
    postal_code: '53925'
  },
  '53926': {
    country: 'US',
    city: 'Dalton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green Lake',
    latitude: 43.6718,
    longitude: -89.1918,
    postal_code: '53926'
  },
  '53927': {
    country: 'US',
    city: 'Dellwood',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Adams',
    latitude: 43.9555,
    longitude: -89.9418,
    postal_code: '53927'
  },
  '53928': {
    country: 'US',
    city: 'Doylestown',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.4266,
    longitude: -89.1491,
    postal_code: '53928'
  },
  '53929': {
    country: 'US',
    city: 'Elroy',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Juneau',
    latitude: 43.7516,
    longitude: -90.2889,
    postal_code: '53929'
  },
  '53930': {
    country: 'US',
    city: 'Endeavor',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marquette',
    latitude: 43.6963,
    longitude: -89.4617,
    postal_code: '53930'
  },
  '53931': {
    country: 'US',
    city: 'Fairwater',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.7475,
    longitude: -88.8665,
    postal_code: '53931'
  },
  '53932': {
    country: 'US',
    city: 'Fall River',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.4008,
    longitude: -89.0625,
    postal_code: '53932'
  },
  '53933': {
    country: 'US',
    city: 'Fox Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.5815,
    longitude: -88.9041,
    postal_code: '53933'
  },
  '53934': {
    country: 'US',
    city: 'Friendship',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Adams',
    latitude: 43.9896,
    longitude: -89.8149,
    postal_code: '53934'
  },
  '53935': {
    country: 'US',
    city: 'Friesland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.5901,
    longitude: -89.0593,
    postal_code: '53935'
  },
  '53936': {
    country: 'US',
    city: 'Grand Marsh',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Adams',
    latitude: 43.8869,
    longitude: -89.7062,
    postal_code: '53936'
  },
  '53937': {
    country: 'US',
    city: 'Hillpoint',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.3951,
    longitude: -90.1588,
    postal_code: '53937'
  },
  '53939': {
    country: 'US',
    city: 'Kingston',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green Lake',
    latitude: 43.6911,
    longitude: -89.1302,
    postal_code: '53939'
  },
  '53940': {
    country: 'US',
    city: 'Lake Delton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.5939,
    longitude: -89.7912,
    postal_code: '53940'
  },
  '53941': {
    country: 'US',
    city: 'La Valle',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.5699,
    longitude: -90.128,
    postal_code: '53941'
  },
  '53942': {
    country: 'US',
    city: 'Lime Ridge',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.4682,
    longitude: -90.1615,
    postal_code: '53942'
  },
  '53943': {
    country: 'US',
    city: 'Loganville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.405,
    longitude: -90.0337,
    postal_code: '53943'
  },
  '53944': {
    country: 'US',
    city: 'Lyndon Station',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Juneau',
    latitude: 43.6899,
    longitude: -89.8915,
    postal_code: '53944'
  },
  '53946': {
    country: 'US',
    city: 'Markesan',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green Lake',
    latitude: 43.714,
    longitude: -89.007,
    postal_code: '53946'
  },
  '53947': {
    country: 'US',
    city: 'Marquette',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green Lake',
    latitude: 43.7459,
    longitude: -89.1397,
    postal_code: '53947'
  },
  '53948': {
    country: 'US',
    city: 'Mauston',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Juneau',
    latitude: 43.7934,
    longitude: -90.0641,
    postal_code: '53948'
  },
  '53949': {
    country: 'US',
    city: 'Montello',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marquette',
    latitude: 43.769,
    longitude: -89.3586,
    postal_code: '53949'
  },
  '53950': {
    country: 'US',
    city: 'New Lisbon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Juneau',
    latitude: 43.8877,
    longitude: -90.1524,
    postal_code: '53950'
  },
  '53951': {
    country: 'US',
    city: 'North Freedom',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.4106,
    longitude: -89.849,
    postal_code: '53951'
  },
  '53952': {
    country: 'US',
    city: 'Oxford',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marquette',
    latitude: 43.7799,
    longitude: -89.5632,
    postal_code: '53952'
  },
  '53953': {
    country: 'US',
    city: 'Packwaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marquette',
    latitude: 43.7644,
    longitude: -89.4576,
    postal_code: '53953'
  },
  '53954': {
    country: 'US',
    city: 'Pardeeville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.534,
    longitude: -89.3265,
    postal_code: '53954'
  },
  '53955': {
    country: 'US',
    city: 'Poynette',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.4013,
    longitude: -89.4189,
    postal_code: '53955'
  },
  '53956': {
    country: 'US',
    city: 'Randolph',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.5397,
    longitude: -89.003,
    postal_code: '53956'
  },
  '53957': {
    country: 'US',
    city: 'Randolph',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.5352,
    longitude: -89.0068,
    postal_code: '53957'
  },
  '53958': {
    country: 'US',
    city: 'Reedsburg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.3938,
    longitude: -89.9559,
    postal_code: '53958'
  },
  '53959': {
    country: 'US',
    city: 'Reedsburg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.5316,
    longitude: -89.9959,
    postal_code: '53959'
  },
  '53960': {
    country: 'US',
    city: 'Rio',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.4231,
    longitude: -89.2354,
    postal_code: '53960'
  },
  '53961': {
    country: 'US',
    city: 'Rock Springs',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sauk',
    latitude: 43.4681,
    longitude: -89.9218,
    postal_code: '53961'
  },
  '53962': {
    country: 'US',
    city: 'Union Center',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Juneau',
    latitude: 43.6853,
    longitude: -90.2668,
    postal_code: '53962'
  },
  '53963': {
    country: 'US',
    city: 'Waupun',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dodge',
    latitude: 43.6324,
    longitude: -88.7373,
    postal_code: '53963'
  },
  '53964': {
    country: 'US',
    city: 'Westfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marquette',
    latitude: 43.8968,
    longitude: -89.502,
    postal_code: '53964'
  },
  '53965': {
    country: 'US',
    city: 'Wisconsin Dells',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.6508,
    longitude: -89.7665,
    postal_code: '53965'
  },
  '53968': {
    country: 'US',
    city: 'Wonewoc',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Juneau',
    latitude: 43.6285,
    longitude: -90.2394,
    postal_code: '53968'
  },
  '53969': {
    country: 'US',
    city: 'Wyocena',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Columbia',
    latitude: 43.4989,
    longitude: -89.305,
    postal_code: '53969'
  },
  '54001': {
    country: 'US',
    city: 'Amery',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.3486,
    longitude: -92.4014,
    postal_code: '54001'
  },
  '54002': {
    country: 'US',
    city: 'Baldwin',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 44.9657,
    longitude: -92.3655,
    postal_code: '54002'
  },
  '54003': {
    country: 'US',
    city: 'Beldenville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.7721,
    longitude: -92.4372,
    postal_code: '54003'
  },
  '54004': {
    country: 'US',
    city: 'Clayton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.3063,
    longitude: -92.1395,
    postal_code: '54004'
  },
  '54005': {
    country: 'US',
    city: 'Clear Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.2365,
    longitude: -92.2749,
    postal_code: '54005'
  },
  '54006': {
    country: 'US',
    city: 'Cushing',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.5604,
    longitude: -92.6241,
    postal_code: '54006'
  },
  '54007': {
    country: 'US',
    city: 'Deer Park',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 45.169,
    longitude: -92.3585,
    postal_code: '54007'
  },
  '54009': {
    country: 'US',
    city: 'Dresser',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.3546,
    longitude: -92.5623,
    postal_code: '54009'
  },
  '54010': {
    country: 'US',
    city: 'East Ellsworth',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.7348,
    longitude: -92.4655,
    postal_code: '54010'
  },
  '54011': {
    country: 'US',
    city: 'Ellsworth',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.7302,
    longitude: -92.489,
    postal_code: '54011'
  },
  '54013': {
    country: 'US',
    city: 'Glenwood City',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 45.0623,
    longitude: -92.1816,
    postal_code: '54013'
  },
  '54014': {
    country: 'US',
    city: 'Hager City',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.627,
    longitude: -92.5501,
    postal_code: '54014'
  },
  '54015': {
    country: 'US',
    city: 'Hammond',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 44.967,
    longitude: -92.4472,
    postal_code: '54015'
  },
  '54016': {
    country: 'US',
    city: 'Hudson',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 44.9842,
    longitude: -92.7271,
    postal_code: '54016'
  },
  '54017': {
    country: 'US',
    city: 'New Richmond',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 45.123,
    longitude: -92.5366,
    postal_code: '54017'
  },
  '54020': {
    country: 'US',
    city: 'Osceola',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.3218,
    longitude: -92.6971,
    postal_code: '54020'
  },
  '54021': {
    country: 'US',
    city: 'Prescott',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.7476,
    longitude: -92.7535,
    postal_code: '54021'
  },
  '54022': {
    country: 'US',
    city: 'River Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.855,
    longitude: -92.6313,
    postal_code: '54022'
  },
  '54023': {
    country: 'US',
    city: 'Roberts',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 44.9874,
    longitude: -92.5598,
    postal_code: '54023'
  },
  '54024': {
    country: 'US',
    city: 'Saint Croix Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.5097,
    longitude: -92.6117,
    postal_code: '54024'
  },
  '54025': {
    country: 'US',
    city: 'Somerset',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 45.1331,
    longitude: -92.6865,
    postal_code: '54025'
  },
  '54026': {
    country: 'US',
    city: 'Star Prairie',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.2013,
    longitude: -92.5514,
    postal_code: '54026'
  },
  '54027': {
    country: 'US',
    city: 'Wilson',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 44.9466,
    longitude: -92.1965,
    postal_code: '54027'
  },
  '54028': {
    country: 'US',
    city: 'Woodville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 44.9408,
    longitude: -92.2848,
    postal_code: '54028'
  },
  '54082': {
    country: 'US',
    city: 'Houlton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'St. Croix',
    latitude: 45.0655,
    longitude: -92.7572,
    postal_code: '54082'
  },
  '54101': {
    country: 'US',
    city: 'Abrams',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 44.7869,
    longitude: -88.0571,
    postal_code: '54101'
  },
  '54102': {
    country: 'US',
    city: 'Amberg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.4989,
    longitude: -87.9643,
    postal_code: '54102'
  },
  '54103': {
    country: 'US',
    city: 'Armstrong Creek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Forest',
    latitude: 45.6726,
    longitude: -88.4908,
    postal_code: '54103'
  },
  '54104': {
    country: 'US',
    city: 'Athelstane',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.4228,
    longitude: -88.1753,
    postal_code: '54104'
  },
  '54106': {
    country: 'US',
    city: 'Black Creek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.4702,
    longitude: -88.4547,
    postal_code: '54106'
  },
  '54107': {
    country: 'US',
    city: 'Bonduel',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.6992,
    longitude: -88.4543,
    postal_code: '54107'
  },
  '54110': {
    country: 'US',
    city: 'Brillion',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Calumet',
    latitude: 44.1779,
    longitude: -88.0833,
    postal_code: '54110'
  },
  '54111': {
    country: 'US',
    city: 'Cecil',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.811,
    longitude: -88.4183,
    postal_code: '54111'
  },
  '54112': {
    country: 'US',
    city: 'Coleman',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.0722,
    longitude: -88.0587,
    postal_code: '54112'
  },
  '54113': {
    country: 'US',
    city: 'Combined Locks',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.2666,
    longitude: -88.3133,
    postal_code: '54113'
  },
  '54114': {
    country: 'US',
    city: 'Crivitz',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.2617,
    longitude: -88.078,
    postal_code: '54114'
  },
  '54115': {
    country: 'US',
    city: 'De Pere',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.4388,
    longitude: -88.0806,
    postal_code: '54115'
  },
  '54119': {
    country: 'US',
    city: 'Dunbar',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.6009,
    longitude: -88.1067,
    postal_code: '54119'
  },
  '54120': {
    country: 'US',
    city: 'Fence',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Florence',
    latitude: 45.7444,
    longitude: -88.4243,
    postal_code: '54120'
  },
  '54121': {
    country: 'US',
    city: 'Florence',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Florence',
    latitude: 45.9027,
    longitude: -88.2289,
    postal_code: '54121'
  },
  '54123': {
    country: 'US',
    city: 'Forest Junction',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Calumet',
    latitude: 44.2125,
    longitude: -88.1443,
    postal_code: '54123'
  },
  '54124': {
    country: 'US',
    city: 'Gillett',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 44.9018,
    longitude: -88.3242,
    postal_code: '54124'
  },
  '54125': {
    country: 'US',
    city: 'Goodman',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.6469,
    longitude: -88.3629,
    postal_code: '54125'
  },
  '54126': {
    country: 'US',
    city: 'Greenleaf',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.2937,
    longitude: -88.0275,
    postal_code: '54126'
  },
  '54127': {
    country: 'US',
    city: 'Green Valley',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.7964,
    longitude: -88.2696,
    postal_code: '54127'
  },
  '54128': {
    country: 'US',
    city: 'Gresham',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.853,
    longitude: -88.7887,
    postal_code: '54128'
  },
  '54129': {
    country: 'US',
    city: 'Hilbert',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Calumet',
    latitude: 44.1271,
    longitude: -88.207,
    postal_code: '54129'
  },
  '54130': {
    country: 'US',
    city: 'Kaukauna',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.2956,
    longitude: -88.2717,
    postal_code: '54130'
  },
  '54131': {
    country: 'US',
    city: 'Freedom',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.4163,
    longitude: -88.4649,
    postal_code: '54131'
  },
  '54135': {
    country: 'US',
    city: 'Keshena',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Menominee',
    latitude: 44.9146,
    longitude: -88.6357,
    postal_code: '54135'
  },
  '54136': {
    country: 'US',
    city: 'Kimberly',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.2701,
    longitude: -88.3384,
    postal_code: '54136'
  },
  '54137': {
    country: 'US',
    city: 'Krakow',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.7635,
    longitude: -88.2593,
    postal_code: '54137'
  },
  '54138': {
    country: 'US',
    city: 'Lakewood',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 45.3163,
    longitude: -88.5032,
    postal_code: '54138'
  },
  '54139': {
    country: 'US',
    city: 'Lena',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 44.956,
    longitude: -88.075,
    postal_code: '54139'
  },
  '54140': {
    country: 'US',
    city: 'Little Chute',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.2842,
    longitude: -88.312,
    postal_code: '54140'
  },
  '54141': {
    country: 'US',
    city: 'Little Suamico',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 44.7175,
    longitude: -88.0093,
    postal_code: '54141'
  },
  '54143': {
    country: 'US',
    city: 'Marinette',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.0874,
    longitude: -87.6697,
    postal_code: '54143'
  },
  '54149': {
    country: 'US',
    city: 'Mountain',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 45.1991,
    longitude: -88.4583,
    postal_code: '54149'
  },
  '54150': {
    country: 'US',
    city: 'Neopit',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Menominee',
    latitude: 44.9808,
    longitude: -88.8309,
    postal_code: '54150'
  },
  '54151': {
    country: 'US',
    city: 'Niagara',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.7653,
    longitude: -88.0309,
    postal_code: '54151'
  },
  '54152': {
    country: 'US',
    city: 'Nichols',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.5668,
    longitude: -88.4582,
    postal_code: '54152'
  },
  '54153': {
    country: 'US',
    city: 'Oconto',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 44.8914,
    longitude: -87.8917,
    postal_code: '54153'
  },
  '54154': {
    country: 'US',
    city: 'Oconto Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 44.8755,
    longitude: -88.1555,
    postal_code: '54154'
  },
  '54155': {
    country: 'US',
    city: 'Oneida',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.5166,
    longitude: -88.1859,
    postal_code: '54155'
  },
  '54156': {
    country: 'US',
    city: 'Pembine',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.6074,
    longitude: -87.9704,
    postal_code: '54156'
  },
  '54157': {
    country: 'US',
    city: 'Peshtigo',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.048,
    longitude: -87.8103,
    postal_code: '54157'
  },
  '54159': {
    country: 'US',
    city: 'Porterfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.1934,
    longitude: -87.8062,
    postal_code: '54159'
  },
  '54160': {
    country: 'US',
    city: 'Potter',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Calumet',
    latitude: 44.1199,
    longitude: -88.0965,
    postal_code: '54160'
  },
  '54161': {
    country: 'US',
    city: 'Pound',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.1273,
    longitude: -88.1396,
    postal_code: '54161'
  },
  '54162': {
    country: 'US',
    city: 'Pulaski',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.6611,
    longitude: -88.2634,
    postal_code: '54162'
  },
  '54165': {
    country: 'US',
    city: 'Seymour',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.5091,
    longitude: -88.3172,
    postal_code: '54165'
  },
  '54166': {
    country: 'US',
    city: 'Shawano',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.7851,
    longitude: -88.6036,
    postal_code: '54166'
  },
  '54169': {
    country: 'US',
    city: 'Sherwood',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Calumet',
    latitude: 44.1764,
    longitude: -88.2779,
    postal_code: '54169'
  },
  '54170': {
    country: 'US',
    city: 'Shiocton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.4972,
    longitude: -88.5562,
    postal_code: '54170'
  },
  '54171': {
    country: 'US',
    city: 'Sobieski',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 44.7105,
    longitude: -88.1076,
    postal_code: '54171'
  },
  '54173': {
    country: 'US',
    city: 'Suamico',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.6435,
    longitude: -88.0317,
    postal_code: '54173'
  },
  '54174': {
    country: 'US',
    city: 'Suring',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 45.0186,
    longitude: -88.3628,
    postal_code: '54174'
  },
  '54175': {
    country: 'US',
    city: 'Townsend',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oconto',
    latitude: 45.3148,
    longitude: -88.6107,
    postal_code: '54175'
  },
  '54177': {
    country: 'US',
    city: 'Wausaukee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marinette',
    latitude: 45.3495,
    longitude: -87.9099,
    postal_code: '54177'
  },
  '54180': {
    country: 'US',
    city: 'Wrightstown',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.3264,
    longitude: -88.1749,
    postal_code: '54180'
  },
  '54182': {
    country: 'US',
    city: 'Zachow',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.7315,
    longitude: -88.3698,
    postal_code: '54182'
  },
  '54201': {
    country: 'US',
    city: 'Algoma',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kewaunee',
    latitude: 44.6101,
    longitude: -87.4578,
    postal_code: '54201'
  },
  '54202': {
    country: 'US',
    city: 'Baileys Harbor',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 45.0565,
    longitude: -87.1676,
    postal_code: '54202'
  },
  '54204': {
    country: 'US',
    city: 'Brussels',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 44.7476,
    longitude: -87.6225,
    postal_code: '54204'
  },
  '54205': {
    country: 'US',
    city: 'Casco',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kewaunee',
    latitude: 44.5833,
    longitude: -87.6231,
    postal_code: '54205'
  },
  '54207': {
    country: 'US',
    city: 'Collins',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.091,
    longitude: -87.9902,
    postal_code: '54207'
  },
  '54208': {
    country: 'US',
    city: 'Denmark',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.3594,
    longitude: -87.827,
    postal_code: '54208'
  },
  '54209': {
    country: 'US',
    city: 'Egg Harbor',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 45.01,
    longitude: -87.2805,
    postal_code: '54209'
  },
  '54210': {
    country: 'US',
    city: 'Ellison Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 45.2572,
    longitude: -87.0512,
    postal_code: '54210'
  },
  '54211': {
    country: 'US',
    city: 'Ephraim',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 45.1592,
    longitude: -87.171,
    postal_code: '54211'
  },
  '54212': {
    country: 'US',
    city: 'Fish Creek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 45.1155,
    longitude: -87.2064,
    postal_code: '54212'
  },
  '54213': {
    country: 'US',
    city: 'Forestville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 44.6996,
    longitude: -87.5215,
    postal_code: '54213'
  },
  '54214': {
    country: 'US',
    city: 'Francis Creek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.2,
    longitude: -87.72,
    postal_code: '54214'
  },
  '54215': {
    country: 'US',
    city: 'Kellnersville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.2226,
    longitude: -87.8017,
    postal_code: '54215'
  },
  '54216': {
    country: 'US',
    city: 'Kewaunee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kewaunee',
    latitude: 44.4401,
    longitude: -87.5594,
    postal_code: '54216'
  },
  '54217': {
    country: 'US',
    city: 'Luxemburg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Kewaunee',
    latitude: 44.5506,
    longitude: -87.7156,
    postal_code: '54217'
  },
  '54220': {
    country: 'US',
    city: 'Manitowoc',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.0971,
    longitude: -87.6823,
    postal_code: '54220'
  },
  '54221': {
    country: 'US',
    city: 'Manitowoc',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.1323,
    longitude: -87.599,
    postal_code: '54221'
  },
  '54226': {
    country: 'US',
    city: 'Maplewood',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 44.7475,
    longitude: -87.4793,
    postal_code: '54226'
  },
  '54227': {
    country: 'US',
    city: 'Maribel',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.2852,
    longitude: -87.8055,
    postal_code: '54227'
  },
  '54228': {
    country: 'US',
    city: 'Mishicot',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.2609,
    longitude: -87.6447,
    postal_code: '54228'
  },
  '54229': {
    country: 'US',
    city: 'New Franken',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.5592,
    longitude: -87.8235,
    postal_code: '54229'
  },
  '54230': {
    country: 'US',
    city: 'Reedsville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.1576,
    longitude: -87.966,
    postal_code: '54230'
  },
  '54232': {
    country: 'US',
    city: 'Saint Nazianz',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.0064,
    longitude: -87.9229,
    postal_code: '54232'
  },
  '54234': {
    country: 'US',
    city: 'Sister Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 45.1875,
    longitude: -87.1139,
    postal_code: '54234'
  },
  '54235': {
    country: 'US',
    city: 'Sturgeon Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 44.8438,
    longitude: -87.3753,
    postal_code: '54235'
  },
  '54240': {
    country: 'US',
    city: 'Tisch Mills',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.3272,
    longitude: -87.6237,
    postal_code: '54240'
  },
  '54241': {
    country: 'US',
    city: 'Two Rivers',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.166,
    longitude: -87.5855,
    postal_code: '54241'
  },
  '54245': {
    country: 'US',
    city: 'Valders',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.042,
    longitude: -87.8812,
    postal_code: '54245'
  },
  '54246': {
    country: 'US',
    city: 'Washington Island',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Door',
    latitude: 45.3738,
    longitude: -86.8975,
    postal_code: '54246'
  },
  '54247': {
    country: 'US',
    city: 'Whitelaw',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Manitowoc',
    latitude: 44.1573,
    longitude: -87.8267,
    postal_code: '54247'
  },
  '54301': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.482,
    longitude: -88.0205,
    postal_code: '54301'
  },
  '54302': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.5025,
    longitude: -87.9771,
    postal_code: '54302'
  },
  '54303': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.5522,
    longitude: -88.0788,
    postal_code: '54303'
  },
  '54304': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.4975,
    longitude: -88.0324,
    postal_code: '54304'
  },
  '54305': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.4601,
    longitude: -88.0074,
    postal_code: '54305'
  },
  '54306': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.5192,
    longitude: -88.0198,
    postal_code: '54306'
  },
  '54307': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.4601,
    longitude: -88.0074,
    postal_code: '54307'
  },
  '54308': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.5192,
    longitude: -88.0198,
    postal_code: '54308'
  },
  '54311': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.4914,
    longitude: -87.9267,
    postal_code: '54311'
  },
  '54313': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.5463,
    longitude: -88.1021,
    postal_code: '54313'
  },
  '54324': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.4601,
    longitude: -88.0074,
    postal_code: '54324'
  },
  '54344': {
    country: 'US',
    city: 'Green Bay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Brown',
    latitude: 44.425,
    longitude: -88.1113,
    postal_code: '54344'
  },
  '54401': {
    country: 'US',
    city: 'Wausau',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.9654,
    longitude: -89.7066,
    postal_code: '54401'
  },
  '54402': {
    country: 'US',
    city: 'Wausau',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.9009,
    longitude: -89.7701,
    postal_code: '54402'
  },
  '54403': {
    country: 'US',
    city: 'Wausau',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.9529,
    longitude: -89.5318,
    postal_code: '54403'
  },
  '54404': {
    country: 'US',
    city: 'Marshfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.6689,
    longitude: -90.1718,
    postal_code: '54404'
  },
  '54405': {
    country: 'US',
    city: 'Abbotsford',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.9641,
    longitude: -90.2994,
    postal_code: '54405'
  },
  '54406': {
    country: 'US',
    city: 'Amherst',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.4231,
    longitude: -89.3035,
    postal_code: '54406'
  },
  '54407': {
    country: 'US',
    city: 'Amherst Junction',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.5065,
    longitude: -89.3038,
    postal_code: '54407'
  },
  '54408': {
    country: 'US',
    city: 'Aniwa',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 45.0088,
    longitude: -89.3578,
    postal_code: '54408'
  },
  '54409': {
    country: 'US',
    city: 'Antigo',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Langlade',
    latitude: 45.1314,
    longitude: -89.1419,
    postal_code: '54409'
  },
  '54410': {
    country: 'US',
    city: 'Arpin',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.5419,
    longitude: -90.037,
    postal_code: '54410'
  },
  '54411': {
    country: 'US',
    city: 'Athens',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.9823,
    longitude: -90.0078,
    postal_code: '54411'
  },
  '54412': {
    country: 'US',
    city: 'Auburndale',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.6378,
    longitude: -90.0136,
    postal_code: '54412'
  },
  '54413': {
    country: 'US',
    city: 'Babcock',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.3031,
    longitude: -90.0986,
    postal_code: '54413'
  },
  '54414': {
    country: 'US',
    city: 'Birnamwood',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.9269,
    longitude: -89.2124,
    postal_code: '54414'
  },
  '54415': {
    country: 'US',
    city: 'Blenker',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.6191,
    longitude: -89.9186,
    postal_code: '54415'
  },
  '54416': {
    country: 'US',
    city: 'Bowler',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.9337,
    longitude: -88.9761,
    postal_code: '54416'
  },
  '54417': {
    country: 'US',
    city: 'Brokaw',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 45.0273,
    longitude: -89.6541,
    postal_code: '54417'
  },
  '54418': {
    country: 'US',
    city: 'Bryant',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Langlade',
    latitude: 45.203,
    longitude: -88.9983,
    postal_code: '54418'
  },
  '54420': {
    country: 'US',
    city: 'Chili',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.6301,
    longitude: -90.36,
    postal_code: '54420'
  },
  '54421': {
    country: 'US',
    city: 'Colby',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.9117,
    longitude: -90.3144,
    postal_code: '54421'
  },
  '54422': {
    country: 'US',
    city: 'Curtiss',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.9602,
    longitude: -90.4592,
    postal_code: '54422'
  },
  '54423': {
    country: 'US',
    city: 'Custer',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.5662,
    longitude: -89.4153,
    postal_code: '54423'
  },
  '54424': {
    country: 'US',
    city: 'Deerbrook',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Langlade',
    latitude: 45.2368,
    longitude: -89.1873,
    postal_code: '54424'
  },
  '54425': {
    country: 'US',
    city: 'Dorchester',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 45.0042,
    longitude: -90.3596,
    postal_code: '54425'
  },
  '54426': {
    country: 'US',
    city: 'Edgar',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.9046,
    longitude: -90.0125,
    postal_code: '54426'
  },
  '54427': {
    country: 'US',
    city: 'Eland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.8309,
    longitude: -89.2461,
    postal_code: '54427'
  },
  '54428': {
    country: 'US',
    city: 'Elcho',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Langlade',
    latitude: 45.4409,
    longitude: -89.1516,
    postal_code: '54428'
  },
  '54429': {
    country: 'US',
    city: 'Elderon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.7802,
    longitude: -89.2478,
    postal_code: '54429'
  },
  '54430': {
    country: 'US',
    city: 'Elton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Langlade',
    latitude: 45.1562,
    longitude: -88.8837,
    postal_code: '54430'
  },
  '54432': {
    country: 'US',
    city: 'Galloway',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.7128,
    longitude: -89.2643,
    postal_code: '54432'
  },
  '54433': {
    country: 'US',
    city: 'Gilman',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Taylor',
    latitude: 45.1891,
    longitude: -90.8257,
    postal_code: '54433'
  },
  '54434': {
    country: 'US',
    city: 'Jump River',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Taylor',
    latitude: 45.353,
    longitude: -90.8012,
    postal_code: '54434'
  },
  '54435': {
    country: 'US',
    city: 'Gleason',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lincoln',
    latitude: 45.3384,
    longitude: -89.4753,
    postal_code: '54435'
  },
  '54436': {
    country: 'US',
    city: 'Granton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.5606,
    longitude: -90.4484,
    postal_code: '54436'
  },
  '54437': {
    country: 'US',
    city: 'Greenwood',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.7404,
    longitude: -90.6229,
    postal_code: '54437'
  },
  '54439': {
    country: 'US',
    city: 'Hannibal',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Taylor',
    latitude: 45.2525,
    longitude: -90.789,
    postal_code: '54439'
  },
  '54440': {
    country: 'US',
    city: 'Hatley',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.8256,
    longitude: -89.3777,
    postal_code: '54440'
  },
  '54441': {
    country: 'US',
    city: 'Hewitt',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.6437,
    longitude: -90.1031,
    postal_code: '54441'
  },
  '54442': {
    country: 'US',
    city: 'Irma',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lincoln',
    latitude: 45.3485,
    longitude: -89.6611,
    postal_code: '54442'
  },
  '54443': {
    country: 'US',
    city: 'Junction City',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.5963,
    longitude: -89.7438,
    postal_code: '54443'
  },
  '54446': {
    country: 'US',
    city: 'Loyal',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.7272,
    longitude: -90.4922,
    postal_code: '54446'
  },
  '54447': {
    country: 'US',
    city: 'Lublin',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Taylor',
    latitude: 45.0739,
    longitude: -90.7337,
    postal_code: '54447'
  },
  '54448': {
    country: 'US',
    city: 'Marathon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.9291,
    longitude: -89.8404,
    postal_code: '54448'
  },
  '54449': {
    country: 'US',
    city: 'Marshfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.6615,
    longitude: -90.1784,
    postal_code: '54449'
  },
  '54450': {
    country: 'US',
    city: 'Mattoon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 45.0062,
    longitude: -89.0474,
    postal_code: '54450'
  },
  '54451': {
    country: 'US',
    city: 'Medford',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Taylor',
    latitude: 45.1512,
    longitude: -90.3503,
    postal_code: '54451'
  },
  '54452': {
    country: 'US',
    city: 'Merrill',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lincoln',
    latitude: 45.1809,
    longitude: -89.6907,
    postal_code: '54452'
  },
  '54454': {
    country: 'US',
    city: 'Milladore',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.6044,
    longitude: -89.8875,
    postal_code: '54454'
  },
  '54455': {
    country: 'US',
    city: 'Mosinee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.7997,
    longitude: -89.6845,
    postal_code: '54455'
  },
  '54456': {
    country: 'US',
    city: 'Neillsville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.5494,
    longitude: -90.6112,
    postal_code: '54456'
  },
  '54457': {
    country: 'US',
    city: 'Nekoosa',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.282,
    longitude: -89.8814,
    postal_code: '54457'
  },
  '54458': {
    country: 'US',
    city: 'Nelsonville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.495,
    longitude: -89.3098,
    postal_code: '54458'
  },
  '54459': {
    country: 'US',
    city: 'Ogema',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Price',
    latitude: 45.4392,
    longitude: -90.2565,
    postal_code: '54459'
  },
  '54460': {
    country: 'US',
    city: 'Owen',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.9578,
    longitude: -90.5469,
    postal_code: '54460'
  },
  '54462': {
    country: 'US',
    city: 'Pearson',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Langlade',
    latitude: 45.3641,
    longitude: -89.0165,
    postal_code: '54462'
  },
  '54463': {
    country: 'US',
    city: 'Pelican Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oneida',
    latitude: 45.5049,
    longitude: -89.1817,
    postal_code: '54463'
  },
  '54464': {
    country: 'US',
    city: 'Phlox',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Langlade',
    latitude: 45.0511,
    longitude: -89.0143,
    postal_code: '54464'
  },
  '54465': {
    country: 'US',
    city: 'Pickerel',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Langlade',
    latitude: 45.3567,
    longitude: -88.9137,
    postal_code: '54465'
  },
  '54466': {
    country: 'US',
    city: 'Pittsville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.4375,
    longitude: -90.1892,
    postal_code: '54466'
  },
  '54467': {
    country: 'US',
    city: 'Plover',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.4507,
    longitude: -89.5428,
    postal_code: '54467'
  },
  '54469': {
    country: 'US',
    city: 'Port Edwards',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.3497,
    longitude: -89.8652,
    postal_code: '54469'
  },
  '54470': {
    country: 'US',
    city: 'Rib Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Taylor',
    latitude: 45.2984,
    longitude: -90.1763,
    postal_code: '54470'
  },
  '54471': {
    country: 'US',
    city: 'Ringle',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.9238,
    longitude: -89.4328,
    postal_code: '54471'
  },
  '54472': {
    country: 'US',
    city: 'Marshfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.6689,
    longitude: -90.1718,
    postal_code: '54472'
  },
  '54473': {
    country: 'US',
    city: 'Rosholt',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.6622,
    longitude: -89.3356,
    postal_code: '54473'
  },
  '54474': {
    country: 'US',
    city: 'Rothschild',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.8843,
    longitude: -89.6193,
    postal_code: '54474'
  },
  '54475': {
    country: 'US',
    city: 'Rudolph',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.4741,
    longitude: -89.8003,
    postal_code: '54475'
  },
  '54476': {
    country: 'US',
    city: 'Schofield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.9064,
    longitude: -89.5818,
    postal_code: '54476'
  },
  '54479': {
    country: 'US',
    city: 'Spencer',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.7391,
    longitude: -90.3114,
    postal_code: '54479'
  },
  '54480': {
    country: 'US',
    city: 'Stetsonville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Taylor',
    latitude: 45.0792,
    longitude: -90.2829,
    postal_code: '54480'
  },
  '54481': {
    country: 'US',
    city: 'Stevens Point',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.5212,
    longitude: -89.5588,
    postal_code: '54481'
  },
  '54482': {
    country: 'US',
    city: 'Stevens Point',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.5563,
    longitude: -89.5182,
    postal_code: '54482'
  },
  '54484': {
    country: 'US',
    city: 'Stratford',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.7899,
    longitude: -90.0583,
    postal_code: '54484'
  },
  '54485': {
    country: 'US',
    city: 'Summit Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Langlade',
    latitude: 45.3965,
    longitude: -89.2179,
    postal_code: '54485'
  },
  '54486': {
    country: 'US',
    city: 'Tigerton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.7299,
    longitude: -89.0569,
    postal_code: '54486'
  },
  '54487': {
    country: 'US',
    city: 'Tomahawk',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lincoln',
    latitude: 45.4963,
    longitude: -89.7273,
    postal_code: '54487'
  },
  '54488': {
    country: 'US',
    city: 'Unity',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marathon',
    latitude: 44.8227,
    longitude: -90.3221,
    postal_code: '54488'
  },
  '54489': {
    country: 'US',
    city: 'Vesper',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.4677,
    longitude: -89.9826,
    postal_code: '54489'
  },
  '54490': {
    country: 'US',
    city: 'Westboro',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Taylor',
    latitude: 45.3445,
    longitude: -90.3072,
    postal_code: '54490'
  },
  '54491': {
    country: 'US',
    city: 'White Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Langlade',
    latitude: 45.1715,
    longitude: -88.7555,
    postal_code: '54491'
  },
  '54492': {
    country: 'US',
    city: 'Stevens Point',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.5094,
    longitude: -89.5286,
    postal_code: '54492'
  },
  '54493': {
    country: 'US',
    city: 'Willard',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.7274,
    longitude: -90.7499,
    postal_code: '54493'
  },
  '54494': {
    country: 'US',
    city: 'Wisconsin Rapids',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.3758,
    longitude: -89.8062,
    postal_code: '54494'
  },
  '54495': {
    country: 'US',
    city: 'Wisconsin Rapids',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Wood',
    latitude: 44.3881,
    longitude: -89.9228,
    postal_code: '54495'
  },
  '54498': {
    country: 'US',
    city: 'Withee',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.9757,
    longitude: -90.6049,
    postal_code: '54498'
  },
  '54499': {
    country: 'US',
    city: 'Wittenberg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.8232,
    longitude: -89.1665,
    postal_code: '54499'
  },
  '54501': {
    country: 'US',
    city: 'Rhinelander',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oneida',
    latitude: 45.7045,
    longitude: -89.3866,
    postal_code: '54501'
  },
  '54511': {
    country: 'US',
    city: 'Argonne',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Forest',
    latitude: 45.7118,
    longitude: -88.8104,
    postal_code: '54511'
  },
  '54512': {
    country: 'US',
    city: 'Boulder Junction',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 46.1112,
    longitude: -89.6325,
    postal_code: '54512'
  },
  '54513': {
    country: 'US',
    city: 'Brantwood',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Price',
    latitude: 45.5514,
    longitude: -90.116,
    postal_code: '54513'
  },
  '54514': {
    country: 'US',
    city: 'Butternut',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ashland',
    latitude: 46.0184,
    longitude: -90.4821,
    postal_code: '54514'
  },
  '54515': {
    country: 'US',
    city: 'Catawba',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Price',
    latitude: 45.5081,
    longitude: -90.5146,
    postal_code: '54515'
  },
  '54517': {
    country: 'US',
    city: 'Clam Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ashland',
    latitude: 46.1638,
    longitude: -90.9024,
    postal_code: '54517'
  },
  '54519': {
    country: 'US',
    city: 'Conover',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 46.044,
    longitude: -89.2389,
    postal_code: '54519'
  },
  '54520': {
    country: 'US',
    city: 'Crandon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Forest',
    latitude: 45.5416,
    longitude: -88.9116,
    postal_code: '54520'
  },
  '54521': {
    country: 'US',
    city: 'Eagle River',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 45.9161,
    longitude: -89.2531,
    postal_code: '54521'
  },
  '54524': {
    country: 'US',
    city: 'Fifield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Price',
    latitude: 45.8143,
    longitude: -90.453,
    postal_code: '54524'
  },
  '54525': {
    country: 'US',
    city: 'Gile',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iron',
    latitude: 46.4283,
    longitude: -90.2252,
    postal_code: '54525'
  },
  '54526': {
    country: 'US',
    city: 'Glen Flora',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rusk',
    latitude: 45.4862,
    longitude: -90.8479,
    postal_code: '54526'
  },
  '54527': {
    country: 'US',
    city: 'Glidden',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ashland',
    latitude: 46.1337,
    longitude: -90.5889,
    postal_code: '54527'
  },
  '54529': {
    country: 'US',
    city: 'Harshaw',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oneida',
    latitude: 45.6762,
    longitude: -89.6503,
    postal_code: '54529'
  },
  '54530': {
    country: 'US',
    city: 'Hawkins',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rusk',
    latitude: 45.5241,
    longitude: -90.7114,
    postal_code: '54530'
  },
  '54531': {
    country: 'US',
    city: 'Hazelhurst',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oneida',
    latitude: 45.7745,
    longitude: -89.7478,
    postal_code: '54531'
  },
  '54532': {
    country: 'US',
    city: 'Heafford Junction',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Lincoln',
    latitude: 45.5472,
    longitude: -89.7154,
    postal_code: '54532'
  },
  '54534': {
    country: 'US',
    city: 'Hurley',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iron',
    latitude: 46.4448,
    longitude: -90.1975,
    postal_code: '54534'
  },
  '54536': {
    country: 'US',
    city: 'Iron Belt',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iron',
    latitude: 46.3955,
    longitude: -90.3245,
    postal_code: '54536'
  },
  '54537': {
    country: 'US',
    city: 'Kennan',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Price',
    latitude: 45.535,
    longitude: -90.591,
    postal_code: '54537'
  },
  '54538': {
    country: 'US',
    city: 'Lac Du Flambeau',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 45.9718,
    longitude: -89.8901,
    postal_code: '54538'
  },
  '54539': {
    country: 'US',
    city: 'Lake Tomahawk',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oneida',
    latitude: 45.8035,
    longitude: -89.5856,
    postal_code: '54539'
  },
  '54540': {
    country: 'US',
    city: 'Land O Lakes',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 46.1563,
    longitude: -89.3218,
    postal_code: '54540'
  },
  '54541': {
    country: 'US',
    city: 'Laona',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Forest',
    latitude: 45.5534,
    longitude: -88.6715,
    postal_code: '54541'
  },
  '54542': {
    country: 'US',
    city: 'Long Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Florence',
    latitude: 45.9138,
    longitude: -88.6235,
    postal_code: '54542'
  },
  '54543': {
    country: 'US',
    city: 'Mc Naughton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oneida',
    latitude: 45.7229,
    longitude: -89.5573,
    postal_code: '54543'
  },
  '54545': {
    country: 'US',
    city: 'Manitowish Waters',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 46.1551,
    longitude: -89.8455,
    postal_code: '54545'
  },
  '54546': {
    country: 'US',
    city: 'Mellen',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ashland',
    latitude: 46.3007,
    longitude: -90.6552,
    postal_code: '54546'
  },
  '54547': {
    country: 'US',
    city: 'Mercer',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iron',
    latitude: 46.1696,
    longitude: -90.0679,
    postal_code: '54547'
  },
  '54548': {
    country: 'US',
    city: 'Minocqua',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oneida',
    latitude: 45.8719,
    longitude: -89.758,
    postal_code: '54548'
  },
  '54550': {
    country: 'US',
    city: 'Montreal',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iron',
    latitude: 46.428,
    longitude: -90.246,
    postal_code: '54550'
  },
  '54552': {
    country: 'US',
    city: 'Park Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Price',
    latitude: 45.9363,
    longitude: -90.4246,
    postal_code: '54552'
  },
  '54554': {
    country: 'US',
    city: 'Phelps',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 46.0645,
    longitude: -89.0815,
    postal_code: '54554'
  },
  '54555': {
    country: 'US',
    city: 'Phillips',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Price',
    latitude: 45.6975,
    longitude: -90.4041,
    postal_code: '54555'
  },
  '54556': {
    country: 'US',
    city: 'Prentice',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Price',
    latitude: 45.5482,
    longitude: -90.2942,
    postal_code: '54556'
  },
  '54557': {
    country: 'US',
    city: 'Presque Isle',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 46.2,
    longitude: -89.7387,
    postal_code: '54557'
  },
  '54558': {
    country: 'US',
    city: 'Saint Germain',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 45.9183,
    longitude: -89.4866,
    postal_code: '54558'
  },
  '54559': {
    country: 'US',
    city: 'Saxon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iron',
    latitude: 46.4959,
    longitude: -90.4383,
    postal_code: '54559'
  },
  '54560': {
    country: 'US',
    city: 'Sayner',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 45.9867,
    longitude: -89.5194,
    postal_code: '54560'
  },
  '54561': {
    country: 'US',
    city: 'Star Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vilas',
    latitude: 46.0613,
    longitude: -89.4858,
    postal_code: '54561'
  },
  '54562': {
    country: 'US',
    city: 'Three Lakes',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oneida',
    latitude: 45.8046,
    longitude: -89.1328,
    postal_code: '54562'
  },
  '54563': {
    country: 'US',
    city: 'Tony',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rusk',
    latitude: 45.4741,
    longitude: -90.9869,
    postal_code: '54563'
  },
  '54564': {
    country: 'US',
    city: 'Tripoli',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oneida',
    latitude: 45.5814,
    longitude: -89.9852,
    postal_code: '54564'
  },
  '54565': {
    country: 'US',
    city: 'Upson',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Iron',
    latitude: 46.3761,
    longitude: -90.4051,
    postal_code: '54565'
  },
  '54566': {
    country: 'US',
    city: 'Wabeno',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Forest',
    latitude: 45.4332,
    longitude: -88.6625,
    postal_code: '54566'
  },
  '54568': {
    country: 'US',
    city: 'Woodruff',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Oneida',
    latitude: 45.9186,
    longitude: -89.6954,
    postal_code: '54568'
  },
  '54601': {
    country: 'US',
    city: 'La Crosse',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'La Crosse',
    latitude: 43.7989,
    longitude: -91.2175,
    postal_code: '54601'
  },
  '54602': {
    country: 'US',
    city: 'La Crosse',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'La Crosse',
    latitude: 43.9077,
    longitude: -91.1676,
    postal_code: '54602'
  },
  '54603': {
    country: 'US',
    city: 'La Crosse',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'La Crosse',
    latitude: 43.8487,
    longitude: -91.2484,
    postal_code: '54603'
  },
  '54610': {
    country: 'US',
    city: 'Alma',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Buffalo',
    latitude: 44.4294,
    longitude: -91.8079,
    postal_code: '54610'
  },
  '54611': {
    country: 'US',
    city: 'Alma Center',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jackson',
    latitude: 44.4452,
    longitude: -90.9356,
    postal_code: '54611'
  },
  '54612': {
    country: 'US',
    city: 'Arcadia',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.2539,
    longitude: -91.4805,
    postal_code: '54612'
  },
  '54613': {
    country: 'US',
    city: 'Arkdale',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Adams',
    latitude: 44.0529,
    longitude: -89.8962,
    postal_code: '54613'
  },
  '54614': {
    country: 'US',
    city: 'Bangor',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'La Crosse',
    latitude: 43.8687,
    longitude: -90.9809,
    postal_code: '54614'
  },
  '54615': {
    country: 'US',
    city: 'Black River Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jackson',
    latitude: 44.2954,
    longitude: -90.8313,
    postal_code: '54615'
  },
  '54616': {
    country: 'US',
    city: 'Blair',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.2968,
    longitude: -91.2235,
    postal_code: '54616'
  },
  '54618': {
    country: 'US',
    city: 'Camp Douglas',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Juneau',
    latitude: 43.9885,
    longitude: -90.2393,
    postal_code: '54618'
  },
  '54619': {
    country: 'US',
    city: 'Cashton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Monroe',
    latitude: 43.7456,
    longitude: -90.7612,
    postal_code: '54619'
  },
  '54620': {
    country: 'US',
    city: 'Cataract',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Monroe',
    latitude: 44.0876,
    longitude: -90.8423,
    postal_code: '54620'
  },
  '54621': {
    country: 'US',
    city: 'Chaseburg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.6794,
    longitude: -91.0799,
    postal_code: '54621'
  },
  '54622': {
    country: 'US',
    city: 'Cochrane',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Buffalo',
    latitude: 44.2672,
    longitude: -91.7715,
    postal_code: '54622'
  },
  '54623': {
    country: 'US',
    city: 'Coon Valley',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.7218,
    longitude: -91.0143,
    postal_code: '54623'
  },
  '54624': {
    country: 'US',
    city: 'De Soto',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.4807,
    longitude: -91.1333,
    postal_code: '54624'
  },
  '54625': {
    country: 'US',
    city: 'Dodge',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.1431,
    longitude: -91.5204,
    postal_code: '54625'
  },
  '54626': {
    country: 'US',
    city: 'Eastman',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Crawford',
    latitude: 43.1959,
    longitude: -91.0193,
    postal_code: '54626'
  },
  '54627': {
    country: 'US',
    city: 'Ettrick',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.1724,
    longitude: -91.2635,
    postal_code: '54627'
  },
  '54628': {
    country: 'US',
    city: 'Ferryville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Crawford',
    latitude: 43.3959,
    longitude: -91.0446,
    postal_code: '54628'
  },
  '54629': {
    country: 'US',
    city: 'Fountain City',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Buffalo',
    latitude: 44.1364,
    longitude: -91.6779,
    postal_code: '54629'
  },
  '54630': {
    country: 'US',
    city: 'Galesville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.0888,
    longitude: -91.3588,
    postal_code: '54630'
  },
  '54631': {
    country: 'US',
    city: 'Gays Mills',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Crawford',
    latitude: 43.2936,
    longitude: -90.8676,
    postal_code: '54631'
  },
  '54632': {
    country: 'US',
    city: 'Genoa',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.5641,
    longitude: -91.1461,
    postal_code: '54632'
  },
  '54634': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.5966,
    longitude: -90.4266,
    postal_code: '54634'
  },
  '54635': {
    country: 'US',
    city: 'Hixton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jackson',
    latitude: 44.4038,
    longitude: -91.0442,
    postal_code: '54635'
  },
  '54636': {
    country: 'US',
    city: 'Holmen',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'La Crosse',
    latitude: 43.9761,
    longitude: -91.2497,
    postal_code: '54636'
  },
  '54637': {
    country: 'US',
    city: 'Hustler',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Juneau',
    latitude: 43.8801,
    longitude: -90.2729,
    postal_code: '54637'
  },
  '54638': {
    country: 'US',
    city: 'Kendall',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Monroe',
    latitude: 43.7922,
    longitude: -90.3685,
    postal_code: '54638'
  },
  '54639': {
    country: 'US',
    city: 'La Farge',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.6,
    longitude: -90.6365,
    postal_code: '54639'
  },
  '54640': {
    country: 'US',
    city: 'Lynxville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Crawford',
    latitude: 43.2464,
    longitude: -91.0562,
    postal_code: '54640'
  },
  '54641': {
    country: 'US',
    city: 'Mather',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Juneau',
    latitude: 44.1427,
    longitude: -90.3087,
    postal_code: '54641'
  },
  '54642': {
    country: 'US',
    city: 'Melrose',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jackson',
    latitude: 44.1382,
    longitude: -91.0445,
    postal_code: '54642'
  },
  '54643': {
    country: 'US',
    city: 'Millston',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jackson',
    latitude: 44.1869,
    longitude: -90.6358,
    postal_code: '54643'
  },
  '54644': {
    country: 'US',
    city: 'Mindoro',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'La Crosse',
    latitude: 44.0287,
    longitude: -91.0642,
    postal_code: '54644'
  },
  '54645': {
    country: 'US',
    city: 'Mount Sterling',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Crawford',
    latitude: 43.3153,
    longitude: -90.9287,
    postal_code: '54645'
  },
  '54646': {
    country: 'US',
    city: 'Necedah',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Juneau',
    latitude: 44.0345,
    longitude: -90.06,
    postal_code: '54646'
  },
  '54648': {
    country: 'US',
    city: 'Norwalk',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Monroe',
    latitude: 43.8128,
    longitude: -90.6801,
    postal_code: '54648'
  },
  '54649': {
    country: 'US',
    city: 'Oakdale',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Monroe',
    latitude: 43.9715,
    longitude: -90.3612,
    postal_code: '54649'
  },
  '54650': {
    country: 'US',
    city: 'Onalaska',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'La Crosse',
    latitude: 43.8974,
    longitude: -91.2314,
    postal_code: '54650'
  },
  '54651': {
    country: 'US',
    city: 'Ontario',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.7172,
    longitude: -90.6205,
    postal_code: '54651'
  },
  '54652': {
    country: 'US',
    city: 'Readstown',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.4508,
    longitude: -90.7579,
    postal_code: '54652'
  },
  '54653': {
    country: 'US',
    city: 'Rockland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'La Crosse',
    latitude: 43.9064,
    longitude: -90.9193,
    postal_code: '54653'
  },
  '54654': {
    country: 'US',
    city: 'Seneca',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Crawford',
    latitude: 43.265,
    longitude: -90.9585,
    postal_code: '54654'
  },
  '54655': {
    country: 'US',
    city: 'Soldiers Grove',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Crawford',
    latitude: 43.3919,
    longitude: -90.7663,
    postal_code: '54655'
  },
  '54656': {
    country: 'US',
    city: 'Sparta',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Monroe',
    latitude: 43.954,
    longitude: -90.8146,
    postal_code: '54656'
  },
  '54657': {
    country: 'US',
    city: 'Steuben',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Crawford',
    latitude: 43.1955,
    longitude: -90.8609,
    postal_code: '54657'
  },
  '54658': {
    country: 'US',
    city: 'Stoddard',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.6912,
    longitude: -91.1919,
    postal_code: '54658'
  },
  '54659': {
    country: 'US',
    city: 'Taylor',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jackson',
    latitude: 44.2899,
    longitude: -91.1128,
    postal_code: '54659'
  },
  '54660': {
    country: 'US',
    city: 'Tomah',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Monroe',
    latitude: 43.9838,
    longitude: -90.4733,
    postal_code: '54660'
  },
  '54661': {
    country: 'US',
    city: 'Trempealeau',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.0218,
    longitude: -91.4282,
    postal_code: '54661'
  },
  '54662': {
    country: 'US',
    city: 'Tunnel City',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Monroe',
    latitude: 44.0031,
    longitude: -90.562,
    postal_code: '54662'
  },
  '54664': {
    country: 'US',
    city: 'Viola',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.5048,
    longitude: -90.6554,
    postal_code: '54664'
  },
  '54665': {
    country: 'US',
    city: 'Viroqua',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.5442,
    longitude: -90.8939,
    postal_code: '54665'
  },
  '54666': {
    country: 'US',
    city: 'Warrens',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Monroe',
    latitude: 44.157,
    longitude: -90.4967,
    postal_code: '54666'
  },
  '54667': {
    country: 'US',
    city: 'Westby',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Vernon',
    latitude: 43.6631,
    longitude: -90.874,
    postal_code: '54667'
  },
  '54669': {
    country: 'US',
    city: 'West Salem',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'La Crosse',
    latitude: 43.9063,
    longitude: -91.0893,
    postal_code: '54669'
  },
  '54670': {
    country: 'US',
    city: 'Wilton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Monroe',
    latitude: 43.8322,
    longitude: -90.5162,
    postal_code: '54670'
  },
  '54701': {
    country: 'US',
    city: 'Eau Claire',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Eau Claire',
    latitude: 44.784,
    longitude: -91.4877,
    postal_code: '54701'
  },
  '54702': {
    country: 'US',
    city: 'Eau Claire',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Eau Claire',
    latitude: 44.8113,
    longitude: -91.4985,
    postal_code: '54702'
  },
  '54703': {
    country: 'US',
    city: 'Eau Claire',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Eau Claire',
    latitude: 44.8346,
    longitude: -91.5159,
    postal_code: '54703'
  },
  '54720': {
    country: 'US',
    city: 'Altoona',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Eau Claire',
    latitude: 44.8021,
    longitude: -91.4382,
    postal_code: '54720'
  },
  '54721': {
    country: 'US',
    city: 'Arkansaw',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pepin',
    latitude: 44.6267,
    longitude: -92.0566,
    postal_code: '54721'
  },
  '54722': {
    country: 'US',
    city: 'Augusta',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Eau Claire',
    latitude: 44.6947,
    longitude: -91.1232,
    postal_code: '54722'
  },
  '54723': {
    country: 'US',
    city: 'Bay City',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.6166,
    longitude: -92.4469,
    postal_code: '54723'
  },
  '54724': {
    country: 'US',
    city: 'Bloomer',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Chippewa',
    latitude: 45.1025,
    longitude: -91.49,
    postal_code: '54724'
  },
  '54725': {
    country: 'US',
    city: 'Boyceville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 45.0642,
    longitude: -92.0248,
    postal_code: '54725'
  },
  '54726': {
    country: 'US',
    city: 'Boyd',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Chippewa',
    latitude: 44.9437,
    longitude: -91.0294,
    postal_code: '54726'
  },
  '54727': {
    country: 'US',
    city: 'Cadott',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Chippewa',
    latitude: 44.963,
    longitude: -91.1699,
    postal_code: '54727'
  },
  '54728': {
    country: 'US',
    city: 'Chetek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.317,
    longitude: -91.6542,
    postal_code: '54728'
  },
  '54729': {
    country: 'US',
    city: 'Chippewa Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Chippewa',
    latitude: 44.9369,
    longitude: -91.3929,
    postal_code: '54729'
  },
  '54730': {
    country: 'US',
    city: 'Colfax',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 44.9993,
    longitude: -91.7357,
    postal_code: '54730'
  },
  '54731': {
    country: 'US',
    city: 'Conrath',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rusk',
    latitude: 45.3533,
    longitude: -91.0621,
    postal_code: '54731'
  },
  '54732': {
    country: 'US',
    city: 'Cornell',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Chippewa',
    latitude: 45.1619,
    longitude: -91.1733,
    postal_code: '54732'
  },
  '54733': {
    country: 'US',
    city: 'Dallas',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.2549,
    longitude: -91.8368,
    postal_code: '54733'
  },
  '54734': {
    country: 'US',
    city: 'Downing',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 45.103,
    longitude: -92.1134,
    postal_code: '54734'
  },
  '54735': {
    country: 'US',
    city: 'Downsville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 44.7747,
    longitude: -91.9321,
    postal_code: '54735'
  },
  '54736': {
    country: 'US',
    city: 'Durand',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pepin',
    latitude: 44.63,
    longitude: -91.9295,
    postal_code: '54736'
  },
  '54737': {
    country: 'US',
    city: 'Eau Galle',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 44.7152,
    longitude: -91.9822,
    postal_code: '54737'
  },
  '54738': {
    country: 'US',
    city: 'Eleva',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.5985,
    longitude: -91.4804,
    postal_code: '54738'
  },
  '54739': {
    country: 'US',
    city: 'Elk Mound',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 44.867,
    longitude: -91.6752,
    postal_code: '54739'
  },
  '54740': {
    country: 'US',
    city: 'Elmwood',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.7561,
    longitude: -92.2022,
    postal_code: '54740'
  },
  '54741': {
    country: 'US',
    city: 'Fairchild',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Eau Claire',
    latitude: 44.5963,
    longitude: -90.9906,
    postal_code: '54741'
  },
  '54742': {
    country: 'US',
    city: 'Fall Creek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Eau Claire',
    latitude: 44.7684,
    longitude: -91.2856,
    postal_code: '54742'
  },
  '54743': {
    country: 'US',
    city: 'Gilmanton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Buffalo',
    latitude: 44.4708,
    longitude: -91.676,
    postal_code: '54743'
  },
  '54745': {
    country: 'US',
    city: 'Holcombe',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Chippewa',
    latitude: 45.2513,
    longitude: -91.133,
    postal_code: '54745'
  },
  '54746': {
    country: 'US',
    city: 'Humbird',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.5367,
    longitude: -90.8883,
    postal_code: '54746'
  },
  '54747': {
    country: 'US',
    city: 'Independence',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.3959,
    longitude: -91.4535,
    postal_code: '54747'
  },
  '54748': {
    country: 'US',
    city: 'Jim Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Chippewa',
    latitude: 45.0889,
    longitude: -91.2568,
    postal_code: '54748'
  },
  '54749': {
    country: 'US',
    city: 'Knapp',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 44.9545,
    longitude: -92.0752,
    postal_code: '54749'
  },
  '54750': {
    country: 'US',
    city: 'Maiden Rock',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.6086,
    longitude: -92.2782,
    postal_code: '54750'
  },
  '54751': {
    country: 'US',
    city: 'Menomonie',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 44.8718,
    longitude: -91.9265,
    postal_code: '54751'
  },
  '54754': {
    country: 'US',
    city: 'Merrillan',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Jackson',
    latitude: 44.4369,
    longitude: -90.8105,
    postal_code: '54754'
  },
  '54755': {
    country: 'US',
    city: 'Mondovi',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Buffalo',
    latitude: 44.4877,
    longitude: -91.6807,
    postal_code: '54755'
  },
  '54756': {
    country: 'US',
    city: 'Nelson',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Buffalo',
    latitude: 44.4295,
    longitude: -92.0007,
    postal_code: '54756'
  },
  '54757': {
    country: 'US',
    city: 'New Auburn',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Chippewa',
    latitude: 45.2356,
    longitude: -91.5197,
    postal_code: '54757'
  },
  '54758': {
    country: 'US',
    city: 'Osseo',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.5599,
    longitude: -91.2191,
    postal_code: '54758'
  },
  '54759': {
    country: 'US',
    city: 'Pepin',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pepin',
    latitude: 44.4587,
    longitude: -92.1394,
    postal_code: '54759'
  },
  '54760': {
    country: 'US',
    city: 'Pigeon Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.4249,
    longitude: -91.2074,
    postal_code: '54760'
  },
  '54761': {
    country: 'US',
    city: 'Plum City',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.636,
    longitude: -92.1837,
    postal_code: '54761'
  },
  '54762': {
    country: 'US',
    city: 'Prairie Farm',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.2462,
    longitude: -91.9742,
    postal_code: '54762'
  },
  '54763': {
    country: 'US',
    city: 'Ridgeland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 45.1868,
    longitude: -91.879,
    postal_code: '54763'
  },
  '54764': {
    country: 'US',
    city: 'Rock Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 44.7186,
    longitude: -91.6899,
    postal_code: '54764'
  },
  '54765': {
    country: 'US',
    city: 'Sand Creek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 45.1669,
    longitude: -91.6863,
    postal_code: '54765'
  },
  '54766': {
    country: 'US',
    city: 'Sheldon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rusk',
    latitude: 45.3182,
    longitude: -90.9141,
    postal_code: '54766'
  },
  '54767': {
    country: 'US',
    city: 'Spring Valley',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pierce',
    latitude: 44.8356,
    longitude: -92.2906,
    postal_code: '54767'
  },
  '54768': {
    country: 'US',
    city: 'Stanley',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Chippewa',
    latitude: 44.9689,
    longitude: -90.9389,
    postal_code: '54768'
  },
  '54769': {
    country: 'US',
    city: 'Stockholm',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Pepin',
    latitude: 44.5084,
    longitude: -92.2326,
    postal_code: '54769'
  },
  '54770': {
    country: 'US',
    city: 'Strum',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.5674,
    longitude: -91.3833,
    postal_code: '54770'
  },
  '54771': {
    country: 'US',
    city: 'Thorp',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Clark',
    latitude: 44.9573,
    longitude: -90.8029,
    postal_code: '54771'
  },
  '54772': {
    country: 'US',
    city: 'Wheeler',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Dunn',
    latitude: 45.0719,
    longitude: -91.8871,
    postal_code: '54772'
  },
  '54773': {
    country: 'US',
    city: 'Whitehall',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Trempealeau',
    latitude: 44.3827,
    longitude: -91.303,
    postal_code: '54773'
  },
  '54774': {
    country: 'US',
    city: 'Chippewa Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Chippewa',
    latitude: 44.9369,
    longitude: -91.3929,
    postal_code: '54774'
  },
  '54801': {
    country: 'US',
    city: 'Spooner',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washburn',
    latitude: 45.8505,
    longitude: -91.9155,
    postal_code: '54801'
  },
  '54805': {
    country: 'US',
    city: 'Almena',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.4195,
    longitude: -92.0027,
    postal_code: '54805'
  },
  '54806': {
    country: 'US',
    city: 'Ashland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ashland',
    latitude: 46.5586,
    longitude: -90.7388,
    postal_code: '54806'
  },
  '54810': {
    country: 'US',
    city: 'Balsam Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.4588,
    longitude: -92.415,
    postal_code: '54810'
  },
  '54812': {
    country: 'US',
    city: 'Barron',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.4005,
    longitude: -91.85,
    postal_code: '54812'
  },
  '54813': {
    country: 'US',
    city: 'Barronett',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.619,
    longitude: -92.0196,
    postal_code: '54813'
  },
  '54814': {
    country: 'US',
    city: 'Bayfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.8354,
    longitude: -90.8217,
    postal_code: '54814'
  },
  '54816': {
    country: 'US',
    city: 'Benoit',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.5019,
    longitude: -91.0769,
    postal_code: '54816'
  },
  '54817': {
    country: 'US',
    city: 'Birchwood',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washburn',
    latitude: 45.6403,
    longitude: -91.5776,
    postal_code: '54817'
  },
  '54818': {
    country: 'US',
    city: 'Brill',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.6027,
    longitude: -91.6721,
    postal_code: '54818'
  },
  '54819': {
    country: 'US',
    city: 'Bruce',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rusk',
    latitude: 45.4479,
    longitude: -91.2906,
    postal_code: '54819'
  },
  '54820': {
    country: 'US',
    city: 'Brule',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.5763,
    longitude: -91.5539,
    postal_code: '54820'
  },
  '54821': {
    country: 'US',
    city: 'Cable',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.2172,
    longitude: -91.2244,
    postal_code: '54821'
  },
  '54822': {
    country: 'US',
    city: 'Cameron',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.4038,
    longitude: -91.731,
    postal_code: '54822'
  },
  '54824': {
    country: 'US',
    city: 'Centuria',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.4484,
    longitude: -92.5477,
    postal_code: '54824'
  },
  '54826': {
    country: 'US',
    city: 'Comstock',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.4911,
    longitude: -92.086,
    postal_code: '54826'
  },
  '54827': {
    country: 'US',
    city: 'Cornucopia',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.8372,
    longitude: -91.0974,
    postal_code: '54827'
  },
  '54828': {
    country: 'US',
    city: 'Couderay',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sawyer',
    latitude: 45.838,
    longitude: -91.2918,
    postal_code: '54828'
  },
  '54829': {
    country: 'US',
    city: 'Cumberland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.5403,
    longitude: -92.0297,
    postal_code: '54829'
  },
  '54830': {
    country: 'US',
    city: 'Danbury',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Burnett',
    latitude: 45.9726,
    longitude: -92.293,
    postal_code: '54830'
  },
  '54832': {
    country: 'US',
    city: 'Drummond',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.3119,
    longitude: -91.2856,
    postal_code: '54832'
  },
  '54834': {
    country: 'US',
    city: 'Edgewater',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sawyer',
    latitude: 45.7424,
    longitude: -91.4765,
    postal_code: '54834'
  },
  '54835': {
    country: 'US',
    city: 'Exeland',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sawyer',
    latitude: 45.6753,
    longitude: -91.2238,
    postal_code: '54835'
  },
  '54836': {
    country: 'US',
    city: 'Foxboro',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.4875,
    longitude: -92.1493,
    postal_code: '54836'
  },
  '54837': {
    country: 'US',
    city: 'Frederic',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.6729,
    longitude: -92.3545,
    postal_code: '54837'
  },
  '54838': {
    country: 'US',
    city: 'Gordon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.2336,
    longitude: -91.8033,
    postal_code: '54838'
  },
  '54839': {
    country: 'US',
    city: 'Grand View',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.3577,
    longitude: -91.1075,
    postal_code: '54839'
  },
  '54840': {
    country: 'US',
    city: 'Grantsburg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Burnett',
    latitude: 45.7953,
    longitude: -92.6935,
    postal_code: '54840'
  },
  '54841': {
    country: 'US',
    city: 'Haugen',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.6067,
    longitude: -91.7786,
    postal_code: '54841'
  },
  '54842': {
    country: 'US',
    city: 'Hawthorne',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.503,
    longitude: -91.8607,
    postal_code: '54842'
  },
  '54843': {
    country: 'US',
    city: 'Hayward',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sawyer',
    latitude: 45.9552,
    longitude: -91.2783,
    postal_code: '54843'
  },
  '54844': {
    country: 'US',
    city: 'Herbster',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.8213,
    longitude: -91.2332,
    postal_code: '54844'
  },
  '54845': {
    country: 'US',
    city: 'Hertel',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Burnett',
    latitude: 45.8076,
    longitude: -92.1404,
    postal_code: '54845'
  },
  '54846': {
    country: 'US',
    city: 'High Bridge',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ashland',
    latitude: 46.3796,
    longitude: -90.7383,
    postal_code: '54846'
  },
  '54847': {
    country: 'US',
    city: 'Iron River',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.579,
    longitude: -91.4211,
    postal_code: '54847'
  },
  '54848': {
    country: 'US',
    city: 'Ladysmith',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rusk',
    latitude: 45.4719,
    longitude: -91.1088,
    postal_code: '54848'
  },
  '54849': {
    country: 'US',
    city: 'Lake Nebagamon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.5023,
    longitude: -91.7402,
    postal_code: '54849'
  },
  '54850': {
    country: 'US',
    city: 'La Pointe',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ashland',
    latitude: 46.8467,
    longitude: -90.6036,
    postal_code: '54850'
  },
  '54853': {
    country: 'US',
    city: 'Luck',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.5568,
    longitude: -92.4164,
    postal_code: '54853'
  },
  '54854': {
    country: 'US',
    city: 'Maple',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.6206,
    longitude: -91.702,
    postal_code: '54854'
  },
  '54855': {
    country: 'US',
    city: 'Marengo',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ashland',
    latitude: 46.4071,
    longitude: -90.84,
    postal_code: '54855'
  },
  '54856': {
    country: 'US',
    city: 'Mason',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.4399,
    longitude: -91.1318,
    postal_code: '54856'
  },
  '54857': {
    country: 'US',
    city: 'Mikana',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.5919,
    longitude: -91.6013,
    postal_code: '54857'
  },
  '54858': {
    country: 'US',
    city: 'Milltown',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Polk',
    latitude: 45.5271,
    longitude: -92.5017,
    postal_code: '54858'
  },
  '54859': {
    country: 'US',
    city: 'Minong',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washburn',
    latitude: 46.1044,
    longitude: -91.8506,
    postal_code: '54859'
  },
  '54861': {
    country: 'US',
    city: 'Odanah',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Ashland',
    latitude: 46.5608,
    longitude: -90.6197,
    postal_code: '54861'
  },
  '54862': {
    country: 'US',
    city: 'Ojibwa',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sawyer',
    latitude: 45.7854,
    longitude: -91.1109,
    postal_code: '54862'
  },
  '54864': {
    country: 'US',
    city: 'Poplar',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.5771,
    longitude: -91.8253,
    postal_code: '54864'
  },
  '54865': {
    country: 'US',
    city: 'Port Wing',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.762,
    longitude: -91.392,
    postal_code: '54865'
  },
  '54867': {
    country: 'US',
    city: 'Radisson',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sawyer',
    latitude: 45.7734,
    longitude: -91.2262,
    postal_code: '54867'
  },
  '54868': {
    country: 'US',
    city: 'Rice Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.5061,
    longitude: -91.7382,
    postal_code: '54868'
  },
  '54870': {
    country: 'US',
    city: 'Sarona',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washburn',
    latitude: 45.7085,
    longitude: -91.7621,
    postal_code: '54870'
  },
  '54871': {
    country: 'US',
    city: 'Shell Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washburn',
    latitude: 45.7536,
    longitude: -91.9606,
    postal_code: '54871'
  },
  '54872': {
    country: 'US',
    city: 'Siren',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Burnett',
    latitude: 45.7821,
    longitude: -92.3892,
    postal_code: '54872'
  },
  '54873': {
    country: 'US',
    city: 'Solon Springs',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.4173,
    longitude: -91.811,
    postal_code: '54873'
  },
  '54874': {
    country: 'US',
    city: 'South Range',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.5688,
    longitude: -91.9352,
    postal_code: '54874'
  },
  '54875': {
    country: 'US',
    city: 'Springbrook',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washburn',
    latitude: 45.9452,
    longitude: -91.6754,
    postal_code: '54875'
  },
  '54876': {
    country: 'US',
    city: 'Stone Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sawyer',
    latitude: 45.8337,
    longitude: -91.5093,
    postal_code: '54876'
  },
  '54880': {
    country: 'US',
    city: 'Superior',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.7016,
    longitude: -92.0912,
    postal_code: '54880'
  },
  '54888': {
    country: 'US',
    city: 'Trego',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Washburn',
    latitude: 45.9516,
    longitude: -91.8581,
    postal_code: '54888'
  },
  '54889': {
    country: 'US',
    city: 'Turtle Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Barron',
    latitude: 45.4213,
    longitude: -92.1781,
    postal_code: '54889'
  },
  '54890': {
    country: 'US',
    city: 'Wascott',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Douglas',
    latitude: 46.1724,
    longitude: -91.7982,
    postal_code: '54890'
  },
  '54891': {
    country: 'US',
    city: 'Washburn',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Bayfield',
    latitude: 46.6806,
    longitude: -90.9095,
    postal_code: '54891'
  },
  '54893': {
    country: 'US',
    city: 'Webster',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Burnett',
    latitude: 45.874,
    longitude: -92.3227,
    postal_code: '54893'
  },
  '54895': {
    country: 'US',
    city: 'Weyerhaeuser',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Rusk',
    latitude: 45.4263,
    longitude: -91.4289,
    postal_code: '54895'
  },
  '54896': {
    country: 'US',
    city: 'Winter',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Sawyer',
    latitude: 45.8327,
    longitude: -91.0144,
    postal_code: '54896'
  },
  '54901': {
    country: 'US',
    city: 'Oshkosh',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.022,
    longitude: -88.5436,
    postal_code: '54901'
  },
  '54902': {
    country: 'US',
    city: 'Oshkosh',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 43.9946,
    longitude: -88.526,
    postal_code: '54902'
  },
  '54903': {
    country: 'US',
    city: 'Oshkosh',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.0686,
    longitude: -88.6449,
    postal_code: '54903'
  },
  '54904': {
    country: 'US',
    city: 'Oshkosh',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.0304,
    longitude: -88.607,
    postal_code: '54904'
  },
  '54906': {
    country: 'US',
    city: 'Oshkosh',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.0686,
    longitude: -88.6449,
    postal_code: '54906'
  },
  '54909': {
    country: 'US',
    city: 'Almond',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.2898,
    longitude: -89.3584,
    postal_code: '54909'
  },
  '54911': {
    country: 'US',
    city: 'Appleton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.2773,
    longitude: -88.3976,
    postal_code: '54911'
  },
  '54912': {
    country: 'US',
    city: 'Appleton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.4163,
    longitude: -88.4649,
    postal_code: '54912'
  },
  '54913': {
    country: 'US',
    city: 'Appleton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.3456,
    longitude: -88.4343,
    postal_code: '54913'
  },
  '54914': {
    country: 'US',
    city: 'Appleton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.271,
    longitude: -88.4326,
    postal_code: '54914'
  },
  '54915': {
    country: 'US',
    city: 'Appleton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.2425,
    longitude: -88.3564,
    postal_code: '54915'
  },
  '54919': {
    country: 'US',
    city: 'Appleton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.4163,
    longitude: -88.4649,
    postal_code: '54919'
  },
  '54921': {
    country: 'US',
    city: 'Bancroft',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Portage',
    latitude: 44.3065,
    longitude: -89.5304,
    postal_code: '54921'
  },
  '54922': {
    country: 'US',
    city: 'Bear Creek',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.5348,
    longitude: -88.7404,
    postal_code: '54922'
  },
  '54923': {
    country: 'US',
    city: 'Berlin',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green Lake',
    latitude: 43.9769,
    longitude: -88.949,
    postal_code: '54923'
  },
  '54926': {
    country: 'US',
    city: 'Big Falls',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.6178,
    longitude: -89.0166,
    postal_code: '54926'
  },
  '54927': {
    country: 'US',
    city: 'Butte Des Morts',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.1031,
    longitude: -88.6539,
    postal_code: '54927'
  },
  '54928': {
    country: 'US',
    city: 'Caroline',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.7276,
    longitude: -88.8887,
    postal_code: '54928'
  },
  '54929': {
    country: 'US',
    city: 'Clintonville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.6364,
    longitude: -88.743,
    postal_code: '54929'
  },
  '54930': {
    country: 'US',
    city: 'Coloma',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waushara',
    latitude: 44.0275,
    longitude: -89.4943,
    postal_code: '54930'
  },
  '54931': {
    country: 'US',
    city: 'Dale',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.273,
    longitude: -88.6784,
    postal_code: '54931'
  },
  '54932': {
    country: 'US',
    city: 'Eldorado',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.8415,
    longitude: -88.6388,
    postal_code: '54932'
  },
  '54933': {
    country: 'US',
    city: 'Embarrass',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.6686,
    longitude: -88.7043,
    postal_code: '54933'
  },
  '54934': {
    country: 'US',
    city: 'Eureka',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.0032,
    longitude: -88.8397,
    postal_code: '54934'
  },
  '54935': {
    country: 'US',
    city: 'Fond Du Lac',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.7742,
    longitude: -88.4352,
    postal_code: '54935'
  },
  '54936': {
    country: 'US',
    city: 'Fond Du Lac',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.775,
    longitude: -88.4388,
    postal_code: '54936'
  },
  '54937': {
    country: 'US',
    city: 'Fond Du Lac',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.765,
    longitude: -88.6057,
    postal_code: '54937'
  },
  '54940': {
    country: 'US',
    city: 'Fremont',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.2379,
    longitude: -88.8449,
    postal_code: '54940'
  },
  '54941': {
    country: 'US',
    city: 'Green Lake',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green Lake',
    latitude: 43.8444,
    longitude: -88.9685,
    postal_code: '54941'
  },
  '54942': {
    country: 'US',
    city: 'Greenville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.3003,
    longitude: -88.5365,
    postal_code: '54942'
  },
  '54943': {
    country: 'US',
    city: 'Hancock',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waushara',
    latitude: 44.1297,
    longitude: -89.5344,
    postal_code: '54943'
  },
  '54944': {
    country: 'US',
    city: 'Hortonville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Outagamie',
    latitude: 44.3273,
    longitude: -88.6281,
    postal_code: '54944'
  },
  '54945': {
    country: 'US',
    city: 'Iola',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.5322,
    longitude: -89.1373,
    postal_code: '54945'
  },
  '54946': {
    country: 'US',
    city: 'King',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.3365,
    longitude: -89.1463,
    postal_code: '54946'
  },
  '54947': {
    country: 'US',
    city: 'Larsen',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.1984,
    longitude: -88.6963,
    postal_code: '54947'
  },
  '54948': {
    country: 'US',
    city: 'Leopolis',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.7812,
    longitude: -88.8722,
    postal_code: '54948'
  },
  '54949': {
    country: 'US',
    city: 'Manawa',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.4701,
    longitude: -88.9164,
    postal_code: '54949'
  },
  '54950': {
    country: 'US',
    city: 'Marion',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.6794,
    longitude: -88.8767,
    postal_code: '54950'
  },
  '54952': {
    country: 'US',
    city: 'Menasha',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.2112,
    longitude: -88.4175,
    postal_code: '54952'
  },
  '54956': {
    country: 'US',
    city: 'Neenah',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.1811,
    longitude: -88.4792,
    postal_code: '54956'
  },
  '54957': {
    country: 'US',
    city: 'Neenah',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.1858,
    longitude: -88.4626,
    postal_code: '54957'
  },
  '54960': {
    country: 'US',
    city: 'Neshkoro',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Marquette',
    latitude: 43.8989,
    longitude: -89.2336,
    postal_code: '54960'
  },
  '54961': {
    country: 'US',
    city: 'New London',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.3928,
    longitude: -88.7554,
    postal_code: '54961'
  },
  '54962': {
    country: 'US',
    city: 'Ogdensburg',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.4268,
    longitude: -88.9932,
    postal_code: '54962'
  },
  '54963': {
    country: 'US',
    city: 'Omro',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.0357,
    longitude: -88.7519,
    postal_code: '54963'
  },
  '54964': {
    country: 'US',
    city: 'Pickett',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 43.9009,
    longitude: -88.7135,
    postal_code: '54964'
  },
  '54965': {
    country: 'US',
    city: 'Pine River',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waushara',
    latitude: 44.1604,
    longitude: -89.0404,
    postal_code: '54965'
  },
  '54966': {
    country: 'US',
    city: 'Plainfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waushara',
    latitude: 44.2091,
    longitude: -89.4559,
    postal_code: '54966'
  },
  '54967': {
    country: 'US',
    city: 'Poy Sippi',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waushara',
    latitude: 44.131,
    longitude: -88.9836,
    postal_code: '54967'
  },
  '54968': {
    country: 'US',
    city: 'Princeton',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Green Lake',
    latitude: 43.8438,
    longitude: -89.1249,
    postal_code: '54968'
  },
  '54969': {
    country: 'US',
    city: 'Readfield',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.27,
    longitude: -88.7755,
    postal_code: '54969'
  },
  '54970': {
    country: 'US',
    city: 'Redgranite',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waushara',
    latitude: 44.0438,
    longitude: -89.0981,
    postal_code: '54970'
  },
  '54971': {
    country: 'US',
    city: 'Ripon',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.8454,
    longitude: -88.8424,
    postal_code: '54971'
  },
  '54974': {
    country: 'US',
    city: 'Rosendale',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.7889,
    longitude: -88.6417,
    postal_code: '54974'
  },
  '54976': {
    country: 'US',
    city: 'Saxeville',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waushara',
    latitude: 44.1761,
    longitude: -89.1146,
    postal_code: '54976'
  },
  '54977': {
    country: 'US',
    city: 'Scandinavia',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.4604,
    longitude: -89.165,
    postal_code: '54977'
  },
  '54978': {
    country: 'US',
    city: 'Tilleda',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Shawano',
    latitude: 44.796,
    longitude: -88.8984,
    postal_code: '54978'
  },
  '54979': {
    country: 'US',
    city: 'Van Dyne',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Fond du Lac',
    latitude: 43.8734,
    longitude: -88.51,
    postal_code: '54979'
  },
  '54980': {
    country: 'US',
    city: 'Waukau',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 43.9886,
    longitude: -88.7712,
    postal_code: '54980'
  },
  '54981': {
    country: 'US',
    city: 'Waupaca',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.3481,
    longitude: -89.1101,
    postal_code: '54981'
  },
  '54982': {
    country: 'US',
    city: 'Wautoma',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waushara',
    latitude: 44.0656,
    longitude: -89.2666,
    postal_code: '54982'
  },
  '54983': {
    country: 'US',
    city: 'Weyauwega',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.321,
    longitude: -88.941,
    postal_code: '54983'
  },
  '54984': {
    country: 'US',
    city: 'Wild Rose',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waushara',
    latitude: 44.1834,
    longitude: -89.1953,
    postal_code: '54984'
  },
  '54985': {
    country: 'US',
    city: 'Winnebago',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.0706,
    longitude: -88.5178,
    postal_code: '54985'
  },
  '54986': {
    country: 'US',
    city: 'Winneconne',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Winnebago',
    latitude: 44.1187,
    longitude: -88.7214,
    postal_code: '54986'
  },
  '54990': {
    country: 'US',
    city: 'Iola',
    state: 'Wisconsin',
    state_short: 'WI',
    county: 'Waupaca',
    latitude: 44.508,
    longitude: -89.1307,
    postal_code: '54990'
  },
  '55001': {
    country: 'US',
    city: 'Afton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 44.8697,
    longitude: -92.8234,
    postal_code: '55001'
  },
  '55002': {
    country: 'US',
    city: 'Almelund',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.5134,
    longitude: -92.8942,
    postal_code: '55002'
  },
  '55003': {
    country: 'US',
    city: 'Bayport',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 45.0214,
    longitude: -92.7844,
    postal_code: '55003'
  },
  '55005': {
    country: 'US',
    city: 'Bethel',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.3887,
    longitude: -93.2315,
    postal_code: '55005'
  },
  '55006': {
    country: 'US',
    city: 'Braham',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Isanti',
    latitude: 45.7175,
    longitude: -93.2037,
    postal_code: '55006'
  },
  '55007': {
    country: 'US',
    city: 'Brook Park',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 45.9474,
    longitude: -93.0736,
    postal_code: '55007'
  },
  '55008': {
    country: 'US',
    city: 'Cambridge',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Isanti',
    latitude: 45.5576,
    longitude: -93.2889,
    postal_code: '55008'
  },
  '55009': {
    country: 'US',
    city: 'Cannon Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Goodhue',
    latitude: 44.496,
    longitude: -92.864,
    postal_code: '55009'
  },
  '55010': {
    country: 'US',
    city: 'Castle Rock',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.5472,
    longitude: -93.1531,
    postal_code: '55010'
  },
  '55011': {
    country: 'US',
    city: 'Cedar',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.3414,
    longitude: -93.235,
    postal_code: '55011'
  },
  '55012': {
    country: 'US',
    city: 'Center City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.4482,
    longitude: -92.7894,
    postal_code: '55012'
  },
  '55013': {
    country: 'US',
    city: 'Chisago City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.3611,
    longitude: -92.8921,
    postal_code: '55013'
  },
  '55014': {
    country: 'US',
    city: 'Circle Pines',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.1528,
    longitude: -93.144,
    postal_code: '55014'
  },
  '55016': {
    country: 'US',
    city: 'Cottage Grove',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 44.8308,
    longitude: -92.9393,
    postal_code: '55016'
  },
  '55017': {
    country: 'US',
    city: 'Dalbo',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Isanti',
    latitude: 45.6787,
    longitude: -93.4419,
    postal_code: '55017'
  },
  '55018': {
    country: 'US',
    city: 'Dennison',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Goodhue',
    latitude: 44.4265,
    longitude: -92.9554,
    postal_code: '55018'
  },
  '55019': {
    country: 'US',
    city: 'Dundas',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rice',
    latitude: 44.3955,
    longitude: -93.2037,
    postal_code: '55019'
  },
  '55020': {
    country: 'US',
    city: 'Elko New Market',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Scott',
    latitude: 44.5647,
    longitude: -93.3269,
    postal_code: '55020'
  },
  '55021': {
    country: 'US',
    city: 'Faribault',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rice',
    latitude: 44.2945,
    longitude: -93.2818,
    postal_code: '55021'
  },
  '55024': {
    country: 'US',
    city: 'Farmington',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.6628,
    longitude: -93.1539,
    postal_code: '55024'
  },
  '55025': {
    country: 'US',
    city: 'Forest Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 45.2685,
    longitude: -92.9749,
    postal_code: '55025'
  },
  '55026': {
    country: 'US',
    city: 'Frontenac',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Goodhue',
    latitude: 44.52,
    longitude: -92.3582,
    postal_code: '55026'
  },
  '55027': {
    country: 'US',
    city: 'Goodhue',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Goodhue',
    latitude: 44.4022,
    longitude: -92.5717,
    postal_code: '55027'
  },
  '55029': {
    country: 'US',
    city: 'Grandy',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Isanti',
    latitude: 45.6352,
    longitude: -93.1952,
    postal_code: '55029'
  },
  '55030': {
    country: 'US',
    city: 'Grasston',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 45.8566,
    longitude: -93.0813,
    postal_code: '55030'
  },
  '55031': {
    country: 'US',
    city: 'Hampton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.6028,
    longitude: -92.9467,
    postal_code: '55031'
  },
  '55032': {
    country: 'US',
    city: 'Harris',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.5962,
    longitude: -93.0395,
    postal_code: '55032'
  },
  '55033': {
    country: 'US',
    city: 'Hastings',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.7129,
    longitude: -92.8637,
    postal_code: '55033'
  },
  '55036': {
    country: 'US',
    city: 'Henriette',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 45.8712,
    longitude: -93.1196,
    postal_code: '55036'
  },
  '55037': {
    country: 'US',
    city: 'Hinckley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 46.0189,
    longitude: -92.8993,
    postal_code: '55037'
  },
  '55038': {
    country: 'US',
    city: 'Hugo',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 45.1824,
    longitude: -92.9452,
    postal_code: '55038'
  },
  '55040': {
    country: 'US',
    city: 'Isanti',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Isanti',
    latitude: 45.4682,
    longitude: -93.2266,
    postal_code: '55040'
  },
  '55041': {
    country: 'US',
    city: 'Lake City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wabasha',
    latitude: 44.4305,
    longitude: -92.2838,
    postal_code: '55041'
  },
  '55042': {
    country: 'US',
    city: 'Lake Elmo',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 44.9946,
    longitude: -92.9056,
    postal_code: '55042'
  },
  '55043': {
    country: 'US',
    city: 'Lakeland',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 44.9394,
    longitude: -92.7716,
    postal_code: '55043'
  },
  '55044': {
    country: 'US',
    city: 'Lakeville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.6749,
    longitude: -93.2578,
    postal_code: '55044'
  },
  '55045': {
    country: 'US',
    city: 'Lindstrom',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.3873,
    longitude: -92.8421,
    postal_code: '55045'
  },
  '55046': {
    country: 'US',
    city: 'Lonsdale',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rice',
    latitude: 44.4477,
    longitude: -93.4252,
    postal_code: '55046'
  },
  '55047': {
    country: 'US',
    city: 'Marine On Saint Croix',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 45.1988,
    longitude: -92.8258,
    postal_code: '55047'
  },
  '55049': {
    country: 'US',
    city: 'Medford',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Steele',
    latitude: 44.1741,
    longitude: -93.2437,
    postal_code: '55049'
  },
  '55051': {
    country: 'US',
    city: 'Mora',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kanabec',
    latitude: 45.8958,
    longitude: -93.2942,
    postal_code: '55051'
  },
  '55052': {
    country: 'US',
    city: 'Morristown',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rice',
    latitude: 44.2342,
    longitude: -93.4525,
    postal_code: '55052'
  },
  '55053': {
    country: 'US',
    city: 'Nerstrand',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rice',
    latitude: 44.3538,
    longitude: -93.0855,
    postal_code: '55053'
  },
  '55054': {
    country: 'US',
    city: 'Elko New Market',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Scott',
    latitude: 44.5647,
    longitude: -93.3269,
    postal_code: '55054'
  },
  '55055': {
    country: 'US',
    city: 'Newport',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 44.8725,
    longitude: -92.9986,
    postal_code: '55055'
  },
  '55056': {
    country: 'US',
    city: 'North Branch',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.5114,
    longitude: -92.9802,
    postal_code: '55056'
  },
  '55057': {
    country: 'US',
    city: 'Northfield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rice',
    latitude: 44.4587,
    longitude: -93.1668,
    postal_code: '55057'
  },
  '55060': {
    country: 'US',
    city: 'Owatonna',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Steele',
    latitude: 44.0805,
    longitude: -93.2191,
    postal_code: '55060'
  },
  '55063': {
    country: 'US',
    city: 'Pine City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 45.8363,
    longitude: -92.9042,
    postal_code: '55063'
  },
  '55065': {
    country: 'US',
    city: 'Randolph',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.5274,
    longitude: -93.0196,
    postal_code: '55065'
  },
  '55066': {
    country: 'US',
    city: 'Red Wing',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Goodhue',
    latitude: 44.5528,
    longitude: -92.5486,
    postal_code: '55066'
  },
  '55067': {
    country: 'US',
    city: 'Rock Creek',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 45.7575,
    longitude: -92.9624,
    postal_code: '55067'
  },
  '55068': {
    country: 'US',
    city: 'Rosemount',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.7394,
    longitude: -93.1258,
    postal_code: '55068'
  },
  '55069': {
    country: 'US',
    city: 'Rush City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.6855,
    longitude: -92.9655,
    postal_code: '55069'
  },
  '55070': {
    country: 'US',
    city: 'Saint Francis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.3903,
    longitude: -93.3598,
    postal_code: '55070'
  },
  '55071': {
    country: 'US',
    city: 'Saint Paul Park',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 44.8344,
    longitude: -92.9873,
    postal_code: '55071'
  },
  '55072': {
    country: 'US',
    city: 'Sandstone',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 46.1325,
    longitude: -92.589,
    postal_code: '55072'
  },
  '55073': {
    country: 'US',
    city: 'Scandia',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 45.2697,
    longitude: -92.8292,
    postal_code: '55073'
  },
  '55074': {
    country: 'US',
    city: 'Shafer',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.3576,
    longitude: -92.7609,
    postal_code: '55074'
  },
  '55075': {
    country: 'US',
    city: 'South Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.8881,
    longitude: -93.046,
    postal_code: '55075'
  },
  '55076': {
    country: 'US',
    city: 'Inver Grove Heights',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.8288,
    longitude: -93.0391,
    postal_code: '55076'
  },
  '55077': {
    country: 'US',
    city: 'Inver Grove Heights',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.8283,
    longitude: -93.094,
    postal_code: '55077'
  },
  '55078': {
    country: 'US',
    city: 'Stacy',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.398,
    longitude: -92.9874,
    postal_code: '55078'
  },
  '55079': {
    country: 'US',
    city: 'Stacy',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.3975,
    longitude: -93.0177,
    postal_code: '55079'
  },
  '55080': {
    country: 'US',
    city: 'Stanchfield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Isanti',
    latitude: 45.6675,
    longitude: -93.2433,
    postal_code: '55080'
  },
  '55082': {
    country: 'US',
    city: 'Stillwater',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 45.0614,
    longitude: -92.8474,
    postal_code: '55082'
  },
  '55083': {
    country: 'US',
    city: 'Stillwater',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 45.021,
    longitude: -92.9837,
    postal_code: '55083'
  },
  '55084': {
    country: 'US',
    city: 'Taylors Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.4576,
    longitude: -92.733,
    postal_code: '55084'
  },
  '55085': {
    country: 'US',
    city: 'Vermillion',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.6748,
    longitude: -92.9683,
    postal_code: '55085'
  },
  '55087': {
    country: 'US',
    city: 'Warsaw',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rice',
    latitude: 44.2485,
    longitude: -93.3949,
    postal_code: '55087'
  },
  '55088': {
    country: 'US',
    city: 'Webster',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rice',
    latitude: 44.5003,
    longitude: -93.3826,
    postal_code: '55088'
  },
  '55089': {
    country: 'US',
    city: 'Welch',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Goodhue',
    latitude: 44.603,
    longitude: -92.7263,
    postal_code: '55089'
  },
  '55090': {
    country: 'US',
    city: 'Willernie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 45.0535,
    longitude: -92.957,
    postal_code: '55090'
  },
  '55092': {
    country: 'US',
    city: 'Wyoming',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chisago',
    latitude: 45.3364,
    longitude: -92.9675,
    postal_code: '55092'
  },
  '55101': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9512,
    longitude: -93.0902,
    postal_code: '55101'
  },
  '55102': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9372,
    longitude: -93.1209,
    postal_code: '55102'
  },
  '55103': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9608,
    longitude: -93.1216,
    postal_code: '55103'
  },
  '55104': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9532,
    longitude: -93.158,
    postal_code: '55104'
  },
  '55105': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9347,
    longitude: -93.1651,
    postal_code: '55105'
  },
  '55106': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9684,
    longitude: -93.0488,
    postal_code: '55106'
  },
  '55107': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9325,
    longitude: -93.088,
    postal_code: '55107'
  },
  '55108': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9806,
    longitude: -93.1771,
    postal_code: '55108'
  },
  '55109': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0132,
    longitude: -93.0297,
    postal_code: '55109'
  },
  '55110': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.08,
    longitude: -93.0223,
    postal_code: '55110'
  },
  '55111': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8828,
    longitude: -93.2007,
    postal_code: '55111'
  },
  '55112': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0788,
    longitude: -93.1872,
    postal_code: '55112'
  },
  '55113': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0139,
    longitude: -93.1571,
    postal_code: '55113'
  },
  '55114': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.968,
    longitude: -93.1981,
    postal_code: '55114'
  },
  '55115': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 45.071,
    longitude: -92.9391,
    postal_code: '55115'
  },
  '55116': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.914,
    longitude: -93.1727,
    postal_code: '55116'
  },
  '55117': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9995,
    longitude: -93.0969,
    postal_code: '55117'
  },
  '55118': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.8965,
    longitude: -93.1034,
    postal_code: '55118'
  },
  '55119': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9414,
    longitude: -93.0107,
    postal_code: '55119'
  },
  '55120': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.8704,
    longitude: -93.1434,
    postal_code: '55120'
  },
  '55121': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.8471,
    longitude: -93.1543,
    postal_code: '55121'
  },
  '55122': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.786,
    longitude: -93.2202,
    postal_code: '55122'
  },
  '55123': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.806,
    longitude: -93.1409,
    postal_code: '55123'
  },
  '55124': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.7497,
    longitude: -93.2029,
    postal_code: '55124'
  },
  '55125': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 44.9197,
    longitude: -92.9439,
    postal_code: '55125'
  },
  '55126': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0736,
    longitude: -93.138,
    postal_code: '55126'
  },
  '55127': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0803,
    longitude: -93.0875,
    postal_code: '55127'
  },
  '55128': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 44.9913,
    longitude: -92.9487,
    postal_code: '55128'
  },
  '55129': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Washington',
    latitude: 44.8985,
    longitude: -92.923,
    postal_code: '55129'
  },
  '55130': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.973,
    longitude: -93.0827,
    postal_code: '55130'
  },
  '55133': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0059,
    longitude: -93.1059,
    postal_code: '55133'
  },
  '55144': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0059,
    longitude: -93.1059,
    postal_code: '55144'
  },
  '55145': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9444,
    longitude: -93.0933,
    postal_code: '55145'
  },
  '55146': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9427,
    longitude: -93.0828,
    postal_code: '55146'
  },
  '55150': {
    country: 'US',
    city: 'Mendota',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.8858,
    longitude: -93.1612,
    postal_code: '55150'
  },
  '55155': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9522,
    longitude: -93.0955,
    postal_code: '55155'
  },
  '55164': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9909,
    longitude: -93.1066,
    postal_code: '55164'
  },
  '55165': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0059,
    longitude: -93.1059,
    postal_code: '55165'
  },
  '55170': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 44.9444,
    longitude: -93.0933,
    postal_code: '55170'
  },
  '55172': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0059,
    longitude: -93.1059,
    postal_code: '55172'
  },
  '55175': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0059,
    longitude: -93.1059,
    postal_code: '55175'
  },
  '55187': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0059,
    longitude: -93.1059,
    postal_code: '55187'
  },
  '55188': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Ramsey',
    latitude: 45.0059,
    longitude: -93.1059,
    postal_code: '55188'
  },
  '55301': {
    country: 'US',
    city: 'Albertville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.2534,
    longitude: -93.6469,
    postal_code: '55301'
  },
  '55302': {
    country: 'US',
    city: 'Annandale',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.2483,
    longitude: -94.1061,
    postal_code: '55302'
  },
  '55303': {
    country: 'US',
    city: 'Anoka',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.2825,
    longitude: -93.4186,
    postal_code: '55303'
  },
  '55304': {
    country: 'US',
    city: 'Andover',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.2377,
    longitude: -93.2724,
    postal_code: '55304'
  },
  '55305': {
    country: 'US',
    city: 'Hopkins',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9528,
    longitude: -93.4372,
    postal_code: '55305'
  },
  '55306': {
    country: 'US',
    city: 'Burnsville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.7307,
    longitude: -93.2921,
    postal_code: '55306'
  },
  '55307': {
    country: 'US',
    city: 'Arlington',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sibley',
    latitude: 44.6153,
    longitude: -94.0762,
    postal_code: '55307'
  },
  '55308': {
    country: 'US',
    city: 'Becker',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sherburne',
    latitude: 45.4365,
    longitude: -93.841,
    postal_code: '55308'
  },
  '55309': {
    country: 'US',
    city: 'Big Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sherburne',
    latitude: 45.3506,
    longitude: -93.7399,
    postal_code: '55309'
  },
  '55310': {
    country: 'US',
    city: 'Bird Island',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Renville',
    latitude: 44.7507,
    longitude: -94.8716,
    postal_code: '55310'
  },
  '55311': {
    country: 'US',
    city: 'Maple Grove',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0725,
    longitude: -93.4558,
    postal_code: '55311'
  },
  '55312': {
    country: 'US',
    city: 'Brownton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'McLeod',
    latitude: 44.7281,
    longitude: -94.3306,
    postal_code: '55312'
  },
  '55313': {
    country: 'US',
    city: 'Buffalo',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.1814,
    longitude: -93.8635,
    postal_code: '55313'
  },
  '55314': {
    country: 'US',
    city: 'Buffalo Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Renville',
    latitude: 44.7709,
    longitude: -94.5912,
    postal_code: '55314'
  },
  '55315': {
    country: 'US',
    city: 'Carver',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.7169,
    longitude: -93.6879,
    postal_code: '55315'
  },
  '55316': {
    country: 'US',
    city: 'Champlin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.17,
    longitude: -93.3819,
    postal_code: '55316'
  },
  '55317': {
    country: 'US',
    city: 'Chanhassen',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8679,
    longitude: -93.5359,
    postal_code: '55317'
  },
  '55318': {
    country: 'US',
    city: 'Chaska',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8061,
    longitude: -93.6083,
    postal_code: '55318'
  },
  '55319': {
    country: 'US',
    city: 'Clear Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sherburne',
    latitude: 45.4795,
    longitude: -93.9684,
    postal_code: '55319'
  },
  '55320': {
    country: 'US',
    city: 'Clearwater',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.3877,
    longitude: -94.0452,
    postal_code: '55320'
  },
  '55321': {
    country: 'US',
    city: 'Cokato',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.0748,
    longitude: -94.1911,
    postal_code: '55321'
  },
  '55322': {
    country: 'US',
    city: 'Cologne',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.7646,
    longitude: -93.7982,
    postal_code: '55322'
  },
  '55323': {
    country: 'US',
    city: 'Crystal Bay',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.953,
    longitude: -93.5763,
    postal_code: '55323'
  },
  '55324': {
    country: 'US',
    city: 'Darwin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Meeker',
    latitude: 45.1029,
    longitude: -94.4249,
    postal_code: '55324'
  },
  '55325': {
    country: 'US',
    city: 'Dassel',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Meeker',
    latitude: 45.1032,
    longitude: -94.3305,
    postal_code: '55325'
  },
  '55327': {
    country: 'US',
    city: 'Dayton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.1982,
    longitude: -93.4668,
    postal_code: '55327'
  },
  '55328': {
    country: 'US',
    city: 'Delano',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.0342,
    longitude: -93.8016,
    postal_code: '55328'
  },
  '55329': {
    country: 'US',
    city: 'Eden Valley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Meeker',
    latitude: 45.302,
    longitude: -94.6039,
    postal_code: '55329'
  },
  '55330': {
    country: 'US',
    city: 'Elk River',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sherburne',
    latitude: 45.3136,
    longitude: -93.5814,
    postal_code: '55330'
  },
  '55331': {
    country: 'US',
    city: 'Excelsior',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9007,
    longitude: -93.5791,
    postal_code: '55331'
  },
  '55332': {
    country: 'US',
    city: 'Fairfax',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Renville',
    latitude: 44.5329,
    longitude: -94.7256,
    postal_code: '55332'
  },
  '55333': {
    country: 'US',
    city: 'Franklin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Renville',
    latitude: 44.5421,
    longitude: -94.8931,
    postal_code: '55333'
  },
  '55334': {
    country: 'US',
    city: 'Gaylord',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sibley',
    latitude: 44.5463,
    longitude: -94.1955,
    postal_code: '55334'
  },
  '55335': {
    country: 'US',
    city: 'Gibbon',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sibley',
    latitude: 44.5606,
    longitude: -94.5467,
    postal_code: '55335'
  },
  '55336': {
    country: 'US',
    city: 'Glencoe',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'McLeod',
    latitude: 44.778,
    longitude: -94.1616,
    postal_code: '55336'
  },
  '55337': {
    country: 'US',
    city: 'Burnsville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dakota',
    latitude: 44.7609,
    longitude: -93.2753,
    postal_code: '55337'
  },
  '55338': {
    country: 'US',
    city: 'Green Isle',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sibley',
    latitude: 44.6791,
    longitude: -94.0083,
    postal_code: '55338'
  },
  '55339': {
    country: 'US',
    city: 'Hamburg',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.7315,
    longitude: -93.9643,
    postal_code: '55339'
  },
  '55340': {
    country: 'US',
    city: 'Hamel',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.08,
    longitude: -93.576,
    postal_code: '55340'
  },
  '55341': {
    country: 'US',
    city: 'Hanover',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.1602,
    longitude: -93.6734,
    postal_code: '55341'
  },
  '55342': {
    country: 'US',
    city: 'Hector',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Renville',
    latitude: 44.7485,
    longitude: -94.7058,
    postal_code: '55342'
  },
  '55343': {
    country: 'US',
    city: 'Hopkins',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.914,
    longitude: -93.4481,
    postal_code: '55343'
  },
  '55344': {
    country: 'US',
    city: 'Eden Prairie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8574,
    longitude: -93.4376,
    postal_code: '55344'
  },
  '55345': {
    country: 'US',
    city: 'Minnetonka',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9138,
    longitude: -93.485,
    postal_code: '55345'
  },
  '55346': {
    country: 'US',
    city: 'Eden Prairie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8771,
    longitude: -93.483,
    postal_code: '55346'
  },
  '55347': {
    country: 'US',
    city: 'Eden Prairie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8342,
    longitude: -93.4389,
    postal_code: '55347'
  },
  '55348': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0072,
    longitude: -93.6558,
    postal_code: '55348'
  },
  '55349': {
    country: 'US',
    city: 'Howard Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.0616,
    longitude: -94.0695,
    postal_code: '55349'
  },
  '55350': {
    country: 'US',
    city: 'Hutchinson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'McLeod',
    latitude: 44.8946,
    longitude: -94.3847,
    postal_code: '55350'
  },
  '55352': {
    country: 'US',
    city: 'Jordan',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Scott',
    latitude: 44.6713,
    longitude: -93.6195,
    postal_code: '55352'
  },
  '55353': {
    country: 'US',
    city: 'Kimball',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.3436,
    longitude: -94.3028,
    postal_code: '55353'
  },
  '55354': {
    country: 'US',
    city: 'Lester Prairie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'McLeod',
    latitude: 44.8739,
    longitude: -94.0551,
    postal_code: '55354'
  },
  '55355': {
    country: 'US',
    city: 'Litchfield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Meeker',
    latitude: 45.1263,
    longitude: -94.5268,
    postal_code: '55355'
  },
  '55356': {
    country: 'US',
    city: 'Long Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9912,
    longitude: -93.5818,
    postal_code: '55356'
  },
  '55357': {
    country: 'US',
    city: 'Loretto',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.1061,
    longitude: -93.6692,
    postal_code: '55357'
  },
  '55358': {
    country: 'US',
    city: 'Maple Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.2202,
    longitude: -93.9638,
    postal_code: '55358'
  },
  '55359': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9787,
    longitude: -93.7002,
    postal_code: '55359'
  },
  '55360': {
    country: 'US',
    city: 'Mayer',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.9022,
    longitude: -93.8859,
    postal_code: '55360'
  },
  '55361': {
    country: 'US',
    city: 'Minnetonka Beach',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9402,
    longitude: -93.5927,
    postal_code: '55361'
  },
  '55362': {
    country: 'US',
    city: 'Monticello',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.2956,
    longitude: -93.8023,
    postal_code: '55362'
  },
  '55363': {
    country: 'US',
    city: 'Montrose',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.0442,
    longitude: -93.9139,
    postal_code: '55363'
  },
  '55364': {
    country: 'US',
    city: 'Mound',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9382,
    longitude: -93.6561,
    postal_code: '55364'
  },
  '55366': {
    country: 'US',
    city: 'New Auburn',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sibley',
    latitude: 44.6735,
    longitude: -94.2293,
    postal_code: '55366'
  },
  '55367': {
    country: 'US',
    city: 'New Germany',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8994,
    longitude: -93.9701,
    postal_code: '55367'
  },
  '55368': {
    country: 'US',
    city: 'Norwood Young America',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.7736,
    longitude: -93.9216,
    postal_code: '55368'
  },
  '55369': {
    country: 'US',
    city: 'Osseo',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.1284,
    longitude: -93.4589,
    postal_code: '55369'
  },
  '55370': {
    country: 'US',
    city: 'Plato',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'McLeod',
    latitude: 44.7657,
    longitude: -94.0506,
    postal_code: '55370'
  },
  '55371': {
    country: 'US',
    city: 'Princeton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mille Lacs',
    latitude: 45.5851,
    longitude: -93.5961,
    postal_code: '55371'
  },
  '55372': {
    country: 'US',
    city: 'Prior Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Scott',
    latitude: 44.7107,
    longitude: -93.4101,
    postal_code: '55372'
  },
  '55373': {
    country: 'US',
    city: 'Rockford',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.0882,
    longitude: -93.7237,
    postal_code: '55373'
  },
  '55374': {
    country: 'US',
    city: 'Rogers',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.1715,
    longitude: -93.5814,
    postal_code: '55374'
  },
  '55375': {
    country: 'US',
    city: 'Saint Bonifacius',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9041,
    longitude: -93.749,
    postal_code: '55375'
  },
  '55376': {
    country: 'US',
    city: 'Saint Michael',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.2064,
    longitude: -93.6593,
    postal_code: '55376'
  },
  '55377': {
    country: 'US',
    city: 'Santiago',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sherburne',
    latitude: 45.5402,
    longitude: -93.8154,
    postal_code: '55377'
  },
  '55378': {
    country: 'US',
    city: 'Savage',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Scott',
    latitude: 44.7615,
    longitude: -93.3434,
    postal_code: '55378'
  },
  '55379': {
    country: 'US',
    city: 'Shakopee',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Scott',
    latitude: 44.7793,
    longitude: -93.5197,
    postal_code: '55379'
  },
  '55380': {
    country: 'US',
    city: 'Silver Creek',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.3158,
    longitude: -93.9798,
    postal_code: '55380'
  },
  '55381': {
    country: 'US',
    city: 'Silver Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'McLeod',
    latitude: 44.9139,
    longitude: -94.1974,
    postal_code: '55381'
  },
  '55382': {
    country: 'US',
    city: 'South Haven',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.2925,
    longitude: -94.2119,
    postal_code: '55382'
  },
  '55383': {
    country: 'US',
    city: 'Norwood',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.768,
    longitude: -93.9275,
    postal_code: '55383'
  },
  '55384': {
    country: 'US',
    city: 'Spring Park',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9356,
    longitude: -93.6341,
    postal_code: '55384'
  },
  '55385': {
    country: 'US',
    city: 'Stewart',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'McLeod',
    latitude: 44.7259,
    longitude: -94.4516,
    postal_code: '55385'
  },
  '55386': {
    country: 'US',
    city: 'Victoria',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8582,
    longitude: -93.6561,
    postal_code: '55386'
  },
  '55387': {
    country: 'US',
    city: 'Waconia',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.851,
    longitude: -93.7784,
    postal_code: '55387'
  },
  '55388': {
    country: 'US',
    city: 'Watertown',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.9595,
    longitude: -93.8482,
    postal_code: '55388'
  },
  '55389': {
    country: 'US',
    city: 'Watkins',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Meeker',
    latitude: 45.3087,
    longitude: -94.4297,
    postal_code: '55389'
  },
  '55390': {
    country: 'US',
    city: 'Waverly',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.0666,
    longitude: -93.9664,
    postal_code: '55390'
  },
  '55391': {
    country: 'US',
    city: 'Wayzata',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9847,
    longitude: -93.5422,
    postal_code: '55391'
  },
  '55392': {
    country: 'US',
    city: 'Navarre',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55392'
  },
  '55395': {
    country: 'US',
    city: 'Winsted',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'McLeod',
    latitude: 44.9585,
    longitude: -94.0552,
    postal_code: '55395'
  },
  '55396': {
    country: 'US',
    city: 'Winthrop',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sibley',
    latitude: 44.5436,
    longitude: -94.3698,
    postal_code: '55396'
  },
  '55397': {
    country: 'US',
    city: 'Young America',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.7929,
    longitude: -93.918,
    postal_code: '55397'
  },
  '55398': {
    country: 'US',
    city: 'Zimmerman',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sherburne',
    latitude: 45.4553,
    longitude: -93.5879,
    postal_code: '55398'
  },
  '55401': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9835,
    longitude: -93.2683,
    postal_code: '55401'
  },
  '55402': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9762,
    longitude: -93.2759,
    postal_code: '55402'
  },
  '55403': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9673,
    longitude: -93.2828,
    postal_code: '55403'
  },
  '55404': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9609,
    longitude: -93.2642,
    postal_code: '55404'
  },
  '55405': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9702,
    longitude: -93.3047,
    postal_code: '55405'
  },
  '55406': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9384,
    longitude: -93.2214,
    postal_code: '55406'
  },
  '55407': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9378,
    longitude: -93.2545,
    postal_code: '55407'
  },
  '55408': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9466,
    longitude: -93.2862,
    postal_code: '55408'
  },
  '55409': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9264,
    longitude: -93.2818,
    postal_code: '55409'
  },
  '55410': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9124,
    longitude: -93.3188,
    postal_code: '55410'
  },
  '55411': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9996,
    longitude: -93.3005,
    postal_code: '55411'
  },
  '55412': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0242,
    longitude: -93.302,
    postal_code: '55412'
  },
  '55413': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.998,
    longitude: -93.2552,
    postal_code: '55413'
  },
  '55414': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9779,
    longitude: -93.2199,
    postal_code: '55414'
  },
  '55415': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9742,
    longitude: -93.2585,
    postal_code: '55415'
  },
  '55416': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9497,
    longitude: -93.3373,
    postal_code: '55416'
  },
  '55417': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9054,
    longitude: -93.2361,
    postal_code: '55417'
  },
  '55418': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0192,
    longitude: -93.2401,
    postal_code: '55418'
  },
  '55419': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9026,
    longitude: -93.2886,
    postal_code: '55419'
  },
  '55420': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8358,
    longitude: -93.2778,
    postal_code: '55420'
  },
  '55421': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.0523,
    longitude: -93.2541,
    postal_code: '55421'
  },
  '55422': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0096,
    longitude: -93.3424,
    postal_code: '55422'
  },
  '55423': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8756,
    longitude: -93.2553,
    postal_code: '55423'
  },
  '55424': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9052,
    longitude: -93.3403,
    postal_code: '55424'
  },
  '55425': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8427,
    longitude: -93.2363,
    postal_code: '55425'
  },
  '55426': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.955,
    longitude: -93.3829,
    postal_code: '55426'
  },
  '55427': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45,
    longitude: -93.391,
    postal_code: '55427'
  },
  '55428': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0632,
    longitude: -93.3811,
    postal_code: '55428'
  },
  '55429': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0645,
    longitude: -93.3413,
    postal_code: '55429'
  },
  '55430': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0639,
    longitude: -93.3022,
    postal_code: '55430'
  },
  '55431': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8288,
    longitude: -93.3118,
    postal_code: '55431'
  },
  '55432': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.095,
    longitude: -93.2396,
    postal_code: '55432'
  },
  '55433': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.1643,
    longitude: -93.3193,
    postal_code: '55433'
  },
  '55434': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.1681,
    longitude: -93.2504,
    postal_code: '55434'
  },
  '55435': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8735,
    longitude: -93.3346,
    postal_code: '55435'
  },
  '55436': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9034,
    longitude: -93.374,
    postal_code: '55436'
  },
  '55437': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8261,
    longitude: -93.3538,
    postal_code: '55437'
  },
  '55438': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8266,
    longitude: -93.375,
    postal_code: '55438'
  },
  '55439': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8744,
    longitude: -93.3753,
    postal_code: '55439'
  },
  '55440': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55440'
  },
  '55441': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0058,
    longitude: -93.4193,
    postal_code: '55441'
  },
  '55442': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0467,
    longitude: -93.431,
    postal_code: '55442'
  },
  '55443': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.1194,
    longitude: -93.3431,
    postal_code: '55443'
  },
  '55444': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.1178,
    longitude: -93.3054,
    postal_code: '55444'
  },
  '55445': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.1232,
    longitude: -93.3797,
    postal_code: '55445'
  },
  '55446': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.04,
    longitude: -93.4865,
    postal_code: '55446'
  },
  '55447': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0033,
    longitude: -93.4875,
    postal_code: '55447'
  },
  '55448': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.1907,
    longitude: -93.3021,
    postal_code: '55448'
  },
  '55449': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Anoka',
    latitude: 45.1697,
    longitude: -93.1889,
    postal_code: '55449'
  },
  '55450': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.8811,
    longitude: -93.2207,
    postal_code: '55450'
  },
  '55454': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9682,
    longitude: -93.2429,
    postal_code: '55454'
  },
  '55455': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9735,
    longitude: -93.2331,
    postal_code: '55455'
  },
  '55458': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55458'
  },
  '55459': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55459'
  },
  '55460': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55460'
  },
  '55467': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.9861,
    longitude: -93.2708,
    postal_code: '55467'
  },
  '55470': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55470'
  },
  '55472': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55472'
  },
  '55473': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55473'
  },
  '55474': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55474'
  },
  '55478': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55478'
  },
  '55479': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55479'
  },
  '55480': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55480'
  },
  '55483': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55483'
  },
  '55484': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55484'
  },
  '55485': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55485'
  },
  '55486': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55486'
  },
  '55487': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55487'
  },
  '55488': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 44.98,
    longitude: -93.2638,
    postal_code: '55488'
  },
  '55550': {
    country: 'US',
    city: 'Young America',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8055,
    longitude: -93.7665,
    postal_code: '55550'
  },
  '55551': {
    country: 'US',
    city: 'Young America',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8055,
    longitude: -93.7665,
    postal_code: '55551'
  },
  '55553': {
    country: 'US',
    city: 'Young America',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8055,
    longitude: -93.7665,
    postal_code: '55553'
  },
  '55554': {
    country: 'US',
    city: 'Norwood',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8055,
    longitude: -93.7665,
    postal_code: '55554'
  },
  '55558': {
    country: 'US',
    city: 'Young America',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8055,
    longitude: -93.7665,
    postal_code: '55558'
  },
  '55568': {
    country: 'US',
    city: 'Young America',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8055,
    longitude: -93.7665,
    postal_code: '55568'
  },
  '55569': {
    country: 'US',
    city: 'Osseo',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.1194,
    longitude: -93.4025,
    postal_code: '55569'
  },
  '55570': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55570'
  },
  '55571': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55571'
  },
  '55572': {
    country: 'US',
    city: 'Rockford',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0883,
    longitude: -93.7344,
    postal_code: '55572'
  },
  '55573': {
    country: 'US',
    city: 'Young America',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55573'
  },
  '55574': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55574'
  },
  '55575': {
    country: 'US',
    city: 'Howard Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0608,
    longitude: -94.0733,
    postal_code: '55575'
  },
  '55576': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55576'
  },
  '55577': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55577'
  },
  '55578': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55578'
  },
  '55579': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55579'
  },
  '55583': {
    country: 'US',
    city: 'Norwood',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8055,
    longitude: -93.7665,
    postal_code: '55583'
  },
  '55592': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wright',
    latitude: 45.2009,
    longitude: -93.8881,
    postal_code: '55592'
  },
  '55593': {
    country: 'US',
    city: 'Maple Plain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55593'
  },
  '55594': {
    country: 'US',
    city: 'Young America',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carver',
    latitude: 44.8055,
    longitude: -93.7665,
    postal_code: '55594'
  },
  '55595': {
    country: 'US',
    city: 'Loretto',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55595'
  },
  '55596': {
    country: 'US',
    city: 'Loretto',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55596'
  },
  '55597': {
    country: 'US',
    city: 'Loretto',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55597'
  },
  '55598': {
    country: 'US',
    city: 'Loretto',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55598'
  },
  '55599': {
    country: 'US',
    city: 'Loretto',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hennepin',
    latitude: 45.0159,
    longitude: -93.4719,
    postal_code: '55599'
  },
  '55601': {
    country: 'US',
    city: 'Beaver Bay',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lake',
    latitude: 47.256,
    longitude: -91.3566,
    postal_code: '55601'
  },
  '55602': {
    country: 'US',
    city: 'Brimson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.3142,
    longitude: -91.8625,
    postal_code: '55602'
  },
  '55603': {
    country: 'US',
    city: 'Finland',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lake',
    latitude: 47.4197,
    longitude: -91.2096,
    postal_code: '55603'
  },
  '55604': {
    country: 'US',
    city: 'Grand Marais',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cook',
    latitude: 47.7758,
    longitude: -90.3391,
    postal_code: '55604'
  },
  '55605': {
    country: 'US',
    city: 'Grand Portage',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cook',
    latitude: 47.9591,
    longitude: -89.6989,
    postal_code: '55605'
  },
  '55606': {
    country: 'US',
    city: 'Hovland',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cook',
    latitude: 47.8342,
    longitude: -90.0476,
    postal_code: '55606'
  },
  '55607': {
    country: 'US',
    city: 'Isabella',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lake',
    latitude: 47.6604,
    longitude: -91.4989,
    postal_code: '55607'
  },
  '55609': {
    country: 'US',
    city: 'Knife River',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lake',
    latitude: 46.9539,
    longitude: -91.778,
    postal_code: '55609'
  },
  '55612': {
    country: 'US',
    city: 'Lutsen',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cook',
    latitude: 47.6831,
    longitude: -90.6381,
    postal_code: '55612'
  },
  '55613': {
    country: 'US',
    city: 'Schroeder',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cook',
    latitude: 47.5422,
    longitude: -90.9338,
    postal_code: '55613'
  },
  '55614': {
    country: 'US',
    city: 'Silver Bay',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lake',
    latitude: 47.3585,
    longitude: -91.2205,
    postal_code: '55614'
  },
  '55615': {
    country: 'US',
    city: 'Tofte',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cook',
    latitude: 47.5738,
    longitude: -90.8363,
    postal_code: '55615'
  },
  '55616': {
    country: 'US',
    city: 'Two Harbors',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lake',
    latitude: 47.0394,
    longitude: -91.6783,
    postal_code: '55616'
  },
  '55702': {
    country: 'US',
    city: 'Alborn',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.9782,
    longitude: -92.5579,
    postal_code: '55702'
  },
  '55703': {
    country: 'US',
    city: 'Angora',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.7577,
    longitude: -92.6413,
    postal_code: '55703'
  },
  '55704': {
    country: 'US',
    city: 'Askov',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 46.1964,
    longitude: -92.7528,
    postal_code: '55704'
  },
  '55705': {
    country: 'US',
    city: 'Aurora',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.4951,
    longitude: -92.2415,
    postal_code: '55705'
  },
  '55706': {
    country: 'US',
    city: 'Babbitt',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.7091,
    longitude: -91.957,
    postal_code: '55706'
  },
  '55707': {
    country: 'US',
    city: 'Barnum',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.5196,
    longitude: -92.6292,
    postal_code: '55707'
  },
  '55708': {
    country: 'US',
    city: 'Biwabik',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.5328,
    longitude: -92.3408,
    postal_code: '55708'
  },
  '55709': {
    country: 'US',
    city: 'Bovey',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.2868,
    longitude: -93.3723,
    postal_code: '55709'
  },
  '55710': {
    country: 'US',
    city: 'Britt',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.655,
    longitude: -92.6321,
    postal_code: '55710'
  },
  '55711': {
    country: 'US',
    city: 'Brookston',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.8384,
    longitude: -92.643,
    postal_code: '55711'
  },
  '55712': {
    country: 'US',
    city: 'Bruno',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 46.2845,
    longitude: -92.619,
    postal_code: '55712'
  },
  '55713': {
    country: 'US',
    city: 'Buhl',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.4932,
    longitude: -92.7643,
    postal_code: '55713'
  },
  '55716': {
    country: 'US',
    city: 'Calumet',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.3229,
    longitude: -93.2763,
    postal_code: '55716'
  },
  '55717': {
    country: 'US',
    city: 'Canyon',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.0785,
    longitude: -92.4594,
    postal_code: '55717'
  },
  '55718': {
    country: 'US',
    city: 'Carlton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.6484,
    longitude: -92.471,
    postal_code: '55718'
  },
  '55719': {
    country: 'US',
    city: 'Chisholm',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.5007,
    longitude: -92.8617,
    postal_code: '55719'
  },
  '55720': {
    country: 'US',
    city: 'Cloquet',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.7546,
    longitude: -92.5408,
    postal_code: '55720'
  },
  '55721': {
    country: 'US',
    city: 'Cohasset',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.2691,
    longitude: -93.6392,
    postal_code: '55721'
  },
  '55722': {
    country: 'US',
    city: 'Coleraine',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.2888,
    longitude: -93.4277,
    postal_code: '55722'
  },
  '55723': {
    country: 'US',
    city: 'Cook',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.8442,
    longitude: -92.721,
    postal_code: '55723'
  },
  '55724': {
    country: 'US',
    city: 'Cotton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.1521,
    longitude: -92.4352,
    postal_code: '55724'
  },
  '55725': {
    country: 'US',
    city: 'Crane Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 48.2594,
    longitude: -92.4896,
    postal_code: '55725'
  },
  '55726': {
    country: 'US',
    city: 'Cromwell',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.6718,
    longitude: -92.8739,
    postal_code: '55726'
  },
  '55730': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.2372,
    longitude: -93.5302,
    postal_code: '55730'
  },
  '55731': {
    country: 'US',
    city: 'Ely',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.9034,
    longitude: -91.857,
    postal_code: '55731'
  },
  '55732': {
    country: 'US',
    city: 'Embarrass',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.6658,
    longitude: -92.2101,
    postal_code: '55732'
  },
  '55733': {
    country: 'US',
    city: 'Esko',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.7126,
    longitude: -92.3569,
    postal_code: '55733'
  },
  '55734': {
    country: 'US',
    city: 'Eveleth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.451,
    longitude: -92.528,
    postal_code: '55734'
  },
  '55735': {
    country: 'US',
    city: 'Finlayson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 46.2121,
    longitude: -92.9389,
    postal_code: '55735'
  },
  '55736': {
    country: 'US',
    city: 'Floodwood',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.9076,
    longitude: -92.9167,
    postal_code: '55736'
  },
  '55738': {
    country: 'US',
    city: 'Forbes',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.2732,
    longitude: -92.6756,
    postal_code: '55738'
  },
  '55741': {
    country: 'US',
    city: 'Gilbert',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.4885,
    longitude: -92.4025,
    postal_code: '55741'
  },
  '55742': {
    country: 'US',
    city: 'Goodland',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.1924,
    longitude: -93.1469,
    postal_code: '55742'
  },
  '55744': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.2348,
    longitude: -93.5115,
    postal_code: '55744'
  },
  '55745': {
    country: 'US',
    city: 'Grand Rapids',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.2372,
    longitude: -93.5302,
    postal_code: '55745'
  },
  '55746': {
    country: 'US',
    city: 'Hibbing',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.4156,
    longitude: -92.9356,
    postal_code: '55746'
  },
  '55747': {
    country: 'US',
    city: 'Hibbing',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.4272,
    longitude: -92.9377,
    postal_code: '55747'
  },
  '55748': {
    country: 'US',
    city: 'Hill City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Aitkin',
    latitude: 46.9968,
    longitude: -93.5994,
    postal_code: '55748'
  },
  '55749': {
    country: 'US',
    city: 'Holyoke',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.4664,
    longitude: -92.375,
    postal_code: '55749'
  },
  '55750': {
    country: 'US',
    city: 'Hoyt Lakes',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.515,
    longitude: -92.14,
    postal_code: '55750'
  },
  '55751': {
    country: 'US',
    city: 'Iron',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.4115,
    longitude: -92.6195,
    postal_code: '55751'
  },
  '55752': {
    country: 'US',
    city: 'Jacobson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Aitkin',
    latitude: 46.9772,
    longitude: -93.3064,
    postal_code: '55752'
  },
  '55753': {
    country: 'US',
    city: 'Keewatin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.398,
    longitude: -93.0784,
    postal_code: '55753'
  },
  '55756': {
    country: 'US',
    city: 'Kerrick',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 46.3792,
    longitude: -92.578,
    postal_code: '55756'
  },
  '55757': {
    country: 'US',
    city: 'Kettle River',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.5029,
    longitude: -92.9047,
    postal_code: '55757'
  },
  '55758': {
    country: 'US',
    city: 'Kinney',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.5121,
    longitude: -92.7402,
    postal_code: '55758'
  },
  '55760': {
    country: 'US',
    city: 'Mcgregor',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Aitkin',
    latitude: 46.6072,
    longitude: -93.3076,
    postal_code: '55760'
  },
  '55763': {
    country: 'US',
    city: 'Makinen',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.3416,
    longitude: -92.3446,
    postal_code: '55763'
  },
  '55764': {
    country: 'US',
    city: 'Marble',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.3219,
    longitude: -93.2939,
    postal_code: '55764'
  },
  '55765': {
    country: 'US',
    city: 'Meadowlands',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.1021,
    longitude: -92.7884,
    postal_code: '55765'
  },
  '55766': {
    country: 'US',
    city: 'Melrude',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.2496,
    longitude: -92.4123,
    postal_code: '55766'
  },
  '55767': {
    country: 'US',
    city: 'Moose Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.4472,
    longitude: -92.7466,
    postal_code: '55767'
  },
  '55768': {
    country: 'US',
    city: 'Mountain Iron',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.5133,
    longitude: -92.6243,
    postal_code: '55768'
  },
  '55769': {
    country: 'US',
    city: 'Nashwauk',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.42,
    longitude: -93.2168,
    postal_code: '55769'
  },
  '55771': {
    country: 'US',
    city: 'Orr',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 48.1439,
    longitude: -92.8561,
    postal_code: '55771'
  },
  '55772': {
    country: 'US',
    city: 'Nett Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 48.111,
    longitude: -93.0941,
    postal_code: '55772'
  },
  '55775': {
    country: 'US',
    city: 'Pengilly',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.3151,
    longitude: -93.1937,
    postal_code: '55775'
  },
  '55777': {
    country: 'US',
    city: 'Virginia',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.5233,
    longitude: -92.5366,
    postal_code: '55777'
  },
  '55779': {
    country: 'US',
    city: 'Saginaw',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.8795,
    longitude: -92.3917,
    postal_code: '55779'
  },
  '55780': {
    country: 'US',
    city: 'Sawyer',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.6831,
    longitude: -92.6927,
    postal_code: '55780'
  },
  '55781': {
    country: 'US',
    city: 'Side Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.6671,
    longitude: -93.0424,
    postal_code: '55781'
  },
  '55782': {
    country: 'US',
    city: 'Soudan',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.821,
    longitude: -92.2464,
    postal_code: '55782'
  },
  '55783': {
    country: 'US',
    city: 'Sturgeon Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 46.3837,
    longitude: -92.8182,
    postal_code: '55783'
  },
  '55784': {
    country: 'US',
    city: 'Swan River',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.0739,
    longitude: -93.1964,
    postal_code: '55784'
  },
  '55785': {
    country: 'US',
    city: 'Swatara',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Aitkin',
    latitude: 46.9232,
    longitude: -93.7464,
    postal_code: '55785'
  },
  '55786': {
    country: 'US',
    city: 'Taconite',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.3164,
    longitude: -93.3904,
    postal_code: '55786'
  },
  '55787': {
    country: 'US',
    city: 'Tamarack',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Aitkin',
    latitude: 46.6444,
    longitude: -93.1272,
    postal_code: '55787'
  },
  '55790': {
    country: 'US',
    city: 'Tower',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.8089,
    longitude: -92.2878,
    postal_code: '55790'
  },
  '55791': {
    country: 'US',
    city: 'Twig',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.8944,
    longitude: -92.3646,
    postal_code: '55791'
  },
  '55792': {
    country: 'US',
    city: 'Virginia',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.5371,
    longitude: -92.5285,
    postal_code: '55792'
  },
  '55793': {
    country: 'US',
    city: 'Warba',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.1361,
    longitude: -93.2764,
    postal_code: '55793'
  },
  '55795': {
    country: 'US',
    city: 'Willow River',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pine',
    latitude: 46.2949,
    longitude: -92.8309,
    postal_code: '55795'
  },
  '55796': {
    country: 'US',
    city: 'Winton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 47.9263,
    longitude: -91.8007,
    postal_code: '55796'
  },
  '55797': {
    country: 'US',
    city: 'Wrenshall',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.5921,
    longitude: -92.3718,
    postal_code: '55797'
  },
  '55798': {
    country: 'US',
    city: 'Wright',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Carlton',
    latitude: 46.6691,
    longitude: -93.0069,
    postal_code: '55798'
  },
  '55801': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.7833,
    longitude: -92.1066,
    postal_code: '55801'
  },
  '55802': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.7685,
    longitude: -92.0865,
    postal_code: '55802'
  },
  '55803': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.8749,
    longitude: -92.0941,
    postal_code: '55803'
  },
  '55804': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.8551,
    longitude: -92.0074,
    postal_code: '55804'
  },
  '55805': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.7987,
    longitude: -92.0946,
    postal_code: '55805'
  },
  '55806': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.7715,
    longitude: -92.1279,
    postal_code: '55806'
  },
  '55807': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.7408,
    longitude: -92.1698,
    postal_code: '55807'
  },
  '55808': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.681,
    longitude: -92.2226,
    postal_code: '55808'
  },
  '55810': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.7606,
    longitude: -92.266,
    postal_code: '55810'
  },
  '55811': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.8147,
    longitude: -92.1998,
    postal_code: '55811'
  },
  '55812': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.8106,
    longitude: -92.0767,
    postal_code: '55812'
  },
  '55814': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.7833,
    longitude: -92.1066,
    postal_code: '55814'
  },
  '55815': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.7833,
    longitude: -92.1066,
    postal_code: '55815'
  },
  '55816': {
    country: 'US',
    city: 'Duluth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'St. Louis',
    latitude: 46.7833,
    longitude: -92.1066,
    postal_code: '55816'
  },
  '55901': {
    country: 'US',
    city: 'Rochester',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 44.0496,
    longitude: -92.4896,
    postal_code: '55901'
  },
  '55902': {
    country: 'US',
    city: 'Rochester',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 44.0032,
    longitude: -92.4835,
    postal_code: '55902'
  },
  '55903': {
    country: 'US',
    city: 'Rochester',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 43.9966,
    longitude: -92.5409,
    postal_code: '55903'
  },
  '55904': {
    country: 'US',
    city: 'Rochester',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 44.0105,
    longitude: -92.3973,
    postal_code: '55904'
  },
  '55905': {
    country: 'US',
    city: 'Rochester',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 44.0225,
    longitude: -92.4668,
    postal_code: '55905'
  },
  '55906': {
    country: 'US',
    city: 'Rochester',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 44.1078,
    longitude: -92.4053,
    postal_code: '55906'
  },
  '55909': {
    country: 'US',
    city: 'Adams',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.5591,
    longitude: -92.7305,
    postal_code: '55909'
  },
  '55910': {
    country: 'US',
    city: 'Altura',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Winona',
    latitude: 44.1361,
    longitude: -91.9745,
    postal_code: '55910'
  },
  '55912': {
    country: 'US',
    city: 'Austin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.6695,
    longitude: -92.9784,
    postal_code: '55912'
  },
  '55917': {
    country: 'US',
    city: 'Blooming Prairie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Steele',
    latitude: 43.8977,
    longitude: -93.0608,
    postal_code: '55917'
  },
  '55918': {
    country: 'US',
    city: 'Brownsdale',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.7248,
    longitude: -92.8738,
    postal_code: '55918'
  },
  '55919': {
    country: 'US',
    city: 'Brownsville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Houston',
    latitude: 43.6707,
    longitude: -91.3012,
    postal_code: '55919'
  },
  '55920': {
    country: 'US',
    city: 'Byron',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 44.0373,
    longitude: -92.6308,
    postal_code: '55920'
  },
  '55921': {
    country: 'US',
    city: 'Caledonia',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Houston',
    latitude: 43.6221,
    longitude: -91.4837,
    postal_code: '55921'
  },
  '55922': {
    country: 'US',
    city: 'Canton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.5667,
    longitude: -91.913,
    postal_code: '55922'
  },
  '55923': {
    country: 'US',
    city: 'Chatfield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.8362,
    longitude: -92.1574,
    postal_code: '55923'
  },
  '55924': {
    country: 'US',
    city: 'Claremont',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dodge',
    latitude: 44.0522,
    longitude: -92.9888,
    postal_code: '55924'
  },
  '55925': {
    country: 'US',
    city: 'Dakota',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Winona',
    latitude: 43.9148,
    longitude: -91.394,
    postal_code: '55925'
  },
  '55926': {
    country: 'US',
    city: 'Dexter',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.716,
    longitude: -92.7268,
    postal_code: '55926'
  },
  '55927': {
    country: 'US',
    city: 'Dodge Center',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dodge',
    latitude: 44.0325,
    longitude: -92.8554,
    postal_code: '55927'
  },
  '55929': {
    country: 'US',
    city: 'Dover',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 44.0015,
    longitude: -92.1415,
    postal_code: '55929'
  },
  '55931': {
    country: 'US',
    city: 'Eitzen',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Houston',
    latitude: 43.5084,
    longitude: -91.4632,
    postal_code: '55931'
  },
  '55932': {
    country: 'US',
    city: 'Elgin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wabasha',
    latitude: 44.1358,
    longitude: -92.2535,
    postal_code: '55932'
  },
  '55933': {
    country: 'US',
    city: 'Elkton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.6348,
    longitude: -92.7104,
    postal_code: '55933'
  },
  '55934': {
    country: 'US',
    city: 'Eyota',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 44.0099,
    longitude: -92.2648,
    postal_code: '55934'
  },
  '55935': {
    country: 'US',
    city: 'Fountain',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.7284,
    longitude: -92.1423,
    postal_code: '55935'
  },
  '55936': {
    country: 'US',
    city: 'Grand Meadow',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.7101,
    longitude: -92.5692,
    postal_code: '55936'
  },
  '55939': {
    country: 'US',
    city: 'Harmony',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.5663,
    longitude: -92.0145,
    postal_code: '55939'
  },
  '55940': {
    country: 'US',
    city: 'Hayfield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dodge',
    latitude: 43.8923,
    longitude: -92.8175,
    postal_code: '55940'
  },
  '55941': {
    country: 'US',
    city: 'Hokah',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Houston',
    latitude: 43.7509,
    longitude: -91.3455,
    postal_code: '55941'
  },
  '55942': {
    country: 'US',
    city: 'Homer',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Winona',
    latitude: 44.02,
    longitude: -91.6819,
    postal_code: '55942'
  },
  '55943': {
    country: 'US',
    city: 'Houston',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Houston',
    latitude: 43.7929,
    longitude: -91.5626,
    postal_code: '55943'
  },
  '55944': {
    country: 'US',
    city: 'Kasson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dodge',
    latitude: 44.024,
    longitude: -92.7464,
    postal_code: '55944'
  },
  '55945': {
    country: 'US',
    city: 'Kellogg',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wabasha',
    latitude: 44.2739,
    longitude: -92.1095,
    postal_code: '55945'
  },
  '55946': {
    country: 'US',
    city: 'Kenyon',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Goodhue',
    latitude: 44.2552,
    longitude: -93.0197,
    postal_code: '55946'
  },
  '55947': {
    country: 'US',
    city: 'La Crescent',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Houston',
    latitude: 43.8307,
    longitude: -91.3263,
    postal_code: '55947'
  },
  '55949': {
    country: 'US',
    city: 'Lanesboro',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.7174,
    longitude: -91.9877,
    postal_code: '55949'
  },
  '55950': {
    country: 'US',
    city: 'Lansing',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.7452,
    longitude: -92.9702,
    postal_code: '55950'
  },
  '55951': {
    country: 'US',
    city: 'Le Roy',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.5315,
    longitude: -92.5065,
    postal_code: '55951'
  },
  '55952': {
    country: 'US',
    city: 'Lewiston',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Winona',
    latitude: 43.9702,
    longitude: -91.8662,
    postal_code: '55952'
  },
  '55953': {
    country: 'US',
    city: 'Lyle',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.5309,
    longitude: -92.9328,
    postal_code: '55953'
  },
  '55954': {
    country: 'US',
    city: 'Mabel',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.5446,
    longitude: -91.7806,
    postal_code: '55954'
  },
  '55955': {
    country: 'US',
    city: 'Mantorville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dodge',
    latitude: 44.0657,
    longitude: -92.76,
    postal_code: '55955'
  },
  '55956': {
    country: 'US',
    city: 'Mazeppa',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wabasha',
    latitude: 44.2646,
    longitude: -92.5207,
    postal_code: '55956'
  },
  '55957': {
    country: 'US',
    city: 'Millville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wabasha',
    latitude: 44.2359,
    longitude: -92.2672,
    postal_code: '55957'
  },
  '55959': {
    country: 'US',
    city: 'Minnesota City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Winona',
    latitude: 44.0832,
    longitude: -91.7418,
    postal_code: '55959'
  },
  '55960': {
    country: 'US',
    city: 'Oronoco',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 44.1489,
    longitude: -92.485,
    postal_code: '55960'
  },
  '55961': {
    country: 'US',
    city: 'Ostrander',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.5972,
    longitude: -92.4157,
    postal_code: '55961'
  },
  '55962': {
    country: 'US',
    city: 'Peterson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.7769,
    longitude: -91.8443,
    postal_code: '55962'
  },
  '55963': {
    country: 'US',
    city: 'Pine Island',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Goodhue',
    latitude: 44.211,
    longitude: -92.6613,
    postal_code: '55963'
  },
  '55964': {
    country: 'US',
    city: 'Plainview',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wabasha',
    latitude: 44.1637,
    longitude: -92.1621,
    postal_code: '55964'
  },
  '55965': {
    country: 'US',
    city: 'Preston',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.6641,
    longitude: -92.096,
    postal_code: '55965'
  },
  '55967': {
    country: 'US',
    city: 'Racine',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.79,
    longitude: -92.531,
    postal_code: '55967'
  },
  '55968': {
    country: 'US',
    city: 'Reads Landing',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wabasha',
    latitude: 44.3977,
    longitude: -92.0893,
    postal_code: '55968'
  },
  '55969': {
    country: 'US',
    city: 'Rollingstone',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Winona',
    latitude: 44.1025,
    longitude: -91.8158,
    postal_code: '55969'
  },
  '55970': {
    country: 'US',
    city: 'Rose Creek',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.6036,
    longitude: -92.8319,
    postal_code: '55970'
  },
  '55971': {
    country: 'US',
    city: 'Rushford',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.8216,
    longitude: -91.7536,
    postal_code: '55971'
  },
  '55972': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Winona',
    latitude: 43.9585,
    longitude: -92.0517,
    postal_code: '55972'
  },
  '55973': {
    country: 'US',
    city: 'Sargeant',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.809,
    longitude: -92.7595,
    postal_code: '55973'
  },
  '55974': {
    country: 'US',
    city: 'Spring Grove',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Houston',
    latitude: 43.5623,
    longitude: -91.6368,
    postal_code: '55974'
  },
  '55975': {
    country: 'US',
    city: 'Spring Valley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.6823,
    longitude: -92.368,
    postal_code: '55975'
  },
  '55976': {
    country: 'US',
    city: 'Stewartville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Olmsted',
    latitude: 43.8555,
    longitude: -92.4885,
    postal_code: '55976'
  },
  '55977': {
    country: 'US',
    city: 'Taopi',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.5458,
    longitude: -92.6335,
    postal_code: '55977'
  },
  '55979': {
    country: 'US',
    city: 'Utica',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Winona',
    latitude: 43.9587,
    longitude: -91.9417,
    postal_code: '55979'
  },
  '55981': {
    country: 'US',
    city: 'Wabasha',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wabasha',
    latitude: 44.3703,
    longitude: -92.0361,
    postal_code: '55981'
  },
  '55982': {
    country: 'US',
    city: 'Waltham',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mower',
    latitude: 43.807,
    longitude: -92.8734,
    postal_code: '55982'
  },
  '55983': {
    country: 'US',
    city: 'Wanamingo',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Goodhue',
    latitude: 44.3121,
    longitude: -92.8103,
    postal_code: '55983'
  },
  '55985': {
    country: 'US',
    city: 'West Concord',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Dodge',
    latitude: 44.152,
    longitude: -92.8825,
    postal_code: '55985'
  },
  '55987': {
    country: 'US',
    city: 'Winona',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Winona',
    latitude: 44.03,
    longitude: -91.7009,
    postal_code: '55987'
  },
  '55988': {
    country: 'US',
    city: 'Stockton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Winona',
    latitude: 44.0252,
    longitude: -91.7708,
    postal_code: '55988'
  },
  '55990': {
    country: 'US',
    city: 'Wykoff',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Fillmore',
    latitude: 43.7146,
    longitude: -92.2679,
    postal_code: '55990'
  },
  '55991': {
    country: 'US',
    city: 'Zumbro Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wabasha',
    latitude: 44.2427,
    longitude: -92.4256,
    postal_code: '55991'
  },
  '55992': {
    country: 'US',
    city: 'Zumbrota',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Goodhue',
    latitude: 44.3032,
    longitude: -92.6719,
    postal_code: '55992'
  },
  '56001': {
    country: 'US',
    city: 'Mankato',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 44.1538,
    longitude: -93.996,
    postal_code: '56001'
  },
  '56002': {
    country: 'US',
    city: 'Mankato',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 44.1591,
    longitude: -94.0092,
    postal_code: '56002'
  },
  '56003': {
    country: 'US',
    city: 'Mankato',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nicollet',
    latitude: 44.2172,
    longitude: -94.0942,
    postal_code: '56003'
  },
  '56007': {
    country: 'US',
    city: 'Albert Lea',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.6537,
    longitude: -93.3707,
    postal_code: '56007'
  },
  '56009': {
    country: 'US',
    city: 'Alden',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.6466,
    longitude: -93.5823,
    postal_code: '56009'
  },
  '56010': {
    country: 'US',
    city: 'Amboy',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 43.8903,
    longitude: -94.1774,
    postal_code: '56010'
  },
  '56011': {
    country: 'US',
    city: 'Belle Plaine',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Scott',
    latitude: 44.6139,
    longitude: -93.7604,
    postal_code: '56011'
  },
  '56013': {
    country: 'US',
    city: 'Blue Earth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.6394,
    longitude: -94.0924,
    postal_code: '56013'
  },
  '56014': {
    country: 'US',
    city: 'Bricelyn',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.5746,
    longitude: -93.8211,
    postal_code: '56014'
  },
  '56016': {
    country: 'US',
    city: 'Clarks Grove',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.763,
    longitude: -93.3232,
    postal_code: '56016'
  },
  '56017': {
    country: 'US',
    city: 'Cleveland',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Le Sueur',
    latitude: 44.3201,
    longitude: -93.8286,
    postal_code: '56017'
  },
  '56019': {
    country: 'US',
    city: 'Comfrey',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Brown',
    latitude: 44.1111,
    longitude: -94.9134,
    postal_code: '56019'
  },
  '56020': {
    country: 'US',
    city: 'Conger',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.615,
    longitude: -93.5283,
    postal_code: '56020'
  },
  '56021': {
    country: 'US',
    city: 'Courtland',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nicollet',
    latitude: 44.2791,
    longitude: -94.3482,
    postal_code: '56021'
  },
  '56022': {
    country: 'US',
    city: 'Darfur',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Watonwan',
    latitude: 44.0513,
    longitude: -94.838,
    postal_code: '56022'
  },
  '56023': {
    country: 'US',
    city: 'Delavan',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.7719,
    longitude: -94.0225,
    postal_code: '56023'
  },
  '56024': {
    country: 'US',
    city: 'Eagle Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 44.1546,
    longitude: -93.8719,
    postal_code: '56024'
  },
  '56025': {
    country: 'US',
    city: 'Easton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.7658,
    longitude: -93.9011,
    postal_code: '56025'
  },
  '56026': {
    country: 'US',
    city: 'Ellendale',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Steele',
    latitude: 43.8826,
    longitude: -93.3195,
    postal_code: '56026'
  },
  '56027': {
    country: 'US',
    city: 'Elmore',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.5201,
    longitude: -94.0984,
    postal_code: '56027'
  },
  '56028': {
    country: 'US',
    city: 'Elysian',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Le Sueur',
    latitude: 44.2231,
    longitude: -93.6965,
    postal_code: '56028'
  },
  '56029': {
    country: 'US',
    city: 'Emmons',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.5089,
    longitude: -93.4824,
    postal_code: '56029'
  },
  '56030': {
    country: 'US',
    city: 'Essig',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Brown',
    latitude: 44.3258,
    longitude: -94.6052,
    postal_code: '56030'
  },
  '56031': {
    country: 'US',
    city: 'Fairmont',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Martin',
    latitude: 43.6376,
    longitude: -94.451,
    postal_code: '56031'
  },
  '56032': {
    country: 'US',
    city: 'Freeborn',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.7658,
    longitude: -93.5641,
    postal_code: '56032'
  },
  '56033': {
    country: 'US',
    city: 'Frost',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.5638,
    longitude: -93.9361,
    postal_code: '56033'
  },
  '56034': {
    country: 'US',
    city: 'Garden City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 44.0467,
    longitude: -94.1791,
    postal_code: '56034'
  },
  '56035': {
    country: 'US',
    city: 'Geneva',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.8235,
    longitude: -93.2671,
    postal_code: '56035'
  },
  '56036': {
    country: 'US',
    city: 'Glenville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.5577,
    longitude: -93.2618,
    postal_code: '56036'
  },
  '56037': {
    country: 'US',
    city: 'Good Thunder',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 43.9952,
    longitude: -94.0677,
    postal_code: '56037'
  },
  '56039': {
    country: 'US',
    city: 'Granada',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Martin',
    latitude: 43.7061,
    longitude: -94.3307,
    postal_code: '56039'
  },
  '56041': {
    country: 'US',
    city: 'Hanska',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Brown',
    latitude: 44.1527,
    longitude: -94.4933,
    postal_code: '56041'
  },
  '56042': {
    country: 'US',
    city: 'Hartland',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.804,
    longitude: -93.477,
    postal_code: '56042'
  },
  '56043': {
    country: 'US',
    city: 'Hayward',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.6386,
    longitude: -93.2377,
    postal_code: '56043'
  },
  '56044': {
    country: 'US',
    city: 'Henderson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Sibley',
    latitude: 44.5344,
    longitude: -93.9345,
    postal_code: '56044'
  },
  '56045': {
    country: 'US',
    city: 'Hollandale',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.7608,
    longitude: -93.2041,
    postal_code: '56045'
  },
  '56046': {
    country: 'US',
    city: 'Hope',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Steele',
    latitude: 43.9619,
    longitude: -93.276,
    postal_code: '56046'
  },
  '56047': {
    country: 'US',
    city: 'Huntley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.7316,
    longitude: -94.2375,
    postal_code: '56047'
  },
  '56048': {
    country: 'US',
    city: 'Janesville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Waseca',
    latitude: 44.1168,
    longitude: -93.7095,
    postal_code: '56048'
  },
  '56050': {
    country: 'US',
    city: 'Kasota',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Le Sueur',
    latitude: 44.2842,
    longitude: -93.9453,
    postal_code: '56050'
  },
  '56051': {
    country: 'US',
    city: 'Kiester',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.5414,
    longitude: -93.7102,
    postal_code: '56051'
  },
  '56052': {
    country: 'US',
    city: 'Kilkenny',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Le Sueur',
    latitude: 44.3133,
    longitude: -93.5741,
    postal_code: '56052'
  },
  '56054': {
    country: 'US',
    city: 'Lafayette',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nicollet',
    latitude: 44.4073,
    longitude: -94.4365,
    postal_code: '56054'
  },
  '56055': {
    country: 'US',
    city: 'Lake Crystal',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 44.1202,
    longitude: -94.2184,
    postal_code: '56055'
  },
  '56056': {
    country: 'US',
    city: 'La Salle',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Watonwan',
    latitude: 44.0727,
    longitude: -94.5795,
    postal_code: '56056'
  },
  '56057': {
    country: 'US',
    city: 'Le Center',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Le Sueur',
    latitude: 44.3853,
    longitude: -93.7214,
    postal_code: '56057'
  },
  '56058': {
    country: 'US',
    city: 'Le Sueur',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Le Sueur',
    latitude: 44.4582,
    longitude: -93.8856,
    postal_code: '56058'
  },
  '56060': {
    country: 'US',
    city: 'Lewisville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Watonwan',
    latitude: 43.9209,
    longitude: -94.4289,
    postal_code: '56060'
  },
  '56062': {
    country: 'US',
    city: 'Madelia',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Watonwan',
    latitude: 44.0499,
    longitude: -94.411,
    postal_code: '56062'
  },
  '56063': {
    country: 'US',
    city: 'Madison Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 44.2221,
    longitude: -93.8289,
    postal_code: '56063'
  },
  '56065': {
    country: 'US',
    city: 'Mapleton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 43.9331,
    longitude: -93.9542,
    postal_code: '56065'
  },
  '56068': {
    country: 'US',
    city: 'Minnesota Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.8295,
    longitude: -93.8282,
    postal_code: '56068'
  },
  '56069': {
    country: 'US',
    city: 'Montgomery',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Le Sueur',
    latitude: 44.4356,
    longitude: -93.581,
    postal_code: '56069'
  },
  '56071': {
    country: 'US',
    city: 'New Prague',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Le Sueur',
    latitude: 44.5402,
    longitude: -93.5805,
    postal_code: '56071'
  },
  '56072': {
    country: 'US',
    city: 'New Richland',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Waseca',
    latitude: 43.8937,
    longitude: -93.4995,
    postal_code: '56072'
  },
  '56073': {
    country: 'US',
    city: 'New Ulm',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Brown',
    latitude: 44.3044,
    longitude: -94.4644,
    postal_code: '56073'
  },
  '56074': {
    country: 'US',
    city: 'Nicollet',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nicollet',
    latitude: 44.2724,
    longitude: -94.1867,
    postal_code: '56074'
  },
  '56075': {
    country: 'US',
    city: 'Northrop',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Martin',
    latitude: 43.7353,
    longitude: -94.4357,
    postal_code: '56075'
  },
  '56078': {
    country: 'US',
    city: 'Pemberton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 44.0077,
    longitude: -93.7833,
    postal_code: '56078'
  },
  '56080': {
    country: 'US',
    city: 'Saint Clair',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 44.0817,
    longitude: -93.8571,
    postal_code: '56080'
  },
  '56081': {
    country: 'US',
    city: 'Saint James',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Watonwan',
    latitude: 43.9875,
    longitude: -94.6229,
    postal_code: '56081'
  },
  '56082': {
    country: 'US',
    city: 'Saint Peter',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nicollet',
    latitude: 44.3351,
    longitude: -93.9811,
    postal_code: '56082'
  },
  '56083': {
    country: 'US',
    city: 'Sanborn',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.2183,
    longitude: -95.1328,
    postal_code: '56083'
  },
  '56084': {
    country: 'US',
    city: 'Searles',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Brown',
    latitude: 44.2286,
    longitude: -94.4347,
    postal_code: '56084'
  },
  '56085': {
    country: 'US',
    city: 'Sleepy Eye',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Brown',
    latitude: 44.2821,
    longitude: -94.7273,
    postal_code: '56085'
  },
  '56087': {
    country: 'US',
    city: 'Springfield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Brown',
    latitude: 44.2329,
    longitude: -94.9792,
    postal_code: '56087'
  },
  '56088': {
    country: 'US',
    city: 'Truman',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Martin',
    latitude: 43.82,
    longitude: -94.4319,
    postal_code: '56088'
  },
  '56089': {
    country: 'US',
    city: 'Twin Lakes',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Freeborn',
    latitude: 43.5593,
    longitude: -93.4206,
    postal_code: '56089'
  },
  '56090': {
    country: 'US',
    city: 'Vernon Center',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Blue Earth',
    latitude: 43.9706,
    longitude: -94.2142,
    postal_code: '56090'
  },
  '56091': {
    country: 'US',
    city: 'Waldorf',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Waseca',
    latitude: 43.9399,
    longitude: -93.7043,
    postal_code: '56091'
  },
  '56093': {
    country: 'US',
    city: 'Waseca',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Waseca',
    latitude: 44.0834,
    longitude: -93.5108,
    postal_code: '56093'
  },
  '56096': {
    country: 'US',
    city: 'Waterville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Le Sueur',
    latitude: 44.2238,
    longitude: -93.5751,
    postal_code: '56096'
  },
  '56097': {
    country: 'US',
    city: 'Wells',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.7434,
    longitude: -93.7321,
    postal_code: '56097'
  },
  '56098': {
    country: 'US',
    city: 'Winnebago',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Faribault',
    latitude: 43.7755,
    longitude: -94.1632,
    postal_code: '56098'
  },
  '56101': {
    country: 'US',
    city: 'Windom',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cottonwood',
    latitude: 43.8831,
    longitude: -95.1373,
    postal_code: '56101'
  },
  '56110': {
    country: 'US',
    city: 'Adrian',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.6197,
    longitude: -95.9273,
    postal_code: '56110'
  },
  '56111': {
    country: 'US',
    city: 'Alpha',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Jackson',
    latitude: 43.5946,
    longitude: -94.9051,
    postal_code: '56111'
  },
  '56113': {
    country: 'US',
    city: 'Arco',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lincoln',
    latitude: 44.4091,
    longitude: -96.1999,
    postal_code: '56113'
  },
  '56114': {
    country: 'US',
    city: 'Avoca',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Murray',
    latitude: 43.9706,
    longitude: -95.6002,
    postal_code: '56114'
  },
  '56115': {
    country: 'US',
    city: 'Balaton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lyon',
    latitude: 44.2253,
    longitude: -95.8838,
    postal_code: '56115'
  },
  '56116': {
    country: 'US',
    city: 'Beaver Creek',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rock',
    latitude: 43.6223,
    longitude: -96.3698,
    postal_code: '56116'
  },
  '56117': {
    country: 'US',
    city: 'Bigelow',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.5336,
    longitude: -95.6515,
    postal_code: '56117'
  },
  '56118': {
    country: 'US',
    city: 'Bingham Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cottonwood',
    latitude: 43.8942,
    longitude: -95.0457,
    postal_code: '56118'
  },
  '56119': {
    country: 'US',
    city: 'Brewster',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.7032,
    longitude: -95.4807,
    postal_code: '56119'
  },
  '56120': {
    country: 'US',
    city: 'Butterfield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Watonwan',
    latitude: 43.9654,
    longitude: -94.7956,
    postal_code: '56120'
  },
  '56121': {
    country: 'US',
    city: 'Ceylon',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Martin',
    latitude: 43.5382,
    longitude: -94.6149,
    postal_code: '56121'
  },
  '56122': {
    country: 'US',
    city: 'Chandler',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Murray',
    latitude: 43.9163,
    longitude: -95.9296,
    postal_code: '56122'
  },
  '56123': {
    country: 'US',
    city: 'Currie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Murray',
    latitude: 44.0946,
    longitude: -95.6953,
    postal_code: '56123'
  },
  '56125': {
    country: 'US',
    city: 'Dovray',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Murray',
    latitude: 44.0533,
    longitude: -95.5499,
    postal_code: '56125'
  },
  '56127': {
    country: 'US',
    city: 'Dunnell',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Martin',
    latitude: 43.5531,
    longitude: -94.7876,
    postal_code: '56127'
  },
  '56128': {
    country: 'US',
    city: 'Edgerton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pipestone',
    latitude: 43.8824,
    longitude: -96.1463,
    postal_code: '56128'
  },
  '56129': {
    country: 'US',
    city: 'Ellsworth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.5265,
    longitude: -96.0112,
    postal_code: '56129'
  },
  '56131': {
    country: 'US',
    city: 'Fulda',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Murray',
    latitude: 43.8753,
    longitude: -95.5979,
    postal_code: '56131'
  },
  '56132': {
    country: 'US',
    city: 'Garvin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lyon',
    latitude: 44.2293,
    longitude: -95.7672,
    postal_code: '56132'
  },
  '56134': {
    country: 'US',
    city: 'Hardwick',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rock',
    latitude: 43.7911,
    longitude: -96.2161,
    postal_code: '56134'
  },
  '56136': {
    country: 'US',
    city: 'Hendricks',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lincoln',
    latitude: 44.4995,
    longitude: -96.4078,
    postal_code: '56136'
  },
  '56137': {
    country: 'US',
    city: 'Heron Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Jackson',
    latitude: 43.7979,
    longitude: -95.3267,
    postal_code: '56137'
  },
  '56138': {
    country: 'US',
    city: 'Hills',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rock',
    latitude: 43.5335,
    longitude: -96.3645,
    postal_code: '56138'
  },
  '56139': {
    country: 'US',
    city: 'Holland',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pipestone',
    latitude: 44.0759,
    longitude: -96.1903,
    postal_code: '56139'
  },
  '56140': {
    country: 'US',
    city: 'Ihlen',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pipestone',
    latitude: 43.9072,
    longitude: -96.3673,
    postal_code: '56140'
  },
  '56141': {
    country: 'US',
    city: 'Iona',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Murray',
    latitude: 43.9026,
    longitude: -95.7718,
    postal_code: '56141'
  },
  '56142': {
    country: 'US',
    city: 'Ivanhoe',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lincoln',
    latitude: 44.4633,
    longitude: -96.2473,
    postal_code: '56142'
  },
  '56143': {
    country: 'US',
    city: 'Jackson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Jackson',
    latitude: 43.6457,
    longitude: -94.9938,
    postal_code: '56143'
  },
  '56144': {
    country: 'US',
    city: 'Jasper',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pipestone',
    latitude: 43.8568,
    longitude: -96.385,
    postal_code: '56144'
  },
  '56145': {
    country: 'US',
    city: 'Jeffers',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cottonwood',
    latitude: 44.0737,
    longitude: -95.1547,
    postal_code: '56145'
  },
  '56146': {
    country: 'US',
    city: 'Kanaranzi',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rock',
    latitude: 43.5758,
    longitude: -96.0945,
    postal_code: '56146'
  },
  '56147': {
    country: 'US',
    city: 'Kenneth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rock',
    latitude: 43.7637,
    longitude: -96.1089,
    postal_code: '56147'
  },
  '56149': {
    country: 'US',
    city: 'Lake Benton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lincoln',
    latitude: 44.2782,
    longitude: -96.291,
    postal_code: '56149'
  },
  '56150': {
    country: 'US',
    city: 'Lakefield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Jackson',
    latitude: 43.6545,
    longitude: -95.1848,
    postal_code: '56150'
  },
  '56151': {
    country: 'US',
    city: 'Lake Wilson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Murray',
    latitude: 44.0095,
    longitude: -95.9797,
    postal_code: '56151'
  },
  '56152': {
    country: 'US',
    city: 'Lamberton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.2378,
    longitude: -95.2739,
    postal_code: '56152'
  },
  '56153': {
    country: 'US',
    city: 'Leota',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.8404,
    longitude: -96.0128,
    postal_code: '56153'
  },
  '56155': {
    country: 'US',
    city: 'Lismore',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.7339,
    longitude: -95.9682,
    postal_code: '56155'
  },
  '56156': {
    country: 'US',
    city: 'Luverne',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rock',
    latitude: 43.6614,
    longitude: -96.2216,
    postal_code: '56156'
  },
  '56157': {
    country: 'US',
    city: 'Lynd',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lyon',
    latitude: 44.4032,
    longitude: -95.9233,
    postal_code: '56157'
  },
  '56158': {
    country: 'US',
    city: 'Magnolia',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rock',
    latitude: 43.644,
    longitude: -96.0769,
    postal_code: '56158'
  },
  '56159': {
    country: 'US',
    city: 'Mountain Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cottonwood',
    latitude: 43.939,
    longitude: -94.9273,
    postal_code: '56159'
  },
  '56160': {
    country: 'US',
    city: 'Odin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Watonwan',
    latitude: 43.8824,
    longitude: -94.774,
    postal_code: '56160'
  },
  '56161': {
    country: 'US',
    city: 'Okabena',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Jackson',
    latitude: 43.723,
    longitude: -95.3381,
    postal_code: '56161'
  },
  '56162': {
    country: 'US',
    city: 'Ormsby',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Martin',
    latitude: 43.8616,
    longitude: -94.6873,
    postal_code: '56162'
  },
  '56164': {
    country: 'US',
    city: 'Pipestone',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pipestone',
    latitude: 43.9893,
    longitude: -96.2652,
    postal_code: '56164'
  },
  '56165': {
    country: 'US',
    city: 'Reading',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.7136,
    longitude: -95.7389,
    postal_code: '56165'
  },
  '56166': {
    country: 'US',
    city: 'Revere',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.2391,
    longitude: -95.3557,
    postal_code: '56166'
  },
  '56167': {
    country: 'US',
    city: 'Round Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.562,
    longitude: -95.4502,
    postal_code: '56167'
  },
  '56168': {
    country: 'US',
    city: 'Rushmore',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.624,
    longitude: -95.7767,
    postal_code: '56168'
  },
  '56169': {
    country: 'US',
    city: 'Russell',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lyon',
    latitude: 44.3201,
    longitude: -95.9426,
    postal_code: '56169'
  },
  '56170': {
    country: 'US',
    city: 'Ruthton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pipestone',
    latitude: 44.1682,
    longitude: -96.0871,
    postal_code: '56170'
  },
  '56171': {
    country: 'US',
    city: 'Sherburn',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Martin',
    latitude: 43.6611,
    longitude: -94.7269,
    postal_code: '56171'
  },
  '56172': {
    country: 'US',
    city: 'Slayton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Murray',
    latitude: 43.9856,
    longitude: -95.7554,
    postal_code: '56172'
  },
  '56173': {
    country: 'US',
    city: 'Steen',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Rock',
    latitude: 43.5317,
    longitude: -96.2439,
    postal_code: '56173'
  },
  '56174': {
    country: 'US',
    city: 'Storden',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cottonwood',
    latitude: 44.0524,
    longitude: -95.3019,
    postal_code: '56174'
  },
  '56175': {
    country: 'US',
    city: 'Tracy',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lyon',
    latitude: 44.2342,
    longitude: -95.6213,
    postal_code: '56175'
  },
  '56176': {
    country: 'US',
    city: 'Trimont',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Martin',
    latitude: 43.7827,
    longitude: -94.7186,
    postal_code: '56176'
  },
  '56178': {
    country: 'US',
    city: 'Tyler',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lincoln',
    latitude: 44.2773,
    longitude: -96.1302,
    postal_code: '56178'
  },
  '56180': {
    country: 'US',
    city: 'Walnut Grove',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.2294,
    longitude: -95.4965,
    postal_code: '56180'
  },
  '56181': {
    country: 'US',
    city: 'Welcome',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Martin',
    latitude: 43.6705,
    longitude: -94.5886,
    postal_code: '56181'
  },
  '56183': {
    country: 'US',
    city: 'Westbrook',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cottonwood',
    latitude: 44.0654,
    longitude: -95.4232,
    postal_code: '56183'
  },
  '56185': {
    country: 'US',
    city: 'Wilmont',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.7692,
    longitude: -95.8324,
    postal_code: '56185'
  },
  '56186': {
    country: 'US',
    city: 'Woodstock',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pipestone',
    latitude: 43.9873,
    longitude: -96.1192,
    postal_code: '56186'
  },
  '56187': {
    country: 'US',
    city: 'Worthington',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Nobles',
    latitude: 43.6286,
    longitude: -95.6059,
    postal_code: '56187'
  },
  '56201': {
    country: 'US',
    city: 'Willmar',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 45.1393,
    longitude: -95.0523,
    postal_code: '56201'
  },
  '56207': {
    country: 'US',
    city: 'Alberta',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stevens',
    latitude: 45.5571,
    longitude: -96.0498,
    postal_code: '56207'
  },
  '56208': {
    country: 'US',
    city: 'Appleton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Swift',
    latitude: 45.2054,
    longitude: -95.9949,
    postal_code: '56208'
  },
  '56209': {
    country: 'US',
    city: 'Atwater',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 45.1116,
    longitude: -94.7938,
    postal_code: '56209'
  },
  '56210': {
    country: 'US',
    city: 'Barry',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Big Stone',
    latitude: 45.5593,
    longitude: -96.5589,
    postal_code: '56210'
  },
  '56211': {
    country: 'US',
    city: 'Beardsley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Big Stone',
    latitude: 45.5539,
    longitude: -96.706,
    postal_code: '56211'
  },
  '56212': {
    country: 'US',
    city: 'Bellingham',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lac qui Parle',
    latitude: 45.1556,
    longitude: -96.3224,
    postal_code: '56212'
  },
  '56214': {
    country: 'US',
    city: 'Belview',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.6055,
    longitude: -95.3178,
    postal_code: '56214'
  },
  '56215': {
    country: 'US',
    city: 'Benson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Swift',
    latitude: 45.3129,
    longitude: -95.5766,
    postal_code: '56215'
  },
  '56216': {
    country: 'US',
    city: 'Blomkest',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 44.9501,
    longitude: -95.0588,
    postal_code: '56216'
  },
  '56218': {
    country: 'US',
    city: 'Boyd',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lac qui Parle',
    latitude: 44.8507,
    longitude: -95.9421,
    postal_code: '56218'
  },
  '56219': {
    country: 'US',
    city: 'Browns Valley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Traverse',
    latitude: 45.6069,
    longitude: -96.8063,
    postal_code: '56219'
  },
  '56220': {
    country: 'US',
    city: 'Canby',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Yellow Medicine',
    latitude: 44.7202,
    longitude: -96.2784,
    postal_code: '56220'
  },
  '56221': {
    country: 'US',
    city: 'Chokio',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stevens',
    latitude: 45.5524,
    longitude: -96.1733,
    postal_code: '56221'
  },
  '56222': {
    country: 'US',
    city: 'Clara City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chippewa',
    latitude: 44.9639,
    longitude: -95.3499,
    postal_code: '56222'
  },
  '56223': {
    country: 'US',
    city: 'Clarkfield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Yellow Medicine',
    latitude: 44.7743,
    longitude: -95.8304,
    postal_code: '56223'
  },
  '56224': {
    country: 'US',
    city: 'Clements',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.3943,
    longitude: -95.0474,
    postal_code: '56224'
  },
  '56225': {
    country: 'US',
    city: 'Clinton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Big Stone',
    latitude: 45.4577,
    longitude: -96.4182,
    postal_code: '56225'
  },
  '56226': {
    country: 'US',
    city: 'Clontarf',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Swift',
    latitude: 45.3763,
    longitude: -95.6787,
    postal_code: '56226'
  },
  '56227': {
    country: 'US',
    city: 'Correll',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Big Stone',
    latitude: 45.2907,
    longitude: -96.1753,
    postal_code: '56227'
  },
  '56228': {
    country: 'US',
    city: 'Cosmos',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Meeker',
    latitude: 44.959,
    longitude: -94.6978,
    postal_code: '56228'
  },
  '56229': {
    country: 'US',
    city: 'Cottonwood',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lyon',
    latitude: 44.6003,
    longitude: -95.6925,
    postal_code: '56229'
  },
  '56230': {
    country: 'US',
    city: 'Danube',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Renville',
    latitude: 44.7919,
    longitude: -95.0972,
    postal_code: '56230'
  },
  '56231': {
    country: 'US',
    city: 'Danvers',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Swift',
    latitude: 45.2818,
    longitude: -95.7219,
    postal_code: '56231'
  },
  '56232': {
    country: 'US',
    city: 'Dawson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lac qui Parle',
    latitude: 44.9327,
    longitude: -96.0545,
    postal_code: '56232'
  },
  '56235': {
    country: 'US',
    city: 'Donnelly',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stevens',
    latitude: 45.7014,
    longitude: -96.0649,
    postal_code: '56235'
  },
  '56236': {
    country: 'US',
    city: 'Dumont',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Traverse',
    latitude: 45.6717,
    longitude: -96.4061,
    postal_code: '56236'
  },
  '56237': {
    country: 'US',
    city: 'Echo',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Yellow Medicine',
    latitude: 44.631,
    longitude: -95.4182,
    postal_code: '56237'
  },
  '56239': {
    country: 'US',
    city: 'Ghent',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lyon',
    latitude: 44.5078,
    longitude: -95.8936,
    postal_code: '56239'
  },
  '56240': {
    country: 'US',
    city: 'Graceville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Big Stone',
    latitude: 45.5602,
    longitude: -96.4209,
    postal_code: '56240'
  },
  '56241': {
    country: 'US',
    city: 'Granite Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Yellow Medicine',
    latitude: 44.8087,
    longitude: -95.5516,
    postal_code: '56241'
  },
  '56243': {
    country: 'US',
    city: 'Grove City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Meeker',
    latitude: 45.1532,
    longitude: -94.6878,
    postal_code: '56243'
  },
  '56244': {
    country: 'US',
    city: 'Hancock',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stevens',
    latitude: 45.4985,
    longitude: -95.8008,
    postal_code: '56244'
  },
  '56245': {
    country: 'US',
    city: 'Hanley Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Yellow Medicine',
    latitude: 44.6789,
    longitude: -95.6825,
    postal_code: '56245'
  },
  '56248': {
    country: 'US',
    city: 'Herman',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Grant',
    latitude: 45.8086,
    longitude: -96.1434,
    postal_code: '56248'
  },
  '56249': {
    country: 'US',
    city: 'Holloway',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Swift',
    latitude: 45.2708,
    longitude: -95.9164,
    postal_code: '56249'
  },
  '56251': {
    country: 'US',
    city: 'Kandiyohi',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 45.1426,
    longitude: -94.9183,
    postal_code: '56251'
  },
  '56252': {
    country: 'US',
    city: 'Kerkhoven',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Swift',
    latitude: 45.2096,
    longitude: -95.3115,
    postal_code: '56252'
  },
  '56253': {
    country: 'US',
    city: 'Lake Lillian',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 44.966,
    longitude: -94.9014,
    postal_code: '56253'
  },
  '56255': {
    country: 'US',
    city: 'Lucan',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.4132,
    longitude: -95.4119,
    postal_code: '56255'
  },
  '56256': {
    country: 'US',
    city: 'Madison',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lac qui Parle',
    latitude: 44.9946,
    longitude: -96.1645,
    postal_code: '56256'
  },
  '56257': {
    country: 'US',
    city: 'Marietta',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lac qui Parle',
    latitude: 44.9636,
    longitude: -96.4094,
    postal_code: '56257'
  },
  '56258': {
    country: 'US',
    city: 'Marshall',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lyon',
    latitude: 44.4481,
    longitude: -95.7795,
    postal_code: '56258'
  },
  '56260': {
    country: 'US',
    city: 'Maynard',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chippewa',
    latitude: 44.9226,
    longitude: -95.4845,
    postal_code: '56260'
  },
  '56262': {
    country: 'US',
    city: 'Milan',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chippewa',
    latitude: 45.1143,
    longitude: -95.869,
    postal_code: '56262'
  },
  '56263': {
    country: 'US',
    city: 'Milroy',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.4365,
    longitude: -95.5545,
    postal_code: '56263'
  },
  '56264': {
    country: 'US',
    city: 'Minneota',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lyon',
    latitude: 44.5587,
    longitude: -95.9767,
    postal_code: '56264'
  },
  '56265': {
    country: 'US',
    city: 'Montevideo',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chippewa',
    latitude: 44.9688,
    longitude: -95.6765,
    postal_code: '56265'
  },
  '56266': {
    country: 'US',
    city: 'Morgan',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.3926,
    longitude: -94.9218,
    postal_code: '56266'
  },
  '56267': {
    country: 'US',
    city: 'Morris',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stevens',
    latitude: 45.5921,
    longitude: -95.9172,
    postal_code: '56267'
  },
  '56270': {
    country: 'US',
    city: 'Morton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Renville',
    latitude: 44.5662,
    longitude: -95.0268,
    postal_code: '56270'
  },
  '56271': {
    country: 'US',
    city: 'Murdock',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Swift',
    latitude: 45.2161,
    longitude: -95.4049,
    postal_code: '56271'
  },
  '56273': {
    country: 'US',
    city: 'New London',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 45.2949,
    longitude: -94.948,
    postal_code: '56273'
  },
  '56274': {
    country: 'US',
    city: 'Norcross',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Grant',
    latitude: 45.8858,
    longitude: -96.1342,
    postal_code: '56274'
  },
  '56276': {
    country: 'US',
    city: 'Odessa',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Big Stone',
    latitude: 45.2597,
    longitude: -96.3289,
    postal_code: '56276'
  },
  '56277': {
    country: 'US',
    city: 'Olivia',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Renville',
    latitude: 44.7706,
    longitude: -94.9727,
    postal_code: '56277'
  },
  '56278': {
    country: 'US',
    city: 'Ortonville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Big Stone',
    latitude: 45.3296,
    longitude: -96.4596,
    postal_code: '56278'
  },
  '56279': {
    country: 'US',
    city: 'Pennock',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 45.131,
    longitude: -95.1753,
    postal_code: '56279'
  },
  '56280': {
    country: 'US',
    city: 'Porter',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Yellow Medicine',
    latitude: 44.6565,
    longitude: -96.1581,
    postal_code: '56280'
  },
  '56281': {
    country: 'US',
    city: 'Prinsburg',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 44.9371,
    longitude: -95.1865,
    postal_code: '56281'
  },
  '56282': {
    country: 'US',
    city: 'Raymond',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 45.0181,
    longitude: -95.2208,
    postal_code: '56282'
  },
  '56283': {
    country: 'US',
    city: 'Redwood Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.5394,
    longitude: -95.1169,
    postal_code: '56283'
  },
  '56284': {
    country: 'US',
    city: 'Renville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Renville',
    latitude: 44.7776,
    longitude: -95.1989,
    postal_code: '56284'
  },
  '56285': {
    country: 'US',
    city: 'Sacred Heart',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Renville',
    latitude: 44.7975,
    longitude: -95.3538,
    postal_code: '56285'
  },
  '56287': {
    country: 'US',
    city: 'Seaforth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.4772,
    longitude: -95.3264,
    postal_code: '56287'
  },
  '56288': {
    country: 'US',
    city: 'Spicer',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 45.2241,
    longitude: -94.9116,
    postal_code: '56288'
  },
  '56289': {
    country: 'US',
    city: 'Sunburg',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kandiyohi',
    latitude: 45.3583,
    longitude: -95.2049,
    postal_code: '56289'
  },
  '56291': {
    country: 'US',
    city: 'Taunton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lyon',
    latitude: 44.5951,
    longitude: -96.0526,
    postal_code: '56291'
  },
  '56292': {
    country: 'US',
    city: 'Vesta',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.5051,
    longitude: -95.4118,
    postal_code: '56292'
  },
  '56293': {
    country: 'US',
    city: 'Wabasso',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.4059,
    longitude: -95.2632,
    postal_code: '56293'
  },
  '56294': {
    country: 'US',
    city: 'Wanda',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Redwood',
    latitude: 44.3149,
    longitude: -95.213,
    postal_code: '56294'
  },
  '56295': {
    country: 'US',
    city: 'Watson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Chippewa',
    latitude: 45.0412,
    longitude: -95.834,
    postal_code: '56295'
  },
  '56296': {
    country: 'US',
    city: 'Wheaton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Traverse',
    latitude: 45.8111,
    longitude: -96.4866,
    postal_code: '56296'
  },
  '56297': {
    country: 'US',
    city: 'Wood Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Yellow Medicine',
    latitude: 44.6379,
    longitude: -95.5409,
    postal_code: '56297'
  },
  '56301': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.541,
    longitude: -94.1819,
    postal_code: '56301'
  },
  '56302': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5608,
    longitude: -94.1625,
    postal_code: '56302'
  },
  '56303': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5713,
    longitude: -94.2036,
    postal_code: '56303'
  },
  '56304': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5521,
    longitude: -94.1284,
    postal_code: '56304'
  },
  '56307': {
    country: 'US',
    city: 'Albany',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.6151,
    longitude: -94.574,
    postal_code: '56307'
  },
  '56308': {
    country: 'US',
    city: 'Alexandria',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Douglas',
    latitude: 45.8817,
    longitude: -95.382,
    postal_code: '56308'
  },
  '56309': {
    country: 'US',
    city: 'Ashby',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Grant',
    latitude: 46.0781,
    longitude: -95.8214,
    postal_code: '56309'
  },
  '56310': {
    country: 'US',
    city: 'Avon',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.6122,
    longitude: -94.436,
    postal_code: '56310'
  },
  '56311': {
    country: 'US',
    city: 'Barrett',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Grant',
    latitude: 45.8996,
    longitude: -95.8754,
    postal_code: '56311'
  },
  '56312': {
    country: 'US',
    city: 'Belgrade',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.4865,
    longitude: -94.9699,
    postal_code: '56312'
  },
  '56313': {
    country: 'US',
    city: 'Bock',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mille Lacs',
    latitude: 45.7845,
    longitude: -93.5522,
    postal_code: '56313'
  },
  '56314': {
    country: 'US',
    city: 'Bowlus',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 45.8121,
    longitude: -94.4175,
    postal_code: '56314'
  },
  '56315': {
    country: 'US',
    city: 'Brandon',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Douglas',
    latitude: 45.9652,
    longitude: -95.5987,
    postal_code: '56315'
  },
  '56316': {
    country: 'US',
    city: 'Brooten',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5011,
    longitude: -95.1245,
    postal_code: '56316'
  },
  '56317': {
    country: 'US',
    city: 'Buckman',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 45.8975,
    longitude: -94.0936,
    postal_code: '56317'
  },
  '56318': {
    country: 'US',
    city: 'Burtrum',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Todd',
    latitude: 45.888,
    longitude: -94.6962,
    postal_code: '56318'
  },
  '56319': {
    country: 'US',
    city: 'Carlos',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Douglas',
    latitude: 45.9726,
    longitude: -95.3105,
    postal_code: '56319'
  },
  '56320': {
    country: 'US',
    city: 'Cold Spring',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.45,
    longitude: -94.4378,
    postal_code: '56320'
  },
  '56321': {
    country: 'US',
    city: 'Collegeville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5783,
    longitude: -94.4199,
    postal_code: '56321'
  },
  '56323': {
    country: 'US',
    city: 'Cyrus',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pope',
    latitude: 45.6414,
    longitude: -95.7096,
    postal_code: '56323'
  },
  '56324': {
    country: 'US',
    city: 'Dalton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.1738,
    longitude: -95.9156,
    postal_code: '56324'
  },
  '56325': {
    country: 'US',
    city: 'Elrosa',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5636,
    longitude: -94.9464,
    postal_code: '56325'
  },
  '56326': {
    country: 'US',
    city: 'Evansville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Douglas',
    latitude: 46.0153,
    longitude: -95.6951,
    postal_code: '56326'
  },
  '56327': {
    country: 'US',
    city: 'Farwell',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pope',
    latitude: 45.7244,
    longitude: -95.6656,
    postal_code: '56327'
  },
  '56328': {
    country: 'US',
    city: 'Flensburg',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 45.9552,
    longitude: -94.535,
    postal_code: '56328'
  },
  '56329': {
    country: 'US',
    city: 'Foley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Benton',
    latitude: 45.6919,
    longitude: -93.9148,
    postal_code: '56329'
  },
  '56330': {
    country: 'US',
    city: 'Foreston',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mille Lacs',
    latitude: 45.7028,
    longitude: -93.6958,
    postal_code: '56330'
  },
  '56331': {
    country: 'US',
    city: 'Freeport',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.6731,
    longitude: -94.6785,
    postal_code: '56331'
  },
  '56332': {
    country: 'US',
    city: 'Garfield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Douglas',
    latitude: 45.9847,
    longitude: -95.5066,
    postal_code: '56332'
  },
  '56333': {
    country: 'US',
    city: 'Gilman',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Benton',
    latitude: 45.6917,
    longitude: -94.0563,
    postal_code: '56333'
  },
  '56334': {
    country: 'US',
    city: 'Glenwood',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pope',
    latitude: 45.6429,
    longitude: -95.3868,
    postal_code: '56334'
  },
  '56335': {
    country: 'US',
    city: 'Greenwald',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5971,
    longitude: -94.8515,
    postal_code: '56335'
  },
  '56336': {
    country: 'US',
    city: 'Grey Eagle',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Todd',
    latitude: 45.8252,
    longitude: -94.7467,
    postal_code: '56336'
  },
  '56338': {
    country: 'US',
    city: 'Hillman',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 46.0678,
    longitude: -93.8812,
    postal_code: '56338'
  },
  '56339': {
    country: 'US',
    city: 'Hoffman',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Grant',
    latitude: 45.8233,
    longitude: -95.7955,
    postal_code: '56339'
  },
  '56340': {
    country: 'US',
    city: 'Holdingford',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.7249,
    longitude: -94.4581,
    postal_code: '56340'
  },
  '56341': {
    country: 'US',
    city: 'Holmes City',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Douglas',
    latitude: 45.831,
    longitude: -95.5416,
    postal_code: '56341'
  },
  '56342': {
    country: 'US',
    city: 'Isle',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mille Lacs',
    latitude: 46.1696,
    longitude: -93.4741,
    postal_code: '56342'
  },
  '56343': {
    country: 'US',
    city: 'Kensington',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Douglas',
    latitude: 45.8176,
    longitude: -95.6945,
    postal_code: '56343'
  },
  '56344': {
    country: 'US',
    city: 'Lastrup',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 46.0394,
    longitude: -94.0633,
    postal_code: '56344'
  },
  '56345': {
    country: 'US',
    city: 'Little Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 45.9811,
    longitude: -94.3604,
    postal_code: '56345'
  },
  '56347': {
    country: 'US',
    city: 'Long Prairie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Todd',
    latitude: 45.9747,
    longitude: -94.8656,
    postal_code: '56347'
  },
  '56349': {
    country: 'US',
    city: 'Lowry',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pope',
    latitude: 45.7105,
    longitude: -95.5322,
    postal_code: '56349'
  },
  '56350': {
    country: 'US',
    city: 'Mc Grath',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Aitkin',
    latitude: 46.1957,
    longitude: -93.3774,
    postal_code: '56350'
  },
  '56352': {
    country: 'US',
    city: 'Melrose',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.6582,
    longitude: -94.8198,
    postal_code: '56352'
  },
  '56353': {
    country: 'US',
    city: 'Milaca',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mille Lacs',
    latitude: 45.7795,
    longitude: -93.6453,
    postal_code: '56353'
  },
  '56354': {
    country: 'US',
    city: 'Miltona',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Douglas',
    latitude: 46.0545,
    longitude: -95.3258,
    postal_code: '56354'
  },
  '56355': {
    country: 'US',
    city: 'Nelson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Douglas',
    latitude: 45.9356,
    longitude: -95.2398,
    postal_code: '56355'
  },
  '56356': {
    country: 'US',
    city: 'New Munich',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.6297,
    longitude: -94.7519,
    postal_code: '56356'
  },
  '56357': {
    country: 'US',
    city: 'Oak Park',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Benton',
    latitude: 45.7022,
    longitude: -93.8164,
    postal_code: '56357'
  },
  '56358': {
    country: 'US',
    city: 'Ogilvie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kanabec',
    latitude: 45.8476,
    longitude: -93.4359,
    postal_code: '56358'
  },
  '56359': {
    country: 'US',
    city: 'Onamia',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mille Lacs',
    latitude: 46.0902,
    longitude: -93.6867,
    postal_code: '56359'
  },
  '56360': {
    country: 'US',
    city: 'Osakis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Douglas',
    latitude: 45.8768,
    longitude: -95.1332,
    postal_code: '56360'
  },
  '56361': {
    country: 'US',
    city: 'Parkers Prairie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.153,
    longitude: -95.3289,
    postal_code: '56361'
  },
  '56362': {
    country: 'US',
    city: 'Paynesville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.3988,
    longitude: -94.7157,
    postal_code: '56362'
  },
  '56363': {
    country: 'US',
    city: 'Pease',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mille Lacs',
    latitude: 45.6974,
    longitude: -93.6465,
    postal_code: '56363'
  },
  '56364': {
    country: 'US',
    city: 'Pierz',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 46.0081,
    longitude: -94.0853,
    postal_code: '56364'
  },
  '56367': {
    country: 'US',
    city: 'Rice',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Benton',
    latitude: 45.7364,
    longitude: -94.1658,
    postal_code: '56367'
  },
  '56368': {
    country: 'US',
    city: 'Richmond',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.4605,
    longitude: -94.5361,
    postal_code: '56368'
  },
  '56369': {
    country: 'US',
    city: 'Rockville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.4719,
    longitude: -94.3408,
    postal_code: '56369'
  },
  '56371': {
    country: 'US',
    city: 'Roscoe',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.4332,
    longitude: -94.6366,
    postal_code: '56371'
  },
  '56372': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5608,
    longitude: -94.1625,
    postal_code: '56372'
  },
  '56373': {
    country: 'US',
    city: 'Royalton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 45.8589,
    longitude: -94.2211,
    postal_code: '56373'
  },
  '56374': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5651,
    longitude: -94.3367,
    postal_code: '56374'
  },
  '56375': {
    country: 'US',
    city: 'Saint Stephen',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.7118,
    longitude: -94.2817,
    postal_code: '56375'
  },
  '56376': {
    country: 'US',
    city: 'Saint Martin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5019,
    longitude: -94.6689,
    postal_code: '56376'
  },
  '56377': {
    country: 'US',
    city: 'Sartell',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.6318,
    longitude: -94.2136,
    postal_code: '56377'
  },
  '56378': {
    country: 'US',
    city: 'Sauk Centre',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.7281,
    longitude: -94.9682,
    postal_code: '56378'
  },
  '56379': {
    country: 'US',
    city: 'Sauk Rapids',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Benton',
    latitude: 45.604,
    longitude: -94.1591,
    postal_code: '56379'
  },
  '56381': {
    country: 'US',
    city: 'Starbuck',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pope',
    latitude: 45.5926,
    longitude: -95.5421,
    postal_code: '56381'
  },
  '56382': {
    country: 'US',
    city: 'Swanville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 45.9431,
    longitude: -94.6289,
    postal_code: '56382'
  },
  '56384': {
    country: 'US',
    city: 'Upsala',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 45.8097,
    longitude: -94.5755,
    postal_code: '56384'
  },
  '56385': {
    country: 'US',
    city: 'Villard',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pope',
    latitude: 45.7118,
    longitude: -95.2414,
    postal_code: '56385'
  },
  '56386': {
    country: 'US',
    city: 'Wahkon',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mille Lacs',
    latitude: 46.1121,
    longitude: -93.4972,
    postal_code: '56386'
  },
  '56387': {
    country: 'US',
    city: 'Waite Park',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5497,
    longitude: -94.2245,
    postal_code: '56387'
  },
  '56388': {
    country: 'US',
    city: 'Waite Park',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 45.5572,
    longitude: -94.2242,
    postal_code: '56388'
  },
  '56389': {
    country: 'US',
    city: 'West Union',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Todd',
    latitude: 45.7995,
    longitude: -95.0821,
    postal_code: '56389'
  },
  '56393': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5608,
    longitude: -94.1625,
    postal_code: '56393'
  },
  '56395': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5608,
    longitude: -94.1625,
    postal_code: '56395'
  },
  '56396': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5608,
    longitude: -94.1625,
    postal_code: '56396'
  },
  '56397': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5608,
    longitude: -94.1625,
    postal_code: '56397'
  },
  '56398': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5608,
    longitude: -94.1625,
    postal_code: '56398'
  },
  '56399': {
    country: 'US',
    city: 'Saint Cloud',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Stearns',
    latitude: 45.5608,
    longitude: -94.1625,
    postal_code: '56399'
  },
  '56401': {
    country: 'US',
    city: 'Brainerd',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.3502,
    longitude: -94.1,
    postal_code: '56401'
  },
  '56425': {
    country: 'US',
    city: 'Baxter',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.3429,
    longitude: -94.2822,
    postal_code: '56425'
  },
  '56430': {
    country: 'US',
    city: 'Ah Gwah Ching',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 47.0741,
    longitude: -94.5628,
    postal_code: '56430'
  },
  '56431': {
    country: 'US',
    city: 'Aitkin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Aitkin',
    latitude: 46.4799,
    longitude: -93.6454,
    postal_code: '56431'
  },
  '56433': {
    country: 'US',
    city: 'Akeley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hubbard',
    latitude: 47.001,
    longitude: -94.7234,
    postal_code: '56433'
  },
  '56434': {
    country: 'US',
    city: 'Aldrich',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wadena',
    latitude: 46.3797,
    longitude: -94.9364,
    postal_code: '56434'
  },
  '56435': {
    country: 'US',
    city: 'Backus',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 46.8699,
    longitude: -94.3959,
    postal_code: '56435'
  },
  '56436': {
    country: 'US',
    city: 'Benedict',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hubbard',
    latitude: 47.158,
    longitude: -94.6906,
    postal_code: '56436'
  },
  '56437': {
    country: 'US',
    city: 'Bertha',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Todd',
    latitude: 46.2515,
    longitude: -95.0357,
    postal_code: '56437'
  },
  '56438': {
    country: 'US',
    city: 'Browerville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Todd',
    latitude: 46.0905,
    longitude: -94.8346,
    postal_code: '56438'
  },
  '56440': {
    country: 'US',
    city: 'Clarissa',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Todd',
    latitude: 46.1373,
    longitude: -94.9572,
    postal_code: '56440'
  },
  '56441': {
    country: 'US',
    city: 'Crosby',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.5091,
    longitude: -93.9874,
    postal_code: '56441'
  },
  '56442': {
    country: 'US',
    city: 'Crosslake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.6773,
    longitude: -94.1128,
    postal_code: '56442'
  },
  '56443': {
    country: 'US',
    city: 'Cushing',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 46.2022,
    longitude: -94.6185,
    postal_code: '56443'
  },
  '56444': {
    country: 'US',
    city: 'Deerwood',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.4446,
    longitude: -93.8849,
    postal_code: '56444'
  },
  '56446': {
    country: 'US',
    city: 'Eagle Bend',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Todd',
    latitude: 46.117,
    longitude: -95.0961,
    postal_code: '56446'
  },
  '56447': {
    country: 'US',
    city: 'Emily',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.7478,
    longitude: -93.9484,
    postal_code: '56447'
  },
  '56448': {
    country: 'US',
    city: 'Fifty Lakes',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.747,
    longitude: -94.0656,
    postal_code: '56448'
  },
  '56449': {
    country: 'US',
    city: 'Fort Ripley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.2099,
    longitude: -94.2527,
    postal_code: '56449'
  },
  '56450': {
    country: 'US',
    city: 'Garrison',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.2314,
    longitude: -93.8341,
    postal_code: '56450'
  },
  '56452': {
    country: 'US',
    city: 'Hackensack',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 46.9884,
    longitude: -94.5033,
    postal_code: '56452'
  },
  '56453': {
    country: 'US',
    city: 'Hewitt',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Todd',
    latitude: 46.325,
    longitude: -95.0504,
    postal_code: '56453'
  },
  '56455': {
    country: 'US',
    city: 'Ironton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.4778,
    longitude: -93.9789,
    postal_code: '56455'
  },
  '56456': {
    country: 'US',
    city: 'Jenkins',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.6461,
    longitude: -94.3347,
    postal_code: '56456'
  },
  '56458': {
    country: 'US',
    city: 'Lake George',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hubbard',
    latitude: 47.215,
    longitude: -95.0021,
    postal_code: '56458'
  },
  '56459': {
    country: 'US',
    city: 'Lake Hubert',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.4987,
    longitude: -94.2519,
    postal_code: '56459'
  },
  '56461': {
    country: 'US',
    city: 'Laporte',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hubbard',
    latitude: 47.2368,
    longitude: -94.7772,
    postal_code: '56461'
  },
  '56464': {
    country: 'US',
    city: 'Menahga',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wadena',
    latitude: 46.7572,
    longitude: -95.0714,
    postal_code: '56464'
  },
  '56465': {
    country: 'US',
    city: 'Merrifield',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.5393,
    longitude: -94.1344,
    postal_code: '56465'
  },
  '56466': {
    country: 'US',
    city: 'Motley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 46.2881,
    longitude: -94.5638,
    postal_code: '56466'
  },
  '56467': {
    country: 'US',
    city: 'Nevis',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hubbard',
    latitude: 46.9319,
    longitude: -94.846,
    postal_code: '56467'
  },
  '56468': {
    country: 'US',
    city: 'Nisswa',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.4929,
    longitude: -94.2976,
    postal_code: '56468'
  },
  '56469': {
    country: 'US',
    city: 'Palisade',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Aitkin',
    latitude: 46.6895,
    longitude: -93.5627,
    postal_code: '56469'
  },
  '56470': {
    country: 'US',
    city: 'Park Rapids',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Hubbard',
    latitude: 46.9377,
    longitude: -95.0383,
    postal_code: '56470'
  },
  '56472': {
    country: 'US',
    city: 'Pequot Lakes',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Crow Wing',
    latitude: 46.6257,
    longitude: -94.2684,
    postal_code: '56472'
  },
  '56473': {
    country: 'US',
    city: 'Pillager',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 46.3607,
    longitude: -94.4895,
    postal_code: '56473'
  },
  '56474': {
    country: 'US',
    city: 'Pine River',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 46.6938,
    longitude: -94.4477,
    postal_code: '56474'
  },
  '56475': {
    country: 'US',
    city: 'Randall',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Morrison',
    latitude: 46.0734,
    longitude: -94.5005,
    postal_code: '56475'
  },
  '56477': {
    country: 'US',
    city: 'Sebeka',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wadena',
    latitude: 46.6306,
    longitude: -95.0681,
    postal_code: '56477'
  },
  '56479': {
    country: 'US',
    city: 'Staples',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Todd',
    latitude: 46.3536,
    longitude: -94.7633,
    postal_code: '56479'
  },
  '56481': {
    country: 'US',
    city: 'Verndale',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wadena',
    latitude: 46.5065,
    longitude: -94.9676,
    postal_code: '56481'
  },
  '56482': {
    country: 'US',
    city: 'Wadena',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wadena',
    latitude: 46.4401,
    longitude: -95.1283,
    postal_code: '56482'
  },
  '56484': {
    country: 'US',
    city: 'Walker',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 47.0877,
    longitude: -94.5847,
    postal_code: '56484'
  },
  '56501': {
    country: 'US',
    city: 'Detroit Lakes',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 46.8172,
    longitude: -95.8453,
    postal_code: '56501'
  },
  '56502': {
    country: 'US',
    city: 'Detroit Lakes',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 46.8172,
    longitude: -95.8453,
    postal_code: '56502'
  },
  '56510': {
    country: 'US',
    city: 'Ada',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Norman',
    latitude: 47.3253,
    longitude: -96.5973,
    postal_code: '56510'
  },
  '56511': {
    country: 'US',
    city: 'Audubon',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 46.8719,
    longitude: -95.9881,
    postal_code: '56511'
  },
  '56514': {
    country: 'US',
    city: 'Barnesville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 46.6522,
    longitude: -96.4198,
    postal_code: '56514'
  },
  '56515': {
    country: 'US',
    city: 'Battle Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.3142,
    longitude: -95.7144,
    postal_code: '56515'
  },
  '56516': {
    country: 'US',
    city: 'Bejou',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mahnomen',
    latitude: 47.4491,
    longitude: -95.9454,
    postal_code: '56516'
  },
  '56517': {
    country: 'US',
    city: 'Beltrami',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.5721,
    longitude: -96.4549,
    postal_code: '56517'
  },
  '56518': {
    country: 'US',
    city: 'Bluffton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.4918,
    longitude: -95.2236,
    postal_code: '56518'
  },
  '56519': {
    country: 'US',
    city: 'Borup',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Norman',
    latitude: 47.1896,
    longitude: -96.553,
    postal_code: '56519'
  },
  '56520': {
    country: 'US',
    city: 'Breckenridge',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wilkin',
    latitude: 46.2797,
    longitude: -96.5622,
    postal_code: '56520'
  },
  '56521': {
    country: 'US',
    city: 'Callaway',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 47.0076,
    longitude: -95.944,
    postal_code: '56521'
  },
  '56522': {
    country: 'US',
    city: 'Campbell',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wilkin',
    latitude: 46.1402,
    longitude: -96.4433,
    postal_code: '56522'
  },
  '56523': {
    country: 'US',
    city: 'Climax',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.6837,
    longitude: -96.8715,
    postal_code: '56523'
  },
  '56524': {
    country: 'US',
    city: 'Clitherall',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.2757,
    longitude: -95.6308,
    postal_code: '56524'
  },
  '56525': {
    country: 'US',
    city: 'Comstock',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 46.6667,
    longitude: -96.7398,
    postal_code: '56525'
  },
  '56527': {
    country: 'US',
    city: 'Deer Creek',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.3911,
    longitude: -95.3214,
    postal_code: '56527'
  },
  '56528': {
    country: 'US',
    city: 'Dent',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.5554,
    longitude: -95.7644,
    postal_code: '56528'
  },
  '56529': {
    country: 'US',
    city: 'Dilworth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 46.8782,
    longitude: -96.7022,
    postal_code: '56529'
  },
  '56531': {
    country: 'US',
    city: 'Elbow Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Grant',
    latitude: 45.9953,
    longitude: -95.9671,
    postal_code: '56531'
  },
  '56533': {
    country: 'US',
    city: 'Elizabeth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.3803,
    longitude: -96.1326,
    postal_code: '56533'
  },
  '56534': {
    country: 'US',
    city: 'Erhard',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.4948,
    longitude: -96.0593,
    postal_code: '56534'
  },
  '56535': {
    country: 'US',
    city: 'Erskine',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.6618,
    longitude: -96.0122,
    postal_code: '56535'
  },
  '56536': {
    country: 'US',
    city: 'Felton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 47.0705,
    longitude: -96.5052,
    postal_code: '56536'
  },
  '56537': {
    country: 'US',
    city: 'Fergus Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.276,
    longitude: -96.0904,
    postal_code: '56537'
  },
  '56538': {
    country: 'US',
    city: 'Fergus Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.283,
    longitude: -96.0776,
    postal_code: '56538'
  },
  '56540': {
    country: 'US',
    city: 'Fertile',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.5361,
    longitude: -96.2803,
    postal_code: '56540'
  },
  '56541': {
    country: 'US',
    city: 'Flom',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Norman',
    latitude: 47.1588,
    longitude: -96.1309,
    postal_code: '56541'
  },
  '56542': {
    country: 'US',
    city: 'Fosston',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.5816,
    longitude: -95.7431,
    postal_code: '56542'
  },
  '56543': {
    country: 'US',
    city: 'Foxhome',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wilkin',
    latitude: 46.2584,
    longitude: -96.3176,
    postal_code: '56543'
  },
  '56544': {
    country: 'US',
    city: 'Frazee',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 46.7565,
    longitude: -95.5212,
    postal_code: '56544'
  },
  '56545': {
    country: 'US',
    city: 'Gary',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Norman',
    latitude: 47.3675,
    longitude: -96.2152,
    postal_code: '56545'
  },
  '56546': {
    country: 'US',
    city: 'Georgetown',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 47.0999,
    longitude: -96.727,
    postal_code: '56546'
  },
  '56547': {
    country: 'US',
    city: 'Glyndon',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 46.882,
    longitude: -96.5583,
    postal_code: '56547'
  },
  '56548': {
    country: 'US',
    city: 'Halstad',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Norman',
    latitude: 47.3551,
    longitude: -96.798,
    postal_code: '56548'
  },
  '56549': {
    country: 'US',
    city: 'Hawley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 46.9777,
    longitude: -96.4092,
    postal_code: '56549'
  },
  '56550': {
    country: 'US',
    city: 'Hendrum',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Norman',
    latitude: 47.2689,
    longitude: -96.7987,
    postal_code: '56550'
  },
  '56551': {
    country: 'US',
    city: 'Henning',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.3256,
    longitude: -95.3852,
    postal_code: '56551'
  },
  '56552': {
    country: 'US',
    city: 'Hitterdal',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 47.0014,
    longitude: -96.2888,
    postal_code: '56552'
  },
  '56553': {
    country: 'US',
    city: 'Kent',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wilkin',
    latitude: 46.4378,
    longitude: -96.6852,
    postal_code: '56553'
  },
  '56554': {
    country: 'US',
    city: 'Lake Park',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 46.8176,
    longitude: -96.067,
    postal_code: '56554'
  },
  '56556': {
    country: 'US',
    city: 'Mcintosh',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.6522,
    longitude: -95.8863,
    postal_code: '56556'
  },
  '56557': {
    country: 'US',
    city: 'Mahnomen',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mahnomen',
    latitude: 47.3362,
    longitude: -95.8856,
    postal_code: '56557'
  },
  '56560': {
    country: 'US',
    city: 'Moorhead',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 46.8677,
    longitude: -96.7572,
    postal_code: '56560'
  },
  '56561': {
    country: 'US',
    city: 'Moorhead',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 46.8739,
    longitude: -96.7695,
    postal_code: '56561'
  },
  '56562': {
    country: 'US',
    city: 'Moorhead',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 46.8739,
    longitude: -96.7695,
    postal_code: '56562'
  },
  '56563': {
    country: 'US',
    city: 'Moorhead',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 46.8739,
    longitude: -96.7695,
    postal_code: '56563'
  },
  '56565': {
    country: 'US',
    city: 'Nashua',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wilkin',
    latitude: 46.0537,
    longitude: -96.316,
    postal_code: '56565'
  },
  '56566': {
    country: 'US',
    city: 'Naytahwaush',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mahnomen',
    latitude: 47.2601,
    longitude: -95.6283,
    postal_code: '56566'
  },
  '56567': {
    country: 'US',
    city: 'New York Mills',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.5559,
    longitude: -95.4047,
    postal_code: '56567'
  },
  '56568': {
    country: 'US',
    city: 'Nielsville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.5373,
    longitude: -96.7574,
    postal_code: '56568'
  },
  '56569': {
    country: 'US',
    city: 'Ogema',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 47.1029,
    longitude: -95.9151,
    postal_code: '56569'
  },
  '56570': {
    country: 'US',
    city: 'Osage',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 46.9329,
    longitude: -95.2353,
    postal_code: '56570'
  },
  '56571': {
    country: 'US',
    city: 'Ottertail',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.4177,
    longitude: -95.5436,
    postal_code: '56571'
  },
  '56572': {
    country: 'US',
    city: 'Pelican Rapids',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.5994,
    longitude: -96.0662,
    postal_code: '56572'
  },
  '56573': {
    country: 'US',
    city: 'Perham',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.6031,
    longitude: -95.5818,
    postal_code: '56573'
  },
  '56574': {
    country: 'US',
    city: 'Perley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Norman',
    latitude: 47.1831,
    longitude: -96.7777,
    postal_code: '56574'
  },
  '56575': {
    country: 'US',
    city: 'Ponsford',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 47.0133,
    longitude: -95.3197,
    postal_code: '56575'
  },
  '56576': {
    country: 'US',
    city: 'Richville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.4434,
    longitude: -95.7924,
    postal_code: '56576'
  },
  '56577': {
    country: 'US',
    city: 'Richwood',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 46.9747,
    longitude: -95.8228,
    postal_code: '56577'
  },
  '56578': {
    country: 'US',
    city: 'Rochert',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 46.8862,
    longitude: -95.7246,
    postal_code: '56578'
  },
  '56579': {
    country: 'US',
    city: 'Rothsay',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wilkin',
    latitude: 46.5094,
    longitude: -96.2887,
    postal_code: '56579'
  },
  '56580': {
    country: 'US',
    city: 'Sabin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 46.7703,
    longitude: -96.5985,
    postal_code: '56580'
  },
  '56581': {
    country: 'US',
    city: 'Shelly',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Norman',
    latitude: 47.4554,
    longitude: -96.7838,
    postal_code: '56581'
  },
  '56583': {
    country: 'US',
    city: 'Tintah',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Traverse',
    latitude: 46.0074,
    longitude: -96.3593,
    postal_code: '56583'
  },
  '56584': {
    country: 'US',
    city: 'Twin Valley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Norman',
    latitude: 47.2509,
    longitude: -96.2464,
    postal_code: '56584'
  },
  '56585': {
    country: 'US',
    city: 'Ulen',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clay',
    latitude: 47.0903,
    longitude: -96.2824,
    postal_code: '56585'
  },
  '56586': {
    country: 'US',
    city: 'Underwood',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.3265,
    longitude: -95.8415,
    postal_code: '56586'
  },
  '56587': {
    country: 'US',
    city: 'Vergas',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.6706,
    longitude: -95.7948,
    postal_code: '56587'
  },
  '56588': {
    country: 'US',
    city: 'Vining',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Otter Tail',
    latitude: 46.2195,
    longitude: -95.506,
    postal_code: '56588'
  },
  '56589': {
    country: 'US',
    city: 'Waubun',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Mahnomen',
    latitude: 47.192,
    longitude: -95.8871,
    postal_code: '56589'
  },
  '56590': {
    country: 'US',
    city: 'Wendell',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Grant',
    latitude: 46.0562,
    longitude: -96.1144,
    postal_code: '56590'
  },
  '56591': {
    country: 'US',
    city: 'White Earth',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 47.0966,
    longitude: -95.8434,
    postal_code: '56591'
  },
  '56592': {
    country: 'US',
    city: 'Winger',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.5375,
    longitude: -95.9941,
    postal_code: '56592'
  },
  '56593': {
    country: 'US',
    city: 'Wolf Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Becker',
    latitude: 46.8035,
    longitude: -95.3535,
    postal_code: '56593'
  },
  '56594': {
    country: 'US',
    city: 'Wolverton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Wilkin',
    latitude: 46.555,
    longitude: -96.6149,
    postal_code: '56594'
  },
  '56601': {
    country: 'US',
    city: 'Bemidji',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.572,
    longitude: -94.8013,
    postal_code: '56601'
  },
  '56619': {
    country: 'US',
    city: 'Bemidji',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.4736,
    longitude: -94.8803,
    postal_code: '56619'
  },
  '56621': {
    country: 'US',
    city: 'Bagley',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clearwater',
    latitude: 47.487,
    longitude: -95.4133,
    postal_code: '56621'
  },
  '56623': {
    country: 'US',
    city: 'Baudette',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lake of the Woods',
    latitude: 48.6927,
    longitude: -94.5995,
    postal_code: '56623'
  },
  '56626': {
    country: 'US',
    city: 'Bena',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 47.3477,
    longitude: -94.2519,
    postal_code: '56626'
  },
  '56627': {
    country: 'US',
    city: 'Big Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 48.156,
    longitude: -93.7296,
    postal_code: '56627'
  },
  '56628': {
    country: 'US',
    city: 'Bigfork',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.7502,
    longitude: -93.6707,
    postal_code: '56628'
  },
  '56629': {
    country: 'US',
    city: 'Birchdale',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 48.6247,
    longitude: -94.1677,
    postal_code: '56629'
  },
  '56630': {
    country: 'US',
    city: 'Blackduck',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.7381,
    longitude: -94.4961,
    postal_code: '56630'
  },
  '56631': {
    country: 'US',
    city: 'Bowstring',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.543,
    longitude: -93.7966,
    postal_code: '56631'
  },
  '56633': {
    country: 'US',
    city: 'Cass Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 47.3516,
    longitude: -94.6119,
    postal_code: '56633'
  },
  '56634': {
    country: 'US',
    city: 'Clearbrook',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clearwater',
    latitude: 47.7146,
    longitude: -95.3752,
    postal_code: '56634'
  },
  '56636': {
    country: 'US',
    city: 'Deer River',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.3829,
    longitude: -93.8498,
    postal_code: '56636'
  },
  '56637': {
    country: 'US',
    city: 'Talmoon',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.5888,
    longitude: -93.7749,
    postal_code: '56637'
  },
  '56639': {
    country: 'US',
    city: 'Effie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.8472,
    longitude: -93.5799,
    postal_code: '56639'
  },
  '56641': {
    country: 'US',
    city: 'Federal Dam',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 47.2069,
    longitude: -94.2575,
    postal_code: '56641'
  },
  '56644': {
    country: 'US',
    city: 'Gonvick',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clearwater',
    latitude: 47.749,
    longitude: -95.499,
    postal_code: '56644'
  },
  '56646': {
    country: 'US',
    city: 'Gully',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.7697,
    longitude: -95.641,
    postal_code: '56646'
  },
  '56647': {
    country: 'US',
    city: 'Hines',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.7324,
    longitude: -94.6477,
    postal_code: '56647'
  },
  '56649': {
    country: 'US',
    city: 'International Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 48.601,
    longitude: -93.411,
    postal_code: '56649'
  },
  '56650': {
    country: 'US',
    city: 'Kelliher',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.9272,
    longitude: -94.5382,
    postal_code: '56650'
  },
  '56651': {
    country: 'US',
    city: 'Lengby',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.5158,
    longitude: -95.6359,
    postal_code: '56651'
  },
  '56652': {
    country: 'US',
    city: 'Leonard',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clearwater',
    latitude: 47.7334,
    longitude: -95.2481,
    postal_code: '56652'
  },
  '56653': {
    country: 'US',
    city: 'Littlefork',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 48.3852,
    longitude: -93.5399,
    postal_code: '56653'
  },
  '56654': {
    country: 'US',
    city: 'Loman',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 48.5164,
    longitude: -93.8407,
    postal_code: '56654'
  },
  '56655': {
    country: 'US',
    city: 'Longville',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 47.0248,
    longitude: -94.1982,
    postal_code: '56655'
  },
  '56657': {
    country: 'US',
    city: 'Marcell',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.5485,
    longitude: -93.6236,
    postal_code: '56657'
  },
  '56658': {
    country: 'US',
    city: 'Margie',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 48.0904,
    longitude: -93.8292,
    postal_code: '56658'
  },
  '56659': {
    country: 'US',
    city: 'Max',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.6147,
    longitude: -94.068,
    postal_code: '56659'
  },
  '56660': {
    country: 'US',
    city: 'Mizpah',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 47.9495,
    longitude: -94.1467,
    postal_code: '56660'
  },
  '56661': {
    country: 'US',
    city: 'Northome',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 47.844,
    longitude: -94.2043,
    postal_code: '56661'
  },
  '56662': {
    country: 'US',
    city: 'Outing',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 46.8375,
    longitude: -93.9443,
    postal_code: '56662'
  },
  '56663': {
    country: 'US',
    city: 'Pennington',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.4651,
    longitude: -94.4834,
    postal_code: '56663'
  },
  '56666': {
    country: 'US',
    city: 'Ponemah',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 48.0372,
    longitude: -94.917,
    postal_code: '56666'
  },
  '56667': {
    country: 'US',
    city: 'Puposky',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.7436,
    longitude: -94.9803,
    postal_code: '56667'
  },
  '56668': {
    country: 'US',
    city: 'Ranier',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 48.613,
    longitude: -93.3488,
    postal_code: '56668'
  },
  '56669': {
    country: 'US',
    city: 'Kabetogama',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 48.2463,
    longitude: -93.7842,
    postal_code: '56669'
  },
  '56670': {
    country: 'US',
    city: 'Redby',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.8689,
    longitude: -94.9404,
    postal_code: '56670'
  },
  '56671': {
    country: 'US',
    city: 'Redlake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.8654,
    longitude: -95.0643,
    postal_code: '56671'
  },
  '56672': {
    country: 'US',
    city: 'Remer',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Cass',
    latitude: 47.0874,
    longitude: -93.9196,
    postal_code: '56672'
  },
  '56673': {
    country: 'US',
    city: 'Roosevelt',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Roseau',
    latitude: 48.8279,
    longitude: -95.1119,
    postal_code: '56673'
  },
  '56676': {
    country: 'US',
    city: 'Shevlin',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Clearwater',
    latitude: 47.5004,
    longitude: -95.245,
    postal_code: '56676'
  },
  '56678': {
    country: 'US',
    city: 'Solway',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.548,
    longitude: -95.1205,
    postal_code: '56678'
  },
  '56679': {
    country: 'US',
    city: 'South International Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Koochiching',
    latitude: 48.5866,
    longitude: -93.399,
    postal_code: '56679'
  },
  '56680': {
    country: 'US',
    city: 'Spring Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.6359,
    longitude: -93.922,
    postal_code: '56680'
  },
  '56681': {
    country: 'US',
    city: 'Squaw Lake',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.6362,
    longitude: -94.1475,
    postal_code: '56681'
  },
  '56682': {
    country: 'US',
    city: 'Swift',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Roseau',
    latitude: 48.8533,
    longitude: -95.2213,
    postal_code: '56682'
  },
  '56683': {
    country: 'US',
    city: 'Tenstrike',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.6566,
    longitude: -94.6747,
    postal_code: '56683'
  },
  '56684': {
    country: 'US',
    city: 'Trail',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.7478,
    longitude: -95.7607,
    postal_code: '56684'
  },
  '56685': {
    country: 'US',
    city: 'Waskish',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 48.177,
    longitude: -94.5037,
    postal_code: '56685'
  },
  '56686': {
    country: 'US',
    city: 'Williams',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lake of the Woods',
    latitude: 48.8022,
    longitude: -94.9556,
    postal_code: '56686'
  },
  '56687': {
    country: 'US',
    city: 'Wilton',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Beltrami',
    latitude: 47.5036,
    longitude: -95.0011,
    postal_code: '56687'
  },
  '56688': {
    country: 'US',
    city: 'Wirt',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Itasca',
    latitude: 47.7462,
    longitude: -93.963,
    postal_code: '56688'
  },
  '56701': {
    country: 'US',
    city: 'Thief River Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pennington',
    latitude: 48.1191,
    longitude: -96.1811,
    postal_code: '56701'
  },
  '56710': {
    country: 'US',
    city: 'Alvarado',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.202,
    longitude: -96.9915,
    postal_code: '56710'
  },
  '56711': {
    country: 'US',
    city: 'Angle Inlet',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lake of the Woods',
    latitude: 49.3453,
    longitude: -95.0627,
    postal_code: '56711'
  },
  '56713': {
    country: 'US',
    city: 'Argyle',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.3314,
    longitude: -96.8671,
    postal_code: '56713'
  },
  '56714': {
    country: 'US',
    city: 'Badger',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Roseau',
    latitude: 48.7913,
    longitude: -96.0965,
    postal_code: '56714'
  },
  '56715': {
    country: 'US',
    city: 'Brooks',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Red Lake',
    latitude: 47.8129,
    longitude: -96.0117,
    postal_code: '56715'
  },
  '56716': {
    country: 'US',
    city: 'Crookston',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.7797,
    longitude: -96.5931,
    postal_code: '56716'
  },
  '56720': {
    country: 'US',
    city: 'Donaldson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kittson',
    latitude: 48.5722,
    longitude: -96.8964,
    postal_code: '56720'
  },
  '56721': {
    country: 'US',
    city: 'East Grand Forks',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.9318,
    longitude: -97.0213,
    postal_code: '56721'
  },
  '56722': {
    country: 'US',
    city: 'Euclid',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.9845,
    longitude: -96.6428,
    postal_code: '56722'
  },
  '56723': {
    country: 'US',
    city: 'Fisher',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.8003,
    longitude: -96.8015,
    postal_code: '56723'
  },
  '56724': {
    country: 'US',
    city: 'Gatzke',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.4104,
    longitude: -95.7903,
    postal_code: '56724'
  },
  '56725': {
    country: 'US',
    city: 'Goodridge',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pennington',
    latitude: 48.0686,
    longitude: -95.7283,
    postal_code: '56725'
  },
  '56726': {
    country: 'US',
    city: 'Greenbush',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Roseau',
    latitude: 48.6957,
    longitude: -96.1871,
    postal_code: '56726'
  },
  '56727': {
    country: 'US',
    city: 'Grygla',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.3071,
    longitude: -95.6398,
    postal_code: '56727'
  },
  '56728': {
    country: 'US',
    city: 'Hallock',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kittson',
    latitude: 48.7749,
    longitude: -96.9445,
    postal_code: '56728'
  },
  '56729': {
    country: 'US',
    city: 'Halma',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kittson',
    latitude: 48.6669,
    longitude: -96.5969,
    postal_code: '56729'
  },
  '56731': {
    country: 'US',
    city: 'Humboldt',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kittson',
    latitude: 48.9211,
    longitude: -97.0934,
    postal_code: '56731'
  },
  '56732': {
    country: 'US',
    city: 'Karlstad',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kittson',
    latitude: 48.5916,
    longitude: -96.5511,
    postal_code: '56732'
  },
  '56733': {
    country: 'US',
    city: 'Kennedy',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kittson',
    latitude: 48.6337,
    longitude: -96.9614,
    postal_code: '56733'
  },
  '56734': {
    country: 'US',
    city: 'Lake Bronson',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kittson',
    latitude: 48.7787,
    longitude: -96.6431,
    postal_code: '56734'
  },
  '56735': {
    country: 'US',
    city: 'Lancaster',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kittson',
    latitude: 48.9153,
    longitude: -96.6366,
    postal_code: '56735'
  },
  '56736': {
    country: 'US',
    city: 'Mentor',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Polk',
    latitude: 47.6576,
    longitude: -96.1778,
    postal_code: '56736'
  },
  '56737': {
    country: 'US',
    city: 'Middle River',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.4426,
    longitude: -96.1232,
    postal_code: '56737'
  },
  '56738': {
    country: 'US',
    city: 'Newfolden',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.2895,
    longitude: -96.255,
    postal_code: '56738'
  },
  '56740': {
    country: 'US',
    city: 'Noyes',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kittson',
    latitude: 48.9975,
    longitude: -97.2045,
    postal_code: '56740'
  },
  '56741': {
    country: 'US',
    city: 'Oak Island',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Lake of the Woods',
    latitude: 49.3135,
    longitude: -94.8492,
    postal_code: '56741'
  },
  '56742': {
    country: 'US',
    city: 'Oklee',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Red Lake',
    latitude: 47.8286,
    longitude: -95.8617,
    postal_code: '56742'
  },
  '56744': {
    country: 'US',
    city: 'Oslo',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.2066,
    longitude: -97.1163,
    postal_code: '56744'
  },
  '56748': {
    country: 'US',
    city: 'Plummer',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Red Lake',
    latitude: 47.9113,
    longitude: -95.9646,
    postal_code: '56748'
  },
  '56750': {
    country: 'US',
    city: 'Red Lake Falls',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Red Lake',
    latitude: 47.8823,
    longitude: -96.2681,
    postal_code: '56750'
  },
  '56751': {
    country: 'US',
    city: 'Roseau',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Roseau',
    latitude: 48.7048,
    longitude: -95.7504,
    postal_code: '56751'
  },
  '56754': {
    country: 'US',
    city: 'Saint Hilaire',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Pennington',
    latitude: 48.0109,
    longitude: -96.2249,
    postal_code: '56754'
  },
  '56755': {
    country: 'US',
    city: 'Saint Vincent',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Kittson',
    latitude: 48.9458,
    longitude: -97.1703,
    postal_code: '56755'
  },
  '56756': {
    country: 'US',
    city: 'Salol',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Roseau',
    latitude: 48.8522,
    longitude: -95.5356,
    postal_code: '56756'
  },
  '56757': {
    country: 'US',
    city: 'Stephen',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.4525,
    longitude: -96.8674,
    postal_code: '56757'
  },
  '56758': {
    country: 'US',
    city: 'Strandquist',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.4526,
    longitude: -96.4723,
    postal_code: '56758'
  },
  '56759': {
    country: 'US',
    city: 'Strathcona',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Roseau',
    latitude: 48.5459,
    longitude: -96.0879,
    postal_code: '56759'
  },
  '56760': {
    country: 'US',
    city: 'Viking',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.2349,
    longitude: -96.4749,
    postal_code: '56760'
  },
  '56761': {
    country: 'US',
    city: 'Wannaska',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Roseau',
    latitude: 48.6452,
    longitude: -95.7072,
    postal_code: '56761'
  },
  '56762': {
    country: 'US',
    city: 'Warren',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Marshall',
    latitude: 48.2614,
    longitude: -96.7726,
    postal_code: '56762'
  },
  '56763': {
    country: 'US',
    city: 'Warroad',
    state: 'Minnesota',
    state_short: 'MN',
    county: 'Roseau',
    latitude: 48.9111,
    longitude: -95.3538,
    postal_code: '56763'
  },
  '56901': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8952,
    longitude: -77.0365,
    postal_code: '56901'
  },
  '56915': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8952,
    longitude: -77.0365,
    postal_code: '56915'
  },
  '56920': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8952,
    longitude: -77.0365,
    postal_code: '56920'
  },
  '56933': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8952,
    longitude: -77.0365,
    postal_code: '56933'
  },
  '56944': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8952,
    longitude: -77.0365,
    postal_code: '56944'
  },
  '56972': {
    country: 'US',
    city: 'Washington',
    state: 'District of Columbia',
    state_short: 'DC',
    county: 'District of Columbia',
    latitude: 38.8951,
    longitude: -77.0364,
    postal_code: '56972'
  },
  '57001': {
    country: 'US',
    city: 'Alcester',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Union',
    latitude: 43.0047,
    longitude: -96.6332,
    postal_code: '57001'
  },
  '57002': {
    country: 'US',
    city: 'Aurora',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brookings',
    latitude: 44.2838,
    longitude: -96.7043,
    postal_code: '57002'
  },
  '57003': {
    country: 'US',
    city: 'Baltic',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.7309,
    longitude: -96.7563,
    postal_code: '57003'
  },
  '57004': {
    country: 'US',
    city: 'Beresford',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Union',
    latitude: 43.0874,
    longitude: -96.7813,
    postal_code: '57004'
  },
  '57005': {
    country: 'US',
    city: 'Brandon',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.5926,
    longitude: -96.586,
    postal_code: '57005'
  },
  '57006': {
    country: 'US',
    city: 'Brookings',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brookings',
    latitude: 44.3056,
    longitude: -96.7914,
    postal_code: '57006'
  },
  '57007': {
    country: 'US',
    city: 'Brookings',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brookings',
    latitude: 44.3697,
    longitude: -96.7907,
    postal_code: '57007'
  },
  '57010': {
    country: 'US',
    city: 'Burbank',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clay',
    latitude: 42.7638,
    longitude: -96.8466,
    postal_code: '57010'
  },
  '57012': {
    country: 'US',
    city: 'Canistota',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'McCook',
    latitude: 43.5856,
    longitude: -97.2889,
    postal_code: '57012'
  },
  '57013': {
    country: 'US',
    city: 'Canton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lincoln',
    latitude: 43.3038,
    longitude: -96.5938,
    postal_code: '57013'
  },
  '57014': {
    country: 'US',
    city: 'Centerville',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Turner',
    latitude: 43.1176,
    longitude: -96.9636,
    postal_code: '57014'
  },
  '57015': {
    country: 'US',
    city: 'Chancellor',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Turner',
    latitude: 43.408,
    longitude: -96.9827,
    postal_code: '57015'
  },
  '57016': {
    country: 'US',
    city: 'Chester',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lake',
    latitude: 43.8981,
    longitude: -96.9759,
    postal_code: '57016'
  },
  '57017': {
    country: 'US',
    city: 'Colman',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Moody',
    latitude: 43.9558,
    longitude: -96.8189,
    postal_code: '57017'
  },
  '57018': {
    country: 'US',
    city: 'Colton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.7951,
    longitude: -96.9572,
    postal_code: '57018'
  },
  '57020': {
    country: 'US',
    city: 'Crooks',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.6669,
    longitude: -96.8221,
    postal_code: '57020'
  },
  '57021': {
    country: 'US',
    city: 'Davis',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Turner',
    latitude: 43.2864,
    longitude: -96.9792,
    postal_code: '57021'
  },
  '57022': {
    country: 'US',
    city: 'Dell Rapids',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.8228,
    longitude: -96.7223,
    postal_code: '57022'
  },
  '57024': {
    country: 'US',
    city: 'Egan',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Moody',
    latitude: 43.9866,
    longitude: -96.6493,
    postal_code: '57024'
  },
  '57025': {
    country: 'US',
    city: 'Elk Point',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Union',
    latitude: 42.7382,
    longitude: -96.687,
    postal_code: '57025'
  },
  '57026': {
    country: 'US',
    city: 'Elkton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brookings',
    latitude: 44.235,
    longitude: -96.5011,
    postal_code: '57026'
  },
  '57027': {
    country: 'US',
    city: 'Fairview',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lincoln',
    latitude: 43.197,
    longitude: -96.5135,
    postal_code: '57027'
  },
  '57028': {
    country: 'US',
    city: 'Flandreau',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Moody',
    latitude: 44.0658,
    longitude: -96.6222,
    postal_code: '57028'
  },
  '57029': {
    country: 'US',
    city: 'Freeman',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hutchinson',
    latitude: 43.3605,
    longitude: -97.4601,
    postal_code: '57029'
  },
  '57030': {
    country: 'US',
    city: 'Garretson',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.7162,
    longitude: -96.5196,
    postal_code: '57030'
  },
  '57031': {
    country: 'US',
    city: 'Gayville',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Yankton',
    latitude: 42.8835,
    longitude: -97.183,
    postal_code: '57031'
  },
  '57032': {
    country: 'US',
    city: 'Harrisburg',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lincoln',
    latitude: 43.446,
    longitude: -96.6864,
    postal_code: '57032'
  },
  '57033': {
    country: 'US',
    city: 'Hartford',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.6155,
    longitude: -96.9501,
    postal_code: '57033'
  },
  '57034': {
    country: 'US',
    city: 'Hudson',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lincoln',
    latitude: 43.1284,
    longitude: -96.5306,
    postal_code: '57034'
  },
  '57035': {
    country: 'US',
    city: 'Humboldt',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.612,
    longitude: -97.0697,
    postal_code: '57035'
  },
  '57036': {
    country: 'US',
    city: 'Hurley',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Turner',
    latitude: 43.2893,
    longitude: -97.1904,
    postal_code: '57036'
  },
  '57037': {
    country: 'US',
    city: 'Irene',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clay',
    latitude: 43.1027,
    longitude: -97.2558,
    postal_code: '57037'
  },
  '57038': {
    country: 'US',
    city: 'Jefferson',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Union',
    latitude: 42.6013,
    longitude: -96.5784,
    postal_code: '57038'
  },
  '57039': {
    country: 'US',
    city: 'Lennox',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lincoln',
    latitude: 43.3451,
    longitude: -96.8821,
    postal_code: '57039'
  },
  '57040': {
    country: 'US',
    city: 'Lesterville',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Yankton',
    latitude: 43.0394,
    longitude: -97.5956,
    postal_code: '57040'
  },
  '57041': {
    country: 'US',
    city: 'Lyons',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.7285,
    longitude: -96.8709,
    postal_code: '57041'
  },
  '57042': {
    country: 'US',
    city: 'Madison',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lake',
    latitude: 44.0054,
    longitude: -97.1149,
    postal_code: '57042'
  },
  '57043': {
    country: 'US',
    city: 'Marion',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Turner',
    latitude: 43.4188,
    longitude: -97.2771,
    postal_code: '57043'
  },
  '57045': {
    country: 'US',
    city: 'Menno',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hutchinson',
    latitude: 43.234,
    longitude: -97.565,
    postal_code: '57045'
  },
  '57046': {
    country: 'US',
    city: 'Mission Hill',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Yankton',
    latitude: 42.9836,
    longitude: -97.3349,
    postal_code: '57046'
  },
  '57047': {
    country: 'US',
    city: 'Monroe',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Turner',
    latitude: 43.5207,
    longitude: -97.212,
    postal_code: '57047'
  },
  '57048': {
    country: 'US',
    city: 'Montrose',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'McCook',
    latitude: 43.7063,
    longitude: -97.1885,
    postal_code: '57048'
  },
  '57049': {
    country: 'US',
    city: 'North Sioux City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Union',
    latitude: 42.5249,
    longitude: -96.5074,
    postal_code: '57049'
  },
  '57050': {
    country: 'US',
    city: 'Nunda',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lake',
    latitude: 44.1525,
    longitude: -96.9942,
    postal_code: '57050'
  },
  '57051': {
    country: 'US',
    city: 'Oldham',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Kingsbury',
    latitude: 44.2457,
    longitude: -97.2696,
    postal_code: '57051'
  },
  '57052': {
    country: 'US',
    city: 'Olivet',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hutchinson',
    latitude: 43.2928,
    longitude: -97.7184,
    postal_code: '57052'
  },
  '57053': {
    country: 'US',
    city: 'Parker',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Turner',
    latitude: 43.402,
    longitude: -97.1333,
    postal_code: '57053'
  },
  '57054': {
    country: 'US',
    city: 'Ramona',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lake',
    latitude: 44.1229,
    longitude: -97.2349,
    postal_code: '57054'
  },
  '57055': {
    country: 'US',
    city: 'Renner',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.6366,
    longitude: -96.7119,
    postal_code: '57055'
  },
  '57057': {
    country: 'US',
    city: 'Rutland',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lake',
    latitude: 44.0683,
    longitude: -96.9519,
    postal_code: '57057'
  },
  '57058': {
    country: 'US',
    city: 'Salem',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'McCook',
    latitude: 43.7356,
    longitude: -97.3797,
    postal_code: '57058'
  },
  '57059': {
    country: 'US',
    city: 'Scotland',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Bon Homme',
    latitude: 43.1212,
    longitude: -97.7296,
    postal_code: '57059'
  },
  '57061': {
    country: 'US',
    city: 'Sinai',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brookings',
    latitude: 44.2444,
    longitude: -97.0409,
    postal_code: '57061'
  },
  '57062': {
    country: 'US',
    city: 'Springfield',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Bon Homme',
    latitude: 42.8687,
    longitude: -97.9288,
    postal_code: '57062'
  },
  '57063': {
    country: 'US',
    city: 'Tabor',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Bon Homme',
    latitude: 42.9383,
    longitude: -97.6923,
    postal_code: '57063'
  },
  '57064': {
    country: 'US',
    city: 'Tea',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lincoln',
    latitude: 43.4711,
    longitude: -96.8177,
    postal_code: '57064'
  },
  '57065': {
    country: 'US',
    city: 'Trent',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Moody',
    latitude: 43.8942,
    longitude: -96.6326,
    postal_code: '57065'
  },
  '57066': {
    country: 'US',
    city: 'Tyndall',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Bon Homme',
    latitude: 42.99,
    longitude: -97.8633,
    postal_code: '57066'
  },
  '57067': {
    country: 'US',
    city: 'Utica',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Yankton',
    latitude: 43.0164,
    longitude: -97.3694,
    postal_code: '57067'
  },
  '57068': {
    country: 'US',
    city: 'Valley Springs',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.5773,
    longitude: -96.4956,
    postal_code: '57068'
  },
  '57069': {
    country: 'US',
    city: 'Vermillion',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clay',
    latitude: 42.7951,
    longitude: -96.9258,
    postal_code: '57069'
  },
  '57070': {
    country: 'US',
    city: 'Viborg',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Turner',
    latitude: 43.1815,
    longitude: -97.114,
    postal_code: '57070'
  },
  '57071': {
    country: 'US',
    city: 'Volga',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brookings',
    latitude: 44.3224,
    longitude: -96.9251,
    postal_code: '57071'
  },
  '57072': {
    country: 'US',
    city: 'Volin',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Yankton',
    latitude: 42.9572,
    longitude: -97.1803,
    postal_code: '57072'
  },
  '57073': {
    country: 'US',
    city: 'Wakonda',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clay',
    latitude: 42.996,
    longitude: -97.0694,
    postal_code: '57073'
  },
  '57075': {
    country: 'US',
    city: 'Wentworth',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lake',
    latitude: 43.9852,
    longitude: -96.9615,
    postal_code: '57075'
  },
  '57076': {
    country: 'US',
    city: 'Winfred',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lake',
    latitude: 43.9972,
    longitude: -97.3623,
    postal_code: '57076'
  },
  '57077': {
    country: 'US',
    city: 'Worthing',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lincoln',
    latitude: 43.3291,
    longitude: -96.7628,
    postal_code: '57077'
  },
  '57078': {
    country: 'US',
    city: 'Yankton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Yankton',
    latitude: 42.8821,
    longitude: -97.3986,
    postal_code: '57078'
  },
  '57101': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.5464,
    longitude: -96.6906,
    postal_code: '57101'
  },
  '57103': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.5374,
    longitude: -96.6864,
    postal_code: '57103'
  },
  '57104': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.5514,
    longitude: -96.7375,
    postal_code: '57104'
  },
  '57105': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.524,
    longitude: -96.7341,
    postal_code: '57105'
  },
  '57106': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.5179,
    longitude: -96.7924,
    postal_code: '57106'
  },
  '57107': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.5566,
    longitude: -96.8028,
    postal_code: '57107'
  },
  '57108': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.4775,
    longitude: -96.7041,
    postal_code: '57108'
  },
  '57109': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.6746,
    longitude: -96.7913,
    postal_code: '57109'
  },
  '57110': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.5486,
    longitude: -96.6332,
    postal_code: '57110'
  },
  '57117': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.6746,
    longitude: -96.7913,
    postal_code: '57117'
  },
  '57118': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.6746,
    longitude: -96.7913,
    postal_code: '57118'
  },
  '57186': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.5424,
    longitude: -96.7312,
    postal_code: '57186'
  },
  '57193': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.6746,
    longitude: -96.7913,
    postal_code: '57193'
  },
  '57197': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.6746,
    longitude: -96.7913,
    postal_code: '57197'
  },
  '57198': {
    country: 'US',
    city: 'Sioux Falls',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Minnehaha',
    latitude: 43.6746,
    longitude: -96.7913,
    postal_code: '57198'
  },
  '57201': {
    country: 'US',
    city: 'Watertown',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Codington',
    latitude: 44.9043,
    longitude: -97.124,
    postal_code: '57201'
  },
  '57212': {
    country: 'US',
    city: 'Arlington',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Kingsbury',
    latitude: 44.3668,
    longitude: -97.0687,
    postal_code: '57212'
  },
  '57213': {
    country: 'US',
    city: 'Astoria',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Deuel',
    latitude: 44.5735,
    longitude: -96.5416,
    postal_code: '57213'
  },
  '57214': {
    country: 'US',
    city: 'Badger',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Kingsbury',
    latitude: 44.4918,
    longitude: -97.2184,
    postal_code: '57214'
  },
  '57216': {
    country: 'US',
    city: 'Big Stone City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Grant',
    latitude: 45.285,
    longitude: -96.5614,
    postal_code: '57216'
  },
  '57217': {
    country: 'US',
    city: 'Bradley',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clark',
    latitude: 45.0751,
    longitude: -97.6387,
    postal_code: '57217'
  },
  '57218': {
    country: 'US',
    city: 'Brandt',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Deuel',
    latitude: 44.6738,
    longitude: -96.6435,
    postal_code: '57218'
  },
  '57219': {
    country: 'US',
    city: 'Bristol',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Day',
    latitude: 45.288,
    longitude: -97.8086,
    postal_code: '57219'
  },
  '57220': {
    country: 'US',
    city: 'Bruce',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brookings',
    latitude: 44.4675,
    longitude: -96.911,
    postal_code: '57220'
  },
  '57221': {
    country: 'US',
    city: 'Bryant',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hamlin',
    latitude: 44.5987,
    longitude: -97.4537,
    postal_code: '57221'
  },
  '57223': {
    country: 'US',
    city: 'Castlewood',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hamlin',
    latitude: 44.7313,
    longitude: -97.0204,
    postal_code: '57223'
  },
  '57224': {
    country: 'US',
    city: 'Claire City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Roberts',
    latitude: 45.8755,
    longitude: -97.1073,
    postal_code: '57224'
  },
  '57225': {
    country: 'US',
    city: 'Clark',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clark',
    latitude: 44.878,
    longitude: -97.7265,
    postal_code: '57225'
  },
  '57226': {
    country: 'US',
    city: 'Clear Lake',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Deuel',
    latitude: 44.7612,
    longitude: -96.6907,
    postal_code: '57226'
  },
  '57227': {
    country: 'US',
    city: 'Corona',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Roberts',
    latitude: 45.3595,
    longitude: -96.6649,
    postal_code: '57227'
  },
  '57231': {
    country: 'US',
    city: 'De Smet',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Kingsbury',
    latitude: 44.3852,
    longitude: -97.5634,
    postal_code: '57231'
  },
  '57232': {
    country: 'US',
    city: 'Eden',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Marshall',
    latitude: 45.6211,
    longitude: -97.3741,
    postal_code: '57232'
  },
  '57233': {
    country: 'US',
    city: 'Erwin',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Kingsbury',
    latitude: 44.4915,
    longitude: -97.4103,
    postal_code: '57233'
  },
  '57234': {
    country: 'US',
    city: 'Estelline',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hamlin',
    latitude: 44.6029,
    longitude: -96.8928,
    postal_code: '57234'
  },
  '57235': {
    country: 'US',
    city: 'Florence',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Codington',
    latitude: 45.0554,
    longitude: -97.2866,
    postal_code: '57235'
  },
  '57236': {
    country: 'US',
    city: 'Garden City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clark',
    latitude: 44.9841,
    longitude: -97.5814,
    postal_code: '57236'
  },
  '57237': {
    country: 'US',
    city: 'Gary',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Deuel',
    latitude: 44.827,
    longitude: -96.5044,
    postal_code: '57237'
  },
  '57238': {
    country: 'US',
    city: 'Goodwin',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Deuel',
    latitude: 44.8754,
    longitude: -96.8608,
    postal_code: '57238'
  },
  '57239': {
    country: 'US',
    city: 'Grenville',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Day',
    latitude: 45.5097,
    longitude: -97.3092,
    postal_code: '57239'
  },
  '57241': {
    country: 'US',
    city: 'Hayti',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hamlin',
    latitude: 44.6647,
    longitude: -97.2305,
    postal_code: '57241'
  },
  '57242': {
    country: 'US',
    city: 'Hazel',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hamlin',
    latitude: 44.7572,
    longitude: -97.3083,
    postal_code: '57242'
  },
  '57243': {
    country: 'US',
    city: 'Henry',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Codington',
    latitude: 44.8858,
    longitude: -97.4442,
    postal_code: '57243'
  },
  '57245': {
    country: 'US',
    city: 'Kranzburg',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Codington',
    latitude: 44.8923,
    longitude: -96.9174,
    postal_code: '57245'
  },
  '57246': {
    country: 'US',
    city: 'Labolt',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Grant',
    latitude: 45.0415,
    longitude: -96.6892,
    postal_code: '57246'
  },
  '57247': {
    country: 'US',
    city: 'Lake City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Marshall',
    latitude: 45.6899,
    longitude: -97.3481,
    postal_code: '57247'
  },
  '57248': {
    country: 'US',
    city: 'Lake Norden',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hamlin',
    latitude: 44.5844,
    longitude: -97.2009,
    postal_code: '57248'
  },
  '57249': {
    country: 'US',
    city: 'Lake Preston',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Kingsbury',
    latitude: 44.3692,
    longitude: -97.3828,
    postal_code: '57249'
  },
  '57251': {
    country: 'US',
    city: 'Marvin',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Grant',
    latitude: 45.2727,
    longitude: -96.91,
    postal_code: '57251'
  },
  '57252': {
    country: 'US',
    city: 'Milbank',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Grant',
    latitude: 45.2061,
    longitude: -96.6255,
    postal_code: '57252'
  },
  '57255': {
    country: 'US',
    city: 'New Effington',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Roberts',
    latitude: 45.8659,
    longitude: -96.915,
    postal_code: '57255'
  },
  '57256': {
    country: 'US',
    city: 'Ortley',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Roberts',
    latitude: 45.2657,
    longitude: -97.1925,
    postal_code: '57256'
  },
  '57257': {
    country: 'US',
    city: 'Peever',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Roberts',
    latitude: 45.5206,
    longitude: -97.0012,
    postal_code: '57257'
  },
  '57258': {
    country: 'US',
    city: 'Raymond',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clark',
    latitude: 44.8637,
    longitude: -97.9168,
    postal_code: '57258'
  },
  '57259': {
    country: 'US',
    city: 'Revillo',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Grant',
    latitude: 45.0805,
    longitude: -96.5557,
    postal_code: '57259'
  },
  '57260': {
    country: 'US',
    city: 'Rosholt',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Roberts',
    latitude: 45.8753,
    longitude: -96.7174,
    postal_code: '57260'
  },
  '57261': {
    country: 'US',
    city: 'Roslyn',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Day',
    latitude: 45.5006,
    longitude: -97.5401,
    postal_code: '57261'
  },
  '57262': {
    country: 'US',
    city: 'Sisseton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Roberts',
    latitude: 45.6732,
    longitude: -97.0715,
    postal_code: '57262'
  },
  '57263': {
    country: 'US',
    city: 'South Shore',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Codington',
    latitude: 45.1049,
    longitude: -96.9859,
    postal_code: '57263'
  },
  '57264': {
    country: 'US',
    city: 'Stockholm',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Grant',
    latitude: 45.1031,
    longitude: -96.8106,
    postal_code: '57264'
  },
  '57265': {
    country: 'US',
    city: 'Strandburg',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Grant',
    latitude: 45.0389,
    longitude: -96.7901,
    postal_code: '57265'
  },
  '57266': {
    country: 'US',
    city: 'Summit',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Roberts',
    latitude: 45.3521,
    longitude: -97.0427,
    postal_code: '57266'
  },
  '57268': {
    country: 'US',
    city: 'Toronto',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Deuel',
    latitude: 44.5786,
    longitude: -96.7077,
    postal_code: '57268'
  },
  '57269': {
    country: 'US',
    city: 'Twin Brooks',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Grant',
    latitude: 45.2309,
    longitude: -96.8237,
    postal_code: '57269'
  },
  '57270': {
    country: 'US',
    city: 'Veblen',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Marshall',
    latitude: 45.8535,
    longitude: -97.3122,
    postal_code: '57270'
  },
  '57271': {
    country: 'US',
    city: 'Vienna',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clark',
    latitude: 44.7362,
    longitude: -97.5307,
    postal_code: '57271'
  },
  '57272': {
    country: 'US',
    city: 'Wallace',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Codington',
    latitude: 45.0815,
    longitude: -97.4458,
    postal_code: '57272'
  },
  '57273': {
    country: 'US',
    city: 'Waubay',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Day',
    latitude: 45.3784,
    longitude: -97.295,
    postal_code: '57273'
  },
  '57274': {
    country: 'US',
    city: 'Webster',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Day',
    latitude: 45.3151,
    longitude: -97.5254,
    postal_code: '57274'
  },
  '57276': {
    country: 'US',
    city: 'White',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brookings',
    latitude: 44.4132,
    longitude: -96.615,
    postal_code: '57276'
  },
  '57278': {
    country: 'US',
    city: 'Willow Lake',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clark',
    latitude: 44.6272,
    longitude: -97.6747,
    postal_code: '57278'
  },
  '57279': {
    country: 'US',
    city: 'Wilmot',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Roberts',
    latitude: 45.4125,
    longitude: -96.856,
    postal_code: '57279'
  },
  '57301': {
    country: 'US',
    city: 'Mitchell',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Davison',
    latitude: 43.7094,
    longitude: -98.0298,
    postal_code: '57301'
  },
  '57311': {
    country: 'US',
    city: 'Alexandria',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hanson',
    latitude: 43.6684,
    longitude: -97.7563,
    postal_code: '57311'
  },
  '57312': {
    country: 'US',
    city: 'Alpena',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jerauld',
    latitude: 44.1709,
    longitude: -98.3962,
    postal_code: '57312'
  },
  '57313': {
    country: 'US',
    city: 'Armour',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Douglas',
    latitude: 43.316,
    longitude: -98.3414,
    postal_code: '57313'
  },
  '57314': {
    country: 'US',
    city: 'Artesian',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Sanborn',
    latitude: 44.021,
    longitude: -98.0114,
    postal_code: '57314'
  },
  '57315': {
    country: 'US',
    city: 'Avon',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Bon Homme',
    latitude: 43.0397,
    longitude: -98.0283,
    postal_code: '57315'
  },
  '57317': {
    country: 'US',
    city: 'Bonesteel',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Gregory',
    latitude: 43.0695,
    longitude: -98.988,
    postal_code: '57317'
  },
  '57319': {
    country: 'US',
    city: 'Bridgewater',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'McCook',
    latitude: 43.5503,
    longitude: -97.4691,
    postal_code: '57319'
  },
  '57321': {
    country: 'US',
    city: 'Canova',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Miner',
    latitude: 43.8855,
    longitude: -97.5342,
    postal_code: '57321'
  },
  '57322': {
    country: 'US',
    city: 'Carpenter',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Clark',
    latitude: 44.6648,
    longitude: -97.9168,
    postal_code: '57322'
  },
  '57323': {
    country: 'US',
    city: 'Carthage',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Miner',
    latitude: 44.1496,
    longitude: -97.7116,
    postal_code: '57323'
  },
  '57324': {
    country: 'US',
    city: 'Cavour',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Beadle',
    latitude: 44.365,
    longitude: -98.0208,
    postal_code: '57324'
  },
  '57325': {
    country: 'US',
    city: 'Chamberlain',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brule',
    latitude: 43.7953,
    longitude: -99.3118,
    postal_code: '57325'
  },
  '57326': {
    country: 'US',
    city: 'Chamberlain',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brule',
    latitude: 43.8108,
    longitude: -99.3307,
    postal_code: '57326'
  },
  '57328': {
    country: 'US',
    city: 'Corsica',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Douglas',
    latitude: 43.4213,
    longitude: -98.3564,
    postal_code: '57328'
  },
  '57329': {
    country: 'US',
    city: 'Dante',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Charles Mix',
    latitude: 42.9967,
    longitude: -98.1746,
    postal_code: '57329'
  },
  '57330': {
    country: 'US',
    city: 'Delmont',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Douglas',
    latitude: 43.2573,
    longitude: -98.1596,
    postal_code: '57330'
  },
  '57331': {
    country: 'US',
    city: 'Dimock',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hutchinson',
    latitude: 43.4705,
    longitude: -97.9988,
    postal_code: '57331'
  },
  '57332': {
    country: 'US',
    city: 'Emery',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hanson',
    latitude: 43.5656,
    longitude: -97.6475,
    postal_code: '57332'
  },
  '57334': {
    country: 'US',
    city: 'Ethan',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Davison',
    latitude: 43.5478,
    longitude: -97.9881,
    postal_code: '57334'
  },
  '57335': {
    country: 'US',
    city: 'Fairfax',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Gregory',
    latitude: 43.0351,
    longitude: -98.8308,
    postal_code: '57335'
  },
  '57337': {
    country: 'US',
    city: 'Fedora',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Miner',
    latitude: 43.9841,
    longitude: -97.789,
    postal_code: '57337'
  },
  '57339': {
    country: 'US',
    city: 'Fort Thompson',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Buffalo',
    latitude: 44.0517,
    longitude: -99.3973,
    postal_code: '57339'
  },
  '57340': {
    country: 'US',
    city: 'Fulton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hanson',
    latitude: 43.7588,
    longitude: -97.8712,
    postal_code: '57340'
  },
  '57341': {
    country: 'US',
    city: 'Gann Valley',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Buffalo',
    latitude: 44.0693,
    longitude: -99.0543,
    postal_code: '57341'
  },
  '57342': {
    country: 'US',
    city: 'Geddes',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Charles Mix',
    latitude: 43.2597,
    longitude: -98.6926,
    postal_code: '57342'
  },
  '57344': {
    country: 'US',
    city: 'Harrison',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Douglas',
    latitude: 43.4297,
    longitude: -98.5273,
    postal_code: '57344'
  },
  '57345': {
    country: 'US',
    city: 'Highmore',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hyde',
    latitude: 44.5326,
    longitude: -99.4543,
    postal_code: '57345'
  },
  '57346': {
    country: 'US',
    city: 'Stephan',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hyde',
    latitude: 44.248,
    longitude: -99.4532,
    postal_code: '57346'
  },
  '57348': {
    country: 'US',
    city: 'Hitchcock',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Beadle',
    latitude: 44.5834,
    longitude: -98.4509,
    postal_code: '57348'
  },
  '57349': {
    country: 'US',
    city: 'Howard',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Miner',
    latitude: 44.0371,
    longitude: -97.5603,
    postal_code: '57349'
  },
  '57350': {
    country: 'US',
    city: 'Huron',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Beadle',
    latitude: 44.359,
    longitude: -98.2163,
    postal_code: '57350'
  },
  '57353': {
    country: 'US',
    city: 'Iroquois',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Kingsbury',
    latitude: 44.3455,
    longitude: -97.8542,
    postal_code: '57353'
  },
  '57354': {
    country: 'US',
    city: 'Kaylor',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hutchinson',
    latitude: 43.3341,
    longitude: -97.7569,
    postal_code: '57354'
  },
  '57355': {
    country: 'US',
    city: 'Kimball',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brule',
    latitude: 43.7467,
    longitude: -98.9584,
    postal_code: '57355'
  },
  '57356': {
    country: 'US',
    city: 'Lake Andes',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Charles Mix',
    latitude: 43.1303,
    longitude: -98.4964,
    postal_code: '57356'
  },
  '57358': {
    country: 'US',
    city: 'Lane',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jerauld',
    latitude: 44.0696,
    longitude: -98.4252,
    postal_code: '57358'
  },
  '57359': {
    country: 'US',
    city: 'Letcher',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Sanborn',
    latitude: 43.8923,
    longitude: -98.1743,
    postal_code: '57359'
  },
  '57361': {
    country: 'US',
    city: 'Marty',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Charles Mix',
    latitude: 42.9925,
    longitude: -98.4251,
    postal_code: '57361'
  },
  '57362': {
    country: 'US',
    city: 'Miller',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hand',
    latitude: 44.4966,
    longitude: -98.9894,
    postal_code: '57362'
  },
  '57363': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Davison',
    latitude: 43.7204,
    longitude: -98.2633,
    postal_code: '57363'
  },
  '57364': {
    country: 'US',
    city: 'New Holland',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Douglas',
    latitude: 43.4291,
    longitude: -98.6069,
    postal_code: '57364'
  },
  '57365': {
    country: 'US',
    city: 'Oacoma',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lyman',
    latitude: 43.7975,
    longitude: -99.3947,
    postal_code: '57365'
  },
  '57366': {
    country: 'US',
    city: 'Parkston',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hutchinson',
    latitude: 43.3978,
    longitude: -97.9678,
    postal_code: '57366'
  },
  '57367': {
    country: 'US',
    city: 'Pickstown',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Charles Mix',
    latitude: 43.0673,
    longitude: -98.523,
    postal_code: '57367'
  },
  '57368': {
    country: 'US',
    city: 'Plankinton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Aurora',
    latitude: 43.7373,
    longitude: -98.4694,
    postal_code: '57368'
  },
  '57369': {
    country: 'US',
    city: 'Platte',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Charles Mix',
    latitude: 43.4251,
    longitude: -98.9479,
    postal_code: '57369'
  },
  '57370': {
    country: 'US',
    city: 'Pukwana',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brule',
    latitude: 43.7783,
    longitude: -99.1779,
    postal_code: '57370'
  },
  '57371': {
    country: 'US',
    city: 'Ree Heights',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hand',
    latitude: 44.5603,
    longitude: -99.2286,
    postal_code: '57371'
  },
  '57373': {
    country: 'US',
    city: 'Saint Lawrence',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hand',
    latitude: 44.5215,
    longitude: -98.8754,
    postal_code: '57373'
  },
  '57374': {
    country: 'US',
    city: 'Spencer',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'McCook',
    latitude: 43.7557,
    longitude: -97.5936,
    postal_code: '57374'
  },
  '57375': {
    country: 'US',
    city: 'Stickney',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Aurora',
    latitude: 43.5842,
    longitude: -98.5088,
    postal_code: '57375'
  },
  '57376': {
    country: 'US',
    city: 'Tripp',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hutchinson',
    latitude: 43.2404,
    longitude: -97.9713,
    postal_code: '57376'
  },
  '57379': {
    country: 'US',
    city: 'Virgil',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Beadle',
    latitude: 44.2908,
    longitude: -98.4243,
    postal_code: '57379'
  },
  '57380': {
    country: 'US',
    city: 'Wagner',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Charles Mix',
    latitude: 43.0819,
    longitude: -98.2819,
    postal_code: '57380'
  },
  '57381': {
    country: 'US',
    city: 'Wessington',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Beadle',
    latitude: 44.4128,
    longitude: -98.692,
    postal_code: '57381'
  },
  '57382': {
    country: 'US',
    city: 'Wessington Springs',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jerauld',
    latitude: 44.0664,
    longitude: -98.6751,
    postal_code: '57382'
  },
  '57383': {
    country: 'US',
    city: 'White Lake',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Aurora',
    latitude: 43.7564,
    longitude: -98.7076,
    postal_code: '57383'
  },
  '57384': {
    country: 'US',
    city: 'Wolsey',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Beadle',
    latitude: 44.3991,
    longitude: -98.4743,
    postal_code: '57384'
  },
  '57385': {
    country: 'US',
    city: 'Woonsocket',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Sanborn',
    latitude: 44.0572,
    longitude: -98.243,
    postal_code: '57385'
  },
  '57386': {
    country: 'US',
    city: 'Yale',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Beadle',
    latitude: 44.5225,
    longitude: -98.0401,
    postal_code: '57386'
  },
  '57399': {
    country: 'US',
    city: 'Huron',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Beadle',
    latitude: 44.3633,
    longitude: -98.2143,
    postal_code: '57399'
  },
  '57401': {
    country: 'US',
    city: 'Aberdeen',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.4661,
    longitude: -98.4856,
    postal_code: '57401'
  },
  '57402': {
    country: 'US',
    city: 'Aberdeen',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.5896,
    longitude: -98.352,
    postal_code: '57402'
  },
  '57420': {
    country: 'US',
    city: 'Akaska',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Walworth',
    latitude: 45.3324,
    longitude: -100.1186,
    postal_code: '57420'
  },
  '57421': {
    country: 'US',
    city: 'Amherst',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Marshall',
    latitude: 45.7585,
    longitude: -97.9274,
    postal_code: '57421'
  },
  '57422': {
    country: 'US',
    city: 'Andover',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Day',
    latitude: 45.4222,
    longitude: -97.9175,
    postal_code: '57422'
  },
  '57424': {
    country: 'US',
    city: 'Ashton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 45.0465,
    longitude: -98.3973,
    postal_code: '57424'
  },
  '57426': {
    country: 'US',
    city: 'Barnard',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.7325,
    longitude: -98.4965,
    postal_code: '57426'
  },
  '57427': {
    country: 'US',
    city: 'Bath',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.4564,
    longitude: -98.3552,
    postal_code: '57427'
  },
  '57428': {
    country: 'US',
    city: 'Bowdle',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Edmunds',
    latitude: 45.453,
    longitude: -99.654,
    postal_code: '57428'
  },
  '57429': {
    country: 'US',
    city: 'Brentford',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 45.1635,
    longitude: -98.3193,
    postal_code: '57429'
  },
  '57430': {
    country: 'US',
    city: 'Britton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Marshall',
    latitude: 45.8023,
    longitude: -97.7418,
    postal_code: '57430'
  },
  '57432': {
    country: 'US',
    city: 'Claremont',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.6661,
    longitude: -98.0404,
    postal_code: '57432'
  },
  '57433': {
    country: 'US',
    city: 'Columbia',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.6512,
    longitude: -98.3158,
    postal_code: '57433'
  },
  '57434': {
    country: 'US',
    city: 'Conde',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 45.1393,
    longitude: -98.1534,
    postal_code: '57434'
  },
  '57435': {
    country: 'US',
    city: 'Cresbard',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Faulk',
    latitude: 45.1691,
    longitude: -98.9411,
    postal_code: '57435'
  },
  '57436': {
    country: 'US',
    city: 'Doland',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 44.8159,
    longitude: -98.0947,
    postal_code: '57436'
  },
  '57437': {
    country: 'US',
    city: 'Eureka',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'McPherson',
    latitude: 45.7707,
    longitude: -99.3308,
    postal_code: '57437'
  },
  '57438': {
    country: 'US',
    city: 'Faulkton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Faulk',
    latitude: 45.0855,
    longitude: -99.1954,
    postal_code: '57438'
  },
  '57439': {
    country: 'US',
    city: 'Ferney',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.329,
    longitude: -98.0837,
    postal_code: '57439'
  },
  '57440': {
    country: 'US',
    city: 'Frankfort',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 44.8084,
    longitude: -98.2935,
    postal_code: '57440'
  },
  '57441': {
    country: 'US',
    city: 'Frederick',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.8493,
    longitude: -98.5176,
    postal_code: '57441'
  },
  '57442': {
    country: 'US',
    city: 'Gettysburg',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Potter',
    latitude: 45.0259,
    longitude: -99.9766,
    postal_code: '57442'
  },
  '57445': {
    country: 'US',
    city: 'Groton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.4503,
    longitude: -98.1058,
    postal_code: '57445'
  },
  '57446': {
    country: 'US',
    city: 'Hecla',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.8725,
    longitude: -98.1918,
    postal_code: '57446'
  },
  '57448': {
    country: 'US',
    city: 'Hosmer',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Edmunds',
    latitude: 45.569,
    longitude: -99.4857,
    postal_code: '57448'
  },
  '57449': {
    country: 'US',
    city: 'Houghton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.7967,
    longitude: -98.0952,
    postal_code: '57449'
  },
  '57450': {
    country: 'US',
    city: 'Hoven',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Potter',
    latitude: 45.2278,
    longitude: -99.7763,
    postal_code: '57450'
  },
  '57451': {
    country: 'US',
    city: 'Ipswich',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Edmunds',
    latitude: 45.4489,
    longitude: -99.0148,
    postal_code: '57451'
  },
  '57452': {
    country: 'US',
    city: 'Java',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Walworth',
    latitude: 45.4492,
    longitude: -99.822,
    postal_code: '57452'
  },
  '57454': {
    country: 'US',
    city: 'Langford',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Marshall',
    latitude: 45.6174,
    longitude: -97.7925,
    postal_code: '57454'
  },
  '57455': {
    country: 'US',
    city: 'Lebanon',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Potter',
    latitude: 45.0123,
    longitude: -99.7366,
    postal_code: '57455'
  },
  '57456': {
    country: 'US',
    city: 'Leola',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'McPherson',
    latitude: 45.7228,
    longitude: -98.9409,
    postal_code: '57456'
  },
  '57457': {
    country: 'US',
    city: 'Long Lake',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'McPherson',
    latitude: 45.8566,
    longitude: -99.2051,
    postal_code: '57457'
  },
  '57460': {
    country: 'US',
    city: 'Mansfield',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 45.243,
    longitude: -98.5629,
    postal_code: '57460'
  },
  '57461': {
    country: 'US',
    city: 'Mellette',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 45.1631,
    longitude: -98.4824,
    postal_code: '57461'
  },
  '57465': {
    country: 'US',
    city: 'Northville',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 45.1611,
    longitude: -98.6589,
    postal_code: '57465'
  },
  '57466': {
    country: 'US',
    city: 'Onaka',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Faulk',
    latitude: 45.1965,
    longitude: -99.4551,
    postal_code: '57466'
  },
  '57467': {
    country: 'US',
    city: 'Orient',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Faulk',
    latitude: 44.8343,
    longitude: -99.1058,
    postal_code: '57467'
  },
  '57468': {
    country: 'US',
    city: 'Pierpont',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Day',
    latitude: 45.496,
    longitude: -97.8128,
    postal_code: '57468'
  },
  '57469': {
    country: 'US',
    city: 'Redfield',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 44.8719,
    longitude: -98.5112,
    postal_code: '57469'
  },
  '57470': {
    country: 'US',
    city: 'Rockham',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Faulk',
    latitude: 44.8767,
    longitude: -98.8669,
    postal_code: '57470'
  },
  '57471': {
    country: 'US',
    city: 'Roscoe',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Edmunds',
    latitude: 45.4271,
    longitude: -99.3326,
    postal_code: '57471'
  },
  '57472': {
    country: 'US',
    city: 'Selby',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Walworth',
    latitude: 45.4786,
    longitude: -100.0541,
    postal_code: '57472'
  },
  '57473': {
    country: 'US',
    city: 'Seneca',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Faulk',
    latitude: 45.0262,
    longitude: -99.461,
    postal_code: '57473'
  },
  '57474': {
    country: 'US',
    city: 'Stratford',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.2866,
    longitude: -98.2792,
    postal_code: '57474'
  },
  '57475': {
    country: 'US',
    city: 'Tolstoy',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Potter',
    latitude: 45.1702,
    longitude: -99.6176,
    postal_code: '57475'
  },
  '57476': {
    country: 'US',
    city: 'Tulare',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 44.7305,
    longitude: -98.5539,
    postal_code: '57476'
  },
  '57477': {
    country: 'US',
    city: 'Turton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Spink',
    latitude: 45.0379,
    longitude: -98.0996,
    postal_code: '57477'
  },
  '57479': {
    country: 'US',
    city: 'Warner',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.3486,
    longitude: -98.4757,
    postal_code: '57479'
  },
  '57481': {
    country: 'US',
    city: 'Westport',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Brown',
    latitude: 45.6705,
    longitude: -98.5802,
    postal_code: '57481'
  },
  '57501': {
    country: 'US',
    city: 'Pierre',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hughes',
    latitude: 44.3695,
    longitude: -100.3211,
    postal_code: '57501'
  },
  '57520': {
    country: 'US',
    city: 'Agar',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Sully',
    latitude: 44.8393,
    longitude: -100.0712,
    postal_code: '57520'
  },
  '57521': {
    country: 'US',
    city: 'Belvidere',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jackson',
    latitude: 43.886,
    longitude: -101.2387,
    postal_code: '57521'
  },
  '57522': {
    country: 'US',
    city: 'Blunt',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hughes',
    latitude: 44.5026,
    longitude: -99.9463,
    postal_code: '57522'
  },
  '57523': {
    country: 'US',
    city: 'Burke',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Gregory',
    latitude: 43.1825,
    longitude: -99.2937,
    postal_code: '57523'
  },
  '57528': {
    country: 'US',
    city: 'Colome',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Tripp',
    latitude: 43.2273,
    longitude: -99.6933,
    postal_code: '57528'
  },
  '57529': {
    country: 'US',
    city: 'Dallas',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Gregory',
    latitude: 43.2351,
    longitude: -99.514,
    postal_code: '57529'
  },
  '57531': {
    country: 'US',
    city: 'Draper',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jones',
    latitude: 43.926,
    longitude: -100.5085,
    postal_code: '57531'
  },
  '57532': {
    country: 'US',
    city: 'Fort Pierre',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Stanley',
    latitude: 44.3426,
    longitude: -100.4043,
    postal_code: '57532'
  },
  '57533': {
    country: 'US',
    city: 'Gregory',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Gregory',
    latitude: 43.2136,
    longitude: -99.3622,
    postal_code: '57533'
  },
  '57534': {
    country: 'US',
    city: 'Hamill',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Tripp',
    latitude: 43.6439,
    longitude: -99.6918,
    postal_code: '57534'
  },
  '57536': {
    country: 'US',
    city: 'Harrold',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hughes',
    latitude: 44.5215,
    longitude: -99.7382,
    postal_code: '57536'
  },
  '57537': {
    country: 'US',
    city: 'Hayes',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Stanley',
    latitude: 44.3703,
    longitude: -101.0215,
    postal_code: '57537'
  },
  '57538': {
    country: 'US',
    city: 'Herrick',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Gregory',
    latitude: 43.1012,
    longitude: -99.2173,
    postal_code: '57538'
  },
  '57540': {
    country: 'US',
    city: 'Holabird',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Hyde',
    latitude: 44.5173,
    longitude: -99.5943,
    postal_code: '57540'
  },
  '57541': {
    country: 'US',
    city: 'Ideal',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Tripp',
    latitude: 43.5596,
    longitude: -99.9279,
    postal_code: '57541'
  },
  '57543': {
    country: 'US',
    city: 'Kadoka',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jackson',
    latitude: 43.8446,
    longitude: -101.5523,
    postal_code: '57543'
  },
  '57544': {
    country: 'US',
    city: 'Kennebec',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lyman',
    latitude: 43.8921,
    longitude: -99.8502,
    postal_code: '57544'
  },
  '57547': {
    country: 'US',
    city: 'Long Valley',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jackson',
    latitude: 43.4754,
    longitude: -101.4294,
    postal_code: '57547'
  },
  '57548': {
    country: 'US',
    city: 'Lower Brule',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lyman',
    latitude: 44.0937,
    longitude: -99.6139,
    postal_code: '57548'
  },
  '57551': {
    country: 'US',
    city: 'Martin',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Bennett',
    latitude: 43.1722,
    longitude: -101.7341,
    postal_code: '57551'
  },
  '57552': {
    country: 'US',
    city: 'Midland',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Haakon',
    latitude: 44.0717,
    longitude: -101.1554,
    postal_code: '57552'
  },
  '57553': {
    country: 'US',
    city: 'Milesville',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Haakon',
    latitude: 44.4283,
    longitude: -101.7523,
    postal_code: '57553'
  },
  '57555': {
    country: 'US',
    city: 'Mission',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Todd',
    latitude: 43.285,
    longitude: -100.5954,
    postal_code: '57555'
  },
  '57559': {
    country: 'US',
    city: 'Murdo',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jones',
    latitude: 43.8961,
    longitude: -100.7121,
    postal_code: '57559'
  },
  '57560': {
    country: 'US',
    city: 'Norris',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Mellette',
    latitude: 43.4662,
    longitude: -101.1517,
    postal_code: '57560'
  },
  '57562': {
    country: 'US',
    city: 'Okaton',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jones',
    latitude: 43.8861,
    longitude: -100.8918,
    postal_code: '57562'
  },
  '57563': {
    country: 'US',
    city: 'Okreek',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Todd',
    latitude: 43.3633,
    longitude: -100.3809,
    postal_code: '57563'
  },
  '57564': {
    country: 'US',
    city: 'Onida',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Sully',
    latitude: 44.7125,
    longitude: -100.0957,
    postal_code: '57564'
  },
  '57566': {
    country: 'US',
    city: 'Parmelee',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Todd',
    latitude: 43.3117,
    longitude: -101.0081,
    postal_code: '57566'
  },
  '57567': {
    country: 'US',
    city: 'Philip',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Haakon',
    latitude: 44.055,
    longitude: -101.6876,
    postal_code: '57567'
  },
  '57568': {
    country: 'US',
    city: 'Presho',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lyman',
    latitude: 43.8995,
    longitude: -100.0746,
    postal_code: '57568'
  },
  '57569': {
    country: 'US',
    city: 'Reliance',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lyman',
    latitude: 43.8789,
    longitude: -99.6032,
    postal_code: '57569'
  },
  '57570': {
    country: 'US',
    city: 'Rosebud',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Todd',
    latitude: 43.2328,
    longitude: -100.8535,
    postal_code: '57570'
  },
  '57571': {
    country: 'US',
    city: 'Saint Charles',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Gregory',
    latitude: 43.0852,
    longitude: -99.0925,
    postal_code: '57571'
  },
  '57572': {
    country: 'US',
    city: 'Saint Francis',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Todd',
    latitude: 43.1444,
    longitude: -100.9051,
    postal_code: '57572'
  },
  '57574': {
    country: 'US',
    city: 'Tuthill',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Bennett',
    latitude: 43.1196,
    longitude: -101.4701,
    postal_code: '57574'
  },
  '57576': {
    country: 'US',
    city: 'Vivian',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lyman',
    latitude: 43.9535,
    longitude: -100.286,
    postal_code: '57576'
  },
  '57577': {
    country: 'US',
    city: 'Wanblee',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jackson',
    latitude: 43.5589,
    longitude: -101.7219,
    postal_code: '57577'
  },
  '57579': {
    country: 'US',
    city: 'White River',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Mellette',
    latitude: 43.5666,
    longitude: -100.7449,
    postal_code: '57579'
  },
  '57580': {
    country: 'US',
    city: 'Winner',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Tripp',
    latitude: 43.3318,
    longitude: -99.8033,
    postal_code: '57580'
  },
  '57584': {
    country: 'US',
    city: 'Witten',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Tripp',
    latitude: 43.4498,
    longitude: -100.0783,
    postal_code: '57584'
  },
  '57585': {
    country: 'US',
    city: 'Wood',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Mellette',
    latitude: 43.5366,
    longitude: -100.4379,
    postal_code: '57585'
  },
  '57601': {
    country: 'US',
    city: 'Mobridge',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Walworth',
    latitude: 45.5407,
    longitude: -100.4315,
    postal_code: '57601'
  },
  '57620': {
    country: 'US',
    city: 'Bison',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Perkins',
    latitude: 45.5161,
    longitude: -102.4827,
    postal_code: '57620'
  },
  '57621': {
    country: 'US',
    city: 'Bullhead',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Corson',
    latitude: 45.7656,
    longitude: -101.0807,
    postal_code: '57621'
  },
  '57622': {
    country: 'US',
    city: 'Cherry Creek',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Ziebach',
    latitude: 44.6055,
    longitude: -101.4999,
    postal_code: '57622'
  },
  '57623': {
    country: 'US',
    city: 'Dupree',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Ziebach',
    latitude: 45.0079,
    longitude: -101.6337,
    postal_code: '57623'
  },
  '57625': {
    country: 'US',
    city: 'Eagle Butte',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Dewey',
    latitude: 45.0016,
    longitude: -101.2329,
    postal_code: '57625'
  },
  '57626': {
    country: 'US',
    city: 'Faith',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 44.9926,
    longitude: -102.0541,
    postal_code: '57626'
  },
  '57630': {
    country: 'US',
    city: 'Glencross',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Dewey',
    latitude: 45.4483,
    longitude: -100.9213,
    postal_code: '57630'
  },
  '57631': {
    country: 'US',
    city: 'Glenham',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Walworth',
    latitude: 45.5335,
    longitude: -100.2716,
    postal_code: '57631'
  },
  '57632': {
    country: 'US',
    city: 'Herreid',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Campbell',
    latitude: 45.8452,
    longitude: -100.049,
    postal_code: '57632'
  },
  '57633': {
    country: 'US',
    city: 'Isabel',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Dewey',
    latitude: 45.3962,
    longitude: -101.4385,
    postal_code: '57633'
  },
  '57634': {
    country: 'US',
    city: 'Keldron',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Corson',
    latitude: 45.7528,
    longitude: -101.8843,
    postal_code: '57634'
  },
  '57636': {
    country: 'US',
    city: 'Lantry',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Dewey',
    latitude: 45.0144,
    longitude: -101.4315,
    postal_code: '57636'
  },
  '57638': {
    country: 'US',
    city: 'Lemmon',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Perkins',
    latitude: 45.9159,
    longitude: -102.1928,
    postal_code: '57638'
  },
  '57639': {
    country: 'US',
    city: 'Little Eagle',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Corson',
    latitude: 45.6758,
    longitude: -100.8043,
    postal_code: '57639'
  },
  '57640': {
    country: 'US',
    city: 'Lodgepole',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Perkins',
    latitude: 45.8232,
    longitude: -102.7599,
    postal_code: '57640'
  },
  '57641': {
    country: 'US',
    city: 'Mc Intosh',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Corson',
    latitude: 45.7084,
    longitude: -101.1546,
    postal_code: '57641'
  },
  '57642': {
    country: 'US',
    city: 'Mc Laughlin',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Corson',
    latitude: 45.8763,
    longitude: -100.8915,
    postal_code: '57642'
  },
  '57644': {
    country: 'US',
    city: 'Meadow',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Perkins',
    latitude: 45.3538,
    longitude: -102.2844,
    postal_code: '57644'
  },
  '57645': {
    country: 'US',
    city: 'Morristown',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Corson',
    latitude: 45.9386,
    longitude: -101.7232,
    postal_code: '57645'
  },
  '57646': {
    country: 'US',
    city: 'Mound City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Campbell',
    latitude: 45.6786,
    longitude: -100.0479,
    postal_code: '57646'
  },
  '57648': {
    country: 'US',
    city: 'Pollock',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Campbell',
    latitude: 45.889,
    longitude: -100.2875,
    postal_code: '57648'
  },
  '57649': {
    country: 'US',
    city: 'Prairie City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Perkins',
    latitude: 45.5813,
    longitude: -102.8085,
    postal_code: '57649'
  },
  '57650': {
    country: 'US',
    city: 'Ralph',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Harding',
    latitude: 45.8554,
    longitude: -103.0356,
    postal_code: '57650'
  },
  '57651': {
    country: 'US',
    city: 'Reva',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Harding',
    latitude: 45.5277,
    longitude: -103.0692,
    postal_code: '57651'
  },
  '57652': {
    country: 'US',
    city: 'Ridgeview',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Dewey',
    latitude: 45.2024,
    longitude: -100.605,
    postal_code: '57652'
  },
  '57656': {
    country: 'US',
    city: 'Timber Lake',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Dewey',
    latitude: 45.3927,
    longitude: -101.0351,
    postal_code: '57656'
  },
  '57657': {
    country: 'US',
    city: 'Trail City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Corson',
    latitude: 45.4725,
    longitude: -100.7254,
    postal_code: '57657'
  },
  '57658': {
    country: 'US',
    city: 'Wakpala',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Corson',
    latitude: 45.7006,
    longitude: -100.5332,
    postal_code: '57658'
  },
  '57659': {
    country: 'US',
    city: 'Walker',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Corson',
    latitude: 45.9103,
    longitude: -101.0904,
    postal_code: '57659'
  },
  '57660': {
    country: 'US',
    city: 'Watauga',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Corson',
    latitude: 45.9276,
    longitude: -101.5129,
    postal_code: '57660'
  },
  '57661': {
    country: 'US',
    city: 'Whitehorse',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Dewey',
    latitude: 45.2705,
    longitude: -100.8865,
    postal_code: '57661'
  },
  '57701': {
    country: 'US',
    city: 'Rapid City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 44.1415,
    longitude: -103.2052,
    postal_code: '57701'
  },
  '57702': {
    country: 'US',
    city: 'Rapid City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 44.0036,
    longitude: -103.3589,
    postal_code: '57702'
  },
  '57703': {
    country: 'US',
    city: 'Rapid City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 44.048,
    longitude: -103.1763,
    postal_code: '57703'
  },
  '57706': {
    country: 'US',
    city: 'Ellsworth Afb',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 44.1447,
    longitude: -103.0759,
    postal_code: '57706'
  },
  '57709': {
    country: 'US',
    city: 'Rapid City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 44.0761,
    longitude: -103.3171,
    postal_code: '57709'
  },
  '57714': {
    country: 'US',
    city: 'Allen',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Bennett',
    latitude: 43.2908,
    longitude: -101.9329,
    postal_code: '57714'
  },
  '57716': {
    country: 'US',
    city: 'Batesland',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Shannon',
    latitude: 43.1085,
    longitude: -102.2006,
    postal_code: '57716'
  },
  '57717': {
    country: 'US',
    city: 'Belle Fourche',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Butte',
    latitude: 44.6723,
    longitude: -103.8396,
    postal_code: '57717'
  },
  '57718': {
    country: 'US',
    city: 'Black Hawk',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 44.1512,
    longitude: -103.3486,
    postal_code: '57718'
  },
  '57719': {
    country: 'US',
    city: 'Box Elder',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 44.1199,
    longitude: -103.0682,
    postal_code: '57719'
  },
  '57720': {
    country: 'US',
    city: 'Buffalo',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Harding',
    latitude: 45.574,
    longitude: -103.5826,
    postal_code: '57720'
  },
  '57722': {
    country: 'US',
    city: 'Buffalo Gap',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Custer',
    latitude: 43.4958,
    longitude: -103.3157,
    postal_code: '57722'
  },
  '57724': {
    country: 'US',
    city: 'Camp Crook',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Harding',
    latitude: 45.6402,
    longitude: -104.0278,
    postal_code: '57724'
  },
  '57725': {
    country: 'US',
    city: 'Caputa',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 43.9481,
    longitude: -102.7935,
    postal_code: '57725'
  },
  '57730': {
    country: 'US',
    city: 'Custer',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Custer',
    latitude: 43.6573,
    longitude: -103.4258,
    postal_code: '57730'
  },
  '57732': {
    country: 'US',
    city: 'Deadwood',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lawrence',
    latitude: 44.3767,
    longitude: -103.7296,
    postal_code: '57732'
  },
  '57735': {
    country: 'US',
    city: 'Edgemont',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Fall River',
    latitude: 43.2874,
    longitude: -103.811,
    postal_code: '57735'
  },
  '57737': {
    country: 'US',
    city: 'Enning',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 44.5755,
    longitude: -102.5632,
    postal_code: '57737'
  },
  '57738': {
    country: 'US',
    city: 'Fairburn',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Custer',
    latitude: 43.6623,
    longitude: -103.2133,
    postal_code: '57738'
  },
  '57741': {
    country: 'US',
    city: 'Fort Meade',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 44.4093,
    longitude: -103.4554,
    postal_code: '57741'
  },
  '57744': {
    country: 'US',
    city: 'Hermosa',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Custer',
    latitude: 43.8188,
    longitude: -103.206,
    postal_code: '57744'
  },
  '57745': {
    country: 'US',
    city: 'Hill City',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 43.9377,
    longitude: -103.5782,
    postal_code: '57745'
  },
  '57747': {
    country: 'US',
    city: 'Hot Springs',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Fall River',
    latitude: 43.4223,
    longitude: -103.4766,
    postal_code: '57747'
  },
  '57748': {
    country: 'US',
    city: 'Howes',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 44.5838,
    longitude: -102.0229,
    postal_code: '57748'
  },
  '57750': {
    country: 'US',
    city: 'Interior',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Jackson',
    latitude: 43.7319,
    longitude: -101.9642,
    postal_code: '57750'
  },
  '57751': {
    country: 'US',
    city: 'Keystone',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 43.8848,
    longitude: -103.3995,
    postal_code: '57751'
  },
  '57752': {
    country: 'US',
    city: 'Kyle',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Shannon',
    latitude: 43.425,
    longitude: -102.1765,
    postal_code: '57752'
  },
  '57754': {
    country: 'US',
    city: 'Lead',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lawrence',
    latitude: 44.263,
    longitude: -103.8712,
    postal_code: '57754'
  },
  '57755': {
    country: 'US',
    city: 'Ludlow',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Harding',
    latitude: 45.8366,
    longitude: -103.3491,
    postal_code: '57755'
  },
  '57756': {
    country: 'US',
    city: 'Manderson',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Shannon',
    latitude: 43.2353,
    longitude: -102.4707,
    postal_code: '57756'
  },
  '57758': {
    country: 'US',
    city: 'Mud Butte',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 45.031,
    longitude: -102.8031,
    postal_code: '57758'
  },
  '57759': {
    country: 'US',
    city: 'Nemo',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lawrence',
    latitude: 44.2097,
    longitude: -103.5449,
    postal_code: '57759'
  },
  '57760': {
    country: 'US',
    city: 'Newell',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Butte',
    latitude: 44.741,
    longitude: -103.3914,
    postal_code: '57760'
  },
  '57761': {
    country: 'US',
    city: 'New Underwood',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 44.0874,
    longitude: -102.8136,
    postal_code: '57761'
  },
  '57762': {
    country: 'US',
    city: 'Nisland',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Butte',
    latitude: 44.6665,
    longitude: -103.5402,
    postal_code: '57762'
  },
  '57763': {
    country: 'US',
    city: 'Oelrichs',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Fall River',
    latitude: 43.1551,
    longitude: -103.2162,
    postal_code: '57763'
  },
  '57764': {
    country: 'US',
    city: 'Oglala',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Shannon',
    latitude: 43.1886,
    longitude: -102.7396,
    postal_code: '57764'
  },
  '57766': {
    country: 'US',
    city: 'Oral',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Fall River',
    latitude: 43.3876,
    longitude: -103.1832,
    postal_code: '57766'
  },
  '57767': {
    country: 'US',
    city: 'Owanka',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 44.0631,
    longitude: -102.5628,
    postal_code: '57767'
  },
  '57769': {
    country: 'US',
    city: 'Piedmont',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 44.2287,
    longitude: -103.3688,
    postal_code: '57769'
  },
  '57770': {
    country: 'US',
    city: 'Pine Ridge',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Shannon',
    latitude: 43.1124,
    longitude: -102.5984,
    postal_code: '57770'
  },
  '57772': {
    country: 'US',
    city: 'Porcupine',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Shannon',
    latitude: 43.2397,
    longitude: -102.331,
    postal_code: '57772'
  },
  '57773': {
    country: 'US',
    city: 'Pringle',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Custer',
    latitude: 43.6086,
    longitude: -103.5938,
    postal_code: '57773'
  },
  '57775': {
    country: 'US',
    city: 'Quinn',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 44.1035,
    longitude: -102.0159,
    postal_code: '57775'
  },
  '57776': {
    country: 'US',
    city: 'Redig',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Harding',
    latitude: 45.2711,
    longitude: -103.5482,
    postal_code: '57776'
  },
  '57779': {
    country: 'US',
    city: 'Saint Onge',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lawrence',
    latitude: 44.5473,
    longitude: -103.7232,
    postal_code: '57779'
  },
  '57780': {
    country: 'US',
    city: 'Scenic',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 43.7994,
    longitude: -102.5353,
    postal_code: '57780'
  },
  '57782': {
    country: 'US',
    city: 'Smithwick',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Fall River',
    latitude: 43.3014,
    longitude: -103.2183,
    postal_code: '57782'
  },
  '57783': {
    country: 'US',
    city: 'Spearfish',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lawrence',
    latitude: 44.4946,
    longitude: -103.865,
    postal_code: '57783'
  },
  '57785': {
    country: 'US',
    city: 'Sturgis',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 44.3692,
    longitude: -103.3617,
    postal_code: '57785'
  },
  '57787': {
    country: 'US',
    city: 'Union Center',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 44.6539,
    longitude: -102.7252,
    postal_code: '57787'
  },
  '57788': {
    country: 'US',
    city: 'Vale',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Butte',
    latitude: 44.6222,
    longitude: -103.3795,
    postal_code: '57788'
  },
  '57790': {
    country: 'US',
    city: 'Wall',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 43.9812,
    longitude: -102.2245,
    postal_code: '57790'
  },
  '57791': {
    country: 'US',
    city: 'Wasta',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Pennington',
    latitude: 44.225,
    longitude: -102.4311,
    postal_code: '57791'
  },
  '57792': {
    country: 'US',
    city: 'White Owl',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Meade',
    latitude: 44.6182,
    longitude: -102.4452,
    postal_code: '57792'
  },
  '57793': {
    country: 'US',
    city: 'Whitewood',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lawrence',
    latitude: 44.4589,
    longitude: -103.637,
    postal_code: '57793'
  },
  '57794': {
    country: 'US',
    city: 'Wounded Knee',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Shannon',
    latitude: 43.1405,
    longitude: -102.3657,
    postal_code: '57794'
  },
  '57799': {
    country: 'US',
    city: 'Spearfish',
    state: 'South Dakota',
    state_short: 'SD',
    county: 'Lawrence',
    latitude: 44.4958,
    longitude: -103.8703,
    postal_code: '57799'
  },
  '58001': {
    country: 'US',
    city: 'Abercrombie',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.4479,
    longitude: -96.7278,
    postal_code: '58001'
  },
  '58002': {
    country: 'US',
    city: 'Absaraka',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.9763,
    longitude: -97.4069,
    postal_code: '58002'
  },
  '58004': {
    country: 'US',
    city: 'Amenia',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 47.0194,
    longitude: -97.2048,
    postal_code: '58004'
  },
  '58005': {
    country: 'US',
    city: 'Argusville',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 47.1066,
    longitude: -96.9058,
    postal_code: '58005'
  },
  '58006': {
    country: 'US',
    city: 'Arthur',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 47.1048,
    longitude: -97.2097,
    postal_code: '58006'
  },
  '58007': {
    country: 'US',
    city: 'Ayr',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 47.0139,
    longitude: -97.4629,
    postal_code: '58007'
  },
  '58008': {
    country: 'US',
    city: 'Barney',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.2496,
    longitude: -96.9708,
    postal_code: '58008'
  },
  '58009': {
    country: 'US',
    city: 'Blanchard',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Traill',
    latitude: 47.3277,
    longitude: -97.2466,
    postal_code: '58009'
  },
  '58011': {
    country: 'US',
    city: 'Buffalo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.9264,
    longitude: -97.5352,
    postal_code: '58011'
  },
  '58012': {
    country: 'US',
    city: 'Casselton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8992,
    longitude: -97.2138,
    postal_code: '58012'
  },
  '58013': {
    country: 'US',
    city: 'Cayuga',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sargent',
    latitude: 46.1326,
    longitude: -97.4059,
    postal_code: '58013'
  },
  '58015': {
    country: 'US',
    city: 'Christine',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.5736,
    longitude: -96.8017,
    postal_code: '58015'
  },
  '58016': {
    country: 'US',
    city: 'Clifford',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Traill',
    latitude: 47.3569,
    longitude: -97.41,
    postal_code: '58016'
  },
  '58017': {
    country: 'US',
    city: 'Cogswell',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sargent',
    latitude: 46.0659,
    longitude: -97.8207,
    postal_code: '58017'
  },
  '58018': {
    country: 'US',
    city: 'Colfax',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.4574,
    longitude: -96.8737,
    postal_code: '58018'
  },
  '58021': {
    country: 'US',
    city: 'Davenport',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.6968,
    longitude: -97.0871,
    postal_code: '58021'
  },
  '58027': {
    country: 'US',
    city: 'Enderlin',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ransom',
    latitude: 46.6079,
    longitude: -97.6106,
    postal_code: '58027'
  },
  '58029': {
    country: 'US',
    city: 'Erie',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 47.1128,
    longitude: -97.3849,
    postal_code: '58029'
  },
  '58030': {
    country: 'US',
    city: 'Fairmount',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.0427,
    longitude: -96.6308,
    postal_code: '58030'
  },
  '58031': {
    country: 'US',
    city: 'Fingal',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 46.7728,
    longitude: -97.7812,
    postal_code: '58031'
  },
  '58032': {
    country: 'US',
    city: 'Forman',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sargent',
    latitude: 46.0721,
    longitude: -97.6986,
    postal_code: '58032'
  },
  '58033': {
    country: 'US',
    city: 'Fort Ransom',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ransom',
    latitude: 46.4494,
    longitude: -97.9091,
    postal_code: '58033'
  },
  '58035': {
    country: 'US',
    city: 'Galesburg',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Traill',
    latitude: 47.27,
    longitude: -97.4065,
    postal_code: '58035'
  },
  '58036': {
    country: 'US',
    city: 'Gardner',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 47.1252,
    longitude: -96.9901,
    postal_code: '58036'
  },
  '58038': {
    country: 'US',
    city: 'Grandin',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 47.2158,
    longitude: -97.0207,
    postal_code: '58038'
  },
  '58040': {
    country: 'US',
    city: 'Gwinner',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sargent',
    latitude: 46.2258,
    longitude: -97.6626,
    postal_code: '58040'
  },
  '58041': {
    country: 'US',
    city: 'Hankinson',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.0558,
    longitude: -96.8991,
    postal_code: '58041'
  },
  '58042': {
    country: 'US',
    city: 'Harwood',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.9565,
    longitude: -96.9654,
    postal_code: '58042'
  },
  '58043': {
    country: 'US',
    city: 'Havana',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sargent',
    latitude: 45.9644,
    longitude: -97.6094,
    postal_code: '58043'
  },
  '58045': {
    country: 'US',
    city: 'Hillsboro',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Traill',
    latitude: 47.3835,
    longitude: -97.0603,
    postal_code: '58045'
  },
  '58046': {
    country: 'US',
    city: 'Hope',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Steele',
    latitude: 47.3237,
    longitude: -97.7134,
    postal_code: '58046'
  },
  '58047': {
    country: 'US',
    city: 'Horace',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.7101,
    longitude: -96.8851,
    postal_code: '58047'
  },
  '58048': {
    country: 'US',
    city: 'Hunter',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 47.1905,
    longitude: -97.217,
    postal_code: '58048'
  },
  '58049': {
    country: 'US',
    city: 'Kathryn',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 46.7177,
    longitude: -97.9763,
    postal_code: '58049'
  },
  '58051': {
    country: 'US',
    city: 'Kindred',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.6543,
    longitude: -97.0049,
    postal_code: '58051'
  },
  '58052': {
    country: 'US',
    city: 'Leonard',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.6593,
    longitude: -97.2725,
    postal_code: '58052'
  },
  '58053': {
    country: 'US',
    city: 'Lidgerwood',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.0641,
    longitude: -97.1799,
    postal_code: '58053'
  },
  '58054': {
    country: 'US',
    city: 'Lisbon',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ransom',
    latitude: 46.456,
    longitude: -97.6369,
    postal_code: '58054'
  },
  '58056': {
    country: 'US',
    city: 'Luverne',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Steele',
    latitude: 47.2319,
    longitude: -97.9314,
    postal_code: '58056'
  },
  '58057': {
    country: 'US',
    city: 'Mcleod',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ransom',
    latitude: 46.4103,
    longitude: -97.3139,
    postal_code: '58057'
  },
  '58058': {
    country: 'US',
    city: 'Mantador',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.1599,
    longitude: -96.957,
    postal_code: '58058'
  },
  '58059': {
    country: 'US',
    city: 'Mapleton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8614,
    longitude: -97.1157,
    postal_code: '58059'
  },
  '58060': {
    country: 'US',
    city: 'Milnor',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sargent',
    latitude: 46.2007,
    longitude: -97.4772,
    postal_code: '58060'
  },
  '58061': {
    country: 'US',
    city: 'Mooreton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.2686,
    longitude: -96.8762,
    postal_code: '58061'
  },
  '58062': {
    country: 'US',
    city: 'Nome',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 46.6748,
    longitude: -97.7911,
    postal_code: '58062'
  },
  '58063': {
    country: 'US',
    city: 'Oriska',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 46.9433,
    longitude: -97.7853,
    postal_code: '58063'
  },
  '58064': {
    country: 'US',
    city: 'Page',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 47.1515,
    longitude: -97.5967,
    postal_code: '58064'
  },
  '58065': {
    country: 'US',
    city: 'Pillsbury',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 47.1862,
    longitude: -97.7605,
    postal_code: '58065'
  },
  '58067': {
    country: 'US',
    city: 'Rutland',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sargent',
    latitude: 46.0738,
    longitude: -97.5483,
    postal_code: '58067'
  },
  '58068': {
    country: 'US',
    city: 'Sheldon',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ransom',
    latitude: 46.5548,
    longitude: -97.4543,
    postal_code: '58068'
  },
  '58069': {
    country: 'US',
    city: 'Stirum',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sargent',
    latitude: 46.2519,
    longitude: -97.8326,
    postal_code: '58069'
  },
  '58071': {
    country: 'US',
    city: 'Tower City',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.9119,
    longitude: -97.6594,
    postal_code: '58071'
  },
  '58072': {
    country: 'US',
    city: 'Valley City',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 46.9268,
    longitude: -98.0033,
    postal_code: '58072'
  },
  '58074': {
    country: 'US',
    city: 'Wahpeton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.2652,
    longitude: -96.6059,
    postal_code: '58074'
  },
  '58075': {
    country: 'US',
    city: 'Wahpeton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.3366,
    longitude: -96.7921,
    postal_code: '58075'
  },
  '58076': {
    country: 'US',
    city: 'Wahpeton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.2718,
    longitude: -96.6081,
    postal_code: '58076'
  },
  '58077': {
    country: 'US',
    city: 'Walcott',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.5835,
    longitude: -97.0014,
    postal_code: '58077'
  },
  '58078': {
    country: 'US',
    city: 'West Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8907,
    longitude: -96.9258,
    postal_code: '58078'
  },
  '58079': {
    country: 'US',
    city: 'Wheatland',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8326,
    longitude: -97.3461,
    postal_code: '58079'
  },
  '58081': {
    country: 'US',
    city: 'Wyndmere',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Richland',
    latitude: 46.2891,
    longitude: -97.1289,
    postal_code: '58081'
  },
  '58102': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.9209,
    longitude: -96.8318,
    postal_code: '58102'
  },
  '58103': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8564,
    longitude: -96.8123,
    postal_code: '58103'
  },
  '58104': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.7932,
    longitude: -96.8397,
    postal_code: '58104'
  },
  '58105': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8954,
    longitude: -96.8078,
    postal_code: '58105'
  },
  '58106': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8772,
    longitude: -96.7898,
    postal_code: '58106'
  },
  '58107': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8772,
    longitude: -96.7898,
    postal_code: '58107'
  },
  '58108': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8772,
    longitude: -96.7898,
    postal_code: '58108'
  },
  '58109': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8235,
    longitude: -96.8148,
    postal_code: '58109'
  },
  '58121': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8772,
    longitude: -96.7898,
    postal_code: '58121'
  },
  '58122': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8772,
    longitude: -96.7898,
    postal_code: '58122'
  },
  '58124': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8772,
    longitude: -96.7898,
    postal_code: '58124'
  },
  '58125': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8772,
    longitude: -96.7898,
    postal_code: '58125'
  },
  '58126': {
    country: 'US',
    city: 'Fargo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cass',
    latitude: 46.8772,
    longitude: -96.7898,
    postal_code: '58126'
  },
  '58201': {
    country: 'US',
    city: 'Grand Forks',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.901,
    longitude: -97.0446,
    postal_code: '58201'
  },
  '58202': {
    country: 'US',
    city: 'Grand Forks',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9215,
    longitude: -97.0735,
    postal_code: '58202'
  },
  '58203': {
    country: 'US',
    city: 'Grand Forks',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9272,
    longitude: -97.0672,
    postal_code: '58203'
  },
  '58204': {
    country: 'US',
    city: 'Grand Forks Afb',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9679,
    longitude: -97.3675,
    postal_code: '58204'
  },
  '58205': {
    country: 'US',
    city: 'Grand Forks Afb',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9515,
    longitude: -97.379,
    postal_code: '58205'
  },
  '58206': {
    country: 'US',
    city: 'Grand Forks',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9253,
    longitude: -97.0329,
    postal_code: '58206'
  },
  '58207': {
    country: 'US',
    city: 'Grand Forks',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9253,
    longitude: -97.0329,
    postal_code: '58207'
  },
  '58208': {
    country: 'US',
    city: 'Grand Forks',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9253,
    longitude: -97.0329,
    postal_code: '58208'
  },
  '58210': {
    country: 'US',
    city: 'Adams',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.4223,
    longitude: -98.0867,
    postal_code: '58210'
  },
  '58212': {
    country: 'US',
    city: 'Aneta',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Nelson',
    latitude: 47.6993,
    longitude: -97.9814,
    postal_code: '58212'
  },
  '58214': {
    country: 'US',
    city: 'Arvilla',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9093,
    longitude: -97.4871,
    postal_code: '58214'
  },
  '58216': {
    country: 'US',
    city: 'Bathgate',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.8686,
    longitude: -97.4832,
    postal_code: '58216'
  },
  '58218': {
    country: 'US',
    city: 'Buxton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Traill',
    latitude: 47.6163,
    longitude: -97.0893,
    postal_code: '58218'
  },
  '58219': {
    country: 'US',
    city: 'Caledonia',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Traill',
    latitude: 47.4776,
    longitude: -96.879,
    postal_code: '58219'
  },
  '58220': {
    country: 'US',
    city: 'Cavalier',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.7935,
    longitude: -97.7143,
    postal_code: '58220'
  },
  '58222': {
    country: 'US',
    city: 'Crystal',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.5924,
    longitude: -97.6738,
    postal_code: '58222'
  },
  '58223': {
    country: 'US',
    city: 'Cummings',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Traill',
    latitude: 47.537,
    longitude: -96.9913,
    postal_code: '58223'
  },
  '58224': {
    country: 'US',
    city: 'Dahlen',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Nelson',
    latitude: 48.1599,
    longitude: -97.9573,
    postal_code: '58224'
  },
  '58225': {
    country: 'US',
    city: 'Drayton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.6104,
    longitude: -97.2156,
    postal_code: '58225'
  },
  '58227': {
    country: 'US',
    city: 'Edinburg',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.4997,
    longitude: -97.8932,
    postal_code: '58227'
  },
  '58228': {
    country: 'US',
    city: 'Emerado',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9214,
    longitude: -97.263,
    postal_code: '58228'
  },
  '58229': {
    country: 'US',
    city: 'Fairdale',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.4819,
    longitude: -98.2066,
    postal_code: '58229'
  },
  '58230': {
    country: 'US',
    city: 'Finley',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Steele',
    latitude: 47.5306,
    longitude: -97.7442,
    postal_code: '58230'
  },
  '58231': {
    country: 'US',
    city: 'Fordville',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.2212,
    longitude: -97.8022,
    postal_code: '58231'
  },
  '58233': {
    country: 'US',
    city: 'Forest River',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.2251,
    longitude: -97.4605,
    postal_code: '58233'
  },
  '58235': {
    country: 'US',
    city: 'Gilby',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 48.0853,
    longitude: -97.4706,
    postal_code: '58235'
  },
  '58236': {
    country: 'US',
    city: 'Glasston',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.7201,
    longitude: -97.4696,
    postal_code: '58236'
  },
  '58237': {
    country: 'US',
    city: 'Grafton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.4122,
    longitude: -97.4106,
    postal_code: '58237'
  },
  '58238': {
    country: 'US',
    city: 'Hamilton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.7942,
    longitude: -97.4693,
    postal_code: '58238'
  },
  '58239': {
    country: 'US',
    city: 'Hannah',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.9594,
    longitude: -98.7237,
    postal_code: '58239'
  },
  '58240': {
    country: 'US',
    city: 'Hatton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Traill',
    latitude: 47.638,
    longitude: -97.4325,
    postal_code: '58240'
  },
  '58241': {
    country: 'US',
    city: 'Hensel',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.7203,
    longitude: -97.6993,
    postal_code: '58241'
  },
  '58243': {
    country: 'US',
    city: 'Hoople',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.5198,
    longitude: -97.6183,
    postal_code: '58243'
  },
  '58244': {
    country: 'US',
    city: 'Inkster',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 48.1514,
    longitude: -97.6443,
    postal_code: '58244'
  },
  '58249': {
    country: 'US',
    city: 'Langdon',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.7866,
    longitude: -98.3566,
    postal_code: '58249'
  },
  '58250': {
    country: 'US',
    city: 'Lankin',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.2952,
    longitude: -98.007,
    postal_code: '58250'
  },
  '58251': {
    country: 'US',
    city: 'Larimore',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9115,
    longitude: -97.6264,
    postal_code: '58251'
  },
  '58254': {
    country: 'US',
    city: 'Mcville',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Nelson',
    latitude: 47.7639,
    longitude: -98.1773,
    postal_code: '58254'
  },
  '58255': {
    country: 'US',
    city: 'Maida',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.9986,
    longitude: -98.3648,
    postal_code: '58255'
  },
  '58256': {
    country: 'US',
    city: 'Manvel',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 48.0853,
    longitude: -97.1943,
    postal_code: '58256'
  },
  '58257': {
    country: 'US',
    city: 'Mayville',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Traill',
    latitude: 47.5014,
    longitude: -97.3176,
    postal_code: '58257'
  },
  '58258': {
    country: 'US',
    city: 'Mekinock',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 48.0074,
    longitude: -97.4305,
    postal_code: '58258'
  },
  '58259': {
    country: 'US',
    city: 'Michigan',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Nelson',
    latitude: 48.0204,
    longitude: -98.1186,
    postal_code: '58259'
  },
  '58260': {
    country: 'US',
    city: 'Milton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.617,
    longitude: -98.0183,
    postal_code: '58260'
  },
  '58261': {
    country: 'US',
    city: 'Minto',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.26,
    longitude: -97.3144,
    postal_code: '58261'
  },
  '58262': {
    country: 'US',
    city: 'Mountain',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.6931,
    longitude: -97.8841,
    postal_code: '58262'
  },
  '58265': {
    country: 'US',
    city: 'Neche',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.9796,
    longitude: -97.5427,
    postal_code: '58265'
  },
  '58266': {
    country: 'US',
    city: 'Niagara',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.9848,
    longitude: -97.8334,
    postal_code: '58266'
  },
  '58267': {
    country: 'US',
    city: 'Northwood',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.7591,
    longitude: -97.6038,
    postal_code: '58267'
  },
  '58269': {
    country: 'US',
    city: 'Osnabrock',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.6689,
    longitude: -98.1512,
    postal_code: '58269'
  },
  '58270': {
    country: 'US',
    city: 'Park River',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.4039,
    longitude: -97.7439,
    postal_code: '58270'
  },
  '58271': {
    country: 'US',
    city: 'Pembina',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.859,
    longitude: -97.2886,
    postal_code: '58271'
  },
  '58272': {
    country: 'US',
    city: 'Petersburg',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Nelson',
    latitude: 47.998,
    longitude: -97.984,
    postal_code: '58272'
  },
  '58273': {
    country: 'US',
    city: 'Pisek',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Walsh',
    latitude: 48.2971,
    longitude: -97.7029,
    postal_code: '58273'
  },
  '58274': {
    country: 'US',
    city: 'Portland',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Traill',
    latitude: 47.5015,
    longitude: -97.3843,
    postal_code: '58274'
  },
  '58275': {
    country: 'US',
    city: 'Reynolds',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.7068,
    longitude: -97.2092,
    postal_code: '58275'
  },
  '58276': {
    country: 'US',
    city: 'Saint Thomas',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.6252,
    longitude: -97.4545,
    postal_code: '58276'
  },
  '58277': {
    country: 'US',
    city: 'Sharon',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Steele',
    latitude: 47.6062,
    longitude: -97.9051,
    postal_code: '58277'
  },
  '58278': {
    country: 'US',
    city: 'Thompson',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grand Forks',
    latitude: 47.7766,
    longitude: -97.0962,
    postal_code: '58278'
  },
  '58281': {
    country: 'US',
    city: 'Wales',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.9216,
    longitude: -98.5596,
    postal_code: '58281'
  },
  '58282': {
    country: 'US',
    city: 'Walhalla',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pembina',
    latitude: 48.8808,
    longitude: -97.7974,
    postal_code: '58282'
  },
  '58301': {
    country: 'US',
    city: 'Devils Lake',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ramsey',
    latitude: 48.1132,
    longitude: -98.8616,
    postal_code: '58301'
  },
  '58310': {
    country: 'US',
    city: 'Agate',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Rolette',
    latitude: 48.6228,
    longitude: -99.4932,
    postal_code: '58310'
  },
  '58311': {
    country: 'US',
    city: 'Alsen',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.6525,
    longitude: -98.5795,
    postal_code: '58311'
  },
  '58313': {
    country: 'US',
    city: 'Balta',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pierce',
    latitude: 48.1667,
    longitude: -100.0371,
    postal_code: '58313'
  },
  '58316': {
    country: 'US',
    city: 'Belcourt',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Rolette',
    latitude: 48.8379,
    longitude: -99.7688,
    postal_code: '58316'
  },
  '58317': {
    country: 'US',
    city: 'Bisbee',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Towner',
    latitude: 48.6258,
    longitude: -99.3779,
    postal_code: '58317'
  },
  '58318': {
    country: 'US',
    city: 'Bottineau',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bottineau',
    latitude: 48.8451,
    longitude: -100.4329,
    postal_code: '58318'
  },
  '58321': {
    country: 'US',
    city: 'Brocket',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ramsey',
    latitude: 48.2255,
    longitude: -98.3559,
    postal_code: '58321'
  },
  '58323': {
    country: 'US',
    city: 'Calvin',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.851,
    longitude: -98.8808,
    postal_code: '58323'
  },
  '58324': {
    country: 'US',
    city: 'Cando',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Towner',
    latitude: 48.4867,
    longitude: -99.2099,
    postal_code: '58324'
  },
  '58325': {
    country: 'US',
    city: 'Churchs Ferry',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ramsey',
    latitude: 48.2862,
    longitude: -99.1412,
    postal_code: '58325'
  },
  '58327': {
    country: 'US',
    city: 'Crary',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ramsey',
    latitude: 48.091,
    longitude: -98.5731,
    postal_code: '58327'
  },
  '58329': {
    country: 'US',
    city: 'Dunseith',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Rolette',
    latitude: 48.8561,
    longitude: -100.0241,
    postal_code: '58329'
  },
  '58330': {
    country: 'US',
    city: 'Edmore',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ramsey',
    latitude: 48.4308,
    longitude: -98.4458,
    postal_code: '58330'
  },
  '58331': {
    country: 'US',
    city: 'Egeland',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Towner',
    latitude: 48.6355,
    longitude: -99.1115,
    postal_code: '58331'
  },
  '58332': {
    country: 'US',
    city: 'Esmond',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 48.0419,
    longitude: -99.7617,
    postal_code: '58332'
  },
  '58335': {
    country: 'US',
    city: 'Fort Totten',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 47.9811,
    longitude: -99.0264,
    postal_code: '58335'
  },
  '58338': {
    country: 'US',
    city: 'Hampden',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ramsey',
    latitude: 48.5229,
    longitude: -98.6543,
    postal_code: '58338'
  },
  '58339': {
    country: 'US',
    city: 'Hansboro',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Towner',
    latitude: 48.9001,
    longitude: -99.4278,
    postal_code: '58339'
  },
  '58341': {
    country: 'US',
    city: 'Harvey',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Wells',
    latitude: 47.7035,
    longitude: -99.7478,
    postal_code: '58341'
  },
  '58343': {
    country: 'US',
    city: 'Knox',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 48.3388,
    longitude: -99.6713,
    postal_code: '58343'
  },
  '58344': {
    country: 'US',
    city: 'Lakota',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Nelson',
    latitude: 47.972,
    longitude: -98.3246,
    postal_code: '58344'
  },
  '58345': {
    country: 'US',
    city: 'Lawton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ramsey',
    latitude: 48.3033,
    longitude: -98.4142,
    postal_code: '58345'
  },
  '58346': {
    country: 'US',
    city: 'Leeds',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 48.2889,
    longitude: -99.4376,
    postal_code: '58346'
  },
  '58348': {
    country: 'US',
    city: 'Maddock',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 47.9625,
    longitude: -99.5301,
    postal_code: '58348'
  },
  '58351': {
    country: 'US',
    city: 'Minnewaukan',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 48.0698,
    longitude: -99.2743,
    postal_code: '58351'
  },
  '58352': {
    country: 'US',
    city: 'Munich',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.6694,
    longitude: -98.8355,
    postal_code: '58352'
  },
  '58353': {
    country: 'US',
    city: 'Mylo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Rolette',
    latitude: 48.6329,
    longitude: -99.5957,
    postal_code: '58353'
  },
  '58355': {
    country: 'US',
    city: 'Nekoma',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.5772,
    longitude: -98.3704,
    postal_code: '58355'
  },
  '58356': {
    country: 'US',
    city: 'New Rockford',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Eddy',
    latitude: 47.7174,
    longitude: -98.8987,
    postal_code: '58356'
  },
  '58357': {
    country: 'US',
    city: 'Oberon',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 47.9487,
    longitude: -99.1401,
    postal_code: '58357'
  },
  '58361': {
    country: 'US',
    city: 'Pekin',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Nelson',
    latitude: 47.7696,
    longitude: -98.326,
    postal_code: '58361'
  },
  '58362': {
    country: 'US',
    city: 'Penn',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ramsey',
    latitude: 48.2271,
    longitude: -99.0655,
    postal_code: '58362'
  },
  '58363': {
    country: 'US',
    city: 'Perth',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Towner',
    latitude: 48.7546,
    longitude: -99.4384,
    postal_code: '58363'
  },
  '58365': {
    country: 'US',
    city: 'Rocklake',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Towner',
    latitude: 48.8216,
    longitude: -99.1796,
    postal_code: '58365'
  },
  '58366': {
    country: 'US',
    city: 'Rolette',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Rolette',
    latitude: 48.7009,
    longitude: -99.8785,
    postal_code: '58366'
  },
  '58367': {
    country: 'US',
    city: 'Rolla',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Rolette',
    latitude: 48.8594,
    longitude: -99.6134,
    postal_code: '58367'
  },
  '58368': {
    country: 'US',
    city: 'Rugby',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pierce',
    latitude: 48.2545,
    longitude: -99.9899,
    postal_code: '58368'
  },
  '58369': {
    country: 'US',
    city: 'Saint John',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Rolette',
    latitude: 48.9366,
    longitude: -99.7648,
    postal_code: '58369'
  },
  '58370': {
    country: 'US',
    city: 'Saint Michael',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 47.985,
    longitude: -98.8721,
    postal_code: '58370'
  },
  '58372': {
    country: 'US',
    city: 'Sarles',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Cavalier',
    latitude: 48.9458,
    longitude: -98.996,
    postal_code: '58372'
  },
  '58374': {
    country: 'US',
    city: 'Sheyenne',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Eddy',
    latitude: 47.8184,
    longitude: -99.0583,
    postal_code: '58374'
  },
  '58377': {
    country: 'US',
    city: 'Starkweather',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ramsey',
    latitude: 48.4488,
    longitude: -98.8539,
    postal_code: '58377'
  },
  '58379': {
    country: 'US',
    city: 'Tokio',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 47.9247,
    longitude: -98.8162,
    postal_code: '58379'
  },
  '58380': {
    country: 'US',
    city: 'Tolna',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Nelson',
    latitude: 47.8462,
    longitude: -98.4512,
    postal_code: '58380'
  },
  '58381': {
    country: 'US',
    city: 'Warwick',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 47.8913,
    longitude: -98.681,
    postal_code: '58381'
  },
  '58382': {
    country: 'US',
    city: 'Webster',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ramsey',
    latitude: 48.3229,
    longitude: -98.8739,
    postal_code: '58382'
  },
  '58384': {
    country: 'US',
    city: 'Willow City',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bottineau',
    latitude: 48.6062,
    longitude: -100.2968,
    postal_code: '58384'
  },
  '58385': {
    country: 'US',
    city: 'Wolford',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Pierce',
    latitude: 48.4809,
    longitude: -99.6628,
    postal_code: '58385'
  },
  '58386': {
    country: 'US',
    city: 'York',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Benson',
    latitude: 48.2565,
    longitude: -99.6025,
    postal_code: '58386'
  },
  '58401': {
    country: 'US',
    city: 'Jamestown',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 46.921,
    longitude: -98.7455,
    postal_code: '58401'
  },
  '58402': {
    country: 'US',
    city: 'Jamestown',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 46.9788,
    longitude: -98.96,
    postal_code: '58402'
  },
  '58405': {
    country: 'US',
    city: 'Jamestown',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 46.9133,
    longitude: -98.6996,
    postal_code: '58405'
  },
  '58413': {
    country: 'US',
    city: 'Ashley',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McIntosh',
    latitude: 46.0537,
    longitude: -99.3164,
    postal_code: '58413'
  },
  '58415': {
    country: 'US',
    city: 'Berlin',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'LaMoure',
    latitude: 46.3786,
    longitude: -98.4898,
    postal_code: '58415'
  },
  '58416': {
    country: 'US',
    city: 'Binford',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Griggs',
    latitude: 47.5739,
    longitude: -98.3546,
    postal_code: '58416'
  },
  '58418': {
    country: 'US',
    city: 'Bowdon',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Wells',
    latitude: 47.4343,
    longitude: -99.7015,
    postal_code: '58418'
  },
  '58420': {
    country: 'US',
    city: 'Buchanan',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 47.0051,
    longitude: -98.9007,
    postal_code: '58420'
  },
  '58421': {
    country: 'US',
    city: 'Carrington',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Foster',
    latitude: 47.4573,
    longitude: -98.8933,
    postal_code: '58421'
  },
  '58422': {
    country: 'US',
    city: 'Cathay',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Wells',
    latitude: 47.6289,
    longitude: -99.4049,
    postal_code: '58422'
  },
  '58423': {
    country: 'US',
    city: 'Chaseley',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Wells',
    latitude: 47.449,
    longitude: -99.8241,
    postal_code: '58423'
  },
  '58424': {
    country: 'US',
    city: 'Cleveland',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 46.8931,
    longitude: -99.1288,
    postal_code: '58424'
  },
  '58425': {
    country: 'US',
    city: 'Cooperstown',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Griggs',
    latitude: 47.4527,
    longitude: -98.1533,
    postal_code: '58425'
  },
  '58426': {
    country: 'US',
    city: 'Courtenay',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 47.2272,
    longitude: -98.5489,
    postal_code: '58426'
  },
  '58428': {
    country: 'US',
    city: 'Dawson',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Kidder',
    latitude: 46.8686,
    longitude: -99.7515,
    postal_code: '58428'
  },
  '58429': {
    country: 'US',
    city: 'Dazey',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 47.1828,
    longitude: -98.1616,
    postal_code: '58429'
  },
  '58430': {
    country: 'US',
    city: 'Denhoff',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sheridan',
    latitude: 47.5709,
    longitude: -100.263,
    postal_code: '58430'
  },
  '58431': {
    country: 'US',
    city: 'Dickey',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'LaMoure',
    latitude: 46.5426,
    longitude: -98.4682,
    postal_code: '58431'
  },
  '58433': {
    country: 'US',
    city: 'Edgeley',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'LaMoure',
    latitude: 46.3591,
    longitude: -98.7157,
    postal_code: '58433'
  },
  '58436': {
    country: 'US',
    city: 'Ellendale',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Dickey',
    latitude: 46.0073,
    longitude: -98.5138,
    postal_code: '58436'
  },
  '58438': {
    country: 'US',
    city: 'Fessenden',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Wells',
    latitude: 47.6286,
    longitude: -99.6434,
    postal_code: '58438'
  },
  '58439': {
    country: 'US',
    city: 'Forbes',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Dickey',
    latitude: 46.0109,
    longitude: -98.8124,
    postal_code: '58439'
  },
  '58440': {
    country: 'US',
    city: 'Fredonia',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Logan',
    latitude: 46.3701,
    longitude: -99.1733,
    postal_code: '58440'
  },
  '58441': {
    country: 'US',
    city: 'Fullerton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Dickey',
    latitude: 46.1951,
    longitude: -98.3882,
    postal_code: '58441'
  },
  '58442': {
    country: 'US',
    city: 'Gackle',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Logan',
    latitude: 46.5913,
    longitude: -99.2191,
    postal_code: '58442'
  },
  '58443': {
    country: 'US',
    city: 'Glenfield',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Foster',
    latitude: 47.4569,
    longitude: -98.6916,
    postal_code: '58443'
  },
  '58444': {
    country: 'US',
    city: 'Goodrich',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sheridan',
    latitude: 47.471,
    longitude: -100.119,
    postal_code: '58444'
  },
  '58445': {
    country: 'US',
    city: 'Grace City',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Foster',
    latitude: 47.5536,
    longitude: -98.8095,
    postal_code: '58445'
  },
  '58448': {
    country: 'US',
    city: 'Hannaford',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Griggs',
    latitude: 47.3786,
    longitude: -98.3502,
    postal_code: '58448'
  },
  '58451': {
    country: 'US',
    city: 'Hurdsfield',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Wells',
    latitude: 47.4387,
    longitude: -99.9416,
    postal_code: '58451'
  },
  '58452': {
    country: 'US',
    city: 'Jessie',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Griggs',
    latitude: 47.5422,
    longitude: -98.2382,
    postal_code: '58452'
  },
  '58454': {
    country: 'US',
    city: 'Jud',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'LaMoure',
    latitude: 46.5511,
    longitude: -98.8667,
    postal_code: '58454'
  },
  '58455': {
    country: 'US',
    city: 'Kensal',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 47.2726,
    longitude: -98.7206,
    postal_code: '58455'
  },
  '58456': {
    country: 'US',
    city: 'Kulm',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'LaMoure',
    latitude: 46.3073,
    longitude: -98.9427,
    postal_code: '58456'
  },
  '58458': {
    country: 'US',
    city: 'Lamoure',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'LaMoure',
    latitude: 46.3844,
    longitude: -98.3587,
    postal_code: '58458'
  },
  '58460': {
    country: 'US',
    city: 'Lehr',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McIntosh',
    latitude: 46.2586,
    longitude: -99.3491,
    postal_code: '58460'
  },
  '58461': {
    country: 'US',
    city: 'Litchville',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 46.6536,
    longitude: -98.1943,
    postal_code: '58461'
  },
  '58463': {
    country: 'US',
    city: 'Mcclusky',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sheridan',
    latitude: 47.4896,
    longitude: -100.452,
    postal_code: '58463'
  },
  '58464': {
    country: 'US',
    city: 'Mchenry',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Foster',
    latitude: 47.5604,
    longitude: -98.5726,
    postal_code: '58464'
  },
  '58466': {
    country: 'US',
    city: 'Marion',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'LaMoure',
    latitude: 46.5514,
    longitude: -98.369,
    postal_code: '58466'
  },
  '58467': {
    country: 'US',
    city: 'Medina',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 46.892,
    longitude: -99.3106,
    postal_code: '58467'
  },
  '58472': {
    country: 'US',
    city: 'Montpelier',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 46.6816,
    longitude: -98.6604,
    postal_code: '58472'
  },
  '58474': {
    country: 'US',
    city: 'Oakes',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Dickey',
    latitude: 46.1095,
    longitude: -98.2257,
    postal_code: '58474'
  },
  '58475': {
    country: 'US',
    city: 'Pettibone',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Kidder',
    latitude: 47.12,
    longitude: -99.5279,
    postal_code: '58475'
  },
  '58476': {
    country: 'US',
    city: 'Pingree',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 47.2038,
    longitude: -99.0144,
    postal_code: '58476'
  },
  '58477': {
    country: 'US',
    city: 'Regan',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 47.1527,
    longitude: -100.5224,
    postal_code: '58477'
  },
  '58478': {
    country: 'US',
    city: 'Robinson',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Kidder',
    latitude: 47.1749,
    longitude: -99.7457,
    postal_code: '58478'
  },
  '58479': {
    country: 'US',
    city: 'Rogers',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 47.0741,
    longitude: -98.2136,
    postal_code: '58479'
  },
  '58480': {
    country: 'US',
    city: 'Sanborn',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 46.9271,
    longitude: -98.2336,
    postal_code: '58480'
  },
  '58481': {
    country: 'US',
    city: 'Spiritwood',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 46.9865,
    longitude: -98.5219,
    postal_code: '58481'
  },
  '58482': {
    country: 'US',
    city: 'Steele',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Kidder',
    latitude: 46.8524,
    longitude: -99.9336,
    postal_code: '58482'
  },
  '58483': {
    country: 'US',
    city: 'Streeter',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 46.695,
    longitude: -99.2971,
    postal_code: '58483'
  },
  '58484': {
    country: 'US',
    city: 'Sutton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Griggs',
    latitude: 47.3854,
    longitude: -98.432,
    postal_code: '58484'
  },
  '58486': {
    country: 'US',
    city: 'Sykeston',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Wells',
    latitude: 47.4369,
    longitude: -99.3975,
    postal_code: '58486'
  },
  '58487': {
    country: 'US',
    city: 'Tappen',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Kidder',
    latitude: 46.8336,
    longitude: -99.6019,
    postal_code: '58487'
  },
  '58488': {
    country: 'US',
    city: 'Tuttle',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Kidder',
    latitude: 47.1626,
    longitude: -99.9873,
    postal_code: '58488'
  },
  '58490': {
    country: 'US',
    city: 'Verona',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'LaMoure',
    latitude: 46.3712,
    longitude: -98.0895,
    postal_code: '58490'
  },
  '58492': {
    country: 'US',
    city: 'Wimbledon',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Barnes',
    latitude: 47.1438,
    longitude: -98.4329,
    postal_code: '58492'
  },
  '58494': {
    country: 'US',
    city: 'Wing',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 47.1519,
    longitude: -100.3072,
    postal_code: '58494'
  },
  '58495': {
    country: 'US',
    city: 'Wishek',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McIntosh',
    latitude: 46.1917,
    longitude: -99.6131,
    postal_code: '58495'
  },
  '58496': {
    country: 'US',
    city: 'Woodworth',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 47.1636,
    longitude: -99.3406,
    postal_code: '58496'
  },
  '58497': {
    country: 'US',
    city: 'Ypsilanti',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stutsman',
    latitude: 46.7719,
    longitude: -98.4876,
    postal_code: '58497'
  },
  '58501': {
    country: 'US',
    city: 'Bismarck',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.8083,
    longitude: -100.7837,
    postal_code: '58501'
  },
  '58502': {
    country: 'US',
    city: 'Bismarck',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.8887,
    longitude: -100.6819,
    postal_code: '58502'
  },
  '58503': {
    country: 'US',
    city: 'Bismarck',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.8394,
    longitude: -100.7843,
    postal_code: '58503'
  },
  '58504': {
    country: 'US',
    city: 'Bismarck',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.7231,
    longitude: -100.678,
    postal_code: '58504'
  },
  '58505': {
    country: 'US',
    city: 'Bismarck',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.8165,
    longitude: -100.7805,
    postal_code: '58505'
  },
  '58506': {
    country: 'US',
    city: 'Bismarck',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.8083,
    longitude: -100.7837,
    postal_code: '58506'
  },
  '58507': {
    country: 'US',
    city: 'Bismarck',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.8083,
    longitude: -100.7837,
    postal_code: '58507'
  },
  '58520': {
    country: 'US',
    city: 'Almont',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Morton',
    latitude: 46.7046,
    longitude: -101.5221,
    postal_code: '58520'
  },
  '58521': {
    country: 'US',
    city: 'Baldwin',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.9543,
    longitude: -100.7615,
    postal_code: '58521'
  },
  '58523': {
    country: 'US',
    city: 'Beulah',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mercer',
    latitude: 47.2707,
    longitude: -101.8075,
    postal_code: '58523'
  },
  '58524': {
    country: 'US',
    city: 'Braddock',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Emmons',
    latitude: 46.5603,
    longitude: -100.1075,
    postal_code: '58524'
  },
  '58528': {
    country: 'US',
    city: 'Cannon Ball',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sioux',
    latitude: 46.3874,
    longitude: -100.5975,
    postal_code: '58528'
  },
  '58529': {
    country: 'US',
    city: 'Carson',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grant',
    latitude: 46.4624,
    longitude: -101.5387,
    postal_code: '58529'
  },
  '58530': {
    country: 'US',
    city: 'Center',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Oliver',
    latitude: 47.1459,
    longitude: -101.1594,
    postal_code: '58530'
  },
  '58531': {
    country: 'US',
    city: 'Coleharbor',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.5442,
    longitude: -101.2238,
    postal_code: '58531'
  },
  '58532': {
    country: 'US',
    city: 'Driscoll',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.8511,
    longitude: -100.1441,
    postal_code: '58532'
  },
  '58533': {
    country: 'US',
    city: 'Elgin',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grant',
    latitude: 46.3679,
    longitude: -101.7558,
    postal_code: '58533'
  },
  '58535': {
    country: 'US',
    city: 'Flasher',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Morton',
    latitude: 46.5998,
    longitude: -101.3166,
    postal_code: '58535'
  },
  '58538': {
    country: 'US',
    city: 'Fort Yates',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sioux',
    latitude: 46.0905,
    longitude: -100.6516,
    postal_code: '58538'
  },
  '58540': {
    country: 'US',
    city: 'Garrison',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.6675,
    longitude: -101.6142,
    postal_code: '58540'
  },
  '58541': {
    country: 'US',
    city: 'Golden Valley',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mercer',
    latitude: 47.2938,
    longitude: -102.0612,
    postal_code: '58541'
  },
  '58542': {
    country: 'US',
    city: 'Hague',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Emmons',
    latitude: 46.0589,
    longitude: -99.9745,
    postal_code: '58542'
  },
  '58544': {
    country: 'US',
    city: 'Hazelton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Emmons',
    latitude: 46.4875,
    longitude: -100.2733,
    postal_code: '58544'
  },
  '58545': {
    country: 'US',
    city: 'Hazen',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mercer',
    latitude: 47.3271,
    longitude: -101.6107,
    postal_code: '58545'
  },
  '58549': {
    country: 'US',
    city: 'Kintyre',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Emmons',
    latitude: 46.5736,
    longitude: -99.9705,
    postal_code: '58549'
  },
  '58552': {
    country: 'US',
    city: 'Linton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Emmons',
    latitude: 46.1855,
    longitude: -100.2331,
    postal_code: '58552'
  },
  '58554': {
    country: 'US',
    city: 'Mandan',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Morton',
    latitude: 46.8306,
    longitude: -100.9092,
    postal_code: '58554'
  },
  '58558': {
    country: 'US',
    city: 'Menoken',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.8205,
    longitude: -100.5312,
    postal_code: '58558'
  },
  '58559': {
    country: 'US',
    city: 'Mercer',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.4858,
    longitude: -100.7171,
    postal_code: '58559'
  },
  '58560': {
    country: 'US',
    city: 'Moffit',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.6757,
    longitude: -100.2975,
    postal_code: '58560'
  },
  '58561': {
    country: 'US',
    city: 'Napoleon',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Logan',
    latitude: 46.4867,
    longitude: -99.7725,
    postal_code: '58561'
  },
  '58562': {
    country: 'US',
    city: 'New Leipzig',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grant',
    latitude: 46.3823,
    longitude: -101.9619,
    postal_code: '58562'
  },
  '58563': {
    country: 'US',
    city: 'New Salem',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Morton',
    latitude: 46.806,
    longitude: -101.4291,
    postal_code: '58563'
  },
  '58564': {
    country: 'US',
    city: 'Raleigh',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grant',
    latitude: 46.3352,
    longitude: -101.2827,
    postal_code: '58564'
  },
  '58565': {
    country: 'US',
    city: 'Riverdale',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.4975,
    longitude: -101.3713,
    postal_code: '58565'
  },
  '58566': {
    country: 'US',
    city: 'Saint Anthony',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Morton',
    latitude: 46.5888,
    longitude: -100.8972,
    postal_code: '58566'
  },
  '58568': {
    country: 'US',
    city: 'Selfridge',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sioux',
    latitude: 46.0351,
    longitude: -101.1502,
    postal_code: '58568'
  },
  '58569': {
    country: 'US',
    city: 'Shields',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Grant',
    latitude: 46.1838,
    longitude: -101.2589,
    postal_code: '58569'
  },
  '58570': {
    country: 'US',
    city: 'Solen',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sioux',
    latitude: 46.3656,
    longitude: -100.7831,
    postal_code: '58570'
  },
  '58571': {
    country: 'US',
    city: 'Stanton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mercer',
    latitude: 47.3128,
    longitude: -101.3899,
    postal_code: '58571'
  },
  '58572': {
    country: 'US',
    city: 'Sterling',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burleigh',
    latitude: 46.8436,
    longitude: -100.2744,
    postal_code: '58572'
  },
  '58573': {
    country: 'US',
    city: 'Strasburg',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Emmons',
    latitude: 46.0979,
    longitude: -100.2119,
    postal_code: '58573'
  },
  '58575': {
    country: 'US',
    city: 'Turtle Lake',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.5414,
    longitude: -100.8814,
    postal_code: '58575'
  },
  '58576': {
    country: 'US',
    city: 'Underwood',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.4602,
    longitude: -101.1898,
    postal_code: '58576'
  },
  '58577': {
    country: 'US',
    city: 'Washburn',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.3114,
    longitude: -101.0116,
    postal_code: '58577'
  },
  '58579': {
    country: 'US',
    city: 'Wilton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.1709,
    longitude: -100.7944,
    postal_code: '58579'
  },
  '58580': {
    country: 'US',
    city: 'Zap',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mercer',
    latitude: 47.2897,
    longitude: -101.9256,
    postal_code: '58580'
  },
  '58581': {
    country: 'US',
    city: 'Zeeland',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McIntosh',
    latitude: 46.0055,
    longitude: -99.7725,
    postal_code: '58581'
  },
  '58601': {
    country: 'US',
    city: 'Dickinson',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stark',
    latitude: 46.8057,
    longitude: -102.7565,
    postal_code: '58601'
  },
  '58602': {
    country: 'US',
    city: 'Dickinson',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stark',
    latitude: 46.8792,
    longitude: -102.7896,
    postal_code: '58602'
  },
  '58620': {
    country: 'US',
    city: 'Amidon',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Slope',
    latitude: 46.456,
    longitude: -103.2647,
    postal_code: '58620'
  },
  '58621': {
    country: 'US',
    city: 'Beach',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Golden Valley',
    latitude: 46.9324,
    longitude: -103.9843,
    postal_code: '58621'
  },
  '58622': {
    country: 'US',
    city: 'Belfield',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stark',
    latitude: 46.8198,
    longitude: -103.1261,
    postal_code: '58622'
  },
  '58623': {
    country: 'US',
    city: 'Bowman',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bowman',
    latitude: 46.1733,
    longitude: -103.402,
    postal_code: '58623'
  },
  '58625': {
    country: 'US',
    city: 'Dodge',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Dunn',
    latitude: 47.3049,
    longitude: -102.1985,
    postal_code: '58625'
  },
  '58626': {
    country: 'US',
    city: 'Dunn Center',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Dunn',
    latitude: 47.3536,
    longitude: -102.6205,
    postal_code: '58626'
  },
  '58627': {
    country: 'US',
    city: 'Fairfield',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Billings',
    latitude: 47.2329,
    longitude: -103.3056,
    postal_code: '58627'
  },
  '58630': {
    country: 'US',
    city: 'Gladstone',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stark',
    latitude: 46.8152,
    longitude: -102.5274,
    postal_code: '58630'
  },
  '58631': {
    country: 'US',
    city: 'Glen Ullin',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Morton',
    latitude: 46.8233,
    longitude: -101.8223,
    postal_code: '58631'
  },
  '58632': {
    country: 'US',
    city: 'Golva',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Golden Valley',
    latitude: 46.7075,
    longitude: -103.9575,
    postal_code: '58632'
  },
  '58634': {
    country: 'US',
    city: 'Grassy Butte',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McKenzie',
    latitude: 47.4431,
    longitude: -103.2944,
    postal_code: '58634'
  },
  '58636': {
    country: 'US',
    city: 'Halliday',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Dunn',
    latitude: 47.3517,
    longitude: -102.3368,
    postal_code: '58636'
  },
  '58638': {
    country: 'US',
    city: 'Hebron',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Morton',
    latitude: 46.8936,
    longitude: -102.0367,
    postal_code: '58638'
  },
  '58639': {
    country: 'US',
    city: 'Hettinger',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Adams',
    latitude: 46.0578,
    longitude: -102.7851,
    postal_code: '58639'
  },
  '58640': {
    country: 'US',
    city: 'Killdeer',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Dunn',
    latitude: 47.4109,
    longitude: -102.7762,
    postal_code: '58640'
  },
  '58641': {
    country: 'US',
    city: 'Lefor',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stark',
    latitude: 46.6684,
    longitude: -102.4986,
    postal_code: '58641'
  },
  '58642': {
    country: 'US',
    city: 'Manning',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Dunn',
    latitude: 47.1258,
    longitude: -102.6806,
    postal_code: '58642'
  },
  '58643': {
    country: 'US',
    city: 'Marmarth',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Slope',
    latitude: 46.3254,
    longitude: -103.88,
    postal_code: '58643'
  },
  '58644': {
    country: 'US',
    city: 'Marshall',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Dunn',
    latitude: 47.1381,
    longitude: -102.333,
    postal_code: '58644'
  },
  '58645': {
    country: 'US',
    city: 'Medora',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Billings',
    latitude: 46.9387,
    longitude: -103.5632,
    postal_code: '58645'
  },
  '58646': {
    country: 'US',
    city: 'Mott',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Hettinger',
    latitude: 46.3696,
    longitude: -102.327,
    postal_code: '58646'
  },
  '58647': {
    country: 'US',
    city: 'New England',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Hettinger',
    latitude: 46.5128,
    longitude: -102.8358,
    postal_code: '58647'
  },
  '58649': {
    country: 'US',
    city: 'Reeder',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Adams',
    latitude: 46.1116,
    longitude: -102.9405,
    postal_code: '58649'
  },
  '58650': {
    country: 'US',
    city: 'Regent',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Hettinger',
    latitude: 46.4235,
    longitude: -102.5684,
    postal_code: '58650'
  },
  '58651': {
    country: 'US',
    city: 'Rhame',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bowman',
    latitude: 46.1664,
    longitude: -103.7079,
    postal_code: '58651'
  },
  '58652': {
    country: 'US',
    city: 'Richardton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stark',
    latitude: 46.8426,
    longitude: -102.292,
    postal_code: '58652'
  },
  '58653': {
    country: 'US',
    city: 'Scranton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bowman',
    latitude: 46.0916,
    longitude: -103.1354,
    postal_code: '58653'
  },
  '58654': {
    country: 'US',
    city: 'Sentinel Butte',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Golden Valley',
    latitude: 46.8305,
    longitude: -103.8001,
    postal_code: '58654'
  },
  '58655': {
    country: 'US',
    city: 'South Heart',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stark',
    latitude: 46.8127,
    longitude: -103.0162,
    postal_code: '58655'
  },
  '58656': {
    country: 'US',
    city: 'Taylor',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Stark',
    latitude: 46.9284,
    longitude: -102.3756,
    postal_code: '58656'
  },
  '58701': {
    country: 'US',
    city: 'Minot',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.2291,
    longitude: -101.2985,
    postal_code: '58701'
  },
  '58702': {
    country: 'US',
    city: 'Minot',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.2325,
    longitude: -101.2963,
    postal_code: '58702'
  },
  '58703': {
    country: 'US',
    city: 'Minot',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.3306,
    longitude: -101.3156,
    postal_code: '58703'
  },
  '58704': {
    country: 'US',
    city: 'Minot Afb',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.4232,
    longitude: -101.3168,
    postal_code: '58704'
  },
  '58705': {
    country: 'US',
    city: 'Minot Afb',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.4223,
    longitude: -101.3339,
    postal_code: '58705'
  },
  '58707': {
    country: 'US',
    city: 'Minot',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.2453,
    longitude: -101.3012,
    postal_code: '58707'
  },
  '58710': {
    country: 'US',
    city: 'Anamoose',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 47.8703,
    longitude: -100.2529,
    postal_code: '58710'
  },
  '58711': {
    country: 'US',
    city: 'Antler',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bottineau',
    latitude: 48.9585,
    longitude: -101.3338,
    postal_code: '58711'
  },
  '58712': {
    country: 'US',
    city: 'Balfour',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 47.9685,
    longitude: -100.5209,
    postal_code: '58712'
  },
  '58713': {
    country: 'US',
    city: 'Bantry',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 48.5351,
    longitude: -100.5954,
    postal_code: '58713'
  },
  '58716': {
    country: 'US',
    city: 'Benedict',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.7865,
    longitude: -101.0579,
    postal_code: '58716'
  },
  '58718': {
    country: 'US',
    city: 'Berthold',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.3064,
    longitude: -101.6866,
    postal_code: '58718'
  },
  '58721': {
    country: 'US',
    city: 'Bowbells',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burke',
    latitude: 48.8378,
    longitude: -102.2839,
    postal_code: '58721'
  },
  '58722': {
    country: 'US',
    city: 'Burlington',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.2735,
    longitude: -101.4282,
    postal_code: '58722'
  },
  '58723': {
    country: 'US',
    city: 'Butte',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.8119,
    longitude: -100.6604,
    postal_code: '58723'
  },
  '58725': {
    country: 'US',
    city: 'Carpio',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.4323,
    longitude: -101.7119,
    postal_code: '58725'
  },
  '58727': {
    country: 'US',
    city: 'Columbus',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burke',
    latitude: 48.8434,
    longitude: -102.798,
    postal_code: '58727'
  },
  '58730': {
    country: 'US',
    city: 'Crosby',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Divide',
    latitude: 48.8836,
    longitude: -103.274,
    postal_code: '58730'
  },
  '58731': {
    country: 'US',
    city: 'Deering',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 48.4057,
    longitude: -101.0337,
    postal_code: '58731'
  },
  '58733': {
    country: 'US',
    city: 'Des Lacs',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.2557,
    longitude: -101.5672,
    postal_code: '58733'
  },
  '58734': {
    country: 'US',
    city: 'Donnybrook',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.49,
    longitude: -101.896,
    postal_code: '58734'
  },
  '58735': {
    country: 'US',
    city: 'Douglas',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 47.8659,
    longitude: -101.5112,
    postal_code: '58735'
  },
  '58736': {
    country: 'US',
    city: 'Drake',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 47.9024,
    longitude: -100.379,
    postal_code: '58736'
  },
  '58737': {
    country: 'US',
    city: 'Flaxton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burke',
    latitude: 48.8772,
    longitude: -102.3823,
    postal_code: '58737'
  },
  '58740': {
    country: 'US',
    city: 'Glenburn',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Renville',
    latitude: 48.4718,
    longitude: -101.2297,
    postal_code: '58740'
  },
  '58741': {
    country: 'US',
    city: 'Granville',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 48.2566,
    longitude: -100.8082,
    postal_code: '58741'
  },
  '58744': {
    country: 'US',
    city: 'Karlsruhe',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 48.1009,
    longitude: -100.5742,
    postal_code: '58744'
  },
  '58746': {
    country: 'US',
    city: 'Kenmare',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.7182,
    longitude: -102.0852,
    postal_code: '58746'
  },
  '58748': {
    country: 'US',
    city: 'Kramer',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bottineau',
    latitude: 48.6867,
    longitude: -100.6716,
    postal_code: '58748'
  },
  '58750': {
    country: 'US',
    city: 'Lansford',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bottineau',
    latitude: 48.6254,
    longitude: -101.3858,
    postal_code: '58750'
  },
  '58752': {
    country: 'US',
    city: 'Lignite',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burke',
    latitude: 48.8481,
    longitude: -102.5542,
    postal_code: '58752'
  },
  '58755': {
    country: 'US',
    city: 'Mcgregor',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.595,
    longitude: -102.9288,
    postal_code: '58755'
  },
  '58756': {
    country: 'US',
    city: 'Makoti',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 47.9611,
    longitude: -101.8046,
    postal_code: '58756'
  },
  '58757': {
    country: 'US',
    city: 'Mandaree',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McKenzie',
    latitude: 47.7292,
    longitude: -102.676,
    postal_code: '58757'
  },
  '58758': {
    country: 'US',
    city: 'Martin',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Sheridan',
    latitude: 47.7781,
    longitude: -100.1225,
    postal_code: '58758'
  },
  '58759': {
    country: 'US',
    city: 'Max',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.8156,
    longitude: -101.2932,
    postal_code: '58759'
  },
  '58760': {
    country: 'US',
    city: 'Maxbass',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bottineau',
    latitude: 48.7722,
    longitude: -101.2563,
    postal_code: '58760'
  },
  '58761': {
    country: 'US',
    city: 'Mohall',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Renville',
    latitude: 48.7269,
    longitude: -101.6058,
    postal_code: '58761'
  },
  '58762': {
    country: 'US',
    city: 'Newburg',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bottineau',
    latitude: 48.6982,
    longitude: -100.9681,
    postal_code: '58762'
  },
  '58763': {
    country: 'US',
    city: 'New Town',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mountrail',
    latitude: 47.9718,
    longitude: -102.4716,
    postal_code: '58763'
  },
  '58765': {
    country: 'US',
    city: 'Noonan',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Divide',
    latitude: 48.8856,
    longitude: -103.0098,
    postal_code: '58765'
  },
  '58768': {
    country: 'US',
    city: 'Norwich',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 48.2491,
    longitude: -100.9712,
    postal_code: '58768'
  },
  '58769': {
    country: 'US',
    city: 'Palermo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mountrail',
    latitude: 48.3396,
    longitude: -102.24,
    postal_code: '58769'
  },
  '58770': {
    country: 'US',
    city: 'Parshall',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mountrail',
    latitude: 47.956,
    longitude: -102.1427,
    postal_code: '58770'
  },
  '58771': {
    country: 'US',
    city: 'Plaza',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mountrail',
    latitude: 48.0242,
    longitude: -101.9643,
    postal_code: '58771'
  },
  '58772': {
    country: 'US',
    city: 'Portal',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burke',
    latitude: 48.9758,
    longitude: -102.548,
    postal_code: '58772'
  },
  '58773': {
    country: 'US',
    city: 'Powers Lake',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Burke',
    latitude: 48.6551,
    longitude: -102.6795,
    postal_code: '58773'
  },
  '58775': {
    country: 'US',
    city: 'Roseglen',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.696,
    longitude: -101.822,
    postal_code: '58775'
  },
  '58776': {
    country: 'US',
    city: 'Ross',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mountrail',
    latitude: 48.3111,
    longitude: -102.5466,
    postal_code: '58776'
  },
  '58778': {
    country: 'US',
    city: 'Ruso',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McLean',
    latitude: 47.7622,
    longitude: -100.8754,
    postal_code: '58778'
  },
  '58779': {
    country: 'US',
    city: 'Ryder',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 47.9788,
    longitude: -101.6683,
    postal_code: '58779'
  },
  '58781': {
    country: 'US',
    city: 'Sawyer',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.0858,
    longitude: -101.0674,
    postal_code: '58781'
  },
  '58782': {
    country: 'US',
    city: 'Sherwood',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Renville',
    latitude: 48.9576,
    longitude: -101.697,
    postal_code: '58782'
  },
  '58783': {
    country: 'US',
    city: 'Souris',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bottineau',
    latitude: 48.8738,
    longitude: -100.7359,
    postal_code: '58783'
  },
  '58784': {
    country: 'US',
    city: 'Stanley',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mountrail',
    latitude: 48.3458,
    longitude: -102.4185,
    postal_code: '58784'
  },
  '58785': {
    country: 'US',
    city: 'Surrey',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Ward',
    latitude: 48.2365,
    longitude: -101.1216,
    postal_code: '58785'
  },
  '58787': {
    country: 'US',
    city: 'Tolley',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Renville',
    latitude: 48.7969,
    longitude: -101.8559,
    postal_code: '58787'
  },
  '58788': {
    country: 'US',
    city: 'Towner',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 48.3636,
    longitude: -100.4854,
    postal_code: '58788'
  },
  '58789': {
    country: 'US',
    city: 'Upham',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 48.5816,
    longitude: -100.7323,
    postal_code: '58789'
  },
  '58790': {
    country: 'US',
    city: 'Velva',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 48.0675,
    longitude: -100.9346,
    postal_code: '58790'
  },
  '58792': {
    country: 'US',
    city: 'Voltaire',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McHenry',
    latitude: 47.986,
    longitude: -100.7988,
    postal_code: '58792'
  },
  '58793': {
    country: 'US',
    city: 'Westhope',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Bottineau',
    latitude: 48.9051,
    longitude: -101.0338,
    postal_code: '58793'
  },
  '58794': {
    country: 'US',
    city: 'White Earth',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Mountrail',
    latitude: 48.2994,
    longitude: -102.7701,
    postal_code: '58794'
  },
  '58795': {
    country: 'US',
    city: 'Wildrose',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.5678,
    longitude: -103.1536,
    postal_code: '58795'
  },
  '58801': {
    country: 'US',
    city: 'Williston',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.2257,
    longitude: -103.649,
    postal_code: '58801'
  },
  '58802': {
    country: 'US',
    city: 'Williston',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.1688,
    longitude: -103.6148,
    postal_code: '58802'
  },
  '58803': {
    country: 'US',
    city: 'Williston',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.1468,
    longitude: -103.6199,
    postal_code: '58803'
  },
  '58830': {
    country: 'US',
    city: 'Alamo',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.5817,
    longitude: -103.4699,
    postal_code: '58830'
  },
  '58831': {
    country: 'US',
    city: 'Alexander',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McKenzie',
    latitude: 47.7947,
    longitude: -103.6546,
    postal_code: '58831'
  },
  '58833': {
    country: 'US',
    city: 'Ambrose',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Divide',
    latitude: 48.8165,
    longitude: -103.4673,
    postal_code: '58833'
  },
  '58835': {
    country: 'US',
    city: 'Arnegard',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McKenzie',
    latitude: 47.8088,
    longitude: -103.4538,
    postal_code: '58835'
  },
  '58838': {
    country: 'US',
    city: 'Cartwright',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McKenzie',
    latitude: 47.7992,
    longitude: -103.9487,
    postal_code: '58838'
  },
  '58843': {
    country: 'US',
    city: 'Epping',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.2764,
    longitude: -103.3763,
    postal_code: '58843'
  },
  '58844': {
    country: 'US',
    city: 'Fortuna',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Divide',
    latitude: 48.8813,
    longitude: -103.7415,
    postal_code: '58844'
  },
  '58845': {
    country: 'US',
    city: 'Grenora',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.5252,
    longitude: -103.9176,
    postal_code: '58845'
  },
  '58847': {
    country: 'US',
    city: 'Keene',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McKenzie',
    latitude: 47.9405,
    longitude: -102.8906,
    postal_code: '58847'
  },
  '58849': {
    country: 'US',
    city: 'Ray',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.3217,
    longitude: -103.1654,
    postal_code: '58849'
  },
  '58852': {
    country: 'US',
    city: 'Tioga',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.3646,
    longitude: -102.9576,
    postal_code: '58852'
  },
  '58853': {
    country: 'US',
    city: 'Trenton',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.0706,
    longitude: -103.8369,
    postal_code: '58853'
  },
  '58854': {
    country: 'US',
    city: 'Watford City',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'McKenzie',
    latitude: 47.8043,
    longitude: -103.2582,
    postal_code: '58854'
  },
  '58856': {
    country: 'US',
    city: 'Zahl',
    state: 'North Dakota',
    state_short: 'ND',
    county: 'Williams',
    latitude: 48.5787,
    longitude: -103.6599,
    postal_code: '58856'
  },
  '59001': {
    country: 'US',
    city: 'Absarokee',
    state: 'Montana',
    state_short: 'MT',
    county: 'Stillwater',
    latitude: 45.5154,
    longitude: -109.4692,
    postal_code: '59001'
  },
  '59002': {
    country: 'US',
    city: 'Acton',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.9291,
    longitude: -108.6888,
    postal_code: '59002'
  },
  '59003': {
    country: 'US',
    city: 'Ashland',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 45.5827,
    longitude: -106.2797,
    postal_code: '59003'
  },
  '59004': {
    country: 'US',
    city: 'Ashland',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 45.5944,
    longitude: -106.2708,
    postal_code: '59004'
  },
  '59006': {
    country: 'US',
    city: 'Ballantine',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.9547,
    longitude: -108.1231,
    postal_code: '59006'
  },
  '59007': {
    country: 'US',
    city: 'Bearcreek',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carbon',
    latitude: 45.1608,
    longitude: -109.1571,
    postal_code: '59007'
  },
  '59008': {
    country: 'US',
    city: 'Belfry',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carbon',
    latitude: 45.0498,
    longitude: -109.0788,
    postal_code: '59008'
  },
  '59010': {
    country: 'US',
    city: 'Bighorn',
    state: 'Montana',
    state_short: 'MT',
    county: 'Treasure',
    latitude: 45.997,
    longitude: -107.31,
    postal_code: '59010'
  },
  '59011': {
    country: 'US',
    city: 'Big Timber',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sweet Grass',
    latitude: 45.8283,
    longitude: -109.9631,
    postal_code: '59011'
  },
  '59012': {
    country: 'US',
    city: 'Birney',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 45.2862,
    longitude: -106.5095,
    postal_code: '59012'
  },
  '59013': {
    country: 'US',
    city: 'Boyd',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carbon',
    latitude: 45.4577,
    longitude: -109.0665,
    postal_code: '59013'
  },
  '59014': {
    country: 'US',
    city: 'Bridger',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carbon',
    latitude: 45.2857,
    longitude: -108.9082,
    postal_code: '59014'
  },
  '59015': {
    country: 'US',
    city: 'Broadview',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 46.0821,
    longitude: -108.8091,
    postal_code: '59015'
  },
  '59016': {
    country: 'US',
    city: 'Busby',
    state: 'Montana',
    state_short: 'MT',
    county: 'Big Horn',
    latitude: 45.5541,
    longitude: -106.8723,
    postal_code: '59016'
  },
  '59018': {
    country: 'US',
    city: 'Clyde Park',
    state: 'Montana',
    state_short: 'MT',
    county: 'Park',
    latitude: 45.8341,
    longitude: -110.6222,
    postal_code: '59018'
  },
  '59019': {
    country: 'US',
    city: 'Columbus',
    state: 'Montana',
    state_short: 'MT',
    county: 'Stillwater',
    latitude: 45.6262,
    longitude: -109.2571,
    postal_code: '59019'
  },
  '59020': {
    country: 'US',
    city: 'Cooke City',
    state: 'Montana',
    state_short: 'MT',
    county: 'Park',
    latitude: 45.0234,
    longitude: -109.907,
    postal_code: '59020'
  },
  '59022': {
    country: 'US',
    city: 'Crow Agency',
    state: 'Montana',
    state_short: 'MT',
    county: 'Big Horn',
    latitude: 45.6296,
    longitude: -107.4973,
    postal_code: '59022'
  },
  '59024': {
    country: 'US',
    city: 'Custer',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 46.1302,
    longitude: -107.5958,
    postal_code: '59024'
  },
  '59025': {
    country: 'US',
    city: 'Decker',
    state: 'Montana',
    state_short: 'MT',
    county: 'Big Horn',
    latitude: 45.1797,
    longitude: -106.8721,
    postal_code: '59025'
  },
  '59026': {
    country: 'US',
    city: 'Edgar',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carbon',
    latitude: 45.4647,
    longitude: -108.8524,
    postal_code: '59026'
  },
  '59027': {
    country: 'US',
    city: 'Emigrant',
    state: 'Montana',
    state_short: 'MT',
    county: 'Park',
    latitude: 45.2708,
    longitude: -110.7921,
    postal_code: '59027'
  },
  '59028': {
    country: 'US',
    city: 'Fishtail',
    state: 'Montana',
    state_short: 'MT',
    county: 'Stillwater',
    latitude: 45.4002,
    longitude: -109.5821,
    postal_code: '59028'
  },
  '59029': {
    country: 'US',
    city: 'Fromberg',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carbon',
    latitude: 45.4027,
    longitude: -108.9057,
    postal_code: '59029'
  },
  '59030': {
    country: 'US',
    city: 'Gardiner',
    state: 'Montana',
    state_short: 'MT',
    county: 'Park',
    latitude: 45.0849,
    longitude: -110.5715,
    postal_code: '59030'
  },
  '59031': {
    country: 'US',
    city: 'Garryowen',
    state: 'Montana',
    state_short: 'MT',
    county: 'Big Horn',
    latitude: 45.5187,
    longitude: -107.4817,
    postal_code: '59031'
  },
  '59032': {
    country: 'US',
    city: 'Grass Range',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fergus',
    latitude: 47.0259,
    longitude: -108.8271,
    postal_code: '59032'
  },
  '59033': {
    country: 'US',
    city: 'Greycliff',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sweet Grass',
    latitude: 45.7633,
    longitude: -109.7846,
    postal_code: '59033'
  },
  '59034': {
    country: 'US',
    city: 'Hardin',
    state: 'Montana',
    state_short: 'MT',
    county: 'Big Horn',
    latitude: 45.7498,
    longitude: -107.6075,
    postal_code: '59034'
  },
  '59035': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Montana',
    state_short: 'MT',
    county: 'Big Horn',
    latitude: 45.3127,
    longitude: -107.9371,
    postal_code: '59035'
  },
  '59036': {
    country: 'US',
    city: 'Harlowton',
    state: 'Montana',
    state_short: 'MT',
    county: 'Wheatland',
    latitude: 46.4477,
    longitude: -109.8435,
    postal_code: '59036'
  },
  '59037': {
    country: 'US',
    city: 'Huntley',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.8905,
    longitude: -108.285,
    postal_code: '59037'
  },
  '59038': {
    country: 'US',
    city: 'Hysham',
    state: 'Montana',
    state_short: 'MT',
    county: 'Treasure',
    latitude: 46.2765,
    longitude: -107.3072,
    postal_code: '59038'
  },
  '59039': {
    country: 'US',
    city: 'Ingomar',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 46.5767,
    longitude: -107.3723,
    postal_code: '59039'
  },
  '59041': {
    country: 'US',
    city: 'Joliet',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carbon',
    latitude: 45.4941,
    longitude: -108.9922,
    postal_code: '59041'
  },
  '59043': {
    country: 'US',
    city: 'Lame Deer',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 45.6032,
    longitude: -106.5654,
    postal_code: '59043'
  },
  '59044': {
    country: 'US',
    city: 'Laurel',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.6745,
    longitude: -108.769,
    postal_code: '59044'
  },
  '59046': {
    country: 'US',
    city: 'Lavina',
    state: 'Montana',
    state_short: 'MT',
    county: 'Golden Valley',
    latitude: 46.3291,
    longitude: -108.9959,
    postal_code: '59046'
  },
  '59047': {
    country: 'US',
    city: 'Livingston',
    state: 'Montana',
    state_short: 'MT',
    county: 'Park',
    latitude: 45.6546,
    longitude: -110.5609,
    postal_code: '59047'
  },
  '59050': {
    country: 'US',
    city: 'Lodge Grass',
    state: 'Montana',
    state_short: 'MT',
    county: 'Big Horn',
    latitude: 45.3166,
    longitude: -107.3675,
    postal_code: '59050'
  },
  '59052': {
    country: 'US',
    city: 'Mc Leod',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sweet Grass',
    latitude: 45.5963,
    longitude: -109.9357,
    postal_code: '59052'
  },
  '59053': {
    country: 'US',
    city: 'Martinsdale',
    state: 'Montana',
    state_short: 'MT',
    county: 'Meagher',
    latitude: 46.4581,
    longitude: -110.4324,
    postal_code: '59053'
  },
  '59054': {
    country: 'US',
    city: 'Melstone',
    state: 'Montana',
    state_short: 'MT',
    county: 'Musselshell',
    latitude: 46.5986,
    longitude: -107.8692,
    postal_code: '59054'
  },
  '59055': {
    country: 'US',
    city: 'Melville',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sweet Grass',
    latitude: 46.104,
    longitude: -109.7808,
    postal_code: '59055'
  },
  '59057': {
    country: 'US',
    city: 'Molt',
    state: 'Montana',
    state_short: 'MT',
    county: 'Stillwater',
    latitude: 45.8615,
    longitude: -108.9731,
    postal_code: '59057'
  },
  '59058': {
    country: 'US',
    city: 'Mosby',
    state: 'Montana',
    state_short: 'MT',
    county: 'Garfield',
    latitude: 46.9005,
    longitude: -107.7891,
    postal_code: '59058'
  },
  '59059': {
    country: 'US',
    city: 'Musselshell',
    state: 'Montana',
    state_short: 'MT',
    county: 'Musselshell',
    latitude: 46.5186,
    longitude: -108.0912,
    postal_code: '59059'
  },
  '59061': {
    country: 'US',
    city: 'Nye',
    state: 'Montana',
    state_short: 'MT',
    county: 'Stillwater',
    latitude: 45.441,
    longitude: -109.8271,
    postal_code: '59061'
  },
  '59062': {
    country: 'US',
    city: 'Otter',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powder River',
    latitude: 45.0903,
    longitude: -106.1499,
    postal_code: '59062'
  },
  '59063': {
    country: 'US',
    city: 'Park City',
    state: 'Montana',
    state_short: 'MT',
    county: 'Stillwater',
    latitude: 45.6329,
    longitude: -108.9293,
    postal_code: '59063'
  },
  '59064': {
    country: 'US',
    city: 'Pompeys Pillar',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.9838,
    longitude: -107.9154,
    postal_code: '59064'
  },
  '59065': {
    country: 'US',
    city: 'Pray',
    state: 'Montana',
    state_short: 'MT',
    county: 'Park',
    latitude: 45.3331,
    longitude: -110.7137,
    postal_code: '59065'
  },
  '59066': {
    country: 'US',
    city: 'Pryor',
    state: 'Montana',
    state_short: 'MT',
    county: 'Big Horn',
    latitude: 45.4297,
    longitude: -108.5332,
    postal_code: '59066'
  },
  '59067': {
    country: 'US',
    city: 'Rapelje',
    state: 'Montana',
    state_short: 'MT',
    county: 'Stillwater',
    latitude: 45.9795,
    longitude: -109.2761,
    postal_code: '59067'
  },
  '59068': {
    country: 'US',
    city: 'Red Lodge',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carbon',
    latitude: 45.1965,
    longitude: -109.2688,
    postal_code: '59068'
  },
  '59069': {
    country: 'US',
    city: 'Reed Point',
    state: 'Montana',
    state_short: 'MT',
    county: 'Stillwater',
    latitude: 45.7439,
    longitude: -109.5224,
    postal_code: '59069'
  },
  '59070': {
    country: 'US',
    city: 'Roberts',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carbon',
    latitude: 45.3672,
    longitude: -109.1769,
    postal_code: '59070'
  },
  '59071': {
    country: 'US',
    city: 'Roscoe',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carbon',
    latitude: 45.268,
    longitude: -109.5658,
    postal_code: '59071'
  },
  '59072': {
    country: 'US',
    city: 'Roundup',
    state: 'Montana',
    state_short: 'MT',
    county: 'Musselshell',
    latitude: 46.4225,
    longitude: -108.5438,
    postal_code: '59072'
  },
  '59073': {
    country: 'US',
    city: 'Roundup',
    state: 'Montana',
    state_short: 'MT',
    county: 'Musselshell',
    latitude: 46.4452,
    longitude: -108.5418,
    postal_code: '59073'
  },
  '59074': {
    country: 'US',
    city: 'Ryegate',
    state: 'Montana',
    state_short: 'MT',
    county: 'Golden Valley',
    latitude: 46.2724,
    longitude: -109.2761,
    postal_code: '59074'
  },
  '59075': {
    country: 'US',
    city: 'Saint Xavier',
    state: 'Montana',
    state_short: 'MT',
    county: 'Big Horn',
    latitude: 45.3985,
    longitude: -107.8893,
    postal_code: '59075'
  },
  '59076': {
    country: 'US',
    city: 'Sanders',
    state: 'Montana',
    state_short: 'MT',
    county: 'Treasure',
    latitude: 46.2914,
    longitude: -107.0967,
    postal_code: '59076'
  },
  '59077': {
    country: 'US',
    city: 'Sand Springs',
    state: 'Montana',
    state_short: 'MT',
    county: 'Garfield',
    latitude: 47.1008,
    longitude: -107.4856,
    postal_code: '59077'
  },
  '59078': {
    country: 'US',
    city: 'Shawmut',
    state: 'Montana',
    state_short: 'MT',
    county: 'Wheatland',
    latitude: 46.3869,
    longitude: -109.5974,
    postal_code: '59078'
  },
  '59079': {
    country: 'US',
    city: 'Shepherd',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.9461,
    longitude: -108.3426,
    postal_code: '59079'
  },
  '59081': {
    country: 'US',
    city: 'Silver Gate',
    state: 'Montana',
    state_short: 'MT',
    county: 'Park',
    latitude: 45.0046,
    longitude: -109.9854,
    postal_code: '59081'
  },
  '59082': {
    country: 'US',
    city: 'Springdale',
    state: 'Montana',
    state_short: 'MT',
    county: 'Park',
    latitude: 45.7383,
    longitude: -110.2271,
    postal_code: '59082'
  },
  '59083': {
    country: 'US',
    city: 'Sumatra',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 46.6183,
    longitude: -107.5512,
    postal_code: '59083'
  },
  '59084': {
    country: 'US',
    city: 'Teigen',
    state: 'Montana',
    state_short: 'MT',
    county: 'Petroleum',
    latitude: 47.0366,
    longitude: -108.5968,
    postal_code: '59084'
  },
  '59085': {
    country: 'US',
    city: 'Two Dot',
    state: 'Montana',
    state_short: 'MT',
    county: 'Wheatland',
    latitude: 46.4242,
    longitude: -110.0712,
    postal_code: '59085'
  },
  '59086': {
    country: 'US',
    city: 'Wilsall',
    state: 'Montana',
    state_short: 'MT',
    county: 'Park',
    latitude: 45.9484,
    longitude: -110.6061,
    postal_code: '59086'
  },
  '59087': {
    country: 'US',
    city: 'Winnett',
    state: 'Montana',
    state_short: 'MT',
    county: 'Petroleum',
    latitude: 46.9438,
    longitude: -108.3184,
    postal_code: '59087'
  },
  '59088': {
    country: 'US',
    city: 'Worden',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.9779,
    longitude: -108.1533,
    postal_code: '59088'
  },
  '59089': {
    country: 'US',
    city: 'Wyola',
    state: 'Montana',
    state_short: 'MT',
    county: 'Big Horn',
    latitude: 45.1089,
    longitude: -107.4303,
    postal_code: '59089'
  },
  '59101': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7745,
    longitude: -108.5005,
    postal_code: '59101'
  },
  '59102': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7813,
    longitude: -108.5727,
    postal_code: '59102'
  },
  '59103': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7833,
    longitude: -108.5007,
    postal_code: '59103'
  },
  '59104': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7833,
    longitude: -108.5007,
    postal_code: '59104'
  },
  '59105': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.9497,
    longitude: -108.599,
    postal_code: '59105'
  },
  '59106': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7753,
    longitude: -108.6519,
    postal_code: '59106'
  },
  '59107': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.8252,
    longitude: -108.3934,
    postal_code: '59107'
  },
  '59108': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7833,
    longitude: -108.5007,
    postal_code: '59108'
  },
  '59111': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7833,
    longitude: -108.5007,
    postal_code: '59111'
  },
  '59112': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7833,
    longitude: -108.5007,
    postal_code: '59112'
  },
  '59114': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7833,
    longitude: -108.5007,
    postal_code: '59114'
  },
  '59115': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7833,
    longitude: -108.5007,
    postal_code: '59115'
  },
  '59116': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7833,
    longitude: -108.5007,
    postal_code: '59116'
  },
  '59117': {
    country: 'US',
    city: 'Billings',
    state: 'Montana',
    state_short: 'MT',
    county: 'Yellowstone',
    latitude: 45.7833,
    longitude: -108.5007,
    postal_code: '59117'
  },
  '59201': {
    country: 'US',
    city: 'Wolf Point',
    state: 'Montana',
    state_short: 'MT',
    county: 'Roosevelt',
    latitude: 48.1119,
    longitude: -105.6293,
    postal_code: '59201'
  },
  '59211': {
    country: 'US',
    city: 'Antelope',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sheridan',
    latitude: 48.6968,
    longitude: -104.4529,
    postal_code: '59211'
  },
  '59212': {
    country: 'US',
    city: 'Bainville',
    state: 'Montana',
    state_short: 'MT',
    county: 'Roosevelt',
    latitude: 48.158,
    longitude: -104.1995,
    postal_code: '59212'
  },
  '59213': {
    country: 'US',
    city: 'Brockton',
    state: 'Montana',
    state_short: 'MT',
    county: 'Roosevelt',
    latitude: 48.2101,
    longitude: -104.8548,
    postal_code: '59213'
  },
  '59214': {
    country: 'US',
    city: 'Brockway',
    state: 'Montana',
    state_short: 'MT',
    county: 'McCone',
    latitude: 47.2485,
    longitude: -105.7776,
    postal_code: '59214'
  },
  '59215': {
    country: 'US',
    city: 'Circle',
    state: 'Montana',
    state_short: 'MT',
    county: 'McCone',
    latitude: 47.4264,
    longitude: -105.6148,
    postal_code: '59215'
  },
  '59217': {
    country: 'US',
    city: 'Crane',
    state: 'Montana',
    state_short: 'MT',
    county: 'Richland',
    latitude: 47.5758,
    longitude: -104.2624,
    postal_code: '59217'
  },
  '59218': {
    country: 'US',
    city: 'Culbertson',
    state: 'Montana',
    state_short: 'MT',
    county: 'Roosevelt',
    latitude: 48.1495,
    longitude: -104.5132,
    postal_code: '59218'
  },
  '59219': {
    country: 'US',
    city: 'Dagmar',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sheridan',
    latitude: 48.6093,
    longitude: -104.2401,
    postal_code: '59219'
  },
  '59221': {
    country: 'US',
    city: 'Fairview',
    state: 'Montana',
    state_short: 'MT',
    county: 'Richland',
    latitude: 47.8916,
    longitude: -104.2302,
    postal_code: '59221'
  },
  '59222': {
    country: 'US',
    city: 'Flaxville',
    state: 'Montana',
    state_short: 'MT',
    county: 'Daniels',
    latitude: 48.7472,
    longitude: -105.1637,
    postal_code: '59222'
  },
  '59223': {
    country: 'US',
    city: 'Fort Peck',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.0089,
    longitude: -106.4486,
    postal_code: '59223'
  },
  '59225': {
    country: 'US',
    city: 'Frazer',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.2959,
    longitude: -106.0324,
    postal_code: '59225'
  },
  '59226': {
    country: 'US',
    city: 'Froid',
    state: 'Montana',
    state_short: 'MT',
    county: 'Roosevelt',
    latitude: 48.3345,
    longitude: -104.4961,
    postal_code: '59226'
  },
  '59230': {
    country: 'US',
    city: 'Glasgow',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.2034,
    longitude: -106.6094,
    postal_code: '59230'
  },
  '59231': {
    country: 'US',
    city: 'Saint Marie',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.4039,
    longitude: -106.5428,
    postal_code: '59231'
  },
  '59240': {
    country: 'US',
    city: 'Glentana',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.8481,
    longitude: -106.2495,
    postal_code: '59240'
  },
  '59241': {
    country: 'US',
    city: 'Hinsdale',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.4007,
    longitude: -107.0098,
    postal_code: '59241'
  },
  '59242': {
    country: 'US',
    city: 'Homestead',
    state: 'Montana',
    state_short: 'MT',
    county: 'Roosevelt',
    latitude: 48.435,
    longitude: -104.4389,
    postal_code: '59242'
  },
  '59243': {
    country: 'US',
    city: 'Lambert',
    state: 'Montana',
    state_short: 'MT',
    county: 'Richland',
    latitude: 47.7459,
    longitude: -104.5987,
    postal_code: '59243'
  },
  '59244': {
    country: 'US',
    city: 'Larslan',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.5822,
    longitude: -106.2835,
    postal_code: '59244'
  },
  '59247': {
    country: 'US',
    city: 'Medicine Lake',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sheridan',
    latitude: 48.4852,
    longitude: -104.4375,
    postal_code: '59247'
  },
  '59248': {
    country: 'US',
    city: 'Nashua',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.1322,
    longitude: -106.3564,
    postal_code: '59248'
  },
  '59250': {
    country: 'US',
    city: 'Opheim',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.8702,
    longitude: -106.3658,
    postal_code: '59250'
  },
  '59252': {
    country: 'US',
    city: 'Outlook',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sheridan',
    latitude: 48.8817,
    longitude: -104.7415,
    postal_code: '59252'
  },
  '59253': {
    country: 'US',
    city: 'Peerless',
    state: 'Montana',
    state_short: 'MT',
    county: 'Daniels',
    latitude: 48.7808,
    longitude: -105.8006,
    postal_code: '59253'
  },
  '59254': {
    country: 'US',
    city: 'Plentywood',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sheridan',
    latitude: 48.7788,
    longitude: -104.56,
    postal_code: '59254'
  },
  '59255': {
    country: 'US',
    city: 'Poplar',
    state: 'Montana',
    state_short: 'MT',
    county: 'Roosevelt',
    latitude: 48.1307,
    longitude: -105.187,
    postal_code: '59255'
  },
  '59256': {
    country: 'US',
    city: 'Raymond',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sheridan',
    latitude: 48.8764,
    longitude: -104.5802,
    postal_code: '59256'
  },
  '59257': {
    country: 'US',
    city: 'Redstone',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sheridan',
    latitude: 48.728,
    longitude: -104.9402,
    postal_code: '59257'
  },
  '59258': {
    country: 'US',
    city: 'Reserve',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sheridan',
    latitude: 48.5905,
    longitude: -104.6279,
    postal_code: '59258'
  },
  '59259': {
    country: 'US',
    city: 'Richey',
    state: 'Montana',
    state_short: 'MT',
    county: 'Dawson',
    latitude: 47.6229,
    longitude: -105.017,
    postal_code: '59259'
  },
  '59260': {
    country: 'US',
    city: 'Richland',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.6507,
    longitude: -106.1192,
    postal_code: '59260'
  },
  '59261': {
    country: 'US',
    city: 'Saco',
    state: 'Montana',
    state_short: 'MT',
    county: 'Phillips',
    latitude: 48.6389,
    longitude: -107.4293,
    postal_code: '59261'
  },
  '59262': {
    country: 'US',
    city: 'Savage',
    state: 'Montana',
    state_short: 'MT',
    county: 'Richland',
    latitude: 47.5194,
    longitude: -104.2845,
    postal_code: '59262'
  },
  '59263': {
    country: 'US',
    city: 'Scobey',
    state: 'Montana',
    state_short: 'MT',
    county: 'Daniels',
    latitude: 48.7854,
    longitude: -105.417,
    postal_code: '59263'
  },
  '59270': {
    country: 'US',
    city: 'Sidney',
    state: 'Montana',
    state_short: 'MT',
    county: 'Richland',
    latitude: 47.713,
    longitude: -104.1634,
    postal_code: '59270'
  },
  '59273': {
    country: 'US',
    city: 'Vandalia',
    state: 'Montana',
    state_short: 'MT',
    county: 'Valley',
    latitude: 48.3547,
    longitude: -106.9098,
    postal_code: '59273'
  },
  '59274': {
    country: 'US',
    city: 'Vida',
    state: 'Montana',
    state_short: 'MT',
    county: 'McCone',
    latitude: 47.832,
    longitude: -105.493,
    postal_code: '59274'
  },
  '59275': {
    country: 'US',
    city: 'Westby',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sheridan',
    latitude: 48.8587,
    longitude: -104.1247,
    postal_code: '59275'
  },
  '59276': {
    country: 'US',
    city: 'Whitetail',
    state: 'Montana',
    state_short: 'MT',
    county: 'Daniels',
    latitude: 48.8956,
    longitude: -105.1617,
    postal_code: '59276'
  },
  '59301': {
    country: 'US',
    city: 'Miles City',
    state: 'Montana',
    state_short: 'MT',
    county: 'Custer',
    latitude: 46.4075,
    longitude: -105.8332,
    postal_code: '59301'
  },
  '59311': {
    country: 'US',
    city: 'Alzada',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carter',
    latitude: 45.1562,
    longitude: -104.2617,
    postal_code: '59311'
  },
  '59312': {
    country: 'US',
    city: 'Angela',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 46.73,
    longitude: -106.2011,
    postal_code: '59312'
  },
  '59313': {
    country: 'US',
    city: 'Baker',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fallon',
    latitude: 46.3552,
    longitude: -104.2667,
    postal_code: '59313'
  },
  '59314': {
    country: 'US',
    city: 'Biddle',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powder River',
    latitude: 45.0994,
    longitude: -105.3383,
    postal_code: '59314'
  },
  '59315': {
    country: 'US',
    city: 'Bloomfield',
    state: 'Montana',
    state_short: 'MT',
    county: 'Dawson',
    latitude: 47.41,
    longitude: -104.9596,
    postal_code: '59315'
  },
  '59316': {
    country: 'US',
    city: 'Boyes',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carter',
    latitude: 45.268,
    longitude: -105.0311,
    postal_code: '59316'
  },
  '59317': {
    country: 'US',
    city: 'Broadus',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powder River',
    latitude: 45.2572,
    longitude: -105.2568,
    postal_code: '59317'
  },
  '59318': {
    country: 'US',
    city: 'Brusett',
    state: 'Montana',
    state_short: 'MT',
    county: 'Garfield',
    latitude: 47.4253,
    longitude: -107.2668,
    postal_code: '59318'
  },
  '59319': {
    country: 'US',
    city: 'Capitol',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carter',
    latitude: 45.4361,
    longitude: -104.0655,
    postal_code: '59319'
  },
  '59322': {
    country: 'US',
    city: 'Cohagen',
    state: 'Montana',
    state_short: 'MT',
    county: 'Garfield',
    latitude: 47.1263,
    longitude: -106.4981,
    postal_code: '59322'
  },
  '59323': {
    country: 'US',
    city: 'Colstrip',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 45.9344,
    longitude: -106.6368,
    postal_code: '59323'
  },
  '59324': {
    country: 'US',
    city: 'Ekalaka',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carter',
    latitude: 45.8805,
    longitude: -104.504,
    postal_code: '59324'
  },
  '59326': {
    country: 'US',
    city: 'Fallon',
    state: 'Montana',
    state_short: 'MT',
    county: 'Prairie',
    latitude: 46.7866,
    longitude: -105.1161,
    postal_code: '59326'
  },
  '59327': {
    country: 'US',
    city: 'Forsyth',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 46.2819,
    longitude: -106.6991,
    postal_code: '59327'
  },
  '59330': {
    country: 'US',
    city: 'Glendive',
    state: 'Montana',
    state_short: 'MT',
    county: 'Dawson',
    latitude: 47.1008,
    longitude: -104.7287,
    postal_code: '59330'
  },
  '59332': {
    country: 'US',
    city: 'Hammond',
    state: 'Montana',
    state_short: 'MT',
    county: 'Carter',
    latitude: 45.5671,
    longitude: -104.5391,
    postal_code: '59332'
  },
  '59333': {
    country: 'US',
    city: 'Hathaway',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 46.2761,
    longitude: -106.1967,
    postal_code: '59333'
  },
  '59336': {
    country: 'US',
    city: 'Ismay',
    state: 'Montana',
    state_short: 'MT',
    county: 'Custer',
    latitude: 46.4132,
    longitude: -105.2091,
    postal_code: '59336'
  },
  '59337': {
    country: 'US',
    city: 'Jordan',
    state: 'Montana',
    state_short: 'MT',
    county: 'Garfield',
    latitude: 47.3208,
    longitude: -106.9101,
    postal_code: '59337'
  },
  '59338': {
    country: 'US',
    city: 'Kinsey',
    state: 'Montana',
    state_short: 'MT',
    county: 'Custer',
    latitude: 46.5708,
    longitude: -105.6569,
    postal_code: '59338'
  },
  '59339': {
    country: 'US',
    city: 'Lindsay',
    state: 'Montana',
    state_short: 'MT',
    county: 'Dawson',
    latitude: 47.2024,
    longitude: -105.2089,
    postal_code: '59339'
  },
  '59341': {
    country: 'US',
    city: 'Mildred',
    state: 'Montana',
    state_short: 'MT',
    county: 'Prairie',
    latitude: 46.6758,
    longitude: -104.96,
    postal_code: '59341'
  },
  '59343': {
    country: 'US',
    city: 'Olive',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powder River',
    latitude: 45.5506,
    longitude: -105.528,
    postal_code: '59343'
  },
  '59344': {
    country: 'US',
    city: 'Plevna',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fallon',
    latitude: 46.4112,
    longitude: -104.5713,
    postal_code: '59344'
  },
  '59345': {
    country: 'US',
    city: 'Powderville',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powder River',
    latitude: 45.7589,
    longitude: -105.1153,
    postal_code: '59345'
  },
  '59347': {
    country: 'US',
    city: 'Rosebud',
    state: 'Montana',
    state_short: 'MT',
    county: 'Rosebud',
    latitude: 46.4352,
    longitude: -106.352,
    postal_code: '59347'
  },
  '59349': {
    country: 'US',
    city: 'Terry',
    state: 'Montana',
    state_short: 'MT',
    county: 'Prairie',
    latitude: 46.829,
    longitude: -105.3706,
    postal_code: '59349'
  },
  '59351': {
    country: 'US',
    city: 'Volborg',
    state: 'Montana',
    state_short: 'MT',
    county: 'Custer',
    latitude: 45.8428,
    longitude: -105.6811,
    postal_code: '59351'
  },
  '59353': {
    country: 'US',
    city: 'Wibaux',
    state: 'Montana',
    state_short: 'MT',
    county: 'Wibaux',
    latitude: 46.9646,
    longitude: -104.1897,
    postal_code: '59353'
  },
  '59354': {
    country: 'US',
    city: 'Willard',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fallon',
    latitude: 46.1939,
    longitude: -104.3699,
    postal_code: '59354'
  },
  '59401': {
    country: 'US',
    city: 'Great Falls',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.5098,
    longitude: -111.2734,
    postal_code: '59401'
  },
  '59402': {
    country: 'US',
    city: 'Malmstrom Afb',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.5002,
    longitude: -111.3008,
    postal_code: '59402'
  },
  '59403': {
    country: 'US',
    city: 'Great Falls',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.5002,
    longitude: -111.3008,
    postal_code: '59403'
  },
  '59404': {
    country: 'US',
    city: 'Great Falls',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.5098,
    longitude: -111.3405,
    postal_code: '59404'
  },
  '59405': {
    country: 'US',
    city: 'Great Falls',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.495,
    longitude: -111.2502,
    postal_code: '59405'
  },
  '59406': {
    country: 'US',
    city: 'Great Falls',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.62,
    longitude: -111.2393,
    postal_code: '59406'
  },
  '59410': {
    country: 'US',
    city: 'Augusta',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 47.4537,
    longitude: -112.3883,
    postal_code: '59410'
  },
  '59411': {
    country: 'US',
    city: 'Babb',
    state: 'Montana',
    state_short: 'MT',
    county: 'Glacier',
    latitude: 48.8788,
    longitude: -113.3681,
    postal_code: '59411'
  },
  '59412': {
    country: 'US',
    city: 'Belt',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.3821,
    longitude: -110.9081,
    postal_code: '59412'
  },
  '59414': {
    country: 'US',
    city: 'Black Eagle',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.5262,
    longitude: -111.2764,
    postal_code: '59414'
  },
  '59416': {
    country: 'US',
    city: 'Brady',
    state: 'Montana',
    state_short: 'MT',
    county: 'Pondera',
    latitude: 48.0312,
    longitude: -111.755,
    postal_code: '59416'
  },
  '59417': {
    country: 'US',
    city: 'Browning',
    state: 'Montana',
    state_short: 'MT',
    county: 'Glacier',
    latitude: 48.5569,
    longitude: -113.0134,
    postal_code: '59417'
  },
  '59418': {
    country: 'US',
    city: 'Buffalo',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fergus',
    latitude: 46.8211,
    longitude: -109.8285,
    postal_code: '59418'
  },
  '59419': {
    country: 'US',
    city: 'Bynum',
    state: 'Montana',
    state_short: 'MT',
    county: 'Teton',
    latitude: 47.99,
    longitude: -112.2762,
    postal_code: '59419'
  },
  '59420': {
    country: 'US',
    city: 'Carter',
    state: 'Montana',
    state_short: 'MT',
    county: 'Chouteau',
    latitude: 47.781,
    longitude: -110.9786,
    postal_code: '59420'
  },
  '59421': {
    country: 'US',
    city: 'Cascade',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.2912,
    longitude: -111.7223,
    postal_code: '59421'
  },
  '59422': {
    country: 'US',
    city: 'Choteau',
    state: 'Montana',
    state_short: 'MT',
    county: 'Teton',
    latitude: 47.838,
    longitude: -112.2021,
    postal_code: '59422'
  },
  '59424': {
    country: 'US',
    city: 'Coffee Creek',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fergus',
    latitude: 47.3467,
    longitude: -110.0908,
    postal_code: '59424'
  },
  '59425': {
    country: 'US',
    city: 'Conrad',
    state: 'Montana',
    state_short: 'MT',
    county: 'Pondera',
    latitude: 48.1783,
    longitude: -111.9397,
    postal_code: '59425'
  },
  '59427': {
    country: 'US',
    city: 'Cut Bank',
    state: 'Montana',
    state_short: 'MT',
    county: 'Glacier',
    latitude: 48.6603,
    longitude: -112.3654,
    postal_code: '59427'
  },
  '59430': {
    country: 'US',
    city: 'Denton',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fergus',
    latitude: 47.3191,
    longitude: -109.8789,
    postal_code: '59430'
  },
  '59432': {
    country: 'US',
    city: 'Dupuyer',
    state: 'Montana',
    state_short: 'MT',
    county: 'Pondera',
    latitude: 48.1925,
    longitude: -112.4995,
    postal_code: '59432'
  },
  '59433': {
    country: 'US',
    city: 'Dutton',
    state: 'Montana',
    state_short: 'MT',
    county: 'Teton',
    latitude: 47.8601,
    longitude: -111.69,
    postal_code: '59433'
  },
  '59434': {
    country: 'US',
    city: 'East Glacier Park',
    state: 'Montana',
    state_short: 'MT',
    county: 'Glacier',
    latitude: 48.4457,
    longitude: -113.219,
    postal_code: '59434'
  },
  '59435': {
    country: 'US',
    city: 'Ethridge',
    state: 'Montana',
    state_short: 'MT',
    county: 'Toole',
    latitude: 48.5578,
    longitude: -112.1206,
    postal_code: '59435'
  },
  '59436': {
    country: 'US',
    city: 'Fairfield',
    state: 'Montana',
    state_short: 'MT',
    county: 'Teton',
    latitude: 47.6143,
    longitude: -112.0015,
    postal_code: '59436'
  },
  '59440': {
    country: 'US',
    city: 'Floweree',
    state: 'Montana',
    state_short: 'MT',
    county: 'Chouteau',
    latitude: 47.6584,
    longitude: -111.1214,
    postal_code: '59440'
  },
  '59441': {
    country: 'US',
    city: 'Forest Grove',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fergus',
    latitude: 46.9914,
    longitude: -109.0802,
    postal_code: '59441'
  },
  '59442': {
    country: 'US',
    city: 'Fort Benton',
    state: 'Montana',
    state_short: 'MT',
    county: 'Chouteau',
    latitude: 47.924,
    longitude: -110.6052,
    postal_code: '59442'
  },
  '59443': {
    country: 'US',
    city: 'Fort Shaw',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.5055,
    longitude: -111.8103,
    postal_code: '59443'
  },
  '59444': {
    country: 'US',
    city: 'Galata',
    state: 'Montana',
    state_short: 'MT',
    county: 'Toole',
    latitude: 48.8111,
    longitude: -111.4704,
    postal_code: '59444'
  },
  '59446': {
    country: 'US',
    city: 'Geraldine',
    state: 'Montana',
    state_short: 'MT',
    county: 'Chouteau',
    latitude: 47.6024,
    longitude: -110.2765,
    postal_code: '59446'
  },
  '59447': {
    country: 'US',
    city: 'Geyser',
    state: 'Montana',
    state_short: 'MT',
    county: 'Judith Basin',
    latitude: 47.2598,
    longitude: -110.4839,
    postal_code: '59447'
  },
  '59448': {
    country: 'US',
    city: 'Heart Butte',
    state: 'Montana',
    state_short: 'MT',
    county: 'Pondera',
    latitude: 48.2777,
    longitude: -112.8456,
    postal_code: '59448'
  },
  '59450': {
    country: 'US',
    city: 'Highwood',
    state: 'Montana',
    state_short: 'MT',
    county: 'Chouteau',
    latitude: 47.5816,
    longitude: -110.7887,
    postal_code: '59450'
  },
  '59451': {
    country: 'US',
    city: 'Hilger',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fergus',
    latitude: 47.2539,
    longitude: -109.3599,
    postal_code: '59451'
  },
  '59452': {
    country: 'US',
    city: 'Hobson',
    state: 'Montana',
    state_short: 'MT',
    county: 'Judith Basin',
    latitude: 46.9968,
    longitude: -109.8756,
    postal_code: '59452'
  },
  '59453': {
    country: 'US',
    city: 'Judith Gap',
    state: 'Montana',
    state_short: 'MT',
    county: 'Wheatland',
    latitude: 46.6623,
    longitude: -109.6755,
    postal_code: '59453'
  },
  '59454': {
    country: 'US',
    city: 'Kevin',
    state: 'Montana',
    state_short: 'MT',
    county: 'Toole',
    latitude: 48.7508,
    longitude: -111.9708,
    postal_code: '59454'
  },
  '59456': {
    country: 'US',
    city: 'Ledger',
    state: 'Montana',
    state_short: 'MT',
    county: 'Pondera',
    latitude: 48.2643,
    longitude: -111.3886,
    postal_code: '59456'
  },
  '59457': {
    country: 'US',
    city: 'Lewistown',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fergus',
    latitude: 47.0563,
    longitude: -109.4203,
    postal_code: '59457'
  },
  '59460': {
    country: 'US',
    city: 'Loma',
    state: 'Montana',
    state_short: 'MT',
    county: 'Chouteau',
    latitude: 47.9546,
    longitude: -110.4995,
    postal_code: '59460'
  },
  '59461': {
    country: 'US',
    city: 'Lothair',
    state: 'Montana',
    state_short: 'MT',
    county: 'Toole',
    latitude: 48.4717,
    longitude: -111.2322,
    postal_code: '59461'
  },
  '59462': {
    country: 'US',
    city: 'Moccasin',
    state: 'Montana',
    state_short: 'MT',
    county: 'Judith Basin',
    latitude: 47.0916,
    longitude: -109.8901,
    postal_code: '59462'
  },
  '59463': {
    country: 'US',
    city: 'Monarch',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.0722,
    longitude: -110.871,
    postal_code: '59463'
  },
  '59464': {
    country: 'US',
    city: 'Moore',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fergus',
    latitude: 46.9755,
    longitude: -109.6969,
    postal_code: '59464'
  },
  '59465': {
    country: 'US',
    city: 'Neihart',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 46.9391,
    longitude: -110.7328,
    postal_code: '59465'
  },
  '59466': {
    country: 'US',
    city: 'Oilmont',
    state: 'Montana',
    state_short: 'MT',
    county: 'Toole',
    latitude: 48.74,
    longitude: -111.8409,
    postal_code: '59466'
  },
  '59467': {
    country: 'US',
    city: 'Pendroy',
    state: 'Montana',
    state_short: 'MT',
    county: 'Teton',
    latitude: 48.0879,
    longitude: -112.3261,
    postal_code: '59467'
  },
  '59468': {
    country: 'US',
    city: 'Power',
    state: 'Montana',
    state_short: 'MT',
    county: 'Teton',
    latitude: 47.6798,
    longitude: -111.7169,
    postal_code: '59468'
  },
  '59469': {
    country: 'US',
    city: 'Raynesford',
    state: 'Montana',
    state_short: 'MT',
    county: 'Judith Basin',
    latitude: 47.2604,
    longitude: -110.7047,
    postal_code: '59469'
  },
  '59471': {
    country: 'US',
    city: 'Roy',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fergus',
    latitude: 47.3678,
    longitude: -108.8634,
    postal_code: '59471'
  },
  '59472': {
    country: 'US',
    city: 'Sand Coulee',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.4021,
    longitude: -111.1661,
    postal_code: '59472'
  },
  '59474': {
    country: 'US',
    city: 'Shelby',
    state: 'Montana',
    state_short: 'MT',
    county: 'Toole',
    latitude: 48.5037,
    longitude: -111.8391,
    postal_code: '59474'
  },
  '59477': {
    country: 'US',
    city: 'Simms',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.4958,
    longitude: -111.8861,
    postal_code: '59477'
  },
  '59479': {
    country: 'US',
    city: 'Stanford',
    state: 'Montana',
    state_short: 'MT',
    county: 'Judith Basin',
    latitude: 47.1489,
    longitude: -110.1961,
    postal_code: '59479'
  },
  '59480': {
    country: 'US',
    city: 'Stockett',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.3217,
    longitude: -111.1287,
    postal_code: '59480'
  },
  '59482': {
    country: 'US',
    city: 'Sunburst',
    state: 'Montana',
    state_short: 'MT',
    county: 'Toole',
    latitude: 48.8516,
    longitude: -111.7442,
    postal_code: '59482'
  },
  '59483': {
    country: 'US',
    city: 'Sun River',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.481,
    longitude: -111.7242,
    postal_code: '59483'
  },
  '59484': {
    country: 'US',
    city: 'Sweet Grass',
    state: 'Montana',
    state_short: 'MT',
    county: 'Toole',
    latitude: 48.9961,
    longitude: -111.9606,
    postal_code: '59484'
  },
  '59485': {
    country: 'US',
    city: 'Ulm',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.3539,
    longitude: -111.5954,
    postal_code: '59485'
  },
  '59486': {
    country: 'US',
    city: 'Valier',
    state: 'Montana',
    state_short: 'MT',
    county: 'Pondera',
    latitude: 48.2795,
    longitude: -112.3033,
    postal_code: '59486'
  },
  '59487': {
    country: 'US',
    city: 'Vaughn',
    state: 'Montana',
    state_short: 'MT',
    county: 'Cascade',
    latitude: 47.5624,
    longitude: -111.577,
    postal_code: '59487'
  },
  '59489': {
    country: 'US',
    city: 'Winifred',
    state: 'Montana',
    state_short: 'MT',
    county: 'Fergus',
    latitude: 47.6408,
    longitude: -109.2364,
    postal_code: '59489'
  },
  '59501': {
    country: 'US',
    city: 'Havre',
    state: 'Montana',
    state_short: 'MT',
    county: 'Hill',
    latitude: 48.5561,
    longitude: -109.688,
    postal_code: '59501'
  },
  '59520': {
    country: 'US',
    city: 'Big Sandy',
    state: 'Montana',
    state_short: 'MT',
    county: 'Chouteau',
    latitude: 48.1497,
    longitude: -110.0776,
    postal_code: '59520'
  },
  '59521': {
    country: 'US',
    city: 'Box Elder',
    state: 'Montana',
    state_short: 'MT',
    county: 'Hill',
    latitude: 48.2841,
    longitude: -109.8205,
    postal_code: '59521'
  },
  '59522': {
    country: 'US',
    city: 'Chester',
    state: 'Montana',
    state_short: 'MT',
    county: 'Liberty',
    latitude: 48.4541,
    longitude: -110.9798,
    postal_code: '59522'
  },
  '59523': {
    country: 'US',
    city: 'Chinook',
    state: 'Montana',
    state_short: 'MT',
    county: 'Blaine',
    latitude: 48.5799,
    longitude: -109.2225,
    postal_code: '59523'
  },
  '59524': {
    country: 'US',
    city: 'Dodson',
    state: 'Montana',
    state_short: 'MT',
    county: 'Phillips',
    latitude: 48.395,
    longitude: -108.2465,
    postal_code: '59524'
  },
  '59525': {
    country: 'US',
    city: 'Gildford',
    state: 'Montana',
    state_short: 'MT',
    county: 'Hill',
    latitude: 48.5927,
    longitude: -110.2836,
    postal_code: '59525'
  },
  '59526': {
    country: 'US',
    city: 'Harlem',
    state: 'Montana',
    state_short: 'MT',
    county: 'Blaine',
    latitude: 48.5398,
    longitude: -108.7693,
    postal_code: '59526'
  },
  '59527': {
    country: 'US',
    city: 'Hays',
    state: 'Montana',
    state_short: 'MT',
    county: 'Blaine',
    latitude: 47.9892,
    longitude: -108.6943,
    postal_code: '59527'
  },
  '59528': {
    country: 'US',
    city: 'Hingham',
    state: 'Montana',
    state_short: 'MT',
    county: 'Hill',
    latitude: 48.587,
    longitude: -110.4275,
    postal_code: '59528'
  },
  '59529': {
    country: 'US',
    city: 'Hogeland',
    state: 'Montana',
    state_short: 'MT',
    county: 'Blaine',
    latitude: 48.8571,
    longitude: -108.6677,
    postal_code: '59529'
  },
  '59530': {
    country: 'US',
    city: 'Inverness',
    state: 'Montana',
    state_short: 'MT',
    county: 'Hill',
    latitude: 48.593,
    longitude: -110.688,
    postal_code: '59530'
  },
  '59531': {
    country: 'US',
    city: 'Joplin',
    state: 'Montana',
    state_short: 'MT',
    county: 'Liberty',
    latitude: 48.6498,
    longitude: -110.7914,
    postal_code: '59531'
  },
  '59532': {
    country: 'US',
    city: 'Kremlin',
    state: 'Montana',
    state_short: 'MT',
    county: 'Hill',
    latitude: 48.56,
    longitude: -110.0513,
    postal_code: '59532'
  },
  '59535': {
    country: 'US',
    city: 'Lloyd',
    state: 'Montana',
    state_short: 'MT',
    county: 'Blaine',
    latitude: 47.9647,
    longitude: -109.2671,
    postal_code: '59535'
  },
  '59537': {
    country: 'US',
    city: 'Loring',
    state: 'Montana',
    state_short: 'MT',
    county: 'Phillips',
    latitude: 48.7983,
    longitude: -107.8686,
    postal_code: '59537'
  },
  '59538': {
    country: 'US',
    city: 'Malta',
    state: 'Montana',
    state_short: 'MT',
    county: 'Phillips',
    latitude: 48.3692,
    longitude: -107.8408,
    postal_code: '59538'
  },
  '59540': {
    country: 'US',
    city: 'Rudyard',
    state: 'Montana',
    state_short: 'MT',
    county: 'Hill',
    latitude: 48.586,
    longitude: -110.5552,
    postal_code: '59540'
  },
  '59542': {
    country: 'US',
    city: 'Turner',
    state: 'Montana',
    state_short: 'MT',
    county: 'Blaine',
    latitude: 48.8328,
    longitude: -108.3961,
    postal_code: '59542'
  },
  '59544': {
    country: 'US',
    city: 'Whitewater',
    state: 'Montana',
    state_short: 'MT',
    county: 'Phillips',
    latitude: 48.7595,
    longitude: -107.6276,
    postal_code: '59544'
  },
  '59545': {
    country: 'US',
    city: 'Whitlash',
    state: 'Montana',
    state_short: 'MT',
    county: 'Liberty',
    latitude: 48.911,
    longitude: -111.1075,
    postal_code: '59545'
  },
  '59546': {
    country: 'US',
    city: 'Zortman',
    state: 'Montana',
    state_short: 'MT',
    county: 'Phillips',
    latitude: 47.7698,
    longitude: -108.592,
    postal_code: '59546'
  },
  '59547': {
    country: 'US',
    city: 'Zurich',
    state: 'Montana',
    state_short: 'MT',
    county: 'Blaine',
    latitude: 48.5844,
    longitude: -109.0304,
    postal_code: '59547'
  },
  '59601': {
    country: 'US',
    city: 'Helena',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.6131,
    longitude: -112.0213,
    postal_code: '59601'
  },
  '59602': {
    country: 'US',
    city: 'Helena',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.7074,
    longitude: -111.958,
    postal_code: '59602'
  },
  '59604': {
    country: 'US',
    city: 'Helena',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.6672,
    longitude: -111.9689,
    postal_code: '59604'
  },
  '59620': {
    country: 'US',
    city: 'Helena',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.5927,
    longitude: -112.0361,
    postal_code: '59620'
  },
  '59623': {
    country: 'US',
    city: 'Helena',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.5901,
    longitude: -112.0402,
    postal_code: '59623'
  },
  '59624': {
    country: 'US',
    city: 'Helena',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.5927,
    longitude: -112.0361,
    postal_code: '59624'
  },
  '59625': {
    country: 'US',
    city: 'Helena',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.6018,
    longitude: -112.0413,
    postal_code: '59625'
  },
  '59626': {
    country: 'US',
    city: 'Helena',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.5927,
    longitude: -112.0361,
    postal_code: '59626'
  },
  '59631': {
    country: 'US',
    city: 'Basin',
    state: 'Montana',
    state_short: 'MT',
    county: 'Jefferson',
    latitude: 46.3296,
    longitude: -112.1967,
    postal_code: '59631'
  },
  '59632': {
    country: 'US',
    city: 'Boulder',
    state: 'Montana',
    state_short: 'MT',
    county: 'Jefferson',
    latitude: 46.2306,
    longitude: -112.1138,
    postal_code: '59632'
  },
  '59633': {
    country: 'US',
    city: 'Canyon Creek',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.7627,
    longitude: -112.2795,
    postal_code: '59633'
  },
  '59634': {
    country: 'US',
    city: 'Clancy',
    state: 'Montana',
    state_short: 'MT',
    county: 'Jefferson',
    latitude: 46.4652,
    longitude: -111.9864,
    postal_code: '59634'
  },
  '59635': {
    country: 'US',
    city: 'East Helena',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.5973,
    longitude: -111.9051,
    postal_code: '59635'
  },
  '59636': {
    country: 'US',
    city: 'Fort Harrison',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.6197,
    longitude: -112.1099,
    postal_code: '59636'
  },
  '59638': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Montana',
    state_short: 'MT',
    county: 'Jefferson',
    latitude: 46.3679,
    longitude: -112.0232,
    postal_code: '59638'
  },
  '59639': {
    country: 'US',
    city: 'Lincoln',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.9575,
    longitude: -112.6651,
    postal_code: '59639'
  },
  '59640': {
    country: 'US',
    city: 'Marysville',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 46.7499,
    longitude: -112.2994,
    postal_code: '59640'
  },
  '59641': {
    country: 'US',
    city: 'Radersburg',
    state: 'Montana',
    state_short: 'MT',
    county: 'Broadwater',
    latitude: 46.2069,
    longitude: -111.6344,
    postal_code: '59641'
  },
  '59642': {
    country: 'US',
    city: 'Ringling',
    state: 'Montana',
    state_short: 'MT',
    county: 'Meagher',
    latitude: 46.2716,
    longitude: -110.8072,
    postal_code: '59642'
  },
  '59643': {
    country: 'US',
    city: 'Toston',
    state: 'Montana',
    state_short: 'MT',
    county: 'Broadwater',
    latitude: 46.1709,
    longitude: -111.5899,
    postal_code: '59643'
  },
  '59644': {
    country: 'US',
    city: 'Townsend',
    state: 'Montana',
    state_short: 'MT',
    county: 'Broadwater',
    latitude: 46.3346,
    longitude: -111.4919,
    postal_code: '59644'
  },
  '59645': {
    country: 'US',
    city: 'White Sulphur Springs',
    state: 'Montana',
    state_short: 'MT',
    county: 'Meagher',
    latitude: 46.6332,
    longitude: -111.0506,
    postal_code: '59645'
  },
  '59647': {
    country: 'US',
    city: 'Winston',
    state: 'Montana',
    state_short: 'MT',
    county: 'Broadwater',
    latitude: 46.4545,
    longitude: -111.651,
    postal_code: '59647'
  },
  '59648': {
    country: 'US',
    city: 'Wolf Creek',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lewis and Clark',
    latitude: 47.0856,
    longitude: -112.1476,
    postal_code: '59648'
  },
  '59701': {
    country: 'US',
    city: 'Butte',
    state: 'Montana',
    state_short: 'MT',
    county: 'Silver Bow',
    latitude: 45.9979,
    longitude: -112.5988,
    postal_code: '59701'
  },
  '59702': {
    country: 'US',
    city: 'Butte',
    state: 'Montana',
    state_short: 'MT',
    county: 'Silver Bow',
    latitude: 45.9053,
    longitude: -112.6377,
    postal_code: '59702'
  },
  '59703': {
    country: 'US',
    city: 'Butte',
    state: 'Montana',
    state_short: 'MT',
    county: 'Silver Bow',
    latitude: 45.9053,
    longitude: -112.6377,
    postal_code: '59703'
  },
  '59707': {
    country: 'US',
    city: 'Butte',
    state: 'Montana',
    state_short: 'MT',
    county: 'Silver Bow',
    latitude: 46.0038,
    longitude: -112.5347,
    postal_code: '59707'
  },
  '59710': {
    country: 'US',
    city: 'Alder',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.1939,
    longitude: -112.0568,
    postal_code: '59710'
  },
  '59711': {
    country: 'US',
    city: 'Anaconda',
    state: 'Montana',
    state_short: 'MT',
    county: 'Deer Lodge',
    latitude: 46.1299,
    longitude: -112.9739,
    postal_code: '59711'
  },
  '59713': {
    country: 'US',
    city: 'Avon',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powell',
    latitude: 46.5972,
    longitude: -112.602,
    postal_code: '59713'
  },
  '59714': {
    country: 'US',
    city: 'Belgrade',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.7801,
    longitude: -111.1439,
    postal_code: '59714'
  },
  '59715': {
    country: 'US',
    city: 'Bozeman',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.6693,
    longitude: -111.0431,
    postal_code: '59715'
  },
  '59716': {
    country: 'US',
    city: 'Big Sky',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.2847,
    longitude: -111.3683,
    postal_code: '59716'
  },
  '59717': {
    country: 'US',
    city: 'Bozeman',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.628,
    longitude: -110.9013,
    postal_code: '59717'
  },
  '59718': {
    country: 'US',
    city: 'Bozeman',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.6681,
    longitude: -111.2404,
    postal_code: '59718'
  },
  '59719': {
    country: 'US',
    city: 'Bozeman',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.628,
    longitude: -110.9013,
    postal_code: '59719'
  },
  '59720': {
    country: 'US',
    city: 'Cameron',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.139,
    longitude: -111.6508,
    postal_code: '59720'
  },
  '59721': {
    country: 'US',
    city: 'Cardwell',
    state: 'Montana',
    state_short: 'MT',
    county: 'Jefferson',
    latitude: 45.8941,
    longitude: -111.7809,
    postal_code: '59721'
  },
  '59722': {
    country: 'US',
    city: 'Deer Lodge',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powell',
    latitude: 46.3881,
    longitude: -112.7476,
    postal_code: '59722'
  },
  '59724': {
    country: 'US',
    city: 'Dell',
    state: 'Montana',
    state_short: 'MT',
    county: 'Beaverhead',
    latitude: 44.723,
    longitude: -112.6972,
    postal_code: '59724'
  },
  '59725': {
    country: 'US',
    city: 'Dillon',
    state: 'Montana',
    state_short: 'MT',
    county: 'Beaverhead',
    latitude: 45.2339,
    longitude: -112.6405,
    postal_code: '59725'
  },
  '59727': {
    country: 'US',
    city: 'Divide',
    state: 'Montana',
    state_short: 'MT',
    county: 'Silver Bow',
    latitude: 45.803,
    longitude: -112.809,
    postal_code: '59727'
  },
  '59728': {
    country: 'US',
    city: 'Elliston',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powell',
    latitude: 46.5034,
    longitude: -112.4007,
    postal_code: '59728'
  },
  '59729': {
    country: 'US',
    city: 'Ennis',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.3545,
    longitude: -111.687,
    postal_code: '59729'
  },
  '59730': {
    country: 'US',
    city: 'Gallatin Gateway',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.339,
    longitude: -111.2485,
    postal_code: '59730'
  },
  '59731': {
    country: 'US',
    city: 'Garrison',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powell',
    latitude: 46.5948,
    longitude: -112.7756,
    postal_code: '59731'
  },
  '59732': {
    country: 'US',
    city: 'Glen',
    state: 'Montana',
    state_short: 'MT',
    county: 'Beaverhead',
    latitude: 45.4766,
    longitude: -112.6906,
    postal_code: '59732'
  },
  '59733': {
    country: 'US',
    city: 'Gold Creek',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powell',
    latitude: 46.59,
    longitude: -112.9706,
    postal_code: '59733'
  },
  '59735': {
    country: 'US',
    city: 'Harrison',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.7423,
    longitude: -111.8461,
    postal_code: '59735'
  },
  '59736': {
    country: 'US',
    city: 'Jackson',
    state: 'Montana',
    state_short: 'MT',
    county: 'Beaverhead',
    latitude: 45.3687,
    longitude: -113.3598,
    postal_code: '59736'
  },
  '59739': {
    country: 'US',
    city: 'Lima',
    state: 'Montana',
    state_short: 'MT',
    county: 'Beaverhead',
    latitude: 44.6441,
    longitude: -112.5625,
    postal_code: '59739'
  },
  '59740': {
    country: 'US',
    city: 'Mc Allister',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.408,
    longitude: -111.7761,
    postal_code: '59740'
  },
  '59741': {
    country: 'US',
    city: 'Manhattan',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.798,
    longitude: -111.3146,
    postal_code: '59741'
  },
  '59743': {
    country: 'US',
    city: 'Melrose',
    state: 'Montana',
    state_short: 'MT',
    county: 'Silver Bow',
    latitude: 45.6319,
    longitude: -112.6845,
    postal_code: '59743'
  },
  '59745': {
    country: 'US',
    city: 'Norris',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.5323,
    longitude: -111.6943,
    postal_code: '59745'
  },
  '59746': {
    country: 'US',
    city: 'Polaris',
    state: 'Montana',
    state_short: 'MT',
    county: 'Beaverhead',
    latitude: 45.3696,
    longitude: -113.1195,
    postal_code: '59746'
  },
  '59747': {
    country: 'US',
    city: 'Pony',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.6585,
    longitude: -111.8944,
    postal_code: '59747'
  },
  '59748': {
    country: 'US',
    city: 'Ramsay',
    state: 'Montana',
    state_short: 'MT',
    county: 'Silver Bow',
    latitude: 46.0058,
    longitude: -112.6861,
    postal_code: '59748'
  },
  '59749': {
    country: 'US',
    city: 'Sheridan',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.423,
    longitude: -112.1735,
    postal_code: '59749'
  },
  '59750': {
    country: 'US',
    city: 'Butte',
    state: 'Montana',
    state_short: 'MT',
    county: 'Silver Bow',
    latitude: 46.0033,
    longitude: -112.7159,
    postal_code: '59750'
  },
  '59751': {
    country: 'US',
    city: 'Silver Star',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.7,
    longitude: -112.2535,
    postal_code: '59751'
  },
  '59752': {
    country: 'US',
    city: 'Three Forks',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.8811,
    longitude: -111.5436,
    postal_code: '59752'
  },
  '59754': {
    country: 'US',
    city: 'Twin Bridges',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.5311,
    longitude: -112.3495,
    postal_code: '59754'
  },
  '59755': {
    country: 'US',
    city: 'Virginia City',
    state: 'Montana',
    state_short: 'MT',
    county: 'Madison',
    latitude: 45.2938,
    longitude: -111.9461,
    postal_code: '59755'
  },
  '59756': {
    country: 'US',
    city: 'Warm Springs',
    state: 'Montana',
    state_short: 'MT',
    county: 'Deer Lodge',
    latitude: 46.1813,
    longitude: -112.7848,
    postal_code: '59756'
  },
  '59758': {
    country: 'US',
    city: 'West Yellowstone',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 44.6621,
    longitude: -111.1041,
    postal_code: '59758'
  },
  '59759': {
    country: 'US',
    city: 'Whitehall',
    state: 'Montana',
    state_short: 'MT',
    county: 'Jefferson',
    latitude: 45.8771,
    longitude: -112.1245,
    postal_code: '59759'
  },
  '59760': {
    country: 'US',
    city: 'Willow Creek',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.7827,
    longitude: -111.6345,
    postal_code: '59760'
  },
  '59761': {
    country: 'US',
    city: 'Wisdom',
    state: 'Montana',
    state_short: 'MT',
    county: 'Beaverhead',
    latitude: 45.6519,
    longitude: -113.4729,
    postal_code: '59761'
  },
  '59762': {
    country: 'US',
    city: 'Wise River',
    state: 'Montana',
    state_short: 'MT',
    county: 'Beaverhead',
    latitude: 45.7424,
    longitude: -112.9963,
    postal_code: '59762'
  },
  '59771': {
    country: 'US',
    city: 'Bozeman',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.7246,
    longitude: -111.1238,
    postal_code: '59771'
  },
  '59772': {
    country: 'US',
    city: 'Bozeman',
    state: 'Montana',
    state_short: 'MT',
    county: 'Gallatin',
    latitude: 45.6361,
    longitude: -111.0647,
    postal_code: '59772'
  },
  '59801': {
    country: 'US',
    city: 'Missoula',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.8563,
    longitude: -114.0252,
    postal_code: '59801'
  },
  '59802': {
    country: 'US',
    city: 'Missoula',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.9006,
    longitude: -114.0027,
    postal_code: '59802'
  },
  '59803': {
    country: 'US',
    city: 'Missoula',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.8224,
    longitude: -114.0265,
    postal_code: '59803'
  },
  '59804': {
    country: 'US',
    city: 'Missoula',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.8467,
    longitude: -114.1698,
    postal_code: '59804'
  },
  '59806': {
    country: 'US',
    city: 'Missoula',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.8721,
    longitude: -113.994,
    postal_code: '59806'
  },
  '59807': {
    country: 'US',
    city: 'Missoula',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.9103,
    longitude: -113.9587,
    postal_code: '59807'
  },
  '59808': {
    country: 'US',
    city: 'Missoula',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.9776,
    longitude: -114.0619,
    postal_code: '59808'
  },
  '59812': {
    country: 'US',
    city: 'Missoula',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.8721,
    longitude: -113.994,
    postal_code: '59812'
  },
  '59820': {
    country: 'US',
    city: 'Alberton',
    state: 'Montana',
    state_short: 'MT',
    county: 'Mineral',
    latitude: 46.9806,
    longitude: -114.4921,
    postal_code: '59820'
  },
  '59821': {
    country: 'US',
    city: 'Arlee',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.186,
    longitude: -114.076,
    postal_code: '59821'
  },
  '59823': {
    country: 'US',
    city: 'Bonner',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.8733,
    longitude: -113.8645,
    postal_code: '59823'
  },
  '59824': {
    country: 'US',
    city: 'Charlo',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.4385,
    longitude: -114.1723,
    postal_code: '59824'
  },
  '59825': {
    country: 'US',
    city: 'Clinton',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.7673,
    longitude: -113.7038,
    postal_code: '59825'
  },
  '59826': {
    country: 'US',
    city: 'Condon',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 47.5097,
    longitude: -113.7075,
    postal_code: '59826'
  },
  '59827': {
    country: 'US',
    city: 'Conner',
    state: 'Montana',
    state_short: 'MT',
    county: 'Ravalli',
    latitude: 45.919,
    longitude: -114.059,
    postal_code: '59827'
  },
  '59828': {
    country: 'US',
    city: 'Corvallis',
    state: 'Montana',
    state_short: 'MT',
    county: 'Ravalli',
    latitude: 46.3142,
    longitude: -114.096,
    postal_code: '59828'
  },
  '59829': {
    country: 'US',
    city: 'Darby',
    state: 'Montana',
    state_short: 'MT',
    county: 'Ravalli',
    latitude: 46.028,
    longitude: -114.1938,
    postal_code: '59829'
  },
  '59830': {
    country: 'US',
    city: 'De Borgia',
    state: 'Montana',
    state_short: 'MT',
    county: 'Mineral',
    latitude: 47.3888,
    longitude: -115.3479,
    postal_code: '59830'
  },
  '59831': {
    country: 'US',
    city: 'Dixon',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sanders',
    latitude: 47.3131,
    longitude: -114.3056,
    postal_code: '59831'
  },
  '59832': {
    country: 'US',
    city: 'Drummond',
    state: 'Montana',
    state_short: 'MT',
    county: 'Granite',
    latitude: 46.6647,
    longitude: -113.2426,
    postal_code: '59832'
  },
  '59833': {
    country: 'US',
    city: 'Florence',
    state: 'Montana',
    state_short: 'MT',
    county: 'Ravalli',
    latitude: 46.631,
    longitude: -114.0945,
    postal_code: '59833'
  },
  '59834': {
    country: 'US',
    city: 'Frenchtown',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 47.0471,
    longitude: -114.2683,
    postal_code: '59834'
  },
  '59835': {
    country: 'US',
    city: 'Grantsdale',
    state: 'Montana',
    state_short: 'MT',
    county: 'Ravalli',
    latitude: 46.2035,
    longitude: -114.1418,
    postal_code: '59835'
  },
  '59837': {
    country: 'US',
    city: 'Hall',
    state: 'Montana',
    state_short: 'MT',
    county: 'Granite',
    latitude: 46.5825,
    longitude: -113.2087,
    postal_code: '59837'
  },
  '59840': {
    country: 'US',
    city: 'Hamilton',
    state: 'Montana',
    state_short: 'MT',
    county: 'Ravalli',
    latitude: 46.2395,
    longitude: -114.1679,
    postal_code: '59840'
  },
  '59841': {
    country: 'US',
    city: 'Pinesdale',
    state: 'Montana',
    state_short: 'MT',
    county: 'Ravalli',
    latitude: 46.3329,
    longitude: -114.2235,
    postal_code: '59841'
  },
  '59842': {
    country: 'US',
    city: 'Haugan',
    state: 'Montana',
    state_short: 'MT',
    county: 'Mineral',
    latitude: 47.3833,
    longitude: -115.3996,
    postal_code: '59842'
  },
  '59843': {
    country: 'US',
    city: 'Helmville',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powell',
    latitude: 46.83,
    longitude: -112.9413,
    postal_code: '59843'
  },
  '59844': {
    country: 'US',
    city: 'Heron',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sanders',
    latitude: 48.0537,
    longitude: -115.9407,
    postal_code: '59844'
  },
  '59845': {
    country: 'US',
    city: 'Hot Springs',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sanders',
    latitude: 47.5914,
    longitude: -114.6597,
    postal_code: '59845'
  },
  '59846': {
    country: 'US',
    city: 'Huson',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 47.066,
    longitude: -114.4219,
    postal_code: '59846'
  },
  '59847': {
    country: 'US',
    city: 'Lolo',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.7585,
    longitude: -114.1097,
    postal_code: '59847'
  },
  '59848': {
    country: 'US',
    city: 'Lonepine',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sanders',
    latitude: 47.7251,
    longitude: -114.657,
    postal_code: '59848'
  },
  '59851': {
    country: 'US',
    city: 'Milltown',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 46.8737,
    longitude: -113.8783,
    postal_code: '59851'
  },
  '59853': {
    country: 'US',
    city: 'Noxon',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sanders',
    latitude: 48.0768,
    longitude: -115.8582,
    postal_code: '59853'
  },
  '59854': {
    country: 'US',
    city: 'Ovando',
    state: 'Montana',
    state_short: 'MT',
    county: 'Powell',
    latitude: 47.0067,
    longitude: -113.0905,
    postal_code: '59854'
  },
  '59855': {
    country: 'US',
    city: 'Pablo',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.6002,
    longitude: -114.119,
    postal_code: '59855'
  },
  '59856': {
    country: 'US',
    city: 'Paradise',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sanders',
    latitude: 47.3878,
    longitude: -114.799,
    postal_code: '59856'
  },
  '59858': {
    country: 'US',
    city: 'Philipsburg',
    state: 'Montana',
    state_short: 'MT',
    county: 'Granite',
    latitude: 46.3189,
    longitude: -113.3126,
    postal_code: '59858'
  },
  '59859': {
    country: 'US',
    city: 'Plains',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sanders',
    latitude: 47.4734,
    longitude: -114.893,
    postal_code: '59859'
  },
  '59860': {
    country: 'US',
    city: 'Polson',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.6876,
    longitude: -114.1404,
    postal_code: '59860'
  },
  '59863': {
    country: 'US',
    city: 'Ravalli',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.2772,
    longitude: -114.1807,
    postal_code: '59863'
  },
  '59864': {
    country: 'US',
    city: 'Ronan',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.5525,
    longitude: -114.1054,
    postal_code: '59864'
  },
  '59865': {
    country: 'US',
    city: 'Saint Ignatius',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.33,
    longitude: -114.0758,
    postal_code: '59865'
  },
  '59866': {
    country: 'US',
    city: 'Saint Regis',
    state: 'Montana',
    state_short: 'MT',
    county: 'Mineral',
    latitude: 47.3369,
    longitude: -115.1703,
    postal_code: '59866'
  },
  '59867': {
    country: 'US',
    city: 'Saltese',
    state: 'Montana',
    state_short: 'MT',
    county: 'Mineral',
    latitude: 47.4102,
    longitude: -115.5096,
    postal_code: '59867'
  },
  '59868': {
    country: 'US',
    city: 'Seeley Lake',
    state: 'Montana',
    state_short: 'MT',
    county: 'Missoula',
    latitude: 47.1789,
    longitude: -113.481,
    postal_code: '59868'
  },
  '59870': {
    country: 'US',
    city: 'Stevensville',
    state: 'Montana',
    state_short: 'MT',
    county: 'Ravalli',
    latitude: 46.5267,
    longitude: -114.0478,
    postal_code: '59870'
  },
  '59871': {
    country: 'US',
    city: 'Sula',
    state: 'Montana',
    state_short: 'MT',
    county: 'Ravalli',
    latitude: 45.8433,
    longitude: -113.8748,
    postal_code: '59871'
  },
  '59872': {
    country: 'US',
    city: 'Superior',
    state: 'Montana',
    state_short: 'MT',
    county: 'Mineral',
    latitude: 47.1721,
    longitude: -114.8885,
    postal_code: '59872'
  },
  '59873': {
    country: 'US',
    city: 'Thompson Falls',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sanders',
    latitude: 47.6016,
    longitude: -115.3602,
    postal_code: '59873'
  },
  '59874': {
    country: 'US',
    city: 'Trout Creek',
    state: 'Montana',
    state_short: 'MT',
    county: 'Sanders',
    latitude: 47.8111,
    longitude: -115.5592,
    postal_code: '59874'
  },
  '59875': {
    country: 'US',
    city: 'Victor',
    state: 'Montana',
    state_short: 'MT',
    county: 'Ravalli',
    latitude: 46.4005,
    longitude: -114.1665,
    postal_code: '59875'
  },
  '59901': {
    country: 'US',
    city: 'Kalispell',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.2028,
    longitude: -114.3039,
    postal_code: '59901'
  },
  '59903': {
    country: 'US',
    city: 'Kalispell',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.2237,
    longitude: -114.4296,
    postal_code: '59903'
  },
  '59904': {
    country: 'US',
    city: 'Kalispell',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.2404,
    longitude: -114.2561,
    postal_code: '59904'
  },
  '59910': {
    country: 'US',
    city: 'Big Arm',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.7952,
    longitude: -114.302,
    postal_code: '59910'
  },
  '59911': {
    country: 'US',
    city: 'Bigfork',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.0633,
    longitude: -114.0726,
    postal_code: '59911'
  },
  '59912': {
    country: 'US',
    city: 'Columbia Falls',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.3534,
    longitude: -114.1784,
    postal_code: '59912'
  },
  '59913': {
    country: 'US',
    city: 'Coram',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.4342,
    longitude: -114.0584,
    postal_code: '59913'
  },
  '59914': {
    country: 'US',
    city: 'Dayton',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.8607,
    longitude: -114.2809,
    postal_code: '59914'
  },
  '59915': {
    country: 'US',
    city: 'Elmo',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.8305,
    longitude: -114.3496,
    postal_code: '59915'
  },
  '59916': {
    country: 'US',
    city: 'Essex',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.278,
    longitude: -113.6126,
    postal_code: '59916'
  },
  '59917': {
    country: 'US',
    city: 'Eureka',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lincoln',
    latitude: 48.8428,
    longitude: -115.0049,
    postal_code: '59917'
  },
  '59918': {
    country: 'US',
    city: 'Fortine',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lincoln',
    latitude: 48.7586,
    longitude: -114.8809,
    postal_code: '59918'
  },
  '59919': {
    country: 'US',
    city: 'Hungry Horse',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.3858,
    longitude: -114.061,
    postal_code: '59919'
  },
  '59920': {
    country: 'US',
    city: 'Kila',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.0744,
    longitude: -114.5104,
    postal_code: '59920'
  },
  '59921': {
    country: 'US',
    city: 'Lake Mc Donald',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.2989,
    longitude: -113.9497,
    postal_code: '59921'
  },
  '59922': {
    country: 'US',
    city: 'Lakeside',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.0215,
    longitude: -114.2266,
    postal_code: '59922'
  },
  '59923': {
    country: 'US',
    city: 'Libby',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lincoln',
    latitude: 48.3773,
    longitude: -115.5391,
    postal_code: '59923'
  },
  '59925': {
    country: 'US',
    city: 'Marion',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.0836,
    longitude: -114.7446,
    postal_code: '59925'
  },
  '59926': {
    country: 'US',
    city: 'Martin City',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.3916,
    longitude: -114.0382,
    postal_code: '59926'
  },
  '59927': {
    country: 'US',
    city: 'Olney',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.5455,
    longitude: -114.592,
    postal_code: '59927'
  },
  '59928': {
    country: 'US',
    city: 'Polebridge',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.8206,
    longitude: -114.3836,
    postal_code: '59928'
  },
  '59929': {
    country: 'US',
    city: 'Proctor',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.9404,
    longitude: -114.3832,
    postal_code: '59929'
  },
  '59930': {
    country: 'US',
    city: 'Rexford',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lincoln',
    latitude: 48.9179,
    longitude: -115.2129,
    postal_code: '59930'
  },
  '59931': {
    country: 'US',
    city: 'Rollins',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lake',
    latitude: 47.9182,
    longitude: -114.225,
    postal_code: '59931'
  },
  '59932': {
    country: 'US',
    city: 'Somers',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.0793,
    longitude: -114.2355,
    postal_code: '59932'
  },
  '59933': {
    country: 'US',
    city: 'Stryker',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lincoln',
    latitude: 48.6741,
    longitude: -114.7702,
    postal_code: '59933'
  },
  '59934': {
    country: 'US',
    city: 'Trego',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lincoln',
    latitude: 48.7052,
    longitude: -114.8693,
    postal_code: '59934'
  },
  '59935': {
    country: 'US',
    city: 'Troy',
    state: 'Montana',
    state_short: 'MT',
    county: 'Lincoln',
    latitude: 48.4791,
    longitude: -115.8817,
    postal_code: '59935'
  },
  '59936': {
    country: 'US',
    city: 'West Glacier',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.5,
    longitude: -113.9787,
    postal_code: '59936'
  },
  '59937': {
    country: 'US',
    city: 'Whitefish',
    state: 'Montana',
    state_short: 'MT',
    county: 'Flathead',
    latitude: 48.404,
    longitude: -114.3509,
    postal_code: '59937'
  },
  '60001': {
    country: 'US',
    city: 'Alden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.3248,
    longitude: -88.4525,
    postal_code: '60001'
  },
  '60002': {
    country: 'US',
    city: 'Antioch',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.4648,
    longitude: -88.1178,
    postal_code: '60002'
  },
  '60004': {
    country: 'US',
    city: 'Arlington Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.112,
    longitude: -87.9792,
    postal_code: '60004'
  },
  '60005': {
    country: 'US',
    city: 'Arlington Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0639,
    longitude: -87.9856,
    postal_code: '60005'
  },
  '60006': {
    country: 'US',
    city: 'Arlington Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0884,
    longitude: -87.9806,
    postal_code: '60006'
  },
  '60007': {
    country: 'US',
    city: 'Elk Grove Village',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0076,
    longitude: -87.9931,
    postal_code: '60007'
  },
  '60008': {
    country: 'US',
    city: 'Rolling Meadows',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.073,
    longitude: -88.0191,
    postal_code: '60008'
  },
  '60009': {
    country: 'US',
    city: 'Elk Grove Village',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0039,
    longitude: -87.9703,
    postal_code: '60009'
  },
  '60010': {
    country: 'US',
    city: 'Barrington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.1614,
    longitude: -88.1383,
    postal_code: '60010'
  },
  '60011': {
    country: 'US',
    city: 'Barrington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3228,
    longitude: -87.6101,
    postal_code: '60011'
  },
  '60012': {
    country: 'US',
    city: 'Crystal Lake',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.2662,
    longitude: -88.3213,
    postal_code: '60012'
  },
  '60013': {
    country: 'US',
    city: 'Cary',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.2196,
    longitude: -88.2426,
    postal_code: '60013'
  },
  '60014': {
    country: 'US',
    city: 'Crystal Lake',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.2308,
    longitude: -88.3324,
    postal_code: '60014'
  },
  '60015': {
    country: 'US',
    city: 'Deerfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.1705,
    longitude: -87.859,
    postal_code: '60015'
  },
  '60016': {
    country: 'US',
    city: 'Des Plaines',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0467,
    longitude: -87.8859,
    postal_code: '60016'
  },
  '60017': {
    country: 'US',
    city: 'Des Plaines',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0288,
    longitude: -87.8944,
    postal_code: '60017'
  },
  '60018': {
    country: 'US',
    city: 'Des Plaines',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0155,
    longitude: -87.8687,
    postal_code: '60018'
  },
  '60019': {
    country: 'US',
    city: 'Des Plaines',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0243,
    longitude: -87.9071,
    postal_code: '60019'
  },
  '60020': {
    country: 'US',
    city: 'Fox Lake',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3937,
    longitude: -88.1648,
    postal_code: '60020'
  },
  '60021': {
    country: 'US',
    city: 'Fox River Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.1936,
    longitude: -88.2205,
    postal_code: '60021'
  },
  '60022': {
    country: 'US',
    city: 'Glencoe',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1333,
    longitude: -87.7615,
    postal_code: '60022'
  },
  '60025': {
    country: 'US',
    city: 'Glenview',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0758,
    longitude: -87.8223,
    postal_code: '60025'
  },
  '60026': {
    country: 'US',
    city: 'Glenview',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0698,
    longitude: -87.7878,
    postal_code: '60026'
  },
  '60029': {
    country: 'US',
    city: 'Golf',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.058,
    longitude: -87.7916,
    postal_code: '60029'
  },
  '60030': {
    country: 'US',
    city: 'Grayslake',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3524,
    longitude: -88.0545,
    postal_code: '60030'
  },
  '60031': {
    country: 'US',
    city: 'Gurnee',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3669,
    longitude: -87.9452,
    postal_code: '60031'
  },
  '60033': {
    country: 'US',
    city: 'Harvard',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.4227,
    longitude: -88.6048,
    postal_code: '60033'
  },
  '60034': {
    country: 'US',
    city: 'Hebron',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.4642,
    longitude: -88.4176,
    postal_code: '60034'
  },
  '60035': {
    country: 'US',
    city: 'Highland Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.1794,
    longitude: -87.8059,
    postal_code: '60035'
  },
  '60037': {
    country: 'US',
    city: 'Fort Sheridan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.2097,
    longitude: -87.8056,
    postal_code: '60037'
  },
  '60038': {
    country: 'US',
    city: 'Palatine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.098,
    longitude: -88.0141,
    postal_code: '60038'
  },
  '60039': {
    country: 'US',
    city: 'Crystal Lake',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.3248,
    longitude: -88.4525,
    postal_code: '60039'
  },
  '60040': {
    country: 'US',
    city: 'Highwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.2035,
    longitude: -87.8141,
    postal_code: '60040'
  },
  '60041': {
    country: 'US',
    city: 'Ingleside',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3631,
    longitude: -88.1587,
    postal_code: '60041'
  },
  '60042': {
    country: 'US',
    city: 'Island Lake',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.2742,
    longitude: -88.1926,
    postal_code: '60042'
  },
  '60043': {
    country: 'US',
    city: 'Kenilworth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0884,
    longitude: -87.7165,
    postal_code: '60043'
  },
  '60044': {
    country: 'US',
    city: 'Lake Bluff',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.282,
    longitude: -87.856,
    postal_code: '60044'
  },
  '60045': {
    country: 'US',
    city: 'Lake Forest',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.2374,
    longitude: -87.8482,
    postal_code: '60045'
  },
  '60046': {
    country: 'US',
    city: 'Lake Villa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3813,
    longitude: -87.9991,
    postal_code: '60046'
  },
  '60047': {
    country: 'US',
    city: 'Lake Zurich',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.2165,
    longitude: -88.0769,
    postal_code: '60047'
  },
  '60048': {
    country: 'US',
    city: 'Libertyville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.281,
    longitude: -87.95,
    postal_code: '60048'
  },
  '60050': {
    country: 'US',
    city: 'Mchenry',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.3311,
    longitude: -88.2955,
    postal_code: '60050'
  },
  '60051': {
    country: 'US',
    city: 'Mchenry',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.3542,
    longitude: -88.2294,
    postal_code: '60051'
  },
  '60053': {
    country: 'US',
    city: 'Morton Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0431,
    longitude: -87.7899,
    postal_code: '60053'
  },
  '60055': {
    country: 'US',
    city: 'Palatine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.098,
    longitude: -88.0141,
    postal_code: '60055'
  },
  '60056': {
    country: 'US',
    city: 'Mount Prospect',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0624,
    longitude: -87.9377,
    postal_code: '60056'
  },
  '60060': {
    country: 'US',
    city: 'Mundelein',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.2636,
    longitude: -88.0048,
    postal_code: '60060'
  },
  '60061': {
    country: 'US',
    city: 'Vernon Hills',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.2288,
    longitude: -87.9719,
    postal_code: '60061'
  },
  '60062': {
    country: 'US',
    city: 'Northbrook',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1254,
    longitude: -87.8465,
    postal_code: '60062'
  },
  '60064': {
    country: 'US',
    city: 'North Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3247,
    longitude: -87.8564,
    postal_code: '60064'
  },
  '60065': {
    country: 'US',
    city: 'Northbrook',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1275,
    longitude: -87.829,
    postal_code: '60065'
  },
  '60067': {
    country: 'US',
    city: 'Palatine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1139,
    longitude: -88.0429,
    postal_code: '60067'
  },
  '60068': {
    country: 'US',
    city: 'Park Ridge',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0122,
    longitude: -87.8417,
    postal_code: '60068'
  },
  '60069': {
    country: 'US',
    city: 'Lincolnshire',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.1976,
    longitude: -87.9261,
    postal_code: '60069'
  },
  '60070': {
    country: 'US',
    city: 'Prospect Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1058,
    longitude: -87.9395,
    postal_code: '60070'
  },
  '60071': {
    country: 'US',
    city: 'Richmond',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.4669,
    longitude: -88.29,
    postal_code: '60071'
  },
  '60072': {
    country: 'US',
    city: 'Ringwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.4048,
    longitude: -88.3054,
    postal_code: '60072'
  },
  '60073': {
    country: 'US',
    city: 'Round Lake',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3668,
    longitude: -88.0888,
    postal_code: '60073'
  },
  '60074': {
    country: 'US',
    city: 'Palatine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1458,
    longitude: -88.023,
    postal_code: '60074'
  },
  '60075': {
    country: 'US',
    city: 'Russell',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.4906,
    longitude: -87.9126,
    postal_code: '60075'
  },
  '60076': {
    country: 'US',
    city: 'Skokie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0362,
    longitude: -87.7328,
    postal_code: '60076'
  },
  '60077': {
    country: 'US',
    city: 'Skokie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0345,
    longitude: -87.7541,
    postal_code: '60077'
  },
  '60078': {
    country: 'US',
    city: 'Palatine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1103,
    longitude: -88.0342,
    postal_code: '60078'
  },
  '60079': {
    country: 'US',
    city: 'Waukegan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3636,
    longitude: -87.8448,
    postal_code: '60079'
  },
  '60081': {
    country: 'US',
    city: 'Spring Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.4413,
    longitude: -88.2237,
    postal_code: '60081'
  },
  '60082': {
    country: 'US',
    city: 'Techny',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1164,
    longitude: -87.8121,
    postal_code: '60082'
  },
  '60083': {
    country: 'US',
    city: 'Wadsworth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.446,
    longitude: -87.904,
    postal_code: '60083'
  },
  '60084': {
    country: 'US',
    city: 'Wauconda',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.2636,
    longitude: -88.1333,
    postal_code: '60084'
  },
  '60085': {
    country: 'US',
    city: 'Waukegan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3542,
    longitude: -87.8651,
    postal_code: '60085'
  },
  '60086': {
    country: 'US',
    city: 'North Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.4333,
    longitude: -87.7766,
    postal_code: '60086'
  },
  '60087': {
    country: 'US',
    city: 'Waukegan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3989,
    longitude: -87.8554,
    postal_code: '60087'
  },
  '60088': {
    country: 'US',
    city: 'Great Lakes',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.3032,
    longitude: -87.8642,
    postal_code: '60088'
  },
  '60089': {
    country: 'US',
    city: 'Buffalo Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.1598,
    longitude: -87.9644,
    postal_code: '60089'
  },
  '60090': {
    country: 'US',
    city: 'Wheeling',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.134,
    longitude: -87.9341,
    postal_code: '60090'
  },
  '60091': {
    country: 'US',
    city: 'Wilmette',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0765,
    longitude: -87.7246,
    postal_code: '60091'
  },
  '60093': {
    country: 'US',
    city: 'Winnetka',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1054,
    longitude: -87.7535,
    postal_code: '60093'
  },
  '60094': {
    country: 'US',
    city: 'Palatine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1103,
    longitude: -88.0342,
    postal_code: '60094'
  },
  '60095': {
    country: 'US',
    city: 'Palatine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.1103,
    longitude: -88.0342,
    postal_code: '60095'
  },
  '60096': {
    country: 'US',
    city: 'Winthrop Harbor',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.4793,
    longitude: -87.8318,
    postal_code: '60096'
  },
  '60097': {
    country: 'US',
    city: 'Wonder Lake',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.3849,
    longitude: -88.3534,
    postal_code: '60097'
  },
  '60098': {
    country: 'US',
    city: 'Woodstock',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.3198,
    longitude: -88.4477,
    postal_code: '60098'
  },
  '60099': {
    country: 'US',
    city: 'Zion',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lake',
    latitude: 42.4442,
    longitude: -87.8389,
    postal_code: '60099'
  },
  '60101': {
    country: 'US',
    city: 'Addison',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9335,
    longitude: -88.0054,
    postal_code: '60101'
  },
  '60102': {
    country: 'US',
    city: 'Algonquin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.1641,
    longitude: -88.3064,
    postal_code: '60102'
  },
  '60103': {
    country: 'US',
    city: 'Bartlett',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9794,
    longitude: -88.2063,
    postal_code: '60103'
  },
  '60104': {
    country: 'US',
    city: 'Bellwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8825,
    longitude: -87.8786,
    postal_code: '60104'
  },
  '60105': {
    country: 'US',
    city: 'Bensenville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.955,
    longitude: -87.9401,
    postal_code: '60105'
  },
  '60106': {
    country: 'US',
    city: 'Bensenville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9501,
    longitude: -87.945,
    postal_code: '60106'
  },
  '60107': {
    country: 'US',
    city: 'Streamwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0225,
    longitude: -88.169,
    postal_code: '60107'
  },
  '60108': {
    country: 'US',
    city: 'Bloomingdale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9483,
    longitude: -88.0782,
    postal_code: '60108'
  },
  '60109': {
    country: 'US',
    city: 'Burlington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 42.0458,
    longitude: -88.539,
    postal_code: '60109'
  },
  '60110': {
    country: 'US',
    city: 'Carpentersville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 42.123,
    longitude: -88.2606,
    postal_code: '60110'
  },
  '60111': {
    country: 'US',
    city: 'Clare',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 42.0086,
    longitude: -88.8306,
    postal_code: '60111'
  },
  '60112': {
    country: 'US',
    city: 'Cortland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 41.92,
    longitude: -88.6887,
    postal_code: '60112'
  },
  '60113': {
    country: 'US',
    city: 'Creston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 41.9312,
    longitude: -88.9566,
    postal_code: '60113'
  },
  '60115': {
    country: 'US',
    city: 'Dekalb',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 41.9008,
    longitude: -88.7548,
    postal_code: '60115'
  },
  '60116': {
    country: 'US',
    city: 'Carol Stream',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9125,
    longitude: -88.1348,
    postal_code: '60116'
  },
  '60117': {
    country: 'US',
    city: 'Bloomingdale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8397,
    longitude: -88.0887,
    postal_code: '60117'
  },
  '60118': {
    country: 'US',
    city: 'Dundee',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 42.0962,
    longitude: -88.2902,
    postal_code: '60118'
  },
  '60119': {
    country: 'US',
    city: 'Elburn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.8824,
    longitude: -88.4611,
    postal_code: '60119'
  },
  '60120': {
    country: 'US',
    city: 'Elgin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 42.0384,
    longitude: -88.2606,
    postal_code: '60120'
  },
  '60121': {
    country: 'US',
    city: 'Elgin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 42.0372,
    longitude: -88.2812,
    postal_code: '60121'
  },
  '60122': {
    country: 'US',
    city: 'Elgin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 42.0671,
    longitude: -88.305,
    postal_code: '60122'
  },
  '60123': {
    country: 'US',
    city: 'Elgin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 42.0376,
    longitude: -88.3186,
    postal_code: '60123'
  },
  '60124': {
    country: 'US',
    city: 'Elgin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 42.0293,
    longitude: -88.3747,
    postal_code: '60124'
  },
  '60126': {
    country: 'US',
    city: 'Elmhurst',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8927,
    longitude: -87.941,
    postal_code: '60126'
  },
  '60128': {
    country: 'US',
    city: 'Carol Stream',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8397,
    longitude: -88.0887,
    postal_code: '60128'
  },
  '60129': {
    country: 'US',
    city: 'Esmond',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 42.0225,
    longitude: -88.9439,
    postal_code: '60129'
  },
  '60130': {
    country: 'US',
    city: 'Forest Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8744,
    longitude: -87.8106,
    postal_code: '60130'
  },
  '60131': {
    country: 'US',
    city: 'Franklin Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9339,
    longitude: -87.8734,
    postal_code: '60131'
  },
  '60132': {
    country: 'US',
    city: 'Carol Stream',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8397,
    longitude: -88.0887,
    postal_code: '60132'
  },
  '60133': {
    country: 'US',
    city: 'Hanover Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9995,
    longitude: -88.1451,
    postal_code: '60133'
  },
  '60134': {
    country: 'US',
    city: 'Geneva',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.886,
    longitude: -88.311,
    postal_code: '60134'
  },
  '60135': {
    country: 'US',
    city: 'Genoa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 42.0981,
    longitude: -88.6908,
    postal_code: '60135'
  },
  '60136': {
    country: 'US',
    city: 'Gilberts',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 42.0984,
    longitude: -88.3691,
    postal_code: '60136'
  },
  '60137': {
    country: 'US',
    city: 'Glen Ellyn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8661,
    longitude: -88.0648,
    postal_code: '60137'
  },
  '60138': {
    country: 'US',
    city: 'Glen Ellyn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8775,
    longitude: -88.067,
    postal_code: '60138'
  },
  '60139': {
    country: 'US',
    city: 'Glendale Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9205,
    longitude: -88.0793,
    postal_code: '60139'
  },
  '60140': {
    country: 'US',
    city: 'Hampshire',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 42.0807,
    longitude: -88.517,
    postal_code: '60140'
  },
  '60141': {
    country: 'US',
    city: 'Hines',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8623,
    longitude: -87.8355,
    postal_code: '60141'
  },
  '60142': {
    country: 'US',
    city: 'Huntley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.1756,
    longitude: -88.4268,
    postal_code: '60142'
  },
  '60143': {
    country: 'US',
    city: 'Itasca',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.972,
    longitude: -88.0202,
    postal_code: '60143'
  },
  '60144': {
    country: 'US',
    city: 'Kaneville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.8353,
    longitude: -88.522,
    postal_code: '60144'
  },
  '60145': {
    country: 'US',
    city: 'Kingston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 42.1057,
    longitude: -88.7695,
    postal_code: '60145'
  },
  '60146': {
    country: 'US',
    city: 'Kirkland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 42.1014,
    longitude: -88.8685,
    postal_code: '60146'
  },
  '60147': {
    country: 'US',
    city: 'Lafox',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.937,
    longitude: -88.4202,
    postal_code: '60147'
  },
  '60148': {
    country: 'US',
    city: 'Lombard',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8721,
    longitude: -88.016,
    postal_code: '60148'
  },
  '60150': {
    country: 'US',
    city: 'Malta',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 41.9183,
    longitude: -88.8688,
    postal_code: '60150'
  },
  '60151': {
    country: 'US',
    city: 'Maple Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.9232,
    longitude: -88.5999,
    postal_code: '60151'
  },
  '60152': {
    country: 'US',
    city: 'Marengo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.2442,
    longitude: -88.6074,
    postal_code: '60152'
  },
  '60153': {
    country: 'US',
    city: 'Maywood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8793,
    longitude: -87.8433,
    postal_code: '60153'
  },
  '60154': {
    country: 'US',
    city: 'Westchester',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8524,
    longitude: -87.8845,
    postal_code: '60154'
  },
  '60155': {
    country: 'US',
    city: 'Broadview',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8577,
    longitude: -87.8562,
    postal_code: '60155'
  },
  '60156': {
    country: 'US',
    city: 'Lake In The Hills',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.1817,
    longitude: -88.3304,
    postal_code: '60156'
  },
  '60157': {
    country: 'US',
    city: 'Medinah',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9814,
    longitude: -88.0512,
    postal_code: '60157'
  },
  '60159': {
    country: 'US',
    city: 'Schaumburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0334,
    longitude: -88.0834,
    postal_code: '60159'
  },
  '60160': {
    country: 'US',
    city: 'Melrose Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9003,
    longitude: -87.8581,
    postal_code: '60160'
  },
  '60161': {
    country: 'US',
    city: 'Melrose Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9006,
    longitude: -87.8567,
    postal_code: '60161'
  },
  '60162': {
    country: 'US',
    city: 'Hillside',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8725,
    longitude: -87.9016,
    postal_code: '60162'
  },
  '60163': {
    country: 'US',
    city: 'Berkeley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8888,
    longitude: -87.909,
    postal_code: '60163'
  },
  '60164': {
    country: 'US',
    city: 'Melrose Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9214,
    longitude: -87.8924,
    postal_code: '60164'
  },
  '60165': {
    country: 'US',
    city: 'Stone Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.903,
    longitude: -87.8811,
    postal_code: '60165'
  },
  '60168': {
    country: 'US',
    city: 'Schaumburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0334,
    longitude: -88.0834,
    postal_code: '60168'
  },
  '60169': {
    country: 'US',
    city: 'Hoffman Estates',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0493,
    longitude: -88.1065,
    postal_code: '60169'
  },
  '60171': {
    country: 'US',
    city: 'River Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9279,
    longitude: -87.8387,
    postal_code: '60171'
  },
  '60172': {
    country: 'US',
    city: 'Roselle',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9798,
    longitude: -88.0857,
    postal_code: '60172'
  },
  '60173': {
    country: 'US',
    city: 'Schaumburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0581,
    longitude: -88.0482,
    postal_code: '60173'
  },
  '60174': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.9194,
    longitude: -88.307,
    postal_code: '60174'
  },
  '60175': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.9478,
    longitude: -88.3918,
    postal_code: '60175'
  },
  '60176': {
    country: 'US',
    city: 'Schiller Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9563,
    longitude: -87.8692,
    postal_code: '60176'
  },
  '60177': {
    country: 'US',
    city: 'South Elgin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.9969,
    longitude: -88.2986,
    postal_code: '60177'
  },
  '60178': {
    country: 'US',
    city: 'Sycamore',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 41.9911,
    longitude: -88.6928,
    postal_code: '60178'
  },
  '60179': {
    country: 'US',
    city: 'Hoffman Estates',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0793,
    longitude: -88.2237,
    postal_code: '60179'
  },
  '60180': {
    country: 'US',
    city: 'Union',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McHenry',
    latitude: 42.2103,
    longitude: -88.5283,
    postal_code: '60180'
  },
  '60181': {
    country: 'US',
    city: 'Villa Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8799,
    longitude: -87.9782,
    postal_code: '60181'
  },
  '60183': {
    country: 'US',
    city: 'Wasco',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.937,
    longitude: -88.4202,
    postal_code: '60183'
  },
  '60184': {
    country: 'US',
    city: 'Wayne',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9525,
    longitude: -88.2536,
    postal_code: '60184'
  },
  '60185': {
    country: 'US',
    city: 'West Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8886,
    longitude: -88.2022,
    postal_code: '60185'
  },
  '60186': {
    country: 'US',
    city: 'West Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8848,
    longitude: -88.204,
    postal_code: '60186'
  },
  '60187': {
    country: 'US',
    city: 'Wheaton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8724,
    longitude: -88.1123,
    postal_code: '60187'
  },
  '60188': {
    country: 'US',
    city: 'Carol Stream',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9178,
    longitude: -88.137,
    postal_code: '60188'
  },
  '60189': {
    country: 'US',
    city: 'Wheaton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8397,
    longitude: -88.0887,
    postal_code: '60189'
  },
  '60190': {
    country: 'US',
    city: 'Winfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8744,
    longitude: -88.1516,
    postal_code: '60190'
  },
  '60191': {
    country: 'US',
    city: 'Wood Dale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9602,
    longitude: -87.981,
    postal_code: '60191'
  },
  '60192': {
    country: 'US',
    city: 'Hoffman Estates',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0428,
    longitude: -88.0798,
    postal_code: '60192'
  },
  '60193': {
    country: 'US',
    city: 'Schaumburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0144,
    longitude: -88.0935,
    postal_code: '60193'
  },
  '60194': {
    country: 'US',
    city: 'Schaumburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0289,
    longitude: -88.1167,
    postal_code: '60194'
  },
  '60195': {
    country: 'US',
    city: 'Schaumburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0764,
    longitude: -88.1093,
    postal_code: '60195'
  },
  '60196': {
    country: 'US',
    city: 'Schaumburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0564,
    longitude: -88.0725,
    postal_code: '60196'
  },
  '60197': {
    country: 'US',
    city: 'Carol Stream',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9125,
    longitude: -88.1348,
    postal_code: '60197'
  },
  '60199': {
    country: 'US',
    city: 'Carol Stream',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8397,
    longitude: -88.0887,
    postal_code: '60199'
  },
  '60201': {
    country: 'US',
    city: 'Evanston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0546,
    longitude: -87.6943,
    postal_code: '60201'
  },
  '60202': {
    country: 'US',
    city: 'Evanston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0302,
    longitude: -87.6865,
    postal_code: '60202'
  },
  '60203': {
    country: 'US',
    city: 'Evanston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0485,
    longitude: -87.7176,
    postal_code: '60203'
  },
  '60204': {
    country: 'US',
    city: 'Evanston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0411,
    longitude: -87.6901,
    postal_code: '60204'
  },
  '60208': {
    country: 'US',
    city: 'Evanston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0586,
    longitude: -87.6845,
    postal_code: '60208'
  },
  '60209': {
    country: 'US',
    city: 'Evanston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0497,
    longitude: -87.6794,
    postal_code: '60209'
  },
  '60301': {
    country: 'US',
    city: 'Oak Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8886,
    longitude: -87.7986,
    postal_code: '60301'
  },
  '60302': {
    country: 'US',
    city: 'Oak Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8925,
    longitude: -87.7895,
    postal_code: '60302'
  },
  '60303': {
    country: 'US',
    city: 'Oak Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.885,
    longitude: -87.7845,
    postal_code: '60303'
  },
  '60304': {
    country: 'US',
    city: 'Oak Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8725,
    longitude: -87.7877,
    postal_code: '60304'
  },
  '60305': {
    country: 'US',
    city: 'River Forest',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8951,
    longitude: -87.8159,
    postal_code: '60305'
  },
  '60399': {
    country: 'US',
    city: 'Wood Dale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.9545,
    longitude: -87.9377,
    postal_code: '60399'
  },
  '60401': {
    country: 'US',
    city: 'Beecher',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.3444,
    longitude: -87.6115,
    postal_code: '60401'
  },
  '60402': {
    country: 'US',
    city: 'Berwyn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8347,
    longitude: -87.7914,
    postal_code: '60402'
  },
  '60403': {
    country: 'US',
    city: 'Crest Hill',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5548,
    longitude: -88.0987,
    postal_code: '60403'
  },
  '60404': {
    country: 'US',
    city: 'Shorewood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5076,
    longitude: -88.2169,
    postal_code: '60404'
  },
  '60406': {
    country: 'US',
    city: 'Blue Island',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6582,
    longitude: -87.6795,
    postal_code: '60406'
  },
  '60407': {
    country: 'US',
    city: 'Braceville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Grundy',
    latitude: 41.2288,
    longitude: -88.269,
    postal_code: '60407'
  },
  '60408': {
    country: 'US',
    city: 'Braidwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.2657,
    longitude: -88.2231,
    postal_code: '60408'
  },
  '60409': {
    country: 'US',
    city: 'Calumet City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6153,
    longitude: -87.5483,
    postal_code: '60409'
  },
  '60410': {
    country: 'US',
    city: 'Channahon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.4347,
    longitude: -88.2138,
    postal_code: '60410'
  },
  '60411': {
    country: 'US',
    city: 'Chicago Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5087,
    longitude: -87.5904,
    postal_code: '60411'
  },
  '60412': {
    country: 'US',
    city: 'Chicago Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5061,
    longitude: -87.6356,
    postal_code: '60412'
  },
  '60415': {
    country: 'US',
    city: 'Chicago Ridge',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7017,
    longitude: -87.7774,
    postal_code: '60415'
  },
  '60416': {
    country: 'US',
    city: 'Coal City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Grundy',
    latitude: 41.2908,
    longitude: -88.2823,
    postal_code: '60416'
  },
  '60417': {
    country: 'US',
    city: 'Crete',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.439,
    longitude: -87.6027,
    postal_code: '60417'
  },
  '60418': {
    country: 'US',
    city: 'Crestwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6446,
    longitude: -87.7415,
    postal_code: '60418'
  },
  '60419': {
    country: 'US',
    city: 'Dolton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6257,
    longitude: -87.598,
    postal_code: '60419'
  },
  '60420': {
    country: 'US',
    city: 'Dwight',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 41.0887,
    longitude: -88.4159,
    postal_code: '60420'
  },
  '60421': {
    country: 'US',
    city: 'Elwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.426,
    longitude: -88.0864,
    postal_code: '60421'
  },
  '60422': {
    country: 'US',
    city: 'Flossmoor',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5406,
    longitude: -87.6837,
    postal_code: '60422'
  },
  '60423': {
    country: 'US',
    city: 'Frankfort',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5094,
    longitude: -87.8248,
    postal_code: '60423'
  },
  '60424': {
    country: 'US',
    city: 'Gardner',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Grundy',
    latitude: 41.1775,
    longitude: -88.338,
    postal_code: '60424'
  },
  '60425': {
    country: 'US',
    city: 'Glenwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5467,
    longitude: -87.6126,
    postal_code: '60425'
  },
  '60426': {
    country: 'US',
    city: 'Harvey',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6103,
    longitude: -87.6534,
    postal_code: '60426'
  },
  '60428': {
    country: 'US',
    city: 'Markham',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5998,
    longitude: -87.6906,
    postal_code: '60428'
  },
  '60429': {
    country: 'US',
    city: 'Hazel Crest',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5738,
    longitude: -87.6849,
    postal_code: '60429'
  },
  '60430': {
    country: 'US',
    city: 'Homewood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5556,
    longitude: -87.6616,
    postal_code: '60430'
  },
  '60431': {
    country: 'US',
    city: 'Joliet',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.4712,
    longitude: -87.9391,
    postal_code: '60431'
  },
  '60432': {
    country: 'US',
    city: 'Joliet',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5378,
    longitude: -88.0572,
    postal_code: '60432'
  },
  '60433': {
    country: 'US',
    city: 'Joliet',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5119,
    longitude: -88.0569,
    postal_code: '60433'
  },
  '60434': {
    country: 'US',
    city: 'Joliet',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5254,
    longitude: -88.0842,
    postal_code: '60434'
  },
  '60435': {
    country: 'US',
    city: 'Joliet',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5454,
    longitude: -88.1299,
    postal_code: '60435'
  },
  '60436': {
    country: 'US',
    city: 'Joliet',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.4884,
    longitude: -88.1572,
    postal_code: '60436'
  },
  '60437': {
    country: 'US',
    city: 'Kinsman',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Grundy',
    latitude: 41.1631,
    longitude: -88.5535,
    postal_code: '60437'
  },
  '60438': {
    country: 'US',
    city: 'Lansing',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.566,
    longitude: -87.5446,
    postal_code: '60438'
  },
  '60439': {
    country: 'US',
    city: 'Lemont',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7074,
    longitude: -87.9756,
    postal_code: '60439'
  },
  '60440': {
    country: 'US',
    city: 'Bolingbrook',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.6976,
    longitude: -88.0873,
    postal_code: '60440'
  },
  '60441': {
    country: 'US',
    city: 'Lockport',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.593,
    longitude: -88.0507,
    postal_code: '60441'
  },
  '60442': {
    country: 'US',
    city: 'Manhattan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.4289,
    longitude: -87.9771,
    postal_code: '60442'
  },
  '60443': {
    country: 'US',
    city: 'Matteson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5102,
    longitude: -87.7406,
    postal_code: '60443'
  },
  '60444': {
    country: 'US',
    city: 'Mazon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Grundy',
    latitude: 41.2264,
    longitude: -88.4217,
    postal_code: '60444'
  },
  '60445': {
    country: 'US',
    city: 'Midlothian',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.635,
    longitude: -87.7362,
    postal_code: '60445'
  },
  '60446': {
    country: 'US',
    city: 'Romeoville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.6404,
    longitude: -88.0696,
    postal_code: '60446'
  },
  '60447': {
    country: 'US',
    city: 'Minooka',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Grundy',
    latitude: 41.4615,
    longitude: -88.2786,
    postal_code: '60447'
  },
  '60448': {
    country: 'US',
    city: 'Mokena',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5342,
    longitude: -87.8911,
    postal_code: '60448'
  },
  '60449': {
    country: 'US',
    city: 'Monee',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.4191,
    longitude: -87.7748,
    postal_code: '60449'
  },
  '60450': {
    country: 'US',
    city: 'Morris',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Grundy',
    latitude: 41.3672,
    longitude: -88.4178,
    postal_code: '60450'
  },
  '60451': {
    country: 'US',
    city: 'New Lenox',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5067,
    longitude: -87.9631,
    postal_code: '60451'
  },
  '60452': {
    country: 'US',
    city: 'Oak Forest',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6077,
    longitude: -87.7542,
    postal_code: '60452'
  },
  '60453': {
    country: 'US',
    city: 'Oak Lawn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7143,
    longitude: -87.7516,
    postal_code: '60453'
  },
  '60454': {
    country: 'US',
    city: 'Oak Lawn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60454'
  },
  '60455': {
    country: 'US',
    city: 'Bridgeview',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7431,
    longitude: -87.8066,
    postal_code: '60455'
  },
  '60456': {
    country: 'US',
    city: 'Hometown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7311,
    longitude: -87.7315,
    postal_code: '60456'
  },
  '60457': {
    country: 'US',
    city: 'Hickory Hills',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7262,
    longitude: -87.8289,
    postal_code: '60457'
  },
  '60458': {
    country: 'US',
    city: 'Justice',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7447,
    longitude: -87.8346,
    postal_code: '60458'
  },
  '60459': {
    country: 'US',
    city: 'Burbank',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7447,
    longitude: -87.7699,
    postal_code: '60459'
  },
  '60460': {
    country: 'US',
    city: 'Odell',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 41.0238,
    longitude: -88.5156,
    postal_code: '60460'
  },
  '60461': {
    country: 'US',
    city: 'Olympia Fields',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5134,
    longitude: -87.6742,
    postal_code: '60461'
  },
  '60462': {
    country: 'US',
    city: 'Orland Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6194,
    longitude: -87.8423,
    postal_code: '60462'
  },
  '60463': {
    country: 'US',
    city: 'Palos Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6621,
    longitude: -87.7927,
    postal_code: '60463'
  },
  '60464': {
    country: 'US',
    city: 'Palos Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6624,
    longitude: -87.8521,
    postal_code: '60464'
  },
  '60465': {
    country: 'US',
    city: 'Palos Hills',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7004,
    longitude: -87.8263,
    postal_code: '60465'
  },
  '60466': {
    country: 'US',
    city: 'Park Forest',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.479,
    longitude: -87.6828,
    postal_code: '60466'
  },
  '60467': {
    country: 'US',
    city: 'Orland Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6018,
    longitude: -87.8899,
    postal_code: '60467'
  },
  '60468': {
    country: 'US',
    city: 'Peotone',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.3361,
    longitude: -87.7897,
    postal_code: '60468'
  },
  '60469': {
    country: 'US',
    city: 'Posen',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6277,
    longitude: -87.6872,
    postal_code: '60469'
  },
  '60470': {
    country: 'US',
    city: 'Ransom',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.153,
    longitude: -88.6502,
    postal_code: '60470'
  },
  '60471': {
    country: 'US',
    city: 'Richton Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.4819,
    longitude: -87.7238,
    postal_code: '60471'
  },
  '60472': {
    country: 'US',
    city: 'Robbins',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6423,
    longitude: -87.7089,
    postal_code: '60472'
  },
  '60473': {
    country: 'US',
    city: 'South Holland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5979,
    longitude: -87.5938,
    postal_code: '60473'
  },
  '60474': {
    country: 'US',
    city: 'South Wilmington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Grundy',
    latitude: 41.1728,
    longitude: -88.2767,
    postal_code: '60474'
  },
  '60475': {
    country: 'US',
    city: 'Steger',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.4686,
    longitude: -87.6386,
    postal_code: '60475'
  },
  '60476': {
    country: 'US',
    city: 'Thornton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5727,
    longitude: -87.6078,
    postal_code: '60476'
  },
  '60477': {
    country: 'US',
    city: 'Tinley Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5825,
    longitude: -87.805,
    postal_code: '60477'
  },
  '60478': {
    country: 'US',
    city: 'Country Club Hills',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.5637,
    longitude: -87.7247,
    postal_code: '60478'
  },
  '60479': {
    country: 'US',
    city: 'Verona',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Grundy',
    latitude: 41.2501,
    longitude: -88.517,
    postal_code: '60479'
  },
  '60480': {
    country: 'US',
    city: 'Willow Springs',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7364,
    longitude: -87.8786,
    postal_code: '60480'
  },
  '60481': {
    country: 'US',
    city: 'Wilmington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.3078,
    longitude: -88.1467,
    postal_code: '60481'
  },
  '60482': {
    country: 'US',
    city: 'Worth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6895,
    longitude: -87.7863,
    postal_code: '60482'
  },
  '60484': {
    country: 'US',
    city: 'University Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.4418,
    longitude: -87.7101,
    postal_code: '60484'
  },
  '60487': {
    country: 'US',
    city: 'Tinley Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5636,
    longitude: -87.8342,
    postal_code: '60487'
  },
  '60490': {
    country: 'US',
    city: 'Bolingbrook',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.679,
    longitude: -88.1403,
    postal_code: '60490'
  },
  '60491': {
    country: 'US',
    city: 'Homer Glen',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.6028,
    longitude: -87.9599,
    postal_code: '60491'
  },
  '60499': {
    country: 'US',
    city: 'Bedford Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60499'
  },
  '60501': {
    country: 'US',
    city: 'Summit Argo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7801,
    longitude: -87.824,
    postal_code: '60501'
  },
  '60502': {
    country: 'US',
    city: 'Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.7845,
    longitude: -88.2616,
    postal_code: '60502'
  },
  '60503': {
    country: 'US',
    city: 'Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.7199,
    longitude: -88.2548,
    postal_code: '60503'
  },
  '60504': {
    country: 'US',
    city: 'Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7523,
    longitude: -88.2453,
    postal_code: '60504'
  },
  '60505': {
    country: 'US',
    city: 'Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.7582,
    longitude: -88.2971,
    postal_code: '60505'
  },
  '60506': {
    country: 'US',
    city: 'Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.7664,
    longitude: -88.3446,
    postal_code: '60506'
  },
  '60507': {
    country: 'US',
    city: 'Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.7606,
    longitude: -88.3201,
    postal_code: '60507'
  },
  '60510': {
    country: 'US',
    city: 'Batavia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.8482,
    longitude: -88.3098,
    postal_code: '60510'
  },
  '60511': {
    country: 'US',
    city: 'Big Rock',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.7593,
    longitude: -88.5376,
    postal_code: '60511'
  },
  '60512': {
    country: 'US',
    city: 'Bristol',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kendall',
    latitude: 41.7016,
    longitude: -88.4398,
    postal_code: '60512'
  },
  '60513': {
    country: 'US',
    city: 'Brookfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8217,
    longitude: -87.8492,
    postal_code: '60513'
  },
  '60514': {
    country: 'US',
    city: 'Clarendon Hills',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7965,
    longitude: -87.9569,
    postal_code: '60514'
  },
  '60515': {
    country: 'US',
    city: 'Downers Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8034,
    longitude: -88.0138,
    postal_code: '60515'
  },
  '60516': {
    country: 'US',
    city: 'Downers Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7602,
    longitude: -88.0159,
    postal_code: '60516'
  },
  '60517': {
    country: 'US',
    city: 'Woodridge',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7518,
    longitude: -88.0489,
    postal_code: '60517'
  },
  '60518': {
    country: 'US',
    city: 'Earlville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.5859,
    longitude: -88.9103,
    postal_code: '60518'
  },
  '60519': {
    country: 'US',
    city: 'Eola',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7775,
    longitude: -88.2428,
    postal_code: '60519'
  },
  '60520': {
    country: 'US',
    city: 'Hinckley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 41.7691,
    longitude: -88.6448,
    postal_code: '60520'
  },
  '60521': {
    country: 'US',
    city: 'Hinsdale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8001,
    longitude: -87.9287,
    postal_code: '60521'
  },
  '60522': {
    country: 'US',
    city: 'Hinsdale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8397,
    longitude: -88.0887,
    postal_code: '60522'
  },
  '60523': {
    country: 'US',
    city: 'Oak Brook',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8371,
    longitude: -87.9638,
    postal_code: '60523'
  },
  '60525': {
    country: 'US',
    city: 'La Grange',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7842,
    longitude: -87.8689,
    postal_code: '60525'
  },
  '60526': {
    country: 'US',
    city: 'La Grange Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8318,
    longitude: -87.874,
    postal_code: '60526'
  },
  '60527': {
    country: 'US',
    city: 'Willowbrook',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7447,
    longitude: -87.9334,
    postal_code: '60527'
  },
  '60530': {
    country: 'US',
    city: 'Lee',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.7864,
    longitude: -88.9714,
    postal_code: '60530'
  },
  '60531': {
    country: 'US',
    city: 'Leland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.6066,
    longitude: -88.7716,
    postal_code: '60531'
  },
  '60532': {
    country: 'US',
    city: 'Lisle',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7862,
    longitude: -88.0879,
    postal_code: '60532'
  },
  '60534': {
    country: 'US',
    city: 'Lyons',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.813,
    longitude: -87.8236,
    postal_code: '60534'
  },
  '60536': {
    country: 'US',
    city: 'Millbrook',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kendall',
    latitude: 41.5984,
    longitude: -88.5529,
    postal_code: '60536'
  },
  '60537': {
    country: 'US',
    city: 'Millington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kendall',
    latitude: 41.5614,
    longitude: -88.5975,
    postal_code: '60537'
  },
  '60538': {
    country: 'US',
    city: 'Montgomery',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kendall',
    latitude: 41.7177,
    longitude: -88.332,
    postal_code: '60538'
  },
  '60539': {
    country: 'US',
    city: 'Mooseheart',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.8241,
    longitude: -88.3315,
    postal_code: '60539'
  },
  '60540': {
    country: 'US',
    city: 'Naperville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7662,
    longitude: -88.141,
    postal_code: '60540'
  },
  '60541': {
    country: 'US',
    city: 'Newark',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kendall',
    latitude: 41.5267,
    longitude: -88.527,
    postal_code: '60541'
  },
  '60542': {
    country: 'US',
    city: 'North Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.8089,
    longitude: -88.3274,
    postal_code: '60542'
  },
  '60543': {
    country: 'US',
    city: 'Oswego',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kendall',
    latitude: 41.6849,
    longitude: -88.3453,
    postal_code: '60543'
  },
  '60544': {
    country: 'US',
    city: 'Plainfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.6009,
    longitude: -88.1994,
    postal_code: '60544'
  },
  '60545': {
    country: 'US',
    city: 'Plano',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kendall',
    latitude: 41.667,
    longitude: -88.5384,
    postal_code: '60545'
  },
  '60546': {
    country: 'US',
    city: 'Riverside',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8379,
    longitude: -87.8213,
    postal_code: '60546'
  },
  '60548': {
    country: 'US',
    city: 'Sandwich',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 41.6353,
    longitude: -88.6393,
    postal_code: '60548'
  },
  '60549': {
    country: 'US',
    city: 'Serena',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.4995,
    longitude: -88.7509,
    postal_code: '60549'
  },
  '60550': {
    country: 'US',
    city: 'Shabbona',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 41.7638,
    longitude: -88.8752,
    postal_code: '60550'
  },
  '60551': {
    country: 'US',
    city: 'Sheridan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.5164,
    longitude: -88.6706,
    postal_code: '60551'
  },
  '60552': {
    country: 'US',
    city: 'Somonauk',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 41.6383,
    longitude: -88.6816,
    postal_code: '60552'
  },
  '60553': {
    country: 'US',
    city: 'Steward',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.8475,
    longitude: -89.0151,
    postal_code: '60553'
  },
  '60554': {
    country: 'US',
    city: 'Sugar Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.7741,
    longitude: -88.4397,
    postal_code: '60554'
  },
  '60555': {
    country: 'US',
    city: 'Warrenville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.828,
    longitude: -88.1921,
    postal_code: '60555'
  },
  '60556': {
    country: 'US',
    city: 'Waterman',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DeKalb',
    latitude: 41.7504,
    longitude: -88.7754,
    postal_code: '60556'
  },
  '60557': {
    country: 'US',
    city: 'Wedron',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.4409,
    longitude: -88.7703,
    postal_code: '60557'
  },
  '60558': {
    country: 'US',
    city: 'Western Springs',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8049,
    longitude: -87.8995,
    postal_code: '60558'
  },
  '60559': {
    country: 'US',
    city: 'Westmont',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7728,
    longitude: -87.9757,
    postal_code: '60559'
  },
  '60560': {
    country: 'US',
    city: 'Yorkville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kendall',
    latitude: 41.6387,
    longitude: -88.4438,
    postal_code: '60560'
  },
  '60561': {
    country: 'US',
    city: 'Darien',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7434,
    longitude: -87.9805,
    postal_code: '60561'
  },
  '60563': {
    country: 'US',
    city: 'Naperville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7895,
    longitude: -88.169,
    postal_code: '60563'
  },
  '60564': {
    country: 'US',
    city: 'Naperville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.704,
    longitude: -88.1952,
    postal_code: '60564'
  },
  '60565': {
    country: 'US',
    city: 'Naperville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7328,
    longitude: -88.1282,
    postal_code: '60565'
  },
  '60566': {
    country: 'US',
    city: 'Naperville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8397,
    longitude: -88.0887,
    postal_code: '60566'
  },
  '60567': {
    country: 'US',
    city: 'Naperville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7859,
    longitude: -88.1473,
    postal_code: '60567'
  },
  '60568': {
    country: 'US',
    city: 'Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kane',
    latitude: 41.7606,
    longitude: -88.3201,
    postal_code: '60568'
  },
  '60569': {
    country: 'US',
    city: 'Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8122,
    longitude: -88.2172,
    postal_code: '60569'
  },
  '60572': {
    country: 'US',
    city: 'Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.7606,
    longitude: -88.3201,
    postal_code: '60572'
  },
  '60585': {
    country: 'US',
    city: 'Plainfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.6558,
    longitude: -88.2203,
    postal_code: '60585'
  },
  '60586': {
    country: 'US',
    city: 'Plainfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Will',
    latitude: 41.5642,
    longitude: -88.2178,
    postal_code: '60586'
  },
  '60598': {
    country: 'US',
    city: 'Aurora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8397,
    longitude: -88.0887,
    postal_code: '60598'
  },
  '60599': {
    country: 'US',
    city: 'Fox Valley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'DuPage',
    latitude: 41.8397,
    longitude: -88.0887,
    postal_code: '60599'
  },
  '60601': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8858,
    longitude: -87.6181,
    postal_code: '60601'
  },
  '60602': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8829,
    longitude: -87.6321,
    postal_code: '60602'
  },
  '60603': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8798,
    longitude: -87.6285,
    postal_code: '60603'
  },
  '60604': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8785,
    longitude: -87.633,
    postal_code: '60604'
  },
  '60605': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8713,
    longitude: -87.6277,
    postal_code: '60605'
  },
  '60606': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8868,
    longitude: -87.6386,
    postal_code: '60606'
  },
  '60607': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8721,
    longitude: -87.6578,
    postal_code: '60607'
  },
  '60608': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8515,
    longitude: -87.6694,
    postal_code: '60608'
  },
  '60609': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8097,
    longitude: -87.6533,
    postal_code: '60609'
  },
  '60610': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9033,
    longitude: -87.6336,
    postal_code: '60610'
  },
  '60611': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8971,
    longitude: -87.6223,
    postal_code: '60611'
  },
  '60612': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8805,
    longitude: -87.6873,
    postal_code: '60612'
  },
  '60613': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9543,
    longitude: -87.6575,
    postal_code: '60613'
  },
  '60614': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9229,
    longitude: -87.6483,
    postal_code: '60614'
  },
  '60615': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8022,
    longitude: -87.6006,
    postal_code: '60615'
  },
  '60616': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8426,
    longitude: -87.6306,
    postal_code: '60616'
  },
  '60617': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7257,
    longitude: -87.556,
    postal_code: '60617'
  },
  '60618': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9464,
    longitude: -87.7042,
    postal_code: '60618'
  },
  '60619': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7458,
    longitude: -87.6054,
    postal_code: '60619'
  },
  '60620': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7411,
    longitude: -87.6543,
    postal_code: '60620'
  },
  '60621': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.775,
    longitude: -87.6421,
    postal_code: '60621'
  },
  '60622': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9019,
    longitude: -87.6779,
    postal_code: '60622'
  },
  '60623': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.849,
    longitude: -87.7157,
    postal_code: '60623'
  },
  '60624': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8804,
    longitude: -87.7223,
    postal_code: '60624'
  },
  '60625': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9703,
    longitude: -87.7042,
    postal_code: '60625'
  },
  '60626': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0095,
    longitude: -87.6689,
    postal_code: '60626'
  },
  '60628': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6934,
    longitude: -87.6243,
    postal_code: '60628'
  },
  '60629': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7781,
    longitude: -87.7069,
    postal_code: '60629'
  },
  '60630': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9699,
    longitude: -87.7603,
    postal_code: '60630'
  },
  '60631': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9951,
    longitude: -87.8082,
    postal_code: '60631'
  },
  '60632': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8093,
    longitude: -87.7052,
    postal_code: '60632'
  },
  '60633': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6642,
    longitude: -87.5612,
    postal_code: '60633'
  },
  '60634': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9463,
    longitude: -87.8061,
    postal_code: '60634'
  },
  '60636': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.776,
    longitude: -87.6674,
    postal_code: '60636'
  },
  '60637': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7813,
    longitude: -87.6051,
    postal_code: '60637'
  },
  '60638': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7814,
    longitude: -87.7705,
    postal_code: '60638'
  },
  '60639': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9202,
    longitude: -87.7535,
    postal_code: '60639'
  },
  '60640': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9719,
    longitude: -87.6624,
    postal_code: '60640'
  },
  '60641': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9453,
    longitude: -87.7474,
    postal_code: '60641'
  },
  '60642': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9008,
    longitude: -87.6528,
    postal_code: '60642'
  },
  '60643': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6996,
    longitude: -87.6628,
    postal_code: '60643'
  },
  '60644': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8829,
    longitude: -87.7582,
    postal_code: '60644'
  },
  '60645': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0086,
    longitude: -87.6947,
    postal_code: '60645'
  },
  '60646': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.993,
    longitude: -87.7596,
    postal_code: '60646'
  },
  '60647': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9209,
    longitude: -87.7043,
    postal_code: '60647'
  },
  '60649': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.762,
    longitude: -87.5703,
    postal_code: '60649'
  },
  '60651': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9025,
    longitude: -87.7393,
    postal_code: '60651'
  },
  '60652': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.7454,
    longitude: -87.7135,
    postal_code: '60652'
  },
  '60653': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8196,
    longitude: -87.6126,
    postal_code: '60653'
  },
  '60654': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8923,
    longitude: -87.6373,
    postal_code: '60654'
  },
  '60655': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6948,
    longitude: -87.7038,
    postal_code: '60655'
  },
  '60656': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9735,
    longitude: -87.8658,
    postal_code: '60656'
  },
  '60657': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9399,
    longitude: -87.6528,
    postal_code: '60657'
  },
  '60659': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9972,
    longitude: -87.7166,
    postal_code: '60659'
  },
  '60660': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9909,
    longitude: -87.6629,
    postal_code: '60660'
  },
  '60661': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8814,
    longitude: -87.643,
    postal_code: '60661'
  },
  '60664': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60664'
  },
  '60666': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.85,
    longitude: -87.6501,
    postal_code: '60666'
  },
  '60668': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60668'
  },
  '60669': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60669'
  },
  '60670': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60670'
  },
  '60673': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60673'
  },
  '60674': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60674'
  },
  '60675': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60675'
  },
  '60677': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60677'
  },
  '60678': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60678'
  },
  '60680': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60680'
  },
  '60681': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60681'
  },
  '60682': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.837,
    longitude: -87.685,
    postal_code: '60682'
  },
  '60684': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60684'
  },
  '60685': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60685'
  },
  '60686': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8756,
    longitude: -87.6378,
    postal_code: '60686'
  },
  '60687': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60687'
  },
  '60688': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8724,
    longitude: -87.6688,
    postal_code: '60688'
  },
  '60689': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8745,
    longitude: -87.6353,
    postal_code: '60689'
  },
  '60690': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60690'
  },
  '60691': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60691'
  },
  '60693': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.85,
    longitude: -87.6501,
    postal_code: '60693'
  },
  '60694': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60694'
  },
  '60695': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8839,
    longitude: -87.6317,
    postal_code: '60695'
  },
  '60696': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8684,
    longitude: -87.6649,
    postal_code: '60696'
  },
  '60697': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60697'
  },
  '60699': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60699'
  },
  '60701': {
    country: 'US',
    city: 'Chicago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8119,
    longitude: -87.6873,
    postal_code: '60701'
  },
  '60706': {
    country: 'US',
    city: 'Harwood Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9643,
    longitude: -87.8162,
    postal_code: '60706'
  },
  '60707': {
    country: 'US',
    city: 'Elmwood Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.9232,
    longitude: -87.8185,
    postal_code: '60707'
  },
  '60712': {
    country: 'US',
    city: 'Lincolnwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.008,
    longitude: -87.7361,
    postal_code: '60712'
  },
  '60714': {
    country: 'US',
    city: 'Niles',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 42.0312,
    longitude: -87.8112,
    postal_code: '60714'
  },
  '60803': {
    country: 'US',
    city: 'Alsip',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6721,
    longitude: -87.7357,
    postal_code: '60803'
  },
  '60804': {
    country: 'US',
    city: 'Cicero',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.8378,
    longitude: -87.7602,
    postal_code: '60804'
  },
  '60805': {
    country: 'US',
    city: 'Evergreen Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.722,
    longitude: -87.7024,
    postal_code: '60805'
  },
  '60827': {
    country: 'US',
    city: 'Riverdale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cook',
    latitude: 41.6496,
    longitude: -87.6301,
    postal_code: '60827'
  },
  '60901': {
    country: 'US',
    city: 'Kankakee',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.1166,
    longitude: -87.8696,
    postal_code: '60901'
  },
  '60910': {
    country: 'US',
    city: 'Aroma Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.0798,
    longitude: -87.8114,
    postal_code: '60910'
  },
  '60911': {
    country: 'US',
    city: 'Ashkum',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.8844,
    longitude: -87.9411,
    postal_code: '60911'
  },
  '60912': {
    country: 'US',
    city: 'Beaverville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.9672,
    longitude: -87.6217,
    postal_code: '60912'
  },
  '60913': {
    country: 'US',
    city: 'Bonfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.1573,
    longitude: -88.0619,
    postal_code: '60913'
  },
  '60914': {
    country: 'US',
    city: 'Bourbonnais',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.1661,
    longitude: -87.879,
    postal_code: '60914'
  },
  '60915': {
    country: 'US',
    city: 'Bradley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.1454,
    longitude: -87.8601,
    postal_code: '60915'
  },
  '60917': {
    country: 'US',
    city: 'Buckingham',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.0433,
    longitude: -88.1772,
    postal_code: '60917'
  },
  '60918': {
    country: 'US',
    city: 'Buckley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.6018,
    longitude: -88.0361,
    postal_code: '60918'
  },
  '60919': {
    country: 'US',
    city: 'Cabery',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ford',
    latitude: 40.9819,
    longitude: -88.1921,
    postal_code: '60919'
  },
  '60920': {
    country: 'US',
    city: 'Campus',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 41.0248,
    longitude: -88.3072,
    postal_code: '60920'
  },
  '60921': {
    country: 'US',
    city: 'Chatsworth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.7484,
    longitude: -88.2937,
    postal_code: '60921'
  },
  '60922': {
    country: 'US',
    city: 'Chebanse',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 41.0254,
    longitude: -87.8959,
    postal_code: '60922'
  },
  '60924': {
    country: 'US',
    city: 'Cissna Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.5858,
    longitude: -87.8759,
    postal_code: '60924'
  },
  '60926': {
    country: 'US',
    city: 'Claytonville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.5676,
    longitude: -87.804,
    postal_code: '60926'
  },
  '60927': {
    country: 'US',
    city: 'Clifton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.9394,
    longitude: -87.9202,
    postal_code: '60927'
  },
  '60928': {
    country: 'US',
    city: 'Crescent City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.7417,
    longitude: -87.849,
    postal_code: '60928'
  },
  '60929': {
    country: 'US',
    city: 'Cullom',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.8781,
    longitude: -88.2765,
    postal_code: '60929'
  },
  '60930': {
    country: 'US',
    city: 'Danforth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.8244,
    longitude: -87.9868,
    postal_code: '60930'
  },
  '60931': {
    country: 'US',
    city: 'Donovan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.8891,
    longitude: -87.6046,
    postal_code: '60931'
  },
  '60932': {
    country: 'US',
    city: 'East Lynn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.4633,
    longitude: -87.8056,
    postal_code: '60932'
  },
  '60933': {
    country: 'US',
    city: 'Elliott',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ford',
    latitude: 40.4648,
    longitude: -88.2712,
    postal_code: '60933'
  },
  '60934': {
    country: 'US',
    city: 'Emington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.9692,
    longitude: -88.3589,
    postal_code: '60934'
  },
  '60935': {
    country: 'US',
    city: 'Essex',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.1676,
    longitude: -88.1845,
    postal_code: '60935'
  },
  '60936': {
    country: 'US',
    city: 'Gibson City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ford',
    latitude: 40.4659,
    longitude: -88.3609,
    postal_code: '60936'
  },
  '60938': {
    country: 'US',
    city: 'Gilman',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.768,
    longitude: -87.9933,
    postal_code: '60938'
  },
  '60939': {
    country: 'US',
    city: 'Goodwine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.5673,
    longitude: -87.7845,
    postal_code: '60939'
  },
  '60940': {
    country: 'US',
    city: 'Grant Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.2477,
    longitude: -87.648,
    postal_code: '60940'
  },
  '60941': {
    country: 'US',
    city: 'Herscher',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.0464,
    longitude: -88.0858,
    postal_code: '60941'
  },
  '60942': {
    country: 'US',
    city: 'Hoopeston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.4639,
    longitude: -87.6662,
    postal_code: '60942'
  },
  '60944': {
    country: 'US',
    city: 'Hopkins Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.0634,
    longitude: -87.625,
    postal_code: '60944'
  },
  '60945': {
    country: 'US',
    city: 'Iroquois',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.8269,
    longitude: -87.5847,
    postal_code: '60945'
  },
  '60946': {
    country: 'US',
    city: 'Kempton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ford',
    latitude: 40.9126,
    longitude: -88.209,
    postal_code: '60946'
  },
  '60948': {
    country: 'US',
    city: 'Loda',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.5241,
    longitude: -88.0927,
    postal_code: '60948'
  },
  '60949': {
    country: 'US',
    city: 'Ludlow',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.3747,
    longitude: -88.138,
    postal_code: '60949'
  },
  '60950': {
    country: 'US',
    city: 'Manteno',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.2514,
    longitude: -87.8468,
    postal_code: '60950'
  },
  '60951': {
    country: 'US',
    city: 'Martinton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.9052,
    longitude: -87.7443,
    postal_code: '60951'
  },
  '60952': {
    country: 'US',
    city: 'Melvin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ford',
    latitude: 40.5714,
    longitude: -88.2551,
    postal_code: '60952'
  },
  '60953': {
    country: 'US',
    city: 'Milford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.6293,
    longitude: -87.6853,
    postal_code: '60953'
  },
  '60954': {
    country: 'US',
    city: 'Momence',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.1593,
    longitude: -87.6575,
    postal_code: '60954'
  },
  '60955': {
    country: 'US',
    city: 'Onarga',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.712,
    longitude: -87.9958,
    postal_code: '60955'
  },
  '60956': {
    country: 'US',
    city: 'Papineau',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.967,
    longitude: -87.7161,
    postal_code: '60956'
  },
  '60957': {
    country: 'US',
    city: 'Paxton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ford',
    latitude: 40.4565,
    longitude: -88.099,
    postal_code: '60957'
  },
  '60958': {
    country: 'US',
    city: 'Pembroke Township',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.0646,
    longitude: -87.5917,
    postal_code: '60958'
  },
  '60959': {
    country: 'US',
    city: 'Piper City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ford',
    latitude: 40.7556,
    longitude: -88.1873,
    postal_code: '60959'
  },
  '60960': {
    country: 'US',
    city: 'Rankin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.4559,
    longitude: -87.8884,
    postal_code: '60960'
  },
  '60961': {
    country: 'US',
    city: 'Reddick',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.1005,
    longitude: -88.2089,
    postal_code: '60961'
  },
  '60962': {
    country: 'US',
    city: 'Roberts',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ford',
    latitude: 40.6193,
    longitude: -88.1804,
    postal_code: '60962'
  },
  '60963': {
    country: 'US',
    city: 'Rossville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.3625,
    longitude: -87.6692,
    postal_code: '60963'
  },
  '60964': {
    country: 'US',
    city: 'Saint Anne',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.0487,
    longitude: -87.6564,
    postal_code: '60964'
  },
  '60966': {
    country: 'US',
    city: 'Sheldon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.7803,
    longitude: -87.5736,
    postal_code: '60966'
  },
  '60967': {
    country: 'US',
    city: 'Stockland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.6145,
    longitude: -87.5928,
    postal_code: '60967'
  },
  '60968': {
    country: 'US',
    city: 'Thawville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.684,
    longitude: -88.0999,
    postal_code: '60968'
  },
  '60969': {
    country: 'US',
    city: 'Union Hill',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Kankakee',
    latitude: 41.1088,
    longitude: -88.1465,
    postal_code: '60969'
  },
  '60970': {
    country: 'US',
    city: 'Watseka',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.7734,
    longitude: -87.7309,
    postal_code: '60970'
  },
  '60973': {
    country: 'US',
    city: 'Wellington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.5339,
    longitude: -87.6561,
    postal_code: '60973'
  },
  '60974': {
    country: 'US',
    city: 'Woodland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Iroquois',
    latitude: 40.7151,
    longitude: -87.7307,
    postal_code: '60974'
  },
  '61001': {
    country: 'US',
    city: 'Apple River',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jo Daviess',
    latitude: 42.4714,
    longitude: -90.1201,
    postal_code: '61001'
  },
  '61006': {
    country: 'US',
    city: 'Ashton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.8643,
    longitude: -89.2086,
    postal_code: '61006'
  },
  '61007': {
    country: 'US',
    city: 'Baileyville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.1905,
    longitude: -89.5939,
    postal_code: '61007'
  },
  '61008': {
    country: 'US',
    city: 'Belvidere',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Boone',
    latitude: 42.2595,
    longitude: -88.8509,
    postal_code: '61008'
  },
  '61010': {
    country: 'US',
    city: 'Byron',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.1292,
    longitude: -89.2659,
    postal_code: '61010'
  },
  '61011': {
    country: 'US',
    city: 'Caledonia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Boone',
    latitude: 42.3835,
    longitude: -88.9185,
    postal_code: '61011'
  },
  '61012': {
    country: 'US',
    city: 'Capron',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Boone',
    latitude: 42.4087,
    longitude: -88.7465,
    postal_code: '61012'
  },
  '61013': {
    country: 'US',
    city: 'Cedarville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.3761,
    longitude: -89.6365,
    postal_code: '61013'
  },
  '61014': {
    country: 'US',
    city: 'Chadwick',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Carroll',
    latitude: 41.9962,
    longitude: -89.8963,
    postal_code: '61014'
  },
  '61015': {
    country: 'US',
    city: 'Chana',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 41.9933,
    longitude: -89.2117,
    postal_code: '61015'
  },
  '61016': {
    country: 'US',
    city: 'Cherry Valley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2206,
    longitude: -88.9619,
    postal_code: '61016'
  },
  '61018': {
    country: 'US',
    city: 'Dakota',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.4031,
    longitude: -89.5468,
    postal_code: '61018'
  },
  '61019': {
    country: 'US',
    city: 'Davis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.4422,
    longitude: -89.4067,
    postal_code: '61019'
  },
  '61020': {
    country: 'US',
    city: 'Davis Junction',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.0979,
    longitude: -89.0838,
    postal_code: '61020'
  },
  '61021': {
    country: 'US',
    city: 'Dixon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.8478,
    longitude: -89.4893,
    postal_code: '61021'
  },
  '61024': {
    country: 'US',
    city: 'Durand',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.4337,
    longitude: -89.3094,
    postal_code: '61024'
  },
  '61025': {
    country: 'US',
    city: 'East Dubuque',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jo Daviess',
    latitude: 42.4875,
    longitude: -90.6046,
    postal_code: '61025'
  },
  '61027': {
    country: 'US',
    city: 'Eleroy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.332,
    longitude: -89.7612,
    postal_code: '61027'
  },
  '61028': {
    country: 'US',
    city: 'Elizabeth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jo Daviess',
    latitude: 42.3089,
    longitude: -90.1986,
    postal_code: '61028'
  },
  '61030': {
    country: 'US',
    city: 'Forreston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.1229,
    longitude: -89.5831,
    postal_code: '61030'
  },
  '61031': {
    country: 'US',
    city: 'Franklin Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.858,
    longitude: -89.3171,
    postal_code: '61031'
  },
  '61032': {
    country: 'US',
    city: 'Freeport',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.2991,
    longitude: -89.6345,
    postal_code: '61032'
  },
  '61036': {
    country: 'US',
    city: 'Galena',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jo Daviess',
    latitude: 42.4182,
    longitude: -90.4195,
    postal_code: '61036'
  },
  '61037': {
    country: 'US',
    city: 'Galt',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.7865,
    longitude: -89.761,
    postal_code: '61037'
  },
  '61038': {
    country: 'US',
    city: 'Garden Prairie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Boone',
    latitude: 42.251,
    longitude: -88.7437,
    postal_code: '61038'
  },
  '61039': {
    country: 'US',
    city: 'German Valley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.2176,
    longitude: -89.4712,
    postal_code: '61039'
  },
  '61041': {
    country: 'US',
    city: 'Hanover',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jo Daviess',
    latitude: 42.2594,
    longitude: -90.2897,
    postal_code: '61041'
  },
  '61042': {
    country: 'US',
    city: 'Harmon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.6973,
    longitude: -89.5695,
    postal_code: '61042'
  },
  '61043': {
    country: 'US',
    city: 'Holcomb',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.0647,
    longitude: -89.0957,
    postal_code: '61043'
  },
  '61044': {
    country: 'US',
    city: 'Kent',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.3155,
    longitude: -89.9195,
    postal_code: '61044'
  },
  '61046': {
    country: 'US',
    city: 'Lanark',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Carroll',
    latitude: 42.0935,
    longitude: -89.8247,
    postal_code: '61046'
  },
  '61047': {
    country: 'US',
    city: 'Leaf River',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.1532,
    longitude: -89.3959,
    postal_code: '61047'
  },
  '61048': {
    country: 'US',
    city: 'Lena',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.3791,
    longitude: -89.8253,
    postal_code: '61048'
  },
  '61049': {
    country: 'US',
    city: 'Lindenwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.0507,
    longitude: -89.034,
    postal_code: '61049'
  },
  '61050': {
    country: 'US',
    city: 'Mc Connell',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.4281,
    longitude: -89.7643,
    postal_code: '61050'
  },
  '61051': {
    country: 'US',
    city: 'Milledgeville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Carroll',
    latitude: 41.9674,
    longitude: -89.7801,
    postal_code: '61051'
  },
  '61052': {
    country: 'US',
    city: 'Monroe Center',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.105,
    longitude: -89.0169,
    postal_code: '61052'
  },
  '61053': {
    country: 'US',
    city: 'Mount Carroll',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Carroll',
    latitude: 42.1053,
    longitude: -89.9845,
    postal_code: '61053'
  },
  '61054': {
    country: 'US',
    city: 'Mount Morris',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.0479,
    longitude: -89.4346,
    postal_code: '61054'
  },
  '61057': {
    country: 'US',
    city: 'Nachusa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.8316,
    longitude: -89.3896,
    postal_code: '61057'
  },
  '61059': {
    country: 'US',
    city: 'Nora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jo Daviess',
    latitude: 42.4558,
    longitude: -89.9454,
    postal_code: '61059'
  },
  '61060': {
    country: 'US',
    city: 'Orangeville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.4728,
    longitude: -89.6448,
    postal_code: '61060'
  },
  '61061': {
    country: 'US',
    city: 'Oregon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.0095,
    longitude: -89.3444,
    postal_code: '61061'
  },
  '61062': {
    country: 'US',
    city: 'Pearl City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.261,
    longitude: -89.8393,
    postal_code: '61062'
  },
  '61063': {
    country: 'US',
    city: 'Pecatonica',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3051,
    longitude: -89.3472,
    postal_code: '61063'
  },
  '61064': {
    country: 'US',
    city: 'Polo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 41.989,
    longitude: -89.5984,
    postal_code: '61064'
  },
  '61065': {
    country: 'US',
    city: 'Poplar Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Boone',
    latitude: 42.3594,
    longitude: -88.8428,
    postal_code: '61065'
  },
  '61067': {
    country: 'US',
    city: 'Ridott',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.2996,
    longitude: -89.4627,
    postal_code: '61067'
  },
  '61068': {
    country: 'US',
    city: 'Rochelle',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 41.9282,
    longitude: -89.071,
    postal_code: '61068'
  },
  '61070': {
    country: 'US',
    city: 'Rock City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.4103,
    longitude: -89.4759,
    postal_code: '61070'
  },
  '61071': {
    country: 'US',
    city: 'Rock Falls',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.7665,
    longitude: -89.6925,
    postal_code: '61071'
  },
  '61072': {
    country: 'US',
    city: 'Rockton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.4544,
    longitude: -89.0887,
    postal_code: '61072'
  },
  '61073': {
    country: 'US',
    city: 'Roscoe',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.4217,
    longitude: -88.9943,
    postal_code: '61073'
  },
  '61074': {
    country: 'US',
    city: 'Savanna',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Carroll',
    latitude: 42.0956,
    longitude: -90.1401,
    postal_code: '61074'
  },
  '61075': {
    country: 'US',
    city: 'Scales Mound',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jo Daviess',
    latitude: 42.4715,
    longitude: -90.258,
    postal_code: '61075'
  },
  '61077': {
    country: 'US',
    city: 'Seward',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2368,
    longitude: -89.358,
    postal_code: '61077'
  },
  '61078': {
    country: 'US',
    city: 'Shannon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Carroll',
    latitude: 42.161,
    longitude: -89.7481,
    postal_code: '61078'
  },
  '61079': {
    country: 'US',
    city: 'Shirland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.4445,
    longitude: -89.1976,
    postal_code: '61079'
  },
  '61080': {
    country: 'US',
    city: 'South Beloit',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.4837,
    longitude: -89.0298,
    postal_code: '61080'
  },
  '61081': {
    country: 'US',
    city: 'Sterling',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.8055,
    longitude: -89.7054,
    postal_code: '61081'
  },
  '61084': {
    country: 'US',
    city: 'Stillman Valley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 42.1183,
    longitude: -89.1898,
    postal_code: '61084'
  },
  '61085': {
    country: 'US',
    city: 'Stockton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jo Daviess',
    latitude: 42.3492,
    longitude: -90.0202,
    postal_code: '61085'
  },
  '61087': {
    country: 'US',
    city: 'Warren',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jo Daviess',
    latitude: 42.489,
    longitude: -89.986,
    postal_code: '61087'
  },
  '61088': {
    country: 'US',
    city: 'Winnebago',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2727,
    longitude: -89.2373,
    postal_code: '61088'
  },
  '61089': {
    country: 'US',
    city: 'Winslow',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stephenson',
    latitude: 42.4838,
    longitude: -89.806,
    postal_code: '61089'
  },
  '61091': {
    country: 'US',
    city: 'Woosung',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ogle',
    latitude: 41.9034,
    longitude: -89.5409,
    postal_code: '61091'
  },
  '61101': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2922,
    longitude: -89.1161,
    postal_code: '61101'
  },
  '61102': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2547,
    longitude: -89.1247,
    postal_code: '61102'
  },
  '61103': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.301,
    longitude: -89.0833,
    postal_code: '61103'
  },
  '61104': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2554,
    longitude: -89.0768,
    postal_code: '61104'
  },
  '61105': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2711,
    longitude: -89.094,
    postal_code: '61105'
  },
  '61106': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3254,
    longitude: -89.1705,
    postal_code: '61106'
  },
  '61107': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2786,
    longitude: -89.0361,
    postal_code: '61107'
  },
  '61108': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2514,
    longitude: -89.0235,
    postal_code: '61108'
  },
  '61109': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2166,
    longitude: -89.0512,
    postal_code: '61109'
  },
  '61110': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3254,
    longitude: -89.1705,
    postal_code: '61110'
  },
  '61111': {
    country: 'US',
    city: 'Loves Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3295,
    longitude: -89.0335,
    postal_code: '61111'
  },
  '61112': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.2456,
    longitude: -88.9704,
    postal_code: '61112'
  },
  '61114': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3185,
    longitude: -88.9972,
    postal_code: '61114'
  },
  '61115': {
    country: 'US',
    city: 'Machesney Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3545,
    longitude: -89.0397,
    postal_code: '61115'
  },
  '61125': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3254,
    longitude: -89.1705,
    postal_code: '61125'
  },
  '61126': {
    country: 'US',
    city: 'Rockford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3254,
    longitude: -89.1705,
    postal_code: '61126'
  },
  '61130': {
    country: 'US',
    city: 'Loves Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3254,
    longitude: -89.1705,
    postal_code: '61130'
  },
  '61131': {
    country: 'US',
    city: 'Loves Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3254,
    longitude: -89.1705,
    postal_code: '61131'
  },
  '61132': {
    country: 'US',
    city: 'Loves Park',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Winnebago',
    latitude: 42.3254,
    longitude: -89.1705,
    postal_code: '61132'
  },
  '61201': {
    country: 'US',
    city: 'Rock Island',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.4913,
    longitude: -90.5648,
    postal_code: '61201'
  },
  '61204': {
    country: 'US',
    city: 'Rock Island',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.5549,
    longitude: -90.616,
    postal_code: '61204'
  },
  '61230': {
    country: 'US',
    city: 'Albany',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.7659,
    longitude: -90.2081,
    postal_code: '61230'
  },
  '61231': {
    country: 'US',
    city: 'Aledo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.2008,
    longitude: -90.7416,
    postal_code: '61231'
  },
  '61232': {
    country: 'US',
    city: 'Andalusia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.4392,
    longitude: -90.7176,
    postal_code: '61232'
  },
  '61233': {
    country: 'US',
    city: 'Andover',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.2954,
    longitude: -90.2905,
    postal_code: '61233'
  },
  '61234': {
    country: 'US',
    city: 'Annawan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.398,
    longitude: -89.9129,
    postal_code: '61234'
  },
  '61235': {
    country: 'US',
    city: 'Atkinson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.4162,
    longitude: -90.0225,
    postal_code: '61235'
  },
  '61236': {
    country: 'US',
    city: 'Barstow',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.5147,
    longitude: -90.3577,
    postal_code: '61236'
  },
  '61237': {
    country: 'US',
    city: 'Buffalo Prairie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.3368,
    longitude: -90.8522,
    postal_code: '61237'
  },
  '61238': {
    country: 'US',
    city: 'Cambridge',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.3114,
    longitude: -90.1805,
    postal_code: '61238'
  },
  '61239': {
    country: 'US',
    city: 'Carbon Cliff',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.4948,
    longitude: -90.3907,
    postal_code: '61239'
  },
  '61240': {
    country: 'US',
    city: 'Coal Valley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.4351,
    longitude: -90.4652,
    postal_code: '61240'
  },
  '61241': {
    country: 'US',
    city: 'Colona',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.4885,
    longitude: -90.321,
    postal_code: '61241'
  },
  '61242': {
    country: 'US',
    city: 'Cordova',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.6928,
    longitude: -90.3071,
    postal_code: '61242'
  },
  '61243': {
    country: 'US',
    city: 'Deer Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.6316,
    longitude: -89.6972,
    postal_code: '61243'
  },
  '61244': {
    country: 'US',
    city: 'East Moline',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.5118,
    longitude: -90.4321,
    postal_code: '61244'
  },
  '61250': {
    country: 'US',
    city: 'Erie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.656,
    longitude: -90.0843,
    postal_code: '61250'
  },
  '61251': {
    country: 'US',
    city: 'Fenton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.7285,
    longitude: -90.0457,
    postal_code: '61251'
  },
  '61252': {
    country: 'US',
    city: 'Fulton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.8522,
    longitude: -90.1507,
    postal_code: '61252'
  },
  '61254': {
    country: 'US',
    city: 'Geneseo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.4688,
    longitude: -90.1711,
    postal_code: '61254'
  },
  '61256': {
    country: 'US',
    city: 'Hampton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.5559,
    longitude: -90.4093,
    postal_code: '61256'
  },
  '61257': {
    country: 'US',
    city: 'Hillsdale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.5929,
    longitude: -90.2263,
    postal_code: '61257'
  },
  '61258': {
    country: 'US',
    city: 'Hooppole',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.522,
    longitude: -89.9122,
    postal_code: '61258'
  },
  '61259': {
    country: 'US',
    city: 'Illinois City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.3892,
    longitude: -90.8925,
    postal_code: '61259'
  },
  '61260': {
    country: 'US',
    city: 'Joy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.2262,
    longitude: -90.8518,
    postal_code: '61260'
  },
  '61261': {
    country: 'US',
    city: 'Lyndon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.7199,
    longitude: -89.9169,
    postal_code: '61261'
  },
  '61262': {
    country: 'US',
    city: 'Lynn Center',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.2888,
    longitude: -90.3304,
    postal_code: '61262'
  },
  '61263': {
    country: 'US',
    city: 'Matherville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.2599,
    longitude: -90.6128,
    postal_code: '61263'
  },
  '61264': {
    country: 'US',
    city: 'Milan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.4262,
    longitude: -90.5739,
    postal_code: '61264'
  },
  '61265': {
    country: 'US',
    city: 'Moline',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.4906,
    longitude: -90.498,
    postal_code: '61265'
  },
  '61266': {
    country: 'US',
    city: 'Moline',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.5067,
    longitude: -90.5151,
    postal_code: '61266'
  },
  '61270': {
    country: 'US',
    city: 'Morrison',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.8167,
    longitude: -89.969,
    postal_code: '61270'
  },
  '61272': {
    country: 'US',
    city: 'New Boston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.2153,
    longitude: -90.9879,
    postal_code: '61272'
  },
  '61273': {
    country: 'US',
    city: 'Orion',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.3634,
    longitude: -90.3849,
    postal_code: '61273'
  },
  '61274': {
    country: 'US',
    city: 'Osco',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.3637,
    longitude: -90.2681,
    postal_code: '61274'
  },
  '61275': {
    country: 'US',
    city: 'Port Byron',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.6013,
    longitude: -90.3263,
    postal_code: '61275'
  },
  '61276': {
    country: 'US',
    city: 'Preemption',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.2996,
    longitude: -90.5866,
    postal_code: '61276'
  },
  '61277': {
    country: 'US',
    city: 'Prophetstown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.6312,
    longitude: -89.9467,
    postal_code: '61277'
  },
  '61278': {
    country: 'US',
    city: 'Rapids City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.5817,
    longitude: -90.3435,
    postal_code: '61278'
  },
  '61279': {
    country: 'US',
    city: 'Reynolds',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.3277,
    longitude: -90.6384,
    postal_code: '61279'
  },
  '61281': {
    country: 'US',
    city: 'Sherrard',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.3027,
    longitude: -90.4939,
    postal_code: '61281'
  },
  '61282': {
    country: 'US',
    city: 'Silvis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.5007,
    longitude: -90.4126,
    postal_code: '61282'
  },
  '61283': {
    country: 'US',
    city: 'Tampico',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Whiteside',
    latitude: 41.6522,
    longitude: -89.7948,
    postal_code: '61283'
  },
  '61284': {
    country: 'US',
    city: 'Taylor Ridge',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.3828,
    longitude: -90.734,
    postal_code: '61284'
  },
  '61285': {
    country: 'US',
    city: 'Thomson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Carroll',
    latitude: 41.9816,
    longitude: -90.0844,
    postal_code: '61285'
  },
  '61299': {
    country: 'US',
    city: 'Rock Island',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Rock Island',
    latitude: 41.5203,
    longitude: -90.5416,
    postal_code: '61299'
  },
  '61301': {
    country: 'US',
    city: 'La Salle',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.3442,
    longitude: -89.0955,
    postal_code: '61301'
  },
  '61310': {
    country: 'US',
    city: 'Amboy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.7042,
    longitude: -89.3472,
    postal_code: '61310'
  },
  '61311': {
    country: 'US',
    city: 'Ancona',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 41.0426,
    longitude: -88.8642,
    postal_code: '61311'
  },
  '61312': {
    country: 'US',
    city: 'Arlington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.4437,
    longitude: -89.2219,
    postal_code: '61312'
  },
  '61313': {
    country: 'US',
    city: 'Blackstone',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 41.0719,
    longitude: -88.6498,
    postal_code: '61313'
  },
  '61314': {
    country: 'US',
    city: 'Buda',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.314,
    longitude: -89.6795,
    postal_code: '61314'
  },
  '61315': {
    country: 'US',
    city: 'Bureau',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.2894,
    longitude: -89.3686,
    postal_code: '61315'
  },
  '61316': {
    country: 'US',
    city: 'Cedar Point',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.2617,
    longitude: -89.1237,
    postal_code: '61316'
  },
  '61317': {
    country: 'US',
    city: 'Cherry',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.4264,
    longitude: -89.2126,
    postal_code: '61317'
  },
  '61318': {
    country: 'US',
    city: 'Compton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.685,
    longitude: -89.0877,
    postal_code: '61318'
  },
  '61319': {
    country: 'US',
    city: 'Cornell',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 41.031,
    longitude: -88.7668,
    postal_code: '61319'
  },
  '61320': {
    country: 'US',
    city: 'Dalzell',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.3547,
    longitude: -89.1705,
    postal_code: '61320'
  },
  '61321': {
    country: 'US',
    city: 'Dana',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 40.9547,
    longitude: -88.9628,
    postal_code: '61321'
  },
  '61322': {
    country: 'US',
    city: 'Depue',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.3092,
    longitude: -89.3252,
    postal_code: '61322'
  },
  '61323': {
    country: 'US',
    city: 'Dover',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.4362,
    longitude: -89.396,
    postal_code: '61323'
  },
  '61324': {
    country: 'US',
    city: 'Eldena',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.7711,
    longitude: -89.4123,
    postal_code: '61324'
  },
  '61325': {
    country: 'US',
    city: 'Grand Ridge',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.2386,
    longitude: -88.8168,
    postal_code: '61325'
  },
  '61326': {
    country: 'US',
    city: 'Granville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Putnam',
    latitude: 41.2642,
    longitude: -89.225,
    postal_code: '61326'
  },
  '61327': {
    country: 'US',
    city: 'Hennepin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Putnam',
    latitude: 41.2352,
    longitude: -89.3218,
    postal_code: '61327'
  },
  '61328': {
    country: 'US',
    city: 'Kasbeer',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.505,
    longitude: -89.4634,
    postal_code: '61328'
  },
  '61329': {
    country: 'US',
    city: 'Ladd',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.3825,
    longitude: -89.219,
    postal_code: '61329'
  },
  '61330': {
    country: 'US',
    city: 'La Moille',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.5376,
    longitude: -89.297,
    postal_code: '61330'
  },
  '61331': {
    country: 'US',
    city: 'Lee Center',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.7488,
    longitude: -89.2827,
    postal_code: '61331'
  },
  '61332': {
    country: 'US',
    city: 'Leonore',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.1895,
    longitude: -88.9806,
    postal_code: '61332'
  },
  '61333': {
    country: 'US',
    city: 'Long Point',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.9896,
    longitude: -88.8811,
    postal_code: '61333'
  },
  '61334': {
    country: 'US',
    city: 'Lostant',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.145,
    longitude: -89.075,
    postal_code: '61334'
  },
  '61335': {
    country: 'US',
    city: 'Mc Nabb',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Putnam',
    latitude: 41.173,
    longitude: -89.2187,
    postal_code: '61335'
  },
  '61336': {
    country: 'US',
    city: 'Magnolia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Putnam',
    latitude: 41.1164,
    longitude: -89.227,
    postal_code: '61336'
  },
  '61337': {
    country: 'US',
    city: 'Malden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.4245,
    longitude: -89.3693,
    postal_code: '61337'
  },
  '61338': {
    country: 'US',
    city: 'Manlius',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.4555,
    longitude: -89.6697,
    postal_code: '61338'
  },
  '61340': {
    country: 'US',
    city: 'Mark',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Putnam',
    latitude: 41.266,
    longitude: -89.2491,
    postal_code: '61340'
  },
  '61341': {
    country: 'US',
    city: 'Marseilles',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.3302,
    longitude: -88.6947,
    postal_code: '61341'
  },
  '61342': {
    country: 'US',
    city: 'Mendota',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.5443,
    longitude: -89.1083,
    postal_code: '61342'
  },
  '61344': {
    country: 'US',
    city: 'Mineral',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.4036,
    longitude: -89.8201,
    postal_code: '61344'
  },
  '61345': {
    country: 'US',
    city: 'Neponset',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.2905,
    longitude: -89.7944,
    postal_code: '61345'
  },
  '61346': {
    country: 'US',
    city: 'New Bedford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.5122,
    longitude: -89.7199,
    postal_code: '61346'
  },
  '61348': {
    country: 'US',
    city: 'Oglesby',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.2928,
    longitude: -89.0553,
    postal_code: '61348'
  },
  '61349': {
    country: 'US',
    city: 'Ohio',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.5371,
    longitude: -89.4574,
    postal_code: '61349'
  },
  '61350': {
    country: 'US',
    city: 'Ottawa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.3526,
    longitude: -88.8416,
    postal_code: '61350'
  },
  '61353': {
    country: 'US',
    city: 'Paw Paw',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.6852,
    longitude: -88.9674,
    postal_code: '61353'
  },
  '61354': {
    country: 'US',
    city: 'Peru',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.333,
    longitude: -89.1265,
    postal_code: '61354'
  },
  '61356': {
    country: 'US',
    city: 'Princeton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.3629,
    longitude: -89.427,
    postal_code: '61356'
  },
  '61358': {
    country: 'US',
    city: 'Rutland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 40.9844,
    longitude: -89.0388,
    postal_code: '61358'
  },
  '61359': {
    country: 'US',
    city: 'Seatonville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.3613,
    longitude: -89.2691,
    postal_code: '61359'
  },
  '61360': {
    country: 'US',
    city: 'Seneca',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.3152,
    longitude: -88.61,
    postal_code: '61360'
  },
  '61361': {
    country: 'US',
    city: 'Sheffield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.3949,
    longitude: -89.7115,
    postal_code: '61361'
  },
  '61362': {
    country: 'US',
    city: 'Spring Valley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.3279,
    longitude: -89.2042,
    postal_code: '61362'
  },
  '61363': {
    country: 'US',
    city: 'Standard',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Putnam',
    latitude: 41.2567,
    longitude: -89.1778,
    postal_code: '61363'
  },
  '61364': {
    country: 'US',
    city: 'Streator',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.1225,
    longitude: -88.8307,
    postal_code: '61364'
  },
  '61367': {
    country: 'US',
    city: 'Sublette',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.6331,
    longitude: -89.2354,
    postal_code: '61367'
  },
  '61368': {
    country: 'US',
    city: 'Tiskilwa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.2891,
    longitude: -89.508,
    postal_code: '61368'
  },
  '61369': {
    country: 'US',
    city: 'Toluca',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marshall',
    latitude: 41.0046,
    longitude: -89.1348,
    postal_code: '61369'
  },
  '61370': {
    country: 'US',
    city: 'Tonica',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.2327,
    longitude: -89.089,
    postal_code: '61370'
  },
  '61371': {
    country: 'US',
    city: 'Triumph',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.4991,
    longitude: -89.0219,
    postal_code: '61371'
  },
  '61372': {
    country: 'US',
    city: 'Troy Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.4678,
    longitude: -89.0831,
    postal_code: '61372'
  },
  '61373': {
    country: 'US',
    city: 'Utica',
    state: 'Illinois',
    state_short: 'IL',
    county: 'LaSalle',
    latitude: 41.363,
    longitude: -89.0008,
    postal_code: '61373'
  },
  '61374': {
    country: 'US',
    city: 'Van Orin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.55,
    longitude: -89.3534,
    postal_code: '61374'
  },
  '61375': {
    country: 'US',
    city: 'Varna',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marshall',
    latitude: 41.0327,
    longitude: -89.2483,
    postal_code: '61375'
  },
  '61376': {
    country: 'US',
    city: 'Walnut',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.5394,
    longitude: -89.6092,
    postal_code: '61376'
  },
  '61377': {
    country: 'US',
    city: 'Wenona',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marshall',
    latitude: 41.0548,
    longitude: -89.0416,
    postal_code: '61377'
  },
  '61378': {
    country: 'US',
    city: 'West Brooklyn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lee',
    latitude: 41.7292,
    longitude: -89.1909,
    postal_code: '61378'
  },
  '61379': {
    country: 'US',
    city: 'Wyanet',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bureau',
    latitude: 41.3785,
    longitude: -89.5744,
    postal_code: '61379'
  },
  '61401': {
    country: 'US',
    city: 'Galesburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.9521,
    longitude: -90.3698,
    postal_code: '61401'
  },
  '61402': {
    country: 'US',
    city: 'Galesburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.9478,
    longitude: -90.3712,
    postal_code: '61402'
  },
  '61410': {
    country: 'US',
    city: 'Abingdon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.8023,
    longitude: -90.4009,
    postal_code: '61410'
  },
  '61411': {
    country: 'US',
    city: 'Adair',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.3852,
    longitude: -90.5037,
    postal_code: '61411'
  },
  '61412': {
    country: 'US',
    city: 'Alexis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.0521,
    longitude: -90.5436,
    postal_code: '61412'
  },
  '61413': {
    country: 'US',
    city: 'Alpha',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.193,
    longitude: -90.3821,
    postal_code: '61413'
  },
  '61414': {
    country: 'US',
    city: 'Altona',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 41.1128,
    longitude: -90.1598,
    postal_code: '61414'
  },
  '61415': {
    country: 'US',
    city: 'Avon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.6549,
    longitude: -90.4461,
    postal_code: '61415'
  },
  '61416': {
    country: 'US',
    city: 'Bardolph',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.495,
    longitude: -90.5646,
    postal_code: '61416'
  },
  '61417': {
    country: 'US',
    city: 'Berwick',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Warren',
    latitude: 40.7799,
    longitude: -90.5059,
    postal_code: '61417'
  },
  '61418': {
    country: 'US',
    city: 'Biggsville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henderson',
    latitude: 40.8531,
    longitude: -90.8561,
    postal_code: '61418'
  },
  '61419': {
    country: 'US',
    city: 'Bishop Hill',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.1977,
    longitude: -90.1183,
    postal_code: '61419'
  },
  '61420': {
    country: 'US',
    city: 'Blandinsville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.5516,
    longitude: -90.8595,
    postal_code: '61420'
  },
  '61421': {
    country: 'US',
    city: 'Bradford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stark',
    latitude: 41.1532,
    longitude: -89.6521,
    postal_code: '61421'
  },
  '61422': {
    country: 'US',
    city: 'Bushnell',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.5539,
    longitude: -90.506,
    postal_code: '61422'
  },
  '61423': {
    country: 'US',
    city: 'Cameron',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Warren',
    latitude: 40.889,
    longitude: -90.5001,
    postal_code: '61423'
  },
  '61424': {
    country: 'US',
    city: 'Camp Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marshall',
    latitude: 41.078,
    longitude: -89.6332,
    postal_code: '61424'
  },
  '61425': {
    country: 'US',
    city: 'Carman',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henderson',
    latitude: 40.7551,
    longitude: -91.0564,
    postal_code: '61425'
  },
  '61426': {
    country: 'US',
    city: 'Castleton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stark',
    latitude: 41.1183,
    longitude: -89.7071,
    postal_code: '61426'
  },
  '61427': {
    country: 'US',
    city: 'Cuba',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.4995,
    longitude: -90.1811,
    postal_code: '61427'
  },
  '61428': {
    country: 'US',
    city: 'Dahinda',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.9551,
    longitude: -90.1398,
    postal_code: '61428'
  },
  '61430': {
    country: 'US',
    city: 'East Galesburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.9402,
    longitude: -90.3109,
    postal_code: '61430'
  },
  '61431': {
    country: 'US',
    city: 'Ellisville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.6047,
    longitude: -90.2875,
    postal_code: '61431'
  },
  '61432': {
    country: 'US',
    city: 'Fairview',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.6442,
    longitude: -90.1653,
    postal_code: '61432'
  },
  '61433': {
    country: 'US',
    city: 'Fiatt',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.5603,
    longitude: -90.1798,
    postal_code: '61433'
  },
  '61434': {
    country: 'US',
    city: 'Galva',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.1656,
    longitude: -90.0481,
    postal_code: '61434'
  },
  '61435': {
    country: 'US',
    city: 'Gerlaw',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Warren',
    latitude: 40.9863,
    longitude: -90.5491,
    postal_code: '61435'
  },
  '61436': {
    country: 'US',
    city: 'Gilson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.8765,
    longitude: -90.1747,
    postal_code: '61436'
  },
  '61437': {
    country: 'US',
    city: 'Gladstone',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henderson',
    latitude: 40.8377,
    longitude: -90.9941,
    postal_code: '61437'
  },
  '61438': {
    country: 'US',
    city: 'Good Hope',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.5578,
    longitude: -90.6735,
    postal_code: '61438'
  },
  '61439': {
    country: 'US',
    city: 'Henderson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 41.0276,
    longitude: -90.3575,
    postal_code: '61439'
  },
  '61440': {
    country: 'US',
    city: 'Industry',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.3256,
    longitude: -90.6105,
    postal_code: '61440'
  },
  '61441': {
    country: 'US',
    city: 'Ipava',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.3594,
    longitude: -90.2967,
    postal_code: '61441'
  },
  '61442': {
    country: 'US',
    city: 'Keithsburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.1043,
    longitude: -90.9263,
    postal_code: '61442'
  },
  '61443': {
    country: 'US',
    city: 'Kewanee',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.2411,
    longitude: -89.9274,
    postal_code: '61443'
  },
  '61447': {
    country: 'US',
    city: 'Kirkwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Warren',
    latitude: 40.8638,
    longitude: -90.7457,
    postal_code: '61447'
  },
  '61448': {
    country: 'US',
    city: 'Knoxville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.9107,
    longitude: -90.2871,
    postal_code: '61448'
  },
  '61449': {
    country: 'US',
    city: 'La Fayette',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stark',
    latitude: 41.1095,
    longitude: -89.9575,
    postal_code: '61449'
  },
  '61450': {
    country: 'US',
    city: 'La Harpe',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.5846,
    longitude: -90.9687,
    postal_code: '61450'
  },
  '61451': {
    country: 'US',
    city: 'Laura',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.9335,
    longitude: -89.9349,
    postal_code: '61451'
  },
  '61452': {
    country: 'US',
    city: 'Littleton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Schuyler',
    latitude: 40.2339,
    longitude: -90.619,
    postal_code: '61452'
  },
  '61453': {
    country: 'US',
    city: 'Little York',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Warren',
    latitude: 41.0153,
    longitude: -90.7364,
    postal_code: '61453'
  },
  '61454': {
    country: 'US',
    city: 'Lomax',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henderson',
    latitude: 40.6761,
    longitude: -91.0391,
    postal_code: '61454'
  },
  '61455': {
    country: 'US',
    city: 'Macomb',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.4617,
    longitude: -90.6787,
    postal_code: '61455'
  },
  '61458': {
    country: 'US',
    city: 'Maquon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.7849,
    longitude: -90.2008,
    postal_code: '61458'
  },
  '61459': {
    country: 'US',
    city: 'Marietta',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.4978,
    longitude: -90.3885,
    postal_code: '61459'
  },
  '61460': {
    country: 'US',
    city: 'Media',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henderson',
    latitude: 40.7618,
    longitude: -90.857,
    postal_code: '61460'
  },
  '61462': {
    country: 'US',
    city: 'Monmouth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Warren',
    latitude: 40.9107,
    longitude: -90.6448,
    postal_code: '61462'
  },
  '61465': {
    country: 'US',
    city: 'New Windsor',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.1987,
    longitude: -90.4598,
    postal_code: '61465'
  },
  '61466': {
    country: 'US',
    city: 'North Henderson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.1006,
    longitude: -90.4736,
    postal_code: '61466'
  },
  '61467': {
    country: 'US',
    city: 'Oneida',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 41.0832,
    longitude: -90.2391,
    postal_code: '61467'
  },
  '61468': {
    country: 'US',
    city: 'Ophiem',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.2525,
    longitude: -90.3876,
    postal_code: '61468'
  },
  '61469': {
    country: 'US',
    city: 'Oquawka',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henderson',
    latitude: 40.9442,
    longitude: -90.9302,
    postal_code: '61469'
  },
  '61470': {
    country: 'US',
    city: 'Prairie City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.618,
    longitude: -90.4727,
    postal_code: '61470'
  },
  '61471': {
    country: 'US',
    city: 'Raritan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henderson',
    latitude: 40.6959,
    longitude: -90.8274,
    postal_code: '61471'
  },
  '61472': {
    country: 'US',
    city: 'Rio',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 41.1103,
    longitude: -90.39,
    postal_code: '61472'
  },
  '61473': {
    country: 'US',
    city: 'Roseville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Warren',
    latitude: 40.7238,
    longitude: -90.6514,
    postal_code: '61473'
  },
  '61474': {
    country: 'US',
    city: 'Saint Augustine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.7289,
    longitude: -90.3798,
    postal_code: '61474'
  },
  '61475': {
    country: 'US',
    city: 'Sciota',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.5832,
    longitude: -90.7316,
    postal_code: '61475'
  },
  '61476': {
    country: 'US',
    city: 'Seaton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.0732,
    longitude: -90.8257,
    postal_code: '61476'
  },
  '61477': {
    country: 'US',
    city: 'Smithfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.4855,
    longitude: -90.2856,
    postal_code: '61477'
  },
  '61478': {
    country: 'US',
    city: 'Smithshire',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Warren',
    latitude: 40.7579,
    longitude: -90.7606,
    postal_code: '61478'
  },
  '61479': {
    country: 'US',
    city: 'Speer',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stark',
    latitude: 40.9872,
    longitude: -89.652,
    postal_code: '61479'
  },
  '61480': {
    country: 'US',
    city: 'Stronghurst',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henderson',
    latitude: 40.7523,
    longitude: -90.9257,
    postal_code: '61480'
  },
  '61482': {
    country: 'US',
    city: 'Table Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.3784,
    longitude: -90.4239,
    postal_code: '61482'
  },
  '61483': {
    country: 'US',
    city: 'Toulon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stark',
    latitude: 41.1009,
    longitude: -89.8606,
    postal_code: '61483'
  },
  '61484': {
    country: 'US',
    city: 'Vermont',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.3062,
    longitude: -90.422,
    postal_code: '61484'
  },
  '61485': {
    country: 'US',
    city: 'Victoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 41.0256,
    longitude: -90.0933,
    postal_code: '61485'
  },
  '61486': {
    country: 'US',
    city: 'Viola',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mercer',
    latitude: 41.2024,
    longitude: -90.5936,
    postal_code: '61486'
  },
  '61488': {
    country: 'US',
    city: 'Wataga',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 41.0224,
    longitude: -90.2723,
    postal_code: '61488'
  },
  '61489': {
    country: 'US',
    city: 'Williamsfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.9277,
    longitude: -90.0267,
    postal_code: '61489'
  },
  '61490': {
    country: 'US',
    city: 'Woodhull',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Henry',
    latitude: 41.1849,
    longitude: -90.2833,
    postal_code: '61490'
  },
  '61491': {
    country: 'US',
    city: 'Wyoming',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stark',
    latitude: 41.0599,
    longitude: -89.7782,
    postal_code: '61491'
  },
  '61501': {
    country: 'US',
    city: 'Astoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.2311,
    longitude: -90.3443,
    postal_code: '61501'
  },
  '61516': {
    country: 'US',
    city: 'Benson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Woodford',
    latitude: 40.8306,
    longitude: -89.1165,
    postal_code: '61516'
  },
  '61517': {
    country: 'US',
    city: 'Brimfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.8407,
    longitude: -89.897,
    postal_code: '61517'
  },
  '61519': {
    country: 'US',
    city: 'Bryant',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.4663,
    longitude: -90.091,
    postal_code: '61519'
  },
  '61520': {
    country: 'US',
    city: 'Canton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.5601,
    longitude: -90.0242,
    postal_code: '61520'
  },
  '61523': {
    country: 'US',
    city: 'Chillicothe',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.9013,
    longitude: -89.5068,
    postal_code: '61523'
  },
  '61524': {
    country: 'US',
    city: 'Dunfermline',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.4912,
    longitude: -90.0318,
    postal_code: '61524'
  },
  '61525': {
    country: 'US',
    city: 'Dunlap',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.8444,
    longitude: -89.6397,
    postal_code: '61525'
  },
  '61526': {
    country: 'US',
    city: 'Edelstein',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.9454,
    longitude: -89.5858,
    postal_code: '61526'
  },
  '61528': {
    country: 'US',
    city: 'Edwards',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.779,
    longitude: -89.723,
    postal_code: '61528'
  },
  '61529': {
    country: 'US',
    city: 'Elmwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7726,
    longitude: -89.9289,
    postal_code: '61529'
  },
  '61530': {
    country: 'US',
    city: 'Eureka',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Woodford',
    latitude: 40.7152,
    longitude: -89.2706,
    postal_code: '61530'
  },
  '61531': {
    country: 'US',
    city: 'Farmington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.6832,
    longitude: -90.035,
    postal_code: '61531'
  },
  '61532': {
    country: 'US',
    city: 'Forest City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mason',
    latitude: 40.3594,
    longitude: -89.8334,
    postal_code: '61532'
  },
  '61533': {
    country: 'US',
    city: 'Glasford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.576,
    longitude: -89.8113,
    postal_code: '61533'
  },
  '61534': {
    country: 'US',
    city: 'Green Valley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.4198,
    longitude: -89.6549,
    postal_code: '61534'
  },
  '61535': {
    country: 'US',
    city: 'Groveland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.5925,
    longitude: -89.5345,
    postal_code: '61535'
  },
  '61536': {
    country: 'US',
    city: 'Hanna City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6798,
    longitude: -89.7952,
    postal_code: '61536'
  },
  '61537': {
    country: 'US',
    city: 'Henry',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marshall',
    latitude: 41.1115,
    longitude: -89.3743,
    postal_code: '61537'
  },
  '61539': {
    country: 'US',
    city: 'Kingston Mines',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.5571,
    longitude: -89.7685,
    postal_code: '61539'
  },
  '61540': {
    country: 'US',
    city: 'Lacon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marshall',
    latitude: 41.0216,
    longitude: -89.4008,
    postal_code: '61540'
  },
  '61541': {
    country: 'US',
    city: 'La Rose',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marshall',
    latitude: 40.9823,
    longitude: -89.2354,
    postal_code: '61541'
  },
  '61542': {
    country: 'US',
    city: 'Lewistown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.383,
    longitude: -90.1563,
    postal_code: '61542'
  },
  '61543': {
    country: 'US',
    city: 'Liverpool',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.3902,
    longitude: -90.0026,
    postal_code: '61543'
  },
  '61544': {
    country: 'US',
    city: 'London Mills',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.695,
    longitude: -90.2616,
    postal_code: '61544'
  },
  '61545': {
    country: 'US',
    city: 'Lowpoint',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Woodford',
    latitude: 40.8793,
    longitude: -89.3703,
    postal_code: '61545'
  },
  '61546': {
    country: 'US',
    city: 'Manito',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mason',
    latitude: 40.416,
    longitude: -89.7898,
    postal_code: '61546'
  },
  '61547': {
    country: 'US',
    city: 'Mapleton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6117,
    longitude: -89.7184,
    postal_code: '61547'
  },
  '61548': {
    country: 'US',
    city: 'Metamora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Woodford',
    latitude: 40.7844,
    longitude: -89.4309,
    postal_code: '61548'
  },
  '61550': {
    country: 'US',
    city: 'Morton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.6148,
    longitude: -89.4604,
    postal_code: '61550'
  },
  '61552': {
    country: 'US',
    city: 'Mossville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.818,
    longitude: -89.568,
    postal_code: '61552'
  },
  '61553': {
    country: 'US',
    city: 'Norris',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.6259,
    longitude: -90.0323,
    postal_code: '61553'
  },
  '61554': {
    country: 'US',
    city: 'Pekin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.5674,
    longitude: -89.6243,
    postal_code: '61554'
  },
  '61555': {
    country: 'US',
    city: 'Pekin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.5607,
    longitude: -89.6502,
    postal_code: '61555'
  },
  '61558': {
    country: 'US',
    city: 'Pekin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.5675,
    longitude: -89.6407,
    postal_code: '61558'
  },
  '61559': {
    country: 'US',
    city: 'Princeville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Stark',
    latitude: 40.9093,
    longitude: -89.7723,
    postal_code: '61559'
  },
  '61560': {
    country: 'US',
    city: 'Putnam',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Putnam',
    latitude: 41.1949,
    longitude: -89.4409,
    postal_code: '61560'
  },
  '61561': {
    country: 'US',
    city: 'Roanoke',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Woodford',
    latitude: 40.7956,
    longitude: -89.2093,
    postal_code: '61561'
  },
  '61562': {
    country: 'US',
    city: 'Rome',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.8743,
    longitude: -89.5067,
    postal_code: '61562'
  },
  '61563': {
    country: 'US',
    city: 'Saint David',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fulton',
    latitude: 40.4912,
    longitude: -90.0505,
    postal_code: '61563'
  },
  '61564': {
    country: 'US',
    city: 'South Pekin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.4945,
    longitude: -89.6518,
    postal_code: '61564'
  },
  '61565': {
    country: 'US',
    city: 'Sparland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marshall',
    latitude: 41.0134,
    longitude: -89.4571,
    postal_code: '61565'
  },
  '61567': {
    country: 'US',
    city: 'Topeka',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mason',
    latitude: 40.3303,
    longitude: -89.9312,
    postal_code: '61567'
  },
  '61568': {
    country: 'US',
    city: 'Tremont',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.5053,
    longitude: -89.4833,
    postal_code: '61568'
  },
  '61569': {
    country: 'US',
    city: 'Trivoli',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6795,
    longitude: -89.9135,
    postal_code: '61569'
  },
  '61570': {
    country: 'US',
    city: 'Washburn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marshall',
    latitude: 40.9141,
    longitude: -89.283,
    postal_code: '61570'
  },
  '61571': {
    country: 'US',
    city: 'Washington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.7034,
    longitude: -89.4194,
    postal_code: '61571'
  },
  '61572': {
    country: 'US',
    city: 'Yates City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Knox',
    latitude: 40.7878,
    longitude: -90.0265,
    postal_code: '61572'
  },
  '61601': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6931,
    longitude: -89.5898,
    postal_code: '61601'
  },
  '61602': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6936,
    longitude: -89.589,
    postal_code: '61602'
  },
  '61603': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7132,
    longitude: -89.577,
    postal_code: '61603'
  },
  '61604': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7111,
    longitude: -89.6324,
    postal_code: '61604'
  },
  '61605': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6775,
    longitude: -89.6263,
    postal_code: '61605'
  },
  '61606': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6989,
    longitude: -89.6122,
    postal_code: '61606'
  },
  '61607': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6321,
    longitude: -89.6903,
    postal_code: '61607'
  },
  '61610': {
    country: 'US',
    city: 'Creve Coeur',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.6428,
    longitude: -89.5988,
    postal_code: '61610'
  },
  '61611': {
    country: 'US',
    city: 'East Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.6731,
    longitude: -89.5514,
    postal_code: '61611'
  },
  '61612': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7442,
    longitude: -89.7184,
    postal_code: '61612'
  },
  '61613': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7425,
    longitude: -89.6279,
    postal_code: '61613'
  },
  '61614': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7681,
    longitude: -89.6026,
    postal_code: '61614'
  },
  '61615': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7661,
    longitude: -89.645,
    postal_code: '61615'
  },
  '61616': {
    country: 'US',
    city: 'Peoria Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7473,
    longitude: -89.574,
    postal_code: '61616'
  },
  '61625': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6963,
    longitude: -89.6166,
    postal_code: '61625'
  },
  '61629': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.692,
    longitude: -89.5887,
    postal_code: '61629'
  },
  '61630': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7442,
    longitude: -89.7184,
    postal_code: '61630'
  },
  '61633': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7312,
    longitude: -89.6031,
    postal_code: '61633'
  },
  '61634': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6896,
    longitude: -89.5926,
    postal_code: '61634'
  },
  '61635': {
    country: 'US',
    city: 'East Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.6661,
    longitude: -89.5801,
    postal_code: '61635'
  },
  '61636': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.6999,
    longitude: -89.5951,
    postal_code: '61636'
  },
  '61637': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7025,
    longitude: -89.5898,
    postal_code: '61637'
  },
  '61638': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7969,
    longitude: -89.6111,
    postal_code: '61638'
  },
  '61639': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7098,
    longitude: -89.5636,
    postal_code: '61639'
  },
  '61641': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.64,
    longitude: -89.652,
    postal_code: '61641'
  },
  '61643': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7442,
    longitude: -89.7184,
    postal_code: '61643'
  },
  '61650': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7442,
    longitude: -89.7184,
    postal_code: '61650'
  },
  '61651': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7442,
    longitude: -89.7184,
    postal_code: '61651'
  },
  '61652': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.8767,
    longitude: -89.5091,
    postal_code: '61652'
  },
  '61653': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7442,
    longitude: -89.7184,
    postal_code: '61653'
  },
  '61654': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7442,
    longitude: -89.7184,
    postal_code: '61654'
  },
  '61655': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7442,
    longitude: -89.7184,
    postal_code: '61655'
  },
  '61656': {
    country: 'US',
    city: 'Peoria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Peoria',
    latitude: 40.7442,
    longitude: -89.7184,
    postal_code: '61656'
  },
  '61701': {
    country: 'US',
    city: 'Bloomington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.4783,
    longitude: -88.9893,
    postal_code: '61701'
  },
  '61702': {
    country: 'US',
    city: 'Bloomington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.5192,
    longitude: -88.8643,
    postal_code: '61702'
  },
  '61704': {
    country: 'US',
    city: 'Bloomington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.4705,
    longitude: -88.9433,
    postal_code: '61704'
  },
  '61705': {
    country: 'US',
    city: 'Bloomington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.44,
    longitude: -89.067,
    postal_code: '61705'
  },
  '61709': {
    country: 'US',
    city: 'Bloomington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.4614,
    longitude: -88.953,
    postal_code: '61709'
  },
  '61710': {
    country: 'US',
    city: 'Bloomington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.4777,
    longitude: -88.9542,
    postal_code: '61710'
  },
  '61720': {
    country: 'US',
    city: 'Anchor',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.5441,
    longitude: -88.5266,
    postal_code: '61720'
  },
  '61721': {
    country: 'US',
    city: 'Armington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.3613,
    longitude: -89.3231,
    postal_code: '61721'
  },
  '61722': {
    country: 'US',
    city: 'Arrowsmith',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.412,
    longitude: -88.6296,
    postal_code: '61722'
  },
  '61723': {
    country: 'US',
    city: 'Atlanta',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.2586,
    longitude: -89.23,
    postal_code: '61723'
  },
  '61724': {
    country: 'US',
    city: 'Bellflower',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.3401,
    longitude: -88.5227,
    postal_code: '61724'
  },
  '61725': {
    country: 'US',
    city: 'Carlock',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.6029,
    longitude: -89.1098,
    postal_code: '61725'
  },
  '61726': {
    country: 'US',
    city: 'Chenoa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.7446,
    longitude: -88.7219,
    postal_code: '61726'
  },
  '61727': {
    country: 'US',
    city: 'Clinton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'De Witt',
    latitude: 40.1487,
    longitude: -88.9627,
    postal_code: '61727'
  },
  '61728': {
    country: 'US',
    city: 'Colfax',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.5704,
    longitude: -88.62,
    postal_code: '61728'
  },
  '61729': {
    country: 'US',
    city: 'Congerville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Woodford',
    latitude: 40.6208,
    longitude: -89.1994,
    postal_code: '61729'
  },
  '61730': {
    country: 'US',
    city: 'Cooksville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.536,
    longitude: -88.735,
    postal_code: '61730'
  },
  '61731': {
    country: 'US',
    city: 'Cropsey',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.603,
    longitude: -88.4943,
    postal_code: '61731'
  },
  '61732': {
    country: 'US',
    city: 'Danvers',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.5364,
    longitude: -89.1885,
    postal_code: '61732'
  },
  '61733': {
    country: 'US',
    city: 'Deer Creek',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.6224,
    longitude: -89.3323,
    postal_code: '61733'
  },
  '61734': {
    country: 'US',
    city: 'Delavan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.369,
    longitude: -89.5321,
    postal_code: '61734'
  },
  '61735': {
    country: 'US',
    city: 'Dewitt',
    state: 'Illinois',
    state_short: 'IL',
    county: 'De Witt',
    latitude: 40.1848,
    longitude: -88.7637,
    postal_code: '61735'
  },
  '61736': {
    country: 'US',
    city: 'Downs',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.3989,
    longitude: -88.8686,
    postal_code: '61736'
  },
  '61737': {
    country: 'US',
    city: 'Ellsworth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.4432,
    longitude: -88.7371,
    postal_code: '61737'
  },
  '61738': {
    country: 'US',
    city: 'El Paso',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Woodford',
    latitude: 40.7389,
    longitude: -89.012,
    postal_code: '61738'
  },
  '61739': {
    country: 'US',
    city: 'Fairbury',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.745,
    longitude: -88.5165,
    postal_code: '61739'
  },
  '61740': {
    country: 'US',
    city: 'Flanagan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.879,
    longitude: -88.862,
    postal_code: '61740'
  },
  '61741': {
    country: 'US',
    city: 'Forrest',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.7513,
    longitude: -88.4111,
    postal_code: '61741'
  },
  '61742': {
    country: 'US',
    city: 'Goodfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Woodford',
    latitude: 40.6329,
    longitude: -89.2727,
    postal_code: '61742'
  },
  '61743': {
    country: 'US',
    city: 'Graymont',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.8757,
    longitude: -88.7817,
    postal_code: '61743'
  },
  '61744': {
    country: 'US',
    city: 'Gridley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.7439,
    longitude: -88.884,
    postal_code: '61744'
  },
  '61745': {
    country: 'US',
    city: 'Heyworth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.3307,
    longitude: -88.9776,
    postal_code: '61745'
  },
  '61747': {
    country: 'US',
    city: 'Hopedale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.4273,
    longitude: -89.4214,
    postal_code: '61747'
  },
  '61748': {
    country: 'US',
    city: 'Hudson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.6205,
    longitude: -88.9759,
    postal_code: '61748'
  },
  '61749': {
    country: 'US',
    city: 'Kenney',
    state: 'Illinois',
    state_short: 'IL',
    county: 'De Witt',
    latitude: 40.0967,
    longitude: -89.0859,
    postal_code: '61749'
  },
  '61750': {
    country: 'US',
    city: 'Lane',
    state: 'Illinois',
    state_short: 'IL',
    county: 'De Witt',
    latitude: 40.1235,
    longitude: -88.8597,
    postal_code: '61750'
  },
  '61751': {
    country: 'US',
    city: 'Lawndale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.2193,
    longitude: -89.2852,
    postal_code: '61751'
  },
  '61752': {
    country: 'US',
    city: 'Le Roy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.3468,
    longitude: -88.7598,
    postal_code: '61752'
  },
  '61753': {
    country: 'US',
    city: 'Lexington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.6357,
    longitude: -88.8062,
    postal_code: '61753'
  },
  '61754': {
    country: 'US',
    city: 'Mc Lean',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.3282,
    longitude: -89.1645,
    postal_code: '61754'
  },
  '61755': {
    country: 'US',
    city: 'Mackinaw',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.5396,
    longitude: -89.3458,
    postal_code: '61755'
  },
  '61756': {
    country: 'US',
    city: 'Maroa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 40.0342,
    longitude: -88.9578,
    postal_code: '61756'
  },
  '61758': {
    country: 'US',
    city: 'Merna',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.5167,
    longitude: -88.8259,
    postal_code: '61758'
  },
  '61759': {
    country: 'US',
    city: 'Minier',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Tazewell',
    latitude: 40.4359,
    longitude: -89.3165,
    postal_code: '61759'
  },
  '61760': {
    country: 'US',
    city: 'Minonk',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Woodford',
    latitude: 40.8985,
    longitude: -89.0349,
    postal_code: '61760'
  },
  '61761': {
    country: 'US',
    city: 'Normal',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.5124,
    longitude: -88.9883,
    postal_code: '61761'
  },
  '61764': {
    country: 'US',
    city: 'Pontiac',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.8764,
    longitude: -88.6328,
    postal_code: '61764'
  },
  '61769': {
    country: 'US',
    city: 'Saunemin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.8885,
    longitude: -88.4094,
    postal_code: '61769'
  },
  '61770': {
    country: 'US',
    city: 'Saybrook',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.432,
    longitude: -88.5247,
    postal_code: '61770'
  },
  '61771': {
    country: 'US',
    city: 'Secor',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Woodford',
    latitude: 40.7224,
    longitude: -89.1271,
    postal_code: '61771'
  },
  '61772': {
    country: 'US',
    city: 'Shirley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.4174,
    longitude: -89.0822,
    postal_code: '61772'
  },
  '61773': {
    country: 'US',
    city: 'Sibley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Ford',
    latitude: 40.5823,
    longitude: -88.3815,
    postal_code: '61773'
  },
  '61774': {
    country: 'US',
    city: 'Stanford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.4376,
    longitude: -89.2164,
    postal_code: '61774'
  },
  '61775': {
    country: 'US',
    city: 'Strawn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Livingston',
    latitude: 40.6476,
    longitude: -88.404,
    postal_code: '61775'
  },
  '61776': {
    country: 'US',
    city: 'Towanda',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.5533,
    longitude: -88.8886,
    postal_code: '61776'
  },
  '61777': {
    country: 'US',
    city: 'Wapella',
    state: 'Illinois',
    state_short: 'IL',
    county: 'De Witt',
    latitude: 40.2323,
    longitude: -88.9673,
    postal_code: '61777'
  },
  '61778': {
    country: 'US',
    city: 'Waynesville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'De Witt',
    latitude: 40.2437,
    longitude: -89.1143,
    postal_code: '61778'
  },
  '61790': {
    country: 'US',
    city: 'Normal',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.5103,
    longitude: -88.998,
    postal_code: '61790'
  },
  '61791': {
    country: 'US',
    city: 'Bloomington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.5192,
    longitude: -88.8643,
    postal_code: '61791'
  },
  '61799': {
    country: 'US',
    city: 'Bloomington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McLean',
    latitude: 40.4885,
    longitude: -88.9396,
    postal_code: '61799'
  },
  '61801': {
    country: 'US',
    city: 'Urbana',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1095,
    longitude: -88.2036,
    postal_code: '61801'
  },
  '61802': {
    country: 'US',
    city: 'Urbana',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.0746,
    longitude: -88.1691,
    postal_code: '61802'
  },
  '61803': {
    country: 'US',
    city: 'Urbana',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1059,
    longitude: -88.2247,
    postal_code: '61803'
  },
  '61810': {
    country: 'US',
    city: 'Allerton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 39.9188,
    longitude: -87.9312,
    postal_code: '61810'
  },
  '61811': {
    country: 'US',
    city: 'Alvin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.3007,
    longitude: -87.608,
    postal_code: '61811'
  },
  '61812': {
    country: 'US',
    city: 'Armstrong',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.2175,
    longitude: -87.8943,
    postal_code: '61812'
  },
  '61813': {
    country: 'US',
    city: 'Bement',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Piatt',
    latitude: 39.9222,
    longitude: -88.5688,
    postal_code: '61813'
  },
  '61814': {
    country: 'US',
    city: 'Bismarck',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.2552,
    longitude: -87.6138,
    postal_code: '61814'
  },
  '61815': {
    country: 'US',
    city: 'Bondville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1134,
    longitude: -88.3695,
    postal_code: '61815'
  },
  '61816': {
    country: 'US',
    city: 'Broadlands',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 39.9142,
    longitude: -87.9948,
    postal_code: '61816'
  },
  '61817': {
    country: 'US',
    city: 'Catlin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.0699,
    longitude: -87.7113,
    postal_code: '61817'
  },
  '61818': {
    country: 'US',
    city: 'Cerro Gordo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Piatt',
    latitude: 39.8681,
    longitude: -88.7256,
    postal_code: '61818'
  },
  '61820': {
    country: 'US',
    city: 'Champaign',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.111,
    longitude: -88.2407,
    postal_code: '61820'
  },
  '61821': {
    country: 'US',
    city: 'Champaign',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1073,
    longitude: -88.2788,
    postal_code: '61821'
  },
  '61822': {
    country: 'US',
    city: 'Champaign',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1317,
    longitude: -88.2854,
    postal_code: '61822'
  },
  '61824': {
    country: 'US',
    city: 'Champaign',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1164,
    longitude: -88.2434,
    postal_code: '61824'
  },
  '61825': {
    country: 'US',
    city: 'Champaign',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1164,
    longitude: -88.2434,
    postal_code: '61825'
  },
  '61826': {
    country: 'US',
    city: 'Champaign',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1131,
    longitude: -88.3613,
    postal_code: '61826'
  },
  '61830': {
    country: 'US',
    city: 'Cisco',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Piatt',
    latitude: 39.9972,
    longitude: -88.6962,
    postal_code: '61830'
  },
  '61831': {
    country: 'US',
    city: 'Collison',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.2207,
    longitude: -87.7987,
    postal_code: '61831'
  },
  '61832': {
    country: 'US',
    city: 'Danville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.137,
    longitude: -87.6217,
    postal_code: '61832'
  },
  '61833': {
    country: 'US',
    city: 'Tilton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.0964,
    longitude: -87.644,
    postal_code: '61833'
  },
  '61834': {
    country: 'US',
    city: 'Danville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.1602,
    longitude: -87.6729,
    postal_code: '61834'
  },
  '61839': {
    country: 'US',
    city: 'De Land',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Piatt',
    latitude: 40.1107,
    longitude: -88.6392,
    postal_code: '61839'
  },
  '61840': {
    country: 'US',
    city: 'Dewey',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.3131,
    longitude: -88.277,
    postal_code: '61840'
  },
  '61841': {
    country: 'US',
    city: 'Fairmount',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.0373,
    longitude: -87.8365,
    postal_code: '61841'
  },
  '61842': {
    country: 'US',
    city: 'Farmer City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'De Witt',
    latitude: 40.2447,
    longitude: -88.6634,
    postal_code: '61842'
  },
  '61843': {
    country: 'US',
    city: 'Fisher',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.2991,
    longitude: -88.356,
    postal_code: '61843'
  },
  '61844': {
    country: 'US',
    city: 'Fithian',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.1192,
    longitude: -87.8797,
    postal_code: '61844'
  },
  '61845': {
    country: 'US',
    city: 'Foosland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.3554,
    longitude: -88.4202,
    postal_code: '61845'
  },
  '61846': {
    country: 'US',
    city: 'Georgetown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 39.9792,
    longitude: -87.6365,
    postal_code: '61846'
  },
  '61847': {
    country: 'US',
    city: 'Gifford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.3028,
    longitude: -88.0317,
    postal_code: '61847'
  },
  '61848': {
    country: 'US',
    city: 'Henning',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.304,
    longitude: -87.7007,
    postal_code: '61848'
  },
  '61849': {
    country: 'US',
    city: 'Homer',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.0346,
    longitude: -87.9627,
    postal_code: '61849'
  },
  '61850': {
    country: 'US',
    city: 'Indianola',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 39.9268,
    longitude: -87.7388,
    postal_code: '61850'
  },
  '61851': {
    country: 'US',
    city: 'Ivesdale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 39.9502,
    longitude: -88.4451,
    postal_code: '61851'
  },
  '61852': {
    country: 'US',
    city: 'Longview',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 39.9012,
    longitude: -88.0753,
    postal_code: '61852'
  },
  '61853': {
    country: 'US',
    city: 'Mahomet',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1964,
    longitude: -88.3928,
    postal_code: '61853'
  },
  '61854': {
    country: 'US',
    city: 'Mansfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Piatt',
    latitude: 40.2147,
    longitude: -88.5179,
    postal_code: '61854'
  },
  '61855': {
    country: 'US',
    city: 'Milmine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Piatt',
    latitude: 39.9238,
    longitude: -88.6599,
    postal_code: '61855'
  },
  '61856': {
    country: 'US',
    city: 'Monticello',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Piatt',
    latitude: 40.0263,
    longitude: -88.5686,
    postal_code: '61856'
  },
  '61857': {
    country: 'US',
    city: 'Muncie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.1165,
    longitude: -87.8447,
    postal_code: '61857'
  },
  '61858': {
    country: 'US',
    city: 'Oakwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.1167,
    longitude: -87.7825,
    postal_code: '61858'
  },
  '61859': {
    country: 'US',
    city: 'Ogden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1401,
    longitude: -87.9665,
    postal_code: '61859'
  },
  '61862': {
    country: 'US',
    city: 'Penfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.3101,
    longitude: -87.957,
    postal_code: '61862'
  },
  '61863': {
    country: 'US',
    city: 'Pesotum',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 39.9151,
    longitude: -88.2743,
    postal_code: '61863'
  },
  '61864': {
    country: 'US',
    city: 'Philo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.0052,
    longitude: -88.1595,
    postal_code: '61864'
  },
  '61865': {
    country: 'US',
    city: 'Potomac',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.309,
    longitude: -87.8232,
    postal_code: '61865'
  },
  '61866': {
    country: 'US',
    city: 'Rantoul',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.3107,
    longitude: -88.1462,
    postal_code: '61866'
  },
  '61870': {
    country: 'US',
    city: 'Ridge Farm',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 39.9155,
    longitude: -87.6346,
    postal_code: '61870'
  },
  '61871': {
    country: 'US',
    city: 'Royal',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1924,
    longitude: -87.9742,
    postal_code: '61871'
  },
  '61872': {
    country: 'US',
    city: 'Sadorus',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 39.9613,
    longitude: -88.3447,
    postal_code: '61872'
  },
  '61873': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.1207,
    longitude: -88.0472,
    postal_code: '61873'
  },
  '61874': {
    country: 'US',
    city: 'Savoy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.0654,
    longitude: -88.2528,
    postal_code: '61874'
  },
  '61875': {
    country: 'US',
    city: 'Seymour',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.107,
    longitude: -88.4267,
    postal_code: '61875'
  },
  '61876': {
    country: 'US',
    city: 'Sidell',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 39.911,
    longitude: -87.8248,
    postal_code: '61876'
  },
  '61877': {
    country: 'US',
    city: 'Sidney',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.0232,
    longitude: -88.069,
    postal_code: '61877'
  },
  '61878': {
    country: 'US',
    city: 'Thomasboro',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 40.2402,
    longitude: -88.183,
    postal_code: '61878'
  },
  '61880': {
    country: 'US',
    city: 'Tolono',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Champaign',
    latitude: 39.985,
    longitude: -88.2596,
    postal_code: '61880'
  },
  '61882': {
    country: 'US',
    city: 'Weldon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'De Witt',
    latitude: 40.1177,
    longitude: -88.7531,
    postal_code: '61882'
  },
  '61883': {
    country: 'US',
    city: 'Westville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Vermilion',
    latitude: 40.0451,
    longitude: -87.636,
    postal_code: '61883'
  },
  '61884': {
    country: 'US',
    city: 'White Heath',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Piatt',
    latitude: 40.1009,
    longitude: -88.5193,
    postal_code: '61884'
  },
  '61910': {
    country: 'US',
    city: 'Arcola',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Douglas',
    latitude: 39.687,
    longitude: -88.3037,
    postal_code: '61910'
  },
  '61911': {
    country: 'US',
    city: 'Arthur',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Douglas',
    latitude: 39.7077,
    longitude: -88.4555,
    postal_code: '61911'
  },
  '61912': {
    country: 'US',
    city: 'Ashmore',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Coles',
    latitude: 39.5254,
    longitude: -88.0341,
    postal_code: '61912'
  },
  '61913': {
    country: 'US',
    city: 'Atwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Douglas',
    latitude: 39.8044,
    longitude: -88.4494,
    postal_code: '61913'
  },
  '61914': {
    country: 'US',
    city: 'Bethany',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Moultrie',
    latitude: 39.6348,
    longitude: -88.7543,
    postal_code: '61914'
  },
  '61917': {
    country: 'US',
    city: 'Brocton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edgar',
    latitude: 39.6923,
    longitude: -87.9263,
    postal_code: '61917'
  },
  '61919': {
    country: 'US',
    city: 'Camargo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Douglas',
    latitude: 39.8,
    longitude: -88.1468,
    postal_code: '61919'
  },
  '61920': {
    country: 'US',
    city: 'Charleston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Coles',
    latitude: 39.4869,
    longitude: -88.1761,
    postal_code: '61920'
  },
  '61924': {
    country: 'US',
    city: 'Chrisman',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edgar',
    latitude: 39.7996,
    longitude: -87.6556,
    postal_code: '61924'
  },
  '61925': {
    country: 'US',
    city: 'Dalton City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Moultrie',
    latitude: 39.7119,
    longitude: -88.7975,
    postal_code: '61925'
  },
  '61928': {
    country: 'US',
    city: 'Gays',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Moultrie',
    latitude: 39.4796,
    longitude: -88.5242,
    postal_code: '61928'
  },
  '61929': {
    country: 'US',
    city: 'Hammond',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Piatt',
    latitude: 39.7946,
    longitude: -88.5793,
    postal_code: '61929'
  },
  '61930': {
    country: 'US',
    city: 'Hindsboro',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Douglas',
    latitude: 39.7018,
    longitude: -88.1486,
    postal_code: '61930'
  },
  '61931': {
    country: 'US',
    city: 'Humboldt',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Coles',
    latitude: 39.6012,
    longitude: -88.3141,
    postal_code: '61931'
  },
  '61932': {
    country: 'US',
    city: 'Hume',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edgar',
    latitude: 39.8009,
    longitude: -87.8747,
    postal_code: '61932'
  },
  '61933': {
    country: 'US',
    city: 'Kansas',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edgar',
    latitude: 39.5525,
    longitude: -87.9352,
    postal_code: '61933'
  },
  '61936': {
    country: 'US',
    city: 'La Place',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Piatt',
    latitude: 39.7983,
    longitude: -88.7272,
    postal_code: '61936'
  },
  '61937': {
    country: 'US',
    city: 'Lovington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Moultrie',
    latitude: 39.7192,
    longitude: -88.6417,
    postal_code: '61937'
  },
  '61938': {
    country: 'US',
    city: 'Mattoon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Coles',
    latitude: 39.4802,
    longitude: -88.3762,
    postal_code: '61938'
  },
  '61940': {
    country: 'US',
    city: 'Metcalf',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edgar',
    latitude: 39.8008,
    longitude: -87.7955,
    postal_code: '61940'
  },
  '61941': {
    country: 'US',
    city: 'Murdock',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Douglas',
    latitude: 39.8009,
    longitude: -88.0784,
    postal_code: '61941'
  },
  '61942': {
    country: 'US',
    city: 'Newman',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Douglas',
    latitude: 39.7848,
    longitude: -88.0001,
    postal_code: '61942'
  },
  '61943': {
    country: 'US',
    city: 'Oakland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Coles',
    latitude: 39.6516,
    longitude: -88.0253,
    postal_code: '61943'
  },
  '61944': {
    country: 'US',
    city: 'Paris',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edgar',
    latitude: 39.6132,
    longitude: -87.6976,
    postal_code: '61944'
  },
  '61949': {
    country: 'US',
    city: 'Redmon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edgar',
    latitude: 39.6453,
    longitude: -87.8617,
    postal_code: '61949'
  },
  '61951': {
    country: 'US',
    city: 'Sullivan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Moultrie',
    latitude: 39.5934,
    longitude: -88.6038,
    postal_code: '61951'
  },
  '61953': {
    country: 'US',
    city: 'Tuscola',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Douglas',
    latitude: 39.7995,
    longitude: -88.2816,
    postal_code: '61953'
  },
  '61955': {
    country: 'US',
    city: 'Vermilion',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edgar',
    latitude: 39.5811,
    longitude: -87.5889,
    postal_code: '61955'
  },
  '61956': {
    country: 'US',
    city: 'Villa Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Douglas',
    latitude: 39.8687,
    longitude: -88.1616,
    postal_code: '61956'
  },
  '61957': {
    country: 'US',
    city: 'Windsor',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.4302,
    longitude: -88.5857,
    postal_code: '61957'
  },
  '62001': {
    country: 'US',
    city: 'Alhambra',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.8822,
    longitude: -89.7441,
    postal_code: '62001'
  },
  '62002': {
    country: 'US',
    city: 'Alton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.9087,
    longitude: -90.1568,
    postal_code: '62002'
  },
  '62006': {
    country: 'US',
    city: 'Batchtown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Calhoun',
    latitude: 39.0725,
    longitude: -90.6591,
    postal_code: '62006'
  },
  '62009': {
    country: 'US',
    city: 'Benld',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.0939,
    longitude: -89.8031,
    postal_code: '62009'
  },
  '62010': {
    country: 'US',
    city: 'Bethalto',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.9074,
    longitude: -90.0344,
    postal_code: '62010'
  },
  '62011': {
    country: 'US',
    city: 'Bingham',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fayette',
    latitude: 39.1128,
    longitude: -89.2125,
    postal_code: '62011'
  },
  '62012': {
    country: 'US',
    city: 'Brighton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jersey',
    latitude: 39.0361,
    longitude: -90.1443,
    postal_code: '62012'
  },
  '62013': {
    country: 'US',
    city: 'Brussels',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Calhoun',
    latitude: 38.9495,
    longitude: -90.5887,
    postal_code: '62013'
  },
  '62014': {
    country: 'US',
    city: 'Bunker Hill',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.0408,
    longitude: -89.9624,
    postal_code: '62014'
  },
  '62015': {
    country: 'US',
    city: 'Butler',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.2114,
    longitude: -89.5305,
    postal_code: '62015'
  },
  '62016': {
    country: 'US',
    city: 'Carrollton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Greene',
    latitude: 39.3009,
    longitude: -90.4092,
    postal_code: '62016'
  },
  '62017': {
    country: 'US',
    city: 'Coffeen',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.0908,
    longitude: -89.3945,
    postal_code: '62017'
  },
  '62018': {
    country: 'US',
    city: 'Cottage Hills',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.9124,
    longitude: -90.0826,
    postal_code: '62018'
  },
  '62019': {
    country: 'US',
    city: 'Donnellson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bond',
    latitude: 39.0344,
    longitude: -89.4909,
    postal_code: '62019'
  },
  '62021': {
    country: 'US',
    city: 'Dorsey',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.9832,
    longitude: -89.9786,
    postal_code: '62021'
  },
  '62022': {
    country: 'US',
    city: 'Dow',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jersey',
    latitude: 39.0312,
    longitude: -90.3011,
    postal_code: '62022'
  },
  '62023': {
    country: 'US',
    city: 'Eagarville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.1118,
    longitude: -89.7855,
    postal_code: '62023'
  },
  '62024': {
    country: 'US',
    city: 'East Alton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.8803,
    longitude: -90.083,
    postal_code: '62024'
  },
  '62025': {
    country: 'US',
    city: 'Edwardsville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.805,
    longitude: -89.9637,
    postal_code: '62025'
  },
  '62026': {
    country: 'US',
    city: 'Edwardsville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.8114,
    longitude: -89.9532,
    postal_code: '62026'
  },
  '62027': {
    country: 'US',
    city: 'Eldred',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Greene',
    latitude: 39.2836,
    longitude: -90.5329,
    postal_code: '62027'
  },
  '62028': {
    country: 'US',
    city: 'Elsah',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jersey',
    latitude: 38.9594,
    longitude: -90.3541,
    postal_code: '62028'
  },
  '62030': {
    country: 'US',
    city: 'Fidelity',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jersey',
    latitude: 39.1546,
    longitude: -90.1642,
    postal_code: '62030'
  },
  '62031': {
    country: 'US',
    city: 'Fieldon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jersey',
    latitude: 39.1086,
    longitude: -90.5297,
    postal_code: '62031'
  },
  '62032': {
    country: 'US',
    city: 'Fillmore',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.1039,
    longitude: -89.2946,
    postal_code: '62032'
  },
  '62033': {
    country: 'US',
    city: 'Gillespie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.1341,
    longitude: -89.8442,
    postal_code: '62033'
  },
  '62034': {
    country: 'US',
    city: 'Glen Carbon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.7609,
    longitude: -89.9706,
    postal_code: '62034'
  },
  '62035': {
    country: 'US',
    city: 'Godfrey',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.946,
    longitude: -90.206,
    postal_code: '62035'
  },
  '62036': {
    country: 'US',
    city: 'Golden Eagle',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Calhoun',
    latitude: 38.8961,
    longitude: -90.5602,
    postal_code: '62036'
  },
  '62037': {
    country: 'US',
    city: 'Grafton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jersey',
    latitude: 39.0021,
    longitude: -90.4323,
    postal_code: '62037'
  },
  '62040': {
    country: 'US',
    city: 'Granite City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.7261,
    longitude: -90.1106,
    postal_code: '62040'
  },
  '62044': {
    country: 'US',
    city: 'Greenfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Greene',
    latitude: 39.3491,
    longitude: -90.2089,
    postal_code: '62044'
  },
  '62045': {
    country: 'US',
    city: 'Hamburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Calhoun',
    latitude: 39.2235,
    longitude: -90.6995,
    postal_code: '62045'
  },
  '62046': {
    country: 'US',
    city: 'Hamel',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.8901,
    longitude: -89.8457,
    postal_code: '62046'
  },
  '62047': {
    country: 'US',
    city: 'Hardin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Calhoun',
    latitude: 39.1547,
    longitude: -90.624,
    postal_code: '62047'
  },
  '62048': {
    country: 'US',
    city: 'Hartford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.8278,
    longitude: -90.0926,
    postal_code: '62048'
  },
  '62049': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.1494,
    longitude: -89.4881,
    postal_code: '62049'
  },
  '62050': {
    country: 'US',
    city: 'Hillview',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Greene',
    latitude: 39.4674,
    longitude: -90.5128,
    postal_code: '62050'
  },
  '62051': {
    country: 'US',
    city: 'Irving',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.2089,
    longitude: -89.4104,
    postal_code: '62051'
  },
  '62052': {
    country: 'US',
    city: 'Jerseyville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jersey',
    latitude: 39.1213,
    longitude: -90.3338,
    postal_code: '62052'
  },
  '62053': {
    country: 'US',
    city: 'Kampsville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Calhoun',
    latitude: 39.3064,
    longitude: -90.6269,
    postal_code: '62053'
  },
  '62054': {
    country: 'US',
    city: 'Kane',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Greene',
    latitude: 39.2037,
    longitude: -90.3719,
    postal_code: '62054'
  },
  '62056': {
    country: 'US',
    city: 'Litchfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.1793,
    longitude: -89.6499,
    postal_code: '62056'
  },
  '62058': {
    country: 'US',
    city: 'Livingston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.969,
    longitude: -89.7668,
    postal_code: '62058'
  },
  '62059': {
    country: 'US',
    city: 'Lovejoy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.44,
    longitude: -89.9835,
    postal_code: '62059'
  },
  '62060': {
    country: 'US',
    city: 'Madison',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.6811,
    longitude: -90.1566,
    postal_code: '62060'
  },
  '62061': {
    country: 'US',
    city: 'Marine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.7793,
    longitude: -89.7786,
    postal_code: '62061'
  },
  '62062': {
    country: 'US',
    city: 'Maryville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.7138,
    longitude: -89.9658,
    postal_code: '62062'
  },
  '62063': {
    country: 'US',
    city: 'Medora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jersey',
    latitude: 39.1986,
    longitude: -90.1542,
    postal_code: '62063'
  },
  '62065': {
    country: 'US',
    city: 'Michael',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Calhoun',
    latitude: 39.2353,
    longitude: -90.6235,
    postal_code: '62065'
  },
  '62067': {
    country: 'US',
    city: 'Moro',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.9318,
    longitude: -89.9618,
    postal_code: '62067'
  },
  '62069': {
    country: 'US',
    city: 'Mount Olive',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.0705,
    longitude: -89.7448,
    postal_code: '62069'
  },
  '62070': {
    country: 'US',
    city: 'Mozier',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Calhoun',
    latitude: 39.2925,
    longitude: -90.7493,
    postal_code: '62070'
  },
  '62071': {
    country: 'US',
    city: 'National Stock Yards',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.6516,
    longitude: -90.1639,
    postal_code: '62071'
  },
  '62074': {
    country: 'US',
    city: 'New Douglas',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.9676,
    longitude: -89.7392,
    postal_code: '62074'
  },
  '62075': {
    country: 'US',
    city: 'Nokomis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.3036,
    longitude: -89.2853,
    postal_code: '62075'
  },
  '62076': {
    country: 'US',
    city: 'Ohlman',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.3434,
    longitude: -89.2191,
    postal_code: '62076'
  },
  '62077': {
    country: 'US',
    city: 'Panama',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.0317,
    longitude: -89.5237,
    postal_code: '62077'
  },
  '62078': {
    country: 'US',
    city: 'Patterson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Greene',
    latitude: 39.4806,
    longitude: -90.4829,
    postal_code: '62078'
  },
  '62079': {
    country: 'US',
    city: 'Piasa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.1159,
    longitude: -90.1237,
    postal_code: '62079'
  },
  '62080': {
    country: 'US',
    city: 'Ramsey',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fayette',
    latitude: 39.1445,
    longitude: -89.1087,
    postal_code: '62080'
  },
  '62081': {
    country: 'US',
    city: 'Rockbridge',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Greene',
    latitude: 39.2831,
    longitude: -90.2558,
    postal_code: '62081'
  },
  '62082': {
    country: 'US',
    city: 'Roodhouse',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Greene',
    latitude: 39.4846,
    longitude: -90.3498,
    postal_code: '62082'
  },
  '62083': {
    country: 'US',
    city: 'Rosamond',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.3743,
    longitude: -89.1983,
    postal_code: '62083'
  },
  '62084': {
    country: 'US',
    city: 'Roxana',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.8482,
    longitude: -90.0798,
    postal_code: '62084'
  },
  '62085': {
    country: 'US',
    city: 'Sawyerville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.0828,
    longitude: -89.8043,
    postal_code: '62085'
  },
  '62086': {
    country: 'US',
    city: 'Sorento',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bond',
    latitude: 38.9693,
    longitude: -89.5653,
    postal_code: '62086'
  },
  '62087': {
    country: 'US',
    city: 'South Roxana',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.8225,
    longitude: -90.0583,
    postal_code: '62087'
  },
  '62088': {
    country: 'US',
    city: 'Staunton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.0135,
    longitude: -89.7857,
    postal_code: '62088'
  },
  '62089': {
    country: 'US',
    city: 'Taylor Springs',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.1309,
    longitude: -89.492,
    postal_code: '62089'
  },
  '62090': {
    country: 'US',
    city: 'Venice',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.6706,
    longitude: -90.1689,
    postal_code: '62090'
  },
  '62091': {
    country: 'US',
    city: 'Walshville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.0473,
    longitude: -89.635,
    postal_code: '62091'
  },
  '62092': {
    country: 'US',
    city: 'White Hall',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Greene',
    latitude: 39.4288,
    longitude: -90.4019,
    postal_code: '62092'
  },
  '62093': {
    country: 'US',
    city: 'Wilsonville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.0695,
    longitude: -89.8565,
    postal_code: '62093'
  },
  '62094': {
    country: 'US',
    city: 'Witt',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.2469,
    longitude: -89.3414,
    postal_code: '62094'
  },
  '62095': {
    country: 'US',
    city: 'Wood River',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.8643,
    longitude: -90.0875,
    postal_code: '62095'
  },
  '62097': {
    country: 'US',
    city: 'Worden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.9449,
    longitude: -89.8532,
    postal_code: '62097'
  },
  '62098': {
    country: 'US',
    city: 'Wrights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Greene',
    latitude: 39.3762,
    longitude: -90.2933,
    postal_code: '62098'
  },
  '62201': {
    country: 'US',
    city: 'East Saint Louis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.6427,
    longitude: -90.1387,
    postal_code: '62201'
  },
  '62202': {
    country: 'US',
    city: 'East Saint Louis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.6163,
    longitude: -90.1591,
    postal_code: '62202'
  },
  '62203': {
    country: 'US',
    city: 'East Saint Louis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5992,
    longitude: -90.0744,
    postal_code: '62203'
  },
  '62204': {
    country: 'US',
    city: 'East Saint Louis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.6308,
    longitude: -90.095,
    postal_code: '62204'
  },
  '62205': {
    country: 'US',
    city: 'East Saint Louis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.6149,
    longitude: -90.1275,
    postal_code: '62205'
  },
  '62206': {
    country: 'US',
    city: 'East Saint Louis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5514,
    longitude: -90.1544,
    postal_code: '62206'
  },
  '62207': {
    country: 'US',
    city: 'East Saint Louis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.587,
    longitude: -90.1278,
    postal_code: '62207'
  },
  '62208': {
    country: 'US',
    city: 'Fairview Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.596,
    longitude: -90.0071,
    postal_code: '62208'
  },
  '62214': {
    country: 'US',
    city: 'Addieville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Washington',
    latitude: 38.3815,
    longitude: -89.5794,
    postal_code: '62214'
  },
  '62215': {
    country: 'US',
    city: 'Albers',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.532,
    longitude: -89.6202,
    postal_code: '62215'
  },
  '62216': {
    country: 'US',
    city: 'Aviston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.6089,
    longitude: -89.6034,
    postal_code: '62216'
  },
  '62217': {
    country: 'US',
    city: 'Baldwin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.1754,
    longitude: -89.8414,
    postal_code: '62217'
  },
  '62218': {
    country: 'US',
    city: 'Bartelso',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.5385,
    longitude: -89.4578,
    postal_code: '62218'
  },
  '62219': {
    country: 'US',
    city: 'Beckemeyer',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.6058,
    longitude: -89.4319,
    postal_code: '62219'
  },
  '62220': {
    country: 'US',
    city: 'Belleville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5127,
    longitude: -89.9847,
    postal_code: '62220'
  },
  '62221': {
    country: 'US',
    city: 'Belleville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5396,
    longitude: -89.9583,
    postal_code: '62221'
  },
  '62222': {
    country: 'US',
    city: 'Belleville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.44,
    longitude: -89.9835,
    postal_code: '62222'
  },
  '62223': {
    country: 'US',
    city: 'Belleville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5456,
    longitude: -90.0378,
    postal_code: '62223'
  },
  '62225': {
    country: 'US',
    city: 'Scott Air Force Base',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5432,
    longitude: -89.859,
    postal_code: '62225'
  },
  '62226': {
    country: 'US',
    city: 'Belleville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5352,
    longitude: -90.0006,
    postal_code: '62226'
  },
  '62230': {
    country: 'US',
    city: 'Breese',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.6188,
    longitude: -89.5284,
    postal_code: '62230'
  },
  '62231': {
    country: 'US',
    city: 'Carlyle',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.6066,
    longitude: -89.3805,
    postal_code: '62231'
  },
  '62232': {
    country: 'US',
    city: 'Caseyville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.6345,
    longitude: -90.0135,
    postal_code: '62232'
  },
  '62233': {
    country: 'US',
    city: 'Chester',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 37.9188,
    longitude: -89.8218,
    postal_code: '62233'
  },
  '62234': {
    country: 'US',
    city: 'Collinsville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.6835,
    longitude: -89.9853,
    postal_code: '62234'
  },
  '62236': {
    country: 'US',
    city: 'Columbia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Monroe',
    latitude: 38.4325,
    longitude: -90.2027,
    postal_code: '62236'
  },
  '62237': {
    country: 'US',
    city: 'Coulterville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.1753,
    longitude: -89.6479,
    postal_code: '62237'
  },
  '62238': {
    country: 'US',
    city: 'Cutler',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Perry',
    latitude: 38.0427,
    longitude: -89.5661,
    postal_code: '62238'
  },
  '62239': {
    country: 'US',
    city: 'Dupo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5162,
    longitude: -90.2104,
    postal_code: '62239'
  },
  '62240': {
    country: 'US',
    city: 'East Carondelet',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5349,
    longitude: -90.2208,
    postal_code: '62240'
  },
  '62241': {
    country: 'US',
    city: 'Ellis Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.0054,
    longitude: -89.9008,
    postal_code: '62241'
  },
  '62242': {
    country: 'US',
    city: 'Evansville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.0926,
    longitude: -89.917,
    postal_code: '62242'
  },
  '62243': {
    country: 'US',
    city: 'Freeburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.408,
    longitude: -89.9181,
    postal_code: '62243'
  },
  '62244': {
    country: 'US',
    city: 'Fults',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Monroe',
    latitude: 38.1797,
    longitude: -90.1974,
    postal_code: '62244'
  },
  '62245': {
    country: 'US',
    city: 'Germantown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.5487,
    longitude: -89.5413,
    postal_code: '62245'
  },
  '62246': {
    country: 'US',
    city: 'Greenville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bond',
    latitude: 38.8933,
    longitude: -89.4052,
    postal_code: '62246'
  },
  '62247': {
    country: 'US',
    city: 'Hagarstown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fayette',
    latitude: 38.9434,
    longitude: -89.1684,
    postal_code: '62247'
  },
  '62248': {
    country: 'US',
    city: 'Hecker',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Monroe',
    latitude: 38.3044,
    longitude: -89.9935,
    postal_code: '62248'
  },
  '62249': {
    country: 'US',
    city: 'Highland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.7631,
    longitude: -89.6789,
    postal_code: '62249'
  },
  '62250': {
    country: 'US',
    city: 'Hoffman',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.5408,
    longitude: -89.2661,
    postal_code: '62250'
  },
  '62252': {
    country: 'US',
    city: 'Huey',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.6018,
    longitude: -89.2915,
    postal_code: '62252'
  },
  '62253': {
    country: 'US',
    city: 'Keyesport',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.7769,
    longitude: -89.2975,
    postal_code: '62253'
  },
  '62254': {
    country: 'US',
    city: 'Lebanon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.6053,
    longitude: -89.7992,
    postal_code: '62254'
  },
  '62255': {
    country: 'US',
    city: 'Lenzburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.295,
    longitude: -89.7922,
    postal_code: '62255'
  },
  '62256': {
    country: 'US',
    city: 'Maeystown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Monroe',
    latitude: 38.3043,
    longitude: -90.1356,
    postal_code: '62256'
  },
  '62257': {
    country: 'US',
    city: 'Marissa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.2455,
    longitude: -89.7501,
    postal_code: '62257'
  },
  '62258': {
    country: 'US',
    city: 'Mascoutah',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.4745,
    longitude: -89.7877,
    postal_code: '62258'
  },
  '62259': {
    country: 'US',
    city: 'Menard',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 37.9101,
    longitude: -89.8398,
    postal_code: '62259'
  },
  '62260': {
    country: 'US',
    city: 'Millstadt',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.4443,
    longitude: -90.0888,
    postal_code: '62260'
  },
  '62261': {
    country: 'US',
    city: 'Modoc',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.0507,
    longitude: -90.0163,
    postal_code: '62261'
  },
  '62262': {
    country: 'US',
    city: 'Mulberry Grove',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bond',
    latitude: 38.9311,
    longitude: -89.2463,
    postal_code: '62262'
  },
  '62263': {
    country: 'US',
    city: 'Nashville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Washington',
    latitude: 38.3352,
    longitude: -89.3841,
    postal_code: '62263'
  },
  '62264': {
    country: 'US',
    city: 'New Athens',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.316,
    longitude: -89.8728,
    postal_code: '62264'
  },
  '62265': {
    country: 'US',
    city: 'New Baden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.5315,
    longitude: -89.6922,
    postal_code: '62265'
  },
  '62266': {
    country: 'US',
    city: 'New Memphis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.4857,
    longitude: -89.6816,
    postal_code: '62266'
  },
  '62268': {
    country: 'US',
    city: 'Oakdale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Washington',
    latitude: 38.2573,
    longitude: -89.596,
    postal_code: '62268'
  },
  '62269': {
    country: 'US',
    city: 'O Fallon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5718,
    longitude: -89.8957,
    postal_code: '62269'
  },
  '62271': {
    country: 'US',
    city: 'Okawville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Washington',
    latitude: 38.4319,
    longitude: -89.523,
    postal_code: '62271'
  },
  '62272': {
    country: 'US',
    city: 'Percy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.0126,
    longitude: -89.617,
    postal_code: '62272'
  },
  '62273': {
    country: 'US',
    city: 'Pierron',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bond',
    latitude: 38.78,
    longitude: -89.5981,
    postal_code: '62273'
  },
  '62274': {
    country: 'US',
    city: 'Pinckneyville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Perry',
    latitude: 38.0903,
    longitude: -89.3858,
    postal_code: '62274'
  },
  '62275': {
    country: 'US',
    city: 'Pocahontas',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bond',
    latitude: 38.7846,
    longitude: -89.5247,
    postal_code: '62275'
  },
  '62277': {
    country: 'US',
    city: 'Prairie Du Rocher',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.0831,
    longitude: -90.0959,
    postal_code: '62277'
  },
  '62278': {
    country: 'US',
    city: 'Red Bud',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.1907,
    longitude: -89.9884,
    postal_code: '62278'
  },
  '62279': {
    country: 'US',
    city: 'Renault',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Monroe',
    latitude: 38.1521,
    longitude: -90.1346,
    postal_code: '62279'
  },
  '62280': {
    country: 'US',
    city: 'Rockwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 37.8322,
    longitude: -89.6214,
    postal_code: '62280'
  },
  '62281': {
    country: 'US',
    city: 'Saint Jacob',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.7059,
    longitude: -89.7925,
    postal_code: '62281'
  },
  '62282': {
    country: 'US',
    city: 'Saint Libory',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.3632,
    longitude: -89.7139,
    postal_code: '62282'
  },
  '62284': {
    country: 'US',
    city: 'Smithboro',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Bond',
    latitude: 38.8739,
    longitude: -89.3266,
    postal_code: '62284'
  },
  '62285': {
    country: 'US',
    city: 'Smithton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.4231,
    longitude: -89.9896,
    postal_code: '62285'
  },
  '62286': {
    country: 'US',
    city: 'Sparta',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.1318,
    longitude: -89.7035,
    postal_code: '62286'
  },
  '62288': {
    country: 'US',
    city: 'Steeleville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.0057,
    longitude: -89.6665,
    postal_code: '62288'
  },
  '62289': {
    country: 'US',
    city: 'Summerfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'St. Clair',
    latitude: 38.5967,
    longitude: -89.7512,
    postal_code: '62289'
  },
  '62292': {
    country: 'US',
    city: 'Tilden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.2122,
    longitude: -89.6895,
    postal_code: '62292'
  },
  '62293': {
    country: 'US',
    city: 'Trenton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clinton',
    latitude: 38.6191,
    longitude: -89.6447,
    postal_code: '62293'
  },
  '62294': {
    country: 'US',
    city: 'Troy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Madison',
    latitude: 38.7243,
    longitude: -89.8708,
    postal_code: '62294'
  },
  '62295': {
    country: 'US',
    city: 'Valmeyer',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Monroe',
    latitude: 38.2805,
    longitude: -90.3122,
    postal_code: '62295'
  },
  '62297': {
    country: 'US',
    city: 'Walsh',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Randolph',
    latitude: 38.0203,
    longitude: -89.8297,
    postal_code: '62297'
  },
  '62298': {
    country: 'US',
    city: 'Waterloo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Monroe',
    latitude: 38.3223,
    longitude: -90.1478,
    postal_code: '62298'
  },
  '62301': {
    country: 'US',
    city: 'Quincy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 39.9307,
    longitude: -91.3763,
    postal_code: '62301'
  },
  '62305': {
    country: 'US',
    city: 'Quincy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 39.9601,
    longitude: -91.3026,
    postal_code: '62305'
  },
  '62306': {
    country: 'US',
    city: 'Quincy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 39.9356,
    longitude: -91.4099,
    postal_code: '62306'
  },
  '62311': {
    country: 'US',
    city: 'Augusta',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.2341,
    longitude: -90.9554,
    postal_code: '62311'
  },
  '62312': {
    country: 'US',
    city: 'Barry',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.7047,
    longitude: -91.0265,
    postal_code: '62312'
  },
  '62313': {
    country: 'US',
    city: 'Basco',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.3283,
    longitude: -91.197,
    postal_code: '62313'
  },
  '62314': {
    country: 'US',
    city: 'Baylis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.7612,
    longitude: -90.8832,
    postal_code: '62314'
  },
  '62316': {
    country: 'US',
    city: 'Bowen',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.2341,
    longitude: -91.0705,
    postal_code: '62316'
  },
  '62319': {
    country: 'US',
    city: 'Camden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Schuyler',
    latitude: 40.1511,
    longitude: -90.7544,
    postal_code: '62319'
  },
  '62320': {
    country: 'US',
    city: 'Camp Point',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 40.0293,
    longitude: -91.0769,
    postal_code: '62320'
  },
  '62321': {
    country: 'US',
    city: 'Carthage',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.4129,
    longitude: -91.1005,
    postal_code: '62321'
  },
  '62323': {
    country: 'US',
    city: 'Chambersburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.8071,
    longitude: -90.663,
    postal_code: '62323'
  },
  '62324': {
    country: 'US',
    city: 'Clayton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 40.0133,
    longitude: -90.9554,
    postal_code: '62324'
  },
  '62325': {
    country: 'US',
    city: 'Coatsburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 40.0554,
    longitude: -91.1747,
    postal_code: '62325'
  },
  '62326': {
    country: 'US',
    city: 'Colchester',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.4156,
    longitude: -90.7846,
    postal_code: '62326'
  },
  '62329': {
    country: 'US',
    city: 'Colusa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.5714,
    longitude: -91.1682,
    postal_code: '62329'
  },
  '62330': {
    country: 'US',
    city: 'Dallas City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.572,
    longitude: -91.1255,
    postal_code: '62330'
  },
  '62334': {
    country: 'US',
    city: 'Elvaston',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.395,
    longitude: -91.2496,
    postal_code: '62334'
  },
  '62336': {
    country: 'US',
    city: 'Ferris',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.4689,
    longitude: -91.1704,
    postal_code: '62336'
  },
  '62338': {
    country: 'US',
    city: 'Fowler',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 39.9925,
    longitude: -91.2452,
    postal_code: '62338'
  },
  '62339': {
    country: 'US',
    city: 'Golden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 40.1203,
    longitude: -91.0296,
    postal_code: '62339'
  },
  '62340': {
    country: 'US',
    city: 'Griggsville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.7084,
    longitude: -90.7249,
    postal_code: '62340'
  },
  '62341': {
    country: 'US',
    city: 'Hamilton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.3964,
    longitude: -91.339,
    postal_code: '62341'
  },
  '62343': {
    country: 'US',
    city: 'Hull',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.7186,
    longitude: -91.2338,
    postal_code: '62343'
  },
  '62344': {
    country: 'US',
    city: 'Huntsville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Schuyler',
    latitude: 40.1544,
    longitude: -90.8531,
    postal_code: '62344'
  },
  '62345': {
    country: 'US',
    city: 'Kinderhook',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.6953,
    longitude: -91.1637,
    postal_code: '62345'
  },
  '62346': {
    country: 'US',
    city: 'La Prairie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 40.1572,
    longitude: -90.9844,
    postal_code: '62346'
  },
  '62347': {
    country: 'US',
    city: 'Liberty',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 39.8892,
    longitude: -91.0869,
    postal_code: '62347'
  },
  '62348': {
    country: 'US',
    city: 'Lima',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 40.1775,
    longitude: -91.3785,
    postal_code: '62348'
  },
  '62349': {
    country: 'US',
    city: 'Loraine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 40.1532,
    longitude: -91.213,
    postal_code: '62349'
  },
  '62351': {
    country: 'US',
    city: 'Mendon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 40.0857,
    longitude: -91.2899,
    postal_code: '62351'
  },
  '62352': {
    country: 'US',
    city: 'Milton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.5645,
    longitude: -90.6504,
    postal_code: '62352'
  },
  '62353': {
    country: 'US',
    city: 'Mount Sterling',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Brown',
    latitude: 39.9803,
    longitude: -90.7414,
    postal_code: '62353'
  },
  '62354': {
    country: 'US',
    city: 'Nauvoo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.5261,
    longitude: -91.3318,
    postal_code: '62354'
  },
  '62355': {
    country: 'US',
    city: 'Nebo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.4202,
    longitude: -90.7692,
    postal_code: '62355'
  },
  '62356': {
    country: 'US',
    city: 'New Canton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.6342,
    longitude: -91.0886,
    postal_code: '62356'
  },
  '62357': {
    country: 'US',
    city: 'New Salem',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.6996,
    longitude: -90.844,
    postal_code: '62357'
  },
  '62358': {
    country: 'US',
    city: 'Niota',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.5978,
    longitude: -91.2991,
    postal_code: '62358'
  },
  '62359': {
    country: 'US',
    city: 'Paloma',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 40.0366,
    longitude: -91.2053,
    postal_code: '62359'
  },
  '62360': {
    country: 'US',
    city: 'Payson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 39.8153,
    longitude: -91.2627,
    postal_code: '62360'
  },
  '62361': {
    country: 'US',
    city: 'Pearl',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.4441,
    longitude: -90.638,
    postal_code: '62361'
  },
  '62362': {
    country: 'US',
    city: 'Perry',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.7823,
    longitude: -90.7471,
    postal_code: '62362'
  },
  '62363': {
    country: 'US',
    city: 'Pittsfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.6013,
    longitude: -90.8073,
    postal_code: '62363'
  },
  '62365': {
    country: 'US',
    city: 'Plainville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 39.8004,
    longitude: -91.1436,
    postal_code: '62365'
  },
  '62366': {
    country: 'US',
    city: 'Pleasant Hill',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.4467,
    longitude: -90.877,
    postal_code: '62366'
  },
  '62367': {
    country: 'US',
    city: 'Plymouth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.3236,
    longitude: -90.9672,
    postal_code: '62367'
  },
  '62370': {
    country: 'US',
    city: 'Rockport',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pike',
    latitude: 39.5328,
    longitude: -90.9722,
    postal_code: '62370'
  },
  '62373': {
    country: 'US',
    city: 'Sutter',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.2865,
    longitude: -91.3726,
    postal_code: '62373'
  },
  '62374': {
    country: 'US',
    city: 'Tennessee',
    state: 'Illinois',
    state_short: 'IL',
    county: 'McDonough',
    latitude: 40.414,
    longitude: -90.8556,
    postal_code: '62374'
  },
  '62375': {
    country: 'US',
    city: 'Timewell',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Brown',
    latitude: 40.0117,
    longitude: -90.8661,
    postal_code: '62375'
  },
  '62376': {
    country: 'US',
    city: 'Ursa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Adams',
    latitude: 40.0809,
    longitude: -91.3733,
    postal_code: '62376'
  },
  '62378': {
    country: 'US',
    city: 'Versailles',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Brown',
    latitude: 39.8883,
    longitude: -90.6741,
    postal_code: '62378'
  },
  '62379': {
    country: 'US',
    city: 'Warsaw',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.3544,
    longitude: -91.4348,
    postal_code: '62379'
  },
  '62380': {
    country: 'US',
    city: 'West Point',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hancock',
    latitude: 40.255,
    longitude: -91.1826,
    postal_code: '62380'
  },
  '62401': {
    country: 'US',
    city: 'Effingham',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Effingham',
    latitude: 39.1217,
    longitude: -88.5611,
    postal_code: '62401'
  },
  '62410': {
    country: 'US',
    city: 'Allendale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wabash',
    latitude: 38.5232,
    longitude: -87.7219,
    postal_code: '62410'
  },
  '62411': {
    country: 'US',
    city: 'Altamont',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Effingham',
    latitude: 39.0634,
    longitude: -88.7481,
    postal_code: '62411'
  },
  '62413': {
    country: 'US',
    city: 'Annapolis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Crawford',
    latitude: 39.1179,
    longitude: -87.8029,
    postal_code: '62413'
  },
  '62414': {
    country: 'US',
    city: 'Beecher City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Effingham',
    latitude: 39.1835,
    longitude: -88.8038,
    postal_code: '62414'
  },
  '62417': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lawrence',
    latitude: 38.7067,
    longitude: -87.7657,
    postal_code: '62417'
  },
  '62418': {
    country: 'US',
    city: 'Brownstown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fayette',
    latitude: 38.9891,
    longitude: -88.9494,
    postal_code: '62418'
  },
  '62419': {
    country: 'US',
    city: 'Calhoun',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Richland',
    latitude: 38.6351,
    longitude: -88.0037,
    postal_code: '62419'
  },
  '62420': {
    country: 'US',
    city: 'Casey',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clark',
    latitude: 39.3017,
    longitude: -87.9913,
    postal_code: '62420'
  },
  '62421': {
    country: 'US',
    city: 'Claremont',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Richland',
    latitude: 38.7429,
    longitude: -87.9727,
    postal_code: '62421'
  },
  '62422': {
    country: 'US',
    city: 'Cowden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.2326,
    longitude: -88.8868,
    postal_code: '62422'
  },
  '62423': {
    country: 'US',
    city: 'Dennison',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clark',
    latitude: 39.449,
    longitude: -87.5867,
    postal_code: '62423'
  },
  '62424': {
    country: 'US',
    city: 'Dieterich',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Effingham',
    latitude: 39.0319,
    longitude: -88.4074,
    postal_code: '62424'
  },
  '62425': {
    country: 'US',
    city: 'Dundas',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Richland',
    latitude: 38.8306,
    longitude: -88.0973,
    postal_code: '62425'
  },
  '62426': {
    country: 'US',
    city: 'Edgewood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Effingham',
    latitude: 38.9021,
    longitude: -88.6645,
    postal_code: '62426'
  },
  '62427': {
    country: 'US',
    city: 'Flat Rock',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Crawford',
    latitude: 38.9096,
    longitude: -87.6837,
    postal_code: '62427'
  },
  '62428': {
    country: 'US',
    city: 'Greenup',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cumberland',
    latitude: 39.274,
    longitude: -88.1246,
    postal_code: '62428'
  },
  '62431': {
    country: 'US',
    city: 'Herrick',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.2245,
    longitude: -88.9812,
    postal_code: '62431'
  },
  '62432': {
    country: 'US',
    city: 'Hidalgo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jasper',
    latitude: 39.1228,
    longitude: -88.1397,
    postal_code: '62432'
  },
  '62433': {
    country: 'US',
    city: 'Hutsonville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Crawford',
    latitude: 39.1064,
    longitude: -87.6695,
    postal_code: '62433'
  },
  '62434': {
    country: 'US',
    city: 'Ingraham',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clay',
    latitude: 38.8284,
    longitude: -88.3204,
    postal_code: '62434'
  },
  '62435': {
    country: 'US',
    city: 'Janesville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cumberland',
    latitude: 39.3743,
    longitude: -88.2445,
    postal_code: '62435'
  },
  '62436': {
    country: 'US',
    city: 'Jewett',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cumberland',
    latitude: 39.2076,
    longitude: -88.2474,
    postal_code: '62436'
  },
  '62438': {
    country: 'US',
    city: 'Lakewood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.3134,
    longitude: -88.8715,
    postal_code: '62438'
  },
  '62439': {
    country: 'US',
    city: 'Lawrenceville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lawrence',
    latitude: 38.7309,
    longitude: -87.6784,
    postal_code: '62439'
  },
  '62440': {
    country: 'US',
    city: 'Lerna',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Coles',
    latitude: 39.3958,
    longitude: -88.253,
    postal_code: '62440'
  },
  '62441': {
    country: 'US',
    city: 'Marshall',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clark',
    latitude: 39.422,
    longitude: -87.6923,
    postal_code: '62441'
  },
  '62442': {
    country: 'US',
    city: 'Martinsville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clark',
    latitude: 39.3174,
    longitude: -87.8707,
    postal_code: '62442'
  },
  '62443': {
    country: 'US',
    city: 'Mason',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Effingham',
    latitude: 38.9645,
    longitude: -88.6234,
    postal_code: '62443'
  },
  '62444': {
    country: 'US',
    city: 'Mode',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.2664,
    longitude: -88.7337,
    postal_code: '62444'
  },
  '62445': {
    country: 'US',
    city: 'Montrose',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Effingham',
    latitude: 39.1573,
    longitude: -88.335,
    postal_code: '62445'
  },
  '62446': {
    country: 'US',
    city: 'Mount Erie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.5222,
    longitude: -88.2185,
    postal_code: '62446'
  },
  '62447': {
    country: 'US',
    city: 'Neoga',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cumberland',
    latitude: 39.322,
    longitude: -88.4503,
    postal_code: '62447'
  },
  '62448': {
    country: 'US',
    city: 'Newton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jasper',
    latitude: 38.9847,
    longitude: -88.1704,
    postal_code: '62448'
  },
  '62449': {
    country: 'US',
    city: 'Oblong',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Crawford',
    latitude: 39.001,
    longitude: -87.895,
    postal_code: '62449'
  },
  '62450': {
    country: 'US',
    city: 'Olney',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Richland',
    latitude: 38.7334,
    longitude: -88.0809,
    postal_code: '62450'
  },
  '62451': {
    country: 'US',
    city: 'Palestine',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Crawford',
    latitude: 39.0028,
    longitude: -87.6157,
    postal_code: '62451'
  },
  '62452': {
    country: 'US',
    city: 'Parkersburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Richland',
    latitude: 38.59,
    longitude: -88.0647,
    postal_code: '62452'
  },
  '62454': {
    country: 'US',
    city: 'Robinson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Crawford',
    latitude: 39.007,
    longitude: -87.7484,
    postal_code: '62454'
  },
  '62458': {
    country: 'US',
    city: 'Saint Elmo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fayette',
    latitude: 39.0315,
    longitude: -88.8552,
    postal_code: '62458'
  },
  '62459': {
    country: 'US',
    city: 'Sainte Marie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jasper',
    latitude: 38.928,
    longitude: -88.0291,
    postal_code: '62459'
  },
  '62460': {
    country: 'US',
    city: 'Saint Francisville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lawrence',
    latitude: 38.5921,
    longitude: -87.6487,
    postal_code: '62460'
  },
  '62461': {
    country: 'US',
    city: 'Shumway',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Effingham',
    latitude: 39.1881,
    longitude: -88.6418,
    postal_code: '62461'
  },
  '62462': {
    country: 'US',
    city: 'Sigel',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.2176,
    longitude: -88.4803,
    postal_code: '62462'
  },
  '62463': {
    country: 'US',
    city: 'Stewardson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.272,
    longitude: -88.6319,
    postal_code: '62463'
  },
  '62464': {
    country: 'US',
    city: 'Stoy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Crawford',
    latitude: 38.9898,
    longitude: -87.8396,
    postal_code: '62464'
  },
  '62465': {
    country: 'US',
    city: 'Strasburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.3641,
    longitude: -88.6279,
    postal_code: '62465'
  },
  '62466': {
    country: 'US',
    city: 'Sumner',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Lawrence',
    latitude: 38.7171,
    longitude: -87.8638,
    postal_code: '62466'
  },
  '62467': {
    country: 'US',
    city: 'Teutopolis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Effingham',
    latitude: 39.132,
    longitude: -88.4767,
    postal_code: '62467'
  },
  '62468': {
    country: 'US',
    city: 'Toledo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cumberland',
    latitude: 39.2772,
    longitude: -88.2468,
    postal_code: '62468'
  },
  '62469': {
    country: 'US',
    city: 'Trilla',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Coles',
    latitude: 39.3938,
    longitude: -88.3488,
    postal_code: '62469'
  },
  '62471': {
    country: 'US',
    city: 'Vandalia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fayette',
    latitude: 38.9439,
    longitude: -89.1041,
    postal_code: '62471'
  },
  '62473': {
    country: 'US',
    city: 'Watson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Effingham',
    latitude: 38.9906,
    longitude: -88.5584,
    postal_code: '62473'
  },
  '62474': {
    country: 'US',
    city: 'Westfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clark',
    latitude: 39.4564,
    longitude: -87.9961,
    postal_code: '62474'
  },
  '62475': {
    country: 'US',
    city: 'West Liberty',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jasper',
    latitude: 38.895,
    longitude: -88.0979,
    postal_code: '62475'
  },
  '62476': {
    country: 'US',
    city: 'West Salem',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edwards',
    latitude: 38.5209,
    longitude: -88.0048,
    postal_code: '62476'
  },
  '62477': {
    country: 'US',
    city: 'West Union',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clark',
    latitude: 39.2402,
    longitude: -87.6512,
    postal_code: '62477'
  },
  '62478': {
    country: 'US',
    city: 'West York',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Crawford',
    latitude: 39.1938,
    longitude: -87.7131,
    postal_code: '62478'
  },
  '62479': {
    country: 'US',
    city: 'Wheeler',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jasper',
    latitude: 39.0182,
    longitude: -88.3175,
    postal_code: '62479'
  },
  '62480': {
    country: 'US',
    city: 'Willow Hill',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jasper',
    latitude: 38.9757,
    longitude: -88.0172,
    postal_code: '62480'
  },
  '62481': {
    country: 'US',
    city: 'Yale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jasper',
    latitude: 39.13,
    longitude: -88.0105,
    postal_code: '62481'
  },
  '62501': {
    country: 'US',
    city: 'Argenta',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.934,
    longitude: -88.8052,
    postal_code: '62501'
  },
  '62510': {
    country: 'US',
    city: 'Assumption',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.5095,
    longitude: -89.0398,
    postal_code: '62510'
  },
  '62512': {
    country: 'US',
    city: 'Beason',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.1437,
    longitude: -89.1948,
    postal_code: '62512'
  },
  '62513': {
    country: 'US',
    city: 'Blue Mound',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.6978,
    longitude: -89.1136,
    postal_code: '62513'
  },
  '62514': {
    country: 'US',
    city: 'Boody',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.7624,
    longitude: -89.0507,
    postal_code: '62514'
  },
  '62515': {
    country: 'US',
    city: 'Buffalo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8499,
    longitude: -89.3879,
    postal_code: '62515'
  },
  '62517': {
    country: 'US',
    city: 'Bulpitt',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.5917,
    longitude: -89.424,
    postal_code: '62517'
  },
  '62518': {
    country: 'US',
    city: 'Chestnut',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.0582,
    longitude: -89.19,
    postal_code: '62518'
  },
  '62519': {
    country: 'US',
    city: 'Cornland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 39.9374,
    longitude: -89.4023,
    postal_code: '62519'
  },
  '62520': {
    country: 'US',
    city: 'Dawson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8563,
    longitude: -89.4603,
    postal_code: '62520'
  },
  '62521': {
    country: 'US',
    city: 'Decatur',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.8395,
    longitude: -88.9465,
    postal_code: '62521'
  },
  '62522': {
    country: 'US',
    city: 'Decatur',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.8432,
    longitude: -88.9861,
    postal_code: '62522'
  },
  '62523': {
    country: 'US',
    city: 'Decatur',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.8417,
    longitude: -88.9534,
    postal_code: '62523'
  },
  '62524': {
    country: 'US',
    city: 'Decatur',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.854,
    longitude: -88.9815,
    postal_code: '62524'
  },
  '62525': {
    country: 'US',
    city: 'Decatur',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.854,
    longitude: -88.9815,
    postal_code: '62525'
  },
  '62526': {
    country: 'US',
    city: 'Decatur',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.8583,
    longitude: -88.9382,
    postal_code: '62526'
  },
  '62530': {
    country: 'US',
    city: 'Divernon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.5692,
    longitude: -89.6626,
    postal_code: '62530'
  },
  '62531': {
    country: 'US',
    city: 'Edinburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.6612,
    longitude: -89.3779,
    postal_code: '62531'
  },
  '62532': {
    country: 'US',
    city: 'Elwin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.78,
    longitude: -88.9808,
    postal_code: '62532'
  },
  '62533': {
    country: 'US',
    city: 'Farmersville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.4401,
    longitude: -89.617,
    postal_code: '62533'
  },
  '62534': {
    country: 'US',
    city: 'Findlay',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.5193,
    longitude: -88.7468,
    postal_code: '62534'
  },
  '62535': {
    country: 'US',
    city: 'Forsyth',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.9248,
    longitude: -88.9691,
    postal_code: '62535'
  },
  '62536': {
    country: 'US',
    city: 'Glenarm',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.6327,
    longitude: -89.6581,
    postal_code: '62536'
  },
  '62537': {
    country: 'US',
    city: 'Harristown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.8567,
    longitude: -89.085,
    postal_code: '62537'
  },
  '62538': {
    country: 'US',
    city: 'Harvel',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.3719,
    longitude: -89.538,
    postal_code: '62538'
  },
  '62539': {
    country: 'US',
    city: 'Illiopolis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8499,
    longitude: -89.2513,
    postal_code: '62539'
  },
  '62540': {
    country: 'US',
    city: 'Kincaid',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.5838,
    longitude: -89.4185,
    postal_code: '62540'
  },
  '62541': {
    country: 'US',
    city: 'Lake Fork',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 39.9706,
    longitude: -89.3505,
    postal_code: '62541'
  },
  '62543': {
    country: 'US',
    city: 'Latham',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 39.9711,
    longitude: -89.1725,
    postal_code: '62543'
  },
  '62544': {
    country: 'US',
    city: 'Macon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.7041,
    longitude: -88.988,
    postal_code: '62544'
  },
  '62545': {
    country: 'US',
    city: 'Mechanicsburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7778,
    longitude: -89.4064,
    postal_code: '62545'
  },
  '62546': {
    country: 'US',
    city: 'Morrisonville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.4153,
    longitude: -89.4541,
    postal_code: '62546'
  },
  '62547': {
    country: 'US',
    city: 'Mount Auburn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.769,
    longitude: -89.2489,
    postal_code: '62547'
  },
  '62548': {
    country: 'US',
    city: 'Mount Pulaski',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.0045,
    longitude: -89.2935,
    postal_code: '62548'
  },
  '62549': {
    country: 'US',
    city: 'Mt Zion',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.7705,
    longitude: -88.8659,
    postal_code: '62549'
  },
  '62550': {
    country: 'US',
    city: 'Moweaqua',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.6248,
    longitude: -89.019,
    postal_code: '62550'
  },
  '62551': {
    country: 'US',
    city: 'Niantic',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.8582,
    longitude: -89.1701,
    postal_code: '62551'
  },
  '62553': {
    country: 'US',
    city: 'Oconee',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.2884,
    longitude: -89.0836,
    postal_code: '62553'
  },
  '62554': {
    country: 'US',
    city: 'Oreana',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.9351,
    longitude: -88.8547,
    postal_code: '62554'
  },
  '62555': {
    country: 'US',
    city: 'Owaneco',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.4775,
    longitude: -89.1951,
    postal_code: '62555'
  },
  '62556': {
    country: 'US',
    city: 'Palmer',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.4699,
    longitude: -89.3733,
    postal_code: '62556'
  },
  '62557': {
    country: 'US',
    city: 'Pana',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.3971,
    longitude: -89.1048,
    postal_code: '62557'
  },
  '62558': {
    country: 'US',
    city: 'Pawnee',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.6086,
    longitude: -89.5678,
    postal_code: '62558'
  },
  '62560': {
    country: 'US',
    city: 'Raymond',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.3106,
    longitude: -89.5851,
    postal_code: '62560'
  },
  '62561': {
    country: 'US',
    city: 'Riverton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8663,
    longitude: -89.5087,
    postal_code: '62561'
  },
  '62563': {
    country: 'US',
    city: 'Rochester',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7182,
    longitude: -89.5649,
    postal_code: '62563'
  },
  '62565': {
    country: 'US',
    city: 'Shelbyville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.458,
    longitude: -88.8058,
    postal_code: '62565'
  },
  '62567': {
    country: 'US',
    city: 'Stonington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.6405,
    longitude: -89.1913,
    postal_code: '62567'
  },
  '62568': {
    country: 'US',
    city: 'Taylorville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.5489,
    longitude: -89.2945,
    postal_code: '62568'
  },
  '62570': {
    country: 'US',
    city: 'Tovey',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Christian',
    latitude: 39.588,
    longitude: -89.4495,
    postal_code: '62570'
  },
  '62571': {
    country: 'US',
    city: 'Tower Hill',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Shelby',
    latitude: 39.39,
    longitude: -88.9597,
    postal_code: '62571'
  },
  '62572': {
    country: 'US',
    city: 'Waggoner',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Montgomery',
    latitude: 39.3688,
    longitude: -89.6545,
    postal_code: '62572'
  },
  '62573': {
    country: 'US',
    city: 'Warrensburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macon',
    latitude: 39.9454,
    longitude: -89.0741,
    postal_code: '62573'
  },
  '62601': {
    country: 'US',
    city: 'Alexander',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.7517,
    longitude: -90.0458,
    postal_code: '62601'
  },
  '62610': {
    country: 'US',
    city: 'Alsey',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Scott',
    latitude: 39.5606,
    longitude: -90.434,
    postal_code: '62610'
  },
  '62611': {
    country: 'US',
    city: 'Arenzville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cass',
    latitude: 39.8979,
    longitude: -90.3636,
    postal_code: '62611'
  },
  '62612': {
    country: 'US',
    city: 'Ashland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cass',
    latitude: 39.9386,
    longitude: -90.0792,
    postal_code: '62612'
  },
  '62613': {
    country: 'US',
    city: 'Athens',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Menard',
    latitude: 39.9917,
    longitude: -89.6684,
    postal_code: '62613'
  },
  '62615': {
    country: 'US',
    city: 'Auburn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.5918,
    longitude: -89.744,
    postal_code: '62615'
  },
  '62617': {
    country: 'US',
    city: 'Bath',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mason',
    latitude: 40.1536,
    longitude: -90.1661,
    postal_code: '62617'
  },
  '62618': {
    country: 'US',
    city: 'Beardstown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cass',
    latitude: 40.0044,
    longitude: -90.4229,
    postal_code: '62618'
  },
  '62621': {
    country: 'US',
    city: 'Bluffs',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Scott',
    latitude: 39.7296,
    longitude: -90.5313,
    postal_code: '62621'
  },
  '62622': {
    country: 'US',
    city: 'Bluff Springs',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cass',
    latitude: 39.9796,
    longitude: -90.3524,
    postal_code: '62622'
  },
  '62624': {
    country: 'US',
    city: 'Browning',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Schuyler',
    latitude: 40.1289,
    longitude: -90.3721,
    postal_code: '62624'
  },
  '62625': {
    country: 'US',
    city: 'Cantrall',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.9169,
    longitude: -89.6891,
    postal_code: '62625'
  },
  '62626': {
    country: 'US',
    city: 'Carlinville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.2885,
    longitude: -89.8661,
    postal_code: '62626'
  },
  '62627': {
    country: 'US',
    city: 'Chandlerville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cass',
    latitude: 40.0374,
    longitude: -90.1163,
    postal_code: '62627'
  },
  '62628': {
    country: 'US',
    city: 'Chapin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.7716,
    longitude: -90.4113,
    postal_code: '62628'
  },
  '62629': {
    country: 'US',
    city: 'Chatham',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.6737,
    longitude: -89.7112,
    postal_code: '62629'
  },
  '62630': {
    country: 'US',
    city: 'Chesterfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.2608,
    longitude: -90.0784,
    postal_code: '62630'
  },
  '62631': {
    country: 'US',
    city: 'Concord',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.823,
    longitude: -90.3722,
    postal_code: '62631'
  },
  '62633': {
    country: 'US',
    city: 'Easton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mason',
    latitude: 40.2279,
    longitude: -89.8903,
    postal_code: '62633'
  },
  '62634': {
    country: 'US',
    city: 'Elkhart',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.0132,
    longitude: -89.452,
    postal_code: '62634'
  },
  '62635': {
    country: 'US',
    city: 'Emden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.2967,
    longitude: -89.4714,
    postal_code: '62635'
  },
  '62638': {
    country: 'US',
    city: 'Franklin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.6222,
    longitude: -90.0889,
    postal_code: '62638'
  },
  '62639': {
    country: 'US',
    city: 'Frederick',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Schuyler',
    latitude: 40.07,
    longitude: -90.429,
    postal_code: '62639'
  },
  '62640': {
    country: 'US',
    city: 'Girard',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.3838,
    longitude: -89.8501,
    postal_code: '62640'
  },
  '62642': {
    country: 'US',
    city: 'Greenview',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Menard',
    latitude: 40.1017,
    longitude: -89.7469,
    postal_code: '62642'
  },
  '62643': {
    country: 'US',
    city: 'Hartsburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.2451,
    longitude: -89.4519,
    postal_code: '62643'
  },
  '62644': {
    country: 'US',
    city: 'Havana',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mason',
    latitude: 40.3,
    longitude: -90.061,
    postal_code: '62644'
  },
  '62649': {
    country: 'US',
    city: 'Hettick',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.3752,
    longitude: -90.0672,
    postal_code: '62649'
  },
  '62650': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.7339,
    longitude: -90.229,
    postal_code: '62650'
  },
  '62651': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.6983,
    longitude: -90.2615,
    postal_code: '62651'
  },
  '62655': {
    country: 'US',
    city: 'Kilbourne',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mason',
    latitude: 40.1587,
    longitude: -90.0043,
    postal_code: '62655'
  },
  '62656': {
    country: 'US',
    city: 'Lincoln',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.1451,
    longitude: -89.3684,
    postal_code: '62656'
  },
  '62659': {
    country: 'US',
    city: "Lincoln'S New Salem",
    state: 'Illinois',
    state_short: 'IL',
    county: 'Menard',
    latitude: 40.0311,
    longitude: -89.7867,
    postal_code: '62659'
  },
  '62660': {
    country: 'US',
    city: 'Literberry',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.8589,
    longitude: -90.2007,
    postal_code: '62660'
  },
  '62661': {
    country: 'US',
    city: 'Loami',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.6704,
    longitude: -89.8588,
    postal_code: '62661'
  },
  '62662': {
    country: 'US',
    city: 'Lowder',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.5509,
    longitude: -89.8459,
    postal_code: '62662'
  },
  '62663': {
    country: 'US',
    city: 'Manchester',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Scott',
    latitude: 39.5405,
    longitude: -90.3317,
    postal_code: '62663'
  },
  '62664': {
    country: 'US',
    city: 'Mason City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mason',
    latitude: 40.2023,
    longitude: -89.6982,
    postal_code: '62664'
  },
  '62665': {
    country: 'US',
    city: 'Meredosia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.8173,
    longitude: -90.5339,
    postal_code: '62665'
  },
  '62666': {
    country: 'US',
    city: 'Middletown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.0967,
    longitude: -89.5818,
    postal_code: '62666'
  },
  '62667': {
    country: 'US',
    city: 'Modesto',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.476,
    longitude: -89.9794,
    postal_code: '62667'
  },
  '62668': {
    country: 'US',
    city: 'Murrayville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.5723,
    longitude: -90.2379,
    postal_code: '62668'
  },
  '62670': {
    country: 'US',
    city: 'New Berlin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7681,
    longitude: -89.855,
    postal_code: '62670'
  },
  '62671': {
    country: 'US',
    city: 'New Holland',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Logan',
    latitude: 40.1682,
    longitude: -89.5604,
    postal_code: '62671'
  },
  '62672': {
    country: 'US',
    city: 'Nilwood',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.398,
    longitude: -89.8045,
    postal_code: '62672'
  },
  '62673': {
    country: 'US',
    city: 'Oakford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Menard',
    latitude: 40.0994,
    longitude: -89.9601,
    postal_code: '62673'
  },
  '62674': {
    country: 'US',
    city: 'Palmyra',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.4227,
    longitude: -90.0311,
    postal_code: '62674'
  },
  '62675': {
    country: 'US',
    city: 'Petersburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Menard',
    latitude: 40.0117,
    longitude: -89.8482,
    postal_code: '62675'
  },
  '62677': {
    country: 'US',
    city: 'Pleasant Plains',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8331,
    longitude: -89.8686,
    postal_code: '62677'
  },
  '62681': {
    country: 'US',
    city: 'Rushville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Schuyler',
    latitude: 40.1133,
    longitude: -90.5432,
    postal_code: '62681'
  },
  '62682': {
    country: 'US',
    city: 'San Jose',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Mason',
    latitude: 40.3011,
    longitude: -89.6872,
    postal_code: '62682'
  },
  '62683': {
    country: 'US',
    city: 'Scottville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.4803,
    longitude: -90.1048,
    postal_code: '62683'
  },
  '62684': {
    country: 'US',
    city: 'Sherman',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.9121,
    longitude: -89.5877,
    postal_code: '62684'
  },
  '62685': {
    country: 'US',
    city: 'Shipman',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.1952,
    longitude: -89.9607,
    postal_code: '62685'
  },
  '62688': {
    country: 'US',
    city: 'Tallula',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Menard',
    latitude: 39.9445,
    longitude: -89.9373,
    postal_code: '62688'
  },
  '62689': {
    country: 'US',
    city: 'Thayer',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.5394,
    longitude: -89.7621,
    postal_code: '62689'
  },
  '62690': {
    country: 'US',
    city: 'Virden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Macoupin',
    latitude: 39.5064,
    longitude: -89.7783,
    postal_code: '62690'
  },
  '62691': {
    country: 'US',
    city: 'Virginia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Cass',
    latitude: 39.9512,
    longitude: -90.2123,
    postal_code: '62691'
  },
  '62692': {
    country: 'US',
    city: 'Waverly',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.587,
    longitude: -89.9449,
    postal_code: '62692'
  },
  '62693': {
    country: 'US',
    city: 'Williamsville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.9542,
    longitude: -89.5487,
    postal_code: '62693'
  },
  '62694': {
    country: 'US',
    city: 'Winchester',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Scott',
    latitude: 39.6266,
    longitude: -90.4664,
    postal_code: '62694'
  },
  '62695': {
    country: 'US',
    city: 'Woodson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Morgan',
    latitude: 39.6289,
    longitude: -90.219,
    postal_code: '62695'
  },
  '62701': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8,
    longitude: -89.6495,
    postal_code: '62701'
  },
  '62702': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8317,
    longitude: -89.6465,
    postal_code: '62702'
  },
  '62703': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7622,
    longitude: -89.6275,
    postal_code: '62703'
  },
  '62704': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7725,
    longitude: -89.6889,
    postal_code: '62704'
  },
  '62705': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8017,
    longitude: -89.6437,
    postal_code: '62705'
  },
  '62706': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7989,
    longitude: -89.6534,
    postal_code: '62706'
  },
  '62707': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8544,
    longitude: -89.6544,
    postal_code: '62707'
  },
  '62708': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8061,
    longitude: -89.5864,
    postal_code: '62708'
  },
  '62711': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7655,
    longitude: -89.7293,
    postal_code: '62711'
  },
  '62712': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7533,
    longitude: -89.58,
    postal_code: '62712'
  },
  '62715': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8017,
    longitude: -89.6437,
    postal_code: '62715'
  },
  '62716': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8482,
    longitude: -89.5364,
    postal_code: '62716'
  },
  '62719': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62719'
  },
  '62722': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62722'
  },
  '62723': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62723'
  },
  '62726': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62726'
  },
  '62736': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62736'
  },
  '62739': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8017,
    longitude: -89.6437,
    postal_code: '62739'
  },
  '62756': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62756'
  },
  '62757': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8017,
    longitude: -89.6437,
    postal_code: '62757'
  },
  '62761': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8524,
    longitude: -89.541,
    postal_code: '62761'
  },
  '62762': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62762'
  },
  '62763': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62763'
  },
  '62764': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62764'
  },
  '62765': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8017,
    longitude: -89.6437,
    postal_code: '62765'
  },
  '62766': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62766'
  },
  '62767': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62767'
  },
  '62769': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.8017,
    longitude: -89.6437,
    postal_code: '62769'
  },
  '62776': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62776'
  },
  '62777': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62777'
  },
  '62781': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62781'
  },
  '62786': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62786'
  },
  '62791': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62791'
  },
  '62794': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62794'
  },
  '62796': {
    country: 'US',
    city: 'Springfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Sangamon',
    latitude: 39.7495,
    longitude: -89.606,
    postal_code: '62796'
  },
  '62801': {
    country: 'US',
    city: 'Centralia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.5241,
    longitude: -89.1365,
    postal_code: '62801'
  },
  '62803': {
    country: 'US',
    city: 'Hoyleton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Washington',
    latitude: 38.4455,
    longitude: -89.3069,
    postal_code: '62803'
  },
  '62806': {
    country: 'US',
    city: 'Albion',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edwards',
    latitude: 38.374,
    longitude: -88.0636,
    postal_code: '62806'
  },
  '62807': {
    country: 'US',
    city: 'Alma',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.7231,
    longitude: -88.9157,
    postal_code: '62807'
  },
  '62808': {
    country: 'US',
    city: 'Ashley',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Washington',
    latitude: 38.306,
    longitude: -89.2311,
    postal_code: '62808'
  },
  '62809': {
    country: 'US',
    city: 'Barnhill',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.2845,
    longitude: -88.3642,
    postal_code: '62809'
  },
  '62810': {
    country: 'US',
    city: 'Belle Rive',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.2153,
    longitude: -88.7558,
    postal_code: '62810'
  },
  '62811': {
    country: 'US',
    city: 'Bellmont',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wabash',
    latitude: 38.3845,
    longitude: -87.9081,
    postal_code: '62811'
  },
  '62812': {
    country: 'US',
    city: 'Benton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 38,
    longitude: -88.9227,
    postal_code: '62812'
  },
  '62814': {
    country: 'US',
    city: 'Bluford',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.3531,
    longitude: -88.7587,
    postal_code: '62814'
  },
  '62815': {
    country: 'US',
    city: 'Bone Gap',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edwards',
    latitude: 38.449,
    longitude: -88.0025,
    postal_code: '62815'
  },
  '62816': {
    country: 'US',
    city: 'Bonnie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.198,
    longitude: -88.9229,
    postal_code: '62816'
  },
  '62817': {
    country: 'US',
    city: 'Broughton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hamilton',
    latitude: 37.9546,
    longitude: -88.4678,
    postal_code: '62817'
  },
  '62818': {
    country: 'US',
    city: 'Browns',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edwards',
    latitude: 38.3764,
    longitude: -87.9928,
    postal_code: '62818'
  },
  '62819': {
    country: 'US',
    city: 'Buckner',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.9764,
    longitude: -89.0209,
    postal_code: '62819'
  },
  '62820': {
    country: 'US',
    city: 'Burnt Prairie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'White',
    latitude: 38.2082,
    longitude: -88.2147,
    postal_code: '62820'
  },
  '62821': {
    country: 'US',
    city: 'Carmi',
    state: 'Illinois',
    state_short: 'IL',
    county: 'White',
    latitude: 38.0808,
    longitude: -88.167,
    postal_code: '62821'
  },
  '62822': {
    country: 'US',
    city: 'Christopher',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.9849,
    longitude: -89.0574,
    postal_code: '62822'
  },
  '62823': {
    country: 'US',
    city: 'Cisne',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.5159,
    longitude: -88.4375,
    postal_code: '62823'
  },
  '62824': {
    country: 'US',
    city: 'Clay City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clay',
    latitude: 38.6695,
    longitude: -88.3516,
    postal_code: '62824'
  },
  '62825': {
    country: 'US',
    city: 'Coello',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.9946,
    longitude: -89.0672,
    postal_code: '62825'
  },
  '62827': {
    country: 'US',
    city: 'Crossville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'White',
    latitude: 38.166,
    longitude: -88.0595,
    postal_code: '62827'
  },
  '62828': {
    country: 'US',
    city: 'Dahlgren',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hamilton',
    latitude: 38.1975,
    longitude: -88.6363,
    postal_code: '62828'
  },
  '62829': {
    country: 'US',
    city: 'Dale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hamilton',
    latitude: 37.995,
    longitude: -88.4917,
    postal_code: '62829'
  },
  '62830': {
    country: 'US',
    city: 'Dix',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.4333,
    longitude: -88.9657,
    postal_code: '62830'
  },
  '62831': {
    country: 'US',
    city: 'Du Bois',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Washington',
    latitude: 38.2239,
    longitude: -89.2123,
    postal_code: '62831'
  },
  '62832': {
    country: 'US',
    city: 'Du Quoin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Perry',
    latitude: 38.0137,
    longitude: -89.2333,
    postal_code: '62832'
  },
  '62833': {
    country: 'US',
    city: 'Ellery',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Edwards',
    latitude: 38.365,
    longitude: -88.1335,
    postal_code: '62833'
  },
  '62834': {
    country: 'US',
    city: 'Emma',
    state: 'Illinois',
    state_short: 'IL',
    county: 'White',
    latitude: 37.9762,
    longitude: -88.1202,
    postal_code: '62834'
  },
  '62835': {
    country: 'US',
    city: 'Enfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'White',
    latitude: 38.0927,
    longitude: -88.3325,
    postal_code: '62835'
  },
  '62836': {
    country: 'US',
    city: 'Ewing',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 38.0702,
    longitude: -88.8504,
    postal_code: '62836'
  },
  '62837': {
    country: 'US',
    city: 'Fairfield',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.3782,
    longitude: -88.3593,
    postal_code: '62837'
  },
  '62838': {
    country: 'US',
    city: 'Farina',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fayette',
    latitude: 38.8469,
    longitude: -88.7614,
    postal_code: '62838'
  },
  '62839': {
    country: 'US',
    city: 'Flora',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clay',
    latitude: 38.6703,
    longitude: -88.4919,
    postal_code: '62839'
  },
  '62840': {
    country: 'US',
    city: 'Frankfort Heights',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.9943,
    longitude: -88.9417,
    postal_code: '62840'
  },
  '62841': {
    country: 'US',
    city: 'Freeman Spur',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.8612,
    longitude: -88.9973,
    postal_code: '62841'
  },
  '62842': {
    country: 'US',
    city: 'Geff',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.4413,
    longitude: -88.4144,
    postal_code: '62842'
  },
  '62843': {
    country: 'US',
    city: 'Golden Gate',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.3645,
    longitude: -88.2075,
    postal_code: '62843'
  },
  '62844': {
    country: 'US',
    city: 'Grayville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'White',
    latitude: 38.2627,
    longitude: -88.0035,
    postal_code: '62844'
  },
  '62846': {
    country: 'US',
    city: 'Ina',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.1527,
    longitude: -88.8894,
    postal_code: '62846'
  },
  '62848': {
    country: 'US',
    city: 'Irvington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Washington',
    latitude: 38.4366,
    longitude: -89.1648,
    postal_code: '62848'
  },
  '62849': {
    country: 'US',
    city: 'Iuka',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.6136,
    longitude: -88.7689,
    postal_code: '62849'
  },
  '62850': {
    country: 'US',
    city: 'Johnsonville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.5223,
    longitude: -88.537,
    postal_code: '62850'
  },
  '62851': {
    country: 'US',
    city: 'Keenes',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.3691,
    longitude: -88.6481,
    postal_code: '62851'
  },
  '62852': {
    country: 'US',
    city: 'Keensburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wabash',
    latitude: 38.3514,
    longitude: -87.8661,
    postal_code: '62852'
  },
  '62853': {
    country: 'US',
    city: 'Kell',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.5147,
    longitude: -88.9127,
    postal_code: '62853'
  },
  '62854': {
    country: 'US',
    city: 'Kinmundy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.7559,
    longitude: -88.813,
    postal_code: '62854'
  },
  '62855': {
    country: 'US',
    city: 'Lancaster',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wabash',
    latitude: 38.4034,
    longitude: -87.8187,
    postal_code: '62855'
  },
  '62856': {
    country: 'US',
    city: 'Logan',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.9559,
    longitude: -88.8403,
    postal_code: '62856'
  },
  '62858': {
    country: 'US',
    city: 'Louisville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clay',
    latitude: 38.7718,
    longitude: -88.5065,
    postal_code: '62858'
  },
  '62859': {
    country: 'US',
    city: 'Mc Leansboro',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hamilton',
    latitude: 38.0939,
    longitude: -88.5286,
    postal_code: '62859'
  },
  '62860': {
    country: 'US',
    city: 'Macedonia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hamilton',
    latitude: 38.013,
    longitude: -88.6961,
    postal_code: '62860'
  },
  '62861': {
    country: 'US',
    city: 'Maunie',
    state: 'Illinois',
    state_short: 'IL',
    county: 'White',
    latitude: 38.0356,
    longitude: -88.0456,
    postal_code: '62861'
  },
  '62862': {
    country: 'US',
    city: 'Mill Shoals',
    state: 'Illinois',
    state_short: 'IL',
    county: 'White',
    latitude: 38.2447,
    longitude: -88.3338,
    postal_code: '62862'
  },
  '62863': {
    country: 'US',
    city: 'Mount Carmel',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wabash',
    latitude: 38.4147,
    longitude: -87.7911,
    postal_code: '62863'
  },
  '62864': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.317,
    longitude: -88.9105,
    postal_code: '62864'
  },
  '62865': {
    country: 'US',
    city: 'Mulkeytown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.9687,
    longitude: -89.1159,
    postal_code: '62865'
  },
  '62866': {
    country: 'US',
    city: 'Nason',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.1654,
    longitude: -88.9676,
    postal_code: '62866'
  },
  '62867': {
    country: 'US',
    city: 'New Haven',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Gallatin',
    latitude: 37.8999,
    longitude: -88.1285,
    postal_code: '62867'
  },
  '62868': {
    country: 'US',
    city: 'Noble',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Richland',
    latitude: 38.7119,
    longitude: -88.219,
    postal_code: '62868'
  },
  '62869': {
    country: 'US',
    city: 'Norris City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'White',
    latitude: 37.9773,
    longitude: -88.3243,
    postal_code: '62869'
  },
  '62870': {
    country: 'US',
    city: 'Odin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.6088,
    longitude: -89.0552,
    postal_code: '62870'
  },
  '62871': {
    country: 'US',
    city: 'Omaha',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Gallatin',
    latitude: 37.8904,
    longitude: -88.2865,
    postal_code: '62871'
  },
  '62872': {
    country: 'US',
    city: 'Opdyke',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.275,
    longitude: -88.775,
    postal_code: '62872'
  },
  '62874': {
    country: 'US',
    city: 'Orient',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.9182,
    longitude: -88.9767,
    postal_code: '62874'
  },
  '62875': {
    country: 'US',
    city: 'Patoka',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.7549,
    longitude: -89.0942,
    postal_code: '62875'
  },
  '62876': {
    country: 'US',
    city: 'Radom',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Washington',
    latitude: 38.2607,
    longitude: -89.1989,
    postal_code: '62876'
  },
  '62877': {
    country: 'US',
    city: 'Richview',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Washington',
    latitude: 38.4082,
    longitude: -89.1756,
    postal_code: '62877'
  },
  '62878': {
    country: 'US',
    city: 'Rinard',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.5806,
    longitude: -88.4641,
    postal_code: '62878'
  },
  '62879': {
    country: 'US',
    city: 'Sailor Springs',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clay',
    latitude: 38.7639,
    longitude: -88.3631,
    postal_code: '62879'
  },
  '62880': {
    country: 'US',
    city: 'Saint Peter',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fayette',
    latitude: 38.8697,
    longitude: -88.856,
    postal_code: '62880'
  },
  '62881': {
    country: 'US',
    city: 'Salem',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.6264,
    longitude: -88.9481,
    postal_code: '62881'
  },
  '62882': {
    country: 'US',
    city: 'Sandoval',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.6131,
    longitude: -89.114,
    postal_code: '62882'
  },
  '62883': {
    country: 'US',
    city: 'Scheller',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.1731,
    longitude: -89.0927,
    postal_code: '62883'
  },
  '62884': {
    country: 'US',
    city: 'Sesser',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 38.0894,
    longitude: -89.0574,
    postal_code: '62884'
  },
  '62885': {
    country: 'US',
    city: 'Shobonier',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Fayette',
    latitude: 38.8446,
    longitude: -89.079,
    postal_code: '62885'
  },
  '62886': {
    country: 'US',
    city: 'Sims',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.3923,
    longitude: -88.5306,
    postal_code: '62886'
  },
  '62887': {
    country: 'US',
    city: 'Springerton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'White',
    latitude: 38.1699,
    longitude: -88.3726,
    postal_code: '62887'
  },
  '62888': {
    country: 'US',
    city: 'Tamaroa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Perry',
    latitude: 38.138,
    longitude: -89.2231,
    postal_code: '62888'
  },
  '62889': {
    country: 'US',
    city: 'Texico',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.425,
    longitude: -88.8702,
    postal_code: '62889'
  },
  '62890': {
    country: 'US',
    city: 'Thompsonville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.8804,
    longitude: -88.7684,
    postal_code: '62890'
  },
  '62891': {
    country: 'US',
    city: 'Valier',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 38.0222,
    longitude: -89.0351,
    postal_code: '62891'
  },
  '62892': {
    country: 'US',
    city: 'Vernon',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.8033,
    longitude: -89.083,
    postal_code: '62892'
  },
  '62893': {
    country: 'US',
    city: 'Walnut Hill',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Marion',
    latitude: 38.467,
    longitude: -89.0328,
    postal_code: '62893'
  },
  '62894': {
    country: 'US',
    city: 'Waltonville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.2089,
    longitude: -89.039,
    postal_code: '62894'
  },
  '62895': {
    country: 'US',
    city: 'Wayne City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Wayne',
    latitude: 38.3328,
    longitude: -88.5833,
    postal_code: '62895'
  },
  '62896': {
    country: 'US',
    city: 'West Frankfort',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.8979,
    longitude: -88.9307,
    postal_code: '62896'
  },
  '62897': {
    country: 'US',
    city: 'Whittington',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 38.0872,
    longitude: -88.9014,
    postal_code: '62897'
  },
  '62898': {
    country: 'US',
    city: 'Woodlawn',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jefferson',
    latitude: 38.3844,
    longitude: -89.0745,
    postal_code: '62898'
  },
  '62899': {
    country: 'US',
    city: 'Xenia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Clay',
    latitude: 38.6359,
    longitude: -88.6348,
    postal_code: '62899'
  },
  '62901': {
    country: 'US',
    city: 'Carbondale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.72,
    longitude: -89.2158,
    postal_code: '62901'
  },
  '62902': {
    country: 'US',
    city: 'Carbondale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.6636,
    longitude: -89.1173,
    postal_code: '62902'
  },
  '62903': {
    country: 'US',
    city: 'Carbondale',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.6704,
    longitude: -89.2778,
    postal_code: '62903'
  },
  '62905': {
    country: 'US',
    city: 'Alto Pass',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Union',
    latitude: 37.5681,
    longitude: -89.3172,
    postal_code: '62905'
  },
  '62906': {
    country: 'US',
    city: 'Anna',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Union',
    latitude: 37.4668,
    longitude: -89.2207,
    postal_code: '62906'
  },
  '62907': {
    country: 'US',
    city: 'Ava',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.8793,
    longitude: -89.4654,
    postal_code: '62907'
  },
  '62908': {
    country: 'US',
    city: 'Belknap',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Johnson',
    latitude: 37.3266,
    longitude: -88.9507,
    postal_code: '62908'
  },
  '62909': {
    country: 'US',
    city: 'Boles',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Johnson',
    latitude: 37.4472,
    longitude: -88.8768,
    postal_code: '62909'
  },
  '62910': {
    country: 'US',
    city: 'Brookport',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Massac',
    latitude: 37.1542,
    longitude: -88.5334,
    postal_code: '62910'
  },
  '62912': {
    country: 'US',
    city: 'Buncombe',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Johnson',
    latitude: 37.4637,
    longitude: -88.9806,
    postal_code: '62912'
  },
  '62914': {
    country: 'US',
    city: 'Cairo',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Alexander',
    latitude: 37.0123,
    longitude: -89.1811,
    postal_code: '62914'
  },
  '62915': {
    country: 'US',
    city: 'Cambria',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.7814,
    longitude: -89.1192,
    postal_code: '62915'
  },
  '62916': {
    country: 'US',
    city: 'Campbell Hill',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.9228,
    longitude: -89.5799,
    postal_code: '62916'
  },
  '62917': {
    country: 'US',
    city: 'Carrier Mills',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Saline',
    latitude: 37.6842,
    longitude: -88.6328,
    postal_code: '62917'
  },
  '62918': {
    country: 'US',
    city: 'Carterville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.7748,
    longitude: -89.0978,
    postal_code: '62918'
  },
  '62919': {
    country: 'US',
    city: 'Cave In Rock',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hardin',
    latitude: 37.4692,
    longitude: -88.1653,
    postal_code: '62919'
  },
  '62920': {
    country: 'US',
    city: 'Cobden',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Union',
    latitude: 37.5424,
    longitude: -89.2457,
    postal_code: '62920'
  },
  '62921': {
    country: 'US',
    city: 'Colp',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.8053,
    longitude: -89.0801,
    postal_code: '62921'
  },
  '62922': {
    country: 'US',
    city: 'Creal Springs',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.6195,
    longitude: -88.8367,
    postal_code: '62922'
  },
  '62923': {
    country: 'US',
    city: 'Cypress',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Johnson',
    latitude: 37.365,
    longitude: -88.9747,
    postal_code: '62923'
  },
  '62924': {
    country: 'US',
    city: 'De Soto',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.8147,
    longitude: -89.2218,
    postal_code: '62924'
  },
  '62926': {
    country: 'US',
    city: 'Dongola',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Union',
    latitude: 37.3712,
    longitude: -89.1349,
    postal_code: '62926'
  },
  '62927': {
    country: 'US',
    city: 'Dowell',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.9398,
    longitude: -89.2379,
    postal_code: '62927'
  },
  '62928': {
    country: 'US',
    city: 'Eddyville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pope',
    latitude: 37.4829,
    longitude: -88.5791,
    postal_code: '62928'
  },
  '62930': {
    country: 'US',
    city: 'Eldorado',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Saline',
    latitude: 37.8139,
    longitude: -88.4434,
    postal_code: '62930'
  },
  '62931': {
    country: 'US',
    city: 'Elizabethtown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hardin',
    latitude: 37.4665,
    longitude: -88.2867,
    postal_code: '62931'
  },
  '62932': {
    country: 'US',
    city: 'Elkville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.9155,
    longitude: -89.2336,
    postal_code: '62932'
  },
  '62933': {
    country: 'US',
    city: 'Energy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.7726,
    longitude: -89.025,
    postal_code: '62933'
  },
  '62934': {
    country: 'US',
    city: 'Equality',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Gallatin',
    latitude: 37.7278,
    longitude: -88.3445,
    postal_code: '62934'
  },
  '62935': {
    country: 'US',
    city: 'Galatia',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Saline',
    latitude: 37.8274,
    longitude: -88.6235,
    postal_code: '62935'
  },
  '62938': {
    country: 'US',
    city: 'Golconda',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pope',
    latitude: 37.3602,
    longitude: -88.4886,
    postal_code: '62938'
  },
  '62939': {
    country: 'US',
    city: 'Goreville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Johnson',
    latitude: 37.575,
    longitude: -88.9655,
    postal_code: '62939'
  },
  '62940': {
    country: 'US',
    city: 'Gorham',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.7406,
    longitude: -89.444,
    postal_code: '62940'
  },
  '62941': {
    country: 'US',
    city: 'Grand Chain',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pulaski',
    latitude: 37.251,
    longitude: -89.0083,
    postal_code: '62941'
  },
  '62942': {
    country: 'US',
    city: 'Grand Tower',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.6322,
    longitude: -89.4999,
    postal_code: '62942'
  },
  '62943': {
    country: 'US',
    city: 'Grantsburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Johnson',
    latitude: 37.3794,
    longitude: -88.7583,
    postal_code: '62943'
  },
  '62946': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Saline',
    latitude: 37.7257,
    longitude: -88.544,
    postal_code: '62946'
  },
  '62947': {
    country: 'US',
    city: 'Herod',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pope',
    latitude: 37.5634,
    longitude: -88.44,
    postal_code: '62947'
  },
  '62948': {
    country: 'US',
    city: 'Herrin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.8019,
    longitude: -89.0232,
    postal_code: '62948'
  },
  '62949': {
    country: 'US',
    city: 'Hurst',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.8366,
    longitude: -89.1424,
    postal_code: '62949'
  },
  '62950': {
    country: 'US',
    city: 'Jacob',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.7437,
    longitude: -89.5444,
    postal_code: '62950'
  },
  '62951': {
    country: 'US',
    city: 'Johnston City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.8245,
    longitude: -88.9209,
    postal_code: '62951'
  },
  '62952': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Union',
    latitude: 37.4461,
    longitude: -89.2915,
    postal_code: '62952'
  },
  '62953': {
    country: 'US',
    city: 'Joppa',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Massac',
    latitude: 37.2092,
    longitude: -88.8442,
    postal_code: '62953'
  },
  '62954': {
    country: 'US',
    city: 'Junction',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Gallatin',
    latitude: 37.6952,
    longitude: -88.2491,
    postal_code: '62954'
  },
  '62955': {
    country: 'US',
    city: 'Karbers Ridge',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hardin',
    latitude: 37.5008,
    longitude: -88.2383,
    postal_code: '62955'
  },
  '62956': {
    country: 'US',
    city: 'Karnak',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pulaski',
    latitude: 37.2911,
    longitude: -88.9739,
    postal_code: '62956'
  },
  '62957': {
    country: 'US',
    city: 'Mc Clure',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Alexander',
    latitude: 37.302,
    longitude: -89.4531,
    postal_code: '62957'
  },
  '62958': {
    country: 'US',
    city: 'Makanda',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.6176,
    longitude: -89.209,
    postal_code: '62958'
  },
  '62959': {
    country: 'US',
    city: 'Marion',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.7257,
    longitude: -88.9294,
    postal_code: '62959'
  },
  '62960': {
    country: 'US',
    city: 'Metropolis',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Massac',
    latitude: 37.1753,
    longitude: -88.7252,
    postal_code: '62960'
  },
  '62961': {
    country: 'US',
    city: 'Millcreek',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Union',
    latitude: 37.3409,
    longitude: -89.2542,
    postal_code: '62961'
  },
  '62962': {
    country: 'US',
    city: 'Miller City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Alexander',
    latitude: 37.1034,
    longitude: -89.3494,
    postal_code: '62962'
  },
  '62963': {
    country: 'US',
    city: 'Mound City',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pulaski',
    latitude: 37.0865,
    longitude: -89.1637,
    postal_code: '62963'
  },
  '62964': {
    country: 'US',
    city: 'Mounds',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pulaski',
    latitude: 37.1188,
    longitude: -89.2001,
    postal_code: '62964'
  },
  '62965': {
    country: 'US',
    city: 'Muddy',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Saline',
    latitude: 37.765,
    longitude: -88.5167,
    postal_code: '62965'
  },
  '62966': {
    country: 'US',
    city: 'Murphysboro',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.7655,
    longitude: -89.3317,
    postal_code: '62966'
  },
  '62967': {
    country: 'US',
    city: 'New Burnside',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Johnson',
    latitude: 37.578,
    longitude: -88.7454,
    postal_code: '62967'
  },
  '62969': {
    country: 'US',
    city: 'Olive Branch',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Alexander',
    latitude: 37.1674,
    longitude: -89.3537,
    postal_code: '62969'
  },
  '62970': {
    country: 'US',
    city: 'Olmsted',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pulaski',
    latitude: 37.1935,
    longitude: -89.0933,
    postal_code: '62970'
  },
  '62971': {
    country: 'US',
    city: 'Oraville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.8651,
    longitude: -89.3834,
    postal_code: '62971'
  },
  '62972': {
    country: 'US',
    city: 'Ozark',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Johnson',
    latitude: 37.5367,
    longitude: -88.7688,
    postal_code: '62972'
  },
  '62973': {
    country: 'US',
    city: 'Perks',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pulaski',
    latitude: 37.3113,
    longitude: -89.0831,
    postal_code: '62973'
  },
  '62974': {
    country: 'US',
    city: 'Pittsburg',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Williamson',
    latitude: 37.8047,
    longitude: -88.8081,
    postal_code: '62974'
  },
  '62975': {
    country: 'US',
    city: 'Pomona',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.6501,
    longitude: -89.3693,
    postal_code: '62975'
  },
  '62976': {
    country: 'US',
    city: 'Pulaski',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pulaski',
    latitude: 37.2146,
    longitude: -89.1968,
    postal_code: '62976'
  },
  '62977': {
    country: 'US',
    city: 'Raleigh',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Saline',
    latitude: 37.8256,
    longitude: -88.5324,
    postal_code: '62977'
  },
  '62979': {
    country: 'US',
    city: 'Ridgway',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Gallatin',
    latitude: 37.8042,
    longitude: -88.2612,
    postal_code: '62979'
  },
  '62982': {
    country: 'US',
    city: 'Rosiclare',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Hardin',
    latitude: 37.424,
    longitude: -88.3462,
    postal_code: '62982'
  },
  '62983': {
    country: 'US',
    city: 'Royalton',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.879,
    longitude: -89.1141,
    postal_code: '62983'
  },
  '62984': {
    country: 'US',
    city: 'Shawneetown',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Gallatin',
    latitude: 37.7132,
    longitude: -88.1785,
    postal_code: '62984'
  },
  '62985': {
    country: 'US',
    city: 'Simpson',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Johnson',
    latitude: 37.4673,
    longitude: -88.7551,
    postal_code: '62985'
  },
  '62987': {
    country: 'US',
    city: 'Stonefort',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Saline',
    latitude: 37.6432,
    longitude: -88.6228,
    postal_code: '62987'
  },
  '62988': {
    country: 'US',
    city: 'Tamms',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Alexander',
    latitude: 37.2345,
    longitude: -89.2763,
    postal_code: '62988'
  },
  '62990': {
    country: 'US',
    city: 'Thebes',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Alexander',
    latitude: 37.221,
    longitude: -89.4599,
    postal_code: '62990'
  },
  '62992': {
    country: 'US',
    city: 'Ullin',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pulaski',
    latitude: 37.277,
    longitude: -89.1834,
    postal_code: '62992'
  },
  '62993': {
    country: 'US',
    city: 'Unity',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Alexander',
    latitude: 37.1528,
    longitude: -89.3256,
    postal_code: '62993'
  },
  '62994': {
    country: 'US',
    city: 'Vergennes',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Jackson',
    latitude: 37.9051,
    longitude: -89.3269,
    postal_code: '62994'
  },
  '62995': {
    country: 'US',
    city: 'Vienna',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Johnson',
    latitude: 37.4205,
    longitude: -88.8879,
    postal_code: '62995'
  },
  '62996': {
    country: 'US',
    city: 'Villa Ridge',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Pulaski',
    latitude: 37.1578,
    longitude: -89.1825,
    postal_code: '62996'
  },
  '62997': {
    country: 'US',
    city: 'Willisville',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Perry',
    latitude: 37.9848,
    longitude: -89.5899,
    postal_code: '62997'
  },
  '62998': {
    country: 'US',
    city: 'Wolf Lake',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Union',
    latitude: 37.512,
    longitude: -89.4408,
    postal_code: '62998'
  },
  '62999': {
    country: 'US',
    city: 'Zeigler',
    state: 'Illinois',
    state_short: 'IL',
    county: 'Franklin',
    latitude: 37.8991,
    longitude: -89.0523,
    postal_code: '62999'
  },
  '63005': {
    country: 'US',
    city: 'Chesterfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6318,
    longitude: -90.6142,
    postal_code: '63005'
  },
  '63006': {
    country: 'US',
    city: 'Chesterfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6631,
    longitude: -90.5771,
    postal_code: '63006'
  },
  '63010': {
    country: 'US',
    city: 'Arnold',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.4305,
    longitude: -90.387,
    postal_code: '63010'
  },
  '63011': {
    country: 'US',
    city: 'Ballwin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6091,
    longitude: -90.5598,
    postal_code: '63011'
  },
  '63012': {
    country: 'US',
    city: 'Barnhart',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.3384,
    longitude: -90.4142,
    postal_code: '63012'
  },
  '63013': {
    country: 'US',
    city: 'Beaufort',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.4294,
    longitude: -91.1709,
    postal_code: '63013'
  },
  '63014': {
    country: 'US',
    city: 'Berger',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.6444,
    longitude: -91.3374,
    postal_code: '63014'
  },
  '63015': {
    country: 'US',
    city: 'Catawissa',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.4047,
    longitude: -90.7806,
    postal_code: '63015'
  },
  '63016': {
    country: 'US',
    city: 'Cedar Hill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.3573,
    longitude: -90.6498,
    postal_code: '63016'
  },
  '63017': {
    country: 'US',
    city: 'Chesterfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6491,
    longitude: -90.5358,
    postal_code: '63017'
  },
  '63019': {
    country: 'US',
    city: 'Crystal City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.23,
    longitude: -90.3825,
    postal_code: '63019'
  },
  '63020': {
    country: 'US',
    city: 'De Soto',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.1204,
    longitude: -90.5546,
    postal_code: '63020'
  },
  '63021': {
    country: 'US',
    city: 'Ballwin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.577,
    longitude: -90.5255,
    postal_code: '63021'
  },
  '63022': {
    country: 'US',
    city: 'Ballwin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5951,
    longitude: -90.5462,
    postal_code: '63022'
  },
  '63023': {
    country: 'US',
    city: 'Dittmer',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.3155,
    longitude: -90.6911,
    postal_code: '63023'
  },
  '63024': {
    country: 'US',
    city: 'Ballwin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5951,
    longitude: -90.5462,
    postal_code: '63024'
  },
  '63025': {
    country: 'US',
    city: 'Eureka',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5128,
    longitude: -90.6306,
    postal_code: '63025'
  },
  '63026': {
    country: 'US',
    city: 'Fenton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5015,
    longitude: -90.4683,
    postal_code: '63026'
  },
  '63028': {
    country: 'US',
    city: 'Festus',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.1879,
    longitude: -90.4286,
    postal_code: '63028'
  },
  '63030': {
    country: 'US',
    city: 'Fletcher',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.1659,
    longitude: -90.7335,
    postal_code: '63030'
  },
  '63031': {
    country: 'US',
    city: 'Florissant',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.8069,
    longitude: -90.3401,
    postal_code: '63031'
  },
  '63032': {
    country: 'US',
    city: 'Florissant',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6383,
    longitude: -90.4271,
    postal_code: '63032'
  },
  '63033': {
    country: 'US',
    city: 'Florissant',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7947,
    longitude: -90.2831,
    postal_code: '63033'
  },
  '63034': {
    country: 'US',
    city: 'Florissant',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.8338,
    longitude: -90.2936,
    postal_code: '63034'
  },
  '63036': {
    country: 'US',
    city: 'French Village',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Francois',
    latitude: 37.9961,
    longitude: -90.4005,
    postal_code: '63036'
  },
  '63037': {
    country: 'US',
    city: 'Gerald',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.3507,
    longitude: -91.2931,
    postal_code: '63037'
  },
  '63038': {
    country: 'US',
    city: 'Glencoe',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5878,
    longitude: -90.6639,
    postal_code: '63038'
  },
  '63039': {
    country: 'US',
    city: 'Gray Summit',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.503,
    longitude: -90.8292,
    postal_code: '63039'
  },
  '63040': {
    country: 'US',
    city: 'Grover',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5667,
    longitude: -90.631,
    postal_code: '63040'
  },
  '63041': {
    country: 'US',
    city: 'Grubville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.251,
    longitude: -90.7901,
    postal_code: '63041'
  },
  '63042': {
    country: 'US',
    city: 'Hazelwood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7809,
    longitude: -90.3669,
    postal_code: '63042'
  },
  '63043': {
    country: 'US',
    city: 'Maryland Heights',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7229,
    longitude: -90.4474,
    postal_code: '63043'
  },
  '63044': {
    country: 'US',
    city: 'Bridgeton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7506,
    longitude: -90.4161,
    postal_code: '63044'
  },
  '63045': {
    country: 'US',
    city: 'Earth City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7689,
    longitude: -90.4662,
    postal_code: '63045'
  },
  '63047': {
    country: 'US',
    city: 'Hematite',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.202,
    longitude: -90.481,
    postal_code: '63047'
  },
  '63048': {
    country: 'US',
    city: 'Herculaneum',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.2625,
    longitude: -90.3896,
    postal_code: '63048'
  },
  '63049': {
    country: 'US',
    city: 'High Ridge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.4728,
    longitude: -90.5281,
    postal_code: '63049'
  },
  '63050': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.2586,
    longitude: -90.5782,
    postal_code: '63050'
  },
  '63051': {
    country: 'US',
    city: 'House Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.4131,
    longitude: -90.5575,
    postal_code: '63051'
  },
  '63052': {
    country: 'US',
    city: 'Imperial',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.4069,
    longitude: -90.4381,
    postal_code: '63052'
  },
  '63053': {
    country: 'US',
    city: 'Kimmswick',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.3653,
    longitude: -90.3629,
    postal_code: '63053'
  },
  '63055': {
    country: 'US',
    city: 'Labadie',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.52,
    longitude: -90.877,
    postal_code: '63055'
  },
  '63056': {
    country: 'US',
    city: 'Leslie',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.4555,
    longitude: -91.2328,
    postal_code: '63056'
  },
  '63057': {
    country: 'US',
    city: 'Liguori',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.3419,
    longitude: -90.4082,
    postal_code: '63057'
  },
  '63060': {
    country: 'US',
    city: 'Lonedell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.2743,
    longitude: -90.8903,
    postal_code: '63060'
  },
  '63061': {
    country: 'US',
    city: 'Luebbering',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.2615,
    longitude: -90.8027,
    postal_code: '63061'
  },
  '63065': {
    country: 'US',
    city: 'Mapaville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.2489,
    longitude: -90.4835,
    postal_code: '63065'
  },
  '63066': {
    country: 'US',
    city: 'Morse Mill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.2809,
    longitude: -90.6521,
    postal_code: '63066'
  },
  '63068': {
    country: 'US',
    city: 'New Haven',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.574,
    longitude: -91.2291,
    postal_code: '63068'
  },
  '63069': {
    country: 'US',
    city: 'Pacific',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.4922,
    longitude: -90.748,
    postal_code: '63069'
  },
  '63070': {
    country: 'US',
    city: 'Pevely',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jefferson',
    latitude: 38.2799,
    longitude: -90.4111,
    postal_code: '63070'
  },
  '63071': {
    country: 'US',
    city: 'Richwoods',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Washington',
    latitude: 38.1496,
    longitude: -90.831,
    postal_code: '63071'
  },
  '63072': {
    country: 'US',
    city: 'Robertsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.3816,
    longitude: -90.8016,
    postal_code: '63072'
  },
  '63073': {
    country: 'US',
    city: 'Saint Albans',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.5792,
    longitude: -90.7751,
    postal_code: '63073'
  },
  '63074': {
    country: 'US',
    city: 'Saint Ann',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7259,
    longitude: -90.3864,
    postal_code: '63074'
  },
  '63077': {
    country: 'US',
    city: 'Saint Clair',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.3299,
    longitude: -90.9713,
    postal_code: '63077'
  },
  '63079': {
    country: 'US',
    city: 'Stanton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.2745,
    longitude: -91.1057,
    postal_code: '63079'
  },
  '63080': {
    country: 'US',
    city: 'Sullivan',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.2307,
    longitude: -91.1567,
    postal_code: '63080'
  },
  '63084': {
    country: 'US',
    city: 'Union',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.4456,
    longitude: -91.0206,
    postal_code: '63084'
  },
  '63087': {
    country: 'US',
    city: 'Valles Mines',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Francois',
    latitude: 37.9998,
    longitude: -90.4407,
    postal_code: '63087'
  },
  '63088': {
    country: 'US',
    city: 'Valley Park',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5576,
    longitude: -90.4924,
    postal_code: '63088'
  },
  '63089': {
    country: 'US',
    city: 'Villa Ridge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.4601,
    longitude: -90.8822,
    postal_code: '63089'
  },
  '63090': {
    country: 'US',
    city: 'Washington',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Franklin',
    latitude: 38.5459,
    longitude: -91.0193,
    postal_code: '63090'
  },
  '63091': {
    country: 'US',
    city: 'Rosebud',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gasconade',
    latitude: 38.3853,
    longitude: -91.3974,
    postal_code: '63091'
  },
  '63099': {
    country: 'US',
    city: 'Fenton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6383,
    longitude: -90.4271,
    postal_code: '63099'
  },
  '63101': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6346,
    longitude: -90.1913,
    postal_code: '63101'
  },
  '63102': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6352,
    longitude: -90.1864,
    postal_code: '63102'
  },
  '63103': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6332,
    longitude: -90.2164,
    postal_code: '63103'
  },
  '63104': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6128,
    longitude: -90.2185,
    postal_code: '63104'
  },
  '63105': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6459,
    longitude: -90.3264,
    postal_code: '63105'
  },
  '63106': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6442,
    longitude: -90.2082,
    postal_code: '63106'
  },
  '63107': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6645,
    longitude: -90.2125,
    postal_code: '63107'
  },
  '63108': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6445,
    longitude: -90.2544,
    postal_code: '63108'
  },
  '63109': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.5855,
    longitude: -90.2929,
    postal_code: '63109'
  },
  '63110': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6185,
    longitude: -90.2564,
    postal_code: '63110'
  },
  '63111': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.5633,
    longitude: -90.2495,
    postal_code: '63111'
  },
  '63112': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6616,
    longitude: -90.2819,
    postal_code: '63112'
  },
  '63113': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.659,
    longitude: -90.2496,
    postal_code: '63113'
  },
  '63114': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7023,
    longitude: -90.3644,
    postal_code: '63114'
  },
  '63115': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6756,
    longitude: -90.2385,
    postal_code: '63115'
  },
  '63116': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.5814,
    longitude: -90.2625,
    postal_code: '63116'
  },
  '63117': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6295,
    longitude: -90.3342,
    postal_code: '63117'
  },
  '63118': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.5943,
    longitude: -90.2309,
    postal_code: '63118'
  },
  '63119': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5893,
    longitude: -90.3481,
    postal_code: '63119'
  },
  '63120': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6909,
    longitude: -90.2595,
    postal_code: '63120'
  },
  '63121': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7071,
    longitude: -90.3055,
    postal_code: '63121'
  },
  '63122': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5781,
    longitude: -90.4256,
    postal_code: '63122'
  },
  '63123': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5476,
    longitude: -90.3241,
    postal_code: '63123'
  },
  '63124': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6372,
    longitude: -90.3776,
    postal_code: '63124'
  },
  '63125': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5222,
    longitude: -90.3021,
    postal_code: '63125'
  },
  '63126': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5495,
    longitude: -90.3811,
    postal_code: '63126'
  },
  '63127': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.5355,
    longitude: -90.407,
    postal_code: '63127'
  },
  '63128': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.4915,
    longitude: -90.3772,
    postal_code: '63128'
  },
  '63129': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.4566,
    longitude: -90.3282,
    postal_code: '63129'
  },
  '63130': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6669,
    longitude: -90.3225,
    postal_code: '63130'
  },
  '63131': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6171,
    longitude: -90.4504,
    postal_code: '63131'
  },
  '63132': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6746,
    longitude: -90.3747,
    postal_code: '63132'
  },
  '63133': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6779,
    longitude: -90.3033,
    postal_code: '63133'
  },
  '63134': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7435,
    longitude: -90.341,
    postal_code: '63134'
  },
  '63135': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7497,
    longitude: -90.3012,
    postal_code: '63135'
  },
  '63136': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7196,
    longitude: -90.27,
    postal_code: '63136'
  },
  '63137': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7468,
    longitude: -90.2131,
    postal_code: '63137'
  },
  '63138': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.8033,
    longitude: -90.2065,
    postal_code: '63138'
  },
  '63139': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6108,
    longitude: -90.292,
    postal_code: '63139'
  },
  '63140': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7375,
    longitude: -90.3265,
    postal_code: '63140'
  },
  '63141': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6565,
    longitude: -90.4542,
    postal_code: '63141'
  },
  '63143': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6111,
    longitude: -90.3225,
    postal_code: '63143'
  },
  '63144': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6182,
    longitude: -90.3489,
    postal_code: '63144'
  },
  '63145': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6383,
    longitude: -90.4271,
    postal_code: '63145'
  },
  '63146': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.7033,
    longitude: -90.4618,
    postal_code: '63146'
  },
  '63147': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.7139,
    longitude: -90.2375,
    postal_code: '63147'
  },
  '63150': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6273,
    longitude: -90.1979,
    postal_code: '63150'
  },
  '63151': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6383,
    longitude: -90.4271,
    postal_code: '63151'
  },
  '63155': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6273,
    longitude: -90.1979,
    postal_code: '63155'
  },
  '63156': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63156'
  },
  '63157': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63157'
  },
  '63158': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63158'
  },
  '63160': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63160'
  },
  '63163': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63163'
  },
  '63164': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63164'
  },
  '63166': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63166'
  },
  '63167': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis',
    latitude: 38.6383,
    longitude: -90.4271,
    postal_code: '63167'
  },
  '63169': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6273,
    longitude: -90.1979,
    postal_code: '63169'
  },
  '63171': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63171'
  },
  '63177': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63177'
  },
  '63178': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63178'
  },
  '63179': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63179'
  },
  '63180': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6273,
    longitude: -90.1979,
    postal_code: '63180'
  },
  '63182': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6273,
    longitude: -90.1979,
    postal_code: '63182'
  },
  '63188': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6273,
    longitude: -90.1979,
    postal_code: '63188'
  },
  '63195': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63195'
  },
  '63197': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6273,
    longitude: -90.1979,
    postal_code: '63197'
  },
  '63199': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Louis (city)',
    latitude: 38.6531,
    longitude: -90.2435,
    postal_code: '63199'
  },
  '63301': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.8014,
    longitude: -90.5065,
    postal_code: '63301'
  },
  '63302': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.7839,
    longitude: -90.4812,
    postal_code: '63302'
  },
  '63303': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.7622,
    longitude: -90.5471,
    postal_code: '63303'
  },
  '63304': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.7378,
    longitude: -90.6234,
    postal_code: '63304'
  },
  '63330': {
    country: 'US',
    city: 'Annada',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pike',
    latitude: 39.2558,
    longitude: -90.8224,
    postal_code: '63330'
  },
  '63332': {
    country: 'US',
    city: 'Augusta',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.5728,
    longitude: -90.8815,
    postal_code: '63332'
  },
  '63333': {
    country: 'US',
    city: 'Bellflower',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Montgomery',
    latitude: 39.0012,
    longitude: -91.3489,
    postal_code: '63333'
  },
  '63334': {
    country: 'US',
    city: 'Bowling Green',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pike',
    latitude: 39.3346,
    longitude: -91.1962,
    postal_code: '63334'
  },
  '63336': {
    country: 'US',
    city: 'Clarksville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pike',
    latitude: 39.3465,
    longitude: -90.9362,
    postal_code: '63336'
  },
  '63338': {
    country: 'US',
    city: 'Cottleville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.7462,
    longitude: -90.654,
    postal_code: '63338'
  },
  '63339': {
    country: 'US',
    city: 'Curryville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pike',
    latitude: 39.3257,
    longitude: -91.3493,
    postal_code: '63339'
  },
  '63341': {
    country: 'US',
    city: 'Defiance',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.6616,
    longitude: -90.8302,
    postal_code: '63341'
  },
  '63342': {
    country: 'US',
    city: 'Dutzow',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Warren',
    latitude: 38.606,
    longitude: -90.9962,
    postal_code: '63342'
  },
  '63343': {
    country: 'US',
    city: 'Elsberry',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 39.159,
    longitude: -90.816,
    postal_code: '63343'
  },
  '63344': {
    country: 'US',
    city: 'Eolia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pike',
    latitude: 39.2431,
    longitude: -91.0093,
    postal_code: '63344'
  },
  '63345': {
    country: 'US',
    city: 'Farber',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Audrain',
    latitude: 39.2742,
    longitude: -91.5798,
    postal_code: '63345'
  },
  '63346': {
    country: 'US',
    city: 'Flinthill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.7509,
    longitude: -90.5368,
    postal_code: '63346'
  },
  '63347': {
    country: 'US',
    city: 'Foley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 39.053,
    longitude: -90.7775,
    postal_code: '63347'
  },
  '63348': {
    country: 'US',
    city: 'Foristell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.7626,
    longitude: -90.9343,
    postal_code: '63348'
  },
  '63349': {
    country: 'US',
    city: 'Hawk Point',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 38.9766,
    longitude: -91.1211,
    postal_code: '63349'
  },
  '63350': {
    country: 'US',
    city: 'High Hill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Montgomery',
    latitude: 38.8902,
    longitude: -91.3715,
    postal_code: '63350'
  },
  '63351': {
    country: 'US',
    city: 'Jonesburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Montgomery',
    latitude: 38.8717,
    longitude: -91.3019,
    postal_code: '63351'
  },
  '63352': {
    country: 'US',
    city: 'Laddonia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Audrain',
    latitude: 39.2425,
    longitude: -91.6454,
    postal_code: '63352'
  },
  '63353': {
    country: 'US',
    city: 'Louisiana',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pike',
    latitude: 39.4336,
    longitude: -91.0664,
    postal_code: '63353'
  },
  '63357': {
    country: 'US',
    city: 'Marthasville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Warren',
    latitude: 38.6512,
    longitude: -91.1516,
    postal_code: '63357'
  },
  '63359': {
    country: 'US',
    city: 'Middletown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Montgomery',
    latitude: 39.1055,
    longitude: -91.3873,
    postal_code: '63359'
  },
  '63361': {
    country: 'US',
    city: 'Montgomery City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Montgomery',
    latitude: 38.9839,
    longitude: -91.5085,
    postal_code: '63361'
  },
  '63362': {
    country: 'US',
    city: 'Moscow Mills',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 38.9482,
    longitude: -90.9138,
    postal_code: '63362'
  },
  '63363': {
    country: 'US',
    city: 'New Florence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Montgomery',
    latitude: 38.9023,
    longitude: -91.4909,
    postal_code: '63363'
  },
  '63365': {
    country: 'US',
    city: 'New Melle',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.7163,
    longitude: -90.8751,
    postal_code: '63365'
  },
  '63366': {
    country: 'US',
    city: 'O Fallon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.8239,
    longitude: -90.7427,
    postal_code: '63366'
  },
  '63367': {
    country: 'US',
    city: 'Lake Saint Louis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.7936,
    longitude: -90.7854,
    postal_code: '63367'
  },
  '63368': {
    country: 'US',
    city: 'O Fallon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.7513,
    longitude: -90.7296,
    postal_code: '63368'
  },
  '63369': {
    country: 'US',
    city: 'Old Monroe',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 38.9346,
    longitude: -90.7782,
    postal_code: '63369'
  },
  '63370': {
    country: 'US',
    city: 'Olney',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 39.0839,
    longitude: -91.2432,
    postal_code: '63370'
  },
  '63373': {
    country: 'US',
    city: 'Portage Des Sioux',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.9259,
    longitude: -90.3863,
    postal_code: '63373'
  },
  '63376': {
    country: 'US',
    city: 'Saint Peters',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.7802,
    longitude: -90.6228,
    postal_code: '63376'
  },
  '63377': {
    country: 'US',
    city: 'Silex',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 39.1166,
    longitude: -91.037,
    postal_code: '63377'
  },
  '63378': {
    country: 'US',
    city: 'Treloar',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Warren',
    latitude: 38.6445,
    longitude: -91.1879,
    postal_code: '63378'
  },
  '63379': {
    country: 'US',
    city: 'Troy',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 39.0012,
    longitude: -90.9624,
    postal_code: '63379'
  },
  '63380': {
    country: 'US',
    city: 'Truesdale',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Warren',
    latitude: 38.8117,
    longitude: -91.1304,
    postal_code: '63380'
  },
  '63381': {
    country: 'US',
    city: 'Truxton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 38.967,
    longitude: -91.2126,
    postal_code: '63381'
  },
  '63382': {
    country: 'US',
    city: 'Vandalia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Audrain',
    latitude: 39.2949,
    longitude: -91.4883,
    postal_code: '63382'
  },
  '63383': {
    country: 'US',
    city: 'Warrenton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Warren',
    latitude: 38.805,
    longitude: -91.174,
    postal_code: '63383'
  },
  '63384': {
    country: 'US',
    city: 'Wellsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Montgomery',
    latitude: 39.0765,
    longitude: -91.5645,
    postal_code: '63384'
  },
  '63385': {
    country: 'US',
    city: 'Wentzville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.802,
    longitude: -90.8534,
    postal_code: '63385'
  },
  '63386': {
    country: 'US',
    city: 'West Alton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saint Charles',
    latitude: 38.8758,
    longitude: -90.2384,
    postal_code: '63386'
  },
  '63387': {
    country: 'US',
    city: 'Whiteside',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 39.1853,
    longitude: -91.0168,
    postal_code: '63387'
  },
  '63388': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Callaway',
    latitude: 38.8874,
    longitude: -91.7689,
    postal_code: '63388'
  },
  '63389': {
    country: 'US',
    city: 'Winfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lincoln',
    latitude: 38.9897,
    longitude: -90.8213,
    postal_code: '63389'
  },
  '63390': {
    country: 'US',
    city: 'Wright City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Warren',
    latitude: 38.8097,
    longitude: -91.0329,
    postal_code: '63390'
  },
  '63401': {
    country: 'US',
    city: 'Hannibal',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Marion',
    latitude: 39.7064,
    longitude: -91.3839,
    postal_code: '63401'
  },
  '63430': {
    country: 'US',
    city: 'Alexandria',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clark',
    latitude: 40.3445,
    longitude: -91.5154,
    postal_code: '63430'
  },
  '63431': {
    country: 'US',
    city: 'Anabel',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Macon',
    latitude: 39.7428,
    longitude: -92.328,
    postal_code: '63431'
  },
  '63432': {
    country: 'US',
    city: 'Arbela',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scotland',
    latitude: 40.4863,
    longitude: -92.0047,
    postal_code: '63432'
  },
  '63433': {
    country: 'US',
    city: 'Ashburn',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pike',
    latitude: 39.561,
    longitude: -91.1844,
    postal_code: '63433'
  },
  '63434': {
    country: 'US',
    city: 'Bethel',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shelby',
    latitude: 39.8922,
    longitude: -92.0316,
    postal_code: '63434'
  },
  '63435': {
    country: 'US',
    city: 'Canton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lewis',
    latitude: 40.1437,
    longitude: -91.548,
    postal_code: '63435'
  },
  '63436': {
    country: 'US',
    city: 'Center',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ralls',
    latitude: 39.5154,
    longitude: -91.5398,
    postal_code: '63436'
  },
  '63437': {
    country: 'US',
    city: 'Clarence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shelby',
    latitude: 39.7366,
    longitude: -92.253,
    postal_code: '63437'
  },
  '63438': {
    country: 'US',
    city: 'Durham',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lewis',
    latitude: 39.9625,
    longitude: -91.6704,
    postal_code: '63438'
  },
  '63439': {
    country: 'US',
    city: 'Emden',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shelby',
    latitude: 39.8019,
    longitude: -91.8606,
    postal_code: '63439'
  },
  '63440': {
    country: 'US',
    city: 'Ewing',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lewis',
    latitude: 39.9962,
    longitude: -91.7216,
    postal_code: '63440'
  },
  '63441': {
    country: 'US',
    city: 'Frankford',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pike',
    latitude: 39.4892,
    longitude: -91.3031,
    postal_code: '63441'
  },
  '63442': {
    country: 'US',
    city: 'Granger',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scotland',
    latitude: 40.4533,
    longitude: -92.1474,
    postal_code: '63442'
  },
  '63443': {
    country: 'US',
    city: 'Hunnewell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shelby',
    latitude: 39.7018,
    longitude: -91.8832,
    postal_code: '63443'
  },
  '63445': {
    country: 'US',
    city: 'Kahoka',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clark',
    latitude: 40.4266,
    longitude: -91.725,
    postal_code: '63445'
  },
  '63446': {
    country: 'US',
    city: 'Knox City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Knox',
    latitude: 40.1384,
    longitude: -92.0077,
    postal_code: '63446'
  },
  '63447': {
    country: 'US',
    city: 'La Belle',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lewis',
    latitude: 40.1164,
    longitude: -91.9171,
    postal_code: '63447'
  },
  '63448': {
    country: 'US',
    city: 'La Grange',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lewis',
    latitude: 40.0391,
    longitude: -91.5182,
    postal_code: '63448'
  },
  '63450': {
    country: 'US',
    city: 'Lentner',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shelby',
    latitude: 39.7123,
    longitude: -92.1489,
    postal_code: '63450'
  },
  '63451': {
    country: 'US',
    city: 'Leonard',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shelby',
    latitude: 39.9076,
    longitude: -92.1947,
    postal_code: '63451'
  },
  '63452': {
    country: 'US',
    city: 'Lewistown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lewis',
    latitude: 40.0867,
    longitude: -91.8157,
    postal_code: '63452'
  },
  '63453': {
    country: 'US',
    city: 'Luray',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clark',
    latitude: 40.4931,
    longitude: -91.8912,
    postal_code: '63453'
  },
  '63454': {
    country: 'US',
    city: 'Maywood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Marion',
    latitude: 39.9333,
    longitude: -91.6148,
    postal_code: '63454'
  },
  '63456': {
    country: 'US',
    city: 'Monroe City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Monroe',
    latitude: 39.6546,
    longitude: -91.723,
    postal_code: '63456'
  },
  '63457': {
    country: 'US',
    city: 'Monticello',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lewis',
    latitude: 40.1184,
    longitude: -91.7121,
    postal_code: '63457'
  },
  '63458': {
    country: 'US',
    city: 'Newark',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Knox',
    latitude: 39.9931,
    longitude: -91.973,
    postal_code: '63458'
  },
  '63459': {
    country: 'US',
    city: 'New London',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ralls',
    latitude: 39.5917,
    longitude: -91.396,
    postal_code: '63459'
  },
  '63460': {
    country: 'US',
    city: 'Novelty',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Knox',
    latitude: 40.0125,
    longitude: -92.2082,
    postal_code: '63460'
  },
  '63461': {
    country: 'US',
    city: 'Palmyra',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Marion',
    latitude: 39.7913,
    longitude: -91.5368,
    postal_code: '63461'
  },
  '63462': {
    country: 'US',
    city: 'Perry',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ralls',
    latitude: 39.4207,
    longitude: -91.6641,
    postal_code: '63462'
  },
  '63463': {
    country: 'US',
    city: 'Philadelphia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Marion',
    latitude: 39.8359,
    longitude: -91.7538,
    postal_code: '63463'
  },
  '63464': {
    country: 'US',
    city: 'Plevna',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Knox',
    latitude: 39.9765,
    longitude: -92.0852,
    postal_code: '63464'
  },
  '63465': {
    country: 'US',
    city: 'Revere',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clark',
    latitude: 40.5172,
    longitude: -91.6753,
    postal_code: '63465'
  },
  '63466': {
    country: 'US',
    city: 'Saint Patrick',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clark',
    latitude: 40.4312,
    longitude: -91.6851,
    postal_code: '63466'
  },
  '63467': {
    country: 'US',
    city: 'Saverton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ralls',
    latitude: 39.65,
    longitude: -91.2705,
    postal_code: '63467'
  },
  '63468': {
    country: 'US',
    city: 'Shelbina',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shelby',
    latitude: 39.6947,
    longitude: -92.0371,
    postal_code: '63468'
  },
  '63469': {
    country: 'US',
    city: 'Shelbyville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shelby',
    latitude: 39.8108,
    longitude: -92.0498,
    postal_code: '63469'
  },
  '63471': {
    country: 'US',
    city: 'Taylor',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Marion',
    latitude: 39.9145,
    longitude: -91.5278,
    postal_code: '63471'
  },
  '63472': {
    country: 'US',
    city: 'Wayland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clark',
    latitude: 40.4001,
    longitude: -91.5842,
    postal_code: '63472'
  },
  '63473': {
    country: 'US',
    city: 'Williamstown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lewis',
    latitude: 40.2491,
    longitude: -91.7852,
    postal_code: '63473'
  },
  '63474': {
    country: 'US',
    city: 'Wyaconda',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clark',
    latitude: 40.3721,
    longitude: -91.9071,
    postal_code: '63474'
  },
  '63501': {
    country: 'US',
    city: 'Kirksville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Adair',
    latitude: 40.1908,
    longitude: -92.5856,
    postal_code: '63501'
  },
  '63530': {
    country: 'US',
    city: 'Atlanta',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Macon',
    latitude: 39.9146,
    longitude: -92.475,
    postal_code: '63530'
  },
  '63531': {
    country: 'US',
    city: 'Baring',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Knox',
    latitude: 40.2509,
    longitude: -92.2311,
    postal_code: '63531'
  },
  '63532': {
    country: 'US',
    city: 'Bevier',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Macon',
    latitude: 39.7497,
    longitude: -92.5619,
    postal_code: '63532'
  },
  '63533': {
    country: 'US',
    city: 'Brashear',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Adair',
    latitude: 40.1959,
    longitude: -92.4333,
    postal_code: '63533'
  },
  '63534': {
    country: 'US',
    city: 'Callao',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Macon',
    latitude: 39.7448,
    longitude: -92.6351,
    postal_code: '63534'
  },
  '63535': {
    country: 'US',
    city: 'Coatsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Schuyler',
    latitude: 40.5665,
    longitude: -92.6388,
    postal_code: '63535'
  },
  '63536': {
    country: 'US',
    city: 'Downing',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Schuyler',
    latitude: 40.4875,
    longitude: -92.3694,
    postal_code: '63536'
  },
  '63537': {
    country: 'US',
    city: 'Edina',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Knox',
    latitude: 40.1795,
    longitude: -92.1455,
    postal_code: '63537'
  },
  '63538': {
    country: 'US',
    city: 'Elmer',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Macon',
    latitude: 39.9415,
    longitude: -92.6424,
    postal_code: '63538'
  },
  '63539': {
    country: 'US',
    city: 'Ethel',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Macon',
    latitude: 39.9146,
    longitude: -92.7664,
    postal_code: '63539'
  },
  '63540': {
    country: 'US',
    city: 'Gibbs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Adair',
    latitude: 40.104,
    longitude: -92.4066,
    postal_code: '63540'
  },
  '63541': {
    country: 'US',
    city: 'Glenwood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Schuyler',
    latitude: 40.5149,
    longitude: -92.5886,
    postal_code: '63541'
  },
  '63543': {
    country: 'US',
    city: 'Gorin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scotland',
    latitude: 40.3622,
    longitude: -92.014,
    postal_code: '63543'
  },
  '63544': {
    country: 'US',
    city: 'Green Castle',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Sullivan',
    latitude: 40.2703,
    longitude: -92.878,
    postal_code: '63544'
  },
  '63545': {
    country: 'US',
    city: 'Green City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Sullivan',
    latitude: 40.2601,
    longitude: -92.9532,
    postal_code: '63545'
  },
  '63546': {
    country: 'US',
    city: 'Greentop',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Adair',
    latitude: 40.3446,
    longitude: -92.5567,
    postal_code: '63546'
  },
  '63547': {
    country: 'US',
    city: 'Hurdland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Knox',
    latitude: 40.1609,
    longitude: -92.2791,
    postal_code: '63547'
  },
  '63548': {
    country: 'US',
    city: 'Lancaster',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Schuyler',
    latitude: 40.5253,
    longitude: -92.5264,
    postal_code: '63548'
  },
  '63549': {
    country: 'US',
    city: 'La Plata',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Macon',
    latitude: 40.0208,
    longitude: -92.5077,
    postal_code: '63549'
  },
  '63551': {
    country: 'US',
    city: 'Livonia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Putnam',
    latitude: 40.5112,
    longitude: -92.7241,
    postal_code: '63551'
  },
  '63552': {
    country: 'US',
    city: 'Macon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Macon',
    latitude: 39.7481,
    longitude: -92.4622,
    postal_code: '63552'
  },
  '63555': {
    country: 'US',
    city: 'Memphis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scotland',
    latitude: 40.4619,
    longitude: -92.1851,
    postal_code: '63555'
  },
  '63556': {
    country: 'US',
    city: 'Milan',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Sullivan',
    latitude: 40.1844,
    longitude: -93.1361,
    postal_code: '63556'
  },
  '63557': {
    country: 'US',
    city: 'New Boston',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Linn',
    latitude: 39.9332,
    longitude: -92.916,
    postal_code: '63557'
  },
  '63558': {
    country: 'US',
    city: 'New Cambria',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Macon',
    latitude: 39.7551,
    longitude: -92.7695,
    postal_code: '63558'
  },
  '63559': {
    country: 'US',
    city: 'Novinger',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Adair',
    latitude: 40.2685,
    longitude: -92.7172,
    postal_code: '63559'
  },
  '63560': {
    country: 'US',
    city: 'Pollock',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Sullivan',
    latitude: 40.3385,
    longitude: -93.1115,
    postal_code: '63560'
  },
  '63561': {
    country: 'US',
    city: 'Queen City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Schuyler',
    latitude: 40.4152,
    longitude: -92.5663,
    postal_code: '63561'
  },
  '63563': {
    country: 'US',
    city: 'Rutledge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scotland',
    latitude: 40.329,
    longitude: -92.0976,
    postal_code: '63563'
  },
  '63565': {
    country: 'US',
    city: 'Unionville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Putnam',
    latitude: 40.4815,
    longitude: -92.9951,
    postal_code: '63565'
  },
  '63566': {
    country: 'US',
    city: 'Winigan',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Sullivan',
    latitude: 40.0371,
    longitude: -92.9301,
    postal_code: '63566'
  },
  '63567': {
    country: 'US',
    city: 'Worthington',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Putnam',
    latitude: 40.4084,
    longitude: -92.6888,
    postal_code: '63567'
  },
  '63601': {
    country: 'US',
    city: 'Park Hills',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Francois',
    latitude: 37.8498,
    longitude: -90.4885,
    postal_code: '63601'
  },
  '63620': {
    country: 'US',
    city: 'Annapolis',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Iron',
    latitude: 37.3981,
    longitude: -90.6702,
    postal_code: '63620'
  },
  '63621': {
    country: 'US',
    city: 'Arcadia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Iron',
    latitude: 37.4871,
    longitude: -90.6062,
    postal_code: '63621'
  },
  '63622': {
    country: 'US',
    city: 'Belgrade',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Washington',
    latitude: 37.7889,
    longitude: -90.8613,
    postal_code: '63622'
  },
  '63623': {
    country: 'US',
    city: 'Belleview',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Iron',
    latitude: 37.682,
    longitude: -90.7991,
    postal_code: '63623'
  },
  '63624': {
    country: 'US',
    city: 'Bismarck',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Francois',
    latitude: 37.7539,
    longitude: -90.5984,
    postal_code: '63624'
  },
  '63625': {
    country: 'US',
    city: 'Black',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Reynolds',
    latitude: 37.5473,
    longitude: -90.9917,
    postal_code: '63625'
  },
  '63626': {
    country: 'US',
    city: 'Blackwell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Francois',
    latitude: 38.0544,
    longitude: -90.6279,
    postal_code: '63626'
  },
  '63627': {
    country: 'US',
    city: 'Bloomsdale',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ste. Genevieve',
    latitude: 38.0451,
    longitude: -90.2805,
    postal_code: '63627'
  },
  '63628': {
    country: 'US',
    city: 'Bonne Terre',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Francois',
    latitude: 37.9231,
    longitude: -90.5554,
    postal_code: '63628'
  },
  '63629': {
    country: 'US',
    city: 'Bunker',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Reynolds',
    latitude: 37.4772,
    longitude: -91.1927,
    postal_code: '63629'
  },
  '63630': {
    country: 'US',
    city: 'Cadet',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Washington',
    latitude: 38.0125,
    longitude: -90.7439,
    postal_code: '63630'
  },
  '63631': {
    country: 'US',
    city: 'Caledonia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Washington',
    latitude: 37.7639,
    longitude: -90.7409,
    postal_code: '63631'
  },
  '63632': {
    country: 'US',
    city: 'Cascade',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 37.2995,
    longitude: -90.2693,
    postal_code: '63632'
  },
  '63633': {
    country: 'US',
    city: 'Centerville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Reynolds',
    latitude: 37.4285,
    longitude: -90.9757,
    postal_code: '63633'
  },
  '63636': {
    country: 'US',
    city: 'Des Arc',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Iron',
    latitude: 37.2955,
    longitude: -90.6279,
    postal_code: '63636'
  },
  '63637': {
    country: 'US',
    city: 'Doe Run',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Francois',
    latitude: 37.7348,
    longitude: -90.4968,
    postal_code: '63637'
  },
  '63638': {
    country: 'US',
    city: 'Ellington',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Reynolds',
    latitude: 37.2398,
    longitude: -90.9589,
    postal_code: '63638'
  },
  '63640': {
    country: 'US',
    city: 'Farmington',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Francois',
    latitude: 37.7773,
    longitude: -90.4094,
    postal_code: '63640'
  },
  '63645': {
    country: 'US',
    city: 'Fredericktown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Madison',
    latitude: 37.4906,
    longitude: -90.3362,
    postal_code: '63645'
  },
  '63648': {
    country: 'US',
    city: 'Irondale',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Washington',
    latitude: 37.8296,
    longitude: -90.6984,
    postal_code: '63648'
  },
  '63650': {
    country: 'US',
    city: 'Ironton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Iron',
    latitude: 37.6168,
    longitude: -90.5985,
    postal_code: '63650'
  },
  '63651': {
    country: 'US',
    city: 'Knob Lick',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Francois',
    latitude: 37.6754,
    longitude: -90.3677,
    postal_code: '63651'
  },
  '63653': {
    country: 'US',
    city: 'Leadwood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Francois',
    latitude: 37.8577,
    longitude: -90.5879,
    postal_code: '63653'
  },
  '63654': {
    country: 'US',
    city: 'Lesterville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Reynolds',
    latitude: 37.482,
    longitude: -90.8425,
    postal_code: '63654'
  },
  '63655': {
    country: 'US',
    city: 'Marquand',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Madison',
    latitude: 37.4274,
    longitude: -90.1741,
    postal_code: '63655'
  },
  '63656': {
    country: 'US',
    city: 'Middle Brook',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Iron',
    latitude: 37.5055,
    longitude: -90.845,
    postal_code: '63656'
  },
  '63660': {
    country: 'US',
    city: 'Mineral Point',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Washington',
    latitude: 37.9156,
    longitude: -90.7193,
    postal_code: '63660'
  },
  '63662': {
    country: 'US',
    city: 'Patton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bollinger',
    latitude: 37.4733,
    longitude: -90.05,
    postal_code: '63662'
  },
  '63663': {
    country: 'US',
    city: 'Pilot Knob',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Iron',
    latitude: 37.625,
    longitude: -90.646,
    postal_code: '63663'
  },
  '63664': {
    country: 'US',
    city: 'Potosi',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Washington',
    latitude: 37.9549,
    longitude: -90.8415,
    postal_code: '63664'
  },
  '63665': {
    country: 'US',
    city: 'Redford',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Reynolds',
    latitude: 37.32,
    longitude: -90.8985,
    postal_code: '63665'
  },
  '63666': {
    country: 'US',
    city: 'Reynolds',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Reynolds',
    latitude: 37.4009,
    longitude: -91.0735,
    postal_code: '63666'
  },
  '63670': {
    country: 'US',
    city: 'Sainte Genevieve',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ste. Genevieve',
    latitude: 37.8655,
    longitude: -90.1752,
    postal_code: '63670'
  },
  '63673': {
    country: 'US',
    city: 'Saint Mary',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ste. Genevieve',
    latitude: 37.8325,
    longitude: -89.977,
    postal_code: '63673'
  },
  '63674': {
    country: 'US',
    city: 'Tiff',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Washington',
    latitude: 38.0192,
    longitude: -90.6529,
    postal_code: '63674'
  },
  '63675': {
    country: 'US',
    city: 'Vulcan',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Iron',
    latitude: 37.306,
    longitude: -90.7106,
    postal_code: '63675'
  },
  '63701': {
    country: 'US',
    city: 'Cape Girardeau',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.3169,
    longitude: -89.5459,
    postal_code: '63701'
  },
  '63702': {
    country: 'US',
    city: 'Cape Girardeau',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.3506,
    longitude: -89.5094,
    postal_code: '63702'
  },
  '63703': {
    country: 'US',
    city: 'Cape Girardeau',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.3059,
    longitude: -89.5181,
    postal_code: '63703'
  },
  '63730': {
    country: 'US',
    city: 'Advance',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stoddard',
    latitude: 37.0922,
    longitude: -89.9106,
    postal_code: '63730'
  },
  '63732': {
    country: 'US',
    city: 'Altenburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Perry',
    latitude: 37.6279,
    longitude: -89.5745,
    postal_code: '63732'
  },
  '63735': {
    country: 'US',
    city: 'Bell City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stoddard',
    latitude: 37.0116,
    longitude: -89.7984,
    postal_code: '63735'
  },
  '63736': {
    country: 'US',
    city: 'Benton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 37.0697,
    longitude: -89.5664,
    postal_code: '63736'
  },
  '63737': {
    country: 'US',
    city: 'Brazeau',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Perry',
    latitude: 37.6633,
    longitude: -89.6529,
    postal_code: '63737'
  },
  '63738': {
    country: 'US',
    city: 'Brownwood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stoddard',
    latitude: 37.0828,
    longitude: -89.9523,
    postal_code: '63738'
  },
  '63739': {
    country: 'US',
    city: 'Burfordville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.3632,
    longitude: -89.8206,
    postal_code: '63739'
  },
  '63740': {
    country: 'US',
    city: 'Chaffee',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 37.1726,
    longitude: -89.6457,
    postal_code: '63740'
  },
  '63742': {
    country: 'US',
    city: 'Commerce',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 37.157,
    longitude: -89.4484,
    postal_code: '63742'
  },
  '63743': {
    country: 'US',
    city: 'Daisy',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.5151,
    longitude: -89.8213,
    postal_code: '63743'
  },
  '63744': {
    country: 'US',
    city: 'Delta',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.1967,
    longitude: -89.7362,
    postal_code: '63744'
  },
  '63745': {
    country: 'US',
    city: 'Dutchtown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.2424,
    longitude: -89.6977,
    postal_code: '63745'
  },
  '63746': {
    country: 'US',
    city: 'Farrar',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Perry',
    latitude: 37.7078,
    longitude: -89.6948,
    postal_code: '63746'
  },
  '63747': {
    country: 'US',
    city: 'Friedheim',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.5669,
    longitude: -89.8376,
    postal_code: '63747'
  },
  '63748': {
    country: 'US',
    city: 'Frohna',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Perry',
    latitude: 37.6686,
    longitude: -89.6619,
    postal_code: '63748'
  },
  '63750': {
    country: 'US',
    city: 'Gipsy',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bollinger',
    latitude: 37.147,
    longitude: -90.1795,
    postal_code: '63750'
  },
  '63751': {
    country: 'US',
    city: 'Glenallen',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bollinger',
    latitude: 37.3231,
    longitude: -90.0515,
    postal_code: '63751'
  },
  '63752': {
    country: 'US',
    city: 'Gordonville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.3092,
    longitude: -89.6989,
    postal_code: '63752'
  },
  '63755': {
    country: 'US',
    city: 'Jackson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.3879,
    longitude: -89.6519,
    postal_code: '63755'
  },
  '63758': {
    country: 'US',
    city: 'Kelso',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 37.1858,
    longitude: -89.5575,
    postal_code: '63758'
  },
  '63760': {
    country: 'US',
    city: 'Leopold',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bollinger',
    latitude: 37.2609,
    longitude: -89.9227,
    postal_code: '63760'
  },
  '63763': {
    country: 'US',
    city: 'Mc Gee',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 37.1196,
    longitude: -90.445,
    postal_code: '63763'
  },
  '63764': {
    country: 'US',
    city: 'Marble Hill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bollinger',
    latitude: 37.3061,
    longitude: -89.9823,
    postal_code: '63764'
  },
  '63766': {
    country: 'US',
    city: 'Millersville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.4401,
    longitude: -89.795,
    postal_code: '63766'
  },
  '63767': {
    country: 'US',
    city: 'Morley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 37.0411,
    longitude: -89.6076,
    postal_code: '63767'
  },
  '63769': {
    country: 'US',
    city: 'Oak Ridge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.5258,
    longitude: -89.7508,
    postal_code: '63769'
  },
  '63770': {
    country: 'US',
    city: 'Old Appleton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.5975,
    longitude: -89.703,
    postal_code: '63770'
  },
  '63771': {
    country: 'US',
    city: 'Oran',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 37.087,
    longitude: -89.6734,
    postal_code: '63771'
  },
  '63774': {
    country: 'US',
    city: 'Perkins',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 37.0948,
    longitude: -89.7754,
    postal_code: '63774'
  },
  '63775': {
    country: 'US',
    city: 'Perryville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Perry',
    latitude: 37.7174,
    longitude: -89.8737,
    postal_code: '63775'
  },
  '63776': {
    country: 'US',
    city: 'Mc Bride',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Perry',
    latitude: 37.7348,
    longitude: -89.8116,
    postal_code: '63776'
  },
  '63779': {
    country: 'US',
    city: 'Pocahontas',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.5007,
    longitude: -89.6396,
    postal_code: '63779'
  },
  '63780': {
    country: 'US',
    city: 'Scott City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 37.2077,
    longitude: -89.5181,
    postal_code: '63780'
  },
  '63781': {
    country: 'US',
    city: 'Sedgewickville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bollinger',
    latitude: 37.537,
    longitude: -89.9272,
    postal_code: '63781'
  },
  '63782': {
    country: 'US',
    city: 'Sturdivant',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bollinger',
    latitude: 37.071,
    longitude: -90.0092,
    postal_code: '63782'
  },
  '63783': {
    country: 'US',
    city: 'Uniontown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Perry',
    latitude: 37.6073,
    longitude: -89.6798,
    postal_code: '63783'
  },
  '63784': {
    country: 'US',
    city: 'Vanduser',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 36.9912,
    longitude: -89.6885,
    postal_code: '63784'
  },
  '63785': {
    country: 'US',
    city: 'Whitewater',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cape Girardeau',
    latitude: 37.2781,
    longitude: -89.8061,
    postal_code: '63785'
  },
  '63787': {
    country: 'US',
    city: 'Zalma',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bollinger',
    latitude: 37.1365,
    longitude: -90.0757,
    postal_code: '63787'
  },
  '63801': {
    country: 'US',
    city: 'Sikeston',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 36.8911,
    longitude: -89.582,
    postal_code: '63801'
  },
  '63820': {
    country: 'US',
    city: 'Anniston',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Mississippi',
    latitude: 36.8259,
    longitude: -89.3279,
    postal_code: '63820'
  },
  '63821': {
    country: 'US',
    city: 'Arbyrd',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.0501,
    longitude: -90.2284,
    postal_code: '63821'
  },
  '63822': {
    country: 'US',
    city: 'Bernie',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stoddard',
    latitude: 36.6727,
    longitude: -89.9878,
    postal_code: '63822'
  },
  '63823': {
    country: 'US',
    city: 'Bertrand',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Mississippi',
    latitude: 36.8927,
    longitude: -89.4483,
    postal_code: '63823'
  },
  '63824': {
    country: 'US',
    city: 'Blodgett',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Scott',
    latitude: 37.0042,
    longitude: -89.5266,
    postal_code: '63824'
  },
  '63825': {
    country: 'US',
    city: 'Bloomfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stoddard',
    latitude: 36.8989,
    longitude: -89.9456,
    postal_code: '63825'
  },
  '63826': {
    country: 'US',
    city: 'Braggadocio',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pemiscot',
    latitude: 36.1753,
    longitude: -89.8295,
    postal_code: '63826'
  },
  '63827': {
    country: 'US',
    city: 'Bragg City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pemiscot',
    latitude: 36.2732,
    longitude: -89.8736,
    postal_code: '63827'
  },
  '63828': {
    country: 'US',
    city: 'Canalou',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.7504,
    longitude: -89.6918,
    postal_code: '63828'
  },
  '63829': {
    country: 'US',
    city: 'Cardwell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.0434,
    longitude: -90.2907,
    postal_code: '63829'
  },
  '63830': {
    country: 'US',
    city: 'Caruthersville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pemiscot',
    latitude: 36.1802,
    longitude: -89.6683,
    postal_code: '63830'
  },
  '63833': {
    country: 'US',
    city: 'Catron',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.6876,
    longitude: -89.7706,
    postal_code: '63833'
  },
  '63834': {
    country: 'US',
    city: 'Charleston',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Mississippi',
    latitude: 36.9213,
    longitude: -89.3342,
    postal_code: '63834'
  },
  '63837': {
    country: 'US',
    city: 'Clarkton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.4478,
    longitude: -89.9729,
    postal_code: '63837'
  },
  '63839': {
    country: 'US',
    city: 'Cooter',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pemiscot',
    latitude: 36.0476,
    longitude: -89.8091,
    postal_code: '63839'
  },
  '63840': {
    country: 'US',
    city: 'Deering',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pemiscot',
    latitude: 36.1909,
    longitude: -89.8829,
    postal_code: '63840'
  },
  '63841': {
    country: 'US',
    city: 'Dexter',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stoddard',
    latitude: 36.7885,
    longitude: -89.9639,
    postal_code: '63841'
  },
  '63845': {
    country: 'US',
    city: 'East Prairie',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Mississippi',
    latitude: 36.7776,
    longitude: -89.3726,
    postal_code: '63845'
  },
  '63846': {
    country: 'US',
    city: 'Essex',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stoddard',
    latitude: 36.8109,
    longitude: -89.8366,
    postal_code: '63846'
  },
  '63847': {
    country: 'US',
    city: 'Gibson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.4412,
    longitude: -90.0309,
    postal_code: '63847'
  },
  '63848': {
    country: 'US',
    city: 'Gideon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.4538,
    longitude: -89.9135,
    postal_code: '63848'
  },
  '63849': {
    country: 'US',
    city: 'Gobler',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pemiscot',
    latitude: 36.159,
    longitude: -89.9349,
    postal_code: '63849'
  },
  '63850': {
    country: 'US',
    city: 'Grayridge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stoddard',
    latitude: 36.8283,
    longitude: -89.7565,
    postal_code: '63850'
  },
  '63851': {
    country: 'US',
    city: 'Hayti',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pemiscot',
    latitude: 36.2337,
    longitude: -89.7495,
    postal_code: '63851'
  },
  '63852': {
    country: 'US',
    city: 'Holcomb',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.3885,
    longitude: -90.0208,
    postal_code: '63852'
  },
  '63853': {
    country: 'US',
    city: 'Holland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pemiscot',
    latitude: 36.0559,
    longitude: -89.8709,
    postal_code: '63853'
  },
  '63855': {
    country: 'US',
    city: 'Hornersville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.0627,
    longitude: -90.0816,
    postal_code: '63855'
  },
  '63857': {
    country: 'US',
    city: 'Kennett',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.2407,
    longitude: -90.0491,
    postal_code: '63857'
  },
  '63860': {
    country: 'US',
    city: 'Kewanee',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.6726,
    longitude: -89.5639,
    postal_code: '63860'
  },
  '63862': {
    country: 'US',
    city: 'Lilbourn',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.5853,
    longitude: -89.6112,
    postal_code: '63862'
  },
  '63863': {
    country: 'US',
    city: 'Malden',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.5672,
    longitude: -89.9737,
    postal_code: '63863'
  },
  '63866': {
    country: 'US',
    city: 'Marston',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.519,
    longitude: -89.6126,
    postal_code: '63866'
  },
  '63867': {
    country: 'US',
    city: 'Matthews',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.7154,
    longitude: -89.6287,
    postal_code: '63867'
  },
  '63868': {
    country: 'US',
    city: 'Morehouse',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.8504,
    longitude: -89.6847,
    postal_code: '63868'
  },
  '63869': {
    country: 'US',
    city: 'New Madrid',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.6073,
    longitude: -89.5366,
    postal_code: '63869'
  },
  '63870': {
    country: 'US',
    city: 'Parma',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.5856,
    longitude: -89.819,
    postal_code: '63870'
  },
  '63873': {
    country: 'US',
    city: 'Portageville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.4279,
    longitude: -89.7002,
    postal_code: '63873'
  },
  '63874': {
    country: 'US',
    city: 'Risco',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.5534,
    longitude: -89.8179,
    postal_code: '63874'
  },
  '63875': {
    country: 'US',
    city: 'Rives',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.0937,
    longitude: -90.0134,
    postal_code: '63875'
  },
  '63876': {
    country: 'US',
    city: 'Senath',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.1324,
    longitude: -90.1632,
    postal_code: '63876'
  },
  '63877': {
    country: 'US',
    city: 'Steele',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pemiscot',
    latitude: 36.0915,
    longitude: -89.8346,
    postal_code: '63877'
  },
  '63878': {
    country: 'US',
    city: 'Tallapoosa',
    state: 'Missouri',
    state_short: 'MO',
    county: 'New Madrid',
    latitude: 36.503,
    longitude: -89.8224,
    postal_code: '63878'
  },
  '63879': {
    country: 'US',
    city: 'Wardell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pemiscot',
    latitude: 36.3478,
    longitude: -89.8181,
    postal_code: '63879'
  },
  '63880': {
    country: 'US',
    city: 'Whiteoak',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.3133,
    longitude: -90.1658,
    postal_code: '63880'
  },
  '63881': {
    country: 'US',
    city: 'Wolf Island',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Mississippi',
    latitude: 36.7392,
    longitude: -89.2103,
    postal_code: '63881'
  },
  '63882': {
    country: 'US',
    city: 'Wyatt',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Mississippi',
    latitude: 36.9092,
    longitude: -89.2226,
    postal_code: '63882'
  },
  '63901': {
    country: 'US',
    city: 'Poplar Bluff',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Butler',
    latitude: 36.7662,
    longitude: -90.4166,
    postal_code: '63901'
  },
  '63902': {
    country: 'US',
    city: 'Poplar Bluff',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Butler',
    latitude: 36.7125,
    longitude: -90.407,
    postal_code: '63902'
  },
  '63931': {
    country: 'US',
    city: 'Briar',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ripley',
    latitude: 36.661,
    longitude: -90.8508,
    postal_code: '63931'
  },
  '63932': {
    country: 'US',
    city: 'Broseley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Butler',
    latitude: 36.6899,
    longitude: -90.2508,
    postal_code: '63932'
  },
  '63933': {
    country: 'US',
    city: 'Campbell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dunklin',
    latitude: 36.5197,
    longitude: -90.0829,
    postal_code: '63933'
  },
  '63934': {
    country: 'US',
    city: 'Clubb',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 37.2145,
    longitude: -90.3457,
    postal_code: '63934'
  },
  '63935': {
    country: 'US',
    city: 'Doniphan',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ripley',
    latitude: 36.6501,
    longitude: -90.8106,
    postal_code: '63935'
  },
  '63936': {
    country: 'US',
    city: 'Dudley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stoddard',
    latitude: 36.811,
    longitude: -90.121,
    postal_code: '63936'
  },
  '63937': {
    country: 'US',
    city: 'Ellsinore',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carter',
    latitude: 36.9453,
    longitude: -90.7485,
    postal_code: '63937'
  },
  '63938': {
    country: 'US',
    city: 'Fagus',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Butler',
    latitude: 36.5114,
    longitude: -90.267,
    postal_code: '63938'
  },
  '63939': {
    country: 'US',
    city: 'Fairdealing',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ripley',
    latitude: 36.6704,
    longitude: -90.6335,
    postal_code: '63939'
  },
  '63940': {
    country: 'US',
    city: 'Fisk',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Butler',
    latitude: 36.7836,
    longitude: -90.2168,
    postal_code: '63940'
  },
  '63941': {
    country: 'US',
    city: 'Fremont',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carter',
    latitude: 36.9172,
    longitude: -91.144,
    postal_code: '63941'
  },
  '63942': {
    country: 'US',
    city: 'Gatewood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ripley',
    latitude: 36.5626,
    longitude: -91.0703,
    postal_code: '63942'
  },
  '63943': {
    country: 'US',
    city: 'Grandin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carter',
    latitude: 36.828,
    longitude: -90.7942,
    postal_code: '63943'
  },
  '63944': {
    country: 'US',
    city: 'Greenville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 37.1108,
    longitude: -90.4514,
    postal_code: '63944'
  },
  '63945': {
    country: 'US',
    city: 'Harviell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Butler',
    latitude: 36.6723,
    longitude: -90.5583,
    postal_code: '63945'
  },
  '63950': {
    country: 'US',
    city: 'Lodi',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 37.1196,
    longitude: -90.445,
    postal_code: '63950'
  },
  '63951': {
    country: 'US',
    city: 'Lowndes',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 37.1343,
    longitude: -90.2544,
    postal_code: '63951'
  },
  '63952': {
    country: 'US',
    city: 'Mill Spring',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 37.0675,
    longitude: -90.6746,
    postal_code: '63952'
  },
  '63953': {
    country: 'US',
    city: 'Naylor',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ripley',
    latitude: 36.5843,
    longitude: -90.6124,
    postal_code: '63953'
  },
  '63954': {
    country: 'US',
    city: 'Neelyville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Butler',
    latitude: 36.571,
    longitude: -90.4995,
    postal_code: '63954'
  },
  '63955': {
    country: 'US',
    city: 'Oxly',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ripley',
    latitude: 36.5874,
    longitude: -90.692,
    postal_code: '63955'
  },
  '63956': {
    country: 'US',
    city: 'Patterson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 37.1884,
    longitude: -90.5507,
    postal_code: '63956'
  },
  '63957': {
    country: 'US',
    city: 'Piedmont',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 37.1573,
    longitude: -90.699,
    postal_code: '63957'
  },
  '63960': {
    country: 'US',
    city: 'Puxico',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stoddard',
    latitude: 36.9422,
    longitude: -90.1623,
    postal_code: '63960'
  },
  '63961': {
    country: 'US',
    city: 'Qulin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Butler',
    latitude: 36.5818,
    longitude: -90.2617,
    postal_code: '63961'
  },
  '63962': {
    country: 'US',
    city: 'Rombauer',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Butler',
    latitude: 36.8431,
    longitude: -90.2801,
    postal_code: '63962'
  },
  '63964': {
    country: 'US',
    city: 'Silva',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 37.2119,
    longitude: -90.4375,
    postal_code: '63964'
  },
  '63965': {
    country: 'US',
    city: 'Van Buren',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carter',
    latitude: 37.0015,
    longitude: -91.0007,
    postal_code: '63965'
  },
  '63966': {
    country: 'US',
    city: 'Wappapello',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 36.9356,
    longitude: -90.2709,
    postal_code: '63966'
  },
  '63967': {
    country: 'US',
    city: 'Williamsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wayne',
    latitude: 36.9638,
    longitude: -90.4879,
    postal_code: '63967'
  },
  '64001': {
    country: 'US',
    city: 'Alma',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 39.1048,
    longitude: -93.5429,
    postal_code: '64001'
  },
  '64002': {
    country: 'US',
    city: 'Lees Summit',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9285,
    longitude: -94.3983,
    postal_code: '64002'
  },
  '64011': {
    country: 'US',
    city: 'Bates City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 39.0219,
    longitude: -94.0798,
    postal_code: '64011'
  },
  '64012': {
    country: 'US',
    city: 'Belton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.8161,
    longitude: -94.5328,
    postal_code: '64012'
  },
  '64013': {
    country: 'US',
    city: 'Blue Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.017,
    longitude: -94.2816,
    postal_code: '64013'
  },
  '64014': {
    country: 'US',
    city: 'Blue Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0152,
    longitude: -94.2604,
    postal_code: '64014'
  },
  '64015': {
    country: 'US',
    city: 'Blue Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.015,
    longitude: -94.3118,
    postal_code: '64015'
  },
  '64016': {
    country: 'US',
    city: 'Buckner',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1303,
    longitude: -94.2062,
    postal_code: '64016'
  },
  '64017': {
    country: 'US',
    city: 'Camden',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ray',
    latitude: 39.2048,
    longitude: -94.0259,
    postal_code: '64017'
  },
  '64018': {
    country: 'US',
    city: 'Camden Point',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.4515,
    longitude: -94.7444,
    postal_code: '64018'
  },
  '64019': {
    country: 'US',
    city: 'Centerview',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Johnson',
    latitude: 38.7897,
    longitude: -93.8702,
    postal_code: '64019'
  },
  '64020': {
    country: 'US',
    city: 'Concordia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 38.9776,
    longitude: -93.5812,
    postal_code: '64020'
  },
  '64021': {
    country: 'US',
    city: 'Corder',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 39.1024,
    longitude: -93.6391,
    postal_code: '64021'
  },
  '64022': {
    country: 'US',
    city: 'Dover',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 39.1926,
    longitude: -93.6684,
    postal_code: '64022'
  },
  '64024': {
    country: 'US',
    city: 'Excelsior Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.3392,
    longitude: -94.2261,
    postal_code: '64024'
  },
  '64028': {
    country: 'US',
    city: 'Farley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.2839,
    longitude: -94.8302,
    postal_code: '64028'
  },
  '64029': {
    country: 'US',
    city: 'Grain Valley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0274,
    longitude: -94.2087,
    postal_code: '64029'
  },
  '64030': {
    country: 'US',
    city: 'Grandview',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.8819,
    longitude: -94.5205,
    postal_code: '64030'
  },
  '64034': {
    country: 'US',
    city: 'Greenwood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.8644,
    longitude: -94.2815,
    postal_code: '64034'
  },
  '64035': {
    country: 'US',
    city: 'Hardin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ray',
    latitude: 39.3504,
    longitude: -93.8409,
    postal_code: '64035'
  },
  '64036': {
    country: 'US',
    city: 'Henrietta',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ray',
    latitude: 39.2367,
    longitude: -93.9369,
    postal_code: '64036'
  },
  '64037': {
    country: 'US',
    city: 'Higginsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 39.0705,
    longitude: -93.7133,
    postal_code: '64037'
  },
  '64040': {
    country: 'US',
    city: 'Holden',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Johnson',
    latitude: 38.7186,
    longitude: -93.9856,
    postal_code: '64040'
  },
  '64048': {
    country: 'US',
    city: 'Holt',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.4289,
    longitude: -94.3689,
    postal_code: '64048'
  },
  '64050': {
    country: 'US',
    city: 'Independence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0983,
    longitude: -94.4111,
    postal_code: '64050'
  },
  '64051': {
    country: 'US',
    city: 'Independence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0911,
    longitude: -94.4155,
    postal_code: '64051'
  },
  '64052': {
    country: 'US',
    city: 'Independence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.075,
    longitude: -94.4499,
    postal_code: '64052'
  },
  '64053': {
    country: 'US',
    city: 'Independence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.105,
    longitude: -94.4625,
    postal_code: '64053'
  },
  '64054': {
    country: 'US',
    city: 'Independence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.11,
    longitude: -94.4401,
    postal_code: '64054'
  },
  '64055': {
    country: 'US',
    city: 'Independence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0545,
    longitude: -94.4039,
    postal_code: '64055'
  },
  '64056': {
    country: 'US',
    city: 'Independence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1177,
    longitude: -94.3596,
    postal_code: '64056'
  },
  '64057': {
    country: 'US',
    city: 'Independence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0731,
    longitude: -94.3533,
    postal_code: '64057'
  },
  '64058': {
    country: 'US',
    city: 'Independence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1412,
    longitude: -94.3515,
    postal_code: '64058'
  },
  '64060': {
    country: 'US',
    city: 'Kearney',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.3652,
    longitude: -94.3621,
    postal_code: '64060'
  },
  '64061': {
    country: 'US',
    city: 'Kingsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Johnson',
    latitude: 38.8178,
    longitude: -94.0462,
    postal_code: '64061'
  },
  '64062': {
    country: 'US',
    city: 'Lawson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ray',
    latitude: 39.4401,
    longitude: -94.1966,
    postal_code: '64062'
  },
  '64063': {
    country: 'US',
    city: 'Lees Summit',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.912,
    longitude: -94.3517,
    postal_code: '64063'
  },
  '64064': {
    country: 'US',
    city: 'Lees Summit',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9953,
    longitude: -94.3652,
    postal_code: '64064'
  },
  '64065': {
    country: 'US',
    city: 'Lees Summit',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9529,
    longitude: -94.4058,
    postal_code: '64065'
  },
  '64066': {
    country: 'US',
    city: 'Levasy',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1356,
    longitude: -94.133,
    postal_code: '64066'
  },
  '64067': {
    country: 'US',
    city: 'Lexington',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 39.1742,
    longitude: -93.8714,
    postal_code: '64067'
  },
  '64068': {
    country: 'US',
    city: 'Liberty',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.2461,
    longitude: -94.4191,
    postal_code: '64068'
  },
  '64069': {
    country: 'US',
    city: 'Liberty',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.2461,
    longitude: -94.4191,
    postal_code: '64069'
  },
  '64070': {
    country: 'US',
    city: 'Lone Jack',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.8918,
    longitude: -94.1615,
    postal_code: '64070'
  },
  '64071': {
    country: 'US',
    city: 'Mayview',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 39.0459,
    longitude: -93.8353,
    postal_code: '64071'
  },
  '64072': {
    country: 'US',
    city: 'Missouri City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.2458,
    longitude: -94.2924,
    postal_code: '64072'
  },
  '64073': {
    country: 'US',
    city: 'Mosby',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.3149,
    longitude: -94.2939,
    postal_code: '64073'
  },
  '64074': {
    country: 'US',
    city: 'Napoleon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 39.114,
    longitude: -94.0709,
    postal_code: '64074'
  },
  '64075': {
    country: 'US',
    city: 'Oak Grove',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9985,
    longitude: -94.1399,
    postal_code: '64075'
  },
  '64076': {
    country: 'US',
    city: 'Odessa',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 38.9829,
    longitude: -93.9757,
    postal_code: '64076'
  },
  '64077': {
    country: 'US',
    city: 'Orrick',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ray',
    latitude: 39.2116,
    longitude: -94.1239,
    postal_code: '64077'
  },
  '64078': {
    country: 'US',
    city: 'Peculiar',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.7165,
    longitude: -94.4405,
    postal_code: '64078'
  },
  '64079': {
    country: 'US',
    city: 'Platte City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.3602,
    longitude: -94.789,
    postal_code: '64079'
  },
  '64080': {
    country: 'US',
    city: 'Pleasant Hill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.7859,
    longitude: -94.244,
    postal_code: '64080'
  },
  '64081': {
    country: 'US',
    city: 'Lees Summit',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9142,
    longitude: -94.4073,
    postal_code: '64081'
  },
  '64082': {
    country: 'US',
    city: 'Lees Summit',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.8518,
    longitude: -94.3944,
    postal_code: '64082'
  },
  '64083': {
    country: 'US',
    city: 'Raymore',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.8019,
    longitude: -94.4529,
    postal_code: '64083'
  },
  '64084': {
    country: 'US',
    city: 'Rayville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ray',
    latitude: 39.3853,
    longitude: -94.0284,
    postal_code: '64084'
  },
  '64085': {
    country: 'US',
    city: 'Richmond',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ray',
    latitude: 39.2793,
    longitude: -93.9792,
    postal_code: '64085'
  },
  '64086': {
    country: 'US',
    city: 'Lees Summit',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.944,
    longitude: -94.2881,
    postal_code: '64086'
  },
  '64088': {
    country: 'US',
    city: 'Sibley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1584,
    longitude: -94.1844,
    postal_code: '64088'
  },
  '64089': {
    country: 'US',
    city: 'Smithville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.3917,
    longitude: -94.5592,
    postal_code: '64089'
  },
  '64090': {
    country: 'US',
    city: 'Strasburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.7658,
    longitude: -94.1609,
    postal_code: '64090'
  },
  '64092': {
    country: 'US',
    city: 'Waldron',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.2289,
    longitude: -94.8057,
    postal_code: '64092'
  },
  '64093': {
    country: 'US',
    city: 'Warrensburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Johnson',
    latitude: 38.7667,
    longitude: -93.7273,
    postal_code: '64093'
  },
  '64096': {
    country: 'US',
    city: 'Waverly',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 39.2055,
    longitude: -93.5257,
    postal_code: '64096'
  },
  '64097': {
    country: 'US',
    city: 'Wellington',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 39.1258,
    longitude: -93.9855,
    postal_code: '64097'
  },
  '64098': {
    country: 'US',
    city: 'Weston',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.4453,
    longitude: -94.9145,
    postal_code: '64098'
  },
  '64101': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1024,
    longitude: -94.5986,
    postal_code: '64101'
  },
  '64102': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0861,
    longitude: -94.6066,
    postal_code: '64102'
  },
  '64105': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1025,
    longitude: -94.5901,
    postal_code: '64105'
  },
  '64106': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1052,
    longitude: -94.5699,
    postal_code: '64106'
  },
  '64108': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0837,
    longitude: -94.5868,
    postal_code: '64108'
  },
  '64109': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0663,
    longitude: -94.5674,
    postal_code: '64109'
  },
  '64110': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0361,
    longitude: -94.5722,
    postal_code: '64110'
  },
  '64111': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0565,
    longitude: -94.5929,
    postal_code: '64111'
  },
  '64112': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0382,
    longitude: -94.5929,
    postal_code: '64112'
  },
  '64113': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0123,
    longitude: -94.5938,
    postal_code: '64113'
  },
  '64114': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9621,
    longitude: -94.5959,
    postal_code: '64114'
  },
  '64116': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.1479,
    longitude: -94.568,
    postal_code: '64116'
  },
  '64117': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.1651,
    longitude: -94.5256,
    postal_code: '64117'
  },
  '64118': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.2133,
    longitude: -94.5743,
    postal_code: '64118'
  },
  '64119': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.1979,
    longitude: -94.5199,
    postal_code: '64119'
  },
  '64120': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1222,
    longitude: -94.5487,
    postal_code: '64120'
  },
  '64121': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64121'
  },
  '64123': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1136,
    longitude: -94.5235,
    postal_code: '64123'
  },
  '64124': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1068,
    longitude: -94.5394,
    postal_code: '64124'
  },
  '64125': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.1042,
    longitude: -94.4923,
    postal_code: '64125'
  },
  '64126': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0923,
    longitude: -94.5047,
    postal_code: '64126'
  },
  '64127': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0883,
    longitude: -94.5366,
    postal_code: '64127'
  },
  '64128': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0659,
    longitude: -94.5386,
    postal_code: '64128'
  },
  '64129': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0401,
    longitude: -94.4951,
    postal_code: '64129'
  },
  '64130': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0351,
    longitude: -94.5467,
    postal_code: '64130'
  },
  '64131': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9713,
    longitude: -94.5774,
    postal_code: '64131'
  },
  '64132': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9911,
    longitude: -94.5522,
    postal_code: '64132'
  },
  '64133': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0323,
    longitude: -94.47,
    postal_code: '64133'
  },
  '64134': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9296,
    longitude: -94.5009,
    postal_code: '64134'
  },
  '64136': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0187,
    longitude: -94.4008,
    postal_code: '64136'
  },
  '64137': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9299,
    longitude: -94.5405,
    postal_code: '64137'
  },
  '64138': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9528,
    longitude: -94.4705,
    postal_code: '64138'
  },
  '64139': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.9659,
    longitude: -94.4061,
    postal_code: '64139'
  },
  '64141': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64141'
  },
  '64144': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.2829,
    longitude: -94.409,
    postal_code: '64144'
  },
  '64145': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.8977,
    longitude: -94.5976,
    postal_code: '64145'
  },
  '64146': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.8973,
    longitude: -94.5764,
    postal_code: '64146'
  },
  '64147': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.8549,
    longitude: -94.5568,
    postal_code: '64147'
  },
  '64148': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64148'
  },
  '64149': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 38.8606,
    longitude: -94.4636,
    postal_code: '64149'
  },
  '64150': {
    country: 'US',
    city: 'Riverside',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.1776,
    longitude: -94.6321,
    postal_code: '64150'
  },
  '64151': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.2127,
    longitude: -94.6383,
    postal_code: '64151'
  },
  '64152': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.2176,
    longitude: -94.7238,
    postal_code: '64152'
  },
  '64153': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.2627,
    longitude: -94.697,
    postal_code: '64153'
  },
  '64154': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.2547,
    longitude: -94.6354,
    postal_code: '64154'
  },
  '64155': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.2758,
    longitude: -94.5704,
    postal_code: '64155'
  },
  '64156': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.2901,
    longitude: -94.5336,
    postal_code: '64156'
  },
  '64157': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.2767,
    longitude: -94.4595,
    postal_code: '64157'
  },
  '64158': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.2284,
    longitude: -94.472,
    postal_code: '64158'
  },
  '64161': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.1661,
    longitude: -94.464,
    postal_code: '64161'
  },
  '64162': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.1562,
    longitude: -94.4797,
    postal_code: '64162'
  },
  '64163': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.3402,
    longitude: -94.6908,
    postal_code: '64163'
  },
  '64164': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.3426,
    longitude: -94.6446,
    postal_code: '64164'
  },
  '64165': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.3113,
    longitude: -94.5431,
    postal_code: '64165'
  },
  '64166': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.3294,
    longitude: -94.5199,
    postal_code: '64166'
  },
  '64167': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clay',
    latitude: 39.32,
    longitude: -94.4877,
    postal_code: '64167'
  },
  '64168': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.3432,
    longitude: -94.8516,
    postal_code: '64168'
  },
  '64170': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64170'
  },
  '64171': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64171'
  },
  '64179': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64179'
  },
  '64180': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64180'
  },
  '64184': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64184'
  },
  '64187': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64187'
  },
  '64188': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64188'
  },
  '64190': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.3432,
    longitude: -94.8516,
    postal_code: '64190'
  },
  '64191': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64191'
  },
  '64195': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.3432,
    longitude: -94.8516,
    postal_code: '64195'
  },
  '64196': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64196'
  },
  '64197': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64197'
  },
  '64198': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64198'
  },
  '64199': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64199'
  },
  '64401': {
    country: 'US',
    city: 'Agency',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.6662,
    longitude: -94.717,
    postal_code: '64401'
  },
  '64402': {
    country: 'US',
    city: 'Albany',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gentry',
    latitude: 40.2513,
    longitude: -94.327,
    postal_code: '64402'
  },
  '64420': {
    country: 'US',
    city: 'Allendale',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Worth',
    latitude: 40.4855,
    longitude: -94.2886,
    postal_code: '64420'
  },
  '64421': {
    country: 'US',
    city: 'Amazonia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Andrew',
    latitude: 39.9092,
    longitude: -94.9113,
    postal_code: '64421'
  },
  '64422': {
    country: 'US',
    city: 'Amity',
    state: 'Missouri',
    state_short: 'MO',
    county: 'DeKalb',
    latitude: 39.8837,
    longitude: -94.5136,
    postal_code: '64422'
  },
  '64423': {
    country: 'US',
    city: 'Barnard',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.1862,
    longitude: -94.8056,
    postal_code: '64423'
  },
  '64424': {
    country: 'US',
    city: 'Bethany',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Harrison',
    latitude: 40.2601,
    longitude: -94.0189,
    postal_code: '64424'
  },
  '64426': {
    country: 'US',
    city: 'Blythedale',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Harrison',
    latitude: 40.5024,
    longitude: -93.8954,
    postal_code: '64426'
  },
  '64427': {
    country: 'US',
    city: 'Bolckow',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Andrew',
    latitude: 40.1007,
    longitude: -94.8847,
    postal_code: '64427'
  },
  '64428': {
    country: 'US',
    city: 'Burlington Junction',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.4475,
    longitude: -95.063,
    postal_code: '64428'
  },
  '64429': {
    country: 'US',
    city: 'Cameron',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clinton',
    latitude: 39.7309,
    longitude: -94.2437,
    postal_code: '64429'
  },
  '64430': {
    country: 'US',
    city: 'Clarksdale',
    state: 'Missouri',
    state_short: 'MO',
    county: 'DeKalb',
    latitude: 39.8137,
    longitude: -94.5421,
    postal_code: '64430'
  },
  '64431': {
    country: 'US',
    city: 'Clearmont',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.5175,
    longitude: -95.0055,
    postal_code: '64431'
  },
  '64432': {
    country: 'US',
    city: 'Clyde',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.2635,
    longitude: -94.6687,
    postal_code: '64432'
  },
  '64433': {
    country: 'US',
    city: 'Conception',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.2428,
    longitude: -94.6869,
    postal_code: '64433'
  },
  '64434': {
    country: 'US',
    city: 'Conception Junction',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.2659,
    longitude: -94.6915,
    postal_code: '64434'
  },
  '64436': {
    country: 'US',
    city: 'Cosby',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Andrew',
    latitude: 39.8555,
    longitude: -94.6977,
    postal_code: '64436'
  },
  '64437': {
    country: 'US',
    city: 'Craig',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Holt',
    latitude: 40.1267,
    longitude: -95.3291,
    postal_code: '64437'
  },
  '64438': {
    country: 'US',
    city: 'Darlington',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gentry',
    latitude: 40.1955,
    longitude: -94.4049,
    postal_code: '64438'
  },
  '64439': {
    country: 'US',
    city: 'Dearborn',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.5172,
    longitude: -94.7664,
    postal_code: '64439'
  },
  '64440': {
    country: 'US',
    city: 'De Kalb',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.5834,
    longitude: -94.927,
    postal_code: '64440'
  },
  '64441': {
    country: 'US',
    city: 'Denver',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Worth',
    latitude: 40.4181,
    longitude: -94.3066,
    postal_code: '64441'
  },
  '64442': {
    country: 'US',
    city: 'Eagleville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Harrison',
    latitude: 40.4912,
    longitude: -93.9951,
    postal_code: '64442'
  },
  '64443': {
    country: 'US',
    city: 'Easton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.7517,
    longitude: -94.6582,
    postal_code: '64443'
  },
  '64444': {
    country: 'US',
    city: 'Edgerton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Platte',
    latitude: 39.4742,
    longitude: -94.6352,
    postal_code: '64444'
  },
  '64445': {
    country: 'US',
    city: 'Elmo',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.5185,
    longitude: -95.1236,
    postal_code: '64445'
  },
  '64446': {
    country: 'US',
    city: 'Fairfax',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Atchison',
    latitude: 40.3302,
    longitude: -95.3751,
    postal_code: '64446'
  },
  '64448': {
    country: 'US',
    city: 'Faucett',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.5891,
    longitude: -94.7913,
    postal_code: '64448'
  },
  '64449': {
    country: 'US',
    city: 'Fillmore',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Andrew',
    latitude: 40.0142,
    longitude: -94.9555,
    postal_code: '64449'
  },
  '64451': {
    country: 'US',
    city: 'Forest City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Holt',
    latitude: 39.9897,
    longitude: -95.1916,
    postal_code: '64451'
  },
  '64453': {
    country: 'US',
    city: 'Gentry',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gentry',
    latitude: 40.3411,
    longitude: -94.4142,
    postal_code: '64453'
  },
  '64454': {
    country: 'US',
    city: 'Gower',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clinton',
    latitude: 39.602,
    longitude: -94.5965,
    postal_code: '64454'
  },
  '64455': {
    country: 'US',
    city: 'Graham',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.2012,
    longitude: -95.0121,
    postal_code: '64455'
  },
  '64456': {
    country: 'US',
    city: 'Grant City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Worth',
    latitude: 40.4924,
    longitude: -94.3979,
    postal_code: '64456'
  },
  '64457': {
    country: 'US',
    city: 'Guilford',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.1746,
    longitude: -94.6951,
    postal_code: '64457'
  },
  '64458': {
    country: 'US',
    city: 'Hatfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Harrison',
    latitude: 40.5219,
    longitude: -94.1691,
    postal_code: '64458'
  },
  '64459': {
    country: 'US',
    city: 'Helena',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Andrew',
    latitude: 39.9295,
    longitude: -94.6465,
    postal_code: '64459'
  },
  '64461': {
    country: 'US',
    city: 'Hopkins',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.5483,
    longitude: -94.8189,
    postal_code: '64461'
  },
  '64463': {
    country: 'US',
    city: 'King City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gentry',
    latitude: 40.0652,
    longitude: -94.5234,
    postal_code: '64463'
  },
  '64465': {
    country: 'US',
    city: 'Lathrop',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clinton',
    latitude: 39.5177,
    longitude: -94.3093,
    postal_code: '64465'
  },
  '64466': {
    country: 'US',
    city: 'Maitland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Holt',
    latitude: 40.1991,
    longitude: -95.0927,
    postal_code: '64466'
  },
  '64467': {
    country: 'US',
    city: 'Martinsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Harrison',
    latitude: 40.3662,
    longitude: -94.1645,
    postal_code: '64467'
  },
  '64468': {
    country: 'US',
    city: 'Maryville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.3434,
    longitude: -94.8735,
    postal_code: '64468'
  },
  '64469': {
    country: 'US',
    city: 'Maysville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'DeKalb',
    latitude: 39.9112,
    longitude: -94.3548,
    postal_code: '64469'
  },
  '64470': {
    country: 'US',
    city: 'Mound City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Holt',
    latitude: 40.1362,
    longitude: -95.2138,
    postal_code: '64470'
  },
  '64471': {
    country: 'US',
    city: 'New Hampton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Harrison',
    latitude: 40.2444,
    longitude: -94.1786,
    postal_code: '64471'
  },
  '64473': {
    country: 'US',
    city: 'Oregon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Holt',
    latitude: 39.9809,
    longitude: -95.1234,
    postal_code: '64473'
  },
  '64474': {
    country: 'US',
    city: 'Osborn',
    state: 'Missouri',
    state_short: 'MO',
    county: 'DeKalb',
    latitude: 39.7912,
    longitude: -94.3974,
    postal_code: '64474'
  },
  '64475': {
    country: 'US',
    city: 'Parnell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.44,
    longitude: -94.6233,
    postal_code: '64475'
  },
  '64476': {
    country: 'US',
    city: 'Pickering',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.4591,
    longitude: -94.8411,
    postal_code: '64476'
  },
  '64477': {
    country: 'US',
    city: 'Plattsburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clinton',
    latitude: 39.5705,
    longitude: -94.4338,
    postal_code: '64477'
  },
  '64479': {
    country: 'US',
    city: 'Ravenwood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.3579,
    longitude: -94.6805,
    postal_code: '64479'
  },
  '64480': {
    country: 'US',
    city: 'Rea',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Andrew',
    latitude: 40.0593,
    longitude: -94.7002,
    postal_code: '64480'
  },
  '64481': {
    country: 'US',
    city: 'Ridgeway',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Harrison',
    latitude: 40.3999,
    longitude: -93.9575,
    postal_code: '64481'
  },
  '64482': {
    country: 'US',
    city: 'Rock Port',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Atchison',
    latitude: 40.4306,
    longitude: -95.5274,
    postal_code: '64482'
  },
  '64483': {
    country: 'US',
    city: 'Rosendale',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Andrew',
    latitude: 40.0399,
    longitude: -94.8328,
    postal_code: '64483'
  },
  '64484': {
    country: 'US',
    city: 'Rushville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.5653,
    longitude: -95.0413,
    postal_code: '64484'
  },
  '64485': {
    country: 'US',
    city: 'Savannah',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Andrew',
    latitude: 39.9168,
    longitude: -94.8267,
    postal_code: '64485'
  },
  '64486': {
    country: 'US',
    city: 'Sheridan',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Worth',
    latitude: 40.4928,
    longitude: -94.5701,
    postal_code: '64486'
  },
  '64487': {
    country: 'US',
    city: 'Skidmore',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Nodaway',
    latitude: 40.2896,
    longitude: -95.0792,
    postal_code: '64487'
  },
  '64489': {
    country: 'US',
    city: 'Stanberry',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gentry',
    latitude: 40.2293,
    longitude: -94.5387,
    postal_code: '64489'
  },
  '64490': {
    country: 'US',
    city: 'Stewartsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'DeKalb',
    latitude: 39.7984,
    longitude: -94.5175,
    postal_code: '64490'
  },
  '64491': {
    country: 'US',
    city: 'Tarkio',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Atchison',
    latitude: 40.4418,
    longitude: -95.3786,
    postal_code: '64491'
  },
  '64492': {
    country: 'US',
    city: 'Trimble',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clinton',
    latitude: 39.4871,
    longitude: -94.5512,
    postal_code: '64492'
  },
  '64493': {
    country: 'US',
    city: 'Turney',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Clinton',
    latitude: 39.6318,
    longitude: -94.2972,
    postal_code: '64493'
  },
  '64494': {
    country: 'US',
    city: 'Union Star',
    state: 'Missouri',
    state_short: 'MO',
    county: 'DeKalb',
    latitude: 39.9846,
    longitude: -94.5787,
    postal_code: '64494'
  },
  '64496': {
    country: 'US',
    city: 'Watson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Atchison',
    latitude: 40.478,
    longitude: -95.6193,
    postal_code: '64496'
  },
  '64497': {
    country: 'US',
    city: 'Weatherby',
    state: 'Missouri',
    state_short: 'MO',
    county: 'DeKalb',
    latitude: 39.9277,
    longitude: -94.2429,
    postal_code: '64497'
  },
  '64498': {
    country: 'US',
    city: 'Westboro',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Atchison',
    latitude: 40.5357,
    longitude: -95.3134,
    postal_code: '64498'
  },
  '64499': {
    country: 'US',
    city: 'Worth',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Worth',
    latitude: 40.3939,
    longitude: -94.4398,
    postal_code: '64499'
  },
  '64501': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.7688,
    longitude: -94.8385,
    postal_code: '64501'
  },
  '64502': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.7686,
    longitude: -94.8466,
    postal_code: '64502'
  },
  '64503': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.734,
    longitude: -94.8171,
    postal_code: '64503'
  },
  '64504': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.7076,
    longitude: -94.8677,
    postal_code: '64504'
  },
  '64505': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.7965,
    longitude: -94.8443,
    postal_code: '64505'
  },
  '64506': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Andrew',
    latitude: 39.7893,
    longitude: -94.8043,
    postal_code: '64506'
  },
  '64507': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.7551,
    longitude: -94.8173,
    postal_code: '64507'
  },
  '64508': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Buchanan',
    latitude: 39.6763,
    longitude: -94.8574,
    postal_code: '64508'
  },
  '64601': {
    country: 'US',
    city: 'Chillicothe',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Livingston',
    latitude: 39.7966,
    longitude: -93.5509,
    postal_code: '64601'
  },
  '64620': {
    country: 'US',
    city: 'Altamont',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Daviess',
    latitude: 39.896,
    longitude: -94.0897,
    postal_code: '64620'
  },
  '64622': {
    country: 'US',
    city: 'Bogard',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carroll',
    latitude: 39.4994,
    longitude: -93.5374,
    postal_code: '64622'
  },
  '64623': {
    country: 'US',
    city: 'Bosworth',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carroll',
    latitude: 39.4767,
    longitude: -93.3335,
    postal_code: '64623'
  },
  '64624': {
    country: 'US',
    city: 'Braymer',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Caldwell',
    latitude: 39.5915,
    longitude: -93.7887,
    postal_code: '64624'
  },
  '64625': {
    country: 'US',
    city: 'Breckenridge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Caldwell',
    latitude: 39.7582,
    longitude: -93.8068,
    postal_code: '64625'
  },
  '64628': {
    country: 'US',
    city: 'Brookfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Linn',
    latitude: 39.7846,
    longitude: -93.0719,
    postal_code: '64628'
  },
  '64630': {
    country: 'US',
    city: 'Browning',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Linn',
    latitude: 40.029,
    longitude: -93.1607,
    postal_code: '64630'
  },
  '64631': {
    country: 'US',
    city: 'Bucklin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Linn',
    latitude: 39.8006,
    longitude: -92.8928,
    postal_code: '64631'
  },
  '64632': {
    country: 'US',
    city: 'Cainsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Harrison',
    latitude: 40.4578,
    longitude: -93.759,
    postal_code: '64632'
  },
  '64633': {
    country: 'US',
    city: 'Carrollton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carroll',
    latitude: 39.3673,
    longitude: -93.4926,
    postal_code: '64633'
  },
  '64635': {
    country: 'US',
    city: 'Chula',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Livingston',
    latitude: 39.9226,
    longitude: -93.4841,
    postal_code: '64635'
  },
  '64636': {
    country: 'US',
    city: 'Coffey',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Daviess',
    latitude: 40.0999,
    longitude: -94.0252,
    postal_code: '64636'
  },
  '64637': {
    country: 'US',
    city: 'Cowgill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Caldwell',
    latitude: 39.5643,
    longitude: -93.9295,
    postal_code: '64637'
  },
  '64638': {
    country: 'US',
    city: 'Dawn',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Livingston',
    latitude: 39.6665,
    longitude: -93.5964,
    postal_code: '64638'
  },
  '64639': {
    country: 'US',
    city: 'De Witt',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carroll',
    latitude: 39.384,
    longitude: -93.2238,
    postal_code: '64639'
  },
  '64640': {
    country: 'US',
    city: 'Gallatin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Daviess',
    latitude: 39.9025,
    longitude: -93.9787,
    postal_code: '64640'
  },
  '64641': {
    country: 'US',
    city: 'Galt',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Grundy',
    latitude: 40.144,
    longitude: -93.3953,
    postal_code: '64641'
  },
  '64642': {
    country: 'US',
    city: 'Gilman City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Daviess',
    latitude: 40.145,
    longitude: -93.832,
    postal_code: '64642'
  },
  '64643': {
    country: 'US',
    city: 'Hale',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carroll',
    latitude: 39.5953,
    longitude: -93.3445,
    postal_code: '64643'
  },
  '64644': {
    country: 'US',
    city: 'Hamilton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Caldwell',
    latitude: 39.7364,
    longitude: -93.9909,
    postal_code: '64644'
  },
  '64645': {
    country: 'US',
    city: 'Harris',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Sullivan',
    latitude: 40.3075,
    longitude: -93.3501,
    postal_code: '64645'
  },
  '64646': {
    country: 'US',
    city: 'Humphreys',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Sullivan',
    latitude: 40.1144,
    longitude: -93.3015,
    postal_code: '64646'
  },
  '64647': {
    country: 'US',
    city: 'Jameson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Daviess',
    latitude: 40.0046,
    longitude: -93.9597,
    postal_code: '64647'
  },
  '64648': {
    country: 'US',
    city: 'Jamesport',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Daviess',
    latitude: 39.9837,
    longitude: -93.78,
    postal_code: '64648'
  },
  '64649': {
    country: 'US',
    city: 'Kidder',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Caldwell',
    latitude: 39.7803,
    longitude: -94.1024,
    postal_code: '64649'
  },
  '64650': {
    country: 'US',
    city: 'Kingston',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Caldwell',
    latitude: 39.6508,
    longitude: -94.0827,
    postal_code: '64650'
  },
  '64651': {
    country: 'US',
    city: 'Laclede',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Linn',
    latitude: 39.7837,
    longitude: -93.168,
    postal_code: '64651'
  },
  '64652': {
    country: 'US',
    city: 'Laredo',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Grundy',
    latitude: 40.0144,
    longitude: -93.4407,
    postal_code: '64652'
  },
  '64653': {
    country: 'US',
    city: 'Linneus',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Linn',
    latitude: 39.9099,
    longitude: -93.1885,
    postal_code: '64653'
  },
  '64654': {
    country: 'US',
    city: 'Lock Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Daviess',
    latitude: 39.8489,
    longitude: -93.7774,
    postal_code: '64654'
  },
  '64655': {
    country: 'US',
    city: 'Lucerne',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Putnam',
    latitude: 40.4382,
    longitude: -93.2867,
    postal_code: '64655'
  },
  '64656': {
    country: 'US',
    city: 'Ludlow',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Livingston',
    latitude: 39.6551,
    longitude: -93.7046,
    postal_code: '64656'
  },
  '64657': {
    country: 'US',
    city: 'Mc Fall',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gentry',
    latitude: 40.1051,
    longitude: -94.3003,
    postal_code: '64657'
  },
  '64658': {
    country: 'US',
    city: 'Marceline',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Linn',
    latitude: 39.7125,
    longitude: -92.9455,
    postal_code: '64658'
  },
  '64659': {
    country: 'US',
    city: 'Meadville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Linn',
    latitude: 39.7795,
    longitude: -93.3014,
    postal_code: '64659'
  },
  '64660': {
    country: 'US',
    city: 'Mendon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Chariton',
    latitude: 39.5828,
    longitude: -93.0892,
    postal_code: '64660'
  },
  '64661': {
    country: 'US',
    city: 'Mercer',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Mercer',
    latitude: 40.5169,
    longitude: -93.5242,
    postal_code: '64661'
  },
  '64664': {
    country: 'US',
    city: 'Mooresville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Livingston',
    latitude: 39.7425,
    longitude: -93.7169,
    postal_code: '64664'
  },
  '64667': {
    country: 'US',
    city: 'Newtown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Sullivan',
    latitude: 40.3612,
    longitude: -93.3073,
    postal_code: '64667'
  },
  '64668': {
    country: 'US',
    city: 'Norborne',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carroll',
    latitude: 39.3299,
    longitude: -93.6761,
    postal_code: '64668'
  },
  '64670': {
    country: 'US',
    city: 'Pattonsburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Daviess',
    latitude: 40.0428,
    longitude: -94.1343,
    postal_code: '64670'
  },
  '64671': {
    country: 'US',
    city: 'Polo',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Caldwell',
    latitude: 39.5647,
    longitude: -94.0743,
    postal_code: '64671'
  },
  '64672': {
    country: 'US',
    city: 'Powersville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Putnam',
    latitude: 40.5492,
    longitude: -93.3002,
    postal_code: '64672'
  },
  '64673': {
    country: 'US',
    city: 'Princeton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Mercer',
    latitude: 40.3855,
    longitude: -93.5774,
    postal_code: '64673'
  },
  '64674': {
    country: 'US',
    city: 'Purdin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Linn',
    latitude: 39.9529,
    longitude: -93.17,
    postal_code: '64674'
  },
  '64676': {
    country: 'US',
    city: 'Rothville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Chariton',
    latitude: 39.6627,
    longitude: -93.0467,
    postal_code: '64676'
  },
  '64679': {
    country: 'US',
    city: 'Spickard',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Grundy',
    latitude: 40.2439,
    longitude: -93.5944,
    postal_code: '64679'
  },
  '64680': {
    country: 'US',
    city: 'Stet',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carroll',
    latitude: 39.4231,
    longitude: -93.7588,
    postal_code: '64680'
  },
  '64681': {
    country: 'US',
    city: 'Sumner',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Chariton',
    latitude: 39.655,
    longitude: -93.2241,
    postal_code: '64681'
  },
  '64682': {
    country: 'US',
    city: 'Tina',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Carroll',
    latitude: 39.5518,
    longitude: -93.4647,
    postal_code: '64682'
  },
  '64683': {
    country: 'US',
    city: 'Trenton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Grundy',
    latitude: 40.0823,
    longitude: -93.6086,
    postal_code: '64683'
  },
  '64686': {
    country: 'US',
    city: 'Utica',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Livingston',
    latitude: 39.7417,
    longitude: -93.6289,
    postal_code: '64686'
  },
  '64688': {
    country: 'US',
    city: 'Wheeling',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Livingston',
    latitude: 39.8011,
    longitude: -93.3868,
    postal_code: '64688'
  },
  '64689': {
    country: 'US',
    city: 'Winston',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Daviess',
    latitude: 39.8499,
    longitude: -94.1487,
    postal_code: '64689'
  },
  '64701': {
    country: 'US',
    city: 'Harrisonville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.6419,
    longitude: -94.3285,
    postal_code: '64701'
  },
  '64720': {
    country: 'US',
    city: 'Adrian',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bates',
    latitude: 38.4125,
    longitude: -94.3683,
    postal_code: '64720'
  },
  '64722': {
    country: 'US',
    city: 'Amoret',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bates',
    latitude: 38.2605,
    longitude: -94.5734,
    postal_code: '64722'
  },
  '64723': {
    country: 'US',
    city: 'Amsterdam',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bates',
    latitude: 38.3954,
    longitude: -94.5763,
    postal_code: '64723'
  },
  '64724': {
    country: 'US',
    city: 'Appleton City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Clair',
    latitude: 38.1848,
    longitude: -94.0229,
    postal_code: '64724'
  },
  '64725': {
    country: 'US',
    city: 'Archie',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.4986,
    longitude: -94.363,
    postal_code: '64725'
  },
  '64726': {
    country: 'US',
    city: 'Blairstown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Henry',
    latitude: 38.5319,
    longitude: -93.9221,
    postal_code: '64726'
  },
  '64728': {
    country: 'US',
    city: 'Bronaugh',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 37.69,
    longitude: -94.486,
    postal_code: '64728'
  },
  '64730': {
    country: 'US',
    city: 'Butler',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bates',
    latitude: 38.2712,
    longitude: -94.3137,
    postal_code: '64730'
  },
  '64733': {
    country: 'US',
    city: 'Chilhowee',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Johnson',
    latitude: 38.6126,
    longitude: -93.8653,
    postal_code: '64733'
  },
  '64734': {
    country: 'US',
    city: 'Cleveland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.6898,
    longitude: -94.5695,
    postal_code: '64734'
  },
  '64735': {
    country: 'US',
    city: 'Clinton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Henry',
    latitude: 38.4018,
    longitude: -93.785,
    postal_code: '64735'
  },
  '64738': {
    country: 'US',
    city: 'Collins',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Clair',
    latitude: 37.881,
    longitude: -93.6608,
    postal_code: '64738'
  },
  '64739': {
    country: 'US',
    city: 'Creighton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.5078,
    longitude: -94.0926,
    postal_code: '64739'
  },
  '64740': {
    country: 'US',
    city: 'Deepwater',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Henry',
    latitude: 38.2432,
    longitude: -93.7303,
    postal_code: '64740'
  },
  '64741': {
    country: 'US',
    city: 'Deerfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 37.8197,
    longitude: -94.5608,
    postal_code: '64741'
  },
  '64742': {
    country: 'US',
    city: 'Drexel',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bates',
    latitude: 38.4956,
    longitude: -94.5928,
    postal_code: '64742'
  },
  '64743': {
    country: 'US',
    city: 'East Lynne',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.6682,
    longitude: -94.2333,
    postal_code: '64743'
  },
  '64744': {
    country: 'US',
    city: 'El Dorado Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cedar',
    latitude: 37.8652,
    longitude: -94.0124,
    postal_code: '64744'
  },
  '64745': {
    country: 'US',
    city: 'Foster',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bates',
    latitude: 38.1661,
    longitude: -94.5075,
    postal_code: '64745'
  },
  '64746': {
    country: 'US',
    city: 'Freeman',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.6245,
    longitude: -94.4956,
    postal_code: '64746'
  },
  '64747': {
    country: 'US',
    city: 'Garden City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cass',
    latitude: 38.5681,
    longitude: -94.1825,
    postal_code: '64747'
  },
  '64748': {
    country: 'US',
    city: 'Golden City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barton',
    latitude: 37.3995,
    longitude: -94.1027,
    postal_code: '64748'
  },
  '64750': {
    country: 'US',
    city: 'Harwood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 37.953,
    longitude: -94.1402,
    postal_code: '64750'
  },
  '64752': {
    country: 'US',
    city: 'Hume',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bates',
    latitude: 38.09,
    longitude: -94.5838,
    postal_code: '64752'
  },
  '64755': {
    country: 'US',
    city: 'Jasper',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.3362,
    longitude: -94.3013,
    postal_code: '64755'
  },
  '64756': {
    country: 'US',
    city: 'Jerico Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cedar',
    latitude: 37.6613,
    longitude: -94.0129,
    postal_code: '64756'
  },
  '64759': {
    country: 'US',
    city: 'Lamar',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barton',
    latitude: 37.5191,
    longitude: -94.3364,
    postal_code: '64759'
  },
  '64761': {
    country: 'US',
    city: 'Leeton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Johnson',
    latitude: 38.5831,
    longitude: -93.6947,
    postal_code: '64761'
  },
  '64762': {
    country: 'US',
    city: 'Liberal',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barton',
    latitude: 37.5725,
    longitude: -94.5204,
    postal_code: '64762'
  },
  '64763': {
    country: 'US',
    city: 'Lowry City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Clair',
    latitude: 38.1404,
    longitude: -93.7114,
    postal_code: '64763'
  },
  '64765': {
    country: 'US',
    city: 'Metz',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 37.9975,
    longitude: -94.4433,
    postal_code: '64765'
  },
  '64766': {
    country: 'US',
    city: 'Milford',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barton',
    latitude: 37.5848,
    longitude: -94.1572,
    postal_code: '64766'
  },
  '64767': {
    country: 'US',
    city: 'Milo',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 37.7446,
    longitude: -94.3046,
    postal_code: '64767'
  },
  '64769': {
    country: 'US',
    city: 'Mindenmines',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barton',
    latitude: 37.452,
    longitude: -94.5756,
    postal_code: '64769'
  },
  '64770': {
    country: 'US',
    city: 'Montrose',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Henry',
    latitude: 38.2597,
    longitude: -93.9952,
    postal_code: '64770'
  },
  '64771': {
    country: 'US',
    city: 'Moundville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 37.7476,
    longitude: -94.4496,
    postal_code: '64771'
  },
  '64772': {
    country: 'US',
    city: 'Nevada',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 37.8409,
    longitude: -94.3571,
    postal_code: '64772'
  },
  '64776': {
    country: 'US',
    city: 'Osceola',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Clair',
    latitude: 38.0286,
    longitude: -93.7536,
    postal_code: '64776'
  },
  '64778': {
    country: 'US',
    city: 'Richards',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 37.9061,
    longitude: -94.5592,
    postal_code: '64778'
  },
  '64779': {
    country: 'US',
    city: 'Rich Hill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bates',
    latitude: 38.0944,
    longitude: -94.3635,
    postal_code: '64779'
  },
  '64780': {
    country: 'US',
    city: 'Rockville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Bates',
    latitude: 38.0766,
    longitude: -94.1299,
    postal_code: '64780'
  },
  '64781': {
    country: 'US',
    city: 'Roscoe',
    state: 'Missouri',
    state_short: 'MO',
    county: 'St. Clair',
    latitude: 37.9728,
    longitude: -93.8122,
    postal_code: '64781'
  },
  '64783': {
    country: 'US',
    city: 'Schell City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 38.0094,
    longitude: -94.1579,
    postal_code: '64783'
  },
  '64784': {
    country: 'US',
    city: 'Sheldon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 37.684,
    longitude: -94.2548,
    postal_code: '64784'
  },
  '64788': {
    country: 'US',
    city: 'Urich',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Henry',
    latitude: 38.4449,
    longitude: -93.9785,
    postal_code: '64788'
  },
  '64790': {
    country: 'US',
    city: 'Walker',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Vernon',
    latitude: 37.893,
    longitude: -94.2293,
    postal_code: '64790'
  },
  '64801': {
    country: 'US',
    city: 'Joplin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.0969,
    longitude: -94.5051,
    postal_code: '64801'
  },
  '64802': {
    country: 'US',
    city: 'Joplin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.0842,
    longitude: -94.5133,
    postal_code: '64802'
  },
  '64803': {
    country: 'US',
    city: 'Joplin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.0842,
    longitude: -94.5133,
    postal_code: '64803'
  },
  '64804': {
    country: 'US',
    city: 'Joplin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.0465,
    longitude: -94.5103,
    postal_code: '64804'
  },
  '64830': {
    country: 'US',
    city: 'Alba',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.2367,
    longitude: -94.418,
    postal_code: '64830'
  },
  '64831': {
    country: 'US',
    city: 'Anderson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'McDonald',
    latitude: 36.6506,
    longitude: -94.4436,
    postal_code: '64831'
  },
  '64832': {
    country: 'US',
    city: 'Asbury',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.2942,
    longitude: -94.5655,
    postal_code: '64832'
  },
  '64833': {
    country: 'US',
    city: 'Avilla',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.1953,
    longitude: -94.1297,
    postal_code: '64833'
  },
  '64834': {
    country: 'US',
    city: 'Carl Junction',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.1795,
    longitude: -94.555,
    postal_code: '64834'
  },
  '64835': {
    country: 'US',
    city: 'Carterville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.1507,
    longitude: -94.4359,
    postal_code: '64835'
  },
  '64836': {
    country: 'US',
    city: 'Carthage',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.1597,
    longitude: -94.3112,
    postal_code: '64836'
  },
  '64840': {
    country: 'US',
    city: 'Diamond',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 37.006,
    longitude: -94.3204,
    postal_code: '64840'
  },
  '64841': {
    country: 'US',
    city: 'Duenweg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.076,
    longitude: -94.4071,
    postal_code: '64841'
  },
  '64842': {
    country: 'US',
    city: 'Fairview',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 36.8254,
    longitude: -94.0912,
    postal_code: '64842'
  },
  '64843': {
    country: 'US',
    city: 'Goodman',
    state: 'Missouri',
    state_short: 'MO',
    county: 'McDonald',
    latitude: 36.7323,
    longitude: -94.3986,
    postal_code: '64843'
  },
  '64844': {
    country: 'US',
    city: 'Granby',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 36.9066,
    longitude: -94.2643,
    postal_code: '64844'
  },
  '64847': {
    country: 'US',
    city: 'Lanagan',
    state: 'Missouri',
    state_short: 'MO',
    county: 'McDonald',
    latitude: 36.606,
    longitude: -94.4551,
    postal_code: '64847'
  },
  '64848': {
    country: 'US',
    city: 'La Russell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.1739,
    longitude: -94.0329,
    postal_code: '64848'
  },
  '64849': {
    country: 'US',
    city: 'Neck City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.257,
    longitude: -94.4444,
    postal_code: '64849'
  },
  '64850': {
    country: 'US',
    city: 'Neosho',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 36.8706,
    longitude: -94.3862,
    postal_code: '64850'
  },
  '64853': {
    country: 'US',
    city: 'Newtonia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 36.8767,
    longitude: -94.1855,
    postal_code: '64853'
  },
  '64854': {
    country: 'US',
    city: 'Noel',
    state: 'Missouri',
    state_short: 'MO',
    county: 'McDonald',
    latitude: 36.5417,
    longitude: -94.4906,
    postal_code: '64854'
  },
  '64855': {
    country: 'US',
    city: 'Oronogo',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.2717,
    longitude: -94.4865,
    postal_code: '64855'
  },
  '64856': {
    country: 'US',
    city: 'Pineville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'McDonald',
    latitude: 36.574,
    longitude: -94.377,
    postal_code: '64856'
  },
  '64857': {
    country: 'US',
    city: 'Purcell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.2423,
    longitude: -94.4352,
    postal_code: '64857'
  },
  '64858': {
    country: 'US',
    city: 'Racine',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 36.901,
    longitude: -94.5321,
    postal_code: '64858'
  },
  '64859': {
    country: 'US',
    city: 'Reeds',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.1225,
    longitude: -94.1613,
    postal_code: '64859'
  },
  '64861': {
    country: 'US',
    city: 'Rocky Comfort',
    state: 'Missouri',
    state_short: 'MO',
    county: 'McDonald',
    latitude: 36.7175,
    longitude: -94.1091,
    postal_code: '64861'
  },
  '64862': {
    country: 'US',
    city: 'Sarcoxie',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.0724,
    longitude: -94.1151,
    postal_code: '64862'
  },
  '64863': {
    country: 'US',
    city: 'South West City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'McDonald',
    latitude: 36.5319,
    longitude: -94.5961,
    postal_code: '64863'
  },
  '64864': {
    country: 'US',
    city: 'Saginaw',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 37.024,
    longitude: -94.4683,
    postal_code: '64864'
  },
  '64865': {
    country: 'US',
    city: 'Seneca',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 36.8408,
    longitude: -94.5781,
    postal_code: '64865'
  },
  '64866': {
    country: 'US',
    city: 'Stark City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 36.8785,
    longitude: -94.1548,
    postal_code: '64866'
  },
  '64867': {
    country: 'US',
    city: 'Stella',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 36.7516,
    longitude: -94.2086,
    postal_code: '64867'
  },
  '64868': {
    country: 'US',
    city: 'Tiff City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'McDonald',
    latitude: 36.6684,
    longitude: -94.6172,
    postal_code: '64868'
  },
  '64870': {
    country: 'US',
    city: 'Webb City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jasper',
    latitude: 37.144,
    longitude: -94.4727,
    postal_code: '64870'
  },
  '64873': {
    country: 'US',
    city: 'Wentworth',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Newton',
    latitude: 37.0177,
    longitude: -94.0514,
    postal_code: '64873'
  },
  '64874': {
    country: 'US',
    city: 'Wheaton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.7651,
    longitude: -94.0492,
    postal_code: '64874'
  },
  '64999': {
    country: 'US',
    city: 'Kansas City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Jackson',
    latitude: 39.0997,
    longitude: -94.5786,
    postal_code: '64999'
  },
  '65001': {
    country: 'US',
    city: 'Argyle',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Osage',
    latitude: 38.2986,
    longitude: -92.0155,
    postal_code: '65001'
  },
  '65010': {
    country: 'US',
    city: 'Ashland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.7878,
    longitude: -92.2537,
    postal_code: '65010'
  },
  '65011': {
    country: 'US',
    city: 'Barnett',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Morgan',
    latitude: 38.3967,
    longitude: -92.6686,
    postal_code: '65011'
  },
  '65013': {
    country: 'US',
    city: 'Belle',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Maries',
    latitude: 38.2711,
    longitude: -91.7303,
    postal_code: '65013'
  },
  '65014': {
    country: 'US',
    city: 'Bland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gasconade',
    latitude: 38.3074,
    longitude: -91.6263,
    postal_code: '65014'
  },
  '65016': {
    country: 'US',
    city: 'Bonnots Mill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Osage',
    latitude: 38.5533,
    longitude: -91.9293,
    postal_code: '65016'
  },
  '65017': {
    country: 'US',
    city: 'Brumley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Miller',
    latitude: 38.0709,
    longitude: -92.4747,
    postal_code: '65017'
  },
  '65018': {
    country: 'US',
    city: 'California',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Moniteau',
    latitude: 38.6224,
    longitude: -92.5456,
    postal_code: '65018'
  },
  '65020': {
    country: 'US',
    city: 'Camdenton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Camden',
    latitude: 38.0185,
    longitude: -92.7677,
    postal_code: '65020'
  },
  '65023': {
    country: 'US',
    city: 'Centertown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.6297,
    longitude: -92.3995,
    postal_code: '65023'
  },
  '65024': {
    country: 'US',
    city: 'Chamois',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Osage',
    latitude: 38.6527,
    longitude: -91.7697,
    postal_code: '65024'
  },
  '65025': {
    country: 'US',
    city: 'Clarksburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Moniteau',
    latitude: 38.6432,
    longitude: -92.6729,
    postal_code: '65025'
  },
  '65026': {
    country: 'US',
    city: 'Eldon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Miller',
    latitude: 38.3401,
    longitude: -92.5736,
    postal_code: '65026'
  },
  '65032': {
    country: 'US',
    city: 'Eugene',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.3424,
    longitude: -92.3823,
    postal_code: '65032'
  },
  '65034': {
    country: 'US',
    city: 'Fortuna',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Moniteau',
    latitude: 38.5667,
    longitude: -92.798,
    postal_code: '65034'
  },
  '65035': {
    country: 'US',
    city: 'Freeburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Osage',
    latitude: 38.3551,
    longitude: -91.9276,
    postal_code: '65035'
  },
  '65036': {
    country: 'US',
    city: 'Gasconade',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gasconade',
    latitude: 38.6703,
    longitude: -91.5592,
    postal_code: '65036'
  },
  '65037': {
    country: 'US',
    city: 'Gravois Mills',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Morgan',
    latitude: 38.2584,
    longitude: -92.823,
    postal_code: '65037'
  },
  '65038': {
    country: 'US',
    city: 'Laurie',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Morgan',
    latitude: 38.1992,
    longitude: -92.8335,
    postal_code: '65038'
  },
  '65039': {
    country: 'US',
    city: 'Hartsburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.7159,
    longitude: -92.2864,
    postal_code: '65039'
  },
  '65040': {
    country: 'US',
    city: 'Henley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.3445,
    longitude: -92.3224,
    postal_code: '65040'
  },
  '65041': {
    country: 'US',
    city: 'Hermann',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gasconade',
    latitude: 38.5876,
    longitude: -91.4991,
    postal_code: '65041'
  },
  '65042': {
    country: 'US',
    city: 'High Point',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Moniteau',
    latitude: 38.6749,
    longitude: -92.6109,
    postal_code: '65042'
  },
  '65043': {
    country: 'US',
    city: 'Holts Summit',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Callaway',
    latitude: 38.6328,
    longitude: -92.1163,
    postal_code: '65043'
  },
  '65046': {
    country: 'US',
    city: 'Jamestown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Moniteau',
    latitude: 38.7793,
    longitude: -92.4807,
    postal_code: '65046'
  },
  '65047': {
    country: 'US',
    city: 'Kaiser',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Miller',
    latitude: 38.1637,
    longitude: -92.5799,
    postal_code: '65047'
  },
  '65048': {
    country: 'US',
    city: 'Koeltztown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Osage',
    latitude: 38.3615,
    longitude: -92.0468,
    postal_code: '65048'
  },
  '65049': {
    country: 'US',
    city: 'Lake Ozark',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Camden',
    latitude: 38.2003,
    longitude: -92.6684,
    postal_code: '65049'
  },
  '65050': {
    country: 'US',
    city: 'Latham',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Moniteau',
    latitude: 38.5466,
    longitude: -92.6817,
    postal_code: '65050'
  },
  '65051': {
    country: 'US',
    city: 'Linn',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Osage',
    latitude: 38.4739,
    longitude: -91.8195,
    postal_code: '65051'
  },
  '65052': {
    country: 'US',
    city: 'Linn Creek',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Camden',
    latitude: 38.0605,
    longitude: -92.6831,
    postal_code: '65052'
  },
  '65053': {
    country: 'US',
    city: 'Lohman',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5484,
    longitude: -92.3842,
    postal_code: '65053'
  },
  '65054': {
    country: 'US',
    city: 'Loose Creek',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Osage',
    latitude: 38.4717,
    longitude: -91.9591,
    postal_code: '65054'
  },
  '65055': {
    country: 'US',
    city: 'Mc Girk',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Moniteau',
    latitude: 38.6749,
    longitude: -92.6109,
    postal_code: '65055'
  },
  '65058': {
    country: 'US',
    city: 'Meta',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Osage',
    latitude: 38.25,
    longitude: -92.1358,
    postal_code: '65058'
  },
  '65059': {
    country: 'US',
    city: 'Mokane',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Callaway',
    latitude: 38.6998,
    longitude: -91.8868,
    postal_code: '65059'
  },
  '65061': {
    country: 'US',
    city: 'Morrison',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gasconade',
    latitude: 38.606,
    longitude: -91.658,
    postal_code: '65061'
  },
  '65062': {
    country: 'US',
    city: 'Mount Sterling',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gasconade',
    latitude: 38.4956,
    longitude: -91.6515,
    postal_code: '65062'
  },
  '65063': {
    country: 'US',
    city: 'New Bloomfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Callaway',
    latitude: 38.71,
    longitude: -92.083,
    postal_code: '65063'
  },
  '65064': {
    country: 'US',
    city: 'Olean',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Miller',
    latitude: 38.4109,
    longitude: -92.5294,
    postal_code: '65064'
  },
  '65065': {
    country: 'US',
    city: 'Osage Beach',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Camden',
    latitude: 38.138,
    longitude: -92.6664,
    postal_code: '65065'
  },
  '65066': {
    country: 'US',
    city: 'Owensville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Gasconade',
    latitude: 38.3511,
    longitude: -91.4867,
    postal_code: '65066'
  },
  '65067': {
    country: 'US',
    city: 'Portland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Callaway',
    latitude: 38.7109,
    longitude: -91.7177,
    postal_code: '65067'
  },
  '65068': {
    country: 'US',
    city: 'Prairie Home',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cooper',
    latitude: 38.8253,
    longitude: -92.5974,
    postal_code: '65068'
  },
  '65069': {
    country: 'US',
    city: 'Rhineland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Montgomery',
    latitude: 38.7942,
    longitude: -91.5737,
    postal_code: '65069'
  },
  '65072': {
    country: 'US',
    city: 'Rocky Mount',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Morgan',
    latitude: 38.2911,
    longitude: -92.7059,
    postal_code: '65072'
  },
  '65074': {
    country: 'US',
    city: 'Russellville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5005,
    longitude: -92.4291,
    postal_code: '65074'
  },
  '65075': {
    country: 'US',
    city: 'Saint Elizabeth',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Miller',
    latitude: 38.2712,
    longitude: -92.2635,
    postal_code: '65075'
  },
  '65076': {
    country: 'US',
    city: 'Saint Thomas',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.3912,
    longitude: -92.1894,
    postal_code: '65076'
  },
  '65077': {
    country: 'US',
    city: 'Steedman',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Callaway',
    latitude: 38.7566,
    longitude: -91.7888,
    postal_code: '65077'
  },
  '65078': {
    country: 'US',
    city: 'Stover',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Morgan',
    latitude: 38.4414,
    longitude: -92.9947,
    postal_code: '65078'
  },
  '65079': {
    country: 'US',
    city: 'Sunrise Beach',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Camden',
    latitude: 38.1558,
    longitude: -92.7854,
    postal_code: '65079'
  },
  '65080': {
    country: 'US',
    city: 'Tebbetts',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Callaway',
    latitude: 38.6402,
    longitude: -91.9673,
    postal_code: '65080'
  },
  '65081': {
    country: 'US',
    city: 'Tipton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Moniteau',
    latitude: 38.6548,
    longitude: -92.7814,
    postal_code: '65081'
  },
  '65082': {
    country: 'US',
    city: 'Tuscumbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Miller',
    latitude: 38.2181,
    longitude: -92.4461,
    postal_code: '65082'
  },
  '65083': {
    country: 'US',
    city: 'Ulman',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Miller',
    latitude: 38.1505,
    longitude: -92.433,
    postal_code: '65083'
  },
  '65084': {
    country: 'US',
    city: 'Versailles',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Morgan',
    latitude: 38.4365,
    longitude: -92.8258,
    postal_code: '65084'
  },
  '65085': {
    country: 'US',
    city: 'Westphalia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Osage',
    latitude: 38.427,
    longitude: -92.0392,
    postal_code: '65085'
  },
  '65101': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5462,
    longitude: -92.1525,
    postal_code: '65101'
  },
  '65102': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5767,
    longitude: -92.1735,
    postal_code: '65102'
  },
  '65103': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5767,
    longitude: -92.1735,
    postal_code: '65103'
  },
  '65104': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5767,
    longitude: -92.1735,
    postal_code: '65104'
  },
  '65105': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5767,
    longitude: -92.1735,
    postal_code: '65105'
  },
  '65106': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5767,
    longitude: -92.1735,
    postal_code: '65106'
  },
  '65107': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5767,
    longitude: -92.1735,
    postal_code: '65107'
  },
  '65108': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5767,
    longitude: -92.1735,
    postal_code: '65108'
  },
  '65109': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5773,
    longitude: -92.2443,
    postal_code: '65109'
  },
  '65110': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5767,
    longitude: -92.1735,
    postal_code: '65110'
  },
  '65111': {
    country: 'US',
    city: 'Jefferson City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cole',
    latitude: 38.5309,
    longitude: -92.2493,
    postal_code: '65111'
  },
  '65201': {
    country: 'US',
    city: 'Columbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.9382,
    longitude: -92.3049,
    postal_code: '65201'
  },
  '65202': {
    country: 'US',
    city: 'Columbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.995,
    longitude: -92.3112,
    postal_code: '65202'
  },
  '65203': {
    country: 'US',
    city: 'Columbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.9348,
    longitude: -92.3639,
    postal_code: '65203'
  },
  '65205': {
    country: 'US',
    city: 'Columbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 39.0447,
    longitude: -92.3496,
    postal_code: '65205'
  },
  '65211': {
    country: 'US',
    city: 'Columbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.9517,
    longitude: -92.3341,
    postal_code: '65211'
  },
  '65212': {
    country: 'US',
    city: 'Columbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.9376,
    longitude: -92.3304,
    postal_code: '65212'
  },
  '65215': {
    country: 'US',
    city: 'Columbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.9532,
    longitude: -92.3208,
    postal_code: '65215'
  },
  '65216': {
    country: 'US',
    city: 'Columbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.9517,
    longitude: -92.3341,
    postal_code: '65216'
  },
  '65217': {
    country: 'US',
    city: 'Columbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.9517,
    longitude: -92.3341,
    postal_code: '65217'
  },
  '65218': {
    country: 'US',
    city: 'Columbia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.9517,
    longitude: -92.3341,
    postal_code: '65218'
  },
  '65230': {
    country: 'US',
    city: 'Armstrong',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howard',
    latitude: 39.2566,
    longitude: -92.709,
    postal_code: '65230'
  },
  '65231': {
    country: 'US',
    city: 'Auxvasse',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Callaway',
    latitude: 39.0122,
    longitude: -91.8858,
    postal_code: '65231'
  },
  '65232': {
    country: 'US',
    city: 'Benton City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Audrain',
    latitude: 39.1209,
    longitude: -91.7661,
    postal_code: '65232'
  },
  '65233': {
    country: 'US',
    city: 'Boonville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cooper',
    latitude: 38.9536,
    longitude: -92.745,
    postal_code: '65233'
  },
  '65236': {
    country: 'US',
    city: 'Brunswick',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Chariton',
    latitude: 39.4374,
    longitude: -93.1187,
    postal_code: '65236'
  },
  '65237': {
    country: 'US',
    city: 'Bunceton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cooper',
    latitude: 38.7881,
    longitude: -92.7994,
    postal_code: '65237'
  },
  '65239': {
    country: 'US',
    city: 'Cairo',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Randolph',
    latitude: 39.5114,
    longitude: -92.44,
    postal_code: '65239'
  },
  '65240': {
    country: 'US',
    city: 'Centralia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 39.1961,
    longitude: -92.1472,
    postal_code: '65240'
  },
  '65243': {
    country: 'US',
    city: 'Clark',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Randolph',
    latitude: 39.2811,
    longitude: -92.3427,
    postal_code: '65243'
  },
  '65244': {
    country: 'US',
    city: 'Clifton Hill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Randolph',
    latitude: 39.426,
    longitude: -92.6677,
    postal_code: '65244'
  },
  '65246': {
    country: 'US',
    city: 'Dalton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Chariton',
    latitude: 39.4035,
    longitude: -92.9944,
    postal_code: '65246'
  },
  '65247': {
    country: 'US',
    city: 'Excello',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Macon',
    latitude: 39.6455,
    longitude: -92.4757,
    postal_code: '65247'
  },
  '65248': {
    country: 'US',
    city: 'Fayette',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howard',
    latitude: 39.143,
    longitude: -92.6583,
    postal_code: '65248'
  },
  '65250': {
    country: 'US',
    city: 'Franklin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howard',
    latitude: 39.0668,
    longitude: -92.8316,
    postal_code: '65250'
  },
  '65251': {
    country: 'US',
    city: 'Fulton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Callaway',
    latitude: 38.8518,
    longitude: -91.9605,
    postal_code: '65251'
  },
  '65254': {
    country: 'US',
    city: 'Glasgow',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howard',
    latitude: 39.2257,
    longitude: -92.8318,
    postal_code: '65254'
  },
  '65255': {
    country: 'US',
    city: 'Hallsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 39.1054,
    longitude: -92.2239,
    postal_code: '65255'
  },
  '65256': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 39.1203,
    longitude: -92.441,
    postal_code: '65256'
  },
  '65257': {
    country: 'US',
    city: 'Higbee',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Randolph',
    latitude: 39.3055,
    longitude: -92.5163,
    postal_code: '65257'
  },
  '65258': {
    country: 'US',
    city: 'Holliday',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Monroe',
    latitude: 39.4904,
    longitude: -92.1318,
    postal_code: '65258'
  },
  '65259': {
    country: 'US',
    city: 'Huntsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Randolph',
    latitude: 39.4354,
    longitude: -92.553,
    postal_code: '65259'
  },
  '65260': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Randolph',
    latitude: 39.5791,
    longitude: -92.4315,
    postal_code: '65260'
  },
  '65261': {
    country: 'US',
    city: 'Keytesville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Chariton',
    latitude: 39.4794,
    longitude: -92.9302,
    postal_code: '65261'
  },
  '65262': {
    country: 'US',
    city: 'Kingdom City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Callaway',
    latitude: 38.9551,
    longitude: -91.952,
    postal_code: '65262'
  },
  '65263': {
    country: 'US',
    city: 'Madison',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Monroe',
    latitude: 39.4615,
    longitude: -92.2287,
    postal_code: '65263'
  },
  '65264': {
    country: 'US',
    city: 'Martinsburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Audrain',
    latitude: 39.0968,
    longitude: -91.6646,
    postal_code: '65264'
  },
  '65265': {
    country: 'US',
    city: 'Mexico',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Audrain',
    latitude: 39.1712,
    longitude: -91.8895,
    postal_code: '65265'
  },
  '65270': {
    country: 'US',
    city: 'Moberly',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Randolph',
    latitude: 39.4202,
    longitude: -92.4358,
    postal_code: '65270'
  },
  '65274': {
    country: 'US',
    city: 'New Franklin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howard',
    latitude: 39.02,
    longitude: -92.7386,
    postal_code: '65274'
  },
  '65275': {
    country: 'US',
    city: 'Paris',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Monroe',
    latitude: 39.4932,
    longitude: -92.0113,
    postal_code: '65275'
  },
  '65276': {
    country: 'US',
    city: 'Pilot Grove',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cooper',
    latitude: 38.8712,
    longitude: -92.9305,
    postal_code: '65276'
  },
  '65278': {
    country: 'US',
    city: 'Renick',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Randolph',
    latitude: 39.3414,
    longitude: -92.411,
    postal_code: '65278'
  },
  '65279': {
    country: 'US',
    city: 'Rocheport',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.9756,
    longitude: -92.5079,
    postal_code: '65279'
  },
  '65280': {
    country: 'US',
    city: 'Rush Hill',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Audrain',
    latitude: 39.21,
    longitude: -91.7216,
    postal_code: '65280'
  },
  '65281': {
    country: 'US',
    city: 'Salisbury',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Chariton',
    latitude: 39.4319,
    longitude: -92.8014,
    postal_code: '65281'
  },
  '65282': {
    country: 'US',
    city: 'Santa Fe',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Monroe',
    latitude: 39.3989,
    longitude: -91.8294,
    postal_code: '65282'
  },
  '65283': {
    country: 'US',
    city: 'Stoutsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Monroe',
    latitude: 39.5594,
    longitude: -91.8297,
    postal_code: '65283'
  },
  '65284': {
    country: 'US',
    city: 'Sturgeon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 39.2057,
    longitude: -92.2953,
    postal_code: '65284'
  },
  '65285': {
    country: 'US',
    city: 'Thompson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Audrain',
    latitude: 39.2128,
    longitude: -92.0046,
    postal_code: '65285'
  },
  '65286': {
    country: 'US',
    city: 'Triplett',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Chariton',
    latitude: 39.5011,
    longitude: -93.1928,
    postal_code: '65286'
  },
  '65287': {
    country: 'US',
    city: 'Wooldridge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cooper',
    latitude: 38.8983,
    longitude: -92.5667,
    postal_code: '65287'
  },
  '65299': {
    country: 'US',
    city: 'Mid Missouri',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Boone',
    latitude: 38.9033,
    longitude: -92.1022,
    postal_code: '65299'
  },
  '65301': {
    country: 'US',
    city: 'Sedalia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pettis',
    latitude: 38.6961,
    longitude: -93.2323,
    postal_code: '65301'
  },
  '65302': {
    country: 'US',
    city: 'Sedalia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pettis',
    latitude: 38.7045,
    longitude: -93.2283,
    postal_code: '65302'
  },
  '65305': {
    country: 'US',
    city: 'Whiteman Air Force Base',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Johnson',
    latitude: 38.7318,
    longitude: -93.5731,
    postal_code: '65305'
  },
  '65320': {
    country: 'US',
    city: 'Arrow Rock',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saline',
    latitude: 39.0694,
    longitude: -92.9487,
    postal_code: '65320'
  },
  '65321': {
    country: 'US',
    city: 'Blackburn',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saline',
    latitude: 39.0988,
    longitude: -93.4281,
    postal_code: '65321'
  },
  '65322': {
    country: 'US',
    city: 'Blackwater',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cooper',
    latitude: 38.9727,
    longitude: -92.9683,
    postal_code: '65322'
  },
  '65323': {
    country: 'US',
    city: 'Calhoun',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Henry',
    latitude: 38.4858,
    longitude: -93.6459,
    postal_code: '65323'
  },
  '65324': {
    country: 'US',
    city: 'Climax Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Camden',
    latitude: 38.1396,
    longitude: -92.9537,
    postal_code: '65324'
  },
  '65325': {
    country: 'US',
    city: 'Cole Camp',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Benton',
    latitude: 38.4531,
    longitude: -93.1915,
    postal_code: '65325'
  },
  '65326': {
    country: 'US',
    city: 'Edwards',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Benton',
    latitude: 38.1906,
    longitude: -93.1471,
    postal_code: '65326'
  },
  '65327': {
    country: 'US',
    city: 'Emma',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lafayette',
    latitude: 38.9717,
    longitude: -93.4947,
    postal_code: '65327'
  },
  '65329': {
    country: 'US',
    city: 'Florence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Morgan',
    latitude: 38.6101,
    longitude: -92.9986,
    postal_code: '65329'
  },
  '65330': {
    country: 'US',
    city: 'Gilliam',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saline',
    latitude: 39.2447,
    longitude: -92.9932,
    postal_code: '65330'
  },
  '65332': {
    country: 'US',
    city: 'Green Ridge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pettis',
    latitude: 38.619,
    longitude: -93.4374,
    postal_code: '65332'
  },
  '65333': {
    country: 'US',
    city: 'Houstonia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pettis',
    latitude: 38.9106,
    longitude: -93.3325,
    postal_code: '65333'
  },
  '65334': {
    country: 'US',
    city: 'Hughesville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pettis',
    latitude: 38.8494,
    longitude: -93.2159,
    postal_code: '65334'
  },
  '65335': {
    country: 'US',
    city: 'Ionia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Benton',
    latitude: 38.5019,
    longitude: -93.3224,
    postal_code: '65335'
  },
  '65336': {
    country: 'US',
    city: 'Knob Noster',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Johnson',
    latitude: 38.7667,
    longitude: -93.5585,
    postal_code: '65336'
  },
  '65337': {
    country: 'US',
    city: 'La Monte',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pettis',
    latitude: 38.7753,
    longitude: -93.4313,
    postal_code: '65337'
  },
  '65338': {
    country: 'US',
    city: 'Lincoln',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Benton',
    latitude: 38.4073,
    longitude: -93.3668,
    postal_code: '65338'
  },
  '65339': {
    country: 'US',
    city: 'Malta Bend',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saline',
    latitude: 39.1481,
    longitude: -93.3776,
    postal_code: '65339'
  },
  '65340': {
    country: 'US',
    city: 'Marshall',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saline',
    latitude: 39.1614,
    longitude: -93.2444,
    postal_code: '65340'
  },
  '65344': {
    country: 'US',
    city: 'Miami',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saline',
    latitude: 39.2821,
    longitude: -93.1968,
    postal_code: '65344'
  },
  '65345': {
    country: 'US',
    city: 'Mora',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pettis',
    latitude: 38.5207,
    longitude: -93.2272,
    postal_code: '65345'
  },
  '65347': {
    country: 'US',
    city: 'Nelson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saline',
    latitude: 39.0104,
    longitude: -93.0311,
    postal_code: '65347'
  },
  '65348': {
    country: 'US',
    city: 'Otterville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cooper',
    latitude: 38.7161,
    longitude: -93.0108,
    postal_code: '65348'
  },
  '65349': {
    country: 'US',
    city: 'Slater',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saline',
    latitude: 39.2163,
    longitude: -93.0547,
    postal_code: '65349'
  },
  '65350': {
    country: 'US',
    city: 'Smithton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pettis',
    latitude: 38.66,
    longitude: -93.1088,
    postal_code: '65350'
  },
  '65351': {
    country: 'US',
    city: 'Sweet Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Saline',
    latitude: 38.966,
    longitude: -93.4247,
    postal_code: '65351'
  },
  '65354': {
    country: 'US',
    city: 'Syracuse',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Morgan',
    latitude: 38.6547,
    longitude: -92.8929,
    postal_code: '65354'
  },
  '65355': {
    country: 'US',
    city: 'Warsaw',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Benton',
    latitude: 38.2431,
    longitude: -93.3819,
    postal_code: '65355'
  },
  '65360': {
    country: 'US',
    city: 'Windsor',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Henry',
    latitude: 38.5272,
    longitude: -93.5269,
    postal_code: '65360'
  },
  '65401': {
    country: 'US',
    city: 'Rolla',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Phelps',
    latitude: 37.9485,
    longitude: -91.7603,
    postal_code: '65401'
  },
  '65402': {
    country: 'US',
    city: 'Rolla',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Phelps',
    latitude: 37.9514,
    longitude: -91.7713,
    postal_code: '65402'
  },
  '65409': {
    country: 'US',
    city: 'Rolla',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Phelps',
    latitude: 37.9514,
    longitude: -91.7713,
    postal_code: '65409'
  },
  '65436': {
    country: 'US',
    city: 'Beulah',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Phelps',
    latitude: 37.6158,
    longitude: -91.9064,
    postal_code: '65436'
  },
  '65438': {
    country: 'US',
    city: 'Birch Tree',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shannon',
    latitude: 36.9476,
    longitude: -91.5008,
    postal_code: '65438'
  },
  '65439': {
    country: 'US',
    city: 'Bixby',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Iron',
    latitude: 37.6603,
    longitude: -91.1151,
    postal_code: '65439'
  },
  '65440': {
    country: 'US',
    city: 'Boss',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dent',
    latitude: 37.6373,
    longitude: -91.2109,
    postal_code: '65440'
  },
  '65441': {
    country: 'US',
    city: 'Bourbon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Crawford',
    latitude: 38.172,
    longitude: -91.2225,
    postal_code: '65441'
  },
  '65443': {
    country: 'US',
    city: 'Brinktown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Maries',
    latitude: 38.1483,
    longitude: -92.1015,
    postal_code: '65443'
  },
  '65444': {
    country: 'US',
    city: 'Bucyrus',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.3972,
    longitude: -92.0465,
    postal_code: '65444'
  },
  '65446': {
    country: 'US',
    city: 'Cherryville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Crawford',
    latitude: 37.8066,
    longitude: -91.2348,
    postal_code: '65446'
  },
  '65449': {
    country: 'US',
    city: 'Cook Sta',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Crawford',
    latitude: 37.8242,
    longitude: -91.5623,
    postal_code: '65449'
  },
  '65452': {
    country: 'US',
    city: 'Crocker',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pulaski',
    latitude: 37.9446,
    longitude: -92.27,
    postal_code: '65452'
  },
  '65453': {
    country: 'US',
    city: 'Cuba',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Crawford',
    latitude: 38.0926,
    longitude: -91.4081,
    postal_code: '65453'
  },
  '65456': {
    country: 'US',
    city: 'Davisville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Crawford',
    latitude: 37.787,
    longitude: -91.2116,
    postal_code: '65456'
  },
  '65457': {
    country: 'US',
    city: 'Devils Elbow',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pulaski',
    latitude: 37.8512,
    longitude: -92.0569,
    postal_code: '65457'
  },
  '65459': {
    country: 'US',
    city: 'Dixon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pulaski',
    latitude: 37.9848,
    longitude: -92.0897,
    postal_code: '65459'
  },
  '65461': {
    country: 'US',
    city: 'Duke',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Phelps',
    latitude: 37.6603,
    longitude: -92.0107,
    postal_code: '65461'
  },
  '65462': {
    country: 'US',
    city: 'Edgar Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Phelps',
    latitude: 37.7365,
    longitude: -91.8906,
    postal_code: '65462'
  },
  '65463': {
    country: 'US',
    city: 'Eldridge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Laclede',
    latitude: 37.8346,
    longitude: -92.7382,
    postal_code: '65463'
  },
  '65464': {
    country: 'US',
    city: 'Elk Creek',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.1878,
    longitude: -91.9134,
    postal_code: '65464'
  },
  '65466': {
    country: 'US',
    city: 'Eminence',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shannon',
    latitude: 37.1626,
    longitude: -91.4519,
    postal_code: '65466'
  },
  '65468': {
    country: 'US',
    city: 'Eunice',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.248,
    longitude: -91.7834,
    postal_code: '65468'
  },
  '65470': {
    country: 'US',
    city: 'Falcon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Laclede',
    latitude: 37.6061,
    longitude: -92.3466,
    postal_code: '65470'
  },
  '65473': {
    country: 'US',
    city: 'Fort Leonard Wood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pulaski',
    latitude: 37.7677,
    longitude: -92.112,
    postal_code: '65473'
  },
  '65479': {
    country: 'US',
    city: 'Hartshorn',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.2843,
    longitude: -91.6834,
    postal_code: '65479'
  },
  '65483': {
    country: 'US',
    city: 'Houston',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.3218,
    longitude: -91.953,
    postal_code: '65483'
  },
  '65484': {
    country: 'US',
    city: 'Huggins',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.3538,
    longitude: -92.2153,
    postal_code: '65484'
  },
  '65486': {
    country: 'US',
    city: 'Iberia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Miller',
    latitude: 38.1219,
    longitude: -92.2989,
    postal_code: '65486'
  },
  '65501': {
    country: 'US',
    city: 'Jadwin',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dent',
    latitude: 37.4867,
    longitude: -91.571,
    postal_code: '65501'
  },
  '65529': {
    country: 'US',
    city: 'Jerome',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Phelps',
    latitude: 37.9262,
    longitude: -91.9777,
    postal_code: '65529'
  },
  '65532': {
    country: 'US',
    city: 'Lake Spring',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dent',
    latitude: 37.7825,
    longitude: -91.6788,
    postal_code: '65532'
  },
  '65534': {
    country: 'US',
    city: 'Laquey',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pulaski',
    latitude: 37.6953,
    longitude: -92.2808,
    postal_code: '65534'
  },
  '65535': {
    country: 'US',
    city: 'Leasburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Crawford',
    latitude: 38.092,
    longitude: -91.2965,
    postal_code: '65535'
  },
  '65536': {
    country: 'US',
    city: 'Lebanon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Laclede',
    latitude: 37.685,
    longitude: -92.655,
    postal_code: '65536'
  },
  '65541': {
    country: 'US',
    city: 'Lenox',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dent',
    latitude: 37.6531,
    longitude: -91.7613,
    postal_code: '65541'
  },
  '65542': {
    country: 'US',
    city: 'Licking',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.5091,
    longitude: -91.8556,
    postal_code: '65542'
  },
  '65543': {
    country: 'US',
    city: 'Lynchburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Laclede',
    latitude: 37.5054,
    longitude: -92.3202,
    postal_code: '65543'
  },
  '65546': {
    country: 'US',
    city: 'Montier',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shannon',
    latitude: 36.987,
    longitude: -91.5754,
    postal_code: '65546'
  },
  '65548': {
    country: 'US',
    city: 'Mountain View',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howell',
    latitude: 36.9892,
    longitude: -91.7099,
    postal_code: '65548'
  },
  '65550': {
    country: 'US',
    city: 'Newburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Phelps',
    latitude: 37.9008,
    longitude: -91.8807,
    postal_code: '65550'
  },
  '65552': {
    country: 'US',
    city: 'Plato',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.5122,
    longitude: -92.1709,
    postal_code: '65552'
  },
  '65555': {
    country: 'US',
    city: 'Raymondville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.3569,
    longitude: -91.8131,
    postal_code: '65555'
  },
  '65556': {
    country: 'US',
    city: 'Richland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pulaski',
    latitude: 37.8528,
    longitude: -92.3962,
    postal_code: '65556'
  },
  '65557': {
    country: 'US',
    city: 'Roby',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.5156,
    longitude: -92.139,
    postal_code: '65557'
  },
  '65559': {
    country: 'US',
    city: 'Saint James',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Phelps',
    latitude: 38.0056,
    longitude: -91.6076,
    postal_code: '65559'
  },
  '65560': {
    country: 'US',
    city: 'Salem',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dent',
    latitude: 37.617,
    longitude: -91.5258,
    postal_code: '65560'
  },
  '65564': {
    country: 'US',
    city: 'Solo',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.2428,
    longitude: -91.9624,
    postal_code: '65564'
  },
  '65565': {
    country: 'US',
    city: 'Steelville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Crawford',
    latitude: 37.8904,
    longitude: -91.3032,
    postal_code: '65565'
  },
  '65566': {
    country: 'US',
    city: 'Viburnum',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Iron',
    latitude: 37.7151,
    longitude: -91.1289,
    postal_code: '65566'
  },
  '65567': {
    country: 'US',
    city: 'Stoutland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Camden',
    latitude: 37.8693,
    longitude: -92.5114,
    postal_code: '65567'
  },
  '65570': {
    country: 'US',
    city: 'Success',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.4626,
    longitude: -92.0909,
    postal_code: '65570'
  },
  '65571': {
    country: 'US',
    city: 'Summersville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.1484,
    longitude: -91.6828,
    postal_code: '65571'
  },
  '65580': {
    country: 'US',
    city: 'Vichy',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Maries',
    latitude: 38.098,
    longitude: -91.7788,
    postal_code: '65580'
  },
  '65582': {
    country: 'US',
    city: 'Vienna',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Maries',
    latitude: 38.1919,
    longitude: -91.9422,
    postal_code: '65582'
  },
  '65583': {
    country: 'US',
    city: 'Waynesville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pulaski',
    latitude: 37.7676,
    longitude: -92.2105,
    postal_code: '65583'
  },
  '65584': {
    country: 'US',
    city: 'Saint Robert',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Pulaski',
    latitude: 37.8283,
    longitude: -92.131,
    postal_code: '65584'
  },
  '65586': {
    country: 'US',
    city: 'Wesco',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Crawford',
    latitude: 37.8585,
    longitude: -91.4267,
    postal_code: '65586'
  },
  '65588': {
    country: 'US',
    city: 'Winona',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Shannon',
    latitude: 37.0169,
    longitude: -91.3121,
    postal_code: '65588'
  },
  '65589': {
    country: 'US',
    city: 'Yukon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.2311,
    longitude: -91.8244,
    postal_code: '65589'
  },
  '65590': {
    country: 'US',
    city: 'Long Lane',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dallas',
    latitude: 37.5808,
    longitude: -92.9309,
    postal_code: '65590'
  },
  '65591': {
    country: 'US',
    city: 'Montreal',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Camden',
    latitude: 37.9851,
    longitude: -92.547,
    postal_code: '65591'
  },
  '65601': {
    country: 'US',
    city: 'Aldrich',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.5057,
    longitude: -93.5576,
    postal_code: '65601'
  },
  '65603': {
    country: 'US',
    city: 'Arcola',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dade',
    latitude: 37.5512,
    longitude: -93.8619,
    postal_code: '65603'
  },
  '65604': {
    country: 'US',
    city: 'Ash Grove',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.316,
    longitude: -93.5781,
    postal_code: '65604'
  },
  '65605': {
    country: 'US',
    city: 'Aurora',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lawrence',
    latitude: 36.9709,
    longitude: -93.718,
    postal_code: '65605'
  },
  '65606': {
    country: 'US',
    city: 'Alton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Oregon',
    latitude: 36.6929,
    longitude: -91.3971,
    postal_code: '65606'
  },
  '65607': {
    country: 'US',
    city: 'Caplinger Mills',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cedar',
    latitude: 37.7942,
    longitude: -93.8044,
    postal_code: '65607'
  },
  '65608': {
    country: 'US',
    city: 'Ava',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Douglas',
    latitude: 36.9407,
    longitude: -92.6765,
    postal_code: '65608'
  },
  '65609': {
    country: 'US',
    city: 'Bakersfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.5332,
    longitude: -92.1507,
    postal_code: '65609'
  },
  '65610': {
    country: 'US',
    city: 'Billings',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 37.0628,
    longitude: -93.5476,
    postal_code: '65610'
  },
  '65611': {
    country: 'US',
    city: 'Blue Eye',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stone',
    latitude: 36.5493,
    longitude: -93.3388,
    postal_code: '65611'
  },
  '65612': {
    country: 'US',
    city: 'Bois D Arc',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2214,
    longitude: -93.5447,
    postal_code: '65612'
  },
  '65613': {
    country: 'US',
    city: 'Bolivar',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.6085,
    longitude: -93.4126,
    postal_code: '65613'
  },
  '65614': {
    country: 'US',
    city: 'Bradleyville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.7659,
    longitude: -92.9151,
    postal_code: '65614'
  },
  '65615': {
    country: 'US',
    city: 'Branson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.661,
    longitude: -93.2358,
    postal_code: '65615'
  },
  '65616': {
    country: 'US',
    city: 'Branson',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.669,
    longitude: -93.2481,
    postal_code: '65616'
  },
  '65617': {
    country: 'US',
    city: 'Brighton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.4728,
    longitude: -93.3603,
    postal_code: '65617'
  },
  '65618': {
    country: 'US',
    city: 'Brixey',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.759,
    longitude: -92.4026,
    postal_code: '65618'
  },
  '65619': {
    country: 'US',
    city: 'Brookline',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.1634,
    longitude: -93.4202,
    postal_code: '65619'
  },
  '65620': {
    country: 'US',
    city: 'Bruner',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 36.999,
    longitude: -92.9692,
    postal_code: '65620'
  },
  '65622': {
    country: 'US',
    city: 'Buffalo',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dallas',
    latitude: 37.6429,
    longitude: -93.0906,
    postal_code: '65622'
  },
  '65623': {
    country: 'US',
    city: 'Butterfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.749,
    longitude: -93.9064,
    postal_code: '65623'
  },
  '65624': {
    country: 'US',
    city: 'Cape Fair',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stone',
    latitude: 36.7311,
    longitude: -93.5674,
    postal_code: '65624'
  },
  '65625': {
    country: 'US',
    city: 'Cassville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.6784,
    longitude: -93.8467,
    postal_code: '65625'
  },
  '65626': {
    country: 'US',
    city: 'Caulfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howell',
    latitude: 36.6035,
    longitude: -92.0678,
    postal_code: '65626'
  },
  '65627': {
    country: 'US',
    city: 'Cedarcreek',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.571,
    longitude: -93.0172,
    postal_code: '65627'
  },
  '65629': {
    country: 'US',
    city: 'Chadwick',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 36.922,
    longitude: -93.0451,
    postal_code: '65629'
  },
  '65630': {
    country: 'US',
    city: 'Chestnutridge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 36.8359,
    longitude: -93.2291,
    postal_code: '65630'
  },
  '65631': {
    country: 'US',
    city: 'Clever',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 37.0447,
    longitude: -93.4383,
    postal_code: '65631'
  },
  '65632': {
    country: 'US',
    city: 'Conway',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Laclede',
    latitude: 37.5085,
    longitude: -92.7891,
    postal_code: '65632'
  },
  '65633': {
    country: 'US',
    city: 'Crane',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stone',
    latitude: 36.9258,
    longitude: -93.5303,
    postal_code: '65633'
  },
  '65634': {
    country: 'US',
    city: 'Cross Timbers',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Hickory',
    latitude: 38.0239,
    longitude: -93.1978,
    postal_code: '65634'
  },
  '65635': {
    country: 'US',
    city: 'Dadeville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dade',
    latitude: 37.5105,
    longitude: -93.6956,
    postal_code: '65635'
  },
  '65636': {
    country: 'US',
    city: 'Diggins',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Webster',
    latitude: 37.1726,
    longitude: -92.8543,
    postal_code: '65636'
  },
  '65637': {
    country: 'US',
    city: 'Dora',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.756,
    longitude: -92.2378,
    postal_code: '65637'
  },
  '65638': {
    country: 'US',
    city: 'Drury',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Douglas',
    latitude: 36.9319,
    longitude: -92.3664,
    postal_code: '65638'
  },
  '65640': {
    country: 'US',
    city: 'Dunnegan',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.7031,
    longitude: -93.5216,
    postal_code: '65640'
  },
  '65641': {
    country: 'US',
    city: 'Eagle Rock',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.548,
    longitude: -93.7336,
    postal_code: '65641'
  },
  '65644': {
    country: 'US',
    city: 'Elkland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Webster',
    latitude: 37.4336,
    longitude: -93.021,
    postal_code: '65644'
  },
  '65645': {
    country: 'US',
    city: 'Eudora',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.4767,
    longitude: -93.5397,
    postal_code: '65645'
  },
  '65646': {
    country: 'US',
    city: 'Everton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dade',
    latitude: 37.3425,
    longitude: -93.7024,
    postal_code: '65646'
  },
  '65647': {
    country: 'US',
    city: 'Exeter',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.6815,
    longitude: -93.9702,
    postal_code: '65647'
  },
  '65648': {
    country: 'US',
    city: 'Fair Grove',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.3721,
    longitude: -93.1428,
    postal_code: '65648'
  },
  '65649': {
    country: 'US',
    city: 'Fair Play',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.6335,
    longitude: -93.6064,
    postal_code: '65649'
  },
  '65650': {
    country: 'US',
    city: 'Flemington',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.7803,
    longitude: -93.4471,
    postal_code: '65650'
  },
  '65652': {
    country: 'US',
    city: 'Fordland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Webster',
    latitude: 37.1447,
    longitude: -92.9111,
    postal_code: '65652'
  },
  '65653': {
    country: 'US',
    city: 'Forsyth',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.6955,
    longitude: -93.115,
    postal_code: '65653'
  },
  '65654': {
    country: 'US',
    city: 'Freistatt',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lawrence',
    latitude: 37.0211,
    longitude: -93.8974,
    postal_code: '65654'
  },
  '65655': {
    country: 'US',
    city: 'Gainesville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.5901,
    longitude: -92.4162,
    postal_code: '65655'
  },
  '65656': {
    country: 'US',
    city: 'Galena',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stone',
    latitude: 36.8198,
    longitude: -93.4811,
    postal_code: '65656'
  },
  '65657': {
    country: 'US',
    city: 'Garrison',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 36.8389,
    longitude: -93.0196,
    postal_code: '65657'
  },
  '65658': {
    country: 'US',
    city: 'Golden',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.5625,
    longitude: -93.623,
    postal_code: '65658'
  },
  '65660': {
    country: 'US',
    city: 'Graff',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wright',
    latitude: 37.3262,
    longitude: -92.2647,
    postal_code: '65660'
  },
  '65661': {
    country: 'US',
    city: 'Greenfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dade',
    latitude: 37.4197,
    longitude: -93.8407,
    postal_code: '65661'
  },
  '65662': {
    country: 'US',
    city: 'Grovespring',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wright',
    latitude: 37.4947,
    longitude: -92.6001,
    postal_code: '65662'
  },
  '65663': {
    country: 'US',
    city: 'Half Way',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.6018,
    longitude: -93.242,
    postal_code: '65663'
  },
  '65664': {
    country: 'US',
    city: 'Halltown',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lawrence',
    latitude: 37.1944,
    longitude: -93.6275,
    postal_code: '65664'
  },
  '65666': {
    country: 'US',
    city: 'Hardenville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.5892,
    longitude: -92.3713,
    postal_code: '65666'
  },
  '65667': {
    country: 'US',
    city: 'Hartville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wright',
    latitude: 37.2735,
    longitude: -92.5181,
    postal_code: '65667'
  },
  '65668': {
    country: 'US',
    city: 'Hermitage',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Hickory',
    latitude: 37.8969,
    longitude: -93.2979,
    postal_code: '65668'
  },
  '65669': {
    country: 'US',
    city: 'Highlandville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 36.9408,
    longitude: -93.268,
    postal_code: '65669'
  },
  '65672': {
    country: 'US',
    city: 'Hollister',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.6107,
    longitude: -93.2286,
    postal_code: '65672'
  },
  '65673': {
    country: 'US',
    city: 'Hollister',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.6179,
    longitude: -93.2162,
    postal_code: '65673'
  },
  '65674': {
    country: 'US',
    city: 'Humansville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.7923,
    longitude: -93.5795,
    postal_code: '65674'
  },
  '65675': {
    country: 'US',
    city: 'Hurley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stone',
    latitude: 36.9305,
    longitude: -93.4965,
    postal_code: '65675'
  },
  '65676': {
    country: 'US',
    city: 'Isabella',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.5749,
    longitude: -92.6053,
    postal_code: '65676'
  },
  '65679': {
    country: 'US',
    city: 'Kirbyville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.5792,
    longitude: -93.1263,
    postal_code: '65679'
  },
  '65680': {
    country: 'US',
    city: 'Kissee Mills',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.6704,
    longitude: -93.0377,
    postal_code: '65680'
  },
  '65681': {
    country: 'US',
    city: 'Lampe',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stone',
    latitude: 36.5767,
    longitude: -93.4516,
    postal_code: '65681'
  },
  '65682': {
    country: 'US',
    city: 'Lockwood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dade',
    latitude: 37.386,
    longitude: -93.9541,
    postal_code: '65682'
  },
  '65685': {
    country: 'US',
    city: 'Louisburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dallas',
    latitude: 37.7542,
    longitude: -93.1566,
    postal_code: '65685'
  },
  '65686': {
    country: 'US',
    city: 'Kimberling City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stone',
    latitude: 36.6393,
    longitude: -93.4372,
    postal_code: '65686'
  },
  '65688': {
    country: 'US',
    city: 'Brandsville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howell',
    latitude: 36.6488,
    longitude: -91.6976,
    postal_code: '65688'
  },
  '65689': {
    country: 'US',
    city: 'Cabool',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Texas',
    latitude: 37.1314,
    longitude: -92.1144,
    postal_code: '65689'
  },
  '65690': {
    country: 'US',
    city: 'Couch',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Oregon',
    latitude: 36.5853,
    longitude: -91.2724,
    postal_code: '65690'
  },
  '65692': {
    country: 'US',
    city: 'Koshkonong',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Oregon',
    latitude: 36.6056,
    longitude: -91.6304,
    postal_code: '65692'
  },
  '65702': {
    country: 'US',
    city: 'Macomb',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wright',
    latitude: 37.1049,
    longitude: -92.4821,
    postal_code: '65702'
  },
  '65704': {
    country: 'US',
    city: 'Mansfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wright',
    latitude: 37.1273,
    longitude: -92.5936,
    postal_code: '65704'
  },
  '65705': {
    country: 'US',
    city: 'Marionville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lawrence',
    latitude: 37.0009,
    longitude: -93.6413,
    postal_code: '65705'
  },
  '65706': {
    country: 'US',
    city: 'Marshfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Webster',
    latitude: 37.3312,
    longitude: -92.925,
    postal_code: '65706'
  },
  '65707': {
    country: 'US',
    city: 'Miller',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lawrence',
    latitude: 37.2225,
    longitude: -93.8422,
    postal_code: '65707'
  },
  '65708': {
    country: 'US',
    city: 'Monett',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.9212,
    longitude: -93.9258,
    postal_code: '65708'
  },
  '65710': {
    country: 'US',
    city: 'Morrisville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.4686,
    longitude: -93.4275,
    postal_code: '65710'
  },
  '65711': {
    country: 'US',
    city: 'Mountain Grove',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wright',
    latitude: 37.1626,
    longitude: -92.2839,
    postal_code: '65711'
  },
  '65712': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lawrence',
    latitude: 37.1045,
    longitude: -93.7976,
    postal_code: '65712'
  },
  '65713': {
    country: 'US',
    city: 'Niangua',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Webster',
    latitude: 37.3985,
    longitude: -92.7763,
    postal_code: '65713'
  },
  '65714': {
    country: 'US',
    city: 'Nixa',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 37.0512,
    longitude: -93.2972,
    postal_code: '65714'
  },
  '65715': {
    country: 'US',
    city: 'Noble',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.7441,
    longitude: -92.5769,
    postal_code: '65715'
  },
  '65717': {
    country: 'US',
    city: 'Norwood',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Wright',
    latitude: 37.0687,
    longitude: -92.4082,
    postal_code: '65717'
  },
  '65720': {
    country: 'US',
    city: 'Oldfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 36.9404,
    longitude: -92.9528,
    postal_code: '65720'
  },
  '65721': {
    country: 'US',
    city: 'Ozark',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 37.0169,
    longitude: -93.2022,
    postal_code: '65721'
  },
  '65722': {
    country: 'US',
    city: 'Phillipsburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Laclede',
    latitude: 37.5832,
    longitude: -92.7416,
    postal_code: '65722'
  },
  '65723': {
    country: 'US',
    city: 'Pierce City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lawrence',
    latitude: 36.973,
    longitude: -94.0024,
    postal_code: '65723'
  },
  '65724': {
    country: 'US',
    city: 'Pittsburg',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Hickory',
    latitude: 37.8442,
    longitude: -93.3356,
    postal_code: '65724'
  },
  '65725': {
    country: 'US',
    city: 'Pleasant Hope',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.4615,
    longitude: -93.2617,
    postal_code: '65725'
  },
  '65726': {
    country: 'US',
    city: 'Point Lookout',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.6165,
    longitude: -93.2418,
    postal_code: '65726'
  },
  '65727': {
    country: 'US',
    city: 'Polk',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Polk',
    latitude: 37.7292,
    longitude: -93.2994,
    postal_code: '65727'
  },
  '65728': {
    country: 'US',
    city: 'Ponce De Leon',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stone',
    latitude: 36.8759,
    longitude: -93.3516,
    postal_code: '65728'
  },
  '65729': {
    country: 'US',
    city: 'Pontiac',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.5156,
    longitude: -92.6038,
    postal_code: '65729'
  },
  '65730': {
    country: 'US',
    city: 'Powell',
    state: 'Missouri',
    state_short: 'MO',
    county: 'McDonald',
    latitude: 36.6237,
    longitude: -94.1773,
    postal_code: '65730'
  },
  '65731': {
    country: 'US',
    city: 'Powersite',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.6578,
    longitude: -93.1243,
    postal_code: '65731'
  },
  '65732': {
    country: 'US',
    city: 'Preston',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Hickory',
    latitude: 37.939,
    longitude: -93.1713,
    postal_code: '65732'
  },
  '65733': {
    country: 'US',
    city: 'Protem',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.5286,
    longitude: -92.8465,
    postal_code: '65733'
  },
  '65734': {
    country: 'US',
    city: 'Purdy',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.8069,
    longitude: -93.9164,
    postal_code: '65734'
  },
  '65735': {
    country: 'US',
    city: 'Quincy',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Hickory',
    latitude: 38.0084,
    longitude: -93.4724,
    postal_code: '65735'
  },
  '65737': {
    country: 'US',
    city: 'Reeds Spring',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Stone',
    latitude: 36.6907,
    longitude: -93.3447,
    postal_code: '65737'
  },
  '65738': {
    country: 'US',
    city: 'Republic',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.123,
    longitude: -93.48,
    postal_code: '65738'
  },
  '65739': {
    country: 'US',
    city: 'Ridgedale',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.5247,
    longitude: -93.2778,
    postal_code: '65739'
  },
  '65740': {
    country: 'US',
    city: 'Rockaway Beach',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.7137,
    longitude: -93.1715,
    postal_code: '65740'
  },
  '65741': {
    country: 'US',
    city: 'Rockbridge',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.7895,
    longitude: -92.4091,
    postal_code: '65741'
  },
  '65742': {
    country: 'US',
    city: 'Rogersville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Webster',
    latitude: 37.131,
    longitude: -93.0964,
    postal_code: '65742'
  },
  '65744': {
    country: 'US',
    city: 'Rueter',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.6314,
    longitude: -92.9188,
    postal_code: '65744'
  },
  '65745': {
    country: 'US',
    city: 'Seligman',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.5276,
    longitude: -93.9359,
    postal_code: '65745'
  },
  '65746': {
    country: 'US',
    city: 'Seymour',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Webster',
    latitude: 37.1667,
    longitude: -92.7857,
    postal_code: '65746'
  },
  '65747': {
    country: 'US',
    city: 'Shell Knob',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.6167,
    longitude: -93.6249,
    postal_code: '65747'
  },
  '65752': {
    country: 'US',
    city: 'South Greenfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dade',
    latitude: 37.3743,
    longitude: -93.8445,
    postal_code: '65752'
  },
  '65753': {
    country: 'US',
    city: 'Sparta',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 36.9775,
    longitude: -93.1065,
    postal_code: '65753'
  },
  '65754': {
    country: 'US',
    city: 'Spokane',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Christian',
    latitude: 36.8636,
    longitude: -93.2754,
    postal_code: '65754'
  },
  '65755': {
    country: 'US',
    city: 'Squires',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Douglas',
    latitude: 36.8504,
    longitude: -92.5844,
    postal_code: '65755'
  },
  '65756': {
    country: 'US',
    city: 'Stotts City',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lawrence',
    latitude: 37.1031,
    longitude: -93.9543,
    postal_code: '65756'
  },
  '65757': {
    country: 'US',
    city: 'Strafford',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2797,
    longitude: -93.1066,
    postal_code: '65757'
  },
  '65759': {
    country: 'US',
    city: 'Taneyville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.7405,
    longitude: -93.028,
    postal_code: '65759'
  },
  '65760': {
    country: 'US',
    city: 'Tecumseh',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.5875,
    longitude: -92.2598,
    postal_code: '65760'
  },
  '65761': {
    country: 'US',
    city: 'Theodosia',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.583,
    longitude: -92.6628,
    postal_code: '65761'
  },
  '65762': {
    country: 'US',
    city: 'Thornfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.6875,
    longitude: -92.6534,
    postal_code: '65762'
  },
  '65764': {
    country: 'US',
    city: 'Tunas',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dallas',
    latitude: 37.8392,
    longitude: -92.9808,
    postal_code: '65764'
  },
  '65765': {
    country: 'US',
    city: 'Turners',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.1809,
    longitude: -93.1555,
    postal_code: '65765'
  },
  '65766': {
    country: 'US',
    city: 'Udall',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.544,
    longitude: -92.2588,
    postal_code: '65766'
  },
  '65767': {
    country: 'US',
    city: 'Urbana',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dallas',
    latitude: 37.8523,
    longitude: -93.151,
    postal_code: '65767'
  },
  '65768': {
    country: 'US',
    city: 'Vanzant',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Douglas',
    latitude: 36.9698,
    longitude: -92.3029,
    postal_code: '65768'
  },
  '65769': {
    country: 'US',
    city: 'Verona',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Lawrence',
    latitude: 36.937,
    longitude: -93.8005,
    postal_code: '65769'
  },
  '65770': {
    country: 'US',
    city: 'Walnut Grove',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.3943,
    longitude: -93.5044,
    postal_code: '65770'
  },
  '65771': {
    country: 'US',
    city: 'Walnut Shade',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Taney',
    latitude: 36.7704,
    longitude: -93.2148,
    postal_code: '65771'
  },
  '65772': {
    country: 'US',
    city: 'Washburn',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Barry',
    latitude: 36.5807,
    longitude: -93.992,
    postal_code: '65772'
  },
  '65773': {
    country: 'US',
    city: 'Wasola',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.7573,
    longitude: -92.5093,
    postal_code: '65773'
  },
  '65774': {
    country: 'US',
    city: 'Weaubleau',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Hickory',
    latitude: 37.8906,
    longitude: -93.5419,
    postal_code: '65774'
  },
  '65775': {
    country: 'US',
    city: 'West Plains',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howell',
    latitude: 36.7284,
    longitude: -91.8717,
    postal_code: '65775'
  },
  '65777': {
    country: 'US',
    city: 'Moody',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howell',
    latitude: 36.533,
    longitude: -91.9898,
    postal_code: '65777'
  },
  '65778': {
    country: 'US',
    city: 'Myrtle',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Oregon',
    latitude: 36.5217,
    longitude: -91.2706,
    postal_code: '65778'
  },
  '65779': {
    country: 'US',
    city: 'Wheatland',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Hickory',
    latitude: 37.9103,
    longitude: -93.3981,
    postal_code: '65779'
  },
  '65781': {
    country: 'US',
    city: 'Willard',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2962,
    longitude: -93.4259,
    postal_code: '65781'
  },
  '65783': {
    country: 'US',
    city: 'Windyville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Dallas',
    latitude: 37.7182,
    longitude: -92.9379,
    postal_code: '65783'
  },
  '65784': {
    country: 'US',
    city: 'Zanoni',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Ozark',
    latitude: 36.6862,
    longitude: -92.3318,
    postal_code: '65784'
  },
  '65785': {
    country: 'US',
    city: 'Stockton',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Cedar',
    latitude: 37.7241,
    longitude: -93.796,
    postal_code: '65785'
  },
  '65786': {
    country: 'US',
    city: 'Macks Creek',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Camden',
    latitude: 37.9616,
    longitude: -92.9603,
    postal_code: '65786'
  },
  '65787': {
    country: 'US',
    city: 'Roach',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Camden',
    latitude: 38.1025,
    longitude: -92.9148,
    postal_code: '65787'
  },
  '65788': {
    country: 'US',
    city: 'Peace Valley',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howell',
    latitude: 36.8091,
    longitude: -91.6938,
    postal_code: '65788'
  },
  '65789': {
    country: 'US',
    city: 'Pomona',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howell',
    latitude: 36.8441,
    longitude: -91.9137,
    postal_code: '65789'
  },
  '65790': {
    country: 'US',
    city: 'Pottersville',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howell',
    latitude: 36.7068,
    longitude: -92.044,
    postal_code: '65790'
  },
  '65791': {
    country: 'US',
    city: 'Thayer',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Oregon',
    latitude: 36.5327,
    longitude: -91.5418,
    postal_code: '65791'
  },
  '65793': {
    country: 'US',
    city: 'Willow Springs',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Howell',
    latitude: 36.9958,
    longitude: -91.9405,
    postal_code: '65793'
  },
  '65801': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2581,
    longitude: -93.3437,
    postal_code: '65801'
  },
  '65802': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2117,
    longitude: -93.299,
    postal_code: '65802'
  },
  '65803': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2593,
    longitude: -93.2912,
    postal_code: '65803'
  },
  '65804': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.1654,
    longitude: -93.2522,
    postal_code: '65804'
  },
  '65805': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2581,
    longitude: -93.3437,
    postal_code: '65805'
  },
  '65806': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2031,
    longitude: -93.2971,
    postal_code: '65806'
  },
  '65807': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.1668,
    longitude: -93.3085,
    postal_code: '65807'
  },
  '65808': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2581,
    longitude: -93.3437,
    postal_code: '65808'
  },
  '65809': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.1852,
    longitude: -93.2057,
    postal_code: '65809'
  },
  '65810': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.1136,
    longitude: -93.2896,
    postal_code: '65810'
  },
  '65814': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2581,
    longitude: -93.3437,
    postal_code: '65814'
  },
  '65817': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2581,
    longitude: -93.3437,
    postal_code: '65817'
  },
  '65890': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2581,
    longitude: -93.3437,
    postal_code: '65890'
  },
  '65897': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.1987,
    longitude: -93.2784,
    postal_code: '65897'
  },
  '65898': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.2153,
    longitude: -93.2982,
    postal_code: '65898'
  },
  '65899': {
    country: 'US',
    city: 'Springfield',
    state: 'Missouri',
    state_short: 'MO',
    county: 'Greene',
    latitude: 37.1815,
    longitude: -93.2596,
    postal_code: '65899'
  },
  '66002': {
    country: 'US',
    city: 'Atchison',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Atchison',
    latitude: 39.5594,
    longitude: -95.1304,
    postal_code: '66002'
  },
  '66006': {
    country: 'US',
    city: 'Baldwin City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Douglas',
    latitude: 38.7953,
    longitude: -95.2276,
    postal_code: '66006'
  },
  '66007': {
    country: 'US',
    city: 'Basehor',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Leavenworth',
    latitude: 39.1281,
    longitude: -94.957,
    postal_code: '66007'
  },
  '66008': {
    country: 'US',
    city: 'Bendena',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Doniphan',
    latitude: 39.7174,
    longitude: -95.1765,
    postal_code: '66008'
  },
  '66010': {
    country: 'US',
    city: 'Blue Mound',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Linn',
    latitude: 38.0908,
    longitude: -95.0053,
    postal_code: '66010'
  },
  '66012': {
    country: 'US',
    city: 'Bonner Springs',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.0672,
    longitude: -94.9227,
    postal_code: '66012'
  },
  '66013': {
    country: 'US',
    city: 'Bucyrus',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Miami',
    latitude: 38.7283,
    longitude: -94.6887,
    postal_code: '66013'
  },
  '66014': {
    country: 'US',
    city: 'Centerville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Linn',
    latitude: 38.213,
    longitude: -94.9934,
    postal_code: '66014'
  },
  '66015': {
    country: 'US',
    city: 'Colony',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Anderson',
    latitude: 38.0761,
    longitude: -95.3287,
    postal_code: '66015'
  },
  '66016': {
    country: 'US',
    city: 'Cummings',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Atchison',
    latitude: 39.483,
    longitude: -95.2863,
    postal_code: '66016'
  },
  '66017': {
    country: 'US',
    city: 'Denton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Doniphan',
    latitude: 39.7154,
    longitude: -95.2744,
    postal_code: '66017'
  },
  '66018': {
    country: 'US',
    city: 'De Soto',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9462,
    longitude: -94.9714,
    postal_code: '66018'
  },
  '66020': {
    country: 'US',
    city: 'Easton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Leavenworth',
    latitude: 39.3391,
    longitude: -95.0999,
    postal_code: '66020'
  },
  '66021': {
    country: 'US',
    city: 'Edgerton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.7811,
    longitude: -95.0094,
    postal_code: '66021'
  },
  '66023': {
    country: 'US',
    city: 'Effingham',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Atchison',
    latitude: 39.5222,
    longitude: -95.4008,
    postal_code: '66023'
  },
  '66024': {
    country: 'US',
    city: 'Elwood',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Doniphan',
    latitude: 39.7573,
    longitude: -94.8824,
    postal_code: '66024'
  },
  '66025': {
    country: 'US',
    city: 'Eudora',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Douglas',
    latitude: 38.933,
    longitude: -95.1022,
    postal_code: '66025'
  },
  '66026': {
    country: 'US',
    city: 'Fontana',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Miami',
    latitude: 38.4102,
    longitude: -94.8718,
    postal_code: '66026'
  },
  '66027': {
    country: 'US',
    city: 'Fort Leavenworth',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Leavenworth',
    latitude: 39.3485,
    longitude: -94.9265,
    postal_code: '66027'
  },
  '66030': {
    country: 'US',
    city: 'Gardner',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8075,
    longitude: -94.9157,
    postal_code: '66030'
  },
  '66031': {
    country: 'US',
    city: 'New Century',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8249,
    longitude: -94.8992,
    postal_code: '66031'
  },
  '66032': {
    country: 'US',
    city: 'Garnett',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Anderson',
    latitude: 38.2859,
    longitude: -95.2594,
    postal_code: '66032'
  },
  '66033': {
    country: 'US',
    city: 'Greeley',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Anderson',
    latitude: 38.3524,
    longitude: -95.1188,
    postal_code: '66033'
  },
  '66035': {
    country: 'US',
    city: 'Highland',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Doniphan',
    latitude: 39.8608,
    longitude: -95.2583,
    postal_code: '66035'
  },
  '66036': {
    country: 'US',
    city: 'Hillsdale',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Miami',
    latitude: 38.6581,
    longitude: -94.8521,
    postal_code: '66036'
  },
  '66039': {
    country: 'US',
    city: 'Kincaid',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Anderson',
    latitude: 38.1104,
    longitude: -95.1886,
    postal_code: '66039'
  },
  '66040': {
    country: 'US',
    city: 'Lacygne',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Linn',
    latitude: 39.0478,
    longitude: -95.1538,
    postal_code: '66040'
  },
  '66041': {
    country: 'US',
    city: 'Lancaster',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Atchison',
    latitude: 39.5952,
    longitude: -95.3036,
    postal_code: '66041'
  },
  '66042': {
    country: 'US',
    city: 'Lane',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Franklin',
    latitude: 38.4347,
    longitude: -95.0804,
    postal_code: '66042'
  },
  '66043': {
    country: 'US',
    city: 'Lansing',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Leavenworth',
    latitude: 39.2502,
    longitude: -94.8994,
    postal_code: '66043'
  },
  '66044': {
    country: 'US',
    city: 'Lawrence',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Douglas',
    latitude: 39.0289,
    longitude: -95.2086,
    postal_code: '66044'
  },
  '66045': {
    country: 'US',
    city: 'Lawrence',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Douglas',
    latitude: 38.959,
    longitude: -95.2499,
    postal_code: '66045'
  },
  '66046': {
    country: 'US',
    city: 'Lawrence',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Douglas',
    latitude: 38.9369,
    longitude: -95.242,
    postal_code: '66046'
  },
  '66047': {
    country: 'US',
    city: 'Lawrence',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Douglas',
    latitude: 38.9407,
    longitude: -95.2779,
    postal_code: '66047'
  },
  '66048': {
    country: 'US',
    city: 'Leavenworth',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Leavenworth',
    latitude: 39.3015,
    longitude: -94.9339,
    postal_code: '66048'
  },
  '66049': {
    country: 'US',
    city: 'Lawrence',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Douglas',
    latitude: 38.9704,
    longitude: -95.2769,
    postal_code: '66049'
  },
  '66050': {
    country: 'US',
    city: 'Lecompton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Douglas',
    latitude: 39.0154,
    longitude: -95.4392,
    postal_code: '66050'
  },
  '66051': {
    country: 'US',
    city: 'Olathe',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8999,
    longitude: -94.832,
    postal_code: '66051'
  },
  '66052': {
    country: 'US',
    city: 'Linwood',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Leavenworth',
    latitude: 39.0014,
    longitude: -95.0391,
    postal_code: '66052'
  },
  '66053': {
    country: 'US',
    city: 'Louisburg',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Miami',
    latitude: 38.6073,
    longitude: -94.6829,
    postal_code: '66053'
  },
  '66054': {
    country: 'US',
    city: 'Mc Louth',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jefferson',
    latitude: 39.1668,
    longitude: -95.2183,
    postal_code: '66054'
  },
  '66056': {
    country: 'US',
    city: 'Mound City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Linn',
    latitude: 38.156,
    longitude: -94.8186,
    postal_code: '66056'
  },
  '66058': {
    country: 'US',
    city: 'Muscotah',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Atchison',
    latitude: 39.5388,
    longitude: -95.5175,
    postal_code: '66058'
  },
  '66060': {
    country: 'US',
    city: 'Nortonville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jefferson',
    latitude: 39.4095,
    longitude: -95.3236,
    postal_code: '66060'
  },
  '66061': {
    country: 'US',
    city: 'Olathe',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8865,
    longitude: -94.8204,
    postal_code: '66061'
  },
  '66062': {
    country: 'US',
    city: 'Olathe',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8733,
    longitude: -94.7752,
    postal_code: '66062'
  },
  '66063': {
    country: 'US',
    city: 'Olathe',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8999,
    longitude: -94.832,
    postal_code: '66063'
  },
  '66064': {
    country: 'US',
    city: 'Osawatomie',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Miami',
    latitude: 38.4888,
    longitude: -94.962,
    postal_code: '66064'
  },
  '66066': {
    country: 'US',
    city: 'Oskaloosa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jefferson',
    latitude: 39.2152,
    longitude: -95.3135,
    postal_code: '66066'
  },
  '66067': {
    country: 'US',
    city: 'Ottawa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Franklin',
    latitude: 38.6142,
    longitude: -95.2745,
    postal_code: '66067'
  },
  '66070': {
    country: 'US',
    city: 'Ozawkie',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jefferson',
    latitude: 39.2136,
    longitude: -95.4404,
    postal_code: '66070'
  },
  '66071': {
    country: 'US',
    city: 'Paola',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Miami',
    latitude: 38.572,
    longitude: -94.8937,
    postal_code: '66071'
  },
  '66072': {
    country: 'US',
    city: 'Parker',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Linn',
    latitude: 38.3303,
    longitude: -94.9871,
    postal_code: '66072'
  },
  '66073': {
    country: 'US',
    city: 'Perry',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jefferson',
    latitude: 39.0875,
    longitude: -95.3731,
    postal_code: '66073'
  },
  '66075': {
    country: 'US',
    city: 'Pleasanton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Linn',
    latitude: 38.1823,
    longitude: -94.7057,
    postal_code: '66075'
  },
  '66076': {
    country: 'US',
    city: 'Pomona',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Franklin',
    latitude: 38.6153,
    longitude: -95.4489,
    postal_code: '66076'
  },
  '66078': {
    country: 'US',
    city: 'Princeton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Franklin',
    latitude: 38.4847,
    longitude: -95.2764,
    postal_code: '66078'
  },
  '66079': {
    country: 'US',
    city: 'Rantoul',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Franklin',
    latitude: 38.5666,
    longitude: -95.1234,
    postal_code: '66079'
  },
  '66080': {
    country: 'US',
    city: 'Richmond',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Franklin',
    latitude: 38.4074,
    longitude: -95.2489,
    postal_code: '66080'
  },
  '66083': {
    country: 'US',
    city: 'Spring Hill',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.7631,
    longitude: -94.8246,
    postal_code: '66083'
  },
  '66085': {
    country: 'US',
    city: 'Stilwell',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.7902,
    longitude: -94.6643,
    postal_code: '66085'
  },
  '66086': {
    country: 'US',
    city: 'Tonganoxie',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Leavenworth',
    latitude: 39.1026,
    longitude: -95.1051,
    postal_code: '66086'
  },
  '66087': {
    country: 'US',
    city: 'Troy',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Doniphan',
    latitude: 39.7906,
    longitude: -95.1434,
    postal_code: '66087'
  },
  '66088': {
    country: 'US',
    city: 'Valley Falls',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jefferson',
    latitude: 39.3484,
    longitude: -95.467,
    postal_code: '66088'
  },
  '66090': {
    country: 'US',
    city: 'Wathena',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Doniphan',
    latitude: 39.7625,
    longitude: -94.9255,
    postal_code: '66090'
  },
  '66091': {
    country: 'US',
    city: 'Welda',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Anderson',
    latitude: 38.1686,
    longitude: -95.3096,
    postal_code: '66091'
  },
  '66092': {
    country: 'US',
    city: 'Wellsville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Franklin',
    latitude: 38.7137,
    longitude: -95.0916,
    postal_code: '66092'
  },
  '66093': {
    country: 'US',
    city: 'Westphalia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Anderson',
    latitude: 38.1718,
    longitude: -95.4665,
    postal_code: '66093'
  },
  '66094': {
    country: 'US',
    city: 'White Cloud',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Doniphan',
    latitude: 39.9627,
    longitude: -95.2982,
    postal_code: '66094'
  },
  '66095': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Franklin',
    latitude: 38.4902,
    longitude: -95.4226,
    postal_code: '66095'
  },
  '66097': {
    country: 'US',
    city: 'Winchester',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jefferson',
    latitude: 39.3245,
    longitude: -95.2696,
    postal_code: '66097'
  },
  '66101': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.1157,
    longitude: -94.6271,
    postal_code: '66101'
  },
  '66102': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.1132,
    longitude: -94.6693,
    postal_code: '66102'
  },
  '66103': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.0668,
    longitude: -94.6282,
    postal_code: '66103'
  },
  '66104': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.1375,
    longitude: -94.6792,
    postal_code: '66104'
  },
  '66105': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.085,
    longitude: -94.6356,
    postal_code: '66105'
  },
  '66106': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.0694,
    longitude: -94.7178,
    postal_code: '66106'
  },
  '66109': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.1434,
    longitude: -94.7856,
    postal_code: '66109'
  },
  '66110': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.0966,
    longitude: -94.7495,
    postal_code: '66110'
  },
  '66111': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.0803,
    longitude: -94.7806,
    postal_code: '66111'
  },
  '66112': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.116,
    longitude: -94.764,
    postal_code: '66112'
  },
  '66113': {
    country: 'US',
    city: 'Edwardsville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.0735,
    longitude: -94.7233,
    postal_code: '66113'
  },
  '66115': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.1364,
    longitude: -94.616,
    postal_code: '66115'
  },
  '66117': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.1142,
    longitude: -94.6275,
    postal_code: '66117'
  },
  '66118': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.1011,
    longitude: -94.6144,
    postal_code: '66118'
  },
  '66119': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.0966,
    longitude: -94.7495,
    postal_code: '66119'
  },
  '66160': {
    country: 'US',
    city: 'Kansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wyandotte',
    latitude: 39.0966,
    longitude: -94.7495,
    postal_code: '66160'
  },
  '66201': {
    country: 'US',
    city: 'Mission',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 39.0278,
    longitude: -94.6558,
    postal_code: '66201'
  },
  '66202': {
    country: 'US',
    city: 'Mission',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 39.0278,
    longitude: -94.6558,
    postal_code: '66202'
  },
  '66203': {
    country: 'US',
    city: 'Shawnee',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 39.0417,
    longitude: -94.7202,
    postal_code: '66203'
  },
  '66204': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9928,
    longitude: -94.6771,
    postal_code: '66204'
  },
  '66205': {
    country: 'US',
    city: 'Mission',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 39.0278,
    longitude: -94.6558,
    postal_code: '66205'
  },
  '66206': {
    country: 'US',
    city: 'Leawood',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9667,
    longitude: -94.6169,
    postal_code: '66206'
  },
  '66207': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9575,
    longitude: -94.6452,
    postal_code: '66207'
  },
  '66208': {
    country: 'US',
    city: 'Prairie Village',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9917,
    longitude: -94.6336,
    postal_code: '66208'
  },
  '66209': {
    country: 'US',
    city: 'Leawood',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9667,
    longitude: -94.6169,
    postal_code: '66209'
  },
  '66210': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9273,
    longitude: -94.7143,
    postal_code: '66210'
  },
  '66211': {
    country: 'US',
    city: 'Leawood',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9667,
    longitude: -94.6169,
    postal_code: '66211'
  },
  '66212': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9568,
    longitude: -94.6832,
    postal_code: '66212'
  },
  '66213': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8982,
    longitude: -94.7049,
    postal_code: '66213'
  },
  '66214': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9649,
    longitude: -94.7209,
    postal_code: '66214'
  },
  '66215': {
    country: 'US',
    city: 'Lenexa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9536,
    longitude: -94.7336,
    postal_code: '66215'
  },
  '66216': {
    country: 'US',
    city: 'Shawnee',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 39.0417,
    longitude: -94.7202,
    postal_code: '66216'
  },
  '66217': {
    country: 'US',
    city: 'Shawnee',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 39.0417,
    longitude: -94.7202,
    postal_code: '66217'
  },
  '66218': {
    country: 'US',
    city: 'Shawnee',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 39.0417,
    longitude: -94.7202,
    postal_code: '66218'
  },
  '66219': {
    country: 'US',
    city: 'Lenexa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9536,
    longitude: -94.7336,
    postal_code: '66219'
  },
  '66220': {
    country: 'US',
    city: 'Lenexa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9536,
    longitude: -94.7336,
    postal_code: '66220'
  },
  '66221': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8636,
    longitude: -94.7103,
    postal_code: '66221'
  },
  '66222': {
    country: 'US',
    city: 'Mission',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 39.0278,
    longitude: -94.6558,
    postal_code: '66222'
  },
  '66223': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8619,
    longitude: -94.661,
    postal_code: '66223'
  },
  '66224': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8591,
    longitude: -94.6314,
    postal_code: '66224'
  },
  '66225': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8999,
    longitude: -94.832,
    postal_code: '66225'
  },
  '66226': {
    country: 'US',
    city: 'Shawnee',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 39.0417,
    longitude: -94.7202,
    postal_code: '66226'
  },
  '66227': {
    country: 'US',
    city: 'Lenexa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9536,
    longitude: -94.7336,
    postal_code: '66227'
  },
  '66250': {
    country: 'US',
    city: 'Lenexa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9536,
    longitude: -94.7336,
    postal_code: '66250'
  },
  '66251': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8999,
    longitude: -94.832,
    postal_code: '66251'
  },
  '66276': {
    country: 'US',
    city: 'Shawnee Mission',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8999,
    longitude: -94.832,
    postal_code: '66276'
  },
  '66282': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8999,
    longitude: -94.832,
    postal_code: '66282'
  },
  '66283': {
    country: 'US',
    city: 'Overland Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.8999,
    longitude: -94.832,
    postal_code: '66283'
  },
  '66285': {
    country: 'US',
    city: 'Lenexa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 38.9536,
    longitude: -94.7336,
    postal_code: '66285'
  },
  '66286': {
    country: 'US',
    city: 'Shawnee',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Johnson',
    latitude: 39.0417,
    longitude: -94.7202,
    postal_code: '66286'
  },
  '66401': {
    country: 'US',
    city: 'Alma',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wabaunsee',
    latitude: 39.0092,
    longitude: -96.2923,
    postal_code: '66401'
  },
  '66402': {
    country: 'US',
    city: 'Auburn',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 38.9167,
    longitude: -95.8199,
    postal_code: '66402'
  },
  '66403': {
    country: 'US',
    city: 'Axtell',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.8707,
    longitude: -96.2676,
    postal_code: '66403'
  },
  '66404': {
    country: 'US',
    city: 'Baileyville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Nemaha',
    latitude: 39.8816,
    longitude: -96.1801,
    postal_code: '66404'
  },
  '66406': {
    country: 'US',
    city: 'Beattie',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.8988,
    longitude: -96.4286,
    postal_code: '66406'
  },
  '66407': {
    country: 'US',
    city: 'Belvue',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pottawatomie',
    latitude: 39.2273,
    longitude: -96.1866,
    postal_code: '66407'
  },
  '66408': {
    country: 'US',
    city: 'Bern',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Nemaha',
    latitude: 39.9571,
    longitude: -95.961,
    postal_code: '66408'
  },
  '66409': {
    country: 'US',
    city: 'Berryton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 38.9442,
    longitude: -95.5825,
    postal_code: '66409'
  },
  '66411': {
    country: 'US',
    city: 'Blue Rapids',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.675,
    longitude: -96.6359,
    postal_code: '66411'
  },
  '66412': {
    country: 'US',
    city: 'Bremen',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.8775,
    longitude: -96.7458,
    postal_code: '66412'
  },
  '66413': {
    country: 'US',
    city: 'Burlingame',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osage',
    latitude: 38.7634,
    longitude: -95.84,
    postal_code: '66413'
  },
  '66414': {
    country: 'US',
    city: 'Carbondale',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osage',
    latitude: 38.8206,
    longitude: -95.6871,
    postal_code: '66414'
  },
  '66415': {
    country: 'US',
    city: 'Centralia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Nemaha',
    latitude: 39.7381,
    longitude: -96.1486,
    postal_code: '66415'
  },
  '66416': {
    country: 'US',
    city: 'Circleville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jackson',
    latitude: 39.5155,
    longitude: -95.8517,
    postal_code: '66416'
  },
  '66417': {
    country: 'US',
    city: 'Corning',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Nemaha',
    latitude: 39.6572,
    longitude: -96.0294,
    postal_code: '66417'
  },
  '66418': {
    country: 'US',
    city: 'Delia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jackson',
    latitude: 39.2655,
    longitude: -95.9608,
    postal_code: '66418'
  },
  '66419': {
    country: 'US',
    city: 'Denison',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jackson',
    latitude: 39.354,
    longitude: -95.6127,
    postal_code: '66419'
  },
  '66420': {
    country: 'US',
    city: 'Dover',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 38.9645,
    longitude: -95.9172,
    postal_code: '66420'
  },
  '66422': {
    country: 'US',
    city: 'Emmett',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pottawatomie',
    latitude: 39.3048,
    longitude: -96.0593,
    postal_code: '66422'
  },
  '66423': {
    country: 'US',
    city: 'Eskridge',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wabaunsee',
    latitude: 38.8514,
    longitude: -96.1016,
    postal_code: '66423'
  },
  '66424': {
    country: 'US',
    city: 'Everest',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Brown',
    latitude: 39.688,
    longitude: -95.4138,
    postal_code: '66424'
  },
  '66425': {
    country: 'US',
    city: 'Fairview',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Brown',
    latitude: 39.8445,
    longitude: -95.711,
    postal_code: '66425'
  },
  '66426': {
    country: 'US',
    city: 'Fostoria',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pottawatomie',
    latitude: 39.4398,
    longitude: -96.5068,
    postal_code: '66426'
  },
  '66427': {
    country: 'US',
    city: 'Frankfort',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.7333,
    longitude: -96.5223,
    postal_code: '66427'
  },
  '66428': {
    country: 'US',
    city: 'Goff',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Nemaha',
    latitude: 39.6654,
    longitude: -95.9574,
    postal_code: '66428'
  },
  '66429': {
    country: 'US',
    city: 'Grantville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jefferson',
    latitude: 39.0972,
    longitude: -95.5397,
    postal_code: '66429'
  },
  '66431': {
    country: 'US',
    city: 'Harveyville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wabaunsee',
    latitude: 38.79,
    longitude: -95.9617,
    postal_code: '66431'
  },
  '66432': {
    country: 'US',
    city: 'Havensville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pottawatomie',
    latitude: 39.4942,
    longitude: -96.0769,
    postal_code: '66432'
  },
  '66434': {
    country: 'US',
    city: 'Hiawatha',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Brown',
    latitude: 39.7914,
    longitude: -95.6004,
    postal_code: '66434'
  },
  '66436': {
    country: 'US',
    city: 'Holton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jackson',
    latitude: 39.4436,
    longitude: -95.7525,
    postal_code: '66436'
  },
  '66438': {
    country: 'US',
    city: 'Home',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.8488,
    longitude: -96.5063,
    postal_code: '66438'
  },
  '66439': {
    country: 'US',
    city: 'Horton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Brown',
    latitude: 39.6789,
    longitude: -95.5299,
    postal_code: '66439'
  },
  '66440': {
    country: 'US',
    city: 'Hoyt',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jackson',
    latitude: 39.2552,
    longitude: -95.6867,
    postal_code: '66440'
  },
  '66441': {
    country: 'US',
    city: 'Junction City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Geary',
    latitude: 39.0299,
    longitude: -96.8396,
    postal_code: '66441'
  },
  '66442': {
    country: 'US',
    city: 'Fort Riley',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Geary',
    latitude: 39.0619,
    longitude: -96.7873,
    postal_code: '66442'
  },
  '66449': {
    country: 'US',
    city: 'Leonardville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Riley',
    latitude: 39.3644,
    longitude: -96.8589,
    postal_code: '66449'
  },
  '66451': {
    country: 'US',
    city: 'Lyndon',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osage',
    latitude: 38.6356,
    longitude: -95.6802,
    postal_code: '66451'
  },
  '66501': {
    country: 'US',
    city: 'Mc Farland',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wabaunsee',
    latitude: 39.0535,
    longitude: -96.2379,
    postal_code: '66501'
  },
  '66502': {
    country: 'US',
    city: 'Manhattan',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Riley',
    latitude: 39.1938,
    longitude: -96.5858,
    postal_code: '66502'
  },
  '66503': {
    country: 'US',
    city: 'Manhattan',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Riley',
    latitude: 39.2458,
    longitude: -96.6336,
    postal_code: '66503'
  },
  '66505': {
    country: 'US',
    city: 'Manhattan',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Riley',
    latitude: 39.3049,
    longitude: -96.6753,
    postal_code: '66505'
  },
  '66506': {
    country: 'US',
    city: 'Manhattan',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Riley',
    latitude: 39.196,
    longitude: -96.5839,
    postal_code: '66506'
  },
  '66507': {
    country: 'US',
    city: 'Maple Hill',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wabaunsee',
    latitude: 39.0447,
    longitude: -96.0397,
    postal_code: '66507'
  },
  '66508': {
    country: 'US',
    city: 'Marysville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.8428,
    longitude: -96.6422,
    postal_code: '66508'
  },
  '66509': {
    country: 'US',
    city: 'Mayetta',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jackson',
    latitude: 39.3489,
    longitude: -95.6928,
    postal_code: '66509'
  },
  '66510': {
    country: 'US',
    city: 'Melvern',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osage',
    latitude: 38.5027,
    longitude: -95.629,
    postal_code: '66510'
  },
  '66512': {
    country: 'US',
    city: 'Meriden',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jefferson',
    latitude: 39.2038,
    longitude: -95.5476,
    postal_code: '66512'
  },
  '66514': {
    country: 'US',
    city: 'Milford',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Geary',
    latitude: 39.1692,
    longitude: -96.91,
    postal_code: '66514'
  },
  '66515': {
    country: 'US',
    city: 'Morrill',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Brown',
    latitude: 39.9356,
    longitude: -95.712,
    postal_code: '66515'
  },
  '66516': {
    country: 'US',
    city: 'Netawaka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jackson',
    latitude: 39.6063,
    longitude: -95.727,
    postal_code: '66516'
  },
  '66517': {
    country: 'US',
    city: 'Ogden',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Riley',
    latitude: 39.1153,
    longitude: -96.7101,
    postal_code: '66517'
  },
  '66518': {
    country: 'US',
    city: 'Oketo',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.9598,
    longitude: -96.6235,
    postal_code: '66518'
  },
  '66520': {
    country: 'US',
    city: 'Olsburg',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pottawatomie',
    latitude: 39.4125,
    longitude: -96.6002,
    postal_code: '66520'
  },
  '66521': {
    country: 'US',
    city: 'Onaga',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pottawatomie',
    latitude: 39.4889,
    longitude: -96.17,
    postal_code: '66521'
  },
  '66522': {
    country: 'US',
    city: 'Oneida',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Nemaha',
    latitude: 39.8639,
    longitude: -95.9392,
    postal_code: '66522'
  },
  '66523': {
    country: 'US',
    city: 'Osage City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osage',
    latitude: 38.6269,
    longitude: -95.8303,
    postal_code: '66523'
  },
  '66524': {
    country: 'US',
    city: 'Overbrook',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osage',
    latitude: 38.7922,
    longitude: -95.5616,
    postal_code: '66524'
  },
  '66526': {
    country: 'US',
    city: 'Paxico',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wabaunsee',
    latitude: 39.0803,
    longitude: -96.1818,
    postal_code: '66526'
  },
  '66527': {
    country: 'US',
    city: 'Powhattan',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Brown',
    latitude: 39.7177,
    longitude: -95.6751,
    postal_code: '66527'
  },
  '66528': {
    country: 'US',
    city: 'Quenemo',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osage',
    latitude: 38.5785,
    longitude: -95.5362,
    postal_code: '66528'
  },
  '66531': {
    country: 'US',
    city: 'Riley',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Riley',
    latitude: 39.3005,
    longitude: -96.8222,
    postal_code: '66531'
  },
  '66532': {
    country: 'US',
    city: 'Robinson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Brown',
    latitude: 39.8182,
    longitude: -95.4567,
    postal_code: '66532'
  },
  '66533': {
    country: 'US',
    city: 'Rossville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.1451,
    longitude: -95.9553,
    postal_code: '66533'
  },
  '66534': {
    country: 'US',
    city: 'Sabetha',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Nemaha',
    latitude: 39.8993,
    longitude: -95.8113,
    postal_code: '66534'
  },
  '66535': {
    country: 'US',
    city: 'Saint George',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pottawatomie',
    latitude: 39.2108,
    longitude: -96.4345,
    postal_code: '66535'
  },
  '66536': {
    country: 'US',
    city: 'Saint Marys',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pottawatomie',
    latitude: 39.1987,
    longitude: -96.0683,
    postal_code: '66536'
  },
  '66537': {
    country: 'US',
    city: 'Scranton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osage',
    latitude: 38.788,
    longitude: -95.748,
    postal_code: '66537'
  },
  '66538': {
    country: 'US',
    city: 'Seneca',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Nemaha',
    latitude: 39.8473,
    longitude: -96.0316,
    postal_code: '66538'
  },
  '66539': {
    country: 'US',
    city: 'Silver Lake',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.1102,
    longitude: -95.8552,
    postal_code: '66539'
  },
  '66540': {
    country: 'US',
    city: 'Soldier',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jackson',
    latitude: 39.5013,
    longitude: -95.9652,
    postal_code: '66540'
  },
  '66541': {
    country: 'US',
    city: 'Summerfield',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.9798,
    longitude: -96.3279,
    postal_code: '66541'
  },
  '66542': {
    country: 'US',
    city: 'Tecumseh',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0217,
    longitude: -95.5379,
    postal_code: '66542'
  },
  '66543': {
    country: 'US',
    city: 'Vassar',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osage',
    latitude: 38.6419,
    longitude: -95.6012,
    postal_code: '66543'
  },
  '66544': {
    country: 'US',
    city: 'Vermillion',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.6946,
    longitude: -96.278,
    postal_code: '66544'
  },
  '66546': {
    country: 'US',
    city: 'Wakarusa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 38.9045,
    longitude: -95.7015,
    postal_code: '66546'
  },
  '66547': {
    country: 'US',
    city: 'Wamego',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pottawatomie',
    latitude: 39.21,
    longitude: -96.3153,
    postal_code: '66547'
  },
  '66548': {
    country: 'US',
    city: 'Waterville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marshall',
    latitude: 39.6971,
    longitude: -96.7498,
    postal_code: '66548'
  },
  '66549': {
    country: 'US',
    city: 'Westmoreland',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pottawatomie',
    latitude: 39.4138,
    longitude: -96.4374,
    postal_code: '66549'
  },
  '66550': {
    country: 'US',
    city: 'Wetmore',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Nemaha',
    latitude: 39.6428,
    longitude: -95.8231,
    postal_code: '66550'
  },
  '66552': {
    country: 'US',
    city: 'Whiting',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jackson',
    latitude: 39.5974,
    longitude: -95.6158,
    postal_code: '66552'
  },
  '66554': {
    country: 'US',
    city: 'Randolph',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Riley',
    latitude: 39.4879,
    longitude: -96.7828,
    postal_code: '66554'
  },
  '66601': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0483,
    longitude: -95.678,
    postal_code: '66601'
  },
  '66603': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0553,
    longitude: -95.6802,
    postal_code: '66603'
  },
  '66604': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0405,
    longitude: -95.7178,
    postal_code: '66604'
  },
  '66605': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0151,
    longitude: -95.6439,
    postal_code: '66605'
  },
  '66606': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0583,
    longitude: -95.7095,
    postal_code: '66606'
  },
  '66607': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0421,
    longitude: -95.6449,
    postal_code: '66607'
  },
  '66608': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0858,
    longitude: -95.6867,
    postal_code: '66608'
  },
  '66609': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 38.9919,
    longitude: -95.6681,
    postal_code: '66609'
  },
  '66610': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 38.9822,
    longitude: -95.7461,
    postal_code: '66610'
  },
  '66611': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0142,
    longitude: -95.6981,
    postal_code: '66611'
  },
  '66612': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0427,
    longitude: -95.6818,
    postal_code: '66612'
  },
  '66614': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0154,
    longitude: -95.7469,
    postal_code: '66614'
  },
  '66615': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0446,
    longitude: -95.7906,
    postal_code: '66615'
  },
  '66616': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0645,
    longitude: -95.6413,
    postal_code: '66616'
  },
  '66617': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.1271,
    longitude: -95.6384,
    postal_code: '66617'
  },
  '66618': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.1329,
    longitude: -95.7023,
    postal_code: '66618'
  },
  '66619': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 38.9536,
    longitude: -95.7236,
    postal_code: '66619'
  },
  '66620': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0429,
    longitude: -95.7697,
    postal_code: '66620'
  },
  '66621': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0333,
    longitude: -95.7015,
    postal_code: '66621'
  },
  '66622': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0429,
    longitude: -95.7697,
    postal_code: '66622'
  },
  '66624': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0429,
    longitude: -95.7697,
    postal_code: '66624'
  },
  '66625': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0483,
    longitude: -95.678,
    postal_code: '66625'
  },
  '66626': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0483,
    longitude: -95.678,
    postal_code: '66626'
  },
  '66629': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0483,
    longitude: -95.678,
    postal_code: '66629'
  },
  '66636': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0483,
    longitude: -95.678,
    postal_code: '66636'
  },
  '66647': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0429,
    longitude: -95.7697,
    postal_code: '66647'
  },
  '66667': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0429,
    longitude: -95.7697,
    postal_code: '66667'
  },
  '66675': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0429,
    longitude: -95.7697,
    postal_code: '66675'
  },
  '66683': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0483,
    longitude: -95.678,
    postal_code: '66683'
  },
  '66699': {
    country: 'US',
    city: 'Topeka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Shawnee',
    latitude: 39.0483,
    longitude: -95.678,
    postal_code: '66699'
  },
  '66701': {
    country: 'US',
    city: 'Fort Scott',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Bourbon',
    latitude: 37.8216,
    longitude: -94.7148,
    postal_code: '66701'
  },
  '66710': {
    country: 'US',
    city: 'Altoona',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wilson',
    latitude: 37.5197,
    longitude: -95.6483,
    postal_code: '66710'
  },
  '66711': {
    country: 'US',
    city: 'Arcadia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.6341,
    longitude: -94.6548,
    postal_code: '66711'
  },
  '66712': {
    country: 'US',
    city: 'Arma',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.5573,
    longitude: -94.6949,
    postal_code: '66712'
  },
  '66713': {
    country: 'US',
    city: 'Baxter Springs',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cherokee',
    latitude: 37.0281,
    longitude: -94.7393,
    postal_code: '66713'
  },
  '66714': {
    country: 'US',
    city: 'Benedict',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wilson',
    latitude: 37.6118,
    longitude: -95.7038,
    postal_code: '66714'
  },
  '66716': {
    country: 'US',
    city: 'Bronson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Bourbon',
    latitude: 37.9214,
    longitude: -95.0303,
    postal_code: '66716'
  },
  '66717': {
    country: 'US',
    city: 'Buffalo',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wilson',
    latitude: 37.7011,
    longitude: -95.7014,
    postal_code: '66717'
  },
  '66720': {
    country: 'US',
    city: 'Chanute',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Neosho',
    latitude: 37.6749,
    longitude: -95.457,
    postal_code: '66720'
  },
  '66724': {
    country: 'US',
    city: 'Cherokee',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.3694,
    longitude: -94.8424,
    postal_code: '66724'
  },
  '66725': {
    country: 'US',
    city: 'Columbus',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cherokee',
    latitude: 37.1699,
    longitude: -94.8899,
    postal_code: '66725'
  },
  '66728': {
    country: 'US',
    city: 'Crestline',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cherokee',
    latitude: 37.1694,
    longitude: -94.7041,
    postal_code: '66728'
  },
  '66732': {
    country: 'US',
    city: 'Elsmore',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Allen',
    latitude: 37.8035,
    longitude: -95.1546,
    postal_code: '66732'
  },
  '66733': {
    country: 'US',
    city: 'Erie',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Neosho',
    latitude: 37.6044,
    longitude: -95.2514,
    postal_code: '66733'
  },
  '66734': {
    country: 'US',
    city: 'Farlington',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.6163,
    longitude: -94.8479,
    postal_code: '66734'
  },
  '66735': {
    country: 'US',
    city: 'Franklin',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.5219,
    longitude: -94.7096,
    postal_code: '66735'
  },
  '66736': {
    country: 'US',
    city: 'Fredonia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wilson',
    latitude: 37.5717,
    longitude: -95.7484,
    postal_code: '66736'
  },
  '66738': {
    country: 'US',
    city: 'Fulton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Bourbon',
    latitude: 38.0134,
    longitude: -94.7183,
    postal_code: '66738'
  },
  '66739': {
    country: 'US',
    city: 'Galena',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cherokee',
    latitude: 37.0632,
    longitude: -94.6557,
    postal_code: '66739'
  },
  '66740': {
    country: 'US',
    city: 'Galesburg',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Neosho',
    latitude: 37.4724,
    longitude: -95.3707,
    postal_code: '66740'
  },
  '66741': {
    country: 'US',
    city: 'Garland',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Bourbon',
    latitude: 37.7175,
    longitude: -94.6613,
    postal_code: '66741'
  },
  '66742': {
    country: 'US',
    city: 'Gas',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Allen',
    latitude: 37.9232,
    longitude: -95.3467,
    postal_code: '66742'
  },
  '66743': {
    country: 'US',
    city: 'Girard',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.5091,
    longitude: -94.8569,
    postal_code: '66743'
  },
  '66746': {
    country: 'US',
    city: 'Hepler',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.6576,
    longitude: -94.9892,
    postal_code: '66746'
  },
  '66748': {
    country: 'US',
    city: 'Humboldt',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Allen',
    latitude: 37.8045,
    longitude: -95.422,
    postal_code: '66748'
  },
  '66749': {
    country: 'US',
    city: 'Iola',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Allen',
    latitude: 37.9245,
    longitude: -95.4,
    postal_code: '66749'
  },
  '66751': {
    country: 'US',
    city: 'La Harpe',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Allen',
    latitude: 37.917,
    longitude: -95.2991,
    postal_code: '66751'
  },
  '66753': {
    country: 'US',
    city: 'Mc Cune',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.3468,
    longitude: -95.0376,
    postal_code: '66753'
  },
  '66754': {
    country: 'US',
    city: 'Mapleton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Bourbon',
    latitude: 38.0228,
    longitude: -94.8735,
    postal_code: '66754'
  },
  '66755': {
    country: 'US',
    city: 'Moran',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Allen',
    latitude: 37.9342,
    longitude: -95.1647,
    postal_code: '66755'
  },
  '66756': {
    country: 'US',
    city: 'Mulberry',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.5442,
    longitude: -94.6839,
    postal_code: '66756'
  },
  '66757': {
    country: 'US',
    city: 'Neodesha',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wilson',
    latitude: 37.4257,
    longitude: -95.6765,
    postal_code: '66757'
  },
  '66758': {
    country: 'US',
    city: 'Neosho Falls',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Woodson',
    latitude: 37.9669,
    longitude: -95.5497,
    postal_code: '66758'
  },
  '66759': {
    country: 'US',
    city: 'New Albany',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wilson',
    latitude: 37.569,
    longitude: -95.9379,
    postal_code: '66759'
  },
  '66760': {
    country: 'US',
    city: 'Opolis',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.342,
    longitude: -94.6199,
    postal_code: '66760'
  },
  '66761': {
    country: 'US',
    city: 'Piqua',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Woodson',
    latitude: 37.9228,
    longitude: -95.5353,
    postal_code: '66761'
  },
  '66762': {
    country: 'US',
    city: 'Pittsburg',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.3951,
    longitude: -94.7105,
    postal_code: '66762'
  },
  '66763': {
    country: 'US',
    city: 'Frontenac',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.4466,
    longitude: -94.6916,
    postal_code: '66763'
  },
  '66767': {
    country: 'US',
    city: 'Prescott',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Linn',
    latitude: 38.0718,
    longitude: -94.7008,
    postal_code: '66767'
  },
  '66769': {
    country: 'US',
    city: 'Redfield',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Bourbon',
    latitude: 37.8367,
    longitude: -94.8805,
    postal_code: '66769'
  },
  '66770': {
    country: 'US',
    city: 'Riverton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cherokee',
    latitude: 37.081,
    longitude: -94.7175,
    postal_code: '66770'
  },
  '66771': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Neosho',
    latitude: 37.518,
    longitude: -95.1688,
    postal_code: '66771'
  },
  '66772': {
    country: 'US',
    city: 'Savonburg',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Allen',
    latitude: 37.7517,
    longitude: -95.1545,
    postal_code: '66772'
  },
  '66773': {
    country: 'US',
    city: 'Scammon',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cherokee',
    latitude: 37.2809,
    longitude: -94.8092,
    postal_code: '66773'
  },
  '66775': {
    country: 'US',
    city: 'Stark',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Neosho',
    latitude: 37.6811,
    longitude: -95.1388,
    postal_code: '66775'
  },
  '66776': {
    country: 'US',
    city: 'Thayer',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Neosho',
    latitude: 37.4527,
    longitude: -95.4671,
    postal_code: '66776'
  },
  '66777': {
    country: 'US',
    city: 'Toronto',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Woodson',
    latitude: 37.7953,
    longitude: -95.9368,
    postal_code: '66777'
  },
  '66778': {
    country: 'US',
    city: 'Treece',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cherokee',
    latitude: 37.0005,
    longitude: -94.8409,
    postal_code: '66778'
  },
  '66779': {
    country: 'US',
    city: 'Uniontown',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Bourbon',
    latitude: 37.8473,
    longitude: -94.9752,
    postal_code: '66779'
  },
  '66780': {
    country: 'US',
    city: 'Walnut',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Crawford',
    latitude: 37.5967,
    longitude: -95.0455,
    postal_code: '66780'
  },
  '66781': {
    country: 'US',
    city: 'Weir',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cherokee',
    latitude: 37.2912,
    longitude: -94.7286,
    postal_code: '66781'
  },
  '66782': {
    country: 'US',
    city: 'West Mineral',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cherokee',
    latitude: 37.2851,
    longitude: -94.9261,
    postal_code: '66782'
  },
  '66783': {
    country: 'US',
    city: 'Yates Center',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Woodson',
    latitude: 37.8801,
    longitude: -95.7289,
    postal_code: '66783'
  },
  '66801': {
    country: 'US',
    city: 'Emporia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lyon',
    latitude: 38.4184,
    longitude: -96.1871,
    postal_code: '66801'
  },
  '66830': {
    country: 'US',
    city: 'Admire',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lyon',
    latitude: 38.6393,
    longitude: -96.1017,
    postal_code: '66830'
  },
  '66833': {
    country: 'US',
    city: 'Allen',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lyon',
    latitude: 38.652,
    longitude: -96.1735,
    postal_code: '66833'
  },
  '66834': {
    country: 'US',
    city: 'Alta Vista',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wabaunsee',
    latitude: 38.8636,
    longitude: -96.48,
    postal_code: '66834'
  },
  '66835': {
    country: 'US',
    city: 'Americus',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lyon',
    latitude: 38.5098,
    longitude: -96.2608,
    postal_code: '66835'
  },
  '66838': {
    country: 'US',
    city: 'Burdick',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Morris',
    latitude: 38.5674,
    longitude: -96.8396,
    postal_code: '66838'
  },
  '66839': {
    country: 'US',
    city: 'Burlington',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Coffey',
    latitude: 38.2363,
    longitude: -95.7336,
    postal_code: '66839'
  },
  '66840': {
    country: 'US',
    city: 'Burns',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.1222,
    longitude: -96.8634,
    postal_code: '66840'
  },
  '66842': {
    country: 'US',
    city: 'Cassoday',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 38.0298,
    longitude: -96.6746,
    postal_code: '66842'
  },
  '66843': {
    country: 'US',
    city: 'Cedar Point',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Chase',
    latitude: 38.26,
    longitude: -96.8228,
    postal_code: '66843'
  },
  '66845': {
    country: 'US',
    city: 'Cottonwood Falls',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Chase',
    latitude: 38.3565,
    longitude: -96.5418,
    postal_code: '66845'
  },
  '66846': {
    country: 'US',
    city: 'Council Grove',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Morris',
    latitude: 38.6959,
    longitude: -96.5469,
    postal_code: '66846'
  },
  '66849': {
    country: 'US',
    city: 'Dwight',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Morris',
    latitude: 38.8389,
    longitude: -96.5802,
    postal_code: '66849'
  },
  '66850': {
    country: 'US',
    city: 'Elmdale',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Chase',
    latitude: 38.3779,
    longitude: -96.6675,
    postal_code: '66850'
  },
  '66851': {
    country: 'US',
    city: 'Florence',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.2415,
    longitude: -96.9346,
    postal_code: '66851'
  },
  '66852': {
    country: 'US',
    city: 'Gridley',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Coffey',
    latitude: 38.1012,
    longitude: -95.8874,
    postal_code: '66852'
  },
  '66853': {
    country: 'US',
    city: 'Hamilton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Greenwood',
    latitude: 37.9791,
    longitude: -96.1691,
    postal_code: '66853'
  },
  '66854': {
    country: 'US',
    city: 'Hartford',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lyon',
    latitude: 38.2833,
    longitude: -95.9997,
    postal_code: '66854'
  },
  '66855': {
    country: 'US',
    city: 'Lamont',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Greenwood',
    latitude: 38.1125,
    longitude: -96.0267,
    postal_code: '66855'
  },
  '66856': {
    country: 'US',
    city: 'Lebo',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Coffey',
    latitude: 38.4161,
    longitude: -95.8222,
    postal_code: '66856'
  },
  '66857': {
    country: 'US',
    city: 'Le Roy',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Coffey',
    latitude: 38.0874,
    longitude: -95.6227,
    postal_code: '66857'
  },
  '66858': {
    country: 'US',
    city: 'Lincolnville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.4933,
    longitude: -96.9626,
    postal_code: '66858'
  },
  '66859': {
    country: 'US',
    city: 'Lost Springs',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.5657,
    longitude: -96.9799,
    postal_code: '66859'
  },
  '66860': {
    country: 'US',
    city: 'Madison',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Greenwood',
    latitude: 38.1278,
    longitude: -96.1213,
    postal_code: '66860'
  },
  '66861': {
    country: 'US',
    city: 'Marion',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.3554,
    longitude: -97.0204,
    postal_code: '66861'
  },
  '66862': {
    country: 'US',
    city: 'Matfield Green',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Chase',
    latitude: 38.1448,
    longitude: -96.5541,
    postal_code: '66862'
  },
  '66863': {
    country: 'US',
    city: 'Neal',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Greenwood',
    latitude: 37.8329,
    longitude: -96.0818,
    postal_code: '66863'
  },
  '66864': {
    country: 'US',
    city: 'Neosho Rapids',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lyon',
    latitude: 38.3948,
    longitude: -96.0168,
    postal_code: '66864'
  },
  '66865': {
    country: 'US',
    city: 'Olpe',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lyon',
    latitude: 38.2578,
    longitude: -96.1891,
    postal_code: '66865'
  },
  '66866': {
    country: 'US',
    city: 'Peabody',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.1737,
    longitude: -97.1184,
    postal_code: '66866'
  },
  '66868': {
    country: 'US',
    city: 'Reading',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lyon',
    latitude: 38.529,
    longitude: -95.9895,
    postal_code: '66868'
  },
  '66869': {
    country: 'US',
    city: 'Strong City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Chase',
    latitude: 38.4129,
    longitude: -96.5172,
    postal_code: '66869'
  },
  '66870': {
    country: 'US',
    city: 'Virgil',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Greenwood',
    latitude: 37.8976,
    longitude: -96.0329,
    postal_code: '66870'
  },
  '66871': {
    country: 'US',
    city: 'Waverly',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Coffey',
    latitude: 38.3782,
    longitude: -95.5982,
    postal_code: '66871'
  },
  '66872': {
    country: 'US',
    city: 'White City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Morris',
    latitude: 38.789,
    longitude: -96.7637,
    postal_code: '66872'
  },
  '66873': {
    country: 'US',
    city: 'Wilsey',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Morris',
    latitude: 38.6361,
    longitude: -96.6753,
    postal_code: '66873'
  },
  '66901': {
    country: 'US',
    city: 'Concordia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cloud',
    latitude: 39.5516,
    longitude: -97.6568,
    postal_code: '66901'
  },
  '66930': {
    country: 'US',
    city: 'Agenda',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Republic',
    latitude: 39.7044,
    longitude: -97.4465,
    postal_code: '66930'
  },
  '66932': {
    country: 'US',
    city: 'Athol',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Smith',
    latitude: 39.7719,
    longitude: -98.9077,
    postal_code: '66932'
  },
  '66933': {
    country: 'US',
    city: 'Barnes',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.6841,
    longitude: -96.8676,
    postal_code: '66933'
  },
  '66935': {
    country: 'US',
    city: 'Belleville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Republic',
    latitude: 39.8241,
    longitude: -97.629,
    postal_code: '66935'
  },
  '66936': {
    country: 'US',
    city: 'Burr Oak',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jewell',
    latitude: 39.8932,
    longitude: -98.3499,
    postal_code: '66936'
  },
  '66937': {
    country: 'US',
    city: 'Clifton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.6201,
    longitude: -97.2611,
    postal_code: '66937'
  },
  '66938': {
    country: 'US',
    city: 'Clyde',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cloud',
    latitude: 39.5758,
    longitude: -97.408,
    postal_code: '66938'
  },
  '66939': {
    country: 'US',
    city: 'Courtland',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Republic',
    latitude: 39.7851,
    longitude: -97.89,
    postal_code: '66939'
  },
  '66940': {
    country: 'US',
    city: 'Cuba',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Republic',
    latitude: 39.7975,
    longitude: -97.4496,
    postal_code: '66940'
  },
  '66941': {
    country: 'US',
    city: 'Esbon',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jewell',
    latitude: 39.7562,
    longitude: -98.4462,
    postal_code: '66941'
  },
  '66942': {
    country: 'US',
    city: 'Formoso',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jewell',
    latitude: 39.7795,
    longitude: -97.9889,
    postal_code: '66942'
  },
  '66943': {
    country: 'US',
    city: 'Greenleaf',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.7061,
    longitude: -96.9775,
    postal_code: '66943'
  },
  '66944': {
    country: 'US',
    city: 'Haddam',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.852,
    longitude: -97.3081,
    postal_code: '66944'
  },
  '66945': {
    country: 'US',
    city: 'Hanover',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.8927,
    longitude: -96.8689,
    postal_code: '66945'
  },
  '66946': {
    country: 'US',
    city: 'Hollenberg',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.96,
    longitude: -96.9735,
    postal_code: '66946'
  },
  '66948': {
    country: 'US',
    city: 'Jamestown',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cloud',
    latitude: 39.6021,
    longitude: -97.8631,
    postal_code: '66948'
  },
  '66949': {
    country: 'US',
    city: 'Jewell',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jewell',
    latitude: 39.6719,
    longitude: -98.1472,
    postal_code: '66949'
  },
  '66951': {
    country: 'US',
    city: 'Kensington',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Smith',
    latitude: 39.7692,
    longitude: -99.0308,
    postal_code: '66951'
  },
  '66952': {
    country: 'US',
    city: 'Lebanon',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Smith',
    latitude: 39.8077,
    longitude: -98.556,
    postal_code: '66952'
  },
  '66953': {
    country: 'US',
    city: 'Linn',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.6847,
    longitude: -97.0854,
    postal_code: '66953'
  },
  '66955': {
    country: 'US',
    city: 'Mahaska',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.9845,
    longitude: -97.3453,
    postal_code: '66955'
  },
  '66956': {
    country: 'US',
    city: 'Mankato',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jewell',
    latitude: 39.7833,
    longitude: -98.2152,
    postal_code: '66956'
  },
  '66958': {
    country: 'US',
    city: 'Morrowville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.8616,
    longitude: -97.1825,
    postal_code: '66958'
  },
  '66959': {
    country: 'US',
    city: 'Munden',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Republic',
    latitude: 39.9272,
    longitude: -97.5403,
    postal_code: '66959'
  },
  '66960': {
    country: 'US',
    city: 'Narka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Republic',
    latitude: 39.9582,
    longitude: -97.4243,
    postal_code: '66960'
  },
  '66961': {
    country: 'US',
    city: 'Norway',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Republic',
    latitude: 39.8279,
    longitude: -97.6509,
    postal_code: '66961'
  },
  '66962': {
    country: 'US',
    city: 'Palmer',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.6192,
    longitude: -97.1122,
    postal_code: '66962'
  },
  '66963': {
    country: 'US',
    city: 'Randall',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jewell',
    latitude: 39.6286,
    longitude: -98.0661,
    postal_code: '66963'
  },
  '66964': {
    country: 'US',
    city: 'Republic',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Republic',
    latitude: 39.9376,
    longitude: -97.8435,
    postal_code: '66964'
  },
  '66966': {
    country: 'US',
    city: 'Scandia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Republic',
    latitude: 39.7939,
    longitude: -97.7786,
    postal_code: '66966'
  },
  '66967': {
    country: 'US',
    city: 'Smith Center',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Smith',
    latitude: 39.8042,
    longitude: -98.7842,
    postal_code: '66967'
  },
  '66968': {
    country: 'US',
    city: 'Washington',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Washington',
    latitude: 39.8223,
    longitude: -97.0484,
    postal_code: '66968'
  },
  '66970': {
    country: 'US',
    city: 'Webber',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Jewell',
    latitude: 39.935,
    longitude: -98.035,
    postal_code: '66970'
  },
  '67001': {
    country: 'US',
    city: 'Andale',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7797,
    longitude: -97.6366,
    postal_code: '67001'
  },
  '67002': {
    country: 'US',
    city: 'Andover',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.6985,
    longitude: -97.1179,
    postal_code: '67002'
  },
  '67003': {
    country: 'US',
    city: 'Anthony',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harper',
    latitude: 37.1512,
    longitude: -98.0285,
    postal_code: '67003'
  },
  '67004': {
    country: 'US',
    city: 'Argonia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.284,
    longitude: -97.7557,
    postal_code: '67004'
  },
  '67005': {
    country: 'US',
    city: 'Arkansas City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cowley',
    latitude: 37.0676,
    longitude: -97.0357,
    postal_code: '67005'
  },
  '67008': {
    country: 'US',
    city: 'Atlanta',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cowley',
    latitude: 37.4343,
    longitude: -96.7661,
    postal_code: '67008'
  },
  '67009': {
    country: 'US',
    city: 'Attica',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harper',
    latitude: 37.2528,
    longitude: -98.2264,
    postal_code: '67009'
  },
  '67010': {
    country: 'US',
    city: 'Augusta',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.6836,
    longitude: -96.9648,
    postal_code: '67010'
  },
  '67012': {
    country: 'US',
    city: 'Beaumont',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.658,
    longitude: -96.5329,
    postal_code: '67012'
  },
  '67013': {
    country: 'US',
    city: 'Belle Plaine',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.4052,
    longitude: -97.2852,
    postal_code: '67013'
  },
  '67016': {
    country: 'US',
    city: 'Bentley',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.8866,
    longitude: -97.5166,
    postal_code: '67016'
  },
  '67017': {
    country: 'US',
    city: 'Benton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.7946,
    longitude: -97.0971,
    postal_code: '67017'
  },
  '67018': {
    country: 'US',
    city: 'Bluff City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harper',
    latitude: 37.0838,
    longitude: -97.8754,
    postal_code: '67018'
  },
  '67019': {
    country: 'US',
    city: 'Burden',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cowley',
    latitude: 37.3209,
    longitude: -96.757,
    postal_code: '67019'
  },
  '67020': {
    country: 'US',
    city: 'Burrton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harvey',
    latitude: 38.0261,
    longitude: -97.6666,
    postal_code: '67020'
  },
  '67021': {
    country: 'US',
    city: 'Byers',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pratt',
    latitude: 37.7847,
    longitude: -98.9017,
    postal_code: '67021'
  },
  '67022': {
    country: 'US',
    city: 'Caldwell',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.0452,
    longitude: -97.6247,
    postal_code: '67022'
  },
  '67023': {
    country: 'US',
    city: 'Cambridge',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cowley',
    latitude: 37.3161,
    longitude: -96.6645,
    postal_code: '67023'
  },
  '67024': {
    country: 'US',
    city: 'Cedar Vale',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Chautauqua',
    latitude: 37.1265,
    longitude: -96.4701,
    postal_code: '67024'
  },
  '67025': {
    country: 'US',
    city: 'Cheney',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6353,
    longitude: -97.7686,
    postal_code: '67025'
  },
  '67026': {
    country: 'US',
    city: 'Clearwater',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.5076,
    longitude: -97.5082,
    postal_code: '67026'
  },
  '67028': {
    country: 'US',
    city: 'Coats',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pratt',
    latitude: 37.5125,
    longitude: -98.8504,
    postal_code: '67028'
  },
  '67029': {
    country: 'US',
    city: 'Coldwater',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Comanche',
    latitude: 37.2479,
    longitude: -99.3115,
    postal_code: '67029'
  },
  '67030': {
    country: 'US',
    city: 'Colwich',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7782,
    longitude: -97.5405,
    postal_code: '67030'
  },
  '67031': {
    country: 'US',
    city: 'Conway Springs',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.3903,
    longitude: -97.6284,
    postal_code: '67031'
  },
  '67035': {
    country: 'US',
    city: 'Cunningham',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kingman',
    latitude: 37.6309,
    longitude: -98.3564,
    postal_code: '67035'
  },
  '67036': {
    country: 'US',
    city: 'Danville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harper',
    latitude: 37.2674,
    longitude: -97.8689,
    postal_code: '67036'
  },
  '67037': {
    country: 'US',
    city: 'Derby',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.553,
    longitude: -97.2549,
    postal_code: '67037'
  },
  '67038': {
    country: 'US',
    city: 'Dexter',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cowley',
    latitude: 37.1641,
    longitude: -96.6917,
    postal_code: '67038'
  },
  '67039': {
    country: 'US',
    city: 'Douglass',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.5195,
    longitude: -96.9948,
    postal_code: '67039'
  },
  '67041': {
    country: 'US',
    city: 'Elbing',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 38.0545,
    longitude: -97.1284,
    postal_code: '67041'
  },
  '67042': {
    country: 'US',
    city: 'El Dorado',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.8226,
    longitude: -96.8543,
    postal_code: '67042'
  },
  '67045': {
    country: 'US',
    city: 'Eureka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Greenwood',
    latitude: 37.8265,
    longitude: -96.2959,
    postal_code: '67045'
  },
  '67047': {
    country: 'US',
    city: 'Fall River',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Greenwood',
    latitude: 37.621,
    longitude: -96.0435,
    postal_code: '67047'
  },
  '67049': {
    country: 'US',
    city: 'Freeport',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harper',
    latitude: 37.1903,
    longitude: -97.8634,
    postal_code: '67049'
  },
  '67050': {
    country: 'US',
    city: 'Garden Plain',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6767,
    longitude: -97.66,
    postal_code: '67050'
  },
  '67051': {
    country: 'US',
    city: 'Geuda Springs',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.0809,
    longitude: -97.1795,
    postal_code: '67051'
  },
  '67052': {
    country: 'US',
    city: 'Goddard',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6597,
    longitude: -97.5753,
    postal_code: '67052'
  },
  '67053': {
    country: 'US',
    city: 'Goessel',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.2468,
    longitude: -97.3463,
    postal_code: '67053'
  },
  '67054': {
    country: 'US',
    city: 'Greensburg',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kiowa',
    latitude: 37.6084,
    longitude: -99.3011,
    postal_code: '67054'
  },
  '67055': {
    country: 'US',
    city: 'Greenwich',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7833,
    longitude: -97.2054,
    postal_code: '67055'
  },
  '67056': {
    country: 'US',
    city: 'Halstead',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harvey',
    latitude: 38.0064,
    longitude: -97.5118,
    postal_code: '67056'
  },
  '67057': {
    country: 'US',
    city: 'Hardtner',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barber',
    latitude: 37.03,
    longitude: -98.6547,
    postal_code: '67057'
  },
  '67058': {
    country: 'US',
    city: 'Harper',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harper',
    latitude: 37.2909,
    longitude: -98.018,
    postal_code: '67058'
  },
  '67059': {
    country: 'US',
    city: 'Haviland',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kiowa',
    latitude: 37.6096,
    longitude: -99.134,
    postal_code: '67059'
  },
  '67060': {
    country: 'US',
    city: 'Haysville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.5647,
    longitude: -97.3553,
    postal_code: '67060'
  },
  '67061': {
    country: 'US',
    city: 'Hazelton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barber',
    latitude: 37.0984,
    longitude: -98.4003,
    postal_code: '67061'
  },
  '67062': {
    country: 'US',
    city: 'Hesston',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harvey',
    latitude: 38.136,
    longitude: -97.4495,
    postal_code: '67062'
  },
  '67063': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.3449,
    longitude: -97.2122,
    postal_code: '67063'
  },
  '67065': {
    country: 'US',
    city: 'Isabel',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barber',
    latitude: 37.4485,
    longitude: -98.5351,
    postal_code: '67065'
  },
  '67066': {
    country: 'US',
    city: 'Iuka',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pratt',
    latitude: 37.7397,
    longitude: -98.7361,
    postal_code: '67066'
  },
  '67067': {
    country: 'US',
    city: 'Kechi',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7934,
    longitude: -97.2737,
    postal_code: '67067'
  },
  '67068': {
    country: 'US',
    city: 'Kingman',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kingman',
    latitude: 37.5964,
    longitude: -98.1304,
    postal_code: '67068'
  },
  '67070': {
    country: 'US',
    city: 'Kiowa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barber',
    latitude: 37.0172,
    longitude: -98.4859,
    postal_code: '67070'
  },
  '67071': {
    country: 'US',
    city: 'Lake City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barber',
    latitude: 37.3569,
    longitude: -98.8098,
    postal_code: '67071'
  },
  '67072': {
    country: 'US',
    city: 'Latham',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.531,
    longitude: -96.6791,
    postal_code: '67072'
  },
  '67073': {
    country: 'US',
    city: 'Lehigh',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.3771,
    longitude: -97.3043,
    postal_code: '67073'
  },
  '67074': {
    country: 'US',
    city: 'Leon',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.6813,
    longitude: -96.7526,
    postal_code: '67074'
  },
  '67101': {
    country: 'US',
    city: 'Maize',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7747,
    longitude: -97.4689,
    postal_code: '67101'
  },
  '67102': {
    country: 'US',
    city: 'Maple City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cowley',
    latitude: 37.2377,
    longitude: -96.8389,
    postal_code: '67102'
  },
  '67103': {
    country: 'US',
    city: 'Mayfield',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.2518,
    longitude: -97.5416,
    postal_code: '67103'
  },
  '67104': {
    country: 'US',
    city: 'Medicine Lodge',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barber',
    latitude: 37.2845,
    longitude: -98.5848,
    postal_code: '67104'
  },
  '67105': {
    country: 'US',
    city: 'Milan',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.2578,
    longitude: -97.6521,
    postal_code: '67105'
  },
  '67106': {
    country: 'US',
    city: 'Milton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.4401,
    longitude: -97.7592,
    postal_code: '67106'
  },
  '67107': {
    country: 'US',
    city: 'Moundridge',
    state: 'Kansas',
    state_short: 'KS',
    county: 'McPherson',
    latitude: 38.206,
    longitude: -97.5088,
    postal_code: '67107'
  },
  '67108': {
    country: 'US',
    city: 'Mount Hope',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.8684,
    longitude: -97.6591,
    postal_code: '67108'
  },
  '67109': {
    country: 'US',
    city: 'Mullinville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kiowa',
    latitude: 37.5716,
    longitude: -99.4639,
    postal_code: '67109'
  },
  '67110': {
    country: 'US',
    city: 'Mulvane',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.4764,
    longitude: -97.232,
    postal_code: '67110'
  },
  '67111': {
    country: 'US',
    city: 'Murdock',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kingman',
    latitude: 37.6099,
    longitude: -97.9503,
    postal_code: '67111'
  },
  '67112': {
    country: 'US',
    city: 'Nashville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kingman',
    latitude: 37.4344,
    longitude: -98.4171,
    postal_code: '67112'
  },
  '67114': {
    country: 'US',
    city: 'Newton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harvey',
    latitude: 38.0451,
    longitude: -97.3435,
    postal_code: '67114'
  },
  '67117': {
    country: 'US',
    city: 'North Newton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harvey',
    latitude: 38.0743,
    longitude: -97.3473,
    postal_code: '67117'
  },
  '67118': {
    country: 'US',
    city: 'Norwich',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kingman',
    latitude: 37.4501,
    longitude: -97.8662,
    postal_code: '67118'
  },
  '67119': {
    country: 'US',
    city: 'Oxford',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.2653,
    longitude: -97.1761,
    postal_code: '67119'
  },
  '67120': {
    country: 'US',
    city: 'Peck',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.5054,
    longitude: -97.3408,
    postal_code: '67120'
  },
  '67122': {
    country: 'US',
    city: 'Piedmont',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Greenwood',
    latitude: 37.6372,
    longitude: -96.3695,
    postal_code: '67122'
  },
  '67123': {
    country: 'US',
    city: 'Potwin',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.9389,
    longitude: -97.0198,
    postal_code: '67123'
  },
  '67124': {
    country: 'US',
    city: 'Pratt',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pratt',
    latitude: 37.6502,
    longitude: -98.73,
    postal_code: '67124'
  },
  '67127': {
    country: 'US',
    city: 'Protection',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Comanche',
    latitude: 37.1968,
    longitude: -99.4816,
    postal_code: '67127'
  },
  '67131': {
    country: 'US',
    city: 'Rock',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cowley',
    latitude: 37.4413,
    longitude: -97.0059,
    postal_code: '67131'
  },
  '67132': {
    country: 'US',
    city: 'Rosalia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.796,
    longitude: -96.6482,
    postal_code: '67132'
  },
  '67133': {
    country: 'US',
    city: 'Rose Hill',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.5784,
    longitude: -97.1173,
    postal_code: '67133'
  },
  '67134': {
    country: 'US',
    city: 'Sawyer',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pratt',
    latitude: 37.5101,
    longitude: -98.6642,
    postal_code: '67134'
  },
  '67135': {
    country: 'US',
    city: 'Sedgwick',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harvey',
    latitude: 37.9167,
    longitude: -97.4225,
    postal_code: '67135'
  },
  '67137': {
    country: 'US',
    city: 'Severy',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Greenwood',
    latitude: 37.617,
    longitude: -96.2252,
    postal_code: '67137'
  },
  '67138': {
    country: 'US',
    city: 'Sharon',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barber',
    latitude: 37.2492,
    longitude: -98.4142,
    postal_code: '67138'
  },
  '67140': {
    country: 'US',
    city: 'South Haven',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.05,
    longitude: -97.4042,
    postal_code: '67140'
  },
  '67142': {
    country: 'US',
    city: 'Spivey',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kingman',
    latitude: 37.441,
    longitude: -98.1751,
    postal_code: '67142'
  },
  '67143': {
    country: 'US',
    city: 'Sun City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barber',
    latitude: 37.3786,
    longitude: -98.9159,
    postal_code: '67143'
  },
  '67144': {
    country: 'US',
    city: 'Towanda',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.8005,
    longitude: -96.9918,
    postal_code: '67144'
  },
  '67146': {
    country: 'US',
    city: 'Udall',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cowley',
    latitude: 37.3939,
    longitude: -97.1108,
    postal_code: '67146'
  },
  '67147': {
    country: 'US',
    city: 'Valley Center',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.8616,
    longitude: -97.2621,
    postal_code: '67147'
  },
  '67149': {
    country: 'US',
    city: 'Viola',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.5696,
    longitude: -97.6306,
    postal_code: '67149'
  },
  '67150': {
    country: 'US',
    city: 'Waldron',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harper',
    latitude: 37.0022,
    longitude: -98.1826,
    postal_code: '67150'
  },
  '67151': {
    country: 'US',
    city: 'Walton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Harvey',
    latitude: 38.1239,
    longitude: -97.2361,
    postal_code: '67151'
  },
  '67152': {
    country: 'US',
    city: 'Wellington',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sumner',
    latitude: 37.2778,
    longitude: -97.391,
    postal_code: '67152'
  },
  '67154': {
    country: 'US',
    city: 'Whitewater',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Butler',
    latitude: 37.9612,
    longitude: -97.1308,
    postal_code: '67154'
  },
  '67155': {
    country: 'US',
    city: 'Wilmore',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Comanche',
    latitude: 37.3318,
    longitude: -99.1846,
    postal_code: '67155'
  },
  '67156': {
    country: 'US',
    city: 'Winfield',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cowley',
    latitude: 37.2416,
    longitude: -96.98,
    postal_code: '67156'
  },
  '67159': {
    country: 'US',
    city: 'Zenda',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kingman',
    latitude: 37.4373,
    longitude: -98.2874,
    postal_code: '67159'
  },
  '67201': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6922,
    longitude: -97.3375,
    postal_code: '67201'
  },
  '67202': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6899,
    longitude: -97.3355,
    postal_code: '67202'
  },
  '67203': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7048,
    longitude: -97.3638,
    postal_code: '67203'
  },
  '67204': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7488,
    longitude: -97.3566,
    postal_code: '67204'
  },
  '67205': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7639,
    longitude: -97.4269,
    postal_code: '67205'
  },
  '67206': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7038,
    longitude: -97.2253,
    postal_code: '67206'
  },
  '67207': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.671,
    longitude: -97.2179,
    postal_code: '67207'
  },
  '67208': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7024,
    longitude: -97.2811,
    postal_code: '67208'
  },
  '67209': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6779,
    longitude: -97.4235,
    postal_code: '67209'
  },
  '67210': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6379,
    longitude: -97.2613,
    postal_code: '67210'
  },
  '67211': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6662,
    longitude: -97.3165,
    postal_code: '67211'
  },
  '67212': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7007,
    longitude: -97.4383,
    postal_code: '67212'
  },
  '67213': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.668,
    longitude: -97.3591,
    postal_code: '67213'
  },
  '67214': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7051,
    longitude: -97.3133,
    postal_code: '67214'
  },
  '67215': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6333,
    longitude: -97.425,
    postal_code: '67215'
  },
  '67216': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6223,
    longitude: -97.3136,
    postal_code: '67216'
  },
  '67217': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6266,
    longitude: -97.3581,
    postal_code: '67217'
  },
  '67218': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.669,
    longitude: -97.2802,
    postal_code: '67218'
  },
  '67219': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7719,
    longitude: -97.3175,
    postal_code: '67219'
  },
  '67220': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7667,
    longitude: -97.2805,
    postal_code: '67220'
  },
  '67221': {
    country: 'US',
    city: 'Mcconnell Afb',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6066,
    longitude: -97.2979,
    postal_code: '67221'
  },
  '67223': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7367,
    longitude: -97.499,
    postal_code: '67223'
  },
  '67226': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7379,
    longitude: -97.2479,
    postal_code: '67226'
  },
  '67227': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6281,
    longitude: -97.4916,
    postal_code: '67227'
  },
  '67228': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7742,
    longitude: -97.1711,
    postal_code: '67228'
  },
  '67230': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6808,
    longitude: -97.1558,
    postal_code: '67230'
  },
  '67232': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6395,
    longitude: -97.1714,
    postal_code: '67232'
  },
  '67235': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7149,
    longitude: -97.499,
    postal_code: '67235'
  },
  '67260': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.7194,
    longitude: -97.2936,
    postal_code: '67260'
  },
  '67275': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6936,
    longitude: -97.4804,
    postal_code: '67275'
  },
  '67276': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6936,
    longitude: -97.4804,
    postal_code: '67276'
  },
  '67277': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6936,
    longitude: -97.4804,
    postal_code: '67277'
  },
  '67278': {
    country: 'US',
    city: 'Wichita',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sedgwick',
    latitude: 37.6922,
    longitude: -97.3375,
    postal_code: '67278'
  },
  '67301': {
    country: 'US',
    city: 'Independence',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Montgomery',
    latitude: 37.2292,
    longitude: -95.7165,
    postal_code: '67301'
  },
  '67330': {
    country: 'US',
    city: 'Altamont',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Labette',
    latitude: 37.1873,
    longitude: -95.2983,
    postal_code: '67330'
  },
  '67332': {
    country: 'US',
    city: 'Bartlett',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Labette',
    latitude: 37.0601,
    longitude: -95.2115,
    postal_code: '67332'
  },
  '67333': {
    country: 'US',
    city: 'Caney',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Montgomery',
    latitude: 37.0224,
    longitude: -95.9091,
    postal_code: '67333'
  },
  '67334': {
    country: 'US',
    city: 'Chautauqua',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Chautauqua',
    latitude: 37.0262,
    longitude: -96.1783,
    postal_code: '67334'
  },
  '67335': {
    country: 'US',
    city: 'Cherryvale',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Montgomery',
    latitude: 37.2668,
    longitude: -95.559,
    postal_code: '67335'
  },
  '67336': {
    country: 'US',
    city: 'Chetopa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Labette',
    latitude: 37.0418,
    longitude: -95.0796,
    postal_code: '67336'
  },
  '67337': {
    country: 'US',
    city: 'Coffeyville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Montgomery',
    latitude: 37.0441,
    longitude: -95.6328,
    postal_code: '67337'
  },
  '67340': {
    country: 'US',
    city: 'Dearing',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Montgomery',
    latitude: 37.0596,
    longitude: -95.7131,
    postal_code: '67340'
  },
  '67341': {
    country: 'US',
    city: 'Dennis',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Labette',
    latitude: 37.3243,
    longitude: -95.4116,
    postal_code: '67341'
  },
  '67342': {
    country: 'US',
    city: 'Edna',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Labette',
    latitude: 37.0561,
    longitude: -95.3442,
    postal_code: '67342'
  },
  '67344': {
    country: 'US',
    city: 'Elk City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Montgomery',
    latitude: 37.3146,
    longitude: -95.9135,
    postal_code: '67344'
  },
  '67345': {
    country: 'US',
    city: 'Elk Falls',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Elk',
    latitude: 37.3745,
    longitude: -96.1959,
    postal_code: '67345'
  },
  '67346': {
    country: 'US',
    city: 'Grenola',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Elk',
    latitude: 37.3668,
    longitude: -96.4396,
    postal_code: '67346'
  },
  '67347': {
    country: 'US',
    city: 'Havana',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Montgomery',
    latitude: 37.0917,
    longitude: -95.9414,
    postal_code: '67347'
  },
  '67349': {
    country: 'US',
    city: 'Howard',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Elk',
    latitude: 37.4809,
    longitude: -96.2563,
    postal_code: '67349'
  },
  '67351': {
    country: 'US',
    city: 'Liberty',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Montgomery',
    latitude: 37.1576,
    longitude: -95.6017,
    postal_code: '67351'
  },
  '67352': {
    country: 'US',
    city: 'Longton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Elk',
    latitude: 37.3905,
    longitude: -96.0814,
    postal_code: '67352'
  },
  '67353': {
    country: 'US',
    city: 'Moline',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Elk',
    latitude: 37.3649,
    longitude: -96.3069,
    postal_code: '67353'
  },
  '67354': {
    country: 'US',
    city: 'Mound Valley',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Labette',
    latitude: 37.2091,
    longitude: -95.4247,
    postal_code: '67354'
  },
  '67355': {
    country: 'US',
    city: 'Niotaze',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Chautauqua',
    latitude: 37.037,
    longitude: -96.0121,
    postal_code: '67355'
  },
  '67356': {
    country: 'US',
    city: 'Oswego',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Labette',
    latitude: 37.1823,
    longitude: -95.1335,
    postal_code: '67356'
  },
  '67357': {
    country: 'US',
    city: 'Parsons',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Labette',
    latitude: 37.3389,
    longitude: -95.2693,
    postal_code: '67357'
  },
  '67360': {
    country: 'US',
    city: 'Peru',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Chautauqua',
    latitude: 37.0568,
    longitude: -96.1404,
    postal_code: '67360'
  },
  '67361': {
    country: 'US',
    city: 'Sedan',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Chautauqua',
    latitude: 37.1297,
    longitude: -96.1732,
    postal_code: '67361'
  },
  '67363': {
    country: 'US',
    city: 'Sycamore',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Montgomery',
    latitude: 37.3281,
    longitude: -95.717,
    postal_code: '67363'
  },
  '67364': {
    country: 'US',
    city: 'Tyro',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Montgomery',
    latitude: 37.037,
    longitude: -95.8237,
    postal_code: '67364'
  },
  '67401': {
    country: 'US',
    city: 'Salina',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Saline',
    latitude: 38.8237,
    longitude: -97.6421,
    postal_code: '67401'
  },
  '67402': {
    country: 'US',
    city: 'Salina',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Saline',
    latitude: 38.8403,
    longitude: -97.6114,
    postal_code: '67402'
  },
  '67410': {
    country: 'US',
    city: 'Abilene',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Dickinson',
    latitude: 38.9371,
    longitude: -97.2063,
    postal_code: '67410'
  },
  '67416': {
    country: 'US',
    city: 'Assaria',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Saline',
    latitude: 38.6676,
    longitude: -97.6203,
    postal_code: '67416'
  },
  '67417': {
    country: 'US',
    city: 'Aurora',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cloud',
    latitude: 39.4472,
    longitude: -97.5304,
    postal_code: '67417'
  },
  '67418': {
    country: 'US',
    city: 'Barnard',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lincoln',
    latitude: 39.1816,
    longitude: -98.0711,
    postal_code: '67418'
  },
  '67420': {
    country: 'US',
    city: 'Beloit',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Mitchell',
    latitude: 39.4416,
    longitude: -98.1192,
    postal_code: '67420'
  },
  '67422': {
    country: 'US',
    city: 'Bennington',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ottawa',
    latitude: 39.0224,
    longitude: -97.6031,
    postal_code: '67422'
  },
  '67423': {
    country: 'US',
    city: 'Beverly',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lincoln',
    latitude: 38.9844,
    longitude: -97.9818,
    postal_code: '67423'
  },
  '67425': {
    country: 'US',
    city: 'Brookville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Saline',
    latitude: 38.7858,
    longitude: -97.863,
    postal_code: '67425'
  },
  '67427': {
    country: 'US',
    city: 'Bushton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rice',
    latitude: 38.5018,
    longitude: -98.4016,
    postal_code: '67427'
  },
  '67428': {
    country: 'US',
    city: 'Canton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'McPherson',
    latitude: 38.3858,
    longitude: -97.4291,
    postal_code: '67428'
  },
  '67430': {
    country: 'US',
    city: 'Cawker City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Mitchell',
    latitude: 39.5115,
    longitude: -98.4335,
    postal_code: '67430'
  },
  '67431': {
    country: 'US',
    city: 'Chapman',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Dickinson',
    latitude: 38.9722,
    longitude: -97.017,
    postal_code: '67431'
  },
  '67432': {
    country: 'US',
    city: 'Clay Center',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Clay',
    latitude: 39.3844,
    longitude: -97.1278,
    postal_code: '67432'
  },
  '67436': {
    country: 'US',
    city: 'Delphos',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ottawa',
    latitude: 39.2731,
    longitude: -97.7717,
    postal_code: '67436'
  },
  '67437': {
    country: 'US',
    city: 'Downs',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osborne',
    latitude: 39.5028,
    longitude: -98.5441,
    postal_code: '67437'
  },
  '67438': {
    country: 'US',
    city: 'Durham',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.5038,
    longitude: -97.2555,
    postal_code: '67438'
  },
  '67439': {
    country: 'US',
    city: 'Ellsworth',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellsworth',
    latitude: 38.7312,
    longitude: -98.2057,
    postal_code: '67439'
  },
  '67441': {
    country: 'US',
    city: 'Enterprise',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Dickinson',
    latitude: 38.9063,
    longitude: -97.1122,
    postal_code: '67441'
  },
  '67442': {
    country: 'US',
    city: 'Falun',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Saline',
    latitude: 38.6648,
    longitude: -97.7554,
    postal_code: '67442'
  },
  '67443': {
    country: 'US',
    city: 'Galva',
    state: 'Kansas',
    state_short: 'KS',
    county: 'McPherson',
    latitude: 38.3824,
    longitude: -97.5359,
    postal_code: '67443'
  },
  '67444': {
    country: 'US',
    city: 'Geneseo',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rice',
    latitude: 38.5037,
    longitude: -98.1858,
    postal_code: '67444'
  },
  '67445': {
    country: 'US',
    city: 'Glasco',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cloud',
    latitude: 39.3621,
    longitude: -97.8418,
    postal_code: '67445'
  },
  '67446': {
    country: 'US',
    city: 'Glen Elder',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Mitchell',
    latitude: 39.4958,
    longitude: -98.3155,
    postal_code: '67446'
  },
  '67447': {
    country: 'US',
    city: 'Green',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Clay',
    latitude: 39.4803,
    longitude: -97.0149,
    postal_code: '67447'
  },
  '67448': {
    country: 'US',
    city: 'Gypsum',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Saline',
    latitude: 38.7047,
    longitude: -97.4338,
    postal_code: '67448'
  },
  '67449': {
    country: 'US',
    city: 'Herington',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Dickinson',
    latitude: 38.6767,
    longitude: -96.8941,
    postal_code: '67449'
  },
  '67450': {
    country: 'US',
    city: 'Holyrood',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellsworth',
    latitude: 38.59,
    longitude: -98.4159,
    postal_code: '67450'
  },
  '67451': {
    country: 'US',
    city: 'Hope',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Dickinson',
    latitude: 38.6776,
    longitude: -97.1061,
    postal_code: '67451'
  },
  '67452': {
    country: 'US',
    city: 'Hunter',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Mitchell',
    latitude: 39.243,
    longitude: -98.4017,
    postal_code: '67452'
  },
  '67454': {
    country: 'US',
    city: 'Kanopolis',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellsworth',
    latitude: 38.7091,
    longitude: -98.1575,
    postal_code: '67454'
  },
  '67455': {
    country: 'US',
    city: 'Lincoln',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lincoln',
    latitude: 39.1027,
    longitude: -98.2149,
    postal_code: '67455'
  },
  '67456': {
    country: 'US',
    city: 'Lindsborg',
    state: 'Kansas',
    state_short: 'KS',
    county: 'McPherson',
    latitude: 38.5761,
    longitude: -97.6739,
    postal_code: '67456'
  },
  '67457': {
    country: 'US',
    city: 'Little River',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rice',
    latitude: 38.4079,
    longitude: -98.0113,
    postal_code: '67457'
  },
  '67458': {
    country: 'US',
    city: 'Longford',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Clay',
    latitude: 39.1834,
    longitude: -97.2499,
    postal_code: '67458'
  },
  '67459': {
    country: 'US',
    city: 'Lorraine',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellsworth',
    latitude: 38.5652,
    longitude: -98.29,
    postal_code: '67459'
  },
  '67460': {
    country: 'US',
    city: 'Mcpherson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'McPherson',
    latitude: 38.3763,
    longitude: -97.6702,
    postal_code: '67460'
  },
  '67464': {
    country: 'US',
    city: 'Marquette',
    state: 'Kansas',
    state_short: 'KS',
    county: 'McPherson',
    latitude: 38.556,
    longitude: -97.8381,
    postal_code: '67464'
  },
  '67466': {
    country: 'US',
    city: 'Miltonvale',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cloud',
    latitude: 39.3481,
    longitude: -97.4579,
    postal_code: '67466'
  },
  '67467': {
    country: 'US',
    city: 'Minneapolis',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ottawa',
    latitude: 39.1295,
    longitude: -97.6688,
    postal_code: '67467'
  },
  '67468': {
    country: 'US',
    city: 'Morganville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Clay',
    latitude: 39.4607,
    longitude: -97.2467,
    postal_code: '67468'
  },
  '67470': {
    country: 'US',
    city: 'New Cambria',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Saline',
    latitude: 38.896,
    longitude: -97.523,
    postal_code: '67470'
  },
  '67473': {
    country: 'US',
    city: 'Osborne',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osborne',
    latitude: 39.4194,
    longitude: -98.6961,
    postal_code: '67473'
  },
  '67474': {
    country: 'US',
    city: 'Portis',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osborne',
    latitude: 39.5455,
    longitude: -98.6904,
    postal_code: '67474'
  },
  '67475': {
    country: 'US',
    city: 'Ramona',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.5777,
    longitude: -97.0759,
    postal_code: '67475'
  },
  '67476': {
    country: 'US',
    city: 'Roxbury',
    state: 'Kansas',
    state_short: 'KS',
    county: 'McPherson',
    latitude: 38.5508,
    longitude: -97.4303,
    postal_code: '67476'
  },
  '67478': {
    country: 'US',
    city: 'Simpson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Mitchell',
    latitude: 39.3851,
    longitude: -97.9328,
    postal_code: '67478'
  },
  '67480': {
    country: 'US',
    city: 'Solomon',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Dickinson',
    latitude: 38.9194,
    longitude: -97.3518,
    postal_code: '67480'
  },
  '67481': {
    country: 'US',
    city: 'Sylvan Grove',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lincoln',
    latitude: 39.0324,
    longitude: -98.373,
    postal_code: '67481'
  },
  '67482': {
    country: 'US',
    city: 'Talmage',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Dickinson',
    latitude: 39.0269,
    longitude: -97.2597,
    postal_code: '67482'
  },
  '67483': {
    country: 'US',
    city: 'Tampa',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Marion',
    latitude: 38.5534,
    longitude: -97.1774,
    postal_code: '67483'
  },
  '67484': {
    country: 'US',
    city: 'Tescott',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ottawa',
    latitude: 38.9916,
    longitude: -97.8319,
    postal_code: '67484'
  },
  '67485': {
    country: 'US',
    city: 'Tipton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Mitchell',
    latitude: 39.3436,
    longitude: -98.4644,
    postal_code: '67485'
  },
  '67487': {
    country: 'US',
    city: 'Wakefield',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Clay',
    latitude: 39.2249,
    longitude: -97.0229,
    postal_code: '67487'
  },
  '67490': {
    country: 'US',
    city: 'Wilson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellsworth',
    latitude: 38.8134,
    longitude: -98.4432,
    postal_code: '67490'
  },
  '67491': {
    country: 'US',
    city: 'Windom',
    state: 'Kansas',
    state_short: 'KS',
    county: 'McPherson',
    latitude: 38.3845,
    longitude: -97.8965,
    postal_code: '67491'
  },
  '67492': {
    country: 'US',
    city: 'Woodbine',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Dickinson',
    latitude: 38.8131,
    longitude: -96.9619,
    postal_code: '67492'
  },
  '67501': {
    country: 'US',
    city: 'Hutchinson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 38.055,
    longitude: -97.9311,
    postal_code: '67501'
  },
  '67502': {
    country: 'US',
    city: 'Hutchinson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 38.1156,
    longitude: -97.8937,
    postal_code: '67502'
  },
  '67504': {
    country: 'US',
    city: 'Hutchinson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 37.9532,
    longitude: -98.0859,
    postal_code: '67504'
  },
  '67505': {
    country: 'US',
    city: 'South Hutchinson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 38.0282,
    longitude: -97.9431,
    postal_code: '67505'
  },
  '67510': {
    country: 'US',
    city: 'Abbyville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 37.9626,
    longitude: -98.2071,
    postal_code: '67510'
  },
  '67511': {
    country: 'US',
    city: 'Albert',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barton',
    latitude: 38.4528,
    longitude: -99.0115,
    postal_code: '67511'
  },
  '67512': {
    country: 'US',
    city: 'Alden',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rice',
    latitude: 38.2343,
    longitude: -98.3112,
    postal_code: '67512'
  },
  '67513': {
    country: 'US',
    city: 'Alexander',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rush',
    latitude: 38.457,
    longitude: -99.5378,
    postal_code: '67513'
  },
  '67514': {
    country: 'US',
    city: 'Arlington',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 37.8598,
    longitude: -98.159,
    postal_code: '67514'
  },
  '67515': {
    country: 'US',
    city: 'Arnold',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ness',
    latitude: 38.6403,
    longitude: -100.0462,
    postal_code: '67515'
  },
  '67516': {
    country: 'US',
    city: 'Bazine',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ness',
    latitude: 38.4565,
    longitude: -99.7016,
    postal_code: '67516'
  },
  '67518': {
    country: 'US',
    city: 'Beeler',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ness',
    latitude: 38.4445,
    longitude: -100.1949,
    postal_code: '67518'
  },
  '67519': {
    country: 'US',
    city: 'Belpre',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Edwards',
    latitude: 37.9347,
    longitude: -99.0936,
    postal_code: '67519'
  },
  '67520': {
    country: 'US',
    city: 'Bison',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rush',
    latitude: 38.5193,
    longitude: -99.1986,
    postal_code: '67520'
  },
  '67521': {
    country: 'US',
    city: 'Brownell',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ness',
    latitude: 38.6239,
    longitude: -99.7328,
    postal_code: '67521'
  },
  '67522': {
    country: 'US',
    city: 'Buhler',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 38.1309,
    longitude: -97.7691,
    postal_code: '67522'
  },
  '67523': {
    country: 'US',
    city: 'Burdett',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pawnee',
    latitude: 38.2104,
    longitude: -99.5275,
    postal_code: '67523'
  },
  '67524': {
    country: 'US',
    city: 'Chase',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rice',
    latitude: 38.3635,
    longitude: -98.3556,
    postal_code: '67524'
  },
  '67525': {
    country: 'US',
    city: 'Claflin',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barton',
    latitude: 38.5409,
    longitude: -98.5372,
    postal_code: '67525'
  },
  '67526': {
    country: 'US',
    city: 'Ellinwood',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barton',
    latitude: 38.3565,
    longitude: -98.5849,
    postal_code: '67526'
  },
  '67529': {
    country: 'US',
    city: 'Garfield',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pawnee',
    latitude: 38.0649,
    longitude: -99.2374,
    postal_code: '67529'
  },
  '67530': {
    country: 'US',
    city: 'Great Bend',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barton',
    latitude: 38.3936,
    longitude: -98.7751,
    postal_code: '67530'
  },
  '67543': {
    country: 'US',
    city: 'Haven',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 37.8989,
    longitude: -97.7828,
    postal_code: '67543'
  },
  '67544': {
    country: 'US',
    city: 'Hoisington',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barton',
    latitude: 38.4789,
    longitude: -98.7565,
    postal_code: '67544'
  },
  '67545': {
    country: 'US',
    city: 'Hudson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Stafford',
    latitude: 38.1485,
    longitude: -98.6408,
    postal_code: '67545'
  },
  '67546': {
    country: 'US',
    city: 'Inman',
    state: 'Kansas',
    state_short: 'KS',
    county: 'McPherson',
    latitude: 38.2232,
    longitude: -97.7951,
    postal_code: '67546'
  },
  '67547': {
    country: 'US',
    city: 'Kinsley',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Edwards',
    latitude: 37.9247,
    longitude: -99.4116,
    postal_code: '67547'
  },
  '67548': {
    country: 'US',
    city: 'La Crosse',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rush',
    latitude: 38.5311,
    longitude: -99.3097,
    postal_code: '67548'
  },
  '67550': {
    country: 'US',
    city: 'Larned',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pawnee',
    latitude: 38.1946,
    longitude: -99.101,
    postal_code: '67550'
  },
  '67552': {
    country: 'US',
    city: 'Lewis',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Edwards',
    latitude: 37.9062,
    longitude: -99.248,
    postal_code: '67552'
  },
  '67553': {
    country: 'US',
    city: 'Liebenthal',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rush',
    latitude: 38.6557,
    longitude: -99.3203,
    postal_code: '67553'
  },
  '67554': {
    country: 'US',
    city: 'Lyons',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rice',
    latitude: 38.3349,
    longitude: -98.1831,
    postal_code: '67554'
  },
  '67556': {
    country: 'US',
    city: 'Mc Cracken',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rush',
    latitude: 38.5957,
    longitude: -99.554,
    postal_code: '67556'
  },
  '67557': {
    country: 'US',
    city: 'Macksville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Stafford',
    latitude: 37.9433,
    longitude: -98.9481,
    postal_code: '67557'
  },
  '67559': {
    country: 'US',
    city: 'Nekoma',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rush',
    latitude: 38.4372,
    longitude: -99.4234,
    postal_code: '67559'
  },
  '67560': {
    country: 'US',
    city: 'Ness City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ness',
    latitude: 38.4388,
    longitude: -99.9029,
    postal_code: '67560'
  },
  '67561': {
    country: 'US',
    city: 'Nickerson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 38.1412,
    longitude: -98.0674,
    postal_code: '67561'
  },
  '67563': {
    country: 'US',
    city: 'Offerle',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Edwards',
    latitude: 37.8828,
    longitude: -99.5498,
    postal_code: '67563'
  },
  '67564': {
    country: 'US',
    city: 'Olmitz',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barton',
    latitude: 38.5167,
    longitude: -98.9365,
    postal_code: '67564'
  },
  '67565': {
    country: 'US',
    city: 'Otis',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rush',
    latitude: 38.5353,
    longitude: -99.0534,
    postal_code: '67565'
  },
  '67566': {
    country: 'US',
    city: 'Partridge',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 37.9671,
    longitude: -98.0798,
    postal_code: '67566'
  },
  '67567': {
    country: 'US',
    city: 'Pawnee Rock',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Barton',
    latitude: 38.2782,
    longitude: -98.9814,
    postal_code: '67567'
  },
  '67568': {
    country: 'US',
    city: 'Plevna',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 37.9658,
    longitude: -98.2988,
    postal_code: '67568'
  },
  '67570': {
    country: 'US',
    city: 'Pretty Prairie',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 37.7783,
    longitude: -97.9886,
    postal_code: '67570'
  },
  '67572': {
    country: 'US',
    city: 'Ransom',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ness',
    latitude: 38.64,
    longitude: -99.9267,
    postal_code: '67572'
  },
  '67573': {
    country: 'US',
    city: 'Raymond',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rice',
    latitude: 38.2877,
    longitude: -98.4119,
    postal_code: '67573'
  },
  '67574': {
    country: 'US',
    city: 'Rozel',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Pawnee',
    latitude: 38.2148,
    longitude: -99.4048,
    postal_code: '67574'
  },
  '67575': {
    country: 'US',
    city: 'Rush Center',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rush',
    latitude: 38.4533,
    longitude: -99.3079,
    postal_code: '67575'
  },
  '67576': {
    country: 'US',
    city: 'St John',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Stafford',
    latitude: 38.0022,
    longitude: -98.7601,
    postal_code: '67576'
  },
  '67578': {
    country: 'US',
    city: 'Stafford',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Stafford',
    latitude: 37.9554,
    longitude: -98.5929,
    postal_code: '67578'
  },
  '67579': {
    country: 'US',
    city: 'Sterling',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rice',
    latitude: 38.2126,
    longitude: -98.2055,
    postal_code: '67579'
  },
  '67581': {
    country: 'US',
    city: 'Sylvia',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 37.9557,
    longitude: -98.4068,
    postal_code: '67581'
  },
  '67583': {
    country: 'US',
    city: 'Turon',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 37.8224,
    longitude: -98.3591,
    postal_code: '67583'
  },
  '67584': {
    country: 'US',
    city: 'Utica',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ness',
    latitude: 38.6411,
    longitude: -100.138,
    postal_code: '67584'
  },
  '67585': {
    country: 'US',
    city: 'Yoder',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Reno',
    latitude: 37.9412,
    longitude: -97.8711,
    postal_code: '67585'
  },
  '67601': {
    country: 'US',
    city: 'Hays',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellis',
    latitude: 38.8782,
    longitude: -99.3348,
    postal_code: '67601'
  },
  '67621': {
    country: 'US',
    city: 'Agra',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Phillips',
    latitude: 39.8037,
    longitude: -99.1255,
    postal_code: '67621'
  },
  '67622': {
    country: 'US',
    city: 'Almena',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Norton',
    latitude: 39.8891,
    longitude: -99.7042,
    postal_code: '67622'
  },
  '67623': {
    country: 'US',
    city: 'Alton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osborne',
    latitude: 39.4514,
    longitude: -98.9539,
    postal_code: '67623'
  },
  '67625': {
    country: 'US',
    city: 'Bogue',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Graham',
    latitude: 39.3782,
    longitude: -99.6788,
    postal_code: '67625'
  },
  '67626': {
    country: 'US',
    city: 'Bunker Hill',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Russell',
    latitude: 38.8758,
    longitude: -98.704,
    postal_code: '67626'
  },
  '67627': {
    country: 'US',
    city: 'Catharine',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellis',
    latitude: 38.9276,
    longitude: -99.2161,
    postal_code: '67627'
  },
  '67628': {
    country: 'US',
    city: 'Cedar',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Smith',
    latitude: 39.6602,
    longitude: -98.9369,
    postal_code: '67628'
  },
  '67629': {
    country: 'US',
    city: 'Clayton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Norton',
    latitude: 39.7375,
    longitude: -100.1776,
    postal_code: '67629'
  },
  '67631': {
    country: 'US',
    city: 'Collyer',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Trego',
    latitude: 39.0038,
    longitude: -100.0862,
    postal_code: '67631'
  },
  '67632': {
    country: 'US',
    city: 'Damar',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rooks',
    latitude: 39.3242,
    longitude: -99.5811,
    postal_code: '67632'
  },
  '67634': {
    country: 'US',
    city: 'Dorrance',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Russell',
    latitude: 38.8348,
    longitude: -98.5695,
    postal_code: '67634'
  },
  '67635': {
    country: 'US',
    city: 'Dresden',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Decatur',
    latitude: 39.6094,
    longitude: -100.4112,
    postal_code: '67635'
  },
  '67637': {
    country: 'US',
    city: 'Ellis',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellis',
    latitude: 38.9471,
    longitude: -99.5285,
    postal_code: '67637'
  },
  '67638': {
    country: 'US',
    city: 'Gaylord',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Smith',
    latitude: 39.6439,
    longitude: -98.8476,
    postal_code: '67638'
  },
  '67639': {
    country: 'US',
    city: 'Glade',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Phillips',
    latitude: 39.6704,
    longitude: -99.2996,
    postal_code: '67639'
  },
  '67640': {
    country: 'US',
    city: 'Gorham',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Russell',
    latitude: 38.8722,
    longitude: -99.0112,
    postal_code: '67640'
  },
  '67642': {
    country: 'US',
    city: 'Hill City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Graham',
    latitude: 39.3566,
    longitude: -99.8429,
    postal_code: '67642'
  },
  '67643': {
    country: 'US',
    city: 'Jennings',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Decatur',
    latitude: 39.6762,
    longitude: -100.2834,
    postal_code: '67643'
  },
  '67644': {
    country: 'US',
    city: 'Kirwin',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Phillips',
    latitude: 39.6717,
    longitude: -99.1204,
    postal_code: '67644'
  },
  '67645': {
    country: 'US',
    city: 'Lenora',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Norton',
    latitude: 39.6105,
    longitude: -100.0028,
    postal_code: '67645'
  },
  '67646': {
    country: 'US',
    city: 'Logan',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Phillips',
    latitude: 39.6611,
    longitude: -99.5687,
    postal_code: '67646'
  },
  '67647': {
    country: 'US',
    city: 'Long Island',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Phillips',
    latitude: 39.9517,
    longitude: -99.5391,
    postal_code: '67647'
  },
  '67648': {
    country: 'US',
    city: 'Lucas',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Russell',
    latitude: 39.0581,
    longitude: -98.5352,
    postal_code: '67648'
  },
  '67649': {
    country: 'US',
    city: 'Luray',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Russell',
    latitude: 39.1039,
    longitude: -98.6851,
    postal_code: '67649'
  },
  '67650': {
    country: 'US',
    city: 'Morland',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Graham',
    latitude: 39.3229,
    longitude: -100.0733,
    postal_code: '67650'
  },
  '67651': {
    country: 'US',
    city: 'Natoma',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Osborne',
    latitude: 39.2013,
    longitude: -98.9829,
    postal_code: '67651'
  },
  '67653': {
    country: 'US',
    city: 'Norcatur',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Decatur',
    latitude: 39.8447,
    longitude: -100.2007,
    postal_code: '67653'
  },
  '67654': {
    country: 'US',
    city: 'Norton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Norton',
    latitude: 39.8407,
    longitude: -99.8878,
    postal_code: '67654'
  },
  '67656': {
    country: 'US',
    city: 'Ogallah',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Trego',
    latitude: 38.9914,
    longitude: -99.7323,
    postal_code: '67656'
  },
  '67657': {
    country: 'US',
    city: 'Palco',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rooks',
    latitude: 39.2531,
    longitude: -99.5593,
    postal_code: '67657'
  },
  '67658': {
    country: 'US',
    city: 'Paradise',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Russell',
    latitude: 39.0756,
    longitude: -98.9207,
    postal_code: '67658'
  },
  '67659': {
    country: 'US',
    city: 'Penokee',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Graham',
    latitude: 39.3482,
    longitude: -99.9719,
    postal_code: '67659'
  },
  '67660': {
    country: 'US',
    city: 'Pfeifer',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellis',
    latitude: 38.7153,
    longitude: -99.1718,
    postal_code: '67660'
  },
  '67661': {
    country: 'US',
    city: 'Phillipsburg',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Phillips',
    latitude: 39.7623,
    longitude: -99.3328,
    postal_code: '67661'
  },
  '67663': {
    country: 'US',
    city: 'Plainville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rooks',
    latitude: 39.2308,
    longitude: -99.3008,
    postal_code: '67663'
  },
  '67664': {
    country: 'US',
    city: 'Prairie View',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Phillips',
    latitude: 39.837,
    longitude: -99.5683,
    postal_code: '67664'
  },
  '67665': {
    country: 'US',
    city: 'Russell',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Russell',
    latitude: 38.8806,
    longitude: -98.8595,
    postal_code: '67665'
  },
  '67667': {
    country: 'US',
    city: 'Schoenchen',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellis',
    latitude: 38.7128,
    longitude: -99.3322,
    postal_code: '67667'
  },
  '67669': {
    country: 'US',
    city: 'Stockton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rooks',
    latitude: 39.4376,
    longitude: -99.2871,
    postal_code: '67669'
  },
  '67671': {
    country: 'US',
    city: 'Victoria',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellis',
    latitude: 38.8589,
    longitude: -99.1391,
    postal_code: '67671'
  },
  '67672': {
    country: 'US',
    city: 'Wakeeney',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Trego',
    latitude: 39.025,
    longitude: -99.8796,
    postal_code: '67672'
  },
  '67673': {
    country: 'US',
    city: 'Waldo',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Russell',
    latitude: 39.0877,
    longitude: -98.7785,
    postal_code: '67673'
  },
  '67674': {
    country: 'US',
    city: 'Walker',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ellis',
    latitude: 38.8672,
    longitude: -99.0759,
    postal_code: '67674'
  },
  '67675': {
    country: 'US',
    city: 'Woodston',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rooks',
    latitude: 39.4431,
    longitude: -99.1037,
    postal_code: '67675'
  },
  '67701': {
    country: 'US',
    city: 'Colby',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Thomas',
    latitude: 39.383,
    longitude: -101.0442,
    postal_code: '67701'
  },
  '67730': {
    country: 'US',
    city: 'Atwood',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rawlins',
    latitude: 39.7926,
    longitude: -101.0318,
    postal_code: '67730'
  },
  '67731': {
    country: 'US',
    city: 'Bird City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cheyenne',
    latitude: 39.7579,
    longitude: -101.5319,
    postal_code: '67731'
  },
  '67732': {
    country: 'US',
    city: 'Brewster',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Thomas',
    latitude: 39.3655,
    longitude: -101.373,
    postal_code: '67732'
  },
  '67733': {
    country: 'US',
    city: 'Edson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sherman',
    latitude: 39.3579,
    longitude: -101.521,
    postal_code: '67733'
  },
  '67734': {
    country: 'US',
    city: 'Gem',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Thomas',
    latitude: 39.4296,
    longitude: -100.8948,
    postal_code: '67734'
  },
  '67735': {
    country: 'US',
    city: 'Goodland',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sherman',
    latitude: 39.3491,
    longitude: -101.7164,
    postal_code: '67735'
  },
  '67736': {
    country: 'US',
    city: 'Gove',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Gove',
    latitude: 38.887,
    longitude: -100.4867,
    postal_code: '67736'
  },
  '67737': {
    country: 'US',
    city: 'Grainfield',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Gove',
    latitude: 39.103,
    longitude: -100.468,
    postal_code: '67737'
  },
  '67738': {
    country: 'US',
    city: 'Grinnell',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Gove',
    latitude: 39.0851,
    longitude: -100.6621,
    postal_code: '67738'
  },
  '67739': {
    country: 'US',
    city: 'Herndon',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rawlins',
    latitude: 39.9036,
    longitude: -100.8139,
    postal_code: '67739'
  },
  '67740': {
    country: 'US',
    city: 'Hoxie',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sheridan',
    latitude: 39.3322,
    longitude: -100.4758,
    postal_code: '67740'
  },
  '67741': {
    country: 'US',
    city: 'Kanorado',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sherman',
    latitude: 39.3438,
    longitude: -102.0015,
    postal_code: '67741'
  },
  '67743': {
    country: 'US',
    city: 'Levant',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Thomas',
    latitude: 39.3841,
    longitude: -101.2096,
    postal_code: '67743'
  },
  '67744': {
    country: 'US',
    city: 'Ludell',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rawlins',
    latitude: 39.863,
    longitude: -100.9604,
    postal_code: '67744'
  },
  '67745': {
    country: 'US',
    city: 'Mc Donald',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Rawlins',
    latitude: 39.7923,
    longitude: -101.3227,
    postal_code: '67745'
  },
  '67747': {
    country: 'US',
    city: 'Monument',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Logan',
    latitude: 39.079,
    longitude: -101.035,
    postal_code: '67747'
  },
  '67748': {
    country: 'US',
    city: 'Oakley',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Logan',
    latitude: 39.1121,
    longitude: -100.858,
    postal_code: '67748'
  },
  '67749': {
    country: 'US',
    city: 'Oberlin',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Decatur',
    latitude: 39.8275,
    longitude: -100.5314,
    postal_code: '67749'
  },
  '67751': {
    country: 'US',
    city: 'Park',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Gove',
    latitude: 39.0788,
    longitude: -100.3469,
    postal_code: '67751'
  },
  '67752': {
    country: 'US',
    city: 'Quinter',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Gove',
    latitude: 39.0363,
    longitude: -100.2337,
    postal_code: '67752'
  },
  '67753': {
    country: 'US',
    city: 'Rexford',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Thomas',
    latitude: 39.4267,
    longitude: -100.7461,
    postal_code: '67753'
  },
  '67756': {
    country: 'US',
    city: 'Saint Francis',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Cheyenne',
    latitude: 39.6804,
    longitude: -101.9093,
    postal_code: '67756'
  },
  '67757': {
    country: 'US',
    city: 'Selden',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Sheridan',
    latitude: 39.5216,
    longitude: -100.5257,
    postal_code: '67757'
  },
  '67758': {
    country: 'US',
    city: 'Sharon Springs',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wallace',
    latitude: 38.8857,
    longitude: -101.7431,
    postal_code: '67758'
  },
  '67761': {
    country: 'US',
    city: 'Wallace',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wallace',
    latitude: 38.8747,
    longitude: -101.5735,
    postal_code: '67761'
  },
  '67762': {
    country: 'US',
    city: 'Weskan',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wallace',
    latitude: 38.8649,
    longitude: -101.9512,
    postal_code: '67762'
  },
  '67764': {
    country: 'US',
    city: 'Winona',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Logan',
    latitude: 39.061,
    longitude: -101.2216,
    postal_code: '67764'
  },
  '67801': {
    country: 'US',
    city: 'Dodge City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ford',
    latitude: 37.7569,
    longitude: -100.0241,
    postal_code: '67801'
  },
  '67831': {
    country: 'US',
    city: 'Ashland',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Clark',
    latitude: 37.1823,
    longitude: -99.759,
    postal_code: '67831'
  },
  '67834': {
    country: 'US',
    city: 'Bucklin',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ford',
    latitude: 37.553,
    longitude: -99.6325,
    postal_code: '67834'
  },
  '67835': {
    country: 'US',
    city: 'Cimarron',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Gray',
    latitude: 37.8127,
    longitude: -100.3438,
    postal_code: '67835'
  },
  '67836': {
    country: 'US',
    city: 'Coolidge',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Hamilton',
    latitude: 38.0215,
    longitude: -102.007,
    postal_code: '67836'
  },
  '67837': {
    country: 'US',
    city: 'Copeland',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Gray',
    latitude: 37.568,
    longitude: -100.6148,
    postal_code: '67837'
  },
  '67838': {
    country: 'US',
    city: 'Deerfield',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kearny',
    latitude: 38.0068,
    longitude: -101.1431,
    postal_code: '67838'
  },
  '67839': {
    country: 'US',
    city: 'Dighton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lane',
    latitude: 38.4742,
    longitude: -100.4647,
    postal_code: '67839'
  },
  '67840': {
    country: 'US',
    city: 'Englewood',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Clark',
    latitude: 37.0384,
    longitude: -99.9835,
    postal_code: '67840'
  },
  '67841': {
    country: 'US',
    city: 'Ensign',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Gray',
    latitude: 37.6414,
    longitude: -100.2496,
    postal_code: '67841'
  },
  '67842': {
    country: 'US',
    city: 'Ford',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ford',
    latitude: 37.6323,
    longitude: -99.7642,
    postal_code: '67842'
  },
  '67843': {
    country: 'US',
    city: 'Fort Dodge',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ford',
    latitude: 37.7303,
    longitude: -99.937,
    postal_code: '67843'
  },
  '67844': {
    country: 'US',
    city: 'Fowler',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Meade',
    latitude: 37.3542,
    longitude: -100.1981,
    postal_code: '67844'
  },
  '67846': {
    country: 'US',
    city: 'Garden City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Finney',
    latitude: 37.9769,
    longitude: -100.8621,
    postal_code: '67846'
  },
  '67849': {
    country: 'US',
    city: 'Hanston',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Hodgeman',
    latitude: 38.109,
    longitude: -99.6928,
    postal_code: '67849'
  },
  '67850': {
    country: 'US',
    city: 'Healy',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Lane',
    latitude: 38.5664,
    longitude: -100.6159,
    postal_code: '67850'
  },
  '67851': {
    country: 'US',
    city: 'Holcomb',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Finney',
    latitude: 37.9931,
    longitude: -100.9893,
    postal_code: '67851'
  },
  '67853': {
    country: 'US',
    city: 'Ingalls',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Gray',
    latitude: 37.8293,
    longitude: -100.5143,
    postal_code: '67853'
  },
  '67854': {
    country: 'US',
    city: 'Jetmore',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Hodgeman',
    latitude: 38.0738,
    longitude: -99.9327,
    postal_code: '67854'
  },
  '67855': {
    country: 'US',
    city: 'Johnson',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Stanton',
    latitude: 37.5694,
    longitude: -101.7194,
    postal_code: '67855'
  },
  '67857': {
    country: 'US',
    city: 'Kendall',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Hamilton',
    latitude: 37.9347,
    longitude: -101.546,
    postal_code: '67857'
  },
  '67859': {
    country: 'US',
    city: 'Kismet',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Seward',
    latitude: 37.2042,
    longitude: -100.7014,
    postal_code: '67859'
  },
  '67860': {
    country: 'US',
    city: 'Lakin',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Kearny',
    latitude: 37.9382,
    longitude: -101.2713,
    postal_code: '67860'
  },
  '67861': {
    country: 'US',
    city: 'Leoti',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wichita',
    latitude: 38.4987,
    longitude: -101.3589,
    postal_code: '67861'
  },
  '67862': {
    country: 'US',
    city: 'Manter',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Stanton',
    latitude: 37.5451,
    longitude: -101.9109,
    postal_code: '67862'
  },
  '67863': {
    country: 'US',
    city: 'Marienthal',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Wichita',
    latitude: 38.4875,
    longitude: -101.2129,
    postal_code: '67863'
  },
  '67864': {
    country: 'US',
    city: 'Meade',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Meade',
    latitude: 37.2821,
    longitude: -100.3364,
    postal_code: '67864'
  },
  '67865': {
    country: 'US',
    city: 'Minneola',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Clark',
    latitude: 37.4425,
    longitude: -100.0088,
    postal_code: '67865'
  },
  '67867': {
    country: 'US',
    city: 'Montezuma',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Gray',
    latitude: 37.6016,
    longitude: -100.4461,
    postal_code: '67867'
  },
  '67868': {
    country: 'US',
    city: 'Pierceville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Finney',
    latitude: 37.906,
    longitude: -100.7523,
    postal_code: '67868'
  },
  '67869': {
    country: 'US',
    city: 'Plains',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Meade',
    latitude: 37.2701,
    longitude: -100.5732,
    postal_code: '67869'
  },
  '67870': {
    country: 'US',
    city: 'Satanta',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Haskell',
    latitude: 37.4409,
    longitude: -100.9691,
    postal_code: '67870'
  },
  '67871': {
    country: 'US',
    city: 'Scott City',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Scott',
    latitude: 38.4823,
    longitude: -100.9064,
    postal_code: '67871'
  },
  '67876': {
    country: 'US',
    city: 'Spearville',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ford',
    latitude: 37.8235,
    longitude: -99.737,
    postal_code: '67876'
  },
  '67877': {
    country: 'US',
    city: 'Sublette',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Haskell',
    latitude: 37.5221,
    longitude: -100.8208,
    postal_code: '67877'
  },
  '67878': {
    country: 'US',
    city: 'Syracuse',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Hamilton',
    latitude: 37.9826,
    longitude: -101.7687,
    postal_code: '67878'
  },
  '67879': {
    country: 'US',
    city: 'Tribune',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Greeley',
    latitude: 38.4962,
    longitude: -101.7656,
    postal_code: '67879'
  },
  '67880': {
    country: 'US',
    city: 'Ulysses',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Grant',
    latitude: 37.5792,
    longitude: -101.3488,
    postal_code: '67880'
  },
  '67882': {
    country: 'US',
    city: 'Wright',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Ford',
    latitude: 37.7806,
    longitude: -99.8921,
    postal_code: '67882'
  },
  '67901': {
    country: 'US',
    city: 'Liberal',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Seward',
    latitude: 37.0438,
    longitude: -100.9286,
    postal_code: '67901'
  },
  '67905': {
    country: 'US',
    city: 'Liberal',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Seward',
    latitude: 37.0216,
    longitude: -100.938,
    postal_code: '67905'
  },
  '67950': {
    country: 'US',
    city: 'Elkhart',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Morton',
    latitude: 37.0154,
    longitude: -101.9012,
    postal_code: '67950'
  },
  '67951': {
    country: 'US',
    city: 'Hugoton',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Stevens',
    latitude: 37.1682,
    longitude: -101.3346,
    postal_code: '67951'
  },
  '67952': {
    country: 'US',
    city: 'Moscow',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Stevens',
    latitude: 37.3172,
    longitude: -101.2427,
    postal_code: '67952'
  },
  '67953': {
    country: 'US',
    city: 'Richfield',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Morton',
    latitude: 37.2834,
    longitude: -101.7004,
    postal_code: '67953'
  },
  '67954': {
    country: 'US',
    city: 'Rolla',
    state: 'Kansas',
    state_short: 'KS',
    county: 'Morton',
    latitude: 37.1089,
    longitude: -101.6447,
    postal_code: '67954'
  },
  '68001': {
    country: 'US',
    city: 'Abie',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Butler',
    latitude: 41.3479,
    longitude: -96.9563,
    postal_code: '68001'
  },
  '68002': {
    country: 'US',
    city: 'Arlington',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Washington',
    latitude: 41.4417,
    longitude: -96.307,
    postal_code: '68002'
  },
  '68003': {
    country: 'US',
    city: 'Ashland',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.0541,
    longitude: -96.3904,
    postal_code: '68003'
  },
  '68004': {
    country: 'US',
    city: 'Bancroft',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cuming',
    latitude: 42.0267,
    longitude: -96.6171,
    postal_code: '68004'
  },
  '68005': {
    country: 'US',
    city: 'Bellevue',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.1497,
    longitude: -95.9099,
    postal_code: '68005'
  },
  '68007': {
    country: 'US',
    city: 'Bennington',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.3623,
    longitude: -96.1575,
    postal_code: '68007'
  },
  '68008': {
    country: 'US',
    city: 'Blair',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Washington',
    latitude: 41.5454,
    longitude: -96.1617,
    postal_code: '68008'
  },
  '68009': {
    country: 'US',
    city: 'Blair',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Washington',
    latitude: 41.5383,
    longitude: -96.1823,
    postal_code: '68009'
  },
  '68010': {
    country: 'US',
    city: 'Boys Town',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2587,
    longitude: -96.133,
    postal_code: '68010'
  },
  '68014': {
    country: 'US',
    city: 'Bruno',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Butler',
    latitude: 41.2718,
    longitude: -96.9646,
    postal_code: '68014'
  },
  '68015': {
    country: 'US',
    city: 'Cedar Bluffs',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.3835,
    longitude: -96.5691,
    postal_code: '68015'
  },
  '68016': {
    country: 'US',
    city: 'Cedar Creek',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 41.0425,
    longitude: -96.1044,
    postal_code: '68016'
  },
  '68017': {
    country: 'US',
    city: 'Ceresco',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.0681,
    longitude: -96.6398,
    postal_code: '68017'
  },
  '68018': {
    country: 'US',
    city: 'Colon',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.2883,
    longitude: -96.6141,
    postal_code: '68018'
  },
  '68019': {
    country: 'US',
    city: 'Craig',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Burt',
    latitude: 41.7715,
    longitude: -96.3924,
    postal_code: '68019'
  },
  '68020': {
    country: 'US',
    city: 'Decatur',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Burt',
    latitude: 41.9966,
    longitude: -96.2595,
    postal_code: '68020'
  },
  '68022': {
    country: 'US',
    city: 'Elkhorn',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2756,
    longitude: -96.2431,
    postal_code: '68022'
  },
  '68023': {
    country: 'US',
    city: 'Fort Calhoun',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Washington',
    latitude: 41.4373,
    longitude: -96.0324,
    postal_code: '68023'
  },
  '68025': {
    country: 'US',
    city: 'Fremont',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.4416,
    longitude: -96.4945,
    postal_code: '68025'
  },
  '68026': {
    country: 'US',
    city: 'Fremont',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.4333,
    longitude: -96.4981,
    postal_code: '68026'
  },
  '68028': {
    country: 'US',
    city: 'Gretna',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.1345,
    longitude: -96.2458,
    postal_code: '68028'
  },
  '68029': {
    country: 'US',
    city: 'Herman',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Washington',
    latitude: 41.6524,
    longitude: -96.2869,
    postal_code: '68029'
  },
  '68030': {
    country: 'US',
    city: 'Homer',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dakota',
    latitude: 42.3322,
    longitude: -96.4656,
    postal_code: '68030'
  },
  '68031': {
    country: 'US',
    city: 'Hooper',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.6414,
    longitude: -96.5232,
    postal_code: '68031'
  },
  '68033': {
    country: 'US',
    city: 'Ithaca',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.1748,
    longitude: -96.5298,
    postal_code: '68033'
  },
  '68034': {
    country: 'US',
    city: 'Kennard',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Washington',
    latitude: 41.4526,
    longitude: -96.1903,
    postal_code: '68034'
  },
  '68036': {
    country: 'US',
    city: 'Linwood',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Butler',
    latitude: 41.4129,
    longitude: -96.9399,
    postal_code: '68036'
  },
  '68037': {
    country: 'US',
    city: 'Louisville',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.9967,
    longitude: -96.1948,
    postal_code: '68037'
  },
  '68038': {
    country: 'US',
    city: 'Lyons',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Burt',
    latitude: 41.9442,
    longitude: -96.4661,
    postal_code: '68038'
  },
  '68039': {
    country: 'US',
    city: 'Macy',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thurston',
    latitude: 42.1177,
    longitude: -96.3589,
    postal_code: '68039'
  },
  '68040': {
    country: 'US',
    city: 'Malmo',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.2984,
    longitude: -96.7327,
    postal_code: '68040'
  },
  '68041': {
    country: 'US',
    city: 'Mead',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.2393,
    longitude: -96.4961,
    postal_code: '68041'
  },
  '68042': {
    country: 'US',
    city: 'Memphis',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.0946,
    longitude: -96.4306,
    postal_code: '68042'
  },
  '68044': {
    country: 'US',
    city: 'Nickerson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.5491,
    longitude: -96.4441,
    postal_code: '68044'
  },
  '68045': {
    country: 'US',
    city: 'Oakland',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Burt',
    latitude: 41.8384,
    longitude: -96.4671,
    postal_code: '68045'
  },
  '68046': {
    country: 'US',
    city: 'Papillion',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.1523,
    longitude: -96.0371,
    postal_code: '68046'
  },
  '68047': {
    country: 'US',
    city: 'Pender',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thurston',
    latitude: 42.1177,
    longitude: -96.7189,
    postal_code: '68047'
  },
  '68048': {
    country: 'US',
    city: 'Plattsmouth',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.9992,
    longitude: -95.9139,
    postal_code: '68048'
  },
  '68050': {
    country: 'US',
    city: 'Prague',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.3036,
    longitude: -96.8301,
    postal_code: '68050'
  },
  '68055': {
    country: 'US',
    city: 'Rosalie',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thurston',
    latitude: 42.0574,
    longitude: -96.4929,
    postal_code: '68055'
  },
  '68056': {
    country: 'US',
    city: 'St Columbans',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.0927,
    longitude: -96.0905,
    postal_code: '68056'
  },
  '68057': {
    country: 'US',
    city: 'Scribner',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.663,
    longitude: -96.6441,
    postal_code: '68057'
  },
  '68058': {
    country: 'US',
    city: 'South Bend',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 41.0067,
    longitude: -96.2462,
    postal_code: '68058'
  },
  '68059': {
    country: 'US',
    city: 'Springfield',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.0765,
    longitude: -96.1438,
    postal_code: '68059'
  },
  '68061': {
    country: 'US',
    city: 'Tekamah',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Burt',
    latitude: 41.7819,
    longitude: -96.2281,
    postal_code: '68061'
  },
  '68062': {
    country: 'US',
    city: 'Thurston',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thurston',
    latitude: 42.1882,
    longitude: -96.6904,
    postal_code: '68062'
  },
  '68063': {
    country: 'US',
    city: 'Uehling',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.7369,
    longitude: -96.5037,
    postal_code: '68063'
  },
  '68064': {
    country: 'US',
    city: 'Valley',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.3186,
    longitude: -96.3463,
    postal_code: '68064'
  },
  '68065': {
    country: 'US',
    city: 'Valparaiso',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.0843,
    longitude: -96.8091,
    postal_code: '68065'
  },
  '68066': {
    country: 'US',
    city: 'Wahoo',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.2117,
    longitude: -96.6219,
    postal_code: '68066'
  },
  '68067': {
    country: 'US',
    city: 'Walthill',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thurston',
    latitude: 42.1485,
    longitude: -96.4803,
    postal_code: '68067'
  },
  '68068': {
    country: 'US',
    city: 'Washington',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Washington',
    latitude: 41.3975,
    longitude: -96.2081,
    postal_code: '68068'
  },
  '68069': {
    country: 'US',
    city: 'Waterloo',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2702,
    longitude: -96.3063,
    postal_code: '68069'
  },
  '68070': {
    country: 'US',
    city: 'Weston',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.1811,
    longitude: -96.7691,
    postal_code: '68070'
  },
  '68071': {
    country: 'US',
    city: 'Winnebago',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thurston',
    latitude: 42.2339,
    longitude: -96.4685,
    postal_code: '68071'
  },
  '68072': {
    country: 'US',
    city: 'Winslow',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.6102,
    longitude: -96.5033,
    postal_code: '68072'
  },
  '68073': {
    country: 'US',
    city: 'Yutan',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.234,
    longitude: -96.3932,
    postal_code: '68073'
  },
  '68101': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68101'
  },
  '68102': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.259,
    longitude: -95.9409,
    postal_code: '68102'
  },
  '68103': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68103'
  },
  '68104': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2919,
    longitude: -95.9999,
    postal_code: '68104'
  },
  '68105': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2435,
    longitude: -95.9629,
    postal_code: '68105'
  },
  '68106': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2403,
    longitude: -95.998,
    postal_code: '68106'
  },
  '68107': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2068,
    longitude: -95.9559,
    postal_code: '68107'
  },
  '68108': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2382,
    longitude: -95.9336,
    postal_code: '68108'
  },
  '68109': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68109'
  },
  '68110': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2933,
    longitude: -95.9361,
    postal_code: '68110'
  },
  '68111': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2962,
    longitude: -95.965,
    postal_code: '68111'
  },
  '68112': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.3296,
    longitude: -95.9597,
    postal_code: '68112'
  },
  '68113': {
    country: 'US',
    city: 'Offutt Afb',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.2563,
    longitude: -95.9404,
    postal_code: '68113'
  },
  '68114': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2656,
    longitude: -96.0493,
    postal_code: '68114'
  },
  '68116': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2879,
    longitude: -96.1495,
    postal_code: '68116'
  },
  '68117': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2064,
    longitude: -95.9953,
    postal_code: '68117'
  },
  '68118': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2606,
    longitude: -96.1661,
    postal_code: '68118'
  },
  '68119': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68119'
  },
  '68120': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68120'
  },
  '68122': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.3333,
    longitude: -96.0458,
    postal_code: '68122'
  },
  '68123': {
    country: 'US',
    city: 'Bellevue',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.1156,
    longitude: -95.9393,
    postal_code: '68123'
  },
  '68124': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2338,
    longitude: -96.0495,
    postal_code: '68124'
  },
  '68127': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2074,
    longitude: -96.0612,
    postal_code: '68127'
  },
  '68128': {
    country: 'US',
    city: 'La Vista',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.1814,
    longitude: -96.0658,
    postal_code: '68128'
  },
  '68130': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2355,
    longitude: -96.1688,
    postal_code: '68130'
  },
  '68131': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2647,
    longitude: -95.9639,
    postal_code: '68131'
  },
  '68132': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2657,
    longitude: -95.996,
    postal_code: '68132'
  },
  '68133': {
    country: 'US',
    city: 'Papillion',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.1416,
    longitude: -96.0131,
    postal_code: '68133'
  },
  '68134': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2949,
    longitude: -96.0546,
    postal_code: '68134'
  },
  '68135': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2104,
    longitude: -96.1698,
    postal_code: '68135'
  },
  '68136': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.1683,
    longitude: -96.2096,
    postal_code: '68136'
  },
  '68137': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2075,
    longitude: -96.1147,
    postal_code: '68137'
  },
  '68138': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.1549,
    longitude: -96.1376,
    postal_code: '68138'
  },
  '68139': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68139'
  },
  '68142': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.3359,
    longitude: -96.0901,
    postal_code: '68142'
  },
  '68144': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2335,
    longitude: -96.1188,
    postal_code: '68144'
  },
  '68145': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68145'
  },
  '68147': {
    country: 'US',
    city: 'Bellevue',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.1758,
    longitude: -95.9553,
    postal_code: '68147'
  },
  '68152': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.3346,
    longitude: -96.0003,
    postal_code: '68152'
  },
  '68154': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2642,
    longitude: -96.1206,
    postal_code: '68154'
  },
  '68155': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68155'
  },
  '68157': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sarpy',
    latitude: 41.181,
    longitude: -95.9909,
    postal_code: '68157'
  },
  '68164': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2955,
    longitude: -96.1008,
    postal_code: '68164'
  },
  '68172': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68172'
  },
  '68175': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68175'
  },
  '68176': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68176'
  },
  '68178': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2657,
    longitude: -95.9477,
    postal_code: '68178'
  },
  '68179': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68179'
  },
  '68180': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68180'
  },
  '68182': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68182'
  },
  '68183': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68183'
  },
  '68197': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2563,
    longitude: -95.9404,
    postal_code: '68197'
  },
  '68198': {
    country: 'US',
    city: 'Omaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Douglas',
    latitude: 41.2586,
    longitude: -95.9378,
    postal_code: '68198'
  },
  '68301': {
    country: 'US',
    city: 'Adams',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.4576,
    longitude: -96.5396,
    postal_code: '68301'
  },
  '68303': {
    country: 'US',
    city: 'Alexandria',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.2614,
    longitude: -97.4039,
    postal_code: '68303'
  },
  '68304': {
    country: 'US',
    city: 'Alvo',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.8992,
    longitude: -96.4036,
    postal_code: '68304'
  },
  '68305': {
    country: 'US',
    city: 'Auburn',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nemaha',
    latitude: 40.3789,
    longitude: -95.8526,
    postal_code: '68305'
  },
  '68307': {
    country: 'US',
    city: 'Avoca',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.8159,
    longitude: -96.0957,
    postal_code: '68307'
  },
  '68309': {
    country: 'US',
    city: 'Barneston',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.0483,
    longitude: -96.577,
    postal_code: '68309'
  },
  '68310': {
    country: 'US',
    city: 'Beatrice',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.2705,
    longitude: -96.7435,
    postal_code: '68310'
  },
  '68313': {
    country: 'US',
    city: 'Beaver Crossing',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Seward',
    latitude: 40.7887,
    longitude: -97.2914,
    postal_code: '68313'
  },
  '68314': {
    country: 'US',
    city: 'Bee',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Seward',
    latitude: 41.0007,
    longitude: -97.0745,
    postal_code: '68314'
  },
  '68315': {
    country: 'US',
    city: 'Belvidere',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.251,
    longitude: -97.5554,
    postal_code: '68315'
  },
  '68316': {
    country: 'US',
    city: 'Benedict',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'York',
    latitude: 41.0033,
    longitude: -97.6029,
    postal_code: '68316'
  },
  '68317': {
    country: 'US',
    city: 'Bennet',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.68,
    longitude: -96.5064,
    postal_code: '68317'
  },
  '68318': {
    country: 'US',
    city: 'Blue Springs',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.1407,
    longitude: -96.659,
    postal_code: '68318'
  },
  '68319': {
    country: 'US',
    city: 'Bradshaw',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'York',
    latitude: 40.9207,
    longitude: -97.7607,
    postal_code: '68319'
  },
  '68320': {
    country: 'US',
    city: 'Brock',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nemaha',
    latitude: 40.4772,
    longitude: -95.9801,
    postal_code: '68320'
  },
  '68321': {
    country: 'US',
    city: 'Brownville',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nemaha',
    latitude: 40.3994,
    longitude: -95.6935,
    postal_code: '68321'
  },
  '68322': {
    country: 'US',
    city: 'Bruning',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.3302,
    longitude: -97.5573,
    postal_code: '68322'
  },
  '68323': {
    country: 'US',
    city: 'Burchard',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pawnee',
    latitude: 40.1475,
    longitude: -96.3503,
    postal_code: '68323'
  },
  '68324': {
    country: 'US',
    city: 'Burr',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Otoe',
    latitude: 40.5601,
    longitude: -96.2384,
    postal_code: '68324'
  },
  '68325': {
    country: 'US',
    city: 'Byron',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.0269,
    longitude: -97.7612,
    postal_code: '68325'
  },
  '68326': {
    country: 'US',
    city: 'Carleton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.3005,
    longitude: -97.6719,
    postal_code: '68326'
  },
  '68327': {
    country: 'US',
    city: 'Chester',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.029,
    longitude: -97.6197,
    postal_code: '68327'
  },
  '68328': {
    country: 'US',
    city: 'Clatonia',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.4724,
    longitude: -96.8555,
    postal_code: '68328'
  },
  '68329': {
    country: 'US',
    city: 'Cook',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Johnson',
    latitude: 40.4986,
    longitude: -96.1526,
    postal_code: '68329'
  },
  '68330': {
    country: 'US',
    city: 'Cordova',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Seward',
    latitude: 40.7185,
    longitude: -97.3407,
    postal_code: '68330'
  },
  '68331': {
    country: 'US',
    city: 'Cortland',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.4976,
    longitude: -96.7166,
    postal_code: '68331'
  },
  '68332': {
    country: 'US',
    city: 'Crab Orchard',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Johnson',
    latitude: 40.3166,
    longitude: -96.4116,
    postal_code: '68332'
  },
  '68333': {
    country: 'US',
    city: 'Crete',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saline',
    latitude: 40.6193,
    longitude: -96.9567,
    postal_code: '68333'
  },
  '68335': {
    country: 'US',
    city: 'Davenport',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.3108,
    longitude: -97.805,
    postal_code: '68335'
  },
  '68336': {
    country: 'US',
    city: 'Davey',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.9639,
    longitude: -96.688,
    postal_code: '68336'
  },
  '68337': {
    country: 'US',
    city: 'Dawson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Richardson',
    latitude: 40.1377,
    longitude: -95.8341,
    postal_code: '68337'
  },
  '68338': {
    country: 'US',
    city: 'Daykin',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Jefferson',
    latitude: 40.3192,
    longitude: -97.3043,
    postal_code: '68338'
  },
  '68339': {
    country: 'US',
    city: 'Denton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7378,
    longitude: -96.8445,
    postal_code: '68339'
  },
  '68340': {
    country: 'US',
    city: 'Deshler',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.1387,
    longitude: -97.73,
    postal_code: '68340'
  },
  '68341': {
    country: 'US',
    city: 'De Witt',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saline',
    latitude: 40.3944,
    longitude: -96.9338,
    postal_code: '68341'
  },
  '68342': {
    country: 'US',
    city: 'Diller',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Jefferson',
    latitude: 40.1192,
    longitude: -96.9495,
    postal_code: '68342'
  },
  '68343': {
    country: 'US',
    city: 'Dorchester',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saline',
    latitude: 40.6499,
    longitude: -97.1056,
    postal_code: '68343'
  },
  '68344': {
    country: 'US',
    city: 'Douglas',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Otoe',
    latitude: 40.5838,
    longitude: -96.397,
    postal_code: '68344'
  },
  '68345': {
    country: 'US',
    city: 'Du Bois',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pawnee',
    latitude: 40.0375,
    longitude: -96.0575,
    postal_code: '68345'
  },
  '68346': {
    country: 'US',
    city: 'Dunbar',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Otoe',
    latitude: 40.6592,
    longitude: -96.0136,
    postal_code: '68346'
  },
  '68347': {
    country: 'US',
    city: 'Eagle',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.8169,
    longitude: -96.429,
    postal_code: '68347'
  },
  '68348': {
    country: 'US',
    city: 'Elk Creek',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Johnson',
    latitude: 40.2977,
    longitude: -96.1421,
    postal_code: '68348'
  },
  '68349': {
    country: 'US',
    city: 'Elmwood',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.8378,
    longitude: -96.2944,
    postal_code: '68349'
  },
  '68350': {
    country: 'US',
    city: 'Endicott',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Jefferson',
    latitude: 40.0496,
    longitude: -97.0813,
    postal_code: '68350'
  },
  '68351': {
    country: 'US',
    city: 'Exeter',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Fillmore',
    latitude: 40.6341,
    longitude: -97.4424,
    postal_code: '68351'
  },
  '68352': {
    country: 'US',
    city: 'Fairbury',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Jefferson',
    latitude: 40.1488,
    longitude: -97.1839,
    postal_code: '68352'
  },
  '68354': {
    country: 'US',
    city: 'Fairmont',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Fillmore',
    latitude: 40.6404,
    longitude: -97.5873,
    postal_code: '68354'
  },
  '68355': {
    country: 'US',
    city: 'Falls City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Richardson',
    latitude: 40.0742,
    longitude: -95.5931,
    postal_code: '68355'
  },
  '68357': {
    country: 'US',
    city: 'Filley',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.2946,
    longitude: -96.5301,
    postal_code: '68357'
  },
  '68358': {
    country: 'US',
    city: 'Firth',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.5586,
    longitude: -96.614,
    postal_code: '68358'
  },
  '68359': {
    country: 'US',
    city: 'Friend',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saline',
    latitude: 40.6368,
    longitude: -97.2739,
    postal_code: '68359'
  },
  '68360': {
    country: 'US',
    city: 'Garland',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Seward',
    latitude: 40.9412,
    longitude: -96.9702,
    postal_code: '68360'
  },
  '68361': {
    country: 'US',
    city: 'Geneva',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Fillmore',
    latitude: 40.5277,
    longitude: -97.6096,
    postal_code: '68361'
  },
  '68362': {
    country: 'US',
    city: 'Gilead',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.147,
    longitude: -97.4271,
    postal_code: '68362'
  },
  '68364': {
    country: 'US',
    city: 'Goehner',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Seward',
    latitude: 40.8322,
    longitude: -97.2212,
    postal_code: '68364'
  },
  '68365': {
    country: 'US',
    city: 'Grafton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Fillmore',
    latitude: 40.6402,
    longitude: -97.7403,
    postal_code: '68365'
  },
  '68366': {
    country: 'US',
    city: 'Greenwood',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.9706,
    longitude: -96.4258,
    postal_code: '68366'
  },
  '68367': {
    country: 'US',
    city: 'Gresham',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'York',
    latitude: 41.021,
    longitude: -97.4079,
    postal_code: '68367'
  },
  '68368': {
    country: 'US',
    city: 'Hallam',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.5739,
    longitude: -96.7998,
    postal_code: '68368'
  },
  '68370': {
    country: 'US',
    city: 'Hebron',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.1728,
    longitude: -97.6052,
    postal_code: '68370'
  },
  '68371': {
    country: 'US',
    city: 'Henderson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'York',
    latitude: 40.7814,
    longitude: -97.7979,
    postal_code: '68371'
  },
  '68372': {
    country: 'US',
    city: 'Hickman',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.6119,
    longitude: -96.6247,
    postal_code: '68372'
  },
  '68375': {
    country: 'US',
    city: 'Hubbell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.0456,
    longitude: -97.4735,
    postal_code: '68375'
  },
  '68376': {
    country: 'US',
    city: 'Humboldt',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Richardson',
    latitude: 40.1566,
    longitude: -95.9311,
    postal_code: '68376'
  },
  '68377': {
    country: 'US',
    city: 'Jansen',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Jefferson',
    latitude: 40.2074,
    longitude: -97.0244,
    postal_code: '68377'
  },
  '68378': {
    country: 'US',
    city: 'Johnson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nemaha',
    latitude: 40.4016,
    longitude: -95.9883,
    postal_code: '68378'
  },
  '68379': {
    country: 'US',
    city: 'Julian',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nemaha',
    latitude: 40.5199,
    longitude: -95.8674,
    postal_code: '68379'
  },
  '68380': {
    country: 'US',
    city: 'Lewiston',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pawnee',
    latitude: 40.2309,
    longitude: -96.4049,
    postal_code: '68380'
  },
  '68381': {
    country: 'US',
    city: 'Liberty',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.0801,
    longitude: -96.4804,
    postal_code: '68381'
  },
  '68382': {
    country: 'US',
    city: 'Lorton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Otoe',
    latitude: 40.5973,
    longitude: -96.0241,
    postal_code: '68382'
  },
  '68401': {
    country: 'US',
    city: 'Mc Cool Junction',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'York',
    latitude: 40.7442,
    longitude: -97.5937,
    postal_code: '68401'
  },
  '68402': {
    country: 'US',
    city: 'Malcolm',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.9091,
    longitude: -96.86,
    postal_code: '68402'
  },
  '68403': {
    country: 'US',
    city: 'Manley',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.9207,
    longitude: -96.168,
    postal_code: '68403'
  },
  '68404': {
    country: 'US',
    city: 'Martell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.6514,
    longitude: -96.7442,
    postal_code: '68404'
  },
  '68405': {
    country: 'US',
    city: 'Milford',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Seward',
    latitude: 40.7632,
    longitude: -97.0576,
    postal_code: '68405'
  },
  '68406': {
    country: 'US',
    city: 'Milligan',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Fillmore',
    latitude: 40.4952,
    longitude: -97.3997,
    postal_code: '68406'
  },
  '68407': {
    country: 'US',
    city: 'Murdock',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.919,
    longitude: -96.2848,
    postal_code: '68407'
  },
  '68409': {
    country: 'US',
    city: 'Murray',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.92,
    longitude: -95.9227,
    postal_code: '68409'
  },
  '68410': {
    country: 'US',
    city: 'Nebraska City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Otoe',
    latitude: 40.6747,
    longitude: -95.8619,
    postal_code: '68410'
  },
  '68413': {
    country: 'US',
    city: 'Nehawka',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.833,
    longitude: -95.993,
    postal_code: '68413'
  },
  '68414': {
    country: 'US',
    city: 'Nemaha',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nemaha',
    latitude: 40.3198,
    longitude: -95.6913,
    postal_code: '68414'
  },
  '68415': {
    country: 'US',
    city: 'Odell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.0451,
    longitude: -96.8019,
    postal_code: '68415'
  },
  '68416': {
    country: 'US',
    city: 'Ohiowa',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Fillmore',
    latitude: 40.4065,
    longitude: -97.4427,
    postal_code: '68416'
  },
  '68417': {
    country: 'US',
    city: 'Otoe',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Otoe',
    latitude: 40.7355,
    longitude: -96.1329,
    postal_code: '68417'
  },
  '68418': {
    country: 'US',
    city: 'Palmyra',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Otoe',
    latitude: 40.705,
    longitude: -96.3999,
    postal_code: '68418'
  },
  '68419': {
    country: 'US',
    city: 'Panama',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.5998,
    longitude: -96.5129,
    postal_code: '68419'
  },
  '68420': {
    country: 'US',
    city: 'Pawnee City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pawnee',
    latitude: 40.1093,
    longitude: -96.1509,
    postal_code: '68420'
  },
  '68421': {
    country: 'US',
    city: 'Peru',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nemaha',
    latitude: 40.4766,
    longitude: -95.7312,
    postal_code: '68421'
  },
  '68422': {
    country: 'US',
    city: 'Pickrell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.3821,
    longitude: -96.7344,
    postal_code: '68422'
  },
  '68423': {
    country: 'US',
    city: 'Pleasant Dale',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Seward',
    latitude: 40.8133,
    longitude: -96.9513,
    postal_code: '68423'
  },
  '68424': {
    country: 'US',
    city: 'Plymouth',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Jefferson',
    latitude: 40.3039,
    longitude: -97.0012,
    postal_code: '68424'
  },
  '68428': {
    country: 'US',
    city: 'Raymond',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.9342,
    longitude: -96.7866,
    postal_code: '68428'
  },
  '68429': {
    country: 'US',
    city: 'Reynolds',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thayer',
    latitude: 40.0598,
    longitude: -97.3806,
    postal_code: '68429'
  },
  '68430': {
    country: 'US',
    city: 'Roca',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.6702,
    longitude: -96.6391,
    postal_code: '68430'
  },
  '68431': {
    country: 'US',
    city: 'Rulo',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Richardson',
    latitude: 40.0536,
    longitude: -95.4292,
    postal_code: '68431'
  },
  '68433': {
    country: 'US',
    city: 'Salem',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Richardson',
    latitude: 40.062,
    longitude: -95.7273,
    postal_code: '68433'
  },
  '68434': {
    country: 'US',
    city: 'Seward',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Seward',
    latitude: 40.9066,
    longitude: -97.0966,
    postal_code: '68434'
  },
  '68436': {
    country: 'US',
    city: 'Shickley',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Fillmore',
    latitude: 40.4077,
    longitude: -97.7143,
    postal_code: '68436'
  },
  '68437': {
    country: 'US',
    city: 'Shubert',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Richardson',
    latitude: 40.2325,
    longitude: -95.6895,
    postal_code: '68437'
  },
  '68438': {
    country: 'US',
    city: 'Sprague',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.6267,
    longitude: -96.7456,
    postal_code: '68438'
  },
  '68439': {
    country: 'US',
    city: 'Staplehurst',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Seward',
    latitude: 40.9845,
    longitude: -97.1859,
    postal_code: '68439'
  },
  '68440': {
    country: 'US',
    city: 'Steele City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Jefferson',
    latitude: 40.0378,
    longitude: -97.0248,
    postal_code: '68440'
  },
  '68441': {
    country: 'US',
    city: 'Steinauer',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pawnee',
    latitude: 40.2169,
    longitude: -96.2302,
    postal_code: '68441'
  },
  '68442': {
    country: 'US',
    city: 'Stella',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Richardson',
    latitude: 40.2303,
    longitude: -95.768,
    postal_code: '68442'
  },
  '68443': {
    country: 'US',
    city: 'Sterling',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Johnson',
    latitude: 40.4637,
    longitude: -96.3867,
    postal_code: '68443'
  },
  '68444': {
    country: 'US',
    city: 'Strang',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Fillmore',
    latitude: 40.4156,
    longitude: -97.587,
    postal_code: '68444'
  },
  '68445': {
    country: 'US',
    city: 'Swanton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saline',
    latitude: 40.3844,
    longitude: -97.0806,
    postal_code: '68445'
  },
  '68446': {
    country: 'US',
    city: 'Syracuse',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Otoe',
    latitude: 40.6614,
    longitude: -96.1824,
    postal_code: '68446'
  },
  '68447': {
    country: 'US',
    city: 'Table Rock',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pawnee',
    latitude: 40.1874,
    longitude: -96.0818,
    postal_code: '68447'
  },
  '68448': {
    country: 'US',
    city: 'Talmage',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Otoe',
    latitude: 40.5586,
    longitude: -96.0138,
    postal_code: '68448'
  },
  '68450': {
    country: 'US',
    city: 'Tecumseh',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Johnson',
    latitude: 40.3697,
    longitude: -96.205,
    postal_code: '68450'
  },
  '68452': {
    country: 'US',
    city: 'Ong',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.3968,
    longitude: -97.861,
    postal_code: '68452'
  },
  '68453': {
    country: 'US',
    city: 'Tobias',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saline',
    latitude: 40.4262,
    longitude: -97.3184,
    postal_code: '68453'
  },
  '68454': {
    country: 'US',
    city: 'Unadilla',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Otoe',
    latitude: 40.6917,
    longitude: -96.2825,
    postal_code: '68454'
  },
  '68455': {
    country: 'US',
    city: 'Union',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.8245,
    longitude: -95.9037,
    postal_code: '68455'
  },
  '68456': {
    country: 'US',
    city: 'Utica',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Seward',
    latitude: 40.9168,
    longitude: -97.3344,
    postal_code: '68456'
  },
  '68457': {
    country: 'US',
    city: 'Verdon',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Richardson',
    latitude: 40.1425,
    longitude: -95.7162,
    postal_code: '68457'
  },
  '68458': {
    country: 'US',
    city: 'Virginia',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.2309,
    longitude: -96.5121,
    postal_code: '68458'
  },
  '68460': {
    country: 'US',
    city: 'Waco',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'York',
    latitude: 40.9198,
    longitude: -97.4534,
    postal_code: '68460'
  },
  '68461': {
    country: 'US',
    city: 'Walton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7975,
    longitude: -96.5359,
    postal_code: '68461'
  },
  '68462': {
    country: 'US',
    city: 'Waverly',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.9222,
    longitude: -96.526,
    postal_code: '68462'
  },
  '68463': {
    country: 'US',
    city: 'Weeping Water',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cass',
    latitude: 40.8731,
    longitude: -96.1528,
    postal_code: '68463'
  },
  '68464': {
    country: 'US',
    city: 'Western',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saline',
    latitude: 40.4152,
    longitude: -97.1976,
    postal_code: '68464'
  },
  '68465': {
    country: 'US',
    city: 'Wilber',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saline',
    latitude: 40.4821,
    longitude: -96.9757,
    postal_code: '68465'
  },
  '68466': {
    country: 'US',
    city: 'Wymore',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gage',
    latitude: 40.113,
    longitude: -96.6613,
    postal_code: '68466'
  },
  '68467': {
    country: 'US',
    city: 'York',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'York',
    latitude: 40.8667,
    longitude: -97.5825,
    postal_code: '68467'
  },
  '68501': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8651,
    longitude: -96.8231,
    postal_code: '68501'
  },
  '68502': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7893,
    longitude: -96.6938,
    postal_code: '68502'
  },
  '68503': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8233,
    longitude: -96.6766,
    postal_code: '68503'
  },
  '68504': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8392,
    longitude: -96.6532,
    postal_code: '68504'
  },
  '68505': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8247,
    longitude: -96.6252,
    postal_code: '68505'
  },
  '68506': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7848,
    longitude: -96.6431,
    postal_code: '68506'
  },
  '68507': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8473,
    longitude: -96.6289,
    postal_code: '68507'
  },
  '68508': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8145,
    longitude: -96.7009,
    postal_code: '68508'
  },
  '68509': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7845,
    longitude: -96.6888,
    postal_code: '68509'
  },
  '68510': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8063,
    longitude: -96.6545,
    postal_code: '68510'
  },
  '68512': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7565,
    longitude: -96.6946,
    postal_code: '68512'
  },
  '68514': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.9401,
    longitude: -96.6621,
    postal_code: '68514'
  },
  '68516': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7568,
    longitude: -96.6523,
    postal_code: '68516'
  },
  '68517': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.9317,
    longitude: -96.6045,
    postal_code: '68517'
  },
  '68520': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7744,
    longitude: -96.5693,
    postal_code: '68520'
  },
  '68521': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.851,
    longitude: -96.711,
    postal_code: '68521'
  },
  '68522': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7934,
    longitude: -96.7479,
    postal_code: '68522'
  },
  '68523': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7408,
    longitude: -96.7583,
    postal_code: '68523'
  },
  '68524': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8529,
    longitude: -96.7943,
    postal_code: '68524'
  },
  '68526': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7314,
    longitude: -96.5878,
    postal_code: '68526'
  },
  '68527': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8347,
    longitude: -96.5401,
    postal_code: '68527'
  },
  '68528': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8195,
    longitude: -96.7545,
    postal_code: '68528'
  },
  '68529': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8798,
    longitude: -96.7798,
    postal_code: '68529'
  },
  '68531': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.9008,
    longitude: -96.7201,
    postal_code: '68531'
  },
  '68532': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7922,
    longitude: -96.8551,
    postal_code: '68532'
  },
  '68542': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7845,
    longitude: -96.6888,
    postal_code: '68542'
  },
  '68544': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7845,
    longitude: -96.6888,
    postal_code: '68544'
  },
  '68583': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.7845,
    longitude: -96.6888,
    postal_code: '68583'
  },
  '68588': {
    country: 'US',
    city: 'Lincoln',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lancaster',
    latitude: 40.8206,
    longitude: -96.6928,
    postal_code: '68588'
  },
  '68601': {
    country: 'US',
    city: 'Columbus',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Platte',
    latitude: 41.4297,
    longitude: -97.3684,
    postal_code: '68601'
  },
  '68602': {
    country: 'US',
    city: 'Columbus',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Platte',
    latitude: 41.4297,
    longitude: -97.3684,
    postal_code: '68602'
  },
  '68620': {
    country: 'US',
    city: 'Albion',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Boone',
    latitude: 41.7049,
    longitude: -97.9991,
    postal_code: '68620'
  },
  '68621': {
    country: 'US',
    city: 'Ames',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.4613,
    longitude: -96.6463,
    postal_code: '68621'
  },
  '68622': {
    country: 'US',
    city: 'Bartlett',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Wheeler',
    latitude: 41.8697,
    longitude: -98.5567,
    postal_code: '68622'
  },
  '68623': {
    country: 'US',
    city: 'Belgrade',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nance',
    latitude: 41.4611,
    longitude: -98.0866,
    postal_code: '68623'
  },
  '68624': {
    country: 'US',
    city: 'Bellwood',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Butler',
    latitude: 41.3474,
    longitude: -97.2747,
    postal_code: '68624'
  },
  '68626': {
    country: 'US',
    city: 'Brainard',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Butler',
    latitude: 41.1832,
    longitude: -96.9882,
    postal_code: '68626'
  },
  '68627': {
    country: 'US',
    city: 'Cedar Rapids',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Boone',
    latitude: 41.5564,
    longitude: -98.1554,
    postal_code: '68627'
  },
  '68628': {
    country: 'US',
    city: 'Clarks',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Merrick',
    latitude: 41.2328,
    longitude: -97.8461,
    postal_code: '68628'
  },
  '68629': {
    country: 'US',
    city: 'Clarkson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Colfax',
    latitude: 41.6961,
    longitude: -97.1051,
    postal_code: '68629'
  },
  '68631': {
    country: 'US',
    city: 'Creston',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Platte',
    latitude: 41.7067,
    longitude: -97.3625,
    postal_code: '68631'
  },
  '68632': {
    country: 'US',
    city: 'David City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Butler',
    latitude: 41.2528,
    longitude: -97.13,
    postal_code: '68632'
  },
  '68633': {
    country: 'US',
    city: 'Dodge',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.7052,
    longitude: -96.8969,
    postal_code: '68633'
  },
  '68634': {
    country: 'US',
    city: 'Duncan',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Platte',
    latitude: 41.3895,
    longitude: -97.4948,
    postal_code: '68634'
  },
  '68635': {
    country: 'US',
    city: 'Dwight',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Butler',
    latitude: 41.0894,
    longitude: -96.9931,
    postal_code: '68635'
  },
  '68636': {
    country: 'US',
    city: 'Elgin',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Antelope',
    latitude: 41.9732,
    longitude: -98.0751,
    postal_code: '68636'
  },
  '68637': {
    country: 'US',
    city: 'Ericson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Wheeler',
    latitude: 41.7828,
    longitude: -98.6453,
    postal_code: '68637'
  },
  '68638': {
    country: 'US',
    city: 'Fullerton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nance',
    latitude: 41.3633,
    longitude: -97.9692,
    postal_code: '68638'
  },
  '68640': {
    country: 'US',
    city: 'Genoa',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nance',
    latitude: 41.4468,
    longitude: -97.764,
    postal_code: '68640'
  },
  '68641': {
    country: 'US',
    city: 'Howells',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Colfax',
    latitude: 41.725,
    longitude: -97.0028,
    postal_code: '68641'
  },
  '68642': {
    country: 'US',
    city: 'Humphrey',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Platte',
    latitude: 41.6702,
    longitude: -97.4986,
    postal_code: '68642'
  },
  '68643': {
    country: 'US',
    city: 'Leigh',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Colfax',
    latitude: 41.7053,
    longitude: -97.2381,
    postal_code: '68643'
  },
  '68644': {
    country: 'US',
    city: 'Lindsay',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Platte',
    latitude: 41.6926,
    longitude: -97.6711,
    postal_code: '68644'
  },
  '68647': {
    country: 'US',
    city: 'Monroe',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Platte',
    latitude: 41.4783,
    longitude: -97.6061,
    postal_code: '68647'
  },
  '68648': {
    country: 'US',
    city: 'Morse Bluff',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Saunders',
    latitude: 41.4178,
    longitude: -96.7862,
    postal_code: '68648'
  },
  '68649': {
    country: 'US',
    city: 'North Bend',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.4689,
    longitude: -96.7813,
    postal_code: '68649'
  },
  '68651': {
    country: 'US',
    city: 'Osceola',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Polk',
    latitude: 41.1966,
    longitude: -97.5571,
    postal_code: '68651'
  },
  '68652': {
    country: 'US',
    city: 'Petersburg',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Boone',
    latitude: 41.859,
    longitude: -98.0848,
    postal_code: '68652'
  },
  '68653': {
    country: 'US',
    city: 'Platte Center',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Platte',
    latitude: 41.5375,
    longitude: -97.4884,
    postal_code: '68653'
  },
  '68654': {
    country: 'US',
    city: 'Polk',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Polk',
    latitude: 41.0767,
    longitude: -97.7839,
    postal_code: '68654'
  },
  '68655': {
    country: 'US',
    city: 'Primrose',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Boone',
    latitude: 41.6405,
    longitude: -98.2355,
    postal_code: '68655'
  },
  '68658': {
    country: 'US',
    city: 'Rising City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Butler',
    latitude: 41.2082,
    longitude: -97.3032,
    postal_code: '68658'
  },
  '68659': {
    country: 'US',
    city: 'Rogers',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Colfax',
    latitude: 41.4653,
    longitude: -96.915,
    postal_code: '68659'
  },
  '68660': {
    country: 'US',
    city: 'Saint Edward',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Boone',
    latitude: 41.5708,
    longitude: -97.8801,
    postal_code: '68660'
  },
  '68661': {
    country: 'US',
    city: 'Schuyler',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Colfax',
    latitude: 41.4591,
    longitude: -97.0628,
    postal_code: '68661'
  },
  '68662': {
    country: 'US',
    city: 'Shelby',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Polk',
    latitude: 41.1922,
    longitude: -97.4253,
    postal_code: '68662'
  },
  '68663': {
    country: 'US',
    city: 'Silver Creek',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Merrick',
    latitude: 41.319,
    longitude: -97.6671,
    postal_code: '68663'
  },
  '68664': {
    country: 'US',
    city: 'Snyder',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dodge',
    latitude: 41.7039,
    longitude: -96.7886,
    postal_code: '68664'
  },
  '68665': {
    country: 'US',
    city: 'Spalding',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Greeley',
    latitude: 41.6874,
    longitude: -98.3715,
    postal_code: '68665'
  },
  '68666': {
    country: 'US',
    city: 'Stromsburg',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Polk',
    latitude: 41.1118,
    longitude: -97.5742,
    postal_code: '68666'
  },
  '68667': {
    country: 'US',
    city: 'Surprise',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Butler',
    latitude: 41.1086,
    longitude: -97.3112,
    postal_code: '68667'
  },
  '68669': {
    country: 'US',
    city: 'Ulysses',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Butler',
    latitude: 41.0791,
    longitude: -97.1984,
    postal_code: '68669'
  },
  '68701': {
    country: 'US',
    city: 'Norfolk',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Madison',
    latitude: 42.0329,
    longitude: -97.4229,
    postal_code: '68701'
  },
  '68702': {
    country: 'US',
    city: 'Norfolk',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Madison',
    latitude: 42.0283,
    longitude: -97.417,
    postal_code: '68702'
  },
  '68710': {
    country: 'US',
    city: 'Allen',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dixon',
    latitude: 42.4437,
    longitude: -96.8574,
    postal_code: '68710'
  },
  '68711': {
    country: 'US',
    city: 'Amelia',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Holt',
    latitude: 42.1809,
    longitude: -99.008,
    postal_code: '68711'
  },
  '68713': {
    country: 'US',
    city: 'Atkinson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Holt',
    latitude: 42.5483,
    longitude: -98.9761,
    postal_code: '68713'
  },
  '68714': {
    country: 'US',
    city: 'Bassett',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Rock',
    latitude: 42.5763,
    longitude: -99.5387,
    postal_code: '68714'
  },
  '68715': {
    country: 'US',
    city: 'Battle Creek',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Madison',
    latitude: 41.9943,
    longitude: -97.5982,
    postal_code: '68715'
  },
  '68716': {
    country: 'US',
    city: 'Beemer',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cuming',
    latitude: 41.9374,
    longitude: -96.815,
    postal_code: '68716'
  },
  '68717': {
    country: 'US',
    city: 'Belden',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cedar',
    latitude: 42.4022,
    longitude: -97.1956,
    postal_code: '68717'
  },
  '68718': {
    country: 'US',
    city: 'Bloomfield',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Knox',
    latitude: 42.5978,
    longitude: -97.6545,
    postal_code: '68718'
  },
  '68719': {
    country: 'US',
    city: 'Bristow',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Boyd',
    latitude: 42.9484,
    longitude: -98.5721,
    postal_code: '68719'
  },
  '68720': {
    country: 'US',
    city: 'Brunswick',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Antelope',
    latitude: 42.3459,
    longitude: -98.0123,
    postal_code: '68720'
  },
  '68722': {
    country: 'US',
    city: 'Butte',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Boyd',
    latitude: 42.9124,
    longitude: -98.8459,
    postal_code: '68722'
  },
  '68723': {
    country: 'US',
    city: 'Carroll',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Wayne',
    latitude: 42.277,
    longitude: -97.1926,
    postal_code: '68723'
  },
  '68724': {
    country: 'US',
    city: 'Center',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Knox',
    latitude: 42.5973,
    longitude: -97.867,
    postal_code: '68724'
  },
  '68725': {
    country: 'US',
    city: 'Chambers',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Holt',
    latitude: 42.1916,
    longitude: -98.7378,
    postal_code: '68725'
  },
  '68726': {
    country: 'US',
    city: 'Clearwater',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Antelope',
    latitude: 42.1266,
    longitude: -98.1868,
    postal_code: '68726'
  },
  '68727': {
    country: 'US',
    city: 'Coleridge',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cedar',
    latitude: 42.5061,
    longitude: -97.2037,
    postal_code: '68727'
  },
  '68728': {
    country: 'US',
    city: 'Concord',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dixon',
    latitude: 42.382,
    longitude: -96.981,
    postal_code: '68728'
  },
  '68729': {
    country: 'US',
    city: 'Creighton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Knox',
    latitude: 42.4681,
    longitude: -97.8932,
    postal_code: '68729'
  },
  '68730': {
    country: 'US',
    city: 'Crofton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Knox',
    latitude: 42.737,
    longitude: -97.5406,
    postal_code: '68730'
  },
  '68731': {
    country: 'US',
    city: 'Dakota City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dakota',
    latitude: 42.3807,
    longitude: -96.4536,
    postal_code: '68731'
  },
  '68732': {
    country: 'US',
    city: 'Dixon',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dixon',
    latitude: 42.4185,
    longitude: -96.9774,
    postal_code: '68732'
  },
  '68733': {
    country: 'US',
    city: 'Emerson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dakota',
    latitude: 42.2852,
    longitude: -96.7159,
    postal_code: '68733'
  },
  '68734': {
    country: 'US',
    city: 'Emmet',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Holt',
    latitude: 42.4747,
    longitude: -98.8238,
    postal_code: '68734'
  },
  '68735': {
    country: 'US',
    city: 'Ewing',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Holt',
    latitude: 42.1851,
    longitude: -98.3981,
    postal_code: '68735'
  },
  '68736': {
    country: 'US',
    city: 'Fordyce',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cedar',
    latitude: 42.7309,
    longitude: -97.3567,
    postal_code: '68736'
  },
  '68738': {
    country: 'US',
    city: 'Hadar',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pierce',
    latitude: 42.105,
    longitude: -97.4492,
    postal_code: '68738'
  },
  '68739': {
    country: 'US',
    city: 'Hartington',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cedar',
    latitude: 42.6235,
    longitude: -97.2837,
    postal_code: '68739'
  },
  '68740': {
    country: 'US',
    city: 'Hoskins',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Wayne',
    latitude: 42.1408,
    longitude: -97.3084,
    postal_code: '68740'
  },
  '68741': {
    country: 'US',
    city: 'Hubbard',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dakota',
    latitude: 42.3576,
    longitude: -96.6031,
    postal_code: '68741'
  },
  '68742': {
    country: 'US',
    city: 'Inman',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Holt',
    latitude: 42.3761,
    longitude: -98.5384,
    postal_code: '68742'
  },
  '68743': {
    country: 'US',
    city: 'Jackson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dakota',
    latitude: 42.4529,
    longitude: -96.5743,
    postal_code: '68743'
  },
  '68745': {
    country: 'US',
    city: 'Laurel',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cedar',
    latitude: 42.427,
    longitude: -97.0874,
    postal_code: '68745'
  },
  '68746': {
    country: 'US',
    city: 'Lynch',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Boyd',
    latitude: 42.8373,
    longitude: -98.4504,
    postal_code: '68746'
  },
  '68747': {
    country: 'US',
    city: 'Mclean',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pierce',
    latitude: 42.3921,
    longitude: -97.4751,
    postal_code: '68747'
  },
  '68748': {
    country: 'US',
    city: 'Madison',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Madison',
    latitude: 41.8308,
    longitude: -97.472,
    postal_code: '68748'
  },
  '68749': {
    country: 'US',
    city: 'Magnet',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cedar',
    latitude: 42.455,
    longitude: -97.4701,
    postal_code: '68749'
  },
  '68751': {
    country: 'US',
    city: 'Maskell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dixon',
    latitude: 42.6905,
    longitude: -96.9809,
    postal_code: '68751'
  },
  '68752': {
    country: 'US',
    city: 'Meadow Grove',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Madison',
    latitude: 42.0102,
    longitude: -97.7334,
    postal_code: '68752'
  },
  '68753': {
    country: 'US',
    city: 'Mills',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Keya Paha',
    latitude: 42.9225,
    longitude: -99.4466,
    postal_code: '68753'
  },
  '68755': {
    country: 'US',
    city: 'Naper',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Boyd',
    latitude: 42.9521,
    longitude: -99.071,
    postal_code: '68755'
  },
  '68756': {
    country: 'US',
    city: 'Neligh',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Antelope',
    latitude: 42.1389,
    longitude: -98.0151,
    postal_code: '68756'
  },
  '68757': {
    country: 'US',
    city: 'Newcastle',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dixon',
    latitude: 42.6207,
    longitude: -96.8709,
    postal_code: '68757'
  },
  '68758': {
    country: 'US',
    city: 'Newman Grove',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Madison',
    latitude: 41.7498,
    longitude: -97.774,
    postal_code: '68758'
  },
  '68759': {
    country: 'US',
    city: 'Newport',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Keya Paha',
    latitude: 42.6001,
    longitude: -99.3359,
    postal_code: '68759'
  },
  '68760': {
    country: 'US',
    city: 'Niobrara',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Knox',
    latitude: 42.7391,
    longitude: -97.9248,
    postal_code: '68760'
  },
  '68761': {
    country: 'US',
    city: 'Oakdale',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Antelope',
    latitude: 42.0697,
    longitude: -97.9692,
    postal_code: '68761'
  },
  '68763': {
    country: 'US',
    city: 'Oneill',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Holt',
    latitude: 42.4857,
    longitude: -98.6456,
    postal_code: '68763'
  },
  '68764': {
    country: 'US',
    city: 'Orchard',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Antelope',
    latitude: 42.3399,
    longitude: -98.2409,
    postal_code: '68764'
  },
  '68765': {
    country: 'US',
    city: 'Osmond',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pierce',
    latitude: 42.3539,
    longitude: -97.582,
    postal_code: '68765'
  },
  '68766': {
    country: 'US',
    city: 'Page',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Holt',
    latitude: 42.4119,
    longitude: -98.3964,
    postal_code: '68766'
  },
  '68767': {
    country: 'US',
    city: 'Pierce',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pierce',
    latitude: 42.1943,
    longitude: -97.5256,
    postal_code: '68767'
  },
  '68768': {
    country: 'US',
    city: 'Pilger',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Stanton',
    latitude: 41.9761,
    longitude: -97.0779,
    postal_code: '68768'
  },
  '68769': {
    country: 'US',
    city: 'Plainview',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Pierce',
    latitude: 42.3467,
    longitude: -97.7786,
    postal_code: '68769'
  },
  '68770': {
    country: 'US',
    city: 'Ponca',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dixon',
    latitude: 42.5693,
    longitude: -96.7128,
    postal_code: '68770'
  },
  '68771': {
    country: 'US',
    city: 'Randolph',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cedar',
    latitude: 42.3798,
    longitude: -97.3464,
    postal_code: '68771'
  },
  '68773': {
    country: 'US',
    city: 'Royal',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Antelope',
    latitude: 42.3648,
    longitude: -98.1235,
    postal_code: '68773'
  },
  '68774': {
    country: 'US',
    city: 'Saint Helena',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cedar',
    latitude: 42.8046,
    longitude: -97.3047,
    postal_code: '68774'
  },
  '68776': {
    country: 'US',
    city: 'South Sioux City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dakota',
    latitude: 42.4656,
    longitude: -96.4182,
    postal_code: '68776'
  },
  '68777': {
    country: 'US',
    city: 'Spencer',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Boyd',
    latitude: 42.8849,
    longitude: -98.7059,
    postal_code: '68777'
  },
  '68778': {
    country: 'US',
    city: 'Springview',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Keya Paha',
    latitude: 42.8488,
    longitude: -99.8061,
    postal_code: '68778'
  },
  '68779': {
    country: 'US',
    city: 'Stanton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Stanton',
    latitude: 41.9076,
    longitude: -97.214,
    postal_code: '68779'
  },
  '68780': {
    country: 'US',
    city: 'Stuart',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Holt',
    latitude: 42.5713,
    longitude: -99.1396,
    postal_code: '68780'
  },
  '68781': {
    country: 'US',
    city: 'Tilden',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Madison',
    latitude: 42.0496,
    longitude: -97.8223,
    postal_code: '68781'
  },
  '68783': {
    country: 'US',
    city: 'Verdigre',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Knox',
    latitude: 42.6101,
    longitude: -98.0793,
    postal_code: '68783'
  },
  '68784': {
    country: 'US',
    city: 'Wakefield',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dixon',
    latitude: 42.2733,
    longitude: -96.8776,
    postal_code: '68784'
  },
  '68785': {
    country: 'US',
    city: 'Waterbury',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dixon',
    latitude: 42.4604,
    longitude: -96.7448,
    postal_code: '68785'
  },
  '68786': {
    country: 'US',
    city: 'Wausa',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Knox',
    latitude: 42.4973,
    longitude: -97.5576,
    postal_code: '68786'
  },
  '68787': {
    country: 'US',
    city: 'Wayne',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Wayne',
    latitude: 42.2304,
    longitude: -97.0186,
    postal_code: '68787'
  },
  '68788': {
    country: 'US',
    city: 'West Point',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cuming',
    latitude: 41.845,
    longitude: -96.7318,
    postal_code: '68788'
  },
  '68789': {
    country: 'US',
    city: 'Winnetoon',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Knox',
    latitude: 42.5334,
    longitude: -98.005,
    postal_code: '68789'
  },
  '68790': {
    country: 'US',
    city: 'Winside',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Wayne',
    latitude: 42.1678,
    longitude: -97.1825,
    postal_code: '68790'
  },
  '68791': {
    country: 'US',
    city: 'Wisner',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cuming',
    latitude: 41.998,
    longitude: -96.917,
    postal_code: '68791'
  },
  '68792': {
    country: 'US',
    city: 'Wynot',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cedar',
    latitude: 42.7393,
    longitude: -97.1678,
    postal_code: '68792'
  },
  '68801': {
    country: 'US',
    city: 'Grand Island',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hall',
    latitude: 40.9219,
    longitude: -98.3411,
    postal_code: '68801'
  },
  '68802': {
    country: 'US',
    city: 'Grand Island',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hall',
    latitude: 40.8725,
    longitude: -98.5021,
    postal_code: '68802'
  },
  '68803': {
    country: 'US',
    city: 'Grand Island',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hall',
    latitude: 40.9286,
    longitude: -98.3873,
    postal_code: '68803'
  },
  '68810': {
    country: 'US',
    city: 'Alda',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hall',
    latitude: 40.8711,
    longitude: -98.4681,
    postal_code: '68810'
  },
  '68812': {
    country: 'US',
    city: 'Amherst',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.8495,
    longitude: -99.2609,
    postal_code: '68812'
  },
  '68813': {
    country: 'US',
    city: 'Anselmo',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.6208,
    longitude: -99.8644,
    postal_code: '68813'
  },
  '68814': {
    country: 'US',
    city: 'Ansley',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.3019,
    longitude: -99.3645,
    postal_code: '68814'
  },
  '68815': {
    country: 'US',
    city: 'Arcadia',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Valley',
    latitude: 41.4293,
    longitude: -99.1205,
    postal_code: '68815'
  },
  '68816': {
    country: 'US',
    city: 'Archer',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Merrick',
    latitude: 41.1782,
    longitude: -98.1182,
    postal_code: '68816'
  },
  '68817': {
    country: 'US',
    city: 'Ashton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sherman',
    latitude: 41.2672,
    longitude: -98.8034,
    postal_code: '68817'
  },
  '68818': {
    country: 'US',
    city: 'Aurora',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hamilton',
    latitude: 40.8528,
    longitude: -98.0201,
    postal_code: '68818'
  },
  '68820': {
    country: 'US',
    city: 'Boelus',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Howard',
    latitude: 41.1003,
    longitude: -98.6976,
    postal_code: '68820'
  },
  '68821': {
    country: 'US',
    city: 'Brewster',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Blaine',
    latitude: 41.9467,
    longitude: -99.8292,
    postal_code: '68821'
  },
  '68822': {
    country: 'US',
    city: 'Broken Bow',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.4126,
    longitude: -99.6355,
    postal_code: '68822'
  },
  '68823': {
    country: 'US',
    city: 'Burwell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Garfield',
    latitude: 41.808,
    longitude: -99.0995,
    postal_code: '68823'
  },
  '68824': {
    country: 'US',
    city: 'Cairo',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hall',
    latitude: 41.0002,
    longitude: -98.6165,
    postal_code: '68824'
  },
  '68825': {
    country: 'US',
    city: 'Callaway',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.2485,
    longitude: -99.9932,
    postal_code: '68825'
  },
  '68826': {
    country: 'US',
    city: 'Central City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Merrick',
    latitude: 41.1213,
    longitude: -98.0017,
    postal_code: '68826'
  },
  '68827': {
    country: 'US',
    city: 'Chapman',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Merrick',
    latitude: 40.9856,
    longitude: -98.2217,
    postal_code: '68827'
  },
  '68828': {
    country: 'US',
    city: 'Comstock',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.5606,
    longitude: -99.2504,
    postal_code: '68828'
  },
  '68831': {
    country: 'US',
    city: 'Dannebrog',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Howard',
    latitude: 41.1192,
    longitude: -98.5546,
    postal_code: '68831'
  },
  '68832': {
    country: 'US',
    city: 'Doniphan',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hall',
    latitude: 40.77,
    longitude: -98.379,
    postal_code: '68832'
  },
  '68833': {
    country: 'US',
    city: 'Dunning',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Blaine',
    latitude: 41.8131,
    longitude: -100.0873,
    postal_code: '68833'
  },
  '68834': {
    country: 'US',
    city: 'Eddyville',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawson',
    latitude: 41.0079,
    longitude: -99.681,
    postal_code: '68834'
  },
  '68835': {
    country: 'US',
    city: 'Elba',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Howard',
    latitude: 41.2864,
    longitude: -98.5756,
    postal_code: '68835'
  },
  '68836': {
    country: 'US',
    city: 'Elm Creek',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.7301,
    longitude: -99.3728,
    postal_code: '68836'
  },
  '68837': {
    country: 'US',
    city: 'Elyria',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Valley',
    latitude: 41.6808,
    longitude: -99.0062,
    postal_code: '68837'
  },
  '68838': {
    country: 'US',
    city: 'Farwell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Howard',
    latitude: 41.2204,
    longitude: -98.6481,
    postal_code: '68838'
  },
  '68840': {
    country: 'US',
    city: 'Gibbon',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.7444,
    longitude: -98.8543,
    postal_code: '68840'
  },
  '68841': {
    country: 'US',
    city: 'Giltner',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hamilton',
    latitude: 40.7654,
    longitude: -98.1434,
    postal_code: '68841'
  },
  '68842': {
    country: 'US',
    city: 'Greeley',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Greeley',
    latitude: 41.5526,
    longitude: -98.53,
    postal_code: '68842'
  },
  '68843': {
    country: 'US',
    city: 'Hampton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hamilton',
    latitude: 40.9236,
    longitude: -97.8841,
    postal_code: '68843'
  },
  '68844': {
    country: 'US',
    city: 'Hazard',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sherman',
    latitude: 41.0934,
    longitude: -99.0719,
    postal_code: '68844'
  },
  '68845': {
    country: 'US',
    city: 'Kearney',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.7514,
    longitude: -99.1291,
    postal_code: '68845'
  },
  '68846': {
    country: 'US',
    city: 'Hordville',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hamilton',
    latitude: 41.0813,
    longitude: -97.8881,
    postal_code: '68846'
  },
  '68847': {
    country: 'US',
    city: 'Kearney',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.7136,
    longitude: -99.0779,
    postal_code: '68847'
  },
  '68848': {
    country: 'US',
    city: 'Kearney',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.6861,
    longitude: -99.0693,
    postal_code: '68848'
  },
  '68849': {
    country: 'US',
    city: 'Kearney',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.8497,
    longitude: -99.0741,
    postal_code: '68849'
  },
  '68850': {
    country: 'US',
    city: 'Lexington',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawson',
    latitude: 40.785,
    longitude: -99.7515,
    postal_code: '68850'
  },
  '68852': {
    country: 'US',
    city: 'Litchfield',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sherman',
    latitude: 41.1686,
    longitude: -99.1415,
    postal_code: '68852'
  },
  '68853': {
    country: 'US',
    city: 'Loup City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sherman',
    latitude: 41.2845,
    longitude: -98.9751,
    postal_code: '68853'
  },
  '68854': {
    country: 'US',
    city: 'Marquette',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hamilton',
    latitude: 41.01,
    longitude: -98,
    postal_code: '68854'
  },
  '68855': {
    country: 'US',
    city: 'Mason City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.1853,
    longitude: -99.3049,
    postal_code: '68855'
  },
  '68856': {
    country: 'US',
    city: 'Merna',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.4435,
    longitude: -99.8036,
    postal_code: '68856'
  },
  '68858': {
    country: 'US',
    city: 'Miller',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.9422,
    longitude: -99.374,
    postal_code: '68858'
  },
  '68859': {
    country: 'US',
    city: 'North Loup',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Valley',
    latitude: 41.4972,
    longitude: -98.7858,
    postal_code: '68859'
  },
  '68860': {
    country: 'US',
    city: 'Oconto',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.1411,
    longitude: -99.7632,
    postal_code: '68860'
  },
  '68861': {
    country: 'US',
    city: 'Odessa',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.7017,
    longitude: -99.2568,
    postal_code: '68861'
  },
  '68862': {
    country: 'US',
    city: 'Ord',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Valley',
    latitude: 41.5962,
    longitude: -98.9418,
    postal_code: '68862'
  },
  '68863': {
    country: 'US',
    city: 'Overton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawson',
    latitude: 40.7519,
    longitude: -99.5278,
    postal_code: '68863'
  },
  '68864': {
    country: 'US',
    city: 'Palmer',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Merrick',
    latitude: 41.1788,
    longitude: -98.2411,
    postal_code: '68864'
  },
  '68865': {
    country: 'US',
    city: 'Phillips',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hamilton',
    latitude: 40.8982,
    longitude: -98.2129,
    postal_code: '68865'
  },
  '68866': {
    country: 'US',
    city: 'Pleasanton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.9818,
    longitude: -99.1283,
    postal_code: '68866'
  },
  '68869': {
    country: 'US',
    city: 'Ravenna',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 41.0233,
    longitude: -98.9041,
    postal_code: '68869'
  },
  '68870': {
    country: 'US',
    city: 'Riverdale',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.8524,
    longitude: -99.1611,
    postal_code: '68870'
  },
  '68871': {
    country: 'US',
    city: 'Rockville',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sherman',
    latitude: 41.1108,
    longitude: -98.8578,
    postal_code: '68871'
  },
  '68872': {
    country: 'US',
    city: 'Saint Libory',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Howard',
    latitude: 41.0867,
    longitude: -98.3589,
    postal_code: '68872'
  },
  '68873': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Howard',
    latitude: 41.2242,
    longitude: -98.444,
    postal_code: '68873'
  },
  '68874': {
    country: 'US',
    city: 'Sargent',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.6508,
    longitude: -99.3816,
    postal_code: '68874'
  },
  '68875': {
    country: 'US',
    city: 'Scotia',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Greeley',
    latitude: 41.4837,
    longitude: -98.6893,
    postal_code: '68875'
  },
  '68876': {
    country: 'US',
    city: 'Shelton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Buffalo',
    latitude: 40.7717,
    longitude: -98.7435,
    postal_code: '68876'
  },
  '68878': {
    country: 'US',
    city: 'Sumner',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawson',
    latitude: 40.9504,
    longitude: -99.52,
    postal_code: '68878'
  },
  '68879': {
    country: 'US',
    city: 'Taylor',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Loup',
    latitude: 41.9141,
    longitude: -99.4543,
    postal_code: '68879'
  },
  '68881': {
    country: 'US',
    city: 'Westerville',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.4193,
    longitude: -99.3844,
    postal_code: '68881'
  },
  '68882': {
    country: 'US',
    city: 'Wolbach',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Greeley',
    latitude: 41.4244,
    longitude: -98.3995,
    postal_code: '68882'
  },
  '68883': {
    country: 'US',
    city: 'Wood River',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hall',
    latitude: 40.8106,
    longitude: -98.6065,
    postal_code: '68883'
  },
  '68901': {
    country: 'US',
    city: 'Hastings',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Adams',
    latitude: 40.5877,
    longitude: -98.3911,
    postal_code: '68901'
  },
  '68902': {
    country: 'US',
    city: 'Hastings',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Adams',
    latitude: 40.5896,
    longitude: -98.3972,
    postal_code: '68902'
  },
  '68920': {
    country: 'US',
    city: 'Alma',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Harlan',
    latitude: 40.1189,
    longitude: -99.3601,
    postal_code: '68920'
  },
  '68922': {
    country: 'US',
    city: 'Arapahoe',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Furnas',
    latitude: 40.3027,
    longitude: -99.8997,
    postal_code: '68922'
  },
  '68923': {
    country: 'US',
    city: 'Atlanta',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Phelps',
    latitude: 40.3667,
    longitude: -99.4732,
    postal_code: '68923'
  },
  '68924': {
    country: 'US',
    city: 'Axtell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Kearney',
    latitude: 40.5269,
    longitude: -99.1169,
    postal_code: '68924'
  },
  '68925': {
    country: 'US',
    city: 'Ayr',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Adams',
    latitude: 40.4411,
    longitude: -98.439,
    postal_code: '68925'
  },
  '68926': {
    country: 'US',
    city: 'Beaver City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Furnas',
    latitude: 40.129,
    longitude: -99.8065,
    postal_code: '68926'
  },
  '68927': {
    country: 'US',
    city: 'Bertrand',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Phelps',
    latitude: 40.5608,
    longitude: -99.5759,
    postal_code: '68927'
  },
  '68928': {
    country: 'US',
    city: 'Bladen',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Webster',
    latitude: 40.299,
    longitude: -98.6046,
    postal_code: '68928'
  },
  '68929': {
    country: 'US',
    city: 'Bloomington',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Franklin',
    latitude: 40.1383,
    longitude: -99.0094,
    postal_code: '68929'
  },
  '68930': {
    country: 'US',
    city: 'Blue Hill',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Webster',
    latitude: 40.311,
    longitude: -98.427,
    postal_code: '68930'
  },
  '68932': {
    country: 'US',
    city: 'Campbell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Franklin',
    latitude: 40.2967,
    longitude: -98.737,
    postal_code: '68932'
  },
  '68933': {
    country: 'US',
    city: 'Clay Center',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.5113,
    longitude: -98.0386,
    postal_code: '68933'
  },
  '68934': {
    country: 'US',
    city: 'Deweese',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.354,
    longitude: -98.14,
    postal_code: '68934'
  },
  '68935': {
    country: 'US',
    city: 'Edgar',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.3652,
    longitude: -97.9727,
    postal_code: '68935'
  },
  '68936': {
    country: 'US',
    city: 'Edison',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Furnas',
    latitude: 40.2802,
    longitude: -99.786,
    postal_code: '68936'
  },
  '68937': {
    country: 'US',
    city: 'Elwood',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gosper',
    latitude: 40.5747,
    longitude: -99.8258,
    postal_code: '68937'
  },
  '68938': {
    country: 'US',
    city: 'Fairfield',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.4285,
    longitude: -98.1063,
    postal_code: '68938'
  },
  '68939': {
    country: 'US',
    city: 'Franklin',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Franklin',
    latitude: 40.1052,
    longitude: -98.9469,
    postal_code: '68939'
  },
  '68940': {
    country: 'US',
    city: 'Funk',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Phelps',
    latitude: 40.502,
    longitude: -99.245,
    postal_code: '68940'
  },
  '68941': {
    country: 'US',
    city: 'Glenvil',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.4931,
    longitude: -98.2465,
    postal_code: '68941'
  },
  '68942': {
    country: 'US',
    city: 'Guide Rock',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Webster',
    latitude: 40.0812,
    longitude: -98.3391,
    postal_code: '68942'
  },
  '68943': {
    country: 'US',
    city: 'Hardy',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nuckolls',
    latitude: 40.0285,
    longitude: -97.9271,
    postal_code: '68943'
  },
  '68944': {
    country: 'US',
    city: 'Harvard',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.6265,
    longitude: -98.0846,
    postal_code: '68944'
  },
  '68945': {
    country: 'US',
    city: 'Heartwell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Kearney',
    latitude: 40.5716,
    longitude: -98.7851,
    postal_code: '68945'
  },
  '68946': {
    country: 'US',
    city: 'Hendley',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Furnas',
    latitude: 40.1063,
    longitude: -99.9715,
    postal_code: '68946'
  },
  '68947': {
    country: 'US',
    city: 'Hildreth',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Franklin',
    latitude: 40.3217,
    longitude: -99.0571,
    postal_code: '68947'
  },
  '68948': {
    country: 'US',
    city: 'Holbrook',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Furnas',
    latitude: 40.3019,
    longitude: -100.0136,
    postal_code: '68948'
  },
  '68949': {
    country: 'US',
    city: 'Holdrege',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Phelps',
    latitude: 40.4475,
    longitude: -99.3672,
    postal_code: '68949'
  },
  '68950': {
    country: 'US',
    city: 'Holstein',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Adams',
    latitude: 40.4542,
    longitude: -98.6538,
    postal_code: '68950'
  },
  '68952': {
    country: 'US',
    city: 'Inavale',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Webster',
    latitude: 40.0959,
    longitude: -98.6612,
    postal_code: '68952'
  },
  '68954': {
    country: 'US',
    city: 'Inland',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.5899,
    longitude: -98.2234,
    postal_code: '68954'
  },
  '68955': {
    country: 'US',
    city: 'Juniata',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Adams',
    latitude: 40.5867,
    longitude: -98.515,
    postal_code: '68955'
  },
  '68956': {
    country: 'US',
    city: 'Kenesaw',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Adams',
    latitude: 40.6165,
    longitude: -98.6572,
    postal_code: '68956'
  },
  '68957': {
    country: 'US',
    city: 'Lawrence',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nuckolls',
    latitude: 40.2767,
    longitude: -98.2402,
    postal_code: '68957'
  },
  '68958': {
    country: 'US',
    city: 'Loomis',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Phelps',
    latitude: 40.4792,
    longitude: -99.4979,
    postal_code: '68958'
  },
  '68959': {
    country: 'US',
    city: 'Minden',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Kearney',
    latitude: 40.5091,
    longitude: -98.9383,
    postal_code: '68959'
  },
  '68960': {
    country: 'US',
    city: 'Naponee',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Franklin',
    latitude: 40.1258,
    longitude: -99.1277,
    postal_code: '68960'
  },
  '68961': {
    country: 'US',
    city: 'Nelson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nuckolls',
    latitude: 40.2011,
    longitude: -98.0684,
    postal_code: '68961'
  },
  '68964': {
    country: 'US',
    city: 'Oak',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nuckolls',
    latitude: 40.2378,
    longitude: -97.903,
    postal_code: '68964'
  },
  '68966': {
    country: 'US',
    city: 'Orleans',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Harlan',
    latitude: 40.1484,
    longitude: -99.4572,
    postal_code: '68966'
  },
  '68967': {
    country: 'US',
    city: 'Oxford',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Furnas',
    latitude: 40.256,
    longitude: -99.6302,
    postal_code: '68967'
  },
  '68969': {
    country: 'US',
    city: 'Ragan',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Harlan',
    latitude: 40.3103,
    longitude: -99.2898,
    postal_code: '68969'
  },
  '68970': {
    country: 'US',
    city: 'Red Cloud',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Webster',
    latitude: 40.0952,
    longitude: -98.5187,
    postal_code: '68970'
  },
  '68971': {
    country: 'US',
    city: 'Republican City',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Harlan',
    latitude: 40.1034,
    longitude: -99.2322,
    postal_code: '68971'
  },
  '68972': {
    country: 'US',
    city: 'Riverton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Franklin',
    latitude: 40.1016,
    longitude: -98.7858,
    postal_code: '68972'
  },
  '68973': {
    country: 'US',
    city: 'Roseland',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Adams',
    latitude: 40.4591,
    longitude: -98.5551,
    postal_code: '68973'
  },
  '68974': {
    country: 'US',
    city: 'Ruskin',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nuckolls',
    latitude: 40.1383,
    longitude: -97.872,
    postal_code: '68974'
  },
  '68975': {
    country: 'US',
    city: 'Saronville',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.6021,
    longitude: -97.9388,
    postal_code: '68975'
  },
  '68976': {
    country: 'US',
    city: 'Smithfield',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Gosper',
    latitude: 40.5832,
    longitude: -99.7285,
    postal_code: '68976'
  },
  '68977': {
    country: 'US',
    city: 'Stamford',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Harlan',
    latitude: 40.1163,
    longitude: -99.5814,
    postal_code: '68977'
  },
  '68978': {
    country: 'US',
    city: 'Superior',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Nuckolls',
    latitude: 40.0315,
    longitude: -98.0779,
    postal_code: '68978'
  },
  '68979': {
    country: 'US',
    city: 'Sutton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.6056,
    longitude: -97.8592,
    postal_code: '68979'
  },
  '68980': {
    country: 'US',
    city: 'Trumbull',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Clay',
    latitude: 40.6795,
    longitude: -98.2734,
    postal_code: '68980'
  },
  '68981': {
    country: 'US',
    city: 'Upland',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Franklin',
    latitude: 40.3171,
    longitude: -98.8966,
    postal_code: '68981'
  },
  '68982': {
    country: 'US',
    city: 'Wilcox',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Kearney',
    latitude: 40.3735,
    longitude: -99.1539,
    postal_code: '68982'
  },
  '69001': {
    country: 'US',
    city: 'Mc Cook',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Red Willow',
    latitude: 40.2049,
    longitude: -100.6279,
    postal_code: '69001'
  },
  '69020': {
    country: 'US',
    city: 'Bartley',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Red Willow',
    latitude: 40.2581,
    longitude: -100.2908,
    postal_code: '69020'
  },
  '69021': {
    country: 'US',
    city: 'Benkelman',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dundy',
    latitude: 40.098,
    longitude: -101.5344,
    postal_code: '69021'
  },
  '69022': {
    country: 'US',
    city: 'Cambridge',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Furnas',
    latitude: 40.2808,
    longitude: -100.1676,
    postal_code: '69022'
  },
  '69023': {
    country: 'US',
    city: 'Champion',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Chase',
    latitude: 40.46,
    longitude: -101.7485,
    postal_code: '69023'
  },
  '69024': {
    country: 'US',
    city: 'Culbertson',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hitchcock',
    latitude: 40.2237,
    longitude: -100.85,
    postal_code: '69024'
  },
  '69025': {
    country: 'US',
    city: 'Curtis',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Frontier',
    latitude: 40.6131,
    longitude: -100.5104,
    postal_code: '69025'
  },
  '69026': {
    country: 'US',
    city: 'Danbury',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Red Willow',
    latitude: 40.0377,
    longitude: -100.4242,
    postal_code: '69026'
  },
  '69027': {
    country: 'US',
    city: 'Enders',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Chase',
    latitude: 40.4655,
    longitude: -101.5222,
    postal_code: '69027'
  },
  '69028': {
    country: 'US',
    city: 'Eustis',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Frontier',
    latitude: 40.6625,
    longitude: -100.0296,
    postal_code: '69028'
  },
  '69029': {
    country: 'US',
    city: 'Farnam',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawson',
    latitude: 40.7129,
    longitude: -100.2069,
    postal_code: '69029'
  },
  '69030': {
    country: 'US',
    city: 'Haigler',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dundy',
    latitude: 40.0642,
    longitude: -101.9371,
    postal_code: '69030'
  },
  '69032': {
    country: 'US',
    city: 'Hayes Center',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hayes',
    latitude: 40.5173,
    longitude: -101.0252,
    postal_code: '69032'
  },
  '69033': {
    country: 'US',
    city: 'Imperial',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Chase',
    latitude: 40.5251,
    longitude: -101.6468,
    postal_code: '69033'
  },
  '69034': {
    country: 'US',
    city: 'Indianola',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Red Willow',
    latitude: 40.2357,
    longitude: -100.4298,
    postal_code: '69034'
  },
  '69036': {
    country: 'US',
    city: 'Lebanon',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Red Willow',
    latitude: 40.0506,
    longitude: -100.2763,
    postal_code: '69036'
  },
  '69037': {
    country: 'US',
    city: 'Max',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dundy',
    latitude: 40.198,
    longitude: -101.4116,
    postal_code: '69037'
  },
  '69038': {
    country: 'US',
    city: 'Maywood',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Frontier',
    latitude: 40.6575,
    longitude: -100.6221,
    postal_code: '69038'
  },
  '69039': {
    country: 'US',
    city: 'Moorefield',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Frontier',
    latitude: 40.5786,
    longitude: -100.3108,
    postal_code: '69039'
  },
  '69040': {
    country: 'US',
    city: 'Palisade',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hitchcock',
    latitude: 40.3387,
    longitude: -101.1295,
    postal_code: '69040'
  },
  '69041': {
    country: 'US',
    city: 'Parks',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dundy',
    latitude: 40.1398,
    longitude: -101.7399,
    postal_code: '69041'
  },
  '69042': {
    country: 'US',
    city: 'Stockville',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Frontier',
    latitude: 40.4948,
    longitude: -100.3849,
    postal_code: '69042'
  },
  '69043': {
    country: 'US',
    city: 'Stratton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hitchcock',
    latitude: 40.1445,
    longitude: -101.2183,
    postal_code: '69043'
  },
  '69044': {
    country: 'US',
    city: 'Trenton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hitchcock',
    latitude: 40.1681,
    longitude: -101.0201,
    postal_code: '69044'
  },
  '69045': {
    country: 'US',
    city: 'Wauneta',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Chase',
    latitude: 40.4409,
    longitude: -101.3812,
    postal_code: '69045'
  },
  '69046': {
    country: 'US',
    city: 'Wilsonville',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Furnas',
    latitude: 40.1089,
    longitude: -100.1211,
    postal_code: '69046'
  },
  '69101': {
    country: 'US',
    city: 'North Platte',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lincoln',
    latitude: 41.1326,
    longitude: -100.7746,
    postal_code: '69101'
  },
  '69103': {
    country: 'US',
    city: 'North Platte',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lincoln',
    latitude: 41.0464,
    longitude: -100.7469,
    postal_code: '69103'
  },
  '69120': {
    country: 'US',
    city: 'Arnold',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Custer',
    latitude: 41.4456,
    longitude: -100.1567,
    postal_code: '69120'
  },
  '69121': {
    country: 'US',
    city: 'Arthur',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Arthur',
    latitude: 41.574,
    longitude: -101.6931,
    postal_code: '69121'
  },
  '69122': {
    country: 'US',
    city: 'Big Springs',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Deuel',
    latitude: 41.0696,
    longitude: -102.0933,
    postal_code: '69122'
  },
  '69123': {
    country: 'US',
    city: 'Brady',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lincoln',
    latitude: 41.0515,
    longitude: -100.3736,
    postal_code: '69123'
  },
  '69125': {
    country: 'US',
    city: 'Broadwater',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Morrill',
    latitude: 41.5825,
    longitude: -102.8225,
    postal_code: '69125'
  },
  '69127': {
    country: 'US',
    city: 'Brule',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Keith',
    latitude: 41.1002,
    longitude: -101.9099,
    postal_code: '69127'
  },
  '69128': {
    country: 'US',
    city: 'Bushnell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Kimball',
    latitude: 41.2139,
    longitude: -103.9075,
    postal_code: '69128'
  },
  '69129': {
    country: 'US',
    city: 'Chappell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Deuel',
    latitude: 41.0966,
    longitude: -102.4523,
    postal_code: '69129'
  },
  '69130': {
    country: 'US',
    city: 'Cozad',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawson',
    latitude: 40.8619,
    longitude: -99.9921,
    postal_code: '69130'
  },
  '69131': {
    country: 'US',
    city: 'Dalton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cheyenne',
    latitude: 41.4068,
    longitude: -102.9726,
    postal_code: '69131'
  },
  '69132': {
    country: 'US',
    city: 'Dickens',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lincoln',
    latitude: 40.8041,
    longitude: -101.0149,
    postal_code: '69132'
  },
  '69133': {
    country: 'US',
    city: 'Dix',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Kimball',
    latitude: 41.227,
    longitude: -103.4796,
    postal_code: '69133'
  },
  '69134': {
    country: 'US',
    city: 'Elsie',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Perkins',
    latitude: 40.8596,
    longitude: -101.37,
    postal_code: '69134'
  },
  '69135': {
    country: 'US',
    city: 'Elsmere',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cherry',
    latitude: 42.2403,
    longitude: -100.2595,
    postal_code: '69135'
  },
  '69138': {
    country: 'US',
    city: 'Gothenburg',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawson',
    latitude: 40.94,
    longitude: -100.1547,
    postal_code: '69138'
  },
  '69140': {
    country: 'US',
    city: 'Grant',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Perkins',
    latitude: 40.8511,
    longitude: -101.7196,
    postal_code: '69140'
  },
  '69141': {
    country: 'US',
    city: 'Gurley',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cheyenne',
    latitude: 41.315,
    longitude: -102.9835,
    postal_code: '69141'
  },
  '69142': {
    country: 'US',
    city: 'Halsey',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thomas',
    latitude: 41.9291,
    longitude: -100.2955,
    postal_code: '69142'
  },
  '69143': {
    country: 'US',
    city: 'Hershey',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lincoln',
    latitude: 41.2102,
    longitude: -101.0392,
    postal_code: '69143'
  },
  '69144': {
    country: 'US',
    city: 'Keystone',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Keith',
    latitude: 41.2621,
    longitude: -101.6282,
    postal_code: '69144'
  },
  '69145': {
    country: 'US',
    city: 'Kimball',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Kimball',
    latitude: 41.2321,
    longitude: -103.6602,
    postal_code: '69145'
  },
  '69146': {
    country: 'US',
    city: 'Lemoyne',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Keith',
    latitude: 41.3236,
    longitude: -101.7673,
    postal_code: '69146'
  },
  '69147': {
    country: 'US',
    city: 'Lewellen',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Garden',
    latitude: 41.3435,
    longitude: -102.1396,
    postal_code: '69147'
  },
  '69148': {
    country: 'US',
    city: 'Lisco',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Garden',
    latitude: 41.5114,
    longitude: -102.5498,
    postal_code: '69148'
  },
  '69149': {
    country: 'US',
    city: 'Lodgepole',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cheyenne',
    latitude: 41.1697,
    longitude: -102.657,
    postal_code: '69149'
  },
  '69150': {
    country: 'US',
    city: 'Madrid',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Perkins',
    latitude: 40.8544,
    longitude: -101.5371,
    postal_code: '69150'
  },
  '69151': {
    country: 'US',
    city: 'Maxwell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lincoln',
    latitude: 41.0588,
    longitude: -100.527,
    postal_code: '69151'
  },
  '69152': {
    country: 'US',
    city: 'Mullen',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Hooker',
    latitude: 42.0163,
    longitude: -101.0542,
    postal_code: '69152'
  },
  '69153': {
    country: 'US',
    city: 'Ogallala',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Keith',
    latitude: 41.1275,
    longitude: -101.7107,
    postal_code: '69153'
  },
  '69154': {
    country: 'US',
    city: 'Oshkosh',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Garden',
    latitude: 41.405,
    longitude: -102.3444,
    postal_code: '69154'
  },
  '69155': {
    country: 'US',
    city: 'Paxton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Keith',
    latitude: 41.1268,
    longitude: -101.3585,
    postal_code: '69155'
  },
  '69156': {
    country: 'US',
    city: 'Potter',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cheyenne',
    latitude: 41.2347,
    longitude: -103.3061,
    postal_code: '69156'
  },
  '69157': {
    country: 'US',
    city: 'Purdum',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Blaine',
    latitude: 42.065,
    longitude: -100.2585,
    postal_code: '69157'
  },
  '69160': {
    country: 'US',
    city: 'Sidney',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cheyenne',
    latitude: 41.2205,
    longitude: -102.9959,
    postal_code: '69160'
  },
  '69161': {
    country: 'US',
    city: 'Seneca',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thomas',
    latitude: 42.0433,
    longitude: -100.8329,
    postal_code: '69161'
  },
  '69162': {
    country: 'US',
    city: 'Sidney',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cheyenne',
    latitude: 41.138,
    longitude: -102.9856,
    postal_code: '69162'
  },
  '69163': {
    country: 'US',
    city: 'Stapleton',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Logan',
    latitude: 41.4888,
    longitude: -100.4831,
    postal_code: '69163'
  },
  '69165': {
    country: 'US',
    city: 'Sutherland',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lincoln',
    latitude: 41.1557,
    longitude: -101.136,
    postal_code: '69165'
  },
  '69166': {
    country: 'US',
    city: 'Thedford',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Thomas',
    latitude: 41.9794,
    longitude: -100.5574,
    postal_code: '69166'
  },
  '69167': {
    country: 'US',
    city: 'Tryon',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'McPherson',
    latitude: 41.5732,
    longitude: -101.0175,
    postal_code: '69167'
  },
  '69168': {
    country: 'US',
    city: 'Venango',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Perkins',
    latitude: 40.8073,
    longitude: -101.9839,
    postal_code: '69168'
  },
  '69169': {
    country: 'US',
    city: 'Wallace',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lincoln',
    latitude: 40.8304,
    longitude: -101.1738,
    postal_code: '69169'
  },
  '69170': {
    country: 'US',
    city: 'Wellfleet',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Lincoln',
    latitude: 40.7988,
    longitude: -100.7119,
    postal_code: '69170'
  },
  '69171': {
    country: 'US',
    city: 'Willow Island',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawson',
    latitude: 40.8925,
    longitude: -100.0703,
    postal_code: '69171'
  },
  '69190': {
    country: 'US',
    city: 'Oshkosh',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Garden',
    latitude: 41.405,
    longitude: -102.3444,
    postal_code: '69190'
  },
  '69201': {
    country: 'US',
    city: 'Valentine',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cherry',
    latitude: 42.8062,
    longitude: -100.6215,
    postal_code: '69201'
  },
  '69210': {
    country: 'US',
    city: 'Ainsworth',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Brown',
    latitude: 42.5403,
    longitude: -99.8615,
    postal_code: '69210'
  },
  '69211': {
    country: 'US',
    city: 'Cody',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cherry',
    latitude: 42.825,
    longitude: -101.2913,
    postal_code: '69211'
  },
  '69212': {
    country: 'US',
    city: 'Crookston',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cherry',
    latitude: 42.9255,
    longitude: -100.7735,
    postal_code: '69212'
  },
  '69214': {
    country: 'US',
    city: 'Johnstown',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Brown',
    latitude: 42.5717,
    longitude: -100.0582,
    postal_code: '69214'
  },
  '69216': {
    country: 'US',
    city: 'Kilgore',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cherry',
    latitude: 42.9146,
    longitude: -100.9884,
    postal_code: '69216'
  },
  '69217': {
    country: 'US',
    city: 'Long Pine',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Brown',
    latitude: 42.5364,
    longitude: -99.7007,
    postal_code: '69217'
  },
  '69218': {
    country: 'US',
    city: 'Merriman',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cherry',
    latitude: 42.6419,
    longitude: -101.7583,
    postal_code: '69218'
  },
  '69219': {
    country: 'US',
    city: 'Nenzel',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cherry',
    latitude: 42.9278,
    longitude: -101.1029,
    postal_code: '69219'
  },
  '69220': {
    country: 'US',
    city: 'Sparks',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cherry',
    latitude: 42.9411,
    longitude: -100.256,
    postal_code: '69220'
  },
  '69221': {
    country: 'US',
    city: 'Wood Lake',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Cherry',
    latitude: 42.6264,
    longitude: -100.2872,
    postal_code: '69221'
  },
  '69301': {
    country: 'US',
    city: 'Alliance',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Box Butte',
    latitude: 42.1149,
    longitude: -102.888,
    postal_code: '69301'
  },
  '69331': {
    country: 'US',
    city: 'Angora',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Morrill',
    latitude: 41.8934,
    longitude: -103.085,
    postal_code: '69331'
  },
  '69333': {
    country: 'US',
    city: 'Ashby',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Grant',
    latitude: 41.9776,
    longitude: -101.9636,
    postal_code: '69333'
  },
  '69334': {
    country: 'US',
    city: 'Bayard',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Morrill',
    latitude: 41.7579,
    longitude: -103.3019,
    postal_code: '69334'
  },
  '69335': {
    country: 'US',
    city: 'Bingham',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sheridan',
    latitude: 42.0211,
    longitude: -102.0885,
    postal_code: '69335'
  },
  '69336': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Morrill',
    latitude: 41.6766,
    longitude: -103.0701,
    postal_code: '69336'
  },
  '69337': {
    country: 'US',
    city: 'Chadron',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawes',
    latitude: 42.8193,
    longitude: -102.9953,
    postal_code: '69337'
  },
  '69339': {
    country: 'US',
    city: 'Crawford',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawes',
    latitude: 42.6758,
    longitude: -103.4053,
    postal_code: '69339'
  },
  '69340': {
    country: 'US',
    city: 'Ellsworth',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sheridan',
    latitude: 42.0586,
    longitude: -102.2807,
    postal_code: '69340'
  },
  '69341': {
    country: 'US',
    city: 'Gering',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Scotts Bluff',
    latitude: 41.822,
    longitude: -103.6629,
    postal_code: '69341'
  },
  '69343': {
    country: 'US',
    city: 'Gordon',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sheridan',
    latitude: 42.8068,
    longitude: -102.2049,
    postal_code: '69343'
  },
  '69345': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Banner',
    latitude: 41.5531,
    longitude: -103.7111,
    postal_code: '69345'
  },
  '69346': {
    country: 'US',
    city: 'Harrison',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sioux',
    latitude: 42.6872,
    longitude: -103.8827,
    postal_code: '69346'
  },
  '69347': {
    country: 'US',
    city: 'Hay Springs',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sheridan',
    latitude: 42.6401,
    longitude: -102.6756,
    postal_code: '69347'
  },
  '69348': {
    country: 'US',
    city: 'Hemingford',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Box Butte',
    latitude: 42.3312,
    longitude: -103.0644,
    postal_code: '69348'
  },
  '69350': {
    country: 'US',
    city: 'Hyannis',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Grant',
    latitude: 42.007,
    longitude: -101.7483,
    postal_code: '69350'
  },
  '69351': {
    country: 'US',
    city: 'Lakeside',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sheridan',
    latitude: 42.0496,
    longitude: -102.4437,
    postal_code: '69351'
  },
  '69352': {
    country: 'US',
    city: 'Lyman',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Scotts Bluff',
    latitude: 41.8918,
    longitude: -104.0066,
    postal_code: '69352'
  },
  '69353': {
    country: 'US',
    city: 'Mcgrew',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Scotts Bluff',
    latitude: 41.7469,
    longitude: -103.4164,
    postal_code: '69353'
  },
  '69354': {
    country: 'US',
    city: 'Marsland',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawes',
    latitude: 42.4439,
    longitude: -103.2985,
    postal_code: '69354'
  },
  '69355': {
    country: 'US',
    city: 'Melbeta',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Scotts Bluff',
    latitude: 41.7816,
    longitude: -103.5177,
    postal_code: '69355'
  },
  '69356': {
    country: 'US',
    city: 'Minatare',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Scotts Bluff',
    latitude: 41.8493,
    longitude: -103.489,
    postal_code: '69356'
  },
  '69357': {
    country: 'US',
    city: 'Mitchell',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Scotts Bluff',
    latitude: 41.9459,
    longitude: -103.796,
    postal_code: '69357'
  },
  '69358': {
    country: 'US',
    city: 'Morrill',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Scotts Bluff',
    latitude: 41.9681,
    longitude: -103.9182,
    postal_code: '69358'
  },
  '69360': {
    country: 'US',
    city: 'Rushville',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sheridan',
    latitude: 42.7379,
    longitude: -102.4657,
    postal_code: '69360'
  },
  '69361': {
    country: 'US',
    city: 'Scottsbluff',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Scotts Bluff',
    latitude: 41.872,
    longitude: -103.6619,
    postal_code: '69361'
  },
  '69363': {
    country: 'US',
    city: 'Scottsbluff',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Scotts Bluff',
    latitude: 41.8666,
    longitude: -103.6672,
    postal_code: '69363'
  },
  '69365': {
    country: 'US',
    city: 'Whiteclay',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Sheridan',
    latitude: 42.9969,
    longitude: -102.5546,
    postal_code: '69365'
  },
  '69366': {
    country: 'US',
    city: 'Whitman',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Grant',
    latitude: 41.9584,
    longitude: -101.5216,
    postal_code: '69366'
  },
  '69367': {
    country: 'US',
    city: 'Whitney',
    state: 'Nebraska',
    state_short: 'NE',
    county: 'Dawes',
    latitude: 42.7559,
    longitude: -103.2396,
    postal_code: '69367'
  },
  '70001': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9871,
    longitude: -90.1695,
    postal_code: '70001'
  },
  '70002': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 30.0098,
    longitude: -90.163,
    postal_code: '70002'
  },
  '70003': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9975,
    longitude: -90.2146,
    postal_code: '70003'
  },
  '70004': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9841,
    longitude: -90.1529,
    postal_code: '70004'
  },
  '70005': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 30.0005,
    longitude: -90.1331,
    postal_code: '70005'
  },
  '70006': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 30.0129,
    longitude: -90.1915,
    postal_code: '70006'
  },
  '70009': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9841,
    longitude: -90.1529,
    postal_code: '70009'
  },
  '70010': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9841,
    longitude: -90.1529,
    postal_code: '70010'
  },
  '70011': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9841,
    longitude: -90.1529,
    postal_code: '70011'
  },
  '70030': {
    country: 'US',
    city: 'Des Allemands',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Charles',
    latitude: 29.8134,
    longitude: -90.4407,
    postal_code: '70030'
  },
  '70031': {
    country: 'US',
    city: 'Ama',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Charles',
    latitude: 29.9435,
    longitude: -90.2925,
    postal_code: '70031'
  },
  '70032': {
    country: 'US',
    city: 'Arabi',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Bernard',
    latitude: 29.9612,
    longitude: -89.9965,
    postal_code: '70032'
  },
  '70033': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9841,
    longitude: -90.1529,
    postal_code: '70033'
  },
  '70036': {
    country: 'US',
    city: 'Barataria',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.6964,
    longitude: -90.1122,
    postal_code: '70036'
  },
  '70037': {
    country: 'US',
    city: 'Belle Chasse',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Plaquemines Parish',
    latitude: 29.8345,
    longitude: -90.0042,
    postal_code: '70037'
  },
  '70038': {
    country: 'US',
    city: 'Boothville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Plaquemines Parish',
    latitude: 29.3079,
    longitude: -89.3814,
    postal_code: '70038'
  },
  '70039': {
    country: 'US',
    city: 'Boutte',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Charles',
    latitude: 29.8973,
    longitude: -90.3934,
    postal_code: '70039'
  },
  '70040': {
    country: 'US',
    city: 'Braithwaite',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Plaquemines Parish',
    latitude: 29.7361,
    longitude: -89.9197,
    postal_code: '70040'
  },
  '70041': {
    country: 'US',
    city: 'Buras',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Plaquemines Parish',
    latitude: 29.3519,
    longitude: -89.5242,
    postal_code: '70041'
  },
  '70043': {
    country: 'US',
    city: 'Chalmette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Bernard',
    latitude: 29.9466,
    longitude: -89.9611,
    postal_code: '70043'
  },
  '70044': {
    country: 'US',
    city: 'Chalmette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Bernard',
    latitude: 29.9676,
    longitude: -89.9514,
    postal_code: '70044'
  },
  '70047': {
    country: 'US',
    city: 'Destrehan',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Charles',
    latitude: 29.9642,
    longitude: -90.3682,
    postal_code: '70047'
  },
  '70049': {
    country: 'US',
    city: 'Edgard',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. John the Baptist',
    latitude: 30.0319,
    longitude: -90.5817,
    postal_code: '70049'
  },
  '70050': {
    country: 'US',
    city: 'Empire',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Plaquemines Parish',
    latitude: 29.3814,
    longitude: -89.5926,
    postal_code: '70050'
  },
  '70051': {
    country: 'US',
    city: 'Garyville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. John the Baptist',
    latitude: 30.0535,
    longitude: -90.6201,
    postal_code: '70051'
  },
  '70052': {
    country: 'US',
    city: 'Gramercy',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. James',
    latitude: 30.0527,
    longitude: -90.6902,
    postal_code: '70052'
  },
  '70053': {
    country: 'US',
    city: 'Gretna',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9108,
    longitude: -90.0531,
    postal_code: '70053'
  },
  '70054': {
    country: 'US',
    city: 'Gretna',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9146,
    longitude: -90.054,
    postal_code: '70054'
  },
  '70055': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9841,
    longitude: -90.1529,
    postal_code: '70055'
  },
  '70056': {
    country: 'US',
    city: 'Gretna',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.8872,
    longitude: -90.0331,
    postal_code: '70056'
  },
  '70057': {
    country: 'US',
    city: 'Hahnville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Charles',
    latitude: 29.9668,
    longitude: -90.4082,
    postal_code: '70057'
  },
  '70058': {
    country: 'US',
    city: 'Harvey',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.8725,
    longitude: -90.0673,
    postal_code: '70058'
  },
  '70059': {
    country: 'US',
    city: 'Harvey',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9035,
    longitude: -90.0773,
    postal_code: '70059'
  },
  '70060': {
    country: 'US',
    city: 'Metairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9841,
    longitude: -90.1529,
    postal_code: '70060'
  },
  '70062': {
    country: 'US',
    city: 'Kenner',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9912,
    longitude: -90.2479,
    postal_code: '70062'
  },
  '70063': {
    country: 'US',
    city: 'Kenner',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9941,
    longitude: -90.2417,
    postal_code: '70063'
  },
  '70064': {
    country: 'US',
    city: 'Kenner',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9941,
    longitude: -90.2417,
    postal_code: '70064'
  },
  '70065': {
    country: 'US',
    city: 'Kenner',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 30.0252,
    longitude: -90.2522,
    postal_code: '70065'
  },
  '70067': {
    country: 'US',
    city: 'Lafitte',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.7143,
    longitude: -90.1052,
    postal_code: '70067'
  },
  '70068': {
    country: 'US',
    city: 'La Place',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. John the Baptist',
    latitude: 30.0777,
    longitude: -90.4895,
    postal_code: '70068'
  },
  '70069': {
    country: 'US',
    city: 'La Place',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. John the Baptist',
    latitude: 30.0912,
    longitude: -90.4832,
    postal_code: '70069'
  },
  '70070': {
    country: 'US',
    city: 'Luling',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Charles',
    latitude: 29.9251,
    longitude: -90.3693,
    postal_code: '70070'
  },
  '70071': {
    country: 'US',
    city: 'Lutcher',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. James',
    latitude: 30.0447,
    longitude: -90.7008,
    postal_code: '70071'
  },
  '70072': {
    country: 'US',
    city: 'Marrero',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.8598,
    longitude: -90.1105,
    postal_code: '70072'
  },
  '70073': {
    country: 'US',
    city: 'Marrero',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.8994,
    longitude: -90.1004,
    postal_code: '70073'
  },
  '70075': {
    country: 'US',
    city: 'Meraux',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Bernard',
    latitude: 29.9335,
    longitude: -89.9214,
    postal_code: '70075'
  },
  '70076': {
    country: 'US',
    city: 'Mount Airy',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. John the Baptist',
    latitude: 30.0575,
    longitude: -90.6369,
    postal_code: '70076'
  },
  '70078': {
    country: 'US',
    city: 'New Sarpy',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Charles',
    latitude: 29.9817,
    longitude: -90.3859,
    postal_code: '70078'
  },
  '70079': {
    country: 'US',
    city: 'Norco',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Charles',
    latitude: 30.0057,
    longitude: -90.4198,
    postal_code: '70079'
  },
  '70080': {
    country: 'US',
    city: 'Paradis',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Charles',
    latitude: 29.877,
    longitude: -90.4352,
    postal_code: '70080'
  },
  '70081': {
    country: 'US',
    city: 'Pilottown',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Plaquemines Parish',
    latitude: 29.1816,
    longitude: -89.2576,
    postal_code: '70081'
  },
  '70082': {
    country: 'US',
    city: 'Pointe A La Hache',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Plaquemines Parish',
    latitude: 29.542,
    longitude: -89.7512,
    postal_code: '70082'
  },
  '70083': {
    country: 'US',
    city: 'Port Sulphur',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Plaquemines Parish',
    latitude: 29.47,
    longitude: -89.6847,
    postal_code: '70083'
  },
  '70084': {
    country: 'US',
    city: 'Reserve',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. John the Baptist',
    latitude: 30.0603,
    longitude: -90.5518,
    postal_code: '70084'
  },
  '70085': {
    country: 'US',
    city: 'Saint Bernard',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Bernard',
    latitude: 29.8611,
    longitude: -89.8364,
    postal_code: '70085'
  },
  '70086': {
    country: 'US',
    city: 'Saint James',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. James',
    latitude: 30.0276,
    longitude: -90.8605,
    postal_code: '70086'
  },
  '70087': {
    country: 'US',
    city: 'Saint Rose',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Charles',
    latitude: 29.9581,
    longitude: -90.3124,
    postal_code: '70087'
  },
  '70090': {
    country: 'US',
    city: 'Vacherie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. James',
    latitude: 29.9694,
    longitude: -90.7097,
    postal_code: '70090'
  },
  '70091': {
    country: 'US',
    city: 'Venice',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Plaquemines Parish',
    latitude: 29.2659,
    longitude: -89.3761,
    postal_code: '70091'
  },
  '70092': {
    country: 'US',
    city: 'Violet',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Bernard',
    latitude: 29.9043,
    longitude: -89.896,
    postal_code: '70092'
  },
  '70093': {
    country: 'US',
    city: 'Belle Chasse',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Plaquemines Parish',
    latitude: 29.8549,
    longitude: -89.9906,
    postal_code: '70093'
  },
  '70094': {
    country: 'US',
    city: 'Westwego',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.8973,
    longitude: -90.2033,
    postal_code: '70094'
  },
  '70096': {
    country: 'US',
    city: 'Westwego',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.906,
    longitude: -90.1423,
    postal_code: '70096'
  },
  '70097': {
    country: 'US',
    city: 'Kenner',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9565,
    longitude: -90.2635,
    postal_code: '70097'
  },
  '70112': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9605,
    longitude: -90.0753,
    postal_code: '70112'
  },
  '70113': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9405,
    longitude: -90.0848,
    postal_code: '70113'
  },
  '70114': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9379,
    longitude: -90.0331,
    postal_code: '70114'
  },
  '70115': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9289,
    longitude: -90.1005,
    postal_code: '70115'
  },
  '70116': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9686,
    longitude: -90.0646,
    postal_code: '70116'
  },
  '70117': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9703,
    longitude: -90.0312,
    postal_code: '70117'
  },
  '70118': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9504,
    longitude: -90.1236,
    postal_code: '70118'
  },
  '70119': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9746,
    longitude: -90.0852,
    postal_code: '70119'
  },
  '70121': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9614,
    longitude: -90.1577,
    postal_code: '70121'
  },
  '70122': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 30.0056,
    longitude: -90.0644,
    postal_code: '70122'
  },
  '70123': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9511,
    longitude: -90.206,
    postal_code: '70123'
  },
  '70124': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 30.0071,
    longitude: -90.1094,
    postal_code: '70124'
  },
  '70125': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9512,
    longitude: -90.1028,
    postal_code: '70125'
  },
  '70126': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9987,
    longitude: -90.0446,
    postal_code: '70126'
  },
  '70127': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 30.0313,
    longitude: -89.9758,
    postal_code: '70127'
  },
  '70128': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 30.0487,
    longitude: -89.9585,
    postal_code: '70128'
  },
  '70129': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 30.0877,
    longitude: -89.8462,
    postal_code: '70129'
  },
  '70130': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9324,
    longitude: -90.0739,
    postal_code: '70130'
  },
  '70131': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9168,
    longitude: -89.996,
    postal_code: '70131'
  },
  '70139': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70139'
  },
  '70141': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70141'
  },
  '70142': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70142'
  },
  '70143': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70143'
  },
  '70145': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70145'
  },
  '70146': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70146'
  },
  '70148': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 30.0309,
    longitude: -90.068,
    postal_code: '70148'
  },
  '70150': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70150'
  },
  '70151': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70151'
  },
  '70152': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70152'
  },
  '70153': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70153'
  },
  '70154': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70154'
  },
  '70156': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70156'
  },
  '70157': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70157'
  },
  '70158': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9229,
    longitude: -90.0709,
    postal_code: '70158'
  },
  '70159': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70159'
  },
  '70160': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70160'
  },
  '70161': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70161'
  },
  '70162': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70162'
  },
  '70163': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.95,
    longitude: -90.0755,
    postal_code: '70163'
  },
  '70164': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70164'
  },
  '70165': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70165'
  },
  '70166': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70166'
  },
  '70167': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70167'
  },
  '70170': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70170'
  },
  '70172': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70172'
  },
  '70174': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70174'
  },
  '70175': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70175'
  },
  '70176': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70176'
  },
  '70177': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70177'
  },
  '70178': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70178'
  },
  '70179': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70179'
  },
  '70181': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.6779,
    longitude: -90.0901,
    postal_code: '70181'
  },
  '70182': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70182'
  },
  '70183': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70183'
  },
  '70184': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70184'
  },
  '70185': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70185'
  },
  '70186': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70186'
  },
  '70187': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70187'
  },
  '70189': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70189'
  },
  '70190': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70190'
  },
  '70195': {
    country: 'US',
    city: 'New Orleans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Orleans',
    latitude: 29.9546,
    longitude: -90.0751,
    postal_code: '70195'
  },
  '70301': {
    country: 'US',
    city: 'Thibodaux',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.7992,
    longitude: -90.8096,
    postal_code: '70301'
  },
  '70302': {
    country: 'US',
    city: 'Thibodaux',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.7958,
    longitude: -90.8229,
    postal_code: '70302'
  },
  '70310': {
    country: 'US',
    city: 'Thibodaux',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.8033,
    longitude: -90.8169,
    postal_code: '70310'
  },
  '70339': {
    country: 'US',
    city: 'Pierre Part',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Assumption',
    latitude: 29.9476,
    longitude: -91.1885,
    postal_code: '70339'
  },
  '70340': {
    country: 'US',
    city: 'Amelia',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Mary',
    latitude: 29.6663,
    longitude: -91.102,
    postal_code: '70340'
  },
  '70341': {
    country: 'US',
    city: 'Belle Rose',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Assumption',
    latitude: 30.026,
    longitude: -91.0444,
    postal_code: '70341'
  },
  '70342': {
    country: 'US',
    city: 'Berwick',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Mary',
    latitude: 29.6929,
    longitude: -91.2518,
    postal_code: '70342'
  },
  '70343': {
    country: 'US',
    city: 'Bourg',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.5485,
    longitude: -90.6087,
    postal_code: '70343'
  },
  '70344': {
    country: 'US',
    city: 'Chauvin',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.4634,
    longitude: -90.598,
    postal_code: '70344'
  },
  '70345': {
    country: 'US',
    city: 'Cut Off',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.5232,
    longitude: -90.3393,
    postal_code: '70345'
  },
  '70346': {
    country: 'US',
    city: 'Donaldsonville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.1018,
    longitude: -90.997,
    postal_code: '70346'
  },
  '70352': {
    country: 'US',
    city: 'Donner',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.6964,
    longitude: -90.9444,
    postal_code: '70352'
  },
  '70353': {
    country: 'US',
    city: 'Dulac',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.3695,
    longitude: -90.697,
    postal_code: '70353'
  },
  '70354': {
    country: 'US',
    city: 'Galliano',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.4311,
    longitude: -90.2981,
    postal_code: '70354'
  },
  '70355': {
    country: 'US',
    city: 'Gheens',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.7076,
    longitude: -90.4849,
    postal_code: '70355'
  },
  '70356': {
    country: 'US',
    city: 'Gibson',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.6625,
    longitude: -90.9776,
    postal_code: '70356'
  },
  '70357': {
    country: 'US',
    city: 'Golden Meadow',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.3821,
    longitude: -90.2639,
    postal_code: '70357'
  },
  '70358': {
    country: 'US',
    city: 'Grand Isle',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Parish',
    latitude: 29.2297,
    longitude: -90.0088,
    postal_code: '70358'
  },
  '70359': {
    country: 'US',
    city: 'Gray',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.6903,
    longitude: -90.7807,
    postal_code: '70359'
  },
  '70360': {
    country: 'US',
    city: 'Houma',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.5943,
    longitude: -90.7548,
    postal_code: '70360'
  },
  '70361': {
    country: 'US',
    city: 'Houma',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.5958,
    longitude: -90.7195,
    postal_code: '70361'
  },
  '70363': {
    country: 'US',
    city: 'Houma',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.5601,
    longitude: -90.6917,
    postal_code: '70363'
  },
  '70364': {
    country: 'US',
    city: 'Houma',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.6299,
    longitude: -90.7268,
    postal_code: '70364'
  },
  '70371': {
    country: 'US',
    city: 'Kraemer',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.8652,
    longitude: -90.5962,
    postal_code: '70371'
  },
  '70372': {
    country: 'US',
    city: 'Labadieville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Assumption',
    latitude: 29.8345,
    longitude: -90.9613,
    postal_code: '70372'
  },
  '70373': {
    country: 'US',
    city: 'Larose',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.5724,
    longitude: -90.3817,
    postal_code: '70373'
  },
  '70374': {
    country: 'US',
    city: 'Lockport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.6155,
    longitude: -90.4442,
    postal_code: '70374'
  },
  '70375': {
    country: 'US',
    city: 'Mathews',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.6938,
    longitude: -90.5496,
    postal_code: '70375'
  },
  '70377': {
    country: 'US',
    city: 'Montegut',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.4744,
    longitude: -90.544,
    postal_code: '70377'
  },
  '70380': {
    country: 'US',
    city: 'Morgan City',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Mary',
    latitude: 29.6977,
    longitude: -91.2654,
    postal_code: '70380'
  },
  '70381': {
    country: 'US',
    city: 'Morgan City',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Mary',
    latitude: 29.6946,
    longitude: -91.2593,
    postal_code: '70381'
  },
  '70390': {
    country: 'US',
    city: 'Napoleonville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Assumption',
    latitude: 29.9288,
    longitude: -91.0266,
    postal_code: '70390'
  },
  '70391': {
    country: 'US',
    city: 'Paincourtville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Assumption',
    latitude: 29.9902,
    longitude: -91.0588,
    postal_code: '70391'
  },
  '70392': {
    country: 'US',
    city: 'Patterson',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Mary',
    latitude: 29.6967,
    longitude: -91.2812,
    postal_code: '70392'
  },
  '70393': {
    country: 'US',
    city: 'Plattenville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Assumption',
    latitude: 29.9974,
    longitude: -91.0232,
    postal_code: '70393'
  },
  '70394': {
    country: 'US',
    city: 'Raceland',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafourche',
    latitude: 29.7178,
    longitude: -90.5999,
    postal_code: '70394'
  },
  '70395': {
    country: 'US',
    city: 'Schriever',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.7123,
    longitude: -90.8513,
    postal_code: '70395'
  },
  '70397': {
    country: 'US',
    city: 'Theriot',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Terrebonne',
    latitude: 29.4516,
    longitude: -90.7651,
    postal_code: '70397'
  },
  '70401': {
    country: 'US',
    city: 'Hammond',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.5191,
    longitude: -90.4879,
    postal_code: '70401'
  },
  '70402': {
    country: 'US',
    city: 'Hammond',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.514,
    longitude: -90.4804,
    postal_code: '70402'
  },
  '70403': {
    country: 'US',
    city: 'Hammond',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.4911,
    longitude: -90.4697,
    postal_code: '70403'
  },
  '70404': {
    country: 'US',
    city: 'Hammond',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.5046,
    longitude: -90.4629,
    postal_code: '70404'
  },
  '70420': {
    country: 'US',
    city: 'Abita Springs',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.4837,
    longitude: -90.0041,
    postal_code: '70420'
  },
  '70421': {
    country: 'US',
    city: 'Akers',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.2902,
    longitude: -90.4018,
    postal_code: '70421'
  },
  '70422': {
    country: 'US',
    city: 'Amite',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.7266,
    longitude: -90.509,
    postal_code: '70422'
  },
  '70426': {
    country: 'US',
    city: 'Angie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Washington',
    latitude: 30.9224,
    longitude: -89.8567,
    postal_code: '70426'
  },
  '70427': {
    country: 'US',
    city: 'Bogalusa',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Washington',
    latitude: 30.7733,
    longitude: -89.8653,
    postal_code: '70427'
  },
  '70429': {
    country: 'US',
    city: 'Bogalusa',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Washington',
    latitude: 30.791,
    longitude: -89.8487,
    postal_code: '70429'
  },
  '70431': {
    country: 'US',
    city: 'Bush',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.6134,
    longitude: -89.9557,
    postal_code: '70431'
  },
  '70433': {
    country: 'US',
    city: 'Covington',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.4876,
    longitude: -90.0959,
    postal_code: '70433'
  },
  '70434': {
    country: 'US',
    city: 'Covington',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.4755,
    longitude: -90.1004,
    postal_code: '70434'
  },
  '70435': {
    country: 'US',
    city: 'Covington',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.5661,
    longitude: -90.1098,
    postal_code: '70435'
  },
  '70436': {
    country: 'US',
    city: 'Fluker',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.8128,
    longitude: -90.5207,
    postal_code: '70436'
  },
  '70437': {
    country: 'US',
    city: 'Folsom',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.6145,
    longitude: -90.1879,
    postal_code: '70437'
  },
  '70438': {
    country: 'US',
    city: 'Franklinton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Washington',
    latitude: 30.8577,
    longitude: -90.1155,
    postal_code: '70438'
  },
  '70441': {
    country: 'US',
    city: 'Greensburg',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Helena',
    latitude: 30.8647,
    longitude: -90.7256,
    postal_code: '70441'
  },
  '70442': {
    country: 'US',
    city: 'Husser',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.692,
    longitude: -90.339,
    postal_code: '70442'
  },
  '70443': {
    country: 'US',
    city: 'Independence',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.6351,
    longitude: -90.5277,
    postal_code: '70443'
  },
  '70444': {
    country: 'US',
    city: 'Kentwood',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.8892,
    longitude: -90.4728,
    postal_code: '70444'
  },
  '70445': {
    country: 'US',
    city: 'Lacombe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.322,
    longitude: -89.9297,
    postal_code: '70445'
  },
  '70446': {
    country: 'US',
    city: 'Loranger',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.5884,
    longitude: -90.3567,
    postal_code: '70446'
  },
  '70447': {
    country: 'US',
    city: 'Madisonville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.4287,
    longitude: -90.1773,
    postal_code: '70447'
  },
  '70448': {
    country: 'US',
    city: 'Mandeville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.3666,
    longitude: -90.0487,
    postal_code: '70448'
  },
  '70449': {
    country: 'US',
    city: 'Maurepas',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.2716,
    longitude: -90.7043,
    postal_code: '70449'
  },
  '70450': {
    country: 'US',
    city: 'Mount Hermon',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Washington',
    latitude: 30.9536,
    longitude: -90.2769,
    postal_code: '70450'
  },
  '70451': {
    country: 'US',
    city: 'Natalbany',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.5519,
    longitude: -90.4859,
    postal_code: '70451'
  },
  '70452': {
    country: 'US',
    city: 'Pearl River',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.3944,
    longitude: -89.7732,
    postal_code: '70452'
  },
  '70453': {
    country: 'US',
    city: 'Pine Grove',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Helena',
    latitude: 30.7032,
    longitude: -90.7672,
    postal_code: '70453'
  },
  '70454': {
    country: 'US',
    city: 'Ponchatoula',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.4406,
    longitude: -90.4422,
    postal_code: '70454'
  },
  '70455': {
    country: 'US',
    city: 'Robert',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.5063,
    longitude: -90.3352,
    postal_code: '70455'
  },
  '70456': {
    country: 'US',
    city: 'Roseland',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.7717,
    longitude: -90.5243,
    postal_code: '70456'
  },
  '70457': {
    country: 'US',
    city: 'Saint Benedict',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.5269,
    longitude: -90.1126,
    postal_code: '70457'
  },
  '70458': {
    country: 'US',
    city: 'Slidell',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.2784,
    longitude: -89.7712,
    postal_code: '70458'
  },
  '70459': {
    country: 'US',
    city: 'Slidell',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.4255,
    longitude: -89.8813,
    postal_code: '70459'
  },
  '70460': {
    country: 'US',
    city: 'Slidell',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.2916,
    longitude: -89.8129,
    postal_code: '70460'
  },
  '70461': {
    country: 'US',
    city: 'Slidell',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.2726,
    longitude: -89.729,
    postal_code: '70461'
  },
  '70462': {
    country: 'US',
    city: 'Springfield',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.4157,
    longitude: -90.5775,
    postal_code: '70462'
  },
  '70463': {
    country: 'US',
    city: 'Sun',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.6491,
    longitude: -89.8926,
    postal_code: '70463'
  },
  '70464': {
    country: 'US',
    city: 'Talisheek',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.5347,
    longitude: -89.8859,
    postal_code: '70464'
  },
  '70465': {
    country: 'US',
    city: 'Tangipahoa',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.8764,
    longitude: -90.5089,
    postal_code: '70465'
  },
  '70466': {
    country: 'US',
    city: 'Tickfaw',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tangipahoa',
    latitude: 30.5668,
    longitude: -90.482,
    postal_code: '70466'
  },
  '70467': {
    country: 'US',
    city: 'Varnado',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Washington',
    latitude: 30.8938,
    longitude: -89.8295,
    postal_code: '70467'
  },
  '70469': {
    country: 'US',
    city: 'Slidell',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.2752,
    longitude: -89.7812,
    postal_code: '70469'
  },
  '70470': {
    country: 'US',
    city: 'Mandeville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.4255,
    longitude: -89.8813,
    postal_code: '70470'
  },
  '70471': {
    country: 'US',
    city: 'Mandeville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Tammany',
    latitude: 30.4207,
    longitude: -90.0589,
    postal_code: '70471'
  },
  '70501': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.2361,
    longitude: -92.0083,
    postal_code: '70501'
  },
  '70502': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.2241,
    longitude: -92.0198,
    postal_code: '70502'
  },
  '70503': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.1843,
    longitude: -92.0497,
    postal_code: '70503'
  },
  '70504': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.2139,
    longitude: -92.0187,
    postal_code: '70504'
  },
  '70505': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.2023,
    longitude: -92.0188,
    postal_code: '70505'
  },
  '70506': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.2077,
    longitude: -92.0656,
    postal_code: '70506'
  },
  '70507': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.2813,
    longitude: -92.016,
    postal_code: '70507'
  },
  '70508': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.1582,
    longitude: -92.0236,
    postal_code: '70508'
  },
  '70509': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.1565,
    longitude: -92,
    postal_code: '70509'
  },
  '70510': {
    country: 'US',
    city: 'Abbeville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vermilion',
    latitude: 29.9466,
    longitude: -92.2416,
    postal_code: '70510'
  },
  '70511': {
    country: 'US',
    city: 'Abbeville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vermilion',
    latitude: 29.9747,
    longitude: -92.1343,
    postal_code: '70511'
  },
  '70512': {
    country: 'US',
    city: 'Arnaudville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.3981,
    longitude: -91.9263,
    postal_code: '70512'
  },
  '70513': {
    country: 'US',
    city: 'Avery Island',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberia',
    latitude: 29.8981,
    longitude: -91.9062,
    postal_code: '70513'
  },
  '70514': {
    country: 'US',
    city: 'Baldwin',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Mary',
    latitude: 29.8488,
    longitude: -91.5458,
    postal_code: '70514'
  },
  '70515': {
    country: 'US',
    city: 'Basile',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Evangeline',
    latitude: 30.4978,
    longitude: -92.5736,
    postal_code: '70515'
  },
  '70516': {
    country: 'US',
    city: 'Branch',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.3446,
    longitude: -92.3459,
    postal_code: '70516'
  },
  '70517': {
    country: 'US',
    city: 'Breaux Bridge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Martin',
    latitude: 30.2948,
    longitude: -91.8296,
    postal_code: '70517'
  },
  '70518': {
    country: 'US',
    city: 'Broussard',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.1219,
    longitude: -91.9502,
    postal_code: '70518'
  },
  '70519': {
    country: 'US',
    city: 'Cade',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Martin',
    latitude: 30.0797,
    longitude: -91.9114,
    postal_code: '70519'
  },
  '70520': {
    country: 'US',
    city: 'Carencro',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.3244,
    longitude: -92.0423,
    postal_code: '70520'
  },
  '70521': {
    country: 'US',
    city: 'Cecilia',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Martin',
    latitude: 30.3364,
    longitude: -91.8492,
    postal_code: '70521'
  },
  '70522': {
    country: 'US',
    city: 'Centerville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Mary',
    latitude: 29.7523,
    longitude: -91.4397,
    postal_code: '70522'
  },
  '70523': {
    country: 'US',
    city: 'Charenton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Mary',
    latitude: 29.8796,
    longitude: -91.5337,
    postal_code: '70523'
  },
  '70524': {
    country: 'US',
    city: 'Chataignier',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Evangeline',
    latitude: 30.5709,
    longitude: -92.3202,
    postal_code: '70524'
  },
  '70525': {
    country: 'US',
    city: 'Church Point',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.4013,
    longitude: -92.224,
    postal_code: '70525'
  },
  '70526': {
    country: 'US',
    city: 'Crowley',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.2148,
    longitude: -92.3777,
    postal_code: '70526'
  },
  '70527': {
    country: 'US',
    city: 'Crowley',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.2284,
    longitude: -92.3018,
    postal_code: '70527'
  },
  '70528': {
    country: 'US',
    city: 'Delcambre',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vermilion',
    latitude: 29.9474,
    longitude: -91.9889,
    postal_code: '70528'
  },
  '70529': {
    country: 'US',
    city: 'Duson',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.1912,
    longitude: -92.1525,
    postal_code: '70529'
  },
  '70531': {
    country: 'US',
    city: 'Egan',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.251,
    longitude: -92.5002,
    postal_code: '70531'
  },
  '70532': {
    country: 'US',
    city: 'Elton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Davis',
    latitude: 30.4715,
    longitude: -92.6996,
    postal_code: '70532'
  },
  '70533': {
    country: 'US',
    city: 'Erath',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vermilion',
    latitude: 29.9522,
    longitude: -92.0343,
    postal_code: '70533'
  },
  '70534': {
    country: 'US',
    city: 'Estherwood',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.1808,
    longitude: -92.4643,
    postal_code: '70534'
  },
  '70535': {
    country: 'US',
    city: 'Eunice',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.5116,
    longitude: -92.3985,
    postal_code: '70535'
  },
  '70537': {
    country: 'US',
    city: 'Evangeline',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.2683,
    longitude: -92.5532,
    postal_code: '70537'
  },
  '70538': {
    country: 'US',
    city: 'Franklin',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Mary',
    latitude: 29.7857,
    longitude: -91.5026,
    postal_code: '70538'
  },
  '70540': {
    country: 'US',
    city: 'Garden City',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Mary',
    latitude: 29.7633,
    longitude: -91.467,
    postal_code: '70540'
  },
  '70541': {
    country: 'US',
    city: 'Grand Coteau',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.4201,
    longitude: -92.0462,
    postal_code: '70541'
  },
  '70542': {
    country: 'US',
    city: 'Gueydan',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vermilion',
    latitude: 30.0255,
    longitude: -92.5338,
    postal_code: '70542'
  },
  '70543': {
    country: 'US',
    city: 'Iota',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.3313,
    longitude: -92.4957,
    postal_code: '70543'
  },
  '70544': {
    country: 'US',
    city: 'Jeanerette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberia',
    latitude: 29.9032,
    longitude: -91.6544,
    postal_code: '70544'
  },
  '70546': {
    country: 'US',
    city: 'Jennings',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Davis',
    latitude: 30.2201,
    longitude: -92.6574,
    postal_code: '70546'
  },
  '70548': {
    country: 'US',
    city: 'Kaplan',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vermilion',
    latitude: 29.9771,
    longitude: -92.3025,
    postal_code: '70548'
  },
  '70549': {
    country: 'US',
    city: 'Lake Arthur',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Davis',
    latitude: 30.091,
    longitude: -92.6825,
    postal_code: '70549'
  },
  '70550': {
    country: 'US',
    city: 'Lawtell',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.5165,
    longitude: -92.1841,
    postal_code: '70550'
  },
  '70551': {
    country: 'US',
    city: 'Leonville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.4752,
    longitude: -91.9737,
    postal_code: '70551'
  },
  '70552': {
    country: 'US',
    city: 'Loreauville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberia',
    latitude: 30.0683,
    longitude: -91.6596,
    postal_code: '70552'
  },
  '70554': {
    country: 'US',
    city: 'Mamou',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Evangeline',
    latitude: 30.6496,
    longitude: -92.4196,
    postal_code: '70554'
  },
  '70555': {
    country: 'US',
    city: 'Maurice',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vermilion',
    latitude: 30.0722,
    longitude: -92.107,
    postal_code: '70555'
  },
  '70556': {
    country: 'US',
    city: 'Mermentau',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.1861,
    longitude: -92.5722,
    postal_code: '70556'
  },
  '70558': {
    country: 'US',
    city: 'Milton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.1038,
    longitude: -92.0765,
    postal_code: '70558'
  },
  '70559': {
    country: 'US',
    city: 'Morse',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.1298,
    longitude: -92.4767,
    postal_code: '70559'
  },
  '70560': {
    country: 'US',
    city: 'New Iberia',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberia',
    latitude: 30.001,
    longitude: -91.82,
    postal_code: '70560'
  },
  '70562': {
    country: 'US',
    city: 'New Iberia',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberia',
    latitude: 30.0035,
    longitude: -91.8187,
    postal_code: '70562'
  },
  '70563': {
    country: 'US',
    city: 'New Iberia',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberia',
    latitude: 30.0245,
    longitude: -91.7496,
    postal_code: '70563'
  },
  '70569': {
    country: 'US',
    city: 'Lydia',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberia',
    latitude: 29.9239,
    longitude: -91.7831,
    postal_code: '70569'
  },
  '70570': {
    country: 'US',
    city: 'Opelousas',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.5144,
    longitude: -92.0897,
    postal_code: '70570'
  },
  '70571': {
    country: 'US',
    city: 'Opelousas',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.5335,
    longitude: -92.0815,
    postal_code: '70571'
  },
  '70575': {
    country: 'US',
    city: 'Perry',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vermilion',
    latitude: 29.9485,
    longitude: -92.1571,
    postal_code: '70575'
  },
  '70576': {
    country: 'US',
    city: 'Pine Prairie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Evangeline',
    latitude: 30.7831,
    longitude: -92.4163,
    postal_code: '70576'
  },
  '70577': {
    country: 'US',
    city: 'Port Barre',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.5478,
    longitude: -91.9286,
    postal_code: '70577'
  },
  '70578': {
    country: 'US',
    city: 'Rayne',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Acadia',
    latitude: 30.2349,
    longitude: -92.2685,
    postal_code: '70578'
  },
  '70580': {
    country: 'US',
    city: 'Reddell',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Evangeline',
    latitude: 30.675,
    longitude: -92.4265,
    postal_code: '70580'
  },
  '70581': {
    country: 'US',
    city: 'Roanoke',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Davis',
    latitude: 30.2464,
    longitude: -92.7421,
    postal_code: '70581'
  },
  '70582': {
    country: 'US',
    city: 'Saint Martinville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Martin',
    latitude: 30.2237,
    longitude: -91.7794,
    postal_code: '70582'
  },
  '70583': {
    country: 'US',
    city: 'Scott',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.2504,
    longitude: -92.0981,
    postal_code: '70583'
  },
  '70584': {
    country: 'US',
    city: 'Sunset',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.3884,
    longitude: -92.0934,
    postal_code: '70584'
  },
  '70585': {
    country: 'US',
    city: 'Turkey Creek',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Evangeline',
    latitude: 30.8707,
    longitude: -92.404,
    postal_code: '70585'
  },
  '70586': {
    country: 'US',
    city: 'Ville Platte',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Evangeline',
    latitude: 30.6924,
    longitude: -92.2737,
    postal_code: '70586'
  },
  '70589': {
    country: 'US',
    city: 'Washington',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.6839,
    longitude: -92.0252,
    postal_code: '70589'
  },
  '70591': {
    country: 'US',
    city: 'Welsh',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Davis',
    latitude: 30.2363,
    longitude: -92.819,
    postal_code: '70591'
  },
  '70592': {
    country: 'US',
    city: 'Youngsville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.0975,
    longitude: -92.0096,
    postal_code: '70592'
  },
  '70593': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.2081,
    longitude: -92.0951,
    postal_code: '70593'
  },
  '70596': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.2241,
    longitude: -92.0198,
    postal_code: '70596'
  },
  '70598': {
    country: 'US',
    city: 'Lafayette',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lafayette',
    latitude: 30.2081,
    longitude: -92.0951,
    postal_code: '70598'
  },
  '70601': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2285,
    longitude: -93.188,
    postal_code: '70601'
  },
  '70602': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2642,
    longitude: -93.3265,
    postal_code: '70602'
  },
  '70605': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.1693,
    longitude: -93.2218,
    postal_code: '70605'
  },
  '70606': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2642,
    longitude: -93.3265,
    postal_code: '70606'
  },
  '70607': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.1244,
    longitude: -93.1835,
    postal_code: '70607'
  },
  '70609': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2642,
    longitude: -93.3265,
    postal_code: '70609'
  },
  '70611': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.322,
    longitude: -93.2111,
    postal_code: '70611'
  },
  '70612': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2642,
    longitude: -93.3265,
    postal_code: '70612'
  },
  '70615': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2585,
    longitude: -93.1146,
    postal_code: '70615'
  },
  '70616': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2642,
    longitude: -93.3265,
    postal_code: '70616'
  },
  '70629': {
    country: 'US',
    city: 'Lake Charles',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2642,
    longitude: -93.3265,
    postal_code: '70629'
  },
  '70630': {
    country: 'US',
    city: 'Bell City',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.1054,
    longitude: -93.0372,
    postal_code: '70630'
  },
  '70631': {
    country: 'US',
    city: 'Cameron',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Cameron',
    latitude: 29.7911,
    longitude: -93.438,
    postal_code: '70631'
  },
  '70632': {
    country: 'US',
    city: 'Creole',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Cameron',
    latitude: 29.8646,
    longitude: -93.0033,
    postal_code: '70632'
  },
  '70633': {
    country: 'US',
    city: 'Dequincy',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.4211,
    longitude: -93.4151,
    postal_code: '70633'
  },
  '70634': {
    country: 'US',
    city: 'Deridder',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Beauregard',
    latitude: 30.8287,
    longitude: -93.2685,
    postal_code: '70634'
  },
  '70637': {
    country: 'US',
    city: 'Dry Creek',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Beauregard',
    latitude: 30.6859,
    longitude: -92.9843,
    postal_code: '70637'
  },
  '70638': {
    country: 'US',
    city: 'Elizabeth',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Allen',
    latitude: 30.8547,
    longitude: -92.7815,
    postal_code: '70638'
  },
  '70639': {
    country: 'US',
    city: 'Evans',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 30.9684,
    longitude: -93.5203,
    postal_code: '70639'
  },
  '70640': {
    country: 'US',
    city: 'Fenton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Davis',
    latitude: 30.3655,
    longitude: -92.9122,
    postal_code: '70640'
  },
  '70643': {
    country: 'US',
    city: 'Grand Chenier',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Cameron',
    latitude: 29.7644,
    longitude: -92.9261,
    postal_code: '70643'
  },
  '70644': {
    country: 'US',
    city: 'Grant',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Allen',
    latitude: 30.7962,
    longitude: -92.9517,
    postal_code: '70644'
  },
  '70645': {
    country: 'US',
    city: 'Hackberry',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Cameron',
    latitude: 29.9822,
    longitude: -93.375,
    postal_code: '70645'
  },
  '70646': {
    country: 'US',
    city: 'Hayes',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.097,
    longitude: -92.9146,
    postal_code: '70646'
  },
  '70647': {
    country: 'US',
    city: 'Iowa',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2219,
    longitude: -93.0259,
    postal_code: '70647'
  },
  '70648': {
    country: 'US',
    city: 'Kinder',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Allen',
    latitude: 30.4607,
    longitude: -92.8693,
    postal_code: '70648'
  },
  '70650': {
    country: 'US',
    city: 'Lacassine',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jefferson Davis',
    latitude: 30.2374,
    longitude: -92.9232,
    postal_code: '70650'
  },
  '70651': {
    country: 'US',
    city: 'Leblanc',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Allen',
    latitude: 30.5648,
    longitude: -92.9583,
    postal_code: '70651'
  },
  '70652': {
    country: 'US',
    city: 'Longville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Beauregard',
    latitude: 30.58,
    longitude: -93.2548,
    postal_code: '70652'
  },
  '70653': {
    country: 'US',
    city: 'Merryville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Beauregard',
    latitude: 30.6716,
    longitude: -93.5613,
    postal_code: '70653'
  },
  '70654': {
    country: 'US',
    city: 'Mittie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Allen',
    latitude: 30.7144,
    longitude: -92.9068,
    postal_code: '70654'
  },
  '70655': {
    country: 'US',
    city: 'Oberlin',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Allen',
    latitude: 30.6162,
    longitude: -92.7527,
    postal_code: '70655'
  },
  '70656': {
    country: 'US',
    city: 'Pitkin',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 30.933,
    longitude: -92.9548,
    postal_code: '70656'
  },
  '70657': {
    country: 'US',
    city: 'Ragley',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Beauregard',
    latitude: 30.4703,
    longitude: -93.2337,
    postal_code: '70657'
  },
  '70658': {
    country: 'US',
    city: 'Reeves',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Allen',
    latitude: 30.521,
    longitude: -93.0477,
    postal_code: '70658'
  },
  '70659': {
    country: 'US',
    city: 'Rosepine',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 30.9243,
    longitude: -93.2749,
    postal_code: '70659'
  },
  '70660': {
    country: 'US',
    city: 'Singer',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Beauregard',
    latitude: 30.5329,
    longitude: -93.465,
    postal_code: '70660'
  },
  '70661': {
    country: 'US',
    city: 'Starks',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.3085,
    longitude: -93.6615,
    postal_code: '70661'
  },
  '70662': {
    country: 'US',
    city: 'Sugartown',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Beauregard',
    latitude: 30.8277,
    longitude: -93.017,
    postal_code: '70662'
  },
  '70663': {
    country: 'US',
    city: 'Sulphur',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.219,
    longitude: -93.3639,
    postal_code: '70663'
  },
  '70664': {
    country: 'US',
    city: 'Sulphur',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2642,
    longitude: -93.3265,
    postal_code: '70664'
  },
  '70665': {
    country: 'US',
    city: 'Sulphur',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2009,
    longitude: -93.4437,
    postal_code: '70665'
  },
  '70668': {
    country: 'US',
    city: 'Vinton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2015,
    longitude: -93.5728,
    postal_code: '70668'
  },
  '70669': {
    country: 'US',
    city: 'Westlake',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Calcasieu',
    latitude: 30.2613,
    longitude: -93.2688,
    postal_code: '70669'
  },
  '70704': {
    country: 'US',
    city: 'Baker',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70704'
  },
  '70706': {
    country: 'US',
    city: 'Denham Springs',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.6085,
    longitude: -90.9038,
    postal_code: '70706'
  },
  '70707': {
    country: 'US',
    city: 'Gonzales',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.2047,
    longitude: -90.8695,
    postal_code: '70707'
  },
  '70710': {
    country: 'US',
    city: 'Addis',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Baton Rouge',
    latitude: 30.3557,
    longitude: -91.2613,
    postal_code: '70710'
  },
  '70711': {
    country: 'US',
    city: 'Albany',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.5149,
    longitude: -90.5964,
    postal_code: '70711'
  },
  '70712': {
    country: 'US',
    city: 'Angola',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Feliciana',
    latitude: 30.9656,
    longitude: -91.5979,
    postal_code: '70712'
  },
  '70714': {
    country: 'US',
    city: 'Baker',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5814,
    longitude: -91.1429,
    postal_code: '70714'
  },
  '70715': {
    country: 'US',
    city: 'Batchelor',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.8298,
    longitude: -91.6944,
    postal_code: '70715'
  },
  '70718': {
    country: 'US',
    city: 'Brittany',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.201,
    longitude: -90.8689,
    postal_code: '70718'
  },
  '70719': {
    country: 'US',
    city: 'Brusly',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Baton Rouge',
    latitude: 30.3877,
    longitude: -91.2526,
    postal_code: '70719'
  },
  '70721': {
    country: 'US',
    city: 'Carville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberville',
    latitude: 30.2184,
    longitude: -91.0925,
    postal_code: '70721'
  },
  '70722': {
    country: 'US',
    city: 'Clinton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Feliciana',
    latitude: 30.8249,
    longitude: -90.9331,
    postal_code: '70722'
  },
  '70723': {
    country: 'US',
    city: 'Convent',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. James',
    latitude: 30.0208,
    longitude: -90.8298,
    postal_code: '70723'
  },
  '70725': {
    country: 'US',
    city: 'Darrow',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.13,
    longitude: -90.9651,
    postal_code: '70725'
  },
  '70726': {
    country: 'US',
    city: 'Denham Springs',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.459,
    longitude: -90.9027,
    postal_code: '70726'
  },
  '70727': {
    country: 'US',
    city: 'Denham Springs',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.3375,
    longitude: -90.8434,
    postal_code: '70727'
  },
  '70728': {
    country: 'US',
    city: 'Duplessis',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.2954,
    longitude: -90.9458,
    postal_code: '70728'
  },
  '70729': {
    country: 'US',
    city: 'Erwinville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Baton Rouge',
    latitude: 30.548,
    longitude: -91.4187,
    postal_code: '70729'
  },
  '70730': {
    country: 'US',
    city: 'Ethel',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Feliciana',
    latitude: 30.8131,
    longitude: -91.11,
    postal_code: '70730'
  },
  '70732': {
    country: 'US',
    city: 'Fordoche',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.596,
    longitude: -91.6165,
    postal_code: '70732'
  },
  '70733': {
    country: 'US',
    city: 'French Settlement',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.2952,
    longitude: -90.7951,
    postal_code: '70733'
  },
  '70734': {
    country: 'US',
    city: 'Geismar',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.2196,
    longitude: -91.0079,
    postal_code: '70734'
  },
  '70736': {
    country: 'US',
    city: 'Glynn',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.6376,
    longitude: -91.3423,
    postal_code: '70736'
  },
  '70737': {
    country: 'US',
    city: 'Gonzales',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.2473,
    longitude: -90.918,
    postal_code: '70737'
  },
  '70738': {
    country: 'US',
    city: 'Burnside',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.1388,
    longitude: -90.924,
    postal_code: '70738'
  },
  '70739': {
    country: 'US',
    city: 'Greenwell Springs',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5984,
    longitude: -90.9705,
    postal_code: '70739'
  },
  '70740': {
    country: 'US',
    city: 'Grosse Tete',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberville',
    latitude: 30.3879,
    longitude: -91.4383,
    postal_code: '70740'
  },
  '70743': {
    country: 'US',
    city: 'Hester',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. James',
    latitude: 30.0206,
    longitude: -90.7814,
    postal_code: '70743'
  },
  '70744': {
    country: 'US',
    city: 'Holden',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.5556,
    longitude: -90.6652,
    postal_code: '70744'
  },
  '70747': {
    country: 'US',
    city: 'Innis',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.8736,
    longitude: -91.6766,
    postal_code: '70747'
  },
  '70748': {
    country: 'US',
    city: 'Jackson',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Feliciana',
    latitude: 30.8087,
    longitude: -91.1987,
    postal_code: '70748'
  },
  '70749': {
    country: 'US',
    city: 'Jarreau',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.6326,
    longitude: -91.4332,
    postal_code: '70749'
  },
  '70750': {
    country: 'US',
    city: 'Krotz Springs',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.5379,
    longitude: -91.7563,
    postal_code: '70750'
  },
  '70752': {
    country: 'US',
    city: 'Lakeland',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.5799,
    longitude: -91.4217,
    postal_code: '70752'
  },
  '70753': {
    country: 'US',
    city: 'Lettsworth',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.9324,
    longitude: -91.7403,
    postal_code: '70753'
  },
  '70754': {
    country: 'US',
    city: 'Livingston',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.4741,
    longitude: -90.7673,
    postal_code: '70754'
  },
  '70755': {
    country: 'US',
    city: 'Livonia',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.5524,
    longitude: -91.5332,
    postal_code: '70755'
  },
  '70756': {
    country: 'US',
    city: 'Lottie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.5563,
    longitude: -91.6418,
    postal_code: '70756'
  },
  '70757': {
    country: 'US',
    city: 'Maringouin',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberville',
    latitude: 30.4626,
    longitude: -91.5099,
    postal_code: '70757'
  },
  '70759': {
    country: 'US',
    city: 'Morganza',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.7245,
    longitude: -91.5959,
    postal_code: '70759'
  },
  '70760': {
    country: 'US',
    city: 'New Roads',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.7014,
    longitude: -91.4421,
    postal_code: '70760'
  },
  '70761': {
    country: 'US',
    city: 'Norwood',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Feliciana',
    latitude: 30.9518,
    longitude: -91.0629,
    postal_code: '70761'
  },
  '70762': {
    country: 'US',
    city: 'Oscar',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.5983,
    longitude: -91.4577,
    postal_code: '70762'
  },
  '70763': {
    country: 'US',
    city: 'Paulina',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. James',
    latitude: 30.0352,
    longitude: -90.7374,
    postal_code: '70763'
  },
  '70764': {
    country: 'US',
    city: 'Plaquemine',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberville',
    latitude: 30.2684,
    longitude: -91.2524,
    postal_code: '70764'
  },
  '70765': {
    country: 'US',
    city: 'Plaquemine',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberville',
    latitude: 30.2891,
    longitude: -91.2343,
    postal_code: '70765'
  },
  '70767': {
    country: 'US',
    city: 'Port Allen',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Baton Rouge',
    latitude: 30.472,
    longitude: -91.2541,
    postal_code: '70767'
  },
  '70769': {
    country: 'US',
    city: 'Prairieville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.3073,
    longitude: -90.9405,
    postal_code: '70769'
  },
  '70770': {
    country: 'US',
    city: 'Pride',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.6133,
    longitude: -90.9943,
    postal_code: '70770'
  },
  '70772': {
    country: 'US',
    city: 'Rosedale',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberville',
    latitude: 30.4408,
    longitude: -91.4562,
    postal_code: '70772'
  },
  '70773': {
    country: 'US',
    city: 'Rougon',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.6035,
    longitude: -91.3813,
    postal_code: '70773'
  },
  '70774': {
    country: 'US',
    city: 'Saint Amant',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.2385,
    longitude: -90.8435,
    postal_code: '70774'
  },
  '70775': {
    country: 'US',
    city: 'Saint Francisville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Feliciana',
    latitude: 30.8694,
    longitude: -91.4186,
    postal_code: '70775'
  },
  '70776': {
    country: 'US',
    city: 'Saint Gabriel',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberville',
    latitude: 30.2528,
    longitude: -91.0857,
    postal_code: '70776'
  },
  '70777': {
    country: 'US',
    city: 'Slaughter',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Feliciana',
    latitude: 30.7383,
    longitude: -91.07,
    postal_code: '70777'
  },
  '70778': {
    country: 'US',
    city: 'Sorrento',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ascension',
    latitude: 30.1854,
    longitude: -90.8631,
    postal_code: '70778'
  },
  '70780': {
    country: 'US',
    city: 'Sunshine',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberville',
    latitude: 30.2982,
    longitude: -91.1799,
    postal_code: '70780'
  },
  '70782': {
    country: 'US',
    city: 'Tunica',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Feliciana',
    latitude: 30.9601,
    longitude: -91.5396,
    postal_code: '70782'
  },
  '70783': {
    country: 'US',
    city: 'Ventress',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Pointe Coupee',
    latitude: 30.6814,
    longitude: -91.4033,
    postal_code: '70783'
  },
  '70784': {
    country: 'US',
    city: 'Wakefield',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Feliciana',
    latitude: 30.9175,
    longitude: -91.3581,
    postal_code: '70784'
  },
  '70785': {
    country: 'US',
    city: 'Walker',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.5247,
    longitude: -90.8557,
    postal_code: '70785'
  },
  '70786': {
    country: 'US',
    city: 'Watson',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Livingston',
    latitude: 30.5502,
    longitude: -90.9582,
    postal_code: '70786'
  },
  '70787': {
    country: 'US',
    city: 'Weyanoke',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Feliciana',
    latitude: 30.9468,
    longitude: -91.4612,
    postal_code: '70787'
  },
  '70788': {
    country: 'US',
    city: 'White Castle',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Iberville',
    latitude: 30.1545,
    longitude: -91.1773,
    postal_code: '70788'
  },
  '70789': {
    country: 'US',
    city: 'Wilson',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Feliciana',
    latitude: 30.9473,
    longitude: -91.0655,
    postal_code: '70789'
  },
  '70791': {
    country: 'US',
    city: 'Zachary',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.6561,
    longitude: -91.1358,
    postal_code: '70791'
  },
  '70792': {
    country: 'US',
    city: 'Uncle Sam',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. James',
    latitude: 30.0279,
    longitude: -90.8028,
    postal_code: '70792'
  },
  '70801': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4492,
    longitude: -91.1856,
    postal_code: '70801'
  },
  '70802': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4438,
    longitude: -91.1775,
    postal_code: '70802'
  },
  '70803': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.405,
    longitude: -91.1868,
    postal_code: '70803'
  },
  '70804': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.3863,
    longitude: -91.1339,
    postal_code: '70804'
  },
  '70805': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.486,
    longitude: -91.1481,
    postal_code: '70805'
  },
  '70806': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4485,
    longitude: -91.13,
    postal_code: '70806'
  },
  '70807': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5768,
    longitude: -91.2246,
    postal_code: '70807'
  },
  '70808': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4066,
    longitude: -91.1468,
    postal_code: '70808'
  },
  '70809': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4089,
    longitude: -91.0842,
    postal_code: '70809'
  },
  '70810': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.3633,
    longitude: -91.0919,
    postal_code: '70810'
  },
  '70811': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5317,
    longitude: -91.1167,
    postal_code: '70811'
  },
  '70812': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5052,
    longitude: -91.1181,
    postal_code: '70812'
  },
  '70813': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5201,
    longitude: -91.1949,
    postal_code: '70813'
  },
  '70814': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4848,
    longitude: -91.0689,
    postal_code: '70814'
  },
  '70815': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4558,
    longitude: -91.0596,
    postal_code: '70815'
  },
  '70816': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4273,
    longitude: -91.0356,
    postal_code: '70816'
  },
  '70817': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.3904,
    longitude: -91.0021,
    postal_code: '70817'
  },
  '70818': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5408,
    longitude: -91.05,
    postal_code: '70818'
  },
  '70819': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4668,
    longitude: -91.0156,
    postal_code: '70819'
  },
  '70820': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.3795,
    longitude: -91.1671,
    postal_code: '70820'
  },
  '70821': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4613,
    longitude: -91.0447,
    postal_code: '70821'
  },
  '70822': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70822'
  },
  '70823': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70823'
  },
  '70825': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70825'
  },
  '70826': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70826'
  },
  '70827': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4338,
    longitude: -91.0825,
    postal_code: '70827'
  },
  '70831': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70831'
  },
  '70833': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70833'
  },
  '70835': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70835'
  },
  '70836': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.392,
    longitude: -91.0892,
    postal_code: '70836'
  },
  '70837': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70837'
  },
  '70873': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5305,
    longitude: -91.1163,
    postal_code: '70873'
  },
  '70874': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5902,
    longitude: -91.2054,
    postal_code: '70874'
  },
  '70879': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70879'
  },
  '70884': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70884'
  },
  '70891': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4492,
    longitude: -91.1547,
    postal_code: '70891'
  },
  '70892': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.4507,
    longitude: -91.1546,
    postal_code: '70892'
  },
  '70893': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.413,
    longitude: -91.1715,
    postal_code: '70893'
  },
  '70894': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70894'
  },
  '70895': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70895'
  },
  '70896': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70896'
  },
  '70898': {
    country: 'US',
    city: 'Baton Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Baton Rouge Parish',
    latitude: 30.5159,
    longitude: -91.0804,
    postal_code: '70898'
  },
  '71001': {
    country: 'US',
    city: 'Arcadia',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bienville',
    latitude: 32.5556,
    longitude: -92.9245,
    postal_code: '71001'
  },
  '71002': {
    country: 'US',
    city: 'Ashland',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 32.1378,
    longitude: -93.0906,
    postal_code: '71002'
  },
  '71003': {
    country: 'US',
    city: 'Athens',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Claiborne',
    latitude: 32.6451,
    longitude: -93.0239,
    postal_code: '71003'
  },
  '71004': {
    country: 'US',
    city: 'Belcher',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.7544,
    longitude: -93.8508,
    postal_code: '71004'
  },
  '71006': {
    country: 'US',
    city: 'Benton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.6976,
    longitude: -93.691,
    postal_code: '71006'
  },
  '71007': {
    country: 'US',
    city: 'Bethany',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.3662,
    longitude: -94.0034,
    postal_code: '71007'
  },
  '71008': {
    country: 'US',
    city: 'Bienville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bienville',
    latitude: 32.2523,
    longitude: -92.9084,
    postal_code: '71008'
  },
  '71009': {
    country: 'US',
    city: 'Blanchard',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.5719,
    longitude: -93.8871,
    postal_code: '71009'
  },
  '71016': {
    country: 'US',
    city: 'Castor',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bienville',
    latitude: 32.2452,
    longitude: -93.0936,
    postal_code: '71016'
  },
  '71018': {
    country: 'US',
    city: 'Cotton Valley',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 32.819,
    longitude: -93.4259,
    postal_code: '71018'
  },
  '71019': {
    country: 'US',
    city: 'Coushatta',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Red River',
    latitude: 32.0231,
    longitude: -93.2648,
    postal_code: '71019'
  },
  '71021': {
    country: 'US',
    city: 'Cullen',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 32.9721,
    longitude: -93.4492,
    postal_code: '71021'
  },
  '71023': {
    country: 'US',
    city: 'Doyline',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 32.49,
    longitude: -93.3996,
    postal_code: '71023'
  },
  '71024': {
    country: 'US',
    city: 'Dubberly',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 32.5192,
    longitude: -93.2142,
    postal_code: '71024'
  },
  '71027': {
    country: 'US',
    city: 'Frierson',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'De Soto',
    latitude: 32.245,
    longitude: -93.6915,
    postal_code: '71027'
  },
  '71028': {
    country: 'US',
    city: 'Gibsland',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bienville',
    latitude: 32.5299,
    longitude: -93.0706,
    postal_code: '71028'
  },
  '71029': {
    country: 'US',
    city: 'Gilliam',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.8251,
    longitude: -93.8293,
    postal_code: '71029'
  },
  '71030': {
    country: 'US',
    city: 'Gloster',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'De Soto',
    latitude: 32.1858,
    longitude: -93.801,
    postal_code: '71030'
  },
  '71031': {
    country: 'US',
    city: 'Goldonna',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 32,
    longitude: -92.9611,
    postal_code: '71031'
  },
  '71032': {
    country: 'US',
    city: 'Grand Cane',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'De Soto',
    latitude: 32.105,
    longitude: -93.7941,
    postal_code: '71032'
  },
  '71033': {
    country: 'US',
    city: 'Greenwood',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.4429,
    longitude: -93.973,
    postal_code: '71033'
  },
  '71034': {
    country: 'US',
    city: 'Hall Summit',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Red River',
    latitude: 32.1752,
    longitude: -93.3047,
    postal_code: '71034'
  },
  '71037': {
    country: 'US',
    city: 'Haughton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.5507,
    longitude: -93.5657,
    postal_code: '71037'
  },
  '71038': {
    country: 'US',
    city: 'Haynesville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Claiborne',
    latitude: 32.9278,
    longitude: -93.0691,
    postal_code: '71038'
  },
  '71039': {
    country: 'US',
    city: 'Heflin',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 32.447,
    longitude: -93.2852,
    postal_code: '71039'
  },
  '71040': {
    country: 'US',
    city: 'Homer',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Claiborne',
    latitude: 32.7749,
    longitude: -93.0288,
    postal_code: '71040'
  },
  '71043': {
    country: 'US',
    city: 'Hosston',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.8967,
    longitude: -93.8834,
    postal_code: '71043'
  },
  '71044': {
    country: 'US',
    city: 'Ida',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.9934,
    longitude: -93.9022,
    postal_code: '71044'
  },
  '71045': {
    country: 'US',
    city: 'Jamestown',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bienville',
    latitude: 32.3613,
    longitude: -93.1848,
    postal_code: '71045'
  },
  '71046': {
    country: 'US',
    city: 'Keatchie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'De Soto',
    latitude: 32.1622,
    longitude: -93.951,
    postal_code: '71046'
  },
  '71047': {
    country: 'US',
    city: 'Keithville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.3161,
    longitude: -93.8881,
    postal_code: '71047'
  },
  '71048': {
    country: 'US',
    city: 'Lisbon',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Claiborne',
    latitude: 32.8452,
    longitude: -92.8878,
    postal_code: '71048'
  },
  '71049': {
    country: 'US',
    city: 'Logansport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'De Soto',
    latitude: 31.9943,
    longitude: -93.9627,
    postal_code: '71049'
  },
  '71050': {
    country: 'US',
    city: 'Longstreet',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'De Soto',
    latitude: 32.0977,
    longitude: -93.9521,
    postal_code: '71050'
  },
  '71051': {
    country: 'US',
    city: 'Elm Grove',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.3886,
    longitude: -93.5026,
    postal_code: '71051'
  },
  '71052': {
    country: 'US',
    city: 'Mansfield',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'De Soto',
    latitude: 32.0239,
    longitude: -93.698,
    postal_code: '71052'
  },
  '71055': {
    country: 'US',
    city: 'Minden',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 32.6323,
    longitude: -93.2886,
    postal_code: '71055'
  },
  '71058': {
    country: 'US',
    city: 'Minden',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 32.6154,
    longitude: -93.2868,
    postal_code: '71058'
  },
  '71060': {
    country: 'US',
    city: 'Mooringsport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6626,
    longitude: -93.973,
    postal_code: '71060'
  },
  '71061': {
    country: 'US',
    city: 'Oil City',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.7451,
    longitude: -93.9838,
    postal_code: '71061'
  },
  '71063': {
    country: 'US',
    city: 'Pelican',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'De Soto',
    latitude: 31.8966,
    longitude: -93.5634,
    postal_code: '71063'
  },
  '71064': {
    country: 'US',
    city: 'Plain Dealing',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.9074,
    longitude: -93.6905,
    postal_code: '71064'
  },
  '71065': {
    country: 'US',
    city: 'Pleasant Hill',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Sabine',
    latitude: 31.8086,
    longitude: -93.5136,
    postal_code: '71065'
  },
  '71066': {
    country: 'US',
    city: 'Powhatan',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.8741,
    longitude: -93.1966,
    postal_code: '71066'
  },
  '71067': {
    country: 'US',
    city: 'Princeton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.5791,
    longitude: -93.5226,
    postal_code: '71067'
  },
  '71068': {
    country: 'US',
    city: 'Ringgold',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bienville',
    latitude: 32.3263,
    longitude: -93.2982,
    postal_code: '71068'
  },
  '71069': {
    country: 'US',
    city: 'Rodessa',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.9701,
    longitude: -93.9885,
    postal_code: '71069'
  },
  '71070': {
    country: 'US',
    city: 'Saline',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bienville',
    latitude: 32.197,
    longitude: -92.917,
    postal_code: '71070'
  },
  '71071': {
    country: 'US',
    city: 'Sarepta',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 32.9434,
    longitude: -93.4404,
    postal_code: '71071'
  },
  '71072': {
    country: 'US',
    city: 'Shongaloo',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 32.9713,
    longitude: -93.2963,
    postal_code: '71072'
  },
  '71073': {
    country: 'US',
    city: 'Sibley',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 32.5095,
    longitude: -93.3009,
    postal_code: '71073'
  },
  '71075': {
    country: 'US',
    city: 'Springhill',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Webster',
    latitude: 33.0005,
    longitude: -93.4596,
    postal_code: '71075'
  },
  '71078': {
    country: 'US',
    city: 'Stonewall',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'De Soto',
    latitude: 32.2848,
    longitude: -93.8003,
    postal_code: '71078'
  },
  '71079': {
    country: 'US',
    city: 'Summerfield',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Claiborne',
    latitude: 32.9238,
    longitude: -92.8215,
    postal_code: '71079'
  },
  '71080': {
    country: 'US',
    city: 'Taylor',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bienville',
    latitude: 32.366,
    longitude: -93.1011,
    postal_code: '71080'
  },
  '71082': {
    country: 'US',
    city: 'Vivian',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.8423,
    longitude: -93.9504,
    postal_code: '71082'
  },
  '71101': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.5037,
    longitude: -93.7487,
    postal_code: '71101'
  },
  '71102': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71102'
  },
  '71103': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.4945,
    longitude: -93.7727,
    postal_code: '71103'
  },
  '71104': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.483,
    longitude: -93.7349,
    postal_code: '71104'
  },
  '71105': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.4589,
    longitude: -93.7143,
    postal_code: '71105'
  },
  '71106': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.3912,
    longitude: -93.7116,
    postal_code: '71106'
  },
  '71107': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6016,
    longitude: -93.8738,
    postal_code: '71107'
  },
  '71108': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.4486,
    longitude: -93.7814,
    postal_code: '71108'
  },
  '71109': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.474,
    longitude: -93.8013,
    postal_code: '71109'
  },
  '71110': {
    country: 'US',
    city: 'Barksdale Afb',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.5137,
    longitude: -93.6404,
    postal_code: '71110'
  },
  '71111': {
    country: 'US',
    city: 'Bossier City',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.5449,
    longitude: -93.7038,
    postal_code: '71111'
  },
  '71112': {
    country: 'US',
    city: 'Bossier City',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.486,
    longitude: -93.6767,
    postal_code: '71112'
  },
  '71113': {
    country: 'US',
    city: 'Bossier City',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.6276,
    longitude: -93.609,
    postal_code: '71113'
  },
  '71115': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.3401,
    longitude: -93.6092,
    postal_code: '71115'
  },
  '71118': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.3977,
    longitude: -93.8025,
    postal_code: '71118'
  },
  '71119': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.4771,
    longitude: -93.8726,
    postal_code: '71119'
  },
  '71120': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71120'
  },
  '71129': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.4141,
    longitude: -93.8742,
    postal_code: '71129'
  },
  '71130': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71130'
  },
  '71133': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71133'
  },
  '71134': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71134'
  },
  '71135': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71135'
  },
  '71136': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71136'
  },
  '71137': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71137'
  },
  '71138': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71138'
  },
  '71148': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71148'
  },
  '71149': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71149'
  },
  '71150': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.5056,
    longitude: -93.7434,
    postal_code: '71150'
  },
  '71151': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71151'
  },
  '71152': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71152'
  },
  '71153': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71153'
  },
  '71154': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71154'
  },
  '71156': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71156'
  },
  '71161': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71161'
  },
  '71162': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71162'
  },
  '71163': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71163'
  },
  '71164': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71164'
  },
  '71165': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71165'
  },
  '71166': {
    country: 'US',
    city: 'Shreveport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caddo Parish',
    latitude: 32.6076,
    longitude: -93.7526,
    postal_code: '71166'
  },
  '71171': {
    country: 'US',
    city: 'Bossier City',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.516,
    longitude: -93.7321,
    postal_code: '71171'
  },
  '71172': {
    country: 'US',
    city: 'Bossier City',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Bossier',
    latitude: 32.6276,
    longitude: -93.609,
    postal_code: '71172'
  },
  '71201': {
    country: 'US',
    city: 'Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.5286,
    longitude: -92.1061,
    postal_code: '71201'
  },
  '71202': {
    country: 'US',
    city: 'Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.3868,
    longitude: -92.0722,
    postal_code: '71202'
  },
  '71203': {
    country: 'US',
    city: 'Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.553,
    longitude: -92.0422,
    postal_code: '71203'
  },
  '71207': {
    country: 'US',
    city: 'Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.5093,
    longitude: -92.1193,
    postal_code: '71207'
  },
  '71209': {
    country: 'US',
    city: 'Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.5277,
    longitude: -92.0756,
    postal_code: '71209'
  },
  '71210': {
    country: 'US',
    city: 'Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.4908,
    longitude: -92.1594,
    postal_code: '71210'
  },
  '71211': {
    country: 'US',
    city: 'Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.4908,
    longitude: -92.1594,
    postal_code: '71211'
  },
  '71212': {
    country: 'US',
    city: 'Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.5093,
    longitude: -92.1193,
    postal_code: '71212'
  },
  '71213': {
    country: 'US',
    city: 'Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.4908,
    longitude: -92.1594,
    postal_code: '71213'
  },
  '71217': {
    country: 'US',
    city: 'Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.5116,
    longitude: -92.0849,
    postal_code: '71217'
  },
  '71218': {
    country: 'US',
    city: 'Archibald',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Richland',
    latitude: 32.3632,
    longitude: -91.7693,
    postal_code: '71218'
  },
  '71219': {
    country: 'US',
    city: 'Baskin',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Franklin',
    latitude: 32.2897,
    longitude: -91.7132,
    postal_code: '71219'
  },
  '71220': {
    country: 'US',
    city: 'Bastrop',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Morehouse',
    latitude: 32.7894,
    longitude: -91.9078,
    postal_code: '71220'
  },
  '71221': {
    country: 'US',
    city: 'Bastrop',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Morehouse',
    latitude: 32.7562,
    longitude: -91.8723,
    postal_code: '71221'
  },
  '71222': {
    country: 'US',
    city: 'Bernice',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Union',
    latitude: 32.8221,
    longitude: -92.6579,
    postal_code: '71222'
  },
  '71223': {
    country: 'US',
    city: 'Bonita',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Morehouse',
    latitude: 32.9123,
    longitude: -91.6822,
    postal_code: '71223'
  },
  '71225': {
    country: 'US',
    city: 'Calhoun',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.5248,
    longitude: -92.3299,
    postal_code: '71225'
  },
  '71226': {
    country: 'US',
    city: 'Chatham',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jackson',
    latitude: 32.2922,
    longitude: -92.4374,
    postal_code: '71226'
  },
  '71227': {
    country: 'US',
    city: 'Choudrant',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lincoln',
    latitude: 32.5556,
    longitude: -92.5224,
    postal_code: '71227'
  },
  '71229': {
    country: 'US',
    city: 'Collinston',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Morehouse',
    latitude: 32.6971,
    longitude: -91.8634,
    postal_code: '71229'
  },
  '71230': {
    country: 'US',
    city: 'Crowville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Franklin',
    latitude: 32.2407,
    longitude: -91.5901,
    postal_code: '71230'
  },
  '71232': {
    country: 'US',
    city: 'Delhi',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Richland',
    latitude: 32.3929,
    longitude: -91.4714,
    postal_code: '71232'
  },
  '71233': {
    country: 'US',
    city: 'Delta',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Madison',
    latitude: 32.3257,
    longitude: -90.927,
    postal_code: '71233'
  },
  '71234': {
    country: 'US',
    city: 'Downsville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Union',
    latitude: 32.6525,
    longitude: -92.3745,
    postal_code: '71234'
  },
  '71235': {
    country: 'US',
    city: 'Dubach',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lincoln',
    latitude: 32.6949,
    longitude: -92.6785,
    postal_code: '71235'
  },
  '71237': {
    country: 'US',
    city: 'Epps',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Carroll',
    latitude: 32.6161,
    longitude: -91.4913,
    postal_code: '71237'
  },
  '71238': {
    country: 'US',
    city: 'Eros',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jackson',
    latitude: 32.3988,
    longitude: -92.3479,
    postal_code: '71238'
  },
  '71240': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.6443,
    longitude: -92.0365,
    postal_code: '71240'
  },
  '71241': {
    country: 'US',
    city: 'Farmerville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Union',
    latitude: 32.7534,
    longitude: -92.318,
    postal_code: '71241'
  },
  '71242': {
    country: 'US',
    city: 'Forest',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Carroll',
    latitude: 32.8239,
    longitude: -91.3989,
    postal_code: '71242'
  },
  '71243': {
    country: 'US',
    city: 'Fort Necessity',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Franklin',
    latitude: 32.0434,
    longitude: -91.8257,
    postal_code: '71243'
  },
  '71245': {
    country: 'US',
    city: 'Grambling',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lincoln',
    latitude: 32.5244,
    longitude: -92.7158,
    postal_code: '71245'
  },
  '71247': {
    country: 'US',
    city: 'Hodge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jackson',
    latitude: 32.2768,
    longitude: -92.7235,
    postal_code: '71247'
  },
  '71249': {
    country: 'US',
    city: 'Jigger',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Franklin',
    latitude: 32.0349,
    longitude: -91.7468,
    postal_code: '71249'
  },
  '71250': {
    country: 'US',
    city: 'Jones',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Morehouse',
    latitude: 32.9663,
    longitude: -91.5965,
    postal_code: '71250'
  },
  '71251': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jackson',
    latitude: 32.2483,
    longitude: -92.6944,
    postal_code: '71251'
  },
  '71253': {
    country: 'US',
    city: 'Kilbourne',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Carroll',
    latitude: 32.9946,
    longitude: -91.3162,
    postal_code: '71253'
  },
  '71254': {
    country: 'US',
    city: 'Lake Providence',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Carroll',
    latitude: 32.8071,
    longitude: -91.1906,
    postal_code: '71254'
  },
  '71256': {
    country: 'US',
    city: 'Lillie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Union',
    latitude: 32.9529,
    longitude: -92.6858,
    postal_code: '71256'
  },
  '71259': {
    country: 'US',
    city: 'Mangham',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Richland',
    latitude: 32.3331,
    longitude: -91.7976,
    postal_code: '71259'
  },
  '71260': {
    country: 'US',
    city: 'Marion',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Union',
    latitude: 32.8716,
    longitude: -92.2862,
    postal_code: '71260'
  },
  '71261': {
    country: 'US',
    city: 'Mer Rouge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Morehouse',
    latitude: 32.7718,
    longitude: -91.7716,
    postal_code: '71261'
  },
  '71263': {
    country: 'US',
    city: 'Oak Grove',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Carroll',
    latitude: 32.8731,
    longitude: -91.4302,
    postal_code: '71263'
  },
  '71264': {
    country: 'US',
    city: 'Oak Ridge',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Morehouse',
    latitude: 32.6243,
    longitude: -91.7618,
    postal_code: '71264'
  },
  '71266': {
    country: 'US',
    city: 'Pioneer',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'West Carroll',
    latitude: 32.7154,
    longitude: -91.4648,
    postal_code: '71266'
  },
  '71268': {
    country: 'US',
    city: 'Quitman',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Jackson',
    latitude: 32.3564,
    longitude: -92.7085,
    postal_code: '71268'
  },
  '71269': {
    country: 'US',
    city: 'Rayville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Richland',
    latitude: 32.4456,
    longitude: -91.7433,
    postal_code: '71269'
  },
  '71270': {
    country: 'US',
    city: 'Ruston',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lincoln',
    latitude: 32.5308,
    longitude: -92.6439,
    postal_code: '71270'
  },
  '71272': {
    country: 'US',
    city: 'Ruston',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lincoln',
    latitude: 32.5258,
    longitude: -92.6493,
    postal_code: '71272'
  },
  '71273': {
    country: 'US',
    city: 'Ruston',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lincoln',
    latitude: 32.6065,
    longitude: -92.6484,
    postal_code: '71273'
  },
  '71275': {
    country: 'US',
    city: 'Simsboro',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Lincoln',
    latitude: 32.5384,
    longitude: -92.7995,
    postal_code: '71275'
  },
  '71276': {
    country: 'US',
    city: 'Sondheimer',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Carroll',
    latitude: 32.5756,
    longitude: -91.1496,
    postal_code: '71276'
  },
  '71277': {
    country: 'US',
    city: 'Spearsville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Union',
    latitude: 32.955,
    longitude: -92.587,
    postal_code: '71277'
  },
  '71279': {
    country: 'US',
    city: 'Start',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Richland',
    latitude: 32.4865,
    longitude: -91.8593,
    postal_code: '71279'
  },
  '71280': {
    country: 'US',
    city: 'Sterlington',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.7119,
    longitude: -92.0981,
    postal_code: '71280'
  },
  '71281': {
    country: 'US',
    city: 'Swartz',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.4908,
    longitude: -92.1594,
    postal_code: '71281'
  },
  '71282': {
    country: 'US',
    city: 'Tallulah',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Madison',
    latitude: 32.4326,
    longitude: -91.0645,
    postal_code: '71282'
  },
  '71284': {
    country: 'US',
    city: 'Tallulah',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Madison',
    latitude: 32.4085,
    longitude: -91.1868,
    postal_code: '71284'
  },
  '71286': {
    country: 'US',
    city: 'Transylvania',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'East Carroll',
    latitude: 32.6705,
    longitude: -91.2288,
    postal_code: '71286'
  },
  '71291': {
    country: 'US',
    city: 'West Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.5317,
    longitude: -92.176,
    postal_code: '71291'
  },
  '71292': {
    country: 'US',
    city: 'West Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.4566,
    longitude: -92.1854,
    postal_code: '71292'
  },
  '71294': {
    country: 'US',
    city: 'West Monroe',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Ouachita Parish',
    latitude: 32.4908,
    longitude: -92.1594,
    postal_code: '71294'
  },
  '71295': {
    country: 'US',
    city: 'Winnsboro',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Franklin',
    latitude: 32.1592,
    longitude: -91.7108,
    postal_code: '71295'
  },
  '71301': {
    country: 'US',
    city: 'Alexandria',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.2885,
    longitude: -92.4633,
    postal_code: '71301'
  },
  '71302': {
    country: 'US',
    city: 'Alexandria',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.2683,
    longitude: -92.4242,
    postal_code: '71302'
  },
  '71303': {
    country: 'US',
    city: 'Alexandria',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.3048,
    longitude: -92.5089,
    postal_code: '71303'
  },
  '71306': {
    country: 'US',
    city: 'Alexandria',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.3113,
    longitude: -92.4451,
    postal_code: '71306'
  },
  '71307': {
    country: 'US',
    city: 'Alexandria',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.2034,
    longitude: -92.5269,
    postal_code: '71307'
  },
  '71309': {
    country: 'US',
    city: 'Alexandria',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.3047,
    longitude: -92.6196,
    postal_code: '71309'
  },
  '71315': {
    country: 'US',
    city: 'Alexandria',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.1397,
    longitude: -92.3984,
    postal_code: '71315'
  },
  '71316': {
    country: 'US',
    city: 'Acme',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Concordia',
    latitude: 31.3016,
    longitude: -91.8216,
    postal_code: '71316'
  },
  '71320': {
    country: 'US',
    city: 'Bordelonville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 31.1055,
    longitude: -91.9073,
    postal_code: '71320'
  },
  '71322': {
    country: 'US',
    city: 'Bunkie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 30.8641,
    longitude: -92.1466,
    postal_code: '71322'
  },
  '71323': {
    country: 'US',
    city: 'Center Point',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 31.2482,
    longitude: -92.2099,
    postal_code: '71323'
  },
  '71324': {
    country: 'US',
    city: 'Chase',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Franklin',
    latitude: 32.0971,
    longitude: -91.699,
    postal_code: '71324'
  },
  '71325': {
    country: 'US',
    city: 'Cheneyville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.0201,
    longitude: -92.2951,
    postal_code: '71325'
  },
  '71326': {
    country: 'US',
    city: 'Clayton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Concordia',
    latitude: 31.7158,
    longitude: -91.5163,
    postal_code: '71326'
  },
  '71327': {
    country: 'US',
    city: 'Cottonport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 30.9862,
    longitude: -92.0581,
    postal_code: '71327'
  },
  '71328': {
    country: 'US',
    city: 'Deville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.3574,
    longitude: -92.1654,
    postal_code: '71328'
  },
  '71329': {
    country: 'US',
    city: 'Dupont',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 30.9294,
    longitude: -91.9479,
    postal_code: '71329'
  },
  '71330': {
    country: 'US',
    city: 'Echo',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.1093,
    longitude: -92.2395,
    postal_code: '71330'
  },
  '71331': {
    country: 'US',
    city: 'Effie',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 31.2275,
    longitude: -92.0967,
    postal_code: '71331'
  },
  '71333': {
    country: 'US',
    city: 'Evergreen',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 30.9161,
    longitude: -92.067,
    postal_code: '71333'
  },
  '71334': {
    country: 'US',
    city: 'Ferriday',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Concordia',
    latitude: 31.6602,
    longitude: -91.5502,
    postal_code: '71334'
  },
  '71336': {
    country: 'US',
    city: 'Gilbert',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Franklin',
    latitude: 32.0349,
    longitude: -91.592,
    postal_code: '71336'
  },
  '71339': {
    country: 'US',
    city: 'Hamburg',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 31.0307,
    longitude: -91.9315,
    postal_code: '71339'
  },
  '71340': {
    country: 'US',
    city: 'Harrisonburg',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Catahoula',
    latitude: 31.7779,
    longitude: -91.8133,
    postal_code: '71340'
  },
  '71341': {
    country: 'US',
    city: 'Hessmer',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 31.0534,
    longitude: -92.1399,
    postal_code: '71341'
  },
  '71342': {
    country: 'US',
    city: 'Jena',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'La Salle',
    latitude: 31.6748,
    longitude: -92.1137,
    postal_code: '71342'
  },
  '71343': {
    country: 'US',
    city: 'Jonesville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Catahoula',
    latitude: 31.6862,
    longitude: -91.8678,
    postal_code: '71343'
  },
  '71345': {
    country: 'US',
    city: 'Lebeau',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.7292,
    longitude: -91.9755,
    postal_code: '71345'
  },
  '71346': {
    country: 'US',
    city: 'Lecompte',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.106,
    longitude: -92.389,
    postal_code: '71346'
  },
  '71348': {
    country: 'US',
    city: 'Libuse',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.3541,
    longitude: -92.3335,
    postal_code: '71348'
  },
  '71350': {
    country: 'US',
    city: 'Mansura',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 31.0615,
    longitude: -92.0543,
    postal_code: '71350'
  },
  '71351': {
    country: 'US',
    city: 'Marksville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 31.1396,
    longitude: -92.0831,
    postal_code: '71351'
  },
  '71353': {
    country: 'US',
    city: 'Melville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.6626,
    longitude: -91.7565,
    postal_code: '71353'
  },
  '71354': {
    country: 'US',
    city: 'Monterey',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Concordia',
    latitude: 31.4499,
    longitude: -91.7182,
    postal_code: '71354'
  },
  '71355': {
    country: 'US',
    city: 'Moreauville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 31.0988,
    longitude: -91.8748,
    postal_code: '71355'
  },
  '71356': {
    country: 'US',
    city: 'Morrow',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.8299,
    longitude: -92.0426,
    postal_code: '71356'
  },
  '71357': {
    country: 'US',
    city: 'Newellton',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tensas',
    latitude: 32.0656,
    longitude: -91.2578,
    postal_code: '71357'
  },
  '71358': {
    country: 'US',
    city: 'Palmetto',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'St. Landry',
    latitude: 30.7065,
    longitude: -91.9114,
    postal_code: '71358'
  },
  '71359': {
    country: 'US',
    city: 'Pineville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.3224,
    longitude: -92.4343,
    postal_code: '71359'
  },
  '71360': {
    country: 'US',
    city: 'Pineville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.2232,
    longitude: -92.3203,
    postal_code: '71360'
  },
  '71361': {
    country: 'US',
    city: 'Pineville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.3692,
    longitude: -92.4198,
    postal_code: '71361'
  },
  '71362': {
    country: 'US',
    city: 'Plaucheville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 30.9365,
    longitude: -91.9847,
    postal_code: '71362'
  },
  '71363': {
    country: 'US',
    city: 'Rhinehart',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Catahoula',
    latitude: 31.6377,
    longitude: -92.0068,
    postal_code: '71363'
  },
  '71365': {
    country: 'US',
    city: 'Ruby',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.1894,
    longitude: -92.2487,
    postal_code: '71365'
  },
  '71366': {
    country: 'US',
    city: 'Saint Joseph',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tensas',
    latitude: 31.9248,
    longitude: -91.2784,
    postal_code: '71366'
  },
  '71367': {
    country: 'US',
    city: 'Saint Landry',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Evangeline',
    latitude: 30.8998,
    longitude: -92.2964,
    postal_code: '71367'
  },
  '71368': {
    country: 'US',
    city: 'Sicily Island',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Catahoula',
    latitude: 31.8507,
    longitude: -91.6807,
    postal_code: '71368'
  },
  '71369': {
    country: 'US',
    city: 'Simmesport',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Avoyelles',
    latitude: 30.9771,
    longitude: -91.8259,
    postal_code: '71369'
  },
  '71371': {
    country: 'US',
    city: 'Trout',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'La Salle',
    latitude: 31.6531,
    longitude: -92.1993,
    postal_code: '71371'
  },
  '71373': {
    country: 'US',
    city: 'Vidalia',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Concordia',
    latitude: 31.5782,
    longitude: -91.4695,
    postal_code: '71373'
  },
  '71375': {
    country: 'US',
    city: 'Waterproof',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Tensas',
    latitude: 31.8076,
    longitude: -91.3872,
    postal_code: '71375'
  },
  '71377': {
    country: 'US',
    city: 'Wildsville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Concordia',
    latitude: 31.6094,
    longitude: -91.7808,
    postal_code: '71377'
  },
  '71378': {
    country: 'US',
    city: 'Wisner',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Franklin',
    latitude: 31.9913,
    longitude: -91.6768,
    postal_code: '71378'
  },
  '71401': {
    country: 'US',
    city: 'Aimwell',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Catahoula',
    latitude: 31.7835,
    longitude: -91.9935,
    postal_code: '71401'
  },
  '71403': {
    country: 'US',
    city: 'Anacoco',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 31.2149,
    longitude: -93.3829,
    postal_code: '71403'
  },
  '71404': {
    country: 'US',
    city: 'Atlanta',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Winn',
    latitude: 31.776,
    longitude: -92.7958,
    postal_code: '71404'
  },
  '71405': {
    country: 'US',
    city: 'Ball',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.4151,
    longitude: -92.3945,
    postal_code: '71405'
  },
  '71406': {
    country: 'US',
    city: 'Belmont',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Sabine',
    latitude: 31.71,
    longitude: -93.4959,
    postal_code: '71406'
  },
  '71407': {
    country: 'US',
    city: 'Bentley',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Grant',
    latitude: 31.5157,
    longitude: -92.4882,
    postal_code: '71407'
  },
  '71409': {
    country: 'US',
    city: 'Boyce',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.3216,
    longitude: -92.6867,
    postal_code: '71409'
  },
  '71410': {
    country: 'US',
    city: 'Calvin',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Winn',
    latitude: 31.9657,
    longitude: -92.7757,
    postal_code: '71410'
  },
  '71411': {
    country: 'US',
    city: 'Campti',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.8959,
    longitude: -93.0936,
    postal_code: '71411'
  },
  '71414': {
    country: 'US',
    city: 'Clarence',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.8216,
    longitude: -93.0296,
    postal_code: '71414'
  },
  '71415': {
    country: 'US',
    city: 'Clarks',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caldwell',
    latitude: 32.0344,
    longitude: -92.1381,
    postal_code: '71415'
  },
  '71416': {
    country: 'US',
    city: 'Cloutierville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.5432,
    longitude: -92.9182,
    postal_code: '71416'
  },
  '71417': {
    country: 'US',
    city: 'Colfax',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Grant',
    latitude: 31.5079,
    longitude: -92.6568,
    postal_code: '71417'
  },
  '71418': {
    country: 'US',
    city: 'Columbia',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caldwell',
    latitude: 32.1022,
    longitude: -92.1177,
    postal_code: '71418'
  },
  '71419': {
    country: 'US',
    city: 'Converse',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Sabine',
    latitude: 31.7339,
    longitude: -93.6902,
    postal_code: '71419'
  },
  '71422': {
    country: 'US',
    city: 'Dodson',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Winn',
    latitude: 32.0701,
    longitude: -92.6783,
    postal_code: '71422'
  },
  '71423': {
    country: 'US',
    city: 'Dry Prong',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Grant',
    latitude: 31.5979,
    longitude: -92.5665,
    postal_code: '71423'
  },
  '71424': {
    country: 'US',
    city: 'Elmer',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.1465,
    longitude: -92.7171,
    postal_code: '71424'
  },
  '71425': {
    country: 'US',
    city: 'Enterprise',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Catahoula',
    latitude: 31.9064,
    longitude: -91.8751,
    postal_code: '71425'
  },
  '71426': {
    country: 'US',
    city: 'Fisher',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Sabine',
    latitude: 31.4937,
    longitude: -93.4602,
    postal_code: '71426'
  },
  '71427': {
    country: 'US',
    city: 'Flatwoods',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.3849,
    longitude: -92.8812,
    postal_code: '71427'
  },
  '71428': {
    country: 'US',
    city: 'Flora',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.6124,
    longitude: -93.098,
    postal_code: '71428'
  },
  '71429': {
    country: 'US',
    city: 'Florien',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Sabine',
    latitude: 31.4275,
    longitude: -93.5179,
    postal_code: '71429'
  },
  '71430': {
    country: 'US',
    city: 'Forest Hill',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.0243,
    longitude: -92.5108,
    postal_code: '71430'
  },
  '71431': {
    country: 'US',
    city: 'Gardner',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.2587,
    longitude: -92.6775,
    postal_code: '71431'
  },
  '71432': {
    country: 'US',
    city: 'Georgetown',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Grant',
    latitude: 31.745,
    longitude: -92.3952,
    postal_code: '71432'
  },
  '71433': {
    country: 'US',
    city: 'Glenmora',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.0262,
    longitude: -92.616,
    postal_code: '71433'
  },
  '71434': {
    country: 'US',
    city: 'Gorum',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.433,
    longitude: -92.9435,
    postal_code: '71434'
  },
  '71435': {
    country: 'US',
    city: 'Grayson',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caldwell',
    latitude: 32.067,
    longitude: -92.1698,
    postal_code: '71435'
  },
  '71438': {
    country: 'US',
    city: 'Hineston',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.1669,
    longitude: -92.7375,
    postal_code: '71438'
  },
  '71439': {
    country: 'US',
    city: 'Hornbeck',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 31.3226,
    longitude: -93.3683,
    postal_code: '71439'
  },
  '71440': {
    country: 'US',
    city: 'Joyce',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Winn',
    latitude: 31.9393,
    longitude: -92.5988,
    postal_code: '71440'
  },
  '71441': {
    country: 'US',
    city: 'Kelly',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Caldwell',
    latitude: 31.9648,
    longitude: -92.1825,
    postal_code: '71441'
  },
  '71443': {
    country: 'US',
    city: 'Kurthwood',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 31.3374,
    longitude: -93.1657,
    postal_code: '71443'
  },
  '71446': {
    country: 'US',
    city: 'Leesville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 31.1256,
    longitude: -93.1736,
    postal_code: '71446'
  },
  '71447': {
    country: 'US',
    city: 'Lena',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.4207,
    longitude: -92.8312,
    postal_code: '71447'
  },
  '71448': {
    country: 'US',
    city: 'Longleaf',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.0066,
    longitude: -92.5526,
    postal_code: '71448'
  },
  '71449': {
    country: 'US',
    city: 'Many',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Sabine',
    latitude: 31.5851,
    longitude: -93.4641,
    postal_code: '71449'
  },
  '71450': {
    country: 'US',
    city: 'Marthaville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.7726,
    longitude: -93.3954,
    postal_code: '71450'
  },
  '71452': {
    country: 'US',
    city: 'Melrose',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.6104,
    longitude: -92.9745,
    postal_code: '71452'
  },
  '71454': {
    country: 'US',
    city: 'Montgomery',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Grant',
    latitude: 31.6674,
    longitude: -92.8412,
    postal_code: '71454'
  },
  '71455': {
    country: 'US',
    city: 'Mora',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.4016,
    longitude: -92.974,
    postal_code: '71455'
  },
  '71456': {
    country: 'US',
    city: 'Natchez',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.6615,
    longitude: -93.0241,
    postal_code: '71456'
  },
  '71457': {
    country: 'US',
    city: 'Natchitoches',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.7617,
    longitude: -93.0916,
    postal_code: '71457'
  },
  '71458': {
    country: 'US',
    city: 'Natchitoches',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.7476,
    longitude: -93.0791,
    postal_code: '71458'
  },
  '71459': {
    country: 'US',
    city: 'Fort Polk',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 31.0466,
    longitude: -93.2054,
    postal_code: '71459'
  },
  '71460': {
    country: 'US',
    city: 'Negreet',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Sabine',
    latitude: 31.4693,
    longitude: -93.5749,
    postal_code: '71460'
  },
  '71461': {
    country: 'US',
    city: 'New Llano',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 31.1149,
    longitude: -93.2716,
    postal_code: '71461'
  },
  '71462': {
    country: 'US',
    city: 'Noble',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Sabine',
    latitude: 31.6938,
    longitude: -93.7167,
    postal_code: '71462'
  },
  '71463': {
    country: 'US',
    city: 'Oakdale',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Allen',
    latitude: 30.8172,
    longitude: -92.664,
    postal_code: '71463'
  },
  '71465': {
    country: 'US',
    city: 'Olla',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'La Salle',
    latitude: 31.8734,
    longitude: -92.2214,
    postal_code: '71465'
  },
  '71466': {
    country: 'US',
    city: 'Otis',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.2077,
    longitude: -92.7392,
    postal_code: '71466'
  },
  '71467': {
    country: 'US',
    city: 'Pollock',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Grant',
    latitude: 31.5,
    longitude: -92.4005,
    postal_code: '71467'
  },
  '71468': {
    country: 'US',
    city: 'Provencal',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.5157,
    longitude: -93.1488,
    postal_code: '71468'
  },
  '71469': {
    country: 'US',
    city: 'Robeline',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.7715,
    longitude: -93.321,
    postal_code: '71469'
  },
  '71471': {
    country: 'US',
    city: 'Saint Maurice',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Winn',
    latitude: 31.7596,
    longitude: -92.959,
    postal_code: '71471'
  },
  '71472': {
    country: 'US',
    city: 'Sieper',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.1976,
    longitude: -92.7858,
    postal_code: '71472'
  },
  '71473': {
    country: 'US',
    city: 'Sikes',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Winn',
    latitude: 32.0686,
    longitude: -92.4429,
    postal_code: '71473'
  },
  '71474': {
    country: 'US',
    city: 'Simpson',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 31.257,
    longitude: -93.017,
    postal_code: '71474'
  },
  '71475': {
    country: 'US',
    city: 'Slagle',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 31.2024,
    longitude: -93.1274,
    postal_code: '71475'
  },
  '71477': {
    country: 'US',
    city: 'Tioga',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.3999,
    longitude: -92.6042,
    postal_code: '71477'
  },
  '71479': {
    country: 'US',
    city: 'Tullos',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'La Salle',
    latitude: 31.8569,
    longitude: -92.374,
    postal_code: '71479'
  },
  '71480': {
    country: 'US',
    city: 'Urania',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'La Salle',
    latitude: 31.865,
    longitude: -92.2915,
    postal_code: '71480'
  },
  '71483': {
    country: 'US',
    city: 'Winnfield',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Winn',
    latitude: 31.9214,
    longitude: -92.6366,
    postal_code: '71483'
  },
  '71485': {
    country: 'US',
    city: 'Woodworth',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Rapides',
    latitude: 31.1635,
    longitude: -92.5322,
    postal_code: '71485'
  },
  '71486': {
    country: 'US',
    city: 'Zwolle',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Sabine',
    latitude: 31.6138,
    longitude: -93.6636,
    postal_code: '71486'
  },
  '71496': {
    country: 'US',
    city: 'Leesville',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Vernon',
    latitude: 31.1435,
    longitude: -93.261,
    postal_code: '71496'
  },
  '71497': {
    country: 'US',
    city: 'Natchitoches',
    state: 'Louisiana',
    state_short: 'LA',
    county: 'Natchitoches',
    latitude: 31.7476,
    longitude: -93.0791,
    postal_code: '71497'
  },
  '71601': {
    country: 'US',
    city: 'Pine Bluff',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.209,
    longitude: -91.9859,
    postal_code: '71601'
  },
  '71602': {
    country: 'US',
    city: 'White Hall',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.274,
    longitude: -92.091,
    postal_code: '71602'
  },
  '71603': {
    country: 'US',
    city: 'Pine Bluff',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.1897,
    longitude: -92.0448,
    postal_code: '71603'
  },
  '71611': {
    country: 'US',
    city: 'Pine Bluff',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.2615,
    longitude: -91.9551,
    postal_code: '71611'
  },
  '71612': {
    country: 'US',
    city: 'White Hall',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.274,
    longitude: -92.091,
    postal_code: '71612'
  },
  '71613': {
    country: 'US',
    city: 'Pine Bluff',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.1579,
    longitude: -92.0713,
    postal_code: '71613'
  },
  '71630': {
    country: 'US',
    city: 'Arkansas City',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Desha',
    latitude: 33.6143,
    longitude: -91.2325,
    postal_code: '71630'
  },
  '71631': {
    country: 'US',
    city: 'Banks',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Bradley',
    latitude: 33.5497,
    longitude: -92.2604,
    postal_code: '71631'
  },
  '71635': {
    country: 'US',
    city: 'Crossett',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ashley',
    latitude: 33.156,
    longitude: -91.9975,
    postal_code: '71635'
  },
  '71638': {
    country: 'US',
    city: 'Dermott',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Chicot',
    latitude: 33.5241,
    longitude: -91.4394,
    postal_code: '71638'
  },
  '71639': {
    country: 'US',
    city: 'Dumas',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Desha',
    latitude: 33.8921,
    longitude: -91.4861,
    postal_code: '71639'
  },
  '71640': {
    country: 'US',
    city: 'Eudora',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Chicot',
    latitude: 33.1213,
    longitude: -91.2716,
    postal_code: '71640'
  },
  '71642': {
    country: 'US',
    city: 'Fountain Hill',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ashley',
    latitude: 33.3631,
    longitude: -91.9088,
    postal_code: '71642'
  },
  '71643': {
    country: 'US',
    city: 'Gould',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lincoln',
    latitude: 34.0345,
    longitude: -91.5768,
    postal_code: '71643'
  },
  '71644': {
    country: 'US',
    city: 'Grady',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lincoln',
    latitude: 34.1032,
    longitude: -91.7057,
    postal_code: '71644'
  },
  '71646': {
    country: 'US',
    city: 'Hamburg',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ashley',
    latitude: 33.2058,
    longitude: -91.8023,
    postal_code: '71646'
  },
  '71647': {
    country: 'US',
    city: 'Hermitage',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Bradley',
    latitude: 33.39,
    longitude: -92.156,
    postal_code: '71647'
  },
  '71651': {
    country: 'US',
    city: 'Jersey',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Bradley',
    latitude: 33.3889,
    longitude: -92.2966,
    postal_code: '71651'
  },
  '71652': {
    country: 'US',
    city: 'Kingsland',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleveland',
    latitude: 33.86,
    longitude: -92.3014,
    postal_code: '71652'
  },
  '71653': {
    country: 'US',
    city: 'Lake Village',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Chicot',
    latitude: 33.3274,
    longitude: -91.2825,
    postal_code: '71653'
  },
  '71654': {
    country: 'US',
    city: 'Mc Gehee',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Desha',
    latitude: 33.6297,
    longitude: -91.3928,
    postal_code: '71654'
  },
  '71655': {
    country: 'US',
    city: 'Monticello',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Drew',
    latitude: 33.625,
    longitude: -91.7948,
    postal_code: '71655'
  },
  '71656': {
    country: 'US',
    city: 'Monticello',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Drew',
    latitude: 33.629,
    longitude: -91.791,
    postal_code: '71656'
  },
  '71657': {
    country: 'US',
    city: 'Monticello',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Drew',
    latitude: 33.629,
    longitude: -91.791,
    postal_code: '71657'
  },
  '71658': {
    country: 'US',
    city: 'Montrose',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ashley',
    latitude: 33.3075,
    longitude: -91.5228,
    postal_code: '71658'
  },
  '71659': {
    country: 'US',
    city: 'Moscow',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.1465,
    longitude: -91.7951,
    postal_code: '71659'
  },
  '71660': {
    country: 'US',
    city: 'New Edinburg',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleveland',
    latitude: 33.7588,
    longitude: -92.1939,
    postal_code: '71660'
  },
  '71661': {
    country: 'US',
    city: 'Parkdale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ashley',
    latitude: 33.1213,
    longitude: -91.5428,
    postal_code: '71661'
  },
  '71662': {
    country: 'US',
    city: 'Pickens',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Desha',
    latitude: 33.842,
    longitude: -91.5022,
    postal_code: '71662'
  },
  '71663': {
    country: 'US',
    city: 'Portland',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ashley',
    latitude: 33.2318,
    longitude: -91.5139,
    postal_code: '71663'
  },
  '71665': {
    country: 'US',
    city: 'Rison',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleveland',
    latitude: 33.9453,
    longitude: -92.1188,
    postal_code: '71665'
  },
  '71666': {
    country: 'US',
    city: 'Rohwer',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Desha',
    latitude: 33.7609,
    longitude: -91.2757,
    postal_code: '71666'
  },
  '71667': {
    country: 'US',
    city: 'Star City',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lincoln',
    latitude: 33.9405,
    longitude: -91.8653,
    postal_code: '71667'
  },
  '71670': {
    country: 'US',
    city: 'Tillar',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Desha',
    latitude: 33.6981,
    longitude: -91.4433,
    postal_code: '71670'
  },
  '71671': {
    country: 'US',
    city: 'Warren',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Bradley',
    latitude: 33.614,
    longitude: -92.0778,
    postal_code: '71671'
  },
  '71674': {
    country: 'US',
    city: 'Watson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Desha',
    latitude: 33.8907,
    longitude: -91.2815,
    postal_code: '71674'
  },
  '71675': {
    country: 'US',
    city: 'Wilmar',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Drew',
    latitude: 33.6213,
    longitude: -91.9257,
    postal_code: '71675'
  },
  '71676': {
    country: 'US',
    city: 'Wilmot',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ashley',
    latitude: 33.0576,
    longitude: -91.5723,
    postal_code: '71676'
  },
  '71677': {
    country: 'US',
    city: 'Winchester',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Drew',
    latitude: 33.7471,
    longitude: -91.4649,
    postal_code: '71677'
  },
  '71678': {
    country: 'US',
    city: 'Yorktown',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lincoln',
    latitude: 33.9787,
    longitude: -91.6991,
    postal_code: '71678'
  },
  '71701': {
    country: 'US',
    city: 'Camden',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ouachita',
    latitude: 33.5948,
    longitude: -92.8513,
    postal_code: '71701'
  },
  '71711': {
    country: 'US',
    city: 'Camden',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ouachita',
    latitude: 33.589,
    longitude: -92.8426,
    postal_code: '71711'
  },
  '71720': {
    country: 'US',
    city: 'Bearden',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ouachita',
    latitude: 33.7298,
    longitude: -92.618,
    postal_code: '71720'
  },
  '71721': {
    country: 'US',
    city: 'Beirne',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clark',
    latitude: 33.8887,
    longitude: -93.2041,
    postal_code: '71721'
  },
  '71722': {
    country: 'US',
    city: 'Bluff City',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Nevada',
    latitude: 33.7038,
    longitude: -93.1588,
    postal_code: '71722'
  },
  '71724': {
    country: 'US',
    city: 'Calion',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Union',
    latitude: 33.3261,
    longitude: -92.5464,
    postal_code: '71724'
  },
  '71725': {
    country: 'US',
    city: 'Carthage',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Dallas',
    latitude: 34.0751,
    longitude: -92.5554,
    postal_code: '71725'
  },
  '71726': {
    country: 'US',
    city: 'Chidester',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ouachita',
    latitude: 33.6652,
    longitude: -92.9969,
    postal_code: '71726'
  },
  '71728': {
    country: 'US',
    city: 'Curtis',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clark',
    latitude: 34.0103,
    longitude: -93.0976,
    postal_code: '71728'
  },
  '71730': {
    country: 'US',
    city: 'El Dorado',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Union',
    latitude: 33.2073,
    longitude: -92.6629,
    postal_code: '71730'
  },
  '71731': {
    country: 'US',
    city: 'El Dorado',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Union',
    latitude: 33.2076,
    longitude: -92.6663,
    postal_code: '71731'
  },
  '71740': {
    country: 'US',
    city: 'Emerson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Columbia',
    latitude: 33.0891,
    longitude: -93.1987,
    postal_code: '71740'
  },
  '71742': {
    country: 'US',
    city: 'Fordyce',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Dallas',
    latitude: 33.8176,
    longitude: -92.4225,
    postal_code: '71742'
  },
  '71743': {
    country: 'US',
    city: 'Gurdon',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clark',
    latitude: 33.9125,
    longitude: -93.1417,
    postal_code: '71743'
  },
  '71744': {
    country: 'US',
    city: 'Hampton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Calhoun',
    latitude: 33.5376,
    longitude: -92.5295,
    postal_code: '71744'
  },
  '71745': {
    country: 'US',
    city: 'Harrell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Calhoun',
    latitude: 33.5101,
    longitude: -92.3988,
    postal_code: '71745'
  },
  '71747': {
    country: 'US',
    city: 'Huttig',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Union',
    latitude: 33.0459,
    longitude: -92.1942,
    postal_code: '71747'
  },
  '71748': {
    country: 'US',
    city: 'Ivan',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Dallas',
    latitude: 33.9118,
    longitude: -92.4246,
    postal_code: '71748'
  },
  '71749': {
    country: 'US',
    city: 'Junction City',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Union',
    latitude: 33.0163,
    longitude: -92.7243,
    postal_code: '71749'
  },
  '71750': {
    country: 'US',
    city: 'Lawson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Union',
    latitude: 33.1968,
    longitude: -92.4829,
    postal_code: '71750'
  },
  '71751': {
    country: 'US',
    city: 'Louann',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ouachita',
    latitude: 33.4169,
    longitude: -92.777,
    postal_code: '71751'
  },
  '71752': {
    country: 'US',
    city: 'Mc Neil',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Columbia',
    latitude: 33.3622,
    longitude: -93.193,
    postal_code: '71752'
  },
  '71753': {
    country: 'US',
    city: 'Magnolia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Columbia',
    latitude: 33.2647,
    longitude: -93.2392,
    postal_code: '71753'
  },
  '71754': {
    country: 'US',
    city: 'Magnolia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Columbia',
    latitude: 33.2426,
    longitude: -93.23,
    postal_code: '71754'
  },
  '71758': {
    country: 'US',
    city: 'Mount Holly',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Union',
    latitude: 33.3085,
    longitude: -92.9443,
    postal_code: '71758'
  },
  '71759': {
    country: 'US',
    city: 'Norphlet',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Union',
    latitude: 33.316,
    longitude: -92.6629,
    postal_code: '71759'
  },
  '71762': {
    country: 'US',
    city: 'Smackover',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Union',
    latitude: 33.3398,
    longitude: -92.7442,
    postal_code: '71762'
  },
  '71763': {
    country: 'US',
    city: 'Sparkman',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Dallas',
    latitude: 33.9143,
    longitude: -92.8532,
    postal_code: '71763'
  },
  '71764': {
    country: 'US',
    city: 'Stephens',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Ouachita',
    latitude: 33.455,
    longitude: -93.0214,
    postal_code: '71764'
  },
  '71765': {
    country: 'US',
    city: 'Strong',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Union',
    latitude: 33.1195,
    longitude: -92.3621,
    postal_code: '71765'
  },
  '71766': {
    country: 'US',
    city: 'Thornton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Calhoun',
    latitude: 33.7768,
    longitude: -92.4862,
    postal_code: '71766'
  },
  '71770': {
    country: 'US',
    city: 'Waldo',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Columbia',
    latitude: 33.36,
    longitude: -93.2949,
    postal_code: '71770'
  },
  '71772': {
    country: 'US',
    city: 'Whelen Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clark',
    latitude: 33.8309,
    longitude: -93.1263,
    postal_code: '71772'
  },
  '71801': {
    country: 'US',
    city: 'Hope',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hempstead',
    latitude: 33.6736,
    longitude: -93.6068,
    postal_code: '71801'
  },
  '71802': {
    country: 'US',
    city: 'Hope',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hempstead',
    latitude: 33.6671,
    longitude: -93.5916,
    postal_code: '71802'
  },
  '71820': {
    country: 'US',
    city: 'Alleene',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Little River',
    latitude: 33.7944,
    longitude: -94.2681,
    postal_code: '71820'
  },
  '71822': {
    country: 'US',
    city: 'Ashdown',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Little River',
    latitude: 33.6787,
    longitude: -94.1351,
    postal_code: '71822'
  },
  '71823': {
    country: 'US',
    city: 'Ben Lomond',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sevier',
    latitude: 33.8115,
    longitude: -94.1251,
    postal_code: '71823'
  },
  '71825': {
    country: 'US',
    city: 'Blevins',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hempstead',
    latitude: 33.8718,
    longitude: -93.5771,
    postal_code: '71825'
  },
  '71826': {
    country: 'US',
    city: 'Bradley',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lafayette',
    latitude: 33.107,
    longitude: -93.6275,
    postal_code: '71826'
  },
  '71827': {
    country: 'US',
    city: 'Buckner',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lafayette',
    latitude: 33.3753,
    longitude: -93.447,
    postal_code: '71827'
  },
  '71828': {
    country: 'US',
    city: 'Cale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Nevada',
    latitude: 33.7008,
    longitude: -93.2941,
    postal_code: '71828'
  },
  '71831': {
    country: 'US',
    city: 'Columbus',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hempstead',
    latitude: 33.7935,
    longitude: -93.807,
    postal_code: '71831'
  },
  '71832': {
    country: 'US',
    city: 'De Queen',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sevier',
    latitude: 34.0442,
    longitude: -94.3386,
    postal_code: '71832'
  },
  '71833': {
    country: 'US',
    city: 'Dierks',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Howard',
    latitude: 34.1323,
    longitude: -94.0152,
    postal_code: '71833'
  },
  '71834': {
    country: 'US',
    city: 'Doddridge',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Miller',
    latitude: 33.1054,
    longitude: -93.9543,
    postal_code: '71834'
  },
  '71835': {
    country: 'US',
    city: 'Emmet',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Nevada',
    latitude: 33.6929,
    longitude: -93.4232,
    postal_code: '71835'
  },
  '71836': {
    country: 'US',
    city: 'Foreman',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Little River',
    latitude: 33.7176,
    longitude: -94.3881,
    postal_code: '71836'
  },
  '71837': {
    country: 'US',
    city: 'Fouke',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Miller',
    latitude: 33.3025,
    longitude: -93.901,
    postal_code: '71837'
  },
  '71838': {
    country: 'US',
    city: 'Fulton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hempstead',
    latitude: 33.6299,
    longitude: -93.8086,
    postal_code: '71838'
  },
  '71839': {
    country: 'US',
    city: 'Garland City',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lafayette',
    latitude: 33.3352,
    longitude: -93.7316,
    postal_code: '71839'
  },
  '71840': {
    country: 'US',
    city: 'Genoa',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Miller',
    latitude: 33.3837,
    longitude: -93.9096,
    postal_code: '71840'
  },
  '71841': {
    country: 'US',
    city: 'Gillham',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sevier',
    latitude: 34.157,
    longitude: -94.3165,
    postal_code: '71841'
  },
  '71842': {
    country: 'US',
    city: 'Horatio',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sevier',
    latitude: 33.9392,
    longitude: -94.2959,
    postal_code: '71842'
  },
  '71845': {
    country: 'US',
    city: 'Lewisville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lafayette',
    latitude: 33.3736,
    longitude: -93.5953,
    postal_code: '71845'
  },
  '71846': {
    country: 'US',
    city: 'Lockesburg',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sevier',
    latitude: 33.9306,
    longitude: -94.1276,
    postal_code: '71846'
  },
  '71847': {
    country: 'US',
    city: 'Mc Caskill',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hempstead',
    latitude: 33.923,
    longitude: -93.6266,
    postal_code: '71847'
  },
  '71851': {
    country: 'US',
    city: 'Mineral Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Howard',
    latitude: 33.8639,
    longitude: -93.9188,
    postal_code: '71851'
  },
  '71852': {
    country: 'US',
    city: 'Nashville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Howard',
    latitude: 33.9576,
    longitude: -93.8707,
    postal_code: '71852'
  },
  '71853': {
    country: 'US',
    city: 'Ogden',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Little River',
    latitude: 33.5826,
    longitude: -94.0427,
    postal_code: '71853'
  },
  '71854': {
    country: 'US',
    city: 'Texarkana',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Miller',
    latitude: 33.431,
    longitude: -93.8765,
    postal_code: '71854'
  },
  '71855': {
    country: 'US',
    city: 'Ozan',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hempstead',
    latitude: 33.9028,
    longitude: -93.7714,
    postal_code: '71855'
  },
  '71857': {
    country: 'US',
    city: 'Prescott',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Nevada',
    latitude: 33.804,
    longitude: -93.3725,
    postal_code: '71857'
  },
  '71858': {
    country: 'US',
    city: 'Rosston',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Nevada',
    latitude: 33.5926,
    longitude: -93.2766,
    postal_code: '71858'
  },
  '71859': {
    country: 'US',
    city: 'Saratoga',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Howard',
    latitude: 33.7599,
    longitude: -93.8767,
    postal_code: '71859'
  },
  '71860': {
    country: 'US',
    city: 'Stamps',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lafayette',
    latitude: 33.3569,
    longitude: -93.5013,
    postal_code: '71860'
  },
  '71861': {
    country: 'US',
    city: 'Taylor',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Columbia',
    latitude: 33.108,
    longitude: -93.446,
    postal_code: '71861'
  },
  '71862': {
    country: 'US',
    city: 'Washington',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hempstead',
    latitude: 33.7546,
    longitude: -93.6735,
    postal_code: '71862'
  },
  '71864': {
    country: 'US',
    city: 'Willisville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Nevada',
    latitude: 33.7008,
    longitude: -93.2941,
    postal_code: '71864'
  },
  '71865': {
    country: 'US',
    city: 'Wilton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Little River',
    latitude: 33.7475,
    longitude: -94.1518,
    postal_code: '71865'
  },
  '71866': {
    country: 'US',
    city: 'Winthrop',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Little River',
    latitude: 33.8583,
    longitude: -94.3952,
    postal_code: '71866'
  },
  '71901': {
    country: 'US',
    city: 'Hot Springs National Park',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.5268,
    longitude: -92.9587,
    postal_code: '71901'
  },
  '71902': {
    country: 'US',
    city: 'Hot Springs National Park',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.5814,
    longitude: -93.0994,
    postal_code: '71902'
  },
  '71903': {
    country: 'US',
    city: 'Hot Springs National Park',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.5814,
    longitude: -93.0994,
    postal_code: '71903'
  },
  '71909': {
    country: 'US',
    city: 'Hot Springs Village',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.6484,
    longitude: -92.983,
    postal_code: '71909'
  },
  '71910': {
    country: 'US',
    city: 'Hot Springs Village',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.672,
    longitude: -92.9988,
    postal_code: '71910'
  },
  '71913': {
    country: 'US',
    city: 'Hot Springs National Park',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.5317,
    longitude: -93.0638,
    postal_code: '71913'
  },
  '71914': {
    country: 'US',
    city: 'Hot Springs National Park',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.5137,
    longitude: -92.9685,
    postal_code: '71914'
  },
  '71920': {
    country: 'US',
    city: 'Alpine',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clark',
    latitude: 34.229,
    longitude: -93.3791,
    postal_code: '71920'
  },
  '71921': {
    country: 'US',
    city: 'Amity',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clark',
    latitude: 34.2594,
    longitude: -93.4206,
    postal_code: '71921'
  },
  '71922': {
    country: 'US',
    city: 'Antoine',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pike',
    latitude: 34.0362,
    longitude: -93.4207,
    postal_code: '71922'
  },
  '71923': {
    country: 'US',
    city: 'Arkadelphia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clark',
    latitude: 34.1153,
    longitude: -93.069,
    postal_code: '71923'
  },
  '71929': {
    country: 'US',
    city: 'Bismarck',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hot Spring',
    latitude: 34.311,
    longitude: -93.1872,
    postal_code: '71929'
  },
  '71932': {
    country: 'US',
    city: 'Board Camp',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Polk',
    latitude: 34.5372,
    longitude: -94.0972,
    postal_code: '71932'
  },
  '71933': {
    country: 'US',
    city: 'Bonnerdale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hot Spring',
    latitude: 34.3848,
    longitude: -93.3819,
    postal_code: '71933'
  },
  '71935': {
    country: 'US',
    city: 'Caddo Gap',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Montgomery',
    latitude: 34.3913,
    longitude: -93.7637,
    postal_code: '71935'
  },
  '71937': {
    country: 'US',
    city: 'Cove',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Polk',
    latitude: 34.4192,
    longitude: -94.3923,
    postal_code: '71937'
  },
  '71940': {
    country: 'US',
    city: 'Delight',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pike',
    latitude: 34.0238,
    longitude: -93.5247,
    postal_code: '71940'
  },
  '71941': {
    country: 'US',
    city: 'Donaldson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hot Spring',
    latitude: 34.2212,
    longitude: -92.9094,
    postal_code: '71941'
  },
  '71942': {
    country: 'US',
    city: 'Friendship',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hot Spring',
    latitude: 34.2235,
    longitude: -93.0024,
    postal_code: '71942'
  },
  '71943': {
    country: 'US',
    city: 'Glenwood',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pike',
    latitude: 34.3192,
    longitude: -93.5559,
    postal_code: '71943'
  },
  '71944': {
    country: 'US',
    city: 'Grannis',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Polk',
    latitude: 34.237,
    longitude: -94.3255,
    postal_code: '71944'
  },
  '71945': {
    country: 'US',
    city: 'Hatfield',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Polk',
    latitude: 34.4877,
    longitude: -94.3714,
    postal_code: '71945'
  },
  '71949': {
    country: 'US',
    city: 'Jessieville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.7094,
    longitude: -93.0382,
    postal_code: '71949'
  },
  '71950': {
    country: 'US',
    city: 'Kirby',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pike',
    latitude: 34.2513,
    longitude: -93.741,
    postal_code: '71950'
  },
  '71952': {
    country: 'US',
    city: 'Langley',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pike',
    latitude: 34.314,
    longitude: -93.8509,
    postal_code: '71952'
  },
  '71953': {
    country: 'US',
    city: 'Mena',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Polk',
    latitude: 34.5814,
    longitude: -94.221,
    postal_code: '71953'
  },
  '71956': {
    country: 'US',
    city: 'Mountain Pine',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.5976,
    longitude: -93.1539,
    postal_code: '71956'
  },
  '71957': {
    country: 'US',
    city: 'Mount Ida',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Montgomery',
    latitude: 34.5612,
    longitude: -93.5749,
    postal_code: '71957'
  },
  '71958': {
    country: 'US',
    city: 'Murfreesboro',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pike',
    latitude: 34.1017,
    longitude: -93.7109,
    postal_code: '71958'
  },
  '71959': {
    country: 'US',
    city: 'Newhope',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pike',
    latitude: 34.2273,
    longitude: -93.8905,
    postal_code: '71959'
  },
  '71960': {
    country: 'US',
    city: 'Norman',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Montgomery',
    latitude: 34.4596,
    longitude: -93.6743,
    postal_code: '71960'
  },
  '71961': {
    country: 'US',
    city: 'Oden',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Montgomery',
    latitude: 34.6113,
    longitude: -93.8211,
    postal_code: '71961'
  },
  '71962': {
    country: 'US',
    city: 'Okolona',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clark',
    latitude: 34.0551,
    longitude: -93.2897,
    postal_code: '71962'
  },
  '71964': {
    country: 'US',
    city: 'Pearcy',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.4351,
    longitude: -93.242,
    postal_code: '71964'
  },
  '71965': {
    country: 'US',
    city: 'Pencil Bluff',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Montgomery',
    latitude: 34.6399,
    longitude: -93.7429,
    postal_code: '71965'
  },
  '71966': {
    country: 'US',
    city: 'Oden',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Montgomery',
    latitude: 34.619,
    longitude: -93.7769,
    postal_code: '71966'
  },
  '71968': {
    country: 'US',
    city: 'Royal',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.515,
    longitude: -93.2897,
    postal_code: '71968'
  },
  '71969': {
    country: 'US',
    city: 'Sims',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Montgomery',
    latitude: 34.6462,
    longitude: -93.6741,
    postal_code: '71969'
  },
  '71970': {
    country: 'US',
    city: 'Story',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Montgomery',
    latitude: 34.6687,
    longitude: -93.5376,
    postal_code: '71970'
  },
  '71971': {
    country: 'US',
    city: 'Umpire',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Howard',
    latitude: 34.2921,
    longitude: -94.0314,
    postal_code: '71971'
  },
  '71972': {
    country: 'US',
    city: 'Vandervoort',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Polk',
    latitude: 34.3941,
    longitude: -94.2535,
    postal_code: '71972'
  },
  '71973': {
    country: 'US',
    city: 'Wickes',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Polk',
    latitude: 34.3088,
    longitude: -94.3403,
    postal_code: '71973'
  },
  '71998': {
    country: 'US',
    city: 'Arkadelphia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clark',
    latitude: 34.1209,
    longitude: -93.0538,
    postal_code: '71998'
  },
  '71999': {
    country: 'US',
    city: 'Arkadelphia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clark',
    latitude: 34.1209,
    longitude: -93.0538,
    postal_code: '71999'
  },
  '72001': {
    country: 'US',
    city: 'Adona',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Perry',
    latitude: 35.047,
    longitude: -92.9033,
    postal_code: '72001'
  },
  '72002': {
    country: 'US',
    city: 'Alexander',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.6313,
    longitude: -92.4727,
    postal_code: '72002'
  },
  '72003': {
    country: 'US',
    city: 'Almyra',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Arkansas',
    latitude: 34.4146,
    longitude: -91.431,
    postal_code: '72003'
  },
  '72004': {
    country: 'US',
    city: 'Altheimer',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.3062,
    longitude: -91.8289,
    postal_code: '72004'
  },
  '72005': {
    country: 'US',
    city: 'Amagon',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jackson',
    latitude: 35.562,
    longitude: -91.1104,
    postal_code: '72005'
  },
  '72006': {
    country: 'US',
    city: 'Augusta',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Woodruff',
    latitude: 35.2788,
    longitude: -91.3527,
    postal_code: '72006'
  },
  '72007': {
    country: 'US',
    city: 'Austin',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lonoke',
    latitude: 34.9921,
    longitude: -91.9798,
    postal_code: '72007'
  },
  '72010': {
    country: 'US',
    city: 'Bald Knob',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.3113,
    longitude: -91.5502,
    postal_code: '72010'
  },
  '72011': {
    country: 'US',
    city: 'Bauxite',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Saline',
    latitude: 34.5091,
    longitude: -92.4828,
    postal_code: '72011'
  },
  '72012': {
    country: 'US',
    city: 'Beebe',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.0937,
    longitude: -91.9074,
    postal_code: '72012'
  },
  '72013': {
    country: 'US',
    city: 'Bee Branch',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Van Buren',
    latitude: 35.4683,
    longitude: -92.3779,
    postal_code: '72013'
  },
  '72014': {
    country: 'US',
    city: 'Beedeville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jackson',
    latitude: 35.4291,
    longitude: -91.1057,
    postal_code: '72014'
  },
  '72015': {
    country: 'US',
    city: 'Benton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Saline',
    latitude: 34.4933,
    longitude: -92.5896,
    postal_code: '72015'
  },
  '72016': {
    country: 'US',
    city: 'Bigelow',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Perry',
    latitude: 34.9847,
    longitude: -92.6308,
    postal_code: '72016'
  },
  '72017': {
    country: 'US',
    city: 'Biscoe',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Prairie',
    latitude: 34.8733,
    longitude: -91.3945,
    postal_code: '72017'
  },
  '72018': {
    country: 'US',
    city: 'Benton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Saline',
    latitude: 34.5645,
    longitude: -92.5868,
    postal_code: '72018'
  },
  '72019': {
    country: 'US',
    city: 'Benton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hot Spring',
    latitude: 34.604,
    longitude: -92.6259,
    postal_code: '72019'
  },
  '72020': {
    country: 'US',
    city: 'Bradford',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.4277,
    longitude: -91.519,
    postal_code: '72020'
  },
  '72021': {
    country: 'US',
    city: 'Brinkley',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Monroe',
    latitude: 34.8781,
    longitude: -91.1886,
    postal_code: '72021'
  },
  '72022': {
    country: 'US',
    city: 'Bryant',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Saline',
    latitude: 34.6068,
    longitude: -92.492,
    postal_code: '72022'
  },
  '72023': {
    country: 'US',
    city: 'Cabot',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lonoke',
    latitude: 34.9457,
    longitude: -92.0318,
    postal_code: '72023'
  },
  '72024': {
    country: 'US',
    city: 'Carlisle',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lonoke',
    latitude: 34.7933,
    longitude: -91.7459,
    postal_code: '72024'
  },
  '72025': {
    country: 'US',
    city: 'Casa',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Perry',
    latitude: 35.0322,
    longitude: -93.047,
    postal_code: '72025'
  },
  '72026': {
    country: 'US',
    city: 'Casscoe',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Arkansas',
    latitude: 34.5095,
    longitude: -91.299,
    postal_code: '72026'
  },
  '72027': {
    country: 'US',
    city: 'Center Ridge',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Conway',
    latitude: 35.3981,
    longitude: -92.5582,
    postal_code: '72027'
  },
  '72028': {
    country: 'US',
    city: 'Choctaw',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Van Buren',
    latitude: 35.5773,
    longitude: -92.5456,
    postal_code: '72028'
  },
  '72029': {
    country: 'US',
    city: 'Clarendon',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Monroe',
    latitude: 34.6601,
    longitude: -91.2564,
    postal_code: '72029'
  },
  '72030': {
    country: 'US',
    city: 'Cleveland',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Conway',
    latitude: 35.3955,
    longitude: -92.702,
    postal_code: '72030'
  },
  '72031': {
    country: 'US',
    city: 'Clinton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Van Buren',
    latitude: 35.6045,
    longitude: -92.4758,
    postal_code: '72031'
  },
  '72032': {
    country: 'US',
    city: 'Conway',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.0842,
    longitude: -92.4236,
    postal_code: '72032'
  },
  '72033': {
    country: 'US',
    city: 'Conway',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.1053,
    longitude: -92.3549,
    postal_code: '72033'
  },
  '72034': {
    country: 'US',
    city: 'Conway',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.0823,
    longitude: -92.4683,
    postal_code: '72034'
  },
  '72035': {
    country: 'US',
    city: 'Conway',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.1053,
    longitude: -92.3549,
    postal_code: '72035'
  },
  '72036': {
    country: 'US',
    city: 'Cotton Plant',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Woodruff',
    latitude: 35.0178,
    longitude: -91.229,
    postal_code: '72036'
  },
  '72037': {
    country: 'US',
    city: 'Coy',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lonoke',
    latitude: 34.5387,
    longitude: -91.874,
    postal_code: '72037'
  },
  '72038': {
    country: 'US',
    city: 'Crocketts Bluff',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Arkansas',
    latitude: 34.4528,
    longitude: -91.2692,
    postal_code: '72038'
  },
  '72039': {
    country: 'US',
    city: 'Damascus',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.3673,
    longitude: -92.4096,
    postal_code: '72039'
  },
  '72040': {
    country: 'US',
    city: 'Des Arc',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Prairie',
    latitude: 34.9751,
    longitude: -91.5113,
    postal_code: '72040'
  },
  '72041': {
    country: 'US',
    city: 'De Valls Bluff',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Prairie',
    latitude: 34.7447,
    longitude: -91.4982,
    postal_code: '72041'
  },
  '72042': {
    country: 'US',
    city: 'De Witt',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Arkansas',
    latitude: 34.2853,
    longitude: -91.3336,
    postal_code: '72042'
  },
  '72043': {
    country: 'US',
    city: 'Diaz',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jackson',
    latitude: 35.6336,
    longitude: -91.2592,
    postal_code: '72043'
  },
  '72044': {
    country: 'US',
    city: 'Edgemont',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.6321,
    longitude: -92.157,
    postal_code: '72044'
  },
  '72045': {
    country: 'US',
    city: 'El Paso',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.1142,
    longitude: -92.0901,
    postal_code: '72045'
  },
  '72046': {
    country: 'US',
    city: 'England',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lonoke',
    latitude: 34.5575,
    longitude: -91.9484,
    postal_code: '72046'
  },
  '72047': {
    country: 'US',
    city: 'Enola',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.2087,
    longitude: -92.2123,
    postal_code: '72047'
  },
  '72048': {
    country: 'US',
    city: 'Ethel',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Arkansas',
    latitude: 34.2435,
    longitude: -91.1398,
    postal_code: '72048'
  },
  '72051': {
    country: 'US',
    city: 'Fox',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Stone',
    latitude: 35.7682,
    longitude: -92.3043,
    postal_code: '72051'
  },
  '72052': {
    country: 'US',
    city: 'Garner',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.1443,
    longitude: -91.7772,
    postal_code: '72052'
  },
  '72053': {
    country: 'US',
    city: 'College Station',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7093,
    longitude: -92.2283,
    postal_code: '72053'
  },
  '72055': {
    country: 'US',
    city: 'Gillett',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Arkansas',
    latitude: 34.1218,
    longitude: -91.38,
    postal_code: '72055'
  },
  '72057': {
    country: 'US',
    city: 'Grapevine',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Grant',
    latitude: 34.1309,
    longitude: -92.3109,
    postal_code: '72057'
  },
  '72058': {
    country: 'US',
    city: 'Greenbrier',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.2295,
    longitude: -92.3578,
    postal_code: '72058'
  },
  '72059': {
    country: 'US',
    city: 'Gregory',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Woodruff',
    latitude: 35.1554,
    longitude: -91.3432,
    postal_code: '72059'
  },
  '72060': {
    country: 'US',
    city: 'Griffithville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.1144,
    longitude: -91.6242,
    postal_code: '72060'
  },
  '72061': {
    country: 'US',
    city: 'Guy',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.3239,
    longitude: -92.2878,
    postal_code: '72061'
  },
  '72063': {
    country: 'US',
    city: 'Hattieville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Conway',
    latitude: 35.2907,
    longitude: -92.7783,
    postal_code: '72063'
  },
  '72064': {
    country: 'US',
    city: 'Hazen',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Prairie',
    latitude: 34.7838,
    longitude: -91.5767,
    postal_code: '72064'
  },
  '72065': {
    country: 'US',
    city: 'Hensley',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.5208,
    longitude: -92.2758,
    postal_code: '72065'
  },
  '72066': {
    country: 'US',
    city: 'Hickory Plains',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Prairie',
    latitude: 34.9901,
    longitude: -91.7368,
    postal_code: '72066'
  },
  '72067': {
    country: 'US',
    city: 'Higden',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.5685,
    longitude: -92.1341,
    postal_code: '72067'
  },
  '72068': {
    country: 'US',
    city: 'Higginson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.1903,
    longitude: -91.7108,
    postal_code: '72068'
  },
  '72069': {
    country: 'US',
    city: 'Holly Grove',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Monroe',
    latitude: 34.5993,
    longitude: -91.1844,
    postal_code: '72069'
  },
  '72070': {
    country: 'US',
    city: 'Houston',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Perry',
    latitude: 35.0362,
    longitude: -92.6913,
    postal_code: '72070'
  },
  '72072': {
    country: 'US',
    city: 'Humnoke',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lonoke',
    latitude: 34.4857,
    longitude: -91.7624,
    postal_code: '72072'
  },
  '72073': {
    country: 'US',
    city: 'Humphrey',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Arkansas',
    latitude: 34.4034,
    longitude: -91.679,
    postal_code: '72073'
  },
  '72074': {
    country: 'US',
    city: 'Hunter',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Woodruff',
    latitude: 35.0529,
    longitude: -91.1262,
    postal_code: '72074'
  },
  '72075': {
    country: 'US',
    city: 'Jacksonport',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jackson',
    latitude: 35.6414,
    longitude: -91.3072,
    postal_code: '72075'
  },
  '72076': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.919,
    longitude: -92.1419,
    postal_code: '72076'
  },
  '72078': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8662,
    longitude: -92.1101,
    postal_code: '72078'
  },
  '72079': {
    country: 'US',
    city: 'Jefferson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.3934,
    longitude: -92.2029,
    postal_code: '72079'
  },
  '72080': {
    country: 'US',
    city: 'Jerusalem',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Conway',
    latitude: 35.5699,
    longitude: -92.8251,
    postal_code: '72080'
  },
  '72081': {
    country: 'US',
    city: 'Judsonia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.325,
    longitude: -91.6491,
    postal_code: '72081'
  },
  '72082': {
    country: 'US',
    city: 'Kensett',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.2388,
    longitude: -91.6731,
    postal_code: '72082'
  },
  '72083': {
    country: 'US',
    city: 'Keo',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lonoke',
    latitude: 34.6041,
    longitude: -92.0078,
    postal_code: '72083'
  },
  '72084': {
    country: 'US',
    city: 'Leola',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Grant',
    latitude: 34.1856,
    longitude: -92.5979,
    postal_code: '72084'
  },
  '72085': {
    country: 'US',
    city: 'Letona',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.3617,
    longitude: -91.8293,
    postal_code: '72085'
  },
  '72086': {
    country: 'US',
    city: 'Lonoke',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lonoke',
    latitude: 34.7832,
    longitude: -91.9214,
    postal_code: '72086'
  },
  '72087': {
    country: 'US',
    city: 'Lonsdale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Garland',
    latitude: 34.5562,
    longitude: -92.834,
    postal_code: '72087'
  },
  '72088': {
    country: 'US',
    city: 'Fairfield Bay',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Van Buren',
    latitude: 35.6027,
    longitude: -92.2742,
    postal_code: '72088'
  },
  '72089': {
    country: 'US',
    city: 'Bryant',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Saline',
    latitude: 34.5959,
    longitude: -92.489,
    postal_code: '72089'
  },
  '72099': {
    country: 'US',
    city: 'Little Rock Air Force Base',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.9074,
    longitude: -92.1397,
    postal_code: '72099'
  },
  '72101': {
    country: 'US',
    city: 'Mc Crory',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Woodruff',
    latitude: 35.2473,
    longitude: -91.1793,
    postal_code: '72101'
  },
  '72102': {
    country: 'US',
    city: 'Mc Rae',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.1132,
    longitude: -91.8216,
    postal_code: '72102'
  },
  '72103': {
    country: 'US',
    city: 'Mabelvale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.6478,
    longitude: -92.3849,
    postal_code: '72103'
  },
  '72104': {
    country: 'US',
    city: 'Malvern',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hot Spring',
    latitude: 34.3557,
    longitude: -92.8292,
    postal_code: '72104'
  },
  '72105': {
    country: 'US',
    city: 'Jones Mill',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Hot Spring',
    latitude: 34.4374,
    longitude: -92.8943,
    postal_code: '72105'
  },
  '72106': {
    country: 'US',
    city: 'Mayflower',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 34.9669,
    longitude: -92.4001,
    postal_code: '72106'
  },
  '72107': {
    country: 'US',
    city: 'Menifee',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Conway',
    latitude: 35.1356,
    longitude: -92.5498,
    postal_code: '72107'
  },
  '72108': {
    country: 'US',
    city: 'Monroe',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Monroe',
    latitude: 34.7348,
    longitude: -91.1046,
    postal_code: '72108'
  },
  '72110': {
    country: 'US',
    city: 'Morrilton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Conway',
    latitude: 35.1692,
    longitude: -92.7354,
    postal_code: '72110'
  },
  '72111': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.2265,
    longitude: -92.124,
    postal_code: '72111'
  },
  '72112': {
    country: 'US',
    city: 'Newport',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jackson',
    latitude: 35.5988,
    longitude: -91.2571,
    postal_code: '72112'
  },
  '72113': {
    country: 'US',
    city: 'Maumelle',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8491,
    longitude: -92.4059,
    postal_code: '72113'
  },
  '72114': {
    country: 'US',
    city: 'North Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7666,
    longitude: -92.2629,
    postal_code: '72114'
  },
  '72115': {
    country: 'US',
    city: 'North Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7695,
    longitude: -92.2671,
    postal_code: '72115'
  },
  '72116': {
    country: 'US',
    city: 'North Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8059,
    longitude: -92.2305,
    postal_code: '72116'
  },
  '72117': {
    country: 'US',
    city: 'North Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7658,
    longitude: -92.1524,
    postal_code: '72117'
  },
  '72118': {
    country: 'US',
    city: 'North Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8337,
    longitude: -92.3289,
    postal_code: '72118'
  },
  '72119': {
    country: 'US',
    city: 'North Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8017,
    longitude: -92.2598,
    postal_code: '72119'
  },
  '72120': {
    country: 'US',
    city: 'Sherwood',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8807,
    longitude: -92.2303,
    postal_code: '72120'
  },
  '72121': {
    country: 'US',
    city: 'Pangburn',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.4216,
    longitude: -91.796,
    postal_code: '72121'
  },
  '72122': {
    country: 'US',
    city: 'Paron',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Saline',
    latitude: 34.7853,
    longitude: -92.7482,
    postal_code: '72122'
  },
  '72123': {
    country: 'US',
    city: 'Patterson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Woodruff',
    latitude: 35.2588,
    longitude: -91.237,
    postal_code: '72123'
  },
  '72124': {
    country: 'US',
    city: 'North Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7519,
    longitude: -92.3925,
    postal_code: '72124'
  },
  '72125': {
    country: 'US',
    city: 'Perry',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Perry',
    latitude: 35.0443,
    longitude: -92.7958,
    postal_code: '72125'
  },
  '72126': {
    country: 'US',
    city: 'Perryville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Perry',
    latitude: 34.9701,
    longitude: -92.8472,
    postal_code: '72126'
  },
  '72127': {
    country: 'US',
    city: 'Plumerville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Conway',
    latitude: 35.1575,
    longitude: -92.6204,
    postal_code: '72127'
  },
  '72128': {
    country: 'US',
    city: 'Poyen',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Grant',
    latitude: 34.3023,
    longitude: -92.6095,
    postal_code: '72128'
  },
  '72129': {
    country: 'US',
    city: 'Prattsville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Grant',
    latitude: 34.3079,
    longitude: -92.5131,
    postal_code: '72129'
  },
  '72130': {
    country: 'US',
    city: 'Prim',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.6674,
    longitude: -92.0933,
    postal_code: '72130'
  },
  '72131': {
    country: 'US',
    city: 'Quitman',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.405,
    longitude: -92.1333,
    postal_code: '72131'
  },
  '72132': {
    country: 'US',
    city: 'Redfield',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.4526,
    longitude: -92.1758,
    postal_code: '72132'
  },
  '72133': {
    country: 'US',
    city: 'Reydell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.1715,
    longitude: -91.5656,
    postal_code: '72133'
  },
  '72134': {
    country: 'US',
    city: 'Roe',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Monroe',
    latitude: 34.6286,
    longitude: -91.3771,
    postal_code: '72134'
  },
  '72135': {
    country: 'US',
    city: 'Roland',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8829,
    longitude: -92.5192,
    postal_code: '72135'
  },
  '72136': {
    country: 'US',
    city: 'Romance',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.2155,
    longitude: -92.0699,
    postal_code: '72136'
  },
  '72137': {
    country: 'US',
    city: 'Rose Bud',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.3214,
    longitude: -92.062,
    postal_code: '72137'
  },
  '72139': {
    country: 'US',
    city: 'Russell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.3609,
    longitude: -91.5071,
    postal_code: '72139'
  },
  '72140': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Arkansas',
    latitude: 34.3878,
    longitude: -91.1463,
    postal_code: '72140'
  },
  '72141': {
    country: 'US',
    city: 'Scotland',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Van Buren',
    latitude: 35.508,
    longitude: -92.5867,
    postal_code: '72141'
  },
  '72142': {
    country: 'US',
    city: 'Scott',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.6942,
    longitude: -92.1157,
    postal_code: '72142'
  },
  '72143': {
    country: 'US',
    city: 'Searcy',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.2436,
    longitude: -91.7317,
    postal_code: '72143'
  },
  '72145': {
    country: 'US',
    city: 'Searcy',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.2774,
    longitude: -91.7326,
    postal_code: '72145'
  },
  '72149': {
    country: 'US',
    city: 'Searcy',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.2474,
    longitude: -91.7314,
    postal_code: '72149'
  },
  '72150': {
    country: 'US',
    city: 'Sheridan',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Grant',
    latitude: 34.3165,
    longitude: -92.3657,
    postal_code: '72150'
  },
  '72152': {
    country: 'US',
    city: 'Sherrill',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.3581,
    longitude: -91.9933,
    postal_code: '72152'
  },
  '72153': {
    country: 'US',
    city: 'Shirley',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Van Buren',
    latitude: 35.6498,
    longitude: -92.3143,
    postal_code: '72153'
  },
  '72156': {
    country: 'US',
    city: 'Solgohachia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Conway',
    latitude: 35.2701,
    longitude: -92.6754,
    postal_code: '72156'
  },
  '72157': {
    country: 'US',
    city: 'Springfield',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Conway',
    latitude: 35.2749,
    longitude: -92.5457,
    postal_code: '72157'
  },
  '72158': {
    country: 'US',
    city: 'Benton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Saline',
    latitude: 34.635,
    longitude: -92.6597,
    postal_code: '72158'
  },
  '72160': {
    country: 'US',
    city: 'Stuttgart',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Arkansas',
    latitude: 34.4854,
    longitude: -91.5487,
    postal_code: '72160'
  },
  '72164': {
    country: 'US',
    city: 'Sweet Home',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.6873,
    longitude: -92.2399,
    postal_code: '72164'
  },
  '72165': {
    country: 'US',
    city: 'Thida',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.5762,
    longitude: -91.4539,
    postal_code: '72165'
  },
  '72166': {
    country: 'US',
    city: 'Tichnor',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Arkansas',
    latitude: 34.0893,
    longitude: -91.2437,
    postal_code: '72166'
  },
  '72167': {
    country: 'US',
    city: 'Traskwood',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Saline',
    latitude: 34.4508,
    longitude: -92.6547,
    postal_code: '72167'
  },
  '72168': {
    country: 'US',
    city: 'Tucker',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.4414,
    longitude: -91.9163,
    postal_code: '72168'
  },
  '72169': {
    country: 'US',
    city: 'Tupelo',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jackson',
    latitude: 35.3844,
    longitude: -91.2232,
    postal_code: '72169'
  },
  '72170': {
    country: 'US',
    city: 'Ulm',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Prairie',
    latitude: 34.5772,
    longitude: -91.4633,
    postal_code: '72170'
  },
  '72173': {
    country: 'US',
    city: 'Vilonia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.0719,
    longitude: -92.1832,
    postal_code: '72173'
  },
  '72175': {
    country: 'US',
    city: 'Wabbaseka',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.3936,
    longitude: -91.7549,
    postal_code: '72175'
  },
  '72176': {
    country: 'US',
    city: 'Ward',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lonoke',
    latitude: 34.9532,
    longitude: -91.9004,
    postal_code: '72176'
  },
  '72178': {
    country: 'US',
    city: 'West Point',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'White',
    latitude: 35.2032,
    longitude: -91.6064,
    postal_code: '72178'
  },
  '72179': {
    country: 'US',
    city: 'Wilburn',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.5106,
    longitude: -91.8648,
    postal_code: '72179'
  },
  '72180': {
    country: 'US',
    city: 'Woodson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.5338,
    longitude: -92.2089,
    postal_code: '72180'
  },
  '72181': {
    country: 'US',
    city: 'Wooster',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Faulkner',
    latitude: 35.1645,
    longitude: -92.453,
    postal_code: '72181'
  },
  '72182': {
    country: 'US',
    city: 'Wright',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jefferson',
    latitude: 34.4291,
    longitude: -92.0599,
    postal_code: '72182'
  },
  '72183': {
    country: 'US',
    city: 'Wrightsville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.6012,
    longitude: -92.1938,
    postal_code: '72183'
  },
  '72190': {
    country: 'US',
    city: 'North Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7519,
    longitude: -92.3925,
    postal_code: '72190'
  },
  '72199': {
    country: 'US',
    city: 'North Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8272,
    longitude: -92.2847,
    postal_code: '72199'
  },
  '72201': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7483,
    longitude: -92.2819,
    postal_code: '72201'
  },
  '72202': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7363,
    longitude: -92.2741,
    postal_code: '72202'
  },
  '72203': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8835,
    longitude: -92.3908,
    postal_code: '72203'
  },
  '72204': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7269,
    longitude: -92.344,
    postal_code: '72204'
  },
  '72205': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.751,
    longitude: -92.3455,
    postal_code: '72205'
  },
  '72206': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.6836,
    longitude: -92.2776,
    postal_code: '72206'
  },
  '72207': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7721,
    longitude: -92.3565,
    postal_code: '72207'
  },
  '72209': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.6725,
    longitude: -92.3529,
    postal_code: '72209'
  },
  '72210': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7076,
    longitude: -92.466,
    postal_code: '72210'
  },
  '72211': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7413,
    longitude: -92.4222,
    postal_code: '72211'
  },
  '72212': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7871,
    longitude: -92.4222,
    postal_code: '72212'
  },
  '72214': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7621,
    longitude: -92.2282,
    postal_code: '72214'
  },
  '72215': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7519,
    longitude: -92.3925,
    postal_code: '72215'
  },
  '72216': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8178,
    longitude: -92.2357,
    postal_code: '72216'
  },
  '72217': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8302,
    longitude: -92.1702,
    postal_code: '72217'
  },
  '72219': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7519,
    longitude: -92.3925,
    postal_code: '72219'
  },
  '72221': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7519,
    longitude: -92.3925,
    postal_code: '72221'
  },
  '72222': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7519,
    longitude: -92.3925,
    postal_code: '72222'
  },
  '72223': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7902,
    longitude: -92.5044,
    postal_code: '72223'
  },
  '72225': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7519,
    longitude: -92.3925,
    postal_code: '72225'
  },
  '72227': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7757,
    longitude: -92.3724,
    postal_code: '72227'
  },
  '72231': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.8019,
    longitude: -92.1894,
    postal_code: '72231'
  },
  '72255': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7464,
    longitude: -92.2894,
    postal_code: '72255'
  },
  '72260': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Boone',
    latitude: 34.7399,
    longitude: -92.34,
    postal_code: '72260'
  },
  '72295': {
    country: 'US',
    city: 'Little Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pulaski',
    latitude: 34.7519,
    longitude: -92.3925,
    postal_code: '72295'
  },
  '72301': {
    country: 'US',
    city: 'West Memphis',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crittenden',
    latitude: 35.1484,
    longitude: -90.1779,
    postal_code: '72301'
  },
  '72303': {
    country: 'US',
    city: 'West Memphis',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crittenden',
    latitude: 35.1465,
    longitude: -90.1845,
    postal_code: '72303'
  },
  '72310': {
    country: 'US',
    city: 'Armorel',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.9201,
    longitude: -89.7981,
    postal_code: '72310'
  },
  '72311': {
    country: 'US',
    city: 'Aubrey',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lee',
    latitude: 34.7195,
    longitude: -90.8969,
    postal_code: '72311'
  },
  '72312': {
    country: 'US',
    city: 'Barton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.5467,
    longitude: -90.7679,
    postal_code: '72312'
  },
  '72313': {
    country: 'US',
    city: 'Bassett',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.5365,
    longitude: -90.1268,
    postal_code: '72313'
  },
  '72315': {
    country: 'US',
    city: 'Blytheville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.9273,
    longitude: -89.919,
    postal_code: '72315'
  },
  '72316': {
    country: 'US',
    city: 'Blytheville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.9273,
    longitude: -89.919,
    postal_code: '72316'
  },
  '72319': {
    country: 'US',
    city: 'Gosnell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.9598,
    longitude: -89.972,
    postal_code: '72319'
  },
  '72320': {
    country: 'US',
    city: 'Brickeys',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lee',
    latitude: 34.8548,
    longitude: -90.5377,
    postal_code: '72320'
  },
  '72321': {
    country: 'US',
    city: 'Burdette',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.8173,
    longitude: -89.9393,
    postal_code: '72321'
  },
  '72322': {
    country: 'US',
    city: 'Caldwell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 35.0562,
    longitude: -90.8288,
    postal_code: '72322'
  },
  '72324': {
    country: 'US',
    city: 'Cherry Valley',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cross',
    latitude: 35.3991,
    longitude: -90.7613,
    postal_code: '72324'
  },
  '72325': {
    country: 'US',
    city: 'Clarkedale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crittenden',
    latitude: 35.3093,
    longitude: -90.2362,
    postal_code: '72325'
  },
  '72326': {
    country: 'US',
    city: 'Colt',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 35.0881,
    longitude: -90.8895,
    postal_code: '72326'
  },
  '72327': {
    country: 'US',
    city: 'Crawfordsville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crittenden',
    latitude: 35.2119,
    longitude: -90.3351,
    postal_code: '72327'
  },
  '72328': {
    country: 'US',
    city: 'Crumrod',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.1335,
    longitude: -90.9751,
    postal_code: '72328'
  },
  '72329': {
    country: 'US',
    city: 'Driver',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.6253,
    longitude: -90.0138,
    postal_code: '72329'
  },
  '72330': {
    country: 'US',
    city: 'Dyess',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.5954,
    longitude: -90.2157,
    postal_code: '72330'
  },
  '72331': {
    country: 'US',
    city: 'Earle',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crittenden',
    latitude: 35.2799,
    longitude: -90.4503,
    postal_code: '72331'
  },
  '72332': {
    country: 'US',
    city: 'Edmondson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crittenden',
    latitude: 35.1042,
    longitude: -90.3133,
    postal_code: '72332'
  },
  '72333': {
    country: 'US',
    city: 'Elaine',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.3118,
    longitude: -90.8939,
    postal_code: '72333'
  },
  '72335': {
    country: 'US',
    city: 'Forrest City',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 35.0091,
    longitude: -90.7886,
    postal_code: '72335'
  },
  '72336': {
    country: 'US',
    city: 'Forrest City',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 35.0081,
    longitude: -90.7898,
    postal_code: '72336'
  },
  '72338': {
    country: 'US',
    city: 'Frenchmans Bayou',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.4591,
    longitude: -90.1884,
    postal_code: '72338'
  },
  '72339': {
    country: 'US',
    city: 'Gilmore',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crittenden',
    latitude: 35.4057,
    longitude: -90.2714,
    postal_code: '72339'
  },
  '72340': {
    country: 'US',
    city: 'Goodwin',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 34.9358,
    longitude: -91.0292,
    postal_code: '72340'
  },
  '72341': {
    country: 'US',
    city: 'Haynes',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lee',
    latitude: 34.8838,
    longitude: -90.7666,
    postal_code: '72341'
  },
  '72342': {
    country: 'US',
    city: 'Helena',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.5325,
    longitude: -90.6298,
    postal_code: '72342'
  },
  '72346': {
    country: 'US',
    city: 'Heth',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 35.0973,
    longitude: -90.4586,
    postal_code: '72346'
  },
  '72347': {
    country: 'US',
    city: 'Hickory Ridge',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cross',
    latitude: 35.3995,
    longitude: -90.9823,
    postal_code: '72347'
  },
  '72348': {
    country: 'US',
    city: 'Hughes',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 34.9454,
    longitude: -90.4741,
    postal_code: '72348'
  },
  '72350': {
    country: 'US',
    city: 'Joiner',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.5052,
    longitude: -90.1478,
    postal_code: '72350'
  },
  '72351': {
    country: 'US',
    city: 'Keiser',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.6737,
    longitude: -90.0963,
    postal_code: '72351'
  },
  '72352': {
    country: 'US',
    city: 'La Grange',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lee',
    latitude: 34.7697,
    longitude: -90.7552,
    postal_code: '72352'
  },
  '72353': {
    country: 'US',
    city: 'Lambrook',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.3286,
    longitude: -90.9652,
    postal_code: '72353'
  },
  '72354': {
    country: 'US',
    city: 'Lepanto',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Poinsett',
    latitude: 35.6069,
    longitude: -90.3359,
    postal_code: '72354'
  },
  '72355': {
    country: 'US',
    city: 'Lexa',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.5979,
    longitude: -90.7523,
    postal_code: '72355'
  },
  '72358': {
    country: 'US',
    city: 'Luxora',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.7608,
    longitude: -89.922,
    postal_code: '72358'
  },
  '72359': {
    country: 'US',
    city: 'Madison',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 35.0252,
    longitude: -90.7086,
    postal_code: '72359'
  },
  '72360': {
    country: 'US',
    city: 'Marianna',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lee',
    latitude: 34.7759,
    longitude: -90.7785,
    postal_code: '72360'
  },
  '72364': {
    country: 'US',
    city: 'Marion',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crittenden',
    latitude: 35.2077,
    longitude: -90.1989,
    postal_code: '72364'
  },
  '72365': {
    country: 'US',
    city: 'Marked Tree',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Poinsett',
    latitude: 35.5349,
    longitude: -90.4194,
    postal_code: '72365'
  },
  '72366': {
    country: 'US',
    city: 'Marvell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.5485,
    longitude: -90.9413,
    postal_code: '72366'
  },
  '72367': {
    country: 'US',
    city: 'Mellwood',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.2198,
    longitude: -90.9504,
    postal_code: '72367'
  },
  '72368': {
    country: 'US',
    city: 'Moro',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lee',
    latitude: 34.803,
    longitude: -91.006,
    postal_code: '72368'
  },
  '72369': {
    country: 'US',
    city: 'Oneida',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.4542,
    longitude: -90.7684,
    postal_code: '72369'
  },
  '72370': {
    country: 'US',
    city: 'Osceola',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.7019,
    longitude: -89.9798,
    postal_code: '72370'
  },
  '72372': {
    country: 'US',
    city: 'Palestine',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 34.9663,
    longitude: -90.9049,
    postal_code: '72372'
  },
  '72373': {
    country: 'US',
    city: 'Parkin',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cross',
    latitude: 35.2586,
    longitude: -90.5564,
    postal_code: '72373'
  },
  '72374': {
    country: 'US',
    city: 'Poplar Grove',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.5394,
    longitude: -90.8813,
    postal_code: '72374'
  },
  '72376': {
    country: 'US',
    city: 'Proctor',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crittenden',
    latitude: 35.0834,
    longitude: -90.255,
    postal_code: '72376'
  },
  '72377': {
    country: 'US',
    city: 'Rivervale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Poinsett',
    latitude: 35.674,
    longitude: -90.3401,
    postal_code: '72377'
  },
  '72379': {
    country: 'US',
    city: 'Snow Lake',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Desha',
    latitude: 34.0665,
    longitude: -91.0071,
    postal_code: '72379'
  },
  '72383': {
    country: 'US',
    city: 'Turner',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.4762,
    longitude: -91.0187,
    postal_code: '72383'
  },
  '72384': {
    country: 'US',
    city: 'Turrell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crittenden',
    latitude: 35.3439,
    longitude: -90.2978,
    postal_code: '72384'
  },
  '72386': {
    country: 'US',
    city: 'Tyronza',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Poinsett',
    latitude: 35.4865,
    longitude: -90.3519,
    postal_code: '72386'
  },
  '72387': {
    country: 'US',
    city: 'Vanndale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cross',
    latitude: 35.3245,
    longitude: -90.7712,
    postal_code: '72387'
  },
  '72389': {
    country: 'US',
    city: 'Wabash',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.3466,
    longitude: -90.8869,
    postal_code: '72389'
  },
  '72390': {
    country: 'US',
    city: 'West Helena',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Phillips',
    latitude: 34.5496,
    longitude: -90.6545,
    postal_code: '72390'
  },
  '72391': {
    country: 'US',
    city: 'West Ridge',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.6831,
    longitude: -90.2626,
    postal_code: '72391'
  },
  '72392': {
    country: 'US',
    city: 'Wheatley',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 34.9207,
    longitude: -91.1086,
    postal_code: '72392'
  },
  '72394': {
    country: 'US',
    city: 'Widener',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'St. Francis',
    latitude: 35.0592,
    longitude: -90.6293,
    postal_code: '72394'
  },
  '72395': {
    country: 'US',
    city: 'Wilson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.566,
    longitude: -90.0427,
    postal_code: '72395'
  },
  '72396': {
    country: 'US',
    city: 'Wynne',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cross',
    latitude: 35.233,
    longitude: -90.793,
    postal_code: '72396'
  },
  '72401': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.833,
    longitude: -90.6965,
    postal_code: '72401'
  },
  '72402': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.8088,
    longitude: -90.6529,
    postal_code: '72402'
  },
  '72403': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.8305,
    longitude: -90.7039,
    postal_code: '72403'
  },
  '72404': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.7792,
    longitude: -90.766,
    postal_code: '72404'
  },
  '72410': {
    country: 'US',
    city: 'Alicia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 35.9423,
    longitude: -91.081,
    postal_code: '72410'
  },
  '72411': {
    country: 'US',
    city: 'Bay',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.7456,
    longitude: -90.5507,
    postal_code: '72411'
  },
  '72412': {
    country: 'US',
    city: 'Beech Grove',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Greene',
    latitude: 36.1281,
    longitude: -90.6914,
    postal_code: '72412'
  },
  '72413': {
    country: 'US',
    city: 'Biggers',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Randolph',
    latitude: 36.2844,
    longitude: -90.8538,
    postal_code: '72413'
  },
  '72414': {
    country: 'US',
    city: 'Black Oak',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.8368,
    longitude: -90.4005,
    postal_code: '72414'
  },
  '72415': {
    country: 'US',
    city: 'Black Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 36.1149,
    longitude: -91.1176,
    postal_code: '72415'
  },
  '72416': {
    country: 'US',
    city: 'Bono',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.9086,
    longitude: -90.7848,
    postal_code: '72416'
  },
  '72417': {
    country: 'US',
    city: 'Brookland',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.9165,
    longitude: -90.5762,
    postal_code: '72417'
  },
  '72419': {
    country: 'US',
    city: 'Caraway',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.759,
    longitude: -90.3358,
    postal_code: '72419'
  },
  '72421': {
    country: 'US',
    city: 'Cash',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.8301,
    longitude: -90.941,
    postal_code: '72421'
  },
  '72422': {
    country: 'US',
    city: 'Corning',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.4155,
    longitude: -90.587,
    postal_code: '72422'
  },
  '72424': {
    country: 'US',
    city: 'Datto',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.3897,
    longitude: -90.7231,
    postal_code: '72424'
  },
  '72425': {
    country: 'US',
    city: 'Delaplaine',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Greene',
    latitude: 36.1995,
    longitude: -90.7345,
    postal_code: '72425'
  },
  '72426': {
    country: 'US',
    city: 'Dell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.8554,
    longitude: -90.0418,
    postal_code: '72426'
  },
  '72427': {
    country: 'US',
    city: 'Egypt',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.864,
    longitude: -90.9428,
    postal_code: '72427'
  },
  '72428': {
    country: 'US',
    city: 'Etowah',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.7337,
    longitude: -90.1838,
    postal_code: '72428'
  },
  '72429': {
    country: 'US',
    city: 'Fisher',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Poinsett',
    latitude: 35.514,
    longitude: -90.955,
    postal_code: '72429'
  },
  '72430': {
    country: 'US',
    city: 'Greenway',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.3348,
    longitude: -90.2253,
    postal_code: '72430'
  },
  '72431': {
    country: 'US',
    city: 'Grubbs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jackson',
    latitude: 35.6498,
    longitude: -91.074,
    postal_code: '72431'
  },
  '72432': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Poinsett',
    latitude: 35.5722,
    longitude: -90.7038,
    postal_code: '72432'
  },
  '72433': {
    country: 'US',
    city: 'Hoxie',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 36.0326,
    longitude: -90.9715,
    postal_code: '72433'
  },
  '72434': {
    country: 'US',
    city: 'Imboden',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 36.1976,
    longitude: -91.1854,
    postal_code: '72434'
  },
  '72435': {
    country: 'US',
    city: 'Knobel',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.3176,
    longitude: -90.599,
    postal_code: '72435'
  },
  '72436': {
    country: 'US',
    city: 'Lafe',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Greene',
    latitude: 36.2359,
    longitude: -90.4811,
    postal_code: '72436'
  },
  '72437': {
    country: 'US',
    city: 'Lake City',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.8171,
    longitude: -90.4423,
    postal_code: '72437'
  },
  '72438': {
    country: 'US',
    city: 'Leachville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.9332,
    longitude: -90.1955,
    postal_code: '72438'
  },
  '72440': {
    country: 'US',
    city: 'Lynn',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 35.9813,
    longitude: -91.2676,
    postal_code: '72440'
  },
  '72441': {
    country: 'US',
    city: 'Mc Dougal',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.437,
    longitude: -90.3837,
    postal_code: '72441'
  },
  '72442': {
    country: 'US',
    city: 'Manila',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Mississippi',
    latitude: 35.8439,
    longitude: -90.1806,
    postal_code: '72442'
  },
  '72443': {
    country: 'US',
    city: 'Marmaduke',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Greene',
    latitude: 36.1951,
    longitude: -90.3837,
    postal_code: '72443'
  },
  '72444': {
    country: 'US',
    city: 'Maynard',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Randolph',
    latitude: 36.4386,
    longitude: -90.8749,
    postal_code: '72444'
  },
  '72445': {
    country: 'US',
    city: 'Minturn',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 35.9728,
    longitude: -91.0235,
    postal_code: '72445'
  },
  '72447': {
    country: 'US',
    city: 'Monette',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.9002,
    longitude: -90.3437,
    postal_code: '72447'
  },
  '72449': {
    country: 'US',
    city: 'O Kean',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Randolph',
    latitude: 36.1797,
    longitude: -90.8241,
    postal_code: '72449'
  },
  '72450': {
    country: 'US',
    city: 'Paragould',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Greene',
    latitude: 36.06,
    longitude: -90.5251,
    postal_code: '72450'
  },
  '72451': {
    country: 'US',
    city: 'Paragould',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Greene',
    latitude: 36.0584,
    longitude: -90.4973,
    postal_code: '72451'
  },
  '72453': {
    country: 'US',
    city: 'Peach Orchard',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.283,
    longitude: -90.6702,
    postal_code: '72453'
  },
  '72454': {
    country: 'US',
    city: 'Piggott',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.387,
    longitude: -90.1926,
    postal_code: '72454'
  },
  '72455': {
    country: 'US',
    city: 'Pocahontas',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Randolph',
    latitude: 36.2829,
    longitude: -90.9968,
    postal_code: '72455'
  },
  '72456': {
    country: 'US',
    city: 'Pollard',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.4317,
    longitude: -90.2751,
    postal_code: '72456'
  },
  '72457': {
    country: 'US',
    city: 'Portia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 36.0865,
    longitude: -91.0673,
    postal_code: '72457'
  },
  '72458': {
    country: 'US',
    city: 'Powhatan',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 36.08,
    longitude: -91.1482,
    postal_code: '72458'
  },
  '72459': {
    country: 'US',
    city: 'Ravenden',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 36.2029,
    longitude: -91.2593,
    postal_code: '72459'
  },
  '72460': {
    country: 'US',
    city: 'Ravenden Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Randolph',
    latitude: 36.3104,
    longitude: -91.2094,
    postal_code: '72460'
  },
  '72461': {
    country: 'US',
    city: 'Rector',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.2672,
    longitude: -90.2702,
    postal_code: '72461'
  },
  '72462': {
    country: 'US',
    city: 'Reyno',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Randolph',
    latitude: 36.3744,
    longitude: -90.7676,
    postal_code: '72462'
  },
  '72464': {
    country: 'US',
    city: 'Saint Francis',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.4534,
    longitude: -90.1454,
    postal_code: '72464'
  },
  '72465': {
    country: 'US',
    city: 'Sedgwick',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 35.9643,
    longitude: -90.8954,
    postal_code: '72465'
  },
  '72466': {
    country: 'US',
    city: 'Smithville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 36.0908,
    longitude: -91.2745,
    postal_code: '72466'
  },
  '72467': {
    country: 'US',
    city: 'State University',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Craighead',
    latitude: 35.8422,
    longitude: -90.6735,
    postal_code: '72467'
  },
  '72469': {
    country: 'US',
    city: 'Strawberry',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 35.9673,
    longitude: -91.2935,
    postal_code: '72469'
  },
  '72470': {
    country: 'US',
    city: 'Success',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Clay',
    latitude: 36.4536,
    longitude: -90.7281,
    postal_code: '72470'
  },
  '72471': {
    country: 'US',
    city: 'Swifton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jackson',
    latitude: 35.8274,
    longitude: -91.1264,
    postal_code: '72471'
  },
  '72472': {
    country: 'US',
    city: 'Trumann',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Poinsett',
    latitude: 35.5882,
    longitude: -90.562,
    postal_code: '72472'
  },
  '72473': {
    country: 'US',
    city: 'Tuckerman',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Jackson',
    latitude: 35.7307,
    longitude: -91.2003,
    postal_code: '72473'
  },
  '72474': {
    country: 'US',
    city: 'Walcott',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Greene',
    latitude: 36.0413,
    longitude: -90.6718,
    postal_code: '72474'
  },
  '72475': {
    country: 'US',
    city: 'Waldenburg',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Poinsett',
    latitude: 35.5631,
    longitude: -90.9207,
    postal_code: '72475'
  },
  '72476': {
    country: 'US',
    city: 'Walnut Ridge',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 36.0244,
    longitude: -90.9288,
    postal_code: '72476'
  },
  '72478': {
    country: 'US',
    city: 'Warm Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Randolph',
    latitude: 36.4803,
    longitude: -91.0515,
    postal_code: '72478'
  },
  '72479': {
    country: 'US',
    city: 'Weiner',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Poinsett',
    latitude: 35.6291,
    longitude: -90.9289,
    postal_code: '72479'
  },
  '72482': {
    country: 'US',
    city: 'Williford',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sharp',
    latitude: 36.2453,
    longitude: -91.3792,
    postal_code: '72482'
  },
  '72501': {
    country: 'US',
    city: 'Batesville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.7826,
    longitude: -91.6352,
    postal_code: '72501'
  },
  '72503': {
    country: 'US',
    city: 'Batesville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.7346,
    longitude: -91.5343,
    postal_code: '72503'
  },
  '72512': {
    country: 'US',
    city: 'Horseshoe Bend',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.2025,
    longitude: -91.7553,
    postal_code: '72512'
  },
  '72513': {
    country: 'US',
    city: 'Ash Flat',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sharp',
    latitude: 36.2201,
    longitude: -91.6421,
    postal_code: '72513'
  },
  '72515': {
    country: 'US',
    city: 'Bexar',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Fulton',
    latitude: 36.2901,
    longitude: -92.055,
    postal_code: '72515'
  },
  '72517': {
    country: 'US',
    city: 'Brockwell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.1358,
    longitude: -91.9513,
    postal_code: '72517'
  },
  '72519': {
    country: 'US',
    city: 'Calico Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.1202,
    longitude: -92.1867,
    postal_code: '72519'
  },
  '72520': {
    country: 'US',
    city: 'Camp',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Fulton',
    latitude: 36.4025,
    longitude: -91.7262,
    postal_code: '72520'
  },
  '72521': {
    country: 'US',
    city: 'Cave City',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sharp',
    latitude: 35.9517,
    longitude: -91.5444,
    postal_code: '72521'
  },
  '72522': {
    country: 'US',
    city: 'Charlotte',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.8184,
    longitude: -91.4387,
    postal_code: '72522'
  },
  '72523': {
    country: 'US',
    city: 'Concord',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.6414,
    longitude: -91.8333,
    postal_code: '72523'
  },
  '72524': {
    country: 'US',
    city: 'Cord',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.8184,
    longitude: -91.3375,
    postal_code: '72524'
  },
  '72525': {
    country: 'US',
    city: 'Cherokee Village',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sharp',
    latitude: 36.311,
    longitude: -91.597,
    postal_code: '72525'
  },
  '72526': {
    country: 'US',
    city: 'Cushman',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.8729,
    longitude: -91.754,
    postal_code: '72526'
  },
  '72527': {
    country: 'US',
    city: 'Desha',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.7411,
    longitude: -91.6985,
    postal_code: '72527'
  },
  '72528': {
    country: 'US',
    city: 'Dolph',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.2229,
    longitude: -92.1177,
    postal_code: '72528'
  },
  '72529': {
    country: 'US',
    city: 'Cherokee Village',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sharp',
    latitude: 36.3011,
    longitude: -91.5281,
    postal_code: '72529'
  },
  '72530': {
    country: 'US',
    city: 'Drasco',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.6616,
    longitude: -91.9398,
    postal_code: '72530'
  },
  '72531': {
    country: 'US',
    city: 'Elizabeth',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Fulton',
    latitude: 36.3238,
    longitude: -92.0934,
    postal_code: '72531'
  },
  '72532': {
    country: 'US',
    city: 'Evening Shade',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sharp',
    latitude: 36.0855,
    longitude: -91.598,
    postal_code: '72532'
  },
  '72533': {
    country: 'US',
    city: 'Fifty Six',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Stone',
    latitude: 35.992,
    longitude: -92.2182,
    postal_code: '72533'
  },
  '72534': {
    country: 'US',
    city: 'Floral',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.6023,
    longitude: -91.7341,
    postal_code: '72534'
  },
  '72536': {
    country: 'US',
    city: 'Franklin',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.1703,
    longitude: -91.776,
    postal_code: '72536'
  },
  '72537': {
    country: 'US',
    city: 'Gamaliel',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.4618,
    longitude: -92.2284,
    postal_code: '72537'
  },
  '72538': {
    country: 'US',
    city: 'Gepp',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Fulton',
    latitude: 36.4364,
    longitude: -92.0995,
    postal_code: '72538'
  },
  '72539': {
    country: 'US',
    city: 'Glencoe',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Fulton',
    latitude: 36.2942,
    longitude: -91.7474,
    postal_code: '72539'
  },
  '72540': {
    country: 'US',
    city: 'Guion',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 35.9266,
    longitude: -91.9103,
    postal_code: '72540'
  },
  '72542': {
    country: 'US',
    city: 'Hardy',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sharp',
    latitude: 36.3227,
    longitude: -91.411,
    postal_code: '72542'
  },
  '72543': {
    country: 'US',
    city: 'Heber Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.5103,
    longitude: -92.0392,
    postal_code: '72543'
  },
  '72544': {
    country: 'US',
    city: 'Henderson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.4105,
    longitude: -92.1951,
    postal_code: '72544'
  },
  '72545': {
    country: 'US',
    city: 'Heber Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.5352,
    longitude: -92.0213,
    postal_code: '72545'
  },
  '72546': {
    country: 'US',
    city: 'Ida',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.5816,
    longitude: -91.9305,
    postal_code: '72546'
  },
  '72550': {
    country: 'US',
    city: 'Locust Grove',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.7177,
    longitude: -91.7414,
    postal_code: '72550'
  },
  '72553': {
    country: 'US',
    city: 'Magness',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.6941,
    longitude: -91.4753,
    postal_code: '72553'
  },
  '72554': {
    country: 'US',
    city: 'Mammoth Spring',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Fulton',
    latitude: 36.4076,
    longitude: -91.5757,
    postal_code: '72554'
  },
  '72555': {
    country: 'US',
    city: 'Marcella',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Stone',
    latitude: 35.7756,
    longitude: -91.8718,
    postal_code: '72555'
  },
  '72556': {
    country: 'US',
    city: 'Melbourne',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.0635,
    longitude: -91.9079,
    postal_code: '72556'
  },
  '72560': {
    country: 'US',
    city: 'Mountain View',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Stone',
    latitude: 35.8208,
    longitude: -92.0389,
    postal_code: '72560'
  },
  '72561': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 35.9759,
    longitude: -91.785,
    postal_code: '72561'
  },
  '72562': {
    country: 'US',
    city: 'Newark',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.7118,
    longitude: -91.4394,
    postal_code: '72562'
  },
  '72564': {
    country: 'US',
    city: 'Oil Trough',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.6131,
    longitude: -91.4703,
    postal_code: '72564'
  },
  '72565': {
    country: 'US',
    city: 'Oxford',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.2114,
    longitude: -91.9258,
    postal_code: '72565'
  },
  '72566': {
    country: 'US',
    city: 'Pineville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.1677,
    longitude: -92.1073,
    postal_code: '72566'
  },
  '72567': {
    country: 'US',
    city: 'Pleasant Grove',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Stone',
    latitude: 35.8265,
    longitude: -91.8958,
    postal_code: '72567'
  },
  '72568': {
    country: 'US',
    city: 'Pleasant Plains',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.5893,
    longitude: -91.632,
    postal_code: '72568'
  },
  '72569': {
    country: 'US',
    city: 'Poughkeepsie',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sharp',
    latitude: 36.0767,
    longitude: -91.4793,
    postal_code: '72569'
  },
  '72571': {
    country: 'US',
    city: 'Rosie',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.6638,
    longitude: -91.534,
    postal_code: '72571'
  },
  '72572': {
    country: 'US',
    city: 'Saffell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Lawrence',
    latitude: 35.8781,
    longitude: -91.3004,
    postal_code: '72572'
  },
  '72573': {
    country: 'US',
    city: 'Sage',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.0688,
    longitude: -91.791,
    postal_code: '72573'
  },
  '72575': {
    country: 'US',
    city: 'Salado',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.6919,
    longitude: -91.5989,
    postal_code: '72575'
  },
  '72576': {
    country: 'US',
    city: 'Salem',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Fulton',
    latitude: 36.3766,
    longitude: -91.8759,
    postal_code: '72576'
  },
  '72577': {
    country: 'US',
    city: 'Sidney',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sharp',
    latitude: 36.0664,
    longitude: -91.642,
    postal_code: '72577'
  },
  '72578': {
    country: 'US',
    city: 'Sturkie',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Fulton',
    latitude: 36.4747,
    longitude: -91.8891,
    postal_code: '72578'
  },
  '72579': {
    country: 'US',
    city: 'Sulphur Rock',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Independence',
    latitude: 35.7545,
    longitude: -91.5073,
    postal_code: '72579'
  },
  '72581': {
    country: 'US',
    city: 'Tumbling Shoals',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Cleburne',
    latitude: 35.547,
    longitude: -91.9704,
    postal_code: '72581'
  },
  '72583': {
    country: 'US',
    city: 'Viola',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Fulton',
    latitude: 36.3924,
    longitude: -91.9932,
    postal_code: '72583'
  },
  '72584': {
    country: 'US',
    city: 'Violet Hill',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.163,
    longitude: -91.8471,
    postal_code: '72584'
  },
  '72585': {
    country: 'US',
    city: 'Wideman',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.1865,
    longitude: -92.0088,
    postal_code: '72585'
  },
  '72587': {
    country: 'US',
    city: 'Wiseman',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Izard',
    latitude: 36.2345,
    longitude: -91.8154,
    postal_code: '72587'
  },
  '72601': {
    country: 'US',
    city: 'Harrison',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Boone',
    latitude: 36.2417,
    longitude: -93.1062,
    postal_code: '72601'
  },
  '72602': {
    country: 'US',
    city: 'Harrison',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Boone',
    latitude: 36.2298,
    longitude: -93.1077,
    postal_code: '72602'
  },
  '72611': {
    country: 'US',
    city: 'Alpena',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Boone',
    latitude: 36.2998,
    longitude: -93.2792,
    postal_code: '72611'
  },
  '72613': {
    country: 'US',
    city: 'Beaver',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Carroll',
    latitude: 36.4311,
    longitude: -93.6994,
    postal_code: '72613'
  },
  '72615': {
    country: 'US',
    city: 'Bergman',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Boone',
    latitude: 36.3083,
    longitude: -93.0321,
    postal_code: '72615'
  },
  '72616': {
    country: 'US',
    city: 'Berryville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Carroll',
    latitude: 36.3519,
    longitude: -93.5587,
    postal_code: '72616'
  },
  '72617': {
    country: 'US',
    city: 'Big Flat',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.0068,
    longitude: -92.3917,
    postal_code: '72617'
  },
  '72619': {
    country: 'US',
    city: 'Bull Shoals',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Marion',
    latitude: 36.371,
    longitude: -92.5938,
    postal_code: '72619'
  },
  '72623': {
    country: 'US',
    city: 'Clarkridge',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.4724,
    longitude: -92.3296,
    postal_code: '72623'
  },
  '72624': {
    country: 'US',
    city: 'Compton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Newton',
    latitude: 36.0979,
    longitude: -93.3099,
    postal_code: '72624'
  },
  '72626': {
    country: 'US',
    city: 'Cotter',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.2712,
    longitude: -92.5354,
    postal_code: '72626'
  },
  '72628': {
    country: 'US',
    city: 'Deer',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Newton',
    latitude: 35.853,
    longitude: -93.3174,
    postal_code: '72628'
  },
  '72629': {
    country: 'US',
    city: 'Dennard',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Van Buren',
    latitude: 35.7252,
    longitude: -92.5575,
    postal_code: '72629'
  },
  '72630': {
    country: 'US',
    city: 'Diamond City',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Boone',
    latitude: 36.4645,
    longitude: -92.9202,
    postal_code: '72630'
  },
  '72631': {
    country: 'US',
    city: 'Eureka Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Carroll',
    latitude: 36.435,
    longitude: -93.7673,
    postal_code: '72631'
  },
  '72632': {
    country: 'US',
    city: 'Eureka Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Carroll',
    latitude: 36.4175,
    longitude: -93.7379,
    postal_code: '72632'
  },
  '72633': {
    country: 'US',
    city: 'Everton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Boone',
    latitude: 36.1534,
    longitude: -92.915,
    postal_code: '72633'
  },
  '72634': {
    country: 'US',
    city: 'Flippin',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Marion',
    latitude: 36.2682,
    longitude: -92.578,
    postal_code: '72634'
  },
  '72635': {
    country: 'US',
    city: 'Gassville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.3175,
    longitude: -92.4736,
    postal_code: '72635'
  },
  '72636': {
    country: 'US',
    city: 'Gilbert',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Searcy',
    latitude: 35.9879,
    longitude: -92.7163,
    postal_code: '72636'
  },
  '72638': {
    country: 'US',
    city: 'Green Forest',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Carroll',
    latitude: 36.3224,
    longitude: -93.4059,
    postal_code: '72638'
  },
  '72639': {
    country: 'US',
    city: 'Harriet',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Searcy',
    latitude: 35.9991,
    longitude: -92.5042,
    postal_code: '72639'
  },
  '72640': {
    country: 'US',
    city: 'Hasty',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Newton',
    latitude: 36.0152,
    longitude: -93.046,
    postal_code: '72640'
  },
  '72641': {
    country: 'US',
    city: 'Jasper',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Newton',
    latitude: 36.0038,
    longitude: -93.2048,
    postal_code: '72641'
  },
  '72642': {
    country: 'US',
    city: 'Lakeview',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.373,
    longitude: -92.5422,
    postal_code: '72642'
  },
  '72644': {
    country: 'US',
    city: 'Lead Hill',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Boone',
    latitude: 36.3848,
    longitude: -93.0039,
    postal_code: '72644'
  },
  '72645': {
    country: 'US',
    city: 'Leslie',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Searcy',
    latitude: 35.8272,
    longitude: -92.5663,
    postal_code: '72645'
  },
  '72648': {
    country: 'US',
    city: 'Marble Falls',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Newton',
    latitude: 36.0695,
    longitude: -93.1566,
    postal_code: '72648'
  },
  '72650': {
    country: 'US',
    city: 'Marshall',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Searcy',
    latitude: 35.9267,
    longitude: -92.6402,
    postal_code: '72650'
  },
  '72651': {
    country: 'US',
    city: 'Midway',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.3933,
    longitude: -92.4881,
    postal_code: '72651'
  },
  '72653': {
    country: 'US',
    city: 'Mountain Home',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.3312,
    longitude: -92.3753,
    postal_code: '72653'
  },
  '72654': {
    country: 'US',
    city: 'Mountain Home',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.3353,
    longitude: -92.3852,
    postal_code: '72654'
  },
  '72655': {
    country: 'US',
    city: 'Mount Judea',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Newton',
    latitude: 35.9407,
    longitude: -93.0654,
    postal_code: '72655'
  },
  '72657': {
    country: 'US',
    city: 'Timbo',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Stone',
    latitude: 35.919,
    longitude: -92.1272,
    postal_code: '72657'
  },
  '72658': {
    country: 'US',
    city: 'Norfork',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.2066,
    longitude: -92.273,
    postal_code: '72658'
  },
  '72659': {
    country: 'US',
    city: 'Norfork',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Baxter',
    latitude: 36.2095,
    longitude: -92.2843,
    postal_code: '72659'
  },
  '72660': {
    country: 'US',
    city: 'Oak Grove',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Carroll',
    latitude: 36.4613,
    longitude: -93.4321,
    postal_code: '72660'
  },
  '72661': {
    country: 'US',
    city: 'Oakland',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Marion',
    latitude: 36.4442,
    longitude: -92.5832,
    postal_code: '72661'
  },
  '72662': {
    country: 'US',
    city: 'Omaha',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Boone',
    latitude: 36.4612,
    longitude: -93.1888,
    postal_code: '72662'
  },
  '72663': {
    country: 'US',
    city: 'Onia',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Stone',
    latitude: 35.9403,
    longitude: -92.3459,
    postal_code: '72663'
  },
  '72666': {
    country: 'US',
    city: 'Parthenon',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Newton',
    latitude: 35.9451,
    longitude: -93.2677,
    postal_code: '72666'
  },
  '72668': {
    country: 'US',
    city: 'Peel',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Marion',
    latitude: 36.435,
    longitude: -92.7615,
    postal_code: '72668'
  },
  '72669': {
    country: 'US',
    city: 'Pindall',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Searcy',
    latitude: 36.0675,
    longitude: -92.8864,
    postal_code: '72669'
  },
  '72670': {
    country: 'US',
    city: 'Ponca',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Newton',
    latitude: 36.0242,
    longitude: -93.3646,
    postal_code: '72670'
  },
  '72672': {
    country: 'US',
    city: 'Pyatt',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Marion',
    latitude: 36.2486,
    longitude: -92.8416,
    postal_code: '72672'
  },
  '72675': {
    country: 'US',
    city: 'Saint Joe',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Searcy',
    latitude: 35.9877,
    longitude: -92.7928,
    postal_code: '72675'
  },
  '72677': {
    country: 'US',
    city: 'Summit',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Marion',
    latitude: 36.2523,
    longitude: -92.6907,
    postal_code: '72677'
  },
  '72679': {
    country: 'US',
    city: 'Tilly',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.7341,
    longitude: -92.8391,
    postal_code: '72679'
  },
  '72680': {
    country: 'US',
    city: 'Timbo',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Stone',
    latitude: 35.8845,
    longitude: -92.2913,
    postal_code: '72680'
  },
  '72682': {
    country: 'US',
    city: 'Valley Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Boone',
    latitude: 36.1351,
    longitude: -92.7541,
    postal_code: '72682'
  },
  '72683': {
    country: 'US',
    city: 'Vendor',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Newton',
    latitude: 35.9473,
    longitude: -93.0774,
    postal_code: '72683'
  },
  '72685': {
    country: 'US',
    city: 'Western Grove',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Newton',
    latitude: 36.083,
    longitude: -92.9716,
    postal_code: '72685'
  },
  '72686': {
    country: 'US',
    city: 'Witts Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Searcy',
    latitude: 35.7681,
    longitude: -92.8668,
    postal_code: '72686'
  },
  '72687': {
    country: 'US',
    city: 'Yellville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Marion',
    latitude: 36.2253,
    longitude: -92.7245,
    postal_code: '72687'
  },
  '72701': {
    country: 'US',
    city: 'Fayetteville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.052,
    longitude: -94.1534,
    postal_code: '72701'
  },
  '72702': {
    country: 'US',
    city: 'Fayetteville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.0626,
    longitude: -94.1574,
    postal_code: '72702'
  },
  '72703': {
    country: 'US',
    city: 'Fayetteville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.0992,
    longitude: -94.1716,
    postal_code: '72703'
  },
  '72704': {
    country: 'US',
    city: 'Fayetteville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.0877,
    longitude: -94.3093,
    postal_code: '72704'
  },
  '72711': {
    country: 'US',
    city: 'Avoca',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.4047,
    longitude: -94.0713,
    postal_code: '72711'
  },
  '72712': {
    country: 'US',
    city: 'Bentonville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.3577,
    longitude: -94.2224,
    postal_code: '72712'
  },
  '72713': {
    country: 'US',
    city: 'Bentonville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.3115,
    longitude: -94.3503,
    postal_code: '72713'
  },
  '72714': {
    country: 'US',
    city: 'Bella Vista',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.4676,
    longitude: -94.2166,
    postal_code: '72714'
  },
  '72715': {
    country: 'US',
    city: 'Bella Vista',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.4677,
    longitude: -94.3209,
    postal_code: '72715'
  },
  '72716': {
    country: 'US',
    city: 'Bentonville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.3729,
    longitude: -94.2088,
    postal_code: '72716'
  },
  '72717': {
    country: 'US',
    city: 'Canehill',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 35.8477,
    longitude: -94.4441,
    postal_code: '72717'
  },
  '72718': {
    country: 'US',
    city: 'Cave Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.2738,
    longitude: -94.2183,
    postal_code: '72718'
  },
  '72719': {
    country: 'US',
    city: 'Centerton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.367,
    longitude: -94.3089,
    postal_code: '72719'
  },
  '72721': {
    country: 'US',
    city: 'Combs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Madison',
    latitude: 35.8607,
    longitude: -93.8112,
    postal_code: '72721'
  },
  '72722': {
    country: 'US',
    city: 'Decatur',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.3347,
    longitude: -94.4534,
    postal_code: '72722'
  },
  '72727': {
    country: 'US',
    city: 'Elkins',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.0177,
    longitude: -94.0073,
    postal_code: '72727'
  },
  '72728': {
    country: 'US',
    city: 'Elm Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.21,
    longitude: -94.2546,
    postal_code: '72728'
  },
  '72729': {
    country: 'US',
    city: 'Evansville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 35.7823,
    longitude: -94.4638,
    postal_code: '72729'
  },
  '72730': {
    country: 'US',
    city: 'Farmington',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.0436,
    longitude: -94.2539,
    postal_code: '72730'
  },
  '72732': {
    country: 'US',
    city: 'Garfield',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.4288,
    longitude: -93.9512,
    postal_code: '72732'
  },
  '72733': {
    country: 'US',
    city: 'Gateway',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.4864,
    longitude: -93.9277,
    postal_code: '72733'
  },
  '72734': {
    country: 'US',
    city: 'Gentry',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.2652,
    longitude: -94.4751,
    postal_code: '72734'
  },
  '72735': {
    country: 'US',
    city: 'Goshen',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.0876,
    longitude: -93.9666,
    postal_code: '72735'
  },
  '72736': {
    country: 'US',
    city: 'Gravette',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.4155,
    longitude: -94.4779,
    postal_code: '72736'
  },
  '72737': {
    country: 'US',
    city: 'Greenland',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 35.9942,
    longitude: -94.1752,
    postal_code: '72737'
  },
  '72738': {
    country: 'US',
    city: 'Hindsville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Madison',
    latitude: 36.1422,
    longitude: -93.8633,
    postal_code: '72738'
  },
  '72739': {
    country: 'US',
    city: 'Hiwasse',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.4416,
    longitude: -94.339,
    postal_code: '72739'
  },
  '72740': {
    country: 'US',
    city: 'Huntsville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Madison',
    latitude: 36.1043,
    longitude: -93.7279,
    postal_code: '72740'
  },
  '72741': {
    country: 'US',
    city: 'Johnson',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.1329,
    longitude: -94.1655,
    postal_code: '72741'
  },
  '72742': {
    country: 'US',
    city: 'Kingston',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Madison',
    latitude: 36.0485,
    longitude: -93.5044,
    postal_code: '72742'
  },
  '72744': {
    country: 'US',
    city: 'Lincoln',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 35.9569,
    longitude: -94.4272,
    postal_code: '72744'
  },
  '72745': {
    country: 'US',
    city: 'Lowell',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.2554,
    longitude: -94.1308,
    postal_code: '72745'
  },
  '72747': {
    country: 'US',
    city: 'Maysville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.3811,
    longitude: -94.5783,
    postal_code: '72747'
  },
  '72749': {
    country: 'US',
    city: 'Morrow',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 35.8654,
    longitude: -94.4432,
    postal_code: '72749'
  },
  '72751': {
    country: 'US',
    city: 'Pea Ridge',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.4539,
    longitude: -94.118,
    postal_code: '72751'
  },
  '72752': {
    country: 'US',
    city: 'Pettigrew',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Madison',
    latitude: 35.8346,
    longitude: -93.6181,
    postal_code: '72752'
  },
  '72753': {
    country: 'US',
    city: 'Prairie Grove',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 35.9918,
    longitude: -94.3169,
    postal_code: '72753'
  },
  '72756': {
    country: 'US',
    city: 'Rogers',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.3363,
    longitude: -94.1148,
    postal_code: '72756'
  },
  '72757': {
    country: 'US',
    city: 'Rogers',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.332,
    longitude: -94.1185,
    postal_code: '72757'
  },
  '72758': {
    country: 'US',
    city: 'Rogers',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.3169,
    longitude: -94.1545,
    postal_code: '72758'
  },
  '72760': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Madison',
    latitude: 35.8496,
    longitude: -93.7347,
    postal_code: '72760'
  },
  '72761': {
    country: 'US',
    city: 'Siloam Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.18,
    longitude: -94.528,
    postal_code: '72761'
  },
  '72762': {
    country: 'US',
    city: 'Springdale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.1835,
    longitude: -94.1762,
    postal_code: '72762'
  },
  '72764': {
    country: 'US',
    city: 'Springdale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.1716,
    longitude: -94.0428,
    postal_code: '72764'
  },
  '72765': {
    country: 'US',
    city: 'Springdale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.1725,
    longitude: -94.1535,
    postal_code: '72765'
  },
  '72766': {
    country: 'US',
    city: 'Springdale',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.1867,
    longitude: -94.1288,
    postal_code: '72766'
  },
  '72768': {
    country: 'US',
    city: 'Sulphur Springs',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Benton',
    latitude: 36.4794,
    longitude: -94.4521,
    postal_code: '72768'
  },
  '72769': {
    country: 'US',
    city: 'Summers',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.0138,
    longitude: -94.5,
    postal_code: '72769'
  },
  '72770': {
    country: 'US',
    city: 'Tontitown',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 36.15,
    longitude: -94.2429,
    postal_code: '72770'
  },
  '72773': {
    country: 'US',
    city: 'Wesley',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Madison',
    latitude: 36.0114,
    longitude: -93.8526,
    postal_code: '72773'
  },
  '72774': {
    country: 'US',
    city: 'West Fork',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 35.9082,
    longitude: -94.2304,
    postal_code: '72774'
  },
  '72776': {
    country: 'US',
    city: 'Witter',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Madison',
    latitude: 35.9356,
    longitude: -93.621,
    postal_code: '72776'
  },
  '72801': {
    country: 'US',
    city: 'Russellville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.2842,
    longitude: -93.1315,
    postal_code: '72801'
  },
  '72802': {
    country: 'US',
    city: 'Russellville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.3067,
    longitude: -93.0712,
    postal_code: '72802'
  },
  '72811': {
    country: 'US',
    city: 'Russellville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.2784,
    longitude: -93.1338,
    postal_code: '72811'
  },
  '72812': {
    country: 'US',
    city: 'Russellville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.4231,
    longitude: -93.0544,
    postal_code: '72812'
  },
  '72820': {
    country: 'US',
    city: 'Alix',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Franklin',
    latitude: 35.41,
    longitude: -93.7286,
    postal_code: '72820'
  },
  '72821': {
    country: 'US',
    city: 'Altus',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Franklin',
    latitude: 35.4346,
    longitude: -93.7598,
    postal_code: '72821'
  },
  '72823': {
    country: 'US',
    city: 'Atkins',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.2449,
    longitude: -92.9507,
    postal_code: '72823'
  },
  '72824': {
    country: 'US',
    city: 'Belleville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 35.1038,
    longitude: -93.4518,
    postal_code: '72824'
  },
  '72826': {
    country: 'US',
    city: 'Blue Mountain',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Logan',
    latitude: 35.1304,
    longitude: -93.7116,
    postal_code: '72826'
  },
  '72827': {
    country: 'US',
    city: 'Bluffton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 34.9016,
    longitude: -93.5919,
    postal_code: '72827'
  },
  '72828': {
    country: 'US',
    city: 'Briggsville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 34.9161,
    longitude: -93.5157,
    postal_code: '72828'
  },
  '72829': {
    country: 'US',
    city: 'Centerville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 35.1018,
    longitude: -93.1773,
    postal_code: '72829'
  },
  '72830': {
    country: 'US',
    city: 'Clarksville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Johnson',
    latitude: 35.4908,
    longitude: -93.4911,
    postal_code: '72830'
  },
  '72832': {
    country: 'US',
    city: 'Coal Hill',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Johnson',
    latitude: 35.4371,
    longitude: -93.672,
    postal_code: '72832'
  },
  '72833': {
    country: 'US',
    city: 'Danville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 35.0495,
    longitude: -93.3929,
    postal_code: '72833'
  },
  '72834': {
    country: 'US',
    city: 'Dardanelle',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 35.1955,
    longitude: -93.1873,
    postal_code: '72834'
  },
  '72835': {
    country: 'US',
    city: 'Delaware',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Logan',
    latitude: 35.278,
    longitude: -93.3462,
    postal_code: '72835'
  },
  '72837': {
    country: 'US',
    city: 'Dover',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.4074,
    longitude: -93.1355,
    postal_code: '72837'
  },
  '72838': {
    country: 'US',
    city: 'Gravelly',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 34.8881,
    longitude: -93.6802,
    postal_code: '72838'
  },
  '72839': {
    country: 'US',
    city: 'Hagarville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Johnson',
    latitude: 35.6155,
    longitude: -93.2726,
    postal_code: '72839'
  },
  '72840': {
    country: 'US',
    city: 'Hartman',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Johnson',
    latitude: 35.4436,
    longitude: -93.6142,
    postal_code: '72840'
  },
  '72841': {
    country: 'US',
    city: 'Harvey',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Scott',
    latitude: 34.8762,
    longitude: -93.764,
    postal_code: '72841'
  },
  '72842': {
    country: 'US',
    city: 'Havana',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 35.0832,
    longitude: -93.5955,
    postal_code: '72842'
  },
  '72843': {
    country: 'US',
    city: 'Hector',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.5499,
    longitude: -92.9616,
    postal_code: '72843'
  },
  '72845': {
    country: 'US',
    city: 'Knoxville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Johnson',
    latitude: 35.3749,
    longitude: -93.3618,
    postal_code: '72845'
  },
  '72846': {
    country: 'US',
    city: 'Lamar',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Johnson',
    latitude: 35.4349,
    longitude: -93.3552,
    postal_code: '72846'
  },
  '72847': {
    country: 'US',
    city: 'London',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.3744,
    longitude: -93.2736,
    postal_code: '72847'
  },
  '72851': {
    country: 'US',
    city: 'New Blaine',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Logan',
    latitude: 35.3189,
    longitude: -93.4446,
    postal_code: '72851'
  },
  '72852': {
    country: 'US',
    city: 'Oark',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Johnson',
    latitude: 35.7095,
    longitude: -93.5585,
    postal_code: '72852'
  },
  '72853': {
    country: 'US',
    city: 'Ola',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 35.0309,
    longitude: -93.2136,
    postal_code: '72853'
  },
  '72854': {
    country: 'US',
    city: 'Ozone',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Johnson',
    latitude: 35.6575,
    longitude: -93.4311,
    postal_code: '72854'
  },
  '72855': {
    country: 'US',
    city: 'Paris',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Logan',
    latitude: 35.2941,
    longitude: -93.7265,
    postal_code: '72855'
  },
  '72856': {
    country: 'US',
    city: 'Pelsor',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.7854,
    longitude: -93.0812,
    postal_code: '72856'
  },
  '72857': {
    country: 'US',
    city: 'Plainview',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 34.9668,
    longitude: -93.3099,
    postal_code: '72857'
  },
  '72858': {
    country: 'US',
    city: 'Pottsville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Pope',
    latitude: 35.2398,
    longitude: -93.0564,
    postal_code: '72858'
  },
  '72860': {
    country: 'US',
    city: 'Rover',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Yell',
    latitude: 34.9475,
    longitude: -93.4017,
    postal_code: '72860'
  },
  '72863': {
    country: 'US',
    city: 'Scranton',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Logan',
    latitude: 35.3585,
    longitude: -93.5454,
    postal_code: '72863'
  },
  '72865': {
    country: 'US',
    city: 'Subiaco',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Logan',
    latitude: 35.3153,
    longitude: -93.5991,
    postal_code: '72865'
  },
  '72901': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3653,
    longitude: -94.411,
    postal_code: '72901'
  },
  '72902': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3862,
    longitude: -94.4091,
    postal_code: '72902'
  },
  '72903': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3427,
    longitude: -94.3784,
    postal_code: '72903'
  },
  '72904': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.4051,
    longitude: -94.3872,
    postal_code: '72904'
  },
  '72905': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3859,
    longitude: -94.3985,
    postal_code: '72905'
  },
  '72906': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3859,
    longitude: -94.3985,
    postal_code: '72906'
  },
  '72908': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3219,
    longitude: -94.4028,
    postal_code: '72908'
  },
  '72913': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3859,
    longitude: -94.3985,
    postal_code: '72913'
  },
  '72914': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3859,
    longitude: -94.3985,
    postal_code: '72914'
  },
  '72916': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.2502,
    longitude: -94.3703,
    postal_code: '72916'
  },
  '72917': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3859,
    longitude: -94.3985,
    postal_code: '72917'
  },
  '72918': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3859,
    longitude: -94.3985,
    postal_code: '72918'
  },
  '72919': {
    country: 'US',
    city: 'Fort Smith',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3859,
    longitude: -94.3985,
    postal_code: '72919'
  },
  '72921': {
    country: 'US',
    city: 'Alma',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.5,
    longitude: -94.2073,
    postal_code: '72921'
  },
  '72923': {
    country: 'US',
    city: 'Barling',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.333,
    longitude: -94.3082,
    postal_code: '72923'
  },
  '72926': {
    country: 'US',
    city: 'Boles',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Scott',
    latitude: 34.7654,
    longitude: -94.0629,
    postal_code: '72926'
  },
  '72927': {
    country: 'US',
    city: 'Booneville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Logan',
    latitude: 35.1364,
    longitude: -93.9274,
    postal_code: '72927'
  },
  '72928': {
    country: 'US',
    city: 'Branch',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Franklin',
    latitude: 35.2971,
    longitude: -93.9454,
    postal_code: '72928'
  },
  '72930': {
    country: 'US',
    city: 'Cecil',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Franklin',
    latitude: 35.4252,
    longitude: -94.0409,
    postal_code: '72930'
  },
  '72932': {
    country: 'US',
    city: 'Cedarville',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.603,
    longitude: -94.3749,
    postal_code: '72932'
  },
  '72933': {
    country: 'US',
    city: 'Charleston',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Franklin',
    latitude: 35.3115,
    longitude: -94.0337,
    postal_code: '72933'
  },
  '72934': {
    country: 'US',
    city: 'Chester',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.6898,
    longitude: -94.202,
    postal_code: '72934'
  },
  '72935': {
    country: 'US',
    city: 'Dyer',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.4923,
    longitude: -94.1377,
    postal_code: '72935'
  },
  '72936': {
    country: 'US',
    city: 'Greenwood',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.1955,
    longitude: -94.253,
    postal_code: '72936'
  },
  '72937': {
    country: 'US',
    city: 'Hackett',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.1945,
    longitude: -94.3983,
    postal_code: '72937'
  },
  '72938': {
    country: 'US',
    city: 'Hartford',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.0222,
    longitude: -94.3819,
    postal_code: '72938'
  },
  '72940': {
    country: 'US',
    city: 'Huntington',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.0963,
    longitude: -94.3313,
    postal_code: '72940'
  },
  '72941': {
    country: 'US',
    city: 'Lavaca',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.3661,
    longitude: -94.1917,
    postal_code: '72941'
  },
  '72943': {
    country: 'US',
    city: 'Magazine',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Logan',
    latitude: 35.1587,
    longitude: -93.8003,
    postal_code: '72943'
  },
  '72944': {
    country: 'US',
    city: 'Mansfield',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Scott',
    latitude: 35.0432,
    longitude: -94.2204,
    postal_code: '72944'
  },
  '72945': {
    country: 'US',
    city: 'Midland',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Sebastian',
    latitude: 35.0932,
    longitude: -94.3533,
    postal_code: '72945'
  },
  '72946': {
    country: 'US',
    city: 'Mountainburg',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.6464,
    longitude: -94.1497,
    postal_code: '72946'
  },
  '72947': {
    country: 'US',
    city: 'Mulberry',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.5172,
    longitude: -93.9885,
    postal_code: '72947'
  },
  '72948': {
    country: 'US',
    city: 'Natural Dam',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.6743,
    longitude: -94.4124,
    postal_code: '72948'
  },
  '72949': {
    country: 'US',
    city: 'Ozark',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Franklin',
    latitude: 35.5246,
    longitude: -93.8374,
    postal_code: '72949'
  },
  '72950': {
    country: 'US',
    city: 'Parks',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Scott',
    latitude: 34.8003,
    longitude: -93.9509,
    postal_code: '72950'
  },
  '72951': {
    country: 'US',
    city: 'Ratcliff',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Logan',
    latitude: 35.2917,
    longitude: -93.8899,
    postal_code: '72951'
  },
  '72952': {
    country: 'US',
    city: 'Rudy',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.5468,
    longitude: -94.2913,
    postal_code: '72952'
  },
  '72955': {
    country: 'US',
    city: 'Uniontown',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.5748,
    longitude: -94.4349,
    postal_code: '72955'
  },
  '72956': {
    country: 'US',
    city: 'Van Buren',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.454,
    longitude: -94.3278,
    postal_code: '72956'
  },
  '72957': {
    country: 'US',
    city: 'Van Buren',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Crawford',
    latitude: 35.4368,
    longitude: -94.3483,
    postal_code: '72957'
  },
  '72958': {
    country: 'US',
    city: 'Waldron',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Scott',
    latitude: 34.9026,
    longitude: -94.0772,
    postal_code: '72958'
  },
  '72959': {
    country: 'US',
    city: 'Winslow',
    state: 'Arkansas',
    state_short: 'AR',
    county: 'Washington',
    latitude: 35.8312,
    longitude: -94.1187,
    postal_code: '72959'
  },
  '73001': {
    country: 'US',
    city: 'Albert',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 35.2032,
    longitude: -98.3574,
    postal_code: '73001'
  },
  '73002': {
    country: 'US',
    city: 'Alex',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 34.9612,
    longitude: -97.7571,
    postal_code: '73002'
  },
  '73003': {
    country: 'US',
    city: 'Edmond',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.6748,
    longitude: -97.4997,
    postal_code: '73003'
  },
  '73004': {
    country: 'US',
    city: 'Amber',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 35.1526,
    longitude: -97.8532,
    postal_code: '73004'
  },
  '73005': {
    country: 'US',
    city: 'Anadarko',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 35.0728,
    longitude: -98.2429,
    postal_code: '73005'
  },
  '73006': {
    country: 'US',
    city: 'Apache',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 34.9034,
    longitude: -98.3695,
    postal_code: '73006'
  },
  '73007': {
    country: 'US',
    city: 'Arcadia',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.6543,
    longitude: -97.3243,
    postal_code: '73007'
  },
  '73008': {
    country: 'US',
    city: 'Bethany',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5043,
    longitude: -97.6399,
    postal_code: '73008'
  },
  '73009': {
    country: 'US',
    city: 'Binger',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 35.3106,
    longitude: -98.3148,
    postal_code: '73009'
  },
  '73010': {
    country: 'US',
    city: 'Blanchard',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McClain',
    latitude: 35.1192,
    longitude: -97.6401,
    postal_code: '73010'
  },
  '73011': {
    country: 'US',
    city: 'Bradley',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 34.8741,
    longitude: -97.7118,
    postal_code: '73011'
  },
  '73012': {
    country: 'US',
    city: 'Edmond',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.6215,
    longitude: -97.4733,
    postal_code: '73012'
  },
  '73013': {
    country: 'US',
    city: 'Edmond',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.6215,
    longitude: -97.4733,
    postal_code: '73013'
  },
  '73014': {
    country: 'US',
    city: 'Calumet',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Canadian',
    latitude: 35.5476,
    longitude: -98.1512,
    postal_code: '73014'
  },
  '73015': {
    country: 'US',
    city: 'Carnegie',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 35.1235,
    longitude: -98.5755,
    postal_code: '73015'
  },
  '73016': {
    country: 'US',
    city: 'Cashion',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kingfisher',
    latitude: 35.8,
    longitude: -97.6795,
    postal_code: '73016'
  },
  '73017': {
    country: 'US',
    city: 'Cement',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 34.9321,
    longitude: -98.1466,
    postal_code: '73017'
  },
  '73018': {
    country: 'US',
    city: 'Chickasha',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 35.0268,
    longitude: -97.9518,
    postal_code: '73018'
  },
  '73019': {
    country: 'US',
    city: 'Norman',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.2086,
    longitude: -97.4445,
    postal_code: '73019'
  },
  '73020': {
    country: 'US',
    city: 'Choctaw',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4718,
    longitude: -97.2726,
    postal_code: '73020'
  },
  '73021': {
    country: 'US',
    city: 'Colony',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washita',
    latitude: 35.3448,
    longitude: -98.6707,
    postal_code: '73021'
  },
  '73022': {
    country: 'US',
    city: 'Concho',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Canadian',
    latitude: 35.6193,
    longitude: -97.9796,
    postal_code: '73022'
  },
  '73023': {
    country: 'US',
    city: 'Chickasha',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 35.0526,
    longitude: -97.9364,
    postal_code: '73023'
  },
  '73024': {
    country: 'US',
    city: 'Corn',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washita',
    latitude: 35.3784,
    longitude: -98.7818,
    postal_code: '73024'
  },
  '73025': {
    country: 'US',
    city: 'Edmond',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.722,
    longitude: -97.5133,
    postal_code: '73025'
  },
  '73026': {
    country: 'US',
    city: 'Norman',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.2343,
    longitude: -97.2914,
    postal_code: '73026'
  },
  '73027': {
    country: 'US',
    city: 'Coyle',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Logan',
    latitude: 35.8985,
    longitude: -97.2607,
    postal_code: '73027'
  },
  '73028': {
    country: 'US',
    city: 'Crescent',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Logan',
    latitude: 35.942,
    longitude: -97.5969,
    postal_code: '73028'
  },
  '73029': {
    country: 'US',
    city: 'Cyril',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 34.8959,
    longitude: -98.2083,
    postal_code: '73029'
  },
  '73030': {
    country: 'US',
    city: 'Davis',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Murray',
    latitude: 34.4953,
    longitude: -97.1084,
    postal_code: '73030'
  },
  '73031': {
    country: 'US',
    city: 'Dibble',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McClain',
    latitude: 35.009,
    longitude: -97.6318,
    postal_code: '73031'
  },
  '73032': {
    country: 'US',
    city: 'Dougherty',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Murray',
    latitude: 34.402,
    longitude: -97.051,
    postal_code: '73032'
  },
  '73033': {
    country: 'US',
    city: 'Eakly',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 35.3053,
    longitude: -98.5578,
    postal_code: '73033'
  },
  '73034': {
    country: 'US',
    city: 'Edmond',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.6665,
    longitude: -97.4798,
    postal_code: '73034'
  },
  '73036': {
    country: 'US',
    city: 'El Reno',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Canadian',
    latitude: 35.5335,
    longitude: -97.9591,
    postal_code: '73036'
  },
  '73038': {
    country: 'US',
    city: 'Fort Cobb',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 35.1161,
    longitude: -98.4303,
    postal_code: '73038'
  },
  '73039': {
    country: 'US',
    city: 'Davis',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 34.506,
    longitude: -97.1131,
    postal_code: '73039'
  },
  '73040': {
    country: 'US',
    city: 'Geary',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Blaine',
    latitude: 35.6217,
    longitude: -98.3905,
    postal_code: '73040'
  },
  '73041': {
    country: 'US',
    city: 'Gotebo',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kiowa',
    latitude: 35.0759,
    longitude: -98.876,
    postal_code: '73041'
  },
  '73042': {
    country: 'US',
    city: 'Gracemont',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 35.1875,
    longitude: -98.2835,
    postal_code: '73042'
  },
  '73043': {
    country: 'US',
    city: 'Greenfield',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Blaine',
    latitude: 35.7333,
    longitude: -98.3841,
    postal_code: '73043'
  },
  '73044': {
    country: 'US',
    city: 'Guthrie',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Logan',
    latitude: 35.833,
    longitude: -97.436,
    postal_code: '73044'
  },
  '73045': {
    country: 'US',
    city: 'Harrah',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4833,
    longitude: -97.1734,
    postal_code: '73045'
  },
  '73047': {
    country: 'US',
    city: 'Hinton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 35.4675,
    longitude: -98.3313,
    postal_code: '73047'
  },
  '73048': {
    country: 'US',
    city: 'Hydro',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 35.452,
    longitude: -98.5604,
    postal_code: '73048'
  },
  '73049': {
    country: 'US',
    city: 'Jones',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5753,
    longitude: -97.2891,
    postal_code: '73049'
  },
  '73050': {
    country: 'US',
    city: 'Langston',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Logan',
    latitude: 35.9419,
    longitude: -97.2557,
    postal_code: '73050'
  },
  '73051': {
    country: 'US',
    city: 'Lexington',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.0377,
    longitude: -97.2609,
    postal_code: '73051'
  },
  '73052': {
    country: 'US',
    city: 'Lindsay',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garvin',
    latitude: 34.8211,
    longitude: -97.5998,
    postal_code: '73052'
  },
  '73053': {
    country: 'US',
    city: 'Lookeba',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Caddo',
    latitude: 35.3679,
    longitude: -98.3898,
    postal_code: '73053'
  },
  '73054': {
    country: 'US',
    city: 'Luther',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.6617,
    longitude: -97.1956,
    postal_code: '73054'
  },
  '73055': {
    country: 'US',
    city: 'Marlow',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Stephens',
    latitude: 34.6387,
    longitude: -97.941,
    postal_code: '73055'
  },
  '73056': {
    country: 'US',
    city: 'Marshall',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Logan',
    latitude: 36.1485,
    longitude: -97.6171,
    postal_code: '73056'
  },
  '73057': {
    country: 'US',
    city: 'Maysville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garvin',
    latitude: 34.8113,
    longitude: -97.4131,
    postal_code: '73057'
  },
  '73058': {
    country: 'US',
    city: 'Meridian',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Logan',
    latitude: 35.8451,
    longitude: -97.2462,
    postal_code: '73058'
  },
  '73059': {
    country: 'US',
    city: 'Minco',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 35.3067,
    longitude: -97.9664,
    postal_code: '73059'
  },
  '73061': {
    country: 'US',
    city: 'Morrison',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Noble',
    latitude: 36.2902,
    longitude: -97.0228,
    postal_code: '73061'
  },
  '73062': {
    country: 'US',
    city: 'Mountain View',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kiowa',
    latitude: 35.0654,
    longitude: -98.7307,
    postal_code: '73062'
  },
  '73063': {
    country: 'US',
    city: 'Mulhall',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Logan',
    latitude: 36.0537,
    longitude: -97.4098,
    postal_code: '73063'
  },
  '73064': {
    country: 'US',
    city: 'Mustang',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Canadian',
    latitude: 35.3885,
    longitude: -97.7309,
    postal_code: '73064'
  },
  '73065': {
    country: 'US',
    city: 'Newcastle',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McClain',
    latitude: 35.2453,
    longitude: -97.6216,
    postal_code: '73065'
  },
  '73066': {
    country: 'US',
    city: 'Nicoma Park',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4928,
    longitude: -97.3276,
    postal_code: '73066'
  },
  '73067': {
    country: 'US',
    city: 'Ninnekah',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 34.9143,
    longitude: -97.9333,
    postal_code: '73067'
  },
  '73068': {
    country: 'US',
    city: 'Noble',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.1417,
    longitude: -97.3409,
    postal_code: '73068'
  },
  '73069': {
    country: 'US',
    city: 'Norman',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.2204,
    longitude: -97.4577,
    postal_code: '73069'
  },
  '73070': {
    country: 'US',
    city: 'Norman',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.1876,
    longitude: -97.3975,
    postal_code: '73070'
  },
  '73071': {
    country: 'US',
    city: 'Norman',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.233,
    longitude: -97.4067,
    postal_code: '73071'
  },
  '73072': {
    country: 'US',
    city: 'Norman',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.199,
    longitude: -97.4841,
    postal_code: '73072'
  },
  '73073': {
    country: 'US',
    city: 'Orlando',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Logan',
    latitude: 36.142,
    longitude: -97.396,
    postal_code: '73073'
  },
  '73074': {
    country: 'US',
    city: 'Paoli',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garvin',
    latitude: 34.8285,
    longitude: -97.2608,
    postal_code: '73074'
  },
  '73075': {
    country: 'US',
    city: 'Pauls Valley',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garvin',
    latitude: 34.7385,
    longitude: -97.2195,
    postal_code: '73075'
  },
  '73077': {
    country: 'US',
    city: 'Perry',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Noble',
    latitude: 36.2875,
    longitude: -97.2842,
    postal_code: '73077'
  },
  '73078': {
    country: 'US',
    city: 'Piedmont',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Canadian',
    latitude: 35.6695,
    longitude: -97.7431,
    postal_code: '73078'
  },
  '73079': {
    country: 'US',
    city: 'Pocasset',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 35.1544,
    longitude: -97.979,
    postal_code: '73079'
  },
  '73080': {
    country: 'US',
    city: 'Purcell',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McClain',
    latitude: 35.0103,
    longitude: -97.4255,
    postal_code: '73080'
  },
  '73082': {
    country: 'US',
    city: 'Rush Springs',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 34.7708,
    longitude: -97.9431,
    postal_code: '73082'
  },
  '73083': {
    country: 'US',
    city: 'Edmond',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.6665,
    longitude: -97.4654,
    postal_code: '73083'
  },
  '73084': {
    country: 'US',
    city: 'Spencer',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5183,
    longitude: -97.3488,
    postal_code: '73084'
  },
  '73085': {
    country: 'US',
    city: 'Yukon',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Canadian',
    latitude: 35.4895,
    longitude: -97.75,
    postal_code: '73085'
  },
  '73086': {
    country: 'US',
    city: 'Sulphur',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Murray',
    latitude: 34.5116,
    longitude: -96.9797,
    postal_code: '73086'
  },
  '73089': {
    country: 'US',
    city: 'Tuttle',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 35.2674,
    longitude: -97.7446,
    postal_code: '73089'
  },
  '73090': {
    country: 'US',
    city: 'Union City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Canadian',
    latitude: 35.3913,
    longitude: -97.9398,
    postal_code: '73090'
  },
  '73092': {
    country: 'US',
    city: 'Verden',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grady',
    latitude: 35.0836,
    longitude: -98.0792,
    postal_code: '73092'
  },
  '73093': {
    country: 'US',
    city: 'Washington',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McClain',
    latitude: 35.1324,
    longitude: -97.487,
    postal_code: '73093'
  },
  '73095': {
    country: 'US',
    city: 'Wayne',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McClain',
    latitude: 34.9154,
    longitude: -97.329,
    postal_code: '73095'
  },
  '73096': {
    country: 'US',
    city: 'Weatherford',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Custer',
    latitude: 35.535,
    longitude: -98.6996,
    postal_code: '73096'
  },
  '73097': {
    country: 'US',
    city: 'Wheatland',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.397,
    longitude: -97.6519,
    postal_code: '73097'
  },
  '73098': {
    country: 'US',
    city: 'Wynnewood',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garvin',
    latitude: 34.6385,
    longitude: -97.177,
    postal_code: '73098'
  },
  '73099': {
    country: 'US',
    city: 'Yukon',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Canadian',
    latitude: 35.4977,
    longitude: -97.7323,
    postal_code: '73099'
  },
  '73101': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4916,
    longitude: -97.5628,
    postal_code: '73101'
  },
  '73102': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4726,
    longitude: -97.5199,
    postal_code: '73102'
  },
  '73103': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.491,
    longitude: -97.5196,
    postal_code: '73103'
  },
  '73104': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4794,
    longitude: -97.5017,
    postal_code: '73104'
  },
  '73105': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5108,
    longitude: -97.5003,
    postal_code: '73105'
  },
  '73106': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4853,
    longitude: -97.5372,
    postal_code: '73106'
  },
  '73107': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4874,
    longitude: -97.574,
    postal_code: '73107'
  },
  '73108': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4445,
    longitude: -97.5619,
    postal_code: '73108'
  },
  '73109': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4259,
    longitude: -97.5261,
    postal_code: '73109'
  },
  '73110': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4605,
    longitude: -97.3974,
    postal_code: '73110'
  },
  '73111': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5042,
    longitude: -97.4806,
    postal_code: '73111'
  },
  '73112': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5184,
    longitude: -97.5746,
    postal_code: '73112'
  },
  '73113': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73113'
  },
  '73114': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5704,
    longitude: -97.5257,
    postal_code: '73114'
  },
  '73115': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4449,
    longitude: -97.446,
    postal_code: '73115'
  },
  '73116': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5463,
    longitude: -97.5642,
    postal_code: '73116'
  },
  '73117': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4797,
    longitude: -97.4722,
    postal_code: '73117'
  },
  '73118': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5136,
    longitude: -97.5319,
    postal_code: '73118'
  },
  '73119': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.421,
    longitude: -97.5616,
    postal_code: '73119'
  },
  '73120': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5835,
    longitude: -97.5638,
    postal_code: '73120'
  },
  '73121': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5062,
    longitude: -97.4452,
    postal_code: '73121'
  },
  '73122': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5184,
    longitude: -97.6167,
    postal_code: '73122'
  },
  '73123': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4676,
    longitude: -97.5164,
    postal_code: '73123'
  },
  '73124': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73124'
  },
  '73125': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4654,
    longitude: -97.5218,
    postal_code: '73125'
  },
  '73126': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73126'
  },
  '73127': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4834,
    longitude: -97.6299,
    postal_code: '73127'
  },
  '73128': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4444,
    longitude: -97.6164,
    postal_code: '73128'
  },
  '73129': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4312,
    longitude: -97.4913,
    postal_code: '73129'
  },
  '73130': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4604,
    longitude: -97.3419,
    postal_code: '73130'
  },
  '73131': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5797,
    longitude: -97.4691,
    postal_code: '73131'
  },
  '73132': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5583,
    longitude: -97.6375,
    postal_code: '73132'
  },
  '73134': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.6174,
    longitude: -97.5583,
    postal_code: '73134'
  },
  '73135': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.411,
    longitude: -97.4388,
    postal_code: '73135'
  },
  '73136': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4676,
    longitude: -97.5164,
    postal_code: '73136'
  },
  '73137': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73137'
  },
  '73139': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.3792,
    longitude: -97.5362,
    postal_code: '73139'
  },
  '73140': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5185,
    longitude: -97.4275,
    postal_code: '73140'
  },
  '73141': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4918,
    longitude: -97.3666,
    postal_code: '73141'
  },
  '73142': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.599,
    longitude: -97.6251,
    postal_code: '73142'
  },
  '73143': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73143'
  },
  '73144': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73144'
  },
  '73145': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4304,
    longitude: -97.3962,
    postal_code: '73145'
  },
  '73146': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73146'
  },
  '73147': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73147'
  },
  '73148': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73148'
  },
  '73149': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.395,
    longitude: -97.4972,
    postal_code: '73149'
  },
  '73150': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4123,
    longitude: -97.3331,
    postal_code: '73150'
  },
  '73151': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5676,
    longitude: -97.4111,
    postal_code: '73151'
  },
  '73152': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73152'
  },
  '73153': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73153'
  },
  '73154': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5238,
    longitude: -97.5255,
    postal_code: '73154'
  },
  '73155': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73155'
  },
  '73156': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73156'
  },
  '73157': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73157'
  },
  '73159': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.3922,
    longitude: -97.5567,
    postal_code: '73159'
  },
  '73160': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.334,
    longitude: -97.4768,
    postal_code: '73160'
  },
  '73162': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5806,
    longitude: -97.6419,
    postal_code: '73162'
  },
  '73163': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73163'
  },
  '73164': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73164'
  },
  '73165': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.3267,
    longitude: -97.3534,
    postal_code: '73165'
  },
  '73167': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4676,
    longitude: -97.5164,
    postal_code: '73167'
  },
  '73169': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.3882,
    longitude: -97.6587,
    postal_code: '73169'
  },
  '73170': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.327,
    longitude: -97.5556,
    postal_code: '73170'
  },
  '73172': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5175,
    longitude: -97.6218,
    postal_code: '73172'
  },
  '73173': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cleveland',
    latitude: 35.3425,
    longitude: -97.6317,
    postal_code: '73173'
  },
  '73178': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73178'
  },
  '73179': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4242,
    longitude: -97.6547,
    postal_code: '73179'
  },
  '73184': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73184'
  },
  '73185': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4676,
    longitude: -97.5164,
    postal_code: '73185'
  },
  '73189': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73189'
  },
  '73190': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.4676,
    longitude: -97.5164,
    postal_code: '73190'
  },
  '73194': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73194'
  },
  '73195': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.474,
    longitude: -97.5227,
    postal_code: '73195'
  },
  '73196': {
    country: 'US',
    city: 'Oklahoma City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.5514,
    longitude: -97.4075,
    postal_code: '73196'
  },
  '73301': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '73301'
  },
  '73344': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '73344'
  },
  '73401': {
    country: 'US',
    city: 'Ardmore',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.1566,
    longitude: -97.1792,
    postal_code: '73401'
  },
  '73402': {
    country: 'US',
    city: 'Ardmore',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.2889,
    longitude: -97.2481,
    postal_code: '73402'
  },
  '73403': {
    country: 'US',
    city: 'Ardmore',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.1743,
    longitude: -97.1436,
    postal_code: '73403'
  },
  '73425': {
    country: 'US',
    city: 'Countyline',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Stephens',
    latitude: 34.4487,
    longitude: -97.5628,
    postal_code: '73425'
  },
  '73430': {
    country: 'US',
    city: 'Burneyville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Love',
    latitude: 33.9515,
    longitude: -97.3249,
    postal_code: '73430'
  },
  '73432': {
    country: 'US',
    city: 'Coleman',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Johnston',
    latitude: 34.2625,
    longitude: -96.4588,
    postal_code: '73432'
  },
  '73433': {
    country: 'US',
    city: 'Elmore City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garvin',
    latitude: 34.6265,
    longitude: -97.4142,
    postal_code: '73433'
  },
  '73434': {
    country: 'US',
    city: 'Foster',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Stephens',
    latitude: 34.6379,
    longitude: -97.5455,
    postal_code: '73434'
  },
  '73435': {
    country: 'US',
    city: 'Fox',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.3488,
    longitude: -97.4924,
    postal_code: '73435'
  },
  '73436': {
    country: 'US',
    city: 'Gene Autry',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.2939,
    longitude: -97.0339,
    postal_code: '73436'
  },
  '73437': {
    country: 'US',
    city: 'Graham',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.3762,
    longitude: -97.4255,
    postal_code: '73437'
  },
  '73438': {
    country: 'US',
    city: 'Healdton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.229,
    longitude: -97.4889,
    postal_code: '73438'
  },
  '73439': {
    country: 'US',
    city: 'Kingston',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Marshall',
    latitude: 33.9517,
    longitude: -96.712,
    postal_code: '73439'
  },
  '73440': {
    country: 'US',
    city: 'Lebanon',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Marshall',
    latitude: 33.9687,
    longitude: -96.9064,
    postal_code: '73440'
  },
  '73441': {
    country: 'US',
    city: 'Leon',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Love',
    latitude: 33.8793,
    longitude: -97.4292,
    postal_code: '73441'
  },
  '73442': {
    country: 'US',
    city: 'Loco',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Stephens',
    latitude: 34.3214,
    longitude: -97.6658,
    postal_code: '73442'
  },
  '73443': {
    country: 'US',
    city: 'Lone Grove',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.1774,
    longitude: -97.2685,
    postal_code: '73443'
  },
  '73444': {
    country: 'US',
    city: 'Hennepin',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garvin',
    latitude: 34.4719,
    longitude: -97.3879,
    postal_code: '73444'
  },
  '73446': {
    country: 'US',
    city: 'Madill',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Marshall',
    latitude: 34.0784,
    longitude: -96.7597,
    postal_code: '73446'
  },
  '73447': {
    country: 'US',
    city: 'Mannsville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Johnston',
    latitude: 34.1899,
    longitude: -96.8778,
    postal_code: '73447'
  },
  '73448': {
    country: 'US',
    city: 'Marietta',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Love',
    latitude: 33.9431,
    longitude: -97.1148,
    postal_code: '73448'
  },
  '73449': {
    country: 'US',
    city: 'Mead',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.9944,
    longitude: -96.5299,
    postal_code: '73449'
  },
  '73450': {
    country: 'US',
    city: 'Milburn',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Johnston',
    latitude: 34.1952,
    longitude: -96.5429,
    postal_code: '73450'
  },
  '73453': {
    country: 'US',
    city: 'Overbrook',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Love',
    latitude: 34.0539,
    longitude: -97.1324,
    postal_code: '73453'
  },
  '73455': {
    country: 'US',
    city: 'Ravia',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Johnston',
    latitude: 34.244,
    longitude: -96.7529,
    postal_code: '73455'
  },
  '73456': {
    country: 'US',
    city: 'Ringling',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jefferson',
    latitude: 34.1679,
    longitude: -97.6029,
    postal_code: '73456'
  },
  '73458': {
    country: 'US',
    city: 'Springer',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.3038,
    longitude: -97.1223,
    postal_code: '73458'
  },
  '73459': {
    country: 'US',
    city: 'Thackerville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Love',
    latitude: 33.7882,
    longitude: -97.1363,
    postal_code: '73459'
  },
  '73460': {
    country: 'US',
    city: 'Tishomingo',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Johnston',
    latitude: 34.2643,
    longitude: -96.6675,
    postal_code: '73460'
  },
  '73461': {
    country: 'US',
    city: 'Wapanucka',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Johnston',
    latitude: 34.3866,
    longitude: -96.4532,
    postal_code: '73461'
  },
  '73463': {
    country: 'US',
    city: 'Wilson',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.1364,
    longitude: -97.4273,
    postal_code: '73463'
  },
  '73481': {
    country: 'US',
    city: 'Ratliff City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.449,
    longitude: -97.5095,
    postal_code: '73481'
  },
  '73487': {
    country: 'US',
    city: 'Tatums',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.4777,
    longitude: -97.457,
    postal_code: '73487'
  },
  '73488': {
    country: 'US',
    city: 'Tussy',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Carter',
    latitude: 34.4852,
    longitude: -97.5423,
    postal_code: '73488'
  },
  '73491': {
    country: 'US',
    city: 'Velma',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Stephens',
    latitude: 34.4565,
    longitude: -97.6689,
    postal_code: '73491'
  },
  '73501': {
    country: 'US',
    city: 'Lawton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.5915,
    longitude: -98.3698,
    postal_code: '73501'
  },
  '73502': {
    country: 'US',
    city: 'Lawton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.6087,
    longitude: -98.3903,
    postal_code: '73502'
  },
  '73503': {
    country: 'US',
    city: 'Fort Sill',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.6595,
    longitude: -98.4004,
    postal_code: '73503'
  },
  '73505': {
    country: 'US',
    city: 'Lawton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.6179,
    longitude: -98.4552,
    postal_code: '73505'
  },
  '73506': {
    country: 'US',
    city: 'Lawton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.6309,
    longitude: -98.4576,
    postal_code: '73506'
  },
  '73507': {
    country: 'US',
    city: 'Lawton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.6246,
    longitude: -98.3895,
    postal_code: '73507'
  },
  '73520': {
    country: 'US',
    city: 'Addington',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jefferson',
    latitude: 34.2445,
    longitude: -97.967,
    postal_code: '73520'
  },
  '73521': {
    country: 'US',
    city: 'Altus',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jackson',
    latitude: 34.6484,
    longitude: -99.3205,
    postal_code: '73521'
  },
  '73522': {
    country: 'US',
    city: 'Altus',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jackson',
    latitude: 34.6381,
    longitude: -99.334,
    postal_code: '73522'
  },
  '73523': {
    country: 'US',
    city: 'Altus Afb',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jackson',
    latitude: 34.5982,
    longitude: -99.4408,
    postal_code: '73523'
  },
  '73526': {
    country: 'US',
    city: 'Blair',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jackson',
    latitude: 34.7788,
    longitude: -99.3334,
    postal_code: '73526'
  },
  '73527': {
    country: 'US',
    city: 'Cache',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.6131,
    longitude: -98.6154,
    postal_code: '73527'
  },
  '73528': {
    country: 'US',
    city: 'Chattanooga',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.4262,
    longitude: -98.6514,
    postal_code: '73528'
  },
  '73529': {
    country: 'US',
    city: 'Comanche',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Stephens',
    latitude: 34.3765,
    longitude: -97.9793,
    postal_code: '73529'
  },
  '73530': {
    country: 'US',
    city: 'Davidson',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tillman',
    latitude: 34.2514,
    longitude: -99.064,
    postal_code: '73530'
  },
  '73531': {
    country: 'US',
    city: 'Devol',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cotton',
    latitude: 34.1956,
    longitude: -98.577,
    postal_code: '73531'
  },
  '73532': {
    country: 'US',
    city: 'Duke',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jackson',
    latitude: 34.6668,
    longitude: -99.5482,
    postal_code: '73532'
  },
  '73533': {
    country: 'US',
    city: 'Duncan',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Stephens',
    latitude: 34.5073,
    longitude: -97.9403,
    postal_code: '73533'
  },
  '73534': {
    country: 'US',
    city: 'Duncan',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Stephens',
    latitude: 34.5189,
    longitude: -97.9733,
    postal_code: '73534'
  },
  '73536': {
    country: 'US',
    city: 'Duncan',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Stephens',
    latitude: 34.4853,
    longitude: -97.8522,
    postal_code: '73536'
  },
  '73537': {
    country: 'US',
    city: 'Eldorado',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jackson',
    latitude: 34.4727,
    longitude: -99.646,
    postal_code: '73537'
  },
  '73538': {
    country: 'US',
    city: 'Elgin',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.772,
    longitude: -98.4073,
    postal_code: '73538'
  },
  '73539': {
    country: 'US',
    city: 'Elmer',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jackson',
    latitude: 34.5137,
    longitude: -99.3167,
    postal_code: '73539'
  },
  '73540': {
    country: 'US',
    city: 'Faxon',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.4645,
    longitude: -98.5577,
    postal_code: '73540'
  },
  '73541': {
    country: 'US',
    city: 'Fletcher',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.7847,
    longitude: -98.2002,
    postal_code: '73541'
  },
  '73542': {
    country: 'US',
    city: 'Frederick',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tillman',
    latitude: 34.4012,
    longitude: -99.0119,
    postal_code: '73542'
  },
  '73543': {
    country: 'US',
    city: 'Geronimo',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.4805,
    longitude: -98.3875,
    postal_code: '73543'
  },
  '73544': {
    country: 'US',
    city: 'Gould',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Harmon',
    latitude: 34.665,
    longitude: -99.7843,
    postal_code: '73544'
  },
  '73546': {
    country: 'US',
    city: 'Grandfield',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tillman',
    latitude: 34.2282,
    longitude: -98.6867,
    postal_code: '73546'
  },
  '73547': {
    country: 'US',
    city: 'Granite',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Greer',
    latitude: 34.9712,
    longitude: -99.3881,
    postal_code: '73547'
  },
  '73548': {
    country: 'US',
    city: 'Hastings',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jefferson',
    latitude: 34.2251,
    longitude: -98.1075,
    postal_code: '73548'
  },
  '73549': {
    country: 'US',
    city: 'Headrick',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jackson',
    latitude: 34.6265,
    longitude: -99.139,
    postal_code: '73549'
  },
  '73550': {
    country: 'US',
    city: 'Hollis',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Harmon',
    latitude: 34.6953,
    longitude: -99.9177,
    postal_code: '73550'
  },
  '73551': {
    country: 'US',
    city: 'Hollister',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tillman',
    latitude: 34.3525,
    longitude: -98.8814,
    postal_code: '73551'
  },
  '73552': {
    country: 'US',
    city: 'Indiahoma',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.6242,
    longitude: -98.7349,
    postal_code: '73552'
  },
  '73553': {
    country: 'US',
    city: 'Loveland',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tillman',
    latitude: 34.391,
    longitude: -98.7235,
    postal_code: '73553'
  },
  '73554': {
    country: 'US',
    city: 'Mangum',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Greer',
    latitude: 34.9126,
    longitude: -99.4606,
    postal_code: '73554'
  },
  '73555': {
    country: 'US',
    city: 'Manitou',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tillman',
    latitude: 34.5075,
    longitude: -98.9792,
    postal_code: '73555'
  },
  '73556': {
    country: 'US',
    city: 'Martha',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jackson',
    latitude: 34.7253,
    longitude: -99.387,
    postal_code: '73556'
  },
  '73557': {
    country: 'US',
    city: 'Medicine Park',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.7292,
    longitude: -98.5026,
    postal_code: '73557'
  },
  '73558': {
    country: 'US',
    city: 'Meers',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.7828,
    longitude: -98.579,
    postal_code: '73558'
  },
  '73559': {
    country: 'US',
    city: 'Mountain Park',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kiowa',
    latitude: 34.7032,
    longitude: -98.9591,
    postal_code: '73559'
  },
  '73560': {
    country: 'US',
    city: 'Olustee',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jackson',
    latitude: 34.5496,
    longitude: -99.4287,
    postal_code: '73560'
  },
  '73561': {
    country: 'US',
    city: 'Oscar',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jefferson',
    latitude: 34.0313,
    longitude: -97.6481,
    postal_code: '73561'
  },
  '73562': {
    country: 'US',
    city: 'Randlett',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cotton',
    latitude: 34.174,
    longitude: -98.46,
    postal_code: '73562'
  },
  '73564': {
    country: 'US',
    city: 'Roosevelt',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kiowa',
    latitude: 34.847,
    longitude: -98.9836,
    postal_code: '73564'
  },
  '73565': {
    country: 'US',
    city: 'Ryan',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jefferson',
    latitude: 34.0238,
    longitude: -97.9461,
    postal_code: '73565'
  },
  '73566': {
    country: 'US',
    city: 'Snyder',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kiowa',
    latitude: 34.6548,
    longitude: -98.9508,
    postal_code: '73566'
  },
  '73567': {
    country: 'US',
    city: 'Sterling',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Comanche',
    latitude: 34.7496,
    longitude: -98.1679,
    postal_code: '73567'
  },
  '73568': {
    country: 'US',
    city: 'Temple',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cotton',
    latitude: 34.2607,
    longitude: -98.2371,
    postal_code: '73568'
  },
  '73569': {
    country: 'US',
    city: 'Terral',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jefferson',
    latitude: 33.9379,
    longitude: -97.8053,
    postal_code: '73569'
  },
  '73570': {
    country: 'US',
    city: 'Tipton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tillman',
    latitude: 34.5098,
    longitude: -99.1315,
    postal_code: '73570'
  },
  '73571': {
    country: 'US',
    city: 'Vinson',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Harmon',
    latitude: 34.9006,
    longitude: -99.8598,
    postal_code: '73571'
  },
  '73572': {
    country: 'US',
    city: 'Walters',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cotton',
    latitude: 34.3605,
    longitude: -98.314,
    postal_code: '73572'
  },
  '73573': {
    country: 'US',
    city: 'Waurika',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Jefferson',
    latitude: 34.1745,
    longitude: -97.9973,
    postal_code: '73573'
  },
  '73601': {
    country: 'US',
    city: 'Clinton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Custer',
    latitude: 35.5115,
    longitude: -98.9795,
    postal_code: '73601'
  },
  '73620': {
    country: 'US',
    city: 'Arapaho',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Custer',
    latitude: 35.5789,
    longitude: -98.9595,
    postal_code: '73620'
  },
  '73622': {
    country: 'US',
    city: 'Bessie',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washita',
    latitude: 35.3854,
    longitude: -98.9896,
    postal_code: '73622'
  },
  '73624': {
    country: 'US',
    city: 'Burns Flat',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washita',
    latitude: 35.3492,
    longitude: -99.188,
    postal_code: '73624'
  },
  '73625': {
    country: 'US',
    city: 'Butler',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Custer',
    latitude: 35.6336,
    longitude: -99.2427,
    postal_code: '73625'
  },
  '73626': {
    country: 'US',
    city: 'Canute',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washita',
    latitude: 35.4037,
    longitude: -99.2816,
    postal_code: '73626'
  },
  '73627': {
    country: 'US',
    city: 'Carter',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beckham',
    latitude: 35.2208,
    longitude: -99.4822,
    postal_code: '73627'
  },
  '73628': {
    country: 'US',
    city: 'Cheyenne',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Roger Mills',
    latitude: 35.6081,
    longitude: -99.6725,
    postal_code: '73628'
  },
  '73632': {
    country: 'US',
    city: 'Cordell',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washita',
    latitude: 35.2788,
    longitude: -98.9511,
    postal_code: '73632'
  },
  '73638': {
    country: 'US',
    city: 'Crawford',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Roger Mills',
    latitude: 35.8368,
    longitude: -99.8064,
    postal_code: '73638'
  },
  '73639': {
    country: 'US',
    city: 'Custer City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Custer',
    latitude: 35.6894,
    longitude: -98.912,
    postal_code: '73639'
  },
  '73641': {
    country: 'US',
    city: 'Dill City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washita',
    latitude: 35.2787,
    longitude: -99.1537,
    postal_code: '73641'
  },
  '73642': {
    country: 'US',
    city: 'Durham',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Roger Mills',
    latitude: 35.8364,
    longitude: -99.9088,
    postal_code: '73642'
  },
  '73644': {
    country: 'US',
    city: 'Elk City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beckham',
    latitude: 35.4104,
    longitude: -99.4211,
    postal_code: '73644'
  },
  '73645': {
    country: 'US',
    city: 'Erick',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beckham',
    latitude: 35.2286,
    longitude: -99.8635,
    postal_code: '73645'
  },
  '73646': {
    country: 'US',
    city: 'Fay',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Dewey',
    latitude: 35.8204,
    longitude: -98.6587,
    postal_code: '73646'
  },
  '73647': {
    country: 'US',
    city: 'Foss',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washita',
    latitude: 35.4545,
    longitude: -99.1698,
    postal_code: '73647'
  },
  '73648': {
    country: 'US',
    city: 'Elk City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beckham',
    latitude: 35.412,
    longitude: -99.4043,
    postal_code: '73648'
  },
  '73650': {
    country: 'US',
    city: 'Hammon',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Roger Mills',
    latitude: 35.6461,
    longitude: -99.4027,
    postal_code: '73650'
  },
  '73651': {
    country: 'US',
    city: 'Hobart',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kiowa',
    latitude: 35.0255,
    longitude: -99.0944,
    postal_code: '73651'
  },
  '73654': {
    country: 'US',
    city: 'Leedey',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Dewey',
    latitude: 35.8698,
    longitude: -99.3491,
    postal_code: '73654'
  },
  '73655': {
    country: 'US',
    city: 'Lone Wolf',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kiowa',
    latitude: 34.9806,
    longitude: -99.2502,
    postal_code: '73655'
  },
  '73658': {
    country: 'US',
    city: 'Oakwood',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Dewey',
    latitude: 35.9328,
    longitude: -98.7065,
    postal_code: '73658'
  },
  '73659': {
    country: 'US',
    city: 'Putnam',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Dewey',
    latitude: 35.8539,
    longitude: -98.9638,
    postal_code: '73659'
  },
  '73660': {
    country: 'US',
    city: 'Reydon',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Roger Mills',
    latitude: 35.6576,
    longitude: -99.9166,
    postal_code: '73660'
  },
  '73661': {
    country: 'US',
    city: 'Rocky',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washita',
    latitude: 35.1526,
    longitude: -99.048,
    postal_code: '73661'
  },
  '73662': {
    country: 'US',
    city: 'Sayre',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beckham',
    latitude: 35.3047,
    longitude: -99.6429,
    postal_code: '73662'
  },
  '73663': {
    country: 'US',
    city: 'Seiling',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Dewey',
    latitude: 36.1278,
    longitude: -98.8875,
    postal_code: '73663'
  },
  '73664': {
    country: 'US',
    city: 'Sentinel',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washita',
    latitude: 35.1617,
    longitude: -99.1706,
    postal_code: '73664'
  },
  '73666': {
    country: 'US',
    city: 'Sweetwater',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Roger Mills',
    latitude: 35.4488,
    longitude: -99.9006,
    postal_code: '73666'
  },
  '73667': {
    country: 'US',
    city: 'Taloga',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Dewey',
    latitude: 35.9972,
    longitude: -98.9825,
    postal_code: '73667'
  },
  '73668': {
    country: 'US',
    city: 'Texola',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beckham',
    latitude: 35.2161,
    longitude: -99.9915,
    postal_code: '73668'
  },
  '73669': {
    country: 'US',
    city: 'Thomas',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Custer',
    latitude: 35.7382,
    longitude: -98.7388,
    postal_code: '73669'
  },
  '73673': {
    country: 'US',
    city: 'Willow',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Greer',
    latitude: 35.0702,
    longitude: -99.5421,
    postal_code: '73673'
  },
  '73701': {
    country: 'US',
    city: 'Enid',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.4028,
    longitude: -97.8623,
    postal_code: '73701'
  },
  '73702': {
    country: 'US',
    city: 'Enid',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.3956,
    longitude: -97.8784,
    postal_code: '73702'
  },
  '73703': {
    country: 'US',
    city: 'Enid',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.3975,
    longitude: -97.9157,
    postal_code: '73703'
  },
  '73705': {
    country: 'US',
    city: 'Enid',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.3382,
    longitude: -97.9026,
    postal_code: '73705'
  },
  '73706': {
    country: 'US',
    city: 'Enid',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.3791,
    longitude: -97.7826,
    postal_code: '73706'
  },
  '73716': {
    country: 'US',
    city: 'Aline',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Alfalfa',
    latitude: 36.5056,
    longitude: -98.4574,
    postal_code: '73716'
  },
  '73717': {
    country: 'US',
    city: 'Alva',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woods',
    latitude: 36.8016,
    longitude: -98.6722,
    postal_code: '73717'
  },
  '73718': {
    country: 'US',
    city: 'Ames',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Major',
    latitude: 36.2423,
    longitude: -98.182,
    postal_code: '73718'
  },
  '73719': {
    country: 'US',
    city: 'Amorita',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Alfalfa',
    latitude: 36.9412,
    longitude: -98.2458,
    postal_code: '73719'
  },
  '73720': {
    country: 'US',
    city: 'Bison',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.1962,
    longitude: -97.8805,
    postal_code: '73720'
  },
  '73722': {
    country: 'US',
    city: 'Burlington',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Alfalfa',
    latitude: 36.9036,
    longitude: -98.4215,
    postal_code: '73722'
  },
  '73724': {
    country: 'US',
    city: 'Canton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Blaine',
    latitude: 36.0372,
    longitude: -98.5778,
    postal_code: '73724'
  },
  '73726': {
    country: 'US',
    city: 'Carmen',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Alfalfa',
    latitude: 36.5842,
    longitude: -98.4578,
    postal_code: '73726'
  },
  '73727': {
    country: 'US',
    city: 'Carrier',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.4922,
    longitude: -98.0422,
    postal_code: '73727'
  },
  '73728': {
    country: 'US',
    city: 'Cherokee',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Alfalfa',
    latitude: 36.7564,
    longitude: -98.3594,
    postal_code: '73728'
  },
  '73729': {
    country: 'US',
    city: 'Cleo Springs',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Major',
    latitude: 36.409,
    longitude: -98.4423,
    postal_code: '73729'
  },
  '73730': {
    country: 'US',
    city: 'Covington',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.3099,
    longitude: -97.5752,
    postal_code: '73730'
  },
  '73731': {
    country: 'US',
    city: 'Dacoma',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woods',
    latitude: 36.6606,
    longitude: -98.5942,
    postal_code: '73731'
  },
  '73733': {
    country: 'US',
    city: 'Douglas',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.2481,
    longitude: -97.6896,
    postal_code: '73733'
  },
  '73734': {
    country: 'US',
    city: 'Dover',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kingfisher',
    latitude: 35.9848,
    longitude: -97.9067,
    postal_code: '73734'
  },
  '73735': {
    country: 'US',
    city: 'Drummond',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.2841,
    longitude: -98.0358,
    postal_code: '73735'
  },
  '73736': {
    country: 'US',
    city: 'Fairmont',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.34,
    longitude: -97.6848,
    postal_code: '73736'
  },
  '73737': {
    country: 'US',
    city: 'Fairview',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Major',
    latitude: 36.2871,
    longitude: -98.604,
    postal_code: '73737'
  },
  '73738': {
    country: 'US',
    city: 'Garber',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.4392,
    longitude: -97.5789,
    postal_code: '73738'
  },
  '73739': {
    country: 'US',
    city: 'Goltry',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Alfalfa',
    latitude: 36.5314,
    longitude: -98.154,
    postal_code: '73739'
  },
  '73741': {
    country: 'US',
    city: 'Helena',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Alfalfa',
    latitude: 36.5438,
    longitude: -98.2778,
    postal_code: '73741'
  },
  '73742': {
    country: 'US',
    city: 'Hennessey',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kingfisher',
    latitude: 36.0868,
    longitude: -97.8926,
    postal_code: '73742'
  },
  '73743': {
    country: 'US',
    city: 'Hillsdale',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.5631,
    longitude: -97.9915,
    postal_code: '73743'
  },
  '73744': {
    country: 'US',
    city: 'Hitchcock',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Blaine',
    latitude: 35.9712,
    longitude: -98.3315,
    postal_code: '73744'
  },
  '73746': {
    country: 'US',
    city: 'Hopeton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woods',
    latitude: 36.6881,
    longitude: -98.6659,
    postal_code: '73746'
  },
  '73747': {
    country: 'US',
    city: 'Isabella',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Major',
    latitude: 36.2341,
    longitude: -98.3374,
    postal_code: '73747'
  },
  '73749': {
    country: 'US',
    city: 'Jet',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Alfalfa',
    latitude: 36.6929,
    longitude: -98.1721,
    postal_code: '73749'
  },
  '73750': {
    country: 'US',
    city: 'Kingfisher',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kingfisher',
    latitude: 35.8636,
    longitude: -97.9473,
    postal_code: '73750'
  },
  '73753': {
    country: 'US',
    city: 'Kremlin',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.5207,
    longitude: -97.8542,
    postal_code: '73753'
  },
  '73754': {
    country: 'US',
    city: 'Lahoma',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.385,
    longitude: -98.0727,
    postal_code: '73754'
  },
  '73755': {
    country: 'US',
    city: 'Longdale',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Blaine',
    latitude: 36.1212,
    longitude: -98.55,
    postal_code: '73755'
  },
  '73756': {
    country: 'US',
    city: 'Loyal',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kingfisher',
    latitude: 35.9705,
    longitude: -98.1155,
    postal_code: '73756'
  },
  '73757': {
    country: 'US',
    city: 'Lucien',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Noble',
    latitude: 36.2753,
    longitude: -97.4526,
    postal_code: '73757'
  },
  '73758': {
    country: 'US',
    city: 'Manchester',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grant',
    latitude: 36.9744,
    longitude: -98.0383,
    postal_code: '73758'
  },
  '73759': {
    country: 'US',
    city: 'Medford',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grant',
    latitude: 36.8142,
    longitude: -97.7202,
    postal_code: '73759'
  },
  '73760': {
    country: 'US',
    city: 'Meno',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Major',
    latitude: 36.3815,
    longitude: -98.1635,
    postal_code: '73760'
  },
  '73761': {
    country: 'US',
    city: 'Nash',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grant',
    latitude: 36.6961,
    longitude: -98.0258,
    postal_code: '73761'
  },
  '73762': {
    country: 'US',
    city: 'Okarche',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kingfisher',
    latitude: 35.7502,
    longitude: -97.93,
    postal_code: '73762'
  },
  '73763': {
    country: 'US',
    city: 'Okeene',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Blaine',
    latitude: 36.1165,
    longitude: -98.3254,
    postal_code: '73763'
  },
  '73764': {
    country: 'US',
    city: 'Omega',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kingfisher',
    latitude: 35.8507,
    longitude: -98.1863,
    postal_code: '73764'
  },
  '73766': {
    country: 'US',
    city: 'Pond Creek',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grant',
    latitude: 36.6643,
    longitude: -97.8019,
    postal_code: '73766'
  },
  '73768': {
    country: 'US',
    city: 'Ringwood',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Major',
    latitude: 36.3753,
    longitude: -98.2706,
    postal_code: '73768'
  },
  '73770': {
    country: 'US',
    city: 'Southard',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Blaine',
    latitude: 36.0581,
    longitude: -98.5884,
    postal_code: '73770'
  },
  '73771': {
    country: 'US',
    city: 'Wakita',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grant',
    latitude: 36.8753,
    longitude: -97.9427,
    postal_code: '73771'
  },
  '73772': {
    country: 'US',
    city: 'Watonga',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Blaine',
    latitude: 35.8538,
    longitude: -98.4175,
    postal_code: '73772'
  },
  '73773': {
    country: 'US',
    city: 'Waukomis',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.2781,
    longitude: -97.8996,
    postal_code: '73773'
  },
  '73801': {
    country: 'US',
    city: 'Woodward',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woodward',
    latitude: 36.4268,
    longitude: -99.402,
    postal_code: '73801'
  },
  '73802': {
    country: 'US',
    city: 'Woodward',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woodward',
    latitude: 36.544,
    longitude: -99.3003,
    postal_code: '73802'
  },
  '73832': {
    country: 'US',
    city: 'Arnett',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ellis',
    latitude: 36.0522,
    longitude: -99.6514,
    postal_code: '73832'
  },
  '73834': {
    country: 'US',
    city: 'Buffalo',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Harper',
    latitude: 36.8523,
    longitude: -99.5538,
    postal_code: '73834'
  },
  '73835': {
    country: 'US',
    city: 'Camargo',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Dewey',
    latitude: 36.0212,
    longitude: -99.2781,
    postal_code: '73835'
  },
  '73838': {
    country: 'US',
    city: 'Chester',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Major',
    latitude: 36.2621,
    longitude: -98.8838,
    postal_code: '73838'
  },
  '73840': {
    country: 'US',
    city: 'Fargo',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ellis',
    latitude: 36.3761,
    longitude: -99.6237,
    postal_code: '73840'
  },
  '73841': {
    country: 'US',
    city: 'Fort Supply',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woodward',
    latitude: 36.5722,
    longitude: -99.5738,
    postal_code: '73841'
  },
  '73842': {
    country: 'US',
    city: 'Freedom',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woods',
    latitude: 36.809,
    longitude: -99.1319,
    postal_code: '73842'
  },
  '73843': {
    country: 'US',
    city: 'Gage',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ellis',
    latitude: 36.318,
    longitude: -99.76,
    postal_code: '73843'
  },
  '73844': {
    country: 'US',
    city: 'Gate',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beaver',
    latitude: 36.8757,
    longitude: -100.0734,
    postal_code: '73844'
  },
  '73848': {
    country: 'US',
    city: 'Laverne',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Harper',
    latitude: 36.7062,
    longitude: -99.8918,
    postal_code: '73848'
  },
  '73851': {
    country: 'US',
    city: 'May',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Harper',
    latitude: 36.6226,
    longitude: -99.7858,
    postal_code: '73851'
  },
  '73852': {
    country: 'US',
    city: 'Mooreland',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woodward',
    latitude: 36.4428,
    longitude: -99.1832,
    postal_code: '73852'
  },
  '73853': {
    country: 'US',
    city: 'Mutual',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woodward',
    latitude: 36.214,
    longitude: -99.1145,
    postal_code: '73853'
  },
  '73855': {
    country: 'US',
    city: 'Rosston',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Harper',
    latitude: 36.8789,
    longitude: -99.9003,
    postal_code: '73855'
  },
  '73857': {
    country: 'US',
    city: 'Sharon',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woodward',
    latitude: 36.2699,
    longitude: -99.3587,
    postal_code: '73857'
  },
  '73858': {
    country: 'US',
    city: 'Shattuck',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ellis',
    latitude: 36.2889,
    longitude: -99.8793,
    postal_code: '73858'
  },
  '73859': {
    country: 'US',
    city: 'Vici',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Dewey',
    latitude: 36.1372,
    longitude: -99.267,
    postal_code: '73859'
  },
  '73860': {
    country: 'US',
    city: 'Waynoka',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Woods',
    latitude: 36.5858,
    longitude: -98.8487,
    postal_code: '73860'
  },
  '73901': {
    country: 'US',
    city: 'Adams',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Texas',
    latitude: 36.755,
    longitude: -101.078,
    postal_code: '73901'
  },
  '73931': {
    country: 'US',
    city: 'Balko',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beaver',
    latitude: 36.5996,
    longitude: -100.7103,
    postal_code: '73931'
  },
  '73932': {
    country: 'US',
    city: 'Beaver',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beaver',
    latitude: 36.8341,
    longitude: -100.5177,
    postal_code: '73932'
  },
  '73933': {
    country: 'US',
    city: 'Boise City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cimarron',
    latitude: 36.7283,
    longitude: -102.5355,
    postal_code: '73933'
  },
  '73937': {
    country: 'US',
    city: 'Felt',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cimarron',
    latitude: 36.5666,
    longitude: -102.7974,
    postal_code: '73937'
  },
  '73938': {
    country: 'US',
    city: 'Forgan',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beaver',
    latitude: 36.9086,
    longitude: -100.5409,
    postal_code: '73938'
  },
  '73939': {
    country: 'US',
    city: 'Goodwell',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Texas',
    latitude: 36.6744,
    longitude: -101.7137,
    postal_code: '73939'
  },
  '73942': {
    country: 'US',
    city: 'Guymon',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Texas',
    latitude: 36.6961,
    longitude: -101.4778,
    postal_code: '73942'
  },
  '73944': {
    country: 'US',
    city: 'Hardesty',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Texas',
    latitude: 36.6018,
    longitude: -101.1539,
    postal_code: '73944'
  },
  '73945': {
    country: 'US',
    city: 'Hooker',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Texas',
    latitude: 36.8109,
    longitude: -101.2163,
    postal_code: '73945'
  },
  '73946': {
    country: 'US',
    city: 'Kenton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cimarron',
    latitude: 36.9031,
    longitude: -102.9653,
    postal_code: '73946'
  },
  '73947': {
    country: 'US',
    city: 'Keyes',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cimarron',
    latitude: 36.8003,
    longitude: -102.2361,
    postal_code: '73947'
  },
  '73949': {
    country: 'US',
    city: 'Texhoma',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Texas',
    latitude: 36.5253,
    longitude: -101.8394,
    postal_code: '73949'
  },
  '73950': {
    country: 'US',
    city: 'Turpin',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Beaver',
    latitude: 36.8664,
    longitude: -100.8779,
    postal_code: '73950'
  },
  '73951': {
    country: 'US',
    city: 'Tyrone',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Texas',
    latitude: 36.9558,
    longitude: -101.0594,
    postal_code: '73951'
  },
  '73960': {
    country: 'US',
    city: 'Texhoma',
    state: 'Texas',
    state_short: 'TX',
    county: 'Sherman',
    latitude: 36.5055,
    longitude: -101.7829,
    postal_code: '73960'
  },
  '74001': {
    country: 'US',
    city: 'Avant',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.4901,
    longitude: -96.0616,
    postal_code: '74001'
  },
  '74002': {
    country: 'US',
    city: 'Barnsdall',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.5429,
    longitude: -96.1318,
    postal_code: '74002'
  },
  '74003': {
    country: 'US',
    city: 'Bartlesville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washington',
    latitude: 36.744,
    longitude: -95.9921,
    postal_code: '74003'
  },
  '74004': {
    country: 'US',
    city: 'Bartlesville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washington',
    latitude: 36.7114,
    longitude: -95.894,
    postal_code: '74004'
  },
  '74005': {
    country: 'US',
    city: 'Bartlesville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washington',
    latitude: 36.7473,
    longitude: -95.9808,
    postal_code: '74005'
  },
  '74006': {
    country: 'US',
    city: 'Bartlesville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washington',
    latitude: 36.7366,
    longitude: -95.9251,
    postal_code: '74006'
  },
  '74008': {
    country: 'US',
    city: 'Bixby',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 35.942,
    longitude: -95.8833,
    postal_code: '74008'
  },
  '74010': {
    country: 'US',
    city: 'Bristow',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 35.8209,
    longitude: -96.3758,
    postal_code: '74010'
  },
  '74011': {
    country: 'US',
    city: 'Broken Arrow',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 35.9908,
    longitude: -95.8143,
    postal_code: '74011'
  },
  '74012': {
    country: 'US',
    city: 'Broken Arrow',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0447,
    longitude: -95.8079,
    postal_code: '74012'
  },
  '74013': {
    country: 'US',
    city: 'Broken Arrow',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0526,
    longitude: -95.7908,
    postal_code: '74013'
  },
  '74014': {
    country: 'US',
    city: 'Broken Arrow',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Wagoner',
    latitude: 36.0544,
    longitude: -95.7223,
    postal_code: '74014'
  },
  '74015': {
    country: 'US',
    city: 'Catoosa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Rogers',
    latitude: 36.1721,
    longitude: -95.7273,
    postal_code: '74015'
  },
  '74016': {
    country: 'US',
    city: 'Chelsea',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Rogers',
    latitude: 36.5356,
    longitude: -95.4489,
    postal_code: '74016'
  },
  '74017': {
    country: 'US',
    city: 'Claremore',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Rogers',
    latitude: 36.3242,
    longitude: -95.5985,
    postal_code: '74017'
  },
  '74018': {
    country: 'US',
    city: 'Claremore',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Rogers',
    latitude: 36.3436,
    longitude: -95.606,
    postal_code: '74018'
  },
  '74019': {
    country: 'US',
    city: 'Claremore',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Wagoner',
    latitude: 36.2806,
    longitude: -95.6039,
    postal_code: '74019'
  },
  '74020': {
    country: 'US',
    city: 'Cleveland',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pawnee',
    latitude: 36.2553,
    longitude: -96.423,
    postal_code: '74020'
  },
  '74021': {
    country: 'US',
    city: 'Collinsville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.3701,
    longitude: -95.8469,
    postal_code: '74021'
  },
  '74022': {
    country: 'US',
    city: 'Copan',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washington',
    latitude: 36.9062,
    longitude: -95.913,
    postal_code: '74022'
  },
  '74023': {
    country: 'US',
    city: 'Cushing',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Payne',
    latitude: 35.9822,
    longitude: -96.7526,
    postal_code: '74023'
  },
  '74026': {
    country: 'US',
    city: 'Davenport',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Lincoln',
    latitude: 35.7089,
    longitude: -96.767,
    postal_code: '74026'
  },
  '74027': {
    country: 'US',
    city: 'Delaware',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Nowata',
    latitude: 36.7804,
    longitude: -95.6181,
    postal_code: '74027'
  },
  '74028': {
    country: 'US',
    city: 'Depew',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 35.7561,
    longitude: -96.4897,
    postal_code: '74028'
  },
  '74029': {
    country: 'US',
    city: 'Dewey',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washington',
    latitude: 36.8013,
    longitude: -95.9345,
    postal_code: '74029'
  },
  '74030': {
    country: 'US',
    city: 'Drumright',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 35.9931,
    longitude: -96.5198,
    postal_code: '74030'
  },
  '74031': {
    country: 'US',
    city: 'Foyil',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Rogers',
    latitude: 36.4348,
    longitude: -95.5194,
    postal_code: '74031'
  },
  '74032': {
    country: 'US',
    city: 'Glencoe',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Payne',
    latitude: 36.2158,
    longitude: -96.9138,
    postal_code: '74032'
  },
  '74033': {
    country: 'US',
    city: 'Glenpool',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 35.9591,
    longitude: -95.9997,
    postal_code: '74033'
  },
  '74034': {
    country: 'US',
    city: 'Hallett',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pawnee',
    latitude: 36.2319,
    longitude: -96.562,
    postal_code: '74034'
  },
  '74035': {
    country: 'US',
    city: 'Hominy',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.4111,
    longitude: -96.3878,
    postal_code: '74035'
  },
  '74036': {
    country: 'US',
    city: 'Inola',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Rogers',
    latitude: 36.1503,
    longitude: -95.5205,
    postal_code: '74036'
  },
  '74037': {
    country: 'US',
    city: 'Jenks',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0148,
    longitude: -95.9797,
    postal_code: '74037'
  },
  '74038': {
    country: 'US',
    city: 'Jennings',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pawnee',
    latitude: 36.1863,
    longitude: -96.5732,
    postal_code: '74038'
  },
  '74039': {
    country: 'US',
    city: 'Kellyville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 35.9171,
    longitude: -96.218,
    postal_code: '74039'
  },
  '74041': {
    country: 'US',
    city: 'Kiefer',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 35.9528,
    longitude: -96.061,
    postal_code: '74041'
  },
  '74042': {
    country: 'US',
    city: 'Lenapah',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Nowata',
    latitude: 36.8801,
    longitude: -95.6233,
    postal_code: '74042'
  },
  '74043': {
    country: 'US',
    city: 'Leonard',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 35.9183,
    longitude: -95.7988,
    postal_code: '74043'
  },
  '74044': {
    country: 'US',
    city: 'Mannford',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 36.0927,
    longitude: -96.3576,
    postal_code: '74044'
  },
  '74045': {
    country: 'US',
    city: 'Maramec',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pawnee',
    latitude: 36.242,
    longitude: -96.6803,
    postal_code: '74045'
  },
  '74046': {
    country: 'US',
    city: 'Milfay',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 35.7551,
    longitude: -96.5658,
    postal_code: '74046'
  },
  '74047': {
    country: 'US',
    city: 'Mounds',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 35.8896,
    longitude: -96.0887,
    postal_code: '74047'
  },
  '74048': {
    country: 'US',
    city: 'Nowata',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Nowata',
    latitude: 36.6946,
    longitude: -95.6404,
    postal_code: '74048'
  },
  '74050': {
    country: 'US',
    city: 'Oakhurst',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0731,
    longitude: -96.0621,
    postal_code: '74050'
  },
  '74051': {
    country: 'US',
    city: 'Ochelata',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washington',
    latitude: 36.5947,
    longitude: -95.9691,
    postal_code: '74051'
  },
  '74052': {
    country: 'US',
    city: 'Oilton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 36.0685,
    longitude: -96.5958,
    postal_code: '74052'
  },
  '74053': {
    country: 'US',
    city: 'Oologah',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Rogers',
    latitude: 36.4482,
    longitude: -95.7047,
    postal_code: '74053'
  },
  '74054': {
    country: 'US',
    city: 'Osage',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.2832,
    longitude: -96.3774,
    postal_code: '74054'
  },
  '74055': {
    country: 'US',
    city: 'Owasso',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.2863,
    longitude: -95.8222,
    postal_code: '74055'
  },
  '74056': {
    country: 'US',
    city: 'Pawhuska',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.6904,
    longitude: -96.3121,
    postal_code: '74056'
  },
  '74058': {
    country: 'US',
    city: 'Pawnee',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pawnee',
    latitude: 36.3362,
    longitude: -96.7923,
    postal_code: '74058'
  },
  '74059': {
    country: 'US',
    city: 'Perkins',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Payne',
    latitude: 35.9768,
    longitude: -97.0441,
    postal_code: '74059'
  },
  '74060': {
    country: 'US',
    city: 'Prue',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.2501,
    longitude: -96.2701,
    postal_code: '74060'
  },
  '74061': {
    country: 'US',
    city: 'Ramona',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washington',
    latitude: 36.5752,
    longitude: -95.8962,
    postal_code: '74061'
  },
  '74062': {
    country: 'US',
    city: 'Ripley',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Payne',
    latitude: 35.9985,
    longitude: -96.8967,
    postal_code: '74062'
  },
  '74063': {
    country: 'US',
    city: 'Sand Springs',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1341,
    longitude: -96.1426,
    postal_code: '74063'
  },
  '74066': {
    country: 'US',
    city: 'Sapulpa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 36.003,
    longitude: -96.1106,
    postal_code: '74066'
  },
  '74067': {
    country: 'US',
    city: 'Sapulpa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 36.0196,
    longitude: -96.0937,
    postal_code: '74067'
  },
  '74068': {
    country: 'US',
    city: 'Shamrock',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 35.9124,
    longitude: -96.578,
    postal_code: '74068'
  },
  '74070': {
    country: 'US',
    city: 'Skiatook',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.3725,
    longitude: -96.0123,
    postal_code: '74070'
  },
  '74071': {
    country: 'US',
    city: 'Slick',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 35.7861,
    longitude: -96.2806,
    postal_code: '74071'
  },
  '74072': {
    country: 'US',
    city: 'S Coffeyville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Nowata',
    latitude: 36.9837,
    longitude: -95.6065,
    postal_code: '74072'
  },
  '74073': {
    country: 'US',
    city: 'Sperry',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.2955,
    longitude: -95.9804,
    postal_code: '74073'
  },
  '74074': {
    country: 'US',
    city: 'Stillwater',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Payne',
    latitude: 36.1043,
    longitude: -97.0609,
    postal_code: '74074'
  },
  '74075': {
    country: 'US',
    city: 'Stillwater',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Payne',
    latitude: 36.1396,
    longitude: -97.063,
    postal_code: '74075'
  },
  '74076': {
    country: 'US',
    city: 'Stillwater',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Payne',
    latitude: 36.0724,
    longitude: -97.0551,
    postal_code: '74076'
  },
  '74077': {
    country: 'US',
    city: 'Stillwater',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Payne',
    latitude: 36.1156,
    longitude: -97.0584,
    postal_code: '74077'
  },
  '74078': {
    country: 'US',
    city: 'Stillwater',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Payne',
    latitude: 36.1241,
    longitude: -97.0705,
    postal_code: '74078'
  },
  '74079': {
    country: 'US',
    city: 'Stroud',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Lincoln',
    latitude: 35.7398,
    longitude: -96.6719,
    postal_code: '74079'
  },
  '74080': {
    country: 'US',
    city: 'Talala',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Rogers',
    latitude: 36.5429,
    longitude: -95.7142,
    postal_code: '74080'
  },
  '74081': {
    country: 'US',
    city: 'Terlton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pawnee',
    latitude: 36.1889,
    longitude: -96.4876,
    postal_code: '74081'
  },
  '74082': {
    country: 'US',
    city: 'Vera',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Washington',
    latitude: 36.4501,
    longitude: -95.8814,
    postal_code: '74082'
  },
  '74083': {
    country: 'US',
    city: 'Wann',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Nowata',
    latitude: 36.9402,
    longitude: -95.7768,
    postal_code: '74083'
  },
  '74084': {
    country: 'US',
    city: 'Wynona',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.5462,
    longitude: -96.3272,
    postal_code: '74084'
  },
  '74085': {
    country: 'US',
    city: 'Yale',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Payne',
    latitude: 36.1101,
    longitude: -96.7022,
    postal_code: '74085'
  },
  '74101': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0391,
    longitude: -95.8687,
    postal_code: '74101'
  },
  '74102': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.154,
    longitude: -95.9928,
    postal_code: '74102'
  },
  '74103': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1539,
    longitude: -95.9954,
    postal_code: '74103'
  },
  '74104': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1464,
    longitude: -95.9526,
    postal_code: '74104'
  },
  '74105': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0948,
    longitude: -95.9655,
    postal_code: '74105'
  },
  '74106': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1883,
    longitude: -95.986,
    postal_code: '74106'
  },
  '74107': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1042,
    longitude: -96.0244,
    postal_code: '74107'
  },
  '74108': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1499,
    longitude: -95.7923,
    postal_code: '74108'
  },
  '74110': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1803,
    longitude: -95.9525,
    postal_code: '74110'
  },
  '74112': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.147,
    longitude: -95.907,
    postal_code: '74112'
  },
  '74114': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1262,
    longitude: -95.9408,
    postal_code: '74114'
  },
  '74115': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1754,
    longitude: -95.9112,
    postal_code: '74115'
  },
  '74116': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.175,
    longitude: -95.8477,
    postal_code: '74116'
  },
  '74117': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.2393,
    longitude: -95.8979,
    postal_code: '74117'
  },
  '74119': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1407,
    longitude: -95.9902,
    postal_code: '74119'
  },
  '74120': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1442,
    longitude: -95.9734,
    postal_code: '74120'
  },
  '74121': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74121'
  },
  '74126': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.2383,
    longitude: -95.9931,
    postal_code: '74126'
  },
  '74127': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.1576,
    longitude: -96.0311,
    postal_code: '74127'
  },
  '74128': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1459,
    longitude: -95.8514,
    postal_code: '74128'
  },
  '74129': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1259,
    longitude: -95.8654,
    postal_code: '74129'
  },
  '74130': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.2395,
    longitude: -95.9596,
    postal_code: '74130'
  },
  '74131': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Creek',
    latitude: 36.0557,
    longitude: -96.0602,
    postal_code: '74131'
  },
  '74132': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.064,
    longitude: -96.0251,
    postal_code: '74132'
  },
  '74133': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0467,
    longitude: -95.8841,
    postal_code: '74133'
  },
  '74134': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1162,
    longitude: -95.8225,
    postal_code: '74134'
  },
  '74135': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0976,
    longitude: -95.9228,
    postal_code: '74135'
  },
  '74136': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0605,
    longitude: -95.9452,
    postal_code: '74136'
  },
  '74137': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0284,
    longitude: -95.9306,
    postal_code: '74137'
  },
  '74141': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74141'
  },
  '74145': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0934,
    longitude: -95.8856,
    postal_code: '74145'
  },
  '74146': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1093,
    longitude: -95.8506,
    postal_code: '74146'
  },
  '74147': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74147'
  },
  '74148': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74148'
  },
  '74149': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74149'
  },
  '74150': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74150'
  },
  '74152': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74152'
  },
  '74153': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74153'
  },
  '74155': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74155'
  },
  '74156': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.3024,
    longitude: -95.9605,
    postal_code: '74156'
  },
  '74157': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74157'
  },
  '74158': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74158'
  },
  '74159': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.0772,
    longitude: -96.0835,
    postal_code: '74159'
  },
  '74169': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74169'
  },
  '74170': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74170'
  },
  '74171': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.154,
    longitude: -95.9928,
    postal_code: '74171'
  },
  '74172': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1543,
    longitude: -95.9923,
    postal_code: '74172'
  },
  '74182': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.154,
    longitude: -95.9928,
    postal_code: '74182'
  },
  '74186': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.154,
    longitude: -95.9928,
    postal_code: '74186'
  },
  '74187': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.154,
    longitude: -95.9928,
    postal_code: '74187'
  },
  '74192': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.154,
    longitude: -95.9928,
    postal_code: '74192'
  },
  '74193': {
    country: 'US',
    city: 'Tulsa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Tulsa',
    latitude: 36.1398,
    longitude: -96.0297,
    postal_code: '74193'
  },
  '74301': {
    country: 'US',
    city: 'Vinita',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Craig',
    latitude: 36.6334,
    longitude: -95.1382,
    postal_code: '74301'
  },
  '74330': {
    country: 'US',
    city: 'Adair',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.4115,
    longitude: -95.2732,
    postal_code: '74330'
  },
  '74331': {
    country: 'US',
    city: 'Afton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ottawa',
    latitude: 36.6296,
    longitude: -94.9432,
    postal_code: '74331'
  },
  '74332': {
    country: 'US',
    city: 'Big Cabin',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Craig',
    latitude: 36.534,
    longitude: -95.2246,
    postal_code: '74332'
  },
  '74333': {
    country: 'US',
    city: 'Bluejacket',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Craig',
    latitude: 36.7975,
    longitude: -95.1018,
    postal_code: '74333'
  },
  '74335': {
    country: 'US',
    city: 'Cardin',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ottawa',
    latitude: 36.9795,
    longitude: -94.8557,
    postal_code: '74335'
  },
  '74337': {
    country: 'US',
    city: 'Chouteau',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.1669,
    longitude: -95.3416,
    postal_code: '74337'
  },
  '74338': {
    country: 'US',
    city: 'Colcord',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Delaware',
    latitude: 36.2333,
    longitude: -94.6547,
    postal_code: '74338'
  },
  '74339': {
    country: 'US',
    city: 'Commerce',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ottawa',
    latitude: 36.9331,
    longitude: -94.873,
    postal_code: '74339'
  },
  '74340': {
    country: 'US',
    city: 'Disney',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.4759,
    longitude: -95.024,
    postal_code: '74340'
  },
  '74342': {
    country: 'US',
    city: 'Eucha',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Delaware',
    latitude: 36.4063,
    longitude: -94.9157,
    postal_code: '74342'
  },
  '74343': {
    country: 'US',
    city: 'Fairland',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ottawa',
    latitude: 36.7418,
    longitude: -94.8279,
    postal_code: '74343'
  },
  '74344': {
    country: 'US',
    city: 'Grove',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Delaware',
    latitude: 36.5929,
    longitude: -94.7565,
    postal_code: '74344'
  },
  '74345': {
    country: 'US',
    city: 'Grove',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Delaware',
    latitude: 36.6036,
    longitude: -94.7297,
    postal_code: '74345'
  },
  '74346': {
    country: 'US',
    city: 'Jay',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Delaware',
    latitude: 36.4363,
    longitude: -94.7763,
    postal_code: '74346'
  },
  '74347': {
    country: 'US',
    city: 'Kansas',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Delaware',
    latitude: 36.2161,
    longitude: -94.8114,
    postal_code: '74347'
  },
  '74349': {
    country: 'US',
    city: 'Ketchum',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.5245,
    longitude: -95.0239,
    postal_code: '74349'
  },
  '74350': {
    country: 'US',
    city: 'Langley',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.4645,
    longitude: -95.0478,
    postal_code: '74350'
  },
  '74352': {
    country: 'US',
    city: 'Locust Grove',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.1819,
    longitude: -95.1689,
    postal_code: '74352'
  },
  '74354': {
    country: 'US',
    city: 'Miami',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ottawa',
    latitude: 36.8764,
    longitude: -94.8719,
    postal_code: '74354'
  },
  '74355': {
    country: 'US',
    city: 'Miami',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ottawa',
    latitude: 36.8341,
    longitude: -94.877,
    postal_code: '74355'
  },
  '74358': {
    country: 'US',
    city: 'North Miami',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ottawa',
    latitude: 36.9173,
    longitude: -94.8797,
    postal_code: '74358'
  },
  '74359': {
    country: 'US',
    city: 'Oaks',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Delaware',
    latitude: 36.1662,
    longitude: -94.8547,
    postal_code: '74359'
  },
  '74360': {
    country: 'US',
    city: 'Picher',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ottawa',
    latitude: 36.98,
    longitude: -94.8173,
    postal_code: '74360'
  },
  '74361': {
    country: 'US',
    city: 'Pryor',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.2921,
    longitude: -95.3129,
    postal_code: '74361'
  },
  '74362': {
    country: 'US',
    city: 'Pryor',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.2925,
    longitude: -95.2228,
    postal_code: '74362'
  },
  '74363': {
    country: 'US',
    city: 'Quapaw',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ottawa',
    latitude: 36.9282,
    longitude: -94.743,
    postal_code: '74363'
  },
  '74364': {
    country: 'US',
    city: 'Rose',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.1951,
    longitude: -94.9643,
    postal_code: '74364'
  },
  '74365': {
    country: 'US',
    city: 'Salina',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.3116,
    longitude: -95.1158,
    postal_code: '74365'
  },
  '74366': {
    country: 'US',
    city: 'Spavinaw',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.3912,
    longitude: -95.0466,
    postal_code: '74366'
  },
  '74367': {
    country: 'US',
    city: 'Strang',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Mayes',
    latitude: 36.4141,
    longitude: -95.0773,
    postal_code: '74367'
  },
  '74368': {
    country: 'US',
    city: 'Twin Oaks',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Delaware',
    latitude: 36.2054,
    longitude: -94.8513,
    postal_code: '74368'
  },
  '74369': {
    country: 'US',
    city: 'Welch',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Craig',
    latitude: 36.902,
    longitude: -95.1295,
    postal_code: '74369'
  },
  '74370': {
    country: 'US',
    city: 'Wyandotte',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Ottawa',
    latitude: 36.7796,
    longitude: -94.7002,
    postal_code: '74370'
  },
  '74401': {
    country: 'US',
    city: 'Muskogee',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.7058,
    longitude: -95.5002,
    postal_code: '74401'
  },
  '74402': {
    country: 'US',
    city: 'Muskogee',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.7642,
    longitude: -95.3069,
    postal_code: '74402'
  },
  '74403': {
    country: 'US',
    city: 'Muskogee',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.7411,
    longitude: -95.3449,
    postal_code: '74403'
  },
  '74421': {
    country: 'US',
    city: 'Beggs',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okmulgee',
    latitude: 35.7896,
    longitude: -96.0264,
    postal_code: '74421'
  },
  '74422': {
    country: 'US',
    city: 'Boynton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.6577,
    longitude: -95.66,
    postal_code: '74422'
  },
  '74423': {
    country: 'US',
    city: 'Braggs',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.6574,
    longitude: -95.2033,
    postal_code: '74423'
  },
  '74425': {
    country: 'US',
    city: 'Canadian',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 35.1571,
    longitude: -95.6215,
    postal_code: '74425'
  },
  '74426': {
    country: 'US',
    city: 'Checotah',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McIntosh',
    latitude: 35.4358,
    longitude: -95.535,
    postal_code: '74426'
  },
  '74427': {
    country: 'US',
    city: 'Cookson',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cherokee',
    latitude: 35.7116,
    longitude: -94.9132,
    postal_code: '74427'
  },
  '74428': {
    country: 'US',
    city: 'Council Hill',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.5556,
    longitude: -95.6514,
    postal_code: '74428'
  },
  '74429': {
    country: 'US',
    city: 'Coweta',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Wagoner',
    latitude: 35.9578,
    longitude: -95.6526,
    postal_code: '74429'
  },
  '74430': {
    country: 'US',
    city: 'Crowder',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 35.1334,
    longitude: -95.6602,
    postal_code: '74430'
  },
  '74431': {
    country: 'US',
    city: 'Dewar',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okmulgee',
    latitude: 35.4551,
    longitude: -95.9498,
    postal_code: '74431'
  },
  '74432': {
    country: 'US',
    city: 'Eufaula',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McIntosh',
    latitude: 35.2911,
    longitude: -95.6472,
    postal_code: '74432'
  },
  '74434': {
    country: 'US',
    city: 'Fort Gibson',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.7943,
    longitude: -95.2297,
    postal_code: '74434'
  },
  '74435': {
    country: 'US',
    city: 'Gore',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Sequoyah',
    latitude: 35.5418,
    longitude: -95.1095,
    postal_code: '74435'
  },
  '74436': {
    country: 'US',
    city: 'Haskell',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.8108,
    longitude: -95.684,
    postal_code: '74436'
  },
  '74437': {
    country: 'US',
    city: 'Henryetta',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okmulgee',
    latitude: 35.4622,
    longitude: -96.0122,
    postal_code: '74437'
  },
  '74438': {
    country: 'US',
    city: 'Hitchita',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McIntosh',
    latitude: 35.5297,
    longitude: -95.7596,
    postal_code: '74438'
  },
  '74439': {
    country: 'US',
    city: 'Braggs',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.6767,
    longitude: -95.1775,
    postal_code: '74439'
  },
  '74440': {
    country: 'US',
    city: 'Hoyt',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Haskell',
    latitude: 35.2685,
    longitude: -95.2994,
    postal_code: '74440'
  },
  '74441': {
    country: 'US',
    city: 'Hulbert',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cherokee',
    latitude: 35.9254,
    longitude: -95.1651,
    postal_code: '74441'
  },
  '74442': {
    country: 'US',
    city: 'Indianola',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 35.1314,
    longitude: -95.8158,
    postal_code: '74442'
  },
  '74444': {
    country: 'US',
    city: 'Moodys',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cherokee',
    latitude: 36.0458,
    longitude: -94.9669,
    postal_code: '74444'
  },
  '74445': {
    country: 'US',
    city: 'Morris',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okmulgee',
    latitude: 35.6619,
    longitude: -95.8319,
    postal_code: '74445'
  },
  '74446': {
    country: 'US',
    city: 'Okay',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Wagoner',
    latitude: 35.8507,
    longitude: -95.3183,
    postal_code: '74446'
  },
  '74447': {
    country: 'US',
    city: 'Okmulgee',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okmulgee',
    latitude: 35.6288,
    longitude: -95.9697,
    postal_code: '74447'
  },
  '74450': {
    country: 'US',
    city: 'Oktaha',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.625,
    longitude: -95.4856,
    postal_code: '74450'
  },
  '74451': {
    country: 'US',
    city: 'Park Hill',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cherokee',
    latitude: 35.7977,
    longitude: -94.9822,
    postal_code: '74451'
  },
  '74452': {
    country: 'US',
    city: 'Peggs',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cherokee',
    latitude: 36.1154,
    longitude: -95.1199,
    postal_code: '74452'
  },
  '74454': {
    country: 'US',
    city: 'Porter',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Wagoner',
    latitude: 35.8567,
    longitude: -95.5082,
    postal_code: '74454'
  },
  '74455': {
    country: 'US',
    city: 'Porum',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.3631,
    longitude: -95.2607,
    postal_code: '74455'
  },
  '74456': {
    country: 'US',
    city: 'Preston',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okmulgee',
    latitude: 35.7115,
    longitude: -95.9917,
    postal_code: '74456'
  },
  '74457': {
    country: 'US',
    city: 'Proctor',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Adair',
    latitude: 36.0308,
    longitude: -94.7613,
    postal_code: '74457'
  },
  '74458': {
    country: 'US',
    city: 'Redbird',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Wagoner',
    latitude: 35.8836,
    longitude: -95.5917,
    postal_code: '74458'
  },
  '74459': {
    country: 'US',
    city: 'Rentiesville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McIntosh',
    latitude: 35.5214,
    longitude: -95.495,
    postal_code: '74459'
  },
  '74460': {
    country: 'US',
    city: 'Schulter',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okmulgee',
    latitude: 35.5145,
    longitude: -95.9567,
    postal_code: '74460'
  },
  '74461': {
    country: 'US',
    city: 'Stidham',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McIntosh',
    latitude: 35.3814,
    longitude: -95.7056,
    postal_code: '74461'
  },
  '74462': {
    country: 'US',
    city: 'Stigler',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Haskell',
    latitude: 35.2686,
    longitude: -95.1071,
    postal_code: '74462'
  },
  '74463': {
    country: 'US',
    city: 'Taft',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.7581,
    longitude: -95.5494,
    postal_code: '74463'
  },
  '74464': {
    country: 'US',
    city: 'Tahlequah',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cherokee',
    latitude: 35.9094,
    longitude: -94.9787,
    postal_code: '74464'
  },
  '74465': {
    country: 'US',
    city: 'Tahlequah',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cherokee',
    latitude: 35.9001,
    longitude: -95.04,
    postal_code: '74465'
  },
  '74467': {
    country: 'US',
    city: 'Wagoner',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Wagoner',
    latitude: 35.9549,
    longitude: -95.354,
    postal_code: '74467'
  },
  '74468': {
    country: 'US',
    city: 'Wainwright',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.6126,
    longitude: -95.5644,
    postal_code: '74468'
  },
  '74469': {
    country: 'US',
    city: 'Warner',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.4945,
    longitude: -95.3064,
    postal_code: '74469'
  },
  '74470': {
    country: 'US',
    city: 'Webbers Falls',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Muskogee',
    latitude: 35.5138,
    longitude: -95.1659,
    postal_code: '74470'
  },
  '74471': {
    country: 'US',
    city: 'Welling',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Cherokee',
    latitude: 35.8819,
    longitude: -94.8653,
    postal_code: '74471'
  },
  '74472': {
    country: 'US',
    city: 'Whitefield',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Haskell',
    latitude: 35.2646,
    longitude: -95.2645,
    postal_code: '74472'
  },
  '74477': {
    country: 'US',
    city: 'Wagoner',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Wagoner',
    latitude: 35.9636,
    longitude: -95.5139,
    postal_code: '74477'
  },
  '74501': {
    country: 'US',
    city: 'Mcalester',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.9262,
    longitude: -95.7592,
    postal_code: '74501'
  },
  '74502': {
    country: 'US',
    city: 'Mcalester',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.9334,
    longitude: -95.7697,
    postal_code: '74502'
  },
  '74521': {
    country: 'US',
    city: 'Albion',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pushmataha',
    latitude: 34.6516,
    longitude: -95.0924,
    postal_code: '74521'
  },
  '74522': {
    country: 'US',
    city: 'Alderson',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.9001,
    longitude: -95.6919,
    postal_code: '74522'
  },
  '74523': {
    country: 'US',
    city: 'Antlers',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pushmataha',
    latitude: 34.2349,
    longitude: -95.6254,
    postal_code: '74523'
  },
  '74525': {
    country: 'US',
    city: 'Atoka',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Atoka',
    latitude: 34.3445,
    longitude: -96.1418,
    postal_code: '74525'
  },
  '74528': {
    country: 'US',
    city: 'Blanco',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.7276,
    longitude: -95.7283,
    postal_code: '74528'
  },
  '74529': {
    country: 'US',
    city: 'Blocker',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 35.0615,
    longitude: -95.5683,
    postal_code: '74529'
  },
  '74530': {
    country: 'US',
    city: 'Bromide',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Johnston',
    latitude: 34.4233,
    longitude: -96.4867,
    postal_code: '74530'
  },
  '74531': {
    country: 'US',
    city: 'Calvin',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Hughes',
    latitude: 34.878,
    longitude: -96.271,
    postal_code: '74531'
  },
  '74533': {
    country: 'US',
    city: 'Caney',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Atoka',
    latitude: 34.2221,
    longitude: -96.2584,
    postal_code: '74533'
  },
  '74534': {
    country: 'US',
    city: 'Centrahoma',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Coal',
    latitude: 34.6066,
    longitude: -96.3386,
    postal_code: '74534'
  },
  '74535': {
    country: 'US',
    city: 'Clarita',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Coal',
    latitude: 34.4829,
    longitude: -96.4333,
    postal_code: '74535'
  },
  '74536': {
    country: 'US',
    city: 'Clayton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pushmataha',
    latitude: 34.5906,
    longitude: -95.38,
    postal_code: '74536'
  },
  '74538': {
    country: 'US',
    city: 'Coalgate',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Coal',
    latitude: 34.5344,
    longitude: -96.2167,
    postal_code: '74538'
  },
  '74540': {
    country: 'US',
    city: 'Daisy',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Atoka',
    latitude: 34.5384,
    longitude: -95.7088,
    postal_code: '74540'
  },
  '74543': {
    country: 'US',
    city: 'Finley',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pushmataha',
    latitude: 34.3407,
    longitude: -95.5385,
    postal_code: '74543'
  },
  '74545': {
    country: 'US',
    city: 'Gowen',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Latimer',
    latitude: 34.8724,
    longitude: -95.469,
    postal_code: '74545'
  },
  '74546': {
    country: 'US',
    city: 'Haileyville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.85,
    longitude: -95.5777,
    postal_code: '74546'
  },
  '74547': {
    country: 'US',
    city: 'Hartshorne',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.8452,
    longitude: -95.574,
    postal_code: '74547'
  },
  '74549': {
    country: 'US',
    city: 'Honobia',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.5519,
    longitude: -94.9428,
    postal_code: '74549'
  },
  '74552': {
    country: 'US',
    city: 'Kinta',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Haskell',
    latitude: 35.1193,
    longitude: -95.2377,
    postal_code: '74552'
  },
  '74553': {
    country: 'US',
    city: 'Kiowa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.7278,
    longitude: -95.9328,
    postal_code: '74553'
  },
  '74554': {
    country: 'US',
    city: 'Krebs',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.9255,
    longitude: -95.7216,
    postal_code: '74554'
  },
  '74555': {
    country: 'US',
    city: 'Lane',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Atoka',
    latitude: 34.2691,
    longitude: -95.9685,
    postal_code: '74555'
  },
  '74556': {
    country: 'US',
    city: 'Lehigh',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Coal',
    latitude: 34.4756,
    longitude: -96.1889,
    postal_code: '74556'
  },
  '74557': {
    country: 'US',
    city: 'Moyers',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pushmataha',
    latitude: 34.3386,
    longitude: -95.6639,
    postal_code: '74557'
  },
  '74558': {
    country: 'US',
    city: 'Nashoba',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pushmataha',
    latitude: 34.5072,
    longitude: -95.2075,
    postal_code: '74558'
  },
  '74559': {
    country: 'US',
    city: 'Panola',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Latimer',
    latitude: 34.9287,
    longitude: -95.2133,
    postal_code: '74559'
  },
  '74560': {
    country: 'US',
    city: 'Pittsburg',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.6975,
    longitude: -95.8439,
    postal_code: '74560'
  },
  '74561': {
    country: 'US',
    city: 'Quinton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 35.1547,
    longitude: -95.4671,
    postal_code: '74561'
  },
  '74562': {
    country: 'US',
    city: 'Rattan',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pushmataha',
    latitude: 34.2454,
    longitude: -95.3442,
    postal_code: '74562'
  },
  '74563': {
    country: 'US',
    city: 'Red Oak',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Latimer',
    latitude: 34.9422,
    longitude: -95.0904,
    postal_code: '74563'
  },
  '74565': {
    country: 'US',
    city: 'Savanna',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.8354,
    longitude: -95.838,
    postal_code: '74565'
  },
  '74567': {
    country: 'US',
    city: 'Snow',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pushmataha',
    latitude: 34.4419,
    longitude: -95.4622,
    postal_code: '74567'
  },
  '74569': {
    country: 'US',
    city: 'Stringtown',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Atoka',
    latitude: 34.4676,
    longitude: -96.0002,
    postal_code: '74569'
  },
  '74570': {
    country: 'US',
    city: 'Stuart',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Hughes',
    latitude: 34.8826,
    longitude: -96.1381,
    postal_code: '74570'
  },
  '74571': {
    country: 'US',
    city: 'Talihina',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.7381,
    longitude: -94.9978,
    postal_code: '74571'
  },
  '74572': {
    country: 'US',
    city: 'Tupelo',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Coal',
    latitude: 34.5598,
    longitude: -96.4274,
    postal_code: '74572'
  },
  '74574': {
    country: 'US',
    city: 'Tuskahoma',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pushmataha',
    latitude: 34.6622,
    longitude: -95.2362,
    postal_code: '74574'
  },
  '74576': {
    country: 'US',
    city: 'Wardville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pittsburg',
    latitude: 34.6695,
    longitude: -96.0258,
    postal_code: '74576'
  },
  '74577': {
    country: 'US',
    city: 'Whitesboro',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.6926,
    longitude: -94.8852,
    postal_code: '74577'
  },
  '74578': {
    country: 'US',
    city: 'Wilburton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Latimer',
    latitude: 34.9128,
    longitude: -95.3389,
    postal_code: '74578'
  },
  '74601': {
    country: 'US',
    city: 'Ponca City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kay',
    latitude: 36.7031,
    longitude: -97.0784,
    postal_code: '74601'
  },
  '74602': {
    country: 'US',
    city: 'Ponca City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kay',
    latitude: 36.6954,
    longitude: -97.1377,
    postal_code: '74602'
  },
  '74604': {
    country: 'US',
    city: 'Ponca City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kay',
    latitude: 36.7299,
    longitude: -97.0454,
    postal_code: '74604'
  },
  '74630': {
    country: 'US',
    city: 'Billings',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Noble',
    latitude: 36.5246,
    longitude: -97.4189,
    postal_code: '74630'
  },
  '74631': {
    country: 'US',
    city: 'Blackwell',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kay',
    latitude: 36.8006,
    longitude: -97.2867,
    postal_code: '74631'
  },
  '74632': {
    country: 'US',
    city: 'Braman',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kay',
    latitude: 36.9331,
    longitude: -97.3082,
    postal_code: '74632'
  },
  '74633': {
    country: 'US',
    city: 'Burbank',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.6966,
    longitude: -96.7869,
    postal_code: '74633'
  },
  '74636': {
    country: 'US',
    city: 'Deer Creek',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grant',
    latitude: 36.8048,
    longitude: -97.5136,
    postal_code: '74636'
  },
  '74637': {
    country: 'US',
    city: 'Fairfax',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.5577,
    longitude: -96.6997,
    postal_code: '74637'
  },
  '74640': {
    country: 'US',
    city: 'Hunter',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garfield',
    latitude: 36.5603,
    longitude: -97.6425,
    postal_code: '74640'
  },
  '74641': {
    country: 'US',
    city: 'Kaw City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kay',
    latitude: 36.8382,
    longitude: -96.8833,
    postal_code: '74641'
  },
  '74643': {
    country: 'US',
    city: 'Lamont',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Grant',
    latitude: 36.6939,
    longitude: -97.5601,
    postal_code: '74643'
  },
  '74644': {
    country: 'US',
    city: 'Marland',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Noble',
    latitude: 36.5609,
    longitude: -97.1512,
    postal_code: '74644'
  },
  '74646': {
    country: 'US',
    city: 'Nardin',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kay',
    latitude: 36.8155,
    longitude: -97.4325,
    postal_code: '74646'
  },
  '74647': {
    country: 'US',
    city: 'Newkirk',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kay',
    latitude: 36.84,
    longitude: -97.0561,
    postal_code: '74647'
  },
  '74650': {
    country: 'US',
    city: 'Ralston',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pawnee',
    latitude: 36.4992,
    longitude: -96.7755,
    postal_code: '74650'
  },
  '74651': {
    country: 'US',
    city: 'Red Rock',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Noble',
    latitude: 36.4748,
    longitude: -97.164,
    postal_code: '74651'
  },
  '74652': {
    country: 'US',
    city: 'Shidler',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Osage',
    latitude: 36.7819,
    longitude: -96.6607,
    postal_code: '74652'
  },
  '74653': {
    country: 'US',
    city: 'Tonkawa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Kay',
    latitude: 36.6806,
    longitude: -97.3063,
    postal_code: '74653'
  },
  '74701': {
    country: 'US',
    city: 'Durant',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 34.0061,
    longitude: -96.3847,
    postal_code: '74701'
  },
  '74702': {
    country: 'US',
    city: 'Durant',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.922,
    longitude: -96.1918,
    postal_code: '74702'
  },
  '74720': {
    country: 'US',
    city: 'Achille',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.8396,
    longitude: -96.3648,
    postal_code: '74720'
  },
  '74721': {
    country: 'US',
    city: 'Albany',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.8815,
    longitude: -96.1628,
    postal_code: '74721'
  },
  '74722': {
    country: 'US',
    city: 'Battiest',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.3934,
    longitude: -94.9249,
    postal_code: '74722'
  },
  '74723': {
    country: 'US',
    city: 'Bennington',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.9771,
    longitude: -96.0188,
    postal_code: '74723'
  },
  '74724': {
    country: 'US',
    city: 'Bethel',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.3598,
    longitude: -94.8374,
    postal_code: '74724'
  },
  '74726': {
    country: 'US',
    city: 'Bokchito',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.9859,
    longitude: -96.1621,
    postal_code: '74726'
  },
  '74727': {
    country: 'US',
    city: 'Boswell',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Choctaw',
    latitude: 34.0245,
    longitude: -95.8403,
    postal_code: '74727'
  },
  '74728': {
    country: 'US',
    city: 'Broken Bow',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.027,
    longitude: -94.7623,
    postal_code: '74728'
  },
  '74729': {
    country: 'US',
    city: 'Caddo',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 34.1157,
    longitude: -96.26,
    postal_code: '74729'
  },
  '74730': {
    country: 'US',
    city: 'Calera',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.9322,
    longitude: -96.4308,
    postal_code: '74730'
  },
  '74731': {
    country: 'US',
    city: 'Cartwright',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.8668,
    longitude: -96.5672,
    postal_code: '74731'
  },
  '74733': {
    country: 'US',
    city: 'Colbert',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.8575,
    longitude: -96.4953,
    postal_code: '74733'
  },
  '74734': {
    country: 'US',
    city: 'Eagletown',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.0364,
    longitude: -94.5596,
    postal_code: '74734'
  },
  '74735': {
    country: 'US',
    city: 'Fort Towson',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Choctaw',
    latitude: 34.052,
    longitude: -95.253,
    postal_code: '74735'
  },
  '74736': {
    country: 'US',
    city: 'Garvin',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 33.9192,
    longitude: -94.986,
    postal_code: '74736'
  },
  '74737': {
    country: 'US',
    city: 'Golden',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.0303,
    longitude: -94.8986,
    postal_code: '74737'
  },
  '74738': {
    country: 'US',
    city: 'Grant',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Choctaw',
    latitude: 33.9302,
    longitude: -95.4893,
    postal_code: '74738'
  },
  '74740': {
    country: 'US',
    city: 'Haworth',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 33.785,
    longitude: -94.633,
    postal_code: '74740'
  },
  '74741': {
    country: 'US',
    city: 'Hendrix',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.8016,
    longitude: -96.3581,
    postal_code: '74741'
  },
  '74743': {
    country: 'US',
    city: 'Hugo',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Choctaw',
    latitude: 34.0113,
    longitude: -95.5139,
    postal_code: '74743'
  },
  '74745': {
    country: 'US',
    city: 'Idabel',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 33.8851,
    longitude: -94.802,
    postal_code: '74745'
  },
  '74747': {
    country: 'US',
    city: 'Kemp',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.773,
    longitude: -96.3555,
    postal_code: '74747'
  },
  '74748': {
    country: 'US',
    city: 'Kenefic',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 34.1481,
    longitude: -96.3622,
    postal_code: '74748'
  },
  '74750': {
    country: 'US',
    city: 'Millerton',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 33.9801,
    longitude: -95.0025,
    postal_code: '74750'
  },
  '74752': {
    country: 'US',
    city: 'Pickens',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.3965,
    longitude: -95.0119,
    postal_code: '74752'
  },
  '74753': {
    country: 'US',
    city: 'Platter',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Bryan',
    latitude: 33.907,
    longitude: -96.535,
    postal_code: '74753'
  },
  '74754': {
    country: 'US',
    city: 'Ringold',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.1799,
    longitude: -95.0704,
    postal_code: '74754'
  },
  '74755': {
    country: 'US',
    city: 'Rufe',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.1272,
    longitude: -95.1174,
    postal_code: '74755'
  },
  '74756': {
    country: 'US',
    city: 'Sawyer',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Choctaw',
    latitude: 34.0277,
    longitude: -95.3558,
    postal_code: '74756'
  },
  '74759': {
    country: 'US',
    city: 'Soper',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Choctaw',
    latitude: 34.0366,
    longitude: -95.6916,
    postal_code: '74759'
  },
  '74760': {
    country: 'US',
    city: 'Spencerville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Choctaw',
    latitude: 34.1508,
    longitude: -95.3771,
    postal_code: '74760'
  },
  '74761': {
    country: 'US',
    city: 'Swink',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Choctaw',
    latitude: 34.0179,
    longitude: -95.2022,
    postal_code: '74761'
  },
  '74764': {
    country: 'US',
    city: 'Valliant',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.0091,
    longitude: -95.0686,
    postal_code: '74764'
  },
  '74766': {
    country: 'US',
    city: 'Wright City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.0799,
    longitude: -94.9929,
    postal_code: '74766'
  },
  '74801': {
    country: 'US',
    city: 'Shawnee',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 35.2958,
    longitude: -96.9601,
    postal_code: '74801'
  },
  '74802': {
    country: 'US',
    city: 'Shawnee',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 35.3273,
    longitude: -96.9253,
    postal_code: '74802'
  },
  '74804': {
    country: 'US',
    city: 'Shawnee',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 35.3868,
    longitude: -96.9331,
    postal_code: '74804'
  },
  '74818': {
    country: 'US',
    city: 'Seminole',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Seminole',
    latitude: 35.2245,
    longitude: -96.6706,
    postal_code: '74818'
  },
  '74820': {
    country: 'US',
    city: 'Ada',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pontotoc',
    latitude: 34.78,
    longitude: -96.6924,
    postal_code: '74820'
  },
  '74821': {
    country: 'US',
    city: 'Ada',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pontotoc',
    latitude: 34.7745,
    longitude: -96.6783,
    postal_code: '74821'
  },
  '74824': {
    country: 'US',
    city: 'Agra',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Lincoln',
    latitude: 35.909,
    longitude: -96.8738,
    postal_code: '74824'
  },
  '74825': {
    country: 'US',
    city: 'Allen',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pontotoc',
    latitude: 34.8691,
    longitude: -96.4151,
    postal_code: '74825'
  },
  '74826': {
    country: 'US',
    city: 'Asher',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 34.9849,
    longitude: -96.8763,
    postal_code: '74826'
  },
  '74827': {
    country: 'US',
    city: 'Atwood',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Hughes',
    latitude: 34.9562,
    longitude: -96.3386,
    postal_code: '74827'
  },
  '74829': {
    country: 'US',
    city: 'Boley',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okfuskee',
    latitude: 35.4913,
    longitude: -96.4704,
    postal_code: '74829'
  },
  '74830': {
    country: 'US',
    city: 'Bowlegs',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Seminole',
    latitude: 35.1462,
    longitude: -96.67,
    postal_code: '74830'
  },
  '74831': {
    country: 'US',
    city: 'Byars',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McClain',
    latitude: 34.8904,
    longitude: -97.0997,
    postal_code: '74831'
  },
  '74832': {
    country: 'US',
    city: 'Carney',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Lincoln',
    latitude: 35.8054,
    longitude: -97.0159,
    postal_code: '74832'
  },
  '74833': {
    country: 'US',
    city: 'Castle',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okfuskee',
    latitude: 35.4732,
    longitude: -96.3791,
    postal_code: '74833'
  },
  '74834': {
    country: 'US',
    city: 'Chandler',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Lincoln',
    latitude: 35.7017,
    longitude: -96.8809,
    postal_code: '74834'
  },
  '74836': {
    country: 'US',
    city: 'Connerville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Johnston',
    latitude: 34.4194,
    longitude: -96.6291,
    postal_code: '74836'
  },
  '74837': {
    country: 'US',
    city: 'Cromwell',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Seminole',
    latitude: 35.3405,
    longitude: -96.4546,
    postal_code: '74837'
  },
  '74839': {
    country: 'US',
    city: 'Dustin',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Hughes',
    latitude: 35.2519,
    longitude: -96.0573,
    postal_code: '74839'
  },
  '74840': {
    country: 'US',
    city: 'Earlsboro',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 35.2628,
    longitude: -96.8041,
    postal_code: '74840'
  },
  '74842': {
    country: 'US',
    city: 'Fittstown',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pontotoc',
    latitude: 34.6148,
    longitude: -96.6345,
    postal_code: '74842'
  },
  '74843': {
    country: 'US',
    city: 'Fitzhugh',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pontotoc',
    latitude: 34.6615,
    longitude: -96.7745,
    postal_code: '74843'
  },
  '74844': {
    country: 'US',
    city: 'Francis',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pontotoc',
    latitude: 34.8737,
    longitude: -96.5956,
    postal_code: '74844'
  },
  '74845': {
    country: 'US',
    city: 'Hanna',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McIntosh',
    latitude: 35.2604,
    longitude: -95.8675,
    postal_code: '74845'
  },
  '74848': {
    country: 'US',
    city: 'Holdenville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Hughes',
    latitude: 35.0839,
    longitude: -96.377,
    postal_code: '74848'
  },
  '74849': {
    country: 'US',
    city: 'Konawa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Seminole',
    latitude: 34.9708,
    longitude: -96.7343,
    postal_code: '74849'
  },
  '74850': {
    country: 'US',
    city: 'Lamar',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Hughes',
    latitude: 35.0836,
    longitude: -96.1142,
    postal_code: '74850'
  },
  '74851': {
    country: 'US',
    city: 'Mcloud',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 35.4262,
    longitude: -97.079,
    postal_code: '74851'
  },
  '74852': {
    country: 'US',
    city: 'Macomb',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 35.1205,
    longitude: -97.034,
    postal_code: '74852'
  },
  '74854': {
    country: 'US',
    city: 'Maud',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 35.1292,
    longitude: -96.7627,
    postal_code: '74854'
  },
  '74855': {
    country: 'US',
    city: 'Meeker',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Lincoln',
    latitude: 35.5211,
    longitude: -96.9981,
    postal_code: '74855'
  },
  '74856': {
    country: 'US',
    city: 'Mill Creek',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Johnston',
    latitude: 34.3098,
    longitude: -96.7884,
    postal_code: '74856'
  },
  '74857': {
    country: 'US',
    city: 'Newalla',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Oklahoma',
    latitude: 35.3734,
    longitude: -97.1971,
    postal_code: '74857'
  },
  '74859': {
    country: 'US',
    city: 'Okemah',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okfuskee',
    latitude: 35.4647,
    longitude: -96.3287,
    postal_code: '74859'
  },
  '74860': {
    country: 'US',
    city: 'Paden',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okfuskee',
    latitude: 35.5182,
    longitude: -96.5719,
    postal_code: '74860'
  },
  '74864': {
    country: 'US',
    city: 'Prague',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Lincoln',
    latitude: 35.5105,
    longitude: -96.7009,
    postal_code: '74864'
  },
  '74865': {
    country: 'US',
    city: 'Roff',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pontotoc',
    latitude: 34.6153,
    longitude: -96.8423,
    postal_code: '74865'
  },
  '74866': {
    country: 'US',
    city: 'Saint Louis',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 35.0588,
    longitude: -96.8444,
    postal_code: '74866'
  },
  '74867': {
    country: 'US',
    city: 'Sasakwa',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Seminole',
    latitude: 34.951,
    longitude: -96.5387,
    postal_code: '74867'
  },
  '74868': {
    country: 'US',
    city: 'Seminole',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Seminole',
    latitude: 35.2521,
    longitude: -96.6683,
    postal_code: '74868'
  },
  '74869': {
    country: 'US',
    city: 'Sparks',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Lincoln',
    latitude: 35.6138,
    longitude: -96.8163,
    postal_code: '74869'
  },
  '74871': {
    country: 'US',
    city: 'Stonewall',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pontotoc',
    latitude: 34.6511,
    longitude: -96.5273,
    postal_code: '74871'
  },
  '74872': {
    country: 'US',
    city: 'Stratford',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Garvin',
    latitude: 34.7714,
    longitude: -96.9763,
    postal_code: '74872'
  },
  '74873': {
    country: 'US',
    city: 'Tecumseh',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 35.2502,
    longitude: -96.9667,
    postal_code: '74873'
  },
  '74875': {
    country: 'US',
    city: 'Tryon',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Lincoln',
    latitude: 35.8759,
    longitude: -96.9623,
    postal_code: '74875'
  },
  '74878': {
    country: 'US',
    city: 'Wanette',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Pottawatomie',
    latitude: 34.9983,
    longitude: -97.0419,
    postal_code: '74878'
  },
  '74880': {
    country: 'US',
    city: 'Weleetka',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Okfuskee',
    latitude: 35.3416,
    longitude: -96.1136,
    postal_code: '74880'
  },
  '74881': {
    country: 'US',
    city: 'Wellston',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Lincoln',
    latitude: 35.6757,
    longitude: -97.0597,
    postal_code: '74881'
  },
  '74883': {
    country: 'US',
    city: 'Wetumka',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Hughes',
    latitude: 35.2396,
    longitude: -96.2421,
    postal_code: '74883'
  },
  '74884': {
    country: 'US',
    city: 'Wewoka',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Seminole',
    latitude: 35.1463,
    longitude: -96.628,
    postal_code: '74884'
  },
  '74901': {
    country: 'US',
    city: 'Arkoma',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 35.3434,
    longitude: -94.4403,
    postal_code: '74901'
  },
  '74902': {
    country: 'US',
    city: 'Pocola',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 35.2436,
    longitude: -94.476,
    postal_code: '74902'
  },
  '74930': {
    country: 'US',
    city: 'Bokoshe',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 35.1865,
    longitude: -94.7858,
    postal_code: '74930'
  },
  '74931': {
    country: 'US',
    city: 'Bunch',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Adair',
    latitude: 35.6826,
    longitude: -94.7611,
    postal_code: '74931'
  },
  '74932': {
    country: 'US',
    city: 'Cameron',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 35.1494,
    longitude: -94.506,
    postal_code: '74932'
  },
  '74935': {
    country: 'US',
    city: 'Fanshawe',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.9537,
    longitude: -94.8998,
    postal_code: '74935'
  },
  '74936': {
    country: 'US',
    city: 'Gans',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Sequoyah',
    latitude: 35.3946,
    longitude: -94.6901,
    postal_code: '74936'
  },
  '74937': {
    country: 'US',
    city: 'Heavener',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.8353,
    longitude: -94.6185,
    postal_code: '74937'
  },
  '74939': {
    country: 'US',
    city: 'Hodgen',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.7538,
    longitude: -94.6391,
    postal_code: '74939'
  },
  '74940': {
    country: 'US',
    city: 'Howe',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.9512,
    longitude: -94.6372,
    postal_code: '74940'
  },
  '74941': {
    country: 'US',
    city: 'Keota',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Haskell',
    latitude: 35.2645,
    longitude: -94.9028,
    postal_code: '74941'
  },
  '74942': {
    country: 'US',
    city: 'Leflore',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.8979,
    longitude: -94.9758,
    postal_code: '74942'
  },
  '74943': {
    country: 'US',
    city: 'Lequire',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Haskell',
    latitude: 35.0867,
    longitude: -95.0974,
    postal_code: '74943'
  },
  '74944': {
    country: 'US',
    city: 'Mccurtain',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Haskell',
    latitude: 35.1404,
    longitude: -95.0127,
    postal_code: '74944'
  },
  '74945': {
    country: 'US',
    city: 'Marble City',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Sequoyah',
    latitude: 35.5972,
    longitude: -94.8094,
    postal_code: '74945'
  },
  '74946': {
    country: 'US',
    city: 'Moffett',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Sequoyah',
    latitude: 35.3898,
    longitude: -94.4462,
    postal_code: '74946'
  },
  '74947': {
    country: 'US',
    city: 'Monroe',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.9906,
    longitude: -94.515,
    postal_code: '74947'
  },
  '74948': {
    country: 'US',
    city: 'Muldrow',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Sequoyah',
    latitude: 35.402,
    longitude: -94.6332,
    postal_code: '74948'
  },
  '74949': {
    country: 'US',
    city: 'Muse',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.6641,
    longitude: -94.719,
    postal_code: '74949'
  },
  '74951': {
    country: 'US',
    city: 'Panama',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 35.1535,
    longitude: -94.6709,
    postal_code: '74951'
  },
  '74953': {
    country: 'US',
    city: 'Poteau',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 35.0606,
    longitude: -94.6096,
    postal_code: '74953'
  },
  '74954': {
    country: 'US',
    city: 'Roland',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Sequoyah',
    latitude: 35.4538,
    longitude: -94.5291,
    postal_code: '74954'
  },
  '74955': {
    country: 'US',
    city: 'Sallisaw',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Sequoyah',
    latitude: 35.4852,
    longitude: -94.779,
    postal_code: '74955'
  },
  '74956': {
    country: 'US',
    city: 'Shady Point',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 35.1293,
    longitude: -94.6665,
    postal_code: '74956'
  },
  '74957': {
    country: 'US',
    city: 'Smithville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.4954,
    longitude: -94.6981,
    postal_code: '74957'
  },
  '74959': {
    country: 'US',
    city: 'Spiro',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 35.2492,
    longitude: -94.6265,
    postal_code: '74959'
  },
  '74960': {
    country: 'US',
    city: 'Stilwell',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Adair',
    latitude: 35.8107,
    longitude: -94.6313,
    postal_code: '74960'
  },
  '74962': {
    country: 'US',
    city: 'Vian',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Sequoyah',
    latitude: 35.5404,
    longitude: -94.9888,
    postal_code: '74962'
  },
  '74963': {
    country: 'US',
    city: 'Watson',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'McCurtain',
    latitude: 34.4193,
    longitude: -94.5562,
    postal_code: '74963'
  },
  '74964': {
    country: 'US',
    city: 'Watts',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Adair',
    latitude: 36.1152,
    longitude: -94.6345,
    postal_code: '74964'
  },
  '74965': {
    country: 'US',
    city: 'Westville',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Adair',
    latitude: 35.9912,
    longitude: -94.5926,
    postal_code: '74965'
  },
  '74966': {
    country: 'US',
    city: 'Wister',
    state: 'Oklahoma',
    state_short: 'OK',
    county: 'Le Flore',
    latitude: 34.9556,
    longitude: -94.7832,
    postal_code: '74966'
  },
  '75001': {
    country: 'US',
    city: 'Addison',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.96,
    longitude: -96.8385,
    postal_code: '75001'
  },
  '75002': {
    country: 'US',
    city: 'Allen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0934,
    longitude: -96.6454,
    postal_code: '75002'
  },
  '75006': {
    country: 'US',
    city: 'Carrollton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9657,
    longitude: -96.8825,
    postal_code: '75006'
  },
  '75007': {
    country: 'US',
    city: 'Carrollton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.0033,
    longitude: -96.882,
    postal_code: '75007'
  },
  '75009': {
    country: 'US',
    city: 'Celina',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.3103,
    longitude: -96.7673,
    postal_code: '75009'
  },
  '75010': {
    country: 'US',
    city: 'Carrollton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.0304,
    longitude: -96.8777,
    postal_code: '75010'
  },
  '75011': {
    country: 'US',
    city: 'Carrollton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9537,
    longitude: -96.8903,
    postal_code: '75011'
  },
  '75013': {
    country: 'US',
    city: 'Allen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.1186,
    longitude: -96.6773,
    postal_code: '75013'
  },
  '75014': {
    country: 'US',
    city: 'Irving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75014'
  },
  '75015': {
    country: 'US',
    city: 'Irving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8987,
    longitude: -96.9722,
    postal_code: '75015'
  },
  '75016': {
    country: 'US',
    city: 'Irving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8987,
    longitude: -96.9722,
    postal_code: '75016'
  },
  '75017': {
    country: 'US',
    city: 'Irving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75017'
  },
  '75019': {
    country: 'US',
    city: 'Coppell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9673,
    longitude: -96.9805,
    postal_code: '75019'
  },
  '75020': {
    country: 'US',
    city: 'Denison',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.745,
    longitude: -96.5496,
    postal_code: '75020'
  },
  '75021': {
    country: 'US',
    city: 'Denison',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.7169,
    longitude: -96.5235,
    postal_code: '75021'
  },
  '75022': {
    country: 'US',
    city: 'Flower Mound',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.0268,
    longitude: -97.1193,
    postal_code: '75022'
  },
  '75023': {
    country: 'US',
    city: 'Plano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.055,
    longitude: -96.7365,
    postal_code: '75023'
  },
  '75024': {
    country: 'US',
    city: 'Plano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0752,
    longitude: -96.7843,
    postal_code: '75024'
  },
  '75025': {
    country: 'US',
    city: 'Plano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0784,
    longitude: -96.7291,
    postal_code: '75025'
  },
  '75026': {
    country: 'US',
    city: 'Plano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0198,
    longitude: -96.6989,
    postal_code: '75026'
  },
  '75027': {
    country: 'US',
    city: 'Flower Mound',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.2074,
    longitude: -97.1163,
    postal_code: '75027'
  },
  '75028': {
    country: 'US',
    city: 'Flower Mound',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.0383,
    longitude: -97.0745,
    postal_code: '75028'
  },
  '75029': {
    country: 'US',
    city: 'Lewisville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.0462,
    longitude: -96.9942,
    postal_code: '75029'
  },
  '75030': {
    country: 'US',
    city: 'Rowlett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9029,
    longitude: -96.5639,
    postal_code: '75030'
  },
  '75032': {
    country: 'US',
    city: 'Rockwall',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rockwall',
    latitude: 32.886,
    longitude: -96.4095,
    postal_code: '75032'
  },
  '75033': {
    country: 'US',
    city: 'Frisco',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.2559,
    longitude: -96.8853,
    postal_code: '75033'
  },
  '75034': {
    country: 'US',
    city: 'Frisco',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.1499,
    longitude: -96.8241,
    postal_code: '75034'
  },
  '75035': {
    country: 'US',
    city: 'Frisco',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.1377,
    longitude: -96.7524,
    postal_code: '75035'
  },
  '75036': {
    country: 'US',
    city: 'Frisco',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.1499,
    longitude: -96.8241,
    postal_code: '75036'
  },
  '75038': {
    country: 'US',
    city: 'Irving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8653,
    longitude: -96.9905,
    postal_code: '75038'
  },
  '75039': {
    country: 'US',
    city: 'Irving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8697,
    longitude: -96.9389,
    postal_code: '75039'
  },
  '75040': {
    country: 'US',
    city: 'Garland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9227,
    longitude: -96.6248,
    postal_code: '75040'
  },
  '75041': {
    country: 'US',
    city: 'Garland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8794,
    longitude: -96.6411,
    postal_code: '75041'
  },
  '75042': {
    country: 'US',
    city: 'Garland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9185,
    longitude: -96.6775,
    postal_code: '75042'
  },
  '75043': {
    country: 'US',
    city: 'Garland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8565,
    longitude: -96.5999,
    postal_code: '75043'
  },
  '75044': {
    country: 'US',
    city: 'Garland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9522,
    longitude: -96.6654,
    postal_code: '75044'
  },
  '75045': {
    country: 'US',
    city: 'Garland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9137,
    longitude: -96.6271,
    postal_code: '75045'
  },
  '75046': {
    country: 'US',
    city: 'Garland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9126,
    longitude: -96.6389,
    postal_code: '75046'
  },
  '75047': {
    country: 'US',
    city: 'Garland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9126,
    longitude: -96.6389,
    postal_code: '75047'
  },
  '75048': {
    country: 'US',
    city: 'Sachse',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9643,
    longitude: -96.6012,
    postal_code: '75048'
  },
  '75049': {
    country: 'US',
    city: 'Garland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9126,
    longitude: -96.6389,
    postal_code: '75049'
  },
  '75050': {
    country: 'US',
    city: 'Grand Prairie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7649,
    longitude: -97.0112,
    postal_code: '75050'
  },
  '75051': {
    country: 'US',
    city: 'Grand Prairie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7115,
    longitude: -97.0069,
    postal_code: '75051'
  },
  '75052': {
    country: 'US',
    city: 'Grand Prairie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.6605,
    longitude: -97.0311,
    postal_code: '75052'
  },
  '75053': {
    country: 'US',
    city: 'Grand Prairie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.746,
    longitude: -96.9978,
    postal_code: '75053'
  },
  '75054': {
    country: 'US',
    city: 'Grand Prairie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.5907,
    longitude: -97.0405,
    postal_code: '75054'
  },
  '75056': {
    country: 'US',
    city: 'The Colony',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.094,
    longitude: -96.8836,
    postal_code: '75056'
  },
  '75057': {
    country: 'US',
    city: 'Lewisville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.0532,
    longitude: -96.9999,
    postal_code: '75057'
  },
  '75058': {
    country: 'US',
    city: 'Gunter',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.4495,
    longitude: -96.7341,
    postal_code: '75058'
  },
  '75060': {
    country: 'US',
    city: 'Irving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8023,
    longitude: -96.9597,
    postal_code: '75060'
  },
  '75061': {
    country: 'US',
    city: 'Irving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8267,
    longitude: -96.9633,
    postal_code: '75061'
  },
  '75062': {
    country: 'US',
    city: 'Irving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8479,
    longitude: -96.974,
    postal_code: '75062'
  },
  '75063': {
    country: 'US',
    city: 'Irving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9247,
    longitude: -96.9598,
    postal_code: '75063'
  },
  '75065': {
    country: 'US',
    city: 'Lake Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.1219,
    longitude: -97.0237,
    postal_code: '75065'
  },
  '75067': {
    country: 'US',
    city: 'Lewisville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.0198,
    longitude: -96.9925,
    postal_code: '75067'
  },
  '75068': {
    country: 'US',
    city: 'Little Elm',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.1768,
    longitude: -96.9583,
    postal_code: '75068'
  },
  '75069': {
    country: 'US',
    city: 'Mckinney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.1976,
    longitude: -96.6153,
    postal_code: '75069'
  },
  '75070': {
    country: 'US',
    city: 'Mckinney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.1976,
    longitude: -96.6153,
    postal_code: '75070'
  },
  '75071': {
    country: 'US',
    city: 'Mckinney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.1976,
    longitude: -96.6153,
    postal_code: '75071'
  },
  '75072': {
    country: 'US',
    city: 'Mckinney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.1976,
    longitude: -96.6153,
    postal_code: '75072'
  },
  '75074': {
    country: 'US',
    city: 'Plano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0277,
    longitude: -96.6777,
    postal_code: '75074'
  },
  '75075': {
    country: 'US',
    city: 'Plano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.025,
    longitude: -96.7397,
    postal_code: '75075'
  },
  '75076': {
    country: 'US',
    city: 'Pottsboro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.8095,
    longitude: -96.6906,
    postal_code: '75076'
  },
  '75077': {
    country: 'US',
    city: 'Lewisville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.0775,
    longitude: -97.0704,
    postal_code: '75077'
  },
  '75078': {
    country: 'US',
    city: 'Prosper',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.2362,
    longitude: -96.7954,
    postal_code: '75078'
  },
  '75080': {
    country: 'US',
    city: 'Richardson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.966,
    longitude: -96.7452,
    postal_code: '75080'
  },
  '75081': {
    country: 'US',
    city: 'Richardson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9462,
    longitude: -96.7058,
    postal_code: '75081'
  },
  '75082': {
    country: 'US',
    city: 'Richardson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9865,
    longitude: -96.686,
    postal_code: '75082'
  },
  '75083': {
    country: 'US',
    city: 'Richardson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75083'
  },
  '75085': {
    country: 'US',
    city: 'Richardson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75085'
  },
  '75086': {
    country: 'US',
    city: 'Plano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0198,
    longitude: -96.6989,
    postal_code: '75086'
  },
  '75087': {
    country: 'US',
    city: 'Rockwall',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rockwall',
    latitude: 32.9492,
    longitude: -96.4418,
    postal_code: '75087'
  },
  '75088': {
    country: 'US',
    city: 'Rowlett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9031,
    longitude: -96.5472,
    postal_code: '75088'
  },
  '75089': {
    country: 'US',
    city: 'Rowlett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9346,
    longitude: -96.5544,
    postal_code: '75089'
  },
  '75090': {
    country: 'US',
    city: 'Sherman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.6435,
    longitude: -96.6075,
    postal_code: '75090'
  },
  '75091': {
    country: 'US',
    city: 'Sherman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.6357,
    longitude: -96.6089,
    postal_code: '75091'
  },
  '75092': {
    country: 'US',
    city: 'Sherman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.6372,
    longitude: -96.6184,
    postal_code: '75092'
  },
  '75093': {
    country: 'US',
    city: 'Plano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0299,
    longitude: -96.7889,
    postal_code: '75093'
  },
  '75094': {
    country: 'US',
    city: 'Plano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0148,
    longitude: -96.6189,
    postal_code: '75094'
  },
  '75097': {
    country: 'US',
    city: 'Weston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.3512,
    longitude: -96.6646,
    postal_code: '75097'
  },
  '75098': {
    country: 'US',
    city: 'Wylie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 33.0041,
    longitude: -96.5394,
    postal_code: '75098'
  },
  '75099': {
    country: 'US',
    city: 'Coppell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9546,
    longitude: -97.015,
    postal_code: '75099'
  },
  '75101': {
    country: 'US',
    city: 'Bardwell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.2731,
    longitude: -96.703,
    postal_code: '75101'
  },
  '75102': {
    country: 'US',
    city: 'Barry',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 32.1014,
    longitude: -96.6251,
    postal_code: '75102'
  },
  '75103': {
    country: 'US',
    city: 'Canton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Van Zandt',
    latitude: 32.5143,
    longitude: -95.9047,
    postal_code: '75103'
  },
  '75104': {
    country: 'US',
    city: 'Cedar Hill',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.5885,
    longitude: -96.9438,
    postal_code: '75104'
  },
  '75105': {
    country: 'US',
    city: 'Chatfield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 32.2954,
    longitude: -96.3887,
    postal_code: '75105'
  },
  '75106': {
    country: 'US',
    city: 'Cedar Hill',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.5885,
    longitude: -96.9561,
    postal_code: '75106'
  },
  '75109': {
    country: 'US',
    city: 'Corsicana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 31.9937,
    longitude: -96.4199,
    postal_code: '75109'
  },
  '75110': {
    country: 'US',
    city: 'Corsicana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 32.0868,
    longitude: -96.4762,
    postal_code: '75110'
  },
  '75114': {
    country: 'US',
    city: 'Crandall',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kaufman',
    latitude: 32.6279,
    longitude: -96.4558,
    postal_code: '75114'
  },
  '75115': {
    country: 'US',
    city: 'Desoto',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.6041,
    longitude: -96.8653,
    postal_code: '75115'
  },
  '75116': {
    country: 'US',
    city: 'Duncanville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.6587,
    longitude: -96.9114,
    postal_code: '75116'
  },
  '75117': {
    country: 'US',
    city: 'Edgewood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Van Zandt',
    latitude: 32.7003,
    longitude: -95.878,
    postal_code: '75117'
  },
  '75118': {
    country: 'US',
    city: 'Elmo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kaufman',
    latitude: 32.5996,
    longitude: -96.3027,
    postal_code: '75118'
  },
  '75119': {
    country: 'US',
    city: 'Ennis',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.3321,
    longitude: -96.6224,
    postal_code: '75119'
  },
  '75120': {
    country: 'US',
    city: 'Ennis',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.3347,
    longitude: -96.6335,
    postal_code: '75120'
  },
  '75121': {
    country: 'US',
    city: 'Copeville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0875,
    longitude: -96.4186,
    postal_code: '75121'
  },
  '75123': {
    country: 'US',
    city: 'Desoto',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.5899,
    longitude: -96.8569,
    postal_code: '75123'
  },
  '75124': {
    country: 'US',
    city: 'Eustace',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.2965,
    longitude: -96.0137,
    postal_code: '75124'
  },
  '75125': {
    country: 'US',
    city: 'Ferris',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.5223,
    longitude: -96.6643,
    postal_code: '75125'
  },
  '75126': {
    country: 'US',
    city: 'Forney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kaufman',
    latitude: 32.7491,
    longitude: -96.4598,
    postal_code: '75126'
  },
  '75127': {
    country: 'US',
    city: 'Fruitvale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Van Zandt',
    latitude: 32.677,
    longitude: -95.7899,
    postal_code: '75127'
  },
  '75132': {
    country: 'US',
    city: 'Fate',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rockwall',
    latitude: 32.9415,
    longitude: -96.3814,
    postal_code: '75132'
  },
  '75134': {
    country: 'US',
    city: 'Lancaster',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.6161,
    longitude: -96.783,
    postal_code: '75134'
  },
  '75135': {
    country: 'US',
    city: 'Caddo Mills',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.0683,
    longitude: -96.2391,
    postal_code: '75135'
  },
  '75137': {
    country: 'US',
    city: 'Duncanville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.6347,
    longitude: -96.9113,
    postal_code: '75137'
  },
  '75138': {
    country: 'US',
    city: 'Duncanville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75138'
  },
  '75140': {
    country: 'US',
    city: 'Grand Saline',
    state: 'Texas',
    state_short: 'TX',
    county: 'Van Zandt',
    latitude: 32.6635,
    longitude: -95.7064,
    postal_code: '75140'
  },
  '75141': {
    country: 'US',
    city: 'Hutchins',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.6396,
    longitude: -96.707,
    postal_code: '75141'
  },
  '75142': {
    country: 'US',
    city: 'Kaufman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kaufman',
    latitude: 32.546,
    longitude: -96.2852,
    postal_code: '75142'
  },
  '75143': {
    country: 'US',
    city: 'Kemp',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.2486,
    longitude: -96.2161,
    postal_code: '75143'
  },
  '75144': {
    country: 'US',
    city: 'Kerens',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 32.1275,
    longitude: -96.2298,
    postal_code: '75144'
  },
  '75146': {
    country: 'US',
    city: 'Lancaster',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.5914,
    longitude: -96.7728,
    postal_code: '75146'
  },
  '75147': {
    country: 'US',
    city: 'Mabank',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kaufman',
    latitude: 32.3685,
    longitude: -96.0685,
    postal_code: '75147'
  },
  '75148': {
    country: 'US',
    city: 'Malakoff',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.1705,
    longitude: -96.006,
    postal_code: '75148'
  },
  '75149': {
    country: 'US',
    city: 'Mesquite',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7678,
    longitude: -96.6082,
    postal_code: '75149'
  },
  '75150': {
    country: 'US',
    city: 'Mesquite',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8154,
    longitude: -96.6307,
    postal_code: '75150'
  },
  '75151': {
    country: 'US',
    city: 'Corsicana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 32.0624,
    longitude: -96.4735,
    postal_code: '75151'
  },
  '75152': {
    country: 'US',
    city: 'Palmer',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.4387,
    longitude: -96.6794,
    postal_code: '75152'
  },
  '75153': {
    country: 'US',
    city: 'Powell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 32.1196,
    longitude: -96.3327,
    postal_code: '75153'
  },
  '75154': {
    country: 'US',
    city: 'Red Oak',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.5185,
    longitude: -96.8071,
    postal_code: '75154'
  },
  '75155': {
    country: 'US',
    city: 'Rice',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.2258,
    longitude: -96.4606,
    postal_code: '75155'
  },
  '75156': {
    country: 'US',
    city: 'Mabank',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kaufman',
    latitude: 32.2972,
    longitude: -96.1184,
    postal_code: '75156'
  },
  '75157': {
    country: 'US',
    city: 'Rosser',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kaufman',
    latitude: 32.4626,
    longitude: -96.4533,
    postal_code: '75157'
  },
  '75158': {
    country: 'US',
    city: 'Scurry',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kaufman',
    latitude: 32.4818,
    longitude: -96.3925,
    postal_code: '75158'
  },
  '75159': {
    country: 'US',
    city: 'Seagoville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.6525,
    longitude: -96.558,
    postal_code: '75159'
  },
  '75160': {
    country: 'US',
    city: 'Terrell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kaufman',
    latitude: 32.7515,
    longitude: -96.2831,
    postal_code: '75160'
  },
  '75161': {
    country: 'US',
    city: 'Terrell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kaufman',
    latitude: 32.7332,
    longitude: -96.195,
    postal_code: '75161'
  },
  '75163': {
    country: 'US',
    city: 'Trinidad',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.1383,
    longitude: -96.0831,
    postal_code: '75163'
  },
  '75164': {
    country: 'US',
    city: 'Josephine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0612,
    longitude: -96.3072,
    postal_code: '75164'
  },
  '75165': {
    country: 'US',
    city: 'Waxahachie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.3808,
    longitude: -96.8374,
    postal_code: '75165'
  },
  '75166': {
    country: 'US',
    city: 'Lavon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0139,
    longitude: -96.4377,
    postal_code: '75166'
  },
  '75167': {
    country: 'US',
    city: 'Waxahachie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.3773,
    longitude: -96.9162,
    postal_code: '75167'
  },
  '75168': {
    country: 'US',
    city: 'Waxahachie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.3749,
    longitude: -96.7166,
    postal_code: '75168'
  },
  '75169': {
    country: 'US',
    city: 'Wills Point',
    state: 'Texas',
    state_short: 'TX',
    county: 'Van Zandt',
    latitude: 32.7283,
    longitude: -96.0079,
    postal_code: '75169'
  },
  '75172': {
    country: 'US',
    city: 'Wilmer',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.5981,
    longitude: -96.6838,
    postal_code: '75172'
  },
  '75173': {
    country: 'US',
    city: 'Nevada',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.0593,
    longitude: -96.3877,
    postal_code: '75173'
  },
  '75180': {
    country: 'US',
    city: 'Balch Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7287,
    longitude: -96.6228,
    postal_code: '75180'
  },
  '75181': {
    country: 'US',
    city: 'Mesquite',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7272,
    longitude: -96.5669,
    postal_code: '75181'
  },
  '75182': {
    country: 'US',
    city: 'Sunnyvale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.797,
    longitude: -96.5616,
    postal_code: '75182'
  },
  '75185': {
    country: 'US',
    city: 'Mesquite',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7403,
    longitude: -96.5618,
    postal_code: '75185'
  },
  '75187': {
    country: 'US',
    city: 'Mesquite',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75187'
  },
  '75189': {
    country: 'US',
    city: 'Royse City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rockwall',
    latitude: 32.9628,
    longitude: -96.3648,
    postal_code: '75189'
  },
  '75201': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7904,
    longitude: -96.8044,
    postal_code: '75201'
  },
  '75202': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7781,
    longitude: -96.8054,
    postal_code: '75202'
  },
  '75203': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.746,
    longitude: -96.807,
    postal_code: '75203'
  },
  '75204': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8038,
    longitude: -96.7851,
    postal_code: '75204'
  },
  '75205': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.826,
    longitude: -96.7843,
    postal_code: '75205'
  },
  '75206': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.831,
    longitude: -96.7692,
    postal_code: '75206'
  },
  '75207': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7939,
    longitude: -96.8319,
    postal_code: '75207'
  },
  '75208': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7492,
    longitude: -96.8389,
    postal_code: '75208'
  },
  '75209': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8456,
    longitude: -96.826,
    postal_code: '75209'
  },
  '75210': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7699,
    longitude: -96.743,
    postal_code: '75210'
  },
  '75211': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7319,
    longitude: -96.9057,
    postal_code: '75211'
  },
  '75212': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7829,
    longitude: -96.8714,
    postal_code: '75212'
  },
  '75214': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8248,
    longitude: -96.7498,
    postal_code: '75214'
  },
  '75215': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7582,
    longitude: -96.7623,
    postal_code: '75215'
  },
  '75216': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7086,
    longitude: -96.7955,
    postal_code: '75216'
  },
  '75217': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7244,
    longitude: -96.6755,
    postal_code: '75217'
  },
  '75218': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8463,
    longitude: -96.6972,
    postal_code: '75218'
  },
  '75219': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8132,
    longitude: -96.8142,
    postal_code: '75219'
  },
  '75220': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8681,
    longitude: -96.8622,
    postal_code: '75220'
  },
  '75221': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8147,
    longitude: -96.7877,
    postal_code: '75221'
  },
  '75222': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75222'
  },
  '75223': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7942,
    longitude: -96.7475,
    postal_code: '75223'
  },
  '75224': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7114,
    longitude: -96.8387,
    postal_code: '75224'
  },
  '75225': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8628,
    longitude: -96.7918,
    postal_code: '75225'
  },
  '75226': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7887,
    longitude: -96.7676,
    postal_code: '75226'
  },
  '75227': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7672,
    longitude: -96.6836,
    postal_code: '75227'
  },
  '75228': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.825,
    longitude: -96.6784,
    postal_code: '75228'
  },
  '75229': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8958,
    longitude: -96.8588,
    postal_code: '75229'
  },
  '75230': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8999,
    longitude: -96.7897,
    postal_code: '75230'
  },
  '75231': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8756,
    longitude: -96.7495,
    postal_code: '75231'
  },
  '75232': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.6647,
    longitude: -96.8384,
    postal_code: '75232'
  },
  '75233': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7046,
    longitude: -96.8725,
    postal_code: '75233'
  },
  '75234': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9245,
    longitude: -96.8938,
    postal_code: '75234'
  },
  '75235': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8252,
    longitude: -96.8388,
    postal_code: '75235'
  },
  '75236': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.69,
    longitude: -96.9177,
    postal_code: '75236'
  },
  '75237': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.659,
    longitude: -96.8765,
    postal_code: '75237'
  },
  '75238': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.877,
    longitude: -96.708,
    postal_code: '75238'
  },
  '75240': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9374,
    longitude: -96.7872,
    postal_code: '75240'
  },
  '75241': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.6722,
    longitude: -96.7774,
    postal_code: '75241'
  },
  '75242': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75242'
  },
  '75243': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9103,
    longitude: -96.7285,
    postal_code: '75243'
  },
  '75244': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9322,
    longitude: -96.8353,
    postal_code: '75244'
  },
  '75246': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7948,
    longitude: -96.7697,
    postal_code: '75246'
  },
  '75247': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.8152,
    longitude: -96.8703,
    postal_code: '75247'
  },
  '75248': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9682,
    longitude: -96.7942,
    postal_code: '75248'
  },
  '75249': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.636,
    longitude: -96.9493,
    postal_code: '75249'
  },
  '75250': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75250'
  },
  '75251': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9189,
    longitude: -96.7751,
    postal_code: '75251'
  },
  '75252': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9968,
    longitude: -96.7921,
    postal_code: '75252'
  },
  '75253': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.6833,
    longitude: -96.5964,
    postal_code: '75253'
  },
  '75254': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.9503,
    longitude: -96.819,
    postal_code: '75254'
  },
  '75260': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75260'
  },
  '75261': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75261'
  },
  '75262': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75262'
  },
  '75263': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75263'
  },
  '75264': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75264'
  },
  '75265': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75265'
  },
  '75266': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75266'
  },
  '75267': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75267'
  },
  '75270': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7813,
    longitude: -96.8019,
    postal_code: '75270'
  },
  '75275': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75275'
  },
  '75277': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75277'
  },
  '75283': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75283'
  },
  '75284': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75284'
  },
  '75285': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75285'
  },
  '75287': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 33.0005,
    longitude: -96.8314,
    postal_code: '75287'
  },
  '75301': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75301'
  },
  '75303': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75303'
  },
  '75312': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75312'
  },
  '75313': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75313'
  },
  '75315': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75315'
  },
  '75320': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75320'
  },
  '75326': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75326'
  },
  '75336': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75336'
  },
  '75339': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75339'
  },
  '75342': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75342'
  },
  '75354': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75354'
  },
  '75355': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75355'
  },
  '75356': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75356'
  },
  '75357': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75357'
  },
  '75358': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7942,
    longitude: -96.7652,
    postal_code: '75358'
  },
  '75359': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75359'
  },
  '75360': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75360'
  },
  '75367': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75367'
  },
  '75368': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75368'
  },
  '75370': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75370'
  },
  '75371': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75371'
  },
  '75372': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75372'
  },
  '75373': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75373'
  },
  '75374': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75374'
  },
  '75376': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75376'
  },
  '75378': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75378'
  },
  '75379': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75379'
  },
  '75380': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75380'
  },
  '75381': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75381'
  },
  '75382': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75382'
  },
  '75389': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75389'
  },
  '75390': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75390'
  },
  '75391': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75391'
  },
  '75392': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75392'
  },
  '75393': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75393'
  },
  '75394': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75394'
  },
  '75395': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75395'
  },
  '75397': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75397'
  },
  '75398': {
    country: 'US',
    city: 'Dallas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallas',
    latitude: 32.7673,
    longitude: -96.7776,
    postal_code: '75398'
  },
  '75401': {
    country: 'US',
    city: 'Greenville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.1854,
    longitude: -96.1304,
    postal_code: '75401'
  },
  '75402': {
    country: 'US',
    city: 'Greenville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.1047,
    longitude: -96.0927,
    postal_code: '75402'
  },
  '75403': {
    country: 'US',
    city: 'Greenville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.2185,
    longitude: -96.0487,
    postal_code: '75403'
  },
  '75404': {
    country: 'US',
    city: 'Greenville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.0563,
    longitude: -96.081,
    postal_code: '75404'
  },
  '75407': {
    country: 'US',
    city: 'Princeton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.1555,
    longitude: -96.4981,
    postal_code: '75407'
  },
  '75409': {
    country: 'US',
    city: 'Anna',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.3445,
    longitude: -96.5639,
    postal_code: '75409'
  },
  '75410': {
    country: 'US',
    city: 'Alba',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rains',
    latitude: 32.7652,
    longitude: -95.5971,
    postal_code: '75410'
  },
  '75411': {
    country: 'US',
    city: 'Arthur City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.8643,
    longitude: -95.6111,
    postal_code: '75411'
  },
  '75412': {
    country: 'US',
    city: 'Bagwell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Red River',
    latitude: 33.8361,
    longitude: -95.1487,
    postal_code: '75412'
  },
  '75413': {
    country: 'US',
    city: 'Bailey',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.43,
    longitude: -96.1662,
    postal_code: '75413'
  },
  '75414': {
    country: 'US',
    city: 'Bells',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.6178,
    longitude: -96.4237,
    postal_code: '75414'
  },
  '75415': {
    country: 'US',
    city: 'Ben Franklin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Delta',
    latitude: 33.4768,
    longitude: -95.7689,
    postal_code: '75415'
  },
  '75416': {
    country: 'US',
    city: 'Blossom',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.6945,
    longitude: -95.3823,
    postal_code: '75416'
  },
  '75417': {
    country: 'US',
    city: 'Bogata',
    state: 'Texas',
    state_short: 'TX',
    county: 'Red River',
    latitude: 33.4699,
    longitude: -95.1937,
    postal_code: '75417'
  },
  '75418': {
    country: 'US',
    city: 'Bonham',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.5806,
    longitude: -96.1836,
    postal_code: '75418'
  },
  '75420': {
    country: 'US',
    city: 'Brashear',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hopkins',
    latitude: 33.1155,
    longitude: -95.7345,
    postal_code: '75420'
  },
  '75421': {
    country: 'US',
    city: 'Brookston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.6246,
    longitude: -95.6888,
    postal_code: '75421'
  },
  '75422': {
    country: 'US',
    city: 'Campbell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.151,
    longitude: -95.9439,
    postal_code: '75422'
  },
  '75423': {
    country: 'US',
    city: 'Celeste',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.2649,
    longitude: -96.2076,
    postal_code: '75423'
  },
  '75424': {
    country: 'US',
    city: 'Blue Ridge',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.3061,
    longitude: -96.3901,
    postal_code: '75424'
  },
  '75425': {
    country: 'US',
    city: 'Chicota',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.869,
    longitude: -95.5711,
    postal_code: '75425'
  },
  '75426': {
    country: 'US',
    city: 'Clarksville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Red River',
    latitude: 33.6236,
    longitude: -95.0461,
    postal_code: '75426'
  },
  '75428': {
    country: 'US',
    city: 'Commerce',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.2493,
    longitude: -95.9097,
    postal_code: '75428'
  },
  '75429': {
    country: 'US',
    city: 'Commerce',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.2377,
    longitude: -95.9089,
    postal_code: '75429'
  },
  '75431': {
    country: 'US',
    city: 'Como',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hopkins',
    latitude: 33.0107,
    longitude: -95.4734,
    postal_code: '75431'
  },
  '75432': {
    country: 'US',
    city: 'Cooper',
    state: 'Texas',
    state_short: 'TX',
    county: 'Delta',
    latitude: 33.3812,
    longitude: -95.6623,
    postal_code: '75432'
  },
  '75433': {
    country: 'US',
    city: 'Cumby',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hopkins',
    latitude: 33.1118,
    longitude: -95.7945,
    postal_code: '75433'
  },
  '75434': {
    country: 'US',
    city: 'Cunningham',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.409,
    longitude: -95.3711,
    postal_code: '75434'
  },
  '75435': {
    country: 'US',
    city: 'Deport',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.5221,
    longitude: -95.3654,
    postal_code: '75435'
  },
  '75436': {
    country: 'US',
    city: 'Detroit',
    state: 'Texas',
    state_short: 'TX',
    county: 'Red River',
    latitude: 33.6627,
    longitude: -95.2385,
    postal_code: '75436'
  },
  '75437': {
    country: 'US',
    city: 'Dike',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hopkins',
    latitude: 33.2648,
    longitude: -95.4423,
    postal_code: '75437'
  },
  '75438': {
    country: 'US',
    city: 'Dodd City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.5647,
    longitude: -96.0619,
    postal_code: '75438'
  },
  '75439': {
    country: 'US',
    city: 'Ector',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.5819,
    longitude: -96.2735,
    postal_code: '75439'
  },
  '75440': {
    country: 'US',
    city: 'Emory',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rains',
    latitude: 32.875,
    longitude: -95.7418,
    postal_code: '75440'
  },
  '75441': {
    country: 'US',
    city: 'Enloe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Delta',
    latitude: 33.4291,
    longitude: -95.6518,
    postal_code: '75441'
  },
  '75442': {
    country: 'US',
    city: 'Farmersville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.1659,
    longitude: -96.3686,
    postal_code: '75442'
  },
  '75443': {
    country: 'US',
    city: 'Gober',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.4697,
    longitude: -96.1111,
    postal_code: '75443'
  },
  '75444': {
    country: 'US',
    city: 'Golden',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wood',
    latitude: 32.7299,
    longitude: -95.5636,
    postal_code: '75444'
  },
  '75446': {
    country: 'US',
    city: 'Honey Grove',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.5985,
    longitude: -95.9109,
    postal_code: '75446'
  },
  '75447': {
    country: 'US',
    city: 'Ivanhoe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.7612,
    longitude: -96.1193,
    postal_code: '75447'
  },
  '75448': {
    country: 'US',
    city: 'Klondike',
    state: 'Texas',
    state_short: 'TX',
    county: 'Delta',
    latitude: 33.3034,
    longitude: -95.8018,
    postal_code: '75448'
  },
  '75449': {
    country: 'US',
    city: 'Ladonia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.4245,
    longitude: -95.9455,
    postal_code: '75449'
  },
  '75450': {
    country: 'US',
    city: 'Lake Creek',
    state: 'Texas',
    state_short: 'TX',
    county: 'Delta',
    latitude: 33.4116,
    longitude: -95.4551,
    postal_code: '75450'
  },
  '75451': {
    country: 'US',
    city: 'Leesburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Camp',
    latitude: 32.9763,
    longitude: -95.1079,
    postal_code: '75451'
  },
  '75452': {
    country: 'US',
    city: 'Leonard',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.3796,
    longitude: -96.2475,
    postal_code: '75452'
  },
  '75453': {
    country: 'US',
    city: 'Lone Oak',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 32.9916,
    longitude: -95.9434,
    postal_code: '75453'
  },
  '75454': {
    country: 'US',
    city: 'Melissa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.2841,
    longitude: -96.574,
    postal_code: '75454'
  },
  '75455': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Texas',
    state_short: 'TX',
    county: 'Titus',
    latitude: 33.1733,
    longitude: -94.9695,
    postal_code: '75455'
  },
  '75456': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Texas',
    state_short: 'TX',
    county: 'Titus',
    latitude: 33.1568,
    longitude: -94.9683,
    postal_code: '75456'
  },
  '75457': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Franklin',
    latitude: 33.1702,
    longitude: -95.2181,
    postal_code: '75457'
  },
  '75458': {
    country: 'US',
    city: 'Merit',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.2427,
    longitude: -96.2916,
    postal_code: '75458'
  },
  '75459': {
    country: 'US',
    city: 'Howe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.5311,
    longitude: -96.6777,
    postal_code: '75459'
  },
  '75460': {
    country: 'US',
    city: 'Paris',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.6581,
    longitude: -95.5379,
    postal_code: '75460'
  },
  '75461': {
    country: 'US',
    city: 'Paris',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.6609,
    longitude: -95.5555,
    postal_code: '75461'
  },
  '75462': {
    country: 'US',
    city: 'Paris',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.6805,
    longitude: -95.4905,
    postal_code: '75462'
  },
  '75468': {
    country: 'US',
    city: 'Pattonville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.5702,
    longitude: -95.3908,
    postal_code: '75468'
  },
  '75469': {
    country: 'US',
    city: 'Pecan Gap',
    state: 'Texas',
    state_short: 'TX',
    county: 'Delta',
    latitude: 33.4196,
    longitude: -95.8262,
    postal_code: '75469'
  },
  '75470': {
    country: 'US',
    city: 'Petty',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.6098,
    longitude: -95.7891,
    postal_code: '75470'
  },
  '75471': {
    country: 'US',
    city: 'Pickton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hopkins',
    latitude: 33.0519,
    longitude: -95.3851,
    postal_code: '75471'
  },
  '75472': {
    country: 'US',
    city: 'Point',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rains',
    latitude: 32.9007,
    longitude: -95.8903,
    postal_code: '75472'
  },
  '75473': {
    country: 'US',
    city: 'Powderly',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.7779,
    longitude: -95.5307,
    postal_code: '75473'
  },
  '75474': {
    country: 'US',
    city: 'Quinlan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 32.8983,
    longitude: -96.1261,
    postal_code: '75474'
  },
  '75475': {
    country: 'US',
    city: 'Randolph',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.4846,
    longitude: -96.2541,
    postal_code: '75475'
  },
  '75476': {
    country: 'US',
    city: 'Ravenna',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.7098,
    longitude: -96.1452,
    postal_code: '75476'
  },
  '75477': {
    country: 'US',
    city: 'Roxton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.5429,
    longitude: -95.7416,
    postal_code: '75477'
  },
  '75478': {
    country: 'US',
    city: 'Saltillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hopkins',
    latitude: 33.1767,
    longitude: -95.3433,
    postal_code: '75478'
  },
  '75479': {
    country: 'US',
    city: 'Savoy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.6066,
    longitude: -96.3502,
    postal_code: '75479'
  },
  '75480': {
    country: 'US',
    city: 'Scroggins',
    state: 'Texas',
    state_short: 'TX',
    county: 'Franklin',
    latitude: 33.0478,
    longitude: -95.1962,
    postal_code: '75480'
  },
  '75481': {
    country: 'US',
    city: 'Sulphur Bluff',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hopkins',
    latitude: 33.3334,
    longitude: -95.374,
    postal_code: '75481'
  },
  '75482': {
    country: 'US',
    city: 'Sulphur Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hopkins',
    latitude: 33.1345,
    longitude: -95.5922,
    postal_code: '75482'
  },
  '75483': {
    country: 'US',
    city: 'Sulphur Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hopkins',
    latitude: 33.1384,
    longitude: -95.6011,
    postal_code: '75483'
  },
  '75485': {
    country: 'US',
    city: 'Westminster',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collin',
    latitude: 33.3627,
    longitude: -96.4635,
    postal_code: '75485'
  },
  '75486': {
    country: 'US',
    city: 'Sumner',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamar',
    latitude: 33.7589,
    longitude: -95.6807,
    postal_code: '75486'
  },
  '75487': {
    country: 'US',
    city: 'Talco',
    state: 'Texas',
    state_short: 'TX',
    county: 'Franklin',
    latitude: 33.3344,
    longitude: -95.0497,
    postal_code: '75487'
  },
  '75488': {
    country: 'US',
    city: 'Telephone',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.7979,
    longitude: -96.0449,
    postal_code: '75488'
  },
  '75489': {
    country: 'US',
    city: 'Tom Bean',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.5193,
    longitude: -96.4843,
    postal_code: '75489'
  },
  '75490': {
    country: 'US',
    city: 'Trenton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.4235,
    longitude: -96.3398,
    postal_code: '75490'
  },
  '75491': {
    country: 'US',
    city: 'Whitewright',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.519,
    longitude: -96.451,
    postal_code: '75491'
  },
  '75492': {
    country: 'US',
    city: 'Windom',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fannin',
    latitude: 33.5633,
    longitude: -96.002,
    postal_code: '75492'
  },
  '75493': {
    country: 'US',
    city: 'Winfield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Titus',
    latitude: 33.1673,
    longitude: -95.1119,
    postal_code: '75493'
  },
  '75494': {
    country: 'US',
    city: 'Winnsboro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wood',
    latitude: 32.9146,
    longitude: -95.2726,
    postal_code: '75494'
  },
  '75495': {
    country: 'US',
    city: 'Van Alstyne',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.4292,
    longitude: -96.5486,
    postal_code: '75495'
  },
  '75496': {
    country: 'US',
    city: 'Wolfe City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hunt',
    latitude: 33.3605,
    longitude: -96.0691,
    postal_code: '75496'
  },
  '75497': {
    country: 'US',
    city: 'Yantis',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wood',
    latitude: 32.9257,
    longitude: -95.5311,
    postal_code: '75497'
  },
  '75501': {
    country: 'US',
    city: 'Texarkana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.4113,
    longitude: -94.1774,
    postal_code: '75501'
  },
  '75503': {
    country: 'US',
    city: 'Texarkana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.4669,
    longitude: -94.0774,
    postal_code: '75503'
  },
  '75504': {
    country: 'US',
    city: 'Texarkana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.4251,
    longitude: -94.0477,
    postal_code: '75504'
  },
  '75505': {
    country: 'US',
    city: 'Texarkana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.4624,
    longitude: -94.0715,
    postal_code: '75505'
  },
  '75507': {
    country: 'US',
    city: 'Texarkana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.4251,
    longitude: -94.0477,
    postal_code: '75507'
  },
  '75550': {
    country: 'US',
    city: 'Annona',
    state: 'Texas',
    state_short: 'TX',
    county: 'Red River',
    latitude: 33.5535,
    longitude: -94.8992,
    postal_code: '75550'
  },
  '75551': {
    country: 'US',
    city: 'Atlanta',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 33.109,
    longitude: -94.1646,
    postal_code: '75551'
  },
  '75554': {
    country: 'US',
    city: 'Avery',
    state: 'Texas',
    state_short: 'TX',
    county: 'Red River',
    latitude: 33.5339,
    longitude: -94.7867,
    postal_code: '75554'
  },
  '75555': {
    country: 'US',
    city: 'Bivins',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 32.966,
    longitude: -94.1404,
    postal_code: '75555'
  },
  '75556': {
    country: 'US',
    city: 'Bloomburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 33.1339,
    longitude: -94.0647,
    postal_code: '75556'
  },
  '75558': {
    country: 'US',
    city: 'Cookville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Titus',
    latitude: 33.2432,
    longitude: -94.8747,
    postal_code: '75558'
  },
  '75559': {
    country: 'US',
    city: 'De Kalb',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.5087,
    longitude: -94.6163,
    postal_code: '75559'
  },
  '75560': {
    country: 'US',
    city: 'Douglassville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 33.1758,
    longitude: -94.3467,
    postal_code: '75560'
  },
  '75561': {
    country: 'US',
    city: 'Hooks',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.5253,
    longitude: -94.2657,
    postal_code: '75561'
  },
  '75562': {
    country: 'US',
    city: 'Kildare',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 32.9468,
    longitude: -94.2539,
    postal_code: '75562'
  },
  '75563': {
    country: 'US',
    city: 'Linden',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 33.0048,
    longitude: -94.3605,
    postal_code: '75563'
  },
  '75564': {
    country: 'US',
    city: 'Lodi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Marion',
    latitude: 32.8718,
    longitude: -94.2729,
    postal_code: '75564'
  },
  '75565': {
    country: 'US',
    city: 'Mc Leod',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 32.9511,
    longitude: -94.0808,
    postal_code: '75565'
  },
  '75566': {
    country: 'US',
    city: 'Marietta',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 33.1735,
    longitude: -94.5424,
    postal_code: '75566'
  },
  '75567': {
    country: 'US',
    city: 'Maud',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.3409,
    longitude: -94.3175,
    postal_code: '75567'
  },
  '75568': {
    country: 'US',
    city: 'Naples',
    state: 'Texas',
    state_short: 'TX',
    county: 'Morris',
    latitude: 33.1912,
    longitude: -94.6891,
    postal_code: '75568'
  },
  '75569': {
    country: 'US',
    city: 'Nash',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.4344,
    longitude: -94.1219,
    postal_code: '75569'
  },
  '75570': {
    country: 'US',
    city: 'New Boston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.4619,
    longitude: -94.4375,
    postal_code: '75570'
  },
  '75571': {
    country: 'US',
    city: 'Omaha',
    state: 'Texas',
    state_short: 'TX',
    county: 'Morris',
    latitude: 33.1808,
    longitude: -94.7639,
    postal_code: '75571'
  },
  '75572': {
    country: 'US',
    city: 'Queen City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 33.1557,
    longitude: -94.1537,
    postal_code: '75572'
  },
  '75573': {
    country: 'US',
    city: 'Redwater',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.358,
    longitude: -94.2571,
    postal_code: '75573'
  },
  '75574': {
    country: 'US',
    city: 'Simms',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.3359,
    longitude: -94.552,
    postal_code: '75574'
  },
  '75599': {
    country: 'US',
    city: 'Texarkana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bowie',
    latitude: 33.4251,
    longitude: -94.0477,
    postal_code: '75599'
  },
  '75601': {
    country: 'US',
    city: 'Longview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.5178,
    longitude: -94.7303,
    postal_code: '75601'
  },
  '75602': {
    country: 'US',
    city: 'Longview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.4724,
    longitude: -94.7101,
    postal_code: '75602'
  },
  '75603': {
    country: 'US',
    city: 'Longview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.4264,
    longitude: -94.7117,
    postal_code: '75603'
  },
  '75604': {
    country: 'US',
    city: 'Longview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.5251,
    longitude: -94.799,
    postal_code: '75604'
  },
  '75605': {
    country: 'US',
    city: 'Longview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.5547,
    longitude: -94.7767,
    postal_code: '75605'
  },
  '75606': {
    country: 'US',
    city: 'Longview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.5007,
    longitude: -94.7405,
    postal_code: '75606'
  },
  '75607': {
    country: 'US',
    city: 'Longview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.5112,
    longitude: -94.7835,
    postal_code: '75607'
  },
  '75608': {
    country: 'US',
    city: 'Longview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.5701,
    longitude: -94.8481,
    postal_code: '75608'
  },
  '75615': {
    country: 'US',
    city: 'Longview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.5112,
    longitude: -94.7835,
    postal_code: '75615'
  },
  '75630': {
    country: 'US',
    city: 'Avinger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 32.8485,
    longitude: -94.5795,
    postal_code: '75630'
  },
  '75631': {
    country: 'US',
    city: 'Beckville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Panola',
    latitude: 32.2452,
    longitude: -94.4555,
    postal_code: '75631'
  },
  '75633': {
    country: 'US',
    city: 'Carthage',
    state: 'Texas',
    state_short: 'TX',
    county: 'Panola',
    latitude: 32.1544,
    longitude: -94.3527,
    postal_code: '75633'
  },
  '75636': {
    country: 'US',
    city: 'Cason',
    state: 'Texas',
    state_short: 'TX',
    county: 'Morris',
    latitude: 33.024,
    longitude: -94.8249,
    postal_code: '75636'
  },
  '75637': {
    country: 'US',
    city: 'Clayton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Panola',
    latitude: 32.1027,
    longitude: -94.4935,
    postal_code: '75637'
  },
  '75638': {
    country: 'US',
    city: 'Daingerfield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Morris',
    latitude: 33.0313,
    longitude: -94.7359,
    postal_code: '75638'
  },
  '75639': {
    country: 'US',
    city: 'De Berry',
    state: 'Texas',
    state_short: 'TX',
    county: 'Panola',
    latitude: 32.2543,
    longitude: -94.1356,
    postal_code: '75639'
  },
  '75640': {
    country: 'US',
    city: 'Diana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Upshur',
    latitude: 32.7698,
    longitude: -94.7032,
    postal_code: '75640'
  },
  '75641': {
    country: 'US',
    city: 'Easton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.3874,
    longitude: -94.583,
    postal_code: '75641'
  },
  '75642': {
    country: 'US',
    city: 'Elysian Fields',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.3685,
    longitude: -94.183,
    postal_code: '75642'
  },
  '75643': {
    country: 'US',
    city: 'Gary',
    state: 'Texas',
    state_short: 'TX',
    county: 'Panola',
    latitude: 32.0334,
    longitude: -94.38,
    postal_code: '75643'
  },
  '75644': {
    country: 'US',
    city: 'Gilmer',
    state: 'Texas',
    state_short: 'TX',
    county: 'Upshur',
    latitude: 32.7246,
    longitude: -94.9714,
    postal_code: '75644'
  },
  '75645': {
    country: 'US',
    city: 'Gilmer',
    state: 'Texas',
    state_short: 'TX',
    county: 'Upshur',
    latitude: 32.6044,
    longitude: -94.8491,
    postal_code: '75645'
  },
  '75647': {
    country: 'US',
    city: 'Gladewater',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.5559,
    longitude: -94.932,
    postal_code: '75647'
  },
  '75650': {
    country: 'US',
    city: 'Hallsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.5073,
    longitude: -94.5333,
    postal_code: '75650'
  },
  '75651': {
    country: 'US',
    city: 'Harleton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.6579,
    longitude: -94.4652,
    postal_code: '75651'
  },
  '75652': {
    country: 'US',
    city: 'Henderson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.2131,
    longitude: -94.7834,
    postal_code: '75652'
  },
  '75653': {
    country: 'US',
    city: 'Henderson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.1532,
    longitude: -94.7994,
    postal_code: '75653'
  },
  '75654': {
    country: 'US',
    city: 'Henderson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.126,
    longitude: -94.7488,
    postal_code: '75654'
  },
  '75656': {
    country: 'US',
    city: 'Hughes Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cass',
    latitude: 33.0168,
    longitude: -94.6228,
    postal_code: '75656'
  },
  '75657': {
    country: 'US',
    city: 'Jefferson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Marion',
    latitude: 32.8059,
    longitude: -94.3655,
    postal_code: '75657'
  },
  '75658': {
    country: 'US',
    city: 'Joinerville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.1959,
    longitude: -94.9065,
    postal_code: '75658'
  },
  '75659': {
    country: 'US',
    city: 'Jonesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.5075,
    longitude: -94.1106,
    postal_code: '75659'
  },
  '75660': {
    country: 'US',
    city: 'Judson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.5826,
    longitude: -94.7535,
    postal_code: '75660'
  },
  '75661': {
    country: 'US',
    city: 'Karnack',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.6205,
    longitude: -94.2001,
    postal_code: '75661'
  },
  '75662': {
    country: 'US',
    city: 'Kilgore',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.3836,
    longitude: -94.8653,
    postal_code: '75662'
  },
  '75663': {
    country: 'US',
    city: 'Kilgore',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.387,
    longitude: -94.8951,
    postal_code: '75663'
  },
  '75666': {
    country: 'US',
    city: 'Laird Hill',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.3532,
    longitude: -94.9055,
    postal_code: '75666'
  },
  '75667': {
    country: 'US',
    city: 'Laneville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 31.9508,
    longitude: -94.866,
    postal_code: '75667'
  },
  '75668': {
    country: 'US',
    city: 'Lone Star',
    state: 'Texas',
    state_short: 'TX',
    county: 'Morris',
    latitude: 32.9252,
    longitude: -94.7049,
    postal_code: '75668'
  },
  '75669': {
    country: 'US',
    city: 'Long Branch',
    state: 'Texas',
    state_short: 'TX',
    county: 'Panola',
    latitude: 32.0449,
    longitude: -94.5381,
    postal_code: '75669'
  },
  '75670': {
    country: 'US',
    city: 'Marshall',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.5338,
    longitude: -94.3619,
    postal_code: '75670'
  },
  '75671': {
    country: 'US',
    city: 'Marshall',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.5449,
    longitude: -94.3674,
    postal_code: '75671'
  },
  '75672': {
    country: 'US',
    city: 'Marshall',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.5165,
    longitude: -94.3251,
    postal_code: '75672'
  },
  '75680': {
    country: 'US',
    city: 'Minden',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.0028,
    longitude: -94.7129,
    postal_code: '75680'
  },
  '75681': {
    country: 'US',
    city: 'Mount Enterprise',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 31.9125,
    longitude: -94.6235,
    postal_code: '75681'
  },
  '75682': {
    country: 'US',
    city: 'New London',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.2542,
    longitude: -94.9322,
    postal_code: '75682'
  },
  '75683': {
    country: 'US',
    city: 'Ore City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Upshur',
    latitude: 32.7856,
    longitude: -94.7514,
    postal_code: '75683'
  },
  '75684': {
    country: 'US',
    city: 'Overton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.269,
    longitude: -94.9529,
    postal_code: '75684'
  },
  '75685': {
    country: 'US',
    city: 'Panola',
    state: 'Texas',
    state_short: 'TX',
    county: 'Panola',
    latitude: 32.3579,
    longitude: -94.0913,
    postal_code: '75685'
  },
  '75686': {
    country: 'US',
    city: 'Pittsburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Camp',
    latitude: 32.9623,
    longitude: -94.9603,
    postal_code: '75686'
  },
  '75687': {
    country: 'US',
    city: 'Price',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.1518,
    longitude: -94.9554,
    postal_code: '75687'
  },
  '75688': {
    country: 'US',
    city: 'Scottsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.554,
    longitude: -94.2394,
    postal_code: '75688'
  },
  '75689': {
    country: 'US',
    city: 'Selman City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.1826,
    longitude: -94.9355,
    postal_code: '75689'
  },
  '75691': {
    country: 'US',
    city: 'Tatum',
    state: 'Texas',
    state_short: 'TX',
    county: 'Rusk',
    latitude: 32.3266,
    longitude: -94.596,
    postal_code: '75691'
  },
  '75692': {
    country: 'US',
    city: 'Waskom',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.5184,
    longitude: -94.1355,
    postal_code: '75692'
  },
  '75693': {
    country: 'US',
    city: 'White Oak',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gregg',
    latitude: 32.5383,
    longitude: -94.8622,
    postal_code: '75693'
  },
  '75694': {
    country: 'US',
    city: 'Woodlawn',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harrison',
    latitude: 32.6537,
    longitude: -94.3427,
    postal_code: '75694'
  },
  '75701': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.3254,
    longitude: -95.2922,
    postal_code: '75701'
  },
  '75702': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.362,
    longitude: -95.3117,
    postal_code: '75702'
  },
  '75703': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.2768,
    longitude: -95.3031,
    postal_code: '75703'
  },
  '75704': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.3738,
    longitude: -95.407,
    postal_code: '75704'
  },
  '75705': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.3766,
    longitude: -95.1252,
    postal_code: '75705'
  },
  '75706': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.4441,
    longitude: -95.331,
    postal_code: '75706'
  },
  '75707': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.3038,
    longitude: -95.1927,
    postal_code: '75707'
  },
  '75708': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.419,
    longitude: -95.2106,
    postal_code: '75708'
  },
  '75709': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.3078,
    longitude: -95.3956,
    postal_code: '75709'
  },
  '75710': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.3475,
    longitude: -95.3065,
    postal_code: '75710'
  },
  '75711': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.3513,
    longitude: -95.3011,
    postal_code: '75711'
  },
  '75712': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.4112,
    longitude: -95.2899,
    postal_code: '75712'
  },
  '75713': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.4112,
    longitude: -95.2899,
    postal_code: '75713'
  },
  '75750': {
    country: 'US',
    city: 'Arp',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.2418,
    longitude: -95.0639,
    postal_code: '75750'
  },
  '75751': {
    country: 'US',
    city: 'Athens',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.1935,
    longitude: -95.8432,
    postal_code: '75751'
  },
  '75752': {
    country: 'US',
    city: 'Athens',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.2151,
    longitude: -95.79,
    postal_code: '75752'
  },
  '75754': {
    country: 'US',
    city: 'Ben Wheeler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Van Zandt',
    latitude: 32.4126,
    longitude: -95.6371,
    postal_code: '75754'
  },
  '75755': {
    country: 'US',
    city: 'Big Sandy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Upshur',
    latitude: 32.6168,
    longitude: -95.088,
    postal_code: '75755'
  },
  '75756': {
    country: 'US',
    city: 'Brownsboro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.304,
    longitude: -95.6,
    postal_code: '75756'
  },
  '75757': {
    country: 'US',
    city: 'Bullard',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.1095,
    longitude: -95.3342,
    postal_code: '75757'
  },
  '75758': {
    country: 'US',
    city: 'Chandler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.2572,
    longitude: -95.5393,
    postal_code: '75758'
  },
  '75759': {
    country: 'US',
    city: 'Cuney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cherokee',
    latitude: 32.0415,
    longitude: -95.42,
    postal_code: '75759'
  },
  '75760': {
    country: 'US',
    city: 'Cushing',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.7978,
    longitude: -94.8539,
    postal_code: '75760'
  },
  '75762': {
    country: 'US',
    city: 'Flint',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.2079,
    longitude: -95.3948,
    postal_code: '75762'
  },
  '75763': {
    country: 'US',
    city: 'Frankston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 32.0535,
    longitude: -95.5163,
    postal_code: '75763'
  },
  '75764': {
    country: 'US',
    city: 'Gallatin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cherokee',
    latitude: 31.891,
    longitude: -95.1455,
    postal_code: '75764'
  },
  '75765': {
    country: 'US',
    city: 'Hawkins',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wood',
    latitude: 32.6439,
    longitude: -95.222,
    postal_code: '75765'
  },
  '75766': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cherokee',
    latitude: 31.9618,
    longitude: -95.2703,
    postal_code: '75766'
  },
  '75770': {
    country: 'US',
    city: 'Larue',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.1608,
    longitude: -95.5927,
    postal_code: '75770'
  },
  '75771': {
    country: 'US',
    city: 'Lindale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.5062,
    longitude: -95.4006,
    postal_code: '75771'
  },
  '75772': {
    country: 'US',
    city: 'Maydelle',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cherokee',
    latitude: 31.8008,
    longitude: -95.3001,
    postal_code: '75772'
  },
  '75773': {
    country: 'US',
    city: 'Mineola',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wood',
    latitude: 32.6661,
    longitude: -95.487,
    postal_code: '75773'
  },
  '75778': {
    country: 'US',
    city: 'Murchison',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.3257,
    longitude: -95.7737,
    postal_code: '75778'
  },
  '75779': {
    country: 'US',
    city: 'Neches',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.8668,
    longitude: -95.4958,
    postal_code: '75779'
  },
  '75780': {
    country: 'US',
    city: 'New Summerfield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cherokee',
    latitude: 31.9807,
    longitude: -95.0938,
    postal_code: '75780'
  },
  '75782': {
    country: 'US',
    city: 'Poynor',
    state: 'Texas',
    state_short: 'TX',
    county: 'Henderson',
    latitude: 32.0797,
    longitude: -95.5938,
    postal_code: '75782'
  },
  '75783': {
    country: 'US',
    city: 'Quitman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wood',
    latitude: 32.8049,
    longitude: -95.4302,
    postal_code: '75783'
  },
  '75784': {
    country: 'US',
    city: 'Reklaw',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cherokee',
    latitude: 31.8859,
    longitude: -95.0118,
    postal_code: '75784'
  },
  '75785': {
    country: 'US',
    city: 'Rusk',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cherokee',
    latitude: 31.8136,
    longitude: -95.0965,
    postal_code: '75785'
  },
  '75788': {
    country: 'US',
    city: 'Sacul',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.8254,
    longitude: -94.9189,
    postal_code: '75788'
  },
  '75789': {
    country: 'US',
    city: 'Troup',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.1117,
    longitude: -95.1155,
    postal_code: '75789'
  },
  '75790': {
    country: 'US',
    city: 'Van',
    state: 'Texas',
    state_short: 'TX',
    county: 'Van Zandt',
    latitude: 32.5283,
    longitude: -95.6545,
    postal_code: '75790'
  },
  '75791': {
    country: 'US',
    city: 'Whitehouse',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.222,
    longitude: -95.2266,
    postal_code: '75791'
  },
  '75792': {
    country: 'US',
    city: 'Winona',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.4662,
    longitude: -95.1246,
    postal_code: '75792'
  },
  '75797': {
    country: 'US',
    city: 'Big Sandy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Upshur',
    latitude: 32.5765,
    longitude: -95.1313,
    postal_code: '75797'
  },
  '75798': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.4112,
    longitude: -95.2899,
    postal_code: '75798'
  },
  '75799': {
    country: 'US',
    city: 'Tyler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Smith',
    latitude: 32.4112,
    longitude: -95.2899,
    postal_code: '75799'
  },
  '75801': {
    country: 'US',
    city: 'Palestine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.7588,
    longitude: -95.6342,
    postal_code: '75801'
  },
  '75802': {
    country: 'US',
    city: 'Palestine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.7621,
    longitude: -95.6308,
    postal_code: '75802'
  },
  '75803': {
    country: 'US',
    city: 'Palestine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.7571,
    longitude: -95.6545,
    postal_code: '75803'
  },
  '75831': {
    country: 'US',
    city: 'Buffalo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Leon',
    latitude: 31.4121,
    longitude: -95.9904,
    postal_code: '75831'
  },
  '75832': {
    country: 'US',
    city: 'Cayuga',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.9571,
    longitude: -95.9747,
    postal_code: '75832'
  },
  '75833': {
    country: 'US',
    city: 'Centerville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Leon',
    latitude: 31.272,
    longitude: -95.9213,
    postal_code: '75833'
  },
  '75834': {
    country: 'US',
    city: 'Centralia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Trinity',
    latitude: 31.258,
    longitude: -95.0399,
    postal_code: '75834'
  },
  '75835': {
    country: 'US',
    city: 'Crockett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Houston',
    latitude: 31.3208,
    longitude: -95.3928,
    postal_code: '75835'
  },
  '75838': {
    country: 'US',
    city: 'Donie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Freestone',
    latitude: 31.4873,
    longitude: -96.2387,
    postal_code: '75838'
  },
  '75839': {
    country: 'US',
    city: 'Elkhart',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.648,
    longitude: -95.5551,
    postal_code: '75839'
  },
  '75840': {
    country: 'US',
    city: 'Fairfield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Freestone',
    latitude: 31.7361,
    longitude: -96.1572,
    postal_code: '75840'
  },
  '75844': {
    country: 'US',
    city: 'Grapeland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Houston',
    latitude: 31.4972,
    longitude: -95.4447,
    postal_code: '75844'
  },
  '75845': {
    country: 'US',
    city: 'Groveton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Trinity',
    latitude: 31.0651,
    longitude: -95.0969,
    postal_code: '75845'
  },
  '75846': {
    country: 'US',
    city: 'Jewett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Leon',
    latitude: 31.3739,
    longitude: -96.1918,
    postal_code: '75846'
  },
  '75847': {
    country: 'US',
    city: 'Kennard',
    state: 'Texas',
    state_short: 'TX',
    county: 'Houston',
    latitude: 31.3384,
    longitude: -95.1541,
    postal_code: '75847'
  },
  '75848': {
    country: 'US',
    city: 'Kirvin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Freestone',
    latitude: 31.7666,
    longitude: -96.3303,
    postal_code: '75848'
  },
  '75849': {
    country: 'US',
    city: 'Latexo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Houston',
    latitude: 31.3952,
    longitude: -95.4741,
    postal_code: '75849'
  },
  '75850': {
    country: 'US',
    city: 'Leona',
    state: 'Texas',
    state_short: 'TX',
    county: 'Leon',
    latitude: 31.1421,
    longitude: -95.9284,
    postal_code: '75850'
  },
  '75851': {
    country: 'US',
    city: 'Lovelady',
    state: 'Texas',
    state_short: 'TX',
    county: 'Houston',
    latitude: 31.0564,
    longitude: -95.5501,
    postal_code: '75851'
  },
  '75852': {
    country: 'US',
    city: 'Midway',
    state: 'Texas',
    state_short: 'TX',
    county: 'Madison',
    latitude: 30.9806,
    longitude: -95.7089,
    postal_code: '75852'
  },
  '75853': {
    country: 'US',
    city: 'Montalba',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.8766,
    longitude: -95.7327,
    postal_code: '75853'
  },
  '75855': {
    country: 'US',
    city: 'Oakwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Leon',
    latitude: 31.5849,
    longitude: -95.8491,
    postal_code: '75855'
  },
  '75856': {
    country: 'US',
    city: 'Pennington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Trinity',
    latitude: 31.1953,
    longitude: -95.2469,
    postal_code: '75856'
  },
  '75858': {
    country: 'US',
    city: 'Ratcliff',
    state: 'Texas',
    state_short: 'TX',
    county: 'Houston',
    latitude: 31.3916,
    longitude: -95.1397,
    postal_code: '75858'
  },
  '75859': {
    country: 'US',
    city: 'Streetman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Freestone',
    latitude: 31.8885,
    longitude: -96.2988,
    postal_code: '75859'
  },
  '75860': {
    country: 'US',
    city: 'Teague',
    state: 'Texas',
    state_short: 'TX',
    county: 'Freestone',
    latitude: 31.6328,
    longitude: -96.2778,
    postal_code: '75860'
  },
  '75861': {
    country: 'US',
    city: 'Tennessee Colony',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.7929,
    longitude: -95.8998,
    postal_code: '75861'
  },
  '75862': {
    country: 'US',
    city: 'Trinity',
    state: 'Texas',
    state_short: 'TX',
    county: 'Trinity',
    latitude: 30.942,
    longitude: -95.3403,
    postal_code: '75862'
  },
  '75865': {
    country: 'US',
    city: 'Woodlake',
    state: 'Texas',
    state_short: 'TX',
    county: 'Trinity',
    latitude: 31.0288,
    longitude: -95.033,
    postal_code: '75865'
  },
  '75880': {
    country: 'US',
    city: 'Tennessee Colony',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.8354,
    longitude: -95.8389,
    postal_code: '75880'
  },
  '75882': {
    country: 'US',
    city: 'Palestine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.7942,
    longitude: -95.662,
    postal_code: '75882'
  },
  '75884': {
    country: 'US',
    city: 'Tennessee Colony',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.8354,
    longitude: -95.8389,
    postal_code: '75884'
  },
  '75886': {
    country: 'US',
    city: 'Tennessee Colony',
    state: 'Texas',
    state_short: 'TX',
    county: 'Anderson',
    latitude: 31.8354,
    longitude: -95.8389,
    postal_code: '75886'
  },
  '75901': {
    country: 'US',
    city: 'Lufkin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Angelina',
    latitude: 31.27,
    longitude: -94.6469,
    postal_code: '75901'
  },
  '75902': {
    country: 'US',
    city: 'Lufkin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Angelina',
    latitude: 31.3623,
    longitude: -94.7611,
    postal_code: '75902'
  },
  '75903': {
    country: 'US',
    city: 'Lufkin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Angelina',
    latitude: 31.2766,
    longitude: -94.5676,
    postal_code: '75903'
  },
  '75904': {
    country: 'US',
    city: 'Lufkin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Angelina',
    latitude: 31.3489,
    longitude: -94.8327,
    postal_code: '75904'
  },
  '75915': {
    country: 'US',
    city: 'Lufkin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Angelina',
    latitude: 31.3382,
    longitude: -94.7291,
    postal_code: '75915'
  },
  '75925': {
    country: 'US',
    city: 'Alto',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cherokee',
    latitude: 31.6103,
    longitude: -95.0762,
    postal_code: '75925'
  },
  '75926': {
    country: 'US',
    city: 'Apple Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Trinity',
    latitude: 31.2269,
    longitude: -94.9812,
    postal_code: '75926'
  },
  '75928': {
    country: 'US',
    city: 'Bon Wier',
    state: 'Texas',
    state_short: 'TX',
    county: 'Newton',
    latitude: 30.6876,
    longitude: -93.7665,
    postal_code: '75928'
  },
  '75929': {
    country: 'US',
    city: 'Broaddus',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Augustine',
    latitude: 31.2952,
    longitude: -94.2156,
    postal_code: '75929'
  },
  '75930': {
    country: 'US',
    city: 'Bronson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Sabine',
    latitude: 31.3391,
    longitude: -93.9993,
    postal_code: '75930'
  },
  '75931': {
    country: 'US',
    city: 'Brookeland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Sabine',
    latitude: 31.0922,
    longitude: -93.9684,
    postal_code: '75931'
  },
  '75932': {
    country: 'US',
    city: 'Burkeville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Newton',
    latitude: 31.0099,
    longitude: -93.6585,
    postal_code: '75932'
  },
  '75933': {
    country: 'US',
    city: 'Call',
    state: 'Texas',
    state_short: 'TX',
    county: 'Newton',
    latitude: 30.5741,
    longitude: -93.8334,
    postal_code: '75933'
  },
  '75934': {
    country: 'US',
    city: 'Camden',
    state: 'Texas',
    state_short: 'TX',
    county: 'Polk',
    latitude: 30.9,
    longitude: -94.7547,
    postal_code: '75934'
  },
  '75935': {
    country: 'US',
    city: 'Center',
    state: 'Texas',
    state_short: 'TX',
    county: 'Shelby',
    latitude: 31.7865,
    longitude: -94.1869,
    postal_code: '75935'
  },
  '75936': {
    country: 'US',
    city: 'Chester',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tyler',
    latitude: 30.9447,
    longitude: -94.5554,
    postal_code: '75936'
  },
  '75937': {
    country: 'US',
    city: 'Chireno',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.5119,
    longitude: -94.4302,
    postal_code: '75937'
  },
  '75938': {
    country: 'US',
    city: 'Colmesneil',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tyler',
    latitude: 30.9007,
    longitude: -94.3916,
    postal_code: '75938'
  },
  '75939': {
    country: 'US',
    city: 'Corrigan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Polk',
    latitude: 31.0408,
    longitude: -94.8124,
    postal_code: '75939'
  },
  '75941': {
    country: 'US',
    city: 'Diboll',
    state: 'Texas',
    state_short: 'TX',
    county: 'Angelina',
    latitude: 31.195,
    longitude: -94.7729,
    postal_code: '75941'
  },
  '75942': {
    country: 'US',
    city: 'Doucette',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tyler',
    latitude: 30.8183,
    longitude: -94.4288,
    postal_code: '75942'
  },
  '75943': {
    country: 'US',
    city: 'Douglass',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.6578,
    longitude: -94.8696,
    postal_code: '75943'
  },
  '75944': {
    country: 'US',
    city: 'Etoile',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.3601,
    longitude: -94.4064,
    postal_code: '75944'
  },
  '75946': {
    country: 'US',
    city: 'Garrison',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.8111,
    longitude: -94.5266,
    postal_code: '75946'
  },
  '75948': {
    country: 'US',
    city: 'Hemphill',
    state: 'Texas',
    state_short: 'TX',
    county: 'Sabine',
    latitude: 31.3161,
    longitude: -93.7905,
    postal_code: '75948'
  },
  '75949': {
    country: 'US',
    city: 'Huntington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Angelina',
    latitude: 31.2837,
    longitude: -94.5662,
    postal_code: '75949'
  },
  '75951': {
    country: 'US',
    city: 'Jasper',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jasper',
    latitude: 30.8673,
    longitude: -93.9977,
    postal_code: '75951'
  },
  '75954': {
    country: 'US',
    city: 'Joaquin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Shelby',
    latitude: 31.944,
    longitude: -94.0608,
    postal_code: '75954'
  },
  '75956': {
    country: 'US',
    city: 'Kirbyville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jasper',
    latitude: 30.6583,
    longitude: -93.8984,
    postal_code: '75956'
  },
  '75958': {
    country: 'US',
    city: 'Martinsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.6427,
    longitude: -94.4141,
    postal_code: '75958'
  },
  '75959': {
    country: 'US',
    city: 'Milam',
    state: 'Texas',
    state_short: 'TX',
    county: 'Sabine',
    latitude: 31.4324,
    longitude: -93.8457,
    postal_code: '75959'
  },
  '75960': {
    country: 'US',
    city: 'Moscow',
    state: 'Texas',
    state_short: 'TX',
    county: 'Polk',
    latitude: 30.9179,
    longitude: -94.8544,
    postal_code: '75960'
  },
  '75961': {
    country: 'US',
    city: 'Nacogdoches',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.5611,
    longitude: -94.5,
    postal_code: '75961'
  },
  '75962': {
    country: 'US',
    city: 'Nacogdoches',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.6995,
    longitude: -94.6074,
    postal_code: '75962'
  },
  '75963': {
    country: 'US',
    city: 'Nacogdoches',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.6046,
    longitude: -94.6641,
    postal_code: '75963'
  },
  '75964': {
    country: 'US',
    city: 'Nacogdoches',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.6737,
    longitude: -94.6932,
    postal_code: '75964'
  },
  '75965': {
    country: 'US',
    city: 'Nacogdoches',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.7195,
    longitude: -94.6168,
    postal_code: '75965'
  },
  '75966': {
    country: 'US',
    city: 'Newton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Newton',
    latitude: 30.8351,
    longitude: -93.7497,
    postal_code: '75966'
  },
  '75968': {
    country: 'US',
    city: 'Pineland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Sabine',
    latitude: 31.2418,
    longitude: -93.9754,
    postal_code: '75968'
  },
  '75969': {
    country: 'US',
    city: 'Pollok',
    state: 'Texas',
    state_short: 'TX',
    county: 'Angelina',
    latitude: 31.4291,
    longitude: -94.8254,
    postal_code: '75969'
  },
  '75972': {
    country: 'US',
    city: 'San Augustine',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Augustine',
    latitude: 31.5152,
    longitude: -94.1326,
    postal_code: '75972'
  },
  '75973': {
    country: 'US',
    city: 'Shelbyville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Shelby',
    latitude: 31.7131,
    longitude: -93.9698,
    postal_code: '75973'
  },
  '75974': {
    country: 'US',
    city: 'Tenaha',
    state: 'Texas',
    state_short: 'TX',
    county: 'Shelby',
    latitude: 31.9408,
    longitude: -94.2488,
    postal_code: '75974'
  },
  '75975': {
    country: 'US',
    city: 'Timpson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Shelby',
    latitude: 31.8841,
    longitude: -94.3967,
    postal_code: '75975'
  },
  '75976': {
    country: 'US',
    city: 'Wells',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cherokee',
    latitude: 31.4885,
    longitude: -94.9399,
    postal_code: '75976'
  },
  '75977': {
    country: 'US',
    city: 'Wiergate',
    state: 'Texas',
    state_short: 'TX',
    county: 'Newton',
    latitude: 31.0414,
    longitude: -93.8039,
    postal_code: '75977'
  },
  '75978': {
    country: 'US',
    city: 'Woden',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nacogdoches',
    latitude: 31.5037,
    longitude: -94.525,
    postal_code: '75978'
  },
  '75979': {
    country: 'US',
    city: 'Woodville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tyler',
    latitude: 30.7752,
    longitude: -94.4155,
    postal_code: '75979'
  },
  '75980': {
    country: 'US',
    city: 'Zavalla',
    state: 'Texas',
    state_short: 'TX',
    county: 'Angelina',
    latitude: 31.1569,
    longitude: -94.3871,
    postal_code: '75980'
  },
  '75990': {
    country: 'US',
    city: 'Woodville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tyler',
    latitude: 30.7752,
    longitude: -94.4155,
    postal_code: '75990'
  },
  '76001': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6289,
    longitude: -97.1517,
    postal_code: '76001'
  },
  '76002': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6255,
    longitude: -97.0784,
    postal_code: '76002'
  },
  '76003': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7417,
    longitude: -97.2253,
    postal_code: '76003'
  },
  '76004': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7357,
    longitude: -97.1081,
    postal_code: '76004'
  },
  '76005': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76005'
  },
  '76006': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7785,
    longitude: -97.0834,
    postal_code: '76006'
  },
  '76007': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76007'
  },
  '76008': {
    country: 'US',
    city: 'Aledo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.7004,
    longitude: -97.6039,
    postal_code: '76008'
  },
  '76009': {
    country: 'US',
    city: 'Alvarado',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.4395,
    longitude: -97.213,
    postal_code: '76009'
  },
  '76010': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7204,
    longitude: -97.0826,
    postal_code: '76010'
  },
  '76011': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7582,
    longitude: -97.1003,
    postal_code: '76011'
  },
  '76012': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.754,
    longitude: -97.1348,
    postal_code: '76012'
  },
  '76013': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7199,
    longitude: -97.1442,
    postal_code: '76013'
  },
  '76014': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6954,
    longitude: -97.0876,
    postal_code: '76014'
  },
  '76015': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6931,
    longitude: -97.1347,
    postal_code: '76015'
  },
  '76016': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6889,
    longitude: -97.1905,
    postal_code: '76016'
  },
  '76017': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6555,
    longitude: -97.1599,
    postal_code: '76017'
  },
  '76018': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6548,
    longitude: -97.092,
    postal_code: '76018'
  },
  '76019': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7357,
    longitude: -97.1081,
    postal_code: '76019'
  },
  '76020': {
    country: 'US',
    city: 'Azle',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.9035,
    longitude: -97.5412,
    postal_code: '76020'
  },
  '76021': {
    country: 'US',
    city: 'Bedford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8536,
    longitude: -97.1358,
    postal_code: '76021'
  },
  '76022': {
    country: 'US',
    city: 'Bedford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8297,
    longitude: -97.1454,
    postal_code: '76022'
  },
  '76023': {
    country: 'US',
    city: 'Boyd',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wise',
    latitude: 33.0594,
    longitude: -97.5868,
    postal_code: '76023'
  },
  '76028': {
    country: 'US',
    city: 'Burleson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.5316,
    longitude: -97.309,
    postal_code: '76028'
  },
  '76031': {
    country: 'US',
    city: 'Cleburne',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.3485,
    longitude: -97.3311,
    postal_code: '76031'
  },
  '76033': {
    country: 'US',
    city: 'Cleburne',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.3509,
    longitude: -97.4103,
    postal_code: '76033'
  },
  '76034': {
    country: 'US',
    city: 'Colleyville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8872,
    longitude: -97.146,
    postal_code: '76034'
  },
  '76035': {
    country: 'US',
    city: 'Cresson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hood',
    latitude: 32.5343,
    longitude: -97.6158,
    postal_code: '76035'
  },
  '76036': {
    country: 'US',
    city: 'Crowley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.5814,
    longitude: -97.3703,
    postal_code: '76036'
  },
  '76039': {
    country: 'US',
    city: 'Euless',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8582,
    longitude: -97.0832,
    postal_code: '76039'
  },
  '76040': {
    country: 'US',
    city: 'Euless',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8264,
    longitude: -97.0972,
    postal_code: '76040'
  },
  '76041': {
    country: 'US',
    city: 'Forreston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.2486,
    longitude: -96.8232,
    postal_code: '76041'
  },
  '76043': {
    country: 'US',
    city: 'Glen Rose',
    state: 'Texas',
    state_short: 'TX',
    county: 'Somervell',
    latitude: 32.2298,
    longitude: -97.7629,
    postal_code: '76043'
  },
  '76044': {
    country: 'US',
    city: 'Godley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.4282,
    longitude: -97.5349,
    postal_code: '76044'
  },
  '76048': {
    country: 'US',
    city: 'Granbury',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hood',
    latitude: 32.425,
    longitude: -97.7742,
    postal_code: '76048'
  },
  '76049': {
    country: 'US',
    city: 'Granbury',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hood',
    latitude: 32.4488,
    longitude: -97.7285,
    postal_code: '76049'
  },
  '76050': {
    country: 'US',
    city: 'Grandview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.2704,
    longitude: -97.1792,
    postal_code: '76050'
  },
  '76051': {
    country: 'US',
    city: 'Grapevine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.9328,
    longitude: -97.0808,
    postal_code: '76051'
  },
  '76052': {
    country: 'US',
    city: 'Haslet',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.9557,
    longitude: -97.3372,
    postal_code: '76052'
  },
  '76053': {
    country: 'US',
    city: 'Hurst',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8211,
    longitude: -97.1756,
    postal_code: '76053'
  },
  '76054': {
    country: 'US',
    city: 'Hurst',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8558,
    longitude: -97.1755,
    postal_code: '76054'
  },
  '76055': {
    country: 'US',
    city: 'Itasca',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 32.1636,
    longitude: -97.146,
    postal_code: '76055'
  },
  '76058': {
    country: 'US',
    city: 'Joshua',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.4663,
    longitude: -97.4011,
    postal_code: '76058'
  },
  '76059': {
    country: 'US',
    city: 'Keene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.3937,
    longitude: -97.3287,
    postal_code: '76059'
  },
  '76060': {
    country: 'US',
    city: 'Kennedale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6432,
    longitude: -97.2139,
    postal_code: '76060'
  },
  '76061': {
    country: 'US',
    city: 'Lillian',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.5065,
    longitude: -97.1875,
    postal_code: '76061'
  },
  '76063': {
    country: 'US',
    city: 'Mansfield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.5773,
    longitude: -97.1416,
    postal_code: '76063'
  },
  '76064': {
    country: 'US',
    city: 'Maypearl',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.2993,
    longitude: -97.0271,
    postal_code: '76064'
  },
  '76065': {
    country: 'US',
    city: 'Midlothian',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.4757,
    longitude: -96.9936,
    postal_code: '76065'
  },
  '76066': {
    country: 'US',
    city: 'Millsap',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.7457,
    longitude: -97.9561,
    postal_code: '76066'
  },
  '76067': {
    country: 'US',
    city: 'Mineral Wells',
    state: 'Texas',
    state_short: 'TX',
    county: 'Palo Pinto',
    latitude: 32.8085,
    longitude: -98.1128,
    postal_code: '76067'
  },
  '76068': {
    country: 'US',
    city: 'Mineral Wells',
    state: 'Texas',
    state_short: 'TX',
    county: 'Palo Pinto',
    latitude: 32.8085,
    longitude: -98.1128,
    postal_code: '76068'
  },
  '76070': {
    country: 'US',
    city: 'Nemo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Somervell',
    latitude: 32.2713,
    longitude: -97.6567,
    postal_code: '76070'
  },
  '76071': {
    country: 'US',
    city: 'Newark',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wise',
    latitude: 33.007,
    longitude: -97.4923,
    postal_code: '76071'
  },
  '76073': {
    country: 'US',
    city: 'Paradise',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wise',
    latitude: 33.0826,
    longitude: -97.6974,
    postal_code: '76073'
  },
  '76077': {
    country: 'US',
    city: 'Rainbow',
    state: 'Texas',
    state_short: 'TX',
    county: 'Somervell',
    latitude: 32.2812,
    longitude: -97.7065,
    postal_code: '76077'
  },
  '76078': {
    country: 'US',
    city: 'Rhome',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wise',
    latitude: 33.054,
    longitude: -97.4817,
    postal_code: '76078'
  },
  '76082': {
    country: 'US',
    city: 'Springtown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.966,
    longitude: -97.6836,
    postal_code: '76082'
  },
  '76084': {
    country: 'US',
    city: 'Venus',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.433,
    longitude: -97.1087,
    postal_code: '76084'
  },
  '76085': {
    country: 'US',
    city: 'Weatherford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.7676,
    longitude: -97.7516,
    postal_code: '76085'
  },
  '76086': {
    country: 'US',
    city: 'Weatherford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.7549,
    longitude: -97.7899,
    postal_code: '76086'
  },
  '76087': {
    country: 'US',
    city: 'Weatherford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.7495,
    longitude: -97.6894,
    postal_code: '76087'
  },
  '76088': {
    country: 'US',
    city: 'Weatherford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.8478,
    longitude: -97.8606,
    postal_code: '76088'
  },
  '76092': {
    country: 'US',
    city: 'Southlake',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.9485,
    longitude: -97.1524,
    postal_code: '76092'
  },
  '76093': {
    country: 'US',
    city: 'Rio Vista',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.2532,
    longitude: -97.3678,
    postal_code: '76093'
  },
  '76094': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76094'
  },
  '76095': {
    country: 'US',
    city: 'Bedford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.844,
    longitude: -97.1431,
    postal_code: '76095'
  },
  '76096': {
    country: 'US',
    city: 'Arlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76096'
  },
  '76097': {
    country: 'US',
    city: 'Burleson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Johnson',
    latitude: 32.5421,
    longitude: -97.3208,
    postal_code: '76097'
  },
  '76098': {
    country: 'US',
    city: 'Azle',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.8957,
    longitude: -97.5636,
    postal_code: '76098'
  },
  '76099': {
    country: 'US',
    city: 'Grapevine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.9343,
    longitude: -97.0781,
    postal_code: '76099'
  },
  '76101': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76101'
  },
  '76102': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7589,
    longitude: -97.328,
    postal_code: '76102'
  },
  '76103': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.747,
    longitude: -97.2604,
    postal_code: '76103'
  },
  '76104': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7256,
    longitude: -97.3184,
    postal_code: '76104'
  },
  '76105': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7233,
    longitude: -97.269,
    postal_code: '76105'
  },
  '76106': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7968,
    longitude: -97.356,
    postal_code: '76106'
  },
  '76107': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7392,
    longitude: -97.3852,
    postal_code: '76107'
  },
  '76108': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7822,
    longitude: -97.4969,
    postal_code: '76108'
  },
  '76109': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7002,
    longitude: -97.3789,
    postal_code: '76109'
  },
  '76110': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7065,
    longitude: -97.3375,
    postal_code: '76110'
  },
  '76111': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7824,
    longitude: -97.3003,
    postal_code: '76111'
  },
  '76112': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7493,
    longitude: -97.2181,
    postal_code: '76112'
  },
  '76113': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76113'
  },
  '76114': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7796,
    longitude: -97.3928,
    postal_code: '76114'
  },
  '76115': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6796,
    longitude: -97.3336,
    postal_code: '76115'
  },
  '76116': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.723,
    longitude: -97.4483,
    postal_code: '76116'
  },
  '76117': {
    country: 'US',
    city: 'Haltom City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8087,
    longitude: -97.2709,
    postal_code: '76117'
  },
  '76118': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8013,
    longitude: -97.1952,
    postal_code: '76118'
  },
  '76119': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6914,
    longitude: -97.2675,
    postal_code: '76119'
  },
  '76120': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7639,
    longitude: -97.1781,
    postal_code: '76120'
  },
  '76121': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76121'
  },
  '76122': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76122'
  },
  '76123': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6254,
    longitude: -97.3658,
    postal_code: '76123'
  },
  '76124': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76124'
  },
  '76126': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.64,
    longitude: -97.5448,
    postal_code: '76126'
  },
  '76127': {
    country: 'US',
    city: 'Naval Air Station Jrb',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7254,
    longitude: -97.3208,
    postal_code: '76127'
  },
  '76129': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76129'
  },
  '76130': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76130'
  },
  '76131': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8632,
    longitude: -97.3377,
    postal_code: '76131'
  },
  '76132': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6711,
    longitude: -97.4056,
    postal_code: '76132'
  },
  '76133': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6526,
    longitude: -97.3758,
    postal_code: '76133'
  },
  '76134': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6469,
    longitude: -97.3325,
    postal_code: '76134'
  },
  '76135': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8248,
    longitude: -97.4519,
    postal_code: '76135'
  },
  '76136': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76136'
  },
  '76137': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8664,
    longitude: -97.2891,
    postal_code: '76137'
  },
  '76140': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.6192,
    longitude: -97.2735,
    postal_code: '76140'
  },
  '76147': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76147'
  },
  '76148': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8621,
    longitude: -97.2508,
    postal_code: '76148'
  },
  '76150': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76150'
  },
  '76155': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8247,
    longitude: -97.0503,
    postal_code: '76155'
  },
  '76161': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76161'
  },
  '76162': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76162'
  },
  '76163': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76163'
  },
  '76164': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7811,
    longitude: -97.3546,
    postal_code: '76164'
  },
  '76166': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7539,
    longitude: -97.3362,
    postal_code: '76166'
  },
  '76177': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.9448,
    longitude: -97.3124,
    postal_code: '76177'
  },
  '76179': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.9074,
    longitude: -97.4257,
    postal_code: '76179'
  },
  '76180': {
    country: 'US',
    city: 'North Richland Hills',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.84,
    longitude: -97.225,
    postal_code: '76180'
  },
  '76181': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76181'
  },
  '76182': {
    country: 'US',
    city: 'North Richland Hills',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.8828,
    longitude: -97.2098,
    postal_code: '76182'
  },
  '76185': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76185'
  },
  '76190': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7423,
    longitude: -97.3315,
    postal_code: '76190'
  },
  '76191': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76191'
  },
  '76192': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76192'
  },
  '76193': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76193'
  },
  '76195': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7254,
    longitude: -97.3208,
    postal_code: '76195'
  },
  '76196': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76196'
  },
  '76197': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76197'
  },
  '76198': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76198'
  },
  '76199': {
    country: 'US',
    city: 'Fort Worth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.7714,
    longitude: -97.2915,
    postal_code: '76199'
  },
  '76201': {
    country: 'US',
    city: 'Denton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.2289,
    longitude: -97.1314,
    postal_code: '76201'
  },
  '76202': {
    country: 'US',
    city: 'Denton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.2255,
    longitude: -97.1085,
    postal_code: '76202'
  },
  '76203': {
    country: 'US',
    city: 'Denton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.2148,
    longitude: -97.1331,
    postal_code: '76203'
  },
  '76204': {
    country: 'US',
    city: 'Denton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.2074,
    longitude: -97.1163,
    postal_code: '76204'
  },
  '76205': {
    country: 'US',
    city: 'Denton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.1903,
    longitude: -97.1282,
    postal_code: '76205'
  },
  '76206': {
    country: 'US',
    city: 'Denton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.1694,
    longitude: -97.1506,
    postal_code: '76206'
  },
  '76207': {
    country: 'US',
    city: 'Denton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.2285,
    longitude: -97.1813,
    postal_code: '76207'
  },
  '76208': {
    country: 'US',
    city: 'Denton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.2117,
    longitude: -97.0612,
    postal_code: '76208'
  },
  '76209': {
    country: 'US',
    city: 'Denton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.2346,
    longitude: -97.1131,
    postal_code: '76209'
  },
  '76210': {
    country: 'US',
    city: 'Denton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.1428,
    longitude: -97.0727,
    postal_code: '76210'
  },
  '76225': {
    country: 'US',
    city: 'Alvord',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wise',
    latitude: 33.3698,
    longitude: -97.6885,
    postal_code: '76225'
  },
  '76226': {
    country: 'US',
    city: 'Argyle',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.1062,
    longitude: -97.16,
    postal_code: '76226'
  },
  '76227': {
    country: 'US',
    city: 'Aubrey',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.292,
    longitude: -96.9879,
    postal_code: '76227'
  },
  '76228': {
    country: 'US',
    city: 'Bellevue',
    state: 'Texas',
    state_short: 'TX',
    county: 'Clay',
    latitude: 33.5879,
    longitude: -98.1574,
    postal_code: '76228'
  },
  '76230': {
    country: 'US',
    city: 'Bowie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montague',
    latitude: 33.5568,
    longitude: -97.8373,
    postal_code: '76230'
  },
  '76233': {
    country: 'US',
    city: 'Collinsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.558,
    longitude: -96.9014,
    postal_code: '76233'
  },
  '76234': {
    country: 'US',
    city: 'Decatur',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wise',
    latitude: 33.2351,
    longitude: -97.574,
    postal_code: '76234'
  },
  '76238': {
    country: 'US',
    city: 'Era',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cooke',
    latitude: 33.4965,
    longitude: -97.3859,
    postal_code: '76238'
  },
  '76239': {
    country: 'US',
    city: 'Forestburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montague',
    latitude: 33.5398,
    longitude: -97.5848,
    postal_code: '76239'
  },
  '76240': {
    country: 'US',
    city: 'Gainesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cooke',
    latitude: 33.6547,
    longitude: -97.1583,
    postal_code: '76240'
  },
  '76241': {
    country: 'US',
    city: 'Gainesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cooke',
    latitude: 33.6104,
    longitude: -97.0369,
    postal_code: '76241'
  },
  '76244': {
    country: 'US',
    city: 'Keller',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.931,
    longitude: -97.2843,
    postal_code: '76244'
  },
  '76245': {
    country: 'US',
    city: 'Gordonville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.8343,
    longitude: -96.8403,
    postal_code: '76245'
  },
  '76246': {
    country: 'US',
    city: 'Greenwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wise',
    latitude: 33.407,
    longitude: -97.4716,
    postal_code: '76246'
  },
  '76247': {
    country: 'US',
    city: 'Justin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.0734,
    longitude: -97.3093,
    postal_code: '76247'
  },
  '76248': {
    country: 'US',
    city: 'Keller',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tarrant',
    latitude: 32.9276,
    longitude: -97.2489,
    postal_code: '76248'
  },
  '76249': {
    country: 'US',
    city: 'Krum',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.2734,
    longitude: -97.2675,
    postal_code: '76249'
  },
  '76250': {
    country: 'US',
    city: 'Lindsay',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cooke',
    latitude: 33.636,
    longitude: -97.2214,
    postal_code: '76250'
  },
  '76251': {
    country: 'US',
    city: 'Montague',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montague',
    latitude: 33.6639,
    longitude: -97.7282,
    postal_code: '76251'
  },
  '76252': {
    country: 'US',
    city: 'Muenster',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cooke',
    latitude: 33.6595,
    longitude: -97.3624,
    postal_code: '76252'
  },
  '76253': {
    country: 'US',
    city: 'Myra',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cooke',
    latitude: 33.6178,
    longitude: -97.309,
    postal_code: '76253'
  },
  '76255': {
    country: 'US',
    city: 'Nocona',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montague',
    latitude: 33.7982,
    longitude: -97.727,
    postal_code: '76255'
  },
  '76258': {
    country: 'US',
    city: 'Pilot Point',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.371,
    longitude: -96.9446,
    postal_code: '76258'
  },
  '76259': {
    country: 'US',
    city: 'Ponder',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.1774,
    longitude: -97.2848,
    postal_code: '76259'
  },
  '76261': {
    country: 'US',
    city: 'Ringgold',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montague',
    latitude: 33.8164,
    longitude: -97.944,
    postal_code: '76261'
  },
  '76262': {
    country: 'US',
    city: 'Roanoke',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.0211,
    longitude: -97.2127,
    postal_code: '76262'
  },
  '76263': {
    country: 'US',
    city: 'Rosston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cooke',
    latitude: 33.4674,
    longitude: -97.4534,
    postal_code: '76263'
  },
  '76264': {
    country: 'US',
    city: 'Sadler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.731,
    longitude: -96.84,
    postal_code: '76264'
  },
  '76265': {
    country: 'US',
    city: 'Saint Jo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montague',
    latitude: 33.744,
    longitude: -97.5568,
    postal_code: '76265'
  },
  '76266': {
    country: 'US',
    city: 'Sanger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Denton',
    latitude: 33.3563,
    longitude: -97.1814,
    postal_code: '76266'
  },
  '76267': {
    country: 'US',
    city: 'Slidell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wise',
    latitude: 33.378,
    longitude: -97.3922,
    postal_code: '76267'
  },
  '76268': {
    country: 'US',
    city: 'Southmayd',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.6304,
    longitude: -96.7692,
    postal_code: '76268'
  },
  '76270': {
    country: 'US',
    city: 'Sunset',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montague',
    latitude: 33.4539,
    longitude: -97.7709,
    postal_code: '76270'
  },
  '76271': {
    country: 'US',
    city: 'Tioga',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.4675,
    longitude: -96.9097,
    postal_code: '76271'
  },
  '76272': {
    country: 'US',
    city: 'Valley View',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cooke',
    latitude: 33.5022,
    longitude: -97.2311,
    postal_code: '76272'
  },
  '76273': {
    country: 'US',
    city: 'Whitesboro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grayson',
    latitude: 33.659,
    longitude: -96.879,
    postal_code: '76273'
  },
  '76301': {
    country: 'US',
    city: 'Wichita Falls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.9053,
    longitude: -98.4976,
    postal_code: '76301'
  },
  '76302': {
    country: 'US',
    city: 'Wichita Falls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.8643,
    longitude: -98.494,
    postal_code: '76302'
  },
  '76305': {
    country: 'US',
    city: 'Wichita Falls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.9995,
    longitude: -98.3938,
    postal_code: '76305'
  },
  '76306': {
    country: 'US',
    city: 'Wichita Falls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.9519,
    longitude: -98.5145,
    postal_code: '76306'
  },
  '76307': {
    country: 'US',
    city: 'Wichita Falls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.9137,
    longitude: -98.4934,
    postal_code: '76307'
  },
  '76308': {
    country: 'US',
    city: 'Wichita Falls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.8633,
    longitude: -98.534,
    postal_code: '76308'
  },
  '76309': {
    country: 'US',
    city: 'Wichita Falls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.8931,
    longitude: -98.5343,
    postal_code: '76309'
  },
  '76310': {
    country: 'US',
    city: 'Wichita Falls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.8581,
    longitude: -98.5755,
    postal_code: '76310'
  },
  '76311': {
    country: 'US',
    city: 'Sheppard Afb',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.9824,
    longitude: -98.5088,
    postal_code: '76311'
  },
  '76351': {
    country: 'US',
    city: 'Archer City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Archer',
    latitude: 33.5562,
    longitude: -98.6249,
    postal_code: '76351'
  },
  '76352': {
    country: 'US',
    city: 'Bluegrove',
    state: 'Texas',
    state_short: 'TX',
    county: 'Clay',
    latitude: 33.674,
    longitude: -98.23,
    postal_code: '76352'
  },
  '76354': {
    country: 'US',
    city: 'Burkburnett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 34.086,
    longitude: -98.5708,
    postal_code: '76354'
  },
  '76357': {
    country: 'US',
    city: 'Byers',
    state: 'Texas',
    state_short: 'TX',
    county: 'Clay',
    latitude: 34.0728,
    longitude: -98.1839,
    postal_code: '76357'
  },
  '76360': {
    country: 'US',
    city: 'Electra',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 34.0362,
    longitude: -98.9155,
    postal_code: '76360'
  },
  '76363': {
    country: 'US',
    city: 'Goree',
    state: 'Texas',
    state_short: 'TX',
    county: 'Knox',
    latitude: 33.4748,
    longitude: -99.5258,
    postal_code: '76363'
  },
  '76364': {
    country: 'US',
    city: 'Harrold',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilbarger',
    latitude: 34.0806,
    longitude: -99.0323,
    postal_code: '76364'
  },
  '76365': {
    country: 'US',
    city: 'Henrietta',
    state: 'Texas',
    state_short: 'TX',
    county: 'Clay',
    latitude: 33.8196,
    longitude: -98.26,
    postal_code: '76365'
  },
  '76366': {
    country: 'US',
    city: 'Holliday',
    state: 'Texas',
    state_short: 'TX',
    county: 'Archer',
    latitude: 33.8162,
    longitude: -98.6951,
    postal_code: '76366'
  },
  '76367': {
    country: 'US',
    city: 'Iowa Park',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.9423,
    longitude: -98.6745,
    postal_code: '76367'
  },
  '76369': {
    country: 'US',
    city: 'Kamay',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wichita',
    latitude: 33.8579,
    longitude: -98.8081,
    postal_code: '76369'
  },
  '76370': {
    country: 'US',
    city: 'Megargel',
    state: 'Texas',
    state_short: 'TX',
    county: 'Archer',
    latitude: 33.4533,
    longitude: -98.9297,
    postal_code: '76370'
  },
  '76371': {
    country: 'US',
    city: 'Munday',
    state: 'Texas',
    state_short: 'TX',
    county: 'Knox',
    latitude: 33.4561,
    longitude: -99.6326,
    postal_code: '76371'
  },
  '76372': {
    country: 'US',
    city: 'Newcastle',
    state: 'Texas',
    state_short: 'TX',
    county: 'Young',
    latitude: 33.1901,
    longitude: -98.7446,
    postal_code: '76372'
  },
  '76373': {
    country: 'US',
    city: 'Oklaunion',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilbarger',
    latitude: 34.1295,
    longitude: -99.1429,
    postal_code: '76373'
  },
  '76374': {
    country: 'US',
    city: 'Olney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Young',
    latitude: 33.3601,
    longitude: -98.7427,
    postal_code: '76374'
  },
  '76377': {
    country: 'US',
    city: 'Petrolia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Clay',
    latitude: 34.0132,
    longitude: -98.2323,
    postal_code: '76377'
  },
  '76379': {
    country: 'US',
    city: 'Scotland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Archer',
    latitude: 33.6535,
    longitude: -98.465,
    postal_code: '76379'
  },
  '76380': {
    country: 'US',
    city: 'Seymour',
    state: 'Texas',
    state_short: 'TX',
    county: 'Baylor',
    latitude: 33.5914,
    longitude: -99.2587,
    postal_code: '76380'
  },
  '76384': {
    country: 'US',
    city: 'Vernon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilbarger',
    latitude: 34.1491,
    longitude: -99.303,
    postal_code: '76384'
  },
  '76385': {
    country: 'US',
    city: 'Vernon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilbarger',
    latitude: 34.1554,
    longitude: -99.2663,
    postal_code: '76385'
  },
  '76388': {
    country: 'US',
    city: 'Weinert',
    state: 'Texas',
    state_short: 'TX',
    county: 'Haskell',
    latitude: 33.3249,
    longitude: -99.6664,
    postal_code: '76388'
  },
  '76389': {
    country: 'US',
    city: 'Windthorst',
    state: 'Texas',
    state_short: 'TX',
    county: 'Archer',
    latitude: 33.5796,
    longitude: -98.4376,
    postal_code: '76389'
  },
  '76401': {
    country: 'US',
    city: 'Stephenville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Erath',
    latitude: 32.2214,
    longitude: -98.2224,
    postal_code: '76401'
  },
  '76402': {
    country: 'US',
    city: 'Stephenville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Erath',
    latitude: 32.2153,
    longitude: -98.208,
    postal_code: '76402'
  },
  '76424': {
    country: 'US',
    city: 'Breckenridge',
    state: 'Texas',
    state_short: 'TX',
    county: 'Stephens',
    latitude: 32.7532,
    longitude: -98.9099,
    postal_code: '76424'
  },
  '76426': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wise',
    latitude: 33.187,
    longitude: -97.781,
    postal_code: '76426'
  },
  '76427': {
    country: 'US',
    city: 'Bryson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jack',
    latitude: 33.1615,
    longitude: -98.3874,
    postal_code: '76427'
  },
  '76429': {
    country: 'US',
    city: 'Caddo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Stephens',
    latitude: 32.6886,
    longitude: -98.659,
    postal_code: '76429'
  },
  '76430': {
    country: 'US',
    city: 'Albany',
    state: 'Texas',
    state_short: 'TX',
    county: 'Shackelford',
    latitude: 32.719,
    longitude: -99.3196,
    postal_code: '76430'
  },
  '76431': {
    country: 'US',
    city: 'Chico',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wise',
    latitude: 33.3193,
    longitude: -97.8031,
    postal_code: '76431'
  },
  '76432': {
    country: 'US',
    city: 'Blanket',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brown',
    latitude: 31.7882,
    longitude: -98.8311,
    postal_code: '76432'
  },
  '76433': {
    country: 'US',
    city: 'Bluff Dale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Erath',
    latitude: 32.2884,
    longitude: -98.0539,
    postal_code: '76433'
  },
  '76435': {
    country: 'US',
    city: 'Carbon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Eastland',
    latitude: 32.2701,
    longitude: -98.8348,
    postal_code: '76435'
  },
  '76436': {
    country: 'US',
    city: 'Carlton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hamilton',
    latitude: 31.8949,
    longitude: -98.1842,
    postal_code: '76436'
  },
  '76437': {
    country: 'US',
    city: 'Cisco',
    state: 'Texas',
    state_short: 'TX',
    county: 'Eastland',
    latitude: 32.38,
    longitude: -98.9865,
    postal_code: '76437'
  },
  '76439': {
    country: 'US',
    city: 'Dennis',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.6187,
    longitude: -97.9267,
    postal_code: '76439'
  },
  '76442': {
    country: 'US',
    city: 'Comanche',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comanche',
    latitude: 31.9116,
    longitude: -98.6082,
    postal_code: '76442'
  },
  '76443': {
    country: 'US',
    city: 'Cross Plains',
    state: 'Texas',
    state_short: 'TX',
    county: 'Callahan',
    latitude: 32.1482,
    longitude: -99.1872,
    postal_code: '76443'
  },
  '76444': {
    country: 'US',
    city: 'De Leon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comanche',
    latitude: 32.1087,
    longitude: -98.5489,
    postal_code: '76444'
  },
  '76445': {
    country: 'US',
    city: 'Desdemona',
    state: 'Texas',
    state_short: 'TX',
    county: 'Eastland',
    latitude: 32.2819,
    longitude: -98.5673,
    postal_code: '76445'
  },
  '76446': {
    country: 'US',
    city: 'Dublin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Erath',
    latitude: 32.0909,
    longitude: -98.3455,
    postal_code: '76446'
  },
  '76448': {
    country: 'US',
    city: 'Eastland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Eastland',
    latitude: 32.3994,
    longitude: -98.8071,
    postal_code: '76448'
  },
  '76449': {
    country: 'US',
    city: 'Graford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Palo Pinto',
    latitude: 32.9242,
    longitude: -98.337,
    postal_code: '76449'
  },
  '76450': {
    country: 'US',
    city: 'Graham',
    state: 'Texas',
    state_short: 'TX',
    county: 'Young',
    latitude: 33.0993,
    longitude: -98.5832,
    postal_code: '76450'
  },
  '76452': {
    country: 'US',
    city: 'Energy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comanche',
    latitude: 31.7629,
    longitude: -98.3695,
    postal_code: '76452'
  },
  '76453': {
    country: 'US',
    city: 'Gordon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Palo Pinto',
    latitude: 32.5478,
    longitude: -98.3632,
    postal_code: '76453'
  },
  '76454': {
    country: 'US',
    city: 'Gorman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Eastland',
    latitude: 32.2234,
    longitude: -98.6834,
    postal_code: '76454'
  },
  '76455': {
    country: 'US',
    city: 'Gustine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comanche',
    latitude: 31.8468,
    longitude: -98.4017,
    postal_code: '76455'
  },
  '76457': {
    country: 'US',
    city: 'Hico',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hamilton',
    latitude: 31.9597,
    longitude: -98.0249,
    postal_code: '76457'
  },
  '76458': {
    country: 'US',
    city: 'Jacksboro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jack',
    latitude: 33.2347,
    longitude: -98.1681,
    postal_code: '76458'
  },
  '76459': {
    country: 'US',
    city: 'Jermyn',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jack',
    latitude: 33.2636,
    longitude: -98.3931,
    postal_code: '76459'
  },
  '76460': {
    country: 'US',
    city: 'Loving',
    state: 'Texas',
    state_short: 'TX',
    county: 'Young',
    latitude: 33.2689,
    longitude: -98.5024,
    postal_code: '76460'
  },
  '76461': {
    country: 'US',
    city: 'Lingleville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Erath',
    latitude: 32.2446,
    longitude: -98.3775,
    postal_code: '76461'
  },
  '76462': {
    country: 'US',
    city: 'Lipan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hood',
    latitude: 32.5072,
    longitude: -97.9536,
    postal_code: '76462'
  },
  '76463': {
    country: 'US',
    city: 'Mingus',
    state: 'Texas',
    state_short: 'TX',
    county: 'Palo Pinto',
    latitude: 32.5381,
    longitude: -98.4219,
    postal_code: '76463'
  },
  '76464': {
    country: 'US',
    city: 'Moran',
    state: 'Texas',
    state_short: 'TX',
    county: 'Shackelford',
    latitude: 32.5549,
    longitude: -99.1656,
    postal_code: '76464'
  },
  '76465': {
    country: 'US',
    city: 'Morgan Mill',
    state: 'Texas',
    state_short: 'TX',
    county: 'Erath',
    latitude: 32.3882,
    longitude: -98.1673,
    postal_code: '76465'
  },
  '76466': {
    country: 'US',
    city: 'Olden',
    state: 'Texas',
    state_short: 'TX',
    county: 'Eastland',
    latitude: 32.4421,
    longitude: -98.7342,
    postal_code: '76466'
  },
  '76467': {
    country: 'US',
    city: 'Paluxy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hood',
    latitude: 32.2707,
    longitude: -97.9075,
    postal_code: '76467'
  },
  '76468': {
    country: 'US',
    city: 'Proctor',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comanche',
    latitude: 31.9874,
    longitude: -98.4298,
    postal_code: '76468'
  },
  '76469': {
    country: 'US',
    city: 'Putnam',
    state: 'Texas',
    state_short: 'TX',
    county: 'Callahan',
    latitude: 32.374,
    longitude: -99.1957,
    postal_code: '76469'
  },
  '76470': {
    country: 'US',
    city: 'Ranger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Eastland',
    latitude: 32.4681,
    longitude: -98.6747,
    postal_code: '76470'
  },
  '76471': {
    country: 'US',
    city: 'Rising Star',
    state: 'Texas',
    state_short: 'TX',
    county: 'Eastland',
    latitude: 32.128,
    longitude: -98.9859,
    postal_code: '76471'
  },
  '76472': {
    country: 'US',
    city: 'Santo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Palo Pinto',
    latitude: 32.5979,
    longitude: -98.1797,
    postal_code: '76472'
  },
  '76474': {
    country: 'US',
    city: 'Sidney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comanche',
    latitude: 31.932,
    longitude: -98.768,
    postal_code: '76474'
  },
  '76475': {
    country: 'US',
    city: 'Strawn',
    state: 'Texas',
    state_short: 'TX',
    county: 'Palo Pinto',
    latitude: 32.5945,
    longitude: -98.4995,
    postal_code: '76475'
  },
  '76476': {
    country: 'US',
    city: 'Tolar',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hood',
    latitude: 32.3772,
    longitude: -97.8802,
    postal_code: '76476'
  },
  '76481': {
    country: 'US',
    city: 'South Bend',
    state: 'Texas',
    state_short: 'TX',
    county: 'Young',
    latitude: 33.0052,
    longitude: -98.6892,
    postal_code: '76481'
  },
  '76483': {
    country: 'US',
    city: 'Throckmorton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Throckmorton',
    latitude: 33.1794,
    longitude: -99.1838,
    postal_code: '76483'
  },
  '76484': {
    country: 'US',
    city: 'Palo Pinto',
    state: 'Texas',
    state_short: 'TX',
    county: 'Palo Pinto',
    latitude: 32.7673,
    longitude: -98.2987,
    postal_code: '76484'
  },
  '76485': {
    country: 'US',
    city: 'Peaster',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.8721,
    longitude: -97.8667,
    postal_code: '76485'
  },
  '76486': {
    country: 'US',
    city: 'Perrin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jack',
    latitude: 33.034,
    longitude: -98.0692,
    postal_code: '76486'
  },
  '76487': {
    country: 'US',
    city: 'Poolville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.968,
    longitude: -97.8472,
    postal_code: '76487'
  },
  '76490': {
    country: 'US',
    city: 'Whitt',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parker',
    latitude: 32.9555,
    longitude: -98.021,
    postal_code: '76490'
  },
  '76491': {
    country: 'US',
    city: 'Woodson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Throckmorton',
    latitude: 33.0741,
    longitude: -99.0546,
    postal_code: '76491'
  },
  '76501': {
    country: 'US',
    city: 'Temple',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.0895,
    longitude: -97.3343,
    postal_code: '76501'
  },
  '76502': {
    country: 'US',
    city: 'Temple',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.071,
    longitude: -97.3898,
    postal_code: '76502'
  },
  '76503': {
    country: 'US',
    city: 'Temple',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.054,
    longitude: -97.3203,
    postal_code: '76503'
  },
  '76504': {
    country: 'US',
    city: 'Temple',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.0917,
    longitude: -97.3648,
    postal_code: '76504'
  },
  '76508': {
    country: 'US',
    city: 'Temple',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.0363,
    longitude: -97.492,
    postal_code: '76508'
  },
  '76511': {
    country: 'US',
    city: 'Bartlett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 30.7991,
    longitude: -97.4263,
    postal_code: '76511'
  },
  '76513': {
    country: 'US',
    city: 'Belton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.0723,
    longitude: -97.472,
    postal_code: '76513'
  },
  '76518': {
    country: 'US',
    city: 'Buckholts',
    state: 'Texas',
    state_short: 'TX',
    county: 'Milam',
    latitude: 30.8858,
    longitude: -97.1241,
    postal_code: '76518'
  },
  '76519': {
    country: 'US',
    city: 'Burlington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Milam',
    latitude: 30.9736,
    longitude: -96.9642,
    postal_code: '76519'
  },
  '76520': {
    country: 'US',
    city: 'Cameron',
    state: 'Texas',
    state_short: 'TX',
    county: 'Milam',
    latitude: 30.8527,
    longitude: -96.9766,
    postal_code: '76520'
  },
  '76522': {
    country: 'US',
    city: 'Copperas Cove',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.2029,
    longitude: -97.9301,
    postal_code: '76522'
  },
  '76523': {
    country: 'US',
    city: 'Davilla',
    state: 'Texas',
    state_short: 'TX',
    county: 'Milam',
    latitude: 30.7857,
    longitude: -97.275,
    postal_code: '76523'
  },
  '76524': {
    country: 'US',
    city: 'Eddy',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.3088,
    longitude: -97.2774,
    postal_code: '76524'
  },
  '76525': {
    country: 'US',
    city: 'Evant',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.4388,
    longitude: -98.0535,
    postal_code: '76525'
  },
  '76526': {
    country: 'US',
    city: 'Flat',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.2956,
    longitude: -97.5822,
    postal_code: '76526'
  },
  '76527': {
    country: 'US',
    city: 'Florence',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.8076,
    longitude: -97.7812,
    postal_code: '76527'
  },
  '76528': {
    country: 'US',
    city: 'Gatesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.4177,
    longitude: -97.833,
    postal_code: '76528'
  },
  '76530': {
    country: 'US',
    city: 'Granger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.7398,
    longitude: -97.4451,
    postal_code: '76530'
  },
  '76531': {
    country: 'US',
    city: 'Hamilton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hamilton',
    latitude: 31.6781,
    longitude: -98.1131,
    postal_code: '76531'
  },
  '76533': {
    country: 'US',
    city: 'Heidenheimer',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.0496,
    longitude: -97.4936,
    postal_code: '76533'
  },
  '76534': {
    country: 'US',
    city: 'Holland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 30.88,
    longitude: -97.3857,
    postal_code: '76534'
  },
  '76537': {
    country: 'US',
    city: 'Jarrell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.8119,
    longitude: -97.5942,
    postal_code: '76537'
  },
  '76538': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.5599,
    longitude: -97.8456,
    postal_code: '76538'
  },
  '76539': {
    country: 'US',
    city: 'Kempner',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lampasas',
    latitude: 31.0887,
    longitude: -98.0099,
    postal_code: '76539'
  },
  '76540': {
    country: 'US',
    city: 'Killeen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.1171,
    longitude: -97.7278,
    postal_code: '76540'
  },
  '76541': {
    country: 'US',
    city: 'Killeen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.1164,
    longitude: -97.7278,
    postal_code: '76541'
  },
  '76542': {
    country: 'US',
    city: 'Killeen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.0376,
    longitude: -97.6809,
    postal_code: '76542'
  },
  '76543': {
    country: 'US',
    city: 'Killeen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.1172,
    longitude: -97.6651,
    postal_code: '76543'
  },
  '76544': {
    country: 'US',
    city: 'Fort Hood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.1349,
    longitude: -97.7756,
    postal_code: '76544'
  },
  '76547': {
    country: 'US',
    city: 'Killeen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.1171,
    longitude: -97.7278,
    postal_code: '76547'
  },
  '76548': {
    country: 'US',
    city: 'Harker Heights',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.0286,
    longitude: -97.6115,
    postal_code: '76548'
  },
  '76549': {
    country: 'US',
    city: 'Killeen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.0065,
    longitude: -97.841,
    postal_code: '76549'
  },
  '76550': {
    country: 'US',
    city: 'Lampasas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lampasas',
    latitude: 31.068,
    longitude: -98.1834,
    postal_code: '76550'
  },
  '76554': {
    country: 'US',
    city: 'Little River Academy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 30.9863,
    longitude: -97.3586,
    postal_code: '76554'
  },
  '76556': {
    country: 'US',
    city: 'Milano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Milam',
    latitude: 30.7304,
    longitude: -96.8807,
    postal_code: '76556'
  },
  '76557': {
    country: 'US',
    city: 'Moody',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.2533,
    longitude: -97.41,
    postal_code: '76557'
  },
  '76558': {
    country: 'US',
    city: 'Mound',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.3514,
    longitude: -97.6444,
    postal_code: '76558'
  },
  '76559': {
    country: 'US',
    city: 'Nolanville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.0833,
    longitude: -97.5941,
    postal_code: '76559'
  },
  '76561': {
    country: 'US',
    city: 'Oglesby',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.4438,
    longitude: -97.5501,
    postal_code: '76561'
  },
  '76564': {
    country: 'US',
    city: 'Pendleton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.1952,
    longitude: -97.3486,
    postal_code: '76564'
  },
  '76565': {
    country: 'US',
    city: 'Pottsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hamilton',
    latitude: 31.6732,
    longitude: -98.3256,
    postal_code: '76565'
  },
  '76566': {
    country: 'US',
    city: 'Purmela',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.4841,
    longitude: -97.9903,
    postal_code: '76566'
  },
  '76567': {
    country: 'US',
    city: 'Rockdale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Milam',
    latitude: 30.6583,
    longitude: -97.0079,
    postal_code: '76567'
  },
  '76569': {
    country: 'US',
    city: 'Rogers',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 30.955,
    longitude: -97.2228,
    postal_code: '76569'
  },
  '76570': {
    country: 'US',
    city: 'Rosebud',
    state: 'Texas',
    state_short: 'TX',
    county: 'Falls',
    latitude: 31.0922,
    longitude: -96.9755,
    postal_code: '76570'
  },
  '76571': {
    country: 'US',
    city: 'Salado',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 30.9494,
    longitude: -97.533,
    postal_code: '76571'
  },
  '76573': {
    country: 'US',
    city: 'Schwertner',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.8155,
    longitude: -97.5142,
    postal_code: '76573'
  },
  '76574': {
    country: 'US',
    city: 'Taylor',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.5708,
    longitude: -97.4094,
    postal_code: '76574'
  },
  '76577': {
    country: 'US',
    city: 'Thorndale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Milam',
    latitude: 30.6082,
    longitude: -97.1764,
    postal_code: '76577'
  },
  '76578': {
    country: 'US',
    city: 'Thrall',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.592,
    longitude: -97.2893,
    postal_code: '76578'
  },
  '76579': {
    country: 'US',
    city: 'Troy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bell',
    latitude: 31.1759,
    longitude: -97.2852,
    postal_code: '76579'
  },
  '76596': {
    country: 'US',
    city: 'Gatesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.3902,
    longitude: -97.7993,
    postal_code: '76596'
  },
  '76597': {
    country: 'US',
    city: 'Gatesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.3902,
    longitude: -97.7993,
    postal_code: '76597'
  },
  '76598': {
    country: 'US',
    city: 'Gatesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.3902,
    longitude: -97.7993,
    postal_code: '76598'
  },
  '76599': {
    country: 'US',
    city: 'Gatesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coryell',
    latitude: 31.4706,
    longitude: -97.7347,
    postal_code: '76599'
  },
  '76621': {
    country: 'US',
    city: 'Abbott',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 31.8916,
    longitude: -97.0671,
    postal_code: '76621'
  },
  '76622': {
    country: 'US',
    city: 'Aquilla',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 31.8589,
    longitude: -97.2258,
    postal_code: '76622'
  },
  '76623': {
    country: 'US',
    city: 'Avalon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.2054,
    longitude: -96.79,
    postal_code: '76623'
  },
  '76624': {
    country: 'US',
    city: 'Axtell',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.661,
    longitude: -96.9882,
    postal_code: '76624'
  },
  '76626': {
    country: 'US',
    city: 'Blooming Grove',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 32.0758,
    longitude: -96.701,
    postal_code: '76626'
  },
  '76627': {
    country: 'US',
    city: 'Blum',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 32.1052,
    longitude: -97.3652,
    postal_code: '76627'
  },
  '76628': {
    country: 'US',
    city: 'Brandon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 32.0457,
    longitude: -96.9606,
    postal_code: '76628'
  },
  '76629': {
    country: 'US',
    city: 'Bremond',
    state: 'Texas',
    state_short: 'TX',
    county: 'Robertson',
    latitude: 31.156,
    longitude: -96.6697,
    postal_code: '76629'
  },
  '76630': {
    country: 'US',
    city: 'Bruceville',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.3267,
    longitude: -97.2342,
    postal_code: '76630'
  },
  '76631': {
    country: 'US',
    city: 'Bynum',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 31.9907,
    longitude: -96.9837,
    postal_code: '76631'
  },
  '76632': {
    country: 'US',
    city: 'Chilton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Falls',
    latitude: 31.31,
    longitude: -97.09,
    postal_code: '76632'
  },
  '76633': {
    country: 'US',
    city: 'China Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.6673,
    longitude: -97.3002,
    postal_code: '76633'
  },
  '76634': {
    country: 'US',
    city: 'Clifton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bosque',
    latitude: 31.7918,
    longitude: -97.5203,
    postal_code: '76634'
  },
  '76635': {
    country: 'US',
    city: 'Coolidge',
    state: 'Texas',
    state_short: 'TX',
    county: 'Limestone',
    latitude: 31.7438,
    longitude: -96.6577,
    postal_code: '76635'
  },
  '76636': {
    country: 'US',
    city: 'Covington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 32.1595,
    longitude: -97.2591,
    postal_code: '76636'
  },
  '76637': {
    country: 'US',
    city: 'Cranfills Gap',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bosque',
    latitude: 31.7486,
    longitude: -97.8113,
    postal_code: '76637'
  },
  '76638': {
    country: 'US',
    city: 'Crawford',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5598,
    longitude: -97.39,
    postal_code: '76638'
  },
  '76639': {
    country: 'US',
    city: 'Dawson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 31.8974,
    longitude: -96.7085,
    postal_code: '76639'
  },
  '76640': {
    country: 'US',
    city: 'Elm Mott',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.6725,
    longitude: -97.1138,
    postal_code: '76640'
  },
  '76641': {
    country: 'US',
    city: 'Frost',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 32.0275,
    longitude: -96.7684,
    postal_code: '76641'
  },
  '76642': {
    country: 'US',
    city: 'Groesbeck',
    state: 'Texas',
    state_short: 'TX',
    county: 'Limestone',
    latitude: 31.5357,
    longitude: -96.5234,
    postal_code: '76642'
  },
  '76643': {
    country: 'US',
    city: 'Hewitt',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.4582,
    longitude: -97.1966,
    postal_code: '76643'
  },
  '76644': {
    country: 'US',
    city: 'Laguna Park',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bosque',
    latitude: 31.8593,
    longitude: -97.3797,
    postal_code: '76644'
  },
  '76645': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 32.0149,
    longitude: -97.1198,
    postal_code: '76645'
  },
  '76648': {
    country: 'US',
    city: 'Hubbard',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 31.8436,
    longitude: -96.8,
    postal_code: '76648'
  },
  '76649': {
    country: 'US',
    city: 'Iredell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bosque',
    latitude: 31.9722,
    longitude: -97.8793,
    postal_code: '76649'
  },
  '76650': {
    country: 'US',
    city: 'Irene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 31.9921,
    longitude: -96.8714,
    postal_code: '76650'
  },
  '76651': {
    country: 'US',
    city: 'Italy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.1785,
    longitude: -96.8823,
    postal_code: '76651'
  },
  '76652': {
    country: 'US',
    city: 'Kopperl',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bosque',
    latitude: 32.1035,
    longitude: -97.5421,
    postal_code: '76652'
  },
  '76653': {
    country: 'US',
    city: 'Kosse',
    state: 'Texas',
    state_short: 'TX',
    county: 'Limestone',
    latitude: 31.3147,
    longitude: -96.6195,
    postal_code: '76653'
  },
  '76654': {
    country: 'US',
    city: 'Leroy',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.7316,
    longitude: -97.0178,
    postal_code: '76654'
  },
  '76655': {
    country: 'US',
    city: 'Lorena',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.4093,
    longitude: -97.2302,
    postal_code: '76655'
  },
  '76656': {
    country: 'US',
    city: 'Lott',
    state: 'Texas',
    state_short: 'TX',
    county: 'Falls',
    latitude: 31.1925,
    longitude: -97.0581,
    postal_code: '76656'
  },
  '76657': {
    country: 'US',
    city: 'Mc Gregor',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.4431,
    longitude: -97.3943,
    postal_code: '76657'
  },
  '76660': {
    country: 'US',
    city: 'Malone',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 31.924,
    longitude: -96.8907,
    postal_code: '76660'
  },
  '76661': {
    country: 'US',
    city: 'Marlin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Falls',
    latitude: 31.3036,
    longitude: -96.8889,
    postal_code: '76661'
  },
  '76664': {
    country: 'US',
    city: 'Mart',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5458,
    longitude: -96.8381,
    postal_code: '76664'
  },
  '76665': {
    country: 'US',
    city: 'Meridian',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bosque',
    latitude: 31.929,
    longitude: -97.6443,
    postal_code: '76665'
  },
  '76666': {
    country: 'US',
    city: 'Mertens',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 32.0275,
    longitude: -96.8981,
    postal_code: '76666'
  },
  '76667': {
    country: 'US',
    city: 'Mexia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Limestone',
    latitude: 31.6784,
    longitude: -96.4952,
    postal_code: '76667'
  },
  '76670': {
    country: 'US',
    city: 'Milford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ellis',
    latitude: 32.1482,
    longitude: -96.9612,
    postal_code: '76670'
  },
  '76671': {
    country: 'US',
    city: 'Morgan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bosque',
    latitude: 32.0195,
    longitude: -97.5608,
    postal_code: '76671'
  },
  '76673': {
    country: 'US',
    city: 'Mount Calm',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 31.7575,
    longitude: -96.8944,
    postal_code: '76673'
  },
  '76676': {
    country: 'US',
    city: 'Penelope',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 31.8551,
    longitude: -96.9372,
    postal_code: '76676'
  },
  '76678': {
    country: 'US',
    city: 'Prairie Hill',
    state: 'Texas',
    state_short: 'TX',
    county: 'Limestone',
    latitude: 31.6591,
    longitude: -96.8094,
    postal_code: '76678'
  },
  '76679': {
    country: 'US',
    city: 'Purdon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 31.9483,
    longitude: -96.5856,
    postal_code: '76679'
  },
  '76680': {
    country: 'US',
    city: 'Reagan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Falls',
    latitude: 31.1871,
    longitude: -96.7998,
    postal_code: '76680'
  },
  '76681': {
    country: 'US',
    city: 'Richland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Navarro',
    latitude: 31.9018,
    longitude: -96.4373,
    postal_code: '76681'
  },
  '76682': {
    country: 'US',
    city: 'Riesel',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5002,
    longitude: -96.9476,
    postal_code: '76682'
  },
  '76684': {
    country: 'US',
    city: 'Ross',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.7173,
    longitude: -97.1188,
    postal_code: '76684'
  },
  '76685': {
    country: 'US',
    city: 'Satin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Falls',
    latitude: 31.3601,
    longitude: -97.0108,
    postal_code: '76685'
  },
  '76686': {
    country: 'US',
    city: 'Tehuacana',
    state: 'Texas',
    state_short: 'TX',
    county: 'Limestone',
    latitude: 31.7504,
    longitude: -96.5416,
    postal_code: '76686'
  },
  '76687': {
    country: 'US',
    city: 'Thornton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Limestone',
    latitude: 31.4083,
    longitude: -96.5024,
    postal_code: '76687'
  },
  '76689': {
    country: 'US',
    city: 'Valley Mills',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bosque',
    latitude: 31.6599,
    longitude: -97.4935,
    postal_code: '76689'
  },
  '76690': {
    country: 'US',
    city: 'Walnut Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bosque',
    latitude: 32.0593,
    longitude: -97.7514,
    postal_code: '76690'
  },
  '76691': {
    country: 'US',
    city: 'West',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.7754,
    longitude: -97.1258,
    postal_code: '76691'
  },
  '76692': {
    country: 'US',
    city: 'Whitney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hill',
    latitude: 31.9713,
    longitude: -97.3463,
    postal_code: '76692'
  },
  '76693': {
    country: 'US',
    city: 'Wortham',
    state: 'Texas',
    state_short: 'TX',
    county: 'Freestone',
    latitude: 31.7865,
    longitude: -96.4202,
    postal_code: '76693'
  },
  '76701': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5525,
    longitude: -97.1396,
    postal_code: '76701'
  },
  '76702': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5475,
    longitude: -97.1443,
    postal_code: '76702'
  },
  '76703': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5536,
    longitude: -97.2032,
    postal_code: '76703'
  },
  '76704': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5773,
    longitude: -97.1241,
    postal_code: '76704'
  },
  '76705': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.6403,
    longitude: -97.0963,
    postal_code: '76705'
  },
  '76706': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5171,
    longitude: -97.1198,
    postal_code: '76706'
  },
  '76707': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5527,
    longitude: -97.1588,
    postal_code: '76707'
  },
  '76708': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5765,
    longitude: -97.1786,
    postal_code: '76708'
  },
  '76710': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.535,
    longitude: -97.1899,
    postal_code: '76710'
  },
  '76711': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5175,
    longitude: -97.1547,
    postal_code: '76711'
  },
  '76712': {
    country: 'US',
    city: 'Woodway',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5051,
    longitude: -97.2311,
    postal_code: '76712'
  },
  '76714': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5536,
    longitude: -97.2032,
    postal_code: '76714'
  },
  '76715': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5536,
    longitude: -97.2032,
    postal_code: '76715'
  },
  '76716': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5536,
    longitude: -97.2032,
    postal_code: '76716'
  },
  '76797': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5536,
    longitude: -97.2032,
    postal_code: '76797'
  },
  '76798': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.547,
    longitude: -97.1203,
    postal_code: '76798'
  },
  '76799': {
    country: 'US',
    city: 'Waco',
    state: 'Texas',
    state_short: 'TX',
    county: 'McLennan',
    latitude: 31.5411,
    longitude: -97.1615,
    postal_code: '76799'
  },
  '76801': {
    country: 'US',
    city: 'Brownwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brown',
    latitude: 31.7754,
    longitude: -98.9915,
    postal_code: '76801'
  },
  '76802': {
    country: 'US',
    city: 'Early',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brown',
    latitude: 31.7874,
    longitude: -98.9229,
    postal_code: '76802'
  },
  '76803': {
    country: 'US',
    city: 'Early',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brown',
    latitude: 31.7421,
    longitude: -98.9456,
    postal_code: '76803'
  },
  '76804': {
    country: 'US',
    city: 'Brownwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brown',
    latitude: 31.7742,
    longitude: -99.0921,
    postal_code: '76804'
  },
  '76820': {
    country: 'US',
    city: 'Art',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mason',
    latitude: 30.767,
    longitude: -99.0486,
    postal_code: '76820'
  },
  '76821': {
    country: 'US',
    city: 'Ballinger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Runnels',
    latitude: 31.7468,
    longitude: -99.9589,
    postal_code: '76821'
  },
  '76823': {
    country: 'US',
    city: 'Bangs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brown',
    latitude: 31.7503,
    longitude: -99.1568,
    postal_code: '76823'
  },
  '76824': {
    country: 'US',
    city: 'Bend',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lampasas',
    latitude: 31.1123,
    longitude: -98.4821,
    postal_code: '76824'
  },
  '76825': {
    country: 'US',
    city: 'Brady',
    state: 'Texas',
    state_short: 'TX',
    county: 'McCulloch',
    latitude: 31.1509,
    longitude: -99.3372,
    postal_code: '76825'
  },
  '76827': {
    country: 'US',
    city: 'Brookesmith',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brown',
    latitude: 31.5176,
    longitude: -99.1277,
    postal_code: '76827'
  },
  '76828': {
    country: 'US',
    city: 'Burkett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coleman',
    latitude: 31.9986,
    longitude: -99.2553,
    postal_code: '76828'
  },
  '76831': {
    country: 'US',
    city: 'Castell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Llano',
    latitude: 30.701,
    longitude: -98.9564,
    postal_code: '76831'
  },
  '76832': {
    country: 'US',
    city: 'Cherokee',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Saba',
    latitude: 30.9806,
    longitude: -98.6633,
    postal_code: '76832'
  },
  '76834': {
    country: 'US',
    city: 'Coleman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coleman',
    latitude: 31.8287,
    longitude: -99.427,
    postal_code: '76834'
  },
  '76836': {
    country: 'US',
    city: 'Doole',
    state: 'Texas',
    state_short: 'TX',
    county: 'McCulloch',
    latitude: 31.3957,
    longitude: -99.599,
    postal_code: '76836'
  },
  '76837': {
    country: 'US',
    city: 'Eden',
    state: 'Texas',
    state_short: 'TX',
    county: 'Concho',
    latitude: 31.2192,
    longitude: -99.8407,
    postal_code: '76837'
  },
  '76841': {
    country: 'US',
    city: 'Fort Mc Kavett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Menard',
    latitude: 30.829,
    longitude: -100.0809,
    postal_code: '76841'
  },
  '76842': {
    country: 'US',
    city: 'Fredonia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mason',
    latitude: 30.9214,
    longitude: -99.1216,
    postal_code: '76842'
  },
  '76844': {
    country: 'US',
    city: 'Goldthwaite',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mills',
    latitude: 31.4458,
    longitude: -98.5744,
    postal_code: '76844'
  },
  '76845': {
    country: 'US',
    city: 'Gouldbusk',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coleman',
    latitude: 31.5549,
    longitude: -99.4767,
    postal_code: '76845'
  },
  '76848': {
    country: 'US',
    city: 'Hext',
    state: 'Texas',
    state_short: 'TX',
    county: 'Menard',
    latitude: 30.8816,
    longitude: -99.554,
    postal_code: '76848'
  },
  '76849': {
    country: 'US',
    city: 'Junction',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kimble',
    latitude: 30.4754,
    longitude: -99.7473,
    postal_code: '76849'
  },
  '76852': {
    country: 'US',
    city: 'Lohn',
    state: 'Texas',
    state_short: 'TX',
    county: 'McCulloch',
    latitude: 31.3173,
    longitude: -99.3833,
    postal_code: '76852'
  },
  '76853': {
    country: 'US',
    city: 'Lometa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lampasas',
    latitude: 31.2167,
    longitude: -98.4006,
    postal_code: '76853'
  },
  '76854': {
    country: 'US',
    city: 'London',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kimble',
    latitude: 30.6768,
    longitude: -99.5765,
    postal_code: '76854'
  },
  '76855': {
    country: 'US',
    city: 'Lowake',
    state: 'Texas',
    state_short: 'TX',
    county: 'Concho',
    latitude: 31.5663,
    longitude: -100.0759,
    postal_code: '76855'
  },
  '76856': {
    country: 'US',
    city: 'Mason',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mason',
    latitude: 30.7434,
    longitude: -99.2261,
    postal_code: '76856'
  },
  '76857': {
    country: 'US',
    city: 'May',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brown',
    latitude: 31.9571,
    longitude: -98.9656,
    postal_code: '76857'
  },
  '76858': {
    country: 'US',
    city: 'Melvin',
    state: 'Texas',
    state_short: 'TX',
    county: 'McCulloch',
    latitude: 31.1952,
    longitude: -99.5798,
    postal_code: '76858'
  },
  '76859': {
    country: 'US',
    city: 'Menard',
    state: 'Texas',
    state_short: 'TX',
    county: 'Menard',
    latitude: 30.9119,
    longitude: -99.7847,
    postal_code: '76859'
  },
  '76861': {
    country: 'US',
    city: 'Miles',
    state: 'Texas',
    state_short: 'TX',
    county: 'Runnels',
    latitude: 31.6121,
    longitude: -100.1823,
    postal_code: '76861'
  },
  '76862': {
    country: 'US',
    city: 'Millersview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Concho',
    latitude: 31.4167,
    longitude: -99.7171,
    postal_code: '76862'
  },
  '76864': {
    country: 'US',
    city: 'Mullin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mills',
    latitude: 31.5748,
    longitude: -98.6635,
    postal_code: '76864'
  },
  '76865': {
    country: 'US',
    city: 'Norton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Runnels',
    latitude: 31.8795,
    longitude: -100.1315,
    postal_code: '76865'
  },
  '76866': {
    country: 'US',
    city: 'Paint Rock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Concho',
    latitude: 31.5048,
    longitude: -99.9139,
    postal_code: '76866'
  },
  '76869': {
    country: 'US',
    city: 'Pontotoc',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mason',
    latitude: 30.8906,
    longitude: -99.0212,
    postal_code: '76869'
  },
  '76870': {
    country: 'US',
    city: 'Priddy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mills',
    latitude: 31.641,
    longitude: -98.5276,
    postal_code: '76870'
  },
  '76871': {
    country: 'US',
    city: 'Richland Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Saba',
    latitude: 31.3003,
    longitude: -98.9128,
    postal_code: '76871'
  },
  '76872': {
    country: 'US',
    city: 'Rochelle',
    state: 'Texas',
    state_short: 'TX',
    county: 'McCulloch',
    latitude: 31.3,
    longitude: -99.1572,
    postal_code: '76872'
  },
  '76873': {
    country: 'US',
    city: 'Rockwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coleman',
    latitude: 31.5037,
    longitude: -99.3746,
    postal_code: '76873'
  },
  '76874': {
    country: 'US',
    city: 'Roosevelt',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kimble',
    latitude: 30.47,
    longitude: -100.0901,
    postal_code: '76874'
  },
  '76875': {
    country: 'US',
    city: 'Rowena',
    state: 'Texas',
    state_short: 'TX',
    county: 'Runnels',
    latitude: 31.6436,
    longitude: -100.0191,
    postal_code: '76875'
  },
  '76877': {
    country: 'US',
    city: 'San Saba',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Saba',
    latitude: 31.1627,
    longitude: -98.7309,
    postal_code: '76877'
  },
  '76878': {
    country: 'US',
    city: 'Santa Anna',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coleman',
    latitude: 31.7215,
    longitude: -99.3212,
    postal_code: '76878'
  },
  '76880': {
    country: 'US',
    city: 'Star',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mills',
    latitude: 31.4685,
    longitude: -98.3161,
    postal_code: '76880'
  },
  '76882': {
    country: 'US',
    city: 'Talpa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coleman',
    latitude: 31.8034,
    longitude: -99.6747,
    postal_code: '76882'
  },
  '76883': {
    country: 'US',
    city: 'Telegraph',
    state: 'Texas',
    state_short: 'TX',
    county: 'Edwards',
    latitude: 30.3274,
    longitude: -99.9062,
    postal_code: '76883'
  },
  '76884': {
    country: 'US',
    city: 'Valera',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coleman',
    latitude: 31.7529,
    longitude: -99.5473,
    postal_code: '76884'
  },
  '76885': {
    country: 'US',
    city: 'Valley Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Llano',
    latitude: 30.866,
    longitude: -98.8357,
    postal_code: '76885'
  },
  '76886': {
    country: 'US',
    city: 'Veribest',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.4766,
    longitude: -100.2595,
    postal_code: '76886'
  },
  '76887': {
    country: 'US',
    city: 'Voca',
    state: 'Texas',
    state_short: 'TX',
    county: 'McCulloch',
    latitude: 30.9959,
    longitude: -99.1682,
    postal_code: '76887'
  },
  '76888': {
    country: 'US',
    city: 'Voss',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coleman',
    latitude: 31.5892,
    longitude: -99.6326,
    postal_code: '76888'
  },
  '76890': {
    country: 'US',
    city: 'Zephyr',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brown',
    latitude: 31.6694,
    longitude: -98.8182,
    postal_code: '76890'
  },
  '76901': {
    country: 'US',
    city: 'San Angelo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.4782,
    longitude: -100.4818,
    postal_code: '76901'
  },
  '76902': {
    country: 'US',
    city: 'San Angelo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.4638,
    longitude: -100.437,
    postal_code: '76902'
  },
  '76903': {
    country: 'US',
    city: 'San Angelo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.4707,
    longitude: -100.4386,
    postal_code: '76903'
  },
  '76904': {
    country: 'US',
    city: 'San Angelo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.4194,
    longitude: -100.48,
    postal_code: '76904'
  },
  '76905': {
    country: 'US',
    city: 'San Angelo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.4647,
    longitude: -100.39,
    postal_code: '76905'
  },
  '76906': {
    country: 'US',
    city: 'San Angelo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.4638,
    longitude: -100.437,
    postal_code: '76906'
  },
  '76908': {
    country: 'US',
    city: 'Goodfellow Afb',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.4321,
    longitude: -100.4022,
    postal_code: '76908'
  },
  '76909': {
    country: 'US',
    city: 'San Angelo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.4638,
    longitude: -100.437,
    postal_code: '76909'
  },
  '76930': {
    country: 'US',
    city: 'Barnhart',
    state: 'Texas',
    state_short: 'TX',
    county: 'Irion',
    latitude: 31.1596,
    longitude: -101.1918,
    postal_code: '76930'
  },
  '76932': {
    country: 'US',
    city: 'Big Lake',
    state: 'Texas',
    state_short: 'TX',
    county: 'Reagan',
    latitude: 31.417,
    longitude: -101.5423,
    postal_code: '76932'
  },
  '76933': {
    country: 'US',
    city: 'Bronte',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coke',
    latitude: 31.8789,
    longitude: -100.2988,
    postal_code: '76933'
  },
  '76934': {
    country: 'US',
    city: 'Carlsbad',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.6266,
    longitude: -100.6694,
    postal_code: '76934'
  },
  '76935': {
    country: 'US',
    city: 'Christoval',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.2346,
    longitude: -100.5203,
    postal_code: '76935'
  },
  '76936': {
    country: 'US',
    city: 'Eldorado',
    state: 'Texas',
    state_short: 'TX',
    county: 'Schleicher',
    latitude: 30.8667,
    longitude: -100.5889,
    postal_code: '76936'
  },
  '76937': {
    country: 'US',
    city: 'Eola',
    state: 'Texas',
    state_short: 'TX',
    county: 'Concho',
    latitude: 31.3617,
    longitude: -100.0922,
    postal_code: '76937'
  },
  '76939': {
    country: 'US',
    city: 'Knickerbocker',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.2501,
    longitude: -100.592,
    postal_code: '76939'
  },
  '76940': {
    country: 'US',
    city: 'Mereta',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.4518,
    longitude: -100.1349,
    postal_code: '76940'
  },
  '76941': {
    country: 'US',
    city: 'Mertzon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Irion',
    latitude: 31.2829,
    longitude: -100.8221,
    postal_code: '76941'
  },
  '76943': {
    country: 'US',
    city: 'Ozona',
    state: 'Texas',
    state_short: 'TX',
    county: 'Crockett',
    latitude: 30.7164,
    longitude: -101.2388,
    postal_code: '76943'
  },
  '76945': {
    country: 'US',
    city: 'Robert Lee',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coke',
    latitude: 31.8951,
    longitude: -100.5104,
    postal_code: '76945'
  },
  '76949': {
    country: 'US',
    city: 'Silver',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coke',
    latitude: 32.0484,
    longitude: -100.6922,
    postal_code: '76949'
  },
  '76950': {
    country: 'US',
    city: 'Sonora',
    state: 'Texas',
    state_short: 'TX',
    county: 'Sutton',
    latitude: 30.5558,
    longitude: -100.6307,
    postal_code: '76950'
  },
  '76951': {
    country: 'US',
    city: 'Sterling City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Sterling',
    latitude: 31.8351,
    longitude: -101.0017,
    postal_code: '76951'
  },
  '76953': {
    country: 'US',
    city: 'Tennyson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coke',
    latitude: 31.7397,
    longitude: -100.2884,
    postal_code: '76953'
  },
  '76955': {
    country: 'US',
    city: 'Vancourt',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.3132,
    longitude: -100.1328,
    postal_code: '76955'
  },
  '76957': {
    country: 'US',
    city: 'Wall',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.3741,
    longitude: -100.3076,
    postal_code: '76957'
  },
  '76958': {
    country: 'US',
    city: 'Water Valley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tom Green',
    latitude: 31.6453,
    longitude: -100.716,
    postal_code: '76958'
  },
  '77001': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8131,
    longitude: -95.3098,
    postal_code: '77001'
  },
  '77002': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7594,
    longitude: -95.3594,
    postal_code: '77002'
  },
  '77003': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7489,
    longitude: -95.3391,
    postal_code: '77003'
  },
  '77004': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7247,
    longitude: -95.3625,
    postal_code: '77004'
  },
  '77005': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7179,
    longitude: -95.4263,
    postal_code: '77005'
  },
  '77006': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7409,
    longitude: -95.3923,
    postal_code: '77006'
  },
  '77007': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7736,
    longitude: -95.4034,
    postal_code: '77007'
  },
  '77008': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7991,
    longitude: -95.4118,
    postal_code: '77008'
  },
  '77009': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7936,
    longitude: -95.3675,
    postal_code: '77009'
  },
  '77010': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7543,
    longitude: -95.3609,
    postal_code: '77010'
  },
  '77011': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.742,
    longitude: -95.3073,
    postal_code: '77011'
  },
  '77012': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7149,
    longitude: -95.2819,
    postal_code: '77012'
  },
  '77013': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7842,
    longitude: -95.2301,
    postal_code: '77013'
  },
  '77014': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9796,
    longitude: -95.4625,
    postal_code: '77014'
  },
  '77015': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7853,
    longitude: -95.1852,
    postal_code: '77015'
  },
  '77016': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8579,
    longitude: -95.3032,
    postal_code: '77016'
  },
  '77017': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6863,
    longitude: -95.2555,
    postal_code: '77017'
  },
  '77018': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8272,
    longitude: -95.4266,
    postal_code: '77018'
  },
  '77019': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7517,
    longitude: -95.4054,
    postal_code: '77019'
  },
  '77020': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7758,
    longitude: -95.3121,
    postal_code: '77020'
  },
  '77021': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6954,
    longitude: -95.3562,
    postal_code: '77021'
  },
  '77022': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8299,
    longitude: -95.3769,
    postal_code: '77022'
  },
  '77023': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7242,
    longitude: -95.3178,
    postal_code: '77023'
  },
  '77024': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7696,
    longitude: -95.5201,
    postal_code: '77024'
  },
  '77025': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6889,
    longitude: -95.4341,
    postal_code: '77025'
  },
  '77026': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7972,
    longitude: -95.3288,
    postal_code: '77026'
  },
  '77027': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7396,
    longitude: -95.446,
    postal_code: '77027'
  },
  '77028': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8297,
    longitude: -95.2879,
    postal_code: '77028'
  },
  '77029': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.763,
    longitude: -95.2567,
    postal_code: '77029'
  },
  '77030': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7041,
    longitude: -95.401,
    postal_code: '77030'
  },
  '77031': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6581,
    longitude: -95.5413,
    postal_code: '77031'
  },
  '77032': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9368,
    longitude: -95.3299,
    postal_code: '77032'
  },
  '77033': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6686,
    longitude: -95.3382,
    postal_code: '77033'
  },
  '77034': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6364,
    longitude: -95.2216,
    postal_code: '77034'
  },
  '77035': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6518,
    longitude: -95.4854,
    postal_code: '77035'
  },
  '77036': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6984,
    longitude: -95.5405,
    postal_code: '77036'
  },
  '77037': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8892,
    longitude: -95.3935,
    postal_code: '77037'
  },
  '77038': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9196,
    longitude: -95.4386,
    postal_code: '77038'
  },
  '77039': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9067,
    longitude: -95.3334,
    postal_code: '77039'
  },
  '77040': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8744,
    longitude: -95.5278,
    postal_code: '77040'
  },
  '77041': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8602,
    longitude: -95.5817,
    postal_code: '77041'
  },
  '77042': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7404,
    longitude: -95.5589,
    postal_code: '77042'
  },
  '77043': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8052,
    longitude: -95.5607,
    postal_code: '77043'
  },
  '77044': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8635,
    longitude: -95.1976,
    postal_code: '77044'
  },
  '77045': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6297,
    longitude: -95.4382,
    postal_code: '77045'
  },
  '77046': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.733,
    longitude: -95.4306,
    postal_code: '77046'
  },
  '77047': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6254,
    longitude: -95.375,
    postal_code: '77047'
  },
  '77048': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6321,
    longitude: -95.3416,
    postal_code: '77048'
  },
  '77049': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8235,
    longitude: -95.1848,
    postal_code: '77049'
  },
  '77050': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9015,
    longitude: -95.2848,
    postal_code: '77050'
  },
  '77051': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6579,
    longitude: -95.3688,
    postal_code: '77051'
  },
  '77052': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7633,
    longitude: -95.3633,
    postal_code: '77052'
  },
  '77053': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.5962,
    longitude: -95.4587,
    postal_code: '77053'
  },
  '77054': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6852,
    longitude: -95.4017,
    postal_code: '77054'
  },
  '77055': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7971,
    longitude: -95.4958,
    postal_code: '77055'
  },
  '77056': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7446,
    longitude: -95.4683,
    postal_code: '77056'
  },
  '77057': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7422,
    longitude: -95.4903,
    postal_code: '77057'
  },
  '77058': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.5716,
    longitude: -95.0998,
    postal_code: '77058'
  },
  '77059': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.5975,
    longitude: -95.1134,
    postal_code: '77059'
  },
  '77060': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9335,
    longitude: -95.3981,
    postal_code: '77060'
  },
  '77061': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6652,
    longitude: -95.279,
    postal_code: '77061'
  },
  '77062': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.5721,
    longitude: -95.1303,
    postal_code: '77062'
  },
  '77063': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7348,
    longitude: -95.522,
    postal_code: '77063'
  },
  '77064': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.919,
    longitude: -95.5569,
    postal_code: '77064'
  },
  '77065': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9319,
    longitude: -95.6106,
    postal_code: '77065'
  },
  '77066': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.961,
    longitude: -95.4947,
    postal_code: '77066'
  },
  '77067': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9547,
    longitude: -95.4522,
    postal_code: '77067'
  },
  '77068': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0069,
    longitude: -95.4897,
    postal_code: '77068'
  },
  '77069': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9863,
    longitude: -95.5208,
    postal_code: '77069'
  },
  '77070': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9781,
    longitude: -95.5803,
    postal_code: '77070'
  },
  '77071': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6518,
    longitude: -95.5176,
    postal_code: '77071'
  },
  '77072': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.699,
    longitude: -95.5862,
    postal_code: '77072'
  },
  '77073': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0198,
    longitude: -95.4087,
    postal_code: '77073'
  },
  '77074': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6896,
    longitude: -95.5106,
    postal_code: '77074'
  },
  '77075': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6223,
    longitude: -95.26,
    postal_code: '77075'
  },
  '77076': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.858,
    longitude: -95.3834,
    postal_code: '77076'
  },
  '77077': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7477,
    longitude: -95.603,
    postal_code: '77077'
  },
  '77078': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8497,
    longitude: -95.2582,
    postal_code: '77078'
  },
  '77079': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7738,
    longitude: -95.598,
    postal_code: '77079'
  },
  '77080': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8159,
    longitude: -95.523,
    postal_code: '77080'
  },
  '77081': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7119,
    longitude: -95.4845,
    postal_code: '77081'
  },
  '77082': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7223,
    longitude: -95.6285,
    postal_code: '77082'
  },
  '77083': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6947,
    longitude: -95.6511,
    postal_code: '77083'
  },
  '77084': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.844,
    longitude: -95.6623,
    postal_code: '77084'
  },
  '77085': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6218,
    longitude: -95.4819,
    postal_code: '77085'
  },
  '77086': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9227,
    longitude: -95.4939,
    postal_code: '77086'
  },
  '77087': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6876,
    longitude: -95.3011,
    postal_code: '77087'
  },
  '77088': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8817,
    longitude: -95.4539,
    postal_code: '77088'
  },
  '77089': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.594,
    longitude: -95.2218,
    postal_code: '77089'
  },
  '77090': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0167,
    longitude: -95.447,
    postal_code: '77090'
  },
  '77091': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8534,
    longitude: -95.4435,
    postal_code: '77091'
  },
  '77092': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8324,
    longitude: -95.472,
    postal_code: '77092'
  },
  '77093': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8617,
    longitude: -95.3403,
    postal_code: '77093'
  },
  '77094': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7705,
    longitude: -95.7107,
    postal_code: '77094'
  },
  '77095': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8941,
    longitude: -95.6481,
    postal_code: '77095'
  },
  '77096': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6722,
    longitude: -95.4861,
    postal_code: '77096'
  },
  '77098': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.735,
    longitude: -95.4118,
    postal_code: '77098'
  },
  '77099': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6709,
    longitude: -95.5866,
    postal_code: '77099'
  },
  '77201': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7633,
    longitude: -95.3633,
    postal_code: '77201'
  },
  '77202': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77202'
  },
  '77203': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7633,
    longitude: -95.3633,
    postal_code: '77203'
  },
  '77204': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77204'
  },
  '77205': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77205'
  },
  '77206': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77206'
  },
  '77207': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77207'
  },
  '77208': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7633,
    longitude: -95.3633,
    postal_code: '77208'
  },
  '77209': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7633,
    longitude: -95.3633,
    postal_code: '77209'
  },
  '77210': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7633,
    longitude: -95.3633,
    postal_code: '77210'
  },
  '77212': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7633,
    longitude: -95.3633,
    postal_code: '77212'
  },
  '77213': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77213'
  },
  '77215': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77215'
  },
  '77216': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77216'
  },
  '77217': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77217'
  },
  '77218': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77218'
  },
  '77219': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77219'
  },
  '77220': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77220'
  },
  '77221': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77221'
  },
  '77222': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77222'
  },
  '77223': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77223'
  },
  '77224': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77224'
  },
  '77225': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77225'
  },
  '77226': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77226'
  },
  '77227': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77227'
  },
  '77228': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77228'
  },
  '77229': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77229'
  },
  '77230': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77230'
  },
  '77231': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77231'
  },
  '77233': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77233'
  },
  '77234': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77234'
  },
  '77235': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77235'
  },
  '77236': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77236'
  },
  '77237': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77237'
  },
  '77238': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77238'
  },
  '77240': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77240'
  },
  '77241': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77241'
  },
  '77242': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77242'
  },
  '77243': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77243'
  },
  '77244': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77244'
  },
  '77245': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77245'
  },
  '77248': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77248'
  },
  '77249': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77249'
  },
  '77251': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77251'
  },
  '77252': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7633,
    longitude: -95.3633,
    postal_code: '77252'
  },
  '77253': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7633,
    longitude: -95.3633,
    postal_code: '77253'
  },
  '77254': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77254'
  },
  '77255': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77255'
  },
  '77256': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77256'
  },
  '77257': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77257'
  },
  '77258': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77258'
  },
  '77259': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77259'
  },
  '77261': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77261'
  },
  '77262': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77262'
  },
  '77263': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77263'
  },
  '77265': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77265'
  },
  '77266': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77266'
  },
  '77267': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77267'
  },
  '77268': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77268'
  },
  '77269': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77269'
  },
  '77270': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77270'
  },
  '77271': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77271'
  },
  '77272': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77272'
  },
  '77273': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77273'
  },
  '77274': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77274'
  },
  '77275': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77275'
  },
  '77277': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77277'
  },
  '77279': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77279'
  },
  '77280': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77280'
  },
  '77282': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77282'
  },
  '77284': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77284'
  },
  '77287': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77287'
  },
  '77288': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77288'
  },
  '77289': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77289'
  },
  '77290': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77290'
  },
  '77291': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77291'
  },
  '77292': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7443,
    longitude: -95.3326,
    postal_code: '77292'
  },
  '77293': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77293'
  },
  '77297': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7633,
    longitude: -95.3633,
    postal_code: '77297'
  },
  '77299': {
    country: 'US',
    city: 'Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77299'
  },
  '77301': {
    country: 'US',
    city: 'Conroe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.3125,
    longitude: -95.4527,
    postal_code: '77301'
  },
  '77302': {
    country: 'US',
    city: 'Conroe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.2238,
    longitude: -95.3577,
    postal_code: '77302'
  },
  '77303': {
    country: 'US',
    city: 'Conroe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.3814,
    longitude: -95.3749,
    postal_code: '77303'
  },
  '77304': {
    country: 'US',
    city: 'Conroe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.3217,
    longitude: -95.5285,
    postal_code: '77304'
  },
  '77305': {
    country: 'US',
    city: 'Conroe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.2906,
    longitude: -95.3832,
    postal_code: '77305'
  },
  '77306': {
    country: 'US',
    city: 'Conroe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.2277,
    longitude: -95.2851,
    postal_code: '77306'
  },
  '77315': {
    country: 'US',
    city: 'North Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77315'
  },
  '77316': {
    country: 'US',
    city: 'Montgomery',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.3587,
    longitude: -95.6857,
    postal_code: '77316'
  },
  '77318': {
    country: 'US',
    city: 'Willis',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.4416,
    longitude: -95.5394,
    postal_code: '77318'
  },
  '77320': {
    country: 'US',
    city: 'Huntsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.847,
    longitude: -95.597,
    postal_code: '77320'
  },
  '77325': {
    country: 'US',
    city: 'Kingwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0569,
    longitude: -95.1835,
    postal_code: '77325'
  },
  '77326': {
    country: 'US',
    city: 'Ace',
    state: 'Texas',
    state_short: 'TX',
    county: 'Polk',
    latitude: 30.5209,
    longitude: -94.8221,
    postal_code: '77326'
  },
  '77327': {
    country: 'US',
    city: 'Cleveland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 30.33,
    longitude: -95.0202,
    postal_code: '77327'
  },
  '77328': {
    country: 'US',
    city: 'Cleveland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 30.3963,
    longitude: -95.194,
    postal_code: '77328'
  },
  '77331': {
    country: 'US',
    city: 'Coldspring',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Jacinto',
    latitude: 30.6027,
    longitude: -95.1086,
    postal_code: '77331'
  },
  '77332': {
    country: 'US',
    city: 'Dallardsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Polk',
    latitude: 30.6285,
    longitude: -94.6319,
    postal_code: '77332'
  },
  '77333': {
    country: 'US',
    city: 'Dobbin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.3365,
    longitude: -95.7723,
    postal_code: '77333'
  },
  '77334': {
    country: 'US',
    city: 'Dodge',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.772,
    longitude: -95.3838,
    postal_code: '77334'
  },
  '77335': {
    country: 'US',
    city: 'Goodrich',
    state: 'Texas',
    state_short: 'TX',
    county: 'Polk',
    latitude: 30.6079,
    longitude: -94.9592,
    postal_code: '77335'
  },
  '77336': {
    country: 'US',
    city: 'Huffman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0565,
    longitude: -95.1051,
    postal_code: '77336'
  },
  '77337': {
    country: 'US',
    city: 'Hufsmith',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.1222,
    longitude: -95.5966,
    postal_code: '77337'
  },
  '77338': {
    country: 'US',
    city: 'Humble',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0041,
    longitude: -95.2825,
    postal_code: '77338'
  },
  '77339': {
    country: 'US',
    city: 'Kingwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0569,
    longitude: -95.1835,
    postal_code: '77339'
  },
  '77340': {
    country: 'US',
    city: 'Huntsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.6448,
    longitude: -95.5798,
    postal_code: '77340'
  },
  '77341': {
    country: 'US',
    city: 'Huntsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.7142,
    longitude: -95.5508,
    postal_code: '77341'
  },
  '77342': {
    country: 'US',
    city: 'Huntsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.7813,
    longitude: -95.5953,
    postal_code: '77342'
  },
  '77343': {
    country: 'US',
    city: 'Huntsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.7235,
    longitude: -95.5508,
    postal_code: '77343'
  },
  '77344': {
    country: 'US',
    city: 'Huntsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.7235,
    longitude: -95.5508,
    postal_code: '77344'
  },
  '77345': {
    country: 'US',
    city: 'Kingwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0336,
    longitude: -95.261,
    postal_code: '77345'
  },
  '77346': {
    country: 'US',
    city: 'Humble',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0042,
    longitude: -95.1728,
    postal_code: '77346'
  },
  '77347': {
    country: 'US',
    city: 'Humble',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9988,
    longitude: -95.2622,
    postal_code: '77347'
  },
  '77348': {
    country: 'US',
    city: 'Huntsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.7235,
    longitude: -95.5508,
    postal_code: '77348'
  },
  '77349': {
    country: 'US',
    city: 'Huntsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.7235,
    longitude: -95.5508,
    postal_code: '77349'
  },
  '77350': {
    country: 'US',
    city: 'Leggett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Polk',
    latitude: 30.8568,
    longitude: -94.8561,
    postal_code: '77350'
  },
  '77351': {
    country: 'US',
    city: 'Livingston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Polk',
    latitude: 30.6829,
    longitude: -94.8976,
    postal_code: '77351'
  },
  '77353': {
    country: 'US',
    city: 'Magnolia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.2094,
    longitude: -95.7508,
    postal_code: '77353'
  },
  '77354': {
    country: 'US',
    city: 'Magnolia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.2333,
    longitude: -95.5502,
    postal_code: '77354'
  },
  '77355': {
    country: 'US',
    city: 'Magnolia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.1598,
    longitude: -95.7402,
    postal_code: '77355'
  },
  '77356': {
    country: 'US',
    city: 'Montgomery',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.4411,
    longitude: -95.7097,
    postal_code: '77356'
  },
  '77357': {
    country: 'US',
    city: 'New Caney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.1579,
    longitude: -95.198,
    postal_code: '77357'
  },
  '77358': {
    country: 'US',
    city: 'New Waverly',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.5354,
    longitude: -95.4532,
    postal_code: '77358'
  },
  '77359': {
    country: 'US',
    city: 'Oakhurst',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Jacinto',
    latitude: 30.7126,
    longitude: -95.3095,
    postal_code: '77359'
  },
  '77360': {
    country: 'US',
    city: 'Onalaska',
    state: 'Texas',
    state_short: 'TX',
    county: 'Polk',
    latitude: 30.8225,
    longitude: -95.1056,
    postal_code: '77360'
  },
  '77362': {
    country: 'US',
    city: 'Pinehurst',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.1581,
    longitude: -95.6814,
    postal_code: '77362'
  },
  '77363': {
    country: 'US',
    city: 'Plantersville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grimes',
    latitude: 30.2969,
    longitude: -95.8498,
    postal_code: '77363'
  },
  '77364': {
    country: 'US',
    city: 'Pointblank',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Jacinto',
    latitude: 30.7593,
    longitude: -95.2295,
    postal_code: '77364'
  },
  '77365': {
    country: 'US',
    city: 'Porter',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.1237,
    longitude: -95.2686,
    postal_code: '77365'
  },
  '77367': {
    country: 'US',
    city: 'Riverside',
    state: 'Texas',
    state_short: 'TX',
    county: 'Walker',
    latitude: 30.853,
    longitude: -95.4036,
    postal_code: '77367'
  },
  '77368': {
    country: 'US',
    city: 'Romayor',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 30.4513,
    longitude: -94.8433,
    postal_code: '77368'
  },
  '77369': {
    country: 'US',
    city: 'Rye',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 30.4596,
    longitude: -94.7436,
    postal_code: '77369'
  },
  '77371': {
    country: 'US',
    city: 'Shepherd',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Jacinto',
    latitude: 30.498,
    longitude: -94.9966,
    postal_code: '77371'
  },
  '77372': {
    country: 'US',
    city: 'Splendora',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.2326,
    longitude: -95.1993,
    postal_code: '77372'
  },
  '77373': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0532,
    longitude: -95.3773,
    postal_code: '77373'
  },
  '77374': {
    country: 'US',
    city: 'Thicket',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardin',
    latitude: 30.3765,
    longitude: -94.6361,
    postal_code: '77374'
  },
  '77375': {
    country: 'US',
    city: 'Tomball',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0739,
    longitude: -95.6201,
    postal_code: '77375'
  },
  '77376': {
    country: 'US',
    city: 'Votaw',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardin',
    latitude: 30.4334,
    longitude: -94.6804,
    postal_code: '77376'
  },
  '77377': {
    country: 'US',
    city: 'Tomball',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0615,
    longitude: -95.6821,
    postal_code: '77377'
  },
  '77378': {
    country: 'US',
    city: 'Willis',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.4441,
    longitude: -95.4506,
    postal_code: '77378'
  },
  '77379': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0377,
    longitude: -95.5326,
    postal_code: '77379'
  },
  '77380': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.1441,
    longitude: -95.4703,
    postal_code: '77380'
  },
  '77381': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.1716,
    longitude: -95.4985,
    postal_code: '77381'
  },
  '77382': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.2106,
    longitude: -95.5257,
    postal_code: '77382'
  },
  '77383': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0799,
    longitude: -95.4172,
    postal_code: '77383'
  },
  '77384': {
    country: 'US',
    city: 'Conroe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.2257,
    longitude: -95.4924,
    postal_code: '77384'
  },
  '77385': {
    country: 'US',
    city: 'Conroe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.1877,
    longitude: -95.4288,
    postal_code: '77385'
  },
  '77386': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.1288,
    longitude: -95.4239,
    postal_code: '77386'
  },
  '77387': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.1199,
    longitude: -95.4247,
    postal_code: '77387'
  },
  '77388': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0505,
    longitude: -95.4695,
    postal_code: '77388'
  },
  '77389': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.1044,
    longitude: -95.5066,
    postal_code: '77389'
  },
  '77391': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0799,
    longitude: -95.4172,
    postal_code: '77391'
  },
  '77393': {
    country: 'US',
    city: 'Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Montgomery',
    latitude: 30.329,
    longitude: -95.4635,
    postal_code: '77393'
  },
  '77396': {
    country: 'US',
    city: 'Humble',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9507,
    longitude: -95.2622,
    postal_code: '77396'
  },
  '77399': {
    country: 'US',
    city: 'Livingston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Polk',
    latitude: 30.8179,
    longitude: -94.8691,
    postal_code: '77399'
  },
  '77401': {
    country: 'US',
    city: 'Bellaire',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7023,
    longitude: -95.4611,
    postal_code: '77401'
  },
  '77402': {
    country: 'US',
    city: 'Bellaire',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.834,
    longitude: -95.4342,
    postal_code: '77402'
  },
  '77404': {
    country: 'US',
    city: 'Bay City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.9828,
    longitude: -95.9694,
    postal_code: '77404'
  },
  '77406': {
    country: 'US',
    city: 'Richmond',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.6436,
    longitude: -95.798,
    postal_code: '77406'
  },
  '77407': {
    country: 'US',
    city: 'Richmond',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.6625,
    longitude: -95.7272,
    postal_code: '77407'
  },
  '77410': {
    country: 'US',
    city: 'Cypress',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9691,
    longitude: -95.6972,
    postal_code: '77410'
  },
  '77411': {
    country: 'US',
    city: 'Alief',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7111,
    longitude: -95.5963,
    postal_code: '77411'
  },
  '77412': {
    country: 'US',
    city: 'Altair',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.5714,
    longitude: -96.4541,
    postal_code: '77412'
  },
  '77413': {
    country: 'US',
    city: 'Barker',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7844,
    longitude: -95.6849,
    postal_code: '77413'
  },
  '77414': {
    country: 'US',
    city: 'Bay City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.8636,
    longitude: -95.9173,
    postal_code: '77414'
  },
  '77415': {
    country: 'US',
    city: 'Cedar Lane',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.9386,
    longitude: -95.7366,
    postal_code: '77415'
  },
  '77417': {
    country: 'US',
    city: 'Beasley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.479,
    longitude: -95.9681,
    postal_code: '77417'
  },
  '77418': {
    country: 'US',
    city: 'Bellville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Austin',
    latitude: 29.9658,
    longitude: -96.2531,
    postal_code: '77418'
  },
  '77419': {
    country: 'US',
    city: 'Blessing',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.8649,
    longitude: -96.218,
    postal_code: '77419'
  },
  '77420': {
    country: 'US',
    city: 'Boling',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.2529,
    longitude: -95.974,
    postal_code: '77420'
  },
  '77422': {
    country: 'US',
    city: 'Brazoria',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.0236,
    longitude: -95.5867,
    postal_code: '77422'
  },
  '77423': {
    country: 'US',
    city: 'Brookshire',
    state: 'Texas',
    state_short: 'TX',
    county: 'Waller',
    latitude: 29.8072,
    longitude: -95.9755,
    postal_code: '77423'
  },
  '77426': {
    country: 'US',
    city: 'Chappell Hill',
    state: 'Texas',
    state_short: 'TX',
    county: 'Washington',
    latitude: 30.1833,
    longitude: -96.2347,
    postal_code: '77426'
  },
  '77428': {
    country: 'US',
    city: 'Collegeport',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.7253,
    longitude: -96.175,
    postal_code: '77428'
  },
  '77429': {
    country: 'US',
    city: 'Cypress',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9766,
    longitude: -95.6358,
    postal_code: '77429'
  },
  '77430': {
    country: 'US',
    city: 'Damon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.3014,
    longitude: -95.7036,
    postal_code: '77430'
  },
  '77431': {
    country: 'US',
    city: 'Danciger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.1737,
    longitude: -95.8207,
    postal_code: '77431'
  },
  '77432': {
    country: 'US',
    city: 'Danevang',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.0575,
    longitude: -96.2075,
    postal_code: '77432'
  },
  '77433': {
    country: 'US',
    city: 'Cypress',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8836,
    longitude: -95.7025,
    postal_code: '77433'
  },
  '77434': {
    country: 'US',
    city: 'Eagle Lake',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.5842,
    longitude: -96.3354,
    postal_code: '77434'
  },
  '77435': {
    country: 'US',
    city: 'East Bernard',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.4705,
    longitude: -96.1211,
    postal_code: '77435'
  },
  '77436': {
    country: 'US',
    city: 'Egypt',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.405,
    longitude: -96.2369,
    postal_code: '77436'
  },
  '77437': {
    country: 'US',
    city: 'El Campo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.2008,
    longitude: -96.2743,
    postal_code: '77437'
  },
  '77440': {
    country: 'US',
    city: 'Elmaton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.8864,
    longitude: -96.14,
    postal_code: '77440'
  },
  '77441': {
    country: 'US',
    city: 'Fulshear',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.7217,
    longitude: -95.8977,
    postal_code: '77441'
  },
  '77442': {
    country: 'US',
    city: 'Garwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.476,
    longitude: -96.4919,
    postal_code: '77442'
  },
  '77443': {
    country: 'US',
    city: 'Glen Flora',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.3475,
    longitude: -96.1933,
    postal_code: '77443'
  },
  '77444': {
    country: 'US',
    city: 'Guy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.3327,
    longitude: -95.7702,
    postal_code: '77444'
  },
  '77445': {
    country: 'US',
    city: 'Hempstead',
    state: 'Texas',
    state_short: 'TX',
    county: 'Waller',
    latitude: 30.092,
    longitude: -96.0716,
    postal_code: '77445'
  },
  '77446': {
    country: 'US',
    city: 'Prairie View',
    state: 'Texas',
    state_short: 'TX',
    county: 'Waller',
    latitude: 30.0836,
    longitude: -95.9866,
    postal_code: '77446'
  },
  '77447': {
    country: 'US',
    city: 'Hockley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 30.0729,
    longitude: -95.8104,
    postal_code: '77447'
  },
  '77448': {
    country: 'US',
    city: 'Hungerford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.4137,
    longitude: -96.0929,
    postal_code: '77448'
  },
  '77449': {
    country: 'US',
    city: 'Katy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8323,
    longitude: -95.736,
    postal_code: '77449'
  },
  '77450': {
    country: 'US',
    city: 'Katy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.745,
    longitude: -95.7326,
    postal_code: '77450'
  },
  '77451': {
    country: 'US',
    city: 'Kendleton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.447,
    longitude: -96.0036,
    postal_code: '77451'
  },
  '77452': {
    country: 'US',
    city: 'Kenney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Austin',
    latitude: 30.0477,
    longitude: -96.3269,
    postal_code: '77452'
  },
  '77453': {
    country: 'US',
    city: 'Lane City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.2161,
    longitude: -96.0263,
    postal_code: '77453'
  },
  '77454': {
    country: 'US',
    city: 'Lissie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.5353,
    longitude: -96.2303,
    postal_code: '77454'
  },
  '77455': {
    country: 'US',
    city: 'Louise',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.1114,
    longitude: -96.4033,
    postal_code: '77455'
  },
  '77456': {
    country: 'US',
    city: 'Markham',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.9547,
    longitude: -96.0928,
    postal_code: '77456'
  },
  '77457': {
    country: 'US',
    city: 'Matagorda',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.6908,
    longitude: -95.9675,
    postal_code: '77457'
  },
  '77458': {
    country: 'US',
    city: 'Midfield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.9362,
    longitude: -96.2265,
    postal_code: '77458'
  },
  '77459': {
    country: 'US',
    city: 'Missouri City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.5704,
    longitude: -95.5423,
    postal_code: '77459'
  },
  '77460': {
    country: 'US',
    city: 'Nada',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.405,
    longitude: -96.3864,
    postal_code: '77460'
  },
  '77461': {
    country: 'US',
    city: 'Needville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.4117,
    longitude: -95.8273,
    postal_code: '77461'
  },
  '77463': {
    country: 'US',
    city: 'Old Ocean',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.08,
    longitude: -95.7497,
    postal_code: '77463'
  },
  '77464': {
    country: 'US',
    city: 'Orchard',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.5948,
    longitude: -95.9727,
    postal_code: '77464'
  },
  '77465': {
    country: 'US',
    city: 'Palacios',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.715,
    longitude: -96.2154,
    postal_code: '77465'
  },
  '77466': {
    country: 'US',
    city: 'Pattison',
    state: 'Texas',
    state_short: 'TX',
    county: 'Waller',
    latitude: 29.8173,
    longitude: -96.0073,
    postal_code: '77466'
  },
  '77467': {
    country: 'US',
    city: 'Pierce',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.2052,
    longitude: -96.1369,
    postal_code: '77467'
  },
  '77468': {
    country: 'US',
    city: 'Pledger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 29.1825,
    longitude: -95.9086,
    postal_code: '77468'
  },
  '77469': {
    country: 'US',
    city: 'Richmond',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.5511,
    longitude: -95.7329,
    postal_code: '77469'
  },
  '77470': {
    country: 'US',
    city: 'Rock Island',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.5311,
    longitude: -96.5752,
    postal_code: '77470'
  },
  '77471': {
    country: 'US',
    city: 'Rosenberg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.5497,
    longitude: -95.7982,
    postal_code: '77471'
  },
  '77473': {
    country: 'US',
    city: 'San Felipe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Austin',
    latitude: 29.8019,
    longitude: -96.1014,
    postal_code: '77473'
  },
  '77474': {
    country: 'US',
    city: 'Sealy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Austin',
    latitude: 29.7826,
    longitude: -96.1592,
    postal_code: '77474'
  },
  '77475': {
    country: 'US',
    city: 'Sheridan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.4936,
    longitude: -96.6711,
    postal_code: '77475'
  },
  '77476': {
    country: 'US',
    city: 'Simonton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.6794,
    longitude: -95.9772,
    postal_code: '77476'
  },
  '77477': {
    country: 'US',
    city: 'Stafford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.6228,
    longitude: -95.5678,
    postal_code: '77477'
  },
  '77478': {
    country: 'US',
    city: 'Sugar Land',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.6196,
    longitude: -95.607,
    postal_code: '77478'
  },
  '77479': {
    country: 'US',
    city: 'Sugar Land',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.5785,
    longitude: -95.6066,
    postal_code: '77479'
  },
  '77480': {
    country: 'US',
    city: 'Sweeny',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.0415,
    longitude: -95.7004,
    postal_code: '77480'
  },
  '77481': {
    country: 'US',
    city: 'Thompsons',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.4683,
    longitude: -95.5776,
    postal_code: '77481'
  },
  '77482': {
    country: 'US',
    city: 'Van Vleck',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 29.016,
    longitude: -95.8905,
    postal_code: '77482'
  },
  '77483': {
    country: 'US',
    city: 'Wadsworth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Matagorda',
    latitude: 28.8259,
    longitude: -95.9046,
    postal_code: '77483'
  },
  '77484': {
    country: 'US',
    city: 'Waller',
    state: 'Texas',
    state_short: 'TX',
    county: 'Waller',
    latitude: 30.071,
    longitude: -95.9253,
    postal_code: '77484'
  },
  '77485': {
    country: 'US',
    city: 'Wallis',
    state: 'Texas',
    state_short: 'TX',
    county: 'Austin',
    latitude: 29.6397,
    longitude: -96.0456,
    postal_code: '77485'
  },
  '77486': {
    country: 'US',
    city: 'West Columbia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.1408,
    longitude: -95.6694,
    postal_code: '77486'
  },
  '77487': {
    country: 'US',
    city: 'Sugar Land',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.6197,
    longitude: -95.6349,
    postal_code: '77487'
  },
  '77488': {
    country: 'US',
    city: 'Wharton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wharton',
    latitude: 29.3205,
    longitude: -96.0858,
    postal_code: '77488'
  },
  '77489': {
    country: 'US',
    city: 'Missouri City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.5962,
    longitude: -95.5115,
    postal_code: '77489'
  },
  '77491': {
    country: 'US',
    city: 'Katy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7858,
    longitude: -95.8244,
    postal_code: '77491'
  },
  '77492': {
    country: 'US',
    city: 'Katy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7858,
    longitude: -95.8244,
    postal_code: '77492'
  },
  '77493': {
    country: 'US',
    city: 'Katy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8678,
    longitude: -95.8298,
    postal_code: '77493'
  },
  '77494': {
    country: 'US',
    city: 'Katy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7404,
    longitude: -95.8304,
    postal_code: '77494'
  },
  '77496': {
    country: 'US',
    city: 'Sugar Land',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.5255,
    longitude: -95.7565,
    postal_code: '77496'
  },
  '77497': {
    country: 'US',
    city: 'Stafford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.6161,
    longitude: -95.5577,
    postal_code: '77497'
  },
  '77498': {
    country: 'US',
    city: 'Sugar Land',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.6396,
    longitude: -95.65,
    postal_code: '77498'
  },
  '77501': {
    country: 'US',
    city: 'Pasadena',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6911,
    longitude: -95.2091,
    postal_code: '77501'
  },
  '77502': {
    country: 'US',
    city: 'Pasadena',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6789,
    longitude: -95.1982,
    postal_code: '77502'
  },
  '77503': {
    country: 'US',
    city: 'Pasadena',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6877,
    longitude: -95.1572,
    postal_code: '77503'
  },
  '77504': {
    country: 'US',
    city: 'Pasadena',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6501,
    longitude: -95.1885,
    postal_code: '77504'
  },
  '77505': {
    country: 'US',
    city: 'Pasadena',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6518,
    longitude: -95.1464,
    postal_code: '77505'
  },
  '77506': {
    country: 'US',
    city: 'Pasadena',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7009,
    longitude: -95.1989,
    postal_code: '77506'
  },
  '77507': {
    country: 'US',
    city: 'Pasadena',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6224,
    longitude: -95.0545,
    postal_code: '77507'
  },
  '77508': {
    country: 'US',
    city: 'Pasadena',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.5699,
    longitude: -95.1066,
    postal_code: '77508'
  },
  '77510': {
    country: 'US',
    city: 'Santa Fe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.4032,
    longitude: -95.0734,
    postal_code: '77510'
  },
  '77511': {
    country: 'US',
    city: 'Alvin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.412,
    longitude: -95.2515,
    postal_code: '77511'
  },
  '77512': {
    country: 'US',
    city: 'Alvin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.4238,
    longitude: -95.2441,
    postal_code: '77512'
  },
  '77514': {
    country: 'US',
    city: 'Anahuac',
    state: 'Texas',
    state_short: 'TX',
    county: 'Chambers',
    latitude: 29.662,
    longitude: -94.593,
    postal_code: '77514'
  },
  '77515': {
    country: 'US',
    city: 'Angleton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.181,
    longitude: -95.4467,
    postal_code: '77515'
  },
  '77516': {
    country: 'US',
    city: 'Angleton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.1694,
    longitude: -95.4319,
    postal_code: '77516'
  },
  '77517': {
    country: 'US',
    city: 'Santa Fe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.3673,
    longitude: -95.1361,
    postal_code: '77517'
  },
  '77518': {
    country: 'US',
    city: 'Bacliff',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.5055,
    longitude: -94.9893,
    postal_code: '77518'
  },
  '77519': {
    country: 'US',
    city: 'Batson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardin',
    latitude: 30.225,
    longitude: -94.6096,
    postal_code: '77519'
  },
  '77520': {
    country: 'US',
    city: 'Baytown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7461,
    longitude: -94.9653,
    postal_code: '77520'
  },
  '77521': {
    country: 'US',
    city: 'Baytown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7705,
    longitude: -94.9695,
    postal_code: '77521'
  },
  '77522': {
    country: 'US',
    city: 'Baytown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7355,
    longitude: -94.9774,
    postal_code: '77522'
  },
  '77523': {
    country: 'US',
    city: 'Baytown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Chambers',
    latitude: 29.77,
    longitude: -94.8608,
    postal_code: '77523'
  },
  '77530': {
    country: 'US',
    city: 'Channelview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7914,
    longitude: -95.1317,
    postal_code: '77530'
  },
  '77531': {
    country: 'US',
    city: 'Clute',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.0325,
    longitude: -95.4026,
    postal_code: '77531'
  },
  '77532': {
    country: 'US',
    city: 'Crosby',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.9249,
    longitude: -95.0578,
    postal_code: '77532'
  },
  '77533': {
    country: 'US',
    city: 'Daisetta',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 30.1133,
    longitude: -94.643,
    postal_code: '77533'
  },
  '77534': {
    country: 'US',
    city: 'Danbury',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.2291,
    longitude: -95.3435,
    postal_code: '77534'
  },
  '77535': {
    country: 'US',
    city: 'Dayton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 30.0102,
    longitude: -94.8787,
    postal_code: '77535'
  },
  '77536': {
    country: 'US',
    city: 'Deer Park',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6826,
    longitude: -95.1222,
    postal_code: '77536'
  },
  '77538': {
    country: 'US',
    city: 'Devers',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 29.9978,
    longitude: -94.5746,
    postal_code: '77538'
  },
  '77539': {
    country: 'US',
    city: 'Dickinson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.4585,
    longitude: -95.0345,
    postal_code: '77539'
  },
  '77541': {
    country: 'US',
    city: 'Freeport',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.0357,
    longitude: -95.3379,
    postal_code: '77541'
  },
  '77542': {
    country: 'US',
    city: 'Freeport',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 28.9541,
    longitude: -95.3597,
    postal_code: '77542'
  },
  '77545': {
    country: 'US',
    city: 'Fresno',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fort Bend',
    latitude: 29.5293,
    longitude: -95.4626,
    postal_code: '77545'
  },
  '77546': {
    country: 'US',
    city: 'Friendswood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.5224,
    longitude: -95.1879,
    postal_code: '77546'
  },
  '77547': {
    country: 'US',
    city: 'Galena Park',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.7392,
    longitude: -95.24,
    postal_code: '77547'
  },
  '77549': {
    country: 'US',
    city: 'Friendswood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.5294,
    longitude: -95.201,
    postal_code: '77549'
  },
  '77550': {
    country: 'US',
    city: 'Galveston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.2983,
    longitude: -94.793,
    postal_code: '77550'
  },
  '77551': {
    country: 'US',
    city: 'Galveston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.2766,
    longitude: -94.8303,
    postal_code: '77551'
  },
  '77552': {
    country: 'US',
    city: 'Galveston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.2205,
    longitude: -94.9444,
    postal_code: '77552'
  },
  '77553': {
    country: 'US',
    city: 'Galveston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.3322,
    longitude: -94.8002,
    postal_code: '77553'
  },
  '77554': {
    country: 'US',
    city: 'Galveston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.2243,
    longitude: -94.9637,
    postal_code: '77554'
  },
  '77555': {
    country: 'US',
    city: 'Galveston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.3305,
    longitude: -94.8002,
    postal_code: '77555'
  },
  '77560': {
    country: 'US',
    city: 'Hankamer',
    state: 'Texas',
    state_short: 'TX',
    county: 'Chambers',
    latitude: 29.8752,
    longitude: -94.5938,
    postal_code: '77560'
  },
  '77561': {
    country: 'US',
    city: 'Hardin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 30.151,
    longitude: -94.7338,
    postal_code: '77561'
  },
  '77562': {
    country: 'US',
    city: 'Highlands',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.8296,
    longitude: -95.0393,
    postal_code: '77562'
  },
  '77563': {
    country: 'US',
    city: 'Hitchcock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.3398,
    longitude: -94.9926,
    postal_code: '77563'
  },
  '77564': {
    country: 'US',
    city: 'Hull',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 30.1463,
    longitude: -94.6424,
    postal_code: '77564'
  },
  '77565': {
    country: 'US',
    city: 'Kemah',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.5236,
    longitude: -95.0276,
    postal_code: '77565'
  },
  '77566': {
    country: 'US',
    city: 'Lake Jackson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.0393,
    longitude: -95.4401,
    postal_code: '77566'
  },
  '77568': {
    country: 'US',
    city: 'La Marque',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.3676,
    longitude: -94.9742,
    postal_code: '77568'
  },
  '77571': {
    country: 'US',
    city: 'La Porte',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6884,
    longitude: -95.0513,
    postal_code: '77571'
  },
  '77572': {
    country: 'US',
    city: 'La Porte',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6658,
    longitude: -95.0194,
    postal_code: '77572'
  },
  '77573': {
    country: 'US',
    city: 'League City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.5173,
    longitude: -95.0963,
    postal_code: '77573'
  },
  '77574': {
    country: 'US',
    city: 'League City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.5116,
    longitude: -95.0582,
    postal_code: '77574'
  },
  '77575': {
    country: 'US',
    city: 'Liberty',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 30.0946,
    longitude: -94.7378,
    postal_code: '77575'
  },
  '77577': {
    country: 'US',
    city: 'Liverpool',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.2667,
    longitude: -95.2889,
    postal_code: '77577'
  },
  '77578': {
    country: 'US',
    city: 'Manvel',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.4694,
    longitude: -95.3503,
    postal_code: '77578'
  },
  '77580': {
    country: 'US',
    city: 'Mont Belvieu',
    state: 'Texas',
    state_short: 'TX',
    county: 'Chambers',
    latitude: 29.8477,
    longitude: -94.8908,
    postal_code: '77580'
  },
  '77581': {
    country: 'US',
    city: 'Pearland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.5617,
    longitude: -95.2721,
    postal_code: '77581'
  },
  '77582': {
    country: 'US',
    city: 'Raywood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Liberty',
    latitude: 30.0542,
    longitude: -94.6764,
    postal_code: '77582'
  },
  '77583': {
    country: 'US',
    city: 'Rosharon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.4203,
    longitude: -95.4537,
    postal_code: '77583'
  },
  '77584': {
    country: 'US',
    city: 'Pearland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.5405,
    longitude: -95.3208,
    postal_code: '77584'
  },
  '77585': {
    country: 'US',
    city: 'Saratoga',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardin',
    latitude: 30.2841,
    longitude: -94.5294,
    postal_code: '77585'
  },
  '77586': {
    country: 'US',
    city: 'Seabrook',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.5832,
    longitude: -95.037,
    postal_code: '77586'
  },
  '77587': {
    country: 'US',
    city: 'South Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.6601,
    longitude: -95.2258,
    postal_code: '77587'
  },
  '77588': {
    country: 'US',
    city: 'Pearland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazoria',
    latitude: 29.5127,
    longitude: -95.2542,
    postal_code: '77588'
  },
  '77590': {
    country: 'US',
    city: 'Texas City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.397,
    longitude: -94.9203,
    postal_code: '77590'
  },
  '77591': {
    country: 'US',
    city: 'Texas City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.3891,
    longitude: -94.9942,
    postal_code: '77591'
  },
  '77592': {
    country: 'US',
    city: 'Texas City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.3305,
    longitude: -94.8002,
    postal_code: '77592'
  },
  '77597': {
    country: 'US',
    city: 'Wallisville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Chambers',
    latitude: 29.8591,
    longitude: -94.6759,
    postal_code: '77597'
  },
  '77598': {
    country: 'US',
    city: 'Webster',
    state: 'Texas',
    state_short: 'TX',
    county: 'Harris',
    latitude: 29.5564,
    longitude: -95.144,
    postal_code: '77598'
  },
  '77611': {
    country: 'US',
    city: 'Bridge City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Orange',
    latitude: 30.0192,
    longitude: -93.8326,
    postal_code: '77611'
  },
  '77612': {
    country: 'US',
    city: 'Buna',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jasper',
    latitude: 30.4132,
    longitude: -93.9913,
    postal_code: '77612'
  },
  '77613': {
    country: 'US',
    city: 'China',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.0108,
    longitude: -94.363,
    postal_code: '77613'
  },
  '77614': {
    country: 'US',
    city: 'Deweyville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Newton',
    latitude: 30.2894,
    longitude: -93.7488,
    postal_code: '77614'
  },
  '77615': {
    country: 'US',
    city: 'Evadale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jasper',
    latitude: 30.3129,
    longitude: -94.0731,
    postal_code: '77615'
  },
  '77616': {
    country: 'US',
    city: 'Fred',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tyler',
    latitude: 30.5678,
    longitude: -94.18,
    postal_code: '77616'
  },
  '77617': {
    country: 'US',
    city: 'Gilchrist',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.523,
    longitude: -94.4755,
    postal_code: '77617'
  },
  '77619': {
    country: 'US',
    city: 'Groves',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 29.9448,
    longitude: -93.9152,
    postal_code: '77619'
  },
  '77622': {
    country: 'US',
    city: 'Hamshire',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 29.8668,
    longitude: -94.3187,
    postal_code: '77622'
  },
  '77623': {
    country: 'US',
    city: 'High Island',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.5472,
    longitude: -94.4267,
    postal_code: '77623'
  },
  '77624': {
    country: 'US',
    city: 'Hillister',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tyler',
    latitude: 30.675,
    longitude: -94.3534,
    postal_code: '77624'
  },
  '77625': {
    country: 'US',
    city: 'Kountze',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardin',
    latitude: 30.3703,
    longitude: -94.3259,
    postal_code: '77625'
  },
  '77626': {
    country: 'US',
    city: 'Mauriceville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Orange',
    latitude: 30.204,
    longitude: -93.8866,
    postal_code: '77626'
  },
  '77627': {
    country: 'US',
    city: 'Nederland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 29.9716,
    longitude: -94.0012,
    postal_code: '77627'
  },
  '77629': {
    country: 'US',
    city: 'Nome',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.0015,
    longitude: -94.4189,
    postal_code: '77629'
  },
  '77630': {
    country: 'US',
    city: 'Orange',
    state: 'Texas',
    state_short: 'TX',
    county: 'Orange',
    latitude: 30.0709,
    longitude: -93.8659,
    postal_code: '77630'
  },
  '77631': {
    country: 'US',
    city: 'Orange',
    state: 'Texas',
    state_short: 'TX',
    county: 'Orange',
    latitude: 30.093,
    longitude: -93.7366,
    postal_code: '77631'
  },
  '77632': {
    country: 'US',
    city: 'Orange',
    state: 'Texas',
    state_short: 'TX',
    county: 'Orange',
    latitude: 30.1775,
    longitude: -93.8409,
    postal_code: '77632'
  },
  '77639': {
    country: 'US',
    city: 'Orangefield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Orange',
    latitude: 30.0631,
    longitude: -93.8599,
    postal_code: '77639'
  },
  '77640': {
    country: 'US',
    city: 'Port Arthur',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 29.8709,
    longitude: -93.9643,
    postal_code: '77640'
  },
  '77641': {
    country: 'US',
    city: 'Port Arthur',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 29.8476,
    longitude: -94.1297,
    postal_code: '77641'
  },
  '77642': {
    country: 'US',
    city: 'Port Arthur',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 29.9212,
    longitude: -93.927,
    postal_code: '77642'
  },
  '77643': {
    country: 'US',
    city: 'Port Arthur',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 29.9621,
    longitude: -93.8679,
    postal_code: '77643'
  },
  '77650': {
    country: 'US',
    city: 'Port Bolivar',
    state: 'Texas',
    state_short: 'TX',
    county: 'Galveston',
    latitude: 29.4266,
    longitude: -94.6861,
    postal_code: '77650'
  },
  '77651': {
    country: 'US',
    city: 'Port Neches',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 29.977,
    longitude: -93.9626,
    postal_code: '77651'
  },
  '77655': {
    country: 'US',
    city: 'Sabine Pass',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 29.7336,
    longitude: -93.8943,
    postal_code: '77655'
  },
  '77656': {
    country: 'US',
    city: 'Silsbee',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardin',
    latitude: 30.3244,
    longitude: -94.1907,
    postal_code: '77656'
  },
  '77657': {
    country: 'US',
    city: 'Lumberton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardin',
    latitude: 30.2818,
    longitude: -94.2191,
    postal_code: '77657'
  },
  '77659': {
    country: 'US',
    city: 'Sour Lake',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardin',
    latitude: 30.1491,
    longitude: -94.3733,
    postal_code: '77659'
  },
  '77660': {
    country: 'US',
    city: 'Spurger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tyler',
    latitude: 30.6387,
    longitude: -94.1663,
    postal_code: '77660'
  },
  '77661': {
    country: 'US',
    city: 'Stowell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Chambers',
    latitude: 29.7809,
    longitude: -94.39,
    postal_code: '77661'
  },
  '77662': {
    country: 'US',
    city: 'Vidor',
    state: 'Texas',
    state_short: 'TX',
    county: 'Orange',
    latitude: 30.1502,
    longitude: -94.0008,
    postal_code: '77662'
  },
  '77663': {
    country: 'US',
    city: 'Village Mills',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardin',
    latitude: 30.5185,
    longitude: -94.4458,
    postal_code: '77663'
  },
  '77664': {
    country: 'US',
    city: 'Warren',
    state: 'Texas',
    state_short: 'TX',
    county: 'Tyler',
    latitude: 30.5978,
    longitude: -94.412,
    postal_code: '77664'
  },
  '77665': {
    country: 'US',
    city: 'Winnie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Chambers',
    latitude: 29.8157,
    longitude: -94.3395,
    postal_code: '77665'
  },
  '77670': {
    country: 'US',
    city: 'Vidor',
    state: 'Texas',
    state_short: 'TX',
    county: 'Orange',
    latitude: 30.1316,
    longitude: -94.0155,
    postal_code: '77670'
  },
  '77701': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.0688,
    longitude: -94.1039,
    postal_code: '77701'
  },
  '77702': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.0871,
    longitude: -94.1254,
    postal_code: '77702'
  },
  '77703': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.1132,
    longitude: -94.1197,
    postal_code: '77703'
  },
  '77704': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.1236,
    longitude: -94.1539,
    postal_code: '77704'
  },
  '77705': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.0211,
    longitude: -94.1157,
    postal_code: '77705'
  },
  '77706': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.0948,
    longitude: -94.1648,
    postal_code: '77706'
  },
  '77707': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.0686,
    longitude: -94.1755,
    postal_code: '77707'
  },
  '77708': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.14,
    longitude: -94.1604,
    postal_code: '77708'
  },
  '77710': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.086,
    longitude: -94.1018,
    postal_code: '77710'
  },
  '77713': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.085,
    longitude: -94.2607,
    postal_code: '77713'
  },
  '77720': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.086,
    longitude: -94.1018,
    postal_code: '77720'
  },
  '77725': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.086,
    longitude: -94.1018,
    postal_code: '77725'
  },
  '77726': {
    country: 'US',
    city: 'Beaumont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jefferson',
    latitude: 30.1118,
    longitude: -94.1901,
    postal_code: '77726'
  },
  '77801': {
    country: 'US',
    city: 'Bryan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.6327,
    longitude: -96.3662,
    postal_code: '77801'
  },
  '77802': {
    country: 'US',
    city: 'Bryan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.6582,
    longitude: -96.3351,
    postal_code: '77802'
  },
  '77803': {
    country: 'US',
    city: 'Bryan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.6913,
    longitude: -96.3714,
    postal_code: '77803'
  },
  '77805': {
    country: 'US',
    city: 'Bryan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.6521,
    longitude: -96.341,
    postal_code: '77805'
  },
  '77806': {
    country: 'US',
    city: 'Bryan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.6744,
    longitude: -96.37,
    postal_code: '77806'
  },
  '77807': {
    country: 'US',
    city: 'Bryan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.6711,
    longitude: -96.4799,
    postal_code: '77807'
  },
  '77808': {
    country: 'US',
    city: 'Bryan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.8202,
    longitude: -96.3059,
    postal_code: '77808'
  },
  '77830': {
    country: 'US',
    city: 'Anderson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grimes',
    latitude: 30.5443,
    longitude: -96.0018,
    postal_code: '77830'
  },
  '77831': {
    country: 'US',
    city: 'Bedias',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grimes',
    latitude: 30.7065,
    longitude: -95.9546,
    postal_code: '77831'
  },
  '77833': {
    country: 'US',
    city: 'Brenham',
    state: 'Texas',
    state_short: 'TX',
    county: 'Washington',
    latitude: 30.1774,
    longitude: -96.4028,
    postal_code: '77833'
  },
  '77834': {
    country: 'US',
    city: 'Brenham',
    state: 'Texas',
    state_short: 'TX',
    county: 'Washington',
    latitude: 30.1669,
    longitude: -96.3977,
    postal_code: '77834'
  },
  '77835': {
    country: 'US',
    city: 'Burton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Washington',
    latitude: 30.1767,
    longitude: -96.5925,
    postal_code: '77835'
  },
  '77836': {
    country: 'US',
    city: 'Caldwell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Burleson',
    latitude: 30.5298,
    longitude: -96.7143,
    postal_code: '77836'
  },
  '77837': {
    country: 'US',
    city: 'Calvert',
    state: 'Texas',
    state_short: 'TX',
    county: 'Robertson',
    latitude: 30.9782,
    longitude: -96.6711,
    postal_code: '77837'
  },
  '77838': {
    country: 'US',
    city: 'Chriesman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Burleson',
    latitude: 30.5994,
    longitude: -96.7708,
    postal_code: '77838'
  },
  '77840': {
    country: 'US',
    city: 'College Station',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.6045,
    longitude: -96.3123,
    postal_code: '77840'
  },
  '77841': {
    country: 'US',
    city: 'College Station',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.5726,
    longitude: -96.327,
    postal_code: '77841'
  },
  '77842': {
    country: 'US',
    city: 'College Station',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.6521,
    longitude: -96.341,
    postal_code: '77842'
  },
  '77843': {
    country: 'US',
    city: 'College Station',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.6521,
    longitude: -96.341,
    postal_code: '77843'
  },
  '77844': {
    country: 'US',
    city: 'College Station',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.6521,
    longitude: -96.341,
    postal_code: '77844'
  },
  '77845': {
    country: 'US',
    city: 'College Station',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.5118,
    longitude: -96.3171,
    postal_code: '77845'
  },
  '77850': {
    country: 'US',
    city: 'Concord',
    state: 'Texas',
    state_short: 'TX',
    county: 'Leon',
    latitude: 31.3138,
    longitude: -95.9935,
    postal_code: '77850'
  },
  '77852': {
    country: 'US',
    city: 'Deanville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Burleson',
    latitude: 30.4322,
    longitude: -96.7561,
    postal_code: '77852'
  },
  '77853': {
    country: 'US',
    city: 'Dime Box',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lee',
    latitude: 30.3579,
    longitude: -96.8248,
    postal_code: '77853'
  },
  '77855': {
    country: 'US',
    city: 'Flynn',
    state: 'Texas',
    state_short: 'TX',
    county: 'Leon',
    latitude: 31.1593,
    longitude: -96.1234,
    postal_code: '77855'
  },
  '77856': {
    country: 'US',
    city: 'Franklin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Robertson',
    latitude: 31.0001,
    longitude: -96.5171,
    postal_code: '77856'
  },
  '77857': {
    country: 'US',
    city: 'Gause',
    state: 'Texas',
    state_short: 'TX',
    county: 'Milam',
    latitude: 30.7833,
    longitude: -96.7237,
    postal_code: '77857'
  },
  '77859': {
    country: 'US',
    city: 'Hearne',
    state: 'Texas',
    state_short: 'TX',
    county: 'Robertson',
    latitude: 30.8669,
    longitude: -96.5843,
    postal_code: '77859'
  },
  '77861': {
    country: 'US',
    city: 'Iola',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grimes',
    latitude: 30.7326,
    longitude: -96.0911,
    postal_code: '77861'
  },
  '77862': {
    country: 'US',
    city: 'Kurten',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.7871,
    longitude: -96.2641,
    postal_code: '77862'
  },
  '77863': {
    country: 'US',
    city: 'Lyons',
    state: 'Texas',
    state_short: 'TX',
    county: 'Burleson',
    latitude: 30.3863,
    longitude: -96.5633,
    postal_code: '77863'
  },
  '77864': {
    country: 'US',
    city: 'Madisonville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Madison',
    latitude: 30.9533,
    longitude: -95.9091,
    postal_code: '77864'
  },
  '77865': {
    country: 'US',
    city: 'Marquez',
    state: 'Texas',
    state_short: 'TX',
    county: 'Leon',
    latitude: 31.2309,
    longitude: -96.2375,
    postal_code: '77865'
  },
  '77866': {
    country: 'US',
    city: 'Millican',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.4491,
    longitude: -96.217,
    postal_code: '77866'
  },
  '77867': {
    country: 'US',
    city: 'Mumford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Robertson',
    latitude: 30.7344,
    longitude: -96.565,
    postal_code: '77867'
  },
  '77868': {
    country: 'US',
    city: 'Navasota',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grimes',
    latitude: 30.3576,
    longitude: -96.0594,
    postal_code: '77868'
  },
  '77870': {
    country: 'US',
    city: 'New Baden',
    state: 'Texas',
    state_short: 'TX',
    county: 'Robertson',
    latitude: 31.051,
    longitude: -96.4291,
    postal_code: '77870'
  },
  '77871': {
    country: 'US',
    city: 'Normangee',
    state: 'Texas',
    state_short: 'TX',
    county: 'Leon',
    latitude: 31.0705,
    longitude: -96.125,
    postal_code: '77871'
  },
  '77872': {
    country: 'US',
    city: 'North Zulch',
    state: 'Texas',
    state_short: 'TX',
    county: 'Madison',
    latitude: 30.9285,
    longitude: -96.0925,
    postal_code: '77872'
  },
  '77873': {
    country: 'US',
    city: 'Richards',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grimes',
    latitude: 30.5796,
    longitude: -95.8959,
    postal_code: '77873'
  },
  '77875': {
    country: 'US',
    city: 'Roans Prairie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grimes',
    latitude: 30.6075,
    longitude: -95.9579,
    postal_code: '77875'
  },
  '77876': {
    country: 'US',
    city: 'Shiro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Grimes',
    latitude: 30.6133,
    longitude: -95.8883,
    postal_code: '77876'
  },
  '77878': {
    country: 'US',
    city: 'Snook',
    state: 'Texas',
    state_short: 'TX',
    county: 'Burleson',
    latitude: 30.4702,
    longitude: -96.4804,
    postal_code: '77878'
  },
  '77879': {
    country: 'US',
    city: 'Somerville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Burleson',
    latitude: 30.4076,
    longitude: -96.5358,
    postal_code: '77879'
  },
  '77880': {
    country: 'US',
    city: 'Washington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Washington',
    latitude: 30.3182,
    longitude: -96.2249,
    postal_code: '77880'
  },
  '77881': {
    country: 'US',
    city: 'Wellborn',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brazos',
    latitude: 30.5334,
    longitude: -96.3032,
    postal_code: '77881'
  },
  '77882': {
    country: 'US',
    city: 'Wheelock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Robertson',
    latitude: 30.8977,
    longitude: -96.39,
    postal_code: '77882'
  },
  '77901': {
    country: 'US',
    city: 'Victoria',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.809,
    longitude: -96.9993,
    postal_code: '77901'
  },
  '77902': {
    country: 'US',
    city: 'Victoria',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.9255,
    longitude: -97.1006,
    postal_code: '77902'
  },
  '77903': {
    country: 'US',
    city: 'Victoria',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.8053,
    longitude: -97.0036,
    postal_code: '77903'
  },
  '77904': {
    country: 'US',
    city: 'Victoria',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.8675,
    longitude: -96.999,
    postal_code: '77904'
  },
  '77905': {
    country: 'US',
    city: 'Victoria',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.7525,
    longitude: -97.0338,
    postal_code: '77905'
  },
  '77950': {
    country: 'US',
    city: 'Austwell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Refugio',
    latitude: 28.4023,
    longitude: -96.853,
    postal_code: '77950'
  },
  '77951': {
    country: 'US',
    city: 'Bloomington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.6495,
    longitude: -96.8945,
    postal_code: '77951'
  },
  '77954': {
    country: 'US',
    city: 'Cuero',
    state: 'Texas',
    state_short: 'TX',
    county: 'DeWitt',
    latitude: 29.091,
    longitude: -97.2812,
    postal_code: '77954'
  },
  '77957': {
    country: 'US',
    city: 'Edna',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jackson',
    latitude: 28.9527,
    longitude: -96.6488,
    postal_code: '77957'
  },
  '77960': {
    country: 'US',
    city: 'Fannin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Goliad',
    latitude: 28.6955,
    longitude: -97.2358,
    postal_code: '77960'
  },
  '77961': {
    country: 'US',
    city: 'Francitas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jackson',
    latitude: 28.8597,
    longitude: -96.3386,
    postal_code: '77961'
  },
  '77962': {
    country: 'US',
    city: 'Ganado',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jackson',
    latitude: 29.0308,
    longitude: -96.5031,
    postal_code: '77962'
  },
  '77963': {
    country: 'US',
    city: 'Goliad',
    state: 'Texas',
    state_short: 'TX',
    county: 'Goliad',
    latitude: 28.6993,
    longitude: -97.3783,
    postal_code: '77963'
  },
  '77964': {
    country: 'US',
    city: 'Hallettsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lavaca',
    latitude: 29.4426,
    longitude: -96.9234,
    postal_code: '77964'
  },
  '77967': {
    country: 'US',
    city: 'Hochheim',
    state: 'Texas',
    state_short: 'TX',
    county: 'DeWitt',
    latitude: 29.3125,
    longitude: -97.2917,
    postal_code: '77967'
  },
  '77968': {
    country: 'US',
    city: 'Inez',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.8994,
    longitude: -96.8003,
    postal_code: '77968'
  },
  '77969': {
    country: 'US',
    city: 'La Salle',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jackson',
    latitude: 28.7671,
    longitude: -96.6492,
    postal_code: '77969'
  },
  '77970': {
    country: 'US',
    city: 'La Ward',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jackson',
    latitude: 28.8439,
    longitude: -96.4641,
    postal_code: '77970'
  },
  '77971': {
    country: 'US',
    city: 'Lolita',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jackson',
    latitude: 28.7724,
    longitude: -96.4793,
    postal_code: '77971'
  },
  '77973': {
    country: 'US',
    city: 'Mcfaddin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.5511,
    longitude: -97.0099,
    postal_code: '77973'
  },
  '77974': {
    country: 'US',
    city: 'Meyersville',
    state: 'Texas',
    state_short: 'TX',
    county: 'DeWitt',
    latitude: 28.9229,
    longitude: -97.3042,
    postal_code: '77974'
  },
  '77975': {
    country: 'US',
    city: 'Moulton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lavaca',
    latitude: 29.57,
    longitude: -97.1031,
    postal_code: '77975'
  },
  '77976': {
    country: 'US',
    city: 'Nursery',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.9543,
    longitude: -97.0906,
    postal_code: '77976'
  },
  '77977': {
    country: 'US',
    city: 'Placedo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.6923,
    longitude: -96.8254,
    postal_code: '77977'
  },
  '77978': {
    country: 'US',
    city: 'Point Comfort',
    state: 'Texas',
    state_short: 'TX',
    county: 'Calhoun',
    latitude: 28.6651,
    longitude: -96.5506,
    postal_code: '77978'
  },
  '77979': {
    country: 'US',
    city: 'Port Lavaca',
    state: 'Texas',
    state_short: 'TX',
    county: 'Calhoun',
    latitude: 28.6011,
    longitude: -96.6259,
    postal_code: '77979'
  },
  '77982': {
    country: 'US',
    city: 'Port O Connor',
    state: 'Texas',
    state_short: 'TX',
    county: 'Calhoun',
    latitude: 28.4483,
    longitude: -96.4058,
    postal_code: '77982'
  },
  '77983': {
    country: 'US',
    city: 'Seadrift',
    state: 'Texas',
    state_short: 'TX',
    county: 'Calhoun',
    latitude: 28.4101,
    longitude: -96.7023,
    postal_code: '77983'
  },
  '77984': {
    country: 'US',
    city: 'Shiner',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lavaca',
    latitude: 29.428,
    longitude: -97.164,
    postal_code: '77984'
  },
  '77986': {
    country: 'US',
    city: 'Sublime',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lavaca',
    latitude: 29.4876,
    longitude: -96.7946,
    postal_code: '77986'
  },
  '77987': {
    country: 'US',
    city: 'Sweet Home',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lavaca',
    latitude: 29.3426,
    longitude: -97.0756,
    postal_code: '77987'
  },
  '77988': {
    country: 'US',
    city: 'Telferner',
    state: 'Texas',
    state_short: 'TX',
    county: 'Victoria',
    latitude: 28.8489,
    longitude: -96.8905,
    postal_code: '77988'
  },
  '77989': {
    country: 'US',
    city: 'Thomaston',
    state: 'Texas',
    state_short: 'TX',
    county: 'DeWitt',
    latitude: 28.9974,
    longitude: -97.1539,
    postal_code: '77989'
  },
  '77990': {
    country: 'US',
    city: 'Tivoli',
    state: 'Texas',
    state_short: 'TX',
    county: 'Refugio',
    latitude: 28.4587,
    longitude: -96.8928,
    postal_code: '77990'
  },
  '77991': {
    country: 'US',
    city: 'Vanderbilt',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jackson',
    latitude: 28.8068,
    longitude: -96.6042,
    postal_code: '77991'
  },
  '77993': {
    country: 'US',
    city: 'Weesatche',
    state: 'Texas',
    state_short: 'TX',
    county: 'Goliad',
    latitude: 28.8472,
    longitude: -97.448,
    postal_code: '77993'
  },
  '77994': {
    country: 'US',
    city: 'Westhoff',
    state: 'Texas',
    state_short: 'TX',
    county: 'DeWitt',
    latitude: 29.1792,
    longitude: -97.46,
    postal_code: '77994'
  },
  '77995': {
    country: 'US',
    city: 'Yoakum',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lavaca',
    latitude: 29.284,
    longitude: -97.1306,
    postal_code: '77995'
  },
  '78001': {
    country: 'US',
    city: 'Artesia Wells',
    state: 'Texas',
    state_short: 'TX',
    county: 'La Salle',
    latitude: 28.2639,
    longitude: -99.2802,
    postal_code: '78001'
  },
  '78002': {
    country: 'US',
    city: 'Atascosa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.2705,
    longitude: -98.7421,
    postal_code: '78002'
  },
  '78003': {
    country: 'US',
    city: 'Bandera',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bandera',
    latitude: 29.7276,
    longitude: -99.0453,
    postal_code: '78003'
  },
  '78004': {
    country: 'US',
    city: 'Bergheim',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kendall',
    latitude: 29.8274,
    longitude: -98.5753,
    postal_code: '78004'
  },
  '78005': {
    country: 'US',
    city: 'Bigfoot',
    state: 'Texas',
    state_short: 'TX',
    county: 'Frio',
    latitude: 29.0531,
    longitude: -98.8582,
    postal_code: '78005'
  },
  '78006': {
    country: 'US',
    city: 'Boerne',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kendall',
    latitude: 29.8931,
    longitude: -98.6857,
    postal_code: '78006'
  },
  '78007': {
    country: 'US',
    city: 'Calliham',
    state: 'Texas',
    state_short: 'TX',
    county: 'McMullen',
    latitude: 28.4805,
    longitude: -98.3503,
    postal_code: '78007'
  },
  '78008': {
    country: 'US',
    city: 'Campbellton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Atascosa',
    latitude: 28.767,
    longitude: -98.2566,
    postal_code: '78008'
  },
  '78009': {
    country: 'US',
    city: 'Castroville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Medina',
    latitude: 29.3553,
    longitude: -98.8824,
    postal_code: '78009'
  },
  '78010': {
    country: 'US',
    city: 'Center Point',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kerr',
    latitude: 29.9441,
    longitude: -99.0364,
    postal_code: '78010'
  },
  '78011': {
    country: 'US',
    city: 'Charlotte',
    state: 'Texas',
    state_short: 'TX',
    county: 'Atascosa',
    latitude: 28.8649,
    longitude: -98.707,
    postal_code: '78011'
  },
  '78012': {
    country: 'US',
    city: 'Christine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Atascosa',
    latitude: 28.7858,
    longitude: -98.4886,
    postal_code: '78012'
  },
  '78013': {
    country: 'US',
    city: 'Comfort',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kendall',
    latitude: 29.9677,
    longitude: -98.905,
    postal_code: '78013'
  },
  '78014': {
    country: 'US',
    city: 'Cotulla',
    state: 'Texas',
    state_short: 'TX',
    county: 'La Salle',
    latitude: 28.4398,
    longitude: -99.2328,
    postal_code: '78014'
  },
  '78015': {
    country: 'US',
    city: 'Boerne',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.7327,
    longitude: -98.6646,
    postal_code: '78015'
  },
  '78016': {
    country: 'US',
    city: 'Devine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Medina',
    latitude: 29.1521,
    longitude: -98.909,
    postal_code: '78016'
  },
  '78017': {
    country: 'US',
    city: 'Dilley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Frio',
    latitude: 28.6782,
    longitude: -99.1747,
    postal_code: '78017'
  },
  '78019': {
    country: 'US',
    city: 'Encinal',
    state: 'Texas',
    state_short: 'TX',
    county: 'La Salle',
    latitude: 28.0405,
    longitude: -99.3563,
    postal_code: '78019'
  },
  '78021': {
    country: 'US',
    city: 'Fowlerton',
    state: 'Texas',
    state_short: 'TX',
    county: 'La Salle',
    latitude: 28.4887,
    longitude: -98.8518,
    postal_code: '78021'
  },
  '78022': {
    country: 'US',
    city: 'George West',
    state: 'Texas',
    state_short: 'TX',
    county: 'Live Oak',
    latitude: 28.3204,
    longitude: -98.1162,
    postal_code: '78022'
  },
  '78023': {
    country: 'US',
    city: 'Helotes',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.6321,
    longitude: -98.7542,
    postal_code: '78023'
  },
  '78024': {
    country: 'US',
    city: 'Hunt',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kerr',
    latitude: 30.0027,
    longitude: -99.4823,
    postal_code: '78024'
  },
  '78025': {
    country: 'US',
    city: 'Ingram',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kerr',
    latitude: 30.0731,
    longitude: -99.269,
    postal_code: '78025'
  },
  '78026': {
    country: 'US',
    city: 'Jourdanton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Atascosa',
    latitude: 28.903,
    longitude: -98.544,
    postal_code: '78026'
  },
  '78027': {
    country: 'US',
    city: 'Kendalia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kendall',
    latitude: 29.9406,
    longitude: -98.5166,
    postal_code: '78027'
  },
  '78028': {
    country: 'US',
    city: 'Kerrville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kerr',
    latitude: 30.0416,
    longitude: -99.1408,
    postal_code: '78028'
  },
  '78029': {
    country: 'US',
    city: 'Kerrville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kerr',
    latitude: 30.0332,
    longitude: -99.141,
    postal_code: '78029'
  },
  '78039': {
    country: 'US',
    city: 'La Coste',
    state: 'Texas',
    state_short: 'TX',
    county: 'Medina',
    latitude: 29.3082,
    longitude: -98.8125,
    postal_code: '78039'
  },
  '78040': {
    country: 'US',
    city: 'Laredo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Webb',
    latitude: 27.5155,
    longitude: -99.4986,
    postal_code: '78040'
  },
  '78041': {
    country: 'US',
    city: 'Laredo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Webb',
    latitude: 27.5569,
    longitude: -99.4907,
    postal_code: '78041'
  },
  '78042': {
    country: 'US',
    city: 'Laredo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Webb',
    latitude: 27.5655,
    longitude: -99.4768,
    postal_code: '78042'
  },
  '78043': {
    country: 'US',
    city: 'Laredo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Webb',
    latitude: 27.5879,
    longitude: -99.2176,
    postal_code: '78043'
  },
  '78044': {
    country: 'US',
    city: 'Laredo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Webb',
    latitude: 27.3637,
    longitude: -99.4819,
    postal_code: '78044'
  },
  '78045': {
    country: 'US',
    city: 'Laredo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Webb',
    latitude: 27.6357,
    longitude: -99.5923,
    postal_code: '78045'
  },
  '78046': {
    country: 'US',
    city: 'Laredo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Webb',
    latitude: 27.4047,
    longitude: -99.4743,
    postal_code: '78046'
  },
  '78050': {
    country: 'US',
    city: 'Leming',
    state: 'Texas',
    state_short: 'TX',
    county: 'Atascosa',
    latitude: 29.073,
    longitude: -98.4842,
    postal_code: '78050'
  },
  '78052': {
    country: 'US',
    city: 'Lytle',
    state: 'Texas',
    state_short: 'TX',
    county: 'Atascosa',
    latitude: 29.2366,
    longitude: -98.7945,
    postal_code: '78052'
  },
  '78054': {
    country: 'US',
    city: 'Macdona',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3258,
    longitude: -98.6911,
    postal_code: '78054'
  },
  '78055': {
    country: 'US',
    city: 'Medina',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bandera',
    latitude: 29.8475,
    longitude: -99.3215,
    postal_code: '78055'
  },
  '78056': {
    country: 'US',
    city: 'Mico',
    state: 'Texas',
    state_short: 'TX',
    county: 'Medina',
    latitude: 29.5441,
    longitude: -98.9231,
    postal_code: '78056'
  },
  '78057': {
    country: 'US',
    city: 'Moore',
    state: 'Texas',
    state_short: 'TX',
    county: 'Frio',
    latitude: 29.0595,
    longitude: -99.0204,
    postal_code: '78057'
  },
  '78058': {
    country: 'US',
    city: 'Mountain Home',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kerr',
    latitude: 30.1725,
    longitude: -99.485,
    postal_code: '78058'
  },
  '78059': {
    country: 'US',
    city: 'Natalia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Medina',
    latitude: 29.2117,
    longitude: -98.8552,
    postal_code: '78059'
  },
  '78060': {
    country: 'US',
    city: 'Oakville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Live Oak',
    latitude: 28.4492,
    longitude: -98.1019,
    postal_code: '78060'
  },
  '78061': {
    country: 'US',
    city: 'Pearsall',
    state: 'Texas',
    state_short: 'TX',
    county: 'Frio',
    latitude: 28.8923,
    longitude: -99.0944,
    postal_code: '78061'
  },
  '78062': {
    country: 'US',
    city: 'Peggy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Atascosa',
    latitude: 28.7397,
    longitude: -98.1786,
    postal_code: '78062'
  },
  '78063': {
    country: 'US',
    city: 'Pipe Creek',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bandera',
    latitude: 29.6796,
    longitude: -98.9484,
    postal_code: '78063'
  },
  '78064': {
    country: 'US',
    city: 'Pleasanton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Atascosa',
    latitude: 28.9924,
    longitude: -98.4831,
    postal_code: '78064'
  },
  '78065': {
    country: 'US',
    city: 'Poteet',
    state: 'Texas',
    state_short: 'TX',
    county: 'Atascosa',
    latitude: 29.0994,
    longitude: -98.6241,
    postal_code: '78065'
  },
  '78066': {
    country: 'US',
    city: 'Rio Medina',
    state: 'Texas',
    state_short: 'TX',
    county: 'Medina',
    latitude: 29.4612,
    longitude: -98.8694,
    postal_code: '78066'
  },
  '78067': {
    country: 'US',
    city: 'San Ygnacio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Zapata',
    latitude: 27.0425,
    longitude: -99.44,
    postal_code: '78067'
  },
  '78069': {
    country: 'US',
    city: 'Somerset',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.2205,
    longitude: -98.6678,
    postal_code: '78069'
  },
  '78070': {
    country: 'US',
    city: 'Spring Branch',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comal',
    latitude: 29.9238,
    longitude: -98.3788,
    postal_code: '78070'
  },
  '78071': {
    country: 'US',
    city: 'Three Rivers',
    state: 'Texas',
    state_short: 'TX',
    county: 'Live Oak',
    latitude: 28.4756,
    longitude: -98.1782,
    postal_code: '78071'
  },
  '78072': {
    country: 'US',
    city: 'Tilden',
    state: 'Texas',
    state_short: 'TX',
    county: 'McMullen',
    latitude: 28.4198,
    longitude: -98.5693,
    postal_code: '78072'
  },
  '78073': {
    country: 'US',
    city: 'Von Ormy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.275,
    longitude: -98.6677,
    postal_code: '78073'
  },
  '78074': {
    country: 'US',
    city: 'Waring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kendall',
    latitude: 29.9523,
    longitude: -98.7944,
    postal_code: '78074'
  },
  '78075': {
    country: 'US',
    city: 'Whitsett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Live Oak',
    latitude: 28.6373,
    longitude: -98.2565,
    postal_code: '78075'
  },
  '78076': {
    country: 'US',
    city: 'Zapata',
    state: 'Texas',
    state_short: 'TX',
    county: 'Zapata',
    latitude: 26.8897,
    longitude: -99.2506,
    postal_code: '78076'
  },
  '78101': {
    country: 'US',
    city: 'Adkins',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3805,
    longitude: -98.265,
    postal_code: '78101'
  },
  '78102': {
    country: 'US',
    city: 'Beeville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bee',
    latitude: 28.4222,
    longitude: -97.7616,
    postal_code: '78102'
  },
  '78104': {
    country: 'US',
    city: 'Beeville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bee',
    latitude: 28.3931,
    longitude: -97.776,
    postal_code: '78104'
  },
  '78107': {
    country: 'US',
    city: 'Berclair',
    state: 'Texas',
    state_short: 'TX',
    county: 'Goliad',
    latitude: 28.5295,
    longitude: -97.5925,
    postal_code: '78107'
  },
  '78108': {
    country: 'US',
    city: 'Cibolo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Guadalupe',
    latitude: 29.575,
    longitude: -98.228,
    postal_code: '78108'
  },
  '78109': {
    country: 'US',
    city: 'Converse',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5173,
    longitude: -98.3217,
    postal_code: '78109'
  },
  '78111': {
    country: 'US',
    city: 'Ecleto',
    state: 'Texas',
    state_short: 'TX',
    county: 'Karnes',
    latitude: 29.0464,
    longitude: -97.7514,
    postal_code: '78111'
  },
  '78112': {
    country: 'US',
    city: 'Elmendorf',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.2308,
    longitude: -98.372,
    postal_code: '78112'
  },
  '78113': {
    country: 'US',
    city: 'Falls City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Karnes',
    latitude: 28.9814,
    longitude: -98.0156,
    postal_code: '78113'
  },
  '78114': {
    country: 'US',
    city: 'Floresville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilson',
    latitude: 29.1693,
    longitude: -98.1936,
    postal_code: '78114'
  },
  '78115': {
    country: 'US',
    city: 'Geronimo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Guadalupe',
    latitude: 29.5451,
    longitude: -98.0408,
    postal_code: '78115'
  },
  '78116': {
    country: 'US',
    city: 'Gillett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Karnes',
    latitude: 29.0514,
    longitude: -97.8343,
    postal_code: '78116'
  },
  '78117': {
    country: 'US',
    city: 'Hobson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Karnes',
    latitude: 28.9445,
    longitude: -97.9707,
    postal_code: '78117'
  },
  '78118': {
    country: 'US',
    city: 'Karnes City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Karnes',
    latitude: 28.8828,
    longitude: -97.9071,
    postal_code: '78118'
  },
  '78119': {
    country: 'US',
    city: 'Kenedy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Karnes',
    latitude: 28.8046,
    longitude: -97.8456,
    postal_code: '78119'
  },
  '78121': {
    country: 'US',
    city: 'La Vernia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilson',
    latitude: 29.3509,
    longitude: -98.113,
    postal_code: '78121'
  },
  '78122': {
    country: 'US',
    city: 'Leesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gonzales',
    latitude: 29.3961,
    longitude: -97.7566,
    postal_code: '78122'
  },
  '78123': {
    country: 'US',
    city: 'Mc Queeney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Guadalupe',
    latitude: 29.6023,
    longitude: -98.0492,
    postal_code: '78123'
  },
  '78124': {
    country: 'US',
    city: 'Marion',
    state: 'Texas',
    state_short: 'TX',
    county: 'Guadalupe',
    latitude: 29.5683,
    longitude: -98.1517,
    postal_code: '78124'
  },
  '78125': {
    country: 'US',
    city: 'Mineral',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bee',
    latitude: 28.5552,
    longitude: -97.9407,
    postal_code: '78125'
  },
  '78130': {
    country: 'US',
    city: 'New Braunfels',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comal',
    latitude: 29.7229,
    longitude: -98.0742,
    postal_code: '78130'
  },
  '78131': {
    country: 'US',
    city: 'New Braunfels',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comal',
    latitude: 29.703,
    longitude: -98.1245,
    postal_code: '78131'
  },
  '78132': {
    country: 'US',
    city: 'New Braunfels',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comal',
    latitude: 29.7565,
    longitude: -98.1983,
    postal_code: '78132'
  },
  '78133': {
    country: 'US',
    city: 'Canyon Lake',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comal',
    latitude: 29.9112,
    longitude: -98.2374,
    postal_code: '78133'
  },
  '78135': {
    country: 'US',
    city: 'New Braunfels',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comal',
    latitude: 29.703,
    longitude: -98.1245,
    postal_code: '78135'
  },
  '78140': {
    country: 'US',
    city: 'Nixon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gonzales',
    latitude: 29.3016,
    longitude: -97.7529,
    postal_code: '78140'
  },
  '78141': {
    country: 'US',
    city: 'Nordheim',
    state: 'Texas',
    state_short: 'TX',
    county: 'DeWitt',
    latitude: 28.9142,
    longitude: -97.5946,
    postal_code: '78141'
  },
  '78142': {
    country: 'US',
    city: 'Normanna',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bee',
    latitude: 28.5278,
    longitude: -97.7831,
    postal_code: '78142'
  },
  '78143': {
    country: 'US',
    city: 'Pandora',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilson',
    latitude: 29.2484,
    longitude: -97.8287,
    postal_code: '78143'
  },
  '78144': {
    country: 'US',
    city: 'Panna Maria',
    state: 'Texas',
    state_short: 'TX',
    county: 'Karnes',
    latitude: 28.9562,
    longitude: -97.8982,
    postal_code: '78144'
  },
  '78145': {
    country: 'US',
    city: 'Pawnee',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bee',
    latitude: 28.6526,
    longitude: -97.9921,
    postal_code: '78145'
  },
  '78146': {
    country: 'US',
    city: 'Pettus',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bee',
    latitude: 28.6164,
    longitude: -97.8082,
    postal_code: '78146'
  },
  '78147': {
    country: 'US',
    city: 'Poth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilson',
    latitude: 29.0619,
    longitude: -98.0825,
    postal_code: '78147'
  },
  '78148': {
    country: 'US',
    city: 'Universal City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5465,
    longitude: -98.2954,
    postal_code: '78148'
  },
  '78150': {
    country: 'US',
    city: 'Jbsa Randolph',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3759,
    longitude: -97.958,
    postal_code: '78150'
  },
  '78151': {
    country: 'US',
    city: 'Runge',
    state: 'Texas',
    state_short: 'TX',
    county: 'Karnes',
    latitude: 28.8876,
    longitude: -97.7138,
    postal_code: '78151'
  },
  '78152': {
    country: 'US',
    city: 'Saint Hedwig',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4353,
    longitude: -98.1952,
    postal_code: '78152'
  },
  '78154': {
    country: 'US',
    city: 'Schertz',
    state: 'Texas',
    state_short: 'TX',
    county: 'Guadalupe',
    latitude: 29.579,
    longitude: -98.2778,
    postal_code: '78154'
  },
  '78155': {
    country: 'US',
    city: 'Seguin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Guadalupe',
    latitude: 29.5613,
    longitude: -97.9628,
    postal_code: '78155'
  },
  '78156': {
    country: 'US',
    city: 'Seguin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Guadalupe',
    latitude: 29.6118,
    longitude: -97.9712,
    postal_code: '78156'
  },
  '78159': {
    country: 'US',
    city: 'Smiley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gonzales',
    latitude: 29.2655,
    longitude: -97.6227,
    postal_code: '78159'
  },
  '78160': {
    country: 'US',
    city: 'Stockdale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilson',
    latitude: 29.2319,
    longitude: -97.9351,
    postal_code: '78160'
  },
  '78161': {
    country: 'US',
    city: 'Sutherland Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilson',
    latitude: 29.2888,
    longitude: -98.0509,
    postal_code: '78161'
  },
  '78162': {
    country: 'US',
    city: 'Tuleta',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bee',
    latitude: 28.5708,
    longitude: -97.7972,
    postal_code: '78162'
  },
  '78163': {
    country: 'US',
    city: 'Bulverde',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comal',
    latitude: 29.7767,
    longitude: -98.4626,
    postal_code: '78163'
  },
  '78164': {
    country: 'US',
    city: 'Yorktown',
    state: 'Texas',
    state_short: 'TX',
    county: 'DeWitt',
    latitude: 28.9892,
    longitude: -97.5121,
    postal_code: '78164'
  },
  '78201': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4711,
    longitude: -98.5356,
    postal_code: '78201'
  },
  '78202': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4275,
    longitude: -98.4601,
    postal_code: '78202'
  },
  '78203': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4148,
    longitude: -98.4601,
    postal_code: '78203'
  },
  '78204': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4059,
    longitude: -98.5078,
    postal_code: '78204'
  },
  '78205': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4237,
    longitude: -98.4925,
    postal_code: '78205'
  },
  '78206': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78206'
  },
  '78207': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4229,
    longitude: -98.526,
    postal_code: '78207'
  },
  '78208': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.44,
    longitude: -98.459,
    postal_code: '78208'
  },
  '78209': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4821,
    longitude: -98.4554,
    postal_code: '78209'
  },
  '78210': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3977,
    longitude: -98.4658,
    postal_code: '78210'
  },
  '78211': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3499,
    longitude: -98.5638,
    postal_code: '78211'
  },
  '78212': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4388,
    longitude: -98.4935,
    postal_code: '78212'
  },
  '78213': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5234,
    longitude: -98.5273,
    postal_code: '78213'
  },
  '78214': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3641,
    longitude: -98.4924,
    postal_code: '78214'
  },
  '78215': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4413,
    longitude: -98.4793,
    postal_code: '78215'
  },
  '78216': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5334,
    longitude: -98.4975,
    postal_code: '78216'
  },
  '78217': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5395,
    longitude: -98.4194,
    postal_code: '78217'
  },
  '78218': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4969,
    longitude: -98.4032,
    postal_code: '78218'
  },
  '78219': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4492,
    longitude: -98.3446,
    postal_code: '78219'
  },
  '78220': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4106,
    longitude: -98.4128,
    postal_code: '78220'
  },
  '78221': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3309,
    longitude: -98.5054,
    postal_code: '78221'
  },
  '78222': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3831,
    longitude: -98.396,
    postal_code: '78222'
  },
  '78223': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3579,
    longitude: -98.4356,
    postal_code: '78223'
  },
  '78224': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3374,
    longitude: -98.5393,
    postal_code: '78224'
  },
  '78225': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3875,
    longitude: -98.5245,
    postal_code: '78225'
  },
  '78226': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.393,
    longitude: -98.5511,
    postal_code: '78226'
  },
  '78227': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4027,
    longitude: -98.6433,
    postal_code: '78227'
  },
  '78228': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4589,
    longitude: -98.5699,
    postal_code: '78228'
  },
  '78229': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5042,
    longitude: -98.5697,
    postal_code: '78229'
  },
  '78230': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5407,
    longitude: -98.5521,
    postal_code: '78230'
  },
  '78231': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5714,
    longitude: -98.5414,
    postal_code: '78231'
  },
  '78232': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5863,
    longitude: -98.4769,
    postal_code: '78232'
  },
  '78233': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5563,
    longitude: -98.3643,
    postal_code: '78233'
  },
  '78234': {
    country: 'US',
    city: 'Jbsa Ft Sam Houston',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4241,
    longitude: -98.4936,
    postal_code: '78234'
  },
  '78235': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3494,
    longitude: -98.4422,
    postal_code: '78235'
  },
  '78236': {
    country: 'US',
    city: 'Jbsa Lackland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4241,
    longitude: -98.4936,
    postal_code: '78236'
  },
  '78237': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4208,
    longitude: -98.5645,
    postal_code: '78237'
  },
  '78238': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.451,
    longitude: -98.6169,
    postal_code: '78238'
  },
  '78239': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5182,
    longitude: -98.3627,
    postal_code: '78239'
  },
  '78240': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5189,
    longitude: -98.6006,
    postal_code: '78240'
  },
  '78241': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78241'
  },
  '78242': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3509,
    longitude: -98.6109,
    postal_code: '78242'
  },
  '78243': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78243'
  },
  '78244': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4793,
    longitude: -98.3476,
    postal_code: '78244'
  },
  '78245': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4189,
    longitude: -98.6895,
    postal_code: '78245'
  },
  '78246': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78246'
  },
  '78247': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5855,
    longitude: -98.4071,
    postal_code: '78247'
  },
  '78248': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5894,
    longitude: -98.5201,
    postal_code: '78248'
  },
  '78249': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5612,
    longitude: -98.6117,
    postal_code: '78249'
  },
  '78250': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5054,
    longitude: -98.6688,
    postal_code: '78250'
  },
  '78251': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4597,
    longitude: -98.6555,
    postal_code: '78251'
  },
  '78252': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.346,
    longitude: -98.6464,
    postal_code: '78252'
  },
  '78253': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4599,
    longitude: -98.7479,
    postal_code: '78253'
  },
  '78254': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.5551,
    longitude: -98.7442,
    postal_code: '78254'
  },
  '78255': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.6701,
    longitude: -98.6873,
    postal_code: '78255'
  },
  '78256': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.6169,
    longitude: -98.6252,
    postal_code: '78256'
  },
  '78257': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.6495,
    longitude: -98.6137,
    postal_code: '78257'
  },
  '78258': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.6562,
    longitude: -98.4967,
    postal_code: '78258'
  },
  '78259': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.6283,
    longitude: -98.4445,
    postal_code: '78259'
  },
  '78260': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.7026,
    longitude: -98.4759,
    postal_code: '78260'
  },
  '78261': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.7055,
    longitude: -98.4191,
    postal_code: '78261'
  },
  '78263': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.3614,
    longitude: -98.3174,
    postal_code: '78263'
  },
  '78264': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.1733,
    longitude: -98.4723,
    postal_code: '78264'
  },
  '78265': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78265'
  },
  '78266': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comal',
    latitude: 29.6643,
    longitude: -98.3118,
    postal_code: '78266'
  },
  '78268': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78268'
  },
  '78269': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78269'
  },
  '78270': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78270'
  },
  '78278': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78278'
  },
  '78279': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78279'
  },
  '78280': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78280'
  },
  '78283': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78283'
  },
  '78284': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4426,
    longitude: -98.4913,
    postal_code: '78284'
  },
  '78285': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78285'
  },
  '78288': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4241,
    longitude: -98.4936,
    postal_code: '78288'
  },
  '78289': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78289'
  },
  '78291': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78291'
  },
  '78292': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78292'
  },
  '78293': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78293'
  },
  '78294': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78294'
  },
  '78295': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78295'
  },
  '78296': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78296'
  },
  '78297': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78297'
  },
  '78298': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78298'
  },
  '78299': {
    country: 'US',
    city: 'San Antonio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bexar',
    latitude: 29.4375,
    longitude: -98.4616,
    postal_code: '78299'
  },
  '78330': {
    country: 'US',
    city: 'Agua Dulce',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7817,
    longitude: -97.9086,
    postal_code: '78330'
  },
  '78332': {
    country: 'US',
    city: 'Alice',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jim Wells',
    latitude: 27.7432,
    longitude: -98.0836,
    postal_code: '78332'
  },
  '78333': {
    country: 'US',
    city: 'Alice',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jim Wells',
    latitude: 27.7522,
    longitude: -98.0697,
    postal_code: '78333'
  },
  '78335': {
    country: 'US',
    city: 'Aransas Pass',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Patricio',
    latitude: 27.9125,
    longitude: -97.1884,
    postal_code: '78335'
  },
  '78336': {
    country: 'US',
    city: 'Aransas Pass',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Patricio',
    latitude: 27.9095,
    longitude: -97.1591,
    postal_code: '78336'
  },
  '78338': {
    country: 'US',
    city: 'Armstrong',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kenedy',
    latitude: 26.8698,
    longitude: -97.7698,
    postal_code: '78338'
  },
  '78339': {
    country: 'US',
    city: 'Banquete',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.8051,
    longitude: -97.7922,
    postal_code: '78339'
  },
  '78340': {
    country: 'US',
    city: 'Bayside',
    state: 'Texas',
    state_short: 'TX',
    county: 'Refugio',
    latitude: 28.0968,
    longitude: -97.2106,
    postal_code: '78340'
  },
  '78341': {
    country: 'US',
    city: 'Benavides',
    state: 'Texas',
    state_short: 'TX',
    county: 'Duval',
    latitude: 27.5925,
    longitude: -98.4142,
    postal_code: '78341'
  },
  '78342': {
    country: 'US',
    city: 'Ben Bolt',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jim Wells',
    latitude: 27.6475,
    longitude: -98.0814,
    postal_code: '78342'
  },
  '78343': {
    country: 'US',
    city: 'Bishop',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.5886,
    longitude: -97.783,
    postal_code: '78343'
  },
  '78344': {
    country: 'US',
    city: 'Bruni',
    state: 'Texas',
    state_short: 'TX',
    county: 'Webb',
    latitude: 27.4295,
    longitude: -98.8385,
    postal_code: '78344'
  },
  '78347': {
    country: 'US',
    city: 'Chapman Ranch',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.5988,
    longitude: -97.462,
    postal_code: '78347'
  },
  '78349': {
    country: 'US',
    city: 'Concepcion',
    state: 'Texas',
    state_short: 'TX',
    county: 'Duval',
    latitude: 27.3849,
    longitude: -98.309,
    postal_code: '78349'
  },
  '78350': {
    country: 'US',
    city: 'Dinero',
    state: 'Texas',
    state_short: 'TX',
    county: 'Live Oak',
    latitude: 28.2264,
    longitude: -97.9617,
    postal_code: '78350'
  },
  '78351': {
    country: 'US',
    city: 'Driscoll',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.6745,
    longitude: -97.7486,
    postal_code: '78351'
  },
  '78352': {
    country: 'US',
    city: 'Edroy',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Patricio',
    latitude: 27.9736,
    longitude: -97.6761,
    postal_code: '78352'
  },
  '78353': {
    country: 'US',
    city: 'Encino',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brooks',
    latitude: 26.9249,
    longitude: -98.1922,
    postal_code: '78353'
  },
  '78355': {
    country: 'US',
    city: 'Falfurrias',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brooks',
    latitude: 27.2242,
    longitude: -98.1408,
    postal_code: '78355'
  },
  '78357': {
    country: 'US',
    city: 'Freer',
    state: 'Texas',
    state_short: 'TX',
    county: 'Duval',
    latitude: 27.88,
    longitude: -98.6061,
    postal_code: '78357'
  },
  '78358': {
    country: 'US',
    city: 'Fulton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Aransas',
    latitude: 28.0614,
    longitude: -97.0411,
    postal_code: '78358'
  },
  '78359': {
    country: 'US',
    city: 'Gregory',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Patricio',
    latitude: 27.9222,
    longitude: -97.29,
    postal_code: '78359'
  },
  '78360': {
    country: 'US',
    city: 'Guerra',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jim Hogg',
    latitude: 26.8831,
    longitude: -98.8936,
    postal_code: '78360'
  },
  '78361': {
    country: 'US',
    city: 'Hebbronville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jim Hogg',
    latitude: 27.2997,
    longitude: -98.6829,
    postal_code: '78361'
  },
  '78362': {
    country: 'US',
    city: 'Ingleside',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Patricio',
    latitude: 27.8682,
    longitude: -97.2069,
    postal_code: '78362'
  },
  '78363': {
    country: 'US',
    city: 'Kingsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kleberg',
    latitude: 27.4229,
    longitude: -97.8407,
    postal_code: '78363'
  },
  '78364': {
    country: 'US',
    city: 'Kingsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kleberg',
    latitude: 27.5159,
    longitude: -97.8561,
    postal_code: '78364'
  },
  '78368': {
    country: 'US',
    city: 'Mathis',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Patricio',
    latitude: 28.0802,
    longitude: -97.8097,
    postal_code: '78368'
  },
  '78369': {
    country: 'US',
    city: 'Mirando City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Webb',
    latitude: 27.445,
    longitude: -99.0011,
    postal_code: '78369'
  },
  '78370': {
    country: 'US',
    city: 'Odem',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Patricio',
    latitude: 27.9403,
    longitude: -97.5838,
    postal_code: '78370'
  },
  '78371': {
    country: 'US',
    city: 'Oilton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Webb',
    latitude: 27.4685,
    longitude: -98.9586,
    postal_code: '78371'
  },
  '78372': {
    country: 'US',
    city: 'Orange Grove',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jim Wells',
    latitude: 27.9487,
    longitude: -97.9838,
    postal_code: '78372'
  },
  '78373': {
    country: 'US',
    city: 'Port Aransas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7707,
    longitude: -97.1051,
    postal_code: '78373'
  },
  '78374': {
    country: 'US',
    city: 'Portland',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Patricio',
    latitude: 27.8935,
    longitude: -97.3169,
    postal_code: '78374'
  },
  '78375': {
    country: 'US',
    city: 'Premont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jim Wells',
    latitude: 27.3544,
    longitude: -98.133,
    postal_code: '78375'
  },
  '78376': {
    country: 'US',
    city: 'Realitos',
    state: 'Texas',
    state_short: 'TX',
    county: 'Duval',
    latitude: 27.4184,
    longitude: -98.514,
    postal_code: '78376'
  },
  '78377': {
    country: 'US',
    city: 'Refugio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Refugio',
    latitude: 28.3169,
    longitude: -97.2767,
    postal_code: '78377'
  },
  '78379': {
    country: 'US',
    city: 'Riviera',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kleberg',
    latitude: 27.3217,
    longitude: -97.7787,
    postal_code: '78379'
  },
  '78380': {
    country: 'US',
    city: 'Robstown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7984,
    longitude: -97.6995,
    postal_code: '78380'
  },
  '78381': {
    country: 'US',
    city: 'Rockport',
    state: 'Texas',
    state_short: 'TX',
    county: 'Aransas',
    latitude: 28.0131,
    longitude: -97.0936,
    postal_code: '78381'
  },
  '78382': {
    country: 'US',
    city: 'Rockport',
    state: 'Texas',
    state_short: 'TX',
    county: 'Aransas',
    latitude: 28.0308,
    longitude: -97.0688,
    postal_code: '78382'
  },
  '78383': {
    country: 'US',
    city: 'Sandia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jim Wells',
    latitude: 28.025,
    longitude: -97.8705,
    postal_code: '78383'
  },
  '78384': {
    country: 'US',
    city: 'San Diego',
    state: 'Texas',
    state_short: 'TX',
    county: 'Duval',
    latitude: 27.7654,
    longitude: -98.2503,
    postal_code: '78384'
  },
  '78385': {
    country: 'US',
    city: 'Sarita',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kenedy',
    latitude: 27.1496,
    longitude: -97.8576,
    postal_code: '78385'
  },
  '78387': {
    country: 'US',
    city: 'Sinton',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Patricio',
    latitude: 28.0339,
    longitude: -97.5196,
    postal_code: '78387'
  },
  '78389': {
    country: 'US',
    city: 'Skidmore',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bee',
    latitude: 28.2121,
    longitude: -97.7127,
    postal_code: '78389'
  },
  '78390': {
    country: 'US',
    city: 'Taft',
    state: 'Texas',
    state_short: 'TX',
    county: 'San Patricio',
    latitude: 27.9765,
    longitude: -97.3966,
    postal_code: '78390'
  },
  '78391': {
    country: 'US',
    city: 'Tynan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bee',
    latitude: 28.1693,
    longitude: -97.7549,
    postal_code: '78391'
  },
  '78393': {
    country: 'US',
    city: 'Woodsboro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Refugio',
    latitude: 28.2232,
    longitude: -97.3192,
    postal_code: '78393'
  },
  '78401': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7941,
    longitude: -97.403,
    postal_code: '78401'
  },
  '78402': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.8262,
    longitude: -97.3857,
    postal_code: '78402'
  },
  '78403': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.777,
    longitude: -97.4632,
    postal_code: '78403'
  },
  '78404': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7683,
    longitude: -97.4013,
    postal_code: '78404'
  },
  '78405': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7762,
    longitude: -97.4271,
    postal_code: '78405'
  },
  '78406': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7684,
    longitude: -97.5144,
    postal_code: '78406'
  },
  '78407': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.8042,
    longitude: -97.4356,
    postal_code: '78407'
  },
  '78408': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7945,
    longitude: -97.4381,
    postal_code: '78408'
  },
  '78409': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.8146,
    longitude: -97.527,
    postal_code: '78409'
  },
  '78410': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.8458,
    longitude: -97.596,
    postal_code: '78410'
  },
  '78411': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7311,
    longitude: -97.3877,
    postal_code: '78411'
  },
  '78412': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7061,
    longitude: -97.3537,
    postal_code: '78412'
  },
  '78413': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.691,
    longitude: -97.3983,
    postal_code: '78413'
  },
  '78414': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.677,
    longitude: -97.365,
    postal_code: '78414'
  },
  '78415': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7262,
    longitude: -97.4078,
    postal_code: '78415'
  },
  '78416': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7536,
    longitude: -97.4347,
    postal_code: '78416'
  },
  '78417': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.729,
    longitude: -97.4494,
    postal_code: '78417'
  },
  '78418': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.6349,
    longitude: -97.3103,
    postal_code: '78418'
  },
  '78419': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.6952,
    longitude: -97.2694,
    postal_code: '78419'
  },
  '78426': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.777,
    longitude: -97.4632,
    postal_code: '78426'
  },
  '78427': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.8006,
    longitude: -97.3964,
    postal_code: '78427'
  },
  '78460': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.8006,
    longitude: -97.3964,
    postal_code: '78460'
  },
  '78463': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.777,
    longitude: -97.4632,
    postal_code: '78463'
  },
  '78465': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.777,
    longitude: -97.4632,
    postal_code: '78465'
  },
  '78466': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.777,
    longitude: -97.4632,
    postal_code: '78466'
  },
  '78467': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.777,
    longitude: -97.4632,
    postal_code: '78467'
  },
  '78468': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.777,
    longitude: -97.4632,
    postal_code: '78468'
  },
  '78469': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.777,
    longitude: -97.4632,
    postal_code: '78469'
  },
  '78472': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.7402,
    longitude: -97.5792,
    postal_code: '78472'
  },
  '78480': {
    country: 'US',
    city: 'Corpus Christi',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nueces',
    latitude: 27.777,
    longitude: -97.4632,
    postal_code: '78480'
  },
  '78501': {
    country: 'US',
    city: 'Mcallen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2154,
    longitude: -98.2359,
    postal_code: '78501'
  },
  '78502': {
    country: 'US',
    city: 'Mcallen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2567,
    longitude: -98.1989,
    postal_code: '78502'
  },
  '78503': {
    country: 'US',
    city: 'Mcallen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.1771,
    longitude: -98.252,
    postal_code: '78503'
  },
  '78504': {
    country: 'US',
    city: 'Mcallen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2556,
    longitude: -98.2303,
    postal_code: '78504'
  },
  '78505': {
    country: 'US',
    city: 'Mcallen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2034,
    longitude: -98.23,
    postal_code: '78505'
  },
  '78516': {
    country: 'US',
    city: 'Alamo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.1906,
    longitude: -98.1164,
    postal_code: '78516'
  },
  '78520': {
    country: 'US',
    city: 'Brownsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 25.9337,
    longitude: -97.5174,
    postal_code: '78520'
  },
  '78521': {
    country: 'US',
    city: 'Brownsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 25.9221,
    longitude: -97.4612,
    postal_code: '78521'
  },
  '78522': {
    country: 'US',
    city: 'Brownsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 25.9017,
    longitude: -97.4975,
    postal_code: '78522'
  },
  '78523': {
    country: 'US',
    city: 'Brownsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 25.981,
    longitude: -97.5209,
    postal_code: '78523'
  },
  '78526': {
    country: 'US',
    city: 'Brownsville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 25.9717,
    longitude: -97.4699,
    postal_code: '78526'
  },
  '78535': {
    country: 'US',
    city: 'Combes',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.2451,
    longitude: -97.7416,
    postal_code: '78535'
  },
  '78536': {
    country: 'US',
    city: 'Delmita',
    state: 'Texas',
    state_short: 'TX',
    county: 'Starr',
    latitude: 26.6801,
    longitude: -98.4125,
    postal_code: '78536'
  },
  '78537': {
    country: 'US',
    city: 'Donna',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.1671,
    longitude: -98.0529,
    postal_code: '78537'
  },
  '78538': {
    country: 'US',
    city: 'Edcouch',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.3328,
    longitude: -97.9622,
    postal_code: '78538'
  },
  '78539': {
    country: 'US',
    city: 'Edinburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2792,
    longitude: -98.1832,
    postal_code: '78539'
  },
  '78540': {
    country: 'US',
    city: 'Edinburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.3017,
    longitude: -98.1633,
    postal_code: '78540'
  },
  '78541': {
    country: 'US',
    city: 'Edinburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.4481,
    longitude: -98.2842,
    postal_code: '78541'
  },
  '78542': {
    country: 'US',
    city: 'Edinburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.4661,
    longitude: -98.0695,
    postal_code: '78542'
  },
  '78543': {
    country: 'US',
    city: 'Elsa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2974,
    longitude: -97.9884,
    postal_code: '78543'
  },
  '78545': {
    country: 'US',
    city: 'Falcon Heights',
    state: 'Texas',
    state_short: 'TX',
    county: 'Starr',
    latitude: 26.562,
    longitude: -99.1335,
    postal_code: '78545'
  },
  '78547': {
    country: 'US',
    city: 'Garciasville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Starr',
    latitude: 26.3396,
    longitude: -98.6876,
    postal_code: '78547'
  },
  '78548': {
    country: 'US',
    city: 'Grulla',
    state: 'Texas',
    state_short: 'TX',
    county: 'Starr',
    latitude: 26.2897,
    longitude: -98.6479,
    postal_code: '78548'
  },
  '78549': {
    country: 'US',
    city: 'Hargill',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.4671,
    longitude: -98.0393,
    postal_code: '78549'
  },
  '78550': {
    country: 'US',
    city: 'Harlingen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.1951,
    longitude: -97.689,
    postal_code: '78550'
  },
  '78551': {
    country: 'US',
    city: 'Harlingen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.2447,
    longitude: -97.7206,
    postal_code: '78551'
  },
  '78552': {
    country: 'US',
    city: 'Harlingen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.1831,
    longitude: -97.7468,
    postal_code: '78552'
  },
  '78553': {
    country: 'US',
    city: 'Harlingen',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.1906,
    longitude: -97.6961,
    postal_code: '78553'
  },
  '78557': {
    country: 'US',
    city: 'Hidalgo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.1028,
    longitude: -98.2536,
    postal_code: '78557'
  },
  '78558': {
    country: 'US',
    city: 'La Blanca',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2928,
    longitude: -98.0378,
    postal_code: '78558'
  },
  '78559': {
    country: 'US',
    city: 'La Feria',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.1666,
    longitude: -97.8261,
    postal_code: '78559'
  },
  '78560': {
    country: 'US',
    city: 'La Joya',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2426,
    longitude: -98.4747,
    postal_code: '78560'
  },
  '78561': {
    country: 'US',
    city: 'Lasara',
    state: 'Texas',
    state_short: 'TX',
    county: 'Willacy',
    latitude: 26.4648,
    longitude: -97.9111,
    postal_code: '78561'
  },
  '78562': {
    country: 'US',
    city: 'La Villa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2999,
    longitude: -97.9247,
    postal_code: '78562'
  },
  '78563': {
    country: 'US',
    city: 'Linn',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.6279,
    longitude: -98.2498,
    postal_code: '78563'
  },
  '78564': {
    country: 'US',
    city: 'Lopeno',
    state: 'Texas',
    state_short: 'TX',
    county: 'Zapata',
    latitude: 26.7114,
    longitude: -99.11,
    postal_code: '78564'
  },
  '78565': {
    country: 'US',
    city: 'Los Ebanos',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2434,
    longitude: -98.5617,
    postal_code: '78565'
  },
  '78566': {
    country: 'US',
    city: 'Los Fresnos',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.1158,
    longitude: -97.4107,
    postal_code: '78566'
  },
  '78567': {
    country: 'US',
    city: 'Los Indios',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.0492,
    longitude: -97.745,
    postal_code: '78567'
  },
  '78568': {
    country: 'US',
    city: 'Lozano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.1904,
    longitude: -97.5423,
    postal_code: '78568'
  },
  '78569': {
    country: 'US',
    city: 'Lyford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Willacy',
    latitude: 26.4089,
    longitude: -97.7817,
    postal_code: '78569'
  },
  '78570': {
    country: 'US',
    city: 'Mercedes',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.1513,
    longitude: -97.9185,
    postal_code: '78570'
  },
  '78572': {
    country: 'US',
    city: 'Mission',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2159,
    longitude: -98.3253,
    postal_code: '78572'
  },
  '78573': {
    country: 'US',
    city: 'Mission',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2937,
    longitude: -98.3008,
    postal_code: '78573'
  },
  '78574': {
    country: 'US',
    city: 'Mission',
    state: 'Texas',
    state_short: 'TX',
    county: 'Willacy',
    latitude: 26.3189,
    longitude: -98.3701,
    postal_code: '78574'
  },
  '78575': {
    country: 'US',
    city: 'Olmito',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.0353,
    longitude: -97.5496,
    postal_code: '78575'
  },
  '78576': {
    country: 'US',
    city: 'Penitas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.278,
    longitude: -98.4469,
    postal_code: '78576'
  },
  '78577': {
    country: 'US',
    city: 'Pharr',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.1771,
    longitude: -98.187,
    postal_code: '78577'
  },
  '78578': {
    country: 'US',
    city: 'Port Isabel',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.0539,
    longitude: -97.3125,
    postal_code: '78578'
  },
  '78579': {
    country: 'US',
    city: 'Progreso',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.0922,
    longitude: -97.9533,
    postal_code: '78579'
  },
  '78580': {
    country: 'US',
    city: 'Raymondville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Willacy',
    latitude: 26.4792,
    longitude: -97.7967,
    postal_code: '78580'
  },
  '78582': {
    country: 'US',
    city: 'Rio Grande City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Starr',
    latitude: 26.3942,
    longitude: -98.8104,
    postal_code: '78582'
  },
  '78583': {
    country: 'US',
    city: 'Rio Hondo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.2339,
    longitude: -97.5513,
    postal_code: '78583'
  },
  '78584': {
    country: 'US',
    city: 'Roma',
    state: 'Texas',
    state_short: 'TX',
    county: 'Starr',
    latitude: 26.4215,
    longitude: -99.0025,
    postal_code: '78584'
  },
  '78585': {
    country: 'US',
    city: 'Salineno',
    state: 'Texas',
    state_short: 'TX',
    county: 'Starr',
    latitude: 26.5164,
    longitude: -99.1123,
    postal_code: '78585'
  },
  '78586': {
    country: 'US',
    city: 'San Benito',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.1337,
    longitude: -97.6447,
    postal_code: '78586'
  },
  '78588': {
    country: 'US',
    city: 'San Isidro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Starr',
    latitude: 26.7167,
    longitude: -98.4539,
    postal_code: '78588'
  },
  '78589': {
    country: 'US',
    city: 'San Juan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.2044,
    longitude: -98.1537,
    postal_code: '78589'
  },
  '78590': {
    country: 'US',
    city: 'San Perlita',
    state: 'Texas',
    state_short: 'TX',
    county: 'Willacy',
    latitude: 26.5012,
    longitude: -97.6397,
    postal_code: '78590'
  },
  '78591': {
    country: 'US',
    city: 'Santa Elena',
    state: 'Texas',
    state_short: 'TX',
    county: 'Starr',
    latitude: 26.5808,
    longitude: -98.5138,
    postal_code: '78591'
  },
  '78592': {
    country: 'US',
    city: 'Santa Maria',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.0787,
    longitude: -97.8494,
    postal_code: '78592'
  },
  '78593': {
    country: 'US',
    city: 'Santa Rosa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.2555,
    longitude: -97.8257,
    postal_code: '78593'
  },
  '78594': {
    country: 'US',
    city: 'Sebastian',
    state: 'Texas',
    state_short: 'TX',
    county: 'Willacy',
    latitude: 26.344,
    longitude: -97.8123,
    postal_code: '78594'
  },
  '78595': {
    country: 'US',
    city: 'Sullivan City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.272,
    longitude: -98.5627,
    postal_code: '78595'
  },
  '78596': {
    country: 'US',
    city: 'Weslaco',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.1694,
    longitude: -97.9887,
    postal_code: '78596'
  },
  '78597': {
    country: 'US',
    city: 'South Padre Island',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cameron',
    latitude: 26.2393,
    longitude: -97.1936,
    postal_code: '78597'
  },
  '78598': {
    country: 'US',
    city: 'Port Mansfield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Willacy',
    latitude: 26.5548,
    longitude: -97.425,
    postal_code: '78598'
  },
  '78599': {
    country: 'US',
    city: 'Weslaco',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hidalgo',
    latitude: 26.1595,
    longitude: -97.9908,
    postal_code: '78599'
  },
  '78602': {
    country: 'US',
    city: 'Bastrop',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bastrop',
    latitude: 30.1388,
    longitude: -97.2921,
    postal_code: '78602'
  },
  '78604': {
    country: 'US',
    city: 'Belmont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gonzales',
    latitude: 29.5233,
    longitude: -97.6839,
    postal_code: '78604'
  },
  '78605': {
    country: 'US',
    city: 'Bertram',
    state: 'Texas',
    state_short: 'TX',
    county: 'Burnet',
    latitude: 30.7411,
    longitude: -98.0529,
    postal_code: '78605'
  },
  '78606': {
    country: 'US',
    city: 'Blanco',
    state: 'Texas',
    state_short: 'TX',
    county: 'Blanco',
    latitude: 30.0874,
    longitude: -98.4107,
    postal_code: '78606'
  },
  '78607': {
    country: 'US',
    city: 'Bluffton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Llano',
    latitude: 30.8256,
    longitude: -98.515,
    postal_code: '78607'
  },
  '78608': {
    country: 'US',
    city: 'Briggs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Burnet',
    latitude: 30.9325,
    longitude: -97.9702,
    postal_code: '78608'
  },
  '78609': {
    country: 'US',
    city: 'Buchanan Dam',
    state: 'Texas',
    state_short: 'TX',
    county: 'Llano',
    latitude: 30.7538,
    longitude: -98.4295,
    postal_code: '78609'
  },
  '78610': {
    country: 'US',
    city: 'Buda',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hays',
    latitude: 30.0918,
    longitude: -97.8534,
    postal_code: '78610'
  },
  '78611': {
    country: 'US',
    city: 'Burnet',
    state: 'Texas',
    state_short: 'TX',
    county: 'Burnet',
    latitude: 30.7766,
    longitude: -98.2642,
    postal_code: '78611'
  },
  '78612': {
    country: 'US',
    city: 'Cedar Creek',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bastrop',
    latitude: 30.0966,
    longitude: -97.4976,
    postal_code: '78612'
  },
  '78613': {
    country: 'US',
    city: 'Cedar Park',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.5052,
    longitude: -97.8203,
    postal_code: '78613'
  },
  '78614': {
    country: 'US',
    city: 'Cost',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gonzales',
    latitude: 29.4321,
    longitude: -97.5531,
    postal_code: '78614'
  },
  '78615': {
    country: 'US',
    city: 'Coupland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.4872,
    longitude: -97.3676,
    postal_code: '78615'
  },
  '78616': {
    country: 'US',
    city: 'Dale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Caldwell',
    latitude: 29.9528,
    longitude: -97.581,
    postal_code: '78616'
  },
  '78617': {
    country: 'US',
    city: 'Del Valle',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.1745,
    longitude: -97.6134,
    postal_code: '78617'
  },
  '78618': {
    country: 'US',
    city: 'Doss',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gillespie',
    latitude: 30.4613,
    longitude: -99.1707,
    postal_code: '78618'
  },
  '78619': {
    country: 'US',
    city: 'Driftwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hays',
    latitude: 30.1072,
    longitude: -98.0327,
    postal_code: '78619'
  },
  '78620': {
    country: 'US',
    city: 'Dripping Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hays',
    latitude: 30.2268,
    longitude: -98.1029,
    postal_code: '78620'
  },
  '78621': {
    country: 'US',
    city: 'Elgin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bastrop',
    latitude: 30.3231,
    longitude: -97.3737,
    postal_code: '78621'
  },
  '78622': {
    country: 'US',
    city: 'Fentress',
    state: 'Texas',
    state_short: 'TX',
    county: 'Caldwell',
    latitude: 29.7561,
    longitude: -97.7764,
    postal_code: '78622'
  },
  '78623': {
    country: 'US',
    city: 'Fischer',
    state: 'Texas',
    state_short: 'TX',
    county: 'Comal',
    latitude: 29.9627,
    longitude: -98.2088,
    postal_code: '78623'
  },
  '78624': {
    country: 'US',
    city: 'Fredericksburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gillespie',
    latitude: 30.2817,
    longitude: -98.8799,
    postal_code: '78624'
  },
  '78626': {
    country: 'US',
    city: 'Georgetown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.633,
    longitude: -97.6707,
    postal_code: '78626'
  },
  '78627': {
    country: 'US',
    city: 'Georgetown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.6327,
    longitude: -97.6772,
    postal_code: '78627'
  },
  '78628': {
    country: 'US',
    city: 'Georgetown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.6411,
    longitude: -97.7511,
    postal_code: '78628'
  },
  '78629': {
    country: 'US',
    city: 'Gonzales',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gonzales',
    latitude: 29.5086,
    longitude: -97.4495,
    postal_code: '78629'
  },
  '78630': {
    country: 'US',
    city: 'Cedar Park',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.5052,
    longitude: -97.8203,
    postal_code: '78630'
  },
  '78631': {
    country: 'US',
    city: 'Harper',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gillespie',
    latitude: 30.2816,
    longitude: -99.241,
    postal_code: '78631'
  },
  '78632': {
    country: 'US',
    city: 'Harwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gonzales',
    latitude: 29.6661,
    longitude: -97.4906,
    postal_code: '78632'
  },
  '78633': {
    country: 'US',
    city: 'Georgetown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.7177,
    longitude: -97.7543,
    postal_code: '78633'
  },
  '78634': {
    country: 'US',
    city: 'Hutto',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.5257,
    longitude: -97.5672,
    postal_code: '78634'
  },
  '78635': {
    country: 'US',
    city: 'Hye',
    state: 'Texas',
    state_short: 'TX',
    county: 'Blanco',
    latitude: 30.2269,
    longitude: -98.5396,
    postal_code: '78635'
  },
  '78636': {
    country: 'US',
    city: 'Johnson City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Blanco',
    latitude: 30.2948,
    longitude: -98.3691,
    postal_code: '78636'
  },
  '78638': {
    country: 'US',
    city: 'Kingsbury',
    state: 'Texas',
    state_short: 'TX',
    county: 'Guadalupe',
    latitude: 29.6726,
    longitude: -97.8306,
    postal_code: '78638'
  },
  '78639': {
    country: 'US',
    city: 'Kingsland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Llano',
    latitude: 30.6662,
    longitude: -98.4475,
    postal_code: '78639'
  },
  '78640': {
    country: 'US',
    city: 'Kyle',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hays',
    latitude: 29.9966,
    longitude: -97.8335,
    postal_code: '78640'
  },
  '78641': {
    country: 'US',
    city: 'Leander',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.5835,
    longitude: -97.8575,
    postal_code: '78641'
  },
  '78642': {
    country: 'US',
    city: 'Liberty Hill',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.663,
    longitude: -97.9316,
    postal_code: '78642'
  },
  '78643': {
    country: 'US',
    city: 'Llano',
    state: 'Texas',
    state_short: 'TX',
    county: 'Llano',
    latitude: 30.7151,
    longitude: -98.6109,
    postal_code: '78643'
  },
  '78644': {
    country: 'US',
    city: 'Lockhart',
    state: 'Texas',
    state_short: 'TX',
    county: 'Caldwell',
    latitude: 29.8868,
    longitude: -97.6769,
    postal_code: '78644'
  },
  '78645': {
    country: 'US',
    city: 'Leander',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.449,
    longitude: -97.9669,
    postal_code: '78645'
  },
  '78646': {
    country: 'US',
    city: 'Leander',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.5788,
    longitude: -97.8531,
    postal_code: '78646'
  },
  '78648': {
    country: 'US',
    city: 'Luling',
    state: 'Texas',
    state_short: 'TX',
    county: 'Caldwell',
    latitude: 29.6826,
    longitude: -97.6499,
    postal_code: '78648'
  },
  '78650': {
    country: 'US',
    city: 'Mc Dade',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bastrop',
    latitude: 30.2968,
    longitude: -97.2386,
    postal_code: '78650'
  },
  '78651': {
    country: 'US',
    city: 'Mc Neil',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78651'
  },
  '78652': {
    country: 'US',
    city: 'Manchaca',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.1238,
    longitude: -97.8393,
    postal_code: '78652'
  },
  '78653': {
    country: 'US',
    city: 'Manor',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3388,
    longitude: -97.5323,
    postal_code: '78653'
  },
  '78654': {
    country: 'US',
    city: 'Marble Falls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Burnet',
    latitude: 30.5784,
    longitude: -98.2751,
    postal_code: '78654'
  },
  '78655': {
    country: 'US',
    city: 'Martindale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Caldwell',
    latitude: 29.8394,
    longitude: -97.8508,
    postal_code: '78655'
  },
  '78656': {
    country: 'US',
    city: 'Maxwell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Caldwell',
    latitude: 29.8953,
    longitude: -97.8144,
    postal_code: '78656'
  },
  '78657': {
    country: 'US',
    city: 'Horseshoe Bay',
    state: 'Texas',
    state_short: 'TX',
    county: 'Llano',
    latitude: 30.5443,
    longitude: -98.3739,
    postal_code: '78657'
  },
  '78658': {
    country: 'US',
    city: 'Ottine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gonzales',
    latitude: 29.5952,
    longitude: -97.5911,
    postal_code: '78658'
  },
  '78659': {
    country: 'US',
    city: 'Paige',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bastrop',
    latitude: 30.1858,
    longitude: -97.1198,
    postal_code: '78659'
  },
  '78660': {
    country: 'US',
    city: 'Pflugerville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.4421,
    longitude: -97.6299,
    postal_code: '78660'
  },
  '78661': {
    country: 'US',
    city: 'Prairie Lea',
    state: 'Texas',
    state_short: 'TX',
    county: 'Caldwell',
    latitude: 29.7239,
    longitude: -97.7449,
    postal_code: '78661'
  },
  '78662': {
    country: 'US',
    city: 'Red Rock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bastrop',
    latitude: 29.9906,
    longitude: -97.4081,
    postal_code: '78662'
  },
  '78663': {
    country: 'US',
    city: 'Round Mountain',
    state: 'Texas',
    state_short: 'TX',
    county: 'Blanco',
    latitude: 30.4429,
    longitude: -98.4365,
    postal_code: '78663'
  },
  '78664': {
    country: 'US',
    city: 'Round Rock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.5145,
    longitude: -97.668,
    postal_code: '78664'
  },
  '78665': {
    country: 'US',
    city: 'Round Rock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.5145,
    longitude: -97.668,
    postal_code: '78665'
  },
  '78666': {
    country: 'US',
    city: 'San Marcos',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hays',
    latitude: 29.8754,
    longitude: -97.9404,
    postal_code: '78666'
  },
  '78667': {
    country: 'US',
    city: 'San Marcos',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hays',
    latitude: 30.0544,
    longitude: -98.0036,
    postal_code: '78667'
  },
  '78669': {
    country: 'US',
    city: 'Spicewood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3899,
    longitude: -98.0539,
    postal_code: '78669'
  },
  '78670': {
    country: 'US',
    city: 'Staples',
    state: 'Texas',
    state_short: 'TX',
    county: 'Guadalupe',
    latitude: 29.7706,
    longitude: -97.8186,
    postal_code: '78670'
  },
  '78671': {
    country: 'US',
    city: 'Stonewall',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gillespie',
    latitude: 30.2088,
    longitude: -98.6131,
    postal_code: '78671'
  },
  '78672': {
    country: 'US',
    city: 'Tow',
    state: 'Texas',
    state_short: 'TX',
    county: 'Llano',
    latitude: 30.8609,
    longitude: -98.4596,
    postal_code: '78672'
  },
  '78673': {
    country: 'US',
    city: 'Walburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.7415,
    longitude: -97.5891,
    postal_code: '78673'
  },
  '78674': {
    country: 'US',
    city: 'Weir',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.6747,
    longitude: -97.5929,
    postal_code: '78674'
  },
  '78675': {
    country: 'US',
    city: 'Willow City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gillespie',
    latitude: 30.4549,
    longitude: -98.6646,
    postal_code: '78675'
  },
  '78676': {
    country: 'US',
    city: 'Wimberley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hays',
    latitude: 30.0265,
    longitude: -98.1123,
    postal_code: '78676'
  },
  '78677': {
    country: 'US',
    city: 'Wrightsboro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gonzales',
    latitude: 29.3739,
    longitude: -97.565,
    postal_code: '78677'
  },
  '78680': {
    country: 'US',
    city: 'Round Rock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.6568,
    longitude: -97.6026,
    postal_code: '78680'
  },
  '78681': {
    country: 'US',
    city: 'Round Rock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.5083,
    longitude: -97.6789,
    postal_code: '78681'
  },
  '78682': {
    country: 'US',
    city: 'Round Rock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.6568,
    longitude: -97.6026,
    postal_code: '78682'
  },
  '78683': {
    country: 'US',
    city: 'Round Rock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.6568,
    longitude: -97.6026,
    postal_code: '78683'
  },
  '78691': {
    country: 'US',
    city: 'Pflugerville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.4394,
    longitude: -97.62,
    postal_code: '78691'
  },
  '78701': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2713,
    longitude: -97.7426,
    postal_code: '78701'
  },
  '78702': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2638,
    longitude: -97.7166,
    postal_code: '78702'
  },
  '78703': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2907,
    longitude: -97.7648,
    postal_code: '78703'
  },
  '78704': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2428,
    longitude: -97.7658,
    postal_code: '78704'
  },
  '78705': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2896,
    longitude: -97.7396,
    postal_code: '78705'
  },
  '78708': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78708'
  },
  '78709': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78709'
  },
  '78710': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.352,
    longitude: -97.7151,
    postal_code: '78710'
  },
  '78711': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78711'
  },
  '78712': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2852,
    longitude: -97.7354,
    postal_code: '78712'
  },
  '78713': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2672,
    longitude: -97.7431,
    postal_code: '78713'
  },
  '78714': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2672,
    longitude: -97.7431,
    postal_code: '78714'
  },
  '78715': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2672,
    longitude: -97.7431,
    postal_code: '78715'
  },
  '78716': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3162,
    longitude: -97.8588,
    postal_code: '78716'
  },
  '78717': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.506,
    longitude: -97.7472,
    postal_code: '78717'
  },
  '78718': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78718'
  },
  '78719': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.1802,
    longitude: -97.6667,
    postal_code: '78719'
  },
  '78720': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78720'
  },
  '78721': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2721,
    longitude: -97.6868,
    postal_code: '78721'
  },
  '78722': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2893,
    longitude: -97.715,
    postal_code: '78722'
  },
  '78723': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3085,
    longitude: -97.6849,
    postal_code: '78723'
  },
  '78724': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.296,
    longitude: -97.6396,
    postal_code: '78724'
  },
  '78725': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2562,
    longitude: -97.6243,
    postal_code: '78725'
  },
  '78726': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.43,
    longitude: -97.8326,
    postal_code: '78726'
  },
  '78727': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.4254,
    longitude: -97.7195,
    postal_code: '78727'
  },
  '78728': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.4417,
    longitude: -97.6811,
    postal_code: '78728'
  },
  '78729': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Williamson',
    latitude: 30.4521,
    longitude: -97.7688,
    postal_code: '78729'
  },
  '78730': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3607,
    longitude: -97.8241,
    postal_code: '78730'
  },
  '78731': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3471,
    longitude: -97.7609,
    postal_code: '78731'
  },
  '78732': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3752,
    longitude: -97.9007,
    postal_code: '78732'
  },
  '78733': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3314,
    longitude: -97.8666,
    postal_code: '78733'
  },
  '78734': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3705,
    longitude: -97.9427,
    postal_code: '78734'
  },
  '78735': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.249,
    longitude: -97.8414,
    postal_code: '78735'
  },
  '78736': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2444,
    longitude: -97.916,
    postal_code: '78736'
  },
  '78737': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2107,
    longitude: -97.9427,
    postal_code: '78737'
  },
  '78738': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3337,
    longitude: -97.9824,
    postal_code: '78738'
  },
  '78739': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.172,
    longitude: -97.8784,
    postal_code: '78739'
  },
  '78741': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2315,
    longitude: -97.7223,
    postal_code: '78741'
  },
  '78742': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2313,
    longitude: -97.6703,
    postal_code: '78742'
  },
  '78744': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.1876,
    longitude: -97.7472,
    postal_code: '78744'
  },
  '78745': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2063,
    longitude: -97.7956,
    postal_code: '78745'
  },
  '78746': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2971,
    longitude: -97.8181,
    postal_code: '78746'
  },
  '78747': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.1204,
    longitude: -97.7433,
    postal_code: '78747'
  },
  '78748': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.1743,
    longitude: -97.8225,
    postal_code: '78748'
  },
  '78749': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2166,
    longitude: -97.8508,
    postal_code: '78749'
  },
  '78750': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.4224,
    longitude: -97.7967,
    postal_code: '78750'
  },
  '78751': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3093,
    longitude: -97.7242,
    postal_code: '78751'
  },
  '78752': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3316,
    longitude: -97.7004,
    postal_code: '78752'
  },
  '78753': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3649,
    longitude: -97.6827,
    postal_code: '78753'
  },
  '78754': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3423,
    longitude: -97.6673,
    postal_code: '78754'
  },
  '78755': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78755'
  },
  '78756': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3223,
    longitude: -97.739,
    postal_code: '78756'
  },
  '78757': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3437,
    longitude: -97.7316,
    postal_code: '78757'
  },
  '78758': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3764,
    longitude: -97.7078,
    postal_code: '78758'
  },
  '78759': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.4036,
    longitude: -97.7526,
    postal_code: '78759'
  },
  '78760': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78760'
  },
  '78761': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78761'
  },
  '78762': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78762'
  },
  '78763': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3354,
    longitude: -97.5598,
    postal_code: '78763'
  },
  '78764': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.4455,
    longitude: -97.6595,
    postal_code: '78764'
  },
  '78765': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78765'
  },
  '78766': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2672,
    longitude: -97.7431,
    postal_code: '78766'
  },
  '78767': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2672,
    longitude: -97.7431,
    postal_code: '78767'
  },
  '78768': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2672,
    longitude: -97.7431,
    postal_code: '78768'
  },
  '78769': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78769'
  },
  '78772': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78772'
  },
  '78773': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78773'
  },
  '78774': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78774'
  },
  '78778': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78778'
  },
  '78779': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78779'
  },
  '78783': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.3264,
    longitude: -97.7713,
    postal_code: '78783'
  },
  '78799': {
    country: 'US',
    city: 'Austin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Travis',
    latitude: 30.2672,
    longitude: -97.7431,
    postal_code: '78799'
  },
  '78801': {
    country: 'US',
    city: 'Uvalde',
    state: 'Texas',
    state_short: 'TX',
    county: 'Uvalde',
    latitude: 29.2172,
    longitude: -99.7931,
    postal_code: '78801'
  },
  '78802': {
    country: 'US',
    city: 'Uvalde',
    state: 'Texas',
    state_short: 'TX',
    county: 'Uvalde',
    latitude: 29.2237,
    longitude: -99.7794,
    postal_code: '78802'
  },
  '78827': {
    country: 'US',
    city: 'Asherton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dimmit',
    latitude: 28.4364,
    longitude: -99.7486,
    postal_code: '78827'
  },
  '78828': {
    country: 'US',
    city: 'Barksdale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Edwards',
    latitude: 29.8282,
    longitude: -100.105,
    postal_code: '78828'
  },
  '78829': {
    country: 'US',
    city: 'Batesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Zavala',
    latitude: 28.9286,
    longitude: -99.6115,
    postal_code: '78829'
  },
  '78830': {
    country: 'US',
    city: 'Big Wells',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dimmit',
    latitude: 28.5693,
    longitude: -99.5781,
    postal_code: '78830'
  },
  '78832': {
    country: 'US',
    city: 'Brackettville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kinney',
    latitude: 29.3096,
    longitude: -100.4155,
    postal_code: '78832'
  },
  '78833': {
    country: 'US',
    city: 'Camp Wood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Real',
    latitude: 29.6795,
    longitude: -100.0084,
    postal_code: '78833'
  },
  '78834': {
    country: 'US',
    city: 'Carrizo Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dimmit',
    latitude: 28.5278,
    longitude: -99.8635,
    postal_code: '78834'
  },
  '78836': {
    country: 'US',
    city: 'Catarina',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dimmit',
    latitude: 28.3598,
    longitude: -99.586,
    postal_code: '78836'
  },
  '78837': {
    country: 'US',
    city: 'Comstock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Val Verde',
    latitude: 29.7484,
    longitude: -101.2628,
    postal_code: '78837'
  },
  '78838': {
    country: 'US',
    city: 'Concan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Uvalde',
    latitude: 29.4952,
    longitude: -99.7126,
    postal_code: '78838'
  },
  '78839': {
    country: 'US',
    city: 'Crystal City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Zavala',
    latitude: 28.687,
    longitude: -99.8264,
    postal_code: '78839'
  },
  '78840': {
    country: 'US',
    city: 'Del Rio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Val Verde',
    latitude: 29.4102,
    longitude: -100.8932,
    postal_code: '78840'
  },
  '78841': {
    country: 'US',
    city: 'Del Rio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Val Verde',
    latitude: 29.3465,
    longitude: -100.9289,
    postal_code: '78841'
  },
  '78842': {
    country: 'US',
    city: 'Del Rio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Val Verde',
    latitude: 29.412,
    longitude: -100.9342,
    postal_code: '78842'
  },
  '78843': {
    country: 'US',
    city: 'Laughlin Afb',
    state: 'Texas',
    state_short: 'TX',
    county: 'Val Verde',
    latitude: 29.3627,
    longitude: -100.8968,
    postal_code: '78843'
  },
  '78847': {
    country: 'US',
    city: 'Del Rio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Val Verde',
    latitude: 29.3627,
    longitude: -100.8968,
    postal_code: '78847'
  },
  '78850': {
    country: 'US',
    city: 'D Hanis',
    state: 'Texas',
    state_short: 'TX',
    county: 'Medina',
    latitude: 29.3398,
    longitude: -99.2835,
    postal_code: '78850'
  },
  '78851': {
    country: 'US',
    city: 'Dryden',
    state: 'Texas',
    state_short: 'TX',
    county: 'Terrell',
    latitude: 30.0446,
    longitude: -102.1146,
    postal_code: '78851'
  },
  '78852': {
    country: 'US',
    city: 'Eagle Pass',
    state: 'Texas',
    state_short: 'TX',
    county: 'Maverick',
    latitude: 28.7028,
    longitude: -100.4818,
    postal_code: '78852'
  },
  '78853': {
    country: 'US',
    city: 'Eagle Pass',
    state: 'Texas',
    state_short: 'TX',
    county: 'Maverick',
    latitude: 28.679,
    longitude: -100.4784,
    postal_code: '78853'
  },
  '78860': {
    country: 'US',
    city: 'El Indio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Maverick',
    latitude: 28.5119,
    longitude: -100.3112,
    postal_code: '78860'
  },
  '78861': {
    country: 'US',
    city: 'Hondo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Medina',
    latitude: 29.3475,
    longitude: -99.1414,
    postal_code: '78861'
  },
  '78870': {
    country: 'US',
    city: 'Knippa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Uvalde',
    latitude: 29.2911,
    longitude: -99.6372,
    postal_code: '78870'
  },
  '78871': {
    country: 'US',
    city: 'Langtry',
    state: 'Texas',
    state_short: 'TX',
    county: 'Val Verde',
    latitude: 29.8085,
    longitude: -101.5587,
    postal_code: '78871'
  },
  '78872': {
    country: 'US',
    city: 'La Pryor',
    state: 'Texas',
    state_short: 'TX',
    county: 'Zavala',
    latitude: 28.9488,
    longitude: -99.8507,
    postal_code: '78872'
  },
  '78873': {
    country: 'US',
    city: 'Leakey',
    state: 'Texas',
    state_short: 'TX',
    county: 'Real',
    latitude: 29.769,
    longitude: -99.7479,
    postal_code: '78873'
  },
  '78877': {
    country: 'US',
    city: 'Quemado',
    state: 'Texas',
    state_short: 'TX',
    county: 'Maverick',
    latitude: 28.9256,
    longitude: -100.5912,
    postal_code: '78877'
  },
  '78879': {
    country: 'US',
    city: 'Rio Frio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Real',
    latitude: 29.6495,
    longitude: -99.7165,
    postal_code: '78879'
  },
  '78880': {
    country: 'US',
    city: 'Rocksprings',
    state: 'Texas',
    state_short: 'TX',
    county: 'Edwards',
    latitude: 30.0188,
    longitude: -100.231,
    postal_code: '78880'
  },
  '78881': {
    country: 'US',
    city: 'Sabinal',
    state: 'Texas',
    state_short: 'TX',
    county: 'Uvalde',
    latitude: 29.3267,
    longitude: -99.4781,
    postal_code: '78881'
  },
  '78883': {
    country: 'US',
    city: 'Tarpley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bandera',
    latitude: 29.6455,
    longitude: -99.2469,
    postal_code: '78883'
  },
  '78884': {
    country: 'US',
    city: 'Utopia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Uvalde',
    latitude: 29.5972,
    longitude: -99.5585,
    postal_code: '78884'
  },
  '78885': {
    country: 'US',
    city: 'Vanderpool',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bandera',
    latitude: 29.7452,
    longitude: -99.5551,
    postal_code: '78885'
  },
  '78886': {
    country: 'US',
    city: 'Yancey',
    state: 'Texas',
    state_short: 'TX',
    county: 'Medina',
    latitude: 29.1404,
    longitude: -99.1428,
    postal_code: '78886'
  },
  '78931': {
    country: 'US',
    city: 'Bleiblerville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Austin',
    latitude: 30.0292,
    longitude: -96.4448,
    postal_code: '78931'
  },
  '78932': {
    country: 'US',
    city: 'Carmine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 30.1404,
    longitude: -96.6861,
    postal_code: '78932'
  },
  '78933': {
    country: 'US',
    city: 'Cat Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Austin',
    latitude: 29.7512,
    longitude: -96.39,
    postal_code: '78933'
  },
  '78934': {
    country: 'US',
    city: 'Columbus',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.7032,
    longitude: -96.5527,
    postal_code: '78934'
  },
  '78935': {
    country: 'US',
    city: 'Alleyton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.7092,
    longitude: -96.4865,
    postal_code: '78935'
  },
  '78938': {
    country: 'US',
    city: 'Ellinger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 29.8459,
    longitude: -96.6966,
    postal_code: '78938'
  },
  '78940': {
    country: 'US',
    city: 'Fayetteville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 29.8868,
    longitude: -96.6463,
    postal_code: '78940'
  },
  '78941': {
    country: 'US',
    city: 'Flatonia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 29.7095,
    longitude: -97.0987,
    postal_code: '78941'
  },
  '78942': {
    country: 'US',
    city: 'Giddings',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lee',
    latitude: 30.1777,
    longitude: -96.9332,
    postal_code: '78942'
  },
  '78943': {
    country: 'US',
    city: 'Glidden',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.6994,
    longitude: -96.5942,
    postal_code: '78943'
  },
  '78944': {
    country: 'US',
    city: 'Industry',
    state: 'Texas',
    state_short: 'TX',
    county: 'Austin',
    latitude: 29.9904,
    longitude: -96.4843,
    postal_code: '78944'
  },
  '78945': {
    country: 'US',
    city: 'La Grange',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 29.904,
    longitude: -96.886,
    postal_code: '78945'
  },
  '78946': {
    country: 'US',
    city: 'Ledbetter',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 30.2383,
    longitude: -96.7613,
    postal_code: '78946'
  },
  '78947': {
    country: 'US',
    city: 'Lexington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lee',
    latitude: 30.4191,
    longitude: -97.0524,
    postal_code: '78947'
  },
  '78948': {
    country: 'US',
    city: 'Lincoln',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lee',
    latitude: 30.3177,
    longitude: -96.9702,
    postal_code: '78948'
  },
  '78949': {
    country: 'US',
    city: 'Muldoon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 29.7993,
    longitude: -97.1006,
    postal_code: '78949'
  },
  '78950': {
    country: 'US',
    city: 'New Ulm',
    state: 'Texas',
    state_short: 'TX',
    county: 'Austin',
    latitude: 29.9134,
    longitude: -96.4961,
    postal_code: '78950'
  },
  '78951': {
    country: 'US',
    city: 'Oakland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.6016,
    longitude: -96.83,
    postal_code: '78951'
  },
  '78952': {
    country: 'US',
    city: 'Plum',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 29.9349,
    longitude: -96.9675,
    postal_code: '78952'
  },
  '78953': {
    country: 'US',
    city: 'Rosanky',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bastrop',
    latitude: 29.9241,
    longitude: -97.3119,
    postal_code: '78953'
  },
  '78954': {
    country: 'US',
    city: 'Round Top',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 30.0411,
    longitude: -96.7341,
    postal_code: '78954'
  },
  '78956': {
    country: 'US',
    city: 'Schulenburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 29.6882,
    longitude: -96.9106,
    postal_code: '78956'
  },
  '78957': {
    country: 'US',
    city: 'Smithville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bastrop',
    latitude: 30.0178,
    longitude: -97.1494,
    postal_code: '78957'
  },
  '78959': {
    country: 'US',
    city: 'Waelder',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gonzales',
    latitude: 29.6868,
    longitude: -97.2958,
    postal_code: '78959'
  },
  '78960': {
    country: 'US',
    city: 'Warda',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 30.0702,
    longitude: -96.902,
    postal_code: '78960'
  },
  '78961': {
    country: 'US',
    city: 'Round Top',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 30.0652,
    longitude: -96.6961,
    postal_code: '78961'
  },
  '78962': {
    country: 'US',
    city: 'Weimar',
    state: 'Texas',
    state_short: 'TX',
    county: 'Colorado',
    latitude: 29.6787,
    longitude: -96.755,
    postal_code: '78962'
  },
  '78963': {
    country: 'US',
    city: 'West Point',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fayette',
    latitude: 29.9523,
    longitude: -97.0361,
    postal_code: '78963'
  },
  '79001': {
    country: 'US',
    city: 'Adrian',
    state: 'Texas',
    state_short: 'TX',
    county: 'Oldham',
    latitude: 35.2748,
    longitude: -102.6652,
    postal_code: '79001'
  },
  '79002': {
    country: 'US',
    city: 'Alanreed',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gray',
    latitude: 35.2273,
    longitude: -100.7592,
    postal_code: '79002'
  },
  '79003': {
    country: 'US',
    city: 'Allison',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wheeler',
    latitude: 35.6059,
    longitude: -100.1007,
    postal_code: '79003'
  },
  '79005': {
    country: 'US',
    city: 'Booker',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lipscomb',
    latitude: 36.4429,
    longitude: -100.5238,
    postal_code: '79005'
  },
  '79007': {
    country: 'US',
    city: 'Borger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hutchinson',
    latitude: 35.6678,
    longitude: -101.3974,
    postal_code: '79007'
  },
  '79008': {
    country: 'US',
    city: 'Borger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hutchinson',
    latitude: 35.6678,
    longitude: -101.3974,
    postal_code: '79008'
  },
  '79009': {
    country: 'US',
    city: 'Bovina',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parmer',
    latitude: 34.5137,
    longitude: -102.883,
    postal_code: '79009'
  },
  '79010': {
    country: 'US',
    city: 'Boys Ranch',
    state: 'Texas',
    state_short: 'TX',
    county: 'Oldham',
    latitude: 35.5314,
    longitude: -102.2541,
    postal_code: '79010'
  },
  '79011': {
    country: 'US',
    city: 'Briscoe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wheeler',
    latitude: 35.5855,
    longitude: -100.1679,
    postal_code: '79011'
  },
  '79012': {
    country: 'US',
    city: 'Bushland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.2664,
    longitude: -102.0978,
    postal_code: '79012'
  },
  '79013': {
    country: 'US',
    city: 'Cactus',
    state: 'Texas',
    state_short: 'TX',
    county: 'Moore',
    latitude: 36.0412,
    longitude: -102.0007,
    postal_code: '79013'
  },
  '79014': {
    country: 'US',
    city: 'Canadian',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hemphill',
    latitude: 35.9045,
    longitude: -100.3841,
    postal_code: '79014'
  },
  '79015': {
    country: 'US',
    city: 'Canyon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Randall',
    latitude: 34.9772,
    longitude: -101.9247,
    postal_code: '79015'
  },
  '79016': {
    country: 'US',
    city: 'Canyon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Randall',
    latitude: 34.9803,
    longitude: -101.9188,
    postal_code: '79016'
  },
  '79018': {
    country: 'US',
    city: 'Channing',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hartley',
    latitude: 35.6837,
    longitude: -102.3302,
    postal_code: '79018'
  },
  '79019': {
    country: 'US',
    city: 'Claude',
    state: 'Texas',
    state_short: 'TX',
    county: 'Armstrong',
    latitude: 35.0967,
    longitude: -101.3813,
    postal_code: '79019'
  },
  '79021': {
    country: 'US',
    city: 'Cotton Center',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hale',
    latitude: 33.9753,
    longitude: -102.0314,
    postal_code: '79021'
  },
  '79022': {
    country: 'US',
    city: 'Dalhart',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallam',
    latitude: 36.0732,
    longitude: -102.5177,
    postal_code: '79022'
  },
  '79024': {
    country: 'US',
    city: 'Darrouzett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lipscomb',
    latitude: 36.4453,
    longitude: -100.3254,
    postal_code: '79024'
  },
  '79025': {
    country: 'US',
    city: 'Dawn',
    state: 'Texas',
    state_short: 'TX',
    county: 'Deaf Smith',
    latitude: 34.9106,
    longitude: -102.2002,
    postal_code: '79025'
  },
  '79027': {
    country: 'US',
    city: 'Dimmitt',
    state: 'Texas',
    state_short: 'TX',
    county: 'Castro',
    latitude: 34.5341,
    longitude: -102.3046,
    postal_code: '79027'
  },
  '79029': {
    country: 'US',
    city: 'Dumas',
    state: 'Texas',
    state_short: 'TX',
    county: 'Moore',
    latitude: 35.8823,
    longitude: -101.968,
    postal_code: '79029'
  },
  '79031': {
    country: 'US',
    city: 'Earth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamb',
    latitude: 34.2415,
    longitude: -102.4213,
    postal_code: '79031'
  },
  '79032': {
    country: 'US',
    city: 'Edmonson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hale',
    latitude: 34.2789,
    longitude: -101.8941,
    postal_code: '79032'
  },
  '79033': {
    country: 'US',
    city: 'Farnsworth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ochiltree',
    latitude: 36.2963,
    longitude: -100.9843,
    postal_code: '79033'
  },
  '79034': {
    country: 'US',
    city: 'Follett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lipscomb',
    latitude: 36.4309,
    longitude: -100.2068,
    postal_code: '79034'
  },
  '79035': {
    country: 'US',
    city: 'Friona',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parmer',
    latitude: 34.6417,
    longitude: -102.7241,
    postal_code: '79035'
  },
  '79036': {
    country: 'US',
    city: 'Fritch',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hutchinson',
    latitude: 35.6441,
    longitude: -101.5845,
    postal_code: '79036'
  },
  '79039': {
    country: 'US',
    city: 'Groom',
    state: 'Texas',
    state_short: 'TX',
    county: 'Carson',
    latitude: 35.2168,
    longitude: -101.1285,
    postal_code: '79039'
  },
  '79040': {
    country: 'US',
    city: 'Gruver',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hansford',
    latitude: 36.2868,
    longitude: -101.4089,
    postal_code: '79040'
  },
  '79041': {
    country: 'US',
    city: 'Hale Center',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hale',
    latitude: 34.0669,
    longitude: -101.8736,
    postal_code: '79041'
  },
  '79042': {
    country: 'US',
    city: 'Happy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Swisher',
    latitude: 34.7219,
    longitude: -101.8256,
    postal_code: '79042'
  },
  '79043': {
    country: 'US',
    city: 'Hart',
    state: 'Texas',
    state_short: 'TX',
    county: 'Castro',
    latitude: 34.3878,
    longitude: -102.1155,
    postal_code: '79043'
  },
  '79044': {
    country: 'US',
    city: 'Hartley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hartley',
    latitude: 35.8856,
    longitude: -102.3969,
    postal_code: '79044'
  },
  '79045': {
    country: 'US',
    city: 'Hereford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Deaf Smith',
    latitude: 34.837,
    longitude: -102.405,
    postal_code: '79045'
  },
  '79046': {
    country: 'US',
    city: 'Higgins',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lipscomb',
    latitude: 36.1365,
    longitude: -100.0952,
    postal_code: '79046'
  },
  '79051': {
    country: 'US',
    city: 'Kerrick',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallam',
    latitude: 36.4956,
    longitude: -102.2427,
    postal_code: '79051'
  },
  '79052': {
    country: 'US',
    city: 'Kress',
    state: 'Texas',
    state_short: 'TX',
    county: 'Swisher',
    latitude: 34.3737,
    longitude: -101.737,
    postal_code: '79052'
  },
  '79053': {
    country: 'US',
    city: 'Lazbuddie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parmer',
    latitude: 34.3847,
    longitude: -102.587,
    postal_code: '79053'
  },
  '79054': {
    country: 'US',
    city: 'Lefors',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gray',
    latitude: 35.4391,
    longitude: -100.8059,
    postal_code: '79054'
  },
  '79056': {
    country: 'US',
    city: 'Lipscomb',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lipscomb',
    latitude: 36.223,
    longitude: -100.2702,
    postal_code: '79056'
  },
  '79057': {
    country: 'US',
    city: 'Mclean',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gray',
    latitude: 35.2312,
    longitude: -100.6008,
    postal_code: '79057'
  },
  '79058': {
    country: 'US',
    city: 'Masterson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Moore',
    latitude: 35.6359,
    longitude: -101.9602,
    postal_code: '79058'
  },
  '79059': {
    country: 'US',
    city: 'Miami',
    state: 'Texas',
    state_short: 'TX',
    county: 'Roberts',
    latitude: 35.7193,
    longitude: -100.7027,
    postal_code: '79059'
  },
  '79061': {
    country: 'US',
    city: 'Mobeetie',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wheeler',
    latitude: 35.5297,
    longitude: -100.4242,
    postal_code: '79061'
  },
  '79062': {
    country: 'US',
    city: 'Morse',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hansford',
    latitude: 36.0596,
    longitude: -101.4729,
    postal_code: '79062'
  },
  '79063': {
    country: 'US',
    city: 'Nazareth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Castro',
    latitude: 34.5444,
    longitude: -102.1069,
    postal_code: '79063'
  },
  '79064': {
    country: 'US',
    city: 'Olton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamb',
    latitude: 34.1844,
    longitude: -102.1414,
    postal_code: '79064'
  },
  '79065': {
    country: 'US',
    city: 'Pampa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gray',
    latitude: 35.538,
    longitude: -100.9579,
    postal_code: '79065'
  },
  '79066': {
    country: 'US',
    city: 'Pampa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gray',
    latitude: 35.5334,
    longitude: -100.956,
    postal_code: '79066'
  },
  '79068': {
    country: 'US',
    city: 'Panhandle',
    state: 'Texas',
    state_short: 'TX',
    county: 'Carson',
    latitude: 35.3808,
    longitude: -101.4304,
    postal_code: '79068'
  },
  '79070': {
    country: 'US',
    city: 'Perryton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ochiltree',
    latitude: 36.3748,
    longitude: -100.8156,
    postal_code: '79070'
  },
  '79072': {
    country: 'US',
    city: 'Plainview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hale',
    latitude: 34.1962,
    longitude: -101.7259,
    postal_code: '79072'
  },
  '79073': {
    country: 'US',
    city: 'Plainview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hale',
    latitude: 34.0689,
    longitude: -101.827,
    postal_code: '79073'
  },
  '79077': {
    country: 'US',
    city: 'Samnorwood',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collingsworth',
    latitude: 35.0523,
    longitude: -100.2807,
    postal_code: '79077'
  },
  '79078': {
    country: 'US',
    city: 'Sanford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hutchinson',
    latitude: 35.702,
    longitude: -101.5346,
    postal_code: '79078'
  },
  '79079': {
    country: 'US',
    city: 'Shamrock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wheeler',
    latitude: 35.2142,
    longitude: -100.249,
    postal_code: '79079'
  },
  '79080': {
    country: 'US',
    city: 'Skellytown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Carson',
    latitude: 35.5685,
    longitude: -101.1721,
    postal_code: '79080'
  },
  '79081': {
    country: 'US',
    city: 'Spearman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hansford',
    latitude: 36.1927,
    longitude: -101.1952,
    postal_code: '79081'
  },
  '79082': {
    country: 'US',
    city: 'Springlake',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamb',
    latitude: 34.2393,
    longitude: -102.309,
    postal_code: '79082'
  },
  '79083': {
    country: 'US',
    city: 'Stinnett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hutchinson',
    latitude: 35.8377,
    longitude: -101.45,
    postal_code: '79083'
  },
  '79084': {
    country: 'US',
    city: 'Stratford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Sherman',
    latitude: 36.3335,
    longitude: -101.9886,
    postal_code: '79084'
  },
  '79085': {
    country: 'US',
    city: 'Summerfield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Castro',
    latitude: 34.7437,
    longitude: -102.5064,
    postal_code: '79085'
  },
  '79086': {
    country: 'US',
    city: 'Sunray',
    state: 'Texas',
    state_short: 'TX',
    county: 'Moore',
    latitude: 36.0097,
    longitude: -101.8123,
    postal_code: '79086'
  },
  '79087': {
    country: 'US',
    city: 'Texline',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dallam',
    latitude: 36.374,
    longitude: -102.9845,
    postal_code: '79087'
  },
  '79088': {
    country: 'US',
    city: 'Tulia',
    state: 'Texas',
    state_short: 'TX',
    county: 'Swisher',
    latitude: 34.5583,
    longitude: -101.8039,
    postal_code: '79088'
  },
  '79091': {
    country: 'US',
    city: 'Umbarger',
    state: 'Texas',
    state_short: 'TX',
    county: 'Randall',
    latitude: 34.901,
    longitude: -102.0788,
    postal_code: '79091'
  },
  '79092': {
    country: 'US',
    city: 'Vega',
    state: 'Texas',
    state_short: 'TX',
    county: 'Oldham',
    latitude: 35.2241,
    longitude: -102.4214,
    postal_code: '79092'
  },
  '79093': {
    country: 'US',
    city: 'Waka',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ochiltree',
    latitude: 36.2716,
    longitude: -101.0443,
    postal_code: '79093'
  },
  '79094': {
    country: 'US',
    city: 'Wayside',
    state: 'Texas',
    state_short: 'TX',
    county: 'Armstrong',
    latitude: 34.7928,
    longitude: -101.5471,
    postal_code: '79094'
  },
  '79095': {
    country: 'US',
    city: 'Wellington',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collingsworth',
    latitude: 34.8717,
    longitude: -100.2207,
    postal_code: '79095'
  },
  '79096': {
    country: 'US',
    city: 'Wheeler',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wheeler',
    latitude: 35.4319,
    longitude: -100.2568,
    postal_code: '79096'
  },
  '79097': {
    country: 'US',
    city: 'White Deer',
    state: 'Texas',
    state_short: 'TX',
    county: 'Carson',
    latitude: 35.4278,
    longitude: -101.174,
    postal_code: '79097'
  },
  '79098': {
    country: 'US',
    city: 'Wildorado',
    state: 'Texas',
    state_short: 'TX',
    county: 'Oldham',
    latitude: 35.1916,
    longitude: -102.2118,
    postal_code: '79098'
  },
  '79101': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.2032,
    longitude: -101.8421,
    postal_code: '79101'
  },
  '79102': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.1999,
    longitude: -101.8496,
    postal_code: '79102'
  },
  '79103': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.1751,
    longitude: -101.7976,
    postal_code: '79103'
  },
  '79104': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.1939,
    longitude: -101.7975,
    postal_code: '79104'
  },
  '79105': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.4015,
    longitude: -101.8951,
    postal_code: '79105'
  },
  '79106': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.1977,
    longitude: -101.8949,
    postal_code: '79106'
  },
  '79107': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.2309,
    longitude: -101.806,
    postal_code: '79107'
  },
  '79108': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.2779,
    longitude: -101.83,
    postal_code: '79108'
  },
  '79109': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Randall',
    latitude: 35.1663,
    longitude: -101.8868,
    postal_code: '79109'
  },
  '79110': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Randall',
    latitude: 35.1545,
    longitude: -101.8641,
    postal_code: '79110'
  },
  '79111': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.2286,
    longitude: -101.6703,
    postal_code: '79111'
  },
  '79114': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Randall',
    latitude: 35.05,
    longitude: -101.8175,
    postal_code: '79114'
  },
  '79116': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.2454,
    longitude: -101.999,
    postal_code: '79116'
  },
  '79117': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.3089,
    longitude: -101.843,
    postal_code: '79117'
  },
  '79118': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Randall',
    latitude: 35.0763,
    longitude: -101.8349,
    postal_code: '79118'
  },
  '79119': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Randall',
    latitude: 35.0642,
    longitude: -101.9743,
    postal_code: '79119'
  },
  '79120': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.1964,
    longitude: -101.8034,
    postal_code: '79120'
  },
  '79121': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Randall',
    latitude: 35.1697,
    longitude: -101.9266,
    postal_code: '79121'
  },
  '79124': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.2703,
    longitude: -101.943,
    postal_code: '79124'
  },
  '79159': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.222,
    longitude: -101.8313,
    postal_code: '79159'
  },
  '79166': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.4015,
    longitude: -101.8951,
    postal_code: '79166'
  },
  '79168': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.4015,
    longitude: -101.8951,
    postal_code: '79168'
  },
  '79172': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.4015,
    longitude: -101.8951,
    postal_code: '79172'
  },
  '79174': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.4015,
    longitude: -101.8951,
    postal_code: '79174'
  },
  '79178': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.4015,
    longitude: -101.8951,
    postal_code: '79178'
  },
  '79185': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.4015,
    longitude: -101.8951,
    postal_code: '79185'
  },
  '79189': {
    country: 'US',
    city: 'Amarillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Potter',
    latitude: 35.4015,
    longitude: -101.8951,
    postal_code: '79189'
  },
  '79201': {
    country: 'US',
    city: 'Childress',
    state: 'Texas',
    state_short: 'TX',
    county: 'Childress',
    latitude: 34.4104,
    longitude: -100.2364,
    postal_code: '79201'
  },
  '79220': {
    country: 'US',
    city: 'Afton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dickens',
    latitude: 33.7718,
    longitude: -100.8021,
    postal_code: '79220'
  },
  '79221': {
    country: 'US',
    city: 'Aiken',
    state: 'Texas',
    state_short: 'TX',
    county: 'Floyd',
    latitude: 34.1423,
    longitude: -101.5257,
    postal_code: '79221'
  },
  '79223': {
    country: 'US',
    city: 'Cee Vee',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cottle',
    latitude: 34.2295,
    longitude: -100.4573,
    postal_code: '79223'
  },
  '79225': {
    country: 'US',
    city: 'Chillicothe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardeman',
    latitude: 34.2439,
    longitude: -99.5157,
    postal_code: '79225'
  },
  '79226': {
    country: 'US',
    city: 'Clarendon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Donley',
    latitude: 34.9529,
    longitude: -100.8952,
    postal_code: '79226'
  },
  '79227': {
    country: 'US',
    city: 'Crowell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Foard',
    latitude: 33.9912,
    longitude: -99.6983,
    postal_code: '79227'
  },
  '79229': {
    country: 'US',
    city: 'Dickens',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dickens',
    latitude: 33.628,
    longitude: -100.8197,
    postal_code: '79229'
  },
  '79230': {
    country: 'US',
    city: 'Dodson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collingsworth',
    latitude: 34.7644,
    longitude: -100.0286,
    postal_code: '79230'
  },
  '79231': {
    country: 'US',
    city: 'Dougherty',
    state: 'Texas',
    state_short: 'TX',
    county: 'Floyd',
    latitude: 33.9446,
    longitude: -101.0922,
    postal_code: '79231'
  },
  '79233': {
    country: 'US',
    city: 'Estelline',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hall',
    latitude: 34.5303,
    longitude: -100.4455,
    postal_code: '79233'
  },
  '79234': {
    country: 'US',
    city: 'Flomot',
    state: 'Texas',
    state_short: 'TX',
    county: 'Motley',
    latitude: 34.2321,
    longitude: -101.0038,
    postal_code: '79234'
  },
  '79235': {
    country: 'US',
    city: 'Floydada',
    state: 'Texas',
    state_short: 'TX',
    county: 'Floyd',
    latitude: 33.9743,
    longitude: -101.3346,
    postal_code: '79235'
  },
  '79236': {
    country: 'US',
    city: 'Guthrie',
    state: 'Texas',
    state_short: 'TX',
    county: 'King',
    latitude: 33.6206,
    longitude: -100.3229,
    postal_code: '79236'
  },
  '79237': {
    country: 'US',
    city: 'Hedley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Donley',
    latitude: 34.8698,
    longitude: -100.6806,
    postal_code: '79237'
  },
  '79239': {
    country: 'US',
    city: 'Lakeview',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hall',
    latitude: 34.6724,
    longitude: -100.7259,
    postal_code: '79239'
  },
  '79240': {
    country: 'US',
    city: 'Lelia Lake',
    state: 'Texas',
    state_short: 'TX',
    county: 'Donley',
    latitude: 34.8843,
    longitude: -100.7674,
    postal_code: '79240'
  },
  '79241': {
    country: 'US',
    city: 'Lockney',
    state: 'Texas',
    state_short: 'TX',
    county: 'Floyd',
    latitude: 34.1458,
    longitude: -101.4259,
    postal_code: '79241'
  },
  '79243': {
    country: 'US',
    city: 'Mcadoo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dickens',
    latitude: 33.7413,
    longitude: -100.9833,
    postal_code: '79243'
  },
  '79244': {
    country: 'US',
    city: 'Matador',
    state: 'Texas',
    state_short: 'TX',
    county: 'Motley',
    latitude: 34.0525,
    longitude: -100.8361,
    postal_code: '79244'
  },
  '79245': {
    country: 'US',
    city: 'Memphis',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hall',
    latitude: 34.7122,
    longitude: -100.5347,
    postal_code: '79245'
  },
  '79247': {
    country: 'US',
    city: 'Odell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Wilbarger',
    latitude: 34.3452,
    longitude: -99.4196,
    postal_code: '79247'
  },
  '79248': {
    country: 'US',
    city: 'Paducah',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cottle',
    latitude: 34.0217,
    longitude: -100.2986,
    postal_code: '79248'
  },
  '79250': {
    country: 'US',
    city: 'Petersburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hale',
    latitude: 33.8768,
    longitude: -101.6046,
    postal_code: '79250'
  },
  '79251': {
    country: 'US',
    city: 'Quail',
    state: 'Texas',
    state_short: 'TX',
    county: 'Collingsworth',
    latitude: 34.918,
    longitude: -100.4252,
    postal_code: '79251'
  },
  '79252': {
    country: 'US',
    city: 'Quanah',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hardeman',
    latitude: 34.2955,
    longitude: -99.7494,
    postal_code: '79252'
  },
  '79255': {
    country: 'US',
    city: 'Quitaque',
    state: 'Texas',
    state_short: 'TX',
    county: 'Briscoe',
    latitude: 34.3796,
    longitude: -101.0465,
    postal_code: '79255'
  },
  '79256': {
    country: 'US',
    city: 'Roaring Springs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Motley',
    latitude: 33.8977,
    longitude: -100.8528,
    postal_code: '79256'
  },
  '79257': {
    country: 'US',
    city: 'Silverton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Briscoe',
    latitude: 34.4641,
    longitude: -101.3169,
    postal_code: '79257'
  },
  '79258': {
    country: 'US',
    city: 'South Plains',
    state: 'Texas',
    state_short: 'TX',
    county: 'Floyd',
    latitude: 34.2245,
    longitude: -101.3096,
    postal_code: '79258'
  },
  '79259': {
    country: 'US',
    city: 'Tell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Childress',
    latitude: 34.3759,
    longitude: -100.3948,
    postal_code: '79259'
  },
  '79261': {
    country: 'US',
    city: 'Turkey',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hall',
    latitude: 34.4036,
    longitude: -100.8449,
    postal_code: '79261'
  },
  '79311': {
    country: 'US',
    city: 'Abernathy',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hale',
    latitude: 33.85,
    longitude: -101.8611,
    postal_code: '79311'
  },
  '79312': {
    country: 'US',
    city: 'Amherst',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamb',
    latitude: 33.9976,
    longitude: -102.4416,
    postal_code: '79312'
  },
  '79313': {
    country: 'US',
    city: 'Anton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hockley',
    latitude: 33.8043,
    longitude: -102.1652,
    postal_code: '79313'
  },
  '79314': {
    country: 'US',
    city: 'Bledsoe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cochran',
    latitude: 33.5997,
    longitude: -103.0168,
    postal_code: '79314'
  },
  '79316': {
    country: 'US',
    city: 'Brownfield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Terry',
    latitude: 33.1698,
    longitude: -102.2762,
    postal_code: '79316'
  },
  '79322': {
    country: 'US',
    city: 'Crosbyton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Crosby',
    latitude: 33.6562,
    longitude: -101.2287,
    postal_code: '79322'
  },
  '79323': {
    country: 'US',
    city: 'Denver City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Yoakum',
    latitude: 32.9711,
    longitude: -102.8313,
    postal_code: '79323'
  },
  '79324': {
    country: 'US',
    city: 'Enochs',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bailey',
    latitude: 33.8731,
    longitude: -102.7599,
    postal_code: '79324'
  },
  '79325': {
    country: 'US',
    city: 'Farwell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Parmer',
    latitude: 34.386,
    longitude: -102.9901,
    postal_code: '79325'
  },
  '79326': {
    country: 'US',
    city: 'Fieldton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamb',
    latitude: 34.053,
    longitude: -102.206,
    postal_code: '79326'
  },
  '79329': {
    country: 'US',
    city: 'Idalou',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.6504,
    longitude: -101.6786,
    postal_code: '79329'
  },
  '79330': {
    country: 'US',
    city: 'Justiceburg',
    state: 'Texas',
    state_short: 'TX',
    county: 'Garza',
    latitude: 33.0594,
    longitude: -101.1881,
    postal_code: '79330'
  },
  '79331': {
    country: 'US',
    city: 'Lamesa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dawson',
    latitude: 32.7367,
    longitude: -101.9569,
    postal_code: '79331'
  },
  '79336': {
    country: 'US',
    city: 'Levelland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hockley',
    latitude: 33.5788,
    longitude: -102.3676,
    postal_code: '79336'
  },
  '79338': {
    country: 'US',
    city: 'Levelland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hockley',
    latitude: 33.5932,
    longitude: -102.3627,
    postal_code: '79338'
  },
  '79339': {
    country: 'US',
    city: 'Littlefield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamb',
    latitude: 33.9212,
    longitude: -102.3207,
    postal_code: '79339'
  },
  '79342': {
    country: 'US',
    city: 'Loop',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gaines',
    latitude: 32.9162,
    longitude: -102.4221,
    postal_code: '79342'
  },
  '79343': {
    country: 'US',
    city: 'Lorenzo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Crosby',
    latitude: 33.6666,
    longitude: -101.5277,
    postal_code: '79343'
  },
  '79344': {
    country: 'US',
    city: 'Maple',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bailey',
    latitude: 33.8487,
    longitude: -102.8985,
    postal_code: '79344'
  },
  '79345': {
    country: 'US',
    city: 'Meadow',
    state: 'Texas',
    state_short: 'TX',
    county: 'Terry',
    latitude: 33.3321,
    longitude: -102.2492,
    postal_code: '79345'
  },
  '79346': {
    country: 'US',
    city: 'Morton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cochran',
    latitude: 33.7251,
    longitude: -102.7594,
    postal_code: '79346'
  },
  '79347': {
    country: 'US',
    city: 'Muleshoe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Bailey',
    latitude: 34.2193,
    longitude: -102.7496,
    postal_code: '79347'
  },
  '79350': {
    country: 'US',
    city: 'New Deal',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.7296,
    longitude: -101.8355,
    postal_code: '79350'
  },
  '79351': {
    country: 'US',
    city: 'Odonnell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lynn',
    latitude: 32.9773,
    longitude: -101.8271,
    postal_code: '79351'
  },
  '79353': {
    country: 'US',
    city: 'Pep',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hockley',
    latitude: 33.8104,
    longitude: -102.5656,
    postal_code: '79353'
  },
  '79355': {
    country: 'US',
    city: 'Plains',
    state: 'Texas',
    state_short: 'TX',
    county: 'Yoakum',
    latitude: 33.1894,
    longitude: -102.8294,
    postal_code: '79355'
  },
  '79356': {
    country: 'US',
    city: 'Post',
    state: 'Texas',
    state_short: 'TX',
    county: 'Garza',
    latitude: 33.2017,
    longitude: -101.3922,
    postal_code: '79356'
  },
  '79357': {
    country: 'US',
    city: 'Ralls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Crosby',
    latitude: 33.6851,
    longitude: -101.3836,
    postal_code: '79357'
  },
  '79358': {
    country: 'US',
    city: 'Ropesville',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hockley',
    latitude: 33.4575,
    longitude: -102.1584,
    postal_code: '79358'
  },
  '79359': {
    country: 'US',
    city: 'Seagraves',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gaines',
    latitude: 32.9317,
    longitude: -102.5781,
    postal_code: '79359'
  },
  '79360': {
    country: 'US',
    city: 'Seminole',
    state: 'Texas',
    state_short: 'TX',
    county: 'Gaines',
    latitude: 32.721,
    longitude: -102.6828,
    postal_code: '79360'
  },
  '79363': {
    country: 'US',
    city: 'Shallowater',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.6919,
    longitude: -101.9836,
    postal_code: '79363'
  },
  '79364': {
    country: 'US',
    city: 'Slaton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.4373,
    longitude: -101.6435,
    postal_code: '79364'
  },
  '79366': {
    country: 'US',
    city: 'Ransom Canyon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5334,
    longitude: -101.6796,
    postal_code: '79366'
  },
  '79367': {
    country: 'US',
    city: 'Smyer',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hockley',
    latitude: 33.5918,
    longitude: -102.1692,
    postal_code: '79367'
  },
  '79369': {
    country: 'US',
    city: 'Spade',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamb',
    latitude: 33.9256,
    longitude: -102.1564,
    postal_code: '79369'
  },
  '79370': {
    country: 'US',
    city: 'Spur',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dickens',
    latitude: 33.479,
    longitude: -100.8571,
    postal_code: '79370'
  },
  '79371': {
    country: 'US',
    city: 'Sudan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lamb',
    latitude: 34.0697,
    longitude: -102.5255,
    postal_code: '79371'
  },
  '79372': {
    country: 'US',
    city: 'Sundown',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hockley',
    latitude: 33.4482,
    longitude: -102.4898,
    postal_code: '79372'
  },
  '79373': {
    country: 'US',
    city: 'Tahoka',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lynn',
    latitude: 33.1988,
    longitude: -101.822,
    postal_code: '79373'
  },
  '79376': {
    country: 'US',
    city: 'Tokio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Yoakum',
    latitude: 33.1826,
    longitude: -102.5768,
    postal_code: '79376'
  },
  '79377': {
    country: 'US',
    city: 'Welch',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dawson',
    latitude: 32.9298,
    longitude: -102.1278,
    postal_code: '79377'
  },
  '79378': {
    country: 'US',
    city: 'Wellman',
    state: 'Texas',
    state_short: 'TX',
    county: 'Terry',
    latitude: 33.0473,
    longitude: -102.4282,
    postal_code: '79378'
  },
  '79379': {
    country: 'US',
    city: 'Whiteface',
    state: 'Texas',
    state_short: 'TX',
    county: 'Cochran',
    latitude: 33.5988,
    longitude: -102.6199,
    postal_code: '79379'
  },
  '79380': {
    country: 'US',
    city: 'Whitharral',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hockley',
    latitude: 33.7354,
    longitude: -102.3419,
    postal_code: '79380'
  },
  '79381': {
    country: 'US',
    city: 'Wilson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lynn',
    latitude: 33.3299,
    longitude: -101.7122,
    postal_code: '79381'
  },
  '79382': {
    country: 'US',
    city: 'Wolfforth',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5059,
    longitude: -102.0091,
    postal_code: '79382'
  },
  '79383': {
    country: 'US',
    city: 'New Home',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lynn',
    latitude: 33.3451,
    longitude: -101.9203,
    postal_code: '79383'
  },
  '79401': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5865,
    longitude: -101.8606,
    postal_code: '79401'
  },
  '79402': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5922,
    longitude: -101.8511,
    postal_code: '79402'
  },
  '79403': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.6196,
    longitude: -101.8098,
    postal_code: '79403'
  },
  '79404': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.526,
    longitude: -101.8333,
    postal_code: '79404'
  },
  '79406': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5819,
    longitude: -101.8778,
    postal_code: '79406'
  },
  '79407': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5684,
    longitude: -101.9423,
    postal_code: '79407'
  },
  '79408': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5659,
    longitude: -101.9267,
    postal_code: '79408'
  },
  '79409': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5779,
    longitude: -101.8552,
    postal_code: '79409'
  },
  '79410': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5693,
    longitude: -101.8904,
    postal_code: '79410'
  },
  '79411': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5704,
    longitude: -101.8626,
    postal_code: '79411'
  },
  '79412': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5463,
    longitude: -101.8577,
    postal_code: '79412'
  },
  '79413': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5466,
    longitude: -101.8871,
    postal_code: '79413'
  },
  '79414': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5497,
    longitude: -101.9187,
    postal_code: '79414'
  },
  '79415': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.6021,
    longitude: -101.876,
    postal_code: '79415'
  },
  '79416': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5924,
    longitude: -101.9367,
    postal_code: '79416'
  },
  '79423': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5146,
    longitude: -101.8795,
    postal_code: '79423'
  },
  '79424': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5159,
    longitude: -101.9344,
    postal_code: '79424'
  },
  '79430': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.61,
    longitude: -101.8213,
    postal_code: '79430'
  },
  '79452': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.61,
    longitude: -101.8213,
    postal_code: '79452'
  },
  '79453': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5779,
    longitude: -101.8552,
    postal_code: '79453'
  },
  '79457': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.5779,
    longitude: -101.8552,
    postal_code: '79457'
  },
  '79464': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.4896,
    longitude: -102.0109,
    postal_code: '79464'
  },
  '79490': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.61,
    longitude: -101.8213,
    postal_code: '79490'
  },
  '79491': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.61,
    longitude: -101.8213,
    postal_code: '79491'
  },
  '79493': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.61,
    longitude: -101.8213,
    postal_code: '79493'
  },
  '79499': {
    country: 'US',
    city: 'Lubbock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Lubbock',
    latitude: 33.61,
    longitude: -101.8213,
    postal_code: '79499'
  },
  '79501': {
    country: 'US',
    city: 'Anson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jones',
    latitude: 32.7489,
    longitude: -99.8953,
    postal_code: '79501'
  },
  '79502': {
    country: 'US',
    city: 'Aspermont',
    state: 'Texas',
    state_short: 'TX',
    county: 'Stonewall',
    latitude: 33.1301,
    longitude: -100.2344,
    postal_code: '79502'
  },
  '79503': {
    country: 'US',
    city: 'Avoca',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jones',
    latitude: 32.8832,
    longitude: -99.6964,
    postal_code: '79503'
  },
  '79504': {
    country: 'US',
    city: 'Baird',
    state: 'Texas',
    state_short: 'TX',
    county: 'Callahan',
    latitude: 32.3916,
    longitude: -99.3777,
    postal_code: '79504'
  },
  '79505': {
    country: 'US',
    city: 'Benjamin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Knox',
    latitude: 33.584,
    longitude: -99.7923,
    postal_code: '79505'
  },
  '79506': {
    country: 'US',
    city: 'Blackwell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nolan',
    latitude: 32.082,
    longitude: -100.3111,
    postal_code: '79506'
  },
  '79508': {
    country: 'US',
    city: 'Buffalo Gap',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.2894,
    longitude: -99.8103,
    postal_code: '79508'
  },
  '79510': {
    country: 'US',
    city: 'Clyde',
    state: 'Texas',
    state_short: 'TX',
    county: 'Callahan',
    latitude: 32.3803,
    longitude: -99.5184,
    postal_code: '79510'
  },
  '79511': {
    country: 'US',
    city: 'Coahoma',
    state: 'Texas',
    state_short: 'TX',
    county: 'Howard',
    latitude: 32.2942,
    longitude: -101.3197,
    postal_code: '79511'
  },
  '79512': {
    country: 'US',
    city: 'Colorado City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mitchell',
    latitude: 32.3987,
    longitude: -100.8609,
    postal_code: '79512'
  },
  '79516': {
    country: 'US',
    city: 'Dunn',
    state: 'Texas',
    state_short: 'TX',
    county: 'Scurry',
    latitude: 32.5671,
    longitude: -100.8854,
    postal_code: '79516'
  },
  '79517': {
    country: 'US',
    city: 'Fluvanna',
    state: 'Texas',
    state_short: 'TX',
    county: 'Scurry',
    latitude: 32.853,
    longitude: -101.103,
    postal_code: '79517'
  },
  '79518': {
    country: 'US',
    city: 'Girard',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kent',
    latitude: 33.3632,
    longitude: -100.6937,
    postal_code: '79518'
  },
  '79519': {
    country: 'US',
    city: 'Goldsboro',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coleman',
    latitude: 32.0482,
    longitude: -99.6775,
    postal_code: '79519'
  },
  '79520': {
    country: 'US',
    city: 'Hamlin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jones',
    latitude: 32.8799,
    longitude: -100.128,
    postal_code: '79520'
  },
  '79521': {
    country: 'US',
    city: 'Haskell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Haskell',
    latitude: 33.158,
    longitude: -99.7309,
    postal_code: '79521'
  },
  '79525': {
    country: 'US',
    city: 'Hawley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jones',
    latitude: 32.6423,
    longitude: -99.9239,
    postal_code: '79525'
  },
  '79526': {
    country: 'US',
    city: 'Hermleigh',
    state: 'Texas',
    state_short: 'TX',
    county: 'Scurry',
    latitude: 32.6294,
    longitude: -100.7547,
    postal_code: '79526'
  },
  '79527': {
    country: 'US',
    city: 'Ira',
    state: 'Texas',
    state_short: 'TX',
    county: 'Scurry',
    latitude: 32.5821,
    longitude: -101.0021,
    postal_code: '79527'
  },
  '79528': {
    country: 'US',
    city: 'Jayton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Kent',
    latitude: 33.2518,
    longitude: -100.5825,
    postal_code: '79528'
  },
  '79529': {
    country: 'US',
    city: 'Knox City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Knox',
    latitude: 33.4183,
    longitude: -99.8137,
    postal_code: '79529'
  },
  '79530': {
    country: 'US',
    city: 'Lawn',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.136,
    longitude: -99.7351,
    postal_code: '79530'
  },
  '79532': {
    country: 'US',
    city: 'Loraine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mitchell',
    latitude: 32.4112,
    longitude: -100.7123,
    postal_code: '79532'
  },
  '79533': {
    country: 'US',
    city: 'Lueders',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jones',
    latitude: 32.8712,
    longitude: -99.5783,
    postal_code: '79533'
  },
  '79534': {
    country: 'US',
    city: 'Mc Caulley',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fisher',
    latitude: 32.7787,
    longitude: -100.2168,
    postal_code: '79534'
  },
  '79535': {
    country: 'US',
    city: 'Maryneal',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nolan',
    latitude: 32.2026,
    longitude: -100.4972,
    postal_code: '79535'
  },
  '79536': {
    country: 'US',
    city: 'Merkel',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4444,
    longitude: -99.9924,
    postal_code: '79536'
  },
  '79537': {
    country: 'US',
    city: 'Nolan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nolan',
    latitude: 32.2919,
    longitude: -100.2107,
    postal_code: '79537'
  },
  '79538': {
    country: 'US',
    city: 'Novice',
    state: 'Texas',
    state_short: 'TX',
    county: 'Coleman',
    latitude: 31.9781,
    longitude: -99.6809,
    postal_code: '79538'
  },
  '79539': {
    country: 'US',
    city: 'O Brien',
    state: 'Texas',
    state_short: 'TX',
    county: 'Haskell',
    latitude: 33.3749,
    longitude: -99.8473,
    postal_code: '79539'
  },
  '79540': {
    country: 'US',
    city: 'Old Glory',
    state: 'Texas',
    state_short: 'TX',
    county: 'Stonewall',
    latitude: 33.1298,
    longitude: -100.0559,
    postal_code: '79540'
  },
  '79541': {
    country: 'US',
    city: 'Ovalo',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.1553,
    longitude: -99.8229,
    postal_code: '79541'
  },
  '79543': {
    country: 'US',
    city: 'Roby',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fisher',
    latitude: 32.722,
    longitude: -100.4008,
    postal_code: '79543'
  },
  '79544': {
    country: 'US',
    city: 'Rochester',
    state: 'Texas',
    state_short: 'TX',
    county: 'Haskell',
    latitude: 33.3105,
    longitude: -99.8594,
    postal_code: '79544'
  },
  '79545': {
    country: 'US',
    city: 'Roscoe',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nolan',
    latitude: 32.4276,
    longitude: -100.5391,
    postal_code: '79545'
  },
  '79546': {
    country: 'US',
    city: 'Rotan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fisher',
    latitude: 32.8555,
    longitude: -100.4705,
    postal_code: '79546'
  },
  '79547': {
    country: 'US',
    city: 'Rule',
    state: 'Texas',
    state_short: 'TX',
    county: 'Haskell',
    latitude: 33.1842,
    longitude: -99.8894,
    postal_code: '79547'
  },
  '79548': {
    country: 'US',
    city: 'Rule',
    state: 'Texas',
    state_short: 'TX',
    county: 'Haskell',
    latitude: 33.0771,
    longitude: -99.959,
    postal_code: '79548'
  },
  '79549': {
    country: 'US',
    city: 'Snyder',
    state: 'Texas',
    state_short: 'TX',
    county: 'Scurry',
    latitude: 32.7451,
    longitude: -100.9175,
    postal_code: '79549'
  },
  '79550': {
    country: 'US',
    city: 'Snyder',
    state: 'Texas',
    state_short: 'TX',
    county: 'Scurry',
    latitude: 32.7179,
    longitude: -100.9176,
    postal_code: '79550'
  },
  '79553': {
    country: 'US',
    city: 'Stamford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jones',
    latitude: 32.9453,
    longitude: -99.7859,
    postal_code: '79553'
  },
  '79556': {
    country: 'US',
    city: 'Sweetwater',
    state: 'Texas',
    state_short: 'TX',
    county: 'Nolan',
    latitude: 32.4726,
    longitude: -100.3979,
    postal_code: '79556'
  },
  '79560': {
    country: 'US',
    city: 'Sylvester',
    state: 'Texas',
    state_short: 'TX',
    county: 'Fisher',
    latitude: 32.7209,
    longitude: -100.2543,
    postal_code: '79560'
  },
  '79561': {
    country: 'US',
    city: 'Trent',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4898,
    longitude: -100.1195,
    postal_code: '79561'
  },
  '79562': {
    country: 'US',
    city: 'Tuscola',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.235,
    longitude: -99.8244,
    postal_code: '79562'
  },
  '79563': {
    country: 'US',
    city: 'Tye',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4477,
    longitude: -99.8684,
    postal_code: '79563'
  },
  '79565': {
    country: 'US',
    city: 'Westbrook',
    state: 'Texas',
    state_short: 'TX',
    county: 'Mitchell',
    latitude: 32.3627,
    longitude: -101.043,
    postal_code: '79565'
  },
  '79566': {
    country: 'US',
    city: 'Wingate',
    state: 'Texas',
    state_short: 'TX',
    county: 'Runnels',
    latitude: 32.0318,
    longitude: -100.1183,
    postal_code: '79566'
  },
  '79567': {
    country: 'US',
    city: 'Winters',
    state: 'Texas',
    state_short: 'TX',
    county: 'Runnels',
    latitude: 31.962,
    longitude: -99.9551,
    postal_code: '79567'
  },
  '79601': {
    country: 'US',
    city: 'Abilene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4682,
    longitude: -99.7182,
    postal_code: '79601'
  },
  '79602': {
    country: 'US',
    city: 'Abilene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4178,
    longitude: -99.7214,
    postal_code: '79602'
  },
  '79603': {
    country: 'US',
    city: 'Abilene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4679,
    longitude: -99.7619,
    postal_code: '79603'
  },
  '79604': {
    country: 'US',
    city: 'Abilene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4487,
    longitude: -99.7331,
    postal_code: '79604'
  },
  '79605': {
    country: 'US',
    city: 'Abilene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.432,
    longitude: -99.7724,
    postal_code: '79605'
  },
  '79606': {
    country: 'US',
    city: 'Abilene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.392,
    longitude: -99.7746,
    postal_code: '79606'
  },
  '79607': {
    country: 'US',
    city: 'Dyess Afb',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.419,
    longitude: -99.8221,
    postal_code: '79607'
  },
  '79608': {
    country: 'US',
    city: 'Abilene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4487,
    longitude: -99.7331,
    postal_code: '79608'
  },
  '79697': {
    country: 'US',
    city: 'Abilene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4487,
    longitude: -99.7331,
    postal_code: '79697'
  },
  '79698': {
    country: 'US',
    city: 'Abilene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4751,
    longitude: -99.7348,
    postal_code: '79698'
  },
  '79699': {
    country: 'US',
    city: 'Abilene',
    state: 'Texas',
    state_short: 'TX',
    county: 'Taylor',
    latitude: 32.4665,
    longitude: -99.7117,
    postal_code: '79699'
  },
  '79701': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 31.9896,
    longitude: -102.0626,
    postal_code: '79701'
  },
  '79702': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 31.9637,
    longitude: -102.0801,
    postal_code: '79702'
  },
  '79703': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 31.9721,
    longitude: -102.1369,
    postal_code: '79703'
  },
  '79704': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 31.8693,
    longitude: -102.0317,
    postal_code: '79704'
  },
  '79705': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 32.0295,
    longitude: -102.0915,
    postal_code: '79705'
  },
  '79706': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 31.8816,
    longitude: -102.0134,
    postal_code: '79706'
  },
  '79707': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 32.0199,
    longitude: -102.1476,
    postal_code: '79707'
  },
  '79708': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 31.8693,
    longitude: -102.0317,
    postal_code: '79708'
  },
  '79710': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 31.8693,
    longitude: -102.0317,
    postal_code: '79710'
  },
  '79711': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 31.9973,
    longitude: -102.0779,
    postal_code: '79711'
  },
  '79712': {
    country: 'US',
    city: 'Midland',
    state: 'Texas',
    state_short: 'TX',
    county: 'Midland',
    latitude: 31.8693,
    longitude: -102.0317,
    postal_code: '79712'
  },
  '79713': {
    country: 'US',
    city: 'Ackerly',
    state: 'Texas',
    state_short: 'TX',
    county: 'Dawson',
    latitude: 32.5273,
    longitude: -101.716,
    postal_code: '79713'
  },
  '79714': {
    country: 'US',
    city: 'Andrews',
    state: 'Texas',
    state_short: 'TX',
    county: 'Andrews',
    latitude: 32.3201,
    longitude: -102.5409,
    postal_code: '79714'
  },
  '79718': {
    country: 'US',
    city: 'Balmorhea',
    state: 'Texas',
    state_short: 'TX',
    county: 'Reeves',
    latitude: 30.9843,
    longitude: -103.7446,
    postal_code: '79718'
  },
  '79719': {
    country: 'US',
    city: 'Barstow',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ward',
    latitude: 31.4639,
    longitude: -103.3971,
    postal_code: '79719'
  },
  '79720': {
    country: 'US',
    city: 'Big Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Howard',
    latitude: 32.2787,
    longitude: -101.4578,
    postal_code: '79720'
  },
  '79721': {
    country: 'US',
    city: 'Big Spring',
    state: 'Texas',
    state_short: 'TX',
    county: 'Howard',
    latitude: 32.2733,
    longitude: -101.374,
    postal_code: '79721'
  },
  '79730': {
    country: 'US',
    city: 'Coyanosa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Pecos',
    latitude: 31.2295,
    longitude: -103.0538,
    postal_code: '79730'
  },
  '79731': {
    country: 'US',
    city: 'Crane',
    state: 'Texas',
    state_short: 'TX',
    county: 'Crane',
    latitude: 31.3969,
    longitude: -102.3544,
    postal_code: '79731'
  },
  '79733': {
    country: 'US',
    city: 'Forsan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Howard',
    latitude: 32.1105,
    longitude: -101.3669,
    postal_code: '79733'
  },
  '79734': {
    country: 'US',
    city: 'Fort Davis',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jeff Davis',
    latitude: 30.6131,
    longitude: -103.9364,
    postal_code: '79734'
  },
  '79735': {
    country: 'US',
    city: 'Fort Stockton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Pecos',
    latitude: 30.8908,
    longitude: -102.8799,
    postal_code: '79735'
  },
  '79738': {
    country: 'US',
    city: 'Gail',
    state: 'Texas',
    state_short: 'TX',
    county: 'Borden',
    latitude: 32.7523,
    longitude: -101.45,
    postal_code: '79738'
  },
  '79739': {
    country: 'US',
    city: 'Garden City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Glasscock',
    latitude: 31.8491,
    longitude: -101.5269,
    postal_code: '79739'
  },
  '79740': {
    country: 'US',
    city: 'Girvin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Pecos',
    latitude: 31.0629,
    longitude: -102.3885,
    postal_code: '79740'
  },
  '79741': {
    country: 'US',
    city: 'Goldsmith',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.9541,
    longitude: -102.625,
    postal_code: '79741'
  },
  '79742': {
    country: 'US',
    city: 'Grandfalls',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ward',
    latitude: 31.3459,
    longitude: -102.8568,
    postal_code: '79742'
  },
  '79743': {
    country: 'US',
    city: 'Imperial',
    state: 'Texas',
    state_short: 'TX',
    county: 'Pecos',
    latitude: 31.2729,
    longitude: -102.6926,
    postal_code: '79743'
  },
  '79744': {
    country: 'US',
    city: 'Iraan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Pecos',
    latitude: 30.9156,
    longitude: -101.9152,
    postal_code: '79744'
  },
  '79745': {
    country: 'US',
    city: 'Kermit',
    state: 'Texas',
    state_short: 'TX',
    county: 'Winkler',
    latitude: 31.855,
    longitude: -103.0913,
    postal_code: '79745'
  },
  '79748': {
    country: 'US',
    city: 'Knott',
    state: 'Texas',
    state_short: 'TX',
    county: 'Howard',
    latitude: 32.4125,
    longitude: -101.6517,
    postal_code: '79748'
  },
  '79749': {
    country: 'US',
    city: 'Lenorah',
    state: 'Texas',
    state_short: 'TX',
    county: 'Martin',
    latitude: 32.3046,
    longitude: -101.8762,
    postal_code: '79749'
  },
  '79752': {
    country: 'US',
    city: 'Mc Camey',
    state: 'Texas',
    state_short: 'TX',
    county: 'Upton',
    latitude: 31.1319,
    longitude: -102.2153,
    postal_code: '79752'
  },
  '79754': {
    country: 'US',
    city: 'Mentone',
    state: 'Texas',
    state_short: 'TX',
    county: 'Loving',
    latitude: 31.7068,
    longitude: -103.5988,
    postal_code: '79754'
  },
  '79755': {
    country: 'US',
    city: 'Midkiff',
    state: 'Texas',
    state_short: 'TX',
    county: 'Upton',
    latitude: 31.5424,
    longitude: -101.9244,
    postal_code: '79755'
  },
  '79756': {
    country: 'US',
    city: 'Monahans',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ward',
    latitude: 31.5813,
    longitude: -102.9003,
    postal_code: '79756'
  },
  '79758': {
    country: 'US',
    city: 'Gardendale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 32.0245,
    longitude: -102.3572,
    postal_code: '79758'
  },
  '79759': {
    country: 'US',
    city: 'Notrees',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.8599,
    longitude: -102.7413,
    postal_code: '79759'
  },
  '79760': {
    country: 'US',
    city: 'Odessa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.7652,
    longitude: -102.3543,
    postal_code: '79760'
  },
  '79761': {
    country: 'US',
    city: 'Odessa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.8579,
    longitude: -102.3523,
    postal_code: '79761'
  },
  '79762': {
    country: 'US',
    city: 'Odessa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.889,
    longitude: -102.3548,
    postal_code: '79762'
  },
  '79763': {
    country: 'US',
    city: 'Odessa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.8341,
    longitude: -102.4162,
    postal_code: '79763'
  },
  '79764': {
    country: 'US',
    city: 'Odessa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.8767,
    longitude: -102.4375,
    postal_code: '79764'
  },
  '79765': {
    country: 'US',
    city: 'Odessa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.9375,
    longitude: -102.3944,
    postal_code: '79765'
  },
  '79766': {
    country: 'US',
    city: 'Odessa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.7827,
    longitude: -102.3449,
    postal_code: '79766'
  },
  '79768': {
    country: 'US',
    city: 'Odessa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.8457,
    longitude: -102.3676,
    postal_code: '79768'
  },
  '79769': {
    country: 'US',
    city: 'Odessa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.8457,
    longitude: -102.3676,
    postal_code: '79769'
  },
  '79770': {
    country: 'US',
    city: 'Orla',
    state: 'Texas',
    state_short: 'TX',
    county: 'Reeves',
    latitude: 31.864,
    longitude: -103.9297,
    postal_code: '79770'
  },
  '79772': {
    country: 'US',
    city: 'Pecos',
    state: 'Texas',
    state_short: 'TX',
    county: 'Reeves',
    latitude: 31.4467,
    longitude: -103.5791,
    postal_code: '79772'
  },
  '79776': {
    country: 'US',
    city: 'Penwell',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ector',
    latitude: 31.7334,
    longitude: -102.5879,
    postal_code: '79776'
  },
  '79777': {
    country: 'US',
    city: 'Pyote',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ward',
    latitude: 31.5387,
    longitude: -103.1267,
    postal_code: '79777'
  },
  '79778': {
    country: 'US',
    city: 'Rankin',
    state: 'Texas',
    state_short: 'TX',
    county: 'Upton',
    latitude: 31.2266,
    longitude: -101.9441,
    postal_code: '79778'
  },
  '79780': {
    country: 'US',
    city: 'Saragosa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Reeves',
    latitude: 31.0428,
    longitude: -103.6364,
    postal_code: '79780'
  },
  '79781': {
    country: 'US',
    city: 'Sheffield',
    state: 'Texas',
    state_short: 'TX',
    county: 'Pecos',
    latitude: 30.6935,
    longitude: -101.86,
    postal_code: '79781'
  },
  '79782': {
    country: 'US',
    city: 'Stanton',
    state: 'Texas',
    state_short: 'TX',
    county: 'Martin',
    latitude: 32.14,
    longitude: -101.8099,
    postal_code: '79782'
  },
  '79783': {
    country: 'US',
    city: 'Tarzan',
    state: 'Texas',
    state_short: 'TX',
    county: 'Martin',
    latitude: 32.3575,
    longitude: -101.9607,
    postal_code: '79783'
  },
  '79785': {
    country: 'US',
    city: 'Toyah',
    state: 'Texas',
    state_short: 'TX',
    county: 'Reeves',
    latitude: 31.282,
    longitude: -103.8057,
    postal_code: '79785'
  },
  '79786': {
    country: 'US',
    city: 'Toyahvale',
    state: 'Texas',
    state_short: 'TX',
    county: 'Reeves',
    latitude: 30.9443,
    longitude: -103.7893,
    postal_code: '79786'
  },
  '79788': {
    country: 'US',
    city: 'Wickett',
    state: 'Texas',
    state_short: 'TX',
    county: 'Ward',
    latitude: 31.5692,
    longitude: -103.0067,
    postal_code: '79788'
  },
  '79789': {
    country: 'US',
    city: 'Wink',
    state: 'Texas',
    state_short: 'TX',
    county: 'Winkler',
    latitude: 31.7527,
    longitude: -103.1561,
    postal_code: '79789'
  },
  '79821': {
    country: 'US',
    city: 'Anthony',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.9907,
    longitude: -106.5976,
    postal_code: '79821'
  },
  '79830': {
    country: 'US',
    city: 'Alpine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brewster',
    latitude: 30.2631,
    longitude: -103.6541,
    postal_code: '79830'
  },
  '79831': {
    country: 'US',
    city: 'Alpine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brewster',
    latitude: 30.3068,
    longitude: -103.6704,
    postal_code: '79831'
  },
  '79832': {
    country: 'US',
    city: 'Alpine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brewster',
    latitude: 30.3631,
    longitude: -103.6539,
    postal_code: '79832'
  },
  '79834': {
    country: 'US',
    city: 'Big Bend National Park',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brewster',
    latitude: 29.2924,
    longitude: -103.2963,
    postal_code: '79834'
  },
  '79835': {
    country: 'US',
    city: 'Canutillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.9344,
    longitude: -106.5929,
    postal_code: '79835'
  },
  '79836': {
    country: 'US',
    city: 'Clint',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.5494,
    longitude: -106.2038,
    postal_code: '79836'
  },
  '79837': {
    country: 'US',
    city: 'Dell City',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hudspeth',
    latitude: 31.924,
    longitude: -105.2099,
    postal_code: '79837'
  },
  '79838': {
    country: 'US',
    city: 'Fabens',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.4745,
    longitude: -106.1589,
    postal_code: '79838'
  },
  '79839': {
    country: 'US',
    city: 'Fort Hancock',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hudspeth',
    latitude: 31.2985,
    longitude: -105.8452,
    postal_code: '79839'
  },
  '79842': {
    country: 'US',
    city: 'Marathon',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brewster',
    latitude: 30.1886,
    longitude: -103.2214,
    postal_code: '79842'
  },
  '79843': {
    country: 'US',
    city: 'Marfa',
    state: 'Texas',
    state_short: 'TX',
    county: 'Presidio',
    latitude: 30.293,
    longitude: -104.0848,
    postal_code: '79843'
  },
  '79845': {
    country: 'US',
    city: 'Presidio',
    state: 'Texas',
    state_short: 'TX',
    county: 'Presidio',
    latitude: 29.5573,
    longitude: -104.3551,
    postal_code: '79845'
  },
  '79846': {
    country: 'US',
    city: 'Redford',
    state: 'Texas',
    state_short: 'TX',
    county: 'Presidio',
    latitude: 29.4702,
    longitude: -104.0055,
    postal_code: '79846'
  },
  '79847': {
    country: 'US',
    city: 'Salt Flat',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hudspeth',
    latitude: 31.8244,
    longitude: -105.2212,
    postal_code: '79847'
  },
  '79848': {
    country: 'US',
    city: 'Sanderson',
    state: 'Texas',
    state_short: 'TX',
    county: 'Terrell',
    latitude: 30.1221,
    longitude: -102.3974,
    postal_code: '79848'
  },
  '79849': {
    country: 'US',
    city: 'San Elizario',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.5508,
    longitude: -106.2507,
    postal_code: '79849'
  },
  '79851': {
    country: 'US',
    city: 'Sierra Blanca',
    state: 'Texas',
    state_short: 'TX',
    county: 'Hudspeth',
    latitude: 31.1938,
    longitude: -105.3219,
    postal_code: '79851'
  },
  '79852': {
    country: 'US',
    city: 'Terlingua',
    state: 'Texas',
    state_short: 'TX',
    county: 'Brewster',
    latitude: 29.3165,
    longitude: -103.5597,
    postal_code: '79852'
  },
  '79853': {
    country: 'US',
    city: 'Tornillo',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.4407,
    longitude: -106.0765,
    postal_code: '79853'
  },
  '79854': {
    country: 'US',
    city: 'Valentine',
    state: 'Texas',
    state_short: 'TX',
    county: 'Jeff Davis',
    latitude: 30.62,
    longitude: -104.4811,
    postal_code: '79854'
  },
  '79855': {
    country: 'US',
    city: 'Van Horn',
    state: 'Texas',
    state_short: 'TX',
    county: 'Culberson',
    latitude: 31.0391,
    longitude: -104.8245,
    postal_code: '79855'
  },
  '79901': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7584,
    longitude: -106.4783,
    postal_code: '79901'
  },
  '79902': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7763,
    longitude: -106.4932,
    postal_code: '79902'
  },
  '79903': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7862,
    longitude: -106.4406,
    postal_code: '79903'
  },
  '79904': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8533,
    longitude: -106.4381,
    postal_code: '79904'
  },
  '79905': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7674,
    longitude: -106.4304,
    postal_code: '79905'
  },
  '79906': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8092,
    longitude: -106.4247,
    postal_code: '79906'
  },
  '79907': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7089,
    longitude: -106.3293,
    postal_code: '79907'
  },
  '79908': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8265,
    longitude: -106.3857,
    postal_code: '79908'
  },
  '79910': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79910'
  },
  '79911': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8925,
    longitude: -106.5426,
    postal_code: '79911'
  },
  '79912': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8383,
    longitude: -106.5364,
    postal_code: '79912'
  },
  '79913': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.9373,
    longitude: -106.5724,
    postal_code: '79913'
  },
  '79914': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79914'
  },
  '79915': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7432,
    longitude: -106.3686,
    postal_code: '79915'
  },
  '79916': {
    country: 'US',
    city: 'Fort Bliss',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8136,
    longitude: -106.4122,
    postal_code: '79916'
  },
  '79917': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79917'
  },
  '79918': {
    country: 'US',
    city: 'Fort Bliss',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8136,
    longitude: -106.4122,
    postal_code: '79918'
  },
  '79920': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8214,
    longitude: -106.4614,
    postal_code: '79920'
  },
  '79922': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8218,
    longitude: -106.5732,
    postal_code: '79922'
  },
  '79923': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79923'
  },
  '79924': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.9021,
    longitude: -106.4149,
    postal_code: '79924'
  },
  '79925': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7814,
    longitude: -106.3613,
    postal_code: '79925'
  },
  '79926': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79926'
  },
  '79927': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6446,
    longitude: -106.2737,
    postal_code: '79927'
  },
  '79928': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6631,
    longitude: -106.1401,
    postal_code: '79928'
  },
  '79929': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79929'
  },
  '79930': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8048,
    longitude: -106.4568,
    postal_code: '79930'
  },
  '79931': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79931'
  },
  '79932': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8623,
    longitude: -106.5932,
    postal_code: '79932'
  },
  '79934': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.9386,
    longitude: -106.4073,
    postal_code: '79934'
  },
  '79935': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7718,
    longitude: -106.3303,
    postal_code: '79935'
  },
  '79936': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7677,
    longitude: -106.3016,
    postal_code: '79936'
  },
  '79937': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79937'
  },
  '79938': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.8045,
    longitude: -105.9661,
    postal_code: '79938'
  },
  '79940': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79940'
  },
  '79941': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79941'
  },
  '79942': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79942'
  },
  '79943': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79943'
  },
  '79944': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79944'
  },
  '79945': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79945'
  },
  '79946': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79946'
  },
  '79947': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79947'
  },
  '79948': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79948'
  },
  '79949': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79949'
  },
  '79950': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79950'
  },
  '79951': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79951'
  },
  '79952': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79952'
  },
  '79953': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79953'
  },
  '79954': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79954'
  },
  '79955': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79955'
  },
  '79958': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79958'
  },
  '79960': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79960'
  },
  '79961': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79961'
  },
  '79968': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7705,
    longitude: -106.5048,
    postal_code: '79968'
  },
  '79976': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79976'
  },
  '79978': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.7587,
    longitude: -106.4869,
    postal_code: '79978'
  },
  '79980': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79980'
  },
  '79990': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79990'
  },
  '79995': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79995'
  },
  '79996': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79996'
  },
  '79997': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79997'
  },
  '79998': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79998'
  },
  '79999': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '79999'
  },
  '80001': {
    country: 'US',
    city: 'Arvada',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.8028,
    longitude: -105.0875,
    postal_code: '80001'
  },
  '80002': {
    country: 'US',
    city: 'Arvada',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.7945,
    longitude: -105.0984,
    postal_code: '80002'
  },
  '80003': {
    country: 'US',
    city: 'Arvada',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.8286,
    longitude: -105.0655,
    postal_code: '80003'
  },
  '80004': {
    country: 'US',
    city: 'Arvada',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.8141,
    longitude: -105.1177,
    postal_code: '80004'
  },
  '80005': {
    country: 'US',
    city: 'Arvada',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.8422,
    longitude: -105.1097,
    postal_code: '80005'
  },
  '80006': {
    country: 'US',
    city: 'Arvada',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.8028,
    longitude: -105.0875,
    postal_code: '80006'
  },
  '80007': {
    country: 'US',
    city: 'Arvada',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.8634,
    longitude: -105.1724,
    postal_code: '80007'
  },
  '80010': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.7368,
    longitude: -104.8646,
    postal_code: '80010'
  },
  '80011': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.7378,
    longitude: -104.8152,
    postal_code: '80011'
  },
  '80012': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6987,
    longitude: -104.8377,
    postal_code: '80012'
  },
  '80013': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6575,
    longitude: -104.7846,
    postal_code: '80013'
  },
  '80014': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6662,
    longitude: -104.835,
    postal_code: '80014'
  },
  '80015': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6255,
    longitude: -104.7874,
    postal_code: '80015'
  },
  '80016': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6012,
    longitude: -104.7394,
    postal_code: '80016'
  },
  '80017': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6948,
    longitude: -104.7881,
    postal_code: '80017'
  },
  '80018': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.7102,
    longitude: -104.7071,
    postal_code: '80018'
  },
  '80019': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.7656,
    longitude: -104.7069,
    postal_code: '80019'
  },
  '80020': {
    country: 'US',
    city: 'Broomfield',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 39.9245,
    longitude: -105.0609,
    postal_code: '80020'
  },
  '80021': {
    country: 'US',
    city: 'Broomfield',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.8854,
    longitude: -105.1139,
    postal_code: '80021'
  },
  '80022': {
    country: 'US',
    city: 'Commerce City',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.8259,
    longitude: -104.9113,
    postal_code: '80022'
  },
  '80023': {
    country: 'US',
    city: 'Broomfield',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Broomfield',
    latitude: 39.9619,
    longitude: -105.0148,
    postal_code: '80023'
  },
  '80024': {
    country: 'US',
    city: 'Dupont',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.8445,
    longitude: -104.9188,
    postal_code: '80024'
  },
  '80025': {
    country: 'US',
    city: 'Eldorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 39.9324,
    longitude: -105.288,
    postal_code: '80025'
  },
  '80026': {
    country: 'US',
    city: 'Lafayette',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 39.998,
    longitude: -105.0963,
    postal_code: '80026'
  },
  '80027': {
    country: 'US',
    city: 'Louisville',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 39.9789,
    longitude: -105.1456,
    postal_code: '80027'
  },
  '80030': {
    country: 'US',
    city: 'Westminster',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.8302,
    longitude: -105.037,
    postal_code: '80030'
  },
  '80031': {
    country: 'US',
    city: 'Westminster',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.8753,
    longitude: -105.0345,
    postal_code: '80031'
  },
  '80033': {
    country: 'US',
    city: 'Wheat Ridge',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.774,
    longitude: -105.0962,
    postal_code: '80033'
  },
  '80034': {
    country: 'US',
    city: 'Wheat Ridge',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.7661,
    longitude: -105.0772,
    postal_code: '80034'
  },
  '80035': {
    country: 'US',
    city: 'Westminster',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.8367,
    longitude: -105.0372,
    postal_code: '80035'
  },
  '80036': {
    country: 'US',
    city: 'Westminster',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.8367,
    longitude: -105.0372,
    postal_code: '80036'
  },
  '80037': {
    country: 'US',
    city: 'Commerce City',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.8083,
    longitude: -104.9339,
    postal_code: '80037'
  },
  '80038': {
    country: 'US',
    city: 'Broomfield',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 39.9205,
    longitude: -105.0867,
    postal_code: '80038'
  },
  '80040': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.7294,
    longitude: -104.8319,
    postal_code: '80040'
  },
  '80041': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.7388,
    longitude: -104.4083,
    postal_code: '80041'
  },
  '80042': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.7294,
    longitude: -104.8319,
    postal_code: '80042'
  },
  '80044': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.7388,
    longitude: -104.4083,
    postal_code: '80044'
  },
  '80045': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.7467,
    longitude: -104.8384,
    postal_code: '80045'
  },
  '80046': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.7388,
    longitude: -104.4083,
    postal_code: '80046'
  },
  '80047': {
    country: 'US',
    city: 'Aurora',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.7388,
    longitude: -104.4083,
    postal_code: '80047'
  },
  '80101': {
    country: 'US',
    city: 'Agate',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Elbert',
    latitude: 39.4203,
    longitude: -103.9846,
    postal_code: '80101'
  },
  '80102': {
    country: 'US',
    city: 'Bennett',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.7589,
    longitude: -104.4275,
    postal_code: '80102'
  },
  '80103': {
    country: 'US',
    city: 'Byers',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6985,
    longitude: -104.2019,
    postal_code: '80103'
  },
  '80104': {
    country: 'US',
    city: 'Castle Rock',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.3722,
    longitude: -104.8561,
    postal_code: '80104'
  },
  '80105': {
    country: 'US',
    city: 'Deer Trail',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.5931,
    longitude: -104.068,
    postal_code: '80105'
  },
  '80106': {
    country: 'US',
    city: 'Elbert',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Elbert',
    latitude: 39.0969,
    longitude: -104.5746,
    postal_code: '80106'
  },
  '80107': {
    country: 'US',
    city: 'Elizabeth',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Elbert',
    latitude: 39.3836,
    longitude: -104.592,
    postal_code: '80107'
  },
  '80108': {
    country: 'US',
    city: 'Castle Rock',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.4455,
    longitude: -104.853,
    postal_code: '80108'
  },
  '80109': {
    country: 'US',
    city: 'Castle Rock',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.3643,
    longitude: -104.9014,
    postal_code: '80109'
  },
  '80110': {
    country: 'US',
    city: 'Englewood',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6463,
    longitude: -105.0092,
    postal_code: '80110'
  },
  '80111': {
    country: 'US',
    city: 'Englewood',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6123,
    longitude: -104.8799,
    postal_code: '80111'
  },
  '80112': {
    country: 'US',
    city: 'Englewood',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.5805,
    longitude: -104.9011,
    postal_code: '80112'
  },
  '80113': {
    country: 'US',
    city: 'Englewood',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6405,
    longitude: -104.9614,
    postal_code: '80113'
  },
  '80116': {
    country: 'US',
    city: 'Franktown',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.3728,
    longitude: -104.7256,
    postal_code: '80116'
  },
  '80117': {
    country: 'US',
    city: 'Kiowa',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Elbert',
    latitude: 39.324,
    longitude: -104.4523,
    postal_code: '80117'
  },
  '80118': {
    country: 'US',
    city: 'Larkspur',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.2011,
    longitude: -104.8546,
    postal_code: '80118'
  },
  '80120': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.5994,
    longitude: -105.0044,
    postal_code: '80120'
  },
  '80121': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6111,
    longitude: -104.9532,
    postal_code: '80121'
  },
  '80122': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.5814,
    longitude: -104.9557,
    postal_code: '80122'
  },
  '80123': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.6206,
    longitude: -105.0901,
    postal_code: '80123'
  },
  '80124': {
    country: 'US',
    city: 'Lone Tree',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.5517,
    longitude: -104.8863,
    postal_code: '80124'
  },
  '80125': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.4845,
    longitude: -105.0561,
    postal_code: '80125'
  },
  '80126': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.5437,
    longitude: -104.9691,
    postal_code: '80126'
  },
  '80127': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.592,
    longitude: -105.1328,
    postal_code: '80127'
  },
  '80128': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.5918,
    longitude: -105.0832,
    postal_code: '80128'
  },
  '80129': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.5397,
    longitude: -105.0109,
    postal_code: '80129'
  },
  '80130': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.5414,
    longitude: -104.9218,
    postal_code: '80130'
  },
  '80131': {
    country: 'US',
    city: 'Louviers',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.4764,
    longitude: -105.0075,
    postal_code: '80131'
  },
  '80132': {
    country: 'US',
    city: 'Monument',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 39.1007,
    longitude: -104.8542,
    postal_code: '80132'
  },
  '80133': {
    country: 'US',
    city: 'Palmer Lake',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 39.1205,
    longitude: -104.9148,
    postal_code: '80133'
  },
  '80134': {
    country: 'US',
    city: 'Parker',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.4895,
    longitude: -104.8447,
    postal_code: '80134'
  },
  '80135': {
    country: 'US',
    city: 'Sedalia',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.3113,
    longitude: -105.0676,
    postal_code: '80135'
  },
  '80136': {
    country: 'US',
    city: 'Strasburg',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.7814,
    longitude: -104.2683,
    postal_code: '80136'
  },
  '80137': {
    country: 'US',
    city: 'Watkins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.7623,
    longitude: -104.5834,
    postal_code: '80137'
  },
  '80138': {
    country: 'US',
    city: 'Parker',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.5102,
    longitude: -104.7216,
    postal_code: '80138'
  },
  '80150': {
    country: 'US',
    city: 'Englewood',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6478,
    longitude: -104.9878,
    postal_code: '80150'
  },
  '80151': {
    country: 'US',
    city: 'Englewood',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6478,
    longitude: -104.9878,
    postal_code: '80151'
  },
  '80155': {
    country: 'US',
    city: 'Englewood',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6478,
    longitude: -104.9878,
    postal_code: '80155'
  },
  '80160': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6133,
    longitude: -105.0166,
    postal_code: '80160'
  },
  '80161': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6133,
    longitude: -105.0166,
    postal_code: '80161'
  },
  '80162': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.522,
    longitude: -105.2239,
    postal_code: '80162'
  },
  '80163': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Douglas',
    latitude: 39.3479,
    longitude: -104.9947,
    postal_code: '80163'
  },
  '80165': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6133,
    longitude: -105.0166,
    postal_code: '80165'
  },
  '80166': {
    country: 'US',
    city: 'Littleton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Arapahoe',
    latitude: 39.6133,
    longitude: -105.0166,
    postal_code: '80166'
  },
  '80201': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7263,
    longitude: -104.8568,
    postal_code: '80201'
  },
  '80202': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7491,
    longitude: -104.9946,
    postal_code: '80202'
  },
  '80203': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7313,
    longitude: -104.9811,
    postal_code: '80203'
  },
  '80204': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.734,
    longitude: -105.0259,
    postal_code: '80204'
  },
  '80205': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.759,
    longitude: -104.9661,
    postal_code: '80205'
  },
  '80206': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7331,
    longitude: -104.9524,
    postal_code: '80206'
  },
  '80207': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7584,
    longitude: -104.9177,
    postal_code: '80207'
  },
  '80208': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80208'
  },
  '80209': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7074,
    longitude: -104.9686,
    postal_code: '80209'
  },
  '80210': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.679,
    longitude: -104.9631,
    postal_code: '80210'
  },
  '80211': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7665,
    longitude: -105.0204,
    postal_code: '80211'
  },
  '80212': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7683,
    longitude: -105.0493,
    postal_code: '80212'
  },
  '80214': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.7436,
    longitude: -105.0643,
    postal_code: '80214'
  },
  '80215': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.7435,
    longitude: -105.1009,
    postal_code: '80215'
  },
  '80216': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7835,
    longitude: -104.9669,
    postal_code: '80216'
  },
  '80217': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80217'
  },
  '80218': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7327,
    longitude: -104.9717,
    postal_code: '80218'
  },
  '80219': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.6956,
    longitude: -105.0341,
    postal_code: '80219'
  },
  '80220': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7312,
    longitude: -104.9129,
    postal_code: '80220'
  },
  '80221': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.838,
    longitude: -104.9988,
    postal_code: '80221'
  },
  '80222': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.671,
    longitude: -104.9279,
    postal_code: '80222'
  },
  '80223': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7002,
    longitude: -105.0028,
    postal_code: '80223'
  },
  '80224': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.688,
    longitude: -104.9108,
    postal_code: '80224'
  },
  '80225': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.6971,
    longitude: -105.1204,
    postal_code: '80225'
  },
  '80226': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.7123,
    longitude: -105.0918,
    postal_code: '80226'
  },
  '80227': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.6667,
    longitude: -105.0854,
    postal_code: '80227'
  },
  '80228': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.6888,
    longitude: -105.156,
    postal_code: '80228'
  },
  '80229': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.8671,
    longitude: -104.9227,
    postal_code: '80229'
  },
  '80230': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7218,
    longitude: -104.8951,
    postal_code: '80230'
  },
  '80231': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.6793,
    longitude: -104.8843,
    postal_code: '80231'
  },
  '80232': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.6895,
    longitude: -105.0908,
    postal_code: '80232'
  },
  '80233': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.9015,
    longitude: -104.9407,
    postal_code: '80233'
  },
  '80234': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.9108,
    longitude: -105.0109,
    postal_code: '80234'
  },
  '80235': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.6472,
    longitude: -105.0795,
    postal_code: '80235'
  },
  '80236': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.6535,
    longitude: -105.0376,
    postal_code: '80236'
  },
  '80237': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.6431,
    longitude: -104.8987,
    postal_code: '80237'
  },
  '80238': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80238'
  },
  '80239': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7878,
    longitude: -104.8288,
    postal_code: '80239'
  },
  '80241': {
    country: 'US',
    city: 'Thornton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.868,
    longitude: -104.9719,
    postal_code: '80241'
  },
  '80243': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80243'
  },
  '80244': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80244'
  },
  '80246': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7086,
    longitude: -104.9312,
    postal_code: '80246'
  },
  '80247': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.6971,
    longitude: -104.8819,
    postal_code: '80247'
  },
  '80248': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80248'
  },
  '80249': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7783,
    longitude: -104.7557,
    postal_code: '80249'
  },
  '80250': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80250'
  },
  '80251': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80251'
  },
  '80252': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80252'
  },
  '80256': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7474,
    longitude: -104.9928,
    postal_code: '80256'
  },
  '80257': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80257'
  },
  '80259': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7462,
    longitude: -104.9913,
    postal_code: '80259'
  },
  '80260': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.8672,
    longitude: -105.0041,
    postal_code: '80260'
  },
  '80261': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7379,
    longitude: -104.985,
    postal_code: '80261'
  },
  '80262': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.731,
    longitude: -104.9384,
    postal_code: '80262'
  },
  '80263': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80263'
  },
  '80264': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7426,
    longitude: -104.9863,
    postal_code: '80264'
  },
  '80265': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80265'
  },
  '80266': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7472,
    longitude: -104.9915,
    postal_code: '80266'
  },
  '80271': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80271'
  },
  '80273': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7273,
    longitude: -104.9875,
    postal_code: '80273'
  },
  '80274': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7439,
    longitude: -104.9876,
    postal_code: '80274'
  },
  '80281': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7439,
    longitude: -104.9876,
    postal_code: '80281'
  },
  '80290': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7436,
    longitude: -104.9876,
    postal_code: '80290'
  },
  '80291': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7439,
    longitude: -104.9876,
    postal_code: '80291'
  },
  '80293': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7458,
    longitude: -104.9907,
    postal_code: '80293'
  },
  '80294': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7491,
    longitude: -104.989,
    postal_code: '80294'
  },
  '80299': {
    country: 'US',
    city: 'Denver',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Denver',
    latitude: 39.7392,
    longitude: -104.9847,
    postal_code: '80299'
  },
  '80301': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.0497,
    longitude: -105.2143,
    postal_code: '80301'
  },
  '80302': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.0172,
    longitude: -105.2851,
    postal_code: '80302'
  },
  '80303': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 39.9914,
    longitude: -105.2392,
    postal_code: '80303'
  },
  '80304': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.0375,
    longitude: -105.2771,
    postal_code: '80304'
  },
  '80305': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 39.9807,
    longitude: -105.2531,
    postal_code: '80305'
  },
  '80306': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.1022,
    longitude: -105.3847,
    postal_code: '80306'
  },
  '80307': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.0878,
    longitude: -105.3735,
    postal_code: '80307'
  },
  '80308': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.0277,
    longitude: -105.3868,
    postal_code: '80308'
  },
  '80309': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.015,
    longitude: -105.2705,
    postal_code: '80309'
  },
  '80310': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.015,
    longitude: -105.2705,
    postal_code: '80310'
  },
  '80314': {
    country: 'US',
    city: 'Boulder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.0878,
    longitude: -105.3735,
    postal_code: '80314'
  },
  '80401': {
    country: 'US',
    city: 'Golden',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.7305,
    longitude: -105.1915,
    postal_code: '80401'
  },
  '80402': {
    country: 'US',
    city: 'Golden',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.7555,
    longitude: -105.2211,
    postal_code: '80402'
  },
  '80403': {
    country: 'US',
    city: 'Golden',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.8232,
    longitude: -105.2825,
    postal_code: '80403'
  },
  '80419': {
    country: 'US',
    city: 'Golden',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.522,
    longitude: -105.2239,
    postal_code: '80419'
  },
  '80420': {
    country: 'US',
    city: 'Alma',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Park',
    latitude: 39.2839,
    longitude: -106.0628,
    postal_code: '80420'
  },
  '80421': {
    country: 'US',
    city: 'Bailey',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Park',
    latitude: 39.4482,
    longitude: -105.4693,
    postal_code: '80421'
  },
  '80422': {
    country: 'US',
    city: 'Black Hawk',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gilpin',
    latitude: 39.816,
    longitude: -105.4753,
    postal_code: '80422'
  },
  '80423': {
    country: 'US',
    city: 'Bond',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.8691,
    longitude: -106.6763,
    postal_code: '80423'
  },
  '80424': {
    country: 'US',
    city: 'Breckenridge',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Summit',
    latitude: 39.4753,
    longitude: -106.0225,
    postal_code: '80424'
  },
  '80425': {
    country: 'US',
    city: 'Buffalo Creek',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.3867,
    longitude: -105.2703,
    postal_code: '80425'
  },
  '80426': {
    country: 'US',
    city: 'Burns',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.8739,
    longitude: -106.8856,
    postal_code: '80426'
  },
  '80427': {
    country: 'US',
    city: 'Central City',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gilpin',
    latitude: 39.8019,
    longitude: -105.5142,
    postal_code: '80427'
  },
  '80428': {
    country: 'US',
    city: 'Clark',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Routt',
    latitude: 40.7268,
    longitude: -106.9215,
    postal_code: '80428'
  },
  '80429': {
    country: 'US',
    city: 'Climax',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Lake',
    latitude: 39.2258,
    longitude: -106.3117,
    postal_code: '80429'
  },
  '80430': {
    country: 'US',
    city: 'Coalmont',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jackson',
    latitude: 40.5383,
    longitude: -106.5321,
    postal_code: '80430'
  },
  '80432': {
    country: 'US',
    city: 'Como',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Park',
    latitude: 39.1587,
    longitude: -105.817,
    postal_code: '80432'
  },
  '80433': {
    country: 'US',
    city: 'Conifer',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.5197,
    longitude: -105.3169,
    postal_code: '80433'
  },
  '80434': {
    country: 'US',
    city: 'Cowdrey',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jackson',
    latitude: 40.8597,
    longitude: -106.3131,
    postal_code: '80434'
  },
  '80435': {
    country: 'US',
    city: 'Dillon',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Summit',
    latitude: 39.5952,
    longitude: -105.9741,
    postal_code: '80435'
  },
  '80436': {
    country: 'US',
    city: 'Dumont',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Clear Creek',
    latitude: 39.7647,
    longitude: -105.6003,
    postal_code: '80436'
  },
  '80437': {
    country: 'US',
    city: 'Evergreen',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.522,
    longitude: -105.2239,
    postal_code: '80437'
  },
  '80438': {
    country: 'US',
    city: 'Empire',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Clear Creek',
    latitude: 39.7614,
    longitude: -105.6844,
    postal_code: '80438'
  },
  '80439': {
    country: 'US',
    city: 'Evergreen',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.6374,
    longitude: -105.3402,
    postal_code: '80439'
  },
  '80440': {
    country: 'US',
    city: 'Fairplay',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Park',
    latitude: 39.2256,
    longitude: -105.9994,
    postal_code: '80440'
  },
  '80442': {
    country: 'US',
    city: 'Fraser',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Grand',
    latitude: 39.945,
    longitude: -105.8172,
    postal_code: '80442'
  },
  '80443': {
    country: 'US',
    city: 'Frisco',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Summit',
    latitude: 39.5589,
    longitude: -106.1332,
    postal_code: '80443'
  },
  '80444': {
    country: 'US',
    city: 'Georgetown',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Clear Creek',
    latitude: 39.7061,
    longitude: -105.6975,
    postal_code: '80444'
  },
  '80446': {
    country: 'US',
    city: 'Granby',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Grand',
    latitude: 40.0739,
    longitude: -105.9285,
    postal_code: '80446'
  },
  '80447': {
    country: 'US',
    city: 'Grand Lake',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Grand',
    latitude: 40.2289,
    longitude: -105.8605,
    postal_code: '80447'
  },
  '80448': {
    country: 'US',
    city: 'Grant',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Park',
    latitude: 39.4612,
    longitude: -105.6583,
    postal_code: '80448'
  },
  '80449': {
    country: 'US',
    city: 'Hartsel',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Park',
    latitude: 38.9673,
    longitude: -105.8788,
    postal_code: '80449'
  },
  '80451': {
    country: 'US',
    city: 'Hot Sulphur Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Grand',
    latitude: 40.0712,
    longitude: -106.0693,
    postal_code: '80451'
  },
  '80452': {
    country: 'US',
    city: 'Idaho Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Clear Creek',
    latitude: 39.7402,
    longitude: -105.5983,
    postal_code: '80452'
  },
  '80453': {
    country: 'US',
    city: 'Idledale',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.6684,
    longitude: -105.2442,
    postal_code: '80453'
  },
  '80454': {
    country: 'US',
    city: 'Indian Hills',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.6296,
    longitude: -105.2514,
    postal_code: '80454'
  },
  '80455': {
    country: 'US',
    city: 'Jamestown',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.1155,
    longitude: -105.3886,
    postal_code: '80455'
  },
  '80456': {
    country: 'US',
    city: 'Jefferson',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Park',
    latitude: 39.2759,
    longitude: -105.6865,
    postal_code: '80456'
  },
  '80457': {
    country: 'US',
    city: 'Kittredge',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.6481,
    longitude: -105.2782,
    postal_code: '80457'
  },
  '80459': {
    country: 'US',
    city: 'Kremmling',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Grand',
    latitude: 40.0632,
    longitude: -106.3955,
    postal_code: '80459'
  },
  '80461': {
    country: 'US',
    city: 'Leadville',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Lake',
    latitude: 39.2497,
    longitude: -106.3015,
    postal_code: '80461'
  },
  '80463': {
    country: 'US',
    city: 'Mc Coy',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.9134,
    longitude: -106.7309,
    postal_code: '80463'
  },
  '80465': {
    country: 'US',
    city: 'Morrison',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.6125,
    longitude: -105.1746,
    postal_code: '80465'
  },
  '80466': {
    country: 'US',
    city: 'Nederland',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 39.9703,
    longitude: -105.4813,
    postal_code: '80466'
  },
  '80467': {
    country: 'US',
    city: 'Oak Creek',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Routt',
    latitude: 40.2567,
    longitude: -106.9296,
    postal_code: '80467'
  },
  '80468': {
    country: 'US',
    city: 'Parshall',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Grand',
    latitude: 39.9539,
    longitude: -106.093,
    postal_code: '80468'
  },
  '80469': {
    country: 'US',
    city: 'Phippsburg',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Routt',
    latitude: 40.1907,
    longitude: -106.9735,
    postal_code: '80469'
  },
  '80470': {
    country: 'US',
    city: 'Pine',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jefferson',
    latitude: 39.4667,
    longitude: -105.3741,
    postal_code: '80470'
  },
  '80471': {
    country: 'US',
    city: 'Pinecliffe',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 39.9319,
    longitude: -105.4283,
    postal_code: '80471'
  },
  '80473': {
    country: 'US',
    city: 'Rand',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jackson',
    latitude: 40.4667,
    longitude: -106.1857,
    postal_code: '80473'
  },
  '80474': {
    country: 'US',
    city: 'Rollinsville',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gilpin',
    latitude: 39.9172,
    longitude: -105.5011,
    postal_code: '80474'
  },
  '80475': {
    country: 'US',
    city: 'Shawnee',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Park',
    latitude: 39.4211,
    longitude: -105.5542,
    postal_code: '80475'
  },
  '80476': {
    country: 'US',
    city: 'Silver Plume',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Clear Creek',
    latitude: 39.6996,
    longitude: -105.7507,
    postal_code: '80476'
  },
  '80477': {
    country: 'US',
    city: 'Steamboat Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Routt',
    latitude: 40.485,
    longitude: -106.8317,
    postal_code: '80477'
  },
  '80478': {
    country: 'US',
    city: 'Tabernash',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Grand',
    latitude: 39.9797,
    longitude: -105.8615,
    postal_code: '80478'
  },
  '80479': {
    country: 'US',
    city: 'Toponas',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Routt',
    latitude: 40.0603,
    longitude: -106.8081,
    postal_code: '80479'
  },
  '80480': {
    country: 'US',
    city: 'Walden',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Jackson',
    latitude: 40.71,
    longitude: -106.2767,
    postal_code: '80480'
  },
  '80481': {
    country: 'US',
    city: 'Ward',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.0726,
    longitude: -105.508,
    postal_code: '80481'
  },
  '80482': {
    country: 'US',
    city: 'Winter Park',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Grand',
    latitude: 39.8939,
    longitude: -105.7845,
    postal_code: '80482'
  },
  '80483': {
    country: 'US',
    city: 'Yampa',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Routt',
    latitude: 40.1304,
    longitude: -106.9116,
    postal_code: '80483'
  },
  '80487': {
    country: 'US',
    city: 'Steamboat Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Routt',
    latitude: 40.6327,
    longitude: -106.9318,
    postal_code: '80487'
  },
  '80488': {
    country: 'US',
    city: 'Steamboat Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Routt',
    latitude: 40.6197,
    longitude: -106.8607,
    postal_code: '80488'
  },
  '80497': {
    country: 'US',
    city: 'Silverthorne',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Summit',
    latitude: 39.6411,
    longitude: -106.108,
    postal_code: '80497'
  },
  '80498': {
    country: 'US',
    city: 'Silverthorne',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Summit',
    latitude: 39.7647,
    longitude: -106.2211,
    postal_code: '80498'
  },
  '80501': {
    country: 'US',
    city: 'Longmont',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.1779,
    longitude: -105.1009,
    postal_code: '80501'
  },
  '80502': {
    country: 'US',
    city: 'Longmont',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.1672,
    longitude: -105.1019,
    postal_code: '80502'
  },
  '80503': {
    country: 'US',
    city: 'Longmont',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.1559,
    longitude: -105.1624,
    postal_code: '80503'
  },
  '80504': {
    country: 'US',
    city: 'Longmont',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.1306,
    longitude: -104.9504,
    postal_code: '80504'
  },
  '80510': {
    country: 'US',
    city: 'Allenspark',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.2268,
    longitude: -105.5201,
    postal_code: '80510'
  },
  '80511': {
    country: 'US',
    city: 'Estes Park',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.6281,
    longitude: -105.5692,
    postal_code: '80511'
  },
  '80512': {
    country: 'US',
    city: 'Bellvue',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.6265,
    longitude: -105.261,
    postal_code: '80512'
  },
  '80513': {
    country: 'US',
    city: 'Berthoud',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.2993,
    longitude: -105.1055,
    postal_code: '80513'
  },
  '80514': {
    country: 'US',
    city: 'Dacono',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.0836,
    longitude: -104.9297,
    postal_code: '80514'
  },
  '80515': {
    country: 'US',
    city: 'Drake',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.4275,
    longitude: -105.3831,
    postal_code: '80515'
  },
  '80516': {
    country: 'US',
    city: 'Erie',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.0597,
    longitude: -105.0686,
    postal_code: '80516'
  },
  '80517': {
    country: 'US',
    city: 'Estes Park',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.3658,
    longitude: -105.5142,
    postal_code: '80517'
  },
  '80520': {
    country: 'US',
    city: 'Firestone',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.1125,
    longitude: -104.9366,
    postal_code: '80520'
  },
  '80521': {
    country: 'US',
    city: 'Fort Collins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.5813,
    longitude: -105.1039,
    postal_code: '80521'
  },
  '80522': {
    country: 'US',
    city: 'Fort Collins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.6429,
    longitude: -105.057,
    postal_code: '80522'
  },
  '80523': {
    country: 'US',
    city: 'Fort Collins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.5853,
    longitude: -105.0844,
    postal_code: '80523'
  },
  '80524': {
    country: 'US',
    city: 'Fort Collins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.5986,
    longitude: -105.0581,
    postal_code: '80524'
  },
  '80525': {
    country: 'US',
    city: 'Fort Collins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.5384,
    longitude: -105.0547,
    postal_code: '80525'
  },
  '80526': {
    country: 'US',
    city: 'Fort Collins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.5473,
    longitude: -105.1076,
    postal_code: '80526'
  },
  '80527': {
    country: 'US',
    city: 'Fort Collins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.5853,
    longitude: -105.0844,
    postal_code: '80527'
  },
  '80528': {
    country: 'US',
    city: 'Fort Collins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.4961,
    longitude: -105.0002,
    postal_code: '80528'
  },
  '80530': {
    country: 'US',
    city: 'Frederick',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.0978,
    longitude: -104.9293,
    postal_code: '80530'
  },
  '80532': {
    country: 'US',
    city: 'Glen Haven',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.4578,
    longitude: -105.447,
    postal_code: '80532'
  },
  '80533': {
    country: 'US',
    city: 'Hygiene',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.1815,
    longitude: -105.2327,
    postal_code: '80533'
  },
  '80534': {
    country: 'US',
    city: 'Johnstown',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.3355,
    longitude: -104.9236,
    postal_code: '80534'
  },
  '80535': {
    country: 'US',
    city: 'Laporte',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.6347,
    longitude: -105.1488,
    postal_code: '80535'
  },
  '80536': {
    country: 'US',
    city: 'Livermore',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.8701,
    longitude: -105.3766,
    postal_code: '80536'
  },
  '80537': {
    country: 'US',
    city: 'Loveland',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.3849,
    longitude: -105.0916,
    postal_code: '80537'
  },
  '80538': {
    country: 'US',
    city: 'Loveland',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.4262,
    longitude: -105.09,
    postal_code: '80538'
  },
  '80539': {
    country: 'US',
    city: 'Loveland',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.3978,
    longitude: -105.075,
    postal_code: '80539'
  },
  '80540': {
    country: 'US',
    city: 'Lyons',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.2357,
    longitude: -105.3231,
    postal_code: '80540'
  },
  '80541': {
    country: 'US',
    city: 'Masonville',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.4875,
    longitude: -105.2108,
    postal_code: '80541'
  },
  '80542': {
    country: 'US',
    city: 'Mead',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.2347,
    longitude: -104.9994,
    postal_code: '80542'
  },
  '80543': {
    country: 'US',
    city: 'Milliken',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.3294,
    longitude: -104.8552,
    postal_code: '80543'
  },
  '80544': {
    country: 'US',
    city: 'Niwot',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Boulder',
    latitude: 40.1039,
    longitude: -105.1708,
    postal_code: '80544'
  },
  '80545': {
    country: 'US',
    city: 'Red Feather Lakes',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.8659,
    longitude: -105.6893,
    postal_code: '80545'
  },
  '80546': {
    country: 'US',
    city: 'Severance',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.525,
    longitude: -104.8505,
    postal_code: '80546'
  },
  '80547': {
    country: 'US',
    city: 'Timnath',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.5291,
    longitude: -104.9853,
    postal_code: '80547'
  },
  '80549': {
    country: 'US',
    city: 'Wellington',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.7255,
    longitude: -105.0318,
    postal_code: '80549'
  },
  '80550': {
    country: 'US',
    city: 'Windsor',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.4837,
    longitude: -104.8994,
    postal_code: '80550'
  },
  '80551': {
    country: 'US',
    city: 'Windsor',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.4775,
    longitude: -104.9014,
    postal_code: '80551'
  },
  '80553': {
    country: 'US',
    city: 'Fort Collins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Larimer',
    latitude: 40.5853,
    longitude: -105.0844,
    postal_code: '80553'
  },
  '80601': {
    country: 'US',
    city: 'Brighton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.943,
    longitude: -104.7866,
    postal_code: '80601'
  },
  '80602': {
    country: 'US',
    city: 'Brighton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.9636,
    longitude: -104.9072,
    postal_code: '80602'
  },
  '80603': {
    country: 'US',
    city: 'Brighton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.9515,
    longitude: -104.7746,
    postal_code: '80603'
  },
  '80610': {
    country: 'US',
    city: 'Ault',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.5938,
    longitude: -104.7356,
    postal_code: '80610'
  },
  '80611': {
    country: 'US',
    city: 'Briggsdale',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.6392,
    longitude: -104.2871,
    postal_code: '80611'
  },
  '80612': {
    country: 'US',
    city: 'Carr',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.8666,
    longitude: -104.8859,
    postal_code: '80612'
  },
  '80614': {
    country: 'US',
    city: 'Eastlake',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.9239,
    longitude: -104.9614,
    postal_code: '80614'
  },
  '80615': {
    country: 'US',
    city: 'Eaton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.5273,
    longitude: -104.7146,
    postal_code: '80615'
  },
  '80620': {
    country: 'US',
    city: 'Evans',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.3803,
    longitude: -104.6971,
    postal_code: '80620'
  },
  '80621': {
    country: 'US',
    city: 'Fort Lupton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.108,
    longitude: -104.8013,
    postal_code: '80621'
  },
  '80622': {
    country: 'US',
    city: 'Galeton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.5378,
    longitude: -104.4585,
    postal_code: '80622'
  },
  '80623': {
    country: 'US',
    city: 'Gilcrest',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.2854,
    longitude: -104.7825,
    postal_code: '80623'
  },
  '80624': {
    country: 'US',
    city: 'Gill',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.4696,
    longitude: -104.5,
    postal_code: '80624'
  },
  '80631': {
    country: 'US',
    city: 'Greeley',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.385,
    longitude: -104.6806,
    postal_code: '80631'
  },
  '80632': {
    country: 'US',
    city: 'Greeley',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.3766,
    longitude: -104.7629,
    postal_code: '80632'
  },
  '80633': {
    country: 'US',
    city: 'Greeley',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.4233,
    longitude: -104.7091,
    postal_code: '80633'
  },
  '80634': {
    country: 'US',
    city: 'Greeley',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.4109,
    longitude: -104.7541,
    postal_code: '80634'
  },
  '80638': {
    country: 'US',
    city: 'Greeley',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.4233,
    longitude: -104.7091,
    postal_code: '80638'
  },
  '80639': {
    country: 'US',
    city: 'Greeley',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.3993,
    longitude: -104.7017,
    postal_code: '80639'
  },
  '80640': {
    country: 'US',
    city: 'Henderson',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Adams',
    latitude: 39.8983,
    longitude: -104.8718,
    postal_code: '80640'
  },
  '80642': {
    country: 'US',
    city: 'Hudson',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.0606,
    longitude: -104.6532,
    postal_code: '80642'
  },
  '80643': {
    country: 'US',
    city: 'Keenesburg',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.0958,
    longitude: -104.4464,
    postal_code: '80643'
  },
  '80644': {
    country: 'US',
    city: 'Kersey',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.3963,
    longitude: -104.5288,
    postal_code: '80644'
  },
  '80645': {
    country: 'US',
    city: 'La Salle',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.3211,
    longitude: -104.7268,
    postal_code: '80645'
  },
  '80646': {
    country: 'US',
    city: 'Lucerne',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.4824,
    longitude: -104.7054,
    postal_code: '80646'
  },
  '80648': {
    country: 'US',
    city: 'Nunn',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.7265,
    longitude: -104.785,
    postal_code: '80648'
  },
  '80649': {
    country: 'US',
    city: 'Orchard',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Morgan',
    latitude: 40.3639,
    longitude: -104.0973,
    postal_code: '80649'
  },
  '80650': {
    country: 'US',
    city: 'Pierce',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.6359,
    longitude: -104.7638,
    postal_code: '80650'
  },
  '80651': {
    country: 'US',
    city: 'Platteville',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.2131,
    longitude: -104.8028,
    postal_code: '80651'
  },
  '80652': {
    country: 'US',
    city: 'Roggen',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.0878,
    longitude: -104.282,
    postal_code: '80652'
  },
  '80653': {
    country: 'US',
    city: 'Weldona',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Morgan',
    latitude: 40.3681,
    longitude: -103.9678,
    postal_code: '80653'
  },
  '80654': {
    country: 'US',
    city: 'Wiggins',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Morgan',
    latitude: 40.1598,
    longitude: -104.0468,
    postal_code: '80654'
  },
  '80701': {
    country: 'US',
    city: 'Fort Morgan',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Morgan',
    latitude: 40.2541,
    longitude: -103.8031,
    postal_code: '80701'
  },
  '80705': {
    country: 'US',
    city: 'Log Lane Village',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Morgan',
    latitude: 40.2707,
    longitude: -103.8338,
    postal_code: '80705'
  },
  '80720': {
    country: 'US',
    city: 'Akron',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Washington',
    latitude: 40.1803,
    longitude: -103.2259,
    postal_code: '80720'
  },
  '80721': {
    country: 'US',
    city: 'Amherst',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Phillips',
    latitude: 40.6824,
    longitude: -102.1706,
    postal_code: '80721'
  },
  '80722': {
    country: 'US',
    city: 'Atwood',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Logan',
    latitude: 40.5082,
    longitude: -103.2749,
    postal_code: '80722'
  },
  '80723': {
    country: 'US',
    city: 'Brush',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Morgan',
    latitude: 40.2603,
    longitude: -103.6279,
    postal_code: '80723'
  },
  '80726': {
    country: 'US',
    city: 'Crook',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Logan',
    latitude: 40.8747,
    longitude: -102.8472,
    postal_code: '80726'
  },
  '80727': {
    country: 'US',
    city: 'Eckley',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Yuma',
    latitude: 40.1138,
    longitude: -102.4828,
    postal_code: '80727'
  },
  '80728': {
    country: 'US',
    city: 'Fleming',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Logan',
    latitude: 40.637,
    longitude: -102.8688,
    postal_code: '80728'
  },
  '80729': {
    country: 'US',
    city: 'Grover',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.8716,
    longitude: -104.2346,
    postal_code: '80729'
  },
  '80731': {
    country: 'US',
    city: 'Haxtun',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Phillips',
    latitude: 40.6406,
    longitude: -102.6052,
    postal_code: '80731'
  },
  '80732': {
    country: 'US',
    city: 'Hereford',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.9751,
    longitude: -104.3053,
    postal_code: '80732'
  },
  '80733': {
    country: 'US',
    city: 'Hillrose',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Morgan',
    latitude: 40.3459,
    longitude: -103.5057,
    postal_code: '80733'
  },
  '80734': {
    country: 'US',
    city: 'Holyoke',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Phillips',
    latitude: 40.5825,
    longitude: -102.2825,
    postal_code: '80734'
  },
  '80735': {
    country: 'US',
    city: 'Idalia',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Yuma',
    latitude: 39.8167,
    longitude: -102.4262,
    postal_code: '80735'
  },
  '80736': {
    country: 'US',
    city: 'Iliff',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Logan',
    latitude: 40.7692,
    longitude: -103.0968,
    postal_code: '80736'
  },
  '80737': {
    country: 'US',
    city: 'Julesburg',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Sedgwick',
    latitude: 40.9708,
    longitude: -102.2575,
    postal_code: '80737'
  },
  '80740': {
    country: 'US',
    city: 'Lindon',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Washington',
    latitude: 39.7909,
    longitude: -103.4142,
    postal_code: '80740'
  },
  '80741': {
    country: 'US',
    city: 'Merino',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Logan',
    latitude: 40.5708,
    longitude: -103.4719,
    postal_code: '80741'
  },
  '80742': {
    country: 'US',
    city: 'New Raymer',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.6851,
    longitude: -103.839,
    postal_code: '80742'
  },
  '80743': {
    country: 'US',
    city: 'Otis',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Washington',
    latitude: 40.203,
    longitude: -102.9392,
    postal_code: '80743'
  },
  '80744': {
    country: 'US',
    city: 'Ovid',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Sedgwick',
    latitude: 40.9459,
    longitude: -102.3874,
    postal_code: '80744'
  },
  '80745': {
    country: 'US',
    city: 'Padroni',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Logan',
    latitude: 40.8842,
    longitude: -103.3728,
    postal_code: '80745'
  },
  '80746': {
    country: 'US',
    city: 'Paoli',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Phillips',
    latitude: 40.6106,
    longitude: -102.4722,
    postal_code: '80746'
  },
  '80747': {
    country: 'US',
    city: 'Peetz',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Logan',
    latitude: 40.9519,
    longitude: -103.1166,
    postal_code: '80747'
  },
  '80749': {
    country: 'US',
    city: 'Sedgwick',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Sedgwick',
    latitude: 40.9103,
    longitude: -102.5291,
    postal_code: '80749'
  },
  '80750': {
    country: 'US',
    city: 'Snyder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Morgan',
    latitude: 40.3307,
    longitude: -103.5971,
    postal_code: '80750'
  },
  '80751': {
    country: 'US',
    city: 'Sterling',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Logan',
    latitude: 40.6306,
    longitude: -103.2212,
    postal_code: '80751'
  },
  '80754': {
    country: 'US',
    city: 'Stoneham',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Weld',
    latitude: 40.687,
    longitude: -103.6387,
    postal_code: '80754'
  },
  '80755': {
    country: 'US',
    city: 'Vernon',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Yuma',
    latitude: 39.9331,
    longitude: -102.3193,
    postal_code: '80755'
  },
  '80757': {
    country: 'US',
    city: 'Woodrow',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Washington',
    latitude: 39.805,
    longitude: -103.5752,
    postal_code: '80757'
  },
  '80758': {
    country: 'US',
    city: 'Wray',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Yuma',
    latitude: 40.0685,
    longitude: -102.393,
    postal_code: '80758'
  },
  '80759': {
    country: 'US',
    city: 'Yuma',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Yuma',
    latitude: 40.1301,
    longitude: -102.7072,
    postal_code: '80759'
  },
  '80801': {
    country: 'US',
    city: 'Anton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Washington',
    latitude: 39.6909,
    longitude: -103.1373,
    postal_code: '80801'
  },
  '80802': {
    country: 'US',
    city: 'Arapahoe',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Cheyenne',
    latitude: 38.8417,
    longitude: -102.194,
    postal_code: '80802'
  },
  '80804': {
    country: 'US',
    city: 'Arriba',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Lincoln',
    latitude: 39.3025,
    longitude: -103.271,
    postal_code: '80804'
  },
  '80805': {
    country: 'US',
    city: 'Bethune',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Kit Carson',
    latitude: 39.3448,
    longitude: -102.4281,
    postal_code: '80805'
  },
  '80807': {
    country: 'US',
    city: 'Burlington',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Kit Carson',
    latitude: 39.3106,
    longitude: -102.2583,
    postal_code: '80807'
  },
  '80808': {
    country: 'US',
    city: 'Calhan',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.9648,
    longitude: -104.3553,
    postal_code: '80808'
  },
  '80809': {
    country: 'US',
    city: 'Cascade',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8967,
    longitude: -104.9722,
    postal_code: '80809'
  },
  '80810': {
    country: 'US',
    city: 'Cheyenne Wells',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Cheyenne',
    latitude: 38.8198,
    longitude: -102.3582,
    postal_code: '80810'
  },
  '80812': {
    country: 'US',
    city: 'Cope',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Washington',
    latitude: 39.6848,
    longitude: -102.9904,
    postal_code: '80812'
  },
  '80813': {
    country: 'US',
    city: 'Cripple Creek',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Teller',
    latitude: 38.8261,
    longitude: -105.1499,
    postal_code: '80813'
  },
  '80814': {
    country: 'US',
    city: 'Divide',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Teller',
    latitude: 38.9576,
    longitude: -105.1994,
    postal_code: '80814'
  },
  '80815': {
    country: 'US',
    city: 'Flagler',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Kit Carson',
    latitude: 39.3127,
    longitude: -103.0624,
    postal_code: '80815'
  },
  '80816': {
    country: 'US',
    city: 'Florissant',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Teller',
    latitude: 38.8546,
    longitude: -105.3121,
    postal_code: '80816'
  },
  '80817': {
    country: 'US',
    city: 'Fountain',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.6996,
    longitude: -104.7005,
    postal_code: '80817'
  },
  '80818': {
    country: 'US',
    city: 'Genoa',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Lincoln',
    latitude: 39.3383,
    longitude: -103.4607,
    postal_code: '80818'
  },
  '80819': {
    country: 'US',
    city: 'Green Mountain Falls',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.9389,
    longitude: -105.0094,
    postal_code: '80819'
  },
  '80820': {
    country: 'US',
    city: 'Guffey',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Park',
    latitude: 38.8146,
    longitude: -105.5784,
    postal_code: '80820'
  },
  '80821': {
    country: 'US',
    city: 'Hugo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Lincoln',
    latitude: 39.0843,
    longitude: -103.499,
    postal_code: '80821'
  },
  '80822': {
    country: 'US',
    city: 'Joes',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Yuma',
    latitude: 39.6564,
    longitude: -102.6788,
    postal_code: '80822'
  },
  '80823': {
    country: 'US',
    city: 'Karval',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Lincoln',
    latitude: 38.7119,
    longitude: -103.5006,
    postal_code: '80823'
  },
  '80824': {
    country: 'US',
    city: 'Kirk',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Yuma',
    latitude: 39.6171,
    longitude: -102.4776,
    postal_code: '80824'
  },
  '80825': {
    country: 'US',
    city: 'Kit Carson',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Cheyenne',
    latitude: 38.804,
    longitude: -102.8198,
    postal_code: '80825'
  },
  '80826': {
    country: 'US',
    city: 'Limon',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Lincoln',
    latitude: 39.2639,
    longitude: -103.6922,
    postal_code: '80826'
  },
  '80827': {
    country: 'US',
    city: 'Lake George',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Park',
    latitude: 39.0342,
    longitude: -105.4347,
    postal_code: '80827'
  },
  '80828': {
    country: 'US',
    city: 'Limon',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Lincoln',
    latitude: 39.2713,
    longitude: -103.6856,
    postal_code: '80828'
  },
  '80829': {
    country: 'US',
    city: 'Manitou Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.855,
    longitude: -104.9058,
    postal_code: '80829'
  },
  '80830': {
    country: 'US',
    city: 'Matheson',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Elbert',
    latitude: 39.132,
    longitude: -103.9132,
    postal_code: '80830'
  },
  '80831': {
    country: 'US',
    city: 'Peyton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.9541,
    longitude: -104.5472,
    postal_code: '80831'
  },
  '80832': {
    country: 'US',
    city: 'Ramah',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 39.0736,
    longitude: -104.1247,
    postal_code: '80832'
  },
  '80833': {
    country: 'US',
    city: 'Rush',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.7642,
    longitude: -104.0241,
    postal_code: '80833'
  },
  '80834': {
    country: 'US',
    city: 'Seibert',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Kit Carson',
    latitude: 39.3183,
    longitude: -102.8822,
    postal_code: '80834'
  },
  '80835': {
    country: 'US',
    city: 'Simla',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Elbert',
    latitude: 39.2087,
    longitude: -104.0702,
    postal_code: '80835'
  },
  '80836': {
    country: 'US',
    city: 'Stratton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Kit Carson',
    latitude: 39.3087,
    longitude: -102.5979,
    postal_code: '80836'
  },
  '80840': {
    country: 'US',
    city: 'Usaf Academy',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 39.0896,
    longitude: -103.8188,
    postal_code: '80840'
  },
  '80841': {
    country: 'US',
    city: 'Usaf Academy',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 39.0896,
    longitude: -103.8188,
    postal_code: '80841'
  },
  '80860': {
    country: 'US',
    city: 'Victor',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Teller',
    latitude: 38.71,
    longitude: -105.14,
    postal_code: '80860'
  },
  '80861': {
    country: 'US',
    city: 'Vona',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Kit Carson',
    latitude: 39.3236,
    longitude: -102.7393,
    postal_code: '80861'
  },
  '80862': {
    country: 'US',
    city: 'Wild Horse',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Cheyenne',
    latitude: 38.8041,
    longitude: -103.0798,
    postal_code: '80862'
  },
  '80863': {
    country: 'US',
    city: 'Woodland Park',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Teller',
    latitude: 38.9969,
    longitude: -105.0623,
    postal_code: '80863'
  },
  '80864': {
    country: 'US',
    city: 'Yoder',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.7753,
    longitude: -104.2184,
    postal_code: '80864'
  },
  '80866': {
    country: 'US',
    city: 'Woodland Park',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Teller',
    latitude: 38.9939,
    longitude: -105.0569,
    postal_code: '80866'
  },
  '80901': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80901'
  },
  '80902': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.7536,
    longitude: -104.8063,
    postal_code: '80902'
  },
  '80903': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8388,
    longitude: -104.8145,
    postal_code: '80903'
  },
  '80904': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8533,
    longitude: -104.8595,
    postal_code: '80904'
  },
  '80905': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8377,
    longitude: -104.837,
    postal_code: '80905'
  },
  '80906': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.7902,
    longitude: -104.8199,
    postal_code: '80906'
  },
  '80907': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.876,
    longitude: -104.817,
    postal_code: '80907'
  },
  '80908': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 39.0237,
    longitude: -104.6933,
    postal_code: '80908'
  },
  '80909': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.852,
    longitude: -104.7735,
    postal_code: '80909'
  },
  '80910': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8152,
    longitude: -104.7703,
    postal_code: '80910'
  },
  '80911': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.7457,
    longitude: -104.7223,
    postal_code: '80911'
  },
  '80912': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80912'
  },
  '80913': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.73,
    longitude: -104.7536,
    postal_code: '80913'
  },
  '80914': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8192,
    longitude: -104.7012,
    postal_code: '80914'
  },
  '80915': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8558,
    longitude: -104.7134,
    postal_code: '80915'
  },
  '80916': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8076,
    longitude: -104.7403,
    postal_code: '80916'
  },
  '80917': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.886,
    longitude: -104.7399,
    postal_code: '80917'
  },
  '80918': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.9129,
    longitude: -104.7734,
    postal_code: '80918'
  },
  '80919': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.9268,
    longitude: -104.8464,
    postal_code: '80919'
  },
  '80920': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.9497,
    longitude: -104.767,
    postal_code: '80920'
  },
  '80921': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 39.0487,
    longitude: -104.814,
    postal_code: '80921'
  },
  '80922': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.905,
    longitude: -104.6982,
    postal_code: '80922'
  },
  '80923': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.9189,
    longitude: -104.7045,
    postal_code: '80923'
  },
  '80924': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.9676,
    longitude: -104.7211,
    postal_code: '80924'
  },
  '80925': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.7378,
    longitude: -104.6459,
    postal_code: '80925'
  },
  '80926': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.6981,
    longitude: -104.8505,
    postal_code: '80926'
  },
  '80927': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.9286,
    longitude: -104.6583,
    postal_code: '80927'
  },
  '80928': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.6233,
    longitude: -104.457,
    postal_code: '80928'
  },
  '80929': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.7968,
    longitude: -104.6079,
    postal_code: '80929'
  },
  '80930': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8289,
    longitude: -104.5269,
    postal_code: '80930'
  },
  '80931': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80931'
  },
  '80932': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80932'
  },
  '80933': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80933'
  },
  '80934': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80934'
  },
  '80935': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80935'
  },
  '80936': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80936'
  },
  '80937': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80937'
  },
  '80938': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.9047,
    longitude: -104.6634,
    postal_code: '80938'
  },
  '80939': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8776,
    longitude: -104.6774,
    postal_code: '80939'
  },
  '80941': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80941'
  },
  '80942': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80942'
  },
  '80944': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80944'
  },
  '80946': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80946'
  },
  '80947': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80947'
  },
  '80949': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80949'
  },
  '80950': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80950'
  },
  '80951': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8881,
    longitude: -104.6556,
    postal_code: '80951'
  },
  '80960': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80960'
  },
  '80962': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80962'
  },
  '80970': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80970'
  },
  '80977': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80977'
  },
  '80995': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80995'
  },
  '80997': {
    country: 'US',
    city: 'Colorado Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'El Paso',
    latitude: 38.8339,
    longitude: -104.8214,
    postal_code: '80997'
  },
  '81001': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.2879,
    longitude: -104.5848,
    postal_code: '81001'
  },
  '81002': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.2544,
    longitude: -104.6091,
    postal_code: '81002'
  },
  '81003': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.2843,
    longitude: -104.6234,
    postal_code: '81003'
  },
  '81004': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.2441,
    longitude: -104.6278,
    postal_code: '81004'
  },
  '81005': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.2352,
    longitude: -104.66,
    postal_code: '81005'
  },
  '81006': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.2447,
    longitude: -104.5318,
    postal_code: '81006'
  },
  '81007': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.387,
    longitude: -104.7792,
    postal_code: '81007'
  },
  '81008': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.3133,
    longitude: -104.6284,
    postal_code: '81008'
  },
  '81009': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.1286,
    longitude: -104.5523,
    postal_code: '81009'
  },
  '81010': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.1286,
    longitude: -104.5523,
    postal_code: '81010'
  },
  '81011': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.1286,
    longitude: -104.5523,
    postal_code: '81011'
  },
  '81012': {
    country: 'US',
    city: 'Pueblo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.1286,
    longitude: -104.5523,
    postal_code: '81012'
  },
  '81019': {
    country: 'US',
    city: 'Colorado City',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 37.9441,
    longitude: -104.8412,
    postal_code: '81019'
  },
  '81020': {
    country: 'US',
    city: 'Aguilar',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Las Animas',
    latitude: 37.3933,
    longitude: -104.6769,
    postal_code: '81020'
  },
  '81021': {
    country: 'US',
    city: 'Arlington',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Kiowa',
    latitude: 38.4068,
    longitude: -103.3697,
    postal_code: '81021'
  },
  '81022': {
    country: 'US',
    city: 'Avondale',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.1025,
    longitude: -104.5298,
    postal_code: '81022'
  },
  '81023': {
    country: 'US',
    city: 'Beulah',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.0837,
    longitude: -104.9724,
    postal_code: '81023'
  },
  '81024': {
    country: 'US',
    city: 'Boncarbo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Las Animas',
    latitude: 37.2081,
    longitude: -104.7198,
    postal_code: '81024'
  },
  '81025': {
    country: 'US',
    city: 'Boone',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 38.2646,
    longitude: -104.2585,
    postal_code: '81025'
  },
  '81027': {
    country: 'US',
    city: 'Branson',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Las Animas',
    latitude: 37.0518,
    longitude: -103.8741,
    postal_code: '81027'
  },
  '81029': {
    country: 'US',
    city: 'Campo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Baca',
    latitude: 37.1195,
    longitude: -102.5464,
    postal_code: '81029'
  },
  '81030': {
    country: 'US',
    city: 'Cheraw',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Otero',
    latitude: 38.107,
    longitude: -103.5102,
    postal_code: '81030'
  },
  '81033': {
    country: 'US',
    city: 'Crowley',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Crowley',
    latitude: 38.1931,
    longitude: -103.8561,
    postal_code: '81033'
  },
  '81034': {
    country: 'US',
    city: 'Crowley',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Crowley',
    latitude: 38.1931,
    longitude: -103.8561,
    postal_code: '81034'
  },
  '81036': {
    country: 'US',
    city: 'Eads',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Kiowa',
    latitude: 38.4408,
    longitude: -102.5549,
    postal_code: '81036'
  },
  '81038': {
    country: 'US',
    city: 'Fort Lyon',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Bent',
    latitude: 37.9555,
    longitude: -103.0725,
    postal_code: '81038'
  },
  '81039': {
    country: 'US',
    city: 'Fowler',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Otero',
    latitude: 38.1231,
    longitude: -104.0299,
    postal_code: '81039'
  },
  '81040': {
    country: 'US',
    city: 'Gardner',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Huerfano',
    latitude: 37.7878,
    longitude: -105.1849,
    postal_code: '81040'
  },
  '81041': {
    country: 'US',
    city: 'Granada',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Prowers',
    latitude: 38.0545,
    longitude: -102.3271,
    postal_code: '81041'
  },
  '81043': {
    country: 'US',
    city: 'Hartman',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Prowers',
    latitude: 38.1195,
    longitude: -102.2168,
    postal_code: '81043'
  },
  '81044': {
    country: 'US',
    city: 'Hasty',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Bent',
    latitude: 37.959,
    longitude: -103.015,
    postal_code: '81044'
  },
  '81045': {
    country: 'US',
    city: 'Haswell',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Kiowa',
    latitude: 38.4474,
    longitude: -103.1505,
    postal_code: '81045'
  },
  '81046': {
    country: 'US',
    city: 'Hoehne',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Las Animas',
    latitude: 37.2796,
    longitude: -104.3584,
    postal_code: '81046'
  },
  '81047': {
    country: 'US',
    city: 'Holly',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Prowers',
    latitude: 38.0205,
    longitude: -102.1415,
    postal_code: '81047'
  },
  '81049': {
    country: 'US',
    city: 'Kim',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Las Animas',
    latitude: 37.3328,
    longitude: -103.3736,
    postal_code: '81049'
  },
  '81050': {
    country: 'US',
    city: 'La Junta',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Otero',
    latitude: 37.9546,
    longitude: -103.6644,
    postal_code: '81050'
  },
  '81052': {
    country: 'US',
    city: 'Lamar',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Prowers',
    latitude: 38.0841,
    longitude: -102.6192,
    postal_code: '81052'
  },
  '81054': {
    country: 'US',
    city: 'Las Animas',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Bent',
    latitude: 37.923,
    longitude: -103.0884,
    postal_code: '81054'
  },
  '81055': {
    country: 'US',
    city: 'La Veta',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Huerfano',
    latitude: 37.5117,
    longitude: -105.0575,
    postal_code: '81055'
  },
  '81057': {
    country: 'US',
    city: 'Mc Clave',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Bent',
    latitude: 38.085,
    longitude: -102.8869,
    postal_code: '81057'
  },
  '81058': {
    country: 'US',
    city: 'Manzanola',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Otero',
    latitude: 38.1109,
    longitude: -103.8766,
    postal_code: '81058'
  },
  '81059': {
    country: 'US',
    city: 'Model',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Las Animas',
    latitude: 37.5192,
    longitude: -104.223,
    postal_code: '81059'
  },
  '81062': {
    country: 'US',
    city: 'Olney Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Crowley',
    latitude: 38.2019,
    longitude: -103.941,
    postal_code: '81062'
  },
  '81063': {
    country: 'US',
    city: 'Ordway',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Crowley',
    latitude: 38.2095,
    longitude: -103.8003,
    postal_code: '81063'
  },
  '81064': {
    country: 'US',
    city: 'Pritchett',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Baca',
    latitude: 37.2723,
    longitude: -102.9145,
    postal_code: '81064'
  },
  '81067': {
    country: 'US',
    city: 'Rocky Ford',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Otero',
    latitude: 38.049,
    longitude: -103.7251,
    postal_code: '81067'
  },
  '81069': {
    country: 'US',
    city: 'Rye',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pueblo',
    latitude: 37.9236,
    longitude: -104.9303,
    postal_code: '81069'
  },
  '81071': {
    country: 'US',
    city: 'Sheridan Lake',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Kiowa',
    latitude: 38.4667,
    longitude: -102.2921,
    postal_code: '81071'
  },
  '81073': {
    country: 'US',
    city: 'Springfield',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Baca',
    latitude: 37.4067,
    longitude: -102.6173,
    postal_code: '81073'
  },
  '81076': {
    country: 'US',
    city: 'Sugar City',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Crowley',
    latitude: 38.2444,
    longitude: -103.6556,
    postal_code: '81076'
  },
  '81077': {
    country: 'US',
    city: 'Swink',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Otero',
    latitude: 38.0145,
    longitude: -103.6283,
    postal_code: '81077'
  },
  '81081': {
    country: 'US',
    city: 'Trinchera',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Las Animas',
    latitude: 37.0757,
    longitude: -104.1184,
    postal_code: '81081'
  },
  '81082': {
    country: 'US',
    city: 'Trinidad',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Las Animas',
    latitude: 37.2691,
    longitude: -104.5038,
    postal_code: '81082'
  },
  '81084': {
    country: 'US',
    city: 'Two Buttes',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Baca',
    latitude: 37.5214,
    longitude: -102.4332,
    postal_code: '81084'
  },
  '81087': {
    country: 'US',
    city: 'Vilas',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Baca',
    latitude: 37.373,
    longitude: -102.4437,
    postal_code: '81087'
  },
  '81089': {
    country: 'US',
    city: 'Walsenburg',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Huerfano',
    latitude: 37.6465,
    longitude: -104.7798,
    postal_code: '81089'
  },
  '81090': {
    country: 'US',
    city: 'Walsh',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Baca',
    latitude: 37.3521,
    longitude: -102.2537,
    postal_code: '81090'
  },
  '81091': {
    country: 'US',
    city: 'Weston',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Las Animas',
    latitude: 37.1702,
    longitude: -104.8247,
    postal_code: '81091'
  },
  '81092': {
    country: 'US',
    city: 'Wiley',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Prowers',
    latitude: 38.159,
    longitude: -102.7147,
    postal_code: '81092'
  },
  '81101': {
    country: 'US',
    city: 'Alamosa',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Alamosa',
    latitude: 37.4703,
    longitude: -105.8786,
    postal_code: '81101'
  },
  '81102': {
    country: 'US',
    city: 'Alamosa',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Alamosa',
    latitude: 37.476,
    longitude: -105.8818,
    postal_code: '81102'
  },
  '81120': {
    country: 'US',
    city: 'Antonito',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Conejos',
    latitude: 37.0855,
    longitude: -106.0379,
    postal_code: '81120'
  },
  '81121': {
    country: 'US',
    city: 'Arboles',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Archuleta',
    latitude: 37.0281,
    longitude: -107.4192,
    postal_code: '81121'
  },
  '81122': {
    country: 'US',
    city: 'Bayfield',
    state: 'Colorado',
    state_short: 'CO',
    county: 'La Plata',
    latitude: 37.2603,
    longitude: -107.6137,
    postal_code: '81122'
  },
  '81123': {
    country: 'US',
    city: 'Blanca',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Costilla',
    latitude: 37.4317,
    longitude: -105.5178,
    postal_code: '81123'
  },
  '81124': {
    country: 'US',
    city: 'Capulin',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Conejos',
    latitude: 37.2839,
    longitude: -106.1114,
    postal_code: '81124'
  },
  '81125': {
    country: 'US',
    city: 'Center',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Saguache',
    latitude: 37.7343,
    longitude: -106.0906,
    postal_code: '81125'
  },
  '81126': {
    country: 'US',
    city: 'Chama',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Costilla',
    latitude: 37.162,
    longitude: -105.3783,
    postal_code: '81126'
  },
  '81128': {
    country: 'US',
    city: 'Chromo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Archuleta',
    latitude: 37.0364,
    longitude: -106.8434,
    postal_code: '81128'
  },
  '81129': {
    country: 'US',
    city: 'Conejos',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Conejos',
    latitude: 37.1011,
    longitude: -106.0265,
    postal_code: '81129'
  },
  '81130': {
    country: 'US',
    city: 'Creede',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mineral',
    latitude: 37.8164,
    longitude: -106.9277,
    postal_code: '81130'
  },
  '81131': {
    country: 'US',
    city: 'Crestone',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Saguache',
    latitude: 37.953,
    longitude: -105.6879,
    postal_code: '81131'
  },
  '81132': {
    country: 'US',
    city: 'Del Norte',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Rio Grande',
    latitude: 37.6447,
    longitude: -106.4073,
    postal_code: '81132'
  },
  '81133': {
    country: 'US',
    city: 'Fort Garland',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Costilla',
    latitude: 37.427,
    longitude: -105.4049,
    postal_code: '81133'
  },
  '81135': {
    country: 'US',
    city: 'Homelake',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Rio Grande',
    latitude: 37.5756,
    longitude: -106.097,
    postal_code: '81135'
  },
  '81136': {
    country: 'US',
    city: 'Hooper',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Alamosa',
    latitude: 37.7232,
    longitude: -105.8712,
    postal_code: '81136'
  },
  '81137': {
    country: 'US',
    city: 'Ignacio',
    state: 'Colorado',
    state_short: 'CO',
    county: 'La Plata',
    latitude: 37.1264,
    longitude: -107.6395,
    postal_code: '81137'
  },
  '81138': {
    country: 'US',
    city: 'Jaroso',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Costilla',
    latitude: 37.0028,
    longitude: -105.6242,
    postal_code: '81138'
  },
  '81140': {
    country: 'US',
    city: 'La Jara',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Conejos',
    latitude: 37.2907,
    longitude: -106.0054,
    postal_code: '81140'
  },
  '81141': {
    country: 'US',
    city: 'Manassa',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Conejos',
    latitude: 37.1755,
    longitude: -105.9278,
    postal_code: '81141'
  },
  '81143': {
    country: 'US',
    city: 'Moffat',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Saguache',
    latitude: 38.0452,
    longitude: -105.8411,
    postal_code: '81143'
  },
  '81144': {
    country: 'US',
    city: 'Monte Vista',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Rio Grande',
    latitude: 37.5731,
    longitude: -106.1408,
    postal_code: '81144'
  },
  '81146': {
    country: 'US',
    city: 'Mosca',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Alamosa',
    latitude: 37.6358,
    longitude: -105.8069,
    postal_code: '81146'
  },
  '81147': {
    country: 'US',
    city: 'Pagosa Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Archuleta',
    latitude: 37.2523,
    longitude: -107.0385,
    postal_code: '81147'
  },
  '81148': {
    country: 'US',
    city: 'Romeo',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Conejos',
    latitude: 37.1721,
    longitude: -105.9858,
    postal_code: '81148'
  },
  '81149': {
    country: 'US',
    city: 'Saguache',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Saguache',
    latitude: 38.0977,
    longitude: -106.1876,
    postal_code: '81149'
  },
  '81151': {
    country: 'US',
    city: 'Sanford',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Conejos',
    latitude: 37.2583,
    longitude: -105.9047,
    postal_code: '81151'
  },
  '81152': {
    country: 'US',
    city: 'San Luis',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Costilla',
    latitude: 37.1066,
    longitude: -105.4781,
    postal_code: '81152'
  },
  '81154': {
    country: 'US',
    city: 'South Fork',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Rio Grande',
    latitude: 37.6725,
    longitude: -106.6125,
    postal_code: '81154'
  },
  '81155': {
    country: 'US',
    city: 'Villa Grove',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Saguache',
    latitude: 38.2952,
    longitude: -106.1102,
    postal_code: '81155'
  },
  '81157': {
    country: 'US',
    city: 'Pagosa Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Archuleta',
    latitude: 37.2694,
    longitude: -107.0098,
    postal_code: '81157'
  },
  '81201': {
    country: 'US',
    city: 'Salida',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Chaffee',
    latitude: 38.5259,
    longitude: -105.9978,
    postal_code: '81201'
  },
  '81210': {
    country: 'US',
    city: 'Almont',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gunnison',
    latitude: 38.82,
    longitude: -106.6603,
    postal_code: '81210'
  },
  '81211': {
    country: 'US',
    city: 'Buena Vista',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Chaffee',
    latitude: 38.838,
    longitude: -106.1471,
    postal_code: '81211'
  },
  '81212': {
    country: 'US',
    city: 'Canon City',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.4451,
    longitude: -105.2178,
    postal_code: '81212'
  },
  '81215': {
    country: 'US',
    city: 'Canon City',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.4776,
    longitude: -105.4766,
    postal_code: '81215'
  },
  '81220': {
    country: 'US',
    city: 'Cimarron',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montrose',
    latitude: 38.3876,
    longitude: -107.4824,
    postal_code: '81220'
  },
  '81221': {
    country: 'US',
    city: 'Coal Creek',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.3611,
    longitude: -105.1483,
    postal_code: '81221'
  },
  '81222': {
    country: 'US',
    city: 'Coaldale',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.3824,
    longitude: -105.7843,
    postal_code: '81222'
  },
  '81223': {
    country: 'US',
    city: 'Cotopaxi',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.3703,
    longitude: -105.6881,
    postal_code: '81223'
  },
  '81224': {
    country: 'US',
    city: 'Crested Butte',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gunnison',
    latitude: 38.8691,
    longitude: -106.9619,
    postal_code: '81224'
  },
  '81225': {
    country: 'US',
    city: 'Crested Butte',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gunnison',
    latitude: 38.9006,
    longitude: -106.9631,
    postal_code: '81225'
  },
  '81226': {
    country: 'US',
    city: 'Florence',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.385,
    longitude: -105.1232,
    postal_code: '81226'
  },
  '81227': {
    country: 'US',
    city: 'Monarch',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Chaffee',
    latitude: 38.553,
    longitude: -106.2946,
    postal_code: '81227'
  },
  '81228': {
    country: 'US',
    city: 'Granite',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Chaffee',
    latitude: 39.0307,
    longitude: -106.2566,
    postal_code: '81228'
  },
  '81230': {
    country: 'US',
    city: 'Gunnison',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gunnison',
    latitude: 38.5511,
    longitude: -106.931,
    postal_code: '81230'
  },
  '81231': {
    country: 'US',
    city: 'Gunnison',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gunnison',
    latitude: 38.5458,
    longitude: -106.9253,
    postal_code: '81231'
  },
  '81232': {
    country: 'US',
    city: 'Hillside',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.2653,
    longitude: -105.6117,
    postal_code: '81232'
  },
  '81233': {
    country: 'US',
    city: 'Howard',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.41,
    longitude: -105.7698,
    postal_code: '81233'
  },
  '81235': {
    country: 'US',
    city: 'Lake City',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Hinsdale',
    latitude: 37.9868,
    longitude: -107.302,
    postal_code: '81235'
  },
  '81236': {
    country: 'US',
    city: 'Nathrop',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Chaffee',
    latitude: 38.7103,
    longitude: -106.1166,
    postal_code: '81236'
  },
  '81237': {
    country: 'US',
    city: 'Ohio City',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gunnison',
    latitude: 38.5908,
    longitude: -106.6028,
    postal_code: '81237'
  },
  '81239': {
    country: 'US',
    city: 'Parlin',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gunnison',
    latitude: 38.5371,
    longitude: -106.6352,
    postal_code: '81239'
  },
  '81240': {
    country: 'US',
    city: 'Penrose',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.4336,
    longitude: -105.0113,
    postal_code: '81240'
  },
  '81241': {
    country: 'US',
    city: 'Pitkin',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gunnison',
    latitude: 38.6179,
    longitude: -106.5146,
    postal_code: '81241'
  },
  '81242': {
    country: 'US',
    city: 'Poncha Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Chaffee',
    latitude: 38.5128,
    longitude: -106.0772,
    postal_code: '81242'
  },
  '81243': {
    country: 'US',
    city: 'Powderhorn',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gunnison',
    latitude: 38.2822,
    longitude: -107.1084,
    postal_code: '81243'
  },
  '81244': {
    country: 'US',
    city: 'Rockvale',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.3697,
    longitude: -105.1639,
    postal_code: '81244'
  },
  '81248': {
    country: 'US',
    city: 'Sargents',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Saguache',
    latitude: 38.4033,
    longitude: -106.4161,
    postal_code: '81248'
  },
  '81251': {
    country: 'US',
    city: 'Twin Lakes',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Lake',
    latitude: 39.1011,
    longitude: -106.4416,
    postal_code: '81251'
  },
  '81252': {
    country: 'US',
    city: 'Westcliffe',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Custer',
    latitude: 38.123,
    longitude: -105.4332,
    postal_code: '81252'
  },
  '81253': {
    country: 'US',
    city: 'Wetmore',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Custer',
    latitude: 38.1217,
    longitude: -105.1477,
    postal_code: '81253'
  },
  '81290': {
    country: 'US',
    city: 'Florence',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Fremont',
    latitude: 38.3903,
    longitude: -105.1186,
    postal_code: '81290'
  },
  '81301': {
    country: 'US',
    city: 'Durango',
    state: 'Colorado',
    state_short: 'CO',
    county: 'La Plata',
    latitude: 37.2874,
    longitude: -107.8617,
    postal_code: '81301'
  },
  '81302': {
    country: 'US',
    city: 'Durango',
    state: 'Colorado',
    state_short: 'CO',
    county: 'La Plata',
    latitude: 37.2753,
    longitude: -107.8801,
    postal_code: '81302'
  },
  '81303': {
    country: 'US',
    city: 'Durango',
    state: 'Colorado',
    state_short: 'CO',
    county: 'La Plata',
    latitude: 37.1156,
    longitude: -107.8909,
    postal_code: '81303'
  },
  '81320': {
    country: 'US',
    city: 'Cahone',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Dolores',
    latitude: 37.7182,
    longitude: -108.7917,
    postal_code: '81320'
  },
  '81321': {
    country: 'US',
    city: 'Cortez',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montezuma',
    latitude: 37.3549,
    longitude: -108.5837,
    postal_code: '81321'
  },
  '81323': {
    country: 'US',
    city: 'Dolores',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montezuma',
    latitude: 37.4666,
    longitude: -108.4717,
    postal_code: '81323'
  },
  '81324': {
    country: 'US',
    city: 'Dove Creek',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Dolores',
    latitude: 37.7632,
    longitude: -108.9181,
    postal_code: '81324'
  },
  '81325': {
    country: 'US',
    city: 'Egnar',
    state: 'Colorado',
    state_short: 'CO',
    county: 'San Miguel',
    latitude: 37.9344,
    longitude: -108.9299,
    postal_code: '81325'
  },
  '81326': {
    country: 'US',
    city: 'Hesperus',
    state: 'Colorado',
    state_short: 'CO',
    county: 'La Plata',
    latitude: 37.1654,
    longitude: -108.1219,
    postal_code: '81326'
  },
  '81327': {
    country: 'US',
    city: 'Lewis',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montezuma',
    latitude: 37.5177,
    longitude: -108.6546,
    postal_code: '81327'
  },
  '81328': {
    country: 'US',
    city: 'Mancos',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montezuma',
    latitude: 37.3471,
    longitude: -108.2982,
    postal_code: '81328'
  },
  '81329': {
    country: 'US',
    city: 'Marvel',
    state: 'Colorado',
    state_short: 'CO',
    county: 'La Plata',
    latitude: 37.0926,
    longitude: -108.1252,
    postal_code: '81329'
  },
  '81330': {
    country: 'US',
    city: 'Mesa Verde National Park',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montezuma',
    latitude: 37.2313,
    longitude: -108.4825,
    postal_code: '81330'
  },
  '81331': {
    country: 'US',
    city: 'Pleasant View',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montezuma',
    latitude: 37.5888,
    longitude: -108.8095,
    postal_code: '81331'
  },
  '81332': {
    country: 'US',
    city: 'Rico',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Dolores',
    latitude: 37.6905,
    longitude: -108.0324,
    postal_code: '81332'
  },
  '81334': {
    country: 'US',
    city: 'Towaoc',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montezuma',
    latitude: 37.2084,
    longitude: -108.72,
    postal_code: '81334'
  },
  '81335': {
    country: 'US',
    city: 'Yellow Jacket',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montezuma',
    latitude: 37.5344,
    longitude: -108.7173,
    postal_code: '81335'
  },
  '81401': {
    country: 'US',
    city: 'Montrose',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montrose',
    latitude: 38.4678,
    longitude: -107.8752,
    postal_code: '81401'
  },
  '81402': {
    country: 'US',
    city: 'Montrose',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montrose',
    latitude: 38.4851,
    longitude: -107.886,
    postal_code: '81402'
  },
  '81403': {
    country: 'US',
    city: 'Montrose',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montrose',
    latitude: 38.3602,
    longitude: -107.9381,
    postal_code: '81403'
  },
  '81410': {
    country: 'US',
    city: 'Austin',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Delta',
    latitude: 38.7975,
    longitude: -107.9738,
    postal_code: '81410'
  },
  '81411': {
    country: 'US',
    city: 'Bedrock',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montrose',
    latitude: 38.2509,
    longitude: -108.9799,
    postal_code: '81411'
  },
  '81413': {
    country: 'US',
    city: 'Cedaredge',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Delta',
    latitude: 38.9119,
    longitude: -107.9268,
    postal_code: '81413'
  },
  '81414': {
    country: 'US',
    city: 'Cory',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Delta',
    latitude: 38.7887,
    longitude: -107.9862,
    postal_code: '81414'
  },
  '81415': {
    country: 'US',
    city: 'Crawford',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Delta',
    latitude: 38.6941,
    longitude: -107.6149,
    postal_code: '81415'
  },
  '81416': {
    country: 'US',
    city: 'Delta',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Delta',
    latitude: 38.7349,
    longitude: -108.0604,
    postal_code: '81416'
  },
  '81418': {
    country: 'US',
    city: 'Eckert',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Delta',
    latitude: 38.845,
    longitude: -107.9625,
    postal_code: '81418'
  },
  '81419': {
    country: 'US',
    city: 'Hotchkiss',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Delta',
    latitude: 38.8124,
    longitude: -107.7472,
    postal_code: '81419'
  },
  '81420': {
    country: 'US',
    city: 'Lazear',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Delta',
    latitude: 38.7817,
    longitude: -107.7808,
    postal_code: '81420'
  },
  '81422': {
    country: 'US',
    city: 'Naturita',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montrose',
    latitude: 38.2183,
    longitude: -108.5687,
    postal_code: '81422'
  },
  '81423': {
    country: 'US',
    city: 'Norwood',
    state: 'Colorado',
    state_short: 'CO',
    county: 'San Miguel',
    latitude: 38.1104,
    longitude: -108.2845,
    postal_code: '81423'
  },
  '81424': {
    country: 'US',
    city: 'Nucla',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montrose',
    latitude: 38.2682,
    longitude: -108.5476,
    postal_code: '81424'
  },
  '81425': {
    country: 'US',
    city: 'Olathe',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montrose',
    latitude: 38.5976,
    longitude: -107.9921,
    postal_code: '81425'
  },
  '81426': {
    country: 'US',
    city: 'Ophir',
    state: 'Colorado',
    state_short: 'CO',
    county: 'San Miguel',
    latitude: 37.8569,
    longitude: -107.8326,
    postal_code: '81426'
  },
  '81427': {
    country: 'US',
    city: 'Ouray',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Ouray',
    latitude: 38.0258,
    longitude: -107.6726,
    postal_code: '81427'
  },
  '81428': {
    country: 'US',
    city: 'Paonia',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Delta',
    latitude: 38.865,
    longitude: -107.5985,
    postal_code: '81428'
  },
  '81429': {
    country: 'US',
    city: 'Paradox',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montrose',
    latitude: 38.3711,
    longitude: -108.9698,
    postal_code: '81429'
  },
  '81430': {
    country: 'US',
    city: 'Placerville',
    state: 'Colorado',
    state_short: 'CO',
    county: 'San Miguel',
    latitude: 38.0167,
    longitude: -108.0534,
    postal_code: '81430'
  },
  '81431': {
    country: 'US',
    city: 'Redvale',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Montrose',
    latitude: 38.1865,
    longitude: -108.3895,
    postal_code: '81431'
  },
  '81432': {
    country: 'US',
    city: 'Ridgway',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Ouray',
    latitude: 38.1381,
    longitude: -107.7533,
    postal_code: '81432'
  },
  '81433': {
    country: 'US',
    city: 'Silverton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'San Juan',
    latitude: 37.809,
    longitude: -107.6667,
    postal_code: '81433'
  },
  '81434': {
    country: 'US',
    city: 'Somerset',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Gunnison',
    latitude: 38.9468,
    longitude: -107.3781,
    postal_code: '81434'
  },
  '81435': {
    country: 'US',
    city: 'Telluride',
    state: 'Colorado',
    state_short: 'CO',
    county: 'San Miguel',
    latitude: 37.94,
    longitude: -107.8214,
    postal_code: '81435'
  },
  '81501': {
    country: 'US',
    city: 'Grand Junction',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.0783,
    longitude: -108.5457,
    postal_code: '81501'
  },
  '81502': {
    country: 'US',
    city: 'Grand Junction',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.0179,
    longitude: -108.4814,
    postal_code: '81502'
  },
  '81503': {
    country: 'US',
    city: 'Grand Junction',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.0307,
    longitude: -108.4361,
    postal_code: '81503'
  },
  '81504': {
    country: 'US',
    city: 'Grand Junction',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.0791,
    longitude: -108.4916,
    postal_code: '81504'
  },
  '81505': {
    country: 'US',
    city: 'Grand Junction',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.1071,
    longitude: -108.5968,
    postal_code: '81505'
  },
  '81506': {
    country: 'US',
    city: 'Grand Junction',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.1032,
    longitude: -108.5491,
    postal_code: '81506'
  },
  '81507': {
    country: 'US',
    city: 'Grand Junction',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.0157,
    longitude: -108.6129,
    postal_code: '81507'
  },
  '81520': {
    country: 'US',
    city: 'Clifton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.0805,
    longitude: -108.4496,
    postal_code: '81520'
  },
  '81521': {
    country: 'US',
    city: 'Fruita',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.1637,
    longitude: -108.7218,
    postal_code: '81521'
  },
  '81522': {
    country: 'US',
    city: 'Gateway',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 38.6784,
    longitude: -108.9719,
    postal_code: '81522'
  },
  '81523': {
    country: 'US',
    city: 'Glade Park',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 38.9894,
    longitude: -108.781,
    postal_code: '81523'
  },
  '81524': {
    country: 'US',
    city: 'Loma',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.2279,
    longitude: -108.8149,
    postal_code: '81524'
  },
  '81525': {
    country: 'US',
    city: 'Mack',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.2554,
    longitude: -108.9296,
    postal_code: '81525'
  },
  '81526': {
    country: 'US',
    city: 'Palisade',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.1032,
    longitude: -108.368,
    postal_code: '81526'
  },
  '81527': {
    country: 'US',
    city: 'Whitewater',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 38.9744,
    longitude: -108.399,
    postal_code: '81527'
  },
  '81601': {
    country: 'US',
    city: 'Glenwood Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Garfield',
    latitude: 39.5296,
    longitude: -107.3252,
    postal_code: '81601'
  },
  '81602': {
    country: 'US',
    city: 'Glenwood Springs',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Garfield',
    latitude: 39.5505,
    longitude: -107.3248,
    postal_code: '81602'
  },
  '81610': {
    country: 'US',
    city: 'Dinosaur',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Moffat',
    latitude: 40.2566,
    longitude: -108.9652,
    postal_code: '81610'
  },
  '81611': {
    country: 'US',
    city: 'Aspen',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pitkin',
    latitude: 39.1951,
    longitude: -106.8236,
    postal_code: '81611'
  },
  '81612': {
    country: 'US',
    city: 'Aspen',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pitkin',
    latitude: 39.2234,
    longitude: -106.8828,
    postal_code: '81612'
  },
  '81615': {
    country: 'US',
    city: 'Snowmass Village',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pitkin',
    latitude: 39.2212,
    longitude: -106.932,
    postal_code: '81615'
  },
  '81620': {
    country: 'US',
    city: 'Avon',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.6313,
    longitude: -106.5108,
    postal_code: '81620'
  },
  '81621': {
    country: 'US',
    city: 'Basalt',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.3535,
    longitude: -106.9988,
    postal_code: '81621'
  },
  '81623': {
    country: 'US',
    city: 'Carbondale',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Garfield',
    latitude: 39.2511,
    longitude: -107.2044,
    postal_code: '81623'
  },
  '81624': {
    country: 'US',
    city: 'Collbran',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.2453,
    longitude: -107.9249,
    postal_code: '81624'
  },
  '81625': {
    country: 'US',
    city: 'Craig',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Moffat',
    latitude: 40.5224,
    longitude: -107.5615,
    postal_code: '81625'
  },
  '81626': {
    country: 'US',
    city: 'Craig',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Moffat',
    latitude: 40.5152,
    longitude: -107.5465,
    postal_code: '81626'
  },
  '81630': {
    country: 'US',
    city: 'De Beque',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.3118,
    longitude: -108.2304,
    postal_code: '81630'
  },
  '81631': {
    country: 'US',
    city: 'Eagle',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.6341,
    longitude: -106.7588,
    postal_code: '81631'
  },
  '81632': {
    country: 'US',
    city: 'Edwards',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.6382,
    longitude: -106.6206,
    postal_code: '81632'
  },
  '81633': {
    country: 'US',
    city: 'Dinosaur',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Moffat',
    latitude: 40.377,
    longitude: -108.399,
    postal_code: '81633'
  },
  '81635': {
    country: 'US',
    city: 'Parachute',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Garfield',
    latitude: 39.4519,
    longitude: -108.0529,
    postal_code: '81635'
  },
  '81636': {
    country: 'US',
    city: 'Battlement Mesa',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Garfield',
    latitude: 39.4305,
    longitude: -108.0239,
    postal_code: '81636'
  },
  '81637': {
    country: 'US',
    city: 'Gypsum',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.6618,
    longitude: -106.9671,
    postal_code: '81637'
  },
  '81638': {
    country: 'US',
    city: 'Hamilton',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Moffat',
    latitude: 40.325,
    longitude: -107.5841,
    postal_code: '81638'
  },
  '81639': {
    country: 'US',
    city: 'Hayden',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Routt',
    latitude: 40.4945,
    longitude: -107.2571,
    postal_code: '81639'
  },
  '81640': {
    country: 'US',
    city: 'Maybell',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Moffat',
    latitude: 40.6738,
    longitude: -108.3699,
    postal_code: '81640'
  },
  '81641': {
    country: 'US',
    city: 'Meeker',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Rio Blanco',
    latitude: 40.0387,
    longitude: -107.8925,
    postal_code: '81641'
  },
  '81642': {
    country: 'US',
    city: 'Meredith',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pitkin',
    latitude: 39.3199,
    longitude: -106.6596,
    postal_code: '81642'
  },
  '81643': {
    country: 'US',
    city: 'Mesa',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.1612,
    longitude: -108.1044,
    postal_code: '81643'
  },
  '81645': {
    country: 'US',
    city: 'Minturn',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.5805,
    longitude: -106.4176,
    postal_code: '81645'
  },
  '81646': {
    country: 'US',
    city: 'Molina',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Mesa',
    latitude: 39.1891,
    longitude: -108.0604,
    postal_code: '81646'
  },
  '81647': {
    country: 'US',
    city: 'New Castle',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Garfield',
    latitude: 39.5709,
    longitude: -107.5428,
    postal_code: '81647'
  },
  '81648': {
    country: 'US',
    city: 'Rangely',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Rio Blanco',
    latitude: 40.0828,
    longitude: -108.7991,
    postal_code: '81648'
  },
  '81649': {
    country: 'US',
    city: 'Red Cliff',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.5122,
    longitude: -106.3681,
    postal_code: '81649'
  },
  '81650': {
    country: 'US',
    city: 'Rifle',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Garfield',
    latitude: 39.5491,
    longitude: -107.7898,
    postal_code: '81650'
  },
  '81652': {
    country: 'US',
    city: 'Silt',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Garfield',
    latitude: 39.5028,
    longitude: -107.6657,
    postal_code: '81652'
  },
  '81653': {
    country: 'US',
    city: 'Slater',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Moffat',
    latitude: 40.9979,
    longitude: -107.3388,
    postal_code: '81653'
  },
  '81654': {
    country: 'US',
    city: 'Snowmass',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pitkin',
    latitude: 39.2258,
    longitude: -107.0303,
    postal_code: '81654'
  },
  '81655': {
    country: 'US',
    city: 'Wolcott',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.7028,
    longitude: -106.6786,
    postal_code: '81655'
  },
  '81656': {
    country: 'US',
    city: 'Woody Creek',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Pitkin',
    latitude: 39.2831,
    longitude: -106.8985,
    postal_code: '81656'
  },
  '81657': {
    country: 'US',
    city: 'Vail',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.6512,
    longitude: -106.3234,
    postal_code: '81657'
  },
  '81658': {
    country: 'US',
    city: 'Vail',
    state: 'Colorado',
    state_short: 'CO',
    county: 'Eagle',
    latitude: 39.6242,
    longitude: -106.4973,
    postal_code: '81658'
  },
  '82001': {
    country: 'US',
    city: 'Cheyenne',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.1437,
    longitude: -104.7962,
    postal_code: '82001'
  },
  '82002': {
    country: 'US',
    city: 'Cheyenne',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.14,
    longitude: -104.8202,
    postal_code: '82002'
  },
  '82003': {
    country: 'US',
    city: 'Cheyenne',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.2191,
    longitude: -104.6612,
    postal_code: '82003'
  },
  '82005': {
    country: 'US',
    city: 'Fe Warren Afb',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.1391,
    longitude: -104.8629,
    postal_code: '82005'
  },
  '82006': {
    country: 'US',
    city: 'Cheyenne',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.14,
    longitude: -104.8202,
    postal_code: '82006'
  },
  '82007': {
    country: 'US',
    city: 'Cheyenne',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.1084,
    longitude: -104.8107,
    postal_code: '82007'
  },
  '82008': {
    country: 'US',
    city: 'Cheyenne',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.14,
    longitude: -104.8202,
    postal_code: '82008'
  },
  '82009': {
    country: 'US',
    city: 'Cheyenne',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.1836,
    longitude: -104.8023,
    postal_code: '82009'
  },
  '82010': {
    country: 'US',
    city: 'Cheyenne',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.14,
    longitude: -104.8202,
    postal_code: '82010'
  },
  '82050': {
    country: 'US',
    city: 'Albin',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.4342,
    longitude: -104.1505,
    postal_code: '82050'
  },
  '82051': {
    country: 'US',
    city: 'Bosler',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 41.5735,
    longitude: -105.6112,
    postal_code: '82051'
  },
  '82052': {
    country: 'US',
    city: 'Buford',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 41.1219,
    longitude: -105.3047,
    postal_code: '82052'
  },
  '82053': {
    country: 'US',
    city: 'Burns',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.2003,
    longitude: -104.3155,
    postal_code: '82053'
  },
  '82054': {
    country: 'US',
    city: 'Carpenter',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.0428,
    longitude: -104.2765,
    postal_code: '82054'
  },
  '82055': {
    country: 'US',
    city: 'Centennial',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 41.3391,
    longitude: -105.9945,
    postal_code: '82055'
  },
  '82058': {
    country: 'US',
    city: 'Garrett',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 42.2035,
    longitude: -105.678,
    postal_code: '82058'
  },
  '82059': {
    country: 'US',
    city: 'Granite Canon',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.0473,
    longitude: -105.1517,
    postal_code: '82059'
  },
  '82060': {
    country: 'US',
    city: 'Hillsdale',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.2136,
    longitude: -104.4933,
    postal_code: '82060'
  },
  '82061': {
    country: 'US',
    city: 'Horse Creek',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.4353,
    longitude: -105.1417,
    postal_code: '82061'
  },
  '82063': {
    country: 'US',
    city: 'Jelm',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 41.0578,
    longitude: -106.0133,
    postal_code: '82063'
  },
  '82070': {
    country: 'US',
    city: 'Laramie',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 41.439,
    longitude: -105.801,
    postal_code: '82070'
  },
  '82071': {
    country: 'US',
    city: 'Laramie',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 41.3114,
    longitude: -105.5911,
    postal_code: '82071'
  },
  '82072': {
    country: 'US',
    city: 'Laramie',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 41.4247,
    longitude: -105.4781,
    postal_code: '82072'
  },
  '82073': {
    country: 'US',
    city: 'Laramie',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 41.3071,
    longitude: -105.6247,
    postal_code: '82073'
  },
  '82081': {
    country: 'US',
    city: 'Meriden',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.5424,
    longitude: -104.2866,
    postal_code: '82081'
  },
  '82082': {
    country: 'US',
    city: 'Pine Bluffs',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Laramie',
    latitude: 41.1788,
    longitude: -104.0666,
    postal_code: '82082'
  },
  '82083': {
    country: 'US',
    city: 'Rock River',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 41.7461,
    longitude: -105.9746,
    postal_code: '82083'
  },
  '82084': {
    country: 'US',
    city: 'Tie Siding',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Albany',
    latitude: 41.0528,
    longitude: -105.4462,
    postal_code: '82084'
  },
  '82190': {
    country: 'US',
    city: 'Yellowstone National Park',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Park',
    latitude: 44.7957,
    longitude: -110.6137,
    postal_code: '82190'
  },
  '82201': {
    country: 'US',
    city: 'Wheatland',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Platte',
    latitude: 42.0495,
    longitude: -104.9679,
    postal_code: '82201'
  },
  '82210': {
    country: 'US',
    city: 'Chugwater',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Platte',
    latitude: 41.7487,
    longitude: -104.8179,
    postal_code: '82210'
  },
  '82212': {
    country: 'US',
    city: 'Fort Laramie',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Goshen',
    latitude: 42.2133,
    longitude: -104.5226,
    postal_code: '82212'
  },
  '82213': {
    country: 'US',
    city: 'Glendo',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Platte',
    latitude: 42.5004,
    longitude: -105,
    postal_code: '82213'
  },
  '82214': {
    country: 'US',
    city: 'Guernsey',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Platte',
    latitude: 42.2655,
    longitude: -104.7512,
    postal_code: '82214'
  },
  '82215': {
    country: 'US',
    city: 'Hartville',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Platte',
    latitude: 42.3334,
    longitude: -104.7296,
    postal_code: '82215'
  },
  '82217': {
    country: 'US',
    city: 'Hawk Springs',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Goshen',
    latitude: 41.7861,
    longitude: -104.2647,
    postal_code: '82217'
  },
  '82218': {
    country: 'US',
    city: 'Huntley',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Goshen',
    latitude: 41.9327,
    longitude: -104.1461,
    postal_code: '82218'
  },
  '82219': {
    country: 'US',
    city: 'Jay Em',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Goshen',
    latitude: 42.4987,
    longitude: -104.5134,
    postal_code: '82219'
  },
  '82221': {
    country: 'US',
    city: 'Lagrange',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Goshen',
    latitude: 41.6423,
    longitude: -104.1974,
    postal_code: '82221'
  },
  '82222': {
    country: 'US',
    city: 'Lance Creek',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Niobrara',
    latitude: 43.0325,
    longitude: -104.6419,
    postal_code: '82222'
  },
  '82223': {
    country: 'US',
    city: 'Lingle',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Goshen',
    latitude: 42.1346,
    longitude: -104.332,
    postal_code: '82223'
  },
  '82224': {
    country: 'US',
    city: 'Lost Springs',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Converse',
    latitude: 43.0421,
    longitude: -104.7973,
    postal_code: '82224'
  },
  '82225': {
    country: 'US',
    city: 'Lusk',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Niobrara',
    latitude: 42.766,
    longitude: -104.4651,
    postal_code: '82225'
  },
  '82227': {
    country: 'US',
    city: 'Manville',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Niobrara',
    latitude: 42.73,
    longitude: -104.7024,
    postal_code: '82227'
  },
  '82229': {
    country: 'US',
    city: 'Shawnee',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Converse',
    latitude: 42.7477,
    longitude: -105.0097,
    postal_code: '82229'
  },
  '82240': {
    country: 'US',
    city: 'Torrington',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Goshen',
    latitude: 42.0624,
    longitude: -104.1917,
    postal_code: '82240'
  },
  '82242': {
    country: 'US',
    city: 'Van Tassell',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Niobrara',
    latitude: 42.7033,
    longitude: -104.1408,
    postal_code: '82242'
  },
  '82243': {
    country: 'US',
    city: 'Veteran',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Goshen',
    latitude: 41.9821,
    longitude: -104.3709,
    postal_code: '82243'
  },
  '82244': {
    country: 'US',
    city: 'Yoder',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Goshen',
    latitude: 41.912,
    longitude: -104.3535,
    postal_code: '82244'
  },
  '82301': {
    country: 'US',
    city: 'Rawlins',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.7951,
    longitude: -107.2349,
    postal_code: '82301'
  },
  '82310': {
    country: 'US',
    city: 'Jeffrey City',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 42.5402,
    longitude: -107.8724,
    postal_code: '82310'
  },
  '82321': {
    country: 'US',
    city: 'Baggs',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.0312,
    longitude: -107.6687,
    postal_code: '82321'
  },
  '82322': {
    country: 'US',
    city: 'Bairoil',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 42.2327,
    longitude: -107.5633,
    postal_code: '82322'
  },
  '82323': {
    country: 'US',
    city: 'Dixon',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.0445,
    longitude: -107.499,
    postal_code: '82323'
  },
  '82324': {
    country: 'US',
    city: 'Elk Mountain',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.6874,
    longitude: -106.4146,
    postal_code: '82324'
  },
  '82325': {
    country: 'US',
    city: 'Encampment',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.2054,
    longitude: -106.7803,
    postal_code: '82325'
  },
  '82327': {
    country: 'US',
    city: 'Hanna',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.8726,
    longitude: -106.5283,
    postal_code: '82327'
  },
  '82329': {
    country: 'US',
    city: 'Medicine Bow',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.903,
    longitude: -106.2012,
    postal_code: '82329'
  },
  '82331': {
    country: 'US',
    city: 'Saratoga',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.498,
    longitude: -106.754,
    postal_code: '82331'
  },
  '82332': {
    country: 'US',
    city: 'Savery',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.0395,
    longitude: -107.4234,
    postal_code: '82332'
  },
  '82334': {
    country: 'US',
    city: 'Sinclair',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.7802,
    longitude: -107.1172,
    postal_code: '82334'
  },
  '82335': {
    country: 'US',
    city: 'Walcott',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.7611,
    longitude: -106.845,
    postal_code: '82335'
  },
  '82336': {
    country: 'US',
    city: 'Wamsutter',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 41.6583,
    longitude: -108.1517,
    postal_code: '82336'
  },
  '82401': {
    country: 'US',
    city: 'Worland',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Washakie',
    latitude: 44.0138,
    longitude: -107.9563,
    postal_code: '82401'
  },
  '82410': {
    country: 'US',
    city: 'Basin',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.3788,
    longitude: -108.0438,
    postal_code: '82410'
  },
  '82411': {
    country: 'US',
    city: 'Burlington',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.4442,
    longitude: -108.4327,
    postal_code: '82411'
  },
  '82412': {
    country: 'US',
    city: 'Byron',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.7844,
    longitude: -108.544,
    postal_code: '82412'
  },
  '82414': {
    country: 'US',
    city: 'Cody',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Park',
    latitude: 44.5231,
    longitude: -109.0756,
    postal_code: '82414'
  },
  '82420': {
    country: 'US',
    city: 'Cowley',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.8841,
    longitude: -108.4638,
    postal_code: '82420'
  },
  '82421': {
    country: 'US',
    city: 'Deaver',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.9257,
    longitude: -108.5979,
    postal_code: '82421'
  },
  '82422': {
    country: 'US',
    city: 'Emblem',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.5058,
    longitude: -108.3918,
    postal_code: '82422'
  },
  '82423': {
    country: 'US',
    city: 'Frannie',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Park',
    latitude: 44.9718,
    longitude: -108.6221,
    postal_code: '82423'
  },
  '82426': {
    country: 'US',
    city: 'Greybull',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.4919,
    longitude: -108.0795,
    postal_code: '82426'
  },
  '82428': {
    country: 'US',
    city: 'Hyattville',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.2507,
    longitude: -107.6053,
    postal_code: '82428'
  },
  '82430': {
    country: 'US',
    city: 'Kirby',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Hot Springs',
    latitude: 43.8042,
    longitude: -108.1805,
    postal_code: '82430'
  },
  '82431': {
    country: 'US',
    city: 'Lovell',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.8336,
    longitude: -108.4141,
    postal_code: '82431'
  },
  '82432': {
    country: 'US',
    city: 'Manderson',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.2694,
    longitude: -107.964,
    postal_code: '82432'
  },
  '82433': {
    country: 'US',
    city: 'Meeteetse',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Park',
    latitude: 44.1962,
    longitude: -108.95,
    postal_code: '82433'
  },
  '82434': {
    country: 'US',
    city: 'Otto',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.4056,
    longitude: -108.3047,
    postal_code: '82434'
  },
  '82435': {
    country: 'US',
    city: 'Powell',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Park',
    latitude: 44.7561,
    longitude: -108.7773,
    postal_code: '82435'
  },
  '82440': {
    country: 'US',
    city: 'Ralston',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Park',
    latitude: 44.7161,
    longitude: -108.8646,
    postal_code: '82440'
  },
  '82441': {
    country: 'US',
    city: 'Shell',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Big Horn',
    latitude: 44.6011,
    longitude: -107.7889,
    postal_code: '82441'
  },
  '82442': {
    country: 'US',
    city: 'Ten Sleep',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Washakie',
    latitude: 43.9978,
    longitude: -107.4153,
    postal_code: '82442'
  },
  '82443': {
    country: 'US',
    city: 'Thermopolis',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Hot Springs',
    latitude: 43.7758,
    longitude: -108.3839,
    postal_code: '82443'
  },
  '82450': {
    country: 'US',
    city: 'Wapiti',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Park',
    latitude: 44.4686,
    longitude: -109.4377,
    postal_code: '82450'
  },
  '82501': {
    country: 'US',
    city: 'Riverton',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 43.0351,
    longitude: -108.2024,
    postal_code: '82501'
  },
  '82510': {
    country: 'US',
    city: 'Arapahoe',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 42.9679,
    longitude: -108.4941,
    postal_code: '82510'
  },
  '82512': {
    country: 'US',
    city: 'Crowheart',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 43.3716,
    longitude: -109.296,
    postal_code: '82512'
  },
  '82513': {
    country: 'US',
    city: 'Dubois',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 43.5451,
    longitude: -109.6492,
    postal_code: '82513'
  },
  '82514': {
    country: 'US',
    city: 'Fort Washakie',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 43.0048,
    longitude: -108.8964,
    postal_code: '82514'
  },
  '82515': {
    country: 'US',
    city: 'Hudson',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 42.9061,
    longitude: -108.5834,
    postal_code: '82515'
  },
  '82516': {
    country: 'US',
    city: 'Kinnear',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 43.2678,
    longitude: -108.9334,
    postal_code: '82516'
  },
  '82520': {
    country: 'US',
    city: 'Lander',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 42.9208,
    longitude: -108.5913,
    postal_code: '82520'
  },
  '82523': {
    country: 'US',
    city: 'Pavillion',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 43.3623,
    longitude: -108.6998,
    postal_code: '82523'
  },
  '82524': {
    country: 'US',
    city: 'Saint Stephens',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 42.9838,
    longitude: -108.4165,
    postal_code: '82524'
  },
  '82601': {
    country: 'US',
    city: 'Casper',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 42.8458,
    longitude: -106.3166,
    postal_code: '82601'
  },
  '82602': {
    country: 'US',
    city: 'Casper',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 42.8896,
    longitude: -106.357,
    postal_code: '82602'
  },
  '82604': {
    country: 'US',
    city: 'Casper',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 42.8261,
    longitude: -106.3896,
    postal_code: '82604'
  },
  '82605': {
    country: 'US',
    city: 'Casper',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 42.8666,
    longitude: -106.3131,
    postal_code: '82605'
  },
  '82609': {
    country: 'US',
    city: 'Casper',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 42.8406,
    longitude: -106.2806,
    postal_code: '82609'
  },
  '82615': {
    country: 'US',
    city: 'Shirley Basin',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Carbon',
    latitude: 41.7169,
    longitude: -106.9992,
    postal_code: '82615'
  },
  '82620': {
    country: 'US',
    city: 'Alcova',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 42.5302,
    longitude: -106.76,
    postal_code: '82620'
  },
  '82630': {
    country: 'US',
    city: 'Arminto',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 43.1789,
    longitude: -107.2576,
    postal_code: '82630'
  },
  '82633': {
    country: 'US',
    city: 'Douglas',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Converse',
    latitude: 42.7626,
    longitude: -105.3855,
    postal_code: '82633'
  },
  '82635': {
    country: 'US',
    city: 'Edgerton',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 43.4074,
    longitude: -106.2638,
    postal_code: '82635'
  },
  '82636': {
    country: 'US',
    city: 'Evansville',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 42.8992,
    longitude: -106.1754,
    postal_code: '82636'
  },
  '82637': {
    country: 'US',
    city: 'Glenrock',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Converse',
    latitude: 42.7803,
    longitude: -105.8719,
    postal_code: '82637'
  },
  '82638': {
    country: 'US',
    city: 'Hiland',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 43.1153,
    longitude: -107.3436,
    postal_code: '82638'
  },
  '82639': {
    country: 'US',
    city: 'Kaycee',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Johnson',
    latitude: 43.7236,
    longitude: -106.5632,
    postal_code: '82639'
  },
  '82640': {
    country: 'US',
    city: 'Linch',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Johnson',
    latitude: 43.5651,
    longitude: -106.1728,
    postal_code: '82640'
  },
  '82642': {
    country: 'US',
    city: 'Lysite',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 43.3284,
    longitude: -107.6488,
    postal_code: '82642'
  },
  '82643': {
    country: 'US',
    city: 'Midwest',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 43.4114,
    longitude: -106.28,
    postal_code: '82643'
  },
  '82644': {
    country: 'US',
    city: 'Mills',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 42.8405,
    longitude: -106.3659,
    postal_code: '82644'
  },
  '82646': {
    country: 'US',
    city: 'Natrona',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 43.0297,
    longitude: -106.8092,
    postal_code: '82646'
  },
  '82648': {
    country: 'US',
    city: 'Powder River',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Natrona',
    latitude: 43.0322,
    longitude: -106.9873,
    postal_code: '82648'
  },
  '82649': {
    country: 'US',
    city: 'Shoshoni',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Fremont',
    latitude: 43.2457,
    longitude: -108.1007,
    postal_code: '82649'
  },
  '82701': {
    country: 'US',
    city: 'Newcastle',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Weston',
    latitude: 43.8511,
    longitude: -104.2262,
    postal_code: '82701'
  },
  '82710': {
    country: 'US',
    city: 'Aladdin',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Crook',
    latitude: 44.7473,
    longitude: -104.1931,
    postal_code: '82710'
  },
  '82711': {
    country: 'US',
    city: 'Alva',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Crook',
    latitude: 44.6873,
    longitude: -104.4414,
    postal_code: '82711'
  },
  '82712': {
    country: 'US',
    city: 'Beulah',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Crook',
    latitude: 44.5436,
    longitude: -104.0745,
    postal_code: '82712'
  },
  '82714': {
    country: 'US',
    city: 'Devils Tower',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Crook',
    latitude: 44.5248,
    longitude: -104.6867,
    postal_code: '82714'
  },
  '82715': {
    country: 'US',
    city: 'Four Corners',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Weston',
    latitude: 44.0775,
    longitude: -104.1383,
    postal_code: '82715'
  },
  '82716': {
    country: 'US',
    city: 'Gillette',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Campbell',
    latitude: 44.282,
    longitude: -105.4974,
    postal_code: '82716'
  },
  '82717': {
    country: 'US',
    city: 'Gillette',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Campbell',
    latitude: 44.3047,
    longitude: -105.4959,
    postal_code: '82717'
  },
  '82718': {
    country: 'US',
    city: 'Gillette',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Campbell',
    latitude: 43.9282,
    longitude: -105.5492,
    postal_code: '82718'
  },
  '82720': {
    country: 'US',
    city: 'Hulett',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Crook',
    latitude: 44.7352,
    longitude: -104.6174,
    postal_code: '82720'
  },
  '82721': {
    country: 'US',
    city: 'Moorcroft',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Crook',
    latitude: 44.4154,
    longitude: -104.8389,
    postal_code: '82721'
  },
  '82723': {
    country: 'US',
    city: 'Osage',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Weston',
    latitude: 43.999,
    longitude: -104.4226,
    postal_code: '82723'
  },
  '82725': {
    country: 'US',
    city: 'Recluse',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Campbell',
    latitude: 44.7861,
    longitude: -105.776,
    postal_code: '82725'
  },
  '82727': {
    country: 'US',
    city: 'Rozet',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Campbell',
    latitude: 44.3058,
    longitude: -105.2459,
    postal_code: '82727'
  },
  '82729': {
    country: 'US',
    city: 'Sundance',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Crook',
    latitude: 44.4058,
    longitude: -104.3837,
    postal_code: '82729'
  },
  '82730': {
    country: 'US',
    city: 'Upton',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Weston',
    latitude: 44.0893,
    longitude: -104.6352,
    postal_code: '82730'
  },
  '82731': {
    country: 'US',
    city: 'Weston',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Campbell',
    latitude: 44.7705,
    longitude: -105.3581,
    postal_code: '82731'
  },
  '82732': {
    country: 'US',
    city: 'Wright',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Campbell',
    latitude: 43.7512,
    longitude: -105.492,
    postal_code: '82732'
  },
  '82801': {
    country: 'US',
    city: 'Sheridan',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.7849,
    longitude: -106.9648,
    postal_code: '82801'
  },
  '82831': {
    country: 'US',
    city: 'Arvada',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.6899,
    longitude: -106.1092,
    postal_code: '82831'
  },
  '82832': {
    country: 'US',
    city: 'Banner',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.6003,
    longitude: -106.7954,
    postal_code: '82832'
  },
  '82833': {
    country: 'US',
    city: 'Big Horn',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.6531,
    longitude: -107.0247,
    postal_code: '82833'
  },
  '82834': {
    country: 'US',
    city: 'Buffalo',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Johnson',
    latitude: 44.3485,
    longitude: -106.7073,
    postal_code: '82834'
  },
  '82835': {
    country: 'US',
    city: 'Clearmont',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.661,
    longitude: -106.4581,
    postal_code: '82835'
  },
  '82836': {
    country: 'US',
    city: 'Dayton',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.878,
    longitude: -107.3026,
    postal_code: '82836'
  },
  '82837': {
    country: 'US',
    city: 'Leiter',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.718,
    longitude: -106.2692,
    postal_code: '82837'
  },
  '82838': {
    country: 'US',
    city: 'Parkman',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.965,
    longitude: -107.3254,
    postal_code: '82838'
  },
  '82839': {
    country: 'US',
    city: 'Ranchester',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.9171,
    longitude: -107.174,
    postal_code: '82839'
  },
  '82840': {
    country: 'US',
    city: 'Saddlestring',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Johnson',
    latitude: 44.4976,
    longitude: -106.871,
    postal_code: '82840'
  },
  '82842': {
    country: 'US',
    city: 'Story',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.58,
    longitude: -106.8978,
    postal_code: '82842'
  },
  '82844': {
    country: 'US',
    city: 'Wolf',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.7864,
    longitude: -107.2202,
    postal_code: '82844'
  },
  '82845': {
    country: 'US',
    city: 'Wyarno',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sheridan',
    latitude: 44.7534,
    longitude: -106.6949,
    postal_code: '82845'
  },
  '82901': {
    country: 'US',
    city: 'Rock Springs',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 41.606,
    longitude: -109.23,
    postal_code: '82901'
  },
  '82902': {
    country: 'US',
    city: 'Rock Springs',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 41.5875,
    longitude: -109.2029,
    postal_code: '82902'
  },
  '82922': {
    country: 'US',
    city: 'Bondurant',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sublette',
    latitude: 43.2238,
    longitude: -110.3353,
    postal_code: '82922'
  },
  '82923': {
    country: 'US',
    city: 'Boulder',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sublette',
    latitude: 42.6881,
    longitude: -109.5401,
    postal_code: '82923'
  },
  '82925': {
    country: 'US',
    city: 'Cora',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sublette',
    latitude: 43.1399,
    longitude: -109.9154,
    postal_code: '82925'
  },
  '82929': {
    country: 'US',
    city: 'Little America',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 41.5436,
    longitude: -109.859,
    postal_code: '82929'
  },
  '82930': {
    country: 'US',
    city: 'Evanston',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Uinta',
    latitude: 41.2609,
    longitude: -110.9631,
    postal_code: '82930'
  },
  '82931': {
    country: 'US',
    city: 'Evanston',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Uinta',
    latitude: 41.2619,
    longitude: -110.92,
    postal_code: '82931'
  },
  '82932': {
    country: 'US',
    city: 'Farson',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 42.0834,
    longitude: -109.4184,
    postal_code: '82932'
  },
  '82933': {
    country: 'US',
    city: 'Fort Bridger',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Uinta',
    latitude: 41.3166,
    longitude: -110.3843,
    postal_code: '82933'
  },
  '82934': {
    country: 'US',
    city: 'Granger',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 41.5936,
    longitude: -109.9688,
    postal_code: '82934'
  },
  '82935': {
    country: 'US',
    city: 'Green River',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 41.5196,
    longitude: -109.4714,
    postal_code: '82935'
  },
  '82936': {
    country: 'US',
    city: 'Lonetree',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Uinta',
    latitude: 41.0552,
    longitude: -110.1603,
    postal_code: '82936'
  },
  '82937': {
    country: 'US',
    city: 'Lyman',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Uinta',
    latitude: 41.3291,
    longitude: -110.2926,
    postal_code: '82937'
  },
  '82938': {
    country: 'US',
    city: 'Mc Kinnon',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 41.0409,
    longitude: -109.8745,
    postal_code: '82938'
  },
  '82939': {
    country: 'US',
    city: 'Mountain View',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Uinta',
    latitude: 41.2335,
    longitude: -110.3372,
    postal_code: '82939'
  },
  '82941': {
    country: 'US',
    city: 'Pinedale',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sublette',
    latitude: 42.8543,
    longitude: -109.8561,
    postal_code: '82941'
  },
  '82942': {
    country: 'US',
    city: 'Point Of Rocks',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 41.6802,
    longitude: -108.7857,
    postal_code: '82942'
  },
  '82943': {
    country: 'US',
    city: 'Reliance',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 41.6698,
    longitude: -109.1919,
    postal_code: '82943'
  },
  '82944': {
    country: 'US',
    city: 'Robertson',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Uinta',
    latitude: 41.0993,
    longitude: -110.5007,
    postal_code: '82944'
  },
  '82945': {
    country: 'US',
    city: 'Superior',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sweetwater',
    latitude: 41.7643,
    longitude: -108.9681,
    postal_code: '82945'
  },
  '83001': {
    country: 'US',
    city: 'Jackson',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Teton',
    latitude: 43.4528,
    longitude: -110.7393,
    postal_code: '83001'
  },
  '83002': {
    country: 'US',
    city: 'Jackson',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Teton',
    latitude: 43.4799,
    longitude: -110.7624,
    postal_code: '83002'
  },
  '83011': {
    country: 'US',
    city: 'Kelly',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Teton',
    latitude: 43.6094,
    longitude: -110.5442,
    postal_code: '83011'
  },
  '83012': {
    country: 'US',
    city: 'Moose',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Teton',
    latitude: 43.715,
    longitude: -110.742,
    postal_code: '83012'
  },
  '83013': {
    country: 'US',
    city: 'Moran',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Teton',
    latitude: 43.9509,
    longitude: -110.5532,
    postal_code: '83013'
  },
  '83014': {
    country: 'US',
    city: 'Wilson',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Teton',
    latitude: 43.4992,
    longitude: -110.8742,
    postal_code: '83014'
  },
  '83025': {
    country: 'US',
    city: 'Teton Village',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Teton',
    latitude: 43.5924,
    longitude: -110.8314,
    postal_code: '83025'
  },
  '83101': {
    country: 'US',
    city: 'Kemmerer',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 41.7887,
    longitude: -110.5283,
    postal_code: '83101'
  },
  '83110': {
    country: 'US',
    city: 'Afton',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 42.7128,
    longitude: -110.942,
    postal_code: '83110'
  },
  '83111': {
    country: 'US',
    city: 'Auburn',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 42.8051,
    longitude: -110.9944,
    postal_code: '83111'
  },
  '83112': {
    country: 'US',
    city: 'Bedford',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 42.8701,
    longitude: -110.9401,
    postal_code: '83112'
  },
  '83113': {
    country: 'US',
    city: 'Big Piney',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sublette',
    latitude: 42.6483,
    longitude: -110.1246,
    postal_code: '83113'
  },
  '83114': {
    country: 'US',
    city: 'Cokeville',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 42.058,
    longitude: -110.9164,
    postal_code: '83114'
  },
  '83115': {
    country: 'US',
    city: 'Daniel',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Sublette',
    latitude: 42.9176,
    longitude: -110.1336,
    postal_code: '83115'
  },
  '83116': {
    country: 'US',
    city: 'Diamondville',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 41.7839,
    longitude: -110.54,
    postal_code: '83116'
  },
  '83118': {
    country: 'US',
    city: 'Etna',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 43.0443,
    longitude: -111.0085,
    postal_code: '83118'
  },
  '83119': {
    country: 'US',
    city: 'Fairview',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 42.6885,
    longitude: -110.9824,
    postal_code: '83119'
  },
  '83120': {
    country: 'US',
    city: 'Freedom',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 43.0172,
    longitude: -111.0292,
    postal_code: '83120'
  },
  '83121': {
    country: 'US',
    city: 'Frontier',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 41.8141,
    longitude: -110.5371,
    postal_code: '83121'
  },
  '83122': {
    country: 'US',
    city: 'Grover',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 42.7965,
    longitude: -110.9244,
    postal_code: '83122'
  },
  '83123': {
    country: 'US',
    city: 'La Barge',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 42.2473,
    longitude: -110.2109,
    postal_code: '83123'
  },
  '83124': {
    country: 'US',
    city: 'Opal',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 41.7795,
    longitude: -110.276,
    postal_code: '83124'
  },
  '83126': {
    country: 'US',
    city: 'Smoot',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 42.6192,
    longitude: -110.9224,
    postal_code: '83126'
  },
  '83127': {
    country: 'US',
    city: 'Thayne',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 42.933,
    longitude: -111.0114,
    postal_code: '83127'
  },
  '83128': {
    country: 'US',
    city: 'Alpine',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Lincoln',
    latitude: 43.1635,
    longitude: -111.018,
    postal_code: '83128'
  },
  '83201': {
    country: 'US',
    city: 'Pocatello',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.8876,
    longitude: -112.4381,
    postal_code: '83201'
  },
  '83202': {
    country: 'US',
    city: 'Pocatello',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.9357,
    longitude: -112.4679,
    postal_code: '83202'
  },
  '83203': {
    country: 'US',
    city: 'Fort Hall',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bingham',
    latitude: 43.0331,
    longitude: -112.4285,
    postal_code: '83203'
  },
  '83204': {
    country: 'US',
    city: 'Pocatello',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.8465,
    longitude: -112.4434,
    postal_code: '83204'
  },
  '83205': {
    country: 'US',
    city: 'Pocatello',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.8062,
    longitude: -112.4103,
    postal_code: '83205'
  },
  '83206': {
    country: 'US',
    city: 'Pocatello',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.8713,
    longitude: -112.4455,
    postal_code: '83206'
  },
  '83209': {
    country: 'US',
    city: 'Pocatello',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.6395,
    longitude: -112.3138,
    postal_code: '83209'
  },
  '83210': {
    country: 'US',
    city: 'Aberdeen',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bingham',
    latitude: 43.0049,
    longitude: -112.84,
    postal_code: '83210'
  },
  '83211': {
    country: 'US',
    city: 'American Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Power',
    latitude: 42.6352,
    longitude: -112.9458,
    postal_code: '83211'
  },
  '83212': {
    country: 'US',
    city: 'Arbon',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Power',
    latitude: 42.5026,
    longitude: -112.5585,
    postal_code: '83212'
  },
  '83213': {
    country: 'US',
    city: 'Arco',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Butte',
    latitude: 43.6355,
    longitude: -113.3176,
    postal_code: '83213'
  },
  '83214': {
    country: 'US',
    city: 'Arimo',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.56,
    longitude: -112.1746,
    postal_code: '83214'
  },
  '83215': {
    country: 'US',
    city: 'Atomic City',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bingham',
    latitude: 43.4449,
    longitude: -112.8128,
    postal_code: '83215'
  },
  '83217': {
    country: 'US',
    city: 'Bancroft',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Caribou',
    latitude: 42.7205,
    longitude: -111.8429,
    postal_code: '83217'
  },
  '83218': {
    country: 'US',
    city: 'Basalt',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bingham',
    latitude: 43.3155,
    longitude: -112.1644,
    postal_code: '83218'
  },
  '83220': {
    country: 'US',
    city: 'Bern',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bear Lake',
    latitude: 42.3191,
    longitude: -111.3926,
    postal_code: '83220'
  },
  '83221': {
    country: 'US',
    city: 'Blackfoot',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bingham',
    latitude: 43.1943,
    longitude: -112.3615,
    postal_code: '83221'
  },
  '83223': {
    country: 'US',
    city: 'Bloomington',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bear Lake',
    latitude: 42.184,
    longitude: -111.4086,
    postal_code: '83223'
  },
  '83226': {
    country: 'US',
    city: 'Challis',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Custer',
    latitude: 44.4969,
    longitude: -114.1946,
    postal_code: '83226'
  },
  '83227': {
    country: 'US',
    city: 'Clayton',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Custer',
    latitude: 44.2733,
    longitude: -114.4102,
    postal_code: '83227'
  },
  '83228': {
    country: 'US',
    city: 'Clifton',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Franklin',
    latitude: 42.216,
    longitude: -111.9957,
    postal_code: '83228'
  },
  '83229': {
    country: 'US',
    city: 'Cobalt',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lemhi',
    latitude: 45.093,
    longitude: -114.2317,
    postal_code: '83229'
  },
  '83230': {
    country: 'US',
    city: 'Conda',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Caribou',
    latitude: 42.7191,
    longitude: -111.5967,
    postal_code: '83230'
  },
  '83232': {
    country: 'US',
    city: 'Dayton',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Franklin',
    latitude: 42.1184,
    longitude: -111.9858,
    postal_code: '83232'
  },
  '83233': {
    country: 'US',
    city: 'Dingle',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bear Lake',
    latitude: 42.1774,
    longitude: -111.2174,
    postal_code: '83233'
  },
  '83234': {
    country: 'US',
    city: 'Downey',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.4181,
    longitude: -112.109,
    postal_code: '83234'
  },
  '83235': {
    country: 'US',
    city: 'Ellis',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Custer',
    latitude: 44.6919,
    longitude: -114.0484,
    postal_code: '83235'
  },
  '83236': {
    country: 'US',
    city: 'Firth',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bingham',
    latitude: 43.3021,
    longitude: -112.1588,
    postal_code: '83236'
  },
  '83237': {
    country: 'US',
    city: 'Franklin',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Franklin',
    latitude: 42.0288,
    longitude: -111.7871,
    postal_code: '83237'
  },
  '83238': {
    country: 'US',
    city: 'Geneva',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bear Lake',
    latitude: 42.3136,
    longitude: -111.0722,
    postal_code: '83238'
  },
  '83239': {
    country: 'US',
    city: 'Georgetown',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bear Lake',
    latitude: 42.4821,
    longitude: -111.3708,
    postal_code: '83239'
  },
  '83241': {
    country: 'US',
    city: 'Grace',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Caribou',
    latitude: 42.55,
    longitude: -111.74,
    postal_code: '83241'
  },
  '83243': {
    country: 'US',
    city: 'Holbrook',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Oneida',
    latitude: 42.1619,
    longitude: -112.6539,
    postal_code: '83243'
  },
  '83244': {
    country: 'US',
    city: 'Howe',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Butte',
    latitude: 43.9087,
    longitude: -113.0878,
    postal_code: '83244'
  },
  '83245': {
    country: 'US',
    city: 'Inkom',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.7964,
    longitude: -112.2465,
    postal_code: '83245'
  },
  '83246': {
    country: 'US',
    city: 'Lava Hot Springs',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.6185,
    longitude: -112.0176,
    postal_code: '83246'
  },
  '83250': {
    country: 'US',
    city: 'McCammon',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.6336,
    longitude: -112.1758,
    postal_code: '83250'
  },
  '83251': {
    country: 'US',
    city: 'Mackay',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Custer',
    latitude: 43.9111,
    longitude: -113.612,
    postal_code: '83251'
  },
  '83252': {
    country: 'US',
    city: 'Malad City',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Oneida',
    latitude: 42.1808,
    longitude: -112.262,
    postal_code: '83252'
  },
  '83253': {
    country: 'US',
    city: 'May',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lemhi',
    latitude: 44.6044,
    longitude: -113.912,
    postal_code: '83253'
  },
  '83254': {
    country: 'US',
    city: 'Montpelier',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bear Lake',
    latitude: 42.352,
    longitude: -111.3195,
    postal_code: '83254'
  },
  '83255': {
    country: 'US',
    city: 'Moore',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Butte',
    latitude: 43.7296,
    longitude: -113.4544,
    postal_code: '83255'
  },
  '83256': {
    country: 'US',
    city: 'Moreland',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bingham',
    latitude: 43.2226,
    longitude: -112.4423,
    postal_code: '83256'
  },
  '83261': {
    country: 'US',
    city: 'Paris',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bear Lake',
    latitude: 42.2242,
    longitude: -111.4208,
    postal_code: '83261'
  },
  '83262': {
    country: 'US',
    city: 'Pingree',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bingham',
    latitude: 43.1336,
    longitude: -112.6295,
    postal_code: '83262'
  },
  '83263': {
    country: 'US',
    city: 'Preston',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Franklin',
    latitude: 42.1109,
    longitude: -111.8565,
    postal_code: '83263'
  },
  '83271': {
    country: 'US',
    city: 'Rockland',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Power',
    latitude: 42.5556,
    longitude: -112.854,
    postal_code: '83271'
  },
  '83272': {
    country: 'US',
    city: 'Saint Charles',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bear Lake',
    latitude: 42.1128,
    longitude: -111.3897,
    postal_code: '83272'
  },
  '83274': {
    country: 'US',
    city: 'Shelley',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bingham',
    latitude: 43.3769,
    longitude: -112.1075,
    postal_code: '83274'
  },
  '83276': {
    country: 'US',
    city: 'Soda Springs',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Caribou',
    latitude: 42.6718,
    longitude: -111.5699,
    postal_code: '83276'
  },
  '83277': {
    country: 'US',
    city: 'Springfield',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bingham',
    latitude: 43.0803,
    longitude: -112.673,
    postal_code: '83277'
  },
  '83278': {
    country: 'US',
    city: 'Stanley',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Custer',
    latitude: 44.3714,
    longitude: -114.8561,
    postal_code: '83278'
  },
  '83281': {
    country: 'US',
    city: 'Swanlake',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bannock',
    latitude: 42.3195,
    longitude: -111.9781,
    postal_code: '83281'
  },
  '83283': {
    country: 'US',
    city: 'Thatcher',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Franklin',
    latitude: 42.3794,
    longitude: -111.6841,
    postal_code: '83283'
  },
  '83285': {
    country: 'US',
    city: 'Wayan',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Caribou',
    latitude: 43.0269,
    longitude: -111.2541,
    postal_code: '83285'
  },
  '83286': {
    country: 'US',
    city: 'Weston',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Franklin',
    latitude: 42.0446,
    longitude: -111.9715,
    postal_code: '83286'
  },
  '83287': {
    country: 'US',
    city: 'Fish Haven',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bear Lake',
    latitude: 42.0459,
    longitude: -111.4633,
    postal_code: '83287'
  },
  '83301': {
    country: 'US',
    city: 'Twin Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Twin Falls',
    latitude: 42.5565,
    longitude: -114.4693,
    postal_code: '83301'
  },
  '83302': {
    country: 'US',
    city: 'Rogerson',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Twin Falls',
    latitude: 42.218,
    longitude: -114.5942,
    postal_code: '83302'
  },
  '83303': {
    country: 'US',
    city: 'Twin Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Twin Falls',
    latitude: 42.563,
    longitude: -114.4609,
    postal_code: '83303'
  },
  '83311': {
    country: 'US',
    city: 'Albion',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Cassia',
    latitude: 42.3896,
    longitude: -113.5567,
    postal_code: '83311'
  },
  '83312': {
    country: 'US',
    city: 'Almo',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Cassia',
    latitude: 42.0846,
    longitude: -113.6283,
    postal_code: '83312'
  },
  '83313': {
    country: 'US',
    city: 'Bellevue',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Blaine',
    latitude: 43.4397,
    longitude: -114.2498,
    postal_code: '83313'
  },
  '83314': {
    country: 'US',
    city: 'Bliss',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Gooding',
    latitude: 42.9449,
    longitude: -114.9104,
    postal_code: '83314'
  },
  '83316': {
    country: 'US',
    city: 'Buhl',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Twin Falls',
    latitude: 42.6008,
    longitude: -114.7825,
    postal_code: '83316'
  },
  '83318': {
    country: 'US',
    city: 'Burley',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Cassia',
    latitude: 42.5244,
    longitude: -113.7931,
    postal_code: '83318'
  },
  '83320': {
    country: 'US',
    city: 'Carey',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Blaine',
    latitude: 43.2744,
    longitude: -113.8926,
    postal_code: '83320'
  },
  '83321': {
    country: 'US',
    city: 'Castleford',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Twin Falls',
    latitude: 42.521,
    longitude: -114.8734,
    postal_code: '83321'
  },
  '83322': {
    country: 'US',
    city: 'Corral',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Camas',
    latitude: 43.5282,
    longitude: -114.7307,
    postal_code: '83322'
  },
  '83323': {
    country: 'US',
    city: 'Declo',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Cassia',
    latitude: 42.5245,
    longitude: -113.4338,
    postal_code: '83323'
  },
  '83324': {
    country: 'US',
    city: 'Dietrich',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lincoln',
    latitude: 42.9125,
    longitude: -114.2664,
    postal_code: '83324'
  },
  '83325': {
    country: 'US',
    city: 'Eden',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jerome',
    latitude: 42.5868,
    longitude: -114.2467,
    postal_code: '83325'
  },
  '83327': {
    country: 'US',
    city: 'Fairfield',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Camas',
    latitude: 43.3675,
    longitude: -114.7908,
    postal_code: '83327'
  },
  '83328': {
    country: 'US',
    city: 'Filer',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Twin Falls',
    latitude: 42.5653,
    longitude: -114.614,
    postal_code: '83328'
  },
  '83330': {
    country: 'US',
    city: 'Gooding',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Gooding',
    latitude: 42.9373,
    longitude: -114.712,
    postal_code: '83330'
  },
  '83332': {
    country: 'US',
    city: 'Hagerman',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Gooding',
    latitude: 42.8142,
    longitude: -114.887,
    postal_code: '83332'
  },
  '83333': {
    country: 'US',
    city: 'Hailey',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Blaine',
    latitude: 43.5239,
    longitude: -114.3064,
    postal_code: '83333'
  },
  '83334': {
    country: 'US',
    city: 'Hansen',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Twin Falls',
    latitude: 42.5249,
    longitude: -114.2994,
    postal_code: '83334'
  },
  '83335': {
    country: 'US',
    city: 'Hazelton',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jerome',
    latitude: 42.5955,
    longitude: -114.135,
    postal_code: '83335'
  },
  '83336': {
    country: 'US',
    city: 'Heyburn',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Minidoka',
    latitude: 42.5599,
    longitude: -113.7709,
    postal_code: '83336'
  },
  '83337': {
    country: 'US',
    city: 'Hill City',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Camas',
    latitude: 43.3005,
    longitude: -115.0512,
    postal_code: '83337'
  },
  '83338': {
    country: 'US',
    city: 'Jerome',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jerome',
    latitude: 42.7178,
    longitude: -114.5012,
    postal_code: '83338'
  },
  '83340': {
    country: 'US',
    city: 'Ketchum',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Blaine',
    latitude: 43.6692,
    longitude: -114.4858,
    postal_code: '83340'
  },
  '83341': {
    country: 'US',
    city: 'Kimberly',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Twin Falls',
    latitude: 42.5287,
    longitude: -114.3657,
    postal_code: '83341'
  },
  '83342': {
    country: 'US',
    city: 'Malta',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Cassia',
    latitude: 42.2741,
    longitude: -113.3927,
    postal_code: '83342'
  },
  '83343': {
    country: 'US',
    city: 'Minidoka',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Minidoka',
    latitude: 42.773,
    longitude: -113.5098,
    postal_code: '83343'
  },
  '83344': {
    country: 'US',
    city: 'Murtaugh',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Twin Falls',
    latitude: 42.4776,
    longitude: -114.1606,
    postal_code: '83344'
  },
  '83346': {
    country: 'US',
    city: 'Oakley',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Cassia',
    latitude: 42.194,
    longitude: -113.872,
    postal_code: '83346'
  },
  '83347': {
    country: 'US',
    city: 'Paul',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Minidoka',
    latitude: 42.624,
    longitude: -113.7971,
    postal_code: '83347'
  },
  '83348': {
    country: 'US',
    city: 'Picabo',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Blaine',
    latitude: 43.3101,
    longitude: -114.0861,
    postal_code: '83348'
  },
  '83349': {
    country: 'US',
    city: 'Richfield',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lincoln',
    latitude: 43.0588,
    longitude: -114.1508,
    postal_code: '83349'
  },
  '83350': {
    country: 'US',
    city: 'Rupert',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Minidoka',
    latitude: 42.6888,
    longitude: -113.6481,
    postal_code: '83350'
  },
  '83352': {
    country: 'US',
    city: 'Shoshone',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lincoln',
    latitude: 42.9474,
    longitude: -114.3822,
    postal_code: '83352'
  },
  '83353': {
    country: 'US',
    city: 'Sun Valley',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Blaine',
    latitude: 43.6853,
    longitude: -114.3313,
    postal_code: '83353'
  },
  '83354': {
    country: 'US',
    city: 'Sun Valley',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Blaine',
    latitude: 43.5946,
    longitude: -114.3226,
    postal_code: '83354'
  },
  '83355': {
    country: 'US',
    city: 'Wendell',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Gooding',
    latitude: 42.7579,
    longitude: -114.7154,
    postal_code: '83355'
  },
  '83401': {
    country: 'US',
    city: 'Idaho Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.5518,
    longitude: -111.8919,
    postal_code: '83401'
  },
  '83402': {
    country: 'US',
    city: 'Idaho Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.4934,
    longitude: -112.0578,
    postal_code: '83402'
  },
  '83403': {
    country: 'US',
    city: 'Idaho Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.4666,
    longitude: -112.0341,
    postal_code: '83403'
  },
  '83404': {
    country: 'US',
    city: 'Idaho Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.475,
    longitude: -112.0124,
    postal_code: '83404'
  },
  '83405': {
    country: 'US',
    city: 'Idaho Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.4666,
    longitude: -112.0341,
    postal_code: '83405'
  },
  '83406': {
    country: 'US',
    city: 'Idaho Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.4732,
    longitude: -111.9661,
    postal_code: '83406'
  },
  '83414': {
    country: 'US',
    city: 'Alta',
    state: 'Wyoming',
    state_short: 'WY',
    county: 'Teton',
    latitude: 43.8886,
    longitude: -110.9492,
    postal_code: '83414'
  },
  '83415': {
    country: 'US',
    city: 'Idaho Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.4666,
    longitude: -112.0341,
    postal_code: '83415'
  },
  '83420': {
    country: 'US',
    city: 'Ashton',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Fremont',
    latitude: 44.0153,
    longitude: -111.4253,
    postal_code: '83420'
  },
  '83421': {
    country: 'US',
    city: 'Chester',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Fremont',
    latitude: 44.0011,
    longitude: -111.5351,
    postal_code: '83421'
  },
  '83422': {
    country: 'US',
    city: 'Driggs',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Teton',
    latitude: 43.7263,
    longitude: -111.1199,
    postal_code: '83422'
  },
  '83423': {
    country: 'US',
    city: 'Dubois',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Clark',
    latitude: 44.1858,
    longitude: -112.3259,
    postal_code: '83423'
  },
  '83424': {
    country: 'US',
    city: 'Felt',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Teton',
    latitude: 43.8724,
    longitude: -111.1895,
    postal_code: '83424'
  },
  '83425': {
    country: 'US',
    city: 'Hamer',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jefferson',
    latitude: 43.9308,
    longitude: -112.1872,
    postal_code: '83425'
  },
  '83427': {
    country: 'US',
    city: 'Iona',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.5263,
    longitude: -111.933,
    postal_code: '83427'
  },
  '83428': {
    country: 'US',
    city: 'Irwin',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.3861,
    longitude: -111.2527,
    postal_code: '83428'
  },
  '83429': {
    country: 'US',
    city: 'Island Park',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Fremont',
    latitude: 44.4466,
    longitude: -111.3679,
    postal_code: '83429'
  },
  '83431': {
    country: 'US',
    city: 'Lewisville',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jefferson',
    latitude: 43.6725,
    longitude: -112.0189,
    postal_code: '83431'
  },
  '83433': {
    country: 'US',
    city: 'Macks Inn',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Fremont',
    latitude: 44.3192,
    longitude: -111.6017,
    postal_code: '83433'
  },
  '83434': {
    country: 'US',
    city: 'Menan',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jefferson',
    latitude: 43.7266,
    longitude: -111.9837,
    postal_code: '83434'
  },
  '83435': {
    country: 'US',
    city: 'Monteview',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jefferson',
    latitude: 43.9862,
    longitude: -112.5783,
    postal_code: '83435'
  },
  '83436': {
    country: 'US',
    city: 'Newdale',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Fremont',
    latitude: 43.8881,
    longitude: -111.6042,
    postal_code: '83436'
  },
  '83438': {
    country: 'US',
    city: 'Parker',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Fremont',
    latitude: 43.9594,
    longitude: -111.7577,
    postal_code: '83438'
  },
  '83440': {
    country: 'US',
    city: 'Rexburg',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Madison',
    latitude: 43.81,
    longitude: -111.789,
    postal_code: '83440'
  },
  '83441': {
    country: 'US',
    city: 'Rexburg',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Madison',
    latitude: 43.7761,
    longitude: -111.691,
    postal_code: '83441'
  },
  '83442': {
    country: 'US',
    city: 'Rigby',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jefferson',
    latitude: 43.6715,
    longitude: -111.9005,
    postal_code: '83442'
  },
  '83443': {
    country: 'US',
    city: 'Ririe',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jefferson',
    latitude: 43.5197,
    longitude: -111.5266,
    postal_code: '83443'
  },
  '83444': {
    country: 'US',
    city: 'Roberts',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jefferson',
    latitude: 43.7116,
    longitude: -112.1196,
    postal_code: '83444'
  },
  '83445': {
    country: 'US',
    city: 'Saint Anthony',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Fremont',
    latitude: 43.963,
    longitude: -111.7036,
    postal_code: '83445'
  },
  '83446': {
    country: 'US',
    city: 'Spencer',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Clark',
    latitude: 44.3605,
    longitude: -112.1869,
    postal_code: '83446'
  },
  '83448': {
    country: 'US',
    city: 'Sugar City',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Madison',
    latitude: 43.8477,
    longitude: -111.6932,
    postal_code: '83448'
  },
  '83449': {
    country: 'US',
    city: 'Swan Valley',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.456,
    longitude: -111.3416,
    postal_code: '83449'
  },
  '83450': {
    country: 'US',
    city: 'Terreton',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Jefferson',
    latitude: 43.8586,
    longitude: -112.42,
    postal_code: '83450'
  },
  '83451': {
    country: 'US',
    city: 'Teton',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Fremont',
    latitude: 43.8907,
    longitude: -111.6504,
    postal_code: '83451'
  },
  '83452': {
    country: 'US',
    city: 'Tetonia',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Teton',
    latitude: 43.7814,
    longitude: -111.2118,
    postal_code: '83452'
  },
  '83454': {
    country: 'US',
    city: 'Ucon',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonneville',
    latitude: 43.5936,
    longitude: -111.9573,
    postal_code: '83454'
  },
  '83455': {
    country: 'US',
    city: 'Victor',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Teton',
    latitude: 43.6148,
    longitude: -111.1259,
    postal_code: '83455'
  },
  '83460': {
    country: 'US',
    city: 'Rexburg',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Madison',
    latitude: 43.826,
    longitude: -111.7897,
    postal_code: '83460'
  },
  '83462': {
    country: 'US',
    city: 'Carmen',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lemhi',
    latitude: 45.255,
    longitude: -113.8573,
    postal_code: '83462'
  },
  '83463': {
    country: 'US',
    city: 'Gibbonsville',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lemhi',
    latitude: 45.5704,
    longitude: -113.9913,
    postal_code: '83463'
  },
  '83464': {
    country: 'US',
    city: 'Leadore',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lemhi',
    latitude: 44.7389,
    longitude: -113.4926,
    postal_code: '83464'
  },
  '83465': {
    country: 'US',
    city: 'Lemhi',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lemhi',
    latitude: 44.8516,
    longitude: -113.6198,
    postal_code: '83465'
  },
  '83466': {
    country: 'US',
    city: 'North Fork',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lemhi',
    latitude: 45.4242,
    longitude: -114.0419,
    postal_code: '83466'
  },
  '83467': {
    country: 'US',
    city: 'Salmon',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lemhi',
    latitude: 45.1571,
    longitude: -113.8784,
    postal_code: '83467'
  },
  '83468': {
    country: 'US',
    city: 'Tendoy',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lemhi',
    latitude: 44.9681,
    longitude: -113.8167,
    postal_code: '83468'
  },
  '83469': {
    country: 'US',
    city: 'Shoup',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lemhi',
    latitude: 45.3769,
    longitude: -114.277,
    postal_code: '83469'
  },
  '83501': {
    country: 'US',
    city: 'Lewiston',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Nez Perce',
    latitude: 46.3646,
    longitude: -116.8609,
    postal_code: '83501'
  },
  '83520': {
    country: 'US',
    city: 'Ahsahka',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Clearwater',
    latitude: 46.5394,
    longitude: -116.3233,
    postal_code: '83520'
  },
  '83522': {
    country: 'US',
    city: 'Cottonwood',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 46.0448,
    longitude: -116.3733,
    postal_code: '83522'
  },
  '83523': {
    country: 'US',
    city: 'Craigmont',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lewis',
    latitude: 46.2453,
    longitude: -116.4677,
    postal_code: '83523'
  },
  '83524': {
    country: 'US',
    city: 'Culdesac',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Nez Perce',
    latitude: 46.378,
    longitude: -116.6536,
    postal_code: '83524'
  },
  '83525': {
    country: 'US',
    city: 'Elk City',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 45.7896,
    longitude: -115.5024,
    postal_code: '83525'
  },
  '83526': {
    country: 'US',
    city: 'Ferdinand',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 46.1349,
    longitude: -116.3982,
    postal_code: '83526'
  },
  '83530': {
    country: 'US',
    city: 'Grangeville',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 45.9272,
    longitude: -116.1076,
    postal_code: '83530'
  },
  '83531': {
    country: 'US',
    city: 'Fenn',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 45.9632,
    longitude: -116.2563,
    postal_code: '83531'
  },
  '83533': {
    country: 'US',
    city: 'Greencreek',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 46.1155,
    longitude: -116.2724,
    postal_code: '83533'
  },
  '83535': {
    country: 'US',
    city: 'Juliaetta',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.5754,
    longitude: -116.7188,
    postal_code: '83535'
  },
  '83536': {
    country: 'US',
    city: 'Kamiah',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lewis',
    latitude: 46.2186,
    longitude: -116.0347,
    postal_code: '83536'
  },
  '83537': {
    country: 'US',
    city: 'Kendrick',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.6286,
    longitude: -116.6049,
    postal_code: '83537'
  },
  '83539': {
    country: 'US',
    city: 'Kooskia',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 46.116,
    longitude: -115.9459,
    postal_code: '83539'
  },
  '83540': {
    country: 'US',
    city: 'Lapwai',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Nez Perce',
    latitude: 46.4124,
    longitude: -116.7902,
    postal_code: '83540'
  },
  '83541': {
    country: 'US',
    city: 'Lenore',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Clearwater',
    latitude: 46.5354,
    longitude: -116.513,
    postal_code: '83541'
  },
  '83542': {
    country: 'US',
    city: 'Lucile',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 45.557,
    longitude: -116.2669,
    postal_code: '83542'
  },
  '83543': {
    country: 'US',
    city: 'Nezperce',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lewis',
    latitude: 46.2475,
    longitude: -116.2393,
    postal_code: '83543'
  },
  '83544': {
    country: 'US',
    city: 'Orofino',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Clearwater',
    latitude: 46.4952,
    longitude: -116.2404,
    postal_code: '83544'
  },
  '83545': {
    country: 'US',
    city: 'Peck',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Nez Perce',
    latitude: 46.4807,
    longitude: -116.4114,
    postal_code: '83545'
  },
  '83546': {
    country: 'US',
    city: 'Pierce',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Clearwater',
    latitude: 46.4924,
    longitude: -115.8071,
    postal_code: '83546'
  },
  '83547': {
    country: 'US',
    city: 'Pollock',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 45.3068,
    longitude: -116.3517,
    postal_code: '83547'
  },
  '83548': {
    country: 'US',
    city: 'Reubens',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lewis',
    latitude: 46.3361,
    longitude: -116.5333,
    postal_code: '83548'
  },
  '83549': {
    country: 'US',
    city: 'Riggins',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 45.397,
    longitude: -116.3006,
    postal_code: '83549'
  },
  '83552': {
    country: 'US',
    city: 'Stites',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 46.002,
    longitude: -115.9269,
    postal_code: '83552'
  },
  '83553': {
    country: 'US',
    city: 'Weippe',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Clearwater',
    latitude: 46.3807,
    longitude: -115.9386,
    postal_code: '83553'
  },
  '83554': {
    country: 'US',
    city: 'White Bird',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 45.7521,
    longitude: -116.2889,
    postal_code: '83554'
  },
  '83555': {
    country: 'US',
    city: 'Winchester',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Lewis',
    latitude: 46.2383,
    longitude: -116.6204,
    postal_code: '83555'
  },
  '83601': {
    country: 'US',
    city: 'Atlanta',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Elmore',
    latitude: 43.8479,
    longitude: -115.2538,
    postal_code: '83601'
  },
  '83602': {
    country: 'US',
    city: 'Banks',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boise',
    latitude: 44.0804,
    longitude: -116.124,
    postal_code: '83602'
  },
  '83604': {
    country: 'US',
    city: 'Bruneau',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Owyhee',
    latitude: 42.614,
    longitude: -115.8419,
    postal_code: '83604'
  },
  '83605': {
    country: 'US',
    city: 'Caldwell',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.6627,
    longitude: -116.7,
    postal_code: '83605'
  },
  '83606': {
    country: 'US',
    city: 'Caldwell',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.7249,
    longitude: -116.7989,
    postal_code: '83606'
  },
  '83607': {
    country: 'US',
    city: 'Caldwell',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.6186,
    longitude: -116.7501,
    postal_code: '83607'
  },
  '83610': {
    country: 'US',
    city: 'Cambridge',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Washington',
    latitude: 44.5922,
    longitude: -116.6757,
    postal_code: '83610'
  },
  '83611': {
    country: 'US',
    city: 'Cascade',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Valley',
    latitude: 44.6927,
    longitude: -115.6417,
    postal_code: '83611'
  },
  '83612': {
    country: 'US',
    city: 'Council',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Adams',
    latitude: 44.7628,
    longitude: -116.4518,
    postal_code: '83612'
  },
  '83615': {
    country: 'US',
    city: 'Donnelly',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Valley',
    latitude: 44.6659,
    longitude: -116.0369,
    postal_code: '83615'
  },
  '83616': {
    country: 'US',
    city: 'Eagle',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.7069,
    longitude: -116.362,
    postal_code: '83616'
  },
  '83617': {
    country: 'US',
    city: 'Emmett',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Gem',
    latitude: 43.9089,
    longitude: -116.4927,
    postal_code: '83617'
  },
  '83619': {
    country: 'US',
    city: 'Fruitland',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Payette',
    latitude: 44.0027,
    longitude: -116.9143,
    postal_code: '83619'
  },
  '83622': {
    country: 'US',
    city: 'Garden Valley',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boise',
    latitude: 44.0909,
    longitude: -115.8243,
    postal_code: '83622'
  },
  '83623': {
    country: 'US',
    city: 'Glenns Ferry',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Elmore',
    latitude: 42.9622,
    longitude: -115.316,
    postal_code: '83623'
  },
  '83624': {
    country: 'US',
    city: 'Grand View',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Owyhee',
    latitude: 42.9896,
    longitude: -116.0934,
    postal_code: '83624'
  },
  '83626': {
    country: 'US',
    city: 'Greenleaf',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.669,
    longitude: -116.8315,
    postal_code: '83626'
  },
  '83627': {
    country: 'US',
    city: 'Hammett',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Elmore',
    latitude: 42.9457,
    longitude: -115.4662,
    postal_code: '83627'
  },
  '83628': {
    country: 'US',
    city: 'Homedale',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Owyhee',
    latitude: 43.6138,
    longitude: -116.9472,
    postal_code: '83628'
  },
  '83629': {
    country: 'US',
    city: 'Horseshoe Bend',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boise',
    latitude: 43.9229,
    longitude: -116.1809,
    postal_code: '83629'
  },
  '83630': {
    country: 'US',
    city: 'Huston',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.6027,
    longitude: -116.7903,
    postal_code: '83630'
  },
  '83631': {
    country: 'US',
    city: 'Idaho City',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boise',
    latitude: 43.8834,
    longitude: -115.708,
    postal_code: '83631'
  },
  '83632': {
    country: 'US',
    city: 'Indian Valley',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Adams',
    latitude: 44.5491,
    longitude: -116.443,
    postal_code: '83632'
  },
  '83633': {
    country: 'US',
    city: 'King Hill',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Elmore',
    latitude: 42.9799,
    longitude: -115.1899,
    postal_code: '83633'
  },
  '83634': {
    country: 'US',
    city: 'Kuna',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.487,
    longitude: -116.3819,
    postal_code: '83634'
  },
  '83635': {
    country: 'US',
    city: 'Lake Fork',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Valley',
    latitude: 44.8327,
    longitude: -116.0848,
    postal_code: '83635'
  },
  '83636': {
    country: 'US',
    city: 'Letha',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Gem',
    latitude: 43.8963,
    longitude: -116.6462,
    postal_code: '83636'
  },
  '83637': {
    country: 'US',
    city: 'Lowman',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boise',
    latitude: 44.1106,
    longitude: -115.5285,
    postal_code: '83637'
  },
  '83638': {
    country: 'US',
    city: 'McCall',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Valley',
    latitude: 44.8918,
    longitude: -116.0789,
    postal_code: '83638'
  },
  '83639': {
    country: 'US',
    city: 'Marsing',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Owyhee',
    latitude: 43.5399,
    longitude: -116.824,
    postal_code: '83639'
  },
  '83641': {
    country: 'US',
    city: 'Melba',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.3784,
    longitude: -116.5489,
    postal_code: '83641'
  },
  '83642': {
    country: 'US',
    city: 'Meridian',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.615,
    longitude: -116.3975,
    postal_code: '83642'
  },
  '83643': {
    country: 'US',
    city: 'Mesa',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Adams',
    latitude: 44.6255,
    longitude: -116.4493,
    postal_code: '83643'
  },
  '83644': {
    country: 'US',
    city: 'Middleton',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.7191,
    longitude: -116.6112,
    postal_code: '83644'
  },
  '83645': {
    country: 'US',
    city: 'Midvale',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Washington',
    latitude: 44.442,
    longitude: -116.7038,
    postal_code: '83645'
  },
  '83646': {
    country: 'US',
    city: 'Meridian',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boise',
    latitude: 43.6498,
    longitude: -116.4306,
    postal_code: '83646'
  },
  '83647': {
    country: 'US',
    city: 'Mountain Home',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Elmore',
    latitude: 43.1392,
    longitude: -115.6963,
    postal_code: '83647'
  },
  '83648': {
    country: 'US',
    city: 'Mountain Home Afb',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Elmore',
    latitude: 43.7914,
    longitude: -116.4012,
    postal_code: '83648'
  },
  '83650': {
    country: 'US',
    city: 'Murphy',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Owyhee',
    latitude: 42.8481,
    longitude: -116.6371,
    postal_code: '83650'
  },
  '83651': {
    country: 'US',
    city: 'Nampa',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.5834,
    longitude: -116.5848,
    postal_code: '83651'
  },
  '83652': {
    country: 'US',
    city: 'Nampa',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.7071,
    longitude: -116.6208,
    postal_code: '83652'
  },
  '83653': {
    country: 'US',
    city: 'Nampa',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.5851,
    longitude: -116.753,
    postal_code: '83653'
  },
  '83654': {
    country: 'US',
    city: 'New Meadows',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Adams',
    latitude: 44.994,
    longitude: -116.2874,
    postal_code: '83654'
  },
  '83655': {
    country: 'US',
    city: 'New Plymouth',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Payette',
    latitude: 43.959,
    longitude: -116.8048,
    postal_code: '83655'
  },
  '83656': {
    country: 'US',
    city: 'Notus',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.7259,
    longitude: -116.7997,
    postal_code: '83656'
  },
  '83657': {
    country: 'US',
    city: 'Ola',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Gem',
    latitude: 44.2418,
    longitude: -116.2732,
    postal_code: '83657'
  },
  '83660': {
    country: 'US',
    city: 'Parma',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.7896,
    longitude: -116.9401,
    postal_code: '83660'
  },
  '83661': {
    country: 'US',
    city: 'Payette',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Payette',
    latitude: 44.0782,
    longitude: -116.9203,
    postal_code: '83661'
  },
  '83666': {
    country: 'US',
    city: 'Placerville',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boise',
    latitude: 43.9633,
    longitude: -115.9759,
    postal_code: '83666'
  },
  '83669': {
    country: 'US',
    city: 'Star',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.7013,
    longitude: -116.4967,
    postal_code: '83669'
  },
  '83670': {
    country: 'US',
    city: 'Sweet',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Gem',
    latitude: 43.9948,
    longitude: -116.3232,
    postal_code: '83670'
  },
  '83671': {
    country: 'US',
    city: 'Warren',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Idaho',
    latitude: 45.2641,
    longitude: -115.6765,
    postal_code: '83671'
  },
  '83672': {
    country: 'US',
    city: 'Weiser',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Washington',
    latitude: 44.2522,
    longitude: -116.9651,
    postal_code: '83672'
  },
  '83676': {
    country: 'US',
    city: 'Wilder',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.6579,
    longitude: -116.9122,
    postal_code: '83676'
  },
  '83677': {
    country: 'US',
    city: 'Yellow Pine',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Valley',
    latitude: 44.9698,
    longitude: -115.4963,
    postal_code: '83677'
  },
  '83680': {
    country: 'US',
    city: 'Meridian',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.6121,
    longitude: -116.3915,
    postal_code: '83680'
  },
  '83686': {
    country: 'US',
    city: 'Nampa',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.5441,
    longitude: -116.566,
    postal_code: '83686'
  },
  '83687': {
    country: 'US',
    city: 'Nampa',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Canyon',
    latitude: 43.5937,
    longitude: -116.536,
    postal_code: '83687'
  },
  '83701': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.6038,
    longitude: -116.2729,
    postal_code: '83701'
  },
  '83702': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.6322,
    longitude: -116.2052,
    postal_code: '83702'
  },
  '83703': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.6601,
    longitude: -116.2524,
    postal_code: '83703'
  },
  '83704': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.633,
    longitude: -116.2951,
    postal_code: '83704'
  },
  '83705': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.5851,
    longitude: -116.2191,
    postal_code: '83705'
  },
  '83706': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.5885,
    longitude: -116.191,
    postal_code: '83706'
  },
  '83707': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.6135,
    longitude: -116.2035,
    postal_code: '83707'
  },
  '83708': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83708'
  },
  '83709': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.5741,
    longitude: -116.2941,
    postal_code: '83709'
  },
  '83711': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83711'
  },
  '83712': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.6023,
    longitude: -116.1649,
    postal_code: '83712'
  },
  '83713': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.634,
    longitude: -116.3419,
    postal_code: '83713'
  },
  '83714': {
    country: 'US',
    city: 'Garden City',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.7318,
    longitude: -116.2797,
    postal_code: '83714'
  },
  '83715': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83715'
  },
  '83716': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.5345,
    longitude: -115.9711,
    postal_code: '83716'
  },
  '83717': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83717'
  },
  '83719': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83719'
  },
  '83720': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83720'
  },
  '83722': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83722'
  },
  '83724': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.6195,
    longitude: -116.1952,
    postal_code: '83724'
  },
  '83725': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83725'
  },
  '83726': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83726'
  },
  '83728': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.6135,
    longitude: -116.2035,
    postal_code: '83728'
  },
  '83729': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83729'
  },
  '83731': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83731'
  },
  '83732': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83732'
  },
  '83735': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.6135,
    longitude: -116.2035,
    postal_code: '83735'
  },
  '83756': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.4599,
    longitude: -116.244,
    postal_code: '83756'
  },
  '83799': {
    country: 'US',
    city: 'Boise',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Ada',
    latitude: 43.6135,
    longitude: -116.2035,
    postal_code: '83799'
  },
  '83801': {
    country: 'US',
    city: 'Athol',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.9267,
    longitude: -116.7318,
    postal_code: '83801'
  },
  '83802': {
    country: 'US',
    city: 'Avery',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.2714,
    longitude: -115.866,
    postal_code: '83802'
  },
  '83803': {
    country: 'US',
    city: 'Bayview',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.9822,
    longitude: -116.5494,
    postal_code: '83803'
  },
  '83804': {
    country: 'US',
    city: 'Blanchard',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.0223,
    longitude: -116.9909,
    postal_code: '83804'
  },
  '83805': {
    country: 'US',
    city: 'Bonners Ferry',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boundary',
    latitude: 48.7306,
    longitude: -116.3322,
    postal_code: '83805'
  },
  '83806': {
    country: 'US',
    city: 'Bovill',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.8588,
    longitude: -116.3935,
    postal_code: '83806'
  },
  '83808': {
    country: 'US',
    city: 'Calder',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.3219,
    longitude: -116.0703,
    postal_code: '83808'
  },
  '83809': {
    country: 'US',
    city: 'Careywood',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.0482,
    longitude: -116.5939,
    postal_code: '83809'
  },
  '83810': {
    country: 'US',
    city: 'Cataldo',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.5522,
    longitude: -116.4431,
    postal_code: '83810'
  },
  '83811': {
    country: 'US',
    city: 'Clark Fork',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.1405,
    longitude: -116.1699,
    postal_code: '83811'
  },
  '83812': {
    country: 'US',
    city: 'Clarkia',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.0107,
    longitude: -116.2529,
    postal_code: '83812'
  },
  '83813': {
    country: 'US',
    city: 'Cocolalla',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.1248,
    longitude: -116.6571,
    postal_code: '83813'
  },
  '83814': {
    country: 'US',
    city: "Coeur d'Alene",
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.6928,
    longitude: -116.785,
    postal_code: '83814'
  },
  '83815': {
    country: 'US',
    city: "Coeur d'Alene",
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.7248,
    longitude: -116.789,
    postal_code: '83815'
  },
  '83816': {
    country: 'US',
    city: "Coeur d'Alene",
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.6777,
    longitude: -116.7805,
    postal_code: '83816'
  },
  '83821': {
    country: 'US',
    city: 'Coolin',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.5228,
    longitude: -116.8408,
    postal_code: '83821'
  },
  '83822': {
    country: 'US',
    city: 'Oldtown',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.1552,
    longitude: -116.9781,
    postal_code: '83822'
  },
  '83823': {
    country: 'US',
    city: 'Deary',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.8061,
    longitude: -116.5238,
    postal_code: '83823'
  },
  '83824': {
    country: 'US',
    city: 'Desmet',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Benewah',
    latitude: 47.126,
    longitude: -116.8937,
    postal_code: '83824'
  },
  '83825': {
    country: 'US',
    city: 'Dover',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.2516,
    longitude: -116.611,
    postal_code: '83825'
  },
  '83826': {
    country: 'US',
    city: 'Eastport',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boundary',
    latitude: 48.9994,
    longitude: -116.1813,
    postal_code: '83826'
  },
  '83827': {
    country: 'US',
    city: 'Elk River',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Clearwater',
    latitude: 46.7835,
    longitude: -116.1799,
    postal_code: '83827'
  },
  '83830': {
    country: 'US',
    city: 'Fernwood',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Benewah',
    latitude: 47.116,
    longitude: -116.3831,
    postal_code: '83830'
  },
  '83832': {
    country: 'US',
    city: 'Genesee',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.5714,
    longitude: -116.929,
    postal_code: '83832'
  },
  '83833': {
    country: 'US',
    city: 'Harrison',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.5017,
    longitude: -116.7446,
    postal_code: '83833'
  },
  '83834': {
    country: 'US',
    city: 'Harvard',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.9376,
    longitude: -116.7025,
    postal_code: '83834'
  },
  '83835': {
    country: 'US',
    city: 'Hayden',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.7989,
    longitude: -116.7423,
    postal_code: '83835'
  },
  '83836': {
    country: 'US',
    city: 'Hope',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.2444,
    longitude: -116.2795,
    postal_code: '83836'
  },
  '83837': {
    country: 'US',
    city: 'Kellogg',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.5431,
    longitude: -116.1253,
    postal_code: '83837'
  },
  '83839': {
    country: 'US',
    city: 'Kingston',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.5509,
    longitude: -116.2887,
    postal_code: '83839'
  },
  '83840': {
    country: 'US',
    city: 'Kootenai',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.3102,
    longitude: -116.5135,
    postal_code: '83840'
  },
  '83841': {
    country: 'US',
    city: 'Laclede',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.1702,
    longitude: -116.756,
    postal_code: '83841'
  },
  '83842': {
    country: 'US',
    city: 'Medimont',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.4625,
    longitude: -116.5683,
    postal_code: '83842'
  },
  '83843': {
    country: 'US',
    city: 'Moscow',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.7309,
    longitude: -116.9897,
    postal_code: '83843'
  },
  '83844': {
    country: 'US',
    city: 'Moscow',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.7324,
    longitude: -117.0002,
    postal_code: '83844'
  },
  '83845': {
    country: 'US',
    city: 'Moyie Springs',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boundary',
    latitude: 48.7464,
    longitude: -116.1796,
    postal_code: '83845'
  },
  '83846': {
    country: 'US',
    city: 'Mullan',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.4709,
    longitude: -115.7926,
    postal_code: '83846'
  },
  '83847': {
    country: 'US',
    city: 'Naples',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boundary',
    latitude: 48.6049,
    longitude: -116.3196,
    postal_code: '83847'
  },
  '83848': {
    country: 'US',
    city: 'Nordman',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.6339,
    longitude: -116.9473,
    postal_code: '83848'
  },
  '83849': {
    country: 'US',
    city: 'Osburn',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.5211,
    longitude: -116.021,
    postal_code: '83849'
  },
  '83850': {
    country: 'US',
    city: 'Pinehurst',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.5018,
    longitude: -116.2647,
    postal_code: '83850'
  },
  '83851': {
    country: 'US',
    city: 'Plummer',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Benewah',
    latitude: 47.3278,
    longitude: -116.8662,
    postal_code: '83851'
  },
  '83852': {
    country: 'US',
    city: 'Ponderay',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.3055,
    longitude: -116.5338,
    postal_code: '83852'
  },
  '83853': {
    country: 'US',
    city: 'Porthill',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Boundary',
    latitude: 48.992,
    longitude: -116.4775,
    postal_code: '83853'
  },
  '83854': {
    country: 'US',
    city: 'Post Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.7205,
    longitude: -116.9353,
    postal_code: '83854'
  },
  '83855': {
    country: 'US',
    city: 'Potlatch',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.9448,
    longitude: -116.9141,
    postal_code: '83855'
  },
  '83856': {
    country: 'US',
    city: 'Priest River',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.1664,
    longitude: -116.9066,
    postal_code: '83856'
  },
  '83857': {
    country: 'US',
    city: 'Princeton',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.8996,
    longitude: -116.8287,
    postal_code: '83857'
  },
  '83858': {
    country: 'US',
    city: 'Rathdrum',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.8241,
    longitude: -116.8873,
    postal_code: '83858'
  },
  '83860': {
    country: 'US',
    city: 'Sagle',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.2035,
    longitude: -116.5455,
    postal_code: '83860'
  },
  '83861': {
    country: 'US',
    city: 'Saint Maries',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Benewah',
    latitude: 47.2977,
    longitude: -116.5681,
    postal_code: '83861'
  },
  '83864': {
    country: 'US',
    city: 'Sandpoint',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.312,
    longitude: -116.5332,
    postal_code: '83864'
  },
  '83865': {
    country: 'US',
    city: 'Colburn',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Bonner',
    latitude: 48.3633,
    longitude: -116.6256,
    postal_code: '83865'
  },
  '83866': {
    country: 'US',
    city: 'Santa',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Benewah',
    latitude: 47.1544,
    longitude: -116.431,
    postal_code: '83866'
  },
  '83867': {
    country: 'US',
    city: 'Silverton',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.4981,
    longitude: -115.9657,
    postal_code: '83867'
  },
  '83868': {
    country: 'US',
    city: 'Smelterville',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.5362,
    longitude: -116.2062,
    postal_code: '83868'
  },
  '83869': {
    country: 'US',
    city: 'Spirit Lake',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.9657,
    longitude: -116.868,
    postal_code: '83869'
  },
  '83870': {
    country: 'US',
    city: 'Tensed',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Benewah',
    latitude: 47.1707,
    longitude: -116.9027,
    postal_code: '83870'
  },
  '83871': {
    country: 'US',
    city: 'Troy',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.7426,
    longitude: -116.7681,
    postal_code: '83871'
  },
  '83872': {
    country: 'US',
    city: 'Viola',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Latah',
    latitude: 46.8583,
    longitude: -116.9732,
    postal_code: '83872'
  },
  '83873': {
    country: 'US',
    city: 'Wallace',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.4908,
    longitude: -115.962,
    postal_code: '83873'
  },
  '83874': {
    country: 'US',
    city: 'Murray',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Shoshone',
    latitude: 47.6271,
    longitude: -115.8585,
    postal_code: '83874'
  },
  '83876': {
    country: 'US',
    city: 'Worley',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.4292,
    longitude: -116.9056,
    postal_code: '83876'
  },
  '83877': {
    country: 'US',
    city: 'Post Falls',
    state: 'Idaho',
    state_short: 'ID',
    county: 'Kootenai',
    latitude: 47.718,
    longitude: -116.9516,
    postal_code: '83877'
  },
  '84001': {
    country: 'US',
    city: 'Altamont',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.3514,
    longitude: -110.2754,
    postal_code: '84001'
  },
  '84002': {
    country: 'US',
    city: 'Altonah',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.4013,
    longitude: -110.2938,
    postal_code: '84002'
  },
  '84003': {
    country: 'US',
    city: 'American Fork',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.3928,
    longitude: -111.7941,
    postal_code: '84003'
  },
  '84004': {
    country: 'US',
    city: 'Alpine',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.4616,
    longitude: -111.7689,
    postal_code: '84004'
  },
  '84005': {
    country: 'US',
    city: 'Eagle Mountain',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.3802,
    longitude: -111.991,
    postal_code: '84005'
  },
  '84006': {
    country: 'US',
    city: 'Bingham Canyon',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.5646,
    longitude: -112.0977,
    postal_code: '84006'
  },
  '84007': {
    country: 'US',
    city: 'Bluebell',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.3594,
    longitude: -110.2174,
    postal_code: '84007'
  },
  '84008': {
    country: 'US',
    city: 'Bonanza',
    state: 'Utah',
    state_short: 'UT',
    county: 'Uintah',
    latitude: 40.0211,
    longitude: -109.1773,
    postal_code: '84008'
  },
  '84009': {
    country: 'US',
    city: 'South Jordan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.5622,
    longitude: -111.9297,
    postal_code: '84009'
  },
  '84010': {
    country: 'US',
    city: 'Bountiful',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 40.8775,
    longitude: -111.8727,
    postal_code: '84010'
  },
  '84011': {
    country: 'US',
    city: 'Bountiful',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 40.8894,
    longitude: -111.8808,
    postal_code: '84011'
  },
  '84013': {
    country: 'US',
    city: 'Cedar Valley',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.2959,
    longitude: -112.0923,
    postal_code: '84013'
  },
  '84014': {
    country: 'US',
    city: 'Centerville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 40.9268,
    longitude: -111.877,
    postal_code: '84014'
  },
  '84015': {
    country: 'US',
    city: 'Clearfield',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 41.1294,
    longitude: -112.0482,
    postal_code: '84015'
  },
  '84016': {
    country: 'US',
    city: 'Clearfield',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 41.1108,
    longitude: -112.0261,
    postal_code: '84016'
  },
  '84017': {
    country: 'US',
    city: 'Coalville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Summit',
    latitude: 40.8405,
    longitude: -111.3228,
    postal_code: '84017'
  },
  '84018': {
    country: 'US',
    city: 'Croydon',
    state: 'Utah',
    state_short: 'UT',
    county: 'Morgan',
    latitude: 41.0689,
    longitude: -111.5231,
    postal_code: '84018'
  },
  '84020': {
    country: 'US',
    city: 'Draper',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.5046,
    longitude: -111.881,
    postal_code: '84020'
  },
  '84021': {
    country: 'US',
    city: 'Duchesne',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.1633,
    longitude: -110.4029,
    postal_code: '84021'
  },
  '84022': {
    country: 'US',
    city: 'Dugway',
    state: 'Utah',
    state_short: 'UT',
    county: 'Tooele',
    latitude: 40.2708,
    longitude: -112.6898,
    postal_code: '84022'
  },
  '84023': {
    country: 'US',
    city: 'Dutch John',
    state: 'Utah',
    state_short: 'UT',
    county: 'Daggett',
    latitude: 40.9291,
    longitude: -109.391,
    postal_code: '84023'
  },
  '84024': {
    country: 'US',
    city: 'Echo',
    state: 'Utah',
    state_short: 'UT',
    county: 'Summit',
    latitude: 40.9892,
    longitude: -111.451,
    postal_code: '84024'
  },
  '84025': {
    country: 'US',
    city: 'Farmington',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 40.9889,
    longitude: -111.8938,
    postal_code: '84025'
  },
  '84026': {
    country: 'US',
    city: 'Fort Duchesne',
    state: 'Utah',
    state_short: 'UT',
    county: 'Uintah',
    latitude: 40.3014,
    longitude: -109.8637,
    postal_code: '84026'
  },
  '84027': {
    country: 'US',
    city: 'Fruitland',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.1575,
    longitude: -110.8226,
    postal_code: '84027'
  },
  '84028': {
    country: 'US',
    city: 'Garden City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Rich',
    latitude: 41.9376,
    longitude: -111.407,
    postal_code: '84028'
  },
  '84029': {
    country: 'US',
    city: 'Grantsville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Tooele',
    latitude: 40.6005,
    longitude: -112.4618,
    postal_code: '84029'
  },
  '84031': {
    country: 'US',
    city: 'Hanna',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.4501,
    longitude: -110.8097,
    postal_code: '84031'
  },
  '84032': {
    country: 'US',
    city: 'Heber City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Wasatch',
    latitude: 40.4947,
    longitude: -111.4051,
    postal_code: '84032'
  },
  '84033': {
    country: 'US',
    city: 'Henefer',
    state: 'Utah',
    state_short: 'UT',
    county: 'Summit',
    latitude: 41.0186,
    longitude: -111.5011,
    postal_code: '84033'
  },
  '84034': {
    country: 'US',
    city: 'Ibapah',
    state: 'Utah',
    state_short: 'UT',
    county: 'Tooele',
    latitude: 40.0366,
    longitude: -113.9853,
    postal_code: '84034'
  },
  '84035': {
    country: 'US',
    city: 'Jensen',
    state: 'Utah',
    state_short: 'UT',
    county: 'Uintah',
    latitude: 40.3787,
    longitude: -109.351,
    postal_code: '84035'
  },
  '84036': {
    country: 'US',
    city: 'Kamas',
    state: 'Utah',
    state_short: 'UT',
    county: 'Summit',
    latitude: 40.6414,
    longitude: -111.2619,
    postal_code: '84036'
  },
  '84037': {
    country: 'US',
    city: 'Kaysville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 41.0375,
    longitude: -111.9326,
    postal_code: '84037'
  },
  '84038': {
    country: 'US',
    city: 'Laketown',
    state: 'Utah',
    state_short: 'UT',
    county: 'Rich',
    latitude: 41.8107,
    longitude: -111.2689,
    postal_code: '84038'
  },
  '84039': {
    country: 'US',
    city: 'Lapoint',
    state: 'Utah',
    state_short: 'UT',
    county: 'Uintah',
    latitude: 40.4003,
    longitude: -109.8041,
    postal_code: '84039'
  },
  '84040': {
    country: 'US',
    city: 'Layton',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 41.0846,
    longitude: -111.9274,
    postal_code: '84040'
  },
  '84041': {
    country: 'US',
    city: 'Layton',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 41.0879,
    longitude: -111.9704,
    postal_code: '84041'
  },
  '84042': {
    country: 'US',
    city: 'Lindon',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.3412,
    longitude: -111.7144,
    postal_code: '84042'
  },
  '84043': {
    country: 'US',
    city: 'Lehi',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.3958,
    longitude: -111.8506,
    postal_code: '84043'
  },
  '84044': {
    country: 'US',
    city: 'Magna',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7009,
    longitude: -112.0809,
    postal_code: '84044'
  },
  '84045': {
    country: 'US',
    city: 'Saratoga Springs',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.3495,
    longitude: -111.9043,
    postal_code: '84045'
  },
  '84046': {
    country: 'US',
    city: 'Manila',
    state: 'Utah',
    state_short: 'UT',
    county: 'Daggett',
    latitude: 40.9685,
    longitude: -109.7235,
    postal_code: '84046'
  },
  '84047': {
    country: 'US',
    city: 'Midvale',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6152,
    longitude: -111.8851,
    postal_code: '84047'
  },
  '84049': {
    country: 'US',
    city: 'Midway',
    state: 'Utah',
    state_short: 'UT',
    county: 'Wasatch',
    latitude: 40.5093,
    longitude: -111.4776,
    postal_code: '84049'
  },
  '84050': {
    country: 'US',
    city: 'Morgan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Morgan',
    latitude: 41.0678,
    longitude: -111.7163,
    postal_code: '84050'
  },
  '84051': {
    country: 'US',
    city: 'Mountain Home',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.3922,
    longitude: -110.4291,
    postal_code: '84051'
  },
  '84052': {
    country: 'US',
    city: 'Myton',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.194,
    longitude: -110.0481,
    postal_code: '84052'
  },
  '84053': {
    country: 'US',
    city: 'Neola',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.45,
    longitude: -110.0181,
    postal_code: '84053'
  },
  '84054': {
    country: 'US',
    city: 'North Salt Lake',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 40.8446,
    longitude: -111.9191,
    postal_code: '84054'
  },
  '84055': {
    country: 'US',
    city: 'Oakley',
    state: 'Utah',
    state_short: 'UT',
    county: 'Summit',
    latitude: 40.7243,
    longitude: -111.2456,
    postal_code: '84055'
  },
  '84056': {
    country: 'US',
    city: 'Hill Afb',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 41.1202,
    longitude: -111.9898,
    postal_code: '84056'
  },
  '84057': {
    country: 'US',
    city: 'Orem',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.3134,
    longitude: -111.6953,
    postal_code: '84057'
  },
  '84058': {
    country: 'US',
    city: 'Orem',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.2818,
    longitude: -111.7209,
    postal_code: '84058'
  },
  '84059': {
    country: 'US',
    city: 'Orem',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.177,
    longitude: -111.536,
    postal_code: '84059'
  },
  '84060': {
    country: 'US',
    city: 'Park City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Summit',
    latitude: 40.6524,
    longitude: -111.5018,
    postal_code: '84060'
  },
  '84061': {
    country: 'US',
    city: 'Peoa',
    state: 'Utah',
    state_short: 'UT',
    county: 'Summit',
    latitude: 40.7247,
    longitude: -111.3419,
    postal_code: '84061'
  },
  '84062': {
    country: 'US',
    city: 'Pleasant Grove',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.372,
    longitude: -111.7333,
    postal_code: '84062'
  },
  '84063': {
    country: 'US',
    city: 'Randlett',
    state: 'Utah',
    state_short: 'UT',
    county: 'Uintah',
    latitude: 40.2186,
    longitude: -109.7301,
    postal_code: '84063'
  },
  '84064': {
    country: 'US',
    city: 'Randolph',
    state: 'Utah',
    state_short: 'UT',
    county: 'Rich',
    latitude: 41.6563,
    longitude: -111.1856,
    postal_code: '84064'
  },
  '84065': {
    country: 'US',
    city: 'Riverton',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.4954,
    longitude: -111.9444,
    postal_code: '84065'
  },
  '84066': {
    country: 'US',
    city: 'Roosevelt',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.3102,
    longitude: -110.0108,
    postal_code: '84066'
  },
  '84067': {
    country: 'US',
    city: 'Roy',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.1724,
    longitude: -112.0382,
    postal_code: '84067'
  },
  '84068': {
    country: 'US',
    city: 'Park City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Summit',
    latitude: 40.668,
    longitude: -111.5054,
    postal_code: '84068'
  },
  '84069': {
    country: 'US',
    city: 'Rush Valley',
    state: 'Utah',
    state_short: 'UT',
    county: 'Tooele',
    latitude: 40.3566,
    longitude: -112.4659,
    postal_code: '84069'
  },
  '84070': {
    country: 'US',
    city: 'Sandy',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.5794,
    longitude: -111.8816,
    postal_code: '84070'
  },
  '84071': {
    country: 'US',
    city: 'Stockton',
    state: 'Utah',
    state_short: 'UT',
    county: 'Tooele',
    latitude: 40.4415,
    longitude: -112.3559,
    postal_code: '84071'
  },
  '84072': {
    country: 'US',
    city: 'Tabiona',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.3827,
    longitude: -110.7021,
    postal_code: '84072'
  },
  '84073': {
    country: 'US',
    city: 'Talmage',
    state: 'Utah',
    state_short: 'UT',
    county: 'Duchesne',
    latitude: 40.3207,
    longitude: -110.436,
    postal_code: '84073'
  },
  '84074': {
    country: 'US',
    city: 'Tooele',
    state: 'Utah',
    state_short: 'UT',
    county: 'Tooele',
    latitude: 40.5454,
    longitude: -112.3002,
    postal_code: '84074'
  },
  '84075': {
    country: 'US',
    city: 'Syracuse',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 41.0864,
    longitude: -112.0451,
    postal_code: '84075'
  },
  '84076': {
    country: 'US',
    city: 'Tridell',
    state: 'Utah',
    state_short: 'UT',
    county: 'Uintah',
    latitude: 40.4436,
    longitude: -109.8359,
    postal_code: '84076'
  },
  '84078': {
    country: 'US',
    city: 'Vernal',
    state: 'Utah',
    state_short: 'UT',
    county: 'Uintah',
    latitude: 40.4406,
    longitude: -109.5469,
    postal_code: '84078'
  },
  '84079': {
    country: 'US',
    city: 'Vernal',
    state: 'Utah',
    state_short: 'UT',
    county: 'Uintah',
    latitude: 40.4555,
    longitude: -109.5287,
    postal_code: '84079'
  },
  '84080': {
    country: 'US',
    city: 'Vernon',
    state: 'Utah',
    state_short: 'UT',
    county: 'Tooele',
    latitude: 40.0826,
    longitude: -112.426,
    postal_code: '84080'
  },
  '84081': {
    country: 'US',
    city: 'West Jordan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6037,
    longitude: -112.0411,
    postal_code: '84081'
  },
  '84082': {
    country: 'US',
    city: 'Wallsburg',
    state: 'Utah',
    state_short: 'UT',
    county: 'Wasatch',
    latitude: 40.3657,
    longitude: -111.4649,
    postal_code: '84082'
  },
  '84083': {
    country: 'US',
    city: 'Wendover',
    state: 'Utah',
    state_short: 'UT',
    county: 'Tooele',
    latitude: 40.7372,
    longitude: -114.0375,
    postal_code: '84083'
  },
  '84084': {
    country: 'US',
    city: 'West Jordan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6254,
    longitude: -111.9677,
    postal_code: '84084'
  },
  '84085': {
    country: 'US',
    city: 'Whiterocks',
    state: 'Utah',
    state_short: 'UT',
    county: 'Uintah',
    latitude: 40.468,
    longitude: -109.9307,
    postal_code: '84085'
  },
  '84086': {
    country: 'US',
    city: 'Woodruff',
    state: 'Utah',
    state_short: 'UT',
    county: 'Rich',
    latitude: 41.4888,
    longitude: -111.1868,
    postal_code: '84086'
  },
  '84087': {
    country: 'US',
    city: 'Woods Cross',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 40.8874,
    longitude: -111.9027,
    postal_code: '84087'
  },
  '84088': {
    country: 'US',
    city: 'West Jordan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.5959,
    longitude: -111.9644,
    postal_code: '84088'
  },
  '84089': {
    country: 'US',
    city: 'Clearfield',
    state: 'Utah',
    state_short: 'UT',
    county: 'Davis',
    latitude: 40.9635,
    longitude: -112.116,
    postal_code: '84089'
  },
  '84090': {
    country: 'US',
    city: 'Sandy',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84090'
  },
  '84091': {
    country: 'US',
    city: 'Sandy',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84091'
  },
  '84092': {
    country: 'US',
    city: 'Sandy',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.5834,
    longitude: -111.7467,
    postal_code: '84092'
  },
  '84093': {
    country: 'US',
    city: 'Sandy',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.5927,
    longitude: -111.831,
    postal_code: '84093'
  },
  '84094': {
    country: 'US',
    city: 'Sandy',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.5688,
    longitude: -111.8617,
    postal_code: '84094'
  },
  '84095': {
    country: 'US',
    city: 'South Jordan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.5541,
    longitude: -111.9539,
    postal_code: '84095'
  },
  '84096': {
    country: 'US',
    city: 'Herriman',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.5144,
    longitude: -112.0325,
    postal_code: '84096'
  },
  '84097': {
    country: 'US',
    city: 'Orem',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.2972,
    longitude: -111.6705,
    postal_code: '84097'
  },
  '84098': {
    country: 'US',
    city: 'Park City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Summit',
    latitude: 40.7029,
    longitude: -111.5481,
    postal_code: '84098'
  },
  '84101': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7559,
    longitude: -111.8967,
    postal_code: '84101'
  },
  '84102': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.76,
    longitude: -111.8627,
    postal_code: '84102'
  },
  '84103': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7776,
    longitude: -111.8749,
    postal_code: '84103'
  },
  '84104': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7499,
    longitude: -111.926,
    postal_code: '84104'
  },
  '84105': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7372,
    longitude: -111.8581,
    postal_code: '84105'
  },
  '84106': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7056,
    longitude: -111.8548,
    postal_code: '84106'
  },
  '84107': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6568,
    longitude: -111.8904,
    postal_code: '84107'
  },
  '84108': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7371,
    longitude: -111.8258,
    postal_code: '84108'
  },
  '84109': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7043,
    longitude: -111.8142,
    postal_code: '84109'
  },
  '84110': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84110'
  },
  '84111': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7548,
    longitude: -111.881,
    postal_code: '84111'
  },
  '84112': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7659,
    longitude: -111.8403,
    postal_code: '84112'
  },
  '84113': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7658,
    longitude: -111.8364,
    postal_code: '84113'
  },
  '84114': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84114'
  },
  '84115': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7145,
    longitude: -111.8931,
    postal_code: '84115'
  },
  '84116': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7857,
    longitude: -111.9291,
    postal_code: '84116'
  },
  '84117': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6551,
    longitude: -111.834,
    postal_code: '84117'
  },
  '84118': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6504,
    longitude: -112.0054,
    postal_code: '84118'
  },
  '84119': {
    country: 'US',
    city: 'West Valley City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6916,
    longitude: -112.0011,
    postal_code: '84119'
  },
  '84120': {
    country: 'US',
    city: 'West Valley City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6916,
    longitude: -112.0011,
    postal_code: '84120'
  },
  '84121': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6226,
    longitude: -111.7777,
    postal_code: '84121'
  },
  '84122': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84122'
  },
  '84123': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6596,
    longitude: -111.9193,
    postal_code: '84123'
  },
  '84124': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6772,
    longitude: -111.8133,
    postal_code: '84124'
  },
  '84125': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84125'
  },
  '84126': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84126'
  },
  '84127': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84127'
  },
  '84128': {
    country: 'US',
    city: 'West Valley City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6916,
    longitude: -112.0011,
    postal_code: '84128'
  },
  '84129': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6531,
    longitude: -111.9674,
    postal_code: '84129'
  },
  '84130': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84130'
  },
  '84131': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84131'
  },
  '84132': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7727,
    longitude: -111.8385,
    postal_code: '84132'
  },
  '84133': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.769,
    longitude: -111.8893,
    postal_code: '84133'
  },
  '84134': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84134'
  },
  '84136': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7685,
    longitude: -111.8879,
    postal_code: '84136'
  },
  '84138': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7608,
    longitude: -111.891,
    postal_code: '84138'
  },
  '84139': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7608,
    longitude: -111.891,
    postal_code: '84139'
  },
  '84141': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84141'
  },
  '84143': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7869,
    longitude: -111.9008,
    postal_code: '84143'
  },
  '84145': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84145'
  },
  '84147': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7608,
    longitude: -111.891,
    postal_code: '84147'
  },
  '84148': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7568,
    longitude: -111.8376,
    postal_code: '84148'
  },
  '84150': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84150'
  },
  '84151': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84151'
  },
  '84152': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7608,
    longitude: -111.891,
    postal_code: '84152'
  },
  '84157': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84157'
  },
  '84158': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.769,
    longitude: -111.7621,
    postal_code: '84158'
  },
  '84165': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84165'
  },
  '84170': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84170'
  },
  '84171': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84171'
  },
  '84180': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.769,
    longitude: -111.9008,
    postal_code: '84180'
  },
  '84184': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6768,
    longitude: -111.9568,
    postal_code: '84184'
  },
  '84189': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7608,
    longitude: -111.891,
    postal_code: '84189'
  },
  '84190': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.6681,
    longitude: -111.9083,
    postal_code: '84190'
  },
  '84199': {
    country: 'US',
    city: 'Salt Lake City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Salt Lake',
    latitude: 40.7259,
    longitude: -111.9394,
    postal_code: '84199'
  },
  '84201': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.2443,
    longitude: -112.0079,
    postal_code: '84201'
  },
  '84244': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.2553,
    longitude: -111.9567,
    postal_code: '84244'
  },
  '84301': {
    country: 'US',
    city: 'Bear River City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.6093,
    longitude: -112.1241,
    postal_code: '84301'
  },
  '84302': {
    country: 'US',
    city: 'Brigham City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.5079,
    longitude: -112.0152,
    postal_code: '84302'
  },
  '84304': {
    country: 'US',
    city: 'Cache Junction',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.8169,
    longitude: -111.9982,
    postal_code: '84304'
  },
  '84305': {
    country: 'US',
    city: 'Clarkston',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.9188,
    longitude: -112.0486,
    postal_code: '84305'
  },
  '84306': {
    country: 'US',
    city: 'Collinston',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.7813,
    longitude: -112.0666,
    postal_code: '84306'
  },
  '84307': {
    country: 'US',
    city: 'Corinne',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.545,
    longitude: -112.1514,
    postal_code: '84307'
  },
  '84308': {
    country: 'US',
    city: 'Cornish',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.9443,
    longitude: -111.9733,
    postal_code: '84308'
  },
  '84309': {
    country: 'US',
    city: 'Deweyville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.6972,
    longitude: -112.0947,
    postal_code: '84309'
  },
  '84310': {
    country: 'US',
    city: 'Eden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.3303,
    longitude: -111.8558,
    postal_code: '84310'
  },
  '84311': {
    country: 'US',
    city: 'Fielding',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.8118,
    longitude: -112.119,
    postal_code: '84311'
  },
  '84312': {
    country: 'US',
    city: 'Garland',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.7413,
    longitude: -112.1516,
    postal_code: '84312'
  },
  '84313': {
    country: 'US',
    city: 'Grouse Creek',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.7094,
    longitude: -113.8833,
    postal_code: '84313'
  },
  '84314': {
    country: 'US',
    city: 'Honeyville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.652,
    longitude: -112.105,
    postal_code: '84314'
  },
  '84315': {
    country: 'US',
    city: 'Hooper',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.1668,
    longitude: -112.1364,
    postal_code: '84315'
  },
  '84316': {
    country: 'US',
    city: 'Howell',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.7733,
    longitude: -112.3968,
    postal_code: '84316'
  },
  '84317': {
    country: 'US',
    city: 'Huntsville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.2721,
    longitude: -111.7618,
    postal_code: '84317'
  },
  '84318': {
    country: 'US',
    city: 'Hyde Park',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.8,
    longitude: -111.8123,
    postal_code: '84318'
  },
  '84319': {
    country: 'US',
    city: 'Hyrum',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.6311,
    longitude: -111.849,
    postal_code: '84319'
  },
  '84320': {
    country: 'US',
    city: 'Lewiston',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.9701,
    longitude: -111.8768,
    postal_code: '84320'
  },
  '84321': {
    country: 'US',
    city: 'Logan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.747,
    longitude: -111.8226,
    postal_code: '84321'
  },
  '84322': {
    country: 'US',
    city: 'Logan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.6412,
    longitude: -111.8966,
    postal_code: '84322'
  },
  '84323': {
    country: 'US',
    city: 'Logan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.7355,
    longitude: -111.8344,
    postal_code: '84323'
  },
  '84324': {
    country: 'US',
    city: 'Mantua',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.4975,
    longitude: -111.9416,
    postal_code: '84324'
  },
  '84325': {
    country: 'US',
    city: 'Mendon',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.71,
    longitude: -111.9817,
    postal_code: '84325'
  },
  '84326': {
    country: 'US',
    city: 'Millville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.6759,
    longitude: -111.8185,
    postal_code: '84326'
  },
  '84327': {
    country: 'US',
    city: 'Newton',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.8627,
    longitude: -111.9908,
    postal_code: '84327'
  },
  '84328': {
    country: 'US',
    city: 'Paradise',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.56,
    longitude: -111.8297,
    postal_code: '84328'
  },
  '84329': {
    country: 'US',
    city: 'Park Valley',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.8551,
    longitude: -113.3478,
    postal_code: '84329'
  },
  '84330': {
    country: 'US',
    city: 'Plymouth',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.8831,
    longitude: -112.1388,
    postal_code: '84330'
  },
  '84331': {
    country: 'US',
    city: 'Portage',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.9757,
    longitude: -112.2384,
    postal_code: '84331'
  },
  '84332': {
    country: 'US',
    city: 'Providence',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.7,
    longitude: -111.8123,
    postal_code: '84332'
  },
  '84333': {
    country: 'US',
    city: 'Richmond',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.9282,
    longitude: -111.8069,
    postal_code: '84333'
  },
  '84334': {
    country: 'US',
    city: 'Riverside',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.7868,
    longitude: -112.1467,
    postal_code: '84334'
  },
  '84335': {
    country: 'US',
    city: 'Smithfield',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.8403,
    longitude: -111.8528,
    postal_code: '84335'
  },
  '84336': {
    country: 'US',
    city: 'Snowville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.966,
    longitude: -112.7269,
    postal_code: '84336'
  },
  '84337': {
    country: 'US',
    city: 'Tremonton',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.7016,
    longitude: -112.1813,
    postal_code: '84337'
  },
  '84338': {
    country: 'US',
    city: 'Trenton',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.9105,
    longitude: -111.934,
    postal_code: '84338'
  },
  '84339': {
    country: 'US',
    city: 'Wellsville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.6343,
    longitude: -111.9317,
    postal_code: '84339'
  },
  '84340': {
    country: 'US',
    city: 'Willard',
    state: 'Utah',
    state_short: 'UT',
    county: 'Box Elder',
    latitude: 41.3989,
    longitude: -112.0317,
    postal_code: '84340'
  },
  '84341': {
    country: 'US',
    city: 'Logan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Cache',
    latitude: 41.7759,
    longitude: -111.8068,
    postal_code: '84341'
  },
  '84401': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.2215,
    longitude: -111.9621,
    postal_code: '84401'
  },
  '84402': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.2553,
    longitude: -111.9567,
    postal_code: '84402'
  },
  '84403': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.1894,
    longitude: -111.9489,
    postal_code: '84403'
  },
  '84404': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.2627,
    longitude: -111.9837,
    postal_code: '84404'
  },
  '84405': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.1739,
    longitude: -111.9809,
    postal_code: '84405'
  },
  '84407': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.2385,
    longitude: -111.9659,
    postal_code: '84407'
  },
  '84408': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.1956,
    longitude: -111.9485,
    postal_code: '84408'
  },
  '84409': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.2553,
    longitude: -111.9567,
    postal_code: '84409'
  },
  '84412': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.2553,
    longitude: -111.9567,
    postal_code: '84412'
  },
  '84414': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.3112,
    longitude: -111.9689,
    postal_code: '84414'
  },
  '84415': {
    country: 'US',
    city: 'Ogden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Weber',
    latitude: 41.2553,
    longitude: -111.9567,
    postal_code: '84415'
  },
  '84501': {
    country: 'US',
    city: 'Price',
    state: 'Utah',
    state_short: 'UT',
    county: 'Carbon',
    latitude: 39.602,
    longitude: -110.8081,
    postal_code: '84501'
  },
  '84510': {
    country: 'US',
    city: 'Aneth',
    state: 'Utah',
    state_short: 'UT',
    county: 'San Juan',
    latitude: 37.2161,
    longitude: -109.1871,
    postal_code: '84510'
  },
  '84511': {
    country: 'US',
    city: 'Blanding',
    state: 'Utah',
    state_short: 'UT',
    county: 'San Juan',
    latitude: 37.5863,
    longitude: -109.4866,
    postal_code: '84511'
  },
  '84512': {
    country: 'US',
    city: 'Bluff',
    state: 'Utah',
    state_short: 'UT',
    county: 'San Juan',
    latitude: 37.2844,
    longitude: -109.5518,
    postal_code: '84512'
  },
  '84513': {
    country: 'US',
    city: 'Castle Dale',
    state: 'Utah',
    state_short: 'UT',
    county: 'Emery',
    latitude: 39.2122,
    longitude: -111.0196,
    postal_code: '84513'
  },
  '84515': {
    country: 'US',
    city: 'Cisco',
    state: 'Utah',
    state_short: 'UT',
    county: 'Grand',
    latitude: 38.97,
    longitude: -109.3207,
    postal_code: '84515'
  },
  '84516': {
    country: 'US',
    city: 'Clawson',
    state: 'Utah',
    state_short: 'UT',
    county: 'Emery',
    latitude: 39.1198,
    longitude: -111.1008,
    postal_code: '84516'
  },
  '84518': {
    country: 'US',
    city: 'Cleveland',
    state: 'Utah',
    state_short: 'UT',
    county: 'Emery',
    latitude: 39.3489,
    longitude: -110.8516,
    postal_code: '84518'
  },
  '84520': {
    country: 'US',
    city: 'East Carbon',
    state: 'Utah',
    state_short: 'UT',
    county: 'Carbon',
    latitude: 39.5461,
    longitude: -110.4113,
    postal_code: '84520'
  },
  '84521': {
    country: 'US',
    city: 'Elmo',
    state: 'Utah',
    state_short: 'UT',
    county: 'Emery',
    latitude: 39.4033,
    longitude: -110.81,
    postal_code: '84521'
  },
  '84522': {
    country: 'US',
    city: 'Emery',
    state: 'Utah',
    state_short: 'UT',
    county: 'Emery',
    latitude: 38.9226,
    longitude: -111.2512,
    postal_code: '84522'
  },
  '84523': {
    country: 'US',
    city: 'Ferron',
    state: 'Utah',
    state_short: 'UT',
    county: 'Emery',
    latitude: 39.0692,
    longitude: -111.1467,
    postal_code: '84523'
  },
  '84525': {
    country: 'US',
    city: 'Green River',
    state: 'Utah',
    state_short: 'UT',
    county: 'Emery',
    latitude: 39.0002,
    longitude: -110.1598,
    postal_code: '84525'
  },
  '84526': {
    country: 'US',
    city: 'Helper',
    state: 'Utah',
    state_short: 'UT',
    county: 'Carbon',
    latitude: 39.6737,
    longitude: -110.856,
    postal_code: '84526'
  },
  '84528': {
    country: 'US',
    city: 'Huntington',
    state: 'Utah',
    state_short: 'UT',
    county: 'Emery',
    latitude: 39.3266,
    longitude: -110.9646,
    postal_code: '84528'
  },
  '84529': {
    country: 'US',
    city: 'Kenilworth',
    state: 'Utah',
    state_short: 'UT',
    county: 'Carbon',
    latitude: 39.6884,
    longitude: -110.8073,
    postal_code: '84529'
  },
  '84530': {
    country: 'US',
    city: 'La Sal',
    state: 'Utah',
    state_short: 'UT',
    county: 'San Juan',
    latitude: 38.3122,
    longitude: -109.2482,
    postal_code: '84530'
  },
  '84531': {
    country: 'US',
    city: 'Mexican Hat',
    state: 'Utah',
    state_short: 'UT',
    county: 'San Juan',
    latitude: 37.1184,
    longitude: -109.9919,
    postal_code: '84531'
  },
  '84532': {
    country: 'US',
    city: 'Moab',
    state: 'Utah',
    state_short: 'UT',
    county: 'Grand',
    latitude: 38.5677,
    longitude: -109.5271,
    postal_code: '84532'
  },
  '84533': {
    country: 'US',
    city: 'Lake Powell',
    state: 'Utah',
    state_short: 'UT',
    county: 'San Juan',
    latitude: 37.7489,
    longitude: -110.2269,
    postal_code: '84533'
  },
  '84534': {
    country: 'US',
    city: 'Montezuma Creek',
    state: 'Utah',
    state_short: 'UT',
    county: 'San Juan',
    latitude: 37.2539,
    longitude: -109.1061,
    postal_code: '84534'
  },
  '84535': {
    country: 'US',
    city: 'Monticello',
    state: 'Utah',
    state_short: 'UT',
    county: 'San Juan',
    latitude: 37.9217,
    longitude: -109.3153,
    postal_code: '84535'
  },
  '84536': {
    country: 'US',
    city: 'Monument Valley',
    state: 'Utah',
    state_short: 'UT',
    county: 'San Juan',
    latitude: 37.0614,
    longitude: -110.4273,
    postal_code: '84536'
  },
  '84537': {
    country: 'US',
    city: 'Orangeville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Emery',
    latitude: 39.2302,
    longitude: -111.0518,
    postal_code: '84537'
  },
  '84539': {
    country: 'US',
    city: 'Sunnyside',
    state: 'Utah',
    state_short: 'UT',
    county: 'Carbon',
    latitude: 39.5514,
    longitude: -110.3879,
    postal_code: '84539'
  },
  '84540': {
    country: 'US',
    city: 'Thompson',
    state: 'Utah',
    state_short: 'UT',
    county: 'Grand',
    latitude: 38.9711,
    longitude: -109.7137,
    postal_code: '84540'
  },
  '84542': {
    country: 'US',
    city: 'Wellington',
    state: 'Utah',
    state_short: 'UT',
    county: 'Carbon',
    latitude: 39.5423,
    longitude: -110.735,
    postal_code: '84542'
  },
  '84601': {
    country: 'US',
    city: 'Provo',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.2319,
    longitude: -111.6755,
    postal_code: '84601'
  },
  '84602': {
    country: 'US',
    city: 'Provo',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.3563,
    longitude: -111.7325,
    postal_code: '84602'
  },
  '84603': {
    country: 'US',
    city: 'Provo',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.2039,
    longitude: -111.6261,
    postal_code: '84603'
  },
  '84604': {
    country: 'US',
    city: 'Provo',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.2607,
    longitude: -111.6549,
    postal_code: '84604'
  },
  '84605': {
    country: 'US',
    city: 'Provo',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.177,
    longitude: -111.536,
    postal_code: '84605'
  },
  '84606': {
    country: 'US',
    city: 'Provo',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.2347,
    longitude: -111.6447,
    postal_code: '84606'
  },
  '84620': {
    country: 'US',
    city: 'Aurora',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.9222,
    longitude: -111.9341,
    postal_code: '84620'
  },
  '84621': {
    country: 'US',
    city: 'Axtell',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.0532,
    longitude: -111.8243,
    postal_code: '84621'
  },
  '84622': {
    country: 'US',
    city: 'Centerfield',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.1251,
    longitude: -111.8185,
    postal_code: '84622'
  },
  '84623': {
    country: 'US',
    city: 'Chester',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.4761,
    longitude: -111.563,
    postal_code: '84623'
  },
  '84624': {
    country: 'US',
    city: 'Delta',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 39.3755,
    longitude: -112.5319,
    postal_code: '84624'
  },
  '84626': {
    country: 'US',
    city: 'Elberta',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 39.9739,
    longitude: -111.9554,
    postal_code: '84626'
  },
  '84627': {
    country: 'US',
    city: 'Ephraim',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.36,
    longitude: -111.5823,
    postal_code: '84627'
  },
  '84628': {
    country: 'US',
    city: 'Eureka',
    state: 'Utah',
    state_short: 'UT',
    county: 'Juab',
    latitude: 39.9541,
    longitude: -112.1174,
    postal_code: '84628'
  },
  '84629': {
    country: 'US',
    city: 'Fairview',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.6264,
    longitude: -111.4396,
    postal_code: '84629'
  },
  '84630': {
    country: 'US',
    city: 'Fayette',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.2315,
    longitude: -111.8497,
    postal_code: '84630'
  },
  '84631': {
    country: 'US',
    city: 'Fillmore',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 38.9805,
    longitude: -112.3313,
    postal_code: '84631'
  },
  '84632': {
    country: 'US',
    city: 'Fountain Green',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.63,
    longitude: -111.6352,
    postal_code: '84632'
  },
  '84633': {
    country: 'US',
    city: 'Goshen',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 39.953,
    longitude: -111.9008,
    postal_code: '84633'
  },
  '84634': {
    country: 'US',
    city: 'Gunnison',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.1545,
    longitude: -111.8167,
    postal_code: '84634'
  },
  '84635': {
    country: 'US',
    city: 'Hinckley',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 39.3308,
    longitude: -112.6716,
    postal_code: '84635'
  },
  '84636': {
    country: 'US',
    city: 'Holden',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 39.0964,
    longitude: -112.2834,
    postal_code: '84636'
  },
  '84637': {
    country: 'US',
    city: 'Kanosh',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 38.8318,
    longitude: -112.4561,
    postal_code: '84637'
  },
  '84638': {
    country: 'US',
    city: 'Leamington',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 39.5302,
    longitude: -112.2952,
    postal_code: '84638'
  },
  '84639': {
    country: 'US',
    city: 'Levan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Juab',
    latitude: 39.5215,
    longitude: -111.9438,
    postal_code: '84639'
  },
  '84640': {
    country: 'US',
    city: 'Lynndyl',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 39.4943,
    longitude: -112.3979,
    postal_code: '84640'
  },
  '84642': {
    country: 'US',
    city: 'Manti',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.2683,
    longitude: -111.6369,
    postal_code: '84642'
  },
  '84643': {
    country: 'US',
    city: 'Mayfield',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.1154,
    longitude: -111.6905,
    postal_code: '84643'
  },
  '84644': {
    country: 'US',
    city: 'Meadow',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 38.8858,
    longitude: -112.4105,
    postal_code: '84644'
  },
  '84645': {
    country: 'US',
    city: 'Mona',
    state: 'Utah',
    state_short: 'UT',
    county: 'Juab',
    latitude: 39.8382,
    longitude: -111.848,
    postal_code: '84645'
  },
  '84646': {
    country: 'US',
    city: 'Moroni',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.525,
    longitude: -111.5905,
    postal_code: '84646'
  },
  '84647': {
    country: 'US',
    city: 'Mount Pleasant',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.5232,
    longitude: -111.5039,
    postal_code: '84647'
  },
  '84648': {
    country: 'US',
    city: 'Nephi',
    state: 'Utah',
    state_short: 'UT',
    county: 'Juab',
    latitude: 39.6923,
    longitude: -111.8359,
    postal_code: '84648'
  },
  '84649': {
    country: 'US',
    city: 'Oak City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 39.3729,
    longitude: -112.3288,
    postal_code: '84649'
  },
  '84651': {
    country: 'US',
    city: 'Payson',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.0449,
    longitude: -111.7321,
    postal_code: '84651'
  },
  '84652': {
    country: 'US',
    city: 'Redmond',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 39.0061,
    longitude: -111.8619,
    postal_code: '84652'
  },
  '84653': {
    country: 'US',
    city: 'Salem',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.0113,
    longitude: -111.5998,
    postal_code: '84653'
  },
  '84654': {
    country: 'US',
    city: 'Salina',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.956,
    longitude: -111.8811,
    postal_code: '84654'
  },
  '84655': {
    country: 'US',
    city: 'Santaquin',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 39.9737,
    longitude: -111.8037,
    postal_code: '84655'
  },
  '84656': {
    country: 'US',
    city: 'Scipio',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 39.2495,
    longitude: -112.1064,
    postal_code: '84656'
  },
  '84657': {
    country: 'US',
    city: 'Sigurd',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.8482,
    longitude: -111.9737,
    postal_code: '84657'
  },
  '84660': {
    country: 'US',
    city: 'Spanish Fork',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.1099,
    longitude: -111.6462,
    postal_code: '84660'
  },
  '84662': {
    country: 'US',
    city: 'Spring City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.4671,
    longitude: -111.4661,
    postal_code: '84662'
  },
  '84663': {
    country: 'US',
    city: 'Springville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.1625,
    longitude: -111.5987,
    postal_code: '84663'
  },
  '84664': {
    country: 'US',
    city: 'Mapleton',
    state: 'Utah',
    state_short: 'UT',
    county: 'Utah',
    latitude: 40.1337,
    longitude: -111.5801,
    postal_code: '84664'
  },
  '84665': {
    country: 'US',
    city: 'Sterling',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.1936,
    longitude: -111.6924,
    postal_code: '84665'
  },
  '84667': {
    country: 'US',
    city: 'Wales',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sanpete',
    latitude: 39.4884,
    longitude: -111.6341,
    postal_code: '84667'
  },
  '84701': {
    country: 'US',
    city: 'Richfield',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.7388,
    longitude: -112.0744,
    postal_code: '84701'
  },
  '84710': {
    country: 'US',
    city: 'Alton',
    state: 'Utah',
    state_short: 'UT',
    county: 'Kane',
    latitude: 37.4699,
    longitude: -112.5484,
    postal_code: '84710'
  },
  '84711': {
    country: 'US',
    city: 'Annabella',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.7168,
    longitude: -112.0657,
    postal_code: '84711'
  },
  '84712': {
    country: 'US',
    city: 'Antimony',
    state: 'Utah',
    state_short: 'UT',
    county: 'Garfield',
    latitude: 38.1015,
    longitude: -111.993,
    postal_code: '84712'
  },
  '84713': {
    country: 'US',
    city: 'Beaver',
    state: 'Utah',
    state_short: 'UT',
    county: 'Beaver',
    latitude: 38.2807,
    longitude: -112.6299,
    postal_code: '84713'
  },
  '84714': {
    country: 'US',
    city: 'Beryl',
    state: 'Utah',
    state_short: 'UT',
    county: 'Iron',
    latitude: 37.8958,
    longitude: -113.6614,
    postal_code: '84714'
  },
  '84715': {
    country: 'US',
    city: 'Bicknell',
    state: 'Utah',
    state_short: 'UT',
    county: 'Wayne',
    latitude: 38.3278,
    longitude: -111.5264,
    postal_code: '84715'
  },
  '84716': {
    country: 'US',
    city: 'Boulder',
    state: 'Utah',
    state_short: 'UT',
    county: 'Garfield',
    latitude: 37.9166,
    longitude: -111.4266,
    postal_code: '84716'
  },
  '84718': {
    country: 'US',
    city: 'Cannonville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Garfield',
    latitude: 37.5396,
    longitude: -112.0451,
    postal_code: '84718'
  },
  '84719': {
    country: 'US',
    city: 'Brian Head',
    state: 'Utah',
    state_short: 'UT',
    county: 'Iron',
    latitude: 37.6985,
    longitude: -112.8437,
    postal_code: '84719'
  },
  '84720': {
    country: 'US',
    city: 'Cedar City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Iron',
    latitude: 37.6775,
    longitude: -113.0619,
    postal_code: '84720'
  },
  '84721': {
    country: 'US',
    city: 'Cedar City',
    state: 'Utah',
    state_short: 'UT',
    county: 'Iron',
    latitude: 37.6775,
    longitude: -113.0619,
    postal_code: '84721'
  },
  '84722': {
    country: 'US',
    city: 'Central',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.4166,
    longitude: -113.6244,
    postal_code: '84722'
  },
  '84723': {
    country: 'US',
    city: 'Circleville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Piute',
    latitude: 38.1827,
    longitude: -112.2329,
    postal_code: '84723'
  },
  '84724': {
    country: 'US',
    city: 'Elsinore',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.6753,
    longitude: -112.1489,
    postal_code: '84724'
  },
  '84725': {
    country: 'US',
    city: 'Enterprise',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.5797,
    longitude: -113.6894,
    postal_code: '84725'
  },
  '84726': {
    country: 'US',
    city: 'Escalante',
    state: 'Utah',
    state_short: 'UT',
    county: 'Garfield',
    latitude: 37.7698,
    longitude: -111.6037,
    postal_code: '84726'
  },
  '84728': {
    country: 'US',
    city: 'Garrison',
    state: 'Utah',
    state_short: 'UT',
    county: 'Millard',
    latitude: 38.9344,
    longitude: -114.033,
    postal_code: '84728'
  },
  '84729': {
    country: 'US',
    city: 'Glendale',
    state: 'Utah',
    state_short: 'UT',
    county: 'Kane',
    latitude: 37.3219,
    longitude: -112.6035,
    postal_code: '84729'
  },
  '84730': {
    country: 'US',
    city: 'Glenwood',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.7633,
    longitude: -111.9902,
    postal_code: '84730'
  },
  '84731': {
    country: 'US',
    city: 'Greenville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Beaver',
    latitude: 38.2544,
    longitude: -112.7124,
    postal_code: '84731'
  },
  '84732': {
    country: 'US',
    city: 'Greenwich',
    state: 'Utah',
    state_short: 'UT',
    county: 'Piute',
    latitude: 38.4454,
    longitude: -111.9023,
    postal_code: '84732'
  },
  '84733': {
    country: 'US',
    city: 'Gunlock',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.2877,
    longitude: -113.7606,
    postal_code: '84733'
  },
  '84734': {
    country: 'US',
    city: 'Hanksville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Wayne',
    latitude: 38.2521,
    longitude: -110.8137,
    postal_code: '84734'
  },
  '84735': {
    country: 'US',
    city: 'Hatch',
    state: 'Utah',
    state_short: 'UT',
    county: 'Garfield',
    latitude: 37.6497,
    longitude: -112.4344,
    postal_code: '84735'
  },
  '84736': {
    country: 'US',
    city: 'Henrieville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Garfield',
    latitude: 37.5503,
    longitude: -111.9961,
    postal_code: '84736'
  },
  '84737': {
    country: 'US',
    city: 'Hurricane',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.1454,
    longitude: -113.3675,
    postal_code: '84737'
  },
  '84738': {
    country: 'US',
    city: 'Ivins',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.179,
    longitude: -113.7066,
    postal_code: '84738'
  },
  '84739': {
    country: 'US',
    city: 'Joseph',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.6221,
    longitude: -112.2251,
    postal_code: '84739'
  },
  '84740': {
    country: 'US',
    city: 'Junction',
    state: 'Utah',
    state_short: 'UT',
    county: 'Piute',
    latitude: 38.2439,
    longitude: -112.2237,
    postal_code: '84740'
  },
  '84741': {
    country: 'US',
    city: 'Kanab',
    state: 'Utah',
    state_short: 'UT',
    county: 'Kane',
    latitude: 37.0475,
    longitude: -112.5263,
    postal_code: '84741'
  },
  '84742': {
    country: 'US',
    city: 'Kanarraville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Iron',
    latitude: 37.5389,
    longitude: -113.1841,
    postal_code: '84742'
  },
  '84743': {
    country: 'US',
    city: 'Kingston',
    state: 'Utah',
    state_short: 'UT',
    county: 'Piute',
    latitude: 38.2158,
    longitude: -112.2049,
    postal_code: '84743'
  },
  '84744': {
    country: 'US',
    city: 'Koosharem',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.5103,
    longitude: -111.8813,
    postal_code: '84744'
  },
  '84745': {
    country: 'US',
    city: 'La Verkin',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.2316,
    longitude: -113.2446,
    postal_code: '84745'
  },
  '84746': {
    country: 'US',
    city: 'Leeds',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.2386,
    longitude: -113.3591,
    postal_code: '84746'
  },
  '84747': {
    country: 'US',
    city: 'Loa',
    state: 'Utah',
    state_short: 'UT',
    county: 'Wayne',
    latitude: 38.4415,
    longitude: -111.5958,
    postal_code: '84747'
  },
  '84749': {
    country: 'US',
    city: 'Lyman',
    state: 'Utah',
    state_short: 'UT',
    county: 'Wayne',
    latitude: 38.3969,
    longitude: -111.5896,
    postal_code: '84749'
  },
  '84750': {
    country: 'US',
    city: 'Marysvale',
    state: 'Utah',
    state_short: 'UT',
    county: 'Piute',
    latitude: 38.4449,
    longitude: -112.2518,
    postal_code: '84750'
  },
  '84751': {
    country: 'US',
    city: 'Milford',
    state: 'Utah',
    state_short: 'UT',
    county: 'Beaver',
    latitude: 38.3311,
    longitude: -112.9934,
    postal_code: '84751'
  },
  '84752': {
    country: 'US',
    city: 'Minersville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Beaver',
    latitude: 38.2149,
    longitude: -112.9231,
    postal_code: '84752'
  },
  '84753': {
    country: 'US',
    city: 'Modena',
    state: 'Utah',
    state_short: 'UT',
    county: 'Iron',
    latitude: 37.7995,
    longitude: -113.9193,
    postal_code: '84753'
  },
  '84754': {
    country: 'US',
    city: 'Monroe',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.6578,
    longitude: -112.1261,
    postal_code: '84754'
  },
  '84755': {
    country: 'US',
    city: 'Mount Carmel',
    state: 'Utah',
    state_short: 'UT',
    county: 'Kane',
    latitude: 37.2472,
    longitude: -112.6644,
    postal_code: '84755'
  },
  '84756': {
    country: 'US',
    city: 'Newcastle',
    state: 'Utah',
    state_short: 'UT',
    county: 'Iron',
    latitude: 37.6924,
    longitude: -113.6272,
    postal_code: '84756'
  },
  '84757': {
    country: 'US',
    city: 'New Harmony',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.455,
    longitude: -113.268,
    postal_code: '84757'
  },
  '84758': {
    country: 'US',
    city: 'Orderville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Kane',
    latitude: 37.2744,
    longitude: -112.642,
    postal_code: '84758'
  },
  '84759': {
    country: 'US',
    city: 'Panguitch',
    state: 'Utah',
    state_short: 'UT',
    county: 'Garfield',
    latitude: 37.8078,
    longitude: -112.4369,
    postal_code: '84759'
  },
  '84760': {
    country: 'US',
    city: 'Paragonah',
    state: 'Utah',
    state_short: 'UT',
    county: 'Iron',
    latitude: 37.8917,
    longitude: -112.774,
    postal_code: '84760'
  },
  '84761': {
    country: 'US',
    city: 'Parowan',
    state: 'Utah',
    state_short: 'UT',
    county: 'Iron',
    latitude: 37.8449,
    longitude: -112.8323,
    postal_code: '84761'
  },
  '84762': {
    country: 'US',
    city: 'Duck Creek Village',
    state: 'Utah',
    state_short: 'UT',
    county: 'Kane',
    latitude: 37.5169,
    longitude: -112.6636,
    postal_code: '84762'
  },
  '84763': {
    country: 'US',
    city: 'Rockville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.1601,
    longitude: -113.0425,
    postal_code: '84763'
  },
  '84764': {
    country: 'US',
    city: 'Bryce',
    state: 'Utah',
    state_short: 'UT',
    county: 'Garfield',
    latitude: 37.6184,
    longitude: -112.1735,
    postal_code: '84764'
  },
  '84765': {
    country: 'US',
    city: 'Santa Clara',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.1282,
    longitude: -113.676,
    postal_code: '84765'
  },
  '84766': {
    country: 'US',
    city: 'Sevier',
    state: 'Utah',
    state_short: 'UT',
    county: 'Sevier',
    latitude: 38.5785,
    longitude: -112.3108,
    postal_code: '84766'
  },
  '84767': {
    country: 'US',
    city: 'Springdale',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.1862,
    longitude: -113.0139,
    postal_code: '84767'
  },
  '84770': {
    country: 'US',
    city: 'Saint George',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.1041,
    longitude: -113.5841,
    postal_code: '84770'
  },
  '84771': {
    country: 'US',
    city: 'Saint George',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.1041,
    longitude: -113.5841,
    postal_code: '84771'
  },
  '84772': {
    country: 'US',
    city: 'Summit',
    state: 'Utah',
    state_short: 'UT',
    county: 'Iron',
    latitude: 37.8011,
    longitude: -112.9363,
    postal_code: '84772'
  },
  '84773': {
    country: 'US',
    city: 'Teasdale',
    state: 'Utah',
    state_short: 'UT',
    county: 'Wayne',
    latitude: 38.2853,
    longitude: -111.4335,
    postal_code: '84773'
  },
  '84774': {
    country: 'US',
    city: 'Toquerville',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.2533,
    longitude: -113.2847,
    postal_code: '84774'
  },
  '84775': {
    country: 'US',
    city: 'Torrey',
    state: 'Utah',
    state_short: 'UT',
    county: 'Wayne',
    latitude: 38.2969,
    longitude: -111.4138,
    postal_code: '84775'
  },
  '84776': {
    country: 'US',
    city: 'Tropic',
    state: 'Utah',
    state_short: 'UT',
    county: 'Garfield',
    latitude: 37.6261,
    longitude: -112.0836,
    postal_code: '84776'
  },
  '84779': {
    country: 'US',
    city: 'Virgin',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.2022,
    longitude: -113.1854,
    postal_code: '84779'
  },
  '84780': {
    country: 'US',
    city: 'Washington',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.1364,
    longitude: -113.505,
    postal_code: '84780'
  },
  '84781': {
    country: 'US',
    city: 'Pine Valley',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.3898,
    longitude: -113.5177,
    postal_code: '84781'
  },
  '84782': {
    country: 'US',
    city: 'Veyo',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.359,
    longitude: -113.6668,
    postal_code: '84782'
  },
  '84783': {
    country: 'US',
    city: 'Dammeron Valley',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.2849,
    longitude: -113.6586,
    postal_code: '84783'
  },
  '84784': {
    country: 'US',
    city: 'Hildale',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.0044,
    longitude: -112.9784,
    postal_code: '84784'
  },
  '84790': {
    country: 'US',
    city: 'Saint George',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.0831,
    longitude: -113.5581,
    postal_code: '84790'
  },
  '84791': {
    country: 'US',
    city: 'Saint George',
    state: 'Utah',
    state_short: 'UT',
    county: 'Washington',
    latitude: 37.1041,
    longitude: -113.5841,
    postal_code: '84791'
  },
  '85001': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85001'
  },
  '85002': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85002'
  },
  '85003': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4511,
    longitude: -112.0774,
    postal_code: '85003'
  },
  '85004': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4557,
    longitude: -112.0686,
    postal_code: '85004'
  },
  '85005': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85005'
  },
  '85006': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.465,
    longitude: -112.0474,
    postal_code: '85006'
  },
  '85007': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4523,
    longitude: -112.0893,
    postal_code: '85007'
  },
  '85008': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4665,
    longitude: -111.9984,
    postal_code: '85008'
  },
  '85009': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4564,
    longitude: -112.1284,
    postal_code: '85009'
  },
  '85010': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85010'
  },
  '85011': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85011'
  },
  '85012': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5097,
    longitude: -112.0678,
    postal_code: '85012'
  },
  '85013': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5085,
    longitude: -112.0827,
    postal_code: '85013'
  },
  '85014': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5103,
    longitude: -112.0556,
    postal_code: '85014'
  },
  '85015': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5082,
    longitude: -112.1011,
    postal_code: '85015'
  },
  '85016': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5021,
    longitude: -112.0305,
    postal_code: '85016'
  },
  '85017': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5153,
    longitude: -112.1212,
    postal_code: '85017'
  },
  '85018': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4958,
    longitude: -111.9883,
    postal_code: '85018'
  },
  '85019': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5123,
    longitude: -112.1417,
    postal_code: '85019'
  },
  '85020': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5623,
    longitude: -112.0559,
    postal_code: '85020'
  },
  '85021': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.56,
    longitude: -112.0927,
    postal_code: '85021'
  },
  '85022': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6315,
    longitude: -112.052,
    postal_code: '85022'
  },
  '85023': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6324,
    longitude: -112.1118,
    postal_code: '85023'
  },
  '85024': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6617,
    longitude: -112.037,
    postal_code: '85024'
  },
  '85025': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85025'
  },
  '85026': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85026'
  },
  '85027': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6819,
    longitude: -112.0996,
    postal_code: '85027'
  },
  '85028': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5851,
    longitude: -112.0087,
    postal_code: '85028'
  },
  '85029': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5961,
    longitude: -112.1199,
    postal_code: '85029'
  },
  '85030': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85030'
  },
  '85031': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4939,
    longitude: -112.1696,
    postal_code: '85031'
  },
  '85032': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6238,
    longitude: -112.0044,
    postal_code: '85032'
  },
  '85033': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4944,
    longitude: -112.2132,
    postal_code: '85033'
  },
  '85034': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4413,
    longitude: -112.0421,
    postal_code: '85034'
  },
  '85035': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4724,
    longitude: -112.1832,
    postal_code: '85035'
  },
  '85036': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85036'
  },
  '85037': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4913,
    longitude: -112.2468,
    postal_code: '85037'
  },
  '85038': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85038'
  },
  '85039': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85039'
  },
  '85040': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4061,
    longitude: -112.0265,
    postal_code: '85040'
  },
  '85041': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3889,
    longitude: -112.0954,
    postal_code: '85041'
  },
  '85042': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3794,
    longitude: -112.0283,
    postal_code: '85042'
  },
  '85043': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4491,
    longitude: -112.1972,
    postal_code: '85043'
  },
  '85044': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3291,
    longitude: -111.9943,
    postal_code: '85044'
  },
  '85045': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3022,
    longitude: -112.1226,
    postal_code: '85045'
  },
  '85046': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85046'
  },
  '85048': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.316,
    longitude: -112.0669,
    postal_code: '85048'
  },
  '85050': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6863,
    longitude: -111.9963,
    postal_code: '85050'
  },
  '85051': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5591,
    longitude: -112.1332,
    postal_code: '85051'
  },
  '85053': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6299,
    longitude: -112.1316,
    postal_code: '85053'
  },
  '85054': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6731,
    longitude: -111.9461,
    postal_code: '85054'
  },
  '85060': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85060'
  },
  '85061': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85061'
  },
  '85062': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85062'
  },
  '85063': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85063'
  },
  '85064': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85064'
  },
  '85065': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85065'
  },
  '85066': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85066'
  },
  '85067': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85067'
  },
  '85068': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85068'
  },
  '85069': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85069'
  },
  '85070': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85070'
  },
  '85071': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85071'
  },
  '85072': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85072'
  },
  '85073': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85073'
  },
  '85074': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85074'
  },
  '85075': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85075'
  },
  '85076': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85076'
  },
  '85078': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85078'
  },
  '85079': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85079'
  },
  '85080': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85080'
  },
  '85082': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85082'
  },
  '85083': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.7352,
    longitude: -112.1294,
    postal_code: '85083'
  },
  '85085': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.7529,
    longitude: -112.0893,
    postal_code: '85085'
  },
  '85086': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.8155,
    longitude: -112.1202,
    postal_code: '85086'
  },
  '85087': {
    country: 'US',
    city: 'New River',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.9235,
    longitude: -112.1279,
    postal_code: '85087'
  },
  '85097': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85097'
  },
  '85098': {
    country: 'US',
    city: 'Phoenix',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4484,
    longitude: -112.074,
    postal_code: '85098'
  },
  '85117': {
    country: 'US',
    city: 'Apache Junction',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.3284,
    longitude: -111.327,
    postal_code: '85117'
  },
  '85118': {
    country: 'US',
    city: 'Gold Canyon',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.3284,
    longitude: -111.3502,
    postal_code: '85118'
  },
  '85119': {
    country: 'US',
    city: 'Apache Junction',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.4001,
    longitude: -111.5028,
    postal_code: '85119'
  },
  '85120': {
    country: 'US',
    city: 'Apache Junction',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.3931,
    longitude: -111.5768,
    postal_code: '85120'
  },
  '85121': {
    country: 'US',
    city: 'Bapchule',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.1405,
    longitude: -111.9128,
    postal_code: '85121'
  },
  '85122': {
    country: 'US',
    city: 'Casa Grande',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.8905,
    longitude: -111.754,
    postal_code: '85122'
  },
  '85123': {
    country: 'US',
    city: 'Arizona City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.7559,
    longitude: -111.671,
    postal_code: '85123'
  },
  '85127': {
    country: 'US',
    city: 'Chandler Heights',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.2125,
    longitude: -111.697,
    postal_code: '85127'
  },
  '85128': {
    country: 'US',
    city: 'Coolidge',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.9574,
    longitude: -111.5344,
    postal_code: '85128'
  },
  '85130': {
    country: 'US',
    city: 'Casa Grande',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.8151,
    longitude: -111.7054,
    postal_code: '85130'
  },
  '85131': {
    country: 'US',
    city: 'Eloy',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.7509,
    longitude: -111.5833,
    postal_code: '85131'
  },
  '85132': {
    country: 'US',
    city: 'Florence',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.9969,
    longitude: -111.3612,
    postal_code: '85132'
  },
  '85135': {
    country: 'US',
    city: 'Hayden',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 33.0041,
    longitude: -110.7868,
    postal_code: '85135'
  },
  '85137': {
    country: 'US',
    city: 'Kearny',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.0594,
    longitude: -110.9123,
    postal_code: '85137'
  },
  '85138': {
    country: 'US',
    city: 'Maricopa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.0073,
    longitude: -111.9324,
    postal_code: '85138'
  },
  '85139': {
    country: 'US',
    city: 'Maricopa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.9576,
    longitude: -112.0534,
    postal_code: '85139'
  },
  '85140': {
    country: 'US',
    city: 'San Tan Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.1911,
    longitude: -111.528,
    postal_code: '85140'
  },
  '85141': {
    country: 'US',
    city: 'Picacho',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.6686,
    longitude: -111.4549,
    postal_code: '85141'
  },
  '85142': {
    country: 'US',
    city: 'Queen Creek',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.2487,
    longitude: -111.6343,
    postal_code: '85142'
  },
  '85143': {
    country: 'US',
    city: 'San Tan Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.1911,
    longitude: -111.528,
    postal_code: '85143'
  },
  '85145': {
    country: 'US',
    city: 'Red Rock',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.58,
    longitude: -111.341,
    postal_code: '85145'
  },
  '85147': {
    country: 'US',
    city: 'Sacaton',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.1234,
    longitude: -111.7384,
    postal_code: '85147'
  },
  '85172': {
    country: 'US',
    city: 'Stanfield',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.8823,
    longitude: -111.966,
    postal_code: '85172'
  },
  '85173': {
    country: 'US',
    city: 'Superior',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.2887,
    longitude: -111.0985,
    postal_code: '85173'
  },
  '85178': {
    country: 'US',
    city: 'Apache Junction',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 33.405,
    longitude: -111.547,
    postal_code: '85178'
  },
  '85190': {
    country: 'US',
    city: 'Tortilla Flat',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5264,
    longitude: -111.3892,
    postal_code: '85190'
  },
  '85191': {
    country: 'US',
    city: 'Valley Farms',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.9827,
    longitude: -111.4429,
    postal_code: '85191'
  },
  '85192': {
    country: 'US',
    city: 'Winkelman',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 33.0057,
    longitude: -110.7727,
    postal_code: '85192'
  },
  '85193': {
    country: 'US',
    city: 'Casa Grande',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.8554,
    longitude: -111.7973,
    postal_code: '85193'
  },
  '85194': {
    country: 'US',
    city: 'Casa Grande',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.8984,
    longitude: -111.6261,
    postal_code: '85194'
  },
  '85201': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4317,
    longitude: -111.8469,
    postal_code: '85201'
  },
  '85202': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3851,
    longitude: -111.8724,
    postal_code: '85202'
  },
  '85203': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.437,
    longitude: -111.8057,
    postal_code: '85203'
  },
  '85204': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3992,
    longitude: -111.7896,
    postal_code: '85204'
  },
  '85205': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4368,
    longitude: -111.7129,
    postal_code: '85205'
  },
  '85206': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4026,
    longitude: -111.7242,
    postal_code: '85206'
  },
  '85207': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4321,
    longitude: -111.6426,
    postal_code: '85207'
  },
  '85208': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3984,
    longitude: -111.6513,
    postal_code: '85208'
  },
  '85209': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3782,
    longitude: -111.6406,
    postal_code: '85209'
  },
  '85210': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3887,
    longitude: -111.8428,
    postal_code: '85210'
  },
  '85211': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4663,
    longitude: -111.8373,
    postal_code: '85211'
  },
  '85212': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3425,
    longitude: -111.6353,
    postal_code: '85212'
  },
  '85213': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4367,
    longitude: -111.7731,
    postal_code: '85213'
  },
  '85214': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4125,
    longitude: -111.8319,
    postal_code: '85214'
  },
  '85215': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4707,
    longitude: -111.7188,
    postal_code: '85215'
  },
  '85216': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4125,
    longitude: -111.8319,
    postal_code: '85216'
  },
  '85224': {
    country: 'US',
    city: 'Chandler',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3301,
    longitude: -111.8632,
    postal_code: '85224'
  },
  '85225': {
    country: 'US',
    city: 'Chandler',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3105,
    longitude: -111.8239,
    postal_code: '85225'
  },
  '85226': {
    country: 'US',
    city: 'Chandler',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3092,
    longitude: -111.9198,
    postal_code: '85226'
  },
  '85233': {
    country: 'US',
    city: 'Gilbert',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3354,
    longitude: -111.8153,
    postal_code: '85233'
  },
  '85234': {
    country: 'US',
    city: 'Gilbert',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3527,
    longitude: -111.7809,
    postal_code: '85234'
  },
  '85236': {
    country: 'US',
    city: 'Higley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3024,
    longitude: -111.6969,
    postal_code: '85236'
  },
  '85244': {
    country: 'US',
    city: 'Chandler',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3213,
    longitude: -111.8888,
    postal_code: '85244'
  },
  '85246': {
    country: 'US',
    city: 'Chandler',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3062,
    longitude: -111.8413,
    postal_code: '85246'
  },
  '85248': {
    country: 'US',
    city: 'Chandler',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.2509,
    longitude: -111.8593,
    postal_code: '85248'
  },
  '85249': {
    country: 'US',
    city: 'Chandler',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.2414,
    longitude: -111.7745,
    postal_code: '85249'
  },
  '85250': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5218,
    longitude: -111.9049,
    postal_code: '85250'
  },
  '85251': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4936,
    longitude: -111.9167,
    postal_code: '85251'
  },
  '85252': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4995,
    longitude: -111.8684,
    postal_code: '85252'
  },
  '85253': {
    country: 'US',
    city: 'Paradise Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5494,
    longitude: -111.9565,
    postal_code: '85253'
  },
  '85254': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6165,
    longitude: -111.9554,
    postal_code: '85254'
  },
  '85255': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6968,
    longitude: -111.8892,
    postal_code: '85255'
  },
  '85256': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4858,
    longitude: -111.8533,
    postal_code: '85256'
  },
  '85257': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4669,
    longitude: -111.9151,
    postal_code: '85257'
  },
  '85258': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5647,
    longitude: -111.8931,
    postal_code: '85258'
  },
  '85259': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5879,
    longitude: -111.8404,
    postal_code: '85259'
  },
  '85260': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6013,
    longitude: -111.8867,
    postal_code: '85260'
  },
  '85261': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5092,
    longitude: -111.899,
    postal_code: '85261'
  },
  '85262': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.7752,
    longitude: -111.7791,
    postal_code: '85262'
  },
  '85263': {
    country: 'US',
    city: 'Rio Verde',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.7225,
    longitude: -111.6757,
    postal_code: '85263'
  },
  '85264': {
    country: 'US',
    city: 'Fort Mcdowell',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6118,
    longitude: -111.6806,
    postal_code: '85264'
  },
  '85266': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.7669,
    longitude: -111.9182,
    postal_code: '85266'
  },
  '85267': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5092,
    longitude: -111.899,
    postal_code: '85267'
  },
  '85268': {
    country: 'US',
    city: 'Fountain Hills',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6085,
    longitude: -111.7237,
    postal_code: '85268'
  },
  '85269': {
    country: 'US',
    city: 'Fountain Hills',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6117,
    longitude: -111.7174,
    postal_code: '85269'
  },
  '85271': {
    country: 'US',
    city: 'Scottsdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5092,
    longitude: -111.899,
    postal_code: '85271'
  },
  '85274': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4125,
    longitude: -111.8319,
    postal_code: '85274'
  },
  '85275': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4125,
    longitude: -111.8319,
    postal_code: '85275'
  },
  '85277': {
    country: 'US',
    city: 'Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4125,
    longitude: -111.8319,
    postal_code: '85277'
  },
  '85280': {
    country: 'US',
    city: 'Tempe',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4014,
    longitude: -111.9313,
    postal_code: '85280'
  },
  '85281': {
    country: 'US',
    city: 'Tempe',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4227,
    longitude: -111.9261,
    postal_code: '85281'
  },
  '85282': {
    country: 'US',
    city: 'Tempe',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3917,
    longitude: -111.9249,
    postal_code: '85282'
  },
  '85283': {
    country: 'US',
    city: 'Tempe',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3665,
    longitude: -111.9312,
    postal_code: '85283'
  },
  '85284': {
    country: 'US',
    city: 'Tempe',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3363,
    longitude: -111.9197,
    postal_code: '85284'
  },
  '85285': {
    country: 'US',
    city: 'Tempe',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4148,
    longitude: -111.9093,
    postal_code: '85285'
  },
  '85286': {
    country: 'US',
    city: 'Chandler',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.2711,
    longitude: -111.8311,
    postal_code: '85286'
  },
  '85287': {
    country: 'US',
    city: 'Tempe',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4285,
    longitude: -111.9349,
    postal_code: '85287'
  },
  '85295': {
    country: 'US',
    city: 'Gilbert',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3054,
    longitude: -111.7408,
    postal_code: '85295'
  },
  '85296': {
    country: 'US',
    city: 'Gilbert',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3354,
    longitude: -111.7406,
    postal_code: '85296'
  },
  '85297': {
    country: 'US',
    city: 'Gilbert',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.2781,
    longitude: -111.7096,
    postal_code: '85297'
  },
  '85298': {
    country: 'US',
    city: 'Gilbert',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.2522,
    longitude: -111.7022,
    postal_code: '85298'
  },
  '85299': {
    country: 'US',
    city: 'Gilbert',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3528,
    longitude: -111.789,
    postal_code: '85299'
  },
  '85301': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5311,
    longitude: -112.1767,
    postal_code: '85301'
  },
  '85302': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5675,
    longitude: -112.1753,
    postal_code: '85302'
  },
  '85303': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5262,
    longitude: -112.2149,
    postal_code: '85303'
  },
  '85304': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5943,
    longitude: -112.1746,
    postal_code: '85304'
  },
  '85305': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5291,
    longitude: -112.2482,
    postal_code: '85305'
  },
  '85306': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6239,
    longitude: -112.1776,
    postal_code: '85306'
  },
  '85307': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5376,
    longitude: -112.3137,
    postal_code: '85307'
  },
  '85308': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6539,
    longitude: -112.1694,
    postal_code: '85308'
  },
  '85309': {
    country: 'US',
    city: 'Luke Air Force Base',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5387,
    longitude: -112.186,
    postal_code: '85309'
  },
  '85310': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.7047,
    longitude: -112.1641,
    postal_code: '85310'
  },
  '85311': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5387,
    longitude: -112.186,
    postal_code: '85311'
  },
  '85312': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5387,
    longitude: -112.186,
    postal_code: '85312'
  },
  '85318': {
    country: 'US',
    city: 'Glendale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5387,
    longitude: -112.186,
    postal_code: '85318'
  },
  '85320': {
    country: 'US',
    city: 'Aguila',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.9186,
    longitude: -113.2134,
    postal_code: '85320'
  },
  '85321': {
    country: 'US',
    city: 'Ajo',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2295,
    longitude: -112.6542,
    postal_code: '85321'
  },
  '85322': {
    country: 'US',
    city: 'Arlington',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3133,
    longitude: -112.7891,
    postal_code: '85322'
  },
  '85323': {
    country: 'US',
    city: 'Avondale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4321,
    longitude: -112.3438,
    postal_code: '85323'
  },
  '85324': {
    country: 'US',
    city: 'Black Canyon City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.0755,
    longitude: -112.134,
    postal_code: '85324'
  },
  '85325': {
    country: 'US',
    city: 'Bouse',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'La Paz',
    latitude: 33.9578,
    longitude: -114.0036,
    postal_code: '85325'
  },
  '85326': {
    country: 'US',
    city: 'Buckeye',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3703,
    longitude: -112.5838,
    postal_code: '85326'
  },
  '85327': {
    country: 'US',
    city: 'Cave Creek',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.8333,
    longitude: -111.9508,
    postal_code: '85327'
  },
  '85328': {
    country: 'US',
    city: 'Cibola',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'La Paz',
    latitude: 33.3164,
    longitude: -114.665,
    postal_code: '85328'
  },
  '85329': {
    country: 'US',
    city: 'Cashion',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4353,
    longitude: -112.2979,
    postal_code: '85329'
  },
  '85331': {
    country: 'US',
    city: 'Cave Creek',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.8851,
    longitude: -111.9349,
    postal_code: '85331'
  },
  '85332': {
    country: 'US',
    city: 'Congress',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.1764,
    longitude: -112.768,
    postal_code: '85332'
  },
  '85333': {
    country: 'US',
    city: 'Dateland',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.8679,
    longitude: -113.4631,
    postal_code: '85333'
  },
  '85334': {
    country: 'US',
    city: 'Ehrenberg',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'La Paz',
    latitude: 33.6177,
    longitude: -114.5077,
    postal_code: '85334'
  },
  '85335': {
    country: 'US',
    city: 'El Mirage',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6082,
    longitude: -112.3241,
    postal_code: '85335'
  },
  '85336': {
    country: 'US',
    city: 'Gadsden',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.5545,
    longitude: -114.785,
    postal_code: '85336'
  },
  '85337': {
    country: 'US',
    city: 'Gila Bend',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 32.9306,
    longitude: -112.7468,
    postal_code: '85337'
  },
  '85338': {
    country: 'US',
    city: 'Goodyear',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4368,
    longitude: -112.3834,
    postal_code: '85338'
  },
  '85339': {
    country: 'US',
    city: 'Laveen',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3436,
    longitude: -112.1716,
    postal_code: '85339'
  },
  '85340': {
    country: 'US',
    city: 'Litchfield Park',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5098,
    longitude: -112.4135,
    postal_code: '85340'
  },
  '85341': {
    country: 'US',
    city: 'Lukeville',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 31.8826,
    longitude: -112.8157,
    postal_code: '85341'
  },
  '85342': {
    country: 'US',
    city: 'Morristown',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.8567,
    longitude: -112.6232,
    postal_code: '85342'
  },
  '85343': {
    country: 'US',
    city: 'Palo Verde',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.3481,
    longitude: -112.6774,
    postal_code: '85343'
  },
  '85344': {
    country: 'US',
    city: 'Parker',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'La Paz',
    latitude: 34.15,
    longitude: -114.2891,
    postal_code: '85344'
  },
  '85345': {
    country: 'US',
    city: 'Peoria',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5761,
    longitude: -112.2344,
    postal_code: '85345'
  },
  '85346': {
    country: 'US',
    city: 'Quartzsite',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'La Paz',
    latitude: 33.7292,
    longitude: -114.1925,
    postal_code: '85346'
  },
  '85347': {
    country: 'US',
    city: 'Roll',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.7517,
    longitude: -113.9891,
    postal_code: '85347'
  },
  '85348': {
    country: 'US',
    city: 'Salome',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'La Paz',
    latitude: 33.7481,
    longitude: -113.5715,
    postal_code: '85348'
  },
  '85349': {
    country: 'US',
    city: 'San Luis',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.487,
    longitude: -114.7822,
    postal_code: '85349'
  },
  '85350': {
    country: 'US',
    city: 'Somerton',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.5634,
    longitude: -114.7127,
    postal_code: '85350'
  },
  '85351': {
    country: 'US',
    city: 'Sun City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6061,
    longitude: -112.2797,
    postal_code: '85351'
  },
  '85352': {
    country: 'US',
    city: 'Tacna',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.6934,
    longitude: -113.9763,
    postal_code: '85352'
  },
  '85353': {
    country: 'US',
    city: 'Tolleson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4347,
    longitude: -112.2774,
    postal_code: '85353'
  },
  '85354': {
    country: 'US',
    city: 'Tonopah',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4228,
    longitude: -112.9528,
    postal_code: '85354'
  },
  '85355': {
    country: 'US',
    city: 'Waddell',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5673,
    longitude: -112.4387,
    postal_code: '85355'
  },
  '85356': {
    country: 'US',
    city: 'Wellton',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.6935,
    longitude: -114.1561,
    postal_code: '85356'
  },
  '85357': {
    country: 'US',
    city: 'Wenden',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'La Paz',
    latitude: 34.0422,
    longitude: -113.458,
    postal_code: '85357'
  },
  '85358': {
    country: 'US',
    city: 'Wickenburg',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.9686,
    longitude: -112.7296,
    postal_code: '85358'
  },
  '85359': {
    country: 'US',
    city: 'Quartzsite',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'La Paz',
    latitude: 33.6669,
    longitude: -114.2396,
    postal_code: '85359'
  },
  '85360': {
    country: 'US',
    city: 'Wikieup',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 34.7033,
    longitude: -113.6113,
    postal_code: '85360'
  },
  '85361': {
    country: 'US',
    city: 'Wittmann',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.7637,
    longitude: -112.6142,
    postal_code: '85361'
  },
  '85362': {
    country: 'US',
    city: 'Yarnell',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.2508,
    longitude: -112.7567,
    postal_code: '85362'
  },
  '85363': {
    country: 'US',
    city: 'Youngtown',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5908,
    longitude: -112.3013,
    postal_code: '85363'
  },
  '85364': {
    country: 'US',
    city: 'Yuma',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.7015,
    longitude: -114.6424,
    postal_code: '85364'
  },
  '85365': {
    country: 'US',
    city: 'Yuma',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.7093,
    longitude: -114.4905,
    postal_code: '85365'
  },
  '85366': {
    country: 'US',
    city: 'Yuma',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.61,
    longitude: -114.6312,
    postal_code: '85366'
  },
  '85367': {
    country: 'US',
    city: 'Yuma',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.6566,
    longitude: -114.4042,
    postal_code: '85367'
  },
  '85369': {
    country: 'US',
    city: 'Yuma',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yuma',
    latitude: 32.7253,
    longitude: -114.6244,
    postal_code: '85369'
  },
  '85371': {
    country: 'US',
    city: 'Poston',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'La Paz',
    latitude: 33.9906,
    longitude: -114.3963,
    postal_code: '85371'
  },
  '85372': {
    country: 'US',
    city: 'Sun City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5975,
    longitude: -112.2718,
    postal_code: '85372'
  },
  '85373': {
    country: 'US',
    city: 'Sun City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6588,
    longitude: -112.3214,
    postal_code: '85373'
  },
  '85374': {
    country: 'US',
    city: 'Surprise',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.63,
    longitude: -112.3314,
    postal_code: '85374'
  },
  '85375': {
    country: 'US',
    city: 'Sun City West',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6856,
    longitude: -112.3659,
    postal_code: '85375'
  },
  '85376': {
    country: 'US',
    city: 'Sun City West',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.662,
    longitude: -112.3413,
    postal_code: '85376'
  },
  '85377': {
    country: 'US',
    city: 'Carefree',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.824,
    longitude: -111.913,
    postal_code: '85377'
  },
  '85378': {
    country: 'US',
    city: 'Surprise',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6306,
    longitude: -112.3332,
    postal_code: '85378'
  },
  '85379': {
    country: 'US',
    city: 'Surprise',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6021,
    longitude: -112.3736,
    postal_code: '85379'
  },
  '85380': {
    country: 'US',
    city: 'Peoria',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5806,
    longitude: -112.2374,
    postal_code: '85380'
  },
  '85381': {
    country: 'US',
    city: 'Peoria',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6048,
    longitude: -112.2237,
    postal_code: '85381'
  },
  '85382': {
    country: 'US',
    city: 'Peoria',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6308,
    longitude: -112.2072,
    postal_code: '85382'
  },
  '85383': {
    country: 'US',
    city: 'Peoria',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.7169,
    longitude: -112.238,
    postal_code: '85383'
  },
  '85385': {
    country: 'US',
    city: 'Peoria',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.5806,
    longitude: -112.2374,
    postal_code: '85385'
  },
  '85387': {
    country: 'US',
    city: 'Surprise',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.7129,
    longitude: -112.4375,
    postal_code: '85387'
  },
  '85388': {
    country: 'US',
    city: 'Surprise',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.6134,
    longitude: -112.4512,
    postal_code: '85388'
  },
  '85390': {
    country: 'US',
    city: 'Wickenburg',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.8944,
    longitude: -112.8603,
    postal_code: '85390'
  },
  '85392': {
    country: 'US',
    city: 'Avondale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.4777,
    longitude: -112.3093,
    postal_code: '85392'
  },
  '85395': {
    country: 'US',
    city: 'Goodyear',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.479,
    longitude: -112.3947,
    postal_code: '85395'
  },
  '85396': {
    country: 'US',
    city: 'Buckeye',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Maricopa',
    latitude: 33.468,
    longitude: -112.4787,
    postal_code: '85396'
  },
  '85501': {
    country: 'US',
    city: 'Globe',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 33.4024,
    longitude: -110.7892,
    postal_code: '85501'
  },
  '85502': {
    country: 'US',
    city: 'Globe',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 33.3942,
    longitude: -110.7865,
    postal_code: '85502'
  },
  '85530': {
    country: 'US',
    city: 'Bylas',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Graham',
    latitude: 33.1265,
    longitude: -110.117,
    postal_code: '85530'
  },
  '85531': {
    country: 'US',
    city: 'Central',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Graham',
    latitude: 32.8692,
    longitude: -109.7878,
    postal_code: '85531'
  },
  '85532': {
    country: 'US',
    city: 'Claypool',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 33.4154,
    longitude: -110.8149,
    postal_code: '85532'
  },
  '85533': {
    country: 'US',
    city: 'Clifton',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Greenlee',
    latitude: 33.1323,
    longitude: -109.2462,
    postal_code: '85533'
  },
  '85534': {
    country: 'US',
    city: 'Duncan',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Greenlee',
    latitude: 32.881,
    longitude: -109.2157,
    postal_code: '85534'
  },
  '85535': {
    country: 'US',
    city: 'Eden',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Graham',
    latitude: 32.9612,
    longitude: -109.8954,
    postal_code: '85535'
  },
  '85536': {
    country: 'US',
    city: 'Fort Thomas',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Graham',
    latitude: 33.0333,
    longitude: -109.9717,
    postal_code: '85536'
  },
  '85539': {
    country: 'US',
    city: 'Miami',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 33.3992,
    longitude: -110.8687,
    postal_code: '85539'
  },
  '85540': {
    country: 'US',
    city: 'Morenci',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Greenlee',
    latitude: 33.0787,
    longitude: -109.3654,
    postal_code: '85540'
  },
  '85541': {
    country: 'US',
    city: 'Payson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 34.2198,
    longitude: -111.2878,
    postal_code: '85541'
  },
  '85542': {
    country: 'US',
    city: 'Peridot',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 33.3103,
    longitude: -110.4554,
    postal_code: '85542'
  },
  '85543': {
    country: 'US',
    city: 'Pima',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Graham',
    latitude: 32.9097,
    longitude: -109.856,
    postal_code: '85543'
  },
  '85544': {
    country: 'US',
    city: 'Pine',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 34.3435,
    longitude: -111.5353,
    postal_code: '85544'
  },
  '85545': {
    country: 'US',
    city: 'Roosevelt',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 33.6358,
    longitude: -110.9749,
    postal_code: '85545'
  },
  '85546': {
    country: 'US',
    city: 'Safford',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Graham',
    latitude: 32.8295,
    longitude: -109.6266,
    postal_code: '85546'
  },
  '85547': {
    country: 'US',
    city: 'Payson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 34.2575,
    longitude: -111.2878,
    postal_code: '85547'
  },
  '85548': {
    country: 'US',
    city: 'Safford',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Graham',
    latitude: 32.797,
    longitude: -109.7522,
    postal_code: '85548'
  },
  '85550': {
    country: 'US',
    city: 'San Carlos',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 33.3456,
    longitude: -110.455,
    postal_code: '85550'
  },
  '85551': {
    country: 'US',
    city: 'Solomon',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Graham',
    latitude: 32.8126,
    longitude: -109.634,
    postal_code: '85551'
  },
  '85552': {
    country: 'US',
    city: 'Thatcher',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Graham',
    latitude: 32.8504,
    longitude: -109.7461,
    postal_code: '85552'
  },
  '85553': {
    country: 'US',
    city: 'Tonto Basin',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 33.8317,
    longitude: -111.2946,
    postal_code: '85553'
  },
  '85554': {
    country: 'US',
    city: 'Young',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Gila',
    latitude: 34.1014,
    longitude: -110.9637,
    postal_code: '85554'
  },
  '85601': {
    country: 'US',
    city: 'Arivaca',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 31.5885,
    longitude: -111.316,
    postal_code: '85601'
  },
  '85602': {
    country: 'US',
    city: 'Benson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.9883,
    longitude: -110.2941,
    postal_code: '85602'
  },
  '85603': {
    country: 'US',
    city: 'Bisbee',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.4086,
    longitude: -109.9117,
    postal_code: '85603'
  },
  '85605': {
    country: 'US',
    city: 'Bowie',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 32.3265,
    longitude: -109.487,
    postal_code: '85605'
  },
  '85606': {
    country: 'US',
    city: 'Cochise',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 32.0979,
    longitude: -109.9239,
    postal_code: '85606'
  },
  '85607': {
    country: 'US',
    city: 'Douglas',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.3511,
    longitude: -109.5447,
    postal_code: '85607'
  },
  '85608': {
    country: 'US',
    city: 'Douglas',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.4151,
    longitude: -109.596,
    postal_code: '85608'
  },
  '85609': {
    country: 'US',
    city: 'Dragoon',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 32.0281,
    longitude: -110.0387,
    postal_code: '85609'
  },
  '85610': {
    country: 'US',
    city: 'Elfrida',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.7139,
    longitude: -109.6193,
    postal_code: '85610'
  },
  '85611': {
    country: 'US',
    city: 'Elgin',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Santa Cruz',
    latitude: 31.6598,
    longitude: -110.5254,
    postal_code: '85611'
  },
  '85613': {
    country: 'US',
    city: 'Fort Huachuca',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.5587,
    longitude: -110.3441,
    postal_code: '85613'
  },
  '85614': {
    country: 'US',
    city: 'Green Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 31.8543,
    longitude: -111.0003,
    postal_code: '85614'
  },
  '85615': {
    country: 'US',
    city: 'Hereford',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.4035,
    longitude: -110.2047,
    postal_code: '85615'
  },
  '85616': {
    country: 'US',
    city: 'Huachuca City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.6639,
    longitude: -110.3334,
    postal_code: '85616'
  },
  '85617': {
    country: 'US',
    city: 'Mc Neal',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.5121,
    longitude: -109.8438,
    postal_code: '85617'
  },
  '85618': {
    country: 'US',
    city: 'Mammoth',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.7239,
    longitude: -110.644,
    postal_code: '85618'
  },
  '85619': {
    country: 'US',
    city: 'Mount Lemmon',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.3763,
    longitude: -110.7605,
    postal_code: '85619'
  },
  '85620': {
    country: 'US',
    city: 'Naco',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.3373,
    longitude: -109.9413,
    postal_code: '85620'
  },
  '85621': {
    country: 'US',
    city: 'Nogales',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Santa Cruz',
    latitude: 31.377,
    longitude: -110.9435,
    postal_code: '85621'
  },
  '85622': {
    country: 'US',
    city: 'Green Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 31.8269,
    longitude: -111.0755,
    postal_code: '85622'
  },
  '85623': {
    country: 'US',
    city: 'Oracle',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.6005,
    longitude: -110.7961,
    postal_code: '85623'
  },
  '85624': {
    country: 'US',
    city: 'Patagonia',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Santa Cruz',
    latitude: 31.5353,
    longitude: -110.6968,
    postal_code: '85624'
  },
  '85625': {
    country: 'US',
    city: 'Pearce',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.8885,
    longitude: -109.6119,
    postal_code: '85625'
  },
  '85626': {
    country: 'US',
    city: 'Pirtleville',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.3572,
    longitude: -109.5635,
    postal_code: '85626'
  },
  '85627': {
    country: 'US',
    city: 'Pomerene',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.9995,
    longitude: -110.2862,
    postal_code: '85627'
  },
  '85628': {
    country: 'US',
    city: 'Nogales',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Santa Cruz',
    latitude: 31.532,
    longitude: -110.9093,
    postal_code: '85628'
  },
  '85629': {
    country: 'US',
    city: 'Sahuarita',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 31.9452,
    longitude: -111.0002,
    postal_code: '85629'
  },
  '85630': {
    country: 'US',
    city: 'Saint David',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.8973,
    longitude: -110.2154,
    postal_code: '85630'
  },
  '85631': {
    country: 'US',
    city: 'San Manuel',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.6209,
    longitude: -110.5992,
    postal_code: '85631'
  },
  '85632': {
    country: 'US',
    city: 'San Simon',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 32.0423,
    longitude: -109.1748,
    postal_code: '85632'
  },
  '85633': {
    country: 'US',
    city: 'Sasabe',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 31.4887,
    longitude: -111.5421,
    postal_code: '85633'
  },
  '85634': {
    country: 'US',
    city: 'Sells',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.0315,
    longitude: -112.0065,
    postal_code: '85634'
  },
  '85635': {
    country: 'US',
    city: 'Sierra Vista',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.5365,
    longitude: -110.2666,
    postal_code: '85635'
  },
  '85636': {
    country: 'US',
    city: 'Sierra Vista',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.6687,
    longitude: -110.2801,
    postal_code: '85636'
  },
  '85637': {
    country: 'US',
    city: 'Sonoita',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Santa Cruz',
    latitude: 31.6731,
    longitude: -110.6188,
    postal_code: '85637'
  },
  '85638': {
    country: 'US',
    city: 'Tombstone',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.7216,
    longitude: -110.0584,
    postal_code: '85638'
  },
  '85639': {
    country: 'US',
    city: 'Topawa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 31.814,
    longitude: -111.8257,
    postal_code: '85639'
  },
  '85640': {
    country: 'US',
    city: 'Tumacacori',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Santa Cruz',
    latitude: 31.5696,
    longitude: -111.0534,
    postal_code: '85640'
  },
  '85641': {
    country: 'US',
    city: 'Vail',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.0027,
    longitude: -110.7053,
    postal_code: '85641'
  },
  '85643': {
    country: 'US',
    city: 'Willcox',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 32.373,
    longitude: -109.8631,
    postal_code: '85643'
  },
  '85644': {
    country: 'US',
    city: 'Willcox',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 32.2529,
    longitude: -109.832,
    postal_code: '85644'
  },
  '85645': {
    country: 'US',
    city: 'Amado',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Santa Cruz',
    latitude: 31.6723,
    longitude: -111.0986,
    postal_code: '85645'
  },
  '85646': {
    country: 'US',
    city: 'Tubac',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Santa Cruz',
    latitude: 31.6126,
    longitude: -111.0459,
    postal_code: '85646'
  },
  '85648': {
    country: 'US',
    city: 'Rio Rico',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Santa Cruz',
    latitude: 31.5084,
    longitude: -111.0757,
    postal_code: '85648'
  },
  '85650': {
    country: 'US',
    city: 'Sierra Vista',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.4892,
    longitude: -110.2153,
    postal_code: '85650'
  },
  '85652': {
    country: 'US',
    city: 'Cortaro',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.4201,
    longitude: -111.1132,
    postal_code: '85652'
  },
  '85653': {
    country: 'US',
    city: 'Marana',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.4047,
    longitude: -111.2736,
    postal_code: '85653'
  },
  '85654': {
    country: 'US',
    city: 'Rillito',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.4148,
    longitude: -111.1562,
    postal_code: '85654'
  },
  '85655': {
    country: 'US',
    city: 'Douglas',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.3447,
    longitude: -109.5468,
    postal_code: '85655'
  },
  '85658': {
    country: 'US',
    city: 'Marana',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pinal',
    latitude: 32.4305,
    longitude: -111.1459,
    postal_code: '85658'
  },
  '85662': {
    country: 'US',
    city: 'Nogales',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Santa Cruz',
    latitude: 31.532,
    longitude: -110.9093,
    postal_code: '85662'
  },
  '85670': {
    country: 'US',
    city: 'Fort Huachuca',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.8801,
    longitude: -109.7543,
    postal_code: '85670'
  },
  '85671': {
    country: 'US',
    city: 'Sierra Vista',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Cochise',
    latitude: 31.5545,
    longitude: -110.3037,
    postal_code: '85671'
  },
  '85701': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2139,
    longitude: -110.9694,
    postal_code: '85701'
  },
  '85702': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85702'
  },
  '85703': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85703'
  },
  '85704': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.3379,
    longitude: -110.9855,
    postal_code: '85704'
  },
  '85705': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2691,
    longitude: -110.9845,
    postal_code: '85705'
  },
  '85706': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.1392,
    longitude: -110.9451,
    postal_code: '85706'
  },
  '85707': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.1696,
    longitude: -110.8751,
    postal_code: '85707'
  },
  '85708': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.18,
    longitude: -110.8693,
    postal_code: '85708'
  },
  '85709': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2008,
    longitude: -110.898,
    postal_code: '85709'
  },
  '85710': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2138,
    longitude: -110.824,
    postal_code: '85710'
  },
  '85711': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2127,
    longitude: -110.8829,
    postal_code: '85711'
  },
  '85712': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.25,
    longitude: -110.8869,
    postal_code: '85712'
  },
  '85713': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.1941,
    longitude: -110.9739,
    postal_code: '85713'
  },
  '85714': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.1707,
    longitude: -110.9719,
    postal_code: '85714'
  },
  '85715': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2519,
    longitude: -110.82,
    postal_code: '85715'
  },
  '85716': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2468,
    longitude: -110.9222,
    postal_code: '85716'
  },
  '85717': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85717'
  },
  '85718': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.3112,
    longitude: -110.9179,
    postal_code: '85718'
  },
  '85719': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2474,
    longitude: -110.9491,
    postal_code: '85719'
  },
  '85720': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85720'
  },
  '85721': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2338,
    longitude: -110.95,
    postal_code: '85721'
  },
  '85722': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85722'
  },
  '85723': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85723'
  },
  '85724': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2406,
    longitude: -110.9443,
    postal_code: '85724'
  },
  '85725': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85725'
  },
  '85726': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2027,
    longitude: -110.9453,
    postal_code: '85726'
  },
  '85728': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85728'
  },
  '85730': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.181,
    longitude: -110.819,
    postal_code: '85730'
  },
  '85731': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85731'
  },
  '85732': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85732'
  },
  '85733': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85733'
  },
  '85734': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.0651,
    longitude: -110.9353,
    postal_code: '85734'
  },
  '85735': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.093,
    longitude: -111.3472,
    postal_code: '85735'
  },
  '85736': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 31.9011,
    longitude: -111.3702,
    postal_code: '85736'
  },
  '85737': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.4142,
    longitude: -110.9466,
    postal_code: '85737'
  },
  '85738': {
    country: 'US',
    city: 'Catalina',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.5056,
    longitude: -110.9211,
    postal_code: '85738'
  },
  '85739': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.465,
    longitude: -110.8922,
    postal_code: '85739'
  },
  '85740': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85740'
  },
  '85741': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.3472,
    longitude: -111.0419,
    postal_code: '85741'
  },
  '85742': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.4076,
    longitude: -111.065,
    postal_code: '85742'
  },
  '85743': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.3366,
    longitude: -111.1771,
    postal_code: '85743'
  },
  '85744': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85744'
  },
  '85745': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2434,
    longitude: -111.0179,
    postal_code: '85745'
  },
  '85746': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.1422,
    longitude: -111.0506,
    postal_code: '85746'
  },
  '85747': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.0984,
    longitude: -110.7272,
    postal_code: '85747'
  },
  '85748': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.215,
    longitude: -110.7758,
    postal_code: '85748'
  },
  '85749': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2733,
    longitude: -110.7658,
    postal_code: '85749'
  },
  '85750': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2974,
    longitude: -110.8404,
    postal_code: '85750'
  },
  '85751': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85751'
  },
  '85752': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85752'
  },
  '85754': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85754'
  },
  '85755': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.4227,
    longitude: -110.9768,
    postal_code: '85755'
  },
  '85756': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.0839,
    longitude: -110.9004,
    postal_code: '85756'
  },
  '85757': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.1336,
    longitude: -111.0973,
    postal_code: '85757'
  },
  '85775': {
    country: 'US',
    city: 'Tucson',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Pima',
    latitude: 32.2217,
    longitude: -110.9265,
    postal_code: '85775'
  },
  '85901': {
    country: 'US',
    city: 'Show Low',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.2998,
    longitude: -110,
    postal_code: '85901'
  },
  '85902': {
    country: 'US',
    city: 'Show Low',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.2981,
    longitude: -110.0352,
    postal_code: '85902'
  },
  '85911': {
    country: 'US',
    city: 'Cibecue',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.0448,
    longitude: -110.4854,
    postal_code: '85911'
  },
  '85912': {
    country: 'US',
    city: 'White Mountain Lake',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.2666,
    longitude: -110.2031,
    postal_code: '85912'
  },
  '85920': {
    country: 'US',
    city: 'Alpine',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 33.8279,
    longitude: -109.1283,
    postal_code: '85920'
  },
  '85922': {
    country: 'US',
    city: 'Blue',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Greenlee',
    latitude: 33.6512,
    longitude: -109.0685,
    postal_code: '85922'
  },
  '85923': {
    country: 'US',
    city: 'Clay Springs',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.3617,
    longitude: -110.2954,
    postal_code: '85923'
  },
  '85924': {
    country: 'US',
    city: 'Concho',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 34.4458,
    longitude: -109.6741,
    postal_code: '85924'
  },
  '85925': {
    country: 'US',
    city: 'Eagar',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 34.1077,
    longitude: -109.294,
    postal_code: '85925'
  },
  '85926': {
    country: 'US',
    city: 'Fort Apache',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 33.7906,
    longitude: -109.9887,
    postal_code: '85926'
  },
  '85927': {
    country: 'US',
    city: 'Greer',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 34.0052,
    longitude: -109.4664,
    postal_code: '85927'
  },
  '85928': {
    country: 'US',
    city: 'Heber',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.41,
    longitude: -110.5883,
    postal_code: '85928'
  },
  '85929': {
    country: 'US',
    city: 'Lakeside',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.1662,
    longitude: -109.9869,
    postal_code: '85929'
  },
  '85930': {
    country: 'US',
    city: 'Mcnary',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 34.0754,
    longitude: -109.8532,
    postal_code: '85930'
  },
  '85931': {
    country: 'US',
    city: 'Forest Lakes',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 34.4513,
    longitude: -110.8644,
    postal_code: '85931'
  },
  '85932': {
    country: 'US',
    city: 'Nutrioso',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 33.9531,
    longitude: -109.2092,
    postal_code: '85932'
  },
  '85933': {
    country: 'US',
    city: 'Overgaard',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.4086,
    longitude: -110.56,
    postal_code: '85933'
  },
  '85934': {
    country: 'US',
    city: 'Pinedale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.3003,
    longitude: -110.2457,
    postal_code: '85934'
  },
  '85935': {
    country: 'US',
    city: 'Pinetop',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.1175,
    longitude: -109.9197,
    postal_code: '85935'
  },
  '85936': {
    country: 'US',
    city: 'Saint Johns',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 34.501,
    longitude: -109.3796,
    postal_code: '85936'
  },
  '85937': {
    country: 'US',
    city: 'Snowflake',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.4959,
    longitude: -110.0807,
    postal_code: '85937'
  },
  '85938': {
    country: 'US',
    city: 'Springerville',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 34.1702,
    longitude: -109.3315,
    postal_code: '85938'
  },
  '85939': {
    country: 'US',
    city: 'Taylor',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.439,
    longitude: -110.0858,
    postal_code: '85939'
  },
  '85940': {
    country: 'US',
    city: 'Vernon',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 34.2575,
    longitude: -109.6929,
    postal_code: '85940'
  },
  '85941': {
    country: 'US',
    city: 'Whiteriver',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 33.8021,
    longitude: -109.9937,
    postal_code: '85941'
  },
  '85942': {
    country: 'US',
    city: 'Woodruff',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.7814,
    longitude: -110.0435,
    postal_code: '85942'
  },
  '86001': {
    country: 'US',
    city: 'Flagstaff',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.1859,
    longitude: -111.662,
    postal_code: '86001'
  },
  '86002': {
    country: 'US',
    city: 'Flagstaff',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.1981,
    longitude: -111.6513,
    postal_code: '86002'
  },
  '86003': {
    country: 'US',
    city: 'Flagstaff',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.1981,
    longitude: -111.6513,
    postal_code: '86003'
  },
  '86004': {
    country: 'US',
    city: 'Flagstaff',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.2257,
    longitude: -111.5741,
    postal_code: '86004'
  },
  '86005': {
    country: 'US',
    city: 'Flagstaff',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.1545,
    longitude: -111.6789,
    postal_code: '86005'
  },
  '86011': {
    country: 'US',
    city: 'Flagstaff',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.1981,
    longitude: -111.6513,
    postal_code: '86011'
  },
  '86015': {
    country: 'US',
    city: 'Bellemont',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.2381,
    longitude: -111.8335,
    postal_code: '86015'
  },
  '86016': {
    country: 'US',
    city: 'Gray Mountain',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.7504,
    longitude: -111.6188,
    postal_code: '86016'
  },
  '86017': {
    country: 'US',
    city: 'Munds Park',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 34.9412,
    longitude: -111.641,
    postal_code: '86017'
  },
  '86018': {
    country: 'US',
    city: 'Parks',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.2563,
    longitude: -111.95,
    postal_code: '86018'
  },
  '86020': {
    country: 'US',
    city: 'Cameron',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.9922,
    longitude: -111.5038,
    postal_code: '86020'
  },
  '86021': {
    country: 'US',
    city: 'Colorado City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 36.9903,
    longitude: -112.9758,
    postal_code: '86021'
  },
  '86022': {
    country: 'US',
    city: 'Fredonia',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 36.9044,
    longitude: -112.4979,
    postal_code: '86022'
  },
  '86023': {
    country: 'US',
    city: 'Grand Canyon',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.9421,
    longitude: -112.1309,
    postal_code: '86023'
  },
  '86024': {
    country: 'US',
    city: 'Happy Jack',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 34.6616,
    longitude: -111.3404,
    postal_code: '86024'
  },
  '86025': {
    country: 'US',
    city: 'Holbrook',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.9085,
    longitude: -110.1434,
    postal_code: '86025'
  },
  '86028': {
    country: 'US',
    city: 'Petrified Forest Natl Pk',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.2375,
    longitude: -109.5229,
    postal_code: '86028'
  },
  '86029': {
    country: 'US',
    city: 'Sun Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.9806,
    longitude: -110.0582,
    postal_code: '86029'
  },
  '86030': {
    country: 'US',
    city: 'Hotevilla',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 35.9278,
    longitude: -110.6729,
    postal_code: '86030'
  },
  '86031': {
    country: 'US',
    city: 'Indian Wells',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 35.4056,
    longitude: -110.0848,
    postal_code: '86031'
  },
  '86032': {
    country: 'US',
    city: 'Joseph City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 34.9814,
    longitude: -110.3373,
    postal_code: '86032'
  },
  '86033': {
    country: 'US',
    city: 'Kayenta',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 36.6883,
    longitude: -110.2652,
    postal_code: '86033'
  },
  '86034': {
    country: 'US',
    city: 'Keams Canyon',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 35.8082,
    longitude: -110.2845,
    postal_code: '86034'
  },
  '86035': {
    country: 'US',
    city: 'Leupp',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.3365,
    longitude: -110.9927,
    postal_code: '86035'
  },
  '86036': {
    country: 'US',
    city: 'Marble Canyon',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 36.737,
    longitude: -111.8229,
    postal_code: '86036'
  },
  '86038': {
    country: 'US',
    city: 'Mormon Lake',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 34.9083,
    longitude: -111.463,
    postal_code: '86038'
  },
  '86039': {
    country: 'US',
    city: 'Kykotsmovi Village',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 35.8742,
    longitude: -110.6235,
    postal_code: '86039'
  },
  '86040': {
    country: 'US',
    city: 'Page',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 36.9147,
    longitude: -111.4558,
    postal_code: '86040'
  },
  '86042': {
    country: 'US',
    city: 'Polacca',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 35.8617,
    longitude: -110.3875,
    postal_code: '86042'
  },
  '86043': {
    country: 'US',
    city: 'Second Mesa',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 35.7933,
    longitude: -110.5051,
    postal_code: '86043'
  },
  '86044': {
    country: 'US',
    city: 'Tonalea',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 36.7093,
    longitude: -110.8411,
    postal_code: '86044'
  },
  '86045': {
    country: 'US',
    city: 'Tuba City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 36.1037,
    longitude: -111.2686,
    postal_code: '86045'
  },
  '86046': {
    country: 'US',
    city: 'Williams',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 35.5434,
    longitude: -112.1707,
    postal_code: '86046'
  },
  '86047': {
    country: 'US',
    city: 'Winslow',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 35.0242,
    longitude: -110.6974,
    postal_code: '86047'
  },
  '86052': {
    country: 'US',
    city: 'North Rim',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 36.2105,
    longitude: -112.0613,
    postal_code: '86052'
  },
  '86053': {
    country: 'US',
    city: 'Kaibeto',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 36.4025,
    longitude: -111.3518,
    postal_code: '86053'
  },
  '86054': {
    country: 'US',
    city: 'Shonto',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 36.6159,
    longitude: -110.6477,
    postal_code: '86054'
  },
  '86301': {
    country: 'US',
    city: 'Prescott',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.5917,
    longitude: -112.4265,
    postal_code: '86301'
  },
  '86302': {
    country: 'US',
    city: 'Prescott',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.5749,
    longitude: -112.4915,
    postal_code: '86302'
  },
  '86303': {
    country: 'US',
    city: 'Prescott',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.4958,
    longitude: -112.3783,
    postal_code: '86303'
  },
  '86304': {
    country: 'US',
    city: 'Prescott',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.5967,
    longitude: -112.4907,
    postal_code: '86304'
  },
  '86305': {
    country: 'US',
    city: 'Prescott',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.8185,
    longitude: -112.9584,
    postal_code: '86305'
  },
  '86312': {
    country: 'US',
    city: 'Prescott Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.6683,
    longitude: -112.3078,
    postal_code: '86312'
  },
  '86313': {
    country: 'US',
    city: 'Prescott',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.7067,
    longitude: -112.3977,
    postal_code: '86313'
  },
  '86314': {
    country: 'US',
    city: 'Prescott Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.6019,
    longitude: -112.3264,
    postal_code: '86314'
  },
  '86315': {
    country: 'US',
    city: 'Prescott Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.7055,
    longitude: -112.2639,
    postal_code: '86315'
  },
  '86320': {
    country: 'US',
    city: 'Ash Fork',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 35.215,
    longitude: -112.5027,
    postal_code: '86320'
  },
  '86321': {
    country: 'US',
    city: 'Bagdad',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.5785,
    longitude: -113.1755,
    postal_code: '86321'
  },
  '86322': {
    country: 'US',
    city: 'Camp Verde',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.5697,
    longitude: -111.8551,
    postal_code: '86322'
  },
  '86323': {
    country: 'US',
    city: 'Chino Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.7757,
    longitude: -112.4731,
    postal_code: '86323'
  },
  '86324': {
    country: 'US',
    city: 'Clarkdale',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.7711,
    longitude: -112.0579,
    postal_code: '86324'
  },
  '86325': {
    country: 'US',
    city: 'Cornville',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.7256,
    longitude: -111.9086,
    postal_code: '86325'
  },
  '86326': {
    country: 'US',
    city: 'Cottonwood',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.7055,
    longitude: -112.0091,
    postal_code: '86326'
  },
  '86327': {
    country: 'US',
    city: 'Dewey',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.5368,
    longitude: -112.2567,
    postal_code: '86327'
  },
  '86329': {
    country: 'US',
    city: 'Humboldt',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.5009,
    longitude: -112.2374,
    postal_code: '86329'
  },
  '86331': {
    country: 'US',
    city: 'Jerome',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.7486,
    longitude: -112.1086,
    postal_code: '86331'
  },
  '86332': {
    country: 'US',
    city: 'Kirkland',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.4541,
    longitude: -112.8966,
    postal_code: '86332'
  },
  '86333': {
    country: 'US',
    city: 'Mayer',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.3655,
    longitude: -112.1296,
    postal_code: '86333'
  },
  '86334': {
    country: 'US',
    city: 'Paulden',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 35.0313,
    longitude: -112.5441,
    postal_code: '86334'
  },
  '86335': {
    country: 'US',
    city: 'Rimrock',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.638,
    longitude: -111.7842,
    postal_code: '86335'
  },
  '86336': {
    country: 'US',
    city: 'Sedona',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 34.8266,
    longitude: -111.7506,
    postal_code: '86336'
  },
  '86337': {
    country: 'US',
    city: 'Seligman',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 35.3212,
    longitude: -112.9548,
    postal_code: '86337'
  },
  '86338': {
    country: 'US',
    city: 'Skull Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.5053,
    longitude: -112.6855,
    postal_code: '86338'
  },
  '86339': {
    country: 'US',
    city: 'Sedona',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 34.8697,
    longitude: -111.761,
    postal_code: '86339'
  },
  '86340': {
    country: 'US',
    city: 'Sedona',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.7067,
    longitude: -112.3977,
    postal_code: '86340'
  },
  '86341': {
    country: 'US',
    city: 'Sedona',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.7766,
    longitude: -111.7679,
    postal_code: '86341'
  },
  '86342': {
    country: 'US',
    city: 'Lake Montezuma',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.6417,
    longitude: -111.7872,
    postal_code: '86342'
  },
  '86343': {
    country: 'US',
    city: 'Crown King',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Yavapai',
    latitude: 34.2241,
    longitude: -112.334,
    postal_code: '86343'
  },
  '86351': {
    country: 'US',
    city: 'Sedona',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 34.7784,
    longitude: -111.7851,
    postal_code: '86351'
  },
  '86401': {
    country: 'US',
    city: 'Kingman',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.1328,
    longitude: -113.7033,
    postal_code: '86401'
  },
  '86402': {
    country: 'US',
    city: 'Kingman',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.2632,
    longitude: -114.0637,
    postal_code: '86402'
  },
  '86403': {
    country: 'US',
    city: 'Lake Havasu City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 34.4814,
    longitude: -114.3483,
    postal_code: '86403'
  },
  '86404': {
    country: 'US',
    city: 'Lake Havasu City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 34.5575,
    longitude: -114.3307,
    postal_code: '86404'
  },
  '86405': {
    country: 'US',
    city: 'Lake Havasu City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 34.4839,
    longitude: -114.3225,
    postal_code: '86405'
  },
  '86406': {
    country: 'US',
    city: 'Lake Havasu City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 34.4228,
    longitude: -114.125,
    postal_code: '86406'
  },
  '86409': {
    country: 'US',
    city: 'Kingman',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.2633,
    longitude: -114.0223,
    postal_code: '86409'
  },
  '86411': {
    country: 'US',
    city: 'Hackberry',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.3692,
    longitude: -113.7272,
    postal_code: '86411'
  },
  '86412': {
    country: 'US',
    city: 'Hualapai',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.3972,
    longitude: -113.8432,
    postal_code: '86412'
  },
  '86413': {
    country: 'US',
    city: 'Golden Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.3002,
    longitude: -114.2215,
    postal_code: '86413'
  },
  '86426': {
    country: 'US',
    city: 'Fort Mohave',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.0052,
    longitude: -114.5687,
    postal_code: '86426'
  },
  '86427': {
    country: 'US',
    city: 'Fort Mohave',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.0043,
    longitude: -114.5812,
    postal_code: '86427'
  },
  '86429': {
    country: 'US',
    city: 'Bullhead City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.1715,
    longitude: -114.5386,
    postal_code: '86429'
  },
  '86430': {
    country: 'US',
    city: 'Bullhead City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.1473,
    longitude: -114.5433,
    postal_code: '86430'
  },
  '86431': {
    country: 'US',
    city: 'Chloride',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.3896,
    longitude: -114.2221,
    postal_code: '86431'
  },
  '86432': {
    country: 'US',
    city: 'Littlefield',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 36.8872,
    longitude: -113.9297,
    postal_code: '86432'
  },
  '86433': {
    country: 'US',
    city: 'Oatman',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.0285,
    longitude: -114.3837,
    postal_code: '86433'
  },
  '86434': {
    country: 'US',
    city: 'Peach Springs',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.5378,
    longitude: -113.4202,
    postal_code: '86434'
  },
  '86435': {
    country: 'US',
    city: 'Supai',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Coconino',
    latitude: 36.2242,
    longitude: -112.6932,
    postal_code: '86435'
  },
  '86436': {
    country: 'US',
    city: 'Topock',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 34.7784,
    longitude: -114.4817,
    postal_code: '86436'
  },
  '86437': {
    country: 'US',
    city: 'Valentine',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.3788,
    longitude: -113.6141,
    postal_code: '86437'
  },
  '86438': {
    country: 'US',
    city: 'Yucca',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 34.8722,
    longitude: -114.1494,
    postal_code: '86438'
  },
  '86439': {
    country: 'US',
    city: 'Bullhead City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.1478,
    longitude: -114.5683,
    postal_code: '86439'
  },
  '86440': {
    country: 'US',
    city: 'Mohave Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 34.8929,
    longitude: -114.5951,
    postal_code: '86440'
  },
  '86441': {
    country: 'US',
    city: 'Dolan Springs',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.5692,
    longitude: -114.3777,
    postal_code: '86441'
  },
  '86442': {
    country: 'US',
    city: 'Bullhead City',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.106,
    longitude: -114.5947,
    postal_code: '86442'
  },
  '86443': {
    country: 'US',
    city: 'Temple Bar Marina',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.2106,
    longitude: -114.1792,
    postal_code: '86443'
  },
  '86444': {
    country: 'US',
    city: 'Meadview',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.9822,
    longitude: -114.0775,
    postal_code: '86444'
  },
  '86445': {
    country: 'US',
    city: 'Willow Beach',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 35.7759,
    longitude: -114.5001,
    postal_code: '86445'
  },
  '86446': {
    country: 'US',
    city: 'Mohave Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Mohave',
    latitude: 34.8499,
    longitude: -114.5917,
    postal_code: '86446'
  },
  '86502': {
    country: 'US',
    city: 'Chambers',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.2375,
    longitude: -109.5229,
    postal_code: '86502'
  },
  '86503': {
    country: 'US',
    city: 'Chinle',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 36.1304,
    longitude: -109.6037,
    postal_code: '86503'
  },
  '86504': {
    country: 'US',
    city: 'Fort Defiance',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.7445,
    longitude: -109.0765,
    postal_code: '86504'
  },
  '86505': {
    country: 'US',
    city: 'Ganado',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.7114,
    longitude: -109.542,
    postal_code: '86505'
  },
  '86506': {
    country: 'US',
    city: 'Houck',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.2831,
    longitude: -109.207,
    postal_code: '86506'
  },
  '86507': {
    country: 'US',
    city: 'Lukachukai',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 36.4181,
    longitude: -109.2446,
    postal_code: '86507'
  },
  '86508': {
    country: 'US',
    city: 'Lupton',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.3539,
    longitude: -109.0537,
    postal_code: '86508'
  },
  '86510': {
    country: 'US',
    city: 'Pinon',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 36.1002,
    longitude: -110.2211,
    postal_code: '86510'
  },
  '86511': {
    country: 'US',
    city: 'Saint Michaels',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.6447,
    longitude: -109.0956,
    postal_code: '86511'
  },
  '86512': {
    country: 'US',
    city: 'Sanders',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.2164,
    longitude: -109.3337,
    postal_code: '86512'
  },
  '86514': {
    country: 'US',
    city: 'Teec Nos Pos',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 36.7797,
    longitude: -109.359,
    postal_code: '86514'
  },
  '86515': {
    country: 'US',
    city: 'Window Rock',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.6676,
    longitude: -109.0784,
    postal_code: '86515'
  },
  '86520': {
    country: 'US',
    city: 'Blue Gap',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Navajo',
    latitude: 36.1708,
    longitude: -109.9465,
    postal_code: '86520'
  },
  '86535': {
    country: 'US',
    city: 'Dennehotso',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 36.7773,
    longitude: -109.861,
    postal_code: '86535'
  },
  '86538': {
    country: 'US',
    city: 'Many Farms',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 36.4083,
    longitude: -109.634,
    postal_code: '86538'
  },
  '86540': {
    country: 'US',
    city: 'Nazlini',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.8964,
    longitude: -109.4487,
    postal_code: '86540'
  },
  '86544': {
    country: 'US',
    city: 'Red Valley',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 35.2375,
    longitude: -109.5229,
    postal_code: '86544'
  },
  '86545': {
    country: 'US',
    city: 'Rock Point',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 36.7181,
    longitude: -109.6259,
    postal_code: '86545'
  },
  '86547': {
    country: 'US',
    city: 'Round Rock',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 36.5131,
    longitude: -109.4734,
    postal_code: '86547'
  },
  '86556': {
    country: 'US',
    city: 'Tsaile',
    state: 'Arizona',
    state_short: 'AZ',
    county: 'Apache',
    latitude: 36.3071,
    longitude: -109.2176,
    postal_code: '86556'
  },
  '87001': {
    country: 'US',
    city: 'Algodones',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.389,
    longitude: -106.3517,
    postal_code: '87001'
  },
  '87002': {
    country: 'US',
    city: 'Belen',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Valencia',
    latitude: 34.6511,
    longitude: -106.6952,
    postal_code: '87002'
  },
  '87004': {
    country: 'US',
    city: 'Bernalillo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.3285,
    longitude: -106.5309,
    postal_code: '87004'
  },
  '87005': {
    country: 'US',
    city: 'Bluewater',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 35.255,
    longitude: -107.9856,
    postal_code: '87005'
  },
  '87006': {
    country: 'US',
    city: 'Bosque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Valencia',
    latitude: 34.4996,
    longitude: -106.8038,
    postal_code: '87006'
  },
  '87007': {
    country: 'US',
    city: 'Casa Blanca',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 35.0456,
    longitude: -107.4714,
    postal_code: '87007'
  },
  '87008': {
    country: 'US',
    city: 'Cedar Crest',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1285,
    longitude: -106.3617,
    postal_code: '87008'
  },
  '87009': {
    country: 'US',
    city: 'Cedarvale',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Torrance',
    latitude: 34.3706,
    longitude: -105.702,
    postal_code: '87009'
  },
  '87010': {
    country: 'US',
    city: 'Cerrillos',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.423,
    longitude: -106.1317,
    postal_code: '87010'
  },
  '87011': {
    country: 'US',
    city: 'Claunch',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Socorro',
    latitude: 34.0646,
    longitude: -105.9496,
    postal_code: '87011'
  },
  '87012': {
    country: 'US',
    city: 'Coyote',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.1018,
    longitude: -106.6692,
    postal_code: '87012'
  },
  '87013': {
    country: 'US',
    city: 'Cuba',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 36.0482,
    longitude: -107.1885,
    postal_code: '87013'
  },
  '87014': {
    country: 'US',
    city: 'Cubero',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 35.0859,
    longitude: -107.5181,
    postal_code: '87014'
  },
  '87015': {
    country: 'US',
    city: 'Edgewood',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.0776,
    longitude: -106.1872,
    postal_code: '87015'
  },
  '87016': {
    country: 'US',
    city: 'Estancia',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Torrance',
    latitude: 34.77,
    longitude: -106.135,
    postal_code: '87016'
  },
  '87017': {
    country: 'US',
    city: 'Gallina',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.3202,
    longitude: -106.7687,
    postal_code: '87017'
  },
  '87018': {
    country: 'US',
    city: 'Counselor',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 36.2092,
    longitude: -107.4578,
    postal_code: '87018'
  },
  '87020': {
    country: 'US',
    city: 'Grants',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 35.1476,
    longitude: -107.8526,
    postal_code: '87020'
  },
  '87021': {
    country: 'US',
    city: 'Milan',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 35.1698,
    longitude: -107.8909,
    postal_code: '87021'
  },
  '87022': {
    country: 'US',
    city: 'Isleta',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 34.9073,
    longitude: -106.6892,
    postal_code: '87022'
  },
  '87023': {
    country: 'US',
    city: 'Jarales',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Valencia',
    latitude: 34.592,
    longitude: -106.7611,
    postal_code: '87023'
  },
  '87024': {
    country: 'US',
    city: 'Jemez Pueblo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.6243,
    longitude: -106.7219,
    postal_code: '87024'
  },
  '87025': {
    country: 'US',
    city: 'Jemez Springs',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.8927,
    longitude: -106.7714,
    postal_code: '87025'
  },
  '87026': {
    country: 'US',
    city: 'Laguna',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 34.9625,
    longitude: -107.2325,
    postal_code: '87026'
  },
  '87027': {
    country: 'US',
    city: 'La Jara',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 36.1194,
    longitude: -106.9923,
    postal_code: '87027'
  },
  '87028': {
    country: 'US',
    city: 'La Joya',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Socorro',
    latitude: 34.3813,
    longitude: -106.815,
    postal_code: '87028'
  },
  '87029': {
    country: 'US',
    city: 'Lindrith',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.3249,
    longitude: -107.0495,
    postal_code: '87029'
  },
  '87031': {
    country: 'US',
    city: 'Los Lunas',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Valencia',
    latitude: 34.7806,
    longitude: -106.7115,
    postal_code: '87031'
  },
  '87032': {
    country: 'US',
    city: 'Mcintosh',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Torrance',
    latitude: 34.8648,
    longitude: -106.0517,
    postal_code: '87032'
  },
  '87034': {
    country: 'US',
    city: 'Pueblo Of Acoma',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Valencia',
    latitude: 34.8964,
    longitude: -107.5817,
    postal_code: '87034'
  },
  '87035': {
    country: 'US',
    city: 'Moriarty',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Torrance',
    latitude: 34.9889,
    longitude: -106.0609,
    postal_code: '87035'
  },
  '87036': {
    country: 'US',
    city: 'Mountainair',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Torrance',
    latitude: 34.5158,
    longitude: -106.2577,
    postal_code: '87036'
  },
  '87037': {
    country: 'US',
    city: 'Nageezi',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.1598,
    longitude: -107.7664,
    postal_code: '87037'
  },
  '87038': {
    country: 'US',
    city: 'New Laguna',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 35.0409,
    longitude: -107.422,
    postal_code: '87038'
  },
  '87040': {
    country: 'US',
    city: 'Paguate',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 35.1387,
    longitude: -107.3789,
    postal_code: '87040'
  },
  '87041': {
    country: 'US',
    city: 'Pena Blanca',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.5709,
    longitude: -106.3381,
    postal_code: '87041'
  },
  '87042': {
    country: 'US',
    city: 'Peralta',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Valencia',
    latitude: 34.8332,
    longitude: -106.6872,
    postal_code: '87042'
  },
  '87043': {
    country: 'US',
    city: 'Placitas',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.3092,
    longitude: -106.5293,
    postal_code: '87043'
  },
  '87044': {
    country: 'US',
    city: 'Ponderosa',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.66,
    longitude: -106.6675,
    postal_code: '87044'
  },
  '87045': {
    country: 'US',
    city: 'Prewitt',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.3547,
    longitude: -108.1038,
    postal_code: '87045'
  },
  '87046': {
    country: 'US',
    city: 'Regina',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 36.1842,
    longitude: -106.9567,
    postal_code: '87046'
  },
  '87047': {
    country: 'US',
    city: 'Sandia Park',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1683,
    longitude: -106.3238,
    postal_code: '87047'
  },
  '87048': {
    country: 'US',
    city: 'Corrales',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.2339,
    longitude: -106.62,
    postal_code: '87048'
  },
  '87049': {
    country: 'US',
    city: 'San Fidel',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 35.0823,
    longitude: -107.5992,
    postal_code: '87049'
  },
  '87051': {
    country: 'US',
    city: 'San Rafael',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 35.1125,
    longitude: -107.8826,
    postal_code: '87051'
  },
  '87052': {
    country: 'US',
    city: 'Santo Domingo Pueblo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.5269,
    longitude: -106.3445,
    postal_code: '87052'
  },
  '87053': {
    country: 'US',
    city: 'San Ysidro',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.5634,
    longitude: -106.7706,
    postal_code: '87053'
  },
  '87056': {
    country: 'US',
    city: 'Stanley',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.1295,
    longitude: -106.0283,
    postal_code: '87056'
  },
  '87059': {
    country: 'US',
    city: 'Tijeras',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0446,
    longitude: -106.3062,
    postal_code: '87059'
  },
  '87060': {
    country: 'US',
    city: 'Tome',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Valencia',
    latitude: 34.7426,
    longitude: -106.7328,
    postal_code: '87060'
  },
  '87061': {
    country: 'US',
    city: 'Torreon',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Torrance',
    latitude: 34.6948,
    longitude: -106.3247,
    postal_code: '87061'
  },
  '87062': {
    country: 'US',
    city: 'Veguita',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Socorro',
    latitude: 34.485,
    longitude: -106.7591,
    postal_code: '87062'
  },
  '87063': {
    country: 'US',
    city: 'Willard',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Torrance',
    latitude: 34.5754,
    longitude: -106.0307,
    postal_code: '87063'
  },
  '87064': {
    country: 'US',
    city: 'Youngsville',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.1875,
    longitude: -106.5564,
    postal_code: '87064'
  },
  '87068': {
    country: 'US',
    city: 'Bosque Farms',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Valencia',
    latitude: 34.8764,
    longitude: -106.6975,
    postal_code: '87068'
  },
  '87070': {
    country: 'US',
    city: 'Clines Corners',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Torrance',
    latitude: 35.0095,
    longitude: -105.6692,
    postal_code: '87070'
  },
  '87072': {
    country: 'US',
    city: 'Cochiti Pueblo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.6089,
    longitude: -106.3518,
    postal_code: '87072'
  },
  '87083': {
    country: 'US',
    city: 'Cochiti Lake',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.6485,
    longitude: -106.3428,
    postal_code: '87083'
  },
  '87101': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1996,
    longitude: -106.6448,
    postal_code: '87101'
  },
  '87102': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0818,
    longitude: -106.6482,
    postal_code: '87102'
  },
  '87103': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87103'
  },
  '87104': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1038,
    longitude: -106.6712,
    postal_code: '87104'
  },
  '87105': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0448,
    longitude: -106.6893,
    postal_code: '87105'
  },
  '87106': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.079,
    longitude: -106.6169,
    postal_code: '87106'
  },
  '87107': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1347,
    longitude: -106.6427,
    postal_code: '87107'
  },
  '87108': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0726,
    longitude: -106.5749,
    postal_code: '87108'
  },
  '87109': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1506,
    longitude: -106.569,
    postal_code: '87109'
  },
  '87110': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1104,
    longitude: -106.5781,
    postal_code: '87110'
  },
  '87111': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1347,
    longitude: -106.5222,
    postal_code: '87111'
  },
  '87112': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.101,
    longitude: -106.5183,
    postal_code: '87112'
  },
  '87113': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1759,
    longitude: -106.6015,
    postal_code: '87113'
  },
  '87114': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1868,
    longitude: -106.6652,
    postal_code: '87114'
  },
  '87115': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0549,
    longitude: -106.5461,
    postal_code: '87115'
  },
  '87116': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0561,
    longitude: -106.5506,
    postal_code: '87116'
  },
  '87117': {
    country: 'US',
    city: 'Kirtland Afb',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0203,
    longitude: -106.5503,
    postal_code: '87117'
  },
  '87119': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87119'
  },
  '87120': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1421,
    longitude: -106.7041,
    postal_code: '87120'
  },
  '87121': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0512,
    longitude: -106.7269,
    postal_code: '87121'
  },
  '87122': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.1787,
    longitude: -106.5102,
    postal_code: '87122'
  },
  '87123': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0717,
    longitude: -106.509,
    postal_code: '87123'
  },
  '87124': {
    country: 'US',
    city: 'Rio Rancho',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.2493,
    longitude: -106.6818,
    postal_code: '87124'
  },
  '87125': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87125'
  },
  '87131': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87131'
  },
  '87144': {
    country: 'US',
    city: 'Rio Rancho',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sandoval',
    latitude: 35.324,
    longitude: -106.7099,
    postal_code: '87144'
  },
  '87151': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.023,
    longitude: -106.8556,
    postal_code: '87151'
  },
  '87153': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87153'
  },
  '87154': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87154'
  },
  '87158': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0845,
    longitude: -106.6511,
    postal_code: '87158'
  },
  '87174': {
    country: 'US',
    city: 'Rio Rancho',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87174'
  },
  '87176': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87176'
  },
  '87181': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87181'
  },
  '87184': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87184'
  },
  '87185': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87185'
  },
  '87187': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87187'
  },
  '87190': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87190'
  },
  '87191': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87191'
  },
  '87192': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87192'
  },
  '87193': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87193'
  },
  '87194': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87194'
  },
  '87195': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87195'
  },
  '87196': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87196'
  },
  '87197': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0845,
    longitude: -106.6511,
    postal_code: '87197'
  },
  '87198': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0845,
    longitude: -106.6511,
    postal_code: '87198'
  },
  '87199': {
    country: 'US',
    city: 'Albuquerque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Bernalillo',
    latitude: 35.0443,
    longitude: -106.6729,
    postal_code: '87199'
  },
  '87301': {
    country: 'US',
    city: 'Gallup',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.5065,
    longitude: -108.7414,
    postal_code: '87301'
  },
  '87302': {
    country: 'US',
    city: 'Gallup',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.5281,
    longitude: -108.7426,
    postal_code: '87302'
  },
  '87305': {
    country: 'US',
    city: 'Gallup',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.4499,
    longitude: -108.7705,
    postal_code: '87305'
  },
  '87310': {
    country: 'US',
    city: 'Brimhall',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.8005,
    longitude: -108.5815,
    postal_code: '87310'
  },
  '87311': {
    country: 'US',
    city: 'Church Rock',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.5339,
    longitude: -108.5998,
    postal_code: '87311'
  },
  '87312': {
    country: 'US',
    city: 'Continental Divide',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.4226,
    longitude: -108.323,
    postal_code: '87312'
  },
  '87313': {
    country: 'US',
    city: 'Crownpoint',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.7206,
    longitude: -108.0271,
    postal_code: '87313'
  },
  '87315': {
    country: 'US',
    city: 'Fence Lake',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 34.7344,
    longitude: -108.6934,
    postal_code: '87315'
  },
  '87316': {
    country: 'US',
    city: 'Fort Wingate',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.5191,
    longitude: -108.4864,
    postal_code: '87316'
  },
  '87317': {
    country: 'US',
    city: 'Gamerco',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.5763,
    longitude: -108.7626,
    postal_code: '87317'
  },
  '87319': {
    country: 'US',
    city: 'Mentmore',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.5042,
    longitude: -108.8317,
    postal_code: '87319'
  },
  '87320': {
    country: 'US',
    city: 'Mexican Springs',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.7837,
    longitude: -108.819,
    postal_code: '87320'
  },
  '87321': {
    country: 'US',
    city: 'Ramah',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.1324,
    longitude: -108.492,
    postal_code: '87321'
  },
  '87322': {
    country: 'US',
    city: 'Rehoboth',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.5289,
    longitude: -108.6548,
    postal_code: '87322'
  },
  '87323': {
    country: 'US',
    city: 'Thoreau',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.3976,
    longitude: -108.1604,
    postal_code: '87323'
  },
  '87325': {
    country: 'US',
    city: 'Tohatchi',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 36.0021,
    longitude: -108.6229,
    postal_code: '87325'
  },
  '87326': {
    country: 'US',
    city: 'Vanderwagen',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.3509,
    longitude: -108.6985,
    postal_code: '87326'
  },
  '87327': {
    country: 'US',
    city: 'Zuni',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.0684,
    longitude: -108.8336,
    postal_code: '87327'
  },
  '87328': {
    country: 'US',
    city: 'Navajo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.8942,
    longitude: -109.0226,
    postal_code: '87328'
  },
  '87347': {
    country: 'US',
    city: 'Jamestown',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.4809,
    longitude: -108.1767,
    postal_code: '87347'
  },
  '87357': {
    country: 'US',
    city: 'Pinehill',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Cibola',
    latitude: 34.9992,
    longitude: -108.4112,
    postal_code: '87357'
  },
  '87364': {
    country: 'US',
    city: 'Sheep Springs',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.142,
    longitude: -108.7064,
    postal_code: '87364'
  },
  '87365': {
    country: 'US',
    city: 'Smith Lake',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.4809,
    longitude: -108.1767,
    postal_code: '87365'
  },
  '87375': {
    country: 'US',
    city: 'Yatahey',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'McKinley',
    latitude: 35.4809,
    longitude: -108.1767,
    postal_code: '87375'
  },
  '87401': {
    country: 'US',
    city: 'Farmington',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.7412,
    longitude: -108.1797,
    postal_code: '87401'
  },
  '87402': {
    country: 'US',
    city: 'Farmington',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.7685,
    longitude: -108.1478,
    postal_code: '87402'
  },
  '87410': {
    country: 'US',
    city: 'Aztec',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.8205,
    longitude: -108.011,
    postal_code: '87410'
  },
  '87412': {
    country: 'US',
    city: 'Blanco',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.7242,
    longitude: -107.8253,
    postal_code: '87412'
  },
  '87413': {
    country: 'US',
    city: 'Bloomfield',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.6955,
    longitude: -107.9784,
    postal_code: '87413'
  },
  '87415': {
    country: 'US',
    city: 'Flora Vista',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.8028,
    longitude: -108.0827,
    postal_code: '87415'
  },
  '87416': {
    country: 'US',
    city: 'Fruitland',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.6933,
    longitude: -108.4218,
    postal_code: '87416'
  },
  '87417': {
    country: 'US',
    city: 'Kirtland',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.741,
    longitude: -108.351,
    postal_code: '87417'
  },
  '87418': {
    country: 'US',
    city: 'La Plata',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.9576,
    longitude: -108.1792,
    postal_code: '87418'
  },
  '87419': {
    country: 'US',
    city: 'Navajo Dam',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.8689,
    longitude: -107.6528,
    postal_code: '87419'
  },
  '87420': {
    country: 'US',
    city: 'Shiprock',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.656,
    longitude: -108.7355,
    postal_code: '87420'
  },
  '87421': {
    country: 'US',
    city: 'Waterflow',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.7637,
    longitude: -108.5186,
    postal_code: '87421'
  },
  '87455': {
    country: 'US',
    city: 'Newcomb',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.2847,
    longitude: -108.7059,
    postal_code: '87455'
  },
  '87461': {
    country: 'US',
    city: 'Sanostee',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.4247,
    longitude: -108.8743,
    postal_code: '87461'
  },
  '87499': {
    country: 'US',
    city: 'Farmington',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Juan',
    latitude: 36.7745,
    longitude: -108.0692,
    postal_code: '87499'
  },
  '87501': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.6975,
    longitude: -105.9821,
    postal_code: '87501'
  },
  '87502': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.687,
    longitude: -105.9378,
    postal_code: '87502'
  },
  '87503': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.687,
    longitude: -105.9378,
    postal_code: '87503'
  },
  '87504': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.687,
    longitude: -105.9378,
    postal_code: '87504'
  },
  '87505': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.6219,
    longitude: -105.8688,
    postal_code: '87505'
  },
  '87506': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.8195,
    longitude: -105.9886,
    postal_code: '87506'
  },
  '87507': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.6567,
    longitude: -106.0152,
    postal_code: '87507'
  },
  '87508': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.5335,
    longitude: -105.926,
    postal_code: '87508'
  },
  '87509': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.5212,
    longitude: -105.9818,
    postal_code: '87509'
  },
  '87510': {
    country: 'US',
    city: 'Abiquiu',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.1769,
    longitude: -106.2449,
    postal_code: '87510'
  },
  '87511': {
    country: 'US',
    city: 'Alcalde',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.0889,
    longitude: -106.0536,
    postal_code: '87511'
  },
  '87512': {
    country: 'US',
    city: 'Amalia',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.942,
    longitude: -105.4545,
    postal_code: '87512'
  },
  '87513': {
    country: 'US',
    city: 'Arroyo Hondo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.5796,
    longitude: -105.6873,
    postal_code: '87513'
  },
  '87514': {
    country: 'US',
    city: 'Arroyo Seco',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.52,
    longitude: -105.534,
    postal_code: '87514'
  },
  '87515': {
    country: 'US',
    city: 'Canjilon',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.4795,
    longitude: -106.4378,
    postal_code: '87515'
  },
  '87516': {
    country: 'US',
    city: 'Canones',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.1814,
    longitude: -106.4376,
    postal_code: '87516'
  },
  '87517': {
    country: 'US',
    city: 'Carson',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.3645,
    longitude: -105.7653,
    postal_code: '87517'
  },
  '87518': {
    country: 'US',
    city: 'Cebolla',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.4654,
    longitude: -106.5785,
    postal_code: '87518'
  },
  '87519': {
    country: 'US',
    city: 'Cerro',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.7539,
    longitude: -105.6128,
    postal_code: '87519'
  },
  '87520': {
    country: 'US',
    city: 'Chama',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.8962,
    longitude: -106.5829,
    postal_code: '87520'
  },
  '87521': {
    country: 'US',
    city: 'Chamisal',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.1311,
    longitude: -105.7385,
    postal_code: '87521'
  },
  '87522': {
    country: 'US',
    city: 'Chimayo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.0039,
    longitude: -105.947,
    postal_code: '87522'
  },
  '87523': {
    country: 'US',
    city: 'Cordova',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.0072,
    longitude: -105.8606,
    postal_code: '87523'
  },
  '87524': {
    country: 'US',
    city: 'Costilla',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.9324,
    longitude: -105.6723,
    postal_code: '87524'
  },
  '87525': {
    country: 'US',
    city: 'Taos Ski Valley',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.596,
    longitude: -105.4545,
    postal_code: '87525'
  },
  '87527': {
    country: 'US',
    city: 'Dixon',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.179,
    longitude: -105.8615,
    postal_code: '87527'
  },
  '87528': {
    country: 'US',
    city: 'Dulce',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.8598,
    longitude: -107.0602,
    postal_code: '87528'
  },
  '87529': {
    country: 'US',
    city: 'El Prado',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.4317,
    longitude: -105.5747,
    postal_code: '87529'
  },
  '87530': {
    country: 'US',
    city: 'El Rito',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.3647,
    longitude: -106.2192,
    postal_code: '87530'
  },
  '87531': {
    country: 'US',
    city: 'Embudo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.2153,
    longitude: -105.8901,
    postal_code: '87531'
  },
  '87532': {
    country: 'US',
    city: 'Espanola',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 35.9872,
    longitude: -106.0717,
    postal_code: '87532'
  },
  '87533': {
    country: 'US',
    city: 'Espanola',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 35.9866,
    longitude: -106.0654,
    postal_code: '87533'
  },
  '87535': {
    country: 'US',
    city: 'Glorieta',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.5288,
    longitude: -105.7477,
    postal_code: '87535'
  },
  '87537': {
    country: 'US',
    city: 'Hernandez',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.0738,
    longitude: -106.1396,
    postal_code: '87537'
  },
  '87538': {
    country: 'US',
    city: 'Ilfeld',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.4212,
    longitude: -105.5589,
    postal_code: '87538'
  },
  '87539': {
    country: 'US',
    city: 'La Madera',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.4654,
    longitude: -106.5785,
    postal_code: '87539'
  },
  '87540': {
    country: 'US',
    city: 'Lamy',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.4311,
    longitude: -105.9409,
    postal_code: '87540'
  },
  '87543': {
    country: 'US',
    city: 'Llano',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.14,
    longitude: -105.6817,
    postal_code: '87543'
  },
  '87544': {
    country: 'US',
    city: 'Los Alamos',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Los Alamos',
    latitude: 35.8663,
    longitude: -106.2676,
    postal_code: '87544'
  },
  '87545': {
    country: 'US',
    city: 'Los Alamos',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Los Alamos',
    latitude: 35.8639,
    longitude: -106.2953,
    postal_code: '87545'
  },
  '87547': {
    country: 'US',
    city: 'White Rock',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Los Alamos',
    latitude: 35.8275,
    longitude: -106.2039,
    postal_code: '87547'
  },
  '87548': {
    country: 'US',
    city: 'Medanales',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.1756,
    longitude: -106.1834,
    postal_code: '87548'
  },
  '87549': {
    country: 'US',
    city: 'Ojo Caliente',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.3313,
    longitude: -106.0028,
    postal_code: '87549'
  },
  '87551': {
    country: 'US',
    city: 'Los Ojos',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.7292,
    longitude: -106.57,
    postal_code: '87551'
  },
  '87552': {
    country: 'US',
    city: 'Pecos',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.571,
    longitude: -105.6701,
    postal_code: '87552'
  },
  '87553': {
    country: 'US',
    city: 'Penasco',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.1763,
    longitude: -105.7105,
    postal_code: '87553'
  },
  '87554': {
    country: 'US',
    city: 'Petaca',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.5084,
    longitude: -106.0106,
    postal_code: '87554'
  },
  '87556': {
    country: 'US',
    city: 'Questa',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.7039,
    longitude: -105.595,
    postal_code: '87556'
  },
  '87557': {
    country: 'US',
    city: 'Ranchos De Taos',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.3357,
    longitude: -105.6086,
    postal_code: '87557'
  },
  '87558': {
    country: 'US',
    city: 'Red River',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.6659,
    longitude: -105.4694,
    postal_code: '87558'
  },
  '87560': {
    country: 'US',
    city: 'Ribera',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.3344,
    longitude: -105.4652,
    postal_code: '87560'
  },
  '87562': {
    country: 'US',
    city: 'Rowe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.4917,
    longitude: -105.6753,
    postal_code: '87562'
  },
  '87564': {
    country: 'US',
    city: 'San Cristobal',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.6116,
    longitude: -105.636,
    postal_code: '87564'
  },
  '87565': {
    country: 'US',
    city: 'San Jose',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.4569,
    longitude: -105.4383,
    postal_code: '87565'
  },
  '87566': {
    country: 'US',
    city: 'Ohkay Owingeh',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.0508,
    longitude: -106.069,
    postal_code: '87566'
  },
  '87567': {
    country: 'US',
    city: 'Santa Cruz',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.986,
    longitude: -106.0318,
    postal_code: '87567'
  },
  '87569': {
    country: 'US',
    city: 'Serafina',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.3975,
    longitude: -105.3236,
    postal_code: '87569'
  },
  '87571': {
    country: 'US',
    city: 'Taos',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.3953,
    longitude: -105.5847,
    postal_code: '87571'
  },
  '87573': {
    country: 'US',
    city: 'Tererro',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.7727,
    longitude: -105.6713,
    postal_code: '87573'
  },
  '87574': {
    country: 'US',
    city: 'Tesuque',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.7874,
    longitude: -105.9157,
    postal_code: '87574'
  },
  '87575': {
    country: 'US',
    city: 'Tierra Amarilla',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.6805,
    longitude: -106.557,
    postal_code: '87575'
  },
  '87576': {
    country: 'US',
    city: 'Trampas',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.1311,
    longitude: -105.7589,
    postal_code: '87576'
  },
  '87577': {
    country: 'US',
    city: 'Tres Piedras',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.647,
    longitude: -105.9672,
    postal_code: '87577'
  },
  '87578': {
    country: 'US',
    city: 'Truchas',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.0444,
    longitude: -105.8129,
    postal_code: '87578'
  },
  '87579': {
    country: 'US',
    city: 'Vadito',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.1338,
    longitude: -105.5712,
    postal_code: '87579'
  },
  '87580': {
    country: 'US',
    city: 'Valdez',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Taos',
    latitude: 36.5701,
    longitude: -105.5658,
    postal_code: '87580'
  },
  '87581': {
    country: 'US',
    city: 'Vallecitos',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.5286,
    longitude: -106.1448,
    postal_code: '87581'
  },
  '87582': {
    country: 'US',
    city: 'Velarde',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Rio Arriba',
    latitude: 36.1589,
    longitude: -105.9747,
    postal_code: '87582'
  },
  '87583': {
    country: 'US',
    city: 'Villanueva',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.2667,
    longitude: -105.3608,
    postal_code: '87583'
  },
  '87592': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.5212,
    longitude: -105.9818,
    postal_code: '87592'
  },
  '87594': {
    country: 'US',
    city: 'Santa Fe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Santa Fe',
    latitude: 35.5212,
    longitude: -105.9818,
    postal_code: '87594'
  },
  '87654': {
    country: 'US',
    city: 'Spaceport City',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sierra',
    latitude: 32.9903,
    longitude: -106.9697,
    postal_code: '87654'
  },
  '87701': {
    country: 'US',
    city: 'Las Vegas',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.5949,
    longitude: -105.2272,
    postal_code: '87701'
  },
  '87710': {
    country: 'US',
    city: 'Angel Fire',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Colfax',
    latitude: 36.3849,
    longitude: -105.2439,
    postal_code: '87710'
  },
  '87711': {
    country: 'US',
    city: 'Anton Chico',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Guadalupe',
    latitude: 35.2001,
    longitude: -105.1422,
    postal_code: '87711'
  },
  '87712': {
    country: 'US',
    city: 'Buena Vista',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 35.9136,
    longitude: -105.2492,
    postal_code: '87712'
  },
  '87713': {
    country: 'US',
    city: 'Chacon',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 36.1388,
    longitude: -105.3854,
    postal_code: '87713'
  },
  '87714': {
    country: 'US',
    city: 'Cimarron',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Colfax',
    latitude: 36.4574,
    longitude: -105.0697,
    postal_code: '87714'
  },
  '87715': {
    country: 'US',
    city: 'Cleveland',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 35.9898,
    longitude: -105.4326,
    postal_code: '87715'
  },
  '87718': {
    country: 'US',
    city: 'Eagle Nest',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Colfax',
    latitude: 36.5326,
    longitude: -105.2756,
    postal_code: '87718'
  },
  '87722': {
    country: 'US',
    city: 'Guadalupita',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 36.1375,
    longitude: -105.2386,
    postal_code: '87722'
  },
  '87723': {
    country: 'US',
    city: 'Holman',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 36.0384,
    longitude: -105.3836,
    postal_code: '87723'
  },
  '87724': {
    country: 'US',
    city: 'La Loma',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Guadalupe',
    latitude: 35.1734,
    longitude: -105.0775,
    postal_code: '87724'
  },
  '87728': {
    country: 'US',
    city: 'Maxwell',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Colfax',
    latitude: 36.5434,
    longitude: -104.564,
    postal_code: '87728'
  },
  '87729': {
    country: 'US',
    city: 'Miami',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Colfax',
    latitude: 36.35,
    longitude: -104.7931,
    postal_code: '87729'
  },
  '87730': {
    country: 'US',
    city: 'Mills',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Harding',
    latitude: 36.1281,
    longitude: -104.2277,
    postal_code: '87730'
  },
  '87731': {
    country: 'US',
    city: 'Montezuma',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.6523,
    longitude: -105.2764,
    postal_code: '87731'
  },
  '87732': {
    country: 'US',
    city: 'Mora',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 35.9742,
    longitude: -105.33,
    postal_code: '87732'
  },
  '87733': {
    country: 'US',
    city: 'Mosquero',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Harding',
    latitude: 35.8041,
    longitude: -103.9021,
    postal_code: '87733'
  },
  '87734': {
    country: 'US',
    city: 'Ocate',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 36.1037,
    longitude: -105.066,
    postal_code: '87734'
  },
  '87735': {
    country: 'US',
    city: 'Ojo Feliz',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 36.0578,
    longitude: -105.1183,
    postal_code: '87735'
  },
  '87736': {
    country: 'US',
    city: 'Rainsville',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 35.9786,
    longitude: -105.2092,
    postal_code: '87736'
  },
  '87740': {
    country: 'US',
    city: 'Raton',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Colfax',
    latitude: 36.8952,
    longitude: -104.4349,
    postal_code: '87740'
  },
  '87742': {
    country: 'US',
    city: 'Rociada',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.8226,
    longitude: -105.3147,
    postal_code: '87742'
  },
  '87743': {
    country: 'US',
    city: 'Roy',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Harding',
    latitude: 35.9522,
    longitude: -104.1497,
    postal_code: '87743'
  },
  '87745': {
    country: 'US',
    city: 'Sapello',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.762,
    longitude: -105.1077,
    postal_code: '87745'
  },
  '87746': {
    country: 'US',
    city: 'Solano',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Harding',
    latitude: 35.8484,
    longitude: -104.0675,
    postal_code: '87746'
  },
  '87747': {
    country: 'US',
    city: 'Springer',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Colfax',
    latitude: 36.3767,
    longitude: -104.5927,
    postal_code: '87747'
  },
  '87749': {
    country: 'US',
    city: 'Ute Park',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Colfax',
    latitude: 36.5581,
    longitude: -105.115,
    postal_code: '87749'
  },
  '87750': {
    country: 'US',
    city: 'Valmora',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 35.8164,
    longitude: -104.9231,
    postal_code: '87750'
  },
  '87752': {
    country: 'US',
    city: 'Wagon Mound',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 35.9814,
    longitude: -104.691,
    postal_code: '87752'
  },
  '87753': {
    country: 'US',
    city: 'Watrous',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Mora',
    latitude: 35.7906,
    longitude: -104.9817,
    postal_code: '87753'
  },
  '87801': {
    country: 'US',
    city: 'Socorro',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Socorro',
    latitude: 34.0479,
    longitude: -106.8907,
    postal_code: '87801'
  },
  '87820': {
    country: 'US',
    city: 'Aragon',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Catron',
    latitude: 33.8859,
    longitude: -108.5466,
    postal_code: '87820'
  },
  '87821': {
    country: 'US',
    city: 'Datil',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Catron',
    latitude: 34.0427,
    longitude: -108.0183,
    postal_code: '87821'
  },
  '87823': {
    country: 'US',
    city: 'Lemitar',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Socorro',
    latitude: 34.156,
    longitude: -106.9044,
    postal_code: '87823'
  },
  '87824': {
    country: 'US',
    city: 'Luna',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Catron',
    latitude: 33.8189,
    longitude: -108.9545,
    postal_code: '87824'
  },
  '87825': {
    country: 'US',
    city: 'Magdalena',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Socorro',
    latitude: 34.1114,
    longitude: -107.2409,
    postal_code: '87825'
  },
  '87827': {
    country: 'US',
    city: 'Pie Town',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Catron',
    latitude: 34.3243,
    longitude: -108.3682,
    postal_code: '87827'
  },
  '87828': {
    country: 'US',
    city: 'Polvadera',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Socorro',
    latitude: 34.2059,
    longitude: -106.9167,
    postal_code: '87828'
  },
  '87829': {
    country: 'US',
    city: 'Quemado',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Catron',
    latitude: 34.2652,
    longitude: -108.7578,
    postal_code: '87829'
  },
  '87830': {
    country: 'US',
    city: 'Reserve',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Catron',
    latitude: 33.7131,
    longitude: -108.7578,
    postal_code: '87830'
  },
  '87831': {
    country: 'US',
    city: 'San Acacia',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Socorro',
    latitude: 34.2283,
    longitude: -106.9049,
    postal_code: '87831'
  },
  '87832': {
    country: 'US',
    city: 'San Antonio',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Socorro',
    latitude: 33.819,
    longitude: -106.8285,
    postal_code: '87832'
  },
  '87901': {
    country: 'US',
    city: 'Truth Or Consequences',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sierra',
    latitude: 33.1606,
    longitude: -107.2669,
    postal_code: '87901'
  },
  '87930': {
    country: 'US',
    city: 'Arrey',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sierra',
    latitude: 32.8487,
    longitude: -107.3192,
    postal_code: '87930'
  },
  '87931': {
    country: 'US',
    city: 'Caballo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sierra',
    latitude: 32.9641,
    longitude: -107.4102,
    postal_code: '87931'
  },
  '87933': {
    country: 'US',
    city: 'Derry',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sierra',
    latitude: 32.7881,
    longitude: -107.2822,
    postal_code: '87933'
  },
  '87935': {
    country: 'US',
    city: 'Elephant Butte',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sierra',
    latitude: 33.209,
    longitude: -107.2212,
    postal_code: '87935'
  },
  '87936': {
    country: 'US',
    city: 'Garfield',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.7557,
    longitude: -107.2704,
    postal_code: '87936'
  },
  '87937': {
    country: 'US',
    city: 'Hatch',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.6583,
    longitude: -107.159,
    postal_code: '87937'
  },
  '87939': {
    country: 'US',
    city: 'Monticello',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sierra',
    latitude: 33.397,
    longitude: -107.4509,
    postal_code: '87939'
  },
  '87940': {
    country: 'US',
    city: 'Rincon',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.6597,
    longitude: -107.0643,
    postal_code: '87940'
  },
  '87941': {
    country: 'US',
    city: 'Salem',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.7093,
    longitude: -107.2157,
    postal_code: '87941'
  },
  '87942': {
    country: 'US',
    city: 'Williamsburg',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sierra',
    latitude: 33.1171,
    longitude: -107.2915,
    postal_code: '87942'
  },
  '87943': {
    country: 'US',
    city: 'Winston',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sierra',
    latitude: 33.3061,
    longitude: -107.6675,
    postal_code: '87943'
  },
  '88001': {
    country: 'US',
    city: 'Las Cruces',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.2901,
    longitude: -106.7539,
    postal_code: '88001'
  },
  '88002': {
    country: 'US',
    city: 'White Sands Missile Range',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.3839,
    longitude: -106.4937,
    postal_code: '88002'
  },
  '88003': {
    country: 'US',
    city: 'Las Cruces',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.2738,
    longitude: -106.7472,
    postal_code: '88003'
  },
  '88004': {
    country: 'US',
    city: 'Las Cruces',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.4181,
    longitude: -106.8201,
    postal_code: '88004'
  },
  '88005': {
    country: 'US',
    city: 'Las Cruces',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.3161,
    longitude: -106.7991,
    postal_code: '88005'
  },
  '88006': {
    country: 'US',
    city: 'Las Cruces',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.3052,
    longitude: -106.7863,
    postal_code: '88006'
  },
  '88007': {
    country: 'US',
    city: 'Las Cruces',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.3224,
    longitude: -106.8041,
    postal_code: '88007'
  },
  '88008': {
    country: 'US',
    city: 'Santa Teresa',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 31.8394,
    longitude: -106.6821,
    postal_code: '88008'
  },
  '88009': {
    country: 'US',
    city: 'Playas',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Hidalgo',
    latitude: 32.055,
    longitude: -108.6291,
    postal_code: '88009'
  },
  '88011': {
    country: 'US',
    city: 'Las Cruces',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.3244,
    longitude: -106.6683,
    postal_code: '88011'
  },
  '88012': {
    country: 'US',
    city: 'Las Cruces',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.5835,
    longitude: -106.7714,
    postal_code: '88012'
  },
  '88013': {
    country: 'US',
    city: 'Las Cruces',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.5835,
    longitude: -106.7714,
    postal_code: '88013'
  },
  '88020': {
    country: 'US',
    city: 'Animas',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Hidalgo',
    latitude: 32.053,
    longitude: -108.9069,
    postal_code: '88020'
  },
  '88021': {
    country: 'US',
    city: 'Anthony',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.004,
    longitude: -106.6058,
    postal_code: '88021'
  },
  '88022': {
    country: 'US',
    city: 'Arenas Valley',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.7839,
    longitude: -108.1854,
    postal_code: '88022'
  },
  '88023': {
    country: 'US',
    city: 'Bayard',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.7617,
    longitude: -108.1306,
    postal_code: '88023'
  },
  '88024': {
    country: 'US',
    city: 'Berino',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.0682,
    longitude: -106.631,
    postal_code: '88024'
  },
  '88025': {
    country: 'US',
    city: 'Buckhorn',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 33.0633,
    longitude: -108.6956,
    postal_code: '88025'
  },
  '88026': {
    country: 'US',
    city: 'Santa Clara',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.7795,
    longitude: -108.1503,
    postal_code: '88026'
  },
  '88027': {
    country: 'US',
    city: 'Chamberino',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.0401,
    longitude: -106.6856,
    postal_code: '88027'
  },
  '88028': {
    country: 'US',
    city: 'Cliff',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.9623,
    longitude: -108.6112,
    postal_code: '88028'
  },
  '88029': {
    country: 'US',
    city: 'Columbus',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Luna',
    latitude: 31.8276,
    longitude: -107.64,
    postal_code: '88029'
  },
  '88030': {
    country: 'US',
    city: 'Deming',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Luna',
    latitude: 32.2318,
    longitude: -107.7466,
    postal_code: '88030'
  },
  '88031': {
    country: 'US',
    city: 'Deming',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Luna',
    latitude: 32.2398,
    longitude: -107.7404,
    postal_code: '88031'
  },
  '88032': {
    country: 'US',
    city: 'Dona Ana',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.3895,
    longitude: -106.8139,
    postal_code: '88032'
  },
  '88033': {
    country: 'US',
    city: 'Fairacres',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.2984,
    longitude: -106.8812,
    postal_code: '88033'
  },
  '88034': {
    country: 'US',
    city: 'Faywood',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.5642,
    longitude: -108.0242,
    postal_code: '88034'
  },
  '88036': {
    country: 'US',
    city: 'Fort Bayard',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.7574,
    longitude: -108.0075,
    postal_code: '88036'
  },
  '88038': {
    country: 'US',
    city: 'Gila',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.9659,
    longitude: -108.5767,
    postal_code: '88038'
  },
  '88039': {
    country: 'US',
    city: 'Glenwood',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Catron',
    latitude: 33.3048,
    longitude: -108.7743,
    postal_code: '88039'
  },
  '88040': {
    country: 'US',
    city: 'Hachita',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 31.8904,
    longitude: -108.3316,
    postal_code: '88040'
  },
  '88041': {
    country: 'US',
    city: 'Hanover',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.8045,
    longitude: -107.9545,
    postal_code: '88041'
  },
  '88042': {
    country: 'US',
    city: 'Hillsboro',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Sierra',
    latitude: 32.9239,
    longitude: -107.6276,
    postal_code: '88042'
  },
  '88043': {
    country: 'US',
    city: 'Hurley',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.648,
    longitude: -108.1516,
    postal_code: '88043'
  },
  '88044': {
    country: 'US',
    city: 'La Mesa',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.1383,
    longitude: -106.7999,
    postal_code: '88044'
  },
  '88045': {
    country: 'US',
    city: 'Lordsburg',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Hidalgo',
    latitude: 32.316,
    longitude: -108.723,
    postal_code: '88045'
  },
  '88046': {
    country: 'US',
    city: 'Mesilla',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.268,
    longitude: -106.8059,
    postal_code: '88046'
  },
  '88047': {
    country: 'US',
    city: 'Mesilla Park',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.21,
    longitude: -106.7147,
    postal_code: '88047'
  },
  '88048': {
    country: 'US',
    city: 'Mesquite',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.146,
    longitude: -106.6676,
    postal_code: '88048'
  },
  '88049': {
    country: 'US',
    city: 'Mimbres',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.909,
    longitude: -108.0332,
    postal_code: '88049'
  },
  '88051': {
    country: 'US',
    city: 'Mule Creek',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 33.1071,
    longitude: -108.9032,
    postal_code: '88051'
  },
  '88052': {
    country: 'US',
    city: 'Organ',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.4259,
    longitude: -106.6136,
    postal_code: '88052'
  },
  '88053': {
    country: 'US',
    city: 'Pinos Altos',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.8614,
    longitude: -108.2192,
    postal_code: '88053'
  },
  '88054': {
    country: 'US',
    city: 'Radium Springs',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.4888,
    longitude: -106.9146,
    postal_code: '88054'
  },
  '88055': {
    country: 'US',
    city: 'Redrock',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.6862,
    longitude: -108.7381,
    postal_code: '88055'
  },
  '88056': {
    country: 'US',
    city: 'Rodeo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Hidalgo',
    latitude: 31.8358,
    longitude: -109.0322,
    postal_code: '88056'
  },
  '88058': {
    country: 'US',
    city: 'San Miguel',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.1554,
    longitude: -106.735,
    postal_code: '88058'
  },
  '88061': {
    country: 'US',
    city: 'Silver City',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.79,
    longitude: -108.2749,
    postal_code: '88061'
  },
  '88062': {
    country: 'US',
    city: 'Silver City',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.7282,
    longitude: -108.1379,
    postal_code: '88062'
  },
  '88063': {
    country: 'US',
    city: 'Sunland Park',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 31.8186,
    longitude: -106.5997,
    postal_code: '88063'
  },
  '88065': {
    country: 'US',
    city: 'Tyrone',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Grant',
    latitude: 32.7098,
    longitude: -108.302,
    postal_code: '88065'
  },
  '88072': {
    country: 'US',
    city: 'Vado',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Do√±a Ana',
    latitude: 32.1187,
    longitude: -106.649,
    postal_code: '88072'
  },
  '88081': {
    country: 'US',
    city: 'Chaparral',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.2239,
    longitude: -106.2631,
    postal_code: '88081'
  },
  '88101': {
    country: 'US',
    city: 'Clovis',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Curry',
    latitude: 34.4126,
    longitude: -103.2214,
    postal_code: '88101'
  },
  '88102': {
    country: 'US',
    city: 'Clovis',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Curry',
    latitude: 34.4048,
    longitude: -103.2052,
    postal_code: '88102'
  },
  '88103': {
    country: 'US',
    city: 'Cannon Afb',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Curry',
    latitude: 34.3841,
    longitude: -103.3154,
    postal_code: '88103'
  },
  '88112': {
    country: 'US',
    city: 'Broadview',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Curry',
    latitude: 34.804,
    longitude: -103.1292,
    postal_code: '88112'
  },
  '88113': {
    country: 'US',
    city: 'Causey',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Roosevelt',
    latitude: 33.7781,
    longitude: -103.0865,
    postal_code: '88113'
  },
  '88114': {
    country: 'US',
    city: 'Crossroads',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 33.5272,
    longitude: -103.3564,
    postal_code: '88114'
  },
  '88115': {
    country: 'US',
    city: 'Dora',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Roosevelt',
    latitude: 33.9387,
    longitude: -103.3366,
    postal_code: '88115'
  },
  '88116': {
    country: 'US',
    city: 'Elida',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Roosevelt',
    latitude: 33.9405,
    longitude: -103.6323,
    postal_code: '88116'
  },
  '88118': {
    country: 'US',
    city: 'Floyd',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Roosevelt',
    latitude: 34.252,
    longitude: -103.5827,
    postal_code: '88118'
  },
  '88119': {
    country: 'US',
    city: 'Fort Sumner',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'DeBaca',
    latitude: 34.46,
    longitude: -104.2317,
    postal_code: '88119'
  },
  '88120': {
    country: 'US',
    city: 'Grady',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Curry',
    latitude: 34.8107,
    longitude: -103.298,
    postal_code: '88120'
  },
  '88121': {
    country: 'US',
    city: 'House',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Quay',
    latitude: 34.6952,
    longitude: -103.9203,
    postal_code: '88121'
  },
  '88122': {
    country: 'US',
    city: 'Kenna',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Roosevelt',
    latitude: 33.8423,
    longitude: -103.7719,
    postal_code: '88122'
  },
  '88123': {
    country: 'US',
    city: 'Lingo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Roosevelt',
    latitude: 33.7176,
    longitude: -103.1451,
    postal_code: '88123'
  },
  '88124': {
    country: 'US',
    city: 'Melrose',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Curry',
    latitude: 34.4479,
    longitude: -103.6325,
    postal_code: '88124'
  },
  '88125': {
    country: 'US',
    city: 'Milnesand',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Roosevelt',
    latitude: 33.605,
    longitude: -103.2782,
    postal_code: '88125'
  },
  '88126': {
    country: 'US',
    city: 'Pep',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Roosevelt',
    latitude: 33.8362,
    longitude: -103.3355,
    postal_code: '88126'
  },
  '88130': {
    country: 'US',
    city: 'Portales',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Roosevelt',
    latitude: 34.1799,
    longitude: -103.3363,
    postal_code: '88130'
  },
  '88132': {
    country: 'US',
    city: 'Rogers',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Roosevelt',
    latitude: 33.9216,
    longitude: -103.1898,
    postal_code: '88132'
  },
  '88133': {
    country: 'US',
    city: 'Saint Vrain',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Curry',
    latitude: 34.4756,
    longitude: -103.4669,
    postal_code: '88133'
  },
  '88134': {
    country: 'US',
    city: 'Taiban',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'DeBaca',
    latitude: 34.3271,
    longitude: -104.0159,
    postal_code: '88134'
  },
  '88135': {
    country: 'US',
    city: 'Texico',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Curry',
    latitude: 34.3958,
    longitude: -103.0615,
    postal_code: '88135'
  },
  '88136': {
    country: 'US',
    city: 'Yeso',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'DeBaca',
    latitude: 34.455,
    longitude: -104.7452,
    postal_code: '88136'
  },
  '88201': {
    country: 'US',
    city: 'Roswell',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Chaves',
    latitude: 33.6397,
    longitude: -104.3748,
    postal_code: '88201'
  },
  '88202': {
    country: 'US',
    city: 'Roswell',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Chaves',
    latitude: 33.3038,
    longitude: -104.4318,
    postal_code: '88202'
  },
  '88203': {
    country: 'US',
    city: 'Roswell',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Chaves',
    latitude: 33.3718,
    longitude: -104.5284,
    postal_code: '88203'
  },
  '88210': {
    country: 'US',
    city: 'Artesia',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Eddy',
    latitude: 32.8384,
    longitude: -104.4074,
    postal_code: '88210'
  },
  '88211': {
    country: 'US',
    city: 'Artesia',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Eddy',
    latitude: 32.7536,
    longitude: -104.3281,
    postal_code: '88211'
  },
  '88213': {
    country: 'US',
    city: 'Caprock',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 33.3939,
    longitude: -103.639,
    postal_code: '88213'
  },
  '88220': {
    country: 'US',
    city: 'Carlsbad',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Eddy',
    latitude: 32.4119,
    longitude: -104.2395,
    postal_code: '88220'
  },
  '88221': {
    country: 'US',
    city: 'Carlsbad',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Eddy',
    latitude: 32.342,
    longitude: -104.2937,
    postal_code: '88221'
  },
  '88230': {
    country: 'US',
    city: 'Dexter',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Chaves',
    latitude: 33.191,
    longitude: -104.3833,
    postal_code: '88230'
  },
  '88231': {
    country: 'US',
    city: 'Eunice',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 32.4392,
    longitude: -103.1594,
    postal_code: '88231'
  },
  '88232': {
    country: 'US',
    city: 'Hagerman',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Chaves',
    latitude: 33.1076,
    longitude: -104.3298,
    postal_code: '88232'
  },
  '88240': {
    country: 'US',
    city: 'Hobbs',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 32.7222,
    longitude: -103.1372,
    postal_code: '88240'
  },
  '88241': {
    country: 'US',
    city: 'Hobbs',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 32.7475,
    longitude: -103.2165,
    postal_code: '88241'
  },
  '88242': {
    country: 'US',
    city: 'Hobbs',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 32.8105,
    longitude: -103.1587,
    postal_code: '88242'
  },
  '88244': {
    country: 'US',
    city: 'Hobbs',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 32.7026,
    longitude: -103.136,
    postal_code: '88244'
  },
  '88250': {
    country: 'US',
    city: 'Hope',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Eddy',
    latitude: 32.8156,
    longitude: -104.7299,
    postal_code: '88250'
  },
  '88252': {
    country: 'US',
    city: 'Jal',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 32.1128,
    longitude: -103.1997,
    postal_code: '88252'
  },
  '88253': {
    country: 'US',
    city: 'Lake Arthur',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Chaves',
    latitude: 32.9982,
    longitude: -104.3666,
    postal_code: '88253'
  },
  '88254': {
    country: 'US',
    city: 'Lakewood',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Eddy',
    latitude: 32.6362,
    longitude: -104.365,
    postal_code: '88254'
  },
  '88255': {
    country: 'US',
    city: 'Loco Hills',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Eddy',
    latitude: 32.819,
    longitude: -103.9777,
    postal_code: '88255'
  },
  '88256': {
    country: 'US',
    city: 'Loving',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Eddy',
    latitude: 32.274,
    longitude: -104.0914,
    postal_code: '88256'
  },
  '88260': {
    country: 'US',
    city: 'Lovington',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 32.9512,
    longitude: -103.3488,
    postal_code: '88260'
  },
  '88262': {
    country: 'US',
    city: 'Mcdonald',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 33.1407,
    longitude: -103.3174,
    postal_code: '88262'
  },
  '88263': {
    country: 'US',
    city: 'Malaga',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Eddy',
    latitude: 32.1813,
    longitude: -104.0668,
    postal_code: '88263'
  },
  '88264': {
    country: 'US',
    city: 'Maljamar',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 32.8562,
    longitude: -103.7627,
    postal_code: '88264'
  },
  '88265': {
    country: 'US',
    city: 'Monument',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 32.5874,
    longitude: -103.2703,
    postal_code: '88265'
  },
  '88267': {
    country: 'US',
    city: 'Tatum',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lea',
    latitude: 33.26,
    longitude: -103.314,
    postal_code: '88267'
  },
  '88268': {
    country: 'US',
    city: 'Whites City',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Eddy',
    latitude: 32.1757,
    longitude: -104.3766,
    postal_code: '88268'
  },
  '88301': {
    country: 'US',
    city: 'Carrizozo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.6496,
    longitude: -105.8696,
    postal_code: '88301'
  },
  '88310': {
    country: 'US',
    city: 'Alamogordo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.8932,
    longitude: -105.9485,
    postal_code: '88310'
  },
  '88311': {
    country: 'US',
    city: 'Alamogordo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.8995,
    longitude: -105.9603,
    postal_code: '88311'
  },
  '88312': {
    country: 'US',
    city: 'Alto',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.4314,
    longitude: -105.6776,
    postal_code: '88312'
  },
  '88314': {
    country: 'US',
    city: 'Bent',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 33.148,
    longitude: -105.8635,
    postal_code: '88314'
  },
  '88316': {
    country: 'US',
    city: 'Capitan',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.5602,
    longitude: -105.5269,
    postal_code: '88316'
  },
  '88317': {
    country: 'US',
    city: 'Cloudcroft',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.8897,
    longitude: -105.6744,
    postal_code: '88317'
  },
  '88318': {
    country: 'US',
    city: 'Corona',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 34.1693,
    longitude: -105.5339,
    postal_code: '88318'
  },
  '88321': {
    country: 'US',
    city: 'Encino',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Torrance',
    latitude: 34.615,
    longitude: -105.4839,
    postal_code: '88321'
  },
  '88323': {
    country: 'US',
    city: 'Fort Stanton',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.4959,
    longitude: -105.523,
    postal_code: '88323'
  },
  '88324': {
    country: 'US',
    city: 'Glencoe',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.3898,
    longitude: -105.4391,
    postal_code: '88324'
  },
  '88325': {
    country: 'US',
    city: 'High Rolls Mountain Park',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.9396,
    longitude: -105.8189,
    postal_code: '88325'
  },
  '88330': {
    country: 'US',
    city: 'Holloman Air Force Base',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.8366,
    longitude: -106.0774,
    postal_code: '88330'
  },
  '88336': {
    country: 'US',
    city: 'Hondo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.3876,
    longitude: -105.2708,
    postal_code: '88336'
  },
  '88337': {
    country: 'US',
    city: 'La Luz',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.9774,
    longitude: -105.9534,
    postal_code: '88337'
  },
  '88338': {
    country: 'US',
    city: 'Lincoln',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.4869,
    longitude: -105.382,
    postal_code: '88338'
  },
  '88339': {
    country: 'US',
    city: 'Mayhill',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.907,
    longitude: -105.5181,
    postal_code: '88339'
  },
  '88340': {
    country: 'US',
    city: 'Mescalero',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 33.2164,
    longitude: -105.7743,
    postal_code: '88340'
  },
  '88341': {
    country: 'US',
    city: 'Nogal',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.4996,
    longitude: -105.7036,
    postal_code: '88341'
  },
  '88342': {
    country: 'US',
    city: 'Orogrande',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.3712,
    longitude: -106.0844,
    postal_code: '88342'
  },
  '88343': {
    country: 'US',
    city: 'Picacho',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.3518,
    longitude: -105.145,
    postal_code: '88343'
  },
  '88344': {
    country: 'US',
    city: 'Pinon',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.6396,
    longitude: -105.4157,
    postal_code: '88344'
  },
  '88345': {
    country: 'US',
    city: 'Ruidoso',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.3474,
    longitude: -105.651,
    postal_code: '88345'
  },
  '88346': {
    country: 'US',
    city: 'Ruidoso Downs',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.3577,
    longitude: -105.5383,
    postal_code: '88346'
  },
  '88347': {
    country: 'US',
    city: 'Sacramento',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.8052,
    longitude: -105.6211,
    postal_code: '88347'
  },
  '88348': {
    country: 'US',
    city: 'San Patricio',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.3877,
    longitude: -105.3498,
    postal_code: '88348'
  },
  '88349': {
    country: 'US',
    city: 'Sunspot',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.8672,
    longitude: -105.7811,
    postal_code: '88349'
  },
  '88350': {
    country: 'US',
    city: 'Timberon',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.6295,
    longitude: -105.6945,
    postal_code: '88350'
  },
  '88351': {
    country: 'US',
    city: 'Tinnie',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.3706,
    longitude: -105.2264,
    postal_code: '88351'
  },
  '88352': {
    country: 'US',
    city: 'Tularosa',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 33.065,
    longitude: -106.0108,
    postal_code: '88352'
  },
  '88353': {
    country: 'US',
    city: 'Vaughn',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Guadalupe',
    latitude: 34.6241,
    longitude: -105.1926,
    postal_code: '88353'
  },
  '88354': {
    country: 'US',
    city: 'Weed',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Otero',
    latitude: 32.8052,
    longitude: -105.5063,
    postal_code: '88354'
  },
  '88355': {
    country: 'US',
    city: 'Ruidoso',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Lincoln',
    latitude: 33.3305,
    longitude: -105.6933,
    postal_code: '88355'
  },
  '88401': {
    country: 'US',
    city: 'Tucumcari',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Quay',
    latitude: 35.168,
    longitude: -103.7179,
    postal_code: '88401'
  },
  '88410': {
    country: 'US',
    city: 'Amistad',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Union',
    latitude: 35.8983,
    longitude: -103.213,
    postal_code: '88410'
  },
  '88411': {
    country: 'US',
    city: 'Bard',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Quay',
    latitude: 35.1303,
    longitude: -103.1605,
    postal_code: '88411'
  },
  '88414': {
    country: 'US',
    city: 'Capulin',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Union',
    latitude: 36.7785,
    longitude: -103.9986,
    postal_code: '88414'
  },
  '88415': {
    country: 'US',
    city: 'Clayton',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Union',
    latitude: 36.4414,
    longitude: -103.1888,
    postal_code: '88415'
  },
  '88416': {
    country: 'US',
    city: 'Conchas Dam',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.3786,
    longitude: -104.2052,
    postal_code: '88416'
  },
  '88417': {
    country: 'US',
    city: 'Cuervo',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Guadalupe',
    latitude: 35.0312,
    longitude: -104.4086,
    postal_code: '88417'
  },
  '88418': {
    country: 'US',
    city: 'Des Moines',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Union',
    latitude: 36.7291,
    longitude: -103.8739,
    postal_code: '88418'
  },
  '88419': {
    country: 'US',
    city: 'Folsom',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Union',
    latitude: 36.869,
    longitude: -103.8399,
    postal_code: '88419'
  },
  '88421': {
    country: 'US',
    city: 'Garita',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.3502,
    longitude: -104.4419,
    postal_code: '88421'
  },
  '88422': {
    country: 'US',
    city: 'Gladstone',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Union',
    latitude: 36.3048,
    longitude: -103.973,
    postal_code: '88422'
  },
  '88424': {
    country: 'US',
    city: 'Grenville',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Union',
    latitude: 36.3698,
    longitude: -103.5055,
    postal_code: '88424'
  },
  '88426': {
    country: 'US',
    city: 'Logan',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Quay',
    latitude: 35.3681,
    longitude: -103.4383,
    postal_code: '88426'
  },
  '88427': {
    country: 'US',
    city: 'Mcalister',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Quay',
    latitude: 34.6923,
    longitude: -103.7764,
    postal_code: '88427'
  },
  '88430': {
    country: 'US',
    city: 'Nara Visa',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Quay',
    latitude: 35.618,
    longitude: -103.1317,
    postal_code: '88430'
  },
  '88431': {
    country: 'US',
    city: 'Newkirk',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Guadalupe',
    latitude: 35.0847,
    longitude: -104.2433,
    postal_code: '88431'
  },
  '88433': {
    country: 'US',
    city: 'Quay',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Quay',
    latitude: 34.9167,
    longitude: -103.7782,
    postal_code: '88433'
  },
  '88434': {
    country: 'US',
    city: 'San Jon',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Quay',
    latitude: 35.1198,
    longitude: -103.2846,
    postal_code: '88434'
  },
  '88435': {
    country: 'US',
    city: 'Santa Rosa',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Guadalupe',
    latitude: 34.8526,
    longitude: -104.5899,
    postal_code: '88435'
  },
  '88436': {
    country: 'US',
    city: 'Sedan',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'Union',
    latitude: 36.226,
    longitude: -103.1449,
    postal_code: '88436'
  },
  '88439': {
    country: 'US',
    city: 'Trementina',
    state: 'New Mexico',
    state_short: 'NM',
    county: 'San Miguel',
    latitude: 35.4563,
    longitude: -104.6796,
    postal_code: '88439'
  },
  '88510': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88510'
  },
  '88511': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88511'
  },
  '88512': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88512'
  },
  '88513': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88513'
  },
  '88514': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88514'
  },
  '88515': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88515'
  },
  '88517': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88517'
  },
  '88518': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88518'
  },
  '88519': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88519'
  },
  '88520': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88520'
  },
  '88521': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88521'
  },
  '88523': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88523'
  },
  '88524': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88524'
  },
  '88525': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88525'
  },
  '88526': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88526'
  },
  '88527': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88527'
  },
  '88528': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88528'
  },
  '88529': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88529'
  },
  '88530': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88530'
  },
  '88531': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88531'
  },
  '88532': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88532'
  },
  '88533': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88533'
  },
  '88534': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88534'
  },
  '88535': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88535'
  },
  '88536': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88536'
  },
  '88538': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88538'
  },
  '88539': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88539'
  },
  '88540': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88540'
  },
  '88541': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88541'
  },
  '88542': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88542'
  },
  '88543': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88543'
  },
  '88544': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88544'
  },
  '88545': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88545'
  },
  '88546': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88546'
  },
  '88547': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88547'
  },
  '88548': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88548'
  },
  '88549': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88549'
  },
  '88550': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88550'
  },
  '88553': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88553'
  },
  '88554': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88554'
  },
  '88555': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88555'
  },
  '88556': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88556'
  },
  '88557': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88557'
  },
  '88558': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88558'
  },
  '88559': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88559'
  },
  '88560': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88560'
  },
  '88561': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88561'
  },
  '88562': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88562'
  },
  '88563': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88563'
  },
  '88565': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88565'
  },
  '88566': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88566'
  },
  '88567': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88567'
  },
  '88568': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88568'
  },
  '88569': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88569'
  },
  '88570': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88570'
  },
  '88571': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88571'
  },
  '88572': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88572'
  },
  '88573': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88573'
  },
  '88574': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88574'
  },
  '88575': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88575'
  },
  '88576': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88576'
  },
  '88577': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88577'
  },
  '88578': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88578'
  },
  '88579': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88579'
  },
  '88580': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88580'
  },
  '88581': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88581'
  },
  '88582': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88582'
  },
  '88583': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88583'
  },
  '88584': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88584'
  },
  '88585': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88585'
  },
  '88586': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88586'
  },
  '88587': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88587'
  },
  '88588': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88588'
  },
  '88589': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88589'
  },
  '88590': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88590'
  },
  '88595': {
    country: 'US',
    city: 'El Paso',
    state: 'Texas',
    state_short: 'TX',
    county: 'El Paso',
    latitude: 31.6948,
    longitude: -106.3,
    postal_code: '88595'
  },
  '88901': {
    country: 'US',
    city: 'The Lakes',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.3225,
    longitude: -114.8197,
    postal_code: '88901'
  },
  '88905': {
    country: 'US',
    city: 'The Lakes',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.9279,
    longitude: -114.9721,
    postal_code: '88905'
  },
  '89001': {
    country: 'US',
    city: 'Alamo',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lincoln',
    latitude: 37.3259,
    longitude: -115.308,
    postal_code: '89001'
  },
  '89002': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0008,
    longitude: -114.9588,
    postal_code: '89002'
  },
  '89003': {
    country: 'US',
    city: 'Beatty',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 36.8199,
    longitude: -116.6094,
    postal_code: '89003'
  },
  '89004': {
    country: 'US',
    city: 'Blue Diamond',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0464,
    longitude: -115.4039,
    postal_code: '89004'
  },
  '89005': {
    country: 'US',
    city: 'Boulder City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.9727,
    longitude: -114.8344,
    postal_code: '89005'
  },
  '89006': {
    country: 'US',
    city: 'Boulder City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.9279,
    longitude: -114.9721,
    postal_code: '89006'
  },
  '89007': {
    country: 'US',
    city: 'Bunkerville',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.7684,
    longitude: -114.1281,
    postal_code: '89007'
  },
  '89008': {
    country: 'US',
    city: 'Caliente',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lincoln',
    latitude: 37.6128,
    longitude: -114.5097,
    postal_code: '89008'
  },
  '89009': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.9279,
    longitude: -114.9721,
    postal_code: '89009'
  },
  '89010': {
    country: 'US',
    city: 'Dyer',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Esmeralda',
    latitude: 37.7776,
    longitude: -118.084,
    postal_code: '89010'
  },
  '89011': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1065,
    longitude: -114.9192,
    postal_code: '89011'
  },
  '89012': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0119,
    longitude: -115.0433,
    postal_code: '89012'
  },
  '89013': {
    country: 'US',
    city: 'Goldfield',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Esmeralda',
    latitude: 37.7085,
    longitude: -117.2356,
    postal_code: '89013'
  },
  '89014': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0564,
    longitude: -115.078,
    postal_code: '89014'
  },
  '89015': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0357,
    longitude: -114.9718,
    postal_code: '89015'
  },
  '89016': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.9279,
    longitude: -114.9721,
    postal_code: '89016'
  },
  '89017': {
    country: 'US',
    city: 'Hiko',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lincoln',
    latitude: 37.5926,
    longitude: -115.2262,
    postal_code: '89017'
  },
  '89018': {
    country: 'US',
    city: 'Indian Springs',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.5697,
    longitude: -115.6706,
    postal_code: '89018'
  },
  '89019': {
    country: 'US',
    city: 'Jean',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.7368,
    longitude: -115.5405,
    postal_code: '89019'
  },
  '89020': {
    country: 'US',
    city: 'Amargosa Valley',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 36.539,
    longitude: -116.5496,
    postal_code: '89020'
  },
  '89021': {
    country: 'US',
    city: 'Logandale',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.5935,
    longitude: -114.4683,
    postal_code: '89021'
  },
  '89022': {
    country: 'US',
    city: 'Manhattan',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 38.5388,
    longitude: -117.0734,
    postal_code: '89022'
  },
  '89023': {
    country: 'US',
    city: 'Mercury',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 36.6605,
    longitude: -115.9945,
    postal_code: '89023'
  },
  '89024': {
    country: 'US',
    city: 'Mesquite',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.8101,
    longitude: -114.0722,
    postal_code: '89024'
  },
  '89025': {
    country: 'US',
    city: 'Moapa',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.6916,
    longitude: -114.6514,
    postal_code: '89025'
  },
  '89026': {
    country: 'US',
    city: 'Jean',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.7789,
    longitude: -115.3239,
    postal_code: '89026'
  },
  '89027': {
    country: 'US',
    city: 'Mesquite',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.8113,
    longitude: -114.1235,
    postal_code: '89027'
  },
  '89028': {
    country: 'US',
    city: 'Laughlin',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.1604,
    longitude: -114.7464,
    postal_code: '89028'
  },
  '89029': {
    country: 'US',
    city: 'Laughlin',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.1321,
    longitude: -114.6368,
    postal_code: '89029'
  },
  '89030': {
    country: 'US',
    city: 'North Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2115,
    longitude: -115.1241,
    postal_code: '89030'
  },
  '89031': {
    country: 'US',
    city: 'North Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2589,
    longitude: -115.1718,
    postal_code: '89031'
  },
  '89032': {
    country: 'US',
    city: 'North Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.218,
    longitude: -115.1709,
    postal_code: '89032'
  },
  '89033': {
    country: 'US',
    city: 'North Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2845,
    longitude: -115.1345,
    postal_code: '89033'
  },
  '89034': {
    country: 'US',
    city: 'Mesquite',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.809,
    longitude: -114.0591,
    postal_code: '89034'
  },
  '89036': {
    country: 'US',
    city: 'North Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1989,
    longitude: -115.1175,
    postal_code: '89036'
  },
  '89037': {
    country: 'US',
    city: 'Coyote Springs',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1579,
    longitude: -114.934,
    postal_code: '89037'
  },
  '89039': {
    country: 'US',
    city: 'Cal Nev Ari',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.2522,
    longitude: -114.8714,
    postal_code: '89039'
  },
  '89040': {
    country: 'US',
    city: 'Overton',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.5703,
    longitude: -114.4732,
    postal_code: '89040'
  },
  '89041': {
    country: 'US',
    city: 'Pahrump',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 36.2083,
    longitude: -115.9839,
    postal_code: '89041'
  },
  '89042': {
    country: 'US',
    city: 'Panaca',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lincoln',
    latitude: 37.7905,
    longitude: -114.3894,
    postal_code: '89042'
  },
  '89043': {
    country: 'US',
    city: 'Pioche',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lincoln',
    latitude: 37.8981,
    longitude: -114.3968,
    postal_code: '89043'
  },
  '89044': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lincoln',
    latitude: 35.9025,
    longitude: -115.1789,
    postal_code: '89044'
  },
  '89045': {
    country: 'US',
    city: 'Round Mountain',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 38.711,
    longitude: -117.0676,
    postal_code: '89045'
  },
  '89046': {
    country: 'US',
    city: 'Searchlight',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.5132,
    longitude: -114.8866,
    postal_code: '89046'
  },
  '89047': {
    country: 'US',
    city: 'Silverpeak',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Esmeralda',
    latitude: 37.7225,
    longitude: -117.7965,
    postal_code: '89047'
  },
  '89048': {
    country: 'US',
    city: 'Pahrump',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 36.1661,
    longitude: -116.0038,
    postal_code: '89048'
  },
  '89049': {
    country: 'US',
    city: 'Tonopah',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 38.0672,
    longitude: -117.2301,
    postal_code: '89049'
  },
  '89052': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.9878,
    longitude: -115.1167,
    postal_code: '89052'
  },
  '89053': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.9279,
    longitude: -114.9721,
    postal_code: '89053'
  },
  '89054': {
    country: 'US',
    city: 'Sloan',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.935,
    longitude: -115.2058,
    postal_code: '89054'
  },
  '89060': {
    country: 'US',
    city: 'Pahrump',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 36.2645,
    longitude: -116.0393,
    postal_code: '89060'
  },
  '89061': {
    country: 'US',
    city: 'Pahrump',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 36.1184,
    longitude: -115.9407,
    postal_code: '89061'
  },
  '89067': {
    country: 'US',
    city: 'Moapa',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.6591,
    longitude: -114.6656,
    postal_code: '89067'
  },
  '89070': {
    country: 'US',
    city: 'Indian Springs',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.5697,
    longitude: -115.6706,
    postal_code: '89070'
  },
  '89074': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0384,
    longitude: -115.0857,
    postal_code: '89074'
  },
  '89077': {
    country: 'US',
    city: 'Henderson',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0397,
    longitude: -114.9819,
    postal_code: '89077'
  },
  '89081': {
    country: 'US',
    city: 'North Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2583,
    longitude: -115.1068,
    postal_code: '89081'
  },
  '89084': {
    country: 'US',
    city: 'North Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2815,
    longitude: -115.1482,
    postal_code: '89084'
  },
  '89085': {
    country: 'US',
    city: 'North Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.3097,
    longitude: -115.1981,
    postal_code: '89085'
  },
  '89086': {
    country: 'US',
    city: 'North Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2809,
    longitude: -115.1349,
    postal_code: '89086'
  },
  '89087': {
    country: 'US',
    city: 'North Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2204,
    longitude: -115.1458,
    postal_code: '89087'
  },
  '89101': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1721,
    longitude: -115.1224,
    postal_code: '89101'
  },
  '89102': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1433,
    longitude: -115.2004,
    postal_code: '89102'
  },
  '89103': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1149,
    longitude: -115.2161,
    postal_code: '89103'
  },
  '89104': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.152,
    longitude: -115.1092,
    postal_code: '89104'
  },
  '89105': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.086,
    longitude: -115.1471,
    postal_code: '89105'
  },
  '89106': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1847,
    longitude: -115.1617,
    postal_code: '89106'
  },
  '89107': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1705,
    longitude: -115.2176,
    postal_code: '89107'
  },
  '89108': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2044,
    longitude: -115.2233,
    postal_code: '89108'
  },
  '89109': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.126,
    longitude: -115.1454,
    postal_code: '89109'
  },
  '89110': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.173,
    longitude: -115.0669,
    postal_code: '89110'
  },
  '89111': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89111'
  },
  '89112': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1578,
    longitude: -115.0256,
    postal_code: '89112'
  },
  '89113': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0854,
    longitude: -115.2566,
    postal_code: '89113'
  },
  '89114': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0113,
    longitude: -115.1015,
    postal_code: '89114'
  },
  '89115': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2158,
    longitude: -115.0671,
    postal_code: '89115'
  },
  '89116': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89116'
  },
  '89117': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1302,
    longitude: -115.2755,
    postal_code: '89117'
  },
  '89118': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0811,
    longitude: -115.2169,
    postal_code: '89118'
  },
  '89119': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1008,
    longitude: -115.1365,
    postal_code: '89119'
  },
  '89120': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0914,
    longitude: -115.0885,
    postal_code: '89120'
  },
  '89121': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1232,
    longitude: -115.0902,
    postal_code: '89121'
  },
  '89122': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1205,
    longitude: -115.0523,
    postal_code: '89122'
  },
  '89123': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0383,
    longitude: -115.1462,
    postal_code: '89123'
  },
  '89124': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.4257,
    longitude: -115.4809,
    postal_code: '89124'
  },
  '89125': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2235,
    longitude: -115.2655,
    postal_code: '89125'
  },
  '89126': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89126'
  },
  '89127': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89127'
  },
  '89128': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1968,
    longitude: -115.2644,
    postal_code: '89128'
  },
  '89129': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.245,
    longitude: -115.2743,
    postal_code: '89129'
  },
  '89130': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2471,
    longitude: -115.221,
    postal_code: '89130'
  },
  '89131': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2956,
    longitude: -115.2419,
    postal_code: '89131'
  },
  '89132': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.019,
    longitude: -115.1519,
    postal_code: '89132'
  },
  '89133': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89133'
  },
  '89134': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2092,
    longitude: -115.2941,
    postal_code: '89134'
  },
  '89135': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1378,
    longitude: -115.3261,
    postal_code: '89135'
  },
  '89136': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1753,
    longitude: -115.1364,
    postal_code: '89136'
  },
  '89137': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89137'
  },
  '89138': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1666,
    longitude: -115.3613,
    postal_code: '89138'
  },
  '89139': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0129,
    longitude: -115.2118,
    postal_code: '89139'
  },
  '89140': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.086,
    longitude: -115.1471,
    postal_code: '89140'
  },
  '89141': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0104,
    longitude: -115.2073,
    postal_code: '89141'
  },
  '89142': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.148,
    longitude: -115.0404,
    postal_code: '89142'
  },
  '89143': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.3223,
    longitude: -115.2932,
    postal_code: '89143'
  },
  '89144': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1781,
    longitude: -115.3183,
    postal_code: '89144'
  },
  '89145': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1693,
    longitude: -115.2828,
    postal_code: '89145'
  },
  '89146': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1424,
    longitude: -115.2242,
    postal_code: '89146'
  },
  '89147': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1128,
    longitude: -115.2801,
    postal_code: '89147'
  },
  '89148': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0588,
    longitude: -115.3104,
    postal_code: '89148'
  },
  '89149': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2765,
    longitude: -115.2885,
    postal_code: '89149'
  },
  '89150': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89150'
  },
  '89151': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89151'
  },
  '89152': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89152'
  },
  '89153': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89153'
  },
  '89154': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89154'
  },
  '89155': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89155'
  },
  '89156': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2034,
    longitude: -115.0364,
    postal_code: '89156'
  },
  '89157': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89157'
  },
  '89158': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89158'
  },
  '89159': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89159'
  },
  '89160': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89160'
  },
  '89161': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.0004,
    longitude: -115.3639,
    postal_code: '89161'
  },
  '89162': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1725,
    longitude: -115.1414,
    postal_code: '89162'
  },
  '89163': {
    country: 'US',
    city: 'The Lakes',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89163'
  },
  '89164': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89164'
  },
  '89165': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.3302,
    longitude: -115.3257,
    postal_code: '89165'
  },
  '89166': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.3265,
    longitude: -115.3398,
    postal_code: '89166'
  },
  '89169': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.1234,
    longitude: -115.1429,
    postal_code: '89169'
  },
  '89170': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89170'
  },
  '89173': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89173'
  },
  '89177': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89177'
  },
  '89178': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.9977,
    longitude: -115.2861,
    postal_code: '89178'
  },
  '89179': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2542,
    longitude: -115.5269,
    postal_code: '89179'
  },
  '89180': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89180'
  },
  '89183': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 35.9959,
    longitude: -115.1576,
    postal_code: '89183'
  },
  '89185': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89185'
  },
  '89191': {
    country: 'US',
    city: 'Nellis Afb',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.2395,
    longitude: -115.0257,
    postal_code: '89191'
  },
  '89193': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89193'
  },
  '89195': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89195'
  },
  '89199': {
    country: 'US',
    city: 'Las Vegas',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Clark',
    latitude: 36.175,
    longitude: -115.1372,
    postal_code: '89199'
  },
  '89301': {
    country: 'US',
    city: 'Ely',
    state: 'Nevada',
    state_short: 'NV',
    county: 'White Pine',
    latitude: 39.2474,
    longitude: -114.8886,
    postal_code: '89301'
  },
  '89310': {
    country: 'US',
    city: 'Austin',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lander',
    latitude: 39.508,
    longitude: -117.0811,
    postal_code: '89310'
  },
  '89311': {
    country: 'US',
    city: 'Baker',
    state: 'Nevada',
    state_short: 'NV',
    county: 'White Pine',
    latitude: 38.9559,
    longitude: -114.2438,
    postal_code: '89311'
  },
  '89314': {
    country: 'US',
    city: 'Duckwater',
    state: 'Nevada',
    state_short: 'NV',
    county: 'White Pine',
    latitude: 38.9324,
    longitude: -115.7145,
    postal_code: '89314'
  },
  '89315': {
    country: 'US',
    city: 'Ely',
    state: 'Nevada',
    state_short: 'NV',
    county: 'White Pine',
    latitude: 39.3326,
    longitude: -114.8245,
    postal_code: '89315'
  },
  '89316': {
    country: 'US',
    city: 'Eureka',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Eureka',
    latitude: 39.5897,
    longitude: -115.9943,
    postal_code: '89316'
  },
  '89317': {
    country: 'US',
    city: 'Lund',
    state: 'Nevada',
    state_short: 'NV',
    county: 'White Pine',
    latitude: 38.8641,
    longitude: -115.0069,
    postal_code: '89317'
  },
  '89318': {
    country: 'US',
    city: 'Mc Gill',
    state: 'Nevada',
    state_short: 'NV',
    county: 'White Pine',
    latitude: 39.4034,
    longitude: -114.7791,
    postal_code: '89318'
  },
  '89319': {
    country: 'US',
    city: 'Ruth',
    state: 'Nevada',
    state_short: 'NV',
    county: 'White Pine',
    latitude: 39.2783,
    longitude: -114.9892,
    postal_code: '89319'
  },
  '89402': {
    country: 'US',
    city: 'Crystal Bay',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.226,
    longitude: -120.0041,
    postal_code: '89402'
  },
  '89403': {
    country: 'US',
    city: 'Dayton',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lyon',
    latitude: 39.2806,
    longitude: -119.5287,
    postal_code: '89403'
  },
  '89404': {
    country: 'US',
    city: 'Denio',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Humboldt',
    latitude: 41.9899,
    longitude: -118.6343,
    postal_code: '89404'
  },
  '89405': {
    country: 'US',
    city: 'Empire',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 40.6132,
    longitude: -119.3485,
    postal_code: '89405'
  },
  '89406': {
    country: 'US',
    city: 'Fallon',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Churchill',
    latitude: 39.4703,
    longitude: -118.7861,
    postal_code: '89406'
  },
  '89407': {
    country: 'US',
    city: 'Fallon',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Churchill',
    latitude: 39.4735,
    longitude: -118.7774,
    postal_code: '89407'
  },
  '89408': {
    country: 'US',
    city: 'Fernley',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lyon',
    latitude: 39.6019,
    longitude: -119.235,
    postal_code: '89408'
  },
  '89409': {
    country: 'US',
    city: 'Gabbs',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Nye',
    latitude: 38.8688,
    longitude: -117.9221,
    postal_code: '89409'
  },
  '89410': {
    country: 'US',
    city: 'Gardnerville',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Douglas',
    latitude: 38.8703,
    longitude: -119.6115,
    postal_code: '89410'
  },
  '89411': {
    country: 'US',
    city: 'Genoa',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Douglas',
    latitude: 39.0341,
    longitude: -119.8228,
    postal_code: '89411'
  },
  '89412': {
    country: 'US',
    city: 'Gerlach',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 40.6525,
    longitude: -119.3565,
    postal_code: '89412'
  },
  '89413': {
    country: 'US',
    city: 'Glenbrook',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Douglas',
    latitude: 39.0509,
    longitude: -119.9428,
    postal_code: '89413'
  },
  '89414': {
    country: 'US',
    city: 'Golconda',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Humboldt',
    latitude: 40.9532,
    longitude: -117.4893,
    postal_code: '89414'
  },
  '89415': {
    country: 'US',
    city: 'Hawthorne',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Mineral',
    latitude: 38.5347,
    longitude: -118.6411,
    postal_code: '89415'
  },
  '89418': {
    country: 'US',
    city: 'Imlay',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Pershing',
    latitude: 40.5484,
    longitude: -118.0344,
    postal_code: '89418'
  },
  '89419': {
    country: 'US',
    city: 'Lovelock',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Pershing',
    latitude: 40.1819,
    longitude: -118.4689,
    postal_code: '89419'
  },
  '89420': {
    country: 'US',
    city: 'Luning',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Mineral',
    latitude: 38.5063,
    longitude: -118.1815,
    postal_code: '89420'
  },
  '89421': {
    country: 'US',
    city: 'Mc Dermitt',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Humboldt',
    latitude: 41.9708,
    longitude: -117.6737,
    postal_code: '89421'
  },
  '89422': {
    country: 'US',
    city: 'Mina',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Mineral',
    latitude: 38.387,
    longitude: -118.1096,
    postal_code: '89422'
  },
  '89423': {
    country: 'US',
    city: 'Minden',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Douglas',
    latitude: 39.0218,
    longitude: -119.7314,
    postal_code: '89423'
  },
  '89424': {
    country: 'US',
    city: 'Nixon',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.8272,
    longitude: -119.3605,
    postal_code: '89424'
  },
  '89425': {
    country: 'US',
    city: 'Orovada',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Humboldt',
    latitude: 41.6654,
    longitude: -117.9083,
    postal_code: '89425'
  },
  '89426': {
    country: 'US',
    city: 'Paradise Valley',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Humboldt',
    latitude: 41.5057,
    longitude: -117.5728,
    postal_code: '89426'
  },
  '89427': {
    country: 'US',
    city: 'Schurz',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Mineral',
    latitude: 38.9575,
    longitude: -118.7756,
    postal_code: '89427'
  },
  '89428': {
    country: 'US',
    city: 'Silver City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lyon',
    latitude: 39.2652,
    longitude: -119.6388,
    postal_code: '89428'
  },
  '89429': {
    country: 'US',
    city: 'Silver Springs',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lyon',
    latitude: 39.38,
    longitude: -119.2705,
    postal_code: '89429'
  },
  '89430': {
    country: 'US',
    city: 'Smith',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lyon',
    latitude: 38.7733,
    longitude: -119.3029,
    postal_code: '89430'
  },
  '89431': {
    country: 'US',
    city: 'Sparks',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5473,
    longitude: -119.7556,
    postal_code: '89431'
  },
  '89432': {
    country: 'US',
    city: 'Sparks',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5349,
    longitude: -119.7527,
    postal_code: '89432'
  },
  '89433': {
    country: 'US',
    city: 'Sun Valley',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5955,
    longitude: -119.7754,
    postal_code: '89433'
  },
  '89434': {
    country: 'US',
    city: 'Sparks',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5502,
    longitude: -119.7178,
    postal_code: '89434'
  },
  '89435': {
    country: 'US',
    city: 'Sparks',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5349,
    longitude: -119.7527,
    postal_code: '89435'
  },
  '89436': {
    country: 'US',
    city: 'Sparks',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.6269,
    longitude: -119.7081,
    postal_code: '89436'
  },
  '89438': {
    country: 'US',
    city: 'Valmy',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Humboldt',
    latitude: 40.7927,
    longitude: -117.1268,
    postal_code: '89438'
  },
  '89439': {
    country: 'US',
    city: 'Verdi',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5165,
    longitude: -119.9833,
    postal_code: '89439'
  },
  '89440': {
    country: 'US',
    city: 'Virginia City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Storey',
    latitude: 39.2965,
    longitude: -119.6587,
    postal_code: '89440'
  },
  '89441': {
    country: 'US',
    city: 'Sparks',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.6582,
    longitude: -119.6954,
    postal_code: '89441'
  },
  '89442': {
    country: 'US',
    city: 'Wadsworth',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.6481,
    longitude: -119.2918,
    postal_code: '89442'
  },
  '89444': {
    country: 'US',
    city: 'Wellington',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lyon',
    latitude: 38.8447,
    longitude: -119.3523,
    postal_code: '89444'
  },
  '89445': {
    country: 'US',
    city: 'Winnemucca',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Humboldt',
    latitude: 40.9664,
    longitude: -117.7467,
    postal_code: '89445'
  },
  '89446': {
    country: 'US',
    city: 'Winnemucca',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Humboldt',
    latitude: 41.0764,
    longitude: -117.7602,
    postal_code: '89446'
  },
  '89447': {
    country: 'US',
    city: 'Yerington',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lyon',
    latitude: 38.9866,
    longitude: -119.1596,
    postal_code: '89447'
  },
  '89448': {
    country: 'US',
    city: 'Zephyr Cove',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Douglas',
    latitude: 39.0204,
    longitude: -119.9114,
    postal_code: '89448'
  },
  '89449': {
    country: 'US',
    city: 'Stateline',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Douglas',
    latitude: 38.9643,
    longitude: -119.9068,
    postal_code: '89449'
  },
  '89450': {
    country: 'US',
    city: 'Incline Village',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.2564,
    longitude: -119.9464,
    postal_code: '89450'
  },
  '89451': {
    country: 'US',
    city: 'Incline Village',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.2564,
    longitude: -119.9521,
    postal_code: '89451'
  },
  '89452': {
    country: 'US',
    city: 'Incline Village',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.2591,
    longitude: -119.9566,
    postal_code: '89452'
  },
  '89460': {
    country: 'US',
    city: 'Gardnerville',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Douglas',
    latitude: 38.9166,
    longitude: -119.7272,
    postal_code: '89460'
  },
  '89496': {
    country: 'US',
    city: 'Fallon',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Churchill',
    latitude: 39.4735,
    longitude: -118.7774,
    postal_code: '89496'
  },
  '89501': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5268,
    longitude: -119.8113,
    postal_code: '89501'
  },
  '89502': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.4972,
    longitude: -119.7764,
    postal_code: '89502'
  },
  '89503': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5354,
    longitude: -119.8374,
    postal_code: '89503'
  },
  '89504': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5296,
    longitude: -119.8138,
    postal_code: '89504'
  },
  '89505': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5224,
    longitude: -119.8353,
    postal_code: '89505'
  },
  '89506': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.6412,
    longitude: -119.8735,
    postal_code: '89506'
  },
  '89507': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5423,
    longitude: -119.8164,
    postal_code: '89507'
  },
  '89508': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.6781,
    longitude: -119.9383,
    postal_code: '89508'
  },
  '89509': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.498,
    longitude: -119.8239,
    postal_code: '89509'
  },
  '89510': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.7699,
    longitude: -119.6027,
    postal_code: '89510'
  },
  '89511': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.4151,
    longitude: -119.7668,
    postal_code: '89511'
  },
  '89512': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5483,
    longitude: -119.7957,
    postal_code: '89512'
  },
  '89513': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.53,
    longitude: -119.81,
    postal_code: '89513'
  },
  '89515': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5296,
    longitude: -119.8138,
    postal_code: '89515'
  },
  '89519': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.4814,
    longitude: -119.8591,
    postal_code: '89519'
  },
  '89520': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5296,
    longitude: -119.8138,
    postal_code: '89520'
  },
  '89521': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.3809,
    longitude: -119.6859,
    postal_code: '89521'
  },
  '89523': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5249,
    longitude: -119.9031,
    postal_code: '89523'
  },
  '89533': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5439,
    longitude: -119.9061,
    postal_code: '89533'
  },
  '89555': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5246,
    longitude: -119.8125,
    postal_code: '89555'
  },
  '89557': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5296,
    longitude: -119.8138,
    postal_code: '89557'
  },
  '89570': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5296,
    longitude: -119.8138,
    postal_code: '89570'
  },
  '89595': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5296,
    longitude: -119.8138,
    postal_code: '89595'
  },
  '89599': {
    country: 'US',
    city: 'Reno',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.5296,
    longitude: -119.8138,
    postal_code: '89599'
  },
  '89701': {
    country: 'US',
    city: 'Carson City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Carson City (city)',
    latitude: 39.1507,
    longitude: -119.7459,
    postal_code: '89701'
  },
  '89702': {
    country: 'US',
    city: 'Carson City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Carson City (city)',
    latitude: 39.1355,
    longitude: -119.7588,
    postal_code: '89702'
  },
  '89703': {
    country: 'US',
    city: 'Carson City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Carson City (city)',
    latitude: 39.1704,
    longitude: -119.7782,
    postal_code: '89703'
  },
  '89704': {
    country: 'US',
    city: 'Washoe Valley',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Washoe',
    latitude: 39.2669,
    longitude: -119.8174,
    postal_code: '89704'
  },
  '89705': {
    country: 'US',
    city: 'Carson City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Douglas',
    latitude: 39.0554,
    longitude: -119.8059,
    postal_code: '89705'
  },
  '89706': {
    country: 'US',
    city: 'Carson City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Carson City (city)',
    latitude: 39.2025,
    longitude: -119.7526,
    postal_code: '89706'
  },
  '89711': {
    country: 'US',
    city: 'Carson City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Carson City (city)',
    latitude: 39.1678,
    longitude: -119.7764,
    postal_code: '89711'
  },
  '89712': {
    country: 'US',
    city: 'Carson City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Carson City (city)',
    latitude: 39.1678,
    longitude: -119.7764,
    postal_code: '89712'
  },
  '89713': {
    country: 'US',
    city: 'Carson City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Carson City (city)',
    latitude: 39.1678,
    longitude: -119.7764,
    postal_code: '89713'
  },
  '89714': {
    country: 'US',
    city: 'Carson City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Carson City (city)',
    latitude: 39.1678,
    longitude: -119.7764,
    postal_code: '89714'
  },
  '89721': {
    country: 'US',
    city: 'Carson City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Carson City (city)',
    latitude: 39.1678,
    longitude: -119.7764,
    postal_code: '89721'
  },
  '89801': {
    country: 'US',
    city: 'Elko',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 40.9056,
    longitude: -115.5344,
    postal_code: '89801'
  },
  '89802': {
    country: 'US',
    city: 'Elko',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 40.8324,
    longitude: -115.7631,
    postal_code: '89802'
  },
  '89803': {
    country: 'US',
    city: 'Elko',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 40.8324,
    longitude: -115.7631,
    postal_code: '89803'
  },
  '89815': {
    country: 'US',
    city: 'Spring Creek',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 40.7519,
    longitude: -115.5956,
    postal_code: '89815'
  },
  '89820': {
    country: 'US',
    city: 'Battle Mountain',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Lander',
    latitude: 40.622,
    longitude: -116.9554,
    postal_code: '89820'
  },
  '89821': {
    country: 'US',
    city: 'Crescent Valley',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Eureka',
    latitude: 40.4138,
    longitude: -116.5813,
    postal_code: '89821'
  },
  '89822': {
    country: 'US',
    city: 'Carlin',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 40.7172,
    longitude: -116.1082,
    postal_code: '89822'
  },
  '89823': {
    country: 'US',
    city: 'Deeth',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 41.0655,
    longitude: -115.2748,
    postal_code: '89823'
  },
  '89825': {
    country: 'US',
    city: 'Jackpot',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 41.8882,
    longitude: -114.7233,
    postal_code: '89825'
  },
  '89826': {
    country: 'US',
    city: 'Jarbidge',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 41.8873,
    longitude: -115.3813,
    postal_code: '89826'
  },
  '89828': {
    country: 'US',
    city: 'Lamoille',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 40.7505,
    longitude: -115.3631,
    postal_code: '89828'
  },
  '89830': {
    country: 'US',
    city: 'Montello',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 41.2613,
    longitude: -114.1942,
    postal_code: '89830'
  },
  '89831': {
    country: 'US',
    city: 'Mountain City',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 41.8385,
    longitude: -115.9654,
    postal_code: '89831'
  },
  '89832': {
    country: 'US',
    city: 'Owyhee',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 41.9477,
    longitude: -116.0987,
    postal_code: '89832'
  },
  '89833': {
    country: 'US',
    city: 'Ruby Valley',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 40.3995,
    longitude: -115.2312,
    postal_code: '89833'
  },
  '89834': {
    country: 'US',
    city: 'Tuscarora',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 41.3141,
    longitude: -116.2218,
    postal_code: '89834'
  },
  '89835': {
    country: 'US',
    city: 'Wells',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 41.1116,
    longitude: -114.9645,
    postal_code: '89835'
  },
  '89883': {
    country: 'US',
    city: 'West Wendover',
    state: 'Nevada',
    state_short: 'NV',
    county: 'Elko',
    latitude: 40.7391,
    longitude: -114.0733,
    postal_code: '89883'
  },
  '90001': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9731,
    longitude: -118.2479,
    postal_code: '90001'
  },
  '90002': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9497,
    longitude: -118.2462,
    postal_code: '90002'
  },
  '90003': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9653,
    longitude: -118.2727,
    postal_code: '90003'
  },
  '90004': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0762,
    longitude: -118.3029,
    postal_code: '90004'
  },
  '90005': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0585,
    longitude: -118.3012,
    postal_code: '90005'
  },
  '90006': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0493,
    longitude: -118.2917,
    postal_code: '90006'
  },
  '90007': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0294,
    longitude: -118.2871,
    postal_code: '90007'
  },
  '90008': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0116,
    longitude: -118.3411,
    postal_code: '90008'
  },
  '90009': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90009'
  },
  '90010': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0606,
    longitude: -118.3027,
    postal_code: '90010'
  },
  '90011': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0079,
    longitude: -118.2582,
    postal_code: '90011'
  },
  '90012': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0614,
    longitude: -118.2385,
    postal_code: '90012'
  },
  '90013': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0448,
    longitude: -118.2434,
    postal_code: '90013'
  },
  '90014': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0443,
    longitude: -118.2509,
    postal_code: '90014'
  },
  '90015': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0434,
    longitude: -118.2716,
    postal_code: '90015'
  },
  '90016': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0298,
    longitude: -118.3528,
    postal_code: '90016'
  },
  '90017': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0559,
    longitude: -118.2666,
    postal_code: '90017'
  },
  '90018': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.029,
    longitude: -118.3152,
    postal_code: '90018'
  },
  '90019': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0482,
    longitude: -118.3343,
    postal_code: '90019'
  },
  '90020': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0665,
    longitude: -118.3022,
    postal_code: '90020'
  },
  '90021': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0333,
    longitude: -118.2447,
    postal_code: '90021'
  },
  '90022': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0245,
    longitude: -118.1561,
    postal_code: '90022'
  },
  '90023': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0245,
    longitude: -118.1975,
    postal_code: '90023'
  },
  '90024': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0637,
    longitude: -118.4408,
    postal_code: '90024'
  },
  '90025': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0447,
    longitude: -118.4487,
    postal_code: '90025'
  },
  '90026': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0766,
    longitude: -118.2646,
    postal_code: '90026'
  },
  '90027': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.104,
    longitude: -118.2925,
    postal_code: '90027'
  },
  '90028': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1005,
    longitude: -118.3254,
    postal_code: '90028'
  },
  '90029': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.09,
    longitude: -118.2944,
    postal_code: '90029'
  },
  '90030': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90030'
  },
  '90031': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0783,
    longitude: -118.2113,
    postal_code: '90031'
  },
  '90032': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0818,
    longitude: -118.1753,
    postal_code: '90032'
  },
  '90033': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0487,
    longitude: -118.2084,
    postal_code: '90033'
  },
  '90034': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.029,
    longitude: -118.4005,
    postal_code: '90034'
  },
  '90035': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0531,
    longitude: -118.3806,
    postal_code: '90035'
  },
  '90036': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0699,
    longitude: -118.3492,
    postal_code: '90036'
  },
  '90037': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.003,
    longitude: -118.2863,
    postal_code: '90037'
  },
  '90038': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0898,
    longitude: -118.3215,
    postal_code: '90038'
  },
  '90039': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1121,
    longitude: -118.2594,
    postal_code: '90039'
  },
  '90040': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9909,
    longitude: -118.1532,
    postal_code: '90040'
  },
  '90041': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1339,
    longitude: -118.2082,
    postal_code: '90041'
  },
  '90042': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1145,
    longitude: -118.1929,
    postal_code: '90042'
  },
  '90043': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9871,
    longitude: -118.3321,
    postal_code: '90043'
  },
  '90044': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9551,
    longitude: -118.2901,
    postal_code: '90044'
  },
  '90045': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9631,
    longitude: -118.3941,
    postal_code: '90045'
  },
  '90046': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1074,
    longitude: -118.3652,
    postal_code: '90046'
  },
  '90047': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9569,
    longitude: -118.3073,
    postal_code: '90047'
  },
  '90048': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0737,
    longitude: -118.372,
    postal_code: '90048'
  },
  '90049': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.066,
    longitude: -118.474,
    postal_code: '90049'
  },
  '90050': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90050'
  },
  '90051': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90051'
  },
  '90052': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90052'
  },
  '90053': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90053'
  },
  '90054': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90054'
  },
  '90055': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90055'
  },
  '90056': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9853,
    longitude: -118.3707,
    postal_code: '90056'
  },
  '90057': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0622,
    longitude: -118.2763,
    postal_code: '90057'
  },
  '90058': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9994,
    longitude: -118.2133,
    postal_code: '90058'
  },
  '90059': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9293,
    longitude: -118.2463,
    postal_code: '90059'
  },
  '90060': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90060'
  },
  '90061': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9245,
    longitude: -118.2716,
    postal_code: '90061'
  },
  '90062': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0032,
    longitude: -118.3073,
    postal_code: '90062'
  },
  '90063': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0451,
    longitude: -118.1859,
    postal_code: '90063'
  },
  '90064': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0353,
    longitude: -118.4259,
    postal_code: '90064'
  },
  '90065': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1073,
    longitude: -118.2266,
    postal_code: '90065'
  },
  '90066': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.003,
    longitude: -118.4298,
    postal_code: '90066'
  },
  '90067': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0551,
    longitude: -118.4095,
    postal_code: '90067'
  },
  '90068': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1156,
    longitude: -118.3305,
    postal_code: '90068'
  },
  '90069': {
    country: 'US',
    city: 'West Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0906,
    longitude: -118.3788,
    postal_code: '90069'
  },
  '90070': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90070'
  },
  '90071': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0529,
    longitude: -118.2549,
    postal_code: '90071'
  },
  '90072': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90072'
  },
  '90073': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90073'
  },
  '90074': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90074'
  },
  '90075': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90075'
  },
  '90076': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90076'
  },
  '90077': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1112,
    longitude: -118.4502,
    postal_code: '90077'
  },
  '90078': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90078'
  },
  '90079': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90079'
  },
  '90080': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90080'
  },
  '90081': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90081'
  },
  '90082': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90082'
  },
  '90083': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90083'
  },
  '90084': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90084'
  },
  '90086': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90086'
  },
  '90087': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90087'
  },
  '90088': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90088'
  },
  '90089': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90089'
  },
  '90090': {
    country: 'US',
    city: 'Dodgertown',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0725,
    longitude: -118.2418,
    postal_code: '90090'
  },
  '90091': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90091'
  },
  '90093': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90093'
  },
  '90094': {
    country: 'US',
    city: 'Playa Vista',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9728,
    longitude: -118.4276,
    postal_code: '90094'
  },
  '90095': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90095'
  },
  '90096': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90096'
  },
  '90099': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0522,
    longitude: -118.2437,
    postal_code: '90099'
  },
  '90189': {
    country: 'US',
    city: 'Los Angeles',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0515,
    longitude: -118.2559,
    postal_code: '90189'
  },
  '90201': {
    country: 'US',
    city: 'Bell Gardens',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9653,
    longitude: -118.1515,
    postal_code: '90201'
  },
  '90202': {
    country: 'US',
    city: 'Bell',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9775,
    longitude: -118.187,
    postal_code: '90202'
  },
  '90209': {
    country: 'US',
    city: 'Beverly Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0736,
    longitude: -118.4004,
    postal_code: '90209'
  },
  '90210': {
    country: 'US',
    city: 'Beverly Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0901,
    longitude: -118.4065,
    postal_code: '90210'
  },
  '90211': {
    country: 'US',
    city: 'Beverly Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0652,
    longitude: -118.383,
    postal_code: '90211'
  },
  '90212': {
    country: 'US',
    city: 'Beverly Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0619,
    longitude: -118.3995,
    postal_code: '90212'
  },
  '90213': {
    country: 'US',
    city: 'Beverly Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0736,
    longitude: -118.4004,
    postal_code: '90213'
  },
  '90220': {
    country: 'US',
    city: 'Compton',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8748,
    longitude: -118.2402,
    postal_code: '90220'
  },
  '90221': {
    country: 'US',
    city: 'Compton',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8796,
    longitude: -118.2168,
    postal_code: '90221'
  },
  '90222': {
    country: 'US',
    city: 'Compton',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9099,
    longitude: -118.2357,
    postal_code: '90222'
  },
  '90223': {
    country: 'US',
    city: 'Compton',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8958,
    longitude: -118.2201,
    postal_code: '90223'
  },
  '90224': {
    country: 'US',
    city: 'Compton',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90224'
  },
  '90230': {
    country: 'US',
    city: 'Culver City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9949,
    longitude: -118.3991,
    postal_code: '90230'
  },
  '90231': {
    country: 'US',
    city: 'Culver City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0211,
    longitude: -118.3965,
    postal_code: '90231'
  },
  '90232': {
    country: 'US',
    city: 'Culver City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0168,
    longitude: -118.3973,
    postal_code: '90232'
  },
  '90233': {
    country: 'US',
    city: 'Culver City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0211,
    longitude: -118.3965,
    postal_code: '90233'
  },
  '90239': {
    country: 'US',
    city: 'Downey',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.94,
    longitude: -118.1326,
    postal_code: '90239'
  },
  '90240': {
    country: 'US',
    city: 'Downey',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9581,
    longitude: -118.1174,
    postal_code: '90240'
  },
  '90241': {
    country: 'US',
    city: 'Downey',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9416,
    longitude: -118.1306,
    postal_code: '90241'
  },
  '90242': {
    country: 'US',
    city: 'Downey',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9218,
    longitude: -118.1395,
    postal_code: '90242'
  },
  '90245': {
    country: 'US',
    city: 'El Segundo',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9243,
    longitude: -118.4119,
    postal_code: '90245'
  },
  '90247': {
    country: 'US',
    city: 'Gardena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8925,
    longitude: -118.2961,
    postal_code: '90247'
  },
  '90248': {
    country: 'US',
    city: 'Gardena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8766,
    longitude: -118.2835,
    postal_code: '90248'
  },
  '90249': {
    country: 'US',
    city: 'Gardena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8998,
    longitude: -118.3199,
    postal_code: '90249'
  },
  '90250': {
    country: 'US',
    city: 'Hawthorne',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9143,
    longitude: -118.3493,
    postal_code: '90250'
  },
  '90251': {
    country: 'US',
    city: 'Hawthorne',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9164,
    longitude: -118.3526,
    postal_code: '90251'
  },
  '90254': {
    country: 'US',
    city: 'Hermosa Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8643,
    longitude: -118.3955,
    postal_code: '90254'
  },
  '90255': {
    country: 'US',
    city: 'Huntington Park',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9769,
    longitude: -118.2161,
    postal_code: '90255'
  },
  '90260': {
    country: 'US',
    city: 'Lawndale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8879,
    longitude: -118.351,
    postal_code: '90260'
  },
  '90261': {
    country: 'US',
    city: 'Lawndale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8872,
    longitude: -118.3526,
    postal_code: '90261'
  },
  '90262': {
    country: 'US',
    city: 'Lynwood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9241,
    longitude: -118.2013,
    postal_code: '90262'
  },
  '90263': {
    country: 'US',
    city: 'Malibu',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.005,
    longitude: -118.8101,
    postal_code: '90263'
  },
  '90264': {
    country: 'US',
    city: 'Malibu',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.005,
    longitude: -118.8101,
    postal_code: '90264'
  },
  '90265': {
    country: 'US',
    city: 'Malibu',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0402,
    longitude: -118.7351,
    postal_code: '90265'
  },
  '90266': {
    country: 'US',
    city: 'Manhattan Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8896,
    longitude: -118.3996,
    postal_code: '90266'
  },
  '90267': {
    country: 'US',
    city: 'Manhattan Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90267'
  },
  '90270': {
    country: 'US',
    city: 'Maywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.989,
    longitude: -118.1877,
    postal_code: '90270'
  },
  '90272': {
    country: 'US',
    city: 'Pacific Palisades',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0481,
    longitude: -118.5265,
    postal_code: '90272'
  },
  '90274': {
    country: 'US',
    city: 'Palos Verdes Peninsula',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7669,
    longitude: -118.3806,
    postal_code: '90274'
  },
  '90275': {
    country: 'US',
    city: 'Rancho Palos Verdes',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7515,
    longitude: -118.367,
    postal_code: '90275'
  },
  '90277': {
    country: 'US',
    city: 'Redondo Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8307,
    longitude: -118.3832,
    postal_code: '90277'
  },
  '90278': {
    country: 'US',
    city: 'Redondo Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8707,
    longitude: -118.3715,
    postal_code: '90278'
  },
  '90280': {
    country: 'US',
    city: 'South Gate',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9462,
    longitude: -118.2013,
    postal_code: '90280'
  },
  '90290': {
    country: 'US',
    city: 'Topanga',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1076,
    longitude: -118.6023,
    postal_code: '90290'
  },
  '90291': {
    country: 'US',
    city: 'Venice',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9938,
    longitude: -118.4635,
    postal_code: '90291'
  },
  '90292': {
    country: 'US',
    city: 'Marina Del Rey',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9779,
    longitude: -118.4525,
    postal_code: '90292'
  },
  '90293': {
    country: 'US',
    city: 'Playa Del Rey',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9577,
    longitude: -118.4373,
    postal_code: '90293'
  },
  '90294': {
    country: 'US',
    city: 'Venice',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9908,
    longitude: -118.4601,
    postal_code: '90294'
  },
  '90295': {
    country: 'US',
    city: 'Marina Del Rey',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90295'
  },
  '90296': {
    country: 'US',
    city: 'Playa Del Rey',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90296'
  },
  '90301': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.955,
    longitude: -118.3556,
    postal_code: '90301'
  },
  '90302': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9745,
    longitude: -118.3548,
    postal_code: '90302'
  },
  '90303': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9377,
    longitude: -118.3321,
    postal_code: '90303'
  },
  '90304': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9379,
    longitude: -118.3586,
    postal_code: '90304'
  },
  '90305': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9583,
    longitude: -118.3259,
    postal_code: '90305'
  },
  '90306': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9617,
    longitude: -118.3531,
    postal_code: '90306'
  },
  '90307': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90307'
  },
  '90308': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9617,
    longitude: -118.3531,
    postal_code: '90308'
  },
  '90309': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90309'
  },
  '90310': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90310'
  },
  '90311': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9617,
    longitude: -118.3531,
    postal_code: '90311'
  },
  '90312': {
    country: 'US',
    city: 'Inglewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9617,
    longitude: -118.3531,
    postal_code: '90312'
  },
  '90401': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0176,
    longitude: -118.4907,
    postal_code: '90401'
  },
  '90402': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0349,
    longitude: -118.503,
    postal_code: '90402'
  },
  '90403': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0287,
    longitude: -118.4924,
    postal_code: '90403'
  },
  '90404': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0268,
    longitude: -118.4733,
    postal_code: '90404'
  },
  '90405': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.01,
    longitude: -118.4717,
    postal_code: '90405'
  },
  '90406': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0195,
    longitude: -118.4912,
    postal_code: '90406'
  },
  '90407': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0195,
    longitude: -118.4912,
    postal_code: '90407'
  },
  '90408': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0195,
    longitude: -118.4912,
    postal_code: '90408'
  },
  '90409': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0195,
    longitude: -118.4912,
    postal_code: '90409'
  },
  '90410': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0195,
    longitude: -118.4912,
    postal_code: '90410'
  },
  '90411': {
    country: 'US',
    city: 'Santa Monica',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0195,
    longitude: -118.4912,
    postal_code: '90411'
  },
  '90501': {
    country: 'US',
    city: 'Torrance',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8268,
    longitude: -118.3118,
    postal_code: '90501'
  },
  '90502': {
    country: 'US',
    city: 'Torrance',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8286,
    longitude: -118.292,
    postal_code: '90502'
  },
  '90503': {
    country: 'US',
    city: 'Torrance',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8397,
    longitude: -118.3542,
    postal_code: '90503'
  },
  '90504': {
    country: 'US',
    city: 'Torrance',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8708,
    longitude: -118.3295,
    postal_code: '90504'
  },
  '90505': {
    country: 'US',
    city: 'Torrance',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8106,
    longitude: -118.3507,
    postal_code: '90505'
  },
  '90506': {
    country: 'US',
    city: 'Torrance',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90506'
  },
  '90507': {
    country: 'US',
    city: 'Torrance',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90507'
  },
  '90508': {
    country: 'US',
    city: 'Torrance',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90508'
  },
  '90509': {
    country: 'US',
    city: 'Torrance',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90509'
  },
  '90510': {
    country: 'US',
    city: 'Torrance',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90510'
  },
  '90601': {
    country: 'US',
    city: 'Whittier',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0011,
    longitude: -118.0371,
    postal_code: '90601'
  },
  '90602': {
    country: 'US',
    city: 'Whittier',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9693,
    longitude: -118.0337,
    postal_code: '90602'
  },
  '90603': {
    country: 'US',
    city: 'Whittier',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9432,
    longitude: -117.9927,
    postal_code: '90603'
  },
  '90604': {
    country: 'US',
    city: 'Whittier',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9299,
    longitude: -118.0121,
    postal_code: '90604'
  },
  '90605': {
    country: 'US',
    city: 'Whittier',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9413,
    longitude: -118.0356,
    postal_code: '90605'
  },
  '90606': {
    country: 'US',
    city: 'Whittier',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9777,
    longitude: -118.0658,
    postal_code: '90606'
  },
  '90607': {
    country: 'US',
    city: 'Whittier',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9792,
    longitude: -118.0328,
    postal_code: '90607'
  },
  '90608': {
    country: 'US',
    city: 'Whittier',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9792,
    longitude: -118.0328,
    postal_code: '90608'
  },
  '90609': {
    country: 'US',
    city: 'Whittier',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9792,
    longitude: -118.0328,
    postal_code: '90609'
  },
  '90610': {
    country: 'US',
    city: 'Whittier',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9792,
    longitude: -118.0328,
    postal_code: '90610'
  },
  '90620': {
    country: 'US',
    city: 'Buena Park',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8439,
    longitude: -118.008,
    postal_code: '90620'
  },
  '90621': {
    country: 'US',
    city: 'Buena Park',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8772,
    longitude: -117.9893,
    postal_code: '90621'
  },
  '90622': {
    country: 'US',
    city: 'Buena Park',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8462,
    longitude: -118.0031,
    postal_code: '90622'
  },
  '90623': {
    country: 'US',
    city: 'La Palma',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8491,
    longitude: -118.0398,
    postal_code: '90623'
  },
  '90624': {
    country: 'US',
    city: 'Buena Park',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8584,
    longitude: -118.0033,
    postal_code: '90624'
  },
  '90630': {
    country: 'US',
    city: 'Cypress',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8181,
    longitude: -118.0357,
    postal_code: '90630'
  },
  '90631': {
    country: 'US',
    city: 'La Habra',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.9331,
    longitude: -117.9493,
    postal_code: '90631'
  },
  '90632': {
    country: 'US',
    city: 'La Habra',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.9143,
    longitude: -117.9547,
    postal_code: '90632'
  },
  '90633': {
    country: 'US',
    city: 'La Habra',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.9345,
    longitude: -117.9452,
    postal_code: '90633'
  },
  '90637': {
    country: 'US',
    city: 'La Mirada',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9172,
    longitude: -118.012,
    postal_code: '90637'
  },
  '90638': {
    country: 'US',
    city: 'La Mirada',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9067,
    longitude: -118.0101,
    postal_code: '90638'
  },
  '90639': {
    country: 'US',
    city: 'La Mirada',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9058,
    longitude: -118.0182,
    postal_code: '90639'
  },
  '90640': {
    country: 'US',
    city: 'Montebello',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0133,
    longitude: -118.113,
    postal_code: '90640'
  },
  '90650': {
    country: 'US',
    city: 'Norwalk',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9056,
    longitude: -118.0818,
    postal_code: '90650'
  },
  '90651': {
    country: 'US',
    city: 'Norwalk',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9022,
    longitude: -118.0817,
    postal_code: '90651'
  },
  '90652': {
    country: 'US',
    city: 'Norwalk',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9022,
    longitude: -118.0817,
    postal_code: '90652'
  },
  '90660': {
    country: 'US',
    city: 'Pico Rivera',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9886,
    longitude: -118.0883,
    postal_code: '90660'
  },
  '90661': {
    country: 'US',
    city: 'Pico Rivera',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9831,
    longitude: -118.0967,
    postal_code: '90661'
  },
  '90662': {
    country: 'US',
    city: 'Pico Rivera',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9831,
    longitude: -118.0967,
    postal_code: '90662'
  },
  '90670': {
    country: 'US',
    city: 'Santa Fe Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9464,
    longitude: -118.0838,
    postal_code: '90670'
  },
  '90671': {
    country: 'US',
    city: 'Santa Fe Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9472,
    longitude: -118.0853,
    postal_code: '90671'
  },
  '90680': {
    country: 'US',
    city: 'Stanton',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7991,
    longitude: -117.9956,
    postal_code: '90680'
  },
  '90701': {
    country: 'US',
    city: 'Artesia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8654,
    longitude: -118.0731,
    postal_code: '90701'
  },
  '90702': {
    country: 'US',
    city: 'Artesia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8658,
    longitude: -118.0831,
    postal_code: '90702'
  },
  '90703': {
    country: 'US',
    city: 'Cerritos',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8669,
    longitude: -118.0686,
    postal_code: '90703'
  },
  '90704': {
    country: 'US',
    city: 'Avalon',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.332,
    longitude: -118.3437,
    postal_code: '90704'
  },
  '90706': {
    country: 'US',
    city: 'Bellflower',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8867,
    longitude: -118.1265,
    postal_code: '90706'
  },
  '90707': {
    country: 'US',
    city: 'Bellflower',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8817,
    longitude: -118.117,
    postal_code: '90707'
  },
  '90710': {
    country: 'US',
    city: 'Harbor City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.797,
    longitude: -118.2991,
    postal_code: '90710'
  },
  '90711': {
    country: 'US',
    city: 'Lakewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90711'
  },
  '90712': {
    country: 'US',
    city: 'Lakewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8512,
    longitude: -118.1457,
    postal_code: '90712'
  },
  '90713': {
    country: 'US',
    city: 'Lakewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8473,
    longitude: -118.1115,
    postal_code: '90713'
  },
  '90714': {
    country: 'US',
    city: 'Lakewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8512,
    longitude: -118.1339,
    postal_code: '90714'
  },
  '90715': {
    country: 'US',
    city: 'Lakewood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8405,
    longitude: -118.0767,
    postal_code: '90715'
  },
  '90716': {
    country: 'US',
    city: 'Hawaiian Gardens',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8296,
    longitude: -118.073,
    postal_code: '90716'
  },
  '90717': {
    country: 'US',
    city: 'Lomita',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7938,
    longitude: -118.3172,
    postal_code: '90717'
  },
  '90720': {
    country: 'US',
    city: 'Los Alamitos',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7956,
    longitude: -118.0648,
    postal_code: '90720'
  },
  '90721': {
    country: 'US',
    city: 'Los Alamitos',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8023,
    longitude: -118.069,
    postal_code: '90721'
  },
  '90723': {
    country: 'US',
    city: 'Paramount',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8969,
    longitude: -118.1632,
    postal_code: '90723'
  },
  '90731': {
    country: 'US',
    city: 'San Pedro',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7339,
    longitude: -118.2914,
    postal_code: '90731'
  },
  '90732': {
    country: 'US',
    city: 'San Pedro',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.742,
    longitude: -118.3121,
    postal_code: '90732'
  },
  '90733': {
    country: 'US',
    city: 'San Pedro',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90733'
  },
  '90734': {
    country: 'US',
    city: 'San Pedro',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90734'
  },
  '90740': {
    country: 'US',
    city: 'Seal Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7602,
    longitude: -118.0808,
    postal_code: '90740'
  },
  '90742': {
    country: 'US',
    city: 'Sunset Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7164,
    longitude: -118.069,
    postal_code: '90742'
  },
  '90743': {
    country: 'US',
    city: 'Surfside',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7264,
    longitude: -118.0833,
    postal_code: '90743'
  },
  '90744': {
    country: 'US',
    city: 'Wilmington',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7855,
    longitude: -118.2645,
    postal_code: '90744'
  },
  '90745': {
    country: 'US',
    city: 'Carson',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.823,
    longitude: -118.2684,
    postal_code: '90745'
  },
  '90746': {
    country: 'US',
    city: 'Carson',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8584,
    longitude: -118.2554,
    postal_code: '90746'
  },
  '90747': {
    country: 'US',
    city: 'Carson',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8671,
    longitude: -118.2538,
    postal_code: '90747'
  },
  '90748': {
    country: 'US',
    city: 'Wilmington',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.78,
    longitude: -118.2626,
    postal_code: '90748'
  },
  '90749': {
    country: 'US',
    city: 'Carson',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90749'
  },
  '90755': {
    country: 'US',
    city: 'Signal Hill',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8029,
    longitude: -118.1677,
    postal_code: '90755'
  },
  '90801': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.767,
    longitude: -118.1892,
    postal_code: '90801'
  },
  '90802': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7706,
    longitude: -118.182,
    postal_code: '90802'
  },
  '90803': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7619,
    longitude: -118.1341,
    postal_code: '90803'
  },
  '90804': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7857,
    longitude: -118.1357,
    postal_code: '90804'
  },
  '90805': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8635,
    longitude: -118.1801,
    postal_code: '90805'
  },
  '90806': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8045,
    longitude: -118.1876,
    postal_code: '90806'
  },
  '90807': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8315,
    longitude: -118.1811,
    postal_code: '90807'
  },
  '90808': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8241,
    longitude: -118.1103,
    postal_code: '90808'
  },
  '90809': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90809'
  },
  '90810': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8193,
    longitude: -118.2325,
    postal_code: '90810'
  },
  '90813': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.782,
    longitude: -118.1835,
    postal_code: '90813'
  },
  '90814': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7716,
    longitude: -118.148,
    postal_code: '90814'
  },
  '90815': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7939,
    longitude: -118.1192,
    postal_code: '90815'
  },
  '90822': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7927,
    longitude: -118.1638,
    postal_code: '90822'
  },
  '90831': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7678,
    longitude: -118.1994,
    postal_code: '90831'
  },
  '90832': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.767,
    longitude: -118.1892,
    postal_code: '90832'
  },
  '90833': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7678,
    longitude: -118.1994,
    postal_code: '90833'
  },
  '90834': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7678,
    longitude: -118.1994,
    postal_code: '90834'
  },
  '90835': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7678,
    longitude: -118.1994,
    postal_code: '90835'
  },
  '90840': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7843,
    longitude: -118.1157,
    postal_code: '90840'
  },
  '90842': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90842'
  },
  '90844': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.767,
    longitude: -118.1892,
    postal_code: '90844'
  },
  '90846': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8249,
    longitude: -118.1504,
    postal_code: '90846'
  },
  '90847': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90847'
  },
  '90848': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90848'
  },
  '90853': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '90853'
  },
  '90895': {
    country: 'US',
    city: 'Carson',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.8392,
    longitude: -118.2202,
    postal_code: '90895'
  },
  '90899': {
    country: 'US',
    city: 'Long Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.767,
    longitude: -118.1892,
    postal_code: '90899'
  },
  '91001': {
    country: 'US',
    city: 'Altadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1912,
    longitude: -118.1392,
    postal_code: '91001'
  },
  '91003': {
    country: 'US',
    city: 'Altadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1897,
    longitude: -118.1312,
    postal_code: '91003'
  },
  '91006': {
    country: 'US',
    city: 'Arcadia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1324,
    longitude: -118.0264,
    postal_code: '91006'
  },
  '91007': {
    country: 'US',
    city: 'Arcadia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1243,
    longitude: -118.0515,
    postal_code: '91007'
  },
  '91008': {
    country: 'US',
    city: 'Duarte',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.149,
    longitude: -117.9644,
    postal_code: '91008'
  },
  '91009': {
    country: 'US',
    city: 'Duarte',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1395,
    longitude: -117.9773,
    postal_code: '91009'
  },
  '91010': {
    country: 'US',
    city: 'Duarte',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1407,
    longitude: -117.9567,
    postal_code: '91010'
  },
  '91011': {
    country: 'US',
    city: 'La Canada Flintridge',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2217,
    longitude: -118.2051,
    postal_code: '91011'
  },
  '91012': {
    country: 'US',
    city: 'La Canada Flintridge',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1992,
    longitude: -118.1879,
    postal_code: '91012'
  },
  '91016': {
    country: 'US',
    city: 'Monrovia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.144,
    longitude: -118.0014,
    postal_code: '91016'
  },
  '91017': {
    country: 'US',
    city: 'Monrovia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1481,
    longitude: -117.999,
    postal_code: '91017'
  },
  '91020': {
    country: 'US',
    city: 'Montrose',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2114,
    longitude: -118.2305,
    postal_code: '91020'
  },
  '91021': {
    country: 'US',
    city: 'Montrose',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2064,
    longitude: -118.2242,
    postal_code: '91021'
  },
  '91023': {
    country: 'US',
    city: 'Mount Wilson',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2264,
    longitude: -118.0662,
    postal_code: '91023'
  },
  '91024': {
    country: 'US',
    city: 'Sierra Madre',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1651,
    longitude: -118.0519,
    postal_code: '91024'
  },
  '91025': {
    country: 'US',
    city: 'Sierra Madre',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1617,
    longitude: -118.0528,
    postal_code: '91025'
  },
  '91030': {
    country: 'US',
    city: 'South Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1109,
    longitude: -118.1547,
    postal_code: '91030'
  },
  '91031': {
    country: 'US',
    city: 'South Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1161,
    longitude: -118.1503,
    postal_code: '91031'
  },
  '91040': {
    country: 'US',
    city: 'Sunland',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2618,
    longitude: -118.3371,
    postal_code: '91040'
  },
  '91041': {
    country: 'US',
    city: 'Sunland',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2669,
    longitude: -118.3023,
    postal_code: '91041'
  },
  '91042': {
    country: 'US',
    city: 'Tujunga',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2544,
    longitude: -118.2849,
    postal_code: '91042'
  },
  '91043': {
    country: 'US',
    city: 'Tujunga',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2522,
    longitude: -118.2884,
    postal_code: '91043'
  },
  '91046': {
    country: 'US',
    city: 'Verdugo City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2114,
    longitude: -118.2395,
    postal_code: '91046'
  },
  '91066': {
    country: 'US',
    city: 'Arcadia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1397,
    longitude: -118.0353,
    postal_code: '91066'
  },
  '91077': {
    country: 'US',
    city: 'Arcadia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1397,
    longitude: -118.0353,
    postal_code: '91077'
  },
  '91101': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1468,
    longitude: -118.1391,
    postal_code: '91101'
  },
  '91102': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91102'
  },
  '91103': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1669,
    longitude: -118.1551,
    postal_code: '91103'
  },
  '91104': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1678,
    longitude: -118.1261,
    postal_code: '91104'
  },
  '91105': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1355,
    longitude: -118.1636,
    postal_code: '91105'
  },
  '91106': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1435,
    longitude: -118.1266,
    postal_code: '91106'
  },
  '91107': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.151,
    longitude: -118.0889,
    postal_code: '91107'
  },
  '91108': {
    country: 'US',
    city: 'San Marino',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1207,
    longitude: -118.1117,
    postal_code: '91108'
  },
  '91109': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91109'
  },
  '91110': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91110'
  },
  '91114': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91114'
  },
  '91115': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91115'
  },
  '91116': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91116'
  },
  '91117': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91117'
  },
  '91118': {
    country: 'US',
    city: 'San Marino',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1214,
    longitude: -118.1065,
    postal_code: '91118'
  },
  '91121': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91121'
  },
  '91123': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91123'
  },
  '91124': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91124'
  },
  '91125': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91125'
  },
  '91126': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91126'
  },
  '91129': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91129'
  },
  '91182': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91182'
  },
  '91184': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91184'
  },
  '91185': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91185'
  },
  '91188': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91188'
  },
  '91189': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1445,
    postal_code: '91189'
  },
  '91199': {
    country: 'US',
    city: 'Pasadena',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1478,
    longitude: -118.1436,
    postal_code: '91199'
  },
  '91201': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1716,
    longitude: -118.2899,
    postal_code: '91201'
  },
  '91202': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1652,
    longitude: -118.2656,
    postal_code: '91202'
  },
  '91203': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1517,
    longitude: -118.2636,
    postal_code: '91203'
  },
  '91204': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1379,
    longitude: -118.2599,
    postal_code: '91204'
  },
  '91205': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1378,
    longitude: -118.2425,
    postal_code: '91205'
  },
  '91206': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1556,
    longitude: -118.2322,
    postal_code: '91206'
  },
  '91207': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1649,
    longitude: -118.2451,
    postal_code: '91207'
  },
  '91208': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1921,
    longitude: -118.235,
    postal_code: '91208'
  },
  '91209': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1425,
    longitude: -118.2551,
    postal_code: '91209'
  },
  '91210': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1425,
    longitude: -118.2551,
    postal_code: '91210'
  },
  '91214': {
    country: 'US',
    city: 'La Crescenta',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2316,
    longitude: -118.2457,
    postal_code: '91214'
  },
  '91221': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1425,
    longitude: -118.2551,
    postal_code: '91221'
  },
  '91222': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1425,
    longitude: -118.2551,
    postal_code: '91222'
  },
  '91224': {
    country: 'US',
    city: 'La Crescenta',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2242,
    longitude: -118.2401,
    postal_code: '91224'
  },
  '91225': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1425,
    longitude: -118.2551,
    postal_code: '91225'
  },
  '91226': {
    country: 'US',
    city: 'Glendale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1425,
    longitude: -118.2551,
    postal_code: '91226'
  },
  '91301': {
    country: 'US',
    city: 'Agoura Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1227,
    longitude: -118.7573,
    postal_code: '91301'
  },
  '91302': {
    country: 'US',
    city: 'Calabasas',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1419,
    longitude: -118.6641,
    postal_code: '91302'
  },
  '91303': {
    country: 'US',
    city: 'Canoga Park',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1993,
    longitude: -118.5983,
    postal_code: '91303'
  },
  '91304': {
    country: 'US',
    city: 'Canoga Park',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2197,
    longitude: -118.6111,
    postal_code: '91304'
  },
  '91305': {
    country: 'US',
    city: 'Canoga Park',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2011,
    longitude: -118.5981,
    postal_code: '91305'
  },
  '91306': {
    country: 'US',
    city: 'Winnetka',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2092,
    longitude: -118.5749,
    postal_code: '91306'
  },
  '91307': {
    country: 'US',
    city: 'West Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1963,
    longitude: -118.6389,
    postal_code: '91307'
  },
  '91308': {
    country: 'US',
    city: 'West Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1973,
    longitude: -118.644,
    postal_code: '91308'
  },
  '91309': {
    country: 'US',
    city: 'Canoga Park',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2011,
    longitude: -118.5981,
    postal_code: '91309'
  },
  '91310': {
    country: 'US',
    city: 'Castaic',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4889,
    longitude: -118.6229,
    postal_code: '91310'
  },
  '91311': {
    country: 'US',
    city: 'Chatsworth',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2583,
    longitude: -118.5914,
    postal_code: '91311'
  },
  '91313': {
    country: 'US',
    city: 'Chatsworth',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2572,
    longitude: -118.6012,
    postal_code: '91313'
  },
  '91316': {
    country: 'US',
    city: 'Encino',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1655,
    longitude: -118.5175,
    postal_code: '91316'
  },
  '91319': {
    country: 'US',
    city: 'Newbury Park',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1842,
    longitude: -118.9107,
    postal_code: '91319'
  },
  '91320': {
    country: 'US',
    city: 'Newbury Park',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1774,
    longitude: -118.9358,
    postal_code: '91320'
  },
  '91321': {
    country: 'US',
    city: 'Newhall',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.3795,
    longitude: -118.523,
    postal_code: '91321'
  },
  '91322': {
    country: 'US',
    city: 'Newhall',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.3847,
    longitude: -118.5309,
    postal_code: '91322'
  },
  '91324': {
    country: 'US',
    city: 'Northridge',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2367,
    longitude: -118.5466,
    postal_code: '91324'
  },
  '91325': {
    country: 'US',
    city: 'Northridge',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2353,
    longitude: -118.5188,
    postal_code: '91325'
  },
  '91326': {
    country: 'US',
    city: 'Porter Ranch',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2619,
    longitude: -118.582,
    postal_code: '91326'
  },
  '91327': {
    country: 'US',
    city: 'Northridge',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2283,
    longitude: -118.5368,
    postal_code: '91327'
  },
  '91328': {
    country: 'US',
    city: 'Northridge',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2283,
    longitude: -118.5368,
    postal_code: '91328'
  },
  '91329': {
    country: 'US',
    city: 'Northridge',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2283,
    longitude: -118.5368,
    postal_code: '91329'
  },
  '91330': {
    country: 'US',
    city: 'Northridge',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2283,
    longitude: -118.5368,
    postal_code: '91330'
  },
  '91331': {
    country: 'US',
    city: 'Pacoima',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2556,
    longitude: -118.4208,
    postal_code: '91331'
  },
  '91333': {
    country: 'US',
    city: 'Pacoima',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2625,
    longitude: -118.427,
    postal_code: '91333'
  },
  '91334': {
    country: 'US',
    city: 'Pacoima',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2625,
    longitude: -118.427,
    postal_code: '91334'
  },
  '91335': {
    country: 'US',
    city: 'Reseda',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2007,
    longitude: -118.5391,
    postal_code: '91335'
  },
  '91337': {
    country: 'US',
    city: 'Reseda',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2011,
    longitude: -118.5365,
    postal_code: '91337'
  },
  '91340': {
    country: 'US',
    city: 'San Fernando',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2875,
    longitude: -118.4352,
    postal_code: '91340'
  },
  '91341': {
    country: 'US',
    city: 'San Fernando',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2819,
    longitude: -118.439,
    postal_code: '91341'
  },
  '91342': {
    country: 'US',
    city: 'Sylmar',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.3054,
    longitude: -118.4322,
    postal_code: '91342'
  },
  '91343': {
    country: 'US',
    city: 'North Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2366,
    longitude: -118.4758,
    postal_code: '91343'
  },
  '91344': {
    country: 'US',
    city: 'Granada Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2771,
    longitude: -118.4992,
    postal_code: '91344'
  },
  '91345': {
    country: 'US',
    city: 'Mission Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2619,
    longitude: -118.4587,
    postal_code: '91345'
  },
  '91346': {
    country: 'US',
    city: 'Mission Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2572,
    longitude: -118.467,
    postal_code: '91346'
  },
  '91350': {
    country: 'US',
    city: 'Santa Clarita',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4336,
    longitude: -118.5007,
    postal_code: '91350'
  },
  '91351': {
    country: 'US',
    city: 'Canyon Country',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4262,
    longitude: -118.449,
    postal_code: '91351'
  },
  '91352': {
    country: 'US',
    city: 'Sun Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2209,
    longitude: -118.3699,
    postal_code: '91352'
  },
  '91353': {
    country: 'US',
    city: 'Sun Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2175,
    longitude: -118.3704,
    postal_code: '91353'
  },
  '91354': {
    country: 'US',
    city: 'Valencia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4466,
    longitude: -118.5374,
    postal_code: '91354'
  },
  '91355': {
    country: 'US',
    city: 'Valencia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.3985,
    longitude: -118.5535,
    postal_code: '91355'
  },
  '91356': {
    country: 'US',
    city: 'Tarzana',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1671,
    longitude: -118.5414,
    postal_code: '91356'
  },
  '91357': {
    country: 'US',
    city: 'Tarzana',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1733,
    longitude: -118.554,
    postal_code: '91357'
  },
  '91358': {
    country: 'US',
    city: 'Thousand Oaks',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1706,
    longitude: -118.8376,
    postal_code: '91358'
  },
  '91359': {
    country: 'US',
    city: 'Westlake Village',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1458,
    longitude: -118.8056,
    postal_code: '91359'
  },
  '91360': {
    country: 'US',
    city: 'Thousand Oaks',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2092,
    longitude: -118.8739,
    postal_code: '91360'
  },
  '91361': {
    country: 'US',
    city: 'Westlake Village',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1472,
    longitude: -118.8383,
    postal_code: '91361'
  },
  '91362': {
    country: 'US',
    city: 'Thousand Oaks',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1948,
    longitude: -118.8232,
    postal_code: '91362'
  },
  '91364': {
    country: 'US',
    city: 'Woodland Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1557,
    longitude: -118.6,
    postal_code: '91364'
  },
  '91365': {
    country: 'US',
    city: 'Woodland Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1683,
    longitude: -118.6059,
    postal_code: '91365'
  },
  '91367': {
    country: 'US',
    city: 'Woodland Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1767,
    longitude: -118.6159,
    postal_code: '91367'
  },
  '91371': {
    country: 'US',
    city: 'Woodland Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1683,
    longitude: -118.6059,
    postal_code: '91371'
  },
  '91372': {
    country: 'US',
    city: 'Calabasas',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1578,
    longitude: -118.6384,
    postal_code: '91372'
  },
  '91376': {
    country: 'US',
    city: 'Agoura Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1364,
    longitude: -118.7745,
    postal_code: '91376'
  },
  '91377': {
    country: 'US',
    city: 'Oak Park',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.185,
    longitude: -118.7669,
    postal_code: '91377'
  },
  '91380': {
    country: 'US',
    city: 'Santa Clarita',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.3917,
    longitude: -118.5426,
    postal_code: '91380'
  },
  '91381': {
    country: 'US',
    city: 'Stevenson Ranch',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.3775,
    longitude: -118.6131,
    postal_code: '91381'
  },
  '91382': {
    country: 'US',
    city: 'Santa Clarita',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.3917,
    longitude: -118.5426,
    postal_code: '91382'
  },
  '91383': {
    country: 'US',
    city: 'Santa Clarita',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.3917,
    longitude: -118.5426,
    postal_code: '91383'
  },
  '91384': {
    country: 'US',
    city: 'Castaic',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4827,
    longitude: -118.6254,
    postal_code: '91384'
  },
  '91385': {
    country: 'US',
    city: 'Valencia',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4436,
    longitude: -118.6095,
    postal_code: '91385'
  },
  '91386': {
    country: 'US',
    city: 'Canyon Country',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4233,
    longitude: -118.472,
    postal_code: '91386'
  },
  '91387': {
    country: 'US',
    city: 'Canyon Country',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4132,
    longitude: -118.426,
    postal_code: '91387'
  },
  '91390': {
    country: 'US',
    city: 'Santa Clarita',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4684,
    longitude: -118.5261,
    postal_code: '91390'
  },
  '91392': {
    country: 'US',
    city: 'Sylmar',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.3078,
    longitude: -118.4492,
    postal_code: '91392'
  },
  '91393': {
    country: 'US',
    city: 'North Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2364,
    longitude: -118.4847,
    postal_code: '91393'
  },
  '91394': {
    country: 'US',
    city: 'Granada Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2647,
    longitude: -118.5231,
    postal_code: '91394'
  },
  '91395': {
    country: 'US',
    city: 'Mission Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2572,
    longitude: -118.467,
    postal_code: '91395'
  },
  '91396': {
    country: 'US',
    city: 'Winnetka',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2133,
    longitude: -118.572,
    postal_code: '91396'
  },
  '91401': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1802,
    longitude: -118.4324,
    postal_code: '91401'
  },
  '91402': {
    country: 'US',
    city: 'Panorama City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2262,
    longitude: -118.447,
    postal_code: '91402'
  },
  '91403': {
    country: 'US',
    city: 'Sherman Oaks',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1514,
    longitude: -118.4603,
    postal_code: '91403'
  },
  '91404': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1867,
    longitude: -118.449,
    postal_code: '91404'
  },
  '91405': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2001,
    longitude: -118.4456,
    postal_code: '91405'
  },
  '91406': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2006,
    longitude: -118.4868,
    postal_code: '91406'
  },
  '91407': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1867,
    longitude: -118.449,
    postal_code: '91407'
  },
  '91408': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1867,
    longitude: -118.449,
    postal_code: '91408'
  },
  '91409': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1867,
    longitude: -118.449,
    postal_code: '91409'
  },
  '91410': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1867,
    longitude: -118.449,
    postal_code: '91410'
  },
  '91411': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1781,
    longitude: -118.4574,
    postal_code: '91411'
  },
  '91412': {
    country: 'US',
    city: 'Panorama City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2247,
    longitude: -118.4498,
    postal_code: '91412'
  },
  '91413': {
    country: 'US',
    city: 'Sherman Oaks',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1511,
    longitude: -118.4492,
    postal_code: '91413'
  },
  '91416': {
    country: 'US',
    city: 'Encino',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1592,
    longitude: -118.5012,
    postal_code: '91416'
  },
  '91423': {
    country: 'US',
    city: 'Sherman Oaks',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1526,
    longitude: -118.4322,
    postal_code: '91423'
  },
  '91426': {
    country: 'US',
    city: 'Encino',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1592,
    longitude: -118.5012,
    postal_code: '91426'
  },
  '91436': {
    country: 'US',
    city: 'Encino',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.151,
    longitude: -118.4882,
    postal_code: '91436'
  },
  '91470': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1867,
    longitude: -118.449,
    postal_code: '91470'
  },
  '91482': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1867,
    longitude: -118.449,
    postal_code: '91482'
  },
  '91495': {
    country: 'US',
    city: 'Sherman Oaks',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1511,
    longitude: -118.4492,
    postal_code: '91495'
  },
  '91496': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1867,
    longitude: -118.449,
    postal_code: '91496'
  },
  '91499': {
    country: 'US',
    city: 'Van Nuys',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1867,
    longitude: -118.449,
    postal_code: '91499'
  },
  '91501': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1862,
    longitude: -118.3009,
    postal_code: '91501'
  },
  '91502': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1745,
    longitude: -118.3059,
    postal_code: '91502'
  },
  '91503': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1808,
    longitude: -118.309,
    postal_code: '91503'
  },
  '91504': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2001,
    longitude: -118.3264,
    postal_code: '91504'
  },
  '91505': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.169,
    longitude: -118.3442,
    postal_code: '91505'
  },
  '91506': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1717,
    longitude: -118.3231,
    postal_code: '91506'
  },
  '91507': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1808,
    longitude: -118.309,
    postal_code: '91507'
  },
  '91508': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1808,
    longitude: -118.309,
    postal_code: '91508'
  },
  '91510': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1808,
    longitude: -118.309,
    postal_code: '91510'
  },
  '91521': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1808,
    longitude: -118.309,
    postal_code: '91521'
  },
  '91522': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1808,
    longitude: -118.309,
    postal_code: '91522'
  },
  '91523': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1808,
    longitude: -118.309,
    postal_code: '91523'
  },
  '91526': {
    country: 'US',
    city: 'Burbank',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1808,
    longitude: -118.309,
    postal_code: '91526'
  },
  '91601': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1687,
    longitude: -118.3713,
    postal_code: '91601'
  },
  '91602': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.151,
    longitude: -118.3663,
    postal_code: '91602'
  },
  '91603': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1722,
    longitude: -118.379,
    postal_code: '91603'
  },
  '91604': {
    country: 'US',
    city: 'Studio City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.143,
    longitude: -118.3913,
    postal_code: '91604'
  },
  '91605': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.2057,
    longitude: -118.4001,
    postal_code: '91605'
  },
  '91606': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1872,
    longitude: -118.3865,
    postal_code: '91606'
  },
  '91607': {
    country: 'US',
    city: 'Valley Village',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1672,
    longitude: -118.3989,
    postal_code: '91607'
  },
  '91608': {
    country: 'US',
    city: 'Universal City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1383,
    longitude: -118.3528,
    postal_code: '91608'
  },
  '91609': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1722,
    longitude: -118.379,
    postal_code: '91609'
  },
  '91610': {
    country: 'US',
    city: 'Toluca Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '91610'
  },
  '91611': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1722,
    longitude: -118.379,
    postal_code: '91611'
  },
  '91612': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1722,
    longitude: -118.379,
    postal_code: '91612'
  },
  '91614': {
    country: 'US',
    city: 'Studio City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1486,
    longitude: -118.3965,
    postal_code: '91614'
  },
  '91615': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1722,
    longitude: -118.379,
    postal_code: '91615'
  },
  '91616': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1722,
    longitude: -118.379,
    postal_code: '91616'
  },
  '91617': {
    country: 'US',
    city: 'Valley Village',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1649,
    longitude: -118.3965,
    postal_code: '91617'
  },
  '91618': {
    country: 'US',
    city: 'North Hollywood',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1722,
    longitude: -118.379,
    postal_code: '91618'
  },
  '91701': {
    country: 'US',
    city: 'Rancho Cucamonga',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1376,
    longitude: -117.5999,
    postal_code: '91701'
  },
  '91702': {
    country: 'US',
    city: 'Azusa',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1248,
    longitude: -117.9031,
    postal_code: '91702'
  },
  '91706': {
    country: 'US',
    city: 'Baldwin Park',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0964,
    longitude: -117.9682,
    postal_code: '91706'
  },
  '91708': {
    country: 'US',
    city: 'Chino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 33.954,
    longitude: -117.6404,
    postal_code: '91708'
  },
  '91709': {
    country: 'US',
    city: 'Chino Hills',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 33.9797,
    longitude: -117.7308,
    postal_code: '91709'
  },
  '91710': {
    country: 'US',
    city: 'Chino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0125,
    longitude: -117.6844,
    postal_code: '91710'
  },
  '91711': {
    country: 'US',
    city: 'Claremont',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1092,
    longitude: -117.7183,
    postal_code: '91711'
  },
  '91714': {
    country: 'US',
    city: 'City Of Industry',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0197,
    longitude: -117.9587,
    postal_code: '91714'
  },
  '91715': {
    country: 'US',
    city: 'City Of Industry',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0197,
    longitude: -117.9587,
    postal_code: '91715'
  },
  '91716': {
    country: 'US',
    city: 'City Of Industry',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0197,
    longitude: -117.9587,
    postal_code: '91716'
  },
  '91722': {
    country: 'US',
    city: 'Covina',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0972,
    longitude: -117.9065,
    postal_code: '91722'
  },
  '91723': {
    country: 'US',
    city: 'Covina',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.086,
    longitude: -117.8843,
    postal_code: '91723'
  },
  '91724': {
    country: 'US',
    city: 'Covina',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0938,
    longitude: -117.856,
    postal_code: '91724'
  },
  '91729': {
    country: 'US',
    city: 'Rancho Cucamonga',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1064,
    longitude: -117.5931,
    postal_code: '91729'
  },
  '91730': {
    country: 'US',
    city: 'Rancho Cucamonga',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.107,
    longitude: -117.5941,
    postal_code: '91730'
  },
  '91731': {
    country: 'US',
    city: 'El Monte',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0791,
    longitude: -118.0371,
    postal_code: '91731'
  },
  '91732': {
    country: 'US',
    city: 'El Monte',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0705,
    longitude: -118.0149,
    postal_code: '91732'
  },
  '91733': {
    country: 'US',
    city: 'South El Monte',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0557,
    longitude: -118.0444,
    postal_code: '91733'
  },
  '91734': {
    country: 'US',
    city: 'El Monte',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0686,
    longitude: -118.0276,
    postal_code: '91734'
  },
  '91735': {
    country: 'US',
    city: 'El Monte',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0686,
    longitude: -118.0276,
    postal_code: '91735'
  },
  '91737': {
    country: 'US',
    city: 'Rancho Cucamonga',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1467,
    longitude: -117.5803,
    postal_code: '91737'
  },
  '91739': {
    country: 'US',
    city: 'Rancho Cucamonga',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1705,
    longitude: -117.5182,
    postal_code: '91739'
  },
  '91740': {
    country: 'US',
    city: 'Glendora',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1287,
    longitude: -117.8552,
    postal_code: '91740'
  },
  '91741': {
    country: 'US',
    city: 'Glendora',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1537,
    longitude: -117.8437,
    postal_code: '91741'
  },
  '91743': {
    country: 'US',
    city: 'Guasti',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.065,
    longitude: -117.5864,
    postal_code: '91743'
  },
  '91744': {
    country: 'US',
    city: 'La Puente',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0289,
    longitude: -117.9373,
    postal_code: '91744'
  },
  '91745': {
    country: 'US',
    city: 'Hacienda Heights',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9977,
    longitude: -117.9652,
    postal_code: '91745'
  },
  '91746': {
    country: 'US',
    city: 'La Puente',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0443,
    longitude: -117.9862,
    postal_code: '91746'
  },
  '91747': {
    country: 'US',
    city: 'La Puente',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.02,
    longitude: -117.9495,
    postal_code: '91747'
  },
  '91748': {
    country: 'US',
    city: 'Rowland Heights',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.9818,
    longitude: -117.8969,
    postal_code: '91748'
  },
  '91749': {
    country: 'US',
    city: 'La Puente',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.02,
    longitude: -117.9495,
    postal_code: '91749'
  },
  '91750': {
    country: 'US',
    city: 'La Verne',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1159,
    longitude: -117.7708,
    postal_code: '91750'
  },
  '91752': {
    country: 'US',
    city: 'Mira Loma',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9938,
    longitude: -117.5236,
    postal_code: '91752'
  },
  '91754': {
    country: 'US',
    city: 'Monterey Park',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0534,
    longitude: -118.1271,
    postal_code: '91754'
  },
  '91755': {
    country: 'US',
    city: 'Monterey Park',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.048,
    longitude: -118.115,
    postal_code: '91755'
  },
  '91756': {
    country: 'US',
    city: 'Monterey Park',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0625,
    longitude: -118.1228,
    postal_code: '91756'
  },
  '91758': {
    country: 'US',
    city: 'Ontario',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0633,
    longitude: -117.6509,
    postal_code: '91758'
  },
  '91759': {
    country: 'US',
    city: 'Mt Baldy',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 33.7866,
    longitude: -118.2987,
    postal_code: '91759'
  },
  '91761': {
    country: 'US',
    city: 'Ontario',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0316,
    longitude: -117.6187,
    postal_code: '91761'
  },
  '91762': {
    country: 'US',
    city: 'Ontario',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0584,
    longitude: -117.6665,
    postal_code: '91762'
  },
  '91763': {
    country: 'US',
    city: 'Montclair',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0733,
    longitude: -117.6987,
    postal_code: '91763'
  },
  '91764': {
    country: 'US',
    city: 'Ontario',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0763,
    longitude: -117.6254,
    postal_code: '91764'
  },
  '91765': {
    country: 'US',
    city: 'Diamond Bar',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0066,
    longitude: -117.8098,
    postal_code: '91765'
  },
  '91766': {
    country: 'US',
    city: 'Pomona',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0418,
    longitude: -117.7569,
    postal_code: '91766'
  },
  '91767': {
    country: 'US',
    city: 'Pomona',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0812,
    longitude: -117.7362,
    postal_code: '91767'
  },
  '91768': {
    country: 'US',
    city: 'Pomona',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0662,
    longitude: -117.7763,
    postal_code: '91768'
  },
  '91769': {
    country: 'US',
    city: 'Pomona',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0553,
    longitude: -117.7523,
    postal_code: '91769'
  },
  '91770': {
    country: 'US',
    city: 'Rosemead',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0658,
    longitude: -118.0853,
    postal_code: '91770'
  },
  '91771': {
    country: 'US',
    city: 'Rosemead',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0806,
    longitude: -118.0728,
    postal_code: '91771'
  },
  '91772': {
    country: 'US',
    city: 'Rosemead',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0806,
    longitude: -118.0728,
    postal_code: '91772'
  },
  '91773': {
    country: 'US',
    city: 'San Dimas',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1023,
    longitude: -117.8169,
    postal_code: '91773'
  },
  '91775': {
    country: 'US',
    city: 'San Gabriel',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1155,
    longitude: -118.0857,
    postal_code: '91775'
  },
  '91776': {
    country: 'US',
    city: 'San Gabriel',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.089,
    longitude: -118.0955,
    postal_code: '91776'
  },
  '91778': {
    country: 'US',
    city: 'San Gabriel',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0961,
    longitude: -118.1058,
    postal_code: '91778'
  },
  '91780': {
    country: 'US',
    city: 'Temple City',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.1016,
    longitude: -118.0537,
    postal_code: '91780'
  },
  '91784': {
    country: 'US',
    city: 'Upland',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.141,
    longitude: -117.6581,
    postal_code: '91784'
  },
  '91785': {
    country: 'US',
    city: 'Upland',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1144,
    longitude: -117.6583,
    postal_code: '91785'
  },
  '91786': {
    country: 'US',
    city: 'Upland',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1144,
    longitude: -117.6583,
    postal_code: '91786'
  },
  '91788': {
    country: 'US',
    city: 'Walnut',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0203,
    longitude: -117.8653,
    postal_code: '91788'
  },
  '91789': {
    country: 'US',
    city: 'Walnut',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0183,
    longitude: -117.8546,
    postal_code: '91789'
  },
  '91790': {
    country: 'US',
    city: 'West Covina',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0673,
    longitude: -117.9366,
    postal_code: '91790'
  },
  '91791': {
    country: 'US',
    city: 'West Covina',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0653,
    longitude: -117.8978,
    postal_code: '91791'
  },
  '91792': {
    country: 'US',
    city: 'West Covina',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0229,
    longitude: -117.8975,
    postal_code: '91792'
  },
  '91793': {
    country: 'US',
    city: 'West Covina',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0686,
    longitude: -117.939,
    postal_code: '91793'
  },
  '91801': {
    country: 'US',
    city: 'Alhambra',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0914,
    longitude: -118.1293,
    postal_code: '91801'
  },
  '91802': {
    country: 'US',
    city: 'Alhambra',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0953,
    longitude: -118.127,
    postal_code: '91802'
  },
  '91803': {
    country: 'US',
    city: 'Alhambra',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0745,
    longitude: -118.1434,
    postal_code: '91803'
  },
  '91804': {
    country: 'US',
    city: 'Alhambra',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0953,
    longitude: -118.127,
    postal_code: '91804'
  },
  '91896': {
    country: 'US',
    city: 'Alhambra',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0953,
    longitude: -118.127,
    postal_code: '91896'
  },
  '91899': {
    country: 'US',
    city: 'Alhambra',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.0953,
    longitude: -118.127,
    postal_code: '91899'
  },
  '91901': {
    country: 'US',
    city: 'Alpine',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8282,
    longitude: -116.7543,
    postal_code: '91901'
  },
  '91902': {
    country: 'US',
    city: 'Bonita',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6671,
    longitude: -117.0221,
    postal_code: '91902'
  },
  '91903': {
    country: 'US',
    city: 'Alpine',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8351,
    longitude: -116.7664,
    postal_code: '91903'
  },
  '91905': {
    country: 'US',
    city: 'Boulevard',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6719,
    longitude: -116.32,
    postal_code: '91905'
  },
  '91906': {
    country: 'US',
    city: 'Campo',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6605,
    longitude: -116.4905,
    postal_code: '91906'
  },
  '91908': {
    country: 'US',
    city: 'Bonita',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6578,
    longitude: -117.03,
    postal_code: '91908'
  },
  '91909': {
    country: 'US',
    city: 'Chula Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6401,
    longitude: -117.0842,
    postal_code: '91909'
  },
  '91910': {
    country: 'US',
    city: 'Chula Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6371,
    longitude: -117.0676,
    postal_code: '91910'
  },
  '91911': {
    country: 'US',
    city: 'Chula Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6084,
    longitude: -117.0565,
    postal_code: '91911'
  },
  '91912': {
    country: 'US',
    city: 'Chula Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6401,
    longitude: -117.0842,
    postal_code: '91912'
  },
  '91913': {
    country: 'US',
    city: 'Chula Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6513,
    longitude: -116.9852,
    postal_code: '91913'
  },
  '91914': {
    country: 'US',
    city: 'Chula Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6587,
    longitude: -116.9652,
    postal_code: '91914'
  },
  '91915': {
    country: 'US',
    city: 'Chula Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6315,
    longitude: -116.9408,
    postal_code: '91915'
  },
  '91916': {
    country: 'US',
    city: 'Descanso',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.873,
    longitude: -116.6027,
    postal_code: '91916'
  },
  '91917': {
    country: 'US',
    city: 'Dulzura',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6152,
    longitude: -116.7285,
    postal_code: '91917'
  },
  '91921': {
    country: 'US',
    city: 'Chula Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6401,
    longitude: -117.0842,
    postal_code: '91921'
  },
  '91931': {
    country: 'US',
    city: 'Guatay',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8497,
    longitude: -116.5583,
    postal_code: '91931'
  },
  '91932': {
    country: 'US',
    city: 'Imperial Beach',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.5783,
    longitude: -117.1148,
    postal_code: '91932'
  },
  '91933': {
    country: 'US',
    city: 'Imperial Beach',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.5839,
    longitude: -117.1131,
    postal_code: '91933'
  },
  '91934': {
    country: 'US',
    city: 'Jacumba',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6249,
    longitude: -116.1952,
    postal_code: '91934'
  },
  '91935': {
    country: 'US',
    city: 'Jamul',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7163,
    longitude: -116.8323,
    postal_code: '91935'
  },
  '91941': {
    country: 'US',
    city: 'La Mesa',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7604,
    longitude: -117.0115,
    postal_code: '91941'
  },
  '91942': {
    country: 'US',
    city: 'La Mesa',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7835,
    longitude: -117.0189,
    postal_code: '91942'
  },
  '91943': {
    country: 'US',
    city: 'La Mesa',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7678,
    longitude: -117.0231,
    postal_code: '91943'
  },
  '91944': {
    country: 'US',
    city: 'La Mesa',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7678,
    longitude: -117.0231,
    postal_code: '91944'
  },
  '91945': {
    country: 'US',
    city: 'Lemon Grove',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7332,
    longitude: -117.0326,
    postal_code: '91945'
  },
  '91946': {
    country: 'US',
    city: 'Lemon Grove',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7426,
    longitude: -117.0314,
    postal_code: '91946'
  },
  '91948': {
    country: 'US',
    city: 'Mount Laguna',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8676,
    longitude: -116.4206,
    postal_code: '91948'
  },
  '91950': {
    country: 'US',
    city: 'National City',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6749,
    longitude: -117.0897,
    postal_code: '91950'
  },
  '91951': {
    country: 'US',
    city: 'National City',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6781,
    longitude: -117.0992,
    postal_code: '91951'
  },
  '91962': {
    country: 'US',
    city: 'Pine Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.835,
    longitude: -116.5127,
    postal_code: '91962'
  },
  '91963': {
    country: 'US',
    city: 'Potrero',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6205,
    longitude: -116.6037,
    postal_code: '91963'
  },
  '91976': {
    country: 'US',
    city: 'Spring Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7448,
    longitude: -116.9989,
    postal_code: '91976'
  },
  '91977': {
    country: 'US',
    city: 'Spring Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.724,
    longitude: -116.9976,
    postal_code: '91977'
  },
  '91978': {
    country: 'US',
    city: 'Spring Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7329,
    longitude: -116.9596,
    postal_code: '91978'
  },
  '91979': {
    country: 'US',
    city: 'Spring Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7448,
    longitude: -116.9989,
    postal_code: '91979'
  },
  '91980': {
    country: 'US',
    city: 'Tecate',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.5889,
    longitude: -116.6192,
    postal_code: '91980'
  },
  '91987': {
    country: 'US',
    city: 'Tecate',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.5773,
    longitude: -116.6275,
    postal_code: '91987'
  },
  '92003': {
    country: 'US',
    city: 'Bonsall',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.294,
    longitude: -117.1897,
    postal_code: '92003'
  },
  '92004': {
    country: 'US',
    city: 'Borrego Springs',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.2386,
    longitude: -116.3514,
    postal_code: '92004'
  },
  '92007': {
    country: 'US',
    city: 'Cardiff By The Sea',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.023,
    longitude: -117.2745,
    postal_code: '92007'
  },
  '92008': {
    country: 'US',
    city: 'Carlsbad',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1602,
    longitude: -117.325,
    postal_code: '92008'
  },
  '92009': {
    country: 'US',
    city: 'Carlsbad',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.0954,
    longitude: -117.2619,
    postal_code: '92009'
  },
  '92010': {
    country: 'US',
    city: 'Carlsbad',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1639,
    longitude: -117.3009,
    postal_code: '92010'
  },
  '92011': {
    country: 'US',
    city: 'Carlsbad',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1072,
    longitude: -117.2943,
    postal_code: '92011'
  },
  '92013': {
    country: 'US',
    city: 'Carlsbad',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1581,
    longitude: -117.3506,
    postal_code: '92013'
  },
  '92014': {
    country: 'US',
    city: 'Del Mar',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.9665,
    longitude: -117.249,
    postal_code: '92014'
  },
  '92018': {
    country: 'US',
    city: 'Carlsbad',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1581,
    longitude: -117.3506,
    postal_code: '92018'
  },
  '92019': {
    country: 'US',
    city: 'El Cajon',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7777,
    longitude: -116.9191,
    postal_code: '92019'
  },
  '92020': {
    country: 'US',
    city: 'El Cajon',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7928,
    longitude: -116.9665,
    postal_code: '92020'
  },
  '92021': {
    country: 'US',
    city: 'El Cajon',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8178,
    longitude: -116.9223,
    postal_code: '92021'
  },
  '92022': {
    country: 'US',
    city: 'El Cajon',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7948,
    longitude: -116.9625,
    postal_code: '92022'
  },
  '92023': {
    country: 'US',
    city: 'Encinitas',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.036,
    longitude: -117.292,
    postal_code: '92023'
  },
  '92024': {
    country: 'US',
    city: 'Encinitas',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.0535,
    longitude: -117.2689,
    postal_code: '92024'
  },
  '92025': {
    country: 'US',
    city: 'Escondido',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1101,
    longitude: -117.07,
    postal_code: '92025'
  },
  '92026': {
    country: 'US',
    city: 'Escondido',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1605,
    longitude: -117.0978,
    postal_code: '92026'
  },
  '92027': {
    country: 'US',
    city: 'Escondido',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1388,
    longitude: -117.052,
    postal_code: '92027'
  },
  '92028': {
    country: 'US',
    city: 'Fallbrook',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.369,
    longitude: -117.229,
    postal_code: '92028'
  },
  '92029': {
    country: 'US',
    city: 'Escondido',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.0895,
    longitude: -117.1128,
    postal_code: '92029'
  },
  '92030': {
    country: 'US',
    city: 'Escondido',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1192,
    longitude: -117.0864,
    postal_code: '92030'
  },
  '92033': {
    country: 'US',
    city: 'Escondido',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1192,
    longitude: -117.0864,
    postal_code: '92033'
  },
  '92036': {
    country: 'US',
    city: 'Julian',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.0534,
    longitude: -116.5658,
    postal_code: '92036'
  },
  '92037': {
    country: 'US',
    city: 'La Jolla',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8455,
    longitude: -117.2521,
    postal_code: '92037'
  },
  '92038': {
    country: 'US',
    city: 'La Jolla',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8473,
    longitude: -117.2742,
    postal_code: '92038'
  },
  '92039': {
    country: 'US',
    city: 'La Jolla',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8473,
    longitude: -117.2742,
    postal_code: '92039'
  },
  '92040': {
    country: 'US',
    city: 'Lakeside',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8562,
    longitude: -116.9201,
    postal_code: '92040'
  },
  '92046': {
    country: 'US',
    city: 'Escondido',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1192,
    longitude: -117.0864,
    postal_code: '92046'
  },
  '92049': {
    country: 'US',
    city: 'Oceanside',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1959,
    longitude: -117.3795,
    postal_code: '92049'
  },
  '92051': {
    country: 'US',
    city: 'Oceanside',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1959,
    longitude: -117.3795,
    postal_code: '92051'
  },
  '92052': {
    country: 'US',
    city: 'Oceanside',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1959,
    longitude: -117.3795,
    postal_code: '92052'
  },
  '92054': {
    country: 'US',
    city: 'Oceanside',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.2072,
    longitude: -117.3573,
    postal_code: '92054'
  },
  '92055': {
    country: 'US',
    city: 'Camp Pendleton',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.2433,
    longitude: -117.3768,
    postal_code: '92055'
  },
  '92056': {
    country: 'US',
    city: 'Oceanside',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1968,
    longitude: -117.2831,
    postal_code: '92056'
  },
  '92057': {
    country: 'US',
    city: 'Oceanside',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.2407,
    longitude: -117.3025,
    postal_code: '92057'
  },
  '92058': {
    country: 'US',
    city: 'Oceanside',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1959,
    longitude: -117.3795,
    postal_code: '92058'
  },
  '92059': {
    country: 'US',
    city: 'Pala',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.3777,
    longitude: -117.0717,
    postal_code: '92059'
  },
  '92060': {
    country: 'US',
    city: 'Palomar Mountain',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.3228,
    longitude: -116.8786,
    postal_code: '92060'
  },
  '92061': {
    country: 'US',
    city: 'Pauma Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.3063,
    longitude: -116.9596,
    postal_code: '92061'
  },
  '92064': {
    country: 'US',
    city: 'Poway',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.9756,
    longitude: -117.0402,
    postal_code: '92064'
  },
  '92065': {
    country: 'US',
    city: 'Ramona',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.0293,
    longitude: -116.8535,
    postal_code: '92065'
  },
  '92066': {
    country: 'US',
    city: 'Ranchita',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.21,
    longitude: -116.5167,
    postal_code: '92066'
  },
  '92067': {
    country: 'US',
    city: 'Rancho Santa Fe',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.005,
    longitude: -117.2157,
    postal_code: '92067'
  },
  '92068': {
    country: 'US',
    city: 'San Luis Rey',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.232,
    longitude: -117.3236,
    postal_code: '92068'
  },
  '92069': {
    country: 'US',
    city: 'San Marcos',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1444,
    longitude: -117.1697,
    postal_code: '92069'
  },
  '92070': {
    country: 'US',
    city: 'Santa Ysabel',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1476,
    longitude: -116.6963,
    postal_code: '92070'
  },
  '92071': {
    country: 'US',
    city: 'Santee',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8486,
    longitude: -116.9862,
    postal_code: '92071'
  },
  '92072': {
    country: 'US',
    city: 'Santee',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8384,
    longitude: -116.9739,
    postal_code: '92072'
  },
  '92074': {
    country: 'US',
    city: 'Poway',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.9628,
    longitude: -117.0359,
    postal_code: '92074'
  },
  '92075': {
    country: 'US',
    city: 'Solana Beach',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.9937,
    longitude: -117.2598,
    postal_code: '92075'
  },
  '92078': {
    country: 'US',
    city: 'San Marcos',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1193,
    longitude: -117.185,
    postal_code: '92078'
  },
  '92079': {
    country: 'US',
    city: 'San Marcos',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1434,
    longitude: -117.1661,
    postal_code: '92079'
  },
  '92081': {
    country: 'US',
    city: 'Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1644,
    longitude: -117.2403,
    postal_code: '92081'
  },
  '92082': {
    country: 'US',
    city: 'Valley Center',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.249,
    longitude: -117.0122,
    postal_code: '92082'
  },
  '92083': {
    country: 'US',
    city: 'Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1978,
    longitude: -117.2482,
    postal_code: '92083'
  },
  '92084': {
    country: 'US',
    city: 'Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.2131,
    longitude: -117.2243,
    postal_code: '92084'
  },
  '92085': {
    country: 'US',
    city: 'Vista',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.2,
    longitude: -117.2425,
    postal_code: '92085'
  },
  '92086': {
    country: 'US',
    city: 'Warner Springs',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.3096,
    longitude: -116.6527,
    postal_code: '92086'
  },
  '92088': {
    country: 'US',
    city: 'Fallbrook',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.3764,
    longitude: -117.2511,
    postal_code: '92088'
  },
  '92091': {
    country: 'US',
    city: 'Rancho Santa Fe',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.9623,
    longitude: -117.0462,
    postal_code: '92091'
  },
  '92092': {
    country: 'US',
    city: 'La Jolla',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8473,
    longitude: -117.2742,
    postal_code: '92092'
  },
  '92093': {
    country: 'US',
    city: 'La Jolla',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8473,
    longitude: -117.2742,
    postal_code: '92093'
  },
  '92096': {
    country: 'US',
    city: 'San Marcos',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.1434,
    longitude: -117.1661,
    postal_code: '92096'
  },
  '92101': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7185,
    longitude: -117.1593,
    postal_code: '92101'
  },
  '92102': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7139,
    longitude: -117.1219,
    postal_code: '92102'
  },
  '92103': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7466,
    longitude: -117.1636,
    postal_code: '92103'
  },
  '92104': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7454,
    longitude: -117.1272,
    postal_code: '92104'
  },
  '92105': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7423,
    longitude: -117.0947,
    postal_code: '92105'
  },
  '92106': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7272,
    longitude: -117.2268,
    postal_code: '92106'
  },
  '92107': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7425,
    longitude: -117.2433,
    postal_code: '92107'
  },
  '92108': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7783,
    longitude: -117.1335,
    postal_code: '92108'
  },
  '92109': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7969,
    longitude: -117.2405,
    postal_code: '92109'
  },
  '92110': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7635,
    longitude: -117.2028,
    postal_code: '92110'
  },
  '92111': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7972,
    longitude: -117.1708,
    postal_code: '92111'
  },
  '92112': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92112'
  },
  '92113': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.697,
    longitude: -117.1153,
    postal_code: '92113'
  },
  '92114': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7059,
    longitude: -117.0524,
    postal_code: '92114'
  },
  '92115': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7607,
    longitude: -117.0721,
    postal_code: '92115'
  },
  '92116': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7624,
    longitude: -117.1242,
    postal_code: '92116'
  },
  '92117': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8239,
    longitude: -117.1965,
    postal_code: '92117'
  },
  '92118': {
    country: 'US',
    city: 'Coronado',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6807,
    longitude: -117.1698,
    postal_code: '92118'
  },
  '92119': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8036,
    longitude: -117.0261,
    postal_code: '92119'
  },
  '92120': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7958,
    longitude: -117.0707,
    postal_code: '92120'
  },
  '92121': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8919,
    longitude: -117.2035,
    postal_code: '92121'
  },
  '92122': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8577,
    longitude: -117.2115,
    postal_code: '92122'
  },
  '92123': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7973,
    longitude: -117.1392,
    postal_code: '92123'
  },
  '92124': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8201,
    longitude: -117.0986,
    postal_code: '92124'
  },
  '92126': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.9161,
    longitude: -117.1402,
    postal_code: '92126'
  },
  '92127': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.0279,
    longitude: -117.0856,
    postal_code: '92127'
  },
  '92128': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.0067,
    longitude: -117.069,
    postal_code: '92128'
  },
  '92129': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.9652,
    longitude: -117.1213,
    postal_code: '92129'
  },
  '92130': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.9555,
    longitude: -117.2252,
    postal_code: '92130'
  },
  '92131': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.9123,
    longitude: -117.0898,
    postal_code: '92131'
  },
  '92132': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6437,
    longitude: -117.1384,
    postal_code: '92132'
  },
  '92134': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7242,
    longitude: -117.1466,
    postal_code: '92134'
  },
  '92135': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92135'
  },
  '92136': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6834,
    longitude: -117.1219,
    postal_code: '92136'
  },
  '92137': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8538,
    longitude: -117.1197,
    postal_code: '92137'
  },
  '92138': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92138'
  },
  '92139': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6806,
    longitude: -117.0474,
    postal_code: '92139'
  },
  '92140': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7434,
    longitude: -117.2004,
    postal_code: '92140'
  },
  '92142': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92142'
  },
  '92143': {
    country: 'US',
    city: 'San Ysidro',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 33.2553,
    longitude: -116.5664,
    postal_code: '92143'
  },
  '92145': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8891,
    longitude: -117.1005,
    postal_code: '92145'
  },
  '92147': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92147'
  },
  '92149': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92149'
  },
  '92150': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92150'
  },
  '92152': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92152'
  },
  '92153': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92153'
  },
  '92154': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.5753,
    longitude: -117.0707,
    postal_code: '92154'
  },
  '92155': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6716,
    longitude: -117.1657,
    postal_code: '92155'
  },
  '92158': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92158'
  },
  '92159': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92159'
  },
  '92160': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92160'
  },
  '92161': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.8718,
    longitude: -117.2291,
    postal_code: '92161'
  },
  '92163': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92163'
  },
  '92165': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92165'
  },
  '92166': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92166'
  },
  '92167': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92167'
  },
  '92168': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92168'
  },
  '92169': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92169'
  },
  '92170': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92170'
  },
  '92171': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92171'
  },
  '92172': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92172'
  },
  '92173': {
    country: 'US',
    city: 'San Ysidro',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.5626,
    longitude: -117.043,
    postal_code: '92173'
  },
  '92174': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92174'
  },
  '92175': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92175'
  },
  '92176': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92176'
  },
  '92177': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92177'
  },
  '92178': {
    country: 'US',
    city: 'Coronado',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.6859,
    longitude: -117.1831,
    postal_code: '92178'
  },
  '92179': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92179'
  },
  '92182': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7751,
    longitude: -117.0762,
    postal_code: '92182'
  },
  '92186': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92186'
  },
  '92187': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92187'
  },
  '92190': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92190'
  },
  '92191': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92191'
  },
  '92192': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92192'
  },
  '92193': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92193'
  },
  '92195': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92195'
  },
  '92196': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92196'
  },
  '92197': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92197'
  },
  '92198': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7153,
    longitude: -117.1573,
    postal_code: '92198'
  },
  '92199': {
    country: 'US',
    city: 'San Diego',
    state: 'California',
    state_short: 'CA',
    county: 'San Diego',
    latitude: 32.7516,
    longitude: -117.1918,
    postal_code: '92199'
  },
  '92201': {
    country: 'US',
    city: 'Indio',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7207,
    longitude: -116.2168,
    postal_code: '92201'
  },
  '92202': {
    country: 'US',
    city: 'Indio',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7529,
    longitude: -116.0556,
    postal_code: '92202'
  },
  '92203': {
    country: 'US',
    city: 'Indio',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7532,
    longitude: -116.2676,
    postal_code: '92203'
  },
  '92210': {
    country: 'US',
    city: 'Indian Wells',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7163,
    longitude: -116.3381,
    postal_code: '92210'
  },
  '92211': {
    country: 'US',
    city: 'Palm Desert',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7644,
    longitude: -116.3398,
    postal_code: '92211'
  },
  '92220': {
    country: 'US',
    city: 'Banning',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9282,
    longitude: -116.8899,
    postal_code: '92220'
  },
  '92222': {
    country: 'US',
    city: 'Bard',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.7822,
    longitude: -114.5619,
    postal_code: '92222'
  },
  '92223': {
    country: 'US',
    city: 'Beaumont',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9504,
    longitude: -116.9701,
    postal_code: '92223'
  },
  '92225': {
    country: 'US',
    city: 'Blythe',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.6103,
    longitude: -114.5963,
    postal_code: '92225'
  },
  '92226': {
    country: 'US',
    city: 'Blythe',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.5987,
    longitude: -114.6525,
    postal_code: '92226'
  },
  '92227': {
    country: 'US',
    city: 'Brawley',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.9792,
    longitude: -115.5296,
    postal_code: '92227'
  },
  '92230': {
    country: 'US',
    city: 'Cabazon',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9086,
    longitude: -116.7739,
    postal_code: '92230'
  },
  '92231': {
    country: 'US',
    city: 'Calexico',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.6832,
    longitude: -115.5028,
    postal_code: '92231'
  },
  '92232': {
    country: 'US',
    city: 'Calexico',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.6789,
    longitude: -115.4989,
    postal_code: '92232'
  },
  '92233': {
    country: 'US',
    city: 'Calipatria',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 33.167,
    longitude: -115.5114,
    postal_code: '92233'
  },
  '92234': {
    country: 'US',
    city: 'Cathedral City',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8098,
    longitude: -116.4665,
    postal_code: '92234'
  },
  '92235': {
    country: 'US',
    city: 'Cathedral City',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7797,
    longitude: -116.4653,
    postal_code: '92235'
  },
  '92236': {
    country: 'US',
    city: 'Coachella',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.675,
    longitude: -116.1772,
    postal_code: '92236'
  },
  '92239': {
    country: 'US',
    city: 'Desert Center',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.809,
    longitude: -115.3666,
    postal_code: '92239'
  },
  '92240': {
    country: 'US',
    city: 'Desert Hot Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9531,
    longitude: -116.5219,
    postal_code: '92240'
  },
  '92241': {
    country: 'US',
    city: 'Desert Hot Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8763,
    longitude: -116.354,
    postal_code: '92241'
  },
  '92242': {
    country: 'US',
    city: 'Earp',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1652,
    longitude: -114.3197,
    postal_code: '92242'
  },
  '92243': {
    country: 'US',
    city: 'El Centro',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.7893,
    longitude: -115.5665,
    postal_code: '92243'
  },
  '92244': {
    country: 'US',
    city: 'El Centro',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.7948,
    longitude: -115.6927,
    postal_code: '92244'
  },
  '92247': {
    country: 'US',
    city: 'La Quinta',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.6736,
    longitude: -116.2951,
    postal_code: '92247'
  },
  '92248': {
    country: 'US',
    city: 'La Quinta',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 33.6736,
    longitude: -116.2951,
    postal_code: '92248'
  },
  '92249': {
    country: 'US',
    city: 'Heber',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.7218,
    longitude: -115.4383,
    postal_code: '92249'
  },
  '92250': {
    country: 'US',
    city: 'Holtville',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.8104,
    longitude: -115.3775,
    postal_code: '92250'
  },
  '92251': {
    country: 'US',
    city: 'Imperial',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.847,
    longitude: -115.573,
    postal_code: '92251'
  },
  '92252': {
    country: 'US',
    city: 'Joshua Tree',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1502,
    longitude: -116.3038,
    postal_code: '92252'
  },
  '92253': {
    country: 'US',
    city: 'La Quinta',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.6685,
    longitude: -116.3081,
    postal_code: '92253'
  },
  '92254': {
    country: 'US',
    city: 'Mecca',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.545,
    longitude: -116.0187,
    postal_code: '92254'
  },
  '92255': {
    country: 'US',
    city: 'Palm Desert',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7225,
    longitude: -116.377,
    postal_code: '92255'
  },
  '92256': {
    country: 'US',
    city: 'Morongo Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0606,
    longitude: -116.5656,
    postal_code: '92256'
  },
  '92257': {
    country: 'US',
    city: 'Niland',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 33.3784,
    longitude: -115.6965,
    postal_code: '92257'
  },
  '92258': {
    country: 'US',
    city: 'North Palm Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9228,
    longitude: -116.5431,
    postal_code: '92258'
  },
  '92259': {
    country: 'US',
    city: 'Ocotillo',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.7387,
    longitude: -115.9942,
    postal_code: '92259'
  },
  '92260': {
    country: 'US',
    city: 'Palm Desert',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7225,
    longitude: -116.377,
    postal_code: '92260'
  },
  '92261': {
    country: 'US',
    city: 'Palm Desert',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.6604,
    longitude: -116.4082,
    postal_code: '92261'
  },
  '92262': {
    country: 'US',
    city: 'Palm Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8414,
    longitude: -116.5347,
    postal_code: '92262'
  },
  '92263': {
    country: 'US',
    city: 'Palm Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7611,
    longitude: -116.5359,
    postal_code: '92263'
  },
  '92264': {
    country: 'US',
    city: 'Palm Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8018,
    longitude: -116.517,
    postal_code: '92264'
  },
  '92266': {
    country: 'US',
    city: 'Palo Verde',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 33.3696,
    longitude: -114.7355,
    postal_code: '92266'
  },
  '92267': {
    country: 'US',
    city: 'Parker Dam',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2872,
    longitude: -114.143,
    postal_code: '92267'
  },
  '92268': {
    country: 'US',
    city: 'Pioneertown',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1887,
    longitude: -116.5048,
    postal_code: '92268'
  },
  '92270': {
    country: 'US',
    city: 'Rancho Mirage',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7643,
    longitude: -116.4225,
    postal_code: '92270'
  },
  '92273': {
    country: 'US',
    city: 'Seeley',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.7941,
    longitude: -115.6948,
    postal_code: '92273'
  },
  '92274': {
    country: 'US',
    city: 'Thermal',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.5578,
    longitude: -116.1572,
    postal_code: '92274'
  },
  '92275': {
    country: 'US',
    city: 'Salton City',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 33.3092,
    longitude: -115.9578,
    postal_code: '92275'
  },
  '92276': {
    country: 'US',
    city: 'Thousand Palms',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8082,
    longitude: -116.3713,
    postal_code: '92276'
  },
  '92277': {
    country: 'US',
    city: 'Twentynine Palms',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1455,
    longitude: -116.0601,
    postal_code: '92277'
  },
  '92278': {
    country: 'US',
    city: 'Twentynine Palms',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.238,
    longitude: -116.0604,
    postal_code: '92278'
  },
  '92280': {
    country: 'US',
    city: 'Vidal',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1561,
    longitude: -114.5656,
    postal_code: '92280'
  },
  '92281': {
    country: 'US',
    city: 'Westmorland',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 33.038,
    longitude: -115.5914,
    postal_code: '92281'
  },
  '92282': {
    country: 'US',
    city: 'Whitewater',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.989,
    longitude: -116.6566,
    postal_code: '92282'
  },
  '92283': {
    country: 'US',
    city: 'Winterhaven',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 32.9818,
    longitude: -114.6854,
    postal_code: '92283'
  },
  '92284': {
    country: 'US',
    city: 'Yucca Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1559,
    longitude: -116.4313,
    postal_code: '92284'
  },
  '92285': {
    country: 'US',
    city: 'Landers',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.3103,
    longitude: -116.5241,
    postal_code: '92285'
  },
  '92286': {
    country: 'US',
    city: 'Yucca Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1803,
    longitude: -116.35,
    postal_code: '92286'
  },
  '92301': {
    country: 'US',
    city: 'Adelanto',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.5841,
    longitude: -117.4242,
    postal_code: '92301'
  },
  '92304': {
    country: 'US',
    city: 'Amboy',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.5578,
    longitude: -115.7444,
    postal_code: '92304'
  },
  '92305': {
    country: 'US',
    city: 'Angelus Oaks',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1531,
    longitude: -116.9485,
    postal_code: '92305'
  },
  '92307': {
    country: 'US',
    city: 'Apple Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.5291,
    longitude: -117.2132,
    postal_code: '92307'
  },
  '92308': {
    country: 'US',
    city: 'Apple Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.4698,
    longitude: -117.1927,
    postal_code: '92308'
  },
  '92309': {
    country: 'US',
    city: 'Baker',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 35.3606,
    longitude: -116.0638,
    postal_code: '92309'
  },
  '92310': {
    country: 'US',
    city: 'Fort Irwin',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 35.2625,
    longitude: -116.6966,
    postal_code: '92310'
  },
  '92311': {
    country: 'US',
    city: 'Barstow',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.8914,
    longitude: -117.0387,
    postal_code: '92311'
  },
  '92312': {
    country: 'US',
    city: 'Barstow',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.8986,
    longitude: -117.0228,
    postal_code: '92312'
  },
  '92313': {
    country: 'US',
    city: 'Grand Terrace',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.031,
    longitude: -117.3129,
    postal_code: '92313'
  },
  '92314': {
    country: 'US',
    city: 'Big Bear City',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.261,
    longitude: -116.8131,
    postal_code: '92314'
  },
  '92315': {
    country: 'US',
    city: 'Big Bear Lake',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.235,
    longitude: -116.9053,
    postal_code: '92315'
  },
  '92316': {
    country: 'US',
    city: 'Bloomington',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0662,
    longitude: -117.3993,
    postal_code: '92316'
  },
  '92317': {
    country: 'US',
    city: 'Blue Jay',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2112,
    longitude: -117.0796,
    postal_code: '92317'
  },
  '92318': {
    country: 'US',
    city: 'Bryn Mawr',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0483,
    longitude: -117.2309,
    postal_code: '92318'
  },
  '92320': {
    country: 'US',
    city: 'Calimesa',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9946,
    longitude: -117.043,
    postal_code: '92320'
  },
  '92321': {
    country: 'US',
    city: 'Cedar Glen',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2545,
    longitude: -117.1533,
    postal_code: '92321'
  },
  '92322': {
    country: 'US',
    city: 'Cedarpines Park',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2544,
    longitude: -117.3265,
    postal_code: '92322'
  },
  '92323': {
    country: 'US',
    city: 'Cima',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 35.2378,
    longitude: -115.4992,
    postal_code: '92323'
  },
  '92324': {
    country: 'US',
    city: 'Colton',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0315,
    longitude: -117.2874,
    postal_code: '92324'
  },
  '92325': {
    country: 'US',
    city: 'Crestline',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2433,
    longitude: -117.2811,
    postal_code: '92325'
  },
  '92327': {
    country: 'US',
    city: 'Daggett',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.8668,
    longitude: -116.8876,
    postal_code: '92327'
  },
  '92328': {
    country: 'US',
    city: 'Death Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 36.4672,
    longitude: -116.8937,
    postal_code: '92328'
  },
  '92329': {
    country: 'US',
    city: 'Phelan',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.4261,
    longitude: -117.5723,
    postal_code: '92329'
  },
  '92331': {
    country: 'US',
    city: 'Fontana',
    state: 'California',
    state_short: 'CA',
    county: 'Imperial',
    latitude: 34.0922,
    longitude: -117.4612,
    postal_code: '92331'
  },
  '92332': {
    country: 'US',
    city: 'Essex',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.5881,
    longitude: -115.5771,
    postal_code: '92332'
  },
  '92333': {
    country: 'US',
    city: 'Fawnskin',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2583,
    longitude: -116.9515,
    postal_code: '92333'
  },
  '92334': {
    country: 'US',
    city: 'Fontana',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0922,
    longitude: -117.435,
    postal_code: '92334'
  },
  '92335': {
    country: 'US',
    city: 'Fontana',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0794,
    longitude: -117.4551,
    postal_code: '92335'
  },
  '92336': {
    country: 'US',
    city: 'Fontana',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1173,
    longitude: -117.4378,
    postal_code: '92336'
  },
  '92337': {
    country: 'US',
    city: 'Fontana',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0498,
    longitude: -117.4706,
    postal_code: '92337'
  },
  '92338': {
    country: 'US',
    city: 'Ludlow',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.7211,
    longitude: -116.16,
    postal_code: '92338'
  },
  '92339': {
    country: 'US',
    city: 'Forest Falls',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0937,
    longitude: -116.9362,
    postal_code: '92339'
  },
  '92340': {
    country: 'US',
    city: 'Hesperia',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.4264,
    longitude: -117.3009,
    postal_code: '92340'
  },
  '92341': {
    country: 'US',
    city: 'Green Valley Lake',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2348,
    longitude: -117.066,
    postal_code: '92341'
  },
  '92342': {
    country: 'US',
    city: 'Helendale',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.7499,
    longitude: -117.3367,
    postal_code: '92342'
  },
  '92344': {
    country: 'US',
    city: 'Hesperia',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.4239,
    longitude: -117.4075,
    postal_code: '92344'
  },
  '92345': {
    country: 'US',
    city: 'Hesperia',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.4222,
    longitude: -117.3025,
    postal_code: '92345'
  },
  '92346': {
    country: 'US',
    city: 'Highland',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1283,
    longitude: -117.2087,
    postal_code: '92346'
  },
  '92347': {
    country: 'US',
    city: 'Hinkley',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.9279,
    longitude: -117.1809,
    postal_code: '92347'
  },
  '92350': {
    country: 'US',
    city: 'Loma Linda',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0483,
    longitude: -117.2612,
    postal_code: '92350'
  },
  '92352': {
    country: 'US',
    city: 'Lake Arrowhead',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2606,
    longitude: -117.2016,
    postal_code: '92352'
  },
  '92354': {
    country: 'US',
    city: 'Loma Linda',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0528,
    longitude: -117.2513,
    postal_code: '92354'
  },
  '92356': {
    country: 'US',
    city: 'Lucerne Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.447,
    longitude: -116.9189,
    postal_code: '92356'
  },
  '92357': {
    country: 'US',
    city: 'Loma Linda',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0483,
    longitude: -117.2612,
    postal_code: '92357'
  },
  '92358': {
    country: 'US',
    city: 'Lytle Creek',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2558,
    longitude: -117.5186,
    postal_code: '92358'
  },
  '92359': {
    country: 'US',
    city: 'Mentone',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0774,
    longitude: -117.1126,
    postal_code: '92359'
  },
  '92363': {
    country: 'US',
    city: 'Needles',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.7824,
    longitude: -114.5871,
    postal_code: '92363'
  },
  '92364': {
    country: 'US',
    city: 'Nipton',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 35.4667,
    longitude: -115.2722,
    postal_code: '92364'
  },
  '92365': {
    country: 'US',
    city: 'Newberry Springs',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.885,
    longitude: -116.7464,
    postal_code: '92365'
  },
  '92366': {
    country: 'US',
    city: 'Mountain Pass',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 35.4703,
    longitude: -115.545,
    postal_code: '92366'
  },
  '92368': {
    country: 'US',
    city: 'Oro Grande',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.6178,
    longitude: -117.3327,
    postal_code: '92368'
  },
  '92369': {
    country: 'US',
    city: 'Patton',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1358,
    longitude: -117.2239,
    postal_code: '92369'
  },
  '92371': {
    country: 'US',
    city: 'Phelan',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.4449,
    longitude: -117.5196,
    postal_code: '92371'
  },
  '92372': {
    country: 'US',
    city: 'Pinon Hills',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.4429,
    longitude: -117.6403,
    postal_code: '92372'
  },
  '92373': {
    country: 'US',
    city: 'Redlands',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0397,
    longitude: -117.1804,
    postal_code: '92373'
  },
  '92374': {
    country: 'US',
    city: 'Redlands',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.065,
    longitude: -117.1672,
    postal_code: '92374'
  },
  '92375': {
    country: 'US',
    city: 'Redlands',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0556,
    longitude: -117.1825,
    postal_code: '92375'
  },
  '92376': {
    country: 'US',
    city: 'Rialto',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1132,
    longitude: -117.3771,
    postal_code: '92376'
  },
  '92377': {
    country: 'US',
    city: 'Rialto',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1561,
    longitude: -117.4042,
    postal_code: '92377'
  },
  '92378': {
    country: 'US',
    city: 'Rimforest',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2297,
    longitude: -117.225,
    postal_code: '92378'
  },
  '92382': {
    country: 'US',
    city: 'Running Springs',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2102,
    longitude: -117.1109,
    postal_code: '92382'
  },
  '92384': {
    country: 'US',
    city: 'Shoshone',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 35.973,
    longitude: -116.2711,
    postal_code: '92384'
  },
  '92385': {
    country: 'US',
    city: 'Skyforest',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2353,
    longitude: -117.1792,
    postal_code: '92385'
  },
  '92386': {
    country: 'US',
    city: 'Sugarloaf',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2372,
    longitude: -116.8277,
    postal_code: '92386'
  },
  '92389': {
    country: 'US',
    city: 'Tecopa',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 35.9021,
    longitude: -116.1544,
    postal_code: '92389'
  },
  '92391': {
    country: 'US',
    city: 'Twin Peaks',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2379,
    longitude: -117.2348,
    postal_code: '92391'
  },
  '92392': {
    country: 'US',
    city: 'Victorville',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.4802,
    longitude: -117.4082,
    postal_code: '92392'
  },
  '92393': {
    country: 'US',
    city: 'Victorville',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.5361,
    longitude: -117.2912,
    postal_code: '92393'
  },
  '92394': {
    country: 'US',
    city: 'Victorville',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.5563,
    longitude: -117.3528,
    postal_code: '92394'
  },
  '92395': {
    country: 'US',
    city: 'Victorville',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.5016,
    longitude: -117.2944,
    postal_code: '92395'
  },
  '92397': {
    country: 'US',
    city: 'Wrightwood',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.3628,
    longitude: -117.6249,
    postal_code: '92397'
  },
  '92398': {
    country: 'US',
    city: 'Yermo',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.905,
    longitude: -116.8203,
    postal_code: '92398'
  },
  '92399': {
    country: 'US',
    city: 'Yucaipa',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0282,
    longitude: -117.0489,
    postal_code: '92399'
  },
  '92401': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1105,
    longitude: -117.2898,
    postal_code: '92401'
  },
  '92402': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1083,
    longitude: -117.2898,
    postal_code: '92402'
  },
  '92403': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1083,
    longitude: -117.2898,
    postal_code: '92403'
  },
  '92404': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1426,
    longitude: -117.2606,
    postal_code: '92404'
  },
  '92405': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1446,
    longitude: -117.3013,
    postal_code: '92405'
  },
  '92406': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1083,
    longitude: -117.2898,
    postal_code: '92406'
  },
  '92407': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.2166,
    longitude: -117.3908,
    postal_code: '92407'
  },
  '92408': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.0831,
    longitude: -117.2711,
    postal_code: '92408'
  },
  '92410': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1069,
    longitude: -117.2975,
    postal_code: '92410'
  },
  '92411': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1214,
    longitude: -117.3172,
    postal_code: '92411'
  },
  '92413': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1083,
    longitude: -117.2898,
    postal_code: '92413'
  },
  '92415': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1083,
    longitude: -117.2898,
    postal_code: '92415'
  },
  '92418': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1083,
    longitude: -117.2898,
    postal_code: '92418'
  },
  '92423': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1083,
    longitude: -117.2898,
    postal_code: '92423'
  },
  '92427': {
    country: 'US',
    city: 'San Bernardino',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 34.1083,
    longitude: -117.2898,
    postal_code: '92427'
  },
  '92501': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9924,
    longitude: -117.3694,
    postal_code: '92501'
  },
  '92502': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9533,
    longitude: -117.3962,
    postal_code: '92502'
  },
  '92503': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9208,
    longitude: -117.4589,
    postal_code: '92503'
  },
  '92504': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9315,
    longitude: -117.4119,
    postal_code: '92504'
  },
  '92505': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9228,
    longitude: -117.4867,
    postal_code: '92505'
  },
  '92506': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9455,
    longitude: -117.3757,
    postal_code: '92506'
  },
  '92507': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9761,
    longitude: -117.3389,
    postal_code: '92507'
  },
  '92508': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8897,
    longitude: -117.3043,
    postal_code: '92508'
  },
  '92509': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 34.0033,
    longitude: -117.445,
    postal_code: '92509'
  },
  '92513': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9533,
    longitude: -117.3962,
    postal_code: '92513'
  },
  '92514': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9533,
    longitude: -117.3962,
    postal_code: '92514'
  },
  '92515': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9533,
    longitude: -117.3962,
    postal_code: '92515'
  },
  '92516': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9533,
    longitude: -117.3962,
    postal_code: '92516'
  },
  '92517': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9533,
    longitude: -117.3962,
    postal_code: '92517'
  },
  '92518': {
    country: 'US',
    city: 'March Air Reserve Base',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9533,
    longitude: -117.3962,
    postal_code: '92518'
  },
  '92519': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9533,
    longitude: -117.3962,
    postal_code: '92519'
  },
  '92521': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9533,
    longitude: -117.3962,
    postal_code: '92521'
  },
  '92522': {
    country: 'US',
    city: 'Riverside',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9533,
    longitude: -117.3962,
    postal_code: '92522'
  },
  '92530': {
    country: 'US',
    city: 'Lake Elsinore',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.6598,
    longitude: -117.3485,
    postal_code: '92530'
  },
  '92531': {
    country: 'US',
    city: 'Lake Elsinore',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.6681,
    longitude: -117.3273,
    postal_code: '92531'
  },
  '92532': {
    country: 'US',
    city: 'Lake Elsinore',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.6927,
    longitude: -117.303,
    postal_code: '92532'
  },
  '92536': {
    country: 'US',
    city: 'Aguanga',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.4473,
    longitude: -116.7997,
    postal_code: '92536'
  },
  '92539': {
    country: 'US',
    city: 'Anza',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.5688,
    longitude: -116.7135,
    postal_code: '92539'
  },
  '92543': {
    country: 'US',
    city: 'Hemet',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7416,
    longitude: -116.973,
    postal_code: '92543'
  },
  '92544': {
    country: 'US',
    city: 'Hemet',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.739,
    longitude: -116.9243,
    postal_code: '92544'
  },
  '92545': {
    country: 'US',
    city: 'Hemet',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7399,
    longitude: -117.0151,
    postal_code: '92545'
  },
  '92546': {
    country: 'US',
    city: 'Hemet',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7476,
    longitude: -116.9731,
    postal_code: '92546'
  },
  '92548': {
    country: 'US',
    city: 'Homeland',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7453,
    longitude: -117.1118,
    postal_code: '92548'
  },
  '92549': {
    country: 'US',
    city: 'Idyllwild',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7304,
    longitude: -116.7107,
    postal_code: '92549'
  },
  '92551': {
    country: 'US',
    city: 'Moreno Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8814,
    longitude: -117.2261,
    postal_code: '92551'
  },
  '92552': {
    country: 'US',
    city: 'Moreno Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9375,
    longitude: -117.2306,
    postal_code: '92552'
  },
  '92553': {
    country: 'US',
    city: 'Moreno Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9157,
    longitude: -117.2351,
    postal_code: '92553'
  },
  '92554': {
    country: 'US',
    city: 'Moreno Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9375,
    longitude: -117.2306,
    postal_code: '92554'
  },
  '92555': {
    country: 'US',
    city: 'Moreno Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9377,
    longitude: -117.1851,
    postal_code: '92555'
  },
  '92556': {
    country: 'US',
    city: 'Moreno Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9375,
    longitude: -117.2306,
    postal_code: '92556'
  },
  '92557': {
    country: 'US',
    city: 'Moreno Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9553,
    longitude: -117.2457,
    postal_code: '92557'
  },
  '92561': {
    country: 'US',
    city: 'Mountain Center',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7042,
    longitude: -116.7259,
    postal_code: '92561'
  },
  '92562': {
    country: 'US',
    city: 'Murrieta',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.5631,
    longitude: -117.2738,
    postal_code: '92562'
  },
  '92563': {
    country: 'US',
    city: 'Murrieta',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.569,
    longitude: -117.1783,
    postal_code: '92563'
  },
  '92564': {
    country: 'US',
    city: 'Murrieta',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.5539,
    longitude: -117.2139,
    postal_code: '92564'
  },
  '92567': {
    country: 'US',
    city: 'Nuevo',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8123,
    longitude: -117.1048,
    postal_code: '92567'
  },
  '92570': {
    country: 'US',
    city: 'Perris',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7852,
    longitude: -117.3166,
    postal_code: '92570'
  },
  '92571': {
    country: 'US',
    city: 'Perris',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.811,
    longitude: -117.218,
    postal_code: '92571'
  },
  '92572': {
    country: 'US',
    city: 'Perris',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7825,
    longitude: -117.2286,
    postal_code: '92572'
  },
  '92581': {
    country: 'US',
    city: 'San Jacinto',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7839,
    longitude: -116.9586,
    postal_code: '92581'
  },
  '92582': {
    country: 'US',
    city: 'San Jacinto',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7883,
    longitude: -116.9819,
    postal_code: '92582'
  },
  '92583': {
    country: 'US',
    city: 'San Jacinto',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7967,
    longitude: -116.9324,
    postal_code: '92583'
  },
  '92584': {
    country: 'US',
    city: 'Menifee',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.6647,
    longitude: -117.1743,
    postal_code: '92584'
  },
  '92585': {
    country: 'US',
    city: 'Sun City',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7467,
    longitude: -117.1721,
    postal_code: '92585'
  },
  '92586': {
    country: 'US',
    city: 'Sun City',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7044,
    longitude: -117.1969,
    postal_code: '92586'
  },
  '92587': {
    country: 'US',
    city: 'Quail Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.707,
    longitude: -117.245,
    postal_code: '92587'
  },
  '92589': {
    country: 'US',
    city: 'Temecula',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.4936,
    longitude: -117.1484,
    postal_code: '92589'
  },
  '92590': {
    country: 'US',
    city: 'Temecula',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.4903,
    longitude: -117.1824,
    postal_code: '92590'
  },
  '92591': {
    country: 'US',
    city: 'Temecula',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.5217,
    longitude: -117.1286,
    postal_code: '92591'
  },
  '92592': {
    country: 'US',
    city: 'Temecula',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.4983,
    longitude: -117.0958,
    postal_code: '92592'
  },
  '92593': {
    country: 'US',
    city: 'Temecula',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.4936,
    longitude: -117.1484,
    postal_code: '92593'
  },
  '92595': {
    country: 'US',
    city: 'Wildomar',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.6021,
    longitude: -117.264,
    postal_code: '92595'
  },
  '92596': {
    country: 'US',
    city: 'Winchester',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.6243,
    longitude: -117.0885,
    postal_code: '92596'
  },
  '92599': {
    country: 'US',
    city: 'Perris',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7825,
    longitude: -117.2286,
    postal_code: '92599'
  },
  '92602': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7419,
    longitude: -117.7467,
    postal_code: '92602'
  },
  '92603': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6245,
    longitude: -117.794,
    postal_code: '92603'
  },
  '92604': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6899,
    longitude: -117.7868,
    postal_code: '92604'
  },
  '92605': {
    country: 'US',
    city: 'Huntington Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7151,
    longitude: -118.0077,
    postal_code: '92605'
  },
  '92606': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6951,
    longitude: -117.8224,
    postal_code: '92606'
  },
  '92607': {
    country: 'US',
    city: 'Laguna Niguel',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.5269,
    longitude: -117.7117,
    postal_code: '92607'
  },
  '92609': {
    country: 'US',
    city: 'El Toro',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.624,
    longitude: -117.6908,
    postal_code: '92609'
  },
  '92610': {
    country: 'US',
    city: 'Foothill Ranch',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6748,
    longitude: -117.6649,
    postal_code: '92610'
  },
  '92612': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6607,
    longitude: -117.8264,
    postal_code: '92612'
  },
  '92614': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6829,
    longitude: -117.8298,
    postal_code: '92614'
  },
  '92615': {
    country: 'US',
    city: 'Huntington Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6566,
    longitude: -117.9699,
    postal_code: '92615'
  },
  '92616': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6519,
    longitude: -117.8361,
    postal_code: '92616'
  },
  '92617': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6425,
    longitude: -117.8417,
    postal_code: '92617'
  },
  '92618': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7074,
    longitude: -117.7054,
    postal_code: '92618'
  },
  '92619': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6706,
    longitude: -117.7645,
    postal_code: '92619'
  },
  '92620': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7009,
    longitude: -117.7564,
    postal_code: '92620'
  },
  '92623': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6942,
    longitude: -117.8126,
    postal_code: '92623'
  },
  '92624': {
    country: 'US',
    city: 'Capistrano Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.46,
    longitude: -117.6632,
    postal_code: '92624'
  },
  '92625': {
    country: 'US',
    city: 'Corona Del Mar',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6021,
    longitude: -117.8743,
    postal_code: '92625'
  },
  '92626': {
    country: 'US',
    city: 'Costa Mesa',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6801,
    longitude: -117.9085,
    postal_code: '92626'
  },
  '92627': {
    country: 'US',
    city: 'Costa Mesa',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6483,
    longitude: -117.9155,
    postal_code: '92627'
  },
  '92628': {
    country: 'US',
    city: 'Costa Mesa',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6401,
    longitude: -117.9159,
    postal_code: '92628'
  },
  '92629': {
    country: 'US',
    city: 'Dana Point',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.4743,
    longitude: -117.6964,
    postal_code: '92629'
  },
  '92630': {
    country: 'US',
    city: 'Lake Forest',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6437,
    longitude: -117.6868,
    postal_code: '92630'
  },
  '92637': {
    country: 'US',
    city: 'Laguna Woods',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6103,
    longitude: -117.7253,
    postal_code: '92637'
  },
  '92646': {
    country: 'US',
    city: 'Huntington Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6654,
    longitude: -117.9686,
    postal_code: '92646'
  },
  '92647': {
    country: 'US',
    city: 'Huntington Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.721,
    longitude: -118.0033,
    postal_code: '92647'
  },
  '92648': {
    country: 'US',
    city: 'Huntington Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6773,
    longitude: -118.0051,
    postal_code: '92648'
  },
  '92649': {
    country: 'US',
    city: 'Huntington Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.718,
    longitude: -118.0505,
    postal_code: '92649'
  },
  '92650': {
    country: 'US',
    city: 'East Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6795,
    longitude: -117.7609,
    postal_code: '92650'
  },
  '92651': {
    country: 'US',
    city: 'Laguna Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.5429,
    longitude: -117.7813,
    postal_code: '92651'
  },
  '92652': {
    country: 'US',
    city: 'Laguna Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.543,
    longitude: -117.7815,
    postal_code: '92652'
  },
  '92653': {
    country: 'US',
    city: 'Laguna Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.5916,
    longitude: -117.6985,
    postal_code: '92653'
  },
  '92654': {
    country: 'US',
    city: 'Laguna Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6042,
    longitude: -117.7154,
    postal_code: '92654'
  },
  '92655': {
    country: 'US',
    city: 'Midway City',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7446,
    longitude: -117.984,
    postal_code: '92655'
  },
  '92656': {
    country: 'US',
    city: 'Aliso Viejo',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.5701,
    longitude: -117.7086,
    postal_code: '92656'
  },
  '92657': {
    country: 'US',
    city: 'Newport Coast',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.5943,
    longitude: -117.8334,
    postal_code: '92657'
  },
  '92658': {
    country: 'US',
    city: 'Newport Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6422,
    longitude: -117.8631,
    postal_code: '92658'
  },
  '92659': {
    country: 'US',
    city: 'Newport Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6222,
    longitude: -117.9235,
    postal_code: '92659'
  },
  '92660': {
    country: 'US',
    city: 'Newport Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6295,
    longitude: -117.8684,
    postal_code: '92660'
  },
  '92661': {
    country: 'US',
    city: 'Newport Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6045,
    longitude: -117.9021,
    postal_code: '92661'
  },
  '92662': {
    country: 'US',
    city: 'Newport Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6062,
    longitude: -117.8931,
    postal_code: '92662'
  },
  '92663': {
    country: 'US',
    city: 'Newport Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.621,
    longitude: -117.9321,
    postal_code: '92663'
  },
  '92672': {
    country: 'US',
    city: 'San Clemente',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.4361,
    longitude: -117.6231,
    postal_code: '92672'
  },
  '92673': {
    country: 'US',
    city: 'San Clemente',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.4615,
    longitude: -117.6375,
    postal_code: '92673'
  },
  '92674': {
    country: 'US',
    city: 'San Clemente',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.4409,
    longitude: -117.6211,
    postal_code: '92674'
  },
  '92675': {
    country: 'US',
    city: 'San Juan Capistrano',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.5085,
    longitude: -117.6565,
    postal_code: '92675'
  },
  '92676': {
    country: 'US',
    city: 'Silverado',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7451,
    longitude: -117.6153,
    postal_code: '92676'
  },
  '92677': {
    country: 'US',
    city: 'Laguna Niguel',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.5145,
    longitude: -117.7084,
    postal_code: '92677'
  },
  '92678': {
    country: 'US',
    city: 'Trabuco Canyon',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6643,
    longitude: -117.5896,
    postal_code: '92678'
  },
  '92679': {
    country: 'US',
    city: 'Trabuco Canyon',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6625,
    longitude: -117.5903,
    postal_code: '92679'
  },
  '92683': {
    country: 'US',
    city: 'Westminster',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7524,
    longitude: -117.9939,
    postal_code: '92683'
  },
  '92684': {
    country: 'US',
    city: 'Westminster',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7627,
    longitude: -118.0072,
    postal_code: '92684'
  },
  '92685': {
    country: 'US',
    city: 'Westminster',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7528,
    longitude: -117.9951,
    postal_code: '92685'
  },
  '92688': {
    country: 'US',
    city: 'Rancho Santa Margarita',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6512,
    longitude: -117.5938,
    postal_code: '92688'
  },
  '92690': {
    country: 'US',
    city: 'Mission Viejo',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6117,
    longitude: -117.643,
    postal_code: '92690'
  },
  '92691': {
    country: 'US',
    city: 'Mission Viejo',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6128,
    longitude: -117.6622,
    postal_code: '92691'
  },
  '92692': {
    country: 'US',
    city: 'Mission Viejo',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6144,
    longitude: -117.6433,
    postal_code: '92692'
  },
  '92693': {
    country: 'US',
    city: 'San Juan Capistrano',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.4977,
    longitude: -117.6651,
    postal_code: '92693'
  },
  '92694': {
    country: 'US',
    city: 'Ladera Ranch',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.5472,
    longitude: -117.6238,
    postal_code: '92694'
  },
  '92697': {
    country: 'US',
    city: 'Irvine',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6485,
    longitude: -117.8387,
    postal_code: '92697'
  },
  '92698': {
    country: 'US',
    city: 'Aliso Viejo',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.6686,
    longitude: -117.8386,
    postal_code: '92698'
  },
  '92701': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7523,
    longitude: -117.8541,
    postal_code: '92701'
  },
  '92702': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7365,
    longitude: -117.8714,
    postal_code: '92702'
  },
  '92703': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7489,
    longitude: -117.9072,
    postal_code: '92703'
  },
  '92704': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7249,
    longitude: -117.909,
    postal_code: '92704'
  },
  '92705': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.754,
    longitude: -117.7919,
    postal_code: '92705'
  },
  '92706': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7691,
    longitude: -117.8855,
    postal_code: '92706'
  },
  '92707': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7086,
    longitude: -117.8701,
    postal_code: '92707'
  },
  '92708': {
    country: 'US',
    city: 'Fountain Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7102,
    longitude: -117.9503,
    postal_code: '92708'
  },
  '92711': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7669,
    longitude: -117.8043,
    postal_code: '92711'
  },
  '92712': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7496,
    longitude: -117.875,
    postal_code: '92712'
  },
  '92728': {
    country: 'US',
    city: 'Fountain Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.714,
    longitude: -117.9284,
    postal_code: '92728'
  },
  '92735': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7188,
    longitude: -117.8546,
    postal_code: '92735'
  },
  '92780': {
    country: 'US',
    city: 'Tustin',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7364,
    longitude: -117.8229,
    postal_code: '92780'
  },
  '92781': {
    country: 'US',
    city: 'Tustin',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7369,
    longitude: -117.8181,
    postal_code: '92781'
  },
  '92782': {
    country: 'US',
    city: 'Tustin',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7346,
    longitude: -117.7869,
    postal_code: '92782'
  },
  '92799': {
    country: 'US',
    city: 'Santa Ana',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7205,
    longitude: -117.9098,
    postal_code: '92799'
  },
  '92801': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8428,
    longitude: -117.9546,
    postal_code: '92801'
  },
  '92802': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8085,
    longitude: -117.9228,
    postal_code: '92802'
  },
  '92803': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8397,
    longitude: -117.9388,
    postal_code: '92803'
  },
  '92804': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8186,
    longitude: -117.9729,
    postal_code: '92804'
  },
  '92805': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8359,
    longitude: -117.9086,
    postal_code: '92805'
  },
  '92806': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8356,
    longitude: -117.8681,
    postal_code: '92806'
  },
  '92807': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8544,
    longitude: -117.7858,
    postal_code: '92807'
  },
  '92808': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8579,
    longitude: -117.7513,
    postal_code: '92808'
  },
  '92809': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8426,
    longitude: -117.9388,
    postal_code: '92809'
  },
  '92811': {
    country: 'US',
    city: 'Atwood',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8674,
    longitude: -117.831,
    postal_code: '92811'
  },
  '92812': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.817,
    longitude: -117.9286,
    postal_code: '92812'
  },
  '92814': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8173,
    longitude: -117.9607,
    postal_code: '92814'
  },
  '92815': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8319,
    longitude: -117.9121,
    postal_code: '92815'
  },
  '92816': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8401,
    longitude: -117.8867,
    postal_code: '92816'
  },
  '92817': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8512,
    longitude: -117.7915,
    postal_code: '92817'
  },
  '92821': {
    country: 'US',
    city: 'Brea',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.9291,
    longitude: -117.8845,
    postal_code: '92821'
  },
  '92822': {
    country: 'US',
    city: 'Brea',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.9187,
    longitude: -117.8892,
    postal_code: '92822'
  },
  '92823': {
    country: 'US',
    city: 'Brea',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.923,
    longitude: -117.798,
    postal_code: '92823'
  },
  '92825': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8356,
    longitude: -117.9132,
    postal_code: '92825'
  },
  '92831': {
    country: 'US',
    city: 'Fullerton',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8873,
    longitude: -117.8946,
    postal_code: '92831'
  },
  '92832': {
    country: 'US',
    city: 'Fullerton',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.868,
    longitude: -117.9265,
    postal_code: '92832'
  },
  '92833': {
    country: 'US',
    city: 'Fullerton',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8766,
    longitude: -117.9551,
    postal_code: '92833'
  },
  '92834': {
    country: 'US',
    city: 'Fullerton',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8768,
    longitude: -117.897,
    postal_code: '92834'
  },
  '92835': {
    country: 'US',
    city: 'Fullerton',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8994,
    longitude: -117.9063,
    postal_code: '92835'
  },
  '92836': {
    country: 'US',
    city: 'Fullerton',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8755,
    longitude: -117.9038,
    postal_code: '92836'
  },
  '92837': {
    country: 'US',
    city: 'Fullerton',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8695,
    longitude: -117.9611,
    postal_code: '92837'
  },
  '92838': {
    country: 'US',
    city: 'Fullerton',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8934,
    longitude: -117.931,
    postal_code: '92838'
  },
  '92840': {
    country: 'US',
    city: 'Garden Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7869,
    longitude: -117.9273,
    postal_code: '92840'
  },
  '92841': {
    country: 'US',
    city: 'Garden Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7817,
    longitude: -117.9766,
    postal_code: '92841'
  },
  '92842': {
    country: 'US',
    city: 'Garden Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7783,
    longitude: -117.9456,
    postal_code: '92842'
  },
  '92843': {
    country: 'US',
    city: 'Garden Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7671,
    longitude: -117.929,
    postal_code: '92843'
  },
  '92844': {
    country: 'US',
    city: 'Garden Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7661,
    longitude: -117.9738,
    postal_code: '92844'
  },
  '92845': {
    country: 'US',
    city: 'Garden Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7787,
    longitude: -118.0267,
    postal_code: '92845'
  },
  '92846': {
    country: 'US',
    city: 'Garden Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.788,
    longitude: -118.0325,
    postal_code: '92846'
  },
  '92850': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8442,
    longitude: -117.9555,
    postal_code: '92850'
  },
  '92856': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7841,
    longitude: -117.8435,
    postal_code: '92856'
  },
  '92857': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8317,
    longitude: -117.8491,
    postal_code: '92857'
  },
  '92859': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8027,
    longitude: -117.7867,
    postal_code: '92859'
  },
  '92860': {
    country: 'US',
    city: 'Norco',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9247,
    longitude: -117.5517,
    postal_code: '92860'
  },
  '92861': {
    country: 'US',
    city: 'Villa Park',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8205,
    longitude: -117.8104,
    postal_code: '92861'
  },
  '92862': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7915,
    longitude: -117.714,
    postal_code: '92862'
  },
  '92863': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8153,
    longitude: -117.8273,
    postal_code: '92863'
  },
  '92864': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8143,
    longitude: -117.8308,
    postal_code: '92864'
  },
  '92865': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8263,
    longitude: -117.8511,
    postal_code: '92865'
  },
  '92866': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7877,
    longitude: -117.8423,
    postal_code: '92866'
  },
  '92867': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.811,
    longitude: -117.8493,
    postal_code: '92867'
  },
  '92868': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7875,
    longitude: -117.8776,
    postal_code: '92868'
  },
  '92869': {
    country: 'US',
    city: 'Orange',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.7868,
    longitude: -117.7934,
    postal_code: '92869'
  },
  '92870': {
    country: 'US',
    city: 'Placentia',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8744,
    longitude: -117.8543,
    postal_code: '92870'
  },
  '92871': {
    country: 'US',
    city: 'Placentia',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8829,
    longitude: -117.8557,
    postal_code: '92871'
  },
  '92877': {
    country: 'US',
    city: 'Corona',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8753,
    longitude: -117.5664,
    postal_code: '92877'
  },
  '92878': {
    country: 'US',
    city: 'Corona',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8753,
    longitude: -117.5664,
    postal_code: '92878'
  },
  '92879': {
    country: 'US',
    city: 'Corona',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8797,
    longitude: -117.5354,
    postal_code: '92879'
  },
  '92880': {
    country: 'US',
    city: 'Corona',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.9208,
    longitude: -117.6096,
    postal_code: '92880'
  },
  '92881': {
    country: 'US',
    city: 'Corona',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8241,
    longitude: -117.5198,
    postal_code: '92881'
  },
  '92882': {
    country: 'US',
    city: 'Corona',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.8419,
    longitude: -117.6043,
    postal_code: '92882'
  },
  '92883': {
    country: 'US',
    city: 'Corona',
    state: 'California',
    state_short: 'CA',
    county: 'Riverside',
    latitude: 33.7541,
    longitude: -117.474,
    postal_code: '92883'
  },
  '92885': {
    country: 'US',
    city: 'Yorba Linda',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8911,
    longitude: -117.8222,
    postal_code: '92885'
  },
  '92886': {
    country: 'US',
    city: 'Yorba Linda',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.9058,
    longitude: -117.7865,
    postal_code: '92886'
  },
  '92887': {
    country: 'US',
    city: 'Yorba Linda',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8841,
    longitude: -117.7304,
    postal_code: '92887'
  },
  '92899': {
    country: 'US',
    city: 'Anaheim',
    state: 'California',
    state_short: 'CA',
    county: 'Orange',
    latitude: 33.8373,
    longitude: -117.8712,
    postal_code: '92899'
  },
  '93001': {
    country: 'US',
    city: 'Ventura',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.3308,
    longitude: -119.3584,
    postal_code: '93001'
  },
  '93002': {
    country: 'US',
    city: 'Ventura',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2783,
    longitude: -119.2932,
    postal_code: '93002'
  },
  '93003': {
    country: 'US',
    city: 'Ventura',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2846,
    longitude: -119.2222,
    postal_code: '93003'
  },
  '93004': {
    country: 'US',
    city: 'Ventura',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2788,
    longitude: -119.1651,
    postal_code: '93004'
  },
  '93005': {
    country: 'US',
    city: 'Ventura',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2783,
    longitude: -119.2932,
    postal_code: '93005'
  },
  '93006': {
    country: 'US',
    city: 'Ventura',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2783,
    longitude: -119.2932,
    postal_code: '93006'
  },
  '93007': {
    country: 'US',
    city: 'Ventura',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2783,
    longitude: -119.2932,
    postal_code: '93007'
  },
  '93009': {
    country: 'US',
    city: 'Ventura',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.3562,
    longitude: -119.1462,
    postal_code: '93009'
  },
  '93010': {
    country: 'US',
    city: 'Camarillo',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2313,
    longitude: -119.0464,
    postal_code: '93010'
  },
  '93011': {
    country: 'US',
    city: 'Camarillo',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2164,
    longitude: -119.0376,
    postal_code: '93011'
  },
  '93012': {
    country: 'US',
    city: 'Camarillo',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2218,
    longitude: -118.9866,
    postal_code: '93012'
  },
  '93013': {
    country: 'US',
    city: 'Carpinteria',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4036,
    longitude: -119.5183,
    postal_code: '93013'
  },
  '93014': {
    country: 'US',
    city: 'Carpinteria',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.3989,
    longitude: -119.5185,
    postal_code: '93014'
  },
  '93015': {
    country: 'US',
    city: 'Fillmore',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.3992,
    longitude: -118.9182,
    postal_code: '93015'
  },
  '93016': {
    country: 'US',
    city: 'Fillmore',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.3992,
    longitude: -118.9182,
    postal_code: '93016'
  },
  '93020': {
    country: 'US',
    city: 'Moorpark',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2856,
    longitude: -118.882,
    postal_code: '93020'
  },
  '93021': {
    country: 'US',
    city: 'Moorpark',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2784,
    longitude: -118.8771,
    postal_code: '93021'
  },
  '93022': {
    country: 'US',
    city: 'Oak View',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.402,
    longitude: -119.2982,
    postal_code: '93022'
  },
  '93023': {
    country: 'US',
    city: 'Ojai',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.4451,
    longitude: -119.2565,
    postal_code: '93023'
  },
  '93024': {
    country: 'US',
    city: 'Ojai',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.448,
    longitude: -119.2429,
    postal_code: '93024'
  },
  '93030': {
    country: 'US',
    city: 'Oxnard',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2141,
    longitude: -119.175,
    postal_code: '93030'
  },
  '93031': {
    country: 'US',
    city: 'Oxnard',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.0324,
    longitude: -119.1343,
    postal_code: '93031'
  },
  '93032': {
    country: 'US',
    city: 'Oxnard',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1975,
    longitude: -119.1771,
    postal_code: '93032'
  },
  '93033': {
    country: 'US',
    city: 'Oxnard',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1685,
    longitude: -119.1717,
    postal_code: '93033'
  },
  '93034': {
    country: 'US',
    city: 'Oxnard',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.0324,
    longitude: -119.1343,
    postal_code: '93034'
  },
  '93035': {
    country: 'US',
    city: 'Oxnard',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1822,
    longitude: -119.216,
    postal_code: '93035'
  },
  '93036': {
    country: 'US',
    city: 'Oxnard',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2351,
    longitude: -119.182,
    postal_code: '93036'
  },
  '93040': {
    country: 'US',
    city: 'Piru',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.4352,
    longitude: -118.7855,
    postal_code: '93040'
  },
  '93041': {
    country: 'US',
    city: 'Port Hueneme',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1626,
    longitude: -119.1973,
    postal_code: '93041'
  },
  '93042': {
    country: 'US',
    city: 'Point Mugu Nawc',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1134,
    longitude: -119.1124,
    postal_code: '93042'
  },
  '93043': {
    country: 'US',
    city: 'Port Hueneme Cbc Base',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1621,
    longitude: -119.2074,
    postal_code: '93043'
  },
  '93044': {
    country: 'US',
    city: 'Port Hueneme',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.1478,
    longitude: -119.1951,
    postal_code: '93044'
  },
  '93060': {
    country: 'US',
    city: 'Santa Paula',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.3547,
    longitude: -119.0713,
    postal_code: '93060'
  },
  '93061': {
    country: 'US',
    city: 'Santa Paula',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.3542,
    longitude: -119.0593,
    postal_code: '93061'
  },
  '93062': {
    country: 'US',
    city: 'Simi Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2694,
    longitude: -118.7815,
    postal_code: '93062'
  },
  '93063': {
    country: 'US',
    city: 'Simi Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.3046,
    longitude: -118.6844,
    postal_code: '93063'
  },
  '93064': {
    country: 'US',
    city: 'Brandeis',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2582,
    longitude: -118.7107,
    postal_code: '93064'
  },
  '93065': {
    country: 'US',
    city: 'Simi Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2656,
    longitude: -118.7653,
    postal_code: '93065'
  },
  '93066': {
    country: 'US',
    city: 'Somis',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2798,
    longitude: -119.0115,
    postal_code: '93066'
  },
  '93067': {
    country: 'US',
    city: 'Summerland',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4214,
    longitude: -119.5965,
    postal_code: '93067'
  },
  '93094': {
    country: 'US',
    city: 'Simi Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2694,
    longitude: -118.7815,
    postal_code: '93094'
  },
  '93099': {
    country: 'US',
    city: 'Simi Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Ventura',
    latitude: 34.2694,
    longitude: -118.7815,
    postal_code: '93099'
  },
  '93101': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4197,
    longitude: -119.7078,
    postal_code: '93101'
  },
  '93102': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4208,
    longitude: -119.6982,
    postal_code: '93102'
  },
  '93103': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4291,
    longitude: -119.6833,
    postal_code: '93103'
  },
  '93105': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4369,
    longitude: -119.7285,
    postal_code: '93105'
  },
  '93106': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4329,
    longitude: -119.8371,
    postal_code: '93106'
  },
  '93107': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4218,
    longitude: -119.8637,
    postal_code: '93107'
  },
  '93108': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4378,
    longitude: -119.6159,
    postal_code: '93108'
  },
  '93109': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4038,
    longitude: -119.7194,
    postal_code: '93109'
  },
  '93110': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4418,
    longitude: -119.7647,
    postal_code: '93110'
  },
  '93111': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4453,
    longitude: -119.8025,
    postal_code: '93111'
  },
  '93116': {
    country: 'US',
    city: 'Goleta',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4358,
    longitude: -119.8276,
    postal_code: '93116'
  },
  '93117': {
    country: 'US',
    city: 'Goleta',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4296,
    longitude: -119.8612,
    postal_code: '93117'
  },
  '93118': {
    country: 'US',
    city: 'Goleta',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4358,
    longitude: -119.8276,
    postal_code: '93118'
  },
  '93120': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4208,
    longitude: -119.6982,
    postal_code: '93120'
  },
  '93121': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4208,
    longitude: -119.6982,
    postal_code: '93121'
  },
  '93130': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4208,
    longitude: -119.6982,
    postal_code: '93130'
  },
  '93140': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4208,
    longitude: -119.6982,
    postal_code: '93140'
  },
  '93150': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4208,
    longitude: -119.6982,
    postal_code: '93150'
  },
  '93160': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4208,
    longitude: -119.6982,
    postal_code: '93160'
  },
  '93190': {
    country: 'US',
    city: 'Santa Barbara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.4208,
    longitude: -119.6982,
    postal_code: '93190'
  },
  '93199': {
    country: 'US',
    city: 'Goleta',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.2628,
    longitude: -119.8486,
    postal_code: '93199'
  },
  '93201': {
    country: 'US',
    city: 'Alpaugh',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 35.8877,
    longitude: -119.4873,
    postal_code: '93201'
  },
  '93202': {
    country: 'US',
    city: 'Armona',
    state: 'California',
    state_short: 'CA',
    county: 'Kings',
    latitude: 36.3095,
    longitude: -119.7053,
    postal_code: '93202'
  },
  '93203': {
    country: 'US',
    city: 'Arvin',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.1966,
    longitude: -118.8336,
    postal_code: '93203'
  },
  '93204': {
    country: 'US',
    city: 'Avenal',
    state: 'California',
    state_short: 'CA',
    county: 'Kings',
    latitude: 35.9877,
    longitude: -120.1227,
    postal_code: '93204'
  },
  '93205': {
    country: 'US',
    city: 'Bodfish',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.587,
    longitude: -118.4847,
    postal_code: '93205'
  },
  '93206': {
    country: 'US',
    city: 'Buttonwillow',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.4033,
    longitude: -119.4659,
    postal_code: '93206'
  },
  '93207': {
    country: 'US',
    city: 'California Hot Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 35.8818,
    longitude: -118.6561,
    postal_code: '93207'
  },
  '93208': {
    country: 'US',
    city: 'Camp Nelson',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.1427,
    longitude: -118.6093,
    postal_code: '93208'
  },
  '93210': {
    country: 'US',
    city: 'Coalinga',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.1624,
    longitude: -120.3489,
    postal_code: '93210'
  },
  '93212': {
    country: 'US',
    city: 'Corcoran',
    state: 'California',
    state_short: 'CA',
    county: 'Kings',
    latitude: 36.0865,
    longitude: -119.5607,
    postal_code: '93212'
  },
  '93215': {
    country: 'US',
    city: 'Delano',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.7715,
    longitude: -119.2459,
    postal_code: '93215'
  },
  '93216': {
    country: 'US',
    city: 'Delano',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.7688,
    longitude: -119.2471,
    postal_code: '93216'
  },
  '93218': {
    country: 'US',
    city: 'Ducor',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 35.8916,
    longitude: -119.0473,
    postal_code: '93218'
  },
  '93219': {
    country: 'US',
    city: 'Earlimart',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 35.8744,
    longitude: -119.281,
    postal_code: '93219'
  },
  '93220': {
    country: 'US',
    city: 'Edison',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3475,
    longitude: -118.8718,
    postal_code: '93220'
  },
  '93221': {
    country: 'US',
    city: 'Exeter',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.3041,
    longitude: -119.1293,
    postal_code: '93221'
  },
  '93222': {
    country: 'US',
    city: 'Pine Mountain Club',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 34.8469,
    longitude: -119.1568,
    postal_code: '93222'
  },
  '93223': {
    country: 'US',
    city: 'Farmersville',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.3002,
    longitude: -119.2054,
    postal_code: '93223'
  },
  '93224': {
    country: 'US',
    city: 'Fellows',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.1786,
    longitude: -119.5412,
    postal_code: '93224'
  },
  '93225': {
    country: 'US',
    city: 'Frazier Park',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 34.8265,
    longitude: -119.0355,
    postal_code: '93225'
  },
  '93226': {
    country: 'US',
    city: 'Glennville',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.7377,
    longitude: -118.7169,
    postal_code: '93226'
  },
  '93227': {
    country: 'US',
    city: 'Goshen',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.3572,
    longitude: -119.4254,
    postal_code: '93227'
  },
  '93230': {
    country: 'US',
    city: 'Hanford',
    state: 'California',
    state_short: 'CA',
    county: 'Kings',
    latitude: 36.3314,
    longitude: -119.6491,
    postal_code: '93230'
  },
  '93232': {
    country: 'US',
    city: 'Hanford',
    state: 'California',
    state_short: 'CA',
    county: 'Kings',
    latitude: 36.3275,
    longitude: -119.6457,
    postal_code: '93232'
  },
  '93234': {
    country: 'US',
    city: 'Huron',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.2371,
    longitude: -120.102,
    postal_code: '93234'
  },
  '93235': {
    country: 'US',
    city: 'Ivanhoe',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.3856,
    longitude: -119.2189,
    postal_code: '93235'
  },
  '93237': {
    country: 'US',
    city: 'Kaweah',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.4727,
    longitude: -118.9029,
    postal_code: '93237'
  },
  '93238': {
    country: 'US',
    city: 'Kernville',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.755,
    longitude: -118.4047,
    postal_code: '93238'
  },
  '93239': {
    country: 'US',
    city: 'Kettleman City',
    state: 'California',
    state_short: 'CA',
    county: 'Kings',
    latitude: 36.0083,
    longitude: -119.9618,
    postal_code: '93239'
  },
  '93240': {
    country: 'US',
    city: 'Lake Isabella',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.669,
    longitude: -118.457,
    postal_code: '93240'
  },
  '93241': {
    country: 'US',
    city: 'Lamont',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2571,
    longitude: -118.9124,
    postal_code: '93241'
  },
  '93242': {
    country: 'US',
    city: 'Laton',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.4378,
    longitude: -119.7156,
    postal_code: '93242'
  },
  '93243': {
    country: 'US',
    city: 'Lebec',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 34.8818,
    longitude: -118.8566,
    postal_code: '93243'
  },
  '93244': {
    country: 'US',
    city: 'Lemon Cove',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.4969,
    longitude: -118.9941,
    postal_code: '93244'
  },
  '93245': {
    country: 'US',
    city: 'Lemoore',
    state: 'California',
    state_short: 'CA',
    county: 'Kings',
    latitude: 36.2682,
    longitude: -119.8173,
    postal_code: '93245'
  },
  '93246': {
    country: 'US',
    city: 'Lemoore',
    state: 'California',
    state_short: 'CA',
    county: 'Kings',
    latitude: 36.1389,
    longitude: -119.8947,
    postal_code: '93246'
  },
  '93247': {
    country: 'US',
    city: 'Lindsay',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.2096,
    longitude: -119.0884,
    postal_code: '93247'
  },
  '93249': {
    country: 'US',
    city: 'Lost Hills',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.6163,
    longitude: -119.6943,
    postal_code: '93249'
  },
  '93250': {
    country: 'US',
    city: 'Mc Farland',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.6758,
    longitude: -119.2272,
    postal_code: '93250'
  },
  '93251': {
    country: 'US',
    city: 'Mc Kittrick',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3031,
    longitude: -119.6366,
    postal_code: '93251'
  },
  '93252': {
    country: 'US',
    city: 'Maricopa',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.0589,
    longitude: -119.401,
    postal_code: '93252'
  },
  '93254': {
    country: 'US',
    city: 'New Cuyama',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.9967,
    longitude: -119.8238,
    postal_code: '93254'
  },
  '93255': {
    country: 'US',
    city: 'Onyx',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.6824,
    longitude: -118.0959,
    postal_code: '93255'
  },
  '93256': {
    country: 'US',
    city: 'Pixley',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 35.9553,
    longitude: -119.2564,
    postal_code: '93256'
  },
  '93257': {
    country: 'US',
    city: 'Porterville',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.0686,
    longitude: -119.0315,
    postal_code: '93257'
  },
  '93258': {
    country: 'US',
    city: 'Porterville',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.0331,
    longitude: -119.0073,
    postal_code: '93258'
  },
  '93260': {
    country: 'US',
    city: 'Posey',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 35.8135,
    longitude: -118.6643,
    postal_code: '93260'
  },
  '93261': {
    country: 'US',
    city: 'Richgrove',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 35.805,
    longitude: -119.1315,
    postal_code: '93261'
  },
  '93262': {
    country: 'US',
    city: 'Sequoia National Park',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.6007,
    longitude: -118.711,
    postal_code: '93262'
  },
  '93263': {
    country: 'US',
    city: 'Shafter',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.497,
    longitude: -119.2801,
    postal_code: '93263'
  },
  '93265': {
    country: 'US',
    city: 'Springville',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.1363,
    longitude: -118.7961,
    postal_code: '93265'
  },
  '93266': {
    country: 'US',
    city: 'Stratford',
    state: 'California',
    state_short: 'CA',
    county: 'Kings',
    latitude: 36.179,
    longitude: -119.8236,
    postal_code: '93266'
  },
  '93267': {
    country: 'US',
    city: 'Strathmore',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.1472,
    longitude: -119.0792,
    postal_code: '93267'
  },
  '93268': {
    country: 'US',
    city: 'Taft',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.1482,
    longitude: -119.4557,
    postal_code: '93268'
  },
  '93270': {
    country: 'US',
    city: 'Terra Bella',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 35.957,
    longitude: -119.0312,
    postal_code: '93270'
  },
  '93271': {
    country: 'US',
    city: 'Three Rivers',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.4377,
    longitude: -118.8875,
    postal_code: '93271'
  },
  '93272': {
    country: 'US',
    city: 'Tipton',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.0546,
    longitude: -119.3078,
    postal_code: '93272'
  },
  '93274': {
    country: 'US',
    city: 'Tulare',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.2022,
    longitude: -119.338,
    postal_code: '93274'
  },
  '93275': {
    country: 'US',
    city: 'Tulare',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.2077,
    longitude: -119.3473,
    postal_code: '93275'
  },
  '93276': {
    country: 'US',
    city: 'Tupman',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2992,
    longitude: -119.3584,
    postal_code: '93276'
  },
  '93277': {
    country: 'US',
    city: 'Visalia',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.3114,
    longitude: -119.3065,
    postal_code: '93277'
  },
  '93278': {
    country: 'US',
    city: 'Visalia',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.3302,
    longitude: -119.2921,
    postal_code: '93278'
  },
  '93279': {
    country: 'US',
    city: 'Visalia',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.3302,
    longitude: -119.2921,
    postal_code: '93279'
  },
  '93280': {
    country: 'US',
    city: 'Wasco',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.648,
    longitude: -119.4487,
    postal_code: '93280'
  },
  '93282': {
    country: 'US',
    city: 'Waukena',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.1296,
    longitude: -119.5161,
    postal_code: '93282'
  },
  '93283': {
    country: 'US',
    city: 'Weldon',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.6391,
    longitude: -118.2859,
    postal_code: '93283'
  },
  '93285': {
    country: 'US',
    city: 'Wofford Heights',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.7246,
    longitude: -118.4559,
    postal_code: '93285'
  },
  '93286': {
    country: 'US',
    city: 'Woodlake',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.4313,
    longitude: -119.0918,
    postal_code: '93286'
  },
  '93287': {
    country: 'US',
    city: 'Woody',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.7068,
    longitude: -118.8439,
    postal_code: '93287'
  },
  '93290': {
    country: 'US',
    city: 'Visalia',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.3291,
    longitude: -119.2925,
    postal_code: '93290'
  },
  '93291': {
    country: 'US',
    city: 'Visalia',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.3551,
    longitude: -119.301,
    postal_code: '93291'
  },
  '93292': {
    country: 'US',
    city: 'Visalia',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.3302,
    longitude: -119.2921,
    postal_code: '93292'
  },
  '93301': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3866,
    longitude: -119.0171,
    postal_code: '93301'
  },
  '93302': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3733,
    longitude: -119.0187,
    postal_code: '93302'
  },
  '93303': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2944,
    longitude: -118.9052,
    postal_code: '93303'
  },
  '93304': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3396,
    longitude: -119.0218,
    postal_code: '93304'
  },
  '93305': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3855,
    longitude: -118.986,
    postal_code: '93305'
  },
  '93306': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3867,
    longitude: -118.9391,
    postal_code: '93306'
  },
  '93307': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3275,
    longitude: -118.9839,
    postal_code: '93307'
  },
  '93308': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.4244,
    longitude: -119.0433,
    postal_code: '93308'
  },
  '93309': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3384,
    longitude: -119.0627,
    postal_code: '93309'
  },
  '93311': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3039,
    longitude: -119.1056,
    postal_code: '93311'
  },
  '93312': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3935,
    longitude: -119.1205,
    postal_code: '93312'
  },
  '93313': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2974,
    longitude: -119.0509,
    postal_code: '93313'
  },
  '93314': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3863,
    longitude: -119.17,
    postal_code: '93314'
  },
  '93380': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2944,
    longitude: -118.9052,
    postal_code: '93380'
  },
  '93383': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2944,
    longitude: -118.9052,
    postal_code: '93383'
  },
  '93384': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3733,
    longitude: -119.0187,
    postal_code: '93384'
  },
  '93385': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2944,
    longitude: -118.9052,
    postal_code: '93385'
  },
  '93386': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2944,
    longitude: -118.9052,
    postal_code: '93386'
  },
  '93387': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2944,
    longitude: -118.9052,
    postal_code: '93387'
  },
  '93388': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2944,
    longitude: -118.9052,
    postal_code: '93388'
  },
  '93389': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2944,
    longitude: -118.9052,
    postal_code: '93389'
  },
  '93390': {
    country: 'US',
    city: 'Bakersfield',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2944,
    longitude: -118.9052,
    postal_code: '93390'
  },
  '93401': {
    country: 'US',
    city: 'San Luis Obispo',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.2635,
    longitude: -120.6509,
    postal_code: '93401'
  },
  '93402': {
    country: 'US',
    city: 'Los Osos',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.3172,
    longitude: -120.8333,
    postal_code: '93402'
  },
  '93403': {
    country: 'US',
    city: 'San Luis Obispo',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.2828,
    longitude: -120.6596,
    postal_code: '93403'
  },
  '93405': {
    country: 'US',
    city: 'San Luis Obispo',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.2901,
    longitude: -120.6817,
    postal_code: '93405'
  },
  '93406': {
    country: 'US',
    city: 'San Luis Obispo',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.2828,
    longitude: -120.6596,
    postal_code: '93406'
  },
  '93407': {
    country: 'US',
    city: 'San Luis Obispo',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.2828,
    longitude: -120.6596,
    postal_code: '93407'
  },
  '93408': {
    country: 'US',
    city: 'San Luis Obispo',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.2828,
    longitude: -120.6596,
    postal_code: '93408'
  },
  '93409': {
    country: 'US',
    city: 'San Luis Obispo',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.2211,
    longitude: -120.6364,
    postal_code: '93409'
  },
  '93410': {
    country: 'US',
    city: 'San Luis Obispo',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.3471,
    longitude: -120.4553,
    postal_code: '93410'
  },
  '93412': {
    country: 'US',
    city: 'Los Osos',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.3111,
    longitude: -120.8324,
    postal_code: '93412'
  },
  '93420': {
    country: 'US',
    city: 'Arroyo Grande',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.1661,
    longitude: -120.4651,
    postal_code: '93420'
  },
  '93421': {
    country: 'US',
    city: 'Arroyo Grande',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.1186,
    longitude: -120.5907,
    postal_code: '93421'
  },
  '93422': {
    country: 'US',
    city: 'Atascadero',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.4754,
    longitude: -120.6638,
    postal_code: '93422'
  },
  '93423': {
    country: 'US',
    city: 'Atascadero',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.4282,
    longitude: -120.7695,
    postal_code: '93423'
  },
  '93424': {
    country: 'US',
    city: 'Avila Beach',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.18,
    longitude: -120.7318,
    postal_code: '93424'
  },
  '93426': {
    country: 'US',
    city: 'Bradley',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 35.8093,
    longitude: -120.9728,
    postal_code: '93426'
  },
  '93427': {
    country: 'US',
    city: 'Buellton',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.6209,
    longitude: -120.1922,
    postal_code: '93427'
  },
  '93428': {
    country: 'US',
    city: 'Cambria',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.5566,
    longitude: -121.084,
    postal_code: '93428'
  },
  '93429': {
    country: 'US',
    city: 'Casmalia',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.8458,
    longitude: -120.535,
    postal_code: '93429'
  },
  '93430': {
    country: 'US',
    city: 'Cayucos',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.4446,
    longitude: -120.8908,
    postal_code: '93430'
  },
  '93432': {
    country: 'US',
    city: 'Creston',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.4779,
    longitude: -120.4361,
    postal_code: '93432'
  },
  '93433': {
    country: 'US',
    city: 'Grover Beach',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.121,
    longitude: -120.6173,
    postal_code: '93433'
  },
  '93434': {
    country: 'US',
    city: 'Guadalupe',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.96,
    longitude: -120.5703,
    postal_code: '93434'
  },
  '93435': {
    country: 'US',
    city: 'Harmony',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.4919,
    longitude: -120.9763,
    postal_code: '93435'
  },
  '93436': {
    country: 'US',
    city: 'Lompoc',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.6583,
    longitude: -120.4506,
    postal_code: '93436'
  },
  '93437': {
    country: 'US',
    city: 'Lompoc',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.7532,
    longitude: -120.5171,
    postal_code: '93437'
  },
  '93438': {
    country: 'US',
    city: 'Lompoc',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.6392,
    longitude: -120.4579,
    postal_code: '93438'
  },
  '93440': {
    country: 'US',
    city: 'Los Alamos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.7457,
    longitude: -120.2049,
    postal_code: '93440'
  },
  '93441': {
    country: 'US',
    city: 'Los Olivos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.6678,
    longitude: -120.1149,
    postal_code: '93441'
  },
  '93442': {
    country: 'US',
    city: 'Morro Bay',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.3795,
    longitude: -120.8447,
    postal_code: '93442'
  },
  '93443': {
    country: 'US',
    city: 'Morro Bay',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.3658,
    longitude: -120.8499,
    postal_code: '93443'
  },
  '93444': {
    country: 'US',
    city: 'Nipomo',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.0298,
    longitude: -120.4894,
    postal_code: '93444'
  },
  '93445': {
    country: 'US',
    city: 'Oceano',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.1019,
    longitude: -120.608,
    postal_code: '93445'
  },
  '93446': {
    country: 'US',
    city: 'Paso Robles',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.6406,
    longitude: -120.7003,
    postal_code: '93446'
  },
  '93447': {
    country: 'US',
    city: 'Paso Robles',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.7562,
    longitude: -120.6935,
    postal_code: '93447'
  },
  '93448': {
    country: 'US',
    city: 'Pismo Beach',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.1428,
    longitude: -120.6413,
    postal_code: '93448'
  },
  '93449': {
    country: 'US',
    city: 'Pismo Beach',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.1578,
    longitude: -120.6522,
    postal_code: '93449'
  },
  '93450': {
    country: 'US',
    city: 'San Ardo',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 35.9857,
    longitude: -120.8612,
    postal_code: '93450'
  },
  '93451': {
    country: 'US',
    city: 'San Miguel',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.9004,
    longitude: -120.5929,
    postal_code: '93451'
  },
  '93452': {
    country: 'US',
    city: 'San Simeon',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.6668,
    longitude: -121.144,
    postal_code: '93452'
  },
  '93453': {
    country: 'US',
    city: 'Santa Margarita',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.3584,
    longitude: -120.2596,
    postal_code: '93453'
  },
  '93454': {
    country: 'US',
    city: 'Santa Maria',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.9545,
    longitude: -120.4325,
    postal_code: '93454'
  },
  '93455': {
    country: 'US',
    city: 'Santa Maria',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.8286,
    longitude: -120.4268,
    postal_code: '93455'
  },
  '93456': {
    country: 'US',
    city: 'Santa Maria',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.953,
    longitude: -120.4357,
    postal_code: '93456'
  },
  '93457': {
    country: 'US',
    city: 'Santa Maria',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.953,
    longitude: -120.4357,
    postal_code: '93457'
  },
  '93458': {
    country: 'US',
    city: 'Santa Maria',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.9535,
    longitude: -120.4957,
    postal_code: '93458'
  },
  '93460': {
    country: 'US',
    city: 'Santa Ynez',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.624,
    longitude: -120.0713,
    postal_code: '93460'
  },
  '93461': {
    country: 'US',
    city: 'Shandon',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.6513,
    longitude: -120.372,
    postal_code: '93461'
  },
  '93463': {
    country: 'US',
    city: 'Solvang',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.6488,
    longitude: -120.1701,
    postal_code: '93463'
  },
  '93464': {
    country: 'US',
    city: 'Solvang',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Barbara',
    latitude: 34.6744,
    longitude: -120.1115,
    postal_code: '93464'
  },
  '93465': {
    country: 'US',
    city: 'Templeton',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.5551,
    longitude: -120.7107,
    postal_code: '93465'
  },
  '93475': {
    country: 'US',
    city: 'Oceano',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.1004,
    longitude: -120.6111,
    postal_code: '93475'
  },
  '93483': {
    country: 'US',
    city: 'Grover Beach',
    state: 'California',
    state_short: 'CA',
    county: 'San Luis Obispo',
    latitude: 35.1216,
    longitude: -120.6213,
    postal_code: '93483'
  },
  '93501': {
    country: 'US',
    city: 'Mojave',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.0478,
    longitude: -118.1735,
    postal_code: '93501'
  },
  '93502': {
    country: 'US',
    city: 'Mojave',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.0525,
    longitude: -118.174,
    postal_code: '93502'
  },
  '93504': {
    country: 'US',
    city: 'California City',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.1871,
    longitude: -117.8854,
    postal_code: '93504'
  },
  '93505': {
    country: 'US',
    city: 'California City',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.1278,
    longitude: -117.9651,
    postal_code: '93505'
  },
  '93510': {
    country: 'US',
    city: 'Acton',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4835,
    longitude: -118.1959,
    postal_code: '93510'
  },
  '93512': {
    country: 'US',
    city: 'Benton',
    state: 'California',
    state_short: 'CA',
    county: 'Mono',
    latitude: 37.8926,
    longitude: -118.5647,
    postal_code: '93512'
  },
  '93513': {
    country: 'US',
    city: 'Big Pine',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 37.1679,
    longitude: -118.2916,
    postal_code: '93513'
  },
  '93514': {
    country: 'US',
    city: 'Bishop',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 37.5014,
    longitude: -118.4048,
    postal_code: '93514'
  },
  '93515': {
    country: 'US',
    city: 'Bishop',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 37.3635,
    longitude: -118.3951,
    postal_code: '93515'
  },
  '93516': {
    country: 'US',
    city: 'Boron',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.0037,
    longitude: -117.6629,
    postal_code: '93516'
  },
  '93517': {
    country: 'US',
    city: 'Bridgeport',
    state: 'California',
    state_short: 'CA',
    county: 'Mono',
    latitude: 38.2566,
    longitude: -119.208,
    postal_code: '93517'
  },
  '93518': {
    country: 'US',
    city: 'Caliente',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3701,
    longitude: -118.4612,
    postal_code: '93518'
  },
  '93519': {
    country: 'US',
    city: 'Cantil',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3089,
    longitude: -117.9684,
    postal_code: '93519'
  },
  '93522': {
    country: 'US',
    city: 'Darwin',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 36.2948,
    longitude: -117.5957,
    postal_code: '93522'
  },
  '93523': {
    country: 'US',
    city: 'Edwards',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 34.9261,
    longitude: -117.9351,
    postal_code: '93523'
  },
  '93524': {
    country: 'US',
    city: 'Edwards',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 34.932,
    longitude: -117.9071,
    postal_code: '93524'
  },
  '93526': {
    country: 'US',
    city: 'Independence',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 36.8396,
    longitude: -118.2048,
    postal_code: '93526'
  },
  '93527': {
    country: 'US',
    city: 'Inyokern',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.6397,
    longitude: -117.857,
    postal_code: '93527'
  },
  '93528': {
    country: 'US',
    city: 'Johannesburg',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3708,
    longitude: -117.6427,
    postal_code: '93528'
  },
  '93529': {
    country: 'US',
    city: 'June Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Mono',
    latitude: 37.7773,
    longitude: -119.0825,
    postal_code: '93529'
  },
  '93530': {
    country: 'US',
    city: 'Keeler',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 36.4886,
    longitude: -117.8741,
    postal_code: '93530'
  },
  '93531': {
    country: 'US',
    city: 'Keene',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.2375,
    longitude: -118.6076,
    postal_code: '93531'
  },
  '93532': {
    country: 'US',
    city: 'Lake Hughes',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.6847,
    longitude: -118.5442,
    postal_code: '93532'
  },
  '93534': {
    country: 'US',
    city: 'Lancaster',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.6909,
    longitude: -118.1491,
    postal_code: '93534'
  },
  '93535': {
    country: 'US',
    city: 'Lancaster',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.7131,
    longitude: -117.8783,
    postal_code: '93535'
  },
  '93536': {
    country: 'US',
    city: 'Lancaster',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.7471,
    longitude: -118.3687,
    postal_code: '93536'
  },
  '93539': {
    country: 'US',
    city: 'Lancaster',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.698,
    longitude: -118.1367,
    postal_code: '93539'
  },
  '93541': {
    country: 'US',
    city: 'Lee Vining',
    state: 'California',
    state_short: 'CA',
    county: 'Mono',
    latitude: 37.989,
    longitude: -119.1234,
    postal_code: '93541'
  },
  '93542': {
    country: 'US',
    city: 'Little Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 35.9366,
    longitude: -117.9067,
    postal_code: '93542'
  },
  '93543': {
    country: 'US',
    city: 'Littlerock',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4891,
    longitude: -117.9708,
    postal_code: '93543'
  },
  '93544': {
    country: 'US',
    city: 'Llano',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.493,
    longitude: -117.7543,
    postal_code: '93544'
  },
  '93545': {
    country: 'US',
    city: 'Lone Pine',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 36.5798,
    longitude: -118.0578,
    postal_code: '93545'
  },
  '93546': {
    country: 'US',
    city: 'Mammoth Lakes',
    state: 'California',
    state_short: 'CA',
    county: 'Mono',
    latitude: 37.6094,
    longitude: -118.8656,
    postal_code: '93546'
  },
  '93549': {
    country: 'US',
    city: 'Olancha',
    state: 'California',
    state_short: 'CA',
    county: 'Inyo',
    latitude: 36.23,
    longitude: -117.9552,
    postal_code: '93549'
  },
  '93550': {
    country: 'US',
    city: 'Palmdale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4133,
    longitude: -118.0917,
    postal_code: '93550'
  },
  '93551': {
    country: 'US',
    city: 'Palmdale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.6017,
    longitude: -118.231,
    postal_code: '93551'
  },
  '93552': {
    country: 'US',
    city: 'Palmdale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.5715,
    longitude: -118.0231,
    postal_code: '93552'
  },
  '93553': {
    country: 'US',
    city: 'Pearblossom',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.4225,
    longitude: -117.9055,
    postal_code: '93553'
  },
  '93554': {
    country: 'US',
    city: 'Randsburg',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.3866,
    longitude: -117.7159,
    postal_code: '93554'
  },
  '93555': {
    country: 'US',
    city: 'Ridgecrest',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.6225,
    longitude: -117.6709,
    postal_code: '93555'
  },
  '93556': {
    country: 'US',
    city: 'Ridgecrest',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.6225,
    longitude: -117.6709,
    postal_code: '93556'
  },
  '93558': {
    country: 'US',
    city: 'Red Mountain',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 35.3479,
    longitude: -117.6214,
    postal_code: '93558'
  },
  '93560': {
    country: 'US',
    city: 'Rosamond',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 34.8664,
    longitude: -118.3409,
    postal_code: '93560'
  },
  '93561': {
    country: 'US',
    city: 'Tehachapi',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.1322,
    longitude: -118.449,
    postal_code: '93561'
  },
  '93562': {
    country: 'US',
    city: 'Trona',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 35.7481,
    longitude: -117.3808,
    postal_code: '93562'
  },
  '93563': {
    country: 'US',
    city: 'Valyermo',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.3966,
    longitude: -117.7604,
    postal_code: '93563'
  },
  '93581': {
    country: 'US',
    city: 'Tehachapi',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 35.1322,
    longitude: -118.449,
    postal_code: '93581'
  },
  '93584': {
    country: 'US',
    city: 'Lancaster',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.698,
    longitude: -118.1367,
    postal_code: '93584'
  },
  '93586': {
    country: 'US',
    city: 'Lancaster',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.698,
    longitude: -118.1367,
    postal_code: '93586'
  },
  '93590': {
    country: 'US',
    city: 'Palmdale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.5794,
    longitude: -118.1165,
    postal_code: '93590'
  },
  '93591': {
    country: 'US',
    city: 'Palmdale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.6019,
    longitude: -117.8123,
    postal_code: '93591'
  },
  '93592': {
    country: 'US',
    city: 'Trona',
    state: 'California',
    state_short: 'CA',
    county: 'San Bernardino',
    latitude: 35.7627,
    longitude: -117.3728,
    postal_code: '93592'
  },
  '93596': {
    country: 'US',
    city: 'Boron',
    state: 'California',
    state_short: 'CA',
    county: 'Kern',
    latitude: 34.9994,
    longitude: -117.6498,
    postal_code: '93596'
  },
  '93599': {
    country: 'US',
    city: 'Palmdale',
    state: 'California',
    state_short: 'CA',
    county: 'Los Angeles',
    latitude: 34.5794,
    longitude: -118.1165,
    postal_code: '93599'
  },
  '93601': {
    country: 'US',
    city: 'Ahwahnee',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 37.4076,
    longitude: -119.7233,
    postal_code: '93601'
  },
  '93602': {
    country: 'US',
    city: 'Auberry',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 37.0726,
    longitude: -119.4572,
    postal_code: '93602'
  },
  '93603': {
    country: 'US',
    city: 'Badger',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.6313,
    longitude: -119.0132,
    postal_code: '93603'
  },
  '93604': {
    country: 'US',
    city: 'Bass Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 37.3244,
    longitude: -119.5568,
    postal_code: '93604'
  },
  '93605': {
    country: 'US',
    city: 'Big Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 37.2032,
    longitude: -119.2492,
    postal_code: '93605'
  },
  '93606': {
    country: 'US',
    city: 'Biola',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8032,
    longitude: -120.0185,
    postal_code: '93606'
  },
  '93607': {
    country: 'US',
    city: 'Burrel',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.5898,
    longitude: -119.8994,
    postal_code: '93607'
  },
  '93608': {
    country: 'US',
    city: 'Cantua Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.4921,
    longitude: -120.3353,
    postal_code: '93608'
  },
  '93609': {
    country: 'US',
    city: 'Caruthers',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.5358,
    longitude: -119.8446,
    postal_code: '93609'
  },
  '93610': {
    country: 'US',
    city: 'Chowchilla',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 37.1014,
    longitude: -120.2691,
    postal_code: '93610'
  },
  '93611': {
    country: 'US',
    city: 'Clovis',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8253,
    longitude: -119.6802,
    postal_code: '93611'
  },
  '93612': {
    country: 'US',
    city: 'Clovis',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8149,
    longitude: -119.7106,
    postal_code: '93612'
  },
  '93613': {
    country: 'US',
    city: 'Clovis',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8252,
    longitude: -119.7029,
    postal_code: '93613'
  },
  '93614': {
    country: 'US',
    city: 'Coarsegold',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 37.2214,
    longitude: -119.7455,
    postal_code: '93614'
  },
  '93615': {
    country: 'US',
    city: 'Cutler',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.5243,
    longitude: -119.287,
    postal_code: '93615'
  },
  '93616': {
    country: 'US',
    city: 'Del Rey',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6543,
    longitude: -119.5929,
    postal_code: '93616'
  },
  '93618': {
    country: 'US',
    city: 'Dinuba',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.5349,
    longitude: -119.3909,
    postal_code: '93618'
  },
  '93619': {
    country: 'US',
    city: 'Clovis',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.8432,
    longitude: -119.6518,
    postal_code: '93619'
  },
  '93620': {
    country: 'US',
    city: 'Dos Palos',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.0025,
    longitude: -120.6333,
    postal_code: '93620'
  },
  '93621': {
    country: 'US',
    city: 'Dunlap',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7446,
    longitude: -119.0899,
    postal_code: '93621'
  },
  '93622': {
    country: 'US',
    city: 'Firebaugh',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8651,
    longitude: -120.47,
    postal_code: '93622'
  },
  '93623': {
    country: 'US',
    city: 'Fish Camp',
    state: 'California',
    state_short: 'CA',
    county: 'Mariposa',
    latitude: 37.4785,
    longitude: -119.6404,
    postal_code: '93623'
  },
  '93624': {
    country: 'US',
    city: 'Five Points',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.3386,
    longitude: -120.1118,
    postal_code: '93624'
  },
  '93625': {
    country: 'US',
    city: 'Fowler',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6282,
    longitude: -119.671,
    postal_code: '93625'
  },
  '93626': {
    country: 'US',
    city: 'Friant',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 37.0422,
    longitude: -119.6807,
    postal_code: '93626'
  },
  '93627': {
    country: 'US',
    city: 'Helm',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.5316,
    longitude: -120.0982,
    postal_code: '93627'
  },
  '93628': {
    country: 'US',
    city: 'Hume',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7515,
    longitude: -118.9575,
    postal_code: '93628'
  },
  '93630': {
    country: 'US',
    city: 'Kerman',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7306,
    longitude: -120.0724,
    postal_code: '93630'
  },
  '93631': {
    country: 'US',
    city: 'Kingsburg',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.508,
    longitude: -119.5433,
    postal_code: '93631'
  },
  '93633': {
    country: 'US',
    city: 'Kings Canyon National Pk',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.7341,
    longitude: -118.9588,
    postal_code: '93633'
  },
  '93634': {
    country: 'US',
    city: 'Lakeshore',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 37.253,
    longitude: -119.1748,
    postal_code: '93634'
  },
  '93635': {
    country: 'US',
    city: 'Los Banos',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.0627,
    longitude: -120.8544,
    postal_code: '93635'
  },
  '93636': {
    country: 'US',
    city: 'Madera',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 36.9528,
    longitude: -119.8806,
    postal_code: '93636'
  },
  '93637': {
    country: 'US',
    city: 'Madera',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 36.9403,
    longitude: -120.082,
    postal_code: '93637'
  },
  '93638': {
    country: 'US',
    city: 'Madera',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 37.0402,
    longitude: -120.0335,
    postal_code: '93638'
  },
  '93639': {
    country: 'US',
    city: 'Madera',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 36.9613,
    longitude: -120.0607,
    postal_code: '93639'
  },
  '93640': {
    country: 'US',
    city: 'Mendota',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7424,
    longitude: -120.4093,
    postal_code: '93640'
  },
  '93641': {
    country: 'US',
    city: 'Miramonte',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6894,
    longitude: -119.0477,
    postal_code: '93641'
  },
  '93642': {
    country: 'US',
    city: 'Mono Hot Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 37.3266,
    longitude: -119.0176,
    postal_code: '93642'
  },
  '93643': {
    country: 'US',
    city: 'North Fork',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 37.2125,
    longitude: -119.5143,
    postal_code: '93643'
  },
  '93644': {
    country: 'US',
    city: 'Oakhurst',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 37.3476,
    longitude: -119.6449,
    postal_code: '93644'
  },
  '93645': {
    country: 'US',
    city: 'O Neals',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 37.1639,
    longitude: -119.6652,
    postal_code: '93645'
  },
  '93646': {
    country: 'US',
    city: 'Orange Cove',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6255,
    longitude: -119.3204,
    postal_code: '93646'
  },
  '93647': {
    country: 'US',
    city: 'Orosi',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.5464,
    longitude: -119.2815,
    postal_code: '93647'
  },
  '93648': {
    country: 'US',
    city: 'Parlier',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6103,
    longitude: -119.5375,
    postal_code: '93648'
  },
  '93649': {
    country: 'US',
    city: 'Piedra',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8417,
    longitude: -119.3496,
    postal_code: '93649'
  },
  '93650': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8411,
    longitude: -119.801,
    postal_code: '93650'
  },
  '93651': {
    country: 'US',
    city: 'Prather',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.9938,
    longitude: -119.5268,
    postal_code: '93651'
  },
  '93652': {
    country: 'US',
    city: 'Raisin City',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6024,
    longitude: -119.904,
    postal_code: '93652'
  },
  '93653': {
    country: 'US',
    city: 'Raymond',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 37.279,
    longitude: -119.8766,
    postal_code: '93653'
  },
  '93654': {
    country: 'US',
    city: 'Reedley',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6044,
    longitude: -119.4378,
    postal_code: '93654'
  },
  '93656': {
    country: 'US',
    city: 'Riverdale',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.4295,
    longitude: -119.872,
    postal_code: '93656'
  },
  '93657': {
    country: 'US',
    city: 'Sanger',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7243,
    longitude: -119.5478,
    postal_code: '93657'
  },
  '93660': {
    country: 'US',
    city: 'San Joaquin',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6059,
    longitude: -120.1889,
    postal_code: '93660'
  },
  '93661': {
    country: 'US',
    city: 'Santa Rita Park',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.1869,
    longitude: -120.6504,
    postal_code: '93661'
  },
  '93662': {
    country: 'US',
    city: 'Selma',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.5695,
    longitude: -119.617,
    postal_code: '93662'
  },
  '93664': {
    country: 'US',
    city: 'Shaver Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 37.1397,
    longitude: -119.273,
    postal_code: '93664'
  },
  '93665': {
    country: 'US',
    city: 'South Dos Palos',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 36.9644,
    longitude: -120.6532,
    postal_code: '93665'
  },
  '93666': {
    country: 'US',
    city: 'Sultana',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.5455,
    longitude: -119.3401,
    postal_code: '93666'
  },
  '93667': {
    country: 'US',
    city: 'Tollhouse',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.9943,
    longitude: -119.3914,
    postal_code: '93667'
  },
  '93668': {
    country: 'US',
    city: 'Tranquillity',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6584,
    longitude: -120.2617,
    postal_code: '93668'
  },
  '93669': {
    country: 'US',
    city: 'Wishon',
    state: 'California',
    state_short: 'CA',
    county: 'Madera',
    latitude: 37.281,
    longitude: -119.557,
    postal_code: '93669'
  },
  '93670': {
    country: 'US',
    city: 'Yettem',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.4718,
    longitude: -119.2594,
    postal_code: '93670'
  },
  '93673': {
    country: 'US',
    city: 'Traver',
    state: 'California',
    state_short: 'CA',
    county: 'Tulare',
    latitude: 36.4552,
    longitude: -119.4848,
    postal_code: '93673'
  },
  '93675': {
    country: 'US',
    city: 'Squaw Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7071,
    longitude: -119.1814,
    postal_code: '93675'
  },
  '93701': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7487,
    longitude: -119.7867,
    postal_code: '93701'
  },
  '93702': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.74,
    longitude: -119.7532,
    postal_code: '93702'
  },
  '93703': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7684,
    longitude: -119.7594,
    postal_code: '93703'
  },
  '93704': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7991,
    longitude: -119.8016,
    postal_code: '93704'
  },
  '93705': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7863,
    longitude: -119.8286,
    postal_code: '93705'
  },
  '93706': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6486,
    longitude: -119.9987,
    postal_code: '93706'
  },
  '93707': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93707'
  },
  '93708': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93708'
  },
  '93709': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93709'
  },
  '93710': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8236,
    longitude: -119.7621,
    postal_code: '93710'
  },
  '93711': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8303,
    longitude: -119.8319,
    postal_code: '93711'
  },
  '93712': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93712'
  },
  '93714': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93714'
  },
  '93715': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93715'
  },
  '93716': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93716'
  },
  '93717': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93717'
  },
  '93718': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93718'
  },
  '93720': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8579,
    longitude: -119.7655,
    postal_code: '93720'
  },
  '93721': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7377,
    longitude: -119.7843,
    postal_code: '93721'
  },
  '93722': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7918,
    longitude: -119.8801,
    postal_code: '93722'
  },
  '93723': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7863,
    longitude: -119.9532,
    postal_code: '93723'
  },
  '93724': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93724'
  },
  '93725': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.6207,
    longitude: -119.7308,
    postal_code: '93725'
  },
  '93726': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7949,
    longitude: -119.7604,
    postal_code: '93726'
  },
  '93727': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7528,
    longitude: -119.7061,
    postal_code: '93727'
  },
  '93728': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7581,
    longitude: -119.8113,
    postal_code: '93728'
  },
  '93729': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93729'
  },
  '93730': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.8878,
    longitude: -119.7589,
    postal_code: '93730'
  },
  '93737': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7477,
    longitude: -119.7724,
    postal_code: '93737'
  },
  '93740': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93740'
  },
  '93741': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93741'
  },
  '93744': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93744'
  },
  '93745': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93745'
  },
  '93747': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93747'
  },
  '93750': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93750'
  },
  '93755': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93755'
  },
  '93760': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93760'
  },
  '93761': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93761'
  },
  '93764': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93764'
  },
  '93765': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93765'
  },
  '93771': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93771'
  },
  '93772': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93772'
  },
  '93773': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93773'
  },
  '93774': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93774'
  },
  '93775': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93775'
  },
  '93776': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93776'
  },
  '93777': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93777'
  },
  '93778': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93778'
  },
  '93779': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93779'
  },
  '93786': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93786'
  },
  '93790': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93790'
  },
  '93791': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93791'
  },
  '93792': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93792'
  },
  '93793': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93793'
  },
  '93794': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93794'
  },
  '93844': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93844'
  },
  '93888': {
    country: 'US',
    city: 'Fresno',
    state: 'California',
    state_short: 'CA',
    county: 'Fresno',
    latitude: 36.7464,
    longitude: -119.6397,
    postal_code: '93888'
  },
  '93901': {
    country: 'US',
    city: 'Salinas',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6677,
    longitude: -121.6596,
    postal_code: '93901'
  },
  '93902': {
    country: 'US',
    city: 'Salinas',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6777,
    longitude: -121.6555,
    postal_code: '93902'
  },
  '93905': {
    country: 'US',
    city: 'Salinas',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6811,
    longitude: -121.6176,
    postal_code: '93905'
  },
  '93906': {
    country: 'US',
    city: 'Salinas',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.7103,
    longitude: -121.6438,
    postal_code: '93906'
  },
  '93907': {
    country: 'US',
    city: 'Salinas',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.7563,
    longitude: -121.6703,
    postal_code: '93907'
  },
  '93908': {
    country: 'US',
    city: 'Salinas',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6011,
    longitude: -121.6729,
    postal_code: '93908'
  },
  '93912': {
    country: 'US',
    city: 'Salinas',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6777,
    longitude: -121.6555,
    postal_code: '93912'
  },
  '93915': {
    country: 'US',
    city: 'Salinas',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6777,
    longitude: -121.6555,
    postal_code: '93915'
  },
  '93920': {
    country: 'US',
    city: 'Big Sur',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.2458,
    longitude: -121.7009,
    postal_code: '93920'
  },
  '93921': {
    country: 'US',
    city: 'Carmel By The Sea',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.5552,
    longitude: -121.9233,
    postal_code: '93921'
  },
  '93922': {
    country: 'US',
    city: 'Carmel',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.5433,
    longitude: -121.9263,
    postal_code: '93922'
  },
  '93923': {
    country: 'US',
    city: 'Carmel',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.5457,
    longitude: -121.8949,
    postal_code: '93923'
  },
  '93924': {
    country: 'US',
    city: 'Carmel Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.4787,
    longitude: -121.7244,
    postal_code: '93924'
  },
  '93925': {
    country: 'US',
    city: 'Chualar',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.595,
    longitude: -121.432,
    postal_code: '93925'
  },
  '93926': {
    country: 'US',
    city: 'Gonzales',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.49,
    longitude: -121.4103,
    postal_code: '93926'
  },
  '93927': {
    country: 'US',
    city: 'Greenfield',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.3202,
    longitude: -121.2451,
    postal_code: '93927'
  },
  '93928': {
    country: 'US',
    city: 'Jolon',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 35.9708,
    longitude: -121.176,
    postal_code: '93928'
  },
  '93930': {
    country: 'US',
    city: 'King City',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.2028,
    longitude: -121.1273,
    postal_code: '93930'
  },
  '93932': {
    country: 'US',
    city: 'Lockwood',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 35.95,
    longitude: -121.0626,
    postal_code: '93932'
  },
  '93933': {
    country: 'US',
    city: 'Marina',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6849,
    longitude: -121.7934,
    postal_code: '93933'
  },
  '93940': {
    country: 'US',
    city: 'Monterey',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.5802,
    longitude: -121.8443,
    postal_code: '93940'
  },
  '93942': {
    country: 'US',
    city: 'Monterey',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6002,
    longitude: -121.8947,
    postal_code: '93942'
  },
  '93943': {
    country: 'US',
    city: 'Monterey',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.597,
    longitude: -121.8741,
    postal_code: '93943'
  },
  '93944': {
    country: 'US',
    city: 'Monterey',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6062,
    longitude: -121.9089,
    postal_code: '93944'
  },
  '93950': {
    country: 'US',
    city: 'Pacific Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6167,
    longitude: -121.922,
    postal_code: '93950'
  },
  '93953': {
    country: 'US',
    city: 'Pebble Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.5907,
    longitude: -121.942,
    postal_code: '93953'
  },
  '93954': {
    country: 'US',
    city: 'San Lucas',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.1289,
    longitude: -121.0205,
    postal_code: '93954'
  },
  '93955': {
    country: 'US',
    city: 'Seaside',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6217,
    longitude: -121.7935,
    postal_code: '93955'
  },
  '93960': {
    country: 'US',
    city: 'Soledad',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.4196,
    longitude: -121.3243,
    postal_code: '93960'
  },
  '93962': {
    country: 'US',
    city: 'Spreckels',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.6261,
    longitude: -121.6555,
    postal_code: '93962'
  },
  '94002': {
    country: 'US',
    city: 'Belmont',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5174,
    longitude: -122.2927,
    postal_code: '94002'
  },
  '94005': {
    country: 'US',
    city: 'Brisbane',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.6811,
    longitude: -122.4001,
    postal_code: '94005'
  },
  '94010': {
    country: 'US',
    city: 'Burlingame',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5671,
    longitude: -122.3676,
    postal_code: '94010'
  },
  '94011': {
    country: 'US',
    city: 'Burlingame',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5841,
    longitude: -122.3661,
    postal_code: '94011'
  },
  '94014': {
    country: 'US',
    city: 'Daly City',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.6875,
    longitude: -122.4388,
    postal_code: '94014'
  },
  '94015': {
    country: 'US',
    city: 'Daly City',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.6787,
    longitude: -122.478,
    postal_code: '94015'
  },
  '94016': {
    country: 'US',
    city: 'Daly City',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.7058,
    longitude: -122.4619,
    postal_code: '94016'
  },
  '94017': {
    country: 'US',
    city: 'Daly City',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.7058,
    longitude: -122.4619,
    postal_code: '94017'
  },
  '94018': {
    country: 'US',
    city: 'El Granada',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5101,
    longitude: -122.4734,
    postal_code: '94018'
  },
  '94019': {
    country: 'US',
    city: 'Half Moon Bay',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.4791,
    longitude: -122.4459,
    postal_code: '94019'
  },
  '94020': {
    country: 'US',
    city: 'La Honda',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.2726,
    longitude: -122.2495,
    postal_code: '94020'
  },
  '94021': {
    country: 'US',
    city: 'Loma Mar',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.2708,
    longitude: -122.2807,
    postal_code: '94021'
  },
  '94022': {
    country: 'US',
    city: 'Los Altos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3814,
    longitude: -122.1258,
    postal_code: '94022'
  },
  '94023': {
    country: 'US',
    city: 'Los Altos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3852,
    longitude: -122.1141,
    postal_code: '94023'
  },
  '94024': {
    country: 'US',
    city: 'Los Altos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3547,
    longitude: -122.0862,
    postal_code: '94024'
  },
  '94025': {
    country: 'US',
    city: 'Menlo Park',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.4396,
    longitude: -122.1864,
    postal_code: '94025'
  },
  '94026': {
    country: 'US',
    city: 'Menlo Park',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.3811,
    longitude: -122.3348,
    postal_code: '94026'
  },
  '94027': {
    country: 'US',
    city: 'Atherton',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.4563,
    longitude: -122.2002,
    postal_code: '94027'
  },
  '94028': {
    country: 'US',
    city: 'Portola Valley',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.3702,
    longitude: -122.2182,
    postal_code: '94028'
  },
  '94030': {
    country: 'US',
    city: 'Millbrae',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.6004,
    longitude: -122.402,
    postal_code: '94030'
  },
  '94035': {
    country: 'US',
    city: 'Mountain View',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3861,
    longitude: -122.0839,
    postal_code: '94035'
  },
  '94037': {
    country: 'US',
    city: 'Montara',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5428,
    longitude: -122.5052,
    postal_code: '94037'
  },
  '94038': {
    country: 'US',
    city: 'Moss Beach',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.531,
    longitude: -122.5068,
    postal_code: '94038'
  },
  '94039': {
    country: 'US',
    city: 'Mountain View',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3861,
    longitude: -122.0839,
    postal_code: '94039'
  },
  '94040': {
    country: 'US',
    city: 'Mountain View',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3855,
    longitude: -122.088,
    postal_code: '94040'
  },
  '94041': {
    country: 'US',
    city: 'Mountain View',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3893,
    longitude: -122.0783,
    postal_code: '94041'
  },
  '94042': {
    country: 'US',
    city: 'Mountain View',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3861,
    longitude: -122.0839,
    postal_code: '94042'
  },
  '94043': {
    country: 'US',
    city: 'Mountain View',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.4056,
    longitude: -122.0775,
    postal_code: '94043'
  },
  '94044': {
    country: 'US',
    city: 'Pacifica',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.6196,
    longitude: -122.4816,
    postal_code: '94044'
  },
  '94060': {
    country: 'US',
    city: 'Pescadero',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.2065,
    longitude: -122.3649,
    postal_code: '94060'
  },
  '94061': {
    country: 'US',
    city: 'Redwood City',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.4647,
    longitude: -122.2304,
    postal_code: '94061'
  },
  '94062': {
    country: 'US',
    city: 'Redwood City',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.4245,
    longitude: -122.296,
    postal_code: '94062'
  },
  '94063': {
    country: 'US',
    city: 'Redwood City',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.4815,
    longitude: -122.2091,
    postal_code: '94063'
  },
  '94064': {
    country: 'US',
    city: 'Redwood City',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.3811,
    longitude: -122.3348,
    postal_code: '94064'
  },
  '94065': {
    country: 'US',
    city: 'Redwood City',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5331,
    longitude: -122.2486,
    postal_code: '94065'
  },
  '94066': {
    country: 'US',
    city: 'San Bruno',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.6247,
    longitude: -122.429,
    postal_code: '94066'
  },
  '94070': {
    country: 'US',
    city: 'San Carlos',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.4969,
    longitude: -122.2674,
    postal_code: '94070'
  },
  '94074': {
    country: 'US',
    city: 'San Gregorio',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.3255,
    longitude: -122.3556,
    postal_code: '94074'
  },
  '94080': {
    country: 'US',
    city: 'South San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.6574,
    longitude: -122.4235,
    postal_code: '94080'
  },
  '94083': {
    country: 'US',
    city: 'South San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.6547,
    longitude: -122.4077,
    postal_code: '94083'
  },
  '94085': {
    country: 'US',
    city: 'Sunnyvale',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3886,
    longitude: -122.0177,
    postal_code: '94085'
  },
  '94086': {
    country: 'US',
    city: 'Sunnyvale',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3764,
    longitude: -122.0238,
    postal_code: '94086'
  },
  '94087': {
    country: 'US',
    city: 'Sunnyvale',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3502,
    longitude: -122.0349,
    postal_code: '94087'
  },
  '94088': {
    country: 'US',
    city: 'Sunnyvale',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3688,
    longitude: -122.0363,
    postal_code: '94088'
  },
  '94089': {
    country: 'US',
    city: 'Sunnyvale',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3983,
    longitude: -122.0006,
    postal_code: '94089'
  },
  '94102': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7813,
    longitude: -122.4167,
    postal_code: '94102'
  },
  '94103': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7725,
    longitude: -122.4147,
    postal_code: '94103'
  },
  '94104': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7915,
    longitude: -122.4018,
    postal_code: '94104'
  },
  '94105': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7864,
    longitude: -122.3892,
    postal_code: '94105'
  },
  '94107': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7621,
    longitude: -122.3971,
    postal_code: '94107'
  },
  '94108': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7929,
    longitude: -122.4079,
    postal_code: '94108'
  },
  '94109': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7917,
    longitude: -122.4186,
    postal_code: '94109'
  },
  '94110': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7509,
    longitude: -122.4153,
    postal_code: '94110'
  },
  '94111': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7974,
    longitude: -122.4001,
    postal_code: '94111'
  },
  '94112': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7195,
    longitude: -122.4411,
    postal_code: '94112'
  },
  '94114': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7587,
    longitude: -122.433,
    postal_code: '94114'
  },
  '94115': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7856,
    longitude: -122.4358,
    postal_code: '94115'
  },
  '94116': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7441,
    longitude: -122.4863,
    postal_code: '94116'
  },
  '94117': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7712,
    longitude: -122.4413,
    postal_code: '94117'
  },
  '94118': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7812,
    longitude: -122.4614,
    postal_code: '94118'
  },
  '94119': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94119'
  },
  '94120': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94120'
  },
  '94121': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7786,
    longitude: -122.4892,
    postal_code: '94121'
  },
  '94122': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7593,
    longitude: -122.4836,
    postal_code: '94122'
  },
  '94123': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7999,
    longitude: -122.4342,
    postal_code: '94123'
  },
  '94124': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7309,
    longitude: -122.3886,
    postal_code: '94124'
  },
  '94125': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94125'
  },
  '94126': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94126'
  },
  '94127': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7354,
    longitude: -122.4571,
    postal_code: '94127'
  },
  '94128': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.6216,
    longitude: -122.3929,
    postal_code: '94128'
  },
  '94129': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.8005,
    longitude: -122.465,
    postal_code: '94129'
  },
  '94130': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.8231,
    longitude: -122.3693,
    postal_code: '94130'
  },
  '94131': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.745,
    longitude: -122.4383,
    postal_code: '94131'
  },
  '94132': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7211,
    longitude: -122.4754,
    postal_code: '94132'
  },
  '94133': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.8002,
    longitude: -122.4091,
    postal_code: '94133'
  },
  '94134': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.719,
    longitude: -122.4096,
    postal_code: '94134'
  },
  '94137': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94137'
  },
  '94139': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94139'
  },
  '94140': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94140'
  },
  '94141': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94141'
  },
  '94142': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94142'
  },
  '94143': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7631,
    longitude: -122.4586,
    postal_code: '94143'
  },
  '94144': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94144'
  },
  '94145': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94145'
  },
  '94146': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94146'
  },
  '94147': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94147'
  },
  '94151': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94151'
  },
  '94158': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7694,
    longitude: -122.3867,
    postal_code: '94158'
  },
  '94159': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94159'
  },
  '94160': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94160'
  },
  '94161': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94161'
  },
  '94163': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94163'
  },
  '94164': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94164'
  },
  '94172': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94172'
  },
  '94177': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94177'
  },
  '94188': {
    country: 'US',
    city: 'San Francisco',
    state: 'California',
    state_short: 'CA',
    county: 'City and County of San Francisco',
    latitude: 37.7749,
    longitude: -122.4194,
    postal_code: '94188'
  },
  '94203': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94203'
  },
  '94204': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94204'
  },
  '94205': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94205'
  },
  '94206': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94206'
  },
  '94207': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94207'
  },
  '94208': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94208'
  },
  '94209': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94209'
  },
  '94211': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94211'
  },
  '94229': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94229'
  },
  '94230': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94230'
  },
  '94232': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94232'
  },
  '94234': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94234'
  },
  '94235': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94235'
  },
  '94236': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94236'
  },
  '94237': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94237'
  },
  '94239': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94239'
  },
  '94240': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94240'
  },
  '94244': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94244'
  },
  '94245': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94245'
  },
  '94247': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94247'
  },
  '94248': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94248'
  },
  '94249': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94249'
  },
  '94250': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94250'
  },
  '94252': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94252'
  },
  '94254': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94254'
  },
  '94256': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94256'
  },
  '94257': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94257'
  },
  '94258': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94258'
  },
  '94259': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94259'
  },
  '94261': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94261'
  },
  '94262': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94262'
  },
  '94263': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94263'
  },
  '94267': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94267'
  },
  '94268': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94268'
  },
  '94269': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94269'
  },
  '94271': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94271'
  },
  '94273': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94273'
  },
  '94274': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94274'
  },
  '94277': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94277'
  },
  '94278': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94278'
  },
  '94279': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94279'
  },
  '94280': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94280'
  },
  '94282': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94282'
  },
  '94283': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94283'
  },
  '94284': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94284'
  },
  '94285': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94285'
  },
  '94286': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94286'
  },
  '94287': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94287'
  },
  '94288': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94288'
  },
  '94289': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94289'
  },
  '94290': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94290'
  },
  '94291': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94291'
  },
  '94293': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94293'
  },
  '94294': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94294'
  },
  '94295': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94295'
  },
  '94296': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94296'
  },
  '94297': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94297'
  },
  '94298': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94298'
  },
  '94299': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4944,
    postal_code: '94299'
  },
  '94301': {
    country: 'US',
    city: 'Palo Alto',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.4443,
    longitude: -122.1497,
    postal_code: '94301'
  },
  '94302': {
    country: 'US',
    city: 'Palo Alto',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.4419,
    longitude: -122.143,
    postal_code: '94302'
  },
  '94303': {
    country: 'US',
    city: 'Palo Alto',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.4673,
    longitude: -122.1388,
    postal_code: '94303'
  },
  '94304': {
    country: 'US',
    city: 'Palo Alto',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.4334,
    longitude: -122.1842,
    postal_code: '94304'
  },
  '94305': {
    country: 'US',
    city: 'Stanford',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.4236,
    longitude: -122.1619,
    postal_code: '94305'
  },
  '94306': {
    country: 'US',
    city: 'Palo Alto',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.418,
    longitude: -122.1274,
    postal_code: '94306'
  },
  '94309': {
    country: 'US',
    city: 'Palo Alto',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.4419,
    longitude: -122.143,
    postal_code: '94309'
  },
  '94401': {
    country: 'US',
    city: 'San Mateo',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5735,
    longitude: -122.3225,
    postal_code: '94401'
  },
  '94402': {
    country: 'US',
    city: 'San Mateo',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5507,
    longitude: -122.3276,
    postal_code: '94402'
  },
  '94403': {
    country: 'US',
    city: 'San Mateo',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5395,
    longitude: -122.2998,
    postal_code: '94403'
  },
  '94404': {
    country: 'US',
    city: 'San Mateo',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5538,
    longitude: -122.27,
    postal_code: '94404'
  },
  '94497': {
    country: 'US',
    city: 'San Mateo',
    state: 'California',
    state_short: 'CA',
    county: 'San Mateo',
    latitude: 37.5347,
    longitude: -122.3259,
    postal_code: '94497'
  },
  '94501': {
    country: 'US',
    city: 'Alameda',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7706,
    longitude: -122.2648,
    postal_code: '94501'
  },
  '94502': {
    country: 'US',
    city: 'Alameda',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7351,
    longitude: -122.2431,
    postal_code: '94502'
  },
  '94503': {
    country: 'US',
    city: 'American Canyon',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.1668,
    longitude: -122.2553,
    postal_code: '94503'
  },
  '94505': {
    country: 'US',
    city: 'Discovery Bay',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.8989,
    longitude: -121.6054,
    postal_code: '94505'
  },
  '94506': {
    country: 'US',
    city: 'Danville',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.8321,
    longitude: -121.9167,
    postal_code: '94506'
  },
  '94507': {
    country: 'US',
    city: 'Alamo',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.8537,
    longitude: -122.0229,
    postal_code: '94507'
  },
  '94508': {
    country: 'US',
    city: 'Angwin',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.5769,
    longitude: -122.4477,
    postal_code: '94508'
  },
  '94509': {
    country: 'US',
    city: 'Antioch',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9939,
    longitude: -121.8089,
    postal_code: '94509'
  },
  '94510': {
    country: 'US',
    city: 'Benicia',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.0685,
    longitude: -122.1614,
    postal_code: '94510'
  },
  '94511': {
    country: 'US',
    city: 'Bethel Island',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 38.0266,
    longitude: -121.6425,
    postal_code: '94511'
  },
  '94512': {
    country: 'US',
    city: 'Birds Landing',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.1504,
    longitude: -121.8443,
    postal_code: '94512'
  },
  '94513': {
    country: 'US',
    city: 'Brentwood',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9324,
    longitude: -121.6894,
    postal_code: '94513'
  },
  '94514': {
    country: 'US',
    city: 'Byron',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.8254,
    longitude: -121.6236,
    postal_code: '94514'
  },
  '94515': {
    country: 'US',
    city: 'Calistoga',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.5823,
    longitude: -122.5814,
    postal_code: '94515'
  },
  '94516': {
    country: 'US',
    city: 'Canyon',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.8339,
    longitude: -122.165,
    postal_code: '94516'
  },
  '94517': {
    country: 'US',
    city: 'Clayton',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9154,
    longitude: -121.91,
    postal_code: '94517'
  },
  '94518': {
    country: 'US',
    city: 'Concord',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9504,
    longitude: -122.0263,
    postal_code: '94518'
  },
  '94519': {
    country: 'US',
    city: 'Concord',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9841,
    longitude: -122.0119,
    postal_code: '94519'
  },
  '94520': {
    country: 'US',
    city: 'Concord',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9823,
    longitude: -122.0362,
    postal_code: '94520'
  },
  '94521': {
    country: 'US',
    city: 'Concord',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9575,
    longitude: -121.975,
    postal_code: '94521'
  },
  '94522': {
    country: 'US',
    city: 'Concord',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.978,
    longitude: -122.0311,
    postal_code: '94522'
  },
  '94523': {
    country: 'US',
    city: 'Pleasant Hill',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.954,
    longitude: -122.0737,
    postal_code: '94523'
  },
  '94524': {
    country: 'US',
    city: 'Concord',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.978,
    longitude: -122.0311,
    postal_code: '94524'
  },
  '94525': {
    country: 'US',
    city: 'Crockett',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 38.0519,
    longitude: -122.2177,
    postal_code: '94525'
  },
  '94526': {
    country: 'US',
    city: 'Danville',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.814,
    longitude: -121.966,
    postal_code: '94526'
  },
  '94527': {
    country: 'US',
    city: 'Concord',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9535,
    longitude: -121.9578,
    postal_code: '94527'
  },
  '94528': {
    country: 'US',
    city: 'Diablo',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.8387,
    longitude: -121.9667,
    postal_code: '94528'
  },
  '94529': {
    country: 'US',
    city: 'Concord',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.978,
    longitude: -122.0311,
    postal_code: '94529'
  },
  '94530': {
    country: 'US',
    city: 'El Cerrito',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9156,
    longitude: -122.2985,
    postal_code: '94530'
  },
  '94531': {
    country: 'US',
    city: 'Antioch',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9658,
    longitude: -121.7758,
    postal_code: '94531'
  },
  '94533': {
    country: 'US',
    city: 'Fairfield',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.2671,
    longitude: -122.0357,
    postal_code: '94533'
  },
  '94534': {
    country: 'US',
    city: 'Fairfield',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.2423,
    longitude: -122.1314,
    postal_code: '94534'
  },
  '94535': {
    country: 'US',
    city: 'Travis Afb',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.2743,
    longitude: -121.9463,
    postal_code: '94535'
  },
  '94536': {
    country: 'US',
    city: 'Fremont',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.5605,
    longitude: -121.9999,
    postal_code: '94536'
  },
  '94537': {
    country: 'US',
    city: 'Fremont',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6802,
    longitude: -121.9215,
    postal_code: '94537'
  },
  '94538': {
    country: 'US',
    city: 'Fremont',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.5308,
    longitude: -121.9712,
    postal_code: '94538'
  },
  '94539': {
    country: 'US',
    city: 'Fremont',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.5176,
    longitude: -121.9287,
    postal_code: '94539'
  },
  '94540': {
    country: 'US',
    city: 'Hayward',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6802,
    longitude: -121.9215,
    postal_code: '94540'
  },
  '94541': {
    country: 'US',
    city: 'Hayward',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.674,
    longitude: -122.0894,
    postal_code: '94541'
  },
  '94542': {
    country: 'US',
    city: 'Hayward',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6586,
    longitude: -122.0472,
    postal_code: '94542'
  },
  '94543': {
    country: 'US',
    city: 'Hayward',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6688,
    longitude: -122.0808,
    postal_code: '94543'
  },
  '94544': {
    country: 'US',
    city: 'Hayward',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6374,
    longitude: -122.067,
    postal_code: '94544'
  },
  '94545': {
    country: 'US',
    city: 'Hayward',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6332,
    longitude: -122.0971,
    postal_code: '94545'
  },
  '94546': {
    country: 'US',
    city: 'Castro Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7015,
    longitude: -122.0782,
    postal_code: '94546'
  },
  '94547': {
    country: 'US',
    city: 'Hercules',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 38.0066,
    longitude: -122.2637,
    postal_code: '94547'
  },
  '94548': {
    country: 'US',
    city: 'Knightsen',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9726,
    longitude: -121.6652,
    postal_code: '94548'
  },
  '94549': {
    country: 'US',
    city: 'Lafayette',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.8961,
    longitude: -122.1119,
    postal_code: '94549'
  },
  '94550': {
    country: 'US',
    city: 'Livermore',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.683,
    longitude: -121.763,
    postal_code: '94550'
  },
  '94551': {
    country: 'US',
    city: 'Livermore',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7526,
    longitude: -121.77,
    postal_code: '94551'
  },
  '94552': {
    country: 'US',
    city: 'Castro Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7131,
    longitude: -122.0381,
    postal_code: '94552'
  },
  '94553': {
    country: 'US',
    city: 'Martinez',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9864,
    longitude: -122.135,
    postal_code: '94553'
  },
  '94555': {
    country: 'US',
    city: 'Fremont',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.5735,
    longitude: -122.0469,
    postal_code: '94555'
  },
  '94556': {
    country: 'US',
    city: 'Moraga',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.8437,
    longitude: -122.1242,
    postal_code: '94556'
  },
  '94557': {
    country: 'US',
    city: 'Hayward',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6802,
    longitude: -121.9215,
    postal_code: '94557'
  },
  '94558': {
    country: 'US',
    city: 'Napa',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.4549,
    longitude: -122.2564,
    postal_code: '94558'
  },
  '94559': {
    country: 'US',
    city: 'Napa',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.2904,
    longitude: -122.2841,
    postal_code: '94559'
  },
  '94560': {
    country: 'US',
    city: 'Newark',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.5368,
    longitude: -122.032,
    postal_code: '94560'
  },
  '94561': {
    country: 'US',
    city: 'Oakley',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.994,
    longitude: -121.7036,
    postal_code: '94561'
  },
  '94562': {
    country: 'US',
    city: 'Oakville',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.4379,
    longitude: -122.3991,
    postal_code: '94562'
  },
  '94563': {
    country: 'US',
    city: 'Orinda',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.8787,
    longitude: -122.1728,
    postal_code: '94563'
  },
  '94564': {
    country: 'US',
    city: 'Pinole',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9969,
    longitude: -122.2875,
    postal_code: '94564'
  },
  '94565': {
    country: 'US',
    city: 'Pittsburg',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 38.0031,
    longitude: -121.9172,
    postal_code: '94565'
  },
  '94566': {
    country: 'US',
    city: 'Pleasanton',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6658,
    longitude: -121.8755,
    postal_code: '94566'
  },
  '94567': {
    country: 'US',
    city: 'Pope Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.6152,
    longitude: -122.4278,
    postal_code: '94567'
  },
  '94568': {
    country: 'US',
    city: 'Dublin',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7166,
    longitude: -121.9226,
    postal_code: '94568'
  },
  '94569': {
    country: 'US',
    city: 'Port Costa',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 38.046,
    longitude: -122.1866,
    postal_code: '94569'
  },
  '94570': {
    country: 'US',
    city: 'Moraga',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.7772,
    longitude: -121.9554,
    postal_code: '94570'
  },
  '94571': {
    country: 'US',
    city: 'Rio Vista',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.1637,
    longitude: -121.7016,
    postal_code: '94571'
  },
  '94572': {
    country: 'US',
    city: 'Rodeo',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 38.0307,
    longitude: -122.2581,
    postal_code: '94572'
  },
  '94573': {
    country: 'US',
    city: 'Rutherford',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.4585,
    longitude: -122.4225,
    postal_code: '94573'
  },
  '94574': {
    country: 'US',
    city: 'Saint Helena',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.5138,
    longitude: -122.4619,
    postal_code: '94574'
  },
  '94575': {
    country: 'US',
    city: 'Moraga',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.7772,
    longitude: -121.9554,
    postal_code: '94575'
  },
  '94576': {
    country: 'US',
    city: 'Deer Park',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.5494,
    longitude: -122.4764,
    postal_code: '94576'
  },
  '94577': {
    country: 'US',
    city: 'San Leandro',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7205,
    longitude: -122.1587,
    postal_code: '94577'
  },
  '94578': {
    country: 'US',
    city: 'San Leandro',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7024,
    longitude: -122.124,
    postal_code: '94578'
  },
  '94579': {
    country: 'US',
    city: 'San Leandro',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6892,
    longitude: -122.1507,
    postal_code: '94579'
  },
  '94580': {
    country: 'US',
    city: 'San Lorenzo',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6787,
    longitude: -122.1295,
    postal_code: '94580'
  },
  '94581': {
    country: 'US',
    city: 'Napa',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.2971,
    longitude: -122.2855,
    postal_code: '94581'
  },
  '94582': {
    country: 'US',
    city: 'San Ramon',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.7636,
    longitude: -121.9155,
    postal_code: '94582'
  },
  '94583': {
    country: 'US',
    city: 'San Ramon',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.7562,
    longitude: -121.9522,
    postal_code: '94583'
  },
  '94585': {
    country: 'US',
    city: 'Suisun City',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.2408,
    longitude: -122.042,
    postal_code: '94585'
  },
  '94586': {
    country: 'US',
    city: 'Sunol',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6094,
    longitude: -121.8986,
    postal_code: '94586'
  },
  '94587': {
    country: 'US',
    city: 'Union City',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.5895,
    longitude: -122.0497,
    postal_code: '94587'
  },
  '94588': {
    country: 'US',
    city: 'Pleasanton',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.6873,
    longitude: -121.8957,
    postal_code: '94588'
  },
  '94589': {
    country: 'US',
    city: 'Vallejo',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.1582,
    longitude: -122.2804,
    postal_code: '94589'
  },
  '94590': {
    country: 'US',
    city: 'Vallejo',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.1053,
    longitude: -122.2474,
    postal_code: '94590'
  },
  '94591': {
    country: 'US',
    city: 'Vallejo',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.0985,
    longitude: -122.2124,
    postal_code: '94591'
  },
  '94592': {
    country: 'US',
    city: 'Vallejo',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.0968,
    longitude: -122.2699,
    postal_code: '94592'
  },
  '94595': {
    country: 'US',
    city: 'Walnut Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.8753,
    longitude: -122.0703,
    postal_code: '94595'
  },
  '94596': {
    country: 'US',
    city: 'Walnut Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9053,
    longitude: -122.0549,
    postal_code: '94596'
  },
  '94597': {
    country: 'US',
    city: 'Walnut Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9182,
    longitude: -122.0717,
    postal_code: '94597'
  },
  '94598': {
    country: 'US',
    city: 'Walnut Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9194,
    longitude: -122.0259,
    postal_code: '94598'
  },
  '94599': {
    country: 'US',
    city: 'Yountville',
    state: 'California',
    state_short: 'CA',
    county: 'Napa',
    latitude: 38.4016,
    longitude: -122.3608,
    postal_code: '94599'
  },
  '94601': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7806,
    longitude: -122.2166,
    postal_code: '94601'
  },
  '94602': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8011,
    longitude: -122.2104,
    postal_code: '94602'
  },
  '94603': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7402,
    longitude: -122.171,
    postal_code: '94603'
  },
  '94604': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8044,
    longitude: -122.2708,
    postal_code: '94604'
  },
  '94605': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7641,
    longitude: -122.1633,
    postal_code: '94605'
  },
  '94606': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7957,
    longitude: -122.2429,
    postal_code: '94606'
  },
  '94607': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8071,
    longitude: -122.2851,
    postal_code: '94607'
  },
  '94608': {
    country: 'US',
    city: 'Emeryville',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8365,
    longitude: -122.2804,
    postal_code: '94608'
  },
  '94609': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8361,
    longitude: -122.2637,
    postal_code: '94609'
  },
  '94610': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8126,
    longitude: -122.2443,
    postal_code: '94610'
  },
  '94611': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8471,
    longitude: -122.2223,
    postal_code: '94611'
  },
  '94612': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8085,
    longitude: -122.2668,
    postal_code: '94612'
  },
  '94613': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7811,
    longitude: -122.1866,
    postal_code: '94613'
  },
  '94614': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7277,
    longitude: -122.2046,
    postal_code: '94614'
  },
  '94615': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8067,
    longitude: -122.3004,
    postal_code: '94615'
  },
  '94617': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8078,
    longitude: -122.2717,
    postal_code: '94617'
  },
  '94618': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8431,
    longitude: -122.2402,
    postal_code: '94618'
  },
  '94619': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7878,
    longitude: -122.1884,
    postal_code: '94619'
  },
  '94620': {
    country: 'US',
    city: 'Piedmont',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8244,
    longitude: -122.2316,
    postal_code: '94620'
  },
  '94621': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.7589,
    longitude: -122.1853,
    postal_code: '94621'
  },
  '94622': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.799,
    longitude: -122.2337,
    postal_code: '94622'
  },
  '94623': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8044,
    longitude: -122.2708,
    postal_code: '94623'
  },
  '94624': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8044,
    longitude: -122.2708,
    postal_code: '94624'
  },
  '94649': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8044,
    longitude: -122.2708,
    postal_code: '94649'
  },
  '94659': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8044,
    longitude: -122.2708,
    postal_code: '94659'
  },
  '94660': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8044,
    longitude: -122.2708,
    postal_code: '94660'
  },
  '94661': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8044,
    longitude: -122.2708,
    postal_code: '94661'
  },
  '94662': {
    country: 'US',
    city: 'Emeryville',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8313,
    longitude: -122.2852,
    postal_code: '94662'
  },
  '94666': {
    country: 'US',
    city: 'Oakland',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8044,
    longitude: -122.2708,
    postal_code: '94666'
  },
  '94701': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8606,
    longitude: -122.2967,
    postal_code: '94701'
  },
  '94702': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8656,
    longitude: -122.2851,
    postal_code: '94702'
  },
  '94703': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.863,
    longitude: -122.2749,
    postal_code: '94703'
  },
  '94704': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8664,
    longitude: -122.257,
    postal_code: '94704'
  },
  '94705': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8571,
    longitude: -122.25,
    postal_code: '94705'
  },
  '94706': {
    country: 'US',
    city: 'Albany',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.89,
    longitude: -122.2954,
    postal_code: '94706'
  },
  '94707': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8927,
    longitude: -122.2761,
    postal_code: '94707'
  },
  '94708': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8918,
    longitude: -122.2604,
    postal_code: '94708'
  },
  '94709': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8784,
    longitude: -122.2655,
    postal_code: '94709'
  },
  '94710': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8696,
    longitude: -122.2959,
    postal_code: '94710'
  },
  '94712': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8716,
    longitude: -122.2727,
    postal_code: '94712'
  },
  '94720': {
    country: 'US',
    city: 'Berkeley',
    state: 'California',
    state_short: 'CA',
    county: 'Alameda',
    latitude: 37.8738,
    longitude: -122.2549,
    postal_code: '94720'
  },
  '94801': {
    country: 'US',
    city: 'Richmond',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.94,
    longitude: -122.362,
    postal_code: '94801'
  },
  '94802': {
    country: 'US',
    city: 'Richmond',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9358,
    longitude: -122.3477,
    postal_code: '94802'
  },
  '94803': {
    country: 'US',
    city: 'El Sobrante',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9693,
    longitude: -122.2901,
    postal_code: '94803'
  },
  '94804': {
    country: 'US',
    city: 'Richmond',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9265,
    longitude: -122.3342,
    postal_code: '94804'
  },
  '94805': {
    country: 'US',
    city: 'Richmond',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9417,
    longitude: -122.3238,
    postal_code: '94805'
  },
  '94806': {
    country: 'US',
    city: 'San Pablo',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9724,
    longitude: -122.3369,
    postal_code: '94806'
  },
  '94807': {
    country: 'US',
    city: 'Richmond',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9358,
    longitude: -122.3477,
    postal_code: '94807'
  },
  '94808': {
    country: 'US',
    city: 'Richmond',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9358,
    longitude: -122.3477,
    postal_code: '94808'
  },
  '94820': {
    country: 'US',
    city: 'El Sobrante',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9771,
    longitude: -122.2952,
    postal_code: '94820'
  },
  '94850': {
    country: 'US',
    city: 'Richmond',
    state: 'California',
    state_short: 'CA',
    county: 'Contra Costa',
    latitude: 37.9358,
    longitude: -122.3477,
    postal_code: '94850'
  },
  '94901': {
    country: 'US',
    city: 'San Rafael',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9691,
    longitude: -122.5105,
    postal_code: '94901'
  },
  '94903': {
    country: 'US',
    city: 'San Rafael',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0339,
    longitude: -122.5855,
    postal_code: '94903'
  },
  '94904': {
    country: 'US',
    city: 'Greenbrae',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9479,
    longitude: -122.5363,
    postal_code: '94904'
  },
  '94912': {
    country: 'US',
    city: 'San Rafael',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9735,
    longitude: -122.5311,
    postal_code: '94912'
  },
  '94913': {
    country: 'US',
    city: 'San Rafael',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9735,
    longitude: -122.5311,
    postal_code: '94913'
  },
  '94914': {
    country: 'US',
    city: 'Kentfield',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9521,
    longitude: -122.5572,
    postal_code: '94914'
  },
  '94915': {
    country: 'US',
    city: 'San Rafael',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0739,
    longitude: -122.5594,
    postal_code: '94915'
  },
  '94920': {
    country: 'US',
    city: 'Belvedere Tiburon',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.8843,
    longitude: -122.4637,
    postal_code: '94920'
  },
  '94922': {
    country: 'US',
    city: 'Bodega',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.3514,
    longitude: -122.9741,
    postal_code: '94922'
  },
  '94923': {
    country: 'US',
    city: 'Bodega Bay',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.3309,
    longitude: -123.0373,
    postal_code: '94923'
  },
  '94924': {
    country: 'US',
    city: 'Bolinas',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9079,
    longitude: -122.6947,
    postal_code: '94924'
  },
  '94925': {
    country: 'US',
    city: 'Corte Madera',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9223,
    longitude: -122.5132,
    postal_code: '94925'
  },
  '94926': {
    country: 'US',
    city: 'Rohnert Park',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.3396,
    longitude: -122.7011,
    postal_code: '94926'
  },
  '94927': {
    country: 'US',
    city: 'Rohnert Park',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.3396,
    longitude: -122.7011,
    postal_code: '94927'
  },
  '94928': {
    country: 'US',
    city: 'Rohnert Park',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.347,
    longitude: -122.6941,
    postal_code: '94928'
  },
  '94929': {
    country: 'US',
    city: 'Dillon Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.2508,
    longitude: -122.9653,
    postal_code: '94929'
  },
  '94930': {
    country: 'US',
    city: 'Fairfax',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9883,
    longitude: -122.5937,
    postal_code: '94930'
  },
  '94931': {
    country: 'US',
    city: 'Cotati',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.3259,
    longitude: -122.7048,
    postal_code: '94931'
  },
  '94933': {
    country: 'US',
    city: 'Forest Knolls',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0122,
    longitude: -122.6907,
    postal_code: '94933'
  },
  '94937': {
    country: 'US',
    city: 'Inverness',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.1126,
    longitude: -122.8877,
    postal_code: '94937'
  },
  '94938': {
    country: 'US',
    city: 'Lagunitas',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0139,
    longitude: -122.7016,
    postal_code: '94938'
  },
  '94939': {
    country: 'US',
    city: 'Larkspur',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9367,
    longitude: -122.5362,
    postal_code: '94939'
  },
  '94940': {
    country: 'US',
    city: 'Marshall',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.1762,
    longitude: -122.89,
    postal_code: '94940'
  },
  '94941': {
    country: 'US',
    city: 'Mill Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.8958,
    longitude: -122.5339,
    postal_code: '94941'
  },
  '94942': {
    country: 'US',
    city: 'Mill Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.906,
    longitude: -122.545,
    postal_code: '94942'
  },
  '94945': {
    country: 'US',
    city: 'Novato',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.1163,
    longitude: -122.5714,
    postal_code: '94945'
  },
  '94946': {
    country: 'US',
    city: 'Nicasio',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0546,
    longitude: -122.6964,
    postal_code: '94946'
  },
  '94947': {
    country: 'US',
    city: 'Novato',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0973,
    longitude: -122.5837,
    postal_code: '94947'
  },
  '94948': {
    country: 'US',
    city: 'Novato',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.1489,
    longitude: -122.5737,
    postal_code: '94948'
  },
  '94949': {
    country: 'US',
    city: 'Novato',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0618,
    longitude: -122.5404,
    postal_code: '94949'
  },
  '94950': {
    country: 'US',
    city: 'Olema',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0467,
    longitude: -122.7699,
    postal_code: '94950'
  },
  '94951': {
    country: 'US',
    city: 'Penngrove',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.3153,
    longitude: -122.6483,
    postal_code: '94951'
  },
  '94952': {
    country: 'US',
    city: 'Petaluma',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.2403,
    longitude: -122.6777,
    postal_code: '94952'
  },
  '94953': {
    country: 'US',
    city: 'Petaluma',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.2324,
    longitude: -122.6367,
    postal_code: '94953'
  },
  '94954': {
    country: 'US',
    city: 'Petaluma',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.2507,
    longitude: -122.6155,
    postal_code: '94954'
  },
  '94955': {
    country: 'US',
    city: 'Petaluma',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.2324,
    longitude: -122.6367,
    postal_code: '94955'
  },
  '94956': {
    country: 'US',
    city: 'Point Reyes Station',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0691,
    longitude: -122.8069,
    postal_code: '94956'
  },
  '94957': {
    country: 'US',
    city: 'Ross',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9624,
    longitude: -122.555,
    postal_code: '94957'
  },
  '94960': {
    country: 'US',
    city: 'San Anselmo',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9846,
    longitude: -122.5711,
    postal_code: '94960'
  },
  '94963': {
    country: 'US',
    city: 'San Geronimo',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0133,
    longitude: -122.6639,
    postal_code: '94963'
  },
  '94964': {
    country: 'US',
    city: 'San Quentin',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9416,
    longitude: -122.4844,
    postal_code: '94964'
  },
  '94965': {
    country: 'US',
    city: 'Sausalito',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.8601,
    longitude: -122.4946,
    postal_code: '94965'
  },
  '94966': {
    country: 'US',
    city: 'Sausalito',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.8591,
    longitude: -122.4853,
    postal_code: '94966'
  },
  '94970': {
    country: 'US',
    city: 'Stinson Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.902,
    longitude: -122.6393,
    postal_code: '94970'
  },
  '94971': {
    country: 'US',
    city: 'Tomales',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.2427,
    longitude: -122.9145,
    postal_code: '94971'
  },
  '94972': {
    country: 'US',
    city: 'Valley Ford',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.318,
    longitude: -122.9242,
    postal_code: '94972'
  },
  '94973': {
    country: 'US',
    city: 'Woodacre',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.0069,
    longitude: -122.6382,
    postal_code: '94973'
  },
  '94974': {
    country: 'US',
    city: 'San Quentin',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9413,
    longitude: -122.485,
    postal_code: '94974'
  },
  '94975': {
    country: 'US',
    city: 'Petaluma',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.2324,
    longitude: -122.6367,
    postal_code: '94975'
  },
  '94976': {
    country: 'US',
    city: 'Corte Madera',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9255,
    longitude: -122.5275,
    postal_code: '94976'
  },
  '94977': {
    country: 'US',
    city: 'Larkspur',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9341,
    longitude: -122.5353,
    postal_code: '94977'
  },
  '94978': {
    country: 'US',
    city: 'Fairfax',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9871,
    longitude: -122.5889,
    postal_code: '94978'
  },
  '94979': {
    country: 'US',
    city: 'San Anselmo',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 37.9746,
    longitude: -122.5616,
    postal_code: '94979'
  },
  '94998': {
    country: 'US',
    city: 'Novato',
    state: 'California',
    state_short: 'CA',
    county: 'Marin',
    latitude: 38.1173,
    longitude: -122.5684,
    postal_code: '94998'
  },
  '94999': {
    country: 'US',
    city: 'Petaluma',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.2675,
    longitude: -122.6581,
    postal_code: '94999'
  },
  '95001': {
    country: 'US',
    city: 'Aptos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 36.979,
    longitude: -121.898,
    postal_code: '95001'
  },
  '95002': {
    country: 'US',
    city: 'Alviso',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.426,
    longitude: -121.9736,
    postal_code: '95002'
  },
  '95003': {
    country: 'US',
    city: 'Aptos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 36.9797,
    longitude: -121.8902,
    postal_code: '95003'
  },
  '95004': {
    country: 'US',
    city: 'Aromas',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.8769,
    longitude: -121.6324,
    postal_code: '95004'
  },
  '95005': {
    country: 'US',
    city: 'Ben Lomond',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.0882,
    longitude: -122.0887,
    postal_code: '95005'
  },
  '95006': {
    country: 'US',
    city: 'Boulder Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.1547,
    longitude: -122.1365,
    postal_code: '95006'
  },
  '95007': {
    country: 'US',
    city: 'Brookdale',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.1063,
    longitude: -122.105,
    postal_code: '95007'
  },
  '95008': {
    country: 'US',
    city: 'Campbell',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2803,
    longitude: -121.9539,
    postal_code: '95008'
  },
  '95009': {
    country: 'US',
    city: 'Campbell',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2872,
    longitude: -121.9488,
    postal_code: '95009'
  },
  '95010': {
    country: 'US',
    city: 'Capitola',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 36.9767,
    longitude: -121.9555,
    postal_code: '95010'
  },
  '95011': {
    country: 'US',
    city: 'Campbell',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.294,
    longitude: -121.9571,
    postal_code: '95011'
  },
  '95012': {
    country: 'US',
    city: 'Castroville',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.7658,
    longitude: -121.758,
    postal_code: '95012'
  },
  '95013': {
    country: 'US',
    city: 'Coyote',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2123,
    longitude: -121.7416,
    postal_code: '95013'
  },
  '95014': {
    country: 'US',
    city: 'Cupertino',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.318,
    longitude: -122.0449,
    postal_code: '95014'
  },
  '95015': {
    country: 'US',
    city: 'Cupertino',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.323,
    longitude: -122.0527,
    postal_code: '95015'
  },
  '95017': {
    country: 'US',
    city: 'Davenport',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.0423,
    longitude: -122.2137,
    postal_code: '95017'
  },
  '95018': {
    country: 'US',
    city: 'Felton',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.0662,
    longitude: -122.0618,
    postal_code: '95018'
  },
  '95019': {
    country: 'US',
    city: 'Freedom',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 36.9356,
    longitude: -121.7767,
    postal_code: '95019'
  },
  '95020': {
    country: 'US',
    city: 'Gilroy',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.0139,
    longitude: -121.5773,
    postal_code: '95020'
  },
  '95021': {
    country: 'US',
    city: 'Gilroy',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.0095,
    longitude: -121.5705,
    postal_code: '95021'
  },
  '95023': {
    country: 'US',
    city: 'Hollister',
    state: 'California',
    state_short: 'CA',
    county: 'San Benito',
    latitude: 36.8337,
    longitude: -121.3439,
    postal_code: '95023'
  },
  '95024': {
    country: 'US',
    city: 'Hollister',
    state: 'California',
    state_short: 'CA',
    county: 'San Benito',
    latitude: 36.8586,
    longitude: -121.3982,
    postal_code: '95024'
  },
  '95026': {
    country: 'US',
    city: 'Holy City',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.1584,
    longitude: -121.986,
    postal_code: '95026'
  },
  '95030': {
    country: 'US',
    city: 'Los Gatos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2296,
    longitude: -121.9834,
    postal_code: '95030'
  },
  '95031': {
    country: 'US',
    city: 'Los Gatos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.1574,
    longitude: -121.9676,
    postal_code: '95031'
  },
  '95032': {
    country: 'US',
    city: 'Los Gatos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2417,
    longitude: -121.9554,
    postal_code: '95032'
  },
  '95033': {
    country: 'US',
    city: 'Los Gatos',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.1539,
    longitude: -121.9816,
    postal_code: '95033'
  },
  '95035': {
    country: 'US',
    city: 'Milpitas',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.4352,
    longitude: -121.895,
    postal_code: '95035'
  },
  '95036': {
    country: 'US',
    city: 'Milpitas',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.424,
    longitude: -121.906,
    postal_code: '95036'
  },
  '95037': {
    country: 'US',
    city: 'Morgan Hill',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.1353,
    longitude: -121.6501,
    postal_code: '95037'
  },
  '95038': {
    country: 'US',
    city: 'Morgan Hill',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.1525,
    longitude: -121.6722,
    postal_code: '95038'
  },
  '95039': {
    country: 'US',
    city: 'Moss Landing',
    state: 'California',
    state_short: 'CA',
    county: 'Monterey',
    latitude: 36.8175,
    longitude: -121.7773,
    postal_code: '95039'
  },
  '95041': {
    country: 'US',
    city: 'Mount Hermon',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.0511,
    longitude: -122.0575,
    postal_code: '95041'
  },
  '95042': {
    country: 'US',
    city: 'New Almaden',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.1771,
    longitude: -121.8207,
    postal_code: '95042'
  },
  '95043': {
    country: 'US',
    city: 'Paicines',
    state: 'California',
    state_short: 'CA',
    county: 'San Benito',
    latitude: 36.4985,
    longitude: -120.9744,
    postal_code: '95043'
  },
  '95044': {
    country: 'US',
    city: 'Redwood Estates',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.1584,
    longitude: -121.986,
    postal_code: '95044'
  },
  '95045': {
    country: 'US',
    city: 'San Juan Bautista',
    state: 'California',
    state_short: 'CA',
    county: 'San Benito',
    latitude: 36.8463,
    longitude: -121.5346,
    postal_code: '95045'
  },
  '95046': {
    country: 'US',
    city: 'San Martin',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.0911,
    longitude: -121.5999,
    postal_code: '95046'
  },
  '95050': {
    country: 'US',
    city: 'Santa Clara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3492,
    longitude: -121.953,
    postal_code: '95050'
  },
  '95051': {
    country: 'US',
    city: 'Santa Clara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3483,
    longitude: -121.9844,
    postal_code: '95051'
  },
  '95052': {
    country: 'US',
    city: 'Santa Clara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3522,
    longitude: -121.9583,
    postal_code: '95052'
  },
  '95053': {
    country: 'US',
    city: 'Santa Clara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3498,
    longitude: -121.9378,
    postal_code: '95053'
  },
  '95054': {
    country: 'US',
    city: 'Santa Clara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3924,
    longitude: -121.9623,
    postal_code: '95054'
  },
  '95055': {
    country: 'US',
    city: 'Santa Clara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3451,
    longitude: -121.9769,
    postal_code: '95055'
  },
  '95056': {
    country: 'US',
    city: 'Santa Clara',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3997,
    longitude: -121.9608,
    postal_code: '95056'
  },
  '95060': {
    country: 'US',
    city: 'Santa Cruz',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.0313,
    longitude: -122.1198,
    postal_code: '95060'
  },
  '95061': {
    country: 'US',
    city: 'Santa Cruz',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 36.9741,
    longitude: -122.0308,
    postal_code: '95061'
  },
  '95062': {
    country: 'US',
    city: 'Santa Cruz',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 36.9721,
    longitude: -121.9881,
    postal_code: '95062'
  },
  '95063': {
    country: 'US',
    city: 'Santa Cruz',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 36.9792,
    longitude: -122.0088,
    postal_code: '95063'
  },
  '95064': {
    country: 'US',
    city: 'Santa Cruz',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 36.9959,
    longitude: -122.0578,
    postal_code: '95064'
  },
  '95065': {
    country: 'US',
    city: 'Santa Cruz',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.0089,
    longitude: -121.9849,
    postal_code: '95065'
  },
  '95066': {
    country: 'US',
    city: 'Scotts Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.0597,
    longitude: -122.0152,
    postal_code: '95066'
  },
  '95067': {
    country: 'US',
    city: 'Scotts Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.0511,
    longitude: -122.0136,
    postal_code: '95067'
  },
  '95070': {
    country: 'US',
    city: 'Saratoga',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2713,
    longitude: -122.0227,
    postal_code: '95070'
  },
  '95071': {
    country: 'US',
    city: 'Saratoga',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2593,
    longitude: -122.0302,
    postal_code: '95071'
  },
  '95073': {
    country: 'US',
    city: 'Soquel',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 37.0048,
    longitude: -121.9507,
    postal_code: '95073'
  },
  '95075': {
    country: 'US',
    city: 'Tres Pinos',
    state: 'California',
    state_short: 'CA',
    county: 'San Benito',
    latitude: 36.767,
    longitude: -121.3017,
    postal_code: '95075'
  },
  '95076': {
    country: 'US',
    city: 'Watsonville',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 36.9102,
    longitude: -121.7569,
    postal_code: '95076'
  },
  '95077': {
    country: 'US',
    city: 'Watsonville',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Cruz',
    latitude: 36.9116,
    longitude: -121.7575,
    postal_code: '95077'
  },
  '95101': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3894,
    longitude: -121.8868,
    postal_code: '95101'
  },
  '95103': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3378,
    longitude: -121.8908,
    postal_code: '95103'
  },
  '95106': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3378,
    longitude: -121.8908,
    postal_code: '95106'
  },
  '95108': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3378,
    longitude: -121.8908,
    postal_code: '95108'
  },
  '95109': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3378,
    longitude: -121.8908,
    postal_code: '95109'
  },
  '95110': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3391,
    longitude: -121.9016,
    postal_code: '95110'
  },
  '95111': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2827,
    longitude: -121.8265,
    postal_code: '95111'
  },
  '95112': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3476,
    longitude: -121.887,
    postal_code: '95112'
  },
  '95113': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3329,
    longitude: -121.8916,
    postal_code: '95113'
  },
  '95115': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3378,
    longitude: -121.8908,
    postal_code: '95115'
  },
  '95116': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3518,
    longitude: -121.8508,
    postal_code: '95116'
  },
  '95117': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3108,
    longitude: -121.9623,
    postal_code: '95117'
  },
  '95118': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2568,
    longitude: -121.8896,
    postal_code: '95118'
  },
  '95119': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2329,
    longitude: -121.7875,
    postal_code: '95119'
  },
  '95120': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2144,
    longitude: -121.8574,
    postal_code: '95120'
  },
  '95121': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3042,
    longitude: -121.8099,
    postal_code: '95121'
  },
  '95122': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3293,
    longitude: -121.8339,
    postal_code: '95122'
  },
  '95123': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2458,
    longitude: -121.8306,
    postal_code: '95123'
  },
  '95124': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2563,
    longitude: -121.9229,
    postal_code: '95124'
  },
  '95125': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.296,
    longitude: -121.8939,
    postal_code: '95125'
  },
  '95126': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3249,
    longitude: -121.9153,
    postal_code: '95126'
  },
  '95127': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3692,
    longitude: -121.8208,
    postal_code: '95127'
  },
  '95128': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3163,
    longitude: -121.9356,
    postal_code: '95128'
  },
  '95129': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3066,
    longitude: -122.0002,
    postal_code: '95129'
  },
  '95130': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2886,
    longitude: -121.9818,
    postal_code: '95130'
  },
  '95131': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3864,
    longitude: -121.88,
    postal_code: '95131'
  },
  '95132': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.4031,
    longitude: -121.8585,
    postal_code: '95132'
  },
  '95133': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3729,
    longitude: -121.856,
    postal_code: '95133'
  },
  '95134': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.4087,
    longitude: -121.9406,
    postal_code: '95134'
  },
  '95135': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2974,
    longitude: -121.7562,
    postal_code: '95135'
  },
  '95136': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2685,
    longitude: -121.849,
    postal_code: '95136'
  },
  '95138': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2602,
    longitude: -121.7709,
    postal_code: '95138'
  },
  '95139': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2252,
    longitude: -121.7687,
    postal_code: '95139'
  },
  '95140': {
    country: 'US',
    city: 'Mount Hamilton',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3511,
    longitude: -121.6384,
    postal_code: '95140'
  },
  '95141': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3394,
    longitude: -121.895,
    postal_code: '95141'
  },
  '95148': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3304,
    longitude: -121.7913,
    postal_code: '95148'
  },
  '95150': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3866,
    longitude: -121.897,
    postal_code: '95150'
  },
  '95151': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3198,
    longitude: -121.8262,
    postal_code: '95151'
  },
  '95152': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.4022,
    longitude: -121.847,
    postal_code: '95152'
  },
  '95153': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2488,
    longitude: -121.8459,
    postal_code: '95153'
  },
  '95154': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2649,
    longitude: -121.9139,
    postal_code: '95154'
  },
  '95155': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.31,
    longitude: -121.9011,
    postal_code: '95155'
  },
  '95156': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3576,
    longitude: -121.8416,
    postal_code: '95156'
  },
  '95157': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3008,
    longitude: -121.9777,
    postal_code: '95157'
  },
  '95158': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2625,
    longitude: -121.8779,
    postal_code: '95158'
  },
  '95159': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3179,
    longitude: -121.9349,
    postal_code: '95159'
  },
  '95160': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2187,
    longitude: -121.8601,
    postal_code: '95160'
  },
  '95161': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3894,
    longitude: -121.8868,
    postal_code: '95161'
  },
  '95164': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3916,
    longitude: -121.9203,
    postal_code: '95164'
  },
  '95170': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3103,
    longitude: -122.0093,
    postal_code: '95170'
  },
  '95172': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.334,
    longitude: -121.8847,
    postal_code: '95172'
  },
  '95173': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3352,
    longitude: -121.8938,
    postal_code: '95173'
  },
  '95190': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3894,
    longitude: -121.8868,
    postal_code: '95190'
  },
  '95191': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3262,
    longitude: -121.9158,
    postal_code: '95191'
  },
  '95192': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3383,
    longitude: -121.8801,
    postal_code: '95192'
  },
  '95193': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.2441,
    longitude: -121.8287,
    postal_code: '95193'
  },
  '95194': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3894,
    longitude: -121.8868,
    postal_code: '95194'
  },
  '95196': {
    country: 'US',
    city: 'San Jose',
    state: 'California',
    state_short: 'CA',
    county: 'Santa Clara',
    latitude: 37.3338,
    longitude: -121.8894,
    postal_code: '95196'
  },
  '95201': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.958,
    longitude: -121.2876,
    postal_code: '95201'
  },
  '95202': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9606,
    longitude: -121.2871,
    postal_code: '95202'
  },
  '95203': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9532,
    longitude: -121.3116,
    postal_code: '95203'
  },
  '95204': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9743,
    longitude: -121.3154,
    postal_code: '95204'
  },
  '95205': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9625,
    longitude: -121.2624,
    postal_code: '95205'
  },
  '95206': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9177,
    longitude: -121.3123,
    postal_code: '95206'
  },
  '95207': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.0024,
    longitude: -121.3238,
    postal_code: '95207'
  },
  '95208': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9304,
    longitude: -121.436,
    postal_code: '95208'
  },
  '95209': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.0377,
    longitude: -121.3445,
    postal_code: '95209'
  },
  '95210': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.025,
    longitude: -121.2972,
    postal_code: '95210'
  },
  '95211': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9809,
    longitude: -121.311,
    postal_code: '95211'
  },
  '95212': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.0315,
    longitude: -121.2589,
    postal_code: '95212'
  },
  '95213': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9054,
    longitude: -121.2222,
    postal_code: '95213'
  },
  '95214': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9053,
    longitude: -121.2262,
    postal_code: '95214'
  },
  '95215': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9551,
    longitude: -121.2041,
    postal_code: '95215'
  },
  '95219': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.01,
    longitude: -121.3698,
    postal_code: '95219'
  },
  '95220': {
    country: 'US',
    city: 'Acampo',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.2004,
    longitude: -121.2186,
    postal_code: '95220'
  },
  '95221': {
    country: 'US',
    city: 'Altaville',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.0838,
    longitude: -120.5608,
    postal_code: '95221'
  },
  '95222': {
    country: 'US',
    city: 'Angels Camp',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.071,
    longitude: -120.5722,
    postal_code: '95222'
  },
  '95223': {
    country: 'US',
    city: 'Arnold',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.3086,
    longitude: -120.268,
    postal_code: '95223'
  },
  '95224': {
    country: 'US',
    city: 'Avery',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.2044,
    longitude: -120.3688,
    postal_code: '95224'
  },
  '95225': {
    country: 'US',
    city: 'Burson',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.1838,
    longitude: -120.8894,
    postal_code: '95225'
  },
  '95226': {
    country: 'US',
    city: 'Campo Seco',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.2271,
    longitude: -120.8533,
    postal_code: '95226'
  },
  '95227': {
    country: 'US',
    city: 'Clements',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.1929,
    longitude: -121.0811,
    postal_code: '95227'
  },
  '95228': {
    country: 'US',
    city: 'Copperopolis',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 37.944,
    longitude: -120.6423,
    postal_code: '95228'
  },
  '95229': {
    country: 'US',
    city: 'Douglas Flat',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.1144,
    longitude: -120.4538,
    postal_code: '95229'
  },
  '95230': {
    country: 'US',
    city: 'Farmington',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9299,
    longitude: -121.0002,
    postal_code: '95230'
  },
  '95231': {
    country: 'US',
    city: 'French Camp',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.878,
    longitude: -121.2827,
    postal_code: '95231'
  },
  '95232': {
    country: 'US',
    city: 'Glencoe',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.3554,
    longitude: -120.5778,
    postal_code: '95232'
  },
  '95233': {
    country: 'US',
    city: 'Hathaway Pines',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.1919,
    longitude: -120.3644,
    postal_code: '95233'
  },
  '95234': {
    country: 'US',
    city: 'Holt',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9344,
    longitude: -121.4261,
    postal_code: '95234'
  },
  '95236': {
    country: 'US',
    city: 'Linden',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.032,
    longitude: -121.0493,
    postal_code: '95236'
  },
  '95237': {
    country: 'US',
    city: 'Lockeford',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.1613,
    longitude: -121.1424,
    postal_code: '95237'
  },
  '95240': {
    country: 'US',
    city: 'Lodi',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.1222,
    longitude: -121.2555,
    postal_code: '95240'
  },
  '95241': {
    country: 'US',
    city: 'Lodi',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.1327,
    longitude: -121.2724,
    postal_code: '95241'
  },
  '95242': {
    country: 'US',
    city: 'Lodi',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.1308,
    longitude: -121.3345,
    postal_code: '95242'
  },
  '95245': {
    country: 'US',
    city: 'Mokelumne Hill',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.3152,
    longitude: -120.5591,
    postal_code: '95245'
  },
  '95246': {
    country: 'US',
    city: 'Mountain Ranch',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.2328,
    longitude: -120.4994,
    postal_code: '95246'
  },
  '95247': {
    country: 'US',
    city: 'Murphys',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.1345,
    longitude: -120.4516,
    postal_code: '95247'
  },
  '95248': {
    country: 'US',
    city: 'Rail Road Flat',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.3405,
    longitude: -120.5161,
    postal_code: '95248'
  },
  '95249': {
    country: 'US',
    city: 'San Andreas',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.1904,
    longitude: -120.6441,
    postal_code: '95249'
  },
  '95251': {
    country: 'US',
    city: 'Vallecito',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.1013,
    longitude: -120.4676,
    postal_code: '95251'
  },
  '95252': {
    country: 'US',
    city: 'Valley Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.162,
    longitude: -120.8572,
    postal_code: '95252'
  },
  '95253': {
    country: 'US',
    city: 'Victor',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.138,
    longitude: -121.205,
    postal_code: '95253'
  },
  '95254': {
    country: 'US',
    city: 'Wallace',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.1983,
    longitude: -120.9793,
    postal_code: '95254'
  },
  '95255': {
    country: 'US',
    city: 'West Point',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.4197,
    longitude: -120.4759,
    postal_code: '95255'
  },
  '95257': {
    country: 'US',
    city: 'Wilseyville',
    state: 'California',
    state_short: 'CA',
    county: 'Calaveras',
    latitude: 38.3793,
    longitude: -120.4627,
    postal_code: '95257'
  },
  '95258': {
    country: 'US',
    city: 'Woodbridge',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.1551,
    longitude: -121.3086,
    postal_code: '95258'
  },
  '95267': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.0003,
    longitude: -121.3174,
    postal_code: '95267'
  },
  '95269': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.0187,
    longitude: -121.3225,
    postal_code: '95269'
  },
  '95296': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.9577,
    longitude: -121.2908,
    postal_code: '95296'
  },
  '95297': {
    country: 'US',
    city: 'Stockton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.0025,
    longitude: -121.324,
    postal_code: '95297'
  },
  '95301': {
    country: 'US',
    city: 'Atwater',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.3489,
    longitude: -120.6028,
    postal_code: '95301'
  },
  '95303': {
    country: 'US',
    city: 'Ballico',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.4548,
    longitude: -120.6931,
    postal_code: '95303'
  },
  '95304': {
    country: 'US',
    city: 'Tracy',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.7319,
    longitude: -121.4096,
    postal_code: '95304'
  },
  '95305': {
    country: 'US',
    city: 'Big Oak Flat',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 37.8235,
    longitude: -120.2582,
    postal_code: '95305'
  },
  '95306': {
    country: 'US',
    city: 'Catheys Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Mariposa',
    latitude: 37.4404,
    longitude: -120.1438,
    postal_code: '95306'
  },
  '95307': {
    country: 'US',
    city: 'Ceres',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.5833,
    longitude: -120.9496,
    postal_code: '95307'
  },
  '95309': {
    country: 'US',
    city: 'Chinese Camp',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 37.8594,
    longitude: -120.4069,
    postal_code: '95309'
  },
  '95310': {
    country: 'US',
    city: 'Columbia',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 38.044,
    longitude: -120.3971,
    postal_code: '95310'
  },
  '95311': {
    country: 'US',
    city: 'Coulterville',
    state: 'California',
    state_short: 'CA',
    county: 'Mariposa',
    latitude: 37.7197,
    longitude: -120.1197,
    postal_code: '95311'
  },
  '95312': {
    country: 'US',
    city: 'Cressey',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.4197,
    longitude: -120.6663,
    postal_code: '95312'
  },
  '95313': {
    country: 'US',
    city: 'Crows Landing',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.4218,
    longitude: -121.0411,
    postal_code: '95313'
  },
  '95315': {
    country: 'US',
    city: 'Delhi',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.4273,
    longitude: -120.7752,
    postal_code: '95315'
  },
  '95316': {
    country: 'US',
    city: 'Denair',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.539,
    longitude: -120.7758,
    postal_code: '95316'
  },
  '95317': {
    country: 'US',
    city: 'El Nido',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.1391,
    longitude: -120.5251,
    postal_code: '95317'
  },
  '95318': {
    country: 'US',
    city: 'El Portal',
    state: 'California',
    state_short: 'CA',
    county: 'Mariposa',
    latitude: 37.6747,
    longitude: -119.7841,
    postal_code: '95318'
  },
  '95319': {
    country: 'US',
    city: 'Empire',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6382,
    longitude: -120.9005,
    postal_code: '95319'
  },
  '95320': {
    country: 'US',
    city: 'Escalon',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.7983,
    longitude: -121.0006,
    postal_code: '95320'
  },
  '95321': {
    country: 'US',
    city: 'Groveland',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 37.8298,
    longitude: -120.1037,
    postal_code: '95321'
  },
  '95322': {
    country: 'US',
    city: 'Gustine',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.2001,
    longitude: -121.0047,
    postal_code: '95322'
  },
  '95323': {
    country: 'US',
    city: 'Hickman',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6156,
    longitude: -120.7011,
    postal_code: '95323'
  },
  '95324': {
    country: 'US',
    city: 'Hilmar',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.4002,
    longitude: -120.8723,
    postal_code: '95324'
  },
  '95325': {
    country: 'US',
    city: 'Hornitos',
    state: 'California',
    state_short: 'CA',
    county: 'Mariposa',
    latitude: 37.4676,
    longitude: -120.2793,
    postal_code: '95325'
  },
  '95326': {
    country: 'US',
    city: 'Hughson',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.5964,
    longitude: -120.8627,
    postal_code: '95326'
  },
  '95327': {
    country: 'US',
    city: 'Jamestown',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 37.8906,
    longitude: -120.4717,
    postal_code: '95327'
  },
  '95328': {
    country: 'US',
    city: 'Keyes',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.5591,
    longitude: -120.9148,
    postal_code: '95328'
  },
  '95329': {
    country: 'US',
    city: 'La Grange',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6899,
    longitude: -120.3851,
    postal_code: '95329'
  },
  '95330': {
    country: 'US',
    city: 'Lathrop',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.8209,
    longitude: -121.2827,
    postal_code: '95330'
  },
  '95333': {
    country: 'US',
    city: 'Le Grand',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.2496,
    longitude: -120.2667,
    postal_code: '95333'
  },
  '95334': {
    country: 'US',
    city: 'Livingston',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.3763,
    longitude: -120.7252,
    postal_code: '95334'
  },
  '95335': {
    country: 'US',
    city: 'Long Barn',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 38.093,
    longitude: -120.1344,
    postal_code: '95335'
  },
  '95336': {
    country: 'US',
    city: 'Manteca',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.8134,
    longitude: -121.2132,
    postal_code: '95336'
  },
  '95337': {
    country: 'US',
    city: 'Manteca',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.7808,
    longitude: -121.2344,
    postal_code: '95337'
  },
  '95338': {
    country: 'US',
    city: 'Mariposa',
    state: 'California',
    state_short: 'CA',
    county: 'Mariposa',
    latitude: 37.4931,
    longitude: -119.9219,
    postal_code: '95338'
  },
  '95340': {
    country: 'US',
    city: 'Merced',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.2983,
    longitude: -120.4649,
    postal_code: '95340'
  },
  '95341': {
    country: 'US',
    city: 'Merced',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.2308,
    longitude: -120.5144,
    postal_code: '95341'
  },
  '95343': {
    country: 'US',
    city: 'Merced',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.3082,
    longitude: -120.48,
    postal_code: '95343'
  },
  '95344': {
    country: 'US',
    city: 'Merced',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.3082,
    longitude: -120.48,
    postal_code: '95344'
  },
  '95345': {
    country: 'US',
    city: 'Midpines',
    state: 'California',
    state_short: 'CA',
    county: 'Mariposa',
    latitude: 37.5757,
    longitude: -119.9601,
    postal_code: '95345'
  },
  '95346': {
    country: 'US',
    city: 'Mi Wuk Village',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 38.0675,
    longitude: -120.1794,
    postal_code: '95346'
  },
  '95347': {
    country: 'US',
    city: 'Moccasin',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 37.8108,
    longitude: -120.2988,
    postal_code: '95347'
  },
  '95348': {
    country: 'US',
    city: 'Merced',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.3302,
    longitude: -120.508,
    postal_code: '95348'
  },
  '95350': {
    country: 'US',
    city: 'Modesto',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6746,
    longitude: -121.0113,
    postal_code: '95350'
  },
  '95351': {
    country: 'US',
    city: 'Modesto',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6236,
    longitude: -120.9966,
    postal_code: '95351'
  },
  '95352': {
    country: 'US',
    city: 'Modesto',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6566,
    longitude: -121.0191,
    postal_code: '95352'
  },
  '95353': {
    country: 'US',
    city: 'Modesto',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6424,
    longitude: -120.9999,
    postal_code: '95353'
  },
  '95354': {
    country: 'US',
    city: 'Modesto',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6409,
    longitude: -120.9749,
    postal_code: '95354'
  },
  '95355': {
    country: 'US',
    city: 'Modesto',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6717,
    longitude: -120.9482,
    postal_code: '95355'
  },
  '95356': {
    country: 'US',
    city: 'Modesto',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.7005,
    longitude: -121.0252,
    postal_code: '95356'
  },
  '95357': {
    country: 'US',
    city: 'Modesto',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6693,
    longitude: -120.8817,
    postal_code: '95357'
  },
  '95358': {
    country: 'US',
    city: 'Modesto',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6237,
    longitude: -121.0438,
    postal_code: '95358'
  },
  '95360': {
    country: 'US',
    city: 'Newman',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.3097,
    longitude: -121.0805,
    postal_code: '95360'
  },
  '95361': {
    country: 'US',
    city: 'Oakdale',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.7741,
    longitude: -120.8377,
    postal_code: '95361'
  },
  '95363': {
    country: 'US',
    city: 'Patterson',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.4826,
    longitude: -121.1648,
    postal_code: '95363'
  },
  '95364': {
    country: 'US',
    city: 'Pinecrest',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 38.1889,
    longitude: -119.9924,
    postal_code: '95364'
  },
  '95365': {
    country: 'US',
    city: 'Planada',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.2908,
    longitude: -120.3185,
    postal_code: '95365'
  },
  '95366': {
    country: 'US',
    city: 'Ripon',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.7491,
    longitude: -121.1284,
    postal_code: '95366'
  },
  '95367': {
    country: 'US',
    city: 'Riverbank',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.7298,
    longitude: -120.942,
    postal_code: '95367'
  },
  '95368': {
    country: 'US',
    city: 'Salida',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.7083,
    longitude: -121.0864,
    postal_code: '95368'
  },
  '95369': {
    country: 'US',
    city: 'Snelling',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.5354,
    longitude: -120.378,
    postal_code: '95369'
  },
  '95370': {
    country: 'US',
    city: 'Sonora',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 37.9957,
    longitude: -120.3368,
    postal_code: '95370'
  },
  '95372': {
    country: 'US',
    city: 'Soulsbyville',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 37.9926,
    longitude: -120.2624,
    postal_code: '95372'
  },
  '95373': {
    country: 'US',
    city: 'Standard',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 37.9666,
    longitude: -120.3108,
    postal_code: '95373'
  },
  '95374': {
    country: 'US',
    city: 'Stevinson',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.3283,
    longitude: -120.8764,
    postal_code: '95374'
  },
  '95375': {
    country: 'US',
    city: 'Strawberry',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 38.2042,
    longitude: -120.0101,
    postal_code: '95375'
  },
  '95376': {
    country: 'US',
    city: 'Tracy',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.7383,
    longitude: -121.4345,
    postal_code: '95376'
  },
  '95377': {
    country: 'US',
    city: 'Tracy',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.6567,
    longitude: -121.4955,
    postal_code: '95377'
  },
  '95378': {
    country: 'US',
    city: 'Tracy',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.6761,
    longitude: -121.433,
    postal_code: '95378'
  },
  '95379': {
    country: 'US',
    city: 'Tuolumne',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 37.9678,
    longitude: -120.2357,
    postal_code: '95379'
  },
  '95380': {
    country: 'US',
    city: 'Turlock',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.4888,
    longitude: -120.8535,
    postal_code: '95380'
  },
  '95381': {
    country: 'US',
    city: 'Turlock',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.4994,
    longitude: -120.8428,
    postal_code: '95381'
  },
  '95382': {
    country: 'US',
    city: 'Turlock',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.5239,
    longitude: -120.8517,
    postal_code: '95382'
  },
  '95383': {
    country: 'US',
    city: 'Twain Harte',
    state: 'California',
    state_short: 'CA',
    county: 'Tuolumne',
    latitude: 38.0454,
    longitude: -120.2178,
    postal_code: '95383'
  },
  '95385': {
    country: 'US',
    city: 'Vernalis',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.6176,
    longitude: -121.2581,
    postal_code: '95385'
  },
  '95386': {
    country: 'US',
    city: 'Waterford',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.652,
    longitude: -120.7292,
    postal_code: '95386'
  },
  '95387': {
    country: 'US',
    city: 'Westley',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.5452,
    longitude: -121.2255,
    postal_code: '95387'
  },
  '95388': {
    country: 'US',
    city: 'Winton',
    state: 'California',
    state_short: 'CA',
    county: 'Merced',
    latitude: 37.4014,
    longitude: -120.6045,
    postal_code: '95388'
  },
  '95389': {
    country: 'US',
    city: 'Yosemite National Park',
    state: 'California',
    state_short: 'CA',
    county: 'Mariposa',
    latitude: 37.7314,
    longitude: -119.6489,
    postal_code: '95389'
  },
  '95391': {
    country: 'US',
    city: 'Tracy',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 37.7695,
    longitude: -121.5397,
    postal_code: '95391'
  },
  '95397': {
    country: 'US',
    city: 'Modesto',
    state: 'California',
    state_short: 'CA',
    county: 'Stanislaus',
    latitude: 37.6566,
    longitude: -121.0191,
    postal_code: '95397'
  },
  '95401': {
    country: 'US',
    city: 'Santa Rosa',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4432,
    longitude: -122.7547,
    postal_code: '95401'
  },
  '95402': {
    country: 'US',
    city: 'Santa Rosa',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4399,
    longitude: -122.7096,
    postal_code: '95402'
  },
  '95403': {
    country: 'US',
    city: 'Santa Rosa',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4822,
    longitude: -122.7473,
    postal_code: '95403'
  },
  '95404': {
    country: 'US',
    city: 'Santa Rosa',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4405,
    longitude: -122.7144,
    postal_code: '95404'
  },
  '95405': {
    country: 'US',
    city: 'Santa Rosa',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4386,
    longitude: -122.6727,
    postal_code: '95405'
  },
  '95406': {
    country: 'US',
    city: 'Santa Rosa',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4399,
    longitude: -122.7096,
    postal_code: '95406'
  },
  '95407': {
    country: 'US',
    city: 'Santa Rosa',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4089,
    longitude: -122.7339,
    postal_code: '95407'
  },
  '95409': {
    country: 'US',
    city: 'Santa Rosa',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4592,
    longitude: -122.6393,
    postal_code: '95409'
  },
  '95410': {
    country: 'US',
    city: 'Albion',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.2131,
    longitude: -123.72,
    postal_code: '95410'
  },
  '95412': {
    country: 'US',
    city: 'Annapolis',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.7026,
    longitude: -123.3539,
    postal_code: '95412'
  },
  '95415': {
    country: 'US',
    city: 'Boonville',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.0197,
    longitude: -123.3856,
    postal_code: '95415'
  },
  '95416': {
    country: 'US',
    city: 'Boyes Hot Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.3141,
    longitude: -122.4843,
    postal_code: '95416'
  },
  '95417': {
    country: 'US',
    city: 'Branscomb',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.6949,
    longitude: -123.5527,
    postal_code: '95417'
  },
  '95418': {
    country: 'US',
    city: 'Calpella',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.2214,
    longitude: -123.2154,
    postal_code: '95418'
  },
  '95419': {
    country: 'US',
    city: 'Camp Meeker',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.425,
    longitude: -122.9485,
    postal_code: '95419'
  },
  '95420': {
    country: 'US',
    city: 'Caspar',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.3629,
    longitude: -123.7944,
    postal_code: '95420'
  },
  '95421': {
    country: 'US',
    city: 'Cazadero',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.5918,
    longitude: -123.1965,
    postal_code: '95421'
  },
  '95422': {
    country: 'US',
    city: 'Clearlake',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 38.9576,
    longitude: -122.636,
    postal_code: '95422'
  },
  '95423': {
    country: 'US',
    city: 'Clearlake Oaks',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 39.0664,
    longitude: -122.6558,
    postal_code: '95423'
  },
  '95424': {
    country: 'US',
    city: 'Clearlake Park',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 38.9666,
    longitude: -122.65,
    postal_code: '95424'
  },
  '95425': {
    country: 'US',
    city: 'Cloverdale',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.7931,
    longitude: -123.0074,
    postal_code: '95425'
  },
  '95426': {
    country: 'US',
    city: 'Cobb',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 38.8155,
    longitude: -122.7132,
    postal_code: '95426'
  },
  '95427': {
    country: 'US',
    city: 'Comptche',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.2767,
    longitude: -123.5873,
    postal_code: '95427'
  },
  '95428': {
    country: 'US',
    city: 'Covelo',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.82,
    longitude: -123.0585,
    postal_code: '95428'
  },
  '95429': {
    country: 'US',
    city: 'Dos Rios',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.7168,
    longitude: -123.3533,
    postal_code: '95429'
  },
  '95430': {
    country: 'US',
    city: 'Duncans Mills',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4538,
    longitude: -123.055,
    postal_code: '95430'
  },
  '95431': {
    country: 'US',
    city: 'Eldridge',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.3488,
    longitude: -122.5108,
    postal_code: '95431'
  },
  '95432': {
    country: 'US',
    city: 'Elk',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.1593,
    longitude: -123.7219,
    postal_code: '95432'
  },
  '95433': {
    country: 'US',
    city: 'El Verano',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.2993,
    longitude: -122.4867,
    postal_code: '95433'
  },
  '95435': {
    country: 'US',
    city: 'Finley',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 39.0043,
    longitude: -122.8755,
    postal_code: '95435'
  },
  '95436': {
    country: 'US',
    city: 'Forestville',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4923,
    longitude: -122.9042,
    postal_code: '95436'
  },
  '95437': {
    country: 'US',
    city: 'Fort Bragg',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.4402,
    longitude: -123.7703,
    postal_code: '95437'
  },
  '95439': {
    country: 'US',
    city: 'Fulton',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4947,
    longitude: -122.7761,
    postal_code: '95439'
  },
  '95441': {
    country: 'US',
    city: 'Geyserville',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.7173,
    longitude: -122.8834,
    postal_code: '95441'
  },
  '95442': {
    country: 'US',
    city: 'Glen Ellen',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.3662,
    longitude: -122.5196,
    postal_code: '95442'
  },
  '95443': {
    country: 'US',
    city: 'Glenhaven',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 39.0263,
    longitude: -122.733,
    postal_code: '95443'
  },
  '95444': {
    country: 'US',
    city: 'Graton',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4335,
    longitude: -122.8676,
    postal_code: '95444'
  },
  '95445': {
    country: 'US',
    city: 'Gualala',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 38.8251,
    longitude: -123.5399,
    postal_code: '95445'
  },
  '95446': {
    country: 'US',
    city: 'Guerneville',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.5055,
    longitude: -122.9965,
    postal_code: '95446'
  },
  '95448': {
    country: 'US',
    city: 'Healdsburg',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.6184,
    longitude: -122.862,
    postal_code: '95448'
  },
  '95449': {
    country: 'US',
    city: 'Hopland',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 38.938,
    longitude: -123.0703,
    postal_code: '95449'
  },
  '95450': {
    country: 'US',
    city: 'Jenner',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4987,
    longitude: -123.1974,
    postal_code: '95450'
  },
  '95451': {
    country: 'US',
    city: 'Kelseyville',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 38.9421,
    longitude: -122.7777,
    postal_code: '95451'
  },
  '95452': {
    country: 'US',
    city: 'Kenwood',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4168,
    longitude: -122.5547,
    postal_code: '95452'
  },
  '95453': {
    country: 'US',
    city: 'Lakeport',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 39.047,
    longitude: -122.9328,
    postal_code: '95453'
  },
  '95454': {
    country: 'US',
    city: 'Laytonville',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.6627,
    longitude: -123.4929,
    postal_code: '95454'
  },
  '95456': {
    country: 'US',
    city: 'Little River',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.2707,
    longitude: -123.7883,
    postal_code: '95456'
  },
  '95457': {
    country: 'US',
    city: 'Lower Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 38.8915,
    longitude: -122.5914,
    postal_code: '95457'
  },
  '95458': {
    country: 'US',
    city: 'Lucerne',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 39.0783,
    longitude: -122.7846,
    postal_code: '95458'
  },
  '95459': {
    country: 'US',
    city: 'Manchester',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.0097,
    longitude: -123.6523,
    postal_code: '95459'
  },
  '95460': {
    country: 'US',
    city: 'Mendocino',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.3173,
    longitude: -123.7739,
    postal_code: '95460'
  },
  '95461': {
    country: 'US',
    city: 'Middletown',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 38.7824,
    longitude: -122.6487,
    postal_code: '95461'
  },
  '95462': {
    country: 'US',
    city: 'Monte Rio',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4706,
    longitude: -123.0172,
    postal_code: '95462'
  },
  '95463': {
    country: 'US',
    city: 'Navarro',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.185,
    longitude: -123.5268,
    postal_code: '95463'
  },
  '95464': {
    country: 'US',
    city: 'Nice',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 39.1194,
    longitude: -122.8315,
    postal_code: '95464'
  },
  '95465': {
    country: 'US',
    city: 'Occidental',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4087,
    longitude: -122.9954,
    postal_code: '95465'
  },
  '95466': {
    country: 'US',
    city: 'Philo',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.0657,
    longitude: -123.445,
    postal_code: '95466'
  },
  '95467': {
    country: 'US',
    city: 'Hidden Valley Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 38.8036,
    longitude: -122.5407,
    postal_code: '95467'
  },
  '95468': {
    country: 'US',
    city: 'Point Arena',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 38.9152,
    longitude: -123.6,
    postal_code: '95468'
  },
  '95469': {
    country: 'US',
    city: 'Potter Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.3932,
    longitude: -123.0647,
    postal_code: '95469'
  },
  '95470': {
    country: 'US',
    city: 'Redwood Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.2779,
    longitude: -123.2243,
    postal_code: '95470'
  },
  '95471': {
    country: 'US',
    city: 'Rio Nido',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.521,
    longitude: -122.9769,
    postal_code: '95471'
  },
  '95472': {
    country: 'US',
    city: 'Sebastopol',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.3941,
    longitude: -122.8433,
    postal_code: '95472'
  },
  '95473': {
    country: 'US',
    city: 'Sebastopol',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4022,
    longitude: -122.8227,
    postal_code: '95473'
  },
  '95476': {
    country: 'US',
    city: 'Sonoma',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.2849,
    longitude: -122.4696,
    postal_code: '95476'
  },
  '95480': {
    country: 'US',
    city: 'Stewarts Point',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.7082,
    longitude: -123.3478,
    postal_code: '95480'
  },
  '95481': {
    country: 'US',
    city: 'Talmage',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.1269,
    longitude: -123.1658,
    postal_code: '95481'
  },
  '95482': {
    country: 'US',
    city: 'Ukiah',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.1552,
    longitude: -123.1951,
    postal_code: '95482'
  },
  '95485': {
    country: 'US',
    city: 'Upper Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 39.1804,
    longitude: -122.9144,
    postal_code: '95485'
  },
  '95486': {
    country: 'US',
    city: 'Villa Grande',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.4741,
    longitude: -123.0242,
    postal_code: '95486'
  },
  '95487': {
    country: 'US',
    city: 'Vineburg',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.2725,
    longitude: -122.4375,
    postal_code: '95487'
  },
  '95488': {
    country: 'US',
    city: 'Westport',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.6843,
    longitude: -123.7686,
    postal_code: '95488'
  },
  '95490': {
    country: 'US',
    city: 'Willits',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.4493,
    longitude: -123.3679,
    postal_code: '95490'
  },
  '95492': {
    country: 'US',
    city: 'Windsor',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.5443,
    longitude: -122.8073,
    postal_code: '95492'
  },
  '95493': {
    country: 'US',
    city: 'Witter Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Lake',
    latitude: 39.1821,
    longitude: -122.9711,
    postal_code: '95493'
  },
  '95494': {
    country: 'US',
    city: 'Yorkville',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 38.9235,
    longitude: -123.2973,
    postal_code: '95494'
  },
  '95497': {
    country: 'US',
    city: 'The Sea Ranch',
    state: 'California',
    state_short: 'CA',
    county: 'Sonoma',
    latitude: 38.7256,
    longitude: -123.4675,
    postal_code: '95497'
  },
  '95501': {
    country: 'US',
    city: 'Eureka',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.7938,
    longitude: -124.1573,
    postal_code: '95501'
  },
  '95502': {
    country: 'US',
    city: 'Eureka',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.7965,
    longitude: -124.1737,
    postal_code: '95502'
  },
  '95503': {
    country: 'US',
    city: 'Eureka',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.7592,
    longitude: -124.1593,
    postal_code: '95503'
  },
  '95511': {
    country: 'US',
    city: 'Alderpoint',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.1676,
    longitude: -123.6192,
    postal_code: '95511'
  },
  '95514': {
    country: 'US',
    city: 'Blocksburg',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.2987,
    longitude: -123.6576,
    postal_code: '95514'
  },
  '95518': {
    country: 'US',
    city: 'Arcata',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.8685,
    longitude: -124.0856,
    postal_code: '95518'
  },
  '95519': {
    country: 'US',
    city: 'Mckinleyville',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.9465,
    longitude: -124.0834,
    postal_code: '95519'
  },
  '95521': {
    country: 'US',
    city: 'Arcata',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.8742,
    longitude: -124.0765,
    postal_code: '95521'
  },
  '95524': {
    country: 'US',
    city: 'Bayside',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.8266,
    longitude: -124.0552,
    postal_code: '95524'
  },
  '95525': {
    country: 'US',
    city: 'Blue Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.9374,
    longitude: -123.8913,
    postal_code: '95525'
  },
  '95526': {
    country: 'US',
    city: 'Bridgeville',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.4693,
    longitude: -123.7998,
    postal_code: '95526'
  },
  '95527': {
    country: 'US',
    city: 'Burnt Ranch',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.7897,
    longitude: -123.4113,
    postal_code: '95527'
  },
  '95528': {
    country: 'US',
    city: 'Carlotta',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.507,
    longitude: -123.9743,
    postal_code: '95528'
  },
  '95531': {
    country: 'US',
    city: 'Crescent City',
    state: 'California',
    state_short: 'CA',
    county: 'Del Norte',
    latitude: 41.7817,
    longitude: -124.1332,
    postal_code: '95531'
  },
  '95532': {
    country: 'US',
    city: 'Crescent City',
    state: 'California',
    state_short: 'CA',
    county: 'Del Norte',
    latitude: 41.7561,
    longitude: -124.2005,
    postal_code: '95532'
  },
  '95534': {
    country: 'US',
    city: 'Cutten',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.7965,
    longitude: -124.1737,
    postal_code: '95534'
  },
  '95536': {
    country: 'US',
    city: 'Ferndale',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.5259,
    longitude: -124.2514,
    postal_code: '95536'
  },
  '95537': {
    country: 'US',
    city: 'Fields Landing',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.7268,
    longitude: -124.2174,
    postal_code: '95537'
  },
  '95538': {
    country: 'US',
    city: 'Fort Dick',
    state: 'California',
    state_short: 'CA',
    county: 'Del Norte',
    latitude: 41.8679,
    longitude: -124.149,
    postal_code: '95538'
  },
  '95540': {
    country: 'US',
    city: 'Fortuna',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.5835,
    longitude: -124.1473,
    postal_code: '95540'
  },
  '95542': {
    country: 'US',
    city: 'Garberville',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.0864,
    longitude: -123.7991,
    postal_code: '95542'
  },
  '95543': {
    country: 'US',
    city: 'Gasquet',
    state: 'California',
    state_short: 'CA',
    county: 'Del Norte',
    latitude: 41.9056,
    longitude: -123.831,
    postal_code: '95543'
  },
  '95545': {
    country: 'US',
    city: 'Honeydew',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.2421,
    longitude: -124.0972,
    postal_code: '95545'
  },
  '95546': {
    country: 'US',
    city: 'Hoopa',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 41.0504,
    longitude: -123.6742,
    postal_code: '95546'
  },
  '95547': {
    country: 'US',
    city: 'Hydesville',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.5485,
    longitude: -124.0847,
    postal_code: '95547'
  },
  '95548': {
    country: 'US',
    city: 'Klamath',
    state: 'California',
    state_short: 'CA',
    county: 'Del Norte',
    latitude: 41.5804,
    longitude: -124.0387,
    postal_code: '95548'
  },
  '95549': {
    country: 'US',
    city: 'Kneeland',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.6405,
    longitude: -123.8826,
    postal_code: '95549'
  },
  '95550': {
    country: 'US',
    city: 'Korbel',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.7775,
    longitude: -123.8486,
    postal_code: '95550'
  },
  '95551': {
    country: 'US',
    city: 'Loleta',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.6589,
    longitude: -124.2251,
    postal_code: '95551'
  },
  '95552': {
    country: 'US',
    city: 'Mad River',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.3316,
    longitude: -123.3904,
    postal_code: '95552'
  },
  '95553': {
    country: 'US',
    city: 'Miranda',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.2397,
    longitude: -123.8077,
    postal_code: '95553'
  },
  '95554': {
    country: 'US',
    city: 'Myers Flat',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.2841,
    longitude: -123.7945,
    postal_code: '95554'
  },
  '95555': {
    country: 'US',
    city: 'Orick',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 41.3596,
    longitude: -124.0317,
    postal_code: '95555'
  },
  '95556': {
    country: 'US',
    city: 'Orleans',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 41.3115,
    longitude: -123.5399,
    postal_code: '95556'
  },
  '95558': {
    country: 'US',
    city: 'Petrolia',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.2868,
    longitude: -124.2271,
    postal_code: '95558'
  },
  '95559': {
    country: 'US',
    city: 'Phillipsville',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.2008,
    longitude: -123.7735,
    postal_code: '95559'
  },
  '95560': {
    country: 'US',
    city: 'Redway',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.1201,
    longitude: -123.8234,
    postal_code: '95560'
  },
  '95562': {
    country: 'US',
    city: 'Rio Dell',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.4987,
    longitude: -124.1102,
    postal_code: '95562'
  },
  '95563': {
    country: 'US',
    city: 'Salyer',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.8558,
    longitude: -123.573,
    postal_code: '95563'
  },
  '95564': {
    country: 'US',
    city: 'Samoa',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.8037,
    longitude: -124.1936,
    postal_code: '95564'
  },
  '95565': {
    country: 'US',
    city: 'Scotia',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.4583,
    longitude: -124.0517,
    postal_code: '95565'
  },
  '95567': {
    country: 'US',
    city: 'Smith River',
    state: 'California',
    state_short: 'CA',
    county: 'Del Norte',
    latitude: 41.9404,
    longitude: -124.1587,
    postal_code: '95567'
  },
  '95568': {
    country: 'US',
    city: 'Somes Bar',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.4533,
    longitude: -123.4634,
    postal_code: '95568'
  },
  '95569': {
    country: 'US',
    city: 'Redcrest',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.3437,
    longitude: -123.9095,
    postal_code: '95569'
  },
  '95570': {
    country: 'US',
    city: 'Trinidad',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 41.0593,
    longitude: -124.1431,
    postal_code: '95570'
  },
  '95571': {
    country: 'US',
    city: 'Weott',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.3218,
    longitude: -123.9217,
    postal_code: '95571'
  },
  '95573': {
    country: 'US',
    city: 'Willow Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.9484,
    longitude: -123.6276,
    postal_code: '95573'
  },
  '95585': {
    country: 'US',
    city: 'Leggett',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.8481,
    longitude: -123.6615,
    postal_code: '95585'
  },
  '95587': {
    country: 'US',
    city: 'Piercy',
    state: 'California',
    state_short: 'CA',
    county: 'Mendocino',
    latitude: 39.9465,
    longitude: -123.7552,
    postal_code: '95587'
  },
  '95589': {
    country: 'US',
    city: 'Whitethorn',
    state: 'California',
    state_short: 'CA',
    county: 'Humboldt',
    latitude: 40.0231,
    longitude: -124.0139,
    postal_code: '95589'
  },
  '95595': {
    country: 'US',
    city: 'Zenia',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.2147,
    longitude: -123.3911,
    postal_code: '95595'
  },
  '95601': {
    country: 'US',
    city: 'Amador City',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.4194,
    longitude: -120.823,
    postal_code: '95601'
  },
  '95602': {
    country: 'US',
    city: 'Auburn',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.9829,
    longitude: -121.0944,
    postal_code: '95602'
  },
  '95603': {
    country: 'US',
    city: 'Auburn',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.9115,
    longitude: -121.08,
    postal_code: '95603'
  },
  '95604': {
    country: 'US',
    city: 'Auburn',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.9029,
    longitude: -121.067,
    postal_code: '95604'
  },
  '95605': {
    country: 'US',
    city: 'West Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.5927,
    longitude: -121.5325,
    postal_code: '95605'
  },
  '95606': {
    country: 'US',
    city: 'Brooks',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.8065,
    longitude: -122.2039,
    postal_code: '95606'
  },
  '95607': {
    country: 'US',
    city: 'Capay',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.7209,
    longitude: -122.0912,
    postal_code: '95607'
  },
  '95608': {
    country: 'US',
    city: 'Carmichael',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6284,
    longitude: -121.3287,
    postal_code: '95608'
  },
  '95609': {
    country: 'US',
    city: 'Carmichael',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6257,
    longitude: -121.3272,
    postal_code: '95609'
  },
  '95610': {
    country: 'US',
    city: 'Citrus Heights',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6946,
    longitude: -121.2692,
    postal_code: '95610'
  },
  '95611': {
    country: 'US',
    city: 'Citrus Heights',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.7072,
    longitude: -121.28,
    postal_code: '95611'
  },
  '95612': {
    country: 'US',
    city: 'Clarksburg',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.3945,
    longitude: -121.5641,
    postal_code: '95612'
  },
  '95613': {
    country: 'US',
    city: 'Coloma',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.8,
    longitude: -120.8891,
    postal_code: '95613'
  },
  '95614': {
    country: 'US',
    city: 'Cool',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.8923,
    longitude: -120.9802,
    postal_code: '95614'
  },
  '95615': {
    country: 'US',
    city: 'Courtland',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.3137,
    longitude: -121.563,
    postal_code: '95615'
  },
  '95616': {
    country: 'US',
    city: 'Davis',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.5538,
    longitude: -121.7418,
    postal_code: '95616'
  },
  '95617': {
    country: 'US',
    city: 'Davis',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.5494,
    longitude: -121.7253,
    postal_code: '95617'
  },
  '95618': {
    country: 'US',
    city: 'Davis',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.5449,
    longitude: -121.7405,
    postal_code: '95618'
  },
  '95619': {
    country: 'US',
    city: 'Diamond Springs',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.6865,
    longitude: -120.8145,
    postal_code: '95619'
  },
  '95620': {
    country: 'US',
    city: 'Dixon',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.4403,
    longitude: -121.8088,
    postal_code: '95620'
  },
  '95621': {
    country: 'US',
    city: 'Citrus Heights',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6952,
    longitude: -121.3075,
    postal_code: '95621'
  },
  '95623': {
    country: 'US',
    city: 'El Dorado',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.633,
    longitude: -120.8498,
    postal_code: '95623'
  },
  '95624': {
    country: 'US',
    city: 'Elk Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.4232,
    longitude: -121.3599,
    postal_code: '95624'
  },
  '95625': {
    country: 'US',
    city: 'Elmira',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.3482,
    longitude: -121.91,
    postal_code: '95625'
  },
  '95626': {
    country: 'US',
    city: 'Elverta',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.7233,
    longitude: -121.4497,
    postal_code: '95626'
  },
  '95627': {
    country: 'US',
    city: 'Esparto',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.7061,
    longitude: -122.0152,
    postal_code: '95627'
  },
  '95628': {
    country: 'US',
    city: 'Fair Oaks',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6554,
    longitude: -121.2611,
    postal_code: '95628'
  },
  '95629': {
    country: 'US',
    city: 'Fiddletown',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.5234,
    longitude: -120.6763,
    postal_code: '95629'
  },
  '95630': {
    country: 'US',
    city: 'Folsom',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6709,
    longitude: -121.1529,
    postal_code: '95630'
  },
  '95631': {
    country: 'US',
    city: 'Foresthill',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.0682,
    longitude: -120.7224,
    postal_code: '95631'
  },
  '95632': {
    country: 'US',
    city: 'Galt',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.2691,
    longitude: -121.3,
    postal_code: '95632'
  },
  '95633': {
    country: 'US',
    city: 'Garden Valley',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.8665,
    longitude: -120.8567,
    postal_code: '95633'
  },
  '95634': {
    country: 'US',
    city: 'Georgetown',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.9185,
    longitude: -120.7599,
    postal_code: '95634'
  },
  '95635': {
    country: 'US',
    city: 'Greenwood',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.9143,
    longitude: -120.9001,
    postal_code: '95635'
  },
  '95636': {
    country: 'US',
    city: 'Grizzly Flats',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.6489,
    longitude: -120.5098,
    postal_code: '95636'
  },
  '95637': {
    country: 'US',
    city: 'Guinda',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.8407,
    longitude: -122.2036,
    postal_code: '95637'
  },
  '95638': {
    country: 'US',
    city: 'Herald',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.3119,
    longitude: -121.1729,
    postal_code: '95638'
  },
  '95639': {
    country: 'US',
    city: 'Hood',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.3702,
    longitude: -121.5143,
    postal_code: '95639'
  },
  '95640': {
    country: 'US',
    city: 'Ione',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.3324,
    longitude: -120.9418,
    postal_code: '95640'
  },
  '95641': {
    country: 'US',
    city: 'Isleton',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.157,
    longitude: -121.6066,
    postal_code: '95641'
  },
  '95642': {
    country: 'US',
    city: 'Jackson',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.3545,
    longitude: -120.7573,
    postal_code: '95642'
  },
  '95644': {
    country: 'US',
    city: 'Kit Carson',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.6707,
    longitude: -120.1135,
    postal_code: '95644'
  },
  '95645': {
    country: 'US',
    city: 'Knights Landing',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.8517,
    longitude: -121.7334,
    postal_code: '95645'
  },
  '95646': {
    country: 'US',
    city: 'Kirkwood',
    state: 'California',
    state_short: 'CA',
    county: 'Alpine',
    latitude: 38.6918,
    longitude: -120.0736,
    postal_code: '95646'
  },
  '95648': {
    country: 'US',
    city: 'Lincoln',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.8942,
    longitude: -121.2908,
    postal_code: '95648'
  },
  '95650': {
    country: 'US',
    city: 'Loomis',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.8071,
    longitude: -121.1698,
    postal_code: '95650'
  },
  '95651': {
    country: 'US',
    city: 'Lotus',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.8278,
    longitude: -120.9238,
    postal_code: '95651'
  },
  '95652': {
    country: 'US',
    city: 'Mcclellan',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6621,
    longitude: -121.3955,
    postal_code: '95652'
  },
  '95653': {
    country: 'US',
    city: 'Madison',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.6802,
    longitude: -121.9721,
    postal_code: '95653'
  },
  '95654': {
    country: 'US',
    city: 'Martell',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.3515,
    longitude: -120.7752,
    postal_code: '95654'
  },
  '95655': {
    country: 'US',
    city: 'Mather',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5579,
    longitude: -121.291,
    postal_code: '95655'
  },
  '95656': {
    country: 'US',
    city: 'Mount Aukum',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.55,
    longitude: -120.7304,
    postal_code: '95656'
  },
  '95658': {
    country: 'US',
    city: 'Newcastle',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.8763,
    longitude: -121.143,
    postal_code: '95658'
  },
  '95659': {
    country: 'US',
    city: 'Nicolaus',
    state: 'California',
    state_short: 'CA',
    county: 'Sutter',
    latitude: 38.8657,
    longitude: -121.557,
    postal_code: '95659'
  },
  '95660': {
    country: 'US',
    city: 'North Highlands',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6707,
    longitude: -121.3781,
    postal_code: '95660'
  },
  '95661': {
    country: 'US',
    city: 'Roseville',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.7346,
    longitude: -121.234,
    postal_code: '95661'
  },
  '95662': {
    country: 'US',
    city: 'Orangevale',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6845,
    longitude: -121.2256,
    postal_code: '95662'
  },
  '95663': {
    country: 'US',
    city: 'Penryn',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.8567,
    longitude: -121.1791,
    postal_code: '95663'
  },
  '95664': {
    country: 'US',
    city: 'Pilot Hill',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.8135,
    longitude: -121.0308,
    postal_code: '95664'
  },
  '95665': {
    country: 'US',
    city: 'Pine Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.4049,
    longitude: -120.6544,
    postal_code: '95665'
  },
  '95666': {
    country: 'US',
    city: 'Pioneer',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.4319,
    longitude: -120.5719,
    postal_code: '95666'
  },
  '95667': {
    country: 'US',
    city: 'Placerville',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.7195,
    longitude: -120.8046,
    postal_code: '95667'
  },
  '95668': {
    country: 'US',
    city: 'Pleasant Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Sutter',
    latitude: 38.8115,
    longitude: -121.4982,
    postal_code: '95668'
  },
  '95669': {
    country: 'US',
    city: 'Plymouth',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.4916,
    longitude: -120.8819,
    postal_code: '95669'
  },
  '95670': {
    country: 'US',
    city: 'Rancho Cordova',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6072,
    longitude: -121.2761,
    postal_code: '95670'
  },
  '95671': {
    country: 'US',
    city: 'Represa',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.7065,
    longitude: -121.1694,
    postal_code: '95671'
  },
  '95672': {
    country: 'US',
    city: 'Rescue',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.7194,
    longitude: -120.9945,
    postal_code: '95672'
  },
  '95673': {
    country: 'US',
    city: 'Rio Linda',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6895,
    longitude: -121.4479,
    postal_code: '95673'
  },
  '95674': {
    country: 'US',
    city: 'Rio Oso',
    state: 'California',
    state_short: 'CA',
    county: 'Sutter',
    latitude: 38.967,
    longitude: -121.4773,
    postal_code: '95674'
  },
  '95675': {
    country: 'US',
    city: 'River Pines',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.5463,
    longitude: -120.743,
    postal_code: '95675'
  },
  '95676': {
    country: 'US',
    city: 'Robbins',
    state: 'California',
    state_short: 'CA',
    county: 'Sutter',
    latitude: 38.8702,
    longitude: -121.7052,
    postal_code: '95676'
  },
  '95677': {
    country: 'US',
    city: 'Rocklin',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.7877,
    longitude: -121.2366,
    postal_code: '95677'
  },
  '95678': {
    country: 'US',
    city: 'Roseville',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.7609,
    longitude: -121.2867,
    postal_code: '95678'
  },
  '95679': {
    country: 'US',
    city: 'Rumsey',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.8953,
    longitude: -122.3079,
    postal_code: '95679'
  },
  '95680': {
    country: 'US',
    city: 'Ryde',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.2386,
    longitude: -121.5594,
    postal_code: '95680'
  },
  '95681': {
    country: 'US',
    city: 'Sheridan',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.9953,
    longitude: -121.368,
    postal_code: '95681'
  },
  '95682': {
    country: 'US',
    city: 'Shingle Springs',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.6465,
    longitude: -120.9641,
    postal_code: '95682'
  },
  '95683': {
    country: 'US',
    city: 'Sloughhouse',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5143,
    longitude: -121.0964,
    postal_code: '95683'
  },
  '95684': {
    country: 'US',
    city: 'Somerset',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.5953,
    longitude: -120.5949,
    postal_code: '95684'
  },
  '95685': {
    country: 'US',
    city: 'Sutter Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.4175,
    longitude: -120.7951,
    postal_code: '95685'
  },
  '95686': {
    country: 'US',
    city: 'Thornton',
    state: 'California',
    state_short: 'CA',
    county: 'San Joaquin',
    latitude: 38.2261,
    longitude: -121.4236,
    postal_code: '95686'
  },
  '95687': {
    country: 'US',
    city: 'Vacaville',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.3482,
    longitude: -121.9538,
    postal_code: '95687'
  },
  '95688': {
    country: 'US',
    city: 'Vacaville',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.3847,
    longitude: -121.9887,
    postal_code: '95688'
  },
  '95689': {
    country: 'US',
    city: 'Volcano',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.4765,
    longitude: -120.6017,
    postal_code: '95689'
  },
  '95690': {
    country: 'US',
    city: 'Walnut Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.2396,
    longitude: -121.5443,
    postal_code: '95690'
  },
  '95691': {
    country: 'US',
    city: 'West Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.5673,
    longitude: -121.5516,
    postal_code: '95691'
  },
  '95692': {
    country: 'US',
    city: 'Wheatland',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.0337,
    longitude: -121.4235,
    postal_code: '95692'
  },
  '95693': {
    country: 'US',
    city: 'Wilton',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.3983,
    longitude: -121.2303,
    postal_code: '95693'
  },
  '95694': {
    country: 'US',
    city: 'Winters',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.5322,
    longitude: -121.9676,
    postal_code: '95694'
  },
  '95695': {
    country: 'US',
    city: 'Woodland',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.6816,
    longitude: -121.8052,
    postal_code: '95695'
  },
  '95696': {
    country: 'US',
    city: 'Vacaville',
    state: 'California',
    state_short: 'CA',
    county: 'Solano',
    latitude: 38.43,
    longitude: -122.0168,
    postal_code: '95696'
  },
  '95697': {
    country: 'US',
    city: 'Yolo',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.7343,
    longitude: -121.8066,
    postal_code: '95697'
  },
  '95698': {
    country: 'US',
    city: 'Zamora',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.8204,
    longitude: -121.9191,
    postal_code: '95698'
  },
  '95699': {
    country: 'US',
    city: 'Drytown',
    state: 'California',
    state_short: 'CA',
    county: 'Amador',
    latitude: 38.4411,
    longitude: -120.8533,
    postal_code: '95699'
  },
  '95701': {
    country: 'US',
    city: 'Alta',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.2441,
    longitude: -120.7531,
    postal_code: '95701'
  },
  '95703': {
    country: 'US',
    city: 'Applegate',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.0007,
    longitude: -120.9924,
    postal_code: '95703'
  },
  '95709': {
    country: 'US',
    city: 'Camino',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.747,
    longitude: -120.6743,
    postal_code: '95709'
  },
  '95712': {
    country: 'US',
    city: 'Chicago Park',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.143,
    longitude: -120.9666,
    postal_code: '95712'
  },
  '95713': {
    country: 'US',
    city: 'Colfax',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.0783,
    longitude: -120.9549,
    postal_code: '95713'
  },
  '95714': {
    country: 'US',
    city: 'Dutch Flat',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.1978,
    longitude: -120.8262,
    postal_code: '95714'
  },
  '95715': {
    country: 'US',
    city: 'Emigrant Gap',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.2968,
    longitude: -120.6727,
    postal_code: '95715'
  },
  '95717': {
    country: 'US',
    city: 'Gold Run',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.171,
    longitude: -120.8601,
    postal_code: '95717'
  },
  '95720': {
    country: 'US',
    city: 'Kyburz',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.7825,
    longitude: -120.2569,
    postal_code: '95720'
  },
  '95721': {
    country: 'US',
    city: 'Echo Lake',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.8338,
    longitude: -120.0416,
    postal_code: '95721'
  },
  '95722': {
    country: 'US',
    city: 'Meadow Vista',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.0031,
    longitude: -121.0292,
    postal_code: '95722'
  },
  '95724': {
    country: 'US',
    city: 'Norden',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.318,
    longitude: -120.356,
    postal_code: '95724'
  },
  '95726': {
    country: 'US',
    city: 'Pollock Pines',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.7708,
    longitude: -120.5427,
    postal_code: '95726'
  },
  '95728': {
    country: 'US',
    city: 'Soda Springs',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.317,
    longitude: -120.4259,
    postal_code: '95728'
  },
  '95735': {
    country: 'US',
    city: 'Twin Bridges',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.8092,
    longitude: -120.1242,
    postal_code: '95735'
  },
  '95736': {
    country: 'US',
    city: 'Weimar',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.0375,
    longitude: -120.9713,
    postal_code: '95736'
  },
  '95741': {
    country: 'US',
    city: 'Rancho Cordova',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5891,
    longitude: -121.3016,
    postal_code: '95741'
  },
  '95742': {
    country: 'US',
    city: 'Rancho Cordova',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5981,
    longitude: -121.2153,
    postal_code: '95742'
  },
  '95746': {
    country: 'US',
    city: 'Granite Bay',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.7435,
    longitude: -121.1897,
    postal_code: '95746'
  },
  '95747': {
    country: 'US',
    city: 'Roseville',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.7703,
    longitude: -121.3372,
    postal_code: '95747'
  },
  '95757': {
    country: 'US',
    city: 'Elk Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.4081,
    longitude: -121.4294,
    postal_code: '95757'
  },
  '95758': {
    country: 'US',
    city: 'Elk Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.4243,
    longitude: -121.437,
    postal_code: '95758'
  },
  '95759': {
    country: 'US',
    city: 'Elk Grove',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.407,
    longitude: -121.3752,
    postal_code: '95759'
  },
  '95762': {
    country: 'US',
    city: 'El Dorado Hills',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.685,
    longitude: -121.068,
    postal_code: '95762'
  },
  '95763': {
    country: 'US',
    city: 'Folsom',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.678,
    longitude: -121.175,
    postal_code: '95763'
  },
  '95765': {
    country: 'US',
    city: 'Rocklin',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 38.8136,
    longitude: -121.2677,
    postal_code: '95765'
  },
  '95776': {
    country: 'US',
    city: 'Woodland',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.6808,
    longitude: -121.7411,
    postal_code: '95776'
  },
  '95798': {
    country: 'US',
    city: 'West Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.5805,
    longitude: -121.5291,
    postal_code: '95798'
  },
  '95799': {
    country: 'US',
    city: 'West Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.5713,
    longitude: -121.5715,
    postal_code: '95799'
  },
  '95811': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5762,
    longitude: -121.488,
    postal_code: '95811'
  },
  '95812': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5822,
    longitude: -121.4943,
    postal_code: '95812'
  },
  '95813': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6026,
    longitude: -121.4475,
    postal_code: '95813'
  },
  '95814': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5804,
    longitude: -121.4922,
    postal_code: '95814'
  },
  '95815': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6093,
    longitude: -121.4443,
    postal_code: '95815'
  },
  '95816': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5728,
    longitude: -121.4675,
    postal_code: '95816'
  },
  '95817': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5498,
    longitude: -121.4583,
    postal_code: '95817'
  },
  '95818': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5568,
    longitude: -121.4929,
    postal_code: '95818'
  },
  '95819': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5683,
    longitude: -121.4366,
    postal_code: '95819'
  },
  '95820': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5347,
    longitude: -121.4451,
    postal_code: '95820'
  },
  '95821': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6239,
    longitude: -121.3837,
    postal_code: '95821'
  },
  '95822': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5091,
    longitude: -121.4935,
    postal_code: '95822'
  },
  '95823': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.4797,
    longitude: -121.4438,
    postal_code: '95823'
  },
  '95824': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5178,
    longitude: -121.4419,
    postal_code: '95824'
  },
  '95825': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5892,
    longitude: -121.4057,
    postal_code: '95825'
  },
  '95826': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5539,
    longitude: -121.3693,
    postal_code: '95826'
  },
  '95827': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5662,
    longitude: -121.3286,
    postal_code: '95827'
  },
  '95828': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.4826,
    longitude: -121.4006,
    postal_code: '95828'
  },
  '95829': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.4689,
    longitude: -121.344,
    postal_code: '95829'
  },
  '95830': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.4896,
    longitude: -121.2772,
    postal_code: '95830'
  },
  '95831': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.4962,
    longitude: -121.5297,
    postal_code: '95831'
  },
  '95832': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.4695,
    longitude: -121.4883,
    postal_code: '95832'
  },
  '95833': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6157,
    longitude: -121.5053,
    postal_code: '95833'
  },
  '95834': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6383,
    longitude: -121.5072,
    postal_code: '95834'
  },
  '95835': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6626,
    longitude: -121.4834,
    postal_code: '95835'
  },
  '95836': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.7198,
    longitude: -121.5343,
    postal_code: '95836'
  },
  '95837': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6817,
    longitude: -121.603,
    postal_code: '95837'
  },
  '95838': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6406,
    longitude: -121.444,
    postal_code: '95838'
  },
  '95840': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4933,
    postal_code: '95840'
  },
  '95841': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6627,
    longitude: -121.3406,
    postal_code: '95841'
  },
  '95842': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6865,
    longitude: -121.3494,
    postal_code: '95842'
  },
  '95843': {
    country: 'US',
    city: 'Antelope',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.7159,
    longitude: -121.3648,
    postal_code: '95843'
  },
  '95851': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6026,
    longitude: -121.4475,
    postal_code: '95851'
  },
  '95852': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6026,
    longitude: -121.4475,
    postal_code: '95852'
  },
  '95853': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6026,
    longitude: -121.4475,
    postal_code: '95853'
  },
  '95860': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.6105,
    longitude: -121.3799,
    postal_code: '95860'
  },
  '95864': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5878,
    longitude: -121.3769,
    postal_code: '95864'
  },
  '95865': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.596,
    longitude: -121.3978,
    postal_code: '95865'
  },
  '95866': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.596,
    longitude: -121.3978,
    postal_code: '95866'
  },
  '95867': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4933,
    postal_code: '95867'
  },
  '95894': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5816,
    longitude: -121.4933,
    postal_code: '95894'
  },
  '95899': {
    country: 'US',
    city: 'Sacramento',
    state: 'California',
    state_short: 'CA',
    county: 'Sacramento',
    latitude: 38.5383,
    longitude: -121.5549,
    postal_code: '95899'
  },
  '95901': {
    country: 'US',
    city: 'Marysville',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.1663,
    longitude: -121.5105,
    postal_code: '95901'
  },
  '95903': {
    country: 'US',
    city: 'Beale Afb',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.1115,
    longitude: -121.3604,
    postal_code: '95903'
  },
  '95910': {
    country: 'US',
    city: 'Alleghany',
    state: 'California',
    state_short: 'CA',
    county: 'Sierra',
    latitude: 39.4826,
    longitude: -120.8483,
    postal_code: '95910'
  },
  '95912': {
    country: 'US',
    city: 'Arbuckle',
    state: 'California',
    state_short: 'CA',
    county: 'Colusa',
    latitude: 39.0138,
    longitude: -122.0274,
    postal_code: '95912'
  },
  '95913': {
    country: 'US',
    city: 'Artois',
    state: 'California',
    state_short: 'CA',
    county: 'Glenn',
    latitude: 39.6197,
    longitude: -122.1927,
    postal_code: '95913'
  },
  '95914': {
    country: 'US',
    city: 'Bangor',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.3885,
    longitude: -121.4052,
    postal_code: '95914'
  },
  '95915': {
    country: 'US',
    city: 'Belden',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 40.006,
    longitude: -121.2491,
    postal_code: '95915'
  },
  '95916': {
    country: 'US',
    city: 'Berry Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.6776,
    longitude: -121.3689,
    postal_code: '95916'
  },
  '95917': {
    country: 'US',
    city: 'Biggs',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.4162,
    longitude: -121.7189,
    postal_code: '95917'
  },
  '95918': {
    country: 'US',
    city: 'Browns Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.2882,
    longitude: -121.3303,
    postal_code: '95918'
  },
  '95919': {
    country: 'US',
    city: 'Brownsville',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.4525,
    longitude: -121.2612,
    postal_code: '95919'
  },
  '95920': {
    country: 'US',
    city: 'Butte City',
    state: 'California',
    state_short: 'CA',
    county: 'Glenn',
    latitude: 39.4568,
    longitude: -121.9515,
    postal_code: '95920'
  },
  '95922': {
    country: 'US',
    city: 'Camptonville',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.4518,
    longitude: -121.0486,
    postal_code: '95922'
  },
  '95923': {
    country: 'US',
    city: 'Canyon Dam',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 39.5645,
    longitude: -121.5751,
    postal_code: '95923'
  },
  '95924': {
    country: 'US',
    city: 'Cedar Ridge',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.1988,
    longitude: -121.02,
    postal_code: '95924'
  },
  '95925': {
    country: 'US',
    city: 'Challenge',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.4685,
    longitude: -121.1936,
    postal_code: '95925'
  },
  '95926': {
    country: 'US',
    city: 'Chico',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.7458,
    longitude: -121.8444,
    postal_code: '95926'
  },
  '95927': {
    country: 'US',
    city: 'Chico',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.8117,
    longitude: -121.9398,
    postal_code: '95927'
  },
  '95928': {
    country: 'US',
    city: 'Chico',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.7224,
    longitude: -121.8113,
    postal_code: '95928'
  },
  '95929': {
    country: 'US',
    city: 'Chico',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.7301,
    longitude: -121.8414,
    postal_code: '95929'
  },
  '95930': {
    country: 'US',
    city: 'Clipper Mills',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.5327,
    longitude: -121.1575,
    postal_code: '95930'
  },
  '95932': {
    country: 'US',
    city: 'Colusa',
    state: 'California',
    state_short: 'CA',
    county: 'Colusa',
    latitude: 39.2345,
    longitude: -122.0277,
    postal_code: '95932'
  },
  '95934': {
    country: 'US',
    city: 'Crescent Mills',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 40.0673,
    longitude: -120.9248,
    postal_code: '95934'
  },
  '95935': {
    country: 'US',
    city: 'Dobbins',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.3662,
    longitude: -121.2256,
    postal_code: '95935'
  },
  '95936': {
    country: 'US',
    city: 'Downieville',
    state: 'California',
    state_short: 'CA',
    county: 'Sierra',
    latitude: 39.569,
    longitude: -120.8344,
    postal_code: '95936'
  },
  '95937': {
    country: 'US',
    city: 'Dunnigan',
    state: 'California',
    state_short: 'CA',
    county: 'Yolo',
    latitude: 38.887,
    longitude: -121.9992,
    postal_code: '95937'
  },
  '95938': {
    country: 'US',
    city: 'Durham',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.633,
    longitude: -121.7886,
    postal_code: '95938'
  },
  '95939': {
    country: 'US',
    city: 'Elk Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Glenn',
    latitude: 39.5306,
    longitude: -122.6124,
    postal_code: '95939'
  },
  '95940': {
    country: 'US',
    city: 'Feather Falls',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.622,
    longitude: -121.2669,
    postal_code: '95940'
  },
  '95941': {
    country: 'US',
    city: 'Forbestown',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.521,
    longitude: -121.2423,
    postal_code: '95941'
  },
  '95942': {
    country: 'US',
    city: 'Forest Ranch',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.8821,
    longitude: -121.6728,
    postal_code: '95942'
  },
  '95943': {
    country: 'US',
    city: 'Glenn',
    state: 'California',
    state_short: 'CA',
    county: 'Glenn',
    latitude: 39.6069,
    longitude: -122.0384,
    postal_code: '95943'
  },
  '95944': {
    country: 'US',
    city: 'Goodyears Bar',
    state: 'California',
    state_short: 'CA',
    county: 'Sierra',
    latitude: 39.5399,
    longitude: -120.8844,
    postal_code: '95944'
  },
  '95945': {
    country: 'US',
    city: 'Grass Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.2081,
    longitude: -121.0069,
    postal_code: '95945'
  },
  '95946': {
    country: 'US',
    city: 'Penn Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.2019,
    longitude: -121.2026,
    postal_code: '95946'
  },
  '95947': {
    country: 'US',
    city: 'Greenville',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 40.1699,
    longitude: -120.9014,
    postal_code: '95947'
  },
  '95948': {
    country: 'US',
    city: 'Gridley',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.3532,
    longitude: -121.7137,
    postal_code: '95948'
  },
  '95949': {
    country: 'US',
    city: 'Grass Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.1193,
    longitude: -121.0938,
    postal_code: '95949'
  },
  '95950': {
    country: 'US',
    city: 'Grimes',
    state: 'California',
    state_short: 'CA',
    county: 'Colusa',
    latitude: 39.0744,
    longitude: -121.8927,
    postal_code: '95950'
  },
  '95951': {
    country: 'US',
    city: 'Hamilton City',
    state: 'California',
    state_short: 'CA',
    county: 'Glenn',
    latitude: 39.7386,
    longitude: -122.0085,
    postal_code: '95951'
  },
  '95953': {
    country: 'US',
    city: 'Live Oak',
    state: 'California',
    state_short: 'CA',
    county: 'Sutter',
    latitude: 39.2601,
    longitude: -121.6923,
    postal_code: '95953'
  },
  '95954': {
    country: 'US',
    city: 'Magalia',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.8912,
    longitude: -121.58,
    postal_code: '95954'
  },
  '95955': {
    country: 'US',
    city: 'Maxwell',
    state: 'California',
    state_short: 'CA',
    county: 'Colusa',
    latitude: 39.3163,
    longitude: -122.1849,
    postal_code: '95955'
  },
  '95956': {
    country: 'US',
    city: 'Meadow Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 39.9296,
    longitude: -121.0608,
    postal_code: '95956'
  },
  '95957': {
    country: 'US',
    city: 'Meridian',
    state: 'California',
    state_short: 'CA',
    county: 'Sutter',
    latitude: 39.052,
    longitude: -121.8061,
    postal_code: '95957'
  },
  '95958': {
    country: 'US',
    city: 'Nelson',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.5522,
    longitude: -121.7644,
    postal_code: '95958'
  },
  '95959': {
    country: 'US',
    city: 'Nevada City',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.3017,
    longitude: -120.9717,
    postal_code: '95959'
  },
  '95960': {
    country: 'US',
    city: 'North San Juan',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.3765,
    longitude: -121.0891,
    postal_code: '95960'
  },
  '95961': {
    country: 'US',
    city: 'Olivehurst',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.0861,
    longitude: -121.5497,
    postal_code: '95961'
  },
  '95962': {
    country: 'US',
    city: 'Oregon House',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.3459,
    longitude: -121.2663,
    postal_code: '95962'
  },
  '95963': {
    country: 'US',
    city: 'Orland',
    state: 'California',
    state_short: 'CA',
    county: 'Glenn',
    latitude: 39.7314,
    longitude: -122.2534,
    postal_code: '95963'
  },
  '95965': {
    country: 'US',
    city: 'Oroville',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.6054,
    longitude: -121.5751,
    postal_code: '95965'
  },
  '95966': {
    country: 'US',
    city: 'Oroville',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.4877,
    longitude: -121.4698,
    postal_code: '95966'
  },
  '95967': {
    country: 'US',
    city: 'Paradise',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.7155,
    longitude: -121.6551,
    postal_code: '95967'
  },
  '95968': {
    country: 'US',
    city: 'Palermo',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.4361,
    longitude: -121.5454,
    postal_code: '95968'
  },
  '95969': {
    country: 'US',
    city: 'Paradise',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.7555,
    longitude: -121.6069,
    postal_code: '95969'
  },
  '95970': {
    country: 'US',
    city: 'Princeton',
    state: 'California',
    state_short: 'CA',
    county: 'Colusa',
    latitude: 39.4168,
    longitude: -122.0519,
    postal_code: '95970'
  },
  '95971': {
    country: 'US',
    city: 'Quincy',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 39.9284,
    longitude: -120.9698,
    postal_code: '95971'
  },
  '95972': {
    country: 'US',
    city: 'Rackerby',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.4256,
    longitude: -121.3252,
    postal_code: '95972'
  },
  '95973': {
    country: 'US',
    city: 'Chico',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.8032,
    longitude: -121.8673,
    postal_code: '95973'
  },
  '95974': {
    country: 'US',
    city: 'Richvale',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.4959,
    longitude: -121.748,
    postal_code: '95974'
  },
  '95975': {
    country: 'US',
    city: 'Rough And Ready',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.2286,
    longitude: -121.1509,
    postal_code: '95975'
  },
  '95976': {
    country: 'US',
    city: 'Chico',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.7346,
    longitude: -121.8331,
    postal_code: '95976'
  },
  '95977': {
    country: 'US',
    city: 'Smartsville',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.2076,
    longitude: -121.3001,
    postal_code: '95977'
  },
  '95978': {
    country: 'US',
    city: 'Stirling City',
    state: 'California',
    state_short: 'CA',
    county: 'Butte',
    latitude: 39.9077,
    longitude: -121.5269,
    postal_code: '95978'
  },
  '95979': {
    country: 'US',
    city: 'Stonyford',
    state: 'California',
    state_short: 'CA',
    county: 'Colusa',
    latitude: 39.3176,
    longitude: -122.5393,
    postal_code: '95979'
  },
  '95980': {
    country: 'US',
    city: 'Storrie',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 39.9175,
    longitude: -121.3222,
    postal_code: '95980'
  },
  '95981': {
    country: 'US',
    city: 'Strawberry Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Yuba',
    latitude: 39.687,
    longitude: -121.045,
    postal_code: '95981'
  },
  '95982': {
    country: 'US',
    city: 'Sutter',
    state: 'California',
    state_short: 'CA',
    county: 'Sutter',
    latitude: 39.1779,
    longitude: -121.7758,
    postal_code: '95982'
  },
  '95983': {
    country: 'US',
    city: 'Taylorsville',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 40.0357,
    longitude: -120.7353,
    postal_code: '95983'
  },
  '95984': {
    country: 'US',
    city: 'Twain',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 40.0509,
    longitude: -121.1268,
    postal_code: '95984'
  },
  '95986': {
    country: 'US',
    city: 'Washington',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.3569,
    longitude: -120.8,
    postal_code: '95986'
  },
  '95987': {
    country: 'US',
    city: 'Williams',
    state: 'California',
    state_short: 'CA',
    county: 'Colusa',
    latitude: 39.1337,
    longitude: -122.2162,
    postal_code: '95987'
  },
  '95988': {
    country: 'US',
    city: 'Willows',
    state: 'California',
    state_short: 'CA',
    county: 'Glenn',
    latitude: 39.5353,
    longitude: -122.2597,
    postal_code: '95988'
  },
  '95991': {
    country: 'US',
    city: 'Yuba City',
    state: 'California',
    state_short: 'CA',
    county: 'Sutter',
    latitude: 39.1051,
    longitude: -121.6202,
    postal_code: '95991'
  },
  '95992': {
    country: 'US',
    city: 'Yuba City',
    state: 'California',
    state_short: 'CA',
    county: 'Sutter',
    latitude: 39.023,
    longitude: -121.6116,
    postal_code: '95992'
  },
  '95993': {
    country: 'US',
    city: 'Yuba City',
    state: 'California',
    state_short: 'CA',
    county: 'Sutter',
    latitude: 39.1237,
    longitude: -121.6611,
    postal_code: '95993'
  },
  '96001': {
    country: 'US',
    city: 'Redding',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.5605,
    longitude: -122.4116,
    postal_code: '96001'
  },
  '96002': {
    country: 'US',
    city: 'Redding',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.5486,
    longitude: -122.3339,
    postal_code: '96002'
  },
  '96003': {
    country: 'US',
    city: 'Redding',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.6278,
    longitude: -122.353,
    postal_code: '96003'
  },
  '96006': {
    country: 'US',
    city: 'Adin',
    state: 'California',
    state_short: 'CA',
    county: 'Modoc',
    latitude: 41.2175,
    longitude: -120.9432,
    postal_code: '96006'
  },
  '96007': {
    country: 'US',
    city: 'Anderson',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.4574,
    longitude: -122.3282,
    postal_code: '96007'
  },
  '96008': {
    country: 'US',
    city: 'Bella Vista',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.7409,
    longitude: -122.0725,
    postal_code: '96008'
  },
  '96009': {
    country: 'US',
    city: 'Bieber',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 41.1315,
    longitude: -121.1286,
    postal_code: '96009'
  },
  '96010': {
    country: 'US',
    city: 'Big Bar',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.748,
    longitude: -123.229,
    postal_code: '96010'
  },
  '96011': {
    country: 'US',
    city: 'Big Bend',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.9749,
    longitude: -121.825,
    postal_code: '96011'
  },
  '96013': {
    country: 'US',
    city: 'Burney',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.8949,
    longitude: -121.655,
    postal_code: '96013'
  },
  '96014': {
    country: 'US',
    city: 'Callahan',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.3833,
    longitude: -122.764,
    postal_code: '96014'
  },
  '96015': {
    country: 'US',
    city: 'Canby',
    state: 'California',
    state_short: 'CA',
    county: 'Modoc',
    latitude: 41.4664,
    longitude: -120.9218,
    postal_code: '96015'
  },
  '96016': {
    country: 'US',
    city: 'Cassel',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.9337,
    longitude: -121.5677,
    postal_code: '96016'
  },
  '96017': {
    country: 'US',
    city: 'Castella',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 41.1103,
    longitude: -122.3161,
    postal_code: '96017'
  },
  '96019': {
    country: 'US',
    city: 'Shasta Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.6969,
    longitude: -122.3683,
    postal_code: '96019'
  },
  '96020': {
    country: 'US',
    city: 'Chester',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 40.2975,
    longitude: -121.2273,
    postal_code: '96020'
  },
  '96021': {
    country: 'US',
    city: 'Corning',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 39.9296,
    longitude: -122.196,
    postal_code: '96021'
  },
  '96022': {
    country: 'US',
    city: 'Cottonwood',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.3691,
    longitude: -122.3375,
    postal_code: '96022'
  },
  '96023': {
    country: 'US',
    city: 'Dorris',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.9194,
    longitude: -121.9739,
    postal_code: '96023'
  },
  '96024': {
    country: 'US',
    city: 'Douglas City',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.6342,
    longitude: -122.9239,
    postal_code: '96024'
  },
  '96025': {
    country: 'US',
    city: 'Dunsmuir',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.2124,
    longitude: -122.2734,
    postal_code: '96025'
  },
  '96027': {
    country: 'US',
    city: 'Etna',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.4463,
    longitude: -123.01,
    postal_code: '96027'
  },
  '96028': {
    country: 'US',
    city: 'Fall River Mills',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 41.0393,
    longitude: -121.4606,
    postal_code: '96028'
  },
  '96029': {
    country: 'US',
    city: 'Flournoy',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 39.9024,
    longitude: -122.4918,
    postal_code: '96029'
  },
  '96031': {
    country: 'US',
    city: 'Forks Of Salmon',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.2191,
    longitude: -123.2363,
    postal_code: '96031'
  },
  '96032': {
    country: 'US',
    city: 'Fort Jones',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.617,
    longitude: -122.8832,
    postal_code: '96032'
  },
  '96033': {
    country: 'US',
    city: 'French Gulch',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.7035,
    longitude: -122.6229,
    postal_code: '96033'
  },
  '96034': {
    country: 'US',
    city: 'Gazelle',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.5105,
    longitude: -122.5371,
    postal_code: '96034'
  },
  '96035': {
    country: 'US',
    city: 'Gerber',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 40.043,
    longitude: -122.1649,
    postal_code: '96035'
  },
  '96037': {
    country: 'US',
    city: 'Greenview',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.5403,
    longitude: -122.9366,
    postal_code: '96037'
  },
  '96038': {
    country: 'US',
    city: 'Grenada',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.6125,
    longitude: -122.5258,
    postal_code: '96038'
  },
  '96039': {
    country: 'US',
    city: 'Happy Camp',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.8018,
    longitude: -123.388,
    postal_code: '96039'
  },
  '96040': {
    country: 'US',
    city: 'Hat Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.7677,
    longitude: -121.4637,
    postal_code: '96040'
  },
  '96041': {
    country: 'US',
    city: 'Hayfork',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.5504,
    longitude: -123.1634,
    postal_code: '96041'
  },
  '96044': {
    country: 'US',
    city: 'Hornbrook',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.9077,
    longitude: -122.5265,
    postal_code: '96044'
  },
  '96046': {
    country: 'US',
    city: 'Hyampom',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.6137,
    longitude: -123.4488,
    postal_code: '96046'
  },
  '96047': {
    country: 'US',
    city: 'Igo',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.4318,
    longitude: -122.654,
    postal_code: '96047'
  },
  '96048': {
    country: 'US',
    city: 'Junction City',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.7411,
    longitude: -123.0718,
    postal_code: '96048'
  },
  '96049': {
    country: 'US',
    city: 'Redding',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.7098,
    longitude: -122.3116,
    postal_code: '96049'
  },
  '96050': {
    country: 'US',
    city: 'Klamath River',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.8637,
    longitude: -122.8197,
    postal_code: '96050'
  },
  '96051': {
    country: 'US',
    city: 'Lakehead',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.9105,
    longitude: -122.4106,
    postal_code: '96051'
  },
  '96052': {
    country: 'US',
    city: 'Lewiston',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.746,
    longitude: -122.8426,
    postal_code: '96052'
  },
  '96054': {
    country: 'US',
    city: 'Lookout',
    state: 'California',
    state_short: 'CA',
    county: 'Modoc',
    latitude: 41.2347,
    longitude: -121.2156,
    postal_code: '96054'
  },
  '96055': {
    country: 'US',
    city: 'Los Molinos',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 40.0497,
    longitude: -122.0992,
    postal_code: '96055'
  },
  '96056': {
    country: 'US',
    city: 'Mcarthur',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 41.0502,
    longitude: -121.3991,
    postal_code: '96056'
  },
  '96057': {
    country: 'US',
    city: 'Mccloud',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.2475,
    longitude: -122.1128,
    postal_code: '96057'
  },
  '96058': {
    country: 'US',
    city: 'Macdoel',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.883,
    longitude: -121.9445,
    postal_code: '96058'
  },
  '96059': {
    country: 'US',
    city: 'Manton',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 40.4331,
    longitude: -121.8365,
    postal_code: '96059'
  },
  '96061': {
    country: 'US',
    city: 'Mill Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 40.3263,
    longitude: -121.5228,
    postal_code: '96061'
  },
  '96062': {
    country: 'US',
    city: 'Millville',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.5653,
    longitude: -122.1111,
    postal_code: '96062'
  },
  '96063': {
    country: 'US',
    city: 'Mineral',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 40.3564,
    longitude: -121.571,
    postal_code: '96063'
  },
  '96064': {
    country: 'US',
    city: 'Montague',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.7243,
    longitude: -122.4638,
    postal_code: '96064'
  },
  '96065': {
    country: 'US',
    city: 'Montgomery Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.9124,
    longitude: -121.9233,
    postal_code: '96065'
  },
  '96067': {
    country: 'US',
    city: 'Mount Shasta',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.3174,
    longitude: -122.324,
    postal_code: '96067'
  },
  '96068': {
    country: 'US',
    city: 'Nubieber',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 41.0957,
    longitude: -121.183,
    postal_code: '96068'
  },
  '96069': {
    country: 'US',
    city: 'Oak Run',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.6863,
    longitude: -122.0409,
    postal_code: '96069'
  },
  '96070': {
    country: 'US',
    city: 'Obrien',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.7352,
    longitude: -122.1944,
    postal_code: '96070'
  },
  '96071': {
    country: 'US',
    city: 'Old Station',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.6256,
    longitude: -121.4585,
    postal_code: '96071'
  },
  '96073': {
    country: 'US',
    city: 'Palo Cedro',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.5767,
    longitude: -122.2398,
    postal_code: '96073'
  },
  '96074': {
    country: 'US',
    city: 'Paskenta',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 39.8772,
    longitude: -122.5814,
    postal_code: '96074'
  },
  '96075': {
    country: 'US',
    city: 'Paynes Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 40.3514,
    longitude: -121.765,
    postal_code: '96075'
  },
  '96076': {
    country: 'US',
    city: 'Platina',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.376,
    longitude: -122.937,
    postal_code: '96076'
  },
  '96078': {
    country: 'US',
    city: 'Proberta',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 40.0815,
    longitude: -122.1705,
    postal_code: '96078'
  },
  '96079': {
    country: 'US',
    city: 'Shasta Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.6866,
    longitude: -122.3348,
    postal_code: '96079'
  },
  '96080': {
    country: 'US',
    city: 'Red Bluff',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 40.1795,
    longitude: -122.2383,
    postal_code: '96080'
  },
  '96084': {
    country: 'US',
    city: 'Round Mountain',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.794,
    longitude: -121.9419,
    postal_code: '96084'
  },
  '96085': {
    country: 'US',
    city: 'Scott Bar',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.7736,
    longitude: -122.9882,
    postal_code: '96085'
  },
  '96086': {
    country: 'US',
    city: 'Seiad Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.8866,
    longitude: -123.2438,
    postal_code: '96086'
  },
  '96087': {
    country: 'US',
    city: 'Shasta',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.6109,
    longitude: -122.4968,
    postal_code: '96087'
  },
  '96088': {
    country: 'US',
    city: 'Shingletown',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.505,
    longitude: -121.8857,
    postal_code: '96088'
  },
  '96089': {
    country: 'US',
    city: 'Shasta Lake',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.6579,
    longitude: -122.4273,
    postal_code: '96089'
  },
  '96090': {
    country: 'US',
    city: 'Tehama',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 40.0271,
    longitude: -122.1233,
    postal_code: '96090'
  },
  '96091': {
    country: 'US',
    city: 'Trinity Center',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 41.0615,
    longitude: -122.7239,
    postal_code: '96091'
  },
  '96092': {
    country: 'US',
    city: 'Vina',
    state: 'California',
    state_short: 'CA',
    county: 'Tehama',
    latitude: 39.9272,
    longitude: -122.025,
    postal_code: '96092'
  },
  '96093': {
    country: 'US',
    city: 'Weaverville',
    state: 'California',
    state_short: 'CA',
    county: 'Trinity',
    latitude: 40.7317,
    longitude: -122.9353,
    postal_code: '96093'
  },
  '96094': {
    country: 'US',
    city: 'Weed',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.4226,
    longitude: -122.3861,
    postal_code: '96094'
  },
  '96095': {
    country: 'US',
    city: 'Whiskeytown',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.6388,
    longitude: -122.5597,
    postal_code: '96095'
  },
  '96096': {
    country: 'US',
    city: 'Whitmore',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.6525,
    longitude: -121.8771,
    postal_code: '96096'
  },
  '96097': {
    country: 'US',
    city: 'Yreka',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.7206,
    longitude: -122.6376,
    postal_code: '96097'
  },
  '96099': {
    country: 'US',
    city: 'Redding',
    state: 'California',
    state_short: 'CA',
    county: 'Shasta',
    latitude: 40.7043,
    longitude: -122.3878,
    postal_code: '96099'
  },
  '96101': {
    country: 'US',
    city: 'Alturas',
    state: 'California',
    state_short: 'CA',
    county: 'Modoc',
    latitude: 41.4767,
    longitude: -120.5456,
    postal_code: '96101'
  },
  '96103': {
    country: 'US',
    city: 'Blairsden Graeagle',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 40.1919,
    longitude: -120.1746,
    postal_code: '96103'
  },
  '96104': {
    country: 'US',
    city: 'Cedarville',
    state: 'California',
    state_short: 'CA',
    county: 'Modoc',
    latitude: 41.4759,
    longitude: -120.1516,
    postal_code: '96104'
  },
  '96105': {
    country: 'US',
    city: 'Chilcoot',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 39.7977,
    longitude: -120.1396,
    postal_code: '96105'
  },
  '96106': {
    country: 'US',
    city: 'Clio',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 39.7546,
    longitude: -120.5951,
    postal_code: '96106'
  },
  '96107': {
    country: 'US',
    city: 'Coleville',
    state: 'California',
    state_short: 'CA',
    county: 'Mono',
    latitude: 38.5029,
    longitude: -119.4828,
    postal_code: '96107'
  },
  '96108': {
    country: 'US',
    city: 'Davis Creek',
    state: 'California',
    state_short: 'CA',
    county: 'Modoc',
    latitude: 41.7336,
    longitude: -120.3741,
    postal_code: '96108'
  },
  '96109': {
    country: 'US',
    city: 'Doyle',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.0008,
    longitude: -120.1077,
    postal_code: '96109'
  },
  '96110': {
    country: 'US',
    city: 'Eagleville',
    state: 'California',
    state_short: 'CA',
    county: 'Modoc',
    latitude: 41.3163,
    longitude: -120.1158,
    postal_code: '96110'
  },
  '96111': {
    country: 'US',
    city: 'Floriston',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.3928,
    longitude: -120.0212,
    postal_code: '96111'
  },
  '96112': {
    country: 'US',
    city: 'Fort Bidwell',
    state: 'California',
    state_short: 'CA',
    county: 'Modoc',
    latitude: 41.8644,
    longitude: -120.162,
    postal_code: '96112'
  },
  '96113': {
    country: 'US',
    city: 'Herlong',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.1485,
    longitude: -120.1713,
    postal_code: '96113'
  },
  '96114': {
    country: 'US',
    city: 'Janesville',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.2963,
    longitude: -120.5098,
    postal_code: '96114'
  },
  '96115': {
    country: 'US',
    city: 'Lake City',
    state: 'California',
    state_short: 'CA',
    county: 'Modoc',
    latitude: 41.6682,
    longitude: -120.1814,
    postal_code: '96115'
  },
  '96116': {
    country: 'US',
    city: 'Likely',
    state: 'California',
    state_short: 'CA',
    county: 'Modoc',
    latitude: 41.2329,
    longitude: -120.5079,
    postal_code: '96116'
  },
  '96117': {
    country: 'US',
    city: 'Litchfield',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.4073,
    longitude: -120.4092,
    postal_code: '96117'
  },
  '96118': {
    country: 'US',
    city: 'Loyalton',
    state: 'California',
    state_short: 'CA',
    county: 'Sierra',
    latitude: 39.663,
    longitude: -120.2297,
    postal_code: '96118'
  },
  '96119': {
    country: 'US',
    city: 'Madeline',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.9766,
    longitude: -120.5548,
    postal_code: '96119'
  },
  '96120': {
    country: 'US',
    city: 'Markleeville',
    state: 'California',
    state_short: 'CA',
    county: 'Alpine',
    latitude: 38.7713,
    longitude: -119.8327,
    postal_code: '96120'
  },
  '96121': {
    country: 'US',
    city: 'Milford',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.1828,
    longitude: -120.3895,
    postal_code: '96121'
  },
  '96122': {
    country: 'US',
    city: 'Portola',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 39.8105,
    longitude: -120.4691,
    postal_code: '96122'
  },
  '96123': {
    country: 'US',
    city: 'Ravendale',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.7985,
    longitude: -120.3652,
    postal_code: '96123'
  },
  '96124': {
    country: 'US',
    city: 'Calpine',
    state: 'California',
    state_short: 'CA',
    county: 'Sierra',
    latitude: 39.6139,
    longitude: -120.4046,
    postal_code: '96124'
  },
  '96125': {
    country: 'US',
    city: 'Sierra City',
    state: 'California',
    state_short: 'CA',
    county: 'Sierra',
    latitude: 39.5936,
    longitude: -120.6269,
    postal_code: '96125'
  },
  '96126': {
    country: 'US',
    city: 'Sierraville',
    state: 'California',
    state_short: 'CA',
    county: 'Sierra',
    latitude: 39.5825,
    longitude: -120.3711,
    postal_code: '96126'
  },
  '96127': {
    country: 'US',
    city: 'Susanville',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.4163,
    longitude: -120.653,
    postal_code: '96127'
  },
  '96128': {
    country: 'US',
    city: 'Standish',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.3509,
    longitude: -120.4068,
    postal_code: '96128'
  },
  '96129': {
    country: 'US',
    city: 'Beckwourth',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 39.7721,
    longitude: -120.4051,
    postal_code: '96129'
  },
  '96130': {
    country: 'US',
    city: 'Susanville',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.3983,
    longitude: -120.6464,
    postal_code: '96130'
  },
  '96132': {
    country: 'US',
    city: 'Termo',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.9509,
    longitude: -120.613,
    postal_code: '96132'
  },
  '96133': {
    country: 'US',
    city: 'Topaz',
    state: 'California',
    state_short: 'CA',
    county: 'Mono',
    latitude: 38.6415,
    longitude: -119.5122,
    postal_code: '96133'
  },
  '96134': {
    country: 'US',
    city: 'Tulelake',
    state: 'California',
    state_short: 'CA',
    county: 'Siskiyou',
    latitude: 41.9316,
    longitude: -121.4347,
    postal_code: '96134'
  },
  '96135': {
    country: 'US',
    city: 'Vinton',
    state: 'California',
    state_short: 'CA',
    county: 'Plumas',
    latitude: 39.8043,
    longitude: -120.1783,
    postal_code: '96135'
  },
  '96136': {
    country: 'US',
    city: 'Wendel',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.3406,
    longitude: -120.2824,
    postal_code: '96136'
  },
  '96137': {
    country: 'US',
    city: 'Westwood',
    state: 'California',
    state_short: 'CA',
    county: 'Lassen',
    latitude: 40.3038,
    longitude: -121.0226,
    postal_code: '96137'
  },
  '96140': {
    country: 'US',
    city: 'Carnelian Bay',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.2319,
    longitude: -120.0753,
    postal_code: '96140'
  },
  '96141': {
    country: 'US',
    city: 'Homewood',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.0786,
    longitude: -120.1734,
    postal_code: '96141'
  },
  '96142': {
    country: 'US',
    city: 'Tahoma',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 39.0644,
    longitude: -120.1357,
    postal_code: '96142'
  },
  '96143': {
    country: 'US',
    city: 'Kings Beach',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.2401,
    longitude: -120.0233,
    postal_code: '96143'
  },
  '96145': {
    country: 'US',
    city: 'Tahoe City',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.1806,
    longitude: -120.1445,
    postal_code: '96145'
  },
  '96146': {
    country: 'US',
    city: 'Olympic Valley',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.1752,
    longitude: -120.1954,
    postal_code: '96146'
  },
  '96148': {
    country: 'US',
    city: 'Tahoe Vista',
    state: 'California',
    state_short: 'CA',
    county: 'Placer',
    latitude: 39.2448,
    longitude: -120.0521,
    postal_code: '96148'
  },
  '96150': {
    country: 'US',
    city: 'South Lake Tahoe',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.917,
    longitude: -119.9865,
    postal_code: '96150'
  },
  '96151': {
    country: 'US',
    city: 'South Lake Tahoe',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.9039,
    longitude: -119.995,
    postal_code: '96151'
  },
  '96152': {
    country: 'US',
    city: 'South Lake Tahoe',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.9271,
    longitude: -119.999,
    postal_code: '96152'
  },
  '96154': {
    country: 'US',
    city: 'South Lake Tahoe',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.8753,
    longitude: -120.0188,
    postal_code: '96154'
  },
  '96155': {
    country: 'US',
    city: 'South Lake Tahoe',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.8449,
    longitude: -120.043,
    postal_code: '96155'
  },
  '96156': {
    country: 'US',
    city: 'South Lake Tahoe',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.9352,
    longitude: -119.9676,
    postal_code: '96156'
  },
  '96157': {
    country: 'US',
    city: 'South Lake Tahoe',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.9344,
    longitude: -119.9767,
    postal_code: '96157'
  },
  '96158': {
    country: 'US',
    city: 'South Lake Tahoe',
    state: 'California',
    state_short: 'CA',
    county: 'El Dorado',
    latitude: 38.8981,
    longitude: -119.9984,
    postal_code: '96158'
  },
  '96160': {
    country: 'US',
    city: 'Truckee',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.328,
    longitude: -120.1833,
    postal_code: '96160'
  },
  '96161': {
    country: 'US',
    city: 'Truckee',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.3385,
    longitude: -120.1729,
    postal_code: '96161'
  },
  '96162': {
    country: 'US',
    city: 'Truckee',
    state: 'California',
    state_short: 'CA',
    county: 'Nevada',
    latitude: 39.328,
    longitude: -120.1833,
    postal_code: '96162'
  },
  '96201': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 34.7167,
    longitude: 128.5833,
    postal_code: '96201'
  },
  '96202': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 34.7167,
    longitude: 128.5833,
    postal_code: '96202'
  },
  '96203': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.5339,
    longitude: 126.9775,
    postal_code: '96203'
  },
  '96204': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.5339,
    longitude: 126.9775,
    postal_code: '96204'
  },
  '96205': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.5339,
    longitude: 126.9775,
    postal_code: '96205'
  },
  '96206': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.5339,
    longitude: 126.9775,
    postal_code: '96206'
  },
  '96207': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.5339,
    longitude: 126.9775,
    postal_code: '96207'
  },
  '96208': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.8747,
    longitude: 127.7342,
    postal_code: '96208'
  },
  '96212': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.8703,
    longitude: 128.5911,
    postal_code: '96212'
  },
  '96213': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.8703,
    longitude: 128.5911,
    postal_code: '96213'
  },
  '96214': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.2342,
    longitude: 128.8811,
    postal_code: '96214'
  },
  '96216': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 34.78,
    longitude: 127.0615,
    postal_code: '96216'
  },
  '96218': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.8703,
    longitude: 128.5911,
    postal_code: '96218'
  },
  '96220': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 33.4017,
    longitude: 126.5461,
    postal_code: '96220'
  },
  '96224': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.9211,
    longitude: 127.0626,
    postal_code: '96224'
  },
  '96231': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 36.3214,
    longitude: 127.4197,
    postal_code: '96231'
  },
  '96236': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 33.6,
    longitude: 130.4167,
    postal_code: '96236'
  },
  '96257': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.754,
    longitude: 127.0255,
    postal_code: '96257'
  },
  '96258': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.754,
    longitude: 127.0255,
    postal_code: '96258'
  },
  '96259': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.1028,
    longitude: 129.0403,
    postal_code: '96259'
  },
  '96260': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.9925,
    longitude: 128.3979,
    postal_code: '96260'
  },
  '96261': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.2911,
    longitude: 127.0089,
    postal_code: '96261'
  },
  '96264': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.9786,
    longitude: 126.7114,
    postal_code: '96264'
  },
  '96266': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.1522,
    longitude: 127.0706,
    postal_code: '96266'
  },
  '96267': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.1522,
    longitude: 127.0706,
    postal_code: '96267'
  },
  '96269': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.0642,
    longitude: 128.7653,
    postal_code: '96269'
  },
  '96271': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 36.9622,
    longitude: 127.0311,
    postal_code: '96271'
  },
  '96272': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 36.9622,
    longitude: 127.0311,
    postal_code: '96272'
  },
  '96276': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.566,
    longitude: 126.9784,
    postal_code: '96276'
  },
  '96278': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.0906,
    longitude: 127.0296,
    postal_code: '96278'
  },
  '96283': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 34.9586,
    longitude: 126.9728,
    postal_code: '96283'
  },
  '96284': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 34.9586,
    longitude: 126.9728,
    postal_code: '96284'
  },
  '96306': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.4427,
    longitude: 139.3693,
    postal_code: '96306'
  },
  '96310': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 34.163,
    longitude: 132.22,
    postal_code: '96310'
  },
  '96313': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.4919,
    longitude: 139.475,
    postal_code: '96313'
  },
  '96318': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.6868,
    longitude: 141.3897,
    postal_code: '96318'
  },
  '96319': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.6868,
    longitude: 141.3897,
    postal_code: '96319'
  },
  '96321': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 33.1592,
    longitude: 129.7228,
    postal_code: '96321'
  },
  '96322': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 33.1592,
    longitude: 129.7228,
    postal_code: '96322'
  },
  '96323': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.7485,
    longitude: 139.3485,
    postal_code: '96323'
  },
  '96325': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.7485,
    longitude: 139.3485,
    postal_code: '96325'
  },
  '96326': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.7485,
    longitude: 139.3485,
    postal_code: '96326'
  },
  '96328': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.7485,
    longitude: 139.3485,
    postal_code: '96328'
  },
  '96330': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.7485,
    longitude: 139.3485,
    postal_code: '96330'
  },
  '96336': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.6895,
    longitude: 139.6917,
    postal_code: '96336'
  },
  '96337': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.6895,
    longitude: 139.6917,
    postal_code: '96337'
  },
  '96338': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.6895,
    longitude: 139.6917,
    postal_code: '96338'
  },
  '96339': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.5138,
    longitude: 139.3937,
    postal_code: '96339'
  },
  '96343': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.6895,
    longitude: 139.6917,
    postal_code: '96343'
  },
  '96347': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.4333,
    longitude: 139.65,
    postal_code: '96347'
  },
  '96348': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.4333,
    longitude: 139.65,
    postal_code: '96348'
  },
  '96349': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.2836,
    longitude: 139.6672,
    postal_code: '96349'
  },
  '96350': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.2836,
    longitude: 139.6672,
    postal_code: '96350'
  },
  '96362': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.3,
    longitude: 127.7667,
    postal_code: '96362'
  },
  '96364': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.3652,
    longitude: 127.7586,
    postal_code: '96364'
  },
  '96365': {
    country: 'US',
    city: 'APO STA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.3652,
    longitude: 127.7586,
    postal_code: '96365'
  },
  '96367': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.3652,
    longitude: 127.7586,
    postal_code: '96367'
  },
  '96368': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.3652,
    longitude: 127.7586,
    postal_code: '96368'
  },
  '96370': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.35,
    longitude: 127.7667,
    postal_code: '96370'
  },
  '96372': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2872,
    longitude: 127.7731,
    postal_code: '96372'
  },
  '96373': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.3835,
    longitude: 127.8414,
    postal_code: '96373'
  },
  '96374': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2644,
    longitude: 127.7244,
    postal_code: '96374'
  },
  '96375': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2644,
    longitude: 127.7244,
    postal_code: '96375'
  },
  '96376': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2644,
    longitude: 127.7244,
    postal_code: '96376'
  },
  '96377': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.3905,
    longitude: 127.8588,
    postal_code: '96377'
  },
  '96378': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.3086,
    longitude: 127.7804,
    postal_code: '96378'
  },
  '96379': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.3086,
    longitude: 127.7804,
    postal_code: '96379'
  },
  '96382': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 27,
    longitude: 142.1667,
    postal_code: '96382'
  },
  '96390': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -23.697,
    longitude: 133.8818,
    postal_code: '96390'
  },
  '96407': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.15,
    longitude: 120.5833,
    postal_code: '96407'
  },
  '96408': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.15,
    longitude: 120.5833,
    postal_code: '96408'
  },
  '96409': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.15,
    longitude: 120.5833,
    postal_code: '96409'
  },
  '96410': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.15,
    longitude: 120.5833,
    postal_code: '96410'
  },
  '96411': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.15,
    longitude: 120.5833,
    postal_code: '96411'
  },
  '96415': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.3312,
    longitude: 120.5898,
    postal_code: '96415'
  },
  '96419': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.1423,
    longitude: 120.9757,
    postal_code: '96419'
  },
  '96428': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.6042,
    longitude: 120.9822,
    postal_code: '96428'
  },
  '96431': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.15,
    longitude: 120.5833,
    postal_code: '96431'
  },
  '96432': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.15,
    longitude: 120.5833,
    postal_code: '96432'
  },
  '96434': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.15,
    longitude: 120.5833,
    postal_code: '96434'
  },
  '96440': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.6042,
    longitude: 120.9822,
    postal_code: '96440'
  },
  '96445': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.1423,
    longitude: 120.9757,
    postal_code: '96445'
  },
  '96447': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 16.6159,
    longitude: 120.3166,
    postal_code: '96447'
  },
  '96450': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.7944,
    longitude: 120.2714,
    postal_code: '96450'
  },
  '96451': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.7944,
    longitude: 120.2714,
    postal_code: '96451'
  },
  '96452': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.7944,
    longitude: 120.2714,
    postal_code: '96452'
  },
  '96454': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.7944,
    longitude: 120.2714,
    postal_code: '96454'
  },
  '96456': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.3312,
    longitude: 120.5898,
    postal_code: '96456'
  },
  '96468': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 13.754,
    longitude: 100.5014,
    postal_code: '96468'
  },
  '96498': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 14.7484,
    longitude: 120.965,
    postal_code: '96498'
  },
  '96505': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.88,
    longitude: -176.6581,
    postal_code: '96505'
  },
  '96506': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.88,
    longitude: -176.6581,
    postal_code: '96506'
  },
  '96507': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.5672,
    longitude: 178.8775,
    postal_code: '96507'
  },
  '96508': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 45.7833,
    longitude: -86.7001,
    postal_code: '96508'
  },
  '96509': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 64.3006,
    longitude: -149.1128,
    postal_code: '96509'
  },
  '96510': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 64.7361,
    longitude: -156.9372,
    postal_code: '96510'
  },
  '96511': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 34.2322,
    longitude: 132.5666,
    postal_code: '96511'
  },
  '96512': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.7242,
    longitude: 174.1189,
    postal_code: '96512'
  },
  '96513': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 58.6883,
    longitude: -156.6614,
    postal_code: '96513'
  },
  '96514': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 28.228,
    longitude: -177.389,
    postal_code: '96514'
  },
  '96516': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 28.228,
    longitude: -177.389,
    postal_code: '96516'
  },
  '96517': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 34.2322,
    longitude: 132.5666,
    postal_code: '96517'
  },
  '96518': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 19.2811,
    longitude: 166.6434,
    postal_code: '96518'
  },
  '96520': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -6.2146,
    longitude: 106.8451,
    postal_code: '96520'
  },
  '96521': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 22.2783,
    longitude: 114.1747,
    postal_code: '96521'
  },
  '96522': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 22.2783,
    longitude: 114.1747,
    postal_code: '96522'
  },
  '96531': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -43.5333,
    longitude: 172.6333,
    postal_code: '96531'
  },
  '96534': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 1.2897,
    longitude: 103.8501,
    postal_code: '96534'
  },
  '96539': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 13.4757,
    longitude: 144.7489,
    postal_code: '96539'
  },
  '96540': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 13.4757,
    longitude: 144.7489,
    postal_code: '96540'
  },
  '96541': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 13.4757,
    longitude: 144.7489,
    postal_code: '96541'
  },
  '96542': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 13.4757,
    longitude: 144.7489,
    postal_code: '96542'
  },
  '96543': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 13.4757,
    longitude: 144.7489,
    postal_code: '96543'
  },
  '96546': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 13.754,
    longitude: 100.5014,
    postal_code: '96546'
  },
  '96548': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -23.6975,
    longitude: 133.8836,
    postal_code: '96548'
  },
  '96549': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -35.3065,
    longitude: 149.1162,
    postal_code: '96549'
  },
  '96550': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -21.9306,
    longitude: 114.1209,
    postal_code: '96550'
  },
  '96551': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -37.847,
    longitude: 144.9809,
    postal_code: '96551'
  },
  '96552': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -31.1998,
    longitude: 136.8326,
    postal_code: '96552'
  },
  '96553': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -33.8678,
    longitude: 151.2073,
    postal_code: '96553'
  },
  '96554': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -33.8678,
    longitude: 151.2073,
    postal_code: '96554'
  },
  '96556': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 19.7542,
    longitude: -155.5858,
    postal_code: '96556'
  },
  '96558': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 19.7542,
    longitude: -155.5858,
    postal_code: '96558'
  },
  '96598': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -89.9976,
    longitude: 139.2729,
    postal_code: '96598'
  },
  '96599': {
    country: 'US',
    city: 'FPO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: -77.846,
    longitude: 166.676,
    postal_code: '96599'
  },
  '96701': {
    country: 'US',
    city: 'Aiea',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3908,
    longitude: -157.9332,
    postal_code: '96701'
  },
  '96703': {
    country: 'US',
    city: 'Anahola',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 22.1423,
    longitude: -159.3139,
    postal_code: '96703'
  },
  '96704': {
    country: 'US',
    city: 'Captain Cook',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.4386,
    longitude: -155.8875,
    postal_code: '96704'
  },
  '96705': {
    country: 'US',
    city: 'Eleele',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9074,
    longitude: -159.5832,
    postal_code: '96705'
  },
  '96706': {
    country: 'US',
    city: 'Ewa Beach',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3274,
    longitude: -158.0103,
    postal_code: '96706'
  },
  '96707': {
    country: 'US',
    city: 'Kapolei',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3453,
    longitude: -158.087,
    postal_code: '96707'
  },
  '96708': {
    country: 'US',
    city: 'Haiku',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.9071,
    longitude: -156.3,
    postal_code: '96708'
  },
  '96709': {
    country: 'US',
    city: 'Kapolei',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3233,
    longitude: -158.0058,
    postal_code: '96709'
  },
  '96710': {
    country: 'US',
    city: 'Hakalau',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.8882,
    longitude: -155.1333,
    postal_code: '96710'
  },
  '96712': {
    country: 'US',
    city: 'Haleiwa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.6312,
    longitude: -158.0693,
    postal_code: '96712'
  },
  '96713': {
    country: 'US',
    city: 'Hana',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.7616,
    longitude: -156.0397,
    postal_code: '96713'
  },
  '96714': {
    country: 'US',
    city: 'Hanalei',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 22.1603,
    longitude: -159.5471,
    postal_code: '96714'
  },
  '96715': {
    country: 'US',
    city: 'Hanamaulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9977,
    longitude: -159.3592,
    postal_code: '96715'
  },
  '96716': {
    country: 'US',
    city: 'Hanapepe',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9156,
    longitude: -159.592,
    postal_code: '96716'
  },
  '96717': {
    country: 'US',
    city: 'Hauula',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.6139,
    longitude: -157.9157,
    postal_code: '96717'
  },
  '96718': {
    country: 'US',
    city: 'Hawaii National Park',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.5935,
    longitude: -155.438,
    postal_code: '96718'
  },
  '96719': {
    country: 'US',
    city: 'Hawi',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 20.238,
    longitude: -155.838,
    postal_code: '96719'
  },
  '96720': {
    country: 'US',
    city: 'Hilo',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.7025,
    longitude: -155.0939,
    postal_code: '96720'
  },
  '96721': {
    country: 'US',
    city: 'Hilo',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.7299,
    longitude: -155.0907,
    postal_code: '96721'
  },
  '96722': {
    country: 'US',
    city: 'Princeville',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 22.2192,
    longitude: -159.4866,
    postal_code: '96722'
  },
  '96725': {
    country: 'US',
    city: 'Holualoa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.6103,
    longitude: -155.9176,
    postal_code: '96725'
  },
  '96726': {
    country: 'US',
    city: 'Honaunau',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.4273,
    longitude: -155.9133,
    postal_code: '96726'
  },
  '96727': {
    country: 'US',
    city: 'Honokaa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 20.0827,
    longitude: -155.488,
    postal_code: '96727'
  },
  '96728': {
    country: 'US',
    city: 'Honomu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.8728,
    longitude: -155.1177,
    postal_code: '96728'
  },
  '96729': {
    country: 'US',
    city: 'Hoolehua',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 21.173,
    longitude: -157.0791,
    postal_code: '96729'
  },
  '96730': {
    country: 'US',
    city: 'Kaaawa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.5481,
    longitude: -157.8495,
    postal_code: '96730'
  },
  '96731': {
    country: 'US',
    city: 'Kahuku',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.675,
    longitude: -157.9725,
    postal_code: '96731'
  },
  '96732': {
    country: 'US',
    city: 'Kahului',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.8814,
    longitude: -156.4783,
    postal_code: '96732'
  },
  '96733': {
    country: 'US',
    city: 'Kahului',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.8895,
    longitude: -156.4743,
    postal_code: '96733'
  },
  '96734': {
    country: 'US',
    city: 'Kailua',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.4063,
    longitude: -157.7448,
    postal_code: '96734'
  },
  '96737': {
    country: 'US',
    city: 'Ocean View',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.1002,
    longitude: -155.7258,
    postal_code: '96737'
  },
  '96738': {
    country: 'US',
    city: 'Waikoloa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.9724,
    longitude: -155.818,
    postal_code: '96738'
  },
  '96739': {
    country: 'US',
    city: 'Keauhou',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.5606,
    longitude: -155.9623,
    postal_code: '96739'
  },
  '96740': {
    country: 'US',
    city: 'Kailua Kona',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.6531,
    longitude: -155.9798,
    postal_code: '96740'
  },
  '96741': {
    country: 'US',
    city: 'Kalaheo',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9259,
    longitude: -159.5303,
    postal_code: '96741'
  },
  '96742': {
    country: 'US',
    city: 'Kalaupapa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 21.1929,
    longitude: -156.9835,
    postal_code: '96742'
  },
  '96743': {
    country: 'US',
    city: 'Kamuela',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 20.0081,
    longitude: -155.7052,
    postal_code: '96743'
  },
  '96744': {
    country: 'US',
    city: 'Kaneohe',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.4228,
    longitude: -157.8115,
    postal_code: '96744'
  },
  '96745': {
    country: 'US',
    city: 'Kailua Kona',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.5935,
    longitude: -155.438,
    postal_code: '96745'
  },
  '96746': {
    country: 'US',
    city: 'Kapaa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 22.0868,
    longitude: -159.3448,
    postal_code: '96746'
  },
  '96747': {
    country: 'US',
    city: 'Kaumakani',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9213,
    longitude: -159.6241,
    postal_code: '96747'
  },
  '96748': {
    country: 'US',
    city: 'Kaunakakai',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 21.0905,
    longitude: -156.969,
    postal_code: '96748'
  },
  '96749': {
    country: 'US',
    city: 'Keaau',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.5893,
    longitude: -154.9926,
    postal_code: '96749'
  },
  '96750': {
    country: 'US',
    city: 'Kealakekua',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.5261,
    longitude: -155.93,
    postal_code: '96750'
  },
  '96751': {
    country: 'US',
    city: 'Kealia',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 22.106,
    longitude: -159.3085,
    postal_code: '96751'
  },
  '96752': {
    country: 'US',
    city: 'Kekaha',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9735,
    longitude: -159.7199,
    postal_code: '96752'
  },
  '96753': {
    country: 'US',
    city: 'Kihei',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.7441,
    longitude: -156.4475,
    postal_code: '96753'
  },
  '96754': {
    country: 'US',
    city: 'Kilauea',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 22.2073,
    longitude: -159.3954,
    postal_code: '96754'
  },
  '96755': {
    country: 'US',
    city: 'Kapaau',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 20.2183,
    longitude: -155.799,
    postal_code: '96755'
  },
  '96756': {
    country: 'US',
    city: 'Koloa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9083,
    longitude: -159.4749,
    postal_code: '96756'
  },
  '96757': {
    country: 'US',
    city: 'Kualapuu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 21.1601,
    longitude: -157.0277,
    postal_code: '96757'
  },
  '96759': {
    country: 'US',
    city: 'Kunia',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.463,
    longitude: -158.0639,
    postal_code: '96759'
  },
  '96760': {
    country: 'US',
    city: 'Kurtistown',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.5693,
    longitude: -155.0349,
    postal_code: '96760'
  },
  '96761': {
    country: 'US',
    city: 'Lahaina',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.9174,
    longitude: -156.6772,
    postal_code: '96761'
  },
  '96762': {
    country: 'US',
    city: 'Laie',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.6253,
    longitude: -157.941,
    postal_code: '96762'
  },
  '96763': {
    country: 'US',
    city: 'Lanai City',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.8293,
    longitude: -156.921,
    postal_code: '96763'
  },
  '96764': {
    country: 'US',
    city: 'Laupahoehoe',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.9802,
    longitude: -155.2323,
    postal_code: '96764'
  },
  '96765': {
    country: 'US',
    city: 'Lawai',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9309,
    longitude: -159.4993,
    postal_code: '96765'
  },
  '96766': {
    country: 'US',
    city: 'Lihue',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9816,
    longitude: -159.3683,
    postal_code: '96766'
  },
  '96767': {
    country: 'US',
    city: 'Lahaina',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.8666,
    longitude: -156.6467,
    postal_code: '96767'
  },
  '96768': {
    country: 'US',
    city: 'Makawao',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.8469,
    longitude: -156.3327,
    postal_code: '96768'
  },
  '96769': {
    country: 'US',
    city: 'Makaweli',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9454,
    longitude: -159.9214,
    postal_code: '96769'
  },
  '96770': {
    country: 'US',
    city: 'Maunaloa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 21.1422,
    longitude: -157.2193,
    postal_code: '96770'
  },
  '96771': {
    country: 'US',
    city: 'Mountain View',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.5506,
    longitude: -155.0864,
    postal_code: '96771'
  },
  '96772': {
    country: 'US',
    city: 'Naalehu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.0668,
    longitude: -155.6575,
    postal_code: '96772'
  },
  '96773': {
    country: 'US',
    city: 'Ninole',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.935,
    longitude: -155.1668,
    postal_code: '96773'
  },
  '96774': {
    country: 'US',
    city: 'Ookala',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 20.0119,
    longitude: -155.2747,
    postal_code: '96774'
  },
  '96776': {
    country: 'US',
    city: 'Paauilo',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 20.0271,
    longitude: -155.3697,
    postal_code: '96776'
  },
  '96777': {
    country: 'US',
    city: 'Pahala',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.2079,
    longitude: -155.4815,
    postal_code: '96777'
  },
  '96778': {
    country: 'US',
    city: 'Pahoa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.5089,
    longitude: -154.9231,
    postal_code: '96778'
  },
  '96779': {
    country: 'US',
    city: 'Paia',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.9154,
    longitude: -156.3802,
    postal_code: '96779'
  },
  '96780': {
    country: 'US',
    city: 'Papaaloa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.9751,
    longitude: -155.2203,
    postal_code: '96780'
  },
  '96781': {
    country: 'US',
    city: 'Papaikou',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.7916,
    longitude: -155.0984,
    postal_code: '96781'
  },
  '96782': {
    country: 'US',
    city: 'Pearl City',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.4084,
    longitude: -157.9652,
    postal_code: '96782'
  },
  '96783': {
    country: 'US',
    city: 'Pepeekeo',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.8353,
    longitude: -155.113,
    postal_code: '96783'
  },
  '96784': {
    country: 'US',
    city: 'Puunene',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.8666,
    longitude: -156.6467,
    postal_code: '96784'
  },
  '96785': {
    country: 'US',
    city: 'Volcano',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Hawaii',
    latitude: 19.4801,
    longitude: -155.1974,
    postal_code: '96785'
  },
  '96786': {
    country: 'US',
    city: 'Wahiawa',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.5006,
    longitude: -158.0435,
    postal_code: '96786'
  },
  '96788': {
    country: 'US',
    city: 'Pukalani',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.8559,
    longitude: -156.3259,
    postal_code: '96788'
  },
  '96789': {
    country: 'US',
    city: 'Mililani',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.4531,
    longitude: -158.0174,
    postal_code: '96789'
  },
  '96790': {
    country: 'US',
    city: 'Kula',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.7534,
    longitude: -156.326,
    postal_code: '96790'
  },
  '96791': {
    country: 'US',
    city: 'Waialua',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.5766,
    longitude: -158.1267,
    postal_code: '96791'
  },
  '96792': {
    country: 'US',
    city: 'Waianae',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.4352,
    longitude: -158.1781,
    postal_code: '96792'
  },
  '96793': {
    country: 'US',
    city: 'Wailuku',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Maui',
    latitude: 20.8966,
    longitude: -156.5036,
    postal_code: '96793'
  },
  '96795': {
    country: 'US',
    city: 'Waimanalo',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3418,
    longitude: -157.7131,
    postal_code: '96795'
  },
  '96796': {
    country: 'US',
    city: 'Waimea',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Kauai',
    latitude: 21.9571,
    longitude: -159.6689,
    postal_code: '96796'
  },
  '96797': {
    country: 'US',
    city: 'Waipahu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3982,
    longitude: -158.0124,
    postal_code: '96797'
  },
  '96801': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3278,
    longitude: -157.8294,
    postal_code: '96801'
  },
  '96802': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.306,
    longitude: -157.8587,
    postal_code: '96802'
  },
  '96803': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.306,
    longitude: -157.8585,
    postal_code: '96803'
  },
  '96804': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.306,
    longitude: -157.8585,
    postal_code: '96804'
  },
  '96805': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3062,
    longitude: -157.8585,
    postal_code: '96805'
  },
  '96806': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3062,
    longitude: -157.8585,
    postal_code: '96806'
  },
  '96807': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3062,
    longitude: -157.8585,
    postal_code: '96807'
  },
  '96808': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3062,
    longitude: -157.8585,
    postal_code: '96808'
  },
  '96809': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3062,
    longitude: -157.8585,
    postal_code: '96809'
  },
  '96810': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3062,
    longitude: -157.8585,
    postal_code: '96810'
  },
  '96811': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3278,
    longitude: -157.8294,
    postal_code: '96811'
  },
  '96812': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3061,
    longitude: -157.8585,
    postal_code: '96812'
  },
  '96813': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3179,
    longitude: -157.8521,
    postal_code: '96813'
  },
  '96814': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.2998,
    longitude: -157.8439,
    postal_code: '96814'
  },
  '96815': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.2811,
    longitude: -157.8266,
    postal_code: '96815'
  },
  '96816': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.2887,
    longitude: -157.8006,
    postal_code: '96816'
  },
  '96817': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3295,
    longitude: -157.8615,
    postal_code: '96817'
  },
  '96818': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3532,
    longitude: -157.9269,
    postal_code: '96818'
  },
  '96819': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3488,
    longitude: -157.8759,
    postal_code: '96819'
  },
  '96820': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.351,
    longitude: -157.8795,
    postal_code: '96820'
  },
  '96821': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.2928,
    longitude: -157.7552,
    postal_code: '96821'
  },
  '96822': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3117,
    longitude: -157.8298,
    postal_code: '96822'
  },
  '96823': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3072,
    longitude: -157.8465,
    postal_code: '96823'
  },
  '96824': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.2808,
    longitude: -157.7552,
    postal_code: '96824'
  },
  '96825': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.2987,
    longitude: -157.6985,
    postal_code: '96825'
  },
  '96826': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.2941,
    longitude: -157.8284,
    postal_code: '96826'
  },
  '96828': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.294,
    longitude: -157.8226,
    postal_code: '96828'
  },
  '96830': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.2841,
    longitude: -157.834,
    postal_code: '96830'
  },
  '96836': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.2899,
    longitude: -157.8384,
    postal_code: '96836'
  },
  '96837': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.315,
    longitude: -157.8633,
    postal_code: '96837'
  },
  '96838': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.349,
    longitude: -157.875,
    postal_code: '96838'
  },
  '96839': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3206,
    longitude: -157.8126,
    postal_code: '96839'
  },
  '96840': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3068,
    longitude: -157.8607,
    postal_code: '96840'
  },
  '96841': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3103,
    longitude: -157.8594,
    postal_code: '96841'
  },
  '96843': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3055,
    longitude: -157.853,
    postal_code: '96843'
  },
  '96844': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.2951,
    longitude: -157.8162,
    postal_code: '96844'
  },
  '96846': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3206,
    longitude: -157.8699,
    postal_code: '96846'
  },
  '96847': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3022,
    longitude: -157.8443,
    postal_code: '96847'
  },
  '96848': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3206,
    longitude: -157.8126,
    postal_code: '96848'
  },
  '96849': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3341,
    longitude: -157.9172,
    postal_code: '96849'
  },
  '96850': {
    country: 'US',
    city: 'Honolulu',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3095,
    longitude: -157.863,
    postal_code: '96850'
  },
  '96853': {
    country: 'US',
    city: 'Jbphh',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.4061,
    longitude: -157.9609,
    postal_code: '96853'
  },
  '96854': {
    country: 'US',
    city: 'Wheeler Army Airfield',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.4877,
    longitude: -158.0351,
    postal_code: '96854'
  },
  '96857': {
    country: 'US',
    city: 'Schofield Barracks',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.4963,
    longitude: -158.0646,
    postal_code: '96857'
  },
  '96858': {
    country: 'US',
    city: 'Fort Shafter',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3416,
    longitude: -157.8918,
    postal_code: '96858'
  },
  '96859': {
    country: 'US',
    city: 'Tripler Army Medical Center',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.4061,
    longitude: -157.9609,
    postal_code: '96859'
  },
  '96860': {
    country: 'US',
    city: 'Jbphh',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.316,
    longitude: -157.8677,
    postal_code: '96860'
  },
  '96861': {
    country: 'US',
    city: 'Camp H M Smith',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.3967,
    longitude: -157.8994,
    postal_code: '96861'
  },
  '96863': {
    country: 'US',
    city: 'Mcbh Kaneohe Bay',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 21.316,
    longitude: -157.8677,
    postal_code: '96863'
  },
  '96898': {
    country: 'US',
    city: 'Wake Island',
    state: 'Hawaii',
    state_short: 'HI',
    county: 'Honolulu',
    latitude: 19.2836,
    longitude: 166.6419,
    postal_code: '96898'
  },
  '96960': {
    country: 'US',
    city: 'Marshall Islands',
    state: '',
    state_short: 'MH',
    county: 'Marshall Islands',
    latitude: 7.1128,
    longitude: 171.237,
    postal_code: '96960'
  },
  '96970': {
    country: 'US',
    city: 'Ebeye',
    state: '',
    state_short: 'MH',
    county: 'Marshall Islands',
    latitude: 8.786,
    longitude: 167.7365,
    postal_code: '96970'
  },
  '97001': {
    country: 'US',
    city: 'Antelope',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wasco',
    latitude: 44.8892,
    longitude: -120.7914,
    postal_code: '97001'
  },
  '97002': {
    country: 'US',
    city: 'Aurora',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 45.2284,
    longitude: -122.8039,
    postal_code: '97002'
  },
  '97003': {
    country: 'US',
    city: 'Beaverton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.5201,
    longitude: -122.8604,
    postal_code: '97003'
  },
  '97004': {
    country: 'US',
    city: 'Beavercreek',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.2597,
    longitude: -122.4751,
    postal_code: '97004'
  },
  '97005': {
    country: 'US',
    city: 'Beaverton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.4963,
    longitude: -122.8001,
    postal_code: '97005'
  },
  '97006': {
    country: 'US',
    city: 'Beaverton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.5201,
    longitude: -122.8604,
    postal_code: '97006'
  },
  '97007': {
    country: 'US',
    city: 'Beaverton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.4505,
    longitude: -122.8652,
    postal_code: '97007'
  },
  '97008': {
    country: 'US',
    city: 'Beaverton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.456,
    longitude: -122.7996,
    postal_code: '97008'
  },
  '97009': {
    country: 'US',
    city: 'Boring',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.4297,
    longitude: -122.3807,
    postal_code: '97009'
  },
  '97010': {
    country: 'US',
    city: 'Bridal Veil',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.583,
    longitude: -122.1011,
    postal_code: '97010'
  },
  '97011': {
    country: 'US',
    city: 'Brightwood',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.3652,
    longitude: -122.0036,
    postal_code: '97011'
  },
  '97013': {
    country: 'US',
    city: 'Canby',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.2514,
    longitude: -122.6832,
    postal_code: '97013'
  },
  '97014': {
    country: 'US',
    city: 'Cascade Locks',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Hood River',
    latitude: 45.671,
    longitude: -121.8686,
    postal_code: '97014'
  },
  '97015': {
    country: 'US',
    city: 'Clackamas',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.415,
    longitude: -122.52,
    postal_code: '97015'
  },
  '97016': {
    country: 'US',
    city: 'Clatskanie',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Columbia',
    latitude: 46.0665,
    longitude: -123.2059,
    postal_code: '97016'
  },
  '97017': {
    country: 'US',
    city: 'Colton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.1573,
    longitude: -122.4248,
    postal_code: '97017'
  },
  '97018': {
    country: 'US',
    city: 'Columbia City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Columbia',
    latitude: 45.8925,
    longitude: -122.8122,
    postal_code: '97018'
  },
  '97019': {
    country: 'US',
    city: 'Corbett',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5221,
    longitude: -122.2417,
    postal_code: '97019'
  },
  '97020': {
    country: 'US',
    city: 'Donald',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 45.2199,
    longitude: -122.8405,
    postal_code: '97020'
  },
  '97021': {
    country: 'US',
    city: 'Dufur',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wasco',
    latitude: 45.3913,
    longitude: -121.1432,
    postal_code: '97021'
  },
  '97022': {
    country: 'US',
    city: 'Eagle Creek',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.3582,
    longitude: -122.3381,
    postal_code: '97022'
  },
  '97023': {
    country: 'US',
    city: 'Estacada',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.2872,
    longitude: -122.3259,
    postal_code: '97023'
  },
  '97024': {
    country: 'US',
    city: 'Fairview',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5473,
    longitude: -122.4414,
    postal_code: '97024'
  },
  '97026': {
    country: 'US',
    city: 'Gervais',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 45.1086,
    longitude: -122.8962,
    postal_code: '97026'
  },
  '97027': {
    country: 'US',
    city: 'Gladstone',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.3899,
    longitude: -122.5902,
    postal_code: '97027'
  },
  '97028': {
    country: 'US',
    city: 'Government Camp',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.2972,
    longitude: -121.739,
    postal_code: '97028'
  },
  '97029': {
    country: 'US',
    city: 'Grass Valley',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Sherman',
    latitude: 45.3013,
    longitude: -120.7478,
    postal_code: '97029'
  },
  '97030': {
    country: 'US',
    city: 'Gresham',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5154,
    longitude: -122.4203,
    postal_code: '97030'
  },
  '97031': {
    country: 'US',
    city: 'Hood River',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Hood River',
    latitude: 45.6711,
    longitude: -121.5391,
    postal_code: '97031'
  },
  '97032': {
    country: 'US',
    city: 'Hubbard',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 45.1823,
    longitude: -122.8079,
    postal_code: '97032'
  },
  '97033': {
    country: 'US',
    city: 'Kent',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Sherman',
    latitude: 45.0838,
    longitude: -120.6649,
    postal_code: '97033'
  },
  '97034': {
    country: 'US',
    city: 'Lake Oswego',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.4093,
    longitude: -122.6847,
    postal_code: '97034'
  },
  '97035': {
    country: 'US',
    city: 'Lake Oswego',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.4147,
    longitude: -122.7227,
    postal_code: '97035'
  },
  '97036': {
    country: 'US',
    city: 'Marylhurst',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.4012,
    longitude: -122.6512,
    postal_code: '97036'
  },
  '97037': {
    country: 'US',
    city: 'Maupin',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wasco',
    latitude: 45.0742,
    longitude: -121.2282,
    postal_code: '97037'
  },
  '97038': {
    country: 'US',
    city: 'Molalla',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.1223,
    longitude: -122.5756,
    postal_code: '97038'
  },
  '97039': {
    country: 'US',
    city: 'Moro',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Sherman',
    latitude: 45.4853,
    longitude: -120.6957,
    postal_code: '97039'
  },
  '97040': {
    country: 'US',
    city: 'Mosier',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wasco',
    latitude: 45.6508,
    longitude: -121.379,
    postal_code: '97040'
  },
  '97041': {
    country: 'US',
    city: 'Mount Hood Parkdale',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Hood River',
    latitude: 45.4929,
    longitude: -121.5983,
    postal_code: '97041'
  },
  '97042': {
    country: 'US',
    city: 'Mulino',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.213,
    longitude: -122.5351,
    postal_code: '97042'
  },
  '97044': {
    country: 'US',
    city: 'Odell',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Hood River',
    latitude: 45.6437,
    longitude: -121.5367,
    postal_code: '97044'
  },
  '97045': {
    country: 'US',
    city: 'Oregon City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.3377,
    longitude: -122.57,
    postal_code: '97045'
  },
  '97048': {
    country: 'US',
    city: 'Rainier',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Columbia',
    latitude: 46.0646,
    longitude: -122.9671,
    postal_code: '97048'
  },
  '97049': {
    country: 'US',
    city: 'Rhododendron',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.3465,
    longitude: -121.9151,
    postal_code: '97049'
  },
  '97050': {
    country: 'US',
    city: 'Rufus',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Sherman',
    latitude: 45.6948,
    longitude: -120.7359,
    postal_code: '97050'
  },
  '97051': {
    country: 'US',
    city: 'Saint Helens',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Columbia',
    latitude: 45.8608,
    longitude: -122.8282,
    postal_code: '97051'
  },
  '97053': {
    country: 'US',
    city: 'Warren',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Columbia',
    latitude: 45.826,
    longitude: -122.8634,
    postal_code: '97053'
  },
  '97054': {
    country: 'US',
    city: 'Deer Island',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Columbia',
    latitude: 45.9356,
    longitude: -122.8985,
    postal_code: '97054'
  },
  '97055': {
    country: 'US',
    city: 'Sandy',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.379,
    longitude: -122.223,
    postal_code: '97055'
  },
  '97056': {
    country: 'US',
    city: 'Scappoose',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Columbia',
    latitude: 45.7655,
    longitude: -122.8928,
    postal_code: '97056'
  },
  '97057': {
    country: 'US',
    city: 'Shaniko',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wasco',
    latitude: 45.0027,
    longitude: -120.75,
    postal_code: '97057'
  },
  '97058': {
    country: 'US',
    city: 'The Dalles',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wasco',
    latitude: 45.5995,
    longitude: -121.1905,
    postal_code: '97058'
  },
  '97060': {
    country: 'US',
    city: 'Troutdale',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5254,
    longitude: -122.3739,
    postal_code: '97060'
  },
  '97062': {
    country: 'US',
    city: 'Tualatin',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.3727,
    longitude: -122.7631,
    postal_code: '97062'
  },
  '97063': {
    country: 'US',
    city: 'Tygh Valley',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wasco',
    latitude: 45.2229,
    longitude: -121.2927,
    postal_code: '97063'
  },
  '97064': {
    country: 'US',
    city: 'Vernonia',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Columbia',
    latitude: 45.8573,
    longitude: -123.1967,
    postal_code: '97064'
  },
  '97065': {
    country: 'US',
    city: 'Wasco',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Sherman',
    latitude: 45.5974,
    longitude: -120.7304,
    postal_code: '97065'
  },
  '97067': {
    country: 'US',
    city: 'Welches',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.3399,
    longitude: -121.9598,
    postal_code: '97067'
  },
  '97068': {
    country: 'US',
    city: 'West Linn',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.3669,
    longitude: -122.648,
    postal_code: '97068'
  },
  '97070': {
    country: 'US',
    city: 'Wilsonville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.2986,
    longitude: -122.7699,
    postal_code: '97070'
  },
  '97071': {
    country: 'US',
    city: 'Woodburn',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 45.1446,
    longitude: -122.8583,
    postal_code: '97071'
  },
  '97075': {
    country: 'US',
    city: 'Beaverton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.4871,
    longitude: -122.8037,
    postal_code: '97075'
  },
  '97076': {
    country: 'US',
    city: 'Beaverton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.4871,
    longitude: -122.8037,
    postal_code: '97076'
  },
  '97077': {
    country: 'US',
    city: 'Beaverton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.4871,
    longitude: -122.8037,
    postal_code: '97077'
  },
  '97078': {
    country: 'US',
    city: 'Beaverton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.452,
    longitude: -122.7893,
    postal_code: '97078'
  },
  '97080': {
    country: 'US',
    city: 'Gresham',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.4817,
    longitude: -122.4156,
    postal_code: '97080'
  },
  '97086': {
    country: 'US',
    city: 'Happy Valley',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.4446,
    longitude: -122.5372,
    postal_code: '97086'
  },
  '97089': {
    country: 'US',
    city: 'Damascus',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.4176,
    longitude: -122.459,
    postal_code: '97089'
  },
  '97101': {
    country: 'US',
    city: 'Amity',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Yamhill',
    latitude: 45.1157,
    longitude: -123.1744,
    postal_code: '97101'
  },
  '97102': {
    country: 'US',
    city: 'Arch Cape',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clatsop',
    latitude: 45.8109,
    longitude: -123.963,
    postal_code: '97102'
  },
  '97103': {
    country: 'US',
    city: 'Astoria',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clatsop',
    latitude: 46.1558,
    longitude: -123.798,
    postal_code: '97103'
  },
  '97106': {
    country: 'US',
    city: 'Banks',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.6535,
    longitude: -123.121,
    postal_code: '97106'
  },
  '97107': {
    country: 'US',
    city: 'Bay City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.5197,
    longitude: -123.8761,
    postal_code: '97107'
  },
  '97108': {
    country: 'US',
    city: 'Beaver',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.2767,
    longitude: -123.8234,
    postal_code: '97108'
  },
  '97109': {
    country: 'US',
    city: 'Buxton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.737,
    longitude: -123.2146,
    postal_code: '97109'
  },
  '97110': {
    country: 'US',
    city: 'Cannon Beach',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clatsop',
    latitude: 45.8918,
    longitude: -123.9615,
    postal_code: '97110'
  },
  '97111': {
    country: 'US',
    city: 'Carlton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Yamhill',
    latitude: 45.2859,
    longitude: -123.1523,
    postal_code: '97111'
  },
  '97112': {
    country: 'US',
    city: 'Cloverdale',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.2858,
    longitude: -123.8356,
    postal_code: '97112'
  },
  '97113': {
    country: 'US',
    city: 'Cornelius',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.529,
    longitude: -123.0415,
    postal_code: '97113'
  },
  '97114': {
    country: 'US',
    city: 'Dayton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Yamhill',
    latitude: 45.1977,
    longitude: -123.0753,
    postal_code: '97114'
  },
  '97115': {
    country: 'US',
    city: 'Dundee',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Yamhill',
    latitude: 45.2776,
    longitude: -123.0152,
    postal_code: '97115'
  },
  '97116': {
    country: 'US',
    city: 'Forest Grove',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.5981,
    longitude: -123.1818,
    postal_code: '97116'
  },
  '97117': {
    country: 'US',
    city: 'Gales Creek',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.5957,
    longitude: -123.234,
    postal_code: '97117'
  },
  '97118': {
    country: 'US',
    city: 'Garibaldi',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.5626,
    longitude: -123.9001,
    postal_code: '97118'
  },
  '97119': {
    country: 'US',
    city: 'Gaston',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.4427,
    longitude: -123.1666,
    postal_code: '97119'
  },
  '97121': {
    country: 'US',
    city: 'Hammond',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clatsop',
    latitude: 46.198,
    longitude: -123.9527,
    postal_code: '97121'
  },
  '97122': {
    country: 'US',
    city: 'Hebo',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.1768,
    longitude: -123.846,
    postal_code: '97122'
  },
  '97123': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.4984,
    longitude: -122.957,
    postal_code: '97123'
  },
  '97124': {
    country: 'US',
    city: 'Hillsboro',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.5387,
    longitude: -122.9636,
    postal_code: '97124'
  },
  '97125': {
    country: 'US',
    city: 'Manning',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.6648,
    longitude: -123.1637,
    postal_code: '97125'
  },
  '97127': {
    country: 'US',
    city: 'Lafayette',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Yamhill',
    latitude: 45.2466,
    longitude: -123.1114,
    postal_code: '97127'
  },
  '97128': {
    country: 'US',
    city: 'Mcminnville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Yamhill',
    latitude: 45.2097,
    longitude: -123.2043,
    postal_code: '97128'
  },
  '97130': {
    country: 'US',
    city: 'Manzanita',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.7184,
    longitude: -123.9351,
    postal_code: '97130'
  },
  '97131': {
    country: 'US',
    city: 'Nehalem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.7216,
    longitude: -123.9049,
    postal_code: '97131'
  },
  '97132': {
    country: 'US',
    city: 'Newberg',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Yamhill',
    latitude: 45.3099,
    longitude: -122.9685,
    postal_code: '97132'
  },
  '97133': {
    country: 'US',
    city: 'North Plains',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.6686,
    longitude: -123.0281,
    postal_code: '97133'
  },
  '97134': {
    country: 'US',
    city: 'Oceanside',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.4549,
    longitude: -123.965,
    postal_code: '97134'
  },
  '97135': {
    country: 'US',
    city: 'Pacific City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.205,
    longitude: -123.959,
    postal_code: '97135'
  },
  '97136': {
    country: 'US',
    city: 'Rockaway Beach',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.6134,
    longitude: -123.9429,
    postal_code: '97136'
  },
  '97137': {
    country: 'US',
    city: 'Saint Paul',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 45.196,
    longitude: -122.9674,
    postal_code: '97137'
  },
  '97138': {
    country: 'US',
    city: 'Seaside',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clatsop',
    latitude: 45.9932,
    longitude: -123.9226,
    postal_code: '97138'
  },
  '97140': {
    country: 'US',
    city: 'Sherwood',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.3514,
    longitude: -122.8567,
    postal_code: '97140'
  },
  '97141': {
    country: 'US',
    city: 'Tillamook',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.4492,
    longitude: -123.8189,
    postal_code: '97141'
  },
  '97143': {
    country: 'US',
    city: 'Netarts',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.434,
    longitude: -123.9458,
    postal_code: '97143'
  },
  '97144': {
    country: 'US',
    city: 'Timber',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.727,
    longitude: -123.3119,
    postal_code: '97144'
  },
  '97145': {
    country: 'US',
    city: 'Tolovana Park',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clatsop',
    latitude: 45.8663,
    longitude: -123.9595,
    postal_code: '97145'
  },
  '97146': {
    country: 'US',
    city: 'Warrenton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clatsop',
    latitude: 46.145,
    longitude: -123.9254,
    postal_code: '97146'
  },
  '97147': {
    country: 'US',
    city: 'Wheeler',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.6888,
    longitude: -123.8816,
    postal_code: '97147'
  },
  '97148': {
    country: 'US',
    city: 'Yamhill',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Yamhill',
    latitude: 45.335,
    longitude: -123.2036,
    postal_code: '97148'
  },
  '97149': {
    country: 'US',
    city: 'Neskowin',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Tillamook',
    latitude: 45.097,
    longitude: -123.9434,
    postal_code: '97149'
  },
  '97201': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5078,
    longitude: -122.6897,
    postal_code: '97201'
  },
  '97202': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.484,
    longitude: -122.6365,
    postal_code: '97202'
  },
  '97203': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5889,
    longitude: -122.7347,
    postal_code: '97203'
  },
  '97204': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5181,
    longitude: -122.6745,
    postal_code: '97204'
  },
  '97205': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5207,
    longitude: -122.6888,
    postal_code: '97205'
  },
  '97206': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.484,
    longitude: -122.5973,
    postal_code: '97206'
  },
  '97207': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97207'
  },
  '97208': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97208'
  },
  '97209': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.527,
    longitude: -122.6854,
    postal_code: '97209'
  },
  '97210': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5303,
    longitude: -122.7033,
    postal_code: '97210'
  },
  '97211': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5653,
    longitude: -122.6448,
    postal_code: '97211'
  },
  '97212': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5441,
    longitude: -122.6423,
    postal_code: '97212'
  },
  '97213': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5373,
    longitude: -122.5987,
    postal_code: '97213'
  },
  '97214': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5142,
    longitude: -122.6364,
    postal_code: '97214'
  },
  '97215': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5143,
    longitude: -122.599,
    postal_code: '97215'
  },
  '97216': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5137,
    longitude: -122.5569,
    postal_code: '97216'
  },
  '97217': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5742,
    longitude: -122.6842,
    postal_code: '97217'
  },
  '97218': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.56,
    longitude: -122.6001,
    postal_code: '97218'
  },
  '97219': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.458,
    longitude: -122.7074,
    postal_code: '97219'
  },
  '97220': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5411,
    longitude: -122.5566,
    postal_code: '97220'
  },
  '97221': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.4918,
    longitude: -122.7267,
    postal_code: '97221'
  },
  '97222': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.4373,
    longitude: -122.6147,
    postal_code: '97222'
  },
  '97223': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.4403,
    longitude: -122.7793,
    postal_code: '97223'
  },
  '97224': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.4094,
    longitude: -122.8014,
    postal_code: '97224'
  },
  '97225': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.4985,
    longitude: -122.7787,
    postal_code: '97225'
  },
  '97227': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5496,
    longitude: -122.6743,
    postal_code: '97227'
  },
  '97228': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97228'
  },
  '97229': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.5483,
    longitude: -122.8276,
    postal_code: '97229'
  },
  '97230': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5472,
    longitude: -122.5001,
    postal_code: '97230'
  },
  '97231': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.6401,
    longitude: -122.838,
    postal_code: '97231'
  },
  '97232': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5287,
    longitude: -122.6363,
    postal_code: '97232'
  },
  '97233': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5142,
    longitude: -122.4985,
    postal_code: '97233'
  },
  '97236': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.4887,
    longitude: -122.5091,
    postal_code: '97236'
  },
  '97238': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97238'
  },
  '97239': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.4983,
    longitude: -122.6913,
    postal_code: '97239'
  },
  '97240': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97240'
  },
  '97242': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97242'
  },
  '97250': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97250'
  },
  '97252': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97252'
  },
  '97253': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97253'
  },
  '97254': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5967,
    longitude: -122.5942,
    postal_code: '97254'
  },
  '97256': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97256'
  },
  '97258': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97258'
  },
  '97266': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.4762,
    longitude: -122.5596,
    postal_code: '97266'
  },
  '97267': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.4021,
    longitude: -122.6144,
    postal_code: '97267'
  },
  '97268': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97268'
  },
  '97269': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Clackamas',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97269'
  },
  '97280': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97280'
  },
  '97281': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97281'
  },
  '97282': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97282'
  },
  '97283': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97283'
  },
  '97286': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97286'
  },
  '97290': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97290'
  },
  '97291': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97291'
  },
  '97292': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97292'
  },
  '97293': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97293'
  },
  '97294': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97294'
  },
  '97296': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Multnomah',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97296'
  },
  '97298': {
    country: 'US',
    city: 'Portland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Washington',
    latitude: 45.5235,
    longitude: -122.6762,
    postal_code: '97298'
  },
  '97301': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.949,
    longitude: -123.004,
    postal_code: '97301'
  },
  '97302': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9039,
    longitude: -123.0445,
    postal_code: '97302'
  },
  '97303': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9927,
    longitude: -123.0167,
    postal_code: '97303'
  },
  '97304': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Polk',
    latitude: 44.9588,
    longitude: -123.0753,
    postal_code: '97304'
  },
  '97305': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9961,
    longitude: -122.9124,
    postal_code: '97305'
  },
  '97306': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.8685,
    longitude: -123.0438,
    postal_code: '97306'
  },
  '97307': {
    country: 'US',
    city: 'Keizer',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9901,
    longitude: -123.0262,
    postal_code: '97307'
  },
  '97308': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9429,
    longitude: -123.0351,
    postal_code: '97308'
  },
  '97309': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9429,
    longitude: -123.0351,
    postal_code: '97309'
  },
  '97310': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9271,
    longitude: -122.9861,
    postal_code: '97310'
  },
  '97311': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9438,
    longitude: -123.0286,
    postal_code: '97311'
  },
  '97312': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9364,
    longitude: -123.0381,
    postal_code: '97312'
  },
  '97313': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9849,
    longitude: -122.9988,
    postal_code: '97313'
  },
  '97314': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9655,
    longitude: -123.0066,
    postal_code: '97314'
  },
  '97317': {
    country: 'US',
    city: 'Salem',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9026,
    longitude: -122.9074,
    postal_code: '97317'
  },
  '97321': {
    country: 'US',
    city: 'Albany',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.652,
    longitude: -123.1333,
    postal_code: '97321'
  },
  '97322': {
    country: 'US',
    city: 'Albany',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.626,
    longitude: -123.057,
    postal_code: '97322'
  },
  '97324': {
    country: 'US',
    city: 'Alsea',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Benton',
    latitude: 44.3691,
    longitude: -123.6089,
    postal_code: '97324'
  },
  '97325': {
    country: 'US',
    city: 'Aumsville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.8313,
    longitude: -122.8517,
    postal_code: '97325'
  },
  '97326': {
    country: 'US',
    city: 'Blodgett',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Benton',
    latitude: 44.6281,
    longitude: -123.6067,
    postal_code: '97326'
  },
  '97327': {
    country: 'US',
    city: 'Brownsville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.377,
    longitude: -122.9485,
    postal_code: '97327'
  },
  '97329': {
    country: 'US',
    city: 'Cascadia',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.3973,
    longitude: -122.4817,
    postal_code: '97329'
  },
  '97330': {
    country: 'US',
    city: 'Corvallis',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Benton',
    latitude: 44.5904,
    longitude: -123.2722,
    postal_code: '97330'
  },
  '97331': {
    country: 'US',
    city: 'Corvallis',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Benton',
    latitude: 44.5638,
    longitude: -123.2779,
    postal_code: '97331'
  },
  '97333': {
    country: 'US',
    city: 'Corvallis',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Benton',
    latitude: 44.5393,
    longitude: -123.2799,
    postal_code: '97333'
  },
  '97335': {
    country: 'US',
    city: 'Crabtree',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.6344,
    longitude: -122.8933,
    postal_code: '97335'
  },
  '97336': {
    country: 'US',
    city: 'Crawfordsville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.3593,
    longitude: -122.8508,
    postal_code: '97336'
  },
  '97338': {
    country: 'US',
    city: 'Dallas',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Polk',
    latitude: 44.9225,
    longitude: -123.32,
    postal_code: '97338'
  },
  '97339': {
    country: 'US',
    city: 'Corvallis',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Benton',
    latitude: 44.5646,
    longitude: -123.262,
    postal_code: '97339'
  },
  '97341': {
    country: 'US',
    city: 'Depoe Bay',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.8322,
    longitude: -124.0503,
    postal_code: '97341'
  },
  '97342': {
    country: 'US',
    city: 'Detroit',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.734,
    longitude: -122.1498,
    postal_code: '97342'
  },
  '97343': {
    country: 'US',
    city: 'Eddyville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.6371,
    longitude: -123.7531,
    postal_code: '97343'
  },
  '97344': {
    country: 'US',
    city: 'Falls City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Polk',
    latitude: 44.8706,
    longitude: -123.4461,
    postal_code: '97344'
  },
  '97345': {
    country: 'US',
    city: 'Foster',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.3836,
    longitude: -122.5449,
    postal_code: '97345'
  },
  '97346': {
    country: 'US',
    city: 'Gates',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.7527,
    longitude: -122.3995,
    postal_code: '97346'
  },
  '97347': {
    country: 'US',
    city: 'Grand Ronde',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Polk',
    latitude: 45.075,
    longitude: -123.6335,
    postal_code: '97347'
  },
  '97348': {
    country: 'US',
    city: 'Halsey',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.3862,
    longitude: -123.1251,
    postal_code: '97348'
  },
  '97350': {
    country: 'US',
    city: 'Idanha',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.7001,
    longitude: -122.074,
    postal_code: '97350'
  },
  '97351': {
    country: 'US',
    city: 'Independence',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Polk',
    latitude: 44.8481,
    longitude: -123.1879,
    postal_code: '97351'
  },
  '97352': {
    country: 'US',
    city: 'Jefferson',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.7495,
    longitude: -123.006,
    postal_code: '97352'
  },
  '97355': {
    country: 'US',
    city: 'Lebanon',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.5316,
    longitude: -122.8821,
    postal_code: '97355'
  },
  '97357': {
    country: 'US',
    city: 'Logsden',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.7432,
    longitude: -123.7943,
    postal_code: '97357'
  },
  '97358': {
    country: 'US',
    city: 'Lyons',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.7499,
    longitude: -122.595,
    postal_code: '97358'
  },
  '97360': {
    country: 'US',
    city: 'Mill City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.7516,
    longitude: -122.4768,
    postal_code: '97360'
  },
  '97361': {
    country: 'US',
    city: 'Monmouth',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Polk',
    latitude: 44.8377,
    longitude: -123.2512,
    postal_code: '97361'
  },
  '97362': {
    country: 'US',
    city: 'Mount Angel',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 45.0737,
    longitude: -122.7856,
    postal_code: '97362'
  },
  '97364': {
    country: 'US',
    city: 'Neotsu',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.9988,
    longitude: -123.9843,
    postal_code: '97364'
  },
  '97365': {
    country: 'US',
    city: 'Newport',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.6487,
    longitude: -124.0509,
    postal_code: '97365'
  },
  '97366': {
    country: 'US',
    city: 'South Beach',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.5712,
    longitude: -124.06,
    postal_code: '97366'
  },
  '97367': {
    country: 'US',
    city: 'Lincoln City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.9089,
    longitude: -123.9888,
    postal_code: '97367'
  },
  '97368': {
    country: 'US',
    city: 'Otis',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 45.0138,
    longitude: -123.9332,
    postal_code: '97368'
  },
  '97369': {
    country: 'US',
    city: 'Otter Rock',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.7588,
    longitude: -124.0633,
    postal_code: '97369'
  },
  '97370': {
    country: 'US',
    city: 'Philomath',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Benton',
    latitude: 44.5488,
    longitude: -123.3923,
    postal_code: '97370'
  },
  '97371': {
    country: 'US',
    city: 'Rickreall',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Polk',
    latitude: 45.02,
    longitude: -123.2064,
    postal_code: '97371'
  },
  '97373': {
    country: 'US',
    city: 'Saint Benedict',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9845,
    longitude: -122.457,
    postal_code: '97373'
  },
  '97374': {
    country: 'US',
    city: 'Scio',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.7168,
    longitude: -122.7684,
    postal_code: '97374'
  },
  '97375': {
    country: 'US',
    city: 'Scotts Mills',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.9897,
    longitude: -122.6187,
    postal_code: '97375'
  },
  '97376': {
    country: 'US',
    city: 'Seal Rock',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.4874,
    longitude: -123.9567,
    postal_code: '97376'
  },
  '97377': {
    country: 'US',
    city: 'Shedd',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.453,
    longitude: -123.1065,
    postal_code: '97377'
  },
  '97378': {
    country: 'US',
    city: 'Sheridan',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Yamhill',
    latitude: 45.0897,
    longitude: -123.4003,
    postal_code: '97378'
  },
  '97380': {
    country: 'US',
    city: 'Siletz',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.7313,
    longitude: -123.9062,
    postal_code: '97380'
  },
  '97381': {
    country: 'US',
    city: 'Silverton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.991,
    longitude: -122.7627,
    postal_code: '97381'
  },
  '97383': {
    country: 'US',
    city: 'Stayton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.8021,
    longitude: -122.7624,
    postal_code: '97383'
  },
  '97384': {
    country: 'US',
    city: 'Mehama',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.7903,
    longitude: -122.6187,
    postal_code: '97384'
  },
  '97385': {
    country: 'US',
    city: 'Sublimity',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.8425,
    longitude: -122.8007,
    postal_code: '97385'
  },
  '97386': {
    country: 'US',
    city: 'Sweet Home',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.3981,
    longitude: -122.7286,
    postal_code: '97386'
  },
  '97388': {
    country: 'US',
    city: 'Gleneden Beach',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.8812,
    longitude: -124.0343,
    postal_code: '97388'
  },
  '97389': {
    country: 'US',
    city: 'Tangent',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.5497,
    longitude: -123.1108,
    postal_code: '97389'
  },
  '97390': {
    country: 'US',
    city: 'Tidewater',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.4055,
    longitude: -123.9149,
    postal_code: '97390'
  },
  '97391': {
    country: 'US',
    city: 'Toledo',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.6271,
    longitude: -123.9301,
    postal_code: '97391'
  },
  '97392': {
    country: 'US',
    city: 'Turner',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Marion',
    latitude: 44.8476,
    longitude: -122.9501,
    postal_code: '97392'
  },
  '97394': {
    country: 'US',
    city: 'Waldport',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.4085,
    longitude: -124.0351,
    postal_code: '97394'
  },
  '97396': {
    country: 'US',
    city: 'Willamina',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Yamhill',
    latitude: 45.0826,
    longitude: -123.5047,
    postal_code: '97396'
  },
  '97401': {
    country: 'US',
    city: 'Eugene',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0682,
    longitude: -123.0819,
    postal_code: '97401'
  },
  '97402': {
    country: 'US',
    city: 'Eugene',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0612,
    longitude: -123.1555,
    postal_code: '97402'
  },
  '97403': {
    country: 'US',
    city: 'Eugene',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0385,
    longitude: -123.0614,
    postal_code: '97403'
  },
  '97404': {
    country: 'US',
    city: 'Eugene',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.1005,
    longitude: -123.1334,
    postal_code: '97404'
  },
  '97405': {
    country: 'US',
    city: 'Eugene',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0185,
    longitude: -123.0998,
    postal_code: '97405'
  },
  '97406': {
    country: 'US',
    city: 'Agness',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Curry',
    latitude: 42.5748,
    longitude: -124.0648,
    postal_code: '97406'
  },
  '97407': {
    country: 'US',
    city: 'Allegany',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Coos',
    latitude: 43.4259,
    longitude: -124.0323,
    postal_code: '97407'
  },
  '97408': {
    country: 'US',
    city: 'Eugene',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.1445,
    longitude: -123.0545,
    postal_code: '97408'
  },
  '97409': {
    country: 'US',
    city: 'Alvadore',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.1276,
    longitude: -123.2666,
    postal_code: '97409'
  },
  '97410': {
    country: 'US',
    city: 'Azalea',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 42.845,
    longitude: -123.155,
    postal_code: '97410'
  },
  '97411': {
    country: 'US',
    city: 'Bandon',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Coos',
    latitude: 43.0968,
    longitude: -124.4037,
    postal_code: '97411'
  },
  '97412': {
    country: 'US',
    city: 'Blachly',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.1966,
    longitude: -123.5348,
    postal_code: '97412'
  },
  '97413': {
    country: 'US',
    city: 'Blue River',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.1755,
    longitude: -122.1217,
    postal_code: '97413'
  },
  '97414': {
    country: 'US',
    city: 'Broadbent',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Coos',
    latitude: 42.985,
    longitude: -124.1189,
    postal_code: '97414'
  },
  '97415': {
    country: 'US',
    city: 'Brookings',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Curry',
    latitude: 42.0526,
    longitude: -124.284,
    postal_code: '97415'
  },
  '97416': {
    country: 'US',
    city: 'Camas Valley',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.0557,
    longitude: -123.6655,
    postal_code: '97416'
  },
  '97417': {
    country: 'US',
    city: 'Canyonville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 42.9307,
    longitude: -123.278,
    postal_code: '97417'
  },
  '97419': {
    country: 'US',
    city: 'Cheshire',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.1782,
    longitude: -123.3715,
    postal_code: '97419'
  },
  '97420': {
    country: 'US',
    city: 'Coos Bay',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Coos',
    latitude: 43.2151,
    longitude: -124.1984,
    postal_code: '97420'
  },
  '97423': {
    country: 'US',
    city: 'Coquille',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Coos',
    latitude: 43.1884,
    longitude: -124.2014,
    postal_code: '97423'
  },
  '97424': {
    country: 'US',
    city: 'Cottage Grove',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.7839,
    longitude: -123.0529,
    postal_code: '97424'
  },
  '97426': {
    country: 'US',
    city: 'Creswell',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.9058,
    longitude: -123.0284,
    postal_code: '97426'
  },
  '97429': {
    country: 'US',
    city: 'Days Creek',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 42.9819,
    longitude: -123.1439,
    postal_code: '97429'
  },
  '97430': {
    country: 'US',
    city: 'Deadwood',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.1462,
    longitude: -123.6816,
    postal_code: '97430'
  },
  '97431': {
    country: 'US',
    city: 'Dexter',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.9217,
    longitude: -122.8424,
    postal_code: '97431'
  },
  '97432': {
    country: 'US',
    city: 'Dillard',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.1135,
    longitude: -123.4181,
    postal_code: '97432'
  },
  '97434': {
    country: 'US',
    city: 'Dorena',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.7361,
    longitude: -122.8946,
    postal_code: '97434'
  },
  '97435': {
    country: 'US',
    city: 'Drain',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.6877,
    longitude: -123.2929,
    postal_code: '97435'
  },
  '97436': {
    country: 'US',
    city: 'Elkton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.6378,
    longitude: -123.59,
    postal_code: '97436'
  },
  '97437': {
    country: 'US',
    city: 'Elmira',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0873,
    longitude: -123.3671,
    postal_code: '97437'
  },
  '97438': {
    country: 'US',
    city: 'Fall Creek',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.9509,
    longitude: -122.6884,
    postal_code: '97438'
  },
  '97439': {
    country: 'US',
    city: 'Florence',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.9881,
    longitude: -124.0993,
    postal_code: '97439'
  },
  '97440': {
    country: 'US',
    city: 'Eugene',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0521,
    longitude: -123.0868,
    postal_code: '97440'
  },
  '97441': {
    country: 'US',
    city: 'Gardiner',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.7301,
    longitude: -124.1104,
    postal_code: '97441'
  },
  '97442': {
    country: 'US',
    city: 'Glendale',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 42.7518,
    longitude: -123.3943,
    postal_code: '97442'
  },
  '97443': {
    country: 'US',
    city: 'Glide',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.3015,
    longitude: -123.1012,
    postal_code: '97443'
  },
  '97444': {
    country: 'US',
    city: 'Gold Beach',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Curry',
    latitude: 42.4073,
    longitude: -124.4218,
    postal_code: '97444'
  },
  '97446': {
    country: 'US',
    city: 'Harrisburg',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Linn',
    latitude: 44.2717,
    longitude: -123.1432,
    postal_code: '97446'
  },
  '97447': {
    country: 'US',
    city: 'Idleyld Park',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.3237,
    longitude: -123.024,
    postal_code: '97447'
  },
  '97448': {
    country: 'US',
    city: 'Junction City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.1988,
    longitude: -123.23,
    postal_code: '97448'
  },
  '97449': {
    country: 'US',
    city: 'Lakeside',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Coos',
    latitude: 43.5833,
    longitude: -124.1624,
    postal_code: '97449'
  },
  '97450': {
    country: 'US',
    city: 'Langlois',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Curry',
    latitude: 42.9154,
    longitude: -124.4413,
    postal_code: '97450'
  },
  '97451': {
    country: 'US',
    city: 'Lorane',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.8376,
    longitude: -123.239,
    postal_code: '97451'
  },
  '97452': {
    country: 'US',
    city: 'Lowell',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.921,
    longitude: -122.7806,
    postal_code: '97452'
  },
  '97453': {
    country: 'US',
    city: 'Mapleton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0312,
    longitude: -123.8657,
    postal_code: '97453'
  },
  '97454': {
    country: 'US',
    city: 'Marcola',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.2064,
    longitude: -122.8246,
    postal_code: '97454'
  },
  '97455': {
    country: 'US',
    city: 'Pleasant Hill',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.9458,
    longitude: -122.9285,
    postal_code: '97455'
  },
  '97456': {
    country: 'US',
    city: 'Monroe',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Benton',
    latitude: 44.3245,
    longitude: -123.3203,
    postal_code: '97456'
  },
  '97457': {
    country: 'US',
    city: 'Myrtle Creek',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.0162,
    longitude: -123.2851,
    postal_code: '97457'
  },
  '97458': {
    country: 'US',
    city: 'Myrtle Point',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Coos',
    latitude: 43.0667,
    longitude: -124.1213,
    postal_code: '97458'
  },
  '97459': {
    country: 'US',
    city: 'North Bend',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Coos',
    latitude: 43.4327,
    longitude: -124.2131,
    postal_code: '97459'
  },
  '97461': {
    country: 'US',
    city: 'Noti',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0992,
    longitude: -123.4684,
    postal_code: '97461'
  },
  '97462': {
    country: 'US',
    city: 'Oakland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.453,
    longitude: -123.3558,
    postal_code: '97462'
  },
  '97463': {
    country: 'US',
    city: 'Oakridge',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.7376,
    longitude: -122.4377,
    postal_code: '97463'
  },
  '97464': {
    country: 'US',
    city: 'Ophir',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Curry',
    latitude: 42.5632,
    longitude: -124.3829,
    postal_code: '97464'
  },
  '97465': {
    country: 'US',
    city: 'Port Orford',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Curry',
    latitude: 42.7572,
    longitude: -124.4913,
    postal_code: '97465'
  },
  '97466': {
    country: 'US',
    city: 'Powers',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Coos',
    latitude: 42.88,
    longitude: -124.0709,
    postal_code: '97466'
  },
  '97467': {
    country: 'US',
    city: 'Reedsport',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.7023,
    longitude: -124.0968,
    postal_code: '97467'
  },
  '97469': {
    country: 'US',
    city: 'Riddle',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 42.9389,
    longitude: -123.3612,
    postal_code: '97469'
  },
  '97470': {
    country: 'US',
    city: 'Roseburg',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.2297,
    longitude: -123.2343,
    postal_code: '97470'
  },
  '97471': {
    country: 'US',
    city: 'Roseburg',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.227,
    longitude: -123.5158,
    postal_code: '97471'
  },
  '97473': {
    country: 'US',
    city: 'Scottsburg',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.6765,
    longitude: -123.8041,
    postal_code: '97473'
  },
  '97475': {
    country: 'US',
    city: 'Springfield',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0462,
    longitude: -123.022,
    postal_code: '97475'
  },
  '97476': {
    country: 'US',
    city: 'Sixes',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Curry',
    latitude: 42.825,
    longitude: -124.4409,
    postal_code: '97476'
  },
  '97477': {
    country: 'US',
    city: 'Springfield',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0611,
    longitude: -123.0153,
    postal_code: '97477'
  },
  '97478': {
    country: 'US',
    city: 'Springfield',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0561,
    longitude: -122.9171,
    postal_code: '97478'
  },
  '97479': {
    country: 'US',
    city: 'Sutherlin',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.3904,
    longitude: -123.2974,
    postal_code: '97479'
  },
  '97480': {
    country: 'US',
    city: 'Swisshome',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0899,
    longitude: -123.8279,
    postal_code: '97480'
  },
  '97481': {
    country: 'US',
    city: 'Tenmile',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.114,
    longitude: -123.5294,
    postal_code: '97481'
  },
  '97484': {
    country: 'US',
    city: 'Tiller',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 42.9177,
    longitude: -122.9079,
    postal_code: '97484'
  },
  '97486': {
    country: 'US',
    city: 'Umpqua',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.3745,
    longitude: -123.5358,
    postal_code: '97486'
  },
  '97487': {
    country: 'US',
    city: 'Veneta',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0382,
    longitude: -123.3516,
    postal_code: '97487'
  },
  '97488': {
    country: 'US',
    city: 'Vida',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.13,
    longitude: -122.5044,
    postal_code: '97488'
  },
  '97489': {
    country: 'US',
    city: 'Walterville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.1288,
    longitude: -122.6376,
    postal_code: '97489'
  },
  '97490': {
    country: 'US',
    city: 'Walton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 44.0282,
    longitude: -123.5893,
    postal_code: '97490'
  },
  '97491': {
    country: 'US',
    city: 'Wedderburn',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Curry',
    latitude: 42.6093,
    longitude: -124.3886,
    postal_code: '97491'
  },
  '97492': {
    country: 'US',
    city: 'Westfir',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.7575,
    longitude: -122.481,
    postal_code: '97492'
  },
  '97493': {
    country: 'US',
    city: 'Westlake',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lane',
    latitude: 43.914,
    longitude: -124.0334,
    postal_code: '97493'
  },
  '97494': {
    country: 'US',
    city: 'Wilbur',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.3284,
    longitude: -123.3332,
    postal_code: '97494'
  },
  '97495': {
    country: 'US',
    city: 'Winchester',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.2878,
    longitude: -123.3055,
    postal_code: '97495'
  },
  '97496': {
    country: 'US',
    city: 'Winston',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.1049,
    longitude: -123.4325,
    postal_code: '97496'
  },
  '97497': {
    country: 'US',
    city: 'Wolf Creek',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.6522,
    longitude: -123.425,
    postal_code: '97497'
  },
  '97498': {
    country: 'US',
    city: 'Yachats',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lincoln',
    latitude: 44.3256,
    longitude: -124.0863,
    postal_code: '97498'
  },
  '97499': {
    country: 'US',
    city: 'Yoncalla',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Douglas',
    latitude: 43.6043,
    longitude: -123.2926,
    postal_code: '97499'
  },
  '97501': {
    country: 'US',
    city: 'Medford',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.2818,
    longitude: -122.9054,
    postal_code: '97501'
  },
  '97502': {
    country: 'US',
    city: 'Central Point',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.3899,
    longitude: -122.9222,
    postal_code: '97502'
  },
  '97503': {
    country: 'US',
    city: 'White City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.4319,
    longitude: -122.8296,
    postal_code: '97503'
  },
  '97504': {
    country: 'US',
    city: 'Medford',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.3363,
    longitude: -122.8398,
    postal_code: '97504'
  },
  '97520': {
    country: 'US',
    city: 'Ashland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.1885,
    longitude: -122.693,
    postal_code: '97520'
  },
  '97522': {
    country: 'US',
    city: 'Butte Falls',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.5492,
    longitude: -122.5638,
    postal_code: '97522'
  },
  '97523': {
    country: 'US',
    city: 'Cave Junction',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.1348,
    longitude: -123.6272,
    postal_code: '97523'
  },
  '97524': {
    country: 'US',
    city: 'Eagle Point',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.4935,
    longitude: -122.8088,
    postal_code: '97524'
  },
  '97525': {
    country: 'US',
    city: 'Gold Hill',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.4244,
    longitude: -123.0854,
    postal_code: '97525'
  },
  '97526': {
    country: 'US',
    city: 'Grants Pass',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.4638,
    longitude: -123.3457,
    postal_code: '97526'
  },
  '97527': {
    country: 'US',
    city: 'Grants Pass',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.3989,
    longitude: -123.3538,
    postal_code: '97527'
  },
  '97528': {
    country: 'US',
    city: 'Grants Pass',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.4393,
    longitude: -123.3307,
    postal_code: '97528'
  },
  '97530': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.1813,
    longitude: -123.0241,
    postal_code: '97530'
  },
  '97531': {
    country: 'US',
    city: 'Kerby',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.2093,
    longitude: -123.6573,
    postal_code: '97531'
  },
  '97532': {
    country: 'US',
    city: 'Merlin',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.5297,
    longitude: -123.4393,
    postal_code: '97532'
  },
  '97533': {
    country: 'US',
    city: 'Murphy',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.308,
    longitude: -123.3273,
    postal_code: '97533'
  },
  '97534': {
    country: 'US',
    city: 'O Brien',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.0879,
    longitude: -123.689,
    postal_code: '97534'
  },
  '97535': {
    country: 'US',
    city: 'Phoenix',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.2766,
    longitude: -122.8227,
    postal_code: '97535'
  },
  '97536': {
    country: 'US',
    city: 'Prospect',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.7544,
    longitude: -122.509,
    postal_code: '97536'
  },
  '97537': {
    country: 'US',
    city: 'Rogue River',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.4889,
    longitude: -123.1587,
    postal_code: '97537'
  },
  '97538': {
    country: 'US',
    city: 'Selma',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.2654,
    longitude: -123.5515,
    postal_code: '97538'
  },
  '97539': {
    country: 'US',
    city: 'Shady Cove',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.6107,
    longitude: -122.8125,
    postal_code: '97539'
  },
  '97540': {
    country: 'US',
    city: 'Talent',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.2363,
    longitude: -122.7861,
    postal_code: '97540'
  },
  '97541': {
    country: 'US',
    city: 'Trail',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jackson',
    latitude: 42.6864,
    longitude: -122.816,
    postal_code: '97541'
  },
  '97543': {
    country: 'US',
    city: 'Wilderville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.3751,
    longitude: -123.5621,
    postal_code: '97543'
  },
  '97544': {
    country: 'US',
    city: 'Williams',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Josephine',
    latitude: 42.223,
    longitude: -123.2829,
    postal_code: '97544'
  },
  '97601': {
    country: 'US',
    city: 'Klamath Falls',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.2933,
    longitude: -121.8169,
    postal_code: '97601'
  },
  '97602': {
    country: 'US',
    city: 'Klamath Falls',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.2249,
    longitude: -121.7817,
    postal_code: '97602'
  },
  '97603': {
    country: 'US',
    city: 'Klamath Falls',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.1919,
    longitude: -121.7241,
    postal_code: '97603'
  },
  '97604': {
    country: 'US',
    city: 'Crater Lake',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.9465,
    longitude: -122.1769,
    postal_code: '97604'
  },
  '97620': {
    country: 'US',
    city: 'Adel',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lake',
    latitude: 42.1487,
    longitude: -119.8833,
    postal_code: '97620'
  },
  '97621': {
    country: 'US',
    city: 'Beatty',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.4369,
    longitude: -121.22,
    postal_code: '97621'
  },
  '97622': {
    country: 'US',
    city: 'Bly',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.3995,
    longitude: -121.0806,
    postal_code: '97622'
  },
  '97623': {
    country: 'US',
    city: 'Bonanza',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.2463,
    longitude: -121.3336,
    postal_code: '97623'
  },
  '97624': {
    country: 'US',
    city: 'Chiloquin',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.5463,
    longitude: -121.745,
    postal_code: '97624'
  },
  '97625': {
    country: 'US',
    city: 'Dairy',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.2351,
    longitude: -121.5211,
    postal_code: '97625'
  },
  '97626': {
    country: 'US',
    city: 'Fort Klamath',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.6415,
    longitude: -122.038,
    postal_code: '97626'
  },
  '97627': {
    country: 'US',
    city: 'Keno',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.1265,
    longitude: -121.93,
    postal_code: '97627'
  },
  '97630': {
    country: 'US',
    city: 'Lakeview',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lake',
    latitude: 42.1854,
    longitude: -120.3775,
    postal_code: '97630'
  },
  '97632': {
    country: 'US',
    city: 'Malin',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.0195,
    longitude: -121.4221,
    postal_code: '97632'
  },
  '97633': {
    country: 'US',
    city: 'Merrill',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.0295,
    longitude: -121.5985,
    postal_code: '97633'
  },
  '97634': {
    country: 'US',
    city: 'Midland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.1307,
    longitude: -121.8203,
    postal_code: '97634'
  },
  '97635': {
    country: 'US',
    city: 'New Pine Creek',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lake',
    latitude: 41.9941,
    longitude: -120.2972,
    postal_code: '97635'
  },
  '97636': {
    country: 'US',
    city: 'Paisley',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lake',
    latitude: 42.7031,
    longitude: -120.5532,
    postal_code: '97636'
  },
  '97637': {
    country: 'US',
    city: 'Plush',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lake',
    latitude: 42.5035,
    longitude: -119.8947,
    postal_code: '97637'
  },
  '97638': {
    country: 'US',
    city: 'Silver Lake',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lake',
    latitude: 43.1395,
    longitude: -120.9984,
    postal_code: '97638'
  },
  '97639': {
    country: 'US',
    city: 'Sprague River',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 42.4514,
    longitude: -121.4365,
    postal_code: '97639'
  },
  '97640': {
    country: 'US',
    city: 'Summer Lake',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lake',
    latitude: 42.9447,
    longitude: -120.7946,
    postal_code: '97640'
  },
  '97641': {
    country: 'US',
    city: 'Christmas Valley',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lake',
    latitude: 43.2363,
    longitude: -120.6369,
    postal_code: '97641'
  },
  '97701': {
    country: 'US',
    city: 'Bend',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 44.0928,
    longitude: -121.2936,
    postal_code: '97701'
  },
  '97702': {
    country: 'US',
    city: 'Bend',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 44.0223,
    longitude: -121.2985,
    postal_code: '97702'
  },
  '97703': {
    country: 'US',
    city: 'Bend',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 44.0915,
    longitude: -121.4073,
    postal_code: '97703'
  },
  '97707': {
    country: 'US',
    city: 'Bend',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 43.8431,
    longitude: -121.5764,
    postal_code: '97707'
  },
  '97708': {
    country: 'US',
    city: 'Bend',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 44.0582,
    longitude: -121.3153,
    postal_code: '97708'
  },
  '97709': {
    country: 'US',
    city: 'Bend',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 44.0582,
    longitude: -121.3153,
    postal_code: '97709'
  },
  '97710': {
    country: 'US',
    city: 'Fields',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Harney',
    latitude: 42.1862,
    longitude: -118.4758,
    postal_code: '97710'
  },
  '97711': {
    country: 'US',
    city: 'Ashwood',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jefferson',
    latitude: 44.7195,
    longitude: -120.7192,
    postal_code: '97711'
  },
  '97712': {
    country: 'US',
    city: 'Brothers',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 43.7789,
    longitude: -120.477,
    postal_code: '97712'
  },
  '97720': {
    country: 'US',
    city: 'Burns',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Harney',
    latitude: 43.5145,
    longitude: -119.0504,
    postal_code: '97720'
  },
  '97721': {
    country: 'US',
    city: 'Princeton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Harney',
    latitude: 43.0198,
    longitude: -119.0696,
    postal_code: '97721'
  },
  '97722': {
    country: 'US',
    city: 'Diamond',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Harney',
    latitude: 42.9508,
    longitude: -118.695,
    postal_code: '97722'
  },
  '97730': {
    country: 'US',
    city: 'Camp Sherman',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jefferson',
    latitude: 44.4604,
    longitude: -121.6412,
    postal_code: '97730'
  },
  '97731': {
    country: 'US',
    city: 'Chemult',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 43.146,
    longitude: -121.7974,
    postal_code: '97731'
  },
  '97732': {
    country: 'US',
    city: 'Crane',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Harney',
    latitude: 43.4262,
    longitude: -118.4642,
    postal_code: '97732'
  },
  '97733': {
    country: 'US',
    city: 'Crescent',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 43.4442,
    longitude: -121.7109,
    postal_code: '97733'
  },
  '97734': {
    country: 'US',
    city: 'Culver',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jefferson',
    latitude: 44.4818,
    longitude: -121.2344,
    postal_code: '97734'
  },
  '97735': {
    country: 'US',
    city: 'Fort Rock',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Lake',
    latitude: 43.3565,
    longitude: -121.0539,
    postal_code: '97735'
  },
  '97736': {
    country: 'US',
    city: 'Frenchglen',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Harney',
    latitude: 42.7147,
    longitude: -119.0058,
    postal_code: '97736'
  },
  '97737': {
    country: 'US',
    city: 'Gilchrist',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Klamath',
    latitude: 43.4771,
    longitude: -121.6853,
    postal_code: '97737'
  },
  '97738': {
    country: 'US',
    city: 'Hines',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Harney',
    latitude: 43.5558,
    longitude: -119.0816,
    postal_code: '97738'
  },
  '97739': {
    country: 'US',
    city: 'La Pine',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 43.7091,
    longitude: -121.5194,
    postal_code: '97739'
  },
  '97741': {
    country: 'US',
    city: 'Madras',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jefferson',
    latitude: 44.6376,
    longitude: -121.1349,
    postal_code: '97741'
  },
  '97750': {
    country: 'US',
    city: 'Mitchell',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wheeler',
    latitude: 44.5657,
    longitude: -120.1456,
    postal_code: '97750'
  },
  '97751': {
    country: 'US',
    city: 'Paulina',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Crook',
    latitude: 44.2102,
    longitude: -119.7828,
    postal_code: '97751'
  },
  '97752': {
    country: 'US',
    city: 'Post',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Crook',
    latitude: 44.0793,
    longitude: -120.2998,
    postal_code: '97752'
  },
  '97753': {
    country: 'US',
    city: 'Powell Butte',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Crook',
    latitude: 44.2415,
    longitude: -121.0113,
    postal_code: '97753'
  },
  '97754': {
    country: 'US',
    city: 'Prineville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Crook',
    latitude: 44.3045,
    longitude: -120.8336,
    postal_code: '97754'
  },
  '97756': {
    country: 'US',
    city: 'Redmond',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 44.2767,
    longitude: -121.1896,
    postal_code: '97756'
  },
  '97758': {
    country: 'US',
    city: 'Riley',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Harney',
    latitude: 43.3886,
    longitude: -119.8798,
    postal_code: '97758'
  },
  '97759': {
    country: 'US',
    city: 'Sisters',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 44.3165,
    longitude: -121.5091,
    postal_code: '97759'
  },
  '97760': {
    country: 'US',
    city: 'Terrebonne',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Deschutes',
    latitude: 44.3909,
    longitude: -121.2379,
    postal_code: '97760'
  },
  '97761': {
    country: 'US',
    city: 'Warm Springs',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Jefferson',
    latitude: 44.7468,
    longitude: -121.2909,
    postal_code: '97761'
  },
  '97801': {
    country: 'US',
    city: 'Pendleton',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.6605,
    longitude: -118.7831,
    postal_code: '97801'
  },
  '97810': {
    country: 'US',
    city: 'Adams',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.7674,
    longitude: -118.5625,
    postal_code: '97810'
  },
  '97812': {
    country: 'US',
    city: 'Arlington',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Gilliam',
    latitude: 45.6664,
    longitude: -120.1971,
    postal_code: '97812'
  },
  '97813': {
    country: 'US',
    city: 'Athena',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.8289,
    longitude: -118.4971,
    postal_code: '97813'
  },
  '97814': {
    country: 'US',
    city: 'Baker City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.7749,
    longitude: -117.8344,
    postal_code: '97814'
  },
  '97817': {
    country: 'US',
    city: 'Bates',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Grant',
    latitude: 44.7138,
    longitude: -118.6469,
    postal_code: '97817'
  },
  '97818': {
    country: 'US',
    city: 'Boardman',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Morrow',
    latitude: 45.8272,
    longitude: -119.7206,
    postal_code: '97818'
  },
  '97819': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.4931,
    longitude: -117.7655,
    postal_code: '97819'
  },
  '97820': {
    country: 'US',
    city: 'Canyon City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Grant',
    latitude: 44.3911,
    longitude: -118.9465,
    postal_code: '97820'
  },
  '97823': {
    country: 'US',
    city: 'Condon',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Gilliam',
    latitude: 45.2306,
    longitude: -120.1898,
    postal_code: '97823'
  },
  '97824': {
    country: 'US',
    city: 'Cove',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Union',
    latitude: 45.3199,
    longitude: -117.8147,
    postal_code: '97824'
  },
  '97825': {
    country: 'US',
    city: 'Dayville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Grant',
    latitude: 44.4663,
    longitude: -119.5312,
    postal_code: '97825'
  },
  '97826': {
    country: 'US',
    city: 'Echo',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.7411,
    longitude: -119.1949,
    postal_code: '97826'
  },
  '97827': {
    country: 'US',
    city: 'Elgin',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Union',
    latitude: 45.5941,
    longitude: -117.9112,
    postal_code: '97827'
  },
  '97828': {
    country: 'US',
    city: 'Enterprise',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wallowa',
    latitude: 45.437,
    longitude: -117.2888,
    postal_code: '97828'
  },
  '97830': {
    country: 'US',
    city: 'Fossil',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wheeler',
    latitude: 44.9286,
    longitude: -120.1309,
    postal_code: '97830'
  },
  '97833': {
    country: 'US',
    city: 'Haines',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.8776,
    longitude: -117.9756,
    postal_code: '97833'
  },
  '97834': {
    country: 'US',
    city: 'Halfway',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.8953,
    longitude: -117.1132,
    postal_code: '97834'
  },
  '97835': {
    country: 'US',
    city: 'Helix',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.8665,
    longitude: -118.7222,
    postal_code: '97835'
  },
  '97836': {
    country: 'US',
    city: 'Heppner',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Morrow',
    latitude: 45.3486,
    longitude: -119.5369,
    postal_code: '97836'
  },
  '97837': {
    country: 'US',
    city: 'Hereford',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.5304,
    longitude: -118.0293,
    postal_code: '97837'
  },
  '97838': {
    country: 'US',
    city: 'Hermiston',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.845,
    longitude: -119.2849,
    postal_code: '97838'
  },
  '97839': {
    country: 'US',
    city: 'Lexington',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Morrow',
    latitude: 45.4264,
    longitude: -119.7464,
    postal_code: '97839'
  },
  '97840': {
    country: 'US',
    city: 'Oxbow',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.9749,
    longitude: -116.8629,
    postal_code: '97840'
  },
  '97841': {
    country: 'US',
    city: 'Imbler',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Union',
    latitude: 45.4599,
    longitude: -117.9544,
    postal_code: '97841'
  },
  '97842': {
    country: 'US',
    city: 'Imnaha',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wallowa',
    latitude: 45.5137,
    longitude: -116.8257,
    postal_code: '97842'
  },
  '97843': {
    country: 'US',
    city: 'Ione',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Morrow',
    latitude: 45.5403,
    longitude: -119.769,
    postal_code: '97843'
  },
  '97844': {
    country: 'US',
    city: 'Irrigon',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Morrow',
    latitude: 45.8877,
    longitude: -119.507,
    postal_code: '97844'
  },
  '97845': {
    country: 'US',
    city: 'John Day',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Grant',
    latitude: 44.416,
    longitude: -118.953,
    postal_code: '97845'
  },
  '97846': {
    country: 'US',
    city: 'Joseph',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wallowa',
    latitude: 45.3494,
    longitude: -117.2128,
    postal_code: '97846'
  },
  '97848': {
    country: 'US',
    city: 'Kimberly',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Grant',
    latitude: 44.7226,
    longitude: -119.5965,
    postal_code: '97848'
  },
  '97850': {
    country: 'US',
    city: 'La Grande',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Union',
    latitude: 45.3304,
    longitude: -118.0852,
    postal_code: '97850'
  },
  '97856': {
    country: 'US',
    city: 'Long Creek',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Grant',
    latitude: 44.7554,
    longitude: -119.097,
    postal_code: '97856'
  },
  '97857': {
    country: 'US',
    city: 'Lostine',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wallowa',
    latitude: 45.4939,
    longitude: -117.4359,
    postal_code: '97857'
  },
  '97859': {
    country: 'US',
    city: 'Meacham',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.5215,
    longitude: -118.4335,
    postal_code: '97859'
  },
  '97861': {
    country: 'US',
    city: 'Mikkalo',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Gilliam',
    latitude: 45.4388,
    longitude: -120.2067,
    postal_code: '97861'
  },
  '97862': {
    country: 'US',
    city: 'Milton Freewater',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.9486,
    longitude: -118.3912,
    postal_code: '97862'
  },
  '97864': {
    country: 'US',
    city: 'Monument',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Grant',
    latitude: 44.8189,
    longitude: -119.4192,
    postal_code: '97864'
  },
  '97865': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Grant',
    latitude: 44.4171,
    longitude: -119.1121,
    postal_code: '97865'
  },
  '97867': {
    country: 'US',
    city: 'North Powder',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Union',
    latitude: 45.0317,
    longitude: -117.9337,
    postal_code: '97867'
  },
  '97868': {
    country: 'US',
    city: 'Pilot Rock',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.4227,
    longitude: -118.8483,
    postal_code: '97868'
  },
  '97869': {
    country: 'US',
    city: 'Prairie City',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Grant',
    latitude: 44.457,
    longitude: -118.6952,
    postal_code: '97869'
  },
  '97870': {
    country: 'US',
    city: 'Richland',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.797,
    longitude: -117.3018,
    postal_code: '97870'
  },
  '97873': {
    country: 'US',
    city: 'Seneca',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Grant',
    latitude: 44.1346,
    longitude: -118.9716,
    postal_code: '97873'
  },
  '97874': {
    country: 'US',
    city: 'Spray',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wheeler',
    latitude: 44.8242,
    longitude: -119.8303,
    postal_code: '97874'
  },
  '97875': {
    country: 'US',
    city: 'Stanfield',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.786,
    longitude: -119.2116,
    postal_code: '97875'
  },
  '97876': {
    country: 'US',
    city: 'Summerville',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Union',
    latitude: 45.5077,
    longitude: -118.027,
    postal_code: '97876'
  },
  '97877': {
    country: 'US',
    city: 'Sumpter',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.7457,
    longitude: -118.2022,
    postal_code: '97877'
  },
  '97880': {
    country: 'US',
    city: 'Ukiah',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.0836,
    longitude: -118.894,
    postal_code: '97880'
  },
  '97882': {
    country: 'US',
    city: 'Umatilla',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.8998,
    longitude: -119.3553,
    postal_code: '97882'
  },
  '97883': {
    country: 'US',
    city: 'Union',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Union',
    latitude: 45.2019,
    longitude: -117.8536,
    postal_code: '97883'
  },
  '97884': {
    country: 'US',
    city: 'Unity',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.4374,
    longitude: -118.1927,
    postal_code: '97884'
  },
  '97885': {
    country: 'US',
    city: 'Wallowa',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Wallowa',
    latitude: 45.5717,
    longitude: -117.5357,
    postal_code: '97885'
  },
  '97886': {
    country: 'US',
    city: 'Weston',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Umatilla',
    latitude: 45.8074,
    longitude: -118.3733,
    postal_code: '97886'
  },
  '97901': {
    country: 'US',
    city: 'Adrian',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 43.6537,
    longitude: -117.0602,
    postal_code: '97901'
  },
  '97902': {
    country: 'US',
    city: 'Arock',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 42.9156,
    longitude: -117.498,
    postal_code: '97902'
  },
  '97903': {
    country: 'US',
    city: 'Brogan',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 44.1994,
    longitude: -117.5904,
    postal_code: '97903'
  },
  '97904': {
    country: 'US',
    city: 'Drewsey',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Harney',
    latitude: 43.8643,
    longitude: -118.4707,
    postal_code: '97904'
  },
  '97905': {
    country: 'US',
    city: 'Durkee',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.4988,
    longitude: -117.4129,
    postal_code: '97905'
  },
  '97906': {
    country: 'US',
    city: 'Harper',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 43.8734,
    longitude: -117.5284,
    postal_code: '97906'
  },
  '97907': {
    country: 'US',
    city: 'Huntington',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Baker',
    latitude: 44.3811,
    longitude: -117.3097,
    postal_code: '97907'
  },
  '97908': {
    country: 'US',
    city: 'Ironside',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 44.3009,
    longitude: -117.9444,
    postal_code: '97908'
  },
  '97909': {
    country: 'US',
    city: 'Jamieson',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 44.2161,
    longitude: -117.4813,
    postal_code: '97909'
  },
  '97910': {
    country: 'US',
    city: 'Jordan Valley',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 42.8801,
    longitude: -117.281,
    postal_code: '97910'
  },
  '97911': {
    country: 'US',
    city: 'Juntura',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 43.8253,
    longitude: -118.0921,
    postal_code: '97911'
  },
  '97913': {
    country: 'US',
    city: 'Nyssa',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 43.8604,
    longitude: -117.0251,
    postal_code: '97913'
  },
  '97914': {
    country: 'US',
    city: 'Ontario',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 44.0416,
    longitude: -116.9783,
    postal_code: '97914'
  },
  '97917': {
    country: 'US',
    city: 'Riverside',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 43.5099,
    longitude: -118.0986,
    postal_code: '97917'
  },
  '97918': {
    country: 'US',
    city: 'Vale',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 44.0039,
    longitude: -117.2674,
    postal_code: '97918'
  },
  '97920': {
    country: 'US',
    city: 'Westfall',
    state: 'Oregon',
    state_short: 'OR',
    county: 'Malheur',
    latitude: 43.9922,
    longitude: -117.6877,
    postal_code: '97920'
  },
  '98001': {
    country: 'US',
    city: 'Auburn',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3099,
    longitude: -122.2653,
    postal_code: '98001'
  },
  '98002': {
    country: 'US',
    city: 'Auburn',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.305,
    longitude: -122.2067,
    postal_code: '98002'
  },
  '98003': {
    country: 'US',
    city: 'Federal Way',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3203,
    longitude: -122.3117,
    postal_code: '98003'
  },
  '98004': {
    country: 'US',
    city: 'Bellevue',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6155,
    longitude: -122.2072,
    postal_code: '98004'
  },
  '98005': {
    country: 'US',
    city: 'Bellevue',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.615,
    longitude: -122.1663,
    postal_code: '98005'
  },
  '98006': {
    country: 'US',
    city: 'Bellevue',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5614,
    longitude: -122.1552,
    postal_code: '98006'
  },
  '98007': {
    country: 'US',
    city: 'Bellevue',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6174,
    longitude: -122.1426,
    postal_code: '98007'
  },
  '98008': {
    country: 'US',
    city: 'Bellevue',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6115,
    longitude: -122.1162,
    postal_code: '98008'
  },
  '98009': {
    country: 'US',
    city: 'Bellevue',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6104,
    longitude: -122.2007,
    postal_code: '98009'
  },
  '98010': {
    country: 'US',
    city: 'Black Diamond',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3114,
    longitude: -122.0053,
    postal_code: '98010'
  },
  '98011': {
    country: 'US',
    city: 'Bothell',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7497,
    longitude: -122.2159,
    postal_code: '98011'
  },
  '98012': {
    country: 'US',
    city: 'Bothell',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8401,
    longitude: -122.1972,
    postal_code: '98012'
  },
  '98013': {
    country: 'US',
    city: 'Burton',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.389,
    longitude: -122.4607,
    postal_code: '98013'
  },
  '98014': {
    country: 'US',
    city: 'Carnation',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.638,
    longitude: -121.9111,
    postal_code: '98014'
  },
  '98015': {
    country: 'US',
    city: 'Bellevue',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6104,
    longitude: -122.2007,
    postal_code: '98015'
  },
  '98019': {
    country: 'US',
    city: 'Duvall',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.725,
    longitude: -121.9369,
    postal_code: '98019'
  },
  '98020': {
    country: 'US',
    city: 'Edmonds',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8061,
    longitude: -122.3724,
    postal_code: '98020'
  },
  '98021': {
    country: 'US',
    city: 'Bothell',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.7918,
    longitude: -122.2243,
    postal_code: '98021'
  },
  '98022': {
    country: 'US',
    city: 'Enumclaw',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.2665,
    longitude: -122.0314,
    postal_code: '98022'
  },
  '98023': {
    country: 'US',
    city: 'Federal Way',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3104,
    longitude: -122.3612,
    postal_code: '98023'
  },
  '98024': {
    country: 'US',
    city: 'Fall City',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5682,
    longitude: -121.8896,
    postal_code: '98024'
  },
  '98025': {
    country: 'US',
    city: 'Hobart',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4218,
    longitude: -121.9729,
    postal_code: '98025'
  },
  '98026': {
    country: 'US',
    city: 'Edmonds',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8353,
    longitude: -122.327,
    postal_code: '98026'
  },
  '98027': {
    country: 'US',
    city: 'Issaquah',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4974,
    longitude: -122.0107,
    postal_code: '98027'
  },
  '98028': {
    country: 'US',
    city: 'Kenmore',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7542,
    longitude: -122.2475,
    postal_code: '98028'
  },
  '98029': {
    country: 'US',
    city: 'Issaquah',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5585,
    longitude: -122.0055,
    postal_code: '98029'
  },
  '98030': {
    country: 'US',
    city: 'Kent',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3866,
    longitude: -122.2109,
    postal_code: '98030'
  },
  '98031': {
    country: 'US',
    city: 'Kent',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.388,
    longitude: -122.1932,
    postal_code: '98031'
  },
  '98032': {
    country: 'US',
    city: 'Kent',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3776,
    longitude: -122.2854,
    postal_code: '98032'
  },
  '98033': {
    country: 'US',
    city: 'Kirkland',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6786,
    longitude: -122.1894,
    postal_code: '98033'
  },
  '98034': {
    country: 'US',
    city: 'Kirkland',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7188,
    longitude: -122.1966,
    postal_code: '98034'
  },
  '98035': {
    country: 'US',
    city: 'Kent',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3809,
    longitude: -122.2348,
    postal_code: '98035'
  },
  '98036': {
    country: 'US',
    city: 'Lynnwood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8049,
    longitude: -122.2855,
    postal_code: '98036'
  },
  '98037': {
    country: 'US',
    city: 'Lynnwood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8392,
    longitude: -122.2855,
    postal_code: '98037'
  },
  '98038': {
    country: 'US',
    city: 'Maple Valley',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3845,
    longitude: -122.0574,
    postal_code: '98038'
  },
  '98039': {
    country: 'US',
    city: 'Medina',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6269,
    longitude: -122.2314,
    postal_code: '98039'
  },
  '98040': {
    country: 'US',
    city: 'Mercer Island',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5603,
    longitude: -122.2281,
    postal_code: '98040'
  },
  '98041': {
    country: 'US',
    city: 'Bothell',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7623,
    longitude: -122.2054,
    postal_code: '98041'
  },
  '98042': {
    country: 'US',
    city: 'Kent',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.368,
    longitude: -122.1206,
    postal_code: '98042'
  },
  '98043': {
    country: 'US',
    city: 'Mountlake Terrace',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.7933,
    longitude: -122.3076,
    postal_code: '98043'
  },
  '98045': {
    country: 'US',
    city: 'North Bend',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4755,
    longitude: -121.7571,
    postal_code: '98045'
  },
  '98046': {
    country: 'US',
    city: 'Lynnwood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8209,
    longitude: -122.3151,
    postal_code: '98046'
  },
  '98047': {
    country: 'US',
    city: 'Pacific',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.2666,
    longitude: -122.2435,
    postal_code: '98047'
  },
  '98050': {
    country: 'US',
    city: 'Preston',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.536,
    longitude: -121.9312,
    postal_code: '98050'
  },
  '98051': {
    country: 'US',
    city: 'Ravensdale',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3407,
    longitude: -121.8874,
    postal_code: '98051'
  },
  '98052': {
    country: 'US',
    city: 'Redmond',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6718,
    longitude: -122.1232,
    postal_code: '98052'
  },
  '98053': {
    country: 'US',
    city: 'Redmond',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6462,
    longitude: -122.0386,
    postal_code: '98053'
  },
  '98055': {
    country: 'US',
    city: 'Renton',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4648,
    longitude: -122.2075,
    postal_code: '98055'
  },
  '98056': {
    country: 'US',
    city: 'Renton',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5073,
    longitude: -122.1819,
    postal_code: '98056'
  },
  '98057': {
    country: 'US',
    city: 'Renton',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4714,
    longitude: -122.2203,
    postal_code: '98057'
  },
  '98058': {
    country: 'US',
    city: 'Renton',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4465,
    longitude: -122.1216,
    postal_code: '98058'
  },
  '98059': {
    country: 'US',
    city: 'Renton',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5058,
    longitude: -122.1157,
    postal_code: '98059'
  },
  '98061': {
    country: 'US',
    city: 'Rollingbay',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.6808,
    longitude: -122.5762,
    postal_code: '98061'
  },
  '98062': {
    country: 'US',
    city: 'Seahurst',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4693,
    longitude: -122.3621,
    postal_code: '98062'
  },
  '98063': {
    country: 'US',
    city: 'Federal Way',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3223,
    longitude: -122.3126,
    postal_code: '98063'
  },
  '98064': {
    country: 'US',
    city: 'Kent',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3809,
    longitude: -122.2348,
    postal_code: '98064'
  },
  '98065': {
    country: 'US',
    city: 'Snoqualmie',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5293,
    longitude: -121.8225,
    postal_code: '98065'
  },
  '98068': {
    country: 'US',
    city: 'Snoqualmie Pass',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4452,
    longitude: -121.431,
    postal_code: '98068'
  },
  '98070': {
    country: 'US',
    city: 'Vashon',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4259,
    longitude: -122.4644,
    postal_code: '98070'
  },
  '98071': {
    country: 'US',
    city: 'Auburn',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3073,
    longitude: -122.2285,
    postal_code: '98071'
  },
  '98072': {
    country: 'US',
    city: 'Woodinville',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7684,
    longitude: -122.1271,
    postal_code: '98072'
  },
  '98073': {
    country: 'US',
    city: 'Redmond',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.674,
    longitude: -122.1215,
    postal_code: '98073'
  },
  '98074': {
    country: 'US',
    city: 'Sammamish',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6254,
    longitude: -122.0462,
    postal_code: '98074'
  },
  '98075': {
    country: 'US',
    city: 'Sammamish',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5857,
    longitude: -122.0345,
    postal_code: '98075'
  },
  '98077': {
    country: 'US',
    city: 'Woodinville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.7529,
    longitude: -122.0582,
    postal_code: '98077'
  },
  '98082': {
    country: 'US',
    city: 'Mill Creek',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8601,
    longitude: -122.2043,
    postal_code: '98082'
  },
  '98083': {
    country: 'US',
    city: 'Kirkland',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6815,
    longitude: -122.2087,
    postal_code: '98083'
  },
  '98087': {
    country: 'US',
    city: 'Lynnwood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.862,
    longitude: -122.2532,
    postal_code: '98087'
  },
  '98089': {
    country: 'US',
    city: 'Kent',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3871,
    longitude: -122.2023,
    postal_code: '98089'
  },
  '98092': {
    country: 'US',
    city: 'Auburn',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.2884,
    longitude: -122.098,
    postal_code: '98092'
  },
  '98093': {
    country: 'US',
    city: 'Federal Way',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.311,
    longitude: -122.1138,
    postal_code: '98093'
  },
  '98101': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6114,
    longitude: -122.3305,
    postal_code: '98101'
  },
  '98102': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6302,
    longitude: -122.321,
    postal_code: '98102'
  },
  '98103': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6733,
    longitude: -122.3426,
    postal_code: '98103'
  },
  '98104': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6036,
    longitude: -122.3256,
    postal_code: '98104'
  },
  '98105': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6633,
    longitude: -122.3022,
    postal_code: '98105'
  },
  '98106': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5344,
    longitude: -122.3547,
    postal_code: '98106'
  },
  '98107': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6701,
    longitude: -122.3763,
    postal_code: '98107'
  },
  '98108': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5413,
    longitude: -122.3129,
    postal_code: '98108'
  },
  '98109': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6339,
    longitude: -122.3476,
    postal_code: '98109'
  },
  '98110': {
    country: 'US',
    city: 'Bainbridge Island',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.6478,
    longitude: -122.538,
    postal_code: '98110'
  },
  '98111': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98111'
  },
  '98112': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6301,
    longitude: -122.2972,
    postal_code: '98112'
  },
  '98113': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6716,
    longitude: -122.3411,
    postal_code: '98113'
  },
  '98114': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98114'
  },
  '98115': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6849,
    longitude: -122.2968,
    postal_code: '98115'
  },
  '98116': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5746,
    longitude: -122.3934,
    postal_code: '98116'
  },
  '98117': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6873,
    longitude: -122.3772,
    postal_code: '98117'
  },
  '98118': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5412,
    longitude: -122.275,
    postal_code: '98118'
  },
  '98119': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6379,
    longitude: -122.3643,
    postal_code: '98119'
  },
  '98121': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6151,
    longitude: -122.3447,
    postal_code: '98121'
  },
  '98122': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6116,
    longitude: -122.3056,
    postal_code: '98122'
  },
  '98124': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98124'
  },
  '98125': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.717,
    longitude: -122.3015,
    postal_code: '98125'
  },
  '98126': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5444,
    longitude: -122.3735,
    postal_code: '98126'
  },
  '98127': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6063,
    longitude: -122.3308,
    postal_code: '98127'
  },
  '98129': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98129'
  },
  '98131': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98131'
  },
  '98133': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7377,
    longitude: -122.3431,
    postal_code: '98133'
  },
  '98134': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5903,
    longitude: -122.3263,
    postal_code: '98134'
  },
  '98136': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5398,
    longitude: -122.3878,
    postal_code: '98136'
  },
  '98138': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98138'
  },
  '98139': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6473,
    longitude: -122.3999,
    postal_code: '98139'
  },
  '98141': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6157,
    longitude: -122.3445,
    postal_code: '98141'
  },
  '98144': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.5846,
    longitude: -122.3005,
    postal_code: '98144'
  },
  '98145': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98145'
  },
  '98146': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4995,
    longitude: -122.3603,
    postal_code: '98146'
  },
  '98148': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4441,
    longitude: -122.3249,
    postal_code: '98148'
  },
  '98154': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98154'
  },
  '98155': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7559,
    longitude: -122.3003,
    postal_code: '98155'
  },
  '98158': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4497,
    longitude: -122.3076,
    postal_code: '98158'
  },
  '98160': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98160'
  },
  '98161': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98161'
  },
  '98164': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.606,
    longitude: -122.332,
    postal_code: '98164'
  },
  '98165': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7161,
    longitude: -122.3004,
    postal_code: '98165'
  },
  '98166': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4511,
    longitude: -122.353,
    postal_code: '98166'
  },
  '98168': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4889,
    longitude: -122.3012,
    postal_code: '98168'
  },
  '98170': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6096,
    longitude: -122.3317,
    postal_code: '98170'
  },
  '98174': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98174'
  },
  '98175': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7161,
    longitude: -122.3004,
    postal_code: '98175'
  },
  '98177': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7467,
    longitude: -122.3686,
    postal_code: '98177'
  },
  '98178': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4924,
    longitude: -122.2359,
    postal_code: '98178'
  },
  '98181': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98181'
  },
  '98185': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98185'
  },
  '98188': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.4483,
    longitude: -122.2731,
    postal_code: '98188'
  },
  '98190': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98190'
  },
  '98191': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6062,
    longitude: -122.3321,
    postal_code: '98191'
  },
  '98194': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6024,
    longitude: -122.326,
    postal_code: '98194'
  },
  '98195': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6564,
    longitude: -122.3048,
    postal_code: '98195'
  },
  '98198': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.3929,
    longitude: -122.3129,
    postal_code: '98198'
  },
  '98199': {
    country: 'US',
    city: 'Seattle',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6488,
    longitude: -122.3964,
    postal_code: '98199'
  },
  '98201': {
    country: 'US',
    city: 'Everett',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.9884,
    longitude: -122.2006,
    postal_code: '98201'
  },
  '98203': {
    country: 'US',
    city: 'Everett',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.9419,
    longitude: -122.2218,
    postal_code: '98203'
  },
  '98204': {
    country: 'US',
    city: 'Everett',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.9017,
    longitude: -122.2472,
    postal_code: '98204'
  },
  '98206': {
    country: 'US',
    city: 'Everett',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8599,
    longitude: -122.2848,
    postal_code: '98206'
  },
  '98207': {
    country: 'US',
    city: 'Everett',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.979,
    longitude: -122.2021,
    postal_code: '98207'
  },
  '98208': {
    country: 'US',
    city: 'Everett',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8948,
    longitude: -122.1987,
    postal_code: '98208'
  },
  '98213': {
    country: 'US',
    city: 'Everett',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.9451,
    longitude: -122.2269,
    postal_code: '98213'
  },
  '98220': {
    country: 'US',
    city: 'Acme',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.6752,
    longitude: -122.1914,
    postal_code: '98220'
  },
  '98221': {
    country: 'US',
    city: 'Anacortes',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.5004,
    longitude: -122.6309,
    postal_code: '98221'
  },
  '98222': {
    country: 'US',
    city: 'Blakely Island',
    state: 'Washington',
    state_short: 'WA',
    county: 'San Juan',
    latitude: 48.5618,
    longitude: -122.812,
    postal_code: '98222'
  },
  '98223': {
    country: 'US',
    city: 'Arlington',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 48.1829,
    longitude: -122.1121,
    postal_code: '98223'
  },
  '98224': {
    country: 'US',
    city: 'Baring',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.7671,
    longitude: -121.4814,
    postal_code: '98224'
  },
  '98225': {
    country: 'US',
    city: 'Bellingham',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.749,
    longitude: -122.4887,
    postal_code: '98225'
  },
  '98226': {
    country: 'US',
    city: 'Bellingham',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.7974,
    longitude: -122.4448,
    postal_code: '98226'
  },
  '98227': {
    country: 'US',
    city: 'Bellingham',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.7596,
    longitude: -122.4882,
    postal_code: '98227'
  },
  '98228': {
    country: 'US',
    city: 'Bellingham',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.7596,
    longitude: -122.4882,
    postal_code: '98228'
  },
  '98229': {
    country: 'US',
    city: 'Bellingham',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.6952,
    longitude: -122.4124,
    postal_code: '98229'
  },
  '98230': {
    country: 'US',
    city: 'Blaine',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.9636,
    longitude: -122.7323,
    postal_code: '98230'
  },
  '98231': {
    country: 'US',
    city: 'Blaine',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.9937,
    longitude: -122.7471,
    postal_code: '98231'
  },
  '98232': {
    country: 'US',
    city: 'Bow',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.562,
    longitude: -122.4134,
    postal_code: '98232'
  },
  '98233': {
    country: 'US',
    city: 'Burlington',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.4786,
    longitude: -122.3345,
    postal_code: '98233'
  },
  '98235': {
    country: 'US',
    city: 'Clearlake',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.4701,
    longitude: -122.2388,
    postal_code: '98235'
  },
  '98236': {
    country: 'US',
    city: 'Clinton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Island',
    latitude: 47.9508,
    longitude: -122.3916,
    postal_code: '98236'
  },
  '98237': {
    country: 'US',
    city: 'Concrete',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.531,
    longitude: -121.6643,
    postal_code: '98237'
  },
  '98238': {
    country: 'US',
    city: 'Conway',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.34,
    longitude: -122.3456,
    postal_code: '98238'
  },
  '98239': {
    country: 'US',
    city: 'Coupeville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Island',
    latitude: 48.2189,
    longitude: -122.6823,
    postal_code: '98239'
  },
  '98240': {
    country: 'US',
    city: 'Custer',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.9374,
    longitude: -122.6226,
    postal_code: '98240'
  },
  '98241': {
    country: 'US',
    city: 'Darrington',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 48.2395,
    longitude: -121.5892,
    postal_code: '98241'
  },
  '98243': {
    country: 'US',
    city: 'Deer Harbor',
    state: 'Washington',
    state_short: 'WA',
    county: 'San Juan',
    latitude: 48.6201,
    longitude: -122.9999,
    postal_code: '98243'
  },
  '98244': {
    country: 'US',
    city: 'Deming',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.8018,
    longitude: -122.0918,
    postal_code: '98244'
  },
  '98245': {
    country: 'US',
    city: 'Eastsound',
    state: 'Washington',
    state_short: 'WA',
    county: 'San Juan',
    latitude: 48.6968,
    longitude: -122.9055,
    postal_code: '98245'
  },
  '98247': {
    country: 'US',
    city: 'Everson',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.9045,
    longitude: -122.3325,
    postal_code: '98247'
  },
  '98248': {
    country: 'US',
    city: 'Ferndale',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.8625,
    longitude: -122.5953,
    postal_code: '98248'
  },
  '98249': {
    country: 'US',
    city: 'Freeland',
    state: 'Washington',
    state_short: 'WA',
    county: 'Island',
    latitude: 48.0342,
    longitude: -122.5641,
    postal_code: '98249'
  },
  '98250': {
    country: 'US',
    city: 'Friday Harbor',
    state: 'Washington',
    state_short: 'WA',
    county: 'San Juan',
    latitude: 48.5454,
    longitude: -123.0947,
    postal_code: '98250'
  },
  '98251': {
    country: 'US',
    city: 'Gold Bar',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8331,
    longitude: -121.6365,
    postal_code: '98251'
  },
  '98252': {
    country: 'US',
    city: 'Granite Falls',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 48.079,
    longitude: -121.9428,
    postal_code: '98252'
  },
  '98253': {
    country: 'US',
    city: 'Greenbank',
    state: 'Washington',
    state_short: 'WA',
    county: 'Island',
    latitude: 48.1004,
    longitude: -122.5761,
    postal_code: '98253'
  },
  '98255': {
    country: 'US',
    city: 'Hamilton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.5255,
    longitude: -121.9887,
    postal_code: '98255'
  },
  '98256': {
    country: 'US',
    city: 'Index',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8118,
    longitude: -121.5497,
    postal_code: '98256'
  },
  '98257': {
    country: 'US',
    city: 'La Conner',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.4093,
    longitude: -122.5313,
    postal_code: '98257'
  },
  '98258': {
    country: 'US',
    city: 'Lake Stevens',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 48.0171,
    longitude: -122.0672,
    postal_code: '98258'
  },
  '98259': {
    country: 'US',
    city: 'North Lakewood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 48.152,
    longitude: -122.2074,
    postal_code: '98259'
  },
  '98260': {
    country: 'US',
    city: 'Langley',
    state: 'Washington',
    state_short: 'WA',
    county: 'Island',
    latitude: 48.0187,
    longitude: -122.453,
    postal_code: '98260'
  },
  '98261': {
    country: 'US',
    city: 'Lopez Island',
    state: 'Washington',
    state_short: 'WA',
    county: 'San Juan',
    latitude: 48.4988,
    longitude: -122.8743,
    postal_code: '98261'
  },
  '98262': {
    country: 'US',
    city: 'Lummi Island',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.7128,
    longitude: -122.6823,
    postal_code: '98262'
  },
  '98263': {
    country: 'US',
    city: 'Lyman',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.5345,
    longitude: -122.0586,
    postal_code: '98263'
  },
  '98264': {
    country: 'US',
    city: 'Lynden',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.9372,
    longitude: -122.4592,
    postal_code: '98264'
  },
  '98266': {
    country: 'US',
    city: 'Maple Falls',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.9533,
    longitude: -122.1283,
    postal_code: '98266'
  },
  '98267': {
    country: 'US',
    city: 'Marblemount',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.4435,
    longitude: -121.3163,
    postal_code: '98267'
  },
  '98270': {
    country: 'US',
    city: 'Marysville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 48.0656,
    longitude: -122.1562,
    postal_code: '98270'
  },
  '98271': {
    country: 'US',
    city: 'Marysville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 48.0966,
    longitude: -122.198,
    postal_code: '98271'
  },
  '98272': {
    country: 'US',
    city: 'Monroe',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8585,
    longitude: -121.9474,
    postal_code: '98272'
  },
  '98273': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.4352,
    longitude: -122.2082,
    postal_code: '98273'
  },
  '98274': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.3643,
    longitude: -122.1403,
    postal_code: '98274'
  },
  '98275': {
    country: 'US',
    city: 'Mukilteo',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.9199,
    longitude: -122.3019,
    postal_code: '98275'
  },
  '98276': {
    country: 'US',
    city: 'Nooksack',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.9276,
    longitude: -122.3215,
    postal_code: '98276'
  },
  '98277': {
    country: 'US',
    city: 'Oak Harbor',
    state: 'Washington',
    state_short: 'WA',
    county: 'Island',
    latitude: 48.3151,
    longitude: -122.6374,
    postal_code: '98277'
  },
  '98278': {
    country: 'US',
    city: 'Oak Harbor',
    state: 'Washington',
    state_short: 'WA',
    county: 'Island',
    latitude: 48.3402,
    longitude: -122.6695,
    postal_code: '98278'
  },
  '98279': {
    country: 'US',
    city: 'Olga',
    state: 'Washington',
    state_short: 'WA',
    county: 'San Juan',
    latitude: 48.6195,
    longitude: -122.8355,
    postal_code: '98279'
  },
  '98280': {
    country: 'US',
    city: 'Orcas',
    state: 'Washington',
    state_short: 'WA',
    county: 'San Juan',
    latitude: 48.5979,
    longitude: -122.9446,
    postal_code: '98280'
  },
  '98281': {
    country: 'US',
    city: 'Point Roberts',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.9879,
    longitude: -123.0555,
    postal_code: '98281'
  },
  '98282': {
    country: 'US',
    city: 'Camano Island',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 48.1761,
    longitude: -122.521,
    postal_code: '98282'
  },
  '98283': {
    country: 'US',
    city: 'Rockport',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.4704,
    longitude: -121.5554,
    postal_code: '98283'
  },
  '98284': {
    country: 'US',
    city: 'Sedro Woolley',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skagit',
    latitude: 48.5274,
    longitude: -122.2329,
    postal_code: '98284'
  },
  '98286': {
    country: 'US',
    city: 'Shaw Island',
    state: 'Washington',
    state_short: 'WA',
    county: 'San Juan',
    latitude: 48.5578,
    longitude: -122.9837,
    postal_code: '98286'
  },
  '98287': {
    country: 'US',
    city: 'Silvana',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 48.2023,
    longitude: -122.2538,
    postal_code: '98287'
  },
  '98288': {
    country: 'US',
    city: 'Skykomish',
    state: 'Washington',
    state_short: 'WA',
    county: 'King',
    latitude: 47.6922,
    longitude: -121.3713,
    postal_code: '98288'
  },
  '98290': {
    country: 'US',
    city: 'Snohomish',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8954,
    longitude: -122.0716,
    postal_code: '98290'
  },
  '98291': {
    country: 'US',
    city: 'Snohomish',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.9095,
    longitude: -122.0501,
    postal_code: '98291'
  },
  '98292': {
    country: 'US',
    city: 'Stanwood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 48.2011,
    longitude: -122.378,
    postal_code: '98292'
  },
  '98293': {
    country: 'US',
    city: 'Startup',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8703,
    longitude: -121.7685,
    postal_code: '98293'
  },
  '98294': {
    country: 'US',
    city: 'Sultan',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8589,
    longitude: -121.7369,
    postal_code: '98294'
  },
  '98295': {
    country: 'US',
    city: 'Sumas',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whatcom',
    latitude: 48.9708,
    longitude: -122.2074,
    postal_code: '98295'
  },
  '98296': {
    country: 'US',
    city: 'Snohomish',
    state: 'Washington',
    state_short: 'WA',
    county: 'Snohomish',
    latitude: 47.8579,
    longitude: -122.092,
    postal_code: '98296'
  },
  '98297': {
    country: 'US',
    city: 'Waldron',
    state: 'Washington',
    state_short: 'WA',
    county: 'San Juan',
    latitude: 48.6876,
    longitude: -123.0369,
    postal_code: '98297'
  },
  '98303': {
    country: 'US',
    city: 'Anderson Island',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1563,
    longitude: -122.7067,
    postal_code: '98303'
  },
  '98304': {
    country: 'US',
    city: 'Ashford',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 46.7531,
    longitude: -121.9898,
    postal_code: '98304'
  },
  '98305': {
    country: 'US',
    city: 'Beaver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 48.0673,
    longitude: -124.3054,
    postal_code: '98305'
  },
  '98310': {
    country: 'US',
    city: 'Bremerton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.6019,
    longitude: -122.6299,
    postal_code: '98310'
  },
  '98311': {
    country: 'US',
    city: 'Bremerton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.6271,
    longitude: -122.6373,
    postal_code: '98311'
  },
  '98312': {
    country: 'US',
    city: 'Bremerton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.5754,
    longitude: -122.6958,
    postal_code: '98312'
  },
  '98314': {
    country: 'US',
    city: 'Bremerton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.5593,
    longitude: -122.6492,
    postal_code: '98314'
  },
  '98315': {
    country: 'US',
    city: 'Silverdale',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.692,
    longitude: -122.7161,
    postal_code: '98315'
  },
  '98320': {
    country: 'US',
    city: 'Brinnon',
    state: 'Washington',
    state_short: 'WA',
    county: 'Jefferson',
    latitude: 47.6776,
    longitude: -122.9375,
    postal_code: '98320'
  },
  '98321': {
    country: 'US',
    city: 'Buckley',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1524,
    longitude: -122.0621,
    postal_code: '98321'
  },
  '98322': {
    country: 'US',
    city: 'Burley',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.4179,
    longitude: -122.631,
    postal_code: '98322'
  },
  '98323': {
    country: 'US',
    city: 'Carbonado',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.0802,
    longitude: -122.0513,
    postal_code: '98323'
  },
  '98324': {
    country: 'US',
    city: 'Carlsborg',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 48.0906,
    longitude: -123.1721,
    postal_code: '98324'
  },
  '98325': {
    country: 'US',
    city: 'Chimacum',
    state: 'Washington',
    state_short: 'WA',
    county: 'Jefferson',
    latitude: 47.9861,
    longitude: -122.7883,
    postal_code: '98325'
  },
  '98326': {
    country: 'US',
    city: 'Clallam Bay',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 48.2255,
    longitude: -124.2015,
    postal_code: '98326'
  },
  '98327': {
    country: 'US',
    city: 'Dupont',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.0968,
    longitude: -122.6312,
    postal_code: '98327'
  },
  '98328': {
    country: 'US',
    city: 'Eatonville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 46.8708,
    longitude: -122.2696,
    postal_code: '98328'
  },
  '98329': {
    country: 'US',
    city: 'Gig Harbor',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.3786,
    longitude: -122.7,
    postal_code: '98329'
  },
  '98330': {
    country: 'US',
    city: 'Elbe',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 46.7777,
    longitude: -122.2023,
    postal_code: '98330'
  },
  '98331': {
    country: 'US',
    city: 'Forks',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 47.9287,
    longitude: -124.3989,
    postal_code: '98331'
  },
  '98332': {
    country: 'US',
    city: 'Gig Harbor',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.3607,
    longitude: -122.6001,
    postal_code: '98332'
  },
  '98333': {
    country: 'US',
    city: 'Fox Island',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.248,
    longitude: -122.629,
    postal_code: '98333'
  },
  '98335': {
    country: 'US',
    city: 'Gig Harbor',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.3002,
    longitude: -122.6084,
    postal_code: '98335'
  },
  '98336': {
    country: 'US',
    city: 'Glenoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.5283,
    longitude: -122.099,
    postal_code: '98336'
  },
  '98337': {
    country: 'US',
    city: 'Bremerton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.5686,
    longitude: -122.6373,
    postal_code: '98337'
  },
  '98338': {
    country: 'US',
    city: 'Graham',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.0246,
    longitude: -122.2936,
    postal_code: '98338'
  },
  '98339': {
    country: 'US',
    city: 'Port Hadlock',
    state: 'Washington',
    state_short: 'WA',
    county: 'Jefferson',
    latitude: 48.0345,
    longitude: -122.7682,
    postal_code: '98339'
  },
  '98340': {
    country: 'US',
    city: 'Hansville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.9061,
    longitude: -122.5655,
    postal_code: '98340'
  },
  '98342': {
    country: 'US',
    city: 'Indianola',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.7514,
    longitude: -122.5196,
    postal_code: '98342'
  },
  '98343': {
    country: 'US',
    city: 'Joyce',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 48.1362,
    longitude: -123.7354,
    postal_code: '98343'
  },
  '98344': {
    country: 'US',
    city: 'Kapowsin',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 46.9899,
    longitude: -122.2226,
    postal_code: '98344'
  },
  '98345': {
    country: 'US',
    city: 'Keyport',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.698,
    longitude: -122.6255,
    postal_code: '98345'
  },
  '98346': {
    country: 'US',
    city: 'Kingston',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.8108,
    longitude: -122.5255,
    postal_code: '98346'
  },
  '98348': {
    country: 'US',
    city: 'La Grande',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 46.8343,
    longitude: -122.3187,
    postal_code: '98348'
  },
  '98349': {
    country: 'US',
    city: 'Lakebay',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2886,
    longitude: -122.7776,
    postal_code: '98349'
  },
  '98350': {
    country: 'US',
    city: 'La Push',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 47.9052,
    longitude: -124.6261,
    postal_code: '98350'
  },
  '98351': {
    country: 'US',
    city: 'Longbranch',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2007,
    longitude: -122.7561,
    postal_code: '98351'
  },
  '98352': {
    country: 'US',
    city: 'Sumner',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.0662,
    longitude: -122.1132,
    postal_code: '98352'
  },
  '98353': {
    country: 'US',
    city: 'Manchester',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.5952,
    longitude: -122.6225,
    postal_code: '98353'
  },
  '98354': {
    country: 'US',
    city: 'Milton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2483,
    longitude: -122.3155,
    postal_code: '98354'
  },
  '98355': {
    country: 'US',
    city: 'Mineral',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.7096,
    longitude: -122.1861,
    postal_code: '98355'
  },
  '98356': {
    country: 'US',
    city: 'Morton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.5581,
    longitude: -122.2496,
    postal_code: '98356'
  },
  '98357': {
    country: 'US',
    city: 'Neah Bay',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 48.328,
    longitude: -124.6151,
    postal_code: '98357'
  },
  '98358': {
    country: 'US',
    city: 'Nordland',
    state: 'Washington',
    state_short: 'WA',
    county: 'Jefferson',
    latitude: 48.0432,
    longitude: -122.6926,
    postal_code: '98358'
  },
  '98359': {
    country: 'US',
    city: 'Olalla',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.4241,
    longitude: -122.5745,
    postal_code: '98359'
  },
  '98360': {
    country: 'US',
    city: 'Orting',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.0822,
    longitude: -122.186,
    postal_code: '98360'
  },
  '98361': {
    country: 'US',
    city: 'Packwood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.65,
    longitude: -121.6553,
    postal_code: '98361'
  },
  '98362': {
    country: 'US',
    city: 'Port Angeles',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 48.1065,
    longitude: -123.4384,
    postal_code: '98362'
  },
  '98363': {
    country: 'US',
    city: 'Port Angeles',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 48.0557,
    longitude: -123.9178,
    postal_code: '98363'
  },
  '98364': {
    country: 'US',
    city: 'Port Gamble',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.8543,
    longitude: -122.5838,
    postal_code: '98364'
  },
  '98365': {
    country: 'US',
    city: 'Port Ludlow',
    state: 'Washington',
    state_short: 'WA',
    county: 'Jefferson',
    latitude: 47.9222,
    longitude: -122.6896,
    postal_code: '98365'
  },
  '98366': {
    country: 'US',
    city: 'Port Orchard',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.5427,
    longitude: -122.5871,
    postal_code: '98366'
  },
  '98367': {
    country: 'US',
    city: 'Port Orchard',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.4707,
    longitude: -122.651,
    postal_code: '98367'
  },
  '98368': {
    country: 'US',
    city: 'Port Townsend',
    state: 'Washington',
    state_short: 'WA',
    county: 'Jefferson',
    latitude: 48.104,
    longitude: -122.7945,
    postal_code: '98368'
  },
  '98370': {
    country: 'US',
    city: 'Poulsbo',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.7423,
    longitude: -122.6277,
    postal_code: '98370'
  },
  '98371': {
    country: 'US',
    city: 'Puyallup',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1991,
    longitude: -122.3151,
    postal_code: '98371'
  },
  '98372': {
    country: 'US',
    city: 'Puyallup',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2042,
    longitude: -122.2734,
    postal_code: '98372'
  },
  '98373': {
    country: 'US',
    city: 'Puyallup',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1284,
    longitude: -122.3219,
    postal_code: '98373'
  },
  '98374': {
    country: 'US',
    city: 'Puyallup',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1424,
    longitude: -122.2652,
    postal_code: '98374'
  },
  '98375': {
    country: 'US',
    city: 'Puyallup',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1037,
    longitude: -122.3235,
    postal_code: '98375'
  },
  '98376': {
    country: 'US',
    city: 'Quilcene',
    state: 'Washington',
    state_short: 'WA',
    county: 'Jefferson',
    latitude: 47.8324,
    longitude: -122.8583,
    postal_code: '98376'
  },
  '98377': {
    country: 'US',
    city: 'Randle',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.5492,
    longitude: -121.8555,
    postal_code: '98377'
  },
  '98378': {
    country: 'US',
    city: 'Retsil',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.5459,
    longitude: -122.6112,
    postal_code: '98378'
  },
  '98380': {
    country: 'US',
    city: 'Seabeck',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.5913,
    longitude: -122.8686,
    postal_code: '98380'
  },
  '98381': {
    country: 'US',
    city: 'Sekiu',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 48.3032,
    longitude: -124.4685,
    postal_code: '98381'
  },
  '98382': {
    country: 'US',
    city: 'Sequim',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clallam',
    latitude: 48.0881,
    longitude: -123.1198,
    postal_code: '98382'
  },
  '98383': {
    country: 'US',
    city: 'Silverdale',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.6621,
    longitude: -122.6981,
    postal_code: '98383'
  },
  '98384': {
    country: 'US',
    city: 'South Colby',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.5218,
    longitude: -122.5396,
    postal_code: '98384'
  },
  '98385': {
    country: 'US',
    city: 'South Prairie',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1383,
    longitude: -122.0968,
    postal_code: '98385'
  },
  '98386': {
    country: 'US',
    city: 'Southworth',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.5104,
    longitude: -122.4991,
    postal_code: '98386'
  },
  '98387': {
    country: 'US',
    city: 'Spanaway',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.0732,
    longitude: -122.3943,
    postal_code: '98387'
  },
  '98388': {
    country: 'US',
    city: 'Steilacoom',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1704,
    longitude: -122.5888,
    postal_code: '98388'
  },
  '98390': {
    country: 'US',
    city: 'Sumner',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2099,
    longitude: -122.228,
    postal_code: '98390'
  },
  '98391': {
    country: 'US',
    city: 'Bonney Lake',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.143,
    longitude: -122.1644,
    postal_code: '98391'
  },
  '98392': {
    country: 'US',
    city: 'Suquamish',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.7343,
    longitude: -122.5573,
    postal_code: '98392'
  },
  '98393': {
    country: 'US',
    city: 'Tracyton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kitsap',
    latitude: 47.6267,
    longitude: -122.65,
    postal_code: '98393'
  },
  '98394': {
    country: 'US',
    city: 'Vaughn',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.3309,
    longitude: -122.7736,
    postal_code: '98394'
  },
  '98395': {
    country: 'US',
    city: 'Wauna',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.379,
    longitude: -122.6426,
    postal_code: '98395'
  },
  '98396': {
    country: 'US',
    city: 'Wilkeson',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1095,
    longitude: -122.037,
    postal_code: '98396'
  },
  '98397': {
    country: 'US',
    city: 'Longmire',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 46.7498,
    longitude: -121.8126,
    postal_code: '98397'
  },
  '98398': {
    country: 'US',
    city: 'Paradise Inn',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.0662,
    longitude: -122.1132,
    postal_code: '98398'
  },
  '98401': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2537,
    longitude: -122.4443,
    postal_code: '98401'
  },
  '98402': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2545,
    longitude: -122.4405,
    postal_code: '98402'
  },
  '98403': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2643,
    longitude: -122.4575,
    postal_code: '98403'
  },
  '98404': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2113,
    longitude: -122.4126,
    postal_code: '98404'
  },
  '98405': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2484,
    longitude: -122.4643,
    postal_code: '98405'
  },
  '98406': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2632,
    longitude: -122.4993,
    postal_code: '98406'
  },
  '98407': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2825,
    longitude: -122.5039,
    postal_code: '98407'
  },
  '98408': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2073,
    longitude: -122.4444,
    postal_code: '98408'
  },
  '98409': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2038,
    longitude: -122.4825,
    postal_code: '98409'
  },
  '98411': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2529,
    longitude: -122.4443,
    postal_code: '98411'
  },
  '98412': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2529,
    longitude: -122.4443,
    postal_code: '98412'
  },
  '98413': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2529,
    longitude: -122.4443,
    postal_code: '98413'
  },
  '98415': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2529,
    longitude: -122.4443,
    postal_code: '98415'
  },
  '98416': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2646,
    longitude: -122.4821,
    postal_code: '98416'
  },
  '98417': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2061,
    longitude: -122.4822,
    postal_code: '98417'
  },
  '98418': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2232,
    longitude: -122.4465,
    postal_code: '98418'
  },
  '98419': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2061,
    longitude: -122.4822,
    postal_code: '98419'
  },
  '98421': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2664,
    longitude: -122.4015,
    postal_code: '98421'
  },
  '98422': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2948,
    longitude: -122.3983,
    postal_code: '98422'
  },
  '98424': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2325,
    longitude: -122.3594,
    postal_code: '98424'
  },
  '98430': {
    country: 'US',
    city: 'Camp Murray',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.0662,
    longitude: -122.1132,
    postal_code: '98430'
  },
  '98431': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2529,
    longitude: -122.4443,
    postal_code: '98431'
  },
  '98433': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1125,
    longitude: -122.5891,
    postal_code: '98433'
  },
  '98438': {
    country: 'US',
    city: 'Mcchord Afb',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2258,
    longitude: -122.469,
    postal_code: '98438'
  },
  '98439': {
    country: 'US',
    city: 'Lakewood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1287,
    longitude: -122.5103,
    postal_code: '98439'
  },
  '98443': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2044,
    longitude: -122.3728,
    postal_code: '98443'
  },
  '98444': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1464,
    longitude: -122.4572,
    postal_code: '98444'
  },
  '98445': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1291,
    longitude: -122.4094,
    postal_code: '98445'
  },
  '98446': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1285,
    longitude: -122.3736,
    postal_code: '98446'
  },
  '98447': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1441,
    longitude: -122.4434,
    postal_code: '98447'
  },
  '98448': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2061,
    longitude: -122.4822,
    postal_code: '98448'
  },
  '98464': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2529,
    longitude: -122.4443,
    postal_code: '98464'
  },
  '98465': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2491,
    longitude: -122.5273,
    postal_code: '98465'
  },
  '98466': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.228,
    longitude: -122.5396,
    postal_code: '98466'
  },
  '98467': {
    country: 'US',
    city: 'University Place',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2033,
    longitude: -122.5688,
    postal_code: '98467'
  },
  '98471': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2529,
    longitude: -122.4443,
    postal_code: '98471'
  },
  '98481': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2529,
    longitude: -122.4443,
    postal_code: '98481'
  },
  '98490': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2061,
    longitude: -122.4822,
    postal_code: '98490'
  },
  '98493': {
    country: 'US',
    city: 'Tacoma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.2529,
    longitude: -122.4443,
    postal_code: '98493'
  },
  '98496': {
    country: 'US',
    city: 'Lakewood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1666,
    longitude: -122.5089,
    postal_code: '98496'
  },
  '98497': {
    country: 'US',
    city: 'Lakewood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1718,
    longitude: -122.5185,
    postal_code: '98497'
  },
  '98498': {
    country: 'US',
    city: 'Lakewood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1591,
    longitude: -122.5485,
    postal_code: '98498'
  },
  '98499': {
    country: 'US',
    city: 'Lakewood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 47.1677,
    longitude: -122.5024,
    postal_code: '98499'
  },
  '98501': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 47.0129,
    longitude: -122.8763,
    postal_code: '98501'
  },
  '98502': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 47.1043,
    longitude: -123.0552,
    postal_code: '98502'
  },
  '98503': {
    country: 'US',
    city: 'Lacey',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 47.024,
    longitude: -122.7827,
    postal_code: '98503'
  },
  '98504': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 47.0379,
    longitude: -122.9007,
    postal_code: '98504'
  },
  '98505': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 47.0654,
    longitude: -122.9762,
    postal_code: '98505'
  },
  '98506': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 47.1042,
    longitude: -122.87,
    postal_code: '98506'
  },
  '98507': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 47.0379,
    longitude: -122.9007,
    postal_code: '98507'
  },
  '98508': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 47.0379,
    longitude: -122.9007,
    postal_code: '98508'
  },
  '98509': {
    country: 'US',
    city: 'Lacey',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.9781,
    longitude: -122.7024,
    postal_code: '98509'
  },
  '98511': {
    country: 'US',
    city: 'Tumwater',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.9616,
    longitude: -123.0283,
    postal_code: '98511'
  },
  '98512': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.9498,
    longitude: -123.0212,
    postal_code: '98512'
  },
  '98513': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.9939,
    longitude: -122.743,
    postal_code: '98513'
  },
  '98516': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 47.1126,
    longitude: -122.7794,
    postal_code: '98516'
  },
  '98520': {
    country: 'US',
    city: 'Aberdeen',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 46.9843,
    longitude: -123.7963,
    postal_code: '98520'
  },
  '98522': {
    country: 'US',
    city: 'Adna',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.629,
    longitude: -123.0612,
    postal_code: '98522'
  },
  '98524': {
    country: 'US',
    city: 'Allyn',
    state: 'Washington',
    state_short: 'WA',
    county: 'Mason',
    latitude: 47.3571,
    longitude: -122.8587,
    postal_code: '98524'
  },
  '98526': {
    country: 'US',
    city: 'Amanda Park',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.4859,
    longitude: -123.9206,
    postal_code: '98526'
  },
  '98527': {
    country: 'US',
    city: 'Bay Center',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.6315,
    longitude: -123.9549,
    postal_code: '98527'
  },
  '98528': {
    country: 'US',
    city: 'Belfair',
    state: 'Washington',
    state_short: 'WA',
    county: 'Mason',
    latitude: 47.4325,
    longitude: -122.9289,
    postal_code: '98528'
  },
  '98530': {
    country: 'US',
    city: 'Bucoda',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.7982,
    longitude: -122.8718,
    postal_code: '98530'
  },
  '98531': {
    country: 'US',
    city: 'Centralia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.7246,
    longitude: -122.9671,
    postal_code: '98531'
  },
  '98532': {
    country: 'US',
    city: 'Chehalis',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.6382,
    longitude: -122.9658,
    postal_code: '98532'
  },
  '98533': {
    country: 'US',
    city: 'Cinebar',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.5793,
    longitude: -122.5687,
    postal_code: '98533'
  },
  '98535': {
    country: 'US',
    city: 'Copalis Beach',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.1126,
    longitude: -124.1738,
    postal_code: '98535'
  },
  '98536': {
    country: 'US',
    city: 'Copalis Crossing',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.1591,
    longitude: -124.1352,
    postal_code: '98536'
  },
  '98537': {
    country: 'US',
    city: 'Cosmopolis',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 46.9538,
    longitude: -123.7739,
    postal_code: '98537'
  },
  '98538': {
    country: 'US',
    city: 'Curtis',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.4956,
    longitude: -123.1468,
    postal_code: '98538'
  },
  '98539': {
    country: 'US',
    city: 'Doty',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.6378,
    longitude: -123.2804,
    postal_code: '98539'
  },
  '98540': {
    country: 'US',
    city: 'East Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.9781,
    longitude: -122.7024,
    postal_code: '98540'
  },
  '98541': {
    country: 'US',
    city: 'Elma',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.0058,
    longitude: -123.3997,
    postal_code: '98541'
  },
  '98542': {
    country: 'US',
    city: 'Ethel',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.5321,
    longitude: -122.7404,
    postal_code: '98542'
  },
  '98544': {
    country: 'US',
    city: 'Galvin',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.7423,
    longitude: -123.0271,
    postal_code: '98544'
  },
  '98546': {
    country: 'US',
    city: 'Grapeview',
    state: 'Washington',
    state_short: 'WA',
    county: 'Mason',
    latitude: 47.3273,
    longitude: -122.9163,
    postal_code: '98546'
  },
  '98547': {
    country: 'US',
    city: 'Grayland',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 46.8264,
    longitude: -124.094,
    postal_code: '98547'
  },
  '98548': {
    country: 'US',
    city: 'Hoodsport',
    state: 'Washington',
    state_short: 'WA',
    county: 'Mason',
    latitude: 47.4235,
    longitude: -123.1739,
    postal_code: '98548'
  },
  '98550': {
    country: 'US',
    city: 'Hoquiam',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 46.9823,
    longitude: -123.8842,
    postal_code: '98550'
  },
  '98552': {
    country: 'US',
    city: 'Humptulips',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.3429,
    longitude: -123.9291,
    postal_code: '98552'
  },
  '98554': {
    country: 'US',
    city: 'Lebam',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.5614,
    longitude: -123.5479,
    postal_code: '98554'
  },
  '98555': {
    country: 'US',
    city: 'Lilliwaup',
    state: 'Washington',
    state_short: 'WA',
    county: 'Mason',
    latitude: 47.5128,
    longitude: -123.0631,
    postal_code: '98555'
  },
  '98556': {
    country: 'US',
    city: 'Littlerock',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.9024,
    longitude: -123.017,
    postal_code: '98556'
  },
  '98557': {
    country: 'US',
    city: 'Mccleary',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.0297,
    longitude: -123.273,
    postal_code: '98557'
  },
  '98558': {
    country: 'US',
    city: 'Mckenna',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 46.9348,
    longitude: -122.5565,
    postal_code: '98558'
  },
  '98559': {
    country: 'US',
    city: 'Malone',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 46.9589,
    longitude: -123.3268,
    postal_code: '98559'
  },
  '98560': {
    country: 'US',
    city: 'Matlock',
    state: 'Washington',
    state_short: 'WA',
    county: 'Mason',
    latitude: 47.2636,
    longitude: -123.4412,
    postal_code: '98560'
  },
  '98561': {
    country: 'US',
    city: 'Menlo',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.6215,
    longitude: -123.6471,
    postal_code: '98561'
  },
  '98562': {
    country: 'US',
    city: 'Moclips',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.2387,
    longitude: -124.2039,
    postal_code: '98562'
  },
  '98563': {
    country: 'US',
    city: 'Montesano',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.0901,
    longitude: -123.5006,
    postal_code: '98563'
  },
  '98564': {
    country: 'US',
    city: 'Mossyrock',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.5131,
    longitude: -122.4789,
    postal_code: '98564'
  },
  '98565': {
    country: 'US',
    city: 'Napavine',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.5745,
    longitude: -122.9082,
    postal_code: '98565'
  },
  '98566': {
    country: 'US',
    city: 'Neilton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.3877,
    longitude: -123.8903,
    postal_code: '98566'
  },
  '98568': {
    country: 'US',
    city: 'Oakville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 46.8434,
    longitude: -123.2493,
    postal_code: '98568'
  },
  '98569': {
    country: 'US',
    city: 'Ocean Shores',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 46.9737,
    longitude: -124.1563,
    postal_code: '98569'
  },
  '98570': {
    country: 'US',
    city: 'Onalaska',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.573,
    longitude: -122.7075,
    postal_code: '98570'
  },
  '98571': {
    country: 'US',
    city: 'Pacific Beach',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.2195,
    longitude: -124.1915,
    postal_code: '98571'
  },
  '98572': {
    country: 'US',
    city: 'Pe Ell',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.5829,
    longitude: -123.3147,
    postal_code: '98572'
  },
  '98575': {
    country: 'US',
    city: 'Quinault',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.4603,
    longitude: -123.8307,
    postal_code: '98575'
  },
  '98576': {
    country: 'US',
    city: 'Rainier',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.8829,
    longitude: -122.6795,
    postal_code: '98576'
  },
  '98577': {
    country: 'US',
    city: 'Raymond',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.671,
    longitude: -123.6929,
    postal_code: '98577'
  },
  '98579': {
    country: 'US',
    city: 'Rochester',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.8193,
    longitude: -123.0406,
    postal_code: '98579'
  },
  '98580': {
    country: 'US',
    city: 'Roy',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pierce',
    latitude: 46.956,
    longitude: -122.4483,
    postal_code: '98580'
  },
  '98581': {
    country: 'US',
    city: 'Ryderwood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 46.3752,
    longitude: -123.0431,
    postal_code: '98581'
  },
  '98582': {
    country: 'US',
    city: 'Salkum',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.5321,
    longitude: -122.6259,
    postal_code: '98582'
  },
  '98583': {
    country: 'US',
    city: 'Satsop',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.0022,
    longitude: -123.4836,
    postal_code: '98583'
  },
  '98584': {
    country: 'US',
    city: 'Shelton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Mason',
    latitude: 47.2151,
    longitude: -123.1007,
    postal_code: '98584'
  },
  '98585': {
    country: 'US',
    city: 'Silver Creek',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.5491,
    longitude: -122.4757,
    postal_code: '98585'
  },
  '98586': {
    country: 'US',
    city: 'South Bend',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.6544,
    longitude: -123.8203,
    postal_code: '98586'
  },
  '98587': {
    country: 'US',
    city: 'Taholah',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 47.3407,
    longitude: -124.2827,
    postal_code: '98587'
  },
  '98588': {
    country: 'US',
    city: 'Tahuya',
    state: 'Washington',
    state_short: 'WA',
    county: 'Mason',
    latitude: 47.4446,
    longitude: -123.0232,
    postal_code: '98588'
  },
  '98589': {
    country: 'US',
    city: 'Tenino',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.8641,
    longitude: -122.8493,
    postal_code: '98589'
  },
  '98590': {
    country: 'US',
    city: 'Tokeland',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.7065,
    longitude: -123.9818,
    postal_code: '98590'
  },
  '98591': {
    country: 'US',
    city: 'Toledo',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.4396,
    longitude: -122.8266,
    postal_code: '98591'
  },
  '98592': {
    country: 'US',
    city: 'Union',
    state: 'Washington',
    state_short: 'WA',
    county: 'Mason',
    latitude: 47.3368,
    longitude: -123.075,
    postal_code: '98592'
  },
  '98593': {
    country: 'US',
    city: 'Vader',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.3985,
    longitude: -122.9585,
    postal_code: '98593'
  },
  '98595': {
    country: 'US',
    city: 'Westport',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grays Harbor',
    latitude: 46.8836,
    longitude: -124.1061,
    postal_code: '98595'
  },
  '98596': {
    country: 'US',
    city: 'Winlock',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lewis',
    latitude: 46.494,
    longitude: -122.9158,
    postal_code: '98596'
  },
  '98597': {
    country: 'US',
    city: 'Yelm',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 46.9206,
    longitude: -122.588,
    postal_code: '98597'
  },
  '98599': {
    country: 'US',
    city: 'Olympia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Thurston',
    latitude: 47.0379,
    longitude: -122.9007,
    postal_code: '98599'
  },
  '98601': {
    country: 'US',
    city: 'Amboy',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.9195,
    longitude: -122.4574,
    postal_code: '98601'
  },
  '98602': {
    country: 'US',
    city: 'Appleton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.8504,
    longitude: -121.3129,
    postal_code: '98602'
  },
  '98603': {
    country: 'US',
    city: 'Ariel',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 45.9952,
    longitude: -122.4677,
    postal_code: '98603'
  },
  '98604': {
    country: 'US',
    city: 'Battle Ground',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.7907,
    longitude: -122.5318,
    postal_code: '98604'
  },
  '98605': {
    country: 'US',
    city: 'Bingen',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.7751,
    longitude: -121.6328,
    postal_code: '98605'
  },
  '98606': {
    country: 'US',
    city: 'Brush Prairie',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.7304,
    longitude: -122.4843,
    postal_code: '98606'
  },
  '98607': {
    country: 'US',
    city: 'Camas',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6058,
    longitude: -122.4142,
    postal_code: '98607'
  },
  '98609': {
    country: 'US',
    city: 'Carrolls',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 46.0715,
    longitude: -122.8648,
    postal_code: '98609'
  },
  '98610': {
    country: 'US',
    city: 'Carson',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skamania',
    latitude: 45.7493,
    longitude: -121.8351,
    postal_code: '98610'
  },
  '98611': {
    country: 'US',
    city: 'Castle Rock',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 46.2783,
    longitude: -122.9139,
    postal_code: '98611'
  },
  '98612': {
    country: 'US',
    city: 'Cathlamet',
    state: 'Washington',
    state_short: 'WA',
    county: 'Wahkiakum',
    latitude: 46.1954,
    longitude: -123.3627,
    postal_code: '98612'
  },
  '98613': {
    country: 'US',
    city: 'Centerville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.7032,
    longitude: -120.946,
    postal_code: '98613'
  },
  '98614': {
    country: 'US',
    city: 'Chinook',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.2729,
    longitude: -123.9454,
    postal_code: '98614'
  },
  '98616': {
    country: 'US',
    city: 'Cougar',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 46.0515,
    longitude: -122.2995,
    postal_code: '98616'
  },
  '98617': {
    country: 'US',
    city: 'Dallesport',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.6327,
    longitude: -121.1717,
    postal_code: '98617'
  },
  '98619': {
    country: 'US',
    city: 'Glenwood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 46.0071,
    longitude: -121.2885,
    postal_code: '98619'
  },
  '98620': {
    country: 'US',
    city: 'Goldendale',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.832,
    longitude: -120.813,
    postal_code: '98620'
  },
  '98621': {
    country: 'US',
    city: 'Grays River',
    state: 'Washington',
    state_short: 'WA',
    county: 'Wahkiakum',
    latitude: 46.3535,
    longitude: -123.5888,
    postal_code: '98621'
  },
  '98622': {
    country: 'US',
    city: 'Heisson',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.8248,
    longitude: -122.4912,
    postal_code: '98622'
  },
  '98623': {
    country: 'US',
    city: 'Husum',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.8076,
    longitude: -121.4877,
    postal_code: '98623'
  },
  '98624': {
    country: 'US',
    city: 'Ilwaco',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.3142,
    longitude: -124.0282,
    postal_code: '98624'
  },
  '98625': {
    country: 'US',
    city: 'Kalama',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 46.0112,
    longitude: -122.8166,
    postal_code: '98625'
  },
  '98626': {
    country: 'US',
    city: 'Kelso',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 46.1485,
    longitude: -122.887,
    postal_code: '98626'
  },
  '98628': {
    country: 'US',
    city: 'Klickitat',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.8171,
    longitude: -121.1526,
    postal_code: '98628'
  },
  '98629': {
    country: 'US',
    city: 'La Center',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.8806,
    longitude: -122.624,
    postal_code: '98629'
  },
  '98631': {
    country: 'US',
    city: 'Long Beach',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.3774,
    longitude: -124.047,
    postal_code: '98631'
  },
  '98632': {
    country: 'US',
    city: 'Longview',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 46.1514,
    longitude: -122.9634,
    postal_code: '98632'
  },
  '98635': {
    country: 'US',
    city: 'Lyle',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.745,
    longitude: -121.2501,
    postal_code: '98635'
  },
  '98637': {
    country: 'US',
    city: 'Nahcotta',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.4984,
    longitude: -124.0335,
    postal_code: '98637'
  },
  '98638': {
    country: 'US',
    city: 'Naselle',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.3528,
    longitude: -123.8044,
    postal_code: '98638'
  },
  '98639': {
    country: 'US',
    city: 'North Bonneville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skamania',
    latitude: 45.6373,
    longitude: -121.9712,
    postal_code: '98639'
  },
  '98640': {
    country: 'US',
    city: 'Ocean Park',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.5029,
    longitude: -124.0436,
    postal_code: '98640'
  },
  '98641': {
    country: 'US',
    city: 'Oysterville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.549,
    longitude: -124.0282,
    postal_code: '98641'
  },
  '98642': {
    country: 'US',
    city: 'Ridgefield',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.7846,
    longitude: -122.6934,
    postal_code: '98642'
  },
  '98643': {
    country: 'US',
    city: 'Rosburg',
    state: 'Washington',
    state_short: 'WA',
    county: 'Wahkiakum',
    latitude: 46.3071,
    longitude: -123.6571,
    postal_code: '98643'
  },
  '98644': {
    country: 'US',
    city: 'Seaview',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pacific',
    latitude: 46.3308,
    longitude: -124.0455,
    postal_code: '98644'
  },
  '98645': {
    country: 'US',
    city: 'Silverlake',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 46.3163,
    longitude: -122.7649,
    postal_code: '98645'
  },
  '98647': {
    country: 'US',
    city: 'Skamokawa',
    state: 'Washington',
    state_short: 'WA',
    county: 'Wahkiakum',
    latitude: 46.2952,
    longitude: -123.4332,
    postal_code: '98647'
  },
  '98648': {
    country: 'US',
    city: 'Stevenson',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skamania',
    latitude: 45.6882,
    longitude: -121.9093,
    postal_code: '98648'
  },
  '98649': {
    country: 'US',
    city: 'Toutle',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 46.2956,
    longitude: -122.6477,
    postal_code: '98649'
  },
  '98650': {
    country: 'US',
    city: 'Trout Lake',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.9828,
    longitude: -121.5163,
    postal_code: '98650'
  },
  '98651': {
    country: 'US',
    city: 'Underwood',
    state: 'Washington',
    state_short: 'WA',
    county: 'Skamania',
    latitude: 45.7409,
    longitude: -121.5974,
    postal_code: '98651'
  },
  '98660': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6418,
    longitude: -122.6801,
    postal_code: '98660'
  },
  '98661': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6418,
    longitude: -122.6251,
    postal_code: '98661'
  },
  '98662': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6914,
    longitude: -122.5805,
    postal_code: '98662'
  },
  '98663': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6514,
    longitude: -122.6604,
    postal_code: '98663'
  },
  '98664': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6231,
    longitude: -122.5767,
    postal_code: '98664'
  },
  '98665': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6892,
    longitude: -122.6616,
    postal_code: '98665'
  },
  '98666': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6387,
    longitude: -122.6615,
    postal_code: '98666'
  },
  '98668': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6387,
    longitude: -122.6615,
    postal_code: '98668'
  },
  '98670': {
    country: 'US',
    city: 'Wahkiacus',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.859,
    longitude: -121.1409,
    postal_code: '98670'
  },
  '98671': {
    country: 'US',
    city: 'Washougal',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.5959,
    longitude: -122.3104,
    postal_code: '98671'
  },
  '98672': {
    country: 'US',
    city: 'White Salmon',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.7551,
    longitude: -121.4795,
    postal_code: '98672'
  },
  '98673': {
    country: 'US',
    city: 'Wishram',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.6573,
    longitude: -120.9667,
    postal_code: '98673'
  },
  '98674': {
    country: 'US',
    city: 'Woodland',
    state: 'Washington',
    state_short: 'WA',
    county: 'Cowlitz',
    latitude: 45.9219,
    longitude: -122.7126,
    postal_code: '98674'
  },
  '98675': {
    country: 'US',
    city: 'Yacolt',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.8622,
    longitude: -122.4275,
    postal_code: '98675'
  },
  '98682': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6644,
    longitude: -122.5212,
    postal_code: '98682'
  },
  '98683': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6032,
    longitude: -122.5133,
    postal_code: '98683'
  },
  '98684': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6359,
    longitude: -122.5155,
    postal_code: '98684'
  },
  '98685': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.7162,
    longitude: -122.6899,
    postal_code: '98685'
  },
  '98686': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.712,
    longitude: -122.6322,
    postal_code: '98686'
  },
  '98687': {
    country: 'US',
    city: 'Vancouver',
    state: 'Washington',
    state_short: 'WA',
    county: 'Clark',
    latitude: 45.6387,
    longitude: -122.6615,
    postal_code: '98687'
  },
  '98801': {
    country: 'US',
    city: 'Wenatchee',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.4253,
    longitude: -120.3273,
    postal_code: '98801'
  },
  '98802': {
    country: 'US',
    city: 'East Wenatchee',
    state: 'Washington',
    state_short: 'WA',
    county: 'Douglas',
    latitude: 47.4186,
    longitude: -120.2731,
    postal_code: '98802'
  },
  '98807': {
    country: 'US',
    city: 'Wenatchee',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.4235,
    longitude: -120.3103,
    postal_code: '98807'
  },
  '98811': {
    country: 'US',
    city: 'Ardenvoir',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.7373,
    longitude: -120.369,
    postal_code: '98811'
  },
  '98812': {
    country: 'US',
    city: 'Brewster',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.1206,
    longitude: -119.772,
    postal_code: '98812'
  },
  '98813': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Washington',
    state_short: 'WA',
    county: 'Douglas',
    latitude: 48.0082,
    longitude: -119.6712,
    postal_code: '98813'
  },
  '98814': {
    country: 'US',
    city: 'Carlton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.2526,
    longitude: -120.1055,
    postal_code: '98814'
  },
  '98815': {
    country: 'US',
    city: 'Cashmere',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.5173,
    longitude: -120.5033,
    postal_code: '98815'
  },
  '98816': {
    country: 'US',
    city: 'Chelan',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.8483,
    longitude: -120.0273,
    postal_code: '98816'
  },
  '98817': {
    country: 'US',
    city: 'Chelan Falls',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.8015,
    longitude: -119.9859,
    postal_code: '98817'
  },
  '98819': {
    country: 'US',
    city: 'Conconully',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.5469,
    longitude: -119.7526,
    postal_code: '98819'
  },
  '98821': {
    country: 'US',
    city: 'Dryden',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.5412,
    longitude: -120.5609,
    postal_code: '98821'
  },
  '98822': {
    country: 'US',
    city: 'Entiat',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.7057,
    longitude: -120.276,
    postal_code: '98822'
  },
  '98823': {
    country: 'US',
    city: 'Ephrata',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.2771,
    longitude: -119.5336,
    postal_code: '98823'
  },
  '98824': {
    country: 'US',
    city: 'George',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.0792,
    longitude: -119.8601,
    postal_code: '98824'
  },
  '98826': {
    country: 'US',
    city: 'Leavenworth',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.6438,
    longitude: -120.6748,
    postal_code: '98826'
  },
  '98827': {
    country: 'US',
    city: 'Loomis',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.8696,
    longitude: -119.6427,
    postal_code: '98827'
  },
  '98828': {
    country: 'US',
    city: 'Malaga',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.3553,
    longitude: -120.2086,
    postal_code: '98828'
  },
  '98829': {
    country: 'US',
    city: 'Malott',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.248,
    longitude: -119.7525,
    postal_code: '98829'
  },
  '98830': {
    country: 'US',
    city: 'Mansfield',
    state: 'Washington',
    state_short: 'WA',
    county: 'Douglas',
    latitude: 47.9021,
    longitude: -119.4053,
    postal_code: '98830'
  },
  '98831': {
    country: 'US',
    city: 'Manson',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.8958,
    longitude: -120.149,
    postal_code: '98831'
  },
  '98832': {
    country: 'US',
    city: 'Marlin',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.3014,
    longitude: -119.064,
    postal_code: '98832'
  },
  '98833': {
    country: 'US',
    city: 'Mazama',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.5977,
    longitude: -120.388,
    postal_code: '98833'
  },
  '98834': {
    country: 'US',
    city: 'Methow',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.1171,
    longitude: -120.0891,
    postal_code: '98834'
  },
  '98836': {
    country: 'US',
    city: 'Monitor',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.4852,
    longitude: -120.4158,
    postal_code: '98836'
  },
  '98837': {
    country: 'US',
    city: 'Moses Lake',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.1374,
    longitude: -119.2891,
    postal_code: '98837'
  },
  '98840': {
    country: 'US',
    city: 'Okanogan',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.3513,
    longitude: -119.6046,
    postal_code: '98840'
  },
  '98841': {
    country: 'US',
    city: 'Omak',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.4143,
    longitude: -119.5272,
    postal_code: '98841'
  },
  '98843': {
    country: 'US',
    city: 'Orondo',
    state: 'Washington',
    state_short: 'WA',
    county: 'Douglas',
    latitude: 47.6969,
    longitude: -120.1721,
    postal_code: '98843'
  },
  '98844': {
    country: 'US',
    city: 'Oroville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.9397,
    longitude: -119.4032,
    postal_code: '98844'
  },
  '98845': {
    country: 'US',
    city: 'Palisades',
    state: 'Washington',
    state_short: 'WA',
    county: 'Douglas',
    latitude: 47.419,
    longitude: -119.9145,
    postal_code: '98845'
  },
  '98846': {
    country: 'US',
    city: 'Pateros',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.034,
    longitude: -119.9785,
    postal_code: '98846'
  },
  '98847': {
    country: 'US',
    city: 'Peshastin',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 47.5458,
    longitude: -120.5961,
    postal_code: '98847'
  },
  '98848': {
    country: 'US',
    city: 'Quincy',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.1976,
    longitude: -119.8459,
    postal_code: '98848'
  },
  '98849': {
    country: 'US',
    city: 'Riverside',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.4876,
    longitude: -119.5803,
    postal_code: '98849'
  },
  '98850': {
    country: 'US',
    city: 'Rock Island',
    state: 'Washington',
    state_short: 'WA',
    county: 'Douglas',
    latitude: 47.3706,
    longitude: -120.1378,
    postal_code: '98850'
  },
  '98851': {
    country: 'US',
    city: 'Soap Lake',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.383,
    longitude: -119.486,
    postal_code: '98851'
  },
  '98852': {
    country: 'US',
    city: 'Stehekin',
    state: 'Washington',
    state_short: 'WA',
    county: 'Chelan',
    latitude: 48.3093,
    longitude: -120.6565,
    postal_code: '98852'
  },
  '98853': {
    country: 'US',
    city: 'Stratford',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.4268,
    longitude: -119.2816,
    postal_code: '98853'
  },
  '98855': {
    country: 'US',
    city: 'Tonasket',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.7194,
    longitude: -119.3943,
    postal_code: '98855'
  },
  '98856': {
    country: 'US',
    city: 'Twisp',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.3632,
    longitude: -120.135,
    postal_code: '98856'
  },
  '98857': {
    country: 'US',
    city: 'Warden',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 46.977,
    longitude: -119.0539,
    postal_code: '98857'
  },
  '98858': {
    country: 'US',
    city: 'Waterville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Douglas',
    latitude: 47.6295,
    longitude: -119.9887,
    postal_code: '98858'
  },
  '98859': {
    country: 'US',
    city: 'Wauconda',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.8224,
    longitude: -118.9469,
    postal_code: '98859'
  },
  '98860': {
    country: 'US',
    city: 'Wilson Creek',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.4739,
    longitude: -119.1847,
    postal_code: '98860'
  },
  '98862': {
    country: 'US',
    city: 'Winthrop',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.4756,
    longitude: -120.1805,
    postal_code: '98862'
  },
  '98901': {
    country: 'US',
    city: 'Yakima',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.6021,
    longitude: -120.5059,
    postal_code: '98901'
  },
  '98902': {
    country: 'US',
    city: 'Yakima',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.5934,
    longitude: -120.5311,
    postal_code: '98902'
  },
  '98903': {
    country: 'US',
    city: 'Yakima',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.5445,
    longitude: -120.7444,
    postal_code: '98903'
  },
  '98904': {
    country: 'US',
    city: 'Yakima',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.5645,
    longitude: -120.6947,
    postal_code: '98904'
  },
  '98907': {
    country: 'US',
    city: 'Yakima',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.6288,
    longitude: -120.574,
    postal_code: '98907'
  },
  '98908': {
    country: 'US',
    city: 'Yakima',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.6165,
    longitude: -120.7094,
    postal_code: '98908'
  },
  '98909': {
    country: 'US',
    city: 'Yakima',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.6021,
    longitude: -120.5059,
    postal_code: '98909'
  },
  '98920': {
    country: 'US',
    city: 'Brownstown',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.4043,
    longitude: -120.607,
    postal_code: '98920'
  },
  '98921': {
    country: 'US',
    city: 'Buena',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.4287,
    longitude: -120.3134,
    postal_code: '98921'
  },
  '98922': {
    country: 'US',
    city: 'Cle Elum',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kittitas',
    latitude: 47.2063,
    longitude: -120.9685,
    postal_code: '98922'
  },
  '98923': {
    country: 'US',
    city: 'Cowiche',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.6661,
    longitude: -120.7149,
    postal_code: '98923'
  },
  '98925': {
    country: 'US',
    city: 'Easton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kittitas',
    latitude: 47.233,
    longitude: -121.1772,
    postal_code: '98925'
  },
  '98926': {
    country: 'US',
    city: 'Ellensburg',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kittitas',
    latitude: 46.9996,
    longitude: -120.5163,
    postal_code: '98926'
  },
  '98930': {
    country: 'US',
    city: 'Grandview',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.2538,
    longitude: -119.9157,
    postal_code: '98930'
  },
  '98932': {
    country: 'US',
    city: 'Granger',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.348,
    longitude: -120.1818,
    postal_code: '98932'
  },
  '98933': {
    country: 'US',
    city: 'Harrah',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.4104,
    longitude: -120.5736,
    postal_code: '98933'
  },
  '98934': {
    country: 'US',
    city: 'Kittitas',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kittitas',
    latitude: 46.9806,
    longitude: -120.4163,
    postal_code: '98934'
  },
  '98935': {
    country: 'US',
    city: 'Mabton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.2121,
    longitude: -120.0151,
    postal_code: '98935'
  },
  '98936': {
    country: 'US',
    city: 'Moxee',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.5542,
    longitude: -120.3685,
    postal_code: '98936'
  },
  '98937': {
    country: 'US',
    city: 'Naches',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.8058,
    longitude: -120.9921,
    postal_code: '98937'
  },
  '98938': {
    country: 'US',
    city: 'Outlook',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.3525,
    longitude: -120.097,
    postal_code: '98938'
  },
  '98939': {
    country: 'US',
    city: 'Parker',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.501,
    longitude: -120.4653,
    postal_code: '98939'
  },
  '98940': {
    country: 'US',
    city: 'Ronald',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kittitas',
    latitude: 47.2503,
    longitude: -121.0456,
    postal_code: '98940'
  },
  '98941': {
    country: 'US',
    city: 'Roslyn',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kittitas',
    latitude: 47.2233,
    longitude: -121.0025,
    postal_code: '98941'
  },
  '98942': {
    country: 'US',
    city: 'Selah',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.6767,
    longitude: -120.5408,
    postal_code: '98942'
  },
  '98943': {
    country: 'US',
    city: 'South Cle Elum',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kittitas',
    latitude: 47.1886,
    longitude: -120.9537,
    postal_code: '98943'
  },
  '98944': {
    country: 'US',
    city: 'Sunnyside',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.3213,
    longitude: -120.0126,
    postal_code: '98944'
  },
  '98946': {
    country: 'US',
    city: 'Thorp',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kittitas',
    latitude: 47.0679,
    longitude: -120.6712,
    postal_code: '98946'
  },
  '98947': {
    country: 'US',
    city: 'Tieton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.7063,
    longitude: -120.7473,
    postal_code: '98947'
  },
  '98948': {
    country: 'US',
    city: 'Toppenish',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.3751,
    longitude: -120.3305,
    postal_code: '98948'
  },
  '98950': {
    country: 'US',
    city: 'Vantage',
    state: 'Washington',
    state_short: 'WA',
    county: 'Kittitas',
    latitude: 46.8714,
    longitude: -119.9859,
    postal_code: '98950'
  },
  '98951': {
    country: 'US',
    city: 'Wapato',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.4507,
    longitude: -120.4265,
    postal_code: '98951'
  },
  '98952': {
    country: 'US',
    city: 'White Swan',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.3716,
    longitude: -120.7453,
    postal_code: '98952'
  },
  '98953': {
    country: 'US',
    city: 'Zillah',
    state: 'Washington',
    state_short: 'WA',
    county: 'Yakima',
    latitude: 46.4158,
    longitude: -120.2662,
    postal_code: '98953'
  },
  '99001': {
    country: 'US',
    city: 'Airway Heights',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6438,
    longitude: -117.5921,
    postal_code: '99001'
  },
  '99003': {
    country: 'US',
    city: 'Chattaroy',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.9192,
    longitude: -117.2921,
    postal_code: '99003'
  },
  '99004': {
    country: 'US',
    city: 'Cheney',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.4943,
    longitude: -117.5834,
    postal_code: '99004'
  },
  '99005': {
    country: 'US',
    city: 'Colbert',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.8411,
    longitude: -117.3759,
    postal_code: '99005'
  },
  '99006': {
    country: 'US',
    city: 'Deer Park',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.9543,
    longitude: -117.4769,
    postal_code: '99006'
  },
  '99008': {
    country: 'US',
    city: 'Edwall',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.5379,
    longitude: -117.9071,
    postal_code: '99008'
  },
  '99009': {
    country: 'US',
    city: 'Elk',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 48.0205,
    longitude: -117.2963,
    postal_code: '99009'
  },
  '99011': {
    country: 'US',
    city: 'Fairchild Air Force Base',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6332,
    longitude: -117.6546,
    postal_code: '99011'
  },
  '99012': {
    country: 'US',
    city: 'Fairfield',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.3987,
    longitude: -117.1921,
    postal_code: '99012'
  },
  '99013': {
    country: 'US',
    city: 'Ford',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 47.9169,
    longitude: -117.8119,
    postal_code: '99013'
  },
  '99014': {
    country: 'US',
    city: 'Four Lakes',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.5599,
    longitude: -117.5958,
    postal_code: '99014'
  },
  '99016': {
    country: 'US',
    city: 'Greenacres',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6584,
    longitude: -117.1568,
    postal_code: '99016'
  },
  '99017': {
    country: 'US',
    city: 'Lamont',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 47.1887,
    longitude: -117.8755,
    postal_code: '99017'
  },
  '99018': {
    country: 'US',
    city: 'Latah',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.2999,
    longitude: -117.1395,
    postal_code: '99018'
  },
  '99019': {
    country: 'US',
    city: 'Liberty Lake',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6517,
    longitude: -117.0838,
    postal_code: '99019'
  },
  '99020': {
    country: 'US',
    city: 'Marshall',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.5649,
    longitude: -117.4994,
    postal_code: '99020'
  },
  '99021': {
    country: 'US',
    city: 'Mead',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.7933,
    longitude: -117.3117,
    postal_code: '99021'
  },
  '99022': {
    country: 'US',
    city: 'Medical Lake',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.615,
    longitude: -117.704,
    postal_code: '99022'
  },
  '99023': {
    country: 'US',
    city: 'Mica',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.5538,
    longitude: -117.1637,
    postal_code: '99023'
  },
  '99025': {
    country: 'US',
    city: 'Newman Lake',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.7274,
    longitude: -117.064,
    postal_code: '99025'
  },
  '99026': {
    country: 'US',
    city: 'Nine Mile Falls',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.8019,
    longitude: -117.5894,
    postal_code: '99026'
  },
  '99027': {
    country: 'US',
    city: 'Otis Orchards',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.7027,
    longitude: -117.1121,
    postal_code: '99027'
  },
  '99029': {
    country: 'US',
    city: 'Reardan',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.7054,
    longitude: -117.8663,
    postal_code: '99029'
  },
  '99030': {
    country: 'US',
    city: 'Rockford',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.4528,
    longitude: -117.1318,
    postal_code: '99030'
  },
  '99031': {
    country: 'US',
    city: 'Spangle',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.4338,
    longitude: -117.3827,
    postal_code: '99031'
  },
  '99032': {
    country: 'US',
    city: 'Sprague',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.3247,
    longitude: -117.9897,
    postal_code: '99032'
  },
  '99033': {
    country: 'US',
    city: 'Tekoa',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 47.2271,
    longitude: -117.0819,
    postal_code: '99033'
  },
  '99034': {
    country: 'US',
    city: 'Tumtum',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 47.8941,
    longitude: -117.7536,
    postal_code: '99034'
  },
  '99036': {
    country: 'US',
    city: 'Valleyford',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.5292,
    longitude: -117.2686,
    postal_code: '99036'
  },
  '99037': {
    country: 'US',
    city: 'Veradale',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6421,
    longitude: -117.1977,
    postal_code: '99037'
  },
  '99039': {
    country: 'US',
    city: 'Waverly',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.3427,
    longitude: -117.228,
    postal_code: '99039'
  },
  '99040': {
    country: 'US',
    city: 'Wellpinit',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 47.8697,
    longitude: -117.9856,
    postal_code: '99040'
  },
  '99101': {
    country: 'US',
    city: 'Addy',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.3364,
    longitude: -117.9579,
    postal_code: '99101'
  },
  '99102': {
    country: 'US',
    city: 'Albion',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.792,
    longitude: -117.2503,
    postal_code: '99102'
  },
  '99103': {
    country: 'US',
    city: 'Almira',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.7632,
    longitude: -118.9123,
    postal_code: '99103'
  },
  '99104': {
    country: 'US',
    city: 'Belmont',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 47.0793,
    longitude: -117.177,
    postal_code: '99104'
  },
  '99105': {
    country: 'US',
    city: 'Benge',
    state: 'Washington',
    state_short: 'WA',
    county: 'Adams',
    latitude: 46.8591,
    longitude: -118.1611,
    postal_code: '99105'
  },
  '99107': {
    country: 'US',
    city: 'Boyds',
    state: 'Washington',
    state_short: 'WA',
    county: 'Ferry',
    latitude: 48.8427,
    longitude: -118.1828,
    postal_code: '99107'
  },
  '99109': {
    country: 'US',
    city: 'Chewelah',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.2876,
    longitude: -117.7754,
    postal_code: '99109'
  },
  '99110': {
    country: 'US',
    city: 'Clayton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.0071,
    longitude: -117.5579,
    postal_code: '99110'
  },
  '99111': {
    country: 'US',
    city: 'Colfax',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.88,
    longitude: -117.367,
    postal_code: '99111'
  },
  '99113': {
    country: 'US',
    city: 'Colton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.5901,
    longitude: -117.1692,
    postal_code: '99113'
  },
  '99114': {
    country: 'US',
    city: 'Colville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.578,
    longitude: -117.8645,
    postal_code: '99114'
  },
  '99115': {
    country: 'US',
    city: 'Coulee City',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.5966,
    longitude: -119.2758,
    postal_code: '99115'
  },
  '99116': {
    country: 'US',
    city: 'Coulee Dam',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.0379,
    longitude: -118.9419,
    postal_code: '99116'
  },
  '99117': {
    country: 'US',
    city: 'Creston',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.7977,
    longitude: -118.5307,
    postal_code: '99117'
  },
  '99118': {
    country: 'US',
    city: 'Curlew',
    state: 'Washington',
    state_short: 'WA',
    county: 'Ferry',
    latitude: 48.9108,
    longitude: -118.6452,
    postal_code: '99118'
  },
  '99119': {
    country: 'US',
    city: 'Cusick',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pend Oreille',
    latitude: 48.3915,
    longitude: -117.3295,
    postal_code: '99119'
  },
  '99121': {
    country: 'US',
    city: 'Danville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Ferry',
    latitude: 48.9725,
    longitude: -118.4884,
    postal_code: '99121'
  },
  '99122': {
    country: 'US',
    city: 'Davenport',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.6809,
    longitude: -118.1667,
    postal_code: '99122'
  },
  '99123': {
    country: 'US',
    city: 'Electric City',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.9131,
    longitude: -119.0426,
    postal_code: '99123'
  },
  '99124': {
    country: 'US',
    city: 'Elmer City',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 47.9979,
    longitude: -118.9545,
    postal_code: '99124'
  },
  '99125': {
    country: 'US',
    city: 'Endicott',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.9364,
    longitude: -117.723,
    postal_code: '99125'
  },
  '99126': {
    country: 'US',
    city: 'Evans',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.7458,
    longitude: -118.0001,
    postal_code: '99126'
  },
  '99128': {
    country: 'US',
    city: 'Farmington',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 47.0847,
    longitude: -117.0763,
    postal_code: '99128'
  },
  '99129': {
    country: 'US',
    city: 'Fruitland',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 47.9797,
    longitude: -118.2159,
    postal_code: '99129'
  },
  '99130': {
    country: 'US',
    city: 'Garfield',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.9946,
    longitude: -117.1523,
    postal_code: '99130'
  },
  '99131': {
    country: 'US',
    city: 'Gifford',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.2685,
    longitude: -118.1136,
    postal_code: '99131'
  },
  '99133': {
    country: 'US',
    city: 'Grand Coulee',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.9385,
    longitude: -118.9978,
    postal_code: '99133'
  },
  '99134': {
    country: 'US',
    city: 'Harrington',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.4555,
    longitude: -118.2778,
    postal_code: '99134'
  },
  '99135': {
    country: 'US',
    city: 'Hartline',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 47.6115,
    longitude: -119.0841,
    postal_code: '99135'
  },
  '99136': {
    country: 'US',
    city: 'Hay',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.6973,
    longitude: -117.9417,
    postal_code: '99136'
  },
  '99137': {
    country: 'US',
    city: 'Hunters',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.1333,
    longitude: -118.1525,
    postal_code: '99137'
  },
  '99138': {
    country: 'US',
    city: 'Inchelium',
    state: 'Washington',
    state_short: 'WA',
    county: 'Ferry',
    latitude: 48.2924,
    longitude: -118.3552,
    postal_code: '99138'
  },
  '99139': {
    country: 'US',
    city: 'Ione',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pend Oreille',
    latitude: 48.741,
    longitude: -117.4172,
    postal_code: '99139'
  },
  '99140': {
    country: 'US',
    city: 'Keller',
    state: 'Washington',
    state_short: 'WA',
    county: 'Ferry',
    latitude: 48.0236,
    longitude: -118.6547,
    postal_code: '99140'
  },
  '99141': {
    country: 'US',
    city: 'Kettle Falls',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.6364,
    longitude: -118.0548,
    postal_code: '99141'
  },
  '99143': {
    country: 'US',
    city: 'Lacrosse',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.7717,
    longitude: -117.7703,
    postal_code: '99143'
  },
  '99144': {
    country: 'US',
    city: 'Lamona',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.3778,
    longitude: -118.5043,
    postal_code: '99144'
  },
  '99146': {
    country: 'US',
    city: 'Laurier',
    state: 'Washington',
    state_short: 'WA',
    county: 'Ferry',
    latitude: 48.9753,
    longitude: -118.2208,
    postal_code: '99146'
  },
  '99147': {
    country: 'US',
    city: 'Lincoln',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.8396,
    longitude: -118.4426,
    postal_code: '99147'
  },
  '99148': {
    country: 'US',
    city: 'Loon Lake',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.0784,
    longitude: -117.6325,
    postal_code: '99148'
  },
  '99149': {
    country: 'US',
    city: 'Malden',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 47.2288,
    longitude: -117.4727,
    postal_code: '99149'
  },
  '99150': {
    country: 'US',
    city: 'Malo',
    state: 'Washington',
    state_short: 'WA',
    county: 'Ferry',
    latitude: 48.811,
    longitude: -118.6044,
    postal_code: '99150'
  },
  '99151': {
    country: 'US',
    city: 'Marcus',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.6632,
    longitude: -118.0683,
    postal_code: '99151'
  },
  '99152': {
    country: 'US',
    city: 'Metaline',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pend Oreille',
    latitude: 48.8496,
    longitude: -117.3963,
    postal_code: '99152'
  },
  '99153': {
    country: 'US',
    city: 'Metaline Falls',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pend Oreille',
    latitude: 48.8597,
    longitude: -117.3633,
    postal_code: '99153'
  },
  '99154': {
    country: 'US',
    city: 'Mohler',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.3897,
    longitude: -118.3659,
    postal_code: '99154'
  },
  '99155': {
    country: 'US',
    city: 'Nespelem',
    state: 'Washington',
    state_short: 'WA',
    county: 'Okanogan',
    latitude: 48.1434,
    longitude: -119.0094,
    postal_code: '99155'
  },
  '99156': {
    country: 'US',
    city: 'Newport',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pend Oreille',
    latitude: 48.1695,
    longitude: -117.1508,
    postal_code: '99156'
  },
  '99157': {
    country: 'US',
    city: 'Northport',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.9247,
    longitude: -117.7931,
    postal_code: '99157'
  },
  '99158': {
    country: 'US',
    city: 'Oakesdale',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 47.1282,
    longitude: -117.2427,
    postal_code: '99158'
  },
  '99159': {
    country: 'US',
    city: 'Odessa',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.3395,
    longitude: -118.6983,
    postal_code: '99159'
  },
  '99160': {
    country: 'US',
    city: 'Orient',
    state: 'Washington',
    state_short: 'WA',
    county: 'Ferry',
    latitude: 48.866,
    longitude: -118.2028,
    postal_code: '99160'
  },
  '99161': {
    country: 'US',
    city: 'Palouse',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.9076,
    longitude: -117.0855,
    postal_code: '99161'
  },
  '99163': {
    country: 'US',
    city: 'Pullman',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.7352,
    longitude: -117.1729,
    postal_code: '99163'
  },
  '99164': {
    country: 'US',
    city: 'Pullman',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.7313,
    longitude: -117.1796,
    postal_code: '99164'
  },
  '99166': {
    country: 'US',
    city: 'Republic',
    state: 'Washington',
    state_short: 'WA',
    county: 'Ferry',
    latitude: 48.6704,
    longitude: -118.6999,
    postal_code: '99166'
  },
  '99167': {
    country: 'US',
    city: 'Rice',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.4062,
    longitude: -118.1249,
    postal_code: '99167'
  },
  '99169': {
    country: 'US',
    city: 'Ritzville',
    state: 'Washington',
    state_short: 'WA',
    county: 'Adams',
    latitude: 47.1315,
    longitude: -118.3958,
    postal_code: '99169'
  },
  '99170': {
    country: 'US',
    city: 'Rosalia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 47.2218,
    longitude: -117.4147,
    postal_code: '99170'
  },
  '99171': {
    country: 'US',
    city: 'Saint John',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 47.0755,
    longitude: -117.573,
    postal_code: '99171'
  },
  '99173': {
    country: 'US',
    city: 'Springdale',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.0022,
    longitude: -117.829,
    postal_code: '99173'
  },
  '99174': {
    country: 'US',
    city: 'Steptoe',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 47.006,
    longitude: -117.356,
    postal_code: '99174'
  },
  '99176': {
    country: 'US',
    city: 'Thornton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 47.1253,
    longitude: -117.3864,
    postal_code: '99176'
  },
  '99179': {
    country: 'US',
    city: 'Uniontown',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.5258,
    longitude: -117.0908,
    postal_code: '99179'
  },
  '99180': {
    country: 'US',
    city: 'Usk',
    state: 'Washington',
    state_short: 'WA',
    county: 'Pend Oreille',
    latitude: 48.296,
    longitude: -117.3189,
    postal_code: '99180'
  },
  '99181': {
    country: 'US',
    city: 'Valley',
    state: 'Washington',
    state_short: 'WA',
    county: 'Stevens',
    latitude: 48.1351,
    longitude: -117.761,
    postal_code: '99181'
  },
  '99185': {
    country: 'US',
    city: 'Wilbur',
    state: 'Washington',
    state_short: 'WA',
    county: 'Lincoln',
    latitude: 47.741,
    longitude: -118.7063,
    postal_code: '99185'
  },
  '99201': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6665,
    longitude: -117.4365,
    postal_code: '99201'
  },
  '99202': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6547,
    longitude: -117.381,
    postal_code: '99202'
  },
  '99203': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6294,
    longitude: -117.4041,
    postal_code: '99203'
  },
  '99204': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6501,
    longitude: -117.4298,
    postal_code: '99204'
  },
  '99205': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6964,
    longitude: -117.4399,
    postal_code: '99205'
  },
  '99206': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6496,
    longitude: -117.2581,
    postal_code: '99206'
  },
  '99207': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6977,
    longitude: -117.3746,
    postal_code: '99207'
  },
  '99208': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.7374,
    longitude: -117.4352,
    postal_code: '99208'
  },
  '99209': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6597,
    longitude: -117.4291,
    postal_code: '99209'
  },
  '99210': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99210'
  },
  '99211': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99211'
  },
  '99212': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6686,
    longitude: -117.3049,
    postal_code: '99212'
  },
  '99213': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6567,
    longitude: -117.2825,
    postal_code: '99213'
  },
  '99214': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99214'
  },
  '99215': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99215'
  },
  '99216': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6634,
    longitude: -117.2193,
    postal_code: '99216'
  },
  '99217': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.738,
    longitude: -117.2557,
    postal_code: '99217'
  },
  '99218': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.7556,
    longitude: -117.4146,
    postal_code: '99218'
  },
  '99219': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99219'
  },
  '99220': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99220'
  },
  '99223': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6156,
    longitude: -117.3622,
    postal_code: '99223'
  },
  '99224': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6289,
    longitude: -117.5513,
    postal_code: '99224'
  },
  '99228': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99228'
  },
  '99251': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99251'
  },
  '99252': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99252'
  },
  '99256': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99256'
  },
  '99258': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99258'
  },
  '99260': {
    country: 'US',
    city: 'Spokane',
    state: 'Washington',
    state_short: 'WA',
    county: 'Spokane',
    latitude: 47.6536,
    longitude: -117.4317,
    postal_code: '99260'
  },
  '99301': {
    country: 'US',
    city: 'Pasco',
    state: 'Washington',
    state_short: 'WA',
    county: 'Franklin',
    latitude: 46.2492,
    longitude: -119.1044,
    postal_code: '99301'
  },
  '99302': {
    country: 'US',
    city: 'Pasco',
    state: 'Washington',
    state_short: 'WA',
    county: 'Franklin',
    latitude: 46.235,
    longitude: -119.0943,
    postal_code: '99302'
  },
  '99320': {
    country: 'US',
    city: 'Benton City',
    state: 'Washington',
    state_short: 'WA',
    county: 'Benton',
    latitude: 46.2806,
    longitude: -119.4913,
    postal_code: '99320'
  },
  '99321': {
    country: 'US',
    city: 'Beverly',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 46.8484,
    longitude: -119.9121,
    postal_code: '99321'
  },
  '99322': {
    country: 'US',
    city: 'Bickleton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.9597,
    longitude: -120.1042,
    postal_code: '99322'
  },
  '99323': {
    country: 'US',
    city: 'Burbank',
    state: 'Washington',
    state_short: 'WA',
    county: 'Walla Walla',
    latitude: 46.1966,
    longitude: -118.9017,
    postal_code: '99323'
  },
  '99324': {
    country: 'US',
    city: 'College Place',
    state: 'Washington',
    state_short: 'WA',
    county: 'Walla Walla',
    latitude: 46.0471,
    longitude: -118.4093,
    postal_code: '99324'
  },
  '99326': {
    country: 'US',
    city: 'Connell',
    state: 'Washington',
    state_short: 'WA',
    county: 'Franklin',
    latitude: 46.6643,
    longitude: -118.8545,
    postal_code: '99326'
  },
  '99328': {
    country: 'US',
    city: 'Dayton',
    state: 'Washington',
    state_short: 'WA',
    county: 'Columbia',
    latitude: 46.3075,
    longitude: -117.9738,
    postal_code: '99328'
  },
  '99329': {
    country: 'US',
    city: 'Dixie',
    state: 'Washington',
    state_short: 'WA',
    county: 'Walla Walla',
    latitude: 46.1408,
    longitude: -118.1531,
    postal_code: '99329'
  },
  '99330': {
    country: 'US',
    city: 'Eltopia',
    state: 'Washington',
    state_short: 'WA',
    county: 'Franklin',
    latitude: 46.475,
    longitude: -119.1013,
    postal_code: '99330'
  },
  '99333': {
    country: 'US',
    city: 'Hooper',
    state: 'Washington',
    state_short: 'WA',
    county: 'Whitman',
    latitude: 46.7543,
    longitude: -118.148,
    postal_code: '99333'
  },
  '99335': {
    country: 'US',
    city: 'Kahlotus',
    state: 'Washington',
    state_short: 'WA',
    county: 'Franklin',
    latitude: 46.6779,
    longitude: -118.5337,
    postal_code: '99335'
  },
  '99336': {
    country: 'US',
    city: 'Kennewick',
    state: 'Washington',
    state_short: 'WA',
    county: 'Benton',
    latitude: 46.2109,
    longitude: -119.168,
    postal_code: '99336'
  },
  '99337': {
    country: 'US',
    city: 'Kennewick',
    state: 'Washington',
    state_short: 'WA',
    county: 'Benton',
    latitude: 46.1814,
    longitude: -119.1383,
    postal_code: '99337'
  },
  '99338': {
    country: 'US',
    city: 'Kennewick',
    state: 'Washington',
    state_short: 'WA',
    county: 'Benton',
    latitude: 46.156,
    longitude: -119.264,
    postal_code: '99338'
  },
  '99341': {
    country: 'US',
    city: 'Lind',
    state: 'Washington',
    state_short: 'WA',
    county: 'Adams',
    latitude: 46.956,
    longitude: -118.7061,
    postal_code: '99341'
  },
  '99343': {
    country: 'US',
    city: 'Mesa',
    state: 'Washington',
    state_short: 'WA',
    county: 'Franklin',
    latitude: 46.5782,
    longitude: -119.1373,
    postal_code: '99343'
  },
  '99344': {
    country: 'US',
    city: 'Othello',
    state: 'Washington',
    state_short: 'WA',
    county: 'Adams',
    latitude: 46.8527,
    longitude: -118.9932,
    postal_code: '99344'
  },
  '99345': {
    country: 'US',
    city: 'Paterson',
    state: 'Washington',
    state_short: 'WA',
    county: 'Benton',
    latitude: 45.9911,
    longitude: -119.7559,
    postal_code: '99345'
  },
  '99346': {
    country: 'US',
    city: 'Plymouth',
    state: 'Washington',
    state_short: 'WA',
    county: 'Benton',
    latitude: 45.9301,
    longitude: -119.4023,
    postal_code: '99346'
  },
  '99347': {
    country: 'US',
    city: 'Pomeroy',
    state: 'Washington',
    state_short: 'WA',
    county: 'Garfield',
    latitude: 46.4698,
    longitude: -117.5993,
    postal_code: '99347'
  },
  '99348': {
    country: 'US',
    city: 'Prescott',
    state: 'Washington',
    state_short: 'WA',
    county: 'Walla Walla',
    latitude: 46.3539,
    longitude: -118.4097,
    postal_code: '99348'
  },
  '99349': {
    country: 'US',
    city: 'Mattawa',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 46.731,
    longitude: -119.7783,
    postal_code: '99349'
  },
  '99350': {
    country: 'US',
    city: 'Prosser',
    state: 'Washington',
    state_short: 'WA',
    county: 'Benton',
    latitude: 46.2232,
    longitude: -119.771,
    postal_code: '99350'
  },
  '99352': {
    country: 'US',
    city: 'Richland',
    state: 'Washington',
    state_short: 'WA',
    county: 'Benton',
    latitude: 46.2522,
    longitude: -119.288,
    postal_code: '99352'
  },
  '99353': {
    country: 'US',
    city: 'West Richland',
    state: 'Washington',
    state_short: 'WA',
    county: 'Benton',
    latitude: 46.3153,
    longitude: -119.3714,
    postal_code: '99353'
  },
  '99354': {
    country: 'US',
    city: 'Richland',
    state: 'Washington',
    state_short: 'WA',
    county: 'Benton',
    latitude: 46.3257,
    longitude: -119.3067,
    postal_code: '99354'
  },
  '99356': {
    country: 'US',
    city: 'Roosevelt',
    state: 'Washington',
    state_short: 'WA',
    county: 'Klickitat',
    latitude: 45.8514,
    longitude: -120.3543,
    postal_code: '99356'
  },
  '99357': {
    country: 'US',
    city: 'Royal City',
    state: 'Washington',
    state_short: 'WA',
    county: 'Grant',
    latitude: 46.9156,
    longitude: -119.5815,
    postal_code: '99357'
  },
  '99359': {
    country: 'US',
    city: 'Starbuck',
    state: 'Washington',
    state_short: 'WA',
    county: 'Columbia',
    latitude: 46.5128,
    longitude: -118.1024,
    postal_code: '99359'
  },
  '99360': {
    country: 'US',
    city: 'Touchet',
    state: 'Washington',
    state_short: 'WA',
    county: 'Walla Walla',
    latitude: 46.0903,
    longitude: -118.663,
    postal_code: '99360'
  },
  '99361': {
    country: 'US',
    city: 'Waitsburg',
    state: 'Washington',
    state_short: 'WA',
    county: 'Walla Walla',
    latitude: 46.2691,
    longitude: -118.1447,
    postal_code: '99361'
  },
  '99362': {
    country: 'US',
    city: 'Walla Walla',
    state: 'Washington',
    state_short: 'WA',
    county: 'Walla Walla',
    latitude: 46.0614,
    longitude: -118.3315,
    postal_code: '99362'
  },
  '99363': {
    country: 'US',
    city: 'Wallula',
    state: 'Washington',
    state_short: 'WA',
    county: 'Walla Walla',
    latitude: 46.0846,
    longitude: -118.9061,
    postal_code: '99363'
  },
  '99371': {
    country: 'US',
    city: 'Washtucna',
    state: 'Washington',
    state_short: 'WA',
    county: 'Adams',
    latitude: 46.8209,
    longitude: -118.2862,
    postal_code: '99371'
  },
  '99401': {
    country: 'US',
    city: 'Anatone',
    state: 'Washington',
    state_short: 'WA',
    county: 'Asotin',
    latitude: 46.1285,
    longitude: -117.0883,
    postal_code: '99401'
  },
  '99402': {
    country: 'US',
    city: 'Asotin',
    state: 'Washington',
    state_short: 'WA',
    county: 'Asotin',
    latitude: 46.1343,
    longitude: -117.0015,
    postal_code: '99402'
  },
  '99403': {
    country: 'US',
    city: 'Clarkston',
    state: 'Washington',
    state_short: 'WA',
    county: 'Asotin',
    latitude: 46.3946,
    longitude: -117.0645,
    postal_code: '99403'
  },
  '99501': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.2116,
    longitude: -149.8761,
    postal_code: '99501'
  },
  '99502': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1661,
    longitude: -149.96,
    postal_code: '99502'
  },
  '99503': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.19,
    longitude: -149.8938,
    postal_code: '99503'
  },
  '99504': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.2037,
    longitude: -149.7447,
    postal_code: '99504'
  },
  '99505': {
    country: 'US',
    city: 'Jber',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 59.8666,
    longitude: -158.5996,
    postal_code: '99505'
  },
  '99506': {
    country: 'US',
    city: 'Jber',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 59.8666,
    longitude: -158.5996,
    postal_code: '99506'
  },
  '99507': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1535,
    longitude: -149.8289,
    postal_code: '99507'
  },
  '99508': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.206,
    longitude: -149.8101,
    postal_code: '99508'
  },
  '99509': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.2181,
    longitude: -149.9003,
    postal_code: '99509'
  },
  '99510': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1446,
    longitude: -149.8784,
    postal_code: '99510'
  },
  '99511': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.0683,
    longitude: -149.8005,
    postal_code: '99511'
  },
  '99513': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.2149,
    longitude: -149.8862,
    postal_code: '99513'
  },
  '99514': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.2181,
    longitude: -149.9003,
    postal_code: '99514'
  },
  '99515': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1194,
    longitude: -149.8974,
    postal_code: '99515'
  },
  '99516': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1054,
    longitude: -149.78,
    postal_code: '99516'
  },
  '99517': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1901,
    longitude: -149.9361,
    postal_code: '99517'
  },
  '99518': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1549,
    longitude: -149.8866,
    postal_code: '99518'
  },
  '99519': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1089,
    longitude: -149.4403,
    postal_code: '99519'
  },
  '99520': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.2181,
    longitude: -149.9003,
    postal_code: '99520'
  },
  '99521': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.2181,
    longitude: -149.9003,
    postal_code: '99521'
  },
  '99522': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.2181,
    longitude: -149.9003,
    postal_code: '99522'
  },
  '99523': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.2181,
    longitude: -149.9003,
    postal_code: '99523'
  },
  '99524': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.2181,
    longitude: -149.9003,
    postal_code: '99524'
  },
  '99529': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1515,
    longitude: -149.9443,
    postal_code: '99529'
  },
  '99530': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1515,
    longitude: -149.9443,
    postal_code: '99530'
  },
  '99540': {
    country: 'US',
    city: 'Indian',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1089,
    longitude: -149.4403,
    postal_code: '99540'
  },
  '99545': {
    country: 'US',
    city: 'Kongiganak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 59.9599,
    longitude: -162.8919,
    postal_code: '99545'
  },
  '99546': {
    country: 'US',
    city: 'Adak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians West (CA)',
    latitude: 51.874,
    longitude: -176.634,
    postal_code: '99546'
  },
  '99547': {
    country: 'US',
    city: 'Atka',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians West (CA)',
    latitude: 52.1961,
    longitude: -174.2006,
    postal_code: '99547'
  },
  '99548': {
    country: 'US',
    city: 'Chignik Lake',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 56.2448,
    longitude: -158.7578,
    postal_code: '99548'
  },
  '99549': {
    country: 'US',
    city: 'Port Heiden',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 56.9643,
    longitude: -158.5664,
    postal_code: '99549'
  },
  '99550': {
    country: 'US',
    city: 'Port Lions',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kodiak Island',
    latitude: 57.8675,
    longitude: -152.8822,
    postal_code: '99550'
  },
  '99551': {
    country: 'US',
    city: 'Akiachak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.9094,
    longitude: -161.4314,
    postal_code: '99551'
  },
  '99552': {
    country: 'US',
    city: 'Akiak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.9122,
    longitude: -161.2139,
    postal_code: '99552'
  },
  '99553': {
    country: 'US',
    city: 'Akutan',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians East',
    latitude: 54.143,
    longitude: -165.7854,
    postal_code: '99553'
  },
  '99554': {
    country: 'US',
    city: 'Alakanuk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 62.6944,
    longitude: -164.6475,
    postal_code: '99554'
  },
  '99555': {
    country: 'US',
    city: 'Aleknagik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Dillingham (CA)',
    latitude: 59.2697,
    longitude: -158.6199,
    postal_code: '99555'
  },
  '99556': {
    country: 'US',
    city: 'Anchor Point',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 59.8371,
    longitude: -151.7078,
    postal_code: '99556'
  },
  '99557': {
    country: 'US',
    city: 'Aniak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 61.5783,
    longitude: -159.5222,
    postal_code: '99557'
  },
  '99558': {
    country: 'US',
    city: 'Anvik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 62.6561,
    longitude: -160.2067,
    postal_code: '99558'
  },
  '99559': {
    country: 'US',
    city: 'Bethel',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.7922,
    longitude: -161.7558,
    postal_code: '99559'
  },
  '99561': {
    country: 'US',
    city: 'Chefornak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.1537,
    longitude: -164.2103,
    postal_code: '99561'
  },
  '99563': {
    country: 'US',
    city: 'Chevak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 61.529,
    longitude: -165.5905,
    postal_code: '99563'
  },
  '99564': {
    country: 'US',
    city: 'Chignik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 56.3016,
    longitude: -158.4157,
    postal_code: '99564'
  },
  '99565': {
    country: 'US',
    city: 'Chignik Lagoon',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 58.2687,
    longitude: -156.6484,
    postal_code: '99565'
  },
  '99566': {
    country: 'US',
    city: 'Chitina',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Valdez-Cordova (CA)',
    latitude: 61.5156,
    longitude: -144.4396,
    postal_code: '99566'
  },
  '99567': {
    country: 'US',
    city: 'Chugiak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.4098,
    longitude: -149.4537,
    postal_code: '99567'
  },
  '99568': {
    country: 'US',
    city: 'Clam Gulch',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.2311,
    longitude: -151.3936,
    postal_code: '99568'
  },
  '99569': {
    country: 'US',
    city: 'Clarks Point',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Dillingham (CA)',
    latitude: 58.8173,
    longitude: -158.5299,
    postal_code: '99569'
  },
  '99571': {
    country: 'US',
    city: 'Cold Bay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians East',
    latitude: 55.1858,
    longitude: -162.7211,
    postal_code: '99571'
  },
  '99572': {
    country: 'US',
    city: 'Cooper Landing',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.4767,
    longitude: -149.8235,
    postal_code: '99572'
  },
  '99573': {
    country: 'US',
    city: 'Copper Center',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Valdez-Cordova (CA)',
    latitude: 61.977,
    longitude: -145.3297,
    postal_code: '99573'
  },
  '99574': {
    country: 'US',
    city: 'Cordova',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Valdez-Cordova (CA)',
    latitude: 60.5362,
    longitude: -145.7534,
    postal_code: '99574'
  },
  '99575': {
    country: 'US',
    city: 'Crooked Creek',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 61.8181,
    longitude: -158.0025,
    postal_code: '99575'
  },
  '99576': {
    country: 'US',
    city: 'Dillingham',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Dillingham (CA)',
    latitude: 59.0402,
    longitude: -158.5231,
    postal_code: '99576'
  },
  '99577': {
    country: 'US',
    city: 'Eagle River',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.3114,
    longitude: -149.5085,
    postal_code: '99577'
  },
  '99578': {
    country: 'US',
    city: 'Eek',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.2151,
    longitude: -162.0323,
    postal_code: '99578'
  },
  '99579': {
    country: 'US',
    city: 'Egegik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 58.2062,
    longitude: -157.3422,
    postal_code: '99579'
  },
  '99580': {
    country: 'US',
    city: 'Ekwok',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Dillingham (CA)',
    latitude: 59.3628,
    longitude: -157.4782,
    postal_code: '99580'
  },
  '99581': {
    country: 'US',
    city: 'Emmonak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 62.7818,
    longitude: -164.5335,
    postal_code: '99581'
  },
  '99583': {
    country: 'US',
    city: 'False Pass',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians East',
    latitude: 54.8542,
    longitude: -163.4113,
    postal_code: '99583'
  },
  '99585': {
    country: 'US',
    city: 'Marshall',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 61.9287,
    longitude: -162.268,
    postal_code: '99585'
  },
  '99586': {
    country: 'US',
    city: 'Gakona',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Valdez-Cordova (CA)',
    latitude: 62.3014,
    longitude: -145.3027,
    postal_code: '99586'
  },
  '99587': {
    country: 'US',
    city: 'Girdwood',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 60.9577,
    longitude: -149.1406,
    postal_code: '99587'
  },
  '99588': {
    country: 'US',
    city: 'Glennallen',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Valdez-Cordova (CA)',
    latitude: 62.1039,
    longitude: -145.6617,
    postal_code: '99588'
  },
  '99589': {
    country: 'US',
    city: 'Goodnews Bay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 59.1189,
    longitude: -161.5875,
    postal_code: '99589'
  },
  '99590': {
    country: 'US',
    city: 'Grayling',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 62.9036,
    longitude: -160.0647,
    postal_code: '99590'
  },
  '99591': {
    country: 'US',
    city: 'Saint George Island',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians West (CA)',
    latitude: 56.5944,
    longitude: -169.6186,
    postal_code: '99591'
  },
  '99599': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1872,
    longitude: -149.8804,
    postal_code: '99599'
  },
  '99602': {
    country: 'US',
    city: 'Holy Cross',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 62.1926,
    longitude: -159.8251,
    postal_code: '99602'
  },
  '99603': {
    country: 'US',
    city: 'Homer',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 59.5374,
    longitude: -151.2356,
    postal_code: '99603'
  },
  '99604': {
    country: 'US',
    city: 'Hooper Bay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 61.527,
    longitude: -166.1151,
    postal_code: '99604'
  },
  '99605': {
    country: 'US',
    city: 'Hope',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.8726,
    longitude: -149.4683,
    postal_code: '99605'
  },
  '99606': {
    country: 'US',
    city: 'Iliamna',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 59.7525,
    longitude: -154.8236,
    postal_code: '99606'
  },
  '99607': {
    country: 'US',
    city: 'Kalskag',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 61.541,
    longitude: -160.3261,
    postal_code: '99607'
  },
  '99608': {
    country: 'US',
    city: 'Karluk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kodiak Island',
    latitude: 57.5689,
    longitude: -154.4551,
    postal_code: '99608'
  },
  '99609': {
    country: 'US',
    city: 'Kasigluk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.8956,
    longitude: -162.5181,
    postal_code: '99609'
  },
  '99610': {
    country: 'US',
    city: 'Kasilof',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.3164,
    longitude: -151.2896,
    postal_code: '99610'
  },
  '99611': {
    country: 'US',
    city: 'Kenai',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.6145,
    longitude: -151.2546,
    postal_code: '99611'
  },
  '99612': {
    country: 'US',
    city: 'King Cove',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians East',
    latitude: 55.0628,
    longitude: -162.3056,
    postal_code: '99612'
  },
  '99613': {
    country: 'US',
    city: 'King Salmon',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bristol Bay',
    latitude: 58.6883,
    longitude: -156.6614,
    postal_code: '99613'
  },
  '99614': {
    country: 'US',
    city: 'Kipnuk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 59.9232,
    longitude: -164.101,
    postal_code: '99614'
  },
  '99615': {
    country: 'US',
    city: 'Kodiak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kodiak Island',
    latitude: 57.6036,
    longitude: -153.3751,
    postal_code: '99615'
  },
  '99619': {
    country: 'US',
    city: 'Kodiak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kodiak Island',
    latitude: 57.79,
    longitude: -152.4072,
    postal_code: '99619'
  },
  '99620': {
    country: 'US',
    city: 'Kotlik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 63.0295,
    longitude: -163.5542,
    postal_code: '99620'
  },
  '99621': {
    country: 'US',
    city: 'Kwethluk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.8122,
    longitude: -161.4358,
    postal_code: '99621'
  },
  '99622': {
    country: 'US',
    city: 'Kwigillingok',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 59.8644,
    longitude: -163.1342,
    postal_code: '99622'
  },
  '99623': {
    country: 'US',
    city: 'Wasilla',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 61.5463,
    longitude: -149.5901,
    postal_code: '99623'
  },
  '99624': {
    country: 'US',
    city: 'Larsen Bay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kodiak Island',
    latitude: 57.54,
    longitude: -153.9786,
    postal_code: '99624'
  },
  '99625': {
    country: 'US',
    city: 'Levelock',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 59.2905,
    longitude: -156.6503,
    postal_code: '99625'
  },
  '99626': {
    country: 'US',
    city: 'Lower Kalskag',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 61.5138,
    longitude: -160.36,
    postal_code: '99626'
  },
  '99627': {
    country: 'US',
    city: 'Mc Grath',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 62.9457,
    longitude: -155.5712,
    postal_code: '99627'
  },
  '99628': {
    country: 'US',
    city: 'Manokotak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Dillingham (CA)',
    latitude: 59.0096,
    longitude: -158.9897,
    postal_code: '99628'
  },
  '99629': {
    country: 'US',
    city: 'Wasilla',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 61.5841,
    longitude: -149.4436,
    postal_code: '99629'
  },
  '99630': {
    country: 'US',
    city: 'Mekoryuk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.3657,
    longitude: -166.2836,
    postal_code: '99630'
  },
  '99631': {
    country: 'US',
    city: 'Moose Pass',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.6148,
    longitude: -149.4056,
    postal_code: '99631'
  },
  '99632': {
    country: 'US',
    city: 'Mountain Village',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 62.1123,
    longitude: -163.6644,
    postal_code: '99632'
  },
  '99633': {
    country: 'US',
    city: 'Naknek',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bristol Bay',
    latitude: 58.783,
    longitude: -156.8992,
    postal_code: '99633'
  },
  '99634': {
    country: 'US',
    city: 'Napakiak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.6967,
    longitude: -161.9519,
    postal_code: '99634'
  },
  '99635': {
    country: 'US',
    city: 'Nikiski',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.7007,
    longitude: -151.2766,
    postal_code: '99635'
  },
  '99636': {
    country: 'US',
    city: 'New Stuyahok',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Dillingham (CA)',
    latitude: 59.4882,
    longitude: -157.2905,
    postal_code: '99636'
  },
  '99637': {
    country: 'US',
    city: 'Toksook Bay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.5338,
    longitude: -165.1037,
    postal_code: '99637'
  },
  '99638': {
    country: 'US',
    city: 'Nikolski',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians West (CA)',
    latitude: 52.9381,
    longitude: -168.8678,
    postal_code: '99638'
  },
  '99639': {
    country: 'US',
    city: 'Ninilchik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.0108,
    longitude: -151.6396,
    postal_code: '99639'
  },
  '99640': {
    country: 'US',
    city: 'Nondalton',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 59.9736,
    longitude: -154.8458,
    postal_code: '99640'
  },
  '99641': {
    country: 'US',
    city: 'Nunapitchuk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.8969,
    longitude: -162.4594,
    postal_code: '99641'
  },
  '99643': {
    country: 'US',
    city: 'Old Harbor',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kodiak Island',
    latitude: 57.2028,
    longitude: -153.3039,
    postal_code: '99643'
  },
  '99644': {
    country: 'US',
    city: 'Ouzinkie',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kodiak Island',
    latitude: 57.9236,
    longitude: -152.5022,
    postal_code: '99644'
  },
  '99645': {
    country: 'US',
    city: 'Palmer',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 61.6303,
    longitude: -148.9872,
    postal_code: '99645'
  },
  '99647': {
    country: 'US',
    city: 'Pedro Bay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 59.8681,
    longitude: -154.0757,
    postal_code: '99647'
  },
  '99648': {
    country: 'US',
    city: 'Perryville',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 55.9453,
    longitude: -159.2593,
    postal_code: '99648'
  },
  '99649': {
    country: 'US',
    city: 'Pilot Point',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 57.5642,
    longitude: -157.5792,
    postal_code: '99649'
  },
  '99650': {
    country: 'US',
    city: 'Pilot Station',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 61.9462,
    longitude: -162.8747,
    postal_code: '99650'
  },
  '99651': {
    country: 'US',
    city: 'Platinum',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 59.0131,
    longitude: -161.8164,
    postal_code: '99651'
  },
  '99652': {
    country: 'US',
    city: 'Big Lake',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 61.5214,
    longitude: -149.9544,
    postal_code: '99652'
  },
  '99653': {
    country: 'US',
    city: 'Port Alsworth',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Lake and Peninsula',
    latitude: 60.2025,
    longitude: -154.3128,
    postal_code: '99653'
  },
  '99654': {
    country: 'US',
    city: 'Wasilla',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 61.5923,
    longitude: -149.3959,
    postal_code: '99654'
  },
  '99655': {
    country: 'US',
    city: 'Quinhagak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 59.7381,
    longitude: -161.8749,
    postal_code: '99655'
  },
  '99656': {
    country: 'US',
    city: 'Red Devil',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 61.7611,
    longitude: -157.3125,
    postal_code: '99656'
  },
  '99657': {
    country: 'US',
    city: 'Russian Mission',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 61.7744,
    longitude: -161.3939,
    postal_code: '99657'
  },
  '99658': {
    country: 'US',
    city: 'Saint Marys',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 62.1172,
    longitude: -163.2376,
    postal_code: '99658'
  },
  '99659': {
    country: 'US',
    city: 'Saint Michael',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 63.4776,
    longitude: -162.1091,
    postal_code: '99659'
  },
  '99660': {
    country: 'US',
    city: 'Saint Paul Island',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians West (CA)',
    latitude: 57.1842,
    longitude: -170.2764,
    postal_code: '99660'
  },
  '99661': {
    country: 'US',
    city: 'Sand Point',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians East',
    latitude: 55.3192,
    longitude: -160.4914,
    postal_code: '99661'
  },
  '99662': {
    country: 'US',
    city: 'Scammon Bay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 61.845,
    longitude: -165.5819,
    postal_code: '99662'
  },
  '99663': {
    country: 'US',
    city: 'Seldovia',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 59.4495,
    longitude: -151.7009,
    postal_code: '99663'
  },
  '99664': {
    country: 'US',
    city: 'Seward',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.1329,
    longitude: -149.3985,
    postal_code: '99664'
  },
  '99665': {
    country: 'US',
    city: 'Shageluk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 62.6822,
    longitude: -159.5619,
    postal_code: '99665'
  },
  '99666': {
    country: 'US',
    city: 'Nunam Iqua',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kusilvak',
    latitude: 62.5309,
    longitude: -164.8496,
    postal_code: '99666'
  },
  '99667': {
    country: 'US',
    city: 'Skwentna',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 61.9903,
    longitude: -151.3978,
    postal_code: '99667'
  },
  '99668': {
    country: 'US',
    city: 'Sleetmute',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 61.7025,
    longitude: -157.1697,
    postal_code: '99668'
  },
  '99669': {
    country: 'US',
    city: 'Soldotna',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.4818,
    longitude: -151.1358,
    postal_code: '99669'
  },
  '99670': {
    country: 'US',
    city: 'South Naknek',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bristol Bay',
    latitude: 58.6639,
    longitude: -156.9756,
    postal_code: '99670'
  },
  '99671': {
    country: 'US',
    city: 'Stebbins',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 63.5222,
    longitude: -162.2881,
    postal_code: '99671'
  },
  '99672': {
    country: 'US',
    city: 'Sterling',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 60.5136,
    longitude: -150.8532,
    postal_code: '99672'
  },
  '99674': {
    country: 'US',
    city: 'Sutton',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 61.7327,
    longitude: -148.7837,
    postal_code: '99674'
  },
  '99675': {
    country: 'US',
    city: 'Takotna',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 62.9886,
    longitude: -156.0642,
    postal_code: '99675'
  },
  '99676': {
    country: 'US',
    city: 'Talkeetna',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 62.2605,
    longitude: -150.1101,
    postal_code: '99676'
  },
  '99677': {
    country: 'US',
    city: 'Tatitlek',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Valdez-Cordova (CA)',
    latitude: 60.8906,
    longitude: -146.6703,
    postal_code: '99677'
  },
  '99678': {
    country: 'US',
    city: 'Togiak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Dillingham (CA)',
    latitude: 59.0619,
    longitude: -160.3764,
    postal_code: '99678'
  },
  '99679': {
    country: 'US',
    city: 'Tuluksak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 61.1088,
    longitude: -160.9389,
    postal_code: '99679'
  },
  '99680': {
    country: 'US',
    city: 'Tuntutuliak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.3431,
    longitude: -162.6631,
    postal_code: '99680'
  },
  '99681': {
    country: 'US',
    city: 'Tununak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.5856,
    longitude: -165.2558,
    postal_code: '99681'
  },
  '99682': {
    country: 'US',
    city: 'Tyonek',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kenai Peninsula',
    latitude: 61.0681,
    longitude: -151.1369,
    postal_code: '99682'
  },
  '99683': {
    country: 'US',
    city: 'Trapper Creek',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 62.3167,
    longitude: -150.2314,
    postal_code: '99683'
  },
  '99684': {
    country: 'US',
    city: 'Unalakleet',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 63.8835,
    longitude: -160.7884,
    postal_code: '99684'
  },
  '99685': {
    country: 'US',
    city: 'Unalaska',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians West (CA)',
    latitude: 53.8871,
    longitude: -166.5199,
    postal_code: '99685'
  },
  '99686': {
    country: 'US',
    city: 'Valdez',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Valdez-Cordova (CA)',
    latitude: 61.101,
    longitude: -146.9,
    postal_code: '99686'
  },
  '99687': {
    country: 'US',
    city: 'Wasilla',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 61.5313,
    longitude: -149.4784,
    postal_code: '99687'
  },
  '99688': {
    country: 'US',
    city: 'Willow',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 61.8966,
    longitude: -150.0038,
    postal_code: '99688'
  },
  '99689': {
    country: 'US',
    city: 'Yakutat',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Skagway-Hoonah-Angoon',
    latitude: 59.812,
    longitude: -139.5505,
    postal_code: '99689'
  },
  '99690': {
    country: 'US',
    city: 'Nightmute',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Bethel Census Area',
    latitude: 60.4794,
    longitude: -164.7239,
    postal_code: '99690'
  },
  '99691': {
    country: 'US',
    city: 'Nikolai',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 63.0133,
    longitude: -154.375,
    postal_code: '99691'
  },
  '99692': {
    country: 'US',
    city: 'Dutch Harbor',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Aleutians West (CA)',
    latitude: 53.8898,
    longitude: -166.5422,
    postal_code: '99692'
  },
  '99693': {
    country: 'US',
    city: 'Whittier',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Valdez-Cordova (CA)',
    latitude: 60.7734,
    longitude: -148.6839,
    postal_code: '99693'
  },
  '99694': {
    country: 'US',
    city: 'Houston',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Matanuska-Susitna Borough',
    latitude: 61.6303,
    longitude: -149.8181,
    postal_code: '99694'
  },
  '99695': {
    country: 'US',
    city: 'Anchorage',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Anchorage Municipality',
    latitude: 61.1089,
    longitude: -149.4403,
    postal_code: '99695'
  },
  '99697': {
    country: 'US',
    city: 'Kodiak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Kodiak Island',
    latitude: 57.79,
    longitude: -152.4072,
    postal_code: '99697'
  },
  '99701': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.8378,
    longitude: -147.7164,
    postal_code: '99701'
  },
  '99702': {
    country: 'US',
    city: 'Eielson Afb',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.6735,
    longitude: -147.0805,
    postal_code: '99702'
  },
  '99703': {
    country: 'US',
    city: 'Fort Wainwright',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.8283,
    longitude: -147.6557,
    postal_code: '99703'
  },
  '99704': {
    country: 'US',
    city: 'Clear',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Denali',
    latitude: 64.2966,
    longitude: -149.1608,
    postal_code: '99704'
  },
  '99705': {
    country: 'US',
    city: 'North Pole',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.7805,
    longitude: -147.3694,
    postal_code: '99705'
  },
  '99706': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.8521,
    longitude: -147.9377,
    postal_code: '99706'
  },
  '99707': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.8378,
    longitude: -147.7164,
    postal_code: '99707'
  },
  '99708': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.9475,
    longitude: -147.8564,
    postal_code: '99708'
  },
  '99709': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.8544,
    longitude: -147.8469,
    postal_code: '99709'
  },
  '99710': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.8378,
    longitude: -147.7164,
    postal_code: '99710'
  },
  '99711': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.8378,
    longitude: -147.7164,
    postal_code: '99711'
  },
  '99712': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.9109,
    longitude: -147.5105,
    postal_code: '99712'
  },
  '99714': {
    country: 'US',
    city: 'Salcha',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.5091,
    longitude: -146.953,
    postal_code: '99714'
  },
  '99716': {
    country: 'US',
    city: 'Two Rivers',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.8746,
    longitude: -146.8982,
    postal_code: '99716'
  },
  '99720': {
    country: 'US',
    city: 'Allakaket',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 66.5656,
    longitude: -152.6456,
    postal_code: '99720'
  },
  '99721': {
    country: 'US',
    city: 'Anaktuvuk Pass',
    state: 'Alaska',
    state_short: 'AK',
    county: 'North Slope',
    latitude: 68.1433,
    longitude: -151.7358,
    postal_code: '99721'
  },
  '99722': {
    country: 'US',
    city: 'Arctic Village',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 68.1269,
    longitude: -145.5378,
    postal_code: '99722'
  },
  '99723': {
    country: 'US',
    city: 'Barrow',
    state: 'Alaska',
    state_short: 'AK',
    county: 'North Slope',
    latitude: 71.2346,
    longitude: -156.8174,
    postal_code: '99723'
  },
  '99724': {
    country: 'US',
    city: 'Beaver',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 66.3594,
    longitude: -147.3964,
    postal_code: '99724'
  },
  '99725': {
    country: 'US',
    city: 'Ester',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.871,
    longitude: -148.0949,
    postal_code: '99725'
  },
  '99726': {
    country: 'US',
    city: 'Bettles Field',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 65.2264,
    longitude: -151.0251,
    postal_code: '99726'
  },
  '99727': {
    country: 'US',
    city: 'Buckland',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 65.9789,
    longitude: -161.1262,
    postal_code: '99727'
  },
  '99729': {
    country: 'US',
    city: 'Cantwell',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Denali',
    latitude: 63.3955,
    longitude: -148.8973,
    postal_code: '99729'
  },
  '99730': {
    country: 'US',
    city: 'Central',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 65.4681,
    longitude: -144.7489,
    postal_code: '99730'
  },
  '99731': {
    country: 'US',
    city: 'Fort Greely',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Southeast Fairbanks Census Area',
    latitude: 63.952,
    longitude: -146.451,
    postal_code: '99731'
  },
  '99732': {
    country: 'US',
    city: 'Chicken',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Southeast Fairbanks Census Area',
    latitude: 64.0733,
    longitude: -141.9361,
    postal_code: '99732'
  },
  '99733': {
    country: 'US',
    city: 'Circle',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 65.8245,
    longitude: -144.0826,
    postal_code: '99733'
  },
  '99734': {
    country: 'US',
    city: 'Prudhoe Bay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'North Slope',
    latitude: 70.2922,
    longitude: -148.6694,
    postal_code: '99734'
  },
  '99736': {
    country: 'US',
    city: 'Deering',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 66.0737,
    longitude: -162.714,
    postal_code: '99736'
  },
  '99737': {
    country: 'US',
    city: 'Delta Junction',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Southeast Fairbanks Census Area',
    latitude: 64.0378,
    longitude: -145.7322,
    postal_code: '99737'
  },
  '99738': {
    country: 'US',
    city: 'Eagle',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Southeast Fairbanks Census Area',
    latitude: 64.7881,
    longitude: -141.2,
    postal_code: '99738'
  },
  '99739': {
    country: 'US',
    city: 'Elim',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 64.6217,
    longitude: -162.2604,
    postal_code: '99739'
  },
  '99740': {
    country: 'US',
    city: 'Fort Yukon',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 66.5647,
    longitude: -145.2739,
    postal_code: '99740'
  },
  '99741': {
    country: 'US',
    city: 'Galena',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 64.7333,
    longitude: -156.9275,
    postal_code: '99741'
  },
  '99742': {
    country: 'US',
    city: 'Gambell',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 63.7766,
    longitude: -171.7017,
    postal_code: '99742'
  },
  '99743': {
    country: 'US',
    city: 'Healy',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Denali',
    latitude: 63.9171,
    longitude: -149.0111,
    postal_code: '99743'
  },
  '99744': {
    country: 'US',
    city: 'Anderson',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Denali',
    latitude: 64.3442,
    longitude: -149.1869,
    postal_code: '99744'
  },
  '99745': {
    country: 'US',
    city: 'Hughes',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 66.0382,
    longitude: -154.2644,
    postal_code: '99745'
  },
  '99746': {
    country: 'US',
    city: 'Huslia',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 65.6899,
    longitude: -156.292,
    postal_code: '99746'
  },
  '99747': {
    country: 'US',
    city: 'Kaktovik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'North Slope',
    latitude: 70.1319,
    longitude: -143.6239,
    postal_code: '99747'
  },
  '99748': {
    country: 'US',
    city: 'Kaltag',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 64.3305,
    longitude: -158.7243,
    postal_code: '99748'
  },
  '99749': {
    country: 'US',
    city: 'Kiana',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 66.975,
    longitude: -160.4228,
    postal_code: '99749'
  },
  '99750': {
    country: 'US',
    city: 'Kivalina',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 67.7269,
    longitude: -164.5333,
    postal_code: '99750'
  },
  '99751': {
    country: 'US',
    city: 'Kobuk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 66.9072,
    longitude: -156.8811,
    postal_code: '99751'
  },
  '99752': {
    country: 'US',
    city: 'Kotzebue',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 66.8985,
    longitude: -162.5981,
    postal_code: '99752'
  },
  '99753': {
    country: 'US',
    city: 'Koyuk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 64.9319,
    longitude: -161.1569,
    postal_code: '99753'
  },
  '99754': {
    country: 'US',
    city: 'Koyukuk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 64.8803,
    longitude: -157.7008,
    postal_code: '99754'
  },
  '99755': {
    country: 'US',
    city: 'Denali National Park',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Denali',
    latitude: 63.5436,
    longitude: -149.9867,
    postal_code: '99755'
  },
  '99756': {
    country: 'US',
    city: 'Manley Hot Springs',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 65.0011,
    longitude: -150.6339,
    postal_code: '99756'
  },
  '99757': {
    country: 'US',
    city: 'Lake Minchumina',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 63.8828,
    longitude: -152.3122,
    postal_code: '99757'
  },
  '99758': {
    country: 'US',
    city: 'Minto',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 65.0584,
    longitude: -149.6912,
    postal_code: '99758'
  },
  '99759': {
    country: 'US',
    city: 'Point Lay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'North Slope',
    latitude: 69.7439,
    longitude: -163.0084,
    postal_code: '99759'
  },
  '99760': {
    country: 'US',
    city: 'Nenana',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 64.5577,
    longitude: -149.0867,
    postal_code: '99760'
  },
  '99761': {
    country: 'US',
    city: 'Noatak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 67.5711,
    longitude: -162.9653,
    postal_code: '99761'
  },
  '99762': {
    country: 'US',
    city: 'Nome',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 64.5011,
    longitude: -165.4064,
    postal_code: '99762'
  },
  '99763': {
    country: 'US',
    city: 'Noorvik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 66.8364,
    longitude: -161.0441,
    postal_code: '99763'
  },
  '99764': {
    country: 'US',
    city: 'Northway',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Southeast Fairbanks Census Area',
    latitude: 62.9617,
    longitude: -141.9372,
    postal_code: '99764'
  },
  '99765': {
    country: 'US',
    city: 'Nulato',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 64.7194,
    longitude: -158.1031,
    postal_code: '99765'
  },
  '99766': {
    country: 'US',
    city: 'Point Hope',
    state: 'Alaska',
    state_short: 'AK',
    county: 'North Slope',
    latitude: 68.3486,
    longitude: -166.7347,
    postal_code: '99766'
  },
  '99767': {
    country: 'US',
    city: 'Rampart',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 65.509,
    longitude: -150.1619,
    postal_code: '99767'
  },
  '99768': {
    country: 'US',
    city: 'Ruby',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 64.7201,
    longitude: -155.5039,
    postal_code: '99768'
  },
  '99769': {
    country: 'US',
    city: 'Savoonga',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 63.6797,
    longitude: -170.4709,
    postal_code: '99769'
  },
  '99770': {
    country: 'US',
    city: 'Selawik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 66.6039,
    longitude: -160.0069,
    postal_code: '99770'
  },
  '99771': {
    country: 'US',
    city: 'Shaktoolik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 64.3537,
    longitude: -161.1932,
    postal_code: '99771'
  },
  '99772': {
    country: 'US',
    city: 'Shishmaref',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 66.2306,
    longitude: -166.1373,
    postal_code: '99772'
  },
  '99773': {
    country: 'US',
    city: 'Shungnak',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 66.8881,
    longitude: -157.1364,
    postal_code: '99773'
  },
  '99774': {
    country: 'US',
    city: 'Stevens Village',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 66.0064,
    longitude: -149.0908,
    postal_code: '99774'
  },
  '99775': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.8591,
    longitude: -147.8267,
    postal_code: '99775'
  },
  '99776': {
    country: 'US',
    city: 'Tanacross',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Southeast Fairbanks Census Area',
    latitude: 63.3853,
    longitude: -143.3464,
    postal_code: '99776'
  },
  '99777': {
    country: 'US',
    city: 'Tanana',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 65.1719,
    longitude: -152.0789,
    postal_code: '99777'
  },
  '99778': {
    country: 'US',
    city: 'Teller',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 65.2402,
    longitude: -166.3833,
    postal_code: '99778'
  },
  '99780': {
    country: 'US',
    city: 'Tok',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Southeast Fairbanks Census Area',
    latitude: 63.3367,
    longitude: -142.9856,
    postal_code: '99780'
  },
  '99781': {
    country: 'US',
    city: 'Venetie',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 67.0104,
    longitude: -146.4137,
    postal_code: '99781'
  },
  '99782': {
    country: 'US',
    city: 'Wainwright',
    state: 'Alaska',
    state_short: 'AK',
    county: 'North Slope',
    latitude: 70.6369,
    longitude: -160.0383,
    postal_code: '99782'
  },
  '99783': {
    country: 'US',
    city: 'Wales',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 65.6092,
    longitude: -168.0875,
    postal_code: '99783'
  },
  '99784': {
    country: 'US',
    city: 'White Mountain',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 64.6814,
    longitude: -163.4056,
    postal_code: '99784'
  },
  '99785': {
    country: 'US',
    city: 'Brevig Mission',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Nome (CA)',
    latitude: 65.3342,
    longitude: -166.4786,
    postal_code: '99785'
  },
  '99786': {
    country: 'US',
    city: 'Ambler',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Northwest Arctic',
    latitude: 67.0861,
    longitude: -157.8514,
    postal_code: '99786'
  },
  '99788': {
    country: 'US',
    city: 'Chalkyitsik',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Yukon-Koyukuk (CA)',
    latitude: 66.6544,
    longitude: -143.7222,
    postal_code: '99788'
  },
  '99789': {
    country: 'US',
    city: 'Nuiqsut',
    state: 'Alaska',
    state_short: 'AK',
    county: 'North Slope',
    latitude: 70.1927,
    longitude: -150.9971,
    postal_code: '99789'
  },
  '99790': {
    country: 'US',
    city: 'Fairbanks',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Fairbanks North Star',
    latitude: 64.8378,
    longitude: -147.7164,
    postal_code: '99790'
  },
  '99791': {
    country: 'US',
    city: 'Atqasuk',
    state: 'Alaska',
    state_short: 'AK',
    county: 'North Slope',
    latitude: 70.4947,
    longitude: -157.4411,
    postal_code: '99791'
  },
  '99801': {
    country: 'US',
    city: 'Juneau',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Juneau City and Borough',
    latitude: 58.3628,
    longitude: -134.5294,
    postal_code: '99801'
  },
  '99802': {
    country: 'US',
    city: 'Juneau',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Juneau City and Borough',
    latitude: 58.3019,
    longitude: -134.4197,
    postal_code: '99802'
  },
  '99803': {
    country: 'US',
    city: 'Juneau',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Juneau City and Borough',
    latitude: 58.3019,
    longitude: -134.4197,
    postal_code: '99803'
  },
  '99811': {
    country: 'US',
    city: 'Juneau',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Juneau City and Borough',
    latitude: 58.3019,
    longitude: -134.4197,
    postal_code: '99811'
  },
  '99812': {
    country: 'US',
    city: 'Juneau',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Juneau City and Borough',
    latitude: 58.3271,
    longitude: -134.4742,
    postal_code: '99812'
  },
  '99820': {
    country: 'US',
    city: 'Angoon',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Skagway-Hoonah-Angoon',
    latitude: 57.5005,
    longitude: -134.586,
    postal_code: '99820'
  },
  '99821': {
    country: 'US',
    city: 'Auke Bay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Juneau City and Borough',
    latitude: 58.4494,
    longitude: -134.7003,
    postal_code: '99821'
  },
  '99824': {
    country: 'US',
    city: 'Douglas',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Juneau City and Borough',
    latitude: 58.2756,
    longitude: -134.395,
    postal_code: '99824'
  },
  '99825': {
    country: 'US',
    city: 'Elfin Cove',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Skagway-Hoonah-Angoon',
    latitude: 58.1944,
    longitude: -136.3433,
    postal_code: '99825'
  },
  '99826': {
    country: 'US',
    city: 'Gustavus',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Skagway-Hoonah-Angoon',
    latitude: 58.4284,
    longitude: -135.7615,
    postal_code: '99826'
  },
  '99827': {
    country: 'US',
    city: 'Haines',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Haines',
    latitude: 59.2519,
    longitude: -135.542,
    postal_code: '99827'
  },
  '99829': {
    country: 'US',
    city: 'Hoonah',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Skagway-Hoonah-Angoon',
    latitude: 58.0977,
    longitude: -135.4316,
    postal_code: '99829'
  },
  '99830': {
    country: 'US',
    city: 'Kake',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Wrangell-Petersburg (C',
    latitude: 56.9736,
    longitude: -133.936,
    postal_code: '99830'
  },
  '99832': {
    country: 'US',
    city: 'Pelican',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Skagway-Hoonah-Angoon',
    latitude: 57.9608,
    longitude: -136.2275,
    postal_code: '99832'
  },
  '99833': {
    country: 'US',
    city: 'Petersburg',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Wrangell-Petersburg (C',
    latitude: 56.8271,
    longitude: -133.1607,
    postal_code: '99833'
  },
  '99835': {
    country: 'US',
    city: 'Sitka',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Sitka City and Borough',
    latitude: 57.0514,
    longitude: -135.3166,
    postal_code: '99835'
  },
  '99836': {
    country: 'US',
    city: 'Port Alexander',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Wrangell-Petersburg (C',
    latitude: 56.2497,
    longitude: -134.6444,
    postal_code: '99836'
  },
  '99840': {
    country: 'US',
    city: 'Skagway',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Skagway-Hoonah-Angoon',
    latitude: 59.4685,
    longitude: -135.3018,
    postal_code: '99840'
  },
  '99841': {
    country: 'US',
    city: 'Tenakee Springs',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Skagway-Hoonah-Angoon',
    latitude: 57.7808,
    longitude: -135.2189,
    postal_code: '99841'
  },
  '99850': {
    country: 'US',
    city: 'Juneau',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Juneau City and Borough',
    latitude: 58.3019,
    longitude: -134.4197,
    postal_code: '99850'
  },
  '99901': {
    country: 'US',
    city: 'Ketchikan',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Ketchikan Gateway',
    latitude: 55.372,
    longitude: -131.6832,
    postal_code: '99901'
  },
  '99903': {
    country: 'US',
    city: 'Meyers Chuck',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Prince of Wales-Hyder',
    latitude: 55.7408,
    longitude: -132.2564,
    postal_code: '99903'
  },
  '99918': {
    country: 'US',
    city: 'Coffman Cove',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Prince of Wales-Hyder',
    latitude: 55.9524,
    longitude: -132.7503,
    postal_code: '99918'
  },
  '99919': {
    country: 'US',
    city: 'Thorne Bay',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Prince of Wales-Hyder',
    latitude: 55.6609,
    longitude: -132.5138,
    postal_code: '99919'
  },
  '99921': {
    country: 'US',
    city: 'Craig',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Prince of Wales-Hyder',
    latitude: 55.4732,
    longitude: -133.1171,
    postal_code: '99921'
  },
  '99922': {
    country: 'US',
    city: 'Hydaburg',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Prince of Wales-Hyder',
    latitude: 55.2087,
    longitude: -132.8259,
    postal_code: '99922'
  },
  '99923': {
    country: 'US',
    city: 'Hyder',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Prince of Wales-Hyder',
    latitude: 55.9169,
    longitude: -130.0247,
    postal_code: '99923'
  },
  '99925': {
    country: 'US',
    city: 'Klawock',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Prince of Wales-Hyder',
    latitude: 55.5526,
    longitude: -133.0555,
    postal_code: '99925'
  },
  '99926': {
    country: 'US',
    city: 'Metlakatla',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Prince of Wales-Hyder',
    latitude: 55.1215,
    longitude: -131.579,
    postal_code: '99926'
  },
  '99927': {
    country: 'US',
    city: 'Point Baker',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Prince of Wales-Hyder',
    latitude: 56.3528,
    longitude: -133.6211,
    postal_code: '99927'
  },
  '99928': {
    country: 'US',
    city: 'Ward Cove',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Ketchikan Gateway',
    latitude: 55.3954,
    longitude: -131.6754,
    postal_code: '99928'
  },
  '99929': {
    country: 'US',
    city: 'Wrangell',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Wrangell-Petersburg (C',
    latitude: 56.4335,
    longitude: -132.3529,
    postal_code: '99929'
  },
  '99950': {
    country: 'US',
    city: 'Ketchikan',
    state: 'Alaska',
    state_short: 'AK',
    county: 'Ketchikan Gateway',
    latitude: 55.3422,
    longitude: -131.6478,
    postal_code: '99950'
  },
  '06404': {
    country: 'US',
    city: 'Botsford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3665,
    longitude: -73.2571,
    postal_code: '06404'
  },
  '06440': {
    country: 'US',
    city: 'Hawleyville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.4276,
    longitude: -73.3551,
    postal_code: '06440'
  },
  '06468': {
    country: 'US',
    city: 'Monroe',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3312,
    longitude: -73.2243,
    postal_code: '06468'
  },
  '06470': {
    country: 'US',
    city: 'Newtown',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3931,
    longitude: -73.3167,
    postal_code: '06470'
  },
  '06482': {
    country: 'US',
    city: 'Sandy Hook',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.4087,
    longitude: -73.2485,
    postal_code: '06482'
  },
  '06484': {
    country: 'US',
    city: 'Shelton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3047,
    longitude: -73.1294,
    postal_code: '06484'
  },
  '06491': {
    country: 'US',
    city: 'Stevenson',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3866,
    longitude: -73.1872,
    postal_code: '06491'
  },
  '06601': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.167,
    longitude: -73.2048,
    postal_code: '06601'
  },
  '06602': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1798,
    longitude: -73.189,
    postal_code: '06602'
  },
  '06604': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1796,
    longitude: -73.2019,
    postal_code: '06604'
  },
  '06605': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1668,
    longitude: -73.2163,
    postal_code: '06605'
  },
  '06606': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.2091,
    longitude: -73.2086,
    postal_code: '06606'
  },
  '06607': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1784,
    longitude: -73.165,
    postal_code: '06607'
  },
  '06608': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1895,
    longitude: -73.1811,
    postal_code: '06608'
  },
  '06610': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.2005,
    longitude: -73.1688,
    postal_code: '06610'
  },
  '06611': {
    country: 'US',
    city: 'Trumbull',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.2564,
    longitude: -73.2111,
    postal_code: '06611'
  },
  '06612': {
    country: 'US',
    city: 'Easton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.2523,
    longitude: -73.2871,
    postal_code: '06612'
  },
  '06614': {
    country: 'US',
    city: 'Stratford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.216,
    longitude: -73.1304,
    postal_code: '06614'
  },
  '06615': {
    country: 'US',
    city: 'Stratford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.177,
    longitude: -73.1336,
    postal_code: '06615'
  },
  '06673': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.167,
    longitude: -73.2048,
    postal_code: '06673'
  },
  '06699': {
    country: 'US',
    city: 'Bridgeport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.167,
    longitude: -73.2048,
    postal_code: '06699'
  },
  '06784': {
    country: 'US',
    city: 'Sherman',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.5714,
    longitude: -73.4947,
    postal_code: '06784'
  },
  '06801': {
    country: 'US',
    city: 'Bethel',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3813,
    longitude: -73.4008,
    postal_code: '06801'
  },
  '06804': {
    country: 'US',
    city: 'Brookfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.465,
    longitude: -73.398,
    postal_code: '06804'
  },
  '06807': {
    country: 'US',
    city: 'Cos Cob',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.053,
    longitude: -73.5935,
    postal_code: '06807'
  },
  '06810': {
    country: 'US',
    city: 'Danbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3917,
    longitude: -73.4532,
    postal_code: '06810'
  },
  '06811': {
    country: 'US',
    city: 'Danbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.424,
    longitude: -73.4716,
    postal_code: '06811'
  },
  '06812': {
    country: 'US',
    city: 'New Fairfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.473,
    longitude: -73.4978,
    postal_code: '06812'
  },
  '06813': {
    country: 'US',
    city: 'Danbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3948,
    longitude: -73.454,
    postal_code: '06813'
  },
  '06814': {
    country: 'US',
    city: 'Danbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3089,
    longitude: -73.3637,
    postal_code: '06814'
  },
  '06816': {
    country: 'US',
    city: 'Danbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3089,
    longitude: -73.3637,
    postal_code: '06816'
  },
  '06817': {
    country: 'US',
    city: 'Danbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3948,
    longitude: -73.454,
    postal_code: '06817'
  },
  '06820': {
    country: 'US',
    city: 'Darien',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0768,
    longitude: -73.4853,
    postal_code: '06820'
  },
  '06824': {
    country: 'US',
    city: 'Fairfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1692,
    longitude: -73.2681,
    postal_code: '06824'
  },
  '06825': {
    country: 'US',
    city: 'Fairfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1928,
    longitude: -73.2402,
    postal_code: '06825'
  },
  '06828': {
    country: 'US',
    city: 'Fairfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.169,
    longitude: -73.2334,
    postal_code: '06828'
  },
  '06829': {
    country: 'US',
    city: 'Georgetown',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.2555,
    longitude: -73.4279,
    postal_code: '06829'
  },
  '06830': {
    country: 'US',
    city: 'Greenwich',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0427,
    longitude: -73.6262,
    postal_code: '06830'
  },
  '06831': {
    country: 'US',
    city: 'Greenwich',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0549,
    longitude: -73.6594,
    postal_code: '06831'
  },
  '06836': {
    country: 'US',
    city: 'Greenwich',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0265,
    longitude: -73.6285,
    postal_code: '06836'
  },
  '06838': {
    country: 'US',
    city: 'Greens Farms',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1238,
    longitude: -73.3195,
    postal_code: '06838'
  },
  '06840': {
    country: 'US',
    city: 'New Canaan',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.151,
    longitude: -73.4944,
    postal_code: '06840'
  },
  '06850': {
    country: 'US',
    city: 'Norwalk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1222,
    longitude: -73.4358,
    postal_code: '06850'
  },
  '06851': {
    country: 'US',
    city: 'Norwalk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1323,
    longitude: -73.4058,
    postal_code: '06851'
  },
  '06852': {
    country: 'US',
    city: 'Norwalk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3089,
    longitude: -73.3637,
    postal_code: '06852'
  },
  '06853': {
    country: 'US',
    city: 'Norwalk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0702,
    longitude: -73.4397,
    postal_code: '06853'
  },
  '06854': {
    country: 'US',
    city: 'Norwalk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0957,
    longitude: -73.4285,
    postal_code: '06854'
  },
  '06855': {
    country: 'US',
    city: 'Norwalk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1014,
    longitude: -73.4011,
    postal_code: '06855'
  },
  '06856': {
    country: 'US',
    city: 'Norwalk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1112,
    longitude: -73.4204,
    postal_code: '06856'
  },
  '06857': {
    country: 'US',
    city: 'Norwalk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3089,
    longitude: -73.3637,
    postal_code: '06857'
  },
  '06858': {
    country: 'US',
    city: 'Norwalk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1105,
    longitude: -73.4162,
    postal_code: '06858'
  },
  '06860': {
    country: 'US',
    city: 'Norwalk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3089,
    longitude: -73.3637,
    postal_code: '06860'
  },
  '06870': {
    country: 'US',
    city: 'Old Greenwich',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0354,
    longitude: -73.5673,
    postal_code: '06870'
  },
  '06875': {
    country: 'US',
    city: 'Redding Center',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3089,
    longitude: -73.3637,
    postal_code: '06875'
  },
  '06876': {
    country: 'US',
    city: 'Redding Ridge',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.314,
    longitude: -73.3504,
    postal_code: '06876'
  },
  '06877': {
    country: 'US',
    city: 'Ridgefield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.2977,
    longitude: -73.4973,
    postal_code: '06877'
  },
  '06878': {
    country: 'US',
    city: 'Riverside',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.038,
    longitude: -73.5811,
    postal_code: '06878'
  },
  '06879': {
    country: 'US',
    city: 'Ridgefield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.2815,
    longitude: -73.4982,
    postal_code: '06879'
  },
  '06880': {
    country: 'US',
    city: 'Westport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1434,
    longitude: -73.3496,
    postal_code: '06880'
  },
  '06881': {
    country: 'US',
    city: 'Westport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1415,
    longitude: -73.3579,
    postal_code: '06881'
  },
  '06883': {
    country: 'US',
    city: 'Weston',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.2195,
    longitude: -73.3715,
    postal_code: '06883'
  },
  '06888': {
    country: 'US',
    city: 'Westport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.3089,
    longitude: -73.3637,
    postal_code: '06888'
  },
  '06889': {
    country: 'US',
    city: 'Westport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.141,
    longitude: -73.3469,
    postal_code: '06889'
  },
  '06890': {
    country: 'US',
    city: 'Southport',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1428,
    longitude: -73.2884,
    postal_code: '06890'
  },
  '06896': {
    country: 'US',
    city: 'Redding',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.2711,
    longitude: -73.3863,
    postal_code: '06896'
  },
  '06897': {
    country: 'US',
    city: 'Wilton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.2018,
    longitude: -73.4383,
    postal_code: '06897'
  },
  '06901': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0531,
    longitude: -73.539,
    postal_code: '06901'
  },
  '06902': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0602,
    longitude: -73.5445,
    postal_code: '06902'
  },
  '06903': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.1352,
    longitude: -73.5684,
    postal_code: '06903'
  },
  '06904': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0534,
    longitude: -73.5387,
    postal_code: '06904'
  },
  '06905': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0888,
    longitude: -73.5435,
    postal_code: '06905'
  },
  '06906': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0692,
    longitude: -73.5236,
    postal_code: '06906'
  },
  '06907': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0942,
    longitude: -73.5203,
    postal_code: '06907'
  },
  '06910': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0391,
    longitude: -73.5591,
    postal_code: '06910'
  },
  '06911': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0534,
    longitude: -73.5387,
    postal_code: '06911'
  },
  '06912': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0534,
    longitude: -73.5387,
    postal_code: '06912'
  },
  '06913': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0534,
    longitude: -73.5387,
    postal_code: '06913'
  },
  '06914': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0534,
    longitude: -73.5387,
    postal_code: '06914'
  },
  '06920': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0534,
    longitude: -73.5387,
    postal_code: '06920'
  },
  '06921': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0499,
    longitude: -73.538,
    postal_code: '06921'
  },
  '06922': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0516,
    longitude: -73.5143,
    postal_code: '06922'
  },
  '06926': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0412,
    longitude: -73.5386,
    postal_code: '06926'
  },
  '06927': {
    country: 'US',
    city: 'Stamford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Fairfield',
    latitude: 41.0534,
    longitude: -73.5387,
    postal_code: '06927'
  },
  '06001': {
    country: 'US',
    city: 'Avon',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7905,
    longitude: -72.8653,
    postal_code: '06001'
  },
  '06002': {
    country: 'US',
    city: 'Bloomfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8316,
    longitude: -72.7249,
    postal_code: '06002'
  },
  '06006': {
    country: 'US',
    city: 'Windsor',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8526,
    longitude: -72.6437,
    postal_code: '06006'
  },
  '06010': {
    country: 'US',
    city: 'Bristol',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6823,
    longitude: -72.9302,
    postal_code: '06010'
  },
  '06011': {
    country: 'US',
    city: 'Bristol',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6718,
    longitude: -72.9493,
    postal_code: '06011'
  },
  '06013': {
    country: 'US',
    city: 'Burlington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7573,
    longitude: -72.9444,
    postal_code: '06013'
  },
  '06016': {
    country: 'US',
    city: 'Broad Brook',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9042,
    longitude: -72.5444,
    postal_code: '06016'
  },
  '06019': {
    country: 'US',
    city: 'Canton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8384,
    longitude: -72.8987,
    postal_code: '06019'
  },
  '06020': {
    country: 'US',
    city: 'Canton Center',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8539,
    longitude: -72.9028,
    postal_code: '06020'
  },
  '06022': {
    country: 'US',
    city: 'Collinsville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8513,
    longitude: -72.9283,
    postal_code: '06022'
  },
  '06023': {
    country: 'US',
    city: 'East Berlin',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6128,
    longitude: -72.719,
    postal_code: '06023'
  },
  '06025': {
    country: 'US',
    city: 'East Glastonbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6889,
    longitude: -72.5345,
    postal_code: '06025'
  },
  '06026': {
    country: 'US',
    city: 'East Granby',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9322,
    longitude: -72.7459,
    postal_code: '06026'
  },
  '06027': {
    country: 'US',
    city: 'East Hartland',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 42.0016,
    longitude: -72.9242,
    postal_code: '06027'
  },
  '06028': {
    country: 'US',
    city: 'East Windsor Hill',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8592,
    longitude: -72.603,
    postal_code: '06028'
  },
  '06030': {
    country: 'US',
    city: 'Farmington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06030'
  },
  '06032': {
    country: 'US',
    city: 'Farmington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7284,
    longitude: -72.8415,
    postal_code: '06032'
  },
  '06033': {
    country: 'US',
    city: 'Glastonbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7073,
    longitude: -72.5727,
    postal_code: '06033'
  },
  '06034': {
    country: 'US',
    city: 'Farmington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06034'
  },
  '06035': {
    country: 'US',
    city: 'Granby',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9602,
    longitude: -72.7994,
    postal_code: '06035'
  },
  '06037': {
    country: 'US',
    city: 'Berlin',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6215,
    longitude: -72.7457,
    postal_code: '06037'
  },
  '06040': {
    country: 'US',
    city: 'Manchester',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7777,
    longitude: -72.5244,
    postal_code: '06040'
  },
  '06041': {
    country: 'US',
    city: 'Manchester',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7759,
    longitude: -72.5215,
    postal_code: '06041'
  },
  '06042': {
    country: 'US',
    city: 'Manchester',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7966,
    longitude: -72.5292,
    postal_code: '06042'
  },
  '06045': {
    country: 'US',
    city: 'Manchester',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7759,
    longitude: -72.5215,
    postal_code: '06045'
  },
  '06050': {
    country: 'US',
    city: 'New Britain',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.666,
    longitude: -72.7784,
    postal_code: '06050'
  },
  '06051': {
    country: 'US',
    city: 'New Britain',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6667,
    longitude: -72.7722,
    postal_code: '06051'
  },
  '06052': {
    country: 'US',
    city: 'New Britain',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6588,
    longitude: -72.7989,
    postal_code: '06052'
  },
  '06053': {
    country: 'US',
    city: 'New Britain',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6867,
    longitude: -72.7908,
    postal_code: '06053'
  },
  '06059': {
    country: 'US',
    city: 'North Canton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8997,
    longitude: -72.8907,
    postal_code: '06059'
  },
  '06060': {
    country: 'US',
    city: 'North Granby',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 42.0219,
    longitude: -72.8409,
    postal_code: '06060'
  },
  '06062': {
    country: 'US',
    city: 'Plainville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6727,
    longitude: -72.8644,
    postal_code: '06062'
  },
  '06064': {
    country: 'US',
    city: 'Poquonock',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9048,
    longitude: -72.6787,
    postal_code: '06064'
  },
  '06067': {
    country: 'US',
    city: 'Rocky Hill',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6583,
    longitude: -72.6632,
    postal_code: '06067'
  },
  '06070': {
    country: 'US',
    city: 'Simsbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8759,
    longitude: -72.8012,
    postal_code: '06070'
  },
  '06073': {
    country: 'US',
    city: 'South Glastonbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6571,
    longitude: -72.5722,
    postal_code: '06073'
  },
  '06074': {
    country: 'US',
    city: 'South Windsor',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8341,
    longitude: -72.5576,
    postal_code: '06074'
  },
  '06078': {
    country: 'US',
    city: 'Suffield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.99,
    longitude: -72.642,
    postal_code: '06078'
  },
  '06080': {
    country: 'US',
    city: 'Suffield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9818,
    longitude: -72.6506,
    postal_code: '06080'
  },
  '06081': {
    country: 'US',
    city: 'Tariffville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9077,
    longitude: -72.7678,
    postal_code: '06081'
  },
  '06082': {
    country: 'US',
    city: 'Enfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.989,
    longitude: -72.5652,
    postal_code: '06082'
  },
  '06083': {
    country: 'US',
    city: 'Enfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9762,
    longitude: -72.5918,
    postal_code: '06083'
  },
  '06085': {
    country: 'US',
    city: 'Unionville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7477,
    longitude: -72.8874,
    postal_code: '06085'
  },
  '06087': {
    country: 'US',
    city: 'Unionville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7576,
    longitude: -72.885,
    postal_code: '06087'
  },
  '06088': {
    country: 'US',
    city: 'East Windsor',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9099,
    longitude: -72.6029,
    postal_code: '06088'
  },
  '06089': {
    country: 'US',
    city: 'Weatogue',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8372,
    longitude: -72.8253,
    postal_code: '06089'
  },
  '06090': {
    country: 'US',
    city: 'West Granby',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9556,
    longitude: -72.862,
    postal_code: '06090'
  },
  '06091': {
    country: 'US',
    city: 'West Hartland',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 42.0076,
    longitude: -72.9707,
    postal_code: '06091'
  },
  '06092': {
    country: 'US',
    city: 'West Simsbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8718,
    longitude: -72.8577,
    postal_code: '06092'
  },
  '06093': {
    country: 'US',
    city: 'West Suffield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 42.0115,
    longitude: -72.7362,
    postal_code: '06093'
  },
  '06095': {
    country: 'US',
    city: 'Windsor',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.8561,
    longitude: -72.6639,
    postal_code: '06095'
  },
  '06096': {
    country: 'US',
    city: 'Windsor Locks',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9261,
    longitude: -72.6458,
    postal_code: '06096'
  },
  '06101': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7801,
    longitude: -72.6771,
    postal_code: '06101'
  },
  '06102': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06102'
  },
  '06103': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7672,
    longitude: -72.676,
    postal_code: '06103'
  },
  '06104': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06104'
  },
  '06105': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7691,
    longitude: -72.701,
    postal_code: '06105'
  },
  '06106': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7498,
    longitude: -72.6947,
    postal_code: '06106'
  },
  '06107': {
    country: 'US',
    city: 'West Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7556,
    longitude: -72.7532,
    postal_code: '06107'
  },
  '06108': {
    country: 'US',
    city: 'East Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7803,
    longitude: -72.618,
    postal_code: '06108'
  },
  '06109': {
    country: 'US',
    city: 'Wethersfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7013,
    longitude: -72.6763,
    postal_code: '06109'
  },
  '06110': {
    country: 'US',
    city: 'West Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7326,
    longitude: -72.7337,
    postal_code: '06110'
  },
  '06111': {
    country: 'US',
    city: 'Newington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.686,
    longitude: -72.7296,
    postal_code: '06111'
  },
  '06112': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7905,
    longitude: -72.6964,
    postal_code: '06112'
  },
  '06114': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7403,
    longitude: -72.6807,
    postal_code: '06114'
  },
  '06115': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7588,
    longitude: -72.6794,
    postal_code: '06115'
  },
  '06117': {
    country: 'US',
    city: 'West Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.79,
    longitude: -72.7457,
    postal_code: '06117'
  },
  '06118': {
    country: 'US',
    city: 'East Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7472,
    longitude: -72.6103,
    postal_code: '06118'
  },
  '06119': {
    country: 'US',
    city: 'West Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7628,
    longitude: -72.7268,
    postal_code: '06119'
  },
  '06120': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.786,
    longitude: -72.6758,
    postal_code: '06120'
  },
  '06123': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7637,
    longitude: -72.6851,
    postal_code: '06123'
  },
  '06126': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7637,
    longitude: -72.6851,
    postal_code: '06126'
  },
  '06127': {
    country: 'US',
    city: 'West Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06127'
  },
  '06128': {
    country: 'US',
    city: 'East Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06128'
  },
  '06129': {
    country: 'US',
    city: 'Wethersfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06129'
  },
  '06131': {
    country: 'US',
    city: 'Newington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06131'
  },
  '06132': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7637,
    longitude: -72.6851,
    postal_code: '06132'
  },
  '06133': {
    country: 'US',
    city: 'West Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06133'
  },
  '06134': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7637,
    longitude: -72.6851,
    postal_code: '06134'
  },
  '06137': {
    country: 'US',
    city: 'West Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06137'
  },
  '06138': {
    country: 'US',
    city: 'East Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06138'
  },
  '06140': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06140'
  },
  '06141': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06141'
  },
  '06142': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06142'
  },
  '06143': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06143'
  },
  '06144': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06144'
  },
  '06145': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06145'
  },
  '06146': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06146'
  },
  '06147': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06147'
  },
  '06150': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06150'
  },
  '06151': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06151'
  },
  '06152': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7637,
    longitude: -72.6851,
    postal_code: '06152'
  },
  '06153': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06153'
  },
  '06154': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7715,
    longitude: -72.6867,
    postal_code: '06154'
  },
  '06155': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7693,
    longitude: -72.6865,
    postal_code: '06155'
  },
  '06156': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7679,
    longitude: -72.6907,
    postal_code: '06156'
  },
  '06160': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7665,
    longitude: -72.6933,
    postal_code: '06160'
  },
  '06161': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.766,
    longitude: -72.6718,
    postal_code: '06161'
  },
  '06167': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7637,
    longitude: -72.6851,
    postal_code: '06167'
  },
  '06176': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06176'
  },
  '06180': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7918,
    longitude: -72.7188,
    postal_code: '06180'
  },
  '06183': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.7638,
    longitude: -72.673,
    postal_code: '06183'
  },
  '06199': {
    country: 'US',
    city: 'Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.9274,
    longitude: -72.6804,
    postal_code: '06199'
  },
  '06444': {
    country: 'US',
    city: 'Marion',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.5637,
    longitude: -72.9257,
    postal_code: '06444'
  },
  '06447': {
    country: 'US',
    city: 'Marlborough',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6412,
    longitude: -72.4609,
    postal_code: '06447'
  },
  '06467': {
    country: 'US',
    city: 'Milldale',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.5659,
    longitude: -72.8918,
    postal_code: '06467'
  },
  '06479': {
    country: 'US',
    city: 'Plantsville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.5797,
    longitude: -72.899,
    postal_code: '06479'
  },
  '06489': {
    country: 'US',
    city: 'Southington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Hartford',
    latitude: 41.6052,
    longitude: -72.8727,
    postal_code: '06489'
  },
  '06018': {
    country: 'US',
    city: 'Canaan',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 42.0248,
    longitude: -73.3232,
    postal_code: '06018'
  },
  '06021': {
    country: 'US',
    city: 'Colebrook',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.9895,
    longitude: -73.0957,
    postal_code: '06021'
  },
  '06024': {
    country: 'US',
    city: 'East Canaan',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 42.0158,
    longitude: -73.2913,
    postal_code: '06024'
  },
  '06031': {
    country: 'US',
    city: 'Falls Village',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.9559,
    longitude: -73.3632,
    postal_code: '06031'
  },
  '06039': {
    country: 'US',
    city: 'Lakeville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.9516,
    longitude: -73.4377,
    postal_code: '06039'
  },
  '06057': {
    country: 'US',
    city: 'New Hartford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.8468,
    longitude: -73.0104,
    postal_code: '06057'
  },
  '06058': {
    country: 'US',
    city: 'Norfolk',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.9854,
    longitude: -73.1992,
    postal_code: '06058'
  },
  '06061': {
    country: 'US',
    city: 'Pine Meadow',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.8765,
    longitude: -72.967,
    postal_code: '06061'
  },
  '06063': {
    country: 'US',
    city: 'Barkhamsted',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.9293,
    longitude: -72.914,
    postal_code: '06063'
  },
  '06065': {
    country: 'US',
    city: 'Riverton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.9686,
    longitude: -73.0145,
    postal_code: '06065'
  },
  '06068': {
    country: 'US',
    city: 'Salisbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 42.0015,
    longitude: -73.4215,
    postal_code: '06068'
  },
  '06069': {
    country: 'US',
    city: 'Sharon',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.8714,
    longitude: -73.4578,
    postal_code: '06069'
  },
  '06079': {
    country: 'US',
    city: 'Taconic',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 42.032,
    longitude: -73.4038,
    postal_code: '06079'
  },
  '06094': {
    country: 'US',
    city: 'Winchester Center',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.8963,
    longitude: -73.1463,
    postal_code: '06094'
  },
  '06098': {
    country: 'US',
    city: 'Winsted',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.9252,
    longitude: -73.0663,
    postal_code: '06098'
  },
  '06750': {
    country: 'US',
    city: 'Bantam',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.7215,
    longitude: -73.252,
    postal_code: '06750'
  },
  '06751': {
    country: 'US',
    city: 'Bethlehem',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6387,
    longitude: -73.2091,
    postal_code: '06751'
  },
  '06752': {
    country: 'US',
    city: 'Bridgewater',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.5287,
    longitude: -73.3609,
    postal_code: '06752'
  },
  '06753': {
    country: 'US',
    city: 'Cornwall',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.8281,
    longitude: -73.3323,
    postal_code: '06753'
  },
  '06754': {
    country: 'US',
    city: 'Cornwall Bridge',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.8187,
    longitude: -73.371,
    postal_code: '06754'
  },
  '06755': {
    country: 'US',
    city: 'Gaylordsville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6486,
    longitude: -73.4835,
    postal_code: '06755'
  },
  '06756': {
    country: 'US',
    city: 'Goshen',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.8335,
    longitude: -73.2429,
    postal_code: '06756'
  },
  '06757': {
    country: 'US',
    city: 'Kent',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.7316,
    longitude: -73.4583,
    postal_code: '06757'
  },
  '06758': {
    country: 'US',
    city: 'Lakeside',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6754,
    longitude: -73.242,
    postal_code: '06758'
  },
  '06759': {
    country: 'US',
    city: 'Litchfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.7541,
    longitude: -73.2,
    postal_code: '06759'
  },
  '06763': {
    country: 'US',
    city: 'Morris',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6881,
    longitude: -73.1765,
    postal_code: '06763'
  },
  '06776': {
    country: 'US',
    city: 'New Milford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.5817,
    longitude: -73.4128,
    postal_code: '06776'
  },
  '06777': {
    country: 'US',
    city: 'New Preston Marble Dale',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6903,
    longitude: -73.3103,
    postal_code: '06777'
  },
  '06778': {
    country: 'US',
    city: 'Northfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.7077,
    longitude: -73.109,
    postal_code: '06778'
  },
  '06779': {
    country: 'US',
    city: 'Oakville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.5909,
    longitude: -73.0873,
    postal_code: '06779'
  },
  '06781': {
    country: 'US',
    city: 'Pequabuck',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6699,
    longitude: -72.9915,
    postal_code: '06781'
  },
  '06782': {
    country: 'US',
    city: 'Plymouth',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6611,
    longitude: -73.0449,
    postal_code: '06782'
  },
  '06783': {
    country: 'US',
    city: 'Roxbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.5509,
    longitude: -73.2993,
    postal_code: '06783'
  },
  '06785': {
    country: 'US',
    city: 'South Kent',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6951,
    longitude: -73.469,
    postal_code: '06785'
  },
  '06786': {
    country: 'US',
    city: 'Terryville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6762,
    longitude: -73.0092,
    postal_code: '06786'
  },
  '06787': {
    country: 'US',
    city: 'Thomaston',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6786,
    longitude: -73.0886,
    postal_code: '06787'
  },
  '06790': {
    country: 'US',
    city: 'Torrington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.8131,
    longitude: -73.1156,
    postal_code: '06790'
  },
  '06791': {
    country: 'US',
    city: 'Harwinton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.7701,
    longitude: -73.0728,
    postal_code: '06791'
  },
  '06792': {
    country: 'US',
    city: 'Torrington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.7549,
    longitude: -73.0582,
    postal_code: '06792'
  },
  '06793': {
    country: 'US',
    city: 'Washington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6296,
    longitude: -73.2884,
    postal_code: '06793'
  },
  '06794': {
    country: 'US',
    city: 'Washington Depot',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6559,
    longitude: -73.3274,
    postal_code: '06794'
  },
  '06795': {
    country: 'US',
    city: 'Watertown',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.6057,
    longitude: -73.1221,
    postal_code: '06795'
  },
  '06796': {
    country: 'US',
    city: 'West Cornwall',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.8689,
    longitude: -73.3313,
    postal_code: '06796'
  },
  '06798': {
    country: 'US',
    city: 'Woodbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Litchfield',
    latitude: 41.5521,
    longitude: -73.2083,
    postal_code: '06798'
  },
  '06409': {
    country: 'US',
    city: 'Centerbrook',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.3474,
    longitude: -72.4173,
    postal_code: '06409'
  },
  '06412': {
    country: 'US',
    city: 'Chester',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.4049,
    longitude: -72.4643,
    postal_code: '06412'
  },
  '06413': {
    country: 'US',
    city: 'Clinton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.2912,
    longitude: -72.528,
    postal_code: '06413'
  },
  '06414': {
    country: 'US',
    city: 'Cobalt',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.5667,
    longitude: -72.5581,
    postal_code: '06414'
  },
  '06416': {
    country: 'US',
    city: 'Cromwell',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.6105,
    longitude: -72.6663,
    postal_code: '06416'
  },
  '06417': {
    country: 'US',
    city: 'Deep River',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.3765,
    longitude: -72.4486,
    postal_code: '06417'
  },
  '06419': {
    country: 'US',
    city: 'Killingworth',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.3696,
    longitude: -72.5712,
    postal_code: '06419'
  },
  '06422': {
    country: 'US',
    city: 'Durham',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.465,
    longitude: -72.6875,
    postal_code: '06422'
  },
  '06423': {
    country: 'US',
    city: 'East Haddam',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.4696,
    longitude: -72.4059,
    postal_code: '06423'
  },
  '06424': {
    country: 'US',
    city: 'East Hampton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.5761,
    longitude: -72.5093,
    postal_code: '06424'
  },
  '06426': {
    country: 'US',
    city: 'Essex',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.3549,
    longitude: -72.3965,
    postal_code: '06426'
  },
  '06438': {
    country: 'US',
    city: 'Haddam',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.4627,
    longitude: -72.505,
    postal_code: '06438'
  },
  '06441': {
    country: 'US',
    city: 'Higganum',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.4682,
    longitude: -72.5751,
    postal_code: '06441'
  },
  '06442': {
    country: 'US',
    city: 'Ivoryton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.3421,
    longitude: -72.4404,
    postal_code: '06442'
  },
  '06455': {
    country: 'US',
    city: 'Middlefield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.5168,
    longitude: -72.7186,
    postal_code: '06455'
  },
  '06456': {
    country: 'US',
    city: 'Middle Haddam',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.554,
    longitude: -72.5501,
    postal_code: '06456'
  },
  '06457': {
    country: 'US',
    city: 'Middletown',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.5569,
    longitude: -72.6652,
    postal_code: '06457'
  },
  '06459': {
    country: 'US',
    city: 'Middletown',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.5565,
    longitude: -72.6582,
    postal_code: '06459'
  },
  '06469': {
    country: 'US',
    city: 'Moodus',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.5078,
    longitude: -72.4419,
    postal_code: '06469'
  },
  '06475': {
    country: 'US',
    city: 'Old Saybrook',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.2913,
    longitude: -72.385,
    postal_code: '06475'
  },
  '06480': {
    country: 'US',
    city: 'Portland',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.5852,
    longitude: -72.6128,
    postal_code: '06480'
  },
  '06481': {
    country: 'US',
    city: 'Rockfall',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.5341,
    longitude: -72.6997,
    postal_code: '06481'
  },
  '06498': {
    country: 'US',
    city: 'Westbrook',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Middlesex',
    latitude: 41.2927,
    longitude: -72.4563,
    postal_code: '06498'
  },
  '06401': {
    country: 'US',
    city: 'Ansonia',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3427,
    longitude: -73.0742,
    postal_code: '06401'
  },
  '06403': {
    country: 'US',
    city: 'Beacon Falls',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.4369,
    longitude: -73.0597,
    postal_code: '06403'
  },
  '06405': {
    country: 'US',
    city: 'Branford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.28,
    longitude: -72.8106,
    postal_code: '06405'
  },
  '06408': {
    country: 'US',
    city: 'Cheshire',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3657,
    longitude: -72.9275,
    postal_code: '06408'
  },
  '06410': {
    country: 'US',
    city: 'Cheshire',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5055,
    longitude: -72.9081,
    postal_code: '06410'
  },
  '06411': {
    country: 'US',
    city: 'Cheshire',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.499,
    longitude: -72.9007,
    postal_code: '06411'
  },
  '06418': {
    country: 'US',
    city: 'Derby',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3229,
    longitude: -73.08,
    postal_code: '06418'
  },
  '06437': {
    country: 'US',
    city: 'Guilford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3154,
    longitude: -72.6968,
    postal_code: '06437'
  },
  '06443': {
    country: 'US',
    city: 'Madison',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.309,
    longitude: -72.6153,
    postal_code: '06443'
  },
  '06450': {
    country: 'US',
    city: 'Meriden',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5334,
    longitude: -72.7997,
    postal_code: '06450'
  },
  '06451': {
    country: 'US',
    city: 'Meriden',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5401,
    longitude: -72.8189,
    postal_code: '06451'
  },
  '06460': {
    country: 'US',
    city: 'Milford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.2175,
    longitude: -73.0549,
    postal_code: '06460'
  },
  '06461': {
    country: 'US',
    city: 'Milford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.2338,
    longitude: -73.0747,
    postal_code: '06461'
  },
  '06471': {
    country: 'US',
    city: 'North Branford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3323,
    longitude: -72.7809,
    postal_code: '06471'
  },
  '06472': {
    country: 'US',
    city: 'Northford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3962,
    longitude: -72.7809,
    postal_code: '06472'
  },
  '06473': {
    country: 'US',
    city: 'North Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3822,
    longitude: -72.8585,
    postal_code: '06473'
  },
  '06477': {
    country: 'US',
    city: 'Orange',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.2815,
    longitude: -73.0287,
    postal_code: '06477'
  },
  '06478': {
    country: 'US',
    city: 'Oxford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.4202,
    longitude: -73.1296,
    postal_code: '06478'
  },
  '06483': {
    country: 'US',
    city: 'Seymour',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3862,
    longitude: -73.0817,
    postal_code: '06483'
  },
  '06487': {
    country: 'US',
    city: 'South Britain',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.4709,
    longitude: -73.2515,
    postal_code: '06487'
  },
  '06488': {
    country: 'US',
    city: 'Southbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.4767,
    longitude: -73.2241,
    postal_code: '06488'
  },
  '06492': {
    country: 'US',
    city: 'Wallingford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.46,
    longitude: -72.8222,
    postal_code: '06492'
  },
  '06493': {
    country: 'US',
    city: 'Wallingford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3657,
    longitude: -72.9275,
    postal_code: '06493'
  },
  '06494': {
    country: 'US',
    city: 'Wallingford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3657,
    longitude: -72.9275,
    postal_code: '06494'
  },
  '06495': {
    country: 'US',
    city: 'Wallingford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3885,
    longitude: -72.8795,
    postal_code: '06495'
  },
  '06501': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06501'
  },
  '06502': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06502'
  },
  '06503': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06503'
  },
  '06504': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3657,
    longitude: -72.9275,
    postal_code: '06504'
  },
  '06505': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06505'
  },
  '06506': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06506'
  },
  '06507': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06507'
  },
  '06508': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06508'
  },
  '06509': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06509'
  },
  '06510': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3087,
    longitude: -72.9271,
    postal_code: '06510'
  },
  '06511': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3184,
    longitude: -72.9318,
    postal_code: '06511'
  },
  '06512': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06512'
  },
  '06513': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3072,
    longitude: -72.8654,
    postal_code: '06513'
  },
  '06514': {
    country: 'US',
    city: 'Hamden',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.362,
    longitude: -72.9361,
    postal_code: '06514'
  },
  '06515': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3293,
    longitude: -72.9664,
    postal_code: '06515'
  },
  '06516': {
    country: 'US',
    city: 'West Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.2701,
    longitude: -72.9638,
    postal_code: '06516'
  },
  '06517': {
    country: 'US',
    city: 'Hamden',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3484,
    longitude: -72.9117,
    postal_code: '06517'
  },
  '06518': {
    country: 'US',
    city: 'Hamden',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.4097,
    longitude: -72.911,
    postal_code: '06518'
  },
  '06519': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.2963,
    longitude: -72.9373,
    postal_code: '06519'
  },
  '06520': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06520'
  },
  '06521': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06521'
  },
  '06524': {
    country: 'US',
    city: 'Bethany',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.4262,
    longitude: -73.0007,
    postal_code: '06524'
  },
  '06525': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06525'
  },
  '06530': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06530'
  },
  '06531': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06531'
  },
  '06532': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06532'
  },
  '06533': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06533'
  },
  '06534': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06534'
  },
  '06535': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06535'
  },
  '06536': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3082,
    longitude: -72.9282,
    postal_code: '06536'
  },
  '06537': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3657,
    longitude: -72.9275,
    postal_code: '06537'
  },
  '06538': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.3657,
    longitude: -72.9275,
    postal_code: '06538'
  },
  '06540': {
    country: 'US',
    city: 'New Haven',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.2996,
    longitude: -72.9188,
    postal_code: '06540'
  },
  '06701': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5582,
    longitude: -73.0515,
    postal_code: '06701'
  },
  '06702': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5566,
    longitude: -73.0385,
    postal_code: '06702'
  },
  '06703': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5582,
    longitude: -73.0515,
    postal_code: '06703'
  },
  '06704': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5754,
    longitude: -73.0318,
    postal_code: '06704'
  },
  '06705': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5503,
    longitude: -72.9963,
    postal_code: '06705'
  },
  '06706': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5363,
    longitude: -73.0306,
    postal_code: '06706'
  },
  '06708': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5511,
    longitude: -73.0645,
    postal_code: '06708'
  },
  '06710': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5675,
    longitude: -73.0468,
    postal_code: '06710'
  },
  '06712': {
    country: 'US',
    city: 'Prospect',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5022,
    longitude: -72.9788,
    postal_code: '06712'
  },
  '06716': {
    country: 'US',
    city: 'Wolcott',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.597,
    longitude: -72.9828,
    postal_code: '06716'
  },
  '06720': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5582,
    longitude: -73.0515,
    postal_code: '06720'
  },
  '06721': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5582,
    longitude: -73.0515,
    postal_code: '06721'
  },
  '06722': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5582,
    longitude: -73.0515,
    postal_code: '06722'
  },
  '06723': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5582,
    longitude: -73.0515,
    postal_code: '06723'
  },
  '06724': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5582,
    longitude: -73.0515,
    postal_code: '06724'
  },
  '06725': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5582,
    longitude: -73.0515,
    postal_code: '06725'
  },
  '06726': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5582,
    longitude: -73.0515,
    postal_code: '06726'
  },
  '06749': {
    country: 'US',
    city: 'Waterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5582,
    longitude: -73.0515,
    postal_code: '06749'
  },
  '06762': {
    country: 'US',
    city: 'Middlebury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.5343,
    longitude: -73.1131,
    postal_code: '06762'
  },
  '06770': {
    country: 'US',
    city: 'Naugatuck',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New Haven',
    latitude: 41.492,
    longitude: -73.0493,
    postal_code: '06770'
  },
  '06249': {
    country: 'US',
    city: 'Lebanon',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.633,
    longitude: -72.244,
    postal_code: '06249'
  },
  '06254': {
    country: 'US',
    city: 'North Franklin',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.6161,
    longitude: -72.1425,
    postal_code: '06254'
  },
  '06320': {
    country: 'US',
    city: 'New London',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3507,
    longitude: -72.1062,
    postal_code: '06320'
  },
  '06330': {
    country: 'US',
    city: 'Baltic',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.6263,
    longitude: -72.0775,
    postal_code: '06330'
  },
  '06333': {
    country: 'US',
    city: 'East Lyme',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3668,
    longitude: -72.233,
    postal_code: '06333'
  },
  '06334': {
    country: 'US',
    city: 'Bozrah',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.5465,
    longitude: -72.1711,
    postal_code: '06334'
  },
  '06335': {
    country: 'US',
    city: 'Gales Ferry',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.4285,
    longitude: -72.0672,
    postal_code: '06335'
  },
  '06336': {
    country: 'US',
    city: 'Gilman',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.5795,
    longitude: -72.1963,
    postal_code: '06336'
  },
  '06338': {
    country: 'US',
    city: 'Mashantucket',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.4757,
    longitude: -71.9574,
    postal_code: '06338'
  },
  '06339': {
    country: 'US',
    city: 'Ledyard',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.4401,
    longitude: -71.9956,
    postal_code: '06339'
  },
  '06340': {
    country: 'US',
    city: 'Groton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3572,
    longitude: -72.0579,
    postal_code: '06340'
  },
  '06349': {
    country: 'US',
    city: 'Groton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3997,
    longitude: -72.0904,
    postal_code: '06349'
  },
  '06350': {
    country: 'US',
    city: 'Hanover',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.6445,
    longitude: -72.0677,
    postal_code: '06350'
  },
  '06351': {
    country: 'US',
    city: 'Jewett City',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.6052,
    longitude: -71.9808,
    postal_code: '06351'
  },
  '06353': {
    country: 'US',
    city: 'Montville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.479,
    longitude: -72.1512,
    postal_code: '06353'
  },
  '06355': {
    country: 'US',
    city: 'Mystic',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3616,
    longitude: -71.9774,
    postal_code: '06355'
  },
  '06357': {
    country: 'US',
    city: 'Niantic',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3253,
    longitude: -72.2108,
    postal_code: '06357'
  },
  '06359': {
    country: 'US',
    city: 'North Stonington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.4531,
    longitude: -71.8727,
    postal_code: '06359'
  },
  '06360': {
    country: 'US',
    city: 'Norwich',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.5371,
    longitude: -72.0849,
    postal_code: '06360'
  },
  '06365': {
    country: 'US',
    city: 'Preston',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.5224,
    longitude: -71.9934,
    postal_code: '06365'
  },
  '06370': {
    country: 'US',
    city: 'Oakdale',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.4706,
    longitude: -72.1904,
    postal_code: '06370'
  },
  '06371': {
    country: 'US',
    city: 'Old Lyme',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3347,
    longitude: -72.3086,
    postal_code: '06371'
  },
  '06372': {
    country: 'US',
    city: 'Old Mystic',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3882,
    longitude: -71.9495,
    postal_code: '06372'
  },
  '06375': {
    country: 'US',
    city: 'Quaker Hill',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.4032,
    longitude: -72.1172,
    postal_code: '06375'
  },
  '06376': {
    country: 'US',
    city: 'South Lyme',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.2967,
    longitude: -72.2633,
    postal_code: '06376'
  },
  '06378': {
    country: 'US',
    city: 'Stonington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3664,
    longitude: -71.9155,
    postal_code: '06378'
  },
  '06379': {
    country: 'US',
    city: 'Pawcatuck',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3735,
    longitude: -71.8478,
    postal_code: '06379'
  },
  '06380': {
    country: 'US',
    city: 'Taftville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.5653,
    longitude: -72.0529,
    postal_code: '06380'
  },
  '06382': {
    country: 'US',
    city: 'Uncasville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.4622,
    longitude: -72.1126,
    postal_code: '06382'
  },
  '06383': {
    country: 'US',
    city: 'Versailles',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.6016,
    longitude: -72.0404,
    postal_code: '06383'
  },
  '06384': {
    country: 'US',
    city: 'Voluntown',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.5831,
    longitude: -71.855,
    postal_code: '06384'
  },
  '06385': {
    country: 'US',
    city: 'Waterford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3469,
    longitude: -72.1458,
    postal_code: '06385'
  },
  '06388': {
    country: 'US',
    city: 'West Mystic',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.3441,
    longitude: -71.9765,
    postal_code: '06388'
  },
  '06389': {
    country: 'US',
    city: 'Yantic',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.5598,
    longitude: -72.1237,
    postal_code: '06389'
  },
  '06415': {
    country: 'US',
    city: 'Colchester',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.5662,
    longitude: -72.3441,
    postal_code: '06415'
  },
  '06420': {
    country: 'US',
    city: 'Salem',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.4966,
    longitude: -72.2725,
    postal_code: '06420'
  },
  '06439': {
    country: 'US',
    city: 'Hadlyme',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.4212,
    longitude: -72.4141,
    postal_code: '06439'
  },
  '06474': {
    country: 'US',
    city: 'North Westchester',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'New London',
    latitude: 41.5809,
    longitude: -72.4012,
    postal_code: '06474'
  },
  '06029': {
    country: 'US',
    city: 'Ellington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.9114,
    longitude: -72.4626,
    postal_code: '06029'
  },
  '06043': {
    country: 'US',
    city: 'Bolton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.7689,
    longitude: -72.4396,
    postal_code: '06043'
  },
  '06066': {
    country: 'US',
    city: 'Vernon Rockville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.8501,
    longitude: -72.4649,
    postal_code: '06066'
  },
  '06071': {
    country: 'US',
    city: 'Somers',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.9978,
    longitude: -72.4583,
    postal_code: '06071'
  },
  '06072': {
    country: 'US',
    city: 'Somersville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.9765,
    longitude: -72.4906,
    postal_code: '06072'
  },
  '06075': {
    country: 'US',
    city: 'Stafford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.9848,
    longitude: -72.289,
    postal_code: '06075'
  },
  '06076': {
    country: 'US',
    city: 'Stafford Springs',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.9661,
    longitude: -72.2899,
    postal_code: '06076'
  },
  '06077': {
    country: 'US',
    city: 'Staffordville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.9916,
    longitude: -72.2577,
    postal_code: '06077'
  },
  '06084': {
    country: 'US',
    city: 'Tolland',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.8696,
    longitude: -72.3718,
    postal_code: '06084'
  },
  '06231': {
    country: 'US',
    city: 'Amston',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.629,
    longitude: -72.3646,
    postal_code: '06231'
  },
  '06232': {
    country: 'US',
    city: 'Andover',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.7332,
    longitude: -72.3767,
    postal_code: '06232'
  },
  '06237': {
    country: 'US',
    city: 'Columbia',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.6973,
    longitude: -72.3072,
    postal_code: '06237'
  },
  '06238': {
    country: 'US',
    city: 'Coventry',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.7822,
    longitude: -72.3332,
    postal_code: '06238'
  },
  '06248': {
    country: 'US',
    city: 'Hebron',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.6842,
    longitude: -72.3986,
    postal_code: '06248'
  },
  '06250': {
    country: 'US',
    city: 'Mansfield Center',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.7698,
    longitude: -72.2011,
    postal_code: '06250'
  },
  '06251': {
    country: 'US',
    city: 'Mansfield Depot',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.7993,
    longitude: -72.3065,
    postal_code: '06251'
  },
  '06265': {
    country: 'US',
    city: 'South Willington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.8554,
    longitude: -72.3015,
    postal_code: '06265'
  },
  '06268': {
    country: 'US',
    city: 'Storrs Mansfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.7997,
    longitude: -72.2478,
    postal_code: '06268'
  },
  '06269': {
    country: 'US',
    city: 'Storrs Mansfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.808,
    longitude: -72.251,
    postal_code: '06269'
  },
  '06279': {
    country: 'US',
    city: 'Willington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Tolland',
    latitude: 41.8966,
    longitude: -72.2622,
    postal_code: '06279'
  },
  '06226': {
    country: 'US',
    city: 'Willimantic',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.7149,
    longitude: -72.2134,
    postal_code: '06226'
  },
  '06230': {
    country: 'US',
    city: 'Abington',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.8473,
    longitude: -72.0253,
    postal_code: '06230'
  },
  '06233': {
    country: 'US',
    city: 'Ballouville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.8768,
    longitude: -71.8612,
    postal_code: '06233'
  },
  '06234': {
    country: 'US',
    city: 'Brooklyn',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.7807,
    longitude: -71.9541,
    postal_code: '06234'
  },
  '06235': {
    country: 'US',
    city: 'Chaplin',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.8026,
    longitude: -72.1372,
    postal_code: '06235'
  },
  '06239': {
    country: 'US',
    city: 'Danielson',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.7982,
    longitude: -71.8807,
    postal_code: '06239'
  },
  '06241': {
    country: 'US',
    city: 'Dayville',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.854,
    longitude: -71.8683,
    postal_code: '06241'
  },
  '06242': {
    country: 'US',
    city: 'Eastford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.8771,
    longitude: -72.0895,
    postal_code: '06242'
  },
  '06243': {
    country: 'US',
    city: 'East Killingly',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.8452,
    longitude: -71.8026,
    postal_code: '06243'
  },
  '06244': {
    country: 'US',
    city: 'East Woodstock',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.9843,
    longitude: -71.981,
    postal_code: '06244'
  },
  '06245': {
    country: 'US',
    city: 'Fabyan',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 42.0198,
    longitude: -71.9417,
    postal_code: '06245'
  },
  '06246': {
    country: 'US',
    city: 'Grosvenor Dale',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.9711,
    longitude: -71.8917,
    postal_code: '06246'
  },
  '06247': {
    country: 'US',
    city: 'Hampton',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.7671,
    longitude: -72.0662,
    postal_code: '06247'
  },
  '06255': {
    country: 'US',
    city: 'North Grosvenordale',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.9784,
    longitude: -71.8997,
    postal_code: '06255'
  },
  '06256': {
    country: 'US',
    city: 'North Windham',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.7451,
    longitude: -72.1601,
    postal_code: '06256'
  },
  '06258': {
    country: 'US',
    city: 'Pomfret',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.889,
    longitude: -71.9682,
    postal_code: '06258'
  },
  '06259': {
    country: 'US',
    city: 'Pomfret Center',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.8697,
    longitude: -71.982,
    postal_code: '06259'
  },
  '06260': {
    country: 'US',
    city: 'Putnam',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.9185,
    longitude: -71.8968,
    postal_code: '06260'
  },
  '06262': {
    country: 'US',
    city: 'Quinebaug',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 42.0217,
    longitude: -71.9391,
    postal_code: '06262'
  },
  '06263': {
    country: 'US',
    city: 'Rogers',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.8391,
    longitude: -71.9063,
    postal_code: '06263'
  },
  '06264': {
    country: 'US',
    city: 'Scotland',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.6958,
    longitude: -72.087,
    postal_code: '06264'
  },
  '06266': {
    country: 'US',
    city: 'South Windham',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.6677,
    longitude: -72.1681,
    postal_code: '06266'
  },
  '06267': {
    country: 'US',
    city: 'South Woodstock',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.9439,
    longitude: -71.9453,
    postal_code: '06267'
  },
  '06277': {
    country: 'US',
    city: 'Thompson',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.9803,
    longitude: -71.8376,
    postal_code: '06277'
  },
  '06278': {
    country: 'US',
    city: 'Ashford',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.889,
    longitude: -72.1476,
    postal_code: '06278'
  },
  '06280': {
    country: 'US',
    city: 'Windham',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.7027,
    longitude: -72.1526,
    postal_code: '06280'
  },
  '06281': {
    country: 'US',
    city: 'Woodstock',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.9602,
    longitude: -72.004,
    postal_code: '06281'
  },
  '06282': {
    country: 'US',
    city: 'Woodstock Valley',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.9153,
    longitude: -72.0937,
    postal_code: '06282'
  },
  '06331': {
    country: 'US',
    city: 'Canterbury',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.6844,
    longitude: -72.001,
    postal_code: '06331'
  },
  '06332': {
    country: 'US',
    city: 'Central Village',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.7229,
    longitude: -71.9067,
    postal_code: '06332'
  },
  '06354': {
    country: 'US',
    city: 'Moosup',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.721,
    longitude: -71.885,
    postal_code: '06354'
  },
  '06373': {
    country: 'US',
    city: 'Oneco',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.6937,
    longitude: -71.8084,
    postal_code: '06373'
  },
  '06374': {
    country: 'US',
    city: 'Plainfield',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.6775,
    longitude: -71.922,
    postal_code: '06374'
  },
  '06377': {
    country: 'US',
    city: 'Sterling',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.7156,
    longitude: -71.8196,
    postal_code: '06377'
  },
  '06387': {
    country: 'US',
    city: 'Wauregan',
    state: 'Connecticut',
    state_short: 'CT',
    county: 'Windham',
    latitude: 41.7445,
    longitude: -71.9133,
    postal_code: '06387'
  },
  '02532': {
    country: 'US',
    city: 'Buzzards Bay',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7455,
    longitude: -70.5905,
    postal_code: '02532'
  },
  '02534': {
    country: 'US',
    city: 'Cataumet',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6694,
    longitude: -70.6234,
    postal_code: '02534'
  },
  '02536': {
    country: 'US',
    city: 'East Falmouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.5968,
    longitude: -70.5671,
    postal_code: '02536'
  },
  '02537': {
    country: 'US',
    city: 'East Sandwich',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7283,
    longitude: -70.44,
    postal_code: '02537'
  },
  '02540': {
    country: 'US',
    city: 'Falmouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.5648,
    longitude: -70.6217,
    postal_code: '02540'
  },
  '02541': {
    country: 'US',
    city: 'Falmouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.5515,
    longitude: -70.6148,
    postal_code: '02541'
  },
  '02542': {
    country: 'US',
    city: 'Buzzards Bay',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6531,
    longitude: -70.5537,
    postal_code: '02542'
  },
  '02543': {
    country: 'US',
    city: 'Woods Hole',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.5263,
    longitude: -70.6643,
    postal_code: '02543'
  },
  '02553': {
    country: 'US',
    city: 'Monument Beach',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7196,
    longitude: -70.612,
    postal_code: '02553'
  },
  '02556': {
    country: 'US',
    city: 'North Falmouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6417,
    longitude: -70.623,
    postal_code: '02556'
  },
  '02559': {
    country: 'US',
    city: 'Pocasset',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6881,
    longitude: -70.6105,
    postal_code: '02559'
  },
  '02561': {
    country: 'US',
    city: 'Sagamore',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7703,
    longitude: -70.5337,
    postal_code: '02561'
  },
  '02562': {
    country: 'US',
    city: 'Sagamore Beach',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7933,
    longitude: -70.5196,
    postal_code: '02562'
  },
  '02563': {
    country: 'US',
    city: 'Sandwich',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7113,
    longitude: -70.4775,
    postal_code: '02563'
  },
  '02574': {
    country: 'US',
    city: 'West Falmouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6039,
    longitude: -70.6382,
    postal_code: '02574'
  },
  '02601': {
    country: 'US',
    city: 'Hyannis',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6601,
    longitude: -70.2967,
    postal_code: '02601'
  },
  '02630': {
    country: 'US',
    city: 'Barnstable',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6983,
    longitude: -70.3001,
    postal_code: '02630'
  },
  '02631': {
    country: 'US',
    city: 'Brewster',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7492,
    longitude: -70.0699,
    postal_code: '02631'
  },
  '02632': {
    country: 'US',
    city: 'Centerville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6606,
    longitude: -70.3532,
    postal_code: '02632'
  },
  '02633': {
    country: 'US',
    city: 'Chatham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6889,
    longitude: -69.9722,
    postal_code: '02633'
  },
  '02634': {
    country: 'US',
    city: 'Centerville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6487,
    longitude: -70.3481,
    postal_code: '02634'
  },
  '02635': {
    country: 'US',
    city: 'Cotuit',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6243,
    longitude: -70.4364,
    postal_code: '02635'
  },
  '02637': {
    country: 'US',
    city: 'Cummaquid',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7014,
    longitude: -70.2772,
    postal_code: '02637'
  },
  '02638': {
    country: 'US',
    city: 'Dennis',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7322,
    longitude: -70.1911,
    postal_code: '02638'
  },
  '02639': {
    country: 'US',
    city: 'Dennis Port',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6649,
    longitude: -70.1327,
    postal_code: '02639'
  },
  '02641': {
    country: 'US',
    city: 'East Dennis',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7426,
    longitude: -70.162,
    postal_code: '02641'
  },
  '02642': {
    country: 'US',
    city: 'Eastham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.8408,
    longitude: -69.9849,
    postal_code: '02642'
  },
  '02643': {
    country: 'US',
    city: 'East Orleans',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7843,
    longitude: -69.962,
    postal_code: '02643'
  },
  '02644': {
    country: 'US',
    city: 'Forestdale',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6827,
    longitude: -70.5143,
    postal_code: '02644'
  },
  '02645': {
    country: 'US',
    city: 'Harwich',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7008,
    longitude: -70.0579,
    postal_code: '02645'
  },
  '02646': {
    country: 'US',
    city: 'Harwich Port',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6703,
    longitude: -70.0722,
    postal_code: '02646'
  },
  '02647': {
    country: 'US',
    city: 'Hyannis Port',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.635,
    longitude: -70.3063,
    postal_code: '02647'
  },
  '02648': {
    country: 'US',
    city: 'Marstons Mills',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6703,
    longitude: -70.4163,
    postal_code: '02648'
  },
  '02649': {
    country: 'US',
    city: 'Mashpee',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6181,
    longitude: -70.4854,
    postal_code: '02649'
  },
  '02650': {
    country: 'US',
    city: 'North Chatham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.703,
    longitude: -69.9666,
    postal_code: '02650'
  },
  '02651': {
    country: 'US',
    city: 'North Eastham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.8243,
    longitude: -69.9818,
    postal_code: '02651'
  },
  '02652': {
    country: 'US',
    city: 'North Truro',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 42.0338,
    longitude: -70.0875,
    postal_code: '02652'
  },
  '02653': {
    country: 'US',
    city: 'Orleans',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7792,
    longitude: -69.9822,
    postal_code: '02653'
  },
  '02655': {
    country: 'US',
    city: 'Osterville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.63,
    longitude: -70.3837,
    postal_code: '02655'
  },
  '02657': {
    country: 'US',
    city: 'Provincetown',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 42.0534,
    longitude: -70.1865,
    postal_code: '02657'
  },
  '02659': {
    country: 'US',
    city: 'South Chatham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6801,
    longitude: -70.0241,
    postal_code: '02659'
  },
  '02660': {
    country: 'US',
    city: 'South Dennis',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7097,
    longitude: -70.1585,
    postal_code: '02660'
  },
  '02661': {
    country: 'US',
    city: 'South Harwich',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6862,
    longitude: -70.0329,
    postal_code: '02661'
  },
  '02662': {
    country: 'US',
    city: 'South Orleans',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7567,
    longitude: -69.9841,
    postal_code: '02662'
  },
  '02663': {
    country: 'US',
    city: 'South Wellfleet',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.9182,
    longitude: -69.9953,
    postal_code: '02663'
  },
  '02664': {
    country: 'US',
    city: 'South Yarmouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6739,
    longitude: -70.1949,
    postal_code: '02664'
  },
  '02666': {
    country: 'US',
    city: 'Truro',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.9988,
    longitude: -70.0564,
    postal_code: '02666'
  },
  '02667': {
    country: 'US',
    city: 'Wellfleet',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.9289,
    longitude: -70.0186,
    postal_code: '02667'
  },
  '02668': {
    country: 'US',
    city: 'West Barnstable',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7002,
    longitude: -70.372,
    postal_code: '02668'
  },
  '02669': {
    country: 'US',
    city: 'West Chatham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6812,
    longitude: -69.9911,
    postal_code: '02669'
  },
  '02670': {
    country: 'US',
    city: 'West Dennis',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6626,
    longitude: -70.1681,
    postal_code: '02670'
  },
  '02671': {
    country: 'US',
    city: 'West Harwich',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6694,
    longitude: -70.1135,
    postal_code: '02671'
  },
  '02672': {
    country: 'US',
    city: 'West Hyannisport',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6356,
    longitude: -70.3233,
    postal_code: '02672'
  },
  '02673': {
    country: 'US',
    city: 'West Yarmouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.6614,
    longitude: -70.2363,
    postal_code: '02673'
  },
  '02675': {
    country: 'US',
    city: 'Yarmouth Port',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Barnstable',
    latitude: 41.7051,
    longitude: -70.227,
    postal_code: '02675'
  },
  '01029': {
    country: 'US',
    city: 'East Otis',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.1909,
    longitude: -73.0517,
    postal_code: '01029'
  },
  '01201': {
    country: 'US',
    city: 'Pittsfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.4531,
    longitude: -73.2471,
    postal_code: '01201'
  },
  '01202': {
    country: 'US',
    city: 'Pittsfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.3929,
    longitude: -73.2285,
    postal_code: '01202'
  },
  '01203': {
    country: 'US',
    city: 'Pittsfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.3929,
    longitude: -73.2285,
    postal_code: '01203'
  },
  '01220': {
    country: 'US',
    city: 'Adams',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.6223,
    longitude: -73.1172,
    postal_code: '01220'
  },
  '01222': {
    country: 'US',
    city: 'Ashley Falls',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.0596,
    longitude: -73.3202,
    postal_code: '01222'
  },
  '01223': {
    country: 'US',
    city: 'Becket',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.3594,
    longitude: -73.1203,
    postal_code: '01223'
  },
  '01224': {
    country: 'US',
    city: 'Berkshire',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.5126,
    longitude: -73.1929,
    postal_code: '01224'
  },
  '01225': {
    country: 'US',
    city: 'Cheshire',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.5611,
    longitude: -73.158,
    postal_code: '01225'
  },
  '01226': {
    country: 'US',
    city: 'Dalton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.475,
    longitude: -73.1603,
    postal_code: '01226'
  },
  '01227': {
    country: 'US',
    city: 'Dalton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.4737,
    longitude: -73.1662,
    postal_code: '01227'
  },
  '01229': {
    country: 'US',
    city: 'Glendale',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.2793,
    longitude: -73.3435,
    postal_code: '01229'
  },
  '01230': {
    country: 'US',
    city: 'Great Barrington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.1959,
    longitude: -73.3607,
    postal_code: '01230'
  },
  '01235': {
    country: 'US',
    city: 'Hinsdale',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.4298,
    longitude: -73.0724,
    postal_code: '01235'
  },
  '01236': {
    country: 'US',
    city: 'Housatonic',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.2653,
    longitude: -73.3745,
    postal_code: '01236'
  },
  '01237': {
    country: 'US',
    city: 'Lanesborough',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.5173,
    longitude: -73.2282,
    postal_code: '01237'
  },
  '01238': {
    country: 'US',
    city: 'Lee',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.299,
    longitude: -73.2317,
    postal_code: '01238'
  },
  '01240': {
    country: 'US',
    city: 'Lenox',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.3642,
    longitude: -73.2713,
    postal_code: '01240'
  },
  '01242': {
    country: 'US',
    city: 'Lenox Dale',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.3386,
    longitude: -73.2509,
    postal_code: '01242'
  },
  '01244': {
    country: 'US',
    city: 'Mill River',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.1228,
    longitude: -73.254,
    postal_code: '01244'
  },
  '01245': {
    country: 'US',
    city: 'Monterey',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.1867,
    longitude: -73.2065,
    postal_code: '01245'
  },
  '01247': {
    country: 'US',
    city: 'North Adams',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.6955,
    longitude: -73.08,
    postal_code: '01247'
  },
  '01252': {
    country: 'US',
    city: 'North Egremont',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.1986,
    longitude: -73.4462,
    postal_code: '01252'
  },
  '01253': {
    country: 'US',
    city: 'Otis',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.1931,
    longitude: -73.0918,
    postal_code: '01253'
  },
  '01254': {
    country: 'US',
    city: 'Richmond',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.3784,
    longitude: -73.3645,
    postal_code: '01254'
  },
  '01255': {
    country: 'US',
    city: 'Sandisfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.1094,
    longitude: -73.1163,
    postal_code: '01255'
  },
  '01256': {
    country: 'US',
    city: 'Savoy',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.577,
    longitude: -73.0233,
    postal_code: '01256'
  },
  '01257': {
    country: 'US',
    city: 'Sheffield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.1001,
    longitude: -73.3611,
    postal_code: '01257'
  },
  '01258': {
    country: 'US',
    city: 'South Egremont',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.1012,
    longitude: -73.4566,
    postal_code: '01258'
  },
  '01259': {
    country: 'US',
    city: 'Southfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.078,
    longitude: -73.2609,
    postal_code: '01259'
  },
  '01260': {
    country: 'US',
    city: 'South Lee',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.2779,
    longitude: -73.2773,
    postal_code: '01260'
  },
  '01262': {
    country: 'US',
    city: 'Stockbridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.301,
    longitude: -73.3223,
    postal_code: '01262'
  },
  '01263': {
    country: 'US',
    city: 'Stockbridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.3929,
    longitude: -73.2285,
    postal_code: '01263'
  },
  '01264': {
    country: 'US',
    city: 'Tyringham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.22,
    longitude: -73.1979,
    postal_code: '01264'
  },
  '01266': {
    country: 'US',
    city: 'West Stockbridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.3348,
    longitude: -73.3825,
    postal_code: '01266'
  },
  '01267': {
    country: 'US',
    city: 'Williamstown',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.7089,
    longitude: -73.2036,
    postal_code: '01267'
  },
  '01270': {
    country: 'US',
    city: 'Windsor',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.515,
    longitude: -73.0412,
    postal_code: '01270'
  },
  '01343': {
    country: 'US',
    city: 'Drury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Berkshire',
    latitude: 42.6427,
    longitude: -72.9862,
    postal_code: '01343'
  },
  '02048': {
    country: 'US',
    city: 'Mansfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 42.0212,
    longitude: -71.2178,
    postal_code: '02048'
  },
  '02334': {
    country: 'US',
    city: 'Easton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 42.0235,
    longitude: -71.1324,
    postal_code: '02334'
  },
  '02356': {
    country: 'US',
    city: 'North Easton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 42.059,
    longitude: -71.1123,
    postal_code: '02356'
  },
  '02357': {
    country: 'US',
    city: 'North Easton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 42.0645,
    longitude: -71.0871,
    postal_code: '02357'
  },
  '02375': {
    country: 'US',
    city: 'South Easton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 42.0257,
    longitude: -71.0988,
    postal_code: '02375'
  },
  '02702': {
    country: 'US',
    city: 'Assonet',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.7975,
    longitude: -71.0607,
    postal_code: '02702'
  },
  '02703': {
    country: 'US',
    city: 'Attleboro',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.9296,
    longitude: -71.3009,
    postal_code: '02703'
  },
  '02712': {
    country: 'US',
    city: 'Chartley',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.9487,
    longitude: -71.2259,
    postal_code: '02712'
  },
  '02714': {
    country: 'US',
    city: 'Dartmouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.7562,
    longitude: -71.0671,
    postal_code: '02714'
  },
  '02715': {
    country: 'US',
    city: 'Dighton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.8125,
    longitude: -71.1427,
    postal_code: '02715'
  },
  '02717': {
    country: 'US',
    city: 'East Freetown',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.7635,
    longitude: -70.9677,
    postal_code: '02717'
  },
  '02718': {
    country: 'US',
    city: 'East Taunton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.8736,
    longitude: -71.0192,
    postal_code: '02718'
  },
  '02719': {
    country: 'US',
    city: 'Fairhaven',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6409,
    longitude: -70.8896,
    postal_code: '02719'
  },
  '02720': {
    country: 'US',
    city: 'Fall River',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.7182,
    longitude: -71.14,
    postal_code: '02720'
  },
  '02721': {
    country: 'US',
    city: 'Fall River',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6883,
    longitude: -71.1574,
    postal_code: '02721'
  },
  '02722': {
    country: 'US',
    city: 'Fall River',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.7015,
    longitude: -71.155,
    postal_code: '02722'
  },
  '02723': {
    country: 'US',
    city: 'Fall River',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6926,
    longitude: -71.1332,
    postal_code: '02723'
  },
  '02724': {
    country: 'US',
    city: 'Fall River',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.685,
    longitude: -71.1748,
    postal_code: '02724'
  },
  '02725': {
    country: 'US',
    city: 'Somerset',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.7223,
    longitude: -71.178,
    postal_code: '02725'
  },
  '02726': {
    country: 'US',
    city: 'Somerset',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.756,
    longitude: -71.1492,
    postal_code: '02726'
  },
  '02740': {
    country: 'US',
    city: 'New Bedford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6347,
    longitude: -70.9372,
    postal_code: '02740'
  },
  '02741': {
    country: 'US',
    city: 'New Bedford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6362,
    longitude: -70.9342,
    postal_code: '02741'
  },
  '02742': {
    country: 'US',
    city: 'New Bedford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6362,
    longitude: -70.9342,
    postal_code: '02742'
  },
  '02743': {
    country: 'US',
    city: 'Acushnet',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6997,
    longitude: -70.9087,
    postal_code: '02743'
  },
  '02744': {
    country: 'US',
    city: 'New Bedford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6127,
    longitude: -70.9167,
    postal_code: '02744'
  },
  '02745': {
    country: 'US',
    city: 'New Bedford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6913,
    longitude: -70.9355,
    postal_code: '02745'
  },
  '02746': {
    country: 'US',
    city: 'New Bedford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.66,
    longitude: -70.9324,
    postal_code: '02746'
  },
  '02747': {
    country: 'US',
    city: 'North Dartmouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6338,
    longitude: -70.9958,
    postal_code: '02747'
  },
  '02748': {
    country: 'US',
    city: 'South Dartmouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.5665,
    longitude: -70.9843,
    postal_code: '02748'
  },
  '02760': {
    country: 'US',
    city: 'North Attleboro',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.9775,
    longitude: -71.3298,
    postal_code: '02760'
  },
  '02761': {
    country: 'US',
    city: 'North Attleboro',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.9834,
    longitude: -71.3328,
    postal_code: '02761'
  },
  '02763': {
    country: 'US',
    city: 'Attleboro Falls',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.9726,
    longitude: -71.3082,
    postal_code: '02763'
  },
  '02764': {
    country: 'US',
    city: 'North Dighton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.8529,
    longitude: -71.1485,
    postal_code: '02764'
  },
  '02766': {
    country: 'US',
    city: 'Norton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.9718,
    longitude: -71.1894,
    postal_code: '02766'
  },
  '02767': {
    country: 'US',
    city: 'Raynham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.9324,
    longitude: -71.0469,
    postal_code: '02767'
  },
  '02768': {
    country: 'US',
    city: 'Raynham Center',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.7562,
    longitude: -71.0671,
    postal_code: '02768'
  },
  '02769': {
    country: 'US',
    city: 'Rehoboth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.8515,
    longitude: -71.2545,
    postal_code: '02769'
  },
  '02771': {
    country: 'US',
    city: 'Seekonk',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.8378,
    longitude: -71.3224,
    postal_code: '02771'
  },
  '02777': {
    country: 'US',
    city: 'Swansea',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.7473,
    longitude: -71.2122,
    postal_code: '02777'
  },
  '02779': {
    country: 'US',
    city: 'Berkley',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.8353,
    longitude: -71.0765,
    postal_code: '02779'
  },
  '02780': {
    country: 'US',
    city: 'Taunton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.905,
    longitude: -71.1026,
    postal_code: '02780'
  },
  '02783': {
    country: 'US',
    city: 'Taunton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.7562,
    longitude: -71.0671,
    postal_code: '02783'
  },
  '02790': {
    country: 'US',
    city: 'Westport',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.6211,
    longitude: -71.089,
    postal_code: '02790'
  },
  '02791': {
    country: 'US',
    city: 'Westport Point',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Bristol',
    latitude: 41.5223,
    longitude: -71.0745,
    postal_code: '02791'
  },
  '02535': {
    country: 'US',
    city: 'Chilmark',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Dukes',
    latitude: 41.3575,
    longitude: -70.7416,
    postal_code: '02535'
  },
  '02539': {
    country: 'US',
    city: 'Edgartown',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Dukes',
    latitude: 41.3889,
    longitude: -70.5339,
    postal_code: '02539'
  },
  '02552': {
    country: 'US',
    city: 'Menemsha',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Dukes',
    latitude: 41.3798,
    longitude: -70.6431,
    postal_code: '02552'
  },
  '02557': {
    country: 'US',
    city: 'Oak Bluffs',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Dukes',
    latitude: 41.4543,
    longitude: -70.562,
    postal_code: '02557'
  },
  '02568': {
    country: 'US',
    city: 'Vineyard Haven',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Dukes',
    latitude: 41.45,
    longitude: -70.5937,
    postal_code: '02568'
  },
  '02575': {
    country: 'US',
    city: 'West Tisbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Dukes',
    latitude: 41.4213,
    longitude: -70.6428,
    postal_code: '02575'
  },
  '02713': {
    country: 'US',
    city: 'Cuttyhunk',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Dukes',
    latitude: 41.4218,
    longitude: -70.9313,
    postal_code: '02713'
  },
  '01810': {
    country: 'US',
    city: 'Andover',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6496,
    longitude: -71.1565,
    postal_code: '01810'
  },
  '01812': {
    country: 'US',
    city: 'Andover',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6472,
    longitude: -71.1842,
    postal_code: '01812'
  },
  '01830': {
    country: 'US',
    city: 'Haverhill',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.7856,
    longitude: -71.0721,
    postal_code: '01830'
  },
  '01831': {
    country: 'US',
    city: 'Haverhill',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.7711,
    longitude: -71.1221,
    postal_code: '01831'
  },
  '01832': {
    country: 'US',
    city: 'Haverhill',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.7792,
    longitude: -71.1095,
    postal_code: '01832'
  },
  '01833': {
    country: 'US',
    city: 'Georgetown',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.7281,
    longitude: -70.9822,
    postal_code: '01833'
  },
  '01834': {
    country: 'US',
    city: 'Groveland',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.753,
    longitude: -71.027,
    postal_code: '01834'
  },
  '01835': {
    country: 'US',
    city: 'Haverhill',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.7528,
    longitude: -71.0843,
    postal_code: '01835'
  },
  '01840': {
    country: 'US',
    city: 'Lawrence',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.708,
    longitude: -71.1638,
    postal_code: '01840'
  },
  '01841': {
    country: 'US',
    city: 'Lawrence',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.7115,
    longitude: -71.167,
    postal_code: '01841'
  },
  '01842': {
    country: 'US',
    city: 'Lawrence',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.707,
    longitude: -71.1631,
    postal_code: '01842'
  },
  '01843': {
    country: 'US',
    city: 'Lawrence',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6911,
    longitude: -71.1605,
    postal_code: '01843'
  },
  '01844': {
    country: 'US',
    city: 'Methuen',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.728,
    longitude: -71.181,
    postal_code: '01844'
  },
  '01845': {
    country: 'US',
    city: 'North Andover',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6826,
    longitude: -71.109,
    postal_code: '01845'
  },
  '01860': {
    country: 'US',
    city: 'Merrimac',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.8346,
    longitude: -71.0047,
    postal_code: '01860'
  },
  '01885': {
    country: 'US',
    city: 'West Boxford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.707,
    longitude: -71.0639,
    postal_code: '01885'
  },
  '01899': {
    country: 'US',
    city: 'Andover',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6584,
    longitude: -71.137,
    postal_code: '01899'
  },
  '01901': {
    country: 'US',
    city: 'Lynn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.4612,
    longitude: -70.9467,
    postal_code: '01901'
  },
  '01902': {
    country: 'US',
    city: 'Lynn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.4698,
    longitude: -70.942,
    postal_code: '01902'
  },
  '01903': {
    country: 'US',
    city: 'Lynn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.4668,
    longitude: -70.9495,
    postal_code: '01903'
  },
  '01904': {
    country: 'US',
    city: 'Lynn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.4889,
    longitude: -70.9647,
    postal_code: '01904'
  },
  '01905': {
    country: 'US',
    city: 'Lynn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.4694,
    longitude: -70.9728,
    postal_code: '01905'
  },
  '01906': {
    country: 'US',
    city: 'Saugus',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.4633,
    longitude: -71.0111,
    postal_code: '01906'
  },
  '01907': {
    country: 'US',
    city: 'Swampscott',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.4746,
    longitude: -70.9098,
    postal_code: '01907'
  },
  '01908': {
    country: 'US',
    city: 'Nahant',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.4261,
    longitude: -70.9277,
    postal_code: '01908'
  },
  '01910': {
    country: 'US',
    city: 'Lynn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.4548,
    longitude: -70.9747,
    postal_code: '01910'
  },
  '01913': {
    country: 'US',
    city: 'Amesbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.8559,
    longitude: -70.9367,
    postal_code: '01913'
  },
  '01915': {
    country: 'US',
    city: 'Beverly',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.5608,
    longitude: -70.8759,
    postal_code: '01915'
  },
  '01921': {
    country: 'US',
    city: 'Boxford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6797,
    longitude: -71.0114,
    postal_code: '01921'
  },
  '01922': {
    country: 'US',
    city: 'Byfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.7568,
    longitude: -70.9351,
    postal_code: '01922'
  },
  '01923': {
    country: 'US',
    city: 'Danvers',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.5694,
    longitude: -70.9425,
    postal_code: '01923'
  },
  '01929': {
    country: 'US',
    city: 'Essex',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6286,
    longitude: -70.7828,
    postal_code: '01929'
  },
  '01930': {
    country: 'US',
    city: 'Gloucester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6208,
    longitude: -70.6721,
    postal_code: '01930'
  },
  '01931': {
    country: 'US',
    city: 'Gloucester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6159,
    longitude: -70.662,
    postal_code: '01931'
  },
  '01936': {
    country: 'US',
    city: 'Hamilton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6354,
    longitude: -70.8791,
    postal_code: '01936'
  },
  '01937': {
    country: 'US',
    city: 'Hathorne',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.5862,
    longitude: -70.9745,
    postal_code: '01937'
  },
  '01938': {
    country: 'US',
    city: 'Ipswich',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6809,
    longitude: -70.8494,
    postal_code: '01938'
  },
  '01940': {
    country: 'US',
    city: 'Lynnfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.5327,
    longitude: -71.0339,
    postal_code: '01940'
  },
  '01944': {
    country: 'US',
    city: 'Manchester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.5796,
    longitude: -70.7674,
    postal_code: '01944'
  },
  '01945': {
    country: 'US',
    city: 'Marblehead',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.4984,
    longitude: -70.8653,
    postal_code: '01945'
  },
  '01949': {
    country: 'US',
    city: 'Middleton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.5942,
    longitude: -71.013,
    postal_code: '01949'
  },
  '01950': {
    country: 'US',
    city: 'Newburyport',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.813,
    longitude: -70.8847,
    postal_code: '01950'
  },
  '01951': {
    country: 'US',
    city: 'Newbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.7835,
    longitude: -70.8474,
    postal_code: '01951'
  },
  '01952': {
    country: 'US',
    city: 'Salisbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.8507,
    longitude: -70.8588,
    postal_code: '01952'
  },
  '01960': {
    country: 'US',
    city: 'Peabody',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.5326,
    longitude: -70.9612,
    postal_code: '01960'
  },
  '01961': {
    country: 'US',
    city: 'Peabody',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6354,
    longitude: -70.8791,
    postal_code: '01961'
  },
  '01965': {
    country: 'US',
    city: 'Prides Crossing',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.5581,
    longitude: -70.8257,
    postal_code: '01965'
  },
  '01966': {
    country: 'US',
    city: 'Rockport',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.658,
    longitude: -70.6194,
    postal_code: '01966'
  },
  '01969': {
    country: 'US',
    city: 'Rowley',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.7138,
    longitude: -70.907,
    postal_code: '01969'
  },
  '01970': {
    country: 'US',
    city: 'Salem',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.5151,
    longitude: -70.9003,
    postal_code: '01970'
  },
  '01971': {
    country: 'US',
    city: 'Salem',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6354,
    longitude: -70.8791,
    postal_code: '01971'
  },
  '01982': {
    country: 'US',
    city: 'South Hamilton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6185,
    longitude: -70.8561,
    postal_code: '01982'
  },
  '01983': {
    country: 'US',
    city: 'Topsfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6415,
    longitude: -70.9488,
    postal_code: '01983'
  },
  '01984': {
    country: 'US',
    city: 'Wenham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6017,
    longitude: -70.8786,
    postal_code: '01984'
  },
  '01985': {
    country: 'US',
    city: 'West Newbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.7949,
    longitude: -70.9778,
    postal_code: '01985'
  },
  '05501': {
    country: 'US',
    city: 'Andover',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6472,
    longitude: -71.1842,
    postal_code: '05501'
  },
  '05544': {
    country: 'US',
    city: 'Andover',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Essex',
    latitude: 42.6472,
    longitude: -71.1842,
    postal_code: '05544'
  },
  '01054': {
    country: 'US',
    city: 'Leverett',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.4682,
    longitude: -72.4993,
    postal_code: '01054'
  },
  '01072': {
    country: 'US',
    city: 'Shutesbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.482,
    longitude: -72.4213,
    postal_code: '01072'
  },
  '01093': {
    country: 'US',
    city: 'Whately',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.4427,
    longitude: -72.6525,
    postal_code: '01093'
  },
  '01301': {
    country: 'US',
    city: 'Greenfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.6013,
    longitude: -72.6236,
    postal_code: '01301'
  },
  '01302': {
    country: 'US',
    city: 'Greenfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5222,
    longitude: -72.6242,
    postal_code: '01302'
  },
  '01330': {
    country: 'US',
    city: 'Ashfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5232,
    longitude: -72.811,
    postal_code: '01330'
  },
  '01337': {
    country: 'US',
    city: 'Bernardston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.6952,
    longitude: -72.5772,
    postal_code: '01337'
  },
  '01338': {
    country: 'US',
    city: 'Buckland',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5736,
    longitude: -72.8213,
    postal_code: '01338'
  },
  '01339': {
    country: 'US',
    city: 'Charlemont',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.608,
    longitude: -72.8901,
    postal_code: '01339'
  },
  '01340': {
    country: 'US',
    city: 'Colrain',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.679,
    longitude: -72.7265,
    postal_code: '01340'
  },
  '01341': {
    country: 'US',
    city: 'Conway',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5138,
    longitude: -72.7025,
    postal_code: '01341'
  },
  '01342': {
    country: 'US',
    city: 'Deerfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5406,
    longitude: -72.6072,
    postal_code: '01342'
  },
  '01344': {
    country: 'US',
    city: 'Erving',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.6076,
    longitude: -72.4292,
    postal_code: '01344'
  },
  '01346': {
    country: 'US',
    city: 'Heath',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.6853,
    longitude: -72.8391,
    postal_code: '01346'
  },
  '01347': {
    country: 'US',
    city: 'Lake Pleasant',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5566,
    longitude: -72.5181,
    postal_code: '01347'
  },
  '01349': {
    country: 'US',
    city: 'Millers Falls',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.582,
    longitude: -72.4926,
    postal_code: '01349'
  },
  '01350': {
    country: 'US',
    city: 'Monroe Bridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.7215,
    longitude: -72.9762,
    postal_code: '01350'
  },
  '01351': {
    country: 'US',
    city: 'Montague',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5429,
    longitude: -72.5328,
    postal_code: '01351'
  },
  '01354': {
    country: 'US',
    city: 'Gill',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.6404,
    longitude: -72.4995,
    postal_code: '01354'
  },
  '01355': {
    country: 'US',
    city: 'New Salem',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5146,
    longitude: -72.3062,
    postal_code: '01355'
  },
  '01360': {
    country: 'US',
    city: 'Northfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.6887,
    longitude: -72.451,
    postal_code: '01360'
  },
  '01364': {
    country: 'US',
    city: 'Orange',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.6129,
    longitude: -72.2924,
    postal_code: '01364'
  },
  '01367': {
    country: 'US',
    city: 'Rowe',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.6953,
    longitude: -72.9258,
    postal_code: '01367'
  },
  '01370': {
    country: 'US',
    city: 'Shelburne Falls',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.6022,
    longitude: -72.7391,
    postal_code: '01370'
  },
  '01373': {
    country: 'US',
    city: 'South Deerfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.4756,
    longitude: -72.6153,
    postal_code: '01373'
  },
  '01375': {
    country: 'US',
    city: 'Sunderland',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.4539,
    longitude: -72.5676,
    postal_code: '01375'
  },
  '01376': {
    country: 'US',
    city: 'Turners Falls',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5954,
    longitude: -72.5557,
    postal_code: '01376'
  },
  '01378': {
    country: 'US',
    city: 'Warwick',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.6671,
    longitude: -72.3397,
    postal_code: '01378'
  },
  '01379': {
    country: 'US',
    city: 'Wendell',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5656,
    longitude: -72.4009,
    postal_code: '01379'
  },
  '01380': {
    country: 'US',
    city: 'Wendell Depot',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Franklin',
    latitude: 42.5534,
    longitude: -72.3927,
    postal_code: '01380'
  },
  '01001': {
    country: 'US',
    city: 'Agawam',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0702,
    longitude: -72.6227,
    postal_code: '01001'
  },
  '01008': {
    country: 'US',
    city: 'Blandford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1829,
    longitude: -72.9361,
    postal_code: '01008'
  },
  '01009': {
    country: 'US',
    city: 'Bondsville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.2061,
    longitude: -72.3405,
    postal_code: '01009'
  },
  '01010': {
    country: 'US',
    city: 'Brimfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1165,
    longitude: -72.1885,
    postal_code: '01010'
  },
  '01011': {
    country: 'US',
    city: 'Chester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.2794,
    longitude: -72.9888,
    postal_code: '01011'
  },
  '01013': {
    country: 'US',
    city: 'Chicopee',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1487,
    longitude: -72.6079,
    postal_code: '01013'
  },
  '01014': {
    country: 'US',
    city: 'Chicopee',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1707,
    longitude: -72.6048,
    postal_code: '01014'
  },
  '01020': {
    country: 'US',
    city: 'Chicopee',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1764,
    longitude: -72.5761,
    postal_code: '01020'
  },
  '01021': {
    country: 'US',
    city: 'Chicopee',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1707,
    longitude: -72.6048,
    postal_code: '01021'
  },
  '01022': {
    country: 'US',
    city: 'Chicopee',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1934,
    longitude: -72.5544,
    postal_code: '01022'
  },
  '01028': {
    country: 'US',
    city: 'East Longmeadow',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0672,
    longitude: -72.5056,
    postal_code: '01028'
  },
  '01030': {
    country: 'US',
    city: 'Feeding Hills',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0718,
    longitude: -72.6751,
    postal_code: '01030'
  },
  '01034': {
    country: 'US',
    city: 'Granville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1127,
    longitude: -72.952,
    postal_code: '01034'
  },
  '01036': {
    country: 'US',
    city: 'Hampden',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0648,
    longitude: -72.4318,
    postal_code: '01036'
  },
  '01040': {
    country: 'US',
    city: 'Holyoke',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.202,
    longitude: -72.6262,
    postal_code: '01040'
  },
  '01041': {
    country: 'US',
    city: 'Holyoke',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.2043,
    longitude: -72.6162,
    postal_code: '01041'
  },
  '01056': {
    country: 'US',
    city: 'Ludlow',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1728,
    longitude: -72.471,
    postal_code: '01056'
  },
  '01057': {
    country: 'US',
    city: 'Monson',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.101,
    longitude: -72.3196,
    postal_code: '01057'
  },
  '01069': {
    country: 'US',
    city: 'Palmer',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1762,
    longitude: -72.3288,
    postal_code: '01069'
  },
  '01071': {
    country: 'US',
    city: 'Russell',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1471,
    longitude: -72.8403,
    postal_code: '01071'
  },
  '01077': {
    country: 'US',
    city: 'Southwick',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0511,
    longitude: -72.7706,
    postal_code: '01077'
  },
  '01079': {
    country: 'US',
    city: 'Thorndike',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1929,
    longitude: -72.3296,
    postal_code: '01079'
  },
  '01080': {
    country: 'US',
    city: 'Three Rivers',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1819,
    longitude: -72.3624,
    postal_code: '01080'
  },
  '01081': {
    country: 'US',
    city: 'Wales',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0627,
    longitude: -72.2046,
    postal_code: '01081'
  },
  '01085': {
    country: 'US',
    city: 'Westfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1251,
    longitude: -72.7495,
    postal_code: '01085'
  },
  '01086': {
    country: 'US',
    city: 'Westfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1734,
    longitude: -72.848,
    postal_code: '01086'
  },
  '01089': {
    country: 'US',
    city: 'West Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1151,
    longitude: -72.6411,
    postal_code: '01089'
  },
  '01090': {
    country: 'US',
    city: 'West Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1707,
    longitude: -72.6048,
    postal_code: '01090'
  },
  '01095': {
    country: 'US',
    city: 'Wilbraham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1245,
    longitude: -72.4464,
    postal_code: '01095'
  },
  '01097': {
    country: 'US',
    city: 'Woronoco',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.164,
    longitude: -72.8295,
    postal_code: '01097'
  },
  '01101': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1015,
    longitude: -72.5898,
    postal_code: '01101'
  },
  '01102': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1707,
    longitude: -72.6048,
    postal_code: '01102'
  },
  '01103': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1029,
    longitude: -72.5887,
    postal_code: '01103'
  },
  '01104': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1288,
    longitude: -72.5778,
    postal_code: '01104'
  },
  '01105': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0999,
    longitude: -72.5783,
    postal_code: '01105'
  },
  '01106': {
    country: 'US',
    city: 'Longmeadow',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0507,
    longitude: -72.5676,
    postal_code: '01106'
  },
  '01107': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1179,
    longitude: -72.6065,
    postal_code: '01107'
  },
  '01108': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0853,
    longitude: -72.5584,
    postal_code: '01108'
  },
  '01109': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1145,
    longitude: -72.5543,
    postal_code: '01109'
  },
  '01111': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1707,
    longitude: -72.6048,
    postal_code: '01111'
  },
  '01115': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1029,
    longitude: -72.5916,
    postal_code: '01115'
  },
  '01116': {
    country: 'US',
    city: 'Longmeadow',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1707,
    longitude: -72.6048,
    postal_code: '01116'
  },
  '01118': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0929,
    longitude: -72.5274,
    postal_code: '01118'
  },
  '01119': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1247,
    longitude: -72.5121,
    postal_code: '01119'
  },
  '01128': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0944,
    longitude: -72.4889,
    postal_code: '01128'
  },
  '01129': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1223,
    longitude: -72.4876,
    postal_code: '01129'
  },
  '01138': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1707,
    longitude: -72.6048,
    postal_code: '01138'
  },
  '01139': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1707,
    longitude: -72.6048,
    postal_code: '01139'
  },
  '01144': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1018,
    longitude: -72.5915,
    postal_code: '01144'
  },
  '01151': {
    country: 'US',
    city: 'Indian Orchard',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1532,
    longitude: -72.505,
    postal_code: '01151'
  },
  '01152': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1707,
    longitude: -72.6048,
    postal_code: '01152'
  },
  '01199': {
    country: 'US',
    city: 'Springfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.1199,
    longitude: -72.605,
    postal_code: '01199'
  },
  '01521': {
    country: 'US',
    city: 'Holland',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampden',
    latitude: 42.0403,
    longitude: -72.1544,
    postal_code: '01521'
  },
  '01002': {
    country: 'US',
    city: 'Amherst',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3671,
    longitude: -72.4646,
    postal_code: '01002'
  },
  '01003': {
    country: 'US',
    city: 'Amherst',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3919,
    longitude: -72.5248,
    postal_code: '01003'
  },
  '01004': {
    country: 'US',
    city: 'Amherst',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3845,
    longitude: -72.5132,
    postal_code: '01004'
  },
  '01007': {
    country: 'US',
    city: 'Belchertown',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.2751,
    longitude: -72.411,
    postal_code: '01007'
  },
  '01012': {
    country: 'US',
    city: 'Chesterfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3923,
    longitude: -72.8256,
    postal_code: '01012'
  },
  '01026': {
    country: 'US',
    city: 'Cummington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.4633,
    longitude: -72.9202,
    postal_code: '01026'
  },
  '01027': {
    country: 'US',
    city: 'Easthampton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.2668,
    longitude: -72.669,
    postal_code: '01027'
  },
  '01032': {
    country: 'US',
    city: 'Goshen',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.4404,
    longitude: -72.7995,
    postal_code: '01032'
  },
  '01033': {
    country: 'US',
    city: 'Granby',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.2557,
    longitude: -72.52,
    postal_code: '01033'
  },
  '01035': {
    country: 'US',
    city: 'Hadley',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3606,
    longitude: -72.5715,
    postal_code: '01035'
  },
  '01038': {
    country: 'US',
    city: 'Hatfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3844,
    longitude: -72.6167,
    postal_code: '01038'
  },
  '01039': {
    country: 'US',
    city: 'Haydenville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3818,
    longitude: -72.7032,
    postal_code: '01039'
  },
  '01050': {
    country: 'US',
    city: 'Huntington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.2653,
    longitude: -72.8733,
    postal_code: '01050'
  },
  '01053': {
    country: 'US',
    city: 'Leeds',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3543,
    longitude: -72.7034,
    postal_code: '01053'
  },
  '01059': {
    country: 'US',
    city: 'North Amherst',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.4104,
    longitude: -72.5309,
    postal_code: '01059'
  },
  '01060': {
    country: 'US',
    city: 'Northampton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3223,
    longitude: -72.6313,
    postal_code: '01060'
  },
  '01061': {
    country: 'US',
    city: 'Northampton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3696,
    longitude: -72.636,
    postal_code: '01061'
  },
  '01062': {
    country: 'US',
    city: 'Florence',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3219,
    longitude: -72.6928,
    postal_code: '01062'
  },
  '01063': {
    country: 'US',
    city: 'Northampton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3179,
    longitude: -72.6402,
    postal_code: '01063'
  },
  '01066': {
    country: 'US',
    city: 'North Hatfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.4112,
    longitude: -72.6223,
    postal_code: '01066'
  },
  '01070': {
    country: 'US',
    city: 'Plainfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.5144,
    longitude: -72.9183,
    postal_code: '01070'
  },
  '01073': {
    country: 'US',
    city: 'Southampton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.2247,
    longitude: -72.7194,
    postal_code: '01073'
  },
  '01075': {
    country: 'US',
    city: 'South Hadley',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.2375,
    longitude: -72.5811,
    postal_code: '01075'
  },
  '01082': {
    country: 'US',
    city: 'Ware',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.2618,
    longitude: -72.2583,
    postal_code: '01082'
  },
  '01084': {
    country: 'US',
    city: 'West Chesterfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3903,
    longitude: -72.8709,
    postal_code: '01084'
  },
  '01088': {
    country: 'US',
    city: 'West Hatfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3906,
    longitude: -72.6469,
    postal_code: '01088'
  },
  '01096': {
    country: 'US',
    city: 'Williamsburg',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.4085,
    longitude: -72.778,
    postal_code: '01096'
  },
  '01098': {
    country: 'US',
    city: 'Worthington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3843,
    longitude: -72.9314,
    postal_code: '01098'
  },
  '01243': {
    country: 'US',
    city: 'Middlefield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Hampshire',
    latitude: 42.3561,
    longitude: -73.0104,
    postal_code: '01243'
  },
  '01431': {
    country: 'US',
    city: 'Ashby',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6745,
    longitude: -71.8174,
    postal_code: '01431'
  },
  '01432': {
    country: 'US',
    city: 'Ayer',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5591,
    longitude: -71.5788,
    postal_code: '01432'
  },
  '01450': {
    country: 'US',
    city: 'Groton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6124,
    longitude: -71.5584,
    postal_code: '01450'
  },
  '01460': {
    country: 'US',
    city: 'Littleton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5401,
    longitude: -71.4877,
    postal_code: '01460'
  },
  '01463': {
    country: 'US',
    city: 'Pepperell',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6689,
    longitude: -71.5934,
    postal_code: '01463'
  },
  '01464': {
    country: 'US',
    city: 'Shirley',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5734,
    longitude: -71.6483,
    postal_code: '01464'
  },
  '01469': {
    country: 'US',
    city: 'Townsend',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6525,
    longitude: -71.6896,
    postal_code: '01469'
  },
  '01470': {
    country: 'US',
    city: 'Groton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6112,
    longitude: -71.5745,
    postal_code: '01470'
  },
  '01471': {
    country: 'US',
    city: 'Groton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6112,
    longitude: -71.5745,
    postal_code: '01471'
  },
  '01472': {
    country: 'US',
    city: 'West Groton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6043,
    longitude: -71.6273,
    postal_code: '01472'
  },
  '01474': {
    country: 'US',
    city: 'West Townsend',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6678,
    longitude: -71.7522,
    postal_code: '01474'
  },
  '01701': {
    country: 'US',
    city: 'Framingham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3007,
    longitude: -71.4255,
    postal_code: '01701'
  },
  '01702': {
    country: 'US',
    city: 'Framingham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.2822,
    longitude: -71.4339,
    postal_code: '01702'
  },
  '01703': {
    country: 'US',
    city: 'Framingham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4464,
    longitude: -71.4594,
    postal_code: '01703'
  },
  '01704': {
    country: 'US',
    city: 'Framingham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4464,
    longitude: -71.4594,
    postal_code: '01704'
  },
  '01705': {
    country: 'US',
    city: 'Framingham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4464,
    longitude: -71.4594,
    postal_code: '01705'
  },
  '01718': {
    country: 'US',
    city: 'Acton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4851,
    longitude: -71.4328,
    postal_code: '01718'
  },
  '01719': {
    country: 'US',
    city: 'Boxborough',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4884,
    longitude: -71.5178,
    postal_code: '01719'
  },
  '01720': {
    country: 'US',
    city: 'Acton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4751,
    longitude: -71.4483,
    postal_code: '01720'
  },
  '01721': {
    country: 'US',
    city: 'Ashland',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.2539,
    longitude: -71.4583,
    postal_code: '01721'
  },
  '01730': {
    country: 'US',
    city: 'Bedford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4843,
    longitude: -71.2768,
    postal_code: '01730'
  },
  '01731': {
    country: 'US',
    city: 'Hanscom Afb',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4567,
    longitude: -71.2795,
    postal_code: '01731'
  },
  '01741': {
    country: 'US',
    city: 'Carlisle',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5286,
    longitude: -71.3519,
    postal_code: '01741'
  },
  '01742': {
    country: 'US',
    city: 'Concord',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4567,
    longitude: -71.3747,
    postal_code: '01742'
  },
  '01746': {
    country: 'US',
    city: 'Holliston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.2026,
    longitude: -71.4361,
    postal_code: '01746'
  },
  '01748': {
    country: 'US',
    city: 'Hopkinton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.219,
    longitude: -71.5302,
    postal_code: '01748'
  },
  '01749': {
    country: 'US',
    city: 'Hudson',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3918,
    longitude: -71.5609,
    postal_code: '01749'
  },
  '01752': {
    country: 'US',
    city: 'Marlborough',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3509,
    longitude: -71.5434,
    postal_code: '01752'
  },
  '01754': {
    country: 'US',
    city: 'Maynard',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4321,
    longitude: -71.455,
    postal_code: '01754'
  },
  '01760': {
    country: 'US',
    city: 'Natick',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.2875,
    longitude: -71.3574,
    postal_code: '01760'
  },
  '01770': {
    country: 'US',
    city: 'Sherborn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.2331,
    longitude: -71.3787,
    postal_code: '01770'
  },
  '01773': {
    country: 'US',
    city: 'Lincoln',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4217,
    longitude: -71.3137,
    postal_code: '01773'
  },
  '01775': {
    country: 'US',
    city: 'Stow',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4308,
    longitude: -71.515,
    postal_code: '01775'
  },
  '01776': {
    country: 'US',
    city: 'Sudbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3837,
    longitude: -71.4282,
    postal_code: '01776'
  },
  '01778': {
    country: 'US',
    city: 'Wayland',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3486,
    longitude: -71.3588,
    postal_code: '01778'
  },
  '01784': {
    country: 'US',
    city: 'Woodville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.2376,
    longitude: -71.562,
    postal_code: '01784'
  },
  '01801': {
    country: 'US',
    city: 'Woburn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4829,
    longitude: -71.1574,
    postal_code: '01801'
  },
  '01803': {
    country: 'US',
    city: 'Burlington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5089,
    longitude: -71.2004,
    postal_code: '01803'
  },
  '01805': {
    country: 'US',
    city: 'Burlington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5048,
    longitude: -71.1956,
    postal_code: '01805'
  },
  '01813': {
    country: 'US',
    city: 'Woburn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4793,
    longitude: -71.1523,
    postal_code: '01813'
  },
  '01815': {
    country: 'US',
    city: 'Woburn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4793,
    longitude: -71.1523,
    postal_code: '01815'
  },
  '01821': {
    country: 'US',
    city: 'Billerica',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5519,
    longitude: -71.2518,
    postal_code: '01821'
  },
  '01822': {
    country: 'US',
    city: 'Billerica',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5584,
    longitude: -71.2689,
    postal_code: '01822'
  },
  '01824': {
    country: 'US',
    city: 'Chelmsford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5911,
    longitude: -71.3556,
    postal_code: '01824'
  },
  '01826': {
    country: 'US',
    city: 'Dracut',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6764,
    longitude: -71.3186,
    postal_code: '01826'
  },
  '01827': {
    country: 'US',
    city: 'Dunstable',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6739,
    longitude: -71.4952,
    postal_code: '01827'
  },
  '01850': {
    country: 'US',
    city: 'Lowell',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.656,
    longitude: -71.3051,
    postal_code: '01850'
  },
  '01851': {
    country: 'US',
    city: 'Lowell',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6315,
    longitude: -71.3329,
    postal_code: '01851'
  },
  '01852': {
    country: 'US',
    city: 'Lowell',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6344,
    longitude: -71.2983,
    postal_code: '01852'
  },
  '01853': {
    country: 'US',
    city: 'Lowell',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6334,
    longitude: -71.3162,
    postal_code: '01853'
  },
  '01854': {
    country: 'US',
    city: 'Lowell',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6493,
    longitude: -71.3355,
    postal_code: '01854'
  },
  '01862': {
    country: 'US',
    city: 'North Billerica',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5757,
    longitude: -71.2902,
    postal_code: '01862'
  },
  '01863': {
    country: 'US',
    city: 'North Chelmsford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6347,
    longitude: -71.3908,
    postal_code: '01863'
  },
  '01864': {
    country: 'US',
    city: 'North Reading',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5819,
    longitude: -71.0947,
    postal_code: '01864'
  },
  '01865': {
    country: 'US',
    city: 'Nutting Lake',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5379,
    longitude: -71.2689,
    postal_code: '01865'
  },
  '01866': {
    country: 'US',
    city: 'Pinehurst',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5293,
    longitude: -71.2281,
    postal_code: '01866'
  },
  '01867': {
    country: 'US',
    city: 'Reading',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.528,
    longitude: -71.109,
    postal_code: '01867'
  },
  '01876': {
    country: 'US',
    city: 'Tewksbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6028,
    longitude: -71.2232,
    postal_code: '01876'
  },
  '01879': {
    country: 'US',
    city: 'Tyngsboro',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.6724,
    longitude: -71.4158,
    postal_code: '01879'
  },
  '01880': {
    country: 'US',
    city: 'Wakefield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5009,
    longitude: -71.0685,
    postal_code: '01880'
  },
  '01886': {
    country: 'US',
    city: 'Westford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5864,
    longitude: -71.4401,
    postal_code: '01886'
  },
  '01887': {
    country: 'US',
    city: 'Wilmington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5581,
    longitude: -71.1723,
    postal_code: '01887'
  },
  '01888': {
    country: 'US',
    city: 'Woburn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4793,
    longitude: -71.1523,
    postal_code: '01888'
  },
  '01889': {
    country: 'US',
    city: 'North Reading',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.5716,
    longitude: -71.1096,
    postal_code: '01889'
  },
  '01890': {
    country: 'US',
    city: 'Winchester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.453,
    longitude: -71.1441,
    postal_code: '01890'
  },
  '02138': {
    country: 'US',
    city: 'Cambridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.377,
    longitude: -71.1256,
    postal_code: '02138'
  },
  '02139': {
    country: 'US',
    city: 'Cambridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3647,
    longitude: -71.1042,
    postal_code: '02139'
  },
  '02140': {
    country: 'US',
    city: 'Cambridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3932,
    longitude: -71.1338,
    postal_code: '02140'
  },
  '02141': {
    country: 'US',
    city: 'Cambridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3687,
    longitude: -71.0836,
    postal_code: '02141'
  },
  '02142': {
    country: 'US',
    city: 'Cambridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.362,
    longitude: -71.083,
    postal_code: '02142'
  },
  '02143': {
    country: 'US',
    city: 'Somerville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3829,
    longitude: -71.1028,
    postal_code: '02143'
  },
  '02144': {
    country: 'US',
    city: 'Somerville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4003,
    longitude: -71.1221,
    postal_code: '02144'
  },
  '02145': {
    country: 'US',
    city: 'Somerville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3907,
    longitude: -71.0929,
    postal_code: '02145'
  },
  '02148': {
    country: 'US',
    city: 'Malden',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4291,
    longitude: -71.0605,
    postal_code: '02148'
  },
  '02149': {
    country: 'US',
    city: 'Everett',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4112,
    longitude: -71.0514,
    postal_code: '02149'
  },
  '02153': {
    country: 'US',
    city: 'Medford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4184,
    longitude: -71.1062,
    postal_code: '02153'
  },
  '02155': {
    country: 'US',
    city: 'Medford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4173,
    longitude: -71.1087,
    postal_code: '02155'
  },
  '02156': {
    country: 'US',
    city: 'West Medford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4223,
    longitude: -71.1328,
    postal_code: '02156'
  },
  '02176': {
    country: 'US',
    city: 'Melrose',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4581,
    longitude: -71.0632,
    postal_code: '02176'
  },
  '02180': {
    country: 'US',
    city: 'Stoneham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4828,
    longitude: -71.0978,
    postal_code: '02180'
  },
  '02212': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3584,
    longitude: -71.0598,
    postal_code: '02212'
  },
  '02238': {
    country: 'US',
    city: 'Cambridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3751,
    longitude: -71.1056,
    postal_code: '02238'
  },
  '02420': {
    country: 'US',
    city: 'Lexington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4563,
    longitude: -71.2167,
    postal_code: '02420'
  },
  '02421': {
    country: 'US',
    city: 'Lexington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4426,
    longitude: -71.2265,
    postal_code: '02421'
  },
  '02451': {
    country: 'US',
    city: 'Waltham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3986,
    longitude: -71.2451,
    postal_code: '02451'
  },
  '02452': {
    country: 'US',
    city: 'Waltham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3943,
    longitude: -71.218,
    postal_code: '02452'
  },
  '02453': {
    country: 'US',
    city: 'Waltham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3654,
    longitude: -71.2317,
    postal_code: '02453'
  },
  '02454': {
    country: 'US',
    city: 'Waltham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3567,
    longitude: -71.2505,
    postal_code: '02454'
  },
  '02455': {
    country: 'US',
    city: 'North Waltham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3621,
    longitude: -71.2055,
    postal_code: '02455'
  },
  '02456': {
    country: 'US',
    city: 'New Town',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4464,
    longitude: -71.4594,
    postal_code: '02456'
  },
  '02458': {
    country: 'US',
    city: 'Newton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3528,
    longitude: -71.1875,
    postal_code: '02458'
  },
  '02459': {
    country: 'US',
    city: 'Newton Center',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3341,
    longitude: -71.1833,
    postal_code: '02459'
  },
  '02460': {
    country: 'US',
    city: 'Newtonville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.352,
    longitude: -71.2084,
    postal_code: '02460'
  },
  '02461': {
    country: 'US',
    city: 'Newton Highlands',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3168,
    longitude: -71.2084,
    postal_code: '02461'
  },
  '02462': {
    country: 'US',
    city: 'Newton Lower Falls',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3299,
    longitude: -71.2562,
    postal_code: '02462'
  },
  '02464': {
    country: 'US',
    city: 'Newton Upper Falls',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3129,
    longitude: -71.2195,
    postal_code: '02464'
  },
  '02465': {
    country: 'US',
    city: 'West Newton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3492,
    longitude: -71.2267,
    postal_code: '02465'
  },
  '02466': {
    country: 'US',
    city: 'Auburndale',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3441,
    longitude: -71.248,
    postal_code: '02466'
  },
  '02467': {
    country: 'US',
    city: 'Chestnut Hill',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3164,
    longitude: -71.1612,
    postal_code: '02467'
  },
  '02468': {
    country: 'US',
    city: 'Waban',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3271,
    longitude: -71.2315,
    postal_code: '02468'
  },
  '02471': {
    country: 'US',
    city: 'Watertown',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3709,
    longitude: -71.1828,
    postal_code: '02471'
  },
  '02472': {
    country: 'US',
    city: 'Watertown',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.37,
    longitude: -71.1773,
    postal_code: '02472'
  },
  '02474': {
    country: 'US',
    city: 'Arlington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4202,
    longitude: -71.1565,
    postal_code: '02474'
  },
  '02475': {
    country: 'US',
    city: 'Arlington Heights',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4204,
    longitude: -71.1801,
    postal_code: '02475'
  },
  '02476': {
    country: 'US',
    city: 'Arlington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.4162,
    longitude: -71.1752,
    postal_code: '02476'
  },
  '02477': {
    country: 'US',
    city: 'Watertown',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3709,
    longitude: -71.1828,
    postal_code: '02477'
  },
  '02478': {
    country: 'US',
    city: 'Belmont',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3959,
    longitude: -71.1787,
    postal_code: '02478'
  },
  '02479': {
    country: 'US',
    city: 'Waverley',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3876,
    longitude: -71.1828,
    postal_code: '02479'
  },
  '02493': {
    country: 'US',
    city: 'Weston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3589,
    longitude: -71.3001,
    postal_code: '02493'
  },
  '02495': {
    country: 'US',
    city: 'Nonantum',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Middlesex',
    latitude: 42.3626,
    longitude: -71.2023,
    postal_code: '02495'
  },
  '02554': {
    country: 'US',
    city: 'Nantucket',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Nantucket',
    latitude: 41.2725,
    longitude: -70.0932,
    postal_code: '02554'
  },
  '02564': {
    country: 'US',
    city: 'Siasconset',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Nantucket',
    latitude: 41.2639,
    longitude: -69.9626,
    postal_code: '02564'
  },
  '02584': {
    country: 'US',
    city: 'Nantucket',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Nantucket',
    latitude: 41.2778,
    longitude: -70.046,
    postal_code: '02584'
  },
  '02019': {
    country: 'US',
    city: 'Bellingham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.0746,
    longitude: -71.4768,
    postal_code: '02019'
  },
  '02021': {
    country: 'US',
    city: 'Canton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1645,
    longitude: -71.1355,
    postal_code: '02021'
  },
  '02025': {
    country: 'US',
    city: 'Cohasset',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2395,
    longitude: -70.8128,
    postal_code: '02025'
  },
  '02026': {
    country: 'US',
    city: 'Dedham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2437,
    longitude: -71.1637,
    postal_code: '02026'
  },
  '02027': {
    country: 'US',
    city: 'Dedham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.18,
    longitude: -71.0892,
    postal_code: '02027'
  },
  '02030': {
    country: 'US',
    city: 'Dover',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2362,
    longitude: -71.2854,
    postal_code: '02030'
  },
  '02032': {
    country: 'US',
    city: 'East Walpole',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1532,
    longitude: -71.2179,
    postal_code: '02032'
  },
  '02035': {
    country: 'US',
    city: 'Foxboro',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.0649,
    longitude: -71.2441,
    postal_code: '02035'
  },
  '02038': {
    country: 'US',
    city: 'Franklin',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.0935,
    longitude: -71.4058,
    postal_code: '02038'
  },
  '02052': {
    country: 'US',
    city: 'Medfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1845,
    longitude: -71.3048,
    postal_code: '02052'
  },
  '02053': {
    country: 'US',
    city: 'Medway',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1514,
    longitude: -71.4217,
    postal_code: '02053'
  },
  '02054': {
    country: 'US',
    city: 'Millis',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1669,
    longitude: -71.3607,
    postal_code: '02054'
  },
  '02056': {
    country: 'US',
    city: 'Norfolk',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1177,
    longitude: -71.3269,
    postal_code: '02056'
  },
  '02062': {
    country: 'US',
    city: 'Norwood',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1868,
    longitude: -71.2033,
    postal_code: '02062'
  },
  '02067': {
    country: 'US',
    city: 'Sharon',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1094,
    longitude: -71.1759,
    postal_code: '02067'
  },
  '02070': {
    country: 'US',
    city: 'Sheldonville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.0348,
    longitude: -71.387,
    postal_code: '02070'
  },
  '02071': {
    country: 'US',
    city: 'South Walpole',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.0992,
    longitude: -71.2752,
    postal_code: '02071'
  },
  '02072': {
    country: 'US',
    city: 'Stoughton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1253,
    longitude: -71.1074,
    postal_code: '02072'
  },
  '02081': {
    country: 'US',
    city: 'Walpole',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1444,
    longitude: -71.2544,
    postal_code: '02081'
  },
  '02090': {
    country: 'US',
    city: 'Westwood',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2148,
    longitude: -71.2104,
    postal_code: '02090'
  },
  '02093': {
    country: 'US',
    city: 'Wrentham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.0617,
    longitude: -71.3396,
    postal_code: '02093'
  },
  '02169': {
    country: 'US',
    city: 'Quincy',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2491,
    longitude: -70.9978,
    postal_code: '02169'
  },
  '02170': {
    country: 'US',
    city: 'Quincy',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2671,
    longitude: -71.0186,
    postal_code: '02170'
  },
  '02171': {
    country: 'US',
    city: 'Quincy',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2825,
    longitude: -71.0241,
    postal_code: '02171'
  },
  '02184': {
    country: 'US',
    city: 'Braintree',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2093,
    longitude: -70.9963,
    postal_code: '02184'
  },
  '02185': {
    country: 'US',
    city: 'Braintree',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.18,
    longitude: -71.0892,
    postal_code: '02185'
  },
  '02186': {
    country: 'US',
    city: 'Milton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2537,
    longitude: -71.0771,
    postal_code: '02186'
  },
  '02187': {
    country: 'US',
    city: 'Milton Village',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2668,
    longitude: -71.0717,
    postal_code: '02187'
  },
  '02188': {
    country: 'US',
    city: 'Weymouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2113,
    longitude: -70.9582,
    postal_code: '02188'
  },
  '02189': {
    country: 'US',
    city: 'East Weymouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.214,
    longitude: -70.9203,
    postal_code: '02189'
  },
  '02190': {
    country: 'US',
    city: 'South Weymouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1751,
    longitude: -70.9495,
    postal_code: '02190'
  },
  '02191': {
    country: 'US',
    city: 'North Weymouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2459,
    longitude: -70.9467,
    postal_code: '02191'
  },
  '02269': {
    country: 'US',
    city: 'Quincy',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2529,
    longitude: -71.0023,
    postal_code: '02269'
  },
  '02322': {
    country: 'US',
    city: 'Avon',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1258,
    longitude: -71.0437,
    postal_code: '02322'
  },
  '02343': {
    country: 'US',
    city: 'Holbrook',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1464,
    longitude: -71.0083,
    postal_code: '02343'
  },
  '02368': {
    country: 'US',
    city: 'Randolph',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.1736,
    longitude: -71.0514,
    postal_code: '02368'
  },
  '02445': {
    country: 'US',
    city: 'Brookline',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.3259,
    longitude: -71.1341,
    postal_code: '02445'
  },
  '02446': {
    country: 'US',
    city: 'Brookline',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.3431,
    longitude: -71.123,
    postal_code: '02446'
  },
  '02447': {
    country: 'US',
    city: 'Brookline Village',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.3329,
    longitude: -71.1162,
    postal_code: '02447'
  },
  '02457': {
    country: 'US',
    city: 'Babson Park',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2987,
    longitude: -71.2595,
    postal_code: '02457'
  },
  '02481': {
    country: 'US',
    city: 'Wellesley Hills',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.3106,
    longitude: -71.2747,
    postal_code: '02481'
  },
  '02482': {
    country: 'US',
    city: 'Wellesley',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2945,
    longitude: -71.2992,
    postal_code: '02482'
  },
  '02492': {
    country: 'US',
    city: 'Needham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2798,
    longitude: -71.2501,
    postal_code: '02492'
  },
  '02494': {
    country: 'US',
    city: 'Needham Heights',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.2945,
    longitude: -71.2328,
    postal_code: '02494'
  },
  '02762': {
    country: 'US',
    city: 'Plainville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Norfolk',
    latitude: 42.0124,
    longitude: -71.3275,
    postal_code: '02762'
  },
  '02018': {
    country: 'US',
    city: 'Accord',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1745,
    longitude: -70.8837,
    postal_code: '02018'
  },
  '02020': {
    country: 'US',
    city: 'Brant Rock',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0818,
    longitude: -70.6439,
    postal_code: '02020'
  },
  '02040': {
    country: 'US',
    city: 'Greenbush',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1793,
    longitude: -70.7495,
    postal_code: '02040'
  },
  '02041': {
    country: 'US',
    city: 'Green Harbor',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0696,
    longitude: -70.6491,
    postal_code: '02041'
  },
  '02043': {
    country: 'US',
    city: 'Hingham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.2245,
    longitude: -70.8911,
    postal_code: '02043'
  },
  '02044': {
    country: 'US',
    city: 'Hingham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.2418,
    longitude: -70.8898,
    postal_code: '02044'
  },
  '02045': {
    country: 'US',
    city: 'Hull',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.2853,
    longitude: -70.8754,
    postal_code: '02045'
  },
  '02047': {
    country: 'US',
    city: 'Humarock',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1428,
    longitude: -70.6935,
    postal_code: '02047'
  },
  '02050': {
    country: 'US',
    city: 'Marshfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1062,
    longitude: -70.6993,
    postal_code: '02050'
  },
  '02051': {
    country: 'US',
    city: 'Marshfield Hills',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1512,
    longitude: -70.7341,
    postal_code: '02051'
  },
  '02055': {
    country: 'US',
    city: 'Minot',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.2404,
    longitude: -70.762,
    postal_code: '02055'
  },
  '02059': {
    country: 'US',
    city: 'North Marshfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1432,
    longitude: -70.7703,
    postal_code: '02059'
  },
  '02060': {
    country: 'US',
    city: 'North Scituate',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.219,
    longitude: -70.7856,
    postal_code: '02060'
  },
  '02061': {
    country: 'US',
    city: 'Norwell',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1596,
    longitude: -70.8217,
    postal_code: '02061'
  },
  '02065': {
    country: 'US',
    city: 'Ocean Bluff',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0972,
    longitude: -70.6516,
    postal_code: '02065'
  },
  '02066': {
    country: 'US',
    city: 'Scituate',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.2032,
    longitude: -70.7525,
    postal_code: '02066'
  },
  '02301': {
    country: 'US',
    city: 'Brockton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0794,
    longitude: -71.04,
    postal_code: '02301'
  },
  '02302': {
    country: 'US',
    city: 'Brockton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0847,
    longitude: -71.0002,
    postal_code: '02302'
  },
  '02303': {
    country: 'US',
    city: 'Brockton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0834,
    longitude: -71.0184,
    postal_code: '02303'
  },
  '02304': {
    country: 'US',
    city: 'Brockton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0834,
    longitude: -71.0184,
    postal_code: '02304'
  },
  '02305': {
    country: 'US',
    city: 'Brockton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0834,
    longitude: -71.0184,
    postal_code: '02305'
  },
  '02324': {
    country: 'US',
    city: 'Bridgewater',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.9773,
    longitude: -70.9723,
    postal_code: '02324'
  },
  '02325': {
    country: 'US',
    city: 'Bridgewater',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.9873,
    longitude: -70.9728,
    postal_code: '02325'
  },
  '02327': {
    country: 'US',
    city: 'Bryantville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0407,
    longitude: -70.8272,
    postal_code: '02327'
  },
  '02330': {
    country: 'US',
    city: 'Carver',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.8883,
    longitude: -70.7678,
    postal_code: '02330'
  },
  '02331': {
    country: 'US',
    city: 'Duxbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.9705,
    longitude: -70.7014,
    postal_code: '02331'
  },
  '02332': {
    country: 'US',
    city: 'Duxbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0399,
    longitude: -70.7163,
    postal_code: '02332'
  },
  '02333': {
    country: 'US',
    city: 'East Bridgewater',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0315,
    longitude: -70.945,
    postal_code: '02333'
  },
  '02337': {
    country: 'US',
    city: 'Elmwood',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0098,
    longitude: -70.962,
    postal_code: '02337'
  },
  '02338': {
    country: 'US',
    city: 'Halifax',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0002,
    longitude: -70.8448,
    postal_code: '02338'
  },
  '02339': {
    country: 'US',
    city: 'Hanover',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1214,
    longitude: -70.857,
    postal_code: '02339'
  },
  '02340': {
    country: 'US',
    city: 'Hanover',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1137,
    longitude: -70.8161,
    postal_code: '02340'
  },
  '02341': {
    country: 'US',
    city: 'Hanson',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0616,
    longitude: -70.8651,
    postal_code: '02341'
  },
  '02344': {
    country: 'US',
    city: 'Middleboro',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.8932,
    longitude: -70.9112,
    postal_code: '02344'
  },
  '02345': {
    country: 'US',
    city: 'Manomet',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.8882,
    longitude: -70.581,
    postal_code: '02345'
  },
  '02346': {
    country: 'US',
    city: 'Middleboro',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.8884,
    longitude: -70.893,
    postal_code: '02346'
  },
  '02347': {
    country: 'US',
    city: 'Lakeville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.8374,
    longitude: -70.9582,
    postal_code: '02347'
  },
  '02348': {
    country: 'US',
    city: 'Lakeville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.8459,
    longitude: -70.9495,
    postal_code: '02348'
  },
  '02349': {
    country: 'US',
    city: 'Middleboro',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.8932,
    longitude: -70.9112,
    postal_code: '02349'
  },
  '02350': {
    country: 'US',
    city: 'Monponsett',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0185,
    longitude: -70.8475,
    postal_code: '02350'
  },
  '02351': {
    country: 'US',
    city: 'Abington',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1167,
    longitude: -70.9543,
    postal_code: '02351'
  },
  '02355': {
    country: 'US',
    city: 'North Carver',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.9169,
    longitude: -70.8013,
    postal_code: '02355'
  },
  '02358': {
    country: 'US',
    city: 'North Pembroke',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0932,
    longitude: -70.7925,
    postal_code: '02358'
  },
  '02359': {
    country: 'US',
    city: 'Pembroke',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0621,
    longitude: -70.8044,
    postal_code: '02359'
  },
  '02360': {
    country: 'US',
    city: 'Plymouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.9104,
    longitude: -70.642,
    postal_code: '02360'
  },
  '02361': {
    country: 'US',
    city: 'Plymouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.9705,
    longitude: -70.7014,
    postal_code: '02361'
  },
  '02362': {
    country: 'US',
    city: 'Plymouth',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.9584,
    longitude: -70.6673,
    postal_code: '02362'
  },
  '02364': {
    country: 'US',
    city: 'Kingston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.995,
    longitude: -70.741,
    postal_code: '02364'
  },
  '02366': {
    country: 'US',
    city: 'South Carver',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.8501,
    longitude: -70.7044,
    postal_code: '02366'
  },
  '02367': {
    country: 'US',
    city: 'Plympton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.9655,
    longitude: -70.8046,
    postal_code: '02367'
  },
  '02370': {
    country: 'US',
    city: 'Rockland',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.1293,
    longitude: -70.9133,
    postal_code: '02370'
  },
  '02379': {
    country: 'US',
    city: 'West Bridgewater',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0255,
    longitude: -71.0161,
    postal_code: '02379'
  },
  '02381': {
    country: 'US',
    city: 'White Horse Beach',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.9316,
    longitude: -70.5611,
    postal_code: '02381'
  },
  '02382': {
    country: 'US',
    city: 'Whitman',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 42.0816,
    longitude: -70.9381,
    postal_code: '02382'
  },
  '02538': {
    country: 'US',
    city: 'East Wareham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.7682,
    longitude: -70.6532,
    postal_code: '02538'
  },
  '02558': {
    country: 'US',
    city: 'Onset',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.7476,
    longitude: -70.6582,
    postal_code: '02558'
  },
  '02571': {
    country: 'US',
    city: 'Wareham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.7541,
    longitude: -70.7116,
    postal_code: '02571'
  },
  '02576': {
    country: 'US',
    city: 'West Wareham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.7796,
    longitude: -70.7642,
    postal_code: '02576'
  },
  '02738': {
    country: 'US',
    city: 'Marion',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.7095,
    longitude: -70.7613,
    postal_code: '02738'
  },
  '02739': {
    country: 'US',
    city: 'Mattapoisett',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.6618,
    longitude: -70.8164,
    postal_code: '02739'
  },
  '02770': {
    country: 'US',
    city: 'Rochester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Plymouth',
    latitude: 41.7591,
    longitude: -70.8523,
    postal_code: '02770'
  },
  '02108': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3576,
    longitude: -71.0684,
    postal_code: '02108'
  },
  '02109': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.36,
    longitude: -71.0545,
    postal_code: '02109'
  },
  '02110': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3576,
    longitude: -71.0514,
    postal_code: '02110'
  },
  '02111': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3503,
    longitude: -71.0629,
    postal_code: '02111'
  },
  '02112': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3584,
    longitude: -71.0598,
    postal_code: '02112'
  },
  '02113': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3657,
    longitude: -71.056,
    postal_code: '02113'
  },
  '02114': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3611,
    longitude: -71.0682,
    postal_code: '02114'
  },
  '02115': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3427,
    longitude: -71.0922,
    postal_code: '02115'
  },
  '02116': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3492,
    longitude: -71.0768,
    postal_code: '02116'
  },
  '02117': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3585,
    longitude: -71.058,
    postal_code: '02117'
  },
  '02118': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3362,
    longitude: -71.0729,
    postal_code: '02118'
  },
  '02119': {
    country: 'US',
    city: 'Roxbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3251,
    longitude: -71.0953,
    postal_code: '02119'
  },
  '02120': {
    country: 'US',
    city: 'Roxbury Crossing',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3307,
    longitude: -71.0912,
    postal_code: '02120'
  },
  '02121': {
    country: 'US',
    city: 'Dorchester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.2973,
    longitude: -71.0745,
    postal_code: '02121'
  },
  '02122': {
    country: 'US',
    city: 'Dorchester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.2973,
    longitude: -71.0745,
    postal_code: '02122'
  },
  '02123': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3389,
    longitude: -70.9196,
    postal_code: '02123'
  },
  '02124': {
    country: 'US',
    city: 'Dorchester Center',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.2918,
    longitude: -71.0717,
    postal_code: '02124'
  },
  '02125': {
    country: 'US',
    city: 'Dorchester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.2973,
    longitude: -71.0745,
    postal_code: '02125'
  },
  '02126': {
    country: 'US',
    city: 'Mattapan',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.2739,
    longitude: -71.0939,
    postal_code: '02126'
  },
  '02127': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3347,
    longitude: -71.0375,
    postal_code: '02127'
  },
  '02128': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3642,
    longitude: -71.0257,
    postal_code: '02128'
  },
  '02129': {
    country: 'US',
    city: 'Charlestown',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3778,
    longitude: -71.0627,
    postal_code: '02129'
  },
  '02130': {
    country: 'US',
    city: 'Jamaica Plain',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3126,
    longitude: -71.1115,
    postal_code: '02130'
  },
  '02131': {
    country: 'US',
    city: 'Roslindale',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.2836,
    longitude: -71.1295,
    postal_code: '02131'
  },
  '02132': {
    country: 'US',
    city: 'West Roxbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.2787,
    longitude: -71.1589,
    postal_code: '02132'
  },
  '02133': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3584,
    longitude: -71.0598,
    postal_code: '02133'
  },
  '02134': {
    country: 'US',
    city: 'Allston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3535,
    longitude: -71.1329,
    postal_code: '02134'
  },
  '02135': {
    country: 'US',
    city: 'Brighton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3478,
    longitude: -71.1566,
    postal_code: '02135'
  },
  '02136': {
    country: 'US',
    city: 'Hyde Park',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.254,
    longitude: -71.1261,
    postal_code: '02136'
  },
  '02137': {
    country: 'US',
    city: 'Readville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.2404,
    longitude: -71.137,
    postal_code: '02137'
  },
  '02150': {
    country: 'US',
    city: 'Chelsea',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3963,
    longitude: -71.0325,
    postal_code: '02150'
  },
  '02151': {
    country: 'US',
    city: 'Revere',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.4138,
    longitude: -71.0052,
    postal_code: '02151'
  },
  '02152': {
    country: 'US',
    city: 'Winthrop',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3763,
    longitude: -70.98,
    postal_code: '02152'
  },
  '02163': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3253,
    longitude: -71.1122,
    postal_code: '02163'
  },
  '02196': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3584,
    longitude: -71.0598,
    postal_code: '02196'
  },
  '02199': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3479,
    longitude: -71.0825,
    postal_code: '02199'
  },
  '02201': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3584,
    longitude: -71.0598,
    postal_code: '02201'
  },
  '02203': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3615,
    longitude: -71.0604,
    postal_code: '02203'
  },
  '02204': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3389,
    longitude: -70.9196,
    postal_code: '02204'
  },
  '02205': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3503,
    longitude: -71.0539,
    postal_code: '02205'
  },
  '02206': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3389,
    longitude: -70.9196,
    postal_code: '02206'
  },
  '02210': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3489,
    longitude: -71.0465,
    postal_code: '02210'
  },
  '02211': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3584,
    longitude: -71.0598,
    postal_code: '02211'
  },
  '02215': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3471,
    longitude: -71.1027,
    postal_code: '02215'
  },
  '02217': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3389,
    longitude: -70.9196,
    postal_code: '02217'
  },
  '02222': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3644,
    longitude: -71.0633,
    postal_code: '02222'
  },
  '02241': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3584,
    longitude: -71.0598,
    postal_code: '02241'
  },
  '02266': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3584,
    longitude: -71.0598,
    postal_code: '02266'
  },
  '02283': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3389,
    longitude: -70.9196,
    postal_code: '02283'
  },
  '02284': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3389,
    longitude: -70.9196,
    postal_code: '02284'
  },
  '02293': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3584,
    longitude: -71.0598,
    postal_code: '02293'
  },
  '02297': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3584,
    longitude: -71.0598,
    postal_code: '02297'
  },
  '02298': {
    country: 'US',
    city: 'Boston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Suffolk',
    latitude: 42.3823,
    longitude: -71.0323,
    postal_code: '02298'
  },
  '01005': {
    country: 'US',
    city: 'Barre',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.4097,
    longitude: -72.1084,
    postal_code: '01005'
  },
  '01031': {
    country: 'US',
    city: 'Gilbertville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3322,
    longitude: -72.1986,
    postal_code: '01031'
  },
  '01037': {
    country: 'US',
    city: 'Hardwick',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3479,
    longitude: -72.2253,
    postal_code: '01037'
  },
  '01068': {
    country: 'US',
    city: 'Oakham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.348,
    longitude: -72.0513,
    postal_code: '01068'
  },
  '01074': {
    country: 'US',
    city: 'South Barre',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3854,
    longitude: -72.0954,
    postal_code: '01074'
  },
  '01083': {
    country: 'US',
    city: 'Warren',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.204,
    longitude: -72.1994,
    postal_code: '01083'
  },
  '01092': {
    country: 'US',
    city: 'West Warren',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2029,
    longitude: -72.229,
    postal_code: '01092'
  },
  '01094': {
    country: 'US',
    city: 'Wheelwright',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3582,
    longitude: -72.1408,
    postal_code: '01094'
  },
  '01331': {
    country: 'US',
    city: 'Athol',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.5959,
    longitude: -72.2267,
    postal_code: '01331'
  },
  '01366': {
    country: 'US',
    city: 'Petersham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.4898,
    longitude: -72.1893,
    postal_code: '01366'
  },
  '01368': {
    country: 'US',
    city: 'Royalston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.6722,
    longitude: -72.1964,
    postal_code: '01368'
  },
  '01420': {
    country: 'US',
    city: 'Fitchburg',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.5796,
    longitude: -71.8031,
    postal_code: '01420'
  },
  '01430': {
    country: 'US',
    city: 'Ashburnham',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.6496,
    longitude: -71.9267,
    postal_code: '01430'
  },
  '01434': {
    country: 'US',
    city: 'Devens',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.5385,
    longitude: -71.6115,
    postal_code: '01434'
  },
  '01436': {
    country: 'US',
    city: 'Baldwinville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.5936,
    longitude: -72.0646,
    postal_code: '01436'
  },
  '01438': {
    country: 'US',
    city: 'East Templeton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.5631,
    longitude: -72.037,
    postal_code: '01438'
  },
  '01440': {
    country: 'US',
    city: 'Gardner',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.574,
    longitude: -71.9898,
    postal_code: '01440'
  },
  '01441': {
    country: 'US',
    city: 'Westminster',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.5459,
    longitude: -71.9106,
    postal_code: '01441'
  },
  '01451': {
    country: 'US',
    city: 'Harvard',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.4986,
    longitude: -71.5753,
    postal_code: '01451'
  },
  '01452': {
    country: 'US',
    city: 'Hubbardston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.4865,
    longitude: -72.0012,
    postal_code: '01452'
  },
  '01453': {
    country: 'US',
    city: 'Leominster',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.5274,
    longitude: -71.7563,
    postal_code: '01453'
  },
  '01462': {
    country: 'US',
    city: 'Lunenburg',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.5884,
    longitude: -71.7266,
    postal_code: '01462'
  },
  '01467': {
    country: 'US',
    city: 'Still River',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.4871,
    longitude: -71.6131,
    postal_code: '01467'
  },
  '01468': {
    country: 'US',
    city: 'Templeton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.546,
    longitude: -72.065,
    postal_code: '01468'
  },
  '01473': {
    country: 'US',
    city: 'Westminster',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.5483,
    longitude: -71.9096,
    postal_code: '01473'
  },
  '01475': {
    country: 'US',
    city: 'Winchendon',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.6789,
    longitude: -72.0475,
    postal_code: '01475'
  },
  '01501': {
    country: 'US',
    city: 'Auburn',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2055,
    longitude: -71.8391,
    postal_code: '01501'
  },
  '01503': {
    country: 'US',
    city: 'Berlin',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3844,
    longitude: -71.6356,
    postal_code: '01503'
  },
  '01504': {
    country: 'US',
    city: 'Blackstone',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.0287,
    longitude: -71.5269,
    postal_code: '01504'
  },
  '01505': {
    country: 'US',
    city: 'Boylston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3377,
    longitude: -71.731,
    postal_code: '01505'
  },
  '01506': {
    country: 'US',
    city: 'Brookfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1991,
    longitude: -72.0989,
    postal_code: '01506'
  },
  '01507': {
    country: 'US',
    city: 'Charlton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1379,
    longitude: -71.9664,
    postal_code: '01507'
  },
  '01508': {
    country: 'US',
    city: 'Charlton City',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1459,
    longitude: -71.9884,
    postal_code: '01508'
  },
  '01509': {
    country: 'US',
    city: 'Charlton Depot',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1731,
    longitude: -71.9787,
    postal_code: '01509'
  },
  '01510': {
    country: 'US',
    city: 'Clinton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.4181,
    longitude: -71.6828,
    postal_code: '01510'
  },
  '01515': {
    country: 'US',
    city: 'East Brookfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2193,
    longitude: -72.0481,
    postal_code: '01515'
  },
  '01516': {
    country: 'US',
    city: 'Douglas',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.0528,
    longitude: -71.7509,
    postal_code: '01516'
  },
  '01518': {
    country: 'US',
    city: 'Fiskdale',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1228,
    longitude: -72.1178,
    postal_code: '01518'
  },
  '01519': {
    country: 'US',
    city: 'Grafton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2004,
    longitude: -71.6868,
    postal_code: '01519'
  },
  '01520': {
    country: 'US',
    city: 'Holden',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.342,
    longitude: -71.8414,
    postal_code: '01520'
  },
  '01522': {
    country: 'US',
    city: 'Jefferson',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3755,
    longitude: -71.8706,
    postal_code: '01522'
  },
  '01523': {
    country: 'US',
    city: 'Lancaster',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.451,
    longitude: -71.6868,
    postal_code: '01523'
  },
  '01524': {
    country: 'US',
    city: 'Leicester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.237,
    longitude: -71.9188,
    postal_code: '01524'
  },
  '01525': {
    country: 'US',
    city: 'Linwood',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.0973,
    longitude: -71.6448,
    postal_code: '01525'
  },
  '01526': {
    country: 'US',
    city: 'Manchaug',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.0945,
    longitude: -71.7476,
    postal_code: '01526'
  },
  '01527': {
    country: 'US',
    city: 'Millbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1968,
    longitude: -71.7644,
    postal_code: '01527'
  },
  '01529': {
    country: 'US',
    city: 'Millville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.0331,
    longitude: -71.5798,
    postal_code: '01529'
  },
  '01531': {
    country: 'US',
    city: 'New Braintree',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3198,
    longitude: -72.1306,
    postal_code: '01531'
  },
  '01532': {
    country: 'US',
    city: 'Northborough',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3182,
    longitude: -71.6464,
    postal_code: '01532'
  },
  '01534': {
    country: 'US',
    city: 'Northbridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1494,
    longitude: -71.6564,
    postal_code: '01534'
  },
  '01535': {
    country: 'US',
    city: 'North Brookfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2665,
    longitude: -72.0821,
    postal_code: '01535'
  },
  '01536': {
    country: 'US',
    city: 'North Grafton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2297,
    longitude: -71.7037,
    postal_code: '01536'
  },
  '01537': {
    country: 'US',
    city: 'North Oxford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1655,
    longitude: -71.886,
    postal_code: '01537'
  },
  '01538': {
    country: 'US',
    city: 'North Uxbridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.0876,
    longitude: -71.6412,
    postal_code: '01538'
  },
  '01540': {
    country: 'US',
    city: 'Oxford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1129,
    longitude: -71.8687,
    postal_code: '01540'
  },
  '01541': {
    country: 'US',
    city: 'Princeton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.4508,
    longitude: -71.8762,
    postal_code: '01541'
  },
  '01542': {
    country: 'US',
    city: 'Rochdale',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1997,
    longitude: -71.9069,
    postal_code: '01542'
  },
  '01543': {
    country: 'US',
    city: 'Rutland',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3762,
    longitude: -71.949,
    postal_code: '01543'
  },
  '01545': {
    country: 'US',
    city: 'Shrewsbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2848,
    longitude: -71.7205,
    postal_code: '01545'
  },
  '01546': {
    country: 'US',
    city: 'Shrewsbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3648,
    longitude: -71.8969,
    postal_code: '01546'
  },
  '01550': {
    country: 'US',
    city: 'Southbridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.075,
    longitude: -72.0353,
    postal_code: '01550'
  },
  '01560': {
    country: 'US',
    city: 'South Grafton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.176,
    longitude: -71.6927,
    postal_code: '01560'
  },
  '01561': {
    country: 'US',
    city: 'South Lancaster',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.4435,
    longitude: -71.6861,
    postal_code: '01561'
  },
  '01562': {
    country: 'US',
    city: 'Spencer',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2441,
    longitude: -71.9906,
    postal_code: '01562'
  },
  '01564': {
    country: 'US',
    city: 'Sterling',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.4354,
    longitude: -71.7752,
    postal_code: '01564'
  },
  '01566': {
    country: 'US',
    city: 'Sturbridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1126,
    longitude: -72.0842,
    postal_code: '01566'
  },
  '01568': {
    country: 'US',
    city: 'Upton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1756,
    longitude: -71.6032,
    postal_code: '01568'
  },
  '01569': {
    country: 'US',
    city: 'Uxbridge',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.0744,
    longitude: -71.6329,
    postal_code: '01569'
  },
  '01570': {
    country: 'US',
    city: 'Webster',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.0521,
    longitude: -71.8486,
    postal_code: '01570'
  },
  '01571': {
    country: 'US',
    city: 'Dudley',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.0489,
    longitude: -71.8932,
    postal_code: '01571'
  },
  '01581': {
    country: 'US',
    city: 'Westborough',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2679,
    longitude: -71.6176,
    postal_code: '01581'
  },
  '01583': {
    country: 'US',
    city: 'West Boylston',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3584,
    longitude: -71.7838,
    postal_code: '01583'
  },
  '01585': {
    country: 'US',
    city: 'West Brookfield',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2441,
    longitude: -72.1511,
    postal_code: '01585'
  },
  '01586': {
    country: 'US',
    city: 'West Millbury',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1712,
    longitude: -71.8037,
    postal_code: '01586'
  },
  '01588': {
    country: 'US',
    city: 'Whitinsville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1153,
    longitude: -71.6644,
    postal_code: '01588'
  },
  '01590': {
    country: 'US',
    city: 'Sutton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1266,
    longitude: -71.7552,
    postal_code: '01590'
  },
  '01601': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2626,
    longitude: -71.8023,
    postal_code: '01601'
  },
  '01602': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2703,
    longitude: -71.8417,
    postal_code: '01602'
  },
  '01603': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.245,
    longitude: -71.838,
    postal_code: '01603'
  },
  '01604': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2541,
    longitude: -71.7746,
    postal_code: '01604'
  },
  '01605': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2894,
    longitude: -71.7888,
    postal_code: '01605'
  },
  '01606': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.311,
    longitude: -71.7958,
    postal_code: '01606'
  },
  '01607': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2303,
    longitude: -71.7938,
    postal_code: '01607'
  },
  '01608': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2624,
    longitude: -71.8003,
    postal_code: '01608'
  },
  '01609': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2826,
    longitude: -71.8277,
    postal_code: '01609'
  },
  '01610': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2492,
    longitude: -71.8108,
    postal_code: '01610'
  },
  '01611': {
    country: 'US',
    city: 'Cherry Valley',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2373,
    longitude: -71.875,
    postal_code: '01611'
  },
  '01612': {
    country: 'US',
    city: 'Paxton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3066,
    longitude: -71.9202,
    postal_code: '01612'
  },
  '01613': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2933,
    longitude: -71.802,
    postal_code: '01613'
  },
  '01614': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2626,
    longitude: -71.8023,
    postal_code: '01614'
  },
  '01615': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2626,
    longitude: -71.8023,
    postal_code: '01615'
  },
  '01653': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3648,
    longitude: -71.8969,
    postal_code: '01653'
  },
  '01655': {
    country: 'US',
    city: 'Worcester',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.3648,
    longitude: -71.8969,
    postal_code: '01655'
  },
  '01740': {
    country: 'US',
    city: 'Bolton',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.4365,
    longitude: -71.6076,
    postal_code: '01740'
  },
  '01745': {
    country: 'US',
    city: 'Fayville',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2934,
    longitude: -71.5028,
    postal_code: '01745'
  },
  '01747': {
    country: 'US',
    city: 'Hopedale',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1268,
    longitude: -71.5376,
    postal_code: '01747'
  },
  '01756': {
    country: 'US',
    city: 'Mendon',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.0967,
    longitude: -71.5499,
    postal_code: '01756'
  },
  '01757': {
    country: 'US',
    city: 'Milford',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.1511,
    longitude: -71.5274,
    postal_code: '01757'
  },
  '01772': {
    country: 'US',
    city: 'Southborough',
    state: 'Massachusetts',
    state_short: 'MA',
    county: 'Worcester',
    latitude: 42.2939,
    longitude: -71.532,
    postal_code: '01772'
  },
  '04210': {
    country: 'US',
    city: 'Auburn',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.0948,
    longitude: -70.239,
    postal_code: '04210'
  },
  '04211': {
    country: 'US',
    city: 'Auburn',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.197,
    longitude: -70.2395,
    postal_code: '04211'
  },
  '04212': {
    country: 'US',
    city: 'Auburn',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.197,
    longitude: -70.2395,
    postal_code: '04212'
  },
  '04222': {
    country: 'US',
    city: 'Durham',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 43.9684,
    longitude: -70.1846,
    postal_code: '04222'
  },
  '04223': {
    country: 'US',
    city: 'Danville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.0239,
    longitude: -70.2857,
    postal_code: '04223'
  },
  '04228': {
    country: 'US',
    city: 'East Livermore',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.3994,
    longitude: -70.1303,
    postal_code: '04228'
  },
  '04230': {
    country: 'US',
    city: 'East Poland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.0627,
    longitude: -70.327,
    postal_code: '04230'
  },
  '04236': {
    country: 'US',
    city: 'Greene',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.1891,
    longitude: -70.1455,
    postal_code: '04236'
  },
  '04240': {
    country: 'US',
    city: 'Lewiston',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.0985,
    longitude: -70.1916,
    postal_code: '04240'
  },
  '04241': {
    country: 'US',
    city: 'Lewiston',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.197,
    longitude: -70.2395,
    postal_code: '04241'
  },
  '04243': {
    country: 'US',
    city: 'Lewiston',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.197,
    longitude: -70.2395,
    postal_code: '04243'
  },
  '04250': {
    country: 'US',
    city: 'Lisbon',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.0255,
    longitude: -70.1139,
    postal_code: '04250'
  },
  '04252': {
    country: 'US',
    city: 'Lisbon Falls',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 43.9978,
    longitude: -70.0734,
    postal_code: '04252'
  },
  '04253': {
    country: 'US',
    city: 'Livermore',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.4079,
    longitude: -70.215,
    postal_code: '04253'
  },
  '04254': {
    country: 'US',
    city: 'Livermore Falls',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.4325,
    longitude: -70.1427,
    postal_code: '04254'
  },
  '04256': {
    country: 'US',
    city: 'Mechanic Falls',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.1117,
    longitude: -70.3917,
    postal_code: '04256'
  },
  '04258': {
    country: 'US',
    city: 'Minot',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.1461,
    longitude: -70.3399,
    postal_code: '04258'
  },
  '04263': {
    country: 'US',
    city: 'Leeds',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.2834,
    longitude: -70.1253,
    postal_code: '04263'
  },
  '04266': {
    country: 'US',
    city: 'North Turner',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.358,
    longitude: -70.2558,
    postal_code: '04266'
  },
  '04274': {
    country: 'US',
    city: 'Poland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.0474,
    longitude: -70.3899,
    postal_code: '04274'
  },
  '04280': {
    country: 'US',
    city: 'Sabattus',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.1133,
    longitude: -70.0748,
    postal_code: '04280'
  },
  '04282': {
    country: 'US',
    city: 'Turner',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.2557,
    longitude: -70.2494,
    postal_code: '04282'
  },
  '04288': {
    country: 'US',
    city: 'West Minot',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.1712,
    longitude: -70.3659,
    postal_code: '04288'
  },
  '04291': {
    country: 'US',
    city: 'West Poland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Androscoggin',
    latitude: 44.0409,
    longitude: -70.453,
    postal_code: '04291'
  },
  '04471': {
    country: 'US',
    city: 'Orient',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 45.8911,
    longitude: -67.8471,
    postal_code: '04471'
  },
  '04497': {
    country: 'US',
    city: 'Wytopitlock',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 45.6645,
    longitude: -68.1055,
    postal_code: '04497'
  },
  '04730': {
    country: 'US',
    city: 'Houlton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.1189,
    longitude: -67.863,
    postal_code: '04730'
  },
  '04732': {
    country: 'US',
    city: 'Ashland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.6184,
    longitude: -68.3876,
    postal_code: '04732'
  },
  '04733': {
    country: 'US',
    city: 'Benedicta',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 45.8125,
    longitude: -68.4089,
    postal_code: '04733'
  },
  '04734': {
    country: 'US',
    city: 'Blaine',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.5048,
    longitude: -67.8686,
    postal_code: '04734'
  },
  '04735': {
    country: 'US',
    city: 'Bridgewater',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.4222,
    longitude: -67.8415,
    postal_code: '04735'
  },
  '04736': {
    country: 'US',
    city: 'Caribou',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.8706,
    longitude: -68.0204,
    postal_code: '04736'
  },
  '04737': {
    country: 'US',
    city: 'Clayton Lake',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.6109,
    longitude: -69.5223,
    postal_code: '04737'
  },
  '04738': {
    country: 'US',
    city: 'Crouseville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.755,
    longitude: -68.0964,
    postal_code: '04738'
  },
  '04739': {
    country: 'US',
    city: 'Eagle Lake',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.01,
    longitude: -68.691,
    postal_code: '04739'
  },
  '04740': {
    country: 'US',
    city: 'Easton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.6357,
    longitude: -67.9018,
    postal_code: '04740'
  },
  '04741': {
    country: 'US',
    city: 'Estcourt Station',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.4589,
    longitude: -69.2243,
    postal_code: '04741'
  },
  '04742': {
    country: 'US',
    city: 'Fort Fairfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.7623,
    longitude: -67.8402,
    postal_code: '04742'
  },
  '04743': {
    country: 'US',
    city: 'Fort Kent',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.2587,
    longitude: -68.5895,
    postal_code: '04743'
  },
  '04744': {
    country: 'US',
    city: 'Fort Kent Mills',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.2389,
    longitude: -68.5839,
    postal_code: '04744'
  },
  '04745': {
    country: 'US',
    city: 'Frenchville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.2826,
    longitude: -68.3917,
    postal_code: '04745'
  },
  '04746': {
    country: 'US',
    city: 'Grand Isle',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.3044,
    longitude: -68.1542,
    postal_code: '04746'
  },
  '04747': {
    country: 'US',
    city: 'Island Falls',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.0169,
    longitude: -68.2667,
    postal_code: '04747'
  },
  '04750': {
    country: 'US',
    city: 'Limestone',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.9248,
    longitude: -67.8451,
    postal_code: '04750'
  },
  '04751': {
    country: 'US',
    city: 'Limestone',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.8929,
    longitude: -67.9643,
    postal_code: '04751'
  },
  '04756': {
    country: 'US',
    city: 'Madawaska',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.3294,
    longitude: -68.3328,
    postal_code: '04756'
  },
  '04757': {
    country: 'US',
    city: 'Mapleton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.6746,
    longitude: -68.1536,
    postal_code: '04757'
  },
  '04758': {
    country: 'US',
    city: 'Mars Hill',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.5223,
    longitude: -67.863,
    postal_code: '04758'
  },
  '04760': {
    country: 'US',
    city: 'Monticello',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.3007,
    longitude: -67.8414,
    postal_code: '04760'
  },
  '04761': {
    country: 'US',
    city: 'New Limerick',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.1004,
    longitude: -68.0034,
    postal_code: '04761'
  },
  '04762': {
    country: 'US',
    city: 'New Sweden',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.9559,
    longitude: -68.1154,
    postal_code: '04762'
  },
  '04763': {
    country: 'US',
    city: 'Oakfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.1088,
    longitude: -68.1298,
    postal_code: '04763'
  },
  '04764': {
    country: 'US',
    city: 'Oxbow',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.402,
    longitude: -68.5218,
    postal_code: '04764'
  },
  '04766': {
    country: 'US',
    city: 'Perham',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.8816,
    longitude: -68.2391,
    postal_code: '04766'
  },
  '04768': {
    country: 'US',
    city: 'Portage',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.7753,
    longitude: -68.4877,
    postal_code: '04768'
  },
  '04769': {
    country: 'US',
    city: 'Presque Isle',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.6842,
    longitude: -68.0118,
    postal_code: '04769'
  },
  '04772': {
    country: 'US',
    city: 'Saint Agatha',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.2387,
    longitude: -68.3232,
    postal_code: '04772'
  },
  '04773': {
    country: 'US',
    city: 'Saint David',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.3343,
    longitude: -68.2314,
    postal_code: '04773'
  },
  '04774': {
    country: 'US',
    city: 'Saint Francis',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.1407,
    longitude: -68.9503,
    postal_code: '04774'
  },
  '04775': {
    country: 'US',
    city: 'Sheridan',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.6573,
    longitude: -68.405,
    postal_code: '04775'
  },
  '04776': {
    country: 'US',
    city: 'Sherman',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 45.8731,
    longitude: -68.3847,
    postal_code: '04776'
  },
  '04779': {
    country: 'US',
    city: 'Sinclair',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.1222,
    longitude: -68.323,
    postal_code: '04779'
  },
  '04780': {
    country: 'US',
    city: 'Smyrna Mills',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.1464,
    longitude: -68.2064,
    postal_code: '04780'
  },
  '04781': {
    country: 'US',
    city: 'Wallagrass',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.1295,
    longitude: -68.5973,
    postal_code: '04781'
  },
  '04783': {
    country: 'US',
    city: 'Stockholm',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.0423,
    longitude: -68.1395,
    postal_code: '04783'
  },
  '04785': {
    country: 'US',
    city: 'Van Buren',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 47.1589,
    longitude: -67.9459,
    postal_code: '04785'
  },
  '04786': {
    country: 'US',
    city: 'Washburn',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.7883,
    longitude: -68.1338,
    postal_code: '04786'
  },
  '04787': {
    country: 'US',
    city: 'Westfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Aroostook',
    latitude: 46.5945,
    longitude: -67.9302,
    postal_code: '04787'
  },
  '04003': {
    country: 'US',
    city: 'Bailey Island',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.7341,
    longitude: -69.9952,
    postal_code: '04003'
  },
  '04009': {
    country: 'US',
    city: 'Bridgton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 44.052,
    longitude: -70.7241,
    postal_code: '04009'
  },
  '04011': {
    country: 'US',
    city: 'Brunswick',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.8973,
    longitude: -69.9779,
    postal_code: '04011'
  },
  '04013': {
    country: 'US',
    city: 'Bustins Island',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.799,
    longitude: -70.0698,
    postal_code: '04013'
  },
  '04015': {
    country: 'US',
    city: 'Casco',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.9596,
    longitude: -70.526,
    postal_code: '04015'
  },
  '04017': {
    country: 'US',
    city: 'Chebeague Island',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.7354,
    longitude: -70.1169,
    postal_code: '04017'
  },
  '04019': {
    country: 'US',
    city: 'Cliff Island',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6955,
    longitude: -70.1071,
    postal_code: '04019'
  },
  '04021': {
    country: 'US',
    city: 'Cumberland Center',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.7974,
    longitude: -70.2649,
    postal_code: '04021'
  },
  '04024': {
    country: 'US',
    city: 'East Baldwin',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.8646,
    longitude: -70.6922,
    postal_code: '04024'
  },
  '04029': {
    country: 'US',
    city: 'Sebago',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.9017,
    longitude: -70.6878,
    postal_code: '04029'
  },
  '04032': {
    country: 'US',
    city: 'Freeport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.857,
    longitude: -70.1031,
    postal_code: '04032'
  },
  '04033': {
    country: 'US',
    city: 'Freeport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.857,
    longitude: -70.1031,
    postal_code: '04033'
  },
  '04034': {
    country: 'US',
    city: 'Freeport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.857,
    longitude: -70.1031,
    postal_code: '04034'
  },
  '04038': {
    country: 'US',
    city: 'Gorham',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6843,
    longitude: -70.468,
    postal_code: '04038'
  },
  '04039': {
    country: 'US',
    city: 'Gray',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.8942,
    longitude: -70.3429,
    postal_code: '04039'
  },
  '04040': {
    country: 'US',
    city: 'Harrison',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 44.1071,
    longitude: -70.6539,
    postal_code: '04040'
  },
  '04050': {
    country: 'US',
    city: 'Long Island',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.692,
    longitude: -70.1551,
    postal_code: '04050'
  },
  '04055': {
    country: 'US',
    city: 'Naples',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.9681,
    longitude: -70.5988,
    postal_code: '04055'
  },
  '04057': {
    country: 'US',
    city: 'North Bridgton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 44.0987,
    longitude: -70.6987,
    postal_code: '04057'
  },
  '04062': {
    country: 'US',
    city: 'Windham',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.7958,
    longitude: -70.4143,
    postal_code: '04062'
  },
  '04066': {
    country: 'US',
    city: 'Orrs Island',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.7727,
    longitude: -69.9668,
    postal_code: '04066'
  },
  '04069': {
    country: 'US',
    city: 'Pownal',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.89,
    longitude: -70.1955,
    postal_code: '04069'
  },
  '04070': {
    country: 'US',
    city: 'Scarborough',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.577,
    longitude: -70.2736,
    postal_code: '04070'
  },
  '04071': {
    country: 'US',
    city: 'Raymond',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.9219,
    longitude: -70.4498,
    postal_code: '04071'
  },
  '04074': {
    country: 'US',
    city: 'Scarborough',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.5835,
    longitude: -70.3457,
    postal_code: '04074'
  },
  '04077': {
    country: 'US',
    city: 'South Casco',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.91,
    longitude: -70.5245,
    postal_code: '04077'
  },
  '04078': {
    country: 'US',
    city: 'South Freeport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.8208,
    longitude: -70.1208,
    postal_code: '04078'
  },
  '04079': {
    country: 'US',
    city: 'Harpswell',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.7811,
    longitude: -69.9955,
    postal_code: '04079'
  },
  '04082': {
    country: 'US',
    city: 'South Windham',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.7362,
    longitude: -70.4237,
    postal_code: '04082'
  },
  '04084': {
    country: 'US',
    city: 'Standish',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.8142,
    longitude: -70.4807,
    postal_code: '04084'
  },
  '04085': {
    country: 'US',
    city: 'Steep Falls',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.7719,
    longitude: -70.6396,
    postal_code: '04085'
  },
  '04091': {
    country: 'US',
    city: 'West Baldwin',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.8299,
    longitude: -70.749,
    postal_code: '04091'
  },
  '04092': {
    country: 'US',
    city: 'Westbrook',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6843,
    longitude: -70.358,
    postal_code: '04092'
  },
  '04096': {
    country: 'US',
    city: 'Yarmouth',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.8009,
    longitude: -70.175,
    postal_code: '04096'
  },
  '04097': {
    country: 'US',
    city: 'North Yarmouth',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.838,
    longitude: -70.2001,
    postal_code: '04097'
  },
  '04098': {
    country: 'US',
    city: 'Westbrook',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.677,
    longitude: -70.3712,
    postal_code: '04098'
  },
  '04101': {
    country: 'US',
    city: 'Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6606,
    longitude: -70.2589,
    postal_code: '04101'
  },
  '04102': {
    country: 'US',
    city: 'Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6602,
    longitude: -70.2898,
    postal_code: '04102'
  },
  '04103': {
    country: 'US',
    city: 'Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6876,
    longitude: -70.2876,
    postal_code: '04103'
  },
  '04104': {
    country: 'US',
    city: 'Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6615,
    longitude: -70.2553,
    postal_code: '04104'
  },
  '04105': {
    country: 'US',
    city: 'Falmouth',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.734,
    longitude: -70.2625,
    postal_code: '04105'
  },
  '04106': {
    country: 'US',
    city: 'South Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6318,
    longitude: -70.2709,
    postal_code: '04106'
  },
  '04107': {
    country: 'US',
    city: 'Cape Elizabeth',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6017,
    longitude: -70.2301,
    postal_code: '04107'
  },
  '04108': {
    country: 'US',
    city: 'Peaks Island',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6589,
    longitude: -70.194,
    postal_code: '04108'
  },
  '04109': {
    country: 'US',
    city: 'Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6783,
    longitude: -70.1987,
    postal_code: '04109'
  },
  '04110': {
    country: 'US',
    city: 'Cumberland Foreside',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.759,
    longitude: -70.1993,
    postal_code: '04110'
  },
  '04112': {
    country: 'US',
    city: 'Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6615,
    longitude: -70.2553,
    postal_code: '04112'
  },
  '04116': {
    country: 'US',
    city: 'South Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6415,
    longitude: -70.2409,
    postal_code: '04116'
  },
  '04122': {
    country: 'US',
    city: 'Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6615,
    longitude: -70.2553,
    postal_code: '04122'
  },
  '04123': {
    country: 'US',
    city: 'Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6615,
    longitude: -70.2553,
    postal_code: '04123'
  },
  '04124': {
    country: 'US',
    city: 'Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.6615,
    longitude: -70.2553,
    postal_code: '04124'
  },
  '04260': {
    country: 'US',
    city: 'New Gloucester',
    state: 'Maine',
    state_short: 'ME',
    county: 'Cumberland',
    latitude: 43.9608,
    longitude: -70.2974,
    postal_code: '04260'
  },
  '04225': {
    country: 'US',
    city: 'Dryden',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.6027,
    longitude: -70.2265,
    postal_code: '04225'
  },
  '04227': {
    country: 'US',
    city: 'East Dixfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.5734,
    longitude: -70.3037,
    postal_code: '04227'
  },
  '04234': {
    country: 'US',
    city: 'East Wilton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.6159,
    longitude: -70.1928,
    postal_code: '04234'
  },
  '04239': {
    country: 'US',
    city: 'Jay',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.516,
    longitude: -70.2099,
    postal_code: '04239'
  },
  '04262': {
    country: 'US',
    city: 'North Jay',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.5473,
    longitude: -70.2381,
    postal_code: '04262'
  },
  '04285': {
    country: 'US',
    city: 'Weld',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.7016,
    longitude: -70.4249,
    postal_code: '04285'
  },
  '04294': {
    country: 'US',
    city: 'Wilton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.5928,
    longitude: -70.2281,
    postal_code: '04294'
  },
  '04936': {
    country: 'US',
    city: 'Eustis',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 45.3356,
    longitude: -70.6283,
    postal_code: '04936'
  },
  '04938': {
    country: 'US',
    city: 'Farmington',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.6653,
    longitude: -70.1329,
    postal_code: '04938'
  },
  '04940': {
    country: 'US',
    city: 'Farmington Falls',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.6226,
    longitude: -70.0752,
    postal_code: '04940'
  },
  '04947': {
    country: 'US',
    city: 'Kingfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.9854,
    longitude: -70.1832,
    postal_code: '04947'
  },
  '04955': {
    country: 'US',
    city: 'New Sharon',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.6458,
    longitude: -70.0139,
    postal_code: '04955'
  },
  '04956': {
    country: 'US',
    city: 'New Vineyard',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.7967,
    longitude: -70.122,
    postal_code: '04956'
  },
  '04964': {
    country: 'US',
    city: 'Oquossoc',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.9664,
    longitude: -70.7737,
    postal_code: '04964'
  },
  '04966': {
    country: 'US',
    city: 'Phillips',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.8375,
    longitude: -70.3601,
    postal_code: '04966'
  },
  '04970': {
    country: 'US',
    city: 'Rangeley',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.9631,
    longitude: -70.6658,
    postal_code: '04970'
  },
  '04982': {
    country: 'US',
    city: 'Stratton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 45.1116,
    longitude: -70.423,
    postal_code: '04982'
  },
  '04983': {
    country: 'US',
    city: 'Strong',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.8224,
    longitude: -70.2221,
    postal_code: '04983'
  },
  '04984': {
    country: 'US',
    city: 'Temple',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.6954,
    longitude: -70.2426,
    postal_code: '04984'
  },
  '04992': {
    country: 'US',
    city: 'West Farmington',
    state: 'Maine',
    state_short: 'ME',
    county: 'Franklin',
    latitude: 44.6628,
    longitude: -70.153,
    postal_code: '04992'
  },
  '04408': {
    country: 'US',
    city: 'Aurora',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.8861,
    longitude: -68.2959,
    postal_code: '04408'
  },
  '04416': {
    country: 'US',
    city: 'Bucksport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.6015,
    longitude: -68.7768,
    postal_code: '04416'
  },
  '04420': {
    country: 'US',
    city: 'Castine',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.413,
    longitude: -68.798,
    postal_code: '04420'
  },
  '04421': {
    country: 'US',
    city: 'Castine',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.4156,
    longitude: -68.7929,
    postal_code: '04421'
  },
  '04431': {
    country: 'US',
    city: 'East Orland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.5612,
    longitude: -68.6647,
    postal_code: '04431'
  },
  '04472': {
    country: 'US',
    city: 'Orland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.5458,
    longitude: -68.7313,
    postal_code: '04472'
  },
  '04476': {
    country: 'US',
    city: 'Penobscot',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.4645,
    longitude: -68.7111,
    postal_code: '04476'
  },
  '04605': {
    country: 'US',
    city: 'Ellsworth',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.5548,
    longitude: -68.4121,
    postal_code: '04605'
  },
  '04607': {
    country: 'US',
    city: 'Gouldsboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.4731,
    longitude: -68.0899,
    postal_code: '04607'
  },
  '04609': {
    country: 'US',
    city: 'Bar Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.3738,
    longitude: -68.2448,
    postal_code: '04609'
  },
  '04612': {
    country: 'US',
    city: 'Bernard',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.2415,
    longitude: -68.358,
    postal_code: '04612'
  },
  '04613': {
    country: 'US',
    city: 'Birch Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.3842,
    longitude: -68.0317,
    postal_code: '04613'
  },
  '04614': {
    country: 'US',
    city: 'Blue Hill',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.4343,
    longitude: -68.5885,
    postal_code: '04614'
  },
  '04616': {
    country: 'US',
    city: 'Brooklin',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.2541,
    longitude: -68.5565,
    postal_code: '04616'
  },
  '04617': {
    country: 'US',
    city: 'Brooksville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.3756,
    longitude: -68.7313,
    postal_code: '04617'
  },
  '04624': {
    country: 'US',
    city: 'Corea',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.4053,
    longitude: -67.985,
    postal_code: '04624'
  },
  '04625': {
    country: 'US',
    city: 'Cranberry Isles',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.2484,
    longitude: -68.2603,
    postal_code: '04625'
  },
  '04627': {
    country: 'US',
    city: 'Deer Isle',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.234,
    longitude: -68.6448,
    postal_code: '04627'
  },
  '04629': {
    country: 'US',
    city: 'East Blue Hill',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.4176,
    longitude: -68.5225,
    postal_code: '04629'
  },
  '04634': {
    country: 'US',
    city: 'Franklin',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.6087,
    longitude: -68.2417,
    postal_code: '04634'
  },
  '04635': {
    country: 'US',
    city: 'Frenchboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.1181,
    longitude: -68.3625,
    postal_code: '04635'
  },
  '04640': {
    country: 'US',
    city: 'Hancock',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.5046,
    longitude: -68.2402,
    postal_code: '04640'
  },
  '04642': {
    country: 'US',
    city: 'Harborside',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.334,
    longitude: -68.8042,
    postal_code: '04642'
  },
  '04644': {
    country: 'US',
    city: 'Hulls Cove',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.4192,
    longitude: -68.2506,
    postal_code: '04644'
  },
  '04646': {
    country: 'US',
    city: 'Islesford',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.262,
    longitude: -68.2339,
    postal_code: '04646'
  },
  '04650': {
    country: 'US',
    city: 'Little Deer Isle',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.2847,
    longitude: -68.7058,
    postal_code: '04650'
  },
  '04653': {
    country: 'US',
    city: 'Bass Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.2401,
    longitude: -68.3439,
    postal_code: '04653'
  },
  '04660': {
    country: 'US',
    city: 'Mount Desert',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.3347,
    longitude: -68.3087,
    postal_code: '04660'
  },
  '04662': {
    country: 'US',
    city: 'Northeast Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.2941,
    longitude: -68.2849,
    postal_code: '04662'
  },
  '04664': {
    country: 'US',
    city: 'Sullivan',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.5458,
    longitude: -68.1279,
    postal_code: '04664'
  },
  '04669': {
    country: 'US',
    city: 'Prospect Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.411,
    longitude: -68.0141,
    postal_code: '04669'
  },
  '04672': {
    country: 'US',
    city: 'Salsbury Cove',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.4306,
    longitude: -68.2836,
    postal_code: '04672'
  },
  '04673': {
    country: 'US',
    city: 'Sargentville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.3136,
    longitude: -68.6863,
    postal_code: '04673'
  },
  '04674': {
    country: 'US',
    city: 'Seal Cove',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.2859,
    longitude: -68.3986,
    postal_code: '04674'
  },
  '04675': {
    country: 'US',
    city: 'Seal Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.299,
    longitude: -68.2463,
    postal_code: '04675'
  },
  '04676': {
    country: 'US',
    city: 'Sedgwick',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.3355,
    longitude: -68.6377,
    postal_code: '04676'
  },
  '04677': {
    country: 'US',
    city: 'Sorrento',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.4907,
    longitude: -68.1787,
    postal_code: '04677'
  },
  '04679': {
    country: 'US',
    city: 'Southwest Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.2823,
    longitude: -68.3265,
    postal_code: '04679'
  },
  '04681': {
    country: 'US',
    city: 'Stonington',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.1752,
    longitude: -68.6746,
    postal_code: '04681'
  },
  '04683': {
    country: 'US',
    city: 'Sunset',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.2059,
    longitude: -68.7047,
    postal_code: '04683'
  },
  '04684': {
    country: 'US',
    city: 'Surry',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.4883,
    longitude: -68.5063,
    postal_code: '04684'
  },
  '04685': {
    country: 'US',
    city: 'Swans Island',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.1451,
    longitude: -68.4517,
    postal_code: '04685'
  },
  '04693': {
    country: 'US',
    city: 'Winter Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Hancock',
    latitude: 44.39,
    longitude: -68.0843,
    postal_code: '04693'
  },
  '04259': {
    country: 'US',
    city: 'Monmouth',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.2208,
    longitude: -70.0263,
    postal_code: '04259'
  },
  '04265': {
    country: 'US',
    city: 'North Monmouth',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.2753,
    longitude: -70.0367,
    postal_code: '04265'
  },
  '04284': {
    country: 'US',
    city: 'Wayne',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.3493,
    longitude: -70.0712,
    postal_code: '04284'
  },
  '04330': {
    country: 'US',
    city: 'Augusta',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.3232,
    longitude: -69.7665,
    postal_code: '04330'
  },
  '04332': {
    country: 'US',
    city: 'Augusta',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.4141,
    longitude: -69.7519,
    postal_code: '04332'
  },
  '04333': {
    country: 'US',
    city: 'Augusta',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.3106,
    longitude: -69.7795,
    postal_code: '04333'
  },
  '04336': {
    country: 'US',
    city: 'Augusta',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.3157,
    longitude: -69.818,
    postal_code: '04336'
  },
  '04338': {
    country: 'US',
    city: 'Augusta',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.3106,
    longitude: -69.7795,
    postal_code: '04338'
  },
  '04343': {
    country: 'US',
    city: 'East Winthrop',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.3217,
    longitude: -69.8964,
    postal_code: '04343'
  },
  '04344': {
    country: 'US',
    city: 'Farmingdale',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.2523,
    longitude: -69.7913,
    postal_code: '04344'
  },
  '04345': {
    country: 'US',
    city: 'Gardiner',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.207,
    longitude: -69.7858,
    postal_code: '04345'
  },
  '04346': {
    country: 'US',
    city: 'Randolph',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.2347,
    longitude: -69.7506,
    postal_code: '04346'
  },
  '04347': {
    country: 'US',
    city: 'Hallowell',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.2864,
    longitude: -69.8057,
    postal_code: '04347'
  },
  '04349': {
    country: 'US',
    city: 'Kents Hill',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.4383,
    longitude: -70.0748,
    postal_code: '04349'
  },
  '04350': {
    country: 'US',
    city: 'Litchfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.1634,
    longitude: -69.9581,
    postal_code: '04350'
  },
  '04351': {
    country: 'US',
    city: 'Manchester',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.358,
    longitude: -69.867,
    postal_code: '04351'
  },
  '04352': {
    country: 'US',
    city: 'Mount Vernon',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.4993,
    longitude: -69.9903,
    postal_code: '04352'
  },
  '04355': {
    country: 'US',
    city: 'Readfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.4032,
    longitude: -69.9506,
    postal_code: '04355'
  },
  '04358': {
    country: 'US',
    city: 'South China',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.3953,
    longitude: -69.5804,
    postal_code: '04358'
  },
  '04359': {
    country: 'US',
    city: 'South Gardiner',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.1801,
    longitude: -69.7567,
    postal_code: '04359'
  },
  '04360': {
    country: 'US',
    city: 'Vienna',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.5475,
    longitude: -70.003,
    postal_code: '04360'
  },
  '04363': {
    country: 'US',
    city: 'Windsor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.3009,
    longitude: -69.5806,
    postal_code: '04363'
  },
  '04364': {
    country: 'US',
    city: 'Winthrop',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.3229,
    longitude: -69.9576,
    postal_code: '04364'
  },
  '04901': {
    country: 'US',
    city: 'Waterville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.5543,
    longitude: -69.6178,
    postal_code: '04901'
  },
  '04903': {
    country: 'US',
    city: 'Waterville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.5492,
    longitude: -69.7132,
    postal_code: '04903'
  },
  '04910': {
    country: 'US',
    city: 'Albion',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.5355,
    longitude: -69.4683,
    postal_code: '04910'
  },
  '04917': {
    country: 'US',
    city: 'Belgrade',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.4688,
    longitude: -69.8606,
    postal_code: '04917'
  },
  '04918': {
    country: 'US',
    city: 'Belgrade Lakes',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.5265,
    longitude: -69.887,
    postal_code: '04918'
  },
  '04926': {
    country: 'US',
    city: 'China Village',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.8526,
    longitude: -69.502,
    postal_code: '04926'
  },
  '04927': {
    country: 'US',
    city: 'Clinton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.644,
    longitude: -69.5284,
    postal_code: '04927'
  },
  '04935': {
    country: 'US',
    city: 'East Vassalboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.4478,
    longitude: -69.6059,
    postal_code: '04935'
  },
  '04962': {
    country: 'US',
    city: 'North Vassalboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.4862,
    longitude: -69.6225,
    postal_code: '04962'
  },
  '04963': {
    country: 'US',
    city: 'Oakland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.5173,
    longitude: -69.7401,
    postal_code: '04963'
  },
  '04989': {
    country: 'US',
    city: 'Vassalboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'Kennebec',
    latitude: 44.4405,
    longitude: -69.6519,
    postal_code: '04989'
  },
  '04547': {
    country: 'US',
    city: 'Friendship',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 43.9807,
    longitude: -69.3349,
    postal_code: '04547'
  },
  '04563': {
    country: 'US',
    city: 'Cushing',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 43.9867,
    longitude: -69.2721,
    postal_code: '04563'
  },
  '04574': {
    country: 'US',
    city: 'Washington',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.2693,
    longitude: -69.3842,
    postal_code: '04574'
  },
  '04645': {
    country: 'US',
    city: 'Isle Au Haut',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.0561,
    longitude: -68.6206,
    postal_code: '04645'
  },
  '04841': {
    country: 'US',
    city: 'Rockland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.1123,
    longitude: -69.1139,
    postal_code: '04841'
  },
  '04843': {
    country: 'US',
    city: 'Camden',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.2137,
    longitude: -69.0767,
    postal_code: '04843'
  },
  '04847': {
    country: 'US',
    city: 'Hope',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.2589,
    longitude: -69.1467,
    postal_code: '04847'
  },
  '04851': {
    country: 'US',
    city: 'Matinicus',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 43.8651,
    longitude: -68.887,
    postal_code: '04851'
  },
  '04853': {
    country: 'US',
    city: 'North Haven',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.1436,
    longitude: -68.8667,
    postal_code: '04853'
  },
  '04854': {
    country: 'US',
    city: 'Owls Head',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.0732,
    longitude: -69.0894,
    postal_code: '04854'
  },
  '04855': {
    country: 'US',
    city: 'Port Clyde',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 43.9273,
    longitude: -69.2528,
    postal_code: '04855'
  },
  '04856': {
    country: 'US',
    city: 'Rockport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.1888,
    longitude: -69.0901,
    postal_code: '04856'
  },
  '04858': {
    country: 'US',
    city: 'South Thomaston',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.0378,
    longitude: -69.1359,
    postal_code: '04858'
  },
  '04859': {
    country: 'US',
    city: 'Spruce Head',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.0104,
    longitude: -69.1707,
    postal_code: '04859'
  },
  '04860': {
    country: 'US',
    city: 'Tenants Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 43.9555,
    longitude: -69.2315,
    postal_code: '04860'
  },
  '04861': {
    country: 'US',
    city: 'Thomaston',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.0846,
    longitude: -69.1888,
    postal_code: '04861'
  },
  '04862': {
    country: 'US',
    city: 'Union',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.2619,
    longitude: -69.2771,
    postal_code: '04862'
  },
  '04863': {
    country: 'US',
    city: 'Vinalhaven',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.0397,
    longitude: -68.8368,
    postal_code: '04863'
  },
  '04864': {
    country: 'US',
    city: 'Warren',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.1271,
    longitude: -69.2479,
    postal_code: '04864'
  },
  '04865': {
    country: 'US',
    city: 'West Rockport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Knox',
    latitude: 44.1924,
    longitude: -69.1211,
    postal_code: '04865'
  },
  '04341': {
    country: 'US',
    city: 'Coopers Mills',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.2588,
    longitude: -69.551,
    postal_code: '04341'
  },
  '04342': {
    country: 'US',
    city: 'Dresden',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.0785,
    longitude: -69.7457,
    postal_code: '04342'
  },
  '04348': {
    country: 'US',
    city: 'Jefferson',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.2204,
    longitude: -69.5133,
    postal_code: '04348'
  },
  '04353': {
    country: 'US',
    city: 'Whitefield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.1884,
    longitude: -69.5751,
    postal_code: '04353'
  },
  '04535': {
    country: 'US',
    city: 'Alna',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.0878,
    longitude: -69.6344,
    postal_code: '04535'
  },
  '04537': {
    country: 'US',
    city: 'Boothbay',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.8945,
    longitude: -69.6273,
    postal_code: '04537'
  },
  '04538': {
    country: 'US',
    city: 'Boothbay Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.8518,
    longitude: -69.6278,
    postal_code: '04538'
  },
  '04539': {
    country: 'US',
    city: 'Bristol',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.9519,
    longitude: -69.4954,
    postal_code: '04539'
  },
  '04541': {
    country: 'US',
    city: 'Chamberlain',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.8843,
    longitude: -69.4792,
    postal_code: '04541'
  },
  '04543': {
    country: 'US',
    city: 'Damariscotta',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.0293,
    longitude: -69.5042,
    postal_code: '04543'
  },
  '04544': {
    country: 'US',
    city: 'East Boothbay',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.8262,
    longitude: -69.5939,
    postal_code: '04544'
  },
  '04549': {
    country: 'US',
    city: 'Isle Of Springs',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.0213,
    longitude: -69.5233,
    postal_code: '04549'
  },
  '04551': {
    country: 'US',
    city: 'Bremen',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.0104,
    longitude: -69.4402,
    postal_code: '04551'
  },
  '04553': {
    country: 'US',
    city: 'Newcastle',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.0499,
    longitude: -69.5331,
    postal_code: '04553'
  },
  '04554': {
    country: 'US',
    city: 'New Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.8605,
    longitude: -69.5079,
    postal_code: '04554'
  },
  '04555': {
    country: 'US',
    city: 'Nobleboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.0943,
    longitude: -69.4828,
    postal_code: '04555'
  },
  '04556': {
    country: 'US',
    city: 'Edgecomb',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.9792,
    longitude: -69.6197,
    postal_code: '04556'
  },
  '04558': {
    country: 'US',
    city: 'Pemaquid',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.8924,
    longitude: -69.5289,
    postal_code: '04558'
  },
  '04564': {
    country: 'US',
    city: 'Round Pond',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.925,
    longitude: -69.4662,
    postal_code: '04564'
  },
  '04568': {
    country: 'US',
    city: 'South Bristol',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.8677,
    longitude: -69.5614,
    postal_code: '04568'
  },
  '04570': {
    country: 'US',
    city: 'Squirrel Island',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.0213,
    longitude: -69.5233,
    postal_code: '04570'
  },
  '04571': {
    country: 'US',
    city: 'Trevett',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.8826,
    longitude: -69.6801,
    postal_code: '04571'
  },
  '04572': {
    country: 'US',
    city: 'Waldoboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.1046,
    longitude: -69.3745,
    postal_code: '04572'
  },
  '04573': {
    country: 'US',
    city: 'Walpole',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.9462,
    longitude: -69.5516,
    postal_code: '04573'
  },
  '04575': {
    country: 'US',
    city: 'West Boothbay Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.849,
    longitude: -69.6434,
    postal_code: '04575'
  },
  '04576': {
    country: 'US',
    city: 'Southport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.8199,
    longitude: -69.6626,
    postal_code: '04576'
  },
  '04578': {
    country: 'US',
    city: 'Wiscasset',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 44.0074,
    longitude: -69.6826,
    postal_code: '04578'
  },
  '04852': {
    country: 'US',
    city: 'Monhegan',
    state: 'Maine',
    state_short: 'ME',
    county: 'Lincoln',
    latitude: 43.7642,
    longitude: -69.3164,
    postal_code: '04852'
  },
  '04010': {
    country: 'US',
    city: 'Brownfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 43.9381,
    longitude: -70.9087,
    postal_code: '04010'
  },
  '04016': {
    country: 'US',
    city: 'Center Lovell',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.181,
    longitude: -70.8917,
    postal_code: '04016'
  },
  '04022': {
    country: 'US',
    city: 'Denmark',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 43.9755,
    longitude: -70.7924,
    postal_code: '04022'
  },
  '04037': {
    country: 'US',
    city: 'Fryeburg',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.0313,
    longitude: -70.9668,
    postal_code: '04037'
  },
  '04041': {
    country: 'US',
    city: 'Hiram',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 43.8622,
    longitude: -70.8531,
    postal_code: '04041'
  },
  '04051': {
    country: 'US',
    city: 'Lovell',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.1614,
    longitude: -70.93,
    postal_code: '04051'
  },
  '04068': {
    country: 'US',
    city: 'Porter',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 43.8262,
    longitude: -70.9243,
    postal_code: '04068'
  },
  '04088': {
    country: 'US',
    city: 'Waterford',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.1931,
    longitude: -70.7163,
    postal_code: '04088'
  },
  '04216': {
    country: 'US',
    city: 'Andover',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.6637,
    longitude: -70.7967,
    postal_code: '04216'
  },
  '04217': {
    country: 'US',
    city: 'Bethel',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.4162,
    longitude: -70.8037,
    postal_code: '04217'
  },
  '04219': {
    country: 'US',
    city: 'Bryant Pond',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.3957,
    longitude: -70.6435,
    postal_code: '04219'
  },
  '04220': {
    country: 'US',
    city: 'Buckfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.2877,
    longitude: -70.3683,
    postal_code: '04220'
  },
  '04221': {
    country: 'US',
    city: 'Canton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.4602,
    longitude: -70.2998,
    postal_code: '04221'
  },
  '04224': {
    country: 'US',
    city: 'Dixfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.5548,
    longitude: -70.4241,
    postal_code: '04224'
  },
  '04226': {
    country: 'US',
    city: 'East Andover',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.6084,
    longitude: -70.6993,
    postal_code: '04226'
  },
  '04231': {
    country: 'US',
    city: 'Stoneham',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.2641,
    longitude: -70.8875,
    postal_code: '04231'
  },
  '04237': {
    country: 'US',
    city: 'Hanover',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.4959,
    longitude: -70.7167,
    postal_code: '04237'
  },
  '04238': {
    country: 'US',
    city: 'Hebron',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.2021,
    longitude: -70.3754,
    postal_code: '04238'
  },
  '04255': {
    country: 'US',
    city: 'Greenwood',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.3184,
    longitude: -70.6509,
    postal_code: '04255'
  },
  '04257': {
    country: 'US',
    city: 'Mexico',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.5628,
    longitude: -70.5358,
    postal_code: '04257'
  },
  '04261': {
    country: 'US',
    city: 'Newry',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.6895,
    longitude: -71.0112,
    postal_code: '04261'
  },
  '04267': {
    country: 'US',
    city: 'North Waterford',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.2317,
    longitude: -70.7687,
    postal_code: '04267'
  },
  '04268': {
    country: 'US',
    city: 'Norway',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.2127,
    longitude: -70.5601,
    postal_code: '04268'
  },
  '04270': {
    country: 'US',
    city: 'Oxford',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.1118,
    longitude: -70.5098,
    postal_code: '04270'
  },
  '04271': {
    country: 'US',
    city: 'Paris',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.2641,
    longitude: -70.4985,
    postal_code: '04271'
  },
  '04275': {
    country: 'US',
    city: 'Roxbury',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.6566,
    longitude: -70.6092,
    postal_code: '04275'
  },
  '04276': {
    country: 'US',
    city: 'Rumford',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.5434,
    longitude: -70.5645,
    postal_code: '04276'
  },
  '04281': {
    country: 'US',
    city: 'South Paris',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.2167,
    longitude: -70.5012,
    postal_code: '04281'
  },
  '04286': {
    country: 'US',
    city: 'West Bethel',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.4021,
    longitude: -70.8601,
    postal_code: '04286'
  },
  '04289': {
    country: 'US',
    city: 'West Paris',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.3253,
    longitude: -70.5732,
    postal_code: '04289'
  },
  '04290': {
    country: 'US',
    city: 'Peru',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.4944,
    longitude: -70.4435,
    postal_code: '04290'
  },
  '04292': {
    country: 'US',
    city: 'Sumner',
    state: 'Maine',
    state_short: 'ME',
    county: 'Oxford',
    latitude: 44.374,
    longitude: -70.4469,
    postal_code: '04292'
  },
  '04401': {
    country: 'US',
    city: 'Bangor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.8242,
    longitude: -68.7918,
    postal_code: '04401'
  },
  '04402': {
    country: 'US',
    city: 'Bangor',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.8012,
    longitude: -68.7778,
    postal_code: '04402'
  },
  '04410': {
    country: 'US',
    city: 'Bradford',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.0855,
    longitude: -68.9235,
    postal_code: '04410'
  },
  '04411': {
    country: 'US',
    city: 'Bradley',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.9015,
    longitude: -68.6263,
    postal_code: '04411'
  },
  '04412': {
    country: 'US',
    city: 'Brewer',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.7874,
    longitude: -68.7539,
    postal_code: '04412'
  },
  '04417': {
    country: 'US',
    city: 'Burlington',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.2349,
    longitude: -68.379,
    postal_code: '04417'
  },
  '04418': {
    country: 'US',
    city: 'Greenbush',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.0803,
    longitude: -68.6509,
    postal_code: '04418'
  },
  '04419': {
    country: 'US',
    city: 'Carmel',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.8053,
    longitude: -68.9942,
    postal_code: '04419'
  },
  '04422': {
    country: 'US',
    city: 'Charleston',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.067,
    longitude: -69.0869,
    postal_code: '04422'
  },
  '04427': {
    country: 'US',
    city: 'Corinth',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.9805,
    longitude: -69.0109,
    postal_code: '04427'
  },
  '04428': {
    country: 'US',
    city: 'Eddington',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.7917,
    longitude: -68.5777,
    postal_code: '04428'
  },
  '04429': {
    country: 'US',
    city: 'Holden',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.7208,
    longitude: -68.6165,
    postal_code: '04429'
  },
  '04430': {
    country: 'US',
    city: 'East Millinocket',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.63,
    longitude: -68.5728,
    postal_code: '04430'
  },
  '04434': {
    country: 'US',
    city: 'Etna',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.7932,
    longitude: -69.1322,
    postal_code: '04434'
  },
  '04435': {
    country: 'US',
    city: 'Exeter',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.9679,
    longitude: -69.1079,
    postal_code: '04435'
  },
  '04444': {
    country: 'US',
    city: 'Hampden',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.7411,
    longitude: -68.8731,
    postal_code: '04444'
  },
  '04448': {
    country: 'US',
    city: 'Howland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.2456,
    longitude: -68.6666,
    postal_code: '04448'
  },
  '04449': {
    country: 'US',
    city: 'Hudson',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.9914,
    longitude: -68.8878,
    postal_code: '04449'
  },
  '04450': {
    country: 'US',
    city: 'Kenduskeag',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.9183,
    longitude: -68.9342,
    postal_code: '04450'
  },
  '04451': {
    country: 'US',
    city: 'Kingman',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.5984,
    longitude: -68.2366,
    postal_code: '04451'
  },
  '04453': {
    country: 'US',
    city: 'Lagrange',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.1789,
    longitude: -68.8345,
    postal_code: '04453'
  },
  '04455': {
    country: 'US',
    city: 'Lee',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.3635,
    longitude: -68.2909,
    postal_code: '04455'
  },
  '04456': {
    country: 'US',
    city: 'Levant',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.8843,
    longitude: -68.9837,
    postal_code: '04456'
  },
  '04457': {
    country: 'US',
    city: 'Lincoln',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.3508,
    longitude: -68.5077,
    postal_code: '04457'
  },
  '04459': {
    country: 'US',
    city: 'Mattawamkeag',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.5264,
    longitude: -68.352,
    postal_code: '04459'
  },
  '04460': {
    country: 'US',
    city: 'Medway',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.607,
    longitude: -68.5227,
    postal_code: '04460'
  },
  '04461': {
    country: 'US',
    city: 'Milford',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.9393,
    longitude: -68.6296,
    postal_code: '04461'
  },
  '04462': {
    country: 'US',
    city: 'Millinocket',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.6596,
    longitude: -68.7101,
    postal_code: '04462'
  },
  '04468': {
    country: 'US',
    city: 'Old Town',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.943,
    longitude: -68.675,
    postal_code: '04468'
  },
  '04469': {
    country: 'US',
    city: 'Orono',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.0028,
    longitude: -68.6334,
    postal_code: '04469'
  },
  '04473': {
    country: 'US',
    city: 'Orono',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.8865,
    longitude: -68.7172,
    postal_code: '04473'
  },
  '04474': {
    country: 'US',
    city: 'Orrington',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.7263,
    longitude: -68.7876,
    postal_code: '04474'
  },
  '04475': {
    country: 'US',
    city: 'Passadumkeag',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.1815,
    longitude: -68.5871,
    postal_code: '04475'
  },
  '04487': {
    country: 'US',
    city: 'Springfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.4264,
    longitude: -68.1108,
    postal_code: '04487'
  },
  '04488': {
    country: 'US',
    city: 'Stetson',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.8843,
    longitude: -69.1069,
    postal_code: '04488'
  },
  '04489': {
    country: 'US',
    city: 'Stillwater',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.9084,
    longitude: -68.6864,
    postal_code: '04489'
  },
  '04493': {
    country: 'US',
    city: 'West Enfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.2378,
    longitude: -68.5338,
    postal_code: '04493'
  },
  '04495': {
    country: 'US',
    city: 'Winn',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.4568,
    longitude: -68.3575,
    postal_code: '04495'
  },
  '04765': {
    country: 'US',
    city: 'Patten',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 46.0132,
    longitude: -68.4647,
    postal_code: '04765'
  },
  '04777': {
    country: 'US',
    city: 'Stacyville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.8637,
    longitude: -68.5053,
    postal_code: '04777'
  },
  '04928': {
    country: 'US',
    city: 'Corinna',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.926,
    longitude: -69.2323,
    postal_code: '04928'
  },
  '04930': {
    country: 'US',
    city: 'Dexter',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.0203,
    longitude: -69.2797,
    postal_code: '04930'
  },
  '04932': {
    country: 'US',
    city: 'Dixmont',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.6991,
    longitude: -69.1025,
    postal_code: '04932'
  },
  '04933': {
    country: 'US',
    city: 'East Newport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.8209,
    longitude: -69.2225,
    postal_code: '04933'
  },
  '04939': {
    country: 'US',
    city: 'Garland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 45.0149,
    longitude: -69.157,
    postal_code: '04939'
  },
  '04953': {
    country: 'US',
    city: 'Newport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.8393,
    longitude: -69.2675,
    postal_code: '04953'
  },
  '04969': {
    country: 'US',
    city: 'Plymouth',
    state: 'Maine',
    state_short: 'ME',
    county: 'Penobscot',
    latitude: 44.7699,
    longitude: -69.2266,
    postal_code: '04969'
  },
  '04406': {
    country: 'US',
    city: 'Abbot',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.2345,
    longitude: -69.5699,
    postal_code: '04406'
  },
  '04414': {
    country: 'US',
    city: 'Brownville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.3412,
    longitude: -69.0423,
    postal_code: '04414'
  },
  '04415': {
    country: 'US',
    city: 'Brownville Junction',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.3512,
    longitude: -69.0581,
    postal_code: '04415'
  },
  '04426': {
    country: 'US',
    city: 'Dover Foxcroft',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.1877,
    longitude: -69.2045,
    postal_code: '04426'
  },
  '04441': {
    country: 'US',
    city: 'Greenville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.4716,
    longitude: -69.5844,
    postal_code: '04441'
  },
  '04442': {
    country: 'US',
    city: 'Greenville Junction',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.4796,
    longitude: -69.6939,
    postal_code: '04442'
  },
  '04443': {
    country: 'US',
    city: 'Guilford',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.1735,
    longitude: -69.3975,
    postal_code: '04443'
  },
  '04463': {
    country: 'US',
    city: 'Milo',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.2446,
    longitude: -68.976,
    postal_code: '04463'
  },
  '04464': {
    country: 'US',
    city: 'Monson',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.2981,
    longitude: -69.488,
    postal_code: '04464'
  },
  '04479': {
    country: 'US',
    city: 'Sangerville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.1406,
    longitude: -69.3218,
    postal_code: '04479'
  },
  '04481': {
    country: 'US',
    city: 'Sebec',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.2465,
    longitude: -69.1021,
    postal_code: '04481'
  },
  '04485': {
    country: 'US',
    city: 'Shirley Mills',
    state: 'Maine',
    state_short: 'ME',
    county: 'Piscataquis',
    latitude: 45.3609,
    longitude: -69.6202,
    postal_code: '04485'
  },
  '04008': {
    country: 'US',
    city: 'Bowdoinham',
    state: 'Maine',
    state_short: 'ME',
    county: 'Sagadahoc',
    latitude: 44.0101,
    longitude: -69.8984,
    postal_code: '04008'
  },
  '04086': {
    country: 'US',
    city: 'Topsham',
    state: 'Maine',
    state_short: 'ME',
    county: 'Sagadahoc',
    latitude: 43.9814,
    longitude: -69.9378,
    postal_code: '04086'
  },
  '04287': {
    country: 'US',
    city: 'Bowdoin',
    state: 'Maine',
    state_short: 'ME',
    county: 'Sagadahoc',
    latitude: 44.0575,
    longitude: -69.9656,
    postal_code: '04287'
  },
  '04357': {
    country: 'US',
    city: 'Richmond',
    state: 'Maine',
    state_short: 'ME',
    county: 'Sagadahoc',
    latitude: 44.1042,
    longitude: -69.8211,
    postal_code: '04357'
  },
  '04530': {
    country: 'US',
    city: 'Bath',
    state: 'Maine',
    state_short: 'ME',
    county: 'Sagadahoc',
    latitude: 43.9062,
    longitude: -69.8266,
    postal_code: '04530'
  },
  '04548': {
    country: 'US',
    city: 'Georgetown',
    state: 'Maine',
    state_short: 'ME',
    county: 'Sagadahoc',
    latitude: 43.8054,
    longitude: -69.7453,
    postal_code: '04548'
  },
  '04562': {
    country: 'US',
    city: 'Phippsburg',
    state: 'Maine',
    state_short: 'ME',
    county: 'Sagadahoc',
    latitude: 43.7688,
    longitude: -69.815,
    postal_code: '04562'
  },
  '04565': {
    country: 'US',
    city: 'Sebasco Estates',
    state: 'Maine',
    state_short: 'ME',
    county: 'Sagadahoc',
    latitude: 43.7733,
    longitude: -69.8635,
    postal_code: '04565'
  },
  '04579': {
    country: 'US',
    city: 'Woolwich',
    state: 'Maine',
    state_short: 'ME',
    county: 'Sagadahoc',
    latitude: 43.9503,
    longitude: -69.7891,
    postal_code: '04579'
  },
  '04478': {
    country: 'US',
    city: 'Rockwood',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 45.8641,
    longitude: -69.8768,
    postal_code: '04478'
  },
  '04911': {
    country: 'US',
    city: 'Anson',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.7831,
    longitude: -69.9309,
    postal_code: '04911'
  },
  '04912': {
    country: 'US',
    city: 'Athens',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.9388,
    longitude: -69.6695,
    postal_code: '04912'
  },
  '04920': {
    country: 'US',
    city: 'Bingham',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 45.0682,
    longitude: -69.8857,
    postal_code: '04920'
  },
  '04923': {
    country: 'US',
    city: 'Cambridge',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 45.0513,
    longitude: -69.4419,
    postal_code: '04923'
  },
  '04924': {
    country: 'US',
    city: 'Canaan',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.745,
    longitude: -69.5498,
    postal_code: '04924'
  },
  '04925': {
    country: 'US',
    city: 'Caratunk',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 45.241,
    longitude: -69.9386,
    postal_code: '04925'
  },
  '04929': {
    country: 'US',
    city: 'Detroit',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.7615,
    longitude: -69.3227,
    postal_code: '04929'
  },
  '04937': {
    country: 'US',
    city: 'Fairfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.6463,
    longitude: -69.6802,
    postal_code: '04937'
  },
  '04942': {
    country: 'US',
    city: 'Harmony',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.973,
    longitude: -69.5481,
    postal_code: '04942'
  },
  '04943': {
    country: 'US',
    city: 'Hartland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.8782,
    longitude: -69.4718,
    postal_code: '04943'
  },
  '04944': {
    country: 'US',
    city: 'Hinckley',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.6847,
    longitude: -69.6425,
    postal_code: '04944'
  },
  '04945': {
    country: 'US',
    city: 'Jackman',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 45.6351,
    longitude: -70.2492,
    postal_code: '04945'
  },
  '04950': {
    country: 'US',
    city: 'Madison',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.8096,
    longitude: -69.8449,
    postal_code: '04950'
  },
  '04954': {
    country: 'US',
    city: 'New Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.8842,
    longitude: -70.0967,
    postal_code: '04954'
  },
  '04957': {
    country: 'US',
    city: 'Norridgewock',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.6899,
    longitude: -69.8306,
    postal_code: '04957'
  },
  '04958': {
    country: 'US',
    city: 'North Anson',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.8797,
    longitude: -69.9119,
    postal_code: '04958'
  },
  '04961': {
    country: 'US',
    city: 'New Portland',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.9251,
    longitude: -70.0231,
    postal_code: '04961'
  },
  '04965': {
    country: 'US',
    city: 'Palmyra',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.8576,
    longitude: -69.3811,
    postal_code: '04965'
  },
  '04967': {
    country: 'US',
    city: 'Pittsfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.7871,
    longitude: -69.4022,
    postal_code: '04967'
  },
  '04971': {
    country: 'US',
    city: 'Saint Albans',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.9293,
    longitude: -69.3992,
    postal_code: '04971'
  },
  '04975': {
    country: 'US',
    city: 'Shawmut',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.6245,
    longitude: -69.5869,
    postal_code: '04975'
  },
  '04976': {
    country: 'US',
    city: 'Skowhegan',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.7772,
    longitude: -69.6976,
    postal_code: '04976'
  },
  '04978': {
    country: 'US',
    city: 'Smithfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.6301,
    longitude: -69.8075,
    postal_code: '04978'
  },
  '04979': {
    country: 'US',
    city: 'Solon',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 44.9676,
    longitude: -69.833,
    postal_code: '04979'
  },
  '04985': {
    country: 'US',
    city: 'West Forks',
    state: 'Maine',
    state_short: 'ME',
    county: 'Somerset',
    latitude: 45.3839,
    longitude: -69.9841,
    postal_code: '04985'
  },
  '04354': {
    country: 'US',
    city: 'Palermo',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.3843,
    longitude: -69.4334,
    postal_code: '04354'
  },
  '04438': {
    country: 'US',
    city: 'Frankfort',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.5979,
    longitude: -68.934,
    postal_code: '04438'
  },
  '04496': {
    country: 'US',
    city: 'Winterport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.6552,
    longitude: -68.8862,
    postal_code: '04496'
  },
  '04848': {
    country: 'US',
    city: 'Islesboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.3082,
    longitude: -68.9073,
    postal_code: '04848'
  },
  '04849': {
    country: 'US',
    city: 'Lincolnville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.3048,
    longitude: -69.0824,
    postal_code: '04849'
  },
  '04850': {
    country: 'US',
    city: 'Lincolnville Center',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.2979,
    longitude: -69.1075,
    postal_code: '04850'
  },
  '04915': {
    country: 'US',
    city: 'Belfast',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.4354,
    longitude: -69.0148,
    postal_code: '04915'
  },
  '04921': {
    country: 'US',
    city: 'Brooks',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.5678,
    longitude: -69.1404,
    postal_code: '04921'
  },
  '04922': {
    country: 'US',
    city: 'Burnham',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.6848,
    longitude: -69.38,
    postal_code: '04922'
  },
  '04941': {
    country: 'US',
    city: 'Freedom',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.4633,
    longitude: -69.319,
    postal_code: '04941'
  },
  '04949': {
    country: 'US',
    city: 'Liberty',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.3741,
    longitude: -69.3306,
    postal_code: '04949'
  },
  '04951': {
    country: 'US',
    city: 'Monroe',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.5927,
    longitude: -69.0317,
    postal_code: '04951'
  },
  '04952': {
    country: 'US',
    city: 'Morrill',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.4107,
    longitude: -69.1471,
    postal_code: '04952'
  },
  '04972': {
    country: 'US',
    city: 'Sandy Point',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.5148,
    longitude: -68.8128,
    postal_code: '04972'
  },
  '04973': {
    country: 'US',
    city: 'Searsmont',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.377,
    longitude: -69.2196,
    postal_code: '04973'
  },
  '04974': {
    country: 'US',
    city: 'Searsport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.4877,
    longitude: -68.9311,
    postal_code: '04974'
  },
  '04981': {
    country: 'US',
    city: 'Stockton Springs',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.5141,
    longitude: -68.856,
    postal_code: '04981'
  },
  '04986': {
    country: 'US',
    city: 'Thorndike',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.5744,
    longitude: -69.2487,
    postal_code: '04986'
  },
  '04987': {
    country: 'US',
    city: 'Troy',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.6757,
    longitude: -69.2549,
    postal_code: '04987'
  },
  '04988': {
    country: 'US',
    city: 'Unity',
    state: 'Maine',
    state_short: 'ME',
    county: 'Waldo',
    latitude: 44.6007,
    longitude: -69.3328,
    postal_code: '04988'
  },
  '04413': {
    country: 'US',
    city: 'Brookton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.5507,
    longitude: -67.7436,
    postal_code: '04413'
  },
  '04424': {
    country: 'US',
    city: 'Danforth',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.6687,
    longitude: -67.8688,
    postal_code: '04424'
  },
  '04454': {
    country: 'US',
    city: 'Lambert Lake',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.5459,
    longitude: -67.5269,
    postal_code: '04454'
  },
  '04490': {
    country: 'US',
    city: 'Topsfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.4304,
    longitude: -67.7473,
    postal_code: '04490'
  },
  '04491': {
    country: 'US',
    city: 'Vanceboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.5634,
    longitude: -67.4297,
    postal_code: '04491'
  },
  '04492': {
    country: 'US',
    city: 'Waite',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.3492,
    longitude: -67.678,
    postal_code: '04492'
  },
  '04606': {
    country: 'US',
    city: 'Addison',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.583,
    longitude: -67.7146,
    postal_code: '04606'
  },
  '04611': {
    country: 'US',
    city: 'Beals',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.513,
    longitude: -67.6056,
    postal_code: '04611'
  },
  '04619': {
    country: 'US',
    city: 'Calais',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.1715,
    longitude: -67.2641,
    postal_code: '04619'
  },
  '04622': {
    country: 'US',
    city: 'Cherryfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.6228,
    longitude: -67.9436,
    postal_code: '04622'
  },
  '04623': {
    country: 'US',
    city: 'Columbia Falls',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.6699,
    longitude: -67.7534,
    postal_code: '04623'
  },
  '04626': {
    country: 'US',
    city: 'Cutler',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.6753,
    longitude: -67.2499,
    postal_code: '04626'
  },
  '04628': {
    country: 'US',
    city: 'Dennysville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.8961,
    longitude: -67.2244,
    postal_code: '04628'
  },
  '04630': {
    country: 'US',
    city: 'East Machias',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.7424,
    longitude: -67.3821,
    postal_code: '04630'
  },
  '04631': {
    country: 'US',
    city: 'Eastport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.92,
    longitude: -67.0074,
    postal_code: '04631'
  },
  '04637': {
    country: 'US',
    city: 'Grand Lake Stream',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.1795,
    longitude: -67.7744,
    postal_code: '04637'
  },
  '04643': {
    country: 'US',
    city: 'Harrington',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.6122,
    longitude: -67.8147,
    postal_code: '04643'
  },
  '04648': {
    country: 'US',
    city: 'Jonesboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.6582,
    longitude: -67.5777,
    postal_code: '04648'
  },
  '04649': {
    country: 'US',
    city: 'Jonesport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.5509,
    longitude: -67.6044,
    postal_code: '04649'
  },
  '04652': {
    country: 'US',
    city: 'Lubec',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.9043,
    longitude: -67.0408,
    postal_code: '04652'
  },
  '04654': {
    country: 'US',
    city: 'Machias',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.7215,
    longitude: -67.482,
    postal_code: '04654'
  },
  '04655': {
    country: 'US',
    city: 'Machiasport',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.682,
    longitude: -67.4073,
    postal_code: '04655'
  },
  '04657': {
    country: 'US',
    city: 'Meddybemps',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.0193,
    longitude: -67.3829,
    postal_code: '04657'
  },
  '04658': {
    country: 'US',
    city: 'Milbridge',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.5366,
    longitude: -67.8844,
    postal_code: '04658'
  },
  '04666': {
    country: 'US',
    city: 'Pembroke',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.9654,
    longitude: -67.2002,
    postal_code: '04666'
  },
  '04667': {
    country: 'US',
    city: 'Perry',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.9888,
    longitude: -67.0929,
    postal_code: '04667'
  },
  '04668': {
    country: 'US',
    city: 'Princeton',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.2131,
    longitude: -67.6008,
    postal_code: '04668'
  },
  '04671': {
    country: 'US',
    city: 'Robbinston',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.067,
    longitude: -67.1433,
    postal_code: '04671'
  },
  '04680': {
    country: 'US',
    city: 'Steuben',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.4971,
    longitude: -67.9503,
    postal_code: '04680'
  },
  '04686': {
    country: 'US',
    city: 'Wesley',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.9854,
    longitude: -67.6991,
    postal_code: '04686'
  },
  '04691': {
    country: 'US',
    city: 'Whiting',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 44.7622,
    longitude: -67.2515,
    postal_code: '04691'
  },
  '04694': {
    country: 'US',
    city: 'Baileyville',
    state: 'Maine',
    state_short: 'ME',
    county: 'Washington',
    latitude: 45.1035,
    longitude: -67.4722,
    postal_code: '04694'
  },
  '03901': {
    country: 'US',
    city: 'Berwick',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.2899,
    longitude: -70.855,
    postal_code: '03901'
  },
  '03902': {
    country: 'US',
    city: 'Cape Neddick',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.2133,
    longitude: -70.6397,
    postal_code: '03902'
  },
  '03903': {
    country: 'US',
    city: 'Eliot',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.1309,
    longitude: -70.7822,
    postal_code: '03903'
  },
  '03904': {
    country: 'US',
    city: 'Kittery',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.0921,
    longitude: -70.7429,
    postal_code: '03904'
  },
  '03905': {
    country: 'US',
    city: 'Kittery Point',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.0976,
    longitude: -70.7121,
    postal_code: '03905'
  },
  '03906': {
    country: 'US',
    city: 'North Berwick',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.3254,
    longitude: -70.7212,
    postal_code: '03906'
  },
  '03907': {
    country: 'US',
    city: 'Ogunquit',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.2541,
    longitude: -70.6094,
    postal_code: '03907'
  },
  '03908': {
    country: 'US',
    city: 'South Berwick',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.2292,
    longitude: -70.7859,
    postal_code: '03908'
  },
  '03909': {
    country: 'US',
    city: 'York',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.1544,
    longitude: -70.6578,
    postal_code: '03909'
  },
  '03910': {
    country: 'US',
    city: 'York Beach',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.1715,
    longitude: -70.6089,
    postal_code: '03910'
  },
  '03911': {
    country: 'US',
    city: 'York Harbor',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.1368,
    longitude: -70.6456,
    postal_code: '03911'
  },
  '04001': {
    country: 'US',
    city: 'Acton',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.5494,
    longitude: -70.9307,
    postal_code: '04001'
  },
  '04002': {
    country: 'US',
    city: 'Alfred',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.4875,
    longitude: -70.6961,
    postal_code: '04002'
  },
  '04004': {
    country: 'US',
    city: 'Bar Mills',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.6131,
    longitude: -70.5498,
    postal_code: '04004'
  },
  '04005': {
    country: 'US',
    city: 'Biddeford',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.4935,
    longitude: -70.4883,
    postal_code: '04005'
  },
  '04006': {
    country: 'US',
    city: 'Biddeford Pool',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.4448,
    longitude: -70.3414,
    postal_code: '04006'
  },
  '04007': {
    country: 'US',
    city: 'Biddeford',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.4581,
    longitude: -70.5053,
    postal_code: '04007'
  },
  '04014': {
    country: 'US',
    city: 'Cape Porpoise',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.3657,
    longitude: -70.6044,
    postal_code: '04014'
  },
  '04020': {
    country: 'US',
    city: 'Cornish',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.7796,
    longitude: -70.7784,
    postal_code: '04020'
  },
  '04027': {
    country: 'US',
    city: 'Lebanon',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.4597,
    longitude: -70.9152,
    postal_code: '04027'
  },
  '04028': {
    country: 'US',
    city: 'East Parsonsfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.7334,
    longitude: -70.8434,
    postal_code: '04028'
  },
  '04030': {
    country: 'US',
    city: 'East Waterboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.5995,
    longitude: -70.6906,
    postal_code: '04030'
  },
  '04042': {
    country: 'US',
    city: 'Hollis Center',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.5946,
    longitude: -70.6051,
    postal_code: '04042'
  },
  '04043': {
    country: 'US',
    city: 'Kennebunk',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.3881,
    longitude: -70.5478,
    postal_code: '04043'
  },
  '04046': {
    country: 'US',
    city: 'Kennebunkport',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.3923,
    longitude: -70.4729,
    postal_code: '04046'
  },
  '04047': {
    country: 'US',
    city: 'Parsonsfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.745,
    longitude: -70.9092,
    postal_code: '04047'
  },
  '04048': {
    country: 'US',
    city: 'Limerick',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.6963,
    longitude: -70.7866,
    postal_code: '04048'
  },
  '04049': {
    country: 'US',
    city: 'Limington',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.726,
    longitude: -70.6752,
    postal_code: '04049'
  },
  '04054': {
    country: 'US',
    city: 'Moody',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.2763,
    longitude: -70.5978,
    postal_code: '04054'
  },
  '04056': {
    country: 'US',
    city: 'Newfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.6584,
    longitude: -70.8689,
    postal_code: '04056'
  },
  '04061': {
    country: 'US',
    city: 'North Waterboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.64,
    longitude: -70.7298,
    postal_code: '04061'
  },
  '04063': {
    country: 'US',
    city: 'Ocean Park',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.5006,
    longitude: -70.3862,
    postal_code: '04063'
  },
  '04064': {
    country: 'US',
    city: 'Old Orchard Beach',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.5254,
    longitude: -70.3883,
    postal_code: '04064'
  },
  '04072': {
    country: 'US',
    city: 'Saco',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.5209,
    longitude: -70.4546,
    postal_code: '04072'
  },
  '04073': {
    country: 'US',
    city: 'Sanford',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.4285,
    longitude: -70.7585,
    postal_code: '04073'
  },
  '04076': {
    country: 'US',
    city: 'Shapleigh',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.5674,
    longitude: -70.8286,
    postal_code: '04076'
  },
  '04083': {
    country: 'US',
    city: 'Springvale',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.4715,
    longitude: -70.8064,
    postal_code: '04083'
  },
  '04087': {
    country: 'US',
    city: 'Waterboro',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.5661,
    longitude: -70.7431,
    postal_code: '04087'
  },
  '04090': {
    country: 'US',
    city: 'Wells',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.3144,
    longitude: -70.5969,
    postal_code: '04090'
  },
  '04093': {
    country: 'US',
    city: 'Buxton',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.6379,
    longitude: -70.5189,
    postal_code: '04093'
  },
  '04094': {
    country: 'US',
    city: 'West Kennebunk',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.4061,
    longitude: -70.5733,
    postal_code: '04094'
  },
  '04095': {
    country: 'US',
    city: 'West Newfield',
    state: 'Maine',
    state_short: 'ME',
    county: 'York',
    latitude: 43.601,
    longitude: -70.9027,
    postal_code: '04095'
  },
  '03218': {
    country: 'US',
    city: 'Barnstead',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.3349,
    longitude: -71.2887,
    postal_code: '03218'
  },
  '03220': {
    country: 'US',
    city: 'Belmont',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.4512,
    longitude: -71.489,
    postal_code: '03220'
  },
  '03225': {
    country: 'US',
    city: 'Center Barnstead',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.3566,
    longitude: -71.2424,
    postal_code: '03225'
  },
  '03226': {
    country: 'US',
    city: 'Center Harbor',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.7107,
    longitude: -71.4797,
    postal_code: '03226'
  },
  '03237': {
    country: 'US',
    city: 'Gilmanton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.4175,
    longitude: -71.4121,
    postal_code: '03237'
  },
  '03246': {
    country: 'US',
    city: 'Laconia',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.5727,
    longitude: -71.4781,
    postal_code: '03246'
  },
  '03247': {
    country: 'US',
    city: 'Laconia',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.589,
    longitude: -71.4455,
    postal_code: '03247'
  },
  '03249': {
    country: 'US',
    city: 'Gilford',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.5475,
    longitude: -71.4072,
    postal_code: '03249'
  },
  '03252': {
    country: 'US',
    city: 'Lochmere',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.4686,
    longitude: -71.5371,
    postal_code: '03252'
  },
  '03253': {
    country: 'US',
    city: 'Meredith',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.6311,
    longitude: -71.4997,
    postal_code: '03253'
  },
  '03256': {
    country: 'US',
    city: 'New Hampton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.6184,
    longitude: -71.6435,
    postal_code: '03256'
  },
  '03269': {
    country: 'US',
    city: 'Sanbornton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.5496,
    longitude: -71.6003,
    postal_code: '03269'
  },
  '03276': {
    country: 'US',
    city: 'Tilton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.4603,
    longitude: -71.5774,
    postal_code: '03276'
  },
  '03289': {
    country: 'US',
    city: 'Winnisquam',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.4964,
    longitude: -71.5197,
    postal_code: '03289'
  },
  '03298': {
    country: 'US',
    city: 'Tilton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.5249,
    longitude: -71.4458,
    postal_code: '03298'
  },
  '03299': {
    country: 'US',
    city: 'Tilton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.5249,
    longitude: -71.4458,
    postal_code: '03299'
  },
  '03809': {
    country: 'US',
    city: 'Alton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.463,
    longitude: -71.2297,
    postal_code: '03809'
  },
  '03810': {
    country: 'US',
    city: 'Alton Bay',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.4845,
    longitude: -71.2489,
    postal_code: '03810'
  },
  '03837': {
    country: 'US',
    city: 'Gilmanton Iron Works',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Belknap',
    latitude: 43.4466,
    longitude: -71.2995,
    postal_code: '03837'
  },
  '03227': {
    country: 'US',
    city: 'Center Sandwich',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.8162,
    longitude: -71.4506,
    postal_code: '03227'
  },
  '03254': {
    country: 'US',
    city: 'Moultonborough',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.7281,
    longitude: -71.3922,
    postal_code: '03254'
  },
  '03259': {
    country: 'US',
    city: 'North Sandwich',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.8452,
    longitude: -71.385,
    postal_code: '03259'
  },
  '03812': {
    country: 'US',
    city: 'Bartlett',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 44.0802,
    longitude: -71.292,
    postal_code: '03812'
  },
  '03813': {
    country: 'US',
    city: 'Center Conway',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.9878,
    longitude: -71.0607,
    postal_code: '03813'
  },
  '03814': {
    country: 'US',
    city: 'Center Ossipee',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.7682,
    longitude: -71.1349,
    postal_code: '03814'
  },
  '03816': {
    country: 'US',
    city: 'Center Tuftonboro',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.6849,
    longitude: -71.2551,
    postal_code: '03816'
  },
  '03817': {
    country: 'US',
    city: 'Chocorua',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.8909,
    longitude: -71.2407,
    postal_code: '03817'
  },
  '03818': {
    country: 'US',
    city: 'Conway',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.9742,
    longitude: -71.1503,
    postal_code: '03818'
  },
  '03830': {
    country: 'US',
    city: 'East Wakefield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.6142,
    longitude: -71.0051,
    postal_code: '03830'
  },
  '03832': {
    country: 'US',
    city: 'Eaton Center',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.9092,
    longitude: -71.0837,
    postal_code: '03832'
  },
  '03836': {
    country: 'US',
    city: 'Freedom',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.8172,
    longitude: -71.0628,
    postal_code: '03836'
  },
  '03838': {
    country: 'US',
    city: 'Glen',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 44.1018,
    longitude: -71.1925,
    postal_code: '03838'
  },
  '03845': {
    country: 'US',
    city: 'Intervale',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 44.0955,
    longitude: -71.1194,
    postal_code: '03845'
  },
  '03846': {
    country: 'US',
    city: 'Jackson',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 44.167,
    longitude: -71.1878,
    postal_code: '03846'
  },
  '03847': {
    country: 'US',
    city: 'Kearsarge',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 44.0753,
    longitude: -71.1176,
    postal_code: '03847'
  },
  '03849': {
    country: 'US',
    city: 'Madison',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.9152,
    longitude: -71.1254,
    postal_code: '03849'
  },
  '03850': {
    country: 'US',
    city: 'Melvin Village',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.7077,
    longitude: -71.3017,
    postal_code: '03850'
  },
  '03853': {
    country: 'US',
    city: 'Mirror Lake',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.6366,
    longitude: -71.2729,
    postal_code: '03853'
  },
  '03860': {
    country: 'US',
    city: 'North Conway',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 44.0336,
    longitude: -71.1238,
    postal_code: '03860'
  },
  '03861': {
    country: 'US',
    city: 'Lee',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.1192,
    longitude: -70.9969,
    postal_code: '03861'
  },
  '03864': {
    country: 'US',
    city: 'Ossipee',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.6945,
    longitude: -71.1129,
    postal_code: '03864'
  },
  '03872': {
    country: 'US',
    city: 'Sanbornville',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.5513,
    longitude: -71.02,
    postal_code: '03872'
  },
  '03875': {
    country: 'US',
    city: 'Silver Lake',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.879,
    longitude: -71.1905,
    postal_code: '03875'
  },
  '03882': {
    country: 'US',
    city: 'Effingham',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.7012,
    longitude: -70.9834,
    postal_code: '03882'
  },
  '03883': {
    country: 'US',
    city: 'South Tamworth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.8077,
    longitude: -71.308,
    postal_code: '03883'
  },
  '03886': {
    country: 'US',
    city: 'Tamworth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.862,
    longitude: -71.2645,
    postal_code: '03886'
  },
  '03890': {
    country: 'US',
    city: 'West Ossipee',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.8118,
    longitude: -71.1946,
    postal_code: '03890'
  },
  '03894': {
    country: 'US',
    city: 'Wolfeboro',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.595,
    longitude: -71.1908,
    postal_code: '03894'
  },
  '03896': {
    country: 'US',
    city: 'Wolfeboro Falls',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.5892,
    longitude: -71.2189,
    postal_code: '03896'
  },
  '03897': {
    country: 'US',
    city: 'Wonalancet',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Carroll',
    latitude: 43.9073,
    longitude: -71.3506,
    postal_code: '03897'
  },
  '03431': {
    country: 'US',
    city: 'Keene',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.9627,
    longitude: -72.2955,
    postal_code: '03431'
  },
  '03435': {
    country: 'US',
    city: 'Keene',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.9337,
    longitude: -72.2781,
    postal_code: '03435'
  },
  '03441': {
    country: 'US',
    city: 'Ashuelot',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.7851,
    longitude: -72.4349,
    postal_code: '03441'
  },
  '03443': {
    country: 'US',
    city: 'Chesterfield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.8839,
    longitude: -72.4548,
    postal_code: '03443'
  },
  '03444': {
    country: 'US',
    city: 'Dublin',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.8972,
    longitude: -72.0505,
    postal_code: '03444'
  },
  '03445': {
    country: 'US',
    city: 'Sullivan',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.9982,
    longitude: -72.2019,
    postal_code: '03445'
  },
  '03446': {
    country: 'US',
    city: 'Swanzey',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.8411,
    longitude: -72.3301,
    postal_code: '03446'
  },
  '03447': {
    country: 'US',
    city: 'Fitzwilliam',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.7611,
    longitude: -72.145,
    postal_code: '03447'
  },
  '03448': {
    country: 'US',
    city: 'Gilsum',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 43.0399,
    longitude: -72.2719,
    postal_code: '03448'
  },
  '03450': {
    country: 'US',
    city: 'Harrisville',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.9399,
    longitude: -72.0972,
    postal_code: '03450'
  },
  '03451': {
    country: 'US',
    city: 'Hinsdale',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.8027,
    longitude: -72.5015,
    postal_code: '03451'
  },
  '03452': {
    country: 'US',
    city: 'Jaffrey',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.8178,
    longitude: -72.0275,
    postal_code: '03452'
  },
  '03455': {
    country: 'US',
    city: 'Marlborough',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.8988,
    longitude: -72.2013,
    postal_code: '03455'
  },
  '03456': {
    country: 'US',
    city: 'Marlow',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 43.1326,
    longitude: -72.2109,
    postal_code: '03456'
  },
  '03457': {
    country: 'US',
    city: 'Nelson',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.9906,
    longitude: -72.1309,
    postal_code: '03457'
  },
  '03461': {
    country: 'US',
    city: 'Rindge',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.7544,
    longitude: -72.019,
    postal_code: '03461'
  },
  '03462': {
    country: 'US',
    city: 'Spofford',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.912,
    longitude: -72.4103,
    postal_code: '03462'
  },
  '03464': {
    country: 'US',
    city: 'Stoddard',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 43.0739,
    longitude: -72.1088,
    postal_code: '03464'
  },
  '03465': {
    country: 'US',
    city: 'Troy',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.827,
    longitude: -72.1848,
    postal_code: '03465'
  },
  '03466': {
    country: 'US',
    city: 'West Chesterfield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.8956,
    longitude: -72.5097,
    postal_code: '03466'
  },
  '03467': {
    country: 'US',
    city: 'Westmoreland',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.969,
    longitude: -72.4358,
    postal_code: '03467'
  },
  '03469': {
    country: 'US',
    city: 'West Swanzey',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.8734,
    longitude: -72.3151,
    postal_code: '03469'
  },
  '03470': {
    country: 'US',
    city: 'Winchester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 42.7788,
    longitude: -72.344,
    postal_code: '03470'
  },
  '03602': {
    country: 'US',
    city: 'Alstead',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 43.1265,
    longitude: -72.3281,
    postal_code: '03602'
  },
  '03604': {
    country: 'US',
    city: 'Drewsville',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 43.1281,
    longitude: -72.392,
    postal_code: '03604'
  },
  '03608': {
    country: 'US',
    city: 'Walpole',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 43.0765,
    longitude: -72.4155,
    postal_code: '03608'
  },
  '03609': {
    country: 'US',
    city: 'North Walpole',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Cheshire',
    latitude: 43.1428,
    longitude: -72.4483,
    postal_code: '03609'
  },
  '03570': {
    country: 'US',
    city: 'Berlin',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.4811,
    longitude: -71.1892,
    postal_code: '03570'
  },
  '03575': {
    country: 'US',
    city: 'Bretton Woods',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.2581,
    longitude: -71.4412,
    postal_code: '03575'
  },
  '03576': {
    country: 'US',
    city: 'Colebrook',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.9078,
    longitude: -71.4793,
    postal_code: '03576'
  },
  '03579': {
    country: 'US',
    city: 'Errol',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.8003,
    longitude: -71.1436,
    postal_code: '03579'
  },
  '03581': {
    country: 'US',
    city: 'Gorham',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.4048,
    longitude: -71.1548,
    postal_code: '03581'
  },
  '03582': {
    country: 'US',
    city: 'Groveton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.5984,
    longitude: -71.5064,
    postal_code: '03582'
  },
  '03583': {
    country: 'US',
    city: 'Jefferson',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.3999,
    longitude: -71.4518,
    postal_code: '03583'
  },
  '03584': {
    country: 'US',
    city: 'Lancaster',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.4921,
    longitude: -71.5591,
    postal_code: '03584'
  },
  '03588': {
    country: 'US',
    city: 'Milan',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.587,
    longitude: -71.181,
    postal_code: '03588'
  },
  '03589': {
    country: 'US',
    city: 'Mount Washington',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.6956,
    longitude: -71.3874,
    postal_code: '03589'
  },
  '03590': {
    country: 'US',
    city: 'North Stratford',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.7149,
    longitude: -71.5644,
    postal_code: '03590'
  },
  '03592': {
    country: 'US',
    city: 'Pittsburg',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 45.0866,
    longitude: -71.3636,
    postal_code: '03592'
  },
  '03593': {
    country: 'US',
    city: 'Randolph',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.3753,
    longitude: -71.2798,
    postal_code: '03593'
  },
  '03595': {
    country: 'US',
    city: 'Twin Mountain',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.2689,
    longitude: -71.5471,
    postal_code: '03595'
  },
  '03597': {
    country: 'US',
    city: 'West Stewartstown',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.6956,
    longitude: -71.3874,
    postal_code: '03597'
  },
  '03598': {
    country: 'US',
    city: 'Whitefield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Coos',
    latitude: 44.3681,
    longitude: -71.6035,
    postal_code: '03598'
  },
  '03215': {
    country: 'US',
    city: 'Waterville Valley',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.9501,
    longitude: -71.4995,
    postal_code: '03215'
  },
  '03217': {
    country: 'US',
    city: 'Ashland',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.7034,
    longitude: -71.6121,
    postal_code: '03217'
  },
  '03222': {
    country: 'US',
    city: 'Bristol',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.612,
    longitude: -71.7507,
    postal_code: '03222'
  },
  '03223': {
    country: 'US',
    city: 'Campton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.8418,
    longitude: -71.6652,
    postal_code: '03223'
  },
  '03238': {
    country: 'US',
    city: 'Glencliff',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.9828,
    longitude: -71.8934,
    postal_code: '03238'
  },
  '03240': {
    country: 'US',
    city: 'Grafton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.5727,
    longitude: -71.9634,
    postal_code: '03240'
  },
  '03241': {
    country: 'US',
    city: 'Hebron',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.7186,
    longitude: -71.827,
    postal_code: '03241'
  },
  '03245': {
    country: 'US',
    city: 'Holderness',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.7378,
    longitude: -71.6044,
    postal_code: '03245'
  },
  '03251': {
    country: 'US',
    city: 'Lincoln',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.0582,
    longitude: -71.6727,
    postal_code: '03251'
  },
  '03258': {
    country: 'US',
    city: 'Chichester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.244,
    longitude: -71.4105,
    postal_code: '03258'
  },
  '03262': {
    country: 'US',
    city: 'North Woodstock',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.0354,
    longitude: -71.6863,
    postal_code: '03262'
  },
  '03264': {
    country: 'US',
    city: 'Plymouth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.757,
    longitude: -71.6881,
    postal_code: '03264'
  },
  '03266': {
    country: 'US',
    city: 'Rumney',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.8044,
    longitude: -71.848,
    postal_code: '03266'
  },
  '03279': {
    country: 'US',
    city: 'Warren',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.9447,
    longitude: -71.8901,
    postal_code: '03279'
  },
  '03282': {
    country: 'US',
    city: 'Wentworth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.8685,
    longitude: -71.9097,
    postal_code: '03282'
  },
  '03285': {
    country: 'US',
    city: 'Thornton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.905,
    longitude: -71.647,
    postal_code: '03285'
  },
  '03293': {
    country: 'US',
    city: 'Woodstock',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.9776,
    longitude: -71.6851,
    postal_code: '03293'
  },
  '03561': {
    country: 'US',
    city: 'Littleton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.3112,
    longitude: -71.7768,
    postal_code: '03561'
  },
  '03574': {
    country: 'US',
    city: 'Bethlehem',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.2808,
    longitude: -71.6829,
    postal_code: '03574'
  },
  '03580': {
    country: 'US',
    city: 'Franconia',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.2053,
    longitude: -71.7518,
    postal_code: '03580'
  },
  '03585': {
    country: 'US',
    city: 'Lisbon',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.2148,
    longitude: -71.8966,
    postal_code: '03585'
  },
  '03586': {
    country: 'US',
    city: 'Sugar Hill',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.2203,
    longitude: -71.7964,
    postal_code: '03586'
  },
  '03740': {
    country: 'US',
    city: 'Bath',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.1808,
    longitude: -71.9828,
    postal_code: '03740'
  },
  '03741': {
    country: 'US',
    city: 'Canaan',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.6601,
    longitude: -72.0297,
    postal_code: '03741'
  },
  '03748': {
    country: 'US',
    city: 'Enfield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.6256,
    longitude: -72.127,
    postal_code: '03748'
  },
  '03749': {
    country: 'US',
    city: 'Enfield Center',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.5796,
    longitude: -72.0854,
    postal_code: '03749'
  },
  '03750': {
    country: 'US',
    city: 'Etna',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.7113,
    longitude: -72.2125,
    postal_code: '03750'
  },
  '03755': {
    country: 'US',
    city: 'Hanover',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.7045,
    longitude: -72.285,
    postal_code: '03755'
  },
  '03756': {
    country: 'US',
    city: 'Lebanon',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.6423,
    longitude: -72.2518,
    postal_code: '03756'
  },
  '03765': {
    country: 'US',
    city: 'Haverhill',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.0394,
    longitude: -72.0573,
    postal_code: '03765'
  },
  '03766': {
    country: 'US',
    city: 'Lebanon',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.6447,
    longitude: -72.2428,
    postal_code: '03766'
  },
  '03768': {
    country: 'US',
    city: 'Lyme',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.7913,
    longitude: -72.162,
    postal_code: '03768'
  },
  '03769': {
    country: 'US',
    city: 'Lyme Center',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.7863,
    longitude: -72.1205,
    postal_code: '03769'
  },
  '03771': {
    country: 'US',
    city: 'Monroe',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.2734,
    longitude: -72.025,
    postal_code: '03771'
  },
  '03774': {
    country: 'US',
    city: 'North Haverhill',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.0978,
    longitude: -72.0191,
    postal_code: '03774'
  },
  '03777': {
    country: 'US',
    city: 'Orford',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.8941,
    longitude: -72.0978,
    postal_code: '03777'
  },
  '03779': {
    country: 'US',
    city: 'Piermont',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.9906,
    longitude: -72.0813,
    postal_code: '03779'
  },
  '03780': {
    country: 'US',
    city: 'Pike',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.0255,
    longitude: -72.0096,
    postal_code: '03780'
  },
  '03784': {
    country: 'US',
    city: 'West Lebanon',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 43.644,
    longitude: -72.3007,
    postal_code: '03784'
  },
  '03785': {
    country: 'US',
    city: 'Woodsville',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Grafton',
    latitude: 44.1523,
    longitude: -72.0373,
    postal_code: '03785'
  },
  '03031': {
    country: 'US',
    city: 'Amherst',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.8569,
    longitude: -71.6075,
    postal_code: '03031'
  },
  '03033': {
    country: 'US',
    city: 'Brookline',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.7384,
    longitude: -71.6663,
    postal_code: '03033'
  },
  '03043': {
    country: 'US',
    city: 'Francestown',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.992,
    longitude: -71.8113,
    postal_code: '03043'
  },
  '03045': {
    country: 'US',
    city: 'Goffstown',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 43.0201,
    longitude: -71.5697,
    postal_code: '03045'
  },
  '03047': {
    country: 'US',
    city: 'Greenfield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9493,
    longitude: -71.8728,
    postal_code: '03047'
  },
  '03048': {
    country: 'US',
    city: 'Greenville',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.7482,
    longitude: -71.7619,
    postal_code: '03048'
  },
  '03049': {
    country: 'US',
    city: 'Hollis',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.7485,
    longitude: -71.5772,
    postal_code: '03049'
  },
  '03051': {
    country: 'US',
    city: 'Hudson',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.769,
    longitude: -71.4121,
    postal_code: '03051'
  },
  '03052': {
    country: 'US',
    city: 'Litchfield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.843,
    longitude: -71.455,
    postal_code: '03052'
  },
  '03054': {
    country: 'US',
    city: 'Merrimack',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.8667,
    longitude: -71.5128,
    postal_code: '03054'
  },
  '03055': {
    country: 'US',
    city: 'Milford',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.8285,
    longitude: -71.6606,
    postal_code: '03055'
  },
  '03057': {
    country: 'US',
    city: 'Mont Vernon',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.8976,
    longitude: -71.6762,
    postal_code: '03057'
  },
  '03060': {
    country: 'US',
    city: 'Nashua',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.7564,
    longitude: -71.4667,
    postal_code: '03060'
  },
  '03061': {
    country: 'US',
    city: 'Nashua',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.7654,
    longitude: -71.4676,
    postal_code: '03061'
  },
  '03062': {
    country: 'US',
    city: 'Nashua',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.7235,
    longitude: -71.4893,
    postal_code: '03062'
  },
  '03063': {
    country: 'US',
    city: 'Nashua',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.7717,
    longitude: -71.5132,
    postal_code: '03063'
  },
  '03064': {
    country: 'US',
    city: 'Nashua',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.779,
    longitude: -71.4748,
    postal_code: '03064'
  },
  '03070': {
    country: 'US',
    city: 'New Boston',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9722,
    longitude: -71.6864,
    postal_code: '03070'
  },
  '03071': {
    country: 'US',
    city: 'New Ipswich',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.7511,
    longitude: -71.8703,
    postal_code: '03071'
  },
  '03076': {
    country: 'US',
    city: 'Pelham',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.7288,
    longitude: -71.3046,
    postal_code: '03076'
  },
  '03082': {
    country: 'US',
    city: 'Lyndeborough',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.8954,
    longitude: -71.7744,
    postal_code: '03082'
  },
  '03084': {
    country: 'US',
    city: 'Temple',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.82,
    longitude: -71.8523,
    postal_code: '03084'
  },
  '03086': {
    country: 'US',
    city: 'Wilton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.8368,
    longitude: -71.7541,
    postal_code: '03086'
  },
  '03101': {
    country: 'US',
    city: 'Manchester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9929,
    longitude: -71.4633,
    postal_code: '03101'
  },
  '03102': {
    country: 'US',
    city: 'Manchester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9944,
    longitude: -71.4884,
    postal_code: '03102'
  },
  '03103': {
    country: 'US',
    city: 'Manchester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9656,
    longitude: -71.4493,
    postal_code: '03103'
  },
  '03104': {
    country: 'US',
    city: 'Manchester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 43.0073,
    longitude: -71.4482,
    postal_code: '03104'
  },
  '03105': {
    country: 'US',
    city: 'Manchester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9956,
    longitude: -71.4548,
    postal_code: '03105'
  },
  '03108': {
    country: 'US',
    city: 'Manchester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9956,
    longitude: -71.4548,
    postal_code: '03108'
  },
  '03109': {
    country: 'US',
    city: 'Manchester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9713,
    longitude: -71.4135,
    postal_code: '03109'
  },
  '03110': {
    country: 'US',
    city: 'Bedford',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9403,
    longitude: -71.5213,
    postal_code: '03110'
  },
  '03111': {
    country: 'US',
    city: 'Manchester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9956,
    longitude: -71.4548,
    postal_code: '03111'
  },
  '03244': {
    country: 'US',
    city: 'Hillsborough',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 43.1141,
    longitude: -71.8992,
    postal_code: '03244'
  },
  '03281': {
    country: 'US',
    city: 'Weare',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 43.0714,
    longitude: -71.7038,
    postal_code: '03281'
  },
  '03440': {
    country: 'US',
    city: 'Antrim',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 43.0595,
    longitude: -71.9387,
    postal_code: '03440'
  },
  '03442': {
    country: 'US',
    city: 'Bennington',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 43.0103,
    longitude: -71.9153,
    postal_code: '03442'
  },
  '03449': {
    country: 'US',
    city: 'Hancock',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.9768,
    longitude: -71.9819,
    postal_code: '03449'
  },
  '03458': {
    country: 'US',
    city: 'Peterborough',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.8856,
    longitude: -71.947,
    postal_code: '03458'
  },
  '03468': {
    country: 'US',
    city: 'West Peterborough',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Hillsborough',
    latitude: 42.8908,
    longitude: -71.9334,
    postal_code: '03468'
  },
  '03046': {
    country: 'US',
    city: 'Dunbarton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.1025,
    longitude: -71.6169,
    postal_code: '03046'
  },
  '03106': {
    country: 'US',
    city: 'Hooksett',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.0617,
    longitude: -71.4444,
    postal_code: '03106'
  },
  '03216': {
    country: 'US',
    city: 'Andover',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.4287,
    longitude: -71.783,
    postal_code: '03216'
  },
  '03221': {
    country: 'US',
    city: 'Bradford',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.2517,
    longitude: -71.9617,
    postal_code: '03221'
  },
  '03224': {
    country: 'US',
    city: 'Canterbury',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.357,
    longitude: -71.557,
    postal_code: '03224'
  },
  '03229': {
    country: 'US',
    city: 'Contoocook',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.1978,
    longitude: -71.6968,
    postal_code: '03229'
  },
  '03230': {
    country: 'US',
    city: 'Danbury',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.5115,
    longitude: -71.8691,
    postal_code: '03230'
  },
  '03231': {
    country: 'US',
    city: 'East Andover',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.4595,
    longitude: -71.7476,
    postal_code: '03231'
  },
  '03233': {
    country: 'US',
    city: 'Elkins',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.4209,
    longitude: -71.9373,
    postal_code: '03233'
  },
  '03234': {
    country: 'US',
    city: 'Epsom',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.2174,
    longitude: -71.3546,
    postal_code: '03234'
  },
  '03235': {
    country: 'US',
    city: 'Franklin',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.4426,
    longitude: -71.6491,
    postal_code: '03235'
  },
  '03242': {
    country: 'US',
    city: 'Henniker',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.1791,
    longitude: -71.8159,
    postal_code: '03242'
  },
  '03243': {
    country: 'US',
    city: 'Hill',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.5274,
    longitude: -71.7292,
    postal_code: '03243'
  },
  '03255': {
    country: 'US',
    city: 'Newbury',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.3217,
    longitude: -72.0118,
    postal_code: '03255'
  },
  '03257': {
    country: 'US',
    city: 'New London',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.4145,
    longitude: -71.9857,
    postal_code: '03257'
  },
  '03260': {
    country: 'US',
    city: 'North Sutton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.3653,
    longitude: -71.9341,
    postal_code: '03260'
  },
  '03263': {
    country: 'US',
    city: 'Pittsfield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.2874,
    longitude: -71.333,
    postal_code: '03263'
  },
  '03268': {
    country: 'US',
    city: 'Salisbury',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.4067,
    longitude: -71.7045,
    postal_code: '03268'
  },
  '03272': {
    country: 'US',
    city: 'South Newbury',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.2956,
    longitude: -71.9967,
    postal_code: '03272'
  },
  '03273': {
    country: 'US',
    city: 'South Sutton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.3042,
    longitude: -71.9289,
    postal_code: '03273'
  },
  '03275': {
    country: 'US',
    city: 'Suncook',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.1604,
    longitude: -71.4179,
    postal_code: '03275'
  },
  '03278': {
    country: 'US',
    city: 'Warner',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.3035,
    longitude: -71.8353,
    postal_code: '03278'
  },
  '03287': {
    country: 'US',
    city: 'Wilmot',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.4485,
    longitude: -71.9158,
    postal_code: '03287'
  },
  '03301': {
    country: 'US',
    city: 'Concord',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.2185,
    longitude: -71.5277,
    postal_code: '03301'
  },
  '03302': {
    country: 'US',
    city: 'Concord',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.3103,
    longitude: -71.6629,
    postal_code: '03302'
  },
  '03303': {
    country: 'US',
    city: 'Concord',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.2817,
    longitude: -71.6595,
    postal_code: '03303'
  },
  '03304': {
    country: 'US',
    city: 'Bow',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.1388,
    longitude: -71.5448,
    postal_code: '03304'
  },
  '03305': {
    country: 'US',
    city: 'Concord',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.2134,
    longitude: -71.5172,
    postal_code: '03305'
  },
  '03307': {
    country: 'US',
    city: 'Loudon',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Merrimack',
    latitude: 43.3193,
    longitude: -71.467,
    postal_code: '03307'
  },
  '03032': {
    country: 'US',
    city: 'Auburn',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.9925,
    longitude: -71.3449,
    postal_code: '03032'
  },
  '03034': {
    country: 'US',
    city: 'Candia',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0585,
    longitude: -71.3049,
    postal_code: '03034'
  },
  '03036': {
    country: 'US',
    city: 'Chester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.9678,
    longitude: -71.245,
    postal_code: '03036'
  },
  '03037': {
    country: 'US',
    city: 'Deerfield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.1378,
    longitude: -71.2513,
    postal_code: '03037'
  },
  '03038': {
    country: 'US',
    city: 'Derry',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.8874,
    longitude: -71.302,
    postal_code: '03038'
  },
  '03040': {
    country: 'US',
    city: 'East Candia',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0481,
    longitude: -71.2487,
    postal_code: '03040'
  },
  '03041': {
    country: 'US',
    city: 'East Derry',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.8945,
    longitude: -71.2912,
    postal_code: '03041'
  },
  '03042': {
    country: 'US',
    city: 'Epping',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0411,
    longitude: -71.0764,
    postal_code: '03042'
  },
  '03044': {
    country: 'US',
    city: 'Fremont',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.984,
    longitude: -71.1218,
    postal_code: '03044'
  },
  '03053': {
    country: 'US',
    city: 'Londonderry',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.8656,
    longitude: -71.3772,
    postal_code: '03053'
  },
  '03073': {
    country: 'US',
    city: 'North Salem',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.837,
    longitude: -71.2206,
    postal_code: '03073'
  },
  '03077': {
    country: 'US',
    city: 'Raymond',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0325,
    longitude: -71.1912,
    postal_code: '03077'
  },
  '03079': {
    country: 'US',
    city: 'Salem',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.7846,
    longitude: -71.2176,
    postal_code: '03079'
  },
  '03087': {
    country: 'US',
    city: 'Windham',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.8051,
    longitude: -71.3067,
    postal_code: '03087'
  },
  '03261': {
    country: 'US',
    city: 'Northwood',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.207,
    longitude: -71.2004,
    postal_code: '03261'
  },
  '03290': {
    country: 'US',
    city: 'Nottingham',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.1196,
    longitude: -71.111,
    postal_code: '03290'
  },
  '03291': {
    country: 'US',
    city: 'West Nottingham',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.1823,
    longitude: -71.1396,
    postal_code: '03291'
  },
  '03801': {
    country: 'US',
    city: 'Portsmouth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0729,
    longitude: -70.8052,
    postal_code: '03801'
  },
  '03802': {
    country: 'US',
    city: 'Portsmouth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0718,
    longitude: -70.7626,
    postal_code: '03802'
  },
  '03803': {
    country: 'US',
    city: 'Portsmouth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0718,
    longitude: -70.7626,
    postal_code: '03803'
  },
  '03804': {
    country: 'US',
    city: 'Portsmouth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0718,
    longitude: -70.7626,
    postal_code: '03804'
  },
  '03811': {
    country: 'US',
    city: 'Atkinson',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.837,
    longitude: -71.1603,
    postal_code: '03811'
  },
  '03819': {
    country: 'US',
    city: 'Danville',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.9234,
    longitude: -71.121,
    postal_code: '03819'
  },
  '03826': {
    country: 'US',
    city: 'East Hampstead',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.8877,
    longitude: -71.128,
    postal_code: '03826'
  },
  '03827': {
    country: 'US',
    city: 'East Kingston',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.907,
    longitude: -70.9876,
    postal_code: '03827'
  },
  '03833': {
    country: 'US',
    city: 'Exeter',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.9815,
    longitude: -70.9478,
    postal_code: '03833'
  },
  '03840': {
    country: 'US',
    city: 'Greenland',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0353,
    longitude: -70.8475,
    postal_code: '03840'
  },
  '03841': {
    country: 'US',
    city: 'Hampstead',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.882,
    longitude: -71.1758,
    postal_code: '03841'
  },
  '03842': {
    country: 'US',
    city: 'Hampton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.9359,
    longitude: -70.8243,
    postal_code: '03842'
  },
  '03843': {
    country: 'US',
    city: 'Hampton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0059,
    longitude: -71.0132,
    postal_code: '03843'
  },
  '03844': {
    country: 'US',
    city: 'Hampton Falls',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.9263,
    longitude: -70.8876,
    postal_code: '03844'
  },
  '03848': {
    country: 'US',
    city: 'Kingston',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.9133,
    longitude: -71.0639,
    postal_code: '03848'
  },
  '03854': {
    country: 'US',
    city: 'New Castle',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0681,
    longitude: -70.7199,
    postal_code: '03854'
  },
  '03856': {
    country: 'US',
    city: 'Newfields',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0374,
    longitude: -70.9782,
    postal_code: '03856'
  },
  '03857': {
    country: 'US',
    city: 'Newmarket',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0726,
    longitude: -70.9553,
    postal_code: '03857'
  },
  '03858': {
    country: 'US',
    city: 'Newton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.8678,
    longitude: -71.042,
    postal_code: '03858'
  },
  '03859': {
    country: 'US',
    city: 'Newton Junction',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.8619,
    longitude: -71.04,
    postal_code: '03859'
  },
  '03862': {
    country: 'US',
    city: 'North Hampton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.9776,
    longitude: -70.8267,
    postal_code: '03862'
  },
  '03865': {
    country: 'US',
    city: 'Plaistow',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.8356,
    longitude: -71.0934,
    postal_code: '03865'
  },
  '03870': {
    country: 'US',
    city: 'Rye',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0095,
    longitude: -70.7652,
    postal_code: '03870'
  },
  '03871': {
    country: 'US',
    city: 'Rye Beach',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.9806,
    longitude: -70.7719,
    postal_code: '03871'
  },
  '03873': {
    country: 'US',
    city: 'Sandown',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.9308,
    longitude: -71.1861,
    postal_code: '03873'
  },
  '03874': {
    country: 'US',
    city: 'Seabrook',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 42.8854,
    longitude: -70.8646,
    postal_code: '03874'
  },
  '03885': {
    country: 'US',
    city: 'Stratham',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Rockingham',
    latitude: 43.0194,
    longitude: -70.8997,
    postal_code: '03885'
  },
  '03815': {
    country: 'US',
    city: 'Center Strafford',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.2629,
    longitude: -71.1071,
    postal_code: '03815'
  },
  '03820': {
    country: 'US',
    city: 'Dover',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.1888,
    longitude: -70.8868,
    postal_code: '03820'
  },
  '03821': {
    country: 'US',
    city: 'Dover',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.1979,
    longitude: -70.8737,
    postal_code: '03821'
  },
  '03822': {
    country: 'US',
    city: 'Dover',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.1979,
    longitude: -70.8737,
    postal_code: '03822'
  },
  '03823': {
    country: 'US',
    city: 'Madbury',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.1729,
    longitude: -70.9412,
    postal_code: '03823'
  },
  '03824': {
    country: 'US',
    city: 'Durham',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.1174,
    longitude: -70.9197,
    postal_code: '03824'
  },
  '03825': {
    country: 'US',
    city: 'Barrington',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.2027,
    longitude: -71.0377,
    postal_code: '03825'
  },
  '03835': {
    country: 'US',
    city: 'Farmington',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.3884,
    longitude: -71.0647,
    postal_code: '03835'
  },
  '03839': {
    country: 'US',
    city: 'Rochester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.262,
    longitude: -70.9837,
    postal_code: '03839'
  },
  '03851': {
    country: 'US',
    city: 'Milton',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.4229,
    longitude: -71.0114,
    postal_code: '03851'
  },
  '03852': {
    country: 'US',
    city: 'Milton Mills',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.5025,
    longitude: -70.9697,
    postal_code: '03852'
  },
  '03855': {
    country: 'US',
    city: 'New Durham',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.443,
    longitude: -71.1408,
    postal_code: '03855'
  },
  '03866': {
    country: 'US',
    city: 'Rochester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.3045,
    longitude: -70.9756,
    postal_code: '03866'
  },
  '03867': {
    country: 'US',
    city: 'Rochester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.3045,
    longitude: -70.9756,
    postal_code: '03867'
  },
  '03868': {
    country: 'US',
    city: 'Rochester',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.32,
    longitude: -70.9413,
    postal_code: '03868'
  },
  '03869': {
    country: 'US',
    city: 'Rollinsford',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.2269,
    longitude: -70.8332,
    postal_code: '03869'
  },
  '03878': {
    country: 'US',
    city: 'Somersworth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.2525,
    longitude: -70.8756,
    postal_code: '03878'
  },
  '03884': {
    country: 'US',
    city: 'Strafford',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.2506,
    longitude: -71.1625,
    postal_code: '03884'
  },
  '03887': {
    country: 'US',
    city: 'Union',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Strafford',
    latitude: 43.4861,
    longitude: -71.063,
    postal_code: '03887'
  },
  '03280': {
    country: 'US',
    city: 'Washington',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.1747,
    longitude: -72.0824,
    postal_code: '03280'
  },
  '03284': {
    country: 'US',
    city: 'Springfield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.4938,
    longitude: -72.047,
    postal_code: '03284'
  },
  '03601': {
    country: 'US',
    city: 'Acworth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.196,
    longitude: -72.3001,
    postal_code: '03601'
  },
  '03603': {
    country: 'US',
    city: 'Charlestown',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.2573,
    longitude: -72.4064,
    postal_code: '03603'
  },
  '03605': {
    country: 'US',
    city: 'Lempster',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.2136,
    longitude: -72.1968,
    postal_code: '03605'
  },
  '03607': {
    country: 'US',
    city: 'South Acworth',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.1956,
    longitude: -72.2686,
    postal_code: '03607'
  },
  '03743': {
    country: 'US',
    city: 'Claremont',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.3679,
    longitude: -72.3422,
    postal_code: '03743'
  },
  '03745': {
    country: 'US',
    city: 'Cornish',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.4963,
    longitude: -72.3394,
    postal_code: '03745'
  },
  '03746': {
    country: 'US',
    city: 'Cornish Flat',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.4973,
    longitude: -72.2795,
    postal_code: '03746'
  },
  '03751': {
    country: 'US',
    city: 'Georges Mills',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.4317,
    longitude: -72.0673,
    postal_code: '03751'
  },
  '03752': {
    country: 'US',
    city: 'Goshen',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.3026,
    longitude: -72.1241,
    postal_code: '03752'
  },
  '03753': {
    country: 'US',
    city: 'Grantham',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.5103,
    longitude: -72.1334,
    postal_code: '03753'
  },
  '03754': {
    country: 'US',
    city: 'Guild',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.3767,
    longitude: -72.1381,
    postal_code: '03754'
  },
  '03770': {
    country: 'US',
    city: 'Meriden',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.5299,
    longitude: -72.2756,
    postal_code: '03770'
  },
  '03773': {
    country: 'US',
    city: 'Newport',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.3532,
    longitude: -72.1838,
    postal_code: '03773'
  },
  '03781': {
    country: 'US',
    city: 'Plainfield',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.5519,
    longitude: -72.2704,
    postal_code: '03781'
  },
  '03782': {
    country: 'US',
    city: 'Sunapee',
    state: 'New Hampshire',
    state_short: 'NH',
    county: 'Sullivan',
    latitude: 43.3868,
    longitude: -72.095,
    postal_code: '03782'
  },
  '08037': {
    country: 'US',
    city: 'Hammonton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.638,
    longitude: -74.7728,
    postal_code: '08037'
  },
  '08201': {
    country: 'US',
    city: 'Absecon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.4218,
    longitude: -74.4949,
    postal_code: '08201'
  },
  '08203': {
    country: 'US',
    city: 'Brigantine',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.4101,
    longitude: -74.3646,
    postal_code: '08203'
  },
  '08205': {
    country: 'US',
    city: 'Absecon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.4745,
    longitude: -74.4575,
    postal_code: '08205'
  },
  '08213': {
    country: 'US',
    city: 'Cologne',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.5092,
    longitude: -74.6086,
    postal_code: '08213'
  },
  '08215': {
    country: 'US',
    city: 'Egg Harbor City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.5331,
    longitude: -74.6177,
    postal_code: '08215'
  },
  '08217': {
    country: 'US',
    city: 'Elwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.5737,
    longitude: -74.72,
    postal_code: '08217'
  },
  '08220': {
    country: 'US',
    city: 'Leeds Point',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.4921,
    longitude: -74.429,
    postal_code: '08220'
  },
  '08221': {
    country: 'US',
    city: 'Linwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3469,
    longitude: -74.5807,
    postal_code: '08221'
  },
  '08225': {
    country: 'US',
    city: 'Northfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3703,
    longitude: -74.5552,
    postal_code: '08225'
  },
  '08231': {
    country: 'US',
    city: 'Oceanville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.4712,
    longitude: -74.4604,
    postal_code: '08231'
  },
  '08232': {
    country: 'US',
    city: 'Pleasantville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3876,
    longitude: -74.5149,
    postal_code: '08232'
  },
  '08234': {
    country: 'US',
    city: 'Egg Harbor Township',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3741,
    longitude: -74.6118,
    postal_code: '08234'
  },
  '08240': {
    country: 'US',
    city: 'Pomona',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.4877,
    longitude: -74.5543,
    postal_code: '08240'
  },
  '08241': {
    country: 'US',
    city: 'Port Republic',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.5272,
    longitude: -74.4903,
    postal_code: '08241'
  },
  '08244': {
    country: 'US',
    city: 'Somers Point',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3223,
    longitude: -74.6008,
    postal_code: '08244'
  },
  '08310': {
    country: 'US',
    city: 'Buena',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.537,
    longitude: -74.8895,
    postal_code: '08310'
  },
  '08317': {
    country: 'US',
    city: 'Dorothy',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.4031,
    longitude: -74.8316,
    postal_code: '08317'
  },
  '08319': {
    country: 'US',
    city: 'Estell Manor',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3783,
    longitude: -74.8165,
    postal_code: '08319'
  },
  '08326': {
    country: 'US',
    city: 'Landisville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.5239,
    longitude: -74.9377,
    postal_code: '08326'
  },
  '08330': {
    country: 'US',
    city: 'Mays Landing',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.432,
    longitude: -74.6962,
    postal_code: '08330'
  },
  '08340': {
    country: 'US',
    city: 'Milmay',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.4451,
    longitude: -74.8667,
    postal_code: '08340'
  },
  '08341': {
    country: 'US',
    city: 'Minotola',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.5155,
    longitude: -74.9467,
    postal_code: '08341'
  },
  '08342': {
    country: 'US',
    city: 'Mizpah',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.5021,
    longitude: -74.8335,
    postal_code: '08342'
  },
  '08346': {
    country: 'US',
    city: 'Newtonville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.5645,
    longitude: -74.859,
    postal_code: '08346'
  },
  '08350': {
    country: 'US',
    city: 'Richland',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.485,
    longitude: -74.8776,
    postal_code: '08350'
  },
  '08401': {
    country: 'US',
    city: 'Atlantic City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3664,
    longitude: -74.4317,
    postal_code: '08401'
  },
  '08402': {
    country: 'US',
    city: 'Margate City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3286,
    longitude: -74.509,
    postal_code: '08402'
  },
  '08403': {
    country: 'US',
    city: 'Longport',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3194,
    longitude: -74.5356,
    postal_code: '08403'
  },
  '08404': {
    country: 'US',
    city: 'Atlantic City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3643,
    longitude: -74.4229,
    postal_code: '08404'
  },
  '08405': {
    country: 'US',
    city: 'Atlantic City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.3643,
    longitude: -74.4229,
    postal_code: '08405'
  },
  '08406': {
    country: 'US',
    city: 'Ventnor City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Atlantic',
    latitude: 39.346,
    longitude: -74.4723,
    postal_code: '08406'
  },
  '07010': {
    country: 'US',
    city: 'Cliffside Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8222,
    longitude: -73.988,
    postal_code: '07010'
  },
  '07020': {
    country: 'US',
    city: 'Edgewater',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8317,
    longitude: -73.9738,
    postal_code: '07020'
  },
  '07022': {
    country: 'US',
    city: 'Fairview',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.817,
    longitude: -74,
    postal_code: '07022'
  },
  '07024': {
    country: 'US',
    city: 'Fort Lee',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8503,
    longitude: -73.9745,
    postal_code: '07024'
  },
  '07026': {
    country: 'US',
    city: 'Garfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8789,
    longitude: -74.1081,
    postal_code: '07026'
  },
  '07031': {
    country: 'US',
    city: 'North Arlington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.7898,
    longitude: -74.1343,
    postal_code: '07031'
  },
  '07057': {
    country: 'US',
    city: 'Wallington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8536,
    longitude: -74.1079,
    postal_code: '07057'
  },
  '07070': {
    country: 'US',
    city: 'Rutherford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8292,
    longitude: -74.1121,
    postal_code: '07070'
  },
  '07071': {
    country: 'US',
    city: 'Lyndhurst',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8094,
    longitude: -74.1245,
    postal_code: '07071'
  },
  '07072': {
    country: 'US',
    city: 'Carlstadt',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8403,
    longitude: -74.0925,
    postal_code: '07072'
  },
  '07073': {
    country: 'US',
    city: 'East Rutherford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8385,
    longitude: -74.1041,
    postal_code: '07073'
  },
  '07074': {
    country: 'US',
    city: 'Moonachie',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8394,
    longitude: -74.0566,
    postal_code: '07074'
  },
  '07075': {
    country: 'US',
    city: 'Wood Ridge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8493,
    longitude: -74.0878,
    postal_code: '07075'
  },
  '07401': {
    country: 'US',
    city: 'Allendale',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0327,
    longitude: -74.1342,
    postal_code: '07401'
  },
  '07407': {
    country: 'US',
    city: 'Elmwood Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9069,
    longitude: -74.1209,
    postal_code: '07407'
  },
  '07410': {
    country: 'US',
    city: 'Fair Lawn',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9343,
    longitude: -74.1166,
    postal_code: '07410'
  },
  '07417': {
    country: 'US',
    city: 'Franklin Lakes',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0081,
    longitude: -74.2113,
    postal_code: '07417'
  },
  '07423': {
    country: 'US',
    city: 'Ho Ho Kus',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0004,
    longitude: -74.1025,
    postal_code: '07423'
  },
  '07430': {
    country: 'US',
    city: 'Mahwah',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0817,
    longitude: -74.1861,
    postal_code: '07430'
  },
  '07432': {
    country: 'US',
    city: 'Midland Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9957,
    longitude: -74.1409,
    postal_code: '07432'
  },
  '07436': {
    country: 'US',
    city: 'Oakland',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0294,
    longitude: -74.2338,
    postal_code: '07436'
  },
  '07446': {
    country: 'US',
    city: 'Ramsey',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0577,
    longitude: -74.1445,
    postal_code: '07446'
  },
  '07450': {
    country: 'US',
    city: 'Ridgewood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.982,
    longitude: -74.1131,
    postal_code: '07450'
  },
  '07451': {
    country: 'US',
    city: 'Ridgewood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9793,
    longitude: -74.1165,
    postal_code: '07451'
  },
  '07452': {
    country: 'US',
    city: 'Glen Rock',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9602,
    longitude: -74.1254,
    postal_code: '07452'
  },
  '07458': {
    country: 'US',
    city: 'Saddle River',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0443,
    longitude: -74.0981,
    postal_code: '07458'
  },
  '07463': {
    country: 'US',
    city: 'Waldwick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.013,
    longitude: -74.1243,
    postal_code: '07463'
  },
  '07481': {
    country: 'US',
    city: 'Wyckoff',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9978,
    longitude: -74.166,
    postal_code: '07481'
  },
  '07495': {
    country: 'US',
    city: 'Mahwah',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.1039,
    longitude: -74.1644,
    postal_code: '07495'
  },
  '07601': {
    country: 'US',
    city: 'Hackensack',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8882,
    longitude: -74.0503,
    postal_code: '07601'
  },
  '07602': {
    country: 'US',
    city: 'Hackensack',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8859,
    longitude: -74.0435,
    postal_code: '07602'
  },
  '07603': {
    country: 'US',
    city: 'Bogota',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8744,
    longitude: -74.0281,
    postal_code: '07603'
  },
  '07604': {
    country: 'US',
    city: 'Hasbrouck Heights',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8623,
    longitude: -74.0756,
    postal_code: '07604'
  },
  '07605': {
    country: 'US',
    city: 'Leonia',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8629,
    longitude: -73.9879,
    postal_code: '07605'
  },
  '07606': {
    country: 'US',
    city: 'South Hackensack',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8634,
    longitude: -74.0456,
    postal_code: '07606'
  },
  '07607': {
    country: 'US',
    city: 'Maywood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9024,
    longitude: -74.0629,
    postal_code: '07607'
  },
  '07608': {
    country: 'US',
    city: 'Teterboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.864,
    longitude: -74.0556,
    postal_code: '07608'
  },
  '07620': {
    country: 'US',
    city: 'Alpine',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9511,
    longitude: -73.9308,
    postal_code: '07620'
  },
  '07621': {
    country: 'US',
    city: 'Bergenfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9238,
    longitude: -73.9989,
    postal_code: '07621'
  },
  '07624': {
    country: 'US',
    city: 'Closter',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9721,
    longitude: -73.959,
    postal_code: '07624'
  },
  '07626': {
    country: 'US',
    city: 'Cresskill',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9418,
    longitude: -73.9652,
    postal_code: '07626'
  },
  '07627': {
    country: 'US',
    city: 'Demarest',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9548,
    longitude: -73.9602,
    postal_code: '07627'
  },
  '07628': {
    country: 'US',
    city: 'Dumont',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9447,
    longitude: -73.9921,
    postal_code: '07628'
  },
  '07630': {
    country: 'US',
    city: 'Emerson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9755,
    longitude: -74.0285,
    postal_code: '07630'
  },
  '07631': {
    country: 'US',
    city: 'Englewood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8943,
    longitude: -73.9772,
    postal_code: '07631'
  },
  '07632': {
    country: 'US',
    city: 'Englewood Cliffs',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.882,
    longitude: -73.9544,
    postal_code: '07632'
  },
  '07640': {
    country: 'US',
    city: 'Harrington Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9918,
    longitude: -73.98,
    postal_code: '07640'
  },
  '07641': {
    country: 'US',
    city: 'Haworth',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9608,
    longitude: -73.9874,
    postal_code: '07641'
  },
  '07642': {
    country: 'US',
    city: 'Hillsdale',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0069,
    longitude: -74.0426,
    postal_code: '07642'
  },
  '07643': {
    country: 'US',
    city: 'Little Ferry',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8493,
    longitude: -74.0405,
    postal_code: '07643'
  },
  '07644': {
    country: 'US',
    city: 'Lodi',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8764,
    longitude: -74.0838,
    postal_code: '07644'
  },
  '07645': {
    country: 'US',
    city: 'Montvale',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0495,
    longitude: -74.0384,
    postal_code: '07645'
  },
  '07646': {
    country: 'US',
    city: 'New Milford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9331,
    longitude: -74.0195,
    postal_code: '07646'
  },
  '07647': {
    country: 'US',
    city: 'Northvale',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0086,
    longitude: -73.9389,
    postal_code: '07647'
  },
  '07648': {
    country: 'US',
    city: 'Norwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9952,
    longitude: -73.9582,
    postal_code: '07648'
  },
  '07649': {
    country: 'US',
    city: 'Oradell',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9535,
    longitude: -74.0335,
    postal_code: '07649'
  },
  '07650': {
    country: 'US',
    city: 'Palisades Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8462,
    longitude: -73.9954,
    postal_code: '07650'
  },
  '07652': {
    country: 'US',
    city: 'Paramus',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9477,
    longitude: -74.0672,
    postal_code: '07652'
  },
  '07653': {
    country: 'US',
    city: 'Paramus',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9481,
    longitude: -74.0832,
    postal_code: '07653'
  },
  '07656': {
    country: 'US',
    city: 'Park Ridge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0343,
    longitude: -74.0396,
    postal_code: '07656'
  },
  '07657': {
    country: 'US',
    city: 'Ridgefield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8326,
    longitude: -74.0015,
    postal_code: '07657'
  },
  '07660': {
    country: 'US',
    city: 'Ridgefield Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8562,
    longitude: -74.023,
    postal_code: '07660'
  },
  '07661': {
    country: 'US',
    city: 'River Edge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9265,
    longitude: -74.0392,
    postal_code: '07661'
  },
  '07662': {
    country: 'US',
    city: 'Rochelle Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9057,
    longitude: -74.079,
    postal_code: '07662'
  },
  '07663': {
    country: 'US',
    city: 'Saddle Brook',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9031,
    longitude: -74.0955,
    postal_code: '07663'
  },
  '07666': {
    country: 'US',
    city: 'Teaneck',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8915,
    longitude: -74.0119,
    postal_code: '07666'
  },
  '07670': {
    country: 'US',
    city: 'Tenafly',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9216,
    longitude: -73.9659,
    postal_code: '07670'
  },
  '07675': {
    country: 'US',
    city: 'Westwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0092,
    longitude: -74.0041,
    postal_code: '07675'
  },
  '07676': {
    country: 'US',
    city: 'Township Of Washington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.9883,
    longitude: -74.0635,
    postal_code: '07676'
  },
  '07677': {
    country: 'US',
    city: 'Woodcliff Lake',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 41.0234,
    longitude: -74.0603,
    postal_code: '07677'
  },
  '07699': {
    country: 'US',
    city: 'Teterboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Bergen',
    latitude: 40.8463,
    longitude: -74.0611,
    postal_code: '07699'
  },
  '08010': {
    country: 'US',
    city: 'Beverly',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.0565,
    longitude: -74.9114,
    postal_code: '08010'
  },
  '08011': {
    country: 'US',
    city: 'Birmingham',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.976,
    longitude: -74.7114,
    postal_code: '08011'
  },
  '08015': {
    country: 'US',
    city: 'Browns Mills',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.9597,
    longitude: -74.5655,
    postal_code: '08015'
  },
  '08016': {
    country: 'US',
    city: 'Burlington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.068,
    longitude: -74.8454,
    postal_code: '08016'
  },
  '08019': {
    country: 'US',
    city: 'Chatsworth',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.8019,
    longitude: -74.5256,
    postal_code: '08019'
  },
  '08022': {
    country: 'US',
    city: 'Columbus',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.0642,
    longitude: -74.6899,
    postal_code: '08022'
  },
  '08036': {
    country: 'US',
    city: 'Hainesport',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.9872,
    longitude: -74.8293,
    postal_code: '08036'
  },
  '08041': {
    country: 'US',
    city: 'Jobstown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.0387,
    longitude: -74.6873,
    postal_code: '08041'
  },
  '08042': {
    country: 'US',
    city: 'Juliustown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.0123,
    longitude: -74.6646,
    postal_code: '08042'
  },
  '08046': {
    country: 'US',
    city: 'Willingboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.029,
    longitude: -74.8835,
    postal_code: '08046'
  },
  '08048': {
    country: 'US',
    city: 'Lumberton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.9651,
    longitude: -74.8067,
    postal_code: '08048'
  },
  '08052': {
    country: 'US',
    city: 'Maple Shade',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.9511,
    longitude: -74.9946,
    postal_code: '08052'
  },
  '08053': {
    country: 'US',
    city: 'Marlton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.8845,
    longitude: -74.9067,
    postal_code: '08053'
  },
  '08054': {
    country: 'US',
    city: 'Mount Laurel',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.9478,
    longitude: -74.9036,
    postal_code: '08054'
  },
  '08055': {
    country: 'US',
    city: 'Medford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.8637,
    longitude: -74.8223,
    postal_code: '08055'
  },
  '08057': {
    country: 'US',
    city: 'Moorestown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.9683,
    longitude: -74.9533,
    postal_code: '08057'
  },
  '08060': {
    country: 'US',
    city: 'Mount Holly',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.0086,
    longitude: -74.7896,
    postal_code: '08060'
  },
  '08064': {
    country: 'US',
    city: 'New Lisbon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.9582,
    longitude: -74.6279,
    postal_code: '08064'
  },
  '08065': {
    country: 'US',
    city: 'Palmyra',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.0037,
    longitude: -75.0257,
    postal_code: '08065'
  },
  '08068': {
    country: 'US',
    city: 'Pemberton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.9712,
    longitude: -74.6676,
    postal_code: '08068'
  },
  '08073': {
    country: 'US',
    city: 'Rancocas',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.0095,
    longitude: -74.8668,
    postal_code: '08073'
  },
  '08075': {
    country: 'US',
    city: 'Riverside',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.0293,
    longitude: -74.9497,
    postal_code: '08075'
  },
  '08076': {
    country: 'US',
    city: 'Riverton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.0115,
    longitude: -75.0149,
    postal_code: '08076'
  },
  '08077': {
    country: 'US',
    city: 'Riverton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.002,
    longitude: -74.9952,
    postal_code: '08077'
  },
  '08088': {
    country: 'US',
    city: 'Vincentown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.8604,
    longitude: -74.6693,
    postal_code: '08088'
  },
  '08224': {
    country: 'US',
    city: 'New Gretna',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 39.5923,
    longitude: -74.451,
    postal_code: '08224'
  },
  '08505': {
    country: 'US',
    city: 'Bordentown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.1431,
    longitude: -74.7032,
    postal_code: '08505'
  },
  '08511': {
    country: 'US',
    city: 'Cookstown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.0481,
    longitude: -74.5595,
    postal_code: '08511'
  },
  '08515': {
    country: 'US',
    city: 'Chesterfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.1151,
    longitude: -74.6393,
    postal_code: '08515'
  },
  '08518': {
    country: 'US',
    city: 'Florence',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.118,
    longitude: -74.8055,
    postal_code: '08518'
  },
  '08554': {
    country: 'US',
    city: 'Roebling',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.1154,
    longitude: -74.7772,
    postal_code: '08554'
  },
  '08562': {
    country: 'US',
    city: 'Wrightstown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.072,
    longitude: -74.5731,
    postal_code: '08562'
  },
  '08640': {
    country: 'US',
    city: 'Joint Base Mdl',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.2171,
    longitude: -74.7429,
    postal_code: '08640'
  },
  '08641': {
    country: 'US',
    city: 'Joint Base Mdl',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Burlington',
    latitude: 40.2171,
    longitude: -74.7429,
    postal_code: '08641'
  },
  '08002': {
    country: 'US',
    city: 'Cherry Hill',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.9308,
    longitude: -75.0175,
    postal_code: '08002'
  },
  '08003': {
    country: 'US',
    city: 'Cherry Hill',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8805,
    longitude: -74.9706,
    postal_code: '08003'
  },
  '08004': {
    country: 'US',
    city: 'Atco',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.76,
    longitude: -74.8665,
    postal_code: '08004'
  },
  '08007': {
    country: 'US',
    city: 'Barrington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8651,
    longitude: -75.0564,
    postal_code: '08007'
  },
  '08009': {
    country: 'US',
    city: 'Berlin',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.7788,
    longitude: -74.9308,
    postal_code: '08009'
  },
  '08012': {
    country: 'US',
    city: 'Blackwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.7901,
    longitude: -75.0367,
    postal_code: '08012'
  },
  '08018': {
    country: 'US',
    city: 'Cedar Brook',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.7154,
    longitude: -74.9007,
    postal_code: '08018'
  },
  '08021': {
    country: 'US',
    city: 'Clementon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8036,
    longitude: -75.0058,
    postal_code: '08021'
  },
  '08026': {
    country: 'US',
    city: 'Gibbsboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8365,
    longitude: -74.971,
    postal_code: '08026'
  },
  '08029': {
    country: 'US',
    city: 'Glendora',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8404,
    longitude: -75.0697,
    postal_code: '08029'
  },
  '08030': {
    country: 'US',
    city: 'Gloucester City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8911,
    longitude: -75.117,
    postal_code: '08030'
  },
  '08031': {
    country: 'US',
    city: 'Bellmawr',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8689,
    longitude: -75.0944,
    postal_code: '08031'
  },
  '08033': {
    country: 'US',
    city: 'Haddonfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8954,
    longitude: -75.0417,
    postal_code: '08033'
  },
  '08034': {
    country: 'US',
    city: 'Cherry Hill',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.9074,
    longitude: -75.0008,
    postal_code: '08034'
  },
  '08035': {
    country: 'US',
    city: 'Haddon Heights',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8788,
    longitude: -75.0664,
    postal_code: '08035'
  },
  '08043': {
    country: 'US',
    city: 'Voorhees',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8504,
    longitude: -74.9646,
    postal_code: '08043'
  },
  '08045': {
    country: 'US',
    city: 'Lawnside',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8676,
    longitude: -75.0317,
    postal_code: '08045'
  },
  '08049': {
    country: 'US',
    city: 'Magnolia',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8538,
    longitude: -75.0393,
    postal_code: '08049'
  },
  '08059': {
    country: 'US',
    city: 'Mount Ephraim',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8827,
    longitude: -75.0929,
    postal_code: '08059'
  },
  '08078': {
    country: 'US',
    city: 'Runnemede',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8508,
    longitude: -75.0742,
    postal_code: '08078'
  },
  '08081': {
    country: 'US',
    city: 'Sicklerville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.7354,
    longitude: -74.9864,
    postal_code: '08081'
  },
  '08083': {
    country: 'US',
    city: 'Somerdale',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.84,
    longitude: -75.0309,
    postal_code: '08083'
  },
  '08084': {
    country: 'US',
    city: 'Stratford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8288,
    longitude: -75.0147,
    postal_code: '08084'
  },
  '08089': {
    country: 'US',
    city: 'Waterford Works',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.7215,
    longitude: -74.8609,
    postal_code: '08089'
  },
  '08091': {
    country: 'US',
    city: 'West Berlin',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8051,
    longitude: -74.9255,
    postal_code: '08091'
  },
  '08095': {
    country: 'US',
    city: 'Winslow',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.6549,
    longitude: -74.8685,
    postal_code: '08095'
  },
  '08099': {
    country: 'US',
    city: 'Bellmawr',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.8676,
    longitude: -75.0946,
    postal_code: '08099'
  },
  '08101': {
    country: 'US',
    city: 'Camden',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.9259,
    longitude: -75.1196,
    postal_code: '08101'
  },
  '08102': {
    country: 'US',
    city: 'Camden',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.9512,
    longitude: -75.1186,
    postal_code: '08102'
  },
  '08103': {
    country: 'US',
    city: 'Camden',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.9351,
    longitude: -75.1117,
    postal_code: '08103'
  },
  '08104': {
    country: 'US',
    city: 'Camden',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.9186,
    longitude: -75.1078,
    postal_code: '08104'
  },
  '08105': {
    country: 'US',
    city: 'Camden',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.9484,
    longitude: -75.0864,
    postal_code: '08105'
  },
  '08106': {
    country: 'US',
    city: 'Audubon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.891,
    longitude: -75.0724,
    postal_code: '08106'
  },
  '08107': {
    country: 'US',
    city: 'Oaklyn',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.908,
    longitude: -75.0849,
    postal_code: '08107'
  },
  '08108': {
    country: 'US',
    city: 'Collingswood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.9157,
    longitude: -75.0634,
    postal_code: '08108'
  },
  '08109': {
    country: 'US',
    city: 'Merchantville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.9519,
    longitude: -75.0482,
    postal_code: '08109'
  },
  '08110': {
    country: 'US',
    city: 'Pennsauken',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Camden',
    latitude: 39.9723,
    longitude: -75.0607,
    postal_code: '08110'
  },
  '08202': {
    country: 'US',
    city: 'Avalon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.0951,
    longitude: -74.7262,
    postal_code: '08202'
  },
  '08204': {
    country: 'US',
    city: 'Cape May',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 38.9711,
    longitude: -74.9214,
    postal_code: '08204'
  },
  '08210': {
    country: 'US',
    city: 'Cape May Court House',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.1378,
    longitude: -74.7806,
    postal_code: '08210'
  },
  '08212': {
    country: 'US',
    city: 'Cape May Point',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 38.9372,
    longitude: -74.9654,
    postal_code: '08212'
  },
  '08214': {
    country: 'US',
    city: 'Dennisville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.1932,
    longitude: -74.8252,
    postal_code: '08214'
  },
  '08218': {
    country: 'US',
    city: 'Goshen',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.1415,
    longitude: -74.8529,
    postal_code: '08218'
  },
  '08219': {
    country: 'US',
    city: 'Green Creek',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.0462,
    longitude: -74.9013,
    postal_code: '08219'
  },
  '08223': {
    country: 'US',
    city: 'Marmora',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.2586,
    longitude: -74.6593,
    postal_code: '08223'
  },
  '08226': {
    country: 'US',
    city: 'Ocean City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.2709,
    longitude: -74.5875,
    postal_code: '08226'
  },
  '08230': {
    country: 'US',
    city: 'Ocean View',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.2154,
    longitude: -74.7075,
    postal_code: '08230'
  },
  '08242': {
    country: 'US',
    city: 'Rio Grande',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.0196,
    longitude: -74.8756,
    postal_code: '08242'
  },
  '08243': {
    country: 'US',
    city: 'Sea Isle City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.154,
    longitude: -74.7005,
    postal_code: '08243'
  },
  '08245': {
    country: 'US',
    city: 'South Dennis',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.1,
    longitude: -74.8487,
    postal_code: '08245'
  },
  '08246': {
    country: 'US',
    city: 'South Seaville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.179,
    longitude: -74.7599,
    postal_code: '08246'
  },
  '08247': {
    country: 'US',
    city: 'Stone Harbor',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.0533,
    longitude: -74.762,
    postal_code: '08247'
  },
  '08248': {
    country: 'US',
    city: 'Strathmere',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.1992,
    longitude: -74.6554,
    postal_code: '08248'
  },
  '08250': {
    country: 'US',
    city: 'Tuckahoe',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.2901,
    longitude: -74.7538,
    postal_code: '08250'
  },
  '08251': {
    country: 'US',
    city: 'Villas',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.0219,
    longitude: -74.9354,
    postal_code: '08251'
  },
  '08252': {
    country: 'US',
    city: 'Whitesboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.0421,
    longitude: -74.8618,
    postal_code: '08252'
  },
  '08260': {
    country: 'US',
    city: 'Wildwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 38.9949,
    longitude: -74.838,
    postal_code: '08260'
  },
  '08270': {
    country: 'US',
    city: 'Woodbine',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cape May',
    latitude: 39.2716,
    longitude: -74.7968,
    postal_code: '08270'
  },
  '08302': {
    country: 'US',
    city: 'Bridgeton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.3762,
    longitude: -75.1617,
    postal_code: '08302'
  },
  '08311': {
    country: 'US',
    city: 'Cedarville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.337,
    longitude: -75.1994,
    postal_code: '08311'
  },
  '08313': {
    country: 'US',
    city: 'Deerfield Street',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.5293,
    longitude: -75.2249,
    postal_code: '08313'
  },
  '08314': {
    country: 'US',
    city: 'Delmont',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.2023,
    longitude: -74.9705,
    postal_code: '08314'
  },
  '08315': {
    country: 'US',
    city: 'Dividing Creek',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.2732,
    longitude: -75.095,
    postal_code: '08315'
  },
  '08316': {
    country: 'US',
    city: 'Dorchester',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.2679,
    longitude: -74.9791,
    postal_code: '08316'
  },
  '08320': {
    country: 'US',
    city: 'Fairton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.3799,
    longitude: -75.2217,
    postal_code: '08320'
  },
  '08321': {
    country: 'US',
    city: 'Fortescue',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.2189,
    longitude: -75.1403,
    postal_code: '08321'
  },
  '08323': {
    country: 'US',
    city: 'Greenwich',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.4055,
    longitude: -75.3209,
    postal_code: '08323'
  },
  '08324': {
    country: 'US',
    city: 'Heislerville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.224,
    longitude: -74.9942,
    postal_code: '08324'
  },
  '08327': {
    country: 'US',
    city: 'Leesburg',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.2568,
    longitude: -74.9874,
    postal_code: '08327'
  },
  '08329': {
    country: 'US',
    city: 'Mauricetown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.2853,
    longitude: -74.9983,
    postal_code: '08329'
  },
  '08332': {
    country: 'US',
    city: 'Millville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.3673,
    longitude: -75.0293,
    postal_code: '08332'
  },
  '08345': {
    country: 'US',
    city: 'Newport',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.2832,
    longitude: -75.1716,
    postal_code: '08345'
  },
  '08348': {
    country: 'US',
    city: 'Port Elizabeth',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.3131,
    longitude: -74.9807,
    postal_code: '08348'
  },
  '08349': {
    country: 'US',
    city: 'Port Norris',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.2563,
    longitude: -75.0506,
    postal_code: '08349'
  },
  '08352': {
    country: 'US',
    city: 'Rosenhayn',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.4782,
    longitude: -75.1313,
    postal_code: '08352'
  },
  '08353': {
    country: 'US',
    city: 'Shiloh',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.4594,
    longitude: -75.297,
    postal_code: '08353'
  },
  '08360': {
    country: 'US',
    city: 'Vineland',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.4818,
    longitude: -75.0091,
    postal_code: '08360'
  },
  '08361': {
    country: 'US',
    city: 'Vineland',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.4655,
    longitude: -74.9653,
    postal_code: '08361'
  },
  '08362': {
    country: 'US',
    city: 'Vineland',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Cumberland',
    latitude: 39.4862,
    longitude: -75.0257,
    postal_code: '08362'
  },
  '07003': {
    country: 'US',
    city: 'Bloomfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.8035,
    longitude: -74.1891,
    postal_code: '07003'
  },
  '07004': {
    country: 'US',
    city: 'Fairfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.8822,
    longitude: -74.296,
    postal_code: '07004'
  },
  '07006': {
    country: 'US',
    city: 'Caldwell',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.8545,
    longitude: -74.2789,
    postal_code: '07006'
  },
  '07007': {
    country: 'US',
    city: 'Caldwell',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.8398,
    longitude: -74.2765,
    postal_code: '07007'
  },
  '07009': {
    country: 'US',
    city: 'Cedar Grove',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.8534,
    longitude: -74.2297,
    postal_code: '07009'
  },
  '07017': {
    country: 'US',
    city: 'East Orange',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7696,
    longitude: -74.2077,
    postal_code: '07017'
  },
  '07018': {
    country: 'US',
    city: 'East Orange',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7558,
    longitude: -74.2198,
    postal_code: '07018'
  },
  '07019': {
    country: 'US',
    city: 'East Orange',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7673,
    longitude: -74.2049,
    postal_code: '07019'
  },
  '07021': {
    country: 'US',
    city: 'Essex Fells',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.8279,
    longitude: -74.2797,
    postal_code: '07021'
  },
  '07028': {
    country: 'US',
    city: 'Glen Ridge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.804,
    longitude: -74.2055,
    postal_code: '07028'
  },
  '07039': {
    country: 'US',
    city: 'Livingston',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7896,
    longitude: -74.3202,
    postal_code: '07039'
  },
  '07040': {
    country: 'US',
    city: 'Maplewood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7279,
    longitude: -74.2656,
    postal_code: '07040'
  },
  '07041': {
    country: 'US',
    city: 'Millburn',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7228,
    longitude: -74.3015,
    postal_code: '07041'
  },
  '07042': {
    country: 'US',
    city: 'Montclair',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.8131,
    longitude: -74.2165,
    postal_code: '07042'
  },
  '07043': {
    country: 'US',
    city: 'Montclair',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.843,
    longitude: -74.2011,
    postal_code: '07043'
  },
  '07044': {
    country: 'US',
    city: 'Verona',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.8319,
    longitude: -74.2428,
    postal_code: '07044'
  },
  '07050': {
    country: 'US',
    city: 'Orange',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7692,
    longitude: -74.2355,
    postal_code: '07050'
  },
  '07051': {
    country: 'US',
    city: 'Orange',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7918,
    longitude: -74.2452,
    postal_code: '07051'
  },
  '07052': {
    country: 'US',
    city: 'West Orange',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7859,
    longitude: -74.2568,
    postal_code: '07052'
  },
  '07068': {
    country: 'US',
    city: 'Roseland',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.8203,
    longitude: -74.3047,
    postal_code: '07068'
  },
  '07078': {
    country: 'US',
    city: 'Short Hills',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7368,
    longitude: -74.3271,
    postal_code: '07078'
  },
  '07079': {
    country: 'US',
    city: 'South Orange',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7465,
    longitude: -74.2575,
    postal_code: '07079'
  },
  '07101': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7361,
    longitude: -74.2251,
    postal_code: '07101'
  },
  '07102': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.732,
    longitude: -74.1765,
    postal_code: '07102'
  },
  '07103': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.737,
    longitude: -74.1964,
    postal_code: '07103'
  },
  '07104': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7664,
    longitude: -74.1695,
    postal_code: '07104'
  },
  '07105': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7271,
    longitude: -74.1563,
    postal_code: '07105'
  },
  '07106': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7415,
    longitude: -74.233,
    postal_code: '07106'
  },
  '07107': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7607,
    longitude: -74.1882,
    postal_code: '07107'
  },
  '07108': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7236,
    longitude: -74.2015,
    postal_code: '07108'
  },
  '07109': {
    country: 'US',
    city: 'Belleville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7946,
    longitude: -74.1631,
    postal_code: '07109'
  },
  '07110': {
    country: 'US',
    city: 'Nutley',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.8185,
    longitude: -74.1589,
    postal_code: '07110'
  },
  '07111': {
    country: 'US',
    city: 'Irvington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7261,
    longitude: -74.2313,
    postal_code: '07111'
  },
  '07112': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7107,
    longitude: -74.2131,
    postal_code: '07112'
  },
  '07114': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7082,
    longitude: -74.1891,
    postal_code: '07114'
  },
  '07175': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7357,
    longitude: -74.1724,
    postal_code: '07175'
  },
  '07184': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7918,
    longitude: -74.2452,
    postal_code: '07184'
  },
  '07188': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7357,
    longitude: -74.1724,
    postal_code: '07188'
  },
  '07189': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7918,
    longitude: -74.2452,
    postal_code: '07189'
  },
  '07191': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7918,
    longitude: -74.2452,
    postal_code: '07191'
  },
  '07192': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7357,
    longitude: -74.1724,
    postal_code: '07192'
  },
  '07193': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7357,
    longitude: -74.1724,
    postal_code: '07193'
  },
  '07195': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7357,
    longitude: -74.1724,
    postal_code: '07195'
  },
  '07198': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7918,
    longitude: -74.2452,
    postal_code: '07198'
  },
  '07199': {
    country: 'US',
    city: 'Newark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Essex',
    latitude: 40.7357,
    longitude: -74.1724,
    postal_code: '07199'
  },
  '08014': {
    country: 'US',
    city: 'Bridgeport',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.8016,
    longitude: -75.3478,
    postal_code: '08014'
  },
  '08020': {
    country: 'US',
    city: 'Clarksboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7992,
    longitude: -75.2237,
    postal_code: '08020'
  },
  '08025': {
    country: 'US',
    city: 'Ewan',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7015,
    longitude: -75.1629,
    postal_code: '08025'
  },
  '08027': {
    country: 'US',
    city: 'Gibbstown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.8231,
    longitude: -75.2751,
    postal_code: '08027'
  },
  '08028': {
    country: 'US',
    city: 'Glassboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7068,
    longitude: -75.1172,
    postal_code: '08028'
  },
  '08032': {
    country: 'US',
    city: 'Grenloch',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7788,
    longitude: -75.0601,
    postal_code: '08032'
  },
  '08039': {
    country: 'US',
    city: 'Harrisonville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.6931,
    longitude: -75.2711,
    postal_code: '08039'
  },
  '08051': {
    country: 'US',
    city: 'Mantua',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.787,
    longitude: -75.1785,
    postal_code: '08051'
  },
  '08056': {
    country: 'US',
    city: 'Mickleton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7857,
    longitude: -75.2498,
    postal_code: '08056'
  },
  '08061': {
    country: 'US',
    city: 'Mount Royal',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.8097,
    longitude: -75.2082,
    postal_code: '08061'
  },
  '08062': {
    country: 'US',
    city: 'Mullica Hill',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7252,
    longitude: -75.2065,
    postal_code: '08062'
  },
  '08063': {
    country: 'US',
    city: 'National Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.8664,
    longitude: -75.1794,
    postal_code: '08063'
  },
  '08066': {
    country: 'US',
    city: 'Paulsboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.8312,
    longitude: -75.2242,
    postal_code: '08066'
  },
  '08071': {
    country: 'US',
    city: 'Pitman',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7312,
    longitude: -75.1297,
    postal_code: '08071'
  },
  '08074': {
    country: 'US',
    city: 'Richwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7206,
    longitude: -75.1681,
    postal_code: '08074'
  },
  '08080': {
    country: 'US',
    city: 'Sewell',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7473,
    longitude: -75.0899,
    postal_code: '08080'
  },
  '08085': {
    country: 'US',
    city: 'Swedesboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7529,
    longitude: -75.3362,
    postal_code: '08085'
  },
  '08086': {
    country: 'US',
    city: 'Thorofare',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.8457,
    longitude: -75.1943,
    postal_code: '08086'
  },
  '08090': {
    country: 'US',
    city: 'Wenonah',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7993,
    longitude: -75.1536,
    postal_code: '08090'
  },
  '08093': {
    country: 'US',
    city: 'Westville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.8605,
    longitude: -75.1323,
    postal_code: '08093'
  },
  '08094': {
    country: 'US',
    city: 'Williamstown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.665,
    longitude: -74.971,
    postal_code: '08094'
  },
  '08096': {
    country: 'US',
    city: 'Deptford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.7539,
    longitude: -74.996,
    postal_code: '08096'
  },
  '08097': {
    country: 'US',
    city: 'Woodbury Heights',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.8142,
    longitude: -75.153,
    postal_code: '08097'
  },
  '08312': {
    country: 'US',
    city: 'Clayton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.659,
    longitude: -75.0942,
    postal_code: '08312'
  },
  '08322': {
    country: 'US',
    city: 'Franklinville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.6156,
    longitude: -75.0409,
    postal_code: '08322'
  },
  '08328': {
    country: 'US',
    city: 'Malaga',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.5755,
    longitude: -75.0582,
    postal_code: '08328'
  },
  '08343': {
    country: 'US',
    city: 'Monroeville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.6442,
    longitude: -75.1568,
    postal_code: '08343'
  },
  '08344': {
    country: 'US',
    city: 'Newfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Gloucester',
    latitude: 39.5553,
    longitude: -75.0276,
    postal_code: '08344'
  },
  '07002': {
    country: 'US',
    city: 'Bayonne',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.6664,
    longitude: -74.1192,
    postal_code: '07002'
  },
  '07029': {
    country: 'US',
    city: 'Harrison',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7445,
    longitude: -74.1508,
    postal_code: '07029'
  },
  '07030': {
    country: 'US',
    city: 'Hoboken',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7445,
    longitude: -74.0329,
    postal_code: '07030'
  },
  '07032': {
    country: 'US',
    city: 'Kearny',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7647,
    longitude: -74.1471,
    postal_code: '07032'
  },
  '07047': {
    country: 'US',
    city: 'North Bergen',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7939,
    longitude: -74.0258,
    postal_code: '07047'
  },
  '07086': {
    country: 'US',
    city: 'Weehawken',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7681,
    longitude: -74.0208,
    postal_code: '07086'
  },
  '07087': {
    country: 'US',
    city: 'Union City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7674,
    longitude: -74.0323,
    postal_code: '07087'
  },
  '07093': {
    country: 'US',
    city: 'West New York',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7888,
    longitude: -74.0115,
    postal_code: '07093'
  },
  '07094': {
    country: 'US',
    city: 'Secaucus',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.791,
    longitude: -74.0634,
    postal_code: '07094'
  },
  '07096': {
    country: 'US',
    city: 'Secaucus',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7328,
    longitude: -74.0755,
    postal_code: '07096'
  },
  '07097': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7328,
    longitude: -74.0755,
    postal_code: '07097'
  },
  '07099': {
    country: 'US',
    city: 'Kearny',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7328,
    longitude: -74.0755,
    postal_code: '07099'
  },
  '07302': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7221,
    longitude: -74.0469,
    postal_code: '07302'
  },
  '07303': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7328,
    longitude: -74.0755,
    postal_code: '07303'
  },
  '07304': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.718,
    longitude: -74.0754,
    postal_code: '07304'
  },
  '07305': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.702,
    longitude: -74.089,
    postal_code: '07305'
  },
  '07306': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7321,
    longitude: -74.066,
    postal_code: '07306'
  },
  '07307': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7482,
    longitude: -74.0498,
    postal_code: '07307'
  },
  '07308': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7328,
    longitude: -74.0755,
    postal_code: '07308'
  },
  '07310': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7324,
    longitude: -74.0431,
    postal_code: '07310'
  },
  '07311': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7323,
    longitude: -74.0754,
    postal_code: '07311'
  },
  '07395': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7279,
    longitude: -74.078,
    postal_code: '07395'
  },
  '07399': {
    country: 'US',
    city: 'Jersey City',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hudson',
    latitude: 40.7323,
    longitude: -74.0754,
    postal_code: '07399'
  },
  '07830': {
    country: 'US',
    city: 'Califon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.7162,
    longitude: -74.8152,
    postal_code: '07830'
  },
  '07979': {
    country: 'US',
    city: 'Pottersville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.7026,
    longitude: -74.7276,
    postal_code: '07979'
  },
  '08530': {
    country: 'US',
    city: 'Lambertville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.3731,
    longitude: -74.9266,
    postal_code: '08530'
  },
  '08551': {
    country: 'US',
    city: 'Ringoes',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.4459,
    longitude: -74.8288,
    postal_code: '08551'
  },
  '08556': {
    country: 'US',
    city: 'Rosemont',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.42,
    longitude: -74.9886,
    postal_code: '08556'
  },
  '08557': {
    country: 'US',
    city: 'Sergeantsville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.4459,
    longitude: -74.9435,
    postal_code: '08557'
  },
  '08559': {
    country: 'US',
    city: 'Stockton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.4397,
    longitude: -74.9554,
    postal_code: '08559'
  },
  '08801': {
    country: 'US',
    city: 'Annandale',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.6287,
    longitude: -74.8855,
    postal_code: '08801'
  },
  '08803': {
    country: 'US',
    city: 'Baptistown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.5218,
    longitude: -75.006,
    postal_code: '08803'
  },
  '08804': {
    country: 'US',
    city: 'Bloomsbury',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.6437,
    longitude: -75.0966,
    postal_code: '08804'
  },
  '08809': {
    country: 'US',
    city: 'Clinton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.6412,
    longitude: -74.9088,
    postal_code: '08809'
  },
  '08822': {
    country: 'US',
    city: 'Flemington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.518,
    longitude: -74.8453,
    postal_code: '08822'
  },
  '08825': {
    country: 'US',
    city: 'Frenchtown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.5208,
    longitude: -75.0325,
    postal_code: '08825'
  },
  '08826': {
    country: 'US',
    city: 'Glen Gardner',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.7134,
    longitude: -74.9162,
    postal_code: '08826'
  },
  '08827': {
    country: 'US',
    city: 'Hampton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.6774,
    longitude: -74.9622,
    postal_code: '08827'
  },
  '08829': {
    country: 'US',
    city: 'High Bridge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.6684,
    longitude: -74.8937,
    postal_code: '08829'
  },
  '08833': {
    country: 'US',
    city: 'Lebanon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.6466,
    longitude: -74.829,
    postal_code: '08833'
  },
  '08834': {
    country: 'US',
    city: 'Little York',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.5637,
    longitude: -74.9494,
    postal_code: '08834'
  },
  '08848': {
    country: 'US',
    city: 'Milford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.5929,
    longitude: -75.1025,
    postal_code: '08848'
  },
  '08858': {
    country: 'US',
    city: 'Oldwick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.6726,
    longitude: -74.7474,
    postal_code: '08858'
  },
  '08867': {
    country: 'US',
    city: 'Pittstown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.5992,
    longitude: -74.9576,
    postal_code: '08867'
  },
  '08868': {
    country: 'US',
    city: 'Quakertown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.5655,
    longitude: -74.9389,
    postal_code: '08868'
  },
  '08870': {
    country: 'US',
    city: 'Readington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.5687,
    longitude: -74.7377,
    postal_code: '08870'
  },
  '08885': {
    country: 'US',
    city: 'Stanton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.5764,
    longitude: -74.8311,
    postal_code: '08885'
  },
  '08887': {
    country: 'US',
    city: 'Three Bridges',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.5206,
    longitude: -74.7946,
    postal_code: '08887'
  },
  '08888': {
    country: 'US',
    city: 'Whitehouse',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.6194,
    longitude: -74.7406,
    postal_code: '08888'
  },
  '08889': {
    country: 'US',
    city: 'Whitehouse Station',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Hunterdon',
    latitude: 40.6156,
    longitude: -74.7724,
    postal_code: '08889'
  },
  '08520': {
    country: 'US',
    city: 'Hightstown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2669,
    longitude: -74.525,
    postal_code: '08520'
  },
  '08525': {
    country: 'US',
    city: 'Hopewell',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.3902,
    longitude: -74.771,
    postal_code: '08525'
  },
  '08534': {
    country: 'US',
    city: 'Pennington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.3339,
    longitude: -74.7944,
    postal_code: '08534'
  },
  '08540': {
    country: 'US',
    city: 'Princeton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.3666,
    longitude: -74.6408,
    postal_code: '08540'
  },
  '08541': {
    country: 'US',
    city: 'Princeton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.3487,
    longitude: -74.659,
    postal_code: '08541'
  },
  '08542': {
    country: 'US',
    city: 'Princeton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.3535,
    longitude: -74.6594,
    postal_code: '08542'
  },
  '08543': {
    country: 'US',
    city: 'Princeton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08543'
  },
  '08544': {
    country: 'US',
    city: 'Princeton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.3492,
    longitude: -74.6528,
    postal_code: '08544'
  },
  '08550': {
    country: 'US',
    city: 'Princeton Junction',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2669,
    longitude: -74.6511,
    postal_code: '08550'
  },
  '08560': {
    country: 'US',
    city: 'Titusville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.3077,
    longitude: -74.8655,
    postal_code: '08560'
  },
  '08561': {
    country: 'US',
    city: 'Windsor',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2423,
    longitude: -74.5787,
    postal_code: '08561'
  },
  '08601': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08601'
  },
  '08602': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08602'
  },
  '08603': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08603'
  },
  '08604': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08604'
  },
  '08605': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08605'
  },
  '08606': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08606'
  },
  '08607': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08607'
  },
  '08608': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2204,
    longitude: -74.7622,
    postal_code: '08608'
  },
  '08609': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2248,
    longitude: -74.741,
    postal_code: '08609'
  },
  '08610': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2016,
    longitude: -74.705,
    postal_code: '08610'
  },
  '08611': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2171,
    longitude: -74.7429,
    postal_code: '08611'
  },
  '08618': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2377,
    longitude: -74.7821,
    postal_code: '08618'
  },
  '08619': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2418,
    longitude: -74.6962,
    postal_code: '08619'
  },
  '08620': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.167,
    longitude: -74.6488,
    postal_code: '08620'
  },
  '08625': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08625'
  },
  '08628': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2655,
    longitude: -74.8168,
    postal_code: '08628'
  },
  '08629': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2196,
    longitude: -74.7334,
    postal_code: '08629'
  },
  '08638': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.251,
    longitude: -74.7627,
    postal_code: '08638'
  },
  '08644': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2171,
    longitude: -74.7429,
    postal_code: '08644'
  },
  '08645': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08645'
  },
  '08646': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08646'
  },
  '08647': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08647'
  },
  '08648': {
    country: 'US',
    city: 'Lawrence Township',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2171,
    longitude: -74.7429,
    postal_code: '08648'
  },
  '08650': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08650'
  },
  '08666': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08666'
  },
  '08690': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2336,
    longitude: -74.6576,
    postal_code: '08690'
  },
  '08691': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2197,
    longitude: -74.5939,
    postal_code: '08691'
  },
  '08695': {
    country: 'US',
    city: 'Trenton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Mercer',
    latitude: 40.2805,
    longitude: -74.712,
    postal_code: '08695'
  },
  '07001': {
    country: 'US',
    city: 'Avenel',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5826,
    longitude: -74.2785,
    postal_code: '07001'
  },
  '07008': {
    country: 'US',
    city: 'Carteret',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5823,
    longitude: -74.2313,
    postal_code: '07008'
  },
  '07064': {
    country: 'US',
    city: 'Port Reading',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5709,
    longitude: -74.2466,
    postal_code: '07064'
  },
  '07067': {
    country: 'US',
    city: 'Colonia',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5937,
    longitude: -74.3164,
    postal_code: '07067'
  },
  '07077': {
    country: 'US',
    city: 'Sewaren',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5542,
    longitude: -74.2607,
    postal_code: '07077'
  },
  '07080': {
    country: 'US',
    city: 'South Plainfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5839,
    longitude: -74.4147,
    postal_code: '07080'
  },
  '07095': {
    country: 'US',
    city: 'Woodbridge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.556,
    longitude: -74.2845,
    postal_code: '07095'
  },
  '08512': {
    country: 'US',
    city: 'Cranbury',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.3039,
    longitude: -74.5065,
    postal_code: '08512'
  },
  '08536': {
    country: 'US',
    city: 'Plainsboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.3324,
    longitude: -74.5688,
    postal_code: '08536'
  },
  '08810': {
    country: 'US',
    city: 'Dayton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.3825,
    longitude: -74.5111,
    postal_code: '08810'
  },
  '08812': {
    country: 'US',
    city: 'Dunellen',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5897,
    longitude: -74.4639,
    postal_code: '08812'
  },
  '08816': {
    country: 'US',
    city: 'East Brunswick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.4284,
    longitude: -74.4064,
    postal_code: '08816'
  },
  '08817': {
    country: 'US',
    city: 'Edison',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5171,
    longitude: -74.3973,
    postal_code: '08817'
  },
  '08818': {
    country: 'US',
    city: 'Edison',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.43,
    longitude: -74.4173,
    postal_code: '08818'
  },
  '08820': {
    country: 'US',
    city: 'Edison',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.578,
    longitude: -74.3589,
    postal_code: '08820'
  },
  '08824': {
    country: 'US',
    city: 'Kendall Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.4208,
    longitude: -74.5529,
    postal_code: '08824'
  },
  '08828': {
    country: 'US',
    city: 'Helmetta',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.3777,
    longitude: -74.4204,
    postal_code: '08828'
  },
  '08830': {
    country: 'US',
    city: 'Iselin',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5716,
    longitude: -74.3167,
    postal_code: '08830'
  },
  '08831': {
    country: 'US',
    city: 'Monroe Township',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.3192,
    longitude: -74.4285,
    postal_code: '08831'
  },
  '08832': {
    country: 'US',
    city: 'Keasbey',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5192,
    longitude: -74.3021,
    postal_code: '08832'
  },
  '08837': {
    country: 'US',
    city: 'Edison',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5325,
    longitude: -74.3375,
    postal_code: '08837'
  },
  '08840': {
    country: 'US',
    city: 'Metuchen',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5449,
    longitude: -74.3517,
    postal_code: '08840'
  },
  '08846': {
    country: 'US',
    city: 'Middlesex',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5759,
    longitude: -74.5008,
    postal_code: '08846'
  },
  '08850': {
    country: 'US',
    city: 'Milltown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.4493,
    longitude: -74.439,
    postal_code: '08850'
  },
  '08852': {
    country: 'US',
    city: 'Monmouth Junction',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.3869,
    longitude: -74.5558,
    postal_code: '08852'
  },
  '08854': {
    country: 'US',
    city: 'Piscataway',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5515,
    longitude: -74.459,
    postal_code: '08854'
  },
  '08855': {
    country: 'US',
    city: 'Piscataway',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.43,
    longitude: -74.4173,
    postal_code: '08855'
  },
  '08857': {
    country: 'US',
    city: 'Old Bridge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.398,
    longitude: -74.3236,
    postal_code: '08857'
  },
  '08859': {
    country: 'US',
    city: 'Parlin',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.4587,
    longitude: -74.305,
    postal_code: '08859'
  },
  '08861': {
    country: 'US',
    city: 'Perth Amboy',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5176,
    longitude: -74.2754,
    postal_code: '08861'
  },
  '08862': {
    country: 'US',
    city: 'Perth Amboy',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.43,
    longitude: -74.4173,
    postal_code: '08862'
  },
  '08863': {
    country: 'US',
    city: 'Fords',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5393,
    longitude: -74.3117,
    postal_code: '08863'
  },
  '08871': {
    country: 'US',
    city: 'Sayreville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.43,
    longitude: -74.4173,
    postal_code: '08871'
  },
  '08872': {
    country: 'US',
    city: 'Sayreville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.46,
    longitude: -74.3478,
    postal_code: '08872'
  },
  '08879': {
    country: 'US',
    city: 'South Amboy',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.464,
    longitude: -74.2742,
    postal_code: '08879'
  },
  '08882': {
    country: 'US',
    city: 'South River',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.4444,
    longitude: -74.3801,
    postal_code: '08882'
  },
  '08884': {
    country: 'US',
    city: 'Spotswood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.3847,
    longitude: -74.3894,
    postal_code: '08884'
  },
  '08899': {
    country: 'US',
    city: 'Edison',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5203,
    longitude: -74.4205,
    postal_code: '08899'
  },
  '08901': {
    country: 'US',
    city: 'New Brunswick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.4891,
    longitude: -74.4482,
    postal_code: '08901'
  },
  '08902': {
    country: 'US',
    city: 'North Brunswick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.4538,
    longitude: -74.4823,
    postal_code: '08902'
  },
  '08903': {
    country: 'US',
    city: 'New Brunswick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.5139,
    longitude: -74.4451,
    postal_code: '08903'
  },
  '08904': {
    country: 'US',
    city: 'Highland Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.4991,
    longitude: -74.4266,
    postal_code: '08904'
  },
  '08906': {
    country: 'US',
    city: 'New Brunswick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.4862,
    longitude: -74.4518,
    postal_code: '08906'
  },
  '08933': {
    country: 'US',
    city: 'New Brunswick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.4862,
    longitude: -74.4518,
    postal_code: '08933'
  },
  '08989': {
    country: 'US',
    city: 'New Brunswick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Middlesex',
    latitude: 40.43,
    longitude: -74.4173,
    postal_code: '08989'
  },
  '07701': {
    country: 'US',
    city: 'Red Bank',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3584,
    longitude: -74.0681,
    postal_code: '07701'
  },
  '07702': {
    country: 'US',
    city: 'Shrewsbury',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3282,
    longitude: -74.0589,
    postal_code: '07702'
  },
  '07703': {
    country: 'US',
    city: 'Fort Monmouth',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3177,
    longitude: -74.039,
    postal_code: '07703'
  },
  '07704': {
    country: 'US',
    city: 'Fair Haven',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3599,
    longitude: -74.0389,
    postal_code: '07704'
  },
  '07710': {
    country: 'US',
    city: 'Adelphia',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2182,
    longitude: -74.2563,
    postal_code: '07710'
  },
  '07711': {
    country: 'US',
    city: 'Allenhurst',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2367,
    longitude: -74.0067,
    postal_code: '07711'
  },
  '07712': {
    country: 'US',
    city: 'Asbury Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2507,
    longitude: -74.0486,
    postal_code: '07712'
  },
  '07715': {
    country: 'US',
    city: 'Belmar',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1784,
    longitude: -74.0218,
    postal_code: '07715'
  },
  '07716': {
    country: 'US',
    city: 'Atlantic Highlands',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4015,
    longitude: -74.0309,
    postal_code: '07716'
  },
  '07717': {
    country: 'US',
    city: 'Avon By The Sea',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1918,
    longitude: -74.0167,
    postal_code: '07717'
  },
  '07718': {
    country: 'US',
    city: 'Belford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4173,
    longitude: -74.0889,
    postal_code: '07718'
  },
  '07719': {
    country: 'US',
    city: 'Belmar',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1688,
    longitude: -74.072,
    postal_code: '07719'
  },
  '07720': {
    country: 'US',
    city: 'Bradley Beach',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2023,
    longitude: -74.0132,
    postal_code: '07720'
  },
  '07721': {
    country: 'US',
    city: 'Cliffwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4353,
    longitude: -74.2358,
    postal_code: '07721'
  },
  '07722': {
    country: 'US',
    city: 'Colts Neck',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3012,
    longitude: -74.178,
    postal_code: '07722'
  },
  '07723': {
    country: 'US',
    city: 'Deal',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2506,
    longitude: -74.002,
    postal_code: '07723'
  },
  '07724': {
    country: 'US',
    city: 'Eatontown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3028,
    longitude: -74.0698,
    postal_code: '07724'
  },
  '07726': {
    country: 'US',
    city: 'Englishtown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2825,
    longitude: -74.3424,
    postal_code: '07726'
  },
  '07727': {
    country: 'US',
    city: 'Farmingdale',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2043,
    longitude: -74.1779,
    postal_code: '07727'
  },
  '07728': {
    country: 'US',
    city: 'Freehold',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2458,
    longitude: -74.2768,
    postal_code: '07728'
  },
  '07730': {
    country: 'US',
    city: 'Hazlet',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4226,
    longitude: -74.1799,
    postal_code: '07730'
  },
  '07731': {
    country: 'US',
    city: 'Howell',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1481,
    longitude: -74.2137,
    postal_code: '07731'
  },
  '07732': {
    country: 'US',
    city: 'Highlands',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4037,
    longitude: -73.9915,
    postal_code: '07732'
  },
  '07733': {
    country: 'US',
    city: 'Holmdel',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3859,
    longitude: -74.174,
    postal_code: '07733'
  },
  '07734': {
    country: 'US',
    city: 'Keansburg',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4414,
    longitude: -74.1306,
    postal_code: '07734'
  },
  '07735': {
    country: 'US',
    city: 'Keyport',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4332,
    longitude: -74.1996,
    postal_code: '07735'
  },
  '07737': {
    country: 'US',
    city: 'Leonardo',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4177,
    longitude: -74.0623,
    postal_code: '07737'
  },
  '07738': {
    country: 'US',
    city: 'Lincroft',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3369,
    longitude: -74.1205,
    postal_code: '07738'
  },
  '07739': {
    country: 'US',
    city: 'Little Silver',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3354,
    longitude: -74.0413,
    postal_code: '07739'
  },
  '07740': {
    country: 'US',
    city: 'Long Branch',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2992,
    longitude: -73.9912,
    postal_code: '07740'
  },
  '07746': {
    country: 'US',
    city: 'Marlboro',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3182,
    longitude: -74.2639,
    postal_code: '07746'
  },
  '07747': {
    country: 'US',
    city: 'Matawan',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4109,
    longitude: -74.238,
    postal_code: '07747'
  },
  '07748': {
    country: 'US',
    city: 'Middletown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3944,
    longitude: -74.1157,
    postal_code: '07748'
  },
  '07750': {
    country: 'US',
    city: 'Monmouth Beach',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.333,
    longitude: -73.9809,
    postal_code: '07750'
  },
  '07751': {
    country: 'US',
    city: 'Morganville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3529,
    longitude: -74.2779,
    postal_code: '07751'
  },
  '07752': {
    country: 'US',
    city: 'Navesink',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4023,
    longitude: -74.0273,
    postal_code: '07752'
  },
  '07753': {
    country: 'US',
    city: 'Neptune',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2096,
    longitude: -74.0714,
    postal_code: '07753'
  },
  '07754': {
    country: 'US',
    city: 'Neptune',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2001,
    longitude: -74.0279,
    postal_code: '07754'
  },
  '07755': {
    country: 'US',
    city: 'Oakhurst',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2648,
    longitude: -74.0184,
    postal_code: '07755'
  },
  '07756': {
    country: 'US',
    city: 'Ocean Grove',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2116,
    longitude: -74.0093,
    postal_code: '07756'
  },
  '07757': {
    country: 'US',
    city: 'Oceanport',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3157,
    longitude: -74.0164,
    postal_code: '07757'
  },
  '07758': {
    country: 'US',
    city: 'Port Monmouth',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.4289,
    longitude: -74.1083,
    postal_code: '07758'
  },
  '07760': {
    country: 'US',
    city: 'Rumson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3707,
    longitude: -74.0084,
    postal_code: '07760'
  },
  '07762': {
    country: 'US',
    city: 'Spring Lake',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1542,
    longitude: -74.0379,
    postal_code: '07762'
  },
  '07763': {
    country: 'US',
    city: 'Tennent',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2796,
    longitude: -74.3343,
    postal_code: '07763'
  },
  '07764': {
    country: 'US',
    city: 'West Long Branch',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2878,
    longitude: -74.0162,
    postal_code: '07764'
  },
  '07765': {
    country: 'US',
    city: 'Wickatunk',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3501,
    longitude: -74.2479,
    postal_code: '07765'
  },
  '07799': {
    country: 'US',
    city: 'Eatontown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.3027,
    longitude: -74.2493,
    postal_code: '07799'
  },
  '08501': {
    country: 'US',
    city: 'Allentown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1589,
    longitude: -74.5909,
    postal_code: '08501'
  },
  '08510': {
    country: 'US',
    city: 'Millstone Township',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.147,
    longitude: -74.609,
    postal_code: '08510'
  },
  '08514': {
    country: 'US',
    city: 'Cream Ridge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1399,
    longitude: -74.465,
    postal_code: '08514'
  },
  '08526': {
    country: 'US',
    city: 'Imlaystown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1623,
    longitude: -74.4759,
    postal_code: '08526'
  },
  '08535': {
    country: 'US',
    city: 'Millstone Township',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2739,
    longitude: -74.7162,
    postal_code: '08535'
  },
  '08555': {
    country: 'US',
    city: 'Roosevelt',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.2214,
    longitude: -74.4747,
    postal_code: '08555'
  },
  '08720': {
    country: 'US',
    city: 'Allenwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1389,
    longitude: -74.1122,
    postal_code: '08720'
  },
  '08730': {
    country: 'US',
    city: 'Brielle',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1077,
    longitude: -74.0635,
    postal_code: '08730'
  },
  '08736': {
    country: 'US',
    city: 'Manasquan',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1217,
    longitude: -74.0611,
    postal_code: '08736'
  },
  '08750': {
    country: 'US',
    city: 'Sea Girt',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Monmouth',
    latitude: 40.1345,
    longitude: -74.0436,
    postal_code: '08750'
  },
  '07005': {
    country: 'US',
    city: 'Boonton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9115,
    longitude: -74.414,
    postal_code: '07005'
  },
  '07034': {
    country: 'US',
    city: 'Lake Hiawatha',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8825,
    longitude: -74.383,
    postal_code: '07034'
  },
  '07035': {
    country: 'US',
    city: 'Lincoln Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9208,
    longitude: -74.2995,
    postal_code: '07035'
  },
  '07045': {
    country: 'US',
    city: 'Montville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9049,
    longitude: -74.3646,
    postal_code: '07045'
  },
  '07046': {
    country: 'US',
    city: 'Mountain Lakes',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8904,
    longitude: -74.4415,
    postal_code: '07046'
  },
  '07054': {
    country: 'US',
    city: 'Parsippany',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8621,
    longitude: -74.4117,
    postal_code: '07054'
  },
  '07058': {
    country: 'US',
    city: 'Pine Brook',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8742,
    longitude: -74.35,
    postal_code: '07058'
  },
  '07082': {
    country: 'US',
    city: 'Towaco',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9277,
    longitude: -74.3428,
    postal_code: '07082'
  },
  '07405': {
    country: 'US',
    city: 'Butler',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9988,
    longitude: -74.4261,
    postal_code: '07405'
  },
  '07440': {
    country: 'US',
    city: 'Pequannock',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9473,
    longitude: -74.296,
    postal_code: '07440'
  },
  '07444': {
    country: 'US',
    city: 'Pompton Plains',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9655,
    longitude: -74.3016,
    postal_code: '07444'
  },
  '07457': {
    country: 'US',
    city: 'Riverdale',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9931,
    longitude: -74.3088,
    postal_code: '07457'
  },
  '07801': {
    country: 'US',
    city: 'Dover',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9176,
    longitude: -74.5467,
    postal_code: '07801'
  },
  '07802': {
    country: 'US',
    city: 'Dover',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8673,
    longitude: -74.5783,
    postal_code: '07802'
  },
  '07803': {
    country: 'US',
    city: 'Mine Hill',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8771,
    longitude: -74.5845,
    postal_code: '07803'
  },
  '07806': {
    country: 'US',
    city: 'Picatinny Arsenal',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8673,
    longitude: -74.5783,
    postal_code: '07806'
  },
  '07828': {
    country: 'US',
    city: 'Budd Lake',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8731,
    longitude: -74.7426,
    postal_code: '07828'
  },
  '07834': {
    country: 'US',
    city: 'Denville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8897,
    longitude: -74.4844,
    postal_code: '07834'
  },
  '07836': {
    country: 'US',
    city: 'Flanders',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8453,
    longitude: -74.7019,
    postal_code: '07836'
  },
  '07842': {
    country: 'US',
    city: 'Hibernia',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.944,
    longitude: -74.4927,
    postal_code: '07842'
  },
  '07845': {
    country: 'US',
    city: 'Ironia',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8229,
    longitude: -74.6257,
    postal_code: '07845'
  },
  '07847': {
    country: 'US',
    city: 'Kenvil',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8819,
    longitude: -74.621,
    postal_code: '07847'
  },
  '07849': {
    country: 'US',
    city: 'Lake Hopatcong',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9506,
    longitude: -74.6129,
    postal_code: '07849'
  },
  '07850': {
    country: 'US',
    city: 'Landing',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9087,
    longitude: -74.6554,
    postal_code: '07850'
  },
  '07852': {
    country: 'US',
    city: 'Ledgewood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.878,
    longitude: -74.6554,
    postal_code: '07852'
  },
  '07853': {
    country: 'US',
    city: 'Long Valley',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7878,
    longitude: -74.787,
    postal_code: '07853'
  },
  '07856': {
    country: 'US',
    city: 'Mount Arlington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9283,
    longitude: -74.6363,
    postal_code: '07856'
  },
  '07857': {
    country: 'US',
    city: 'Netcong',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8985,
    longitude: -74.6985,
    postal_code: '07857'
  },
  '07866': {
    country: 'US',
    city: 'Rockaway',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9229,
    longitude: -74.5094,
    postal_code: '07866'
  },
  '07869': {
    country: 'US',
    city: 'Randolph',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8456,
    longitude: -74.5725,
    postal_code: '07869'
  },
  '07870': {
    country: 'US',
    city: 'Schooleys Mountain',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7993,
    longitude: -74.8138,
    postal_code: '07870'
  },
  '07876': {
    country: 'US',
    city: 'Succasunna',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8539,
    longitude: -74.6536,
    postal_code: '07876'
  },
  '07878': {
    country: 'US',
    city: 'Mount Tabor',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8711,
    longitude: -74.4777,
    postal_code: '07878'
  },
  '07885': {
    country: 'US',
    city: 'Wharton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.9139,
    longitude: -74.5863,
    postal_code: '07885'
  },
  '07926': {
    country: 'US',
    city: 'Brookside',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8004,
    longitude: -74.5718,
    postal_code: '07926'
  },
  '07927': {
    country: 'US',
    city: 'Cedar Knolls',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8223,
    longitude: -74.4569,
    postal_code: '07927'
  },
  '07928': {
    country: 'US',
    city: 'Chatham',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7305,
    longitude: -74.4017,
    postal_code: '07928'
  },
  '07930': {
    country: 'US',
    city: 'Chester',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7892,
    longitude: -74.6776,
    postal_code: '07930'
  },
  '07932': {
    country: 'US',
    city: 'Florham Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7757,
    longitude: -74.3928,
    postal_code: '07932'
  },
  '07933': {
    country: 'US',
    city: 'Gillette',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.6877,
    longitude: -74.4681,
    postal_code: '07933'
  },
  '07935': {
    country: 'US',
    city: 'Green Village',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7416,
    longitude: -74.4517,
    postal_code: '07935'
  },
  '07936': {
    country: 'US',
    city: 'East Hanover',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8192,
    longitude: -74.3636,
    postal_code: '07936'
  },
  '07940': {
    country: 'US',
    city: 'Madison',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7599,
    longitude: -74.4179,
    postal_code: '07940'
  },
  '07945': {
    country: 'US',
    city: 'Mendham',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7789,
    longitude: -74.6,
    postal_code: '07945'
  },
  '07946': {
    country: 'US',
    city: 'Millington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.6727,
    longitude: -74.5183,
    postal_code: '07946'
  },
  '07950': {
    country: 'US',
    city: 'Morris Plains',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8445,
    longitude: -74.4824,
    postal_code: '07950'
  },
  '07960': {
    country: 'US',
    city: 'Morristown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7952,
    longitude: -74.4873,
    postal_code: '07960'
  },
  '07961': {
    country: 'US',
    city: 'Convent Station',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7968,
    longitude: -74.4815,
    postal_code: '07961'
  },
  '07962': {
    country: 'US',
    city: 'Morristown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8673,
    longitude: -74.5783,
    postal_code: '07962'
  },
  '07963': {
    country: 'US',
    city: 'Morristown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7968,
    longitude: -74.4815,
    postal_code: '07963'
  },
  '07970': {
    country: 'US',
    city: 'Mount Freedom',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8055,
    longitude: -74.5738,
    postal_code: '07970'
  },
  '07976': {
    country: 'US',
    city: 'New Vernon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.7347,
    longitude: -74.4845,
    postal_code: '07976'
  },
  '07980': {
    country: 'US',
    city: 'Stirling',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.6774,
    longitude: -74.4968,
    postal_code: '07980'
  },
  '07981': {
    country: 'US',
    city: 'Whippany',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8219,
    longitude: -74.42,
    postal_code: '07981'
  },
  '07999': {
    country: 'US',
    city: 'Whippany',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Morris',
    latitude: 40.8673,
    longitude: -74.5783,
    postal_code: '07999'
  },
  '08005': {
    country: 'US',
    city: 'Barnegat',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.7552,
    longitude: -74.247,
    postal_code: '08005'
  },
  '08006': {
    country: 'US',
    city: 'Barnegat Light',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.7512,
    longitude: -74.1146,
    postal_code: '08006'
  },
  '08008': {
    country: 'US',
    city: 'Beach Haven',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.6411,
    longitude: -74.1922,
    postal_code: '08008'
  },
  '08050': {
    country: 'US',
    city: 'Manahawkin',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.705,
    longitude: -74.2604,
    postal_code: '08050'
  },
  '08087': {
    country: 'US',
    city: 'Tuckerton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.5881,
    longitude: -74.3646,
    postal_code: '08087'
  },
  '08092': {
    country: 'US',
    city: 'West Creek',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.6627,
    longitude: -74.2885,
    postal_code: '08092'
  },
  '08527': {
    country: 'US',
    city: 'Jackson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 40.121,
    longitude: -74.3017,
    postal_code: '08527'
  },
  '08533': {
    country: 'US',
    city: 'New Egypt',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 40.0713,
    longitude: -74.5067,
    postal_code: '08533'
  },
  '08701': {
    country: 'US',
    city: 'Lakewood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 40.085,
    longitude: -74.2042,
    postal_code: '08701'
  },
  '08721': {
    country: 'US',
    city: 'Bayville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9093,
    longitude: -74.1549,
    postal_code: '08721'
  },
  '08722': {
    country: 'US',
    city: 'Beachwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9302,
    longitude: -74.1961,
    postal_code: '08722'
  },
  '08723': {
    country: 'US',
    city: 'Brick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 40.0389,
    longitude: -74.1109,
    postal_code: '08723'
  },
  '08724': {
    country: 'US',
    city: 'Brick',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 40.0874,
    longitude: -74.1152,
    postal_code: '08724'
  },
  '08731': {
    country: 'US',
    city: 'Forked River',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.8444,
    longitude: -74.1973,
    postal_code: '08731'
  },
  '08732': {
    country: 'US',
    city: 'Island Heights',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9432,
    longitude: -74.1468,
    postal_code: '08732'
  },
  '08733': {
    country: 'US',
    city: 'Lakehurst',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 40.0263,
    longitude: -74.3254,
    postal_code: '08733'
  },
  '08734': {
    country: 'US',
    city: 'Lanoka Harbor',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.862,
    longitude: -74.1668,
    postal_code: '08734'
  },
  '08735': {
    country: 'US',
    city: 'Lavallette',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9775,
    longitude: -74.0704,
    postal_code: '08735'
  },
  '08738': {
    country: 'US',
    city: 'Mantoloking',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 40.0261,
    longitude: -74.0562,
    postal_code: '08738'
  },
  '08739': {
    country: 'US',
    city: 'Normandy Beach',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 40.0026,
    longitude: -74.0604,
    postal_code: '08739'
  },
  '08740': {
    country: 'US',
    city: 'Ocean Gate',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.926,
    longitude: -74.1351,
    postal_code: '08740'
  },
  '08741': {
    country: 'US',
    city: 'Pine Beach',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9347,
    longitude: -74.168,
    postal_code: '08741'
  },
  '08742': {
    country: 'US',
    city: 'Point Pleasant Beach',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 40.0806,
    longitude: -74.0595,
    postal_code: '08742'
  },
  '08751': {
    country: 'US',
    city: 'Seaside Heights',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9466,
    longitude: -74.0765,
    postal_code: '08751'
  },
  '08752': {
    country: 'US',
    city: 'Seaside Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9222,
    longitude: -74.0795,
    postal_code: '08752'
  },
  '08753': {
    country: 'US',
    city: 'Toms River',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9771,
    longitude: -74.1565,
    postal_code: '08753'
  },
  '08754': {
    country: 'US',
    city: 'Toms River',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 40.0008,
    longitude: -74.2493,
    postal_code: '08754'
  },
  '08755': {
    country: 'US',
    city: 'Toms River',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9999,
    longitude: -74.2228,
    postal_code: '08755'
  },
  '08756': {
    country: 'US',
    city: 'Toms River',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.788,
    longitude: -74.1911,
    postal_code: '08756'
  },
  '08757': {
    country: 'US',
    city: 'Toms River',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9715,
    longitude: -74.2512,
    postal_code: '08757'
  },
  '08758': {
    country: 'US',
    city: 'Waretown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.7896,
    longitude: -74.1954,
    postal_code: '08758'
  },
  '08759': {
    country: 'US',
    city: 'Manchester Township',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Ocean',
    latitude: 39.9515,
    longitude: -74.1521,
    postal_code: '08759'
  },
  '07011': {
    country: 'US',
    city: 'Clifton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.8789,
    longitude: -74.1425,
    postal_code: '07011'
  },
  '07012': {
    country: 'US',
    city: 'Clifton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.8488,
    longitude: -74.1612,
    postal_code: '07012'
  },
  '07013': {
    country: 'US',
    city: 'Clifton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.8693,
    longitude: -74.1711,
    postal_code: '07013'
  },
  '07014': {
    country: 'US',
    city: 'Clifton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.8344,
    longitude: -74.1377,
    postal_code: '07014'
  },
  '07015': {
    country: 'US',
    city: 'Clifton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.8584,
    longitude: -74.1638,
    postal_code: '07015'
  },
  '07055': {
    country: 'US',
    city: 'Passaic',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.8601,
    longitude: -74.1283,
    postal_code: '07055'
  },
  '07403': {
    country: 'US',
    city: 'Bloomingdale',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0128,
    longitude: -74.3338,
    postal_code: '07403'
  },
  '07420': {
    country: 'US',
    city: 'Haskell',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0301,
    longitude: -74.2965,
    postal_code: '07420'
  },
  '07421': {
    country: 'US',
    city: 'Hewitt',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.1709,
    longitude: -74.3686,
    postal_code: '07421'
  },
  '07424': {
    country: 'US',
    city: 'Little Falls',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.8835,
    longitude: -74.2144,
    postal_code: '07424'
  },
  '07435': {
    country: 'US',
    city: 'Newfoundland',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0647,
    longitude: -74.4359,
    postal_code: '07435'
  },
  '07438': {
    country: 'US',
    city: 'Oak Ridge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0302,
    longitude: -74.5198,
    postal_code: '07438'
  },
  '07442': {
    country: 'US',
    city: 'Pompton Lakes',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9993,
    longitude: -74.2876,
    postal_code: '07442'
  },
  '07456': {
    country: 'US',
    city: 'Ringwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0928,
    longitude: -74.2659,
    postal_code: '07456'
  },
  '07465': {
    country: 'US',
    city: 'Wanaque',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0544,
    longitude: -74.279,
    postal_code: '07465'
  },
  '07470': {
    country: 'US',
    city: 'Wayne',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9471,
    longitude: -74.2466,
    postal_code: '07470'
  },
  '07474': {
    country: 'US',
    city: 'Wayne',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9481,
    longitude: -74.245,
    postal_code: '07474'
  },
  '07480': {
    country: 'US',
    city: 'West Milford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0915,
    longitude: -74.375,
    postal_code: '07480'
  },
  '07501': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9143,
    longitude: -74.1671,
    postal_code: '07501'
  },
  '07502': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9199,
    longitude: -74.1932,
    postal_code: '07502'
  },
  '07503': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.897,
    longitude: -74.1573,
    postal_code: '07503'
  },
  '07504': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9122,
    longitude: -74.1452,
    postal_code: '07504'
  },
  '07505': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9166,
    longitude: -74.174,
    postal_code: '07505'
  },
  '07506': {
    country: 'US',
    city: 'Hawthorne',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9564,
    longitude: -74.1569,
    postal_code: '07506'
  },
  '07507': {
    country: 'US',
    city: 'Hawthorne',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9493,
    longitude: -74.1538,
    postal_code: '07507'
  },
  '07508': {
    country: 'US',
    city: 'Haledon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9457,
    longitude: -74.1826,
    postal_code: '07508'
  },
  '07509': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9168,
    longitude: -74.1718,
    postal_code: '07509'
  },
  '07510': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9168,
    longitude: -74.1718,
    postal_code: '07510'
  },
  '07511': {
    country: 'US',
    city: 'Totowa',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0114,
    longitude: -74.3048,
    postal_code: '07511'
  },
  '07512': {
    country: 'US',
    city: 'Totowa',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9048,
    longitude: -74.2168,
    postal_code: '07512'
  },
  '07513': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.907,
    longitude: -74.1529,
    postal_code: '07513'
  },
  '07514': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9248,
    longitude: -74.1467,
    postal_code: '07514'
  },
  '07522': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9252,
    longitude: -74.1781,
    postal_code: '07522'
  },
  '07524': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9309,
    longitude: -74.1555,
    postal_code: '07524'
  },
  '07533': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0114,
    longitude: -74.3048,
    postal_code: '07533'
  },
  '07538': {
    country: 'US',
    city: 'Haledon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 40.9357,
    longitude: -74.1863,
    postal_code: '07538'
  },
  '07543': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0114,
    longitude: -74.3048,
    postal_code: '07543'
  },
  '07544': {
    country: 'US',
    city: 'Paterson',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Passaic',
    latitude: 41.0114,
    longitude: -74.3048,
    postal_code: '07544'
  },
  '08001': {
    country: 'US',
    city: 'Alloway',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.5591,
    longitude: -75.3506,
    postal_code: '08001'
  },
  '08023': {
    country: 'US',
    city: 'Deepwater',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.6815,
    longitude: -75.4934,
    postal_code: '08023'
  },
  '08038': {
    country: 'US',
    city: 'Hancocks Bridge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.4716,
    longitude: -75.4902,
    postal_code: '08038'
  },
  '08067': {
    country: 'US',
    city: 'Pedricktown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.7435,
    longitude: -75.412,
    postal_code: '08067'
  },
  '08069': {
    country: 'US',
    city: 'Penns Grove',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.6994,
    longitude: -75.4495,
    postal_code: '08069'
  },
  '08070': {
    country: 'US',
    city: 'Pennsville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.6491,
    longitude: -75.5155,
    postal_code: '08070'
  },
  '08072': {
    country: 'US',
    city: 'Quinton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.5459,
    longitude: -75.4124,
    postal_code: '08072'
  },
  '08079': {
    country: 'US',
    city: 'Salem',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.5591,
    longitude: -75.4521,
    postal_code: '08079'
  },
  '08098': {
    country: 'US',
    city: 'Woodstown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.6457,
    longitude: -75.3248,
    postal_code: '08098'
  },
  '08318': {
    country: 'US',
    city: 'Elmer',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.5691,
    longitude: -75.163,
    postal_code: '08318'
  },
  '08347': {
    country: 'US',
    city: 'Norma',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Salem',
    latitude: 39.4998,
    longitude: -75.082,
    postal_code: '08347'
  },
  '07059': {
    country: 'US',
    city: 'Warren',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.6318,
    longitude: -74.5105,
    postal_code: '07059'
  },
  '07920': {
    country: 'US',
    city: 'Basking Ridge',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.6789,
    longitude: -74.5605,
    postal_code: '07920'
  },
  '07921': {
    country: 'US',
    city: 'Bedminster',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.6571,
    longitude: -74.6432,
    postal_code: '07921'
  },
  '07924': {
    country: 'US',
    city: 'Bernardsville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.7225,
    longitude: -74.5778,
    postal_code: '07924'
  },
  '07931': {
    country: 'US',
    city: 'Far Hills',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.6996,
    longitude: -74.6536,
    postal_code: '07931'
  },
  '07934': {
    country: 'US',
    city: 'Gladstone',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.7219,
    longitude: -74.6707,
    postal_code: '07934'
  },
  '07938': {
    country: 'US',
    city: 'Liberty Corner',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.6554,
    longitude: -74.5862,
    postal_code: '07938'
  },
  '07939': {
    country: 'US',
    city: 'Lyons',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.6674,
    longitude: -74.5539,
    postal_code: '07939'
  },
  '07977': {
    country: 'US',
    city: 'Peapack',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.7079,
    longitude: -74.6541,
    postal_code: '07977'
  },
  '07978': {
    country: 'US',
    city: 'Pluckemin',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.6425,
    longitude: -74.6396,
    postal_code: '07978'
  },
  '08502': {
    country: 'US',
    city: 'Belle Mead',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.4483,
    longitude: -74.6557,
    postal_code: '08502'
  },
  '08504': {
    country: 'US',
    city: 'Blawenburg',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.4076,
    longitude: -74.7027,
    postal_code: '08504'
  },
  '08528': {
    country: 'US',
    city: 'Kingston',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.3828,
    longitude: -74.6096,
    postal_code: '08528'
  },
  '08553': {
    country: 'US',
    city: 'Rocky Hill',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.401,
    longitude: -74.64,
    postal_code: '08553'
  },
  '08558': {
    country: 'US',
    city: 'Skillman',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.4173,
    longitude: -74.6938,
    postal_code: '08558'
  },
  '08805': {
    country: 'US',
    city: 'Bound Brook',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.5681,
    longitude: -74.5397,
    postal_code: '08805'
  },
  '08807': {
    country: 'US',
    city: 'Bridgewater',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.5904,
    longitude: -74.6267,
    postal_code: '08807'
  },
  '08821': {
    country: 'US',
    city: 'Flagtown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.517,
    longitude: -74.6843,
    postal_code: '08821'
  },
  '08823': {
    country: 'US',
    city: 'Franklin Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.4421,
    longitude: -74.5369,
    postal_code: '08823'
  },
  '08835': {
    country: 'US',
    city: 'Manville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.5399,
    longitude: -74.5934,
    postal_code: '08835'
  },
  '08836': {
    country: 'US',
    city: 'Martinsville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.6,
    longitude: -74.5572,
    postal_code: '08836'
  },
  '08844': {
    country: 'US',
    city: 'Hillsborough',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.4775,
    longitude: -74.6272,
    postal_code: '08844'
  },
  '08853': {
    country: 'US',
    city: 'Neshanic Station',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.5293,
    longitude: -74.7401,
    postal_code: '08853'
  },
  '08869': {
    country: 'US',
    city: 'Raritan',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.5711,
    longitude: -74.6377,
    postal_code: '08869'
  },
  '08873': {
    country: 'US',
    city: 'Somerset',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.5007,
    longitude: -74.5013,
    postal_code: '08873'
  },
  '08875': {
    country: 'US',
    city: 'Somerset',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.4976,
    longitude: -74.4885,
    postal_code: '08875'
  },
  '08876': {
    country: 'US',
    city: 'Somerville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.588,
    longitude: -74.6874,
    postal_code: '08876'
  },
  '08880': {
    country: 'US',
    city: 'South Bound Brook',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.5523,
    longitude: -74.5311,
    postal_code: '08880'
  },
  '08890': {
    country: 'US',
    city: 'Zarephath',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Somerset',
    latitude: 40.5361,
    longitude: -74.5789,
    postal_code: '08890'
  },
  '07416': {
    country: 'US',
    city: 'Franklin',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.1164,
    longitude: -74.5865,
    postal_code: '07416'
  },
  '07418': {
    country: 'US',
    city: 'Glenwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.2356,
    longitude: -74.4885,
    postal_code: '07418'
  },
  '07419': {
    country: 'US',
    city: 'Hamburg',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.1467,
    longitude: -74.5874,
    postal_code: '07419'
  },
  '07422': {
    country: 'US',
    city: 'Highland Lakes',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.1826,
    longitude: -74.4564,
    postal_code: '07422'
  },
  '07428': {
    country: 'US',
    city: 'Mc Afee',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.2065,
    longitude: -74.5381,
    postal_code: '07428'
  },
  '07439': {
    country: 'US',
    city: 'Ogdensburg',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.0767,
    longitude: -74.5982,
    postal_code: '07439'
  },
  '07460': {
    country: 'US',
    city: 'Stockholm',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.0992,
    longitude: -74.5283,
    postal_code: '07460'
  },
  '07461': {
    country: 'US',
    city: 'Sussex',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.2292,
    longitude: -74.5992,
    postal_code: '07461'
  },
  '07462': {
    country: 'US',
    city: 'Vernon',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.185,
    longitude: -74.5332,
    postal_code: '07462'
  },
  '07821': {
    country: 'US',
    city: 'Andover',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 40.9614,
    longitude: -74.7524,
    postal_code: '07821'
  },
  '07822': {
    country: 'US',
    city: 'Augusta',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.1451,
    longitude: -74.6848,
    postal_code: '07822'
  },
  '07826': {
    country: 'US',
    city: 'Branchville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.1705,
    longitude: -74.75,
    postal_code: '07826'
  },
  '07827': {
    country: 'US',
    city: 'Montague',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.3023,
    longitude: -74.754,
    postal_code: '07827'
  },
  '07837': {
    country: 'US',
    city: 'Glasser',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.1283,
    longitude: -74.679,
    postal_code: '07837'
  },
  '07839': {
    country: 'US',
    city: 'Greendell',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 40.974,
    longitude: -74.821,
    postal_code: '07839'
  },
  '07843': {
    country: 'US',
    city: 'Hopatcong',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 40.939,
    longitude: -74.6616,
    postal_code: '07843'
  },
  '07848': {
    country: 'US',
    city: 'Lafayette',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.0761,
    longitude: -74.6912,
    postal_code: '07848'
  },
  '07851': {
    country: 'US',
    city: 'Layton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.2299,
    longitude: -74.8466,
    postal_code: '07851'
  },
  '07855': {
    country: 'US',
    city: 'Middleville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.0559,
    longitude: -74.8629,
    postal_code: '07855'
  },
  '07860': {
    country: 'US',
    city: 'Newton',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.0695,
    longitude: -74.8069,
    postal_code: '07860'
  },
  '07871': {
    country: 'US',
    city: 'Sparta',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.0277,
    longitude: -74.6407,
    postal_code: '07871'
  },
  '07874': {
    country: 'US',
    city: 'Stanhope',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 40.9217,
    longitude: -74.7004,
    postal_code: '07874'
  },
  '07875': {
    country: 'US',
    city: 'Stillwater',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.0439,
    longitude: -74.872,
    postal_code: '07875'
  },
  '07877': {
    country: 'US',
    city: 'Swartswood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.1029,
    longitude: -74.8508,
    postal_code: '07877'
  },
  '07879': {
    country: 'US',
    city: 'Tranquility',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 40.9559,
    longitude: -74.7881,
    postal_code: '07879'
  },
  '07881': {
    country: 'US',
    city: 'Wallpack Center',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.1256,
    longitude: -74.9177,
    postal_code: '07881'
  },
  '07890': {
    country: 'US',
    city: 'Branchville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Sussex',
    latitude: 41.1465,
    longitude: -74.7524,
    postal_code: '07890'
  },
  '07016': {
    country: 'US',
    city: 'Cranford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6554,
    longitude: -74.3057,
    postal_code: '07016'
  },
  '07023': {
    country: 'US',
    city: 'Fanwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6419,
    longitude: -74.3868,
    postal_code: '07023'
  },
  '07027': {
    country: 'US',
    city: 'Garwood',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6512,
    longitude: -74.3239,
    postal_code: '07027'
  },
  '07033': {
    country: 'US',
    city: 'Kenilworth',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6759,
    longitude: -74.2944,
    postal_code: '07033'
  },
  '07036': {
    country: 'US',
    city: 'Linden',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6354,
    longitude: -74.2556,
    postal_code: '07036'
  },
  '07060': {
    country: 'US',
    city: 'Plainfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6152,
    longitude: -74.415,
    postal_code: '07060'
  },
  '07061': {
    country: 'US',
    city: 'Plainfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6657,
    longitude: -74.2997,
    postal_code: '07061'
  },
  '07062': {
    country: 'US',
    city: 'Plainfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6323,
    longitude: -74.3997,
    postal_code: '07062'
  },
  '07063': {
    country: 'US',
    city: 'Plainfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6048,
    longitude: -74.4427,
    postal_code: '07063'
  },
  '07065': {
    country: 'US',
    city: 'Rahway',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6087,
    longitude: -74.2819,
    postal_code: '07065'
  },
  '07066': {
    country: 'US',
    city: 'Clark',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6203,
    longitude: -74.3106,
    postal_code: '07066'
  },
  '07069': {
    country: 'US',
    city: 'Watchung',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6378,
    longitude: -74.4514,
    postal_code: '07069'
  },
  '07076': {
    country: 'US',
    city: 'Scotch Plains',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6379,
    longitude: -74.3682,
    postal_code: '07076'
  },
  '07081': {
    country: 'US',
    city: 'Springfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.7015,
    longitude: -74.3227,
    postal_code: '07081'
  },
  '07083': {
    country: 'US',
    city: 'Union',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6952,
    longitude: -74.2677,
    postal_code: '07083'
  },
  '07088': {
    country: 'US',
    city: 'Vauxhall',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.7179,
    longitude: -74.2829,
    postal_code: '07088'
  },
  '07090': {
    country: 'US',
    city: 'Westfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6479,
    longitude: -74.3451,
    postal_code: '07090'
  },
  '07091': {
    country: 'US',
    city: 'Westfield',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6657,
    longitude: -74.2997,
    postal_code: '07091'
  },
  '07092': {
    country: 'US',
    city: 'Mountainside',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6785,
    longitude: -74.3588,
    postal_code: '07092'
  },
  '07201': {
    country: 'US',
    city: 'Elizabeth',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6717,
    longitude: -74.2043,
    postal_code: '07201'
  },
  '07202': {
    country: 'US',
    city: 'Elizabeth',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6565,
    longitude: -74.2215,
    postal_code: '07202'
  },
  '07203': {
    country: 'US',
    city: 'Roselle',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.653,
    longitude: -74.261,
    postal_code: '07203'
  },
  '07204': {
    country: 'US',
    city: 'Roselle Park',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6651,
    longitude: -74.267,
    postal_code: '07204'
  },
  '07205': {
    country: 'US',
    city: 'Hillside',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6968,
    longitude: -74.2281,
    postal_code: '07205'
  },
  '07206': {
    country: 'US',
    city: 'Elizabethport',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6501,
    longitude: -74.1871,
    postal_code: '07206'
  },
  '07207': {
    country: 'US',
    city: 'Elizabeth',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.664,
    longitude: -74.2107,
    postal_code: '07207'
  },
  '07208': {
    country: 'US',
    city: 'Elizabeth',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6747,
    longitude: -74.2239,
    postal_code: '07208'
  },
  '07901': {
    country: 'US',
    city: 'Summit',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.7149,
    longitude: -74.3642,
    postal_code: '07901'
  },
  '07902': {
    country: 'US',
    city: 'Summit',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.7156,
    longitude: -74.3647,
    postal_code: '07902'
  },
  '07922': {
    country: 'US',
    city: 'Berkeley Heights',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.6752,
    longitude: -74.4346,
    postal_code: '07922'
  },
  '07974': {
    country: 'US',
    city: 'New Providence',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Union',
    latitude: 40.7004,
    longitude: -74.4023,
    postal_code: '07974'
  },
  '07820': {
    country: 'US',
    city: 'Allamuchy',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.9218,
    longitude: -74.8102,
    postal_code: '07820'
  },
  '07823': {
    country: 'US',
    city: 'Belvidere',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.8308,
    longitude: -75.0503,
    postal_code: '07823'
  },
  '07825': {
    country: 'US',
    city: 'Blairstown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.9674,
    longitude: -74.9651,
    postal_code: '07825'
  },
  '07829': {
    country: 'US',
    city: 'Buttzville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.8434,
    longitude: -74.9859,
    postal_code: '07829'
  },
  '07831': {
    country: 'US',
    city: 'Changewater',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.7394,
    longitude: -74.9448,
    postal_code: '07831'
  },
  '07832': {
    country: 'US',
    city: 'Columbia',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.9388,
    longitude: -75.055,
    postal_code: '07832'
  },
  '07833': {
    country: 'US',
    city: 'Delaware',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.8929,
    longitude: -75.0646,
    postal_code: '07833'
  },
  '07838': {
    country: 'US',
    city: 'Great Meadows',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.852,
    longitude: -74.9418,
    postal_code: '07838'
  },
  '07840': {
    country: 'US',
    city: 'Hackettstown',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.8529,
    longitude: -74.8343,
    postal_code: '07840'
  },
  '07844': {
    country: 'US',
    city: 'Hope',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.9197,
    longitude: -74.9846,
    postal_code: '07844'
  },
  '07846': {
    country: 'US',
    city: 'Johnsonburg',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.9645,
    longitude: -74.8785,
    postal_code: '07846'
  },
  '07863': {
    country: 'US',
    city: 'Oxford',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.8105,
    longitude: -75.0019,
    postal_code: '07863'
  },
  '07865': {
    country: 'US',
    city: 'Port Murray',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.7906,
    longitude: -74.9167,
    postal_code: '07865'
  },
  '07880': {
    country: 'US',
    city: 'Vienna',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.8648,
    longitude: -74.897,
    postal_code: '07880'
  },
  '07882': {
    country: 'US',
    city: 'Washington',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.7582,
    longitude: -74.9914,
    postal_code: '07882'
  },
  '08802': {
    country: 'US',
    city: 'Asbury',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.695,
    longitude: -75.0281,
    postal_code: '08802'
  },
  '08808': {
    country: 'US',
    city: 'Broadway',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.732,
    longitude: -75.0516,
    postal_code: '08808'
  },
  '08865': {
    country: 'US',
    city: 'Phillipsburg',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.7079,
    longitude: -75.1507,
    postal_code: '08865'
  },
  '08886': {
    country: 'US',
    city: 'Stewartsville',
    state: 'New Jersey',
    state_short: 'NJ',
    county: 'Warren',
    latitude: 40.6937,
    longitude: -75.111,
    postal_code: '08886'
  },
  '00501': {
    country: 'US',
    city: 'Holtsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8154,
    longitude: -73.0451,
    postal_code: '00501'
  },
  '00544': {
    country: 'US',
    city: 'Holtsville',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 40.8154,
    longitude: -73.0451,
    postal_code: '00544'
  },
  '06390': {
    country: 'US',
    city: 'Fishers Island',
    state: 'New York',
    state_short: 'NY',
    county: 'Suffolk',
    latitude: 41.2639,
    longitude: -72.0178,
    postal_code: '06390'
  },
  '02806': {
    country: 'US',
    city: 'Barrington',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Bristol',
    latitude: 41.7443,
    longitude: -71.3175,
    postal_code: '02806'
  },
  '02809': {
    country: 'US',
    city: 'Bristol',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Bristol',
    latitude: 41.6825,
    longitude: -71.2676,
    postal_code: '02809'
  },
  '02872': {
    country: 'US',
    city: 'Prudence Island',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Bristol',
    latitude: 41.7071,
    longitude: -71.2868,
    postal_code: '02872'
  },
  '02885': {
    country: 'US',
    city: 'Warren',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Bristol',
    latitude: 41.7256,
    longitude: -71.2702,
    postal_code: '02885'
  },
  '02816': {
    country: 'US',
    city: 'Coventry',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Kent',
    latitude: 41.6914,
    longitude: -71.5768,
    postal_code: '02816'
  },
  '02817': {
    country: 'US',
    city: 'West Greenwich',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Kent',
    latitude: 41.64,
    longitude: -71.6435,
    postal_code: '02817'
  },
  '02818': {
    country: 'US',
    city: 'East Greenwich',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Kent',
    latitude: 41.6498,
    longitude: -71.474,
    postal_code: '02818'
  },
  '02827': {
    country: 'US',
    city: 'Greene',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Kent',
    latitude: 41.7062,
    longitude: -71.7356,
    postal_code: '02827'
  },
  '02886': {
    country: 'US',
    city: 'Warwick',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Kent',
    latitude: 41.7026,
    longitude: -71.4476,
    postal_code: '02886'
  },
  '02887': {
    country: 'US',
    city: 'Warwick',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Kent',
    latitude: 41.7001,
    longitude: -71.4162,
    postal_code: '02887'
  },
  '02888': {
    country: 'US',
    city: 'Warwick',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Kent',
    latitude: 41.7494,
    longitude: -71.4084,
    postal_code: '02888'
  },
  '02889': {
    country: 'US',
    city: 'Warwick',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Kent',
    latitude: 41.7141,
    longitude: -71.3901,
    postal_code: '02889'
  },
  '02893': {
    country: 'US',
    city: 'West Warwick',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Kent',
    latitude: 41.7004,
    longitude: -71.5183,
    postal_code: '02893'
  },
  '02801': {
    country: 'US',
    city: 'Adamsville',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Newport',
    latitude: 41.5301,
    longitude: -71.2841,
    postal_code: '02801'
  },
  '02835': {
    country: 'US',
    city: 'Jamestown',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Newport',
    latitude: 41.5164,
    longitude: -71.3761,
    postal_code: '02835'
  },
  '02837': {
    country: 'US',
    city: 'Little Compton',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Newport',
    latitude: 41.522,
    longitude: -71.1612,
    postal_code: '02837'
  },
  '02840': {
    country: 'US',
    city: 'Newport',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Newport',
    latitude: 41.4876,
    longitude: -71.3271,
    postal_code: '02840'
  },
  '02841': {
    country: 'US',
    city: 'Newport',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Newport',
    latitude: 41.499,
    longitude: -71.299,
    postal_code: '02841'
  },
  '02842': {
    country: 'US',
    city: 'Middletown',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Newport',
    latitude: 41.5198,
    longitude: -71.2731,
    postal_code: '02842'
  },
  '02871': {
    country: 'US',
    city: 'Portsmouth',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Newport',
    latitude: 41.5944,
    longitude: -71.252,
    postal_code: '02871'
  },
  '02878': {
    country: 'US',
    city: 'Tiverton',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Newport',
    latitude: 41.6338,
    longitude: -71.1808,
    postal_code: '02878'
  },
  '02802': {
    country: 'US',
    city: 'Albion',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9512,
    longitude: -71.4545,
    postal_code: '02802'
  },
  '02814': {
    country: 'US',
    city: 'Chepachet',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9155,
    longitude: -71.6795,
    postal_code: '02814'
  },
  '02815': {
    country: 'US',
    city: 'Clayville',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.7778,
    longitude: -71.6706,
    postal_code: '02815'
  },
  '02823': {
    country: 'US',
    city: 'Fiskeville',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.7312,
    longitude: -71.5468,
    postal_code: '02823'
  },
  '02824': {
    country: 'US',
    city: 'Forestdale',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 42.0002,
    longitude: -71.5631,
    postal_code: '02824'
  },
  '02825': {
    country: 'US',
    city: 'Foster',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.7815,
    longitude: -71.7187,
    postal_code: '02825'
  },
  '02826': {
    country: 'US',
    city: 'Glendale',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9762,
    longitude: -71.6326,
    postal_code: '02826'
  },
  '02828': {
    country: 'US',
    city: 'Greenville',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8734,
    longitude: -71.5569,
    postal_code: '02828'
  },
  '02829': {
    country: 'US',
    city: 'Harmony',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8793,
    longitude: -71.5894,
    postal_code: '02829'
  },
  '02830': {
    country: 'US',
    city: 'Harrisville',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9764,
    longitude: -71.6534,
    postal_code: '02830'
  },
  '02831': {
    country: 'US',
    city: 'Hope',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.7516,
    longitude: -71.5612,
    postal_code: '02831'
  },
  '02838': {
    country: 'US',
    city: 'Manville',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9689,
    longitude: -71.4741,
    postal_code: '02838'
  },
  '02839': {
    country: 'US',
    city: 'Mapleville',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9417,
    longitude: -71.6376,
    postal_code: '02839'
  },
  '02857': {
    country: 'US',
    city: 'North Scituate',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8439,
    longitude: -71.6242,
    postal_code: '02857'
  },
  '02858': {
    country: 'US',
    city: 'Oakland',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9573,
    longitude: -71.6478,
    postal_code: '02858'
  },
  '02859': {
    country: 'US',
    city: 'Pascoag',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9627,
    longitude: -71.7099,
    postal_code: '02859'
  },
  '02860': {
    country: 'US',
    city: 'Pawtucket',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8729,
    longitude: -71.3907,
    postal_code: '02860'
  },
  '02861': {
    country: 'US',
    city: 'Pawtucket',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8814,
    longitude: -71.356,
    postal_code: '02861'
  },
  '02862': {
    country: 'US',
    city: 'Pawtucket',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8612,
    longitude: -71.3691,
    postal_code: '02862'
  },
  '02863': {
    country: 'US',
    city: 'Central Falls',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8883,
    longitude: -71.3945,
    postal_code: '02863'
  },
  '02864': {
    country: 'US',
    city: 'Cumberland',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9484,
    longitude: -71.4154,
    postal_code: '02864'
  },
  '02865': {
    country: 'US',
    city: 'Lincoln',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9089,
    longitude: -71.4348,
    postal_code: '02865'
  },
  '02876': {
    country: 'US',
    city: 'Slatersville',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9984,
    longitude: -71.5763,
    postal_code: '02876'
  },
  '02895': {
    country: 'US',
    city: 'Woonsocket',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9846,
    longitude: -71.5194,
    postal_code: '02895'
  },
  '02896': {
    country: 'US',
    city: 'North Smithfield',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.9724,
    longitude: -71.5508,
    postal_code: '02896'
  },
  '02901': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8227,
    longitude: -71.4145,
    postal_code: '02901'
  },
  '02902': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8184,
    longitude: -71.4249,
    postal_code: '02902'
  },
  '02903': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.82,
    longitude: -71.4158,
    postal_code: '02903'
  },
  '02904': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8541,
    longitude: -71.4378,
    postal_code: '02904'
  },
  '02905': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.7845,
    longitude: -71.3959,
    postal_code: '02905'
  },
  '02906': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8351,
    longitude: -71.3971,
    postal_code: '02906'
  },
  '02907': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.7971,
    longitude: -71.4255,
    postal_code: '02907'
  },
  '02908': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8383,
    longitude: -71.4377,
    postal_code: '02908'
  },
  '02909': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8206,
    longitude: -71.4443,
    postal_code: '02909'
  },
  '02910': {
    country: 'US',
    city: 'Cranston',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.7766,
    longitude: -71.4383,
    postal_code: '02910'
  },
  '02911': {
    country: 'US',
    city: 'North Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8547,
    longitude: -71.4735,
    postal_code: '02911'
  },
  '02912': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.824,
    longitude: -71.4128,
    postal_code: '02912'
  },
  '02914': {
    country: 'US',
    city: 'East Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8138,
    longitude: -71.3688,
    postal_code: '02914'
  },
  '02915': {
    country: 'US',
    city: 'Riverside',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.7723,
    longitude: -71.3542,
    postal_code: '02915'
  },
  '02916': {
    country: 'US',
    city: 'Rumford',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8425,
    longitude: -71.3559,
    postal_code: '02916'
  },
  '02917': {
    country: 'US',
    city: 'Smithfield',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8964,
    longitude: -71.5207,
    postal_code: '02917'
  },
  '02918': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8415,
    longitude: -71.4404,
    postal_code: '02918'
  },
  '02919': {
    country: 'US',
    city: 'Johnston',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.8274,
    longitude: -71.52,
    postal_code: '02919'
  },
  '02920': {
    country: 'US',
    city: 'Cranston',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.7716,
    longitude: -71.4659,
    postal_code: '02920'
  },
  '02921': {
    country: 'US',
    city: 'Cranston',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.7614,
    longitude: -71.5061,
    postal_code: '02921'
  },
  '02940': {
    country: 'US',
    city: 'Providence',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Providence',
    latitude: 41.824,
    longitude: -71.4128,
    postal_code: '02940'
  },
  '02804': {
    country: 'US',
    city: 'Ashaway',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.4231,
    longitude: -71.7837,
    postal_code: '02804'
  },
  '02807': {
    country: 'US',
    city: 'Block Island',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.1715,
    longitude: -71.5748,
    postal_code: '02807'
  },
  '02808': {
    country: 'US',
    city: 'Bradford',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.4114,
    longitude: -71.7465,
    postal_code: '02808'
  },
  '02812': {
    country: 'US',
    city: 'Carolina',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.4691,
    longitude: -71.6751,
    postal_code: '02812'
  },
  '02813': {
    country: 'US',
    city: 'Charlestown',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.4007,
    longitude: -71.6615,
    postal_code: '02813'
  },
  '02822': {
    country: 'US',
    city: 'Exeter',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.574,
    longitude: -71.6076,
    postal_code: '02822'
  },
  '02832': {
    country: 'US',
    city: 'Hope Valley',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.5096,
    longitude: -71.7339,
    postal_code: '02832'
  },
  '02833': {
    country: 'US',
    city: 'Hopkinton',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.4751,
    longitude: -71.7726,
    postal_code: '02833'
  },
  '02836': {
    country: 'US',
    city: 'Kenyon',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.4474,
    longitude: -71.6204,
    postal_code: '02836'
  },
  '02852': {
    country: 'US',
    city: 'North Kingstown',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.5894,
    longitude: -71.4625,
    postal_code: '02852'
  },
  '02873': {
    country: 'US',
    city: 'Rockville',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.5199,
    longitude: -71.774,
    postal_code: '02873'
  },
  '02874': {
    country: 'US',
    city: 'Saunderstown',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.5105,
    longitude: -71.4427,
    postal_code: '02874'
  },
  '02875': {
    country: 'US',
    city: 'Shannock',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.456,
    longitude: -71.6355,
    postal_code: '02875'
  },
  '02877': {
    country: 'US',
    city: 'Slocum',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.5289,
    longitude: -71.5299,
    postal_code: '02877'
  },
  '02879': {
    country: 'US',
    city: 'Wakefield',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.4437,
    longitude: -71.5342,
    postal_code: '02879'
  },
  '02880': {
    country: 'US',
    city: 'Wakefield',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.3753,
    longitude: -71.6439,
    postal_code: '02880'
  },
  '02881': {
    country: 'US',
    city: 'Kingston',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.4803,
    longitude: -71.5292,
    postal_code: '02881'
  },
  '02882': {
    country: 'US',
    city: 'Narragansett',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.4353,
    longitude: -71.4616,
    postal_code: '02882'
  },
  '02883': {
    country: 'US',
    city: 'Peace Dale',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.4512,
    longitude: -71.4987,
    postal_code: '02883'
  },
  '02891': {
    country: 'US',
    city: 'Westerly',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.3691,
    longitude: -71.8126,
    postal_code: '02891'
  },
  '02892': {
    country: 'US',
    city: 'West Kingston',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.5058,
    longitude: -71.6211,
    postal_code: '02892'
  },
  '02894': {
    country: 'US',
    city: 'Wood River Junction',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.45,
    longitude: -71.7074,
    postal_code: '02894'
  },
  '02898': {
    country: 'US',
    city: 'Wyoming',
    state: 'Rhode Island',
    state_short: 'RI',
    county: 'Washington',
    latitude: 41.5041,
    longitude: -71.663,
    postal_code: '02898'
  },
  '05443': {
    country: 'US',
    city: 'Bristol',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 44.146,
    longitude: -73.0717,
    postal_code: '05443'
  },
  '05456': {
    country: 'US',
    city: 'Ferrisburgh',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 44.2056,
    longitude: -73.2462,
    postal_code: '05456'
  },
  '05469': {
    country: 'US',
    city: 'Monkton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 44.2404,
    longitude: -73.1347,
    postal_code: '05469'
  },
  '05472': {
    country: 'US',
    city: 'New Haven',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 44.1126,
    longitude: -73.1735,
    postal_code: '05472'
  },
  '05473': {
    country: 'US',
    city: 'North Ferrisburgh',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 44.2595,
    longitude: -73.2112,
    postal_code: '05473'
  },
  '05487': {
    country: 'US',
    city: 'Starksboro',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 44.2261,
    longitude: -73.0157,
    postal_code: '05487'
  },
  '05491': {
    country: 'US',
    city: 'Vergennes',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 44.1326,
    longitude: -73.2793,
    postal_code: '05491'
  },
  '05669': {
    country: 'US',
    city: 'Roxbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 44.0846,
    longitude: -72.7202,
    postal_code: '05669'
  },
  '05734': {
    country: 'US',
    city: 'Bridport',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 43.9538,
    longitude: -73.3476,
    postal_code: '05734'
  },
  '05740': {
    country: 'US',
    city: 'East Middlebury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 43.9734,
    longitude: -73.1062,
    postal_code: '05740'
  },
  '05747': {
    country: 'US',
    city: 'Granville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 43.9849,
    longitude: -72.8245,
    postal_code: '05747'
  },
  '05748': {
    country: 'US',
    city: 'Hancock',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 43.9125,
    longitude: -72.9133,
    postal_code: '05748'
  },
  '05753': {
    country: 'US',
    city: 'Middlebury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 43.9919,
    longitude: -73.1716,
    postal_code: '05753'
  },
  '05760': {
    country: 'US',
    city: 'Orwell',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 43.7837,
    longitude: -73.2949,
    postal_code: '05760'
  },
  '05766': {
    country: 'US',
    city: 'Ripton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 43.9929,
    longitude: -73.0187,
    postal_code: '05766'
  },
  '05769': {
    country: 'US',
    city: 'Salisbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 43.9017,
    longitude: -73.1008,
    postal_code: '05769'
  },
  '05770': {
    country: 'US',
    city: 'Shoreham',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 43.8862,
    longitude: -73.3054,
    postal_code: '05770'
  },
  '05778': {
    country: 'US',
    city: 'Whiting',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Addison',
    latitude: 43.894,
    longitude: -73.203,
    postal_code: '05778'
  },
  '05152': {
    country: 'US',
    city: 'Peru',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 43.2565,
    longitude: -72.8868,
    postal_code: '05152'
  },
  '05201': {
    country: 'US',
    city: 'Bennington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 42.8827,
    longitude: -73.1923,
    postal_code: '05201'
  },
  '05250': {
    country: 'US',
    city: 'Arlington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 43.0857,
    longitude: -73.1594,
    postal_code: '05250'
  },
  '05251': {
    country: 'US',
    city: 'Dorset',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 43.2636,
    longitude: -73.0766,
    postal_code: '05251'
  },
  '05252': {
    country: 'US',
    city: 'East Arlington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 43.0668,
    longitude: -73.0611,
    postal_code: '05252'
  },
  '05253': {
    country: 'US',
    city: 'East Dorset',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 43.237,
    longitude: -73.0081,
    postal_code: '05253'
  },
  '05254': {
    country: 'US',
    city: 'Manchester',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 43.1637,
    longitude: -73.0723,
    postal_code: '05254'
  },
  '05255': {
    country: 'US',
    city: 'Manchester Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 43.1693,
    longitude: -73.0474,
    postal_code: '05255'
  },
  '05257': {
    country: 'US',
    city: 'North Bennington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 42.9304,
    longitude: -73.2426,
    postal_code: '05257'
  },
  '05260': {
    country: 'US',
    city: 'North Pownal',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 42.8098,
    longitude: -73.2534,
    postal_code: '05260'
  },
  '05261': {
    country: 'US',
    city: 'Pownal',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 42.788,
    longitude: -73.2162,
    postal_code: '05261'
  },
  '05262': {
    country: 'US',
    city: 'Shaftsbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 42.9614,
    longitude: -73.2166,
    postal_code: '05262'
  },
  '05340': {
    country: 'US',
    city: 'Bondville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 43.1618,
    longitude: -72.9128,
    postal_code: '05340'
  },
  '05350': {
    country: 'US',
    city: 'Readsboro',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 42.7837,
    longitude: -72.9609,
    postal_code: '05350'
  },
  '05352': {
    country: 'US',
    city: 'Stamford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 42.7562,
    longitude: -73.0679,
    postal_code: '05352'
  },
  '05768': {
    country: 'US',
    city: 'Rupert',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 43.2598,
    longitude: -73.2229,
    postal_code: '05768'
  },
  '05776': {
    country: 'US',
    city: 'West Rupert',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Bennington',
    latitude: 43.2636,
    longitude: -73.1905,
    postal_code: '05776'
  },
  '05042': {
    country: 'US',
    city: 'East Ryegate',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.2275,
    longitude: -72.0996,
    postal_code: '05042'
  },
  '05046': {
    country: 'US',
    city: 'Groton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.2204,
    longitude: -72.2175,
    postal_code: '05046'
  },
  '05050': {
    country: 'US',
    city: 'Mc Indoe Falls',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.4624,
    longitude: -72.1358,
    postal_code: '05050'
  },
  '05069': {
    country: 'US',
    city: 'South Ryegate',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.1978,
    longitude: -72.1104,
    postal_code: '05069'
  },
  '05819': {
    country: 'US',
    city: 'Saint Johnsbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.4272,
    longitude: -72.0051,
    postal_code: '05819'
  },
  '05821': {
    country: 'US',
    city: 'Barnet',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.3179,
    longitude: -72.0783,
    postal_code: '05821'
  },
  '05828': {
    country: 'US',
    city: 'Danville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.4332,
    longitude: -72.1133,
    postal_code: '05828'
  },
  '05832': {
    country: 'US',
    city: 'East Burke',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.6078,
    longitude: -71.9357,
    postal_code: '05832'
  },
  '05836': {
    country: 'US',
    city: 'East Hardwick',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.5288,
    longitude: -72.3034,
    postal_code: '05836'
  },
  '05838': {
    country: 'US',
    city: 'East Saint Johnsbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.4387,
    longitude: -71.9456,
    postal_code: '05838'
  },
  '05843': {
    country: 'US',
    city: 'Hardwick',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.507,
    longitude: -72.3638,
    postal_code: '05843'
  },
  '05848': {
    country: 'US',
    city: 'Lower Waterford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.3821,
    longitude: -71.9195,
    postal_code: '05848'
  },
  '05849': {
    country: 'US',
    city: 'Lyndon',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.4976,
    longitude: -71.9505,
    postal_code: '05849'
  },
  '05850': {
    country: 'US',
    city: 'Lyndon Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.5416,
    longitude: -72.0175,
    postal_code: '05850'
  },
  '05851': {
    country: 'US',
    city: 'Lyndonville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.5555,
    longitude: -72.0715,
    postal_code: '05851'
  },
  '05861': {
    country: 'US',
    city: 'Passumpsic',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.3767,
    longitude: -72.0276,
    postal_code: '05861'
  },
  '05862': {
    country: 'US',
    city: 'Peacham',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.3289,
    longitude: -72.169,
    postal_code: '05862'
  },
  '05863': {
    country: 'US',
    city: 'Saint Johnsbury Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.4567,
    longitude: -72.0157,
    postal_code: '05863'
  },
  '05866': {
    country: 'US',
    city: 'Sheffield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.6178,
    longitude: -72.1239,
    postal_code: '05866'
  },
  '05867': {
    country: 'US',
    city: 'Sutton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.6465,
    longitude: -72.0211,
    postal_code: '05867'
  },
  '05871': {
    country: 'US',
    city: 'West Burke',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.6636,
    longitude: -71.9572,
    postal_code: '05871'
  },
  '05873': {
    country: 'US',
    city: 'West Danville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Caledonia',
    latitude: 44.4465,
    longitude: -72.2198,
    postal_code: '05873'
  },
  '05401': {
    country: 'US',
    city: 'Burlington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.484,
    longitude: -73.2199,
    postal_code: '05401'
  },
  '05402': {
    country: 'US',
    city: 'Burlington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.4421,
    longitude: -73.0825,
    postal_code: '05402'
  },
  '05403': {
    country: 'US',
    city: 'South Burlington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.4513,
    longitude: -73.1796,
    postal_code: '05403'
  },
  '05404': {
    country: 'US',
    city: 'Winooski',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.4949,
    longitude: -73.1874,
    postal_code: '05404'
  },
  '05405': {
    country: 'US',
    city: 'Burlington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.4421,
    longitude: -73.0825,
    postal_code: '05405'
  },
  '05406': {
    country: 'US',
    city: 'Burlington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.4421,
    longitude: -73.0825,
    postal_code: '05406'
  },
  '05407': {
    country: 'US',
    city: 'South Burlington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.4421,
    longitude: -73.0825,
    postal_code: '05407'
  },
  '05408': {
    country: 'US',
    city: 'Burlington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.512,
    longitude: -73.2492,
    postal_code: '05408'
  },
  '05439': {
    country: 'US',
    city: 'Colchester',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.4952,
    longitude: -73.1651,
    postal_code: '05439'
  },
  '05445': {
    country: 'US',
    city: 'Charlotte',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.3113,
    longitude: -73.228,
    postal_code: '05445'
  },
  '05446': {
    country: 'US',
    city: 'Colchester',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.536,
    longitude: -73.2022,
    postal_code: '05446'
  },
  '05449': {
    country: 'US',
    city: 'Colchester',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.5439,
    longitude: -73.1479,
    postal_code: '05449'
  },
  '05451': {
    country: 'US',
    city: 'Essex',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.5084,
    longitude: -73.0503,
    postal_code: '05451'
  },
  '05452': {
    country: 'US',
    city: 'Essex Junction',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.5035,
    longitude: -73.0906,
    postal_code: '05452'
  },
  '05453': {
    country: 'US',
    city: 'Essex Junction',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.4421,
    longitude: -73.0825,
    postal_code: '05453'
  },
  '05461': {
    country: 'US',
    city: 'Hinesburg',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.3346,
    longitude: -73.098,
    postal_code: '05461'
  },
  '05462': {
    country: 'US',
    city: 'Huntington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.3227,
    longitude: -72.9964,
    postal_code: '05462'
  },
  '05465': {
    country: 'US',
    city: 'Jericho',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.459,
    longitude: -72.9552,
    postal_code: '05465'
  },
  '05466': {
    country: 'US',
    city: 'Jonesville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.3839,
    longitude: -72.9376,
    postal_code: '05466'
  },
  '05468': {
    country: 'US',
    city: 'Milton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.6483,
    longitude: -73.1317,
    postal_code: '05468'
  },
  '05477': {
    country: 'US',
    city: 'Richmond',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.3873,
    longitude: -72.9533,
    postal_code: '05477'
  },
  '05482': {
    country: 'US',
    city: 'Shelburne',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.39,
    longitude: -73.2171,
    postal_code: '05482'
  },
  '05489': {
    country: 'US',
    city: 'Underhill',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.5391,
    longitude: -72.9258,
    postal_code: '05489'
  },
  '05490': {
    country: 'US',
    city: 'Underhill Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.5047,
    longitude: -72.8853,
    postal_code: '05490'
  },
  '05494': {
    country: 'US',
    city: 'Westford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.6182,
    longitude: -73.006,
    postal_code: '05494'
  },
  '05495': {
    country: 'US',
    city: 'Williston',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Chittenden',
    latitude: 44.4367,
    longitude: -73.0957,
    postal_code: '05495'
  },
  '05824': {
    country: 'US',
    city: 'Concord',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.4461,
    longitude: -71.8676,
    postal_code: '05824'
  },
  '05837': {
    country: 'US',
    city: 'East Haven',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.6517,
    longitude: -71.8675,
    postal_code: '05837'
  },
  '05840': {
    country: 'US',
    city: 'Granby',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.6808,
    longitude: -71.7194,
    postal_code: '05840'
  },
  '05846': {
    country: 'US',
    city: 'Island Pond',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.8095,
    longitude: -71.884,
    postal_code: '05846'
  },
  '05858': {
    country: 'US',
    city: 'North Concord',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.5501,
    longitude: -71.7867,
    postal_code: '05858'
  },
  '05901': {
    country: 'US',
    city: 'Averill',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.9981,
    longitude: -71.7104,
    postal_code: '05901'
  },
  '05902': {
    country: 'US',
    city: 'Beecher Falls',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 45.0085,
    longitude: -71.4907,
    postal_code: '05902'
  },
  '05903': {
    country: 'US',
    city: 'Canaan',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.9764,
    longitude: -71.5602,
    postal_code: '05903'
  },
  '05904': {
    country: 'US',
    city: 'Gilman',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.414,
    longitude: -71.7179,
    postal_code: '05904'
  },
  '05905': {
    country: 'US',
    city: 'Guildhall',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.6902,
    longitude: -71.5991,
    postal_code: '05905'
  },
  '05906': {
    country: 'US',
    city: 'Lunenburg',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.4663,
    longitude: -71.693,
    postal_code: '05906'
  },
  '05907': {
    country: 'US',
    city: 'Norton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Essex',
    latitude: 44.9888,
    longitude: -71.7884,
    postal_code: '05907'
  },
  '05441': {
    country: 'US',
    city: 'Bakersfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.7914,
    longitude: -72.7697,
    postal_code: '05441'
  },
  '05447': {
    country: 'US',
    city: 'East Berkshire',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.931,
    longitude: -72.7028,
    postal_code: '05447'
  },
  '05448': {
    country: 'US',
    city: 'East Fairfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.7412,
    longitude: -72.8827,
    postal_code: '05448'
  },
  '05450': {
    country: 'US',
    city: 'Enosburg Falls',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.8985,
    longitude: -72.791,
    postal_code: '05450'
  },
  '05454': {
    country: 'US',
    city: 'Fairfax',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.6924,
    longitude: -73.0241,
    postal_code: '05454'
  },
  '05455': {
    country: 'US',
    city: 'Fairfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.7841,
    longitude: -73.0222,
    postal_code: '05455'
  },
  '05457': {
    country: 'US',
    city: 'Franklin',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.9614,
    longitude: -72.9037,
    postal_code: '05457'
  },
  '05459': {
    country: 'US',
    city: 'Highgate Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.9404,
    longitude: -73.0155,
    postal_code: '05459'
  },
  '05460': {
    country: 'US',
    city: 'Highgate Springs',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.9748,
    longitude: -73.1052,
    postal_code: '05460'
  },
  '05470': {
    country: 'US',
    city: 'Montgomery',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.9025,
    longitude: -72.6382,
    postal_code: '05470'
  },
  '05471': {
    country: 'US',
    city: 'Montgomery Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.8781,
    longitude: -72.6023,
    postal_code: '05471'
  },
  '05476': {
    country: 'US',
    city: 'Richford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.9715,
    longitude: -72.6905,
    postal_code: '05476'
  },
  '05478': {
    country: 'US',
    city: 'Saint Albans',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.8111,
    longitude: -73.089,
    postal_code: '05478'
  },
  '05479': {
    country: 'US',
    city: 'Saint Albans',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.8109,
    longitude: -73.0832,
    postal_code: '05479'
  },
  '05481': {
    country: 'US',
    city: 'Saint Albans Bay',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.8078,
    longitude: -73.1393,
    postal_code: '05481'
  },
  '05483': {
    country: 'US',
    city: 'Sheldon',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.8872,
    longitude: -72.953,
    postal_code: '05483'
  },
  '05485': {
    country: 'US',
    city: 'Sheldon Springs',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.9062,
    longitude: -72.9776,
    postal_code: '05485'
  },
  '05488': {
    country: 'US',
    city: 'Swanton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Franklin',
    latitude: 44.9168,
    longitude: -73.1211,
    postal_code: '05488'
  },
  '05440': {
    country: 'US',
    city: 'Alburgh',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Grand Isle',
    latitude: 44.6031,
    longitude: -72.9527,
    postal_code: '05440'
  },
  '05458': {
    country: 'US',
    city: 'Grand Isle',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Grand Isle',
    latitude: 44.7197,
    longitude: -73.3056,
    postal_code: '05458'
  },
  '05463': {
    country: 'US',
    city: 'Isle La Motte',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Grand Isle',
    latitude: 44.8781,
    longitude: -73.3386,
    postal_code: '05463'
  },
  '05474': {
    country: 'US',
    city: 'North Hero',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Grand Isle',
    latitude: 44.8353,
    longitude: -73.2778,
    postal_code: '05474'
  },
  '05486': {
    country: 'US',
    city: 'South Hero',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Grand Isle',
    latitude: 44.64,
    longitude: -73.3113,
    postal_code: '05486'
  },
  '05442': {
    country: 'US',
    city: 'Belvidere Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.7248,
    longitude: -72.7016,
    postal_code: '05442'
  },
  '05444': {
    country: 'US',
    city: 'Cambridge',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.6222,
    longitude: -72.8812,
    postal_code: '05444'
  },
  '05464': {
    country: 'US',
    city: 'Jeffersonville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.6388,
    longitude: -72.822,
    postal_code: '05464'
  },
  '05492': {
    country: 'US',
    city: 'Waterville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.7124,
    longitude: -72.7596,
    postal_code: '05492'
  },
  '05652': {
    country: 'US',
    city: 'Eden',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.7343,
    longitude: -72.5863,
    postal_code: '05652'
  },
  '05653': {
    country: 'US',
    city: 'Eden Mills',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.6931,
    longitude: -72.5134,
    postal_code: '05653'
  },
  '05655': {
    country: 'US',
    city: 'Hyde Park',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.6225,
    longitude: -72.595,
    postal_code: '05655'
  },
  '05656': {
    country: 'US',
    city: 'Johnson',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.642,
    longitude: -72.6712,
    postal_code: '05656'
  },
  '05657': {
    country: 'US',
    city: 'Lake Elmore',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.5426,
    longitude: -72.5254,
    postal_code: '05657'
  },
  '05661': {
    country: 'US',
    city: 'Morrisville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.5543,
    longitude: -72.6026,
    postal_code: '05661'
  },
  '05662': {
    country: 'US',
    city: 'Moscow',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.442,
    longitude: -72.7188,
    postal_code: '05662'
  },
  '05665': {
    country: 'US',
    city: 'North Hyde Park',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.6731,
    longitude: -72.5971,
    postal_code: '05665'
  },
  '05672': {
    country: 'US',
    city: 'Stowe',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.4695,
    longitude: -72.6923,
    postal_code: '05672'
  },
  '05680': {
    country: 'US',
    city: 'Wolcott',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Lamoille',
    latitude: 44.5558,
    longitude: -72.4844,
    postal_code: '05680'
  },
  '05033': {
    country: 'US',
    city: 'Bradford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.0006,
    longitude: -72.1406,
    postal_code: '05033'
  },
  '05036': {
    country: 'US',
    city: 'Brookfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.0321,
    longitude: -72.5953,
    postal_code: '05036'
  },
  '05038': {
    country: 'US',
    city: 'Chelsea',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.0039,
    longitude: -72.4793,
    postal_code: '05038'
  },
  '05039': {
    country: 'US',
    city: 'Corinth',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.0325,
    longitude: -72.2822,
    postal_code: '05039'
  },
  '05040': {
    country: 'US',
    city: 'East Corinth',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.0619,
    longitude: -72.2151,
    postal_code: '05040'
  },
  '05041': {
    country: 'US',
    city: 'East Randolph',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.9553,
    longitude: -72.5487,
    postal_code: '05041'
  },
  '05043': {
    country: 'US',
    city: 'East Thetford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.8073,
    longitude: -72.2147,
    postal_code: '05043'
  },
  '05045': {
    country: 'US',
    city: 'Fairlee',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.901,
    longitude: -72.1902,
    postal_code: '05045'
  },
  '05051': {
    country: 'US',
    city: 'Newbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.0683,
    longitude: -72.1187,
    postal_code: '05051'
  },
  '05054': {
    country: 'US',
    city: 'North Thetford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.8431,
    longitude: -72.1856,
    postal_code: '05054'
  },
  '05058': {
    country: 'US',
    city: 'Post Mills',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.8858,
    longitude: -72.27,
    postal_code: '05058'
  },
  '05060': {
    country: 'US',
    city: 'Randolph',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.9444,
    longitude: -72.6726,
    postal_code: '05060'
  },
  '05061': {
    country: 'US',
    city: 'Randolph Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.9379,
    longitude: -72.597,
    postal_code: '05061'
  },
  '05070': {
    country: 'US',
    city: 'South Strafford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.8282,
    longitude: -72.37,
    postal_code: '05070'
  },
  '05072': {
    country: 'US',
    city: 'Strafford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.8723,
    longitude: -72.3636,
    postal_code: '05072'
  },
  '05074': {
    country: 'US',
    city: 'Thetford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.996,
    longitude: -72.4114,
    postal_code: '05074'
  },
  '05075': {
    country: 'US',
    city: 'Thetford Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.8149,
    longitude: -72.2662,
    postal_code: '05075'
  },
  '05076': {
    country: 'US',
    city: 'Topsham',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.7982,
    longitude: -72.4008,
    postal_code: '05076'
  },
  '05077': {
    country: 'US',
    city: 'Tunbridge',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.9169,
    longitude: -72.4786,
    postal_code: '05077'
  },
  '05079': {
    country: 'US',
    city: 'Vershire',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.9618,
    longitude: -72.319,
    postal_code: '05079'
  },
  '05081': {
    country: 'US',
    city: 'Wells River',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.1524,
    longitude: -72.053,
    postal_code: '05081'
  },
  '05083': {
    country: 'US',
    city: 'West Fairlee',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 43.9189,
    longitude: -72.2681,
    postal_code: '05083'
  },
  '05085': {
    country: 'US',
    city: 'West Newbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.0645,
    longitude: -72.1248,
    postal_code: '05085'
  },
  '05086': {
    country: 'US',
    city: 'West Topsham',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.1199,
    longitude: -72.2701,
    postal_code: '05086'
  },
  '05675': {
    country: 'US',
    city: 'Washington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.0782,
    longitude: -72.4263,
    postal_code: '05675'
  },
  '05679': {
    country: 'US',
    city: 'Williamstown',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orange',
    latitude: 44.1182,
    longitude: -72.5381,
    postal_code: '05679'
  },
  '05820': {
    country: 'US',
    city: 'Albany',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.7417,
    longitude: -72.3646,
    postal_code: '05820'
  },
  '05822': {
    country: 'US',
    city: 'Barton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.7395,
    longitude: -72.16,
    postal_code: '05822'
  },
  '05823': {
    country: 'US',
    city: 'Beebe Plain',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 45.0058,
    longitude: -72.1383,
    postal_code: '05823'
  },
  '05825': {
    country: 'US',
    city: 'Coventry',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.8656,
    longitude: -72.2648,
    postal_code: '05825'
  },
  '05826': {
    country: 'US',
    city: 'Craftsbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.6434,
    longitude: -72.366,
    postal_code: '05826'
  },
  '05827': {
    country: 'US',
    city: 'Craftsbury Common',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.6788,
    longitude: -72.3594,
    postal_code: '05827'
  },
  '05829': {
    country: 'US',
    city: 'Derby',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.9502,
    longitude: -72.1376,
    postal_code: '05829'
  },
  '05830': {
    country: 'US',
    city: 'Derby Line',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.9902,
    longitude: -72.0824,
    postal_code: '05830'
  },
  '05833': {
    country: 'US',
    city: 'East Charleston',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.8299,
    longitude: -71.9763,
    postal_code: '05833'
  },
  '05839': {
    country: 'US',
    city: 'Glover',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.6747,
    longitude: -72.2131,
    postal_code: '05839'
  },
  '05841': {
    country: 'US',
    city: 'Greensboro',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.5951,
    longitude: -72.285,
    postal_code: '05841'
  },
  '05842': {
    country: 'US',
    city: 'Greensboro Bend',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.5489,
    longitude: -72.2648,
    postal_code: '05842'
  },
  '05845': {
    country: 'US',
    city: 'Irasburg',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.8081,
    longitude: -72.2763,
    postal_code: '05845'
  },
  '05847': {
    country: 'US',
    city: 'Lowell',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.7961,
    longitude: -72.4501,
    postal_code: '05847'
  },
  '05853': {
    country: 'US',
    city: 'Morgan',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.8787,
    longitude: -71.9714,
    postal_code: '05853'
  },
  '05855': {
    country: 'US',
    city: 'Newport',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.9393,
    longitude: -72.2065,
    postal_code: '05855'
  },
  '05857': {
    country: 'US',
    city: 'Newport Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.9442,
    longitude: -72.2974,
    postal_code: '05857'
  },
  '05859': {
    country: 'US',
    city: 'North Troy',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.9378,
    longitude: -72.4334,
    postal_code: '05859'
  },
  '05860': {
    country: 'US',
    city: 'Orleans',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.8238,
    longitude: -72.102,
    postal_code: '05860'
  },
  '05868': {
    country: 'US',
    city: 'Troy',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.9014,
    longitude: -72.4015,
    postal_code: '05868'
  },
  '05872': {
    country: 'US',
    city: 'West Charleston',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.8729,
    longitude: -72.0521,
    postal_code: '05872'
  },
  '05874': {
    country: 'US',
    city: 'Westfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.8825,
    longitude: -72.4396,
    postal_code: '05874'
  },
  '05875': {
    country: 'US',
    city: 'Barton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Orleans',
    latitude: 44.7481,
    longitude: -72.1762,
    postal_code: '05875'
  },
  '05701': {
    country: 'US',
    city: 'Rutland',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.6141,
    longitude: -72.9708,
    postal_code: '05701'
  },
  '05702': {
    country: 'US',
    city: 'Rutland',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.6106,
    longitude: -72.9726,
    postal_code: '05702'
  },
  '05730': {
    country: 'US',
    city: 'Belmont',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.4284,
    longitude: -72.8257,
    postal_code: '05730'
  },
  '05731': {
    country: 'US',
    city: 'Benson',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.6877,
    longitude: -73.2919,
    postal_code: '05731'
  },
  '05732': {
    country: 'US',
    city: 'Bomoseen',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.6437,
    longitude: -73.2094,
    postal_code: '05732'
  },
  '05733': {
    country: 'US',
    city: 'Brandon',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.8065,
    longitude: -73.0882,
    postal_code: '05733'
  },
  '05735': {
    country: 'US',
    city: 'Castleton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.6223,
    longitude: -73.1708,
    postal_code: '05735'
  },
  '05736': {
    country: 'US',
    city: 'Center Rutland',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.6023,
    longitude: -73.017,
    postal_code: '05736'
  },
  '05737': {
    country: 'US',
    city: 'Chittenden',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.7132,
    longitude: -72.9252,
    postal_code: '05737'
  },
  '05738': {
    country: 'US',
    city: 'Cuttingsville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.5221,
    longitude: -72.8691,
    postal_code: '05738'
  },
  '05739': {
    country: 'US',
    city: 'Danby',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.3583,
    longitude: -73.0129,
    postal_code: '05739'
  },
  '05741': {
    country: 'US',
    city: 'East Poultney',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.5265,
    longitude: -73.2046,
    postal_code: '05741'
  },
  '05742': {
    country: 'US',
    city: 'East Wallingford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.4461,
    longitude: -72.8844,
    postal_code: '05742'
  },
  '05743': {
    country: 'US',
    city: 'Fair Haven',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.6234,
    longitude: -73.2701,
    postal_code: '05743'
  },
  '05744': {
    country: 'US',
    city: 'Florence',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.7093,
    longitude: -73.079,
    postal_code: '05744'
  },
  '05745': {
    country: 'US',
    city: 'Forest Dale',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.8278,
    longitude: -73.0537,
    postal_code: '05745'
  },
  '05750': {
    country: 'US',
    city: 'Hydeville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.5994,
    longitude: -73.251,
    postal_code: '05750'
  },
  '05751': {
    country: 'US',
    city: 'Killington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.6634,
    longitude: -72.7963,
    postal_code: '05751'
  },
  '05757': {
    country: 'US',
    city: 'Middletown Springs',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.4856,
    longitude: -73.1182,
    postal_code: '05757'
  },
  '05758': {
    country: 'US',
    city: 'Mount Holly',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.4487,
    longitude: -72.7956,
    postal_code: '05758'
  },
  '05759': {
    country: 'US',
    city: 'North Clarendon',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.5522,
    longitude: -72.9561,
    postal_code: '05759'
  },
  '05761': {
    country: 'US',
    city: 'Pawlet',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.3587,
    longitude: -73.1444,
    postal_code: '05761'
  },
  '05762': {
    country: 'US',
    city: 'Pittsfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.7723,
    longitude: -72.8129,
    postal_code: '05762'
  },
  '05763': {
    country: 'US',
    city: 'Pittsford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.7152,
    longitude: -73.0135,
    postal_code: '05763'
  },
  '05764': {
    country: 'US',
    city: 'Poultney',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.5332,
    longitude: -73.2253,
    postal_code: '05764'
  },
  '05765': {
    country: 'US',
    city: 'Proctor',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.658,
    longitude: -73.0348,
    postal_code: '05765'
  },
  '05773': {
    country: 'US',
    city: 'Wallingford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.4573,
    longitude: -72.9877,
    postal_code: '05773'
  },
  '05774': {
    country: 'US',
    city: 'Wells',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.4305,
    longitude: -73.2027,
    postal_code: '05774'
  },
  '05775': {
    country: 'US',
    city: 'West Pawlet',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.3667,
    longitude: -73.2313,
    postal_code: '05775'
  },
  '05777': {
    country: 'US',
    city: 'West Rutland',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Rutland',
    latitude: 43.5781,
    longitude: -73.0424,
    postal_code: '05777'
  },
  '05601': {
    country: 'US',
    city: 'Montpelier',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.1991,
    longitude: -72.5596,
    postal_code: '05601'
  },
  '05602': {
    country: 'US',
    city: 'Montpelier',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.2641,
    longitude: -72.577,
    postal_code: '05602'
  },
  '05603': {
    country: 'US',
    city: 'Montpelier',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.2601,
    longitude: -72.5754,
    postal_code: '05603'
  },
  '05604': {
    country: 'US',
    city: 'Montpelier',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.2595,
    longitude: -72.585,
    postal_code: '05604'
  },
  '05609': {
    country: 'US',
    city: 'Montpelier',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.2595,
    longitude: -72.585,
    postal_code: '05609'
  },
  '05620': {
    country: 'US',
    city: 'Montpelier',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.2595,
    longitude: -72.585,
    postal_code: '05620'
  },
  '05633': {
    country: 'US',
    city: 'Montpelier',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.2595,
    longitude: -72.585,
    postal_code: '05633'
  },
  '05640': {
    country: 'US',
    city: 'Adamant',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.3575,
    longitude: -72.5046,
    postal_code: '05640'
  },
  '05641': {
    country: 'US',
    city: 'Barre',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.1945,
    longitude: -72.4936,
    postal_code: '05641'
  },
  '05647': {
    country: 'US',
    city: 'Cabot',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.4042,
    longitude: -72.3064,
    postal_code: '05647'
  },
  '05648': {
    country: 'US',
    city: 'Calais',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.3934,
    longitude: -72.4741,
    postal_code: '05648'
  },
  '05649': {
    country: 'US',
    city: 'East Barre',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.1576,
    longitude: -72.4533,
    postal_code: '05649'
  },
  '05650': {
    country: 'US',
    city: 'East Calais',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.38,
    longitude: -72.4419,
    postal_code: '05650'
  },
  '05651': {
    country: 'US',
    city: 'East Montpelier',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.2828,
    longitude: -72.4926,
    postal_code: '05651'
  },
  '05654': {
    country: 'US',
    city: 'Graniteville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.1512,
    longitude: -72.4929,
    postal_code: '05654'
  },
  '05658': {
    country: 'US',
    city: 'Marshfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.3509,
    longitude: -72.354,
    postal_code: '05658'
  },
  '05660': {
    country: 'US',
    city: 'Moretown',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.2596,
    longitude: -72.7494,
    postal_code: '05660'
  },
  '05663': {
    country: 'US',
    city: 'Northfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.1512,
    longitude: -72.6565,
    postal_code: '05663'
  },
  '05664': {
    country: 'US',
    city: 'Northfield Falls',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.172,
    longitude: -72.6509,
    postal_code: '05664'
  },
  '05666': {
    country: 'US',
    city: 'North Montpelier',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.2595,
    longitude: -72.585,
    postal_code: '05666'
  },
  '05667': {
    country: 'US',
    city: 'Plainfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.2622,
    longitude: -72.4224,
    postal_code: '05667'
  },
  '05670': {
    country: 'US',
    city: 'South Barre',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.1757,
    longitude: -72.5025,
    postal_code: '05670'
  },
  '05671': {
    country: 'US',
    city: 'Waterbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.3378,
    longitude: -72.7562,
    postal_code: '05671'
  },
  '05673': {
    country: 'US',
    city: 'Waitsfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.1889,
    longitude: -72.8283,
    postal_code: '05673'
  },
  '05674': {
    country: 'US',
    city: 'Warren',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.111,
    longitude: -72.8609,
    postal_code: '05674'
  },
  '05676': {
    country: 'US',
    city: 'Waterbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.3451,
    longitude: -72.7798,
    postal_code: '05676'
  },
  '05677': {
    country: 'US',
    city: 'Waterbury Center',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.3828,
    longitude: -72.708,
    postal_code: '05677'
  },
  '05678': {
    country: 'US',
    city: 'Websterville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.1558,
    longitude: -72.4658,
    postal_code: '05678'
  },
  '05681': {
    country: 'US',
    city: 'Woodbury',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.4548,
    longitude: -72.4036,
    postal_code: '05681'
  },
  '05682': {
    country: 'US',
    city: 'Worcester',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Washington',
    latitude: 44.3944,
    longitude: -72.5609,
    postal_code: '05682'
  },
  '05101': {
    country: 'US',
    city: 'Bellows Falls',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.1482,
    longitude: -72.4664,
    postal_code: '05101'
  },
  '05141': {
    country: 'US',
    city: 'Cambridgeport',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.1515,
    longitude: -72.5604,
    postal_code: '05141'
  },
  '05146': {
    country: 'US',
    city: 'Grafton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.1601,
    longitude: -72.5972,
    postal_code: '05146'
  },
  '05148': {
    country: 'US',
    city: 'Londonderry',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.2281,
    longitude: -72.788,
    postal_code: '05148'
  },
  '05154': {
    country: 'US',
    city: 'Saxtons River',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.1379,
    longitude: -72.5098,
    postal_code: '05154'
  },
  '05155': {
    country: 'US',
    city: 'South Londonderry',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.1699,
    longitude: -72.8515,
    postal_code: '05155'
  },
  '05158': {
    country: 'US',
    city: 'Westminster',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.0826,
    longitude: -72.4749,
    postal_code: '05158'
  },
  '05159': {
    country: 'US',
    city: 'Westminster Station',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.094,
    longitude: -72.4565,
    postal_code: '05159'
  },
  '05301': {
    country: 'US',
    city: 'Brattleboro',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.8574,
    longitude: -72.5933,
    postal_code: '05301'
  },
  '05302': {
    country: 'US',
    city: 'Brattleboro',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.8509,
    longitude: -72.5579,
    postal_code: '05302'
  },
  '05303': {
    country: 'US',
    city: 'Brattleboro',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.8509,
    longitude: -72.5579,
    postal_code: '05303'
  },
  '05304': {
    country: 'US',
    city: 'Brattleboro',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.8509,
    longitude: -72.5579,
    postal_code: '05304'
  },
  '05341': {
    country: 'US',
    city: 'East Dover',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.9505,
    longitude: -72.783,
    postal_code: '05341'
  },
  '05342': {
    country: 'US',
    city: 'Jacksonville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.7778,
    longitude: -72.8071,
    postal_code: '05342'
  },
  '05343': {
    country: 'US',
    city: 'Jamaica',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.1042,
    longitude: -72.7944,
    postal_code: '05343'
  },
  '05344': {
    country: 'US',
    city: 'Marlboro',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.9947,
    longitude: -72.7204,
    postal_code: '05344'
  },
  '05345': {
    country: 'US',
    city: 'Newfane',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.9704,
    longitude: -72.6781,
    postal_code: '05345'
  },
  '05346': {
    country: 'US',
    city: 'Putney',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.0356,
    longitude: -72.5123,
    postal_code: '05346'
  },
  '05351': {
    country: 'US',
    city: 'South Newfane',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.9391,
    longitude: -72.7292,
    postal_code: '05351'
  },
  '05353': {
    country: 'US',
    city: 'Townshend',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.0622,
    longitude: -72.6687,
    postal_code: '05353'
  },
  '05354': {
    country: 'US',
    city: 'Vernon',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.7565,
    longitude: -72.5115,
    postal_code: '05354'
  },
  '05355': {
    country: 'US',
    city: 'Wardsboro',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.0326,
    longitude: -72.8077,
    postal_code: '05355'
  },
  '05356': {
    country: 'US',
    city: 'West Dover',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.9535,
    longitude: -72.8687,
    postal_code: '05356'
  },
  '05357': {
    country: 'US',
    city: 'West Dummerston',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.9265,
    longitude: -72.6154,
    postal_code: '05357'
  },
  '05358': {
    country: 'US',
    city: 'West Halifax',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.7761,
    longitude: -72.7501,
    postal_code: '05358'
  },
  '05359': {
    country: 'US',
    city: 'West Townshend',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.13,
    longitude: -72.721,
    postal_code: '05359'
  },
  '05360': {
    country: 'US',
    city: 'West Wardsboro',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 43.0472,
    longitude: -72.8805,
    postal_code: '05360'
  },
  '05361': {
    country: 'US',
    city: 'Whitingham',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.7892,
    longitude: -72.8851,
    postal_code: '05361'
  },
  '05362': {
    country: 'US',
    city: 'Williamsville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.9364,
    longitude: -72.6611,
    postal_code: '05362'
  },
  '05363': {
    country: 'US',
    city: 'Wilmington',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windham',
    latitude: 42.8812,
    longitude: -72.8613,
    postal_code: '05363'
  },
  '05001': {
    country: 'US',
    city: 'White River Junction',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.6722,
    longitude: -72.3813,
    postal_code: '05001'
  },
  '05009': {
    country: 'US',
    city: 'White River Junction',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.649,
    longitude: -72.3193,
    postal_code: '05009'
  },
  '05030': {
    country: 'US',
    city: 'Ascutney',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.4107,
    longitude: -72.4298,
    postal_code: '05030'
  },
  '05031': {
    country: 'US',
    city: 'Barnard',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.7287,
    longitude: -72.619,
    postal_code: '05031'
  },
  '05032': {
    country: 'US',
    city: 'Bethel',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.8195,
    longitude: -72.6528,
    postal_code: '05032'
  },
  '05034': {
    country: 'US',
    city: 'Bridgewater',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.5804,
    longitude: -72.6467,
    postal_code: '05034'
  },
  '05035': {
    country: 'US',
    city: 'Bridgewater Corners',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.5998,
    longitude: -72.6752,
    postal_code: '05035'
  },
  '05037': {
    country: 'US',
    city: 'Brownsville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.4646,
    longitude: -72.4944,
    postal_code: '05037'
  },
  '05047': {
    country: 'US',
    city: 'Hartford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.6721,
    longitude: -72.3555,
    postal_code: '05047'
  },
  '05048': {
    country: 'US',
    city: 'Hartland',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.5708,
    longitude: -72.4131,
    postal_code: '05048'
  },
  '05049': {
    country: 'US',
    city: 'Hartland Four Corners',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.5465,
    longitude: -72.4245,
    postal_code: '05049'
  },
  '05052': {
    country: 'US',
    city: 'North Hartland',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.5967,
    longitude: -72.3526,
    postal_code: '05052'
  },
  '05053': {
    country: 'US',
    city: 'North Pomfret',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.7204,
    longitude: -72.4943,
    postal_code: '05053'
  },
  '05055': {
    country: 'US',
    city: 'Norwich',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.7404,
    longitude: -72.3016,
    postal_code: '05055'
  },
  '05056': {
    country: 'US',
    city: 'Plymouth',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.4987,
    longitude: -72.7107,
    postal_code: '05056'
  },
  '05059': {
    country: 'US',
    city: 'Quechee',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.6462,
    longitude: -72.4184,
    postal_code: '05059'
  },
  '05062': {
    country: 'US',
    city: 'Reading',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.4793,
    longitude: -72.5704,
    postal_code: '05062'
  },
  '05065': {
    country: 'US',
    city: 'Sharon',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.7748,
    longitude: -72.4243,
    postal_code: '05065'
  },
  '05067': {
    country: 'US',
    city: 'South Pomfret',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.6797,
    longitude: -72.5349,
    postal_code: '05067'
  },
  '05068': {
    country: 'US',
    city: 'South Royalton',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.8089,
    longitude: -72.5232,
    postal_code: '05068'
  },
  '05071': {
    country: 'US',
    city: 'South Woodstock',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.5601,
    longitude: -72.5384,
    postal_code: '05071'
  },
  '05073': {
    country: 'US',
    city: 'Taftsville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.6309,
    longitude: -72.4891,
    postal_code: '05073'
  },
  '05084': {
    country: 'US',
    city: 'West Hartford',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.7123,
    longitude: -72.4176,
    postal_code: '05084'
  },
  '05088': {
    country: 'US',
    city: 'Wilder',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.6735,
    longitude: -72.312,
    postal_code: '05088'
  },
  '05089': {
    country: 'US',
    city: 'Windsor',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.476,
    longitude: -72.411,
    postal_code: '05089'
  },
  '05091': {
    country: 'US',
    city: 'Woodstock',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.6248,
    longitude: -72.5385,
    postal_code: '05091'
  },
  '05142': {
    country: 'US',
    city: 'Cavendish',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.3766,
    longitude: -72.6092,
    postal_code: '05142'
  },
  '05143': {
    country: 'US',
    city: 'Chester',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.2831,
    longitude: -72.6031,
    postal_code: '05143'
  },
  '05149': {
    country: 'US',
    city: 'Ludlow',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.3929,
    longitude: -72.7002,
    postal_code: '05149'
  },
  '05150': {
    country: 'US',
    city: 'North Springfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.338,
    longitude: -72.5277,
    postal_code: '05150'
  },
  '05151': {
    country: 'US',
    city: 'Perkinsville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.3879,
    longitude: -72.5038,
    postal_code: '05151'
  },
  '05153': {
    country: 'US',
    city: 'Proctorsville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.3823,
    longitude: -72.6384,
    postal_code: '05153'
  },
  '05156': {
    country: 'US',
    city: 'Springfield',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.3034,
    longitude: -72.4778,
    postal_code: '05156'
  },
  '05161': {
    country: 'US',
    city: 'Weston',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.2941,
    longitude: -72.7935,
    postal_code: '05161'
  },
  '05746': {
    country: 'US',
    city: 'Gaysville',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.7784,
    longitude: -72.699,
    postal_code: '05746'
  },
  '05767': {
    country: 'US',
    city: 'Rochester',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.8804,
    longitude: -72.8159,
    postal_code: '05767'
  },
  '05772': {
    country: 'US',
    city: 'Stockbridge',
    state: 'Vermont',
    state_short: 'VT',
    county: 'Windsor',
    latitude: 43.7738,
    longitude: -72.7814,
    postal_code: '05772'
  },
  '09001': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 38.1105,
    longitude: 15.6613,
    postal_code: '09001'
  },
  '09007': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4077,
    longitude: 8.6908,
    postal_code: '09007'
  },
  '09008': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1155,
    longitude: 8.6842,
    postal_code: '09008'
  },
  '09009': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4333,
    longitude: 7.6,
    postal_code: '09009'
  },
  '09012': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4333,
    longitude: 7.6,
    postal_code: '09012'
  },
  '09014': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4077,
    longitude: 8.6908,
    postal_code: '09014'
  },
  '09021': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4292,
    longitude: 7.6969,
    postal_code: '09021'
  },
  '09025': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.1113,
    longitude: 9.7391,
    postal_code: '09025'
  },
  '09026': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.3776,
    longitude: 9.9109,
    postal_code: '09026'
  },
  '09028': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.5431,
    longitude: 8.4497,
    postal_code: '09028'
  },
  '09031': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.7397,
    longitude: 10.1507,
    postal_code: '09031'
  },
  '09033': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.0494,
    longitude: 10.2217,
    postal_code: '09033'
  },
  '09034': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.6174,
    longitude: 7.3338,
    postal_code: '09034'
  },
  '09036': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.7939,
    longitude: 9.9512,
    postal_code: '09036'
  },
  '09039': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1155,
    longitude: 8.6842,
    postal_code: '09039'
  },
  '09041': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.1374,
    longitude: 11.5755,
    postal_code: '09041'
  },
  '09044': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.9817,
    longitude: 11.6139,
    postal_code: '09044'
  },
  '09046': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.6821,
    longitude: 9.0117,
    postal_code: '09046'
  },
  '09047': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.759,
    longitude: 9.5085,
    postal_code: '09047'
  },
  '09050': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 47.7611,
    longitude: 11.5589,
    postal_code: '09050'
  },
  '09052': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.2469,
    longitude: 7.3698,
    postal_code: '09052'
  },
  '09053': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 47.4926,
    longitude: 11.0865,
    postal_code: '09053'
  },
  '09054': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.443,
    longitude: 7.7716,
    postal_code: '09054'
  },
  '09056': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.6328,
    longitude: 8.3592,
    postal_code: '09056'
  },
  '09057': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50,
    longitude: 8.6667,
    postal_code: '09057'
  },
  '09058': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.6328,
    longitude: 8.3592,
    postal_code: '09058'
  },
  '09059': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4052,
    longitude: 7.4372,
    postal_code: '09059'
  },
  '09060': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1155,
    longitude: 8.6842,
    postal_code: '09060'
  },
  '09063': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4077,
    longitude: 8.6908,
    postal_code: '09063'
  },
  '09064': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50,
    longitude: 8.6667,
    postal_code: '09064'
  },
  '09066': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.591,
    longitude: 11.0078,
    postal_code: '09066'
  },
  '09067': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.443,
    longitude: 7.7716,
    postal_code: '09067'
  },
  '09068': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4759,
    longitude: 10.9886,
    postal_code: '09068'
  },
  '09069': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 53.5502,
    longitude: 8.5767,
    postal_code: '09069'
  },
  '09070': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4424,
    longitude: 10.9541,
    postal_code: '09070'
  },
  '09071': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4429,
    longitude: 11.8627,
    postal_code: '09071'
  },
  '09072': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.8699,
    longitude: 6.6969,
    postal_code: '09072'
  },
  '09073': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.7035,
    longitude: 9.6521,
    postal_code: '09073'
  },
  '09074': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.3569,
    longitude: 10.9846,
    postal_code: '09074'
  },
  '09077': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.4339,
    longitude: 8.6712,
    postal_code: '09077'
  },
  '09078': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.9624,
    longitude: 7.6257,
    postal_code: '09078'
  },
  '09079': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1155,
    longitude: 8.6842,
    postal_code: '09079'
  },
  '09080': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.6846,
    longitude: 7.1539,
    postal_code: '09080'
  },
  '09081': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.3822,
    longitude: 8.5823,
    postal_code: '09081'
  },
  '09082': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1006,
    longitude: 8.7665,
    postal_code: '09082'
  },
  '09086': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.5,
    longitude: 8.5,
    postal_code: '09086'
  },
  '09089': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.9652,
    longitude: 8.9513,
    postal_code: '09089'
  },
  '09090': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1254,
    longitude: 8.6089,
    postal_code: '09090'
  },
  '09091': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.2016,
    longitude: 9.1874,
    postal_code: '09091'
  },
  '09094': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4445,
    longitude: 7.5553,
    postal_code: '09094'
  },
  '09095': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.2231,
    longitude: 8.3639,
    postal_code: '09095'
  },
  '09096': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.0826,
    longitude: 8.2493,
    postal_code: '09096'
  },
  '09097': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50,
    longitude: 8.6667,
    postal_code: '09097'
  },
  '09098': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 47.8638,
    longitude: 12.0105,
    postal_code: '09098'
  },
  '09099': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4077,
    longitude: 8.6908,
    postal_code: '09099'
  },
  '09100': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.3822,
    longitude: 8.5823,
    postal_code: '09100'
  },
  '09102': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4077,
    longitude: 8.6908,
    postal_code: '09102'
  },
  '09103': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.1747,
    longitude: 6.3169,
    postal_code: '09103'
  },
  '09104': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.9675,
    longitude: 6.1176,
    postal_code: '09104'
  },
  '09107': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.1761,
    longitude: 9.5392,
    postal_code: '09107'
  },
  '09108': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.1374,
    longitude: 11.5755,
    postal_code: '09108'
  },
  '09110': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.8472,
    longitude: 8.3167,
    postal_code: '09110'
  },
  '09111': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.8414,
    longitude: 7.8671,
    postal_code: '09111'
  },
  '09112': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.5106,
    longitude: 10.3187,
    postal_code: '09112'
  },
  '09114': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.7173,
    longitude: 11.9065,
    postal_code: '09114'
  },
  '09123': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.9833,
    longitude: 6.6833,
    postal_code: '09123'
  },
  '09126': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.9833,
    longitude: 6.6833,
    postal_code: '09126'
  },
  '09128': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.9356,
    longitude: 8.9604,
    postal_code: '09128'
  },
  '09130': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.5118,
    longitude: 7.8666,
    postal_code: '09130'
  },
  '09131': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.9356,
    longitude: 8.9604,
    postal_code: '09131'
  },
  '09132': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.9679,
    longitude: 6.5273,
    postal_code: '09132'
  },
  '09136': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.5118,
    longitude: 7.8666,
    postal_code: '09136'
  },
  '09137': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.7035,
    longitude: 9.6521,
    postal_code: '09137'
  },
  '09138': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.2249,
    longitude: 7.6104,
    postal_code: '09138'
  },
  '09139': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.8987,
    longitude: 10.9007,
    postal_code: '09139'
  },
  '09140': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4804,
    longitude: 10.3877,
    postal_code: '09140'
  },
  '09141': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.872,
    longitude: 9.7089,
    postal_code: '09141'
  },
  '09142': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.3305,
    longitude: 11.0235,
    postal_code: '09142'
  },
  '09143': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.5873,
    longitude: 8.6755,
    postal_code: '09143'
  },
  '09144': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1514,
    longitude: 8.425,
    postal_code: '09144'
  },
  '09154': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.7823,
    longitude: 9.177,
    postal_code: '09154'
  },
  '09156': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.9679,
    longitude: 6.5273,
    postal_code: '09156'
  },
  '09157': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.3715,
    longitude: 10.8985,
    postal_code: '09157'
  },
  '09160': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.542,
    longitude: 9.7905,
    postal_code: '09160'
  },
  '09162': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.977,
    longitude: 9.1521,
    postal_code: '09162'
  },
  '09164': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.0094,
    longitude: 8.4044,
    postal_code: '09164'
  },
  '09165': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1342,
    longitude: 8.9142,
    postal_code: '09165'
  },
  '09166': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4891,
    longitude: 8.4669,
    postal_code: '09166'
  },
  '09169': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.5873,
    longitude: 8.6755,
    postal_code: '09169'
  },
  '09171': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.9624,
    longitude: 7.6257,
    postal_code: '09171'
  },
  '09172': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 47.5981,
    longitude: 11.0669,
    postal_code: '09172'
  },
  '09173': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.204,
    longitude: 11.8484,
    postal_code: '09173'
  },
  '09175': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.8717,
    longitude: 8.6503,
    postal_code: '09175'
  },
  '09176': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.1399,
    longitude: 9.2205,
    postal_code: '09176'
  },
  '09177': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.3048,
    longitude: 10.5931,
    postal_code: '09177'
  },
  '09178': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.3715,
    longitude: 10.8985,
    postal_code: '09178'
  },
  '09180': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4131,
    longitude: 7.5702,
    postal_code: '09180'
  },
  '09182': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.6531,
    longitude: 9.9444,
    postal_code: '09182'
  },
  '09183': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.1374,
    longitude: 11.5755,
    postal_code: '09183'
  },
  '09184': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.1374,
    longitude: 11.5755,
    postal_code: '09184'
  },
  '09185': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.9842,
    longitude: 8.2791,
    postal_code: '09185'
  },
  '09186': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.443,
    longitude: 7.7716,
    postal_code: '09186'
  },
  '09187': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.5244,
    longitude: 13.4105,
    postal_code: '09187'
  },
  '09189': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.2014,
    longitude: 7.6053,
    postal_code: '09189'
  },
  '09192': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.0826,
    longitude: 8.2493,
    postal_code: '09192'
  },
  '09196': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.0826,
    longitude: 8.2493,
    postal_code: '09196'
  },
  '09202': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.0099,
    longitude: 8.2829,
    postal_code: '09202'
  },
  '09205': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.1727,
    longitude: 9.2491,
    postal_code: '09205'
  },
  '09211': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.8717,
    longitude: 8.6503,
    postal_code: '09211'
  },
  '09212': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50,
    longitude: 8.6667,
    postal_code: '09212'
  },
  '09213': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1155,
    longitude: 8.6842,
    postal_code: '09213'
  },
  '09214': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1738,
    longitude: 7.0633,
    postal_code: '09214'
  },
  '09215': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 53.5753,
    longitude: 10.0153,
    postal_code: '09215'
  },
  '09216': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.1338,
    longitude: 10.8897,
    postal_code: '09216'
  },
  '09220': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.0826,
    longitude: 8.2493,
    postal_code: '09220'
  },
  '09222': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4759,
    longitude: 10.9886,
    postal_code: '09222'
  },
  '09225': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.7397,
    longitude: 10.1507,
    postal_code: '09225'
  },
  '09226': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.0494,
    longitude: 10.2217,
    postal_code: '09226'
  },
  '09227': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.443,
    longitude: 7.7716,
    postal_code: '09227'
  },
  '09228': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1155,
    longitude: 8.6842,
    postal_code: '09228'
  },
  '09229': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.443,
    longitude: 7.7716,
    postal_code: '09229'
  },
  '09233': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.2217,
    longitude: 6.7762,
    postal_code: '09233'
  },
  '09235': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.5244,
    longitude: 13.4105,
    postal_code: '09235'
  },
  '09237': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1155,
    longitude: 8.6842,
    postal_code: '09237'
  },
  '09239': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.1344,
    longitude: 10.0719,
    postal_code: '09239'
  },
  '09242': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.1155,
    longitude: 8.6842,
    postal_code: '09242'
  },
  '09244': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.7939,
    longitude: 9.9512,
    postal_code: '09244'
  },
  '09245': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.1374,
    longitude: 11.5755,
    postal_code: '09245'
  },
  '09248': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.5873,
    longitude: 8.6755,
    postal_code: '09248'
  },
  '09249': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.2469,
    longitude: 7.3698,
    postal_code: '09249'
  },
  '09250': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4667,
    longitude: 10.7,
    postal_code: '09250'
  },
  '09251': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.2469,
    longitude: 7.3698,
    postal_code: '09251'
  },
  '09252': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.8414,
    longitude: 7.8671,
    postal_code: '09252'
  },
  '09258': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4445,
    longitude: 7.5553,
    postal_code: '09258'
  },
  '09259': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.8055,
    longitude: 13.0902,
    postal_code: '09259'
  },
  '09261': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.7391,
    longitude: 6.291,
    postal_code: '09261'
  },
  '09262': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.7144,
    longitude: 7.3078,
    postal_code: '09262'
  },
  '09263': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.443,
    longitude: 7.7716,
    postal_code: '09263'
  },
  '09264': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.3048,
    longitude: 10.5931,
    postal_code: '09264'
  },
  '09265': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.2023,
    longitude: 10.0778,
    postal_code: '09265'
  },
  '09279': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.8973,
    longitude: 9.1916,
    postal_code: '09279'
  },
  '09281': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.7995,
    longitude: 9.7981,
    postal_code: '09281'
  },
  '09305': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.6084,
    longitude: 7.1741,
    postal_code: '09305'
  },
  '09333': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.4664,
    longitude: 8.5577,
    postal_code: '09333'
  },
  '09351': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 47.7138,
    longitude: 7.6028,
    postal_code: '09351'
  },
  '09352': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.568,
    longitude: 10.8856,
    postal_code: '09352'
  },
  '09353': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.0826,
    longitude: 8.2493,
    postal_code: '09353'
  },
  '09354': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 54.7843,
    longitude: 9.4396,
    postal_code: '09354'
  },
  '09355': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 53.281,
    longitude: 8.6986,
    postal_code: '09355'
  },
  '09358': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.0826,
    longitude: 8.2493,
    postal_code: '09358'
  },
  '09359': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.6884,
    longitude: 9.1677,
    postal_code: '09359'
  },
  '09360': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 49.032,
    longitude: 8.3426,
    postal_code: '09360'
  },
  '09405': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.0933,
    longitude: 1.3204,
    postal_code: '09405'
  },
  '09415': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.4406,
    longitude: -4.9954,
    postal_code: '09415'
  },
  '09418': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 58.5927,
    longitude: -3.5259,
    postal_code: '09418'
  },
  '09419': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 56.8091,
    longitude: -2.656,
    postal_code: '09419'
  },
  '09420': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.8908,
    longitude: -5.1224,
    postal_code: '09420'
  },
  '09421': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.5085,
    longitude: -0.1257,
    postal_code: '09421'
  },
  '09422': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 55.4232,
    longitude: -5.7347,
    postal_code: '09422'
  },
  '09447': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.4015,
    longitude: -1.3247,
    postal_code: '09447'
  },
  '09448': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 53.4295,
    longitude: -2.6585,
    postal_code: '09448'
  },
  '09449': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.5486,
    longitude: -0.4805,
    postal_code: '09449'
  },
  '09450': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.8782,
    longitude: 0.5529,
    postal_code: '09450'
  },
  '09456': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.7082,
    longitude: -1.7813,
    postal_code: '09456'
  },
  '09459': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.2443,
    longitude: 0.7724,
    postal_code: '09459'
  },
  '09461': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.4167,
    longitude: 0.75,
    postal_code: '09461'
  },
  '09462': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.4015,
    longitude: -1.3247,
    postal_code: '09462'
  },
  '09463': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.4015,
    longitude: -1.3247,
    postal_code: '09463'
  },
  '09464': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.4093,
    longitude: 0.561,
    postal_code: '09464'
  },
  '09467': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.9001,
    longitude: -2.0797,
    postal_code: '09467'
  },
  '09468': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 53.9908,
    longitude: -1.5373,
    postal_code: '09468'
  },
  '09469': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.369,
    longitude: -0.2601,
    postal_code: '09469'
  },
  '09470': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.3642,
    longitude: -0.2243,
    postal_code: '09470'
  },
  '09471': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.6291,
    longitude: -0.7493,
    postal_code: '09471'
  },
  '09472': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.7193,
    longitude: -1.9714,
    postal_code: '09472'
  },
  '09494': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.9977,
    longitude: -1.2047,
    postal_code: '09494'
  },
  '09495': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.4015,
    longitude: -1.3247,
    postal_code: '09495'
  },
  '09497': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.0933,
    longitude: 1.3204,
    postal_code: '09497'
  },
  '09498': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.5085,
    longitude: -0.1257,
    postal_code: '09498'
  },
  '09499': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 51.5085,
    longitude: -0.1257,
    postal_code: '09499'
  },
  '09508': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 19.9064,
    longitude: -75.2069,
    postal_code: '09508'
  },
  '09593': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 19.9064,
    longitude: -75.2069,
    postal_code: '09593'
  },
  '09596': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 19.9064,
    longitude: -75.2069,
    postal_code: '09596'
  },
  '09601': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 46.0706,
    longitude: 12.5947,
    postal_code: '09601'
  },
  '09602': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09602'
  },
  '09604': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09604'
  },
  '09605': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.6321,
    longitude: 17.9361,
    postal_code: '09605'
  },
  '09606': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 39.3103,
    longitude: 8.9696,
    postal_code: '09606'
  },
  '09609': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 41.2141,
    longitude: 13.5708,
    postal_code: '09609'
  },
  '09610': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 45.402,
    longitude: 10.2768,
    postal_code: '09610'
  },
  '09613': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 43.5443,
    longitude: 10.3262,
    postal_code: '09613'
  },
  '09618': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.85,
    longitude: 9.1667,
    postal_code: '09618'
  },
  '09619': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.8522,
    longitude: 14.2681,
    postal_code: '09619'
  },
  '09620': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.8522,
    longitude: 14.2681,
    postal_code: '09620'
  },
  '09621': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.8522,
    longitude: 14.2681,
    postal_code: '09621'
  },
  '09622': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.8824,
    longitude: 14.2693,
    postal_code: '09622'
  },
  '09624': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 41.8919,
    longitude: 12.5113,
    postal_code: '09624'
  },
  '09625': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.8522,
    longitude: 14.2681,
    postal_code: '09625'
  },
  '09626': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.8522,
    longitude: 14.2681,
    postal_code: '09626'
  },
  '09627': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.4017,
    longitude: 14.9224,
    postal_code: '09627'
  },
  '09628': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 45.4299,
    longitude: 10.9844,
    postal_code: '09628'
  },
  '09629': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 45.5467,
    longitude: 11.5475,
    postal_code: '09629'
  },
  '09630': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 45.5467,
    longitude: 11.5475,
    postal_code: '09630'
  },
  '09641': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.4165,
    longitude: -3.7026,
    postal_code: '09641'
  },
  '09642': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.4165,
    longitude: -3.7026,
    postal_code: '09642'
  },
  '09643': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 41.4136,
    longitude: -2.4132,
    postal_code: '09643'
  },
  '09644': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 36.6255,
    longitude: -6.3622,
    postal_code: '09644'
  },
  '09645': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 36.6255,
    longitude: -6.3622,
    postal_code: '09645'
  },
  '09646': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 41.6561,
    longitude: -0.8773,
    postal_code: '09646'
  },
  '09647': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.6433,
    longitude: -3.3338,
    postal_code: '09647'
  },
  '09648': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09648'
  },
  '09655': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09655'
  },
  '09658': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09658'
  },
  '09670': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 44.0575,
    longitude: 12.5653,
    postal_code: '09670'
  },
  '09680': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09680'
  },
  '09681': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09681'
  },
  '09682': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09682'
  },
  '09683': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09683'
  },
  '09694': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 36.9489,
    longitude: 14.6073,
    postal_code: '09694'
  },
  '09703': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.9467,
    longitude: 5.9708,
    postal_code: '09703'
  },
  '09704': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 76.5311,
    longitude: -68.7017,
    postal_code: '09704'
  },
  '09705': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.5758,
    longitude: 3.831,
    postal_code: '09705'
  },
  '09706': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 59.9291,
    longitude: 10.519,
    postal_code: '09706'
  },
  '09707': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 59.9127,
    longitude: 10.7461,
    postal_code: '09707'
  },
  '09708': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.5879,
    longitude: 3.8071,
    postal_code: '09708'
  },
  '09709': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 67.017,
    longitude: -50.6893,
    postal_code: '09709'
  },
  '09713': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.8483,
    longitude: 5.6889,
    postal_code: '09713'
  },
  '09714': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.8504,
    longitude: 4.3488,
    postal_code: '09714'
  },
  '09715': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 52.0826,
    longitude: 4.315,
    postal_code: '09715'
  },
  '09716': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 55.6947,
    longitude: 12.5832,
    postal_code: '09716'
  },
  '09717': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.8483,
    longitude: 5.6889,
    postal_code: '09717'
  },
  '09718': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 34.0133,
    longitude: -6.8326,
    postal_code: '09718'
  },
  '09719': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 46.0706,
    longitude: 12.5947,
    postal_code: '09719'
  },
  '09720': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 38.7167,
    longitude: -27.2167,
    postal_code: '09720'
  },
  '09721': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 60.1695,
    longitude: 24.9354,
    postal_code: '09721'
  },
  '09722': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 56.3067,
    longitude: 9.1683,
    postal_code: '09722'
  },
  '09723': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 60.1695,
    longitude: 24.9354,
    postal_code: '09723'
  },
  '09724': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 50.8504,
    longitude: 4.3488,
    postal_code: '09724'
  },
  '09725': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 64.0049,
    longitude: -22.5624,
    postal_code: '09725'
  },
  '09726': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 38.7167,
    longitude: -9.1333,
    postal_code: '09726'
  },
  '09727': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 32.3302,
    longitude: -64.74,
    postal_code: '09727'
  },
  '09728': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 64.0049,
    longitude: -22.5624,
    postal_code: '09728'
  },
  '09729': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 64.2539,
    longitude: -15.2121,
    postal_code: '09729'
  },
  '09730': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 47.2999,
    longitude: -53.9814,
    postal_code: '09730'
  },
  '09731': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 43.7632,
    longitude: -65.3235,
    postal_code: '09731'
  },
  '09732': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 46.3168,
    longitude: -79.4663,
    postal_code: '09732'
  },
  '09734': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09734'
  },
  '09738': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09738'
  },
  '09740': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09740'
  },
  '09745': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09745'
  },
  '09746': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09746'
  },
  '09747': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09747'
  },
  '09749': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09749'
  },
  '09754': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09754'
  },
  '09760': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09760'
  },
  '09762': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09762'
  },
  '09765': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09765'
  },
  '09766': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09766'
  },
  '09767': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09767'
  },
  '09768': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09768'
  },
  '09770': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09770'
  },
  '09772': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09772'
  },
  '09774': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09774'
  },
  '09775': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09775'
  },
  '09777': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 48.8534,
    longitude: 2.3488,
    postal_code: '09777'
  },
  '09802': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 21.2703,
    longitude: 40.4158,
    postal_code: '09802'
  },
  '09803': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09803'
  },
  '09804': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09804'
  },
  '09805': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 25.4,
    longitude: 49.65,
    postal_code: '09805'
  },
  '09806': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 28.3356,
    longitude: 46.1247,
    postal_code: '09806'
  },
  '09808': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09808'
  },
  '09809': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 18.3,
    longitude: 42.7333,
    postal_code: '09809'
  },
  '09810': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 28.3998,
    longitude: 36.5715,
    postal_code: '09810'
  },
  '09811': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 21.5424,
    longitude: 39.198,
    postal_code: '09811'
  },
  '09812': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 33.7215,
    longitude: 73.0433,
    postal_code: '09812'
  },
  '09813': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 6.3005,
    longitude: -10.7969,
    postal_code: '09813'
  },
  '09814': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.8608,
    longitude: 67.0104,
    postal_code: '09814'
  },
  '09815': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: -4.6167,
    longitude: 55.45,
    postal_code: '09815'
  },
  '09816': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 39.6492,
    longitude: 27.8861,
    postal_code: '09816'
  },
  '09817': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 38.3502,
    longitude: 38.3167,
    postal_code: '09817'
  },
  '09818': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 39.9086,
    longitude: 41.2769,
    postal_code: '09818'
  },
  '09819': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 38.4127,
    longitude: 27.1384,
    postal_code: '09819'
  },
  '09820': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 42.0268,
    longitude: 35.1625,
    postal_code: '09820'
  },
  '09821': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 38.4127,
    longitude: 27.1384,
    postal_code: '09821'
  },
  '09822': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 39.9199,
    longitude: 32.8543,
    postal_code: '09822'
  },
  '09823': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 39.9199,
    longitude: 32.8543,
    postal_code: '09823'
  },
  '09824': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.0017,
    longitude: 35.3289,
    postal_code: '09824'
  },
  '09825': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.9136,
    longitude: 40.2172,
    postal_code: '09825'
  },
  '09826': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 39.7767,
    longitude: 30.5206,
    postal_code: '09826'
  },
  '09827': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 41.0138,
    longitude: 28.9497,
    postal_code: '09827'
  },
  '09828': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: -4.3276,
    longitude: 15.3136,
    postal_code: '09828'
  },
  '09829': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 15.5518,
    longitude: 32.5324,
    postal_code: '09829'
  },
  '09830': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 32.0809,
    longitude: 34.7806,
    postal_code: '09830'
  },
  '09831': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: -1.2833,
    longitude: 36.8167,
    postal_code: '09831'
  },
  '09832': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 31.069,
    longitude: 34.1292,
    postal_code: '09832'
  },
  '09833': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 27.9158,
    longitude: 34.3299,
    postal_code: '09833'
  },
  '09834': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2106,
    longitude: 50.6117,
    postal_code: '09834'
  },
  '09835': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 30.0626,
    longitude: 31.2497,
    postal_code: '09835'
  },
  '09836': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.1753,
    longitude: 33.3642,
    postal_code: '09836'
  },
  '09837': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2106,
    longitude: 50.6117,
    postal_code: '09837'
  },
  '09838': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.381,
    longitude: 38.4573,
    postal_code: '09838'
  },
  '09839': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 30.0626,
    longitude: 31.2497,
    postal_code: '09839'
  },
  '09840': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.9795,
    longitude: 23.7162,
    postal_code: '09840'
  },
  '09841': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.9795,
    longitude: 23.7162,
    postal_code: '09841'
  },
  '09842': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 37.9795,
    longitude: 23.7162,
    postal_code: '09842'
  },
  '09843': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 38.1652,
    longitude: 21.4086,
    postal_code: '09843'
  },
  '09844': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 40.6436,
    longitude: 22.9309,
    postal_code: '09844'
  },
  '09845': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 38.0413,
    longitude: 23.543,
    postal_code: '09845'
  },
  '09846': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.3279,
    longitude: 25.1434,
    postal_code: '09846'
  },
  '09847': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 38.0873,
    longitude: 23.9764,
    postal_code: '09847'
  },
  '09848': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09848'
  },
  '09850': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09850'
  },
  '09851': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09851'
  },
  '09852': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09852'
  },
  '09853': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09853'
  },
  '09855': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09855'
  },
  '09858': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09858'
  },
  '09861': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09861'
  },
  '09864': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09864'
  },
  '09865': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 35.5249,
    longitude: 24.1435,
    postal_code: '09865'
  },
  '09866': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2106,
    longitude: 50.6117,
    postal_code: '09866'
  },
  '09873': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09873'
  },
  '09874': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09874'
  },
  '09875': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09875'
  },
  '09876': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09876'
  },
  '09877': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09877'
  },
  '09878': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09878'
  },
  '09879': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09879'
  },
  '09880': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09880'
  },
  '09881': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09881'
  },
  '09882': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09882'
  },
  '09883': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09883'
  },
  '09884': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09884'
  },
  '09886': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09886'
  },
  '09887': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09887'
  },
  '09888': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09888'
  },
  '09889': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09889'
  },
  '09890': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09890'
  },
  '09891': {
    country: 'US',
    city: 'APO AA',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09891'
  },
  '09892': {
    country: 'US',
    city: 'FPO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 31.9552,
    longitude: 35.945,
    postal_code: '09892'
  },
  '09893': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 30.0626,
    longitude: 31.2497,
    postal_code: '09893'
  },
  '09894': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09894'
  },
  '09895': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09895'
  },
  '09896': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09896'
  },
  '09897': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 24.6877,
    longitude: 46.7218,
    postal_code: '09897'
  },
  '09898': {
    country: 'US',
    city: 'APO AE',
    state: '',
    state_short: '',
    county: '',
    latitude: 26.2886,
    longitude: 50.114,
    postal_code: '09898'
  }
};
