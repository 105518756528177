import { Button } from "../../../components/Button";
import { ReactComponent as CloseIcon } from "../../../assets/icons/x.svg";
import { ReactComponent as Check } from "../../../assets/icons/check.svg";
import { useMailBox } from "../context/mailbox-context";

export function InquiryActionBar({ onDecline, onStartChat }) {
  const { inquiryDeclineLoading, inquiryAcceptLoading } = useMailBox() as any;
  return (
    <div className="rounded-[10px] bg-[#EDF3F2] px-6 py-2.5 w-full flex justify-center items-center gap-1.5">
      <Button
        variant="primary"
        className="bg-white !text-black hover:!text-white group !rounded-full"
        onClick={onDecline}
        disabled={inquiryDeclineLoading || inquiryAcceptLoading}
        loading={inquiryDeclineLoading}
      >
        <CloseIcon className="group-hover:[&_path]:!stroke-white" />
        Decline
      </Button>
      <Button
        onClick={onStartChat}
        variant="primary"
        className="!rounded-full"
        disabled={inquiryDeclineLoading || inquiryAcceptLoading}
        loading={inquiryAcceptLoading}
      >
        <Check className="[&_path]:!stroke-white" />
        Start Chat
      </Button>
    </div>
  );
}
