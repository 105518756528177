export function EmptyStates({ text }) {
  return (
    <div className="mx-2 w-[96%] md:w-full flex flex-col md:items-center gap-2.5">
      <div className="w-[80%] md:w-72 bg-white rounded-lg border border-[#E0ECE8] flex items-center gap-3 p-[15px]">
        <div className="w-12 h-12 md:w-20 md:h-20 bg-[#E0ECE8] rounded-lg" />
        <div>
          <div className="w-24 md:w-36 h-[5px] md:h-2.5 mb-2 bg-[#E0ECE8] rounded-lg" />
          <div className="w-20 md:w-28 h-[5px] md:h-2.5 mb-2 md:mb-[13px] bg-[#E0ECE8] rounded-lg" />
          <div className="w-20 md:w-28 h-3.5 md:h-7 rounded-3xl border border-[#E0ECE8]" />
        </div>
      </div>
      <div className="ml-10 md:ml-20 w-[80%] md:w-72 bg-white rounded-lg border border-[#E0ECE8] flex items-center gap-3 p-[15px]">
        <div className="w-12 h-12 md:w-20 md:h-20 bg-[#E0ECE8] rounded-lg" />
        <div>
          <div className="w-24 md:w-36 h-[5px] md:h-2.5 mb-2 bg-[#E0ECE8] rounded-lg" />
          <div className="w-20 md:w-28 h-[5px] md:h-2.5 mb-2 md:mb-[13px] bg-[#E0ECE8] rounded-lg" />
          <div className="w-20 md:w-28 h-3.5 md:h-7 rounded-3xl border border-[#E0ECE8]" />
        </div>
      </div>
      <div className="text-center mt-6">{text}</div>
    </div>
  );
}
