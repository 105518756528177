import ChevronDown from "../../assets/icons/chevron-down.svg";

export function SelectInput({
  data,
  disabledOptionText,
  ...props
}: React.InputHTMLAttributes<HTMLSelectElement> & {
  data: { value: string; label: string; }[];
  disabledOptionText?: string;
}) {
  return (
    <div className="w-full">
      <select
        {...props}
        className={`!outline-none w-full bg-ultraLightGray appearance-none focus:bg-white rounded-lg border border-lightGray focus:border-grey2 
          ${props.className?.includes("p-[") ? '' : "p-[18px]"} 
          ${props.className?.includes("text-[gray]") ? "text-[gray]" : 'text-black'} 
          text-base ${props.className || ""
          }`}
        style={{
          backgroundImage: `url(${ChevronDown})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "97% 55%",
        }}
      >
        <option value="" disabled>
          {(disabledOptionText && disabledOptionText) ?? "Select Location"}
        </option>
        {data.map((item) => (
          <option key={item.value} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
}
