import { HighlightCard } from "./components/HighlightCard";
import { ReactComponent as ChevronRight } from "../../assets/icons/right-chevron.svg";
import { ReactComponent as UserIcon } from "../../assets/icons/users.svg";
import { ReactComponent as ProvidersIcon } from "../../assets/icons/person.svg";
import { ReactComponent as DownloadIcon } from "../../assets/icons/download.svg";
import { ReactComponent as ArrowDown } from "../../assets/icons/arrow-down.svg";
import { CSVLink } from "react-csv";

import {
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  AreaChart,
  XAxis,
  YAxis,
  Area,
} from "recharts";

import { Link } from "react-router-dom";
import { useState } from "react";
import { useQuery } from "@tanstack/react-query";
import moment, { unitOfTime } from "moment";
import { PersonCard } from "./components/PersonCard";
import { formatNumber, getUser } from "../../services/utils";
import { api } from "../../services/api";
import { LoadAnimatedContainer } from "../../components/LoadAnimatedContainer";
import { NewRequestList } from "../provider/list/NewRequestList";
import { ChartFilter } from "./components/FIlters";
import { ImageCrop } from "../../components/ImageCropper/ImageCrop";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const getCsvData = (data) => {
  if (!data) return [];
  const csvData: any[] = [];
  csvData.push(["Date", "Clients", "Providers"]);
  data.families.forEach((item) => {
    csvData.push([
      item.day || item.month,
      item.count,
      data.providers.find(
        (provider) =>
          (provider.day && provider.day === item.day) ||
          (provider.month && provider.month === item.month)
      )?.count,
    ]);
  });
  return csvData;
};

function CustomizedLabel({ x, y, payload, ...rest }) {
  return (
    <g transform={`translate(${x},${y})`}>
      <text x={6} y={0} dy={16} textAnchor="end" fill="#666">
        {rest.type === "CURRENT_WEEK"
          ? moment(payload.value).format("dd")
          : rest.type === "CURRENT_MONTH"
            ? moment(payload.value).format("DD")
            : // turn month number to month name
            payload.value >= 1 && payload.value <= 12
              ? months[payload.value - 1]
              : payload.value}
      </text>
    </g>
  );
}

const renderUsersTooltip =
  (selectedChart: string) =>
    ({ active, payload }: any) => {
      if (active && payload && payload.length) {
        return (
          <div className="custom-tooltip">
            <div className="px-5 py-4 bg-white rounded-xl shadow-[0px_16px_40px_0px_rgba(96,0,0,0.11)] flex-col justify-start items-start gap-1 inline-flex">
              <div className="text-black/50 text-sm font-normal font-['Helvetica Neue'] leading-tight">
                {selectedChart === "CURRENT_YEAR"
                  ? moment(payload[0]?.payload?.name, "MM").format("MMM, YYYY")
                  : moment(payload[0]?.payload?.name).format("MMM DD, YYYY")}
              </div>
              <div className="flex items-center gap-1 text-black text-xl font-bold font-['Helvetica Neue'] leading-normal">
                <span className="font-normal">Providers:</span>
                {formatNumber(Number(payload[0]?.payload?.providerCount))}
              </div>
              <div className="flex items-center gap-1 text-black text-xl font-bold font-['Helvetica Neue'] leading-normal">
                <span className="font-normal">Clients:</span>
                {formatNumber(Number(payload[0]?.payload?.familyCount))}
              </div>
            </div>
          </div>
        );
      }

      return null;
    };

function DashboardSkeleton() {
  return (
    <div>
      <div className="flex flex-col md:flex-row gap-5 md:items-center flex-wrap flex-1">
        <div className="flex-1 lg:w-[350px] lg:h-[112px] rounded-[16px]">
          <div
            className={`relative5 gap-12 items-center px-6 py-5 bg-white rounded-[16px] flex-1 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] flex`}
          >
            <div className="flex gap-5">
              <div className="flex items-center gap-3">
                <div
                  className={`w-16 h-16 rounded-full relative flex items-center justify-center bg-gray-300 animate-pulse transition duration-50`}
                ></div>
              </div>
              <div className="flex flex-col gap-2 justify-center">
                <div className="w-32 h-4 text-black text-base font-medium bg-gray-300 animate-pulse transition duration-50"></div>
                <div className="w-16 h-5 text-black text-[32px] font-bold bg-gray-300 animate-pulse transition duration-50"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-1  lg:w-[350px] lg:h-[112px] rounded-[16px]">
          <div
            className={`relative5 gap-12 items-center px-6 py-5 bg-white rounded-[16px] flex-1 shadow-[0px_16px_40px_0px_rgba(96,0,0,0.11)] flex`}
          >
            <div className="flex gap-5">
              <div className="flex items-center gap-3">
                <div
                  className={`w-16 h-16 rounded-full relative flex items-center justify-center bg-gray-300 animate-pulse transition duration-50`}
                ></div>
              </div>
              <div className="flex flex-col gap-2 justify-center">
                <div className="w-32 h-4 text-black text-base font-medium bg-gray-300 animate-pulse transition duration-50"></div>
                <div className="w-16 h-5 text-black text-[32px] font-bold bg-gray-300 animate-pulse transition duration-50"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mt-5 flex flex-col lg:flex-row  gap-5">
        <div className="flex flex-col flex-1 p-6 bg-white rounded-[16px] h-[450px]">
          <div className="flex justify-between items-center mb-4">
            <div className="w-[80px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
            <div className="lg:w-[125px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
          </div>
          <div className="animate-pulse transition duration-50 h-16 rounded-[16px] mb-4 bg-gray-300"></div>
          <div className="flex-1 animate-pulse transition duration-50 h-[402px] bg-gray-300"></div>
        </div>
        <div className="flex flex-col flex-1 p-6 bg-white rounded-[16px] h-[450px]">
          <div className="flex justify-between items-center mb-4">
            <div className="w-[80px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
            <div className="lg:w-[125px] animate-pulse transition duration-50 h-[20px] bg-gray-300 rounded-[16px]"></div>
          </div>
          <div className="animate-pulse transition duration-50 h-16 rounded-[16px] mb-4 bg-gray-300"></div>
          <div className="flex-1 animate-pulse transition duration-50 h-[402px] bg-gray-300"></div>
        </div>
      </div>
    </div>
  );
}

function Card({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) {
  return (
    <div className={`${className} bg-[white] p-[1.5rem] rounded-[16px]`}>
      {children}
    </div>
  );
}

export function Dashboard() {
  const user = getUser();
  const [selectedChart, setSelectedChart] = useState<{
    type: string;
    startDate?: string;
    endDate?: string;
  }>({ type: "CURRENT_WEEK" });
  const [showNewRequestList, setShowNewRequestList] = useState(false);

  const { isLoading, data } = useQuery({
    queryKey: ["dashboard", user.id],
    queryFn: () => api.getDashboard(user.id),
  });
  const { data: chartData } = useQuery({
    queryKey: ["dashboard-charts", selectedChart, user.id],
    queryFn: () =>
      api.getDashboardCharts({
        duration: selectedChart.type,
        startDate: selectedChart.startDate,
        endDate: selectedChart.endDate,
        id: user.id,
        type: "USERS_COUNT",
      }),
  });

  const dashboardData = data;

  const { data: providersData, refetch: refreshRequest } = useQuery({
    queryKey: ["provider-requests"],
    queryFn: () =>
      api.getUsers({
        type: "P",
        page: 0,
        limit: 10,
        showPending: true,
        showActive: false,
      }),
  });
  const providersList = providersData?.items;

  return (
    <LoadAnimatedContainer>
      <div className="mb-5 flex gap-5 flex-col md:flex-row justify-between md:items-center">
        <div className="text-black text-3xl font-bold">Dashboard</div>
        <div className="flex justify-center"></div>
      </div>
      <>
        {isLoading ? (
          <DashboardSkeleton />
        ) : (
          <>
            <div className="flex gap-5 md:items-center flex-wrap flex-1 flex-col md:flex-row">
              <Link to="/clients" className="flex-1">
                <HighlightCard
                  icon={
                    <UserIcon className="w-9 h-9 [&_path]:stroke-primary" />
                  }
                  label={
                    <div className="flex items-center">
                      <span className="text-black/60 text-lg ">
                        Total Clients
                      </span>
                      <ChevronRight className="mt-1 [&_path]:stroke-primary" />
                    </div>
                  }
                  value={formatNumber(Number(dashboardData?.totalPatients))}
                  className="transition-all cursor-pointer hover:shadow-[rgba(0,71,28,0.12)_0px_7px_16px] w-full"
                />
              </Link>
              <Link to="/providers" className="flex-1">
                <HighlightCard
                  icon={
                    <ProvidersIcon className="w-9 h-9 [&_path]:stroke-primary" />
                  }
                  label={
                    <div className="flex items-center">
                      <span className="text-black/60 text-lg ">
                        Total Providers
                      </span>
                      <ChevronRight className="mt-1 [&_path]:stroke-primary" />
                    </div>
                  }
                  value={formatNumber(Number(dashboardData?.totalProviders))}
                  className="transition-all cursor-pointer hover:shadow-[rgba(0,71,28,0.12)_0px_7px_16px] w-full"
                />
              </Link>
            </div>
            <div className="mt-5 flex flex-wrap gap-5">
              <Card className="max-w-[calc(50%-10px)] bg-white p-5 shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] rounded-[16px] flex-1">
                <div className="flex">
                  <div className="w-full mt-6 p-4 rounded-lg border border-gray-200 justify-between items-center flex flex-1 w-full">
                    <div className="text-black text-base font-normal font-['Helvetica Neue'] leading-tight">
                      {selectedChart.type !== "CUSTOM" ? (
                        <>
                          {moment()
                            .startOf(
                              selectedChart.type.split(
                                "_"
                              )[1] as unitOfTime.StartOf
                            )
                            .format("MMM D")}{" "}
                          -{" "}
                          {moment()
                            .endOf(
                              selectedChart.type.split(
                                "_"
                              )[1] as unitOfTime.StartOf
                            )
                            .format("MMM D")}
                        </>
                      ) : (
                        <>
                          {moment(selectedChart.startDate).format("MMM D")} -{" "}
                          {moment(selectedChart.endDate).format("MMM D")}
                        </>
                      )}
                    </div>
                    <div className="justify-end items-center gap-1 flex">
                      <div className="flex items-center gap-1 text-black text-lg font-bold font-['Helvetica Neue'] leading-tight">
                        <span className="font-normal">Providers:</span>
                        {chartData?.families
                          ? formatNumber(
                            Number(
                              chartData?.providers?.reduce(
                                (acc, item) => acc + Number(item.count),
                                0
                              )
                            )
                          )
                          : ""}
                      </div>
                      <div className="border-l border-l-black/20 h-3 mx-2" />
                      <div className="flex items-center gap-1 text-black text-lg font-bold font-['Helvetica Neue'] leading-tight">
                        <span className="font-normal">Clients:</span>

                        {chartData?.families
                          ? formatNumber(
                            Number(
                              chartData?.families?.reduce(
                                (acc, item) => acc + Number(item.count),
                                0
                              )
                            )
                          )
                          : ""}
                      </div>
                      {(chartData?.families || chartData?.providers) && (
                        <div className="ml-2 rounded-full bg-primary p-1 justify-end items-center gap-1.5 flex">
                          <ArrowDown className="w-4 h-4" />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {(chartData?.families || chartData?.providers) && (
                  <div className="h-[402px] mt-12">
                    <ResponsiveContainer width="100%" height="100%">
                      <AreaChart
                        data={chartData?.families?.map((item) => {
                          const payload = {
                            name: item.day || item.month,
                            familyCount: item.count,
                            providerCount: chartData?.providers?.find(
                              (provider) =>
                                (provider.day && provider.day === item.day) ||
                                (provider.month &&
                                  provider.month === item.month)
                            )?.count,
                          };
                          return payload;
                        })}
                        margin={{
                          top: 0,
                          right: 0,
                          left:
                            chartData?.families.length < 10
                              ? -40
                              : chartData?.families.length < 100
                                ? -30
                                : -10,
                          bottom: 15,
                        }}
                      >
                        <defs>
                          <linearGradient
                            id="paint0_linear_53_758"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00FFB3" />
                            <stop
                              offset="1"
                              stop-color="#F0FF44"
                              stop-opacity="0"
                            />
                          </linearGradient>
                          <linearGradient
                            id="provider_gradient_53_758"
                            gradientUnits="userSpaceOnUse"
                          >
                            <stop stop-color="#00000026" />
                            <stop
                              offset="1"
                              stop-color="#00000000"
                              stop-opacity="0"
                            />
                          </linearGradient>
                        </defs>
                        <CartesianGrid vertical={false} />
                        <XAxis
                          dataKey="name"
                          tick={(props) => (
                            <CustomizedLabel
                              {...props}
                              type={selectedChart.type}
                            />
                          )}
                        />
                        <YAxis
                          tickFormatter={(tick) => {
                            return Number.isInteger(tick) ? tick : "";
                          }}
                        />
                        <Tooltip
                          content={renderUsersTooltip(selectedChart.type)}
                        />
                        <Area
                          type="monotone"
                          dataKey="familyCount"
                          stackId="users"
                          stroke="#00FFB3"
                          strokeWidth="3px"
                          fill="url(#paint0_linear_53_758)"
                        />
                        <Area
                          type="monotone"
                          stackId="users"
                          dataKey="providerCount"
                          stroke="#000000"
                          strokeWidth="3px"
                          fill="url(#provider_gradient_53_758)"
                        />
                      </AreaChart>
                    </ResponsiveContainer>
                  </div>
                )}
              </Card>
              <Card className="max-w-[calc(50%-10px)] flex-1 !p-7">
                <div className="flex justify-between items-center mb-[1.5rem]">
                  <h2 className="font-[700] text-[1.25rem]">
                    Provider Requests ({providersData?.totalRecords})
                  </h2>
                  <button
                    onClick={() => setShowNewRequestList(true)}
                    className="text-black/60 hover:text-black text-lg flex items-center"
                  >
                    View all
                    <ChevronRight className="mt-1 [&_path]:stroke-primary" />
                  </button>
                </div>
                <div className="grid gap-4 overflow-y-auto max-h-[calc(100vh-180px)]">
                  {providersList?.map((provider) => (
                    <PersonCard
                      key={provider.id}
                      provider={provider}
                      refetch={refreshRequest}
                    />
                  ))}
                </div>
              </Card>
            </div>
          </>
        )}
      </>

      {showNewRequestList && (
        <NewRequestList
          isOpen={showNewRequestList}
          onClose={() => setShowNewRequestList(false)}
          onRefresh={refreshRequest}
        />
      )}
    </LoadAnimatedContainer>
  );
}
