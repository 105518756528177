import React, { useEffect, useMemo, useState } from "react";
import { getUser } from "../../../services/utils";
import { RbtCandidate } from "../RbtCandidate.types";
import { api } from "../../../services/api";

export const RBTCandidateContext = React.createContext<{ rbtCandidates: Array<RbtCandidate>; refreshCandidates: () => {} }>({
  rbtCandidates: [],
  refreshCandidates: () => ({}),
});

export function RbtCandidateProvider({ children }) {
  const user = getUser();
  const [candidates, setCandidates] = useState<Array<RbtCandidate>>([]);

  const fetch = useMemo(
    () => async () => {
      const newCandidates = await api.getRbtCandidates();
      setCandidates(newCandidates);
    },
    []
  );

  useEffect(() => {
    if (user?.id) {
      fetch();
    }
  }, [user?.id]);

  const values = useMemo(
    () => ({
      rbtCandidates: candidates,
      refreshCandidates: fetch,
    }),
    [candidates, fetch]
  );

  return <RBTCandidateContext.Provider value={values}>{children}</RBTCandidateContext.Provider>;
}

export function useRbtCandidates() {
  const context = React.useContext<{ rbtCandidates: Array<RbtCandidate>; refreshCandidates: () => {} }>(RBTCandidateContext);
  if (context === undefined) {
    throw new Error("useRbtCandidates must be used within a RBTCandidateProvider");
  }
  return context;
}
