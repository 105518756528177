import { LoadAnimatedContainer } from "../../../components/LoadAnimatedContainer";
import { useRbtRequests } from "../context/RbtRequestContext";
import { useState } from "react";
import { ReactComponent as ArrowDown } from "../../../assets/icons/arrow-down-black.svg";
import { ReactComponent as ArrowUp } from "../../../assets/icons/arrow-up-black.svg";
import moment from "moment";
import Modal from "../../../components/Modal";
import { RbtRequest, RbtRequestStatus, rbtRequestStatusColor } from "../RequestRbt.types";
import { RbtRequestModal } from "../modals/RbtRequestModal";
import { zipCodes } from "../modals/zip_codes";

export function InactiveRbtRequestList({ isOpen, onClose, onRefresh }) {
  const { rbtRequests, refreshRequests } = useRbtRequests();
  const [requestView, setRequestView] = useState<RbtRequest>();
  const [sortField, setSortField] = useState<String | undefined>()
  const [sortASC, setSortASC] = useState(false)

  const sortedRequests = rbtRequests.sort((a, b) => {

    // default
    if ((!sortField) && a.createdAt && b.createdAt) {
      return a.createdAt < b.createdAt ? -1 : 1;
    }

    if (sortField === 'owner' && a.owner?.firstName && b.owner?.firstName) {
      return a.owner.firstName.localeCompare(b.owner.firstName) * (sortASC ? 1 : -1)
    }
    if (sortField === 'friendlyId' && a.friendlyId && b.friendlyId) {
      return (sortASC ? (a.friendlyId < b.friendlyId) : (b.friendlyId < a.friendlyId)) ? -1 : 1;
    }
    if (sortField === 'createdAt' && a.createdAt && b.createdAt) {
      return (sortASC ? (a.createdAt < b.createdAt) : (b.createdAt < a.createdAt)) ? -1 : 1;
    }
    if (sortField === 'desiredStart' && a.desiredStart && b.desiredStart) {
      return (sortASC ? (a.desiredStart < b.desiredStart) : (b.desiredStart < a.desiredStart)) ? -1 : 1;
    }
    if (sortField === 'clientZipCode' && a.clientZipCode && b.clientZipCode) {
      return (sortASC ? (a.clientZipCode < b.clientZipCode) : (b.clientZipCode < a.clientZipCode)) ? -1 : 1;
    }
    if (sortField === 'pointPerson') {
      if (a.pointPerson && b.pointPerson) {
        return a.pointPerson.localeCompare(b.pointPerson) * (sortASC ? 1 : -1)
      } else {
        if (a.pointPerson && !b.pointPerson) {
          return (sortASC ? -1 : 1)
        } else if (b.pointPerson && !a.pointPerson) {
          return (sortASC ? 1 : -1)
        }
      }
    }
    if (sortField === 'status' && a.status && b.status) {
      const keys = Object.values(RbtRequestStatus);
      return ((keys.indexOf(a.status) < keys.indexOf(b.status)) ? -1 : 1) * (sortASC ? 1 : -1)
    }
    return 0;
  })


  const sortToggleClick = (field) => () => {
    if (sortField === field) {
      if (sortASC) {
        setSortASC(false)
      } else {
        setSortField(undefined)
        setSortASC(true)
      }
    } else {
      setSortField(field)
      setSortASC(true)
    }
  }

  const headers = [
    { value: "owner", label: "Provider Name" },
    { value: "friendlyId", label: "Request #" },
    { value: "createdAt", label: "Submitted Date" },
    { value: "desiredStart", label: "Target Start Date" },
    { value: "clientZipCode", label: "Client Zip Code" },
    { value: "pointPerson", label: "Headstart Point Person" },
    { value: "status", label: "Status" },
    { value: "candidate", label: "Candidate Assigned" }
  ]

  return (
    <Modal isOpen={isOpen} handleModal={onClose}>
      <LoadAnimatedContainer className="mx-auto">
        <div className="text-black text-3xl font-bold mb-5">
          Inactive RBT Requests
        </div>
        <div className="bg-white rounded-lg shadow-[10px_14px_40px_0px_rgba(0,71,28,0.04)] p-5">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
            </div>

            <div className="flex gap-2 items-center"></div>
          </div>
          <div
            className={`mt-3 text-[14px] h-[calc(100vh-232px)] overflow-auto`}
          >
            <table className="w-full">
              <thead className="[&_td]:bg-[#EEF1EF] [&_td]:py-4">
                <tr className="sticky top-0 z-[1]">
                  {headers.map((header, i) => (<td className={i === 0 ? "rounded-l pl-5" : ""}>
                    <div className="flex items-center cursor-pointer" onClick={sortToggleClick(header.value)}>
                      {header.label}
                      {sortField === header.value && <div>
                        {sortASC ? <ArrowUp className="w-4 h-4" /> : <ArrowDown className="w-4 h-4" />}
                      </div>}
                    </div>
                  </td>))}
                </tr>
              </thead>
              <tbody>
                {sortedRequests?.filter(r => !r.isActive)?.map((request) => (
                  <tr
                    key={request.id}
                    className="cursor-pointer hover:bg-[#F9F9F9] [&_td]:py-3 border-b border-b-[#EFF1FF]"
                    onClick={() => setRequestView(request)}
                  >
                    <td className="name pl-5">
                      <div className="flex items-center gap-3">
                        <div className="flex flex-col">
                          <div className="flex items-center gap-2.5">
                            <div className="inline-block overflow-none">
                              {request?.owner?.firstName} {request?.owner?.lastName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                    <td className="">
                      <div className="flex items-center gap-2">
                        #<div className="font-bold">{request.friendlyId}</div>
                      </div>
                    </td>
                    <td className="text-ellipsis overflow-hidden whitespace-nowrap max-w-[200px]">
                      {moment.utc(request.createdAt).local().format("MMM DD,YYYY")}
                    </td>
                    <td>
                      {moment.utc(request.desiredStart).local().format("MMM DD,YYYY")}
                    </td>
                    <td>
                      {request.clientZipCode ?? ''} {zipCodes?.[request?.clientZipCode]?.city ? `(${zipCodes?.[request?.clientZipCode]?.city},${zipCodes?.[request?.clientZipCode]?.state_short})` : ''}
                    </td>
                    <td>
                      {request?.pointPerson ?? '-'}
                    </td>

                    <td>
                      <div style={{ color: rbtRequestStatusColor(request.status || RbtRequestStatus.REVIEW) }}>
                        {request.status}
                      </div>
                    </td>
                    <td>
                      {(!request?.rbtCandidates || request?.rbtCandidates?.length === 0) ? "-" :
                        `${request.rbtCandidates.map(c => `${c.firstName} ${c.lastName}`).join(", ")}`}
                    </td>
                  </tr>
                ))}

                <div className="h-5"></div>
              </tbody>
            </table>
          </div>
        </div>
        {requestView && <RbtRequestModal rbtRequest={requestView} onClose={
          () => {
            setRequestView(undefined);
            refreshRequests();
          }}
        />}
      </LoadAnimatedContainer>
    </Modal>
  );
}

export const FamilyOptionsDropdown = ({ onUnBlock, onRemove }) => {
  return (
    <div className="z-10 w-[150px] flex flex-col justify-center items-center bg-white shadow-[0_6px_24px_0_rgba(28,43,40,0.25)] rounded-xl py-2 mr-8">
      <ul
        className="py-1 text-sm text-gray-700 "
        aria-labelledby="dropdownMenuIconButton"
      >
        <li>
          <button
            className="w-full text-[#212424] px-5 h-10 text-base rounded-[10px] hover:bg-whiteButtonHover"
            onClick={(e) => {
              e.stopPropagation();

              onUnBlock();
            }}
          >
            Activate
          </button>
        </li>
      </ul>
    </div>
  );
};
