import React, { useEffect, useState, useRef } from "react";
import { api } from "../../../services/api";
import { getUser, setUser } from "../../../services/utils";
import { ShowToast } from "../../../services/toast";
import { CONTACT_FETCH_INTERVAL } from "../../mailbox/context/mailbox-context";

export const UserContext = React.createContext({});

const ACTIVE_STATUS_INTERVAL = 1000 * 60; // 1 minute

export type UnreadConvCounts = {
  rbtAdminCount: number;
  clientAcqCount: number;
  insOpsCount: number;
  generalAdminCount: number;
  clientCount: number;
  generalAdminUnread: string[];
  rbtAdminUnread: string[];
  clientAcqUnread: string[];
  insOpsUnread: string[];
};

export function UserProvider({ children }) {
  const [userInfo, setUserInfo] = useState({});
  const [userInfoLoading, setUserInfoLoading] = useState(false);
  const [unreadConvCounts, setUnreadConvCounts] = useState<UnreadConvCounts>({ 
    rbtAdminCount: 0,
    clientAcqCount: 0,
    insOpsCount: 0,
    generalAdminCount: 0, 
    clientCount: 0,
    generalAdminUnread: [],
    rbtAdminUnread: [],
    clientAcqUnread: [],
    insOpsUnread: []
  });

  const [selectedProviderToMessage, setSelectedProviderToMessage] =
    useState<any>(null);
  const user = getUser();
  const activeStatusRef = useRef<any>(null);
  const unreadConvCountRef = useRef<any>(null);

  const totalUnreadConvCount = unreadConvCounts.rbtAdminCount + unreadConvCounts.clientAcqCount + unreadConvCounts.insOpsCount + unreadConvCounts.generalAdminCount + unreadConvCounts.clientCount;

  const fetchUser = async (userId: string) => {
    try {
      setUserInfoLoading(true);
      const response = await api.getUserProfile(userId);
      setUserInfoLoading(false);
      setUserInfo({
        ...response,
      });
      setUser({
        ...response,
      });
    } catch (error) {
      ShowToast({
        message: "Something went wrong while fetching user info",
        type: "error",
      });
    }
  };

  const fetchUnreadConvCounts = async () => {
    try {
      const response = await api.getUnreadConversationsCount(user?.id);
      if ([200, 201].includes(response.status)) {
        setUnreadConvCounts(response.data)
      }
    } catch (error) { }
  };

  const values = {
    userInfo,
    setUserInfo,
    fetchUser,
    loading: userInfoLoading,
    selectedProviderToMessage,
    setSelectedProviderToMessage,
    fetchUnreadConvCounts,
    unreadConvCounts,
    totalUnreadConvCount
  };
  useEffect(() => {
    if (user?.id) {
      fetchUser(user?.id);

      // send active status to the server every 1 minute
      if (activeStatusRef.current) clearInterval(activeStatusRef.current);
      activeStatusRef.current = setInterval(() => {
        api.markUserAsActive(user?.id);
      }, ACTIVE_STATUS_INTERVAL);

      // fetch unread conversation count
      fetchUnreadConvCounts();
      if (unreadConvCountRef.current) clearInterval(unreadConvCountRef.current);
      unreadConvCountRef.current = setInterval(() => {
        fetchUnreadConvCounts();
      }, CONTACT_FETCH_INTERVAL);
    }
    return () => {
      if (activeStatusRef.current) clearInterval(activeStatusRef.current);
      if (unreadConvCountRef.current) clearInterval(unreadConvCountRef.current);
    };
  }, [user?.id]);
  return <UserContext.Provider value={values}>{children}</UserContext.Provider>;
}

export function useUser() {
  const context = React.useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within a UserProvider");
  }
  return context;
}
