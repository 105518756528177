import { useEffect, useState } from "react";
import { readFile } from "./CropImage";
import ImageCropModalContent from "./ImageCropModalContent";
import { useImageCropContext } from "./provider/ImageCropProvider";
import Modal from "../Modal";
import { PopupModal } from "../PopupModal";

export const ImageCrop = ({
  image,
  isOpen,
  onClose,
  onImageChange,
}: {
  image: File;
  isOpen: boolean;
  onClose: () => void;
  onImageChange: (data: any) => void;
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [preview, setPreview] = useState<any>();

  const { getProcessedImage, setImage, resetStates } =
    useImageCropContext() as any;

  const handleDone = async () => {
    const avatar = await getProcessedImage();
    setPreview(window.URL.createObjectURL(avatar));
    onImageChange({
      url: window.URL.createObjectURL(avatar),
      file: new File([avatar], image.name, {
        type: image.type,
      }),
    });
    resetStates();
    onClose();
  };

  const handleFileChange = async ({ target: { files } }) => {
    const file = files && files[0];
    const imageDataUrl = await readFile(file);
    setImage(imageDataUrl);
    setOpenModal(true);
  };

  useEffect(() => {
    if (isOpen && image) {
      if (typeof image === "string") {
        (async function () {
          const blob = await (await fetch(image, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            headers: {
              Origin: 'https://headstart.health',
              "Content-Type": "application/json",
            },
          })).blob();
          let dataUrl = await new Promise((resolve) => {
            let reader = new FileReader();
            reader.onload = () => resolve(reader.result);
            reader.readAsDataURL(blob);
          });
          // now do something with `dataUrl`
          setImage(dataUrl);
        })();
      } else {
        readFile(image).then((imageDataUrl) => {
          setImage(imageDataUrl);
        });
      }
    }
  }, [isOpen, image]);

  return (
    <PopupModal
      contentClassName="w-full md:mx-auto md:w-[600px] max-w-[600px]"
      onClose={onClose}
    >
      <ImageCropModalContent handleDone={handleDone} handleClose={onClose} />
    </PopupModal>
  );
};
