export function ContactList({
  children,
  containerClassName,
}: {
  children: any;
  containerClassName?: any;
}) {
  return (
    <div
      className={`lg:max-w-[360px] lg:w-[360px] bg-white rounded-[16px] p-2.5 gap-3 h-full ${containerClassName}`}
    >
      {children}
    </div>
  );
}
